import * as ko from 'knockout';
import * as _ from 'underscore';

import 'pubsub';

import {Notifier} from "Core/Common/Notifier";
import {BlockUI} from "Core/Common/BlockUi";
import {Event} from "Core/Common/Event";

import {SecurityProfileStore} from "Core/Common/Security/SecurityProfile/Stores/SecurityProfileStore";

import {SecurityProfileResponseModel} from "Core/Common/Security/SecurityProfile/Stores/Models/SecurityProfileResponseModel";

import {SecurityProfileModel} from "MenuManager/SecurityProfileMenu/Models/SecurityProfileModel";

import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';

import Template from 'MenuManager/SecurityProfileMenu/Templates/SecurityProfileMenu.html';

export class SecurityProfileMenu extends Event {
    private _profiles: KnockoutObservableArray<SecurityProfileModel>;
    private _selectedProfile: KnockoutObservable<SecurityProfileModel>;

    private _target: HTMLElement;

    IsAuthenticated: KnockoutObservable<boolean>;

    constructor(isAuthenticated: boolean) {
        super();

        this._profiles = ko.observableArray([]);
        this._selectedProfile = ko.observable(null);

        this.IsAuthenticated = ko.observable(isAuthenticated);
        this.IsAuthenticated.subscribe(isAuthenticated => isAuthenticated && this.LoadProfiles());

        if (this.IsAuthenticated()) {
            this.LoadProfiles();
        }

        if (isAuthenticated) {
            PubSub.subscribe(PUB_SUB_EVENTS.REFRESH_SECURITY_PROFILE, () => {
                this.RefreshUI();
            });
        }
    }

    GetTemplate() {
        return Template;
    }

    AfterRender() {

    }

    Render(targetId: string) {
        this._target = document.getElementById(targetId);
        if (this._target) {
            ko.cleanNode(this._target);
            ko.applyBindings(this, this._target);
        }
    }

    private LoadProfiles() {
        BlockUI.Block({
            Target: this._target
        });

        SecurityProfileStore.LoadProfiles()
            .then(profiles => this.SaveProfiles(this.MapToProfiles(profiles)))
            .fail(error => new Notifier(error.message))
            .always(() => BlockUI.Unblock(this._target));
    }

    private MapToProfiles(profilesResponse: SecurityProfileResponseModel[]) {
        return profilesResponse.map(profile => new SecurityProfileModel(profile.Id, profile.Name, profile.Main, profile.Image));
    }

    private SaveProfiles(profiles: SecurityProfileModel[]) {
        this._profiles(profiles);

        const selectedProfile = _.find(profiles, profile => profile.Main());
        this._selectedProfile(selectedProfile);

        this.Trigger('CHANGE_LOGO_IMAGE', {ProfileImage: selectedProfile.Image});
    }

    private SelectProfile(profile: SecurityProfileModel) {
        if (profile.Main()) {
            return;
        }

        BlockUI.Block({
            Target: this._target
        });

        SecurityProfileStore.SelectMainProfile(profile.Id)
            .then(() => this.RefreshUI())
            .fail(error => new Notifier().Failed(JSON.parse(error.message).Message))
            .always(() => BlockUI.Unblock(this._target));
    }

    private RefreshUI() {
        this.LoadProfiles();

        PubSub.publish(PUB_SUB_EVENTS.CLOSE_POPUPS, null);
        PubSub.publish(PUB_SUB_EVENTS.REFRESH_MAIN_MENU, null);
    }
}