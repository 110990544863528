import * as _ from 'underscore';
import * as ko from 'knockout';

import { QueryEntityJoinModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityJoinModel';
import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { ToolbarQueryEntity } from 'Desktop/Toolbar/ToolbarQueryEntity/ToolbarQueryEntity';

import Template from 'Desktop/Toolbar/ToolbarQueryEntityJoin/Templates/Template.html';

export class ToolbarQueryEntityJoin {
	private _isExpanded: KnockoutObservable<boolean>;
	private _toolbarQueryEntity: ToolbarQueryEntity;
	private _toolbarQueryLinkEntity: ToolbarQueryEntity;
	private _model: QueryEntityJoinModel;
	private _isDesktop: boolean;
	private _isRoot: boolean;
	private _joins: KnockoutObservableArray<ToolbarQueryEntityJoin>;

	constructor(
		queryEntityJoin: QueryEntityJoinModel,
		isDesktop: boolean,
		isRoot: boolean = false
	) {
		this._model = queryEntityJoin;
		this._isDesktop = isDesktop;
		this._isExpanded = ko.observable(false);
		this._joins = ko.observableArray([]);
		
		this._isRoot = isRoot;
		this.Init();
	}

	get Style() {
		return !this._isRoot  ? { margin: '5px' } : {};
	}

	Init() {
		if (this._model.Entity) {
			this._toolbarQueryEntity = new ToolbarQueryEntity(this._model.Entity, this._isDesktop);
		}

		if (this._model.LinkEntity) {
			this._toolbarQueryLinkEntity = new ToolbarQueryEntity(this._model.LinkEntity, this._isDesktop);
		}


		let allJoins = this._model.Joins
		.concat(this._model.SubEntityJoins)
		.concat(this._model.LookupEntityJoins)
		.concat(this._model.ReferenceEntityJoins)
		.concat(this._model.ReferenceLookupEntityJoins);					

		this._joins(allJoins.map(item=> new ToolbarQueryEntityJoin(item, this._isDesktop, false)));
	}

	get Entity(): ToolbarQueryEntity {
		return this._toolbarQueryEntity;
	}

	get LinkEntity(): ToolbarQueryEntity {
		return this._toolbarQueryLinkEntity;
	}

	get EntityModel(): QueryEntityModel {
		return this._model.Entity;
	}

	get LinkEntityModel(): QueryEntityModel {
		return this._model.LinkEntity;
	}

	get Name() {
		return this._model.Entity.Metadata.NameTranslation || this._model.Entity.Metadata.Name;
	}

	get Title() {
		var name = this._model.Entity.Metadata.NameTranslation || this._model.Entity.Metadata.Name;
		if (this._model.Entity.Index > 1) {
			name = `${name}${this._model.Entity.Index}`;
		}
		return name;
	}

	get LinkName() {
		return this._model.LinkEntity.Metadata.Name;
	}

	GetTemplate() {
		return Template;
	}

	Expand() {
		this._isExpanded(!this._isExpanded());
	}

	get IsExpanded(): KnockoutObservable<boolean> {
		return this._isExpanded;
	}

	get Joins(): KnockoutObservableArray<ToolbarQueryEntityJoin> {
		return this._joins;
	}
}