import {P} from "Core/Common/Promise";
import { Request, IRequestOptions } from "Core/Common/Request";
import { BlockUI } from "Core/Common/BlockUi";

export class RecordSecurityStore {
	static GetRecordSecurityScreen(securityProfileId: number): P.Promise<any> {
		let deferredResult = P.defer<any>();

		Request.Send({
				proxy: {
					url: '/api/RecordSecurityApi/GetRecordSecurityScreen',
					dataType: 'json',
					type: 'GET'
				},
				params: { id: securityProfileId }
		}).then(result => deferredResult.resolve(result));

		return deferredResult.promise();
	}

	static ResetSecurity(securityProfileId: number): P.Promise<any> {
		const deferredResult = P.defer();

		BlockUI.Block();

		Request.Send({
			proxy: {
				url: "/api/RecordSecurityApi/ResetSecurity",
				type: "GET",
				dataType: "json"
			}, params: { id: securityProfileId }
		}).then(result => {
			if (result.IsSuccessfull) {
				deferredResult.resolve(null);
			} else {
				deferredResult.reject({ message: result.ErrorMessage });
			}
		})
			.fail(() => deferredResult.reject({ message: "Error reseting security" }))
			.always(() => BlockUI.Unblock());

		return deferredResult.promise();
	}


	static ReserveGroup(profileId: number, groupId: number): P.Promise<any> {
		const deferredResult = P.defer();

		BlockUI.Block();

		Request.Send({
			proxy: {
				url: "/api/RecordSecurityApi/ReserveGroup",
				type: "POST"
			}, params: { ProfileId: profileId, GroupId: groupId }
		}).then(result => {
				deferredResult.resolve(null);
		})
		.fail((data) => {
			let jsonResult = JSON.parse(data.message);
			deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
		})
		.always(() => BlockUI.Unblock());

		return deferredResult.promise();
	}
}