import * as ko from "knockout";

import {Event} from "Core/Common/Event";

import {IFieldSecuritySettings} from "Core/Controls/FieldSecurity/Shared/Interfaces/IFieldSecuritySettings";

import {ViewModes} from "Core/Controls/FieldSecurity/Shared/Enums/ViewModes";

export abstract class FieldSecurityComponent extends Event {
	protected Settings: IFieldSecuritySettings;
	protected Templates: { View: any, Edit: any, Design: any };
	protected ManipulationsEnabled: KnockoutObservable<boolean>;

	constructor(settings: IFieldSecuritySettings) {
		super();

		this.Settings = settings;
		this.ManipulationsEnabled = ko.observable(true);
	}

	GetTemplate() {
		const viewModeTemplateMapping = {};

		viewModeTemplateMapping[ViewModes.View] = this.Templates.View;
		viewModeTemplateMapping[ViewModes.Edit] = this.Templates.Edit;
		viewModeTemplateMapping[ViewModes.Design] = this.Templates.Design;

		return viewModeTemplateMapping[this.Settings.ViewMode];
	}

	ChangeManipulations(enable: boolean) {
		this.ManipulationsEnabled(enable);
	}
}