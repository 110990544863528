import * as _ from 'underscore';

import { Request, IRequestOptions } from 'Core/Common/Request';
import { P } from 'Core/Common/Promise';


export interface IRunReportRequestModel {
	ControlId: number;
	ExportFormat?: number;
}

export class CrystalReportViewerStore {
	static RunReport(params: IRunReportRequestModel): P.Promise<string> {
		var deferredResult = P.defer<string>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: "/api/CrystalReportViewerApi/RunReport",
				dataType: "json",
				type: "POST"
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(data.ResultObject);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			}).fail((data)=>{
				let jsonResult = JSON.parse(data.message);
				deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			});

		return deferredResult.promise();
	}
}