import * as ko from 'knockout';
import * as $ from 'jquery';
import * as _ from 'underscore';
import {IUpdateActionStatusRequestModel, KanbanStore} from 'Core/Controls/Kanban/Stores/KanbanStore';
import {
    ActionModel, ParentOrChildRecordModel,
    PriorityModel,
    RecordModel,
    StatusModel,
    StepModel,
    ThumbnailModel
} from 'Core/Controls/Kanban/Models/StatusModel';
import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';
import {DataModes} from 'Core/Enums/DataModes';
import {IScreen} from 'Core/Screens/IScreen';
import type {EditScreen} from 'Core/Screens/EditScreen/EditScreen';
import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';

import {CONFIRMATIONS, LABELS, NOTIFICATIONS} from 'Core/Components/Translation/Locales';

import {TypeScreen} from 'Core/Screens/TypeScreen/TypeScreen';
import {LOCK_EVENTS, LockManager} from 'Core/Components/Locker/LockManager';
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';

import {Guid} from 'Core/Common/Guid';

import {FollowUpRecordModel} from 'Core/Controls/ButtonFollowUp/Models/FollowUpRecordModel';
import {ButtonFollowUp} from 'Core/Controls/ButtonFollowUp/ButtonFollowUp';

import {ConfigModel} from 'Core/GeneralProperties/Models/ConfigModel';
import {TABLE_SECURITY_WORDS} from 'Core/Constants/TableSecurityWords';
import {FollowupModes} from 'Core/Constants/FollowupModes';
import {CONTROL_TYPES, LIFE_STATUS_GROUPS} from 'Core/Constant';
import {PROPERTIES} from './Constants';

import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';

import {LinkList} from 'Core/Controls/LinkList/LinkList';
import {NewRelationModel} from 'Core/Controls/LinkList/Models/NewRelationModel';
import {Tab} from 'Core/Controls/Tab/Tab';
import {TabPage} from 'Core/Controls/TabPage/TabPage';
import {Timer} from "Core/Controls/Timer/Timer";

import {ResizeObserver} from 'Core/Common/ResizeObserver';

import 'Core/Controls/Kanban/KanbanCard';
import {FollowUpStore} from "../ButtonFollowUp/Stores/FollowUpStore";
import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS,
    Types as ConfirmationTypes
} from "../../Components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import {PromptDialog, Types} from "../../Components/Dialogs/PromptDialog/PromptDialog";
import {LifeStatusSimpleModel} from "../ButtonFollowUp/Models/LifestatusSimpleModel";
import {Tooltip} from "Core/Common/Tooltip";
import {BaseScreen} from "../../Screens/BaseScreen";
import {PathRunner} from "../../Components/PathRunner/PathRunner";

import {LifeStatusesGeneralModel} from "Core/Controls/ButtonFollowUp/Models/LifeStatusesGeneralModel";
import {FlowFolderModel} from "Core/Components/ProgressBar/Models/Response/FlowFolderModel";
import {KanbanStep} from 'Core/Controls/Kanban/Step';
import {FollowUpLifeStatuses} from "Core/Controls/ButtonFollowUp/FollowUpLifeStatuses";
import {ScreenParamsModel} from "Core/Controls/ButtonFollowUp/Models/ScreenParamsModel";
import {ActionCheckList} from "Core/Components/ActionCheckList/ActionCheckList";
import {LifeStatusesModel} from 'Core/Controls/ButtonFollowUp/Models/LifeStatusesModel';
import {ICheckItemSerializedValue} from "../../Components/ActionCheckList/CheckItems/ICheckItemSerializedValue";
import {FlowFolderStore} from "../../Components/ProgressBar/Stores/FlowFolderStore";
import {ProgressBarInfoModel} from "../../Components/ProgressBar/Models/Response/ProgressBarInfoModel";
import {KanbanTypeViewModel} from "Core/Controls/Kanban/Models/KanbanTypeViewModel";

const ResizeService = new ResizeObserver();

interface TodoKanbanParams {
    screen: KnockoutObservable<IScreen>;
    selectedTypeObservable: KnockoutObservable<KanbanTypeViewModel>;
    noTypesLoaded: KnockoutObservable<boolean>;
    generalProperties: ConfigModel;
    searchString: KnockoutObservable<string>;
    selectedUsers: KnockoutObservableArray<number>;
    showPlanned: KnockoutObservable<boolean>;
    isTodoToggleShown: KnockoutObservable<boolean>;
    titleFieldId: number;
    kanbanSubjectId: KnockoutObservable<number>;
    viewId: KnockoutObservable<number>;
    todoEntityId: KnockoutObservable<number>;
    todoTypeId: KnockoutObservable<number>;
    todoFieldId: KnockoutObservable<number>;
    controlId: number;
    loadStatuses?: boolean;
    timerControl: Timer;
}

//Templates
import Template from 'Core/Controls/Kanban/Templates/Todo/TodoKanban.html';
import TodoParentOrChildRecordTemplate from 'Core/Controls/Kanban/Templates/Todo/TodoParentOrChildRecordTemplate.html';
import TodoActionItemsTemplate from "Core/Controls/Kanban/Templates/Todo/TodoActionItemsTemplate.html";

ko.templates['Core/Controls/Kanban/Templates/Todo/TodoKanban'] = Template;

class TodoKanban {
    private _screen: KnockoutObservable<IScreen>;
    private _selectedTypeObservable: KnockoutObservable<KanbanTypeViewModel>;
    private _noTypesLoaded: KnockoutObservable<boolean>;
    private _searchString: KnockoutObservable<string>;
    private _selectedUsers: KnockoutObservableArray<number>;
    private _showPlanned: KnockoutObservable<boolean>;
    private _generalProperties: ConfigModel;
    private _isTodoToggleShown: KnockoutObservable<boolean>;
    private _viewId: KnockoutObservable<number>;
    private _todoEntityId: KnockoutObservable<number>;
    private _todoTypeId: KnockoutObservable<number>;
    private _todoFieldId: KnockoutObservable<number>;
    private _elObservable: KnockoutObservable<HTMLElement>;
    private _element: HTMLElement;
    private _draggingFrom: KnockoutObservable<{ recordId: number; lifestatusId: number }>;
    private _selectedTypeSubscription: KnockoutSubscription;
    private _noTypesLoadedSubscription: KnockoutSubscription;
    private _selectedUsersSubscription: KnockoutSubscription;
    private _showPlannedSubscription: KnockoutSubscription;
    private _viewIdSubscription: KnockoutSubscription;
    private _guid: string;
    _titleFieldId: number;
    private _kanbanSubjectId: KnockoutObservable<number>;
    _withDateBadges: boolean;
    _withMemo: boolean;
    _readOnly: boolean;
    _colorBar: boolean;
    _loadStatuses: boolean;
    private _timerControl: Timer;
    private _heightImage: any;
    private _userVars: UserVarsManager;
    private _controlId: number
    private _alwaysShowDeadline: boolean;
    private _hideOwner: boolean;
    private _backgroundColor: string;
    private _showTooltip: boolean;
    private _showAlias: boolean;

    private _followUpRecord: FollowUpRecordModel;
    private _followUpLifeStatuses: FollowUpLifeStatuses;
    private _checkList: ActionCheckList;
    private _isFlowFolder: boolean;
    private _lifeStatuses: LifeStatusesModel;

    _status: KnockoutObservable<StatusModel>;
    _isCreationAllowed: KnockoutObservable<boolean>;

    _recordsWithTodoActions: KnockoutComputed<{
        record: RecordModel;
        groupedActions: {
            [lifestatusId: string]: KnockoutObservableArray<ActionModel>;
        };
    }[]>;

    _avatarThumbnails: KnockoutComputed<ThumbnailModel[]>;
    _priorities: KnockoutComputed<PriorityModel[]>;
    _isTypeHierarchyCorrect: KnockoutComputed<boolean>;
    _isNewButtonEnabled: KnockoutComputed<boolean>;

    _labels = LABELS;

    private _tooltip: Tooltip;

    constructor(params: TodoKanbanParams & { element: HTMLElement }) {
        this._screen = params.screen;
        this._selectedTypeObservable = params.selectedTypeObservable;
        this._noTypesLoaded = params.noTypesLoaded;
        this._generalProperties = params.generalProperties;
        this._searchString = params.searchString;
        this._selectedUsers = params.selectedUsers;
        this._showPlanned = params.showPlanned;
        this._isTodoToggleShown = params.isTodoToggleShown;
        this._titleFieldId = params.titleFieldId;
        this._kanbanSubjectId = params.kanbanSubjectId;
        this._viewId = params.viewId;
        this._todoEntityId = params.todoEntityId;
        this._todoTypeId = params.todoTypeId;
        this._todoFieldId = params.todoFieldId;
        this._elObservable = ko.observable(null);
        this._element = params.element;
        this._controlId = params.controlId;
        this._guid = Guid.NewGuid();
        if (!this._userVars) {
            this._userVars = UserVarsManager.Instance;
        }

        this._tooltip = null;

        this._loadStatuses = params.loadStatuses;

        this._timerControl = params.timerControl;

        this._withDateBadges = this._generalProperties.GetPropertyValue(PROPERTIES.WITH_DATE_BADGES);
        this._withMemo = this._generalProperties.GetPropertyValue(PROPERTIES.WITH_MEMO);
        this._readOnly = this._generalProperties.GetPropertyValue(PROPERTIES.READ_ONLY);
        this._heightImage = this._generalProperties.GetPropertyValue(PROPERTIES.HEIGHT_IMAGE);
        this._colorBar = this._generalProperties.GetPropertyValue(PROPERTIES.COLOR_BAR);
        this._alwaysShowDeadline = this._generalProperties.GetPropertyValue(PROPERTIES.ALWAYS_SHOW_DEADLINE);
        this._hideOwner = this._generalProperties.GetPropertyValue(PROPERTIES.HIDE_OWNER);
        this._backgroundColor = this._generalProperties.GetPropertyValue(PROPERTIES.BACKGROUND_COLOR);
        this._showTooltip = this._generalProperties.GetPropertyValue(PROPERTIES.SHOW_TOOLTIP);
        this._showAlias = this._generalProperties.GetPropertyValue(PROPERTIES.SHOW_ALIAS);

        this._status = ko.observable({
            Records: [],
            Steps: [],
            Thumbnails: [],
            Links: [],
            Priorities: [],
            HasTodoType: true,
            IsTodoEnabled: true,
            IsTypeHierarchyCorrect: true,
            MailEntityId: undefined
        });
        this._isCreationAllowed = ko.observable();

        this._draggingFrom = ko.observable({recordId: undefined, lifestatusId: undefined});

        this._recordsWithTodoActions = ko.pureComputed(() => {
            const status = this._status();
            const searchStringValue = this._searchString();
            const searchString = searchStringValue && searchStringValue.toUpperCase();

            const todoTypeId = this._todoTypeId();

            _.each(status.Records, (record) => {
                let isKanbanBoardExpanded = this._userVars.GetKanbanBoardAccordionGroup(this._controlId, record.EntityId, record.RecordId, true);
                record.AriaExpanded(isKanbanBoardExpanded);
                record.AriaExpanded.subscribe((newValue) => {
                    this._userVars.SetKanbanBoardAccordionGroup(this._controlId, record.EntityId, record.RecordId, newValue);
                })
            })

            if (status.Records[0] !== undefined) {
                const recordsWithActions = status.Records.map(record => ({
                    record,
                    actions: record.Actions.filter(action => action.ActionTypeId === todoTypeId)
                }));

                const filteredRecordsWithActions = searchString
                    ? _.compact(
                        recordsWithActions.map(recordWithAction => {
                            const {record, actions} = recordWithAction;

                            if (
                                [record.Name, record.LifeStatusTranslatedName || record.LifestatusName].some(
                                    label => label && label.toUpperCase().includes(searchString)
                                )
                            ) {
                                return recordWithAction;
                            }

                            const filteredActions = actions.filter(
                                action =>
                                    (action.ActionName && action.ActionName.toUpperCase().includes(searchString)) ||
                                    action.Users.some(
                                        user => user.UserName && user.UserName.toUpperCase().includes(searchString)
                                    )
                            );

                            return filteredActions.length > 0 ? {record, actions: filteredActions} : undefined;
                        })
                    )
                    : recordsWithActions;

                return filteredRecordsWithActions.map(({record, actions}) => ({
                    record,
                    groupedActions: _.mapObject(
                        _.groupBy(actions, (action) => action.ActionLifestatusId),
                        // Conversion to observable because of some inflexibility of dragula extension
                        actions => ko.observableArray(actions)
                    )
                }));
            }

            return [];
        });

        this._avatarThumbnails = ko.pureComputed(() => {
            const status = this._status();

            return status.Thumbnails;
        });

        this._priorities = ko.computed(() => {
            const status = this._status();
            
            return status.Priorities;
        });

        this._isTypeHierarchyCorrect = ko.pureComputed(() => {
            const status = this._status();

            return status.IsTypeHierarchyCorrect;
        });

        this._isNewButtonEnabled = ko.pureComputed(() =>
            this._isCreationAllowed() && !this._readOnly && this._isTypeHierarchyCorrect()
        );

        this._followUpLifeStatuses = null;
        this._isFlowFolder = null;
        this._checkList = null;

        this.BindEvents();
    }

    private BindEvents() {
        PubSub.subscribe('KANBAN_FULLSCREEN', (message: any, data: boolean)=> {
            this.SetContainerHeight(data);
        });
    }

    get IsCalculatedViaCalcPriority(): boolean {
        return this._generalProperties.GetPropertyValue(PROPERTIES.WITH_PRIORITIES).Value === 'WithCalculatedViaCalсPrio';
    }

    get HasTimer(): boolean {
        return !!this._timerControl;
    }

    GetBackgroundColor(): string {
        return `${!!this._backgroundColor && this._backgroundColor}`
    }

    GetToDoKanbanGroupWidth(): string {
        let stepWidth = '180px',
            stepMargin = '5px',
            amendment = '10px';

        return `calc((${this._status().Steps.length} * ${stepWidth}) + (${this._status().Steps.length} * ${stepMargin}) - ${amendment})`
    }

    SetContainerHeight(isFullscreen: boolean): void {
        const $todoKanban__component = $(this._elObservable()).find('.js-todo-kanban__component');
        const $container = $todoKanban__component.find('.todo-kanban');
        const toolbarHeight = $(this._elObservable()).find('.table-toolbar-line').outerHeight();

        let calculate: number = 45;

        if (isFullscreen) {
            calculate = toolbarHeight
        }

        $container.css('height', isFullscreen ? `calc(100vh - ${calculate}px)`: 'auto');
    }

    ListenForStatusesLoad() {
        this._selectedTypeSubscription = this._selectedTypeObservable.subscribe(() => {
            this.LoadStatuses();
        });
        this._noTypesLoadedSubscription = this._noTypesLoaded.subscribe(() => {
            this.LoadStatuses();
        });
        this._selectedUsersSubscription = this._selectedUsers.subscribe(() => {
            this.LoadStatuses();
        });
        this._showPlannedSubscription = this._showPlanned.subscribe(() => {
            this.LoadStatuses();
        });
        this._viewIdSubscription = this._viewId.subscribe(() => {
            this.LoadStatuses();
        });

        if(this._loadStatuses){
            this.LoadStatuses();
        }

        if (this._screen().IsConsultScreen && !this._loadStatuses && this._viewId() !== null ){
            this.LoadStatuses();
        }
    }

    LoadStatuses() {
        this._isFlowFolder = this._selectedTypeObservable() && this._selectedTypeObservable().HasFlowFolder;

        const recordId = this._screen().GetRecordId();
        const selectedUsers = this._selectedUsers();
        const showPlanned = this._showPlanned();

        const selectedType = this._selectedTypeObservable() ? this._selectedTypeObservable().K_Type : null;
        const isTypeSelected = !!selectedType || this._noTypesLoaded();

        const isViewSelected = this._viewId() !== null;

        const canLoadData = selectedUsers.length > 0 && showPlanned !== null && isTypeSelected && isViewSelected;

        if (!canLoadData) {
            return;
        }
        
        return this.LoadStatusesForAllRecords();
    }

    LoadStatusesForAllRecords() {
        const el = this._elObservable();
        BlockUI.Block({Target: el});
        return KanbanStore.GetStatuses({
            EntityId: this.SubjectEntity,
            FieldId: this._titleFieldId,
            TypeId: this._selectedTypeObservable() ? this._selectedTypeObservable().K_Type : null,
            SubjectTypeId: this._screen().GetTableTypeId(),
            FilterByTypeHierarchy: this._selectedTypeObservable() && this._selectedTypeObservable().ShowTypeHierarchy,
            FilterByOwners: true,
            UseSubjectEntity: this.UseKanbanSubject,
            FilterByEntityId: this.FilterByEntityId,
            FilterByRecordId: this.FilterByRecordId,
            RecordOwners: this._selectedUsers(),
            ShowPlanned: this._showPlanned(),
            WithTags: !this._generalProperties.GetPropertyValue(PROPERTIES.WITH_TAGS),
            WithSubjectImage: this._generalProperties.GetPropertyValue(PROPERTIES.WITH_SUBJECT_IMAGE),
            WithPriorities: this._generalProperties.GetPropertyValue(PROPERTIES.WITH_PRIORITIES).Value === 'Show',
            WithCalculatedPriority: this._generalProperties.GetPropertyValue(PROPERTIES.WITH_PRIORITIES).Value === 'Calculated',
            WithCalculatedViaCalсPrioPriority: this.IsCalculatedViaCalcPriority,
            WithMails: this._generalProperties.GetPropertyValue(PROPERTIES.WITH_MAILS),
            WithColor: this._colorBar,
            WithTimers: this.HasTimer,
            ViewId: this._viewId(),
            IsToDo: true,
            TodoModel: {
                EntityId: this._todoEntityId(),
                TypeId: this._todoTypeId(),
                FieldId: this._todoFieldId()
            },
            WithMemo: this._withMemo
        })
            .then((status: StatusModel) => {
                this._status(status);

                this._isTodoToggleShown(status.HasTodoType);
                this.HeaderHeightResize();

                if (
                    this._isCreationAllowed() === undefined &&
                    !this._readOnly &&
                    status.IsTypeHierarchyCorrect &&
                    status.Records.length > 0 &&
                    status.IsTodoEnabled
                ) {
                    return this.LoadUserAllowanceToCreate(this._todoEntityId());
                }
            })
            .fail(error => {
                new Notifier().Failed(error);
            })
            .always(() => {
                BlockUI.Unblock(el);
            });
    }

    GetActionItemsTemplate(data){
        return $((ko as any).renderTemplateX(TodoActionItemsTemplate, {$_data: data}));
    }

    LoadStatusesForRecord(recordId: number) {
        let targetElement = this._elObservable() ? {Target: this._elObservable()} : {};
        BlockUI.Block(targetElement);
        return KanbanStore.GetStatuses({
            RecordId: recordId,
            EntityId: this.SubjectEntity,
            FieldId: this._titleFieldId,
            FilterByOwners: true,
            UseSubjectEntity: this.UseKanbanSubject,
            FilterByEntityId: this.FilterByEntityId,
            FilterByRecordId: this.FilterByRecordId,
            RecordOwners: this._selectedUsers(),
            ShowPlanned: this._showPlanned(),
            WithTags: !this._generalProperties.GetPropertyValue(PROPERTIES.WITH_TAGS),
            WithSubjectImage: this._generalProperties.GetPropertyValue(PROPERTIES.WITH_SUBJECT_IMAGE),
            WithPriorities: this._generalProperties.GetPropertyValue(PROPERTIES.WITH_PRIORITIES).Value === 'Show',
            WithCalculatedPriority: this._generalProperties.GetPropertyValue(PROPERTIES.WITH_PRIORITIES).Value === 'Calculated',
            WithCalculatedViaCalсPrioPriority: this.IsCalculatedViaCalcPriority,
            WithMails: this._generalProperties.GetPropertyValue(PROPERTIES.WITH_MAILS),
            WithColor: this._colorBar,
            WithTimers: this.HasTimer,
            IsToDo: true,
            ViewId: this._viewId(),
            IsViewForAction: this._screen().IsConsultScreen,
            TodoModel: {
                EntityId: this._todoEntityId(),
                TypeId: this._todoTypeId(),
                FieldId: this._todoFieldId()
            },
            WithMemo: this._withMemo
        }).then((status: StatusModel) => {
            if(!status || !status.Records || status.Records.length === 0){
                return;
            }

            const [newRecord] = status.Records;

            const oldStatus = this._status();

            const changedRecordIndex = oldStatus.Records.findIndex(record => record.RecordId === recordId);

            this._status({
                ...oldStatus,
                Steps: status.Steps,
                Links: status.Links,
                Thumbnails: status.Thumbnails,
                Priorities: status.Priorities,
                Records: [
                    ...oldStatus.Records.slice(0, changedRecordIndex),
                    newRecord,
                    ...oldStatus.Records.slice(changedRecordIndex + 1)
                ]
            });

            this.HeaderHeightResize();

            if (
                this._isCreationAllowed() === undefined &&
                !this._readOnly &&
                status.IsTypeHierarchyCorrect &&
                status.Records.length > 0 &&
                status.IsTodoEnabled
            ) {
                return this.LoadUserAllowanceToCreate(this._todoEntityId());
            }
        })
        .always(() => {
            BlockUI.Unblock(this._elObservable());
        });
    }

    LoadUserAllowanceToCreate(actionsEntityId: number) {
        return KanbanStore.GetUserAllowance({
            TableId: actionsEntityId,
            SecurityWord: TABLE_SECURITY_WORDS.CREATE
        }).then((isCreationAllowed: boolean) => {
            this._isCreationAllowed(isCreationAllowed);
        });
    }

    AfterDrop(
        item: RecordModel,
        action: ActionModel,
        sourceIndex: number,
        sourceItems: any,
        sourceContext: StepModel,
        targetIndex: number,
        targetItems: any,
        targetContext: StepModel
    ) {
        let record = item as RecordModel;
        let isActionItems = !!(targetContext.ActionItems && targetContext.ActionItems.length);

        if (record){
            if (isActionItems){
                this.ShowCheckListKanban(action, record, targetContext);
            } else {
                FollowUpStore.GetStatuses({
                    RecordId: action.ActionRecordId,
                    EntityId: action.ActionsEntityId
                }).then(result => {
                    this.ApproveLifeStatus({
                        action,
                        record,
                        currentLifeStatus: result.Child.CurrentLifeStatus,
                        nextLifeStatus: result.Child.LifeStatuses.find(lifestatus => lifestatus.Id === targetContext.Id)
                    });
                });
            }
        }
    }

    ShowCheckListKanban(action: ActionModel, record: RecordModel, targetContext: StepModel){
        FlowFolderStore.GetProgressBarInfo({ EntityId: action.ActionsEntityId, RecordId: action.ActionRecordId })
            .then((result: ProgressBarInfoModel) => {
                result.NextLifeStatuses.Child.CheckLists = _.filter(result.NextLifeStatuses.Child.CheckLists, (checkList) => checkList.LifeStatusId === targetContext.Id);
                let nextLifeStatus = result.NextLifeStatuses.Child.LifeStatuses.find(lifestatus => lifestatus.Id === targetContext.Id);
                this.CheckListKanban(
                    result.NextLifeStatuses,
                    result.FlowFolder,
                    action,
                    record,
                    targetContext,
                    nextLifeStatus
                );
            });
    }

    CheckListKanban(result: LifeStatusesGeneralModel,
                    flowFolderModel: FlowFolderModel,
                    action: ActionModel,
                    record: RecordModel,
                    targetContext: StepModel,
                    nextLifeStatus: LifeStatusSimpleModel) {
        if (result == null || !result.Child) {
            return;
        }

        if (!this._followUpRecord) {
            this._followUpRecord = new FollowUpRecordModel();
        }

        if (!this._followUpLifeStatuses){
            this._followUpLifeStatuses = new FollowUpLifeStatuses();
        }

        const screenParams: ScreenParamsModel = { EntityId: action.ActionsEntityId, RecordId: action.ActionRecordId };

        this._followUpRecord.CurrentLifeStatus = result.Child.CurrentLifeStatus;

        this._lifeStatuses = result.Child;

        const isShowCheckLists = _.any(this._lifeStatuses.CheckLists, checkList => checkList.CheckItems.length > 0);

        if (isShowCheckLists) {
            this.ShowCheckLists(result,
                this._isFlowFolder && !this._screen().IsEditScreen,
                screenParams,
                flowFolderModel,
                action,
                record,
                nextLifeStatus);
            return;
        } else {
            this.ApproveLifeStatus({
                action,
                record,
                currentLifeStatus: result.Child.CurrentLifeStatus,
                nextLifeStatus: result.Child.LifeStatuses.find(lifestatus => lifestatus.Id === targetContext.Id)
            });
        }
    }

    private ShowCheckLists(result: LifeStatusesGeneralModel,
                           isFlowFolder: boolean,
                           screenParams,
                           flowFolderModel: FlowFolderModel,
                           action: ActionModel,
                           record: RecordModel,
                           nextLifeStatus: LifeStatusSimpleModel) {
        const actionSubject = result.Child.ActionSubjectRecord;
        const checkLists = result.Child.CheckLists;

        this._checkList = new ActionCheckList({
            EntityId: actionSubject.EntityId,
            RecordId: actionSubject.RecordId,
            FollowUpMode: true,
            CurrentLifestatus: result.Child.CurrentLifeStatus,
            KanbanMod: true
        });
        this._checkList.On('KANBAN_CHECKLIST_CANCEL',
            this,
            eventArgs => {
                this.LoadStatusesForAllRecords();
            });
        this._checkList.On('KANBAN_CHECKLIST_SAVE',
            this,
            eventArgs => {

                const checkItemsValue: ICheckItemSerializedValue[] = eventArgs.data.serializedData[0].CheckItems;

                if(nextLifeStatus.ApprovalName === 'Password' || nextLifeStatus.ApprovalName === 'Yes/No'){
                    this.ApproveLifeStatus({
                        action,
                        record,
                        currentLifeStatus: result.Child.CurrentLifeStatus,
                        nextLifeStatus: nextLifeStatus,
                        checkItems: checkItemsValue
                    });
                } else {
                    this.UpdateStatus({action, record, newStatusId: nextLifeStatus.Id, checkItems: checkItemsValue, password: null});
                }
            });

        this._checkList.ProvideData(checkLists);
        this._checkList.InitFlowFolder(isFlowFolder, screenParams.EntityId, flowFolderModel);
        this._checkList.Show();
    }

    ApproveLifeStatus({action, record, currentLifeStatus, nextLifeStatus, checkItems}: {
        action: ActionModel;
        record: RecordModel;
        currentLifeStatus: LifeStatusSimpleModel;
        nextLifeStatus: LifeStatusSimpleModel;
        checkItems?: ICheckItemSerializedValue[];
    }) {
        if (nextLifeStatus.ApprovalName === 'Password') {
            const passwordPrompt = new PromptDialog(
                {
                    Type: Types.Password,
                    Label: CONFIRMATIONS.PLEASE_TYPE_YOUR_PASSWORD_TO_CONFIRM,
                    Value: '',
                    MinHeight: 200,
                    Required: true,
                    RequiredErrorMessage: LABELS.PASSWORD + ' ' + NOTIFICATIONS.IS_REQUIRED,
                    ShowNotification: false,
                    CloseOnSave: false
                });
            passwordPrompt.Show();

            passwordPrompt.On('Save', this,
                (eventargs) => {
                    const password = eventargs.data.value;
                    if (password) {
                        BlockUI.Block();

                        FollowUpStore.ConfirmPassword({password: password})
                            .always(() => {
                                BlockUI.Unblock();
                            })
                            .then((response) => {
                                if (response) {
                                    passwordPrompt.Hide(false);
                                    this.UpdateStatus({action, record, newStatusId: nextLifeStatus.Id, checkItems: (checkItems && checkItems.length ? checkItems : null), password});
                                } else {
                                    passwordPrompt.ExternalInvalidActionHandling(NOTIFICATIONS.INCORRECT_PASSWORD);
                                    this.SaveCheckList();
                                    this.LoadStatusesForAllRecords();
                                }
                            })
                            .fail(error => {
                                new Notifier().Failed(error);
                            });
                    }
                }
            );

            passwordPrompt.On('Cancel', this, () => {
                this.SaveCheckList();
                this.LoadStatusesForAllRecords();
            });
        } else if (nextLifeStatus.ApprovalName === 'Yes/No') {
            const confirmationText = CONFIRMATIONS.ARE_SURE_TO_CHANGE_LIFESTATUS
                .replace('{fromstatus}', currentLifeStatus.Name)
                .replace('{tostatus}', nextLifeStatus.Name);

            const confirmationDialog = new ConfirmationDialog({
                Text: confirmationText,
                Type: ConfirmationTypes.Question
            });

            confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED,
                this,
                () => {
                    this.UpdateStatus({action, record, newStatusId: nextLifeStatus.Id, checkItems: (checkItems && checkItems.length ? checkItems : null)});
                });

            confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.DISCARD_SELECTED,
                this,
                () => {
                    this.SaveCheckList();
                    this.LoadStatusesForAllRecords();
                });

            confirmationDialog.Show();
        } else {
            this.UpdateStatus({action, record, newStatusId: nextLifeStatus.Id});
        }
    }

    SaveCheckList(){
        if (this._checkList){
            this._checkList.SaveCheckList();
        }
    }

    UpdateStatus({action, record, newStatusId, checkItems, password}: {
        action: ActionModel;
        record: RecordModel;
        newStatusId: number;
        checkItems?: ICheckItemSerializedValue[]
        password?: string
    }) {
        const groupEl = this._element.querySelector<HTMLDivElement>(`[data-record-id="${record.RecordId}"]`);
        BlockUI.Block({Target: groupEl});

        const requestModel: IUpdateActionStatusRequestModel = {
            SubjectEntityId: record.EntityId,
            SubjectRecordId: record.RecordId,
            ActionsEntityId: action.ActionsEntityId,
            ActionRecordId: action.ActionRecordId,
            PreviousLifeStatusId: action.ActionLifestatusId,
            NewStatusId: newStatusId,
            Users: action.Users
        }

        if(password){
            requestModel.ConfirmationPassword = password;
        }

        if (checkItems && checkItems.length) {
            requestModel.CheckItems = checkItems;
        }

        KanbanStore.UpdateActionStatus(requestModel)
            .fail(error => {
                new Notifier().Failed(error.message);
            })
            .always(() =>
                this.LoadStatusesForAllRecords()
                    .always(() => {
                        BlockUI.Unblock(groupEl);
                    })
            );
    }

    HeaderHeightResize() {
        const todoKanbanHtml = $(this._elObservable()).find('.js-todo-kanban');
        const unbindResize = ResizeService.SubscribeWidth(this.OnResize, todoKanbanHtml[0]); // subscribe on resize

        ko.utils.domNodeDisposal.addDisposeCallback(todoKanbanHtml[0], () => {
            unbindResize();
            window.removeEventListener("scroll", this.RepositionToDoKanbanHeaderOnScroll, false)
        });
        window.addEventListener("scroll", this.RepositionToDoKanbanHeaderOnScroll, false);
    }

    RepositionToDoKanbanHeaderOnScroll = () => {
        const kanbanContainer = $(this._elObservable()).find('.js-todo-kanban').find('.kanban-container');
        if (!kanbanContainer.hasClass('todo-kanban')) {
            window.removeEventListener("scroll", this.RepositionToDoKanbanHeaderOnScroll, false);
            return false;
        }
        const scrollTop = $(window).scrollTop();
        const titleElement: HTMLDivElement = this._elObservable().querySelector(".todo-kanban > .todo-kanban__container > .todo-kanban__titles");
        const toDoKanbanContainer: HTMLDivElement = this._elObservable().querySelector(".todo-kanban > .todo-kanban__container");
        const titleElementHeight = $(titleElement).outerHeight();
        const boundingRect = $(this._elObservable()).find('.todo-kanban')[0].getBoundingClientRect();
        const positionKanbanBoardRoot = $(this._elObservable()).find('.todo-kanban').offset().top + 6;
        let fixedHeaderHeight: number = 0;
        let calculate: number = 0;

        toDoKanbanContainer.style.paddingTop = titleElementHeight + 'px';

        if (window.innerWidth > 991) {
            const headerElement: HTMLDivElement = document.querySelector("body > .page-header");
            fixedHeaderHeight = headerElement ? headerElement.offsetHeight : 0;
        }

        if ((boundingRect.top) < fixedHeaderHeight) {
            calculate = Math.abs((positionKanbanBoardRoot - (scrollTop + fixedHeaderHeight + 3)));
        }

        if ((boundingRect.bottom - fixedHeaderHeight - titleElementHeight) <= 0) {
            return false;
        }
        titleElement.style.top = calculate + "px";
    }

    OnResize = () => {
        this.RepositionToDoKanbanHeaderOnScroll();
    };

    Accepts(el: HTMLElement, target: HTMLElement, source: HTMLElement) {
        const targetData: StepModel = ko.dataFor(target);
        const sourceData: StepModel = ko.dataFor(source);

        return this._status().Links.some(link => sourceData.Id === link.IdStepFrom && targetData.Id === link.IdStepTo);
    }



    Moves(el: HTMLElement, target: HTMLElement, source: HTMLElement) {
        const targetData: StepModel = ko.dataFor(target);
        const sourceData: StepModel = ko.dataFor(source);
        const record = ko.dataFor(el) as ActionModel;

        return !record.IsDisabled;
    }

    IsCardInvalid(el: HTMLElement) {
        return el.classList.contains('todo-kanban__retired-card');
    }

    OnDrag({recordId}: { recordId: number }, action: ActionModel) {
        this._draggingFrom({recordId, lifestatusId: action.ActionLifestatusId});
    }

    OnDragEnd() {
        this._draggingFrom({recordId: undefined, lifestatusId: undefined});
    }

    SelectAction(record: RecordModel) {
        const todoEntityId = this._todoEntityId();
        const parentTypeId = this.SubjectEntity === todoEntityId ? record.TypeId : 0;
        const typeScreen = new TypeScreen(todoEntityId, parentTypeId, false, false, null, [{Id: this._todoTypeId()}]);
        typeScreen
            .On('TYPE_SELECTED', this, eventArgs => this.NewRecord(record, eventArgs.data))
            .On('TYPES_NOT_FOUND', this, (eventArgs) => new Notifier($(this._elObservable())).Warning(eventArgs.data.Message || NOTIFICATIONS.SUB_TYPE_NOT_FOUND));
        typeScreen.Show();
    }

    private async NewRecord(record: RecordModel, newRecordSettings: any, dataMode: DataModes = DataModes.Default, linkToSource: boolean = false, aliasSuffix: string = '') {
        const actionEntityId = newRecordSettings.EntityId;
        const typeId = newRecordSettings.TypeId;
        const kindId = newRecordSettings.KindId;
        const exampleRecordId = newRecordSettings.ExampleRecordId;

        if (actionEntityId) {
            BlockUI.Block();

            const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

            screenManager.GetEditScreen({
                EntityId: actionEntityId,
                TableTypeId: typeId,
                KindId: kindId,
                RecordId: exampleRecordId,
                LoadAsExample: exampleRecordId > 0,
                DataMode: dataMode,
                AliasSuffix: aliasSuffix
            }).always(() => {
                BlockUI.Unblock();
            }).fail(error => {
                let notifier = new Notifier();
                notifier.Warning(error.message);
            }).then((screen) => {
                let editScreen = screen as EditScreen;

                editScreen.On('LINK_LIST_DATA_LOADED', this, eventArgs => {
                    if (dataMode === DataModes.Default) {
                        let linkListControl = eventArgs.data.Control as LinkList;
                        const relationModel = _.find(linkListControl.DataModel().Entities(), relatedEntity => {
                            return relatedEntity.EntityId === record.EntityId;
                        });

                        if (relationModel) {
                            let newRelation = new NewRelationModel();
                            newRelation.Name = record.LinkName;
                            newRelation.Id = record.RecordId;
                            newRelation.IsMain = true;
                            newRelation.TypeName = record.TypeName;
                            linkListControl.AddRecord(record.RecordId, relationModel, newRelation);
                        }
                    }

                });
                editScreen.IsDataFromExample = exampleRecordId > 0;
                editScreen.SaveImmediately = true;
                editScreen.LinkToSource = linkToSource;

                editScreen.On('RECORD_SAVED', this, () => {
                    const groupEl = this._element.querySelector<HTMLDivElement>(`[data-record-id="${record.RecordId}"]`);
                    BlockUI.Block({Target: groupEl});
                    
                    this.LoadStatusesForAllRecords()
                        .fail(error => {
                            new Notifier().Failed(error);
                        })
                        .always(() => {
                            BlockUI.Unblock(groupEl);
                        });
                });
                editScreen.On('COPY', this, (eventArgs) => {
                    const copyTableType = eventArgs.data.copyToType || typeId;
                    this.NewRecord(record, {
                        EntityId: actionEntityId,
                        TypeId: copyTableType,
                        ExampleRecordId: eventArgs.data.recordId
                    }, eventArgs.data.dataMode, eventArgs.data.linkToSource, eventArgs.data.aliasSuffix);
                });
                editScreen.ShowInModal();
            });
        } else {
            var notifier = new Notifier($(this._elObservable()));
            notifier.Failed(NOTIFICATIONS.GRID_SUBJECT_NOT_FOUND);
        }
    }

    private GetEditScreenType(): ScreenTypes {
        return ScreenTypes.EditScreen;
    }

    private GetScreenTypeFromProperty(propertyName: string): ScreenTypes {
        const screenTypeName = this._generalProperties.GetPropertyValue(propertyName);
        if (screenTypeName) {
            if (screenTypeName.Value === 'ConsultScreen') {
                return ScreenTypes.ConsultScreen;
            }

            if (screenTypeName.Value === 'EditScreen') {
                return ScreenTypes.EditScreen;
            }
        }

        return ScreenTypes.EditScreen;
    }

    OpenActionRecordScreen(record: RecordModel, action: ActionModel, onlyEditScreen: boolean) {
        const followUpRecord = new FollowUpRecordModel();
        followUpRecord.Actions = record.Actions;
        this.OpenScreen(
            action.ActionsEntityId,
            action.ActionTypeId,
            action.ActionRecordId,
            followUpRecord,
            DataModes.Default,
            record,
            onlyEditScreen ? ScreenTypes.EditScreen : this.GetScreenTypeFromProperty(PROPERTIES.ACTION_SCREEN_TYPE)
        );
    }

    private async OpenScreen(
        entityId: number,
        recordTypeId: number,
        recordId: number,
        record: FollowUpRecordModel,
        mode: DataModes,
        activeCard: RecordModel,
        screenType: ScreenTypes,
        openMailTab?: boolean
    ) {
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

        BlockUI.Block();

        if (screenType === ScreenTypes.EditScreen) {
            LockManager.Instance.TryLock(entityId, recordId)
                .then(() => {
                    screenManager.IsTypeTransformationRequired(entityId, recordId)
                        .then((result: any) => {
                            if (result) {
                                const typeScreen = new TypeScreen(entityId, recordTypeId, true, false);

                                typeScreen.On("TYPE_SELECTED",
                                    this,
                                    (eventArgs) => {
                                        UserVarsManager.Instance.RemoveFromRecent(recordId, entityId, recordTypeId);

                                        const typeId = eventArgs.data.TypeId;
                                        const typeName = eventArgs.data.TypeName;

                                        UserVarsManager.Instance.AddRecent(entityId, recordId, typeId);

                                        this.ShowEditScreen(entityId, typeId, recordId, record, mode, activeCard, typeName, openMailTab)
                                    });

                                typeScreen.Show();
                            } else {
                                this.ShowEditScreen(entityId, recordTypeId, recordId, record, mode, activeCard, null, openMailTab)
                            }
                        });
                })
                .fail(() => BlockUI.Unblock());
        } else if (screenType === ScreenTypes.ConsultScreen) {
            screenManager.GetScreenByScreenType(
                entityId,
                screenType,
                recordId
            )
                .always(() => {
                    BlockUI.Unblock();
                })
                .then(screen => {
                    screen.ShowInModal();

                    if (openMailTab) {
                        this.OpenMailTab(screen);
                    }
                })
                .fail(err => new Notifier().Warning(err.message));
        }
    }

    private async ShowEditScreen(
        entityId: number,
        recordTypeId: number,
        recordId: number,
        record: FollowUpRecordModel,
        mode: DataModes,
        activeCard: RecordModel,
        typeName: string = null,
        openMailTab?: boolean
    ) {
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

        BlockUI.Block();
        screenManager.GetEditScreen({
            EntityId: entityId,
            TableTypeId: recordTypeId,
            TableTypeName: typeName,
            RecordId: recordId,
            DataMode: mode
        }).always(() => {
            BlockUI.Unblock();
        })
            .then(screen => {
                LockManager.Instance.On(LOCK_EVENTS.RELEASED, this, (eventArgs) => {
                    if (eventArgs.data.TableId === entityId && eventArgs.data.RecordId === recordId) {
                        screen.Close();
                    }
                });

                screen.On('MODAL_CLOSE',
                    this,
                    () => {
                        LockManager.Instance.ReleaseLock(entityId, recordId);
                    });

                screen.On('RECORD_SAVED',
                    this,
                    () => {
                        const notifier = new Notifier($(this._elObservable()));
                        notifier.Success(NOTIFICATIONS.RECORD_SAVED);
                        this.LoadStatuses();
                        LockManager.Instance.ReleaseLock(entityId, recordId);
                    });

                screen.On("RECORD_REMOVED",
                    this,
                    (eventArgs) => {
                        const notifier = new Notifier($(this._elObservable()));
                        notifier.Success(NOTIFICATIONS.RECORD_REMOVED);

                        const editScreen = screen as EditScreen;
                        editScreen.Close();

                        LockManager.Instance.ReleaseLock(entityId, recordId);
                        this.LoadStatuses();
                    });

                screen.On('COPY', this, (eventArgs) => {
                    const copyTableType = eventArgs.data.copyToType || recordTypeId;
                    this.NewRecord(activeCard, {
                        EntityId: entityId,
                        TypeId: copyTableType,
                        ExampleRecordId: eventArgs.data.recordId
                    }, eventArgs.data.dataMode, eventArgs.data.linkToSource, eventArgs.data.aliasSuffix);
                });

                const followUpControl = screen.GetControlByType(CONTROL_TYPES.ButtonFollowUp) as ButtonFollowUp;

                if (followUpControl) {
                    followUpControl.SetRecord(record);

                    screen.On("FOLLOWUP_RECORD",
                        this,
                        (eventArgs) => {
                            screen.Close();

                            const followUpRecordModel = followUpControl.GetRecord();
                            const currentLifestatus = followUpRecordModel ? followUpRecordModel.CurrentLifeStatus : null;

                            BlockUI.Block();
                            if (followUpRecordModel && (followUpRecordModel.LifeStatusSort === LIFE_STATUS_GROUPS.RETIRED
                                || followUpRecordModel.LifeStatusNoActionNode
                                || (currentLifestatus
                                    && (currentLifestatus.FollowupModeName === FollowupModes.DIRECT_STATUS_UPDATE
                                        || currentLifestatus.FollowupModeName === FollowupModes.EDIT_CURRENT)))) {
                                BlockUI.Unblock();
                                return;
                            }

                            screenManager.GetEditScreen({
                                EntityId: entityId,
                                TableTypeId: recordTypeId,
                                RecordId: recordId,
                                LoadAsExample: recordId > 0,
                                DataMode: DataModes.FollowUp,
                                SubjectLifestatusId: followUpRecordModel ? followUpRecordModel.LifeStatusId : null
                            }).always(() => {
                                BlockUI.Unblock();
                            })
                                .then((screen: EditScreen) => {
                                    this.EditScreenInFollowUpMode(screen);
                                }).fail(error => {
                                new Notifier($(this._elObservable())).Warning(error.message);
                            });
                        });
                }

                screen.ShowInModal();

                if (openMailTab) {
                    this.OpenMailTab(screen);
                }
            }).fail(err => new Notifier().Warning(err.message));
    }

    private EditScreenInFollowUpMode(editScreen: EditScreen) {
        LockManager.Instance.ReleaseAllLocks();

        editScreen.IsDataFromExample = true;
        editScreen.UseLinking = true;

        editScreen.On('FOLLOWUP',
            this,
            (eventArgs) => {
                LockManager.Instance.ReleaseAllLocks();
                this.LoadStatuses();
            });

        editScreen.On("RECORD_SAVED",
            this,
            (eventArgs) => {
                const notifier = new Notifier($(this._elObservable()));
                notifier.Success(NOTIFICATIONS.RECORD_CREATED);
                this.LoadStatuses();
                LockManager.Instance.ReleaseAllLocks();
            });

        editScreen.On("RECORD_REMOVED",
            this,
            (eventArgs) => {
                const notifier = new Notifier($(this._elObservable()));
                notifier.Success(NOTIFICATIONS.RECORD_REMOVED);

                LockManager.Instance.ReleaseAllLocks();
                this.LoadStatuses();
            });

        editScreen.ShowInModal();
    }

    private OpenMailTab(screen: IScreen) {
        const tab = screen.GetControl<Tab>(CONTROL_TYPES.Tab);

        if (tab) {
            for (const tabPage of tab.GetSubControls() as TabPage[]) {
                const grid = tabPage.GetSubControls().find(control => control.GetType() === CONTROL_TYPES.Grid);
                if (grid && grid.GetModel().EntityId === this._status().MailEntityId) {
                    tab.SetActiveTabPage(tabPage);
                    break;
                }
            }
        }
    }

    IsDraggingCssClassApplied({
                                  recordId,
                                  lifestatusId,
                                  isForAllow
                              }: {
        recordId: number;
        lifestatusId: number;
        isForAllow: boolean;
    }) {
        const draggingFrom = this._draggingFrom();

        if (!draggingFrom.recordId || recordId !== draggingFrom.recordId) {
            return false;
        }

        const isAllowed = this._status().Links.some(
            link => draggingFrom.lifestatusId === link.IdStepFrom && lifestatusId === link.IdStepTo
        );

        return isForAllow ? isAllowed : !isAllowed;
    }

    OnPriorityUpdateError(recordId: number) {
        const groupEl = this._element.querySelector<HTMLDivElement>(`[data-record-id="${recordId}"]`);
        BlockUI.Block({Target: groupEl});
        
        this.LoadStatusesForAllRecords()
            .fail(error => {
                new Notifier().Failed(error);
            })
            .always(() => {
                BlockUI.Unblock(groupEl);
            });
    }

    OnMailClick({record, action}: { record: RecordModel, action: ActionModel }) {
        const followUpRecord = new FollowUpRecordModel();
        followUpRecord.Actions = record.Actions;
        this.OpenScreen(
            action.ActionsEntityId,
            action.ActionTypeId,
            action.ActionRecordId,
            followUpRecord,
            DataModes.Default,
            record,
            this.GetScreenTypeFromProperty(PROPERTIES.ACTION_SCREEN_TYPE),
            true
        );
    }

    OpenConsultScreen(_, data, evt) {
        PubSub.publish(PUB_SUB_EVENTS.GO_TO_CONSULT_SCREEN, {
            EntityId: data.record.EntityId,
            RecordId: data.record.RecordId,
            TypeId: data.record.TypeId,
            IsOpenInModal: true
        });
    }

    GetParentOrChildRecordTemplate(_TodoKanban, recordModel) {
        return $((ko as any).renderTemplateX(TodoParentOrChildRecordTemplate, {$_data: _TodoKanban, record: recordModel}));
    }

    async OpenRecord(_TodoKanban, RecordModel, ParentOrChildRecord){
        BlockUI.Block();
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

        screenManager.GetScreenByScreenType(
            _TodoKanban.SubjectEntity,
            ScreenTypes.ConsultScreen,
            ParentOrChildRecord.Id
        )
            .always(() => {
                BlockUI.Unblock();
            })
            .then((screen: any) => {
                screen.ShowInModal();

                if (screen.GetType() === ScreenTypes[ScreenTypes.ConsultScreen]) {
                    PathRunner.Instance.GetUnit(screen.GetEntityId(), screen.IsSpecialScreenExist).LoadNewData(screen.GetRecordId());
                }
            })
            .fail((err) => { new Notifier().Failed(err.message); });
    }

    GetParentOrChildRecordName(nameTranslation?: string, name?: string): string {
        return nameTranslation ? nameTranslation : (name ? name : '-');
    }

    GetParentOrChildRecordTypeName(typeNameTranslation?: string, typeName?: string): string {
        return typeNameTranslation ? typeNameTranslation : typeName;
    }

    GetTitle(IsChild: boolean, IsParent: boolean) {
        if(IsChild && IsParent) {
            return this._labels.ITSELF;
        }
        else if(IsChild) {
            return this._labels.CHILD;
        }
        else {
            return this._labels.PARENT;
        }
    }

    GetIcon(IsChild: boolean, IsParent: boolean) {
        if(IsChild && IsParent) {
            return 'fa-retweet';
        }
        else if(IsChild) {
            return 'fa-level-down';
        }
        else {
            return 'fa-level-up';
        }
    }

    private get UseKanbanSubject() {
        return !!this._kanbanSubjectId();
    }

    private get UseFilterByEntityRecord() {
        return this._screen().IsConsultScreen;
    }

    private get SubjectEntity() {
        return this.UseKanbanSubject
            ? this._kanbanSubjectId()
            : this._screen().GetEntityId();
    }

    private get FilterByEntityId() {
        return this.UseFilterByEntityRecord
            ? this._screen().GetEntityId()
            : null;
    }

    private get FilterByRecordId() {
        return this.UseFilterByEntityRecord
            ? this._screen().GetRecordId()
            : null;
    }

    MouseOver(typeText: string, recordModel: ParentOrChildRecordModel, evt: any){
        let currentEl = $(evt.currentTarget);

        if (Math.round(currentEl.prop('scrollWidth')) > Math.round(currentEl.outerWidth()) ){
            let recordName = recordModel.NameTranslation ? recordModel.NameTranslation : (recordModel.Name ? recordModel.Name : '-');
            let recordTypeName = recordModel.TypeNameTranslation ? recordModel.TypeNameTranslation : recordModel.TypeName;
            let isTypeName = (typeText === 'isTypeName');

            let typeNameTooltipOptions = {
                    position: {x: 'left', y: 'center'},
                    outside: 'x',
                    zIndex: 500001
                },
                nameTooltipOptions = {
                    position: {x: 'right', y: 'center'},
                    outside: 'x',
                    zIndex: 500001
                };

            this._tooltip = new Tooltip(evt.currentTarget,
                ko.unwrap(isTypeName ? recordTypeName : recordName),
                isTypeName ? typeNameTooltipOptions : nameTooltipOptions);
            this._tooltip.Show(evt.currentTarget);
        }
    }

    MouseOut(data, evt: any){
        if (this._tooltip){
            this._tooltip.Destroy();
        }
    }

    OpenEditScreen(_, data, evt) {
        this.OpenScreen(data.record.EntityId,
            data.record.TypeId,
            data.record.RecordId,
            null,
            DataModes.Default,
            data.record,
            this.GetEditScreenType());
    }

    AfterRenderSubject(elements: HTMLElement[]) {
        const subjectContainer = elements[1];
        const childrenCount = subjectContainer.parentElement.children.length;
        const requiredCount = this._recordsWithTodoActions().length;

        if (childrenCount === requiredCount) {
            BlockUI.Unblock();
        }
    }
    AfterRender(el: Array<HTMLElement>){
        this._elObservable(el[0].parentElement.parentElement.parentElement);
        this.ListenForStatusesLoad();

        ko.utils.domNodeDisposal.addDisposeCallback(el[0], () => {
            PubSub.unsubscribe('KANBAN_FULLSCREEN');
        });
    }

    GetRootToDoTemplateName(): string {
        return 'Core/Controls/Kanban/Templates/Todo/TodoKanban';
    }

    dispose() {
        this._selectedTypeSubscription.dispose();
        this._selectedUsersSubscription.dispose();
        this._showPlannedSubscription.dispose();
        this._viewIdSubscription.dispose();
    }
}

ko.components.register('todo-kanban', {
    viewModel: {
        createViewModel: (params: TodoKanbanParams, componentInfo) =>
            new TodoKanban({...params, element: componentInfo.element as HTMLElement})
    },
    template: `<div class="js-todo-kanban__component" data-bind="template: { name: GetRootToDoTemplateName.bind($data), afterRender: $data.AfterRender.bind($data) }"></div>`
});
