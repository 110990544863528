//Store models
import {LookupValueResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Common/Response/LookupValueResponseModel";

//View models
import {LookupValue} from "Core/Components/Controls/TimeWriting/Models/View/DayDataViewModel"

export class LookupValueMappings {
    static OnViewModel(model: LookupValueResponseModel): LookupValue {
        const viewModel = new LookupValue();

        viewModel.FieldValue = model.Id;
        viewModel.DisplayValue = model.Value;

        return viewModel;
    }
}