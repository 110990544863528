import { LifeStatusSimpleModel } from 'Core/Controls/ButtonFollowUp/Models/LifestatusSimpleModel';
import * as ko from "knockout";

import {JBoxDropDown} from 'Core/Components/JBoxDropdown/DropDown';
import {BaseGrid} from "Core/Controls/Grid/BaseGrid/BaseGrid";
import {EVENTS as GRID_EVENTS} from "Core/Controls/Grid/BaseGrid/Events";
import {Icon} from "Core/Icon/Icon";
import {Event} from "Core/Common/Event";
import {EVENTS} from "Core/Controls/Grid/BaseGrid/QueryResultPage/Events";
import {PivotDetailModel} from "Core/Controls/Grid/Models/PivotDetailModel";
import {FONT_NAME} from "Core/Constant";
import {LABELS} from "Core/Components/Translation/Locales";
import {ZIndexManager} from 'Core/Common/ZIndexManager';

import Template from 'Core/Components/ProgressBar/ActionDropdown/Templates/ActionDropdown.html';
ko.templates['Core/Components/ProgressBar/ActionDropdown/Templates/ActionDropdown'] = Template;

export class ActionDropdown extends Event {
    private readonly _id: string;
    private _dropDown: JBoxDropDown;
    private _labels = LABELS;

    constructor(private _processCardsLifeStatuses: Array<LifeStatusSimpleModel>, private _showStatusSelector: boolean){
        super();
        this._id = JBoxDropDown.GetDropDownId();
        this.AddEvent('SHOW_STATUS_SELECTOR');
        this.AddEvent(EVENTS.RECORD_SELECTED);
    }

    Close(){
        if(this._dropDown){
            this._dropDown.Close();
        }
    }

    SelectLifeStatus(status: LifeStatusSimpleModel){
        this.Trigger(EVENTS.RECORD_SELECTED, { status: status });
    }

    ShowStatusSelector(){
        this.Trigger('SHOW_STATUS_SELECTOR');
    }

    Show(target: any){
        this._dropDown = new JBoxDropDown({
            target: target || "." + this._id,
            otherOptions: {
                attach: undefined,
                closeOnClick: 'body',
                position: {
                    x: "left",
                    y: "bottom"
                },
                onCloseComplete: () => this._dropDown.Destroy(),
                zIndex: ZIndexManager.Instance.NextValue,
                blockScroll: true,
                addClass: 'lookup-dropdown',
                maxWidth: "calc(100%)"
            },
            onOpen: () => {
                this._dropDown.SetContent({ content: Template as any });
            },
            bindComponent: this,
        });

        this._dropDown.Open();
    }

    FormatDisplayValue(status: LifeStatusSimpleModel){
        return _.isEmpty(status.TranslatedName) ? status.Name : status.TranslatedName;
    }
}