import {deserialize, deserializeAs, serialize, serializeAs} from "libs/cerialize";
import {IconModel} from "Core/Controls/BaseControl/Models/IconModel";

export class SelectedEntityModel {
    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    Translation: string;

    @deserializeAs(IconModel)
	Icon: IconModel;
}