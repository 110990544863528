import {deserialize, deserializeAs} from 'libs/cerialize';
import {EntityModel} from 'QueryBuilder/Models/EntityModel'

export class RelatedSubEntitiesModel {
	@deserializeAs(EntityModel)
	Entities: Array<EntityModel>;

	constructor() {
		this.Entities = [];
	}
}