import * as ko from 'knockout';

import {RenderModes} from 'Core/Constant';

import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';

import {Paginator} from 'Core/Components/Paginator/Paginator';
import {EVENTS as PAGINATOR_EVENTS} from 'Core/Components/Paginator/Constants';
import {EVENTS as CONSULT_SCREEN_EVENTS} from 'Core/Screens/ConsultScreen/Events';

import {IControlParam} from 'Core/Screens/IScreen';
import {BaseControl, IControlValue} from '../BaseControl/BaseControl';
import {RecordLinker} from 'Core/Components/RecordLinker/RecordLinker';

import BookConfigJson from 'Core/Controls/Book/Configs/book-config.json';
import {GeneralProperties} from 'Core/GeneralProperties/GeneralProperties';

import {BookStore} from './Store/BookStore';
import {PageResponse} from './Store/Models/PageResponse';

import ViewTemplate from "Core/Controls/Book/Templates/View.html";
import HelpViewTemplate from "Core/Controls/Book/Templates/HelpView.html";
import ToolBarTemplate from "Core/Controls/Book/Templates/ToolBar.html";
import DesignTemplate from "Core/Controls/Book/Templates/Design.html";

ko.templates['Core/Controls/Book/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Book/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/Book/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Book/Templates/Design'] = DesignTemplate;

export class Book extends BaseControl {
    private _store: BookStore;
    private _bookId: number;
    private _dataLoading: boolean;

    private _notShownReason: KnockoutObservable<string>;
    private _currentPage: KnockoutObservable<any>;
    private _paginator: Paginator;

    constructor(params: IControlParam) {
        super(params, BookConfigJson);

        if (this._renderMode() === RenderModes.View) {
            this.InitRuntime();
        }
    }

    ApplyProperties(){}

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);

        if (this._bookId && !this._dataLoading) {
            this.LoadPage(1);
        }
    }

    SetValue(value: IControlValue) {
        this.InitialLoad();
    }

    private InitialLoad() {
        if (this.GetForm().GetScreen().NestedLevel > 1) {
            this._notShownReason(this._labels.BOOK_CANNOT_BE_RENDERED_ON_THE_BOOK_PAGE);
            return;
        }

        this._bookId = this.GetForm().GetScreen().GetRecordId();

        if (this._isRendered()) {
            this._dataLoading = true;
            this.LoadPage(1);
        }
    }


    private InitRuntime() {
        this._store = new BookStore(this.GetForm().GetScreen().GetEntityId(), this.GetModel().EntityId);
        this._currentPage = ko.observable(null);
        this._notShownReason = ko.observable(null);

        this._paginator = new Paginator(-1, true);
        this._paginator.PageNumber = 1;
        this._paginator.RecordsPerPage = 1;

        this.BindRuntimeEvents();
    }

    private BindRuntimeEvents() {
        this._paginator.On(PAGINATOR_EVENTS.CHANGE, this, () => this.LoadPage(this._paginator.PageNumber));
    }

    private LoadPage(pageNumber: number, recordId: number = 0) {
        BlockUI.Block({Target: this._el});

        this.DisposeCurrentPage();
        const pageLayout = this.GetPageLayout();

        this._store.GetPage(this._bookId, pageNumber, recordId, this.GetControlId(), pageLayout)
            .then(async page => {
                if (!page) {
                    this._notShownReason(this._labels.THERE_ARE_NO_PAGES)
                    return;
                }

                if (!page.Screen) {
                    this._notShownReason(this._labels.SCREEN_NOT_FOUND_FOR_THE_PAGE);
                    return;
                }

                this._notShownReason(null);
                await this.RenderPage(page);
            })
            .fail(error => {
                this._notShownReason(error.message)
            })
            .always(() => {
                BlockUI.Unblock(this._el);
                this._dataLoading = false;
            });
    }

    private DisposeCurrentPage() {
        if (this._currentPage()) {
            this._currentPage().Dispose();
            this._currentPage(null);
        }
    }

    GetBookClass(layoutProperty):string{
        let layout = layoutProperty;
        let blocksClass: string = ' ';

        if (layout.ActionBar.Checked && layout.BottomBar.Checked && layout.MainBlocks.Checked) {
            return 'bookFullBlocks';
        }
        if (layout.ActionBar.Checked){
            blocksClass += 'bookActionBar ';
        }
        if (layout.MainBlocks.Checked){
            blocksClass += 'bookMainBlocks ';
        }
        if (layout.BottomBar.Checked){
            blocksClass += 'bookBottomBar ';
        }
        return blocksClass;
    }

    get PageLayoutBlockClass(): string {
        return this.GetBookClass(this.GetPageLayout());
    }

    get DesignPageLayoutClass(): string {
        return this.GetBookClass(this.GeneralProperties.GetPropertyValue('Layout') || this.GeneralProperties.GetPropertyDefaultValue('Layout'));
    }

    private GetPageLayout() {
        return this.GeneralProperties.GetPropertyValue('Layout') || this.GeneralProperties.GetPropertyDefaultValue('Layout')
    }

    private async RenderPage(page: PageResponse) {
        page.Screen.IsNested = true;
        page.Screen.NestedLevel = this.GetForm().GetScreen().NestedLevel + 1;

        const screenFactory = (await import('Core/Screens/ScreenFactory')).ScreenFactory;
        const screen = screenFactory.GetScreenInstance(page.Screen);
        screen.IsOpenedViaBookControl = true;
        screen.SetIsReady(true);

        screen.On(CONSULT_SCREEN_EVENTS.LINK_BOOK_RECORD, this, (eventArgs) => {
            BlockUI.Block({Target: this._el});

            const mainScreen = this.GetForm().GetScreen();

            RecordLinker.LinkRecord({
                MainTableId: mainScreen.GetEntityId(),
                MainRecordId: mainScreen.GetRecordId(),
                LinkedTableId: eventArgs.data.EntityId,
                LinkedRecordId: eventArgs.data.RecordId
            })
                .always(() => {
                    BlockUI.Unblock(this._el);
                })
                .then(result => {
                    if (!result.IsSuccessfull) {
                        Notifier.Failed(result.ErrorMessage);
                        return;
                    }

                    if (result.Warnings.length > 0) {
                        result.Warnings.forEach(w => Notifier.Warning(w.replace("/n", "<br>")));
                    }

                    Notifier.Success(result.OperationMessage);
                    this.LoadPage(1, eventArgs.data.RecordId);
                });
        });

        this._currentPage(screen);
        this.SetPageNumberWithoutTriggeringChangeEvent(page.PageNumber);
        this._paginator.TotalRecords = page.NumberOfPages;
    }

    private SetPageNumberWithoutTriggeringChangeEvent(pageNumber: number) {
        this._paginator.SkipChangeTrigger = true;
        this._paginator.PageNumber = pageNumber;
        this._paginator.SkipChangeTrigger = false;
    }
}