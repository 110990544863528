import * as _ from 'underscore';

import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {GenericDeserialize} from 'libs/cerialize';
import { PersonalSettingsModel, PersonalSettingsUpdateModel } from 'Core/ProfilePage/PersonalSettings/Models/PersonalSettingsModel';

export class PersonalSettingsStore {
	static GetPersonalSettingsModel(): P.Promise<PersonalSettingsModel> {
		const deferredResult = P.defer<PersonalSettingsModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/GlobalsApi/GetUserGlobals',
				dataType: "json",
				type: "GET"
			}
		};

		Request.Send(requestParams)
			.then(globals => deferredResult.resolve(this.Deserialize(globals)))
			.fail(err => this.GetResponseError(err, 'Error getting personal settings'));

		return deferredResult.promise();
	}

	static UpdateGlobals(params: PersonalSettingsUpdateModel[]): P.Promise<void> {
		const deferredResult = P.defer<void>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/GlobalsApi/UpdateGlobals',
				type: "POST"
			}, params: {UserGlobals: params}
		};

		Request.Send(requestParams)
			.then(() => deferredResult.resolve(null))
			.fail(err => this.GetResponseError(err, 'Error updating personal settings'));

		return deferredResult.promise();
	}

	static Deserialize(jsonObject: any): PersonalSettingsModel {
		const model = GenericDeserialize<PersonalSettingsModel>(jsonObject, PersonalSettingsModel);
		return model;
	}

	private static GetResponseError(err: P.Rejection, generalError: string) {
		if (err.status === 400) {
			return JSON.parse(err.message).Message;
		}

		return generalError;
	}
}