import * as ko from "knockout"
import {BaseControl, IControlValue} from "Core/Controls/BaseControl/BaseControl"
import {SubForm} from "Core/Screens/Forms/SubForm/SubForm"
import {IControlParam} from "Core/Screens/IScreen"

import ViewTemplate from "Core/Controls/LifestatusDesigner/Templates/View.html"
import ToolBarTemplate from "Core/Controls/LifestatusDesigner/Templates/ToolBar.html"
import DesignTemplate from "Core/Controls/LifestatusDesigner/Templates/Design.html"

ko.templates["Core/Controls/LifestatusDesigner/Templates/ToolBar"] = ToolBarTemplate;
ko.templates["Core/Controls/LifestatusDesigner/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/LifestatusDesigner/Templates/Edit"] = ViewTemplate;
ko.templates["Core/Controls/LifestatusDesigner/Templates/Design"] = DesignTemplate;

export class LifestatusDesigner extends BaseControl {
	private _value: KnockoutObservable<string>;

	constructor(params: IControlParam) {
		super(params);
		this._value = ko.observable("");
		this.Init();
	}

	private Init(): void {
	}

	ApplyProperties(){}

	SetValue(value: IControlValue): void {
		if (value.Data) {
			this._value(value.Data.Value);
		}
	}

	AfterRender(el: Array<HTMLElement>): void {
		super.AfterRender(el);
	}
}