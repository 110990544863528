import {SECURITY_LEVELS} from 'FieldCollection/PriorityManager/PriorityManagerConstants';
import {IType} from "FieldCollection/Models/IFields";
import {IViewModelType, IViewModelField} from 'FieldCollection/Models/IViewModel';
import {NOTIFICATIONS, LABELS, CONFIRMATIONS} from "Core/Components/Translation/Locales";

import {Event} from 'Core/Common/Event';
import {
	ConfirmationDialog,
	EVENTS as CONFIRMATION_EVENTS,
	Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

export class PriorityManager extends Event {
	constructor() {
		super();
	};

	RestrictDowngrade(row: IViewModelField, initialCalculation: boolean, field?: IViewModelType,) {
		const highestField = row.types.filter((type) => type.isActiveType && !type.PertableType).pop();

		const targetField = field || highestField;

		if (!targetField) {
			this.Trigger('DowngradeDone', {Data: 'Success'});

			return;
		}

		if (!initialCalculation) {
			targetField.isActiveType = !targetField.isActiveType;
		}

		const roleType = targetField.TypeOfType;
		const deletedOtherFields = [];

		const types = row.types.filter((type: IViewModelType) => type.TypeOfType);

		types.forEach((type: IViewModelType) => {
			if (targetField.isActiveType && SECURITY_LEVELS[type.TypeOfType] < SECURITY_LEVELS[roleType]) {
				if (type.isActiveType) {
					deletedOtherFields.push(type);
				}
			}
		});

		function applyAvailability(types: IViewModelType[]) {
			types.forEach((type) => {
				if (targetField.isActiveType && SECURITY_LEVELS[type.TypeOfType] < SECURITY_LEVELS[roleType]) {
					type.isUnavailableField = true;
				} else if (!targetField.isActiveType && SECURITY_LEVELS[type.TypeOfType] < SECURITY_LEVELS[roleType]) {
					type.isUnavailableField = false;
				}
			});
		}

		if (deletedOtherFields.length) {
			targetField.isActiveType = false;

			const deletedFieldsString = deletedOtherFields.map((el) => el.Name).join(', ');

			const dialog = new ConfirmationDialog({
				Text: CONFIRMATIONS.FIELD_WILL_BE_REMOVED_FROM_NEXT_FIELD_COLLECTION.replace('{DeletedFields}', deletedFieldsString),
				Type: ConfirmationTypes.Warning,
				Width: 500,
				MinHeight: 160
			});

			dialog.On(CONFIRMATION_EVENTS.CONFIRM_SELECTED, this, () => {
				targetField.isActiveType = true;

				applyAvailability(types);

				this.Trigger('DowngradeDone', {Data: 'Success'});
			});

			dialog.On(CONFIRMATION_EVENTS.DISCARD_SELECTED, this, () => {
				this.Trigger('DowngradeDone', {Data: 'Success'});
			});

			dialog.Show();
		} else {
			applyAvailability(types);
		}

		this.Trigger('DowngradeDone', {Data: 'Success'});
	}

	SortTypes(types: IType[]) {
		if (!types) return null;

		types.sort((prev: IType, next: IType) => {
				let result = SECURITY_LEVELS[prev.TypeOfType] - SECURITY_LEVELS[next.TypeOfType];

				if (!result) {
					result = prev.Name.toUpperCase() > next.Name.toUpperCase() ? 1 : -1;
				}

				return result;
			}
		);

		return types;
	}
}