import { P } from "Core/Common/Promise";
import { Request, IRequestOptions } from "Core/Common/Request";

import { IPathRunnerRequestModel } from "Core/Components/PathRunner/Models/Request/IPathRunnerRequestModel";
import { PathRunnerResponseModel } from "Core/Components/PathRunner/Models/Response/PathRunnerResponseModel";
import { PathRunnerRecordModel } from "Core/Components/PathRunner/Models/Response/PathRunnerRecordModel";

export class PathRunnerStore {
	static GetData(requestModel: IPathRunnerRequestModel): P.Promise<PathRunnerResponseModel> {
		const deferredResult = P.defer<PathRunnerResponseModel>();

		const requestOptions: IRequestOptions = {
			proxy: {
				url: 'api/PathRunnerApi/GetData',
				type: 'GET',
				dataType: 'json'
			},
			params: requestModel
		}

		Request.Send(requestOptions)
			.then(response => this.ResolveResponse(response, deferredResult))
			.fail(() => this.ResolveFail(deferredResult, "Error getting path runner data"));

		return deferredResult.promise();
	}

	static GetLinkedRecords(requestModel: IPathRunnerRequestModel): P.Promise<PathRunnerRecordModel[]> {
		const deferredResult = P.defer<PathRunnerRecordModel[]>();

		const requestOptions: IRequestOptions = {
			proxy: {
				url: 'api/PathRunnerApi/GetLinkedRecords',
				type: 'GET',
				dataType: 'json'
			},
			params: requestModel
		}

		Request.Send(requestOptions)
			.then(response => this.ResolveResponse(response, deferredResult))
			.fail(() => this.ResolveFail(deferredResult, "Error getting linked records"));

		return deferredResult.promise();
	}

	private static ResolveResponse(response, deferredResult: P.Deferred<any>) {
		if (response.IsSuccessfull) {
			deferredResult.resolve(response.ResultList || response.ResultObject);
			return;
		}

		this.ResolveFail(deferredResult, response.ErrorMessage);
	}

	private static ResolveFail(deferredResult: P.Deferred<any>, errorMessage: string) {
		const error = { message: errorMessage };
		deferredResult.reject(error);
	}
}