import * as _ from "underscore";
import * as ko from "knockout";

import {deserialize} from 'libs/cerialize';
import {deserializeAs} from 'libs/cerialize';
import {TableModel} from 'MenuManager/MenuAreas/Models/TableModel';
import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

export class DesignedScreenModel {
    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    TypeId: number;

    @deserialize
    TypeName: string;

    @deserialize
    TableTypeId: number;

    @deserializeAs(TableModel)
    Table: TableModel;

    IsSelected: KnockoutObservable<boolean>;

    @enumerable
    public get TranslatedName() {
        return this.Table.TranslatedName || this.Table.Name;
    }

    constructor() {
        this.IsSelected = ko.observable(false);
    }
}