import {deserializeAs} from 'libs/cerialize';
import {UserVarsRecordModel} from 'Core/Screens/SearchScreen/Models/UserVarsRecordModel';


export class GroupNameToRecordsModel {

	constructor() {
		this.recent = [];
		this.favorites = [];
	}

	@deserializeAs(UserVarsRecordModel)
	recent: Array<UserVarsRecordModel>;

	@deserializeAs(UserVarsRecordModel)
	favorites: Array<UserVarsRecordModel>;
}