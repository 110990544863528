import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {ChartDataModel} from '../Models/ChartDataModel';

export interface IGetChartDataRequestModel {
	EntityId: number;
	SubjectEntityId: number | null;
    SubjectRecordId: number | null;
	XAxisField: number;
	YAxisField: number;
	SeriesField: number;
	SortBy: string;
    XAxisExists: boolean;
    YAxisExists: boolean;
    SeriesExists: boolean;
    Type: any;
};

export class ChartStore {
	GetChartData(params: IGetChartDataRequestModel): P.Promise<ChartDataModel> {
		const deferred = P.defer<ChartDataModel>();

        Request.Send({
            proxy: {
				url: '/api/ChartApi/GetChartData',
                type: 'POST',
                dataType: 'json',
            }, params: params
        }).then(options => {
            deferred.resolve(options);
        }).fail(err => {
	        deferred.reject(this.GetResponseError(err, 'Error getting chart control data'));
        });

        return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}