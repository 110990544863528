import {deserialize} from 'libs/cerialize';

export class SpreadsheetFieldDescriptorDto {
    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    TranslatedName: string;

    @deserialize
    TypeId: number;

    @deserialize
    Type: string;

    @deserialize
    ValTableName?: string;

    @deserialize
    ValFieldName?: string;
}