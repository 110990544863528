import * as ko from 'knockout';
import * as $ from 'jquery';

export class DropdownExtention {
	static Init() {
		ko.bindingHandlers.dropdown = {
			init: (element, valueAccessor) => {
				//var options = valueAccessor();
				//const updateValueCallback = options.onUpdate || (value => { });
				console.log(element, valueAccessor());
				//if (options.options) {
				console.log($(element));
				$(element).on('show.bs.dropdown', (e: any) => {
						console.log('show');
						$('#Condition, #Filtering').css('overflow', 'visible');

					}).on('hide.bs.dropdown', (e: any) => {
						console.log('hide');
						$('#Condition, #Filtering').css({ 'overflow-x': 'auto', 'overflow-y': 'hidden' });

					});

				//}
			},
		};
	}
}