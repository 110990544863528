import {deserialize, deserializeAs} from 'libs/cerialize';

import { AutolinkModel } from "Core/Controls/LinkList/Models/AutoLinkModel";
import { AutoLinkedRecordModel } from "Core/Controls/LinkList/Models/AutoLinkedRecordModel";
import {ActionSubjectRecordModel} from "Core/ScreenManager/Models/ActionSubjectRecordModel";

export interface IRelationRecord {
	Id: number;
	KSeq: number;
	Level: number;
	IsMain: boolean;
	Name: string;
	NameTranslation: string;
    TypeName: string;
    TypeTranslatedName: string;
	TypeId: number;
	IsRecordOwner: boolean;
	UserAllowance: number;
}


export interface ILinkType {
	Id: number;
	Name: string;
	InstancingMaximum: number;
}


export class LinkType {
	constructor(LinkType?: ILinkType) {
		if (LinkType) {

			this.Id = LinkType.Id;
			this.Name = LinkType.Name;
			this.InstancingMaximum = LinkType.InstancingMaximum;

		}
	}

	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	InstancingMaximum: number;

}

export class RelationRecord {
	constructor(record?: IRelationRecord) {
		if (record) {
			this.Id = record.Id;
			this.KSeq = record.KSeq;
			this.Level = record.Level;
			this.IsMain = record.IsMain;
			this.Name = record.Name;
			this.NameTranslation = record.NameTranslation;
            this.TypeName = record.TypeName;
            this.TypeTranslatedName = record.TypeTranslatedName;
			this.TypeId = record.TypeId;
			this.IsRecordOwner = record.IsRecordOwner;
			this.UserAllowance = record.UserAllowance;
		}
		this.IsNew = false;
	}

	@deserialize
	Id: number;

	@deserialize
	KSeq: number;

	@deserialize
	Level: number;

	@deserialize
	IsMain: boolean;

	@deserialize
	Name: string;

	@deserialize
	NameTranslation: string;

	@deserialize
    TypeName: string;

    @deserialize
    TypeTranslatedName: string;

	@deserialize
	TypeId: number;

	@deserialize
	IsNew: boolean;

	@deserialize
	IsRecordOwner: boolean;

	@deserialize
    UserAllowance: number;
}

export class EntityRelations {
	constructor() {
		this.Records = [];
		this.AutoLinks = [];
		this.AutoLinkedRecords = [];
		this.LinkTypes = [];
	}

	@deserialize
	EntityId: number;

	@deserialize
	EntityName: string;

	@deserialize
	EntityTranslatedName: string;

	@deserialize
	TableIcon: string;

	@deserialize
	TableIconType: string;

	@deserialize
	TableImage: string;

	@deserialize
	HasRecords: boolean;

	@deserialize
	HasFields: boolean;

	@deserialize
	HasSequence: boolean;

	@deserializeAs(AutolinkModel)
	AutoLinks: Array<AutolinkModel>;

	@deserializeAs(RelationRecord)
	Records: Array<RelationRecord>;

	@deserializeAs(LinkType)
	LinkTypes: Array<LinkType>;

	@deserializeAs(AutoLinkedRecordModel)
	AutoLinkedRecords: Array<AutoLinkedRecordModel>;
}

export class LinkListRelationsModel {
	constructor() {
		this.Entities = [];
	}

	@deserialize
	ControlId: number;

	@deserialize
	MainEntityId: number;

	@deserialize
	SingleMode: boolean;

	ErrorMessage: string;

	Warnings: string[];

	@deserializeAs(EntityRelations)
	Entities: Array<EntityRelations>;

	@deserializeAs(ActionSubjectRecordModel)
	ActionSubjectRecord: ActionSubjectRecordModel;
}