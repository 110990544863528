import * as ko from 'knockout'
import {IconModel} from 'Core/Controls/BaseControl/Models/IconModel'

import { IconStore } from './IconStore';
import { Notifier } from 'Core/Common/Notifier';
import {FONT_NAME, ICON_NAME} from "Core/Constant";

// Templates
import IconTemplate from 'Core/Icon/Templates/Icon.html';
import PortletIconTemplate from 'Core/Icon/Templates/PortletIcon.html';
ko.templates['Core/Icon/Templates/Icon'] = IconTemplate;
ko.templates['Core/Icon/Templates/PortletIcon'] = PortletIconTemplate;

const FONT_NAME_TO_CSS = {
	'fontawesome-webfont': 'fa fa-3x fa-stack',
	'Social-Icons': 'socicon-btn',
	'Simple-Line-Icons': 'simple-icons'
}

export class Icon {
	private _model: IconModel;
	private _imageUrl: KnockoutObservable<string>;

	constructor(model: IconModel) {
		this._model = model;
		this._imageUrl = ko.observable(this._model && this._model.Image);
	}

	private LoadImage(){
		if(this._model.IsImage && _.isEmpty(this._model.Image)){
			IconStore.GetImageIcon(this._model.Id).then((image)=>{
				this._imageUrl(image);
				this._model.Image = image;
			}).fail((err)=>{
				new Notifier().Failed(err.message);
			});
		}
	}

	get FontName(): string {
		return `${FONT_NAME_TO_CSS[this._model.FontName]} ${this._model.Name}`;
	}

	get Name(): string {
		return this._model.Name;
	}

	get Id(): number {
		return this._model.Id;
	}

	get IconFontName(): string {
		return this._model.FontName;
	}

	GetTemplateName() {
		this.LoadImage();
		return 'Core/Icon/Templates/Icon';
	}

	GetTemplate() {
		this.LoadImage();
		return IconTemplate;
	}

	GetPortletIconTemplate() {
		this.LoadImage();
		return 'Core/Icon/Templates/PortletIcon';
	};

	GetIconTemplate(classes: string = '') {
		if (this._model) {
			let iconClass = this._model.IsIcon ? `` : ``,
				imageClass = this._model.IsImage ? `` : ``;

			if (this._model.IsImage && !this._model.IsIcon) {
				return `<img class="img-responsive image-centered image-icon ${classes}" src="${this._model.Image}" alt="${this._model.Name}">`;
			}

			let iconDataName = this._model.Name ? `data-icon-font-name="${this._model.FontName}"` : ``,
				iconName = !this._model.Name ? '' : this._model.Name;

			switch (this._model.FontName) {
				case FONT_NAME.FONT_AWESOME:
					if (this._model.Name === ICON_NAME.EMPTY_ICON){
						return `<i class="noImageNoIconBox ${iconName} ${classes}" ${iconDataName} data-icon-name="${iconName}"></i>`;
					} else {
						return `<i class="fa ${iconName} ${classes}" ${iconDataName}></i>`;
					}

				case FONT_NAME.SOCIAL_ICONS:
					return `<i class="socicon-btn ${iconName} ${classes}" ${iconDataName}></i>`;

				case FONT_NAME.SIMPLE_LINE:
					return `<i class="${iconName} ${classes}" ${iconDataName}></i>`;

				case null:
					return `<i class="noImageNoIconBox" data-icon-font-name="no-Image-No-Icon"></i>`;

				default:
					return `<i class="${iconName} ${classes}" ${iconDataName} data-icon-font-name="TestDefaultName"></i>`;
			}
		}
		return `<i class="noImageNoIconBox" data-icon-font-name="null-icon-model"></i>`;
	}

	get IsImage() {
		return this._model.IsImage;
	}

	get Image() {
		return this._model.Image;
	}

	get IsIcon() {
		return this._model.IsIcon && (this._model.Name != '');
	}

	get NoIconNoImage(): boolean {
		return !this.IsIcon && !this.IsImage
	}

	get Model(): IconModel{
		return this._model;
	}

	get IsSocialIcon(){
		return this.IconFontName === FONT_NAME.SOCIAL_ICONS;
	}

	get IsFontAwesome(){
		return this.IconFontName === FONT_NAME.FONT_AWESOME;
	}

	get SimpleLineIcon(){
		return this.IconFontName === FONT_NAME.SIMPLE_LINE;
	}
}