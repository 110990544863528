import * as ko from 'knockout';
import * as _ from 'underscore';

import {Guid} from 'Core/Common/Guid';

import {AssignmentModel} from './AssignmentModel';
import {Resource} from 'ganttChart';

export class AssignmentsPopupModel {
    Assignments: KnockoutObservableArray<AssignmentModel>;

    FreeAssignments: KnockoutObservableArray<AssignmentModel>;
    NewAssignment: KnockoutObservable<AssignmentModel>;

    constructor(assignments: AssignmentModel[], resources: Resource<number>[]) {
        this.Assignments = ko.observableArray(assignments);

        const freeAssignments = _.chain(resources)
            .map(r => new AssignmentModel(Guid.NewGuid(), r.id, r.name, r.entity, 0))
            .filter(fa => !_.any(this.Assignments(), a => a.ResourceId === fa.ResourceId && a.ResourceEntity === fa.ResourceEntity))
            .value();

        this.FreeAssignments = ko.observableArray(freeAssignments);
        this.NewAssignment = ko.observable(null);
    }

    EditAssignment(assignment: AssignmentModel) {
        assignment.Editing(true);
    }

    SaveAssignmentChanges(assignment: AssignmentModel) {
        assignment.SaveChanges();
    }

    CancelAssignmentChanges(assignment: AssignmentModel) {
        assignment.CancelChanges();
    }

    RemoveAssignment(assignment: AssignmentModel) {
        this.Assignments.remove(assignment);

        const freeAssignment = new AssignmentModel(assignment.Id, assignment.ResourceId, assignment.ResourceName, assignment.ResourceEntity, 0);
        this.FreeAssignments.push(freeAssignment);
    }

    CreateAssignment() {
        const freeAssignment = this.FreeAssignments()[0];
        this.NewAssignment(freeAssignment);
    }

    SaveNewAssignment() {
        const newAssignment = this.NewAssignment();

        const occupiedAssignment = _.find(this.FreeAssignments(), a => a.Id === newAssignment.Id);
        this.FreeAssignments.remove(occupiedAssignment);

        this.Assignments.push(newAssignment);
        this.NewAssignment(null);
    }

    CancelNewAssignment() {
        this.NewAssignment(null);
    }

    GetAssignments() {
        return this.Assignments();
    }
}