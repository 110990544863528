import * as _ from "underscore";
import * as moment from 'moment';

import {DATE_FORMATS} from "Core/Constants/DateTimeFormats";
import {FormatConverter} from "FormatEditor/FormatConverter";

//Utils
import {CellEditor} from "../Utils/CellEditor";
import {ColumnEditor} from "../Utils/ColumnEditor";
import {ColumnFirstEditor} from "../Utils/ColumnFirstEditor";
import {GroupEditor} from "../Utils/GroupEditor";
import {SubGroupEditor} from "../Utils/SubGroupEditor";

import {SchedulerView} from "../Views/SchedulerView";
import {Period} from "../Models/View/SchedulerViewModel";
import {ISubResourcesMapping, SubGroupsMapping} from "./SubGroupsMapping";

import {
    IResource,
    ISchedularResponseModel,
    IUser, UserGroup
} from "../Models/Store/Response/SchedulerResponseModel";
import {IRePlanningData} from "Core/Controls/Scheduler/Interfaces";

export interface IGroupsMapping {
    responseModel: ISchedularResponseModel,
    userGroups: string,
    periods: Array<Period>,
    startDate: moment.Moment,
    endDate: moment.Moment,
    schedulerView: SchedulerView,
    rePlanningData: IRePlanningData
}

export class GroupsMapping {
    static OnViewModel(model: IGroupsMapping) {
        let groups = model.userGroups.split(';');
        let users = model.responseModel.Users;
        let resourceGroups = model.responseModel.ResourceGroups;
        let schGroups: Array<GroupEditor> = [];
        let sortOrder = 10;

        const processedUsers = [];
        let usersGroups = [];
        if (model.rePlanningData) {
            usersGroups = this.GetLinkedGroups(users, model.rePlanningData.LinkedRecords.Users);
        }

        _.forEach(groups, (group) => {
            let groupEditor;
            if (usersGroups) {
                groupEditor = new GroupEditor(group, sortOrder, _.contains(usersGroups, group));
            } else {
                groupEditor = new GroupEditor(group, sortOrder,false);
            }

            _.forEach(users, (user) => {
                let processedUser = _.find(processedUsers, pu => pu.UserId === user.UserId);

                if (!processedUser) {
                    processedUser = {
                        UserId: user.UserId,
                        TimeStructure: this.CreateTimeStructure(user),
                        ScheduleStructure: this.CreateScheduleStructure(user),
                        FreeDaysStructure: this.CreateFreeDaysStructure(user),
                        Editor: null,
                        Agenda: user.Agenda
                    };

                    processedUsers.push(processedUser);
                }

                let usersGroup = _.find(user.UsersGroups, (usersGroup) => {
                    return usersGroup.GroupName === group;
                });

                if (usersGroup) {
                    let userEditor = null;
                    let isUserSelected = false;

                    //select user if one of his groups is linked
                    if (model.rePlanningData && model.rePlanningData.LinkedRecords) {
                        _.forEach(user.UsersGroups, (uGroup) => {
                           _.forEach(model.rePlanningData.LinkedRecords.Users, (subject: number) => {
                                if (uGroup.GroupId === subject) {
                                    isUserSelected = true;
                                }
                           });
                        });
                    }
                    //select user if one of his groups is linked

                    if (processedUser.Editor) {
                        userEditor = processedUser.Editor.CloneToNewGroup(groupEditor, processedUser.Agenda, isUserSelected);
                    } else {
                        let iseSelected = isUserSelected  || ( model.rePlanningData && _.contains(model.rePlanningData.LinkedRecords.Users, processedUser.UserId));
                        userEditor = new SubGroupEditor(groupEditor, processedUser.Agenda, iseSelected);
                        userEditor._columnFirst = new ColumnFirstEditor(user.UserName, user.UserId, null, 'Subject', user.UserTypeId, user.UserTypeName);
                        SubGroupsMapping.OnUserViewModel({
                            userEditor: userEditor,
                            timeStructure: processedUser.TimeStructure,
                            startDate: model.startDate,
                            endDate: model.endDate,
                            periods: model.periods,
                            schedulerView: model.schedulerView,
                            scheduleStructure: processedUser.ScheduleStructure,
                            freeDaysStructure: processedUser.FreeDaysStructure,
                            rePlanningData: model.rePlanningData
                        });

                        processedUser.Editor = userEditor;
                    }
                    groupEditor._subGroupEditors.push(userEditor);
                }
            });
            this.GenerateCellEditors(groupEditor, model.rePlanningData);
            if (groupEditor._subGroupEditors.length) {
                schGroups.push(groupEditor);
                sortOrder = sortOrder + 10;
            }

            const selectedSubGroups = _.filter(groupEditor._subGroupEditors, (subGroup: SubGroupEditor) => {
                return subGroup._isSelected() === true;
            });

            if (selectedSubGroups.length === groupEditor._subGroupEditors.length) {
                groupEditor._isSelected(true);
            }
            groupEditor._selectedCount(selectedSubGroups.length);
        });

        _.forEach(resourceGroups, (resourceGroup) => {
            if (resourceGroup.Resources.length) {
                const linkedEntity = model.rePlanningData && model.rePlanningData.LinkedRecords.Resources[resourceGroup.EntityId];

                let groupEditor = new GroupEditor(resourceGroup.ResourceGroupName, sortOrder, false);
                _.forEach(resourceGroup.Resources, (resource) => {
                    const isResourceSelected = (model.rePlanningData && _.contains(linkedEntity, resource.ResourceId));
                    let subGroupEditor = new SubGroupEditor(groupEditor, resource.Agenda, isResourceSelected);
                    subGroupEditor._columnFirst = new ColumnFirstEditor(resource.ResourceName, resource.ResourceId, resourceGroup.EntityId, 'Resource', resource.ResourceTypeId, resource.ResourceTypeName);
                    groupEditor._subGroupEditors.push(subGroupEditor);
                    let timeStructure = this.CreateTimeStructure(resource);

                    const resourcesMapModel: ISubResourcesMapping = {
                        subGroupEditor: subGroupEditor,
                        timeStructure: timeStructure,
                        startDate: model.startDate,
                        endDate: model.endDate,
                        periods:  model.periods,
                        schedulerView: model.schedulerView,
                        rePlanningData: model.rePlanningData
                    };
                    SubGroupsMapping.OnViewModel(resourcesMapModel);
                });
                this.GenerateCellEditors(groupEditor, model.rePlanningData);
                schGroups.push(groupEditor);
                sortOrder = sortOrder + 10;

                const selectedSubGroups = _.filter(groupEditor._subGroupEditors, (subGroup: SubGroupEditor) => {
                    return subGroup._isSelected() === true;
                });

                if (selectedSubGroups.length === groupEditor._subGroupEditors.length) {
                    groupEditor._isSelected(true);
                }
                groupEditor._selectedCount(selectedSubGroups.length);
            }
        });

        return schGroups;
    }

    static GenerateCellEditors(groupEditor: GroupEditor, replanningData? : IRePlanningData) {
        _.forEach(groupEditor._subGroupEditors, (subGroupEditor, subGroupIter) => {
            _.forEach(subGroupEditor._columnsEditors, (columnsEditor, columnIter) => {
                if (subGroupIter === 0) {
                    groupEditor._columnsEditors.push(new ColumnEditor());
                }
                _.forEach(columnsEditor._cellsEditors, (cellEditor, cellIter) => {
                    if (subGroupIter === 0) {
                        let count = cellEditor._isAvailable && !cellEditor._isFreeDay && !cellEditor._count ? 1 : 0;
                        const cellEditorModel = {
                            count: count,
                            isAvailable: true,
                            isFreeDay: false,
                            period: cellEditor._period,
                            schedulerView: cellEditor._view,
                            isGroupCell: true,
                            isSelected: false,
                            columnIndex: '',
                            appointments: []
                        };
                        const cell = new CellEditor(cellEditorModel);
                        if (replanningData) {
                            cell._isReplanningCell(cellEditor._isReplanningCell());
                        }
                        groupEditor._columnsEditors[columnIter]._cellsEditors.push(cell);
                    } else {
                        let currentCount = groupEditor._columnsEditors[columnIter]._cellsEditors[cellIter]._count;
                        groupEditor._columnsEditors[columnIter]._cellsEditors[cellIter]._count =
                            cellEditor._isAvailable && !cellEditor._isFreeDay && !cellEditor._count ? currentCount + 1 : currentCount;
                        if (replanningData && !groupEditor._columnsEditors[columnIter]._cellsEditors[cellIter]._isReplanningCell()) {
                            groupEditor._columnsEditors[columnIter]._cellsEditors[cellIter]._isReplanningCell(cellEditor._isReplanningCell());
                        }
                    }
                });
            });
        });
    }

    static GetUserGroups(users: Array<IUser>): Array<UserGroup> {
        let usersGroups = [];
        _.forEach(users, (user) => {
            usersGroups = usersGroups.concat(user.UsersGroups);
        });
        return _.uniq(usersGroups, (us) => us.GroupId);
    }

    static GetLinkedGroups(users: Array<IUser>, linkedUsers: Array<number>): Array<string> {
        const allGroups = this.GetUserGroups(users);
        let intersection = [];
        _.forEach(allGroups, (group) => {
            if (_.contains(linkedUsers, group.GroupId)) {
                intersection.push(group.GroupName);
            }
        });
        return intersection;
    }

    static CreateTimeStructure(item: IUser | IResource) {
        const timeStructure = {};

        _.forEach(item.Agenda, (event) => {
            const start = moment(FormatConverter.CorrectTimezone(event.Starting));
            const endDate = moment(start).add(moment.duration(moment(event.Duration).format(DATE_FORMATS.TIME.Format)));
            while (start.isBefore(endDate)) {
                const startTime = start.format(DATE_FORMATS.DATE_TIME.Format);
                timeStructure[startTime] = timeStructure[startTime] && timeStructure[startTime] + 1 || 1;
                start.add(1, 'm');
            }
        });

        return timeStructure;
    }

    static CreateScheduleStructure(item: IUser) {
        const timeStructure = {};

        _.forEach(item.WorkingSchedule, (event) => {
            let endDate: moment.Moment,
                currentDate: moment.Moment;
            if (event.IsDefault) {
                endDate = moment(event.Ending);
                currentDate = moment(event.Starting);
            } else {
                endDate = moment(FormatConverter.CorrectTimezone(event.Ending));
                currentDate = moment(FormatConverter.CorrectTimezone(event.Starting));
            }

            while (currentDate.isBefore(endDate)) {
                const currentTime = currentDate.format(DATE_FORMATS.DATE_TIME.Format);
                timeStructure[currentTime] = timeStructure[currentTime] && timeStructure[currentTime] + 1 || 1;
                currentDate.add(1, 'm');
            }
        });
        return timeStructure;
    }

    static CreateFreeDaysStructure(item: IUser) {
        const timeStructure = {};

        _.forEach(item.FreeDays, (event) => {
            if (event.FreeDay) {
                const endDate = moment(FormatConverter.CorrectTimezone(event.Date)).endOf('day');
                const currentDate = moment(FormatConverter.CorrectTimezone(event.Date)).startOf('day');

                while (currentDate.isBefore(endDate)) {
                    const currentTime = currentDate.format(DATE_FORMATS.DATE_TIME.Format);
                    timeStructure[currentTime] = timeStructure[currentTime] && timeStructure[currentTime] + 1 || 1;
                    currentDate.add(1, 'm');
                }
            }
        });
        return timeStructure;
    }
}