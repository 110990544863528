import * as ko from "knockout";

import { RecordPersonalizationModel, IRecordPersonalizationParams } from "Core/Components/Controls/MakePersonal/Models/RecordPersonalizationModel";
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";

import MakePersonalTemplate from "Core/Components/Controls/MakePersonal/Templates/MakePersonal.html";

export class MakePersonal {
	private _model: KnockoutObservable<RecordPersonalizationModel>;
	private _labels = LABELS;

	constructor(params?: IRecordPersonalizationParams) {
		this._model = ko.observable(new RecordPersonalizationModel(params));
	}

	GetTemplate() {
		return MakePersonalTemplate;
	}

	GetPersonalizationInfo(): RecordPersonalizationModel {
		return this._model();
	}

	get IsMakePersonal(): boolean {
		return this._model() && (this._model().AlreadyPersonalized() || this._model().PersonalizationSelected() || this._model().PossibleToPersonalize())
	}
}