import * as $ from 'jquery';
import * as _ from 'underscore';

import 'lockr';
import 'pubsub';

import {NetworkMonitor} from './NetworkMonitor';
import {LOCAL_STORAGE} from './Enums/LocalStorageItems';

import {P} from "Core/Common/Promise";
import {BlockUI} from "Core/Common/BlockUi";
import {CookieManager} from "Core/Common/CookieManager";
import { Guid } from './Guid';

export interface IRequestOptions {
    proxy: IRequestProxy;
    params?: any;
}

export interface IRequestProxy {
    url: string;
    type?: string;
    dataType?: string;
    cache?: boolean;
    contentType?: string | false;
    processData?: boolean;
    async?: boolean;
    xhrFields?: any;
    cacheRequest?: boolean;
    transactionId?: string;
}

export interface IFile {
    FileName: string;
    FileData: string;
}

class RequestCache {
    public static store: any = {};

    public static Add(key: string, value: any){
        if(!this.store[key]){
            this.store[key] = { Value: [value] };            
        }else{
            this.store[key].Value.push(value);
        }
        
         return this.store[key].Value;
    }

    public static Get(key: string){
        return this.store[key].Value;
    }

    public static Remove(key: string){
        if(this.store[key]){
            delete this.store[key];
        }
    }
}

export class Request {
    private _params: IRequestOptions;

    constructor(){
        this._params = { proxy: { url: null }, params: null };
    }

    static For(url: string): Request{
        let instance = new Request();
        instance._params.proxy.url = url;
        instance._params.proxy.dataType = 'json';
        return instance;
    }

    UseCacheRequest(): Request{
        this._params.proxy.cacheRequest = true;
        return this;
    }

    Get(params?: any, dataType?: string): P.Promise<any> {
        this._params.params = params;
        this._params.proxy.type = 'GET';
        this._params.proxy.dataType =  dataType ?? 'json';
        return Request.Send(this._params);
    }

    Post(params?: any, dataType?: string): P.Promise<any> {
        this._params.params = params;
        this._params.proxy.type = 'POST';
        this._params.proxy.dataType =  dataType ?? 'json';
        return Request.Send(this._params);
    }

    static Send(params: IRequestOptions): P.Promise<any> {
        return this.SendRequest(
            params,
            (data, status, response, key) => {

                _.each(RequestCache.Get(key), (deferred: any) =>{
                    deferred.resolve(data);
                });
                
                RequestCache.Remove(key);

            }
        );
    }

    static DownloadFile(params: IRequestOptions): P.Promise<IFile> {
        return this.SendRequest(
            params,
            (data, status, response, key) => {
                const contentDisposition = response.getResponseHeader('Content-Disposition');
                const fileNameStr = "filename=";
                const fileName = contentDisposition.substr(contentDisposition.indexOf(fileNameStr) + fileNameStr.length);

                _.each(RequestCache.Get(key), (deferred: any) =>{
                    deferred.resolve({
                        FileName: fileName,
                        FileData: data
                    });
                });
                
                RequestCache.Remove(key);
            }
        )
    }

    private static SendRequest(params: IRequestOptions,
                               onSuccess: (data, status, response, key: string) => void): P.Promise<any> {
        const deferred = P.defer<any>();

        let key = params.proxy.cacheRequest ? JSON.stringify(params.params) : Guid.NewGuid();
        let values = RequestCache.Add(key, deferred);
        if(values.length > 1){
            return deferred.promise();
        }        

        const token = CookieManager.GetAuthToken();
        const adminToken = CookieManager.GetAdminToken();

        $.ajax({
            url: params.proxy.url,
            async: params.proxy.async !== undefined ? params.proxy.async : true,
            data: params.params,
            dataType: params.proxy.dataType,
            type: params.proxy.type,
            cache: params.proxy.cache,
            contentType: params.proxy.contentType,
            processData: params.proxy.processData,
            headers: {
                "Authorization": `Bearer ${token}`,
                "AdminToken": adminToken,
                "Transaction-Id": params.proxy.transactionId
            },
            xhrFields: params.proxy.xhrFields,
            success: (data, status, response) => {
                this.CheckVersion(response);
                onSuccess(data, status, response, key)
            },
            error: e => {

                if (e.status === 0) {
                    NetworkMonitor.ShowOfflineMessage();
                }

                if (e.status === 401 || e.status === 999) {
                    PubSub.publish('UNAUTHORIZE', null);
                    return;
                }

                if (e.status === 997) {
                    PubSub.publish('UNAUTHORIZE', null);
                    return;
                }

                if (e.status === 998) {
                    PubSub.publish('SESSION_LOCKED', null);
                    deferred.reject(null);
                    return;
                }
                const rej: P.Rejection = {message: e.responseText, status: e.status};

                _.each(RequestCache.Get(key), (deferred: any) =>{
                    deferred.reject(rej);
                });
                
                RequestCache.Remove(key);
            }
        });

        return deferred.promise();
    }

    static CheckVersion(response: JQuery.jqXHR<any>){
        let newBuildVersion = response.getResponseHeader('build-version');

        if (newBuildVersion) {
            const buildVersion = Lockr.get(LOCAL_STORAGE.BUILD_VERSION);

            if (!buildVersion) {
                Lockr.set(LOCAL_STORAGE.BUILD_VERSION, newBuildVersion);
            } else {
                if (newBuildVersion !== buildVersion) {
                    Lockr.set(LOCAL_STORAGE.BUILD_VERSION, newBuildVersion);
                        location.reload();
                }
            }
        }
    }
}