import * as moment from 'moment';

import { DATE_FORMATS } from 'Core/Constants/DateTimeFormats';
import { FormatConverter } from 'FormatEditor/FormatConverter';

export interface ErrorItem {
    Error: string;
    Timestamp: string;
}

const errors: ErrorItem[] = [];

export const ErrorHolder = {
    SaveError: (error: string) => {
        errors.push({
            Error: error,
            Timestamp: moment(FormatConverter.ShiftTimeZone(moment().format(), true)).format(
                DATE_FORMATS.FULL_DATETIME.Format
            )
        });
    },
    GetErrors: () => errors
};
