
import {deserialize, deserializeAs, GenericDeserialize} from 'libs/cerialize';

import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize} from 'libs/cerialize';
import {DropDataModel} from 'Core/Controls/Drop/Models/DropDataModel';
import { OperationResultModel } from 'Core/Models/OperationResultModel';
import { UploadChunkModel } from 'Core/Controls/Drop/Models/UploadChunkModel';
import { MissingFieldsDto } from 'Core/Controls/Drop/Models/MissingFieldsDto';
import {GetLimitOfUploadedDocumentsRequestModel} from "../Models/GetLimitOfUploadedDocumentsRequestModel";
import {GetLimitOfUploadedDocumentsResponseModel} from "../Models/GetLimitOfUploadedDocumentsResponseModel";

export interface IValidateSpreadsheetDto {
    ControlId: number;
    TempName: string;
}

export class DropDataStore {

	static ValidateSreadsheet(params: IValidateSpreadsheetDto): P.Promise<MissingFieldsDto> {
		var deferredResult = P.defer<MissingFieldsDto>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: 'api/DropZoneApi/ValidateSpreadsheet',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(GenericDeserialize(data, MissingFieldsDto));
			});

		return deferredResult.promise();
	}

	static GetLimitOfUploadedDocuments(params: GetLimitOfUploadedDocumentsRequestModel): P.Promise<GetLimitOfUploadedDocumentsResponseModel> {
		var deferredResult = P.defer<GetLimitOfUploadedDocumentsResponseModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: 'api/DropZoneApi/GetLimitOfUploadedDocuments',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if(!data.IsSuccessfull){
					deferredResult.reject({ message: data.ErrorMessage });
				}else{
					deferredResult.resolve(data.ResultObject);
				}
			});

		return deferredResult.promise();
	}

	static InsertDropDocument(params: DropDataModel): P.Promise<number> {
		var deferredResult = P.defer<number>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: 'api/DropZoneApi/InsertDocument',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
                if(!data.IsSuccessfull){
                    deferredResult.reject({ message: data.ErrorMessage });
                }else{
					deferredResult.resolve(data.ResultObject);
				}
			});

		return deferredResult.promise();
	}

	static Deserialize(jsonObject: any): OperationResultModel {
		var model = <OperationResultModel>Deserialize(jsonObject, OperationResultModel);
		return model;
    }

    static UploadFileChunk(params: UploadChunkModel): P.Promise<OperationResultModel> {
        var deferredResult = P.defer<OperationResultModel>();
        var formData = new FormData();
        formData.append('file', params.FileChunk, params.FileName);
        
        var requestParams: IRequestOptions = {
            proxy: {
                url: 'api/DropZoneApi/UploadFileChunk',
                type: 'POST',
                contentType: false,
                processData: false
            }, params: formData
        };

        Request.Send(requestParams)
            .then(data => {
                deferredResult.resolve(this.Deserialize(data));
            }).fail((error)=>{
				deferredResult.reject({message: error.message});
			});

        return deferredResult.promise();
    }
}