import {Icon} from "Core/Icon/Icon";

import {LookupOptionsDto} from "../Models/Dto/LookupOptionsDto";
import {LookupOptionDto} from "../Models/Dto/LookupOptionDto";
import {LookupFieldDto} from "../Models/Dto/LookupFieldDto";
import {LookupFieldTableDto} from "../Models/Dto/LookupFieldTableDto";

import {ViewModel} from "../Models/View/ViewModel";
import {LookupOption} from "../Models/View/LookupOption";
import {LookupField} from "../Models/View/LookupField";
import {LookupFieldTable} from "../Models/View/LookupFieldTable";

export class Mappings {
    static MapToViewModel(dto: LookupOptionsDto): ViewModel {
        const viewModel = new ViewModel();

        viewModel.Options(this.MapToLookupOptions(dto.Options));
        viewModel.SelectedOption(dto.SelectedOption && dto.SelectedOption.Id);
        viewModel.LookupField = this.MapToLookupField(dto.LookupField);

        return viewModel;
    }

    static MapToLookupOptions(dtos: LookupOptionDto[]): LookupOption[] {
        return dtos.map(dto => this.MapToLookupOption(dto));
    }

    static MapToLookupOption(dto: LookupOptionDto): LookupOption {
        if (!dto) return null;

        const option = new LookupOptionDto();

        option.Id = dto.Id;
        option.Name = dto.Name;

        return option;
    }

    static MapToLookupField(dto: LookupFieldDto): LookupField {
        if (!dto) return null;

        const field = new LookupField();

        field.Id = dto.Id;
        field.Name = dto.Name;
        field.Icon = new Icon(dto.Icon);

        field.LinkTable = this.MapToLookupFieldTable(dto.LinkTable);
        field.LeftTable = this.MapToLookupFieldTable(dto.LeftTable);
        field.RightTable = this.MapToLookupFieldTable(dto.RightTable);

        return field;
    }

    static MapToLookupFieldTable(dto: LookupFieldTableDto): LookupFieldTable {
        if (!dto) return null;

        const table = new LookupFieldTable();

        table.Id = dto.Id;
        table.Name = dto.Name;

        return table;
    }
}