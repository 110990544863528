import * as $ from "jquery";
import * as ko from 'knockout';
import * as _ from 'underscore';
import {GenericDeserialize} from 'libs/cerialize';

import {ScreenModel} from 'Core/Models/Screens/ScreenModel';
import {FormProperties} from 'Core/Screens/DesignScreen/Models/FormProperties';
import CardScreenTemplate
    from 'Core/Screens/Templates/AdditionalConsultScreen/CardScreen/CardScreen.html';

import { ConsultScreen } from "Core/Screens/ConsultScreen/ConsultScreen";

import {getWidthValue} from "Core/Screens/CardScreen/CardScreenUtils";
import {CARD_SCREEN_PROPERTY_TYPE} from "../../Constant";
import {ISettingsModal} from "../../Controls/FormDesigner/SettingsModal/SettingsModal";
import {PUB_SUB_EVENTS} from "MenuManager/PubSubEvents";

ko.templates['Core/Screens/Templates/AdditionalConsultScreen/CardScreen/CardScreen'] = CardScreenTemplate;

export class CardScreen extends ConsultScreen {
    private _properties: FormProperties = {};

    private _hasCustomColor: KnockoutObservable<boolean>;
    private _hasCustomWeight: KnockoutObservable<boolean>;
    private _screenWidth: KnockoutObservable<any>;
    private _screenBgColor: KnockoutObservable<any>;
    private _screenColors: KnockoutObservable<any>;
    private _fontColor: KnockoutObservable<any>;
    private _customStyle: KnockoutObservable<any>;

    private _fontFamily: KnockoutObservable<string>;
    private _fontWeight: KnockoutObservable<string>;
    private _fontStyle: KnockoutObservable<string>;

    constructor(screenModel: ScreenModel) {
        super(screenModel);

        this._hasCustomColor = ko.observable(null);
        this._hasCustomWeight = ko.observable(null);
        this._screenWidth = ko.observable(null);
        this._screenBgColor = ko.observable(null);
        this._screenColors = ko.observable(null);
        this._fontColor = ko.observable(null);
        this._customStyle = ko.observable(null);

        this._fontFamily = ko.observable(null);
        this._fontWeight = ko.observable(null);
        this._fontStyle = ko.observable(null);

        try {
            this._properties = GenericDeserialize(JSON.parse(screenModel.Properties), FormProperties);
        } catch (error) {}
    }

    GetTemplateName() {
        return 'Core/Screens/Templates/AdditionalConsultScreen/CardScreen/CardScreen';
    }

    AfterRender(el: HTMLElement[]) {
        super.AfterRender(el);

        const bgColor = this.FindElementByType(this._properties?.Options, CARD_SCREEN_PROPERTY_TYPE.SCREEN_BACKGROUND_COLOR)?.Value,
            screenWidth = this.FindElementByType(this._properties?.Options, CARD_SCREEN_PROPERTY_TYPE.SCREEN_WIDTH)?.Value,
            fontColor = this.FindElementByType(this._properties?.Options, CARD_SCREEN_PROPERTY_TYPE.FONT_COLOR)?.Value,
            fontFamily = this.FindElementByType(this._properties?.Options, CARD_SCREEN_PROPERTY_TYPE.FONT_FAMILY)?.Value.Value,
            fontWeight = this.FindElementByType(this._properties?.Options, CARD_SCREEN_PROPERTY_TYPE.FONT_WEIGHT)?.Value.Value,
            fontStyle = this.FindElementByType(this._properties?.Options, CARD_SCREEN_PROPERTY_TYPE.FONT_STYLE)?.Value.Value;

        this._screenWidth(!!screenWidth?.Value ? `calc(${getWidthValue(screenWidth)} - 5px)` : null);
        this._screenBgColor(!!bgColor ? bgColor : null);
        this._fontColor(!!fontColor ? fontColor : null);

        this._fontFamily(!!fontFamily ? fontFamily : null);
        this._fontWeight(!!fontWeight ? fontWeight : null);
        this._fontStyle(!!fontStyle ? fontStyle : null);

        this._customStyle(`${this._fontFamily()} ${this._fontWeight()} ${this._fontStyle()}`);
        this._hasCustomWeight(!!this._fontWeight());
        this._hasCustomColor(!!this._fontColor());

    }

    FindElementByType(array: ISettingsModal[], type: string): ISettingsModal | undefined {
        return _.find(array, { Type: type });
    }
}