import * as ko from "knockout";
import * as _ from 'underscore';

import {Notifier} from "Core/Common/Notifier";

import {
	ConfirmationDialog,
	EVENTS as CONFIRMATION_EVENTS,
	Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

import {FieldSecurityComponent} from "Core/Controls/FieldSecurity/Components/Base/FieldSecurityComponent";
import {IFieldSecuritySettings} from "Core/Controls/FieldSecurity/Shared/Interfaces/IFieldSecuritySettings";

import {HeaderComponent} from "Core/Controls/FieldSecurity/Components/DataRole/Header/HeaderComponent";
import {FieldCollectionComponent} from "Core/Controls/FieldSecurity/Components/DataRole/FieldCollection/FieldCollectionComponent";

import {NewDataRoleComponent} from "Core/Controls/FieldSecurity/Components/DataRole/NewDataRole/NewDataRoleComponent";
import {NewDORoleComponent} from "Core/Controls/FieldSecurity/Components/DataRole/NewDataRole/NewDORole/NewDORoleComponent";
import {NewPORoleComponent} from "Core/Controls/FieldSecurity/Components/DataRole/NewDataRole/NewPORole/NewPORoleComponent";
import {NewSORoleComponent} from "Core/Controls/FieldSecurity/Components/DataRole/NewDataRole/NewSORole/NewSORoleComponent";
import {NewVORoleComponent} from "Core/Controls/FieldSecurity/Components/DataRole/NewDataRole/NewVORole/NewVORoleComponent";
import {NewDPORoleComponent} from "Core/Controls/FieldSecurity/Components/DataRole/NewDataRole/NewDPORole/NewDPORoleComponent";
import { NewDEPRoleComponent } from "Core/Controls/FieldSecurity/Components/DataRole/NewDataRole/NewDEPRole/NewDEPRoleComponent";

import { NewThirdPartyDataRoleModel } from "Core/Controls/FieldSecurity/Shared/Models/Business/NewThirdPartyDataRoleModel";
import { AssigneeModel } from "Core/Controls/FieldSecurity/Shared/Models/Business/AssigneeModel";

import {DataRole, DataRoles} from "Core/Controls/FieldSecurity/Shared/Enums/DataRoles";

import {DataRoleModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/DataRoleModel";
import {FieldCollectionModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/FieldCollectionModel";
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";

import ViewTemplate from 'Core/Controls/FieldSecurity/Components/DataRole/View.html';

ko.templates['Core/Controls/FieldSecurity/Components/DataRole/View'] = ViewTemplate;

export class DataRoleComponent extends FieldSecurityComponent {
	private _dataRole: DataRole;
	private _header: HeaderComponent;
	private _fieldCollections: KnockoutObservableArray<FieldCollectionComponent>;
	private _newRoleForm: KnockoutObservable<NewDataRoleComponent>;

	constructor(settings: IFieldSecuritySettings, dataRole: DataRole) {
		super(settings);

		this._dataRole = dataRole;

		this._header = new HeaderComponent(settings, dataRole);
		this._header.On('DataRoleAdding', this, () => {
			this.Trigger('DataRoleAdding');
			this.ShowNewRoleForm();
		});

		this._fieldCollections = ko.observableArray([]);
		this._newRoleForm = ko.observable(null);

		this.Templates = {View: ViewTemplate, Edit: ViewTemplate, Design: ViewTemplate};
	}

	get DataRole() {
		return this._dataRole;
	}

	AddCollections(fieldCollections: FieldCollectionModel[]) {
		const newCollections = fieldCollections.map(fieldCollection => new FieldCollectionComponent(this.Settings, fieldCollection));
		newCollections.forEach(fieldCollection => fieldCollection.On('DataRoleRemoving', this, eventArgs => this.RemoveDataRole(fieldCollection, eventArgs.data.DataRole)));
		this._fieldCollections(this._fieldCollections().concat(newCollections));
	}

	RemoveCollectionComponent(fieldCollectionId: number) {
		const component = _.find(this._fieldCollections(), fieldCollection => fieldCollection.FieldCollectionId === fieldCollectionId);
		this._fieldCollections.remove(component);
	}

	ChangeManipulations(enable: boolean) {
		super.ChangeManipulations(enable);

		this._header.ChangeManipulations(enable);
		this._fieldCollections().forEach(component => component.ChangeManipulations(enable));

		if (enable) {
			this._newRoleForm(null);
		}
	}

	HasFieldCollection(name: string) {
		return _.any(this._fieldCollections(), fieldCollection => fieldCollection.FieldCollectionName === name);
	}

	HasAssignee(assigneeId: number){
		return _.any(this._fieldCollections(), fieldCollection => fieldCollection.Assignee && fieldCollection.Assignee.Id === assigneeId);
	}

	private ShowNewRoleForm() {
		this._newRoleForm(this.SelectNewRoleComponent());

		if (this._newRoleForm()) {
            this._newRoleForm().On('UsersLoaded', this, eventArgs => this.OnUsersLoad(eventArgs.data.DataRole, eventArgs.data.Model, eventArgs.data.Users));

            this._newRoleForm().On('DataRoleAdded', this, eventArgs => this.OnNewDataRoleSaving(eventArgs.data.DataRole));
            this._newRoleForm().On('AddingCancelled', this, () => this.OnCancelNewDataRole());
		} else {
			new Notifier().Failed(NOTIFICATIONS.DATA_ROLE_IS_NOT_SUPPORTED);
		}
	}

	private SelectNewRoleComponent() {
		switch (this._dataRole) {
			case DataRoles.Instance.DPO:
				return new NewDPORoleComponent();
			case DataRoles.Instance.VO:
				return new NewVORoleComponent();
			case DataRoles.Instance.SO:
				return new NewSORoleComponent();
			case DataRoles.Instance.DO:
				return new NewDORoleComponent();
			case DataRoles.Instance.PO:
				return new NewPORoleComponent();
			case DataRoles.Instance.DEP:
				return new NewDEPRoleComponent();
			default:
				return null;
		}
    }

    private OnUsersLoad(dataRole: DataRoleModel, model: NewThirdPartyDataRoleModel, users: AssigneeModel[]) {
        this.Trigger("UsersLoaded", {DataRole: dataRole, Model: model, Users: users });
    }

	private OnNewDataRoleSaving(dataRole: DataRoleModel) {
		this.Trigger('DataRoleAdded', {DataRole: dataRole});
	}

	private OnCancelNewDataRole() {
		this._newRoleForm(null);
		this.Trigger('AddingCancelled');
	}

	private RemoveDataRole(component: FieldCollectionComponent, fieldCollection: FieldCollectionModel) {
		const confirmationDialog = new ConfirmationDialog({
			Text: "Remove this role?",
			Type: ConfirmationTypes.Question
		});

		confirmationDialog.On(CONFIRMATION_EVENTS.CONFIRM_SELECTED, this, () => {
			this._fieldCollections.remove(component);

			const dataRole = new DataRoleModel(null, this.DataRole.ShortName, fieldCollection);
			this.Trigger('DataRoleRemoved', {DataRole: dataRole});
		});

		confirmationDialog.Show();
	}
}