import * as ko from 'knockout';
import * as _ from 'underscore';

import clone from 'clone';

import {GlobalManager, GLOBALS} from 'Core/GlobalManager/GlobalManager';

import {BlockUI} from 'Core/Common/BlockUi';

import {IControl} from 'Core/Controls/IControl';
import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel';
import {BaseControlEditor} from '../BaseControlEditor';

import {ChartControlEditorStore} from './Stores/ChartControlEditorStore';
import {ChartEntity} from './Models/ChartEntity';
import {ChartView} from './Models/ChartView';

import {PROPERTIES} from 'Core/Controls/Chart/Constants';

import Template from 'Core/Screens/DesignScreen/ControlEditor/Templates/ChartControlEditor.html';

ko.templates['Core/Screens/DesignScreen/ControlEditor/Templates/ChartControlEditor'] = Template;

export const EVENTS = {
    ENTITY_SELECTED: 'ENTITY_SELECTED',
    VIEW_SELECTED: 'VIEW_SELECTED'
};

export class ChartControlEditor extends BaseControlEditor {
    private _store: ChartControlEditorStore;

    private _entities: KnockoutObservableArray<ChartEntity>;
    private _selectedEntity: KnockoutObservable<ChartEntity>;
    private _hasEntities: KnockoutComputed<boolean>;

    private _views: KnockoutObservableArray<ChartView>;
    private _selectedView: KnockoutObservable<ChartView>;

    constructor(control: IControl) {
        super(control);

        this._store = new ChartControlEditorStore();
        this.InitEditControlData(control);
    }

    GetTemplateName(): string {
        return 'Core/Screens/DesignScreen/ControlEditor/Templates/ChartControlEditor';
    }

    AfterRender(el: HTMLElement) {
        const container = el[0]
        super.AfterRender(container);

        BlockUI.Block();

        this._store.GetControlOptions()
            .then(options => this.PopulateEntities(options.Entities))
            .always(() => BlockUI.Unblock());
    }

    GetControlEditorModel() {
        const desktopLanguage = GlobalManager.Instance.GetGlobal(GLOBALS.DESKTOP_LANGUAGE);
        const controlEditorModel = super.GetControlEditorModel();

        _.each(controlEditorModel.NameTranslations, (translation) => {
	        if (translation.Selected || (desktopLanguage == translation.Language.ShortName)) {
		        translation.Selected = true;
	        }
        });

        const selectedView = this._selectedView();
		if (!selectedView) {
			return controlEditorModel;
		}

        controlEditorModel.EntityId = selectedView.Id;
        controlEditorModel.EntityName = selectedView.Name;

		const selectedField = this.EditControl.GetFieldModel();
		if (!selectedField) {
			return controlEditorModel;
		}

        controlEditorModel.FieldId = selectedField.Id;
        controlEditorModel.FieldName = selectedField.Name;

        return controlEditorModel;
    }

    protected InitEditControlData(control: IControl) {
        super.InitEditControlData(control);
        this.InitEntities();
    }

    private InitEntities() {
        this._entities = ko.observableArray([]);
        this._selectedEntity = ko.observable(null);
        this._hasEntities = ko.computed(() => _.any(this._entities()));

        this._views = ko.observableArray([]);
        this._selectedView = ko.observable(null);
    }

	private PopulateEntities(entities: ChartEntity[]) {
		if (!_.any(entities)) {
			return;
		}

        this._entities(entities);

        const attachedField = _.first(this.EditControlModel.Fields);
        const selectedView = attachedField && _.chain(entities)
            .map(entity => entity.Views)
            .flatten()
            .value()
            .find(v => v.PkFieldId === attachedField.Id);

        if (selectedView) {
            const selectedEntity = _.find(entities, entity => _.any(entity.Views, view => view.Id === selectedView.Id));

            if (selectedEntity) {
                this._selectedEntity(selectedEntity);
                this._views(selectedEntity.Views);
				this._selectedView(selectedView);
				this.UpdateAxisOptions();
                this.BindEvents();

                return;
            }
        }

        this._selectedEntity(entities[0]);
        this.UpdateSubTableView();
        this.BindEvents();
    }

    private BindEvents() {
        this._selectedEntity.subscribe(selectedEntity => {
            if (this._selectedEntity()) {
                this.Trigger(EVENTS.ENTITY_SELECTED, selectedEntity);
            }
        });

        this._selectedView.subscribe(selectedView => {
            if (this._selectedView()) {
                this.Trigger(EVENTS.VIEW_SELECTED, selectedView);
            }
        })

        this.On(EVENTS.ENTITY_SELECTED, this, () => this.UpdateSubTableView());
		this.On(EVENTS.VIEW_SELECTED, this, () => {
			this.UpdateField();
			this.UpdateAxisOptions();
		});
    }

    private UpdateSubTableView() {
        const selectedEntity = this._selectedEntity();

        if (!selectedEntity) {
            return;
        }

        this._views(selectedEntity.Views);
        this._selectedView(selectedEntity.Views[0]);

		this.UpdateField();
		this.UpdateAxisOptions();
    }

    private UpdateField() {
        const selectedView = this._selectedView();
        if (!selectedView) {
            return;
        }

        let fieldModel = this.EditControl.GetFieldModel();

        if (!fieldModel) {
            fieldModel = new AttachedFieldModel({ Id: selectedView.PkFieldId, Name: selectedView.PkFieldName, EntityId: selectedView.Id});
            this.EditControl.GetModel().Fields.push(fieldModel);
        } else {
            fieldModel.Id = selectedView.PkFieldId;
            fieldModel.Name = selectedView.PkFieldName;
            fieldModel.EntityId = selectedView.Id;
        }
	}

	private UpdateAxisOptions() {
		const selectedView = this._selectedView();
		if (!selectedView) {
			return;
		}

		const generalProperties = this.EditControl.GeneralProperties;
		const seriesOptions = _.map(selectedView.Fields, (f) => {
			return {
				Name: f.Name,
				Value: f.Id
			};
		});

		generalProperties.SetPropertyOptions('Series', clone(seriesOptions));

		const axisFields = _.filter(selectedView.Fields, f => f.IsInteger || f.IsDecimal || f.IsText || f.IsDate || f.IsDatetime);
		if (!_.any(axisFields)) {
			return;
		}

		const axisOptions = _.map(axisFields, (f) => {
			return {
				Name: f.Name,
				Value: f.Id
			};
		});

		generalProperties.SetPropertyOptions(PROPERTIES.X_AXIS, clone(axisOptions));
		generalProperties.SetPropertyOptions(PROPERTIES.Y_AXIS, clone(axisOptions));
	}
}