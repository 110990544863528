import * as ko from "knockout";
import * as _ from "underscore";

import {BaseProperty, IPropertyDescription} from "Core/GeneralProperties/Managers/BaseProperty";
import {IControl} from 'Core/Controls/IControl';
import {BlockUI} from 'Core/Common/BlockUi';
import {TableTypeModel} from 'Core/Screens/TypeScreen/Models/TableTypeModel';

import Template from "Core/GeneralProperties/Managers/TemplateTypeProperty/TemplateTypePropertyTemplate.html";
import { EntityTypesStore, IGetTypesRequestModel } from "Core/Screens/TypeScreen/Stores/EntityTypesStore";

ko.templates["Core/GeneralProperties/Managers/TemplateTypeProperty/TemplateTypePropertyTemplate"] = Template;


export class TemplateTypeProperty extends BaseProperty {
    private _el: HTMLElement;
    private _control: IControl;
    private _types: KnockoutObservableArray<TableTypeModel>;
    private _selectedType: KnockoutObservable<TableTypeModel>;
    private _selectedTypeSubscription: KnockoutSubscription;

    constructor(property: IPropertyDescription, propertyValue: string, control: IControl) {
        super(property);
        this._control = control;
        this._types = ko.observableArray([]);
        this._selectedType = ko.observable(null);
        this.Value = ko.observable(propertyValue || this.GetDefaultValue());
        this._selectedTypeSubscription = null;
    }

    GetTemplateName(): string {
        return "Core/GeneralProperties/Managers/TemplateTypeProperty/TemplateTypePropertyTemplate";
    }

    GetDefaultValue() {
        return null;
    }

    SetValue(propertyValue: string): void {
        this.Value(propertyValue);
    }

    OnInit(): void {
        if(this._control.GetFieldModel()) {
            let request: IGetTypesRequestModel = {
                EntityId: this._control.GetFieldModel().EntityId,
                ParentTypeId: 0,
                WithRoot: false,
                OnlyEnabled: true
            };

            EntityTypesStore.GetTypes(request).then((data)=> {
                _.each(data.TableTypes, (type) =>{
                    type.Name = type.Name + ' only';
                });

                this._types(data.TableTypes);

                if(this.Value()){
                    let selectedType = _.find(data.TableTypes, (type) => type.Id === this.Value());
                    if(selectedType){
                        this._selectedType(selectedType);
                    }else{
                        this.Value(null);
                    }                    
                }

                this._selectedTypeSubscription = this._selectedType.subscribe((newValue)=> {
                    if(newValue && newValue.Id){
                        this.Value(newValue.Id);
                    }else{
                        this.Value(null);
                    }
                });    
                
            });
        }
    }

    Reset() {
        if(this._selectedTypeSubscription){
            this._selectedTypeSubscription.dispose();
        }
        this.OnInit();
    }

    AfterRender(el: Array<HTMLElement>) {}
}