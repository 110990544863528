export const EVENTS = {
    MOUSE_OVER: 'MouseOver',
    MOUSE_OUT: 'MouseOut',
    UNSELECT_CELLS: 'UNSELECT_CELLS',
    KEY_DOWN: 'KEY_DOWN',
    UPDATE_PLAN: 'UPDATE_PLAN',
    UNLINK_QUERY_RECORD: 'UNLINK_QUERY_RECORD',
    HIGHER_RESOLUTION_PLANNING: 'HIGHER_RESOLUTION_PLANNING',
    COPY_CELL: 'COPY_CELL',
    RUN_QUERY1: 'RUN_QUERY1',
    RUN_QUERY2: 'RUN_QUERY2',
    DROPDOWN_CLOSED: 'DROPDOWN_CLOSED'
}