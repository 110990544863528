import {TaskParentDto} from './TaskParentDto';
import {AssignmentDto} from './AssignmentDto';
import {DependencyDto} from './DependencyDto';

export class TaskDto {
    Entity: string;
    ChildEntity: string;
    Id: number;
    Name: string;
    Level: number;
    Starting: string;
    Ending: string;
    StartIsMilestone: boolean;
    EndIsMilestone: boolean;
    Completeness: number;
    Duration: number;
    Dependencies: DependencyDto[];
    Parent: TaskParentDto;
    Assignments: AssignmentDto[];
    BarColor: string;

    constructor() {
        this.Dependencies = [];
        this.Assignments = [];
    }
}