import {Request} from 'Core/Common/Request';
import {URL} from "Core/Common/Url";
import {ConfigureBulkEmailDto} from "./Models/ConfigureBulkEmailDto";
import {CreateNewGroupDto} from "./Models/CreateNewGroupDto";

export class BulkEmailStore {
	static GetConfig(subjectTableId: number, subjectRecordId: number, templateTableId: number) {
		return Request.Send({
			proxy: {
				type: 'GET',
				url: URL.BULK_EMAIL.GET_CONFIG,
				dataType: 'json',
			},
			params: {groupTableId: subjectTableId, groupId: subjectRecordId, templateTableId: templateTableId}
		});
	}

	static GetTemplates(entityId: number, templateTableId: number) {
		return Request.Send({
			proxy: {
				type: 'GET',
				url: URL.BULK_EMAIL.GET_TEMPLATES,
				dataType: 'json'
			},
			params: {groupTableId: entityId, templateTableId: templateTableId}
		});
	}

	static Start(subjectTableId: number, subjectRecordId: number) {
		return Request.Send({
			proxy: {
				type: 'POST',
				url: URL.BULK_EMAIL.START,
				dataType: 'json'
			},
			params: {GroupTableId: subjectTableId, GroupId: subjectRecordId}
		});
	}

	static Stop(subjectTableId: number, subjectRecordId: number) {
		return Request.Send({
			proxy: {
				type: 'POST',
				url: URL.BULK_EMAIL.STOP,
				dataType: 'json'
			},
			params: {GroupTableId: subjectTableId, GroupId: subjectRecordId}
		});
	}

	static ConfigureBulkEmails(sendModel: ConfigureBulkEmailDto) {
		return Request.Send({
			proxy: {
				type: 'POST',
				url: URL.BULK_EMAIL.CONFIGURE_BULK_EMAILS,
				dataType: 'json'
			},
			params: sendModel
		});
	}

	static CreateNewGroup(createModel: CreateNewGroupDto) {
		return Request.Send({
			proxy: {
				type: 'POST',
				url: URL.BULK_EMAIL.CREATE_NEW_GROUP,
				dataType: 'json'
			},
			params: createModel
		});
	}
}