import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';

import {NotificationDesignerModel} from 'Core/ProfilePage/NotificationDesigner/Models/NotificationDesignerModel';

export class NotificationDesignerStore {
	static GetSettings() {
		const deferredResult = P.defer<NotificationDesignerModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/UserApi/GetNotificationSettings',
				dataType: 'json',
				type: 'GET'
			}
		};

		Request.Send(requestParams)
			.then((response) => {
				deferredResult.resolve(response);
			})
			.fail((error) => {
				deferredResult.reject(error);
			});

		return deferredResult.promise();
	}

	static SaveSettings(params: NotificationDesignerModel) {
		const deferredResult = P.defer<NotificationDesignerModel>();

		Request
			.For('/api/UserApi/SetNotificationSettings')
			.Post(params)
			.then(response => {
				deferredResult.resolve(response);
			})
			.fail(error => {
				deferredResult.reject(error);
			});

		return deferredResult.promise();
	}
}