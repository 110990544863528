import { deserialize } from 'libs/cerialize'

export class KindModel {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	TranslatedName: string;

	@deserialize
	IsEnabled: boolean;

	get DisplayName() {
		return this.TranslatedName || this.Name;
	}

	static CreateDashKind() {
		const model = new KindModel();
		model.Id = 0;
		model.Name = '-';
		model.IsEnabled = true;
		return model;
	}
}