import {SpreadsheetCellPosition} from './SpreadsheetCellPosition';

export class SpreadsheetCellRange {
    constructor(public Start: SpreadsheetCellPosition, public End: SpreadsheetCellPosition) {
    }

    get RangeName() {
        return `${this.Start.PositionName}:${this.End.PositionName}`;
    }

    ContainsCell(position: SpreadsheetCellPosition) {
        return this.Start.Row <= position.Row && this.Start.GetColumnNumber() <= position.GetColumnNumber()
            && this.End.Row >= position.Row && this.End.GetColumnNumber() >= position.GetColumnNumber();
    }
}