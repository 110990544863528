import { deserialize } from "libs/cerialize";

export class TagModel {

	@deserialize
	Name: string;

	@deserialize
	TagId: number;

	@deserialize
	TypeId: number;

	@deserialize
	GroupId: number;

	@deserialize
	GroupName: string;

	get FullName(): string {
		let groupName = this.GroupName ? (this.GroupName + '/') : '';
		let name = this.Name || "";
		return groupName + name;
	}
}