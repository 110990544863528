import * as ko from "knockout";
import {SelectUserModel, UserModel, UserGroupModel } from "Core/Controls/SelectUser/Models/SelectUserModel";

export class SelectUserFormModel {
	Users: Array<UserFormModel>;
	UserGroups: Array<UserGroupFormModel>;

	constructor(model: SelectUserModel) {
		this.Users = model.Users.map(user => new UserFormModel(user));
		this.UserGroups = model.UserGroups.map(userGroup => new UserGroupFormModel(userGroup));
	}

	ToSelectUserModel() {
		const model = new SelectUserModel();
		model.Users = this.Users.map(user => user.ToUserModel());
		model.UserGroups = this.UserGroups.map(userGroup => userGroup.ToUserGroupModel());

		return model;
	}
}

export class UserFormModel {
	Id: number;
	Name: string;
	Checked: KnockoutObservable<boolean>;
	UserTypeName: string;
	UserTypeNameTranslation: string;

	constructor(model: UserModel) {
		this.Id = model.Id;
		this.Name = model.Name;
		this.Checked = ko.observable(model.Checked);
		this.UserTypeName = model.UserTypeName;
		this.UserTypeNameTranslation = model.UserTypeNameTranslation;
	}

	ToUserModel() {
		const user = new UserModel;

		user.Id = this.Id;
		user.Name = this.Name;
		user.Checked = this.Checked();
		user.UserTypeName = this.UserTypeName;
		user.UserTypeNameTranslation = this.UserTypeNameTranslation;

		return user;
	}
}

export class UserGroupFormModel {
	Id: number;
	Name: string;
	Checked: KnockoutObservable<boolean>;
	TypeId: number;
	TypeName: string;
	TypeNameTranslation: string;

	constructor(model: UserGroupModel) {
		this.Id = model.Id;
		this.Name = model.Name;
		this.Checked = ko.observable(model.Checked);
		this.TypeId = model.TypeId;
		this.TypeName = model.TypeName;
		this.TypeNameTranslation = model.TypeNameTranslation;
	}

	ToUserGroupModel() {
		const userGroup = new UserGroupModel();

		userGroup.Id = this.Id;
		userGroup.Name = this.Name;
		userGroup.Checked = this.Checked();
		userGroup.TypeId = this.TypeId;
		userGroup.TypeName = this.TypeName;
		userGroup.TypeNameTranslation = this.TypeNameTranslation;

		return userGroup;
	}
}
