import * as ko from "knockout";

import {BaseControl, IControlValue} from "Core/Controls/BaseControl/BaseControl";
import {IControlParam} from "Core/Screens/IScreen";

import {ColorSelector as Component} from 'Core/Components/ColorSelector/ColorSelector';

import ToolBarTemplate from "Core/Controls/ColorSelector/Templates/ToolBar.html";
import DesignTemplate from "Core/Controls/ColorSelector/Templates/Design.html";
import HelpViewTemplate from "Core/Controls/ColorSelector/Templates/HelpView.html";
import ViewTemplate from "Core/Controls/ColorSelector/Templates/View.html";
import EditTemplate from "Core/Controls/ColorSelector/Templates/Edit.html";
import {RenderModes} from "../../Constant";
import {ScreenTypes} from "../../Common/Enums/ScreenTypes";
import {ColorDescription} from "../../Components/ColorSelector/ColorDescription";

ko.templates["Core/Controls/ColorSelector/Templates/ToolBar"] = ToolBarTemplate;
ko.templates["Core/Controls/ColorSelector/Templates/Design"] = DesignTemplate;
ko.templates["Core/Controls/ColorSelector/Templates/HelpView"] = HelpViewTemplate;
ko.templates["Core/Controls/ColorSelector/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/ColorSelector/Templates/Edit"] = EditTemplate;

export class ColorSelector extends BaseControl {
    private _colorDescription: KnockoutObservable<ColorDescription>;

    private _component: Component;
    private _initialColor: string;

    constructor(params: IControlParam) {
        super(params);

        if (this._renderMode() !== RenderModes.Design && this._renderMode() !== RenderModes.ToolBar) {
            this._colorDescription = ko.observable(null);
            this._component = new Component({
				Label: null,
				LabelStyle: this.GetLabelStyle()
            });

            this.BindComponentEvents();
        }
    }

    ApplyProperties(){}

    GetValue() {
        return this._colorDescription() && this._colorDescription().Name;
    }

    SetValue(value: IControlValue): void {
        this._initialColor = value.Data.Value;
        this._component.SetColor(this._initialColor);
        this._colorDescription(this._component.SelectedColorDescription);

        this.BindComponentEvents();
    }

    IsDataControl(): boolean {
        return true;
    }

    IsModified(): boolean {
        return this._initialColor !== this._component.SelectedColorName;
    }

    Deserialize() {
        const field = _.first(this._model().Fields);
        if (field) {
            if (!this._isReadonly) {
                return [`${field.EntityName}.${field.Name}`, this._component.SelectedColorName];
            } else {
                return null;
            }
        }
        return null;
    }

    IsValid(): boolean {
        if (this._isRequired && !this._component.SelectedColorName) {
            this._isValid(false);
        } else {
            this._isValid(true);
        }
        return this._isValid();
    }

	private GetLabelStyle() {
		return this._form && (this._form.GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.LinkEditor]) &&
			this.Properties && this.Properties.BackgroundColor
			? { backgroundColor: this.Properties.BackgroundColor }
			: null;
	}

    private BindComponentEvents() {
        this._component
            .On('COLOR_SET', this, () => this._colorDescription(this._component.SelectedColorDescription))
            .On('COLOR_RESET', this, () => this._colorDescription(this._component.SelectedColorDescription))
    }
}