import {ActionsCheckListsDto} from "../Models/Dto/ActionsCheckListsDto";
import {ActionsCheckListsViewModel} from "../Models/View/ActionsCheckListsViewModel";
import {ActionCheckListMappingProfile} from "./ActionCheckListMappingProfile";
import {CurrentLifeStatusMappingProfile} from "./CurrentLifeStatusMappingProfile";

export class ActionsCheckListsMappingProfile {
    static MapToViewModel(dto: ActionsCheckListsDto) {
        const viewModel = new ActionsCheckListsViewModel();

        viewModel.CurrentLifeStatus = dto.CurrentLifeStatus && CurrentLifeStatusMappingProfile.MapToViewModel(dto.CurrentLifeStatus);

        viewModel.CheckStatuses = dto.LifeStatuses
            .filter(lifeStatus => lifeStatus.CheckItems.length > 0)
            .map(lifeStatus => ActionCheckListMappingProfile.MapToViewModel(lifeStatus));

        viewModel.NonCheckStatuses = dto.LifeStatuses
            .filter(lifeStatus => lifeStatus.CheckItems.length === 0)
            .map(lifeStatus => ActionCheckListMappingProfile.MapToViewModel(lifeStatus));

        return viewModel;
    }
}