import * as ko from "knockout";

import {P} from "Core/Common/Promise";

import {NewDataRoleComponent} from "Core/Controls/FieldSecurity/Components/DataRole/NewDataRole/NewDataRoleComponent";
import {FieldSecurityStore} from "Core/Controls/FieldSecurity/Stores/FieldSecurityStore";
import {AssigneeDTOModel} from "Core/Controls/FieldSecurity/Shared/Models/DTO/Get/AssigneeDTOModel";
import {AssigneeModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/AssigneeModel";
import {FieldCollectionModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/FieldCollectionModel";
import {DataRoleModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/DataRoleModel";
import {DataRole} from "Core/Controls/FieldSecurity/Shared/Enums/DataRoles";
import {NewThirdPartyDataRoleModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/NewThirdPartyDataRoleModel";
import {AssigneeMappings} from "Core/Controls/FieldSecurity/Mappings/AssigneeMappings";

import Template from 'Core/Controls/FieldSecurity/Shared/Templates/NewDataRole/NewThirdPartyDataRole.html';

export class NewThirdPartyDataRoleComponent extends NewDataRoleComponent {
	protected Model: KnockoutValidatedObservable<NewThirdPartyDataRoleModel>;
	protected DataRole: DataRole;

	constructor(dataRole: DataRole) {
		super(Template);

		this.Model = ko.validatedObservable(new NewThirdPartyDataRoleModel());
		this.DataRole = dataRole;
	}

	AfterRender() {
	}

	ValidateForm() {
		const valid = this.Model.isValid();

		if(!valid){
			this.Model.errors.showAllMessages();
		}

		return valid;
	}

	protected LoadUsers(userType: string): P.Promise<AssigneeDTOModel[]> {
		return FieldSecurityStore.GetUsers(userType);
	}

	protected UsersLoaded(usersDto: AssigneeDTOModel[]) {
        const users = usersDto.map(user => AssigneeMappings.MapToAssignee(user));
        this.Trigger("UsersLoaded", {DataRole: this.DataRole, Model: this.Model(), Users: users});
	}

	protected MapToNewDataRole() {
		const assignee = new AssigneeModel(this.Model().SelectedUser().Id, this.Model().SelectedUser().Name);
		const fieldCollection = new FieldCollectionModel(null, this.Model().FieldCollectionName(), assignee);

		return new DataRoleModel(null, this.DataRole.ShortName, fieldCollection);
	}
}