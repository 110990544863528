import { Guid } from "Core/Common/Guid";
import { BaseModel } from "DatabaseDesigner/Models/BaseModel";
import { CollectionModel } from "DatabaseDesigner/Models/CollectionModel";

export function KindModel() {
    this._id = null;
    this._guid = Guid.NewGuid();
    this._name = null;
    this._enabled = null;
    this._typeId = null;
    this._typeGuid = null;
    this._fields = [];

    BaseModel.call(this);
}

KindModel.prototype = Object.create(BaseModel.prototype);
KindModel.prototype.constructor = KindModel;

KindModel.prototype.FromJSONArray = function (kinds) {
    var kindsList = new CollectionModel({ model: KindModel });

    _.each(kinds, function (kind) {
        var model = new KindModel();
        model.FromJSON(kind);
        kindsList.Add(model);
    });

    return kindsList;
};

KindModel.prototype.FromJSON = function (data) {
    this._id = data.Id;
    this._name = data.Name;
    this._enabled = data.Enabled;
    this._fields = [];

    for (var fieldIndex = 0; fieldIndex < data.Fields.length; fieldIndex++) {
        var field = data.Fields[fieldIndex];

        this._fields.push({
            FieldId: field.FieldId,
            ShowModeId: field.ShowModeId
        });
    }
};

KindModel.prototype.toJSON = function () {
    return {
        Id: this._id,
        Guid: this._guid,
        Name: this._name,
        Enabled: this._enabled,
        Fields: this._fields
    };
};

Object.defineProperty(KindModel.prototype, 'Id', {
    enumerable: true,
    get: function () {
        return this._id;
    },
    set: function (val) {
        this._id = val;
    }
});

Object.defineProperty(KindModel.prototype, 'Name', {
    enumerable: true,
    get: function () {
        return this._name;
    },
    set: function (val) {
        this._name = val;
    }
});

Object.defineProperty(KindModel.prototype, 'Enabled', {
    enumerable: true,
    get: function () {
        return this._enabled;
    },
    set: function (val) {
        this._enabled = val;
    }
});

Object.defineProperty(KindModel.prototype, 'Fields', {
    enumerable: true,
    get: function () {
        return this._fields;
    },
    set: function (val) {
        this._fields = val;
    }
});