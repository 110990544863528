import * as ko from 'knockout';
import * as _ from 'underscore'
import 'datetimepicker';
import * as moment from 'moment';

import {BaseControl, IControlValue} from "../BaseControl/BaseControl";
import {IControlParam} from "Core/Screens/IScreen";
import {CurrencyStore, ICurrency} from "./Stores/CurrencyStore";
import {JBoxDropDown} from "Core/Components/JBoxDropdown/DropDown";
import {Icon} from "Core/Icon/Icon";
import {DATE_FORMATS} from "Core/Constants/DateTimeFormats";
import {FIELD_TYPES, FONT_STYLES, RenderModes} from "Core/Constant";
import {ScreenTypes} from "Core/Common/Enums/ScreenTypes";
import {FormatConverter} from "FormatEditor/FormatConverter";
import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";

import ToolBarTemplate from 'Core/Controls/Currency/Templates/ToolBar.html';
import DesignTemplate from 'Core/Controls/Currency/Templates/Design.html';
import HelpViewTemplate from 'Core/Controls/Currency/Templates/HelpView.html';
import ViewTemplate from 'Core/Controls/Currency/Templates/View.html';
import EditTemplate from 'Core/Controls/Currency/Templates/Edit.html';

ko.templates['Core/Controls/Currency/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Currency/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/Currency/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Currency/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/Currency/Templates/Edit'] = EditTemplate;

export interface ICurrencyValueModel {
    CurrencyId?: number;
    Currency: ICurrency;
    RateDate: string;
    Value: string;
}

export class Currency extends BaseControl {
    private _value: KnockoutObservable<string>;
    private _labelStyle: KnockoutObservable<any>;
    private _textInputStyle: KnockoutObservable<any>;
    private _rateDate: KnockoutObservable<string>;
    private _selectedCurrency: KnockoutObservable<ICurrency>;
    private _availableCurrencies: KnockoutObservableArray<ICurrency>;
    private _dropDown: JBoxDropDown;
    private _originalCurrency: ICurrencyValueModel;
    private _errorResolving: KnockoutObservable<boolean>;
    private _originalValue: { RateDate: string, Value: string, CurrencyId: number };

    constructor(params: IControlParam) {
        super(params);

        this._value = ko.observable('');
        this._labelStyle = ko.observable(null);
        this._textInputStyle = ko.observable(null);
        this._rateDate = ko.observable(null);
        this._selectedCurrency = ko.observable(null);
        this._availableCurrencies = ko.observableArray([]);
        this._errorResolving = ko.observable(true);

        this.ApplyProperties();
    }

    GetRateTitle() {
        const date = moment(this._rateDate()).format('L');
        if ((this._form && this._form.GetScreen() && this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.EditScreen]) || this._renderMode() === RenderModes.Edit) {
            return `<div class="origin-tooltip"> ${date} </div>`;
        } else if (this._originalCurrency && this._originalCurrency.Currency) {
            const icon = new Icon(this._originalCurrency.Currency.Icon);
            const template = `<div class="origin-tooltip"> ${icon.GetTemplate()}${this._originalCurrency.Value} - ${date} </div>`;
            return (ko as any).renderTemplateXHtml(template, icon);
        }
    }

    SetValue(value: IControlValue): void {
        if (value.Data) {
            if (value.Data.Value) {
                const currencyModel: { Origin: ICurrencyValueModel, Converted: ICurrencyValueModel } = this.ParseValue(value);

                let showCurrency = currencyModel.Converted ? currencyModel.Converted : currencyModel.Origin;
                if (this._renderMode() === RenderModes.Edit) {
                    if (!currencyModel.Origin) {
                        showCurrency = JSON.parse(value.Data.Value);
                        showCurrency.Currency.Icon = showCurrency.Currency.Icon._model;
                    } else {
                        showCurrency = currencyModel.Origin;
                    }
                }

                showCurrency.Value = this.ApplyCulture(showCurrency.Value.toString());

                this._value(showCurrency.Value);
                currencyModel.Origin? this._rateDate(currencyModel.Origin.RateDate) : this._rateDate(showCurrency.RateDate);

                this._originalValue = {
                    Value: showCurrency.Value,
                    RateDate: this._rateDate(),
                    CurrencyId: showCurrency.Currency ? showCurrency.Currency.Id : null
                };

                this._originalCurrency = currencyModel.Origin ? currencyModel.Origin : showCurrency;

                if(showCurrency.Currency) {
                    this._selectedCurrency({
                        IsoCode: showCurrency.Currency.IsoCode,
                        Icon: new Icon(showCurrency.Currency.Icon),
                        Id: showCurrency.Currency.Id,
                        Name: showCurrency.Currency.Name
                    });
                }
                this._errorResolving(false);
            } else {
                CurrencyStore.GetCurrencies()
                    .then((response) => {
                        if (!response.length) {
                            this._errorResolving(true);
                            return;
                        }

                        const userCurrencyIso = GlobalManager.Instance.GetGlobal(GLOBALS.DEFAULT_CURRENCY).toLowerCase();
                        const userCurrency = _.find(response, currencyModel => currencyModel.IsoCode
                            && currencyModel.IsoCode.toLowerCase() === userCurrencyIso);
                        const defaultCurrency = userCurrency ? userCurrency : response[0];

                        _.forEach(response,
                            (currency: ICurrency) => {
                                const icon = new Icon(currency.Icon);
                                this._availableCurrencies.push({
                                    IsoCode: currency.IsoCode,
                                    Icon: icon,
                                    Id: currency.Id,
                                    Name: currency.Name
                                });
                            });

                        const icon = new Icon(defaultCurrency.Icon);
                        this._rateDate(moment().format());
                        this._selectedCurrency({
                            IsoCode: defaultCurrency.IsoCode,
                            Icon: icon,
                            Id: defaultCurrency.Id,
                            Name: defaultCurrency.Name
                        });
                        this._errorResolving(false);
                    })
                    .fail((error) => {
                        // JSON.parse(error.message).ErrorMessage;
                    });
            }
        }
    }

    ApplyCulture(value: string): string {
        if (!!value && this.FieldModel && this.FieldModel.FieldTypeName === FIELD_TYPES.Currency) {
            return FormatConverter.ConvertDecimal(value);
        }

        return value;
    }

    get FormatDisplayValue() {
        const value = this._value();

        if (!!value && this.FieldModel && this.FieldModel.FieldTypeName === FIELD_TYPES.Currency) {
            return FormatConverter.LocalizeDecimalOrInteger(
                Number(value.replace(/[.,]/g, '.')).toFixed(this.FieldModel.Size)
            );
        }

        return value;
    }

    Deserialize() {
        const field = _.first(this._model().Fields);
        if (field) {
            if (!this._isReadonly) {
                const value = {
                    CurrencyId: this._selectedCurrency().Id,
                    Currency: this._selectedCurrency(),
                    Value: this._value(),
                    RateDate: this._rateDate()
                };
                return [`${field.EntityName}.${field.Name}`, JSON.stringify(value)];
            } else {
                return null;
            }
        }
        return null;
    }

    GetValue(){
        let currencyValue: ICurrencyValueModel =
            {
                CurrencyId: this._selectedCurrency().Id,
                Currency: {
                    Id: this._selectedCurrency().Id,
                    Name: this._selectedCurrency().Name,
                    IsoCode: this._selectedCurrency().IsoCode,
                    Icon: this._selectedCurrency().Icon.Model
                },
                RateDate: this._rateDate(),
                Value: this._value()
            };

        return currencyValue
    }

    private ParseValue(value: IControlValue) {
        let currencyModel: { Origin: ICurrencyValueModel, Converted: ICurrencyValueModel } = value.Data.Value;
        if (!currencyModel.Origin) {
            currencyModel = JSON.parse(value.Data.Value);
        }

        return currencyModel;
    }

    private ChangeRate(newDate: Date) {
        const rateDate = moment(newDate).format(DATE_FORMATS.SHORT_DATE.Format);
        this._rateDate(rateDate);
    }

    private OnSelectCurrency(currency: ICurrency) {
        this._selectedCurrency(currency);
        this._dropDown.Toggle();
    }

    private InitCurrencyDropdown(el: HTMLElement) {
        this._dropDown = new JBoxDropDown({
            onCreated: () => {
                this._dropDown.SetContent();
            },
            target: el,
            bindTarget: el,
            bindComponent: this,
            otherOptions: {
                addClass: "currency-dropdown",
                closeOnClick: 'body',
                attach: undefined,
                position: {
                    x: "left",
                    y: "bottom"
                },
                offset: {
                    x: 36,
                    y: -11
                }
            }
        });
    }

    SetAvailableCurrencies(response: Array<ICurrency>) {
        if (!response.length)
            return;
        let defaultCurrency = response[0];
        _.forEach(response, (currency: ICurrency) => {
            const icon = new Icon(currency.Icon);
            if (currency.Icon.IsDefault) {
                defaultCurrency = currency;
            }
            this._availableCurrencies.push({
                IsoCode: currency.IsoCode,
                Icon: icon,
                Id: currency.Id,
                Name: currency.Name
            });

        });
    }

    private ShowAvailableCurrencies(data, event) {
        if (!this._availableCurrencies().length && !this._isReadonly) {
            CurrencyStore.GetCurrencies()
                .then((response) => {
                    if (!response.length) {
                        this._errorResolving(true);
                        return;
                    }
                    this.SetAvailableCurrencies(response);
                    this._errorResolving(false);
                    this.InitCurrencyDropdown(event.currentTarget);
                    this._dropDown.Open();
                })
        } else {
            if (this._dropDown) {
                this._dropDown.Toggle();
            } else {
                this.InitCurrencyDropdown(event.currentTarget);
                this._dropDown.Open();
            }
        }
    }

    ApplyProperties() {
        if (this.Properties) {
            // label
            if (this.Properties.Label) {
                const labelStyle = {backgroundColor: null, color: null};

                _.each(this.Properties.Label.Properties, (property: any) => {
                    if (property.BackgroundColor) {
                        labelStyle.backgroundColor = property.BackgroundColor;
                    }

                    if (property.Color) {
                        labelStyle.color = property.Color;
                    }
                });
                this._labelStyle(labelStyle);
            }

            // textInput
            if (this.Properties.TextInput) {
                const textInputStyle = {backgroundColor: null};

                _.each(this.Properties.TextInput.Properties, (property: any) => {
                    if (property.BackgroundColor) {
                        textInputStyle.backgroundColor = property.BackgroundColor;
                    }
                });

                this._textInputStyle(textInputStyle);
            }
        }

        if (this._form && (this._form.GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.LinkEditor])) {
            this.ApplyLinkEditorStyles();
        }
    }

    private ApplyLinkEditorStyles() {
        if(!this.FieldModel.HasLinkEditorVisibility){
            return;
        }
        const labelStyle = {
            color: null,
            fontWeight: null,
            fontStyle: null,
            textDecoration: null
        };
        if (this.FieldModel.FontColor) {
            labelStyle.color = this.FieldModel.FontColor;
        }
        labelStyle.fontWeight = FONT_STYLES.NORMAL; //default fontWeight

        if (this.FieldModel.FontStyles) {
            _.forEach(this.FieldModel.FontStyles, (style) => {

                switch ( style.Name.toLowerCase() ) {
                    case FONT_STYLES.BOLD:
                        labelStyle.fontWeight = FONT_STYLES.BOLD;
                        break;
                    case FONT_STYLES.UNDERLINE:
                        labelStyle.textDecoration = FONT_STYLES.UNDERLINE;
                        break;
                    case FONT_STYLES.ITALIC:
                        labelStyle.fontStyle = FONT_STYLES.ITALIC;
                        break;
                }
            })
        }

        this.Extend(labelStyle, this._labelStyle());
        this._labelStyle(labelStyle);
    }

    IsValid(): boolean {
        this._isValid(!this._isRequired || (!!this._value() && !!this._rateDate() && !!this._selectedCurrency()));
        return this._isValid();
    }

    IsModified(): boolean {
        const value = {
            CurrencyId: this._selectedCurrency().Id,
            Value: this._value(),
            RateDate: this._rateDate()
        };
        return this._originalValue !== value;
    }
}