import {deserialize, deserializeAs, serialize, serializeAs} from "libs/cerialize";

import { TagsScreenDataModel } from "Core/Controls/Tag/Models/TagsScreenDataModel";
import { ControlDataModel } from "./ControlDataModel";
import { RightsModel } from "./RightsModel";
import { RecordSpecsModel } from "./RecordSpecsModel";
import { ActionSubjectRecordModel } from "./ActionSubjectRecordModel";

export class ScreenDataModel {
	@deserialize
	RecordId: number;

	@deserialize
	RecordExists: boolean;

	constructor() {
		this.ControlsData = [];
		this.QueryBuilderData = [];
		this.RecordId = 0;
	}

	@deserialize
	IsTypeTransformationRequired: boolean;

	@deserializeAs(ControlDataModel)
	ControlsData: Array<ControlDataModel>;

	@deserializeAs(ControlDataModel)
	QueryBuilderData: Array<ControlDataModel>;

	@deserializeAs(RightsModel)
	Rights: RightsModel;

	@deserializeAs(RecordSpecsModel)
	RecordSpecs: RecordSpecsModel;

	@deserializeAs(TagsScreenDataModel)
	ScreenTags: Array<TagsScreenDataModel>;

	@deserializeAs(ActionSubjectRecordModel)
	ActionSubjectRecord: ActionSubjectRecordModel;
}