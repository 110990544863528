import * as ko from "knockout";

import {EVENTS, PortletRenderModes, RenderModes} from "Core/Constant";
import {P} from "Core/Common/Promise";

import {BasePortletManager} from "Core/Portlets/Managers/Base/BasePortletManager"
import {EntityPortlet} from "Core/Portlets/Models/Design/Explorer/EntityPortlet"
import type {Portlet} from "Core/Portlets/Models/Runtime/Portlet"
import {ScreenStore} from 'Core/ScreenManager/Stores/ScreenStore';
import {ScreenModel} from 'Core/Models/Screens/ScreenModel';
import {IPortletParams} from "Core/Portlets/IPortletParams";
import {ScreenDataModel} from "Core/ScreenManager/Models/ScreenDataModel";

import {PortletStates} from "Core/Portlets/Enums/PortletStates"
import {IPosition} from "Core/Portlets/Models/Common/Interfaces/IPosition";

import {EventHandlerCallback, PortletBarEventHandlersFactory} from "Core/Portlets/Utils/PortletBarEventHandlersFactory"
import {RemoveButtonViewModel} from "Core/Controls/PortletBar/Models/Buttons/RemoveButtonViewModel"
import {SettingsButtonViewModel} from "Core/Controls/PortletBar/Models/Buttons/SettingsButtonViewModel";
import {ReloadButtonViewModel} from 'Core/Controls/PortletBar/Models/Buttons/ReloadButtonViewModel';

import {SettingsButton} from "Core/Controls/PortletBar/Managers/Buttons/SettingsButton";
import {RemoveButton} from "Core/Controls/PortletBar/Managers/Buttons/RemoveButton";
import {ReloadButton} from 'Core/Controls/PortletBar/Managers/Buttons/ReloadButton';

import {IPortletRuntimeSettings} from "Core/Controls/PortletBar/Interfaces/IPortletRuntimeSettings";

import {RuntimeSettingsReader} from "Core/Controls/PortletBar/Utils/RuntimeSettingsReader";

import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager'
import {LABELS} from "Core/Components/Translation/Locales";

import PortletManagerTemplate from 'Core/Portlets/Templates/PortletManager.html';

ko.templates["Core/Portlets/Templates/PortletManager"] = PortletManagerTemplate;

export class PortletManager extends BasePortletManager {
    private _params: IPortletParams;
    private _portlet: Portlet;
    private _labels = LABELS;

    get Portlet() {
        return this._portlet;
    }

    constructor(params: IPortletParams) {
        super();

        this._params = params;
        this.renderMode(params.IsNew ? PortletRenderModes.Design : PortletRenderModes.View);
    }

    async LoadPortlet(portletId: number, screenId: number, position: IPosition, model: ScreenModel) {
        this.SetPortletSubject(model);

        const portlet = (await import ("Core/Portlets/Models/Runtime/Portlet")).Portlet;
        this._portlet = new portlet(model);
        this.Portlet.ObjectId = portletId;
        this.Portlet.Position = position;

        this.BindPortletEvents();
        this.ApplyRuntimeSettings();
    }

    GeneratePortlet(portlet: EntityPortlet): P.Promise<any> {
        let deferedResult = P.defer<any>();

        ScreenStore.GetScreenById({
            ScreenId: portlet.ScreenId
        })
            .then(async (screen: ScreenModel) => {
                this.SetPortletSubject(screen);

                const portletType = (await import ("Core/Portlets/Models/Runtime/Portlet")).Portlet;

                this._portlet = new portletType(screen);
                this.Portlet.State = this._params.IsNew ? PortletStates.New : PortletStates.NoChanges;

                this.Portlet.Position = portlet.Position;
                this.Portlet.OldPosition = portlet.Position;

                this.BindPortletEvents();
                this.ApplyRuntimeSettings();
                this.SwitchRenderMode(PortletRenderModes.Design);

                deferedResult.resolve(null);
            });

        return deferedResult.promise();
    }

    ReloadPortlet() {
        this._portlet.Refresh();
    }

    SwitchRenderMode(renderMode: PortletRenderModes) {
        if (renderMode === PortletRenderModes.View) {
            renderMode = this.Portlet.GetRuntimeSettings().ReadOnly() ? PortletRenderModes.View : PortletRenderModes.Edit;
        }

        super.SwitchRenderMode(renderMode);

        this.Portlet.PortletBar.Buttons()
            .filter(button => button instanceof RemoveButton)
            .forEach(button => (<RemoveButtonViewModel>button.ViewModel).DisplayValue(button.ViewModel.Properties().Visible() && renderMode === PortletRenderModes.Design));

        this.Portlet.PortletBar.Buttons()
            .filter(button => button instanceof SettingsButton)
            .forEach(button => (<SettingsButtonViewModel>button.ViewModel).DisplayValue(button.ViewModel.Properties().Visible() && renderMode !== PortletRenderModes.Design));
    }

    SetState(state: PortletStates) {
        this.Portlet.State = state;
    }

    SetStateForcibly(state: PortletStates) {
        this.Portlet.State = state;
    }

    GetTemplateName(): string {
        return "Core/Portlets/Templates/PortletManager";
    }

    AfterRender() {
    }

    AddRuntimeSettings() {
        let runtimeSettings = this.Portlet.GetRuntimeSettings();

        let userVariables = UserVarsManager.Instance;

        userVariables.SetPortletSettings(this.Portlet.ObjectId, ko.toJSON(runtimeSettings));
    }

    private SetPortletSubject(model: ScreenModel) {
        if (this._params.Subject) {
            model.EntityId = this._params.Subject.EntityId;
            model.Data = new ScreenDataModel();
            model.Data.RecordId = this._params.Subject && this._params.Subject.RecordId;
        }
    }

    private GetRenderMode(screen: ScreenModel): RenderModes {
        let [portletBarControl, ...otherControls] = screen.SubForms[0].Controls;

        let config;

        try {
            config = JSON.parse(portletBarControl.Properties);
        } catch (err) {
            return RenderModes.View;
        }

        if (!config || !config.ReadOnly) {
            return RenderModes.View;
        }

        return config.ReadOnly.Properties[0].ReadOnly ? RenderModes.View : RenderModes.Edit;
    }

    private ApplyRuntimeSettings() {
        this.Portlet.ApplyRuntimeSettings(this.GetRuntimeSettings());
    }

    private GetRuntimeSettings(): IPortletRuntimeSettings {
        let variablesManager = UserVarsManager.Instance;

        let runtimeSettings = variablesManager.GetPortleteSettings(this.Portlet.ObjectId);

        return RuntimeSettingsReader.GetRuntimeSettings(runtimeSettings);
    }

    private UpdateRuntimeSettings(runtimeSettings: IPortletRuntimeSettings) {
        let userVariables = UserVarsManager.Instance;
        if (this.Portlet.State !== PortletStates.New) {
            userVariables.SetPortletSettings(this.Portlet.ObjectId, ko.toJSON(runtimeSettings));
        }
    }

    private BindPortletEvents() {
        let controlButtonsClickEvents = EVENTS.PORTLETS.PORTLET_BAR.CONTROL_BUTTONS;

        let controlButtonsEventsHandlers: { [eventName: string]: EventHandlerCallback } = {};
        controlButtonsEventsHandlers[EVENTS.PORTLETS.PORTLET_BAR.CONTROL_BUTTONS.REMOVE_BUTTON_CLICKED] = {Handler: this.RemovePortlet}
        controlButtonsEventsHandlers[EVENTS.PORTLETS.PORTLET_BAR.CONTROL_BUTTONS.RELOAD_BUTTON_CLICKED] = {Handler: this.ReloadPortlet}

        let controlButtonsHandlersFactory = new PortletBarEventHandlersFactory<PortletManager, Portlet>(this, this.Portlet, controlButtonsEventsHandlers);

        Object.keys(controlButtonsClickEvents).forEach(event => {
            controlButtonsHandlersFactory.BindEvent(controlButtonsClickEvents[event]);
        });

        this.Portlet.On(EVENTS.PORTLETS.RUNTIME_SETTINGS_UPDATED, this, eventArgs => this.UpdateRuntimeSettings(eventArgs.data));
    }

    private RemovePortlet() {
        this.Trigger(EVENTS.PORTLETS.PORTLET_BAR.CONTROL_BUTTONS.REMOVE_BUTTON_CLICKED, this);
    }
}