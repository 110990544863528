import * as ko from 'knockout'
import * as _ from 'underscore';

import {FlagResolver} from "Core/Common/FlagResolver";
import {FieldFlags} from "Core/Common/Enums/FieldFlags";

import {TranslationManager} from "Core/Components/Translation/TranslationManager";
import {LanguageModel} from "Core/Components/Translation/Models/LanguageModel";

import {CONTROL_TYPES, RenderModes} from 'Core/Constant'
import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";
import {IForm, IControlParam, IScreen} from 'Core/Screens/IScreen'
import {Event} from 'Core/Common/Event'
import {Guid} from 'Core/Common/Guid'
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel'
import {IControl} from 'Core/Controls/IControl'
import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel'
import {ControlDataModel} from 'Core/ScreenManager/Models/ControlDataModel'
import {RecordSpecsModel} from 'Core/ScreenManager/Models/RecordSpecsModel'
import clone from 'clone'
import {Icon} from 'Core/Icon/Icon'
import {GeneralProperties} from 'Core/GeneralProperties/GeneralProperties'
import {ConfigModel} from 'Core/GeneralProperties/Models/ConfigModel'

import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';
import {HelpBook} from 'HelpBook/HelpBook';
import {LABELS} from "Core/Components/Translation/Locales";
import {IWebSizeValue} from "Core/GeneralProperties/Managers/WebSizeProperty/WebSizeProperty";

import DesignTemplate from 'Core/Controls/BaseControl/Templates/DesignWrapper.html'
import StaticDesignTemplate from 'Core/Controls/BaseControl/Templates/StaticDesignWrapper.html'
import {BaseControlStore} from 'Core/Controls/BaseControl/BaseControlStore'
import PredefinedDesignTemplate from 'Core/Controls/BaseControl/Templates/PredefinedDesignWrapper.html'
import {IconModel} from "./Models/IconModel";
import {TranslationModel} from "./Models/TranslationModel";
import {ITooltipConfig} from "../../KnockoutExtentions/TooltipExtention";
import {MobileChecker} from 'Core/Common/MobileChecker';
import { IDynamicFieldData, IGetDynamicDependsOnValue, RecordStore } from 'Core/Common/Stores/RecordStore';
import { Notifier } from 'Core/Common/Notifier';
import { P } from 'Core/Common/Promise';
import { BlockUI } from 'Core/Common/BlockUi';
import { IControlAttachedField, IScreenVariable } from 'Core/Screens/BaseScreen';
import { IConditionValueDto } from 'Core/Common/Interfaces/IConditionValueDto';
import { QueryConditionItemModel } from '../Grid/Models/GridDataModel/QueryExpression/QueryConditionItemModel';
import { GENERAL_PROPERTIES } from 'Core/GeneralProperties/Managers/Constants';
import { GenericDeserialize } from 'libs/cerialize';
import { QueryConditionGroupModel } from '../Grid/Models/GridDataModel/QueryExpression/QueryConditionGroup';
import { EVENTS as SCREEN_EVENTS } from 'Core/Screens/Events';
import { ConditionBuilder } from 'QueryBuilder/QueryCondition/ConditionBuilder/ConditionBuilder';

import DataLoadTemplate from 'Core/Controls/BaseControl/Templates/DataLoadTemplate.html'

ko.templates['Core/Controls/BaseControl/Templates/DesignWrapper'] = DesignTemplate;
ko.templates['Core/Controls/BaseControl/Templates/StaticDesignWrapper'] = StaticDesignTemplate;
ko.templates['Core/Controls/BaseControl/Templates/PredefinedDesignWrapper'] = PredefinedDesignTemplate;
ko.templates['Core/Controls/BaseControl/Templates/DataLoadTemplate'] = DataLoadTemplate;

export interface IControlValue {
    SubjectRecordId: number;
    SubjectEntityId: number;
    Data?: ControlDataModel;
    Datas?: Array<ControlDataModel>;
    RecordSpecsModel: RecordSpecsModel;
}

export interface ITranslationValue {
    Value: string;
    TranslatedValue: string;
    Language: LanguageModel;
}

const focusableControls = [CONTROL_TYPES.Text];

export abstract class BaseControl extends Event implements IControl {
    protected _model: KnockoutObservable<ControlModel>;
    protected _renderMode: KnockoutObservable<RenderModes>;
    protected _form: IForm;
    protected _subControls: KnockoutObservableArray<IControl>;
    protected _guid: string;
    protected _isRendered: KnockoutObservable<boolean>;
    protected _display: KnockoutObservable<string>;
    protected _el: HTMLElement;
    protected _parentControl: IControl;
    protected _isWrapped: KnockoutObservable<boolean>;
    protected _icon: KnockoutObservable<IconModel>;
    protected _defaultIcon: Icon;
    protected _isStatic: boolean;
    private _generalProperties: ConfigModel;
    protected _isActive: KnockoutObservable<boolean>;
    protected _originalModel: string;
    protected _isValid: KnockoutObservable<boolean>;
    protected _isVisible: KnockoutObservable<boolean>;
    protected _isRequired: boolean;
    protected _isReadonly: boolean;
    protected _getCurrentName: any;
    protected _errorMessage: KnockoutObservable<string>;
    protected IsFocused: KnockoutObservable<boolean>;
    protected _help: HelpBook;
    protected _isIconVisible: boolean;
    protected _labels = LABELS;
    protected _initWithDefault: boolean;
    protected _webSizeValue: IWebSizeValue;
	protected Label: KnockoutObservable<string>;
	protected HelpPageLabel: KnockoutObservable<string>;
    protected DescriptionToolTip: KnockoutObservable<ITooltipConfig>;
    protected _resetDependsOnValue: KnockoutObservable<boolean>;
    protected _isDataLoading: KnockoutObservable<boolean>;
    protected _conditionScreenData: Map<string, string>;
    protected _isHideIfDataLoading: KnockoutObservable<boolean>;
    protected _gridRow: any;

    protected _enabled: KnockoutObservable<boolean>;
    protected _backgroundColor: KnockoutObservable<string>;
    protected _color: KnockoutObservable<string>;
    protected _borderColor: KnockoutObservable<string>;
    protected _border: KnockoutObservable<boolean>;
    protected _style: KnockoutComputed<{}>;
    protected HideCaption: KnockoutObservable<boolean>;
    protected HideCaptionFromProperty: KnockoutObservable<boolean>;

    CreateControl: (params: IControlParam) => IControl;

    GetTemplateName: KnockoutComputed<string>;
    


    constructor(params: IControlParam, config: JSON = null) {
        super();
        this._model = ko.observable(params.Model);
        this._form = params.Form;
        this._parentControl = params.ParentControl;
        this.CreateControl = params.CreateControl;

        this._model.subscribe(() => {
            if(this._model().Icon){
                this._icon(new Icon(this._model().Icon));
            }

            if(config){
                this.InitProperties(config);
            }    

            if (this.Properties) {
                this.ApplyProperties();
            }
        });

        if(config){
            this.InitProperties(config);
        }

        this._guid = Guid.NewGuid();
        this._conditionScreenData = new Map<string, string>();
        this._isWrapped = ko.observable(false);
        this._isStatic = params.IsStatic || params.Model.Predefined || false;
        this._isRequired = this.GetIsRequired();
        this._isReadonly = this.GetIsReadOnly();
        this._renderMode = ko.observable(params.RenderMode);
        this._subControls = ko.observableArray<IControl>();
        this.InitSubControls();
        this._display = ko.observable('');
        this._isRendered = ko.observable(false);
        this._isActive = ko.observable(false);
        this._isValid = ko.observable(true);
        this._isVisible = ko.observable(true);
        this._el = null;
        this._getCurrentName = '';
        this._icon = ko.observable(null);
        this._errorMessage = ko.observable('');
        this._help = HelpBook.Instance;
        this._isIconVisible = GlobalManager.Instance.GetGlobal(GLOBALS.SHOW_CONTROL_ICONS) !== '0';
        this._webSizeValue = null;
        this._resetDependsOnValue = ko.observable(params.Model.ResetDependsOnValue);
        this._isDataLoading = ko.observable(false);
        this._isHideIfDataLoading = ko.observable(false);

		this.Label = ko.observable(this.GetLabel());
        this.DescriptionToolTip = ko.observable(null);
        this.InitShowIfCondition();

        this._originalModel = ko.toJSON(this._model,
            (key, value) => {
                if (key === 'SubControls') {
                    return [];
                }
                return value;
            });

        this.IsFocused = ko.observable(false).extend({throttle:200});

        this._subControls.subscribe((subControls) => {
            _.each(subControls,
                (control, index) => {
                    control.SetSort(index * 10);
                });
        });

        this.GetTemplateName = ko.computed(() => {
            if(this._isHideIfDataLoading()){
                return 'Core/Controls/BaseControl/Templates/DataLoadTemplate';
            }

            if (this._renderMode() === RenderModes.Design) {
                if (this._model().Predefined) {
                    return 'Core/Controls/BaseControl/Templates/PredefinedDesignWrapper';
                }

                if (this._isStatic) {
                    return 'Core/Controls/BaseControl/Templates/StaticDesignWrapper';
                }

                return 'Core/Controls/BaseControl/Templates/DesignWrapper';
            }

            let template = RenderModes[this._renderMode()];
            let templateName = `Core/Controls/${this.GetType()}/Templates/${template}`;

            return templateName;
        });

        //TODO use one model for icon
        this._model().Fields.map((field) => {
            if (field.EntityTypeName === 'Link' || this.CheckEntityTypeByName(field.EntityName)) {
                field.RelatedField = true;
            }

            if (field.EntityTypeName === 'Sub') {
                field.SubTableField = true;
            }

            return field;
        });
        var field = _.first(this._model().Fields);
        if (field) {
            if (this._model().IsComplexControl) {
                if (field.EntityIcon) {
                    this._icon(new Icon(field.EntityIcon));
                }
            } else {
                if (field.FieldIcon) {
                    this._icon(new Icon(field.FieldIcon));
                }
            }
        }

        this.AddEvent("READY");
        this.AddEvent("AFTER_RENDER");
        this.AddEvent("CONTROL_MODIFY");

        if (this._renderMode() === RenderModes.View || this._renderMode() === RenderModes.Edit) {
            if (this._form) {
                if (this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.ListScreen]) {
                    this._isReadonly = false;
                }
            }
        }

        this._enabled = ko.observable(false)
        this._backgroundColor = ko.observable(null);
        this._color = ko.observable(null);
        this._borderColor = ko.observable(null);
        this._border = ko.observable(false);
        this._style = ko.computed(() => {
            return null;
        });
        this.HideCaption = ko.observable(null);
        this.HideCaptionFromProperty = ko.observable(null);
    }

    public PostInit(){
        this.UpdateShowIfValue();
    };

    private InitShowIfCondition(){
        if(this.HideIfCondition){
            this.Screen?.On(SCREEN_EVENTS.DATA_CHANGED, this, (eventArgs) => {
                let screenData: IScreenVariable = eventArgs.data.ScreenVariable;
                var screenVariableCondition = ConditionBuilder.GetScreenVariableCondition(screenData, this.HideIfCondition);
                if (screenVariableCondition.length > 0) {
                    this.SetConditionScreenData(screenVariableCondition, screenData.Value);
                    this.UpdateShowIfValue();
                }
            });
        }
    }

    UpdateShowIfValue(){
        if(!this.HideIfCondition){
            return;
        }
        this._isHideIfDataLoading(true);
        BlockUI.Block({ Target: this._el });
        BaseControlStore.GetHideIfConditionValue({
            ControlId: this.GetControlId(),
            SubjectEntityId: this.Screen.GetEntityId(),
            SubjectRecordId: this.Screen.GetRecordId(),
            ConditionValues: this.MapConditionValues()
        })
        .always(()=>{
            this._isHideIfDataLoading(false);
            BlockUI.Unblock(this._el);
        })
        .then(result=> {
            this._isVisible(!result);
        });
    }

    UpdateVariable(controlField: IControlAttachedField, value: string | number){
        this.Screen && this.Screen.UpdateVariable(controlField, value);
    }

    protected abstract ApplyProperties();

    private InitProperties(config: any){
        this._generalProperties = new GeneralProperties(config, this._model().Properties, this).Config;
    }

    set BackgroundColor(value: string){};

    get BackgroundColor(): string{
        return null;
    };

    SetData(data: ControlDataModel) {
        throw new Error('Method not implemented.');
    }

    ControlModify() {
        this.Trigger('CONTROL_MODIFY', {Control: this});
    }

    private CheckEntityTypeByName(entityName: string): boolean {
        if (!entityName) return false;

        if (entityName.indexOf('SYS_') !== -1 || entityName.indexOf('CD_') !== -1) {
            return entityName.split('').filter(item => item === '_').length > 1;
        } else {
            return entityName.indexOf('_') !== -1;
        }
    }

    IsJsonString(str){
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    SetIsWrapped(isWrapped) {
        this._isWrapped(isWrapped);
    }

    SetWebSizeValue(value: IWebSizeValue){
        this._webSizeValue = value;
    }

    SetIsVisible(isVisible: boolean) {
        this._isVisible(isVisible);
    }

    GetIsVisible() {
        return this._isVisible();
    }

    SetHideCaption(data: boolean) {
        this.HideCaption(data);
    }

    GetHideCaption(): boolean {
        return this.HideCaption();
    }

    SetFocus(hasFocus: boolean): void {
        let isMobile = MobileChecker.IsMobile();
        this.IsFocused(isMobile ? false : hasFocus);
    }

    TrySetFocus(): boolean {
        let focusable = this.IsFocusable();

        if (focusable) {
            this.SetFocus(true);
        }

        return focusable;
    }

    GetDefaultName() {
        return "Default name";
    }

    GetDesignTemplateName(): string {
        return `Core/Controls/${this.GetType()}/Templates/${RenderModes[RenderModes.Design]}`;
    }

    private InitSubControls() {
        _.each(this._model().SubControls, (controlModel: ControlModel) => {
            var params: IControlParam = {
                Model: controlModel,
                Form: this._form,
                RenderMode: this._renderMode(),
                ParentControl: this
            };

            var control = this.CreateControl(params);
            if (control) {
                this._subControls.push(control);
            }
        });
    }

    get Guid(): string {
        return this._guid;
    }

    get IsStatic(): boolean {
        return this._isStatic;
    }

    GetControlId(): number {
        return this._model().Id;
    }

    GetControlTypeName(): string {
        return this._model().TypeName;
    }

    GetControlTypeTranslatedName(): string {
        return this._model().TypeTranslatedName ? this._model().TypeTranslatedName : this._model().TypeName;
    }

    GetFieldId(): number {
        var field = _.first(this._model().Fields);
        return field ? field.Id : null;
    }

    GetFieldModel(): AttachedFieldModel {
        return this.FieldModel;
    }

    HasOneField(): boolean {
        return this._model().Fields.length === 1;
    }

    GetDefaultIcon(): Icon {
        return this._defaultIcon;
    }

    SetDefaultIcon(icon: Icon) {
        this._defaultIcon = icon;

        if (!this._icon()) {
            this._icon(this._defaultIcon);
        }
    }

    get IsDisabledByCondition(): boolean {
        return this._model().IsDisabledByCondition;
    }

    get IsMailEnabled(): boolean {
        return this._model().IsMailEnabled;
    }

    get FieldModel(): AttachedFieldModel {
        return _.first(this._model().Fields);
    }

    get FormatName() {
        const fieldModel = _.first(this._model().Fields);

        return fieldModel ? (fieldModel.FormatName || '') : '';
    }


    get Name(): string {
        var name = this._model().Name;
        var self = this;
        if (this._model().NameTranslations) {
            _.each(self._model().NameTranslations, item => {
                if (item.Selected && item.Translation && item.Translation !== '' && item.Translation !== null) {
                    name = item.Translation;
                    return;
                }
            });
        }
        return name;
    }

    set Name(value: string) {
        this._model().Name = value;
    }

    get NameTranslations(): TranslationModel[] {
        return this._model().NameTranslations;
    }

    set NameTranslations(translations: TranslationModel[]) {
        this._model().NameTranslations = translations;
    }

    get Icon(): IconModel {
        return this._icon();
    }

    SetFontName(fontName: string) {
        this._icon().FontName = fontName;
    }

    get LabelPosition(): string {
        return this._model().LabelPosition;
    }

    get ExampleAlias(): string {
        return this._model().ExampleAlias;
    }

    get Description(): string {
        return this._model().Description;
    }

    get Properties(): any {
        return this._model().Properties;
    }

    SetProperties(properties): void {
        this._model().Properties = properties;
    }

    get Model(): ControlModel {
        return this._model();
    }

    get IsEditScreenExist(): boolean {
        return this._form ? this._form.GetScreen().IsEditScreenExist : false;
    }

    get IsListScreenExist(): boolean {
        return this._form ? this._form.GetScreen().IsListScreenExist : false;
    }

    get IsSpecialScreenExist(): boolean {
        return this._form ? this._form.GetScreen().IsSpecialScreenExist : false;
    }

    get IsDashboardScreenExist() : boolean {
        return this._form ? this._form.GetScreen().IsDashboardScreenExist : false;
    }

    get IsConsultScreenExist(): boolean {
        return this._form ? this._form.GetScreen().IsConsultScreenExist : false;
    }

    get IsStepsScreenExist(): boolean {
        return this._form ? this._form.GetScreen().IsStepsScreenExist : false;
    }

    IsFocusable(): boolean {
        return (focusableControls.indexOf(this.GetType()) >= 0) && !this.GetCombinedReadOnly();
    }

    get GeneralProperties(): ConfigModel {
        return this._generalProperties;
    }

    ChangeProperty(type: string, value) {
        if (this._generalProperties && this.Properties) {
            this._generalProperties.SetPropertyValue(type, value);
        }
    }

    GetSubControls(): Array<IControl> {
        return this._subControls();
    }

    GetAllSubControls(): Array<IControl> {
        var subControls = [];
        subControls = subControls.concat(this.GetSubControls());
        _.each(subControls, subControl => {
            subControls = subControls.concat(subControl.GetAllSubControls());

        });
        return subControls;
    }

    SetValue(value: IControlValue): void {

    }

    SetDefaultValue(value: IControlValue): void {
        this.SetValue(value);
        this._initWithDefault = true;
    }


    SetRenderMode(renderMode: RenderModes): void {
        this._renderMode(renderMode);
    }

    GetRenderMode(): RenderModes {
        return this._renderMode();
    }

    SetReadOnly(readOnly: boolean): void {
    }

    GetType(): string {
        return this._model().TypeName;
    }

    GetIsReadOnly(): boolean {
        return this._model().IsReadOnly;
    }

    GetCombinedReadOnly() {
        return this._isReadonly || this.FieldModel.IsSystem;
    }

    GetIsRequired(): boolean {
        return this._model().IsRequired;
    }

    AfterRender(el: Array<HTMLElement>): void {
        if (el) {
            this._el = el[0];
		}

        this.CreateDescriptionToolTip();

        this._isRendered(true);
        this.Trigger('AFTER_RENDER');
    }

    AddStyle(name: string, value: string) {
        if (name === 'display') {
            this._display(value);
        }
    }

    IsDataControl(): boolean {
        return false;
    }

    IsLazyLoadData(): boolean {
        return false;
    }

    GetForm(): IForm {
        return this._form;
    }

    SetForm(form: IForm) {
        return this._form = form;
    }

    SetParentControl(control: IControl) {
        return this._parentControl = control;
    }

    AddSubControl(control: IControl) {
        this._subControls.push(control);
    }

    SetGuid(guid: string) {
        this._guid = guid;
    }

    GetGuid() {
        return this._guid;
    }

    Clone(): IControl {
        var model = clone(this._model());
        var controlParams: IControlParam = {
            Model: model,
            Form: this._form,
            ParentControl: this._parentControl,
            RenderMode: this._renderMode()
        }
        return this.CreateControl(controlParams);
    }

    ToDesignedControl(): IControl {
        var model = clone(this._model());
        model.Id = 0;
        var controlParams: IControlParam = {
            Model: model,
            Form: this._form,
            ParentControl: this._parentControl,
            RenderMode: RenderModes.Design
        }
        return this.CreateControl(controlParams);
    }

    RemoveControl(control: IControl) {
        this._subControls.remove(control);
        (this.GetForm().GetScreen() as any).OnControlRemoved(control);
    }

    GetValue(): any {}

    GetTranslations() {
        return [];
    }

    GetDefaultTranslation() {
        return null;
    }

    Deserialize() {
        return null;
    }

    GetModel(): ControlModel {
        return this._model();
    }

    SetModel(model: ControlModel) {
        this._model(model);
    }

    GetParentControl(): IControl {
        return this._parentControl;
    }

    ModelHasMutated() {
        this._model.valueHasMutated();
    }

    GetWidthSize(): string{
        let widthSizeValue = '';

        if (this.Model && this.Model.TypeName === 'Group'){
            if (this._webSizeValue){
                if (this._isWrapped()){
                    widthSizeValue = `calc(${this._webSizeValue.Value}${this._webSizeValue.Unit.Value} - 5px)`;
                } else {
                    widthSizeValue = `${this._webSizeValue.Value}${this._webSizeValue.Unit.Value}`;
                }
            } else {
                if (this._isWrapped()){
                    widthSizeValue = "calc(100% - 5px)";
                } else {
                    widthSizeValue = "100%";
                }
            }
        }
        return widthSizeValue;
    }

    GetWrapperClass(): string {
        var classes = '';

        if (this._isWrapped() && this.Model.TypeName === 'Memo') {
            classes += 'wrapped-image wrapped-memo ';
        } else if(this._isWrapped() && this.Model.TypeName === 'Group'){

            if (this._webSizeValue &&
                this._webSizeValue.Value == 100 &&
                this._webSizeValue.Unit.Value == '%'){
                classes += 'not-wrapped-group ';
            } else {
                classes += 'wrapped-group ';
            }

        } else if (this._isWrapped()) {
            classes += 'wrapped-image ';
        }

        if(this.Model.TypeName === 'Tab'){
            classes += 'tab-container ';
        }

        if(this.Model.TypeName === CONTROL_TYPES.Tag) {
            classes += 'tag-control-container ';
        }

        if (this.Model.TypeName === 'Memo') {
            classes += 'memo-container ';
        }

        if (this._model().IsParentControl) {
            classes += 'parent-control';
        }

        if (this._model().IsFieldAttached) {
            classes += 'field-attached';
        }

        if (this._model().IsParentForFields) {
            classes += 'parent-for-fields';
        }

        if (this._model().IsBlobFieldAttached) {
            classes += 'blob-field-attached';
        }
        if (this._model().IsLookupFieldAttached) {
            classes += 'lookup-field-attached';
        }

        if (this._model().IsColorFieldAttached) {
            classes += 'color-field-attached';
        }

        if (this._model().IsMultiSelectFieldAttached) {
            classes += 'multi-select-field-attached';
        }

        if (this._model().IsComplexControl) {
            classes += 'is-complex-control';
        }

        if (this.Model.TypeName === CONTROL_TYPES.ButtonEdit
            || this.Model.TypeName === CONTROL_TYPES.ButtonDelete
            || this.Model.TypeName === CONTROL_TYPES.ButtonListScreen
            || this.Model.TypeName === CONTROL_TYPES.ButtonSpecialScreen
            || this.Model.TypeName === CONTROL_TYPES.ButtonDashboardScreen
            || this.Model.TypeName === CONTROL_TYPES.ButtonReturn
            || this.Model.TypeName === CONTROL_TYPES.ButtonSecurityReset
            || this.Model.TypeName === CONTROL_TYPES.ButtonFavorite
            || this.Model.TypeName === CONTROL_TYPES.ButtonTemplate
            || this.Model.TypeName === CONTROL_TYPES.ButtonConsultScreen
            || this.Model.TypeName === CONTROL_TYPES.ButtonAdd
            || this.Model.TypeName === CONTROL_TYPES.GenericButton
            || this.Model.TypeName === CONTROL_TYPES.ButtonCopy
            || this.Model.TypeName === CONTROL_TYPES.History
            || this.Model.TypeName === CONTROL_TYPES.Timer
        ){
            classes += 'templateButtonsGroup';
        }

        return classes;
    }

    HasSubControl(typeName: string): boolean {
        return _.some(this.Model.SubControls, (control: ControlModel)=> control.TypeName === typeName);
    }

    GetWrapper(): HTMLElement {
        return this._el;
    }

    IsModified(): boolean {
        return this._initWithDefault;
    }

    GetIsActive(): boolean {
        return this._isActive();
    }

    SetIsActive(value: boolean) {
        this._isActive(value);
    }

    GetSort(): number {
        return this._model().Sort;
    }

    SetSort(value: number) {
        this._model().Sort = value;
    }

    get IsNew(): boolean {
        return this._model().Id === 0 || this._model().Id === null;
    }

    IsValid(): boolean {
        return true;
    }

    IsPropertiesValid(): boolean {
        if (this._generalProperties) {
            return this._generalProperties.IsValid();
        }

        return true;
    }

    IsPropertiesValidToSave(): boolean {
        if (this._generalProperties) {
            return this._generalProperties.IsValidToSave();
        }

        return true;
    }

    GetPropertiesErrors(): string[] {
        if (this._generalProperties) {
            return this._generalProperties.GetPropertiesErrors();
        }

        return [];
    }

    IsTranslatable() {
        return FlagResolver.ContainsFlag(FieldFlags.Translate, this._model().FieldFlag);
    }

    GetTranslation(data: ControlDataModel): ITranslationValue {
        return TranslationManager.Instance.GetTranslation(data.DisplayValue, data.Translations);
    }

    GetIcon() {
        return this._model().Icon;
    }

    UpdateLabel() {
        this.Label(this.GetLabel());
    }

    protected GetLabel(): string {
        if (this._model().UseFieldName && (this._model().IsFieldAttached || this._model().IsLookupFieldAttached || this._model().IsMultiSelectFieldAttached)) {
            let fieldAttached = _.first(this._model().Fields);
            if (fieldAttached) {
                this._getCurrentName = fieldAttached.FieldNameTranslation;
            }
        } else {
            var translationList = this._model().NameTranslations;
            _.each(translationList, item => {

                if (item.Selected && item.Translation !== '' && item.Translation !== null) {
                    this._getCurrentName = item.Translation;
                } else if (item.Selected) {
                    this._getCurrentName = this._model().Label;
                }
            });

            if (!translationList.length) {
                return this._model().TranslatedName || this._model().Label;
            }
        }

        if (this._getCurrentName) {
            return this._getCurrentName;
        } else {
            return this._model().Label;
        }
	}

    CreateDescriptionToolTip() {
	    this.DescriptionToolTip({
		    content: this.DescriptionToolTipContent,
		    onlyOnOverflow: true,
		    position: { x: 'left', y: 'center' },
		    outside: 'x',
		    addClass: 'ellipsis-tooltip'
	    });
    }

    get DescriptionToolTipContent(): string {
	    const controlDescriptionTranslationModel = _.find(this._model().DescriptionTranslations, item => item.Selected && item.Translation !== '' && item.Translation !== null);
	    const controlDescription = controlDescriptionTranslationModel
		    ? controlDescriptionTranslationModel.Translation
		    : this.Description;

	    if (controlDescription) {
		    return controlDescription;
	    }

	    const fieldAttached = _.first(this._model().Fields);
		if (fieldAttached) {
		    return fieldAttached.MemoTranslation || fieldAttached.Memo;
	    }

	    return null;
    }

    public SetIsRequired(isRequired: boolean) {
        this._isRequired = isRequired;
        this._model().IsRequired = isRequired;
    }

    Dispose() {

    }

    get Screen(): IScreen {
        return this._form ? this._form.GetScreen() : null;
    }

    get EnableResetDependOnValue(): boolean {
        return this.FieldModel && this.FieldModel.DynamicFields.length > 0 && this.FieldModel.FilledById != 0;
    }

    protected GetDynamicFieldsData(): Array<IDynamicFieldData>{
        let dynamicFieldValues: Array<IDynamicFieldData> = [];
        if(!this._form){
            return dynamicFieldValues;
        }

        _.each(this._form.GetScreen().GetAllControls(), (control) => {
            if([CONTROL_TYPES.Text, CONTROL_TYPES.Dropdown, CONTROL_TYPES.MultiSelect, CONTROL_TYPES.DateTime].indexOf(control.GetType()) >= 0){
                dynamicFieldValues.push(
                    {
                        FieldId: control.GetFieldModel().Id, Value: control.GetValue()
                    }
                );
            }            
        });

        if(this.IsLinkEditor && (this.Screen as any).RelationTypeField){
            dynamicFieldValues.push(
                {
                    FieldId: (this.Screen as any).RelationTypeField.Id, Value: (this.Screen as any).RelationTypeId
                }
            );
        }

        return dynamicFieldValues;
    }

    protected CalculateDependOnValue(): P.Promise<string>  {

        this._isDataLoading(true);

        const deferredResult = P.defer<string>();

        if (this._form.GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.LinkEditor]) {
            let screen = this._form.GetScreen() as any;
            let requestModel: IGetDynamicDependsOnValue = {
                SubjectRecordId: screen._mainRecordId,
                SubjectEntityId: screen._mainEntityId,
                RelatedEntityId: screen._relatedEntityId,
                RelatedRecordId: screen._relatedRecordId,
                KSeq: screen._kSeq,
                ScreenData: this.GetDynamicFieldsData(),
                FieldId: this.FieldModel.Id
            };

            RecordStore.GetDynamicDependsOnValues(requestModel)
            .always(()=>{
                this._isDataLoading(false);
            })
            .then((data) => {
                const dependsOnValue = (data.ResultObject && data.ResultObject.Value) || null;
                deferredResult.resolve(dependsOnValue);
                if (this._resetDependsOnValue()) {
                    this._resetDependsOnValue(false);
                }
            }).fail((error) => {
                let notifier = new Notifier(null);
                notifier.Failed(error.message);
            });
        }

        return deferredResult.promise();
    }

    get IsConsultScreen(): boolean {
        return this._form && this._form.GetScreen().IsConsultScreen;
    }

    get IsListScreen(): boolean {
        return this._form && this._form.GetScreen().IsListScreen;
    }

    get IsEditScreen(): boolean {
        return this._form && this._form.GetScreen().IsEditScreen;
    }


    get IsProcessCardPageScreen(): boolean {
        return this._form && this._form.GetScreen().IsProcessCardPageScreen;
    }
    get IsQueryScreen(): boolean {
        return this._form && this._form.GetScreen().IsQueryScreen;
    }

    get IsLinkEditor(): boolean {
        return this._form && this._form.GetScreen().IsLinkEditor;
    }

    get IsLIsSpecialScreen(): boolean {
        return this._form && this._form.GetScreen().IsSpecialScreen;
    }
    
    get IsPortlet(): boolean {
        return this._form && this._form.GetScreen().IsPortlet;
    }

    get IsEditMode(): boolean {
        return this._renderMode() === RenderModes.Edit;
    }

    get IsViewMode(): boolean {
        return this._renderMode() === RenderModes.View;
    }
    get IsHelpMode(): boolean {
        return this._renderMode() === RenderModes.HelpView;
    }

    get IsRunTime(){
        return this._form && this._renderMode() !== RenderModes.Design && this._renderMode() !== RenderModes.ToolBar;
    }

    get IsDesignTime(){
        return this._renderMode() === RenderModes.Design;
    }

    get Fields(): Array<AttachedFieldModel>{
        return this._model().Fields;
    }

    HasDynamicField(id: number): boolean{
        return !!_.find(this.FieldModel.DynamicFields, (field) => field === id );
    }

    MapConditionValues(): Array<IConditionValueDto>{
        let result: Array<IConditionValueDto> = [];
        
        if(this._conditionScreenData){
            this._conditionScreenData.forEach((value, key)=> {
                let res: IConditionValueDto = {
                    Guid: key,
                    Value: value
                } 
                result.push(res);
            });
        }
        return result;
    }

    SetConditionScreenData(conditions: Array<QueryConditionItemModel>, value: string){
        _.each(conditions, condition=> this._conditionScreenData.set(condition.Column.Guid, value));
    }

    get HideIfCondition(): QueryConditionGroupModel{
        let conditionString = this.GeneralProperties?.GetPropertyValue(GENERAL_PROPERTIES.HIDE_IF_CONDITION);
        if(!conditionString){
            return null;
        }
        
        let jsonObj = JSON.parse(conditionString);
        let condition = GenericDeserialize<QueryConditionGroupModel>(jsonObj, QueryConditionGroupModel);
        if(condition.HasAnyConditions){
            return condition;
        }
        return null;
    }

    ForceFocus(){

    }

    SetGridRow(gridRow: any) {
        this._gridRow = gridRow;
        this._renderMode(RenderModes.GridRow);
    }

    RenderByTargetId(target: string){
        let container = document.getElementById(target);
        ko.cleanNode(container);
        ko.applyBindings(this, container);
    }
}