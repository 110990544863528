import * as ko from 'knockout'
import * as _ from 'underscore'
import * as $ from "jquery"
import {Modal} from 'Core/Common/Modal'
import {Event} from 'Core/Common/Event'

import ImageCropperScreenTemplate
    from 'Core/Controls/Image/ImageCropperScreen/Templates/ImageCropperScreen.html'
import enumerable from '../../../Common/Decorators/EnumerableDecorator';

ko.templates['Core/Controls/Image/ImageCropperScreen/Templates/ImageCropperScreen'] = ImageCropperScreenTemplate;


export class ImageCropperScreen extends Event {
    private _modal: Modal;
    private _image: string;
    private _type: string;
    private _cropedImage: string;
    private _aspectRatio: number;
    private _windowWidth: number;

    constructor(isPortrait: boolean = false) {
        super();
        this._aspectRatio = isPortrait ? 3 / 4 : undefined;
        this._image = '';
        this.AddEvent('IMAGE_CROPPED');
        this._windowWidth = $(window).width();
    }

    set Image(image: string) {
        this._image = image;
    }

    get Image() {
        return this._image;
    }

    set Type(type: string) {
        this._type = type;
    }

    get Type() {
        return this._type;
    }

    GetTemplateName(): string {
        return 'Core/Controls/Image/ImageCropperScreen/Templates/ImageCropperScreen';
    }

    Show() {
        this._modal = new Modal({
            addClass: 'image-cropper-screen'
        });
        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
    }

    AfterRender() {
        this._modal.Show();
    }

    Save() {
        this.Trigger('IMAGE_CROPPED', {CropedImage: this._cropedImage});
        this._modal.Close();
    }

    Cancel() {
        this._modal.Close();
    }

    AfterCrop(image) {
        this._cropedImage = image;
    }
}