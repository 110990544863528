import * as moment from 'moment';

//Constants
import {DATE_FORMATS} from 'Core/Constants/DateTimeFormats';

//Store models
import { DayReportSummaryResponseModel } from "Core/Components/Controls/TimeWriting/Models/Store/Day/Response/DayReportSummaryResponseModel";

//ViewModels
import { DayOfWeekStatistics } from "Core/Components/Controls/TimeWriting/Models/View/DayDataViewModel"

export class DayMappings {
	static OnViewModel(model: DayReportSummaryResponseModel): DayOfWeekStatistics {
		const date = new Date(model.Date);

		const day = new DayOfWeekStatistics();

		day.Name = moment(date).format(DATE_FORMATS.WEEKDAY_SHORTNAME.MomentFormat);
		day.Date = date;
		day.Booked = Math.round(model.Booked * 100) / 100;
		day.ClosedDate = model.ClosedDate;

		return day;
	}
}