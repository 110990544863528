import * as ko from 'knockout';

import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';
import {EVENTS} from 'Core/Controls/Grid/BaseGrid/Events';
import {Event} from 'Core/Common/Event';
import { EntityMetadataModel } from 'Core/Controls/Grid/Models/GridDataModel/EntityMetadataModel';

import ActionCellTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/ActionCell.html';
import BasketButtonTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/BasketButton.html';
import {IControl} from 'Core/Controls/IControl';

import {LABELS} from "Core/Components/Translation/Locales";
import {TranslationManager} from "Core/Components/Translation/TranslationManager";
import { TABLE_TYPES } from '../../../../Constant';
import { ProcessCardsDropdown } from '../ProcessCardsDropdown/ProcessCardsDropdown';
import { LifeStatusSimpleModel } from '../../../ButtonFollowUp/Models/LifestatusSimpleModel';
import type { StepsScreen } from 'Core/Screens/StepsScreen/StepsScreen';
import { BlockUI, GridStore } from '../Index';

ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/ActionCell'] = ActionCellTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/BasketButton'] = BasketButtonTemplate;

export class ActionCell extends Event {
    private _showExpandButton: KnockoutObservable<boolean>;
    private _showColapsButton: KnockoutObservable<boolean>;

    private _isEditMode: KnockoutObservable<boolean>;
    private _enableEdit: KnockoutObservable<boolean>;
    private _isLocked: KnockoutObservable<boolean>;
    private _isLifeStatusEnabled: KnockoutObservable<boolean>;
    private _hideToggleDisableButton: KnockoutObservable<boolean>;
    private _hideUnlink: KnockoutObservable<boolean>;
    private _hideEdit: KnockoutObservable<boolean>;
    private _isEditScreen: KnockoutObservable<boolean>;
    private _isListScreen: KnockoutObservable<boolean>;
    private _isConsultScreen: KnockoutObservable<boolean>;
    private _isVisibleButtons: KnockoutObservable<boolean>;
    private _screenType: string;
    private _showEditButton: KnockoutObservable<boolean>;
    private _showDeleteButton: KnockoutObservable<boolean>;
    private _isCollapsed: KnockoutObservable<boolean>;
    private _labels = LABELS;
    private _unlinkButtonTitle: KnockoutComputed<string>;
    private _editLinkButtonTitle: KnockoutComputed<string>;
    private _isUnlinkButtonDisabled: KnockoutComputed<boolean>;
    private _isEditLinkButtonDisabled: KnockoutComputed<boolean>;
    private _isUnlinkCheckboxChecked: KnockoutObservable<boolean>;
    private _isMultipleUnlinkModeActive: KnockoutObservable<boolean> = ko.observable(false);
    private _showStepsScreenButton: KnockoutObservable<boolean>;
    private _processCardsLifeStatuses: Array<LifeStatusSimpleModel>;

    constructor(
        private _isEditable: KnockoutObservable<boolean> = ko.observable(false),
        private _enableBasket: KnockoutObservable<boolean> = ko.observable(false),
        private _recordInBasket: KnockoutObservable<boolean> = ko.observable(false),
        private _isDisableEditLinkButton: KnockoutObservable<boolean> = ko.observable(false),
        private _backLinkingButtons: KnockoutObservableArray<any> = ko.observableArray([]),
        private _isEditingAllowed: boolean,
        private _isCrossTable: boolean,
        private _inlineControls: Array<IControl>,
        private _enableExpaning,
        private _hasSubGridData,
        private _isRetired: boolean,
        private _rowEntity: EntityMetadataModel,
        private _isSubgrid: boolean,
        private _isLinkJoin: boolean,
        processCardsLifeStatuses,
        private _recordId: number,
        private _entityId: number
    ) {
        super();
        this._processCardsLifeStatuses = processCardsLifeStatuses || [];
        this._showExpandButton = ko.observable(true);
        this._showColapsButton = ko.observable(false);
        this._isEditMode = ko.observable(false);
        this._isLocked = ko.observable(false);
        this._isLifeStatusEnabled = ko.observable(false);
        this._hideToggleDisableButton = ko.observable(false);
        this._hideUnlink = ko.observable(false);
        this._hideEdit = ko.observable(false);
        this._isEditScreen = ko.observable(false);
        this._isListScreen = ko.observable(false);
        this._isConsultScreen = ko.observable(false);
        this._isVisibleButtons = ko.observable(false);
        this._showEditButton = ko.observable(false);
        this._showDeleteButton = ko.observable(false);
        this._isCollapsed = ko.observable(true);
        this._isUnlinkCheckboxChecked = ko.observable(false);
        this._showStepsScreenButton = ko.observable(false);

        this.AddEvent(EVENTS.EXPAND);
        this.AddEvent(EVENTS.COLLAPSE);
        this.AddEvent(EVENTS.EDIT_RECORD);
        this.AddEvent(EVENTS.EDIT_LINK);
        this.AddEvent(EVENTS.CANCEL_EDIT);
        this.AddEvent(EVENTS.SAVE_RECORD);
        this.AddEvent(EVENTS.DELETE_RECORD);
        this.AddEvent(EVENTS.CHANGE_LIFESTATUS);
        this.AddEvent(EVENTS.ADD_TO_BASKET);
        this.AddEvent(EVENTS.REMOVE_FROM_BASKET);
        this.AddEvent(EVENTS.UNLINK_RECORD);
        this.AddEvent(EVENTS.BACK_LINKING_POPUP_REQUESTED);
        this.AddEvent(EVENTS.CLICK_UNLINK_CHECKBOX);
        this.AddEvent('REFRESH')
        this.Init(); 
    }

    private Init(){
        this._unlinkButtonTitle = ko.computed(()=> {
            if(this._isRetired){
                return this._labels.LINKED_RECORD_IS_RETIRED;
            }

            return this._isEditingAllowed ? this._labels.UNLINK_RECORD_LABEL : this._labels.ACCESS_DENIED_LABEL;
        });

        this._editLinkButtonTitle = ko.computed(()=> {
            if(this._isRetired){
                return this._labels.LINKED_RECORD_IS_RETIRED;
            }

            return this._isEditingAllowed ? this._isDisableEditLinkButton() ? this._labels.NO_ANY_CUSTOM_FIELDS : this._labels.EDIT_LINK_LABEL : this._labels.ACCESS_DENIED_LABEL;
        });

        this._isUnlinkButtonDisabled = ko.computed(()=> {
            if(this._isRetired){
               return true;
            }

            return !this._isEditingAllowed;
        });
       
        this._isEditLinkButtonDisabled = ko.computed(()=> {
            if(this._isRetired){
               return true;
            }

            return !this._isEditingAllowed ? true : this._isDisableEditLinkButton();
        });
    }

    set ScreenType(value: string) {
        this._screenType = value;        
        this._isEditScreen(value === ScreenTypes[ScreenTypes.EditScreen]);
        this._isListScreen(value === ScreenTypes[ScreenTypes.ListScreen] || value === ScreenTypes[ScreenTypes.SpecialScreen]);
        this._isConsultScreen(value === ScreenTypes[ScreenTypes.ConsultScreen]);
        this._showEditButton(((value === ScreenTypes[ScreenTypes.EditScreen] || value === ScreenTypes[ScreenTypes.ProcessCardPage]) && !this._isSubgrid) || value === ScreenTypes[ScreenTypes.ListScreen]
            || value === ScreenTypes[ScreenTypes.QueryScreen] || value === ScreenTypes[ScreenTypes.SpecialScreen]);
        
        let showDeleteButton = value === ScreenTypes[ScreenTypes.EditScreen] || (value === ScreenTypes[ScreenTypes.ListScreen] && this._rowEntity && this._rowEntity.Type === TABLE_TYPES.Sub);
        this._showStepsScreenButton(this._processCardsLifeStatuses.length > 0 && (value === ScreenTypes[ScreenTypes.ListScreen] || value === ScreenTypes[ScreenTypes.Portlet]));
        this._showDeleteButton(showDeleteButton);
    }

    set IsVisibleButtons(value: boolean) {
        this._isVisibleButtons(value);
    }

    get IsVisibleButtons(): boolean {
        return this._isLocked();
    }

    set IsLocked(value: boolean) {
        this._isLocked(value);
    }

    get IsLocked(): boolean {
        return this._isLocked();
    }

    set IsMultipleUnlinkModeActive(value: boolean) {
        this._isMultipleUnlinkModeActive(value);
    }

    get IsMultipleUnlinkModeActive(): boolean {
        return this._isMultipleUnlinkModeActive();
    }

    set IsLifeStatusEnabled(value: boolean) {
        this._isLifeStatusEnabled(value);
    }

    get IsLifeStatusEnabled() {
        return this._isLifeStatusEnabled();
    }

    set HideToggleDisableButton(value: boolean) {
        this._hideToggleDisableButton(value);
    }

    get HideToggleDisableButton() {
        return this._hideToggleDisableButton();
    }

    set HideUnlink(value: boolean) {
        this._hideUnlink(value);
    }

    get HideUnlink() {
        return this._hideUnlink();
    }

    set HideEdit(value: boolean) {
        this._hideEdit(value);
    }

    get HideEdit() {
        return this._hideEdit();
    }

    GetTemplateName(): string {
        return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/ActionCell';
    }

    GetBasketButtonTemplateName() {
        return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/BasketButton';
    }

    ExpandColapseNestedGrid() {
        if (this._hasSubGridData) {
            this._isCollapsed(!this._isCollapsed());
            if (this._isCollapsed()) {
                this.Trigger(EVENTS.COLLAPSE);
            } else {
                this.Trigger(EVENTS.EXPAND);
            }
        }
    }

    /**
     * @param e {MouseEvent}
     */
    EditRecord(that, event) {
        if (this._isEditingAllowed) {
            this.Trigger(EVENTS.EDIT_RECORD);
            this._isEditMode(true);
            event.currentTarget.dispatchEvent(new CustomEvent("resizeTable", {
                bubbles: true,
                cancelable: true
            }));
        }
    }

    EditLink() {
        if (this._isEditingAllowed && !this._isRetired) {
            if (!this._isDisableEditLinkButton()) {
                this.Trigger(EVENTS.EDIT_LINK);
            }
        }
    }

    DeleteRecord() {
        if (this._isEditingAllowed) {
            this.Trigger(EVENTS.DELETE_RECORD);
        }
    }

    UnlinkRecord() {
        if (this._isEditingAllowed  && !this._isRetired) {
            this.Trigger(EVENTS.UNLINK_RECORD);
        }
    }

    OnClickUnlinkCheckbox() {
        if (!this._isUnlinkCheckboxChecked()) {
            this._isUnlinkCheckboxChecked(true);
        } else {
            this._isUnlinkCheckboxChecked(false);
        }

        this.Trigger(EVENTS.CLICK_UNLINK_CHECKBOX);
    }

    ChangeLifeStatus() {
        if (this._isEditingAllowed) {
            this._isLifeStatusEnabled(!this._isLifeStatusEnabled());
            this.Trigger(EVENTS.CHANGE_LIFESTATUS);
        }
    }

    CancelEditRecord() {
        if (this._isEditingAllowed) {
            this.Trigger(EVENTS.CANCEL_EDIT);
            this._isEditMode(false);
        }
    }

    ShowBackLinkPopup(button: any) {
        this.Trigger(EVENTS.BACK_LINKING_POPUP_REQUESTED, {
            ButtonName: button.ButtonName,
            SubTableView: button.SubTableView,
            Intention: button.Intention
        });
    }

    SaveRecord() {
        this.Trigger(EVENTS.SAVE_RECORD);
    }

    AddToBasket() {
        if (!this._isCrossTable) {
            this.Trigger(EVENTS.ADD_TO_BASKET);
        }
    }

    RemoveFromBasket() {
        this.Trigger(EVENTS.REMOVE_FROM_BASKET);
    }

    set IsEditMode(value: boolean) {
        this._isEditMode(value);
    }

    GetCurrentName(backLinkingButton: any) {
        const currentLang = TranslationManager.Instance.GetCurrentLanguage();
        const translation = _.find(backLinkingButton.NameTranslations, (item: any) => item.Id === currentLang.Id);
        if (translation && translation.Value) {
            return translation.Value;
        }
        return backLinkingButton.ButtonName;
    }

    ShowProcessCardsLifeStatuses(context: ActionCell, evt){
        if(this._processCardsLifeStatuses.length == 1){
            this.ShowStepsScreen(this._processCardsLifeStatuses[0]);
            return;
        }
        let processCardsDropdown = new ProcessCardsDropdown(this._processCardsLifeStatuses);
        processCardsDropdown.Show(evt.target);
        processCardsDropdown.On('RECORD_SELECTED', this, (evtArgs)=>{
            this.ShowStepsScreen(evtArgs.data.status);
        });
    }

    async ShowStepsScreen(status: LifeStatusSimpleModel){
        BlockUI.Block();
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
        let screen = await screenManager.GetScreenById({ ScreenId: status.StepsScreenId }) as StepsScreen;
        screen.On('FINISH_PROCESS', this, ()=>this.SwitchToNextStatus(status.Id));
        screen.LoadStepControlData({ recordId: this._recordId });
        screen.ShowInModal();            
        BlockUI.Unblock();
    }

    SwitchToNextStatus(nextStatusId: number){
        GridStore.SwitchToNextStatus({ EntityId: this._entityId, RecordId: this._recordId, NextStatusId: nextStatusId }).then(()=>this.Trigger('REFRESH'));
    }
}