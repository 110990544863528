import * as ko from "knockout";
import * as _ from "underscore";

import { ControlGroupModel } from "Core/Controls/FormDesigner/ToolBar/ControlToolBar/Models/ControlGroupModel";
import { ControlFactory } from "Core/Controls/ControlFactory";
import { IControl } from "Core/Controls/IControl";
import { IControlParam } from "Core/Screens/IScreen";
import { RenderModes } from "Core/Constant";

export class ControlGroup {
	private _model: ControlGroupModel;
	private _controls: KnockoutObservableArray<IControl>;
	private _expanded: KnockoutObservable<boolean>;
	private _el: HTMLElement;

	constructor(model: ControlGroupModel) {
		this._model = model;
		this._controls = ko.observableArray([]);
		this._expanded = ko.observable(false);
		this.Init();
	}

	Init() {
		this._model.Controls = _.sortBy(this._model.Controls, control => control.TypeName);
		_.each(this._model.Controls, (controlModel) => {
			var controlParam: IControlParam = {
				Model: controlModel,
				Form: null,
				RenderMode: RenderModes.ToolBar
			};

			var control = ControlFactory.CreateControl(controlParam);
			if (control) {
				this._controls.push(control);
			}
		});
	}

	set IsExpaned(isExpanded: boolean) {
		this._expanded(isExpanded);
	}

	get IsExpaned() {
		return this._expanded();
	}

	get Name(): string {
		return this._model.Name;
	}

	get Controls(): KnockoutObservableArray<IControl> {
		return this._controls;
	}

	AfterRender(el: Array<HTMLElement>): void {
		this._el = el[0];
	}
}