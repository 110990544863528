import * as ko from "knockout"

import {IPortletRuntimeSettings} from "Core/Controls/PortletBar/Interfaces/IPortletRuntimeSettings"
import {ReadableRuntimeSettings} from "Core/Controls/PortletBar/Utils/ReadableRuntimeSettings"

export class RuntimeSettingsReader {
	constructor(public RuntimeSettings?: IPortletRuntimeSettings) { }

	static GetRuntimeSettings(readableRuntimeSettings: ReadableRuntimeSettings): IPortletRuntimeSettings {
		if (!readableRuntimeSettings) {
			return undefined;
		}

		let dataSpaceColor = readableRuntimeSettings.UsePortletBarColor ? readableRuntimeSettings.PortletColor : "#fff";
		
		let reader = new RuntimeSettingsReader({
			ReadOnly: ko.observable(readableRuntimeSettings.ReadOnly),
			Search: ko.observable(readableRuntimeSettings.Search),
			PortletColor: ko.observable(readableRuntimeSettings.PortletColor),
			UsePortletBarColor: ko.observable(readableRuntimeSettings.UsePortletBarColor),
			DataSpaceColor: ko.observable(dataSpaceColor),
			Kind: ko.observable(readableRuntimeSettings.Kind),
			Length: ko.observable(readableRuntimeSettings.Length),
			HeaderText: ko.observable(readableRuntimeSettings.HeaderText),
			CollapseButton: ko.observable(readableRuntimeSettings.CollapseButton),
			ExpandButton: ko.observable(readableRuntimeSettings.ExpandButton),
			SettingsButton: ko.observable(readableRuntimeSettings.SettingsButton),
			ReloadButton: ko.observable(readableRuntimeSettings.ReloadButton),
			RemoveButton: ko.observable(readableRuntimeSettings.RemoveButton),
			UseBodySpace: ko.observable(readableRuntimeSettings.PortletColor !== dataSpaceColor)
		});

		return reader.RuntimeSettings;
	}
}