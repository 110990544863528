import * as ko from 'knockout';
import * as _ from 'underscore';

import {EntityListItem} from 'QueryBuilder/EntityListMenu/EntityListItem';
import {Event} from "Core/Common/Event";
import {EVENTS} from 'QueryBuilder/Events';
import { FieldMetadataModel } from "Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel";

import EntityListMenuTemplate from 'QueryBuilder/EntityListMenu/Templates/EntityListMenu.html';

const DELETE_LIFESTATUS = 'Delete';

export class EntityListMenu extends Event {
	private _items: KnockoutObservableArray<EntityListItem>;
	private _enableLeave: boolean;
	private _referenceField: FieldMetadataModel;
	SelectedEntitiesCount: KnockoutComputed<number>;

	IsIterator: KnockoutObservable<boolean>;

	constructor(
		isIterator: boolean,
		referenceField: FieldMetadataModel
	) {
		super();
		this._referenceField = referenceField;
		this.IsIterator = ko.observable(isIterator);
		this._items = ko.observableArray([]);
		this.AddEvent(EVENTS.ENTITY_SELECTED);
		this.AddEvent(EVENTS.ENTITY_UNSELECTED);

		this.AddEvent(EVENTS.LOOKUP_ENTITY_SELECTED);
		this.AddEvent(EVENTS.LOOKUP_ENTITY_UNSELECTED);

		this.AddEvent(EVENTS.REFERENCE_LOOKUP_ENTITY_SELECTED);
		this.AddEvent(EVENTS.REFERENCE_LOOKUP_ENTITY_UNSELECTED);

		this.AddEvent(EVENTS.REFERENCE_ENTITY_SELECTED);
		this.AddEvent(EVENTS.REFERENCE_ENTITY_UNSELECTED);

		this.AddEvent(EVENTS.IS_ITERATOR_CHANGED);
		this._enableLeave = true;

		this.SelectedEntitiesCount = ko.computed(() => {
			return _.filter(this._items(), item => { return item.IsSelected() }).length;
		});

		this.IsIterator.subscribe((newValue) => {
			this.Trigger(EVENTS.IS_ITERATOR_CHANGED, { IsIterator: newValue });
		});
	}

	get Items(): KnockoutObservableArray<EntityListItem> {
		return this._items;
	}

	SetIterator() {
		this.IsIterator(!this.IsIterator());
	}

	AddItems(items: Array<EntityListItem>) {
		_.each(items, (item)=>this.InitEvents(item));
		this._items([...this._items(),...items]);
	}

	get ReferenceTitle(): string {
		if(this._referenceField){
			return this._referenceField.NameTranslation || this._referenceField.Name;
		}
		return '';
	}

	get ReferenceFieldId(): number {
		return this._referenceField.Id
	}

	get ReferenceField(): FieldMetadataModel {
		return this._referenceField
	}

	GetTemplateHtml() {
		return EntityListMenuTemplate;
	}

	SelectEntityItemById(entityId: number) {
		let itemToSelect = _.find(this._items(), item => {
			return (item.Model.Id === entityId);
		});

		if (itemToSelect) {
			itemToSelect.Off(EVENTS.ENTITY_SELECTED);
			itemToSelect.Off(EVENTS.ENTITY_UNSELECTED);
			itemToSelect.Off(EVENTS.LOOKUP_ENTITY_SELECTED);
			itemToSelect.Off(EVENTS.LOOKUP_ENTITY_UNSELECTED);
			itemToSelect.Off(EVENTS.REFERENCE_LOOKUP_ENTITY_SELECTED);
			itemToSelect.Off(EVENTS.REFERENCE_LOOKUP_ENTITY_UNSELECTED);
			itemToSelect.Off(EVENTS.REFERENCE_ENTITY_SELECTED);
			itemToSelect.Off(EVENTS.REFERENCE_ENTITY_UNSELECTED);
			itemToSelect.Off(EVENTS.SELECTED);
			itemToSelect.IsSelected(true);
			this.InitEvents(itemToSelect);
		}
	}

	SelectReferenceItemById(entityId: number, referenceFieldId: number) {
		let itemToSelect = _.find(this._items(), item => {
			return (item.Model.Id === entityId && item.ReferenceFieldId == referenceFieldId)
		});

		if (itemToSelect) {
			itemToSelect.Off(EVENTS.ENTITY_SELECTED);
			itemToSelect.Off(EVENTS.ENTITY_UNSELECTED);
			itemToSelect.Off(EVENTS.LOOKUP_ENTITY_SELECTED);
			itemToSelect.Off(EVENTS.LOOKUP_ENTITY_UNSELECTED);
			itemToSelect.Off(EVENTS.REFERENCE_LOOKUP_ENTITY_SELECTED);
			itemToSelect.Off(EVENTS.REFERENCE_LOOKUP_ENTITY_UNSELECTED);
			itemToSelect.Off(EVENTS.REFERENCE_ENTITY_SELECTED);
			itemToSelect.Off(EVENTS.REFERENCE_ENTITY_UNSELECTED);
			itemToSelect.Off(EVENTS.SELECTED);
			itemToSelect.IsSelected(true);
			this.InitEvents(itemToSelect);
		}
	}

	SelectLookupItemById(entityId: number, lookupFieldId: number) {
		let itemToSelect = _.find(this._items(), item => {
			return (item.Model.Id === entityId && item.LookupFieldId == lookupFieldId);
		});

		if (itemToSelect) {
			itemToSelect.Off(EVENTS.ENTITY_SELECTED);
			itemToSelect.Off(EVENTS.ENTITY_UNSELECTED);
			itemToSelect.Off(EVENTS.LOOKUP_ENTITY_SELECTED);
			itemToSelect.Off(EVENTS.LOOKUP_ENTITY_UNSELECTED);
			itemToSelect.Off(EVENTS.REFERENCE_LOOKUP_ENTITY_SELECTED);
			itemToSelect.Off(EVENTS.REFERENCE_LOOKUP_ENTITY_UNSELECTED);
			itemToSelect.Off(EVENTS.REFERENCE_ENTITY_SELECTED);
			itemToSelect.Off(EVENTS.REFERENCE_ENTITY_UNSELECTED);
			itemToSelect.Off(EVENTS.SELECTED);
			itemToSelect.IsSelected(true);
			this.InitEvents(itemToSelect);
		}
	}

	SelectReferenceLookupItemById(entityId: number, referenceLookupFieldId: number) {
		let itemToSelect = _.find(this._items(), item => {
			return (item.Model.Id === entityId && item.ReferenceLookupFieldId == referenceLookupFieldId);
		});

		if (itemToSelect) {
			itemToSelect.Off(EVENTS.ENTITY_SELECTED);
			itemToSelect.Off(EVENTS.ENTITY_UNSELECTED);
			itemToSelect.Off(EVENTS.LOOKUP_ENTITY_SELECTED);
			itemToSelect.Off(EVENTS.LOOKUP_ENTITY_UNSELECTED);
			itemToSelect.Off(EVENTS.REFERENCE_LOOKUP_ENTITY_SELECTED);
			itemToSelect.Off(EVENTS.REFERENCE_LOOKUP_ENTITY_UNSELECTED);
			itemToSelect.Off(EVENTS.REFERENCE_ENTITY_SELECTED);
			itemToSelect.Off(EVENTS.REFERENCE_ENTITY_UNSELECTED);
			itemToSelect.Off(EVENTS.SELECTED);
			itemToSelect.IsSelected(true);
			this.InitEvents(itemToSelect);
		}
	}

	get SelectedItemsCount(): number{
		return _.filter(this._items(), (item) =>{ return item.IsSelected()}).length;
	}

	InitEvents(item: EntityListItem) {
		item.On(EVENTS.ENTITY_SELECTED, this, (eventArgs: any) => {
			this._enableLeave = false;
			this.Trigger(EVENTS.ENTITY_SELECTED, eventArgs.data);
		});
		item.On(EVENTS.ENTITY_UNSELECTED, this, (eventArgs: any) => {
			if (item.IsEnableUncheck()) {
				this.Trigger(EVENTS.ENTITY_UNSELECTED, eventArgs.data);
				if(item.Model.Lifestatus === DELETE_LIFESTATUS){
					this._items.splice(this._items().indexOf(item), 1);
				}
			}
		});

		item.On(EVENTS.LOOKUP_ENTITY_SELECTED, this, (eventArgs: any) => {
			this._enableLeave = false;
			this.Trigger(EVENTS.LOOKUP_ENTITY_SELECTED, eventArgs.data);
		});
		item.On(EVENTS.LOOKUP_ENTITY_UNSELECTED, this, (eventArgs: any) => {
			if (item.IsEnableUncheck()) {
				this.Trigger(EVENTS.LOOKUP_ENTITY_UNSELECTED, eventArgs.data);
				if(item.Model.Lifestatus === DELETE_LIFESTATUS) {
					this._items.splice(this._items().indexOf(item), 1);
				}
			}
		});

		item.On(EVENTS.REFERENCE_LOOKUP_ENTITY_SELECTED, this, (eventArgs: any) => {
			this._enableLeave = false;
			this.Trigger(EVENTS.REFERENCE_LOOKUP_ENTITY_SELECTED, eventArgs.data);
		});
		
		item.On(EVENTS.REFERENCE_LOOKUP_ENTITY_UNSELECTED, this, (eventArgs: any) => {
			if (item.IsEnableUncheck()) {
				this.Trigger(EVENTS.REFERENCE_LOOKUP_ENTITY_UNSELECTED, eventArgs.data);
				if(item.Model.Lifestatus === DELETE_LIFESTATUS) {
					this._items.splice(this._items().indexOf(item), 1);
				}
			}
		});

		item.On(EVENTS.REFERENCE_ENTITY_SELECTED, this, (eventArgs: any) => {
			this._enableLeave = false;		
			this.Trigger(EVENTS.REFERENCE_ENTITY_SELECTED, eventArgs.data);
		});
		item.On(EVENTS.REFERENCE_ENTITY_UNSELECTED, this, (eventArgs: any) => {
			if (item.IsEnableUncheck()) {
				this.Trigger(EVENTS.REFERENCE_ENTITY_UNSELECTED, eventArgs.data);
				if(item.Model.Lifestatus === DELETE_LIFESTATUS) {
					this._items.splice(this._items().indexOf(item), 1);
				}
			}
		});

		item.On(EVENTS.SELECTED, this, (eventArgs: any) => {
			this.Select(item);
		});
	}

	Select(item: EntityListItem) {
		if (item.IsEnableUncheck()) {
			item.IsSelected(!item.IsSelected());
		}
	}

	AfterRender(args) {
		args && args[1] && $(args[1].parentNode).on('show.bs.dropdown', function () {
            const styling: any = {
                position: 'absolute',
                left: 0
            };
            styling.top = 43;
            styling['box-shadow'] = '5px -5px rgba(102, 102, 102, 0.5)';
			$(args[0]).css(styling).addClass('queryBuilderDropdown');
		});
	}

	MouseLeave(element: HTMLElement) {
		if (this._enableLeave) {
			$(element).parent().removeClass('open');
		}
		this._enableLeave = true;
	}
}