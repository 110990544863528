import * as ko from "knockout";
import { Guid } from "Core/Common/Guid";
import { LABELS } from "Core/Components/Translation/Locales";

import { LifeStatusGroups, LifeStatusGroupEnum } from "Core/Common/Enums/LifeStatusGroups";
import {EllipsisTooltipExtention} from "Core/KnockoutExtentions/EllipsisTooltipExtention";

import ItemNameTemplate from "Core/Components/PathRunner/Templates/ItemNameTemplate.html";
ko.templates["Core/Components/PathRunner/Templates/ItemNameTemplate"] = ItemNameTemplate;

export class PathRunnerNodeViewModel {
	Guid: string;
	Id: number;
	Name: string;
	TranslatedName: string;
	TypeId: number;
	TypeName: string;
    TypeTranslatedName: string;
    LifeStatusId: number;
	LifeStatusName: string;
	LifeStatusGroup: number;
	IsSubject: boolean;
	IsInitialNode: boolean;
	HasChildren: boolean;

	public _children: PathRunnerNodeViewModel[];
	private _tooltip: jBox;
	private _labels = LABELS;

	constructor() {
		this.Guid = Guid.NewGuid();
		this._children = [];
	}

	get Children() {
		return this._children;
	}

	GetTranslatedName() {
		return this.TranslatedName ? this.TranslatedName : this.Name;
	}

    GetTypeName() {
        return this.TypeTranslatedName ? this.TypeTranslatedName : this.TypeName ? this.TypeName : '-';
    }

	GetTemplateName() {
		return "Core/Components/PathRunner/Templates/ItemTemplate";
	}

	AfterRender() {

	}

	Add(node: PathRunnerNodeViewModel) {
		this._children.push(node);
	}

	AddRange(nodes: PathRunnerNodeViewModel[]) {
		nodes.forEach(this.Add.bind(this));
	}

	GetNode(id: string, callback: (matchedNode: PathRunnerNodeViewModel) => void) {
		if (this.Guid === id) {
			callback(this);
		}

		this._children.forEach(child => child.GetNode(id, callback));
	}

	GetNodeByRecordId(id: number, callback: (matchedNode: PathRunnerNodeViewModel) => void) {
		if (this.Id === id) {
			callback(this);
		}

		this._children.forEach(child => child.GetNodeByRecordId(id, callback));
	}

	SetInitialNode(initialNodeId: number) {
		this.IsInitialNode = this.Id === initialNodeId;
		this._children.forEach(childRecord => childRecord.SetInitialNode(initialNodeId));
	}

	AddEllipsisTooltip(data) {
		this._tooltip = EllipsisTooltipExtention.CreateEllipsisTooltipForPathRunner(data.node.original.record);
	}

	DestroyEllipsisTooltip() {
		if (this._tooltip instanceof jBox) {
			this._tooltip.destroy();
		}
	}

	IsRetired() {
		return this.LifeStatusName === 'Disabled' ||
			(this.LifeStatusGroup && LifeStatusGroups.GetLifeStatusGroup(this.LifeStatusGroup) === LifeStatusGroupEnum.Retired);
	}

	ToJson() {
		const nestedNodes = this._children.map(child => child.ToJson());
		let children: object[] | boolean;

		if (this.IsSubject || nestedNodes.length > 0) {
			children = nestedNodes;
		} else {
			children = this.HasChildren;
		}

		return {
			id: this.Guid,
			text: (ko as any).renderTemplateXHtml(ItemNameTemplate, { TypeName: this.GetTypeName(), Name: this.GetTranslatedName(), Tooltip: this._labels.RECORD_DISABLED, IsRetired: this.IsRetired(), IsInitialNode: this.IsInitialNode, Guid: this.Guid }),
			children: children,
			record: this
		};
	}
}