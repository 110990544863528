import * as _ from 'underscore';
import { serialize, serializeAs } from 'libs/cerialize';

import {RelationshipTypes} from "Core/Controls/Grid/BaseGrid/Enums/RelationshipTypes";

import { DataModel as LinkEditorDataModel } from 'Core/Screens/Common/LinkEditor/Models/DataModel'
import { RelationRecordViewModel } from './LinkListRelationsViewModel';
import {ActionSubjectRecordModel} from "Core/ScreenManager/Models/ActionSubjectRecordModel";

export class NewRelationModel {
	constructor(
		controlId: number,
		recordId: number,
		relatedEntityId: number,
		relatedRecordId: number,
		guid: string,
		level: number,
		isMain: boolean,
		isNewRecord: boolean
	) {
		this.ControlId = controlId;
		this.RecordId = recordId;
		this.RelatedEntityId = relatedEntityId;
		this.RelatedRecordId = relatedRecordId;
		this.Level = level;
		this.Guid = guid;
		this.IsMain = isMain;
		this.IsNewRecord = isNewRecord;
	}

	@serialize
	ControlId: number;

	@serialize
	RecordId: number;

	@serialize
	RelatedEntityId: number;

	@serialize
	RelatedRecordId: number;

	@serialize
	Guid: string;

	@serialize
	IsMain: boolean;

	Level: number;

	@serialize
    IsNewRecord: boolean;
}

export class DeletedRelationModel {
	constructor(entityId: number, recordId: number, kSeq: number, relationshipType: number = RelationshipTypes.None) {
		this.EntityId = entityId;
		this.RecordId = recordId;
		this.KSeq = kSeq;
		this.RelationshipType = relationshipType;
	}

	@serialize
	EntityId: number;

	@serialize
	RecordId: number;

	@serialize
	KSeq: number;

	@serialize
	RelationshipType: number;
}

export class ChangeRelationModel {
    constructor(entityId: number, oldId: number, newId: number, guid: string, kSeq?: number, oldKSeq?: number) {
		this.EntityId = entityId;
		this.OldId = oldId;
		this.NewId = newId;
		this.KSeq = kSeq;
        this.Guid = guid;
        this.OldKSeq = oldKSeq;
    }

	@serialize
	EntityId: number;

	@serialize
    OldId: number;

    @serialize
    OldKSeq: number;

	@serialize
	NewId: number;

	@serialize
	KSeq: number;

	@serialize
	Guid: string;
}

export class ChangeSecurityModel {
	@serialize
	EntityId: number;

	@serialize
	Id: number;

	@serialize
	Level: number;
}

interface DeleteRelation {
	EntityId: number;
	RecordId: number;
	KSeq: number;
	Guid: string;
	IsNew: boolean;
}

export class FieldDataModel {
	FieldId: number;
	FieldValue: Array<string>;
}

export class EditLinkDataModel {
	constructor(model: LinkEditorDataModel, relationGuid: string) {
		this.FieldValues = model.FieldValues;
		this.KSeq = model.KSeq;
		this.EntityId = model.EntityId;
		this.RecordId = model.RecordId;
		this.Guid = relationGuid;
	}

	FieldValues: Array<FieldDataModel>;
	EntityId: number;
	RecordId: number;
	KSeq?: number;
	Guid: string;
}

export class UpdateDataModel {
	MainEntityId: number;
	MainRecordId: number;

	ReferenceLookupFieldId: number;

	constructor() {
		this.MainRecordId = null;
		this.NewRelations = [];
		this.DeletedRelations = [];
		this.ChangedRelations = [];
		this.ChangedSecurity = [];
		this.LinkEditorChanges = [];
	}

	@serializeAs(NewRelationModel)
	NewRelations: Array<NewRelationModel>;

	@serializeAs(DeletedRelationModel)
	DeletedRelations: Array<DeletedRelationModel>;

	@serializeAs(ChangeRelationModel)
	ChangedRelations: Array<ChangeRelationModel>;

	@serializeAs(ChangeSecurityModel)
	ChangedSecurity: Array<ChangeSecurityModel>;

	@serializeAs(LinkEditorDataModel)
	LinkEditorChanges: Array<EditLinkDataModel>;

	@serializeAs(ActionSubjectRecordModel)
	ActionSubjectRecord: ActionSubjectRecordModel;

	AddNewRelation(controlId: number, recordId: number, relatedEntityId: number, relatedRecord: RelationRecordViewModel) {
		if (controlId && relatedEntityId && !!relatedRecord) {
			let deletedRelation = _.find(this.DeletedRelations, (item) =>
				item.RecordId === relatedRecord.Id && item.EntityId === relatedEntityId && item.KSeq === relatedRecord.KSeq);

			if (deletedRelation) {
				this.DeletedRelations = _.without(this.DeletedRelations, deletedRelation);

			} else {
				this.NewRelations.push(new NewRelationModel(controlId, recordId, relatedEntityId, relatedRecord.Id, relatedRecord.Guid, relatedRecord.Level, relatedRecord.IsMain(), relatedRecord.IsNewRecord));
			}
		}
	}

	DeleteRelation(relation: DeleteRelation) {
		if (relation.EntityId && relation.RecordId) {
			let newRelation = _.find(this.NewRelations, (item) =>
				item.RelatedRecordId === relation.RecordId && item.RelatedEntityId === relation.EntityId && item.Guid === relation.Guid);

			if (newRelation) {
				this.NewRelations = _.without(this.NewRelations, newRelation);
			}

			let changedRelation = _.find(this.ChangedRelations, (item) =>
				item.EntityId === relation.EntityId && item.OldId === relation.RecordId
				&& (item.Guid === relation.Guid || item.KSeq === relation.KSeq));

			if (changedRelation) {
				this.ChangedRelations = _.without(this.ChangedRelations, changedRelation);
			}

			let changedLevelRecord = _.find(this.ChangedSecurity, { EntityId: relation.EntityId, Id: relation.RecordId });
			if (changedLevelRecord) {
				this.ChangedSecurity = _.without(this.ChangedSecurity, changedLevelRecord);
			}

			if (relation.IsNew === false) {
				this.DeletedRelations.push(new DeletedRelationModel(relation.EntityId, relation.RecordId, relation.KSeq));
			}
		}
	}

	ChangeRelation(entityId: number, oldId: number, newId: number, kSeq: number, guid: string, oldKSeq: number) {
		if (entityId && oldId && newId) {
            let newMainLink = new ChangeRelationModel(entityId, oldId, newId, guid, kSeq, oldKSeq);
			let previousMainLink = _.findWhere(this.ChangedRelations, { EntityId: entityId });
			if (previousMainLink) {
				let previousMainLinkIndex = this.ChangedRelations.indexOf(previousMainLink);
				this.ChangedRelations.splice(previousMainLinkIndex, 1, newMainLink);
			}
			this.ChangedRelations.push(newMainLink);
		}
	}

	ChangeUserLevel(entityId: number, userId: number, level: number) {
		let existingLink = _.findWhere(this.ChangedSecurity, { EntityId: entityId, Id: userId });

		if (existingLink) {
			existingLink.Level = level;
			return;
		}

		let changedSecurity = new ChangeSecurityModel();

		changedSecurity.EntityId = entityId;
		changedSecurity.Id = userId;
		changedSecurity.Level = level;

		this.ChangedSecurity.push(changedSecurity);
	}
}