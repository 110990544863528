import * as koAMD from "knockout-amd-helpers";
import * as _ from "underscore";

import {LookupModel} from "LookupEditor/Store/Models/LookupModel"
import {RecordDataModel} from "Core/Screens/Models/RecordDataModel";
import {EditableColumnModel} from "LookupEditor/Models/EditableColumnModel";
import {EditableRowModel} from "LookupEditor/Models/EditableRowModel";
import {SystemFields} from "LookupEditor/Enums/SystemFields";
import {ColumnTypes} from "LookupEditor/Enums/ColumnTypes";
import {Notifier} from "Core/Common/Notifier";
import {Event} from "Core/Common/Event";
import {EVENTS} from "Core/Constant";
import {NOTIFICATIONS, LABELS} from "Core/Components/Translation/Locales";
import {ILookupGridModelOptions} from "../Interfaces/ILookupGridModelOptions";
import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

export class LookupGridModel extends Event {
    private _tableId: KnockoutObservable<number>;
    private _columns: KnockoutObservableArray<EditableColumnModel>;
    private _rows: KnockoutObservableArray<EditableRowModel>;

    AllRecordsCount: KnockoutObservable<number>;

    AvailableRecordsPerPage: KnockoutObservableArray<any>;
    SelectedRecordsPerPage: KnockoutObservable<number>;
    IsPrevPageAvailable: KnockoutComputed<boolean>;
    IsNextPageAvailable: KnockoutComputed<boolean>;

    CurrentPage: KnockoutObservable<number>;
    PagesCount: KnockoutComputed<number>;

    IsAnyRecordChanged: KnockoutComputed<boolean>;
    IsAnyRecordSelected: KnockoutComputed<boolean>;

    constructor(options?: ILookupGridModelOptions) {
        super();

        const config = this.BuildConfig(options);

        this._tableId = ko.observable(0);
        this._columns = ko.observableArray([]);
        this._rows = ko.observableArray([]);

        const lookupTable = config.Model;
        const prevCurrentValue = config.CurrentPage;

        if (lookupTable) {
            this._tableId = ko.observable(lookupTable.Id);
            lookupTable.Fields.forEach((field) => {
                if (field.Type !== ColumnTypes[ColumnTypes.Binary] && !field.IsHidden) {
                    this._columns.push(
                        new EditableColumnModel(field.ValTableId || lookupTable.Id, field)
                    );
                }
            });
        }

        this.AllRecordsCount = ko.observable(0);

        this.AvailableRecordsPerPage = ko.observableArray([
            {Display: '15', Value: 15},
            {Display: '25', Value: 25},
            {Display: '50', Value: 50},
            {Display: LABELS.ALL, Value: -1}
        ]);

        this.SelectedRecordsPerPage = ko.observable(config.RecordsPerPage);
        this.SelectedRecordsPerPage.subscribe(newSelectedValue => {
            this.Trigger(EVENTS.ON_SELECT_CHANGED, newSelectedValue);
        });

        this.CurrentPage = ko.observable(config.CurrentPage);
        this.CurrentPage.subscribe(newValue => {
            var selectedPage = this.CurrentPage();
            if (!isNaN(newValue) && Math.abs(newValue) <= this.PagesCount()) {
                selectedPage = newValue === 0 ? 1 : Math.floor(Math.abs(newValue));
                this.CurrentPage(selectedPage);
                this.Trigger(EVENTS.ON_PAGE_CHANGED, selectedPage);
            } else {
                this.CurrentPage(prevCurrentValue);
            }
        });

        this.PagesCount = ko.computed(() => {
            return this.SelectedRecordsPerPage() === -1 ? 1 : Math.ceil(this.AllRecordsCount() / this.SelectedRecordsPerPage());
        });

        this.IsPrevPageAvailable = ko.computed(() => {
            return this.CurrentPage() !== 1;
        });

        this.IsNextPageAvailable = ko.computed(() => {
            return this.CurrentPage() !== this.PagesCount();
        });

        this.IsAnyRecordChanged = ko.computed(() => {
            return _.any(this._rows(), (row: EditableRowModel) => row.IsRecordChanged());
        });


        this.IsAnyRecordSelected = ko.computed(() => {
            return _.any(this._rows(), (row: EditableRowModel) => row.IsRecordSelected());
        });
    }

    get TableId(): number {
        return this._tableId();
    }

    get Columns(): EditableColumnModel[] {
        return this._columns();
    }

    set Columns(items) {
        this._columns(items);
    }

    set AllRecordsSelected(selectAllRecords: boolean) {
        this._rows().forEach((row) => {
            row.IsRecordSelected(selectAllRecords);
        });
    }

    set Data(dataTable: Array<RecordDataModel>) {
        this._rows([]);

        dataTable.forEach((dataObject) => {
            this.AddRow(new EditableRowModel(dataObject));
        });
    }

    get SelectedRecordsId(): Array<number> {
        var primaryKeyField = _.findWhere(this._columns(), {IsPrimaryKey: true});

        if (primaryKeyField === undefined) {
            var notifier = new Notifier(null);
            notifier.Warning(NOTIFICATIONS.NO_PRIMARY_KEY);
            return null;
        }

        var selectedRecords: EditableRowModel[] = _.filter(this._rows(), (row) => {
            return row.IsRecordSelected()
        });

        var selectedRecordsId = selectedRecords.map((record) => {
            return parseInt(record.GetValue(primaryKeyField.Name));
        });

        return selectedRecordsId;
    }

    get ChangedRecords(): EditableRowModel[] {
        return _.filter(this._rows(), (row) => {
            return row.IsRecordChanged()
        });
    }

    AddColumn(column: EditableColumnModel) {
        this._columns.push(column);
    }

    AddRow(row: EditableRowModel) {
        this._rows.push(row);
    }

    MoveRowUp(row: EditableRowModel) {
        var selectedRowIndex = this._rows.indexOf(row);
        var selectedRowSortValue = row.GetValue(SystemFields[SystemFields.SORT]);

        var upperRowIndex = selectedRowIndex - 1;
        if (upperRowIndex < 0) {
            return;
        }
        var upperRow = this._rows()[upperRowIndex];
        var upperRowSortValue = upperRow.GetValue(SystemFields[SystemFields.SORT]);

        row.SetValue(SystemFields[SystemFields.SORT], upperRowSortValue);
        upperRow.SetValue(SystemFields[SystemFields.SORT], selectedRowSortValue);

        this._rows()[selectedRowIndex] = upperRow;
        this._rows()[upperRowIndex] = row;

        row.IsRecordChanged(true);
        upperRow.IsRecordChanged(true);

        upperRow.GetColumnData(SystemFields[SystemFields.SORT]).Changed = true;
        row.GetColumnData(SystemFields[SystemFields.SORT]).Changed = true;

        this._rows(this._rows());
    }

    MoveRowDown(row: EditableRowModel) {
        var selectedRowIndex = this._rows.indexOf(row);
        var selectedRowSortValue = row.GetValue(SystemFields[SystemFields.SORT]);

        var lowerRowIndex = selectedRowIndex + 1;
        if (lowerRowIndex === this._rows().length) {
            return;
        }
        var lowerRow = this._rows()[lowerRowIndex];
        var upperRowSortValue = lowerRow.GetValue(SystemFields[SystemFields.SORT]);

        row.SetValue(SystemFields[SystemFields.SORT], upperRowSortValue);
        lowerRow.SetValue(SystemFields[SystemFields.SORT], selectedRowSortValue);

        this._rows()[selectedRowIndex] = lowerRow;
        this._rows()[lowerRowIndex] = row;

        lowerRow.GetColumnData(SystemFields[SystemFields.SORT]).Changed = true;
        row.GetColumnData(SystemFields[SystemFields.SORT]).Changed = true;

        row.IsRecordChanged(true);
        lowerRow.IsRecordChanged(true);

        this._rows(this._rows());
    }

    ShowFirstPage() {
        this.CurrentPage(1);
    }

    ShowPrevPage() {
        if (+this.CurrentPage() > 1) {
            this.CurrentPage(+this.CurrentPage() - 1);
        }
    }

    ShowNextPage() {
        if (+this.CurrentPage() < this.PagesCount()) {
            this.CurrentPage(+this.CurrentPage() + 1);
        }
    }

    ShowLastPage() {
        if (this.PagesCount() > 1) {
            this.CurrentPage(this.PagesCount());
        }
    }

    Reset() {
        this._columns([]);
        this._rows([]);
    }

    private BuildConfig(options?: ILookupGridModelOptions): ILookupGridModelOptions {
        return {
            Model: options && options.Model || null,
            CurrentPage: options && options.CurrentPage || 1,
            RecordsPerPage: options && options.RecordsPerPage || 15
        };
    }
}