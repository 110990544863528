import * as ko from 'knockout';

import {EventBusConsumer} from 'Core/Common/EventBus/EventBusConsumer';

import {ConfirmationDialog, EVENTS, Types} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';

import {NavigationStack} from '../../Stores/NavigationStack/NavigationStack';
import {NavigationStackEvents} from '../../Stores/NavigationStack/NavigationStackEvents';

import { NavigationItem } from '../../Stores/NavigationStack/NavigationItem';

import { LABELS } from "Core/Components/Translation/Locales";

import Template from './Templates/BackButton.html';

export class BackButton extends EventBusConsumer {
    _labels = LABELS;
    private _navigateConfirmation: string;
    private _navigationItems: KnockoutObservableArray<NavigationItem>;
    private _visible: KnockoutComputed<boolean>;
    private _currentNavigationItem: NavigationItem;

    constructor(private _navigationStack: NavigationStack) {
        super();

        const [_, ...navigationItems] = this._navigationStack.GetAll();
        this._navigationItems = ko.observableArray(navigationItems);
        this._visible = ko.computed(() => this._navigationItems().length > 0);

        this._currentNavigationItem = null;
        this._navigateConfirmation = null;

        this.BindEvents();
    }

    get NavigationItems(): KnockoutObservableArray<NavigationItem> {
        return this._navigationItems;
    }

    get Visible(): KnockoutObservable<boolean> {
        return this._visible;
    }

    set NavigateConfirmation(message: string) {
        this._navigateConfirmation = message;
    }

    GetTemplate() {
        return Template;
    }

    Click() {
        this.DispatchEvent(NavigationStackEvents.CheckPage);
    }

    SelectNavigationItem(item: NavigationItem) {
        this._currentNavigationItem = item;
        this.DispatchEvent(NavigationStackEvents.CheckPage);
    }

    private Navigate() {
        const item = this._currentNavigationItem;
        if (!this._navigateConfirmation) {
            if (item) {
                this._navigationStack.NavigateTo(item);
                this._currentNavigationItem = null;
            } else {
                this._navigationStack.NavigateBack();
            }
            return;
        }

        const dialog = this.BuildNavigateConfirmation(() => item ? this._navigationStack.NavigateTo(item) : this._navigationStack.NavigateBack());
        this._navigateConfirmation = null;
        dialog.Show();
    }

    private BindEvents() {
        this.AssignEventBus(this._navigationStack.EventBus);

        this.HandleEvent<NavigationItem>(NavigationStackEvents.Push)
            .Using(() => this.RefreshItems())
            .Always();

        this.HandleEvent<NavigationItem>(NavigationStackEvents.Pop)
            .Using(() => this.RefreshItems())
            .Always();

        this.HandleEvent<NavigationItem>(NavigationStackEvents.Navigate)
            .Using(() => this.RefreshItems())
            .Always();

        this.HandleEvent<{ OldItem: NavigationItem, NewItem: NavigationStack }>(NavigationStackEvents.Update)
            .Using(() => this.RefreshItems())
            .Always();

        this.HandleEvent(NavigationStackEvents.Checked)
            .Using(() => this.Navigate())
            .Always();
    }

    private RefreshItems() {
        const [_, ...navigationItems] = this._navigationStack.GetAll();
        this._navigationItems(navigationItems);
    }

    private BuildNavigateConfirmation(onConfirm: () => void) {
        const dialog = new ConfirmationDialog({
            Text: this._navigateConfirmation,
            Type: Types.Question
        });

        dialog.On(EVENTS.CONFIRM_SELECTED, this, onConfirm);

        return dialog;
    }
}