import {deserialize} from 'libs/cerialize';
import {deserializeAs} from 'libs/cerialize';

export class ExampleRecordModel {
	@deserialize
	Id: number;

	@deserialize
	F_Type: number;

	@deserialize
	F_Kind: number;

	@deserialize
	Name: string;
}