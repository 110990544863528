import * as moment from "moment";
import {DATE_FORMATS} from "Core/Constants/DateTimeFormats";
import {GroupEditor} from "Core/Components/Controls/Scheduler/Utils/GroupEditor";
import {LABELS} from "../../../../Translation/Locales";

export class SchedulerViewModel {
    Week: number;
    Date: Date;
    StartingTime: String;
    NumberOfPeriods: number;
    Periods: Array<Period>;
    HeaderColumns: Array<HeaderColumn>;
    PeriodDuration: number;
    Groups: Array<GroupEditor>;
    AgendaConnection: boolean;
    CreatingData: boolean;
    IsValid: boolean;
	ReadingData: boolean;
	ScreenSubjectRecordName: string;
    Errors: Array<string>;
    Month: String;
    MonthDayYear: String;
    CurrentDate: any;
    AvailablePeriods: {
        [key: number]: {
            [key: number]: Array<{a: moment.Moment, b: moment.Moment}>
        }
    };

    constructor() {
        this.Date = new Date();
    }

    get WeekNumber() {
        return `${this.Week}`;
    }

    AfterRender(columnNumber, isRePlanMode: boolean, el, headerCol: HeaderColumn) {
        if (isRePlanMode && (this.HeaderColumns[columnNumber] === headerCol)) {
            const parent = $(el).parent(),
                  scrollElem = $(el).filter('.scheduler__header__column'),
                  scrollSize = scrollElem.offset().left - ($(window).width() - parent.width());
            parent.animate({
                scrollLeft: scrollSize
            });
        }
    }

    GetGetColumnWidthClass() {
        return 'header__subcolumn--width-' + this.Periods.length;
    }

    GetHeaderWidthClass() {
        return 'header__subcolumns--wrapper-' + this.Periods.length;
    }

    GetPeriods(): Array<Period> {
        return this.Periods;
    }
}

export class Period {
    Start: string;
    Duration: number;

    constructor(start: string, duration: number) {
        this.Start = start;
        this.Duration = duration;
    }

    get DisplayValue() {
        if (moment(this.Start).isValid()) {
            return moment(this.Start).format(DATE_FORMATS.TIME.Format);
        } else {
            return moment(moment().format(DATE_FORMATS.SHORT_DATE.Format) + " " + this.Start).format(DATE_FORMATS.TIME.Format);
        }
    }
}

export class HeaderColumn {
    Day: moment.Moment;
    Periods: Array<Period>;
    DisplayValue: string;
    private _labels = LABELS;

    constructor(day: moment.Moment, periods: Array<Period>, screen: string) {
        this.Day = day;
        this.Periods = periods;

        switch (screen) {
            case this._labels.AGENDA_MONTH:
                this.DisplayValue = this.Day.format(DATE_FORMATS.DAY_OF_WEK_ddd.MomentFormat)+', '+ this.Day.format(DATE_FORMATS.SHORT_DATE_SHORT_WEEK.Format)+', '+ this._labels.NUMBER_WEEKS +this.Day.format(DATE_FORMATS.WEEK_OF_YEAR_WW.Format);
                break;
            case this._labels.AGENDA_WEEK:
                this.DisplayValue = this.Day.format(DATE_FORMATS.DAY_OF_WEK_ddd.MomentFormat)+', '+ this.Day.format(DATE_FORMATS.SHORT_DATE_SHORT_WEEK.Format);
                break;
            case this._labels.AGENDA_WORK_WEEK:
                this.DisplayValue = this.Day.format(DATE_FORMATS.DAY_OF_WEK_ddd.MomentFormat)+', '+ this.Day.format(DATE_FORMATS.SHORT_DATE_SHORT_WEEK.Format);
                break;
            case this._labels.AGENDA_DAY:
                this.DisplayValue = this.Day.format(DATE_FORMATS.DAY_OF_WEK_ddd.MomentFormat)+', '+ this.Day.format(DATE_FORMATS.SHORT_DATE_SHORT_WEEK.Format);
                break;
            default:
                this.DisplayValue = this.Day.format(DATE_FORMATS.SHORT_DATE_SHORT_WEEK.Format);
        }
    }
}