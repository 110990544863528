import Template from 'MenuManager/Wiki/Wiki.html';
import { Modal } from '../../Core/Common/Modal';
import {CookieManager} from "Core/Common/CookieManager";
import { BlockUI } from '../../Core/Common/BlockUi';
import * as _ from 'underscore';

ko.templates['MenuManager/Wiki/Wiki'] = Template;

export class WikiJS {
    private _modal: Modal;
    private _src: KnockoutObservable<string>;
    constructor(){
        this._src = ko.observable(`/api/WikiApi/GetWiki?access_token=${ CookieManager.GetAuthToken()}`);
    }

    Show(){
        this._modal = new Modal({ widthInPercent: 99, heightInPercent: 99}, false);
        ko.applyBindings(this, this._modal.Wrapper);
        this._modal.Show();
        BlockUI.Block({ Target:  this._modal.Wrapper });
    }

    GetTemplateName(){
        return 'MenuManager/Wiki/Wiki';
    }

    LoadFinished(__, event: any){

        _.each($(event.target.contentWindow.document.body).find('a'), (el)=>{
            let href = el.getAttribute("href") as string;

            if(href.startsWith("/en")){
                el.href = '/api/WikiApi/GetWiki' + href;
            }

            el.href += `?access_token=${ CookieManager.GetAuthToken()}`;
        });

        $(event.target.contentWindow.document.body).find('[aria-label="Account"]').hide();

        const targetNode = $(event.target.contentWindow.document).find(".__view")[0];
        if(targetNode){
            const config = { attributes: true, childList: true, subtree: true };
            
            const callback = (mutationList, observer) => {
                for (const mutation of mutationList) {
                    if (mutation.type === "childList") {

                        if(mutation.target.nodeName === 'A'){
                            let href = mutation.target.getAttribute("href") as string;

                            if(href.startsWith("/en")){
                                mutation.target.href = '/api/WikiApi/GetWiki' + href + `?access_token=${ CookieManager.GetAuthToken()}`;
                            }
                        }

                        _.each($(mutation.target).find('a'), (el)=>{
                            let href = el.getAttribute("href") as string;
                            if(href.startsWith("/en")){
                                    el.href = '/api/WikiApi/GetWiki' + href + `?access_token=${ CookieManager.GetAuthToken()}`;
                            }
                        });
                    }
                }
            };
    
            const observer = new MutationObserver(callback);
            observer.observe(targetNode, config);
        }

        event.target.contentWindow.onbeforeunload = (()=>{
            BlockUI.Block({ Target:  this._modal.Wrapper });
        });

        BlockUI.Unblock(this._modal.Wrapper);
    }

    AfterRender(){}
}