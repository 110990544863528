import * as ko from "knockout";
import * as _ from 'underscore';

import { TableSecurityModel, TableModel, RightModel, Rights, TalbeModel } from "Core/Controls/TableSecurity/Models/TableSecurityModel";
import {LABELS} from "Core/Components/Translation/Locales";

export class TableGroupViewModel {
	Id: number;
	Name: string;
	AllSelected: KnockoutObservable<boolean>;

	constructor(model: TalbeModel) {
		this.Id = model.Id;
		this.Name = model.Name;
		this.AllSelected = ko.observable(false);
	}
}


export class RightViewModel {
	TableGroupId: number;
	Name: string;
	Value: number;
	IsChecked: KnockoutObservable<boolean>;

	constructor(right: RightModel) {
		this.TableGroupId = right.Value;
		this.Name = right.Name;
		this.Value = right.Value;
		this.IsChecked = ko.observable(right.IsChecked);
	}
}

export class TableViewModel {
	Id: number;
	Name: string;
	Rights: KnockoutObservableArray<RightViewModel>;

	constructor(table: TableModel) {
		this.Id = table.Id;
		this.Name = table.Name;
		let rights = table.Rights.map(right => new RightViewModel(right));
		this.Rights = ko.observableArray(rights);
	}
}

export class TableSecurityViewModel {
	Rights: Array<TableGroupViewModel>;
	Tables: Array<TableViewModel>;
	private _labels = LABELS;

	constructor(model: TableSecurityModel) {
		this.Rights = [this._labels.SHOW, this._labels.EDIT, this._labels.DELETE, this._labels.CREATE]
			.map((rightsTranslation) =>
				new TableGroupViewModel({
					Name: rightsTranslation,
					Id: this.GetRightsValue(rightsTranslation)
				}));
		this.Tables = model.Tables.map(table => new TableViewModel(table));
	}

	private GetRightsValue(rightsTranslation: string) {
		const rightsValue = [
			{
				Translation: this._labels.SHOW,
				Value: Rights.Show
			}, {
				Translation: this._labels.EDIT,
				Value: Rights.Edit
			}, {
				Translation: this._labels.DELETE,
				Value: Rights.Delete
			}, {
				Translation: this._labels.CREATE,
				Value: Rights.Create
			}];
		return _.find(rightsValue, rights => rights.Translation === rightsTranslation).Value
	}
}