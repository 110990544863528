import * as _ from 'underscore';
import {FastFilter} from 'Core/Controls/Grid/BaseGrid/FastFilter/FastFilter';
import {GridColumnModel} from 'Core/Controls/Grid/Models/GridDataModel/GridColumnModel';
import {EVENTS} from 'Core/Controls/Grid/BaseGrid/Events';
import {IGetGridDataRequestModel} from 'Core/Controls/Grid/Stores/GridStore';
import {FieldFormat} from 'Core/Common/FieldFormat';
import {FormatConverter} from 'FormatEditor/FormatConverter';
import {extractLookupValFieldMetadata} from 'Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel';
import { FIELD_TYPES } from 'Core/Constant';
import { LABELS } from 'Core/Components/Translation/Locales';

import LookupFilterTemplate from 'Core/Controls/Grid/BaseGrid/FastFilter/Filters/Lookup/LookupFilter.html';

export class LookupFilter extends FastFilter {
	constructor(model: GridColumnModel,
				recordId: number,
				tableViewId: number,
				getGridDataModel: IGetGridDataRequestModel,
				existedColumnAliases: string[]) {
		super(model, recordId, tableViewId, getGridDataModel, existedColumnAliases);

		this.Preselect();
	}

	protected Filter() {
		this.MapToSaveModel();

		this.Trigger(EVENTS.FAST_FILTER);
	}

	FormatValue(value: string) {
        if (!value || value === LABELS.EMPTY_VALUE) {
			return LABELS.EMPTY_VALUE;
		}

		const validationFieldMetadata = extractLookupValFieldMetadata(this._model.FieldMetadata);

		if (
			validationFieldMetadata &&
			_.contains([FIELD_TYPES.Decimal, FIELD_TYPES.Integer], validationFieldMetadata.Type)
		) {
			return FormatConverter.LocalizeDecimalOrInteger(
				validationFieldMetadata.FormatName === FieldFormat.PERCENTAGE
					? (Number(value) * 100).toFixed(
							validationFieldMetadata.Size < 2 ? 0 : validationFieldMetadata.Size - 2
					  )
					: value
			);
		}

		return value;
	}

	GetTemplate() {
		return LookupFilterTemplate;
	}
}