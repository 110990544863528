import {deserialize, deserializeAs} from 'libs/cerialize';
export class ConfigurationDescription {
    @deserialize
    Description: string;

    @deserialize
    DescriptionTranslation: string;
    
    @deserialize
    Level: string;
}