import * as ko from 'knockout'

import {TranslationModel} from "Core/Controls/BaseControl/Models/TranslationModel";
import {MemoOperationModel} from "../Response/MemoOperationModel";

export class MemoRecordModel {
    EntityId: number;
    RecordId: number;
    Memo: KnockoutObservable<string>;
    MemoPlaintext?: string;
    MemoTranslations: KnockoutObservableArray<TranslationModel>;
    Kseq?: number;
    FlowMemosOperations: MemoOperationModel[];
    FlowMemosOperationsSelectID: number;
    FlowMemosOperationsSelect: KnockoutObservable<MemoOperationModel>

    constructor() {
        this.Memo = ko.observable(null);
        this.MemoTranslations = ko.observableArray([]);

        this.FlowMemosOperations = [];
        this.FlowMemosOperationsSelect = ko.observable(null);
    }

    Init(){
        this.FlowMemosOperationsSelect(_.find(this.FlowMemosOperations, select => select.Id == this.FlowMemosOperationsSelectID ));
    }
}