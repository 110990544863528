import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize, GenericDeserialize, Serialize} from 'libs/cerialize';

import {SignInModel} from "Auth/Models/SignInModel";
import {SignInResultModel} from "Auth/Models/SignInResultModel";
import {UpdatePasswordModel} from "Auth/Models/UpdatePasswordModel";
import {SignOutModel} from 'Auth/Models/SignOutModel';
import {RequestResetPasswordModel} from "Auth/Models/RequestResetPasswordModel";
import {ResetPasswordModel} from "Auth/Models/ResetPasswordModel";
import {LanguageModel} from 'Auth/UI/AuthForm/Models/LanguageModel';

import OperationResult from "Core/Results/ResultModels/OperationResult";
import { UpdateTotpSecretModel } from '../Models/UpdateTotpSecretModel';

export class AuthStore {

    public static SignOut(params: SignOutModel): P.Promise<any> {
        let deferredResult = P.defer<any>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: URL.AUTH.LOGOUT,
                dataType: 'text',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams).then((fileDir) => {
            deferredResult.resolve(fileDir);
        });

        return deferredResult.promise();
    }

    public static SignIn(formData: SignInModel): P.Promise<SignInResultModel> {
        let deferredResult = P.defer<SignInResultModel>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: URL.AUTH.LOGIN,
                type: 'POST'
            }, params: formData
        }

        Request.Send(requestParams)
            .then(signInResult => deferredResult.resolve(GenericDeserialize(signInResult, SignInResultModel)))
            .fail(err => deferredResult.reject(this.GetSignInError(err)));

        return deferredResult.promise();
    }

    public static RequestResetPassword(model: RequestResetPasswordModel) {
        return Request.Send({
            proxy: {
                url: URL.AUTH.RESET_PASSWORD,
                type: 'GET'
            }, params: model
        });
    }

    public static ResetPassword(model: ResetPasswordModel, token: string) {
        return Request.Send({
            proxy: {
                url: URL.AUTH.RESET_PASSWORD + `?token=${token}`,
                type: 'POST'
            }, params: model
        });
    }

    public static SignInOutlook(formData: SignInModel): P.Promise<SignInResultModel> {
        let deferredResult = P.defer<SignInResultModel>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: URL.AUTH.LOGIN,
                dataType: 'json',
                type: 'POST'
            }, params: Serialize(formData)
        }

        Request.Send(requestParams)
            .then(signInResult => deferredResult.resolve(GenericDeserialize(signInResult, SignInResultModel)))
            .fail(err => deferredResult.reject(this.GetSignInError(err)));

        return deferredResult.promise();
    }

    public static SignInSolidworks(formData: SignInModel): P.Promise<SignInResultModel> {
        let deferredResult = P.defer<SignInResultModel>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: URL.AUTH.LOGIN,
                dataType: 'json',
                type: 'POST'
            }, params: Serialize(formData)
        }

        Request.Send(requestParams)
            .then(signInResult => deferredResult.resolve(GenericDeserialize(signInResult, SignInResultModel)))
            .fail(err => deferredResult.reject(this.GetSignInError(err)));

        return deferredResult.promise();
    }

    public static UpdateExpiredPassword(formData: UpdatePasswordModel): P.Promise<OperationResult> {
        let deferredResult = P.defer<OperationResult>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: URL.AUTH.UPDATE_EXPIRED_PASSWORD,
                dataType: 'json',
                type: 'POST'
            }, params: formData
        }

        Request.Send(requestParams)
            .then(updatePasswordResult => deferredResult.resolve(<OperationResult>Deserialize(updatePasswordResult, OperationResult)));

        return deferredResult.promise();
    }

    public static UpdateTotpSecret(formData: UpdateTotpSecretModel): P.Promise<OperationResult> {
        let deferredResult = P.defer<OperationResult>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: URL.AUTH.UPDATE_TOTP_SECRET,
                dataType: 'json',
                type: 'POST'
            }, params: formData
        }

        Request.Send(requestParams)
            .then(updatePasswordResult => deferredResult.resolve(<OperationResult>Deserialize(updatePasswordResult, OperationResult)));

        return deferredResult.promise();
    }

    public static UpdatePassword(formData: UpdatePasswordModel): P.Promise<OperationResult> {
        let deferredResult = P.defer<OperationResult>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: URL.AUTH.UPDATE_EXPIRED_PASSWORD,
                dataType: 'json',
                type: 'POST'
            }, params: formData
        }

        Request.Send(requestParams)
            .then(updatePasswordResult => deferredResult.resolve(<OperationResult>Deserialize(updatePasswordResult, OperationResult)));

        return deferredResult.promise();
    }

    public static GetDatabaseList(): P.Promise<string[]> {
        let deferredResult = P.defer<string[]>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: '/api/CyberConfigApi/GetDatabases',
                dataType: 'json',
                type: 'GET'
            }
        };

        Request.Send(requestParams)
            .then(databases => deferredResult.resolve(databases));

        return deferredResult.promise();
    }

    public static GetLanguages(databaseName: string): P.Promise<Array<LanguageModel>> {
        let deferredResult = P.defer<Array<LanguageModel>>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: '/api/AuthApi/GetLanguages',
                dataType: 'json',
                type: 'GET'
            },
            params: {DatabaseName: databaseName}
        }

        Request.Send(requestParams)
            .then((result) => {
                if (result.IsSuccessfull) {
                    deferredResult.resolve(<Array<LanguageModel>>Deserialize(result.ResultObject, LanguageModel));
                } else {
                    deferredResult.reject(result.ErrorMessage);
                }
            });

        return deferredResult.promise();
    }

    private static GetSignInError(error: P.Rejection): P.Rejection {
        const errorResult = JSON.parse(error.message);
        return { message: errorResult.Message, value: errorResult.ErrorCode};
    }
}