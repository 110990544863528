import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';

import {CreateRecipeVersionDto} from "../Models/Dto/CreateRecipeVersionDto";
import {GetRecipeDataDto} from "../Models/Dto/GetRecipeDataDto";
import {RecipeRecordModel} from "../RecipeEditorTree/Response/RecipeRecordModel";
import {RecipeDataModel} from "../Models/RecipeDataModel";
import {DeleteRecipeVersionDto} from "../Models/Dto/DeleteRecipeVersionDto";
import {GetRecipeDataByVersionDto} from "../Models/Dto/GetRecipeDataByVersionDto";
import {CopyRecipeVersionDto} from "../Models/Dto/CopyRecipeVersionDto";
import {SaveRecipeDataDto} from "../Models/Dto/SaveRecipeDataDto";
import {CheckIfRecipeIsCreatedDto} from "../Models/Dto/CheckIfRecipeIsCreatedDto";

export interface GetLinkedRecordsDto {
    RootEntityId: number;
    ParentRecordId: number;
    VersionId: number;
}

export class RecipeEditorStore {
    static CreateRecipeVersion(params: CreateRecipeVersionDto): P.Promise<RecipeDataModel>{
        const deferredResult = P.defer<RecipeDataModel>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: '/api/RecipeEditorApi/CreateRecipeVersion',
                dataType: 'json',
                type: 'POST'
            },
            params
        };

        Request.Send(requestParams)
            .then(result => {
                deferredResult.resolve(result);
            })
            .fail(error => {
                deferredResult.reject(error);
            });

        return deferredResult.promise();
    }

    static SaveRecipeData(params: SaveRecipeDataDto): P.Promise<RecipeDataModel>{
        const deferredResult = P.defer<RecipeDataModel>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: '/api/RecipeEditorApi/SaveRecipeData',
                dataType: 'json',
                type: 'POST'
            },
            params
        };

        Request.Send(requestParams)
            .then(result => {
                deferredResult.resolve(result);
            })
            .fail(error => {
                deferredResult.reject(error);
            });

        return deferredResult.promise();
    }

    static DeleteRecipeVersion(params: DeleteRecipeVersionDto): P.Promise<RecipeDataModel>{
        const deferredResult = P.defer<RecipeDataModel>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: '/api/RecipeEditorApi/DeleteRecipeVersion',
                dataType: 'json',
                type: 'POST'
            },
            params
        };

        Request.Send(requestParams)
            .then(result => {
                deferredResult.resolve(result);
            })
            .fail(error => {
                deferredResult.reject(error);
            });

        return deferredResult.promise();
    }

    static CopyRecipeVersion(params: CopyRecipeVersionDto): P.Promise<RecipeDataModel>{
        const deferredResult = P.defer<RecipeDataModel>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: '/api/RecipeEditorApi/CopyRecipeVersion',
                dataType: 'json',
                type: 'POST'
            },
            params
        };

        Request.Send(requestParams)
            .then(result => {
                deferredResult.resolve(result);
            })
            .fail(error => {
                deferredResult.reject(error);
            });

        return deferredResult.promise();
    }

    static GetRecipeDataByVersion(params: GetRecipeDataByVersionDto): P.Promise<RecipeDataModel>{
        const deferredResult = P.defer<RecipeDataModel>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: '/api/RecipeEditorApi/GetRecipeDataByVersion',
                dataType: 'json',
                type: 'POST'
            },
            params
        };

        Request.Send(requestParams)
            .then(result => {
                deferredResult.resolve(result);
            })
            .fail(error => {
                deferredResult.reject(error);
            });

        return deferredResult.promise();
    }

    static GetRecipeData(params: GetRecipeDataDto): P.Promise<RecipeDataModel>{
        const deferredResult = P.defer<RecipeDataModel>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: '/api/RecipeEditorApi/GetRecipeData',
                dataType: 'json',
                type: 'POST'
            },
            params
        };

        Request.Send(requestParams)
            .then(result => {
                deferredResult.resolve(result);
            })
            .fail(error => {
                deferredResult.reject(error);
            });

        return deferredResult.promise();

    }

    static GetLinkedRecords(requestModel: GetLinkedRecordsDto): P.Promise<RecipeRecordModel[]> {
        const deferredResult = P.defer<RecipeRecordModel[]>();

        const requestOptions: IRequestOptions = {
            proxy: {
                url: 'api/RecipeEditorApi/GetLinkedRecords',
                type: 'POST',
                dataType: 'json'
            },
            params: requestModel
        }

        Request.Send(requestOptions)
            .then(response => this.ResolveResponse(response, deferredResult))
            .fail((response) => this.ResolveFail(deferredResult, "Error!!!!"));

        return deferredResult.promise();
    }

    static CheckIfRecipeIsCreated(params: CheckIfRecipeIsCreatedDto): P.Promise<boolean>{
        const deferredResult = P.defer<boolean>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: '/api/RecipeEditorApi/CheckIfRecipeIsCreated',
                dataType: 'json',
                type: 'POST'
            },
            params
        };

        Request.Send(requestParams)
            .then(result => {
                deferredResult.resolve(result);
            })
            .fail(error => {
                deferredResult.reject(error);
            });

        return deferredResult.promise();
    }

    private static ResolveResponse(response, deferredResult: P.Deferred<any>) {
        if (response.length) {
            deferredResult.resolve(response);
            return;
        }

        this.ResolveFail(deferredResult, response.ErrorMessage);
    }

    private static ResolveFail(deferredResult: P.Deferred<any>, errorMessage: string) {
        const error = { message: errorMessage };
        deferredResult.reject(error);
    }
}