import * as $ from "jquery";
import "jqueryCokie";

export class CookieManager {
    private static authToken = 'token';
    private static refreshToken = 'refreshToken';
    private static adminToken = 'adminToken';
    private static sbiDesigner = 'sbiDesigner';

    public static Init() {
        $.cookie.raw = true;
    }

    public static SetValue(name: string, value: string) {
        $.cookie(name, value, {expires: 7});
    }

    public static GetValue(name: string): any {
        return $.cookie(name);
    }

    public static Remove(name: string) {
        $.removeCookie(name);
    }

    public static GetAuthToken() {
        return $.cookie(this.authToken);
    }

    public static SetAuthToken(token: string) {
        $.cookie(this.authToken, token, {expires: 14});
    }

    public static RemoveAuthToken() {
        $.removeCookie(this.authToken);
        $.removeCookie(this.authToken, { path: '/' });        
    }

    public static GetRefreshToken() {
        return $.cookie(this.refreshToken);
    }

    public static SetRefreshToken(token: string) {
        $.cookie(this.refreshToken, token, {expires: 28});
    }

    public static RemoveRefreshToken() {
        $.removeCookie(this.refreshToken);
    }

    public static GetAdminToken() {
        return $.cookie(this.adminToken);
    }

    public static SetAdminToken(token: string) {
        $.cookie(this.adminToken, token, {expires: 14});
    }

    public static RemoveAdminToken() {
        $.removeCookie(this.adminToken);
    }

    public static GetSBIDesigner() {
        return $.cookie(this.sbiDesigner);
    }

    public static SetSBIDesigner(sbiDesigner: string) {
        $.cookie(this.sbiDesigner, sbiDesigner, {expires: 7});
    }

    public static RemoveTokens() {
        this.RemoveAuthToken();
        this.RemoveRefreshToken();
        this.RemoveAdminToken();
        this.Remove(this.sbiDesigner);
    }
}