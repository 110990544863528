import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import { List } from 'underscore';
import { Deserialize, GenericDeserialize } from 'libs/cerialize';
import { PromptDto } from './Models/PromptDto';
import { MessageDto } from './Models/MessageDto';

export interface IAssistantMessageDto {
	Database?: any;
	ChatHistory: Array<MessageDto>;
}

export interface IAssistantMessage2Dto {
	Message?: string;
	threadId: string;
}

export interface IGenerateAIDto {
	DBStructure: string;
}

export class AssistantApi {


	static CreateThread(): P.Promise<string> {
		var deferredResult = P.defer<string>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/AssistantApi/CreateThread',
				type: 'GET'
			}
		};

		Request.Send(requestParams)
			.then(data => {				
				deferredResult.resolve(data);
			}).fail(err=>{				
				let jsonResult = JSON.parse(err.message);
				deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			});

		return deferredResult.promise();
	}

	static AssistantMessage2(params: IAssistantMessage2Dto): P.Promise<PromptDto> {
		var deferredResult = P.defer<PromptDto>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/AssistantApi/SendMessage',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {				
				deferredResult.resolve(GenericDeserialize(data, PromptDto));
			}).fail(err=>{				
				let jsonResult = JSON.parse(err.message);
				deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			});

		return deferredResult.promise();
	}

	static AssistantMessage(params: IAssistantMessageDto): P.Promise<PromptDto> {
		var deferredResult = P.defer<PromptDto>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/DataBaseDesignerApi/AssistantMessage',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {				
				deferredResult.resolve(GenericDeserialize(data, PromptDto));
			}).fail(err=>{				
				let jsonResult = JSON.parse(err.message);
				deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			});

		return deferredResult.promise();
	}

	static GenerateStructure(params: IAssistantMessageDto): P.Promise<PromptDto> {
		var deferredResult = P.defer<PromptDto>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/DataBaseDesignerApi/GenerateStructure',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {				
				deferredResult.resolve(data);
			}).fail(err=>{				
				let jsonResult = JSON.parse(err.message);
				deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			});

		return deferredResult.promise();
	}
}