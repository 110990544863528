import {Notifier} from "Core/Common/Notifier";

import {NewThirdPartyDataRoleComponent} from "Core/Controls/FieldSecurity/Components/DataRole/NewDataRole/NewThirdPartyDataRoleComponent";

import {UserTypes} from "User/UserManager";
import {DataRoles} from "Core/Controls/FieldSecurity/Shared/Enums/DataRoles";

export class NewDORoleComponent extends NewThirdPartyDataRoleComponent {
	constructor() {
		super(DataRoles.Instance.DO);
	}

	AfterRender() {
		this.LoadUsers(UserTypes.Portal)
			.then(users => this.UsersLoaded(users))
			.fail(error => new Notifier().Failed(error.message));
	}
}