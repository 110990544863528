import {deserialize} from 'libs/cerialize';
import {FONT_NAME} from "Core/Constant";

export class IconModel {

	constructor(init?: Partial<IconModel>) {
		Object.assign(this, init);
	}

	@deserialize
	Name: string;

	@deserialize
	FontName: string;

	@deserialize
	Image: string;

	@deserialize
	IsIcon: boolean;

	@deserialize
	IsImage: boolean;

	@deserialize
	Id: number;

	static GetClasses(fontName, name):string {
        let classes:string = '';
            if (fontName === FONT_NAME.FONT_AWESOME) {
                classes = `${classes} fa`;
            } else if (fontName === FONT_NAME.SOCIAL_ICONS) {
                classes = `${classes} socicon-btn`;
            }
            classes = `${classes} ${name}`;

            return classes;
    }
}

