import { dia as Dia, shapes, V, g } from 'libs/rappid/build/rappid';
import * as joint from 'libs/rappid/build/rappid';
import 'Core/Controls/CanvasDesigner/Shapes/Models/Table';

var template = V('<g class="rotatable"><g class="scalable"><rect/></g><text class="shape-label"/><text class="toggle-icon fa"/><text class="icons fa"/><title/></g>');

declare module 'libs/rappid/build/rappid' {
	namespace shapes {
		namespace cyberThing {
			class TableView extends Dia.ElementView { }
		}
	}
}

joint.shapes.cyberThing.TableView = Dia.ElementView.extend({

	initialize: function () {
		joint.dia.ElementView.prototype.initialize.apply(this, arguments);
		this.model.position(0, 0, { parentRelative: true });
		this.model.get('attrs')['.shape-label']['ref-y'] = 0;
		this.model.get('attrs')['.shape-label'].refY2 = 15;
		this.model.attr('./visibility', 'hidden');
	},

	renderMarkup: function () {
		this.initIcons();
		this.vel.append(template.clone());
	},

	initIcons: function(){
		let toggleIcon = '\uf062';
		let propertyIcons = '';

		if(this.model.attributes.attrs.IsCollapsed){
			toggleIcon = '\uf063';
		}

		if(this.model.attributes.attrs.IsSearchExisting){
			propertyIcons += '\uf069';
		}

		if (this.model.attributes.attrs.UseMatching) {
			propertyIcons += '\uf24e';
		}

		this.model.attr({
			'.icons': {
				'ref-x': 0.9,
				'ref-y': 0,
				refY2: 10,
				text: propertyIcons
			},
			'.toggle-icon': {
				"ref-y": 0,
				refY2: 10,
				"ref-x": 0,
				refX2: 10,
				text: toggleIcon
			}
		});

	}
});