import { PasswordActions } from "Core/Controls/Password/Password";
import { FollowUpRecordModel } from "Core/Controls/ButtonFollowUp/Models/FollowUpRecordModel";
import { TagListChanges } from "Core/Controls/Tag/Models/TagListChanges";
import {RecordSpecsModel} from "Core/ScreenManager/Models/RecordSpecsModel";
import {ActionSubjectRecordModel} from "Core/ScreenManager/Models/ActionSubjectRecordModel";
import {DataModes} from "Core/Enums/DataModes";

import { SecurityEditorModel } from "Core/Components/SecurityEditor/Models/SecurityEditorModel";
import { GridRowDataModel } from 'Core/Controls/Grid/Models/UpdateDataModel/UpdateDataModel';
import {SpreadsheetSaveDto} from 'Core/Controls/Spreadsheet/Models/Dto/SpreadsheetSaveDto';
import {TypeSecuritySerializedModel} from "Core/Controls/TypeSecurity/Models/TypeSecuritySerializedModel";

export class DataModel {

	EntityId: number;
	EntityTypeId: number;
	KindId: number;
	RecordId: number;
	ParentRecordId: number;
	ParentRecordTypeId: number;
	ScreenTypeId: number;
	UseLinking: boolean;
	FieldValueList: Array<Array<string>>;
	RecordSpecs: RecordSpecsModel;
	RecordSettings: SecurityEditorModel;
	Attachments: Array<any>;
	RemoveAttachments: Array<any>;
	DropAttachments: Array<any>;
	ExampleRecordId: number;
	FillFromExample: boolean;
	DataMode?: DataModes;
	LinklistChanges: any;
	TableSecurityChanges: any;
	TypeSecurityChanges: TypeSecuritySerializedModel;
	RecordSecurityChanges: any;
	RecordSharingChanges: any;
	UserSecurityChanges: any;
	UserAllowanceChanges: any;
	FieldSecurityControlChanges: any;
	PasswordFieldList: Array<Array<string>>;
	PasswordAction: PasswordActions;
	FollowUpRecord: FollowUpRecordModel;
	TagsChanges: Array<TagListChanges>;
	Spreadsheets: Array<SpreadsheetSaveDto>;
	GridsData: Array<Array<GridRowDataModel>>;
	ActionSubjectRecord: ActionSubjectRecordModel;
	Annotations: Array<any>;
	LinkToSource: boolean;
	AliasSuffix: string;

	constructor() {
		this.FieldValueList = [];
		this.PasswordFieldList = [];
		this.RecordSpecs = new RecordSpecsModel();
		this.Attachments = [];
		this.RemoveAttachments = [];
		this.Annotations = [];
		this.GridsData = [];
		this.DropAttachments = [];
		this.LinklistChanges = {
			ChangedRelations: [],
			ChangedSecurity: [],
			DeletedRelations: [],
			LinkEditorChanges: [],
			NewRelations: [],
			ActionSubjectRecord: null
		};
		this.Spreadsheets = [];
	}
}