import * as $ from 'jquery';
import * as _ from 'underscore';

import {Guid} from 'Core/Common/Guid';
import {Event} from 'Core/Common/Event';
import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';

import {IGanttChartDataOptions} from './IGanttChartDataOptions';

import {AssignmentsPopup} from './AssignmentsPopup/AssignmentsPopup';

import {GanttChartStore} from './Stores/GanttChartStore';
import {GanttChartInfoDto} from './Stores/Models/GanttChartInfoDto';
import {SaveGanttChartInfoDto} from './Stores/Models/SaveGanttChartInfoDto';

import {GanttChartMappings} from './Mappings/GanttChartMappings';

import {GanttMaster, Project, Resource} from 'ganttChart';

import Template from './Templates/Templates.html';
import { PlanVersionDto } from './Stores/Models/PlanVersionDto';
import { Modal } from '../../../Common/Modal';


($ as any).JST.loadDecorator("RESOURCE_ROW", function(resTr, res){
    resTr.find(".delRes").click(function(){$(this).closest("tr").remove()});
  });

  ($ as any).JST.loadDecorator("ASSIGNMENT_ROW", function(assigTr, taskAssig){
    var resEl = assigTr.find("[name=resourceId]");
    var opt = $("<option>");
    resEl.append(opt);
    for(var i=0; i< taskAssig.task.master.resources.length;i++){
      var res = taskAssig.task.master.resources[i];
      opt = $("<option>");
      opt.val(res.id).html(res.name);
      if(taskAssig.assig.resourceId == res.id)
        opt.attr("selected", "true");
      resEl.append(opt);
    }
    var roleEl = assigTr.find("[name=roleId]");
    for(var i=0; i< taskAssig.task.master.roles.length;i++){
      var role = taskAssig.task.master.roles[i];
      var optr = $("<option>");
      optr.val(role.id).html(role.name);
      if(taskAssig.assig.roleId == role.id)
        optr.attr("selected", "true");
      roleEl.append(optr);
    }

    if(taskAssig.task.master.permissions.canWrite && taskAssig.task.canWrite){
      assigTr.find(".delAssig").click(function(){
        var tr = $(this).closest("[assId]").fadeOut(200, function(){$(this).remove()});
      });
    }
  });

export class GanttChartComponent extends Event {
    private _wrapperId: string;
    private _wrapper: HTMLElement;

    private _store: GanttChartStore;
    private _mappings: GanttChartMappings;

    private _ge: GanttMaster;
    private _project: Project;

    private _dataOptions: IGanttChartDataOptions;

    private _defaultScale: string | undefined;
    private _versions: Array<PlanVersionDto>;
    private _version: number;

    constructor({ defaultScale }: { defaultScale: string | undefined }) {
        super();

        this._versions = [];
        this._wrapperId = Guid.NewGuid();

        this._store = new GanttChartStore();
        this._mappings = new GanttChartMappings();

        this._ge = new GanttMaster();

        this._defaultScale = defaultScale;

    }

    GetTemplate() {
        return Template;
    }

    AfterRender(el: HTMLElement) {
        this._wrapper = document.getElementById(this._wrapperId);
        this.Trigger('RENDERED');
    }

    async Refresh(options: IGanttChartDataOptions) {
        this._dataOptions = options;
        this.RenderChart();
        await this.LoadVersions();
        this.LoadData();
    }

    private async LoadVersions(){
        const $wrapper = $(this._wrapper);
        this._versions = await this._store.GetPlanVersions(this._dataOptions.SubjectEntityId, this._dataOptions.RootId);

        if(!this._version){
            this._version = this._versions.length > 0 ? _.last(this._versions).Version : null;
        }

        let versionSelect = $wrapper.find('#plan-version');
        versionSelect.empty();

        versionSelect.off('change');
        versionSelect.on('change', ()=>{
            this._version = versionSelect.val() ? Number(versionSelect.val()) : null;
            this.LoadData();
        });

        var option = $('<option></option>')
            .val('-1')
            .text('No version');
        versionSelect.append(option);

        _.each(this._versions, (version)=>{
            var option = $('<option></option>')
                .val(version.Version)
                .text(version.Version);
                versionSelect.append(option);
        });
        versionSelect.val(this._version);
    }

    ShowCriticalPath() {
        this._ge.gantt.showCriticalPath = !this._ge.gantt.showCriticalPath;
        this._ge.redraw();
    }

    Resize(value: number) {
        this._ge.splitter.resize(value);
    }

    ShowAssignmentsPopup(taskId) {
        const task = this._ge.getTask(taskId);

        const hasChildren = task.getChildren().length > 0;
        if (hasChildren) {
            return;
        }

        //const resourcesHolder = task.getParent();
        //const resources = _.filter(this._project.resources, r => _.any(r.holders, rh => rh.id == resourcesHolder.id && rh.entity == resourcesHolder.entity));

        const popup = new AssignmentsPopup(task, this._project.resources);

        popup.On('SAVE_CHANGES', this, () => {
            popup.Close();
            this._ge.redraw();
        });

        popup.Show();
    }

    Save() {
        BlockUI.Block({Target: this._wrapper});
        const project = this._ge.saveProject();
        const tasksDto = this._mappings.OnDtoModel.AsTasksDto(project.tasks);

        this._store.SaveGanttChartInfo(new SaveGanttChartInfoDto(this._version, this._dataOptions.SourceTable, this._dataOptions.ResourcesTable, tasksDto))
            .then(() => {
                this.LoadData();
            })
            .fail(err => new Notifier().Failed(err.message))
            .always(() => BlockUI.Unblock(this._wrapper));
    }

    CreateNewVersion() {
        BlockUI.Block({Target: this._wrapper});
        const project = this._ge.saveProject();
        const tasksDto = this._mappings.OnDtoModel.AsTasksDto(project.tasks);

        this._store.CreateNewVersion(new SaveGanttChartInfoDto(this._version, this._dataOptions.SourceTable, this._dataOptions.ResourcesTable, tasksDto))
            .then(async (version) => {
                this._version = version;
                await this.LoadVersions();
                this.LoadData();
            })
            .fail(err => new Notifier().Failed(err.message))
            .always(() => BlockUI.Unblock(this._wrapper));
    }

    private LoadData() {
        BlockUI.Block({Target: this._wrapper});
        this._store.GetGanttChartInfo(this._version, this._dataOptions.SourceTable, this._dataOptions.ResourcesTable, this._dataOptions.RootId)
            .then(dto => {
                this.InitProject(dto);
                this.LoadProject();
                this.BindRowEvents();
                this.EnableScrollOnTimelineDrag();
                this.ApplyColors();
            })
            .fail(err => new Notifier().Failed(err.message))
            .always(() => BlockUI.Unblock(this._wrapper));
    }

    private InitProject(dto: GanttChartInfoDto) {
        const defaultScaleZoomMap = {
            'Year - Semester': '2y',
            'Semester - Month': '1y',
            'Quarter - Month (small)': '2Q',
            'Quarter - Month (large)': '1Q',
            'Month - Date': '1M',
            'Week - Day (small)': '2w',
            'Week - Day (medium)': '1w',
            'Week - Day (large)': '3d'
        };

        this._project = {
            tasks: this._mappings.OnViewModel.AsTasks(dto.Tasks),
            canWrite: true,
            canDelete: true,
            canWriteOnParent: true,
            canAdd: true,
            canSeePopEdit: true,
            canSeeFullEdit: true,
            resources: this._mappings.OnViewModel.AsResources(dto.Resources),
            roles: this._mappings.OnViewModel.AsRoles(dto.Roles),
            zoom: defaultScaleZoomMap[this._defaultScale]
        };
    }

    private LoadProject(){
        this._ge.reset();
        this._ge.loadProject(this._project);
    }

    private RenderChart() {
        const $wrapper = $(this._wrapper);

        this._ge.init($wrapper);

        // Fix event handler context
        this._ge.element
            .off('saveRequired.gantt', this._ge.manageSaveRequired)
            .on('saveRequired.gantt', this._ge.manageSaveRequired.bind(this._ge));        
      
        this._ge.checkpoint();

        this.BindEvents();

        // Scroll to current date
        // Based on Ganttalendar.prototype.goToMillis and Ganttalendar.prototype.centerOnToday from ganttDrawerSVG.js
        setTimeout(() => {
            const {
                gantt,
                splitter: { secondBox }
            } = this._ge;

            secondBox.scrollLeft(
                (Date.now() - gantt.startMillis) * gantt.fx + secondBox.width() / 2 - secondBox.prop('scrollWidth')
            );
        }, 200); // 200ms from GanttMaster.prototype.loadProject
    }

    private BindRowEvents(){
        const $wrapper = $(this._wrapper);
        $wrapper.find('.taskAssigs').on('click', event => {
            var row = $(event.target).closest("tr");
            var taskId = row.attr("taskId");
            this.ShowAssignmentsPopup(taskId);
        });
    }

    private BindEvents() {
        const $wrapper = $(this._wrapper);

        $wrapper.find('#gantt-save-btn').on('click', () => this.Save());
        $wrapper.find('#gantt-create-new-version-btn').on('click', () => this.CreateNewVersion());
        $wrapper.find('#gantt-show-critical-path-btn').on('click', () => this.ShowCriticalPath());
        $wrapper.find('#show-gantt-only-btn').on('click', () => this.Resize(.1));
        $wrapper.find('#show-both-btn').on('click', () => this.Resize(50));
        $wrapper.find('#show-table-only-btn').on('click', () => this.Resize(100));
        $wrapper.find('#gantt-edit-resources').on('click', () => this.EditResources());

        $wrapper.find('#gantt-zoom-minus').on('click', () => this.EnableScrollOnTimelineDrag());
        $wrapper.find('#gantt-zoom-plus').on('click', () => this.EnableScrollOnTimelineDrag());

        const splitBox1 = this._wrapper.querySelector<HTMLDivElement>('.splitBox1');
        splitBox1.addEventListener(
            'wheel',
            event => {
                if (event.shiftKey) {
                    splitBox1.scrollLeft += event.deltaY;
                }
            },
            true
        );

        this.EnableScrollOnTimelineDrag();
    }

    private EnableScrollOnTimelineDrag() {
        setTimeout(() => {
            const splitBox2 = this._wrapper.querySelector<HTMLDivElement>('.splitBox2');
            const ganttFixHead = splitBox2.querySelector<HTMLDivElement>('.ganttFixHead');

            let initialScrollLeft: number | undefined;
            let initialClientX: number | undefined;

            function scroll(event: PointerEvent) {
                splitBox2.scrollLeft = initialScrollLeft - (event.clientX - initialClientX);
            }

            ganttFixHead.addEventListener('pointerdown', event => {
                initialScrollLeft = splitBox2.scrollLeft;
                initialClientX = event.clientX;

                ganttFixHead.addEventListener('pointermove', scroll);
                ganttFixHead.setPointerCapture(event.pointerId);
            });

            ganttFixHead.addEventListener('pointerup', event => {
                ganttFixHead.removeEventListener('pointermove', scroll);
                ganttFixHead.releasePointerCapture(event.pointerId);
            });
        }, 50); // 50ms from GanttMaster.prototype.taskIsChanged
    }

    private ApplyColors() {
        for (const task of this._ge.tasks) {
            if (task.color) {
                task.rowElement.find('.taskStatus').css('background-color', task.color);
            }
        }

        // Gantt colors
        const mutationObserver = new MutationObserver(mutationList => {
            const ganttBars = _.flatten(
                mutationList.map(mutation =>
                    Array.prototype.filter.call(mutation.addedNodes,
                        node => node instanceof SVGElement && node.classList.contains('taskStatusSVG')
                    )
                )
            );

            for (const bar of ganttBars) {
                const fill = bar.getAttribute('fill');
                if (fill !== '#eee') { // if not default value
                    bar.style.fill = fill;
                }
            }
        });

        const splitBox2 = this._wrapper.querySelector<HTMLDivElement>('.splitBox2');

        mutationObserver.observe(splitBox2, { childList: true, subtree: true });
    }

    private EditResources(){
        let modal = new Modal({ height: 600, width: 400 }, false);      
        modal.AddClass('gantt-chart-wrapper');
        //make resource editor
        var resourceEditor = ($ as any).JST.createFromTemplate({}, "RESOURCE_EDITOR");
        var resTbl=resourceEditor.find("#resourcesTable");
      
        for (var i=0;i<(this._ge as any).resources.length;i++){
          var res=(this._ge as any).resources[i];
          resTbl.append(($ as any).JST.createFromTemplate(res, "RESOURCE_ROW"))
        }
      
      
        //bind add resource
        resourceEditor.find("#addResource").click(function(){
          resTbl.append(($ as any).JST.createFromTemplate({id:"new",name:"resource"}, "RESOURCE_ROW"))
        });
      
        //bind save event
        resourceEditor.find("#resSaveButton").click(function(){
          var newRes=[];
          //find for deleted res
          for (var i=0;i<(this._ge as any).resources.length;i++){
            var res=(this._ge as any).resources[i];
            var row = resourceEditor.find("[resId="+res.id+"]");
            if (row.length>0){
              //if still there save it
              var name = row.find("input[name]").val();
              if (name && name!="")
                res.name=name;
              newRes.push(res);
            } else {
              //remove assignments
              for (var j=0;j<(this._ge as any).tasks.length;j++){
                var task=(this._ge as any).tasks[j];
                var newAss=[];
                for (var k=0;k<task.assigs.length;k++){
                  var ass=task.assigs[k];
                  if (ass.resourceId!=res.id)
                    newAss.push(ass);
                }
                task.assigs=newAss;
              }
            }
          }
      
          var cnt=0
          resourceEditor.find("[resId=new]").each(function(){
            cnt++;
            var row = $(this);
            var name = row.find("input[name]").val();
            if (name && name!="")
              newRes.push (new (Resource as any)("tmp_"+new Date().getTime()+"_"+cnt,name));
          });
      
          (this._ge as any).resources=newRes;
      
           modal.Close();
          (this._ge as any).redraw();
        });
      

        modal.SetContent(resourceEditor);
        modal.Show();
      }      
}