export const PROPERTIES = {
    ACTION_SCREEN_TYPE: 'ActionScreenType',
    WITH_TAGS: 'WithTags',
    WITH_SUBJECT_IMAGE: 'WithSubjectImage',
    SHOW_ALIAS: 'ShowAlias',
    WITH_DATE_BADGES: 'WithDateBadges',
    WITH_PRIORITIES: 'WithPriorities',
    PROGRESS_BAR_TYPE: 'ProgressBarType',
    WITH_MEMO: 'WithMemo',
    WITH_MAILS: 'WithMails',
    READ_ONLY: 'ReadOnly',
    HEIGHT_IMAGE: 'HeightImage',
    COLOR_BAR: 'ColorBar',
    ALWAYS_SHOW_DEADLINE: 'AlwaysShowDeadline',
    HIDE_OWNER: 'HideOwner',
    BACKGROUND_COLOR: 'BackgroundColor',
    SHOW_TOOLTIP: 'ShowTooltip'
};