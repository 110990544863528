import * as ko from "knockout";
import * as _ from "underscore";

import { Notifier } from "Core/Common/Notifier";
import { NOTIFICATIONS } from "Core/Components/Translation/Locales";

import { GlobalManager, GLOBALS } from "Core/GlobalManager/GlobalManager";

import { SearchScreen, ISelectedRecord } from "Core/Screens/SearchScreen/SearchScreen";

import { ColumnEditor } from "LookupEditor/ColumnEditors/Base/ColumnEditor";
import { IValueUpdater } from "LookupEditor/Interfaces/IValueUpdater";

import { LookupEditorStore } from "LookupEditor/Store/LookupEditorStore";

import Template from "LookupEditor/Templates/Columns/MultiSelect.html";
import { EditableColumnModel } from "LookupEditor/Models/EditableColumnModel";
import { UserVarsManager } from "Core/UserVarsManager/UserVarsManager";
import { PUB_SUB_EVENTS } from 'MenuManager/PubSubEvents';
import { ItemModel } from "Core/Controls/MultiSelect/Models/MultiSelectListModel";

import { RecordDataModel, FieldDataModel } from "Core/Screens/Models/RecordDataModel";

import { LABELS } from "Core/Components/Translation/Locales";
import { FIELD_TYPES } from "Core/Constant";
import { FormatConverter } from "FormatEditor/FormatConverter";
import { FieldFormat } from "Core/Common/FieldFormat";

ko.templates["LookupEditor/Templates/Columns/MultiSelect"] = Template;

interface IDropdownOption {
	DisplayValue: string;
	Value: string;
}

export class MultiSelectColumnEditor extends ColumnEditor {
	private _columnName: string;
	private _owner: IValueUpdater;
	private _optionsLoaded: KnockoutObservable<boolean>;
	private _editModeEnabled: KnockoutObservable<boolean>;
	private _availableOptions: KnockoutObservableArray<IDropdownOption>;
	private CurrentItems: KnockoutObservableArray<ItemModel>;
	private _currentOwner: KnockoutObservable<IValueUpdater>;
	private _changesMade: KnockoutComputed<boolean>;
	private _showSearchScreen: KnockoutObservable<boolean>;
	private DisplayedItems: KnockoutComputed<Array<ItemModel>>;
	private SelectedOption: KnockoutObservable<IDropdownOption>;

	constructor(column: EditableColumnModel) {
		super(column);
		this._columnName = column.Name;
		this._currentOwner = ko.observable(null);
		this._optionsLoaded = ko.observable(false);
		this._editModeEnabled = ko.computed(() => this._optionsLoaded() && this._currentOwner() !== null, this);
		this._availableOptions = ko.observableArray([]);
		this.CurrentItems = ko.observableArray([]);

		this.DisplayedItems = ko.computed(() => this.CurrentOptions().filter(item => item.Label != LABELS.EMPTY), this);
		this._changesMade = ko.computed(() => this.CurrentOwner()!= null && _.difference(this.CurrentItems(), this.SelectedOptions(this.CurrentOwner().GetValue(this._columnName))).length > 0, this);
		this._showSearchScreen = ko.observable(false);

		this.On("REQUEST_FAILED", this, eventArgs => new Notifier().Failed(eventArgs.data.message));
	}

	Show(): void {
	}

	Click(owner: IValueUpdater, el: HTMLElement) {
		this.DisableEditMode();
		this._currentOwner(owner);
		LookupEditorStore.GetLookupRecordsCount(this.column.Id)
			.then(recordsCount => this.ShowOptions(recordsCount, owner, el))
			.fail(error => this.Trigger("REQUEST_FAILED", error));
	}

	GetTemplateName(): string {
		return "LookupEditor/Templates/Columns/MultiSelect";
	}

	AfterRender(el, owner: IValueUpdater, columnName): void {
		this._currentOwner(owner);
		this._columnName = columnName;
		this._owner = owner;
	}

	ToString() {
		return "MultiSelect";
	}

	get EditModeEnabled() {
		return this._editModeEnabled;
	}

	private SelectedOptions(columnData: any) {
		let selected = new Array<ItemModel>();

		if (columnData === LABELS.EMPTY) {
			const item = new ItemModel();
			item.Label = LABELS.EMPTY;
			selected.push(item);
		} else {
			const values = columnData.FieldValue.split(";").filter(x => x);
			const labels = columnData.DisplayValue
				? columnData.DisplayValue.split(";").filter(x => x)
				: columnData.FieldValue.split(";").filter(x => x);
			for (let i = 0; i < values.length; i++) {
				const item = new ItemModel();
				item.RecordId = parseInt(values[i]);
				item.Label = this.FormatLabel(labels[i]);
				selected.push(item);
			}
		}

		return selected;
	}

	get DisplayedOptions() {
		return this.DisplayedItems;
	}
	get CurrentOptions() {
		return this.CurrentItems;
	}

	get AvailableOptions() {
		return this._availableOptions;
	}

	get CurrentOwner() {
		return this._currentOwner;
	}

	FormatLabel(value: string) {
		if (!value) {
			return value;
		}

		const columnData = this._owner && this._owner.GetColumnData(this._columnName);

		if (columnData && _.contains([FIELD_TYPES.Decimal, FIELD_TYPES.Integer], columnData.ValFieldTypeName)) {
			return FormatConverter.LocalizeDecimalOrInteger(
				columnData.ValFieldFormatName === FieldFormat.PERCENTAGE
					? (Number(value.replace(/[.,]/g, '.')) * 100).toFixed(
							columnData.ValFieldSize < 2 ? 0 : columnData.ValFieldSize - 2
					  )
					: value
			);
		}

		return value;
	}

	private ShowOptions(recordsCount: number, owner: IValueUpdater, el: HTMLElement) {
		let searchScreenIfNumber = parseInt(GlobalManager.Instance.GetGlobal(GLOBALS.SEARCH_SCREEN_IF_RECORDS_COUNT));

		this.CurrentItems([...this.SelectedOptions(owner.GetValue(this._columnName))]);

		this.EnableEditMode();

		if (recordsCount >= searchScreenIfNumber) {
			this._showSearchScreen(true);
		} else {
			this._showSearchScreen(false);
			this.ShowDropdown(owner, el);
		}
	}

	private ShowSearchScreen(owner: IValueUpdater, el: HTMLElement) {
        let searchScreen = new SearchScreen({
            EntityId: this.column.ValTableId,
            SearchTerm: '',
            MultiSelectMode: true,
            SelectedRecordIds: _.map(this.CurrentItems(), (item: ItemModel) => item.RecordId)
        });

		searchScreen.On("RECORD_SELECTED", this, eventArgs => this.UpdateValue(eventArgs.data, owner, el));
        searchScreen.On("RECORDS_SELECTED", this, eventArgs => this.UpdateValues(eventArgs.data.Ids));

        searchScreen.On("ALT_ENTITY_RECORD_SELECTED", this, (eventArgs) => {
			const data = eventArgs.data;
			UserVarsManager.Instance.AddRecent(data.EntityId, data.RecordId, data.TypeId);

			data.IsOpenInModal = false;
			PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, data);
		});

		searchScreen.Show();
	}

	private ShowDropdown(owner: IValueUpdater, el: HTMLElement) {
		this.GetRecords()
			.then(records => {
				this._availableOptions([{Value: "0", DisplayValue: `${LABELS.SELECT_LABEL}...`}].concat(this.GetOptions(records)));
			}).fail(error => new Notifier().Failed(error.message));
	}

	private UpdateValue(record: any, owner: IValueUpdater, el: HTMLElement) {
		this.GetRecord(record.RecordId)
			.then(recordData => {
				const displayField = _.find(recordData.Fields, (field: any) => field.FieldId === this.column.ValFieldId);
				const displayValue = displayField.FieldValue;

				this.UpdateSelectedItems(parseInt(record.RecordId), displayValue);

			}).fail(error => new Notifier().Failed(error.message));
    }

	private UpdateValues(recordIds: number[]) {
		if (!_.any(recordIds)) {
			this.CurrentItems([]);
		}

        this.GetRecordsByIds(recordIds)
			.then(recordsData => {
				let selectedItems = _.map(recordsData, (recordData: any) => {
					const item = new ItemModel();
					item.IsSelected(true);

					const displayField = _.find(recordData.Fields,
						(field: any) => field.FieldId === this.column.ValFieldId);
					item.Label = displayField.FieldValue;

					const pkField = _.find(recordData.Fields,
						(field: any) => field.FieldId === recordData.TableStruct.PkId);
					item.RecordId = parseInt(pkField.FieldValue);

					return item;
				});

				selectedItems = _.filter(selectedItems, item => item.RecordId && item.RecordId !== 0);
				if (_.any(selectedItems)) {
					this.CurrentItems(selectedItems);
				}
			}).fail(error => new Notifier().Failed(error.message));
    }

	private UpdateSelectedItems(value: number, displayValue: string) {
		if (value != 0 && this.CurrentOptions().find(item => item.RecordId == value) == null) {
			const item = new ItemModel();
			item.RecordId = value;
			item.Label = displayValue;
			this.CurrentItems.push(item);
		}
    }

	private GetRecord(recordId: number) {
		return LookupEditorStore.GetRecord(this.column.ValTableId, recordId);
	}

	private GetRecords() {
		return LookupEditorStore.GetRecords(this.column.ValTableId);
    }

    private GetRecordsByIds(recordsIds: number[]) {
        return LookupEditorStore.GetRecordsByIds(this.column.ValTableId, recordsIds);
    }

	private GetOptions(records: Array<RecordDataModel>): Array<IDropdownOption> {
		if (!records || records.length === 0) {
			return [];
		}

		return records.map(record => {
			const valueField = _.find(record.Fields, (field: any) => field.IsPrimaryKey);
			const displayField = _.find(record.Fields, (field: any) => field.FieldId === this.column.ValFieldId);

			return {
				Value: valueField ? valueField.FieldValue : null,
				DisplayValue: displayField.FieldValue
					? this.FormatLabel(displayField.FieldValue)
					: this.emptyLookupValue
			};
		});
	}

	private UpdateOwnerValue(owner: IValueUpdater) {

		let selected = this.CurrentOptions().filter(item => item.Label != LABELS.EMPTY);

		const groupSeparatorRegExp = new RegExp(`[${FormatConverter.GetGroupSeparator()}]`, 'g');
		const decimalSeparatorRegExp = new RegExp(`[${FormatConverter.GetSeparator()}]`, 'g');

		const value = selected.map(a => a.RecordId).join(';');
		const displayValue = selected
			.map(a => a.Label.replace(groupSeparatorRegExp, '').replace(decimalSeparatorRegExp, '.'))
			.join(';');

		owner.UpdateValue({
			Name: this._columnName,
			Value: value,
			DisplayValue: displayValue
		});

		this.DisableEditMode();
	}

	private AddItem(owner: IValueUpdater) {
		this.UpdateSelectedItems(parseInt(this.SelectedOption.Value), this.SelectedOption.DisplayValue);
    }

	private EnableEditMode() {
		this._optionsLoaded(true);
	}

	Accept(owner: IValueUpdater) {
		if (this._changesMade) {
			this.UpdateOwnerValue(owner);
		} else {
			new Notifier().Warning(NOTIFICATIONS.SELECT_ANY_OPTION);
		}
	}

	RemoveItem(item: ItemModel) {
		this.CurrentItems(this.CurrentOptions().filter(record => record.RecordId != item.RecordId));
	}

	private DisableEditMode() {
		this._optionsLoaded(false);
		this._currentOwner(null);
	}
}
