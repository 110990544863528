import * as ko from 'knockout';
import {EventArgs} from "Core/Common/Event";
import {UserManager} from "User/UserManager";

import {TimerStore} from "Core/Controls/Timer/Stores/TimerStore";
import {ActiveTimersModal} from "Core/Controls/Timer/ActiveTimersModal/ActiveTimersModal";

import {IActiveTimer, TimerModel} from "Core/Controls/Timer/Models/TimerModel";

import Template from "./Templates/Template.html";

export class TimersMenu {
    IsAuthenticated: KnockoutObservable<boolean>;
    IsActiveTimers: KnockoutComputed<boolean>
    private _activeTimers: KnockoutObservableArray<IActiveTimer>;
    private _el: HTMLElement;

    constructor() {
        this._el = null;
        this.IsAuthenticated = ko.observable(UserManager.Instance.IsAuthenticated);
        this._activeTimers = ko.observableArray([]);
        this.GetActiveTimers();

        this.BindEvents();
    }

    private BindEvents() {
        const self = this;

        this.IsActiveTimers = ko.computed(()=> this._activeTimers().length > 0, this);

        PubSub.subscribe('STOP_ACTIVE_TIMERS',(message, timers: Array<IActiveTimer>) =>{
            const idToDelete = timers[0].Id;
            const indexToDelete = self._activeTimers().findIndex((item) => item.Id === idToDelete);
            if (indexToDelete !== -1) {
                self._activeTimers.splice(indexToDelete, 1);
            }
        })

        PubSub.subscribe('START_ACTIVE_TIMER', (message, data: Array<IActiveTimer>) => {
            const ids = new Set(self._activeTimers().map(timer => timer.Id));
            self._activeTimers.splice(Infinity, 0,
                ...data.filter(timer => !ids.has(timer.Id))
            );
        })
    }

    GetTemplate() {
        return Template;
    }

    OpenTimers(){
        const activeTimersModal = new ActiveTimersModal();
        activeTimersModal.On('STOP_TIMER', this, (data: EventArgs)=>{
            const stopTimers: Array<IActiveTimer> = data.data;
            PubSub.publish('STOP_ACTIVE_TIMERS', stopTimers);
        })
        activeTimersModal.Show();
    }

    GetActiveTimers(){
        TimerStore.GetActiveTimers()
            .then((timers: Array<TimerModel>) => {
                this._activeTimers(
                    timers.map(timer => {
                        return {
                            EntityId: timer.EntityId,
                            RecordId: timer.RecordId,
                            Id: timer.Id,
                            SubjectName: timer.Subject.TranslatedName || timer.Subject.Name
                        };
                    })
                );
            })
    }

    AfterRender(el: HTMLElement) {
        if (el){
            this._el = el[0].parentElement;
        }
    }

    Render(targetId: string) {
        const element = document.getElementById(targetId);

        ko.cleanNode(element);
        ko.applyBindings(this, element);
    }
}