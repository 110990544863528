import * as ko from 'knockout';
import * as _ from "underscore";
import {Guid} from 'Core/Common/Guid';

import {DatabaseModel} from 'Core/CyberConfigManager/Models/DatabaseModel';

export class CyberConfigManagerViewModel {
	Databases: KnockoutObservableArray<DatabaseViewModel>;

	CurrentDb: KnockoutObservable<DatabaseViewModel>;

	constructor(databases: Array<DatabaseModel>) {
		this.Databases = ko.observableArray(databases.map(database => new DatabaseViewModel(database)));
		this.CurrentDb = ko.observable(_.first(this.Databases()));
	}
}

export class DatabaseViewModel {
	Name: KnockoutObservable<string>;
	ConnectionString: KnockoutObservable<string>;
	Guid: string;
	Id: string;

	constructor(model: DatabaseModel) {
		this.Name = ko.observable(model.Name);
		this.ConnectionString = ko.observable(model.ConnectionString);
		this.Guid = Guid.NewGuid();
		this.Id = model.Id;
	}

	Copy(): DatabaseViewModel {
		let databaseModel = new DatabaseModel();
		databaseModel.Name = this.Name();
		databaseModel.ConnectionString = this.ConnectionString();

		let model = new DatabaseViewModel(databaseModel);

		model.Guid = this.Guid;

		return model;
	}

	GetModel(): DatabaseModel {

		let databaseModel = new DatabaseModel();
		databaseModel.Name = this.Name();
		databaseModel.ConnectionString = this.ConnectionString();

		return databaseModel;
	}
}

export const States = {
	Update: 0,
	Create: 1
};
