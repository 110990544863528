export const ON_LINK_EDITOR_GRID_SAVE = {
    NAME:  'OnLinkEditorGridSave',
    LEFT_ENTITY_ID:  'LeftEntityId',
    RIGHT_ENTITY_ID: 'RightEntityId',
    RELATION_TYPE_ID: 'RelationTypeId',
    GRID_ENTITY_ID: 'GridEntityId'
}

export const ON_CLUSTERED_PIVOT_FINISHED = {
    NAME:  'OnClusteredPivotFinished',
    MAIN_ENTITY_ID: 'MainEntityId',
    MAIN_RECORD_ID: 'MainRecordId',
    RELATED_ENTITY_ID: 'RelatedEntityId',
    RELATED_RECORD_ID: 'RelatedRecordId',
    NEW_K_SEQ: 'NewKSeq',
    OLD_K_SEQ: 'OldKSeq',
    CONTROL_ID: 'ControlId',
}

export const ON_LINK_EDITOR_UNLINK = {
    NAME:  'OnLinkEditorUnlink',
    LEFT_ENTITY_ID:  'LeftEntityId',
    RIGHT_ENTITY_ID: 'RightEntityId',
    RELATION_TYPE_ID: 'RelationTypeId',
    GRID_ENTITY_ID: 'GridEntityId'
}