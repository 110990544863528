import {CONTROL_TYPES, RenderModes} from "Core/Constant";

import {Event} from 'Core/Common/Event';

import {IFinancialAPIParams} from "./Params/IFinancialAPIParams";

import {GenericButton} from "Core/Controls/GenericButton/GenericButton";

import Template from './Templates/Template.html';
import DesignTemplate from './Templates/DesignTemplate.html';
import NoControlsTemplate from './Templates/NoControlsTemplate.html';
import {LABELS} from "../../../../Translation/Locales";

export class FinancialAPI extends Event {
    private _renderMode: RenderModes;
    private _financialButtons: GenericButton[];
    private _labels = LABELS;

    constructor(params: IFinancialAPIParams) {
        super();

        this._renderMode = params.RenderMode;
        this._financialButtons = params.RenderMode === RenderModes.View ? params.FinancialButtons : [];
    }

    get ButtonsPresent() {
        return this._financialButtons.length > 0;
    }

    get NoControlsMessage() {
        return `No controls of type ${CONTROL_TYPES.GenericButton}`
    }

    GetTemplate() {
        if (this._renderMode === RenderModes.Design) {
            return DesignTemplate;
        }

        return Template;
    }

    GetNoControlsTemplate() {
        return NoControlsTemplate;
    }
}