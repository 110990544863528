import * as moment from "moment";

import {Period} from "../Models/View/SchedulerViewModel";
import {DATE_FORMATS} from "Core/Constants/DateTimeFormats";

export class PeriodsMapping {
    static OnViewModel(startingTime: string, periodDuration: number, numberOfPeriods: number): Array<Period> {
        let periods: Array<Period> = [];
        let periodStart = moment(startingTime, DATE_FORMATS.TIME.Format);
        let duration = periodDuration;

        for (let i = 0; i < numberOfPeriods; i++) {
            periods.push(new Period(periodStart.format(DATE_FORMATS.TIME.Format), duration));

            const periodEnd = periodStart.clone().add(duration, 'h');

            if (periodStart.isSame(periodEnd, 'day')) {
                periodStart = periodEnd;
            } else {
                periods[i].Duration = periodEnd.diff(periodStart, 'hours');
                break;
            }
        }

        return periods;
    }
}