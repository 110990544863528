import * as _ from "underscore";
import * as ko from "knockout";

import {TotalCell} from 'Core/Controls/Grid/BaseGrid/GridCell/TotalCell';
import {GridTotalRowModel} from 'Core/Controls/Grid/Models/GridDataModel/GridTotalRowModel';
import {GridColumnModel} from 'Core/Controls/Grid/Models/GridDataModel/GridColumnModel';
import {LABELS} from "Core/Components/Translation/Locales";
import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

export class GridTotalRow {
	private _dataCells: KnockoutObservableArray<TotalCell>;
	private _rowColorEnabled: KnockoutObservable<boolean> = ko.observable(false);
	private _labels = LABELS;
	private _isGroupActive: KnockoutObservable<boolean>;

	constructor(
		private _model: GridTotalRowModel,
		private _showFavoriteCell,
		public _isEnableSelectRecord: KnockoutObservable<boolean>,
		columns: GridColumnModel[]
	) {
		this._dataCells = ko.observableArray([]);
		_.each(this._model.Data, (item, index) => {
			let filterTotalColumns = _.filter(columns, column => !column.IsSortDescription);
			this._dataCells.push(new TotalCell(item, _.find(filterTotalColumns, (col)=>col.QueryColumnGuid == item.QueryColumnGuid)));
		});

		this._isGroupActive = ko.observable(true);
	}

	set IsGroupActive(active: boolean) {
		this._isGroupActive(active);
	}

	get IsGroupActive():boolean {
		return this._isGroupActive();
	}

	@enumerable 
	get HasWrappedColumn(): boolean {
		var wrappedCell = _.find(this._dataCells(), (dataCell) => { return dataCell.IsWrapped });
		return wrappedCell != null;
	}

	@enumerable
	get HasData(): boolean {
		return this._model.Data.length > 0;
	}

	@enumerable 
	get Guid(): string {
		return this._model.Guid;
	}
}