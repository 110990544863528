import * as ko from 'knockout';

export class DebugExtention {
	static Init() {
		ko.bindingHandlers.debug = {
			init: (element, valueAccessor) => {
				console.log('Knockoutbinding:');
				console.log(element);
				console.log(valueAccessor());
			}
		};
	}
}