import * as ko from 'knockout';
import * as _ from 'underscore';

import {TABLE_TYPES} from 'Core/Constant';

import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';

import {GlobalManager, GLOBALS} from 'Core/GlobalManager/GlobalManager';

import {IControl} from 'Core/Controls/IControl';
import {ILabelPosition} from '../ControlEditor';

import {EntitiesStore} from 'Core/Screens/DesignScreen/ControlEditor/Stores/EntitiesStore';
import {LabelPositions} from 'Core/Screens/DesignScreen/ControlEditor/Enums/LabelPositions';

import {TableWithImagesModel} from '../../Models/TableWithImagesModel';
import {ImageFieldModel} from '../../Models/ImageFieldModel';

import {BaseControlEditor, EVENTS as BASE_CONTROL_EDITOR_EVENTS} from '../BaseControlEditor';

import {EVENTS} from '../SignatureControlEditor';

import Template from 'Core/Screens/DesignScreen/ControlEditor/Templates/ImageControlEditor.html';

ko.templates['Core/Screens/DesignScreen/ControlEditor/Templates/ImageControlEditor'] = Template;

export class ImageControlEditor extends BaseControlEditor {
    private _fields: KnockoutObservableArray<ImageFieldModel>;
    private _selectedField: KnockoutObservable<ImageFieldModel>;

    private _entities: KnockoutObservableArray<TableWithImagesModel>;
    private _selectedEntity: KnockoutObservable<TableWithImagesModel>;

    private _uncheckShowAnnotations: KnockoutObservable<boolean>;

    constructor(control: IControl) {
        super(control);

        this.InitEditControlData(control);
        this.BindEvents();
    }

    GetTemplateName(): string {
        return 'Core/Screens/DesignScreen/ControlEditor/Templates/ImageControlEditor';
    }

    AfterRender(el: HTMLElement) {
        const container = el[0];
        super.AfterRender(container);

        BlockUI.Block();

        this.LoadData()
            .then(entities => {
                this.PopulateEntities(entities);
                this.EnableOrDisableShowAnnotations(container);
            })
            .always(() => BlockUI.Unblock());
    }

    LoadData() {
        return EntitiesStore.GetImageEntities({
            SubjectTableId: this.GetSubjectEntityId()
        });
    }

    Save(): void {
        const validationError = this.Validate();
        if (validationError) {
            new Notifier().Warning(validationError);
            return;
        }

        const controlEditorModel = this.GetControlEditorModel();

        this.Trigger(BASE_CONTROL_EDITOR_EVENTS.CONTROL_SAVED, {UpdateControlModel: controlEditorModel});

        this.Close();
    }

    Validate() {
        const validationError = super.Validate();
        if (validationError) {
            return validationError;
        }

        if (!this._selectedField()) {
            this.IsValid(false);
            return 'Please, select field';
        }
    }

    GetControlEditorModel() {
        const desktopLanguage = GlobalManager.Instance.GetGlobal(GLOBALS.DESKTOP_LANGUAGE);
        const controlEditorModel = super.GetControlEditorModel();

        const selectedEntity = this._selectedEntity();
        controlEditorModel.EntityId = selectedEntity.Id;
        controlEditorModel.EntityName = selectedEntity.Name;

        const selectedField = this._selectedField();
        controlEditorModel.FieldId = selectedField.Id;
        controlEditorModel.FieldName = selectedField.Name;
        controlEditorModel.UseFieldName = this.UseFieldName();

        if (controlEditorModel.UseFieldName) {
            controlEditorModel.Name = selectedField.Name;
        } else {
            _.each(controlEditorModel.NameTranslations, (translation) => {
                if (translation.Selected || (desktopLanguage == translation.Language.ShortName)) {
                    translation.Selected = true;
                }
            });
        }

        if (this._uncheckShowAnnotations()) {
            let propsObj = JSON.parse(controlEditorModel.Properties);
            propsObj.Annotations.Properties[1].ShowAnnotations = false;

            let propsString = JSON.stringify(propsObj);
            controlEditorModel.Properties = propsString;

            this._uncheckShowAnnotations(false);
        }
        return controlEditorModel;
    }

    protected InitEditControlData(control: IControl) {
        super.InitEditControlData(control);

        this.InitEntities();
    }

    BindEvents() {
        this._selectedEntity.subscribe(selectedEntity => {
            if (this._selectedEntity()) {
                this.Trigger(EVENTS.ENTITY_SELECTED, selectedEntity);
            }
        });

        this.On(EVENTS.ENTITY_SELECTED, this, eventArgs => this.OnEntityChange());
        this.On(EVENTS.FIELD_SELECTED, this, () => this.OnFieldChange());

        if (this.UseFieldName()) {
            this.UseFieldNameAsControlName();
        }

        this.UseFieldName.subscribe((newValue) => {
            if (newValue) {
                this.UseFieldNameAsControlName();
            }
        });
    }

    OnSelectField(selectedField) {
        this.Trigger(EVENTS.FIELD_SELECTED, selectedField);
    }

    private InitEntities() {
        this._entities = ko.observableArray([]);
        this._selectedEntity = ko.observable(null);

        this._fields = ko.observableArray([]);
        this._selectedField = ko.observable(null);

        this._uncheckShowAnnotations = ko.observable(false);
    }

    PopulateEntities(entities: TableWithImagesModel[]) {
        entities = _.uniq(entities, false, entity => entity.Id);

        if (this.EditControl.GetForm().GetScreen().IsEditScreen) {
            entities = _.filter(entities, entity => entity.Id === this.GetSubjectEntityId() || entity.Type === TABLE_TYPES.Sub);
        }

        this._entities(entities);

        const attachedField = _.first(this.EditControlModel.Fields);

        const attachedEntity = attachedField && _.find(entities, entity => entity.Id === attachedField.EntityId);

        if (attachedEntity) {
            this._selectedEntity(attachedEntity);
        } else {
            const subjectEntity = _.find(entities, entity => entity.Id === this.SubjectEntityId);
            this._selectedEntity(subjectEntity);
        }
    }

    EnableOrDisableShowAnnotations(container) {
        const spansArray = $(`#${container.id} .panel-default .form-group > label > span`);
        let useAnnotationsCheckbox = null, showAnnotationsCheckbox = null;

        _.each(spansArray, elem => {
            if (elem.innerText === this.GeneralProperties.GetPropertyName('Annotations')) {
                useAnnotationsCheckbox = elem.nextElementSibling;
            }

            if (elem.innerText === this.GeneralProperties.GetPropertyName('ShowAnnotations')) {
                showAnnotationsCheckbox = elem.nextElementSibling;
            }
        });

        if (!useAnnotationsCheckbox.checked) {
            showAnnotationsCheckbox.checked = false;
            this._uncheckShowAnnotations(true);
            showAnnotationsCheckbox.disabled = true;
        }

        let annotationsCheckboxListener = useAnnotationsCheckbox as HTMLElement;
        annotationsCheckboxListener.addEventListener('change', () => {
            if (!useAnnotationsCheckbox.checked) {
                showAnnotationsCheckbox.checked = false;
                this._uncheckShowAnnotations(true);
                showAnnotationsCheckbox.disabled = true;
            } else {
                this._uncheckShowAnnotations(false);
                showAnnotationsCheckbox.disabled = false;
            }
        })
    }

    private OnEntityChange() {
        const selectedEntity = this._selectedEntity();

        this._fields(selectedEntity.ImageFields);

        const attachedField = _.first(this.EditControlModel.Fields);
        if (attachedField) {
            const selectedField = _.find(selectedEntity.ImageFields, field => field.Id === attachedField.Id);
            this._selectedField(selectedField);
            if (this.UseFieldName()) {
                this.UseFieldNameAsControlName();
            }
        }
    }

    private OnFieldChange() {
        if (this.UseFieldName()) {
            this.UseFieldNameAsControlName();
        } else {
            const translations = this.NameTranslations();
            const defTrans = this.NameTranslations()[0];
            defTrans.Translation = this._selectedField().Name;
            this.SelectedName(defTrans);
            this.NameTranslations([]);
            this.NameTranslations(translations);
        }


    }

    ToggleUseFieldName() {
        this.UseFieldName(!this.UseFieldName());
    }

    UseFieldNameAsControlName() {
        let field = this._selectedField();
        if (field) {
            _.each(this.NameTranslations(), (translation) => {

                if (translation.Language.K_Language === -1) {
                    translation.Translation = field.Name;
                }

                let fieldTranslation = _.find(field.NameTranslations, (fieldTranslation) => {
                    return fieldTranslation.Language.K_Language === translation.Language.K_Language;
                });
                if (fieldTranslation) {

                    if (this.SelectedName() && this.SelectedName().Language.K_Language == fieldTranslation.Language.K_Language) {
                        this.SelectedName().Translation = fieldTranslation.Translation;
                        this.SelectedName.valueHasMutated();
                    }

                    translation.Translation = fieldTranslation.Translation;
                }
            });
            let translations = this.NameTranslations();
            this.NameTranslations([]);
            this.NameTranslations(translations);

            const defTrans = this.NameTranslations()[0];
            defTrans.Translation = this._selectedField().Name;
            this.SelectedName(defTrans);
        }
    }
}