import {EVENTS} from "Core/Constant";

import {IButtonConfig} from "Core/Controls/PortletBar/Interfaces/IButtonConfig"
import {PortletBarButton} from "Core/Controls/PortletBar/Managers/Buttons/PortletBarButton"
import {RemoveButtonViewModel} from "Core/Controls/PortletBar/Models/Buttons/RemoveButtonViewModel"

export class RemoveButton extends PortletBarButton {
	constructor(buttonName: string, buttonConfig: IButtonConfig) {
		super(buttonConfig);
		this.ViewModel = new RemoveButtonViewModel(buttonName, buttonConfig);
	}

	OnClickEvent() {
		return EVENTS.PORTLETS.PORTLET_BAR.CONTROL_BUTTONS.REMOVE_BUTTON_CLICKED;
	}

	OnClick() {
		this.Trigger(this.OnClickEvent());
	}

	protected GetButtonTemplateFolder() {
		return `${super.GetButtonTemplateFolder()}/Remove`;
	}
}