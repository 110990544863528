import {CONTROL_TYPES} from 'Core/Constant';

import {ControlModel} from './BaseControl/Models/ControlModel';
import {ScreenTypes} from '../Common/Enums/ScreenTypes';

export interface IStaticControlsPosition {
    ActionBar: ControlModel[],
    SubForms: [ControlModel[], ControlModel[], ControlModel[], ControlModel[]],
    BottomBar: ControlModel[]
}

const DEFAULT_POSITION: IStaticControlsPosition = {ActionBar: [], SubForms: [[], [], [], []], BottomBar: []};

export class StaticControlsProvider {
    static ForScreen(type: string, followUp: boolean, isDashMain: boolean): IStaticControlsPosition {
        switch (type) {
            case ScreenTypes[ScreenTypes.ConsultScreen]:
                return this.ForConsultScreen(followUp);

            case ScreenTypes[ScreenTypes.EditScreen]:
                return this.ForEditScreen(followUp, isDashMain);

            case ScreenTypes[ScreenTypes.ListScreen]:
                return this.ForListScreen();

            case ScreenTypes[ScreenTypes.SpecialScreen]:
                return this.ForSpecialScreen();

            default: {
                return DEFAULT_POSITION;
            }
        }
    }

    static ForConsultScreen(followUp: boolean): IStaticControlsPosition {
        const controlsPosition: IStaticControlsPosition = { ActionBar: [], SubForms: [[], [], [], []], BottomBar: [] };
        if (followUp) {
            controlsPosition.ActionBar.push(this.BuildControlModel(CONTROL_TYPES.ButtonFollowUp));
        }

        return controlsPosition;
    }

    static ForListScreen(): IStaticControlsPosition {
        return DEFAULT_POSITION;
    }

    static ForSpecialScreen() : IStaticControlsPosition {
        return DEFAULT_POSITION;
    }

    static ForEditScreen(followUp: boolean, isDashMain: boolean): IStaticControlsPosition {
        const controlsPosition: IStaticControlsPosition = {ActionBar: [], SubForms: [[], [], [], []], BottomBar: []};

        if (isDashMain) {
            controlsPosition.ActionBar.push(this.BuildControlModel(CONTROL_TYPES.Search));
        }

        controlsPosition.ActionBar.push(this.BuildControlModel(CONTROL_TYPES.ButtonSave));
        controlsPosition.ActionBar.push(this.BuildControlModel(CONTROL_TYPES.ButtonCancel));

        if (followUp) {
            controlsPosition.ActionBar.push(this.BuildControlModel(CONTROL_TYPES.ButtonFollowUp));
        }

        return controlsPosition;
    }

    static ForLinkEditor(): IStaticControlsPosition {
        const controlModels: IStaticControlsPosition = {ActionBar: [], SubForms: [[], [], [], []], BottomBar: []};

        controlModels.ActionBar.push(this.BuildControlModel(CONTROL_TYPES.ButtonSave));
        controlModels.ActionBar.push(this.BuildControlModel(CONTROL_TYPES.ButtonCancel));

        return controlModels;
    }

    static AddForLookupEditorNewRecord() {
        const controlModels: IStaticControlsPosition = {ActionBar: [], SubForms: [[], [], [], []], BottomBar: []};

        controlModels.ActionBar.push(this.BuildControlModel(CONTROL_TYPES.ButtonSave));
        controlModels.ActionBar.push(this.BuildControlModel(CONTROL_TYPES.ButtonCancel));

        return controlModels;
    }

    private static BuildControlModel(typeName: string): ControlModel {
        const controlModel = new ControlModel();

        controlModel.TypeName = typeName;
        controlModel.Sort = 30;

        return controlModel;
    }
}