import * as ko from 'knockout';
import * as _ from 'underscore';

import {BaseControl, IControlValue} from "../BaseControl/BaseControl";
import {IControlParam} from "../../Screens/IScreen";
import {BlockUI, BlockUI as BlockUi} from 'Core/Common/BlockUi';
import {P} from 'Core/Common/Promise';

import {FileModel, ImageDataModel} from "../Image/Models/ImageDataModel";
import {RenderModes} from "../../Constant";
import {SignatureEditScreen} from "./SignatureEditScreen/SignatureEditScreen";
import {AttachmentModel} from "../Image/Models/AttachmentModel";
import {IGetImageControlDataRequestModel, ImageDataStore} from "../Image/Stores/ImageDataStore";
import {Notifier} from "../../Common/Notifier";
import {ScreenTypes} from "Core/Common/Enums/ScreenTypes";
import OperationResult from "../../Results/ResultModels/OperationResult";
import {SignatureStore} from "./Store/SignatureStore";
import {EventArgs} from "Core/Common/Event";

import ViewTemplate from "Core/Controls/Signature/Templates/View.html";
import ToolBarTemplate from "Core/Controls/Signature/Templates/ToolBar.html";
import DesignTemplate from "Core/Controls/Signature/Templates/Design.html";
import EditTemplate from "Core/Controls/Signature/Templates/Edit.html";
import HelpViewTemplate from "Core/Controls/Signature/Templates/HelpView.html";

ko.templates['Core/Controls/Signature/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Signature/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Signature/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/Signature/Templates/Edit'] = EditTemplate;
ko.templates['Core/Controls/Signature/Templates/HelpView'] = HelpViewTemplate;

export interface SaveSignatureDataDto {
    Data: AttachmentModel;
    FieldId: number;
    RecordId: number;
}

export class Signature extends BaseControl {

    private _value: KnockoutObservable<string>;
    private _dataModel: KnockoutObservable<ImageDataModel>;
    private _originalImageDataModel: KnockoutObservable<ImageDataModel>;
    private _labelStyle: KnockoutObservable<any>;

    private _image: KnockoutObservable<FileModel>;
    private _iControlParams: IControlParam;
    private _originalValue: string;
    private _isNewRecord: boolean;

    private _attachments: Array<AttachmentModel>;
    private _removeAttachments: Array<FileModel>;
    private _newAttachments: Array<any>;
    private _isReadOnly: KnockoutObservable<boolean>;

    constructor(params: IControlParam){
        super(params);

        this._iControlParams = params;
        this._value = ko.observable(null);
        this._dataModel = ko.observable(null);
        this._originalImageDataModel = ko.observable(null);
        this._image = ko.observable(this.GetRenderMode() === RenderModes.Edit ? new FileModel() : null);

        this._attachments = [];
        this._removeAttachments = [];

        this.Init();
        this.BindEvents();

        this._labelStyle = ko.observable(null);

        this.ApplyProperties();

        this._isReadOnly = ko.observable(null);

    }

    Deserialize(){
        const data = {attachments: [], removeAttachments: []};
        const field = _.first(this._model().Fields);

        _.each(this._attachments, item => {
            data['attachments'].push({FieldId: field.Id, Data: item});
        });

        _.each(this._removeAttachments, item => {
            data['removeAttachments'].push({FieldId: field.Id, Data: {Id: item.Id, IsImage: true}});
        });

        this._newAttachments = data.attachments;
        data['FieldName'] = `${field.EntityName}.${field.Name}`;

        return data;

    }

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);
        if(this.GetRenderMode() !== RenderModes.Design){
            this._isReadOnly(this._iControlParams.Model.Fields[0].IsReadOnly);
        }
    }

    private BindEvents() {
        this._model.subscribe(() => this.Init());
    }

    ApplyProperties(){}

    private Init(): void {

    }

    get Image(): KnockoutObservable<FileModel> {
        return this._image;
    }

    GetValue(): any {
        return this._value();
    }

    SetValue(data: IControlValue): void {
        if (data.RecordSpecsModel) {
            this._isNewRecord = data.RecordSpecsModel && data.RecordSpecsModel.IsNewRecord;
        }

        this._isRendered.subscribe(() => {
            if (data.Data && data.Data.Value){
                this.LoadSignatureImageData(data)
                    .then(dataModel => this.PrepareForSignatureImage(dataModel));
            }
        });
    }

    private PrepareForSignatureImage(dataModel: ImageDataModel) {
        this._dataModel(dataModel);

        //Adds attachment when creating record from example
        if (this._isNewRecord && this._dataModel()) {
            const [file] = this._dataModel().Files;

            if (file) {
                const exampleImage = new AttachmentModel();

                exampleImage.FileName = file.Name;
                exampleImage.Base64Data = file.ImageData;
                this._attachments.push(exampleImage);
            }
        }
        this._originalImageDataModel = ko.observable(this.GetOriginalImage());
    }

    private LoadSignatureImageData(data: IControlValue): P.Promise<ImageDataModel> {
        const deferred = P.defer<ImageDataModel>();

        this.BlockUi();

        const requestModel: IGetImageControlDataRequestModel = {
            ControlId: this.GetControlId(),
            FieldId: this.GetFieldId(),
            RecordId: +data.Data.Value,
            FirstRecordOnly: true
        };

        ImageDataStore.Get(requestModel)
            .then(data => {
                deferred.resolve(data)
            })
            .fail(err => deferred.reject(err))
            .always(() => this.UnBlockUi());

        return deferred.promise();
    }

    private BlockUi() {
        if (this._el) {
            BlockUi.Block({Target: this._el});
        }
    }

    private UnBlockUi() {
        BlockUi.Unblock(this._el);
    }

    IsModified(): boolean {
        this.Deserialize();

        if (super.IsModified() || this._isNewRecord) {
            return true;
        }

        if (!this._isNewRecord && this._newAttachments.length == 0) {
            return false;
        } else if (this._newAttachments.length != 0) {
            let modifiedValue = null;
            _.each(this._newAttachments, item => {
                if (!this._originalValue && !item.Data.Base64Data) {
                    modifiedValue = null;
                } else if (this._originalValue && !item.Data.Base64Data) {
                    modifiedValue = true;
                } else {
                    modifiedValue = item.Data.Base64Data;
                }
            });
            return !!modifiedValue;
        }
    }

    GetOriginalImage() {
        let fileModel = new FileModel();
        let originalImg = new ImageDataModel();

        if (this._dataModel().Files.length > 0){

            const requestModel: IGetImageControlDataRequestModel = {
                ControlId: this.GetControlId(),
                FieldId: this.GetFieldId(),
                RecordId: this._form.GetScreen().GetRecordId(),
                FirstRecordOnly: false
            };

            ImageDataStore.GetOriginal(requestModel)
                .then(attachment => {
                    fileModel.ImageData = attachment.Base64Data;
                    this._image(fileModel);
                    originalImg.Files.push(fileModel);
                })
                .fail(err => new Notifier().Failed(err.message))
                .always(() => {

                });
        }
        return originalImg;
    }

    Edit(){
        if (this._help.IsHelpButtonPressed()){
            return;
        }

        const signatureExampleImage = new AttachmentModel();
        const signatureEditScreen = new SignatureEditScreen(this._iControlParams, this._originalImageDataModel() && this._originalImageDataModel().Files.length > 0 && this._originalImageDataModel().Files[0].ImageData ? this._originalImageDataModel() : this._dataModel());
        const signatureIsConsultScreen = this._form && this._form.GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.ConsultScreen];

        signatureEditScreen.Show();

        signatureEditScreen.On('SIGNATURE_IMAGE_SAVE', this, (event: EventArgs) => {
            signatureExampleImage.FileName = `Signature_${Date.now()}.jpg`;
            signatureExampleImage.Base64Data = event.data.SignatureImage.split(',')[1];
            this._attachments.push(signatureExampleImage);

            if (signatureIsConsultScreen){
                this.SaveOnlySignature(this._attachments[0])
                    .then(() =>{
                        this._form.GetScreen().Refresh();
                        signatureEditScreen.Close();
                    });
            } else {
                let fileModel = new FileModel();
                this._dataModel = ko.observable(null);
                this._dataModel(new ImageDataModel());

                fileModel.ImageData = event.data.SignatureImage;
                this._image(fileModel);
                this._dataModel().Files.push(fileModel);
                this._dataModel.valueHasMutated();
                fileModel.Attachment = signatureExampleImage;

                this._originalImageDataModel = ko.observable(null);
                this._originalImageDataModel(new ImageDataModel());
                this._originalImageDataModel().Files.push(fileModel);
                signatureEditScreen.Close();
            }
        });
    }

    SaveOnlySignature(exampleImage: AttachmentModel): P.Promise<any>{
        let deferredResult = P.defer<any>();

        let params: SaveSignatureDataDto = {
            Data: exampleImage,
            FieldId: this.GetFieldId(),
            RecordId: this._form.GetScreen().GetRecordId(),
        }
        BlockUI.Block();
        SignatureStore.SaveSignatureData(params)
            .always(()=> {
                BlockUI.Unblock();
            })
            .then((result) => {
                deferredResult.resolve(result);
            })
            .fail((err) => {
                new Notifier().Failed(err.message);
            });
        return deferredResult.promise();
    }

}