import {deserialize} from 'libs/cerialize';

export class KanbanParentTypeModel {
    @deserialize
    K_Type: number;

    @deserialize
    Name: string;

    @deserialize
    TranslatedName: string;
}