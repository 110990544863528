import * as _ from 'underscore';

export enum NavigationItemTypes {
    StartPage,
    RootProducts,
    NestedProducts,
    ConfigurationPage
}

export class NavigationItem {
    constructor(private _name: string, private _type: NavigationItemTypes, private _payload: any, private _pageNumber: number) {
    }

    get Name(): string {
        return this._name;
    }

    get Type(): NavigationItemTypes {
        return this._type;
    }

    get Payload() {
        return this._payload;
    }

    get Page() {
        return this._pageNumber;
    }

    Is(type: NavigationItemTypes) {
        return this.Type === type;
    }

    Equals(item: NavigationItem) {
        return this.Is(item.Type) && _.isEqual(this.Payload, item.Payload);
    }
}