import {LABELS} from "Core/Components/Translation/Locales";


export enum ClusterModes {
    None,
    Copy,
    Default,
    Delta,
    Use,
    Next,
    NextNext
}

export class ClusterModeTranlsation {
    static GetTranslation(clusterMode: ClusterModes) {
        switch (clusterMode) {
            case ClusterModes.Copy:
                return LABELS.COPY;
            case ClusterModes.Default:
                return LABELS.DEFAULT;
            case ClusterModes.Delta:
                return LABELS.DELTA;
            case ClusterModes.Use:
                return LABELS.USE;
            case ClusterModes.Next:
                return LABELS.NEXT;
            case ClusterModes.NextNext:
                return LABELS.NEXT_NEXT;
        }
    }
}
