import { Request, IRequestOptions } from "Core/Common/Request";
import { P } from "Core/Common/Promise";
import { SelectUserModel } from "Core/Controls/SelectUser/Models/SelectUserModel";
import { SelectUserByBusinessRoleModel } from "Core/Controls/SelectUser/Models/SelectUserByBusinessRoleModel";

export class SelectUserStore {
	static GetSelectUserModel(entityId: number): P.Promise<SelectUserModel> {
		const requestOptions: IRequestOptions = {
			proxy: {
				url: "/api/ControlsApi/GetSelectUserList",
				type: "GET",
				dataType: "json"
			},params: {
				id: entityId
			}
		};

		const deffered = P.defer<SelectUserModel>();

		Request.Send(requestOptions)
			.then(response => {
				if (response.IsSuccessfull) {
					const model = response.ResultObject;
					deffered.resolve(model);
				} else {
					deffered.reject({ message: response.ErrorMessage });
				}
			}).fail(() => deffered.reject({ message: "Error getting data" }));
		return deffered.promise();
	}

	static GetSelectUsersByBusinessRole(entityId: number, roleId: number): P.Promise<SelectUserByBusinessRoleModel> {
		const requestOptions: IRequestOptions = {
			proxy: {
				url: "/api/ControlsApi/GetSelectUsersByBusinessRole",
				type: "POST",
				dataType: "json"
			},params: {
				EntityId: entityId,
				RoleId: roleId
			}
		};

		const deferred = P.defer<SelectUserByBusinessRoleModel>();

		Request.Send(requestOptions)
			.then(selectUserByBusinessRoleModel => {
				deferred.resolve(selectUserByBusinessRoleModel);
			}).fail(err => {
				deferred.reject(this.GetResponseError(err, 'Error getting data'));
			});

		return deferred.promise();
	}

	private static GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
		if (err.status === 400) {
			return {message: JSON.parse(err.message).Message};
		}

		return {message: generalError};
	}
}