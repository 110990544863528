import * as ko from 'knockout'
import * as _ from 'underscore';

import {FIELD_TYPES, TABLE_TYPES} from "Core/Constant";

import {IControlParam} from "Core/Screens/IScreen";

import {ComplexControl} from "Core/Controls/ComplexControl/ComplexControl";
import { RequiredFieldModel } from "Core/Controls/ComplexControl/Models/RequiredFieldModel";
import { AttachedFieldModel } from 'Core/Controls/BaseControl/Models/AttachedFieldModel';

import {IControlValue} from "../BaseControl/BaseControl";

import {Icon} from "Core/Icon/Icon";
import {DEFAULT_ICONS} from "Core/Constant";
import {GeneralProperties} from "Core/GeneralProperties/GeneralProperties";

import InvoicingConfigJson from "Core/Controls/Invoicing/Configs/invoicing-config.json"

import ToolBarTemplate from "Core/Controls/Invoicing/Templates/Toolbar.html";
import ViewTemplate from "Core/Controls/Invoicing/Templates/View.html";
import HelpViewTemplate from "Core/Controls/Invoicing/Templates/HelpView.html";
import {InvoicingStore} from "./Stores/InvoicingStore";
import {BlockUI} from "../../Common/BlockUi";
import {Notifier} from "../../Common/Notifier";

ko.templates['Core/Controls/Invoicing/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Invoicing/Templates/Design'] = ComplexControl.designTemplate;
ko.templates['Core/Controls/Invoicing/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Invoicing/Templates/HelpView'] = HelpViewTemplate;

export class InvoicingControl extends ComplexControl {
    private _avalableForScreen: KnockoutObservable<boolean>;

    constructor(params: IControlParam) {
        super(params, InvoicingConfigJson);

        this._avalableForScreen = ko.observable(false);
        this._style = ko.computed(() => {
            return {
                backgroundColor: this._backgroundColor(),
                color: this._color(),
                borderColor: this._borderColor(),
                borderWidth: this._border() ? '1px' : '0'
            };
        });

        this.Init();
    }

    SetValue(value: IControlValue) {
        if (!value.SubjectRecordId) return;

        const currentLifeStatus = value.RecordSpecsModel.LifeStatusInfo && value.RecordSpecsModel.LifeStatusInfo.Id;
        if (!currentLifeStatus) return;

        const validLifeStatus = this.GetModel().RecordLifeStatusId;

        if ((!validLifeStatus || currentLifeStatus === validLifeStatus) && !this.IsDisabledByCondition) {
            this._enabled(true);
        }
    }

    ConvertToInvoice() {
        if(this._help.IsHelpButtonPressed()) {
            this._help.ShowControlHelp(this);
        } else {
            const screen = this.GetForm().GetScreen();

            const entityId = screen.GetEntityId();
            const recordId = screen.GetRecordId();
            const controlId = this.GetModel().Id;

            BlockUI.Block();

            InvoicingStore.CreateInvoices({
                EntityId: entityId,
                RecordId: recordId,
                ControlId: controlId
            })
                .then(() => {
                    this._enabled(!this.GetModel().RecordLifeStatusId);
                    const attachedField = _.find(this.GetModel().Fields,
                        (field: AttachedFieldModel) => field.EntityTypeName === TABLE_TYPES.Entity);
                    const entityName = attachedField ? attachedField.EntityNameTranslation : '';

                    new Notifier().Success(this._labels.RECORD_FOR_TABLE_CREATED.replace('{table}', entityName));
                })
                .fail(error => new Notifier().Failed(JSON.parse(error.message).Message))
                .always(() => BlockUI.Unblock());
        }
    }

    private Init(): void {
        this.SetDefaultIcon(new Icon(DEFAULT_ICONS.Invoicing));

        this._requiredFields([
            new RequiredFieldModel('DOCUMENT_DATE', FIELD_TYPES.Date, TABLE_TYPES.Entity, null)
        ]);

        this.InitRequiredFields();

        this._model.subscribe(() => this.ApplyProperties());

        this.ApplyProperties();
    }


    ApplyProperties() {
        if (this.Properties) {
            const availability = this.GeneralProperties.GetPropertyValue('Availability');
            if (availability.Value === 'OnScreen' || availability.Value === 'Both') {
                this._avalableForScreen(true);
            }
            this.AssignProperty('Styling', 'BackgroundColor', this._backgroundColor);
            this.AssignProperty('Styling', 'TextColor', this._color);
            this.AssignProperty('Styling', 'Border', this._border);
            this.AssignProperty('Styling', 'BorderColor', this._borderColor);
        }
    }

    private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
        if (this.Properties[groupedBy]) {
            _.each(this.Properties[groupedBy].Properties,
                (property: any) => {
                    if (property.hasOwnProperty(propertyName)) {
                        propertyHolder(property[propertyName]);
                    }
                });
        }
    }
}