import * as ko from 'knockout';

import {ITabTablesParams} from './ITabTablesParams';
import {TabTableData} from './Models/TabTableData';

import {GridDataModel} from '../../Models/DataModel';

import Template from './Templates/TabTables.html';


export class TabTables {
    Data: TabTableData[];
    CurrentTabTable: KnockoutObservable<TabTableData>;

    constructor(params: ITabTablesParams) {
        this.Data = params.TableParams.map(tableParams => new TabTableData(tableParams, params.Screen));
        this.CurrentTabTable = ko.observable(null);
    }

    GetTemplate() {
        return Template;
    }

    ValidateData(): boolean {
        let result = true;

        _.each(this.Data, (tab) =>{
            if(!tab.Grid.IsValid){
                result = false;
            };
        });
        return result;
    }

    OnTabClick(tableData: TabTableData) {
        this.CurrentTabTable(tableData);
    }

    SelectTab(index: number) {
        const tabTable = this.Data[index];

        if (tabTable) {
            this.CurrentTabTable(tabTable);
        }
    }

    Serialize(): GridDataModel[] {
        return this.Data
            .map(data => data.Grid.Serialize())
            .filter(gridData => gridData.Rows.length > 0 || gridData.LinkListChanges.length > 0);
    }
}