import {IControl} from 'Core/Controls/IControl'
import {Text} from 'Core/Controls/Text/Text'
import {RecordSharing} from 'Core/Controls/RecordSharing/RecordSharing'
import {UserSecurity} from 'Core/Controls/UserSecurity/UserSecurity'
import {UserAllowance} from 'Core/Controls/UserAllowance/UserAllowance'
import {TableSecurity} from 'Core/Controls/TableSecurity/TableSecurity'
import {TypeSecurity} from 'Core/Controls/TypeSecurity/TypeSecurity'
import {RecordSecurity} from 'Core/Controls/RecordSecurity/RecordSecurity'
import {QueryBuilder} from 'Core/Controls/QueryBuilder/QueryBuilder'
import {LifestatusDesigner} from 'Core/Controls/LifestatusDesigner/LifestatusDesigner'
import {DatabaseDesigner} from 'Core/Controls/DatabaseDesigner/DatabaseDesigner'
import {FormDesigner} from 'Core/Controls/FormDesigner/FormDesigner'
import {LinkList} from 'Core/Controls/LinkList/LinkList'
import {Mail} from 'Core/Controls/Mail/Mail'
import {Scheduler} from 'Core/Controls/Scheduler/Scheduler'
import {Agenda} from 'Core/Controls/Agenda/Agenda'
import {ColorSelector} from 'Core/Controls/ColorSelector/ColorSelector'
import {ButtonEdit} from 'Core/Controls/ButtonEdit/ButtonEdit'
import {ButtonDelete} from 'Core/Controls/ButtonDelete/ButtonDelete'
import {ButtonGoogleMaps} from 'Core/Controls/ButtonGoogleMaps/ButtonGoogleMaps'
import {ButtonListScreen} from 'Core/Controls/ButtonListScreen/ButtonListScreen'
import {ButtonSpecialScreen} from "Core/Controls/ButtonSpecialScreen/ButtonSpecialScreen";
import {ButtonDashboardScreen} from "Core/Controls/ButtonDashboardScreen/ButtonDashboardScreen";
import {ButtonPrint} from 'Core/Controls/ButtonPrint/ButtonPrint'
import {ButtonLinkedIn} from 'Core/Controls/ButtonLinkedIn/ButtonLinkedIn'
import {ButtonTree} from 'Core/Controls/ButtonTree/ButtonTree'
import {ButtonCancel} from 'Core/Controls/ButtonCancel/ButtonCancel'
import {ButtonSave} from 'Core/Controls/ButtonSave/ButtonSave'
import {ButtonPlan} from 'Core/Controls/ButtonPlan/ButtonPlan'
import {ButtonSecurityReset} from 'Core/Controls/ButtonSecurityReset/ButtonSecurityReset'
import {ButtonFollowUp} from 'Core/Controls/ButtonFollowUp/ButtonFollowUp'
import {Tab} from 'Core/Controls/Tab/Tab'
import {TabPage} from 'Core/Controls/TabPage/TabPage'
import {Search} from 'Core/Controls/Search/Search'
import {Dropdown} from 'Core/Controls/Dropdown/Dropdown'
import {Image} from 'Core/Controls/Image/Image'
import {MultiSelect} from 'Core/Controls/MultiSelect/MultiSelect'
import {Checkbox} from 'Core/Controls/Checkbox/Checkbox'
import {DateTime} from 'Core/Controls/DateTime/DateTime'
import {Drop} from 'Core/Controls/Drop/Drop'
import {RadioButton} from 'Core/Controls/RadioButton/RadioButton'
import {Grid} from 'Core/Controls/Grid/Grid'
import {Group} from 'Core/Controls/Group/Group'
import {PortletBar} from 'Core/Controls/PortletBar/PortletBar'
import {Label} from 'Core/Controls/Label/Label'
import {Memo} from 'Core/Controls/Memo/Memo'
import {Document} from 'Core/Controls/Document/Document'
import {ButtonReturn} from 'Core/Controls/ButtonReturn/ButtonReturn'
import {Password} from 'Core/Controls/Password/Password'
import {KanbanBoard} from 'Core/Controls/Kanban/KanbanBoard'
import {ButtonFavorite} from 'Core/Controls/ButtonFavorite/ButtonFavorite'
import {IControlParam} from 'Core/Screens/IScreen'
import {SelectUser} from "Core/Controls/SelectUser/SelectUser";
import {TimeWriting} from "Core/Controls/TimeWriting/TimeWriting";
import {ButtonTemplate} from "Core/Controls/ButtonTemplate/ButtonTemplate";
import {TemplateDesigner} from "Core/Controls/TemplateDesigner/TemplateDesigner";
import {RssFeed} from "Core/Controls/RssFeed/RssFeed";
import {Alias} from "Core/Controls/Alias/Alias";
import {CrystalReportViewer} from "Core/Controls/CrystalViewer/CrystalReportViewer";
import {Tag} from "Core/Controls/Tag/Tag";
import {FieldSecurity} from 'Core/Controls/FieldSecurity/FieldSecurity';
import {ButtonAdd} from 'Core/Controls/ButtonAdd/ButtonAdd';
import {ButtonConsultScreen} from 'Core/Controls/ButtonConsultScreen/ButtonConsultScreen';
import {Basket} from 'Core/Controls/Basket/Basket';
import {History} from "Core/Controls/History/History";
import {QueryResult} from "Core/Controls/QueryResult/QueryResult";
import {GanttChart} from "Core/Controls/GanttChart/GanttChart";
import {Maps} from "Core/Controls/Maps/Maps";
import {Step} from "Core/Controls/Step/Step";
import {StepPage} from "Core/Controls/StepPage/StepPage";

import {CONTROL_TYPES} from 'Core/Constant';
import {PortletSpace} from "Core/Controls/PortletSpace/PortletSpace";
import {BulkEmail} from "Core/Controls/BulkEmail/BulkEmail";
import {GenericButton} from "Core/Controls/GenericButton/GenericButton";
import {InvoicingControl} from "Core/Controls/Invoicing/InvoicingControl";
import {FinancialDashboard} from 'Core/Controls/FinancialDashboard/FinancialDashboard';
import {ButtonCopy} from 'Core/Controls/ButtonCopy/ButtonCopy';
import {Currency} from "Core/Controls/Currency/Currency";
import {Planner} from "Core/Controls/Planner/Planner";
import {PlannerPage} from "Core/Controls/PlannerPage/PlannerPage";
import {PlannerLevel} from "./PlannerLevel/PlannerLevel";
import {IFrame} from "Core/Controls/IFrame/IFrame";
import {ProductConfigurator} from "Core/Controls/ProductConfigurator/ProductConfigurator";
import {Spim} from "./Spim/Spim";
import {SPIMCopy} from "Core/Controls/SpimCopy/SpimCopy";
import {Book} from './Book/Book';
import {Timer} from './Timer/Timer';
import {Signature} from "./Signature/Signature";
import {SpreadsheetControl} from './Spreadsheet/SpreadsheetControl';
import {ChartControl} from './Chart/ChartControl';
import {RecipeEditor} from "./RecipeEditor/RecipeEditor";
import { IFrameExtension } from './IFrameExtension/IFrameExtension'

const controlTypeNameToControlClass = {
    [CONTROL_TYPES.Text]: Text,
    [CONTROL_TYPES.Dropdown]: Dropdown,
    [CONTROL_TYPES.ButtonEdit]: ButtonEdit,
    [CONTROL_TYPES.ButtonDelete]: ButtonDelete,
    [CONTROL_TYPES.ButtonListScreen]: ButtonListScreen,
    [CONTROL_TYPES.ButtonSpecialScreen]: ButtonSpecialScreen,
    [CONTROL_TYPES.ButtonDashboardScreen] : ButtonDashboardScreen,
    [CONTROL_TYPES.ButtonGoogleMaps]: ButtonGoogleMaps,
    [CONTROL_TYPES.ButtonSecurityReset]: ButtonSecurityReset,
    [CONTROL_TYPES.ButtonFollowUp]: ButtonFollowUp,
    [CONTROL_TYPES.History]: History,
    [CONTROL_TYPES.Tab]: Tab,
    [CONTROL_TYPES.TabPage]: TabPage,
    [CONTROL_TYPES.Image]: Image,
    [CONTROL_TYPES.MultiSelect]: MultiSelect,
    [CONTROL_TYPES.Checkbox]: Checkbox,
    [CONTROL_TYPES.DateTime]: DateTime,
    [CONTROL_TYPES.Drop]: Drop,
    [CONTROL_TYPES.RadioButton]: RadioButton,
    [CONTROL_TYPES.Grid]: Grid,
    [CONTROL_TYPES.Group]: Group,
    [CONTROL_TYPES.PortletBar]: PortletBar,
    [CONTROL_TYPES.Label]: Label,
    [CONTROL_TYPES.Memo]: Memo,
    [CONTROL_TYPES.Document]: Document,
    [CONTROL_TYPES.ButtonReturn]: ButtonReturn,
    [CONTROL_TYPES.Kanban]: KanbanBoard,
    [CONTROL_TYPES.SelectUser]: SelectUser,
    [CONTROL_TYPES.ButtonPrint]: ButtonPrint,
    [CONTROL_TYPES.ButtonLinkedIn]: ButtonLinkedIn,
    [CONTROL_TYPES.ButtonTree]: ButtonTree,
    [CONTROL_TYPES.Password]: Password,
    [CONTROL_TYPES.ColorSelector]: ColorSelector,
    [CONTROL_TYPES.Agenda]: Agenda,
    [CONTROL_TYPES.Mail]: Mail,
    [CONTROL_TYPES.Scheduler]: Scheduler,
    [CONTROL_TYPES.LinkList]: LinkList,
    [CONTROL_TYPES.DatabaseDesigner]: DatabaseDesigner,
    [CONTROL_TYPES.FormDesigner]: FormDesigner,
    [CONTROL_TYPES.LifestatusDesigner]: LifestatusDesigner,
    [CONTROL_TYPES.QueryBuilder]: QueryBuilder,
    [CONTROL_TYPES.TableSecurity]: TableSecurity,
    [CONTROL_TYPES.TypeSecurity]: TypeSecurity,
    [CONTROL_TYPES.RecordSecurity]: RecordSecurity,
    [CONTROL_TYPES.UserSecurity]: UserSecurity,
    [CONTROL_TYPES.UserAllowance]: UserAllowance,
    [CONTROL_TYPES.RecordSharing]: RecordSharing,
    [CONTROL_TYPES.ButtonFavorite]: ButtonFavorite,
    [CONTROL_TYPES.Search]: Search,
    [CONTROL_TYPES.ButtonCancel]: ButtonCancel,
    [CONTROL_TYPES.ButtonSave]: ButtonSave,
    [CONTROL_TYPES.TimeWriting]: TimeWriting,
    [CONTROL_TYPES.ButtonTemplate]: ButtonTemplate,
    [CONTROL_TYPES.TemplateDesigner]: TemplateDesigner,
    [CONTROL_TYPES.RssFeed]: RssFeed,
    [CONTROL_TYPES.Alias]: Alias,
    [CONTROL_TYPES.CrystalViewer]: CrystalReportViewer,
    [CONTROL_TYPES.Tag]: Tag,
    [CONTROL_TYPES.FieldSecurity]: FieldSecurity,
    [CONTROL_TYPES.ButtonAdd]: ButtonAdd,
    [CONTROL_TYPES.ButtonConsultScreen]: ButtonConsultScreen,
    [CONTROL_TYPES.Basket]: Basket,
    [CONTROL_TYPES.QueryResult]: QueryResult,
    [CONTROL_TYPES.PortletSpace]: PortletSpace,
    [CONTROL_TYPES.GanttChart]: GanttChart,
    [CONTROL_TYPES.BulkEmail]: BulkEmail,
    [CONTROL_TYPES.GenericButton]: GenericButton,
    [CONTROL_TYPES.Invoicing]: InvoicingControl,
    [CONTROL_TYPES.FinancialDashboard]: FinancialDashboard,
    [CONTROL_TYPES.ButtonCopy]: ButtonCopy,
    [CONTROL_TYPES.Currency]: Currency,
    [CONTROL_TYPES.Planner]: Planner,
    [CONTROL_TYPES.PlannerPage]: PlannerPage,
    [CONTROL_TYPES.PlannerLevel]: PlannerLevel,
    [CONTROL_TYPES.IFrame]: IFrame,
    [CONTROL_TYPES.ProductConfigurator]: ProductConfigurator,
    [CONTROL_TYPES.Spim]: Spim,
    [CONTROL_TYPES.SpimCopy]: SPIMCopy,
    [CONTROL_TYPES.Book]: Book,
    [CONTROL_TYPES.Timer]: Timer,
    [CONTROL_TYPES.Signature]: Signature,
    [CONTROL_TYPES.Spreadsheet]: SpreadsheetControl,
    [CONTROL_TYPES.Chart]: ChartControl,
    [CONTROL_TYPES.RecipeEditor]: RecipeEditor,
    [CONTROL_TYPES.Maps]: Maps,
    [CONTROL_TYPES.IFrameExtension]: IFrameExtension,
    [CONTROL_TYPES.Step]: Step,
    [CONTROL_TYPES.StepPage]: StepPage,
    [CONTROL_TYPES.ButtonPlan]: ButtonPlan
};

export class ControlFactory {
    static CreateControl(params: IControlParam): IControl {
        let model = params.Model;

        let control = null;

        let ControlClass = controlTypeNameToControlClass[model.TypeName];

        if (ControlClass) {
            params.CreateControl = this.CreateControl;
            control = new ControlClass(params);            
        }

        if (!control) {
            console.log(`Not implemented control: ${model.TypeName}`);
            console.log(model);
        }

        return control;
    }
}
