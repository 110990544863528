import * as ko from "knockout";

import {Event} from 'Core/Common/Event';

import TagbookTemplate from "Core/Controls/Tag/Tagbook/TagbookList/TagbookListTemplate.html";
import {TagViewModel} from 'Core/Controls/Tag/ViewModels/TagViewModel';
import { TagModel } from "../../Models/TagsScreenDataModel";
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";

export class TagbookList extends Event {
	private _taglist: TagViewModel[];
	private _taglistFilteredByGroup: TagViewModel[];
	private _columnCount: number;
	private _tagGroups: any[];
	private _itemsPerColumn: number;
	private _initialTaglist: TagViewModel[];
	private _mappedTagsList: KnockoutObservableArray<any>;
	private _mappedGroupsList: KnockoutObservableArray<any>;
	private _mode: KnockoutObservable<string>;
	private _groupId: number;
	private _labels = LABELS;

	constructor(taglist: TagViewModel[], tagGroups) {
		super();

		this._taglist = [...taglist];
		this._taglistFilteredByGroup = [];
		this._initialTaglist = taglist;
		this._tagGroups = tagGroups;
		this._columnCount = 1;
		this._mappedTagsList = ko.observableArray([]);
		this._mappedGroupsList = ko.observableArray([]);
		this._itemsPerColumn = 12;
		this._mode = ko.observable('book');
		this._groupId = null;

		this.MapToViewlist();
	}

	ChangeMode(nextMode: string): void {
		this._mode(nextMode);

		this.MapToViewlist();
	}

	private MapToViewlist() {
		let list;
		let mappedListObservable;

		if (this._mode() === 'book') {
			list = this._taglist;
			mappedListObservable = this._mappedTagsList;
		} else if (this._mode() === 'group') {
			list = this._tagGroups;
			mappedListObservable = this._mappedGroupsList;
		}

		const mappedListLength = Math.ceil(list.length / this._itemsPerColumn);
		let mappedList = Array(mappedListLength >= 2 ? mappedListLength : 2).fill(null);

		mappedList = mappedList.map((el) => []);

		list.forEach((el, index) => {
			const arrayIndex = Math.floor(index / this._itemsPerColumn);
			const elementIndex = index % this._itemsPerColumn;

			mappedList[arrayIndex][elementIndex] = el;
		});

		mappedListObservable(mappedList);

		// console.log('mappedListObservable()', mappedListObservable());

		// mappedListObservable.valueHasMutated();
	}

	FilterList(firstLetter: string, groupId: any, isInGroupMode?: boolean) {
		let groupId_ = groupId ? groupId.Id : null;

		if (firstLetter === LABELS.ALL) {
			if (groupId_ === null) {
				this._taglist = this._initialTaglist;
			} else {
				this._taglist = this._initialTaglist.filter((tag) => tag.GroupId === groupId_);
			}

			this.MapToViewlist();

			return;
		}

		if (groupId_ !== null && firstLetter !== null) {
			this._taglist = this._initialTaglist.filter((tag) => tag.Name[0].toUpperCase() === firstLetter);
			this._taglist = this._taglist.filter((tag) => tag.GroupId === groupId_);
		} else if (firstLetter !== null) {
			this._taglist = this._initialTaglist.filter((tag) => tag.Name[0].toUpperCase() === firstLetter);
		} else if (groupId_ !== null) {
			this._taglist = this._initialTaglist.filter((tag) => tag.GroupId === groupId_);
		}

		this.MapToViewlist();

		return;
	}

	SelectTag(tag): void {
		this.Trigger('TagSeleted', {data: tag});
	}

	SelectGroup(group): void {
		this.Trigger('GroupSeleted', {data: group});
	}

	IdentifyGroupName(id: number): string {
		return this._tagGroups.filter((group) => group.Id === id)[0].Name;
	}

	DisplayName(tag) {
		let displayName = `<span>${tag.Name}</span>`;

		if (tag.IsFirstWord) {
			displayName = `<span class="first-word-letter">${tag.Name[0]}</span><span>${tag.Name.slice(1)}</span>`
		}

		return displayName;
	}

	GetTemplate(): any {
		return TagbookTemplate;
	}
}
