import * as _ from "underscore";

import {ColorPaletteItem} from "./ColorModels/Palette/ColorPaletteItem";

import * as RALColorsJson from "Core/Components/ColorSelector/ColorModels/Palette/Data/RALColors.json";
import * as PaletteColorsJson from "Core/Components/ColorSelector/ColorModels/Palette/Data/PaletteColors.json";

export class ColorConverter {
    private static _paletteColors: ColorPaletteItem[];
    private static _ralColors: ColorPaletteItem[];

    private static get RalColors() {
        if (this._ralColors) {
            return this._ralColors;
        }

        this._ralColors = this.ReadColorPalette(RALColorsJson);
        return this._ralColors;
    }

    private static get PaletteColors() {
        if (this._paletteColors) {
            return this._paletteColors;
        }

        this._paletteColors = this.ReadColorPalette(PaletteColorsJson);
        return this._paletteColors;
    }

    static ToHex(color: string) {
        if (!color) {
            return null;
        }

        if (color.startsWith('#')) {
            return color;
        }

        if (color.startsWith('RAL')) {
            return this.FromRalToHex(color);
        }

        return this.FromPaletteToHex(color);
    }

    static Hexify(color: string, alpha?: number) {
        const rgba = this.HexToRGB(color, alpha);
        const values = rgba
            .replace(/rgba?\(/, '')
            .replace(/\)/, '')
            .replace(/[\s+]/g, '')
            .split(',');
        const a = parseFloat(values[3] || '1'),
            r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
            g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
            b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255);
        return "#" +
            ("0" + r.toString(16)).slice(-2) +
            ("0" + g.toString(16)).slice(-2) +
            ("0" + b.toString(16)).slice(-2);
    }

    static HexToRGB(hex: string, alpha?: number) {
        const r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        } else {
            return "rgb(" + r + ", " + g + ", " + b + ")";
        }
    }

    static GetContrast(hexValue) {
        if (hexValue) {
            if (hexValue.slice(0, 1) === '#') {
                hexValue = hexValue.slice(1);

                if (hexValue.length === 3) {
                    hexValue = _.map(hexValue.split('') as number[], (hex) => {
                        return hex + hex;
                    }).join('');
                }

                let r = parseInt(hexValue.substr(0,2),16);
                let g = parseInt(hexValue.substr(2,2),16);
                let b = parseInt(hexValue.substr(4,2),16);

                let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

                return (yiq >= 128) ? 'black' : 'white';
            }
        }
    }

    private static ReadColorPalette(json: any) {
        try {
            return ColorPaletteItem.Deserialize(json);
        } catch (e) {
            console.error(e.message);
            return [];
        }
    }

    private static FromPaletteToHex(color: string) {
        const colorItem = _.find(this.PaletteColors, item => item.Name === color);
        return colorItem && colorItem.Value || null;
    }

    private static FromRalToHex(color: string) {
        const colorItem = _.find(this.RalColors, item => item.Name === color);
        return colorItem && colorItem.Value || null;
    }
}