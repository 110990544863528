import * as ko from "knockout";

import { LABELS } from 'Core/Components/Translation/Locales';
import { FieldMetadataModel } from 'Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel';
import { DropdownDataStore } from 'Core/Controls/Dropdown/Stores/DropDownDataStore';
import { DropdownListModel } from 'Core/Controls/Dropdown/Models/DropdownListModel';
import { GlobalManager, GLOBALS } from 'Core/GlobalManager/GlobalManager';
import { SearchScreen } from 'Core/Screens/SearchScreen/SearchScreen';
import { EVENTS as SEARCH_SCREEN_EVENTS } from 'Core/Screens/SearchScreen/Events';

import {DropdownItemModel} from 'Core/Controls/Dropdown/Models/DropdownListModel';

import Template from 'QueryBuilder/QueryCondition/ConditionEditors/Spim/Templates/Spim.html'

export class Spim {
	Value: KnockoutObservable<any>;
	LowValue: KnockoutObservable<any>;
	HighValue: KnockoutObservable<any>;

	private _labels = LABELS;
	private _featureTypes = ['A', 'L', 'N', 'R'];
	public FeatureType: KnockoutObservable<any>;

	private _items: KnockoutObservableArray<DropdownItemModel>;
	private _selectedItem: KnockoutObservable<DropdownItemModel>;
	private _showSearchScreenButton: KnockoutObservable<boolean>;
	private _searchScreenIfRecordsCount: number;

	private _fieldId: FieldMetadataModel;
	private _firstLoad: boolean;

	constructor(field: FieldMetadataModel) {
		this._fieldId = field;
		this.Value = ko.observable(false);
		this.FeatureType = ko.observable(this._featureTypes[0]);
		this.LowValue = ko.observable(null);
		this.HighValue = ko.observable(null);

		this.LowValue.subscribe((newValue)=>{
			this.Value(`${this.LowValue()};${this.HighValue()}`);
		});

		this.HighValue.subscribe((newValue)=>{
			this.Value(`${this.LowValue()};${this.HighValue()}`);
		});

		this.FeatureType.subscribe((newValue)=>{
			if(newValue === 'L'){
				this.Value(false);
			}

			if(newValue === 'N'){
				this.Value(null);
			}

			if(newValue === 'A'){
				this.Value(null);
			}

		});

		this.Value = ko.observable(null);
		this._selectedItem = ko.observable(null);
		this._showSearchScreenButton = ko.observable(false);
		this._items = ko.observableArray([]);
		this._firstLoad = true;

		var searchScreenIfRecordsCount = parseInt(GlobalManager.Instance.GetGlobal(GLOBALS.SEARCH_SCREEN_IF_RECORDS_COUNT));
		this._searchScreenIfRecordsCount = Number.isNaN(searchScreenIfRecordsCount) ? 10 : searchScreenIfRecordsCount;

		this.Value.subscribe((newValue) => {
			if (this._firstLoad) {
				this.LoadData();
			}
		});

		this._selectedItem.subscribe((newValue: DropdownItemModel) => {
			if (newValue) {
				this.Value(`${newValue.RecordId}`);
			}
		});

		if(this.FeatureType() === 'A'){
			this.LoadData();
		}

	}

	GetTemplate() {
		return Template;
	}

	AfterRender(el: Array<HTMLElement>) {

	}

	LoadData() {
		DropdownDataStore.Get({ FieldId: this._fieldId.Id, RecordId: parseInt(this.Value()), SubjectRecordId: 0, SubjectTypeId: 0, SubjectKindId: 0, SubjectStatusId: 0, IsEnableFilter: false })
			.then((dropDownData: DropdownListModel) => {
				this._showSearchScreenButton(dropDownData.RecordsCount > this._searchScreenIfRecordsCount);
				this._firstLoad = false;
				this._items(dropDownData.Items);
				this._selectedItem(_.find(dropDownData.Items,(item: DropdownItemModel) => {
					return item.RecordId === parseInt(this.Value());
				}));
		});
	}

	get FieldId(): number {
		return this._fieldId.Id;
	}

	ShowSearchScreen() {
		const searchScreen = new SearchScreen({ EntityId: this._fieldId.ValTableId, SearchTerm: '', ButtonAdd: false });
		searchScreen.On(SEARCH_SCREEN_EVENTS.RECORD_SELECTED, this, (eventArgs) => {
			this.Value(eventArgs.data.RecordId);
			this.LoadData();
		});
		searchScreen.Show();
	}
} 