//Utils
import { DateTime } from "Core/Components/Controls/TimeWriting/Utils/DateTime";

export class State {
	Date: Date;

	constructor(initializers?: Partial<State>) {
		if (initializers) {
			this.Date = initializers.Date;
		}
	}

	get Year() {
		return DateTime.GetWeekYear(this.Date);
	}

	get Week() {
		return DateTime.GetWeekNumber(this.Date); 
	}
}