import * as ko from 'knockout'
import * as _ from 'underscore';

import { BaseControl, IControlValue } from 'Core/Controls/BaseControl/BaseControl'
import { IControlParam } from 'Core/Screens/IScreen'
import { RenderModes } from "Core/Constant";
import { GeneralProperties } from "Core/GeneralProperties/GeneralProperties";
import {CHECKBOX_PROPERTIES} from "Core/GeneralProperties/Managers/CheckboxProperty/Constants";

import DeleteButtonConfig from "Core/Controls/ButtonDelete/Configs/delete-button-config.json";

import ViewTemplate from 'Core/Controls/ButtonDelete/Templates/View.html'
import HelpViewTemplate from 'Core/Controls/ButtonDelete/Templates/HelpView.html'
import DesignTemplate from 'Core/Controls/ButtonDelete/Templates/Design.html'
import ToolBarTemplate from 'Core/Controls/ButtonDelete/Templates/ToolBar.html'

ko.templates['Core/Controls/ButtonDelete/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/ButtonDelete/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/ButtonDelete/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/ButtonDelete/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/ButtonDelete/Templates/Design'] = DesignTemplate;

export class ButtonDelete extends BaseControl {
	private _mainRecordId: number;

	constructor(params: IControlParam) {
		super(params, DeleteButtonConfig);
        this._style = ko.computed(() => {
            return {
                backgroundColor: this._backgroundColor(),
                color: this._color(),
                borderColor: this._borderColor(),
                borderWidth: this._border() ? '1px' : '0',
                padding: this._border() ? '6px 14px' : '7px 14px'
            };
        });
		this.Init();
	}

	private Init(): void {    
        this.ApplyProperties();
	}

	Click() {
		if (this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
			return;
		}

		if (this.IsDisabledByCondition) {
			return;
		}

		var screen = this._form.GetScreen();
		if (screen) {
			screen.Trigger('DELETE_RECORD');
		}
	}

	SetValue(value: IControlValue): void {
		if (value.RecordSpecsModel && value.RecordSpecsModel.IsNewRecord) {
			this._model().IsDisabledByCondition = true;
			return;
		}

		if (value.Data && value.Data.Rights) {
			var enabledCondition = value.Data.Rights.IsDeletingAllowed && value.Data.Rights.IsRecordSecurityOk;
			this._model().IsDisabledByCondition = !enabledCondition;
		}
	}

	SetHideCaption(data: boolean) {
		this.HideCaption(this.HideCaptionFromProperty() ? this.HideCaptionFromProperty() : data);
	}

	GetHideCaption(): boolean {
		return this.HideCaption();
	}

    ApplyProperties() {
        if (this.Properties) {
            this.AssignProperty('Styling', 'BackgroundColor', this._backgroundColor);
            this.AssignProperty('Styling', 'TextColor', this._color);
            this.AssignProperty('Styling', 'Border', this._border);
            this.AssignProperty('Styling', 'BorderColor', this._borderColor);
			this.AssignProperty('Styling', CHECKBOX_PROPERTIES.HIDE_CAPTION, this.HideCaptionFromProperty);
			this.HideCaption(this.HideCaptionFromProperty());
        }
    }

    private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
        if (this.Properties[groupedBy]) {
            _.each(this.Properties[groupedBy].Properties,
                (property: any) => {
                    if (property.hasOwnProperty(propertyName)) {
                        propertyHolder(property[propertyName]);
                    }
                });
        }
    }

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}
} 