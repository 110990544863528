import { IScreen } from "Core/Screens/IScreen";
import {Event} from 'Core/Common/Event';
import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';
import { controlFacade } from "./ControlFacade";
export interface INavigateToScreenOptions {
    entityId: number;
    recordId: number;
}

export const screenFacade = (screen: IScreen) => {

    const addOnAfterSave = (handler: Function) =>{
        screen.On('RECORD_SAVED', this, ()=> handler.apply(null));
    }

    const getAllControls = () =>{
        return _.map(screen.GetAllControls(), (control)=> controlFacade(control));
    }

    const showInModal = () =>{
        screen.ShowInModal();                    
    }

    const navigateToScreen = (options: INavigateToScreenOptions) =>{
        PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, {
            EntityId: options.entityId,
            RecordId: options.recordId
        });
    }

    return { addOnAfterSave, getAllControls, navigateToScreen, showInModal }
}