export class ResizeObserver {
    private _idWidth = 0;
    private _listenersWidth = {};
    private _resizeIntervalWidth = null;
    private _resizeIntervalCheckDelayWidth = 1000;

    private _idHeight = 0;
    private _listenersHeight = {};
    private _resizeIntervalHeight = null;
    private _resizeIntervalCheckDelayHeight = 1000;

    /**
     * @param action {function} => action to call after width change
     * @param el {HTMLElement} => element to listen width changing
     * @param Unsubscribe {function} => that stop listening the element
     */
    public SubscribeWidth(action: Function, el: HTMLElement) {
        let id = this._idWidth++;
        this._listenersWidth[id] = {
            action,
            el,
            oldWidth: el.offsetWidth
        };
        if (!this._resizeIntervalWidth) {
            this._resizeIntervalWidth = setInterval(this.OnResizeWidth, this._resizeIntervalCheckDelayWidth);
        }
        return () => this.UnsubscribeWidth(id);
    }

    private OnResizeWidth = () => {
        let res = 2;
        for (let key in this._listenersWidth) {
            const { oldWidth, action, el } = this._listenersWidth[key];
            const newWidth = el.offsetWidth;
            if (newWidth !== oldWidth) {
                this._listenersWidth[key].oldWidth = newWidth;
                setTimeout(action, Math.min(10 * res++, 100)); // prevent update of all grids at the same time
            }
        }
    };

    private UnsubscribeWidth(id) {
        delete this._listenersWidth[id];
        if (!Object.keys(this._listenersWidth).length) {
            clearInterval(this._resizeIntervalWidth);
            this._resizeIntervalWidth = null;
        }
    }

    /**
     * @param action {function} => action to call after Height change
     * @param el {HTMLElement} => element to listen Height changing
     * @param UnsubscribeHeight {function} => that stop listening the element
     */
    public SubscribeHeight(action: Function, el: HTMLElement) {
        let id = this._idHeight++;
        this._listenersHeight[id] = {
            action,
            el,
            oldHeight: el.offsetHeight
        };
        if (!this._resizeIntervalHeight) {
            this._resizeIntervalHeight = setInterval(this.OnResizeHeight, this._resizeIntervalCheckDelayHeight);
        }
        return () => this.UnsubscribeHeight(id);
    }

    private OnResizeHeight = () => {
        let res = 2;
        for (let key in this._listenersHeight) {
            const { oldHeight, action, el } = this._listenersHeight[key];
            const newHeight = el.offsetHeight;
            if (newHeight !== oldHeight) {
                this._listenersHeight[key].oldHeight = newHeight;
                setTimeout(action, Math.min(10 * res++, 100)); // prevent update of all grids at the same time
            }
        }
    };

    private UnsubscribeHeight(id) {
        delete this._listenersHeight[id];
        if (!Object.keys(this._listenersHeight).length) {
            clearInterval(this._resizeIntervalHeight);
            this._resizeIntervalHeight = null;
        }
    }
}
