import * as ko from "knockout";

import { LABELS } from "Core/Components/Translation/Locales";
import {IControl} from "Core/Controls/IControl";
import {BaseControlEditor, EVENTS as BASE_CONTROL_EDITOR_EVENTS} from "./BaseControlEditor";
import {DesignScreen} from "Core/Screens/DesignScreen/DesignScreen";
import {IconManager} from "Core/Components/IconManager/IconManager";
import {IconModel} from "Core/Controls/BaseControl/Models/IconModel";
import {Notifier} from "Core/Common/Notifier";

import Template from 'Core/Screens/DesignScreen/ControlEditor/Templates/GenericButtonControlEditor.html';
ko.templates['Core/Screens/DesignScreen/ControlEditor/Templates/GenericButtonControlEditor'] = Template;

export class GenericButtonControlEditor extends BaseControlEditor {

    private _labels = LABELS;
    private _iconModel = IconModel;
    private _icon: KnockoutObservable<IconModel>;

    constructor(control: IControl) {
        super(control);
        this._icon = ko.observable(control.GetIcon());
    }

    GetTemplateName() {
        return 'Core/Screens/DesignScreen/ControlEditor/Templates/GenericButtonControlEditor';
    }

    GetControlEditorModel() {
        const controlEditorModel = super.GetControlEditorModel();
        controlEditorModel.Icon = this._icon();
        return controlEditorModel;
    }

    Save(): void {
        const validationError = this.Validate();
        if (validationError) {
            new Notifier().Warning(validationError);
            return;
        }

        const controlEditorModel = this.GetControlEditorModel();
        this.Trigger(BASE_CONTROL_EDITOR_EVENTS.CONTROL_SAVED, {UpdateControlModel: controlEditorModel});
        this.Close();
    }

    OpenIconManager() {
        const screen = this.Control.GetForm().GetScreen() as DesignScreen;
        const icons = screen.DesignModel.Icons;

        let iconManager = new IconManager(icons);
        iconManager.Show();

        iconManager.On('ICON_SELECTED', this, (eventArgs) => {
            this._icon(eventArgs.data.Icon);
        });
    }

    get Icon() {
        return this._icon();
    }
}