import {deserialize} from 'libs/cerialize';

export class GlobalModel {
	@deserialize
	Id: number;

	@deserialize
	KeepInSession: boolean;

	@deserialize
	LockToDefault: boolean;

	@deserialize
	Name: string;

	@deserialize
	TranslatedName: string;

	@deserialize
	TypeId: number;

	@deserialize
	TypeName: string;

	@deserialize
	Value: string;
}