import * as ko from 'knockout';
import { deserialize, deserializeAs } from 'libs/cerialize';
import { ActionSubjectRecordModel } from "Core/ScreenManager/Models/ActionSubjectRecordModel";
import {FollowUpBaseRecordModel} from "Core/Controls/ButtonFollowUp/Models/FollowUpBaseRecordModel";

export class FollowUpParentRecordModel extends FollowUpBaseRecordModel {
	constructor(password?: string) {
		super(password);
	}
	ParentActionSubject?: ActionSubjectRecordModel;
}
