import * as ko from "knockout";
import * as _ from "underscore";

import { ViewModes } from "Core/Controls/Grid/BaseGrid/Enums/ViewModes";
import { EVENTS as QUERY_BUILDER_EVENTS } from 'QueryBuilder/Events';
import { Modal } from 'Core/Common/Modal';
import { FieldMetadataModel } from 'Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel';
import { AttachedFieldModel } from 'Core/Controls/BaseControl/Models/AttachedFieldModel';

import Template from 'QueryBuilder/QueryCondition/ConditionEditors/QueryBuilder/Templates/QueryBuilder.html'
import { IControlAttachedField } from "Core/Screens/BaseScreen";

export class QueryBuilder {
	Value: KnockoutObservable<any>;
	private _el: HTMLElement;
	private _fieldMetadata: FieldMetadataModel;
	private _screenFields: Array<IControlAttachedField>;


	constructor(fieldMetadata: FieldMetadataModel, screenFields: Array<IControlAttachedField>) {
		this.Value = ko.observable('');
		this._fieldMetadata = fieldMetadata;
		this._screenFields = screenFields;
	}

	ShowQueryBuilder() {
		var modal = new Modal();
		var queryBuilder = require('QueryBuilder/QueryBuilder');
		var queryBuilder = new queryBuilder.QueryBuilder(true, false, false, false, this._screenFields);
		queryBuilder.ShowQueryName = false;
		queryBuilder.On(QUERY_BUILDER_EVENTS.CLOSE, this, (eventArgs: any) => { modal.Close(); });

		queryBuilder.On(QUERY_BUILDER_EVENTS.DATA_SAVED, this, (eventArgs: any) => {
			this.Value(eventArgs.data.QueryText);
			modal.Close();
		});

		queryBuilder.QueryTypeName = ViewModes.Query;
		queryBuilder.SingleColumn = true;
		queryBuilder.SingleColumnType = this._fieldMetadata.Type;
		modal.Show();

		if (this.Value()) {
			queryBuilder.EditFreeQuery(JSON.parse(this.Value()));
		} else {
			queryBuilder.NewFreeQuery();
		}

		ko.cleanNode(modal.Wrapper);
		ko.applyBindings(queryBuilder, modal.Wrapper);
	}

	GetTemplate() {
		return Template;
	}

	AfterRender() {}
} 