export class LifeStatuses {
	static Enabled = new LifeStatuses("Enabled");
	static Disabled = new LifeStatuses("Disabled");

	private readonly _name: string;

	private constructor(name: string) {
		this._name = name;
	}

	get Name() {
		return this._name;
	}

	static GetAll() {
		return [
			LifeStatuses.Enabled.Name,
			LifeStatuses.Disabled.Name
		];
	}
}