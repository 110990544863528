export const QUERY_BUILDER = {
	CELL_SIZE: 20
}

export const QUERY_TYPES = {
	TABLE_VIEW: 'TableView',
	QUERY: 'Query',
	TEMPLATE: 'Template'
}

export const ENTITY_COLORS = {
	MAIN_ENTITY: 'color-view bg-yellow-gold bg-font-yellow-gold',
	RELATED_ENTITY: 'color-view bg-blue bg-font-blue',
	SUB_ENTITY: 'color-view bg-blue bg-font-blue'
}

export const AGGREGATE_FUNCTIONS = {
	COUNT: 'COUNT',
	SUM: 'SUM',
	MIN: 'MIN',
	MAX: 'MAX',
	AVG: 'AVG'
}

export const COMPARISON_OPERATOR_LABELS = {
	Equals: '=',
	NotEquals: '!=',
	IsNull: 'IsNull',
	IsNotNull: 'IsNotNull',
	Like: 'Like',
	GreaterThen: '>',
	LessThen: '<',
	GreaterOrEqual: '>=',
	LessOrEqual: '<=',
	In: 'In',
	NotIn: 'NotIn',
	StartsWith: 'Starts with',
	NotStartWith: 'Not start with',
	EndsWith: 'Ends with',
	NotEndWith: 'Not end with',
	NotLike: 'Not like'
}