import * as _ from "underscore";

import {deserialize} from 'libs/cerialize';

export class SpaceStatusDto {
    @deserialize
    Environment: string;

    @deserialize
    DeviceStatus: Array<string>;
}