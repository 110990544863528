import {PropertyValueDto} from "./PropertyValueDto";
import {CustomFieldValueDto} from "./CustomFieldValueDto";
import {PcfGroup} from "Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/PcfGroup";

export class ProductPartDto {
	PropertyValues: PropertyValueDto[];
	CustomFieldValues: CustomFieldValueDto[];
    Price: number;
    Quantity: number;
    Level: string;
    PcfGroup: PcfGroup;

    constructor(public Id: number, public KSeq: number, public Path: number[], groupId: number, groupName: string) {
        this.PcfGroup = new PcfGroup(groupId, groupName, null, []);
    }
}