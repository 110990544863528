import {EventBusConsumer} from "Core/Common/EventBus/EventBusConsumer";

import {BaseProductGroup} from "./BaseProductGroup";
import {RootGroup} from "./RootGroup";

import {ImageTableTypes} from "../../../Components/ProductImageViewer/Enums/ImageTableTypes";
import { ImagePreloader } from "../../../../../../Common/Image";

export abstract class BaseProduct extends EventBusConsumer {
    protected container: HTMLElement;
    public Image: string;

    protected constructor(public Id: number,
                          public KSeq: number,
                          public Name: string,
                          public NameTranslated: string,
                          image: string,
                          public Description: string,
                          protected basePrice: number,
                          protected parentGroup: BaseProductGroup) {
        super();
        this.Image = image ? `api/FilesApi/GetImage/${image}` : null;
        ImagePreloader.Preload(this.Image);
    }

    get Container() {
        return this.container;
    }

    get BasePrice() {
        return this.basePrice;
    }

    get ParentGroup() {
        return this.parentGroup;
    }

	get ImageTableType() {
		return ImageTableTypes.Product;
	}

    abstract RootGroupIs(rootGroup: RootGroup);

    AfterRender(el: HTMLElement) {
        this.container = el[0];
    }
}