import {RecipeNodeViewModel} from "./RecipeNodeViewModel";

export class RecipeViewModel {
    private _nodes: RecipeNodeViewModel[];

    constructor() {
        this._nodes = [];
    }

    Add(node: RecipeNodeViewModel) {
        this._nodes.push(node);
    }

    AddRange(nodes: RecipeNodeViewModel[]) {
        nodes.forEach(this.Add.bind(this));
    }

    GetNode(id: string, callback: (matchedNode: RecipeNodeViewModel) => void) {
        this._nodes.forEach(node => node.GetNode(id, callback));
    }

    get RootNode(): RecipeNodeViewModel {
        return this._nodes[0];
    }

    GetNodeByRecordId(id: number, callback: (matchedNode: RecipeNodeViewModel) => void) {
        this._nodes.forEach(node => node.GetNodeByRecordId(id, callback));
    }

    SetInitialNode(initialNodeId: number) {
        this._nodes.forEach(node => node.SetInitialNode(initialNodeId));

        // if (this.CurrentNode) {
        //     this.CurrentNode.SetInitialNode(initialNodeId);
        // }
    }

    ToJson() {
        return this._nodes.map(node => node.ToJson());
    }
}