import * as ko from 'knockout';
import {EventBus} from "Core/Common/EventBus/EventBus";
import {EventBusConsumer} from "Core/Common/EventBus/EventBusConsumer";

import {NavigationStack} from '../Stores/NavigationStack/NavigationStack';
import {BackButton} from '../Components/BackButton/BackButton';
import {ProductImageViewer} from '../Components/ProductImageViewer/ProductImageViewer';
import {ConfigurationRole} from "./ConfigurationPage/Models/ConfigurationRole";

export interface IProductConfigurationPageParams {
    OrderEntityId: number;
    ProductsEntityId: number;
    OrderId: number;
    ConfigurationRoles: ConfigurationRole[];
}

export abstract class ProductConfiguratorPage<TParams extends IProductConfigurationPageParams> extends EventBusConsumer {
    protected container: HTMLElement;
    protected totalContinueBtnContainer: HTMLElement;
    protected backButton: BackButton;
	protected productImageViewer: ProductImageViewer;
    protected ConfigurationRoles: KnockoutObservableArray<ConfigurationRole>;
    protected configRolesDropdownActive: KnockoutObservable<boolean>;
    protected ActiveConfigurationRole: KnockoutObservable<ConfigurationRole>;
    protected RolesImplemented: boolean;

    protected constructor(protected params: TParams, protected navigationStack: NavigationStack, eventBus: EventBus) {
        super();

		this.backButton = new BackButton(navigationStack);
		this.productImageViewer = new ProductImageViewer({
			ProductsEntityId: params.ProductsEntityId,
			OrderEntityId: params.OrderEntityId
		});
        this.AssignEventBus(eventBus);

        this.RolesImplemented = params.ConfigurationRoles.length > 0;

        this.ConfigurationRoles = ko.observableArray(params.ConfigurationRoles);
        this.ActiveConfigurationRole = ko.observable(this.GetActiveConfigurationRole());
        this.configRolesDropdownActive = ko.observable(params.ConfigurationRoles.length > 1);
    }

    GetActiveConfigurationRole() {
        return this.ConfigurationRoles().find(r => r.Active())
    }

    SelectConfigurationRole(selectedRole: ConfigurationRole) {
        if (selectedRole.Id != this.ActiveConfigurationRole().Id) {
            _.each(this.ConfigurationRoles(), role => (role.Id == selectedRole.Id) ? role.Active(true) : role.Active(false));
            this.ActiveConfigurationRole(this.GetActiveConfigurationRole());
        }
    }

    abstract GetTemplate();

    AfterRender(el: HTMLElement): void {
        this.container = el[0];
    }

    Dispose() {
        super.Dispose();
        this.backButton.Dispose();
    }
}