import * as ko from 'knockout';
import * as _ from 'underscore';
import {Modal} from 'Core/Common/Modal';

import {TableTypeModel} from 'Core/Screens/TypeScreen/Models/TableTypeModel'
import {EntityTypesStore} from 'Core/Screens/TypeScreen/Stores/EntityTypesStore';
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';

import TruncateFileName from 'Core/Common/TruncateFileName';

import {DropDataStore} from 'Core/Controls/Drop/Stores/DropDataStore';
import {DropFileModel} from 'Core/Controls/Drop/Models/DropFileModel';
import {DropDataModel} from 'Core/Controls/Drop/Models/DropDataModel';
import {UploadChunkModel} from 'Core/Controls/Drop/Models/UploadChunkModel';
import {Guid} from "Core/Common/Guid";
import {LABELS} from "Core/Components/Translation/Locales";

import DropFilesModalTemplate from 'Core/Controls/Drop/Templates/DropFilesModal.html'
import { Notifier } from '../../Common/Notifier';
import {GetLimitOfUploadedDocumentsRequestModel} from "./Models/GetLimitOfUploadedDocumentsRequestModel";
import {BlockUI} from "../../Common/BlockUi";
import { P } from 'Core/Common/Promise';


ko.templates['Core/Controls/Drop/Templates/DropFilesModal'] = DropFilesModalTemplate;

interface DropFilesModalOptions {
    DocumentEntityId: number;
    DocumentTypeId?: number;
    OnOkayCallBack: Function;
    OnCloseCallBack: Function;
    ScreenTypeName: string;
    HasDwPackage: boolean;
    DestinationTypeId?: number;
}

export class DropFilesModal {
    private _modal: Modal;
    private OnOkayCallBack: Function;
    private OnCloseCallBack: Function;
    private _files: KnockoutObservableArray<DropDataModel>;
    private _checkedFiles: KnockoutObservableArray<any> = ko.observableArray([]);
    private _hasCustomTypes: KnockoutObservable<boolean> = ko.observable(true);
    private _allFilesCheck: KnockoutObservable<boolean> = ko.observable(true);
    private _uploadingStarted: KnockoutObservable<boolean> = ko.observable(false);
    private _uploadingFinished: KnockoutObservable<boolean> = ko.observable(false);
    private _allSelectValue: KnockoutObservable<number> = ko.observable(null);
    private _showOkayButton: KnockoutObservable<boolean> = ko.observable(false);
    private _originalFiles = [];
    private _entityTypes: KnockoutObservableArray<TableTypeModel> = ko.observableArray([]);
    private _isConsultScreen: boolean;
    private _readyFiles: object[];
    private _labels = LABELS;
    private _filesTypeSelected: KnockoutComputed<boolean>;
    private _affectedRecords: KnockoutObservable<number> = ko.observable(null);
    private _hasDwPackage: boolean;
    private _requiredXlsxFields: KnockoutObservableArray<string>;
    private _nonrequiredXlsxFields: KnockoutObservableArray<string>;
    private _hasMissingFields: KnockoutComputed<boolean>;
    private _disabledElment: KnockoutComputed<boolean>;
    private _destinationTypeId: number;

    constructor(options: DropFilesModalOptions) {
        this._requiredXlsxFields = ko.observableArray([]);
        this._nonrequiredXlsxFields = ko.observableArray([]);
        this._hasMissingFields = ko.computed(()=> this._requiredXlsxFields().length != 0 || this._nonrequiredXlsxFields().length != 0);

        this._destinationTypeId = options.DestinationTypeId;

        this._hasDwPackage = options.HasDwPackage;
        this._files = ko.observableArray([]);
        this.OnOkayCallBack = options.OnOkayCallBack;
        this.OnCloseCallBack = options.OnCloseCallBack;
        this._filesTypeSelected = ko.computed(() => {
            if(this._requiredXlsxFields().length > 0){
                return false;
            }

            if (!this._hasCustomTypes()) {
                return true;
            }

            const checkedFiles = this._files().filter((file, index) => this._checkedFiles().indexOf(index) > -1);
            return _.every(checkedFiles, file => file.FType > 0);
        });

        this._disabledElment =ko.computed(()=> this._uploadingStarted() || (!!this._destinationTypeId && !this._hasDwPackage) );

        this._isConsultScreen = options.ScreenTypeName === ScreenTypes[ScreenTypes.ConsultScreen];

        this._modal = new Modal({
            minWidth: 368,
            maxWidth: "100%",
            height: 'auto',
            minHeight: 100,
            closeButton: false,
            onClose: this.OnClose,
            addClass: "drop-files-modal-main"
        }, false);

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);

        if (options.DocumentTypeId !== undefined) {
            this._hasCustomTypes(false);
        } else {
            EntityTypesStore.GetTypes({
                EntityId: options.DocumentEntityId,
                ParentTypeId: 0,
                WithRoot: true,
                OnlyEnabled: true,
                EntityName: null
            })
                .then(tableTypesModel => {
                    const types = tableTypesModel.TableTypes.filter(type => type.IsEnabled);
                    if (types.length) {
                        if (types.length === 1 && types[0].Name === '-') {
                            this._hasCustomTypes(false);
                        }
                    } else {
                        this._hasCustomTypes(false);
                    }
                    if (types.length === 1) {
                        const singleTypeId = _.first(types).Id;
                        _.each(this._files(), file => { file.FType = singleTypeId; });
                        this._hasCustomTypes(false);
                    }

                    _.each(types, type => type.TranslatedName = type.TranslatedName || type.Name);
                    this._entityTypes(types);

                    if(!!this._destinationTypeId){
                        _.each(this._files(), file => { file.FType = this._destinationTypeId; });
                        let files = this._files();
                        this._files([]);
                        this._files(files);

                        if(!this._hasDwPackage){
                            this.OnOkay();
                        }
                    }

                });
        }
        this.SubscribeObservables();
    }

    private SetOptionDisable(option, value) {
        ko.applyBindingsToNode(option, {
            disable: !value
        }, value);
    }

    private SubscribeObservables() {
        let changinFromSubscription = false;
        this._allFilesCheck.subscribe(newValue => {
            if (changinFromSubscription) {
                changinFromSubscription = false;
                return;
            }
            const newdata = [];
            if (newValue) {
                const files = this._files();
                const filesCount = files.length;
                for (let i = 0; i < filesCount; i++) {
                    if (!files[i].FileError) {
                        newdata.push(i);
                    }
                }
            }
            this._checkedFiles(newdata);
        });

        this._checkedFiles.subscribe(newValue => {
            const currentValue = this._allFilesCheck();
            const filesWithoutErrors = this._files().filter(file => !file.FileError);
            const filesCount = filesWithoutErrors ? filesWithoutErrors.length : 0;
            if (newValue.length === filesCount && !currentValue) {
                changinFromSubscription = true;
                this._allFilesCheck(true);
            } else if (newValue.length !== filesCount && currentValue) {
                changinFromSubscription = true;
                this._allFilesCheck(false);
            }
        });

        this._allSelectValue.subscribe(newValue => {
            if (newValue) {
                this._files(this._files().map(file => {
                    file.FType = newValue;
                    return Object.assign(new DropDataModel(), file);
                }))
            }
        });
    }

    private OnClose = () => {
        this._files([]);
        this._allFilesCheck(true);
        this._checkedFiles([]);
        this._originalFiles = [];
        this._uploadingFinished(false);
        this._uploadingStarted(false);
        this._showOkayButton(false);
        this._readyFiles = [];
    }
    
    public OnOkay (hideModal?:boolean): Promise<void>{
        return this.Upload(false, hideModal);
    }

    private Upload(validateSpreadsheet: boolean, hideModal?:boolean): Promise<void>{
        const files = this._files();

        const checkedFiles = this._checkedFiles() .map((fileIndex) => {
            return {
                dropDataModel: files[fileIndex],
                index: fileIndex,
                file: this._originalFiles[fileIndex]
            }
        });

        if(this._isConsultScreen && checkedFiles && _.any(checkedFiles) && _.first(checkedFiles).dropDataModel){
            const checkFile = _.first(checkedFiles);
            const getLimitAmountOfDocumentsModel = {
                EntityId: checkFile.dropDataModel.EntityId,
                DocumentEntityId: checkFile.dropDataModel.DocumentEntityId,
                ControlId: checkFile.dropDataModel.ControlId,
                EntityRecordId: checkFile.dropDataModel.EntityRecordId
            }

            this.CheckLimitAndSendDocuments(getLimitAmountOfDocumentsModel, checkedFiles, validateSpreadsheet);
        } else {
            return this.SendDocuments(checkedFiles, validateSpreadsheet, hideModal);
        }
    }

    public Open() {
        this._requiredXlsxFields([]);
        this._nonrequiredXlsxFields([])
        this._modal.Show();
    }

    public Close() {
        this._modal.Close();
        if (this._isConsultScreen && this._uploadingFinished()) {
            this.OnCloseCallBack();
        }
    }

    public AddFile(dropDataModel: DropDataModel, file: File) {

        if(this._files().length == 1 && this._hasDwPackage){
            new Notifier().Failed(this._labels.CANNOT_UPLOAD_MULTIPLE_FILES);
            return;
        }

        if(file.size == 0){
            dropDataModel.FileError = 'Error uploading file';
        }

        const entityTypes = this._entityTypes();
        if(entityTypes && entityTypes.length === 1){
            const singleTypeId = _.first(entityTypes).Id;
            dropDataModel.FType = singleTypeId;
        }

        this._originalFiles.push(file);
        this._checkedFiles.push(this._files().length);
        this._files.push(dropDataModel);
        this._allFilesCheck(true);

        if(this._hasDwPackage){
            this.Upload(true);
        }
    }

    private SendDocuments(files, validateSpreadsheet: boolean, hideModal?:boolean): Promise<void> {
        
        if (hideModal){
            BlockUI.Block();
        }
        this._uploadingStarted(true);
        return Promise.all(
            files.map(f=>this.SendDocument(f, validateSpreadsheet))

        ).then(result => {
            if (hideModal){
                BlockUI.Unblock();
            }
            if (!this._isConsultScreen) {
                const filesWithoutErrors = result.filter(file => file !== false);

                if (filesWithoutErrors.length === result.length) {
                    this.OnOkayCallBack(result);
                    this.Close();
                } else {
                    this._readyFiles = filesWithoutErrors as object[];
                    this._showOkayButton(true);
                }
            }
            if(validateSpreadsheet){
                this._uploadingStarted(false);
            }
            else{ 
                this._uploadingFinished(true);
            }

            if(this._destinationTypeId && this._destinationTypeId != 0 && !this._hasDwPackage){
                this.Open();
            }
        });        
    }

    private CheckLimitAndSendDocuments(model: GetLimitOfUploadedDocumentsRequestModel, files, validateSpreadsheet: boolean){
        DropDataStore.GetLimitOfUploadedDocuments(model)
            .fail(err=>{
                new Notifier().Failed(err.message);
            })
            .then(result => {
                if(result.IsLimitOfUploadedDocumentsSet && result.DocumentsQuantityLeftToLink == 0){
                    new Notifier().Failed(this._labels.MAXIMUM_AMOUNT_OF_LINKED_DOCUMENTS_REACHED);
                    return;
                }

                let documents = files;
                if(result.IsLimitOfUploadedDocumentsSet && files.length > result.DocumentsQuantityLeftToLink){
                    const warningMessage = this._labels.MAXIMUM_LINKED_DOCUMENTS_REACHED_ONLY_FILES_WERE_UPLOADED
                        .replace('{filesAmount}', `${result.DocumentsQuantityLeftToLink}`);
                    new Notifier().Warning(warningMessage);
                    documents = _.first(files, result.DocumentsQuantityLeftToLink)
                }

                this.SendDocuments(documents, validateSpreadsheet);
            });
    }

    private OnOkayWhenError = () => {
        this.OnOkayCallBack(this._readyFiles);
        this.Close();
    }

    private ChangeFile(index: number, key: string, value: any) {
        const files = this._files();
        files[index] = Object.assign(new DropDataModel(), files[index], {[key]: value});
        this._files([].concat(files));
    }

    private SendDocument = ({dropDataModel, file, index}, validateSpreadsheet: boolean) => {
        return new Promise(resolve => {
            const attachment = new DropFileModel();

            attachment.Name = TruncateFileName(dropDataModel.Name);
            attachment.Status = 0;
            attachment.Version = 1;
            attachment.Type = dropDataModel.Name.substr(dropDataModel.Name.lastIndexOf('.'));
            attachment.TypeId = this._destinationTypeId? this._destinationTypeId : dropDataModel.FType;

            //Append random number to file name to make it unique
            attachment.TempName = Guid.NewGuid();

            if(!validateSpreadsheet){
                dropDataModel.DropFileModels.push(attachment);
            }

            var maxFileSizeKb = 100;

            var fileChunks = [];

            var bufferChunkSizeInBytes = maxFileSizeKb * (1024);

            var currentStreamPosition = 0;
            var endPosition = bufferChunkSizeInBytes;
            var size = file.size;

            this.ChangeFile(index, "LoadingProgress", 10);

            while (currentStreamPosition < size) {
                fileChunks.push(file.slice(currentStreamPosition, endPosition));
                currentStreamPosition = endPosition;
                endPosition = currentStreamPosition + bufferChunkSizeInBytes;
            }

            if(fileChunks.length == 0){
                resolve(true);
                this.ChangeFile(index, "FileError", "Error uploading file");
                this.ChangeFile(index, "LoadingProgress", 0);
                return;
            }

            const OnError = (): void => {
                this.ChangeFile(index, "FileError", "Error uploading file");
                this.ChangeFile(index, "LoadingProgress", 0);
                resolve(false);
            };

            this.UploadFileChunk(fileChunks, attachment.TempName, 1, fileChunks.length).then(result => {
                    if (!result) {
                        OnError();
                    } else {
                        if (this._isConsultScreen) {

                            if(validateSpreadsheet){
                                DropDataStore
                                .ValidateSreadsheet({ TempName: attachment.TempName, ControlId: dropDataModel.ControlId })
                                .then((result)=>{
                                    
                                    this._nonrequiredXlsxFields(result.NonrequiredFields);
                                    this._requiredXlsxFields(result.RequiredFields);

                                    resolve(true);
                                    this.ChangeFile(index, "LoadingProgress", 100);
                                });
                            }else{
                                DropDataStore.InsertDropDocument(dropDataModel)
                                .fail(err=>{
                                    new Notifier().Failed(err.message);
                                     resolve(true);
                                    this.ChangeFile(index, "LoadingProgress", 100);
                                    this._files.splice(this._files.length - 1, 1);
                                })
                                .then(result => {
                                    if(!!result){
                                        this._affectedRecords(result);
                                    }
                                    
                                    resolve(true);
                                    this.ChangeFile(index, "LoadingProgress", 100);
                                });
                            }
                        } else {
                            resolve(attachment);
                        }
                    }
                }
            );
        });
    }

    private SaveDocument(){
        
    }

    private UploadFileChunk(fileChunks, fileName, currentPart, totalPart) {
        var self = this;
        return new Promise(resolve => {
            var uploadChunkModel = new UploadChunkModel();
            uploadChunkModel.FileName = fileName;
            uploadChunkModel.FileChunk = fileChunks[currentPart - 1];

            DropDataStore.UploadFileChunk(uploadChunkModel)
                .then(result => {
                    if (!result.IsSuccessfull) {
                        resolve(false);
                    } else {
                        if (totalPart >= currentPart) {
                            if (totalPart === currentPart) {
                                //Whole file uploaded
                                resolve(true);
                            } else {
                                //Show uploading progress
                                self.UploadFileChunk(fileChunks, fileName, currentPart + 1, totalPart).then(result => {
                                    resolve(result);
                                });
                            }
                        }
                    }
                });
        });
    }

    private OnSelectTableType = index => (data, event) => {
        this._allSelectValue(null);
        this.ChangeFile(index, "FType", Number(event.currentTarget.value));
    }

    public Destroy = () => {
        this._modal.Destroy();
    }

    GetTemplateName() {
        return 'Core/Controls/Drop/Templates/DropFilesModal';
    }
}