

import {P} from 'Core/Common/Promise';
import {Deserialize} from 'libs/cerialize';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {MenuAreaModel} from "MenuManager/MenuAreas/Models/MenuAreaModel";

export class MenuAreasStore {
    static Get(): P.Promise<Array<MenuAreaModel>> {

        var deferredResult = P.defer<Array<MenuAreaModel>>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: "/api/MenuApi/GetMenuAreas",
                dataType: 'json',
                type: 'GET'
            }
        };

        Request.Send(requestParams)
            .then(data => {
                if (data.IsSuccessfull) {
                    deferredResult.resolve(this.Deserialize(data));
                } else {
                    deferredResult.reject({ message: data.ErrorMessage });
                }
            });

        return deferredResult.promise();
    }

    static Deserialize(jsonObject: any): Array<MenuAreaModel> {
        var res = [];

        _.each(jsonObject.ResultList, (item) => {
            res.push(<MenuAreaModel>Deserialize(item, MenuAreaModel));
        });

        return res;
    }
}