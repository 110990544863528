import * as ko from 'knockout';
import * as _ from 'underscore';

import {BlockUI} from "Core/Common/BlockUi";
import {Notifier} from "Core/Common/Notifier";

import {LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";

import {EnvironmentsPageStore} from "../Store/EnvironmentsPageStore";
import {CreateEnvironmentModel} from 'Core/CyberConfigManager/Models/CreateEnvironmentModel';

import Template from "Core/CyberConfigManager/Templates/EnvironmentsPage.html";
import {
    ConfirmationDialog, EVENTS as CONFIRMATION_DIALOG_EVENTS,
    Types as ConfirmationTypes
} from "../../Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

ko.templates['Core/CyberConfigManager/Templates/EnvironmentsPage'] = Template;

export class EnvironmentsPage {
    private _store: EnvironmentsPageStore;
    private _environmentName: KnockoutObservable<string>;
    private _labels = LABELS;
    private _notifications = NOTIFICATIONS;
    private _isValid: KnockoutObservable<boolean>;
    private _validationMsg: KnockoutObservable<string>;

    constructor() {
        this._store = new EnvironmentsPageStore();
        this._environmentName = ko.observable(null);
        this._isValid = ko.observable(true);
        this._validationMsg = ko.observable(this._labels.THIS_FIELD_IS_REQUIRED);
    }

    GetTemplateName() {
        return 'Core/CyberConfigManager/Templates/EnvironmentsPage';
	}

	Save() {
        BlockUI.Block();
        let model = new CreateEnvironmentModel();
        model.Name = this._environmentName();

        this._store.CreateEnvironment(model)
            .always(() => {
                BlockUI.Unblock();
            })
            .then(() => {
                new Notifier().Success(NOTIFICATIONS.ENVIROMENT_WAS_CREATED.replace('{Environment}', this._environmentName()));
            })
            .fail(error => new Notifier().Failed(error.message));
    }

    Copy() {
        if(!this.IsValid()){
            return;
        }
        this.ShowConfirmationDialog();
    }

    IsValid(): boolean {
        if(!this._environmentName()) {
            this._isValid(false);
            this._validationMsg(this._labels.THIS_FIELD_IS_REQUIRED);
        }else {
            const specialChars = new RegExp('^[a-zA-Z_\\-0-9]+$');
            if(!specialChars.test(this._environmentName())) {
                this._isValid(false);
                this._validationMsg(this._labels.SPECIAL_CHARACTERS_NOT_ALLOWED);
            }else {
                this._isValid(true);
                this._validationMsg(this._labels.THIS_FIELD_IS_REQUIRED);
            }
        }

        return this._isValid();
    }

    OnKeyUpValidation(that, event): boolean {
        if(!this._environmentName()){
            this._isValid(false);
            this._validationMsg(this._labels.THIS_FIELD_IS_REQUIRED);
        }else {
            const specialChars = new RegExp('^[a-zA-Z_\\-0-9]+$');
            if(!specialChars.test(this._environmentName())) {
                this._isValid(false);
                this._validationMsg(this._labels.SPECIAL_CHARACTERS_NOT_ALLOWED);
            }else {
                this._isValid(true);
                this._validationMsg(this._labels.THIS_FIELD_IS_REQUIRED);
            }
        }

        return this._isValid();
    }

    private ShowConfirmationDialog() {
        const confirmationDialog = new ConfirmationDialog({
            Text: NOTIFICATIONS.CONFIRMATION_FOR_COPY_ENVIROMENT,
            Type: ConfirmationTypes.Question
        });

        confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
            this.Save();
        });
        confirmationDialog.Show();
    }

    AfterRender() {
        this.LoadImage();
    }

	private LoadImage() {
	}
}