export var COMPOSER_EVENTS = {
	EMAIL_SENT: 'EMAIL_SENT',
	DISCARD: 'DISCARD',
	EMAIL_WAS_READ: 'EMAIL_WAS_READ'
};

export var ADD_EMAIL_EVENTS = {
	EMAIL_SAVED: 'EMAIL_SAVED'
};

export var MAIL_LIST_EVENTS = {
	LOAD_DATA: 'LOAD_DATA',
	SAVE_TO_CYBER_BOX: 'SAVE_TO_CYBER_BOX'
};