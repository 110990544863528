//Units
import { RecipeEditorTreeUnit } from "Core/Controls/RecipeEditor/RecipeEditorTree/RecipeEditorTreeUnit";

import {ITypePropertyModel} from "Core/GeneralProperties/Managers/TypesProperty/TypesProperty";

export class RecipeEditorTree {
    private static _instance: RecipeEditorTree;
    private static _initialized: boolean;

    private _entityToUnit: { [enityId: number]: RecipeEditorTreeUnit };

    constructor() {
        if (RecipeEditorTree._initialized) {
            throw new Error("Path runner already initialized");
        }

        RecipeEditorTree._initialized = true;
        this._entityToUnit = {};
    }

    static get Instance() {
        if (RecipeEditorTree._instance) {
            return RecipeEditorTree._instance;
        }

        RecipeEditorTree._instance = new RecipeEditorTree();

        return RecipeEditorTree._instance;
    }

    GetUnit(entityId: number, controlId: number, typesProperty: ITypePropertyModel[]) {
        let unit = this._entityToUnit[entityId];

        if (!unit) {
            unit = new RecipeEditorTreeUnit(entityId, controlId, typesProperty);
            this._entityToUnit[entityId] = unit;
        }

        return unit;
    }
}