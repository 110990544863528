import * as ko from "knockout";
import {FormatConverter} from "FormatEditor/FormatConverter";
import { EventBusConsumer } from "../../../../../../Common/EventBus/EventBusConsumer";
import { LABELS } from "Core/Components/Translation/Locales";

export abstract class PriceItem extends EventBusConsumer  {
    private _labels = LABELS;

    Price: KnockoutObservable<number>;
    ComparisonPrice: KnockoutObservable<number>;

    PriceLabel: KnockoutComputed<string>;
    ComparisonPriceLabel: KnockoutComputed<string>;

    AlternativePrice: KnockoutObservable<number>;
    ComparisonAlternativePrice: KnockoutObservable<number>;

    AlternativePriceLabel: KnockoutComputed<string>;
    ComparisonAlternativePriceLabel: KnockoutComputed<string>;

    _compare: KnockoutObservable<boolean>;
    _inactive: KnockoutComputed<boolean>;
    _priceMiscalculated: KnockoutObservable<boolean>;

    protected constructor(public Id: number, public Name: string, public NameTranslated: string, price: number) {
        super();
        this.Price = ko.observable(price);
        this.ComparisonPrice = ko.observable(null);
        this._compare = ko.observable(false);

        this.AlternativePrice = ko.observable(null);
        this.ComparisonAlternativePrice = ko.observable(null);

        this._inactive = ko.computed(() => this.Price() == null);
        this._priceMiscalculated = ko.observable(false);

        this.PriceLabel = ko.computed(() => {
            if (this.Price() == null){
                return '-';
            }
            const decimalValue = FormatConverter.LocalizeDecimalOrInteger(Math.abs(this.Price()).toFixed(2));
            return `€ ${this.Price() < 0 ? '-' : ''}${decimalValue}`;
        });
        this.ComparisonPriceLabel = ko.computed(() => {
            if (this.ComparisonPrice() == null) {
                return '-';
            }
            const decimalValue = FormatConverter.LocalizeDecimalOrInteger(Math.abs(this.ComparisonPrice()).toFixed(2));
            return `€ ${this.Price() < 0 ? '-' : ''}${decimalValue}`;
        });
        this.AlternativePriceLabel = ko.computed(() => {
            if (this.AlternativePrice() == null){
                return '* -';
            }
            const decimalValue = FormatConverter.LocalizeDecimalOrInteger(Math.abs(this.AlternativePrice()).toFixed(2));
            return `* € ${this.AlternativePrice() < 0 ? '-' : ''}${decimalValue}`;
        });
        this.ComparisonAlternativePriceLabel = ko.computed(() => {
            if (this.ComparisonAlternativePrice() == null) {
                return '* -';
            }
            const decimalValue = FormatConverter.LocalizeDecimalOrInteger(Math.abs(this.ComparisonAlternativePrice()).toFixed(2));
            return `* € ${this.ComparisonAlternativePrice() < 0 ? '-' : ''}${decimalValue}`;
        });
    }
}