import {deserialize, deserializeAs} from "libs/cerialize";

export class ItemModel {
	@deserialize
	RecordId: number;

	@deserialize
	Label: string;
}

export class RadioButtonOptionsModel {
	@deserializeAs(ItemModel)
	Items: Array<ItemModel>;

	@deserialize
	RecordsCount: number;

	constructor() {
		this.Items = [];
	}
}
