import {deserialize, deserializeAs, serialize, serializeAs} from "libs/cerialize";

export class RightsModel {
	@deserialize
	IsDeletingAllowed: boolean;

	@deserialize
	IsEditingAllowed: boolean;

	@deserialize
	ChangeSecurityAllowance: boolean;

	@deserialize
	IsChangingSecurityAllowed: boolean;

	@deserialize
	IsKanbanBoardExist: boolean;

	@deserialize
	IsAddingRecordAllowed: boolean;

	@deserialize
	IsRecordSecurityOk: boolean;
}