import * as _ from 'underscore';

import {CachedProduct} from './CachedProduct';

export class CachedProducts {
    private _products: CachedProduct[];

    constructor() {
        this._products = [];
    }

    Find(criteria: (product: CachedProduct) => boolean) {
        return _.find(this._products, criteria);
    }

    FindById(id: number) {
        return _.find(this._products, cp => cp.Id === id);
    }

    Add(product: CachedProduct) {
        if (!this.Exist(product)) {
            this._products.push(product);
        }
    }

    AddRange(products: CachedProduct[]) {
        products.forEach(product => this.Add(product));
    }

    Exist(product: CachedProduct): boolean {
        return _.any(this._products, cp => cp.Equals(product));
    }
}