import {deserialize, deserializeAs} from 'libs/cerialize';
export class DropFileModel {
	@deserialize
	RecordId: number;

	@deserialize
	EntityId: number;

	@deserialize
	EntityName: string;

	@deserialize
    Name: string;

    @deserialize
    TempName: string;

	@deserialize
	Type: string;

	@deserialize
	Version: number;

	@deserialize
	Status: number;

	@deserialize
	Base64Data: string;

	TypeId: number;
}