import {Event} from 'Core/Common/Event';

import {Intentions} from "./Constants/Intentions";

import {IParams} from "./IParams";

import {FillLookupPopup} from "./Popup/FillLookup/FillLookupPopup";
import {MakeBackLinkPopup} from "./Popup/MakeBackLink/MakeBackLinkPopup";

export class BackLinking extends Event {
    constructor(private _params: IParams) {
        super();
    }

    ShowPopup() {
        switch (this._params.Intention) {
            case Intentions.FillLookup:
                this.ShowFillLookupPopup();
                break;

            case Intentions.MakeBackLink:
                this.ShowMakeBackLinkPopup();
                break;
        }
    }

    private ShowFillLookupPopup() {
        new FillLookupPopup(this._params).Show();
    }

    private ShowMakeBackLinkPopup() {
        const popup = new MakeBackLinkPopup(this._params);
        popup.On('CHANGES_SAVED', this, () => this.Trigger('CHANGES_SAVED'));
        popup.Show();
    }
}
