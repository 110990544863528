import * as ko from "knockout";

import {JBoxDropDown} from 'Core/Components/JBoxDropdown/DropDown';
import {BaseGrid} from "Core/Controls/Grid/BaseGrid/BaseGrid";
import {EVENTS as GRID_EVENTS} from "Core/Controls/Grid/BaseGrid/Events";
import {Event} from "Core/Common/Event";
import {EVENTS} from "Core/Controls/Grid/BaseGrid/QueryResultPage/Events";
import {GroupDetailModel} from "Core/Controls/Grid/Models/GroupDetailModel";
import {LABELS} from "Core/Components/Translation/Locales";
import { ZIndexManager } from "Core/Common/ZIndexManager";

import Template from 'Core/Controls/Grid/BaseGrid/GroupDetailsDropdown/Templates/GroupDetailsDropdown.html';

ko.templates['Core/Controls/Grid/BaseGrid/GroupDetailsDropdown/Templates/GroupDetailsDropdown'] = Template;

export class GroupDetailsDropdown extends Event {
    private readonly _id: string;
    readonly _grid: KnockoutObservable<BaseGrid>;
    private _dropDown: JBoxDropDown;
    _labels = LABELS;

    constructor() {
        super();
        this._id = JBoxDropDown.GetDropDownId();
        this._grid = ko.observable(null);
        this.AddEvent(EVENTS.RECORD_SELECTED);
    }

    Close() {
        if (this._dropDown) {
            this._dropDown.Close();
        }
    }

    SetData(groupDetails: GroupDetailModel) {
        const grid = new BaseGrid({
            isPrioritiesDisabled: true,
            isQueryResultGrid: true,
            isEditable: ko.observable(false),
            screenType: null,
            enabledFavorite: false,
            isEnableSelectRecord: ko.observable(false),
            enableSelectRowCell: true
        });

        grid.On(GRID_EVENTS.SELECT_RECORD, this,(eventArgs: any) => {
            this.Trigger(EVENTS.RECORD_SELECTED, { SelectedRecord: eventArgs.data.Row });
        });

        this._grid(grid);

        grid.SetData(groupDetails.Data, undefined, true);
    }

    Show(target: HTMLElement = undefined) {
        this._dropDown = new JBoxDropDown({
            target: target || "." + this._id,
            otherOptions: {
                attach: undefined,
                closeOnClick: 'body',
                position: {
                    x: "left",
                    y: "bottom"
                },
                onCloseComplete: () => this._dropDown.Destroy(),
                zIndex: ZIndexManager.Instance.NextValue
            },
            onOpen: () => {
                this._dropDown.SetContent({ content: Template as any });
            },
            bindComponent: this,
        });

        this._dropDown.Open();
    }
}