import * as ko from 'knockout';

import { SortOrder } from 'QueryBuilder/Enums';
import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { QuerySortModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QuerySortModel';

import SortItemTemplate from 'QueryBuilder/QuerySorting/SortItem/Templates/SortItem.html';
import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

ko.templates['QueryBuilder/QuerySorting/SortItem/Templates/SortItem'] = SortItemTemplate;

export class SortItem {
	private _sortOrder: KnockoutObservable<SortOrder>;
	private _model: QuerySortModel;

	constructor(model: QuerySortModel, private _queryEntityModel: QueryEntityModel) {
		this._sortOrder = ko.observable(model.SortOrder);
		this._model = model;
	}

	static GetTemplateName() {
		return 'QueryBuilder/QuerySorting/SortItem/Templates/SortItem';
	}

	@enumerable get Title(): string {
		return `${this._queryEntityModel.Metadata.NameTranslation || this._queryEntityModel.Metadata.Name}.${this._model.Column.Metadata.NameTranslation || this._model.Column.Metadata.Name}`;
	}

	@enumerable get SortOrder(): KnockoutObservable<SortOrder> {
		return this._sortOrder;
	}

	@enumerable get Model(): QuerySortModel {
		return this._model;
	}

	ChangeOrderDirection() {
		if (this._sortOrder() === SortOrder.Ascending) {
			this._sortOrder(SortOrder.Descending);
			this._model.SortOrder = SortOrder.Descending;
		} else {
			this._sortOrder(SortOrder.Ascending);
			this._model.SortOrder = SortOrder.Ascending;
		}
	}
}