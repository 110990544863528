import {SpreadsheetFieldDescriptor} from './SpreadsheetFieldDescriptor';
import {SpreadsheetDimensionDescriptor} from './SpreadsheetDimensionDescriptor';
import {SpreadsheetCellPosition} from './SpreadsheetCellPosition';

export class SpreadsheetDescriptor {
    constructor(public ValueField: SpreadsheetFieldDescriptor,
                public PageField: SpreadsheetFieldDescriptor,
                public Origin: SpreadsheetCellPosition,
                public Dimensions: SpreadsheetDimensionDescriptor[],
                public IsView: boolean) {
    }

    get IsPaged() {
        return !!this.PageField;
    }
}