import {FIELD_TYPES} from "Core/Constant";

//Store models
import { DisapprovedReportResponseModel } from "Core/Components/Controls/TimeWriting/Models/Store/Disapproved/Response/DisapprovedReportResponseModel";
import { SaveReservationRequestModel } from "Core/Components/Controls/TimeWriting/Models/Store/Day/Request/SaveReservationRequestModel";
import { DeleteReservationRequestModel } from "Core/Components/Controls/TimeWriting/Models/Store/Day/Request/DeleteReservationRequestModel";

//View models
import { Reservation } from "Core/Components/Controls/TimeWriting/Models/View/DayDataViewModel";
import { DisapprovedDataViewModel } from "Core/Components/Controls/TimeWriting/Models/View/DisapprovedDataViewModel"

//Mappings
import {DisapprovedRequestedDateMappings } from "Core/Components/Controls/TimeWriting/Mappings/Disapproved/DisapprovedRequestedDateMappings";
import { EntityMappings } from "Core/Components/Controls/TimeWriting/Mappings/Day/EntityMappings";
import { ReservationMappings } from "Core/Components/Controls/TimeWriting/Mappings/Day/ReservationMappings";
import {LookupFieldMappings} from "../Common/LookupFieldMappings";

export class DisapprovedMappingProfile {
    static OnViewModel(model: DisapprovedReportResponseModel): DisapprovedDataViewModel {
        const viewModel = new DisapprovedDataViewModel();
        viewModel.RequestedDate = DisapprovedRequestedDateMappings.OnViewModel(model.DayReservations);
        viewModel.AllowedEntities = model.GlobalSettings.AllowedEntities.map(entity => EntityMappings.OnViewModel(entity));
        viewModel.LookupFields = model.Fields.filter(field => field.TypeName === FIELD_TYPES.Lookup ).map(field => LookupFieldMappings.OnViewModel(field));

        return viewModel;
    }

    static OnSaveModel(model: Reservation): SaveReservationRequestModel {
        return ReservationMappings.OnSaveModel(model);
    }

    static OnDeleteModel(model: Reservation): DeleteReservationRequestModel {
        return ReservationMappings.OnDeleteModel(model);
    }
}