import * as _ from 'underscore';

import {EntityControlEditor} from "./EntityControlEditor";

import {IControl} from "Core/Controls/IControl";

import {EntityModel} from "../Models/EntityModel";

export class BasketControlEditor extends EntityControlEditor {
    constructor(control: IControl) {
        super(control);
    }

    protected PopulateEntities(entities: EntityModel[]) {
        entities = _.uniq(entities, false, entity => entity.EntityId);

        this.Entities(entities);

        const attachedField = _.first(this.EditControlModel.Fields);
        const attachedEntity = attachedField && _.find(entities, entity => entity.EntityId === attachedField.EntityId);

        if (attachedEntity) {
            this.SelectedEntity(attachedEntity);
        } else {
            const subjectEntity = _.find(entities, entity => entity.EntityId === this.SubjectEntityId);
            this.SelectedEntity(subjectEntity);
        }
    }
}