import * as ko from 'knockout';
import $ from "jquery";

import {GenericDeserialize, Serialize} from 'libs/cerialize';

import {Event} from 'Core/Common/Event';
import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';

import {LABELS} from 'Core/Components/Translation/Locales';
import {QueryParamsPage} from 'QueryBuilder/QueryParamsPage/QueryParamsPage';
import {EVENTS as QUERY_PARAMS_PAGE_EVENTS} from 'QueryBuilder/QueryParamsPage/Events';

import {ViewModes} from 'Core/Controls/Grid/BaseGrid/Enums/ViewModes';
import {QueryResultPage} from 'Core/Controls/Grid/BaseGrid/QueryResultPage/QueryResultPage';
import {QueryExpressionModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import {JBoxDropDown} from "../JBoxDropdown/DropDown";
import {MobileChecker} from "../../Common/MobileChecker";

import {MenuQueryMetadata} from './Models/MenuQueryMetadata';
import {MenuQueriesStore} from './Stores/MenuQueriesStore';

import Template from './Templates/MenuQueries.html';

export class MenuQueries extends Event {
    private LABELS: any;
    private _renderQueryTargetId: string;
    private _store: MenuQueriesStore;

    private _dataLoaded: KnockoutObservable<boolean>;
    private _menuQueries: KnockoutObservableArray<MenuQueryMetadata>;
    private _dropDown: any;
    private _targetElem: HTMLElement;
    private _isMobile: KnockoutObservable<boolean>;

    constructor(private _entityId: number, target) {
        super();

        this.LABELS = LABELS;
        this._renderQueryTargetId = 'viewBody';
        this._store = new MenuQueriesStore(this._entityId);

        this._dataLoaded = ko.observable(false);
        this._menuQueries = ko.observableArray([]);
        this._targetElem = target;
        this._isMobile = ko.observable(MobileChecker.IsMobile());

        this.Init();
    }

    get EntityId() {
        return this._entityId;
    }

    GetTemplate() {
        return Template;
    }

    AfterRender(el: HTMLElement){

    }

    InitMenuQueriesDropdown(el) {
        let listNavItem = el.closest('.recent-and-favorites-attach-to');

        this._dropDown = new JBoxDropDown({
            target: el,
            bindTarget: el,
            bindComponent: this,
            otherOptions: {
                addClass: "queries-dropdown",
                closeOnClick: 'body',
                attach: undefined,
                target: $(listNavItem),
                adjustPosition: true,
                adjustTracker: true,
                isolateScroll: true,
                pointer: "top",
                maxWidth: 200,
                minHeight: 50,
                onCloseComplete: () => {
                    if (this._dropDown){
                        this._dropDown.Destroy();
                        this._dropDown = null;

                        if ($('.page-sidebar-menu').hasClass("page-sidebar-menu-closed")) {
                            const newTarget = $(listNavItem).closest(".subject-area").length ? $(listNavItem).closest(".subject-area") : listNavItem;
                            $(newTarget).removeClass("recent-and-favorites-active");
                        }
                    }
                },
                zIndex: 1000,
                position: {
                    x: "left",
                    y: "center"
                },
                outside: 'x'
            },

            onOpen: () => {}
        });

        this._dropDown.Open();
    }

    Init() {

        if (!this._isMobile()) {
            this.InitMenuQueriesDropdown(this._targetElem);
        }
        let elem = $(".queries-dropdown .jBox-container");
        BlockUI.Block({Target: elem.get(0)});

        this._store.GetMenuQueriesMetadata()
            .then(menuQueriesMetadata => {
                this._menuQueries(menuQueriesMetadata);
                if (!this._isMobile()) {
                    if (this._dropDown) {
                        this._dropDown.SetContent({ content: this.GetTemplate() as any});
                    }
                }
            })
            .fail(err => new Notifier().Failed(err.message))
            .always(() => {
                this._dataLoaded(true);
                BlockUI.Unblock(elem.get(0));
            });
    }

    DestroyMenuQueriesDropdown() {
        this._dropDown.Destroy();
    }

    Select(queryMetadata: MenuQueryMetadata) {
        const queryModel = GenericDeserialize<QueryExpressionModel>(JSON.parse(queryMetadata.Query), QueryExpressionModel);
        const paramsPage = new QueryParamsPage(queryModel, ViewModes[ViewModes.Query]);

        if (paramsPage.HasShowParameters()) {
            paramsPage.On(QUERY_PARAMS_PAGE_EVENTS.SAVE, this, () => {
                this.Show(queryModel, queryModel.QueryScreenId);
            });

            paramsPage.Show();
        } else {
            this.Show(queryModel, queryModel.QueryScreenId);
        }

        this.Trigger('QUERY_SELECTED');
    }

    private Show(query: QueryExpressionModel, queryScreen?: number) {
        if (queryScreen > 0) {
            this.ShowQueryScreen(query, queryScreen);
        } else {
            this.ShowStaticQueryScreen(query);
        }
    }

    private async ShowQueryScreen(query: QueryExpressionModel, queryScreenId: number) {
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
        BlockUI.Block();
        screenManager.GetScreenById({ScreenId: queryScreenId, RaiseNotImplemented: false})
            .always(() => {
                BlockUI.Unblock();
            })
            .then((screen: any) => {
                screen.SetQuery(query);
                screen.ShowInModal();
            })
            .fail((err) => {
                new Notifier().Failed(err.message);
            });
    }

    private ShowStaticQueryScreen(query: QueryExpressionModel) {
        const queryResultPage = new QueryResultPage(true, ScreenTypes.ListScreen, true, false);
        queryResultPage.ShowQueryResult(query);
    }
}