//Store models
import { SubjectResponseModel } from "Core/Components/Controls/TimeWriting/Models/Store/Day/Response/SubjectResponseModel";
import { SaveSubjectRequestModel } from "Core/Components/Controls/TimeWriting/Models/Store/Day/Request/SaveReservationRequestModel";

//View models
import { Subject } from "Core/Components/Controls/TimeWriting/Models/View/DayDataViewModel";

//UI
import { Icon } from "Core/Icon/Icon";
import {IconModel} from "Core/Controls/BaseControl/Models/IconModel";
import {FONT_NAME} from "Core/Constant";

export class SubjectMappings {
	static OnViewModel(model: SubjectResponseModel): Subject {
		const viewModel = new Subject();

		viewModel.EntityId = model.EntityId;
		viewModel.EntityName = model.EntityName;
		viewModel.SubjectId = model.SubjectId;
		viewModel.SubjectName = model.SubjectName;
		viewModel.Icon = this.SelectIcon(model.Icon);
		viewModel.PathToRoot = model.PathToRoot;

		return viewModel;
	}

	static OnSaveModel(model: Subject): SaveSubjectRequestModel {
		const requestModel = new SaveSubjectRequestModel();

		requestModel.EntityId = model.EntityId;
		requestModel.SubjectId = model.SubjectId;

		return requestModel;
	}

	private static SelectIcon(model: IconModel): Icon {
		if (model && model.Id > 0) {
			return new Icon(model);
		}

		const defaultIconModel = new IconModel();

		defaultIconModel.Name = 'fa-file';
		defaultIconModel.FontName = FONT_NAME.FONT_AWESOME;
		defaultIconModel.IsIcon = true;
		defaultIconModel.IsImage = false;

		return new Icon(defaultIconModel);
	}
}