import * as ko from "knockout"
import 'jquery';
import 'freeze-table-private';

export class FreezeTableExtention {
	static Init() {
		ko.bindingHandlers.freezeTable = {
			init: (element, valueAccessor, allBindings, viewModel) => {
				($(element) as any).freezeTable(ko.unwrap(valueAccessor()));
			}
		};
	}
}