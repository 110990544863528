import * as ko from 'knockout';
import * as _ from 'underscore';

import { Event } from "Core/Common/Event";

import { Modal } from "Core/Common/Modal";

import { SecurityEditorModel, User } from "Core/Components/SecurityEditor/Models/SecurityEditorModel";
import { SecurityEditorFormModel, User as UserFormModel } from "Core/Components/SecurityEditor/Form/Models/SecurityEditorFormModel";

import SecurityEditorFormTemplate from 'Core/Components/SecurityEditor/Form/Templates/SecurityEditorForm.html';
import { LABELS } from "Core/Components/Translation/Locales";
ko.templates['Core/Components/SecurityEditor/Form/Templates/SecurityEditorForm'] = SecurityEditorFormTemplate;



export const EVENTS = {
    ADDING_NEW_USER: 'AddingNewUser',
    SAVING_USERS: 'SavingUsers',
    SELECT_OWNER: 'SelectOwner'
};

export class SecurityEditorForm extends Event {
    private _model: SecurityEditorFormModel;
    private _readonly: boolean;
    private _modal: Modal;
    private _labels = LABELS;

    constructor(model: SecurityEditorFormModel, readOnly: boolean) {
        super();

        this._model = model;
        this._readonly = readOnly;

        this.Init();
    }

    Show() {
        this._modal.Show();
    }

    Close() {
        this._modal.Close();
    }

    GetTemplateName() {
        return 'Core/Components/SecurityEditor/Form/Templates/SecurityEditorForm';
    }

    AfterRender(el) {

    }

    OnRemove(user: UserFormModel) {
        user.Deleted(true);
    }

    OnAdd() {
        this.Trigger(EVENTS.ADDING_NEW_USER);
    }

    OnSave() {
        const model = new SecurityEditorModel();
        model.Users = this._model.Users().map(user => {
            const securityEditorUser = new User();

            securityEditorUser.Id = user.Id;
			securityEditorUser.Name = user.Name;
			securityEditorUser.SecurityData = user.SecurityData;
            securityEditorUser.Rights = user.ActiveRights().Value;
            securityEditorUser.OptionalRights = user.ActiveOptionalRights().Value;
            securityEditorUser.Deleted = user.Deleted();

            return securityEditorUser;
        });

        const owner = new User();

        owner.Name = this._model.Owner().Name;
		owner.Id = this._model.Owner().Id;
		owner.SecurityData = this._model.Owner().SecurityData;
        model.Owner = owner;

        model.SecurityPass.PassOwner = this._model.SecurityPass.PassOwner();
        model.SecurityPass.DoNotPass = this._model.SecurityPass.DoNotPassRights;
        model.SecurityPass.PassRights = this._model.SecurityPass.PassRights;
        model.SecurityPass.PassOptionalRights = this._model.SecurityPass.PassOptionalRights;
        model.SecurityPass.PassInheritance = this._model.SecurityPass.PassInheritance();
        model.SecurityPass.RefusePass = this._model.SecurityPass.RefusePass();

        this.Trigger(EVENTS.SAVING_USERS, model);
        this.Close();
    }

    OnCancel() {
        this.Close();
    }

    AddUser(user: UserFormModel) {
        const existingUser = _.find<UserFormModel>(this._model.Users(), u => u.Id === user.Id);

        if (existingUser) {
            existingUser.Deleted(false);
        } else {
            this._model.Users.push(user);
        }
    }

    private Init() {
        this.InitModal();
        this.AddEvent(EVENTS.ADDING_NEW_USER);
        this.AddEvent(EVENTS.SAVING_USERS);
        this.AddEvent(EVENTS.SELECT_OWNER);
    }

    private InitModal() {
        this._modal = new Modal({
            width: '700',
            minHeight: '300'
        }, false);

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
    }

    private SelectOwner() {
        this.Trigger(EVENTS.SELECT_OWNER);
    }
}