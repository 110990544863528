import {deserialize, deserializeAs, serialize, serializeAs} from "libs/cerialize";
import { ReferenceModel } from "./ReferenceModel";
import { RightsModel } from "./RightsModel";
import { TranslationModel } from "./TranslationModel";


export class ControlDataModel {
	constructor(init?: Partial<ControlDataModel>) {
		this.ControlId = 0;
		this.FieldName = null;
		this.FieldId = null;
		this.Value = '';
		this.Translations = [];
		this.ValFieldTypeName = null;
		this.LabelOriginalValue = '';
		Object.assign(this, init);
	}

	@deserialize
	ControlId: number;

    @deserialize
    RecordId: number;

	@deserialize
	Value: string | any;

	@deserialize
	LabelOriginalValue: string | any;

	@deserialize
	Values: any;

	@deserialize
	FieldId: number;

	@deserialize
	DisplayValue: string;

	@deserializeAs(TranslationModel)
	Translations: Array<TranslationModel>;

	@deserializeAs(ReferenceModel)
	Reference: ReferenceModel;

	Rights: RightsModel;

	FieldName: string;
	
	EntityName: string;

	@deserialize
	ValFieldTypeName: string;
}