import {Event} from "Core/Common/Event";

import {LABELS} from "Core/Components/Translation/Locales";

import {ILedgerParams} from "./Params/ILedgerParams";

import {FinancialDashboardMappingProfile} from "Core/Components/Dashboards/FinancialDashboard/Mappings/FinancialDashboardMappings";

import {LedgerViewModel} from "Core/Components/Dashboards/FinancialDashboard/Models/View/LedgerViewModel";
import {SubAdministrationViewModel} from "Core/Components/Dashboards/FinancialDashboard/Models/View/SubAdministrationViewModel";

import Template
    from 'Core/Components/Dashboards/FinancialDashboard/Components/Ledger/Templates/Template.html';

export class Ledger extends Event {
    private _labels = LABELS;

    Ledger: LedgerViewModel;

    constructor(params: ILedgerParams) {
        super();

        this.Init(params);
    }

    GetTemplate() {
        return Template;
    }

    AccountsBtnClicked() {
    }

    SubAdminBtnClicked(subAdministration: SubAdministrationViewModel) {
    }

    private Init(params: ILedgerParams) {
        this.Ledger = FinancialDashboardMappingProfile.MapToLedgerViewModel(params.Ledger, params.JournalsState, params.AccountsState);
    }
}