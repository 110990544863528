export const EVENTS = {
	NEW_TABLE_VIEW: 'NEW_TABLE_VIEW',
	EDIT_TABLE_VIEW: 'EDIT_TABLE_VIEW',
	COPY_TABLE_VIEW: 'COPY_TABLE_VIEW',
	DELETE_TABLE_VIEW: 'DELETE_TABLE_VIEW',
	LOCK_TABLE_VIEW: 'LOCK_TABLE_VIEW',
	UNLOCK_TABLE_VIEW: 'UNLOCK_TABLE_VIEW',
	LINK_RECORD: 'LINK_RECORD',
	UNLINK_MULTIPLE_RECORDS: 'UNLINK_MULTIPLE_RECORDS',
	CONFIRM_MULTIPLE_UNLINK: 'CONFIRM_MULTIPLE_UNLINK',
	CANCEL_MULTIPLE_UNLINK: 'CANCEL_MULTIPLE_UNLINK',
	LINK_PARENT_RECORD: 'LINK_PARENT_RECORD',
	COPY_LATEST_RECORD: 'COPY_LATEST_RECORD',
	ADD_QUERY: 'ADD_QUERY',
	ADD_AND_LINK_RECORD: 'ADD_AND_LINK_RECORD',
	SCAN_AND_LINK_RECORD: 'SCAN_AND_LINK_RECORD',
	SELECT_TABLE_VIEW: 'SELECT_TABLE_VIEW',
	SEARCH_BY_PHRASE: 'SEARCH_BY_PHRASE',
	NEW_RECORD: 'NEW_RECORD',
	EXPORT_DATA: 'EXPORT_DATA',
	TOGGLE_PRIORITIES: 'TOGGLE_PRIORITIES',
	BULK_EDIT: 'BULK_EDIT',
	RESET_FILTER: 'RESET_FILTER',
	TOGGLE_RETIRED: 'TOGGLE_RETIRED',
	EDIT_ALL: 'EDIT_ALL',
	SAVE_ALL: 'SAVE_ALL'
};