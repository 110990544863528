export class ConfigStructModel {
    Groups: Array<GroupStructModel>;

    constructor() {
        this.Groups = [];
    }

    static Deserialize(jsonConfig: any) {
        return <ConfigStructModel>jsonConfig;
    }
}

export class GroupStructModel {
    Name: string;
    Screens: string[];
    ParentControls: string[];
    Properties: Array<PropertyStructModel>;

    constructor() {
    	this.Screens = [];
        this.Properties = [];
    }
}


export class PropertyStructModel {
    Name: string;
	Type: string;
	ValueRequired: boolean;
    PropertyManager: string;
	DefaultValue: any;
	Options: Array<IPropertyOption>;
    Source: ISource;
    Params: any;

	constructor() {
		this.Options = [];
	}
}

interface IPropertyOption {
	Name: string;
	Value: any;
}

interface ISource {
    TableName: string;
    Value: string;
    DisplayValue: string;
}