import {RemoteDatabaseConnectionDto} from '../Stores/Models/RemoteDatabaseConnectionDto';
import {RemoteDatabaseConnection} from '../Models/RemoteDatabaseConnection';

export class RemoteDatabaseConnectionMappings {
    MapToRemoteDatabaseConnections(dtos: RemoteDatabaseConnectionDto[]): RemoteDatabaseConnection[] {
        return dtos.map(dto => this.MapToRemoteDatabaseConnection(dto));
    }

    MapToRemoteDatabaseConnection(dto: RemoteDatabaseConnectionDto): RemoteDatabaseConnection {
        return new RemoteDatabaseConnection(dto.Id, dto.Name, dto.Url, dto.RemoteUserName, dto.Connected);
    }
}