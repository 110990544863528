import * as ko from "knockout";

import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

import {TagModel} from "Core/Controls/Tag/Models/TagsScreenDataModel";

export class ChangeModel {
	private _addedList: TagModel[];
	private _deletedList: TagModel[];
	private _selectedTags: KnockoutObservableArray<TagModel>;

	constructor() {
		this._addedList = [];
		this._deletedList = [];
		this._selectedTags = ko.observableArray([]);
	}

	SelectTag(tag: TagModel): void {
		this._addedList.push(tag);
		this._selectedTags.push(tag);

		this._deletedList.forEach((el, index) => {
			if (el.TagId === tag.TagId) {
				this._deletedList.splice(index, 1);
			}
		});
	}

	RemoveTag(tag: TagModel): void {
		this._deletedList.push(tag);

		this._addedList.forEach((el, index) => {
			if (el.TagId === tag.TagId) {
				this._addedList.splice(index, 1);
			}
		});
	}

	@enumerable get AddedList() {
		return this._addedList;
	}

	@enumerable get DeletedList() {
		return this._deletedList;
	}

	@enumerable get SelectedTags() {
		return this._selectedTags();
	}

	set SelectedTags(data) {
		this._selectedTags(data);
	}
}