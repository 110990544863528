import * as _ from 'underscore';

import {Guid} from 'Core/Common/Guid';

import {FormatConverter} from 'FormatEditor/FormatConverter';
import {ColorConverter} from 'Core/Components/ColorSelector/ColorConverter';

import {TaskDto} from '../Stores/Models/TaskDto';
import {AssignmentDto} from '../Stores/Models/AssignmentDto';
import {ResourceDto} from '../Stores/Models/ResourceDto';

import {Assignment, Resource, ResourceHolder, Role, Task} from 'ganttChart';
import {DependencyDto} from '../Stores/Models/DependencyDto';
import {ResourceHolderDto} from '../Stores/Models/ResourceHolderDto';
import { RoleDto } from '../Stores/Models/RoleDto';

export class OnViewModelMappings {
    AsTasks(dtos: TaskDto[]): Task<number>[] {
        const tasks = dtos.map(dto => this.AsTask(dto));
        this.ResolveDependencies(tasks, dtos);

        return tasks;
    }

    AsTask(dto: TaskDto): Task<number> {
        const task = new Task<number>();
        task.parentEntity = dto.Parent?.Entity;
        task.childEntity = dto.ChildEntity;
        task.parentRecordId = dto.Parent?.Id;
        task.entity = dto.Entity;
        task.id = dto.Id;
        task.name = dto.Name;
        task.level = dto.Level;
        task.start = this.AsMilliseconds(dto.Starting);
        task.end = this.AsMilliseconds(dto.Ending);
        task.progress = dto.Completeness * 100;
        task.status = task.progress === 100 ? 'STATUS_DONE' : 'STATUS_ACTIVE';
        task.assigs = this.AsAssignees(dto.Assignments);
        task.color = ColorConverter.ToHex(dto.BarColor);
        task.startIsMilestone = false;
        task.endIsMilestone = false;
        task.duration = dto.Duration;
        task.startIsMilestone = dto.StartIsMilestone;
        task.endIsMilestone = dto.EndIsMilestone;

        return task;
    }

    AsAssignees(dtos: AssignmentDto[]): Assignment<Guid, number, number>[] {
        return dtos.map(dto => this.AsAssignee(dto));
    }

    AsAssignee(dto: AssignmentDto): Assignment<Guid, number, number> {
        return {
            id: Guid.NewGuid(),
            entity: dto.Entity,
            resourceId: dto.Id,
            roleId: dto.RoleId,
            effort: dto.Effort * 60 * 60 * 1000
        };
    }

    AsResources(dtos: ResourceDto[]): Resource<number>[] {
        return dtos.map(dto => this.AsResource(dto));
    }

    AsRoles(dtos: RoleDto[]): Role<number>[] {
        return dtos.map(dto => this.AsRole(dto));
    }

    AsRole(dto: RoleDto): Role<number> {
        return {
            id: dto.Id,
            name: dto.Name
        };
    }

    AsResource(dto: ResourceDto): Resource<number> {
        return {
            id: dto.Id,
            entity: dto.Entity,
            name: dto.Name,
            holders: this.AsResourceHolders(dto.ResourceHolders)
        };
    }

    private AsResourceHolders(dtos: ResourceHolderDto[]): ResourceHolder<number>[] {
        return dtos.map(dto => this.AsResourceHolder(dto));
    }

    private AsResourceHolder(dto: ResourceHolderDto): ResourceHolder<number> {
        return {
            id: dto.Id,
            entity: dto.Entity
        };
    }

    private AsMilliseconds(utcDateTime: string): number {
        const shiftedDateTime = FormatConverter.CorrectTimezone(utcDateTime);
        return new Date(shiftedDateTime).getTime();
    }

    private ResolveDependencies(tasks: Task<number>[], taskDtos: TaskDto[]) {
        taskDtos.forEach(taskDto => {
            const task = _.find(tasks, t => t.id === taskDto.Id && t.entity === taskDto.Entity);

            const dependencies = [];
            taskDto.Dependencies.forEach(dependencyDto => {
                const dependencyTask = _.find(tasks, t => t.id == dependencyDto.RecordId && t.entity === dependencyDto.Entity);
                if (!dependencyTask) return;

                const dependencyRowNumber = tasks.indexOf(dependencyTask) + 1;
                const dependencyString = dependencyDto.StartAfterDays ? `${dependencyRowNumber}:${dependencyDto.StartAfterDays}` : `${dependencyRowNumber}`;
                dependencies.push(dependencyString);
            });

            task.depends = dependencies.join(',');
        });
    }
}