import {deserialize} from 'libs/cerialize';
import {deserializeAs} from 'libs/cerialize';

export class BoldBISettings{
    
    @deserialize
    RootUrl: string;

    @deserialize
    SiteIdentifier: string;
}