import {EntityModel} from 'QueryBuilder/Models/EntityModel'
import {deserialize, deserializeAs} from 'libs/cerialize';


export class RelatedEntitiesModel {

	@deserializeAs(EntityModel)
	Entities: Array<EntityModel>;

	constructor() {
		this.Entities = [];
	}
} 