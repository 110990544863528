import {Request} from "Core/Common/Request";
import {URL} from "Core/Common/Url";

export class SecurityProfileStore {

    static LoadProfiles() {
        return Request.Send({
            proxy: {
                url: URL.USER.GET_SECURITY_PROFILES,
                dataType: 'json',
                type: 'GET'
            }
        });
    }

    static SelectMainProfile(profileId: number) {
        return Request.Send({
            proxy: {
                url: URL.USER.SELECT_MAIN_PROFILE,
                type: "POST"
            },
            params: {
                profileId
            }
        });
    }

}