import * as ko from 'knockout'

import {BaseControl} from 'Core/Controls/BaseControl/BaseControl'
import {IControlParam} from 'Core/Screens/IScreen'

import ViewTemplate from 'Core/Controls/ButtonSave/Templates/View.html'
import HelpViewTemplate from 'Core/Controls/ButtonSave/Templates/HelpView.html'
import ToolbarTemplate from 'Core/Controls/ButtonSave/Templates/ToolBar.html'
import DesignTemplate from 'Core/Controls/ButtonSave/Templates/Design.html'

ko.templates['Core/Controls/ButtonSave/Templates/ToolBar'] = ToolbarTemplate;
ko.templates['Core/Controls/ButtonSave/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/ButtonSave/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/ButtonSave/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/ButtonSave/Templates/Design'] = DesignTemplate;

export class ButtonSave extends BaseControl {

	constructor(params: IControlParam) {
		super(params);
		this.Init();
	}

	ApplyProperties(){}

	get SaveBtnText(): any{
		return this._form.GetScreen().IsEditScreen ? this._labels.CTRL_S_SAVE : null;
	}

	private Init(): void {
	}

	Click(): void {
		if (!this._help.IsHelpButtonPressed()) {
			var screen = this._form.GetScreen();
			if (screen) {
				screen.Trigger('SAVE');
			}
		}
	}

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}
}