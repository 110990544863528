export class ImagePreloader{
    static Preload(scr: string){
        if(scr){
            var img = new Image();
            img.src = scr;    
        }
    }

    static FormatImageUrl(guid: string){

        if(guid && guid.indexOf('api/FilesApi/GetImage/') < 0){
            return `api/FilesApi/GetImage/${guid}`;
        }
        return guid;
        
    }
}