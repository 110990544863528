import * as ko from 'knockout';
import * as _ from 'underscore';

import { ToolbarQueryEntity } from 'Desktop/Toolbar/ToolbarQueryEntity/ToolbarQueryEntity';
import { GetQueryStore } from 'QueryBuilder/Stores/GetQueryStore';
import { Modal } from 'Core/Common/Modal';
import { QueryBuilder } from '../../../../QueryBuilder/QueryBuilder';
import { EVENTS as QUERY_BUILDER_EVENTS } from '../../../../QueryBuilder/Events';
import { DragulaExtention } from 'Core/KnockoutExtentions/DragulaExtention';
import { ToolbarQueryColumn } from 'Desktop/Toolbar/ToolbarQueryColumn/ToolbarQueryColumn';
import { Event } from 'Core/Common/Event';
import { EVENTS } from 'Core/Controls/TemplateDesigner/QueryToolbar/Events';
import { ViewModes } from "Core/Controls/Grid/BaseGrid/Enums/ViewModes";
import { GlobalManager, GLOBALS } from 'Core/GlobalManager/GlobalManager';
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";
import { SpheeresLink } from 'Desktop/Toolbar/SpheeresLink';
import { ToolbarQueryEntityJoin } from 'Desktop/Toolbar/ToolbarQueryEntityJoin/ToolbarQueryEntityJoin';
import { Util } from 'QueryBuilder/Util';
import { EditQueryModel } from 'QueryBuilder/Models/EditQueryModel';
import { UrlCommandModel } from 'Core/Controls/TemplateDesigner/Models/EditTemplateModel';

import Template from 'Core/Controls/TemplateDesigner/QueryToolbar/Templates/QueryToolbar.html';
import { OpenRecordLink } from '../../../../Desktop/Toolbar/OpenRecordLink';
import { Notifier } from 'Core/Common/Notifier';


const DRAGULA_EVENTS = {
	DROP: 'drop',
	DRAG: 'drag',
	DRAG_END: 'dragend',
	CLONED: 'cloned'
}

export class EmailSubject {
	Title: string;
	Value: string;
}

export class EmailCC {
	Title: string;
	Value: string;
}

export class EmailBCC {
	Title: string;
	Value: string;
}

export class EmailTo {
	Title: string;
	Value: string;
}

export class Script {
	constructor(title: string, value: string){
		this.Title = title;
		this.Value = value;
	}

	Title: string;
	Value: string;
}

export class Variable {
	constructor(title: string, value: string){
		this.Title = title;
		this.Value = value;
	}

	Title: string;
	Value: string;
}

export class QueryToolbar extends Event {
	private _subjectEntity: KnockoutObservable<ToolbarQueryEntity>;
	private _joins: KnockoutObservableArray<ToolbarQueryEntityJoin>;
	private _isLoadInProgress: KnockoutObservable<boolean>;
	private _queryId: number;
	private _iframeBlocks: JQuery;
	private _original: any;
	private _dragulaGroup: any;
	private _labels = LABELS;
	private _urlCommands: KnockoutObservableArray<UrlCommandModel>;

	private _variables: KnockoutObservableArray<Variable>;
	private __urlCommands: Array<UrlCommandModel>;
	private _email: KnockoutObservableArray<any>;
	private _scripting: KnockoutObservableArray<Script>;
	private IsScriptingExpanded: KnockoutObservable<boolean>;

	
	constructor() {
		super();
		this.AddEvent(EVENTS.DROP_FIELD);
		this.AddEvent(EVENTS.DROP_ENTITY);
		this.AddEvent(EVENTS.SAVE);
		this.AddEvent(EVENTS.DROP_SPHEERES_LINK);
		this.AddEvent(EVENTS.DROP_OPEN_RECORD_LINK);
		this.AddEvent(EVENTS.DROP_URL_COMMAND);
		this.AddEvent(EVENTS.DROP_EMAIL_SUBJECT);
		this.AddEvent(EVENTS.DROP_TO);
		this.AddEvent(EVENTS.DROP_CC);
		this.AddEvent(EVENTS.DROP_BCC);
		this.AddEvent(EVENTS.DROP_SCRIPT);
		this.AddEvent(EVENTS.PREVIEW);
		this.AddEvent(EVENTS.DROP_VARIABLE);
		this._subjectEntity = ko.observable(null);
		this._joins = ko.observableArray([]);
		this._isLoadInProgress = ko.observable(false);
		this._iframeBlocks = null;
		this._queryId = null;
		this._urlCommands = ko.observableArray([]);
		this._email = ko.observableArray([]);
		this.__urlCommands = [];
		this.IsScriptingExpanded = ko.observable(false);
		this._scripting = ko.observableArray();
		this._variables = ko.observableArray([]);
	}

	SetCommands(commands: Array<UrlCommandModel>) {
		this.__urlCommands = commands;
	}

	InitDragula() {
		_.each(DragulaExtention.FindGroups('ToolBarToTemplateEditor'), (item) => { DragulaExtention.DestroyGroup(item); });

		this._dragulaGroup = DragulaExtention.AddGroupWithOptions('ToolBarToTemplateEditor', {
			copy: (el, source) => {
				return true;
			}
		});

		this._dragulaGroup.drake
			.on(DRAGULA_EVENTS.DROP, (el, target, source, sibling) => {

				let elementModel = ko.dataFor(this._original);
				let sourceModel = ko.dataFor(source);
				if (sourceModel instanceof ToolbarQueryEntity && elementModel instanceof ToolbarQueryColumn) {
					this.Trigger(EVENTS.DROP_FIELD, { Field: elementModel, Entity: sourceModel });
				}

				if (elementModel instanceof ToolbarQueryEntityJoin) {
					this.Trigger(EVENTS.DROP_ENTITY, { Entity: sourceModel });
				}

				if (elementModel instanceof SpheeresLink) {
					this.Trigger(EVENTS.DROP_SPHEERES_LINK);
				}

				if (elementModel instanceof OpenRecordLink) {
					this.Trigger(EVENTS.DROP_OPEN_RECORD_LINK);
				}

				if (elementModel instanceof EmailSubject) {
					this.Trigger(EVENTS.DROP_EMAIL_SUBJECT, { EmailSubject: elementModel });
				}

				if (elementModel instanceof EmailCC) {
					this.Trigger(EVENTS.DROP_CC, { EmailSubject: elementModel });
				}

				if (elementModel instanceof EmailBCC) {
					this.Trigger(EVENTS.DROP_BCC, { EmailSubject: elementModel });
				}

				if (elementModel instanceof EmailTo) {
					this.Trigger(EVENTS.DROP_TO, { EmailSubject: elementModel });
				}

				if (elementModel instanceof Script) {
					this.Trigger(EVENTS.DROP_SCRIPT, { Script: elementModel });
				}

				if (elementModel instanceof Variable) {
					this.Trigger(EVENTS.DROP_VARIABLE, { Variable: elementModel });
				}

				if (elementModel instanceof UrlCommandModel) {
					this.Trigger(EVENTS.DROP_URL_COMMAND, { Id: elementModel.Id, Name: elementModel.Name, Type: elementModel.Type });
				}

			})
			.on(DRAGULA_EVENTS.DRAG, (el) => {
				this.BlockFrames();
			})
			.on(DRAGULA_EVENTS.DRAG_END, (el) => {
				this.UnblockFrames();
			})
			.on(DRAGULA_EVENTS.CLONED, (clone, original, type) => {
				this._original = original;
			});
	}

	BlockFrames() {
		this._iframeBlocks = $('iframe').map((indx, element) => {
			var iframe = $(element);
			return $('<div>')
				.css('position', 'absolute')
				.appendTo(iframe.parent())
				.outerWidth(iframe.outerWidth())
				.outerHeight(iframe.outerHeight())
				.offset(iframe.offset())[0];
		});
	}

	UnblockFrames() {
		if (this._iframeBlocks) {
			this._iframeBlocks.remove();
			delete this._iframeBlocks;
		}
	}

	GetTemplate() {
		return Template;
	}

	AfterRender() { }

	Refresh() {
		if (this._queryId) {
			this._isLoadInProgress(true);
			GetQueryStore.GetToolbarQuery({ Id: this._queryId })
				.always(() => {
					this._isLoadInProgress(false);
				}).then((data) => {
					this.SetData(data);
				}).fail(err=>{
					new Notifier().Failed(err.message);
				});
		}
	}


	SetData(editQuery: EditQueryModel) {
		this._subjectEntity(null);		
		this._joins([]);
		this._email([]);
		this._scripting([]);
		this._urlCommands([]);
		this._variables([]);
		
		this.InitDragula();
		this._urlCommands(this.__urlCommands);

		let emailSubject = new EmailSubject();
		emailSubject.Title = 'SUBJECT';
		emailSubject.Value = '{SUBJECT}{/SUBJECT}';

		let emailCC = new EmailCC();
		emailCC.Title = 'CC';
		emailCC.Value = '{CC}{/CC}';


		let emailBCC= new EmailBCC();
		emailBCC.Title = 'BCC';
		emailBCC.Value = '{BCC}{/BCC}';

		let emailTo= new EmailTo();
		emailTo.Title = 'TO';
		emailTo.Value = '{TO}{/TO}';

		this._email([emailSubject, emailTo, emailCC, emailBCC]);

		this._scripting([
			new Script('<%%>', '<% %>' ),
			new Script('IIF',  'IIF();'),
			new Script('COUNT',  'COUNT();'),
			new Script('TOTAL',  'TOTAL();'),
			new Script('ROUND',  'ROUND();'),
			new Script('GROUP',  "{GROUP('TABLE_NAME.FIELD_NAME', 'ASC')}\n\n{/GROUP}"),
			new Script('SHOWIF', "{SHOWIF('CONDITION')}\n\n{/SHOWIF}"),
			new Script('HIDEIF', "{HIDEIF('CONDITION')}\n\n{/HIDEIF}"),
			new Script('SIGNATURE',  "{SIGNATURE('USER NAME')}"),
			new Script('MOLLIE',  "{MOLLIE}TableName; RecordId; EUR; Amount; Description{/MOLLIE}"),
			new Script('QRCODE',  "{QRCODE}\n\n{/QRCODE}"),
		]);	
		
		this._variables([new Variable ('FLOW FOLDER FILES', '{FLOW_FOLDER_FILES}' )]);

		if(editQuery.Query){
			this._subjectEntity(new ToolbarQueryEntity(editQuery.Query.Entity, false));
				
			let query = editQuery.Query;
	
			let allJoins = query.EntityJoins
				.concat(query.SubEntityJoins)
				.concat(query.LookupEntityJoins)
				.concat(query.ReferenceEntityJoins)
				.concat(query.ReferenceLookupEntityJoins);					
	
			this._joins(allJoins.map(item=> new ToolbarQueryEntityJoin(item, false, true)));
		}	
	}

	get QueryEntity(): KnockoutObservable<ToolbarQueryEntity> {
		return this._subjectEntity;
	}

	get Joins(): KnockoutObservableArray<ToolbarQueryEntityJoin>{
		return this._joins;
	}

	ShowQueryBuilder() {
		const modal = new Modal();
		modal.Show();
		const queryBuilder = new QueryBuilder(true, false, true);
		queryBuilder.QueryTypeName = ViewModes.Template;
		ko.cleanNode(modal.Wrapper);
		ko.applyBindings(queryBuilder, modal.Wrapper);
		var recordsPerPageGlobalValue = GlobalManager.Instance.GetGlobal(GLOBALS.OTHER_GRID_PAGE_LINES);
		var recordsPerPage = parseInt(recordsPerPageGlobalValue) || 10;
		if (this._queryId) {
			queryBuilder.EditQuery(this._queryId, recordsPerPage);
		} else {
			queryBuilder.InitTemplateQuery(recordsPerPage, '', []);
		}

		queryBuilder.On(QUERY_BUILDER_EVENTS.CLOSE, this, (eventArgs) => { modal.Close(); });
		queryBuilder.On(QUERY_BUILDER_EVENTS.DATA_SAVED, this, (eventArgs: any) => {
			modal.Close();
			if (eventArgs.data) {
				this._queryId = eventArgs.data.RecordId;
				this.Refresh();
			};
		});
	}

	Save() {
		this.Trigger(EVENTS.SAVE);
	}

	PasteTable() { }

	PasteFieldCode() { }

	get QueryId(): number {
		return this._queryId;
	}

	set QueryId(queryId: number) {
		this._queryId = queryId;
	}

	private Preview(){
		this.Trigger(EVENTS.PREVIEW, { EntityId: this._subjectEntity().Model.Metadata.Id }); 
	}

	ExpandScripting(){
		this.IsScriptingExpanded(!this.IsScriptingExpanded())
	}
}