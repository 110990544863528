import {Event} from "Core/Common/Event";

import {PortletManager} from "Core/Portlets/Managers/Runtime/PortletManager";

export interface EventHandlerCallback {
	Handler: (portletManager: PortletManager) => void;
}
export class PortletManagerEventHandlersFactory<THandlerOwner extends Event, TEventEmitter extends Event> {
	constructor(private _handlerOwner: THandlerOwner, private _eventEmitter: TEventEmitter, private _handlers: { [eventName: string]: EventHandlerCallback }) {

	}

	BindEvent(eventName: string) {
		if (this._handlers[eventName]) {
			this._eventEmitter.On(eventName, this, () => this._handlers[eventName].Handler.call(this._handlerOwner, this._eventEmitter));
		}
	}
}