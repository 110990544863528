import {P} from "Core/Common/Promise";
import {UserSecurityModel} from "Core/Controls/UserSecurity/Models/UserSecurityModel";
import {Request, IRequestOptions} from "Core/Common/Request";

export class UserSecurityStore {
	static GetUserSecurityData(userId: number): P.Promise<any> {
		let deferredResult = P.defer<any>();

		Request.Send({
			proxy: {
				url: '/api/UserSecurityApi/GetUserSecurityScreen',
				dataType: 'json',
				type: "GET"
			},
			params: { id: userId }
		}).then(result => deferredResult.resolve(result));

		return deferredResult.promise();
	}
}