import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize} from 'libs/cerialize';
import {PlannerLevelRowModel, TimeCellModel} from "../Models/PlannerLevelRowModel";
import {IPlannerLevelParentRecords} from "../PlannerLevel";

export interface IGetPlannerLevelDataRequestModel {
    ControlId: number;
    ParentBarId: string;
    WeekNumber: number;
    StartDate: string;
    ViewType: string;
    IsRoot: boolean;
    RecordsPerPage: number;
    PageNumber: number;
    BarId?: string;
    ManagerId?: number;
    ParentRecords?: Array<IPlannerLevelParentRecords>;
}

export interface ICreateLinkRequestModel {
    RecordId: number;
    ControlId: number;
    StartDate: string;
    ParentRecords: Array<IPlannerLevelParentRecords>;
}

export interface IGetHigherResolutionPlanRequestModel {
    ControlId: number;
    ParentPlan: TimeCellModel;
}

export class PlannerLevelStore {
    static GetPlannerLevelData(params: IGetPlannerLevelDataRequestModel): P.Promise<Array<PlannerLevelRowModel>> {
        let deferredResult = P.defer<Array<PlannerLevelRowModel>>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: '/api/PlannerApi/GetPlannerLevelData',
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(result => {
                deferredResult.resolve( Deserialize(result.ResultObject, PlannerLevelRowModel));
            });

        return deferredResult.promise();
    }

    static CreateOrUpdatePlan(params: any): P.Promise<Array<PlannerLevelRowModel>>  {
        let deferredResult = P.defer<Array<PlannerLevelRowModel>>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: '/api/PlannerApi/CreateOrUpdatePlan',
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(result => {
                if (!result.IsSuccessfull) {
                    deferredResult.reject({message: result.ErrorMessage});
                } else {
                    deferredResult.resolve( Deserialize(result.ResultObject, PlannerLevelRowModel));
                }
            });

        return deferredResult.promise();
    }

    static UnlinkQueryRecord(params: any): P.Promise<Array<PlannerLevelRowModel>>  {
        let deferredResult = P.defer<Array<PlannerLevelRowModel>>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: '/api/PlannerApi/UnlinkQueryRecord',
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(result => {
                if (!result.IsSuccessfull) {
                    deferredResult.reject({message: result.ErrorMessage});
                } else {
                    deferredResult.resolve( Deserialize(result.ResultObject, PlannerLevelRowModel));
                }
            });

        return deferredResult.promise();
    }

    static CreateOrUpdatePlans(params: any): P.Promise<Array<PlannerLevelRowModel>>  {
        let deferredResult = P.defer<Array<PlannerLevelRowModel>>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: '/api/PlannerApi/CreateOrUpdatePlans',
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(result => {
                if (!result.IsSuccessfull) {
                    deferredResult.reject({message: result.ErrorMessage});
                } else {
                    deferredResult.resolve( Deserialize(result.ResultObject, PlannerLevelRowModel));
                }
            });

        return deferredResult.promise();
    }

    static LinkRecord(params: ICreateLinkRequestModel){
        let deferredResult = P.defer<any>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: '/api/PlannerApi/CreateLink',
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(result => {
                deferredResult.resolve(null);
            });

        return deferredResult.promise();
    }

    static GetHigherResolutionPlan(params: IGetHigherResolutionPlanRequestModel): P.Promise<Array<PlannerLevelRowModel>>  {
        let deferredResult = P.defer<Array<PlannerLevelRowModel>>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: '/api/PlannerApi/GetHigherResolutionPlan',
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(result => {
                if (!result.IsSuccessfull) {
                    deferredResult.reject({message: result.ErrorMessage});
                } else {
                    deferredResult.resolve( Deserialize(result.ResultObject, PlannerLevelRowModel));
                }
            });

        return deferredResult.promise();
    }

}