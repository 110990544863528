import {deserialize} from 'libs/cerialize';
import * as ko from 'knockout';

export class RelationTypeValueModel {

	public ShowNexRelationButton: KnockoutObservable<boolean>;

    public ShowEditRelationButton: KnockoutObservable<boolean>;

	constructor(){
		this.ShowNexRelationButton = ko.observable(false);
		this.ShowEditRelationButton = ko.observable(false);
	}

	@deserialize
	RelationTypeValueId: number;

	@deserialize
	RelationTypeValue: string;

    @deserialize
	Type: string;

	get FullName(){
		return `${this.Type}: ${this.RelationTypeValue}`;
	}
}