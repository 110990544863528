import {DataRoles} from "Core/Controls/FieldSecurity/Shared/Enums/DataRoles";

import {DataRoleMappings} from "Core/Controls/FieldSecurity/Mappings/DataRoleMappings";

import {DataRoleDTOModel} from "Core/Controls/FieldSecurity/Shared/Models/DTO/Get/DataRoleDTOModel";
import {DataRoleModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/DataRoleModel";

import {FieldSecurityChangesModel} from "Core/Controls/FieldSecurity/Shared/Models/State/FieldSecurityChangesModel";

import {PostDataRolesRequestModel} from "Core/Controls/FieldSecurity/Shared/Models/DTO/Post/PostDataRolesRequestModel";
import {NewDeputyDTOModel} from "Core/Controls/FieldSecurity/Shared/Models/DTO/Post/NewDeputyDTOModel";
import {DeletedRoleDTOModel} from "Core/Controls/FieldSecurity/Shared/Models/DTO/Post/DeletedRoleDTOModel";

export class FieldSecurityMappings {
	static MapToDataRoles(dataRoleDto: DataRoleDTOModel): DataRoleModel {
		return DataRoleMappings.MapToDataRole(dataRoleDto);
	}

	static MapToPostModel(changes: FieldSecurityChangesModel) {
		const newRoles =
			changes.NewRoles
				.filter(newRole => newRole.Name !== DataRoles.Instance.DEP.ShortName)
				.map(newRole => DataRoleMappings.MapToNewDataRole(newRole));

		const newDeputies =
			changes.NewRoles
				.filter(newRole => newRole.Name === DataRoles.Instance.DEP.ShortName)
				.map(newRole => new NewDeputyDTOModel(newRole.FieldCollection.Id));

		const deletedRoles =
			changes.DeletedRoles
				.filter(deletedRole => deletedRole.Name !== DataRoles.Instance.DEP.ShortName)
				.map(deletedRole => new DeletedRoleDTOModel(deletedRole.FieldCollection.Id));

		const deletedDeputies =
			changes.DeletedRoles
				.filter(deletedRole => deletedRole.Name === DataRoles.Instance.DEP.ShortName)
				.map(deletedRole => new DeletedRoleDTOModel(deletedRole.FieldCollection.Id));

		return new PostDataRolesRequestModel(newRoles, newDeputies, deletedRoles, deletedDeputies);
	}
}