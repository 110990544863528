import * as ko from 'knockout'

import {Modal} from 'Core/Common/Modal';
import {Event} from 'Core/Common/Event';
import {FollowUpStore} from "Core/Controls/ButtonFollowUp/Stores/FollowUpStore";
import {Notifier} from 'Core/Common/Notifier';
import {BlockUI} from 'Core/Common/BlockUi';
import {MutationObserverConfig, RepositionModalObserver} from "Core/Common/RepositionModalObserver";
import {LifeStatusGroupEnum, LifeStatusGroups} from "Core/Common/Enums/LifeStatusGroups";
import {PromptDialog, Types} from 'Core/Components/Dialogs/PromptDialog/PromptDialog';

import {CONFIRMATIONS, LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";

import {
	ConfirmationDialog, EVENTS as ConfirmationDialogEvents,
	EVENTS as CONFIRMATION_DIALOG_EVENTS,
	Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog'

import {LifeStatusSimpleModel} from "./Models/LifestatusSimpleModel";
import {FollowUpOptionModel} from "./Models/FollowUpOptionModel";
import {LIFE_STATUS_GROUPS} from "../../Constant";

import {FlowFolderMemos} from "Core/Controls/ButtonFollowUp/FlowFolderMemos";

import {FlowFolderModel} from "Core/Components/ProgressBar/Models/Response/FlowFolderModel";
import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';
import {FlowFolderMemosModel} from "Core/Components/ProgressBar/Models/Response/FlowFolderMemosModel";
import {FlowFolder} from "./FlowFolder";
import {ScreenParamsModel} from "Core/Controls/ButtonFollowUp/Models/ScreenParamsModel";
import {LifeStatusesGeneralModel} from "Core/Controls/ButtonFollowUp/Models/LifeStatusesGeneralModel";
import {FlowFolderStore} from "Core/Components/ProgressBar/Stores/FlowFolderStore";

import LifeStatuses from "Core/Controls/ButtonFollowUp/Templates/LifeStatuses.html";

ko.templates["Core/Controls/ButtonFollowUp/Templates/LifeStatuses"] = LifeStatuses;

export class FollowUpLifeStatuses extends Event {
	private _modal: Modal;
	private _options: KnockoutObservableArray<FollowUpOptionModel>;
	private _selectedOption: KnockoutObservable<FollowUpOptionModel>;
	private _childOption: FollowUpOptionModel;
	private _selectedParentOption: KnockoutObservable<FollowUpOptionModel>;
	private _parentOption: FollowUpOptionModel;
	private _caption: KnockoutObservable<string>;
    private _clickedChildId: KnockoutObservable<number>;
	private _labels = LABELS;
	private _isFlowFolder: boolean;
	private _isFollowUpDisabled: boolean;
	private _disabledReason: string;
	private _entityId: number;
	private _recordId: number;
	private _retireChildren: boolean;
	private _flowFolder: FlowFolder;
	private _flowFolderMemos: KnockoutObservable<FlowFolderMemos>;
	private _observer: RepositionModalObserver;

	constructor() {
		super();
		this._options = ko.observableArray([]);
		this._selectedOption = ko.observable(null);
		this._caption = ko.observable(null);
		this._selectedParentOption = ko.observable(null);
        this._clickedChildId = ko.observable(null);
        this._isFlowFolder = null;
		this._isFollowUpDisabled = null;
		this._disabledReason = null;
		this._entityId = null;
		this._recordId = null;
		this._retireChildren = false;
		this._flowFolder = null;
		this._flowFolderMemos = ko.observable(null);
		this._observer = null;

		this.AddEvent('LIFESTATUS_CHOOSEN');
		this.AddEvent('PARENT_LIFESTATUS_CHOOSEN');
		this.AddEvent('CHILD_LIFESTATUS_CHOOSEN');
        this.AddEvent('DONE');

    }

    get IsCurrentDisabled(){
		return this._selectedParentOption() || this._selectedOption().LifeStatuses.CurrentLifeStatus.IsFollowUpDisabled;
	}

	FlowMemoTab(){
		BlockUI.Block();
		FlowFolderStore.GetFlowMemos({EntityId: this._entityId, RecordId: this._recordId})
			.always(()=>BlockUI.Unblock())
			.then(data => {
				let flowFolderMemosModel = new FlowFolderMemosModel();

				flowFolderMemosModel.IsMemoTranslatable = data.IsMemoTranslatable;
				flowFolderMemosModel.MemoItems = data.MemoItemModels;

				this._flowFolderMemos(new FlowFolderMemos(flowFolderMemosModel, {EntityId: this._entityId, RecordId: this._recordId}));
				this._flowFolderMemos().On('DELETE_FLOW_FOLDER_MEMO_RECORD', this, (eventArgs)=> {
					if (this._modal){
						this._modal.OneRepositionModal();
					}
				})
				this._flowFolderMemos().On('CHANGE_FLOW_FOLDER_MEMO_RECORD', this, (eventArgs)=> {
					if (this._modal){
						this._modal.OneRepositionModal();
					}
				})
			})
	}

	ShowInModal() {
		let self = this;
		this._modal = new Modal({
			minWidth: 350,
			minHeight: 150,
			maxHeight: '85vh',
			maxWidth: '60vw',
			closeOnClick: 'overlay',
			blockScroll: true,
			closeOnEsc: false,
			onOpen: function () {
				const target: HTMLElement = this.wrapper.find('.jBox-content')[0];
				const mutationObserverConfig: MutationObserverConfig = {
					attributes: true,
					childList: true,
					characterData: true,
					subtree: true,
				}
				self._observer = new RepositionModalObserver(target, mutationObserverConfig);
			}
		}, false);
		this._modal.One('CLOSE', this, () => { this.Close() });

		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);
		this._modal.Show();

	}

	Close() {
		if (this._modal) {
			this._observer && this._observer.Disconnect();
			this._modal.Close();
			this.Off("LIFESTATUS_CHOOSEN");
			this.Off("PARENT_LIFESTATUS_CHOOSEN");
			this.Off("CHILD_LIFESTATUS_CHOOSEN");
			this.Off("DONE");
			if (this._selectedParentOption() === this._parentOption){
				this._clickedChildId(null);
				this._selectedParentOption(null);
			}
		}
	}

	InitFlowFolder(isFlowFolder: boolean, _entityId: number, flowFolderModel?: FlowFolderModel, followUpData?: LifeStatusesGeneralModel){
		this._isFlowFolder = isFlowFolder;

		if (followUpData) {
			this._isFollowUpDisabled = followUpData.IsFollowUpDisabled;
			this._disabledReason = followUpData.DisabledReason;
		}

		if (this._isFlowFolder) {
			this._flowFolder = new FlowFolder();
			this._flowFolder.Init(this._isFlowFolder, this._entityId, flowFolderModel);
			this._flowFolder.On('CLOSE_FOLLOW_UP_LIFE_STATUSES', this, (eventArgs)=> {
				this.Close();
			});
		}
	}

	Init(childOption: FollowUpOptionModel, parentOption: FollowUpOptionModel, screenParams: ScreenParamsModel) {
		if (screenParams){
			this._recordId = screenParams.RecordId;
			this._entityId = screenParams.EntityId;
		}
		this._options([childOption, parentOption]);
		this._childOption = childOption;
		if (parentOption) {
			this._parentOption = parentOption;
        }
		if (this._childOption) {
			this._selectedOption(this._childOption);
		}
	}

	Click(data, event) {
		PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, data);
		this.Close();
	}

	SelectCurrent(disable?: boolean) {
		if(disable) {
			return;
		}

		this.UpdateLifeStatus(this._selectedOption().LifeStatuses.CurrentLifeStatus);
    }

	PickLifeStatus(lifeStatus: LifeStatusSimpleModel, disable?: boolean) {
		if(disable) {
			return;
		}

		if (LifeStatusGroups.GetLifeStatusGroup(lifeStatus.Sort) === LifeStatusGroupEnum.Retired) {
			this.CheckRetiredChildrenAndApproval(lifeStatus);
			return;
		}

		this.CheckApprovalAndUpdateLifeStatus(lifeStatus);
	}

	set Caption(value: string) {
		this._caption(value);
	}

	get Caption():string {
		return this._caption();
	}

	CheckApprovalAndUpdateLifeStatus(lifeStatus: LifeStatusSimpleModel) {
		const confirmationText = CONFIRMATIONS.ARE_SURE_TO_CHANGE_LIFESTATUS
			.replace('{fromstatus}', this._selectedOption().LifeStatuses.CurrentLifeStatus.Name)
			.replace('{tostatus}', lifeStatus.Name);

		const confirmationDialog = new ConfirmationDialog({
			Text: confirmationText,
			Type: ConfirmationTypes.Question
		});

		confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED,
			this,
			() => {
				this.UpdateLifeStatus(lifeStatus);
			});

		if (lifeStatus.Id === this._selectedOption().LifeStatuses.CurrentLifeStatus.Id) {
			this.UpdateLifeStatus(lifeStatus);
			return;
		}

		const approvalName = lifeStatus.ApprovalName || 'Off';
		switch (approvalName) {
			case 'Off':
				this.UpdateLifeStatus(lifeStatus);
				break;
			case 'Password':
				const passwordPrompt = new PromptDialog(
					{
						Type: Types.Password,
						Label: CONFIRMATIONS.PLEASE_TYPE_YOUR_PASSWORD_TO_CONFIRM,
						Value: '',
						MinHeight: 200,
						Required: true,
						RequiredErrorMessage: LABELS.PASSWORD + ' ' + NOTIFICATIONS.IS_REQUIRED,
						ShowNotification: false,
						CloseOnSave: false
					});
				passwordPrompt.Show();

				passwordPrompt.On('Save', this,
					(eventargs) => {
						const password = eventargs.data.value;
						if (password) {
							BlockUI.Block();

							FollowUpStore.ConfirmPassword({password: password})
								.always(() => {
									BlockUI.Unblock();
								})
								.then((response) => {
									if (response) {
										passwordPrompt.Hide();
										this.UpdateLifeStatus(lifeStatus, password);
									} else {
										passwordPrompt.ExternalInvalidActionHandling(NOTIFICATIONS.INCORRECT_PASSWORD);
									}
								})
								.fail(error => {
									new Notifier().Failed(error);
								});
						}
					}
				);
				break;

			case 'Yes/No':
				confirmationDialog.Show();
				break;
		}
	}

	CheckRetiredChildrenAndApproval(lifeStatus: LifeStatusSimpleModel) {
		BlockUI.Block();

		const entityId = this._entityId || (this._selectedOption().LifeStatuses.ActionSubjectRecord
			&& this._selectedOption().LifeStatuses.ActionSubjectRecord.EntityId);
		const recordId = this._recordId || (this._selectedOption().LifeStatuses.ActionSubjectRecord
			&& this._selectedOption().LifeStatuses.ActionSubjectRecord.RecordId);

		FollowUpStore.CheckRetiredChildren({
			ParentEntityId: entityId,
			ParentRecordId: recordId
		})
			.always(() => {
				BlockUI.Unblock();
			})
			.then((data) => {
				if (!data.ChildrenToRetireExist) {
					this.CheckApprovalAndUpdateLifeStatus(lifeStatus);
					return;
				}

				const retiredStatuses = data.RetiredStatuses.join(', ');
				const confirmationText = CONFIRMATIONS.ALL_CHILD_RECORDS_WILL_BE_MOVED_TO_THE_RETIRED_STATUSES
					.replace('{RetiredStatuses}', retiredStatuses);

				const confirmationDialog = new ConfirmationDialog({
					Text: confirmationText,
					Type: ConfirmationTypes.Question,
					TextConfirm: LABELS.YES,
					TextDecline: LABELS.NO
				});

				confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED,
					this,
					() => {
						this._retireChildren = data.ChildrenToRetireExist;
						this.CheckApprovalAndUpdateLifeStatus(lifeStatus);
					});

				confirmationDialog.Show();
			})
			.fail(error => {
				new Notifier().Failed(error);
			});
	}

	UpdateLifeStatus(lifeStatus: LifeStatusSimpleModel, password?) {
		//Current follow up functionality was disabled according to CYB-15142
		/*if (this._parentOption) {
			if (this._selectedParentOption() === this._parentOption) {
				this.Trigger('PARENT_LIFESTATUS_CHOOSEN', { Option: this._parentOption, LifeStatus: lifeStatus, Password: password });
				this._modal.Close();
			} else {
				if (lifeStatus.Sort === LIFESTATUS_GROUPS.RETIRED) {
					this._selectedParentOption(this._parentOption);
					this._clickedChildId(lifeStatus.Id);
					this.Trigger('CHILD_LIFESTATUS_CHOOSEN',
						{ Option: this._selectedOption(), LifeStatus: lifeStatus, Password: password });
				} else {
					this.Trigger('LIFESTATUS_CHOOSEN', { Option: this._selectedOption(), LifeStatus: lifeStatus, Password: password });
					this._modal.Close();
				}
			}
		} else {
			this.Trigger('LIFESTATUS_CHOOSEN', { Option: this._selectedOption(), LifeStatus: lifeStatus, Password: password });
			this._modal.Close();
		}*/

		this.Trigger('LIFESTATUS_CHOOSEN', { Option: this._selectedOption(), LifeStatus: lifeStatus, Password: password, RetireChildren: this._retireChildren });
		this._modal.Close();
	}

	GetTemplateName() {
		return 'Core/Controls/ButtonFollowUp/Templates/LifeStatuses';
	}

	AfterRender() {
	}

}