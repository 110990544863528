import * as ko from 'knockout'
import * as _ from "underscore";

import {EventTracker, EVENTS_TO_LISTEN} from "Core/Common/EventTracker";

import {BaseControl, IControlValue} from 'Core/Controls/BaseControl/BaseControl'
import {IControlParam} from 'Core/Screens/IScreen'
import {TranslationModel} from "Core/ScreenManager/Models/TranslationModel";
import {TranslationFieldEditor} from "Core/Components/TranslationFieldEditor/TranslationFieldEditor";

import {FONT_STYLES, RenderModes} from 'Core/Constant';

import {GeneralProperties} from 'Core/GeneralProperties/GeneralProperties';
import MemoConfig from 'Core/Controls/Memo/Configs/memo-config.json';

import ViewTemplate from 'Core/Controls/Memo/Templates/View.html'
import HelpViewTemplate from 'Core/Controls/Memo/Templates/HelpView.html'
import ToolBarTemplate from 'Core/Controls/Memo/Templates/ToolBar.html'
import DesignTemplate from 'Core/Controls/Memo/Templates/Design.html'
import EditTemplate from 'Core/Controls/Memo/Templates/Edit.html'
import {TranslationManager} from "../../Components/Translation/TranslationManager";
import {ScreenTypes} from "../../Common/Enums/ScreenTypes";
import {PROPERTIES} from "Core/Controls/Memo/Constants";
import { data } from 'jquery';

ko.templates['Core/Controls/Memo/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Memo/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Memo/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/Memo/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/Memo/Templates/Edit'] = EditTemplate;

export class Memo extends BaseControl {
    private _isNewRecord: boolean;

    private _value: KnockoutObservable<string>;
    private _editor: any;
    private _originalValue: string;
    private _labelStyle: KnockoutObservable<any>;

    private _serverTranslations: TranslationModel[];
    private _translationFieldEditor: TranslationFieldEditor;
    private _memoHeightPX: KnockoutObservable<number>;
    private _memoHeightAuto: KnockoutObservable<string>;

    constructor(params: IControlParam) {
        super(params, MemoConfig);
        this._value = ko.observable('');
        this._labelStyle = ko.observable(null);
        this._memoHeightPX = ko.observable(null);
        this._memoHeightAuto = ko.observable(null);

        this._translationFieldEditor = new TranslationFieldEditor();

        this.Init();
        this.BindEvents();

        if (this._form && (this._form.GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.LinkEditor])) {
            this.ApplyLinkEditorStyles();
        }
    }

    private ApplyLinkEditorStyles() {
        if(!this.FieldModel.HasLinkEditorVisibility){
            return;
        }
        const labelStyle = {
            color: null,
            fontWeight: null,
            fontStyle: null,
            textDecoration: null
        };
        if (this.FieldModel.FontColor) {
            labelStyle.color = this.FieldModel.FontColor;
        }
        labelStyle.fontWeight = FONT_STYLES.NORMAL;

        if (this.FieldModel.FontStyles) {
            _.forEach(this.FieldModel.FontStyles, (style) => {

                switch ( style.Name.toLowerCase() ) {
                    case FONT_STYLES.BOLD:
                        labelStyle.fontWeight = FONT_STYLES.BOLD;
                        break;
                    case FONT_STYLES.UNDERLINE:
                        labelStyle.textDecoration = FONT_STYLES.UNDERLINE;
                        break;
                    case FONT_STYLES.ITALIC:
                        labelStyle.fontStyle = FONT_STYLES.ITALIC;
                        break;
                }
            })
        }

        this.Extend(labelStyle, this._labelStyle());
        this._labelStyle(labelStyle);
    }

    GetDefaultTranslation() {
        return this._translationFieldEditor.GetDefaultTranslation();
    }

    GetTranslations() {
        return this._translationFieldEditor.GetTranslations(false);
    }

    private Init(): void {
        this.ApplyProperties();
    }

    ApplyProperties() {
        if (this.Properties) {
            if (this.Properties.IsWrapped) {
                _.each(this.Properties.IsWrapped.Properties, (item: any) => {
                    this.ApplyWrapTextProperty(item.WrapText);
                });
            }

            this._memoHeightPX(!!this.GeneralProperties.GetPropertyValue(PROPERTIES.HEIGHT_PX) ? this.GeneralProperties.GetPropertyValue(PROPERTIES.HEIGHT_PX) : '');
            this._memoHeightAuto(!!this.GeneralProperties.GetPropertyValue(PROPERTIES.HEIGHT_AUTO) ? this.GeneralProperties.GetPropertyValue(PROPERTIES.HEIGHT_AUTO) : false);
        }
    }

    private GetLanguages() {
        if (this.IsTranslatable()) {
            if (this._translationFieldEditor.TranslationItemsList.length == 0) {
                this._translationFieldEditor.LoadTranslationItems();
            }
            const languages = this._translationFieldEditor.TranslationItemsList.map(translation => translation.Language);
            const activeLanguage = this._translationFieldEditor.ActiveTranslation.Language;

            return {
                List: languages,
                Active: activeLanguage
            };
        }

        return null;
    }

    private OnChangeLanguage(id: number) {
        const selectedTranslation = this._translationFieldEditor.GetTranslationById(id);
        this._translationFieldEditor.SelectTranslation(selectedTranslation);

        const selectedValue = selectedTranslation.Value();
        this._editor.setContent(selectedValue || '');
        this._value(selectedValue);
    }

    ApplyWrapTextProperty(wrapText) {
        this._isWrapped(wrapText);
        this._form && this._form.Wrap(this, wrapText);
        if (this._parentControl) {
            this._parentControl.SetIsWrapped(true);
        }

    }

    private BindEvents() {
        if (this._renderMode() === RenderModes.Design) {
            this._model.subscribe(() => this.Init());
        }
    }

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);
    }

    SetValue(value: IControlValue): void {
        if (value.Data) {
            this._isNewRecord = value.RecordSpecsModel && value.RecordSpecsModel.IsNewRecord;
            this._originalValue = value.Data.Value && _.unescape(value.Data.Value) || '';

            let currentValue = this._originalValue;

            if (this.IsTranslatable()) {
                this._serverTranslations = value.Data.Translations;

                const translation = this.GetTranslation(value.Data);
                currentValue = translation.TranslatedValue || translation.Value;

                this._translationFieldEditor.LoadTranslationItems();
                this._translationFieldEditor.SetTranslations(value.Data.Translations, value.Data.Value === null ? '' : value.Data.Value);
                this._translationFieldEditor.SetActiveTranslation(translation.Language.Id);
            }

            this._value(currentValue);

            if (this._editor) {
                this._editor.setContent(this._value() || '');
            }
        }
    }

    AfterInit(editor) {
        this._editor = editor;
        this._editor.setContent(this._value() || '');
        this.Trigger('READY');
    }

    Deserialize() {
        const field = _.first(this._model().Fields);

        if (this._editor && field && !this._isReadonly) {

            const serializedValue = [`${field.EntityName}.${field.Name}`];

            if (this.IsTranslatable()) {
                const defaultTranslation = this._translationFieldEditor.GetDefaultTranslation().Value;
                serializedValue.push(defaultTranslation);

                const activeTranslations = this._translationFieldEditor.GetTranslations(false);
                const changedTranslations = activeTranslations.map(translation => {
                    const languageShortName = TranslationManager.Instance.GetLanguageById(translation.LanguageId).ShortName;
                    return !translation.Value ? `${languageShortName}_` : `${languageShortName}_${translation.Value}`;
                });

                serializedValue.push(...changedTranslations);
                return serializedValue;
            }

            serializedValue.push(this._value());
            return serializedValue;
        }

        return null;
    }

    AfterChange(content) {
        EventTracker.Instance.RegisterEvent(EVENTS_TO_LISTEN.KEYUP);

        this._value(content);

        const currentValue = this._value();

        if (this.IsTranslatable()) {
            this._translationFieldEditor.ActiveTranslation.Value(currentValue);
        }
    }

    IsModified(): boolean {
        if (super.IsModified()) {
            return true;
        }

        if (!this.IsTranslatable()) {
            return this._value() !== this._originalValue;
        }

        const defaultTranslation = this._translationFieldEditor.GetDefaultTranslation().Value;
        const activeTranslations = this._translationFieldEditor.GetTranslations(false);

        if (this._isNewRecord) {
            return defaultTranslation !== this._originalValue || _.any(activeTranslations, activeTranslation => !!activeTranslation.Value);
        }

        const translationDiffExists = _.any(activeTranslations, activeTranslation => {
            const serverTranslation = _.find(this._serverTranslations, serverTranslation => serverTranslation.LanguageId === activeTranslation.LanguageId);
            return serverTranslation && activeTranslation.Value !== serverTranslation.Value;
        });

        return defaultTranslation !== this._originalValue || translationDiffExists;
    }

    IsValid(): boolean {
        if (this._isRequired && (this._value() === '' || this._value() === undefined)) {
            this._isValid(false);
        } else {
            this._isValid(true);
        }
        return this._isValid();
    }

    GetValue() {
        return `${this._value()}`;
    }
}