import * as ko from 'knockout';
import {PASSWORD} from 'Core/Constant';

export class LockScreenModel {
	Password: KnockoutObservable<string>;

	constructor() {
		this.Password = ko.observable('').extend({
			required: {
				message: 'Please, enter a password'
			},
			minLength: PASSWORD.MinimumLength
		});
	}
}