import { deserialize } from 'libs/cerialize';
import { deserializeAs } from 'libs/cerialize';

export class TemplateModel {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	TypeName: string;

	@deserialize
	EntityTypeId: number;

	@deserialize
	DestinationEntityId: number;
}