//Libs
import * as ko from "knockout";
import * as moment from "moment";
import * as _ from 'underscore';

//Common
import {Guid} from "Core/Common/Guid";
import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';

//Constants
import {DATE_FORMATS} from 'Core/Constants/DateTimeFormats';

//UI
import {Icon} from "Core/Icon/Icon";
import {IconModel} from "Core/Controls/BaseControl/Models/IconModel";
import {UsersViewModel} from "./UsersViewModel";
import {AttachedFieldModel} from "Core/Controls/BaseControl/Models/AttachedFieldModel";
import {TranslationItem} from "Core/Components/TranslationFieldEditor/TranslationItem";
import {FIELD_TYPES} from "Core/Constant";
import {FormatConverter} from "FormatEditor/FormatConverter";

export class DayDataViewModel {
    WeekId: string;

    Date: Date;
    Day: string;
    Year: number;
    Week: number;
    TimeClosedDate: Date;
    DaysOfWeek: DayOfWeekStatistics[];
    LookupFields: LookupField[];
    DefaultValues: DefaultValue[];
    TranslatedFields: TranslatedField[];
    HasDisapprovals: boolean;

    RequestedDate: RequestedDate;
    AllowedEntities: AllowedEntity[];

    Users: UsersViewModel;
    StartBySchedule: Date;

    constructor() {
        this.WeekId = Guid.NewGuid();
        this.DaysOfWeek = [];
        this.AllowedEntities = [];
        this.HasDisapprovals = false;
    }

    get ClosedWeek() {
        return _.all(this.DaysOfWeek, dow => dow.ClosedDate);
    }

    get WeekDescription() {
        const formattedDate = moment(this.Date).format(DATE_FORMATS.LONG_DATE_SHORT_WEEK.MomentFormat);
        //return `${this.Year}-${this.Week}: ${formattedDate}`;
        return `${formattedDate}`;
    }

    get WeekNumber() {
        return `${this.Week}`;
    }

    get Booked() {
        const newSum = (sum: number, booked: any) => Math.round((sum + booked) * 100) / 100;
        this.IsWeekend();
        return this.DaysOfWeek.reduce((sum: number, current: DayOfWeekStatistics) => newSum(sum, current.Booked), 0);
    }

    get SelectedUser() {
        return this.Users.SelectedUser();
    }

    IsWeekend() {
        _.each(this.DaysOfWeek,
            day => {
                if (moment(day.Date).isoWeekday() === 6 || moment(day.Date).isoWeekday() === 7) {
                    day.Weekend = true;
                }
            });
    }

    IsActive(day: DayOfWeekStatistics) {
        return day.Date.getDate() === this.Date.getDate();
    }

    IsNormBooked(day: DayOfWeekStatistics) {
        return day.Booked >= 8;
    }
}

export class RequestedDate {
    Reservations: KnockoutObservableArray<Reservation>;

    constructor() {
        this.Reservations = ko.observableArray([]);
    }
}

export class Reservation {
    Guid: string;
    Id: number;
    AgendaId: number;
    Start: KnockoutObservable<Date>;
    TimeSpent: KnockoutObservable<Date>;
    To: KnockoutObservable<Date>;
    Subject: KnockoutObservable<Subject>;
    Description: KnockoutObservable<string>;
    TimeStatus: KnockoutObservable<string>;
    Ovw: KnockoutObservable<LookupValue>;
    Pay: KnockoutObservable<LookupValue>;
    HourKind: KnockoutObservable<LookupValue>;
    Accepted: boolean;
    OptionalData: OptionalFieldData[];

    constructor() {

        this.Guid = Guid.NewGuid();
        this.Start = ko.observable(null);
        this.TimeSpent = ko.observable(null);
        this.To = ko.observable(null);
        this.Subject = ko.observable(null);
        this.Description = ko.observable(null);
        this.TimeStatus = ko.observable(null);
        this.Ovw = ko.observable(null);
        this.Pay = ko.observable(null);
        this.HourKind = ko.observable(null);
        this.OptionalData = [];

        this.AfterInit();
    }

    get StartFormat() {
        return moment(this.Start()).format("HH:mm");
    }

    get ToFormat() {
        return moment(this.To()).format("HH:mm");
    }

    get TimeSpentFormat() {
        const timeSpent = moment(this.TimeSpent());
        return timeSpent.format("HH:mm");
    }

    GetOptionalField(fieldName: string) {
        return _.find(this.OptionalData, f => f.FieldName === fieldName);
    }

    GetOptionalValue(fieldName: string) {
        return this.GetOptionalField(fieldName).FieldValue;
    }

    GetTranslations(fieldName: string) {
        return this.GetOptionalField(fieldName).Translations;
    }

    SetOptionalValue(fieldName: string, value: any) {
        const field = this.GetOptionalField(fieldName);
        field.FieldValue(value);
    }

    ChangeDate(date: Date) {
        const startTime = this.Start();
        const startDate = new Date(date.toString());

        startDate.setHours(startTime.getHours(), startTime.getMinutes());
        this.Start(startDate);

        const timeSpent = this.TimeSpent();
        const timeDate = new Date(date.toString());

        timeDate.setHours(timeSpent.getHours(), timeSpent.getMinutes());
        this.TimeSpent(timeDate);
    }

    static ReserveFor(start: Date, timeSpent: Date, subject: Subject, optionalFields: AttachedFieldModel[]) {
        const model = new Reservation();
        model.Start(start);
        model.TimeSpent(timeSpent);
        model.Subject(subject || Subject.NotSpecified());
        model.OptionalData = optionalFields.map(field => new OptionalFieldData({
            FieldName: field.Name,
            FieldType: field.FieldTypeName,
            FieldValue: ko.observable(null),
            Translations: ko.observableArray([])
        }));

        return model;
    }

    private AfterInit() {
        this.BindTimeChangesEvents();
    }

    private BindTimeChangesEvents() {
        this.Start.subscribe(() => this.CalculateTo());
        this.TimeSpent.subscribe(() => this.CalculateTo());
        this.To.subscribe(() => this.CalculateDuration());
    }

    private CalculateTo() {
        if (!this.Start() || !this.TimeSpent()) {
            this.To(null);
            return;
        }

        const start = moment(this.Start());
        const timeSpent = moment(this.TimeSpent());
        const to = moment(start).add(timeSpent.hours(), 'h').add(timeSpent.minutes(), 'm');

        if (!to.isSame(moment(this.To()))) {
            this.To(to.toDate());
        }
    }

    private CalculateDuration() {
        if (!this.Start() || !this.To()) {
            this.TimeSpent(null);
            return;
        }

        const to = moment(this.To());
        const start = moment(this.Start());
        const timeSpent = moment(to).add(-start.hours(), 'h').add(-start.minutes(), 'm');

        if (!timeSpent.isSame(moment(this.TimeSpent()))) {
            this.TimeSpent(timeSpent.toDate());
        }
    }
}

export class Subject {
    EntityId: number;
    EntityName: string;
    SubjectId: number;
    SubjectName: string;
    Icon: Icon;
    PathToRoot: string[];

    private _notSpecified: boolean;

    get SubjectLabel() {
        if (this.PathToRoot) {
            return [...this.PathToRoot.reverse(), this.SubjectName].join(' > ');
        }

        return this.SubjectName;
    }

    constructor(initialization?: Partial<Subject>) {
        this._notSpecified = false;

        if (initialization) {
            this.EntityId = initialization.EntityId;
            this.EntityName = initialization.EntityName;
            this.SubjectId = initialization.SubjectId;
            this.SubjectName = initialization.SubjectName;
            this.Icon = initialization.Icon;
            this.PathToRoot = initialization.PathToRoot;
        }
    }

    GoToRecordScreen() {
        PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, {
            EntityId: this.EntityId,
            RecordId: this.SubjectId,
            IsOpenInModal: true
        });

    }


    get NotSpecified() {
        return this._notSpecified;
    }

    static NotSpecified(): Subject {
        const model = new Subject();

        model.EntityId = 0;
        model.Icon = new Icon(new IconModel());
        model.SubjectName = "";

        model._notSpecified = true;

        return model;
    }
}

export class DayOfWeekStatistics {
    Name: string;
    Date: Date;
    ClosedDate: boolean;
    Booked: any;
    Weekend: boolean;
    Hovered: KnockoutObservable<boolean>;

    constructor() {
        this.Hovered = ko.observable(false);
    }

    get DayDescription() {
        let currentDay = new Date();
        currentDay.setHours(0, 0, 0, 0);

        if (this.Weekend) {
            //if saturday/sunday it will be '-' if not booked
            return this.Booked == 0 ? `${this.Name} - ` : `${this.Name} ${this.Booked}`;
        } else if (this.Date.getTime() > currentDay.getTime() && this.Booked == 0) {
            //future
            return `- ${this.Name}`;
        } else {
            return `${this.Booked} ${this.Name}`;
        }
    }
}

export class LookupField {
    Id: number;
    Name: string;
    RequiresSearch: boolean;
    Values: LookupValue[];
    ValTableId: number;
    ValFieldId: number;
    ValFieldTypeName: string;
    ValFieldFormatName: string;
    ValFieldSize: number;

    constructor() {
        this.Values = [];
    }
}

export class DefaultValue {
    Id: number;
    Name: string;
    TypeName: string;
    DefaultValue: any;

    constructor(partial?: Partial<DefaultValue>) {
        if (partial) {
            this.Id = partial.Id;
            this.Name = partial.Name;
            this.TypeName = partial.TypeName;
            this.DefaultValue = this.ProcessValue(partial.DefaultValue);
        }
    }

    private ProcessValue(value: any): any {
        if (this.TypeName === FIELD_TYPES.DateTime) {
            return new Date(FormatConverter.CorrectTimezone(value));
        }

        if (this.TypeName === FIELD_TYPES.Date) {
            return new Date(value);
        }

        return value;
    }
}

export class TranslatedField {
    Id: number;

    constructor(partial?: Partial<TranslatedField>) {
        if (partial) {
            this.Id = partial.Id;
        }
    }
}

export class LookupValue {
    FieldValue: number;
    DisplayValue: string;

    constructor(partial?: Partial<LookupValue>) {
        if (partial) {
            this.FieldValue = partial.FieldValue;
            this.DisplayValue = partial.DisplayValue;
        }
    }
}

export class OptionalFieldData {
    FieldName: string;
    FieldType: string;
    FieldValue: KnockoutObservable<any>;
    Translations: KnockoutObservableArray<TranslationItem>;

    constructor(partial?: Partial<OptionalFieldData>) {
        if (partial) {
            this.FieldName = partial.FieldName;
            this.FieldType = partial.FieldType;
            this.FieldValue = partial.FieldValue;
            this.Translations = partial.Translations;
        } else {
            this.Translations = ko.observableArray([]);
            this.FieldValue = ko.observable(null);
        }
    }
}

export class AllowedEntity {
    Id: number;
    Name: string;
    Icon: Icon;

    constructor(partial?: Partial<AllowedEntity>) {
        if (partial) {
            this.Id = partial.Id;
            this.Name = partial.Name;
            this.Icon = partial.Icon;
        }
    }
}