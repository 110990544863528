import * as ko from 'knockout';

import {BaseProperty, IPropertyDescription} from '../BaseProperty';
import {IConsultScreenLayoutValue} from './IConsultScreenLayoutValue';

import Template
    from 'Core/GeneralProperties/Managers/ConsultScreenLayout/Templates/ConsultScreenLayoutProperty.html';

ko.templates['Core/GeneralProperties/Managers/ConsultScreenLayout/Templates/ConsultScreenLayoutProperty'] = Template;

export class ConsultScreenLayout extends BaseProperty {
    constructor(property: IPropertyDescription, propertyValue: any) {
        super(property);
        this.Value = ko.observable(propertyValue || this.GetDefaultValue());
    }

    GetTemplateName(): string {
        return 'Core/GeneralProperties/Managers/ConsultScreenLayout/Templates/ConsultScreenLayoutProperty';
    }

    SetValue(propertyValue: any) {
        if (propertyValue) {
            this.Value(propertyValue);
        }
    }

    GetDefaultValue(): IConsultScreenLayoutValue {
        return {
            ActionBar: {
                Checked: true
            },
            MainBlocks: {
                Checked: true,
                Minimized: false
            },
            BottomBar: {
                Checked: true
            }
        };
    }

    ToggleActionBar() {
        this.Value().ActionBar.Checked = !this.Value().ActionBar.Checked;
        this.Value.valueHasMutated();
    }

    ToggleMainBlocks() {
        this.Value().MainBlocks.Checked = !this.Value().MainBlocks.Checked;

        if (!this.Value().MainBlocks.Checked) {
            this.Value().MainBlocks.Minimized = false;
        }

        this.Value.valueHasMutated();
    }

    ToggleMinimizeMainBlocks() {
        this.Value().MainBlocks.Minimized = !this.Value().MainBlocks.Minimized;
        this.Value.valueHasMutated();
    }

    ToggleBottomBar() {
        this.Value().BottomBar.Checked = !this.Value().BottomBar.Checked;
        this.Value.valueHasMutated();
    }
}