const Palette_945d60 = '#945d60';
const Palette_383838 = '#383838';
export enum TextColour {
	Source = Palette_945d60,
	Destination = Palette_945d60,
	Table = Palette_383838,
	Trigger = Palette_383838,
	JSFunction = Palette_383838,
	CSharpFunction = Palette_383838,
	APIMethod = Palette_383838,
	Param = '#fff'
}