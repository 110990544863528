export class TranslationHeader {
	private _columnName: string;

	constructor(columnName: string) {
		this._columnName = columnName;
	}

	get Title(): string {
		return this._columnName;
	}
}