import * as _ from 'underscore'
import {GenericDeserialize} from 'libs/cerialize'
import {URL} from 'Core/Common/Url'
import {Request, IRequestOptions} from 'Core/Common/Request'
import {P} from 'Core/Common/Promise'
import {Deserialize} from 'libs/cerialize'

import {LinkEditorModel} from 'Core/Screens/Common/LinkEditor/Models/LinkEditorModel'

export interface IGetLinkEditorRequestModel {
	MainEntityId: number;
	MainRecordId: number;
	MainRecordTypeId: number;
	MainRecordKindId: number;
	RelatedEntityId: number;
	RelatedRecordId: number;
	Kseq: number;
	IsNew: boolean;
	ErrorMessage?: string;
}

export interface IGetRelationTypesRequestModel {
	MainEntityId: number,
	MainRecordId: number,
	MainRecordTypeId: number,
	RelatedEntityId: number,
	RelatedRecordId: number,
	RelatedRecordTypeId: number,
	SequenceId: number;
}

export class LinkEditorStore {
	static GetData(params: IGetLinkEditorRequestModel): P.Promise<LinkEditorModel> {
		var deferredResult = P.defer<LinkEditorModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.LINK_EDITOR.GET_DATA,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					if (data.ResultObject !== null) {
						deferredResult.resolve(this.Deserialize(data.ResultObject));
					} else {
						deferredResult.resolve(null);
					}
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static GetRelationTypesData(params: IGetRelationTypesRequestModel): P.Promise<any> {
		var deferredResult = P.defer<LinkEditorModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.LINK_EDITOR.GET_RELATION_TYPES,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(data.ResultObject);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static Deserialize(jsonObject: any): LinkEditorModel {
		return GenericDeserialize<LinkEditorModel>(jsonObject, LinkEditorModel);
	}
}