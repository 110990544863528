import * as ko from 'knockout';
import * as $ from "jquery";
import {ZIndexManager} from "../Common/ZIndexManager";

export class AlignDropdown {
	static Init() {
		ko.bindingHandlers.alignDropdown = {
			init: (element, valueAccessor) => {
				const params: AlignDropdownParams = ko.unwrap(valueAccessor());

				const dropdownType = params.type;                       // type of dropdown
				const verticalAlign = params.directions.vertical;       // if dropdown needed to manage vertical position
				const horizontalAlign = params.directions.horizontal;   // if dropdown needed to manage horizontal position
				const addEventOnclick = params.addEventOnclick;         // if need to add on click recalculation to open button
				const dropdownInsideOverflow = params.dropdownInsideOverflow;  //if dropdown located inside overflow: hidden block;

				let $dropdownContainer,               // main dropdown container
					appliedClassNameHorizontal,       // class name for horizontal align
					appliedClassNameVertical,         // class name for vertical align
					$dropdownList,                    // dropdown list (may be same as dropdown container)
					$target,                          // usually button that opens dropdown
					isRightSidebar = $('body').hasClass('page-sidebar-reversed'),
                    $targetParent;

				if (!horizontalAlign && !verticalAlign) {
					return;
				}

				if (dropdownType === 'bootstrap') {
					$target = $(element);
					$targetParent = $(element).parent();
					$dropdownContainer = $target.parent();
					appliedClassNameVertical = 'dropup';
					appliedClassNameHorizontal = 'dropdown-menu-right';
					$dropdownList = $target.next();
				} else if (dropdownType === 'generic') {
					$target = $(element).parent();
					$dropdownContainer = $(params.applyToElement);
					appliedClassNameVertical = 'align-up';
					appliedClassNameHorizontal = 'align-left';
					$dropdownList = $dropdownContainer;
				} else {
					return;
				}

				addEventOnclick && $target.on('click', changeAlignment);

				window.addEventListener('resize', function() {
                    changeAlignment();
                    if (dropdownInsideOverflow) {
                        if ($targetParent.hasClass('open')) {
                            $targetParent.removeClass('open');
                            $targetParent.trigger('hidden.bs.dropdown');
                        }
                    }
				});

				// whether we need to wait for knockout to render dropdown list or not
				if (params.waitForRender) {
					setTimeout(changeAlignment, 0);
				} else {
					changeAlignment();
				}

				function changeAlignment() {
					const windowWidth = isRightSidebar ? $('#mainMenu').offset().left : document.body.clientWidth;
					const windowHeight = document.body.clientHeight;
					const buttonOffsetX = $target.offset().left;
					const buttonOffsetY = $target.offset().top;
					const buttonHeight = $target.height();
					const buttonWidth = $target.width();

					const isMultiDropdown: boolean =
						dropdownType === 'bootstrap' &&
						$dropdownContainer
							.find('.dropdown-menu')
							.children()
							.hasClass('dropdown-submenu');

					const dropdownWidth = $dropdownList.width() > 170 ? $dropdownList.width() : 170;
					const dropdownHeight = $dropdownList.height();

					if (verticalAlign &&
						windowHeight - buttonOffsetY - buttonHeight < dropdownHeight + 30 &&
						buttonOffsetY > dropdownHeight) {
						$dropdownContainer.addClass(appliedClassNameVertical);
					} else if (verticalAlign) {
						$dropdownContainer.removeClass(appliedClassNameVertical);
					}

					if (horizontalAlign && windowWidth - buttonOffsetX - buttonWidth < dropdownWidth) {
						$dropdownList.addClass(appliedClassNameHorizontal);
					} else if (horizontalAlign &&
						isMultiDropdown &&
						windowWidth - buttonOffsetX < 2 * dropdownWidth &&
						buttonOffsetX > dropdownWidth) {
						$dropdownList.addClass(appliedClassNameHorizontal);
					} else if (horizontalAlign) {
						$dropdownList.removeClass(appliedClassNameHorizontal);
					}
            }

				if (dropdownInsideOverflow) {
					const $parent = $(element).parent('.dropdown').parent();

                    $(element).parent('.dropdown').on('show.bs.dropdown', function () {
                    	const $button = $(this).children('.dropdown-toggle');

                    	$(this).parent().css({
							width: $(this).parent().width(),
							height: $(this).parent().height()
						});

                        $button.css({
							padding: $button.css('padding')
						});
                        $('body').append($(this).css({
                            position:'absolute',
                            left: $(this).offset().left,
                            top: $(this).offset().top,
                            'z-index': ZIndexManager.Instance.NextValue
                        }).detach());
                    });

                    $targetParent.on('hidden.bs.dropdown', function() {
                        $parent.append($(this).css({
                            position: 'static'
                        }).detach());
                    });
				}
            }
		};
	}
}

export interface AlignDropdownParams {
	type: string;
	applyToElement?: string;
	directions: {
		horizontal?: boolean;
		vertical?: boolean;
	},
	addEventOnclick?: boolean;
	waitForRender?: boolean;
    dropdownInsideOverflow?: boolean;
}