import * as ko from 'knockout';
import * as _ from 'underscore';

import {Modal} from 'Core/Common/Modal';
import {P} from 'Core/Common/Promise';
import {Event} from 'Core/Common/Event';
import {Notifier} from 'Core/Common/Notifier';
import {BlockUI} from "Core/Common/BlockUi";
import {MobileChecker} from "Core/Common/MobileChecker";

import {LifeStatusGroupEnum, LifeStatusGroups} from "Core/Common/Enums/LifeStatusGroups";

import {PromptDialog, Types} from "Core/Components/Dialogs/PromptDialog/PromptDialog";

import {IActionCheckListParams} from "Core/Components/ActionCheckList/IActionCheckListParams";

import {ActionsCheckListsDto} from "Core/Components/ActionCheckList/Models/Dto/ActionsCheckListsDto";
import {CurrentLifeStatusDto} from "Core/Components/ActionCheckList/Models/Dto/CurrentLifeStatusDto";

import {ActionCheckListStore} from "Core/Components/ActionCheckList/Stores/ActionCheckListStore";

import {ActionsCheckListsMappingProfile} from "Core/Components/ActionCheckList/Mappings/ActionsCheckListsMappingProfile";

import {ActionsCheckListsViewModel} from "Core/Components/ActionCheckList/Models/View/ActionsCheckListsViewModel";
import {ActionCheckListViewModel} from "Core/Components/ActionCheckList/Models/View/ActionCheckListViewModel";
import {LifeStatusesGeneralModel} from "Core/Controls/ButtonFollowUp/Models/LifeStatusesGeneralModel";

import {ActionCheckListDto} from "Core/Components/ActionCheckList/Models/Dto/ActionCheckListDto";
import {CONFIRMATIONS, LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";
import {LIFE_STATUS_GROUPS} from "Core/Constant";
import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS,
    Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import {FollowUpStore} from "Core/Controls/ButtonFollowUp/Stores/FollowUpStore";
import {ICheckItemSerializedValue} from "Core/Components/ActionCheckList/CheckItems/ICheckItemSerializedValue";


import {FlowFolderModel} from "Core/Components/ProgressBar/Models/Response/FlowFolderModel";
import {FlowFolder} from "Core/Controls/ButtonFollowUp/FlowFolder";
import {FlowFolderMemosModel} from "Core/Components/ProgressBar/Models/Response/FlowFolderMemosModel";
import {FlowFolderMemos} from "Core/Controls/ButtonFollowUp/FlowFolderMemos";
import {FlowFolderStore} from "Core/Components/ProgressBar/Stores/FlowFolderStore";
import {MutationObserverConfig, RepositionModalObserver} from "Core/Common/RepositionModalObserver";

//Templates
import Template from 'Core/Components/ActionCheckList/Templates/Template.html';
import KanbanModeTemplate from 'Core/Components/ActionCheckList/Templates/KanbanModeTemplate.html';

ko.templates['Core/Components/ActionCheckList/Templates/Template'] = Template;
ko.templates['Core/Components/ActionCheckList/Templates/KanbanModeTemplate'] = KanbanModeTemplate;

export class ActionCheckList extends Event {
    private _modal: Modal;
    private _viewModel: ActionsCheckListsViewModel;
    private _labels = LABELS;
    private _oldCheckStatuses: ActionCheckListDto[];
    private _iCheckItemsModify: ICheckItemSerializedValue[];
    private _isModifyItems: boolean;
    private _isFlowFolder: boolean;
    private _isFollowUpDisabled: boolean;
    private _disabledReason: string;
    private _flowFolder: FlowFolder;
    private _flowFolderMemos: KnockoutObservable<FlowFolderMemos>;
    private _entityId: number;
    private _recordId: number;
    private _observer: RepositionModalObserver;
    private _kanbanMod: boolean;
    private _retireChildren: boolean;
    private _isMobile: MobileChecker;

    constructor(private _params: IActionCheckListParams) {
        super();
        this._isFlowFolder = null;
        this._isFollowUpDisabled = null;
        this._disabledReason = null;
        this._flowFolder = null;
        this._flowFolderMemos = ko.observable(null);
        this._entityId = this._params.EntityId;
        this._recordId = this._params.RecordId;
        this._observer = null;
        this._kanbanMod = !!this._params.KanbanMod;
        this._retireChildren = false;
        this._isMobile = MobileChecker.IsMobile();
        this.AddEvents();
        this.PreventWindowConfirmation();
    }

    AddEvents() {
        this.AddEvent('ON_CHANGED_PAGE_LEAVING');
        this.AddEvent('LIFESTATUS_CHOOSEN');
        this.AddEvent('KANBAN_CHECKLIST_CANCEL');
        this.AddEvent('KANBAN_CHECKLIST_SAVE');
    }

    FlowMemoTab(data, evt){
        BlockUI.Block({ Target: $(evt.currentTarget).closest('.jBox-content')[0], JBoxBlockButton: true});
        FlowFolderStore.GetFlowMemos({EntityId: this._entityId, RecordId: this._recordId})
            .always(()=>BlockUI.Unblock($(evt.currentTarget).closest('.jBox-content')[0], true))
            .then(data => {
                let flowFolderMemosModel = new FlowFolderMemosModel();

                flowFolderMemosModel.IsMemoTranslatable = data.IsMemoTranslatable
                flowFolderMemosModel.MemoItems = data.MemoItemModels;

                this._flowFolderMemos(new FlowFolderMemos(flowFolderMemosModel, {EntityId: this._entityId, RecordId: this._recordId}));
                this._flowFolderMemos().On('DELETE_FLOW_FOLDER_MEMO_RECORD', this, (eventArgs)=> {
                    if (this._modal){
                        this._modal.OneRepositionModal();
                    }
                })
                this._flowFolderMemos().On('CHANGE_FLOW_FOLDER_MEMO_RECORD', this, (eventArgs)=> {
                    if (this._modal){
                        this._modal.OneRepositionModal();
                    }
                })
            })
    }

    IsModifyItems(): boolean {
        this._isModifyItems = false;
        let oldCheckStatuses = this._oldCheckStatuses;

        _.each(oldCheckStatuses, (oldCheckStatus, key) => {
            _.each(oldCheckStatus.CheckItems, (oldCheckItem, key) => {
                let newCheckStatuses = this._viewModel.CheckStatuses.map(checkStatus => checkStatus.SerializeData());

                _.each(newCheckStatuses, (newCheckStatus, key) => {
                    this._iCheckItemsModify = _.filter(newCheckStatus.CheckItems, (newCheckItem) => {
                        const oldID = oldCheckItem.Id,
                            newID = newCheckItem.Id,
                            oldValue = oldCheckItem.Value,
                            newValue = newCheckItem.Value === '' ? newCheckItem.Value = null : newCheckItem.Value;

                        return (oldID === newID) && (oldValue !== newValue);
                    });

                    if (this._iCheckItemsModify.length != 0) {
                        this._isModifyItems = true;
                    }
                });

            });
        });
        return this._isModifyItems;
    }

    InitFlowFolder(isFlowFolder: boolean, _entityId: number, flowFolderModel?: FlowFolderModel, followUpData?: LifeStatusesGeneralModel){
        this._isFlowFolder = isFlowFolder;

        if (followUpData) {
            this._isFollowUpDisabled = followUpData.IsFollowUpDisabled;
            this._disabledReason = followUpData.DisabledReason;
        }

        if (this._isFlowFolder) {
            this._flowFolder = new FlowFolder();
            this._flowFolder.Init(this._isFlowFolder, this._entityId, flowFolderModel);
            this._flowFolder.On('CLOSE_FOLLOW_UP_LIFE_STATUSES', this, (eventArgs)=> {
                this.Close();
            });
        }
    }

    PreventWindowConfirmation() {
        const eventWindowConfirmation = (event) => {
            if (this.IsModifyItems()) {
                // Cancel the event as stated by the standard.
                event.preventDefault();
                // Chrome requires returnValue to be set.
                event.returnValue = 'Would you like to leave the page?';
                return 'Would you like to leave the page?'
            } else {
                this.Trigger('ON_CHANGED_PAGE_LEAVING');
            }
        };

        window.addEventListener('beforeunload', eventWindowConfirmation);

        this.On('ON_CHANGED_PAGE_LEAVING', this, () => {
            window.removeEventListener('beforeunload', eventWindowConfirmation);
        });
    }

    GetTemplateName() {
        if (this._kanbanMod) {
            return 'Core/Components/ActionCheckList/Templates/KanbanModeTemplate';
        } else {
            return 'Core/Components/ActionCheckList/Templates/Template';
        }
    }

    AfterRender(el: HTMLElement): void {

    }

    HasAnyCheckedStatuses() {
        return this._viewModel && this._viewModel.CheckStatuses.length > 0;
    }

    Show() {
        let self = this;
        this._modal = new Modal({
            addClass: 'requestCheckListPopup',
            minWidth: 350,
            minHeight: 150,
            maxHeight: '85vh',
            maxWidth: '60vw',
            closeOnEsc: false,
            blockScroll: true,
            onOpen: function () {
                $(this.closeButton[0]).off('click');
                this.closeButton[0].addEventListener('click', self.CloseModal.bind(self));

                const target: HTMLElement = this.wrapper.find('.jBox-content')[0];
                const mutationObserverConfig: MutationObserverConfig = {
                    attributes: true,
                    childList: true,
                    characterData: true,
                    subtree: true,
                }
                self._observer = new RepositionModalObserver(target, mutationObserverConfig);
            }
        }, false);

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);

        this._modal.Show();
    }

    CloseModal(evt) {
        evt.preventDefault();
        if (this._modal) {
            this._observer && this._observer.Disconnect();
            this._modal.Close();

            if (this._kanbanMod){
                this.Trigger('ON_CHANGED_PAGE_LEAVING');
                this.Trigger('KANBAN_CHECKLIST_CANCEL');
                this.SaveCheckList();
            } else {
                this.Trigger('ON_CHANGED_PAGE_LEAVING');
                this.SaveCheckList();
            }

        }
    }

    ProvideData(checkLists: ActionCheckListDto[]) {
        let newCheckLists = checkLists;
        _.forEach(newCheckLists, (list) => {
            _.forEach(list.CheckItems, (item) => {
                if (item.TypeName === 'YesNo' && item.Value === '0') {
                    item.Value = false;
                }
            });
        });
        this._oldCheckStatuses = newCheckLists;

        const actionsCheckListsDto = this.ExtendDto(checkLists);

        this._viewModel = ActionsCheckListsMappingProfile.MapToViewModel(actionsCheckListsDto);
        this._viewModel.FollowUpMode = this._params.FollowUpMode;
    }

    LoadData() {
        BlockUI.Block();

        const deferred = P.defer();

        ActionCheckListStore.GetActionsCheckLists(this._params.EntityId, this._params.RecordId)
            .then(checkLists => {
                this.ProvideData(checkLists);
                BlockUI.Unblock();
                deferred.resolve(null);
            })
            .fail(error => {
                new Notifier().Failed(error.message);
                BlockUI.Unblock();

                deferred.reject(error);
            });

        return deferred.promise();
    }

    TryFollowUpKanbanModCheckStatus(status: ActionCheckListViewModel, disable?: boolean){
        if(disable) {
            return;
        }

        this.EnableValidation();

        if (!status.Valid()) {
            return;
        }
        const lifeStatusesSerializedData = this._viewModel.CheckStatuses.map(checkStatus => checkStatus.SerializeData());
        this.Trigger('KANBAN_CHECKLIST_SAVE', {serializedData: lifeStatusesSerializedData});
        if (this._modal) {
            this._observer && this._observer.Disconnect();
            this._modal.Close();
            this.Trigger('ON_CHANGED_PAGE_LEAVING');
        }
    }

    TryFollowUpKanbanModCurrentLifeStatus(status: ActionCheckListViewModel, disable?: boolean) {
        if(disable) {
            return;
        }

        this.Trigger('KANBAN_CHECKLIST_CANCEL');
        this.SaveCheckList();
        if (this._modal) {
            this._observer && this._observer.Disconnect();
            this._modal.Close();
            this.Trigger('ON_CHANGED_PAGE_LEAVING');
        }
    }

    TryFollowUp(status: ActionCheckListViewModel, disable?: boolean) {
        if(disable) {
            return;
        }

        this.EnableValidation();

        if (!this._params.FollowUpMode) {
            return;
        }

        if (!status.Valid()) {
            return;
        }

        if (LifeStatusGroups.GetLifeStatusGroup(status.LifeStatusSort) === LifeStatusGroupEnum.Retired) {
            this.CheckRetiredChildren(status);
            return;
        }

        this.PickLifeStatus(status);
    }

    EnableValidation() {
        this._viewModel.CheckStatuses.forEach(lifeStatus => lifeStatus.EnableValidation());
    }

    private ExtendDto(checkLists: ActionCheckListDto[]) {
        const extended = new ActionsCheckListsDto();

        if (this._params.FollowUpMode) {
            extended.CurrentLifeStatus = new CurrentLifeStatusDto({
                Id: this._params.CurrentLifestatus.Id,
                Name: this._params.CurrentLifestatus.Name,
                Memo: this._params.CurrentLifestatus.Memo,
                MemoTranslation: this._params.CurrentLifestatus.MemoTranslation,
                LifeStatusTranslatedName: this._params.CurrentLifestatus.TranslatedName,
                LifestatusNoActionNode: this._params.CurrentLifestatus.NoActionNode,
                DisabledReason: this._params.CurrentLifestatus.DisabledReason,
                IsFollowUpDisabled: this._params.CurrentLifestatus.IsFollowUpDisabled
            });
        }

        extended.LifeStatuses = checkLists || [];

        return extended;
    }

    Close() {
        if (this._modal) {
            this.Trigger('ON_CHANGED_PAGE_LEAVING');
            this._observer && this._observer.Disconnect();
            this._modal.Close();
        } else {
            this.Trigger('ON_CHANGED_PAGE_LEAVING');
        }
    }

    PickLifeStatus(lifeStatus: ActionCheckListViewModel) {
        const confirmationText = CONFIRMATIONS.ARE_SURE_TO_CHANGE_LIFESTATUS
            .replace('{fromstatus}', this._params.CurrentLifestatus.Name)
            .replace('{tostatus}', lifeStatus.LifeStatusName);

        const confirmationDialog = new ConfirmationDialog({
            Text: confirmationText,
            Type: ConfirmationTypes.Question
        });

        confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED,
            this,
            () => {
                this.UpdateLifeStatus(lifeStatus);
            });

        if (lifeStatus.LifeStatusId === this._params.CurrentLifestatus.Id) {
            this.UpdateLifeStatus(lifeStatus);
            return;
        }

        const approvalName = lifeStatus.LifeStatusApprovalName || 'Off';
        switch (approvalName) {
            case 'Off':
                this.UpdateLifeStatus(lifeStatus);
                break;
            case 'Password':
                const passwordPrompt = new PromptDialog(
                    {
                        Type: Types.Password,
                        Label: CONFIRMATIONS.PLEASE_TYPE_YOUR_PASSWORD_TO_CONFIRM,
                        Value: '',
                        MinHeight: 200,
                        Required: true,
                        RequiredErrorMessage: this._labels.PASSWORD + ' ' + NOTIFICATIONS.IS_REQUIRED,
                        ShowNotification: false,
                        CloseOnSave: false
                    });
                passwordPrompt.Show();

                passwordPrompt.On('Save', this,
                    (eventargs) => {
                        const password = eventargs.data.value;
                        if (password) {
                            BlockUI.Block();

                            FollowUpStore.ConfirmPassword({password: password})
                                .always(() => {
                                    BlockUI.Unblock();
                                })
                                .then((response) => {
                                    if (response) {
                                        passwordPrompt.Hide();
                                        this.UpdateLifeStatus(lifeStatus, password);
                                    } else {
                                        passwordPrompt.ExternalInvalidActionHandling(NOTIFICATIONS.INCORRECT_PASSWORD);
                                    }
                                })
                                .fail(error => {
                                    new Notifier().Failed(error);
                                });
                        }
                    }
                );
                break;

            case 'Yes/No':
                confirmationDialog.Show();
                break;
        }

    }

    UpdateLifeStatus(lifeStatus: ActionCheckListViewModel, password?) {
        this.SaveCheckList().then(() => {
            this.Trigger('ON_CHANGED_PAGE_LEAVING');
            this.Trigger('LIFESTATUS_CHOOSEN', {
                Option: lifeStatus,
                LifeStatus: lifeStatus,
                Password: password,
                RetireChildren: this._retireChildren
            });

            this._modal.Close();
        });
    }

    SaveCheckList() {
        BlockUI.Block();

        const lifeStatusesSerializedData = this._viewModel.CheckStatuses.map(checkStatus => checkStatus.SerializeData());

        const updateCheckListPromise = ActionCheckListStore.UpdateActionsCheckList({
            EntityId: this._params.EntityId,
            RecordId: this._params.RecordId,
            LifeStatuses: lifeStatusesSerializedData
        });

        updateCheckListPromise
            .fail(error => {
                new Notifier().Failed(error.message)
            }).always(() => BlockUI.Unblock());

        return updateCheckListPromise;
    }

    CheckRetiredChildren(status: ActionCheckListViewModel) {
        BlockUI.Block();
        FollowUpStore.CheckRetiredChildren({
            ParentEntityId: this._params.EntityId,
            ParentRecordId: this._params.RecordId
        })
            .always(() => {
                BlockUI.Unblock();
            })
            .then((data) => {
                if (!data.ChildrenToRetireExist) {
                    this.PickLifeStatus(status);
                    return;
                }

                const retiredStatuses = data.RetiredStatuses.join(', ');
                const confirmationText = CONFIRMATIONS.ALL_CHILD_RECORDS_WILL_BE_MOVED_TO_THE_RETIRED_STATUSES
                    .replace('{RetiredStatuses}', retiredStatuses);

                const confirmationDialog = new ConfirmationDialog({
                    Text: confirmationText,
                    Type: ConfirmationTypes.Question,
                    TextConfirm: LABELS.YES,
                    TextDecline: LABELS.NO
                });

                confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED,
                    this,
                    () => {
                        this._retireChildren = data.ChildrenToRetireExist;
                        this.PickLifeStatus(status);
                    });

                confirmationDialog.Show();
            })
            .fail(error => {
                new Notifier().Failed(error);
            });
    }
}