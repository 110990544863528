import {AssigneeMappings} from "Core/Controls/FieldSecurity/Mappings/AssigneeMappings";

import {FieldCollectionDTOModel} from "Core/Controls/FieldSecurity/Shared/Models/DTO/Get/FieldCollectionDTOModel";
import {FieldCollectionModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/FieldCollectionModel";

export class FieldCollectionMappings {
	static MapToFieldCollection(fieldCollectionDto: FieldCollectionDTOModel): FieldCollectionModel {
		const assignee = fieldCollectionDto.Assignee && AssigneeMappings.MapToAssignee(fieldCollectionDto.Assignee);
		return new FieldCollectionModel(fieldCollectionDto.Id, fieldCollectionDto.Name, assignee);
	}
}