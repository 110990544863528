import ko from "knockout";
export interface ConversionDropdownViewValue {
    Value: string;
    IsActive: KnockoutObservable<boolean>;
    IsDisabled: KnockoutObservable<boolean>;
}

export class ConversionDropdownView {
    FieldId: number;
    FieldName: string;
    FieldNameTranslation: string;
    FieldType: string;
    Values: KnockoutObservableArray<ConversionDropdownViewValue>;
    SelectValue: KnockoutObservable<string>;
    IsOpenDropdown: KnockoutObservable<boolean>;
    IsGridMode: boolean;
    IsDisabledValuesGroup: KnockoutObservable<boolean>;
    private _message: KnockoutObservable<string>;
    constructor() {
        this.SelectValue = ko.observable(null);
        this.IsOpenDropdown = ko.observable(false);
        this.Values = ko.observableArray([]);
        this.IsGridMode = false;
        this.IsDisabledValuesGroup = ko.observable(null);
        this._message = ko.observable(null);
    }

    SetMessage(value: string): void{
        this._message(value);
    }

    ResetMessage(): void {
        this._message(null);
    }


    get TranslationFieldName(): string {
        return this.FieldNameTranslation || this.FieldName;
    }
}