import {deserialize, deserializeAs} from 'libs/cerialize';

export class InvoiceTable {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	TranslatedName: string;

	@deserialize
	PrimaryFieldId: number;

	@deserialize
	PrimaryFieldName: string;
}

export class LifeStatusModel {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	TranslatedName: string;

	@deserialize
	Memo: string;

	@deserialize
	MemoTranslation: string;
}

export class InvoiceType {
	@deserialize
	Id: number;

	@deserialize
    Name: string;

    @deserialize
    TranslatedName: string;

	@deserialize
    TableId: number;

    GetName(): string {
        return this.TranslatedName ? this.TranslatedName : this.Name
    }
}

export class InvoicingControlConfigOptions {
	@deserialize
	Issue: string;

	@deserializeAs(InvoiceTable)
	SourceTables: InvoiceTable[];

	@deserializeAs(LifeStatusModel)
	SourceLifeStatuses: LifeStatusModel[];

	@deserializeAs(InvoiceTable)
	DestinationTables: InvoiceTable[];

	@deserializeAs(InvoiceType)
	DestinationTypes: InvoiceType[];
}