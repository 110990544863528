import {ActionCheckListDto} from "./ActionCheckListDto";
import {CurrentLifeStatusDto} from "./CurrentLifeStatusDto";

export class ActionsCheckListsDto {
    CurrentLifeStatus: CurrentLifeStatusDto;
    LifeStatuses: ActionCheckListDto[];

    constructor() {
        this.LifeStatuses = [];
    }
}