import clone from 'clone';

import { ModelState } from "Core/Common/Enums/ModelState";
import { Guid } from "Core/Common/Guid";
import { BaseModel } from "DatabaseDesigner/Models/BaseModel";
import { CollectionModel } from "DatabaseDesigner/Models/CollectionModel";
import { KindModel } from "DatabaseDesigner/Models/KindModel";
import { LifeStatusModel } from "DatabaseDesigner/Models/LifestatusModel";
import { RelationTypeFiltersModel } from "DatabaseDesigner/Models/RelationTypeFiltersModel";
import { RelationTypeValueModel } from "DatabaseDesigner/Models/RelationTypeValueModel";
import { TranslationModel } from "DatabaseDesigner/Models/TranslationModel";
import { TypeRestrictionModel } from "DatabaseDesigner/Models/TypeRestrictionModel";
import { LifecycleDto } from 'Core/LifecycleDesigner/Models/DTO/LifecycleDto';
import { GenericDeserialize } from '../../libs/cerialize';
import { LifecycleStepDto } from 'Core/LifecycleDesigner/Models/DTO/LifecycleStepDto';
import { LIfecycleLinkDto } from 'Core/LifecycleDesigner/Models/DTO/LifecycleLinkDto';

export function TableTypeModel() {
    this._id = null;
    this._parentId = 0;
    this._parentGuid = '';
    this._flowFolder = false;
    this._treeLevel = 0;
    this._memo = '';
    this._instancingMaximum = 1;
    this._name = 'New Type';
    this._uniqueGuid = Guid.NewGuid();
    this._uniqueParentGuid = '';
    this._icon = null;
    this._iconId = null;
    this._iconImage = null;
    this._iconType = null;
    this._lifeStatus = -1;
    this._lifestatusDesignObject = null;
    this._lifeStatuses = new CollectionModel({ model: LifeStatusModel });
    this._kinds = new CollectionModel({ model: KindModel });
    this._values = new CollectionModel({ model: RelationTypeValueModel });
    this._filters = new CollectionModel({ model: RelationTypeFiltersModel });
    this._restrictions = new CollectionModel({ model: TypeRestrictionModel });
    this._currentKindId = 0;
    this._isRoot = false;
    this._savedLifestatus = this._lifeStatus;
    this._translations = new CollectionModel({ model: TranslationModel });
    BaseModel.apply(this);
};

TableTypeModel.prototype = Object.create(BaseModel.prototype);
TableTypeModel.prototype.constructor = TableTypeModel;

TableTypeModel.prototype.FromJSONArray = function (types) {
    var typeList = new CollectionModel({ model: TableTypeModel });
    _.each(types, function (type: any) {
        var existsModel = _.find(typeList.Items, function (item: any) {
            return item.Id === type.Id;
        });
        var model = new TableTypeModel();
        if (existsModel) {
            model.Guid = existsModel.Guid;
        }
        model.FromJSON(type);
        typeList.Add(model);
    });
    return typeList;
};

TableTypeModel.prototype.toJSON = function (onlyModified) {
    var result = {
        Id: this.Id,
        Name: this.Name,
        ParentId: this.ParentId,
        ParentGuid: this.ParentGuid,
        Guid: this.Guid,
        IsModified: this.IsModified,
        FlowFolder: this.FlowFolder,
        LifeStatus: this.LifeStatus,
        LifeStatuses: this.LifeStatuses.toJSON(),
        Icon: this.Icon,
        IconId: this.IconId,
        IconImage: this.IconImage,
        IconType: this.IconType,
        LifestatusDesignObject: this.LifestatusDesignObject,
        RoleLifes: this.RoleLifes,
        Memo: this.Memo,
        Kinds: this.Kinds.toJSON(),
        Values: this.Values.toJSON(),
        Restrictions: this.Restrictions.toJSON(),
        Filters: this.Filters.GetItemsWhereIn('State', [ModelState.New, ModelState.Changed, ModelState.Deleted]).map(function (filter) {
            return filter.toJSON()
        }),
        CurrentKindId: this.CurrentKindId,
        IsRoot: this.IsRoot,
        IsLifestatusModified: this._savedLifestatus !== this._lifeStatus,
        Translations: this.Translations.toJSON(),
        InstancingMaximum: this.InstancingMaximum
    };

    if (onlyModified && onlyModified === true) {
        if (this._isModified !== true) {
            result = null;
        }
    }
    return result;
}

TableTypeModel.prototype.Clone = function () {
    return clone(this);
}

TableTypeModel.prototype.FromJSON = function (data) {
    this._id = data.Id;
    this._name = data.Name;
    this._parentId = data.ParentId;
    this._flowFolder = data.FlowFolder;
    this._lifeStatus = data.LifeStatus;
    this._savedLifestatus = data.LifeStatus;
    this._icon = data.Icon;
    this._iconId = data.IconId;
    this._iconImage = data.IconImage;
    this._iconType = data.IconType;
    this._memo = data.Memo;
    this._instancingMaximum = data.InstancingMaximum;
    this._kinds = KindModel.prototype.FromJSONArray(data.Kinds);
    this._restrictions = TypeRestrictionModel.FromJSONArray(data.Restrictions);
    this._values = RelationTypeValueModel.prototype.FromJSONArray(data.Values);
    this._filters = RelationTypeFiltersModel.prototype.FromJSONArray(data.Filters);
    this._lifeStatuses = LifeStatusModel.prototype.FromJSONArray(data.LifeStatuses);
    this._currentKindId = data.CurrentKindId;
    this._translations = TranslationModel.prototype.FromJSONArray(data.Translations);
    this._lifestatusDesignObject = GenericDeserialize<LifecycleDto>(data.LifestatusDesignObject, LifecycleDto);

    if (this._lifestatusDesignObject && this._lifestatusDesignObject.Steps) {
        _.each(this._lifestatusDesignObject.Steps, (step: LifecycleStepDto)=>{

            const lifeStatusItem = _.find(this._lifeStatuses.Items, (status: any) => status.Id === step.Id);
            if(lifeStatusItem){
                step.LifeStatusGuid = lifeStatusItem.Guid;
            }

            _.each(this._lifestatusDesignObject.Links, (link: LIfecycleLinkDto)=>{
                if(link.IdStepFrom === step.Id){
                    link.GuidStepFrom = step.Guid;
                }
                if(link.IdStepTo === step.Id){
                    link.GuidStepTo = step.Guid;
                }
            });
        });
    }

    this._isRoot = data.IsRoot;
}

TableTypeModel.prototype.CreateDefaultTranslations = function (languages) {
    if (languages) {
        var self = this;
        self._translations = new CollectionModel({ model: TranslationModel });

        _.forEach(languages, function (language) {
            var translation = new TranslationModel();
            translation.Language = language;
            self._translations.Add(translation);
        });
    }
}

Object.defineProperty(TableTypeModel.prototype, 'Id', {
    enumerable: true,
    get: function () {
        return this._id;
    },
    set: function (val) {
        this._id = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'Kinds', {
    enumerable: true,
    get: function () {
        return this._kinds;
    },
    set: function (val) {
        this._kinds = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'Values', {
    enumerable: true,
    get: function () {
        return this._values;
    },
    set: function (val) {
        this._values = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'Filters', {
    enumerable: true,
    get: function () {
        return this._filters;
    },
    set: function (val) {
        this._filters = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'Restrictions', {
    enumerable: true,
    get: function () {
        return this._restrictions;
    },
    set: function (val) {
        this._restrictions = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'LifeStatuses', {
    enumerable: true,
    get: function () {
        return this._lifeStatuses;
    },
    set: function (val) {
        this._lifeStatuses = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'CurrentKindId', {
    enumerable: true,
    get: function () {
        return this._currentKindId;
    },
    set: function (val) {
        this._currentKindId = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'Name', {
    enumerable: true,
    get: function () {
        return this._name;
    },
    set: function (val) {
        this._name = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'LifeStatus', {
    enumerable: true,
    get: function () {
        return this._lifeStatus;
    },
    set: function (val) {
        this._lifeStatus = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'Memo', {
    enumerable: true,
    get: function () {
        return this._memo;
    },
    set: function (val) {
        this._memo = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'InstancingMaximum', {
    enumerable: true,
    get: function () {
        return this._instancingMaximum;
    },
    set: function (val) {
        this._instancingMaximum = val;
    }
});


Object.defineProperty(TableTypeModel.prototype, 'FlowFolder', {
    enumerable: true,
    get: function () {
        return this._flowFolder;
    },
    set: function (val) {
        this._flowFolder = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'ParentId', {
    enumerable: true,
    get: function () {
        return this._parentId;
    },
    set: function (val) {
        this._parentId = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'ParentGuid', {
    enumerable: true,
    get: function () {
        return this._parentGuid;
    },
    set: function (val) {
        this._parentGuid = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'TreeLevel', {
    enumerable: true,
    get: function () {
        return this._treeLevel;
    },
    set: function (val) {
        this._treeLevel = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'UniqueGuid', {
    enumerable: true,
    get: function () {
        return this._uniqueGuid;
    },
    set: function (val) {
        this._uniqueGuid = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'UniqueParentGuid', {
    enumerable: true,
    get: function () {
        return this._uniqueParentGuid;
    },
    set: function (val) {
        this._uniqueParentGuid = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'Icon', {
    enumerable: true,
    get: function () {
        return this._icon;
    },
    set: function (val) {
        this._icon = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'IconId', {
    enumerable: true,
    get: function () {
        return this._iconId;
    },
    set: function (val) {
        this._iconId = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'LifestatusDesignObject', {
    enumerable: true,
    get: function () {
        return this._lifestatusDesignObject;
    },
    set: function (val) {
        this._lifestatusDesignObject = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'IconImage', {
    enumerable: true,
    get: function () {
        return this._iconImage;
    },
    set: function (val) {
        this._iconImage = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'IconType', {
    enumerable: true,
    get: function () {
        return this._iconType;
    },
    set: function (val) {
        this._iconType = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'IsRoot', {
    get: function () {
        return this._isRoot;
    },
    set: function (val) {
        this._isRoot = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'Translations', {
    enumerable: true,
    get: function () {
        return this._translations;
    },
    set: function (val) {
        this._translations = val;
    }
});

Object.defineProperty(TableTypeModel.prototype, 'TypeNameMaxSize', {
    enumerable: true,
    get: function () {
        return this._typeNameMaxSize;
    },
    set: function (val) {
        this._typeNameMaxSize = val;
    }
});
