import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

import {LockManager} from "Core/Components/Locker/LockManager";

export const EVENTS_TO_LISTEN = {
	CLICK: 'click',
	KEYUP: 'keyup'
};

export class EventTracker {
	private static _instance: EventTracker;

	private constructor() {
	}

	static get Instance() {
		if (!EventTracker._instance) {
			EventTracker._instance = new EventTracker();
		}

		return EventTracker._instance;
	}

	ListenEvents() {
		Object.keys(EVENTS_TO_LISTEN).forEach(eventKey => {
			const domEvent = EVENTS_TO_LISTEN[eventKey];
			document.addEventListener(domEvent, () => this.RegisterEvent(domEvent));
		});
	}

	RegisterEvent(eventType: string) {
		LockManager.Instance.RegisterEvent();
	}
}