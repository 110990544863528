import {UserResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Response/UsersResponseModel";
import {UserViewModel} from "Core/Components/Controls/TimeWriting/Models/View/UsersViewModel";

export class UserMappings {
	static OnViewModel(user: UserResponseModel) {
		const userViewModel = new UserViewModel();

		userViewModel.Id = user.Id;
		userViewModel.Name = user.Name;

		return userViewModel;
	}
}