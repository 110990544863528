import * as ko from 'knockout';

import {LABELS} from 'Core/Components/Translation/Locales';
import {PortletSpace} from "Core/Portlets/Models/Runtime/PortletSpace"

export class PortletSpaceEditorModel {
    PortletSpaceName: KnockoutObservable<string>;

    ShowCopyFromDropdown: KnockoutComputed<boolean>;
    CopyFromPortletSpace: KnockoutObservable<PortletSpace>;
    AllPortletSpaces: KnockoutObservableArray<PortletSpace>;

    constructor() {
        this.PortletSpaceName = ko.observable('').extend({
            required: {
                message: LABELS.PLEASE_ENTER_PORTLET_SPACE_NAME
            }
        });

        this.AllPortletSpaces = ko.observableArray([]);
        this.CopyFromPortletSpace = ko.observable(null);
        this.ShowCopyFromDropdown = ko.computed(() => _.any(this.AllPortletSpaces()));
    }
}