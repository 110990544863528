import * as ko from 'knockout';
import * as _ from 'underscore';
import {SearchFieldModel} from "Core/Screens/SearchScreen/Models/SearchFieldModel";
import { SearchTypes } from "Core/Screens/SearchScreen/Enums/Enums";
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";
import Exception from '../../../Results/Exceptions/Base/Exception';


export class SearchGroupItemModel {
	constructor(public Id: number, public Name: string, public DefaultName: string, private _searchType: SearchTypes, public Disabled: boolean = false) {

	}

	get SearchType() {
		return this._searchType;
	}
}

export abstract class SearchGroupModel {
	protected Items: KnockoutObservableArray<SearchGroupItemModel>;

	constructor(public Name: string) {
		this.Items = ko.observableArray();
	}

	abstract AddItem(id: number, name: string, defaultName: string);

	GetItem(name: string) {
		let item = _.find(this.Items(), (item: SearchGroupItemModel) => item.Name === name);

		return item || null;
	}

	HasItem(name: string): boolean {
		return this.GetItem(name) !== null;
	}
}

class SearchModeGroupModel extends SearchGroupModel {
	constructor() {
		super(LABELS.SEARCH_MODES);

		this.Items([
			new SearchGroupItemModel(0, LABELS.FAST_SEARCH, null, SearchTypes.FastSearch),
			new SearchGroupItemModel(0, LABELS.TOTAL_SEARCH, null, SearchTypes.TotalSearch),
			new SearchGroupItemModel(0, LABELS.PHONETIC_SEARCH,null, SearchTypes.PhoneticSearch)
		]);
	}

	AddItem(id: number, name: string, defaultName: string) {
		//this.Items.push(new SearchGroupItemModel(id, name, SearchTypes.None));
	}
}

class FieldSearchGroupModel extends SearchGroupModel {
	constructor() {
		super(LABELS.SEARCH_FIELDS);
	}

	AddItem(id: number, name: string, defaultName: string) {
		this.Items.push(new SearchGroupItemModel(id, name, defaultName, SearchTypes.FieldSearch));
	}
}

export class SearchModeModel {
	private _groups: Array<SearchGroupModel>;

	constructor() {
		this._groups = [new SearchModeGroupModel(), new FieldSearchGroupModel()];
	}

	AddSearchFields(...searchFields: Array<SearchFieldModel>) {
		let searchFieldGroup = this._groups[1]//this.GetGroup(LABELS.SEARCH_FIELDS);
		searchFields.forEach(searchField => searchFieldGroup.AddItem(searchField.Id, searchField.Name, searchField.DefaultName));
	}

	GetSearchItem(name: string) {
		let groupWithItem = _.find(this._groups, group => group.HasItem(name));

		if (groupWithItem) {
			return groupWithItem.GetItem(name);
		}

		return null;
	}

	GetDefaultMode(): SearchGroupItemModel {
		return new SearchGroupItemModel(0, LABELS.FAST_SEARCH, null, SearchTypes.FastSearch);
	}

	private GetGroup(name: string): SearchGroupModel {
		return _.find(this._groups, (group: SearchGroupModel) => group.Name === name);
	}

}