import * as ko from 'knockout';
import * as _ from 'underscore';
import * as $ from "jquery";
import * as moment from "moment";

import {BaseControl} from 'Core/Controls/BaseControl/BaseControl';
import {IControlParam} from 'Core/Screens/IScreen';
import {IControl} from "../IControl";
import {TranslationManager} from "../../Components/Translation/TranslationManager";

import {IGetPlannerLevelDataRequestModel, PlannerLevelStore} from "./Store/PlannerLevelStore";
import {BlockUI} from "../../Common/BlockUi";
import {PlannerLevelRowModel, TimeCellModel} from "./Models/PlannerLevelRowModel";
import {CONTROL_TYPES, RenderModes, FIELD_TYPES} from "../../Constant";
import {Icon} from "../../Icon/Icon";
import {EntityTypes} from "../Grid/BaseGrid/Enums/EntityTypes";
import {GeneralProperties} from "../../GeneralProperties/GeneralProperties";
import {SearchScreen} from "../../Screens/SearchScreen/SearchScreen";
import {LABELS, NOTIFICATIONS} from 'Core/Components/Translation/Locales';
import {TypeScreen} from "../../Screens/TypeScreen/TypeScreen";
import {Notifier} from "../../Common/Notifier";
import {CustomColumnValueModel} from "./Models/CustomColumnValueModel";
import {CustomColumnHeaderModel} from "../PlannerPage/Models/CustomColumnHeaderModel";
import {RightBarRow} from "Core/Controls/PlannerLevel/RightBarRow/RightBarRow";
import {EVENTS} from "Core/Controls/PlannerLevel/Events";
import {EVENTS as HIGHER_RESOLUTION_EVENTS} from 'Core/Controls/PlannerLevel/HigherResolutionPlanning/Events';

import Config from 'Core/Controls/PlannerLevel/Configs/planner-level-config.json';
import DesignTemplate from 'Core/Controls/PlannerLevel/Templates/Design.html';
import RightBarViewTemplate from 'Core/Controls/PlannerLevel/Templates/RightBarView.html';
import LeftBarViewTemplate from 'Core/Controls/PlannerLevel/Templates/LeftBarView.html';
import {KEY_CODES} from "./KeyCodes";
import {RightBarCell} from "./RightBarCell/RightBarCell";
import {HigherResolutionPlanning} from "./HigherResolutionPlanning/HigherResolutionPlanning";
import {Paginator} from "Core/Components/Paginator/Paginator";
import {EVENTS as PAGINATOR_EVENTS} from "Core/Components/Paginator/Constants";
import {AttachedFieldModel} from "../BaseControl/Models/AttachedFieldModel";
import {EntityTypesStore} from "../../Screens/TypeScreen/Stores/EntityTypesStore";
import {FormatConverter} from "../../../FormatEditor/FormatConverter";
import { IPlannerLevelParams } from '../../UserVarsManager/UserVarsManager';
import {UserVarsManager, IPlannerPageParams} from 'Core/UserVarsManager/UserVarsManager';
import { TimeStatus } from '../../Components/Controls/TimeWriting/Constants/TimeStatus';
import {FieldMetadataModel} from 'Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel';

ko.templates['Core/Controls/PlannerLevel/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/PlannerLevel/Templates/LeftBarView'] = LeftBarViewTemplate;
ko.templates['Core/Controls/PlannerLevel/Templates/RightBarView'] = RightBarViewTemplate;

const PROPERTIES = {
    READONLY: 'ReadOnly',
    ENABLE_LINK: 'EnableLinkButton',
    ENABLE_ADD_AND_LINK: 'EnableAdd&LinkButton',
    QUERY_BUTTON1: 'QueryButton1',
    QUERY_BUTTON2: 'QueryButton2',
    HIDE_QUERY_BUTTONS: 'HideQueryButtons'
};

export interface IPlannerLevelParentRecords {
    RecordId: number;
    ControlId: number;
    BarId: string;
    ParentBarId: string;
}

export interface ICellElement {
    Element: HTMLElement;
    Cell: TimeCellModel
}

export const USER_TYPES = {
    PROFILE: 'Profile',
    USER: 'User'
}

export class PlannerLevel extends BaseControl{

    private _dataRows: KnockoutObservableArray<PlannerLevelRowModel>;
    private _parentBarId: string;
    private _parentRecordId: number;
    private _parentBarSubjectId: number;
    private _weekNumber: number;
    private _startDate: string;
    private _level: number;
    private _viewType: string;
    private  _subjectEntityIcon: KnockoutObservable<Icon>;
    private _subjectTitle: KnockoutObservable<string>;
    private _leftBarIcon: KnockoutObservable<Icon>;
    private _leftBarTitle: KnockoutObservable<string>;
    private _rightBarIcon: KnockoutObservable<Icon>
    private _rightBarTitle: KnockoutObservable<string>;
    private _enableLink: KnockoutObservable<boolean>;
    private _enableAddAndLink: KnockoutObservable<boolean>;
    private _linkUnderlyingTooltip: string;
    private _addAndLinkUnderlyingTooltip: string;
    private _readOnly: boolean;
    private _parentRecords: Array<IPlannerLevelParentRecords>;
    private _showControlName: KnockoutComputed<boolean>;
    private _divideRowColSpan: KnockoutObservable<number>;
    private _showDivideRow: KnockoutObservable<boolean>;
    private _customColumnHeaders: Array<CustomColumnHeaderModel>;
    private _timeRows: KnockoutObservableArray<RightBarRow>;
    private _isHightResolutionPlanning: boolean;
    private _paginator: KnockoutObservable<Paginator>;
    private _managerId: number;
    private _button1TableName: string;
    private _button2TableName: string;
    private _hideQueryButtons: boolean;

    constructor(params: IControlParam){
        super(params, Config)
        this._dataRows = ko.observableArray([]);
        this._parentBarId = null;
        this._parentRecordId = 0;
        this._parentBarSubjectId = 0;
        this._weekNumber = 36;
        this._startDate = moment().format('YYYY-MM-DD');
        this._level = 0
        this._subjectEntityIcon = ko.observable(null);
        this._leftBarIcon = ko.observable(null);
        this._leftBarTitle = ko.observable('');
        this._rightBarIcon = ko.observable(null);
        this._rightBarTitle = ko.observable('');
        this._subjectTitle = ko.observable('');
        this._enableLink = ko.observable(false);
        this._enableAddAndLink = ko.observable(false);
        this._linkUnderlyingTooltip = '';
        this._addAndLinkUnderlyingTooltip = '';
        this._parentRecords = [];
        this._divideRowColSpan = ko.observable(0);
        this._showDivideRow = ko.observable(false);
        this._customColumnHeaders = [];
        this._isHightResolutionPlanning = false;
        this._paginator = ko.observable(null);
        this._hideQueryButtons = false;

        this.ApplyProperties();
        this.Init();

        this._showControlName = ko.computed(()=>{
           return this._model().Fields.length === 0;
        });

        this._timeRows = ko.observableArray([]);
    }

    set IsHightResolutionPlanning(value: boolean){
        this._isHightResolutionPlanning = value;
        _.each(this._timeRows(), (timeRow) => {
           timeRow.IsHightResolutionPlanning = value;
        });
    }

    SetParentRecords(parentRecords: Array<IPlannerLevelParentRecords>){
        this._parentRecords = parentRecords;
    }

    set ShowDivideRow(value: boolean){
        this._showDivideRow(value);
    }

    ApplyProperties(){
        const enableLinkProperty = this.GeneralProperties.GetPropertyValue(PROPERTIES.ENABLE_LINK);
        const enableAddAndLinkProperty = this.GeneralProperties.GetPropertyValue(PROPERTIES.ENABLE_ADD_AND_LINK);
        this._readOnly = this.GeneralProperties.GetPropertyValue(PROPERTIES.READONLY);
        this._hideQueryButtons = this.GeneralProperties.GetPropertyValue(PROPERTIES.HIDE_QUERY_BUTTONS) || false;
        this._enableLink(enableLinkProperty && this.HasNexLevel());
        this._enableAddAndLink(enableAddAndLinkProperty && this.HasNexLevel());
    }

    Init(){
        this.InitIcons();
        this._model.subscribe(()=>{
            this.InitIcons();
        })

        this._linkUnderlyingTooltip = LABELS.LINK_UNDERLYING_RECORD.replace('{UnderlyingSubject}', this.GetNexLevelSubjectEntityName());
        this._addAndLinkUnderlyingTooltip = LABELS.ADD_AND_LINK_UNDERLYING_RECORD.replace('{UnderlyingSubject}', this.GetNexLevelSubjectEntityName());
    }

    get Index(){
        return this._parentControl.GetSubControls().indexOf(this);
    }

    HasNexLevel(){
        let nextPlannerLevel = _.find(this._subControls(), (subControl) => {
            return subControl.GetType() === CONTROL_TYPES.PlannerLevel;
        });

        return !!nextPlannerLevel;
    }

    GetLeftBarTemplateName(){
        return 'Core/Controls/PlannerLevel/Templates/LeftBarView';
    }

    GetRightBarTemplateName(){
        return 'Core/Controls/PlannerLevel/Templates/RightBarView';
    }

    InitIcons(){
        let subjectField = this._model().Fields[0];
        if(subjectField && subjectField.EntityTypeName === EntityTypes[EntityTypes.Entity]){
            if (subjectField.EntityIcon) {
                this._subjectEntityIcon(new Icon(subjectField.EntityIcon));
            }

            this._subjectTitle(subjectField.EntityName);
        }

        let leftBarField = this._model().Fields[1];
        if(leftBarField && leftBarField.EntityTypeName === EntityTypes[EntityTypes.Sub]){
            if (leftBarField.EntityIcon) {
                this._leftBarIcon(new Icon(leftBarField.EntityIcon));
            }

            this._leftBarTitle(leftBarField.EntityName);
        }

        let rightBarField = this._model().Fields[2];
        if(rightBarField && leftBarField.EntityTypeName === EntityTypes[EntityTypes.Sub]){
            if (rightBarField.EntityIcon) {
                this._rightBarIcon(new Icon(rightBarField.EntityIcon));
            }

            this._rightBarTitle(rightBarField.EntityName);
        }
    }

    IsRootLevel(){
        return this._parentControl.GetType() === CONTROL_TYPES.PlannerPage;
    }

    SetParentBarId(parentBarId: string, parentBarSubjectId: number){
        this._parentBarId = parentBarId;
        this._parentBarSubjectId = parentBarSubjectId;
    }

    SetLevel(level: number){
        this._level = level;
    }

    SetParentRecordId(parentRecordId: number){
        this._parentRecordId = parentRecordId;
    }

    SetViewType(viewType: string){
        this._viewType = viewType;
    }

    SetProjectManager(id: number){
        this._managerId = id;
    }

    AddSubControl(control: IControl) {
        this._subControls.push(control);
    }

    static GetDefaultName() {
        return 'New planer level';
    }

    get Title(): string {
        return this.GetName() || this.GetDefaultName();
    }

    private GetName(): string {
        let translationList = this._model().NameTranslations;
        let currentLang = TranslationManager.Instance.GetCurrentLanguage();
        let currentLangItem = _.find(translationList, (item) => {
            return currentLang.Id === item.Language.K_Language;
        });
        if (currentLangItem) {
            this._getCurrentName = currentLangItem.Translation;
        }

        if (this._getCurrentName) {
            return this._getCurrentName;
        } else {
            return this._model().Name;
        }
    }

    AfterRender(el: Array<HTMLElement>): void {
        super.AfterRender(el);
    }

    SetWeekNumber(weekNumber: number){
        this._weekNumber = weekNumber;
        this._dataRows([]);
    }

    SetStartDate(date: string){
        this._startDate = date;
        this._dataRows([]);
    }

    SetCustomColumnHeaders(headers: Array<CustomColumnHeaderModel>){
        this._customColumnHeaders = headers;
    }

    LoadData(expandAll: boolean = false, parent: PlannerLevelRowModel = null){
        if(this._renderMode() === RenderModes.View) {
            let requestModel: IGetPlannerLevelDataRequestModel = {
                StartDate: this._startDate,
                ControlId: this.GetControlId(),
                ParentRecords: this._parentRecords,
                ParentBarId: this._parentBarId,
                WeekNumber: this._weekNumber,
                ViewType: this._viewType,
                ManagerId: this._managerId,
                IsRoot: this.IsRootLevel(),
                RecordsPerPage: this._paginator() ? this._paginator().RecordsPerPage : 0,
                PageNumber: this._paginator() ? this._paginator().PageNumber : 0,
            }

            PlannerLevelStore.GetPlannerLevelData(requestModel).always(() => {
                if(parent != null){
                    parent.IsBlocked(false);
                }
                }).then((result) => {
                    this.PopulateData(result, expandAll);
            });
        }
    }

    FormatValue(value: string, fieldType: string, fieldFormat: string){

       let field = new FieldMetadataModel();
       field.Type = fieldType;
       field.FormatName = fieldFormat;

        if(fieldType === FIELD_TYPES.Decimal || fieldType === FIELD_TYPES.Integer){
            return this.PrepareDecimalOrInteger(value, field);
        }

        if(fieldType === FIELD_TYPES.Date || fieldType === FIELD_TYPES.DateTime || fieldType === FIELD_TYPES.Time){
            return this.PrepareTime(value, field)
        }

        if(fieldType === FIELD_TYPES.TimeSpan){
            return this.PrepareTimeSpan(value, field)
        }

        return value;
    }

    private PrepareDecimalOrInteger(value: string, field: FieldMetadataModel): string {
        return FormatConverter.LocalizeDecimalOrInteger(value);
    }

    private PrepareTimeSpan(value: string, field: FieldMetadataModel): string {
        let datetimeFormat = FormatConverter.GetDateFormatFromFieldModel(field, true);
        return FormatConverter.ConvertFromDefaultFormat(value, datetimeFormat);
    }

    private PrepareTime(value: string, field: FieldMetadataModel): string {
        value = FormatConverter.CorrectTimezone(value);
        let datetimeFormat = FormatConverter.GetDateFormatFromFieldModel(field, true);
        return FormatConverter.ConvertFromDefaultFormat(value, datetimeFormat);
    }

    get Button1TableName(): string{
        let buttonTableName = null;
        if(this._button1TableName){
            buttonTableName = this._button1TableName;
        }else{
            _.each(this._timeRows(), (row)=>{
                _.each(row.SubControls(), (control) =>{
                    if(control instanceof PlannerLevel && control.Button1TableName){
                        buttonTableName = control.Button1TableName;
                    }
                 });
            });
        }        
        return buttonTableName;
    }

    get Button2TableName(): string{
        let buttonTableName = null;
        if(this._button2TableName){
            buttonTableName = this._button2TableName;
        }else{
            _.each(this._timeRows(), (row)=>{
                _.each(row.SubControls(), (control) =>{
                    if(control instanceof PlannerLevel && control.Button2TableName){
                        buttonTableName = control.Button2TableName;
                    }
                 });
            });
        }        
        return buttonTableName;
    }

    PopulateData(data: Array<PlannerLevelRowModel>, expandAll: boolean = false){

        if(data.length > 0){
            this._button1TableName = data[0].Button1TableName;
            this._button2TableName = data[0].Button2TableName;
        }

        let timeRows: Array<RightBarRow> = [];
        _.each(data, (dataRow) =>{
            let customValues = dataRow.CustomValues;
            dataRow.CustomValues = [];
            _.each(this._customColumnHeaders, (header) => {
                let customValue = new CustomColumnValueModel();
                customValue.FieldName = header.FieldName;
                customValue.FieldType = header.FieldType;
                let value = _.find(customValues, (customValue) => {
                    return customValue.FieldName === header.FieldName && customValue.FieldType === header.FieldType;
                });

                if(value){
                    customValue.Value(this.FormatValue(value.Value(), value.FieldType, value.FieldFormat));
                }

                dataRow.CustomValues.push(customValue);
            });

            let rightBarRow = new RightBarRow( { Model: dataRow, Level: this._level, ReadOnly: this._readOnly, HideQueryButtons: this._hideQueryButtons });

            rightBarRow.IsHightResolutionPlanning = this._isHightResolutionPlanning;

            rightBarRow.On(EVENTS.MOUSE_OVER, this, (eventArgs) =>{
                this.MouseCellOver(eventArgs.data.CellModel);
            });

            rightBarRow.On(EVENTS.MOUSE_OUT, this, (eventArgs) =>{
                this.MouseCellOut(eventArgs.data.CellModel);
            });

            rightBarRow.On(EVENTS.UNSELECT_CELLS, this, (eventArgs) =>{
                this.UnselectParentCells(eventArgs.data.CellModel);
            });

            rightBarRow.On(EVENTS.KEY_DOWN, this, (eventArgs) =>{
                this.KeyDown(eventArgs.data.RowModel, eventArgs.data.CellModel, eventArgs.data.Event, eventArgs.data.CellElement);
            });

            rightBarRow.On(EVENTS.UPDATE_PLAN, this, (eventArgs) =>{
                this.UpdatePlan(
                    eventArgs.data.RowModel,
                    eventArgs.data.Button1TableId,
                    eventArgs.data.Button1RecordId,
                    eventArgs.data.Button2TableId,
                    eventArgs.data.Button2RecordId,
                    eventArgs.data.CellModel,
                    eventArgs.data.CellElement
                );
            });

            rightBarRow.On(EVENTS.UNLINK_QUERY_RECORD, this, (eventArgs) =>{

                this.UnlinkQueryRecord(
                    eventArgs.data.RowModel,
                    eventArgs.data.ButtonTableId,
                    eventArgs.data.ButtonRecordId,
                    eventArgs.data.CellModel,
                    eventArgs.data.CellElement
                );
            });

            rightBarRow.On(EVENTS.COPY_CELL, this, () =>{
                if(this._parentControl){
                    (this._parentControl as any).SetCopyCellMode();
                }
            });

            rightBarRow.On(EVENTS.HIGHER_RESOLUTION_PLANNING, this, (eventArgs) =>{
                this.ShowHigherResolutionPlanning(eventArgs.data.RowModel, eventArgs.data.CellModel);
            });

            timeRows.push(rightBarRow);
            
        });

        this._timeRows(timeRows);
        this._dataRows(data);

        _.each(data, (row)=>{
            if(expandAll){
                this.Toggle(row, expandAll);
            }else{
                if(this.GetDefaultExpandedState(row.BarId)){
                    this.Toggle(row, false);
                }
            }
        });

        let firstRow = _.first(this._dataRows());
        if(firstRow){
            this._divideRowColSpan(firstRow.TimeLine.length);
        }

        if(!this._paginator() && this.IsRootLevel()){
            this._paginator(new Paginator());
            this._paginator().RecordsPerPage = 20;
            this._paginator().On(PAGINATOR_EVENTS.CHANGE, this, () => {
                this.CancelCopyToSelection();
                this.LoadData();
            });
        }

        if(firstRow && this._paginator()){
            this._paginator().TotalRecords = firstRow.TotalRecords;
        }
    }

    CancelCopyToSelection(){
        if(this._parentControl){
            (this._parentControl as any).CancelCopyToSelection();
        }
    }

    SetCopyCellMode(){
        if(this._parentControl){
            (this._parentControl as any).SetCopyCellMode();
        }
    }
    
    WeekOfMonth(m: moment.Moment) {
        return m.isoWeek() - moment(m).startOf('month').isoWeek() + 1;
    }

    ShowHigherResolutionPlanning(row: PlannerLevelRowModel, cell: TimeCellModel){
        BlockUI.Block();
        
        let startDate = cell.TimeAxis;
        if(moment(cell.TimeAxis).startOf('isoWeek').month() < moment(cell.TimeAxis).month()){
            startDate = moment(cell.TimeAxis).add(6, 'day').format();
        }

        let requestModel: IGetPlannerLevelDataRequestModel = {
            StartDate: startDate,
            ControlId: this.GetControlId(),
            ParentBarId: this._parentBarId,
            WeekNumber: 2,
            ViewType: 'Week',
            IsRoot: this.IsRootLevel(),
            RecordsPerPage: 0,
            PageNumber: 0,
            BarId: row.BarId
        }

        PlannerLevelStore.GetPlannerLevelData(requestModel).always(() => {
            BlockUI.Unblock();
        }).then((result) => {
            if(result.length > 0){

                result[0].TimeLine = _.filter(result[0].TimeLine, (item) => {
                   return moment(item.TimeAxis).month() === moment(cell.TimeAxis).month() && moment(item.TimeAxis).year() === moment(cell.TimeAxis).year();
                });

                let higherResolutionPlanning = new HigherResolutionPlanning(result[0], cell, row.BarName);
                higherResolutionPlanning.On(HIGHER_RESOLUTION_EVENTS.SAVE, this, () =>{
                   let request = [];
                   _.each(higherResolutionPlanning.Cells, (cell) =>{
                       request.push({
                           RecordId: row.RecordId,
                           TimeValue: this.FormatPlanValue(cell.Value().toString()),
                           TimeAxis: cell.TimeAxis,
                           ControlId: this.GetControlId(),
                           ParentRecords: this._parentRecords,
                           ViewType: 'Week',
                           BarId: row.BarId
                       });
                   });

                    BlockUI.Block();

                    PlannerLevelStore.CreateOrUpdatePlans({ HigherResolutionPlans: request, Plan: {
                            RecordId: row.RecordId,
                            TimeValue: this.FormatPlanValue(cell.TimeValue.toString()),
                            TimeAxis: cell.TimeAxis,
                            ControlId: this.GetControlId(),
                            ParentRecords: this._parentRecords,
                            ViewType: this._viewType,
                            BarId: row.BarId
                    } }).then((result)=>{
                        this.UpdateCells(result);
                        cell.IsSelected(true);
                        this.UnselectParentCells(cell);
                    }).always(()=>{
                        higherResolutionPlanning.Close();
                        BlockUI.Unblock();
                    }).fail(data => {
                        new Notifier(null).Failed(data.message);
                    });

                });
                higherResolutionPlanning.Show();
            }
        });
    }

    FormatPlanValue(value: string){
        return value.replace(',', '.');
    }

    UpdateCells(rows: Array<PlannerLevelRowModel>){

        let parentControl = this.GetParentControl();
        if(parentControl instanceof PlannerLevel){
            parentControl.UpdateCells(rows);
        }

        let controlDataRows = _.filter(rows, (row) => {
           return row.ControlId === this.GetControlId();
        });

        _.each(controlDataRows, (dataRow) => {
                let dataRowToUpdate = _.find(this._dataRows(), (item) => {
                    return dataRow.BarId === item.BarId;
                });

                if(dataRowToUpdate) {
                    _.each(dataRow.CustomValues, (customValue) =>{
                        let existsCustomValue = _.find(dataRowToUpdate.CustomValues, (item) => {
                            return item.FieldType === customValue.FieldType && item.FieldName === customValue.FieldName;
                        });

                        if(existsCustomValue){
                          existsCustomValue.Value(this.FormatValue(customValue.Value(), existsCustomValue.FieldType, existsCustomValue.FieldFormat));
                        };
                    });
                }
            }
        );

        _.each(controlDataRows, (dataRow) => {
                let dataRowToUpdate = _.find(this._timeRows(), (item) => {
                    return dataRow.BarId === item.BarId;
                });

                if(dataRowToUpdate) {
                    dataRowToUpdate.UpdateCells(dataRow.TimeLine);
               }
            }
        );
    }

    UnlinkQueryRecord(
        row: PlannerLevelRowModel,
        buttonTableId: number,
        buttonRecordId: number,
        cell: TimeCellModel,
        cellElement: HTMLElement
    ){
        BlockUI.Block({ Target: cellElement });
        PlannerLevelStore.UnlinkQueryRecord({
            UnlinkButtonRecordId: buttonRecordId,
            UnlinkButtonTableId: buttonTableId,
            RecordId: row.RecordId,
            TimeAxis: cell.TimeAxis,
            ControlId: this.GetControlId(),
            ParentRecords: this._parentRecords,
            ViewType: this._viewType,
            BarId: row.BarId
        }).then((result)=>{
            this.UpdateCells(result);
        }).always(()=>{
            BlockUI.Unblock(cellElement);
        }).fail(data => {
            new Notifier(null).Failed(data.message);
        });
    }

    UpdatePlan(
        row: PlannerLevelRowModel,
        button1TableId: number,
        button1RecordId: number,
        button2TableId: number,
        button2RecordId: number,
        cell: TimeCellModel,
        cellElement: HTMLElement
    ){
        BlockUI.Block({ Target: cellElement });

        if(button1RecordId || button2RecordId){
            if(this._viewType == 'Month'){
                cell.TimeValue = '720';
            }

            if(this._viewType == 'Week'){
                cell.TimeValue = '168';
            }

            if(this._viewType == 'Day'){
                cell.TimeValue = '24';
            }
        }

        PlannerLevelStore.CreateOrUpdatePlan({
            Button1RecordId: button1RecordId,
            Button1TableId: button1TableId,
            Button2RecordId: button2RecordId,
            Button2TableId: button2TableId,
            RecordId: row.RecordId,
            TimeValue: this.FormatPlanValue(cell.TimeValue.toString()),
            TimeAxis: cell.TimeAxis,
            ControlId: this.GetControlId(),
            ParentRecords: this._parentRecords,
            ViewType: this._viewType,
            BarId: row.BarId
        }).then((result)=>{
            this.UpdateCells(result);
        }).always(()=>{
            BlockUI.Unblock(cellElement);
        }).fail(data => {
            new Notifier(null).Failed(data.message);
        });
    }

    public ResetDefaultExpandedState(){
        let userVars = this.GetUserVars();
        userVars.ExpandedBarIds = [];
        this.SetUserVars(userVars);

        _.each(this._subControls(), (subControl)=>{
            if(subControl instanceof PlannerLevel){
                subControl.ResetDefaultExpandedState();
            }
        });

        _.each(this._dataRows(), (dataRow)=>{
            this.SetDefaultExpandedState(dataRow, false);
        });
    }

    private SetDefaultExpandedState(row: PlannerLevelRowModel, expanded: boolean){
        let userVars = this.GetUserVars();
        if(expanded){
            if(userVars.ExpandedBarIds.indexOf(row.BarId) < 0){
                userVars.ExpandedBarIds.push(row.BarId);            
            }            
        }else{
            userVars.ExpandedBarIds.splice(userVars.ExpandedBarIds.indexOf(row.BarId), 1);
            _.each(row.SubControls(), (subControl)=>{
                if(subControl instanceof PlannerLevel){
                    subControl.ResetDefaultExpandedState();
                }
            });
        }
        this.SetUserVars(userVars);
    }

    private GetDefaultExpandedState(barId: string): boolean{
        let userVars = this.GetUserVars();
        return userVars.ExpandedBarIds.indexOf(barId) >= 0;
    }

    private GetUserVars(): IPlannerLevelParams {
		return UserVarsManager.Instance.GetPlannerLevelParams(this.GetControlId());
	}

	private SetUserVars(params: IPlannerLevelParams) {
		UserVarsManager.Instance.SetPlannerLevelParams(this.GetControlId(), params);
	}

    Toggle(plannerLevelRow: PlannerLevelRowModel, expandAll: boolean){

        let planerLevelControls = _.filter(this._subControls(), (subControl) => {
            return subControl.GetType() === CONTROL_TYPES.PlannerLevel;
        });

        if(planerLevelControls.length === 0) return;

        plannerLevelRow.IsExpanded(!plannerLevelRow.IsExpanded());
        
        this.SetDefaultExpandedState(plannerLevelRow, plannerLevelRow.IsExpanded());
        plannerLevelRow.SubControls([]);
        if(plannerLevelRow.IsExpanded()) {
            plannerLevelRow.IsBlocked(true);
            _.each(planerLevelControls, (planerLevelControl) => {
                let control = planerLevelControl.Clone() as PlannerLevel;
                control.IsHightResolutionPlanning = this._isHightResolutionPlanning;
                control.SetCustomColumnHeaders(this._customColumnHeaders);
                control.SetParentBarId(plannerLevelRow.BarId, plannerLevelRow.SubjectId);
                control.SetLevel(this._level + 1);
                control.SetStartDate(this._startDate);
                control.SetViewType(this._viewType);
                control.SetWeekNumber(this._weekNumber);
                control.SetParentRecordId(plannerLevelRow.RecordId);
                control.SetProjectManager(this._managerId);
                control.SetParentRecords(this._parentRecords.concat([
                    {
                        BarId: plannerLevelRow.BarId,
                        ParentBarId: this._parentBarId,
                        ControlId: this.GetControlId(),
                        RecordId: plannerLevelRow.RecordId
                    }]));
                plannerLevelRow.SubControls.push(control);
                (control as PlannerLevel).LoadData(expandAll, plannerLevelRow);
            });
        }
    }

    EditValue(timeCell: TimeCellModel){
        timeCell.IsEditMode(true);
    }

    LinkRecord(plannerLevelRow: PlannerLevelRowModel) {
        let childPlannerLevel = _.find(this._subControls(), (item) => {
           return item.GetType() === CONTROL_TYPES.PlannerLevel;
        });

        let currentLevelSubject = this.GetModel().Fields[0];

        if(childPlannerLevel){
            let plannerLevelSubject = childPlannerLevel.GetModel().Fields[0];

            if(plannerLevelSubject.EntityName === 'SYS_USERS'){

                EntityTypesStore.GetTypes({ EntityId: plannerLevelSubject.EntityId, ParentTypeId: 0, WithRoot: false, OnlyEnabled: true })
                    .then((result) => {

                        let profileType =  _.find(result.TableTypes, (item) => {
                           return item.Name === USER_TYPES.PROFILE;
                        });

                        if(profileType){
                            EntityTypesStore.GetTypes({ EntityId: plannerLevelSubject.EntityId, ParentTypeId: profileType.Id, WithRoot: false, OnlyEnabled: true })
                                .then((result) => {
                                    let userType =  _.find(result.TableTypes, (item) => {
                                        return item.Name === USER_TYPES.USER;
                                    });

                                    if(userType){
                                        this.ShowSearchScreen(
                                            plannerLevelSubject,
                                            currentLevelSubject,
                                            plannerLevelRow,
                                            childPlannerLevel,
                                            [userType.Id],
                                            profileType.Id,
                                            currentLevelSubject.EntityId,
                                            0,
                                            currentLevelSubject.EntityId,
                                            plannerLevelRow.RecordId
                                            );
                                    }
                                });
                        }
                    });
            }else{
                this.ShowSearchScreen(plannerLevelSubject, currentLevelSubject, plannerLevelRow, childPlannerLevel, []);
            }
        }
    }

    ShowSearchScreen(
        plannerLevelSubject: AttachedFieldModel,
        currentLevelSubject: AttachedFieldModel,
        plannerLevelRow: PlannerLevelRowModel,
        childPlannerLevel: IControl,
        allowedTypes: Array<number>,
        parentTypeId?: number,
        parentEntityId?: number,
        parentRecordId?: number,
        plannerLevelEntityId?: number,
        plannerLevelRecordId?: number
    ){
        if(plannerLevelSubject && plannerLevelSubject.EntityTypeName === EntityTypes[EntityTypes.Entity]){
            let searchScreen = new SearchScreen({
                EntityId: plannerLevelSubject.EntityId,
                SubjectEntityId: parentEntityId || currentLevelSubject.EntityId,
                SubjectRecordId: parentRecordId || plannerLevelRow.RecordId,
                SubjectTypeId: parentTypeId || plannerLevelRow.SubjectTypeId,
                SearchTerm: '',
                ButtonAdd: false,
                SearchByTypes: allowedTypes,
                PlannerLevelEntityId: plannerLevelEntityId,
                PlannerLevelRecordId: plannerLevelRecordId
            });

            searchScreen.On('RECORD_SELECTED', this, eventArgs => {
                this.CreateLink(eventArgs.data.RecordId, plannerLevelRow, childPlannerLevel as PlannerLevel);
            });

            searchScreen.Show();
        }
    }

    GetNexLevelSubjectEntityName(){
        let nextPlannerLevel = _.find(this._subControls(), (subControl) => {
            return subControl.GetType() === CONTROL_TYPES.PlannerLevel;
        });

        if(nextPlannerLevel){
            let subjectEntityField = nextPlannerLevel.GetModel().Fields[0];
            if(subjectEntityField){
                return subjectEntityField.EntityName;
            }
        }
        return null;
    }

    MouseRowOver(row: PlannerLevelRowModel){
      row.IsActive(true);
    }

    MouseRowOut(row: PlannerLevelRowModel){
       row.IsActive(false)
    }

    MouseCellOver(cell: TimeCellModel){
        _.each(this._dataRows(), (dataRow) =>{
           let cellIndex = dataRow.TimeLine.indexOf(cell);
           if(cellIndex >= 0){
               this.ActivateParentColumn(cellIndex);
               return;
           }
        });
    }

    MouseCellOut(cell: TimeCellModel){
        _.each(this._dataRows(), (dataRow) =>{
            let cellIndex = dataRow.TimeLine.indexOf(cell);
            if(cellIndex >= 0){
                this.DeactivateParentColumn(cellIndex);
                return;
            }
        });
    }

    ActivateParentColumn(index: number){
        if(this._parentControl) {
            if (this._parentControl instanceof PlannerLevel) {
                this._parentControl.ActivateParentColumn(index);
            } else {
                (this._parentControl as any).ActivateColumn(index);
            }
        }
    }

    DeactivateParentColumn(index: number){
        if(this._parentControl) {
            if (this._parentControl instanceof PlannerLevel) {
                this._parentControl.DeactivateParentColumn(index);
            } else {
                (this._parentControl as any).DeactivateColumn(index);
            }
        }
    }

    UnselectParentCells(exceptCell: TimeCellModel){
        if(this._parentControl) {
            if (this._parentControl instanceof PlannerLevel) {
                this._parentControl.UnselectParentCells(exceptCell);
            } else {
                (this._parentControl as any).UnselectCells(exceptCell);
            }
        }
    }

    KeyDown(row: PlannerLevelRowModel, cell: TimeCellModel, event, cellElement: HTMLElement){
        if(event.keyCode === KEY_CODES.RIGHT){
            this.MoveRight(row, cell, event, cellElement);
        } else if(event.keyCode === KEY_CODES.LEFT){
            this.MoveLeft(row, cell, event, cellElement);
        } else  if(event.keyCode === KEY_CODES.UP){
            this.MoveUp(row, cell, event, cellElement);
        }else  if(event.keyCode === KEY_CODES.DOWN || event.keyCode === KEY_CODES.ENTER){
            this.MoveDown(row, cell, event, cellElement);
        }
    }

    MoveDown(row: PlannerLevelRowModel, cell: TimeCellModel, event, cellElement: HTMLElement){
        let nextRowElement = $(cellElement).closest('tr').next('tr')[0];
        if(nextRowElement){
            let nextRow = ko.dataFor(nextRowElement);
            if(nextRow instanceof RightBarRow){
                this.UnselectParentCells(cell);
                nextRow.SelectCell(row.TimeLine.indexOf(cell));
            }
        }
    }

    MoveUp(row: PlannerLevelRowModel, cell: TimeCellModel, event, cellElement: HTMLElement){
        let previousRowElement = $(cellElement).closest('tr').prev('tr')[0];
        if(previousRowElement){
            let previousRow = ko.dataFor(previousRowElement);
            if(previousRow instanceof RightBarRow){
                this.UnselectParentCells(cell);
                previousRow.SelectCell(row.TimeLine.indexOf(cell));
            }
        }
    }

    MoveLeft(row: PlannerLevelRowModel, cell: TimeCellModel, event, cellElement: HTMLElement){
        let previousCellElement = $(cellElement).prev('td')[0];
        if(previousCellElement){
            let previousCell = ko.dataFor(previousCellElement);
            if(previousCell instanceof RightBarCell){
                this.UnselectParentCells(cell);
                previousCell.IsSelected(true);
            }
        }
    }

    MoveRight(row: PlannerLevelRowModel, cell: TimeCellModel, event, cellElement: HTMLElement){
        let nextCellElement = $(cellElement).next('td')[0];
        if(nextCellElement){
            let nextCell = ko.dataFor(nextCellElement);
            if(nextCell instanceof RightBarCell){
                this.UnselectParentCells(cell);
                nextCell.IsSelected(true);
            }
        }
    }

    UnselectCells(exceptCell: TimeCellModel){
        _.each(this._dataRows(), (item) => {
            _.each(item.SubControls(), (subControl) =>{
                if(subControl instanceof PlannerLevel){
                    subControl.UnselectCells(exceptCell);
                }
            })

            _.each(item.TimeLine, (cell) => {
                cell.IsSelected(false);
            });
        });
    }

    ActivateColumn(index: number){
        _.each(this._dataRows(), (item) => {
            _.each(item.SubControls(), (subControl) =>{
                if(subControl instanceof PlannerLevel){
                    subControl.ActivateColumn(index);
                }
            })

            let timeCell = item.TimeLine[index];
            if(timeCell){
                timeCell.IsActive(true);
            }
        });
    }

    DeactivateColumn(index: number){
        _.each(this._dataRows(), (item) => {

            _.each(item.SubControls(), (subControl) =>{
                if(subControl instanceof PlannerLevel){
                    subControl.DeactivateColumn(index);
                }
            })


            let timeCell = item.TimeLine[index];
            if(timeCell){
                timeCell.IsActive(false);
            }
        });
    }

    CreateLink(
        recordId: number,
        plannerLevelRow: PlannerLevelRowModel,
        childPlannerLevel: PlannerLevel
    ){
        BlockUI.Block();

        let treeRecords = this._parentRecords.concat(
            [{
                BarId: plannerLevelRow.BarId,
                ParentBarId: this._parentBarId,
                ControlId: this.GetControlId(),
                RecordId: plannerLevelRow.RecordId
            }]
        );

        PlannerLevelStore.LinkRecord({
            RecordId: recordId,
            ControlId: childPlannerLevel.GetControlId(),
            StartDate: this._startDate,
            ParentRecords: treeRecords
        }).then(()=>{
            plannerLevelRow.IsExpanded(true);
            plannerLevelRow.SubControls([]);

            if(plannerLevelRow.IsExpanded()) {
                let planerLevelControls = _.filter(this._subControls(), (subControl) => {
                    return subControl.GetType() === CONTROL_TYPES.PlannerLevel;
                });

                _.each(planerLevelControls, (planerLevelControl) => {
                    let control = planerLevelControl.Clone() as PlannerLevel;
                    control.SetParentBarId(plannerLevelRow.BarId, plannerLevelRow.SubjectId);
                    control.SetLevel(this._level + 1);
                    control.SetStartDate(this._startDate);
                    control.SetParentRecords(this._parentRecords.concat([
                        {
                            BarId: plannerLevelRow.BarId,
                            ParentBarId: this._parentBarId,
                            ControlId: this.GetControlId(),
                            RecordId: plannerLevelRow.RecordId
                        }]));
                    control.LoadData();
                    plannerLevelRow.SubControls.push(control);
                });
            }
        })
        .always(()=>{
            BlockUI.Unblock();
        });
    }

    AddAndLink(plannerLevelRow: PlannerLevelRowModel){

        let childPlannerLevel = _.find(this._subControls(), (item) => {
            return item.GetType() === CONTROL_TYPES.PlannerLevel;
        });

        let itSelfRelation = this.GetModel().Fields[0].EntityId === childPlannerLevel.GetModel().Fields[0].EntityId;
        let parentType = itSelfRelation ?  plannerLevelRow.SubjectTypeId : 0;

        let plannerLevelSubject = childPlannerLevel.GetModel().Fields[0];
        if(plannerLevelSubject && plannerLevelSubject.EntityTypeName === EntityTypes[EntityTypes.Entity]){
            let typeScreen = new TypeScreen(plannerLevelSubject.EntityId, parentType, false);

            typeScreen.On('TYPE_SELECTED', this, eventArgs => {
                const typeId = eventArgs.data.TypeId;
                const kindId = eventArgs.data.KindId;
                const exampleRecordId = eventArgs.data.ExampleRecordId;

                this.AddAndLinkRecord(
                    plannerLevelSubject.EntityId,
                    typeId,
                    kindId,
                    exampleRecordId,
                    plannerLevelRow,
                    childPlannerLevel as PlannerLevel
                );
            }).On('TYPES_NOT_FOUND', this, (eventArgs) =>
                new Notifier($(this._el)).Warning(eventArgs.data.Message || NOTIFICATIONS.SUB_TYPE_NOT_FOUND)
            );

            typeScreen.Show();
        }

    }

    async AddAndLinkRecord(
        relatedEntityId: number,
        typeId: number,
        kindId: number,
        exampleRecordId: number,
        plannerLevelRow: PlannerLevelRowModel,
        childPlannerLevel: PlannerLevel
    ){
        BlockUI.Block();
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

        screenManager.GetEditScreen({
            EntityId: relatedEntityId,
            TableTypeId: typeId,
            KindId: kindId,
            RecordId: exampleRecordId,
            LoadAsExample: exampleRecordId > 0,
        }).always(() => {
            BlockUI.Unblock();
        }).fail(error => {
            const notifier = new Notifier($(this._el));
            notifier.Warning(error.message);
        }).then((screen) => {

            screen.On('RECORD_SAVED', this, (eventArgs) => {
                this.CreateLink(
                    eventArgs.data.RecordId,
                    plannerLevelRow,
                    childPlannerLevel
                );
            });

            screen.ShowInModal();

        });
    }

    AfterRowRender(el: Array<HTMLElement>, row: PlannerLevelRowModel){
        ko.tasks.schedule(()  => {
            let height = $(el[1]).height();
            row.RowHeight(height);
        });
    }

    get Paginator(): KnockoutObservable<Paginator>{
        return this._paginator;
    }

    get HasPagination(): boolean {
        return this._level === 0 && !!this._paginator() && this._paginator().GetIsReady();
    }

    SelectCopyFromCell(index: number){
        _.each(this._timeRows(), (row)=>{
            row.SelectCopyFromCell(index);
            _.each(row.SubControls(), (subControl) => {
                if(subControl instanceof PlannerLevel){
                    subControl.SelectCopyFromCell(index);
                }
            });
        });   
    }

    UnSelectCopyFromCell(){
        _.each(this._timeRows(), (row)=>{
            row.UnSelectCopyFromCells();
            _.each(row.SubControls(), (subControl) => {
                if(subControl instanceof PlannerLevel){
                    subControl.UnSelectCopyFromCell();
                }
            });
        });   
    }


    SelectCopyToCell(index: number){
        if(this.IsCopyToCellSelected(index)){
            this.UnSelectCopyToCell(index);
            return;
        }
        _.each(this._timeRows(), (row)=>{
            row.SelectCopyToCell(index);
            _.each(row.SubControls(), (subControl) => {
                if(subControl instanceof PlannerLevel){
                    subControl.SelectCopyToCell(index);
                }
            });
        });   
    }

    IsCopyToCellSelected(index: number): boolean{
        let isSelected = false
        _.each(this._timeRows(), (row)=>{
           if(row.IsCopyToCellSelected(index)){
               isSelected = true;
           }
        });   
        return isSelected;
    }

    UnSelectCopyToCell(index: number){
        _.each(this._timeRows(), (row)=>{
           row.UnSelectCopyToCell(index);
            _.each(row.SubControls(), (subControl) => {
                if(subControl instanceof PlannerLevel){
                    subControl.UnSelectCopyToCell(index);
                }
            });
        });   
    }
    
    UnSelectCopyToCells(){
        _.each(this._timeRows(), (row)=>{
           row.UnSelectCopyToCells();
            _.each(row.SubControls(), (subControl) => {
                if(subControl instanceof PlannerLevel){
                    subControl.UnSelectCopyToCells();
                }
            });
        });   
    }

    CopyColumn(index: number, overwrite: boolean, copyQueryButton1: boolean, copyQueryButton2: boolean){
        _.each(this._timeRows(), (row)=>{

            if(!this._readOnly || this.HasAnyQueryButton){
                row.CopyCell(index, overwrite, copyQueryButton1, copyQueryButton2);
            }else{
                row.UnSelectCopyToCells();
            }

            _.each(row.SubControls(), (subControl) => {
                if(subControl instanceof PlannerLevel){
                    subControl.CopyColumn(index, overwrite, copyQueryButton1, copyQueryButton2);
                }
            });

        });   
    }

    CopyCell(overwrite: boolean, copyQueryButton1: boolean, copyQueryButton2: boolean){
        _.each(this._timeRows(), (row)=>{
            let copyFromCellIndex = row.CopyFromCellIndex;
            
            if(!this._readOnly || this.HasAnyQueryButton){
                if(copyFromCellIndex >= 0){
                    row.CopyCell(copyFromCellIndex, overwrite, copyQueryButton1, copyQueryButton2);
                }
            }else{
                row.UnSelectCopyToCells();
            }

            _.each(row.SubControls(), (subControl) => {
                if(subControl instanceof PlannerLevel){
                    subControl.CopyCell(overwrite, copyQueryButton1, copyQueryButton2);
                }
            });
        });   
    }

    get HasAnyQueryButton(){
        let hasQuery = this.GeneralProperties.GetPropertyValue(PROPERTIES.QUERY_BUTTON1) || this.GeneralProperties.GetPropertyValue(PROPERTIES.QUERY_BUTTON2);
        return !!hasQuery;
    }

    get HasData(){
        let hasData = false;
        _.each(this._timeRows(), (row)=>{
            if((!this._readOnly || this.HasAnyQueryButton) && row.HasData){
                hasData = true;
            }

            _.each(row.SubControls(), (subControl) => {
                if(subControl instanceof PlannerLevel && subControl.HasData){
                    hasData = true;
                }
            });

        });   

        return hasData;       
    }
}