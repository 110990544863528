import * as ko from 'knockout';

import {BaseScreen} from 'Core/Screens/BaseScreen';
import {SearchScreen} from 'Core/Screens/SearchScreen/SearchScreen';
import {ISearchScreenParam} from 'Core/Screens/IScreen';
import {ScreenModel} from 'Core/Models/Screens/ScreenModel';
import {IControlValue} from 'Core/Controls/BaseControl/BaseControl';
import {IControl} from 'Core/Controls/IControl';
import {CONTROL_TYPES, RenderModes} from 'Core/Constant';
import {TypeScreen} from 'Core/Screens/TypeScreen/TypeScreen';
import {EditScreen} from 'Core/Screens/EditScreen/EditScreen';
import {ScreenManager} from 'Core/ScreenManager/ScreenManager';
import {Notifier} from 'Core/Common/Notifier';
import {MenuManager} from 'MenuManager/MenuManager';
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';
import {Grid} from 'Core/Controls/Grid/Grid';
import {ControlDataModel} from 'Core/ScreenManager/Models/ControlDataModel';
import {RecordSpecsModel} from 'Core/ScreenManager/Models/RecordSpecsModel';
import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';
import {NOTIFICATIONS, LABELS} from 'Core/Components/Translation/Locales';
import {LockManager} from 'Core/Components/Locker/LockManager';
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';
import {IQueryScreen} from "Core/Screens/QueryScreen/IQueryScreen";

import ListScreenTemplate from 'Core/Screens/Templates/AdditionalConsultScreen/QueryScreen/QueryScreen.html';
import {ProfileSelectorScreen} from '../ProfileSelectorScreen/ProfileSelectorScreen';
import {QueryExpressionModel} from "../../Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel";

ko.templates['Core/Screens/Templates/AdditionalConsultScreen/QueryScreen/QueryScreen'] = ListScreenTemplate;

export class QueryScreen extends BaseScreen implements IQueryScreen {
    private _grid: Grid;
    private _queryName: String;

    constructor(screenModel: ScreenModel) {
        super(screenModel, RenderModes.Edit);

        this.AddEvent('NEW_RECORD_CREATED');
        this.AddEvent('SEARCH_RECORD');
        this.AddEvent('ADD_RECORD');
        this.AddEvent('DATA_CHANGED');
        this.Init();
    }

    private Init() {
        if(!this._model.Data) {
            return;
        }
        this._grid = this.GetControl<Grid>('Grid');
        this._queryName = null;

        if (this._grid) {
            ['EnableLinkButton', 'EnableAdd&LinkButton', 'EnableAddQueryButton']
                .forEach(property => this._grid.ChangeProperty(property, false));
        }

        const controlData = new ControlDataModel();

        controlData.Rights = this._model.Data ? this._model.Data.Rights : null;

        const controlValue: IControlValue = {
            Data: controlData,
            SubjectEntityId: this._model.EntityId,
            SubjectRecordId: -1,
            RecordSpecsModel: null
        };

        _.each(this._controls, (control: IControl) => control.SetValue(controlValue));

        this.On('SEARCH_RECORD', this, (eventArgs: any) => this.SearchRecord());
        this.On('ADD_RECORD', this, (eventArgs: any) => this.CreateRecord());
    }

    Refresh() {
        this._grid.LoadData();
    }

    SetRecordId(recordId: number): void {
        this._recordId = recordId;
    }

    GetTemplateName(): string {
        return `Core/Screens/Templates/AdditionalConsultScreen/QueryScreen/QueryScreen`;
    }

    GetTextSearchTermControl(): IControl {
        return _.first(_.filter(this.GetAllControls(), (control: IControl) => control.GetType() === 'Search'));
    }

    SearchRecord(): void {
        LockManager.Instance.ReleaseAllLocks();

        const SearchControl = this.GetTextSearchTermControl();
        const searchControlId = SearchControl != null ? SearchControl.GetControlId() : 0;
        const searchTerm = SearchControl ? SearchControl.GetValue() : '';

        const searchParams: ISearchScreenParam = {
            EntityId: this.GetEntityId(),
            ControlId: searchControlId,
            SearchTerm: searchTerm
        };

        const searchScreen = new SearchScreen(searchParams);

        searchScreen.On('RECORD_SELECTED', this, (eventArgs: any) => {
            this._tableTypeId = eventArgs.data.TypeId;
            this._recordId = eventArgs.data.RecordId;

            UserVarsManager.Instance.AddRecent(this.GetEntityId(), this._recordId, this._tableTypeId);

            searchScreen.Close();

            PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, {
                EntityId: this.GetEntityId(),
                RecordId: this._recordId,
                RecordTypeId: this._tableTypeId
            });
        });

        searchScreen.On('ALT_ENTITY_RECORD_SELECTED', this, (eventArgs) => {
            const data = eventArgs.data;

            UserVarsManager.Instance.AddRecent(data.EntityId, data.RecordId, data.TypeId);
            data.IsOpenInModal = false;
            PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, data);
        });


        searchScreen.On('NEW_RECORD', this, (eventArgs) => {
            const typeScreen = new TypeScreen(this.GetEntityId(), this.GetTableTypeId(), true);

            typeScreen.On('TYPES_NOT_FOUND', this, (eventArgs) => new Notifier().Warning(eventArgs.data.Message || NOTIFICATIONS.TYPES_NOT_FOUND));

            typeScreen.On('TYPE_SELECTED', this, (eventArgs) => {

                const typeId = eventArgs.data.TypeId;
                const kindId = eventArgs.data.KindId;
                const exampleRecordId = eventArgs.data.ExampleRecordId;

                this.NewRecord(typeId, kindId, exampleRecordId, searchScreen.SearchTerm());
            });

            this.On('NEW_RECORD_CREATED', this, () => {
                searchScreen.Cancel();
            });

            typeScreen.Show();
        });

        searchScreen.Show();
    }

    EditRecord(): void {

    }

    NewRecord(tableTypeId: number, kindId: number, exampleRecordId: number, searchTerm?: string) {
        ScreenManager.GetEditScreen({
            EntityId: this.GetEntityId(),
            TableTypeId: tableTypeId,
            KindId: kindId,
            RecordId: exampleRecordId,
            LoadAsExample: exampleRecordId > 0
        })
            .then((screen: EditScreen) => {
                if (!screen) {
                    new Notifier($(this._el)).Warning(NOTIFICATIONS.EDIT_SCREEN_NOT_EXISTS);

                    return;
                }

                LockManager.Instance.ReleaseAllLocks();
                const editScreen = screen;

                editScreen.IsDataFromExample = exampleRecordId > 0;
                editScreen.ParentRecordId = this.GetRecordId();
                editScreen.UseLinking = true;

                if (searchTerm) {
                    this.NameControlSetValue(editScreen, searchTerm);
                }

                screen.On('RECORD_SAVED', this, (eventArgs) => {
                    const notifier = new Notifier($(this._el));
                    notifier.Success(NOTIFICATIONS.RECORD_CREATED);
                    this.Trigger('NEW_RECORD_CREATED');
                    this.LoadData(eventArgs.data.RecordId);
                });

                screen.ShowInModal();
            });
    }

    CreateRecord() {
        const showTypeSelector = () => {
            const typeScreen = new TypeScreen(this.GetEntityId(), this.GetTableTypeId(), true);

            const SearchControl = this.GetTextSearchTermControl();
            const searchTerm = SearchControl ? SearchControl.GetValue() : '';

            typeScreen.On('TYPES_NOT_FOUND', this, (eventArgs) => new Notifier().Warning(eventArgs.data.Message || NOTIFICATIONS.TYPES_NOT_FOUND));

            typeScreen.On('TYPE_SELECTED', this, (eventArgs) => {
                const typeId = eventArgs.data.TypeId;
                const kindId = eventArgs.data.KindId;
                const exampleRecordId = eventArgs.data.ExampleRecordId;

                this.NewRecord(typeId, kindId, exampleRecordId, searchTerm);
            });

            typeScreen.Show();
        };

        if (this.IsInModal()) {
            showTypeSelector();
            return;
        }

		const profileSelectorScreen = new ProfileSelectorScreen(this.GetEntityId(), this.GetEntityName());

        profileSelectorScreen
            .On('PROFILES_NOT_FOUND', this, () => {
                new Notifier().Warning('Profiles with create possibilities not found');
            })
            .On('PROFILE_SELECTION_CANCELLED', this, () => {
                profileSelectorScreen.Close();
            })
            .On('PROFILE_SELECTED', this, () => {
                profileSelectorScreen.Close();
                showTypeSelector();
            })
            .On('USED_CURRENT_PROFILE', this, () => {
                profileSelectorScreen.Close();
                showTypeSelector();
            });

        profileSelectorScreen.ShowIfNeeded();
    }

    LoadData(recordId: number) {
        this.LoadScreenFor(recordId);
    }

    LoadScreenFor(recordId: number) {
        if (this.IsDashMain) {
            this._grid.UpdateTableViewList();
        } else {
            ScreenManager.GetScreenByScreenType(this.GetEntityId(), ScreenTypes.ConsultScreen, recordId)
                .then(screen => {
                    screen.SetIsReady(true);
                    MenuManager.Instance.GoToScreen(screen);
                })
                .fail(err => {
                    new Notifier().Warning(err.message);

                });
        }
    }

    private NameControlSetValue(screen: EditScreen, value: string) {
        const control = screen.GetControlByFieldName('NAME', CONTROL_TYPES.Text);

        if (control) {
            const controlData = new ControlDataModel();

            controlData.FieldId = control.FieldModel.Id;
            controlData.Value = value;
            controlData.DisplayValue = value;

            const controlValue: IControlValue = {
                Data: controlData,
                SubjectEntityId: this._model.EntityId,
                SubjectRecordId: this._recordId,
                RecordSpecsModel: new RecordSpecsModel()
            };

            control.SetDefaultValue(controlValue);
        }
    }

    SetQuery(query: QueryExpressionModel, queryName: string = '') {
        if (this._grid) {
            this._grid.SetQuery(query);
            this._grid.SetSubjectRecord(this._recordId);
        }
        this._queryName = queryName;
    }
} 