export class NewRecordModel {
	TableId: number;
	Fields: Array<FieldModel>;

	constructor() {
		this.Fields = [];
	}
}

export class FieldModel {
	Id: number;
	Name: string;
	Value: any;
	Translations: TranslationValueModel[];

	constructor() {
		this.Translations = [];
	}
}

export class TranslationValueModel {
	constructor(
		public LanguageShortName: string,
		public Value: string
	) {}
}