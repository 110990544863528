import * as ko from 'knockout';
import 'jquery-qrcode';

import { Events } from "Auth/Enums/Events";
import { Event } from "Core/Common/Event";
import {CONFIRMATIONS, LABELS} from "Core/Components/Translation/Locales";

import TwoFaConfigurationFormTemplate from 'Auth/UI/TwoFaConfigurationForm/Templates/TwoFaConfigurationForm.html';
import { Modal } from 'Core/Common/Modal';
import { WebAuthnStore } from '../Stores/WebAuthnStore';
import { BinaryUtils } from '../../Core/Common/BinaryUtils';
ko.templates['Auth/UI/TwoFaConfigurationForm/Templates/TwoFaConfigurationForm'] = TwoFaConfigurationFormTemplate;

export class PasswordLessConfigurationForm extends Event {

    private _credentials: any;

    constructor(){
        super();
    }


    Load(_: string, dbName: string = '', login: string = ''){
        WebAuthnStore.BuildCredentialOptions(login, dbName).then((options: any)=>{
            options.challenge = BinaryUtils.CoerceToArrayBuffer(options.challenge);

            options.user.id = BinaryUtils.CoerceToArrayBuffer(options.user.id);
           
            options.excludeCredentials = options.excludeCredentials.map((c) => {
                c.id = BinaryUtils.CoerceToArrayBuffer(c.id);
                return c;
            });
           
            if (options.authenticatorSelection.authenticatorAttachment === null) options.authenticatorSelection.authenticatorAttachment = undefined;
           
            try {
                navigator.credentials.create({
                    publicKey: options
                }).then((newCredential: any)=>{
                    let attestationObject = new Uint8Array(newCredential.response.attestationObject);
                    let clientDataJSON = new Uint8Array(newCredential.response.clientDataJSON);
                    let rawId = new Uint8Array(newCredential.rawId);
                    
                    this._credentials = {
                        id: newCredential.id,
                        rawId: BinaryUtils.CoerceToBase64Url(rawId),
                        type: newCredential.type,
                        extensions: newCredential.getClientExtensionResults(),
                        response: {
                            attestationObject: BinaryUtils.CoerceToBase64Url(attestationObject),
                            clientDataJSON: BinaryUtils.CoerceToBase64Url(clientDataJSON)
                        }
                    };

                    this.Trigger(Events.SavePasswordLessCredentials);
                    
                });
            } catch (e) {
                var msg = "Could not create credentials in browser. Probably because the username is already registered with your authenticator. Please change username or authenticator."
                console.error(msg, e);
            }
        });
    };

    GetFormData(){
        return JSON.stringify(this._credentials);
    }
}