import * as ko from 'knockout';

export class RemoteDatabaseConnection {
    private _connected: KnockoutObservable<boolean>;

    constructor(public Id: number, public Name: string, public Url: string, public RemoteUserName: string, connected: boolean) {
        this._connected = ko.observable(connected);
    }

    get Connected() {
        return this._connected();
    }

    set Connected(value: boolean) {
        this._connected(value);
    }
}