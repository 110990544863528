import { Text } from 'QueryBuilder/QueryCondition/ConditionEditors/Text/Text';
import * as ko from 'knockout';
import * as _ from 'underscore';

import 'signalR';

import ace, { Ace } from 'ace-builds';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-csharp';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/snippets/text';
import 'ace-builds/src-noconflict/snippets/csharp';
import 'ace-builds/src-noconflict/snippets/javascript';
import 'ace-builds/src-noconflict/worker-javascript';
import 'ace-builds/src-noconflict/ext-searchbox';

import 'ace-builds/src-noconflict/theme-dracula';
import 'ace-builds/src-noconflict/theme-ambiance';
import 'ace-builds/src-noconflict/theme-chaos';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/theme-clouds';
import 'ace-builds/src-noconflict/theme-clouds_midnight';
import 'ace-builds/src-noconflict/theme-cobalt';
import 'ace-builds/src-noconflict/theme-crimson_editor';
import 'ace-builds/src-noconflict/theme-dawn';
import 'ace-builds/src-noconflict/theme-dracula';
import 'ace-builds/src-noconflict/theme-dreamweaver';
import 'ace-builds/src-noconflict/theme-eclipse';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-gob';
import 'ace-builds/src-noconflict/theme-gruvbox';
import 'ace-builds/src-noconflict/theme-idle_fingers';
import 'ace-builds/src-noconflict/theme-iplastic';
import 'ace-builds/src-noconflict/theme-katzenmilch';
import 'ace-builds/src-noconflict/theme-kr_theme';
import 'ace-builds/src-noconflict/theme-kuroir';
import 'ace-builds/src-noconflict/theme-merbivore';
import 'ace-builds/src-noconflict/theme-merbivore_soft';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-mono_industrial';
import 'ace-builds/src-noconflict/theme-pastel_on_dark';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/theme-solarized_light';
import 'ace-builds/src-noconflict/theme-sqlserver';
import 'ace-builds/src-noconflict/theme-terminal';
import 'ace-builds/src-noconflict/theme-textmate';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/theme-tomorrow_night';
import 'ace-builds/src-noconflict/theme-tomorrow_night_blue';
import 'ace-builds/src-noconflict/theme-tomorrow_night_bright';
import 'ace-builds/src-noconflict/theme-tomorrow_night_eighties';
import 'ace-builds/src-noconflict/theme-twilight';
import 'ace-builds/src-noconflict/theme-vibrant_ink';
import 'ace-builds/src-noconflict/theme-xcode';

import {FunctionDesignerStore} from 'Core/Controls/FunctionDesigner/Stores/FunctionDesignerStore';
import {P} from 'Core/Common/Promise';
import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier, MessageTypes} from 'Core/Common/Notifier';
import {Modal} from "Core/Common/Modal";
import {FunctionValidationModel} from 'Core/Controls/FunctionDesigner/Models/FunctionValidationModel';
import {SyntaxErrorModel} from 'Core/Controls/FunctionDesigner/Models/SyntaxErrorModel';
import {FunctionResult} from 'Core/Controls/FunctionDesigner/FunctionResult';
import {FunctionDesignerMethodModel} from 'Core/Controls/FunctionDesigner/Models/FunctionDesignerMethodModel';
import {CookieManager} from 'Core/Common/CookieManager';
import {FUNCTION_TYPES} from 'Core/Controls/FunctionDesigner/FunctionTypes';
import {
    Types as DecisionDialogTypes,
    EVENTS as DECISION_DIALOG_EVENTS,
    DecisionDialog
} from "Core/Components/Dialogs/DecisionDialog/DecisionDialog";
import {LABELS} from "Core/Components/Translation/Locales";
import {Event} from "Core/Common/Event";

import CodeEditorTemplate from 'Core/Controls/FunctionDesigner/Templates/CodeEditor.html';
import { Guid } from '../../Common/Guid';
import { FunctionBuilder } from 'Core/Components/CustomFunctions/FunctionBuilder';
import { PUB_SUB_EVENTS } from 'MenuManager/PubSubEvents';
import { ColorSelector } from '../../Components/ColorSelector/ColorSelector';
import Split from 'split.js';
import { SignalRNotificationManager } from '../../Components/SignalR/SignalRNotificationManager';
import { Tooltip } from '../../Common/Tooltip';
import { event } from 'jquery';

ko.templates['Core/Controls/FunctionDesigner/Templates/CodeEditor'] = CodeEditorTemplate;

var customObjects = ['FinancialProcessor', 'DocumentHandling', 'RecordAPI', 'SecurityAPI', 'ShowMessage()', 'ConsoleLog()', '_userDbProvider', '_cdDbProvider', 'param', 'result'];

var customObjectProperties = {
    '_userDbProvider.': ['MapAsKeyValueList()', 'MapAsKeyValuePair()'],
    '_cdDbProvider.': ['MapAsKeyValueList()', 'MapAsKeyValuePair()'],
    'FinancialProcessor.': ['CallFinancialProcessor()'],
    'DocumentHandling.': ['AddToMergeTemplateQueue()', 'AddToSendDocumentQueue()', 'AddToPDFConversionQueue()'],
    'RecordAPI.': ['GetRecords()', 'Get()', 'CreateFromExample()', 'LinkRecord()', 'SetMain()', 'SetReference()', 'SetNextAllowedLifeStatus()'],
    'InvoicingAPI': ['CreateInvoice()'],
    'SecurityAPI.': ['ChangeOwner()', 'ShareRecord()', 'UpsertSecurityRecord()'],
    'UserAPI': ['GetSecurityProfile()', 'GetUserGroup()', 'GetUser()'],
    'MatchingAPI': ['RunSpimMatching()'],
    'TableManager.': ['GetById(), GetTableStructAsync()'],
    'param.': [],
    'result.': [],
    'NotificationTypes.': ['Success', 'Warning', 'Failed']
};

var customObjectsCompleter = {
    getCompletions: (editor, session, pos, prefix, callback) => {
        callback(null, customObjects.map((word) => {
            return {
                caption: word,
                value: word
            };
        }));
    }
};

var customPropertiesCompleter = {
    getCompletions: (editor, session, pos, prefix, callback) => {
        var position = editor.getCursorPosition();
        var currentLine = editor.session.getLine(position.row);
        var properties = [];

        if (currentLine) {
            var lastWord = _.last(currentLine.split(' '));

            if (customObjectProperties[`${lastWord}`]) {
                properties = customObjectProperties[`${lastWord}`];
            }
        }

        callback(null, properties.map((word) => {
            return {
                caption: word,
                value: word
            };
        }));
    }
};

const EDITOR_MODES = {
    JAVASCRIPT: 'ace/mode/javascript',
    CSHARP: 'ace/mode/csharp'
};

var langTools = ace.require('ace/ext/language_tools');
langTools.addCompleter(customObjectsCompleter);

(ace as any).config.set("workerPath", `${__webpack_public_path__}ace/`);

interface IImageLog{
    title: string;
    image: string;
}

export class CodeEditor extends Event {
    private _modal: Modal;
    private _method: FunctionDesignerMethodModel;
    private _validation: KnockoutObservable<FunctionValidationModel>;
    private _packageId: number;
    private _editor: any;
    private _logEditor: Ace.Editor;
    private _originalCode;
    private _labels = LABELS;
    private _guid: string;

    private _selectedTheme: KnockoutObservable<string>;

    private _selectedFontSize: KnockoutObservable<string>;
    private _selectedFontFamily: KnockoutObservable<string>;
    private _currentTheme: string;
    private _toolbarButtonBackgroundColor: KnockoutObservable<string>;
    private _toolbarButtonBackgroundHoverColor: KnockoutObservable<string>;

    private _toolbarButtonColor: KnockoutObservable<string>;
    private _toolbarButtonHoverColor: KnockoutObservable<string>;

    private _hoverTag: string;

    private _fontSizes = ['15px','16px','17px','18px','19px','20px', '21px', '22px','23px', '24px','25px'];

    //private _fontFamilies = ["Monaco, Menlo, 'Ubuntu Mono', Consolas, source-code-pro, monospace", 'Consolas', 'Open Sans', 'Courier New'];

    private _themes = [
    'ambiance',
    'chaos',
    'chrome',
    'clouds',
    'clouds_midnight',
    'cobalt',
    'crimson_editor',
    'dawn',
    'dracula',
    'dreamweaver',
    'eclipse',
    'github',
    'gob',
    'gruvbox',
    'idle_fingers',
    'iplastic',
    'katzenmilch',
    'kr_theme',
    'kuroir',
    'merbivore',
    'merbivore_soft',
    'monokai',
    'mono_industrial',
    'pastel_on_dark',
    'solarized_dark',
    'solarized_light',
    'sqlserver',
    'terminal',
    'textmate',
    'tomorrow',
    'tomorrow_night',
    'tomorrow_night_blue',
    'tomorrow_night_bright',
    'tomorrow_night_eighties',
    'twilight',
    'vibrant_ink',
    'xcode']

    private _imageLogs: KnockoutObservableArray<IImageLog>;

    constructor(method: FunctionDesignerMethodModel, packageId: number) {
        super();
        this.AddEvents();
        this._editor = null;
        this._validation = ko.observable(null);
        this._packageId = packageId;
        this._method = method;
        this.PrepareParams();
        this.PreventWindowConfirmation();
        this._guid = Guid.NewGuid();
        this._selectedTheme = ko.observable('');
        this._selectedFontSize = ko.observable('15px');
        this._selectedFontFamily = ko.observable('Monaco');
        this._currentTheme = '';
        this._toolbarButtonBackgroundColor = ko.observable('');
        this._toolbarButtonBackgroundHoverColor = ko.observable('');

        this._toolbarButtonColor = ko.observable('');
        this._toolbarButtonHoverColor = ko.observable('');
        this._imageLogs = ko.observableArray([]);
        
        PubSub.subscribe(PUB_SUB_EVENTS.EXECUTE_SCRIPT, (_, data) => {
            if(data && data.ObjectId === this._guid){
                FunctionBuilder.Execute(data.Code, data.Params);
            }
        });

        this._selectedFontSize.subscribe((newValue)=>{
            this._editor.setFontSize(newValue);
        })

        this._selectedFontFamily.subscribe((newValue)=>{
            this._editor.setOption("fontFamily", newValue);
        })
    }

    AddEvents() {
        this.AddEvent('ON_CHANGED_PAGE_LEAVING');
    }

    PreventWindowConfirmation() {
        const eventWindowConfirmation = (event) => {
            if (!!this.HasChanges()) {
                // Cancel the event as stated by the standard.
                event.preventDefault();
                // Chrome requires returnValue to be set.
                event.returnValue = 'Would you like to leave the page?';
                return 'Would you like to leave the page?'
            } else {
                this.Trigger('ON_CHANGED_PAGE_LEAVING');
            }
        };

        window.addEventListener('beforeunload', eventWindowConfirmation);

        this.On('ON_CHANGED_PAGE_LEAVING', this, () => {
            window.removeEventListener('beforeunload', eventWindowConfirmation);
        });
    }

    private PrepareParams() {
        customObjectProperties['param.'] = this._method.InParams.map(param => param.Name.replace(' ', '_'));
        customObjectProperties['result.'] = this._method.OutParams.map(param => param.Name.replace(' ', '_'));
    }

    private EvalFunction(code, param, Modal: any): P.Promise<any> {
        try {
            var deferredResult = P.defer<any>();
            eval(code);
            return deferredResult.promise();
        } catch (e) {
            deferredResult.reject({message: e.message});
            return deferredResult.promise();
        }
    }

    get Validation(): KnockoutObservable<FunctionValidationModel> {
        return this._validation;
    }

    Show() {
        var self = this;
        this._modal = new Modal({
            width: '100%',
            height: '100%',
            closeOnEsc: false,
            closeButton: false
        }, false);

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);


        this.ShowModal();
    }

    Close(evt) {
        evt.preventDefault();
        evt.stopImmediatePropagation();
        this.Cancel();
    }

    GetTemplateName(): string {
        return 'Core/Controls/FunctionDesigner/Templates/CodeEditor';
    }

    Cancel() {
        if (this.HasChanges()) {
            const decisionDialog = new DecisionDialog({
                Text: this._labels.CONFIRMATION_FOR_UNSAVED_CHANGES,
                Type: DecisionDialogTypes.Question,
            });

            decisionDialog.On(
                DECISION_DIALOG_EVENTS.CONFIRM_SELECTED,
                this,
                () => {
                    this.UpdateCode();
                });

            decisionDialog.On(
                DECISION_DIALOG_EVENTS.DISCARD_SELECTED,
                this,
                () => {
                    if (this._modal) {
                        this._modal.Close();
                        this.Trigger('ON_CHANGED_PAGE_LEAVING');
                    }
                });

            decisionDialog.Show();
        } else {
            if (this._modal) {
                this._modal.Close();
            }
            this.Trigger('ON_CHANGED_PAGE_LEAVING');
        }
    }

    HasChanges(): boolean {
        return this._originalCode != this._editor.getValue();
    }

    GoToErrorLine(error: SyntaxErrorModel) {
        if (this._editor) {
            this._editor.focus();
            this._editor.gotoLine(error.Line)
        }
    }

    GetCode() {
        var Range = ace.require('ace/range').Range;
        return this._editor.session.getTextRange(new Range(3, 0, this._editor.session.getLength() - 2, 0));
    }

    Test() {
        if (this._editor) {
            this._imageLogs([]);
            this._logEditor.session.setValue(null);
            BlockUI.Block();
            FunctionDesignerStore.Test({PackageId: this._packageId, MethodId: this._method.Id, Code: this.GetCode(), ObjectId: this._guid})
                .always(() => {
                    BlockUI.Unblock();
                })
                .then((result) => {
                    this.pasteScriptDebugLog(JSON.stringify(result.Data));
                }).fail(err => new Notifier().Failed(err.message));
        }
    }

    UpdateCode() {
        if (this._editor) {
            BlockUI.Block();
            FunctionDesignerStore.UpdateCode({Id: this._method.Id, Code: btoa(this.GetCode())})
                .always(() => {
                    BlockUI.Unblock();
                })
                .then((functionPackage) => {
                    this._method.Code = this._editor.getValue();
                    this._modal.Close();
                    this.Trigger('ON_CHANGED_PAGE_LEAVING');
                }).fail(err => new Notifier().Failed(err.message));
        }
    }

    Validate() {
        if (this._editor) {
            BlockUI.Block();
            FunctionDesignerStore.Validate({
                MethodId: this._method.Id,
                Code: this.GetCode(),
                PackageId: this._packageId
            })
                .always(() => {
                    BlockUI.Unblock();
                })
                .then((result) => {
                    this._validation(result);
                }).fail(err => new Notifier().Failed(err.message));
        }
    }

    ShowModal() {
        if (this._modal) {
            this._modal.Show();
        }
    }

    AfterRender(el: Array<HTMLElement>) {
        var Range = ace.require('ace/range').Range;
        var aceEditor = ace;
        var topNonEditableMarkerId = null;
        var bottomNonEditableMarkerId = null;

        var mode = '';
        if (this._method.TypeName === FUNCTION_TYPES.CSHARP_FUNCTION || this._method.TypeName === FUNCTION_TYPES.API_METHOD) {
            mode = EDITOR_MODES.CSHARP;
        }

        if (this._method.TypeName === FUNCTION_TYPES.JS_FUNCTION) {
            mode = EDITOR_MODES.JAVASCRIPT;
        }

		var editor = ace.edit(document.getElementById('csharp-code'));
		this._editor = editor;
		editor.setOption("showPrintMargin", false);
		editor.setTheme('ace/theme/ambiance');
        editor.setFontSize('15px');

        this._logEditor = ace.edit(document.getElementById('log-editor'));
		this._logEditor.setTheme('ace/theme/ambiance');
        this._logEditor.session.setValue('>');
        this._logEditor.session.setUseWorker(false);
        this._logEditor.setShowPrintMargin(false);
        this._logEditor.setReadOnly(true);
        this._logEditor.setFontSize('15px');


        SignalRNotificationManager.Instance.SetScriptDebugEditor(this._logEditor);

        SignalRNotificationManager.Instance.On('PASTE_IMAGE', this, (evtArgs)=>this.PasteImage(evtArgs.data.title, evtArgs.data.image));

        this._modal.AddClass('ace-ambiance');

		editor.session.setMode(mode);
		editor.session.setValue(this._method.Code);
		this._originalCode = this._editor.getValue();

        editor.session.selection.on('changeCursor', function(e) {
            var rowCol = editor.selection.getCursor();
            if ((rowCol.row < 3)) {
                editor.moveCursorTo(3,0);
                e.preventDefault();
                e.stopPropagation();                
            }

            if ((editor.session.getLength() - (rowCol.row + 1) < 2)) {
                editor.moveCursorTo(editor.session.getLength() - 3, 0);
                e.preventDefault();
                e.stopPropagation();                
            }
        });

        (editor.commands as any).on('exec', (e) => {
            var rowCol = editor.selection.getCursor();
            if ((rowCol.row < 3) || (editor.session.getLength() - (rowCol.row + 1) < 2)) {
                e.preventDefault();
                e.stopPropagation();                
            }
            setTimeout(() => {
                editor.session.removeMarker(topNonEditableMarkerId);
                editor.session.removeMarker(bottomNonEditableMarkerId);
                topNonEditableMarkerId = editor.session.addMarker(new Range(0, 0, 3, 0), 'ace_active-line', 'text', false);
                bottomNonEditableMarkerId = editor.session.addMarker(new Range(editor.session.getLength() - 2, 0, editor.session.getLength(), 0), 'ace_active-line', 'text', false);
            }, 100);
        });

        (editor.commands as any).on('afterExec', (e, t) => {
            if (mode === EDITOR_MODES.CSHARP) {
                if (e.command.name == 'insertstring' && e.args == '.') {
                    var all = e.editor.completers;
                    e.editor.completers = [customPropertiesCompleter];
                    e.editor.execCommand('startAutocomplete');
                    e.editor.completers = all;
                }
            }
        });

        editor.setOptions({
            enableBasicAutocompletion: true,
            enableSnippets: true,
            enableLiveAutocompletion: true
        });

        setTimeout(() => {
            topNonEditableMarkerId = editor.session.addMarker(new Range(0, 0, 3, 0), 'ace_active-line', 'text', false);
            bottomNonEditableMarkerId = editor.session.addMarker(new Range(editor.session.getLength() - 2, 0, editor.session.getLength(), 0), 'ace_active-line', 'text', false);
        }, 100);


        Split(['#csharp-code', '#log-tabs'], {
            sizes: [80, 20],
            direction: 'vertical'
        })

        this._selectedTheme.subscribe((newValue)=>{
            this._editor.setTheme(`ace/theme/${newValue}`);
            this._logEditor.setTheme(`ace/theme/${newValue}`);
            this._modal.RemoveClass(`ace-${this._currentTheme.replace(/_/g, '-')}`);
            this._modal.AddClass(`ace-${newValue.replace(/_/g, '-')}`);

            let backgroundColor = $(`.ace-${newValue.replace(/_/g, '-')}`).css('background-color');
            this._toolbarButtonBackgroundColor(backgroundColor);
            this._toolbarButtonBackgroundHoverColor(this.invert(backgroundColor));

            let color = $(`.ace-${newValue.replace(/_/g, '-')}`).css('color');

            this._toolbarButtonColor(color);
            this._toolbarButtonHoverColor(this.invert(color));

            this._currentTheme = newValue;
        })

        this._selectedTheme('ambiance');

    }

    PasteImage(title: string, image: string){
        this._imageLogs.push({title: title, image: image});
    }

    Hover(_, event){
        if(event.target.tagName == 'A'){
            $(event.target).css( "background-color", this._toolbarButtonBackgroundHoverColor());
            $(event.target).css( "color", this._toolbarButtonHoverColor());
            $(event.target).children().css("background-color", this._toolbarButtonBackgroundHoverColor());
            $(event.target).children().css("color", this._toolbarButtonHoverColor());
        };

        if(event.target.tagName == 'I'){
            $(event.target).css( "background-color", this._toolbarButtonBackgroundHoverColor());
            $(event.target).css( "color", this._toolbarButtonHoverColor());
            $(event.target).parent().css("background-color", this._toolbarButtonBackgroundHoverColor());    
            $(event.target).parent().css("color", this._toolbarButtonHoverColor());    
        };
    }

    UnHover(_, event){
        if(event.target.tagName == 'A'){
            $(event.target).css("background-color", this._toolbarButtonBackgroundColor());
            $(event.target).children().css("background-color", this._toolbarButtonBackgroundColor());  
            
            $(event.target).css("color", this._toolbarButtonColor());
            $(event.target).children().css("color", this._toolbarButtonColor());  
        };
    }

    componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }
    
    rgbToHex(r, g, b) {
        return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    }
    
    invert(rgb) {
        rgb = [].slice.call(arguments).join(",").replace(/rgb\(|\)|rgba\(|\)|\s/gi, '').split(',');
        for (var i = 0; i < rgb.length; i++) rgb[i] = (i === 3 ? 1 : 255) - rgb[i];
        return this.rgbToHex(rgb[0], rgb[1], rgb[2]);
    }
    
    pasteScriptDebugLog(message: string){
        this._logEditor.session.insert({
            row: this._logEditor.session.getLength(),
            column: 0
        }, "\n" + message);

        this._logEditor.scrollToLine(this._logEditor.session.getLength(), true, true, function () {
        });
        this._logEditor.gotoLine(this._logEditor.session.getLength(), 0, true);
    }
}