import * as ko from 'knockout';

import 'prism';

const Prism = (<any>window).Prism;

export class PrismExtension {
	static Init() {
		ko.bindingHandlers.prism = {
			init: (element, valueAccessor) => {
				element.innerHTML = Prism.highlight(valueAccessor(), Prism.languages.sql);
			},

			update: (element, valueAccessor) => {
				element.innerHTML = Prism.highlight(valueAccessor(), Prism.languages.sql);
			}
		}
	}
}