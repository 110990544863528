import * as ko from 'knockout'
import * as _ from "underscore";

import { MailStore } from 'Core/Controls/Mail/Stores/MailStore'
import { BlockUI } from 'Core/Common/BlockUi'
import { MailTabModel } from 'Core/Controls/Mail/Models/MailTabModel'
import { Event } from 'Core/Common/Event';
import { Notifier } from 'Core/Common/Notifier';
import { ComposeMail } from 'Core/Controls/Mail/ComposeMail';
import { IComposerOptionsModel } from 'Core/Controls/Mail/Models/ComposerOptionsModel';
import { FieldFormat } from 'Core/Common/FieldFormat';
import { QueryResultPage } from 'Core/Controls/Grid/BaseGrid/QueryResultPage/QueryResultPage';
import { GenericDeserialize, Serialize } from 'libs/cerialize';
import { QueryExpressionModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";
import { QueryParamsPage } from 'QueryBuilder/QueryParamsPage/QueryParamsPage';
import {EVENTS as QUERY_RESULT_PAGE_EVENTS} from "Core/Controls/Grid/BaseGrid/QueryResultPage/Events";
import {
	FormatManagerStore,
	ICreateEmlMessageRequestModel
} from 'Core/Components/FormatManager/Stores/FormatManagerStore';
import { EVENTS as QUERY_PARAMS_PAGE_EVENTS } from 'QueryBuilder/QueryParamsPage/Events';
import { IControlParam, IScreen } from 'Core/Screens/IScreen';

import SQLViewTemplate from 'Core/Components/FormatManager/FormatTemplates/SQLView.html'
import SocialLinkViewTemplate from 'Core/Components/FormatManager/FormatTemplates/SocialLinkView.html'
import SkypeViewTemplate from 'Core/Components/FormatManager/FormatTemplates/SkypeView.html'
import LinkedinViewTemplate from 'Core/Components/FormatManager/FormatTemplates/LinkedinView.html'
import MailViewTemplate from 'Core/Components/FormatManager/FormatTemplates/MailView.html'
import UrlViewTemplate from 'Core/Components/FormatManager/FormatTemplates/UrlView.html'
import LocalUrlViewTemplate from 'Core/Components/FormatManager/FormatTemplates/LocalUrlView.html'


ko.templates['Core/Components/FormatManager/FormatTemplates/SQLView'] = SQLViewTemplate;
ko.templates['Core/Components/FormatManager/FormatTemplates/SocialLinkView'] = SocialLinkViewTemplate;
ko.templates['Core/Components/FormatManager/FormatTemplates/SkypeView'] = SkypeViewTemplate;
ko.templates['Core/Components/FormatManager/FormatTemplates/LinkedinView'] = LinkedinViewTemplate;
ko.templates['Core/Components/FormatManager/FormatTemplates/MailView'] = MailViewTemplate;
ko.templates['Core/Components/FormatManager/FormatTemplates/UrlView'] = UrlViewTemplate;
ko.templates['Core/Components/FormatManager/FormatTemplates/LocalUrlView'] = LocalUrlViewTemplate;

import { ScreenTypes } from '../../Common/Enums/ScreenTypes';
import { JBoxDropDown } from '../JBoxDropdown/DropDown';
import {GridRow} from "Core/Controls/Grid/BaseGrid/GridRow/GridRow";
import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";

const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol [1]
	'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name [2]
	'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
	'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
	'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
	'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

const socialFormatsEnum = {
	Linkedin: FieldFormat.LINKEDIN,
	Facebook: FieldFormat.FACEBOOK,
	YouTube: FieldFormat.YOUTUBE,
	Twitter: FieldFormat.TWITTER,
	Instagram: FieldFormat.INSTAGRAM,
	Pinterest: FieldFormat.PINTEREST,
	Medium: FieldFormat.MEDIUM,
};

export let FormatsEnum = {
	...socialFormatsEnum,
	Mail: FieldFormat.MAIL,
	SQL: FieldFormat.SQL,
	URL: FieldFormat.URL,
	LOCALURL: FieldFormat.LOCALURL,
	SKYPE: FieldFormat.SKYPE,
	Linkedin: FieldFormat.LINKEDIN,
	GetFormat: (name: string) => {
		const formatKeys= Object.keys(FormatsEnum);
		const key = _.find(formatKeys, (formatKey: any) => FieldFormat[formatKey] === name);
		return key;
	}
};

let TemplatesToFormats = [
	{ Format: FieldFormat.FACEBOOK, Template: SocialLinkViewTemplate },
	{ Format: FieldFormat.YOUTUBE, Template: SocialLinkViewTemplate },
	{ Format: FieldFormat.TWITTER, Template: SocialLinkViewTemplate },
	{ Format: FieldFormat.INSTAGRAM, Template: SocialLinkViewTemplate },
	{ Format: FieldFormat.PINTEREST, Template: SocialLinkViewTemplate },
	{ Format: FieldFormat.MEDIUM, Template: SocialLinkViewTemplate },
	{ Format: FieldFormat.SQL, Template: SQLViewTemplate },
	{ Format: FieldFormat.URL, Template: UrlViewTemplate },
	{ Format: FieldFormat.MAIL, Template: MailViewTemplate },
	{ Format: FieldFormat.SKYPE, Template: SkypeViewTemplate },
	{ Format: FieldFormat.LINKEDIN, Template: LinkedinViewTemplate },
	{ Format: FieldFormat.LOCALURL, Template: LocalUrlViewTemplate }
];

let TemplateNamesToFormats = [
	{ Format: FieldFormat.FACEBOOK, Template: 'Core/Components/FormatManager/FormatTemplates/SocialLinkView' },
	{ Format: FieldFormat.YOUTUBE, Template: 'Core/Components/FormatManager/FormatTemplates/SocialLinkView' },
	{ Format: FieldFormat.TWITTER, Template: 'Core/Components/FormatManager/FormatTemplates/SocialLinkView' },
	{ Format: FieldFormat.INSTAGRAM, Template: 'Core/Components/FormatManager/FormatTemplates/SocialLinkView' },
	{ Format: FieldFormat.PINTEREST, Template: 'Core/Components/FormatManager/FormatTemplates/SocialLinkView' },
	{ Format: FieldFormat.MEDIUM, Template: 'Core/Components/FormatManager/FormatTemplates/SocialLinkView' },
	{ Format: FieldFormat.SQL, Template: 'Core/Components/FormatManager/FormatTemplates/SQLView' },
	{ Format: FieldFormat.URL, Template: 'Core/Components/FormatManager/FormatTemplates/UrlView' },
	{ Format: FieldFormat.MAIL, Template: 'Core/Components/FormatManager/FormatTemplates/MailView' },
	{ Format: FieldFormat.SKYPE, Template: 'Core/Components/FormatManager/FormatTemplates/SkypeView' },
	{ Format: FieldFormat.LINKEDIN, Template: 'Core/Components/FormatManager/FormatTemplates/LinkedinView' },
	{ Format: FieldFormat.LOCALURL, Template: 'Core/Components/FormatManager/FormatTemplates/LocalUrlView' }
];

export interface FormatParams {
	Value: string,
	Format: string,
	TableId: number,
	RecordId: number,
	Label?: string,
	Icon?: string,
	LabelPosition?: string,
	IsGrid?: boolean,
	LabelStyle?: any,
	Screen?: IScreen
}

export class FormatManager extends Event {
	private _value: string;
	private _formatName: string;
	private _tableId: number;
	private _recordId: number;
	private _label: string;
    private _icon: string;

	private _template: any;
	private _templateName: string;

	public HasTemplate: boolean;

	private _mails: KnockoutObservableArray<MailTabModel>;
	private _isGrid: boolean;
	private _hasMails: KnockoutComputed<boolean>;
	private _showMails: KnockoutComputed<boolean>;
	private _labelPosition: string;
	private _labels = LABELS;
	private _id: string;
	private _dropDown: JBoxDropDown;
	private _labelStyle: KnockoutObservable<any>;
	private _isIconVisible: boolean;
	private _screen: IScreen;

	constructor(params: FormatParams) {
		super();
		this._screen = params.Screen;
		this._value = params.Value;
		this._tableId = params.TableId;
		this._recordId = params.RecordId;
		this._formatName = params.Format;
		this._label = params.Label;
		this._labelPosition = params.LabelPosition;
		this._icon = params.Icon;
		this._isGrid = params.IsGrid;
        this._isIconVisible = GlobalManager.Instance.GetGlobal(GLOBALS.SHOW_CONTROL_ICONS) !== '0';
        this.GetLabelStyleColor(params);
		this.SetTemplate();

		if (FieldFormat.MAIL === this._formatName) {
			this.ApplyMailProperties();
		}
		this._id = JBoxDropDown.GetDropDownId();
	}

	private GetLabelStyleColor(params: FormatParams){
		if (!params.IsGrid && params.LabelStyle && params.LabelStyle() && params.LabelStyle().color){
			this._labelStyle = params.LabelStyle().color;
		} else {
			this._labelStyle = null;
		}
	}

	public static IsSocialFormat(formatName: string): boolean {
		return !!socialFormatsEnum[formatName];
	}

	public static IsSkypeFormat(formatName: string): boolean {
		return FieldFormat.SKYPE === formatName;
	}

	public static FormatUrlForSocial(url: string): string {
		const matches = url && url.match(urlPattern);
		if (matches) {
			if (!matches[1]) { // if no protocol we add it
				url = "http://" + url;
			}
			return url;
		}
		return null;
	}

	GetTemplate() {
		return this._template;
	}

	GetTemplateName() {
		return this._templateName;
	}

	private SetTemplate() {
		let templateToFormat = _.find(TemplatesToFormats, tf => {
			return tf.Format === this._formatName;
		});

		let templateNameToFormat = _.find(TemplateNamesToFormats, tf => {
			return tf.Format === this._formatName;
		});

		this.HasTemplate = !!templateToFormat;
		this._template = this.HasTemplate ? templateToFormat.Template : null;
		this._templateName = this.HasTemplate ? templateNameToFormat.Template : null;
	}

	//Mail format
	private ApplyMailProperties() {
		this._mails = ko.observableArray<MailTabModel>();
		this._hasMails = ko.computed(() => {
			return this._mails().length > 0;
		}, this);
		this._showMails = ko.computed(() => {
			return this._mails().length > 1;
		}, this);
	}

	private OpenDropDown() {
		this._dropDown = new JBoxDropDown({
			target: "." + this._id,
			otherOptions: {
				attach: undefined,
				closeOnClick: true,
				addClass: "mails-dropdown",
				onCloseComplete: () => this._dropDown.Destroy()
			},
			onOpen: () => {
				this._dropDown.SetContent({ content: $("." + this._id).next().data("jbox-content") });
			},
			bindComponent: this,
		});

		this._dropDown.Open();
	}

	private LoadTabs() {
		let TryOpenComposer = () => {
			if (!this._showMails()) {
				this.MailClick(this._mails()[0]);
			} else {
				this.OpenDropDown()
			}
		};

		if (this._hasMails()) {
			TryOpenComposer();
			return;
		}

		BlockUI.Block();
		MailStore.GetMailTabs()
			.then((tabs: any) => {
				//not issue something wrong with model
				_.map(tabs, (tab: any) => {
					let mail = new MailTabModel();
					mail.Login = tab.Login;
					mail.MailConnectionId = tab.MailConnectionId;
					mail.Client = tab.Client;
					this._mails.push(mail);
				});

				if (!this._hasMails()) {
					new Notifier().Failed(NOTIFICATIONS.PLEASE_ADD_YOUR_EMAIL);
				} else {
					TryOpenComposer();
				}
			})
			.always(() => BlockUI.Unblock())
			.fail((err) => new Notifier().Failed(err.message));
	}

	private MailClick(mail: MailTabModel) {
		if (!!this._value) {
			if (mail.Client === 'Desktop') {
				this.OpenDesktopClient(mail);
			} else {
				this.OpenInternalClient(mail);
			}

		} else {
			new Notifier().Failed(NOTIFICATIONS.PLEASE_ADD_EMAIL);
		}
	}

	private OpenDesktopClient(mail: MailTabModel) {
		var requestModel: ICreateEmlMessageRequestModel = {
			FromAddress: mail.Login,
			ToAddresses: [this._value]
		}
		BlockUI.Block();
		FormatManagerStore.CreateEmlMessage(requestModel).fail((err) => {
			new Notifier().Failed(err.message);
		}).always(() => {
			BlockUI.Unblock();
		});
	}

	private OpenInternalClient(mail: MailTabModel) {
		const composerOptions: IComposerOptionsModel = {
			ResponseMailItem: null,
			SubjectEntityId: this._screen?.GetEntityId(),
			SubjectRecordId: this._screen?.GetRecordId(),
			SubjectTypeId: this._screen?.GetTableTypeId()
		};
		let composeMail = new ComposeMail(composerOptions);

		composeMail.AddScreen(this._screen);
		composeMail.AddMailWithRecord(this._value, this._tableId, this._recordId);
		composeMail.ShowInModal(mail.MailConnectionId);
	}

	private RunQueryFromGrid(context: any) {

		if(context instanceof GridRow){
			let dataCells = context.DataCells;
			const nameCell = _.find(dataCells, (cell) => cell.Model.FieldMetadata.Name === 'NAME');
			const typeCell = _.find(dataCells, (cell) => cell.Model.FieldMetadata.Name === 'F_TYPE');
			const queryName: string = nameCell ? nameCell.Value : '';
			const typeName: string = typeCell ? typeCell.DisplayValue : '';
			this.RunQuery(this._value, typeName, queryName);
		}else{
			this.RunQuery(this._value, context.GetScreen().GetTableTypeName(), '');
		}
	}

	private StartLocalURL(){
		BlockUI.Block();
		FormatManagerStore.StartLocalUrl(this._value)
		.always(()=>{ BlockUI.Unblock() })
		.fail((err) => { new Notifier().Failed(err.message); });
	}

	public RunQuery(
		query: string,
		queryType: string,
		queryName: string,
		targetId = '',
		enableSelectRecord: boolean = false,
		planningDate: string = '',
		multiselect: boolean = true
	){
		if( queryType === 'Spim'){
			enableSelectRecord = true;
		}
		
		var queryModel = GenericDeserialize<QueryExpressionModel>(JSON.parse(query), QueryExpressionModel);
		var paramsPage = new QueryParamsPage(queryModel, queryType, planningDate);

		if (paramsPage.HasShowParameters()) {
			paramsPage.On(QUERY_PARAMS_PAGE_EVENTS.SAVE, this, () => {
				this.Trigger(QUERY_PARAMS_PAGE_EVENTS.SAVE);
				if (queryModel.QueryScreenId && queryModel.QueryScreenId > 0) {
					this.ShowQueryScreen(queryModel, queryName, targetId, enableSelectRecord);
				} else {
					this.ShowStaticQueryScreen(queryModel, targetId, enableSelectRecord, multiselect);
				}
			});
			paramsPage.Show();
		} else {
			if (queryModel.QueryScreenId && queryModel.QueryScreenId > 0) {
				this.ShowQueryScreen(queryModel, queryName, targetId, enableSelectRecord);
			} else {
				this.ShowStaticQueryScreen(queryModel, targetId, enableSelectRecord, multiselect);
			}
		}
	}

	private async ShowQueryScreen(queryModel: QueryExpressionModel, name: string, targetId = '', enableSelectRecord: boolean = false) {
		
		const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
		
		BlockUI.Block();
		screenManager.GetScreenById({ ScreenId: queryModel.QueryScreenId, RaiseNotImplemented :false})
			.always(() => {
				BlockUI.Unblock();
			})
			.then((screen: any) => {
				if (screen.GetTypeName() === ScreenTypes[ScreenTypes.QueryScreen]) {
					screen.SetQuery(queryModel, name);
					if(targetId != ''){
						screen.RenderByTargetId(targetId);
					}else{
						screen.ShowInModal();
					}					
				}
			}).fail((err) => {new Notifier().Failed(err.message);});
	}

	private ShowStaticQueryScreen(
		queryModel: QueryExpressionModel,
		targetId = '',
		enableSelectRecord: boolean = false,
		multiselect: boolean = true
	) {
		var queryResultPage = new QueryResultPage(true, ScreenTypes.ListScreen);
		queryResultPage.On(QUERY_RESULT_PAGE_EVENTS.RECORDS_SELECTED, this, (evtArgs) =>{
			this.Trigger(QUERY_RESULT_PAGE_EVENTS.RECORDS_SELECTED, { SelectedRecords: evtArgs.data.SelectedRecords });
		});

		queryResultPage.ShowQueryResult(queryModel, targetId, enableSelectRecord, multiselect);
	}

	private FormatUrl(value): string {
		return value && value.includes('://') ? value : `http://${value}`;
	}
}