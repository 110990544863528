import {AttachmentModel} from 'Core/Controls/Image/Models/AttachmentModel';
import {UpdateAnnotationsDto} from "../Components/ConfigurationImageGallery/Models/UpdateAnnotationsDto";

export const StartPageEvents = {
    Selected: 'StartPageEvents_Selected',
    Copied: 'StartPageEvents_Copied',
    Removed: 'StartPageEvents_Removed',
    ConfiguredProductSelected: 'StartPageEvents_ConfiguredProductSelected',
    AddNewProduct: 'StartPageEvents_AddNewProduct',
    ClosePopup: 'StartPageEvents_ClosePopup',
    TabSelected: 'StartPageEvents_TabSelected',
    SaveConfigurationData: 'StartPageEvents_SaveConfigurationData'
};

export class SaveConfigurationDataEventArgs {
    constructor(public Memo: string, public AddedImageAttachments: Array<AttachmentModel>, public RemovedImageIds: Array<number>, public UpdatedAnnotations: Array<UpdateAnnotationsDto>) {
    }
}