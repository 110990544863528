import * as ko from "knockout";

//Constants
import {FIELD_TYPES, RenderModes, TABLE_TYPES} from "Core/Constant";

//Controls
import {ComplexControl} from "Core/Controls/ComplexControl/ComplexControl";
import {IControlParam} from "Core/Screens/IScreen";
import {RequiredFieldModel} from "Core/Controls/ComplexControl/Models/RequiredFieldModel";
import {CONFIRMATIONS, LABELS} from "Core/Components/Translation/Locales";
import {BlockUI} from "Core/Common/BlockUi";
import {Notifier} from "Core/Common/Notifier";
import {SpimCopyStore} from "Core/Controls/SpimCopy/Stores/SPIMCopyStore";
import {IControlValue} from "Core/Controls/BaseControl/BaseControl";
import {Grid} from "Core/Controls/Grid/Grid";
import {SearchScreen} from "Core/Screens/SearchScreen/SearchScreen";

import ViewTemplate from "Core/Controls/SpimCopy/Templates/View.html";
import HelpViewTemplate from "Core/Controls/SpimCopy/Templates/HelpView.html";
import ToolBarTemplate from "Core/Controls/SpimCopy/Templates/ToolBar.html";

import { SPIMCopyControlModel } from 'Core/Controls/SpimCopy/Models/SPIMCopyControlModel';
import {
    ConfirmationDialog, EVENTS as ConfirmationDialogEvents,
    Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

ko.templates['Core/Controls/SPIMCopy/Templates/Design'] = ComplexControl.designTemplate;
ko.templates['Core/Controls/SPIMCopy/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/SPIMCopy/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/SPIMCopy/Templates/View'] = ViewTemplate;

export class SPIMCopy extends ComplexControl {
    protected _labels = LABELS;
    private _selectedClassId: number;
    private _viewModel: SPIMCopyControlModel;
    constructor(params: IControlParam) {
        super(params);
        this._selectedClassId = null;
        this._viewModel = new SPIMCopyControlModel();

        this.Init();
    }

    ApplyProperties(){}

    Init() {
        this._requiredFields([
            new RequiredFieldModel('F_FEATURE', FIELD_TYPES.Lookup, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('F_UNIT', FIELD_TYPES.Lookup, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('F_FEATURETYPE', FIELD_TYPES.Lookup, TABLE_TYPES.Sub, null)
        ]);

        this.InitRequiredFields();
    }

    SetValue(value: IControlValue): void  {
        if (this._renderMode() !== RenderModes.ToolBar && this._renderMode() !== RenderModes.Design) {
            if(this._isRendered()) {
                this.LoadClass();
            } else {
                this._isRendered.subscribe(() => this.LoadClass());
            }
        }
    }

    FillViewModel(data) {
        this._viewModel.EtimLabel(`${data.EtimCode} - ${data.Name}`);
        this._viewModel.CurrentVersionNumber(data.CurentVersionNumber);
        this._viewModel.UpdateAvailable(data.NewVersionNumber);
        const grid = this._parentControl as Grid;
        grid.LoadData();
    }

    LoadClass() {
        BlockUI.Block();
        const params = {
            ClassFeaturesTableId: this.FieldModel.EntityId,
            ProductsEntityId: this.GetForm().GetScreen().GetEntityId(),
            ProductId: this.GetForm().GetScreen().GetRecordId()
        };
        SpimCopyStore.GetClass(params).always(() => {
            BlockUI.Unblock();
        })
            .then(data => {
                this._viewModel.IsDefaultState(false);
                this._selectedClassId = data.Id;
                this.FillViewModel(data);
        })
            .fail(error => {
                this._viewModel.IsDefaultState(true);
                if(error.status !== 404) {
                    new Notifier().Failed(error.message);
                }
            });
    }

    AssignClass(recordId) {
        BlockUI.Block();
        const params = {
            ClassFeaturesTableId: this.FieldModel.EntityId,
            ProductFeaturesTableId: this._parentControl.GetFieldModel().EntityId,
            ProductId: this.GetForm().GetScreen().GetRecordId(),
            ClassId: recordId
        };
        SpimCopyStore.AssignClass(params).always(() => {
            BlockUI.Unblock();
        })
            .then(() => {
                this.LoadClass();
        })
            .fail(error => {
                new Notifier().Failed(error.message);
            });
    }

    private SendUpdateVersionRequest() {
        BlockUI.Block();
        const params = {
            ClassFeaturesTableId: this.FieldModel.EntityId,
            ProductFeaturesTableId: this._parentControl.GetFieldModel().EntityId,
            ProductId: this.GetForm().GetScreen().GetRecordId(),
            ClassId: this._selectedClassId
        };
        SpimCopyStore.UpdateVersion(params).always(() => {
            BlockUI.Unblock();
        })
            .then(() => {
            this.LoadClass();
        })
            .fail(error => {
                new Notifier().Failed(error.message);
            });
    }

    UpdateVersion() {
        if(this._viewModel.UpdateAvailable()) {
            const confirmationDialog = new ConfirmationDialog({
                Text: CONFIRMATIONS.ARE_SURE_TO_UPDATE_VERSION,
                Type: ConfirmationTypes.Question
            });
            confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, () => {
                this.SendUpdateVersionRequest();
            });
            confirmationDialog.Show();
        }
    }

    Search(): void {
        const searchScreen = new SearchScreen({
            EntityName: 'SYS_THESAURI',
            SearchTerm: '',
            CloseAfterSelectingRecord: false
        });

        searchScreen.On('RECORD_SELECTED', this, (eventArgs) => {
            if(eventArgs.data.TypeName !== 'Class') {
                new Notifier().Warning(this._labels.CLASS_RECORD_SHOULD_BE_SELECTED);
                return;
            }
            var recordId = eventArgs.data.RecordId;
            if(this._selectedClassId) {
                this._selectedClassId = recordId;
                const confirmationDialog = new ConfirmationDialog({
                    Text: CONFIRMATIONS.ARE_SURE_TO_CHANGE_CLASS,
                    Type: ConfirmationTypes.Question
                });
                confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, () => {
                    searchScreen.Close();
                    this.AssignClass(recordId);
                });
                confirmationDialog.Show();
            } else {
                this._selectedClassId = recordId;
                searchScreen.Close();
                this.AssignClass(recordId);
            }
        });

        searchScreen.Show();
    }
}