import * as ko from 'knockout';

import {TABLE_TYPES} from 'Core/Constant';

import {EntityControlEditor} from '../EntityControlEditor';
import {IControl} from 'Core/Controls/IControl';

import {EntityModel} from '../../Models/EntityModel';

import Template from 'Core/Screens/DesignScreen/ControlEditor/Templates/BookControlEditor.html';

ko.templates['Core/Screens/DesignScreen/ControlEditor/Templates/BookControlEditor'] = Template;

export class BookControlEditor extends EntityControlEditor {
    constructor(control: IControl) {
        super(control);
    }

    GetTemplateName(): string {
        return 'Core/Screens/DesignScreen/ControlEditor/Templates/BookControlEditor';
    }

    protected BindEvents() {
        super.BindEvents();
        this.SelectedEntity.subscribe(() => {
            this.ResetSelectOnlyProperty();
        });
    }

    protected PopulateEntities(entities: EntityModel[]) {
        entities = entities.filter(e => e.TypeName === TABLE_TYPES.Entity);

        this.Entities(entities);

        const attachedField = _.first(this.EditControlModel.Fields);
        const attachedEntity = attachedField && _.find(entities, entity => entity.EntityId === attachedField.EntityId);

        if (attachedEntity) {
            this.SelectedEntity(attachedEntity);
        } else {
            const subjectEntity = _.find(entities, entity => entity.EntityId === this.SubjectEntityId);
            this.SelectedEntity(subjectEntity);
        }

        this.InitField(this.SelectedEntity());
        this.BindEvents();

        if (!attachedEntity) {
            this.ResetSelectOnlyProperty();
        }
    }

    private ResetSelectOnlyProperty() {
        this.EditControl.GeneralProperties.ResetProperty('SelectOnly');
    }
}