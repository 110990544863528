import * as ko from 'knockout';
import * as _ from 'underscore';

import {Notifier} from "Core/Common/Notifier";
import {RenderModes} from "Core/Constant";
import {GeneralProperties} from "Core/GeneralProperties/GeneralProperties";

import {BaseControl} from "Core/Controls/BaseControl/BaseControl";
import {IControlParam} from "Core/Screens/IScreen";
import {QueryResultPage} from "Core/Controls/Grid/BaseGrid/QueryResultPage/QueryResultPage";
import {QueryParamsPage} from 'QueryBuilder/QueryParamsPage/QueryParamsPage';
import {EVENTS as QUERY_PARAMS_PAGE_EVENTS} from 'QueryBuilder/QueryParamsPage/Events';
import {QueryExpressionModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import {GenericDeserialize} from 'libs/cerialize';

import  QueryResultConfig from 'Core/Controls/QueryResult/Configs/query-result-config.json';

import ViewTemplate from "Core/Controls/QueryResult/Templates/View.html";
import HelpViewTemplate from "Core/Controls/QueryResult/Templates/HelpView.html";
import ToolBarTemplate from "Core/Controls/QueryResult/Templates/ToolBar.html";
import DesignTemplate from "Core/Controls/QueryResult/Templates/Design.html";
import EditTemplate from "Core/Controls/QueryResult/Templates/Edit.html";
import {BlockUI} from "../../Common/BlockUi";
import {ScreenTypes} from "../../Common/Enums/ScreenTypes";

ko.templates['Core/Controls/QueryResult/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/QueryResult/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/QueryResult/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/QueryResult/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/QueryResult/Templates/Edit'] = EditTemplate;

export class QueryResult extends BaseControl {
	constructor(params: IControlParam) {
		super(params, QueryResultConfig);

		this.Init();
	}

	Init(){}

	ApplyProperties(){}

	RunQuery() {
		if(this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
		} else {
			const queryProperties = this.Properties
				&& this.Properties.Query
				&& this.Properties.Query.Properties;

			const queryExpressionProperty = _.find<any>(queryProperties, property => property.Query);

			if (queryExpressionProperty) {
				var queryModel = GenericDeserialize<QueryExpressionModel>(JSON.parse(queryExpressionProperty.Query), QueryExpressionModel);
				var paramsPage = new QueryParamsPage(queryModel, '');
				if (paramsPage.HasShowParameters()) {
					paramsPage.On(QUERY_PARAMS_PAGE_EVENTS.SAVE, this, (evtArgs) => {
						if (queryModel.QueryScreenId && queryModel.QueryScreenId > 0) {
							this.ShowQueryScreen(queryModel);
						} else {
							this.ShowStaticQueryScreen(queryModel);
						}
					});
					paramsPage.Show();
				} else {
					if (queryModel.QueryScreenId && queryModel.QueryScreenId > 0) {
						this.ShowQueryScreen(queryModel);
					} else {
						this.ShowStaticQueryScreen(queryModel);
					}
				}

			} else {
				new Notifier().Warning('Query expression was not created');
			}
		}
	}

	private async ShowQueryScreen(queryModel: QueryExpressionModel) {
		const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

		BlockUI.Block();
		screenManager.GetScreenById({ ScreenId: queryModel.QueryScreenId, RaiseNotImplemented: false })
			.always(() => {
				BlockUI.Unblock();
			})
			.then((screen: any) => {
				if (screen.GetTypeName() === ScreenTypes[ScreenTypes.QueryScreen]) {
					screen.SetRecordId(this.GetForm().GetScreen().GetRecordId());
					screen.SetQuery(queryModel);
					screen.ShowInModal();
				}
			}).fail((err) => { new Notifier().Failed(err.message); });
	}

	private ShowStaticQueryScreen(queryModel: QueryExpressionModel) {
		var queryResultPage = new QueryResultPage(true, ScreenTypes.ListScreen);
		queryResultPage.RecordId = this.GetForm().GetScreen().GetRecordId();
		queryResultPage.ShowQueryResult(queryModel);
	}
}