import * as ko from 'knockout';
import * as _ from 'underscore';
import {Icon} from "Core/Icon/Icon";
import {DEFAULT_ICONS} from "Core/Constant";
import {IControlParam, IScreen} from 'Core/Screens/IScreen';
import {MailStore} from 'Core/Controls/Mail/Stores/MailStore';
import {BlockUI} from 'Core/Common/BlockUi';
import {RenderModes, FIELD_TYPES, TABLE_TYPES} from 'Core/Constant';
import {ComposeMail} from 'Core/Controls/Mail/ComposeMail';
import {MailList} from 'Core/Controls/Mail/MailList';
import {Detail} from 'Core/Controls/Mail/Detail';
import {MailItemModel} from 'Core/Controls/Mail/Models/MailItemModel';
import {GetMailsResponseModel} from 'Core/Controls/Mail/Models/GetMailsResponseModel';
import {GetTabsResponseModel} from 'Core/Controls/Mail/Models/GetTabsResponseModel';
import {Notifier} from 'Core/Common/Notifier';
import {ComplexControl} from 'Core/Controls/ComplexControl/ComplexControl';
import {RequiredFieldModel} from 'Core/Controls/ComplexControl/Models/RequiredFieldModel';
import {MailFolder} from 'Core/Controls/Mail/Enums/MailFolder';
import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel';
import {EntityTypesStore} from 'Core/Screens/TypeScreen/Stores/EntityTypesStore';

import {MailItemExtendedModel} from 'Core/Controls/Mail/Models/MailItemExtendedModel';
import {ScreenDataModel} from '../../ScreenManager/Models/ScreenDataModel';
import {ControlDataModel} from '../../ScreenManager/Models/ControlDataModel';

import {InsertOrUpdateRecordStore} from 'Core/Screens/EditScreen/Stores/InsertOrUpdateRecordStore';
import {DataModel} from 'Core/Screens/EditScreen/Models/DataModel';
import {ISendMailRequestModel} from 'Core/Controls/Mail/Stores/MailStore';
import {COMPOSER_EVENTS, MAIL_LIST_EVENTS} from 'Core/Controls/Mail/Events';
import {NOTIFICATIONS, LABELS} from "Core/Components/Translation/Locales";

import {CONTROL_TYPES} from "Core/Constant";
import {LinkList} from "Core/Controls/LinkList/LinkList";
import {EditScreen} from "Core/Screens/EditScreen/EditScreen";
import {NewRelationModel} from "Core/Controls/LinkList/Models/NewRelationModel";

import {DocumentStore} from 'Core/Controls/Document/Stores/DocumentStore';
import { SECURITY_LEVELS } from "Core/Constants/SecurityLevels";


import ViewTemplate from 'Core/Controls/Mail/Templates/View.html';
import ToolBarTemplate from 'Core/Controls/Mail/Templates/ToolBar.html';
import EditTemplate from 'Core/Controls/Mail/Templates/Edit.html';
import enumerable from '../../Common/Decorators/EnumerableDecorator';

ko.templates['Core/Controls/Mail/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Mail/Templates/Design'] = ComplexControl.designTemplate;
ko.templates['Core/Controls/Mail/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Mail/Templates/Edit'] = EditTemplate;

enum MailTypes {
	In,
	Out
}

export class Mail extends ComplexControl {
	private _mailList: KnockoutObservable<MailList>;
	private _currentFolder: KnockoutObservable<MailFolder>;
	private _loadInProgress: boolean;
	private _mailItems: KnockoutObservableArray<MailItemModel>;

	private _recordNameField: AttachedFieldModel;
	private _recordTypeField: AttachedFieldModel;
	private _mailBodyField: AttachedFieldModel;
	private _mailFromField: AttachedFieldModel;
	private _mailGuidField: AttachedFieldModel;

	private _mailsCount: KnockoutObservable<number>;
	private _itemPerPage: KnockoutObservable<number>;
	private _currentPage: KnockoutObservable<number>;
	private _firstMailIndex: KnockoutObservable<number>;
	private _lastMailIndex: KnockoutObservable<number>;
	private _totalPage: KnockoutObservable<number>;
	private _tabNames: KnockoutObservable<object>;
	private _mailConnectionId: number;
	private _oldMailContentId: number;
	private _tabNumber: number;
	private _isLoad: boolean;

	constructor(params: IControlParam) {
		super(params);

		this._model().IsComplexControl = true;
		this._currentFolder = ko.observable(MailFolder.Inbox);
		this._mailsCount = ko.observable(0);
		this._itemPerPage = ko.observable(10);
		this._currentPage = ko.observable(1);
		this._firstMailIndex = ko.observable(1);
		this._lastMailIndex = ko.observable(0);
		this._totalPage = ko.observable(0);
		this._mailList = ko.observable(null);
		this._isLoad = false;
		this._mailConnectionId = 0;
		this._tabNumber = 0;
		this._tabNames = ko.observable(null);
		this._mailItems = ko.observableArray([]);

		this.Init();
	}

	ApplyProperties(){}

	private Init(): void {
		this.SetDefaultIcon(new Icon(DEFAULT_ICONS.Mail));
		//TODO save mail body as document
		if (this.GetRenderMode() === RenderModes.View) {
			this._recordNameField = this._model().Fields[0];
			this._recordTypeField = this._model().Fields[1];
			//this._mailBodyField = this._model().Fields[2];
			this._mailFromField = this._model().Fields[2];
			this._mailGuidField = this._model().Fields[3];
			this.LoadTabs();
			this._loadInProgress = true;
		}


		this._requiredFields([
			new RequiredFieldModel('NAME', FIELD_TYPES.Text, TABLE_TYPES.Entity, null),
			new RequiredFieldModel('F_TYPE', FIELD_TYPES.Lookup, TABLE_TYPES.Entity, null),
			new RequiredFieldModel('BODY', FIELD_TYPES.Document, TABLE_TYPES.Entity, null),
			new RequiredFieldModel('SERVERMAILID', FIELD_TYPES.Text, TABLE_TYPES.Entity, null)
		]);

		this.InitRequiredFields();
	}

	@enumerable get CurrentFolder(): string {
		return MailFolder[this._currentFolder()];
	}

	Compose() {
		this._currentFolder(null);

		const composeMail = new ComposeMail({ResponseMailItem: null});

		composeMail.AddScreen(this._form.GetScreen());
		composeMail.ShowInModal(this._mailConnectionId);

		//composeMail.On(COMPOSER_EVENTS.EMAIL_SENT, this, (eventArgs) => {
		//	this.SaveEmail(eventArgs.data.Mail, eventArgs.data.Guid);
		//});
	}

	SaveEmail(sendMail: ISendMailRequestModel, Guid: string) {

		// TODO provide as complex control
		DocumentStore.GetDocumentEntityId().then(documentEntityId => {
			EntityTypesStore
				.GetTypes({
					EntityId: this._form.GetScreen().GetEntityId(),
					ParentTypeId: 0,
					WithRoot: false,
					OnlyEnabled: true
				})
				.then((entityTypes) => {
					const outType = _.find(entityTypes.TableTypes, (type) => {
						return type.Name === MailTypes[MailTypes.Out];
					});

					if (outType) {
						const dataModel = new DataModel();

						dataModel.EntityTypeId = outType.Id;
						dataModel.EntityId = this._form.GetScreen().GetEntityId();

						const guidData = [];

						guidData.push(`${this._mailGuidField.EntityName}.${this._mailGuidField.Name}`);
						guidData.push(Guid);
						dataModel.FieldValueList.push(guidData);

						const nameData = [];

						nameData.push(`${this._recordNameField.EntityName}.${this._recordNameField.Name}`);
						nameData.push(sendMail.Subject);
						dataModel.FieldValueList.push(nameData);

						const bodyData = [];

						if (this._mailBodyField) {
							bodyData.push(`${this._mailBodyField.EntityName}.${this._mailBodyField.Name}`);
							bodyData.push(sendMail.Body);
							dataModel.FieldValueList.push(bodyData);
						}

						const fromMailData = [];

						fromMailData.push(`${this._mailFromField.EntityName}.${this._mailFromField.Name}`);

						let fromMail = '';

						_.each(sendMail.Recipients, (recipient) => fromMail = `${recipient.Mail}`);

						fromMailData.push(fromMail);
						dataModel.FieldValueList.push(fromMailData);

						const newRelations = [];

						_.each(sendMail.Attachements, (attachment) => {
							newRelations.push({
								ControlId: 0,
								RecordId: 0,
								RelatedEntityId: documentEntityId,
								RelatedRecordId: attachment.DocumentId
							});
						});

						dataModel.LinklistChanges = {NewRelations: newRelations};

						InsertOrUpdateRecordStore.UpdateData(dataModel)
							.then(() => {
								new Notifier().Success(NOTIFICATIONS.RECORD_SAVED);
							});

					} else {
						new Notifier().Failed(NOTIFICATIONS.OUT_TYPE_NOT_FOUND);
					}
				});
		});
	}

	UpdatePagination() {
		this._totalPage(Math.ceil(this._mailsCount() / this._itemPerPage()));

		this._firstMailIndex(this._currentPage() * this._itemPerPage() - this._itemPerPage() + 1);

		let expectedLastNumber = this._currentPage() * this._itemPerPage();

		if (expectedLastNumber < this._mailsCount()) {
			this._lastMailIndex(expectedLastNumber);
		} else {
			this._lastMailIndex(this._mailsCount());
		}
	}

	PrevPage() {
		let expectedPage = this._currentPage() - 1;

		if (expectedPage >= 1) {
			this._currentPage(this._currentPage() - 1);
		}

		this.LoadData();
	}

	NextPage() {
		let expectedPage = this._currentPage() + 1;

		if (expectedPage <= this._totalPage()) {
			this._currentPage(this._currentPage() + 1);
		}

		this.LoadData();
	}

	SwitchItemPerPage(number, element, bindedData) {
		this._itemPerPage(number);
		this._currentPage(1);
		this._firstMailIndex(1);

		this.LoadData();
	}

	IsDefaultNumber(number) {
		return this._itemPerPage() === number;
	}

	LoadTabs() {
		let self = this;

		this.BlockUI();

		MailStore.GetMailTabs()
			.always(() => {
				self.UnblockUI();
			})
			.then((tabs: GetTabsResponseModel) => {
				this._tabNames(tabs);
				let count = 0;
				let i;

				for (i in tabs) {
					if (tabs.hasOwnProperty(i)) {
						count++;
					}
				}

				if (this._tabNumber >= count) {
					new Notifier().Warning(NOTIFICATIONS.PLEASE_ADD_ACCOUNT);
				} else {
					this._mailConnectionId = tabs[this._tabNumber].MailConnectionId ?
						tabs[this._tabNumber].MailConnectionId :
						tabs[this._tabNumber + 1].MailConnectionId;

					this.LoadData();
				}
			})
			.fail((err) => {
				new Notifier().Warning(err.message);
			});
	}

	SwitchTab(mailConnectionId) {
		this._oldMailContentId = this._mailConnectionId;
		this._mailConnectionId = mailConnectionId;
		this._currentPage(1);

		this.LoadData();
	}

	LoadData() {
		this.BlockUI();

		const self = this;

		MailStore.GetMailFolder({
			MailConnectionId: this._mailConnectionId,
			PageSize: this._itemPerPage(),
			Page: this._currentPage()
		})
			.always(() => {
				self.UnblockUI();

				this._loadInProgress = false;
			})
			.then((mailItems: GetMailsResponseModel) => {


				this._mailsCount(mailItems.TotalItems);
				this.UpdatePagination();
				this._totalPage(Math.ceil(this._mailsCount() / this._itemPerPage()));
				this._mailItems(mailItems.Items);

				this.InitView(this._mailItems);
			})
			.fail((err) => {
				if (this._oldMailContentId) {
					this._mailConnectionId = this._oldMailContentId;
				} else {
					this._tabNumber = this._tabNumber + 1;
					this.LoadTabs();
				}

				new Notifier().Warning(err.message);
			});
	}

	BlockUI() {
		if (this._el) {
			BlockUI.Block({Target: this._el});
		} else {
			BlockUI.Block();
		}
	}

	UnblockUI() {
		if (this._el) {
			BlockUI.Unblock(this._el);
		} else {
			BlockUI.Unblock();
		}
	}

	InitView(mailItems: KnockoutObservableArray<MailItemModel>) {
		this._mailList(new MailList(mailItems(), this._currentFolder(), this._mailConnectionId));

		this._mailList().On(MAIL_LIST_EVENTS.LOAD_DATA, this, (eventArgs: any) => {
			if (!_.isEmpty(eventArgs.data)) {
				this._isLoad = false;
			}

			this.LoadData();
		});

		this._mailList().On(MAIL_LIST_EVENTS.SAVE_TO_CYBER_BOX, this, (eventArgs) => {
			const mailItem = eventArgs.data as MailItemModel;

			BlockUI.Block();

			MailStore
				.GetMailById({
					MailConnectionId: this._mailConnectionId,
					Guid: mailItem.Guid,
					IsIncludeAttachmentContent: true
				})
				.always(() => {
					BlockUI.Unblock();
				})
				.then((data) => {
					this.SaveToEditScreen(data);
				})
				.fail((err) => {
					new Notifier().Warning(err.message);
				});
		});
	}

	ShowEditScreen(screenData: ScreenDataModel, mailItemDetail: MailItemExtendedModel) {
		BlockUI.Block();

		EntityTypesStore
			.GetTypes({
				EntityId: this._form.GetScreen().GetEntityId(),
				ParentTypeId: 0,
				WithRoot: false,
				OnlyEnabled: true
			})
			.always(() => {
				BlockUI.Unblock();
			})
			.then(async(entityTypes) => {
				const inType = _.find(entityTypes.TableTypes, (type) => type.Name === MailTypes[MailTypes.In]);
				if (inType) {
					
					const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;;

					screenManager.GetEditScreen({
						EntityId: this.GetForm().GetScreen().GetEntityId(),
						TableTypeId: inType.Id
					}).always(() => {
						BlockUI.Unblock();
					}).fail((err) => {
						var notifier = new Notifier();
						notifier.Failed(err.message);
					}).then(screen => {
						screen.ShowInModal();
						
						screen.SetDefaultData(screenData, [CONTROL_TYPES.LinkList]);
						screen.On('LINK_LIST_DATA_LOADED', this, () => {
							this.LinkMailRelatedData(screen, mailItemDetail);
						});
					});
				} else {
					new Notifier().Failed(NOTIFICATIONS.IN_TYPE_NOT_FOUND);
				}
			});
	}

	private LinkMailRelatedData(screen: IScreen, mailItemDetail: MailItemExtendedModel) {
		
		let isLinkListExist = screen.HasControl(CONTROL_TYPES.LinkList);

		if (!isLinkListExist) {
			return;
		}

		const linkList = screen.GetControl(CONTROL_TYPES.LinkList) as LinkList;

		const usersRelationModel = linkList.DataModel().UserRelation();
		mailItemDetail.MailRelatedData.Users.forEach(user => {
			const existingLink = linkList.FindLinkByRecord(user.Id, usersRelationModel);
			if (existingLink) {
				return;
			}

			let newRelation = new NewRelationModel();
			newRelation.Name = user.Name;
			newRelation.Id = user.Id;
			newRelation.IsMain = false;
			newRelation.Level = SECURITY_LEVELS.SHARED;

			linkList.AddRecord(user.Id, usersRelationModel, newRelation);
		});

		mailItemDetail.MailRelatedData.Entities.forEach(entity => {
			const relationModel = _.find(linkList.DataModel().Entities(), relatedEntity => {
				return relatedEntity.EntityId === entity.Id;
			});

			if (!relationModel) {
				return;
			}

			if (entity.Items.length === 0) {
				return;
			}

			const groupsById = _.groupBy(entity.Items, item => item.Id);

			for (const groupIndex in groupsById) {
				const item = groupsById[groupIndex][0];
				const addressTypesId = groupsById[groupIndex].map(item => item.AddressTypeId).join(';');

				let newRelation = new NewRelationModel();
				newRelation.Name = item.Name;
				newRelation.Id = item.Id;
				newRelation.IsMain = false;

				const guid = linkList.AddRecord(item.Id, relationModel, newRelation);
				linkList.UpdateCustomDataByGuid(guid,
					[
						{
							FieldId: entity.AddressTypeFieldId,
							FieldValue: addressTypesId
						}
					]);
			}
		});
	}

	SaveToEditScreen(mailItemDetail: MailItemExtendedModel) {
		const DOCUMENT_FIELD_ID = 680;

		const screenData = new ScreenDataModel();
		const nameControlData = new ControlDataModel();

		if (this._recordNameField) {
			nameControlData.FieldId = this._recordNameField.Id;
			nameControlData.Value = mailItemDetail.Subject;
			screenData.ControlsData.push(nameControlData);
		}

		if (this._mailBodyField) {
			const mailBodyControlData = new ControlDataModel();

			mailBodyControlData.FieldId = this._mailBodyField.Id;
			mailBodyControlData.Value = mailItemDetail.Body;
			screenData.ControlsData.push(mailBodyControlData);
		}

		if (this._mailFromField) {
			const mailFromControlData = new ControlDataModel();

			mailFromControlData.FieldId = this._mailFromField.Id;
			mailFromControlData.Value = mailItemDetail.FromAddress;
			screenData.ControlsData.push(mailFromControlData);
		}

		if (this._mailGuidField) {
			const serverMailIdControlData = new ControlDataModel();

			serverMailIdControlData.FieldId = this._mailGuidField.Id;
			serverMailIdControlData.Value = mailItemDetail.Guid;
			screenData.ControlsData.push(serverMailIdControlData);
		}

		const dropControlData = new ControlDataModel();

		dropControlData.FieldId = DOCUMENT_FIELD_ID;

		if (mailItemDetail.Attachments.length > 0) {
			const dropControlValues = [];

			_.each(mailItemDetail.Attachments, (attachment) => {
				dropControlValues.push({FileName: attachment.Name, Content: attachment.Content});
				dropControlData.Values = dropControlValues;
			});

			screenData.ControlsData.push(dropControlData);
		}

		this.ShowEditScreen(screenData, mailItemDetail);
	}

	Detail(mailItem: MailItemModel) {
		this.BlockUI();

		MailStore
			.GetMailById({
				MailConnectionId: this._mailConnectionId,
				Guid: mailItem.Guid,
				IsIncludeAttachmentContent: false
			})
			.always(() => {
				this.UnblockUI();
			})
			.then((data) => {
				const detail = new Detail(data);

				detail.On(COMPOSER_EVENTS.EMAIL_WAS_READ, this, (eventArgs) => {
					this._mailList().MarkAsReadByGuid(eventArgs.data.guid);
				});

				detail.ShowInModal(this._mailConnectionId);
			})
			.fail((err) => {
				new Notifier().Warning(err.message);
			});
	}

	AfterRender(el: Array<HTMLElement>): void {
		super.AfterRender(el);

		if (this._loadInProgress) {
			this.BlockUI();
		} else {
			this.UnblockUI();
		}
	}
}