import * as ko from "knockout";
import * as $ from "jquery";
import * as _ from 'underscore';
import "bootstrapContextMenu";

import {EventHandlerCallback, PortletManagerEventHandlersFactory} from "Core/Portlets/Utils/PortletManagerEventHandlersFactory"

import {EVENTS} from "Core/Constant";
import {P} from 'Core/Common/Promise';
import {Notifier} from "Core/Common/Notifier";

import {PortletRenderModes} from "Core/Constant"
import {BasePortletManager} from "Core/Portlets/Managers/Base/BasePortletManager"
import {DesignedPortletSpace} from "Core/Portlets/Models/Store/DesignedPortletSpace"
import {DesignedPortlet} from "Core/Portlets/Models/Store/DesignedPortlet"
import {PortletSpace} from "Core/Portlets/Models/Runtime/PortletSpace"
import {EntityPortlet} from "Core/Portlets/Models/Design/Explorer/EntityPortlet"
import {PortletManager} from "Core/Portlets/Managers/Runtime/PortletManager"
import {PortletDesignManager, IDraggable} from "Core/Portlets/Managers/Design/PortletDesignManager"
import {PortletSpaceStore} from "Core/Portlets/Stores/PortletSpaceStore"
import {PortletStates} from "Core/Portlets/Enums/PortletStates"

import {
	PortletSpaceEditorModal,
	ISavePortletSpaceParams,
	EVENTS as PORTLET_SPACE_EDITOR_EVENTS
} from "Core/Portlets/Modals/PortletSpaceEditorModal/PortletSpaceEditorModal";

import {
	Types as DecisionDialogTypes,
	EVENTS as DECISION_DIALOG_EVENTS,
	DecisionDialog
} from "Core/Components/Dialogs/DecisionDialog/DecisionDialog";
import {
	ConfirmationDialog,
	Types as DialogTypes,
	EVENTS as DIALOG_EVENTS
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

import {IPortletSpaceParams} from "Core/Portlets/IPortletSpaceParams";

import {BlockUI} from 'Core/Common/BlockUi'
import {LABELS} from "Core/Components/Translation/Locales";

import PortletSpaceTemplate from "Core/Portlets/Templates/PortletSpace.html";
import Rejection = P.Rejection;

ko.templates["Core/Portlets/Templates/PortletSpace"] = PortletSpaceTemplate;

export class PortletSpaceManager extends BasePortletManager {
	private _params: IPortletSpaceParams;
	private _mainPortletSpaceId: number;

	private _portletSpaces: KnockoutObservableArray<PortletSpace>;
	private _portletSpace: KnockoutObservable<PortletSpace>;

	private _portletManagers1: KnockoutObservableArray<PortletManager>;
	private _portletManagers2: KnockoutObservableArray<PortletManager>;
	private _portletManagers3: KnockoutObservableArray<PortletManager>;

	private _newPortlets: Array<PortletManager>;
	private _removedPortlets: Array<PortletManager>;

	private _portletDesignManager: KnockoutObservable<PortletDesignManager>;
	private _isReady: KnockoutObservable<boolean>;

	private _isRendered: KnockoutObservable<boolean>;
	private _portletsAreLoaded: KnockoutObservable<boolean>;

	private _labels = LABELS;

	IsChanged: KnockoutObservable<boolean>;
	IsMain: KnockoutComputed<boolean>;

	constructor(params: IPortletSpaceParams) {
		super();

		this._params = params;
		this._portletSpaces = ko.observableArray([]);
		this._portletSpace = ko.observable(null);
		this._portletDesignManager = ko.observable(null);

		this._portletManagers1 = ko.observableArray([]);
		this._portletManagers2 = ko.observableArray([]);
		this._portletManagers3 = ko.observableArray([]);

		this.IsChanged = ko.observable(false);
		this.IsMain = ko.computed(() => this._portletSpace() && this._portletSpace().ObjectId === this._mainPortletSpaceId);

		this._isReady = ko.observable(true);

		this._isRendered = ko.observable(false);
		this._portletsAreLoaded = ko.observable(false);
	}

	get IsReady(): boolean {
		return true;
	}

	SwitchRenderMode(renderMode: PortletRenderModes) {
		super.SwitchRenderMode(renderMode);

		this.GetPortletManagers().forEach((columnManagers: KnockoutObservableArray<PortletManager>) =>
			columnManagers().forEach((manager: PortletManager) => manager.SwitchRenderMode(renderMode))
		);
	}

	GetTemplateName(): string {
		return "Core/Portlets/Templates/PortletSpace";
	}

	AfterRender() {
		this._isRendered(true);
		return this.IsReady;
	}

	Load() {
		return this.LoadPortletSpaces()
			.then(() => {
				if (this._mainPortletSpaceId) {
					return this.LoadPortlets(this._mainPortletSpaceId)
				}
			});
	}

	ChangePortletSpace(portletSpaceId: number) {
		if (this.IsChanged()) {
			const decisionDialog = new DecisionDialog({
				Text: this._labels.CONFIRMATION_FOR_UNSAVED_CHANGES,
				Type: DecisionDialogTypes.Question,
			});

			decisionDialog.On(
				DECISION_DIALOG_EVENTS.CONFIRM_SELECTED,
				this,
				() => this.SaveChanges()
					.then(() => this.SwitchPortletSpace(portletSpaceId))
					.then(() => this.EnableDesignMode())
					.fail(error => this.ShowError(error)));

			decisionDialog.On(
				DECISION_DIALOG_EVENTS.DISCARD_SELECTED,
				this,
				() => this.SwitchPortletSpace(portletSpaceId)
					.then(() => this.EnableDesignMode())
					.fail(error => this.ShowError(error)));

			decisionDialog.Show();
			return;
		}

		return this.SwitchPortletSpace(portletSpaceId).then(() => this.EnableDesignMode());
	}

	MakeMain() {
		if (this._params.Control) {
			this.BindToControl();
		}
		else {
			this.SavePortletSpaceInfo(this._portletSpace().Name, true);
		}
	}

	EnableDesignMode() {
		this._newPortlets = new Array<PortletManager>();
		this._removedPortlets = new Array<PortletManager>();

		this.SwitchRenderMode(PortletRenderModes.Design);

		if (this._portletDesignManager() !== null) {
			this._portletDesignManager().InitDesignMode();
			return;
		}

		return PortletSpaceStore.GetPortletsExplorer()
			.then(portletsCollection => {
				this._portletDesignManager(new PortletDesignManager(portletsCollection));
				this._portletDesignManager()
					.On(EVENTS.PORTLETS.NEW_PORTLET_ADDED, this, (eventArgs: any) => this.AddNewPortlet(eventArgs.data))
					.On(EVENTS.PORTLETS.PORTLET_POSITION_CHANGED, this, (eventArgs: any) => this.ChangePortletPosition(eventArgs.data));
			});
	}

	EnableViewMode() {
		this.SwitchRenderMode(PortletRenderModes.View);

		this._newPortlets = new Array<PortletManager>();
		this._removedPortlets = new Array<PortletManager>();

		this._portletDesignManager().DestroyDragAndDrop();
		this.ProcessEachManager((columnManagers, manager) => {
			manager.SetState(PortletStates.NoChanges);
			manager.SwitchRenderMode(PortletRenderModes.View);
		});
	}

	SaveChanges() {
		let designedPortletSpace = DesignedPortletSpace.Map(this._portletSpace());

		let portletGuidToInfo = this._portletDesignManager().GetPositions().ToArray();

		portletGuidToInfo.forEach(pair => {
			this.GetPortletManagerByCriteria((manager: PortletManager) => manager.Portlet.Guid === pair.Key).then(manager => {
				designedPortletSpace.Portlets.push(DesignedPortlet.Map(manager.Portlet, pair.Value.Position, manager.Portlet.State));
			});
		});

		this._removedPortlets.forEach((manager: PortletManager) =>
			designedPortletSpace.Portlets.push(DesignedPortlet.Map(manager.Portlet, manager.Portlet.Position, manager.Portlet.State))
		);

		return PortletSpaceStore.UpdatePortletSpace(designedPortletSpace)
			.then(() => {
				this.IsChanged(false);
				new Notifier().Success(this._labels.PORTLET_SPACE_WAS_UPDATED);
				return this.ChangePortletSpace(this._portletSpace().ObjectId);
			})
			.fail(error => this.ShowError(error));
	}

	BackToView() {
		this.EnableViewMode();
		if (this._mainPortletSpaceId) {
			this.SwitchPortletSpace(this._mainPortletSpaceId).fail(error => this.ShowError(error));
		}
	}

	RollbackChanges() {
		return this.SwitchPortletSpace(this._portletSpace().ObjectId)
			.then(() => this.EnableDesignMode())
			.fail(error => this.ShowError(error));
	}

	RemovePortlet(portletManager: PortletManager) {
		if (portletManager.Portlet.State === PortletStates.New) {
			let index = this._newPortlets.indexOf(portletManager);
			this._newPortlets.splice(index, 1);
			return;
		}

		portletManager.SetState(PortletStates.Deleted);
		this._removedPortlets.push(portletManager);
		this.IsChanged(true);
	}

	CreatePortletSpace() {
		if (this.IsChanged()) {
			const decisionDialog = new DecisionDialog({
				Text: 'You have unsaved changes. Do you want to save them?',
				Type: DecisionDialogTypes.Question
			});

			decisionDialog.On(DECISION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => this.SaveChanges().then(() => this.CreatePortletSpaceForce()));
			decisionDialog.On(DECISION_DIALOG_EVENTS.DISCARD_SELECTED, this, () => {
				this.SwitchPortletSpace(this._portletSpace().ObjectId)
					.then(() => this.EnableDesignMode())
					.then(() => this.CreatePortletSpaceForce());
			});
			decisionDialog.Show();

			return;
		}

		this.CreatePortletSpaceForce();
	}

	EditPortletSpace() {
		const portletSpace = this._portletSpace();

		const modal = new PortletSpaceEditorModal({
			Name: portletSpace.Name
		});

		modal.On(PORTLET_SPACE_EDITOR_EVENTS.SAVE_PORTLET_SPACE, this, eventArgs => {
			const params = eventArgs.data as ISavePortletSpaceParams;
			this.SavePortletSpaceInfo(params.Name).then(() => modal.Close());
		});

		modal.Show();
	}

	DeletePortletSpace() {
		if (this._portletSpace().ObjectId === this._mainPortletSpaceId) {
			new Notifier().Warning('You cannot delete main portlet space');
			return;
		}

		const dialog = new ConfirmationDialog({
			Text: this._labels.PORTLET_SPACE_WILL_BE_DELETED,
			Type: DialogTypes.Warning
		});

		dialog.On(DIALOG_EVENTS.CONFIRM_SELECTED, this, () => this.DeletePortletSpaceForce());

		dialog.Show();
	}

	private GetClasses(portletsColumnNumber: number) {
		if (this.IsInDesignMode()) {
			return 'design-mode';
		}

		let classes = [];
		const division = [this._portletManagers1()[0], this._portletManagers2()[0], this._portletManagers3()[0]].filter(portlet => !!portlet).length;

		let columnManager = null;

		switch (portletsColumnNumber) {
			case 1:
				columnManager = this._portletManagers1();
				break;
			case 2:
				columnManager = this._portletManagers2();
				break;
			case 3:
				columnManager = this._portletManagers3();
				break;
		}

		if(!columnManager[0]) {
			classes.push('portlet-block-empty');
			return classes.join(' ');
		}

		if (division !== 0) {
			classes.push('portlet-block-1-of-' + division);
		}

		return classes.join(' ');
	}

	private SavePortletSpaceInfo(name: string, isMain?: boolean) {
		const portletSpace = this._portletSpace();
		const portletSpaceId = portletSpace.ObjectId;

		BlockUI.Block();
		return PortletSpaceStore.UpdatePortletSpaceInfo(portletSpace.ObjectId, name, isMain)
			.then(() => {
				const portletSpaces = this._portletSpaces();
				const portletSpace = _.find(portletSpaces, ps => ps.ObjectId === portletSpaceId);

				if (isMain) {
					this._mainPortletSpaceId = portletSpaceId;
					portletSpaces.forEach(ps => ps.IsMain = false);
				}

				portletSpace.Name = name;
				portletSpace.IsMain = isMain;

				this._portletSpace().Name = name;
				this._portletSpace().IsMain = isMain;
				this._portletSpace.valueHasMutated();

				this._portletSpaces([]);
				this._portletSpaces(portletSpaces);
			})
			.fail(error => this.ShowError(error))
			.always(() => BlockUI.Unblock());
	}

	private BindToControl() {
		const portletSpace = this._portletSpace();
		const portletSpaceId = portletSpace.ObjectId;

		BlockUI.Block();
		return PortletSpaceStore.BindToControl(portletSpaceId, this._params.Control.Id)
			.then(() => {
				const portletSpaces = this._portletSpaces();
				const portletSpace = _.find(portletSpaces, ps => ps.ObjectId === portletSpaceId);

				this._mainPortletSpaceId = portletSpaceId;
				portletSpaces.forEach(ps => ps.IsMain = false);

				portletSpace.IsMain = true;

				this._portletSpace().IsMain = true;
				this._portletSpace.valueHasMutated();

				this._portletSpaces([]);
				this._portletSpaces(portletSpaces);
			})
			.fail(error => this.ShowError(error))
			.always(() => BlockUI.Unblock());
	}

	private CreatePortletSpaceForce() {
		const modal = new PortletSpaceEditorModal({
			AllPortletSpaces: this._portletSpaces()
		});

		modal.On(PORTLET_SPACE_EDITOR_EVENTS.SAVE_PORTLET_SPACE, this, eventArgs => {
			const params = eventArgs.data as ISavePortletSpaceParams;

			BlockUI.Block();
			this.SaveNewPortletSpace(params)
				.then(newPortletSpace => {
					modal.Close();

					if (this._portletSpaces().length === 0) {
						this._mainPortletSpaceId = newPortletSpace.Id;
					}

					const portletSpace = PortletSpace.Map(newPortletSpace);
					this._portletSpaces.push(portletSpace);

					this.SwitchPortletSpace(newPortletSpace.Id)
						.then(() => this.EnableDesignMode())
						.fail(error => this.ShowError(error));
				})
				.fail(error => this.ShowError(error))
				.always(() => BlockUI.Unblock());
		});

		modal.Show();
	}

	private SwitchPortletSpace(portletSpaceId: number) {
		this._portletSpace(null);
		this.IsChanged(false);

		this.GetPortletManagers().forEach((columnManagers: KnockoutObservableArray<PortletManager>) => columnManagers([]));
		return this.LoadPortlets(portletSpaceId);
	}

	private SaveNewPortletSpace(params: ISavePortletSpaceParams) {
		return params.CopyFromId !== 0
			? PortletSpaceStore.CopyPortletSpace({ Name: params.Name, CopyFromId: params.CopyFromId })
			: PortletSpaceStore.CreatePortletSpace({ Name: params.Name });
	}

	private DeletePortletSpaceForce() {
		const portletSpaceId = this._portletSpace().ObjectId;

		BlockUI.Block();

		PortletSpaceStore.DeletePortletSpace(portletSpaceId)
			.then(() => {
				const portletSpaces = this._portletSpaces();
				const portletSpaceIndex = _.findIndex(portletSpaces, portletSpace => portletSpace.ObjectId === this._portletSpace().ObjectId);

				portletSpaces.splice(portletSpaceIndex, 1);
				this._portletSpaces(portletSpaces);

				if (portletSpaceId !== this._mainPortletSpaceId) {
					this.SwitchPortletSpace(this._mainPortletSpaceId)
						.then(() => this.EnableDesignMode());
					return;
				}

				if (this._portletSpaces().length > 0) {
					this._mainPortletSpaceId = this._portletSpaces()[0].ObjectId;

					this.SwitchPortletSpace(this._mainPortletSpaceId)
						.then(() => this.EnableDesignMode());
					return;
				}

				this._mainPortletSpaceId = null;
				this._portletSpace(null);
				this.GetPortletManagers().forEach((columnManagers: KnockoutObservableArray<PortletManager>) => columnManagers([]));
			})
			.fail(error => this.ShowError(error))
			.always(() => BlockUI.Unblock());
	}

	private ShowError(error: Rejection) {
		new Notifier().Failed(JSON.parse(error.message).Message);
	}

	private LoadPortletSpaces() {
		BlockUI.Block();

		return PortletSpaceStore.GetPortletSpaces(this._params.Control && this._params.Control.Id)
			.then(portletSpacesDesign => {
				const portletSpaces = portletSpacesDesign.map(portletSpace => PortletSpace.Map(portletSpace));
				this._portletSpaces(portletSpaces);

				const mainPortletSpace: PortletSpace = _.find<any>(portletSpaces, portletSpace => portletSpace.IsMain) || portletSpaces[0];
				this._mainPortletSpaceId = mainPortletSpace && mainPortletSpace.ObjectId;
			})
			.fail(error => this.ShowError(error))
			.always(() => BlockUI.Unblock());
	}

	private LoadPortlets(portletSpaceId: number) {
		BlockUI.Block();

		return PortletSpaceStore.GetPortletSpace(portletSpaceId)
			.then(async designedPortletSpace => {
				if (designedPortletSpace !== null) {
					await this.ApplyPortletSpace(designedPortletSpace);
				}
			})
			.fail(error => this.ShowError(error))
			.always(() => BlockUI.Unblock());
	}

	private AddNewPortlet(portlet: EntityPortlet) {
		let portletManager = new PortletManager({
			Subject: this._params.Subject,
			IsNew: true
		});

		portletManager.GeneratePortlet(portlet).then(() => {
			this.ChangePortletPosition({
				Guid: portletManager.Portlet.Guid,
				PrevPosition: portlet.Position,
				NewPosition: portlet.Position
			});

			let columnManagers = this.GetPortletManagersOfColumn(portlet.Position.ColNumber);
			this.InsertInPosition(columnManagers(), portletManager, portlet.Position.RowNumber);
			columnManagers(columnManagers());
		});

		this.BindPortletManagerEvents(portletManager);

		this._newPortlets.push(portletManager);

		this.IsChanged(true);
	}

	private async ApplyPortletSpace(designedPortletSpace: DesignedPortletSpace) {
		this._portletSpace(PortletSpace.Map(designedPortletSpace));

		for(let portlet of designedPortletSpace.Portlets){

            if (portlet && portlet.ScreenModel && portlet.ScreenModel.SubForms.length === 0) continue;

			let portletManager = new PortletManager({
				Subject: this._params.Subject,
				IsNew: false
			});

			this.BindPortletManagerEvents(portletManager);

			await portletManager.LoadPortlet(portlet.Id, portlet.ScreenId, {
				RowNumber: portlet.RowPosition,
				ColNumber: portlet.ColPosition
			}, portlet.ScreenModel);

			this.InsertInObservableList(this.GetPortletManagersOfColumn(portlet.ColPosition), portletManager, portlet.RowPosition);
		};

		if (this._portletsAreLoaded()) {
			this._portletsAreLoaded.valueHasMutated();
		} else {
			this._portletsAreLoaded(true);
		}
	}

	private ChangePortletPosition(droppedObject: IDraggable) {
		this.GetPortletManagerByCriteria(manager => manager.Portlet.Guid === droppedObject.Guid).then(manager => {
			return manager || this.GetByCriteria(this._newPortlets, manager => manager.Portlet.Guid === droppedObject.Guid);
		}).then(droppedPortletManager => {
			if (droppedPortletManager.Portlet.State === PortletStates.NoChanges) {
				droppedPortletManager.SetState(PortletStates.Changed);
				this._newPortlets.push(droppedPortletManager);
			}

			this.IsChanged(true);
		});
	}

	private BindPortletManagerEvents(manager: PortletManager) {
		let controlButtonsClickEvents = EVENTS.PORTLETS.PORTLET_BAR.CONTROL_BUTTONS;

		let handlers: { [eventName: string]: EventHandlerCallback } = {};
		handlers[EVENTS.PORTLETS.PORTLET_BAR.CONTROL_BUTTONS.REMOVE_BUTTON_CLICKED] = {Handler: this.RemovePortlet}

		let eventHandlersFactory = new PortletManagerEventHandlersFactory(this, manager, handlers);

		Object.keys(controlButtonsClickEvents).forEach(event => eventHandlersFactory.BindEvent(controlButtonsClickEvents[event]));
	}

	//Helpers--------------------------------------------------------
	private ProcessByCriteria<TElement>(list: Array<TElement>, criteria: (element: TElement) => boolean, delegate: (element: TElement) => void) {
		_.where(list, criteria).forEach(delegate);
	}

	private GetByCriteria<TElement>(list: Array<TElement>, criteria: (element: TElement) => boolean) {
		return _.find(list, criteria);
	}

	//Concrete helpers----------------------------------------------
	private GetPortletManagerByCriteria(criteria: (manager: PortletManager) => boolean): P.Promise<PortletManager> {
		let deferredResult = P.defer<PortletManager>();

		this.GetPortletManagers().map((columnManagers: KnockoutObservableArray<PortletManager>) => {
			columnManagers().filter(criteria).forEach((manager: PortletManager) => deferredResult.resolve(manager));
		});

		return deferredResult.promise();
	}

	private GetPortletManagers(): Array<KnockoutObservableArray<PortletManager>> {
		return [this._portletManagers1, this._portletManagers2, this._portletManagers3];
	}

	private GetPortletManagersOfColumn(columnNumber: number): KnockoutObservableArray<PortletManager> {
		return this.GetPortletManagers()[columnNumber];
	}

	private ProcessEachManager(delegate: (columnManagers: KnockoutObservableArray<PortletManager>, manager: PortletManager) => void) {
		this.GetPortletManagers().map((columnManagers: KnockoutObservableArray<PortletManager>) => {
			columnManagers().forEach((manager: PortletManager) => delegate(columnManagers, manager));
		});
	}

	private ProcessManagersByCriteria(criteria: (manager: PortletManager) => boolean,
									  delegate: (columnManagers: KnockoutObservableArray<PortletManager>, manager: PortletManager) => void) {

		this.GetPortletManagers().map((columnManagers: KnockoutObservableArray<PortletManager>) => {
			columnManagers().filter(criteria).forEach((manager: PortletManager) => delegate(columnManagers, manager));
		});
	}

	private InsertInPosition<TElement>(list: Array<TElement>, element: TElement, insertPosition: number) {
		let length = list.length;

		if (length === 0) {
			list[insertPosition] = element;
			return;
		}

		list.push(list[length - 1]);

		for (let positionToShift = list.length - 1; positionToShift > insertPosition; positionToShift--) {
			list[positionToShift] = list[positionToShift - 1];
		}

		list[insertPosition] = element;
	}

	private InsertInObservableList<TElement>(list: KnockoutObservableArray<TElement>, element: TElement, insertPosition: number) {
		let length = list.length;

		if (length === 0) {
			list.splice(insertPosition, 0, element);
			return;
		}

		list.push(list()[length - 1]);

		for (let positionToShift = list.length - 1; positionToShift > insertPosition; positionToShift--) {
			list.splice(positionToShift, 0, list()[positionToShift - 1]);
		}

		list.splice(insertPosition, 1, element);
	}
}