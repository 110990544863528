import * as ko from 'knockout';

export class AssignmentModel {
    Effort: number;
    EditingEffort: KnockoutObservable<number>;
    Editing: KnockoutObservable<boolean>;

    constructor(public Id: string,
                public ResourceId: number,
                public ResourceName: string,
                public ResourceEntity: string,
                effort: number) {

        this.Effort = Math.floor(effort / 1000 / 60 / 60 * 1000) / 1000;
        this.EditingEffort = ko.observable(this.Effort);
        this.Editing = ko.observable(false);
    }

    SaveChanges() {
        this.Effort = this.EditingEffort();
        this.Editing(false);
    }

    CancelChanges() {
        this.EditingEffort(this.Effort);
        this.Editing(false);
    }
}