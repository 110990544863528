import * as ko from 'knockout';

import {LABELS} from "Core/Components/Translation/Locales";

import {ICheckItemParams} from "./ICheckItemParams";
import {ICheckItemSerializedValue} from "./ICheckItemSerializedValue";

export abstract class BaseCheckItem {
    Id: number;
    Name: string;
    NameTranslation: string;
    TypeName: string;
    Required: boolean;

    private _labels: LABELS;

    Value: KnockoutObservable<any>;

    ValidationEnabled: KnockoutObservable<boolean>;
    Valid: KnockoutComputed<boolean>;
    IsLoaded: KnockoutObservable<boolean>;

    protected constructor(params: ICheckItemParams) {
        this.Id = params.Id;
        this.Name = params.Name;
        this.NameTranslation = params.NameTranslation;
        this.TypeName = params.TypeName;
        this.Required = params.Required;

        this._labels = LABELS;

        this.Value = ko.observable(null);
        this.SetValue(params.Value);

        this.IsLoaded = ko.observable(true);

        this.ValidationEnabled = ko.observable(false);
        this.Valid = ko.computed(() => this.IsLoaded() && (!this.Required || !!this.Value()));
    }

    protected SetValue(value: any) {
        this.Value(value);
    }

    protected GetValue(): any {
        return this.Value();
    }

    abstract GetTemplateName(): string;

    SerializeData(): ICheckItemSerializedValue {
        const value = this.GetValue();
        return {Id: this.Id, Name: this.Name, NameTranslation: this.NameTranslation, TypeName: this.TypeName, Value: value};
    }

    AfterRender(el: HTMLElement[]) {

    }

    EnableValidation() {
        this.ValidationEnabled(true);
    }
}