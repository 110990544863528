import * as ko from 'knockout';
import * as _ from "underscore";

import {BlockUI} from "Core/Common/BlockUi";
import {Notifier} from "Core/Common/Notifier";

import {LookupCheckItemStore} from "Core/Components/ActionCheckList/CheckItems/Lookup/Stores/LookupCheckItemStore";

import {BaseCheckItem} from "Core/Components/ActionCheckList/CheckItems/BaseCheckItem";
import {ICheckItemParams} from "Core/Components/ActionCheckList/CheckItems/ICheckItemParams";
import {GetLookupCheckListResponseModel} from "Core/Components/ActionCheckList/CheckItems/Lookup/Models/GetLookupCheckListResponseModel";
import {LookupCheckListItemModel} from "Core/Components/ActionCheckList/CheckItems/Lookup/Models/LookupCheckListItemModel";

import Template from 'Core/Components/ActionCheckList/CheckItems/Lookup/Templates/Template.html';

ko.templates['Core/Components/ActionCheckList/CheckItems/Lookup/Templates/Template'] = Template;

export class LookupCheckItem extends BaseCheckItem {
    ValField: number;

    private _el: HTMLElement;
    private _items: KnockoutObservableArray<LookupCheckListItemModel>;
    private _selectedItem: KnockoutObservable<LookupCheckListItemModel>;

    constructor(params: ICheckItemParams) {
        super(params);

        this._items = ko.observableArray([]);
        this._selectedItem = ko.observable(null);
        this.ValField = params.ValField;
        this.IsLoaded(false);

        this._selectedItem.subscribe((newValue: LookupCheckListItemModel) => {
            if (newValue) {
                this.Value(`${newValue.Id}`);
            }
            else if (this.IsLoaded()) {
                this.Value(0);
            }
        });
    }

    AfterRender(el: HTMLElement[]) {
        this._el = el[0];
        super.AfterRender(el);

        this.LoadData();
    }

    LoadData() {
        BlockUI.Block({Target: this._el});

        LookupCheckItemStore.GetLookupCheckListData({ FieldId: this.ValField })
            .always(() => {
                BlockUI.Unblock(this._el);
                this.IsLoaded(true);
            })
            .then((result) => {
                const items = result.Items;
                const selectedItem = _.find(items,(item: LookupCheckListItemModel) => item.Id === parseInt(this.Value()));

                this._items(items);
                this._selectedItem(selectedItem ? selectedItem : null);
            })
            .fail((error) => new Notifier().Failed(error.message));
    }

    GetTemplateName(): string {
        return 'Core/Components/ActionCheckList/CheckItems/Lookup/Templates/Template';
    }
}