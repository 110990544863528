/**
Core script to handle the entire theme and core functions
**/
define(["require", "exports", "jquery", "metronic", "jquerySlimscroll"], function (require, exports, $, metronic, slimscroll) {
	var Metronic = metronic.Metronic;

	var Layout = (function () {
		function Layout() { }
			
			var layoutImgPath = 'admin/layout/img/';

			var layoutCssPath = 'admin/layout/css/';

			var resBreakpointMd = Metronic.getResponsiveBreakpoint('md');

			//* BEGIN:CORE HANDLERS *//
			// this function handles responsive layout on screen size resize or mobile device rotate.

			// Set proper height for sidebar and content. The content and sidebar height must be synced always.
			var handleSidebarAndContentHeight = function () {
				var content = $('.page-content');
				var sidebar = $('.page-sidebar');
				var body = $('body');
				var height;

				if (body.hasClass('Dashboard')){
					body.removeClass('Dashboard');
				}

				if (body.hasClass("page-footer-fixed") === true && body.hasClass("page-sidebar-fixed") === false) {
					var available_height = Metronic.getViewPort().height - $('.page-footer').outerHeight() - $('.page-header').outerHeight();
					if (content.height() < available_height) {
						content.attr('style', 'min-height:' + available_height + 'px');
					}
				} else {
					if (body.hasClass('page-sidebar-fixed')) {
						height = _calculateFixedSidebarViewportHeight();
						if (body.hasClass('page-footer-fixed') === false) {
							height = height - $('.page-footer').outerHeight();
						}
					} else {
						var headerHeight = $('.page-header').outerHeight();
						var footerHeight = $('.page-footer').outerHeight();

						if (Metronic.getViewPort().width < resBreakpointMd) {
							height = Metronic.getViewPort().height - headerHeight - footerHeight;
						} else {
							height = sidebar.height() + 20;
						}

						if ((height + headerHeight + footerHeight) <= Metronic.getViewPort().height) {
							height = Metronic.getViewPort().height - headerHeight - footerHeight;
						}
					}
					if(isNaN(height)){
						height = Metronic.getViewPort().height;
					}
					content.attr('style', 'min-height:' + height + 'px');
				}
			};

			// Handle sidebar menu links

			var handleSidebarMenuActiveLink = function (mode, el) {
				var url = location.hash.toLowerCase();

				var menu = $('.page-sidebar-menu');

				if (mode === 'click' || mode === 'set') {
					el = $(el);
				} else if (mode === 'match') {
					menu.find("li > a").each(function () {
						var path = $(this).attr("href").toLowerCase();
						// url match condition         
						if (path.length > 1 && url.substr(1, path.length - 1) == path.substr(1)) {
							el = $(this);
							return;
						}
					});
				}

				if (!el || el.length == 0) {
					return;
				}

				if (el.attr('href').toLowerCase() === 'javascript:;' || el.attr('href').toLowerCase() === '#') {
					return;
				}

				var slideSpeed = parseInt(menu.data("slide-speed"));
				var keepExpand = menu.data("keep-expanded");

				// disable active states

				menu.find('li.active').removeClass('active');
				menu.find('li > a > .selected').remove();

				if (menu.hasClass('page-sidebar-menu-hover-submenu') === false) {
					menu.find('li.open').each(function () {
						if ($(this).children('.sub-menu').length === 0) {
							$(this).removeClass('open');
							$(this).find('> a > .arrow.open').removeClass('open');
						}
					});

				} else {
					menu.find('li.open').removeClass('open');

				}


				el.parents('li').each(function () {
					$(this).addClass('active');
					$(this).find('> a > span.arrow').addClass('open');

					if ($(this).parent('ul.page-sidebar-menu').length === 1) {
						$(this).find('> a').append('<span class="selected"></span>');
					}

					if ($(this).children('ul.sub-menu').length === 1) {
						$(this).addClass('open');
					}
				});

				if (mode === 'click') {
					if (Metronic.getViewPort().width < resBreakpointMd && $('.page-sidebar').hasClass("in")) { // close the menu on mobile view while laoding a page 
						$('.page-header .responsive-toggler').click();
					}
				}
			};

		// Handle sidebar menu

			var handleSidebarMenu = function () {
				// handle sidebar link click
		
				function removeValue(arr) {
					var what, a = arguments, L = a.length, ax;
					while (L > 1 && arr.length) {
						what = a[--L];
						while ((ax = arr.indexOf(what)) !== -1) {
							arr.splice(ax, 1);
						}
					}
					return arr;
				}

				//cyberlan menu
				var itemsArray = [];
				var thisActiveIndex = '';
				$('.page-sidebar').on('click', '.page-sidebar-menu > li > a', function (e) {
					var screenWidth = $(window).width();
					var hasSubMenu = $(this).next().hasClass('sub-menu');
					var selectedItem = '';
					selectedItem = $('.page-sidebar-menu > li.active');
					if (hasSubMenu) {
						$(this).closest('li').toggleClass('open');
						var thisIndex = 0;
						thisIndex = $(this).closest('li').index();
						if (thisIndex != itemsArray[0] && thisIndex != itemsArray[1]) {
							if (selectedItem.eq(thisActiveIndex)) {
								removeValue(itemsArray, thisActiveIndex);
								itemsArray.splice();
							}
							itemsArray.push(thisIndex);
						}
						if (itemsArray.length > 2) {
							$('.page-sidebar-menu > li').eq(itemsArray[0]).removeClass('open');
							itemsArray.shift();
							itemsArray.splice();
						}
					} else {
						$('.page-sidebar-menu > li').removeClass('active open');
						$(this).closest('li').addClass('active');
						$('.sub-menu li').removeClass('active');
						if (screenWidth <= 992) {
							$('.page-header .responsive-toggler').click();
							Metronic.scrollTop();
						}
					}

					handleSidebarAndContentHeight();
				});
				$('.page-sidebar').on('click', '.sub-menu a', function (e) {
					var thisParentItem = '';
					thisParentItem = $(this).closest('.page-sidebar-menu > li');
					var thisParent = '';
					thisParent = $(this).closest('li');
					thisParent.addClass('active');
					thisParentItem.addClass('active');
					$('.sub-menu li').not(thisParent).removeClass('active');
					$('.page-sidebar-menu > li').not(thisParentItem).removeClass('open active');
					thisActiveIndex = thisParentItem.index();

					var screenWidth = $(window).width();
					if (screenWidth <= 992) {
						$('.page-header .responsive-toggler').click();
						Metronic.scrollTop();
					}
					
					handleSidebarAndContentHeight();
				});
				
				//metronic menu
				/*
				$('.page-sidebar').on('click', 'li > a', function (e) {

					if ($('body').hasClass('page-sidebar-closed') && $(this).parent('li').parent('.page-sidebar-menu').length === 1) {
						return;
					}

					var hasSubMenu = $(this).next().hasClass('sub-menu');

					if (Metronic.getViewPort().width >= resBreakpointMd && $(this).parents('.page-sidebar-menu-hover-submenu').length === 1) { // exit of hover sidebar menu
						return;
					}

					if (hasSubMenu === false) {
						if (Metronic.getViewPort().width < resBreakpointMd && $('.page-sidebar').hasClass("in")) { // close the menu on mobile view while laoding a page 
							$('.page-header .responsive-toggler').click();
						}
						return;
					}

					if ($(this).next().hasClass('sub-menu always-open')) {
						return;
					}

					var parent = $(this).parent().parent();
					var the = $(this);
					var menu = $('.page-sidebar-menu');
					var sub = $(this).next();

					var autoScroll = menu.data("auto-scroll");
					var slideSpeed = parseInt(menu.data("slide-speed"));
					var keepExpand = menu.data("keep-expanded");

					if (keepExpand !== true) {
						parent.children('li.open').children('a').children('.arrow').removeClass('open');
						parent.children('li.open').children('.sub-menu:not(.always-open)').slideUp(slideSpeed);
						parent.children('li.open').removeClass('open');
					}

					var slideOffeset = -200;

					if (sub.is(":visible")) {
						$('.arrow', $(this)).removeClass("open");
						$(this).parent().removeClass("open");
						sub.slideUp(slideSpeed, function () {
							if (autoScroll === true && $('body').hasClass('page-sidebar-closed') === false) {
								if ($('body').hasClass('page-sidebar-fixed')) {
									menu.slimScroll({
										'scrollTo': (the.position()).top
									});
								} else {
									Metronic.scrollTo(the, slideOffeset);
								}
							}
							handleSidebarAndContentHeight();
						});
					} else if (hasSubMenu) {
						$('.arrow', $(this)).addClass("open");
						$(this).parent().addClass("open");
						sub.slideDown(slideSpeed, function () {
							if (autoScroll === true && $('body').hasClass('page-sidebar-closed') === false) {
								if ($('body').hasClass('page-sidebar-fixed')) {
									menu.slimScroll({
										'scrollTo': (the.position()).top
									});
								} else {
									Metronic.scrollTo(the, slideOffeset);
								}
							}
							handleSidebarAndContentHeight();
						});
					}
					
					e.preventDefault();
				});


				// handle ajax links within sidebar menu
				$('.page-sidebar').on('click', ' li > a.ajaxify', function (e) {
					e.preventDefault();
					Metronic.scrollTop();

					var url = $(this).attr("href");
					var menuContainer = $('.page-sidebar ul');
					var pageContent = $('.page-content');
					var pageContentBody = $('.page-content .page-content-body');

					menuContainer.children('li.active').removeClass('active');
					menuContainer.children('arrow.open').removeClass('open');

					$(this).parents('li').each(function () {
						$(this).addClass('active');
						$(this).children('a > span.arrow').addClass('open');
					});
					$(this).parents('li').addClass('active');

					if (Metronic.getViewPort().width < resBreakpointMd && $('.page-sidebar').hasClass("in")) { // close the menu on mobile view while laoding a page 
						$('.page-header .responsive-toggler').click();
					}

					Metronic.startPageLoading();

					var the = $(this);

					$.ajax({
						type: "GET",
						cache: false,
						url: url,
						dataType: "html",
						success: function (res) {
							if (the.parents('li.open').length === 0) {
								$('.page-sidebar-menu > li.open > a').click();
							}

							Metronic.stopPageLoading();
							pageContentBody.html(res);
							Layout.fixContentHeight(); // fix content height
							Metronic.initAjax(); // initialize core stuff
						},
						error: function (xhr, ajaxOptions, thrownError) {
							Metronic.stopPageLoading();
							pageContentBody.html('<h4>Could not load the requested content.</h4>');
						}

					});
				});

				// handle ajax link within main content
				$('.page-content').on('click', '.ajaxify', function (e) {
					e.preventDefault();
					Metronic.scrollTop();

					var url = $(this).attr("href");
					var pageContent = $('.page-content');
					var pageContentBody = $('.page-content .page-content-body');

					Metronic.startPageLoading();

					if (Metronic.getViewPort().width < resBreakpointMd && $('.page-sidebar').hasClass("in")) { // close the menu on mobile view while laoding a page 
						$('.page-header .responsive-toggler').click();
					}

					$.ajax({
						type: "GET",
						cache: false,
						url: url,
						dataType: "html",
						success: function (res) {
							Metronic.stopPageLoading();
							pageContentBody.html(res);
							Layout.fixContentHeight(); // fix content height
							Metronic.initAjax(); // initialize core stuff
						},
						error: function (xhr, ajaxOptions, thrownError) {
							pageContentBody.html('<h4>Could not load the requested content.</h4>');
							Metronic.stopPageLoading();
						}
					});
				});
				*/
				// handle scrolling to top on responsive menu toggler click when header is fixed for mobile view
				$(document).on('click', '.page-header-fixed-mobile .page-header .responsive-toggler', function () {
					Metronic.scrollTop();
				});

				// disabled possibility of drag-n-drop actions not over allowed elements with "dropzone" class name.
				window.addEventListener("dragover", function (e) {
					e = e || event;
					if ($(e.target).find('dropzone').length === 0){
						e.preventDefault();
					}
				}, false);
				window.addEventListener("drop", function (e) {
					e = e || event;
					if ($(e.target).find('dropzone').length === 0) {
						e.preventDefault();
					}
				}, false);



				// handle the search bar close
				$('.page-sidebar').on('click', '.sidebar-search .remove', function (e) {
					e.preventDefault();
					$('.sidebar-search').removeClass("open");
				});

				// handle the search query submit on enter press
				$('.page-sidebar .sidebar-search').on('keypress', 'input.form-control', function (e) {
					if (e.which == 13) {
						$('.sidebar-search').submit();
						return false; //<---- Add this line
					}
				});

				// handle the search submit(for sidebar search and responsive mode of the header search)
				$('.sidebar-search .submit').on('click', function (e) {
					e.preventDefault();
					if ($('body').hasClass("page-sidebar-closed")) {
						if ($('.sidebar-search').hasClass('open') === false) {
							if ($('.page-sidebar-fixed').length === 1) {
								$('.page-sidebar .sidebar-toggler').click(); //trigger sidebar toggle button
							}
							$('.sidebar-search').addClass("open");
						} else {
							$('.sidebar-search').submit();
						}
					} else {
						$('.sidebar-search').submit();
					}
				});

				// handle close on body click
				if ($('.sidebar-search').length !== 0) {
					$('.sidebar-search .input-group').on('click', function (e) {
						e.stopPropagation();
					});

					$('body').on('click', function () {
						if ($('.sidebar-search').hasClass('open')) {
							$('.sidebar-search').removeClass("open");
						}
					});
				}
			};

			// Helper function to calculate sidebar height for fixed sidebar layout.
			var _calculateFixedSidebarViewportHeight = function () {
				var sidebarHeight = Metronic.getViewPort().height - $('.page-header').outerHeight();
				if ($('body').hasClass("page-footer-fixed")) {
					sidebarHeight = sidebarHeight - $('.page-footer').outerHeight();
				}

				return sidebarHeight;
			};

			// Handles fixed sidebar
			var handleFixedSidebar = function () {
				var menu = $('.page-sidebar-menu:first');

				Metronic.destroySlimScroll(menu);

				if ($('.page-sidebar-fixed').length === 0) {
					handleSidebarAndContentHeight();
					return;
				}

				if (Metronic.getViewPort().width >= resBreakpointMd) {
					menu.attr("data-height", _calculateFixedSidebarViewportHeight());
					Metronic.initSlimScroll(menu);
					handleSidebarAndContentHeight();
				}
			};


			// Hanles sidebar toggler
		var handleSidebarToggler = function () {
				var body = $('body');
				if ($.cookie && $.cookie('sidebar_closed') === '1' && Metronic.getViewPort().width >= resBreakpointMd) {
					$('body').addClass('page-sidebar-closed');
					$('.page-sidebar-menu').addClass('page-sidebar-menu-closed');
				}

				// handle sidebar show/hide
				$('body').on('click', '.sidebar-toggler', function (e) {
					var sidebar = $('.page-sidebar');
					var sidebarMenu = $('.page-sidebar-menu');
					$(".sidebar-search", sidebar).removeClass("open");

					if (body.hasClass("page-sidebar-closed")) {
						body.removeClass("page-sidebar-closed");
						sidebarMenu.removeClass("page-sidebar-menu-closed");
						if ($.cookie) {
							$.cookie('sidebar_closed', '0');
						}
					} else {
						body.addClass("page-sidebar-closed");
						sidebarMenu.addClass("page-sidebar-menu-closed");
						if (body.hasClass("page-sidebar-fixed")) {
							sidebarMenu.trigger("mouseleave");
						}
						if ($.cookie) {
							$.cookie('sidebar_closed', '1');
						}
					}
					$(window).trigger('resize');
				});
			};

			// Handles the horizontal menu
			var handleHorizontalMenu = function () {
				//handle tab click
				$('.page-header').on('click', '.hor-menu a[data-toggle="tab"]', function (e) {
					e.preventDefault();
					var nav = $(".hor-menu .nav");
					var active_link = nav.find('li.current');
					$('li.active', active_link).removeClass("active");
					$('.selected', active_link).remove();
					var new_link = $(this).parents('li').last();
					new_link.addClass("current");
					new_link.find("a:first").append('<span class="selected"></span>');
				});

				// handle search box expand/collapse        
				$('.page-header').on('click', '.search-form', function (e) {
					$(this).addClass("open");
					$(this).find('.form-control').focus();

					$('.page-header .search-form .form-control').on('blur', function (e) {
						$(this).closest('.search-form').removeClass("open");
						$(this).unbind("blur");
					});
				});

				// handle hor menu search form on enter press
				$('.page-header').on('keypress', '.hor-menu .search-form .form-control', function (e) {
					if (e.which == 13) {
						$(this).closest('.search-form').submit();
						return false;
					}
				});

				// handle header search button click
				$('.page-header').on('mousedown', '.search-form.open .submit', function (e) {
					e.preventDefault();
					e.stopPropagation();
					$(this).closest('.search-form').submit();
				});

				// handle hover dropdown menu for desktop devices only
				$('[data-hover="megamenu-dropdown"]').not('.hover-initialized').each(function () {
					$(this).dropdownHover();
					$(this).addClass('hover-initialized');
				});

				$(document).on('click', '.mega-menu-dropdown .dropdown-menu', function (e) {
					e.stopPropagation();
				});
			};

			// Handles Bootstrap Tabs.
			var handleTabs = function () {
				// fix content height on tab click
				$('body').on('shown.bs.tab', 'a[data-toggle="tab"]', function () {
					handleSidebarAndContentHeight();
				});
			};

			// Handles the go to top button at the footer
			var handleGoTop = function () {
				var offset = 300;
				var duration = 500;

				if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {  // ios supported
					$(window).bind("touchend touchcancel touchleave", function (e) {
						if ($(this).scrollTop() > offset) {
							$('.scroll-to-top').fadeIn(duration);
						} else {
							$('.scroll-to-top').fadeOut(duration);
						}
					});
				} else {  // general 
					$(window).scroll(function () {
						if ($(this).scrollTop() > offset) {
							$('.scroll-to-top').fadeIn(duration);
						} else {
							$('.scroll-to-top').fadeOut(duration);
						}
					});
				}

				$('.scroll-to-top').click(function (e) {
					e.preventDefault();
					$('html, body').animate({ scrollTop: 0 }, duration);
					return false;
				});
			};

			// Hanlde 100% height elements(block, portlet, etc)
			var handle100HeightContent = function () {

				var target = $('.full-height-content');
				var height;

				height = Metronic.getViewPort().height -
					$('.page-header').outerHeight(true) -
					$('.page-footer').outerHeight(true) -
					$('.page-title').outerHeight(true) -
					$('.page-bar').outerHeight(true);

				if (target.hasClass('portlet')) {
					var portletBody = target.find('.portlet-body');

					if (Metronic.getViewPort().width < resBreakpointMd) {
						Metronic.destroySlimScroll(portletBody.find('.full-height-content-body')); // destroy slimscroll 
						return;
					}

					height = height -
						target.find('.portlet-title').outerHeight(true) -
						parseInt(target.find('.portlet-body').css('padding-top')) -
						parseInt(target.find('.portlet-body').css('padding-bottom')) - 2;

					if (target.hasClass("full-height-content-scrollable")) {
						height = height - 35;
						portletBody.find('.full-height-content-body').css('height', height);
						Metronic.initSlimScroll(portletBody.find('.full-height-content-body'));
					} else {
						portletBody.css('min-height', height);
					}
				} else {
					if (Metronic.getViewPort().width < resBreakpointMd) {
						Metronic.destroySlimScroll(target.find('.full-height-content-body')); // destroy slimscroll 
						return;
					}

					if (target.hasClass("full-height-content-scrollable")) {
						height = height - 35;
						target.find('.full-height-content-body').css('height', height);
						Metronic.initSlimScroll(target.find('.full-height-content-body'));
					} else {
						target.css('min-height', height);
					}
				}
			};
			//* END:CORE HANDLERS *//


			Layout.initHeader = function () {
				handleHorizontalMenu(); // handles horizontal menu    
			};

			Layout.setSidebarMenuActiveLink = function (mode, el) {
				handleSidebarMenuActiveLink(mode, el);
			};

			Layout.initSidebar = function () {
				//layout handlers
				handleFixedSidebar(); // handles fixed sidebar menu
				handleSidebarMenu(); // handles main menu
				handleSidebarToggler(); // handles sidebar hide/show

				if (Metronic.isAngularJsApp()) {
					handleSidebarMenuActiveLink('match'); // init sidebar active links 
				}

				Metronic.addResizeHandler(handleFixedSidebar); // reinitialize fixed sidebar on window resize
			};

			Layout.initContent = function () {
				handle100HeightContent(); // handles 100% height elements(block, portlet, etc)
				handleTabs(); // handle bootstrah tabs

				Metronic.addResizeHandler(handleSidebarAndContentHeight); // recalculate sidebar & content height on window resize
				Metronic.addResizeHandler(handle100HeightContent); // reinitialize content height on window resize 
			};

			Layout.initFooter = function () {
				handleGoTop(); //handles scroll to top functionality in the footer
			};

			Layout.init = function () {
				this.initHeader();
				this.initSidebar();
				this.initContent();
				this.initFooter();
			};

				//public function to fix the sidebar and content height accordingly
			Layout.fixContentHeight = function () {
				handleSidebarAndContentHeight();
			};

			Layout.initFixedSidebar = function () {
				handleFixedSidebar();
			};

			Layout.getLayoutImgPath = function () {
				return Metronic.getAssetsPath() + layoutImgPath;
			};

			Layout.getLayoutCssPath = function () {
				return Metronic.getAssetsPath() + layoutCssPath;
			};
		return Layout;
	})();
	exports.Layout = Layout;
})