import * as ko from 'knockout';
import { P } from 'Core/Common/Promise';

import FieldCollectionTemplate from 'FieldCollection/Templates/FieldCollection.html';

import { FieldCollectionStore } from 'FieldCollection/Stores/FieldCollectionStore';
import { IDatarole } from 'FieldCollection/Models/IDataRole';
import { IEntity } from 'FieldCollection/Models/IEntity';
import { IFields, IType } from 'FieldCollection/Models/IFields';
import { ICollectionSave } from 'FieldCollection/Models/ICollectionSave';
import { IViewModel, ICollectionObject, IViewModelField } from 'FieldCollection/Models/IViewModel';
import { FieldTable } from 'FieldCollection/FieldTable/FieldTable';
import { DATA_ROLE_NAMES } from 'FieldCollection/PriorityManager/PriorityManagerConstants';
import { PriorityManager } from 'FieldCollection/PriorityManager/PriorityManager';
import { NOTIFICATIONS, LABELS, CONFIRMATIONS } from "Core/Components/Translation/Locales";

import { BlockUI } from 'Core/Common/BlockUi';
import { Notifier } from 'Core/Common/Notifier';
import {
	ConfirmationDialog,
	EVENTS as CONFIRMATION_EVENTS,
	Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import { FCRequiredFields } from "./Enums/FCRequiredFields";

ko.templates['FieldCollection/Templates/FieldCollection'] = FieldCollectionTemplate;

export class FieldCollection {
	private _isReady: KnockoutObservable<boolean>;
	private _isChecked: KnockoutObservable<boolean>;
	private _dataRoles: KnockoutObservableArray<IDatarole>;
	private _selectedDataRole: KnockoutObservable<IDatarole>;
	private _previosSelectedDataRole: IDatarole;
	private _entities: KnockoutObservableArray<IEntity>;
	private _selectedEntity: KnockoutObservable<IEntity>;
	private _pertableTypes: KnockoutObservableArray<IType>;
	private _otherTypes: KnockoutObservableArray<IType>;
	private _fieldTable: KnockoutObservable<FieldTable>;
	private _fieldCollectionStore: FieldCollectionStore;
	private _currentCollection: ICollectionObject;
	private _currentCollectionInitial: ICollectionObject;
	private _currentEntityInitial: IViewModel;
	private _priorityManager: PriorityManager;
	private _mutatedEntitiesId: number[];
	private _hasUnsavedChanges: KnockoutObservable<boolean>;
	private _labels = LABELS;

	constructor() {
		this._isReady = ko.observable(false);
		this._isChecked = ko.observable(true);
		this._dataRoles = ko.observableArray([]);
		this._selectedDataRole = ko.observable(null);
		this._previosSelectedDataRole = null;
		this._entities = ko.observableArray([]);
		this._selectedEntity = ko.observable(null);
		this._pertableTypes = ko.observableArray([]);
		this._otherTypes = ko.observableArray([]);
		this._fieldTable = ko.observable(null);
		this._currentCollection = {};
		this._currentCollectionInitial = {};
		this._currentEntityInitial = { Id: null, Fields: [], Types: { PerTable: [], Other: [] } };
		this._mutatedEntitiesId = [];
		this._hasUnsavedChanges = ko.observable(false);

		this._priorityManager = new PriorityManager();
		this._fieldCollectionStore = new FieldCollectionStore();
	}

	get SelectedCollectionName(): string {
		return this._selectedDataRole() && this._selectedDataRole().Name || 'Not selected';
	}

	get SelectedTypeName(): string {
		return this._selectedDataRole() && this._selectedDataRole().Type || 'Not selected';
	}

	Render(elId: string): void {
		const container = document.getElementById(elId);

		const getDataRolePromise: P.Promise<IEntity[]> = this._fieldCollectionStore.GetDataRole();
		const getEntitiesPromise: P.Promise<IEntity[]> = this._fieldCollectionStore.GetEntities();

		BlockUI.Block();

		P.when(getDataRolePromise, getEntitiesPromise)
			.always(() => {
				BlockUI.Unblock();
				ko.cleanNode(container);
				ko.applyBindings(this, container);
			})
			.then((result) => {
				this._dataRoles(result[0]);
				this._entities(result[1]);
				this._selectedEntity(this._entities()[0]);
			})
			.fail((error) => {
				new Notifier().Failed(error.message)
			});
	}


	GetDataRoleText(dataRole: IDatarole): string {
		return `${dataRole.Name}-${DATA_ROLE_NAMES[dataRole.Type]}`;
	}

	GetTemplateName(): string {
		return 'FieldCollection/Templates/FieldCollection';
	}

	ChangeTab(entity: IEntity): void {
		BlockUI.Block();

		this._selectedEntity(entity);
		this._previosSelectedDataRole = this._selectedDataRole();
		const entityId = this._selectedEntity().Id;

		if (this._currentCollection[entityId]) {
			this._currentEntityInitial = this._currentCollection[entityId];
			const fieldTable = new FieldTable(this._currentCollection[entityId]);

			fieldTable.On('FieldToggled', this, (eventArgs) => this.RowChanged(eventArgs.data.Data));

			this._fieldTable(fieldTable);

			BlockUI.Unblock();

			return;
		}

		this._fieldCollectionStore.GetFieldCollection(entityId, this._selectedDataRole().Id)
			.then((result: IFields) => {
				this._currentCollection[entityId] = this.MapToViewModel(result);
				this._currentCollectionInitial[entityId] = JSON.parse(JSON.stringify(this._currentCollection[entityId]));
				this._currentEntityInitial = JSON.parse(JSON.stringify(this._currentCollection[entityId]));

				const fieldTable = new FieldTable(this._currentCollection[entityId]);

				fieldTable.On('FieldToggled', this, (eventArgs) => this.RowChanged(eventArgs.data.Data));

				this._fieldTable(fieldTable);
			})
			.fail((error) => {
				new Notifier().Failed(error.message);
			})
			.always(() => {
				BlockUI.Unblock();
			});
	}

	RowChanged(row: IViewModelField): void {
		const currentEntity = this._currentCollection[this._selectedEntity().Id];

		currentEntity.Fields.forEach((field, fieldIndex) => {
			if (field.Id === row.Id) {
				field.types = row.types.map((cell, typeIndex) => {
					if (cell.isActiveType) {
						field.ActiveTypes.push(cell.Id);
					} else {
						field.ActiveTypes.splice(field.ActiveTypes.indexOf(cell.Id), 1);
					}

					const initialState = this._currentEntityInitial.Fields[fieldIndex]
						? !!this._currentEntityInitial.Fields[fieldIndex].types[typeIndex].isActiveType
						: false;

					cell.mutated = initialState !== cell.isActiveType && !cell.isUnavailableField;

					if (row.isRequired) {
						cell.mutated = false;
					}

					return _.clone(cell);
				});
			}
		});

		if (!this._mutatedEntitiesId.some((el) => el === this._selectedEntity().Id)) {
			this._mutatedEntitiesId.push(this._selectedEntity().Id);
		}

		this.CheckCollectionChanges();
	}

	ChangeDataRole(): void {
		const confirmCallback = () => {
			this._currentCollection = {};
			this._previosSelectedDataRole = this._selectedDataRole();
			this._hasUnsavedChanges(false);

			if (!this._selectedDataRole()) return;

			this.ChangeTab(this._entities()[0]);
		};

		const discardCallback = () => {
			this._selectedDataRole(this._previosSelectedDataRole);
		};

		if (!this._previosSelectedDataRole) {
			confirmCallback();

			return;
		}

		if (this._hasUnsavedChanges()) {
			const message = CONFIRMATIONS.ALL_CHANGES_WILL_BE_LOST;
			this.ConfirmationDialog(message, confirmCallback, discardCallback);
		} else {
			confirmCallback();
		}
	}

	ConfirmationDialog(message: string, confirmCallback, discardCallback): void {
		const dialog = new ConfirmationDialog({
			Text: message,
			Type: ConfirmationTypes.Question,
			Width: 500,
			MinHeight: 160
		});

		dialog.On(CONFIRMATION_EVENTS.CONFIRM_SELECTED, this, () => {
			confirmCallback();
		});

		dialog.On(CONFIRMATION_EVENTS.DISCARD_SELECTED, this, () => {
			discardCallback();
		});

		dialog.Show();
	}

	MapToViewModel(model: IFields): IViewModel {
		const initialFields = model.Fields;
		const initialTypes = model.Types;

		const dataRole = this._selectedDataRole();

		const pertableTypes = initialTypes.filter((type) => type.TypeOfType === 'PerTable');
		const otherTypes = initialTypes.filter((type) => type.TypeOfType !== 'PerTable');

		const currentType = otherTypes.filter((type) => type.Id === dataRole.Id)[0];

		if (currentType) {
			currentType.isCurrentType = true;
		} else {
			otherTypes.push({
				Id: dataRole.Id,
				Name: dataRole.Name,
				TypeOfType: dataRole.Type,
				isCurrentType: true
			});
		}

		this._priorityManager.SortTypes(otherTypes);

		let allTypes = pertableTypes.concat(otherTypes);

		let fieldsMapped = initialFields.map((_field) => {
			let field: IViewModelField = {
				Id: _field.Id,
				Name: _field.Name,
				isRequired: FCRequiredFields.IsRequired(_field.Name) || _field.Type === 'PKey',
				ActiveTypes: [..._field.ActiveTypes],
				types: []
			};

			field.types = allTypes.map((type) => {
				const _type = {
					Id: type.Id,
					Name: type.Name,
					TypeOfType: type.TypeOfType,
					mutated: false,
					Available: true,
					isUnavailableField: false,
					isActiveType: false,
					isCurrentType: !!type.isCurrentType,
					PertableType: type.TypeOfType === 'PerTable'
				};

				_field.ActiveTypes.forEach((activeType) => {
					if (type.Id === activeType) {
						_type.isActiveType = true;
					}
				});

				return _type;
			});

			return field;
		});

		const modelMapped = {
			Fields: fieldsMapped,
			Types: {
				PerTable: pertableTypes,
				Other: otherTypes
			},
			Id: this._selectedEntity().Id
		};

		return modelMapped;
	}

	Save(): void {
		BlockUI.Block();

		const saveModel: ICollectionSave = this.MapToSaveModel();

		this.CheckCollectionChanges();

		if (!this._hasUnsavedChanges) {
			return;
		}

		const onFail = (error) => {
			console.log(error);

			new Notifier().Failed(error.message || error);

			this.RevertChanges(true);

			BlockUI.Unblock();
		};

		this._fieldCollectionStore.SaveFieldCollection(saveModel)
			.then((result) => {
				if (!result.IsSuccessfull) {
					onFail(result.ErrorMessage || result.Messsage);

					return;
				}

				new Notifier().Success(NOTIFICATIONS.SAVED);

				this._mutatedEntitiesId = [];
				this._currentCollection = {};

				this.ChangeTab(this._selectedEntity());

				this._hasUnsavedChanges(false);
			})
			.fail((error) => {
				onFail(error.message);
			})
			.always(() => {
				BlockUI.Unblock();
			});
	}

	CheckCollectionChanges(): any {
		const mutatedIds = _.uniq(this._mutatedEntitiesId);

		const mutatedEntities = mutatedIds.map((id) => this._currentCollection[id]);

		if (!mutatedEntities.length) {
			this._hasUnsavedChanges(false);

			return [];
		}

		let entities = mutatedEntities.map((entity) => {
			const mutatedEntity = {
				Id: entity.Id,
				MutatedFields: []
			};

			const fields = entity.Fields.map((field) => {
				const mutatedField = field.types.filter((type) => {
					return type.Id === this._selectedDataRole().Id && type.mutated;
				})[0];

				if (!mutatedField) return;

				return {
					Id: field.Id,
					Active: mutatedField.isActiveType
				}
			});

			mutatedEntity.MutatedFields = fields.filter((field) => field !== undefined);

			return mutatedEntity;
		});

		entities = entities.filter((entity) => entity.MutatedFields.length);

		this._hasUnsavedChanges(!!entities.length);

		return entities;
	}

	MapToSaveModel(): ICollectionSave {
		const entities = this.CheckCollectionChanges();

		const mutatedCollection = {
			Id: this._selectedDataRole().Id,
			Entities: entities
		};

		return mutatedCollection;
	}

	RevertChanges(noConfirm?: boolean): void {
		const confirmCallback = () => {
			this._mutatedEntitiesId = [];
			this._currentCollection = JSON.parse(JSON.stringify(this._currentCollectionInitial));

			this.ChangeTab(this._selectedEntity());

			this._hasUnsavedChanges(false);
		};

		const discardCallback = () => {
			return;
		};

		if (noConfirm) {
			confirmCallback();

			return;
		}

		const message = CONFIRMATIONS.ALL_CHANGES_WILL_BE_LOST;

		this.ConfirmationDialog(message, confirmCallback, discardCallback);
	}

	UpdateViews() {
		BlockUI.Block();

		this._fieldCollectionStore.UpdateViews()
			.then(() => new Notifier().Success(NOTIFICATIONS.VIEWS_UPDATED))
			.fail(error => new Notifier().Failed(error.message))
			.always(() => BlockUI.Unblock());
	}

	AfterRender(el): void {
		this._isReady(true);
	}
}