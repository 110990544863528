import {ConfigurationDescription} from "Core/Components/Controls/ProductConfigurator/Pages/StartPage/Stores/Models/ConfigurationDescription";
import {deserialize, deserializeAs} from 'libs/cerialize';
export class ProductResponse {
    
    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    NameTranslated: string;

    @deserialize
    Image: string;

    @deserialize
    ConfigurationId: number;

    @deserializeAs(ConfigurationDescription)
    ConfigurationDescriptions: ConfigurationDescription[];

    @deserialize
    Levels: string[];
}