import * as ko from 'knockout';
import 'jquery-qrcode';

import { Events } from "Auth/Enums/Events";
import { Event } from "Core/Common/Event";
import {CONFIRMATIONS, LABELS} from "Core/Components/Translation/Locales";

import TwoFaConfigurationFormTemplate from 'Auth/UI/TwoFaConfigurationForm/Templates/TwoFaConfigurationForm.html';
import { Modal } from 'Core/Common/Modal';
ko.templates['Auth/UI/TwoFaConfigurationForm/Templates/TwoFaConfigurationForm'] = TwoFaConfigurationFormTemplate;

export class TwoFaConfigurationForm extends Event {
    private _modal: Modal;
    private _secret: string;
    private _el: HTMLElement;
    private _error: KnockoutObservable<string>;
    private _dbName: string;
    private _login: string;
    public _confirmations = CONFIRMATIONS;
    public _labels = LABELS;

    constructor(){
        super();
        this._error = ko.observable('');
        this.AddEvent(Events.SaveTotpSecred);
    }

    GetTemplateName(){
        return 'Auth/UI/TwoFaConfigurationForm/Templates/TwoFaConfigurationForm';
    }

    Load(title: string, dbName: string = '', login: string = ''){
        this._dbName = dbName;
        this._login = login;
        this._modal = new Modal(
            {
                addClass: 'totp-form',
                minWidth: 400,
                minHeight: 400,
                maxHeight: 400,
                maxWidth: 400
            }, false);
        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
        this._modal.Show();
        this.GenerateQrCode();
    };

    GenerateQrCode(){
        this.GenerateSecret();
        $(this._el).find("#qrcode")
            .qrcode(
                { 
                    size: 150,
                    render: 'canvas',
                    text: `otpauth://totp/${this._login}/${this._dbName}?secret=${this._secret}`
                });
    }

    OnNext(){
        this.Trigger(Events.SaveTotpSecred);
    }

    GetFormData(): string{
        return this._secret;
    }

    ShowError(errorText: string){
        this._error(errorText);
    }

    RemoveErrorSummary(){
        this._error(null);
    }

    GenerateSecret() {
       let stringInclude = '';
       stringInclude += '234567';
       stringInclude += 'QWERTYUIOPASDFGHJKLZXCVBNM';
       var secret ='';
       for (let i = 0; i < 10; i++) {
           secret += stringInclude.charAt(Math.floor(Math.random() * stringInclude.length));
       }
       this._secret = secret;
    }

    AfterRender(el: Array<HTMLElement>){
        this._el = el[0];
    }

    Close(){
        this._modal.Close();
    }
}