import { deserialize } from 'libs/cerialize';
import { deserializeAs } from 'libs/cerialize';
import { TriggerParamModel } from 'Core/Controls/BaseControl/Models/TriggerParamModel';

export class TriggerModel {

	constructor() {
		this.Params = [];
	}

	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	Action: string;

	@deserializeAs(TriggerParamModel)
	Params: Array<TriggerParamModel>;
}