import * as ko from 'knockout';

import {LookupOption} from "./LookupOption";
import {LookupField} from "./LookupField";

export class ViewModel {
    Header: string;
    ErrorSummary: KnockoutObservable<string>;
    Options: KnockoutObservableArray<LookupOption>;
    SelectedOption: KnockoutObservable<number>;
    LookupField: LookupField;

    constructor() {
        this.ErrorSummary = ko.observable(null);
        this.Options = ko.observableArray([]);
        this.SelectedOption = ko.observable(null);
    }
}