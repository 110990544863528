import * as ko from "knockout";

import {IScreen, IFormParam, IControlParam, IForm} from "Core/Screens/IScreen";
import {ControlFactory} from "Core/Controls/ControlFactory";
import {IControl} from "Core/Controls/IControl";
import {SubFormModel} from 'Core/Models/Screens/SubFormModel'
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel'
import {RenderModes} from "Core/Constant";
import {BaseForm} from "Core/Screens/Forms/Base/BaseForm";
import {LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";
import {LOCAL_STORAGE} from "Core/Common/Enums/LocalStorageItems";
import {Notifier} from "Core/Common/Notifier";

export class ActionBar extends BaseForm {
	Icon: string;
	private _activeScreen: any;
	private _labels = LABELS;

	constructor(params: IFormParam) {
		super(params);
		this.Icon = params.Screen.GetEntityIcon();
		this._activeScreen = params.Screen;
	}

	get IsStaticControls(): boolean {
		return this.Controls().filter(control => control.IsStatic).length >= 1;
	}

	CopyRecordLink() {
		let url = new URL(window.location.href);
		let lastDb = Lockr.get(LOCAL_STORAGE.LAST_DB);


		let urlForCopying = `${url.origin}/view?db=${lastDb}&recordId=${this._activeScreen.GetRecordId()}&entityId=${this._activeScreen.GetEntityId()}`;

		this._activeScreen.CopyTextToClipboard(urlForCopying, this.Success);

	}

	Success() {
		const notifier = new Notifier();
		notifier.Success(NOTIFICATIONS.LINK_HAS_BEEN_COPIED);
	}
}