import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {GenericDeserialize} from 'libs/cerialize';
import {LifeStatusesModel} from 'Core/Controls/ButtonFollowUp/Models/LifeStatusesModel';
import {LifeStatusesGeneralModel} from "Core/Controls/ButtonFollowUp/Models/LifeStatusesGeneralModel";
import {CheckRetiredChildrenResponseModel} from "Core/Controls/ButtonFollowUp/Models/CheckRetiredChildrenResponseModel";

export interface ICheckRetiredChildrenRequestModel {
	ParentEntityId: number;
	ParentRecordId: number;
}

export class FollowUpStore {
    static GetStatuses(params: { EntityId: number; RecordId: number; ActionEntityId?: number}): P.Promise<LifeStatusesGeneralModel> {
		const deferredResult = P.defer<LifeStatusesGeneralModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.FOLLOWUP.GET_NEXT_LIFESTATUSES,
				dataType: "json",
				type: "POST"
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				const model = GenericDeserialize<LifeStatusesGeneralModel>(data.ResultObject, LifeStatusesGeneralModel);
				deferredResult.resolve(model);
			});

		return deferredResult.promise();
	}

	static ConfirmPassword(params: any): P.Promise<any> {
		const deferredResult = P.defer<LifeStatusesModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.FOLLOWUP.CONFIRM_PASSWORD,
				dataType: "json",
				type: "POST"
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data.IsSuccessfull);
			});

		return deferredResult.promise();
	}

	static CheckRetiredChildren(params: ICheckRetiredChildrenRequestModel): P.Promise<CheckRetiredChildrenResponseModel> {
		const deferredResult = P.defer<CheckRetiredChildrenResponseModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.FOLLOWUP.CHECK_RETIRED_CHILDREN,
				dataType: "json",
				type: "POST"
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
			});

		return deferredResult.promise();
	}
}