import * as ko from 'knockout';
import * as _ from 'underscore';
import { TriggerDto } from './Dto/TriggerDto';

export class TriggerGroupViewModel {
    
    Group: string;
    
    Triggers: Array<TriggerDto>;

    IsExpanded: KnockoutObservable<boolean>;

    constructor(group: string, triggers: Array<TriggerDto>){
        this.Group = group;
        this.IsExpanded = ko.observable(false);
        this.Triggers = _.filter(triggers, (trigger) => trigger.Group == group);
    }
}