import clone from 'clone';

import { BaseModel } from "DatabaseDesigner/Models/BaseModel";
import { CollectionModel } from "DatabaseDesigner/Models/CollectionModel";
import { FieldModel } from "DatabaseDesigner/Models/FieldModel";
import { TableTypeModel } from "DatabaseDesigner/Models/TableTypeModel";
import { TranslationModel } from "DatabaseDesigner/Models/TranslationModel";
import {LanguageModel} from "Core/Controls/BaseControl/Models/LanguageModel";
import {TranslationModel as MemoTranslationModel} from "Core/Controls/BaseControl/Models/TranslationModel";
import {FormProperties} from 'Core/Screens/DesignScreen/Models/FormProperties';
import {Serialize} from "../../libs/cerialize";

export function TableBaseModel() {
    this._id = 0;
    this._name = '';
    this._shortName = '';
    this._typeId = 0;
    this._typeName = '';
    this._sort = 0;
    this._fields = new CollectionModel({ model: FieldModel });
    this._types = new CollectionModel({ model: TableTypeModel });
    this._icon = null;
    this._iconType = null;
    this._iconId = null;
    this._iconImage = null;
    this._entityColor = null;
    this._lifeStatusName = null;
    this._lastLifestatusName = this._lifeStatusName;
    this._translations = new CollectionModel({ model: TranslationModel });
    this._memo = "";
    this._memoTranslations = new CollectionModel({ model: TranslationModel });
    this._attachedControlTypes = new Array<string>();
    this._isView = false;
    this._query = '';
    this._dependsOnFieldName = null;
    this._dependsOnFieldId = 0;
    this._dependsOnFieldGuid = null;
    this._dynamicFields = '';
    this._connectionString = null;
    this._trigger = null;
    this._sequenceType = 0;
    this._isMergeView = false;
    this._properties = new FormProperties();

    BaseModel.apply(this);
};

TableBaseModel.prototype = Object.create(BaseModel.prototype);
TableBaseModel.prototype.constructor = TableBaseModel;

TableBaseModel.prototype.FromJSONArray = function (tables) {
    var tableList = new CollectionModel({ model: TableBaseModel });
    _.each(tables, function (table) {
        var model = new TableBaseModel();
        model.FromJSON(table);
        tableList.Add(model);
    });
    return tableList;
}

TableBaseModel.prototype.toJSON = function (onlyModified) {
    var result = {
        Id: this.Id,
        Guid: this.Guid,
        Name: this.Name,
        ShortName: this.ShortName,
        TypeId: this.TypeId,
        IsModified: this.IsModified,
        IsRestored: this.IsRestored,
        Fields: this.Fields.toJSON(onlyModified),
        Icon: this.Icon,
        IconId: this.IconId,
        IconImage: this.IconImage,
        EntityColor: this.EntityColor,
        SubjectAreaId: this.SubjectAreaId,
        OnDelete: this.OnDelete,
        LifeStatusName: this.LifeStatusName,
        IsLifeStatusModified: this.LifeStatusName !== this._lastLifestatusName,
        Translations: this.Translations.toJSON(),
        Memo: this.Memo,
        MemoTranslations: this.MemoTranslations,
        IsView: this.IsView,
        Query: this.Query,
        DynamicFields: this._dynamicFields,
        ConnectionString: this._connectionString,
        Trigger: this._trigger,
        SequenceType: this._sequenceType,
        IsMergeView: this._isMergeView,
        Properties: JSON.stringify(Serialize(this._properties))
    };

    if (onlyModified && onlyModified === true) {
        if (this._isModified !== true) {
            result = null;
        }
    }
    return result;

}

TableBaseModel.prototype.Clone = function () {
    return clone(this);
}

TableBaseModel.prototype.FromJSON = function (data) {
    this._id = data.Id;
    this._databaseId = data.DatabaseId;
    this._name = data.Name;
    this._subjectAreaId = data.SubjectAreaId;
    this._onDelete = data.OnDelete;
    this._shortName = data.ShortName;
    this._typeId = data.TypeId;
    this._fields = FieldModel.prototype.FromJSONArray(data.Fields);
    this._sort = data.Sort;
    this._typeName = data.TypeName;
    this._icon = data.Icon;
    this._iconType = data.IconType;
    this._iconId = data.IconId;
    this._iconImage = data.IconImage;
    this._entityColor = data.EntityColor;
    this._lifeStatusName = data.LifeStatusName;
    this._lastLifestatusName = this._lifeStatusName;
    this._translations = TranslationModel.prototype.FromJSONArray(data.Translations);
    this._memo = data.Memo;
    this._memoTranslations = data.MemoTranslations;
    this._attachedControlTypes = data.AttachedControlTypes;
    this._isView = data.IsView;
    this._query = data.Query;
    this._dynamicFields = data.DynamicFields;
    this._connectionString = data.ConnectionString;
    this._trigger = data.Trigger;
    this._sequenceType = data.SequenceType;
    this._isMergeView = data.IsMergeView;
    this._properties = data.Properties ? JSON.parse(data.Properties) : new FormProperties();
}

TableBaseModel.prototype.GetMaxSortGroup = function (groupName) {
    var sortGroup = window["DatabaseDesigner"].Shared.FieldSortGroupOptions[groupName];
    var fields = _.filter(this._fields.Items, function (item: any) {
        return (item.Sort >= sortGroup.Item1 && item.Sort <= sortGroup.Item2);
    });


    if (fields.length > 0) {
        var maxField = _.max(fields, function (item) {
            return item.Sort;
        });
        return maxField.Sort;
    }
    return sortGroup.Item1;
}

TableBaseModel.prototype.CreateDefaultTranslations = function (languages) {
    if (languages) {
        var self = this;
        self._translations = new CollectionModel({ model: TranslationModel });

        _.forEach(languages, function (language) {
            var translation = new TranslationModel();
            translation.Language = language;
            self._translations.Add(translation);
        });
    }
};

TableBaseModel.prototype.CreateMemoTranslations = function (languages) {
    if (languages) {
        var self = this;
        self._memoTranslations = [];

        _.forEach(languages, function (language: LanguageModel) {
            var translation = new MemoTranslationModel();
            translation.Language = new LanguageModel(language.K_Language, language.Name, language.ShortName, language.Enabled, language.FlagBase64);
            translation.Translation = "";
            self._memoTranslations.push(translation);
        });
    }
};

TableBaseModel.prototype.GetCustomFields = function () {
    return this.GetFieldsBySort(3000, 3999);
};

TableBaseModel.prototype.GetFieldsBySort = function (minSort, maxSort) {
    return this.Fields.Items.filter(function (field) {
        return field.Sort >= minSort && field.Sort <= maxSort;
    })
};

Object.defineProperty(TableBaseModel.prototype, 'DependsOnFieldName', {
    enumerable: true,
    get: function () {
        return this._dependsOnFieldName;
    },
    set: function (val) {
        this._dependsOnFieldName = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'DependsOnFieldId', {
    enumerable: true,
    get: function () {
        return this._dependsOnFieldId;
    },
    set: function (val) {
        this._dependsOnFieldId = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'DependsOnFieldGuid', {
    enumerable: true,
    get: function () {
        return this._dependsOnFieldGuid;
    },
    set: function (val) {
        this._dependsOnFieldGuid = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'MemoTranslations', {
    enumerable: true,
    get: function () {
        return this._memoTranslations;
    },
    set: function (val) {
        this._memoTranslations = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'Memo', {
    enumerable: true,
    get: function () {
        return this._memo;
    },
    set: function (val) {
        this._memo = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'Id', {
    enumerable: true,
    get: function () {
        return this._id;
    },
    set: function (val) {
        this._id = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'Name', {
    enumerable: true,
    get: function () {
        return this._name;
    },
    set: function (val) {
        this._name = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'TypeId', {
    enumerable: true,
    get: function () {
        return this._typeId;
    },
    set: function (val) {
        this._typeId = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'Fields', {
    enumerable: true,
    get: function () {
        return this._fields;
    },
    set: function (val) {
        this._fields = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'AttachedControlTypes', {
    enumerable: true,
    get: function () {
        return this._attachedControlTypes;
    },
    set: function (val) {
        this._attachedControlTypes = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'ShortName', {
    enumerable: true,
    get: function () {
        return this._shortName;
    },
    set: function (val) {
        this._shortName = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'Types', {
    enumerable: true,
    get: function () {
        return this._types;
    },
    set: function (val) {
        this._types = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'Icon', {
    enumerable: true,
    get: function () {
        return this._icon;
    },
    set: function (val) {
        this._icon = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'IconType', {
    enumerable: true,
    get: function () {
        return this._iconType;
    },
    set: function (val) {
        this._iconType = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'IconId', {
    enumerable: true,
    get: function () {
        return this._iconId;
    },
    set: function (val) {
        this._iconId = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'IconImage', {
    enumerable: true,
    get: function () {
        return this._iconImage;
    },
    set: function (val) {
        this._iconImage = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'EntityColor', {
    enumerable: true,
    get: function () {
        return this._entityColor;
    },
    set: function (val) {
        this._entityColor = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'SubjectAreaId', {
    enumerable: true,
    get: function () {
        return this._subjectAreaId;
    },
    set: function (val) {
        this._subjectAreaId = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'OnDelete', {
    enumerable: true,
    get: function () {
        return this._onDelete;
    },
    set: function (val) {
        this._onDelete = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'TypeName', {
    get: function () {
        return this._typeName;
    },
    set: function (val) {
        this._typeName = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'LifeStatusName', {
    enumerable: true,
    get: function () {
        return this._lifeStatusName;
    },
    set: function (val) {
        this._lifeStatusName = val;
    }
});


Object.defineProperty(TableBaseModel.prototype, 'Translations', {
    enumerable: true,
    get: function () {
        return this._translations;
    },
    set: function (val) {
        this._translations = val;
    }
});


Object.defineProperty(TableBaseModel.prototype, 'IsView', {
    enumerable: true,
    get: function () {
        return this._isView;
    },
    set: function (val) {
        this._isView = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'Query', {
    enumerable: true,
    get: function () {
        return this._query;
    },
    set: function (val) {
        this._query = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'ConnectionString', {
    enumerable: true,
    get: function () {
        return this._connectionString;
    },
    set: function (val) {
        this._connectionString = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'IsExternalTable', {
    enumerable: true,
    get: function () {
        return !!this._connectionString;
    },
    set: function (val) {}
});

Object.defineProperty(TableBaseModel.prototype, 'Trigger', {
    enumerable: true,
    get: function () {
        return this._trigger;
    },
    set: function (val) {
        this._trigger = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'SequenceType', {
    enumerable: true,
    get: function () {
        return this._sequenceType;
    },
    set: function (val) {
        this._sequenceType = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'IsDynamicView', {
    enumerable: false,
    get: function () {
        if (this._dynamicFields) {
            var dynamicFields = this._dynamicFields.split(',');
            return dynamicFields.length > 0;
        }
        return false;
    }
});


Object.defineProperty(TableBaseModel.prototype, 'DynamicFields', {
    enumerable: true,
    get: function () {
        return this._dynamicFields;
    },
    set: function (val) {
        this._dynamicFields = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'IsMergeView', {
    enumerable: true,
    get: function () {
        return this._isMergeView;
    },
    set: function (val) {
        this._isMergeView = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'DatabaseId', {
    enumerable: true,
    get: function () {
        return this._databaseId;
    },
    set: function (val) {
        this._databaseId = val;
    }
});

Object.defineProperty(TableBaseModel.prototype, 'Properties', {
    enumerable: true,
    get: function () {
        return this._properties;
    },
    set: function (val) {
        this._properties = val;
    }
});