import * as ko from "knockout"
import 'jquery';
import 'bootstrap-multiselect';
import $ from "jquery";

export class BootstrapMultiselect {
	private static $multiselect: any;

	static Init() {
		ko.bindingHandlers.multiselect = {
			init: (element, valueAccessor, allBindings) => {
				this.$multiselect = $(element) as any;

				($(element) as any).multiselect(valueAccessor());

				// let elementOffset = ($(element) as any).parent().offset();
				// let jBoxContent = ($(element) as any).closest( ".jBox-content" );
				let multiselectContainer = ($(element) as any).parent().find('.multiselect-container');
				multiselectContainer.css({
					'max-width': '100%'
				})
			},
			update: (element, valueAccessor, allBindings) => {
				const options = allBindings().options();

				this.$multiselect.multiselect('setOptions', options);
				this.$multiselect.multiselect('rebuild');
			}
		};
	}

	static Update() {
		this.$multiselect && this.$multiselect.multiselect('rebuild');
	}
}