import * as ko from "knockout";

import {
	RecordSharingModel,
	UserGroupModel,
	TableModel,
	RightsModel
}from "Core/Controls/RecordSharing/Models/RecordSharingModel";

export class UserGroupViewModel {
	Id: number;
	Name: string;
	AllSelected: KnockoutObservable<boolean>;

	constructor(model: UserGroupModel) {
		this.Id = model.Id;
		this.Name = !!model.Name && model.Name.trim().length > 0 ? model.Name : '(no name)';
		this.AllSelected = ko.observable(false);
	}
}

export class RightsViewModel {
	UserGroupId: number;
	IsShared: KnockoutObservable<boolean>;
	Value: number;

	constructor(model: RightsModel) {
		this.UserGroupId = model.UserGroupId;
		this.IsShared = ko.observable(model.IsShared);
		this.Value = 512;
	}
}

class TableViewModel {
	Id: number;
	Name: string;
	Rights: KnockoutObservableArray<RightsViewModel>;

	constructor(model: TableModel) {
		this.Id = model.Id;
		this.Name = model.Name;

		let rights = model.Rights.map(right => new RightsViewModel(right));
		this.Rights = ko.observableArray(rights);
	}
}

export class RecordSharingViewModel {
	UserGroups: Array<UserGroupViewModel>;
	Tables: Array<TableViewModel>;

	constructor(model: RecordSharingModel) {
		this.UserGroups = model.UserGroups.map(userGroup => new UserGroupViewModel(userGroup));
		this.Tables = model.Tables.map(table => new TableViewModel(table));
	}
}