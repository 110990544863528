import {IPosition} from "Core/Portlets/Models/Common/Interfaces/IPosition"
import {IPortletInfo} from "Core/Portlets/Models/Common/Interfaces/IPortletInfo"

export interface IKeyValuePair<TKey, TValue> {
	Key: TKey;
	Value: TValue;
}

export class GuidToPortletInfoMap {
	private _keys: Array<string>;
	private _dictionary: { [guid: string]: IPortletInfo };

	constructor() {
		this._keys = new Array<string>();
		this._dictionary = {};
	}

	Add(guid: string, portletInfo: IPortletInfo) {
		this._keys.push(guid);
		this._dictionary[guid] = portletInfo;
	}

	Get(guid: string) {
		return this._dictionary[guid];
	}

	ToArray() {
		let array = new Array<IKeyValuePair<string, IPortletInfo>>();

		this._keys.forEach(key => array.push({
			Key: key,
			Value: this._dictionary[key]
		}));

		return array;
	}
}