import * as _ from 'underscore';
import {deserialize, deserializeAs, serializeAs, serialize, Deserialize, GenericDeserialize, Serialize} from 'libs/cerialize';
import { QueryColumnModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryColumnModel';
import { ConditionValueTypes } from 'QueryBuilder/Enums';
import { QueryExpressionModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';

export class QueryConditionItemModel {

	@serialize
	@deserialize
	Value: string;

	@serialize
	@deserialize
	ValueType: number;

	@serialize
	@deserialize
	Operator: number;

	@serializeAs(QueryColumnModel)
	@deserializeAs(QueryColumnModel)
	Column: QueryColumnModel;

	@serializeAs(QueryColumnModel)
	@deserializeAs(QueryColumnModel)
	ValueColumn: QueryColumnModel;

	@serialize
	@deserialize
	ShowParam: boolean;

	@serialize
	@deserialize
	IsEnabled: boolean;

	@serialize
	@deserialize
	SPIMFeatureType: string;

	SubQuery: QueryExpressionModel;

	public static OnSerialized(instance : QueryConditionItemModel, json : any) : void {
		if(instance.ValueType === ConditionValueTypes.QueryBuilder && instance.SubQuery){
			let queryExpressionType = require('Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel').QueryExpressionModel;
			json.Value = JSON.stringify(Serialize(instance.SubQuery, queryExpressionType));
		}
	}

	static OnDeserialized(instance: QueryConditionItemModel, json: any): void {

		if(instance.ValueType === ConditionValueTypes.QueryBuilder){
			let queryExpressionType = require('Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel').QueryExpressionModel;
			instance.SubQuery = instance.Value == '' ? null : Deserialize(JSON.parse(instance.Value), queryExpressionType);
		}
		
    }
}