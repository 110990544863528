export const DATA_TYPES = {
	DATABASE: {Name: 'Database', Icon: 'fa fa-database'},
	SPREADSHEET: {Name: 'Spreadsheet', Icon: 'fa fa-file-excel-o'},
	QUERY: {Name: 'Query', Icon: 'fa fa-code'},
	API: {Name: 'API', Icon: 'fa fa-server'},
	TRIGGER: {Name: 'Trigger', Icon: 'fa fa-bolt'},
	DW_PACKAGE: {Name: 'DwPackage', Icon: 'fa fa-cube'},
	EXACT_ONLINE: {Name: 'ExactOnline', Icon: 'fa fa-bicycle'},
	EWS: {Name: 'EWS', Icon: 'fa fa-envelope'},
	OCI_PUNCH_OUT: {Name: 'OCI PunchOut', Icon: 'icon-boxing-glove'},
	MS_ACCESS: {Name: 'MS Access', Icon: 'fa fa-align-justify'},
	DESKTOP_FOLDER: { Name: 'Desktop folder', Icon: 'fa fa-folder-open'}
}

export enum DataTypes {
	Cyberbase,
	Database,
	File,
	Service,
	Query,
	ApiPackage,
	Trigger,
	DwPackage,
	ExactOnline,
	EWS,
	OCIPunchOut,
	MsAccess,
	DesktopFolder
}