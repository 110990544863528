import * as _ from 'underscore';
import * as ko from 'knockout';
import {BaseColumn, SortOrder} from 'Core/Controls/Grid/BaseGrid/GridColumn/BaseColumn';
import {EVENTS} from "Core/Controls/Grid/BaseGrid/Events";
import {GridColumnModel} from "Core/Controls/Grid/Models/GridDataModel/GridColumnModel";
import {SortModel} from "Core/Controls/Grid/Models/SortModel";
import {BlockUI} from "Core/Common/BlockUi";
import enumerable from '../../../Common/Decorators/EnumerableDecorator';
import {FIELD_TYPES} from 'Core/Constant';
import {extractLookupValFieldMetadata} from "Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel";


export class DeduplicationHeader extends BaseColumn{
	private _columnName: string;

	constructor(model: GridColumnModel, columnName: string) {
        super(model);

        this._columnName = columnName;
	}

	@enumerable get Title(): string {
		return this._columnName;
	}

    get IsEnableSort(): boolean {
        return this._model.IsEnableSort;
    }

    ResetSort() {
		this._sortOrder(SortOrder.Both);
    }

	get SortOrder() {
		return this._sortOrder();
	}

	set SortOrder(order) {
		this._sortOrder(order);
	}

	GetSortModel(): SortModel {
		const model = new SortModel();

		model.OrderColumn = this._model.Alias;
		model.SortOrder = this._sortOrder();

		return model;
	}


	Sort() {
        BlockUI.Block();
		if (this._sortOrder() === SortOrder.Both) {
			this._sortOrder(SortOrder.Asc);
		} else if (this._sortOrder() === SortOrder.Asc) {
			this._sortOrder(SortOrder.Desc);
		} else if (this._sortOrder() === SortOrder.Desc) {
			this._sortOrder(SortOrder.Both);
		}
		this.Trigger(EVENTS.SORT);
    }

	GetClassNames(model: any, classNames?: string[]): string {
		let result: string;
		let additionalClassNames: string;
		if(!this.IsEnableSort){
			return '';
		}

		if (model.FieldMetadata) {
			result =
				model.FieldMetadata.Type.toString() + ' ' + model.FieldMetadata.FormatName.toString().replace(' ', '');

			if (model.FieldMetadata.Type === FIELD_TYPES.Lookup) {
				const validationFieldMetadata = extractLookupValFieldMetadata(model.FieldMetadata);

				if (validationFieldMetadata) {
					result += ` ${validationFieldMetadata.Type} ${validationFieldMetadata.FormatName.toString().replace(
						' ',
						''
					)}`;
				}
			}
		} else if (model.Type) {
			result = model.Type;
		} else {
			result = '';
		}

		if (classNames) {
			additionalClassNames = classNames.join(' ');

			return result + ' ' + additionalClassNames;
		}

		return result;
	}
}