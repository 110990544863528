import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import { SubFlowDto } from './SubFlowDto';
import { Deserialize } from 'libs/cerialize';

export interface IGetSubFlowsDto{
    EntityId: number;
    TypeId: number;
}

export class SubFlowStore {

    static GetSubFlows(params: IGetSubFlowsDto) {
        const deferred = P.defer<SubFlowDto[]>();

        Request.Send({
            proxy: {
                url: '/api/FormDesignerApi/GetSubFlows',
                type: 'GET'
            },
            params: params
        })
            .then(subFlows => {
                deferred.resolve(Deserialize(subFlows, SubFlowDto))})
            .fail(error => {

            });

        return deferred.promise();
    }
}