import { deserializeAs } from "libs/cerialize";
import {LifeStatusesGeneralModel} from "../../../../Controls/ButtonFollowUp/Models/LifeStatusesGeneralModel";
import {FlowFolderModel} from "./FlowFolderModel";

export class ProgressBarInfoModel {
	@deserializeAs(LifeStatusesGeneralModel)
	NextLifeStatuses: LifeStatusesGeneralModel;

	@deserializeAs(FlowFolderModel)
	FlowFolder: FlowFolderModel;
}