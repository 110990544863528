import * as ko from 'knockout';
import 'jquery';

export class ResizableExtention {
	static Init() {
		ko.bindingHandlers.resizable = {
			init: (element, valueAccessor) => {
				var options = valueAccessor();
				options.minWidth = ko.unwrap(options.minWidth);
				options.minHeight = ko.unwrap(options.minHeight);
				$(element).resizable(options);
			},
			update: (element, valueAccessor) => {
				var options = valueAccessor();
				options.minWidth = ko.unwrap(options.minWidth);
				options.minHeight = ko.unwrap(options.minHeight);
				$(element).resizable(options);
				$(element).trigger('resize');
			}
		};
	}
}