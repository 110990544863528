import * as ko from 'knockout';

import Template from 'Core/Controls/CanvasDesigner/Shapes/Views/DataSelectorView/Templates/DataSelectorTemplate.html'
import { DATA_TYPES } from 'Core/Controls/CanvasDesigner/Constants/DataTypes';

import { EVENTS as QUERY_BUILDER_EVENTS } from 'QueryBuilder/Events';
import { Event } from 'Core/Common/Event'
import { EVENTS } from 'Core/Controls/CanvasDesigner/Shapes/Views/DataSelectorView/Events';
import { LABELS } from "Core/Components/Translation/Locales";
import { CanvasDesignerStore } from 'Core/Controls/CanvasDesigner/Stores/CanvasDesignerStore';
import { BlockUI } from 'Core/Common/BlockUi';
import { CanvasItemModel } from 'Core/Controls/CanvasDesigner/Models/CanvasModel';
import { Modal } from 'Core/Common/Modal';
import { QueryBuilder } from 'QueryBuilder/QueryBuilder';
import { CANVAS_TYPES } from "Core/Controls/CanvasDesigner/Constants/CanvasTypes";
import { Notifier } from 'Core/Common/Notifier';
import { MsAccessSchemaDto } from 'Core/Controls/CanvasDesigner/Models/Dto/MsAccessSchemaDto';
import { MsAccessTableDto } from 'Core/Controls/CanvasDesigner/Models/Dto/MsAccessTableDto';
import { TriggerDto } from 'Core/Controls/CanvasDesigner/Models/Dto/TriggerDto';
import { TriggerGroupViewModel } from 'Core/Controls/CanvasDesigner/Models/TriggerGroupViewModel';

ko.templates['Core/Controls/CanvasDesigner/Shapes/Views/DataSelectorView/Templates/DataSelectorTemplate'] = Template;

export class DataSelector extends Event {

	private _labels = LABELS;
	private _data_types = DATA_TYPES;

	Title: string;
	DataTypes: KnockoutObservableArray<string>;
	IsEnabled: KnockoutObservable<boolean>;
	NextStep: KnockoutObservable<boolean>;
	SelectedDataType: KnockoutObservable<string>;
	Triggers: KnockoutObservableArray<TriggerGroupViewModel>;
	FunctionPackages: KnockoutObservableArray<CanvasItemModel>;
	DataWizardPackages: KnockoutObservableArray<CanvasItemModel>;
	Entities: KnockoutObservableArray<any>;
	ExactOnlineEntities: KnockoutObservableArray<string>;
	EWSEntities: KnockoutObservableArray<string>;

	IsShowFileUpload: KnockoutObservable<boolean>;
	IsShowTriggerList: KnockoutObservable<boolean>;
	IsShowFunctionPackageList: KnockoutObservable<boolean>;
	IsShowEntityList: KnockoutObservable<boolean>;
	IsShowNewQueryButton: KnockoutObservable<boolean>;
	IsShowDwPackageList: KnockoutObservable<boolean>;
	IsShowExactOnlineEntityList: KnockoutObservable<boolean>;
	IsShowEWSEntityList: KnockoutObservable<boolean>;
	IsShowMsAccessEntityList: KnockoutObservable<boolean>;
	MsAccessSchema: KnockoutObservable<MsAccessSchemaDto>;

	constructor(title: string, dataTypes: Array<string>) {
		super();
		this.Title = title;
		this.DataTypes = ko.observableArray(dataTypes);
		this.IsEnabled = ko.observable(false);
		this.NextStep = ko.observable(false);
		this.IsShowFileUpload = ko.observable(false);
		this.IsShowTriggerList = ko.observable(false);
		this.IsShowFunctionPackageList = ko.observable(false);
		this.IsShowDwPackageList = ko.observable(false);
		this.IsShowEntityList = ko.observable(false);
		this.IsShowNewQueryButton = ko.observable(false);
		this.IsShowExactOnlineEntityList = ko.observable(false);
		this.IsShowEWSEntityList = ko.observable(false);
		this.IsShowMsAccessEntityList = ko.observable(false);
		this.MsAccessSchema = ko.observable(null);

		this.SelectedDataType = ko.observable('');
		this.Triggers = ko.observableArray([]);
		this.FunctionPackages = ko.observableArray([]);
		this.Entities = ko.observableArray([]);
		this.DataWizardPackages = ko.observableArray([]);
		this.ExactOnlineEntities = ko.observableArray([]);
		this.EWSEntities = ko.observableArray([]);

		this.AddEvent(EVENTS.SPREADSHEET_SELECTED);
		this.AddEvent(EVENTS.TRIGGER_SELECTED);
		this.AddEvent(EVENTS.API_PACKAGE_SELECTED);
		this.AddEvent(EVENTS.ENTITY_SELECTED);
		this.AddEvent(EVENTS.QUERY_SELECTED);
		this.AddEvent(EVENTS.DW_PACKAGE_SELECTED);
		this.AddEvent(EVENTS.EXACT_ONLINE_ENTITY_SELECTED);
		this.AddEvent(EVENTS.EWS_ENTITY_SELECTED);
		this.AddEvent(EVENTS.OCI_PUNCH_OUT_SELECTED);
		this.AddEvent(EVENTS.MS_ACCESS_ENTITY_SELECTED);
		this.AddEvent(EVENTS.DESKTOP_FOLDER_SELECTED);
	}

	GetTemplateName() {
		return 'Core/Controls/CanvasDesigner/Shapes/Views/DataSelectorView/Templates/DataSelectorTemplate';
	}

	AfterRender() {}

	Render(target: HTMLElement) {
		ko.cleanNode(target);
		ko.applyBindings(this, target);
	}

	SetIsEnabled(value: boolean) {
		this.IsEnabled(value);
	}

	async SelectDataType(dataType: string) {

		this.SelectedDataType(dataType);

		if (dataType == DATA_TYPES.OCI_PUNCH_OUT.Name) {
			this.Trigger(EVENTS.OCI_PUNCH_OUT_SELECTED);
			return;
		}
		
		if (dataType == DATA_TYPES.DATABASE.Name) {
			this.ShowEntityList();
		}

		if (dataType == DATA_TYPES.SPREADSHEET.Name) {
			this.ShowFileUpload();
		}

		if (dataType == DATA_TYPES.QUERY.Name) {
			this.ShowQueryNewQueryButton();
		}

		if (dataType == DATA_TYPES.API.Name) {
			this.ShowFunctionPackageList();
		}

		if (dataType == DATA_TYPES.TRIGGER.Name) {
			this.ShowTriggerList();
		}

		if (dataType == DATA_TYPES.DW_PACKAGE.Name) {
			this.ShowDWPackageList();
		}

		if (dataType == DATA_TYPES.EXACT_ONLINE.Name) {
			this.ShowExactOnlineEntityList();
		}

		if (dataType == DATA_TYPES.EWS.Name) {
			this.ShowEWSEntityList();
		}

		if (dataType == DATA_TYPES.MS_ACCESS.Name) {
			this.MsAccessSchema(await this.GetMSAccessFilePath());
			this.Title = this.MsAccessSchema().Path;
			this.IsShowMsAccessEntityList(true);
		}

		if (dataType == DATA_TYPES.DESKTOP_FOLDER.Name) {
			let path = await this.GetDesktopFolderPath();
			if(path && path != ''){
				this.Trigger(EVENTS.DESKTOP_FOLDER_SELECTED, { Path: path });
			}else{
				return;
			}			
		}

		this.NextStep(true);
	}

	async GetDesktopFolderPath(): Promise<string>{
		BlockUI.Block();

		let result = await CanvasDesignerStore.GetDesktopFolderPath()
			.always(() => BlockUI.Unblock())
			.fail(err => Notifier.Failed(err.message));

		return result.Path;
	}

	async GetMSAccessFilePath(): Promise<MsAccessSchemaDto>{
		BlockUI.Block();

		let result = await CanvasDesignerStore.GetMsAccessSchema()
			.always(() => BlockUI.Unblock())
			.fail(err => Notifier.Failed(err.message));

		return result;
	}

	ShowEWSEntityList(){
		BlockUI.Block();
		
		CanvasDesignerStore.GetEWSEntities()
			.always(() => {
				BlockUI.Unblock();
			})
			.then((result) => {				
				this.EWSEntities(result);
				this.IsShowEWSEntityList(true);
			});
	}

	ShowExactOnlineEntityList(){
		BlockUI.Block();
		
		CanvasDesignerStore.GetExactOnlineEntities()
			.always(() => {
				BlockUI.Unblock();
			})
			.then((result) => {
				this.ExactOnlineEntities(result);
				this.IsShowExactOnlineEntityList(true);
			});
	}

	ShowDWPackageList(){
		BlockUI.Block();
		CanvasDesignerStore.GetDataWizardPackages()
			.always(() => {
				BlockUI.Unblock();
			})
			.then((result) => {
				this.DataWizardPackages(result);
				this.IsShowDwPackageList(true);
			});
	}

	ShowTriggerList() {
		BlockUI.Block();
		CanvasDesignerStore.GetTriggers()
			.always(() => {
				BlockUI.Unblock();
			})
			.then((result) => {
				this.PrepareTriggerData(result);
				this.IsShowTriggerList(true);
		});
	}

	Toggle(triggerGroup: TriggerGroupViewModel){
		triggerGroup.IsExpanded(!triggerGroup.IsExpanded());
	}

	private PrepareTriggerData(triggers: Array<TriggerDto>){
		let groups = ['General', 'GenericButton', 'Grid', 'LifeStatus', 'Other'];
		_.each(groups, (group)=>{
			this.Triggers.push(new TriggerGroupViewModel(group, triggers));	
		});
	}

	ShowFunctionPackageList() {
		BlockUI.Block();
		CanvasDesignerStore.GetFunctionPackages()
			.always(() => {
				BlockUI.Unblock();
			})
			.then((result) => {
				this.FunctionPackages(result);
				this.IsShowFunctionPackageList(true);
			});
	}

	ShowQueryBuilder() {
		let queryBuilder = new QueryBuilder(true, false, false, true);
		queryBuilder.NewFreeQuery();

		queryBuilder.On(QUERY_BUILDER_EVENTS.CLOSE, this, () => { modal.Close(); });
		queryBuilder.On(QUERY_BUILDER_EVENTS.DATA_SAVED, this, (eventArgs: any) => {
			modal.Close();
			this.Trigger(EVENTS.QUERY_SELECTED, { Query: eventArgs.data.QueryText });
		});

		let modal = new Modal({});
		ko.cleanNode(modal.Wrapper);
		ko.applyBindings(queryBuilder, modal.Wrapper);
		modal.Show();
	}

	ShowQueryNewQueryButton() {
		this.IsShowNewQueryButton(true);
	}

	ShowFileUpload() {
		this.IsShowFileUpload(true);
	}

	ShowEntityList() {
		BlockUI.Block();

		CanvasDesignerStore.GetEntities({ IncludeDeleted: this.Title === CANVAS_TYPES.SOURCE })
			.always(() => {
				BlockUI.Unblock();
			})
			.then((result) => {
				this.IsShowFileUpload(false);
				this.IsShowTriggerList(false);
				this.IsShowFunctionPackageList(false);
				this.IsShowEntityList(true);
				this.Entities(result);
			});
	}

	SelectTrigger(trigger: CanvasItemModel) {
		this.Trigger(EVENTS.TRIGGER_SELECTED, { Id: trigger.Id });
	}


	SelectEntity(trigger: CanvasItemModel) {
		this.Trigger(EVENTS.ENTITY_SELECTED, { Id: trigger.Id });
	}

	SelectPackage(apiPackage: CanvasItemModel) {
		this.Trigger(EVENTS.API_PACKAGE_SELECTED, { Id: apiPackage.Id });
	}

	UploadFile(fileInput: HTMLInputElement) {
		let file = fileInput.files[0];
		this.Trigger(EVENTS.SPREADSHEET_SELECTED, { File: file });
	}

	SelectDataWizardPackage(dwPackage: CanvasItemModel){
		this.Trigger(EVENTS.DW_PACKAGE_SELECTED, { Id: dwPackage.Id });
	}

	SelectExactOnlineEntity(name: string){
		this.Trigger(EVENTS.EXACT_ONLINE_ENTITY_SELECTED, { Name: name });
	}

	SelectEWSEntity(name: string){
		this.Trigger(EVENTS.EWS_ENTITY_SELECTED, { Name: name });
	}

	SelectMsAccessEntity(table: MsAccessTableDto){
		this.Trigger(EVENTS.MS_ACCESS_ENTITY_SELECTED, { Table: table, Path: this.MsAccessSchema().Path });
	}

	Back() {
		this.Entities([]);
		this.Triggers([]);
		this.ExactOnlineEntities([]);
		this.DataWizardPackages([]);
		this.EWSEntities([]);
		this.MsAccessSchema(null);

		this.IsShowFileUpload(false);
		this.IsShowTriggerList(false);
		this.IsShowFunctionPackageList(false);
		this.IsShowEntityList(false);
		this.NextStep(false);
		this.IsShowNewQueryButton(false);
		this.IsShowExactOnlineEntityList(false);
		this.IsShowEWSEntityList(false);
		this.IsShowDwPackageList(false);
		this.IsShowMsAccessEntityList(false);
	}

	FormatTriggerTitle(triggerDto: TriggerDto){
		return `${triggerDto.Name} ${triggerDto.Description}`;
	}
}