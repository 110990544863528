import * as ko from 'knockout';
import * as _ from 'underscore';
import * as $ from "jquery";

import {Modal} from 'Core/Common/Modal';
import {Event} from 'Core/Common/Event';

import {LABELS} from 'Core/Components/Translation/Locales';

import {IPropertyDescription, IPropertyOption, ISource} from "../../../GeneralProperties/Managers/BaseProperty";
import {ColorPicker} from "../../../GeneralProperties/Managers/ColorPicker/ColorPicker";
import {SelectProperty} from "../../../GeneralProperties/Managers/SelectProperty/SelectProperty";
import {CARD_SCREEN_PROPERTY_TYPE, SCREEN_PROPERTY_TYPE} from "Core/Constant";
import {IWebSizeValue, WebSizeProperty} from "../../../GeneralProperties/Managers/WebSizeProperty/WebSizeProperty";
import {ScreenTypes} from "Core/Common/Enums/ScreenTypes";

import CardScreenSettingsModalTemplate from 'Core/Controls/FormDesigner/SettingsModal/Templates/CardScreenSettingsModal.html';
ko.templates['Core/Controls/FormDesigner/SettingsModal/Templates/CardScreenSettingsModal'] = CardScreenSettingsModalTemplate;

import SettingsModalTemplate from 'Core/Controls/FormDesigner/SettingsModal/Templates/SettingsModal.html';
ko.templates['Core/Controls/FormDesigner/SettingsModal/Templates/SettingsModal'] = SettingsModalTemplate;

export interface ISettingsModal {
    Type: string;
    Value?: any;
}
export class SettingsModal extends Event {
    private _modal = new Modal(
        { addClass: 'settings-modal jBox-padding-15px' },
        false
    );

    _labels = LABELS;

    private _bgColorCardScreen: ColorPicker;
    private _widthCardScreen: WebSizeProperty;
    private _fontColorCardScreen: ColorPicker;
    private _fontWeightCardScreen: SelectProperty;
    private _fontFamilyCardScreen: SelectProperty;
    private _fontStyleCardScreen: SelectProperty;
    private _params: KnockoutObservableArray<ISettingsModal>;

    private _fontStyleOptions: KnockoutObservable<any>;
    private _fontFamily: KnockoutObservable<string>;
    private _fontColor: KnockoutObservable<string>;
    private _fontWeight: KnockoutObservable<string>;
    private _fontStyle: KnockoutObservable<string>;
    private _styleText: KnockoutObservable<any>;
    private _screenType: string;
    private _screenBackgroundColor: ColorPicker;
    private _screenHeaderBackgroundColor: ColorPicker;
    private _screenBGColor: KnockoutObservable<string>;
    private _screenHeaderBGColor: KnockoutObservable<string>;

    constructor(params: Array<ISettingsModal>, screenType: string) {
        super();
        this._screenType = screenType;

        this._fontStyleOptions = ko.observable(null);
        this._fontColor = ko.observable(null);
        this._fontFamily = ko.observable(null);
        this._fontWeight = ko.observable(null);
        this._fontStyle = ko.observable(null);
        this._styleText = ko.observable(null);
        this._screenBGColor = ko.observable(null);
        this._screenHeaderBGColor = ko.observable(null);

        this._params = ko.observableArray(params);
        this.InitSettingControls(params);
    }

    InitSettingControls(params: Array<ISettingsModal>): void {
        if (this._screenType === ScreenTypes[ScreenTypes.CardScreen]) {
            this.InitWidthCardScreen(this.FindElementByType(params, CARD_SCREEN_PROPERTY_TYPE.SCREEN_WIDTH)?.Value);
            this.InitBackgroundColorScreen(this.FindElementByType(params, CARD_SCREEN_PROPERTY_TYPE.SCREEN_BACKGROUND_COLOR)?.Value);
            this.InitFontColor(this.FindElementByType(params, CARD_SCREEN_PROPERTY_TYPE.FONT_COLOR)?.Value);
            this.InitFontWeight(this.FindElementByType(params, CARD_SCREEN_PROPERTY_TYPE.FONT_WEIGHT)?.Value);
            this.InitFontFamily(this.FindElementByType(params, CARD_SCREEN_PROPERTY_TYPE.FONT_FAMILY)?.Value);
            this.InitFontStyle(this.FindElementByType(params, CARD_SCREEN_PROPERTY_TYPE.FONT_STYLE)?.Value);
        } else {
            this.InitScreenBGColor(this.FindElementByType(params, SCREEN_PROPERTY_TYPE.SCREEN_BACKGROUND_COLOR)?.Value);
            this.InitScreenHeaderBGColor(this.FindElementByType(params, SCREEN_PROPERTY_TYPE.SCREEN_HEADER_BACKGROUND_COLOR)?.Value);
        }
    }

    FindElementByType(array: ISettingsModal[], type: string): ISettingsModal | undefined {
        return _.find(array, { Type: type });
    }

    InitWidthCardScreen(value?: IWebSizeValue) {
        let propertyValue: IWebSizeValue | undefined = value,
            options: Array<IPropertyOption> = [
                {Name: "%", Value: '%'},
                {Name: "px", Value: 'px'}
            ],
            defaultOptions: IPropertyOption = {Name: "%", Value: '%'},
            property: IPropertyDescription = {
                Name: "Screen Width",
                Type: CARD_SCREEN_PROPERTY_TYPE.SCREEN_WIDTH,
                PropertyManager: "WebSizeProperty",
                ValueRequired: false,
                Options: options,
                Source: null,
                DefaultValue: defaultOptions
            };

        this._widthCardScreen = new WebSizeProperty(property, propertyValue);
    }

    InitBackgroundColorScreen(value?: string) {
        let propertyValue: string | undefined = value,
            property: IPropertyDescription = {
                Name: "Background Color",
                Type: CARD_SCREEN_PROPERTY_TYPE.SCREEN_BACKGROUND_COLOR,
                PropertyManager: "ColorPicker",
                ValueRequired: false,
                Options: null,
                Source: null
            };

        this._bgColorCardScreen = new ColorPicker(property, propertyValue);
    }

    InitFontColor(value?: string){
        let propertyValue: string | undefined = value,
            property: IPropertyDescription = {
                Name: "Font Color",
                Type: CARD_SCREEN_PROPERTY_TYPE.FONT_COLOR,
                PropertyManager: "ColorPicker",
                ValueRequired: false,
                Options: null,
                Source: null
            };

        this._fontColorCardScreen = new ColorPicker(property, propertyValue);
        this._styleText(propertyValue ? {'color': propertyValue} : null);
    }

    InitFontWeight(value?: IPropertyOption) {
        let getData = null,
            propertyValue: IPropertyOption | undefined = value,
            options: Array<IPropertyOption> = [
                {Name: "Light", Value: 'font-light'},
                {Name: "Regular", Value: 'font-regular'},
                {Name: "Medium", Value: 'font-medium'},
                {Name: "Bold", Value: 'font-bold'},
                {Name: "Black", Value: 'font-black'},
            ],
            property: IPropertyDescription = {
                Name: "Font Weight",
                Type: CARD_SCREEN_PROPERTY_TYPE.FONT_WEIGHT,
                PropertyManager: "SelectProperty",
                ValueRequired: false,
                Options: options,
                Source: null
            };

        this._fontWeightCardScreen = new SelectProperty(property, propertyValue, getData);
        this._fontWeight(propertyValue?.Value);
    }

    InitFontFamily(value?: IPropertyOption) {
        // add new font:
        // 1. https://fonts.google.com/selection/embed
        // 2. update <link href="https://fonts.googleapis.com/css2?family=...  on _Layout.cshtml
        // 2. update @mixin customFontFamily on _mixins.scss
        // 3. update options
        let getData = null,
            propertyValue: IPropertyOption | undefined = value,
            options: Array<IPropertyOption> = [
                {Name: "Roboto", Value: 'roboto-font'},
                {Name: "Lato", Value: 'lato-font'},
                {Name: "Poppins", Value: 'poppins-font'},
                {Name: "Inria Sans", Value: 'inria-sans-font'},
                {Name: "Ubuntu", Value: 'ubuntu-font'},
                {Name: "Fira Sans", Value: 'fira-sans-font'},
                {Name: "Titillium Web", Value: 'titillium-web-font'},
            ],
            property: IPropertyDescription = {
                Name: "Font Family",
                Type: CARD_SCREEN_PROPERTY_TYPE.FONT_FAMILY,
                PropertyManager: "SelectProperty",
                ValueRequired: false,
                Options: options,
                Source: null
            };

        this._fontFamilyCardScreen = new SelectProperty(property, propertyValue, getData);
        this._fontFamily(propertyValue?.Value);
    }

    InitFontStyle(value?: IPropertyOption) {
        let getData = null,
            propertyValue: IPropertyOption | undefined = value,
            options: Array<IPropertyOption> = [
                {Name: "Normal", Value: 'font-normal'},
                {Name: "Italic", Value: 'font-italic'},
            ],
            property: IPropertyDescription = {
                Name: "Font Style",
                Type: CARD_SCREEN_PROPERTY_TYPE.FONT_STYLE,
                PropertyManager: "SelectProperty",
                ValueRequired: false,
                Options: options,
                Source: null
            };

        this._fontStyleCardScreen = new SelectProperty(property, propertyValue, getData);
        this._fontStyle(propertyValue?.Value);
    }

    InitScreenBGColor(value?: string){
        let propertyValue: string | undefined = value,
            property: IPropertyDescription = {
                Name: "Screen Background Color",
                Type: SCREEN_PROPERTY_TYPE.SCREEN_BACKGROUND_COLOR,
                PropertyManager: "ColorPicker",
                ValueRequired: false,
                Options: null,
                Source: null
            };

        this._screenBackgroundColor = new ColorPicker(property, propertyValue);
        this._screenBGColor(propertyValue ? propertyValue : null);
    }

    InitScreenHeaderBGColor(value?: string){
        let propertyValue: string | undefined = value,
            property: IPropertyDescription = {
                Name: "Screen Header Background Color",
                Type: SCREEN_PROPERTY_TYPE.SCREEN_HEADER_BACKGROUND_COLOR,
                PropertyManager: "ColorPicker",
                ValueRequired: false,
                Options: null,
                Source: null
            };

        this._screenHeaderBackgroundColor = new ColorPicker(property, propertyValue);
        this._screenHeaderBGColor(propertyValue ? propertyValue : null);
    }

    Show() {
        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
        this._modal.Show();
    }

    OnSubmit() {
        let screenOptions: Array<ISettingsModal> = null;

        if (this._screenType === ScreenTypes[ScreenTypes.CardScreen]) {

            screenOptions = [
                {Type: this._bgColorCardScreen.PropertyDescriptionType, Value: this._bgColorCardScreen.Value()},
                {Type: this._widthCardScreen.PropertyDescriptionType, Value: this._widthCardScreen.Value()},
                {Type: this._fontColorCardScreen.PropertyDescriptionType, Value: this._fontColorCardScreen.Value()},
                {Type: this._fontFamilyCardScreen.PropertyDescriptionType, Value: this._fontFamilyCardScreen.Value()},
                {Type: this._fontWeightCardScreen.PropertyDescriptionType, Value: this._fontWeightCardScreen.Value()},
                {Type: this._fontStyleCardScreen.PropertyDescriptionType, Value: this._fontStyleCardScreen.Value()}
            ];

        } else {

            screenOptions = [
                {Type: this._screenBackgroundColor.PropertyDescriptionType, Value: this._screenBackgroundColor.Value()},
                {Type: this._screenHeaderBackgroundColor.PropertyDescriptionType, Value: this._screenHeaderBackgroundColor.Value()},
            ];

        }


        // let hasChanges: boolean = !_.isEqual(screenOptions, this._params());
        let hasChanges: boolean = true;

        if (screenOptions?.length) {
            this.Trigger('SUBMIT', { Options: screenOptions, HasChanges: hasChanges});
        }  else {
            this.Trigger('SUBMIT', { Options: null, HasChanges: hasChanges });
        }

        this._modal.Close();
    }

    GetTemplateName() {
        let isCardScreen:boolean = this._screenType === ScreenTypes[ScreenTypes.CardScreen];
        return isCardScreen ? 'Core/Controls/FormDesigner/SettingsModal/Templates/CardScreenSettingsModal' : 'Core/Controls/FormDesigner/SettingsModal/Templates/SettingsModal';
    }

    AfterRender() {
        if (this._screenType === ScreenTypes[ScreenTypes.CardScreen]) {
            this._fontFamilyCardScreen.Value.subscribe((newValue) => {
                this._fontFamily(newValue.Value);
            })
            this._fontWeightCardScreen.Value.subscribe((newValue) => {
                this._fontWeight(newValue.Value);
            })
            this._fontStyleCardScreen.Value.subscribe((newValue) => {
                this._fontStyle(newValue.Value);
            })
            this._fontColorCardScreen.Value.subscribe((newValue)=> {
                this._fontColor(newValue);
                this._styleText({'color': this._fontColor()});
            })
            this._fontFamily.subscribe((newValue)=>{
                this._fontStyleOptions(`${this._fontFamily()} ${this._fontWeight()} ${this._fontStyle()}`);
            })
            this._fontWeight.subscribe((newValue)=>{
                this._fontStyleOptions(`${this._fontFamily()} ${this._fontWeight()} ${this._fontStyle()}`);
            })
            this._fontStyle.subscribe((newValue)=>{
                this._fontStyleOptions(`${this._fontFamily()} ${this._fontWeight()} ${this._fontStyle()}`);
            })

            this._fontStyleOptions(`${this._fontFamily()} ${this._fontWeight()} ${this._fontStyle()}`);

        } else {

            const testScreenBodyHeader: JQuery<HTMLElement> = $(document.body).find(".test-screen_body__header");
            const testScreenBodyPageContainer: JQuery<HTMLElement> = $(document.body).find(".test-screen_body__page-container");

            this._screenBackgroundColor.Value.subscribe((newValue) => {
                this._screenBGColor(newValue)
                $(testScreenBodyPageContainer).get(0).style.setProperty("--root-background-color", newValue);
            })
            this._screenHeaderBackgroundColor.Value.subscribe((newValue) => {
                this._screenHeaderBGColor(newValue)
                $(testScreenBodyHeader).get(0).style.setProperty("--main-color-1", newValue);
            })

            if (this._screenBGColor()) {
                const testScreenBodyPageContainer: JQuery<HTMLElement> = $(document.body).find(".test-screen_body__page-container");
                $(testScreenBodyPageContainer).get(0).style.setProperty("--root-background-color", this._screenBGColor());
            }
            if (this._screenHeaderBGColor()) {
                const testScreenBodyHeader: JQuery<HTMLElement> = $(document.body).find(".test-screen_body__header");
                $(testScreenBodyHeader).get(0).style.setProperty("--main-color-1", this._screenHeaderBGColor());
            }

        }
    }
}
