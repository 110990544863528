import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {GanttChartDesignOptions} from '../Models/GanttChartDesignOptions';

export class GanttChartControlEditorStore {
    GetDesignOptions(entityId: number): P.Promise<GanttChartDesignOptions> {
        const deferred = P.defer<GanttChartDesignOptions>();

        Request.Send({
            proxy: {
                url: '/api/GanttChartApi/GetGanttChartControlConfig',
                type: 'GET',
                dataType: 'json',
            },
            params: {
                subjectTableId: entityId
            }
        })
            .then(options => deferred.resolve(options))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error getting design options')));

        return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}