import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize} from 'libs/cerialize';
import {ExampleAppointmentResponseModel} from 'Core/GeneralProperties/Managers/ExampleAppointmentProperty/Stores/Models/ExampleAppointmentResponseModel';

export class ExampleAppointmentStore {
    static GetExampleAppointments() {
        const deferred = P.defer<ExampleAppointmentResponseModel[]>();

        Request.Send({
            proxy: {
                url: '/api/FormDesignerApi/GetExampleAppointments',
                type: 'GET'
            }
        })
            .then(exampleAppointments => {
                deferred.resolve(Deserialize(exampleAppointments, ExampleAppointmentResponseModel))});

        return deferred.promise();
    }
}