'use strict';

import * as ko from "knockout";

import {BaseControl} from "Core/Controls/BaseControl/BaseControl";
import {IControlParam} from "Core/Screens/IScreen";

import 'Core/Controls/ButtonGoogleMaps/Templates/Edit.html';
import ViewTemplate from "Core/Controls/ButtonGoogleMaps/Templates/View.html";
import HelpViewTemplate from "Core/Controls/ButtonGoogleMaps/Templates/HelpView.html";
import ToolBarTemplate from "Core/Controls/ButtonGoogleMaps/Templates/ToolBar.html";
import DesignTemplate from "Core/Controls/ButtonGoogleMaps/Templates/Design.html";

ko.templates["Core/Controls/ButtonGoogleMaps/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/ButtonGoogleMaps/Templates/View"] = HelpViewTemplate;
ko.templates["Core/Controls/ButtonGoogleMaps/Templates/ToolBar"] = ToolBarTemplate;
ko.templates["Core/Controls/ButtonGoogleMaps/Templates/Design"] = DesignTemplate;

export class ButtonGoogleMaps extends BaseControl {

	constructor(params: IControlParam) {
		super(params);
		this.Init();
	}

	ApplyProperties(){}

	private Init(): void {
	}

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}
}