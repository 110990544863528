import * as ko from 'knockout'

import { BaseControl } from 'Core/Controls/BaseControl/BaseControl'
import { SubForm } from 'Core/Screens/Forms/SubForm/SubForm'
import { IControlParam } from 'Core/Screens/IScreen'

import ViewTemplate from 'Core/Controls/ButtonCancel/Templates/View.html'
import ToolbarTemplate from 'Core/Controls/ButtonCancel/Templates/ToolBar.html'
import DesignTemplate from 'Core/Controls/ButtonCancel/Templates/Design.html'
import HelpViewTemplate from "Core/Controls/ButtonCancel/Templates/HelpView.html";

ko.templates['Core/Controls/ButtonCancel/Templates/ToolBar'] = ToolbarTemplate;
ko.templates['Core/Controls/ButtonCancel/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/ButtonCancel/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/ButtonCancel/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/ButtonCancel/Templates/HelpView'] = HelpViewTemplate;

export class ButtonCancel extends BaseControl {

	constructor(params: IControlParam) {
		super(params);
		this.Init();
	}

	private Init(): void {
	}

	ApplyProperties(){}

	Click(): void {

		if (!this._help.IsHelpButtonPressed()) {
			var screen = this._form.GetScreen();
			if (screen) {
                screen.Trigger('CANCEL');
                screen.Trigger('CLOSE');
            }
		}
	}

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}
}