import {deserialize} from 'libs/cerialize';

export class SearchFieldModel {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	DefaultName: string;
}