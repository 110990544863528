import * as ko from 'knockout';
import * as _ from 'underscore';

import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

import {Modal} from "Core/Common/Modal";
import {LABELS} from "Core/Components/Translation/Locales";


import {IColorSelectorOptions} from "../../IColorSelectorOptions";

import {ColorDescription} from "../../ColorDescription";

import {ColorPalettes} from "./Enums/ColorPalettes";

import {ColorModel} from "../ColorModel";
import {ColorPaletteItem} from "./ColorPaletteItem";

import * as RALColorsJson from "Core/Components/ColorSelector/ColorModels/Palette/Data/RALColors.json";
import * as PaletteColorsJson from "Core/Components/ColorSelector/ColorModels/Palette/Data/PaletteColors.json";

import Template from './Templates/Template.html';
import Palette from './Templates/Palette.html';
import {IPaletteColorModelOptions} from "./IPaletteColorModelOptions";

ko.templates["Core/Components/ColorSelector/ColorModels/Palette/Templates/Palette"] = Palette;

export class PaletteColorModel extends ColorModel {
    private _modal: Modal;

    private _label: string;
    private _colors: ColorPaletteItem[];
    private _selectedColor: KnockoutObservable<ColorPaletteItem>;

    constructor(options: IPaletteColorModelOptions) {
        super(options);

        this._label = options.Label;

        this.InitPalette(options.Palette);
        this.InitSelectedColor(options.InitialColor);
    }

    @enumerable get Colors() {
        return this._colors;
    }

    @enumerable get ColorValue() {
        return this._selectedColor() && this._selectedColor().Value;
    }

    SetColor(color: string) {
        const item = this.GetColorItem(color);
        this._selectedColor(item);
    }

    GetTemplate(): any {
        return Template;
    }

    GetTemplateName(): string {
        return 'Core/Components/ColorSelector/ColorModels/Palette/Templates/Palette';
    }

    AfterRender() {

    }

    ShowColorPalette() {
        this._modal = new Modal({}, true);

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);

        this._modal.Show();
    }

    SelectColor(item: ColorPaletteItem) {
        this._modal.Close();

        this._selectedColor(item);
        this.Trigger('COLOR_SET', new ColorDescription(item.Name, item.Value));
    }

    Reset() {
        this._selectedColor(null);
        this.Trigger('COLOR_RESET');
    }

    private InitPalette(palette: ColorPalettes) {
        const colorsJson = palette === ColorPalettes.RAL ? RALColorsJson : PaletteColorsJson;

        try {
            this._colors = ColorPaletteItem.Deserialize(colorsJson);
            _.first(this._colors).Name = LABELS.NO_COLOR;
        } catch (e) {
            console.error(e.message);
            this._colors = [];
        }
    }

    private InitSelectedColor(color: string) {
        const paletteItem = this.GetColorItem(color);
        this._selectedColor = ko.observable(paletteItem || null);
    }

    private GetColorItem(color: string) {
        return _.find(this._colors, item => item.Name === color)
    }
}