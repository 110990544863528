import * as $ from 'jquery';
import * as _ from 'underscore';
import * as ko from 'knockout';

import {ScreenModel} from 'Core/Models/Screens/ScreenModel';

import {ResizeObserver} from 'Core/Common/ResizeObserver';

const ResizeService = new ResizeObserver();

// Templates
import Template from 'Core/Screens/Templates/AdditionalConsultScreen/ProcessCardScreen/ProcessCardScreen.html';
import { EditScreen } from '../EditScreen/EditScreen';

ko.templates['Core/Screens/Templates/AdditionalConsultScreen/ProcessCardScreen/ProcessCardScreen'] = Template;

export class ProcessCardPage extends EditScreen {
   
    constructor(screenModel: ScreenModel) {
        super(screenModel);
    }

    GetTemplateName(): string {
        return 'Core/Screens/Templates/AdditionalConsultScreen/ProcessCardScreen/ProcessCardScreen';
    }
    
    AfterRender(el) {
        if (el){
            this._el = el[0];
        }
        let isModalMode: boolean = !this.TargetId || false;
        let isParentControlExist: boolean = true;

        this.SetScreenStyle(isModalMode, isParentControlExist);

        if (this._dataModel) {
            this.SetFocusOnFirstControl();
        }
    }
}