import {SignInModel} from 'Auth/Models/SignInModel';
import { PasswordExpiredModel } from "Auth/UI/PasswordExpiredForm/Model/PasswordExpiredModel";
import { serialize } from '../../libs/cerialize';

export class SavePasswordLessCredentialsDto {
	DatabaseName: string;
	Login: string;
	Password: string;
	AuthenticatorAttestation: string;

	__RequestVerificationToken: string;

	MapFrom(signInModel: SignInModel) {
		for (let property in signInModel) {
			if (signInModel.hasOwnProperty(property)) {
				this[property] = signInModel[property];
			}
		}

		return this;
	}

	static FillFromFormData(authenticatorAttestation: string) {
		let model = new SavePasswordLessCredentialsDto();

		model.AuthenticatorAttestation = authenticatorAttestation;
		return model;
	}
}