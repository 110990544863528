import * as ko from 'knockout';
import { BlockUI } from 'Core/Common/BlockUi';

import AboutPageTemplate from 'Core/ProfilePage/AboutPage/Templates/AboutPage.html';
ko.templates['Core/ProfilePage/AboutPage/Templates/AboutPage'] = AboutPageTemplate;

export class AboutPage {
	private _isReady: KnockoutObservable<boolean>;

	constructor() {
		this._isReady = ko.observable(true);
	}
	GetTemplateName() {
		return 'Core/ProfilePage/AboutPage/Templates/AboutPage';
	}

	RenderByTargetId(target: string): void {
		ko.cleanNode(document.getElementById(target));
		ko.applyBindings(this, document.getElementById(target));
	}

	AfterRender(el: Array<HTMLElement>) {

	}
}