import * as ko from "knockout"
import 'jquery';
import {ResizeObserver} from 'Core/Common/ResizeObserver';
const ResizeService = new ResizeObserver();

export class ScreenHeightExtention {
	static _preventResize: boolean = false;

	static Init() {
		ko.bindingHandlers.screenHeight = {
			update: (element, valueAccessor) => {
				const ViewBody = $('#viewBody');
				const FourthColumn = ViewBody.find('.fourth-column');
				const RightColumn = ViewBody.find('.rightColumn');

				let listener;
				this.ResizeContainer.call(this, element, ViewBody, FourthColumn, RightColumn);
				listener = this.ResizeContainer.bind(this, element, ViewBody, FourthColumn, RightColumn);

				const unbindResize = ResizeService.SubscribeHeight(this.OnResize.bind(this, element, ViewBody, FourthColumn, RightColumn), RightColumn[0]); // subscribe on resize
				ko.utils.domNodeDisposal.addDisposeCallback(RightColumn[0], () => {
					unbindResize();
					window.removeEventListener("resize", listener, false);
				});
				window.addEventListener('resize', listener, false);
			}
		};
	}

	static OnResize = (element, viewBody, fourthColumn, rightColumn) => {
		ScreenHeightExtention.ResizeContainer(element, viewBody, fourthColumn, rightColumn);
	};

	static ResizeContainer = (element, viewBody, fourthColumn, rightColumn) => {
		let paddingElement: number = 40;
		let pageElements: number = 0;
		let screenHeight = window.innerHeight;
		let actionBarSubForm = viewBody.find('.actionBarSubForm');
		pageElements = actionBarSubForm.outerHeight(true);

		let fixedHeaderHeight: number = 0;
		let calculate: number = 0;

		let fourthColumnHeight = fourthColumn.innerHeight()
		let rightColumnHeight = rightColumn.innerHeight()

		if (window.innerWidth > 991) {
			const headerElement: HTMLDivElement = document.querySelector("body > .page-header");
			fixedHeaderHeight = headerElement ? headerElement.offsetHeight : 0;
		}
		calculate = Math.abs(fixedHeaderHeight + pageElements + paddingElement);
		let expectedHeight = screenHeight - calculate;

		if ( expectedHeight > 400 ) {
			$(element).css({
				'max-height': (fourthColumnHeight < rightColumnHeight) ? rightColumnHeight : expectedHeight,
				'position': 'sticky',
				'top': fixedHeaderHeight + 20
			});
		} else {
			$(element).css({
				'max-height': 540,
				'position': 'static'
			});
		}
	}
}