export class RecordKeyModel {
	FieldId: number;
	RecordId: number;
}

export class MailAttachmentModel {
	constructor() {
		this.RecordKeys = [];
	}

	public DocumentId: number;
	public Name: string;

	public DocumentFieldId: number;
	public RecordKeys: Array<RecordKeyModel>
}