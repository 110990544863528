import * as ko from 'knockout';

import {Event} from "Core/Common/Event";
import {LanguageModel} from "Core/Components/Translation/Models/LanguageModel";

export class TranslationItem extends Event {
	Value: KnockoutObservable<string>;
	IsFocused: KnockoutObservable<boolean>;

	constructor(public Language: LanguageModel) {
		super();
		this.Value = ko.observable(null);
		this.IsFocused = ko.observable(false);

		this.Value.subscribe(value => this.Trigger("TranslationChanged", this));
	}

	SetTranslation(value: string) {
		this.Value(value);
	}
}