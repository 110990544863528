import * as ko from "knockout"
import 'jquery';

export class ToggleExtention {
	static Init() {
		ko.bindingHandlers.toggle = {
			init: (element, valueAccessor, allBindings, viewModel, bindingContext) => { },

			update(element, valueAccessor) {
				var options = valueAccessor();
				if (options.show) {
					if (options.show() === true) {
						$(element).toggle("slow");
					} else {
						$(element).toggle(options.show());
					}
				}
			}
		};
	}
}