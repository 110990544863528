import * as ko from 'knockout';
import * as _ from 'underscore';

import { FieldListMenu } from 'QueryBuilder/FieldListMenu/FieldListMenu';
import { FieldListItem } from 'QueryBuilder/FieldListMenu/FieldListItem';
import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { JoinTypeImages } from 'QueryBuilder/QueryEntityJoin/QueryLinkEntity/JoinTypesImages';
import { UseMainImages } from 'QueryBuilder/QueryEntityJoin/QueryLinkEntity/UseMainImages';
import { JoinTypes, UseMainCondition, LinkDirectionCondition } from 'QueryBuilder/Enums';
import { Event } from 'Core/Common/Event';
import { JoinOptionsMenu } from 'QueryBuilder/QueryEntityJoin/JoinOptionsMenu/JoinOptionsMenu';
import { EVENTS as OPTION_MENU_EVENTS } from 'QueryBuilder/QueryEntityJoin/JoinOptionsMenu/Events';
import { EVENTS } from 'QueryBuilder/QueryEntityJoin/QueryLinkEntity/Events';
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";
import { EntityRelationshipsMetadataModel } from 'Core/Controls/Grid/Models/GridDataModel/Metadata/EntityRelationshipsMetadataModel';
import { IQueryLinkEntityParams } from 'QueryBuilder/QueryEntityJoin/QueryLinkEntity/IQueryLinkEntityParams';
import { IObjectIndex } from 'QueryBuilder/IObjectIndex';
import {EVENTS as QUERY_BUILDER_EVENTS} from 'QueryBuilder/Events';

import QueryLinkEntityTemplate from 'QueryBuilder/QueryEntityJoin/QueryLinkEntity/Templates/QueryLinkEntity.html';
import { EntityListMenu } from "../../EntityListMenu/EntityListMenu";
import { EntityListItem } from "../../EntityListMenu/EntityListItem";
import { FIELD_TYPES } from "../../../Core/Constant";
import { ISelectedEntity, QueryEntity } from "../../QueryEntity/QueryEntity";

export class QueryLinkEntity extends Event {
	private _fieldListMenu: FieldListMenu;
	private _entityListMenu: EntityListMenu;
	private _joinTypeImage: KnockoutObservable<string>;
	private _useMainImage: KnockoutObservable<string>;
	private _joinOptionsMenu: JoinOptionsMenu;
	private _labels = LABELS;
	private _model: QueryEntityModel;
	private _backBackgroundColor: string;
	private _joinType: JoinTypes;
	private _useMain: UseMainCondition;
	private _linkDirection: LinkDirectionCondition;
	private _entitiesRelationships: Array<EntityRelationshipsMetadataModel>;
	private _enabledColumnTypes: Array<string>;
	private _objectIndexes: Array<IObjectIndex>;
	private _selectedEntitiesCount: KnockoutObservable<number>;
	private _referenceEntityListMenus: KnockoutObservable<Array<EntityListMenu>>;
	private _parent: QueryEntityModel;

	constructor (params: IQueryLinkEntityParams) {
		super();
		this._selectedEntitiesCount = ko.observable(0);
		this._model = params.Model;
		this._parent = params.Parent;
		this._backBackgroundColor = params.BackBackgroundColor;
		this._joinType = params.JoinType;
		this._useMain = params.UseMain;
		this._linkDirection = params.LinkDirection;
		this._entitiesRelationships = params.EntitiesRelationships;
		this._enabledColumnTypes = params.EnabledColumnTypes;
		this._objectIndexes = params.ObjectIndexes;
		this._referenceEntityListMenus = ko.observableArray();

		this.AddEvent(EVENTS.JOIN_TYPE_CHANGED);
		this.AddEvent(EVENTS.USE_MAIN_CHANGED);
		this.AddEvent(EVENTS.LINK_DIRECTION_CHANGED);
		this.AddEvent(EVENTS.CLICK);
		this.AddEvent(EVENTS.MOUSE_OVER);
		this.AddEvent(QUERY_BUILDER_EVENTS.COLUMN_SELECTED);
		this.AddEvent(QUERY_BUILDER_EVENTS.LOOKUP_ENTITY_SELECTED);
		this.AddEvent(QUERY_BUILDER_EVENTS.LOOKUP_ENTITY_UNSELECTED);
		this.AddEvent(QUERY_BUILDER_EVENTS.REFERENCE_ENTITY_SELECTED);
		this.AddEvent(QUERY_BUILDER_EVENTS.REFERENCE_ENTITY_UNSELECTED);

		this._joinTypeImage = ko.observable(JoinTypeImages[this._joinType]);
		this._useMainImage = ko.observable(UseMainImages[this._useMain]);
		this.Init();

		this._joinOptionsMenu = new JoinOptionsMenu(this._joinType, this._useMain, this.EnableUseMain, this._linkDirection, this.EnableLinkDirection);

		this._joinOptionsMenu.On(OPTION_MENU_EVENTS.JOIN_TYPE_CHANGED, this, (eventArgs: any) => {
			this._joinTypeImage(JoinTypeImages[eventArgs.data.JoinType]);
			this.Trigger(EVENTS.JOIN_TYPE_CHANGED, { JoinType: eventArgs.data.JoinType });
		});

		this._joinOptionsMenu.On(OPTION_MENU_EVENTS.USE_MAIN_CHANGED, this, (eventArgs: any) => {
			this._useMainImage(UseMainImages[eventArgs.data.UseMain]);
			this.Trigger(EVENTS.USE_MAIN_CHANGED, { UseMain: eventArgs.data.UseMain });
		});

		this._joinOptionsMenu.On(OPTION_MENU_EVENTS.LINK_DIRECTION_CHANGED, this, (eventArgs: any) => {
			this.Trigger(EVENTS.LINK_DIRECTION_CHANGED, { LinkDirection: eventArgs.data.LinkDirection });
		});

		this.InitFieldListMenu(this._enabledColumnTypes);
		this.InitEntityListMenu();
		this.InitReferenceMenu();
	}

	SetSelectedEntities(selectedEntities: Array<ISelectedEntity>) {
        let entities = _.filter(selectedEntities, (item) => !item.LookupFieldId && !item.ReferenceFieldId);
        _.each(entities, selectedEntity => {
            this._entityListMenu.SelectEntityItemById(selectedEntity.Id);
        });

        let lookupEntities = _.filter(selectedEntities, (item) => !!item.LookupFieldId && !item.ReferenceFieldId);
        _.each(lookupEntities, selectedEntity => {
            this._entityListMenu.SelectLookupItemById(selectedEntity.Id, selectedEntity.LookupFieldId);
        });

        let referenceEntities = _.filter(selectedEntities, (item) => !item.LookupFieldId && !!item.ReferenceFieldId);
        _.each(this._referenceEntityListMenus(), (referenceMenu) => {
            _.each(referenceEntities, selectedEntity => {
                referenceMenu.SelectReferenceItemById(selectedEntity.Id, selectedEntity.ReferenceFieldId);
            });
        });
		
		this.RemovedDeletedReferencesMenus();
        this._selectedEntitiesCount(this._entityListMenu.SelectedItemsCount);
	}

	private RemovedDeletedReferenceMenu(referenceMenu: EntityListMenu) {
		if(!!referenceMenu && referenceMenu.ReferenceField.LifestatusName === 'Delete' && referenceMenu.SelectedItemsCount === 0){
			this._referenceEntityListMenus.splice(this._referenceEntityListMenus().indexOf(referenceMenu), 1);
		}
	}

	private RemovedDeletedReferencesMenus() {
		let makerFields = _.filter(this._model.Metadata.Fields, (item) => {
			return item.Type === FIELD_TYPES.Reference});
		_.each((makerFields), (referenceField)=>{
			let referenceMenu = _.find(this._referenceEntityListMenus(), item => item.ReferenceFieldId === referenceField.Id);

			if(!!referenceMenu && referenceField.LifestatusName === 'Delete' && referenceMenu.SelectedItemsCount === 0){
				this._referenceEntityListMenus.splice(this._referenceEntityListMenus().indexOf(referenceMenu), 1);
			}
		});
	}

	private InitEntityListMenu() {		
		this._entityListMenu = new EntityListMenu(false, null);
		var lookupFields =  _.filter(this._model.Metadata.Fields, (item)=> { return item.Type === FIELD_TYPES.Lookup});
		let items = [];
		_.each(lookupFields, (lookupField) => {

			if(lookupField.LookupTable){
				var entityListItem = new EntityListItem(lookupField.LookupTable, false, true, lookupField);
				items.push(entityListItem);
			}
		});
		this._entityListMenu.AddItems(items);

		this._entityListMenu.On(QUERY_BUILDER_EVENTS.LOOKUP_ENTITY_SELECTED, this, (eventArgs: any) => {
			this._selectedEntitiesCount(this._selectedEntitiesCount() + 1);
			this.Trigger(QUERY_BUILDER_EVENTS.LOOKUP_ENTITY_SELECTED, eventArgs.data);
		});
		this._entityListMenu.On(QUERY_BUILDER_EVENTS.LOOKUP_ENTITY_UNSELECTED, this, (eventArgs: any) => {
			this._selectedEntitiesCount(this._selectedEntitiesCount() -1);
			this.Trigger(QUERY_BUILDER_EVENTS.LOOKUP_ENTITY_UNSELECTED, eventArgs.data);
		});
	}

	private InitReferenceMenu() {
		let relationship = _.first(this._entitiesRelationships);
		let makerFields = _.filter(this._model.Metadata.Fields, (item) => {
			return item.Type === FIELD_TYPES.Reference});
		_.each((makerFields), (referenceField)=>{
			let items = [];
			
			let referenceMenu = new EntityListMenu(false, referenceField);

			referenceMenu.On(QUERY_BUILDER_EVENTS.REFERENCE_ENTITY_SELECTED, this, (eventArgs: any) => {
				this.Trigger(QUERY_BUILDER_EVENTS.REFERENCE_ENTITY_SELECTED, eventArgs.data);
			});

			referenceMenu.On(QUERY_BUILDER_EVENTS.REFERENCE_ENTITY_UNSELECTED, this, (eventArgs: any) => {
				this.Trigger(QUERY_BUILDER_EVENTS.REFERENCE_ENTITY_UNSELECTED, eventArgs.data);
				this.RemovedDeletedReferenceMenu(referenceMenu);
			});

			_.each(relationship.ReferenceEntities, (item)=>{
				let entityListItem = new EntityListItem(item, false, true, null, referenceField);
				items.push(entityListItem);
			});

			referenceMenu.AddItems(items);

			this._referenceEntityListMenus.push(referenceMenu);
		});
	}

	private Init() {

		var objIndex = _.find(this._objectIndexes, (indx) => { return indx.Id === this._model.Metadata.Id });

		if (objIndex) {
			if (this._model.Index === 0 || this._model.Index === null) {
				objIndex.Index = objIndex.Index + 1;
				this._model.Index = objIndex.Index;
			} else {
				objIndex.Index = this._model.Index;
			}
		}
		else {
			var objIndex: IObjectIndex = {
				Id: this._model.Metadata.Id,
				Index: 1
			};
			this._objectIndexes.push(objIndex);
			this._model.Index = objIndex.Index;
		}

		var metadata = _.find(this._entitiesRelationships, item => item.EntityMetadata.Id === this._model.Metadata.Id);

		if (metadata) {
			this._model.Metadata = metadata.EntityMetadata;
		}

		_.each(this._model.Metadata.Fields, (fieldMetadata) => {
			var selectedQueryColumnModel = _.find(this._model.Columns, queryColumn => { return queryColumn.Metadata.Id === fieldMetadata.Id });
			if (selectedQueryColumnModel) {
				selectedQueryColumnModel.Metadata = fieldMetadata;
			}
		});
	}

	GetTemplateHtml() {
		return QueryLinkEntityTemplate;
	}

	get EnableUseMain(): boolean {
		var mainField = _.find(this._model.Metadata.Fields, (field) => { return field.Name === 'MAIN' });
		return mainField != null && this._model.Metadata.Name.startsWith(`${this._parent.Metadata.ShortName}_`);
	}

	get EnableLinkDirection(): boolean {
		let entities = this._model.Metadata.Name.replace('SYS_', '').split('_');
		return entities[0] === entities[1];
	}

	Render(target: Element) {
		ko.cleanNode(target);
		ko.applyBindings(this, target);
	}

	AfterRender() {}

	get SelectedEntitiesCount(): KnockoutObservable<number>{
		return this._selectedEntitiesCount;
	}

	private InitFieldListMenu(enabledColumnTypes: Array<string>) {
		this._fieldListMenu = new FieldListMenu(this._model, false);
		_.each(this._model.Metadata.Fields, field => {
			var column = _.find(this._model.Columns, queryColumn => { return queryColumn.Metadata.Id === field.Id });
			if (enabledColumnTypes && enabledColumnTypes.length > 0) {
				if (enabledColumnTypes.indexOf(field.Type) >= 0) {
					this._fieldListMenu.AddItem(new FieldListItem(field, column != null));
				}
			} else {
				this._fieldListMenu.AddItem(new FieldListItem(field, column != null));
			}
		});

		this._fieldListMenu.On(QUERY_BUILDER_EVENTS.COLUMN_SELECTED, this, (eventArgs) =>{
			this.Trigger(QUERY_BUILDER_EVENTS.COLUMN_SELECTED, {Id: eventArgs.data.Id});
		});
	}

	get BackgroundColor() {
		return this._backBackgroundColor;
	}

	get FieldListMenu(): FieldListMenu {
		return this._fieldListMenu;
	}

	get JoinOptionsMenu(): JoinOptionsMenu {
		return this._joinOptionsMenu;
	}

	Click() {
		this.Trigger(EVENTS.CLICK);
	}

	MouseOver() {
		this.Trigger(EVENTS.MOUSE_OVER);
	}

	get EntityListMenu(): EntityListMenu{
		return this._entityListMenu;
	}

	get ReferenceEntityListMenus(): KnockoutObservable<Array<EntityListMenu>>{
		return this._referenceEntityListMenus;
	}
}