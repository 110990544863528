import {deserialize, deserializeAs, GenericDeserialize} from 'libs/cerialize';

import {SpreadsheetFieldDescriptorDto} from './SpreadsheetFieldDescriptorDto';
import {SpreadsheetCellPositionDto} from './SpreadsheetCellPositionDto';
import {SpreadsheetDimensionDescriptorDto} from './SpreadsheetDimensionDescriptorDto';

export class SpreadsheetDescriptorDto {
    @deserializeAs(SpreadsheetFieldDescriptorDto)
    ValueField: SpreadsheetFieldDescriptorDto;

    @deserializeAs(SpreadsheetFieldDescriptorDto)
    PageField: SpreadsheetFieldDescriptorDto;

    @deserializeAs(SpreadsheetCellPositionDto)
    Origin: SpreadsheetCellPositionDto;

    @deserializeAs(SpreadsheetDimensionDescriptorDto)
    Dimensions: SpreadsheetDimensionDescriptorDto[];

    @deserialize
    IsView: boolean;

    constructor() {
        this.Dimensions = [];
    }

    static Deserialize(jsonObj): SpreadsheetDescriptorDto {
        return GenericDeserialize<SpreadsheetDescriptorDto>(jsonObj, SpreadsheetDescriptorDto);
    }
}