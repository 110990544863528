import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize} from 'libs/cerialize';
import {QueryScreenModel} from 'QueryBuilder/Models/QueryScreenModel';
import {CardScreenModel} from 'QueryBuilder/Models/CardScreenModel';
import { RelationTypeValueModel } from 'QueryBuilder/Models/RelationTypeValueModel';

export interface IInitTableViewQueryRequestModel {
	SubjectEntityId: number;
	ControlId: number;
	QueryTypeName: string;
};

export interface IGetQueryScreenRequestModel {
	EntityId: number;
}

export interface IGetCardScreenRequestModel {
	EntityId: number;
}

export interface IGetRelationTypeDto {
	LinkTableId: number;
}

export class QueryBuilderStore {
	static GetQueryScreens(params: IGetQueryScreenRequestModel): P.Promise<Array<QueryScreenModel>> {
		var deferredResult = P.defer<Array<QueryScreenModel>>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.QUERY_BUILDER.GET_QUERY_SCREENS,
				dataType: 'json',
				type: 'GET'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(<Array<QueryScreenModel>>Deserialize(data.ResultObject, QueryScreenModel));
			});

		return deferredResult.promise();
	}

	static GetCardScreens(params: IGetCardScreenRequestModel): P.Promise<Array<CardScreenModel>> {
		var deferredResult = P.defer<Array<CardScreenModel>>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.QUERY_BUILDER.GET_CARD_SCREENS,
				dataType: 'json',
				type: 'GET'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(<Array<CardScreenModel>>Deserialize(data, CardScreenModel));
			}).fail(data => { 				
				let jsonResult = JSON.parse(data.message);
				deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			 });;

		return deferredResult.promise();
	}


	static GetRelationTypes(params: IGetRelationTypeDto): P.Promise<Array<RelationTypeValueModel>> {
		let deferredResult = P.defer<Array<RelationTypeValueModel>>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/VisualQueryBuilderApi/GetRelationTypes',
				type: 'GET'
			},
			params: params
		};
		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(<Array<RelationTypeValueModel>>Deserialize(data, RelationTypeValueModel));
			}).fail(data => { 
				let jsonResult = JSON.parse(data.message);
				deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			 });;

		return deferredResult.promise();
	}
} 