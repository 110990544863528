import * as ko from "knockout"

export class AllowBindingsExtention {
	static Init() {
		ko.bindingHandlers.allowBindings = {
			init: function (elem, valueAccessor) {
				var shouldAllowBindings = ko.unwrap(valueAccessor());
				return { controlsDescendantBindings: !shouldAllowBindings };
			}
		};
	}
}