import * as ko from 'knockout';

import {BlockUI} from "Core/Common/BlockUi";
import {Notifier} from "Core/Common/Notifier";

import {NOTIFICATIONS} from "Core/Components/Translation/Locales";

import {AttachmentModel} from "Core/Controls/Image/Models/AttachmentModel";

import {GuestPageStore} from "../Store/GuestPageStore";

import Template from "Core/CyberConfigManager/Templates/GuestPage.html";

ko.templates['Core/CyberConfigManager/Templates/GuestPage'] = Template;

export class GuestPage {
    private _store: GuestPageStore;
    private _image: KnockoutObservable<string>;
    private _showRemove: KnockoutObservable<boolean>;

	private _defaultImage: string;
	private _imageUrl: string;
	private _maxImageSize: number;

    constructor() {
        this._store = new GuestPageStore();
        this._image = ko.observable(null);
        this._showRemove = ko.observable(false);

		this._defaultImage = '../../../../../img/Custom/logo_upload.png';
		this._imageUrl = this.GetImageUrl();
		this._maxImageSize = 10;
    }

    GetTemplateName() {
        return 'Core/CyberConfigManager/Templates/GuestPage';
	}

    GetImageUrl() {
		const subDomain = window.location.hostname.split('.')[0];
		var timestamp = +new Date;
	    return `/Static/Images/${subDomain}/Guest.jpg?${timestamp}`;
    }

    AfterRender() {
        this.LoadImage();
    }

    AfterUpload(file) {
        BlockUI.Block();

        const attachmentModel = new AttachmentModel();
        attachmentModel.FileName = file.name;

        const reader = new FileReader();
		reader.onloadend = () => {
			if (file.status === 'error') {
                const message = NOTIFICATIONS.FILE_SIZE_IS_TOO_BIG.replace('{MaxFileSize}', this._maxImageSize.toString());
                new Notifier().Warning(message);
                BlockUI.Unblock();
                return;
			}

			if (file.status === 'removed') {
				BlockUI.Unblock();
				return;
			}

            attachmentModel.Base64Data = (reader.result as string).split(',')[1];

            this._store.UploadImage(attachmentModel)
                .always(() => {
                    BlockUI.Unblock();
                })
                .then(() => {
                    new Notifier().Success('Image Updated');
					this._image(this.GetImageUrl());
                    this._showRemove(true);
                })
                .fail(error => new Notifier().Failed(error.message));
        };

        reader.readAsDataURL(file);
    }

    ImageDelete() {
        this._store.RemoveImage()
            .always(() => BlockUI.Unblock())
            .then(() => {
                this._image(this._defaultImage);
                this._showRemove(false);
            });
    }

	private LoadImage() {
		var img = new Image();
		img.onload = () => {
			this._image(this._imageUrl);
			this._showRemove(true);
		};
		img.onerror = () => this._image(this._defaultImage);
		img.src = this._imageUrl;
	}
}