import { dia as Dia, shapes, V, g } from 'libs/rappid/build/rappid';
import * as joint from 'libs/rappid/build/rappid';
import * as _ from 'underscore';

import 'Core/Controls/CanvasDesigner/Shapes/Models/Trigger';

var template = V('<g class="rotatable"><g class="scalable"><rect/></g><text class="shape-label"/><text class="toggle-icon fa"/><title/></g>');

declare module 'libs/rappid/build/rappid' {
	namespace shapes {
		namespace cyberThing {
			class TriggerView extends Dia.ElementView { }
		}
	}
}

var padding = { top: 25, left: 10, right: 20, bottom: 10 };

joint.shapes.cyberThing.TriggerView = Dia.ElementView.extend({

	initialize: function () {
		joint.dia.ElementView.prototype.initialize.apply(this, arguments);
		this.model.position(0, 0, { parentRelative: true });
		this.model.get('attrs')['.shape-label']['ref-y'] = 0;
		this.model.get('attrs')['.shape-label'].refY2 = 15;
	},

	renderMarkup: function () {
		this.initIcons();
		this.vel.append(template.clone());
	},

	initIcons: function(){
		let icon = '\uf062';

		if(this.model.attributes.attrs.IsCollapsed){
			icon = '\uf063';
		}

		this.model.attr({
			'.toggle-icon': {
				"ref-y": 0,
				refY2: 10,
				"ref-x": 0,
				refX2: 10,
				text: icon
			}
		});

	}
});