import {deserialize, deserializeAs} from 'libs/cerialize';

export class MissingFieldsDto {
    constructor(){
        this.NonrequiredFields = [];
        this.RequiredFields = [];
    }

    @deserialize
    NonrequiredFields: Array<string>;

    @deserialize
    RequiredFields: Array<string>;
}