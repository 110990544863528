import * as ko from "knockout";

export interface IRecordPersonalizationParams {
	PossibleToPersonalize: boolean;
	IsPersonal: boolean;
}

export class RecordPersonalizationModel {
	PossibleToPersonalize: KnockoutObservable<boolean>;
	AlreadyPersonalized: KnockoutObservable<boolean>;
	PersonalizationSelected: KnockoutObservable<boolean>;

	constructor(params?: IRecordPersonalizationParams) {
		let options = params || this.GetDefaultParams();
		this.PossibleToPersonalize = ko.observable(options.PossibleToPersonalize);
		this.AlreadyPersonalized = ko.observable(options.IsPersonal);
		this.PersonalizationSelected = ko.observable(options.IsPersonal);
	}

	private GetDefaultParams(): IRecordPersonalizationParams {
		return {
			PossibleToPersonalize: false,
			IsPersonal: false
		}
	}
}