import * as _ from 'underscore';
import {ILock} from "./ILock";

export class LockChain {
    private _locks: ILock[];

    constructor() {
        this._locks = [];
    }

    get Count() {
        return this._locks.length;
    }

    FindLock(lock: ILock) {
        const lockIndex = this.FindLockIndex(lock);

        if (lockIndex > -1) {
            return this._locks[lockIndex];
        }

        return null;
    }

    FindLocks(locks: ILock[]) {
        return _.filter(this._locks, lock => _.any(locks, l => l.TableId === lock.TableId && l.RecordId === lock.RecordId));
    }

    HasLock(lock: ILock) {
        return !!this.FindLock(lock);
    }

    AddLock(lock: ILock) {
        this._locks.push(lock);
    }

    RemoveLock(lock: ILock) {
        const lockIndex = this.FindLockIndex(lock);

        if (lockIndex > -1) {
            this._locks.splice(lockIndex, 1);
        }
    }

    RemoveAllLocks() {
        this._locks = [];
    }

    TakeRootLock() {
        return this._locks[0] || null;
    }

    TakeAllLocks() {
        return this._locks.slice();
    }

    HasAnyLock() {
        return this._locks.length > 0;
    }

    private FindLockIndex(lock: ILock) {
        return _.findIndex(this._locks, l => l.TableId === lock.TableId && l.RecordId === lock.RecordId);
    }
}
