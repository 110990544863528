import * as ko from "knockout";
import * as _ from "underscore";

import {Event} from "Core/Common/Event";
import {Modal} from "Core/Common/Modal";

import {Notifier} from "Core/Common/Notifier";

import {ScreenTypes} from "Core/Common/Enums/ScreenTypes";

import {LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";

import {IControl} from "Core/Controls/IControl";
import {ControlModel} from "Core/Controls/BaseControl/Models/ControlModel";

import {DesignScreen} from "Core/Screens/DesignScreen/DesignScreen";

import {TranslationModel} from "Core/Controls/BaseControl/Models/TranslationModel";
import {LanguageModel} from "Core/Controls/BaseControl/Models/LanguageModel";

import {ControlEditorModel} from "../Models/ControlEditorModel";
import {TranslationManager} from "Core/Components/Translation/TranslationManager";

import Template from 'Core/Screens/DesignScreen/ControlEditor/Templates/BaseControlEditor.html';
import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";
import {MobileChecker} from 'Core/Common/MobileChecker';

ko.templates['Core/Screens/DesignScreen/ControlEditor/Templates/BaseControlEditor'] = Template;

export const EVENTS = {
    CONTROL_SAVED: 'CONTROL_SAVED'
};

export const CONTROL_DEFAULT_NAME: string = 'Default';

export class BaseControlEditor extends Event {
    private _modal: Modal;

    protected Control: IControl;
    protected SubjectEntityId: number;

    protected EditControl: IControl;
    protected EditControlModel: ControlModel;

    protected NameTranslations: KnockoutObservableArray<TranslationModel>;
    protected DescriptionTranslations: KnockoutObservableArray<TranslationModel>;
    protected SelectedName: KnockoutObservable<TranslationModel>;
    protected SelectedDescription: KnockoutObservable<TranslationModel>;

    protected NameFocused: KnockoutObservable<boolean>;
    protected NameTranslationsOpened: KnockoutObservable<boolean>;
    protected DescriptionTranslationsOpened: KnockoutObservable<boolean>;
    protected UseFieldName: KnockoutObservable<boolean>;

    protected Description: KnockoutObservable<string>;

    protected IsValid: KnockoutObservable<boolean>;
    private ValidationMsg: KnockoutObservable<string>;

    protected Labels = LABELS;

    constructor(control: IControl) {
        super();

        this._modal = new Modal({
            closeOnEsc: false,
            addClass: 'jBox-padding-15px',
        });

        this.NameFocused = ko.observable(true);
        this.NameTranslationsOpened = ko.observable(false);
        this.DescriptionTranslationsOpened = ko.observable(false);

        this.SelectedName = ko.observable(null);
        this.SelectedDescription = ko.observable(null);
        this.Description = ko.observable(null);

        this.UseFieldName = ko.observable(false);

        this.IsValid = ko.observable(true);
        this.ValidationMsg = ko.observable(this.Labels.THIS_FIELD_IS_REQUIRED);

        this.InitEditControlData(control);
    }

    get Id() {
        return this.EditControlModel.Id;
    }

    get TypeName() {
        return this.EditControlModel.TypeName;
    }

    get TypeTranslatedName() {
        return this.EditControlModel.TypeTranslatedName ? this.EditControlModel.TypeTranslatedName : this.TypeName;
    }

    get GeneralProperties() {
        return this.EditControl.GeneralProperties;
    }

    get DefaultTranslation() {
        return _.find(this.NameTranslations, (item) => item.Language.K_Language === -1);
    }


    Show() {
        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
    }

    ShowNameTranslations() {
        this.NameTranslationsOpened(true);
    }

    ToggleNameTranslations() {
        if (this.NameTranslationsOpened()) {
            this.HideNameTranslations();
        } else {
            this.ShowNameTranslations();
            this.SetFocusItem(this.NameTranslations());
        }
    }

    ToggleDescriptionTranslations() {
        if (this.DescriptionTranslationsOpened()) {
            this.DescriptionTranslationsOpened(false);
        } else {
            this.DescriptionTranslationsOpened(true);
            this.SetFocusItem(this.DescriptionTranslations());
        }
    }

    // Add to focus the first blank item Name and item Description
    SetFocusItem(ArrTranslate: Array<TranslationModel>) {
        let focusEmptyElement = _.find(ArrTranslate, item => {
            if (item.Translation === undefined) {
                return item.Translation === undefined;
            } else if (item.Translation === null) {
                return item.Translation === null;
            } else if (item.Translation === '') {
                return item.Translation === '';
            }
        });
        let isMobile = MobileChecker.IsMobile();
        focusEmptyElement && focusEmptyElement.IsFocusedItem(!isMobile);
    }

    HideNameTranslations() {
        this.NameTranslationsOpened(false);
    }

    SelectNameTranslation(translationModel: TranslationModel) {
        this.NameFocused(true);
        this.SelectedName(translationModel);
        this.HideNameTranslations();
    }

    SelectDescriptionTranslation(translationModel: TranslationModel) {
        this.SelectedDescription(translationModel);
        this.DescriptionTranslationsOpened(false);
    }

    UpdateTranslationList() {
        let languages = this.NameTranslations().slice();
        this.NameTranslations([]);
        this.NameTranslations(languages);
    }

    GetFieldMaxLength(): number {
        if(!this.EditControlModel) {
            return;
        }
        const maxLength = this.EditControlModel.NameMaxSize;

        return maxLength > 0 ? maxLength : -1;
    }

    UpdateDescriptionTranslationList() {
        let languages = this.DescriptionTranslations().slice();
        this.DescriptionTranslations([]);
        this.DescriptionTranslations(languages);
    }

    AfterRender(el: HTMLElement) {
        this._modal.Show();
    }

    Validate(): string {
        if (!this.SelectedName()) {
            this.IsValid(false);
            return 'Please, provide control name';
        }

        if (!this.EditControl.IsPropertiesValid()) {
            this.IsValid(false);
            const propertiesErrors = this.EditControl.GetPropertiesErrors();

            const errorMessage = _.find(propertiesErrors, error => !_.isEmpty(error));
            return !_.isEmpty(errorMessage) ? errorMessage : NOTIFICATIONS.PLEASE_SET_ALL_PROPERTIES;
        }

        return null;
    }

    Cancel() {
        this.Close();
    }

    Close() {
        this._modal.Close();
    }

    Save(): void {
        const validationError = this.Validate();
        if (validationError) {
            new Notifier().Warning(validationError);
            return;
        }
        const controlEditorModel = this.GetControlEditorModel();

        this.Trigger(EVENTS.CONTROL_SAVED, {UpdateControlModel: controlEditorModel});

        this.Close();
    }

    ChangeTranslation() {
        let current = _.find(this.NameTranslations(), (item) => {
            return item.Language.K_Language === this.SelectedName().Language.K_Language;
        });
        this.SelectedName(current);
    }

    OnKeyUpUpdateTranslationList(that, event): boolean {
        this.UpdateTranslationList();

        return this.NameValidate(that, event);
    }

    OnKeyUpChangeTranslation(that, event): boolean {
        this.ChangeTranslation();

        return this.NameValidate(that, event);
    }

    NameValidate(that, event): boolean {
        const fieldMaxLength = this.GetFieldMaxLength();
        if(that.Translation && that.Translation.length > 0 && fieldMaxLength !== -1 && that.Translation.length >= fieldMaxLength){
            this.IsValid(false);
            this.ValidationMsg(`${NOTIFICATIONS.MAXIMUM_LENGTH_OF_THIS_FIELD} ${fieldMaxLength}`);
        }else {
            this.IsValid(true);
        }

        return this.IsValid();
    }

    ChangeDescriptionTranslation() {
        let current = _.find(this.DescriptionTranslations(), (item) => {
            return item.Language.K_Language === this.SelectedDescription().Language.K_Language;
        });
        this.SelectedDescription(current);
    }

    GetControlEditorModel() {
        const desktopLanguage = GlobalManager.Instance.GetGlobal(GLOBALS.DESKTOP_LANGUAGE);
        const controlEditorModel = new ControlEditorModel();

        controlEditorModel.K_Control = this.EditControlModel.Id;
        controlEditorModel.Name = _.find(this.EditControlModel.NameTranslations, translationItem => translationItem.IsDefault).Translation;
        controlEditorModel.F_Type = this.EditControlModel.TypeId;
        controlEditorModel.TypeName = this.EditControlModel.TypeName;
        controlEditorModel.TypeTranslatedName = this.EditControlModel.TypeTranslatedName;
        controlEditorModel.ScreenId = this.EditControlModel.ScreenId;

        controlEditorModel.RecordLifeStatusId = this.EditControlModel.RecordLifeStatusId;
        controlEditorModel.DestinationTypeId = this.EditControlModel.DestinationTypeId;
        controlEditorModel.Description = _.find(this.EditControlModel.DescriptionTranslations, translationItem => translationItem.IsDefault).Translation;

        const generalProperties = this.EditControl.GeneralProperties;
        controlEditorModel.Properties = generalProperties ? generalProperties.Serialize() : null;

        this.EditControlModel.NameTranslations
            .filter(translationItem => !translationItem.IsDefault)
            .forEach(translationItem => controlEditorModel.NameTranslations.push(translationItem));

        if (this.UseFieldName()) {
            _.each(controlEditorModel.NameTranslations, (translation) => {
                translation.Translation = '';
            })
        } else {
            _.each(controlEditorModel.NameTranslations, (translation) => {
                if (translation.Selected || (desktopLanguage == translation.Language.ShortName)) {
                    translation.Selected = true;
                }
            });
        }

        this.EditControlModel.DescriptionTranslations
            .filter(translationItem => !translationItem.IsDefault)
            .forEach(translationItem => controlEditorModel.DescriptionTranslations.push(translationItem));

        const parentControl = this.EditControl.GetParentControl();

        controlEditorModel.ParentControlId = parentControl ? parentControl.GetControlId() : 0;
        controlEditorModel.HasParentControl = !!parentControl;
        controlEditorModel.SubFormId = parentControl ? 0 : this.EditControl.GetForm().GetFormId();

        return controlEditorModel;
    }

    GetTemplateName(): string {
        return 'Core/Screens/DesignScreen/ControlEditor/Templates/BaseControlEditor';
    }

    protected GetSubjectEntityId() {
        return this.EditControl.GetForm().GetScreen().GetEntityId();
    }

    protected GetSubjectEntityName() {
        return this.EditControl.GetForm().GetScreen().GetEntityName();
    }

    protected GetSubjectTableTypeId() {
        return this.EditControl.GetForm().GetScreen().GetTableTypeId();
    }

    protected InitEditControlData(control: IControl) {
        this.Control = control;
        this.SubjectEntityId = control.GetForm().GetScreen().GetEntityId();

        this.EditControl = this.Control.Clone();

        this.EditControlModel = this.EditControl.GetModel();
        this.EditControlModel.Id = this.Control.GetControlId();

        this.Description(this.EditControlModel.Description);
        this.UseFieldName(this.EditControlModel.UseFieldName);

        this.InitName();
    }

    protected GetDesignScreen() {
        return <DesignScreen>this.EditControl.GetForm().GetScreen();
    }

    protected IsScreenOfType(...screenTypes: ScreenTypes[]) {
        const screenTypeName = this.GetDesignScreen().GetType();
        return _.any(screenTypes, requestedType => requestedType === ScreenTypes[screenTypeName]);
    }

    private InitName() {
        const defaultName = new TranslationModel();
        defaultName.Language = new LanguageModel(-1);
        if (this.UseFieldName()) {
            defaultName.Translation = this.EditControlModel.Name || (this.Control.GetFieldModel() && this.Control.GetFieldModel().Name) || CONTROL_DEFAULT_NAME;
        } else {
            defaultName.Translation = this.EditControlModel.Name;
        }

        const defaultTranslation = new TranslationModel();
        defaultTranslation.Language = new LanguageModel(-1);
        defaultTranslation.Translation = this.EditControlModel.Description;

        this.EditControlModel.NameTranslations.unshift(defaultName);
        this.EditControlModel.DescriptionTranslations.unshift(defaultTranslation);
        this.NameTranslations = ko.observableArray(this.EditControlModel.NameTranslations);
        this.DescriptionTranslations = ko.observableArray(this.EditControlModel.DescriptionTranslations);
        this.SelectedName(this.GetNameTranslation());
        this.SelectedDescription(this.GetDescriptionTranslation());
    }

    private GetNameTranslation() {
        const name = _.find(this.EditControlModel.NameTranslations, language => language.Selected && language.Translation !== '' && language.Translation !== null);
        return name || _.first(this.NameTranslations());
    }

    private GetDescriptionTranslation() {
        const description = _.find(this.EditControlModel.DescriptionTranslations, language => language.Selected && language.Translation !== '' && language.Translation !== null);
        return description || _.first(this.DescriptionTranslations());
    }

}