import {deserializeAs} from 'libs/cerialize';

import {SpreadsheetCellDataDto} from './SpreadsheetCellDataDto';
import {SpreadsheetPageDescriptorDto} from './SpreadsheetPageDescriptorDto';

export class SpreadsheetPageDataDto {
    @deserializeAs(SpreadsheetCellDataDto)
    CellsData: SpreadsheetCellDataDto[];

    @deserializeAs(SpreadsheetPageDescriptorDto)
    Descriptor: SpreadsheetPageDescriptorDto;

    constructor() {
        this.CellsData = [];
    }
}