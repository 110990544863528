import {P} from 'Core/Common/Promise';
import {Request} from 'Core/Common/Request';

import {NOTIFICATIONS} from "Core/Components/Translation/Locales";

import {ProductInfoResponse} from "./Models/ProductInfoResponse";
import {ConfiguredProductResponse} from "./Models/ConfiguredProductResponse";

import {GetActionDependsOnValuesDto} from "Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Stores/Params/GetActionDependsOnValuesDto";
import {ActionDependsOnFieldValueResponse} from "Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Stores/Models/ActionDependsOnFieldValueResponse";

import {CreateConfigurationDto} from "./Params/CreateConfigurationDto";
import {UpdateConfigurationDto} from "./Params/UpdateConfigurationDto";
import {PropertyValueDto} from "./Params/PropertyValueDto";
import {UpdateConfigurationByLevelsDto} from './Params/UpdateConfigurationByLevelsDto';
import {CustomFieldValue} from '../Models/CustomFieldValue';
import {CustomFieldValueDto} from './Params/CustomFieldValueDto';
import {ConversionDropdownResponse} from "./Models/ConversionDropdownResponse";
import {SearchByConversionDto} from 'Core/Components/Controls/ProductConfigurator/Components/ConversionDropdown/Params/SearchByConversionDto';

export class ConfigurationPageStore {
    constructor(private _ordersEntityId: number, private _productsEntityId: number, private _endProductId: number) {
    }


    GetProductInfo(productId: number, orderId: number, level: string): P.Promise<ProductInfoResponse> {
        const deferred = P.defer<ProductInfoResponse>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetProductInfo',
                type: 'GET',
                dataType: 'json'
            },
            params: {
                ProductEntityId: this._productsEntityId,
                OrderEntityId: this._ordersEntityId,
                ProductId: productId,
                OrderId: orderId,
                Level: level
            }
        })
            .then(productInfo => deferred.resolve(productInfo))
            .fail(err => deferred.reject(this.GetResponseError(err, NOTIFICATIONS.ERROR_GETTING_PRODUCT_INFO)));

        return deferred.promise();
    }

    GetExtraProductsInfo(productsId: number[], level: string): P.Promise<ProductInfoResponse[]> {
        const deferred = P.defer<ProductInfoResponse[]>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetExtraProductsInfo',
                type: 'POST',
                dataType: 'json'
            },
            params: {
                OrderEntityId: this._ordersEntityId,
                ProductEntityId: this._productsEntityId,
                EndProductId: this._endProductId,
                ProductsId: productsId,
                Level: level
            }
        })
            .then(groupProducts => deferred.resolve(groupProducts))
            .fail(err => deferred.reject(this.GetResponseError(err, NOTIFICATIONS.ERROR_GETTING_GROUPS_PRODUCTS)));

        return deferred.promise();
    }

    GetConfiguredProduct(dto: { ConfigurationId: number }): P.Promise<ConfiguredProductResponse> {
        const deferred = P.defer<ConfiguredProductResponse>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetConfiguredProduct',
                type: 'GET',
                dataType: 'json'
            }, params: {
                OrderEntityId: this._ordersEntityId,
                ProductEntityId: this._productsEntityId,
                ConfigurationId: dto.ConfigurationId
            }
        })
            .then(configuredProduct => deferred.resolve(configuredProduct))
            .fail(err => deferred.reject(this.GetResponseError(err, NOTIFICATIONS.ERROR_GETTING_CONFIGURED_PRODUCT)));

        return deferred.promise();
    }

    GetConfiguredProductWithLevels(dto: { OrderRecordId: number, ProductRecordId: number, ConfigurationId: number }): P.Promise<ConfiguredProductResponse[]> {
        const deferred = P.defer<ConfiguredProductResponse[]>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetConfiguredProductWithLevels',
                type: 'GET',
                dataType: 'json'
            }, params: {
                OrderEntityId: this._ordersEntityId,
                ProductEntityId: this._productsEntityId,
                ...dto
            }
        })
            .then(configuredProduct => deferred.resolve(configuredProduct))
            .fail(err => deferred.reject(this.GetResponseError(err, NOTIFICATIONS.ERROR_GETTING_CONFIGURED_PRODUCT)));

        return deferred.promise();
    }

    GetConversionDropdowns(dto: {
                                    ProductId: number,
                                    ProductGroupId: number,
                                    ProductGroupName: string,
                                    Level: string,
                                    SelectedConversions?: Array<SearchByConversionDto>
                                }): P.Promise<ConversionDropdownResponse[]> {
        const deferred = P.defer<ConversionDropdownResponse[]>();

        let params = {
            OrderEntityId: this._ordersEntityId,
            ProductEntityId: this._productsEntityId,
            ProductId: dto.ProductId,
            ProductGroupId: dto.ProductGroupId,
            ProductGroupName: dto.ProductGroupName,
            Level: dto.Level,
            SelectedConversions: dto.SelectedConversions
        }

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetConversionDropdowns',
                type: 'POST',
                dataType: 'json'
            },
            params: params
        })
            .then(conversionDropdowns => deferred.resolve(conversionDropdowns))
            .fail(err => deferred.reject(err));

        return deferred.promise();
    }

    GetGroupProducts(dto: {
                                ProductId: number,
                                ProductGroupId: number,
                                ProductGroupName: string,
                                PageNumber: number,
                                RecordsPerPage: number,
                                Level: string,
                                Conversions?: Array<SearchByConversionDto>
                            }): P.Promise<ProductInfoResponse[]> {
        const deferred = P.defer<ProductInfoResponse[]>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetGroupProductsInfo',
                type: 'POST',
                dataType: 'json'
            },
            params: {
                OrderEntityId: this._ordersEntityId,
                ProductEntityId: this._productsEntityId,
                EndProductId: this._endProductId,
                ProductId: dto.ProductId,
                ProductGroupId: dto.ProductGroupId,
                ProductGroupName: dto.ProductGroupName,
                PageNumber: dto.PageNumber,
                RecordsPerPage: dto.RecordsPerPage,
                Level: dto.Level,
                Conversions: dto.Conversions
            }
        })
            .then(groupProducts => deferred.resolve(groupProducts))
            .fail(err => deferred.reject(this.GetResponseError(err, NOTIFICATIONS.ERROR_GETTING_GROUPS_PRODUCTS)));

        return deferred.promise();
    }

    GetInclusiveProducts(dto: { SelectedProductPartsIds: number[], InclusiveProductPartsIds: number[], ExclusiveProductPartsIds: number[], DefaultPartsIds: number[], AlreadyDownloadedAlternatives: number[], GroupId: number, GroupName: string, Level: string }): P.Promise<ProductInfoResponse[]> {
        const deferred = P.defer<ProductInfoResponse[]>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetInexProductParts',
                type: 'POST',
                dataType: 'json'
            },
            params: {
                OrderEntityId: this._ordersEntityId,
                ProductEntityId: this._productsEntityId,
                ProductId: this._endProductId,
                SelectedProductPartsIds: dto.SelectedProductPartsIds,
                InclusiveProductPartsIds: dto.InclusiveProductPartsIds,
                ExclusiveProductPartsIds: dto.ExclusiveProductPartsIds,
                Level: dto.Level,
                GroupId: dto.GroupId,
                GroupName: dto.GroupName
            }

        })
            .then(inclusiveProducts => deferred.resolve(inclusiveProducts))
            .fail(err => deferred.reject(this.GetResponseError(err, "Error getting inclusive products")));

        return deferred.promise();
    }

    GetFactualPrice(productPartId: number, quantity: number, propertyValues: PropertyValueDto[], customFieldValues: CustomFieldValueDto[]): P.Promise<number> {
        const deferred = P.defer<number>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetFactualPrice',
                type: 'POST'
            },
            params: {
                OrderEntityId: this._ordersEntityId,
                ProductEntityId: this._productsEntityId,
                ProductId: this._endProductId,
                Quantity: quantity,
                ProductPartId: productPartId,
                PropertyValues: propertyValues,
                CustomFieldValues: customFieldValues
            }
        })
            .then(factualPrice => deferred.resolve(factualPrice))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error calculating factual price')));

        return deferred.promise();
    }

    GetActionDependsOnValues(dto: GetActionDependsOnValuesDto): P.Promise<ActionDependsOnFieldValueResponse[]> {
        const deferred = P.defer<ActionDependsOnFieldValueResponse[]>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetActionDependsOnValues',
                type: 'POST'
            },
            params: dto
        })
            .then(actionDependsOnFieldValues => deferred.resolve(actionDependsOnFieldValues))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error getting action depends on values')));

        return deferred.promise();
    }

    CreateProductConfiguration(dto: CreateConfigurationDto): P.Promise<void> {
        const deferred = P.defer<void>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/CreateProductConfiguration',
                type: 'POST'
            },
            params: dto
        })
            .then(() => deferred.resolve(null))
            .fail(err => deferred.reject(this.GetResponseError(err, NOTIFICATIONS.ERROR_CREATING_CONFIGURATION)));

        return deferred.promise();
    }

    UpdateProductConfiguration(dto: UpdateConfigurationDto): P.Promise<void> {
        const deferred = P.defer<void>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/UpdateProductConfiguration',
                type: 'POST'
            },
            params: dto
        })
            .then(() => deferred.resolve(null))
            .fail(err => deferred.reject(this.GetResponseError(err, NOTIFICATIONS.ERROR_UPDATING_CONFIGURATION)));

        return deferred.promise();
    }

    UpdateProductConfigurationByLevels(dto: UpdateConfigurationByLevelsDto): P.Promise<void> {
        const deferred = P.defer<void>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/UpdateProductConfigurationByLevels',
                type: 'POST'
            },
            params: dto
        })
            .then(() => deferred.resolve(null))
            .fail(err => deferred.reject(this.GetResponseError(err, NOTIFICATIONS.ERROR_UPDATING_CONFIGURATION)));

        return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}