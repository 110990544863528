import {serialize, serializeAs} from 'libs/cerialize';

export class SignInModel {
    @serialize
    UserName: string;

    @serialize
    Password: string;

    @serialize
    TotpPassword: string;

    @serialize
    ClientId: string;

    @serialize
    ClientSecret: string;

    @serialize
    DatabaseName: string;

    @serialize
    SecurityCode: string;

    @serialize
    Language: string;

    @serialize
    GrantType: string;
}