import {AttachmentModel} from 'Core/Controls/Image/Models/AttachmentModel';
import {UpdateAnnotationsDto} from "Core/Components/Controls/ProductConfigurator/Pages/StartPage/Components/ConfigurationImageGallery/Models/UpdateAnnotationsDto";

export class SaveConfigurationDataDto {
    OrderEntityId: number;
    ProductEntityId: number;

    constructor(public ConfigurationId: number,
                public Memo: string,
                public AddedImageAttachments: Array<AttachmentModel>,
                public RemovedImageIds: Array<number>,
                public UpdatedAnnotations: Array<UpdateAnnotationsDto>) {
    }
}