import {ProductDto} from "Core/Components/Controls/ProductConfigurator/Models/ProductDto";

import {ProductResponse} from "../Stores/Models/ProductResponse";

import {Product} from "../Models/Product";

export type AfterMap = (productResponse: ProductResponse, product: Product) => void;

export class RootProductsPageMappings {
    MapToProductDto(product: Product) {
        return new ProductDto(product.Id, product.Name, product.NameTranslated, product.Image, null);
    }

    MapToProduct(productResponse: ProductResponse, afterMap?: AfterMap): Product {
        const product = new Product(productResponse.Id, productResponse.Name, productResponse.NameTranslated, productResponse.Image, productResponse.Description, productResponse.PcfGroup.Id, productResponse.PcfGroup.Name);

        if (afterMap) {
            afterMap(productResponse, product);
        }

        return product;
    }

    MapToProducts(productsDto: ProductResponse[], afterMap?: AfterMap): Product[] {
        return productsDto.map(productResponse => this.MapToProduct(productResponse, afterMap));
    }
}