import {deserializeAs} from 'libs/cerialize';
import {deserialize} from 'libs/cerialize';

import {FieldFlags} from "Core/Common/Enums/FieldFlags";

export class LookupFieldModel {

    @deserialize        
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    NameTranslated: string;

    @deserialize
    Type: string;

    @deserialize
    FormatName: string;

    @deserialize
    IsPrimaryKey: boolean;

    @deserialize
    IsVirtual: boolean;

    @deserialize
    ValTableId: number;

    @deserialize
    ValFieldId: number;

    @deserialize
    ValFieldTypeName: string;

    @deserialize
    ValFieldFormatName: string;

    @deserialize
    ValFieldSize: number;

    @deserialize
    Flags: number;

    @deserialize
    Size: number;

    get IsHidden() {
		  return (FieldFlags.Hidden & this.Flags) === FieldFlags.Hidden;
    }
}

export class LookupModel {

    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    TranslatedName: string;

    @deserialize
    Type: string;

    @deserializeAs(LookupFieldModel)
    Fields: Array<LookupFieldModel>;
}