import * as ko from 'knockout';
import * as _ from 'underscore';

import {RenderModes, CONTROL_TYPES} from "Core/Constant";
import {BaseControl, IControlValue} from 'Core/Controls/BaseControl/BaseControl';
import {IControlParam} from "Core/Screens/IScreen";
import {GeneralProperties} from "Core/GeneralProperties/GeneralProperties";
import {TriggerModel} from 'Core/Controls/BaseControl/Models/TriggerModel';
import {TRIGGER_ACTIONS} from 'Core/Constants/TriggerActions';
import {TRIGGER_PARAMS} from 'Core/Constants/TriggerParams';
import {
    IParamModel,
    IExecuteTriggerRequestModel,
    TriggerStore
} from 'Core/Components/Triggers/TriggerStore';
import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';
import {IconModel} from "../BaseControl/Models/IconModel";
import {GenericButtonConfigModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/GenericButtonConfigModel';
import {NOTIFICATIONS} from 'Core/Components/Translation/Locales';
import {RecordKey} from 'Core/Controls/Grid/Models/GridDataModel/GridCellValueModel';
import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';

import GenericButtonConfig from 'Core/Controls/GenericButton/Configs/generic-button-config.json';

import ViewTemplate from 'Core/Controls/GenericButton/Templates/View.html';
import HelpViewTemplate from 'Core/Controls/GenericButton/Templates/HelpView.html';
import DesignTemplate from 'Core/Controls/GenericButton/Templates/Design.html';
import ToolBarTemplate from 'Core/Controls/GenericButton/Templates/ToolBar.html';
import { GridRow } from "Core/Controls/Grid/BaseGrid/GridRow/GridRow";
import { FunctionBuilder } from 'Core/Components/CustomFunctions/FunctionBuilder';
import { genericButtonFacade } from 'Core/Components/CustomFunctions/Facades/GenericButtonFacade';


ko.templates['Core/Controls/GenericButton/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/GenericButton/Templates/GridRow'] = ViewTemplate;
ko.templates['Core/Controls/GenericButton/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/GenericButton/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/GenericButton/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/GenericButton/Templates/ToolBar'] = ToolBarTemplate;

export class GenericButton extends BaseControl {
    private _config: any;
    private _iconColor: KnockoutObservable<string>;
    private _nameInTooltip: KnockoutObservable<boolean>;
    private _synchronousExecution: KnockoutObservable<boolean>;
    private _buttonClassName: KnockoutObservable<string>;
    private _iconModel = IconModel;

    constructor(params: IControlParam) {
        super(params, GenericButtonConfig);

        this._style = ko.computed(() => {
            const paddingV = this._border() ? 6 : 7;
            const paddingH = this._border() || this._backgroundColor() ? 14 : 4;

            return {
                backgroundColor: this._backgroundColor(),
                color: this._color(),
                borderColor: this._borderColor(),
                borderWidth: this._border() ? '1px' : '0',
                padding: `${paddingV}px ${paddingH}px`
            };
        });

        this._iconColor = ko.observable(null);
        this._nameInTooltip = ko.observable(false);
        this._synchronousExecution = ko.observable(false);

        this.Init();
        this._icon(this._model().Icon);
        this._buttonClassName = ko.observable(this._iconModel.GetClasses(this._model().Icon?.FontName, this._model().Icon?.Name));

        PubSub.subscribe(PUB_SUB_EVENTS.EXECUTE_SCRIPT, (_, data) => {
            if(data && data.ObjectId === this.GetGuid()){
                FunctionBuilder.Execute(data.Code, data.Params, genericButtonFacade(this));
            }
        });

        this._icon.subscribe(() => {
            this._buttonClassName(this._iconModel.GetClasses(this._model().Icon?.FontName, this._model().Icon?.Name))
        });
    }

    get ClickTrigger(): TriggerModel{
        return _.find(this._model().Triggers, (trigger: TriggerModel) => {
            return trigger.Action === TRIGGER_ACTIONS.ON_CLICK
        });
    }

    async Click() {
        if (!this.IsDesignTime) {
            if (this._help.IsHelpButtonPressed()) {
                this._help.ShowControlHelp(this);
                return;
            }

            if (this.IsDisabledByCondition) {
                return;
            }

            if (this.ClickTrigger) {
                let params: Array<IParamModel> = [];
                if (this.PrepareParams(this.ClickTrigger, params)) {
                    let requestModel: IExecuteTriggerRequestModel = {
                        Id: this.ClickTrigger.Id,
                        ObjectId: this.GetGuid(),
                        Name: this.ClickTrigger.Name,
                        Params: params
                    }

                    BlockUI.Block();
                    await TriggerStore.ExecuteClickTrigger(requestModel).fail((err) => new Notifier().Failed(err.message));
                    BlockUI.Unblock();

                    if (this._synchronousExecution()) {
                        this._form.GetScreen().Refresh();
                        return;
                    }
                }
            }
        }
    }

    private PrepareParams(trigger: TriggerModel, params: Array<IParamModel>): boolean {
        let result = true;
        if (this._form) {
            _.each(trigger.Params, (param) => {
                if (param.Name === TRIGGER_PARAMS.ENTITY_ID || param.Name === TRIGGER_PARAMS.MAIN_ENTITY_ID) {
                    params.push({Name: param.Name, Value: this._form.GetScreen().GetEntityId()});
                }

                if (param.Name === TRIGGER_PARAMS.RECORD_ID || param.Name === TRIGGER_PARAMS.MAIN_RECORD_ID) {
                    params.push({Name: param.Name, Value: this._form.GetScreen().GetRecordId()});
                }

                if (param.Name === TRIGGER_PARAMS.SCREEN_ID) {
                    params.push({Name: param.Name, Value: this._form.GetScreen().GetId()});
                }
                if (param.Name === TRIGGER_PARAMS.TABLE_TYPE_ID) {
                    params.push({Name: param.Name, Value: this._form.GetScreen().GetTableTypeId()});
                }

                const source = this._form.GetScreen().GetSource();
                if (source) {
                    if (param.Name === TRIGGER_PARAMS.PREVIOUS_ENTITY_ID) {
                        params.push({Name: param.Name, Value: source.GetEntityId()});
                    }

                    if (param.Name === TRIGGER_PARAMS.PREVIOUS_RECORD_ID) {
                        params.push({
                            Name: param.Name,
                            Value: this._form.GetScreen().GetSource().GetRecordId()
                        });
                    }
                }

                if (param.Name === TRIGGER_PARAMS.RELATED_RECORD_IDS) {
                    if (this.GetParentControl() && this.GetParentControl().GetType() === CONTROL_TYPES.Grid) {
                        let grid = this.GetParentControl() as any;

                        let config = this.GetButtonInGridConfig(grid);

                        if (config) {

                            let selectedRecords = grid.GetSelectRecords();

                            let keys = [];
                            _.each(selectedRecords, (row: GridRow) => {
                                keys = keys.concat(row.Model.RecordKeys);
                            });

                            let recordKeys = _.filter(keys, (item: RecordKey) => {
                                return item.RecordId != 0 && item.QueryEntityGuid === config.QueryEntityGuid && item.FieldId === config.FieldId;
                            });

                            if (recordKeys.length === 0) {
                                new Notifier().Failed(NOTIFICATIONS.SELECT_RECORDS_TO_PROCESS);
                                result = false;
                            }

                            let ids = _.map(recordKeys, (recordKey) => {
                                return recordKey.RecordId;
                            })

                            params.push({Name: param.Name, Value: ids.join()});

                        } else {

                            let selectedRecords = grid.GetSelectRecords();

                            let keys = [];
                            _.each(selectedRecords, (row: GridRow) => {
                                keys = keys.concat(row.RecordId);
                            });

                            if (keys.length === 0) {
                                new Notifier().Failed(NOTIFICATIONS.SELECT_RECORDS_TO_PROCESS);
                                result = false;
                            }

                            params.push({Name: param.Name, Value: keys.join()});
                        }
                    }
                }

                if (param.Name === TRIGGER_PARAMS.KSEQS) {
                    if (this.GetParentControl() && this.GetParentControl().GetType() === CONTROL_TYPES.Grid) {
                        let grid = this.GetParentControl() as any;

                        let selectedRecords = grid.GetSelectRecords();

                        let keys = [];
                        _.each(selectedRecords, (row: GridRow) => {
                            keys = keys.concat(row.KSeq);
                        });

                        if (keys.length === 0) {
                            new Notifier().Failed(NOTIFICATIONS.SELECT_RECORDS_TO_PROCESS);
                            result = false;
                        }

                        params.push({Name: param.Name, Value: keys.join()});
                    }
                }

                if (param.Name === TRIGGER_PARAMS.RELATED_RECORD_ID) {
                    if (this.GetParentControl() && this.GetParentControl().GetType() === CONTROL_TYPES.Grid && this._gridRow) {
                        let grid = this.GetParentControl() as any;
                        let config = this.GetButtonInGridConfig(grid);

                        if (config) {
                            let paramData = _.find(this._gridRow.Model.RecordKeys, (item: any) => {
                                return item.RecordId != 0 && item.QueryEntityGuid === config.QueryEntityGuid && item.FieldId === config.FieldId;
                            });

                            if (!paramData) {
                                let notifier = new Notifier(null);
                                notifier.Success(NOTIFICATIONS.PARAM_IS_EMPTY.replace('{ParamName}', param.Name));
                                result = false;
                            } else {
                                params.push({Name: param.Name, Value: paramData.RecordId});
                            }
                        } else {
                            params.push({ Name: param.Name, Value: this._gridRow.RecordId});
                        }

                    }
                }

                if (param.Name === TRIGGER_PARAMS.KSEQ) {
                    if (this.GetParentControl() && this.GetParentControl().GetType() === CONTROL_TYPES.Grid && this._gridRow) {
                        params.push({ Name: param.Name, Value: this._gridRow.KSeq});
                    }
                }

                if (param.Name === TRIGGER_PARAMS.RELATED_ENTITY_ID) {
                    if (this.GetParentControl() && this.GetParentControl().GetType() === CONTROL_TYPES.Grid) {
                        let grid = this.GetParentControl() as any;
                        let config = this.GetButtonInGridConfig(grid);
                        if (config) {
                            params.push({Name: param.Name, Value: config.EntityId});
                        } else {
                            params.push({Name: param.Name, Value: grid._gridEntityId});
                        }
                    }
                }
            });
        }
        return result;
    }

    private GetButtonInGridConfig(grid: any): GenericButtonConfigModel {
        return _.find(grid.QueryExpression.GenericButtonConfigs, (config: GenericButtonConfigModel) => {
            return config.GenericButtonId === this.GetControlId();
        });
    }

    private Init() {
        this.ApplyProperties();
    }

    SetValue(value: IControlValue): void {
        super.SetValue(value);
    }

    ApplyProperties() {
        if (this.Properties) {
            this.AssignProperty('Styling', 'BackgroundColor', this._backgroundColor);
            this.AssignProperty('Styling', 'TextColor', this._color);
            this.AssignProperty('Styling', 'Border', this._border);
            this.AssignProperty('Styling', 'IconColor', this._iconColor);
            this.AssignProperty('Styling', 'NameInTooltip', this._nameInTooltip);
            this.AssignProperty('Styling', 'BorderColor', this._borderColor);
            this.AssignProperty('Execution', 'SynchronousExecution', this._synchronousExecution)
        }
    }

    private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
        if (this.Properties[groupedBy]) {
            _.each(this.Properties[groupedBy].Properties,
                (property: any) => {
                    if (property.hasOwnProperty(propertyName)) {
                        propertyHolder(property[propertyName]);
                    }
                });
        }
    }

    private GetTooltipValue(): string {
        if (this._nameInTooltip()) {
            return this.Label();
        }
        return '';
    }
}
