import * as ko from 'knockout';

import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

import {Event} from 'Core/Common/Event';
import {EVENTS} from 'QueryBuilder/Events';
import {EntityMetadataModel} from 'Core/Controls/Grid/Models/GridDataModel/EntityMetadataModel';
import { FieldMetadataModel } from "Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel";

export class EntityListItem extends Event {
	private _isSelected: KnockoutObservable<boolean>;
	private _isEnableUncheck: KnockoutObservable<boolean>;
	private _lookupField: FieldMetadataModel;
	private _referenceField: FieldMetadataModel;
	private _referenceLookupField: FieldMetadataModel;

	constructor(
		private _model: EntityMetadataModel,
		isSelected: boolean = false,
		isEnableUncheck: boolean = true,
		lookupField: FieldMetadataModel = null,
		referenceField: FieldMetadataModel = null,
		referenceLookupField: FieldMetadataModel = null
	) {
		super();

		this.AddEvent(EVENTS.SELECTED);

		this.AddEvent(EVENTS.ENTITY_SELECTED);
		this.AddEvent(EVENTS.ENTITY_UNSELECTED);

		this.AddEvent(EVENTS.LOOKUP_ENTITY_SELECTED);
		this.AddEvent(EVENTS.LOOKUP_ENTITY_UNSELECTED);

		this.AddEvent(EVENTS.REFERENCE_ENTITY_SELECTED);
		this.AddEvent(EVENTS.REFERENCE_ENTITY_UNSELECTED);

		this.AddEvent(EVENTS.REFERENCE_LOOKUP_ENTITY_SELECTED);
		this.AddEvent(EVENTS.REFERENCE_LOOKUP_ENTITY_UNSELECTED);

		this._lookupField = lookupField;
		this._referenceField = referenceField;
		this._referenceLookupField = referenceLookupField;
		this._isSelected = ko.observable(isSelected);
		this._isEnableUncheck = ko.observable(isEnableUncheck);

		this._isSelected.subscribe(newValue => {
			if (newValue) {
				this.Trigger(this.ItemSelectedTriggerName, {
					EntityId: this._model.Id,
					ReferenceLookupFieldId: this._referenceLookupField ? this._referenceLookupField.Id : null,
					LookupFieldId: this._lookupField ? this._lookupField.Id : null,
					ReferenceFieldId: this._referenceField ? this._referenceField.Id : null,
					ReferenceFieldName: this._referenceField ? this._referenceField.Name : null,
					Lifestatus: this._model.Lifestatus
				});
			} else {
				if (this.IsEnableUncheck) {
					this.Trigger(this.ItemUnSelectedTriggerName, {
						EntityId: this._model.Id,
						Type: this._model.Type,
						ReferenceLookupFieldId: this._referenceLookupField ? this._referenceLookupField.Id : null,
						LookupFieldId: this._lookupField ? this._lookupField.Id : null,
						ReferenceFieldId: this._referenceField ? this._referenceField.Id : null,
						ReferenceFieldName: this._referenceField ? this._referenceField.Name : null,
						Lifestatus: this._model.Lifestatus
					});
				}
			}
		});
	}

	@enumerable get ItemUnSelectedTriggerName(): string {
		if(this.IsLookup){
			return EVENTS.LOOKUP_ENTITY_UNSELECTED;
		}

		if(this.IsReferenceLookup){
			return EVENTS.REFERENCE_LOOKUP_ENTITY_UNSELECTED;
		}

		if(this.IsReference){
			return EVENTS.REFERENCE_ENTITY_UNSELECTED;
		}

		return EVENTS.ENTITY_UNSELECTED;
	}

	@enumerable get ItemSelectedTriggerName(): string {
		if(this.IsLookup){
			return EVENTS.LOOKUP_ENTITY_SELECTED;
		}

		if(this.IsReferenceLookup){
			return EVENTS.REFERENCE_LOOKUP_ENTITY_SELECTED;
		}

		if(this.IsReference){
			return EVENTS.REFERENCE_ENTITY_SELECTED;
		}

		return EVENTS.ENTITY_SELECTED;
	}

	@enumerable get Name(): string {
		return this._model.Name;
	}

	@enumerable get LookupFieldId(): number{
		return this._lookupField ? this._lookupField.Id : null;
	}

	@enumerable get ReferenceLookupFieldId(): number{
		return this._referenceLookupField ? this._referenceLookupField.Id : null;
	}

	@enumerable get ReferenceFieldId(): number{
		return this._referenceField ? this._referenceField.Id : null;
	}

	@enumerable get IsReferenceLookup(): boolean {
		return !!this._referenceLookupField;
	}

	@enumerable get IsLookup(): boolean {
		return !!this._lookupField;
	}

	@enumerable get IsReference(): boolean {
		return !!this._referenceField;
	}

	//TODO add translation for lookup table
	@enumerable get NameTranslation(): string {

		if(this._referenceLookupField){
			return `${this._referenceLookupField.Name} < ${this._model.Name}`;
		}

		if(this._lookupField){
			return `${this._model.Name} > ${this._lookupField.Name}`;
		}
		return this._model.NameTranslation || this._model.Name;
	}

	@enumerable get Model(): EntityMetadataModel {
		return this._model;
	}

	@enumerable get IsSelected(): KnockoutObservable<boolean> {
		return this._isSelected;
	}

	@enumerable get IsEnableUncheck(): KnockoutObservable<boolean> {
		return this._isEnableUncheck;
	}

	Select(){
		this.Trigger(EVENTS.SELECTED);
	}
}