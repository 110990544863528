export enum ColumnTypes {
	Decimal,
	Date,
	DateTime,
	Binary,
	Time,
	TimeSpan,
	Guid,
	Radio,
	Currency,
	Document,
	FlagCheck,
	Image,
	Integer,
	PKey,
	MultiSelect,
	Color,
	Lookup,
	Icon,
	Sort,
	Text,
	Type,
	YesNo,
	Memo,
	Password
}
