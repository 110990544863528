//Libs
import * as ko from "knockout";
import * as _ from "underscore";
//Constants
import {DEFAULT_ICONS, FIELD_TYPES, RenderModes, TABLE_TYPES, SortGroups} from "Core/Constant";

import {Icon} from "Core/Icon/Icon";
//Control
import {ComplexControl} from "Core/Controls/ComplexControl/ComplexControl";
import {IControlParam} from "Core/Screens/IScreen";
import {IControlValue} from "Core/Controls/BaseControl/BaseControl";
import {RequiredFieldModel} from "Core/Controls/ComplexControl/Models/RequiredFieldModel";
import TimeWritingConfig from 'Core/Controls/TimeWriting/Configs/time-writing-config.json';
//Components
import {TimeWriting as TimeWritingComponent} from "Core/Components/Controls/TimeWriting/TimeWriting";
//Templates
import ViewTemplate from "Core/Controls/TimeWriting/Templates/View.html";
import DesignTemplate from "Core/Controls/TimeWriting/Templates/Design.html";
import ToolBarTemplate from "Core/Controls/TimeWriting/Templates/ToolBar.html";

import {ScreenTypes} from "Core/Common/Enums/ScreenTypes";
import {AttachedFieldModel} from "../BaseControl/Models/AttachedFieldModel";
import {FieldModel} from "../../../QueryBuilder/Models/FieldModel";
import {EntityModel} from "../../../QueryBuilder/Models/EntityModel";
import {Notifier} from "../../Common/Notifier";
import {NOTIFICATIONS} from "../../Components/Translation/Locales";
import {DesignScreen} from "../../Screens/DesignScreen/DesignScreen";
import {GeneralProperties} from "../../GeneralProperties/GeneralProperties";

ko.templates["Core/Controls/TimeWriting/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/TimeWriting/Templates/ToolBar"] = ToolBarTemplate;
ko.templates["Core/Controls/TimeWriting/Templates/Design"] = DesignTemplate;

export class TimeWriting extends ComplexControl {
    private _component: TimeWritingComponent;
    private _customFields: KnockoutObservableArray<AttachedFieldModel>;
    protected _isRendered: KnockoutObservable<boolean>;

    constructor(params: IControlParam) {
        super(params, TimeWritingConfig);

        this.Init();
        this.BindEvents();

        if (this._renderMode() === RenderModes.View) {
            this._component.ShowStatic();
        }
    }

    ApplyProperties(){}

    SetValue(value: IControlValue) {
        if (this._renderMode() !== RenderModes.View || !value.SubjectEntityId || !value.SubjectRecordId) {
            return;
        }

        const screen = this.GetForm().GetScreen();
        const screenType = screen.GetType();
        const params = {
            ControlId: this.GetControlId(),
            EntityId: this._model().EntityId,
            SubjectEntityId: null,
            SubjectEntityName: null,
            SubjectEntityIcon: null,
            SubjectRecordId: null,
            SubjectRecordName: null,
            CustomFields: this._customFields(),
            Properties: this.GeneralProperties
        };

        if (screenType === ScreenTypes[ScreenTypes.ConsultScreen]) {
            params.SubjectEntityId = value.SubjectEntityId;
            params.SubjectEntityName = screen.GetEntityName();
            params.SubjectEntityIcon = screen.GetScreenIcon();
            params.SubjectRecordId = value.SubjectRecordId;
        }

        this._component = new TimeWritingComponent(params);
        screen.On('AGENDA_CHANGED', this, () => {
            if (this._component.DayViewIsUsed && this._component.CanBeUpdated()) {
                this._component.Show(new Date());
            }
        });

        this._isRendered.subscribe(() => this._component.Show(new Date()));
    }

    GetAttachedTableId() {
        return this._model().SubTableId || this._model().EntityId;
    }

    Init() {
        this.SetDefaultIcon(new Icon(DEFAULT_ICONS.TimeWriting));
        this._requiredFields([
            new RequiredFieldModel('BOOKEDTIMESUBJECT', FIELD_TYPES.Reference, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('F_USER', FIELD_TYPES.Lookup, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('NAME', FIELD_TYPES.Text, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('START', FIELD_TYPES.DateTime, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('TIMESPENT', FIELD_TYPES.TimeSpan, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('F_OVERWORKINDICATOR', FIELD_TYPES.Lookup, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('F_PAYMENTINDICATOR', FIELD_TYPES.Lookup, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('F_HOURKIND', FIELD_TYPES.Lookup, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('BOOKEDCOUNTER', FIELD_TYPES.Integer, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('COMMENT', FIELD_TYPES.Text, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('F_TIMESTATUS', FIELD_TYPES.Lookup, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('F_DISAPPROVEREASON', FIELD_TYPES.Lookup, TABLE_TYPES.Sub, null)
        ]);
        this.InitRequiredFields();
        this.InitCustomFields();
        this.InitRuntime();
    }

    BindEvents() {
        if (this._renderMode() === RenderModes.Design) {
            const oldAttachedTableId = this.GetAttachedTableId();

            this._model.subscribe(() => {
                if (oldAttachedTableId !== this.GetAttachedTableId()) {
                    this.Init();
                }
            });
        }
    }

    InitCustomFields() {
        const requiredFields = this._requiredFields();
        const customFields = _.filter(this._model().Fields, field => !_.some(requiredFields, rf => rf.DefaultName === field.Name));
        this._customFields = ko.observableArray(customFields);
    }

    InitRuntime() {
        if (this._renderMode() !== RenderModes.ToolBar && this._renderMode() !== RenderModes.Design) {
            this._component = new TimeWritingComponent({
                ControlId: this.GetControlId(),
                EntityId: this.GetAttachedTableId(),
                SubjectEntityId: null,
                SubjectEntityName: null,
                SubjectEntityIcon: null,
                SubjectRecordId: null,
                SubjectRecordName: null,
                CustomFields: this._customFields(),
                Properties: this.GeneralProperties
            });
        }
    }

    FormatFieldName(field: AttachedFieldModel) {
        if (field.FieldNameTranslation) {
            return field.FieldNameTranslation;
        }

        return field.Name;
    }

    GetComplexControlDesignTemplate() {
        return ComplexControl.designTemplate;
    }

    AfterDropField(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext) {
        if (targetContext instanceof TimeWriting && item instanceof FieldModel && sourceContext instanceof EntityModel) {
            const field = item as FieldModel;
            const table = sourceContext as EntityModel;

            if (table.EntityId !== this.GetAttachedTableId()) {
                new Notifier().Warning(NOTIFICATIONS.ONLY_CUSTOM_FIELDS_ALLOWED);
                return;
            }

            if (!SortGroups.InCustom(field.Sort) || field.IsSystem) {
                new Notifier().Warning(NOTIFICATIONS.ONLY_CUSTOM_FIELDS_ALLOWED);
                return;
            }

            const alreadyAddedField = _.some(this._model().Fields, f => f.Name === field.FieldName);

            if (alreadyAddedField) {
                new Notifier().Warning(NOTIFICATIONS.FIELD_ALREADY_ADDED);
                return;
            }

            const attachedFieldModel = new AttachedFieldModel();
            attachedFieldModel.Id = field.FieldId;
            attachedFieldModel.Name = field.FieldName;
            attachedFieldModel.FieldNameTranslation = field.FieldNameTranslation;
            attachedFieldModel.EntityName = table.EntityName;
            attachedFieldModel.EntityNameTranslation = table.EntityNameTranslation;
            attachedFieldModel.EntityTypeName = table.TypeName;
            attachedFieldModel.EntityId = table.EntityId;

            this._customFields.push(attachedFieldModel);
            this._model().Fields.push(attachedFieldModel);

            this.DesignChanged();
        }

        if(targetContext instanceof TimeWriting && item instanceof AttachedFieldModel){
            targetItems.splice(targetIndex, 0, sourceItems.splice(sourceIndex, 1)[0]);
            this.DesignChanged();

            _.forEach(targetItems(), (targetItem: AttachedFieldModel, i) => {
                targetItem.Sort = i * 10;
            })
        }
    }

    RemoveField(customField: AttachedFieldModel) {
        const customFieldIndex = this._customFields.indexOf(customField);
        this._customFields.splice(customFieldIndex, 1);

        const attachedFieldIndex = this._model().Fields.indexOf(customField);
        this._model().Fields.splice(attachedFieldIndex, 1);

        this.DesignChanged();
    }

    DesignChanged() {
        (this.GetForm().GetScreen() as DesignScreen).HasChanges = true;
    }
}