import * as ko from 'knockout';
import {PASSWORD} from 'Core/Constant';
import { LanguageModel } from 'Auth/UI/AuthForm/Models/LanguageModel';
import {LABELS} from "Core/Components/Translation/Locales";

export class AuthFormModel {
	UserName: KnockoutObservable<string>;

	Password: KnockoutObservable<string>;
	Database: KnockoutObservable<string>;
	FileDir: string;
	Language: string;

	constructor() {
		this.FileDir = null;

		this.UserName = ko.observable('').extend({
			required: {
				message: LABELS.ENTER_LOGIN
			},
			minLength: 2
		});

		this.Password = ko.observable('').extend({
			required: {
				message: LABELS.ENTER_PASSWORD
			},
			minLength: PASSWORD.MinimumLength
		});

		this.Database = ko.observable(undefined).extend({
			required: {
				message: LABELS.SELECT_DATABASE
			}
		});
	}
}