import * as _ from 'underscore';
import 'lockr';
import {Notifier} from 'Core/Common/Notifier';
import {IPathRunnerSettings} from "Core/UserVarsManager/UserVars/IPathRunnerSettings";
import * as moment from "moment";
import {UserVarsStore} from 'Core/UserVarsManager/UserVarsStores/UserVarsStore';
import {GlobalManager, GLOBALS} from '../GlobalManager/GlobalManager';
import {CookieManager} from "Core/Common/CookieManager";
import {NOTIFICATIONS, LABELS} from "Core/Components/Translation/Locales";
import {Guid} from 'Core/Common/Guid';
import {FastFilterSaveModel} from 'Core/Controls/Grid/BaseGrid/FastFilter/FastFilterModels';
import enumerable from '../Common/Decorators/EnumerableDecorator';
import {CheckedUserAndGroup, SelectedUserType} from "Core/Controls/SelectUser/SelectUser";

export const GROUPS = {
    RECENT: 'recent',
    FAVORITES: 'favorites',
    SETTINGS: 'settings',
    SEARCH_PARAMS: 'searchParams',
    TABS_PARAMS: 'tabsParams',
    PLANNER_TABS_PARAMS: 'plannerTabsParams',
    GRID_VIEW: 'gridView',
    GRID_PRIORITY: 'gridPriority',
    PORTLET_SETTINGS: 'portletSettings',
    PATH_RUNNER_SETTINGS: 'pathRunnerSettings',
    KANBAN_LAST_SELECTED_TYPE: 'kanbanLastSelectedType',
    KANBAN_TODO_SELECTED: 'kanbanTodoSelected',
    KANBAN_VIEW: 'kanbanView',
    FINANCIAL_DASHBOARD_GRID_VIEW: 'financialDashboardGridView',
    BASKET: 'basket',
    DB_STATUS: 'databaseDesignerDbId',
    USER_SELECTION: 'userSelection',
    LAST_SYNC_TIME: 'lastSyncTime',
    ALT_ENTITIES: 'altEntities',
    GRID_COLUMN_FILTERS: 'gridColumnFilters',
    LOAD_CHART_STATES: 'loadChartStates',
    SCHEDULER_VIEW: 'schedulerView',
    SCHEDULER_SUBJECT: 'schedulerSubject',
    SCHEDULER_DATE: 'schedulerDate',
    LIFE_STATUS_ADVANCED_MODE: 'lifeStatusAdvancedMode',
    AGENDA_DATE: 'agendaDate',
    PLANNER_PAGE: 'plannerPage',
    PLANNER_LEVEL: 'plannerLevel',
    KANBAN_BOARD_ACCORDION_GROUPS: 'kanbanBoardAccordionGroups'
}

export interface ISelection {
    RememberMe: boolean,
    ScreenId: number,
    UserSelection: Array<IUserSelection>;
}

export interface IUserSelection {
    Guid: string;
    Users: Array<SelectedUserType>,
    UserGroups: Array<CheckedUserAndGroup>;
    Checked: boolean;
}

export interface IPlannerPageParams {
    StartDate: string,
    ViewType: string;
    Period: number,
    ProjectManagerId: number,
    IsWeekPlannnig: boolean;
}

export interface IPlannerLevelParams {
    ExpandedBarIds: Array<string>;
}

export interface IKanbanBoardAccordionGroups {
    controlId: number,
    entity: {
        id: number,
        records: [{
            id: number,
            expanded: boolean
        }]
    }
}

export class UserVarsManager {
    private static instance: UserVarsManager;
    private static allowInstantiation: boolean = true;
    private _syncInterval: any;

    constructor() {
        if (!UserVarsManager.allowInstantiation) {
            throw new Error('Use UserVarManager.Instance instead');
        }
    }

    static get Instance(): UserVarsManager {
        if (UserVarsManager.instance == null) {
            UserVarsManager.instance = new UserVarsManager();
            UserVarsManager.allowInstantiation = false;
        }

        return UserVarsManager.instance;
    }

    GetAll(): string {
        var allGroups = {};

        _.each(_.values(GROUPS), value => {
            allGroups[value] = Lockr.get(value);
        });

        return JSON.stringify(allGroups);
    }

    GetLastRecentRecordByEntityId(entityId: number): any {
        var recentRecords = <any>Lockr.get(GROUPS.RECENT);
        var entityWithRecords = _.findWhere(recentRecords, {
            'entityId': entityId
        });

        if (entityWithRecords && entityWithRecords["records"]) {
            return _.last(entityWithRecords["records"]);
        }
        return null;
    }

    GetLastDbChoosen() {
        var databaseDesignerDbId = <any>Lockr.get(GROUPS.DB_STATUS);
        if (databaseDesignerDbId) {
            return _.last(databaseDesignerDbId);
        }
        return null;
    }

    GetTabsParams() {
        var tabsParams = Lockr.get(GROUPS.TABS_PARAMS);

        if (!tabsParams) {
            return null;
        }

        var entityParams = tabsParams['tabs'];
        return entityParams ? entityParams : null;
    }

    SetTabsParams(activeTabPageCID: any, tabsActivityStorage?: any) {
        let tabParams = Lockr.get(GROUPS.TABS_PARAMS) || {};
        let existedTabs = tabParams['tabs'] && tabParams['tabs'].activeTabPageCIDs || [];
        const entityParams = {
            activeTabPageCIDs: [...existedTabs],
            tabsActivityStorage: {}
        };
        if (tabsActivityStorage) {
            entityParams.tabsActivityStorage = tabsActivityStorage;
        }

        if (activeTabPageCID) {
            entityParams.activeTabPageCIDs = entityParams.activeTabPageCIDs.filter((activeTabPageData) => {
                return activeTabPageData.screenId !== activeTabPageCID.screenId;
            });

            entityParams.activeTabPageCIDs.push(activeTabPageCID);
        }

        if (!tabParams) {
            tabParams = {};
        }

        tabParams['tabs'] = entityParams;
        Lockr.set(GROUPS.TABS_PARAMS, tabParams);
    }

    GetPlannerTabsParams() {
        let plannerTabsParams = Lockr.get(GROUPS.PLANNER_TABS_PARAMS);

        if (!plannerTabsParams) {
            return null;
        }

        let entityParams = plannerTabsParams['tabs'];
        return entityParams ? entityParams : null;
    }

    SetPlannerTabsParams(activePlannerTabPageCID: any, plannerTabsActivityStorage?: any) {
        let plannerTabsParams = Lockr.get(GROUPS.PLANNER_TABS_PARAMS) || {};
        let existedTabs = plannerTabsParams['tabs'] && plannerTabsParams['tabs'].activePlannerTabPageCIDs || [];
        const entityParams = {
            activePlannerTabPageCIDs: [...existedTabs],
            plannerTabsActivityStorage: {}
        };
        if (plannerTabsActivityStorage) {
            entityParams.plannerTabsActivityStorage = plannerTabsActivityStorage;
        }

        if (activePlannerTabPageCID) {
            entityParams.activePlannerTabPageCIDs = entityParams.activePlannerTabPageCIDs.filter((activeTabPageData) => {
                return activeTabPageData.screenId !== activePlannerTabPageCID.screenId;
            });

            entityParams.activePlannerTabPageCIDs.push(activePlannerTabPageCID);
        }

        if (!plannerTabsParams) {
            plannerTabsParams = {};
        }

        plannerTabsParams['tabs'] = entityParams;
        Lockr.set(GROUPS.PLANNER_TABS_PARAMS, plannerTabsParams);
    }

    RemovePreselectTabData(screenId: number) {
        var tabsActivityStorage = this.GetTabsParams() ? this.GetTabsParams().tabsActivityStorage : {};
        const activeTabPageCID = this.GetTabsParams() && _.where(this.GetTabsParams().activeTabPageCIDs, {screenId: screenId})[0] || null;

        this.SetTabsParams(activeTabPageCID, tabsActivityStorage);
    }

    GetByEntityId(entityId: number) {
        var allGroups = {};
        _.each(_.values(GROUPS), (value) => {
            let groupRecords = <any>Lockr.get(value);

            if (groupRecords) {
                let entityRecords = _.findWhere(groupRecords, {'entityId': entityId});

                if (entityRecords) {
                    allGroups[value] = entityRecords["records"];

                    if (value === GROUPS.RECENT) {
                        allGroups[value].reverse();
                    }
                }
            }
        });

        return allGroups;
    }

    GetRecordAltEntities(recordId) {
        var altEntities = Lockr.get(GROUPS.ALT_ENTITIES);

        if (!altEntities) {
            return null;
        }
        var recordParams = altEntities[recordId];
        return recordParams ? recordParams : null;
    }

    SetRecordAltEntities(recordId, altEntitiesData) {
        recordId = parseInt(recordId);

        var recordParams = {};
        recordParams = {
            altEntities: altEntitiesData
        };

        var altEntities = Lockr.get(GROUPS.ALT_ENTITIES);
        if (!altEntities) {
            altEntities = {};
        }

        altEntities[recordId] = recordParams;
        Lockr.set(GROUPS.ALT_ENTITIES, altEntities);
    }


    GetSearchParams(entityId) {
        var searchParams = Lockr.get(GROUPS.SEARCH_PARAMS);

        if (!searchParams) {
            return null;
        }
        var entityParams = searchParams[entityId];
        return entityParams ? entityParams : null;
    }

    SetLastDbChoosen(databaseDesignerDbId) {
        Lockr.set(GROUPS.DB_STATUS, databaseDesignerDbId);
    }

    SetLoadChartState(controlId: number, state: boolean) {
        const loadChartStates = Lockr.get(GROUPS.LOAD_CHART_STATES) || {};

        if (!loadChartStates[controlId]) {
            loadChartStates[controlId] = {};
        }

        loadChartStates[controlId] = state;

        Lockr.set(GROUPS.LOAD_CHART_STATES, loadChartStates);
    }

    GetLoadChartState(controlId: number): boolean {
        const loadChartStates = Lockr.get(GROUPS.LOAD_CHART_STATES) || {};

        if (loadChartStates[controlId] == null) {
            return true;
        } else {
            return loadChartStates[controlId];
        }
    }

    GetGridColumnFilters(recordId: number) {
        const gridColumnFilters = Lockr.get(GROUPS.GRID_COLUMN_FILTERS);

        return gridColumnFilters ? gridColumnFilters[recordId] : null;
    }

    HasFastFilter(recordId: number) {
        let filteredList = this.GetGridColumnFilters(recordId);
        if (filteredList && filteredList.length) {
            let filteredItem = _.find(filteredList, (item: any) => item.Values.length);

            return !!filteredItem;
        }

        return !!(filteredList && filteredList.length);
    }

    ResetGridFilters(recordId: number = -1) {
        let gridColumnFilters = Lockr.get(GROUPS.GRID_COLUMN_FILTERS);

        if (gridColumnFilters) {
            gridColumnFilters[recordId] = [];
        }

        Lockr.set(GROUPS.GRID_COLUMN_FILTERS, gridColumnFilters);
    }

    SetGridColumnFilters(recordId: number = -1, filters: FastFilterSaveModel[]) {
        let gridColumnFilters = Lockr.get(GROUPS.GRID_COLUMN_FILTERS);

        if (!gridColumnFilters) {
            gridColumnFilters = {};
        } else {
            // check if uservars has a valid model, if not - clear it
            try {
                const currentFilter: FastFilterSaveModel = gridColumnFilters[recordId];

                let isModelValid = !currentFilter
                    || (typeof currentFilter[0].FieldId === 'number'
                        && typeof currentFilter[0].FieldAlias === 'string'
                        && Array.isArray(currentFilter[0].Values));

                if (!isModelValid) {
                    throw new Error('invalid uservars model, cleaning...');
                }
            } catch (error) {
                gridColumnFilters[recordId] = [];
            }
        }

        filters.forEach((filter: FastFilterSaveModel) => {
            if (gridColumnFilters[recordId]) {
                let isFilterAlreadyExists = false;

                gridColumnFilters[recordId] = gridColumnFilters[recordId].map((existedFilter: FastFilterSaveModel) => {
                    if (existedFilter.TableViewId === filter.TableViewId && existedFilter.FieldAlias === filter.FieldAlias) {
                        isFilterAlreadyExists = true;

                        return filter;
                    } else {
                        return existedFilter;
                    }
                });

                if (!isFilterAlreadyExists) {
                    gridColumnFilters[recordId].push(filter);
                }
            } else {
                gridColumnFilters[recordId] = [filter];
            }
        });

        Lockr.set(GROUPS.GRID_COLUMN_FILTERS, gridColumnFilters);
    }

    SetSearchParams(entityId, recordTypeName, searchModeValue, lifeStatusId, recordTypeValue, searchMode?, searchField?, enableFiltering?, searchByRelations?) {
        entityId = parseInt(entityId);

        var entityParams = {};
        entityParams = {
            recordTypeName: recordTypeName,
            searchMode: searchMode,
            searchField: searchField,
            searchModeValue: searchModeValue,
            lifeStatusId: lifeStatusId,
            recordTypeValue: recordTypeValue,
            enableFiltering: enableFiltering,
            searchByRelationsValue: searchByRelations
        };

        var searchParams = Lockr.get(GROUPS.SEARCH_PARAMS);
        if (!searchParams) {
            searchParams = {};
        }

        searchParams[entityId] = entityParams;
        Lockr.set(GROUPS.SEARCH_PARAMS, searchParams);
    }

    GetRecentAndFavourites(entityId: number): any {
        //var allGroups = this.GetByEntityId(entityId);
        return this.GetByEntityId(entityId);

        //return {
        //	recent: allGroups[GROUPS.RECENT],
        //	favorites: allGroups[GROUPS.FAVOURITES]
        //};
    }


    GetPortleteSettings(portletId) {
        var portletsSettings = Lockr.get(GROUPS.PORTLET_SETTINGS) || {};
        return portletsSettings[portletId];
    }

    SetPortletSettings(portletId: number, settings: any) {
        settings = JSON.parse(settings);
        var portletsSettings = Lockr.get(GROUPS.PORTLET_SETTINGS) || {};

        portletsSettings[portletId] = settings;

        Lockr.set(GROUPS.PORTLET_SETTINGS, portletsSettings);
    }

    GetSettingsColor() {
        return this.GetSettings()['color'];
    }

    GetKanbanLastType(controlId: number): number {
        const types = Lockr.get(GROUPS.KANBAN_LAST_SELECTED_TYPE) || {};
        return types[controlId];
    }

    SetKanbanLastType(controlId: number, typeId: number) {
        const types = Lockr.get(GROUPS.KANBAN_LAST_SELECTED_TYPE) || {};
        types[controlId] = typeId;

        Lockr.set(GROUPS.KANBAN_LAST_SELECTED_TYPE, types);
    }

    GetKanbanTodoSelected(controlId: number): boolean {
        const kanbanTodoSelectedValues = Lockr.get(GROUPS.KANBAN_TODO_SELECTED) || {};
        return kanbanTodoSelectedValues[controlId];
    }

    SetKanbanTodoSelected(controlId: number, isKanbanTodoSelected: boolean) {
        const kanbanTodoSelectedValues = Lockr.get(GROUPS.KANBAN_TODO_SELECTED) || {};
        kanbanTodoSelectedValues[controlId] = isKanbanTodoSelected;

        Lockr.set(GROUPS.KANBAN_TODO_SELECTED, kanbanTodoSelectedValues);
    }

    GetFinancialDashboardGridView(controlId: number): number {
        const gridViews = Lockr.get(GROUPS.FINANCIAL_DASHBOARD_GRID_VIEW) || {};
        return gridViews[controlId];
    }

    SetFinancialDashboardGridView(controlId: number, gridViewId: number) {
        const gridViews = Lockr.get(GROUPS.FINANCIAL_DASHBOARD_GRID_VIEW) || {};
        gridViews[controlId] = gridViewId;

        Lockr.set(GROUPS.FINANCIAL_DASHBOARD_GRID_VIEW, gridViews);
    }

    GetKanbanView(controlId: number): number {
        const kanbanViews = Lockr.get(GROUPS.KANBAN_VIEW) || {};
        return kanbanViews[controlId];
    }

    SetKanbanView(controlId: number, kanbanViewId: number) {
        const kanbanViews = Lockr.get(GROUPS.KANBAN_VIEW) || {};
        kanbanViews[controlId] = kanbanViewId;

        Lockr.set(GROUPS.KANBAN_VIEW, kanbanViews);
    }

    GetSettings() {
        var settings = Lockr.get(GROUPS.SETTINGS);
        return settings != undefined ? settings : {}
    }

    SetSettingsColor(color) {
        var value = this.GetSettings();
        value['color'] = color;
        Lockr.set(GROUPS.SETTINGS, value);
    }


    UservarsLastSync() {
        const globalValue = Number(GlobalManager.Instance.GetGlobal(GLOBALS.USER_VARS_UPDATE_TIME)) || 30;
        const syncItervalTime = globalValue * 60000;

        this.UpdateUserVars();

        this._syncInterval = setInterval(() => this.UpdateUserVars(), syncItervalTime);
    }

    SignOut() {
        clearInterval(this._syncInterval);
    }

    UpdateUserVars() {
        if (!CookieManager.GetAuthToken()) return;

        UserVarsStore.UpdateUserVars({'UserVariables': this.GetAll()}).then(result => {
            if (result && !result.IsSuccessfull) {
                new Notifier().Failed(result.ErrorMessage);
                return;
            }
            Lockr.set(GROUPS.LAST_SYNC_TIME, moment().toISOString());
        });
    }

    GetSettingsCustomThemeName() {
        return this.GetSettings()['customThemeName'];
    }

    GetSettingsCustomThemeColor() {
        return this.GetSettings()['customThemeColor'];
    }

    GetSettingsCustomColor() {
        return this.GetSettings()['customColor'];
    }

    GetSettingsLayoutMode() {
        return this.GetSettings()['style'];
    }

    GetSettingsSidebarBorders() {
        return this.GetSettings()['sidebarBorders'];
    }

    SetSettingsSidebarBorders(sidebarBorders) {
        var value = this.GetSettings();
        value['sidebarBorders'] = sidebarBorders;
        Lockr.set(GROUPS.SETTINGS, value);
    }

    SetSettingsLayoutMode(style) {
        var value = this.GetSettings();
        value['style'] = style;
        Lockr.set(GROUPS.SETTINGS, value);
    }

    SetSettingsCustomThemeName(customThemeName) {
        var value = this.GetSettings();
        value['customThemeName'] = customThemeName;
        Lockr.set(GROUPS.SETTINGS, value);
    }

    SetSettingsCustomThemeColor(customThemeColor) {
        var value = this.GetSettings();
        value['customThemeColor'] = customThemeColor;
        Lockr.set(GROUPS.SETTINGS, value);
    }

    SetSettingsCustomColor(customColor) {
        var value = this.GetSettings();
        value['customColor'] = customColor;
        Lockr.set(GROUPS.SETTINGS, value);
    }

    RestoreFromJson(jsonString: string) {
        var jsonObject = JSON.parse(jsonString);
        this.Clear();

        _.each(jsonObject, (objValue, key: string) => {
            Lockr.set(key, objValue);
        });
    }

    AddSelection(screenId: number, userGroups: Array<CheckedUserAndGroup>, users: SelectedUserType[], rememberMe: boolean) {
        const userSelectionData = this.GetUserSelections();
        let selectionByScreenId = _.find(userSelectionData, (selection: ISelection) => selection.ScreenId === screenId);

        const userSelection: IUserSelection = {
            Guid: Guid.NewGuid(),
            Users: users,
            UserGroups: userGroups,
            Checked: true
        };

        if (_.isEmpty(userSelectionData)) {
            const data: ISelection[] = [{
                RememberMe: rememberMe,
                ScreenId: screenId,
                UserSelection: [userSelection]
            }];

            Lockr.set(GROUPS.USER_SELECTION, data);
            return;
        }

        if (_.isEmpty(selectionByScreenId)) {
            selectionByScreenId = {
                RememberMe: rememberMe,
                ScreenId: screenId,
                UserSelection: [userSelection]
            };

            userSelectionData.push(selectionByScreenId);
            Lockr.set(GROUPS.USER_SELECTION, userSelectionData);
            return;
        }

        selectionByScreenId.UserSelection.unshift(userSelection);

        if (selectionByScreenId.UserSelection.length > 10) {
            selectionByScreenId.UserSelection.pop();
        }

        Lockr.set(GROUPS.USER_SELECTION, userSelectionData);
    }

    GetUserSelections(): ISelection[] {
        return <ISelection[]>Lockr.get(GROUPS.USER_SELECTION);
    }

    GetUserSelection(screenId: number): ISelection {
        const allSelections = this.GetUserSelections();
        let selection = _.find(allSelections, (selection: ISelection) => selection.ScreenId === screenId);
        if(selection){
            selection.UserSelection = _.filter(selection.UserSelection, (selection) => selection != null);
        }
        return selection;
    }

    SetUserSelection(screeId: number, userSelectionData: ISelection[]) {
        Lockr.set(GROUPS.USER_SELECTION, userSelectionData);
    }

    SetRememberUserSelection(screenId: number, rememberMe: boolean) {
        const userSelectionsData = this.GetUserSelections();
        let currentSelection = _.find<ISelection>(userSelectionsData, selection => selection.ScreenId === screenId);

        if (currentSelection) {
            currentSelection.RememberMe = rememberMe;
            Lockr.set(GROUPS.USER_SELECTION, userSelectionsData);
        } else {
            const data: ISelection[] = [{
                RememberMe: rememberMe,
                ScreenId: screenId,
                UserSelection: []
            }];
            Lockr.set(GROUPS.USER_SELECTION, data);
        }
    }

    AddRecent(entityId: number, recordId: number, typeId: number) {
        this.AddToGroup(recordId, entityId, typeId, GROUPS.RECENT);
    }

    RemoveFromRecent(recordId: number, entityId: number, tableTypeId: number) {
        this.RemoveFromGroup(recordId, entityId, tableTypeId, GROUPS.RECENT);
    }

    private AddToGroup(recordId: number, entityId: number, typeId: number, group) {
        if (!typeId) {
            typeId = 0;
        }

        if (!entityId || !recordId || recordId < 1) {
            var notifier = new Notifier(null);
            notifier.Failed(NOTIFICATIONS.WRONG_ARGS);
            return;
        }

        var groupValues = <any>Lockr.get(group);

        var data = [{
            'entityId': entityId,
            'records': [{
                'id': recordId,
                'typeId': typeId
            }]
        }];

        if (!groupValues || groupValues.length === 0) {
            Lockr.set(group, data);
        } else {
            let entityPair = _.findWhere(groupValues, {
                'entityId': entityId
            });

            if (entityPair) {
                let newGroup = _.filter(groupValues, pair => (pair['entityId'] !== entityId));

                entityPair['records'] = _.filter(entityPair['records'],
                    recordIdItem => (recordIdItem['id'] !== recordId));

                let recordData = {
                    'id': recordId,
                    'typeId': typeId
                }

                entityPair['records'].push(recordData);

                if (entityPair['records'].length > this.GetMaxLimitConst(group)) {
                    entityPair['records'].shift();
                }

                newGroup.push(entityPair);

                Lockr.set(group, newGroup);
            } else {
                groupValues = groupValues.concat(data[0]);
                Lockr.set(group, groupValues);
            }
        }
    }

    GetMaxLimitConst(group) {
        switch (group) {
            case GROUPS.RECENT:
            case GROUPS.FAVORITES:
                return 10;
            default:
                return Infinity;
        }
    }

    Clear(): void {
        _.each(_.values(GROUPS), value => {
            Lockr.rm(value);
        });
    }

    IsInFavorites(
        recordId: number,
        entityId: number,
        tableTypeId: number): boolean {
        return this.IsInGroup(recordId, entityId, tableTypeId, GROUPS.FAVORITES);
    }

    RemoveFromFavorites(
        recordId: number,
        entityId: number,
        tableTypeId: number) {
        this.RemoveFromGroup(recordId, entityId, tableTypeId, GROUPS.FAVORITES);
    }

    AddToFavorites(
        recordId: number,
        entityId: number,
        tableTypeId: number) {
        this.AddToGroup(recordId, entityId, tableTypeId, GROUPS.FAVORITES);
    }

    private IsInGroup(recordId: number, entityId: number, tableTypeId: number, group: string): boolean {
        var groupData = <any>Lockr.get(group);

        var entityData = _.findWhere(groupData, {'entityId': entityId});

        if (entityData) {
            let result = _.findWhere(entityData['records'], {'id': recordId});
            return !_.isEmpty(result);
        }
        return false;
    }

    private RemoveFromGroup(recordId: number, entityId: number, tableTypeId: number, group: string): void {
        var groupData = <any>Lockr.get(group);

        if (!groupData || !entityId || !recordId) {
            return;
        }

        var entityRecordUpdated = _.findWhere(groupData, {'entityId': entityId});

        if (!entityRecordUpdated) {
            return;
        }

        groupData = _.filter(groupData,
            entityRecord => (entityRecord['entityId'] !== entityId));

        entityRecordUpdated['records'] = _.filter(entityRecordUpdated['records'],
            recordData => (recordData['id'] !== recordId));

        if (!_.isEmpty(entityRecordUpdated['records'])) {
            groupData.push(entityRecordUpdated);
        }

        Lockr.set(group, groupData);
    }

    GetPathRunnerSettings(place: string): IPathRunnerSettings {
        const groupData = Lockr.get(GROUPS.PATH_RUNNER_SETTINGS) || {};

        return groupData[place];
    }

    SetPathRunnerSettings(settings: IPathRunnerSettings) {
        const groupData = Lockr.get(GROUPS.PATH_RUNNER_SETTINGS) || {};

        groupData[settings.Place] = settings;

        Lockr.set(GROUPS.PATH_RUNNER_SETTINGS, groupData);
    }

    GetBasket(basketEntityId: number, gridEntityId: number) {
        const groupData = <any>(Lockr.get(GROUPS.BASKET) || []);
        var basket = _.find(groupData, (item: any) => {
            return item.basketEntityId === basketEntityId && item.gridEntityId === gridEntityId;
        });
        if (basket) {
            return basket.recordId;
        }
        return null;
    }

    SaveBasket(basketEntityId: number, basketRecordId: number, gridEntityId: number) {
        const groupData = <any>(Lockr.get(GROUPS.BASKET) || []);
        var basket = _.find(groupData, (item: any) => {
            return item.basketEntityId === basketEntityId && item.gridEntityId === gridEntityId;
        });

        if (basket) {
            basket.recordId = basketRecordId;
        } else {
            groupData.push({basketEntityId: basketEntityId, gridEntityId: gridEntityId, recordId: basketRecordId});
        }
        Lockr.set(GROUPS.BASKET, groupData);
    }

    SetGridView(controlId: number, tableViewId: number) {
        let gridView = Lockr.get(GROUPS.GRID_VIEW);
        if (!gridView) {
            gridView = {};
        }

        if (!gridView[controlId]) {
            gridView[controlId] = {};
        }

        gridView[controlId] = tableViewId;
        Lockr.set(GROUPS.GRID_VIEW, gridView);
    }

    GetGridView(controlId: number): number {
        let gridView = Lockr.get(GROUPS.GRID_VIEW);
        if (gridView) {
            return gridView[controlId];
        }

        return 0;
    }


    GetLinkEditorGridView(key: string): number {
        let gridView = Lockr.get(GROUPS.GRID_VIEW);
        if (gridView) {
            return gridView[key];
        }

        return 0;
    }

    SetLinkEditorGridView(key: string, tableViewId: number) {
        let gridView = Lockr.get(GROUPS.GRID_VIEW);
        if (!gridView) {
            gridView = {};
        }

        if (!gridView[key]) {
            gridView[key] = {};
        }

        gridView[key] = tableViewId;
        Lockr.set(GROUPS.GRID_VIEW, gridView);
    }

    SetKanbanBoardAccordionGroup(controlId: number, entityId: number, recordId: number, ariaExpanded: boolean) {
        let accordionGroups: [IKanbanBoardAccordionGroups] = Lockr.get(GROUPS.KANBAN_BOARD_ACCORDION_GROUPS);

        if (!accordionGroups) {
            accordionGroups = [{
                controlId,
                entity: {
                    id: entityId,
                    records: [{
                        id: recordId,
                        expanded: ariaExpanded
                    }]
                },
            }];
        }

        let accordionGroup = accordionGroups.find(group => group.controlId === controlId && group.entity.id == entityId);
        if (!accordionGroup) {
            accordionGroup = {controlId, entity: {id: entityId, records: [{id: recordId, expanded: ariaExpanded}]}}
            accordionGroups.push(accordionGroup);
            Lockr.set(GROUPS.KANBAN_BOARD_ACCORDION_GROUPS, accordionGroups);
            return;
        }

        let accordionRecord = accordionGroup.entity.records.find(record => record.id === recordId);
        if (!accordionRecord) {
            accordionRecord = {id: recordId, expanded: ariaExpanded};
            accordionGroup.entity.records.push(accordionRecord);
            Lockr.set(GROUPS.KANBAN_BOARD_ACCORDION_GROUPS, accordionGroups);
            return;
        }

        accordionRecord.expanded = ariaExpanded;
        Lockr.set(GROUPS.KANBAN_BOARD_ACCORDION_GROUPS, accordionGroups);
    }

    GetKanbanBoardAccordionGroup(controlId: number, entityId: number, recordId: number, defaultValue: boolean): boolean {
        let accordionGroups: [IKanbanBoardAccordionGroups] = Lockr.get(GROUPS.KANBAN_BOARD_ACCORDION_GROUPS);

        if (!accordionGroups) {
            return defaultValue;
        }

        let accordionGroup = accordionGroups.find(group => group.controlId === controlId && group.entity.id == entityId);
        if (!accordionGroup) {
            return defaultValue;
        }

        let accordionRecord = accordionGroup.entity.records.find(record => record.id === recordId);
        if (!accordionRecord) {
            return defaultValue;
        } else {
            return accordionRecord.expanded;
        }
    }

    GetPlannerPageParams(controlId: number): IPlannerPageParams {
        let params = Lockr.get(GROUPS.PLANNER_PAGE);
        if (params && params[controlId]) {
            return params[controlId];
        }

        return {
            StartDate: null,
            ViewType: null,
            Period: null,
            ProjectManagerId: null,
            IsWeekPlannnig: null
        };
    }

    SetPlannerPageParams(controlId: number, pageParams: IPlannerPageParams) {
        let params = Lockr.get(GROUPS.PLANNER_PAGE);
        if (!params) {
            params = {};
        }

        if (!params[controlId]) {
            params[controlId] = {};
        }

        params[controlId] = pageParams;
        Lockr.set(GROUPS.PLANNER_PAGE, params);
    }

    GetPlannerLevelParams(controlId: number): IPlannerLevelParams {
        let params = Lockr.get(GROUPS.PLANNER_LEVEL);
        if (params && params[controlId]) {
            return params[controlId];
        }

        return {
            ExpandedBarIds: []
        };
    }

    SetPlannerLevelParams(controlId: number, levelParams: IPlannerLevelParams) {
        let params = Lockr.get(GROUPS.PLANNER_LEVEL);
        if (!params) {
            params = {};
        }

        if (!params[controlId]) {
            params[controlId] = {};
        }

        params[controlId] = levelParams;
        Lockr.set(GROUPS.PLANNER_LEVEL, params);
    }

    SetGridPriority(subjectEntityId: number, gridSubjectEntityId: number, priority: boolean) {
        let gridView = Lockr.get(GROUPS.GRID_PRIORITY);
        if (!gridView) {
            gridView = {};
        }

        if (!gridView[subjectEntityId]) {
            gridView[subjectEntityId] = {};
        }

        gridView[subjectEntityId][gridSubjectEntityId] = priority;
        Lockr.set(GROUPS.GRID_PRIORITY, gridView);
    }

    GetGridPriority(subjectEntityId: number, gridSubjectEntityId: number, defaultValue: boolean): boolean {
        const gridView = Lockr.get(GROUPS.GRID_PRIORITY);
        if (gridView) {
            if (gridView[subjectEntityId]) {
                return !!gridView[subjectEntityId][gridSubjectEntityId];
            }
        }

        return defaultValue;
    }

    SetSchedulerView(controlId: number, view: string) {
        let schedulerView = Lockr.get(GROUPS.SCHEDULER_VIEW) || {};
        schedulerView[controlId] = view;
        Lockr.set(GROUPS.SCHEDULER_VIEW, schedulerView);
    }

    GetSchedulerView(controlId: number): string {
        let schedulerView = Lockr.get(GROUPS.SCHEDULER_VIEW) || {};
        return schedulerView[controlId];
    }

    SetSchedulerSubjectToggle(controlId: number, value: boolean) {
        let schedulerView = Lockr.get(GROUPS.SCHEDULER_SUBJECT) || {};
        schedulerView[controlId] = value;
        Lockr.set(GROUPS.SCHEDULER_SUBJECT, schedulerView);
    }

    GetSchedulerSubjectToggle(controlId: number): boolean {
        let schedulerView = Lockr.get(GROUPS.SCHEDULER_SUBJECT) || {};
        return schedulerView[controlId];
    }

    SetSchedulerDate(controlId: number, value: string) {
        let schedulerDate = Lockr.get(GROUPS.SCHEDULER_DATE) || {};
        schedulerDate[controlId] = value;
        Lockr.set(GROUPS.SCHEDULER_DATE, schedulerDate);
    }

    GetSchedulerDate(controlId: number): string {
        let schedulerDate = Lockr.get(GROUPS.SCHEDULER_DATE) || {};
        return schedulerDate[controlId];
    }

    SetAdvancedLifestatus(value: boolean) {
        Lockr.set(GROUPS.LIFE_STATUS_ADVANCED_MODE, value);
    }

    GetAdvancedLifestatus(): boolean {
        return Lockr.get(GROUPS.LIFE_STATUS_ADVANCED_MODE) || false;
    }

    SetAgendaDate(controlId: number, value: string) {
        let agendaDate = Lockr.get(GROUPS.AGENDA_DATE) || {};
        agendaDate[controlId] = value;
        Lockr.set(GROUPS.AGENDA_DATE, agendaDate);
    }

    GetAgendaDate(controlId: number): string {
        let agendaDate = Lockr.get(GROUPS.AGENDA_DATE) || {};
        return agendaDate[controlId];
    }
}