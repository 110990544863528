import * as ko from "knockout";
import * as _ from 'underscore';
import * as moment from 'moment';

import {GenericDeserialize} from "libs/cerialize";
import {Notifier} from "Core/Common/Notifier";
import {P} from "Core/Common/Promise";

import {RecordStore} from "Core/Common/Stores/RecordStore";
import {BaseControl, IControlValue} from "Core/Controls/BaseControl/BaseControl";
import {SubForm} from "Core/Screens/Forms/SubForm/SubForm";
import {IControlParam, ISearchScreenParam} from "Core/Screens/IScreen";
import {CONTROL_TYPES, FIELD_TYPES, FONT_STYLES, RenderModes, TABLE_TYPES} from 'Core/Constant';
import {GlobalManager, GLOBALS} from 'Core/GlobalManager/GlobalManager';
import {SearchScreen} from 'Core/Screens/SearchScreen/SearchScreen';
import {EditScreen} from 'Core/Screens/EditScreen/EditScreen';
import {TypeScreen} from "Core/Screens/TypeScreen/TypeScreen";
import {BlockUI} from 'Core/Common/BlockUi';

import {RadioButtonDataStore} from "Core/Controls/RadioButton/Stores/RadioButtonDataStore";

import {RadioButtonOptionsModel} from "Core/Controls/RadioButton/Models/Data/RadioButtonOptionsModel";
import {RadioButtonOptionViewModel} from "Core/Controls/RadioButton/Models/View/RadioButtonOptionViewModel";

import {LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";
import {SERVER_REQUEST_ERRORS} from "Core/Common/Enums/ServerRequestErrors";
import {FormatConverter} from 'FormatEditor/FormatConverter';

import EditTemplate from "Core/Controls/RadioButton/Templates/Edit.html";
import HelpViewTemplate from "Core/Controls/RadioButton/Templates/HelpView.html";
import ViewTemplate from "Core/Controls/RadioButton/Templates/View.html";
import ToolBarTemplate from "Core/Controls/RadioButton/Templates/ToolBar.html";
import DesignTemplate from "Core/Controls/RadioButton/Templates/Design.html";
import SearchTemplate from 'Core/Controls/RadioButton/Templates/Search.html';
import RadioButtonTemplate from 'Core/Controls/RadioButton/Templates/RadioButton.html';
import {ScreenTypes} from "../../Common/Enums/ScreenTypes";
import {IGetRadioButtonControlDataRequestModel} from './Models/Request/IGetRadioButtonControlDataRequestModel';
import {Guid} from "Core/Common/Guid";

ko.templates["Core/Controls/RadioButton/Templates/Edit"] = EditTemplate;
ko.templates["Core/Controls/RadioButton/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/RadioButton/Templates/HelpView"] = HelpViewTemplate;
ko.templates["Core/Controls/RadioButton/Templates/ToolBar"] = ToolBarTemplate;
ko.templates["Core/Controls/RadioButton/Templates/Design"] = DesignTemplate;
ko.templates['Core/Controls/RadioButton/Templates/Search'] = SearchTemplate;
ko.templates['Core/Controls/RadioButton/Templates/RadioButton'] = RadioButtonTemplate;

export class RadioButton extends BaseControl {
    private _originalValue: any;
    private _value: KnockoutObservable<any>;
    private _subjectRecordId: number;
    private _isItemsEmpty: KnockoutObservable<boolean>;
    private _isValueSet: KnockoutObservable<boolean>;
    private _labelStyle: KnockoutObservable<any>;
    Items: KnockoutObservableArray<RadioButtonOptionViewModel>;
    private _showSearchScreenButton: KnockoutObservable<boolean>;
    private _items: KnockoutObservableArray<RadioButtonOptionViewModel>;
    private _selectedItem: KnockoutObservable<RadioButtonOptionViewModel>;
    private _selectedValueId: KnockoutObservable<number>;
    private _selectedValueLabel: KnockoutObservable<string>;
    private _searchScreenIfRecordsCount: number;
    private _searchScreeen: SearchScreen;
    private _totalRecordsCount: number;
    private _searchTerm: string;
    private _isEnableFilter: KnockoutObservable<boolean>;
    private _clearAllowed: KnockoutObservable<boolean>;
    private _isReady: KnockoutObservable<boolean>;
    protected _guid: string;

    constructor(params: IControlParam) {
        super(params);
        this._isItemsEmpty = ko.observable(false);
        this.Items = ko.observableArray([]);
        this._value = ko.observable(null);
        this._guid = Guid.NewGuid();
        this._isValueSet = ko.observable(false);
        this._labelStyle = ko.observable(null);
        this._showSearchScreenButton = ko.observable(false);
        this._selectedItem = ko.observable(null);
        this._selectedValueId = ko.observable(null);
        this._selectedValueLabel = ko.observable(null);
        this._clearAllowed = ko.observable(false);
        this._totalRecordsCount = 0;
        this._searchTerm = '';
        this._isEnableFilter = ko.observable(true);
        this._isReady = ko.observable(true);
        var searchScreenIfRecordsCount = parseInt(GlobalManager.Instance.GetGlobal(GLOBALS.SEARCH_SCREEN_IF_RECORDS_COUNT));
        this._searchScreenIfRecordsCount = Number.isNaN(searchScreenIfRecordsCount) ? 10 : searchScreenIfRecordsCount;
        this.InitChangeEvent();
        if (this._form && (this._form.GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.LinkEditor])) {
            this.ApplyLinkEditorStyles();
        }
    }

    ApplyProperties(){}

    GetRadioButtonTemplate() {
        return RadioButtonTemplate;
    }

    GetSearchTemplate() {
        return SearchTemplate;
    }

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);
    }

    AfterSearchTemplateRender() {
        this._el.dispatchEvent(
            new CustomEvent('resizeTable', {
                bubbles: true,
                cancelable: true
            })
        );
    }

    InitChangeEvent() {
        this._value.subscribe((newValue) => {
            var value = newValue ? String(newValue) : null;
            this.UpdateVariable({ Field: this.GetFieldModel(), ControlType: CONTROL_TYPES.RadioButton }, value);
        });
    }

    ResetSelection(data) {
        this._isReady(false);
        const recordId = 0;

        this._selectedItem(null);
        this._selectedValueId(null);
        this._selectedValueLabel(null);
        this._value(null);

        this._form && this.GetForm().GetScreen().UpdateDependsOnValues(recordId, this.FieldModel.Id);
        this._isReady(true);
    }


    ShowSearchScreen(model, evt) {
        if (this._totalRecordsCount > this._searchScreenIfRecordsCount) {
            let attachedFieldModel = this.FieldModel;
            if (attachedFieldModel) {
                let params: ISearchScreenParam = {
                    EntityId: attachedFieldModel.ValTableId,
                    SearchTerm: this._searchTerm,
                    EntityType: attachedFieldModel.ValTableType,
                    SearchFieldId: attachedFieldModel.ValFieldId,
                    ControlId: this._isEnableFilter() ? this.GetControlId() : 0,
                    ScreenData: this.GetDynamicFieldsData(),
                    ButtonAdd: false
                };

                if (this._form && this.FieldModel.ValTableType === TABLE_TYPES.Entity) {
                    params = _.extend(params, {
                        SubjectEntityId: this.GetForm().GetScreen().GetEntityId(),
                        SubjectRecordId: this.GetForm().GetScreen().GetRecordId()
                    });
                }

                this._searchScreeen = new SearchScreen(params);
                this._searchScreeen.On('RECORD_SELECTED', this, (evtArgs) => {
                    const recordId = evtArgs.data.RecordId;
                    this.ReplaceSelectedValue(recordId);
                });
                this._searchScreeen.Show();
            }
        }
    }

    private ApplyLinkEditorStyles() {
        if (!this.FieldModel.HasLinkEditorVisibility) {
            return;
        }
        const labelStyle = {
            color: null,
            fontWeight: null,
            fontStyle: null,
            textDecoration: null
        };
        if (this.FieldModel.FontColor) {
            labelStyle.color = this.FieldModel.FontColor;
        }
        labelStyle.fontWeight = FONT_STYLES.NORMAL; //default fontWeight

        if (this.FieldModel.FontStyles) {
            _.forEach(this.FieldModel.FontStyles, (style) => {

                switch (style.Name.toLowerCase()) {
                    case FONT_STYLES.BOLD:
                        labelStyle.fontWeight = FONT_STYLES.BOLD;
                        break;
                    case FONT_STYLES.UNDERLINE:
                        labelStyle.textDecoration = FONT_STYLES.UNDERLINE;
                        break;
                    case FONT_STYLES.ITALIC:
                        labelStyle.fontStyle = FONT_STYLES.ITALIC;
                        break;
                }
            })
        }

        this.Extend(labelStyle, this._labelStyle());
        this._labelStyle(labelStyle);
    }

    private ReplaceSelectedValue(recordId: number) {
        const valFieldId = this.FieldModel.ValFieldId;
        if (this._el) {
            BlockUI.Block({Target: this._el});
        }

        RecordStore.GetRecord({TableId: this.FieldModel.ValTableId, RecordId: recordId})
            .always(() => {
                BlockUI.Unblock(this._el);
                this._el.dispatchEvent(
                    new CustomEvent('resizeTable', {
                        bubbles: true,
                        cancelable: true
                    })
                );
            })
            .then((record: any) => {
                this._selectedItem(null);
                this._selectedValueId(null);
                this._selectedValueLabel(null);

                const selected = _.find(record.Fields, (item: any) => item.FieldId === valFieldId);

                if (selected) {

                    selected.FieldValue = this.PrepareFormattedValue(selected.FieldValue, selected.FieldValue, selected.FieldTypeName);
                    const itemTranslation = _.find(selected.FieldValueTranslations, (item: any) => item.Selected);
                    selected.FieldValue = itemTranslation && itemTranslation.Value ? itemTranslation.Value : selected.FieldValue;

                    const newItem = RadioButtonOptionViewModel.Create(recordId, selected.FieldValue ? selected.FieldValue : LABELS.EMPTY_VALUE, true, true);
                    this._selectedItem(newItem);
                    this._selectedValueId(newItem.Value);
                    this._selectedValueLabel(newItem.Text);
                    this._value(newItem.Value);

                    this._form && this.GetForm().GetScreen().UpdateDependsOnValues(recordId, this.FieldModel.Id);
                }
            })
            .fail((error) => {
                if (error.requestError === SERVER_REQUEST_ERRORS.NOT_FOUND) {
                    const newItem = RadioButtonOptionViewModel.Create(recordId, NOTIFICATIONS.RECORD_NOT_FOUND, true, true);

                    this._selectedItem(newItem);
                    this._selectedValueId(newItem.Value);
                    this._selectedValueLabel(newItem.Text);
                    this._value(newItem.Value);

                    this.Items().push(newItem);
                    this.Items(this.Items());

                } else if (error.requestError === SERVER_REQUEST_ERRORS.INTERNAL) {
                    new Notifier().Failed('Error applying created record');
                }
            });
    }

    private PrepareFormattedValue(value, displayValue, type) {
        let result = displayValue;
        if (this.IsDate(type)) {
            const datetimeFormat = FormatConverter.GetDateFormatFromFieldModel(
                {Type: type, FormatName: this.FieldModel.ValFieldFormatName},
                true
            );

            if (this.HasTime(type))
                value = FormatConverter.CorrectTimezone(value);

            result = moment(value).isValid() ? moment(value).format(datetimeFormat) : value;
        } else if (_.contains([FIELD_TYPES.Decimal, FIELD_TYPES.Integer], type)) {
            result = FormatConverter.LocalizeDecimalOrInteger(
                displayValue && this.FieldModel.ValFieldFormatName === 'Percentage'
                    ? (Number(displayValue.replace(/[.,]/g, '.')) * 100).toFixed(
                    this.FieldModel.ValFieldSize < 2 ? 0 : this.FieldModel.ValFieldSize - 2
                    )
                    : displayValue
            );
        }

        return result ? result : "";
    }

    private IsDate(fieldType: string) {
        return fieldType === FIELD_TYPES.Date ||
            fieldType === FIELD_TYPES.DateTime ||
            fieldType === FIELD_TYPES.Time ||
            fieldType === FIELD_TYPES.TimeSpan;
    }

    private HasTime(fieldType: string) {
        return fieldType === FIELD_TYPES.DateTime ||
            fieldType === FIELD_TYPES.Time;
    }

    private LoadData(recordId: number): P.Promise<any> {
        let attachedField = this.FieldModel;
        let deferredResult = P.defer();


        this._clearAllowed(!(attachedField.IsRequired || attachedField.IsReadOnly || attachedField.IsSystem));

        if (attachedField) {
            if (this._el) {
                BlockUI.Block({Target: this._el});
            }

            const getDataRequestModel: IGetRadioButtonControlDataRequestModel = {
                FieldId: attachedField.Id,
                ControlId: this.GetControlId()
            };

            const screen = this._form && this.GetForm().GetScreen();

            if (screen && screen.GetType() === ScreenTypes[ScreenTypes.LinkEditor]) {
                const linkEditor = screen as any;

                getDataRequestModel.LeftEntityId = linkEditor.GetEntityId();
                getDataRequestModel.RightEntityId = linkEditor.GetRelatedEntityId();
                getDataRequestModel.LeftRecordId = linkEditor.GetRecordId();
                getDataRequestModel.RightRecordId = linkEditor.GetRelatedRecordId();
                getDataRequestModel.Kseq = linkEditor.GetKseq();
            }

            RadioButtonDataStore.GetOptions(getDataRequestModel)
                .always(() => {
                    BlockUI.Unblock(this._el);
                })
                .then((dataModel: RadioButtonOptionsModel) => {
                    this._totalRecordsCount = dataModel.RecordsCount;
                    this._showSearchScreenButton(this._totalRecordsCount > this._searchScreenIfRecordsCount);
                    let options = dataModel.Items.map(option =>
                        new RadioButtonOptionViewModel({
                            Text: option.Label ? option.Label : LABELS.EMPTY_VALUE,
                            Value: option.RecordId
                        }));

                    if (!attachedField.IsRequired && !attachedField.IsSystem && !attachedField.IsReadOnly) {
                        options.push(new RadioButtonOptionViewModel({
                            Text: LABELS.SELECT_LABEL + '...',
                            Value: null
                        }));
                    }

                    this.Items(options);

                    deferredResult.resolve({});
                }).fail(error => {
                new Notifier().Failed(error.message);
                deferredResult.reject(error);
            });
        } else {
            deferredResult.reject({message: "Relation is not setup"});
        }

        return deferredResult.promise();
    }

    SetValue(value: IControlValue): void {
        this._isValueSet(true);

        let selectedValue = null;
        if (value.Data && isNaN(parseInt(value.Data.Value))) {
            selectedValue = null;
        } else {
            selectedValue = parseInt(value.Data.Value);
        }

        let recordId = value.SubjectRecordId;
        if (!this.ItemsAreLoaded()) {
            this.LoadData(recordId).then(() => {
                this._originalValue = selectedValue;
                this._value(selectedValue);
                if (this.Items.length == 0 && selectedValue != null) {
                    this.ReplaceSelectedValue(selectedValue);
                }
            }).fail(() => this._isItemsEmpty(true));
        }
    }

    Deserialize() {
        let attachedField = this.FieldModel;

        if (attachedField) {
            if (!this.GetCombinedReadOnly()) {
                return [`${attachedField.EntityName}.${attachedField.Name}`, this._value() || ""];
            } else {
                return null;
            }
        }
        return null;
    }

    private ItemsAreLoaded(): boolean {
        return this.Items().length > 0;
    }

    IsValid(): boolean {
        if (this._isRequired) {
            if (this._value()) {
                this._isValid(true);
            } else {
                this._isValid(false);
            }
        } else {
            this._isValid(true);
        }
        return this._isValid();
    }

    GetValue() {
        if (this._value()) {
            return this._value();
        }
        return null;
    }

    GetDisplayValue(): string {
        if (this._value()) {
            const selectedValue = this.Items().find(item => item.Value == this._value());
            return selectedValue ? selectedValue.Text : this._selectedValueLabel();
        }
        return null;
    }

    IsModified(): boolean {
        let originalValue = this._originalValue,
            newValue = this._value();

        if (isNaN(originalValue))
            originalValue = 'valueNaN';

        if (isNaN(newValue))
            newValue = 'valueNaN';

        return super.IsModified() || originalValue != newValue;
    }
} 