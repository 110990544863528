import * as ko from 'knockout'
import * as _ from 'underscore';

import { BaseControl } from "Core/Controls/BaseControl/BaseControl";
import { IControlParam } from "Core/Screens/IScreen";
import { ScreenTypes } from 'Core/Common/Enums/ScreenTypes';
import { CHECKBOX_PROPERTIES } from "Core/GeneralProperties/Managers/CheckboxProperty/Constants";

import SpecialScreenButtonConfig from "Core/Controls/ButtonSpecialScreen/Configs/special-screen-button-config.json";

import DesignTemplate from "Core/Controls/ButtonSpecialScreen/Templates/Design.html";
import EditTemplate from "Core/Controls/ButtonSpecialScreen/Templates/View.html";
import ToolBarTemplate from "Core/Controls/ButtonSpecialScreen/Templates/Toolbar.html";
import ViewTemplate from "Core/Controls/ButtonSpecialScreen/Templates/View.html";
import HelpViewTemplate from "Core/Controls/ButtonSpecialScreen/Templates/HelpView.html";

ko.templates["Core/Controls/ButtonSpecialScreen/Templates/Design"] = DesignTemplate;
ko.templates["Core/Controls/ButtonSpecialScreen/Templates/Edit"] = EditTemplate;
ko.templates["Core/Controls/ButtonSpecialScreen/Templates/ToolBar"] = ToolBarTemplate;
ko.templates["Core/Controls/ButtonSpecialScreen/Templates/HelpView"] = HelpViewTemplate;
ko.templates["Core/Controls/ButtonSpecialScreen/Templates/View"] = ViewTemplate;

export class ButtonSpecialScreen extends BaseControl {

    private _config: any;

	constructor(params: IControlParam) {
		super(params, SpecialScreenButtonConfig);

		this._enabled = ko.observable(this.IsSpecialScreenExist || this.IsStepsScreenExist);
        this._style = ko.computed(() => {
            return {
                backgroundColor: this._backgroundColor(),
                color: this._color(),
                borderColor: this._borderColor(),
                borderWidth: this._border() ? '1px' : '0',
                padding: this._border() ? '6px 14px' : '7px 14px'
            };
        });
        this.ApplyProperties();
	}

    SetHideCaption(data: boolean) {
        this.HideCaption(this.HideCaptionFromProperty() ? this.HideCaptionFromProperty() : data);
    }

    GetHideCaption(): boolean {
        return this.HideCaption();
    }

    ApplyProperties() {
        if (this.Properties) {
            this.AssignProperty('Styling', 'BackgroundColor', this._backgroundColor);
            this.AssignProperty('Styling', 'TextColor', this._color);
            this.AssignProperty('Styling', 'Border', this._border);
            this.AssignProperty('Styling', 'BorderColor', this._borderColor);
            this.AssignProperty('Styling', CHECKBOX_PROPERTIES.HIDE_CAPTION, this.HideCaptionFromProperty);
            this.HideCaption(this.HideCaptionFromProperty());
        }
    }

    private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
        if (this.Properties[groupedBy]) {
            _.each(this.Properties[groupedBy].Properties,
                (property: any) => {
                    if (property.hasOwnProperty(propertyName)) {
                        propertyHolder(property[propertyName]);
                    }
                });
        }
    }

	OnClick() {
		if (this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
		} else if(this._enabled()) {
			const screen = this._form.GetScreen();
			const screenType = this.IsSpecialScreenExist ? ScreenTypes.SpecialScreen : ScreenTypes.StepsScreen;

			screen.Trigger("SHOW_SPECIAL_SCREEN", { ScreenType: screenType });
		}
	}
}