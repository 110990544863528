import * as ko from 'knockout';
import 'knockoutValidation';

export class KnockoutValidationExtensions {
	static Init() {
		ko.validation.rules['areSame'] = {
			getValue: o => typeof o === 'function' ? o() : o,
			validator: function (val, otherField) {
				return val === this.getValue(otherField);
			},
			message: 'The fields must have the same value'
		};
	}
}