import * as ko from "knockout";
import * as _ from 'underscore';

import {DEFAULT_ICONS, FIELD_TYPES, RenderModes, TABLE_TYPES} from "Core/Constant";
import {Icon} from "Core/Icon/Icon";

import {IControlParam} from "Core/Screens/IScreen";
import {IControlValue} from "Core/Controls/BaseControl/BaseControl";

import {ComplexControl} from "Core/Controls/ComplexControl/ComplexControl";
import {RequiredFieldModel} from "Core/Controls/ComplexControl/Models/RequiredFieldModel";

import {GeneralProperties} from 'Core/GeneralProperties/GeneralProperties';
import {IPropertyOption} from 'Core/GeneralProperties/Managers/BaseProperty';

import ProductConfiguratorConfig from 'Core/Controls/ProductConfigurator/Configs/product-configurator-config.json';

import {ProductConfigurator as ProductConfiguratorComponent} from 'Core/Components/Controls/ProductConfigurator/ProductConfigurator';

import ToolBarTemplate from "Core/Controls/ProductConfigurator/Templates/ToolBar.html";
import ViewTemplate from "Core/Controls/ProductConfigurator/Templates/View.html";
import HelpViewTemplate from "Core/Controls/ProductConfigurator/Templates/HelpView.html";

ko.templates['Core/Controls/ProductConfigurator/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/ProductConfigurator/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/ProductConfigurator/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/ProductConfigurator/Templates/Design'] = ComplexControl.designTemplate;

export class ProductConfigurator extends ComplexControl {
    private _component: ProductConfiguratorComponent;

    private _subjectEntityId: number;
    private _subjectRecordId: number;

    private _productsEntityId: number;
    private _rootProductsViewId?: number;
    private _descriptionFieldId?: number;

    private _config: any;

    Disabled: KnockoutObservable<boolean>;
    Tooltip: KnockoutObservable<string>;

    constructor(params: IControlParam) {
        super(params, ProductConfiguratorConfig);
        this._style = ko.computed(() => {
            return {
                backgroundColor: this._backgroundColor(),
                color: this._color(),
                borderColor: this._borderColor(),
                borderWidth: this._border() ? '1px' : '0'
            };
        });

        this.Disabled = ko.observable(false);
        this.Tooltip = ko.observable(null);

        this.Init();
    }

    SetValue(value: IControlValue): void {
        this._subjectEntityId = value.SubjectEntityId;
        this._subjectRecordId = value.SubjectRecordId;

        if (!this._subjectEntityId || !this._subjectRecordId) {
            this.Disabled(true);
            this.Tooltip('Subject record is required to open product configurator');
            return;
        }

        this._component = new ProductConfiguratorComponent({
            OrdersEntityId: this._subjectEntityId,
            ProductsEntityId: this._productsEntityId,
            RootProductsViewId: this._rootProductsViewId,
            DescriptionFieldId: this._descriptionFieldId,
            OrderId: this._subjectRecordId,
            ControlId: this.GetControlId(),
            Screen: this.Screen,
            General: {
                ReloadScreen: this.GetConfigurationBooleanPropertyValue('ReloadScreen')
            },
            HidePCFGROUPIfEmpty: {
                HiddenPCFGROUPSIfEmpty: this.GetHiddenPCFGROUPSIfEmpty('HidePCFGROUPIfEmpty')
            },
            ImageSizes: {
                RootProductsPage: {
                    ProductSelection: this.GetImageSize('ProductSelectionPopup')
                },
                StartPage: {
                    OrderedProduct: this.GetImageSize('OrderedProduct')
                },
                ConfigurationPage: {
                    SelectedComponent: this.GetImageSize('SelectedComponent'),
                    Tiles: this.GetImageSize('Tiles'),
                    InEx: this.GetImageSize('InExPopup')
                }
            },
            ConfigurationRoles: {
                Level1Value: this.GetConfigurationRoleValue('Level1'),
                Level2Value: this.GetConfigurationRoleValue('Level2'),
                LockLevel1: this.GetConfigurationBooleanPropertyValue('LockLevel1')
            },
            Annotations: {
                UseAnnotations: this.GetConfigurationAnnotationsValue('Annotations'),
                ShowAnnotationsModeByDefault: this.GetConfigurationAnnotationsValue('ShowAnnotations')
            },
            AlternativePrice: {
                EnableAlternativePriceCalculation: this.GetConfigurationAlternativePriceValue('EnableAlternativePriceCalculation'),
                AlternativePriceCalculationRule: this.GetConfigurationAlternativePriceValue('AlternativePriceCalculationRule')
            }
        });
    }

    ApplyProperties() {
        if (this.Properties) {
            this.AssignProperty('Styling', 'BackgroundColor', this._backgroundColor);
            this.AssignProperty('Styling', 'TextColor', this._color);
            this.AssignProperty('Styling', 'Border', this._border);
            this.AssignProperty('Styling', 'BorderColor', this._borderColor);
        }
    }

    private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
        if (this.Properties[groupedBy]) {
            _.each(this.Properties[groupedBy].Properties,
                (property: any) => {
                    if (property.hasOwnProperty(propertyName)) {
                        propertyHolder(property[propertyName]);
                    }
                });
        }
    }

    ShowConfigurator() {
        this._component.FlushNavigationStack();
        this._component.ShowStartPage();
    }

    private Init() {
        this.ApplyProperties();
        switch (this._renderMode()) {
            case RenderModes.Design:
                this.InitForDesignTime();
                return;

            case RenderModes.View:
            case RenderModes.Edit:
                this.InitForRuntime();
                return;
        }
    }

    private InitForDesignTime() {
        this.SetDefaultIcon(new Icon(DEFAULT_ICONS.ProductConfigurator));

        this._requiredFields([
            new RequiredFieldModel('NAME', FIELD_TYPES.Text, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('IMAGE', FIELD_TYPES.Image, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('PRICE', FIELD_TYPES.Decimal, TABLE_TYPES.Entity, null)
        ]);

        this.InitRequiredFields();
    }

    private InitForRuntime() {
        const attachedFields = this.Model.Fields;

        this._productsEntityId = attachedFields[0].EntityId;

        const lastField = attachedFields[attachedFields.length - 1];
        if (lastField.EntityTypeName === TABLE_TYPES.Link) {
            this._descriptionFieldId = lastField.Id;
            this._rootProductsViewId = attachedFields[attachedFields.length - 2].EntityTypeName === TABLE_TYPES.Sub && attachedFields[attachedFields.length - 2].EntityId;
        } else if (lastField.EntityTypeName === TABLE_TYPES.Sub) {
            this._rootProductsViewId = lastField.EntityId;
        }
    }

    private GetHiddenPCFGROUPSIfEmpty(hidePCFGROUPIfEmptyParam: string) {
        const propertyValue = this.GeneralProperties.GetPropertyValue(hidePCFGROUPIfEmptyParam);

        const hiddenPCFGROUPSIfEmpty = [];
        if (propertyValue) {
            const hiddenPCFGROUPSIfEmptyString = propertyValue.split(';');
            _.each(hiddenPCFGROUPSIfEmptyString, groupString => {
                if (groupString && !isNaN(Number(groupString))) {
                    hiddenPCFGROUPSIfEmpty.push(Number(groupString));
                }
            });
        }

        return hiddenPCFGROUPSIfEmpty;
    }

    private GetImageSize(typeName: string) {
        const propertyValue = this.GeneralProperties.GetPropertyValue(typeName);
        const value = propertyValue ? +propertyValue.Value : null;

        switch (value) {
            case 1:
                return 60;
            case 2:
                return 70;
            case 3:
                return 80;
            case 4:
                return 90;
            default:
                return 100;
        }
    }

    private GetConfigurationRoleValue(relationTypeValue: string) {
        const propertyValue = this.GeneralProperties.GetPropertyValue(relationTypeValue);

        return propertyValue ? propertyValue : null;
    }

    private GetConfigurationBooleanPropertyValue(booleanParam: string) {
        const propertyValue = this.GeneralProperties.GetPropertyValue(booleanParam);

        return !!propertyValue;
    }

    private GetConfigurationAnnotationsValue(annotationParam: string) {
        const propertyValue = this.GeneralProperties.GetPropertyValue(annotationParam);

        return propertyValue;
    }

    private GetConfigurationAlternativePriceValue(alternativePriceParam: string) {
        const propertyValue = this.GeneralProperties.GetPropertyValue(alternativePriceParam);

        return propertyValue;
    }
}