import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize} from 'libs/cerialize';
import {PlannerPageModel} from "../Models/PlannerPageModel";

export interface IGetPlannerPageDataRequestModel {
    ControlId: number;
    WeekNumber: number;
    StartDate: string;
    RecordsPerPage: number;
    PageNumber: number;
    ViewType: string;
    ManagerId?: number
    SubjectRecordId?: number;
}

export class PlannerPageStore {
    static GetData(params: IGetPlannerPageDataRequestModel): P.Promise<PlannerPageModel>{
        let deferredResult = P.defer<PlannerPageModel>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: 'api/PlannerApi/GetPlannerPageData',
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(result => {
                if(result.IsSuccessfull){
                    deferredResult.resolve( Deserialize(result.ResultObject, PlannerPageModel));
                }
            });

        return deferredResult.promise();
    }
}