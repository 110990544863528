import { States } from 'Core/Controls/Grid/BaseGrid/GridRow/States';
import {serialize, serializeAs} from 'libs/cerialize';

export class RecordKeyModel {
	@serialize
	FieldId: number;

	@serialize
	RecordId: number;
}

export class TranslationModel {
	@serialize
	LanguageShortName: string;

	@serialize
	Value: string;
}

export class FieldDataModel {
	@serialize
	EntityId: number;

	@serialize
	FieldId: number;

	@serialize
	Value: string;

	@serialize
	CustomValue: string;

	@serializeAs(RecordKeyModel)
	RecordKeys: Array<RecordKeyModel>;

	@serializeAs(TranslationModel)
	Translations: TranslationModel[];

	constructor() {
		this.RecordKeys = [];
	}
}

export class GridRowDataModel {
	@serialize
	Data: Array<FieldDataModel>;

	@serialize
	State: States;

	constructor() {
		this.Data = [];
	}

	@serialize
	SubjectEntityId: number;

	@serialize
	SubjectRecordId: number;

	@serialize
	EntityId: number;

	@serialize
	RecordId: number;

	@serialize
	KSeq: number;

	@serialize
	Guid: string;

	@serialize
	ReferenceLookupFieldId: number;

	@serialize
	ScopedTableId: number;

	@serialize
	ScopedRecordId: number;

	@serialize
	IsLinkParent: boolean;
}