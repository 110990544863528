export const PROPERTIES = {
	SETTINGS: 'Settings',
	CHART_TYPE: 'ChartType',
	CHART_TITLE: 'ChartTitle',
	X_AXIS: 'XAxis',
	X_AXIS_LABEL: 'XAxisLabel',
	Y_AXIS: 'YAxis',
	Y_AXIS_LABEL: 'YAxisLabel',
	SORT_BY: 'SortBy',
    SERIES: 'Series'
}