import {JournalsState} from "../Enums/JournalsState";
import {AccountsState} from "../Enums/AccountsState";

import { SourceTableDtoModel } from "../Models/Dto/Response/SourceTableDtoModel";
import { SourceTableTypeDtoModel } from "../Models/Dto/Response/SourceTableTypeDtoModel";
import { InvoicingControlDtoModel } from "../Models/Dto/Response/InvoicingControlDtoModel";
import { InvoicingResultsDtoModel, InvoicingResultDtoModel } from "../Models/Dto/Response/InvoicingResultsDtoModel";
import { FinancialProcessorRunnerDtoModel } from "../Models/Dto/Response/FinancialProcessorRunnerDtoModel";
import {LedgerDtoModel} from "../Models/Dto/Response/LedgerDtoModel";
import {AccountsDtoModel} from "../Models/Dto/Response/AccountsDtoModel";
import {SubAdministrationDtoModel} from "../Models/Dto/Response/SubAdministrationDtoModel";
import {ReadyForProcessingDataDtoModel} from "../Models/Dto/Response/ReadyForProcessingDataDtoModel";
import {FinancialProcessorDtoModel} from "../Models/Dto/Response/FinacialProcessorDtoModel";

import { SourceTableViewModel } from "../Models/View/SourceTableViewModel";
import { SourceTableTypeViewModel } from "../Models/View/SourceTableTypeViewModel";
import { InvoicingControlViewModel } from "../Models/View/InvoicingControlViewModel";
import { FinancialProcessorRunnerViewModel } from "../Models/View/FinancialProcessorRunnerViewModel";
import { InvoicingResultsViewModel, InvoicingResultViewModel } from "../Models/View/InvoicingResultsViewModel";
import {LedgerViewModel} from "../Models/View/LedgerViewModel";
import {AccountsViewModel} from "../Models/View/AccountsViewModel";
import {SubAdministrationViewModel} from "../Models/View/SubAdministrationViewModel";
import {ReadyForProcessingDataViewModel} from "../Models/View/ReadyForProcessingDataViewModel";
import {ReadyForInvoicingDataDtoModel} from "../Models/Dto/Response/ReadyForInvoicingDataDtoModel";
import {ReadyForInvoicingDataViewModel} from "../Models/View/ReadyForInvoicingDataViewModel";
import {FinancialProcessorViewModel} from "../Models/View/FinancialProcessorViewModel";

export class FinancialDashboardMappingProfile {
	static MapToSourceTableViewModel(dtoModel: SourceTableDtoModel): SourceTableViewModel {
		return new SourceTableViewModel(
			dtoModel.Id,
			dtoModel.Name,
			dtoModel.TranslatedName,
			dtoModel.Icon,
			dtoModel.Types.map(sourceTableType => this.MapToSourceTableTypeViewModel(sourceTableType, dtoModel.Id))
		);
	}

	static MapToSourceTableTypeViewModel(dtoModel: SourceTableTypeDtoModel, tableId: number): SourceTableTypeViewModel {
		return new SourceTableTypeViewModel(
			dtoModel.Id,
			dtoModel.Name,
			dtoModel.Controls.map(invoicingControl => this.MapToInvoicingControlViewModel(invoicingControl, tableId))
		);
	}

	static MapToInvoicingControlViewModel(dtoModel: InvoicingControlDtoModel, tableId: number): InvoicingControlViewModel {
		return new InvoicingControlViewModel(dtoModel.Id, tableId, dtoModel.Name, dtoModel.RecordsAmount);
	}

	static MapToFinancialProcessorRunner(dtoModel: FinancialProcessorRunnerDtoModel): FinancialProcessorRunnerViewModel {
		return new FinancialProcessorRunnerViewModel(dtoModel.Id, dtoModel.Name, dtoModel.RecordsAmount);
	}

	static MapToLedgerViewModel(dtoModel: LedgerDtoModel, journalsState: JournalsState, accountsState: AccountsState): LedgerViewModel {
		return new LedgerViewModel(
			this.MapToAccountsViewModel(dtoModel.Accounts),
			dtoModel.SubAdministrations.map(subAdministration => this.MapToSubAdministrationViewModel(subAdministration)),
			journalsState,
			accountsState
		);
	}

	static MapToAccountsViewModel(dtoModel: AccountsDtoModel): AccountsViewModel {
		return new AccountsViewModel(dtoModel.TableId, dtoModel.Icon);
	}

	static MapToSubAdministrationViewModel(dtoModel: SubAdministrationDtoModel): SubAdministrationViewModel {
		return new SubAdministrationViewModel(dtoModel.TableId, dtoModel.Name, dtoModel.Icon);
	}

	static MapToInvoicingResultsViewModel(dtoModel: InvoicingResultsDtoModel): InvoicingResultsViewModel {
		return new InvoicingResultsViewModel(
			dtoModel.Results.map(invoicingResult => this.MapToInvoicingResultViewModel(invoicingResult))
		);
	}

	static MapToInvoicingResultViewModel(dtoModel: InvoicingResultDtoModel): InvoicingResultViewModel {
		return new InvoicingResultViewModel(
			dtoModel.RecordId,
			dtoModel.RecordName,
			dtoModel.Result,
			dtoModel.IsSuccessfull
		);
	}

	static MapToReadyForInvoicingData(dtoModel: ReadyForInvoicingDataDtoModel): ReadyForInvoicingDataViewModel {
		return dtoModel;
	}

    static MapToReadyForProcessingData(dtoModel: ReadyForProcessingDataDtoModel): ReadyForProcessingDataViewModel {
        return dtoModel;
    }

    static MapToFinancialProcessor(dtoModel: FinancialProcessorDtoModel) {
		return new FinancialProcessorViewModel(dtoModel.Id, dtoModel.Name, dtoModel.TypeId);
	}
}