import * as ko from "knockout";
import * as _ from "underscore";

import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

import {Event} from "Core/Common/Event";
import {EVENTS} from "Core/Components/Paginator/Constants";
import {LABELS} from "Core/Components/Translation/Locales";

import Template from 'Core/Components/Paginator/Templates/Paginator.html';
import NextPrevTemplate from 'Core/Components/Paginator/Templates/NextPrevPaginator.html';

ko.templates['Core/Components/Paginator/Templates/Paginator'] = Template;
ko.templates['Core/Components/Paginator/Templates/NextPrevPaginator'] = NextPrevTemplate;

export class Paginator extends Event {
    private _title: KnockoutComputed<string>;

    private _currentPage: KnockoutObservable<number>;
    private _totalRecords: KnockoutObservable<number>;
    private _selectedPage: KnockoutObservable<string>;
    private _recordsPerPage: KnockoutObservable<number>;
    private _pagesCount: KnockoutObservable<number>;
    private _isReady: KnockoutObservable<boolean>;
    private _isSearch: KnockoutObservable<boolean>;

    private _enableNext: KnockoutComputed<boolean>;
    private _enablePrevious: KnockoutComputed<boolean>;
    private _titleWidth: KnockoutComputed<string>;
    private _hasFocus: KnockoutObservable<boolean>;
    private _nextPrevTemplate: KnockoutObservable<boolean>;
    private _skipChangeTrigger: KnockoutObservable<boolean>;
    private _updateTimeout: any;

    private _labels = LABELS;

    constructor(private tabindex: number = -1, nextPrevTemplate: boolean = false) {
        super();

        this._currentPage = ko.observable(1);
        this._pagesCount = ko.observable(1);
        this._totalRecords = ko.observable(0);
        this._selectedPage = ko.observable("");
        this._recordsPerPage = ko.observable(null);
        this._isReady = ko.observable(false);
        this._hasFocus = ko.observable(false);
        this._isSearch = ko.observable(false);
        this._nextPrevTemplate = ko.observable(nextPrevTemplate);
        this._skipChangeTrigger = ko.observable(false);

        this._title = ko.computed({
            owner: this,

            read: () => {
                let pagesCount = Math.ceil(this._totalRecords() / this._recordsPerPage());

                if (isNaN(pagesCount) || pagesCount === Infinity) {
                    pagesCount = 1;
                }

                this._pagesCount(pagesCount);
                this._isReady(pagesCount !== 0 && pagesCount !== 1);

                return ` ${this._currentPage()} ${this._labels.OF} ${pagesCount} `;
            }
        });

        this._titleWidth = ko.computed({
            owner: this,
            read: () => `${(this._title().length + 1) * 7}px`
        });

        this._enableNext = ko.computed({
            owner: this,
            read: () => this._currentPage() < this._pagesCount()
        });

        this._enablePrevious = ko.computed({
            owner: this,
            read: () => this._currentPage() > 1
        });

        this._currentPage.subscribe(() => {
            this.DelayedUpdate();
        });

        this.AddEvent(EVENTS.CHANGE);
    }

    DelayedUpdate() {
        if (this._updateTimeout) clearTimeout(this._updateTimeout);

        if (this.SkipChangeTrigger) {
            return;
        }

        this._updateTimeout = setTimeout(() => {
            this.ChangeTrigger();
        }, 500);
    }

    GetIsReady(): boolean {
        return this._isReady();
    }

    set TotalRecords(value: number) {
        this._totalRecords(value);
    }

    @enumerable get TotalRecords(): number {
        return this._totalRecords();
    }

    @enumerable get PageNumber(): number {
        return this._currentPage();
    }

    set PageNumber(page: number) {
        this._currentPage(page);
    }

    @enumerable get SkipChangeTrigger(): boolean {
        return this._skipChangeTrigger();
    }

    set SkipChangeTrigger(shouldSkip: boolean) {
        this._skipChangeTrigger(shouldSkip);
    }

    @enumerable get RecordsPerPage(): number {
        return this._recordsPerPage();
    }

    set RecordsPerPage(value: number) {
        this._recordsPerPage(value);
    }

    NavigationButtonClick(navigationType) {
        if (this._enablePrevious()) {
            if (navigationType === 'firstPage') {
                this._currentPage(1);
            }

            if (navigationType === 'previousPage') {
                this._currentPage(this._currentPage() - 1);
            }
        }

        if (this._enableNext()) {
            if (navigationType === 'nextPage') {
                this._currentPage(this._currentPage() + 1);
            }

            if (navigationType === 'lastPage') {
                this._currentPage(this._pagesCount());
            }
        }

        this._selectedPage("");
    }

    ChangeTrigger() {
        this.Trigger(EVENTS.CHANGE, {pageNumber: this._currentPage(), recordsPerPage: this._recordsPerPage()});
    }

    GetTemplateName() {
        return 'Core/Components/Paginator/Templates/Paginator';
    }

    GetNextPrevTemplateName(){
        return 'Core/Components/Paginator/Templates/NextPrevPaginator';
    }

    EnterKey() {
        let selectedPage = parseInt(this._selectedPage());

        if (!isNaN(selectedPage) && Math.abs(selectedPage) <= this._pagesCount()) {
            selectedPage = selectedPage === 0 ? 1 : Math.abs(selectedPage);
            this._currentPage(selectedPage);
            this._hasFocus(false);
        }

        this._selectedPage("");
    }

    Reset() {
        this._currentPage(1);
    }
}