import * as ko from "knockout"
import * as tippy from "tippy";


export class TippyExtention {
    static Init() {

        ko.bindingHandlers.tippy = {
            init: (element, valueAccessor) => {
                var options = valueAccessor();
                (tippy as any)(element, options);
            }
        };
    }
}