import {deserialize} from "libs/cerialize";

export class RegisteredLock {
	@deserialize
	TableId: number;

	@deserialize
	RecordId: number;

	@deserialize
	PulseDate: string;
}