import * as _ from 'underscore';

export class FCRequiredFields {
	static CREATE_DATE = "CREATEDATE";
	static CREATED_BY = "CREATEDBY";
	static CHANGE_DATE = "CHANGEDATE";
	static CHANGED_BY = "CHANGEDBY";
	static F_TYPE = "F_TYPE";
	static F_LIFESTATUS = "F_LIFESTATUS";
	static LAST_STATUS_DATE = "LASTSTATUSDATE";
	static F_OWNER = "F_OWNER";

	static GetAll() {
		return [
			this.CREATE_DATE,
			this.CREATED_BY,
			this.CHANGE_DATE,
			this.CHANGED_BY,
			this.F_TYPE,
			this.F_LIFESTATUS,
			this.LAST_STATUS_DATE,
			this.F_OWNER
		];
	}

	static IsRequired(fieldName: string){
		return _.contains(this.GetAll(), fieldName);
	}
}