import { Guid } from "Core/Common/Guid";
import { CollectionModel } from "DatabaseDesigner/Models/CollectionModel";

export function LinkEditorTabSettingsModel() {
    this._guid = Guid.NewGuid();

    this._leftTypeId = null;
    this._leftTypeGuid = null;

    this._rightTypeId = null;
    this._rightTypeGuid = null;

    this._leftStatusId = null;
    this._leftStatusGuid = null;

    this._rightStatusId = null;
    this._rightStatusGuid = null;

    this._tableId = null;
    this._tableGuid = null;

    this._targetTableId = null;
    this._targetTableGuid = null;

    this._lookupFieldId = null;
    this._lookupFieldGuid = null;
}

LinkEditorTabSettingsModel.prototype.FromJSON = function (data) {
    this._leftTypeId = data.LeftTypeId;
    this._leftTypeGuid = data.LeftTypeGuid;

    this._rightTypeId = data.RightTypeId;
    this._rightTypeGuid = data.RightTypeGuid;

    this._leftStatusId = data.LeftStatusId;
    this._leftStatusGuid = data.LeftStatusGuid;

    this._rightStatusId = data.RightStatusId;
    this._rightStatusGuid = data.RightStatusGuid;

    this._tableId = data.TableId;
    this._tableGuid = data.TableGuid;

    this._targetTableId = data.TargetTableId;
    this._targetTableGuid = data.TargetTableGuid;

    this._lookupFieldId = data.LookupFieldId;
    this._lookupFieldGuid = data.LookupFieldGuid;
};

LinkEditorTabSettingsModel.prototype.toJSON = function () {
    return {
        Guid: this.Guid,
        LeftTypeId: this.LeftTypeId,
        LeftTypeGuid: this.LeftTypeGuid,
        LeftStatusId: this.LeftStatusId,
        LeftStatusGuid: this.LeftStatusGuid,
        RightTypeId: this.RightTypeId,
        RightTypeGuid: this.RightTypeGuid,
        RightStatusId: this.RightStatusId,
        RightStatusGuid: this.RightStatusGuid,
        TableId: this.TableId,
        TableGuid: this.TableGuid,
        TargetTableId: this.TargetTableId,
        TargetTableGuid: this.TargetTableGuid,
        LookupFieldId: this.LookupFieldId,
        LookupFieldGuid: this.LookupFieldGuid
    };
}

LinkEditorTabSettingsModel.prototype.Reverse = function () {
    const newLeftTypeId = this._rightTypeId;
    const newRightTypeId = this._leftTypeId;
    const newLeftTypeGuid = this._rightTypeGuid;
    const newRightTypeGuid = this._leftTypeGuid;
    const newLeftStatusId = this._rightStatusId;
    const newRightStatusId = this._leftStatusId;
    const newLeftStatusGuid = this._rightStatusGuid;
    const newRightStatusGuid = this._leftStatusGuid;

    this._leftTypeId = newLeftTypeId;
    this._rightTypeId = newRightTypeId;
    this._leftTypeGuid = newLeftTypeGuid;
    this._rightTypeGuid = newRightTypeGuid;
    this._leftStatusId = newLeftStatusId;
    this._rightStatusId = newRightStatusId;
    this._leftStatusGuid = newLeftStatusGuid;
    this._rightStatusGuid = newRightStatusGuid;
};

LinkEditorTabSettingsModel.prototype.FromJSONArray = function (data) {
    const linkEditorTabSettingsList = new CollectionModel({ model: LinkEditorTabSettingsModel });

    for (let index = 0; index < data.length; index++) {
        const linkEditorVisibilityModel = new LinkEditorTabSettingsModel();

        linkEditorVisibilityModel.FromJSON(data[index]);
        linkEditorTabSettingsList.Add(linkEditorVisibilityModel);
    }

    return linkEditorTabSettingsList;
};

Object.defineProperty(LinkEditorTabSettingsModel.prototype, 'Guid', {
    enumerable: true,
    get: function () {
        return this._guid;
    }
});

Object.defineProperty(LinkEditorTabSettingsModel.prototype, 'LeftTypeId', {
    enumerable: true,
    get: function () {
        return this._leftTypeId;
    },
    set: function (value) {
        this._leftTypeId = value;
    }
});

Object.defineProperty(LinkEditorTabSettingsModel.prototype, 'LeftTypeGuid', {
    enumerable: true,
    get: function () {
        return this._leftTypeGuid;
    },
    set: function (value) {
        this._leftTypeGuid = value;
    }
});

Object.defineProperty(LinkEditorTabSettingsModel.prototype, 'RightTypeId', {
    enumerable: true,
    get: function () {
        return this._rightTypeId;
    },
    set: function (value) {
        this._rightTypeGuid = value;
    }
});

Object.defineProperty(LinkEditorTabSettingsModel.prototype, 'RightTypeGuid', {
    enumerable: true,
    get: function () {
        return this._rightTypeGuid;
    },
    set: function (value) {
        this._rightTypeGuid = value;
    }
});

Object.defineProperty(LinkEditorTabSettingsModel.prototype, 'LeftStatusId', {
    enumerable: true,
    get: function () {
        return this._leftStatusId;
    },
    set: function (value) {
        this._leftStatusId = value;
    }
});

Object.defineProperty(LinkEditorTabSettingsModel.prototype, 'LeftStatusGuid', {
    enumerable: true,
    get: function () {
        return this._leftStatusGuid;
    },
    set: function (value) {
        this._leftStatusGuid = value;
    }
});

Object.defineProperty(LinkEditorTabSettingsModel.prototype, 'RightStatusId', {
    enumerable: true,
    get: function () {
        return this._rightStatusId;
    },
    set: function (value) {
        this._rightStatusId = value;
    }
});

Object.defineProperty(LinkEditorTabSettingsModel.prototype, 'RightStatusGuid', {
    enumerable: true,
    get: function () {
        return this._rightStatusGuid;
    },
    set: function (value) {
        this._rightStatusGuid = value;
    }
});

Object.defineProperty(LinkEditorTabSettingsModel.prototype, 'TableId', {
    enumerable: true,
    get: function () {
        return this._tableId;
    },
    set: function (value) {
        this._tableId = value;
    }
});

Object.defineProperty(LinkEditorTabSettingsModel.prototype, 'TableGuid', {
    enumerable: true,
    get: function () {
        return this._tableGuid;
    },
    set: function (value) {
        this._tableGuid = value;
    }
});

Object.defineProperty(LinkEditorTabSettingsModel.prototype, 'TargetTableId', {
    enumerable: true,
    get: function () {
        return this._targetTableId;
    },
    set: function (value) {
        this._targetTableId = value;
    }
});

Object.defineProperty(LinkEditorTabSettingsModel.prototype, 'TargetTableGuid', {
    enumerable: true,
    get: function () {
        return this._targetTableGuid;
    },
    set: function (value) {
        this._targetTableGuid = value;
    }
});

Object.defineProperty(LinkEditorTabSettingsModel.prototype, 'LookupFieldId', {
    enumerable: true,
    get: function () {
        return this._lookupFieldId;
    },
    set: function (value) {
        this._lookupFieldId = value;
    }
});

Object.defineProperty(LinkEditorTabSettingsModel.prototype, 'LookupFieldGuid', {
    enumerable: true,
    get: function () {
        return this._lookupFieldGuid;
    },
    set: function (value) {
        this._lookupFieldGuid = value;
    }
});
