//Libs
import * as moment from "moment";
import {DATE_FORMATS} from "Core/Constants/DateTimeFormats";

export class DateTime {
	static GetWeekNumber(date: Date): number {
		return moment(date).isoWeek();
	}

	static DestructDate(date: Date): IDestructedDate {
		const weekAndYear: IDestructedDate = {
			WeekNumber: this.GetWeekNumber(date),
			Year: this.GetWeekYear(date)
		}

		return weekAndYear;
	}

	static GetWeekYear(date: Date): number {
		return moment(date).isoWeekYear();
	}

	static GetWeekDescription(date: Date): string {
        return moment(date).format(DATE_FORMATS.LONG_DATE_SHORT_WEEK.MomentFormat);
    }

	static GetWeekStart(date: Date): Date {
		return moment(date).startOf("isoWeek").toDate();
	}

	static GetWeekStartByNumber(week: number, year: number): Date {
		return moment().year(year).isoWeek(week).startOf("isoWeek").toDate();
	}

	static GetWeekEnd(date: Date): Date {
		return moment(date).endOf("isoWeek").toDate();
	}

	static GetPrevWeekStartDate(date: Date) {
		const weekStartDate = this.GetWeekStart(date);
		return this.AddDays(weekStartDate, -7);
	}

	static GetNextWeekStartDate(date: Date) {
		const weekEndDate = this.GetWeekEnd(date);
		return this.AddSeconds(weekEndDate, 1);
	}

	static GetDatesBetween(start: Date, end: Date): Date[] {
		const datesRange: Date[] = [];
		let current = new Date(start.toString());

		while (current <= end) {
			datesRange.push(new Date(current.toString()));
			current = this.AddDays(current, 1);
		}

		return datesRange;
	}

	static GetWeeksCount(year: number): number {
		return moment(new Date(year, 1, 1)).isoWeeksInYear();
	}

	static AddMinutes(date: Date, minutes: number): Date {
		return moment(date).add("minutes", minutes).toDate();
	}

	static AddSeconds(date: Date, seconds: number): Date {
		return moment(date).add("seconds", seconds).toDate();
	}

	static AreSame(first: Date, second: Date, withTime: boolean = false): boolean {
		if (withTime) {
			return first === second;
		}

        const firstCopy = moment(new Date(first.toString())).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
        const secondCopy = moment(new Date(second.toString())).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();

		return firstCopy === secondCopy;
	}

	static Max(first: Date, second: Date, withTime: boolean = false): Date {
		if (withTime) {
			return first >= second
				? first
				: second;
		}

        const firstCopy = moment(new Date(first.toString())).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
        const secondCopy = moment(new Date(second.toString())).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();

		return firstCopy >= secondCopy
			? first
			: second;
	}

	private static AddDays(date: Date, days: number): Date {
		const newDate = new Date(date.valueOf());
		newDate.setDate(newDate.getDate() + days);
		return newDate;
	}
}

export interface IDestructedDate {
	WeekNumber: number;
	Year: number;
}