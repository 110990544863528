import * as ko from "knockout";

import {Event} from "Core/Common/Event";
import {EVENTS} from "Core/Constant";
import {PortletRenderModes} from "Core/Constant"

export abstract class BasePortletManager extends Event {
	protected renderMode: KnockoutObservable<PortletRenderModes>;
	protected isChanged: boolean;
	protected isReady: boolean;

	constructor() {
		super();
		this.isChanged = false;
		this.isReady = false;
		this.renderMode = ko.observable(PortletRenderModes.View);
		this.IsInViewMode = ko.computed(() => this.renderMode() === PortletRenderModes.View);
		this.IsInDesignMode = ko.computed(() => this.renderMode() === PortletRenderModes.Design);
	}

	get IsReady(): boolean {
		return this.isReady;
	}

	get RenderMode() {
		return this.renderMode();
	}

	SwitchRenderMode(renderMode: PortletRenderModes) {
		this.renderMode(renderMode);
	}

	IsInViewMode: KnockoutComputed<boolean>;
	IsInDesignMode: KnockoutComputed<boolean>;
}