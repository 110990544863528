import * as ko from "knockout";
import * as _ from "underscore";

import {Event} from "Core/Common/Event";

import {
    RecordSecurityModel,
    UserGroupModel,
    RecordRightsModel,
    TableModel,
    RightUnitModel
} from "Core/Controls/RecordSecurity/Models/RecordSecurityModel";
import {RecordSecurityRights} from "Core/Common/Enums/RecordSecurityRights";
import {LABELS} from "../../../Components/Translation/Locales";

export class UserGroupViewModel extends Event {
    Id: number;
    Name: string;
    ProfileReservationId: KnockoutObservable<number>;
    Options: Array<RecordRightsViewModel>;
    SelectedOption: KnockoutObservable<RecordRightsViewModel>;

    IsReserved: KnockoutComputed<boolean>;

    IsReservationBanned: boolean;

    UsesInGroups: Array<string>;

    constructor(profileId: number, model: UserGroupModel, options: Array<RecordRightsViewModel>, activeOption: RecordRightsViewModel) {
        super();

        this.Id = model.Id;
        this.Name = !!model.Name && model.Name.trim().length > 0 ? model.Name : '(no name)';
        this.Options = options;
        this.ProfileReservationId = ko.observable(model.ProfileReservationId);
        this.SelectedOption = ko.observable(activeOption);
        this.IsReserved = ko.computed(()=>{
            return this.ProfileReservationId() == profileId;
        });

        this.IsReservationBanned = model.UsesInGroups.length > 0;
        this.UsesInGroups = model.UsesInGroups;
    }

    //Translation for UserGroups
    get TranslationGroupName() {
        if (this.Id === 0) {
            return LABELS.OWNER_LABEL;
        }
        return this.Name;
    }

    private BlockCaption(option, item) {
        const index = this.Options.indexOf(item);
        ko.applyBindingsToNode(option, {disable: index === 0}, item);
    }
}

class RecordRightsViewModel {
    constructor(public Name: string, public Value: number) {
    }
}

class RightUnitViewModel extends Event {
    UserGroupId: number;
    Value: number;

    Options: Array<RecordRightsViewModel>;
    SelectedOption: KnockoutObservable<RecordRightsViewModel>;

    constructor(model: RightUnitModel, options: Array<RecordRightsViewModel>) {
        super();

        this.UserGroupId = model.UserGroupId;
        this.Value = model.Value;

        this.Options = options;

        let optionsReversed = options.slice().reverse();

        this.SelectedOption = ko.observable(_.find(optionsReversed, option => (option.Value & model.Value) === option.Value));
        this.SelectedOption.subscribe(newRights => this.Trigger('RightsChanged', {
            UserGroupId: this.UserGroupId,
            Value: newRights.Value
        }));
    }
}

class TableViewModel extends Event {
    Id: number;
    Name: string;
    Rights: KnockoutObservableArray<RightUnitViewModel>;

    constructor(model: TableModel, rightOptions: Array<RecordRightsViewModel>) {
        super();

        this.Id = model.Id;
        this.Name = model.Name;

        let rights = model.Rights.map(right => {
            const rightUnit = new RightUnitViewModel(right, rightOptions);
            rightUnit.On('RightsChanged', this, eventArgs => this.Trigger('RightsChanged', eventArgs.data));
            return rightUnit;
        });

        this.Rights = ko.observableArray(rights);
    }
}

class UserGroupSelectorItemViewModel {
    constructor(public Id: number, public Name: string) {
    }
}

class UserGroupSelectorViewModel {
    UserGroups: KnockoutObservableArray<UserGroupSelectorItemViewModel>;
    CurrentUserGroup: KnockoutObservable<UserGroupSelectorItemViewModel>;

    constructor(currentGroupId: number, userGroups: UserGroupModel[], tables: TableModel[]) {
        const groups = [new UserGroupSelectorItemViewModel(null, LABELS.SELECT_GROUP)];

        for (const table of tables) {
            const tableUserGroups = table.Rights
                .filter(rights => rights.UserGroupId > 0 && rights.Value > RecordSecurityRights.Instance.NoAccess.Value)
                .map(rights => rights.UserGroupId);

            for (const tableUserGroup of tableUserGroups) {
                const userGroupAdded = _.any(groups, userGroup => userGroup.Id === tableUserGroup);

                if (!userGroupAdded) {
                    const userGroup = _.find(userGroups, userGroup => userGroup.Id === tableUserGroup);
                    groups.push(new UserGroupSelectorItemViewModel(userGroup.Id, userGroup.Name));
                }
            }

            if (groups.length == userGroups.length) {
                break;
            }
        }

        this.UserGroups = ko.observableArray(groups);

        let currentGroupItem = _.find(groups, group => group.Id == currentGroupId)
        if (currentGroupItem) {
            this.CurrentUserGroup = ko.observable(currentGroupItem);
            return;
        }

        const currentGroup = _.find(userGroups, group => group.Id === currentGroupId);
        if (currentGroup) {
            currentGroupItem = new UserGroupSelectorItemViewModel(currentGroup.Id, currentGroup.Name);
            this.CurrentUserGroup = ko.observable(currentGroupItem);
            return;
        }

        this.CurrentUserGroup = ko.observable(groups[0]);
    }
}

export class RecordSecurityViewModel {
    UserGroups: Array<UserGroupViewModel>;
    UserGroupSelector: UserGroupSelectorViewModel;

    Rights: Array<RecordRightsViewModel>;
    Tables: Array<TableViewModel>;

    constructor(profileId: number, model: RecordSecurityModel) {
        this.Rights = RecordSecurityRights.Instance.GetAll();
        this.Tables = model.Tables.map(table => {
            const tableView = new TableViewModel(table, this.Rights.slice(1));
            tableView.On('RightsChanged', this, eventArgs => {
                this.UpdateCurrentGroup(eventArgs.data);
                this.UpdateGeneralRightsSelector(eventArgs.data);
            });
            return tableView;
        });
        this.UserGroups = this.GetUserGroups(model, profileId);
        this.UserGroupSelector = new UserGroupSelectorViewModel(model.CurrentUserGroup, model.UserGroups, model.Tables);
    }

    private GetUserGroups(model, profileId: number) {
        return model.UserGroups.map(userGroup => {
            let activeGeneralOption = this.Rights[0];
            const rights = _.flatten(this.Tables.map(table => _.filter(table.Rights(), right => right.UserGroupId === userGroup.Id)));
            const rightsValues = _.map(rights, right => right.Value);
            const ifAllAreSame = () => {
                return (rightsValues[0] < 512)
                    ? _.every(rightsValues, value => value === rightsValues[0] || value - 512 === rightsValues[0])
                    : _.every(rightsValues, value => value === rightsValues[0] || value + 512 === rightsValues[0])
            };
            const allRightsAreSame = rightsValues.length > 0
                ? ifAllAreSame()
                : false;

            const checkValue = (right) => {
                return (rightsValues[0] < 512) ?
                    right.Value - 512 === rightsValues[0]
                    : right.Value + 512 === rightsValues[0]
            };

            if (allRightsAreSame) {
                activeGeneralOption = _.find(this.Rights, right => right.Value === rightsValues[0] || checkValue(right));
            }

            return new UserGroupViewModel(profileId, userGroup, this.Rights, activeGeneralOption);
        });
    }

    private UpdateGeneralRightsSelector(rights) {
        const userGroup = _.find(this.UserGroups, userGroup => userGroup.Id === rights.UserGroupId);

        const groupRights = _.flatten(_.map(this.Tables, table => _.filter(table.Rights(), right => right.UserGroupId === rights.UserGroupId)));
        const allRightsAreSame = _.every(groupRights, groupRight => groupRight.SelectedOption().Value == rights.Value);

        const rightsOptions = allRightsAreSame ? _.find(userGroup.Options, option => option.Value == rights.Value) : userGroup.Options[0];
        userGroup.SelectedOption(rightsOptions);
    }

    private UpdateCurrentGroup(rights) {
        const groupIndex = _.findIndex(this.UserGroupSelector.UserGroups(), userGroup => userGroup.Id === rights.UserGroupId);
        const userGroup = _.find(this.UserGroups, userGroup => userGroup.Id === rights.UserGroupId);
        const groupHasAnyRights = this.UserGroupHasAnyRights(rights.UserGroupId);

        if (rights.UserGroupId === 0) {
            return;
        }

        //Remove userGroup from dropdown if it does not have any rights
        if (groupIndex > -1 && !groupHasAnyRights) {
            this.UserGroupSelector.UserGroups.splice(groupIndex, 1);
            return;
        }

        //Add group to dropdown if it received first rights
        if (groupIndex == -1 && rights.Value > RecordSecurityRights.Instance.NoAccess.Value) {
            this.UserGroupSelector.UserGroups.push(new UserGroupSelectorItemViewModel(userGroup.Id, userGroup.Name));
            return;
        }
    }

    private UserGroupHasAnyRights(userGroupId: number) {
        const groupHasTableRights = (userGroupId: number, right: RightUnitViewModel) => {
            return right.UserGroupId === userGroupId && right.SelectedOption().Value > RecordSecurityRights.Instance.NoAccess.Value;
        };

        return _.any(this.Tables, table => _.any(table.Rights(), right => groupHasTableRights(userGroupId, right)));
    }
}