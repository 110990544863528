import * as ko from "knockout"
import 'jquery';

import {Tooltip} from 'Core/Common/Tooltip';
import {ZIndexManager} from "Core/Common/ZIndexManager";

// customOptions
// showHideOnlyOnHover - Restarts the tooltip on hover over the element.
// isLineClampMode - Triggers the tooltip upon reaching a certain height, used together with the ".line-clamp-ellipsis" class, but can be used without it

export class EllipsisTooltipExtention {
    private _jbox: jBox;
    private static _tooltip: Tooltip | null = null;

    static Init() {
        const self = this;
        ko.bindingHandlers.ellipsisTooltip = {
            init: (element, valueAccessor) => {
                const options = ko.unwrap(valueAccessor()).options;
                
                const updateEllipsisTooltip = () => {
                    if (options && options.isLineClampMode){ //Used in conjunction with the ".line-clamp-ellipsis" class, but can be used without it
                        let currentEl = $(element);
                        if (currentEl[0].offsetHeight < currentEl[0].scrollHeight ||
                            currentEl[0].offsetWidth < currentEl[0].scrollWidth) {
                            // your element have overflow
                            if (options.showHideOnlyOnHover) {
                                self.ShowTooltip(element, valueAccessor);
                            } else {
                                self.Update(element, valueAccessor);
                            }
                        } else {
                            self.HideTooltip(options);
                        }
                    } else {
                        let currentEl = $(element),
                            parrentEl = currentEl.parent(),
                            rightCoorCurrent = currentEl.offset().left + currentEl.prop('scrollWidth'),
                            rightCoorParent = parrentEl.offset().left + parrentEl.prop('scrollWidth');

                        if (rightCoorCurrent > rightCoorParent) {
                            if (options && options.showHideOnlyOnHover) {
                                self.ShowTooltip(element, valueAccessor);
                            } else {
                                self.Update(element, valueAccessor);
                            }
                        } else {
                            self.HideTooltip(options);
                        }
                    }
                }

                $(document).ready(updateEllipsisTooltip);

                if (options && options.showHideOnlyOnHover) {
                    $(element).on('mouseenter', function () {
                        setTimeout(updateEllipsisTooltip, 1);
                    });
                    $(element).on('mouseleave', function () {
                        setTimeout(()=> {
                            self.HideTooltip(options);
                        }, 1)
                    });
                }
                
            }
        };
    }

    private static ShowTooltip(element, valueAccessor) {
        const options = ko.unwrap(valueAccessor()) as ITooltipConfig;

        if ((options && options.options) && options.options.showHideOnlyOnHover) {
            const otherOptions = options.options;
            if (!this._tooltip) {
                this._tooltip = new Tooltip(element, options.content, otherOptions);
            }
            
            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            });

            this._tooltip.Show(element);
        }
    }

    private static HideTooltip(options) {
        if (options && options.showHideOnlyOnHover) {
            if (this._tooltip) {
                this._tooltip.Close();
                this._tooltip.Destroy();
                this._tooltip = null;
            }
        }
    }

    private static Update(element, valueAccessor) {
        const options = ko.unwrap(valueAccessor()) as ITooltipConfig;
        const otherOptions = options.options;
        const tooltip = new Tooltip(element, options.content, otherOptions);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
        });
    }

    static CreateEllipsisTooltipForPathRunner(record) {
        let currentNodeType = $(`#${record.Guid}_node-type`),
            currentAnchor = $(`#${record.Guid}_anchor`),
            jbox;

        if (!!currentAnchor.length && !!currentNodeType){
            if (currentAnchor.prop('offsetWidth') < currentAnchor.prop('scrollWidth') || currentNodeType.prop('offsetWidth') < currentNodeType.prop('scrollWidth')) {
                jbox = new jBox('Tooltip', {
                    content: `<b>${record.TypeNameTranslation || record.TypeTranslatedName || record.TypeName}:</b> ${record.TranslatedName || record.NameTranslation || record.Name}`,
                    addClass: `${record.Guid}_tooltip path-runner-tooltip`,
                    closeOnClick: true,
                    closeOnMouseleave: true,
                    target: $(`#${record.Guid}_anchor`),
                    zIndex: ZIndexManager.Instance.NextValue
                });
                jbox.open();

                return jbox;
            }
        }
    }
}

export interface ITooltipConfig {
    content: string;
    options: any;
}