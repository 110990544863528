export class ColumnFirstEditor {
    _name: string;
    _id: number;
    _entityId: number;
    _type: string;
    _recordTypeId: number;
    _recordTypeName: string;

    constructor(name: string, id: number, entityId: number, type: string, recordTypeId: number, recordTypeName: string) {
        this._name = name;
        this._id = id;
        this._entityId = entityId;
        this._type = type;
        this._recordTypeId = recordTypeId;
        this._recordTypeName = recordTypeName;
    }
}
