import * as ko from 'knockout';
import * as _ from 'underscore';

import {LABELS} from "Core/Components/Translation/Locales";

import {EventBusConsumer} from "Core/Common/EventBus/EventBusConsumer";

import {PriceGroup} from "./PriceGroup";
import {MainProductPriceGroup} from './MainProductPriceGroup';
import {ProductPrice} from "./ProductPrice";

import {ConfigurationPageEvents} from "../Events/ConfigurationPageEvents";

import {FormatConverter} from 'FormatEditor/FormatConverter';
import {ConfigurationLevel} from './ConfigurationLEvel';
import {AlternativePriceModel} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/AlternativePriceModel';

import Template from '../Templates/PriceList.html';

export class PriceList extends EventBusConsumer {
    MainProductPriceGroup: KnockoutObservable<MainProductPriceGroup>;
    Groups: KnockoutObservableArray<PriceGroup>;

    Total: KnockoutObservable<number>;
    Quantity: KnockoutObservable<number>;
    TotalLabel: KnockoutComputed<string>;

    ComparisonTotal: KnockoutObservable<number>;
    ComparisonQuantity: KnockoutObservable<number>;
    ComparisonTotalLabel: KnockoutComputed<string>;

    AlternativeTotal: KnockoutObservable<number>;
    AlternativeTotalLabel: KnockoutComputed<string>;
    ComparisonAlternativeTotal: KnockoutObservable<number>;
    ComparisonAlternativeTotalLabel: KnockoutComputed<string>;

    Raw: KnockoutObservable<boolean>;

    private _labels = LABELS;
    private _isActiveLevelLocked: KnockoutObservable<boolean>;

    Levels: KnockoutObservableArray<ConfigurationLevel>;
    _compare: KnockoutObservable<boolean>;

    MainLevel: string;

    _pricesMiscalculated: KnockoutComputed<boolean>;

    AlternativePriceModel: KnockoutObservable<AlternativePriceModel>;
    ShowAlternativePrice: KnockoutObservable<boolean>;

    private _isSaveDisabledByAction: KnockoutObservable<boolean>;

    constructor() {
        super();

        this.Groups = ko.observableArray([]);
        this.MainProductPriceGroup = ko.observable(null);
        this.Total = ko.observable(0);
        this.Quantity = ko.observable(0);

        this.ComparisonTotal = ko.observable(0);
        this.ComparisonQuantity = ko.observable(0);

        this.AlternativeTotal = ko.observable(0);
        this.ComparisonAlternativeTotal = ko.observable(0);

        this.Raw = ko.observable(false);

        this._isActiveLevelLocked = ko.observable(false);

        this.Levels = ko.observableArray([]);
        this._compare = ko.observable(false);
        this._pricesMiscalculated = ko.computed(() => this.Groups().find(group => group._priceMiscalculated()) != null);

        this.AlternativePriceModel = ko.observable(null);
        this.ShowAlternativePrice = ko.observable(false);

        this._isSaveDisabledByAction = ko.observable(false);

        this.TotalLabel = ko.computed(() => {
            const decimalValue = FormatConverter.LocalizeDecimalOrInteger(Math.abs(this.Total()).toFixed(2));
            return `€ ${this.Total() < 0 ? '-' : ''}${decimalValue}`;
        });

        this.ComparisonTotalLabel = ko.computed(() => {
            const decimalValue = FormatConverter.LocalizeDecimalOrInteger(Math.abs(this.ComparisonTotal()).toFixed(2));
            return `€ ${this.ComparisonTotal() < 0 ? '-' : ''}${decimalValue}`;
        });

        this.AlternativeTotalLabel = ko.computed(() => {
            const decimalValue = FormatConverter.LocalizeDecimalOrInteger(Math.abs(this.AlternativeTotal()).toFixed(2));
            return `* € ${this.AlternativeTotal() < 0 ? '-' : ''}${decimalValue}`;
        });

        this.ComparisonAlternativeTotalLabel = ko.computed(() => {
            const decimalValue = FormatConverter.LocalizeDecimalOrInteger(Math.abs(this.ComparisonAlternativeTotal()).toFixed(2));
            return `* € ${this.ComparisonAlternativeTotal() < 0 ? '-' : ''}${decimalValue}`;
        });
    }

    get LevelsClass(): string {
        return `levels-${this.Levels().length}`
    }

    get ActiveLevel() {
        if (this._compare() && this.Levels().length > 0) {
            const activeLevel = this.Levels().find(level => level.Active());
            if (activeLevel != null) {
                return activeLevel.Level;
            }
        }
        return null;
    }

    get IsActiveLevelLocked(): boolean {
        return this._isActiveLevelLocked();
    }

    set IsActiveLevelLocked(isActiveLevelLocked: boolean) {
        this._isActiveLevelLocked(isActiveLevelLocked);
    }

    get IsAlternativePriceEnabled(): boolean {
        return this.AlternativePriceModel() && this.AlternativePriceModel().IsEnabled;
    }

    get IsCalculationRuleValid(): boolean {
        return this.AlternativePriceModel() && this.AlternativePriceModel().IsCalculationRuleValid;
    }

    get AlternativePriceCheckboxLabel(): string {
        return `${this._labels.SHOW} *${this._labels.ALTERNATIVE_PRICE}`;
    }

    get AlternativePriceCheckboxTooltip(): string {
        if (!this.IsCalculationRuleValid) {
            return this._labels.COULD_NOT_CALCULATE_ALTERNATIVE_PRICE;
        }

        return null;
    }

    get IsSaveDisabledByAction(): boolean {
        return this._isSaveDisabledByAction();
    }

    set IsSaveDisabledByAction(isSaveDisabled: boolean) {
        this._isSaveDisabledByAction(isSaveDisabled);
    }

    get IsSaveDisabledByUndefinedGroup() {
        return _.any(this.Groups(), group => group.Id === 0 && group.Name === this._labels.UNDEFINED && group.HasProducts());
    }

    get IsSaveInactive(): boolean {
        return this._pricesMiscalculated() || this.IsActiveLevelLocked || this.IsSaveDisabledByAction || this.IsSaveDisabledByUndefinedGroup;
    }

    GetTemplate() {
        return Template;
    }

    AddAlternativePriceModel(alternativePriceModel: AlternativePriceModel) {
        this.AlternativePriceModel(alternativePriceModel);
    }

    AddMainProductPriceGroup(productId: number, productName: string, productNameTranslated: string, basePrice: number, quantity: number) {
        this.MainProductPriceGroup(new MainProductPriceGroup(productId, productName, basePrice, productNameTranslated));

        if (this._compare()) {
            this.MainProductPriceGroup()._compare(true);
        }

        this.Quantity(quantity);
        this.Total(basePrice);

        const alternativePrice = this.CalculateAlternativePrice(basePrice);
        if (alternativePrice !== null) {
            this.MainProductPriceGroup().AlternativePrice(alternativePrice);
            this.AlternativeTotal(alternativePrice);
        }
    }

    AddToPriceList(product: ProductPrice, groupId: number, groupName: string, groupTranslatedName: string) {
        let group = this.FindGroup(groupId, groupName);

        if (!group) {
            group = new PriceGroup(groupId, groupName, 0, groupTranslatedName);
            if (product._compare()) {
                group._compare(true);
            }
            group.AssignEventBus(this.EventBus);
            this.Groups.push(group);
        }

        const alternativePrice = this.CalculateAlternativePrice(product.Price());
        product.AlternativePrice(alternativePrice);

        const existingProduct = group.GetProduct(product.Id, product.RootGroupId, product.RootGroupName, product.KSeq, product.KSeqGuid);

        if (existingProduct) {
            if (existingProduct.Path != product.Path) {
                existingProduct.Path = product.Path;
            }

            if (existingProduct.Price() != product.Price() || existingProduct.Quantity() !== product.Quantity()) {
                existingProduct.Price(product.Price());
                existingProduct.AlternativePrice(product.AlternativePrice());
                existingProduct.Quantity(product.Quantity());

                group.Price(group.Price() + product.PositionPrice);
                this.Total(this.Total() + product.PositionPrice);

                group.AlternativePrice(group.AlternativePrice() + product.PositionAlternativePrice);
                this.AlternativeTotal(this.AlternativeTotal() + product.PositionAlternativePrice);
            }
            return;
        }

        product.AssignEventBus(this.EventBus);

        group.AddProduct(product);

        this.Total(this.Total() + product.PositionPrice);
        this.AlternativeTotal(this.AlternativeTotal() + product.PositionAlternativePrice);
    }

    AddToPriceListByLevel(product: ProductPrice, groupId: number, groupName: string, groupTranslatedName: string, level: string) {
        if (this._compare()) {
            product._compare(true);
        }

        if (level == this.MainLevel) {
            this.AddToPriceList(product, groupId, groupName, groupTranslatedName);
            return;
        }

        let group = this.FindGroup(groupId, groupName);

        if (!group) {
            group = new PriceGroup(groupId, groupName, 0, groupTranslatedName);
            if (this._compare()) {
                group._compare(true);
            }
            group.AssignEventBus(this.EventBus);
            this.Groups.push(group);
        }

        const alternativePrice = this.CalculateAlternativePrice(product.Price());
        product.AlternativePrice(alternativePrice);

        const existingProduct = group.GetProduct(product.Id, product.RootGroupId, product.RootGroupName, product.KSeq, product.KSeqGuid);

        if (existingProduct) {
            existingProduct.ComparisonPrice(product.Price());
            existingProduct.ComparisonAlternativePrice(product.AlternativePrice());
            existingProduct.ComparisonQuantity(product.Quantity());

            this.ComparisonTotal(this.ComparisonTotal() + existingProduct.ComparisonPositionPrice);
            group.ComparisonPrice(group.ComparisonPrice() + existingProduct.ComparisonPositionPrice);

            this.ComparisonAlternativeTotal(this.ComparisonAlternativeTotal() + existingProduct.ComparisonPositionAlternativePrice);
            group.ComparisonAlternativePrice(group.ComparisonAlternativePrice() + existingProduct.ComparisonPositionAlternativePrice);
        } else {
            product.ComparisonPrice(product.Price());
            product.ComparisonAlternativePrice(product.AlternativePrice());
            product.ComparisonQuantity(product.Quantity());

            product.Price(null);
            product.AlternativePrice(null);
            product.Quantity(null);

            product.AssignEventBus(this.EventBus);

            group.AddProduct(product);
            group.AssignEventBus(this.EventBus);

            this.ComparisonTotal(this.ComparisonTotal() + product.ComparisonPositionPrice);
            this.ComparisonAlternativeTotal(this.ComparisonAlternativeTotal() + product.ComparisonPositionAlternativePrice);
        }
    }

    RemoveFromPriceList(rootGroupId: number, rootGroupName: string, productName: string, kSeq: number, kSeqGuid: string) {
        const rootGroup = this.FindGroup(rootGroupId, rootGroupName);
        if (!rootGroup) return;

        const product = this.FindProductPrice(rootGroupId, rootGroupName, productName, kSeq, kSeqGuid);
        if (!product) return;

        const dependencies = this.GetDependenciesOf(product, rootGroupId, rootGroupName);
        dependencies.forEach(dependency => this.RemoveFromPriceList(rootGroupId, rootGroupName, dependency.Name, dependency.KSeq, dependency.KSeqGuid));

        this.Total(this.Total() - product.PositionPrice);
        this.AlternativeTotal(this.AlternativeTotal() - product.PositionAlternativePrice);

        if (product.ComparisonPrice() == null) {
            rootGroup.RemoveProduct(product);

            if (!rootGroup.HasProducts()) {
                this.RemoveGroup(rootGroup);
            }
        } else {
            rootGroup.Price(rootGroup.Price() - product.PositionPrice);
            product.Price(null);

            rootGroup.AlternativePrice(rootGroup.AlternativePrice() - product.PositionAlternativePrice);
            product.AlternativePrice(null);
        }
    }

    UpdatePriceFor(product: ProductPrice, groupId: number, groupName: string) {
        let group = this.FindGroup(groupId, groupName);

        const alternativePrice = this.CalculateAlternativePrice(product.Price());
        product.AlternativePrice(alternativePrice);

        const existingProduct = group.GetProduct(product.Id, product.RootGroupId, product.RootGroupName, product.KSeq, product.KSeqGuid);

        group.Price(group.Price() - existingProduct.PositionPrice);
        this.Total(this.Total() - existingProduct.PositionPrice);

        group.AlternativePrice(group.AlternativePrice() - existingProduct.PositionAlternativePrice);
        this.AlternativeTotal(this.AlternativeTotal() - existingProduct.PositionAlternativePrice);

        if (!product._priceMiscalculated()) {
            existingProduct._priceMiscalculated(false);
            group._priceMiscalculated(false);

            existingProduct.Price(product.Price());
            existingProduct.AlternativePrice(product.AlternativePrice());
            existingProduct.Quantity(product.Quantity());

            group.Price(group.Price() + existingProduct.PositionPrice);
            this.Total(this.Total() + existingProduct.PositionPrice);

            group.AlternativePrice(group.AlternativePrice() + existingProduct.PositionAlternativePrice);
            this.AlternativeTotal(this.AlternativeTotal() + existingProduct.PositionAlternativePrice);
        } else {
            existingProduct._priceMiscalculated(true);
            group._priceMiscalculated(true);
            existingProduct.Price(0);
            existingProduct.AlternativePrice(0);
            existingProduct.Quantity(existingProduct.Quantity());
        }
    }

    UpdateMainProductPrice(price: number) {
        const oldPrice = this.MainProductPriceGroup().Price();
        this.Total(this.Total() - oldPrice);

        const oldAlternativePrice = this.MainProductPriceGroup().AlternativePrice();
        this.AlternativeTotal(this.AlternativeTotal() - oldAlternativePrice);

        if (price != null) {
            this.MainProductPriceGroup()._priceMiscalculated(false);
            this.MainProductPriceGroup().Price(price);
            this.Total(this.Total() + price);

            const alternativePrice = this.CalculateAlternativePrice(price);
            if (alternativePrice !== null) {
                this.MainProductPriceGroup().AlternativePrice(alternativePrice);
                this.AlternativeTotal(this.AlternativeTotal() + alternativePrice);
            }
        } else {
            this.MainProductPriceGroup()._priceMiscalculated(true);
            this.MainProductPriceGroup().Price(0);
            this.MainProductPriceGroup().AlternativePrice(0);
        }
    }

    UpdateAssemblyQuantity(quantity: number) {
        this.Quantity(quantity);
    }

    GetDependenciesOf(product: ProductPrice, rootGroupId: number, rootGroupName: string): ProductPrice[] {
        const rootGroup = this.FindGroup(rootGroupId, rootGroupName);
        return rootGroup.GetDependenciesOf(product.Id);
    }

    OnContinueBtnClick() {
        this.SaveConfiguration();
    }

    private FindGroup(groupId: number, groupName: string): PriceGroup {
        return _.find(this.Groups(), group => group.Id === groupId && group.Name === groupName);
    }

    private FindProductPrice(groupId: number, groupName: string, productName: string, kSeq: number, kSeqGuid: string): ProductPrice {
        const group = _.find(this.Groups(), group => group.Id === groupId && group.Name === groupName);
        if (group) {
            return _.find(group.Products(), product => product.Name == productName && product.KSeq == kSeq && product.KSeqGuid == kSeqGuid);
        }
        return null;
    }

    private RemoveGroup(group: PriceGroup) {
        const index = this.Groups().indexOf(group);
        this.Groups.splice(index, 1);
    }

    private SaveConfiguration() {
        this.DispatchEvent(ConfigurationPageEvents.SaveConfigurationRequested);
    }

    CalculateAlternativePrice(price: number) {
        if (!this.AlternativePriceModel() || !this.AlternativePriceModel().IsCalculationRuleValid || price === null) {
            return null;
        }

        try {
            const alternativePriceCalculationRule = this.ReplaceAll(this.AlternativePriceModel().CalculationRule, "PRICE", `${price}`);
            return eval(alternativePriceCalculationRule);
        }
        catch {
            return null
        }
    }

    private ReplaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    ToggleSelection(level: ConfigurationLevel) {
        this.Levels().forEach(level => level.Active(false));
        level.Active(true);
    }
}