import { CONTROL_TYPES } from "Core/Constant";
import { GenericButton } from "Core/Controls/GenericButton/GenericButton";
import { Grid } from "Core/Controls/Grid/Grid";
import { gridFacade } from "./GridFacade";
import { screenFacade } from "Core/Components/CustomFunctions/Facades/ScreenFacade";

export const genericButtonFacade = (control: GenericButton) => {

    const getGrid = () => {
        let parent = control.GetParentControl();
        if(parent && parent.GetControlTypeName() === CONTROL_TYPES.Grid){
            return gridFacade(parent as Grid);
        }
        return null;
    }

    return { getGrid }
}