import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';

import {ColorResponseModel} from "../Models/ColorResponseModel";

export class ColorPropertyControlStore {
    constructor(private _ordersEntityId: number, private _productsEntityId: number) {
    }

    GetAvailableColors(productId: number, fieldId: number): P.Promise<ColorResponseModel[]> {
        const deferred = P.defer<ColorResponseModel[]>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetAvailableColors',
                type: 'GET',
                dataType: 'json'
            }, params: {
                OrderEntityId: this._ordersEntityId,
                ProductEntityId: this._productsEntityId,
                ProductId: productId,
                FieldId: fieldId
            }
        }).then(colors => deferred.resolve(colors))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error getting available colors')));

        return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string) {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}