import * as ko from "knockout";

import {LABELS} from "Core/Components/Translation/Locales";

import {BaseProperty, IPropertyDescription} from "Core/GeneralProperties/Managers/BaseProperty";
import Template from "Core/GeneralProperties/Managers/FileUploadProperty/Templates/FileUploadPropertyTemplate.html";
import { IControl } from "../../../Controls/IControl";
import { CONTROL_TYPES } from "../../../Constant";
import { FileDownloader } from "../../../Components/FileDownloader/FileDownloader";
ko.templates["Core/GeneralProperties/Managers/FileUploadProperty/Templates/FileUploadPropertyTemplate"] = Template;

export class FileUploadProperty extends BaseProperty {
	private _isValid: KnockoutObservable<boolean>;
	private _control: IControl;
	private _labels = LABELS;
	private _extension: KnockoutObservable<string>;
	private _dropZoneLabel: KnockoutObservable<string>;
	MaxFileSize: number;

	constructor(property: IPropertyDescription, propertyValue: string, control: IControl) {
		super(property);
		this._isValid = ko.observable(true);
		this.Value = ko.observable(propertyValue || this.GetDefaultValue());
		this._extension = ko.observable('');
		this._dropZoneLabel = ko.observable('');
		this._control = control;
		this.MaxFileSize = 5;
		this.Init();
	}

	GetTemplateName(): string {
		return "Core/GeneralProperties/Managers/FileUploadProperty/Templates/FileUploadPropertyTemplate";
	}

	GetDefaultValue() {
		return null;
	}

	SetValue(propertyValue: string): void {
		this.Value(propertyValue);
	}

	Download(){
		FileDownloader.DownloadBase64(this.Value().FileData, this.Value().FileName);
	}

	private Init() {
		if(this._control.GetControlTypeName() === CONTROL_TYPES.CrystalViewer){
			this._extension('.rpt');
			this._dropZoneLabel(this._labels.DROP_REPORT_HERE);
		}

		if(this._control.GetControlTypeName() === CONTROL_TYPES.IFrameExtension){
			this._dropZoneLabel('Drop extension application ZIP');
			this._extension('.zip');
		}
	}

	AfterUpload(file) {
		var reader = new FileReader();
		reader.onloadend = () => {
			if (file.status === 'removed') {
				return;
			}

			var base64Data = (reader.result as string).split(',')[1];
			this.Value({ FileName: file.name, FileData: base64Data });
		}

		reader.readAsDataURL(file);
	}

	IsValid(): boolean {
		var result = this.Value() != null;
		this._isValid(result);
		return result;
	}

	AfterRender() {

	}
}