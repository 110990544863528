import { deserialize } from 'libs/cerialize';

export class SubjectActionModel {
    @deserialize
    EntityId: number;

    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    LifeStatusId: number;

    @deserialize
    TypeId: number;

    @deserialize
    KindId: number;

    @deserialize
    TypeName: string;
}