import * as ko from "knockout"
import 'jquery';

export class CustomTableExtention {
	static Init() {
		ko.bindingHandlers.customTable = {
			init: (element, valueAccessor) => {
				let options = valueAccessor();
				let insideModal = null;
				let modalWrapperClass = '.jBox-container';
				let modalScrollClass = '.jBox-content';
				let sysTableClassName = '.sys-table';
				let headerWrapClassName = '.header-wrap';
				let scrollHeaderClassName = '.scroll-header';
				const formWrapper = '.jBox-container .form-wrapper';
				const tabs = '.jBox-container .tabs';
				const sysTableHeader = '.jBox-container .sys-table-header';
				const actionBarBlock = '.jBox-container #ActionBarBlock'

				insideModal = $(element).closest(modalWrapperClass);


				let windowHeight = null;
				let documentHeight = null;

				let tableWidth = 0;
				let scrollWidth = 0;

				let tableContainer = $(element).closest(sysTableClassName);
				let scrollRepeaterTop = $(tableContainer).find(headerWrapClassName);
				let scrollHeader = $(tableContainer).find(scrollHeaderClassName);

				if (insideModal.length) {
					tableWidth = $(element).width();
					scrollWidth = $(element).innerWidth();

					windowHeight = $(modalScrollClass).height();
					documentHeight = $(modalScrollClass).innerHeight();
				} else {
					tableWidth = $(element).width();
					scrollWidth = $(element).innerWidth();

					windowHeight = $(window).height();
					documentHeight = $(document).height();
				}

				$(scrollRepeaterTop).css({
					'overflow-x': 'auto',
					'overflow-y': 'hidden'
				});

				$(scrollHeader).width(scrollWidth);

				if (documentHeight > windowHeight) {
					const outerHeight = $(actionBarBlock).outerHeight() + $(formWrapper).outerHeight() + $(tabs).outerHeight() + $(sysTableHeader).outerHeight();
					const height = $(actionBarBlock).height() + $(formWrapper).height() + $(tabs).height() + $(sysTableHeader).height();

					$(element).parent().css({
						'height': windowHeight - height - 20,
						'overflow': 'auto'
					})
				}
				$(window).trigger('resize');
			}
		};
	}
}