//Common
import { P } from "Core/Common/Promise";
import { BlockUI } from "Core/Common/BlockUi";
import { Request } from "Core/Common/Request";

//Models
import { ISchedulerRequestModel } from "../Models/Store/Request/SchedularRequestModel";
import { ISchedularResponseModel } from "../Models/Store/Response/SchedulerResponseModel";

export class SchedulerStore {

	static GetScheduler(params: ISchedulerRequestModel, isBlockingNeed: boolean = true): P.Promise<ISchedularResponseModel> {
		const deferredResult = P.defer<any>();

		isBlockingNeed && BlockUI.Block();

		Request.Send({
				proxy: {
					url: "api/SchedulerApi/GetScheduler",
					type: "POST",
					dataType: "json"
				},
				params: params
			}).then(response => {
				this.ResolveObjectResponse(response, deferredResult)
			})
			.fail((error) => {
				var errorException = JSON.parse(error.message);
				this.RejectResponse(errorException.ExceptionMessage, deferredResult);
			})
			.always(() => isBlockingNeed && BlockUI.Unblock());

		return deferredResult.promise();
	}

	private static ResolveObjectResponse<T>(response, deferredResult: P.Deferred<T>) {
		if (response) {
			deferredResult.resolve(response);
		} else {
			deferredResult.reject({ message: response.ErrorMessage });
		}
	}

	private static RejectResponse<T>(message: string, deferredResult: P.Deferred<T>) {
		deferredResult.reject({ message: message });
	}
}
