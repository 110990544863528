import * as ko from "knockout";
import * as $ from "jquery";
import * as moment from 'moment';

import {BlockUI} from "Core/Common/BlockUi";
import {Notifier} from "Core/Common/Notifier";
import {NOTIFICATIONS} from "Core/Components/Translation/Locales";
import {Icon} from "Core/Icon/Icon";
import {IControlParam, IScreen} from "Core/Screens/IScreen";
import {CONTROL_TYPES, FIELD_TYPES, TABLE_TYPES, DEFAULT_ICONS} from "Core/Constant";

import {ControlDataModel} from "Core/ScreenManager/Models/ControlDataModel";
import {RecordSpecsModel} from "Core/ScreenManager/Models/RecordSpecsModel";
import {ComplexControl} from "Core/Controls/ComplexControl/ComplexControl";
import {RequiredFieldModel} from "../ComplexControl/Models/RequiredFieldModel";
import {ButtonCellEditor, ILinkedModel} from "Core/Components/Controls/Scheduler/Utils/ButtonCellEditor";

import {LinkList} from "../LinkList/LinkList";
import {Agenda} from "../Agenda/Agenda";

import {Scheduler as SchedulerComponent} from "Core/Components/Controls/Scheduler/Scheduler";
import {NewRelationModel} from "../LinkList/Models/NewRelationModel";
import {EntityRelationsViewModel} from "../LinkList/Models/LinkListRelationsViewModel";

import ViewTemplate from "Core/Controls/Scheduler/Templates/View.html";
import ToolBarTemplate from "Core/Controls/Scheduler/Templates/ToolBar.html";
import {IControlValue} from "../BaseControl/BaseControl";
import {ScreenTypes} from "Core/Common/Enums/ScreenTypes";
import {UserManager} from "User/UserManager";
import {SECURITY_LEVELS} from "Core/Constants/SecurityLevels";
import {PUB_SUB_EVENTS} from "MenuManager/PubSubEvents";

import {TabPage} from "../TabPage/TabPage";
import {Tab} from "../Tab/Tab";
import {LockManager} from "Core/Components/Locker/LockManager";
import {UserVarsManager} from "../../UserVarsManager/UserVarsManager";
import SchedulerConfigJson from "Core/Controls/Scheduler/Config/scheduler-config.json"
import {GeneralProperties} from 'Core/GeneralProperties/GeneralProperties';
import {PROPERTIES, SCHEDULER_EVENTS} from "./Constants";
import { IRePlanningData } from "./Interfaces";


ko.templates["Core/Controls/Scheduler/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/Scheduler/Templates/ToolBar"] = ToolBarTemplate;
ko.templates["Core/Controls/Scheduler/Templates/Design"] = ComplexControl.designTemplate;

export class Scheduler extends ComplexControl {
    private _component: KnockoutObservable<SchedulerComponent>;
    private _isLinkListLoaded: boolean;
    private _isRePlanningMode: boolean;
    private screenSubjectEntityId: number;
    private screenSubjectRecordId: number;
    private _rePlanningData: IRePlanningData;
    private _isFromAgenda: boolean;
    private isDestroyed = true;
    private _isRefreshScreen: boolean;

    constructor(params: IControlParam) {
        super(params, SchedulerConfigJson);
        
        this._isRePlanningMode = false;
        this._isFromAgenda = false;

        this.SetDefaultIcon(new Icon(DEFAULT_ICONS.Scheduler));
        this._isLinkListLoaded = false;
        this._requiredFields([
            new RequiredFieldModel('NAME', FIELD_TYPES.Text, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('STARTING', FIELD_TYPES.DateTime, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('DURATION', FIELD_TYPES.TimeSpan, TABLE_TYPES.Entity, null)
        ]);

        this.InitRequiredFields();
        this.ApplyProperties();

        this._component = ko.observable(null);
    }

    ApplyProperties(){
        this._isRefreshScreen = this.GeneralProperties.GetPropertyValue(PROPERTIES.REFRESH_SCREEN);
    }

    LoadData(rePlanningData?: IRePlanningData) {
        if (rePlanningData) {
            this._rePlanningData = rePlanningData;
            this._isRePlanningMode = true;
        } else if (!this._isFromAgenda) {
            this._rePlanningData = null;
            this._isRePlanningMode = false;
        }
        const screenType = this.GetForm().GetScreen().GetType();
        const filterBySubject = ScreenTypes[screenType] === ScreenTypes.ConsultScreen || ScreenTypes[screenType] === ScreenTypes.EditScreen;
        let screenSubjectEntityId = null,
            screenSubjectRecordId = null;

        if (filterBySubject) {
            screenSubjectEntityId = this.screenSubjectEntityId;
            screenSubjectRecordId = this.screenSubjectRecordId;
        }

        this._component(new SchedulerComponent({
            EntityId: this._model().EntityId,
            ScreenSubjectEntityId: screenSubjectEntityId,
            ScreenSubjectRecordId: screenSubjectRecordId,
            RePlanningData: this._rePlanningData,
            ControlId: this.GetControlId()
        }));
        this.BindEvents();
        this._isFromAgenda = false;
    }

    SetValue(value: IControlValue) {
        this.screenSubjectEntityId = value.SubjectEntityId;
        this.screenSubjectRecordId = value.SubjectRecordId;
    }

    SetRePlanMode(params: IRePlanningData) {
        this._isFromAgenda = true;
        this._isRePlanningMode = true;
        this._rePlanningData = params;
        this.LoadData(params);
    }

    private BindEvents() {
        this._component()._view().On(SCHEDULER_EVENTS.CREATE_SCREEN, this, eventArgs => this.ShowEditScreen(eventArgs.data));
        this._component()._view().On(SCHEDULER_EVENTS.OPEN_DEFAULT_SCREEN, this, eventArgs => this.ShowDefaultScreen(eventArgs.data.Id, eventArgs.data.Event, eventArgs.data.EntityId, eventArgs.data.TypeId));
        this._component()._view().On(SCHEDULER_EVENTS.RETURN_TO_AGENDA, this, eventArgs => this.ReturnToAgenda());
        this._component()._view().On(SCHEDULER_EVENTS.SWITCH_TO_DEFAULT, this, eventArgs => this.SwitchToDefaultMode());
        this._component()._view().On(SCHEDULER_EVENTS.REPLAN_APPOINTMENT, this, eventArgs => this.ReplanAppointment(eventArgs.data.Start));
        this._component()._view().On(SCHEDULER_EVENTS.SET_DATA, this, eventArgs => this.OnSetData(eventArgs.data.Date));
    }

    private SwitchToDefaultMode() {
        this._isRePlanningMode = false;
        this._rePlanningData = null;
        this.LoadData();
    }

    private OnSetData(date: string) {
        const screen = this._form && this._form.GetScreen();
        const agendas = screen.GetControlsByType(CONTROL_TYPES.Agenda);

        _.each(agendas, agenda => {
            UserVarsManager.Instance.SetAgendaDate(agenda.GetControlId(), date);
        });
    }

    private async ReplanAppointment(start: moment.Moment) {
        BlockUI.Block();

        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
        LockManager.Instance.TryLock(this.FieldModel.EntityId, this._rePlanningData.Id)
            .then(() => {
                screenManager.GetEditScreen({
                    EntityId: this._component()._entityId,
                    RecordId: this._rePlanningData.Id,
                    TableTypeId: this._rePlanningData.TableTypeId
                })
                    .always(() => {
                        BlockUI.Unblock();
                    })
                    .then(screen => {
                        const recordId = this._rePlanningData.Id;
                        screen.On('MODAL_CLOSE', this, () => LockManager.Instance.ReleaseLock(this.FieldModel.EntityId, recordId));

                        screen.On('RECORD_SAVED', this, () => {
                            const notifier = new Notifier($(this._el));
                            notifier.Success(NOTIFICATIONS.RECORD_CREATED);
                            this._isRePlanningMode = false;
                            this._rePlanningData = null;
                            this.LoadData();
                        });

                        this.SetStartingValue(screen, start.format());

                        screen.ShowInModal();
                    })
                    .fail(err => {
                        LockManager.Instance.ReleaseLock(this.FieldModel.EntityId, this._rePlanningData.Id);
                        let notifier = new Notifier();
                        notifier.Warning(err.message);
                    });
            });
    }

    private ReturnToAgenda() {
        if (this._rePlanningData.IsInTab) {
            const tabPage = this._rePlanningData.Agenda.GetParentControl() as TabPage;
            (tabPage.GetParentControl() as Tab).SetActiveTab(this._rePlanningData.TabIndex, {
                controlId: this._rePlanningData.Agenda.GetModel().Id,
                params: this._rePlanningData,
                action: 'ReturnFromScheduler'
            });
            this._isRePlanningMode = false;
            this._rePlanningData = null;
        } else {
            let isLoaded = false;
            $([document.documentElement, document.body]).animate({
                scrollTop: $(this._rePlanningData.Agenda.GetWrapper()).offset().top - 60
            }, 1000, () => {
                if (!isLoaded) {
                    this._rePlanningData.Agenda.LoadData();
                    this.SwitchToDefaultMode();
                    isLoaded = true;
                }
            });
        }
    }

    private ShowDefaultScreen(id: number, evt, entityId?: number, typeId?: number) {
        const params = {
            EntityId: entityId ? entityId : this.GetForm().GetScreen().GetEntityId(),
            RecordId: id,
            IsOpenInModal: evt.ctrlKey ? evt.ctrlKey : this._form.GetScreen().IsInModal(),
            ShowInModal: evt.ctrlKey,
            Owner: this.GetForm().GetScreen(),
            TypeId: typeId
        };
        PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, params);
    }

    private get AgendaRequestData() {
        return {
            EntityId: this._component()._entityId,
            TableTypeName: 'Appointment'
        }
    }

    private LinkResources(screen: IScreen, resources: Array<ILinkedModel>) {
        let currentUser = UserManager.Instance.CurrentUser;
        let linkList = screen.GetControl(CONTROL_TYPES.LinkList) as LinkList;

        let screenSubjectRecordName = this._component().GetScreenSubjectRecordName();
        if (this._component()._screenSubjectEntityId && this._component()._screenSubjectRecordId && screenSubjectRecordName) {
            resources.push({
                GroupEntityId: this._component()._screenSubjectEntityId,
                ResourceId: this._component()._screenSubjectRecordId,
                Name: screenSubjectRecordName,
                TypeId: null,
                TypeName: null
            });
        }

        if (linkList) {
            _.forEach(resources, (user) => {
                let model: EntityRelationsViewModel;

                if (!user.GroupEntityId) {
                    model = linkList.DataModel().UserRelation();
                } else {
                    model = _.find(linkList.DataModel().Entities(), (entity) => {
                        return user.GroupEntityId === entity.EntityId;
                    });
                }

                if (model) {
                    const record = _.find(model.Records(), (record) => {
                        return record.Id === user.ResourceId;
                    });

                    if (!record) {
                        let newRelation = new NewRelationModel();
                        newRelation.Name = user.Name;
                        newRelation.Id = user.ResourceId;
                        newRelation.TypeName = user.TypeName;
                        newRelation.IsMain = false;
                        newRelation.Level = SECURITY_LEVELS.SHARED;
                        linkList.AddRecord(user.ResourceId, model, newRelation);
                    }
                }
            });

            const currentUserIsResource = _.any(resources, resource =>
                resource.GroupEntityId === null && resource.ResourceId === currentUser.Id
            );

            if (!currentUserIsResource) {
                linkList.EnablePlanner();
            }
        }
    }

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);

        ko.utils.domNodeDisposal.addDisposeCallback(el[0].parentElement, () => {
            this._isRePlanningMode = false;
            this._rePlanningData = null;
            this._component(null);
            this.isDestroyed = true;
        });
        if (this.isDestroyed) {
            this.isDestroyed = false;
            this.LoadData();
        }
    }

    private SetStartingValue(screen: IScreen, start: string) {
        const startingControl = screen.GetControlByFieldName('STARTING', CONTROL_TYPES.DateTime);
        let startingModel = new ControlDataModel();
        startingModel.Value = moment(start).clone().format();
        startingControl.SetDefaultValue({
            SubjectRecordId: this._component()._screenSubjectRecordId,
            SubjectEntityId: this._component()._screenSubjectEntityId,
            Data: startingModel,
            RecordSpecsModel: new RecordSpecsModel()
        });
    }

    private SetDurationValue(screen: IScreen, duration: number) {
        const durationControl = screen.GetControlByFieldName('DURATION', CONTROL_TYPES.DateTime);
        let durationModel = new ControlDataModel();
        durationModel.Value = Agenda.ConvertDefaultAppointmentToDateTime(duration * 60);
        durationControl.SetDefaultValue({
            SubjectRecordId: this._component()._screenSubjectRecordId,
            SubjectEntityId: this._component()._screenSubjectEntityId,
            Data: durationModel,
            RecordSpecsModel: new RecordSpecsModel()
        });
    }

    private SetEditScreenValues(screen: IScreen, data: ButtonCellEditor) {
        this.SetStartingValue(screen, data._period.Start);
        this.SetDurationValue(screen, data._period.Duration);
    }

    private async ShowEditScreen(data: ButtonCellEditor) {
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

        BlockUI.Block();
        screenManager.GetEditScreen(this.AgendaRequestData)
            .always(() => {
                BlockUI.Unblock();
            })
            .then(screen => {
                screen.ShowInModal();
                this.SetEditScreenValues(screen, data);

                screen.On('LINK_LIST_DATA_LOADED', this, () => {
                    this.LinkResources(screen, data._linkedSubGroups);
                });

                screen.On('RECORD_SAVED', this, () => {
                    const notifier = new Notifier($(this._el));
                    if (this._isRefreshScreen){
                        this._form && this._form.GetScreen().Refresh();
                    } else {
                        this._component().UpdateSchedulerView();
                    }
                    notifier.Success(NOTIFICATIONS.RECORD_CREATED);
                });
            })
            .fail(err => new Notifier().Warning(err.message));
    }
}