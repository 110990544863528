import { deserialize, serialize } from 'libs/cerialize';

export class GenericButtonConfigModel {
    @serialize
    @deserialize
    QueryEntityGuid: string;

    @serialize
    @deserialize
    EntityId: number;

    @serialize
    @deserialize
    FieldId: number;

    @serialize
    @deserialize
    GenericButtonId: number;
}