import { deserialize } from 'libs/cerialize';
import { deserializeAs } from 'libs/cerialize';
import { FunctionDesignerMethodModel } from 'Core/Controls/FunctionDesigner/Models/FunctionDesignerMethodModel';

export class ParamValueModel {
	@deserialize
	Key: string;

	@deserialize
	Value: string;
}

export class FunctionExecutionModel {
	constructor() {
		this.OutParams = [];
		this.Data = [];
	}

	@deserialize
	Data: Array<Array<ParamValueModel>>;

	@deserialize
	OutParams: Array<string>;
}