import * as ko from 'knockout'

import 'lockr';

import {LOCAL_STORAGE} from 'Core/Common/Enums/LocalStorageItems';

import {BaseControl} from 'Core/Controls/BaseControl/BaseControl'
import {CONTROL_TYPES, EVENTS, RenderModes} from 'Core/Constant'
import {IControlParam, IForm} from 'Core/Screens/IScreen'
import {TabPage} from 'Core/Controls/TabPage/TabPage'
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';
import {IControl} from 'Core/Controls/IControl'
import {GlobalManager, GLOBALS} from 'Core/GlobalManager/GlobalManager';
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel';

import {
	ConfirmationDialog, EVENTS as CONFIRMATION_DIALOG_EVENTS,
	Types as ConfirmationTypes
} from "../../Components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import {LABELS, CONFIRMATIONS} from "Core/Components/Translation/Locales";
import {SpreadsheetControl} from "../Spreadsheet/SpreadsheetControl";

import ViewTemplate from 'Core/Controls/Tab/Templates/View.html'
import HelpViewTemplate from 'Core/Controls/Tab/Templates/HelpView.html'
import DesignTemplate from 'Core/Controls/Tab/Templates/Design.html'
import ToolBarTemplate from 'Core/Controls/Tab/Templates/ToolBar.html'
import {EntityTypes} from "Core/Controls/Grid/BaseGrid/Enums/EntityTypes";
import {AttachedFieldModel} from "Core/Controls/BaseControl/Models/AttachedFieldModel";
import {Agenda} from "../Agenda/Agenda";

ko.templates['Core/Controls/Tab/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Tab/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Tab/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/Tab/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/Tab/Templates/Design'] = DesignTemplate;

export const SpreadsheetStatusColor = {
	VALID: "#5ad539",
	NOT_VALID: "#FF3838",
	EMPTY: "#A4ABB6"
};

export class Tab extends BaseControl {
	private _currentTabPage: KnockoutObservable<TabPage> = ko.observable(null);
	private _currentTabIndex: number;
	private _dateRange: number;
	private _isHiddenExist: KnockoutObservable<boolean>;
	private _isShowHideButton: KnockoutObservable<boolean>;

	constructor(params: IControlParam) {
		super(params);
		this._currentTabIndex = 0;
		this._dateRange = 5;
		this._isHiddenExist = ko.observable(false);
		this._isShowHideButton = ko.observable(false);
		this.Init();
	}

    ApplyProperties(){}

	get Tabs() {
		return this._subControls;
	}

	GetForm(): IForm {
		return this._form;
	}

	get IsTabs(): boolean{
		return (this._subControls().length > 0);
	}

	SpreadsheetSetActiveTabPage(page: TabPage, pageHasSpreadsheet: boolean, subControlsArr: IControl[], eventArgs: any): void {
		const tabPage = <TabPage>eventArgs.source;

		const confirmationDialog = new ConfirmationDialog({
			Text: CONFIRMATIONS.YOU_CAN_LOSE_UNSAVED_DATA_IF_YOU_LEAVE,
			Type: ConfirmationTypes.Question,
			TextConfirm: LABELS.OK,
			TextDecline: LABELS.CANCEL
		});
		confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
			this.ChangeTabPageBackground(page, pageHasSpreadsheet, subControlsArr);
			this.SetActiveTabPage(tabPage, eventArgs.source._parentControl.GetControlId());
		});

		confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.DISCARD_SELECTED, this, () => {
			return;
		});
		confirmationDialog.Show();
	}

	IsSpreadsheetValid(page: TabPage) {
		_.each(page.GetSubControls(), control => {
			if (control instanceof SpreadsheetControl) {
				return control.IsValid();
			}
		});

		return true;
	}

	private Init(): void {
		const hidingDays = GlobalManager.Instance.GetGlobal(GLOBALS.TAB_HIDING_DAYS);
		const subControls = this._subControls();
        const controlId = this.GetControlId();

		if (hidingDays) {
			this._dateRange = parseInt(hidingDays);
		}

		const spreadsheetControls: IControl[] = _.filter(subControls,(control: IControl)=> control.HasSubControl('Spreadsheet'));
		const emptySpreadsheets: boolean = _.every(spreadsheetControls, (control)=> control.HasSubControl('Spreadsheet') && !control.GetModel().HasSpreadsheetData);
		const thereAreSeveralSpreadsheet = spreadsheetControls.length && spreadsheetControls.length > 1;
		// let isValidSpreadsheet: IControl = _.find(subControls,(control: IControl)=> control.GetModel().HasSpreadsheet && control.GetModel().HasSpreadsheetData);
		// let isValidGreySpreadsheet: IControl = _.find(subControls,(control: IControl)=> control.GetModel().HasSpreadsheet && control.GetModel()._tabSpreadsheetBackground() === '#5ad539');

		subControls.forEach((page: TabPage, num: number, subControlsArr: IControl[]) => {
			const pageHasSpreadsheet = page.HasSubControl('Spreadsheet');
			page.SetHasSpreadsheet(pageHasSpreadsheet);
			this.SetTabPageBackground(page, pageHasSpreadsheet, emptySpreadsheets);

            page.On(EVENTS.ON_TAB_CLICK, this, (eventArgs: any) => {
                const tabPage = <TabPage>eventArgs.source;
				let currentPageHasSpreadsheet: boolean = this._currentTabPage().HasSubControl('Spreadsheet');

				if (pageHasSpreadsheet && this._renderMode() === RenderModes.Edit && thereAreSeveralSpreadsheet){

					// _.each(page.GetSubControls(), (control)=>{
					// 	if (control instanceof SpreadsheetControl){
					// 		control.IsValid();
					// 		debugger;
					// 	}
					// })
					if (this.IsSpreadsheetValid(tabPage)) {
						this.ChangeTabPageSpreadsheetLogic(pageHasSpreadsheet, currentPageHasSpreadsheet, emptySpreadsheets, page, subControlsArr, eventArgs);
					}
				} else {
					if (currentPageHasSpreadsheet && this._renderMode() === RenderModes.Edit && thereAreSeveralSpreadsheet) {
						if (this.IsSpreadsheetValid(tabPage)) {
							this.SpreadsheetSetActiveTabPage(page, pageHasSpreadsheet, subControlsArr, eventArgs);
							return;
						}
					}

					if (this.IsSpreadsheetValid(tabPage)) {
						this.SetActiveTabPage(tabPage, eventArgs.source._parentControl.GetControlId());
					}
				}
            });
        });

		let validSpreadsheet = _.find(spreadsheetControls, (control: IControl)=> control.GetModel().HasSpreadsheetData);
		if (validSpreadsheet && thereAreSeveralSpreadsheet) {
			let spreadsheetActiveTabPage =  validSpreadsheet as TabPage;
			this.SetActiveTabPage(spreadsheetActiveTabPage, spreadsheetActiveTabPage.GetParentControl().GetControlId());
		}

		const tabsParams = UserVarsManager.Instance.GetTabsParams();
		const activityStorage = tabsParams ? tabsParams.tabsActivityStorage || {} : {};

		if (_.isEmpty(activityStorage[controlId])) {
			const startDate = new Date();
			this._isHiddenExist(false);
			startDate.setDate(startDate.getDate() - 1);
			activityStorage[controlId] = {};
            subControls.forEach(control => activityStorage[controlId][control.GetControlId()] = startDate.getTime());

			UserVarsManager.Instance.SetTabsParams(null, activityStorage);
		} else {
			this.SetTabsHidden();
		}

		let tab = this.GetSourceTab() || this.GetPreselectTab();
		const activeTab = tab as TabPage;
		Lockr.rm(LOCAL_STORAGE.HYPERLINK_SOURCE);

		if (activeTab) {
			this.ActiveTabSetSpreadsheetBackground(activeTab);
			activeTab.SetIsVisible(true);
			this.SetActiveTab(subControls.indexOf(activeTab));
			this.SetTabIsVisible(<TabPage>activeTab);

            this.SetActiveTabPage(<TabPage>activeTab);
		} else {
            const firstVisible: IControl | TabPage = subControls.find((subControl: IControl | TabPage) => subControl.GetIsVisible());

            if (firstVisible) {
				this.ActiveTabSetSpreadsheetBackground(firstVisible as TabPage);
                this.SetActiveTabPage(<TabPage>firstVisible);
            } else if (subControls.length >= 1) {
				this.ActiveTabSetSpreadsheetBackground(subControls[0] as TabPage);
                subControls[0].SetIsVisible(true);
                this.SetActiveTabPage(<TabPage>subControls[0]);
			}
		}
	}

	private ActiveTabSetSpreadsheetBackground(activeTab: TabPage): void {
		if (activeTab && activeTab.HasSubControl('Spreadsheet')) {
			activeTab.SetSpreadsheetBackground(SpreadsheetStatusColor.VALID);
		}
	}

	private GetPreselectTab() {
		let preSelectedTab = UserVarsManager.Instance.GetTabsParams();
		if (preSelectedTab && this._form) {
			const preselectedTabData: any = _.where(preSelectedTab.activeTabPageCIDs, {screenId: this._form.GetScreen().GetScreenId()})[0];
			if (!preselectedTabData) {
				return null;
			}
			var tabToSelect = <TabPage>this._subControls().filter((control) => {
				return control.GetControlId() === preselectedTabData.activeTabPageCID;
			})[0];
			if (tabToSelect) {
				return tabToSelect;
			}
		}

		return null;
	}

	private GetSourceTab() {
		const sourceEntityId = parseInt(Lockr.get(LOCAL_STORAGE.HYPERLINK_SOURCE));

		if (!sourceEntityId) {
			return null;
		}

		const tabPageControls = this.GetSubControls();
		const tabPageSubControls = _.flatten(tabPageControls.map(tabPage => tabPage.GetSubControls()));
		const gridControls = tabPageSubControls.filter(control => control.GetType() === CONTROL_TYPES.Grid);

		const sourceGrid = _.find<BaseControl>(gridControls, grid => {
			const attachedField = _.find(grid.Model.Fields, (field: AttachedFieldModel) => field.EntityTypeName === EntityTypes[EntityTypes.Entity]);
			return attachedField && attachedField.EntityId === sourceEntityId;
		});

		return sourceGrid && sourceGrid.GetParentControl();
	}

	SetTabsHidden() {
		const tabParams = UserVarsManager.Instance.GetTabsParams();
		const activityStorage = tabParams ? tabParams.tabsActivityStorage : {};
		const subControls = this._subControls();
		const controlId = this.GetControlId();

		if(subControls.length > 1) {
			let hiddenTabs = 0;
			subControls.forEach(control => {
                const activeDate = activityStorage[controlId][control.GetControlId()];
                if (activeDate) {
                    const oneDay = 86400000;
                    const date1 = new Date(activeDate);
                    const date2 = new Date();
                    const diffDays = Math.round(Math.abs((date2.getTime() - date1.getTime()) / (oneDay)));

                    if (diffDays > this._dateRange && !control.GetIsActive()) {
                        control.SetIsVisible(false);
                        this._isHiddenExist(true);
                        hiddenTabs++
                    }
                }
			});
			if(hiddenTabs === subControls.length) {
                const activeTab = <TabPage>(subControls.find(control => control.GetIsActive()) || subControls[0]);
                this.SetActiveTabPage(activeTab);
                this.SetTabIsVisible(activeTab);
			}
		}

		this._isShowHideButton(false);
	}

	SetActiveTabPage(tabPage: TabPage, parentControlId?: number) {
        const controlId = this.GetControlId();
        const subControls = this._subControls();
		const currentPage = this._currentTabPage();
        const tabControlId = tabPage.GetControlId();
        const activeSubControl = tabPage.GetAllSubControls().find(control => control.GetIsActive());
        const firstSubControl = tabPage.GetAllSubControls().length && tabPage.GetAllSubControls().length > 1 ? tabPage.GetAllSubControls()[1].GetControlId() : null;
        const activeSubControlId = (activeSubControl) ? activeSubControl.GetControlId() : firstSubControl;
        const storageTabsParams = UserVarsManager.Instance.GetTabsParams();
        const activityStorage = storageTabsParams ? storageTabsParams.tabsActivityStorage : {};
        const isShowHideButton = this._isShowHideButton();

		if (currentPage) {
            currentPage.SetIsActive(false);
		}

		tabPage.SetIsActive(true);

		this._currentTabIndex = this._subControls().indexOf(tabPage);
		this._currentTabPage(tabPage);

		if (parentControlId) {
			if (!activityStorage[parentControlId]) {
				activityStorage[parentControlId] = {};
			}
			activityStorage[parentControlId][tabControlId] = Date.now();
		}

		const screen = this._form && this._form.GetScreen();

		if (screen) {
			UserVarsManager.Instance.SetTabsParams({
				screenId: screen.GetScreenId(),
				activeTabPageCID: tabControlId
			}, activityStorage);
			screen.SetState( {ActiveControlId: tabControlId, ActiveSubControlId: activeSubControlId});
		}

		if(isShowHideButton) {
			let i = 0;
			let needToHideButton = true;
			while (i < subControls.length) {
				const control = subControls[i];
				const activeDate = activityStorage[controlId][control.GetControlId()];
				if (activeDate) {
					const oneDay = 86400000;
					const date1 = new Date(activeDate);
					const date2 = new Date();
					const diffDays = Math.round(Math.abs((date2.getTime() - date1.getTime()) / (oneDay)));

                    if (diffDays >= this._dateRange) {
                        needToHideButton = false;
                        break;
					}
				}
				i++;
			}
        	needToHideButton && this._isShowHideButton(false);
		}
	}

	SetActiveTab(tabIndex: number, defaultParams?: {controlId: number, params, action? :string, control?: any}) {
		const subControls = this._subControls();
		if (subControls[tabIndex]) {
			const tab = <TabPage>subControls[tabIndex];
			if (this._currentTabPage()) {
				this._currentTabPage().SetIsActive(false);
			}
			tab.SetIsActive(true);

            this._currentTabPage(tab);
            this._currentTabIndex = tabIndex;

			if (defaultParams) {
				switch (defaultParams.action) {
					case 'OpenReplanMode' : {
                        if (defaultParams.control) {
                            defaultParams.control.SetRePlanMode(defaultParams.params);
                        }
						break;
					}
                    case 'ReturnFromScheduler' : {
						//go to agenda
                        const controlAgenda = _.find(tab.GetSubControls(), (item) => {
                            return (item.GetModel().Id === defaultParams.controlId) && (item.GetType() === CONTROL_TYPES.Agenda);
                        }) as Agenda;

                        if (controlAgenda) {
                            controlAgenda.ReturnFromScheduler(defaultParams.params);
                        }
                        break;
                    }
                    case 'HyperlinkAgenda' : {
						//go to agenda
                        const controlAgenda = _.find(tab.GetSubControls(), (item) => {
                            return (item.GetModel().Id === defaultParams.controlId) && (item.GetType() === CONTROL_TYPES.Agenda);
                        }) as Agenda;
                        if (controlAgenda) {
                            controlAgenda.SetStarting(defaultParams.params.starting);
                        }
                        break;
                    }
					default : break;
				}
			}
		}
	}

	SetValue(value: any): void {
	}

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}

	AddSubControl(control: IControl) {
		var tabPage = control as TabPage;
		this._subControls.push(control);

		if (this._currentTabPage()) {
			this._currentTabPage().SetIsActive(false);
		}
		tabPage.SetIsActive(true);
		this._currentTabPage(tabPage);

		tabPage.On(EVENTS.ON_TAB_CLICK, this, (evntArgs: any) => {
			var tabPage = <TabPage>evntArgs.source;

			if (this._currentTabPage()) {
				this._currentTabPage().SetIsActive(false);
			}
			tabPage.SetIsActive(true);
			this._currentTabPage(tabPage);
		});
	}


	RemoveControl(control: IControl) {
		var wrappers = new Array<Element>(control.GetWrapper());

		_.each(this._currentTabPage().GetAllSubControls(),
			(subControl) => {
				wrappers.push(subControl.GetWrapper());
			});

		wrappers.map((el) => {
			$(control.GetWrapper()).add(el).fadeOut('slow', () => {
				var subControls = this._subControls();
				var tabPage = control as TabPage;
				this._subControls.remove(control);

				if (tabPage.GetIsActive()) {
					if (this._currentTabIndex <= subControls.length - 1) {
						this.SetActiveTab(this._currentTabIndex);
					} else if (this._currentTabIndex > 0) {
						this.SetActiveTab(this._currentTabIndex - 1);
					} else {
						this._currentTabPage(null);
					}
				} else {
					this.SetActiveTabPage(this._currentTabPage());
				}

				(this.GetForm().GetScreen() as any).OnControlRemoved(control);
			});
		});
	}

	get CurrentTabPage(): TabPage {
		return this._currentTabPage();
	}

	ShowAll() {
		_.each(this._subControls(), subControl => {
			subControl.SetIsVisible(true);
		});
		this._isHiddenExist(false);
		this._isShowHideButton(true);
	}

	SetTabIsVisible(control: IControl) {
		control.SetIsVisible(true);
		this.SetActiveTabPage(<TabPage>control, this.GetControlId());
		this._isHiddenExist(this._subControls().some(x => !x.GetIsVisible()));
		if (!this._subControls().some(x => !x.GetIsVisible())) {
			this._isShowHideButton(false);
		}

	}

	SetTabPageBackground(page: TabPage, pageHasSpreadsheet: boolean, emptySpreadsheets: boolean): void {
		const model: ControlModel = page.GetModel();
		if (pageHasSpreadsheet) {
			let background: string = emptySpreadsheets ? SpreadsheetStatusColor.EMPTY : model.HasSpreadsheetData ? SpreadsheetStatusColor.VALID : SpreadsheetStatusColor.NOT_VALID;
			if (page.IsActiveTabPage) {
				background = model.HasSpreadsheetData ? SpreadsheetStatusColor.VALID : SpreadsheetStatusColor.NOT_VALID;
			}
			page.SetSpreadsheetBackground(background);
		}
	}

	ChangeTabPageBackground(page: TabPage, pageHasSpreadsheet: boolean, subControlsArr: IControl[]): void {
		if (pageHasSpreadsheet){
			let allSpreadsheetsExceptSelectedOne = _.filter(subControlsArr,(control: IControl)=> control.HasSubControl('Spreadsheet') && control.GetGuid() != page.GetGuid());
			allSpreadsheetsExceptSelectedOne.forEach((pageControl: TabPage) => pageControl.SetSpreadsheetBackground(SpreadsheetStatusColor.NOT_VALID));
			page.SetSpreadsheetBackground(SpreadsheetStatusColor.VALID);
		}
	}

	ChangeTabPageSpreadsheetLogic(
		pageHasSpreadsheet: boolean,
		currentPageHasSpreadsheet: boolean,
		emptySpreadsheets: boolean,
		page: TabPage,
		subControlsArr: IControl[],
		eventArgs: any): void {

		const tabPage = <TabPage>eventArgs.source;

		if (page.Guid === this._currentTabPage().Guid) {
			return;
		}

		if (currentPageHasSpreadsheet) {
			this.SpreadsheetSetActiveTabPage(page, pageHasSpreadsheet, subControlsArr, eventArgs);
			return;
		}

		if (!currentPageHasSpreadsheet && pageHasSpreadsheet) {
			if (emptySpreadsheets) {
				this.ChangeTabPageBackground(page, pageHasSpreadsheet, subControlsArr);
				this.SetActiveTabPage(tabPage, eventArgs.source._parentControl.GetControlId());
				return;
			}

			this.ChangeTabPageBackground(page, pageHasSpreadsheet, subControlsArr);
			this.SetActiveTabPage(tabPage, eventArgs.source._parentControl.GetControlId());
		}

	}
}