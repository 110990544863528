import { Modal } from "../../../Core/Common/Modal";
import Template   from "DatabaseDesigner/Components/Assistant/GeneratedTablesTemplate.html";
import { DatabaseModel } from "../../../Core/CyberConfigManager/Models/DatabaseModel";
import {Event} from 'Core/Common/Event';

ko.templates["DatabaseDesigner/Components/Assistant/GeneratedTablesTemplate"] = Template;

export class GeneratedTables extends Event {
    private _modal: Modal;
    private _database: DatabaseModel;
    constructor(database: DatabaseModel){
        super();
        this._database = database;
    }
   
    Show(){

      this._modal = new Modal({
        width: 450,
        minHeight: 800,
        minWidth: 300,
        maxHeight: 300
      }, false);

      ko.cleanNode(this._modal.Wrapper);
      ko.applyBindings(this, this._modal.Wrapper);      
      this._modal.Show();
    }

    GetTemplateName(){
      return 'DatabaseDesigner/Components/Assistant/GeneratedTablesTemplate';
    }

    OkBtnClick(){
        this._modal.Close();
        this.Trigger('SAVE')
    }
}