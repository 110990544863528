import { ZIndexManager } from 'Core/Common/ZIndexManager';
import * as $ from "jquery";
import "jqueryBlockui";
import { Counter } from "Core/Common/Counter";

export interface IBlockUIOptions {
	Target?: HTMLElement;
	TextOnly?: boolean;
	TextMessage?: string;
	OpacityMessage?: boolean;
	CenterY?: boolean;
	Boxed?: boolean;
	ZIndex?: number;
	PaddingRootElement?: number;
	BackgroundColor?: string;
	OverlayColor?: string;
	Cursor?: string;
	JBoxBlockButton?: boolean;
}

export interface ITargetObject {
	Target: HTMLElement;
}

export class BlockUI {
	static Block(options?: IBlockUIOptions): void {
		let html;
		options = options || {};

		if (options.TextOnly) {
			if (!!options.TextMessage){
				html = '<div class="loading-message textMessageBox '+ (options.OpacityMessage ? 'opacityMessage' : '') + (options.Boxed ? 'loading-message-boxed' : '') + '">'+ options.TextMessage +'</div>';
			} else {
				html = '<div class="loading-message ' + (options.Boxed ? 'loading-message-boxed' : '') + '"></div>';
			}
		} else {
			html = '<div class="loading-message ' + (options.Boxed ? 'loading-message-boxed' : '') + '"><div class="sk-spinner sk-spinner-fading-circle"><div class="sk-circle1 sk-circle"></div><div class="sk-circle2 sk-circle"></div><div class="sk-circle3 sk-circle"></div><div class="sk-circle4 sk-circle"></div><div class="sk-circle5 sk-circle"></div><div class="sk-circle6 sk-circle"></div><div class="sk-circle7 sk-circle"></div><div class="sk-circle8 sk-circle"></div><div class="sk-circle9 sk-circle"></div><div class="sk-circle10 sk-circle"></div><div class="sk-circle11 sk-circle"></div><div class="sk-circle12 sk-circle"></div></div></div>';
		}

		if (options.Target) {
			BlockUI.BlockTarget(options, html);
		} else {
			BlockUI.BlockPage(options, html);
		}
	}

	static TransparentBlock(target?: HTMLElement, options?: IBlockUIOptions): void {
		options = options || {};
		let transparentOptions = {
			Target: target,
			TextOnly: true,
			TextMessage: options.TextMessage || null,
			OpacityMessage: options.OpacityMessage || false,
			CenterY: options.CenterY || false,
			Boxed: options.Boxed || false,
			PaddingRootElement: options.PaddingRootElement || null,
			ZIndex: options.ZIndex || 1010,
			BackgroundColor: options.BackgroundColor || 'transparent',
			OverlayColor: options.OverlayColor || 'transparent',
			Cursor: options.Cursor || 'normal',
		};
		BlockUI.Block(transparentOptions);
	}

	static Unblock(target?: HTMLElement, jBoxBlockButton?:boolean, callBack?: Function): void {
		if (target) {
			$(target).unblock({
				onUnblock: () => {
					$(target).css('position', '');
					$(target).css('zoom', '');
					callBack && callBack();
					if (jBoxBlockButton){
						this.JBoxToggleCloseButton(target, true);
					}
				},
			});
		} else {
			$.unblockUI();
		}
	}

	private static JBoxToggleCloseButton(target: HTMLElement, show?: boolean){
		let el = $(target);
		let jBox = el.closest('.jBox-wrapper');
		if (jBox.length === 1){
			let closeButton = jBox.find('.jBox-closeButton');

			if (closeButton.length === 1){

				if (show){
					closeButton.attr('data-BlockUiCounter', Counter.Instance.PrevValue);
					if ( Number(closeButton.attr('data-BlockUiCounter')) == 0) {
						$(closeButton).removeClass('blockButton');
					}
				} else {
					closeButton.attr('data-BlockUiCounter', Counter.Instance.NextValue);
					$(closeButton).css('zIndex', ZIndexManager.Instance.NextValue);
					$(closeButton).addClass('blockButton');
				}
			}

		}
	}

	private static BlockTarget(options: IBlockUIOptions, html: string): void {
		let el = $(options.Target);

		if (!!options.PaddingRootElement){
			el.css({'padding': options.PaddingRootElement + 'px'})
		}

		if (el.height() <= ($(window).height())) {
			options.CenterY = true;
		}

		el.block({
			message: html,
			baseZ: options.ZIndex ? options.ZIndex : 1010,
			centerY: options.CenterY !== undefined ? options.CenterY : false,
			css: {
				top: '10%',
				border: '0',
				padding: '0',
				backgroundColor: options.BackgroundColor,
				cursor: options.Cursor ? options.Cursor : 'wait'
			},
			overlayCSS: {
				backgroundColor: options.OverlayColor ? options.OverlayColor : '#000000',
				// opacity: options.Boxed ? 0.05 : 0.1,
				opacity: .5,
				cursor: options.Cursor ? options.Cursor : 'wait'
			}
		});
		if (options.JBoxBlockButton){
			this.JBoxToggleCloseButton(options.Target);
		}
	}

	private static BlockPage(options: IBlockUIOptions, html: string): void {
		$.blockUI({
			message: html,
			baseZ: options.ZIndex ? options.ZIndex : ZIndexManager.Instance.NextValue,
			css: {
				border: '0',
				padding: '0',
				backgroundColor: 'none',
				cursor: options.Cursor ? options.Cursor : 'wait'
			},
			overlayCSS: {
				backgroundColor: options.OverlayColor ? options.OverlayColor : '#000000',
				// opacity: options.Boxed ? 0.05 : 0.1,
				opacity: .5,
				cursor: options.Cursor ? options.Cursor : 'wait'
			}
		});
	}

	static GetTargetObjectFromTarget(target: HTMLElement): ITargetObject | {} {
		return target ? {Target: target} : {};
	}
} 