import { P } from "Core/Common/Promise";
import { URL } from "Core/Common/Url";
import { IRequestOptions, Request } from "Core/Common/Request";

export class HelpBookStore {
	static GetScreens(model): P.Promise<any> {
		var deferredResult = P.defer<any>();


		var requestParams: IRequestOptions = {
			proxy: {
				url: "api/HelpPagesApi/GetScreens",
				dataType: 'json',
				type: 'POST'
			},
			params: model
		};
		Request.Send(requestParams)
			.then(data =>
				deferredResult.resolve(data));

		return deferredResult.promise();

	}


	static GetEntities(): P.Promise<any> {
		var deferredResult = P.defer<any>();


		var requestParams: IRequestOptions = {
			proxy: {
				url: "api/HelpPagesApi/GetEntities",
				dataType: 'json',
				type: 'GET'
			}
		};
		Request.Send(requestParams)
			.then(data =>
				deferredResult.resolve(data));

		return deferredResult.promise();

	}


	static GetEntityInfo(model): P.Promise<any> {
		var deferredResult = P.defer<any>();


		var requestParams: IRequestOptions = {
			proxy: {
				url: "api/HelpPagesApi/GetEntityInfo",
				dataType: 'json',
				type: 'POST'
			},
			params: { id: model.id }
		};
		Request.Send(requestParams)
			.then(data =>
				deferredResult.resolve(data));

		return deferredResult.promise();

	}

	static GetTypeInfo(model): P.Promise<any> {
		var deferredResult = P.defer<any>();


		var requestParams: IRequestOptions = {
			proxy: {
				url: "api/HelpPagesApi/GetTypeInfo",
				dataType: 'json',
				type: 'POST'
			},
			params: { id: model.id }
		};
		Request.Send(requestParams)
			.then(data =>
				deferredResult.resolve(data));

		return deferredResult.promise();

	}

	static GetScreenInfo(model): P.Promise<any> {
		var deferredResult = P.defer<any>();


		var requestParams: IRequestOptions = {
			proxy: {
				url: "api/HelpPagesApi/GetScreenInfo",
				dataType: 'json',
				type: 'POST'
			},
			params: { id: model.id }
		};
		Request.Send(requestParams)
			.then(data =>
				deferredResult.resolve(data));

		return deferredResult.promise();

	}


	static GetControlInfo(model): P.Promise<any> {
		var deferredResult = P.defer<any>();


		var requestParams: IRequestOptions = {
			proxy: {
				url: "api/HelpPagesApi/GetControlInfo",
				dataType: 'json',
				type: 'POST'
			},
			params: { id: model.id }
		};
		Request.Send(requestParams)
			.then(data =>
				deferredResult.resolve(data));

		return deferredResult.promise();

	}

} 

