import * as _ from 'underscore';

import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {GenericDeserialize} from 'libs/cerialize';
import {ProfileCardModel} from 'Core/ProfilePage/ProfileCard/Models/ProfileCardModel';
import {ProfilePasswordModel} from 'Core/ProfilePage/ProfileCard/Models/ProfilePasswordModel';



export class ProfileCardStore {
	static Get(): P.Promise<ProfileCardModel> {
		var deferredResult = P.defer<ProfileCardModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: "api/UserApi/GetUserProfile",
				dataType: "json",
				type: "GET"
			}
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(this.Deserialize(data.Data));
			});

		return deferredResult.promise();
	}

	static ChangePassword(params: ProfilePasswordModel): P.Promise<any> {
		var deferredResult = P.defer<ProfileCardModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: "api/UserApi/ChangePassword",
				dataType: "json",
				type: "POST"
			}, params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
			});

		return deferredResult.promise();
	}

	static Deserialize(jsonObject: any): ProfileCardModel {
		var model = GenericDeserialize<ProfileCardModel>(jsonObject, ProfileCardModel);
		return model;
	}
	
}