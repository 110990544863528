import {SpreadsheetDimensionDirections} from '../../Enums/SpreadsheetDimensionDirections';
import {SpreadsheetCellPosition} from './SpreadsheetCellPosition';
import {SpreadsheetFieldDescriptor} from './SpreadsheetFieldDescriptor';

export class SpreadsheetDimensionDescriptor {
    constructor(public Field: SpreadsheetFieldDescriptor,
                public Direction: SpreadsheetDimensionDirections,
                public Number: number,
                public StartPosition: SpreadsheetCellPosition) {
    }
}