import * as _ from 'underscore';
import * as  moment from 'moment';

import {AllowedEntity, DayOfWeekStatistics, LookupField, Reservation, TranslatedField} from './DayDataViewModel';
import {UsersViewModel} from './UsersViewModel';
import {DATE_FORMATS} from '../../../../../Constants/DateTimeFormats';

export class WeekDataViewModel {
    Week: number;
    Days: DayOfWeekViewModel[];
    DaysOfWeek: DayOfWeekStatistics[];
    Users: UsersViewModel;
    AllowedEntities: AllowedEntity[];
    LookupFields: LookupField[];
    TranslatedFields: TranslatedField[];

    constructor() {
        this.Days = [];
        this.DaysOfWeek = [];
        this.Users = null;
        this.AllowedEntities = [];
        this.TranslatedFields = [];
    }

    get WeekNumber() {
        return `${this.Week}`;
    }

    get WeekDescription() {
        const formattedDate = moment(this.DaysOfWeek[0].Date).format(DATE_FORMATS.LONG_DATE_SHORT_WEEK.MomentFormat);
        return `${formattedDate}`;
    }

    get Booked() {
        const newSum = (sum: number, booked: any) => Math.round((sum + booked) * 100) / 100;
        this.IsWeekend();
        return this.DaysOfWeek.reduce((sum: number, current: DayOfWeekStatistics) => newSum(sum, current.Booked), 0);
    }

    get SelectedUser() {
        return this.Users.SelectedUser();
    }

    get Reservations() {
        return _.chain(this.Days).map(day => day.Reservations).flatten().value();
    }

    IsActive(day: DayOfWeekStatistics) {
        return day.Date.getDate() === new Date().getDate();
    }

    IsNormBooked(day: DayOfWeekStatistics) {
        return day.Booked >= 8;
    }

    IsWeekend() {
        _.each(this.DaysOfWeek,
            day => {
                if (moment(day.Date).isoWeekday() === 6 || moment(day.Date).isoWeekday() === 7) {
                    day.Weekend = true;
                }
            });
    }
}

export class DayOfWeekViewModel {
    Day: string;
    Reservations: Reservation[];

    constructor() {
        this.Reservations = [];
    }
}