import { P } from 'Core/Common/Promise';
import {Deserialize, GenericDeserialize} from 'libs/cerialize';
import { BoldBISettings } from 'Pages/BI/Models/BISettings';
import {URL} from 'Core/Common/Url';
import {Request} from 'Core/Common/Request';
import { List } from 'underscore';
import { Dashboard } from '../Models/Dashboard';

export class BIStore {

    static GetItems(): P.Promise<Array<Dashboard>> {
		let deferredResult = P.defer<Array<Dashboard>>();

		Request
		.For(URL.BI.GET_ITEMS)
		.Get()
        .fail(error=>deferredResult.reject({ message: error.message}))
		.then(data => deferredResult.resolve(Deserialize(data, Dashboard)));

        return deferredResult.promise();
    }

    static GetSettings(): P.Promise<BoldBISettings> {
		let deferredResult = P.defer<BoldBISettings>();

		Request
		.For(URL.BI.GET_SETTINGS)
		.Get()
        .fail(error=>deferredResult.reject({ message: error.message}))
		.then(data => deferredResult.resolve(GenericDeserialize<BoldBISettings>(data, BoldBISettings)));

        return deferredResult.promise();
    }

	static GetToken(): P.Promise<string> {
		let deferredResult = P.defer<string>();

		Request
		.For(URL.BI.GET_TOKEN)
		.Get()
        .fail(error=>deferredResult.reject({ message: error.message}))
		.then(data => console.log(data));

        return deferredResult.promise();
    }
}