import * as ko from 'knockout'
import * as _ from 'underscore'
import {Modal} from 'Core/Common/Modal'
import {Event} from 'Core/Common/Event'

import SignatureEditScreenTemplate from 'Core/Controls/Signature/SignatureEditScreen/Templates/SignatureEditScreen.html';
import {IControlParam} from "../../../Screens/IScreen";
import {GlobalManager, GLOBALS} from "../../../GlobalManager/GlobalManager";
import SignaturePad from "../../../../libs/signature/signature_pad";
import {Notifier} from 'Core/Common/Notifier'
import {ImageDataModel} from "../../Image/Models/ImageDataModel";
import {MobileChecker} from "Core/Common/MobileChecker";

ko.templates['Core/Controls/Signature/SignatureEditScreen/Templates/SignatureEditScreen'] = SignatureEditScreenTemplate;

//https://github.com/szimek/signature_pad
export class SignatureEditScreen extends Event {
    private _modal: Modal;
    private _isModal: boolean;
    private _iControlParams: IControlParam;
    private _labelName: string;
    protected _isIconVisible: boolean;
    private _signaturePad: SignaturePad;
    private _dataModel: KnockoutObservable<ImageDataModel>;

    constructor(params: IControlParam, dataModel: ImageDataModel) {
        super();

        this._iControlParams = params;
        this._labelName = this.GetName();
        this._isIconVisible = GlobalManager.Instance.GetGlobal(GLOBALS.SHOW_CONTROL_ICONS) !== '0';

        this._dataModel = ko.observable(null);
        const newDataModel = new ImageDataModel();

        if (dataModel && dataModel.Files.length > 0){
            this._dataModel(dataModel);
        } else {
            this._dataModel(newDataModel);
        }

        this._isModal = false;
        this.AddEvent('SIGNATURE_IMAGE_SAVE')

    }

    GetName(): string{
        let name =  this._iControlParams.Model.Name;

        if (this._iControlParams.Model.NameTranslations){
            _.each(this._iControlParams.Model.NameTranslations, item => {
                if (item.Selected && item.Translation && item.Translation !== '' && item.Translation !== null) {
                    name = item.Translation;
                    return;
                }
            });
        }

        return name;
    }

    GetTemplateName(): string {
        return 'Core/Controls/Signature/SignatureEditScreen/Templates/SignatureEditScreen';
    }

    Show() {
        let defaultOptions =  {
            addClass: 'signaturePadModal',
            width: MobileChecker.IsMobile() ? '95vw' : 450,
            height: MobileChecker.IsMobile() ? '60vh' : 330
        };

        this._modal = new Modal(_.extend(defaultOptions), false, false);

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
        this._isModal = true;
        this._modal.On('CLOSE', this, (eventArgs: any) => {
            this.Close();
        });

        this.InitSignature();
    }
    private ResizeCanvas(canvas){
        // Adjust canvas coordinate space taking into account pixel ratio,
        // to make it look crisp on mobile devices.
        // This also causes canvas to be cleared.

        // When zoomed out to less than 100%, for some very strange reason,
        // some browsers report devicePixelRatio as less than 1
        // and only part of the canvas is cleared then.
        var ratio =  Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = null;
        canvas.height = null;
        // This part causes the canvas to be cleared
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
        this._signaturePad.clear(); // otherwise isEmpty() might return incorrect value
    }

     private InitSignature(){
        let wrapper = document.getElementById("signature-pad");
        if (wrapper){
            let canvas = wrapper.querySelector("canvas");
            if (canvas){
                this._signaturePad = new SignaturePad(canvas,{
                    backgroundColor: 'rgb(255, 255, 255)'
                });
                this.ResizeCanvas(canvas);

                if (this._dataModel() && this._dataModel().Files.length > 0){
                    this._signaturePad.fromDataURL(this._dataModel().Files[0].ImageData);
                }
            }
        }
    }

    Close(): void {
        if (this._modal) {
            $(window).off('keydown');
            this._modal.Close();
        }
    }

    Clear(){
        if (!this._signaturePad.isEmpty()){
            this._signaturePad.clear();
        } else {
            let notifier = new Notifier();
            notifier.Warning('The canvas is already empty');
            return;
        }
    }

    Save(){
        let dataImageString = this._signaturePad.toDataURL("image/jpeg"); // save JPG
        this.Trigger('SIGNATURE_IMAGE_SAVE', {SignatureImage: dataImageString});
    }

    AfterRender(el: Array<HTMLElement>) {
        this._modal.Show();
    }

}