import * as ko from 'knockout';
import * as moment from 'moment';

import Template from 'QueryBuilder/QueryCondition/ConditionEditors/Date/Templates/Date.html';

import {QueryConditionItemModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryConditionItemModel';

export class Date {
	Value: KnockoutObservable<any>;
	private _el: HTMLElement;
	private _datetimeFormat: string;
	private _date: string;
	private _initialState: boolean;

	constructor(model: QueryConditionItemModel, datetimeFormat: string, date: string) {
		this.Value = ko.observable('');
		this._date = date;
		this._datetimeFormat = datetimeFormat;
		this._initialState = true;
	}

	GetTemplate() {
		return Template;
	}

	GetDateFormat() {
		return this._datetimeFormat;
	}

	GetLocale() {
		return moment.locale();
	}

	Update() {
		if (!this._initialState || $(this._el).val()) {
			const newValue = $(this._el).val();
			this.Value(newValue);
		}
		this._initialState = false;
	}

	AfterRender(el: Array<HTMLElement>) {
		this._el = el[0];
		$(this._el).val(this._date);
	}
} 