import { Notifier } from "Core/Common/Notifier";
import { ON_CLUSTERED_PIVOT_FINISHED, ON_LINK_EDITOR_GRID_SAVE, ON_LINK_EDITOR_UNLINK } from "./Triggers";
import { IExecuteClusteredPivotFinishedTriggerDto, IExecuteLinkEditorGridSaveTriggerDto, IExecuteLinkEditorUnlinkTriggerDto, IParamModel, TriggerStore } from "./TriggerStore";

export interface IOnLinkEditorGridSaveTrigger {
    LeftEntityId: number;
    RightEntityId: number;
    RelationTypeId: number;
    GridEntityId: number;
}

export interface IOnLinkEditorUnlinkTrigger {
    LeftEntityId: number;
    RightEntityId: number;
    RelationTypeId: number;
    GridEntityId: number;
}

export interface IOnClusteredPivotFinishedTrigger{
    MainEntityId: number,
    MainRecordId: number,
    RelatedEntityId: number,
    RelatedRecordId: number,
    NewKSeq: number,
    OldKSeq: number,
    ControlId: number
}

export class TriggerManager {
    constructor(){

    }

    public static ExecuteOnClickTrigger(){

    }

    public static async ExecuteOnLinkEditorGridSaveTrigger(options: IOnLinkEditorGridSaveTrigger, objectId: string){        
        let triggerParams: Array<IParamModel> = [
            { Name: ON_LINK_EDITOR_GRID_SAVE.LEFT_ENTITY_ID, Value: options.LeftEntityId },
            { Name: ON_LINK_EDITOR_GRID_SAVE.RIGHT_ENTITY_ID, Value: options.RightEntityId },
            { Name: ON_LINK_EDITOR_GRID_SAVE.RELATION_TYPE_ID, Value: options.RelationTypeId },
            { Name: ON_LINK_EDITOR_GRID_SAVE.GRID_ENTITY_ID, Value: options.GridEntityId }
        ]

        let request: IExecuteLinkEditorGridSaveTriggerDto = {
            Name: ON_LINK_EDITOR_GRID_SAVE.NAME,
            ObjectId: objectId,
            Params: triggerParams
        }

        return await TriggerStore.ExecuteLinkEditorGridSaveTrigger(request).fail((err) => new Notifier().Failed(err.message));
    }

    
    public static async ExecuteOnLinkEditorUnlinkTrigger(options: IOnLinkEditorUnlinkTrigger, objectId: string){        
        let triggerParams: Array<IParamModel> = [
            { Name: ON_LINK_EDITOR_UNLINK.LEFT_ENTITY_ID, Value: options.LeftEntityId },
            { Name: ON_LINK_EDITOR_UNLINK.RIGHT_ENTITY_ID, Value: options.RightEntityId },
            { Name: ON_LINK_EDITOR_UNLINK.RELATION_TYPE_ID, Value: options.RelationTypeId },
            { Name: ON_LINK_EDITOR_UNLINK.GRID_ENTITY_ID, Value: options.GridEntityId }
        ]

        let request: IExecuteLinkEditorUnlinkTriggerDto = {
            Name: ON_LINK_EDITOR_UNLINK.NAME,
            ObjectId: objectId,
            Params: triggerParams
        }

        return await TriggerStore.ExecuteLinkEditorUnlinkTrigger(request).fail((err) => new Notifier().Failed(err.message));
    }

    public static async OnClusteredPivotFinished(options: IOnClusteredPivotFinishedTrigger, objectId: string, triggerId: number){
        let triggerParams: Array<IParamModel> = [
            { Name: ON_CLUSTERED_PIVOT_FINISHED.MAIN_RECORD_ID, Value: options.MainRecordId },
            { Name: ON_CLUSTERED_PIVOT_FINISHED.MAIN_ENTITY_ID, Value: options.MainEntityId },
            { Name: ON_CLUSTERED_PIVOT_FINISHED.RELATED_RECORD_ID, Value: options.RelatedRecordId },
            { Name: ON_CLUSTERED_PIVOT_FINISHED.RELATED_ENTITY_ID, Value: options.RelatedEntityId },
            { Name: ON_CLUSTERED_PIVOT_FINISHED.CONTROL_ID, Value: options.ControlId },
            { Name: ON_CLUSTERED_PIVOT_FINISHED.NEW_K_SEQ, Value: options.NewKSeq },
            { Name: ON_CLUSTERED_PIVOT_FINISHED.OLD_K_SEQ, Value: options.OldKSeq }
        ];

        let request: IExecuteClusteredPivotFinishedTriggerDto = {
            Id: triggerId,
            ObjectId: objectId,
            Params: triggerParams
        }

        return await TriggerStore.ExecuteClusteredPivotFinishedTrigger(request).fail((err) => new Notifier().Failed(err.message));
    }
}