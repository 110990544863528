import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { Guid } from 'Core/Common/Guid';
import { FieldMetadataModel } from 'Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel';

export class ConditionItemField {
	id: string;
	private _queryEntity: QueryEntityModel;
	private _fieldMetadata: FieldMetadataModel;

	constructor(queryEntity: QueryEntityModel, fieldMetadata: FieldMetadataModel) {
		this.id = Guid.NewGuid();
		this._queryEntity = queryEntity;
		this._fieldMetadata = fieldMetadata;
	}

	get title(): string {
		var entityName = this._queryEntity.Metadata.NameTranslation || this._queryEntity.Metadata.Name;

		if (this._queryEntity.Index > 1) {
			entityName = `${entityName}${this._queryEntity.Index}`;
		}

		return `${entityName}.${this._fieldMetadata.NameTranslation || this._fieldMetadata.Name}`;
	}

	get Title(): string {
		var entityName = this._queryEntity.Metadata.NameTranslation || this._queryEntity.Metadata.Name;

		if (this._queryEntity.Index > 1) {
			entityName = `${entityName}${this._queryEntity.Index}`;
		}

		return `${entityName}.${this._fieldMetadata.NameTranslation || this._fieldMetadata.Name}`;
	}

	get FieldMetadata() {
		return this._fieldMetadata;
	}

	get EntityMetadata() {
		return this._queryEntity.Metadata;
	}

	get QueryEntityGuid() {
		return this._queryEntity.Guid;
	}
}