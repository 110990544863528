import * as ko from 'knockout';
import {BlockUI} from 'Core/Common/BlockUi';

import {HelpBookStore} from "HelpBook/Store/HelpBookStore";
import {HelpDescription} from "HelpBook/HelpDescription/HelpDescription";
import {HelpStatusFlow} from "HelpBook/HelpStatusFlow/HelpStatusFlow";
import {HelpScreen} from "HelpBook/HelpScreen/HelpScreen";
import {Notifier} from 'Core/Common/Notifier';

import HelpTypeTemplate from 'HelpBook/HelpType/Templates/HelpType.html';

ko.templates['HelpBook/HelpType/Templates/HelpType'] = HelpTypeTemplate;

export class HelpType {
	public selectedType: KnockoutObservable<any>;

	constructor(typeModel) {
		this.selectedType = ko.observable(null);
		this.FetchData(typeModel);
	}

	FetchData(typeModel) {
		BlockUI.Block();

		HelpBookStore.GetTypeInfo({ id: typeModel.Id }).always(() => {
			BlockUI.Unblock();
		}).then(result => {
			if (!result.IsSuccessfull) {
				new Notifier().Failed(result.ErrorMessage);
				return;
			}

			if (result.HasData) {
				const type = result.ResultObject;

				type.noDescription = ko.observable('');
				type.previewText = ko.observable('');
				type.showFullDescription = ko.observable(false);
				type.isActive = ko.observable(true);
				type.selectedEntityId = typeModel.EntityId;
				type.Click = (data) => {
					data.isActive(!data.isActive());
				};
				type.HelpDescription = new HelpDescription(type.Description);
				type.HelpStatusFlow = new HelpStatusFlow(type.Id);
				type.ActiveHelpScreen = new HelpScreen(type, true);

				this.selectedType(type);
			}
		});
	}

	GetTemplateName(): string {
		return 'HelpBook/HelpType/Templates/HelpType';
	}
}