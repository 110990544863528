import * as ko from "knockout";

import { BlockUI } from 'Core/Common/BlockUi';
import { Notifier } from 'Core/Common/Notifier';
import { IControl } from "Core/Controls/IControl";
import { BaseProperty, IPropertyDescription } from "Core/GeneralProperties/Managers/BaseProperty";
import { TypeColorModel } from "Core/GeneralProperties/Managers/TypesProperty/Models/TypeColorModel";
import { LABELS } from "Core/Components/Translation/Locales";
import { EntityTypesStore, IGetTypesRequestModel } from "Core/Screens/TypeScreen/Stores/EntityTypesStore";
import {TableTypeModel} from 'Core/Screens/TypeScreen/Models/TableTypeModel';
import {JBoxDropDown} from "Core/Components/JBoxDropdown/DropDown";
import {ZIndexManager} from "Core/Common/ZIndexManager";

import Template from 'Core/GeneralProperties/Managers/TypesProperty/Templates/TypesProperty.html';
ko.templates["Core/GeneralProperties/Managers/TypesProperty/Templates/TypesProperty"] = Template;

import TypePropertyColorDropdown from "Core/GeneralProperties/Managers/TypesProperty/Templates/TypePropertyColorDropdown.html";
import {HexColorModel} from "../../../Components/ColorSelector/ColorModels/Hex/HexColorModel";
import {ColorModel} from "../../../Components/ColorSelector/ColorModels/ColorModel";
ko.templates["Core/GeneralProperties/Managers/TypesProperty/Templates/TypePropertyColorDropdown"] = TypePropertyColorDropdown;

export interface ITypePropertyModel {
	TypeId: number;
	TypeName: string;
	TypeColor: string;
}

export class TypesProperty extends BaseProperty {
	private _control: IControl;
	private _showOptions: KnockoutObservable<boolean>;
	private _types: KnockoutObservableArray<TableTypeModel>;
	private _labels = LABELS;
	private _dropDown: JBoxDropDown;
	private _colorModel: ColorModel;
	private _el: HTMLElement;

	ColorTypes: KnockoutObservableArray<TypeColorModel>;
	SelectedColorTypes: KnockoutObservableArray<TypeColorModel>;


	constructor(property: IPropertyDescription, propertyValue: string, control: IControl) {
		super(property);

		this.Value = ko.observable(propertyValue || this.GetDefaultValue());

		//property is stored into value after that it will be saved automaticly
		this.Value([]);

		this._control = control;

		this._showOptions = ko.observable(true);

		this._types = ko.observableArray([]);

		this.ColorTypes = ko.observableArray([]);
		this.SelectedColorTypes = ko.observableArray([]);
	}

	Init() {
		this.GetTypes();
	}

	GetTemplateName(): string {
		return "Core/GeneralProperties/Managers/TypesProperty/Templates/TypesProperty";
	}

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
		this._el = el[0];

		this.Init();
	}

	GetDefaultValue() {
		return null;
	}

	SetValue(propertyValue: string): void {
		this.Value(propertyValue);
		this._colorModel.SetColor(this.Value());
	}

	SelectType(type: TypeColorModel) {
		type.SelectedStatus(true);
		this.SelectedColorTypes.push(type);
		let entityProperty: ITypePropertyModel = {
			TypeId: type.Id,
			TypeName: type.Name,
			TypeColor: type.TypeColor()
		}
		this.Value().push(entityProperty);
	}

	DeselectType(selectType: TypeColorModel) {
		selectType.SelectedStatus(false);
		const filteredSelectedTypes = _.filter(this.SelectedColorTypes(), (type: TypeColorModel) => {
			return type.Id !== selectType.Id;
		});
		this.Value(_.filter(this.Value(),
			(type: ITypePropertyModel) => {
				return selectType.Id !== type.TypeId;
			})
		);

		this.SelectedColorTypes(filteredSelectedTypes);
	}

	ChangeTypeColor(selectType: TypeColorModel, event) {
		this._colorModel = new HexColorModel({Label: null, InitialColor: selectType.TypeColor(), Positions: 'top left'});
		this._colorModel.On("COLOR_SET", this, eventArgs => {
			this.ChangeColor(selectType, eventArgs);
		});
		this._colorModel.On('COLOR_RESET', this, () => {
			this.ChangeColor(selectType, null);
		});

		const container = event.currentTarget;
		let options = {
			target: container,
			bindTarget: container,
			bindComponent: this,
			otherOptions: {
				addClass: "colorPopUp",
				closeOnClick: 'body',
				attach: undefined,
				height: 'auto',
				maxHeight: 300,
				isolateScroll: true,
				maxWidth: 400,
				minWidth: 240,
				onCloseComplete: () => {this._dropDown.Destroy()},
				zIndex: ZIndexManager.Instance.NextValue,
				position: {
					x: "center",
					y: "top"
				}
			},
			onCreated: () => {
				this._dropDown.SetContent({ content: TypePropertyColorDropdown as any });
			},
			onOpen: () => {}
		}
		this._dropDown = new JBoxDropDown(options);
		this._dropDown.Open();
	}

	ChangeColor(selectType: TypeColorModel, eventArgs) {
		_.each(this.Value(), (value: ITypePropertyModel)=> {
			if (value.TypeId === selectType.Id){
				value.TypeColor = eventArgs ? eventArgs.data.Name : null;
			}
		});
		_.each(this.SelectedColorTypes(), (value: TypeColorModel)=> {
			if (value.Id === selectType.Id){
				value.TypeColor(eventArgs ? eventArgs.data.Name : null);
			}
		});
	}

	GetTypes() {
		BlockUI.Block({Target: this._el});
		let request: IGetTypesRequestModel = {
			EntityId: this._control.GetForm().GetScreen().GetEntityId(),
			ParentTypeId: 0,
			WithRoot: false,
			OnlyEnabled: false
		};

		EntityTypesStore.GetAllTypes(request)
			.then((data)=> {

				const typesColor = (this._control as any)._typesProperty;
				typesColor && typesColor.map(( type: ITypePropertyModel ) => {
					this.Value().push(type);
				});

				this._types(data.TableTypes);

				this.ColorTypes(this._types().map((type: TableTypeModel) => {

					let typeSelected = _.find(this.Value(), (value: ITypePropertyModel) => {
						return value.TypeId === type.Id;
					});

					const mappedType = _.extend(type, {
						SelectedStatus: ko.observable(!!typeSelected),
						TypeColor: ko.observable(!!typeSelected ? typeSelected.TypeColor : null)
					});

					!!typeSelected && this.SelectedColorTypes.push(mappedType);

					return mappedType;
				}));
			})
			.always(() => BlockUI.Unblock(this._el))
			.fail((err) => new Notifier().Failed(err.message));
	}
}