import * as _ from "underscore";

import {deserialize} from 'libs/cerialize';
import {deserializeAs} from 'libs/cerialize';
import {ColorConverter} from "../../../Core/Components/ColorSelector/ColorConverter";
import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

export class TableModel {
    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    TranslatedName: string;

    @deserialize
    Color: string;

    @deserialize
    Icon: string;

    @deserialize
    IconType: string;

    @deserialize
    Image: string;

    @deserialize
    TypeId: string;

    @deserialize
    TypeName: string;

    @deserialize
    HasQueries: boolean;

    @enumerable get HexColor() {
        return ColorConverter.ToHex(this.Color);
    }
}