import * as ko from 'knockout';

import {Event} from 'Core/Common/Event';
import {Modal} from 'Core/Common/Modal';
import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';

import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';

import {SecurityProfileStore} from 'Core/Common/Security/SecurityProfile/Stores/SecurityProfileStore';

import {LABELS} from 'Core/Components/Translation/Locales';

import {ProfileSelectorStore} from './Stores/ProfileSelectorStore';
import {ProfileSelectorItem} from './Models/ProfileSelectorItem';

import Template from './Templates/Template.html';
import {GlobalManager, GLOBALS} from '../../GlobalManager/GlobalManager';

ko.templates['Core/Screens/ProfileSelectorScreen/Templates/Template'] = Template;

export class ProfileSelectorScreen extends Event {
    private _originalMainProfileId: number;

    private _modal: Modal;
    private _store: ProfileSelectorStore;

    private _labels: LABELS;

    Profiles: KnockoutObservableArray<ProfileSelectorItem>;
    SelectedProfile: KnockoutObservable<ProfileSelectorItem>;
    Error: KnockoutObservable<string>;

    constructor(private _entityId: number, private _entityName: string) {
        super();

        this._store = new ProfileSelectorStore();
        this._labels = LABELS;

        this.Profiles = ko.observableArray([]);
        this.SelectedProfile = ko.observable(null);
        this.Error = ko.observable(null);
    }

    GetTemplateName() {
        return 'Core/Screens/ProfileSelectorScreen/Templates/Template';
    }

    AfterRender() {

    }

    ShowIfNeeded() {
		if (!this.HasToBeShownByGlobal() || this.IsCdTable() || this.IsSysTable()) {
            this.Trigger('USED_CURRENT_PROFILE');
            return;
        }

        BlockUI.Block();
        this.LoadCreatorProfiles()
            .then(() => this.ResolveCreatorProfiles())
            .fail(err => new Notifier().Failed(err.message))
            .always(() => BlockUI.Unblock());
    }

    OnConfirm() {
        if (!this.SelectedProfile()) {
            this.Error(LABELS.PLEASE_SELECT_THE_PROFILE);
            return;
        }

        if (this._originalMainProfileId === this.SelectedProfile().Id) {
            this.Trigger('USED_CURRENT_PROFILE');
            return;
        }

        SecurityProfileStore.SelectMainProfile(this.SelectedProfile().Id)
            .then(() => {
                PubSub.publish(PUB_SUB_EVENTS.REFRESH_SECURITY_PROFILE, null);
                this.Trigger('PROFILE_SELECTED');
            })
            .fail(error => new Notifier().Failed(JSON.parse(error.message).Message));
    }

    OnCancel() {
        this.Trigger('PROFILE_SELECTION_CANCELLED');
    }

    Close() {
        if (!this._modal) {
            return;
        }

        this._modal.Close();
        this._modal.Destroy();
    }

    private LoadCreatorProfiles() {
        return this._store.GetCreatorProfiles(this._entityId)
            .then(profiles => {
                const selectedProfile = profiles.find(profile => profile.Main);
                this._originalMainProfileId = selectedProfile && selectedProfile.Id;
                this.Profiles(profiles);
            })
            .fail(error => new Notifier().Failed(error.message));
    }

    private ResolveCreatorProfiles() {
        if (this.Profiles().length === 0) {
            this.Trigger('PROFILES_NOT_FOUND');
            return;
        }
        if (this.Profiles().length === 1) {
            this.Trigger('USED_CURRENT_PROFILE');
            return;
        }

        this.RenderSelector();
    }

    private RenderSelector() {
        this._modal = new Modal({width: 400}, false);

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);

        this._modal.Show();
    }

    private HasToBeShownByGlobal() {
        return GlobalManager.Instance.GetGlobal(GLOBALS.SHOW_PROFILE_SELECTOR) === '1';
	}

	private IsCdTable() {
		return this._entityName && this._entityName.startsWith('CD_');
	}

	private IsSysTable() {
		return this._entityName && this._entityName.startsWith('SYS_');
	}
}