import * as ko from "knockout"
import 'jquery';
import 'contextMenu';
let id = 0;
export class ContextMenu {
	static Init() {
		ko.bindingHandlers.contextMenu = {
			init: (element, valueAccessor) => {				
				let options = valueAccessor();
				if(options.hasOwnProperty('enabled') && !ko.unwrap(options.enabled)){
					return;						
				}
				let contextMenuSelector = `context-menu${id++}`;
				$(element).addClass(contextMenuSelector);
				options.selector = `.${contextMenuSelector}`;
				$.contextMenu(options);
			}
		};
	}
}