import { dia as Dia, shapes, V } from 'libs/rappid/build/rappid';
import * as joint from 'libs/rappid/build/rappid';
import 'Core/Controls/CanvasDesigner/Shapes/Models/DecoratedRect';

var iconTemplate = V('<g class="rotatable"><g class="scalable"><image/></g><text class="shape-label"/></g>');

declare module 'libs/rappid/build/rappid' {
	namespace shapes {
		namespace cyberThing {
			class DecoratedRectView extends Dia.ElementView { }
		}
	}
}

joint.shapes.cyberThing.DecoratedRectView  = Dia.ElementView.extend({

	initialize: function () {
		joint.dia.ElementView.prototype.initialize.apply(this, arguments);
	},

	renderMarkup: function () {
		this.vel.append(iconTemplate.clone());
	}
});