import * as _ from 'underscore';
import * as ko from 'knockout';

import { QueryExpressionModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import { QueryConditionGroupModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryConditionGroup';
import { QueryConditionItemModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryConditionItemModel';
import { Modal } from 'Core/Common/Modal';
import { ConditionItem } from 'QueryBuilder/QueryCondition/ConditionItem/ConditionItem';
import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { QueryEntityJoinModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityJoinModel';
import { EVENTS } from 'QueryBuilder/QueryParamsPage/Events';
import { Event } from 'Core/Common/Event';
import { LABELS } from "Core/Components/Translation/Locales";
import { Util } from 'QueryBuilder/Util';
import { EntityMetadataStore } from 'QueryBuilder/Stores/EntityMetadataStore';
import { BlockUI } from 'Core/Common/BlockUi';
import { ViewModes } from 'Core/Controls/Grid/BaseGrid/Enums/ViewModes';
import { FIELD_TYPES } from 'Core/Constant';
import { ConditionValueTypes } from 'QueryBuilder/Enums';

import clone from 'clone'

import Template from 'QueryBuilder/QueryParamsPage/Templates/QueryParamsPage.html';
import { ConditionGroup } from '../QueryCondition/ConditionGroup/ConditionGroup';
import { Utils } from '../../Core/Common/Utils';
import {MobileChecker} from "Core/Common/MobileChecker";

ko.templates['QueryBuilder/QueryParamsPage/Templates/QueryParamsPage'] = Template;

export class QueryParamsPage extends Event {
	private _model: QueryExpressionModel;
	private _modal: Modal;
	private _showParameters: KnockoutObservableArray<ConditionItem>;
	private _spimParameters: Array<ConditionItem>;
	private _labels = LABELS;
	private _queryType: string;
	private _spimConditions: KnockoutObservableArray<ConditionGroup>;
	private _planningDate: string;

	constructor(model: QueryExpressionModel, queryType: string, planningDate: string = '') {
		super();
		this._planningDate = planningDate;
		this._model = model;
		this._queryType = queryType;
		this._showParameters = ko.observableArray([]);
		this._spimParameters = [];
		this._spimConditions = ko.observableArray(null);
		this.Init();
	}

	private Init() {
		this.AddEvent(EVENTS.SAVE);
	}

	get SpimCondition(): KnockoutObservableArray<ConditionGroup>{
		return this._spimConditions;
	}

	get ConditionItems(): KnockoutObservableArray<ConditionItem> {
		return this._showParameters;
	}

	HasShowParameters() {
		var showParameters = this.GetShowParams(this._model.Condition);
		return showParameters.length > 0;
	}

	private GetShowParams(condition: QueryConditionGroupModel): Array<QueryConditionItemModel> {
		return _.filter(Util.GetAllConditions(this._model.Condition), item=>item.ShowParam);
	}

	Show() {
		let autoStart = !!this._planningDate;
		var entities = Util.GetAllQueryEntities(this._model);
		var ids = _.map(entities, (entity) => { return entity.Metadata.Id });
		BlockUI.Block();
		EntityMetadataStore.GetEntitiesMetadata({ EntityIds: ids })
			.always(() => {
				BlockUI.Unblock();
			})
			.then((data) => {
				_.each(entities, (entity) => {
					var metadata = _.find(data, (item) => item.EntityMetadata.Id === entity.Metadata.Id);
					if (metadata) {
						entity.Metadata = metadata.EntityMetadata;
					}
				});

			let spimParams = [];	
			_.each(this.GetShowParams(this._model.Condition), (item) => {
				var conditionItem = new ConditionItem(item, ko.observableArray(entities), [], false);
				if(conditionItem.HasMetadata){

					if(conditionItem.Model.Column.Metadata.Name === 'DECDATE'){
						conditionItem.Model.Value = this._planningDate;
						conditionItem.UpdateEditor();
					}else if(!!this._planningDate){
						autoStart = false;
					}

					if((conditionItem.Model.Column.Metadata.Type === FIELD_TYPES.Spim
						|| conditionItem.Model.Column.Metadata.Name === 'F_UNIT'
						|| conditionItem.Model.Column.Metadata.Name === 'F_FEATURE')
						&& this._queryType === ViewModes[ViewModes.Spim])
					{
						item.IsEnabled = false;
						spimParams.push(conditionItem);
					}else{
						this._showParameters.push(conditionItem);
					}
				}
			});

			spimParams = this.SortSpimConditions(spimParams);

			this._spimParameters = spimParams;

			const resizePopupOptions = {
                widthInPercent: MobileChecker.IsMobile() ? 95 : 85,
                heightInPercent: MobileChecker.IsMobile() ? 60 : 50
            };

			if(!autoStart){
				this._modal = new Modal( {
					addClass: 'query-builder-modal-Spim jBox-padding-15px',
				}, false, false, resizePopupOptions);
				this.Render();
			}else{
				this.Trigger(EVENTS.SAVE);
			}			
		});
	}

	SortSpimConditions(conditionItems: Array<ConditionItem>){
		return _.sortBy(conditionItems, (param) => {
			if(param.Model.Column.Metadata.Name === 'F_FEATURE'){
				return 0;
			}

			if(param.Model.Column.Metadata.Type === FIELD_TYPES.Spim){
				return 1;
			}

			if(param.Model.Column.Metadata.Name === 'F_UNIT'){
				return 2;
			}
		});
	}

	AddSpimCondition(){
		var entities = Util.GetAllQueryEntities(this._model);
		let newGroupModel = new QueryConditionGroupModel();

		_.each(this._spimParameters, (param) => {
			let paramModel = clone(param.Model) as QueryConditionItemModel;
			paramModel.IsEnabled = true;
			newGroupModel.Items.push(paramModel);
		});

		let newGroup = new ConditionGroup(false, newGroupModel, ViewModes.Spim, ko.observableArray(entities), [], false);

		this._spimConditions.push(newGroup);
		this._model.Condition.ConditionGroups.push(newGroupModel);

		if(!this._model.SpimCondition){
			this._model.SpimCondition = new QueryConditionGroupModel();
		}

		this._model.SpimCondition.ConditionGroups.push(newGroupModel);
	}

	RemoveSpimCondition(group: ConditionGroup){
		this._spimConditions.splice(this._spimConditions().indexOf(group), 1);
		this._model.Condition.ConditionGroups.splice(this._model.Condition.ConditionGroups.indexOf(group.Model), 1);
	}

	AfterRender() {
		if (this._modal) {
			this._modal.Show();
		}
	}

	Render(): void {
		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);
	}

	GetTemplateName() {
		return 'QueryBuilder/QueryParamsPage/Templates/QueryParamsPage';
	}

	Save() {
		this.Trigger(EVENTS.SAVE);
		this.Close();
	}

	Close() {
		if (this._modal) {
			this._modal.Close();
		}
	}
}