import * as joint from 'libs/rappid/build/rappid';
import * as _ from 'underscore';

export var GridLayout = {

	layout: function (graphOrCells, opt) {

		var graph;

		if (graphOrCells instanceof joint.dia.Graph) {
			graph = graphOrCells;
		} else {
			graph = (new joint.dia.Graph()).resetCells(graphOrCells, { dry: true });
		}

		// This is not needed anymore.
		graphOrCells = null;

		opt = opt || {};

		var elements = graph.getElements();

		elements = _.sortBy(elements, (item: any) => { return item.get('name'); });
		elements = _.sortBy(elements, (item: any) => { return item.get('sort'); });

		// number of columns
		var columns = opt.columns || 1;
		var rows = Math.ceil(elements.length / columns);
		// shift the element horizontally by a given amount
		var dx = opt.dx || 0;

		// shift the element vertically by a given amount
		var dy = opt.dy || 0;
		// position the elements in the centre of a grid cell
		var centre = opt.centre === undefined || opt.centre !== false;

		// resize the elements to fit a grid cell & preserves ratio
		var resizeToFit = !!opt.resizeToFit;

		// coordinates of the most top-left element.
		var marginX = opt.marginX || 0;
		var marginY = opt.marginY || 0;

		// width of a column
		var columnWidths = [];
		var columnWidth = opt.columnWidth;
		if (columnWidth === 'compact') {

			for (var cIndex = 0; cIndex < columns; cIndex++) {
				var elementsAtColumn = this._elementsAtColumn(elements, cIndex, columns);
				columnWidths.push(this._maxDim(elementsAtColumn, 'width') + dx);
			}
		} else {
			if (!columnWidth || joint.util.isString(columnWidth)) {
				columnWidth = this._maxDim(elements, 'width') + dx;
			}
			for (var i = 0; i < columns; i++) {
				columnWidths.push(columnWidth);
			}
		}

		var columnsX = this._accumulate(columnWidths, marginX);

		var rowHeights = [];
		elements.forEach(function(element, index) {
			rowHeights.push(element.get('size').height + dy);
		});

		var rowsY = this._accumulate(rowHeights, marginY);

		graph.startBatch('layout');

		elements.forEach(function (element, index) {
			var cIndex = index % columns;
			var rIndex = Math.floor(index / columns);
			var cx = 0;
			var cy = 0;
			element.position(columnsX[cIndex] + dx, rowsY[rIndex] + dy, opt);
		});

		graph.stopBatch('layout');
	},

	_maxDim: function (elements, dimension) {
		return elements.reduce(function (max, el) {
			return Math.max(el.get('size')[dimension], max);
		}, 0);
	},

	_elementsAtRow: function (elements, rowIndex, numberOfColumns) {
		var elementsAtRow = [];
		var i = numberOfColumns * rowIndex;
		var n = i + numberOfColumns;
		for (; i < n; i++) {
			elementsAtRow.push(elements[i]);
		}
		return elementsAtRow;
	},

	_elementsAtColumn: function (elements, columnIndex, numberOfColumns) {
		var elementsAtColumn = [];
		var i = columnIndex;
		var n = elements.length;
		for (; i < n; i += numberOfColumns) {
			elementsAtColumn.push(elements[i]);
		}
		return elementsAtColumn;
	},

	_accumulate: function (array, baseVal) {
		return array.reduce(function (res, val, i) {
			res.push(res[i] + val);
			return res;
		}, [baseVal || 0]);
	}
}