import * as ko from 'knockout';

import {FormatConverter} from "FormatEditor/FormatConverter";
import {FieldFormat} from 'Core/Common/FieldFormat';

import {PropertyControl} from "../PropertyControl";

import Template from './Template.html';
import {ProductPropertyDescription} from "../../ProductPropertyDescription";
import { ProductPart } from "../../../ProductPart";
import { PropertyValue } from '../../../PropertyValue';

export class DecimalPropertyControl extends PropertyControl {
    private _displayValue: KnockoutObservable<string>;

    constructor(property: ProductPropertyDescription, productPart: ProductPart) {
        super(property, productPart);

        this.HasContent = ko.observable(true);
        this._displayValue = ko.observable(null);
    }

    GetTemplate() {
        return Template;
    }

    get DecimalMaskSize() {
        if (FieldFormat.IsPercentage(this.FormatName)) {
            return Math.max(this.Size - 2, 0);
        }

        return this.Size;
    }

    get IsCurrencyIcon() : boolean {
        return FieldFormat.IsCurrency(this.FormatName);
    }

    get IsPercentageIcon() : boolean {
        return !!(this._displayValue() && this._displayValue().length !== 0 && FieldFormat.IsPercentage(this.FormatName));
    }

    GetConvertedValue(){
        const value = this._displayValue();

        if (!value && value !== '0') {
            return null;
        }

        let convertedValue = +value.replace(',', '.');

        convertedValue = isNaN(convertedValue) ? null : convertedValue;

        if (convertedValue && FieldFormat.IsPercentage(this.FormatName)) {
            return (convertedValue / 100).toFixed(this.Size);
        }

        return convertedValue;
    }

    GetValueForSave(): any {
        return this.GetConvertedValue();
    }

    SetValue(propertyValue: PropertyValue) {
        let formattedValue = null;

        if (propertyValue && propertyValue.Value && FieldFormat.IsPercentage(this.FormatName)) {
            const percentageValue = (Number(String(propertyValue.Value).replace(',', '.')) * 100)
                .toFixed((this.Size < 2) ? 0 : this.Size - 2);
            formattedValue = FormatConverter.ConvertDecimal(percentageValue);
        }
        else if (propertyValue && propertyValue.Value) {
            formattedValue = FormatConverter.ConvertDecimal(propertyValue.Value.toString());
        }

        this._displayValue(formattedValue);
        this.StartListeningValueChanges();
    }

    ClearValue() {
        this._displayValue(null);
    }

    private StartListeningValueChanges() {
        if (this._displayValue.getSubscriptionsCount() == 0) {
            this._displayValue.subscribe(() => {
                this.CheckValidControl();
                this.OnValueChanged();
            });
        }
    }

    IsValid(value?: any): boolean {
        return this.GetDecimalIntegerValid(this.GetConvertedValue());
    }

    CheckValidControl() {
        this.isValidControl(this.GetDecimalIntegerValidRules(this.GetConvertedValue()));
    }
}