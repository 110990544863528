import { deserialize } from "libs/cerialize";
import {IconModel} from "../../../../Controls/BaseControl/Models/IconModel";

export class FlowItemModel {
	@deserialize
	EntityId:number;

	@deserialize
	RecordId: number;

	@deserialize
	TypeId: number;

	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	Icon: IconModel;

	@deserialize
	FlowOperationId: number;
}