import {deserialize, deserializeAs} from 'libs/cerialize';

export class LicenseHolderModel {
	@deserialize
	Name: string;

	@deserialize
	LicenseUsersNumber: number;

	@deserialize
	ActiveUsersNumber: number;

	@deserialize
	LicenseConnectUsersNumber: number;

	@deserialize
	ActiveConnectUsersNumber: number;
	@deserialize
	LicenseRemoteUsersNumber: number;

	@deserialize
	ActiveRemoteUsersNumber: number;
	@deserialize
	LicenseLimited1UsersNumber: number;

	@deserialize
	ActiveLimited1UsersNumber: number;
	@deserialize
	LicenseLimited2UsersNumber: number;

	@deserialize
	ActiveLimited2UsersNumber: number;
	@deserialize
	LicenseLimited3UsersNumber: number;

	@deserialize
	ActiveLimited3UsersNumber: number;

	@deserialize
	LicensePortalUsersNumber: number;

	@deserialize
	ActivePortalUsersNumber: number;

	@deserialize
	LicenseGDPRUsersNumber: number;

	@deserialize
	ActiveGDPRUsersNumber: number;

	@deserialize
	MaxRole: number;

	@deserialize
	UserMaxRole: string;

	@deserialize
	ExpirationDate: Date;

	@deserialize
	Image: string;
}