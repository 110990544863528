import * as ko from "knockout";

import {IScreen, IFormParam, IControlParam, IForm} from "Core/Screens/IScreen";
import {ControlFactory} from "Core/Controls/ControlFactory";
import {IControl} from "Core/Controls/IControl";
import {SubFormModel} from 'Core/Models/Screens/SubFormModel'
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel'
import {RenderModes, CONTROL_TYPES} from "Core/Constant";
import {Event} from 'Core/Common/Event';
import {BaseControl} from 'Core/Controls/BaseControl/BaseControl';

export class BaseForm extends Event implements IForm {
    protected _model: SubFormModel;
    protected _controls: KnockoutObservableArray<IControl>;
    protected _screen: IScreen;
    protected _renderMode: RenderModes;
    protected _el: HTMLElement;
    protected _isWrapped: KnockoutObservable<boolean>;
    protected _controlToWrap: IControl;
    protected _isExpanded: KnockoutObservable<boolean>;
    protected _noSubControls: KnockoutObservable<boolean>;

    constructor(params: IFormParam) {
        super();
        this._model = params.Model;
        this._screen = params.Screen;
        this._isWrapped = ko.observable(false);
        this._renderMode = params.RenderMode;
        this._controls = ko.observableArray([]);
        this._renderMode = params.RenderMode;
        this._isExpanded = ko.observable(false);
        this.InitControls();

        this._controls.subscribe((subControls) => {
            _.each(subControls,
                (control, index) => {
                    control.SetSort(index * 10);
                });
        });
        this._noSubControls = ko.observable(!this._controls().length);
    }

    GetModel() {
        return this._model;
    }

    GetScreen(): IScreen {
        return this._screen;
    }

    private InitControls(): void {
        _.each(this._model.Controls, (controlModel: ControlModel) => {
            var params: IControlParam = {
                Model: controlModel,
                Form: this,
                RenderMode: this._renderMode
            };
            var control = ControlFactory.CreateControl(params);
            if (control) {
                this._controls.push(control);
            }
        });
    }

    Remove() {
        this.Trigger('REMOVE_SUB_FORM', {subForm: this});
    }

    RemoveControl(parent, control: IControl) {
        if (parent instanceof BaseControl) {
            const parentControl = parent as BaseControl;
            parentControl.RemoveControl(control);
        } else {
            $(control.GetWrapper())
                .fadeOut('slow',
                    () => {
                        this._controls.remove(control);
                    });

            (this.GetScreen() as any).OnControlRemoved(control);
        }
    }

    get Controls(): KnockoutObservableArray<IControl> {
        return this._controls;
    }

    AddStaticControl(control: IControl) {
        let isFavourite: boolean = control.GetType() === CONTROL_TYPES.ButtonFavorite;
        const controlNotExists = !_.any(this._controls(), c => c.GetModel().TypeName === control.GetModel().TypeName);

        if (!isFavourite && controlNotExists) {
            this._controls.unshift(control);
            return;
        }

    }

    AfterRender(el): void {
        this._el = el[0];
    }

    Wrap(controlToWrap: IControl, value: boolean) {
        this._controlToWrap = controlToWrap;
        this._isWrapped(value);
    }

    GetFormId(): number {
        return this._model.Id;
    }

    GetFormGuid(): string {
        return this._model.Guid;
    }

    ValidateExpandSubForm(){

    }

    set IsExpanded(isExpanded: boolean) {
        this._isExpanded(isExpanded);
    }
} 