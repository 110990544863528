import {serialize, deserialize, deserializeAs, serializeAs} from 'libs/cerialize';
import {GridStackWidget} from 'gridstack';
import {ISettingsModal} from "Core/Controls/FormDesigner/SettingsModal/SettingsModal";
import {IWebSizeValue} from "Core/GeneralProperties/Managers/WebSizeProperty/WebSizeProperty";

export class Width {
    constructor(option: IWebSizeValue) {
        this.Value = option.Value;
        this.Unit = option.Unit;
    }

    @serialize
    @deserialize
    Value: number;

    @serialize
    @deserialize
    Unit: {
        Name: string;
        Value: any
    };
}

export class DashboardBlock {
    constructor(widget: GridStackWidget) {
        if (widget) {
            this.x = widget.x;
            this.y = widget.y;
            this.w = widget.w;
            this.h = widget.h;
        }
    }

    @serializeAs('X')
    @deserializeAs('X')
    x: number;

    @serializeAs('Y')
    @deserializeAs('Y')
    y: number;

    @serializeAs('W')
    @deserializeAs('W')
    w: number;

    @serializeAs('H')
    @deserializeAs('H')
    h: number;
}

export class SubFormProperties {
    @serializeAs(DashboardBlock)
    @deserializeAs(DashboardBlock)
    DashboardBlock: DashboardBlock;
}

export class FormProperties {
    @serialize
    @deserialize
    Options?: Array<ISettingsModal>;
}