import * as ko from 'knockout';
import * as $ from 'jquery';
import {Icon} from "Core/Icon/Icon";
import {CONTROL_TYPES, DEFAULT_ICONS, RenderModes} from "Core/Constant";
import {KanbanStore} from 'Core/Controls/Kanban/Stores/KanbanStore';
import {TableStore} from "Core/Common/Stores/TableStore";
import {EntityTypeModel} from 'Core/Controls/Kanban/Models/EntityTypeModel';
import {StepModel} from 'Core/Controls/Kanban/Models/StatusModel';
import {KanbanViewModel} from 'Core/Controls/Kanban/Models/KanbanViewModel';
import {KanbanTypeViewModel} from "Core/Controls/Kanban/Models/KanbanTypeViewModel";
import {KanbanStep} from 'Core/Controls/Kanban/Step';
import {Timer} from "Core/Controls/Timer/Timer";
import {BaseControl, IControlValue} from "Core/Controls/BaseControl/BaseControl";
import {IControlParam, IScreen} from "Core/Screens/IScreen";
import {BaseScreen} from "Core/Screens/BaseScreen";
import {EditScreen} from "Core/Screens/EditScreen/EditScreen";

import {Notifier} from "Core/Common/Notifier";
import {BlockUI} from "Core/Common/BlockUi";

import {LOCK_EVENTS, LockManager} from "Core/Components/Locker/LockManager";
import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS,
    Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import {LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";

import {QueryBuilder as QueryBuilderControl} from "Core/Controls/QueryBuilder/QueryBuilder";
import {DeleteQueryStore} from "QueryBuilder/Stores/DeleteQueryStore";

import ViewTemplate from 'Core/Controls/Kanban/Templates/View.html';
import ToolBarTemplate from 'Core/Controls/Kanban/Templates/Toolbar.html';
import DesignTemplate from 'Core/Controls/Kanban/Templates/Design.html';

import KanbanConfigJson from 'Core/Controls/Kanban/Configs/kanban-config.json';
import {GeneralProperties} from 'Core/GeneralProperties/GeneralProperties';

import {UserManager} from "../../../User/UserManager";
import * as _ from "underscore";
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';

import {GridStore} from "Core/Controls/Grid/Stores/GridStore";
import {ViewModes} from "Core/Controls/Grid/BaseGrid/Enums/ViewModes";

import "Core/Controls/Kanban/DefaultKanban";
import "Core/Controls/Kanban/TodoKanban";
import "Core/Controls/Kanban/KanbanCard";
import "Core/Components/QueryDropdowns/QueryDropdowns";
import {CheckedUserAndGroup} from "Core/Controls/SelectUser/SelectUser";

ko.templates['Core/Controls/Kanban/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Kanban/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/Kanban/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Kanban/Templates/Design'] = DesignTemplate;

export class KanbanBoard extends BaseControl {
    private _selectedType: KanbanTypeViewModel;
    private _isKanbanViewsShown: KnockoutObservable<boolean>;
    private _isTypesShown: KnockoutObservable<boolean>;
    private _noTypesLoaded: KnockoutObservable<boolean>;
    private _entityTypes: KnockoutObservableArray<KanbanTypeViewModel>;
    private _steps: KnockoutObservableArray<KanbanStep>;
    private _searchTerm: KnockoutObservable<string>;
    private _searchString: KnockoutObservable<string>;
    private _kanbanSubjectName: KnockoutObservable<string>;
    private _controlId: number;
    private _loadStatuses: boolean;
    private _timerControl: Timer;
    private _isFullScreen: KnockoutObservable<boolean>;
    private _fullScreenTooltip: KnockoutObservable<string>;
    _screen: KnockoutObservable<IScreen>;
    _kanbanViews: KnockoutObservableArray<KanbanViewModel>;
    _isAddingRecordAllowed: KnockoutObservable<boolean>;
    _selectedTypeObservable: KnockoutObservable<KanbanTypeViewModel>;
    _selectedUsers: KnockoutObservableArray<number>;
    _showPlanned: KnockoutObservable<boolean>;
    _showTypeError: KnockoutObservable<boolean>;
    _isTodoToggleShown: KnockoutObservable<boolean>;
    _isTodoSelected: KnockoutObservable<boolean>;
    _chosenKanbanView: KnockoutObservable<KanbanViewModel>;
    _isEditingAllowed: KnockoutComputed<boolean>;
    _isDeletingAllowed: KnockoutComputed<boolean>;
    _viewId: KnockoutComputed<number>;
    _labels = LABELS;

    _designCardSecondaryInfoTemplate = `
        <div class="kanban-item__secondary-info-item">
            <div class="transition kanban-item__action">Action Name</div>
            <div class="kanban-item__date-badge kanban-item__date-badge--deadline">
                <i class="fa fa-clock-o"></i>
                Jun 30
            </div>
        </div>
    `;

    constructor(params: IControlParam) {
        super(params, KanbanConfigJson);

        this._selectedType = null;
        this._entityTypes = ko.observableArray([]);
        this._steps = ko.observableArray([]);
        this._searchTerm = ko.observable(null);
        this._searchString = ko.observable(null);
        this._screen = ko.observable();
        this._kanbanViews = ko.observableArray([]);
        this._isAddingRecordAllowed = ko.observable(true);
        this._selectedTypeObservable = ko.observable(null);
        this._selectedUsers = ko.observableArray([]);
        this._showPlanned = ko.observable(null);
        this._isTodoToggleShown = ko.observable(true);
        this._isKanbanViewsShown = ko.observable(true);
        this._isTypesShown = ko.observable(true);
        this._noTypesLoaded = ko.observable(false);
        this._showTypeError = ko.observable(false);
        this._kanbanSubjectName = ko.observable(null);

        this._loadStatuses = false;

        this._controlId = params.Model.Id;

        this._isFullScreen = ko.observable(false);

        this._fullScreenTooltip = ko.observable(LABELS.MAXIMIZE);

        this.Init();
    }

    private Init() {
        this.SetDefaultIcon(new Icon(DEFAULT_ICONS.Kanban));
        const renderMode = this._renderMode();

        const isDesignMode = renderMode === RenderModes.Design;
        if (isDesignMode) {
            this.InitForDesignMode();
        }

        if (this.IsRuntimeMode()) {
            this.InitForRuntimeMode();
        }
    }

    private InitForDesignMode() {
        const stepNames = ['To Do', 'In Progress', 'Done'];

        const kanbanSteps = stepNames
            .map(stepName => new StepModel({Label: stepName}))
            .map(stepModel => new KanbanStep(stepModel, []));

        this._steps(kanbanSteps);
    }

    ApplyProperties(){}

    private InitForRuntimeMode() {
        const screen = this._form.GetScreen();

        this._screen(screen);

        if (this._form.GetScreen().IsConsultScreen) {
            this._isTodoSelected = ko.observable(true);
        } else {
            this._isTodoSelected = ko.observable(UserVarsManager.Instance.GetKanbanTodoSelected(this.GetControlId()) || false);
        }

        this._isKanbanViewsShown(this._isTodoSelected() || !this._form.GetScreen().IsConsultScreen);

        this._isTodoSelected.subscribe(isTodoSelected => {
            this._isKanbanViewsShown(this._isTodoSelected() || !this._form.GetScreen().IsConsultScreen);

            if(this._form.GetScreen() && !this._selectedTypeObservable()){
                this.LoadTypes();
            }

            if(isTodoSelected){
                this._loadStatuses = true;
            }

            UserVarsManager.Instance.SetKanbanTodoSelected(this.GetControlId(), isTodoSelected);

            setTimeout(()=>{
                PubSub.publish('KANBAN_FULLSCREEN', this._isFullScreen());
            }, 500);
        });

        this._chosenKanbanView = ko.observable();
        this._chosenKanbanView.subscribe(chosenKanbanView => {
            this.SetKanbanView(chosenKanbanView.Id);
        });

        this._isEditingAllowed = ko.pureComputed(() => {
            const chosenKanbanView = this._chosenKanbanView();
            return chosenKanbanView && chosenKanbanView.IsEditingAllowed;
        });
        this._isDeletingAllowed = ko.pureComputed(() => {
            const chosenKanbanView = this._chosenKanbanView();
            return chosenKanbanView && chosenKanbanView.IsDeletingAllowed;
        });
        this._viewId = ko.pureComputed(() => {
            const chosenKanbanView = this._chosenKanbanView();
            return chosenKanbanView ? chosenKanbanView.Id : null;
        }).extend({notify: 'always'});

        const timerControl = this.GetSubControls() && _.find(this.GetSubControls(), control => control.GetControlTypeName() === CONTROL_TYPES.Timer
            && control instanceof Timer);
        this._timerControl = timerControl && timerControl as Timer;

        screen.On('UsersSelectionChanged', this, (eventArgs) => {

            const groupIds = _.filter(_.map(eventArgs.data?.SelectedUsersAndGroups, (item: CheckedUserAndGroup) => item.GroupId), groupId => groupId !== null) as number[];
            const userIds = _.flatten(_.map(eventArgs.data?.SelectedUsersAndGroups, (item: CheckedUserAndGroup) => item.UserIds));
            const combinedIds = groupIds.concat(userIds);
            const uniqueIds = _.uniq(combinedIds);

            this._selectedUsers(uniqueIds);
            this._showPlanned(eventArgs.data.ShowPlanned);

            if (this._selectedUsers().length === 0) {
                this._selectedUsers.push(UserManager.Instance.CurrentUser.Id);
            }
        });
    }

    private LoadTypes() {
        KanbanStore.GetTypes({
            EntityId: this.SubjectEntity,
            ScreenSubjectTypeId: this.Screen.GetTableTypeId(),
            ShowTypeHierarchy: !this.UseKanbanSubject && this.Screen.IsConsultScreen
        })
            .then(data => {
                this._entityTypes(this.MapToKanbanTypeViewModels(data));

                const selectedType = this.GetSelectedType();

                if (selectedType) {
                    this.SelectType(selectedType);
                }
                else if (!this._isTodoSelected()) {
                    this._showTypeError(true);
                }
                else {
                    this._noTypesLoaded(true);
                }
            });
    }

    private LoadViewList({withBlockUI}: { withBlockUI?: boolean } = {}) {
        const queryDropdowns = this._el.querySelector<HTMLElement>('query-dropdowns');

        if (withBlockUI) {
            BlockUI.Block({Target: queryDropdowns});
        }

        KanbanStore.GetViewList({SubjectEntityId: this.QueryRootEntityId})
            .then(data => {
                this._isAddingRecordAllowed(data.IsAddingRecordAllowed);

                const kanbanViews = [new KanbanViewModel(0, LABELS.DEFAULT_VIEW), ...data.KanbanViews];

                this._kanbanViews(kanbanViews);

                const kanbanViewId = this.GetKanbanView();

                this._chosenKanbanView(
                    kanbanViews.find(kanbanView => kanbanView.Id === kanbanViewId) || kanbanViews[0]
                );
            })
            .always(() => {
                BlockUI.Unblock(queryDropdowns);
            });
    }

    LoadKanbanSubjectInfo() {
        TableStore.Get({TableId: this.Model.KanbanSubjectId})
            .then((tableModel: any) => {
                this._kanbanSubjectName(tableModel.Name);
                this.LoadTypes();
            })
            .fail(error => {
                new Notifier().Failed(error.message);
            });
    }

    IsRuntimeMode() {
        const renderMode = this._renderMode();
        return renderMode !== RenderModes.Design && renderMode !== RenderModes.ToolBar;
    }

    SelectType(kanbanTypeViewModel: KanbanTypeViewModel) {
        if (this._selectedType) {
            this._selectedType.IsSelected(false);
        }
        this._selectedType = kanbanTypeViewModel;
        this._selectedType.IsSelected(true);
        this._selectedTypeObservable(this._selectedType);

        this.SetKanbanLastType();
    }

    get EntityTypes() {
        return this._entityTypes;
    }

    private get UseKanbanSubject() {
        return !!this.Model.KanbanSubjectId;
    }

    private get SubjectEntity() {
        return this.UseKanbanSubject
            ? this.Model.KanbanSubjectId
            : this.Screen.GetEntityId();
    }

    private get QueryRootEntityId() {
        return  this.Screen.IsConsultScreen
            ? this.Model.TodoEntityId
            : this.SubjectEntity;
    }

    Search() {
        this._searchString(this._searchTerm());
    }

    ClearSearch() {
        this._searchTerm(null);
        this.Search();
    }
    ChangeFullScreen(): void {
        this._isFullScreen(!this._isFullScreen());
        setTimeout(()=>{
            PubSub.publish('KANBAN_FULLSCREEN', this._isFullScreen());
        }, 500);

        this._fullScreenTooltip(this._isFullScreen() ? LABELS.MINIMIZE : LABELS.MAXIMIZE);
    }

    NewKanbanView() {
        BlockUI.Block();

        KanbanStore.GetDefaultKanbanViewMetaData()
            .then(async(data) =>{
            const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

            screenManager.GetEditScreen({
                    EntityId: data.TableId,
                    TableTypeId: data.TableTypeId
                })
            .always(() => {
                BlockUI.Unblock();
            })
            .then((editScreen: BaseScreen) => {
                editScreen.ShowInModal();

                return GridStore.GetDefaultTableView({
                    ControlId: 0,
                    ViewMode: ViewModes.KanbanQuery,
                    SubjectEntityId: this.QueryRootEntityId
                })
                    .then(data => {
                        const queryBuilderControl = editScreen.GetControl(CONTROL_TYPES.QueryBuilder) as QueryBuilderControl;

                        if (queryBuilderControl == null) {
                            const notifier = new Notifier($(this._el));

                            notifier.Failed(NOTIFICATIONS.QUERY_BUILDER_NOT_FOUND);
                        } else {
                            queryBuilderControl.SetSubjectEntityId(this.QueryRootEntityId);
                            queryBuilderControl.SetSubjectRecordId(this.Screen.GetRecordId());
                            queryBuilderControl.SetQueryTypeName(ViewModes.KanbanQuery);
                            queryBuilderControl.SetFilterByOwners(true);
                            queryBuilderControl.InitQuery(data);

                            editScreen.On('RECORD_SAVED', this, (eventArgs) => {
                                this.SetKanbanView(eventArgs.data.RecordId);
                                this.LoadViewList({withBlockUI: true});
                            });
                        }
                    });
            })
            .fail(err => {
                const notifier = new Notifier($(this._el));

                notifier.Failed(err.message);
            });
        });
    }

    EditKanbanView() {
        const chosenKanbanView = this._chosenKanbanView();
        const kanbanViewId = chosenKanbanView && chosenKanbanView.Id;

        if (!kanbanViewId) {
            return;
        }

        BlockUI.Block();

        KanbanStore.GetDefaultKanbanViewMetaData()
            .then(data => {
                LockManager.Instance.TryLock(data.TableId, kanbanViewId)
                    .then(async () => {
                        BlockUI.Block();
                        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
                        screenManager.GetEditScreen({
                            EntityId: data.TableId,
                            TableTypeId: data.TableTypeId,
                            RecordId: kanbanViewId
                        })
                            .always(() => {
                                BlockUI.Unblock();
                            })
                            .fail(error => {
                                const notifier = new Notifier($(this._el));
                                notifier.Warning(error.message);
                            })
                            .then((editScreen: BaseScreen) => {
                                LockManager.Instance.On(LOCK_EVENTS.RELEASED, this, (eventArgs) => {
                                    if (eventArgs.data.TableId === data.TableId && eventArgs.data.RecordId === kanbanViewId) {
                                        editScreen.Close();
                                    }
                                });
                                editScreen.On('MODAL_CLOSE', this, () => {
                                    LockManager.Instance.ReleaseLock(data.TableId, kanbanViewId);
                                });

                                editScreen.ShowInModal();

                                editScreen.On('RECORD_DELETED', this, () => {
                                    this.LoadViewList({withBlockUI: true});
                                });

                                const queryBuilderControl = editScreen.GetControl(CONTROL_TYPES.QueryBuilder) as QueryBuilderControl;

                                if (queryBuilderControl == null) {
                                    const notifier = new Notifier($(this._el));

                                    notifier.Failed(NOTIFICATIONS.QUERY_BUILDER_NOT_FOUND);
                                } else {
                                    queryBuilderControl.SetQueryTypeName(ViewModes.KanbanQuery);
                                    queryBuilderControl.SetSubjectRecordId(this.Screen.GetRecordId());
                                    queryBuilderControl.SetFilterByOwners(true);

                                    editScreen.On('RECORD_SAVED', this, () => {
                                        this.LoadViewList({withBlockUI: true});
                                    });
                                }
                            });
                    });
            })
            .fail(err => {
                const notifier = new Notifier($(this._el));

                notifier.Failed(err.message);
            })
            .always(() => {
                BlockUI.Unblock();
            });
    }

    CopyKanbanView() {
        const chosenKanbanView = this._chosenKanbanView();
        const kanbanViewId = chosenKanbanView && chosenKanbanView.Id;

        if (!kanbanViewId) {
            return;
        }

        BlockUI.Block();

        KanbanStore.GetDefaultKanbanViewMetaData()
            .then(async data => {
                const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
                    screenManager.GetEditScreen({
                        EntityId: data.TableId,
                        TableTypeId: data.TableTypeId,
                        RecordId: kanbanViewId,
                        LoadAsExample: true
                    })
                .always(() => {
                    BlockUI.Unblock();
                })
                .then((editScreen: EditScreen) => {
                    editScreen.IsDataFromExample = true;
                    editScreen.ShowInModal();

                    const queryBuilderControl = editScreen.GetControl(CONTROL_TYPES.QueryBuilder) as QueryBuilderControl;

                    if (queryBuilderControl == null) {
                        const notifier = new Notifier($(this._el));

                        notifier.Failed(NOTIFICATIONS.QUERY_BUILDER_NOT_FOUND);
                    } else {
                        queryBuilderControl.SetSubjectRecordId(this.Screen.GetRecordId());
                        queryBuilderControl.SetQueryTypeName(ViewModes.KanbanQuery);
                        queryBuilderControl.SetFilterByOwners(true);

                        editScreen.On('RECORD_SAVED', this, (eventArgs) => {
                            this.SetKanbanView(eventArgs.data.RecordId);

                            this.LoadViewList({withBlockUI: true});
                        });
                    }
                });
        });
    }

    DeleteKanbanView() {
        const chosenKanbanView = this._chosenKanbanView();
        const kanbanViewId = chosenKanbanView && chosenKanbanView.Id;

        if (!kanbanViewId) {
            return;
        }

        const confirmationDialog = new ConfirmationDialog({
            Text: 'Are you sure?',
            Type: ConfirmationTypes.Question,
            TextConfirm: 'Yes',
            TextDecline: 'Cancel'
        });

        confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
            DeleteQueryStore.Delete({Id: kanbanViewId})
                .then(() => {
                    this.LoadViewList({withBlockUI: true});
                });
        });
        confirmationDialog.Show();
    }

    private MapToKanbanTypeViewModels(models: EntityTypeModel[]) {
        return models.map(model => this.MapToKanbanTypeViewModel(model));
    }

    private MapToKanbanTypeViewModel(model: EntityTypeModel) {
        if (!model) {
            return null;
        }

        const kanbanTypeViewModel = new KanbanTypeViewModel(
            model.K_Type,
            model.Name,
            model.TranslatedName,
            model.HasFlowFolder,
            model.ShowTypeHierarchy,
            model.ParentTypeModels);

        return kanbanTypeViewModel;
    }

    SetValue(value: IControlValue): void {
        const onRender = () => {
            if (this.UseKanbanSubject) {
                this.LoadKanbanSubjectInfo();
            } else {
                this.LoadTypes();
            }

            this.LoadViewList();
        };

        if (this._isRendered()) {
            onRender();
        } else {
            this._isRendered.subscribe(onRender);
        }
    }

    private GetSelectedType() {
        const lastType = this.GetKanbanLastType();
        const selectedType = _.find(this._entityTypes(), type => type.K_Type === lastType) || _.first(this._entityTypes());

        const isScreenEntitySubjectEntity = this.Screen.GetEntityId() === this.SubjectEntity;
        if (!isScreenEntitySubjectEntity || !this.Screen.IsConsultScreen) {
            return selectedType;
        }

        const screenEntityType = this.Screen.GetTableTypeId();
        const screenEntityTypeIsOnKanban = _.any(this._entityTypes(), type => type.K_Type === screenEntityType);

        const screenEntitySubTypeIsOnKanban = _.any(this._entityTypes(), type => type.K_Type !== screenEntityType && _.contains(type.ParentTypeModels.map(m => m.K_Type), screenEntityType));

        if (screenEntitySubTypeIsOnKanban) {
            return _.find(this._entityTypes(), type => type.K_Type !== screenEntityType && _.contains(type.ParentTypeModels.map(m => m.K_Type), screenEntityType));
        }
        else if (screenEntityTypeIsOnKanban) {
            return _.find(this._entityTypes(), type => type.K_Type === screenEntityType);
        }

        return selectedType;
    }

    private GetKanbanLastType() {
        return UserVarsManager.Instance.GetKanbanLastType(this.GetControlId());
    }

    private SetKanbanLastType() {
        UserVarsManager.Instance.SetKanbanLastType(this.GetControlId(), this._selectedType.K_Type);
    }

    private GetKanbanView() {
        return UserVarsManager.Instance.GetKanbanView(this.GetControlId());
    }

    private SetKanbanView(kanbanViewId: number) {
        UserVarsManager.Instance.SetKanbanView(this.GetControlId(), kanbanViewId);
    }
}