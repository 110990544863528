import 'lockr';

import {LOCAL_STORAGE} from '../Core/Common/Enums/LocalStorageItems';

import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager'
import {GenericDeserialize} from "libs/cerialize";
import {UserStore} from "User/Store/UserStore";
import {UserModel} from "User/Store/Models/UserModel";
import {P} from "Core/Common/Promise";
import {Demo} from 'metronicDemo';
import {Notifier} from "Core/Common/Notifier";
import {CookieManager} from "Core/Common/CookieManager";
import {ISignInOptions} from "./Options/ISignInOptions";
import {IRequestUserOptions} from "./Options/IRequestUserOptions";
import {NOTIFICATIONS} from "Core/Components/Translation/Locales";
import { stopRecording, startRecording } from '../Session/SessionRecorder';

export enum UserRoles {
    User = 4,
    SuperUser = 8,
    DataRole = 256,
    DesignerLevel1 = 16,
    DesignerLevel2 = 32,
    DesignerLevel3 = 64,
    NetworkManager = 128,
    US = 1024
}

export enum UserTypes {
    User = "User",
    Usergroup = "Usergroup",
    Gdpr = "GDPR",
    Portal = "Portal",
    Guest = "Guest",
}

export enum UserDataRoles {
    Dpo = "DPO",
    Vo = "VO",
    So = "SO",
    Do = "DO",
    Po = "PO",
    Dep = "DEP"
}

export class UserManager {
    private static _instance;
    private static _allowInstantiation: boolean;
    private _currentUser: UserModel;

    constructor() {
        this._currentUser = null;
        if (!UserManager._allowInstantiation) {
            throw new Error("Use UserModel.Instance instead");
        }
    }

    get CurrentUser(): UserModel {
        return this._currentUser;
    }

    get IsAuthenticated() {
        return this._currentUser != null;
    }

    InitMetronic() {
        Demo.init();
    }

    SignIn(options: ISignInOptions) {
        CookieManager.SetAuthToken(options.AccessToken);
        CookieManager.SetRefreshToken(options.RefreshToken);
        CookieManager.SetSBIDesigner(String(options.SBIDesigner));

        const signInPromise = this.RequestUser({
            LoadUserVars: options.RefreshUserVars
        });

        signInPromise
            .then(user => {
                startRecording();
                this.SaveUserModel(user);
                Lockr.set(LOCAL_STORAGE.FILE_DIR, user.FileDir);

                if (options.RefreshUserVars) {
                    UserVarsManager.Instance.RestoreFromJson(user.UserVars);
                }

            }).fail(error => new Notifier().Failed(error.message));

        this.InitMetronic();

        return signInPromise;
    }

    SignOut() {
        stopRecording();
        this._currentUser = null;

        CookieManager.RemoveTokens();
        Lockr.rm(LOCAL_STORAGE.FILE_DIR);
    }

    private RequestUser(options: IRequestUserOptions) {
        const deferredResult = P.defer<UserModel>();

        UserStore.GetCurrentUser(options)
            .then(getUserResult => {
                if (getUserResult.IsSuccessfull) {
                    deferredResult.resolve(GenericDeserialize<UserModel>(getUserResult.ResultObject, UserModel));
                } else {
                    deferredResult.reject({message: getUserResult.ErrorMessage});
                }
            })
            .fail(() => {
                deferredResult.reject({message: NOTIFICATIONS.ERROR_USER_DATA});
            });

        return deferredResult.promise();
    }

    private SaveUserModel(user: UserModel) {
        window["UserManager"] = this;
        window["UserRoles"] = UserRoles;
        this._currentUser = user;
    }

    static get Instance(): UserManager {
        if (UserManager._instance == null) {
            UserManager._allowInstantiation = true;
            UserManager._instance = new UserManager();
            UserManager._allowInstantiation = false;
        }

        return UserManager._instance;
    }

    IsUserInRole(userRole: UserRoles): boolean {
        return (userRole & this._currentUser.SecurityRolesKey) === userRole;
    }

    IsCurrentUserMaxRole(userRole: UserRoles) {
        return Math.max(...this.GetCurrentUserRoles()) === userRole;
    }

    private GetCurrentUserRoles() {
        let rolesList = [];

        Object.keys(UserRoles).map(key => UserRoles[key]).filter(k => parseInt(k) >= 0).forEach(role => {

            if ((role & this._currentUser.SecurityRolesKey) == role) {
                rolesList.push(role);
            }
        })

        return rolesList;
    }

    GetDataRoles() {
        return UserManager.Instance.CurrentUser.UserRoles;
    }

    GetUserAllowance(userId: number = this._currentUser.Id) {
        const deferred = P.defer();

        UserStore.GetUserAllowance(this._currentUser.Id)
            .then(result => {
                if (result.IsSuccessfull) {
                    deferred.resolve(result.ResultObject.UserAllowance);
                } else {
                    deferred.reject({message: result.ErrorMessage});
                }
            }).fail(() => deferred.reject({message: NOTIFICATIONS.ERROR_USER_ALLOWANCE}));

        return deferred.promise();
    }
}