import * as ko from 'knockout';
import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel';

export class RequiredFieldModel {
	
	FieldName: KnockoutComputed<string>;
	IsSelected: KnockoutComputed<boolean>;

	constructor(defaultName: string, typeName: string, entityTypeName: string, attachedField: AttachedFieldModel = null) {
		this.DefaultName = defaultName;
		this.Type = typeName;
		this.EntityType = entityTypeName;
		this.AttachedField = ko.observable(attachedField);

		this.FieldName = ko.computed(() => {
			return this.AttachedField() ? this.AttachedField().Name : this.DefaultName;
		});

		this.IsSelected = ko.computed(() => {
			return this.AttachedField() != null;
		});
	}

	DefaultName: string;
	Type: string;
	EntityType: string;
	AttachedField: KnockoutObservable<AttachedFieldModel>;
}