import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';

import {GanttChartInfoDto} from './Models/GanttChartInfoDto';
import {SaveGanttChartInfoDto} from './Models/SaveGanttChartInfoDto';
import { PlanVersionDto } from './Models/PlanVersionDto';

export class GanttChartStore {

    GetPlanVersions(subjectEntityId: number, rootId: number): P.Promise<Array<PlanVersionDto>> {
        const deferred = P.defer<Array<PlanVersionDto>>();
        Request.Send({
            proxy: {
                url: '/api/GanttChartApi/GetVersions',
                type: 'GET',
                dataType: 'json'
            },
            params: {
                SubjectEntityId: subjectEntityId,
                RootId: rootId
            }
        })
            .then(data => deferred.resolve(data))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error getting Gantt Chart info')));

        return deferred.promise();
    }

    GetGanttChartInfo(version: number, subTableView: string, resourcesView: string, rootId: number): P.Promise<GanttChartInfoDto> {
        const deferred = P.defer<GanttChartInfoDto>();
        Request.Send({
            proxy: {
                url: '/api/GanttChartApi/GetGanttChartInfo',
                type: 'GET',
                dataType: 'json'
            },
            params: {
                Version: version,
                SubTableView: subTableView,
                ResourcesView: resourcesView,
                RootId: rootId
            }
        })
            .then(data => deferred.resolve(data))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error getting Gantt Chart info')));

        return deferred.promise();
    }

    CreateNewVersion(info: SaveGanttChartInfoDto): P.Promise<number> {
        const deferred = P.defer<number>();

        Request.Send({
            proxy: {
                url: '/api/GanttChartApi/CreateNewVersion',
                type: 'POST'
            },
            params: info
        })
            .then((data) => deferred.resolve(data))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error saving Gantt Chart info')));

        return deferred.promise();
    }

    SaveGanttChartInfo(info: SaveGanttChartInfoDto) {
        const deferred = P.defer<void>();

        Request.Send({
            proxy: {
                url: '/api/GanttChartApi/SaveGanttChartInfo',
                type: 'POST'
            },
            params: info
        })
            .then(() => deferred.resolve(null))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error saving Gantt Chart info')));

        return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}