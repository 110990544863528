import {deserialize, deserializeAs} from 'libs/cerialize';
import {IPosition} from "Core/Portlets/Models/Common/Interfaces/IPosition"
import {ScreenModel} from "Core/Models/Screens/ScreenModel"

import {Guid} from "Core/Common/Guid"

export class EntityPortlet {
	Guid: Guid;

	@deserialize
	ScreenId: number;

	@deserialize
    ScreenName: string;

    @deserialize
    ScreenTranslatedName: string;

    @deserializeAs(ScreenModel)    
    ScreenModel: ScreenModel;

	Position: IPosition;
	OldPosition: IPosition;
} 