import * as _ from 'underscore';
import {Format} from "../../DatabaseDesigner/FieldConfig";

export const FORMATS = {
    PHONE_NUMBER: 'Phone number',
    DUTCH_POSTAL_CODE: 'Dutch Postal Code'
}

export class FieldFormat {
	static CURRENCY = 'Currency';
	static LONGDATE = 'LongDate';
	static DECIMAL = 'Decimal';
	static STARTSWITHCAPITAL = 'Startswithcapital';
	static LOWERCASE = 'Lowercase';
	static IPADDRESS = 'IPAddress';
	static PHONENUMBER = 'Phonenumber';
	static UPPERCASE = 'Uppercase';
	static SHORTDATE = 'ShortDate';
	static DATETIME = 'DateTime';
	static DATE = 'Date';
	static DD_MMM = 'DD-MMM';
	static DD_MM = 'DD-MM';
	static MMM_YYYY = 'MMM-YYYY';
	static MM_YY = 'MM-YY';
	static YYYY_WW = 'YYYY-WW';
	static TIMESPAN = 'TimeSpan';
	static TIME = 'Time';
	static LONGTIME = 'LongTime';
	static URL = 'URL';
	static MAIL = 'Mail';
	static PERCENTAGE = 'Percentage';
	static SQL = 'SQL';
	static SKYPE = 'SKYPE';
    static LINKEDIN = 'Linkedin';
	static FACEBOOK = 'Facebook';
	static YOUTUBE = 'YouTube';
	static TWITTER = 'Twitter';
	static INSTAGRAM = 'Instagram';
	static PINTEREST = 'Pinterest';
	static MEDIUM = 'Medium';
	static DUTCHPOSTALCODE = 'DutchPostalCode';
	static INTEGER = 'Integer';
	static LOCALURL = 'Local URL';
    static BARCODE = 'Barcode';
    static DD_MMM_YYYY = 'DD-MMM-YYYY';

    static whiteSpaceReplate = / /g;

	public static Replacer(name) {
		return name? name.replace(this.whiteSpaceReplate, '') : '';
	}

	public static IsCurrency(name: string): boolean { 
		return this.Replacer(name).toLowerCase() === this.CURRENCY.toLowerCase();
	}

	public static IsLongDate(name: string): boolean {
		return this.Replacer(name).toLowerCase() === this.LONGDATE.toLowerCase();
	}

	public static IsDecimal(name: string): boolean {
		return this.Replacer(name).toLowerCase() === this.DECIMAL.toLowerCase();
	}

	public static IsStartswithcapital(name: string): boolean {
		return this.Replacer(name).toLowerCase() === this.STARTSWITHCAPITAL.toLowerCase();
	}

	public static IsLowercase(name: string): boolean {
		return this.Replacer(name).toLowerCase() === this.LOWERCASE.toLowerCase();
	}

	public static IsIPAddress(name: string): boolean {
		return this.Replacer(name).toLowerCase() === this.IPADDRESS.toLowerCase();
	}

	public static IsPhonenumber(name: string): boolean {
		return this.Replacer(name).toLowerCase() === this.PHONENUMBER.toLowerCase();
	}

	public static IsUppercase(name: string): boolean {
		return this.Replacer(name).toLowerCase() === this.UPPERCASE.toLowerCase();
	}

	public static IsShortDate(name: string): boolean {
		return this.Replacer(name).toLowerCase() === this.SHORTDATE.toLowerCase();
	}

	public static IsDateTime(name: string): boolean {
		return this.Replacer(name).toLowerCase() === this.DATETIME.toLowerCase();
	}

	public static IsTime(name: string): boolean {
		return this.Replacer(name).toLowerCase() === this.TIME.toLowerCase();
	}

	public static IsTimeSpan(name: string): boolean {
		return this.Replacer(name).toLowerCase() === this.TIMESPAN.toLowerCase();
	}

	public static IsLongTime(name: string): boolean {
		return this.Replacer(name).toLowerCase() === this.LONGTIME.toLowerCase();
	}

	public static IsURL(name: string): boolean {
		return this.Replacer(name).toLowerCase() === this.URL.toLowerCase();
	}

	public static IsMail(name: string): boolean {
		return this.Replacer(name).toLowerCase() === this.MAIL.toLowerCase();
	}

	public static IsPercentage(name: string): boolean {
		return this.Replacer(name).toLowerCase() === this.PERCENTAGE.toLowerCase();
	}

	public static IsDutchPostalCode(name: string): boolean {
		return this.Replacer(name).toLowerCase() === this.DUTCHPOSTALCODE.toLowerCase();
    }

    public static IsBarcode(name: string): boolean {
        return this.Replacer(name).toLowerCase() === this.BARCODE.toLowerCase();
    }

	public static IsInteger(name: string): boolean {
		return this.Replacer(name).toLowerCase() === this.INTEGER.toLowerCase();
	}

	public static GetFormat(name: string) {
		const formatKeys= Object.keys(FieldFormat);
		const key = _.find(formatKeys, (formatKey: any) => FieldFormat[formatKey] === name);
		return key;
	}
}