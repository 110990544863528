import { BaseModel } from "DatabaseDesigner/Models/BaseModel";
import { CollectionModel } from "DatabaseDesigner/Models/CollectionModel";
import { FieldModel } from "DatabaseDesigner/Models/FieldModel";
import { LanguageModel } from "DatabaseDesigner/Models/LanguageModel";
import { LinkTableModel } from "DatabaseDesigner/Models/LinkTableModel";
import { TableModel } from "DatabaseDesigner/Models/TableModel";
import { TableBaseModel } from "DatabaseDesigner/Models/TableBaseModel";
import * as _ from 'underscore';
import { LifeStatusEnum } from "common/life-status-enum";

export function DatabaseModel() {
            this._DataBaseId = 0;
            this._tables = new CollectionModel({ model: TableModel });
            this._sortPosition = [];
            this._maxSort = 0;
            this._linkTables = new CollectionModel({ model: LinkTableModel });
            this._deletedLinkTables = new CollectionModel({ model: LinkTableModel });
            this._deletedSubTables = new CollectionModel({model: TableBaseModel});
            this._languages = new CollectionModel({ model: LanguageModel });
            this._isUpdateDatabaseRequired = false;
            BaseModel.apply(this);
        }
        ;
        DatabaseModel.prototype = Object.create(BaseModel.prototype);
        DatabaseModel.prototype.constructor = DatabaseModel;
        DatabaseModel.prototype.GetAllFields = function () {
            var fieldsCollection = new CollectionModel({ model: FieldModel });
            var fields = [];
            _.each(this.Tables.Items, function (table: any) {
                _.each(table.Fields.Items, function (field) {
                    fields.push(field);
                });
            });
            fieldsCollection.Items = fields;
            return fieldsCollection;
        };
        DatabaseModel.prototype.GetTable = function (id, guid) {
            for (let entityTable of this.Tables.Items) {
                if (entityTable.Id === id || entityTable.Guid === guid) {
                    return entityTable;
                }
                for (let subTable of entityTable.SubTables.Items) {
                    if (subTable.Id === id || subTable.Guid === guid) {
                        return subTable;
                    }
                }
            }
            for (let linkTable of this.LinkTables.Items) {
                if (linkTable.Id === id || linkTable.Guid === guid) {
                    return linkTable;
                }
            }
            for (let lookupTable of window['DatabaseDesigner'].Shared.lookupTables.Items) {
                if (lookupTable.Id === id || lookupTable.Guid === guid) {
                    return lookupTable;
                }
            }
            return null;
        };
        DatabaseModel.prototype.toJSON = function (onlyModified) {
            var data: any = {};
            data.DatabaseId = this._DataBaseId;
            data.Tables = this._tables.toJSON(onlyModified);
            data.LinkTables = this._linkTables.toJSON(onlyModified);
            data.Languages = this._languages.toJSON(onlyModified);
            data.LookupTables = window['DatabaseDesigner'].Shared.lookupTables.toJSON(onlyModified);
            data.Roles = this.Roles;
            data.ExampleStatuses = window['DatabaseDesigner'].Shared.ExampleStatuses;
            return data;
        };
        DatabaseModel.prototype.FromJSON = function (data) {
            var self = this;
            this._DataBaseId = data.DatabaseId;
 
            _.each(data.Tables, (table) => {
                var model = new TableModel();
                model.FromJSON(table);
                self._tables.Add(model);
            });
            
            self._isUpdateDatabaseRequired = data.IsUpdateDatabaseRequired;
            self._tables.Items = _.sortBy(self._tables.Items, function (item: any) {
                return item.Sort;
            });
            self._tables.Items = _.groupBy(self._tables.Items, function (item: any) {
                return item.SubTypeId;
            });
            self._tables.Items = _.map(self._tables.Items, function (val, key) {
                return val;
            });
            self._tables.Items = _.flatten(self._tables.Items);
            _.each(self._tables.Items, function (item: any, index) {
                item._position = index;
            });
            this._maxSort = _.max(this._tables.Items, function (item) {
                return item.Sort;
            });
            this._maxSort = this._maxSort.Sort;
            _.each(data.LinkTables, function (table) {
                var model = new LinkTableModel();
                model.FromJSON(table);
                self.LinkTables.Add(model);
            });
            _.each(this.LinkTables.Items, function (table: any) {
                var leftTable = self.Tables.GetItemBy('Id', table.K1TableId);
                var rightTable = self.Tables.GetItemBy('Id', table.K2TableId);
                if (leftTable && rightTable) {
                    table.K1TableGuid = leftTable.Guid;
                    table.K2TableGuid = rightTable.Guid;
                }
                ;
            });
            this.DeletedLinkTables = LinkTableModel.prototype.FromJSONArray(data.DeletedLinkTables);
            this.DeletedSubTables = TableBaseModel.prototype.FromJSONArray(data.DeletedSubTables);
            //Fill matching guids
            var allFields = this.GetAllFields();
            _.each(this.Tables.Items, function (table: any) {
                var matchingFields = table.Fields.Items.filter(function (field) {
                    return field.Matching;
                });
                _.each(matchingFields, function (field: any) {
                    var currentEntityMatching = field.Matching.CurrentEntity;
                    if (currentEntityMatching) {
                        currentEntityMatching.MatchSubjectGuid = table.Guid;
                        currentEntityMatching.MatchFieldsGuid = [field.Guid];
                    }
                    var linkedEntityMatching = field.Matching.LinkedEntity;
                    if (linkedEntityMatching) {
                        linkedEntityMatching.MatchSubjectGuid = self.Tables.GetItemBy('Id', linkedEntityMatching.MatchSubjectId).Guid;
                        linkedEntityMatching.MatchFieldsGuid = allFields.GetItemsWhereIn('Id', linkedEntityMatching.MatchFieldsId).map(function (matchingField) {
                            return matchingField.Guid;
                        });
                    }
                });
            });
            _.each(data.Languages, function (table) {
                var model = new LanguageModel();
                model.FromJSON(table);
                self._languages.Add(model);
            });
            this._matchingModes = data.MatchingModes;
        };
        DatabaseModel.prototype.GetLinkTableByTableGuids = function (k1Guid, k2Guid) {
            return _.find(this.LinkTables.Items, function (item: any) {
                if (((item.K1TableGuid === k1Guid && item.K2TableGuid === k2Guid) || (item.K2TableGuid === k1Guid && item.K1TableGuid === k2Guid)) && item.LifeStatusName !== LifeStatusEnum.Delete) {
                    return item;
                }
            });
        };
        DatabaseModel.prototype.GetSubTable = function (id, guid) {
            var subTables = _.flatten(this._tables.Items.map(function (table) {
                return table.SubTables.Items;
            }));
            return _.find(subTables, function (subTable) {
                return subTable.Id === id || subTable.Guid === guid;
            });
        };
        Object.defineProperty(DatabaseModel.prototype, 'Tables', {
            enumerable: true,
            get: function () {
                return this._tables;
            },
            set: function (val) {
                this._tables = val;
            }
        });
        Object.defineProperty(DatabaseModel.prototype, 'IsUpdateDatabaseRequired', {
            get: function () {
                return this._isUpdateDatabaseRequired;
            }
        });
        Object.defineProperty(DatabaseModel.prototype, 'LinkTables', {
            enumerable: true,
            get: function () {
                return this._linkTables;
            },
            set: function (val) {
                this._linkTables = val;
            }
        });
        Object.defineProperty(DatabaseModel.prototype, 'DeletedLinkTables', {
            enumerable: true,
            get: function () {
                return this._deletedLinkTables;
            },
            set: function (val) {
                this._deletedLinkTables = val;
            }
        });
        Object.defineProperty(TableModel.prototype, 'DeletedSubTables', {
            enumerable: true,
            get: function () {
                return this._deletedSubTables;
            },
            set: function (val) {
                this._deletedSubTables = val;
            }
        });
        Object.defineProperty(DatabaseModel.prototype, 'Languages', {
            enumerable: true,
            get: function () {
                return this._languages;
            },
            set: function (val) {
                this._languages = val;
            }
        });