import * as ko from "knockout";
import * as moment from "moment";

import { PersonalSettingsModel } from 'Core/ProfilePage/PersonalSettings/Models/PersonalSettingsModel';

import Template from 'Core/ProfilePage/PersonalSettings/PersonalSettingsEditors/Time/Templates/Time.html'
ko.templates['Core/ProfilePage/PersonalSettings/PersonalSettingsEditors/Time/Templates/Time'] = Template;


export class Time {
    Value: KnockoutObservable<string | number | boolean>;
    HasChanges: KnockoutObservable<boolean>;
    Disabled: boolean;
    Name: string;
    Id: number;
    Description: string;
    ChangeHandler: any;
    PersonalSettingsInstance: any;

    constructor(model: PersonalSettingsModel, changeHandler, PersonalSettingsInstance) {
        this.Value = ko.observable(model.Value);
        this.Name = model.TranslatedName ? model.TranslatedName : model.Name;
        this.Disabled = model.LockToDefault;
        this.Id = model.Id;
        this.Description = model.DescriptionTranslation ? model.DescriptionTranslation : model.Description;
        this.HasChanges = ko.observable(false);
        this.ChangeHandler = changeHandler;
        this.PersonalSettingsInstance = PersonalSettingsInstance;
    }

    DateChanged(event): void {
        const newDate = event ? moment(event).format('HH:mm') : null;
        this.Value(newDate);

        if (this.HasChanges()) {
            this.ChangeHandler.call(this.PersonalSettingsInstance);
        } else {
            this.HasChanges(true);
        }
    }

    GetTemplateName() {
        return 'Core/ProfilePage/PersonalSettings/PersonalSettingsEditors/Time/Templates/Time';
    }

    AfterRender(el: Array<HTMLElement>) {
    }
}