import {P} from "Core/Common/Promise";
import {IRequestOptions, Request} from "Core/Common/Request";

import {SwitchLanguageModel} from '../Models/SwitchLanguageModel';
import {SwitchLanguageResult} from '../Models/SwitchLanguageResult';

export class LanguageSwitcherStore {
    static ChangeLanguage(model: SwitchLanguageModel): P.Promise<SwitchLanguageResult> {
        let deferredResult = P.defer<any>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: '/api/AuthApi/RefreshToken',
                type: 'POST',
                dataType: 'json'
            }, params: model
        };

        Request.Send(requestParams)
            .then(data => {
                deferredResult.resolve(SwitchLanguageResult.Deserialize(data))
            })
            .fail(err => {
                deferredResult.reject(this.GetError(err))
            });

        return deferredResult.promise();
    }

    private static GetError(error: P.Rejection): P.Rejection {
        const errorResult = JSON.parse(error.message);
        return {message: errorResult.error};
    }
}