import {P} from "Core/Common/Promise";
import {Request, IRequestOptions} from "Core/Common/Request";

interface ICheckRightsDto{
	TableId: number;
	SecurityWord: number;
}

export class TableSecurityStore {
	static GetTableSecurityScreen(userGroupId: number): P.Promise<any> {
		let deferredResult = P.defer<any>();

		Request.Send({
				proxy: {
					url: '/api/TableSecurityApi/GetTableSecurityScreen',
					dataType: 'json',
					type: 'GET'
				},
				params: { id: userGroupId }
			}).then(result => deferredResult.resolve(result));

		return deferredResult.promise();
	}

	static IsUserAllowedTo(request :ICheckRightsDto): P.Promise<boolean> {
		let deferredResult = P.defer<boolean>();

		Request.Send({
				proxy: {
					cacheRequest: true,
					url: '/api/TableSecurityApi/IsUserAllowedTo',
					dataType: 'json',
					type: 'POST'
				},
				params: request
			}).then(result => {
				if (result.IsSuccessfull) {
					deferredResult.resolve(result.ResultObject);
				} else {
					deferredResult.reject({message: result.ErrorMessage});
				}
			});

		return deferredResult.promise();
	}
}