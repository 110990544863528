const Num_16 = 16;
const Num_18 = 18;
export enum FontSize {
	Source = Num_16,
	Destination = Num_16,
	Table = Num_18,
	Trigger = Num_18,
	JSFunction = Num_18,
	APIMethod = Num_18,
	CSharpFunction = Num_18,
	Param = 13
}