import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {URL} from 'Core/Common/Url';
import {GenericDeserialize} from 'libs/cerialize';
import {DocumentDataModel} from 'Core/Controls/Document/DocumentDataModel';

export interface IGetDocumentDataRequestModel {
	SubjectEntityId : number;
	SubjectRecordId : number;
	FieldId: number;
};

export class DocumentStore {
	static GetData(params: IGetDocumentDataRequestModel): P.Promise<any> {
		let deferredResult = P.defer();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/DocumentsApi/GetData',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(this.Deserialize(data));
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}


	static GetDocumentEntityId(): P.Promise<any> {
		let deferredResult = P.defer();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/DocumentsApi/GetDocumentEntityId',
				dataType: 'json',
				type: 'GET'
			}
	};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(this.Deserialize(data));
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}


	static Deserialize(jsonObject: any): DocumentDataModel {
		return GenericDeserialize<DocumentDataModel>(jsonObject.ResultObject, DocumentDataModel);
	}
}