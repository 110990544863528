import * as ko from 'knockout';
import * as moment from 'moment';
import * as _ from 'underscore';

import {BaseControl, IControlValue} from 'Core/Controls/BaseControl/BaseControl';
import {IControlParam} from 'Core/Screens/IScreen';
import {CONTROL_TYPES, FIELD_TYPES, FONT_STYLES, RenderModes} from 'Core/Constant';

import DateTimeConfig from 'Core/Controls/DateTime/Configs/date-time-config.json';
import {GeneralProperties} from 'Core/GeneralProperties/GeneralProperties';
import {ICON_CLASSES} from 'Core/Controls/DateTime/Icons';
import {FormatConverter} from 'FormatEditor/FormatConverter';
import {DATE_FORMATS} from 'Core/Constants/DateTimeFormats';

import ViewTemplate from 'Core/Controls/DateTime/Templates/View.html';
import HelpViewTemplate from 'Core/Controls/DateTime/Templates/HelpView.html';
import ToolBarTempalte from 'Core/Controls/DateTime/Templates/ToolBar.html';
import DesignTempalte from 'Core/Controls/DateTime/Templates/Design.html';
import EditTemplate from 'Core/Controls/DateTime/Templates/Edit.html';
import {ScreenTypes} from "../../Common/Enums/ScreenTypes";
import { EVENTS } from 'Core/Screens/Events';
import { BlockUI } from 'Core/Common/BlockUi';

ko.templates['Core/Controls/DateTime/Templates/ToolBar'] = ToolBarTempalte;
ko.templates['Core/Controls/DateTime/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/DateTime/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/DateTime/Templates/Design'] = DesignTempalte;
ko.templates['Core/Controls/DateTime/Templates/Edit'] = EditTemplate;

export class DateTime extends BaseControl {
	private _value: KnockoutObservable<Date>;
	private _originalValue: string;
	private _labelStyle: KnockoutObservable<any>;
	private _textInputStyle: KnockoutObservable<any>;
	private _datetimeFormat: string;
	private _initialDateChange: boolean;
	private _skipTimeShifting: boolean;
	private _calculatedDependsOnValue: string;

	constructor(params: IControlParam) {
		super(params, DateTimeConfig);

		this._value = ko.observable(null);
		this._initialDateChange = true;

		this._skipTimeShifting = params.Model.SkipTimeShifting;

		this.GetDateFormat();

		this._labelStyle = ko.observable(null);
		this._textInputStyle = ko.observable(null);

		this._model.subscribe(() => {
			if (this.Properties) {
				this.ApplyProperties();
			}
		});

		const screen = this._form && this._form.GetScreen();
        if (screen) {
            screen.On(EVENTS.DATA_CHANGED, this, (evt) => {
                if (evt.data && evt.data.ScreenVariable && this.FieldModel.FilledById != 0) {

                    let dynamicField = _.find(this.FieldModel.DynamicFields, (field) => {
                        return field === evt.data.ScreenVariable.Field.Id
                    });

                    if (dynamicField) {
                        this.UpdateDependsOnValue();
                    }
                }
            });
        }

        this.ApplyProperties();
    }

	private UpdateDependsOnValue() {

		this.CalculateDependOnValue()
		.then(dependsOnValue=>{
			let valueWithTimezone = this.PrepareValue(dependsOnValue)
			this._originalValue = valueWithTimezone;
			this._calculatedDependsOnValue = dependsOnValue;
			let momentDate = moment(valueWithTimezone);
			if(momentDate.isValid()){
				this._value(momentDate.toDate());
			}else{
				this._value(null);
			}			
		});
	}

	GetDateFormat() {
		this._datetimeFormat = FormatConverter.GetDateFormatFromFieldModel(this.FieldModel, true, this._model());
	}

	ApplyProperties() {
		if (this.Properties) {
			// label
			if (this.Properties.Label) {
				const labelStyle = {backgroundColor: null, color: null};

				_.each(this.Properties.Label.Properties, (property: any) => {
					if (property.BackgroundColor) {
						labelStyle.backgroundColor = property.BackgroundColor;
					}

					if (property.Color) {
						labelStyle.color = property.Color;
					}
				});

				this._labelStyle(labelStyle);
			}
		}

		if (this.Properties) {
			// textInput
			if (this.Properties.TextInput) {
				const textInputStyle = {backgroundColor: null};

				_.each(this.Properties.TextInput.Properties, (property: any) => {
					if (property.BackgroundColor) {
						textInputStyle.backgroundColor = property.BackgroundColor;
					}
				});

				this._textInputStyle(textInputStyle);
			}
		}
		if (this._form && (this._form.GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.LinkEditor])) {
			this.ApplyLinkEditorStyles();
		}

		if(this.HideIfCondition){
            if(this.IsRunTime){
                this._isVisible(false);
            }
        }
	}

	private ApplyLinkEditorStyles() {
		if(!this.FieldModel.HasLinkEditorVisibility){
			return;
		}
		const labelStyle = {
			color: null,
			fontWeight: null,
			fontStyle: null,
			textDecoration: null
		};
		if (this.FieldModel.FontColor) {
			labelStyle.color = this.FieldModel.FontColor;
		}
		labelStyle.fontWeight = FONT_STYLES.NORMAL;

		if (this.FieldModel.FontStyles) {
			_.forEach(this.FieldModel.FontStyles, (style) => {

				switch ( style.Name.toLowerCase() ) {
					case FONT_STYLES.BOLD:
						labelStyle.fontWeight = FONT_STYLES.BOLD;
						break;
					case FONT_STYLES.UNDERLINE:
						labelStyle.textDecoration = FONT_STYLES.UNDERLINE;
						break;
					case FONT_STYLES.ITALIC:
						labelStyle.fontStyle = FONT_STYLES.ITALIC;
						break;
				}
			})
		}

		this.Extend(labelStyle, this._labelStyle());
		this._labelStyle(labelStyle);
	}

	SetValue(controlValue: IControlValue): void {
		if (controlValue.Data && controlValue.Data.Value) {
		
			let valueWithTimezone = this.PrepareValue(controlValue.Data.Value)
			this._value(moment(valueWithTimezone).toDate());
			this._originalValue = valueWithTimezone;
		} else {
			this._value(null);
		}
		this._resetDependsOnValue(false);
	}
	
    ResetDependOnValue() {
        this.UpdateDependsOnValue();
    }

	PrepareValue(value: string){
		const firstAttachedField = _.first(this._model().Fields);
		const fieldType = firstAttachedField.FieldTypeName || this._model() && this._model().TypeName;

		var valueWithTimezone = 
			this._datetimeFormat === DATE_FORMATS.TIME_SPAN.Format
			|| this._datetimeFormat === DATE_FORMATS.TIME_SPAN_LONG.Format
			|| fieldType === FIELD_TYPES.Date
			|| this._skipTimeShifting
				? value
				: FormatConverter.CorrectTimezone(value);
		return valueWithTimezone;				
	}

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}

	Deserialize() {
		const field = _.first(this._model().Fields);
		const saveValue = this.ConvertDateToUTC(this._value());

		if (field && !this._isReadonly) {
			return [`${field.EntityName}.${field.Name}`, saveValue];
		}

		return null;
	}

	ConvertDateToUTC(date: Date): string {
		if (!date) {
			return null;
		}

		return moment(date).format();
	}

	GetDateFormatIconClass(): string {
		const iconClassName =
			(this.FormatName && this.FormatName !== 'None' && ICON_CLASSES[this.FormatName]) ||
			(this.FieldModel.FieldTypeName && ICON_CLASSES[this.FieldModel.FieldTypeName]) ||
			ICON_CLASSES[this._model().TypeName];

		return `${iconClassName} ${this.GetCombinedReadOnly() ? 'disabled' : ''}`;
	}

	// method fired when value was changed via datepicker UI either as value was setted from model at first time
	DateChanged(event): void {
		// return if date was changed after setting initial and value is not empty
		if (this._initialDateChange && this._value()) {
			this._initialDateChange = false;

			return;
		}

		this._initialDateChange = false;

		// update value with new date
		this._value(event || null);

		const saveValue = this.ConvertDateToUTC(this._value());

		this.UpdateVariable({ Field: this.GetFieldModel(), ControlType: CONTROL_TYPES.DateTime }, saveValue);		
	}

	IsModified(): boolean {
		const saveValue = this.ConvertDateToUTC(this._value());

		if (this._originalValue != saveValue && (!this._originalValue || !saveValue)) {
			return true;
		}

		const difference = moment(saveValue).diff(moment(this._originalValue));

		return !!difference || super.IsModified();
	}

	GetValue(): any {
		return this.ConvertDateToUTC(this._value());
	}

	GetDisplayValue(): any {
		const value = this._value();

		return value && moment(value).format(this._datetimeFormat);
	}

	IsValid(): boolean {
		this._isValid(!this._isRequired || !!this._value());

		return this._isValid();
	}

	GetLocale() {
		return moment.locale();
	}
}