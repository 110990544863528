import {deserialize, deserializeAs} from 'libs/cerialize';

import {ActionCheckListItemDto} from "./ActionCheckListItemDto";

export class ActionCheckListDto {
    @deserialize
    LifeStatusId: number;

    @deserialize
    LifeStatusName: string;

    @deserialize
    LifeStatusMemo: string;

    @deserialize
    LifeStatusMemoTranslation: string;

    @deserialize
    LifeStatusApprovalName: string;

    @deserialize
    LifeStatusNoActionNode: boolean;

    @deserialize
    LifeStatusMultipleActions: boolean;

    @deserialize
    LifeStatusTranslatedName: string;

    @deserialize
    LifeStatusSort: number;

    @deserialize
    IsFollowUpDisabled: boolean;

    @deserialize
    DisabledReason: string;

    @deserializeAs(ActionCheckListItemDto)
    CheckItems: ActionCheckListItemDto[];

    constructor() {
        this.CheckItems = [];
    }
}