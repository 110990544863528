export const CONTROL_TYPES_LINK = {
	Mail: {name: 'Mail', icon: 'fa fa-send'},
	URL: {name: 'URL', icon: 'fa fa-globe'},
	SKYPE: {name: 'SKYPE', icon: 'fa fa-skype'},
	Linkedin: {name: 'Linkedin', icon: 'fa fa-linkedin'},
	Facebook: {name : "Facebook", icon: "fa fa-facebook-square"},
	YouTube: {name : "YouTube", icon: "fa fa-youtube-play"},
	Twitter: {name : "Twitter", icon: "fa fa-twitter-square"},
	Instagram: {name : "Instagram", icon: "fa fa-instagram"},
	Pinterest: {name : "Pinterest", icon: "fa fa-pinterest-square"},
	Medium: {name : "Medium", icon: "fa fa-medium"},
	SQL: {name: 'SQL', icon: ''}
};