import { deserialize } from 'libs/cerialize';
import { deserializeAs } from 'libs/cerialize';

export class MailTabModel {
	constructor() {}

	@deserialize
	MailConnectionId: number;
	
	@deserialize
	Login: string;

	@deserialize
	Client: string;
}