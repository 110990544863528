export const NetworkDesignerEvents = {
    HeaderClicked: 'NetworkDesignerEvents_HeaderClicked',

    RemovingAccessRequested: 'NetworkDesignerEvents_RemovingAccessRequested',
    AccessRemoving: 'NetworkDesignerEvents_AccessRemoving',

    SavingAccessChangesRequested: 'NetworkDesignerEvents_SavingAccessChangesRequested',
    AccessСhangesSaving: 'NetworkDesignerEvents_AccessСhangesSaving',

    SavingAccessRequested: 'NetworkDesignerEvents_SavingAccessRequested',
    AccessSaving: 'NetworkDesignerEvents_AccessSaving',

    CreatingAccessCancelled: 'NetworkDesignerEvents_CreatingAccessCancelled',
    EditingAccessCancelled: 'NetworkDesignerEvents_EditingAccessCancelled'
}

export class RemoteDatabaseAccessChanges {
    constructor(public Id: number, public UserId: number, public RemoteUserName: string) {
    }
}

export class NewRemoteDatabaseAccess {
    constructor(public UniqueId: string, public UserId: number, public RemoteUserName: string) {
    }
}