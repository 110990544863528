import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import { OperationResultModel } from 'Core/Models/OperationResultModel';

export interface IDeleteQueryRequestModel {
	Id: number;
};

export class DeleteQueryStore {
	static Delete(params: IDeleteQueryRequestModel): P.Promise<OperationResultModel> {

		var deferredResult = P.defer<OperationResultModel>();
		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.QUERY_BUILDER.DELETE_QUERY,
				dataType: 'json',
				type: 'GET'
			}, params: params
		};
		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(null);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}

			});

		return deferredResult.promise();
	}
}