import * as Viewer from "viewer";

import {BlockUI} from "Core/Common/BlockUi";
import {Notifier} from 'Core/Common/Notifier';
import {P} from "Core/Common/Promise";

import {ProductConfiguratorStore} from '../../Stores/ProductConfiguratorStore';
import {ImagePropertyControlStore} from '../../Pages/ConfigurationPage/Models/Properties/Controls/Image/Stores/ImagePropertyControlStore';

import {ImageTableTypes} from "./Enums/ImageTableTypes";

import Template from './Templates/ProductImageViewer.html';

export interface IProductImageViewerOptions {
	ProductsEntityId: number;
	OrderEntityId: number;
	ImageFieldId?: number;
}

export class ProductImageViewer {
	private _productConfiguratorStore: ProductConfiguratorStore;
	private _imagePropertyControlStore: ImagePropertyControlStore;
	private _imageContainer: HTMLElement;
	private _imageViewer: Viewer;

	private _orderEntityId: number;
	private _productsEntityId: number;
	private _imageFieldId: number;

	constructor(options: IProductImageViewerOptions) {
		this._orderEntityId = options.OrderEntityId;
		this._productsEntityId = options.ProductsEntityId;
		this._imageFieldId = options.ImageFieldId;

		this.InitImageViewer();
	}

	InitImageViewer() {
		const $container = $("<div/>");
		this._imageContainer = $container[0];

		const opt = {
			button: true,
			inline: false,
			navbar: false,
			title: false,
			toolbar: false,
			tooltip: true,
			movable: true,
			zoomable: true,
			rotatable: true,
			scalable: true,
			transition: true,
			fullscreen: true,
			keyboard: true,
			zIndex: 99999,
			filter() {
				return true;
			}
		};

		this._imageViewer = new Viewer(this._imageContainer, opt);
	}

	ShowOriginalImage(recordId: number, imageTableType: ImageTableTypes) {
		let imageIndex = this.GetImageIndex(recordId, imageTableType);
		if (imageIndex !== -1) {
			this._imageViewer.view(imageIndex);
			return;
		}

		BlockUI.Block();
		this.BuildRequest(recordId, imageTableType)
			.then(imageData => {
				const image = document.createElement('img');
				$(image).prop('src', imageData);
				$(image).attr('data-id', recordId);
				$(image).attr('table-type', ImageTableTypes[imageTableType]);

				$(this._imageContainer).append(image);
				this._imageViewer.update();

				imageIndex = this.GetImageIndex(recordId, imageTableType);
				this._imageViewer.view(imageIndex);
			}).fail(error => new Notifier().Failed(error.message)
			).always(() => BlockUI.Unblock());
	}

	private BuildRequest(recordId: number, imageTableType: ImageTableTypes): P.Promise<string> {
		switch (imageTableType) {
			case ImageTableTypes.Product:
			{
				return new ProductConfiguratorStore(this._orderEntityId, this._productsEntityId)
					.GetOriginalImage(recordId);
			}
			case ImageTableTypes.ProductProperty:
			{
				return new ImagePropertyControlStore(this._orderEntityId, this._productsEntityId)
					.GetOriginalImageProperty(recordId, this._imageFieldId);
			}
			case ImageTableTypes.OrderProduct:
			{
				return new ImagePropertyControlStore(this._orderEntityId, this._productsEntityId)
					.GetOriginalSavedProductImage(recordId, this._imageFieldId);
			}
			case ImageTableTypes.Hook:
			{
				return new ImagePropertyControlStore(this._orderEntityId, this._productsEntityId)
					.GetOriginalSavedPartImage(recordId, this._imageFieldId);
			}
			default:
			{
				return new ProductConfiguratorStore(this._orderEntityId, this._productsEntityId)
					.GetOriginalImage(recordId);
			}
		}
	}

	private GetImageIndex(recordId: number, imageTableType: ImageTableTypes) {
		return _.findIndex(this._imageViewer.images, image => $(image).attr('data-id') === `${recordId}`
			&& $(image).attr('table-type') === `${ImageTableTypes[imageTableType]}`);
	}

	GetTemplate() {
		return Template;
	}
}