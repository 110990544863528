import { deserialize, deserializeAs, serialize, serializeAs} from 'libs/cerialize';
import {FieldMetadataModel} from 'Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel';
import { Guid } from 'Core/Common/Guid';

export class CrossValueSettingsModel {
	constructor(partial?: Partial<CrossValueSettingsModel>) {
		if (partial) {
			this.RelationTypeValueId = partial.RelationTypeValueId;
			this.ShowNexRelationButton = partial.ShowNexRelationButton;
			this.ShowEditRelationButton = partial.ShowEditRelationButton;
		}
	}

	@serialize
	@deserialize
	RelationTypeValueId: number;

	@serialize
	@deserialize
	ShowNexRelationButton: boolean;

	@serialize
	@deserialize
	ShowEditRelationButton: boolean;
}

export class QueryColumnModel {

	constructor(){
		this.CrossValueSettings = [];
		this.Guid = Guid.NewGuid();
		this.DisplayFields = [];
	}

	@serialize
	@deserialize
	QueryEntityGuid: string;

	@serializeAs(FieldMetadataModel)
	@deserializeAs(FieldMetadataModel)
	Metadata: FieldMetadataModel;

	@serializeAs(CrossValueSettingsModel)
	@deserializeAs(CrossValueSettingsModel)
	CrossValueSettings: Array<CrossValueSettingsModel>;

	@serialize
	@deserialize
	Guid: string;

	@serialize
	@deserialize
	DisplayOrder: number;

	@serialize
	@deserialize
	IsWrapped: boolean;

	@serialize
	@deserialize
	IsGroup: boolean;

	@serialize
	@deserialize
	IsAverage: boolean;

	@serialize
	@deserialize
	IsTotal: boolean;

	@serialize
	@deserialize
	IsCount: boolean;

	@serialize
	@deserialize
	IsSubTotal: boolean;

	@serialize
	@deserialize
	DisplayPriority: number;

	@serialize
	@deserialize
	IsCrossColumn: boolean;

	@serialize
	@deserialize
	IsCrossWrapped: boolean;

	@serialize
	@deserialize
	IsCrossRow: boolean;

	@serialize
	@deserialize
	IsCrossValue: boolean;

	@serialize
	@deserialize
	AggregateFunction: string;

	@serialize
	@deserialize
	DisplayFields: Array<number>;
}