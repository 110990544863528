import * as ko from 'knockout';
import _ from 'underscore';

import {Notifier} from 'Core/Common/Notifier';
import {CookieManager} from 'Core/Common/CookieManager';
import {BlockUI} from 'Core/Common/BlockUi';

import {LockManager} from 'Core/Components/Locker/LockManager';
import {SignalRNotificationManager} from 'Core/Components/SignalR/SignalRNotificationManager';

import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';

import {RemoteDatabaseConnection} from './Models/RemoteDatabaseConnection';

import {RemoteDatabaseConnectionMappings} from './Mappings/RemoteDatabaseConnectionMappings';

import {RemoteDatabasesStore} from './Stores/RemoteDatabasesStore';
import {AuthStore} from 'Auth/Stores/AuthStore';

import {GetRemoteDatabaseConnectionUrlDto} from './Stores/Models/GetRemoteDatabaseConnectionUrlDto';
import {SetConnectedDto} from './Stores/Models/SetConnectedDto';

import Template from './Templates/Template.html';
import {DisconnectDto} from './Stores/Models/DisconnectDto';

ko.templates['Core/ProfilePage/NetworkDatabases/Templates/Template'] = Template;

export class RemoteDatabases {
    private _el: HTMLElement;
    private _store: RemoteDatabasesStore;
    private _mappings: RemoteDatabaseConnectionMappings;

    private _connections: KnockoutObservableArray<RemoteDatabaseConnection>;
    private _anyConnection: KnockoutComputed<boolean>;
    private _anyConnected: KnockoutComputed<boolean>;

    constructor() {
        this._store = new RemoteDatabasesStore();
        this._mappings = new RemoteDatabaseConnectionMappings();

        this._connections = ko.observableArray([]);
        this._anyConnection = ko.pureComputed(() => this._connections().length > 0);
        this._anyConnected = ko.pureComputed(() => _.any(this._connections(), c => c.Connected));
    }

    GetTemplateName() {
        return 'Core/ProfilePage/NetworkDatabases/Templates/Template';
    }

    AfterRender(el: HTMLElement) {
        this._el = el;

        this.GetConnections();
    }

    Connect(connection: RemoteDatabaseConnection) {
        //If the database is on the same env
        if (document.URL.indexOf(connection.Url) > -1) {
            this.ConnectToAnotherDb(connection);
            return;
        }

        this.ConnectToSeparateEnv(connection);
    }

    Disconnect(connection: RemoteDatabaseConnection) {
        connection.Connected = false;

        const params = new DisconnectDto(connection.Id, 'WebApplicationId', 'WebApplicationSecret');
        this._store.Disconnect(params);
    }

    private GetConnections() {
        this._store.GetConnections()
            .then(connectionsDto => {
                const connections = this._mappings.MapToRemoteDatabaseConnections(connectionsDto);
                this._connections(connections);
            })
            .fail(err => {
                new Notifier().Failed(err.message);
            });
    }

    private ConnectToAnotherDb(connection: RemoteDatabaseConnection) {
        BlockUI.Block();
        this.GetConnectionUrlPromise(connection)
            .then(connectionUrl => {
                const userVarsManager = UserVarsManager.Instance;
                const userVars = userVarsManager.GetAll();

                AuthStore.SignOut({UserVariables: userVars})
                    .then(() => {
                        LockManager.Instance.ReleaseAllLocks();
                        SignalRNotificationManager.Instance.Stop();
                        CookieManager.RemoveTokens();

                        BlockUI.Unblock();
                        window.open(connectionUrl, '_self');
                    });
            })
            .fail(err => {
                BlockUI.Unblock();
                new Notifier().Failed(err.message)
            });
    }

    private ConnectToSeparateEnv(connection: RemoteDatabaseConnection) {
        this.GetConnectionUrlPromise(connection)
            .then(connectionUrl => {
                connection.Connected = true;

                const params = new SetConnectedDto(connection.Id, true);
                this._store.SetConnected(params);

                window.open(connectionUrl, '_blank');
            })
            .fail(err => new Notifier().Failed(err.message));
    }

    private GetConnectionUrlPromise(connection: RemoteDatabaseConnection) {
        const params = new GetRemoteDatabaseConnectionUrlDto(connection.Id, 'WebApplicationId', 'WebApplicationSecret');
        return this._store.GetConnectionUrl(params);
    }
}