import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {LookupResponseModel} from "../Models/LookupResponseModel";

export class LookupPropertyControlStore {
    constructor(private _ordersEntityId: number, private _productsEntityId: number) {
    }

    GetAvailableOptions(productId: number, fieldId: number): P.Promise<LookupResponseModel[]> {
        const deferred = P.defer<LookupResponseModel[]>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetAvailableOptions',
                type: 'GET',
                dataType: 'json'
            }, params: {
                OrderEntityId: this._ordersEntityId,
                ProductEntityId: this._productsEntityId,
                ProductId: productId,
                FieldId: fieldId
            }
        })
            .then(images => deferred.resolve(images))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error getting available options')))

        return deferred.promise();
    }

    GetAvailableOption(productId: number, fieldId: number, optionId: number): P.Promise<LookupResponseModel> {
        const deferred = P.defer<LookupResponseModel>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetAvailableOption',
                type: 'GET',
                dataType: 'json'
            }, params: {
                OrderEntityId: this._ordersEntityId,
                ProductEntityId: this._productsEntityId,
                ProductId: productId,
                FieldId: fieldId,
                OptionId: optionId
            }
        })
            .then(option => deferred.resolve(option))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error getting available option')));

        return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}