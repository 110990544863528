import * as ko from 'knockout';
import * as _ from 'underscore';

import { QueryExpressionModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { QueryColumnModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryColumnModel';
import { QueryEntityJoinModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityJoinModel';
import { FIELD_TYPES } from 'Core/Constant';
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";
import { AGGREGATE_FUNCTIONS } from 'QueryBuilder/Constants';
import { Util } from 'QueryBuilder/Util';
import { FieldInfo } from 'QueryBuilder/CrossTableConfig/FieldInfo';

import Template from 'QueryBuilder/CrossTableConfig/Templates/CrossTableConfig.html';
import { QueryBuilderStore } from 'QueryBuilder/Stores/QueryBuilderStore';
import { CrossValueConfig } from './ClusteredConfig';

export class CrossTableConfig{
	private _expressionModel: QueryExpressionModel;
	private _fields: KnockoutObservableArray<FieldInfo>;
	private _crossColumn: KnockoutObservable<FieldInfo>;
	private _crossValue: KnockoutObservable<FieldInfo>;
	private _crossWrapped: KnockoutObservable<FieldInfo>;

	private _isCrossTable: KnockoutObservable<boolean>;
	private _labels = LABELS;
	private _clusteredConfig: KnockoutObservable<CrossValueConfig>;

	constructor() {
		this._fields = ko.observableArray([]);
		this._crossColumn = ko.observable(null);
		this._crossValue = ko.observable(null);
		this._crossWrapped = ko.observable(null);
		this._clusteredConfig = ko.observable(null);

		this._isCrossTable = ko.observable(false);

		this._isCrossTable.subscribe((newValue) => { this._expressionModel.IsCrossTable = newValue; });

		this._crossWrapped.subscribe((newValue) => {
			_.each(this._fields(), (item: FieldInfo) => { item.Column.IsCrossWrapped = false; });
			if (newValue) {
				newValue.Column.IsCrossWrapped = true;
				if (newValue === this._crossValue()) {
					this._crossValue(null);
				}

				if (newValue === this._crossColumn()) {
					this._crossColumn(null);
				}

				newValue.IsCrossRow(false);
			}
		});

		this._crossColumn.subscribe((newValue) => {
			_.each(this._fields(), (item: FieldInfo) => { item.Column.IsCrossColumn = false; });
			if (newValue) {
				newValue.Column.IsCrossColumn = true;
				if (newValue === this._crossValue()) {
					this._crossValue(null);
				}

				if (newValue === this._crossWrapped()) {
					this._crossWrapped(null);
				}

				newValue.IsCrossRow(false);
			}
		});

		this._crossValue.subscribe(async (newValue) => {
			_.each(this._fields(), (item: FieldInfo) => { item.Column.IsCrossValue = false; });
			if (newValue) {
				newValue.Column.IsCrossValue = true;
				if (newValue === this._crossColumn()) {
					this._crossColumn(null);
				}

				if (newValue === this._crossWrapped()) {
					this._crossWrapped(null);
				}
				newValue.IsCrossRow(false);
			}

			this._clusteredConfig(new CrossValueConfig(newValue));
			
		});
	}

	set ExpressionModel(expressionModel: QueryExpressionModel) {
		this._expressionModel = expressionModel;
		this._isCrossTable(this._expressionModel.IsCrossTable);
	}

	get CrossColumn(): KnockoutObservable<FieldInfo> {
		return this._crossColumn;
	}

	get CrossValue(): KnockoutObservable<FieldInfo> {
		return this._crossValue;
	}

	get CrossWrapped(): KnockoutObservable<FieldInfo> {
		return this._crossWrapped;
	}

	get IsCrossTable(): KnockoutObservable<boolean> {
		return this._isCrossTable;
	}

	GetTemplate() {
		return Template;
	}

	AfterRender() { }

	Update() {
		let fields = [];
		let queryEntities = Util.GetAllQueryEntities(this._expressionModel);
		_.each(queryEntities,
			entity => {
				_.each(entity.Columns,
					column => {
						if(column.Metadata.Type) {
							let field = new FieldInfo(column, entity);
								fields.push(field);
								field.IsCrossRow.subscribe((newValue) => {
									if (newValue) {
										if (field === this._crossColumn()) {
											this._crossColumn(null);
										}
										if (field === this._crossValue()) {
											this._crossValue(null);
										}
										if (field === this._crossWrapped()) {
											this._crossWrapped(null);
										}
									}
								});
							}
					});
			});

		fields = _.sortBy(fields, (fieldModel: FieldInfo) => { return fieldModel.Column.DisplayOrder; });

		this._fields(fields);
		this._crossColumn(_.find(fields, (item: FieldInfo) => { return item.Column.IsCrossColumn }));
		this._crossValue(_.find(fields, (item: FieldInfo) => { return item.Column.IsCrossValue }));
		this._crossWrapped(_.find(fields, (item: FieldInfo) => { return item.Column.IsCrossWrapped }));
	}

	get Fields(): KnockoutObservableArray<FieldInfo> {
		return this._fields;
	}

	Validate() {

	}
}