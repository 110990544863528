import { deserialize } from 'libs/cerialize';
import { deserializeAs } from 'libs/cerialize';
import * as ko from 'knockout';
import * as _ from 'underscore';

import { FunctionDesignerMethodParamModel } from 'Core/Controls/FunctionDesigner/Models/FunctionDesignerMethodParamModel';

export const SIDES = {
	IN: 'IN',
	OUT: 'OUT'
}

export class FunctionDesignerMethodModel {
	constructor() {
		this.Params = [];
	}
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	TypeId: number;

	@deserialize
	TypeName: string;

	@deserialize
	Code: string;

	@deserializeAs(FunctionDesignerMethodParamModel)
	Params: Array<FunctionDesignerMethodParamModel>;

	get InParams(): Array<FunctionDesignerMethodParamModel> {
		return _.filter(this.Params, (item) => item.SideName === SIDES.IN);
	}

	get OutParams(): Array<FunctionDesignerMethodParamModel> {
		return _.filter(this.Params, (item) => item.SideName === SIDES.OUT);
	}

	get Title(): string {
		return `${this.Name}(${this.TypeName})`;
	}
}