import { P } from 'Core/Common/Promise';
import {URL} from 'Core/Common/Url';
import {Request} from 'Core/Common/Request';

export class DictionaryComparisonStore {

    static GetEnvironments(): P.Promise<Array<string>> {
		let deferredResult = P.defer<Array<string>>();

		Request
		.For('api/DictionaryApi/GetEnvironments')
		.Get()
        .fail(error=>deferredResult.reject({ message: error.message}))
		.then(data => deferredResult.resolve(data));

        return deferredResult.promise();
    }

    static GetDatabases(environment: string): P.Promise<Array<string>> {
		let deferredResult = P.defer<Array<string>>();

		Request
		.For('api/DictionaryApi/GetDatabases')
		.Get({ environment: environment })
        .fail(error=>deferredResult.reject({ message: error.message}))
		.then(data => deferredResult.resolve(data));

        return deferredResult.promise();
    }

	static Compare(environment: string, database: string): P.Promise<string> {
		let deferredResult = P.defer<string>();

		Request
		.For('api/DictionaryApi/Compare')
		.Post({ environment: environment, database: database }, 'text')
        .fail(error=>deferredResult.reject({ message: error.message}))
		.then(data => {
			deferredResult.resolve(data)
		});

        return deferredResult.promise();
    }
}