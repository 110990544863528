import {deserialize} from 'libs/cerialize';
import {deserializeAs} from 'libs/cerialize';
import {SubFormModel} from 'Core/Models/Screens/SubFormModel'
import {ScreenDataModel} from "Core/ScreenManager/Models/ScreenDataModel";
import {RecordSpecsModel } from "Core/ScreenManager/Models/RecordSpecsModel";
import {ConcreteEntityModel} from 'QueryBuilder/Models/ConcreteEntityModel';
import {LanguageModel} from 'Core/Controls/BaseControl/Models/LanguageModel';
import {LanguageInfoModel} from "Core/Components/Translation/Models/LanguageInfoModel";

import {ControlToolBarModel} from 'Core/Controls/FormDesigner/ToolBar/ControlToolBar/Models/ControlToolBarModel'
import {DataModes} from "Core/Enums/DataModes";
import {TranslationModel} from "Core/Controls/BaseControl/Models/TranslationModel";
import { IconModel } from "Core/Controls/BaseControl/Models/IconModel";
import { ActionSubjectRecordModel } from "Core/ScreenManager/Models/ActionSubjectRecordModel";

export class ScreenModel {
	constructor(init?: Partial<ScreenModel>) {
		this.TranslationModel = [];
		this.SubForms = [];
		Object.assign(this, init);
	}

	@deserialize
	Id: number;

	@deserialize
	Name: string;

    @deserializeAs(TranslationModel)
    TranslationModel: Array<TranslationModel>;

	@deserialize
	EntityId: number;

	@deserialize
	EntityName: string;

	@deserialize
	EntityNameTranslation: string;

	@deserializeAs(IconModel)
	EntityIcon: IconModel;

	@deserialize
	EntityColor: string;

	@deserialize
	EntityIconImage: string;

	@deserialize
	FieldCollectionId: number;

	@deserialize
	TableTypeId: number;

	@deserialize
    TableTypeName: string;

    @deserialize
    TableTypeNameTranslation: string;

    @deserialize
    TableTypeFlowFolder: boolean;

	@deserialize
	KindId: number;

	@deserialize
	ScreenTypeId: number;

	@deserialize
	ScreenTypeName: string;

	@deserialize
	TemplateName: string;

	@deserialize
	IsDashMain: boolean;

	@deserialize
	IsEditScreenExist: boolean;

	@deserialize
	IsListScreenExist: boolean;

	@deserialize
	IsSpecialScreenExist: boolean;

	@deserialize
	IsDashboardScreenExist: boolean;

    @deserialize
	IsConsultScreenExist: boolean;

    @deserialize
	IsStepsScreenExist: boolean;

    @deserialize
	ScreenDoesNotExistError: boolean;

    @deserialize
	ScreenDoesNotExistErrorMessage: boolean;

	@deserialize
	ShowFollowUp: boolean;

	@deserializeAs(SubFormModel)
	ActionBar: SubFormModel;

	@deserializeAs(SubFormModel)
	BottomBar: SubFormModel;

	@deserializeAs(SubFormModel)
	SubForms: Array<SubFormModel>;

	@deserializeAs(ScreenDataModel)
	Data: ScreenDataModel;

	@deserializeAs(LanguageInfoModel)
	Languages: Array<LanguageInfoModel>;

	@deserializeAs(RecordSpecsModel)
	RecordSpecs: RecordSpecsModel;

	@deserialize
	Properties: string;

	@deserialize
	EntityProperties: string;

	DataMode: DataModes;
    SubjectLifestatusId: number;
    ActionSubjectRecord?: ActionSubjectRecordModel;
    IsNested: boolean;
    NestedLevel: number;
    AliasSuffix: string;

	@deserialize
	IsEnablePathRunner: boolean;
}

export class FieldCollectionModel {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	constructor(id?: number, name?: string){
		this.Id = isNaN(Number(id)) ? null : id;
		this.Name = name;
	}
}

export class TypedScreenModel {
	@deserialize
	FormId: number;

	@deserialize
	FieldCollectionId: number;

	@deserialize
	TableTypeId: number;
}

export class TableType {
	@deserialize
	Id: number;

	@deserialize
    Name: string;

    @deserialize
    TranslatedName: string;

    GetName(): string {
        return this.TranslatedName ? this.TranslatedName : this.Name ? this.Name : '-'
    }

    constructor(id?: number, name?: string, translatedName?: string){
		this.Id = id;
        this.Name = name;
        this.TranslatedName = translatedName;
    }
}

export class FormTypeModel {
	@deserialize
	IsMain: boolean;

	@deserialize
	TypeId: number;

	@deserialize
    TypeName: string;

    @deserialize
    TypeTranslatedName: string;

	@deserialize
    FormId: number;

    GetTypeName(): string {
        return this.TypeTranslatedName ? this.TypeTranslatedName : this.TypeName ? this.TypeName : '-'
    }
}

export class DesignScreenModel {

	constructor() {
		this.FieldCollections = [];
		this.TypedScreens = [];
	}

	@deserialize
	FormId: number;

	@deserializeAs(ScreenModel)
	ExistingScreenViewModel: ScreenModel;

	@deserializeAs(FieldCollectionModel)
	FieldCollections: Array<FieldCollectionModel>;

	@deserializeAs(TypedScreenModel)
	TypedScreens: Array<TypedScreenModel>;

	@deserializeAs(TableType)
	EntityTypes: Array<TableType>;


	@deserializeAs(ControlToolBarModel)
	ControlToolbar: ControlToolBarModel;

	@deserializeAs(LanguageModel)
	Languages: Array<LanguageModel>;

	@deserializeAs(FormTypeModel)
	ScreenTypes: Array<FormTypeModel>;

	@deserializeAs(IconModel)
	Icons: Array<IconModel>

	@deserialize
	FormProperties: string;
}