import {P} from "Core/Common/Promise";
import {Request} from "Core/Common/Request";

import {IGetOptionsDto} from "../Models/Dto/IGetOptionsDto";
import {BackLinkOptionsDto} from "../Models/Dto/BackLinkOptionsDto";
import {ISaveSelectionDto} from "../Models/Dto/ISaveSelectionDto";

export class Store {
    static GetBackLinkOptions(dto: IGetOptionsDto): P.Promise<BackLinkOptionsDto> {
        return Request.Send({
            proxy: {
                url: '/api/BackLinkApi/GetBackLinkOptions',
                type: 'POST',
                dataType: 'json'
            },
            params: dto
        });
    }

    static SaveSelection(dto: ISaveSelectionDto) : P.Promise<any> {
        return Request.Send({
            proxy: {
                url: '/api/BackLinkApi/CreateBackLinks',
                type: 'POST'
            },
            params: dto
        });
    }
}