import * as ko from 'knockout';
import {BrowserBarcodeReader, DecodeHintType} from '@zxing/library';

import {Modal} from 'Core/Common/Modal';
import {Event} from 'Core/Common/Event';
import {Notifier} from 'Core/Common/Notifier';
import {MobileChecker} from 'Core/Common/MobileChecker';

import BarcodeScannerTemplate from 'Core/Components/BarcodeScanner/Templates/BarcodeScanner.html';

ko.templates['Core/Components/BarcodeScanner/Templates/BarcodeScanner'] = BarcodeScannerTemplate;

interface BarcodeScannerParams {
    isContinuous?: boolean;
}

export class BarcodeScanner extends Event {
    _devices = ko.observableArray<MediaDeviceInfo>();
    _selectedDeviceId = ko.observable<string>();

    private _modal = new Modal(
        { heightInPercent: MobileChecker.IsMobile() ? 100 : undefined, addClass: 'barcode-scanner-modal-container' },
        true
    );

    private _videoContainer: HTMLDivElement;
    private _videoElement: HTMLVideoElement;
    private _reader = new BrowserBarcodeReader(300, new Map([[DecodeHintType.TRY_HARDER, true]]));
    private _isDisabled = false;
    private _isContinuous?: boolean;

    constructor(params: BarcodeScannerParams = {}) {
        super();

        this._isContinuous = params.isContinuous;
    }

    Show() {
        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
        this._modal.Show();
    }

    GetTemplateName() {
        return 'Core/Components/BarcodeScanner/Templates/BarcodeScanner';
    }

    AfterRender() {
        this._videoElement = this._modal.Wrapper.querySelector<HTMLVideoElement>('video');

        const startDecodingPromise = this.StartDecoding();

        startDecodingPromise.then(async streamStarted => {
            if (!streamStarted) {
                return;
            }

            try {
                const devices = await this._reader.listVideoInputDevices();

                this._devices(devices);
                this._selectedDeviceId(this.GetCurrentDeviceId());
            } catch (error) {
                new Notifier().Failed(error.message);
            }
        });

        this._selectedDeviceId.subscribe(selectedDeviceId => {
            if (selectedDeviceId !== this.GetCurrentDeviceId()) {
                this._reader.reset();

                this.StartDecoding();
            }
        });

        this._modal.On('CLOSE', this, () => {
            startDecodingPromise.then(() => {
                this._reader.reset();

                this.Trigger('CLOSE');
            });
        });
    }

    Reenable() {
        this._isDisabled = false;
    }

    async StartDecoding() {
        const videoCover: any = this._modal.Wrapper.querySelector('.barcode-scanner-modal__video-cover');

        try {
            await this._reader.decodeFromVideoDevice(this._selectedDeviceId(), this._videoElement, result => {
                if (result && !this._isDisabled) {
                    this.Trigger('SUBMIT', { value: result.getText() });
                    this._isDisabled = true;
                    if (this._isContinuous) {
                        videoCover.animate([{opacity: 0}, {opacity: 0.5}, {opacity: 0}], 500);
                    } else {
                        this._modal.Close();
                    }
                    if (navigator.vibrate) {
                        navigator.vibrate(50);
                    }
                }
            });

            return true;
        } catch (error) {
            new Notifier().Failed(!navigator.mediaDevices ? 'Camera can be used only on https' : error.message);
        }
    }

    GetCurrentDeviceId() {
        const videoTracks = this._videoElement.srcObject ? (this._videoElement.srcObject as MediaStream).getVideoTracks() : [];

        if (!videoTracks[0]) {
            return undefined;
        }

        const videoTrackSettings = videoTracks[0].getSettings();

        return videoTrackSettings.deviceId;
    }
}
