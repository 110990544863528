import {PathRunnerResponseModel} from "Core/Components/PathRunner/Models/Response/PathRunnerResponseModel";
import {PathRunnerViewModel} from "Core/Components/PathRunner/Models/View/PathRunnerViewModel";
import {PathRunnerNodeViewModelMapping} from "Core/Components/PathRunner/Mappings/PathRunnerNodeViewModelMapping";

export class PathRunnerViewModelMapping {
	static MapFromResponse(responseModel: PathRunnerResponseModel) {
		let viewModel = new PathRunnerViewModel();

		let roots = responseModel.Roots.map(root => PathRunnerNodeViewModelMapping.MapFromResponse(root));

		viewModel.AddRange(roots);
		return viewModel;
	}
}