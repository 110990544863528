import * as ko from 'knockout';

import {Event} from 'Core/Common/Event';

import {DimensionField} from './DimensionField';

export class Dimension extends Event {
    private _initialized: boolean;
    private _oldSelectedField: DimensionField;

    Fields: KnockoutObservableArray<DimensionField>;
    SelectedField: KnockoutObservable<DimensionField>;

    constructor(fields: DimensionField[], selectedFieldId?: number) {
        super();

        this._initialized = false;

        this.Fields = ko.observableArray(fields);

        this.SelectedField = ko.observable(selectedFieldId ? fields.find(field => field.Id === selectedFieldId) : null);

        this.SelectedField.subscribe(oldSelectedField => this._oldSelectedField = oldSelectedField, undefined, 'beforeChange');
        this.SelectedField.subscribe(() => {
            if (!this._initialized) {
                this._initialized = true;
                return;
            }

            this.Trigger('SelectedField');
        });

        if (this.SelectedField()) {
            this._initialized = true;
        }
    }

    get OldSelectedField() {
        return this._oldSelectedField;
    }
}