import {deserializeAs} from 'libs/cerialize'
import {deserialize} from 'libs/cerialize'

import {ExampleRecordModel} from 'Core/Screens/TypeScreen/Models/ExampleRecordModel'
import {TableTypeModel} from 'Core/Screens/TypeScreen/Models/TableTypeModel'

export class TableTypesModel {
    @deserializeAs(ExampleRecordModel)
    ExampleRecords: Array<ExampleRecordModel>;

    @deserializeAs(TableTypeModel)
    TableTypes: Array<TableTypeModel>;

    @deserialize
    TableHasCustomTypes: boolean;

    @deserialize
    WarningMessage: string;

    @deserialize
    TableId: number;

	get ShowTypeSelector() {
		const enabledTypes = this.TableTypes.filter(type => type.IsEnabled);

		const moreThanOneType = enabledTypes.length > 1;
		const moreThanOneKind = enabledTypes.filter(type => type.Kinds.length > 0).length > 1;

        return moreThanOneType || moreThanOneKind;
    }
}