import {deserialize, deserializeAs, serializeAs} from 'libs/cerialize';
import {TranslationModel} from 'Core/Controls/BaseControl/Models/TranslationModel';

export class ImageFieldModel {
    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    NameTranslated: string;

    @deserializeAs(TranslationModel)
    NameTranslations: Array<TranslationModel>;
}