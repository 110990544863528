import { Guid } from '../../../Common/Guid';
import { deserialize, deserializeAs } from "libs/cerialize";
import {TranslationModel} from "Core/Controls/BaseControl/Models/TranslationModel";
import { FollowupModeDto } from "./FollowupModeDto";
import { NodeTypeDto } from "./NodeTypeDto";
import { TriggerDto } from './TriggerDto';

export class LifecycleStepDto {

    constructor(init?: Partial<LifecycleStepDto>){
        Object.assign(this, init);
    }

    @deserialize
    Guid: string;

    @deserialize
    Id: number;

    @deserialize
    ActionItems: []

    @deserialize
    DateElapsed: string;

    @deserialize
    DeadlineElapsed: string;

    @deserialize
    DictionaryId: number;

    @deserialize
    Enabled: boolean

    @deserialize
    ExampleId: number;

    @deserialize
    FLifestatus: number;

    @deserializeAs(FollowupModeDto)
    FollowupMode: FollowupModeDto;

    @deserialize
    IsAutocomplete: boolean;

    @deserialize
    IsNew: boolean;

    @deserialize
    IsRetired: boolean;

    @deserialize
    Json: string;

    @deserialize
    Label: string;

    @deserialize
    LifeStatusGuid: string;

    @deserialize
    MultipleActions: boolean;

    @deserialize
    NoActionNode: boolean;

    @deserialize
    Locked: boolean;

    @deserialize
    DefaultRetired: boolean;

    @deserialize
    FSubflow: number;

    @deserialize
    FSubflowGuid: string;

    @deserializeAs(NodeTypeDto)
    NodeType: NodeTypeDto;

    @deserialize
    Sort: number;

    @deserialize
    Memo: string;

    @deserialize
    MemoTranslation: string;

    @deserialize
    MemoTranslations: Array<TranslationModel>;

    @deserializeAs(TranslationModel)
    Translations: Array<TranslationModel>;

    @deserializeAs(TriggerDto)
    Trigger: TriggerDto;

    @deserialize
    UserId: number;

    @deserialize
    DisplaySort: number;
}