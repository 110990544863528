import {deserializeAs, deserialize} from 'libs/cerialize';
import {QueryExpressionModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import { TranslationModel } from 'Core/ScreenManager/Models/TranslationModel';
import {TableSecurityUnitModel} from 'QueryBuilder/Models/TableSecurityUnitModel';

export class NewQueryDto {
	constructor() {
		this.Entities = [];
	}

	@deserialize
	Name: string;

	@deserializeAs(TableSecurityUnitModel)
	Entities: Array<TableSecurityUnitModel>;

	@deserializeAs(QueryExpressionModel)
	Query: QueryExpressionModel;

	@deserializeAs(TranslationModel)
	NameTranslations: Array<TranslationModel>;
}