import * as _ from "underscore";

import {Notifier} from 'Core/Common/Notifier';
import {BlockUI} from "Core/Common/BlockUi";

import {TABLE_TYPES} from "Core/Constant";

import {IPropertyDescription, IPropertyOption} from "Core/GeneralProperties/Managers/BaseProperty";
import {BaseSelectProperty} from "Core/GeneralProperties/Managers/BaseSelectProperty/BaseSelectProperty";
import {EntitiesStore} from "Core/Screens/DesignScreen/ControlEditor/Stores/EntitiesStore";

import {EntityModel} from "Core/Screens/DesignScreen/ControlEditor/Models/EntityModel";
import {IControl} from "Core/Controls/IControl";

import SelectLockedLinkEntityPropertyTemplate
    from "Core/GeneralProperties/Managers/SelectLockedLinkEntityProperty/Templates/SelectLockedLinkEntityProperty.html";

ko.templates["Core/GeneralProperties/Managers/SelectLockedLinkEntityProperty/Templates/SelectLockedLinkEntityProperty"] = SelectLockedLinkEntityPropertyTemplate;

export class SelectLockedLinkEntityProperty extends BaseSelectProperty {
    private _control: IControl;
    private _propertyValue: IPropertyOption;
    private _isValid: KnockoutObservable<boolean>;
    private _el: HTMLElement;

    constructor(property: IPropertyDescription, propertyValue: IPropertyOption, control: IControl) {
        super(property, propertyValue);

        this._control = control;
        this._propertyValue = propertyValue;
        this._isValid = ko.observable(true);
    }

    IsValid(): boolean {
        return this._isValid();
    }

    ErrorMessage(): string {
        return 'Wait for the control\'s data load';
    }

    GetTemplateName(): string {
        return "Core/GeneralProperties/Managers/SelectLockedLinkEntityProperty/Templates/SelectLockedLinkEntityProperty";
    }

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);
        this._el = el[0];

        this._isValid(false);

        BlockUI.Block({Target: this._el});
        this.LoadData()
            .then(entities => {
                this.PopulateData(entities);
            })
            .fail(error => {
                new Notifier().Failed(error.message);
            })
            .always(() => {
                this._isValid(true);
                BlockUI.Unblock(this._el);
            });
    }

    private LoadData()  {
        return EntitiesStore.Get({
            EntityId: this._control.Screen.GetEntityId(),
            TableTypeId: this._control.Screen.GetTypeId()
        });
    }

    private PopulateData(entities: EntityModel[]){
        const subjectEntityId = this._control.Screen.GetEntityId();
        const lockedEntities = _.filter(entities, entity => entity.TypeName === TABLE_TYPES.Entity && entity.EntityId !== subjectEntityId);

        let items: IPropertyOption[] = _.map(lockedEntities,
            (item: EntityModel) => {
                return {Name: item.EntityName, Value: item.EntityId}
            });
        this.Init(items, this._propertyValue);
    }
}