import * as _ from 'underscore';

import { URL } from 'Core/Common/Url';
import { Request, IRequestOptions } from 'Core/Common/Request';
import { P } from 'Core/Common/Promise';
import { GenericDeserialize } from 'libs/cerialize';
import { DropdownListModel } from 'Core/Controls/Dropdown/Models/DropdownListModel';
import {BasketDataModel} from 'Core/Controls/Basket/Models/BasketDataModel';

export interface IGetBasketControlDataRequestModel {
	ControlId: number;
	SubjectEntityId: number;
};

export interface IRemoveFromBasketRequestModel {
	BasketEntityId: number;
	BasketRecordId: number;
	RelatedEntityId: number;
	RelatedRecordId: number;
}

export interface INextStatusRequestModel {
	EntityId: number;
	RecordId: number;
	LifestatusId: number;
}

export class BasketStore {
	static GetData(params: IGetBasketControlDataRequestModel): P.Promise<BasketDataModel> {
		var deferredResult = P.defer<BasketDataModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/BasketApi/GetData',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(this.Deserialize(data));
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static RemoveFromBasket(params: IRemoveFromBasketRequestModel): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/BasketApi/RemoveFromBasket',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(data.ResultObject);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static UpdateStatus(params: INextStatusRequestModel): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/BasketApi/UpdateStatus',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(data.ResultObject);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static Deserialize(jsonObject: any): BasketDataModel {
		var model = GenericDeserialize<BasketDataModel>(jsonObject.ResultObject, BasketDataModel);
		return model;
	}
}