import * as ko from 'knockout';
import 'knockout-selectize';

import {FeatureTypes} from "Core/Common/Enums/FeatureTypes";

import {BaseControl, IControlValue} from "../BaseControl/BaseControl";
import {IControlParam} from "Core/Screens/IScreen";

import {SpimStore} from "./Store/SpimStore";

import {SpimValueModel} from "Core/Controls/Grid/Models/GridDataModel/GridCellValueModel";

import {SpimValueViewModel} from "./Model/SpimValueViewModel";
import {NumericValueViewModel} from "./Model/NumericValueViewModel";
import {RangeValueViewModel} from "./Model/RangeValueViewModel";
import {LogicValueViewModel} from "./Model/LogicValueViewModel";
import {AlphaNumericValueViewModel} from "./Model/AlphaNumericValueViewModel";

import EditTemplate from './Templates/Edit.html';
import {Notifier} from "../../Common/Notifier";

ko.templates['Core/Controls/Spim/Templates/Edit'] = EditTemplate;

export class Spim extends BaseControl {
    private _store: SpimStore;

    private _originalValue: SpimValueModel;
    private _viewModel: KnockoutObservable<SpimValueViewModel>;

    constructor(params: IControlParam) {
        super(params);

        this._store = new SpimStore();
        this._viewModel = ko.observable(null);
    }

    ApplyProperties(){}

    SetValue(value: IControlValue): void {
        this._originalValue = value.Data.Value as SpimValueModel;
        this.LoadData();
    }

    GetValue(): SpimValueModel {
        return this._viewModel() && this._viewModel().GetValue() || null;
    }

    Deserialize() {
        return this.GetValue();
    }

    IsModified(): boolean {
        return _.isEqual(this._originalValue, this.GetValue());
    }

    private LoadData() {
        switch (this._originalValue.FeatureType) {
            case FeatureTypes.Numeric:
                this._viewModel(new NumericValueViewModel(this._originalValue.RecordId, this._originalValue.NumericValue));
                break;

            case FeatureTypes.Range:
                this._viewModel(new RangeValueViewModel(this._originalValue.RecordId, this._originalValue.RangeLow, this._originalValue.RangeHigh));
                break;

            case FeatureTypes.Logic:
                this._viewModel(new LogicValueViewModel(this._originalValue.RecordId, this._originalValue.LogicalValue));
                break;

            case FeatureTypes.AlphaNumeric:
                this._store.GetAlphaNumericValues({FieldId: this.FieldModel.Id, RecordId: this._originalValue.RecordId})
                    .then(values => this._viewModel(new AlphaNumericValueViewModel(this._originalValue.RecordId, this._originalValue.AlphaNumericValue, this._originalValue.AlphaNumericDisplayValue, values)))
                    .fail(error => new Notifier().Failed(error.message));

                break;
        }
    }
}
