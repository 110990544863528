import * as _ from 'underscore';

import { deserialize } from 'libs/cerialize';
import {UserTypes} from "User/UserManager";


export class UserModel {

	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	NameTranslation: string;

	@deserialize
	UserName: string;

	@deserialize
	UserType: UserTypes;
	
	@deserialize
	SecurityRolesKey: number;

	@deserialize
	ContactId: number;

	@deserialize
	FileDir: string;

	@deserialize
	DbId: number;

	@deserialize
	DbName: string;

	@deserialize
	LicenseName: string;

	@deserialize
	LicenseImage: string;

	@deserialize
	UserImage: string;

	@deserialize
	UserRoles : Array<string>;

	@deserialize
	UserVars: string;

	@deserialize
	IsRemote: boolean;

	@deserialize
	IsLocked: boolean;

	@deserialize
	IsLicenseServer: boolean;

	@deserialize
	IsShowAIAssistant: boolean;
	HasAnyType(userTypes: UserTypes[]): boolean {
		return _.any(userTypes, userType => userType === this.UserType);
	}
}