import { deserialize } from 'libs/cerialize';

export class SyntaxErrorModel {
	constructor() {}

	@deserialize
	Error: string;

	@deserialize
	Column: number;

	@deserialize
	Line: number;
}