import {CurrentLifeStatusDto} from "../Models/Dto/CurrentLifeStatusDto";

import {CurrentLifeStatusViewModel} from "../Models/View/CurrentLifeStatusViewModel";

export class CurrentLifeStatusMappingProfile {
    static MapToViewModel(dto: CurrentLifeStatusDto): CurrentLifeStatusViewModel {
        const viewModel = new CurrentLifeStatusViewModel();

        viewModel.Id = dto.Id;
        viewModel.Name = dto.Name;
        viewModel.Memo = dto.Memo;
        viewModel.MemoTranslation = dto.MemoTranslation;
        viewModel.LifeStatusTranslatedName = dto.LifeStatusTranslatedName;
        viewModel.NoActionNode = dto.LifestatusNoActionNode;
        viewModel.IsFollowUpDisabled = dto.IsFollowUpDisabled;
        viewModel.DisabledReason = dto.DisabledReason

        return viewModel;
    }
}