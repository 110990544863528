import * as ko from "knockout";
import {BaseProperty, IPropertyDescription} from "Core/GeneralProperties/Managers/BaseProperty";

import InputNumberPropertyTemplate from "Core/GeneralProperties/Managers/InputNumberProperty/Templates/InputNumberProperty.html";

ko.templates["Core/GeneralProperties/Managers/InputNumberProperty/Templates/InputNumberProperty"] = InputNumberPropertyTemplate;

export class InputNumberProperty extends BaseProperty {
    constructor(property: IPropertyDescription, propertyValue: number) {
        super(property);
		this.Value = ko.observable(propertyValue || this.GetDefaultValue());
    }

    GetTemplateName(): string {
        return "Core/GeneralProperties/Managers/InputNumberProperty/Templates/InputNumberProperty";
    }

    SetValue(propertyValue: number): void {
        if (propertyValue) {
            this.Value(propertyValue);
        }
    }

    GetDefaultValue() {
        return 0;
    }
}