import * as ko from 'knockout';

import { IFieldSecuritySettings } from "Core/Controls/FieldSecurity/Shared/Interfaces/IFieldSecuritySettings";
import { FieldSecurityComponent } from "Core/Controls/FieldSecurity/Components/Base/FieldSecurityComponent";
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";

import ViewTemplate from 'Core/Controls/FieldSecurity/Components/Header/View.html';
import EditTemplate from 'Core/Controls/FieldSecurity/Components/Header/Edit.html';

ko.templates['Core/Controls/FieldSecurity/Components/Header/View'] = ViewTemplate;
ko.templates['Core/Controls/FieldSecurity/Components/Header/Edit'] = EditTemplate;

export class HeaderComponent extends FieldSecurityComponent {
	private _labels = LABELS;
	constructor(settings: IFieldSecuritySettings) {
		super(settings);

		this.Templates = { View: ViewTemplate, Edit: EditTemplate, Design: ViewTemplate };
	}
}