import * as ko from 'knockout'
import * as _ from 'underscore';

import { BaseControl, IControlValue } from 'Core/Controls/BaseControl/BaseControl'
import { IControlParam } from 'Core/Screens/IScreen'
import { MapsStore  } from './Stores/MapsStore';

import ViewTemplate from 'Core/Controls/Maps/Templates/View.html'
import DesignTemplate from 'Core/Controls/Maps/Templates/Design.html'
import ToolbarTemplate from 'Core/Controls/Maps/Templates/ToolBar.html'
import HelpViewTemplate from 'Core/Controls/Maps/Templates/HelpView.html'
import { GlobalManager, GLOBALS } from 'Core/GlobalManager/GlobalManager';
import { NOTIFICATIONS } from '../Grid/BaseGrid/Index';

ko.templates['Core/Controls/Maps/Templates/ToolBar'] = ToolbarTemplate;
ko.templates['Core/Controls/Maps/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Maps/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/Maps/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/Maps/Templates/HelpView'] = HelpViewTemplate;

export class Maps extends BaseControl {
	private _query: KnockoutComputed<string>;
	private _keyIsNotValidMessage: KnockoutObservable<string>;
	private _address: KnockoutObservable<string>;

	constructor(params: IControlParam) {
		super(params);

		this._address = ko.observable('');
		this._keyIsNotValidMessage = ko.observable('');
		this._query = ko.computed(()=> `https://www.google.com/maps/embed/v1/place?q=${this._address()}&key=${GlobalManager.Instance.GetGlobal(GLOBALS.GOOGLE_MAPS_API_KEY)}`);

		this.Init();
	}

	private Init(): void {
        this.ApplyProperties();
	}

	private LoadData(){

		MapsStore.CheckGoogleMapsKey(GlobalManager.Instance.GetGlobal(GLOBALS.GOOGLE_MAPS_API_KEY))
		.then((data)=>{
			if(data){
				MapsStore.GetAddress(
					{
						SubjectEntityId: this._form.GetScreen().GetEntityId(),
						SubjectRecordId: this._form.GetScreen().GetRecordId(),
						ControlId : this.GetControlId()
					}).then((data)=> this._address(data));		
			}else{
				this._keyIsNotValidMessage(NOTIFICATIONS.GOOGLE_KEY_IS_NOT_VALID);
			}
		});
	}

	SetValue(value: IControlValue): void {
		this.LoadData();
	}

    ApplyProperties() {
    }

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}
}