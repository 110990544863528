import * as ko from 'knockout'
import * as _ from 'underscore';

import { BlockUI } from "Core/Common/BlockUi";
import { Notifier } from "Core/Common/Notifier";

import { BaseControlEditor, EVENTS as BASE_CONTROL_EDITOR_EVENTS } from "../BaseControlEditor";
import { IControl } from "Core/Controls/IControl";
import {EntitiesStore} from "../../Stores/EntitiesStore";
import {EntityModel} from "../../Models/EntityModel";

import Template from 'Core/Screens/DesignScreen/ControlEditor/Templates/PlannerLevelControlEditor.html';
import {PlannerLevelEntityModel} from "../../Models/PlannerLevelEntityModel";
import {AttachedFieldModel} from "../../../../../Controls/BaseControl/Models/AttachedFieldModel";
import {FIELD_TYPES} from "../../../../../Constant";
import {IconModel} from "../../../../../Controls/BaseControl/Models/IconModel";

ko.templates['Core/Screens/DesignScreen/ControlEditor/Templates/PlannerLevelControlEditor'] = Template;

export class PlannerLevelControlEditor extends BaseControlEditor {

    private _entities: KnockoutObservableArray<EntityModel>;
    private _selectedEntity: KnockoutObservable<EntityModel>;

    private _leftBarSubEntities: KnockoutObservableArray<EntityModel>;
    private _selectedLeftBarSubEntity: KnockoutObservable<EntityModel>;

    private _rightBarSubEntities: KnockoutObservableArray<EntityModel>;
    private _selectedRightBarSubEntity: KnockoutObservable<EntityModel>;

    private _plannerLevelEntities : Array<PlannerLevelEntityModel>;

    constructor(control: IControl) {
        super(control);

        this._leftBarSubEntities = ko.observableArray([]);
        this._selectedLeftBarSubEntity = ko.observable();

        this._entities = ko.observableArray([]);
        this._selectedEntity = ko.observable();

        this._rightBarSubEntities = ko.observableArray([]);
        this._selectedRightBarSubEntity = ko.observable();
        this._plannerLevelEntities = [];

        this._selectedEntity.subscribe((newValue) => {
            this._selectedRightBarSubEntity(null);
            this._selectedLeftBarSubEntity(null);
            if(newValue){
                let planerLevelEntity = _.find(this._plannerLevelEntities, (entity) =>{
                    return entity.Entity.EntityId === newValue.EntityId;
                });
                if(planerLevelEntity){
                    this._leftBarSubEntities(planerLevelEntity.SubEntities);
                    this._rightBarSubEntities(planerLevelEntity.SubEntities);
                }
            }
        });
    }

    Save() {

        const validationError = this.Validate();
        if (validationError) {
            new Notifier().Warning(validationError);
            return;
        }

        const controlEditorModel = this.GetControlEditorModel();
        this.Trigger(BASE_CONTROL_EDITOR_EVENTS.CONTROL_SAVED, { UpdateControlModel: controlEditorModel });

        this.Close();
    }

    GetControlEditorModel() {
        let controlEditorModel = super.GetControlEditorModel();

        let primaryField = _.find(this._selectedEntity().Fields, (field) => {
            return field.FieldTypeName === FIELD_TYPES.PKey;
        });

        let  primaryAttachedField = new AttachedFieldModel({ Id: primaryField.FieldId, Name: primaryField.FieldName, EntityId: this._selectedEntity().EntityId });
        let primaryIconModel = new IconModel();
        primaryIconModel.Name = this._selectedEntity().IconName;
        primaryIconModel.FontName = this._selectedEntity().FontName;
        primaryIconModel.Image = this._selectedEntity().GraphicalIcon;
        primaryIconModel.IsIcon = this._selectedEntity().GraphicalIcon == null;
        primaryIconModel.IsImage = !primaryIconModel.IsIcon;
        primaryAttachedField.EntityIcon = primaryIconModel;
        primaryAttachedField.EntityTypeName = this._selectedEntity().TypeName;
        primaryAttachedField.EntityName = this._selectedEntity().EntityName;
        primaryAttachedField.Sort = 0;

        let primaryLeftField = _.find(this._selectedLeftBarSubEntity().Fields, (field) => {
           return field.FieldTypeName === FIELD_TYPES.PKey;
        });

        let  primaryLeftAttachedField = new AttachedFieldModel({ Id: primaryLeftField.FieldId, Name: primaryLeftField.FieldName, EntityId: this._selectedLeftBarSubEntity().EntityId });
        let leftIconModel = new IconModel();
        leftIconModel.Name = this._selectedLeftBarSubEntity().IconName;
        leftIconModel.FontName = this._selectedLeftBarSubEntity().FontName;
        leftIconModel.Image = this._selectedLeftBarSubEntity().GraphicalIcon;
        leftIconModel.IsIcon = this._selectedLeftBarSubEntity().GraphicalIcon == null;
        leftIconModel.IsImage = !leftIconModel.IsIcon;
        primaryLeftAttachedField.EntityIcon = leftIconModel;
        primaryLeftAttachedField.EntityTypeName = this._selectedLeftBarSubEntity().TypeName;
        primaryLeftAttachedField.EntityName = this._selectedLeftBarSubEntity().EntityFullNameTranslation || this._selectedLeftBarSubEntity().EntityFullName;
        primaryLeftAttachedField.Sort = 10;

        let primaryRightField = _.find(this._selectedRightBarSubEntity().Fields, (field) => {
            return field.FieldTypeName === FIELD_TYPES.PKey;
        });

        let  primaryRightAttachedField = new AttachedFieldModel({ Id: primaryRightField.FieldId, Name: primaryRightField.FieldName, EntityId: this._selectedRightBarSubEntity().EntityId });
        let rightIconModel = new IconModel();
        rightIconModel.Name = this._selectedRightBarSubEntity().IconName;
        rightIconModel.FontName = this._selectedRightBarSubEntity().FontName;
        rightIconModel.Image = this._selectedRightBarSubEntity().GraphicalIcon;
        rightIconModel.IsIcon = this._selectedRightBarSubEntity().GraphicalIcon == null;
        rightIconModel.IsImage = !rightIconModel.IsIcon;
        primaryLeftAttachedField.EntityIcon = rightIconModel;
        primaryRightAttachedField.EntityTypeName = this._selectedRightBarSubEntity().TypeName;
        primaryRightAttachedField.EntityName = this._selectedRightBarSubEntity().EntityFullNameTranslation || this._selectedLeftBarSubEntity().EntityFullName;
        primaryRightAttachedField.Sort = 20;

        controlEditorModel.AttachedFields = [primaryAttachedField, primaryLeftAttachedField, primaryRightAttachedField];

        return controlEditorModel;
    }

    GetSubEntity(entityModel: PlannerLevelEntityModel, fieldId: number){
        return  _.find(entityModel.SubEntities, (sub) => {
            return !!_.find(sub.Fields, (field) => {
                return field.FieldId === fieldId;
            });
        });
    }

    PopulateData(){
        let entities = _.map(this._plannerLevelEntities, (entity) => {
           return entity.Entity;
        });

        this._entities(entities);

        let entityField = this.EditControlModel.Fields[0];

        if(entityField){

            let subjectEntity = _.find(this._plannerLevelEntities, (entity) => {
               return entity.Entity.EntityId == entityField.EntityId;
            });

            if(subjectEntity) {

                this._selectedEntity(subjectEntity.Entity);

                let leftBarField = this.EditControlModel.Fields[1];

                if (leftBarField) {
                    let leftBarSubEntityView = this.GetSubEntity(subjectEntity, leftBarField.Id);
                    this._selectedLeftBarSubEntity(leftBarSubEntityView);
                }

                let rightBarField = this.EditControlModel.Fields[2];

                if (rightBarField) {
                    let rightBarSubEntityView = this.GetSubEntity(subjectEntity, rightBarField.Id);
                    this._selectedRightBarSubEntity(rightBarSubEntityView);
                }
            }
        }
    }

    LoadData() {
        BlockUI.Block();
        EntitiesStore.GetPlanerLevelEntities({
            EntityId: this.GetSubjectEntityId(),
            TableTypeId: this.GetSubjectTableTypeId()
        }).always(()=>{
            BlockUI.Unblock();
        }).then((entities) => {
            this._plannerLevelEntities = entities;
            this.PopulateData();
        });
    }

    Validate() {
        const validationError = super.Validate();
        if (validationError) {
            return validationError;
        }

        if (!this._selectedEntity()) {
            this.IsValid(false);
            return 'Please select entity';
        }

        if (!this._selectedLeftBarSubEntity()) {
            this.IsValid(false);
            return 'Please select left bar sub entity';
        }

        if (!this._selectedRightBarSubEntity()) {
            this.IsValid(false);
            return 'Please select right bar sub entity';
        }

    }

    GetTemplateName(): string {
		return 'Core/Screens/DesignScreen/ControlEditor/Templates/PlannerLevelControlEditor';
    }

    AfterRender(el: HTMLElement) {
        const container = el[0];
        super.AfterRender(container);
        this.LoadData();
    }
}