import * as ko from "knockout";
import { KanbanParentTypeModel } from 'Core/Controls/Kanban/Models/KanbanParentTypeModel';

export class KanbanTypeViewModel {
    constructor(
        public K_Type: number,
        public Name: string,
        public TranslatedName: string,
        public HasFlowFolder: boolean,
        public ShowTypeHierarchy: boolean,
        public ParentTypeModels: Array<KanbanParentTypeModel>
    ) {
        this.IsSelected = ko.observable(false);
    }

    IsSelected: KnockoutObservable<boolean>;

    GetName(): string {
        const typeNameTranslation = this.TranslatedName ? this.TranslatedName : this.Name;
        if (!_.any(this.ParentTypeModels) || !this.ShowTypeHierarchy) {
            return typeNameTranslation;
        }

        const parentTypeModel = _.first(this.ParentTypeModels);
        const parentTypeNameTranslation = parentTypeModel.TranslatedName ? parentTypeModel.TranslatedName : parentTypeModel.Name;

        return `${parentTypeNameTranslation} \\ ${typeNameTranslation}`;
    }
}