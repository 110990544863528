import * as ko from 'knockout';

import { Icon } from "Core/Icon/Icon";
import { DEFAULT_ICONS } from "Core/Constant";

import {BaseControl} from 'Core/Controls/BaseControl/BaseControl';
import {IControlParam, IScreen} from 'Core/Screens/IScreen';
import {DocumentStore, IGetDocumentDataRequestModel} from 'Core/Controls/Document/Stores/DocumentStore';
import {Notifier} from 'Core/Common/Notifier';
import {DocumentManager} from 'Core/Components/Controls/DocumentManager/DocumentManager';
import {IControlValue} from 'Core/Controls/BaseControl/BaseControl';
import {DocumentDataModel} from 'Core/Controls/Document/DocumentDataModel';
import {EVENTS} from 'Core/Components/Controls/DocumentManager/Events';

import EditTemplate from 'Core/Controls/Document/Templates/Edit.html';
import HelpViewTempalte from 'Core/Controls/Document/Templates/HelpView.html';
import ViewTempalte from 'Core/Controls/Document/Templates/View.html';
import ToolBarTemplate from 'Core/Controls/Document/Templates/ToolBar.html';
import DesignTemplate from 'Core/Controls/Document/Templates/Design.html';

ko.templates['Core/Controls/Document/Templates/View'] = ViewTempalte;
ko.templates['Core/Controls/Document/Templates/HelpView'] = HelpViewTempalte;
ko.templates['Core/Controls/Document/Templates/Edit'] = EditTemplate;
ko.templates['Core/Controls/Document/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Document/Templates/Design'] = DesignTemplate;

export class Document extends BaseControl {
	private _documentManager: KnockoutObservable<DocumentManager>;
	private _notifier: Notifier;

	constructor(params: IControlParam) {
		super(params);

		this.Init();
	}

	ApplyProperties(){}

	private Init(): void {
		this.SetDefaultIcon(new Icon(DEFAULT_ICONS.Document));
		this._notifier = new Notifier();
		this._documentManager = ko.observable(null);
	}

	get DocumentManager(): KnockoutObservable<DocumentManager> {
		return this._documentManager;
	}

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}

	SetValue(value: IControlValue): void {
		if (value.SubjectRecordId !== 0) {
			this.LoadData();
		}
	}

	get ScreenType(): string {
		return this._form ? this._form.GetScreen().GetType() : null;
	}

	get Screen(): IScreen {
		return this._form ? this._form.GetScreen() : null;
	}

	LoadData() {
		if (this._form) {
			const subjectEntityId = this._form.GetScreen().GetEntityId();
			const subjectRecordId = this._form.GetScreen().GetRecordId();

			const requestModel: IGetDocumentDataRequestModel = {
				SubjectEntityId: subjectEntityId,
				SubjectRecordId: subjectRecordId,
				FieldId: this.GetFieldId()
			};

			DocumentStore.GetData(requestModel)
				.fail((err) => {
					this._notifier.Failed(err.message);
				})
				.then((data: DocumentDataModel) => {
					const documentManager =
						new DocumentManager(
							this.ScreenType,
							this.Screen,
							data,
							this.GetFieldModel().EntityId,
							this.GetFieldModel().EntityTypeName,
							this.GetFieldModel().Id,
							this.GetFieldModel().Name,
							data && data.IsEnableMail
						);

					this._documentManager(documentManager);
					this._documentManager().On(EVENTS.UPDATED, this, (eventArgs) => this.LoadData());
				});
		}
	}
}