import * as ko from 'knockout';
import * as _ from 'underscore';

import {Notifier} from "Core/Common/Notifier";

import {PropertyControl} from "../PropertyControl";

import {LookupPropertyControlStore} from "./Stores/LookupPropertyControlStore";
import {LookupResponseModel} from "./Models/LookupResponseModel";

import { LookupModel} from "./Models/LookupModel";

import Template from './Template.html';
import {ProductPropertyDescription} from "../../ProductPropertyDescription";
import { ProductPart } from "../../../ProductPart";
import {
    EVENTS as CONFIRMATION_DIALOG_EVENTS, Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

import { ConfigurationPageEvents } from "Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Events/ConfigurationPageEvents";
import { LookupPropertyValue } from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/LookupPropertyValue';
import { SearchScreen } from 'Core/Screens/SearchScreen/SearchScreen';
import { GlobalManager, GLOBALS } from 'Core/GlobalManager/GlobalManager';


export class LookupPropertyControl extends PropertyControl {
    private _store: LookupPropertyControlStore;

    private _selectedOptionValue: number;
    private _subscriptionReady: boolean;
    private _searchScreen: SearchScreen;
    private _useSearch: KnockoutObservable<boolean>;
    private _displayValue: KnockoutComputed<string>;
    private _entityId: number;
    private _relatedTableId: number;
    private _relatedTableFieldId: number;
    private _actionExpressionValue: KnockoutObservable<number>;

    Options: KnockoutObservableArray<LookupModel>;
    SelectedOption: KnockoutObservable<LookupModel>;

    constructor(property: ProductPropertyDescription, productPart: ProductPart, ordersEntityId, productEntityId: number) {
        super(property, productPart);

        this._store = new LookupPropertyControlStore(ordersEntityId, productEntityId);

        this.Options = ko.observableArray([]);
        this.SelectedOption = ko.observable(null);
        this._useSearch = ko.observable(false);
        this._displayValue = ko.computed(() => this.SelectedOption() ? this.SelectedOption().Text: null );

        this._selectedOptionValue = null;

        const searchScreenIfRecordsCount = parseInt(GlobalManager.Instance.GetGlobal(GLOBALS.SEARCH_SCREEN_IF_RECORDS_COUNT));
        this._entityId = property.LookupTableId;
        this._relatedTableFieldId = property.RelatedTableFieldId;
        this._relatedTableId = property.RelatedTableId;

        this._actionExpressionValue = ko.observable(null);

        if (property.AvailableOptionsCount > searchScreenIfRecordsCount) {
            this._useSearch(true);
            this.HasContent(true);
            this.StartListeningValueChanges();
        } else {
            this._useSearch(false);
        }
    }

    OnInit() {
        if (!this.HasContent() && !this._useSearch()) {
            if (!this.readOnly()) {
                this.LoadOptions();
            }
        }

    }

    ClearValue() {
        this.SelectedOption(null);
    }

    GetTemplate() {
        return Template;
    }

    GetValueForPrice(): any {
        return this.SelectedOption() && this.SelectedOption().Value;
    }

    GetValueForSave(): any {
        if (this.SelectedOption() && this.SelectedOption().Value) {
            return this.SelectedOption().Value;
        }
        return null;
    }

    SetValue(propertyValue: LookupPropertyValue) {
        if (propertyValue) {
            this._selectedOptionValue = parseInt(propertyValue.Value);
            if (!_.isEmpty(propertyValue.Value) && (this.readOnly() || this._useSearch())) {
                this.Options().push(new LookupModel(propertyValue.Text, parseInt(propertyValue.Value)))
                this.HasContent(true);
                this.UpdateSelectedOptionReference();
            }
        }
    }

    SetActionExpressionValue(recordId: number) {
        if (!recordId) {
            return;
        }

        this._actionExpressionValue(recordId);
        this.CheckAndUpdateActionExpressionValue();
    }

    private CheckAndUpdateActionExpressionValue() {
        if (!this._actionExpressionValue() || (!this._useSearch() && !_.any(this.Options()))) {
            return;
        }

        const optionId = this._actionExpressionValue();
        this._actionExpressionValue(null);

        if (this._selectedOptionValue && this._selectedOptionValue !== 0) {
            return;
        }

        if (!this._useSearch()) {
            this._selectedOptionValue = optionId;
            return;
        }

        this.DispatchEvent(ConfigurationPageEvents.PropertyContentLoading);
        this._store.GetAvailableOption(this.property.ProductId, this.ValueHolder, optionId)
            .then(optionResponse => {
                if (!optionResponse) {
                    return;
                }

                const lookupOption = new LookupModel(optionResponse.Text, optionResponse.Value);
                this.Options([lookupOption]);
                this._selectedOptionValue = lookupOption.Value;

                this.UpdateSelectedOptionReference();
                this.StartListeningValueChanges();

                this.HasContent(true);
            })
            .fail(err => new Notifier().Failed(err.message))
            .always(() => this.DispatchEvent(ConfigurationPageEvents.PropertyContentLoaded));
    }

    Select(option: LookupModel) {
        if (option == null) {
            this.UnSelect();
        } else {
            this._selectedOptionValue = option.Value;
            this.UpdateSelectedOptionReference();
        }
    }

    UnSelect() {
        this._selectedOptionValue = null;
        this.UpdateSelectedOptionReference();
    }

    private StartListeningValueChanges() {
        if (!this._subscriptionReady) {
            this.SelectedOption.subscribe(() => {
                this._selectedOptionValue = this.SelectedOption() && this.SelectedOption().Value;
                this.CheckValidControl();
                this.OnValueChanged();
            });

            this._subscriptionReady = true;
        }
    }

    ShowSearchScreen() {
        const params = {
            SearchTerm: '',
            EntityId: this._entityId,
            ButtonAdd: false,
            RelatedTableFieldId: this._relatedTableFieldId,
            RelatedTableId: this._relatedTableId,
            RelatedTablePk: this.product.Id

        };

        this._searchScreen = new SearchScreen(params);
        this._searchScreen.On('RECORD_SELECTED', this, (evtArgs) => {
            const tableId = evtArgs.data.TableId;
            const recordId = evtArgs.data.RecordId;
            const fieldNameValue = evtArgs.data.Name;

            this.Options([]);
            this.Options().push(new LookupModel(fieldNameValue, recordId))

            this._selectedOptionValue = parseInt(recordId);
            this.UpdateSelectedOptionReference();
        });

        this._searchScreen.Show();
    }

    private LoadOptions() {
        this.DispatchEvent(ConfigurationPageEvents.PropertyContentLoading);
        this._store.GetAvailableOptions(this.property.ProductId, this.ValueHolder)
            .then(optionsResponse => {
                const options = optionsResponse.map(option => new LookupModel(option.Text, option.Value));

                if (this.readOnly()) {
                    this.Options([options.find(option => option.Value == this._selectedOptionValue)]);
                } else {
                    this.Options(options);
                }

                this.CheckAndUpdateActionExpressionValue();

                this.UpdateSelectedOptionReference();
                this.StartListeningValueChanges();

                this.HasContent(this.Options().length > 0);
            })
            .fail(err => new Notifier().Failed(err.message))
            .always(() => this.DispatchEvent(ConfigurationPageEvents.PropertyContentLoaded));
    }

    private UpdateSelectedOptionReference() {
        const selectedOption = _.find(this.Options(), option => option.Value === this._selectedOptionValue);
        this.SelectedOption(selectedOption);
    }

    IsValid(value?: any): boolean {
        return this.GetIsRequiredValidation(value ? value : this.GetValueForSave());
    }

    CheckValidControl() {
        this.isValidControl(this.GetIsRequiredValidRule(this.GetValueForSave()));
    }
}