export class RecipeRecordModel {
    Id: number;
    Name: string;
    NameTranslation: string;
    TypeId: number;
    TypeName: string;
    TypeNameTranslation: string;
    LifeStatusId: number;
    LifeStatusName: string;
    LifeStatusGroup: number;
    IsLocked: boolean;
    HasChildren: boolean;

    Children: RecipeRecordModel[];
    TypeColor?: string;

    constructor() {
        this.Children = []
        this.TypeColor = null;
    }
}