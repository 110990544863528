export interface IUpdateLifestatusModel {
	EntityId: number;
	RecordId: number;
	RowLifestatus: number;
}
export class UpdateLifestatusModel{
	EntityId: number;
	RecordId: number;
	RowLifestatus: number;

	constructor(model: IUpdateLifestatusModel) {
		this.EntityId = model.EntityId;
		this.RecordId = model.RecordId;
		this.RowLifestatus = model.RowLifestatus;
	}
}