import * as ko from "knockout";
import {BaseProperty, IPropertyDescription} from "Core/GeneralProperties/Managers/BaseProperty";

import CheckboxPropertyTemplate from "Core/GeneralProperties/Managers/CheckboxProperty/Templates/CheckboxProperty.html";

ko.templates["Core/GeneralProperties/Managers/CheckboxProperty/Templates/CheckboxProperty"] = CheckboxPropertyTemplate;

export class CheckboxProperty extends BaseProperty {
    constructor(property: IPropertyDescription, propertyValue: boolean, isEnabled: boolean = true) {
        super(property, isEnabled);
		this.Value = ko.observable(propertyValue || this.GetDefaultValue());
	}

    GetTemplateName(): string {
        return "Core/GeneralProperties/Managers/CheckboxProperty/Templates/CheckboxProperty";
    }

    GetDefaultValue() {
        return false;
    }

    SetValue(propertyValue: boolean): void {
        this.Value(propertyValue);
	}
}