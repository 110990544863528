import { deserialize } from 'libs/cerialize';
import { deserializeAs } from 'libs/cerialize';
import { FunctionDesignerMethodModel } from 'Core/Controls/FunctionDesigner/Models/FunctionDesignerMethodModel';

export class FunctionDesignerPackageModel {
	constructor() {
		this.Methods = [];
	}

	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	TypeId: number;

	@deserialize
	TypeName: string;

	@deserializeAs(FunctionDesignerMethodModel)
	Methods: Array<FunctionDesignerMethodModel>;
}