import {Event} from "Core/Common/Event";
import {EVENTS} from "Core/Constant";

import {IButtonConfig} from "Core/Controls/PortletBar/Interfaces/IButtonConfig";

import {ITooltipConfig} from "Core/KnockoutExtentions/TooltipExtention";
import {ButtonViewModel} from "../../Models/Buttons/ButtonViewModel";

export abstract class PortletBarButton extends Event {
	protected Tooltip: KnockoutObservable<ITooltipConfig>;
    ViewModel: ButtonViewModel;

	constructor(buttonConfig: IButtonConfig) {
		super();
	}

	GetTemplateName() { return `${this.GetButtonTemplateFolder()}/Button`; }

	GetDesignTemplateName() { return `${this.GetButtonTemplateFolder()}/Design`}

	GetPropsTemplateName() { return `${this.GetButtonTemplateFolder()}/Props` }

	AfterRender() { }

	abstract OnClickEvent(): string;
	abstract OnClick(): void;

	protected GetButtonTemplateFolder() {
		return "Core/Controls/PortletBar/Templates/Buttons";
	}
}