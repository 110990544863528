import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';

import {RECORD_SECURITY_WORDS} from 'Core/Constants/RecordSecurityWords';

export class RecordSecurityStore {
    static IsUserAllowedToEdit(tableId: number, recordId: number): P.Promise<any> {
        return this.IsUserAllowedTo(tableId, recordId, RECORD_SECURITY_WORDS.Edit);
    }

    static IsUserAllowedTo(tableId: number, recordId: number, securityWord: number): P.Promise<any> {
        return Request.Send({
            proxy: {
                url: '/api/RecordSecurityApi/IsUserAllowedTo',
                type: 'POST',
                dataType: 'json'
            },
            params: {
                TableId: tableId,
                RecordId: recordId,
                SecurityWord: securityWord
            }
        });
    }
}