import * as _ from 'underscore';

import { DictionaryComparisonStore } from "Pages/DictionaryComparison/DictionaryComparisonStore";
import { BlockUI } from 'Core/Common/BlockUi';

import Template from "Pages/DictionaryComparison/DictionaryComparisonTemplate.html";
ko.templates["Pages/DictionaryComparison/DictionaryComparisonTemplate"] = Template;

export class DictionaryComparisonPage {

    private _isReady: KnockoutObservable<boolean>;
    private _environments: KnockoutObservableArray<string>;
    private _databases: KnockoutObservableArray<string>;
    private _selectedEnvironment: KnockoutObservable<string>;
    private _selectedDatabase: KnockoutObservable<string>;
    private _isEnableToCompare: KnockoutComputed<boolean>;
    private _comparisonSuccessMessage: KnockoutObservable<string>;

    constructor(){
        this._isReady = ko.observable(true);
        this._environments = ko.observableArray([]);
        this._databases = ko.observableArray([]);
        this._selectedEnvironment = ko.observable(null);
        this._selectedDatabase = ko.observable(null);
        this._comparisonSuccessMessage = ko.observable(null);

        this._selectedEnvironment.subscribe((newValue)=>{
            if(newValue){
                BlockUI.Block();
                DictionaryComparisonStore.GetDatabases(newValue)
                .always(()=>BlockUI.Unblock())
                .then(databases=>{                
                    this._databases(databases);                    
                });
            }
        });

        this._isEnableToCompare = ko.computed(()=>{
            return !!this._selectedDatabase() && !!this._selectedEnvironment()
        })

        this.Init();
    }

    CompareDatabases(){
        this._comparisonSuccessMessage(null);
        BlockUI.Block();
        DictionaryComparisonStore.Compare(this._selectedEnvironment(), this._selectedDatabase())
        .then((comparisonName)=>{
            this._comparisonSuccessMessage(comparisonName)
        }).always(()=>BlockUI.Unblock());
    }

    Init(){
        BlockUI.Block();
        DictionaryComparisonStore.GetEnvironments()
        .always(()=>BlockUI.Unblock())
        .then(environments=> {
            this._selectedEnvironment(_.find(environments, (environment)=>environment == window.location.host));
            this._environments(environments)
        });
    }


    Render(target): void {
		ko.cleanNode(document.getElementById(target));
		ko.applyBindings(this, document.getElementById(target));
	}

	GetTemplateName(): string {
		return "Pages/DictionaryComparison/DictionaryComparisonTemplate";
	}

    AfterRender(){}
}