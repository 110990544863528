//Libs
import * as ko from "knockout";
import * as moment from "moment";
import * as _ from "underscore";

//Constants
import {FIELD_TYPES} from "Core/Constant";

//Templates
import WeekTemplate from "Core/Components/Controls/TimeWriting/Templates/WeekTemplate.html";

//Views
import {TimeWritingView} from "Core/Components/Controls/TimeWriting/Views/TimeWritingView";

//Settings
import {IWeekViewSettings} from "Core/Components/Controls/TimeWriting/Views/Week/IWeekViewSettings";

//TextColumn
import TextViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Text/View.html";
import TextEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Text/Edit.html";

import DescriptionViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Description/View.html";
import DescriptionEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Description/Edit.html";

//Integer
import IntegerViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Integer/View.html";
import IntegerEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Integer/Edit.html";

//Decimal
import DecimalViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Decimal/View.html";
import DecimalEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Decimal/Edit.html";

//Subject
import SubjectViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Subject/View.html";
//Dropdown
import DropdownViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Dropdown/View.html";
import DropdownEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Dropdown/Edit.html";

//Multiselect
import MultiselectViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Multiselect/View.html";
import MultiselectEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Multiselect/Edit.html";

//DateTime
import DateTimeViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/DateTime/View.html";
import DateTimeEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/DateTime/Edit.html";

//Time
import TimeViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Time/View.html";
import TimeEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Time/Edit.html";

//YesNo
import YesNoViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/YesNo/View.html";
import YesNoEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/YesNo/Edit.html";

//Dash
import DashViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Dash/View.html";

//Stores
import {TimeWritingStore} from "Core/Components/Controls/TimeWriting/Stores/TimeWritingStore";

//Common
import {P} from "Core/Common/Promise";
import {Notifier} from "Core/Common/Notifier";

//User
import {UserManager} from "User/UserManager";

//Store models
import {TimeWritingReportResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Response/TimeWritingReportResponseModel";

//ViewModels
import {
    AllowedEntity,
    DayDataViewModel, DefaultValue,
    LookupField,
    OptionalFieldData,
    Reservation,
} from "Core/Components/Controls/TimeWriting/Models/View/DayDataViewModel";
import {UsersViewModel, UserViewModel} from "Core/Components/Controls/TimeWriting/Models/View/UsersViewModel";

//Mappings
import {DayMappingProfile} from "Core/Components/Controls/TimeWriting/Mappings/Day/DayMappingProfile";
import {UsersMappingProfile} from "Core/Components/Controls/TimeWriting/Mappings/Day/UsersMappingProfile";

//State
import {State} from "Core/Components/Controls/TimeWriting/Views/Day/State";
import {ViewState} from "Core/Components/Controls/TimeWriting/Views/Day/ViewState";

//Utils
import {
    CellEditor, DateCellEditor,
    DateTimeCellEditor,
    DecimalCellEditor,
    DescriptionCellEditor,
    DropdownCellEditor,
    IntegerCellEditor,
    MultiSelectCellEditor,
    SubjectCellEditor, TextCellEditor, TimeCellEditor,
    ValidationCellEditor, ValueCellEditor, WeekRowEditor
} from "Core/Components/Controls/TimeWriting/Utils/RowEditor";

import {NOTIFICATIONS, LABELS} from "Core/Components/Translation/Locales";
import {TranslationManager} from "Core/Components/Translation/TranslationManager";
import {UsersResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Response/UsersResponseModel";
import {AttachedFieldModel} from "Core/Controls/BaseControl/Models/AttachedFieldModel";
import {BlockUI} from "Core/Common/BlockUi";
import {WeekMappingProfile} from '../../Mappings/Week/WeekMappingProfile';
import {WeekDataViewModel} from '../../Models/View/WeekDataViewModel';
import {DateTime} from '../../Utils/DateTime';
import {DATE_FORMATS} from '../../../../../Constants/DateTimeFormats';

export class WeekView extends TimeWritingView {
    private _state: KnockoutObservable<State>;
    private _currentDate: Date;
    private _isPastWeek: KnockoutComputed<boolean>;
    private _viewModel: KnockoutObservable<WeekDataViewModel>;

    private _viewState: KnockoutObservable<ViewState>;
    private _isReadState: KnockoutComputed<boolean>;
    private _isEditState: KnockoutComputed<boolean>;
    private _isNewState: KnockoutComputed<boolean>;
    private _isFutureDay: KnockoutObservable<boolean>;
    private _isEditable: KnockoutObservable<boolean>;
    private _rowEditors: KnockoutObservableArray<WeekRowEditor>;
    private _compareCurrentDate: Date;

    private _isFromVisible: KnockoutObservable<boolean>;
    private _isDashVisible: KnockoutObservable<boolean>;
    private _isToVisible: KnockoutObservable<boolean>;
    private _isDurationVisible: KnockoutObservable<boolean>;
    private _isHourVisible: KnockoutObservable<boolean>;
    private _isOvwVisible: KnockoutObservable<boolean>;
    private _isPayVisible: KnockoutObservable<boolean>;

    private _initialDateSet = true;

    private _countOfVisibleColumns: number;

    private _groupedRows: KnockoutObservable<any>;

    private _labels = LABELS;

    constructor(private _settings: IWeekViewSettings) {
        super();
        this._state = ko.observable(new State({Date: new Date()}));
        this._currentDate = new Date();
        this._isPastWeek = ko.computed(() => DateTime.GetWeekYear(this._state().Date) < DateTime.GetWeekYear(this._currentDate) || DateTime.GetWeekNumber(this._state().Date) < DateTime.GetWeekNumber(this._currentDate), this);
        this._viewModel = ko.observable(null);

        this._isFutureDay = ko.observable(false);
        this._isEditable = ko.observable(false);
        this._viewState = ko.observable(ViewState.Read);
        this._rowEditors = ko.observableArray([]);
        this._compareCurrentDate = this._currentDate;
        this._compareCurrentDate.setHours(0, 0, 0, 0);
        this._groupedRows = ko.observable(null);
        this._isReadState = ko.computed(() => this._viewState() === ViewState.Read, this);
        this._isEditState = ko.computed(() => this._viewState() === ViewState.Edit, this);
        this._isNewState = ko.computed(() => this._viewState() === ViewState.New, this);

        const visibleFields = this._settings.Properties.Groups.find(group => group.Name === "VisibleFields").Properties;
        const dashVisibility = visibleFields.find(field => field.Name === "START").Value && visibleFields.find(field => field.Name === "TO").Value;

        this._countOfVisibleColumns = visibleFields.filter(f => f.Value === true).length + dashVisibility + this._settings.CustomFields.length;

        this._isFromVisible = ko.observable(visibleFields.find(field => field.Name === "START").Value);
        this._isDashVisible = ko.observable(dashVisibility);
        this._isToVisible = ko.observable(visibleFields.find(field => field.Name === "TO").Value);
        this._isDurationVisible = ko.observable(visibleFields.find(field => field.Name === "TIMESPENT").Value);
        this._isHourVisible = ko.observable(visibleFields.find(field => field.Name === "F_HOURKIND").Value);
        this._isOvwVisible = ko.observable(visibleFields.find(field => field.Name === "F_OVERWORKINDICATOR").Value);
        this._isPayVisible = ko.observable(visibleFields.find(field => field.Name === "F_PAYMENTINDICATOR").Value);
    }

    Show({startDate, endDate}: { startDate: Date, endDate: Date }, userId?: number) {
        this._initialDateSet = true;
        this._compareCurrentDate.setHours(0, 0, 0, 0);
        this._state(new State({
            Date: startDate
        }));

        BlockUI.Block();

        const usersList = this._viewModel() && this._viewModel().Users;

        if (usersList && userId) {
            const selectedUser = usersList.Users().find(user => user.Id === userId);
            if (selectedUser) {
                usersList.SelectedUser(selectedUser);
            }
        }

        if (usersList) {
            this.LoadData(startDate, endDate, usersList.SelectedUser().Id)
                .then(model => this.RenderData(model, usersList))
                .fail(error => {
                    this.ShowError(error.message);
                    BlockUI.Unblock();
                });
        } else {
            const loadDataPromise = this.LoadData(startDate, endDate, userId || UserManager.Instance.CurrentUser.Id);
            const loadUsersPromise = this.LoadUsers();

            loadDataPromise.fail(error => this.ShowError(error.message));
            loadUsersPromise.fail(error => this.ShowError(error.message));

            P.when(loadDataPromise, loadUsersPromise)
                .then(result => this.RenderData(result[0], result[1], userId || UserManager.Instance.CurrentUser.Id))
                .fail(() => BlockUI.Unblock());
        }
    }

    OnPrevWeekClick() {
        const startDate = DateTime.GetPrevWeekStartDate(this._state().Date);
        const endDate = moment(startDate).add(7, 'days').toDate()
        this.Show({startDate, endDate});
    }

    OnNextWeekClick() {
        const startDate = DateTime.GetNextWeekStartDate(this._state().Date);
        const endDate = moment(startDate).add(7, 'days').toDate()
        this.Show({startDate, endDate});
    }

    OnYearClick() {
        this.Trigger("YearClicked", {State: this._state(), UserId: this._viewModel().SelectedUser.Id});
    }

    OnWeekClick() {
        const startDate = DateTime.GetWeekStart(this._state().Date);
        const endDate = moment(startDate).add(7, 'days').toDate()
        this.Show({startDate, endDate});
    }

    OnDayClick(date: Date) {
        this.Trigger("DayClicked", {Date: date, UserId: this._viewModel().SelectedUser.Id});
    }

    SelectDate(date: Date) {
        if (!this._initialDateSet) {
            this.Trigger("DayClicked", {Date: date, UserId: this._viewModel().SelectedUser.Id});
        } else {
            this._initialDateSet = false;
        }
        return true;
    }

    UserSelected(user: UserViewModel) {
        const startDate = DateTime.GetWeekStart(this._state().Date);
        const endDate = moment(startDate).add(7, 'days').toDate()
        this.Show({startDate, endDate});
    }

    private RenderData(model: TimeWritingReportResponseModel, users: UsersViewModel, selectedUserId?: number) {
        const viewModel = WeekMappingProfile.OnViewModel(model);

        viewModel.Users = users;
        viewModel.Week = this._state().Week;

        if (selectedUserId) {
            const selectedUser = viewModel.Users.Users().find(user => user.Id === selectedUserId);
            if (selectedUser) {
                viewModel.Users.SelectedUser(selectedUser);
            }
        }

        for (const reservation of viewModel.Reservations) {
            if (!_.any(reservation.OptionalData)) {
                this._settings.CustomFields.forEach(field => reservation.OptionalData.push(new OptionalFieldData({
                    FieldName: field.Name,
                    FieldValue: ko.observable(null),
                    FieldType: field.FieldTypeName,
                    Translations: ko.observableArray([])
                })));
            }
        }
        this._isEditable(model.DayReports[0].Editable);
        this._viewState(ViewState.Read);
        this._rowEditors([]);
        this.InitRowEditors(viewModel);
        this._groupedRows(_.values(_.groupBy(this._rowEditors(), (rowEditor) => rowEditor.Day)));
        this._initialDateSet = true;
        this._viewModel(viewModel);

        if (model.DayReports[0].GlobalSettings.FreezeTime === undefined) {
            new Notifier().Warning(NOTIFICATIONS.FREEZE_TIME_NOT_SPECIFIED);
        }

        BlockUI.Unblock();
    }

    private InitRowEditors(viewModel: WeekDataViewModel) {
        for (let dayDescriptor of viewModel.Days) {
            dayDescriptor.Reservations
                .filter(res => res.Accepted)
                .forEach(reservation => this.AddRowEditor(reservation, dayDescriptor.Day, viewModel.LookupFields, viewModel.AllowedEntities, viewModel));
        }
    }

    private AddRowEditor(reservation: Reservation, day: string, lookupFields: LookupField[], allowedEntities: AllowedEntity[], viewModel: WeekDataViewModel) {
        const rowEditor = this.GenerateRowEditor(reservation, day, lookupFields, allowedEntities, viewModel);
        this._rowEditors.push(rowEditor);
    }

    private GenerateRowEditor(reservation: Reservation, day: string, lookupFields: LookupField[], allowedEntities: AllowedEntity[], viewModel: WeekDataViewModel) {
        const customFieldCellEditors = this._settings.CustomFields.map(field => this.GenerateOptionalCellEditor(field, reservation, lookupFields, viewModel));
        const hourKindLookupField = _.find(lookupFields, f => f.Name === "F_HOURKIND");
        const ovwLookupField = _.find(lookupFields, f => f.Name === "F_OVERWORKINDICATOR");
        const payLookupField = _.find(lookupFields, f => f.Name === "F_PAYMENTINDICATOR");

        return new WeekRowEditor(reservation.Guid,
            day,
            lookupFields,
            new SubjectCellEditor({
                Name: "Subject",
                Label: this._labels.SUBJECT,
                Value: reservation.Subject,
                ViewTemplate: SubjectViewCellTemplate,
                EditTemplate: null,
                Editable: true,
                Visible: ko.observable(true),
                OnEntityClick: null,
                OnEntityIconClick: null,
                OnSubjectClick: null,
                AllowedEntities: allowedEntities,
                IsNewState: this._isNewState,
                RequiredRule: {
                    Validate: value => !value.NotSpecified,
                    Message: this._labels.SELECT__RESERVATION_SUBJECT
                },
                ClassName: 'subject'
            }),
            new ValidationCellEditor({
                Name: "Start",
                Label: this._labels.FROM,
                Value: reservation.Start,
                ViewTemplate: TimeViewCellTemplate,
                EditTemplate: TimeEditCellTemplate,
                Editable: true,
                Visible: this._isFromVisible,
                ClassName: 'from'
            }),
            new CellEditor({
                Name: "-",
                Label: "",
                ViewTemplate: DashViewCellTemplate,
                Visible: this._isDashVisible,
                ClassName: 'dash'
            }),
            new ValidationCellEditor({
                Name: "To",
                Label: this._labels.TO,
                Value: reservation.To,
                ViewTemplate: TimeViewCellTemplate,
                EditTemplate: TimeEditCellTemplate,
                Editable: true,
                Visible: this._isToVisible,
                ClassName: 'to'
            }),
            new ValidationCellEditor({
                Name: "TimeSpent",
                Label: this._labels.TIME,
                Value: reservation.TimeSpent,
                ViewTemplate: TimeViewCellTemplate,
                EditTemplate: TimeEditCellTemplate,
                Editable: true,
                Visible: this._isDurationVisible,
                ClassName: 'duration'
            }),
            new DescriptionCellEditor({
                Name: "Description",
                Label: this._labels.DESCRIPTION,
                Value: reservation.Description,
                ViewTemplate: DescriptionViewCellTemplate,
                EditTemplate: DescriptionEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                RequiredRule: {
                    Validate: value => value && value.length > 0,
                    Message: this._labels.SPECIFY_RESERVATION_DESCRIPTION
                },
                MinLengthRule: {
                    Validate: value => value.length >= 3,
                    Message: this._labels.DESCRIPTION_MIN_LENGTH.replace('{minLengthValidation}', '3')
                },
                MaxLenghtRule: {
                    Validate: value => value.length <= 80,
                    Message: this._labels.DESCRIPTION_MAX_LENGTH.replace('{maxLengthValidation}', '80')
                },
                ClassName: 'description'
            }),
            new DropdownCellEditor({
                Name: "Hour",
                Label: this._labels.HOUR,
                Value: reservation.HourKind,
                Options: ko.observableArray(hourKindLookupField.Values),
                RequiresSearch: false,
                ValTableId: hourKindLookupField.ValTableId,
                ValFieldId: hourKindLookupField.ValFieldId,
                ValFieldTypeName: hourKindLookupField.ValFieldTypeName,
                ValFieldFormatName: hourKindLookupField.ValFieldFormatName,
                ValFieldSize: hourKindLookupField.ValFieldSize,
                ViewTemplate: DropdownViewCellTemplate,
                EditTemplate: DropdownEditCellTemplate,
                Editable: true,
                Visible: this._isHourVisible,
                ClassName: 'hour'
            }),
            new DropdownCellEditor({
                Name: "Ovw",
                Label: this._labels.OVW,
                Value: reservation.Ovw,
                Options: ko.observableArray(ovwLookupField.Values),
                RequiresSearch: false,
                ValTableId: ovwLookupField.ValTableId,
                ValFieldId: ovwLookupField.ValFieldId,
                ValFieldTypeName: ovwLookupField.ValFieldTypeName,
                ValFieldFormatName: ovwLookupField.ValFieldFormatName,
                ValFieldSize: ovwLookupField.ValFieldSize,
                ViewTemplate: DropdownViewCellTemplate,
                EditTemplate: DropdownEditCellTemplate,
                Editable: true,
                Visible: this._isOvwVisible,
                ClassName: 'ovw'
            }),
            new DropdownCellEditor({
                Name: "Pay",
                Label: this._labels.PAY,
                Value: reservation.Pay,
                Options: ko.observableArray(payLookupField.Values),
                RequiresSearch: false,
                ValTableId: payLookupField.ValTableId,
                ValFieldId: payLookupField.ValFieldId,
                ValFieldTypeName: payLookupField.ValFieldTypeName,
                ValFieldFormatName: payLookupField.ValFieldFormatName,
                ValFieldSize: payLookupField.ValFieldSize,
                ViewTemplate: DropdownViewCellTemplate,
                EditTemplate: DropdownEditCellTemplate,
                Editable: true,
                Visible: this._isPayVisible,
                ClassName: 'pay'
            }),
            ...customFieldCellEditors,
        );
    }

    private GenerateOptionalCellEditor(field: AttachedFieldModel, reservation: Reservation, lookupFields: LookupField[], viewModel: WeekDataViewModel): CellEditor {
        if (field.FieldTypeName === FIELD_TYPES.Lookup) {
            const dropdownField = _.find(lookupFields, f => f.Name === field.Name);

            return new DropdownCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                Options: ko.observableArray(dropdownField.Values),
                RequiresSearch: dropdownField.RequiresSearch,
                ValTableId: field.ValTableId,
                ValFieldId: field.ValFieldId,
                ValFieldTypeName: field.ValFieldTypeName,
                ValFieldFormatName: field.ValFieldFormatName,
                ValFieldSize: field.ValFieldSize,
                ViewTemplate: DropdownViewCellTemplate,
                EditTemplate: DropdownEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'ovw',
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value && value.FieldValue
                }
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.MultiSelect) {
            const multiSelectField = _.find(lookupFields, f => f.Name === field.Name);

            return new MultiSelectCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                Options: ko.observableArray(multiSelectField.Values),
                RequiresSearch: multiSelectField.RequiresSearch,
                ValTableId: field.ValTableId,
                ValFieldId: field.ValFieldId,
                ValFieldTypeName: field.ValFieldTypeName,
                ValFieldFormatName: field.ValFieldFormatName,
                ValFieldSize: field.ValFieldSize,
                ViewTemplate: MultiselectViewCellTemplate,
                EditTemplate: MultiselectEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'ovw',
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value && value.FieldValue
                }
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.Integer) {
            return new IntegerCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: IntegerViewCellTemplate,
                EditTemplate: IntegerEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: null,
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value !== null && value !== undefined
                }
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.Decimal) {
            return new DecimalCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: DecimalViewCellTemplate,
                EditTemplate: DecimalEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: null,
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value !== null && value !== undefined
                },
                Size: field.Size,
                FormatName: field.FormatName
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.DateTime) {
            return new DateTimeCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: DateTimeViewCellTemplate,
                EditTemplate: DateTimeEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'DateTime',
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value !== null && value !== undefined
                },
                FormatName: field.FormatName
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.Date) {
            return new DateCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: DateTimeViewCellTemplate,
                EditTemplate: DateTimeEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'DateTime',
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value !== null && value !== undefined
                },
                FormatName: field.FormatName
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.Time) {
            return new TimeCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: TimeViewCellTemplate,
                EditTemplate: TimeEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'DateTime',
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value !== null && value !== undefined
                }
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.TimeSpan) {
            return new ValidationCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: TimeViewCellTemplate,
                EditTemplate: TimeEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'DateTime',
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value !== null && value !== undefined
                }
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.YesNo) {
            return new ValueCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: YesNoViewCellTemplate,
                EditTemplate: YesNoEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'description'
            });
        }

        return new TextCellEditor({
            Name: field.Name,
            Label: field.FieldNameTranslation,
            Value: reservation.GetOptionalValue(field.Name),
            ViewTemplate: TextViewCellTemplate,
            EditTemplate: TextEditCellTemplate,
            Editable: true,
            Visible: ko.observable(true),
            ClassName: 'description',
            RequiredRule: {
                Message: `${field.FieldNameTranslation} is required`,
                Validate: value => !field.IsRequired || value !== null && !!value
            },
            Size: field.Size,
            Translations: reservation.GetTranslations(field.Name),
            IsTranslate: _.some(viewModel.TranslatedFields, item => item.Id === field.Id)
        });
    }

    private LoadData(startDate: Date, endDate: Date, userId: number): P.Promise<TimeWritingReportResponseModel> {
        return TimeWritingStore.GetDays({
            ControlId: this._settings.ControlId,
            TimeTableId: this._settings.EntityId,
            SubjectEntityId: this._settings.SubjectEntityId,
            SubjectRecordId: this._settings.SubjectRecordId,
            StartDate: moment(startDate).set({hours: 0, minutes: 0, seconds: 0}).utcOffset(0, true).format(),
            EndDate: moment(endDate).set({hours: 0, minutes: 0, seconds: 0}).utcOffset(0, true).format(),
            UserId: userId,
        }).always(() => BlockUI.Unblock());
    }

    private LoadUsers() {
        return TimeWritingStore.GetUsers(this._settings.EntityId)
            .then(users => users.Users.length === 0
                ? UsersResponseModel.CreateDefault()
                : UsersMappingProfile.OnViewModel(users)
            )
            .fail(error => new Notifier().Failed(error.message));
    }

    private GetCustomFieldNames() {
        return this._settings.CustomFields.map(field => {
            return {Name: field.FieldNameTranslation, Type: field.FieldTypeName}
        });
    }

    GetTemplate() {
        return WeekTemplate;
    }

    private ShowError(message: string) {
        new Notifier().Failed(message);
    }

    private GetLocale() {
        return moment.locale();
    }

    GetShortDateFormat() {
        return DATE_FORMATS.SHORT_DATE['Format'];
    }
}
