import * as _ from 'underscore';
import { iconsArrayFA, iconsArraySI, iconsArraySoc } from 'Core/Controls/CanvasDesigner/Configuration/Icons';
import {
	CanvasItemModel,
	CanvasLinkModel,
	CanvasModel,
	CanvasIconModel,
	ShapeModel,
} from 'Core/Controls/CanvasDesigner/Models/CanvasModel';
import { SourceViewModel } from 'Core/Controls/CanvasDesigner/Models/SourceViewModel';
import { DestinationViewModel } from 'Core/Controls/CanvasDesigner/Models/DestinationViewModel';
import { TableViewModel } from 'Core/Controls/CanvasDesigner/Models/TableViewModel';
import { TriggerViewModel } from 'Core/Controls/CanvasDesigner/Models/TriggerViewModel';
import { JSFunctionViewModel } from 'Core/Controls/CanvasDesigner/Models/JSFunctionViewModel';
import { ParamViewModel } from 'Core/Controls/CanvasDesigner/Models/ParamViewModel';
import { ArrowViewModel } from 'Core/Controls/CanvasDesigner/Models/ArrowViewModel';
import { DecoratedRect } from 'Core/Controls/CanvasDesigner/Models/DecoratedRect';
import { CanvasShapeWidth } from 'Core/Controls/CanvasDesigner/Enums/CanvasShapeWidth';
import { CANVAS_TYPES } from 'Core/Controls/CanvasDesigner/Constants/CanvasTypes';
import {dia as Dia, shapes, dia} from 'libs/rappid/build/rappid';

export class CanvasViewModel {
	Cells: Array<SourceViewModel | TableViewModel | ParamViewModel | ArrowViewModel | DecoratedRect | TriggerViewModel | DestinationViewModel | JSFunctionViewModel>;
	CanvasLinks: Array<CanvasLinkModel>;
	Nodes: Array<CanvasItemModel>;
	tabZonesEmbeds: any;
	tabsArePresent: boolean = false;
	functionsHeight: number;
	combinedExistingHeight: number = 0;
	RecordId: number;
	Shapes: Array<ShapeModel>;
	Source: SourceViewModel;
	Destination: DestinationViewModel;

	constructor(model: CanvasModel, topPosition?: number) {
		this.Cells = [];
		this.CanvasLinks = model.CanvasLinks;
		this.Nodes = [];
		this.functionsHeight = topPosition;
		this.Shapes = model.Shapes;
		this.Init(model);
	}

	Init(model: CanvasModel) {
		model.CanvasItems.map((cell: CanvasItemModel) => {
			switch (cell.TypeName) {
				case CANVAS_TYPES.DW_PACKAGE:
					this.RecordId = cell.Id;
					break;
				case CANVAS_TYPES.SOURCE:
					var source = new SourceViewModel(cell);
					this.Source = source;
					this.Cells.push(source);
					break;
				case CANVAS_TYPES.DESTINATION:
					var destination = new DestinationViewModel(cell);
					this.Destination = destination;
					this.Cells.push(destination);
					break;
				case CANVAS_TYPES.TABLE:
					this.Nodes.push(cell);
					break;
				case CANVAS_TYPES.TRIGGER:
					this.Cells.push(new TriggerViewModel(cell));
					this.Nodes.push(cell);
					break;
				case CANVAS_TYPES.JS_FUNCTION:
					this.Cells.push(new JSFunctionViewModel(cell));
					this.Nodes.push(cell);
					break;
				case CANVAS_TYPES.PARAM:
					this.Cells.push(new ParamViewModel(cell));
					break;
			};
		});

		
		model.CanvasLinks.map((link: CanvasLinkModel) => {
			const parentItem: any = _.findWhere(this.Cells, { id: link.CanvasItem1Id || link.CanvasItem1Guid });
			const childItem: any = _.findWhere(this.Cells, { id: link.CanvasItem2Id || link.CanvasItem2Guid });
			if (!parentItem || !childItem) {
				return;
			}

			const bothSocket = parentItem.type.split('.')[1] === CANVAS_TYPES.PARAM && childItem.type.split('.')[1] === CANVAS_TYPES.PARAM;

			if (!bothSocket) {
					parentItem.SetEmbedds(link);
					childItem.SetParentId(link);
				}
		});
	}
}