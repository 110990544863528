import * as ko from "knockout";
import * as _ from "underscore";

import {IControl} from "Core/Controls/IControl";
import {BaseControlEditor} from "./BaseControlEditor";

import Template from 'Core/Screens/DesignScreen/ControlEditor/Templates/StepPageControlEditor.html';
import { ProcessCardPageModel } from "../Models/ProcessCardPageModel";
import { StepPageStore } from "../Stores/StepPageStore";
import { BlockUI } from "../../../../Common/BlockUi";

ko.templates['Core/Screens/DesignScreen/ControlEditor/Templates/StepPageControlEditor'] = Template;

export class StepPageControlEditor extends BaseControlEditor {
	private _processCardPageScreens: KnockoutObservableArray<ProcessCardPageModel>;
	private _selectedProcessCardScreen: KnockoutObservable<ProcessCardPageModel>;
	private _isLoading: KnockoutObservable<boolean>;
	
	constructor(control: IControl) {		
		super(control);
		this._isLoading = ko.observable(false);
		this._processCardPageScreens = ko.observableArray([]);
		this._selectedProcessCardScreen = ko.observable(null);
		this.LoadData();
	}

	LoadData(){
		this._isLoading(true);
		StepPageStore.GetProcessCardPageScreens()
		.always(()=>{
			this._isLoading(false);
		})
		.then(screens=>{
			this._processCardPageScreens(screens);
			this._selectedProcessCardScreen(_.find(screens, screen=>screen.Id == this.EditControlModel.ScreenId));
			this._selectedProcessCardScreen.subscribe((newValue)=>{
				this.EditControlModel.ScreenId = newValue?.Id;
			});
		});
	}

	GetTemplateName() {
		return 'Core/Screens/DesignScreen/ControlEditor/Templates/StepPageControlEditor';
	}
}