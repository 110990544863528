//Libs
import * as ko from "knockout";

import {UsersViewModel} from "./UsersViewModel";
import {AllowedEntity, LookupField, Reservation} from "./DayDataViewModel";

export class DisapprovedDataViewModel {
    AllowedEntities: AllowedEntity[];
    Users: UsersViewModel;
    BookedCounter: number;
    Comment: string;
    DisapprovedReason: string;
    RequestedDate: DisapprovedRequestedDate;
    LookupFields: LookupField[];

    constructor() {
        this.AllowedEntities = [];
        this.LookupFields = [];
    }

    get SelectedUser() {
        return this.Users.SelectedUser();
    }
}

export class DisapprovedRequestedDate {
    Reservations: KnockoutObservableArray<DisapprovedReservation>;

    constructor() {
        this.Reservations = ko.observableArray([]);
    }
}

export class DisapprovedReservation extends Reservation{
    Comment: string;
    DisapprovedReason: string;
    BookedCounter: number;
    Date: string;
    WeekNumber: number;
}