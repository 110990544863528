import * as ko from "knockout"
import 'jquery';
import "knob";

export class KnobExtention {
	static Init() {
		ko.bindingHandlers.knob = {
			init: (element, valueAccessor, allBindings, viewModel) => {
				let options = valueAccessor();
				let value = Math.round(ko.unwrap(options.value));
				let minCount = options.min || 0;

				$(element).knob({
					min: minCount,
					max: ko.unwrap(options.max),
					fgColor: ko.unwrap(options.fgColor),
					release: newValue => {
						if (newValue < minCount) {
							options.value(Math.round(minCount));
						} else {
							options.value(Math.round(newValue));
						}
					}
				});

				$(element).val(value).trigger('change');
			},
			update: (element, valueAccessor) => {
				let options = valueAccessor();
				let max = ko.unwrap(options.max);
				let fgColor = ko.unwrap(options.fgColor);
				if (max || fgColor) {
					$(element).trigger('configure', { max, fgColor });
				}
			}
		}
	}
}