import * as ko from 'knockout';
import * as _ from 'underscore';

import { ICell } from 'Pages/TransaltionEditorPage/TranslationEditor/ICell';
import { Event } from 'Core/Common/Event';
import { EVENTS } from 'Pages/TransaltionEditorPage/TranslationEditor/Events';

import Template from 'Pages/TransaltionEditorPage/TranslationEditor/Templates/TranslationActionCell.html';

export class TranslationActionCell extends Event implements ICell {
	private _isEditMode: KnockoutObservable<boolean>;

	constructor() {
		super();
		this._isEditMode = ko.observable(false);
		this.AddEvent(EVENTS.EDIT);
		this.AddEvent(EVENTS.SAVE);
		this.AddEvent(EVENTS.CANCEL);
	}

	GetTemplate() {
		return Template;
	}

	AfterRender() {}

	Edit() {
		this._isEditMode(true);
		this.Trigger(EVENTS.EDIT);
	}

	Save() {
		this._isEditMode(false);
		this.Trigger(EVENTS.SAVE);
	}

	Cancel() {
		this._isEditMode(false);
		this.Trigger(EVENTS.CANCEL);
	}

	get IsEditMode(): KnockoutObservable<boolean> {
		return this._isEditMode;
	}
}