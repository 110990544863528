import * as ko from "knockout";
import * as _ from "underscore";

import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';
import {TableStore} from 'Core/Common/Stores/TableStore';
import {RecordStore} from 'Core/Common/Stores/RecordStore';
import {ZIndexManager} from 'Core/Common/ZIndexManager';

import {LABELS} from 'Core/Components/Translation/Locales';

import {
    BaseProperty,
    IPropertyDescription,
    ISource
} from "Core/GeneralProperties/Managers/BaseProperty";

import {GlobalManager, GLOBALS} from 'Core/GlobalManager/GlobalManager';
import {ItemModel} from 'Core/Controls/MultiSelect/Models/MultiSelectListModel';
import {LookupEditorStore} from 'LookupEditor/Store/LookupEditorStore';
import {SearchScreen} from 'Core/Screens/SearchScreen/SearchScreen';
import {JBoxDropDown} from 'Core/Components/JBoxDropdown/DropDown';

import DropDownContentTemplate from 'Core/GeneralProperties/Managers/MultiSelectProperty/Templates/DropDownContent.html';
import MultiSelectPropertyTemplate from "Core/GeneralProperties/Managers/MultiSelectProperty/Templates/MultiSelectProperty.html";

ko.templates["Core/GeneralProperties/Managers/MultiSelectProperty/Templates/MultiSelectProperty"] = MultiSelectPropertyTemplate;

export class MultiSelectProperty extends BaseProperty {
    Items: KnockoutObservableArray<ItemModel>;
    SelectedItems: KnockoutObservableArray<ItemModel>;

    private _showMultiSelectText: KnockoutComputed<boolean>;

    private _separator: string;
    private _el: HTMLElement;
    private _tooltip: JBoxDropDown;

    private _showSearchScreen: KnockoutObservable<boolean>;
    private _searchScreenIfRecordsCount: number;
    private _searchScreen: SearchScreen;

    private _tableId: number;
    private _source: ISource;

    private _labels = LABELS;

    Value: KnockoutObservable<string>;

    constructor(property: IPropertyDescription, propertyValue: string) {
        super(property);

        this._source = property.Source;

        this.Items = ko.observableArray([]);
        this.SelectedItems = ko.observableArray([]);
        this._showMultiSelectText = ko.computed(() => this.SelectedItems().length === 0);

        this._separator = ';';

        this._showSearchScreen = ko.observable(false);
        const searchScreenIfRecordsCount = parseInt(GlobalManager.Instance.GetGlobal(GLOBALS.SEARCH_SCREEN_IF_RECORDS_COUNT));
        this._searchScreenIfRecordsCount = Number.isNaN(searchScreenIfRecordsCount) ? 10 : searchScreenIfRecordsCount;

        this.Value = ko.observable(propertyValue);
    }

    GetTemplateName(): string {
        return 'Core/GeneralProperties/Managers/MultiSelectProperty/Templates/MultiSelectProperty';
    }

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);
        this._el = el[0];

        this.LoadData();
    }

    private LoadData() {
        BlockUI.Block({Target: this._el});
        TableStore.Get({TableName: this._source.TableName})
            .then((tableModel: any) => {
                this._tableId = tableModel.Id;
                RecordStore.GetCount(this._tableId)
                    .then(recordsCount => {
                        const showSearchScreen = recordsCount && Number(recordsCount) > this._searchScreenIfRecordsCount;
                        this._showSearchScreen(showSearchScreen);

                        if (this._showSearchScreen())
                        {
                            this.LoadDataForSearch();
                        } else {
                            this.LoadDataForDropdown();
                        }
                    })
                    .fail(error => {
                        BlockUI.Unblock(this._el);
                        new Notifier().Failed(error.message);
                    });
            })
            .fail(error => {
                BlockUI.Unblock(this._el);
                new Notifier().Failed(error.message);
            });
    }

    private LoadDataForSearch()
    {
        const selectedItemsIdsString = this.Value() ? this.Value().split(';') : [];
        if (!_.any(selectedItemsIdsString))
        {
            BlockUI.Unblock(this._el);
            return;
        }

        const selectedItemsIds = selectedItemsIdsString.map(item => item ? Number(item) : 0);
        this.AddRecordsByIds(selectedItemsIds);
    }

    private LoadNewDataForSearch(selectedItemsIds: number[]) {
        _.each(this.Items(), item => item.IsSelected(_.contains(selectedItemsIds, item.RecordId)));

        const existingItemsIds = _.map(this.Items(), item => item.RecordId);
        const newItems = _.difference(selectedItemsIds, existingItemsIds);

        if (!_.any(newItems)) {
            this.UpdateSelectedItems();
            return;
        }

        BlockUI.Block({Target: this._el});
        this.AddRecordsByIds(newItems);
    }

    private AddRecordsByIds(itemsIds: number[]) {
        LookupEditorStore.GetRecordsByIds(this._tableId, itemsIds)
            .always(() => {
                BlockUI.Unblock(this._el);
            })
            .then((records: any) => {
                const fieldName = this._source.DisplayValue;

                _.each(records, (record: any) => {
                    const chosenField = _.find(record.Fields, (field: any) => field.FieldName === fieldName);
                    const newItem = new ItemModel();

                    newItem.Label = chosenField.FieldValue;
                    newItem.RecordId = record.RecordId;
                    newItem.IsSelected(true);

                    this.Items.push(newItem);
                });

                this.UpdateSelectedItems();
            }).fail(error => new Notifier().Failed(error.message));
    }

    private LoadDataForDropdown()
    {
        LookupEditorStore.GetRecords(this._tableId)
            .always(() => {
                BlockUI.Unblock(this._el);
            })
            .then((records: any) => {
                const fieldName = this._source.DisplayValue;
                const selectedItemsIds = this.Value() ? this.Value().split(';') : [];

                _.each(records, (record: any) => {
                    const chosenField = _.find(record.Fields, (field: any) => field.FieldName === fieldName);
                    const newItem = new ItemModel();

                    newItem.Label = chosenField.FieldValue;
                    newItem.RecordId = record.RecordId;

                    const isItemSelected = _.contains(selectedItemsIds, String(newItem.RecordId));
                    newItem.IsSelected(isItemSelected);

                    this.Items.push(newItem);
                });

                this.UpdateSelectedItems();
            }).fail(error => new Notifier().Failed(error.message));
    }

    FormatValue(value: string) {
        if (!value) {
            return this._labels.EMPTY_VALUE;
        }

        return value;
    }

    SelectItem(item: ItemModel) {
        item.IsSelected(true);
        this.UpdateSelectedItems();
    }

    RemoveItem(item: ItemModel) {
        item.IsSelected(false);
        this.UpdateSelectedItems();
    }

    UpdateSelectedItems() {
        var selectedItems = [];
        _.each(this.Items(),
            item => {
                if (item.IsSelected()) {
                    selectedItems.push(item);
                }
            });
        this.SelectedItems(selectedItems);

        const itemIds = this.SelectedItems().map(item => item.RecordId);
        this.Value(itemIds.join(";"));
    }

    ShowListSearchScreen(model, evt: MouseEvent) {
        if (this._showSearchScreen()) {
            evt.stopPropagation();
            evt.preventDefault();

            this._searchScreen = new SearchScreen({
                EntityId: this._tableId,
                SearchTerm: '',
                ButtonAdd: false,
                MultiSelectMode: true,
                SelectedRecordIds: _.map(this.SelectedItems(), (item) => item.RecordId)
            });

            this._searchScreen.On('RECORD_SELECTED', this, (evtArgs) => {
                this.LoadNewDataForSearch([evtArgs.data.RecordId]);
            });

            this._searchScreen.On('RECORDS_SELECTED', this, (eventArgs) => {
                this.LoadNewDataForSearch(eventArgs.data.Ids);
            });

            this._searchScreen.Show();
        } else {
            if (this.Items().length > 0) {

                if (!this._tooltip) {
                    const target: any = evt.currentTarget;
                    this._tooltip = new JBoxDropDown({
                        bindComponent: this,
                        target: target,
                        otherOptions: {
                            addClass: "multi-select-dropdown",
                            attach: undefined,
                            pointer: "",
                            isolateScroll: true,
                            content: DropDownContentTemplate as any,
                            position: {
                                x: "right",
                                y: "bottom"
                            },
                            height: 'auto',
                            zIndex: ZIndexManager.Instance.NextValue,
                            maxHeight: 200
                        },
                        bindTarget: target,
                        bindOnCreate: true
                    });
                }

                this._tooltip.Toggle();
            }
        }
    }
}