import * as ko from 'knockout';
import {deserialize, deserializeAs} from 'libs/cerialize';
import {ActionModel} from "Core/Controls/Kanban/Models/StatusModel";
import {FollowUpParentRecordModel} from "Core/Controls/ButtonFollowUp/Models/FollowUpParentRecordModel ";
import {FollowUpBaseRecordModel} from "Core/Controls/ButtonFollowUp/Models/FollowUpBaseRecordModel";

export class FollowUpRecordModel extends FollowUpBaseRecordModel {
    constructor(password?: string) {
        super(password);
    }

    FollowUpParentRecord?: FollowUpParentRecordModel;
}