import {UserManager} from "User/UserManager";

export class UsersResponseModel {
	Users: UserResponseModel[];

	constructor() {
		this.Users = [];
	}

	static CreateDefault() {
		const currentUser = UserManager.Instance.CurrentUser;
		const defaultUser = new UserResponseModel();

		defaultUser.Id = currentUser.Id;
		defaultUser.Name = currentUser.Name;

		const model = new UsersResponseModel();
		model.Users = [defaultUser];

		return model;
	}
}

export class UserResponseModel {
	Id: number;
	Name: string;
}