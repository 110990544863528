import * as ko from "knockout";

import {BaseControlEditor} from 'Core/Screens/DesignScreen/ControlEditor/Editors/BaseControlEditor';
import {IControl} from 'Core/Controls/IControl';
import {Behavior} from 'Core/Controls/ButtonCopy/Constants/Behavior';
import {IconManager} from 'Core/Components/IconManager/IconManager';
import {IconModel} from 'Core/Controls/BaseControl/Models/IconModel';
import {DesignScreen} from 'Core/Screens/DesignScreen/DesignScreen';

export class ButtonCopyControlEditor extends BaseControlEditor {
    private _iconModel = IconModel;
    private _icon: KnockoutObservable<IconModel>;

    constructor(control: IControl) {
        super(control);
        this._icon = ko.observable(control.GetIcon());
    }

    AfterRender(el: HTMLElement) {
        const container = el[0];
        super.AfterRender(container);

        this.CheckProperties();
    }

    private CheckProperties() {
        let behavior = this.GeneralProperties.GetPropertyManager('Behavior');
        let type = this.GeneralProperties.GetPropertyManager('Type');
        let aliasSuffix = this.GeneralProperties.GetPropertyManager('AliasSuffix');

        if (behavior && type && aliasSuffix) {
            let isEnabled = behavior.Value() && behavior.Value().Value === Behavior.CopyToType;

            type.SetEnabled(isEnabled);
            aliasSuffix.SetEnabled(isEnabled);

            if (!isEnabled){
                type.SetValue(null);
                aliasSuffix.Value('');
            }

            behavior.Value.subscribe((val)=>{
                isEnabled = val && val.Value === Behavior.CopyToType;

                type.SetEnabled(isEnabled);
                aliasSuffix.SetEnabled(isEnabled);

                if (!isEnabled){
                    type.SetValue(null);
                    aliasSuffix.Value('');
                }
            });
        }
    }

    GetControlEditorModel() {
        const controlEditorModel = super.GetControlEditorModel();
        controlEditorModel.Icon = this._icon();
        return controlEditorModel;
    }

    OpenIconManager() {
        const screen = this.Control.GetForm().GetScreen() as DesignScreen;
        const icons = screen.DesignModel.Icons;

        let iconManager = new IconManager(icons);
        iconManager.Show();

        iconManager.On('ICON_SELECTED', this, (eventArgs) => {
            this._icon(eventArgs.data.Icon);
        });
    }

    get Icon() {
        return this._icon();
    }
}