import * as _ from "underscore";
import * as ko from "knockout";

import { IForm, IScreen } from "Core/Screens/IScreen";
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';

import Template from 'Core/Controls/Grid/BaseGrid/GridRow/Templates/GridScreenRow.html';
import { BlockUI } from "../Index";
ko.templates['Core/Controls/Grid/BaseGrid/GridRow/Templates/GridScreenRow'] = Template;

export class GridScreenRow{
    private _screen: KnockoutObservable<IScreen>;
    private __screen: IScreen;
    private _el: HTMLElement;
    private _form: IForm;
    private _recordId: number;


    constructor(screen: IScreen, form: IForm, recordId: number){
        this._screen = ko.observable(null);
        this.__screen = screen;
        this._recordId = recordId;
        this._form = form;   
    }

    async LoadScreenData(){
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
        let screen = await screenManager.GetScreenById({ ScreenId: this.__screen.GetScreenId(), RecordId: this._recordId });
        this._screen(screen);
        this.__screen = screen;
    }

    AfterRender(elements: Array<HTMLElement>){
        this._el = _.find(elements, el=>el.nodeType === 1);
        let $el: any = $(this._el);

        if (this._form && this.__screen.GetRecordId() > 0) {
            const screen = this._form.GetScreen();

            if ( screen.GetType() !== ScreenTypes[ScreenTypes.EditScreen] && screen.GetTableTypeFlowFolder()) {
                setTimeout(() => {
                    const gridContainer = $el.closest('.grid-relative-container');

                    const resizeObserver = new ResizeObserver(() => {
                        $el.popover('destroy');

                        $el.popover({
                            content: '<i class="fa fa-ellipsis-v" /><i class="fa fa-ellipsis-v" />',
                            template: `
                                <div class="popover grid-relative-container__flow-folder-popover">
                                    <div class="popover-content" />
                                </div>
                            `,
                            html: true,
                            placement: 'left',
                            trigger: 'manual',
                            animation: false,
                            container: gridContainer,
                            viewport: gridContainer
                        });

                        $el.on('shown.bs.popover', (evt) => {
                            const $grip = $(`#${$el.attr('aria-describedby')}`);
                            $grip.css({top: evt.currentTarget.offsetTop});
                        });

                        $el.on('inserted.bs.popover', () => {
                            const $grip = $(`#${$el.attr('aria-describedby')}`);

                            $grip.data('EntityId', this.__screen.GetEntityId());
                            $grip.data('RecordId', this._recordId);

                            $grip.draggable({
                                helper: () =>
                                    $('<div />')
                                        .append($grip.clone().removeAttr('id')),
                                classes: { 'ui-draggable-dragging': 'flow-folder-dragging-helper' },
                                appendTo: 'body',
                                scrollSensitivity: 75,
                                zIndex: 100000
                            });
                        });

                        $el.popover('show');
                    });

                    resizeObserver.observe(gridContainer[0]);

                    ko.utils.domNodeDisposal.addDisposeCallback($el[0], () => {
                        $el.popover('destroy');

                        resizeObserver.unobserve(gridContainer[0]);
                    });
                }, 1500);
            }
        }
    }

    GetTemplateName(){
        return 'Core/Controls/Grid/BaseGrid/GridRow/Templates/GridScreenRow';
    }
}