import {Request} from 'Core/Common/Request';

export class FinancialControlStore {
	static GetConfigOptions(subjectTypeId: number) {
		return Request.Send({
			proxy: {
				url: '/api/FormDesignerApi/GetInvoiceControlConfigOptions',
				type: 'GET',
				dataType: 'json'
			},
			params: {subjectTypeId: subjectTypeId}
		});
	}
}