import * as ko from 'knockout';
import * as _ from 'underscore';

import {RecordSecurityRights, Rights} from "Core/Common/Enums/RecordSecurityRights";
import { SecurityDataModel } from './SecurityDataModel';
import enumerable from '../../../../Common/Decorators/EnumerableDecorator';

export class SecurityEditorFormModel {
	Owner: KnockoutObservable<User>;
	Users: KnockoutObservableArray<User>;
	SecurityPass: SecurityPassViewModel;

	constructor() {
		this.Users = ko.observableArray([]);
		this.Owner = ko.observable(null);
	}
}

export class User {
	Id: number;
	Name: string;
	SecurityData: SecurityDataModel;
	ActiveRights: KnockoutObservable<Rights>;
	Rights: KnockoutObservableArray<Rights>;

	ActiveOptionalRights: KnockoutObservable<Rights>;
	OptionalRights: KnockoutObservableArray<Rights>;

	Deleted: KnockoutObservable<boolean>;

	constructor() {
		this.Rights = ko.observableArray(RecordSecurityRights.Instance.GetAll());
		this.OptionalRights = ko.observableArray(RecordSecurityRights.Instance.GetAll());

		const defaultRights = _.find<Rights>(this.Rights(), rights => rights.Name === RecordSecurityRights.Instance.NoAccess.Name);
		this.ActiveRights = ko.observable(defaultRights);

		const defaultOptionalRights = _.find<Rights>(this.Rights(), rights => rights.Name === RecordSecurityRights.Instance.NoAccess.Name);
		this.ActiveOptionalRights = ko.observable(defaultOptionalRights);

		this.Deleted = ko.observable(false);
	}

	static Create(id: number, name: string, securityData: SecurityDataModel, rightsValue?: number, optionaRightsValue?: number, deleted?: boolean) {
		const user = new User();

		user.Id = id;
		user.Name = name;
		user.SecurityData = securityData;

		if (!id) {
			user.Name = "Undefined";
		}

		if (deleted) {
            user.Deleted(deleted);
        }

		if (rightsValue || optionaRightsValue) {
			if (rightsValue >= 512) {
				rightsValue -= 512;
			}

			const activeRights = _.find<Rights>(user.Rights(), r => r.Value === rightsValue);
			user.ActiveRights(activeRights);

			const optionalRights = _.find<Rights>(user.OptionalRights(), r => r.Value === optionaRightsValue);
			user.ActiveOptionalRights(optionalRights);
		}

		return user;
	}
}

export class SecurityPassViewModel {
	PassOption: KnockoutObservable<string>;
	PassInheritance: KnockoutObservable<boolean>;
	RefusePass: KnockoutObservable<boolean>;
	PassOwner: KnockoutObservable<boolean>;

	EnablePassOwner: KnockoutObservable<boolean>;
	EnablePassInheritance: KnockoutObservable<boolean>;

	constructor() {
		this.PassOption = ko.observable(null);
		this.PassInheritance = ko.observable(false);
		this.RefusePass = ko.observable(false);
		this.PassOwner = ko.observable(false);

		this.EnablePassOwner = ko.observable(false);
		this.EnablePassInheritance = ko.observable(false);

		this.PassOption.subscribe(() => {
			const passAnyRights = this.PassRights || this.PassOptionalRights;

			this.EnablePassOwner(passAnyRights);
			this.EnablePassInheritance(passAnyRights);

			if (!passAnyRights) {
				this.PassOwner(false);
				this.PassInheritance(false);
			}
		});
	}

	@enumerable get DoNotPassRights() {
		return this.PassOption() === 'do not pass';
	}

	@enumerable get PassRights() {
		return this.PassOption() === 'pass';
	}

	@enumerable get PassOptionalRights() {
		return this.PassOption() === 'pass optional';
	}

	static Create(doNotPass: boolean,
				  passRights: boolean,
				  passOptionalRights: boolean,
				  passInheritance: boolean,
				  refusePass: boolean,
				  passOwner: boolean) {

		const viewModel = new SecurityPassViewModel();

		const passOption = doNotPass && 'do not pass' || passRights && 'pass' || passOptionalRights && 'pass optional';

		viewModel.PassOption(passOption);
		viewModel.PassInheritance(passInheritance);
		viewModel.RefusePass(refusePass);
		viewModel.PassOwner(passOwner);

		return viewModel;
	}
}