import * as ko from "knockout";
import * as _ from "underscore";
import { Event } from 'Core/Common/Event';
import Template from 'QueryBuilder/QueryCondition/ConditionEditors/Text/Templates/Text.html'

export class Text {
	Value: KnockoutObservable<string>;

	constructor() {
		this.Value = ko.observable("");
	}
	
	GetTemplate() {
		return Template;
	}

	AfterRender(el: Array<HTMLElement>) {
	}
}