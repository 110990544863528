export const TRIGGER_PARAMS = {
	SCREEN_ID: 'ScreenId',
	TABLE_TYPE_ID: 'TableTypeId',
	USER_ID: 'UserId',
	RECORD_ID: 'RecordId',
	ENTITY_ID: 'EntityId',
	PREVIOUS_ENTITY_ID: 'PreviousEntityId',
	PREVIOUS_RECORD_ID: 'PreviousRecordId',
	MAIN_RECORD_ID: 'MainRecordId',
	MAIN_ENTITY_ID: 'MainEntityId',
	RELATED_ENTITY_ID: 'RelatedEntityId',
	RELATED_RECORD_ID: 'RelatedRecordId',
	RELATED_RECORD_IDS: 'RelatedRecordIds',
	OLD_KSEQ: 'OldKSeq',
	NEW_KSEQ: 'NewKSeq',
	CONTROL_ID: 'ControlId',
	KSEQ: 'KSeq',
	KSEQS: 'KSeqs'
}