//Models
import { PathRunnerNodeViewModel } from "Core/Components/PathRunner/Models/View/PathRunnerNodeViewModel";

export class PathRunnerViewModel {
	private _nodes: PathRunnerNodeViewModel[];

	CurrentNode: PathRunnerNodeViewModel;
	
	constructor() {
		this._nodes = [];
	}

	Add(node: PathRunnerNodeViewModel) {
		this._nodes.push(node);
	}

	AddRange(nodes: PathRunnerNodeViewModel[]) {
		nodes.forEach(this.Add.bind(this));
	}

	GetNodes() {
		return this._nodes;
	}

	AddEllipsisTooltip(givenNodes, data) {
		givenNodes.forEach(node => {
			if (node.Guid === data.node.original.record.Guid) {
				node.AddEllipsisTooltip(data);
				return;
			}

			if (node._children && node._children.length) {
				this.AddEllipsisTooltip(node._children, data);
			}
		});
	}

	DestroyEllipsisTooltip(givenNodes, data) {
		givenNodes.forEach(node => {
			if (node.Guid === data.node.original.record.Guid) {
				node.DestroyEllipsisTooltip();
				return;
			}

			if (node._children && node._children.length) {
				this.DestroyEllipsisTooltip(node._children, data);
			}
		});
	}

	GetNode(id: string, callback: (matchedNode: PathRunnerNodeViewModel) => void) {
		this._nodes.forEach(node => node.GetNode(id, callback));
	}

	GetNodeByRecordId(id: number, callback: (matchedNode: PathRunnerNodeViewModel) => void) {
		this._nodes.forEach(node => node.GetNodeByRecordId(id, callback));
	}

	SetInitialNode(initialNodeId: number) {
		this._nodes.forEach(node => node.SetInitialNode(initialNodeId));

		if (this.CurrentNode) {
			this.CurrentNode.SetInitialNode(initialNodeId);
		}
	}

	ToJson() {
		return this._nodes.map(node => node.ToJson());
	}
}