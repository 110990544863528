import * as ko from 'knockout';
import * as _ from 'underscore';
import 'jquery';
import 'jqueryBackstretch';
import 'lockr';

import 'knockout-selectize';
import 'hidden_textfield';
import 'dropdown_direction';
import 'scroll_parent';

import {LOCAL_STORAGE} from 'Core/Common/Enums/LocalStorageItems';
import {P} from 'Core/Common/Promise';
import {BlockUI} from "Core/Common/BlockUi";
import {Event} from 'Core/Common/Event';
import {Modal} from 'Core/Common/Modal';
import {AuthStore} from 'Auth/Stores/AuthStore';
import {WebAuthnStore} from 'Auth/Stores/WebAuthnStore';
import {AuthFormModel} from 'Auth/UI/AuthForm/Models/AuthFormModel';
import {Notifier} from 'Core/Common/Notifier';
import {LanguageSelector} from 'Auth/UI/AuthForm/LanguageSelector';
import {CookieManager} from "Core/Common/CookieManager";
import {TranslationManager} from "Core/Components/Translation/TranslationManager";
import {LABELS} from "Core/Components/Translation/Locales";
import { Events } from 'Auth/Enums/Events';

import AuthFormTemplate from 'Auth/UI/AuthForm/Templates/AuthForm.html';
import { BinaryUtils } from '../../../Core/Common/BinaryUtils';
const BOUND_MANAGER = 'desktopBoundManager';

ko.templates['Auth/UI/AuthForm/Templates/AuthForm'] = AuthFormTemplate;

const SLIDES = [
    '/img/Metronic/bg/1.jpg',
    '/img/Metronic/bg/2.jpg',
    '/img/Metronic/bg/3.jpg',
    '/img/Metronic/bg/4.jpg'
];

export class AuthForm extends Event {
    private _loginErrorMessage: KnockoutObservable<string>;
    private _databaseList: KnockoutObservableArray<string>;
    private _languageSelector: KnockoutObservable<LanguageSelector>;
    private _model: KnockoutValidatedObservable<AuthFormModel>;
    private _modal: Modal;
    private _fileDir: string;
    private _isLoginBlocked: KnockoutObservable<boolean>;
    private _enableLanguageList: KnockoutObservable<boolean>;
    private _isUrlDBExist: KnockoutObservable<boolean>;
    private _labels = LABELS;

    constructor() {
        super();
        this._databaseList = ko.observableArray([]);
        this._loginErrorMessage = ko.observable(null);
        this._isLoginBlocked = ko.observable(false);
        this._enableLanguageList = ko.observable(false);
        this._languageSelector = ko.observable(null);
        this._isUrlDBExist = ko.observable(false);
        this.InitValidation();
    }

    InitValidation() {
        ko.validation.init({insertMessages: false, errorElementClass: "is-invalid"}, true);
    }

    IsValid() {
        if (!this._model.isValid()) {
            this._model.errors.showAllMessages();
        }

        return this._model.isValid();
    }

    IsValidForPasswordReset() {
        const errors = ko.validation.group([this._model().UserName, this._model().Database()]);
        errors.showAllMessages();

        return errors().length === 0;
    }

    GetFormData() {
        return this._model();
    }

    InitPasskey(){

        if(this._model().UserName() && this._model().Database()){
            WebAuthnStore.GetAssertionOptions(this._model().UserName(), this._model().Database()).then((makeAssertionOptions: any)=>{
                if(!makeAssertionOptions){
                    return;
                }
                let conditional = false;

                const challenge = makeAssertionOptions.challenge.replace(/-/g, "+").replace(/_/g, "/");
                makeAssertionOptions.challenge = Uint8Array.from(atob(challenge), c => c.charCodeAt(0));
                
                makeAssertionOptions.allowCredentials.forEach(function (listItem) {
                    var fixedId = listItem.id.replace(/\_/g, "/").replace(/\-/g, "+");
                    listItem.id = Uint8Array.from(atob(fixedId), c => c.charCodeAt(0));
                });

                try{
                    navigator.credentials
                    .get({ publicKey: makeAssertionOptions, mediation: conditional ? 'conditional' : 'optional' })
                    .then((assertedCredential) =>{
                        this.MakeAssertion(assertedCredential)
                    });
                }catch(e){
                    Notifier.Failed(e.message);
                }                
            });
        }
    }

    MakeAssertion(assertedCredential: any){
        let authData = new Uint8Array(assertedCredential.response.authenticatorData);
        let clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
        let rawId = new Uint8Array(assertedCredential.rawId);
        let sig = new Uint8Array(assertedCredential.response.signature);
        let userHandle = new Uint8Array(assertedCredential.response.userHandle);
        const data = {
            id: assertedCredential.id,
            rawId: BinaryUtils.CoerceToBase64Url(rawId),
            type: assertedCredential.type,
            extensions: assertedCredential.getClientExtensionResults(),
            response: {
                authenticatorData: BinaryUtils.CoerceToBase64Url(authData),
                clientDataJSON: BinaryUtils.CoerceToBase64Url(clientDataJSON),
                userHandle: userHandle !== null && userHandle.length > 0 ? BinaryUtils.CoerceToBase64Url(userHandle) : null,
                signature: BinaryUtils.CoerceToBase64Url(sig)
            }
        };

        WebAuthnStore.MakeAssertion({ ClientResponse: JSON.stringify(data), UserName: this._model().UserName(), DatabaseName: this._model().Database() })
        .then((signInResult)=>{
            this.Trigger(Events.PasswordLessSignIn, { SignInResult: signInResult });
        });
    }

    ShowError(errorText: string) {
        this._loginErrorMessage(errorText);
    }

    RemoveErrorSummary() {
        this._loginErrorMessage(null);
    }

    SignIn() {
        if (this.IsValid()) {
            this.SaveLastLoginDb();
            this.SaveLastLogin();
            this.Trigger(Events.SignIn, {FileDir: this._fileDir, modalWrapper: this._modal.Wrapper});
        }
    }

    ResetPassword() {
        this.Trigger(Events.ResetPassword);
    }

    GetUrlParams() {
        let vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value): string => {
            return vars[key] = value;
        });
        return vars;
    }

    ShowInModal() {
        CookieManager.RemoveAuthToken();
        const deferred = P.defer();

        this._model = ko.validatedObservable(new AuthFormModel());

        this._model().UserName.subscribe(()=>{
            this.InitPasskey();
        });

        this._languageSelector(null);

        this._model().Database.subscribe((newValue) => {
            this.UpdateUrl();
            this._languageSelector(null);
            if (newValue) {
                AuthStore.GetLanguages(newValue).then((languages) => {
                    const lastLanguage = this.GetLastLanguage(this._model().Database());
                    this._languageSelector(new LanguageSelector());
                    this._languageSelector().SetLanguages(languages, lastLanguage);
                    this._model().Language = this._languageSelector().ActiveLanguage.ShortName;
                    this._languageSelector().On('LANGUAGE_SELECTED', this, (eventArgs) => {
                        if (eventArgs.data) {
                            this._model().Language = eventArgs.data.Language;
                            this.SaveLanguage(this._model().Language);
                            BlockUI.Block();
                            TranslationManager.Instance.LoadTranslationsForDb(this._model().Database(), this._model().Language)
                                .always(()=>BlockUI.Unblock())
                                .then(() => {
                                    this.SaveLastLogin();                                    
                                    this.ApplyBinding();
                                })
                                .fail((message) => {
                                    new Notifier().Failed(message);
                                });
                        }
                    });
                }).fail((message) => {
                    new Notifier().Failed(message);
                });
            }
        });

        AuthStore.GetDatabaseList().then(databases => {
            this._databaseList(databases);

            const lastDb = this.GetLastLoginDb();
            const username = this.GetLastLogin();
            const lastSelectedDatabase = _.find(databases, (db) => db?.toLowerCase() === lastDb?.toLowerCase());
            const databaseFromUrl = _.find(databases, (db) => db?.toLowerCase() === this.GetUrlParams()["db"]?.toLowerCase());

            if (databaseFromUrl) {
                this._model().Database(databaseFromUrl);
                this._isUrlDBExist(true);
            }else if (lastSelectedDatabase) {
                this._model().Database(lastDb);
            } else {
                this._model().Database(this._databaseList()[0]);
                const lastLanguage = this.GetLastLanguage(this._model().Database());

                if (this._model().Database()) {
                    BlockUI.Block();
                    TranslationManager.Instance.LoadTranslationsForDb(this._model().Database(), lastLanguage)
                        .always(()=>BlockUI.Unblock())
                        .then(() => {
                            this.SaveLastLoginDb();
                            this.SaveLastLogin();
                            this.ApplyBinding();
                        })
                        .fail((message) => {
                            new Notifier().Failed(message);
                        });
                }
            }

            if (username) {
                this._model().UserName(username);
            }

            this._model().Database.subscribe((newValue) => {
                if (window[BOUND_MANAGER] && newValue) {
                    window[BOUND_MANAGER].SelectDatabase(newValue);
                }
            });

            this._modal = new Modal({
                    addClass: 'login login-modal jBox-no-padding',
                    width: '400',
                    minHeight: '400',
                    height: '430',
                    fade: 0,
                    animation: null,
                    closeButton: false,
                    createOnInit: true,
                    closeOnEsc: false,
                    blockScroll: true
                },
                false);

            this.ApplyBinding();
            this._modal.Show();

            $(this._modal.Overlay()).css({'background': '#666'});
            $(this._modal.Container()).css({'background': 'none'});
            $(this._modal.Overlay()).backstretch(SLIDES,
                {
                    fade: 1000,
                    duration: 8000
                });

            deferred.resolve(null);

        }).fail(() => deferred.resolve(null));

        return deferred.promise();
    }

    ApplyBinding(){
        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
    }

    UpdateUrl(){
        const newUrl = this._model().Database() ? window.location.origin + '?db=' + this._model().Database() : window.location.origin;
        if(window.location.href != newUrl){
            window.history.pushState({path: newUrl}, '', newUrl);
        }
    }

    SaveLastLoginDb() {
        Lockr.set(LOCAL_STORAGE.LAST_DB, this._model().Database());
    }

    SaveLastLogin() {
        Lockr.set(LOCAL_STORAGE.LAST_LOGIN, this._model().UserName());
    }

    GetLastLoginDb(): string {
        return Lockr.get(LOCAL_STORAGE.LAST_DB);
    }

    GetLastLogin(): string {
        return Lockr.get(LOCAL_STORAGE.LAST_LOGIN);
    }

    SaveLanguage(languageName: string) {
        var language = Lockr.get<any>(LOCAL_STORAGE.LANGUAGE);
        if (language) {
            try {
                language = JSON.parse(language);
            } catch (err) {
                language = {};
            }
        } else {
            language = {};
        }
        language[this._model().Database()] = languageName;
        Lockr.set(LOCAL_STORAGE.LANGUAGE, JSON.stringify(language));
    }

    GetLastLanguage(db: string): string {
        var language = Lockr.get<any>(LOCAL_STORAGE.LANGUAGE)
        if (language) {
            try {
                language = JSON.parse(language);
            } catch (err) {
                language = {};
            }
        } else {
            language = {};
        }
        return language[db];
    }

    Close() {
        if(this._modal){
            $(this._modal.Overlay()).backstretch('destroy');
            this._modal.Close();
        }
        this._loginErrorMessage(null);
    }

    PreventLogin() {
        this._isLoginBlocked(true);
    }

    AllowLogin(modalWrapper?: HTMLElement) {
        this._isLoginBlocked(false);
        BlockUI.Unblock(modalWrapper);
    }

    get FileDir(): string {
        return this._fileDir;
    }

    private GetFileDir(): string {
        var fileDir = _.last(document.URL.split('/'));
        return fileDir;
    }

    private ChangeUrl() {
        const db = this._model().Database();
        const lastLanguage = this.GetLastLanguage(db);
        if (db) {
            BlockUI.Block();
            TranslationManager.Instance.LoadTranslationsForDb(this._model().Database(), lastLanguage)
                .always(()=>BlockUI.Unblock())
                .then(() => {
                    this.SaveLastLoginDb();
                    this.SaveLastLogin();
                    this.UpdateUrl();
                    this.ApplyBinding();
                })
                .fail((message) => {
                    new Notifier().Failed(message);
                });
        } else {
            window.history.pushState({path: window.location.origin}, '', window.location.origin);
        }
    }

    GetTemplateName() {
        return 'Auth/UI/AuthForm/Templates/AuthForm';
    }

    AfterRender() {
    }
}