import * as ko from 'knockout';
import * as _ from 'underscore';
import 'pubsub';


import {Event} from "Core/Common/Event";
import {Modal} from "Core/Common/Modal";
import {Notifier} from "Core/Common/Notifier";
import {BlockUI} from "Core/Common/BlockUi";

import {PUB_SUB_EVENTS} from "MenuManager/PubSubEvents";

import {LABELS} from "Core/Components/Translation/Locales";

import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";

import {SearchScreen} from "Core/Screens/SearchScreen/SearchScreen";

import {BaseGrid} from "Core/Controls/Grid/BaseGrid/BaseGrid";
import {SortModel} from "Core/Controls/Grid/Models/SortModel";

import {EVENTS} from "Core/Controls/Grid/BaseGrid/Events";

import {Toolbar} from "Core/Controls/Grid/Toolbar/Toolbar";

import {ViewModes} from "Core/Controls/Grid/BaseGrid/Enums/ViewModes";

import {EVENTS as PAGINATOR_EVENTS} from "Core/Components/Paginator/Constants";

import {ReadyForProcessingDataDtoModel} from "Core/Components/Dashboards/FinancialDashboard/Models/Dto/Response/ReadyForProcessingDataDtoModel";
import {ISendToFPDtoModel} from "Core/Components/Dashboards/FinancialDashboard/Models/Dto/Request/ISendToFPDtoModel";

import {FinancialDashboardMappingProfile} from "Core/Components/Dashboards/FinancialDashboard/Mappings/FinancialDashboardMappings";

import {FinancialProcessorViewModel} from "Core/Components/Dashboards/FinancialDashboard/Models/View/FinancialProcessorViewModel";

import {IReadyForProcessingDataParams} from "./Params/IReadyForProcessingDataParams";

import Template
    from 'Core/Components/Dashboards/FinancialDashboard/Components/ReadyForProcessingData/Templates/Template.html';

ko.templates['Core/Components/Dashboards/FinancialDashboard/Components/ReadyForProcessingData/Templates/Template'] = Template;

export class ReadyForProcessingData extends Event {
    private _labels = LABELS;

    private _modal: Modal;

    private _selectProcessorFromSearch: KnockoutObservable<boolean>;
    private _financialProcessors: KnockoutObservableArray<FinancialProcessorViewModel>;
    private _financialProcessor: KnockoutObservable<FinancialProcessorViewModel>;

    private _searchPhrase: KnockoutObservable<string>;

    private _grid: BaseGrid;
    private _sort: SortModel[];
    private _paginationToolbar: Toolbar;

    constructor(private _params: IReadyForProcessingDataParams) {
        super();

        this._modal = new Modal();

        this._selectProcessorFromSearch = ko.observable(true);
        this._financialProcessors = ko.observableArray([]);
        this._financialProcessor = ko.observable(null);

        this._searchPhrase = ko.observable(null);

        this._paginationToolbar = this.BuildPaginationToolbar();

        this._grid = new BaseGrid({
            isPrioritiesDisabled: true,
            isQueryResultGrid: true,
            isEnableSelectRecord: ko.observable(true),
            enabledFavorite: false
        });
        this._sort = [];

        this._grid.On(EVENTS.SORT, this, eventArgs => {
            this._sort = eventArgs.data.SortColumns;
            this.LoadGridData(1);
        });

        this._grid.On(EVENTS.OPEN_HYPERLINK, this, eventArgs => {
            const data = _.clone(eventArgs.data);
            data.IsOpenInModal = true;

            PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, data);
        });

        this._financialProcessor = ko.observable(null);

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
    }

    GetTemplateName() {
        return 'Core/Components/Dashboards/FinancialDashboard/Components/ReadyForProcessingData/Templates/Template';
    }

    AfterRender() {

    }

    Show() {
        this.LoadFinancialProcessors();
        this.LoadGridData(1)
            .then(() => this._modal.Show());
    }

    SearchFinancialProcessor() {
        const searchScreen = new SearchScreen({
            SearchTerm: '',
            EntityName: 'SYS_QUEUES',
            ButtonAdd: false,
            CloseAfterSelectingRecord: false
        });

        searchScreen.On('RECORD_SELECTED', this, eventArgs => {
            const selectedRecord = eventArgs.data;

            const desiredQueueType = 'Financial';
            if (selectedRecord.TypeName !== desiredQueueType) {
                new Notifier().Warning(`Please, select a queue of type ${desiredQueueType}`);
                return;
            }

            const selectedFinancialProcessor = new FinancialProcessorViewModel(selectedRecord.RecordId, selectedRecord.Name, selectedRecord.TypeId);
            this._financialProcessor(selectedFinancialProcessor);

            searchScreen.Close();
        });

        searchScreen.Show();
    }

    OnStartProcessing() {
        const selectedRecords = this._grid.GetSelectRecords().map(row => row.RecordId);

        const validationError = this.ValidateProcessor() || this.ValidateSelectedRecords(selectedRecords);
        if (validationError) {
            new Notifier().Warning(validationError);
            return;
        }

        this.SendToFinancialProcessor({
            FinancialProcessorId: this._financialProcessor().Id,
            QueryResultId: this._params.QueryResultId,
            SelectedRecords: selectedRecords
        });
    }

    private BuildPaginationToolbar() {
        const toolbar = new Toolbar(
            false,
            false,
			ko.observable(false),
			ko.observable(false),
            ko.observable(false),
            ko.observable(false),
            ko.observable(false),
            ko.observable(false),
            ko.observable(false),
            ko.observable(false),
            null,
            null,
            ko.observable(false),
            ko.observable(true),
            null,
            false,
            false,
            ko.observable(false),
            ko.observable(false),
            ko.observable(false),
            true,
            false,
            []
        );

        toolbar.Paginator().On(PAGINATOR_EVENTS.CHANGE, this, eventArgs => this.LoadGridData(eventArgs.data.pageNumber));

        return toolbar;
    }

    private LoadFinancialProcessors() {
        const maxDropdownItemsAmount = +GlobalManager.Instance.GetGlobal(GLOBALS.SEARCH_SCREEN_IF_RECORDS_COUNT);
        this._params.Store.GetFinancialProcessors({Amount: maxDropdownItemsAmount, PageNumber: 1})
            .then(pagedResult => {
                const financialProcessors = pagedResult.Items.map(financialProcessor => FinancialDashboardMappingProfile.MapToFinancialProcessor(financialProcessor));
                this._financialProcessors(financialProcessors);
                this._selectProcessorFromSearch(pagedResult.TotalRecords > pagedResult.Items.length);
            });
    }

    private LoadGridData(pageNumber: number) {
        const params = {
            PageNumber: pageNumber,
            Query: this._params.QueryExpression,
            RecordsPerPage: this._params.RecordsPerPage,
            ViewMode: ViewModes.Query,
            RecordId: 0,
            FilterByOwners: false,
            SearchPhrase: this._searchPhrase(),
            Sort: this._sort
        };

        BlockUI.Block();

        const getDataPromise = this._params.Store.GetReadyForProcessingData(params);

        getDataPromise
            .then(data => this.SetGridData(pageNumber, data))
            .fail((error) => new Notifier().Failed(error.message))
            .always(() => BlockUI.Unblock());

        return getDataPromise;
    }

    private SetGridData(pageNumber: number, data: ReadyForProcessingDataDtoModel) {
        this._grid.SetData(FinancialDashboardMappingProfile.MapToReadyForProcessingData(data), true);

        this._paginationToolbar.Paginator().PageNumber = pageNumber;
        this._paginationToolbar.Paginator().RecordsPerPage = this._params.RecordsPerPage;
        this._paginationToolbar.Paginator().TotalRecords = data.TotalRecords;
        this._paginationToolbar.Enable();
    }

    private SendToFinancialProcessor(sendToFPDtoModel: ISendToFPDtoModel) {
        BlockUI.Block();

        this._params.Store.SendToFinancialProcessor(sendToFPDtoModel)
            .then(() => {
                this.Trigger('FINANCIAL_PROCESSING_STARTED');
                this._modal.Close();
            })
            .fail(error => this.Trigger('FINANCIAL_PROCESSING_FAILED', {error: error}))
            .always(() => BlockUI.Unblock());
    }

    private ValidateProcessor() {
        if (!this._financialProcessor()) {
            return 'Please select Financial Processor';
        }
    }

    private ValidateSelectedRecords(selectedRecords: number[]) {
        if (selectedRecords.length === 0) {
            return 'Please select records to process';
        }
    }
}