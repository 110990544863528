import * as ko from "knockout"
import * as $ from 'jquery';

interface IHasScrollBarExtention {
    addClass: string,
    position: string,
    isReady: boolean,
    checkElement: string
}

export class HasScrollBarExtention {
    static Init() {
        ko.bindingHandlers.hasScrollBar = {
            update: (element: HTMLElement, valueAccessor) => {
                const options: IHasScrollBarExtention = valueAccessor();
                const $element = $(element);
                const isReady = ko.unwrap(options.isReady);
                const $checkElement = $element.find(options.checkElement);
                if (isReady) {
                    if (options.position === 'vertical') {
                        if ($checkElement.get(0).scrollHeight > $checkElement.height()) {
                            $element.addClass(options.addClass);
                        }
                    } else if (options.position === 'horizontal') {
                        if ($checkElement.get(0).scrollWidth > $checkElement.width()) {
                            $element.addClass(options.addClass);
                        }
                    }
                }
            }
        }
    }
}