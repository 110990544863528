import clone from 'clone';

import { BaseModel } from "DatabaseDesigner/Models/BaseModel";
import { CollectionModel } from "DatabaseDesigner/Models/CollectionModel";

export function TableFieldModel() {
	this._valTableId = 0;
	this._valTableGuid = '';
	this._valFieldId = 0;
	this._valFieldGuid = '';
	this._search60 = false;
	this._allowEdit = false;
	this._dependsOnId = 0;
	this._dependsOnGuid = '';
	this._filledById = 0;
	this._filledByGuid = '';
	this._dependsOnFieldId = 0;
	this._dependsOnFieldGuid = '';
	this._tableTypeId = 0;
	this._tableTypeGuid = null;
	this._aliasDefinition = '';
	BaseModel.apply(this);
};

TableFieldModel.prototype = Object.create(BaseModel.prototype);
TableFieldModel.prototype.constructor = TableFieldModel;

TableFieldModel.prototype.FromJSONArray = function (tableFields) {
	var tableFieldList = new CollectionModel({ model: TableFieldModel });
	_.each(tableFields, function (tableField) {
		var tableFieldModel = new TableFieldModel();
		tableFieldModel.FromJSON(tableField);
		tableFieldList.Add(tableFieldModel);
	});
	return tableFieldList;
};

TableFieldModel.prototype.toJSON = function () {
	return {
		ValTableId: this._valTableId,
		ValTableGuid: this.ValTableGuid,
		ValFieldId: this._valFieldId,
		ValFieldGuid: this._valFieldGuid,
		Search60: this._search60,
		AllowEdit: this._allowEdit,
		DependsOn: this._dependsOnId,
		FilledById: this._filledById,
		FilledByGuid: this._filledByGuid,
		DependsOnFieldId: this._dependsOnFieldId,
		DependsOnFieldGuid: this._dependsOnFieldGuid,
		TableTypeId: this._tableTypeId,
		TableTypeGuid: this._tableTypeGuid,
		AliasDefinition: this._aliasDefinition && JSON.parse(this._aliasDefinition).length ? this._aliasDefinition : null
	}
};

TableFieldModel.prototype.FromJSON = function (data) {
	this._valTableId = data.ValTableId;
	this._valFieldId = data.ValFieldId;
	this._tableTypeId = data.TableTypeId;
	this._search60 = data.Search60;
	this._allowEdit = data.AllowEdit;
	this._dependsOnId = data.DependsOn;
	this._filledById = data.FilledById;
	this._aliasDefinition = data.AliasDefinition;
	this._dependsOnFieldId = data.DependsOnFieldId;

	if (data.TableTypeId === 0) {
		this._tableTypeGuid = '-';
	}
};

TableFieldModel.prototype.Clone = function () {
	return clone(this);
};

Object.defineProperty(TableFieldModel.prototype, 'ValTableId', {
	enumerable: true,
	get: function () {
		return this._valTableId;
	},
	set: function (val) {
		this._valTableId = val;
	}
});

Object.defineProperty(TableFieldModel.prototype, 'ValTableGuid', {
	enumerable: true,
	get: function () {
		return this._valTableGuid;
	},
	set: function (val) {
		this._valTableGuid = val;
	}
});

Object.defineProperty(TableFieldModel.prototype, 'ValFieldId', {
	enumerable: true,
	get: function () {
		return this._valFieldId;
	},
	set: function (val) {
		this._valFieldId = val;
	}
});

Object.defineProperty(TableFieldModel.prototype, 'ValFieldGuid', {
	enumerable: true,
	get: function () {
		return this._valFieldGuid;
	},
	set: function (val) {
		this._valFieldGuid = val;
	}
});

Object.defineProperty(TableFieldModel.prototype, 'Search60', {
	enumerable: true,
	get: function () {
		return this._search60;
	},
	set: function (val) {
		this._search60 = val;
	}
});

Object.defineProperty(TableFieldModel.prototype, 'AllowEdit', {
	enumerable: true,
	get: function () {
		return this._allowEdit;
	},
	set: function (val) {
		this._allowEdit = val;
	}
});

Object.defineProperty(TableFieldModel.prototype, 'DependsOnId', {
	enumerable: true,
	get: function () {
		return this._dependsOnId;
	},
	set: function (val) {
		this._dependsOnId = val;
	}
});

Object.defineProperty(TableFieldModel.prototype, 'DependsOnGuid', {
	enumerable: true,
	get: function () {
		return this._dependsOnGuid;
	},
	set: function (val) {
		this._dependsOnGuid = val;
	}
});

Object.defineProperty(TableFieldModel.prototype, 'DependsOnFieldId', {
	enumerable: true,
	get: function () {
		return this._dependsOnFieldId;
	},
	set: function (val) {
		this._dependsOnFieldId = val;
	}
});

Object.defineProperty(TableFieldModel.prototype, 'DependsOnFieldGuid', {
	enumerable: true,
	get: function () {
		return this._dependsOnFieldGuid;
	},
	set: function (val) {
		this._dependsOnFieldGuid = val;
	}
});

Object.defineProperty(TableFieldModel.prototype, 'FilledById', {
	enumerable: true,
	get: function () {
		return this._filledById;
	},
	set: function (val) {
		this._filledById = val;
	}
});

Object.defineProperty(TableFieldModel.prototype, 'FilledByGuid', {
	enumerable: true,
	get: function () {
		return this._filledByGuid;
	},
	set: function (val) {
		this._filledByGuid = val;
	}
});

Object.defineProperty(TableFieldModel.prototype, 'TableTypeId', {
	enumerable: true,
	get: function () {
		return this._tableTypeId;
	},
	set: function (val) {
		this._tableTypeId = val;
	}
});

Object.defineProperty(TableFieldModel.prototype, 'TableTypeGuid', {
	enumerable: true,
	get: function () {
		return this._tableTypeGuid;
	},
	set: function (val) {
		this._tableTypeGuid = val;
	}
});

Object.defineProperty(TableFieldModel.prototype, 'AliasDefiniton', {
	enumerable: true,
	get: function () {
		return this._aliasDefinition;
	},
	set: function (val) {
		this._aliasDefinition = val;
	}
});