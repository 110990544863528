import * as _ from 'underscore';

import { URL } from 'Core/Common/Url';
import { Request, IRequestOptions } from 'Core/Common/Request';
import { P } from 'Core/Common/Promise';
import { GenericDeserialize } from 'libs/cerialize';
import { DropdownListModel, FastFilterRangeModel } from 'Core/Controls/Dropdown/Models/DropdownListModel';

export interface ICreateEmlMessageRequestModel {
	FromAddress: string;
	ToAddresses: Array<string>;
}

export class FormatManagerStore {
	static CreateEmlMessage(params: ICreateEmlMessageRequestModel): P.Promise<any> {
		var deferredResult = P.defer<DropdownListModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/ButtonTemplateApi/CreateEmlMessage',
				dataType: "json",
				type: "POST"
			}, params: params
		};

		Request.Send(requestParams)
			.then(result => {
				if (result.IsSuccessfull) {
					deferredResult.resolve(null);
				} else {
					deferredResult.reject({ message: result.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static StartLocalUrl(localUrl: string): P.Promise<any> {
		const deferedResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/ScreenFormApi/StartLocalUrl',
				type: 'POST'
			}, params: { '': localUrl }
		};

		Request.Send(requestParams)
			.then((result) => {
				deferedResult.resolve(null);
			}).fail(data => { 
				let responseJson = JSON.parse(data.message);
				deferedResult.reject({ message: responseJson.ExceptionMessage || responseJson.Message })
			 });

		return deferedResult.promise();
	}
}