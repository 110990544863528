import * as ko from 'knockout';
import * as _ from 'underscore';
import {Icon} from "Core/Icon/Icon";

import {BaseControl} from 'Core/Controls/BaseControl/BaseControl';
import {IControlParam as ControlParam} from 'Core/Screens/IScreen';
import {EntityModel} from 'Core/Screens/DesignScreen/ControlEditor/Models/EntityModel';
import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel';

import DesignTemplate from 'Core/Controls/ComplexControl/Templates/Design.html'
import {RequiredFieldModel} from 'Core/Controls/ComplexControl/Models/RequiredFieldModel';
import { CONTROL_TYPES } from '../../Constant';


export abstract class ComplexControl extends BaseControl {
    static designTemplate = DesignTemplate;
    private _isExpanded: KnockoutObservable<boolean>;
    protected _requiredFields: KnockoutObservableArray<RequiredFieldModel>;
    private _title: KnockoutObservable<string>;

    protected _enabled: KnockoutObservable<boolean>;
    protected _backgroundColor: KnockoutObservable<string>;
    protected _color: KnockoutObservable<string>;
    protected _borderColor: KnockoutObservable<string>;
    protected _border: KnockoutObservable<boolean>;
    protected _style: KnockoutComputed<{}>;
    IsEntitySelected: KnockoutObservable<boolean>;
    protected HideCaption: KnockoutObservable<boolean>;
    protected HideCaptionFromProperty: KnockoutObservable<boolean>;

    constructor(params: ControlParam, config: JSON = null) {
        super(params, config);
        this._model().IsComplexControl = true;
        this._requiredFields = ko.observableArray([]);
        this._isExpanded = ko.observable(false);
        this.IsEntitySelected = ko.observable(false);
        this._title = ko.observable('');
        this._style = ko.computed(() => {
            return null;
        });

        this._enabled = ko.observable(false);
        this._backgroundColor = ko.observable(null);
        this._color = ko.observable(null);
        this._borderColor = ko.observable(null);
        this._border = ko.observable(false);

        this._model.subscribe(() => {
            this.InitRequiredFields();
        });
    }

    protected abstract ApplyProperties();

    InitRequiredFields() {
        _.each(this._requiredFields(), (requiredField, index) => {
            const attachedField = _.find(this._model().Fields, (field) => field.Sort === index * 10);
            if (attachedField) {
                requiredField.AttachedField(attachedField);
            } else {
                requiredField.AttachedField(null);
            }
        });

        this._title(this.Name);
        
        this.InitIcon();

        this.IsEntitySelected(this._model().Fields.length > 0);
    }

    InitIcon(){
        if(this._model().Icon?.Id > 0 && this.GetControlTypeName() === CONTROL_TYPES.ButtonTemplate){
            this._icon(new Icon(this._model().Icon));
            return;
        }

        this._icon(this.GetDefaultIcon());

        var firstField = _.first(this._model().Fields);

        if (firstField) {
            if (firstField.EntityIcon) {
                this._icon(new Icon(firstField.EntityIcon));
            }
        }
    }

    Expand() {
        this._isExpanded(!this._isExpanded());
    }

    get IsExpanded(): KnockoutObservable<boolean> {
        return this._isExpanded;
    }

    get RequiredFields(): KnockoutObservableArray<RequiredFieldModel> {
        return this._requiredFields;
    }

    get Title(): KnockoutObservable<string> {
        return this._title;
    }

    IsDesignValid() {
        return _.filter(this._requiredFields(), field => !(field.AttachedField())).length === 0;
    }

    AutoSelectFieldsFromStruct(tablesStruct: any[]) {
        this._model().Fields = [];

        _.each(tablesStruct, tableStruct => {
            _.each(this._requiredFields(), (requiredField, index) => {
                if (requiredField.EntityType && requiredField.EntityType !== tableStruct.Type) {
                    return;
                }

                const attachedField = _.find(tableStruct.Fields, (field: any) => {
                    return field.Name === requiredField.DefaultName && field.Type === requiredField.Type;
                });

                if (attachedField) {
                    const attachedFieldModel = new AttachedFieldModel();
                    attachedFieldModel.Id = attachedField.Id;
                    attachedFieldModel.Name = attachedField.Name;
                    attachedFieldModel.EntityName = tableStruct.Name;
                    attachedFieldModel.EntityId = tableStruct.Id;
                    attachedFieldModel.Sort = index * 10;
                    attachedFieldModel.FieldTypeName = attachedField.Type;
                    requiredField.AttachedField(attachedFieldModel);
                    this._model().Fields.push(attachedFieldModel);
                }
            });
        });
    }

    AutoSelectFields(entityModels: Array<EntityModel>) {
        this._model().Fields = [];
        _.each(entityModels, (entityModel) => {
            _.each(this._requiredFields(), (requiredField, index) => {
                const tableFits = !requiredField.EntityType || requiredField.EntityType === entityModel.TypeName;
                const attachedField = tableFits && _.find(entityModel.Fields, entityField => {
                    return entityField.FieldName === requiredField.DefaultName && entityField.FieldTypeName === requiredField.Type;
                });

                if (attachedField) {
                    const attachedFieldModel = new AttachedFieldModel();
                    attachedFieldModel.Id = attachedField.FieldId;
                    attachedFieldModel.Name = attachedField.FieldName;
                    attachedFieldModel.EntityName = entityModel.EntityName;
                    attachedFieldModel.EntityId = entityModel.EntityId;
                    attachedFieldModel.EntityTypeName = entityModel.TypeName;
                    attachedFieldModel.Sort = index * 10;
                    attachedFieldModel.FieldTypeName = attachedField.FieldTypeName;
                    requiredField.AttachedField(attachedFieldModel);
                    this._model().Fields.push(attachedFieldModel);
                }
            });
        });

    }
}
