import {TableModel} from "LookupEditor/Store/Models/TableModel";
import {LookupModel} from "LookupEditor/Store/Models/LookupModel";
import {IRequestOptions, Request} from "Core/Common/Request";
import {P} from "Core/Common/Promise";
import {URL} from "Core/Common/Url";

export class TablesStore {
    static GetEntity(entityId: number): P.Promise<any> {
        let deferredResult = P.defer<any>();
        let requestParams: IRequestOptions = {
            proxy: {
                url: URL.LOOKUP_EDITOR.GET_ENTITY,
                type: 'GET',
                dataType: 'json'
            }, params: { id: entityId }
        };

        Request.Send(requestParams)
            .then(data => deferredResult.resolve(data));

        return deferredResult.promise();
    }

    static GetRelatedTables(tableId: number, tableType: string, isRoot: boolean): P.Promise<Array<TableModel>> {
		let deferredResult = P.defer<Array<TableModel>>();

		const url = tableType === 'Entity' || tableType === 'Sub' || tableType === 'Link' || isRoot
		    ? URL.LOOKUP_EDITOR.GET_LOOKUPS
			: URL.LOOKUP_EDITOR.GET_ENTITIES;

        let requestParams: IRequestOptions = {
            proxy: {
				url: url,
                type: 'GET',
                dataType: 'json'
			}, params: { id: tableId }
        };

        Request.Send(requestParams)
            .then(data => deferredResult.resolve(data));

        return deferredResult.promise();
    }

    static GetLookup(lookupId: number): P.Promise<any> {
        var deferredResult = P.defer<LookupModel>();
        var requestParams: IRequestOptions = {
            proxy: {
                url: URL.LOOKUP_EDITOR.GET_LOOKUP,
                dataType: 'json',
                type: 'GET'
            }, params: { id: lookupId }
        };

        Request.Send(requestParams)
            .then(result => deferredResult.resolve(result));

        return deferredResult.promise();
    }
}