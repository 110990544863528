import * as ko from "knockout";
import * as _ from "underscore";

import {IControlValue} from "Core/Controls/BaseControl/BaseControl";
import {TagStore} from "Core/Controls/Tag/Stores/TagStore";
import {IControlParam} from "Core/Screens/IScreen";
import {ComplexControl} from 'Core/Controls/ComplexControl/ComplexControl';
import {RequiredFieldModel} from 'Core/Controls/ComplexControl/Models/RequiredFieldModel';
import {FIELD_TYPES, TABLE_TYPES, RenderModes, CONTROL_TYPES} from 'Core/Constant';
import {TagModel, TagGroupModel} from "Core/Controls/Tag/Models/TagsScreenDataModel";
import {TagListChanges} from "Core/Controls/Tag/Models/TagListChanges";
import {Serialize, Deserialize} from 'libs/cerialize';
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';
import {Grid} from 'Core/Controls/Grid/Grid';
import {P} from "Core/Common/Promise";
import {DEFAULT_ICONS} from 'Core/Constant'
import {Modal} from "Core/Common/Modal";

import {TagGroupViewModel} from 'Core/Controls/Tag/ViewModels/TagGroupViewModel';
import {TagViewModel} from 'Core/Controls/Tag/ViewModels/TagViewModel';
import {IScreen} from 'Core/Screens/IScreen';
import {TagsScreenDataModel, TagListScreenModel} from 'Core/Controls/Tag/Models/TagsScreenDataModel';
import { Tagbook } from 'Core/Controls/Tag/Tagbook/Tagbook';
import { GeneralProperties } from "Core/GeneralProperties/GeneralProperties";

import Config from "Core/Controls/Tag/Configs/tag-config.json";

const ResizeService = new ResizeObserver();


import ListTemplate from "Core/Controls/Tag/Templates/List.html";
import HelpViewTemplate from "Core/Controls/Tag/Templates/HelpView.html";
import ViewTemplate from "Core/Controls/Tag/Templates/View.html";
import ToolBarTemplate from "Core/Controls/Tag/Templates/ToolBar.html";
import EditTemplate from "Core/Controls/Tag/Templates/Edit.html";
import DesignTemplate from "Core/Controls/Tag/Templates/Design.html";
import {Icon} from "Core/Icon/Icon";
import { BlockUI } from "../../Common/BlockUi";
import {ResizeObserver} from "../../Common/ResizeObserver";

ko.templates["Core/Controls/Tag/Templates/ToolBar"] = ToolBarTemplate;
ko.templates["Core/Controls/Tag/Templates/HelpView"] = HelpViewTemplate;
ko.templates["Core/Controls/Tag/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/Tag/Templates/Edit"] = EditTemplate;
ko.templates["Core/Controls/Tag/Templates/Design"] = DesignTemplate;
ko.templates["Core/Controls/Tag/Templates/List"] = ListTemplate;

export class Tag extends ComplexControl {
	private _selectedTags: KnockoutObservableArray<TagModel>;
	private _tags: KnockoutObservableArray<TagModel>;
	private _tagsByGroup: KnockoutObservableArray<TagGroupViewModel>;
	private _isTagsLoaded: KnockoutObservable<boolean>;
	private _isTagsSelected: KnockoutObservable<boolean>;
	private _changeModel: TagListChanges;
	private _labelStyle: KnockoutObservable<any>;
	private _term: KnockoutObservable<string>;
	private _termOperationMessage: KnockoutObservable<string>;
	private _showOptions: KnockoutObservable<boolean>;
	private _tagScope: number;
	private _filterByTags: KnockoutComputed<Array<TagViewModel>>;
	private _isEnableDropGrid: KnockoutObservable<boolean>;
	private _blockTagList: KnockoutObservable<boolean>;
	private _preselectedTags: Array<number>;
	private _tagListScreens: KnockoutObservableArray<TagListScreenModel>;
	private _isReloadGrid: boolean;
	private _isNoTags: KnockoutObservable<boolean>;
	private _inverseGridCondition: boolean;
	private _tagbook: Tagbook;
	private _modal: Modal;
	private _controlId: number;
	private _subControlsLoading: number;
	private _expandFilter: KnockoutObservable<boolean>;
	private _stickyFilter: boolean;

	private readonly _minTermLength: number = 3;

	constructor(params: IControlParam) {
		super(params, Config);

		this._tags = ko.observableArray([]);
		this._tagsByGroup = ko.observableArray([]);
		this._selectedTags = ko.observableArray([]);
		this._term = ko.observable(null);
		this._labelStyle = ko.observable(null);
		this._isTagsLoaded = ko.observable(false);
		this._isTagsSelected = ko.observable(false);
		this._isEnableDropGrid = ko.observable(false);
		this._blockTagList = ko.observable(false);
		this._tagListScreens = ko.observableArray([]);
		this._preselectedTags = [];
		this._isReloadGrid = false;
		this._isNoTags = ko.observable(false);
		this._inverseGridCondition = false;
		this._subControlsLoading = 0;

		this._controlId = this.GetControlId();

		this.SetDefaultIcon(new Icon(DEFAULT_ICONS.Tag));

		this._selectedTags.subscribe(() => {
			this._isTagsSelected(this._selectedTags().length > 0);
		});

		this._termOperationMessage = ko.observable('');
		this._showOptions = ko.observable(false);

		this._filterByTags = ko.computed(() => {
			let selectedTags = [];

			_.each(this._tagsByGroup(), (group) => {
				const tags = _.filter(group.Tags, (tag) => tag.IsSelected());

				selectedTags = selectedTags.concat(tags);
			});

			return selectedTags;
		});
		this._expandFilter = ko.observable(null);
		this._stickyFilter = false;

		this.Init();

		this._model.subscribe(() => {
			if (this.Properties) {
				this.ApplyProperties();
			}
		});

		this.ApplyProperties();
	}

	Init(): void {

		if (this._form) {
			this._tagScope = this._form.GetScreen().GetEntityId();
		}

		this.AddEvent('GRID_LOADED');

		if ((this._renderMode() === RenderModes.View || this._renderMode() === RenderModes.Edit) &&
            (this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.ListScreen]
                || this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.SpecialScreen])) {

			if (this._renderMode() === RenderModes.View || this._renderMode() === RenderModes.Edit) {
				this._renderMode(RenderModes.List);
			}
		}

		if (this._renderMode() === RenderModes.Design &&
			this._form &&
            (this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.ListScreen]
            || this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.SpecialScreen])) {

			this._isEnableDropGrid(true);
		}

		this._renderMode.subscribe((newValue) => {
			if (newValue === RenderModes.Design &&
				this._form &&
                (this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.ListScreen]
                   || this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.SpecialScreen])) {

				this._isEnableDropGrid(true);
			}
		});

		this._requiredFields([
			new RequiredFieldModel('NAME', FIELD_TYPES.Text, TABLE_TYPES.Entity, null),
			new RequiredFieldModel('F_TYPE', FIELD_TYPES.Lookup, TABLE_TYPES.Entity, null),
			new RequiredFieldModel('F_TAGSCOPE', FIELD_TYPES.Lookup, TABLE_TYPES.Entity, null)
		]);

		this.InitRequiredFields();

		if (this._renderMode() === RenderModes.Edit) {
			this._term.subscribe(() => {
				const termValid = this._term() && this._term().length > 0;

				if (!termValid) {
					this._term(null);
				}

				this.LoadTagsBySearchTerm();
				this._showOptions(termValid);
			});

			this._tags.subscribe(() => {
				this._isTagsLoaded(this._tags().length > 0);
			});
		}

		_.each(this._subControls(), (subControl) => {
			if (subControl instanceof Grid) {
				subControl.On('START_DATA_LOAD', this, () => {
					this._subControlsLoading = this._subControlsLoading + 1;
					this._blockTagList(true);
				});
				subControl.On('DATA_LOADED', this, (eventArgs) => {
					this._subControlsLoading = this._subControlsLoading - 1;
					if (this._subControlsLoading === 0) {
						this._blockTagList(false);
					}
					this.Trigger('GRID_LOADED', {Records: subControl.GetRecordIds()});
				});
			}
		});

		if (this._renderMode() === RenderModes.List) {
			this.InitTagList();
		}
	}

	ApplyProperties() {
		if (this.Properties) {

			if (this.Properties.Conditions) {
				if (this.Properties.Conditions.Properties && this.Properties.Conditions.Properties[0]) {
					this._inverseGridCondition = this.Properties.Conditions.Properties[0].InverseGridCondition;
				}
			}

			//Label
			if (this.Properties.Label) {
				const labelStyle = {backgroundColor: null, color: null};

				_.each(this.Properties.Label.Properties, (property: any) => {
					if (property.BackgroundColor) {
						labelStyle.backgroundColor = property.BackgroundColor;
					}

					if (property.Color) {
						labelStyle.color = property.Color;
					}
				});

				this._labelStyle(labelStyle);
			}
		}
	}

	SetValue(value: IControlValue): void {
		if (value.Data) {
			try {
				let tagsJson = JSON.parse(value.Data.Value);
				let tagData = Deserialize(tagsJson, TagsScreenDataModel);

				if (tagData) {
					this._selectedTags(tagData.Tags);
					this._tagListScreens(tagData.TagListScreens);
				}
			} catch (err) {
			}
		}

		let selectedTags = this._selectedTags() || [];
		let oldTags = selectedTags.map(tag => tag.TagId);

		this._changeModel = new TagListChanges(this.GetControlId(), oldTags);
	}

	Deserialize() {
		return Serialize(this._changeModel);
	}

	private IsTermMinimum() {
		return this._term() && this._term().length >= this._minTermLength;
	}

	private IsTagSelected(model: TagModel) {
		const sameItem = _.find(this._selectedTags(), (tag: TagModel) => model.TagId === tag.TagId);

		return !!sameItem;
	}

	private FormatItemName(tag: TagModel): string {
		const groupName = tag.GroupName ? (tag.GroupName + '/') : '';
		const name = tag.Name || '';

		return groupName + name;
	}

	private RemoveItem(model: TagModel) {
		this._selectedTags.splice(this._selectedTags.indexOf(model), 1);
		this._changeModel.DeleteTag(model.TagId);
		this._term('');
	}

	private SelectItem(model: TagModel) {
		const isSelected = this.IsTagSelected(model);

		if (!isSelected) {
			this._term('');
			this._selectedTags.push(model);
			this._changeModel.AddTag(model.TagId);
		}
    }

    SelectItems(models: TagModel[]) {
        _.each(models, (model) => this.SelectItem(model));
    }

	private LoadTagsBySearchTerm() {
		const term = this._term();

		if (this.IsTermMinimum()) {
			const controlId = this.GetControlId();

			TagStore.GetTags(term, this._tagScope, controlId)
				.then(res => {
					this._tags(res);

					if (this._tags.length <= 0) {
						this._termOperationMessage('No such tags');
					} else {
						this._termOperationMessage('');
					}
				})
				.fail((() => this._termOperationMessage('Unable to get tags')));
		} else {
			this._termOperationMessage(`Please enter ${this._minTermLength} or more characters`);
		}
	}

	EnterKey() {
		return false;
	}

	private LoadAllTags(hideDisabled: boolean = false): P.Promise<any> {
		const deferredResult = P.defer();
		const controlId = this.GetControlId();

		TagStore.GetAllTags(this._tagScope, controlId, false, hideDisabled)
			.then(tags => {
				const tagsByGroup = _.groupBy(tags, (item) => item.GroupName);

				const tagsByGroupModels = _.map(tagsByGroup, (value, key) => {
					const groupName = key === 'null' ? 'No group' : key;

					const tags = _.map(value, (tag) => {
						const tagViewModel = new TagViewModel(tag.Name, tag.TagId, tag.GroupId, tag.TypeId);

						tagViewModel.IsSelected(this._preselectedTags.indexOf(tag.TagId) >= 0);

						return tagViewModel;
					});

					return new TagGroupViewModel(tags, groupName);
				});

				const noGroupTags = tagsByGroupModels.find(g => g.GroupName == "No group");

				if (noGroupTags != null) {
					tagsByGroupModels.push(tagsByGroupModels.splice(tagsByGroupModels.indexOf(noGroupTags), 1)[0]);
                }

				this._tagsByGroup(tagsByGroupModels);
				this._isNoTags(this._tagsByGroup().length === 0);

				deferredResult.resolve(tagsByGroupModels);
			})
			.fail((() => this._termOperationMessage('Unable to get tags')));

		return deferredResult.promise();
	}

	private LoadScopedTags() {
		const controlId = this.GetControlId();

		TagStore.GetAllTags(this._tagScope, controlId, true)
			.then(tags => {
				_.each(this._tagsByGroup(), (group) => {
					_.each(group.Tags, (tag) => {
						const tagInList = _.find(tags, (tag_) => tag_.TagId === tag.TagId);

						if (!tagInList) {
							tag.IsEnabled(false);
						} else {
							tag.IsEnabled(true);
						}
					});
				});
			})
			.fail((() => this._termOperationMessage('Unable to get tags')));
	}


	private InitTagList() {
		const loadAllTagsPromise = this.LoadAllTags();

		this.On('GRID_LOADED', this, (eventArgs) => {
			if (loadAllTagsPromise.status === 0) {
				loadAllTagsPromise.then(() => {
					if (this._filterByTags().length === 0) {
						this.LoadScopedTags();
					}

					if (this._isReloadGrid) {
						this.FilterData();
						this._isReloadGrid = false;
					}
				});
			} else {
				if (this._isReloadGrid) {
					this.FilterData();
					this._isReloadGrid = false;
				}

				if (this._filterByTags().length === 0) {
					this.LoadScopedTags();
				}
			}
		});
	}

	private UpdateFilter(tag: TagViewModel) {
		if (tag.IsEnabled()) {
			tag.IsSelected(!tag.IsSelected());
			this.FilterData();
		}
	}

	private Exclude(tag: TagViewModel) {
		if (tag.IsEnabled()) {
			tag.IsExcluded(!tag.IsExcluded());
			this.FilterData();
		}
	}

	private ResetFilter() {
		_.each(this._filterByTags(), (tag) => tag.IsSelected(false));

		this.FilterData();
	}

	private FilterData() {
		let tagIds = [];

		const tags = _.filter(this._filterByTags(), tag => !tag.IsExcluded());

		const tagsByGroup = _.groupBy(tags, (item) => item.GroupId);

		tagIds = _.map(tagsByGroup, (value, key, object) => {
			return _.map(value, (value_) => value_.TagId);
		});

		const entityId = this.FieldModel.EntityId;

		_.each(this._subControls(), (subControl) => {
			if (subControl instanceof Grid) {
				subControl.SetPageNumber(1);
				subControl.FilterByTags({ EntityId: entityId, Records: tagIds, InverseCondition: this._inverseGridCondition });
			}
		});
	}

	set PreselectedTags(tagIds: Array<number>) {
		this._preselectedTags = tagIds;
		this._isReloadGrid = true;
	}

	async GoToListScreen(listScreen: TagListScreenModel, tag: Tag, evt) {
		const isOpenInModal = evt.ctrlKey

		const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

		BlockUI.Block();
		screenManager.GetScreenById({ ScreenId: listScreen.ScreenId})
		.always(()=>BlockUI.Unblock())
		.then(async (screen: IScreen) => {
			screen.SetIsReady(true);

			const tagControl = screen.GetControl<Tag>(CONTROL_TYPES.Tag);
			const selectedTagIds = _.map(this._selectedTags(), (tag) => tag.TagId);

			if (tagControl) {
				tagControl.PreselectedTags = selectedTagIds;
			}

			const menuManager = (await import('MenuManager/MenuManager')).MenuManager;
			menuManager.Instance.GoToScreen(screen, isOpenInModal);
		});
	}

	ExpandFilter(evt): void {
		this._expandFilter(!this._expandFilter());
	}

	StickyActionBarHeight(isModal:boolean, jBoxModal, _el): number{
		let stickyActionBarHeight = 0;
		if (isModal){
			let actionBarBlock = jBoxModal.find('#ActionBarBlock'),
				actionBarSubForm = jBoxModal.find('.actionBarSubForm'),
				stickyActionBar = actionBarBlock.length > 0 ? actionBarBlock : actionBarSubForm.length > 0 ? actionBarSubForm : null;

			if(stickyActionBar !== null && stickyActionBar.length !== 0 && stickyActionBar.css('position') === 'sticky'){
				let paddingModalPX = $(_el).parents('.jBox-content').css('padding-top'),
					paddingModal = +paddingModalPX.substring(0, paddingModalPX.length - 2);

				stickyActionBarHeight = Math.abs(stickyActionBar.innerHeight() + paddingModal);
			}
		}
		return stickyActionBarHeight;
	}

	RepositionHeaderOnScroll = (scrollPositionInContainer?: number) => {
		const headElement = this._el.querySelector<HTMLDivElement>('.tag-filter-column');
		if (!headElement) {
			return;
		}
		const boundingRect = this._el.getBoundingClientRect();
		const modalTopPosition = Math.abs(($('.jBox-content').parents('.jBox-Modal').height() - $(window).height()) / 2);
		let fixedHeaderHeight = 0;
		let jBoxModal = $(this._el).parents('.jBox-content').parents('.jBox-Modal');
		let isModal = jBoxModal.length > 0 && jBoxModal.css('display') !== 'none';
		if (window.innerWidth > 991) {
			const headerElement: HTMLDivElement = document.querySelector("body > .page-header");
			fixedHeaderHeight = isModal ? modalTopPosition + this.StickyActionBarHeight(isModal, jBoxModal, this._el) : headerElement ? headerElement.offsetHeight : 0;
		}

		if (boundingRect.top < fixedHeaderHeight) {
			const distanceFromTop = Math.min(boundingRect.top * -1 + fixedHeaderHeight, boundingRect.height - 28);
			headElement.style.top = Math.max(distanceFromTop, 0) + "px";
		} else {
			const portletToolbarHeight = $(this._el).parents('.portlet-body-content').find('.toolbar-wrapper').height();
			const topHeaderPosition = scrollPositionInContainer && portletToolbarHeight < scrollPositionInContainer ? scrollPositionInContainer - portletToolbarHeight - 5 + "px" : '0';
			headElement.style.top = topHeaderPosition;
		}
	}

	OnResize = () => {
		this.RepositionHeaderOnScroll();
	}

	AfterRender(el: Array<HTMLElement>): void {
		super.AfterRender(el);

		if (this._stickyFilter) { //it is necessary to modernize and make logic
			const unbindResize = ResizeService.SubscribeWidth(this.OnResize, this._el); // subscribe on resize
			ko.utils.domNodeDisposal.addDisposeCallback(this._el, () => {
				unbindResize();
				window.removeEventListener("scroll", () => this.RepositionHeaderOnScroll())
			});

			window.addEventListener("scroll", () => this.RepositionHeaderOnScroll());
			$(this._el).parents('.jBox-content').on('scroll', () => this.RepositionHeaderOnScroll());
			$(this._el).parents('.portlet-body').on('scroll', () => this.RepositionHeaderOnScroll($(this._el).parents('.portlet-body').scrollTop()));
		}

	}

	TagbookOpen(): void {
		this._tagbook = new Tagbook();

		this._form.GetScreen().AttachModalComponent(this._tagbook);

		let hideDisabled : boolean = true;
		this.LoadAllTags(hideDisabled)
			.then((data) => {
				this._tagbook.SetTagsList(data, this._selectedTags(), this._term());
			});

		this._modal = new Modal({
			addClass: 'tagbook-modal',
			closeOnEsc: false
		});

		this._tagbook.On('Save', this, (data) => {
			const addedTags: TagModel[] = data.data.addedTags;
			const deletedTags: TagModel[] = data.data.deletedTags;
			const selectedTags: TagModel[] = data.data.selectedTags;

			deletedTags.forEach((tag) => this._changeModel.DeleteTag(tag.TagId));
			addedTags.forEach((tag) => this._changeModel.AddTag(tag.TagId));

			this._selectedTags(selectedTags);

			this._modal.Close();
		});

		this._tagbook.On('Cancel', this, () => {
			this._modal.Close();
		});

		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this._tagbook, this._modal.Wrapper);

		this._modal.Show();
	}
}