import {Modal} from 'Core/Common/Modal';
import * as ko from "knockout";

import Template from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/MemoValueModal.html';
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/MemoValueModal'] = Template;

export class MemoValueModal {
    private _value: string;

    constructor(value: string) {
        this._value = value;
    }

    Show(){
        let modal = new Modal({}, true);
        ko.cleanNode(modal.Wrapper);
        ko.applyBindings(this, modal.Wrapper);
        modal.Show();
    }

    GetTemplateName(){
        return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/MemoValueModal';
    }

    AfterRender(){}
}