import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';

import {ProgressBarInfoModel} from "../Models/Response/ProgressBarInfoModel";
import type {ICreateFlowRecordRequestModel} from "../Models/Request/ICreateFlowRecordRequestModel";
import type {IUpdateFlowRecordRequestModel} from "../Models/Request/IUpdateFlowRecordRequestModel";
import type {IDeleteFlowRecordRequestModel} from "../Models/Request/IDeleteFlowRecordRequestModel";
import type {IGetProgressBarInfoRequestModel} from "../Models/Request/IGetProgressBarInfoRequestModel";
import type {IGetMemosRequestModel} from "../Models/Request/IGetMemosRequestModel";
import type {MemoInfoModel} from "../Models/Response/MemoInfoModel";
import type {MemoItemModel} from "../Models/Response/MemoItemModel";
import type {ICreateMemoRecordRequestModel} from "../Models/Request/ICreateMemoRecordRequestModel";
import type {IGetMemoRequestModel} from "../Models/Request/IGetMemoRequestModel";
import type {IDeleteMemoRecordRequestModel} from "../Models/Request/IDeleteMemoRecordRequestModel";
import {
	IUpdateFlowMemoRecordRequestModel
} from "../Models/Request/IUpdateFlowMemoRecordRequestModel";
import {IUpdateFlowOperationRecordRequestModel} from "../Models/Request/IUpdateFlowOperationRecordRequestModel";
import {GenericDeserialize} from "../../../../libs/cerialize";

export class FlowFolderStore {
    static CreateFlowRecord(params: ICreateFlowRecordRequestModel) {
        const deferredResult = P.defer<any>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: '/api/FlowFolderApi/CreateFlowRecord',
                dataType: 'json',
                type: 'POST'
            },
            params
        };

        Request.Send(requestParams)
            .then(result => {
                deferredResult.resolve(result);
            })
            .fail(error => {
                deferredResult.reject(error);
            });

        return deferredResult.promise();
	}

    static UpdateFlowRecord(params: IUpdateFlowRecordRequestModel) {
	    const deferredResult = P.defer<any>();

	    const requestParams: IRequestOptions = {
		    proxy: {
			    url: '/api/FlowFolderApi/UpdateFlowRecord',
			    dataType: 'json',
			    type: 'POST'
		    },
		    params
	    };

	    Request.Send(requestParams)
		    .then(result => {
			    deferredResult.resolve(result);
		    })
		    .fail(error => {
			    deferredResult.reject(error);
		    });

	    return deferredResult.promise();
    }

    static DeleteFlowRecord(params: IDeleteFlowRecordRequestModel) {
	    const deferredResult = P.defer<any>();

	    const requestParams: IRequestOptions = {
		    proxy: {
			    url: '/api/FlowFolderApi/DeleteFlowRecord',
			    dataType: 'json',
			    type: 'POST'
		    },
		    params
	    };

	    Request.Send(requestParams)
		    .then(result => {
			    deferredResult.resolve(result);
		    })
		    .fail(error => {
			    deferredResult.reject(error);
		    });

	    return deferredResult.promise();
    }

	static GetProgressBarInfo(params: IGetProgressBarInfoRequestModel): P.Promise<ProgressBarInfoModel> {
	    const deferredResult = P.defer<ProgressBarInfoModel>();

	    const requestParams: IRequestOptions = {
		    proxy: {
			    url: '/api/FlowFolderApi/GetProgressBarInfo',
			    dataType: 'json',
			    type: 'POST'
		    },
		    params
	    };

	    Request.Send(requestParams)
		    .then(data => {
			    if (data.IsSuccessfull) {
					var model = GenericDeserialize<ProgressBarInfoModel>(data.ResultObject, ProgressBarInfoModel);
					deferredResult.resolve(model);
				} else {
				    deferredResult.reject({ message: data.ErrorMessage });
			    }
		    });

	    return deferredResult.promise();
    }

	static GetFlowMemos(params: IGetMemosRequestModel): P.Promise<MemoInfoModel> {
		const deferredResult = P.defer<MemoInfoModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/FlowFolderApi/GetFlowMemos',
				dataType: 'json',
				type: 'POST'
			},
			params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
				// if (data.IsSuccessfull) {
				// 	deferredResult.resolve(data.ResultObject);
				// } else {
				// 	deferredResult.reject({ message: data.ErrorMessage });
				// }
			});

		return deferredResult.promise();
	}

	static GetFlowMemo(params: IGetMemoRequestModel): P.Promise<MemoItemModel> {
		const deferredResult = P.defer<MemoItemModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/FlowFolderApi/GetFlowMemo',
				dataType: 'json',
				type: 'POST'
			},
			params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
				// if (data.IsSuccessfull) {
				// 	deferredResult.resolve(data.ResultObject);
				// } else {
				// 	deferredResult.reject({ message: data.ErrorMessage });
				// }
			});

		return deferredResult.promise();
	}

	static CreateFlowMemo(params: ICreateMemoRecordRequestModel) {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/FlowFolderApi/CreateFlowMemo',
				dataType: 'json',
				type: 'POST'
			},
			params
		};

		Request.Send(requestParams)
			.then(result => {
				deferredResult.resolve(result);
			})
			.fail(error => {
				deferredResult.reject(error);
			});

		return deferredResult.promise();
	}

	static UpdateFlowMemoRecord(params: IUpdateFlowMemoRecordRequestModel) {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/FlowFolderApi/UpdateFlowMemo',
				type: 'POST'
			},
			params
		};

		Request.Send(requestParams)
			.then(result => {
				deferredResult.resolve(result);
			})
			.fail(error => {
				deferredResult.reject(error);
			});

		return deferredResult.promise();
	}

	static UpdateFlowOperationRecord(params: IUpdateFlowOperationRecordRequestModel) {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/FlowFolderApi/UpdateFlowOperation',
				type: 'POST'
			},
			params
		};

		Request.Send(requestParams)
			.then(result => {
				deferredResult.resolve(result);
			})
			.fail(error => {
				deferredResult.reject(error);
			});

		return deferredResult.promise();
	}

	static DeleteFlowMemo(params: IDeleteMemoRecordRequestModel) {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/FlowFolderApi/DeleteFlowMemo',
				type: 'POST'
			},
			params
		};

		Request.Send(requestParams)
			.then(result => {
				deferredResult.resolve(result);
			})
			.fail(error => {
				deferredResult.reject(error);
			});

		return deferredResult.promise();
	}

}