import {P} from 'Core/Common/Promise';

import {JournalsState} from "../Enums/JournalsState";
import {AccountsState} from "../Enums/AccountsState";

import {IFinancialDashboardStore} from './IFinancialDashboardStore';

import {ICreateInvoiceDtoModel} from 'Core/Components/Dashboards/FinancialDashboard/Models/Dto/Request/ICreateInvoiceDtoModel';
import {ISendToFPDtoModel} from 'Core/Components/Dashboards/FinancialDashboard/Models/Dto/Request/ISendToFPDtoModel';
import {PagedResult} from "Core/Results/ResultModels/PagedResult";

import {IGetGridDataRequestModel} from "QueryBuilder/QueryResultGrid/Stores/QueryResultStore";

import {FinancialDashboardDtoModel} from "Core/Components/Dashboards/FinancialDashboard/Models/Dto/Response/FinancialDashboardDtoModel";
import {SourceTableDtoModel} from "Core/Components/Dashboards/FinancialDashboard/Models/Dto/Response/SourceTableDtoModel";
import {SourceTableTypeDtoModel} from "Core/Components/Dashboards/FinancialDashboard/Models/Dto/Response/SourceTableTypeDtoModel";
import {InvoicingControlDtoModel} from "Core/Components/Dashboards/FinancialDashboard/Models/Dto/Response/InvoicingControlDtoModel";
import {
    InvoicingResultDtoModel,
    InvoicingResultsDtoModel
} from 'Core/Components/Dashboards/FinancialDashboard/Models/Dto/Response/InvoicingResultsDtoModel';
import {FinancialProcessorRunnerDtoModel} from 'Core/Components/Dashboards/FinancialDashboard/Models/Dto/Response/FinancialProcessorRunnerDtoModel';
import {LedgerDtoModel} from "Core/Components/Dashboards/FinancialDashboard/Models/Dto/Response/LedgerDtoModel";
import {AccountsDtoModel} from "../Models/Dto/Response/AccountsDtoModel";
import {SubAdministrationDtoModel} from "../Models/Dto/Response/SubAdministrationDtoModel";
import {FinancialProcessorDtoModel} from "../Models/Dto/Response/FinacialProcessorDtoModel";
import {ReadyForProcessingDataDtoModel} from "../Models/Dto/Response/ReadyForProcessingDataDtoModel";
import {ReadyForInvoicingDataDtoModel} from "../Models/Dto/Response/ReadyForInvoicingDataDtoModel";
import {IGetReadyForInvoicingDataDtoModel} from "../Models/Dto/Request/IGetReadyForInvoicingDataDtoModel";
import {IGetFinancialProcessorsDtoModel} from "../Models/Dto/Request/IGetFinancialProcessorsDtoModel";

export class FinancialDashboardMockStore implements IFinancialDashboardStore {
    GetData(controlId: number): P.Promise<FinancialDashboardDtoModel> {
        const dashboardModel = new FinancialDashboardDtoModel();

        const projects = this.GetSourceTable(1, 'PROJECTS', [{
            Id: 1,
            Name: 'Startup',
            InvoicingControlsName: ['Invoice', 'Purchase']
        }]);

        const orders = this.GetSourceTable(2, 'ORDERS', [{
            Id: 2, Name: 'Shipping',
            InvoicingControlsName: ['Shipping']
        }]);

        const contracts = this.GetSourceTable(3, 'CONTRACTS', [{
            Id: 3,
            Name: 'Service',
            InvoicingControlsName: ['Invoice']
        }, {
            Id: 4,
            Name: 'Lease',
            InvoicingControlsName: ['Create lease']
        }]);

        dashboardModel.SourceTables = [projects, orders, contracts];

        dashboardModel.FinancialProcessorRunners = [
            this.GetFinancialProcessorRunner(1, 'Send to FP1', 10),
            this.GetFinancialProcessorRunner(2, 'Send to FP2', 15),
            this.GetFinancialProcessorRunner(3, 'Send to FP3', 20)
        ];

        dashboardModel.JournalsState = JournalsState.Ok;
        dashboardModel.AccountsState = AccountsState.Ok;
        dashboardModel.Ledger = this.GetLedger();

        const deferred = P.defer<FinancialDashboardDtoModel>();
        deferred.resolve(dashboardModel);

        return deferred.promise();
    }

    Invoice(createInvoiceParams: ICreateInvoiceDtoModel) {
        const results = new InvoicingResultsDtoModel();

        results.Results = [
            this.GetInvoicingResult(1, 'Spheeres.com'),
            this.GetInvoicingResult(2, "Bad guys company", "Because it's bad")
        ];

        const deferred = P.defer<InvoicingResultsDtoModel>();
        deferred.resolve(results);

        return deferred.promise();
    }

    GetFinancialProcessors(params: IGetFinancialProcessorsDtoModel): P.Promise<PagedResult<FinancialProcessorDtoModel>> {
        const deferred = P.defer<PagedResult<FinancialProcessorDtoModel>>();
        deferred.resolve(null);

        return deferred.promise();
    }

    GetReadyForInvoicingData(params: IGetReadyForInvoicingDataDtoModel): P.Promise<ReadyForInvoicingDataDtoModel> {
        const deferred = P.defer<any>();
        deferred.resolve(null);

        return deferred.promise();
    }

    GetReadyForProcessingData(params: IGetGridDataRequestModel): P.Promise<ReadyForProcessingDataDtoModel> {
        const deferred = P.defer<any>();
        deferred.resolve(null);

        return deferred.promise();
    }

    SendToFinancialProcessor(sendToFPParams: ISendToFPDtoModel) {
        const deferred = P.defer<any>();
        deferred.resolve(null);

        return deferred.promise();
    }

    private GetSourceTable(id: number, name: string, types: { Id: number, Name: string, InvoicingControlsName: string[] }[]) {
        const sourceTable = new SourceTableDtoModel();

        sourceTable.Id = id;
        sourceTable.Name = name;
        sourceTable.Types = types.map((type, index) => this.GetSourceTableType(index + 1, type.Name, type.InvoicingControlsName));

        return sourceTable;
    }

    private GetSourceTableType(id: number, name: string, invoicingControlsName: string[]) {
        const sourceTableType = new SourceTableTypeDtoModel();

        sourceTableType.Id = id;
        sourceTableType.Name = name;
        sourceTableType.Controls = invoicingControlsName.map((invoicingControlName, index) => this.GetInvoicingControl(index + 1, invoicingControlName, index + 1));

        return sourceTableType;
    }

    private GetInvoicingControl(id: number, name: string, recordsAmount: number) {
        const invoicingControl = new InvoicingControlDtoModel();

        invoicingControl.Id = id;
        invoicingControl.Name = name;
        invoicingControl.RecordsAmount = recordsAmount;

        return invoicingControl;
    }

    private GetFinancialProcessorRunner(id: number, name: string, recordsAmount: number) {
        const fpRunner = new FinancialProcessorRunnerDtoModel();

        fpRunner.Id = id;
        fpRunner.Name = name;
        fpRunner.RecordsAmount = recordsAmount;

        return fpRunner;
    }

    private GetLedger(): LedgerDtoModel {
        const ledger = new LedgerDtoModel();

        ledger.Accounts = this.GetAccounts(1);
        ledger.SubAdministrations = [
            this.GetSubAdministration(1, 'PROJECTS SA'),
            this.GetSubAdministration(2, 'COMPANIES SA'),
        ];

        return ledger;
    }

    private GetAccounts(id: number): AccountsDtoModel {
        const accounts = new AccountsDtoModel();

        accounts.TableId = id;

        return accounts;
    }

    private GetSubAdministration(id: number, name: string): SubAdministrationDtoModel {
        const subAdministration = new SubAdministrationDtoModel();

        subAdministration.TableId = id;
        subAdministration.Name = name;

        return subAdministration;
    }

    private GetInvoicingResult(id: number, name: string, error?: string) {
        const result = new InvoicingResultDtoModel();

        result.RecordId = id;
        result.RecordName = name;
        result.Result = error;
        result.IsSuccessfull = !error;

        return result;
    }
}