import {ImageTableTypes} from "../../../../../../../Components/ProductImageViewer/Enums/ImageTableTypes";

export class ImageModel {
	Id: number;
	DisplayName: string;
	ImageTableType: ImageTableTypes;

	constructor(public id: number, public Name: string, public Thumbnail: string, public imageTableType: ImageTableTypes) {
		this.Id = id;
		this.DisplayName = Name;
		this.ImageTableType = imageTableType;
	}
}