import { Request, IRequestOptions } from 'Core/Common/Request';
import { P } from 'Core/Common/Promise';
import Rejection = P.Rejection;

export interface ICurrency {
    Id: number;
    Name: string;
    IsoCode: string;
    Icon: any;
}

export class CurrencyStore {
    static GetNativeCurrency(): P.Promise<ICurrency> {
        const deferred = P.defer<ICurrency>();
        const requestParams: IRequestOptions = {
            proxy: {
                url: "api/CurrencyApi/GetNativeCurrency",
                dataType: "json",
                type: "GET"
            },
        };

        Request.Send(requestParams)
            .then(response => this.ResolveResponse(deferred, response))
            .fail(error => this.RejectResponse(deferred, error));

        return deferred.promise();
    }

    static GetCurrencies(): P.Promise<Array<ICurrency>> {
        const deferred = P.defer<Array<ICurrency>>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: "api/CurrencyApi/GetCurrencies",
                dataType: "json",
                type: "GET"
            },
        };

        Request.Send(requestParams)
            .then(response => this.ResolveResponse(deferred, response))
            .fail(error => this.RejectResponse(deferred, error));

        return deferred.promise();
    }

    private static ResolveResponse<T>(deferred: P.Deferred<T>, response: T) {
        deferred.resolve(response);
    }

    private static RejectResponse<T>(deferred: P.Deferred<T>, error: Rejection) {
        deferred.reject(error);
    }
}