import * as ko from "knockout";
import * as _ from "underscore";
import { Event } from 'Core/Common/Event';
import { PersonalSettingsModel } from 'Core/ProfilePage/PersonalSettings/Models/PersonalSettingsModel';

import Template from 'Core/ProfilePage/PersonalSettings/PersonalSettingsEditors/Text/Templates/Text.html'
ko.templates['Core/ProfilePage/PersonalSettings/PersonalSettingsEditors/Text/Templates/Text'] = Template;


enum typeConfiguration { Integer = "number", Text = "text" };

export class Text {
	Value: KnockoutObservable<string | number | boolean>;
	HasChanges: KnockoutObservable<boolean>;
	Disabled: boolean;
	Name: string;
	Id: number;
	Type: string;
	Description: string;


	constructor(model: PersonalSettingsModel, changeHandler, PersonalSettingsInstance) {
		this.Value = ko.observable(model.Value);
        this.Name = model.TranslatedName ? model.TranslatedName : model.Name;
		this.Disabled = model.LockToDefault;
		this.Id = model.Id;
		this.Description = model.DescriptionTranslation ? model.DescriptionTranslation : model.Description;
		this.HasChanges = ko.observable(false);
		this.Type = typeConfiguration[model.TypeName];

		this.Value.subscribe((newValue) => {
			this.HasChanges(true);
			changeHandler.call(PersonalSettingsInstance);
		});
	}
	
	GetTemplateName() {
		return 'Core/ProfilePage/PersonalSettings/PersonalSettingsEditors/Text/Templates/Text';
	}

	AfterRender(el: Array<HTMLElement>) {
	}
}