import * as ko from "knockout";

import {TranslationManager} from "Core/Components/Translation/TranslationManager";
import {Convert} from "Core/Common/ValueConverter";

import {IValueUpdater} from "LookupEditor/Interfaces/IValueUpdater";
import {IColumnValue} from "LookupEditor/Interfaces/IColumnValue";
import {RecordDataModel, FieldDataModel} from "Core/Screens/Models/RecordDataModel";
import {FormatConverter} from "FormatEditor/FormatConverter";
import {NOTIFICATIONS, LABELS} from "Core/Components/Translation/Locales";
import {FieldFlags} from "../../Core/Common/Enums/FieldFlags";
import {ColorConverter} from "../../Core/Components/ColorSelector/ColorConverter";
import {extractLookupValFieldMetadata} from "Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel";
import { FIELD_TYPES } from "Core/Constant";

export class EditableRowModel implements IValueUpdater {
    IsRecordSelected: KnockoutObservable<boolean>;
    IsRecordChanged: KnockoutObservable<boolean>;

    private _dataObject: RecordDataModel;
    private emptyLookupValue: string = '-';

    constructor(dataObject?: RecordDataModel) {
        this.IsRecordSelected = ko.observable(false);
        this._dataObject = dataObject || null;
        this.IsRecordChanged = ko.observable(false);
    }

    get Data(): any {
        return this._dataObject;
    }

    GetDbValue(columnName: string): any {
        const column = this.GetColumn(columnName);
        const columnData = this.GetColumnData(columnName);

        let value = columnData.FieldValue;

        if (columnName.toLowerCase() === "memo" && value != null) {
            value = _.unescape(value);
        }

        if (column.Type === FIELD_TYPES.YesNo) {
            return value ? value !== "False" : false;
        }

        return value === undefined || value === null ? "" : value;
    }

    GetFieldFormat(columnName: string): string {
        const columnData = this.GetColumnData(columnName);

        if (columnData.FieldTypeFormat === 'Percentage' && (!columnData.FieldValue || columnData.FieldValue == this.emptyLookupValue)) {
            return null;
        }
        return columnData.FieldTypeFormat;
    }

    GetLookupClassName(columnName: string): string {
        const columnData = this.GetColumnData(columnName);

        if (!columnData.FieldValue || !columnData.DisplayValue) {
            return undefined;
        }

        const column = this.GetColumn(columnName);
        const validationFieldMetadata = extractLookupValFieldMetadata(column);
        return validationFieldMetadata && validationFieldMetadata.FormatName;
    }

    GetSize(columnName: string): number {
        const column = this.GetColumn(columnName);
        return column.Size;
    }

    GetValue(columnName: string): any {
        const column = this.GetColumn(columnName);
        const columnData = this.GetColumnData(columnName);

        let value = columnData && (columnData.DisplayValue || columnData.FieldValue);

        if (column.Type === "Text") {
            const translation = this.GetTranslation(columnData);
            value = translation.TranslatedValue || translation.Value;
        }

        if (columnName.toLowerCase() === "memo" && value != null) {
            value = _.unescape(value);
        }

        if (column.Type === "Decimal" || column.Type === "Integer") {
            if (columnData.FieldValue && columnData.FieldTypeFormat === 'Percentage') {
                return FormatConverter.LocalizeDecimalOrInteger(String((parseFloat(columnData.FieldValue.replace(',', '.')) * 100).toFixed(columnData.FieldSize < 2 ? 0 : columnData.FieldSize - 2)));
            }
            return FormatConverter.LocalizeDecimalOrInteger(columnData.FieldValue);
        }

        if (column.Type === FIELD_TYPES.YesNo) {
            return value ? value !== "False" : false;
        }

        if (column.Type === "Lookup") {
            if (!columnData.FieldValue) {
                return LABELS.EMPTY;
            }

            if (!columnData.DisplayValue) {
                return this.emptyLookupValue;
            }

            const validationFieldMetadata = extractLookupValFieldMetadata(column);

            if (validationFieldMetadata && _.contains(["Decimal", "Integer"], validationFieldMetadata.Type)) {
                if (validationFieldMetadata.FormatName === 'Percentage') {
                    return FormatConverter.LocalizeDecimalOrInteger(String((parseFloat(value) * 100).toFixed(columnData.FieldSize < 2 ? 0 : columnData.FieldSize - 2)));
                }
                return FormatConverter.LocalizeDecimalOrInteger(value);
            }

            return value;
        }

        if (column.Type === "Radio") {
            return columnData.FieldValue ? value : LABELS.EMPTY;
        }

        if (column.Type === "MultiSelect") {
            return columnData.FieldValue ? columnData : LABELS.EMPTY;
        }

        if (column.Type === "Image") {
            const imageColumnName = column.Name + "_THUMBNAIL";
            const data = this.GetColumnData(imageColumnName).FieldValue;
            value = Convert.ToBase64Image(data);
        }

        if (column.Type === "Password") {
            return columnData.FieldValue;
        }

        if (column.Type === "Color"){
            return columnData.FieldValue ? columnData.FieldValue : LABELS.EMPTY;
        }

        return value === undefined || value === null ? "" : value;
    }

    GetHexValue(columnName: string): any {
        const columnData = this.GetColumnData(columnName);
        return columnData.FieldValue ? ColorConverter.ToHex(columnData.FieldValue) : LABELS.EMPTY;
    }

    SetValue(columnName: string, value: any) {
        const field = this.GetColumnData(columnName);
        field.FieldValue = value;
    }

    UpdateValue(value: IColumnValue) {
        const field = this.GetColumnData(value.Name);
        const translatable = (FieldFlags.Translate & this.GetColumn(value.Name).Flags) === FieldFlags.Translate;

        if (translatable || field.FieldValue !== value.Value) {
            field.FieldValue = value.Value;
            field.DisplayValue = value.DisplayValue;
            field.FieldValueTranslations = value.Translations;

            field.Changed = true;
            this.IsRecordChanged(true);
        }
    }

    GetColumn(name: string) {
        return _.find(this._dataObject.TableStruct.Fields, (f: any) => f.Name === name);
    }

    GetColumnData(name: string): FieldDataModel {
        return _.find(this._dataObject.Fields, f => f.FieldName === name);
    }

    GetTranslation(columnData: FieldDataModel) {
        return TranslationManager.Instance.GetTranslation(columnData.FieldValue, columnData.FieldValueTranslations);
    }
}