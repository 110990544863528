import * as ko from 'knockout'
import * as _ from 'underscore';
import * as moment from 'moment';

import 'knockout-selectize';

import {BaseControl, IControlValue} from 'Core/Controls/BaseControl/BaseControl'
import {IControlParam, ISearchScreenParam} from 'Core/Screens/IScreen'
import {DropdownDataStore, IGetDropdownControlDataRequestModel} from 'Core/Controls/Dropdown/Stores/DropDownDataStore'
import {DropdownItemModel} from 'Core/Controls/Dropdown/Models/DropdownListModel'
import {CONTROL_TYPES, FIELD_TYPES, FONT_STYLES, RenderModes, TABLE_TYPES} from 'Core/Constant';
import {GlobalManager, GLOBALS} from 'Core/GlobalManager/GlobalManager';
import {SearchScreen} from 'Core/Screens/SearchScreen/SearchScreen';
import {EditScreen} from 'Core/Screens/EditScreen/EditScreen';
import {BlockUI} from 'Core/Common/BlockUi';
import {GenericDeserialize} from 'libs/cerialize';
import {QueryConditionGroupModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryConditionGroup';
import {Notifier} from 'Core/Common/Notifier';
import {GeneralProperties} from 'Core/GeneralProperties/GeneralProperties';
import {ConditionBuilder} from 'QueryBuilder/QueryCondition/ConditionBuilder/ConditionBuilder';
import {EntityTypesStore} from 'Core/Screens/TypeScreen/Stores/EntityTypesStore';
import {LABELS, NOTIFICATIONS} from 'Core/Components/Translation/Locales';
import {EVENTS} from 'Core/Controls/Dropdown/Constants';

import {TypeScreen} from "Core/Screens/TypeScreen/TypeScreen";
import {IDynamicFieldData, RecordStore} from "Core/Common/Stores/RecordStore";
import {SERVER_REQUEST_ERRORS} from "Core/Common/Enums/ServerRequestErrors";
import {ScreenTypes} from "../../Common/Enums/ScreenTypes";
import { EVENTS as SCREEN_EVENTS } from 'Core/Screens/Events';

import type {NewRecordScreen} from "LookupEditor/NewRecord/NewRecordScreen";
import {NewRecordModel} from "LookupEditor/NewRecord/Models/NewRecordModel";
import {LookupModel} from "LookupEditor/Store/Models/LookupModel";
import {TablesStore} from "LookupEditor/Store/TablesStore";
import {LookupEditorStore} from "LookupEditor/Store/LookupEditorStore";

import {FormatConverter} from 'FormatEditor/FormatConverter';

import DropdownConfig from 'Core/Controls/Dropdown/Configs/dropdown-config.json';

import ViewTemplate from 'Core/Controls/Dropdown/Templates/View.html';
import HelpViewTemplate from 'Core/Controls/Dropdown/Templates/HelpView.html';
import ToolBarTemplate from 'Core/Controls/Dropdown/Templates/ToolBar.html';
import DesignTemplate from 'Core/Controls/Dropdown/Templates/Design.html';
import EditTemplate from 'Core/Controls/Dropdown/Templates/Edit.html';
import DropdownTemplate from 'Core/Controls/Dropdown/Templates/Dropdown.html';
import SearchTemplate from 'Core/Controls/Dropdown/Templates/Search.html';
import {RecordSpecsModel} from "../../ScreenManager/Models/RecordSpecsModel";
import { TableSecurityStore } from '../TableSecurity/Stores/TableSecurityStore';
import { TABLE_SECURITY_WORDS } from 'Core/Constants/TableSecurityWords';
import { PROPERTIES, PROPERTY_GROUPS } from './Constants';
import { IScreenVariable } from 'Core/Screens/BaseScreen';

ko.templates['Core/Controls/Dropdown/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Dropdown/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Dropdown/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/Dropdown/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/Dropdown/Templates/Edit'] = EditTemplate;
ko.templates['Core/Controls/Dropdown/Templates/Dropdown'] = DropdownTemplate;
ko.templates['Core/Controls/Dropdown/Templates/Search'] = SearchTemplate;

const TYPE_FIELD_NAME = 'F_TYPE';

export class Dropdown extends BaseControl {
    private _value: number;
    private _originalValue: number;
    private _displayValue: string;
    private _subjectRecordId: number;
    private _dropdownPlugins: string[];
    private _labelStyle: KnockoutObservable<any>;
    private _textInputStyle: KnockoutObservable<any>;
    private _selectedItem: KnockoutObservable<DropdownItemModel>;
    private _selectedValueId: KnockoutObservable<number>;
    private _selectedValueLabel: KnockoutObservable<string>;
    private _initialValueLabel: KnockoutObservable<string>;
    private _items: KnockoutObservableArray<DropdownItemModel>;
    private _showSearchScreenButton: KnockoutObservable<boolean>;
    private _autoSelectIfOneRecord: KnockoutObservable<boolean>;
    private _searchScreenIfRecordsCount: number;
    private _searchScreen: SearchScreen;
    private _totalRecordsCount: number;
    private _conditions: QueryConditionGroupModel;
    private _searchTerm: string;
    private _allowInsert: KnockoutObservable<boolean>;
    private _clearAllowed: KnockoutObservable<boolean>;
    private _isCustomValueEntered: KnockoutObservable<boolean>;
    private _customPlaceholder: KnockoutObservable<string>;
    private _showConditionToggle: KnockoutObservable<boolean>;
    private _isEnableFilter: KnockoutObservable<boolean>;
    private _isReady: KnockoutObservable<boolean>;
    private _isDisableDropDown: KnockoutObservable<boolean>;
    private _isPreselect: KnockoutObservable<boolean>;
    private _label = LABELS;
    private _defaultPlaceholder = `${LABELS.SELECT_LABEL}...`;
    private _select: HTMLSelectElement;
    private _subjectRecordSpecsModel: RecordSpecsModel;
    private _newLookupRecordScreen: NewRecordScreen;
    private IsAllowInsert: KnockoutComputed<boolean>;

    constructor(params: IControlParam) {
        super(params, DropdownConfig);

        this._value = null;
        this._displayValue = null;
        this._showSearchScreenButton = ko.observable(false);
        this._selectedItem = ko.observable(null);
        this._selectedValueId = ko.observable(null);
        this._selectedValueLabel = ko.observable(null);
        this._initialValueLabel = ko.observable(null);
        this._allowInsert = ko.observable(false);
        this._clearAllowed = ko.observable(false);
        this._isCustomValueEntered = ko.observable(false);
        this._customPlaceholder = ko.observable(this._defaultPlaceholder);
        this._totalRecordsCount = 0;
        this._autoSelectIfOneRecord = ko.observable(false);
        this._showConditionToggle = ko.observable(this.FieldModel && this.FieldModel.HasFilter);
        this._labelStyle = ko.observable(null);
        this._textInputStyle = ko.observable(null);
        this._items = ko.observableArray([]);
        var searchScreenIfRecordsCount = parseInt(GlobalManager.Instance.GetGlobal(GLOBALS.SEARCH_SCREEN_IF_RECORDS_COUNT));
        this._searchScreenIfRecordsCount = Number.isNaN(searchScreenIfRecordsCount) ? 10 : searchScreenIfRecordsCount;
        this.Init();
        this._conditions = null;
        this._searchTerm = '';
        this._isEnableFilter = ko.observable(false);
        this._isReady = ko.observable(true);
        this._isDisableDropDown = ko.observable(false);
        this._isPreselect = ko.observable(false);
        this._newLookupRecordScreen = null;

        this.ApplyProperties();
        this.InitDropdownPlugins();

        this.AddEvent(EVENTS.DROPDOWN_VALUE_SELECTED);
        this.AddEvent(EVENTS.REFRESH);

        this.IsAllowInsert = ko.computed(()=>{
            if (this._allowInsert()) {
                if (this._showConditionToggle()) {
                    return true;
                }
                
                if (!this._conditions) {
                    return true;
                }
                
                if (!(this._conditions.Items.length || this._conditions.ConditionGroups.length)) {
                    return true;
                }
                    return false;
            }
                return false;
        });
        this.CheckCreateRecordAllowance();
    }

    GetDropdownTemplateName() {
        return 'Core/Controls/Dropdown/Templates/Dropdown';
    }

    GetSearchTemplateName() {
        return 'Core/Controls/Dropdown/Templates/Search';;
    }

    ApplyProperties() {

        let labelBackgroundColor = this.GeneralProperties.GetPropertyValue(PROPERTIES.BACKGROUND_COLOR, PROPERTY_GROUPS.LABEL);
        let labelColor = this.GeneralProperties.GetPropertyValue(PROPERTIES.COLOR, PROPERTY_GROUPS.LABEL);
        let inputBackgroundColor = this.GeneralProperties.GetPropertyValue(PROPERTIES.BACKGROUND_COLOR, PROPERTY_GROUPS.TEXT_INPUT);

        this._labelStyle({ backgroundColor: labelBackgroundColor, color: labelColor });
        this._textInputStyle({ backgroundColor: inputBackgroundColor });

        let condition = this.GeneralProperties.GetPropertyValue(PROPERTIES.QUERY_CONDITION);

        if(condition){
            var jsonObj = JSON.parse(condition);
            var conditions = GenericDeserialize<QueryConditionGroupModel>(jsonObj, QueryConditionGroupModel);
            this._conditions = conditions;

            this._autoSelectIfOneRecord(this.GeneralProperties.GetPropertyValue(PROPERTIES.AUTO_SELECT));
            let showConditionToggle = this.GeneralProperties.GetPropertyValue(PROPERTIES.SHOW_CONDITION_TOGGLE);

            showConditionToggle = this._conditions?.HasAnyConditions && showConditionToggle;
            this._showConditionToggle(showConditionToggle);
        }
       
        this.ApplyLinkEditorStyles();

        if(this.HideIfCondition){
            if(this.IsRunTime){
                this._isVisible(false);
            }
        }
    }

    set AutoSelectIfOneRecord(value: boolean){
        this._autoSelectIfOneRecord (value);
    }

    private InitDropdownPlugins() {
        if(!this.IsRunTime) return;
        this._dropdownPlugins = ['dropdown_direction'];

        if (!this.FieldModel.AllowCustomValue) {
            this._dropdownPlugins.push('hidden_textfield');
        }
    }

    get DefaultFontWeight(){
        return FONT_STYLES.NORMAL;
    }

    private ApplyLinkEditorStyles() {
        if (!this.IsLinkEditor || !this.FieldModel.HasLinkEditorVisibility) {
            return;
        }
        const labelStyle = {
            color: null,
            fontWeight: null,
            fontStyle: null,
            textDecoration: null
        };
        if (this.FieldModel.FontColor) {
            labelStyle.color = this.FieldModel.FontColor;
        }
        labelStyle.fontWeight = this.DefaultFontWeight;

        if (this.FieldModel.FontStyles) {
            _.forEach(this.FieldModel.FontStyles, (style) => {

                switch (style.Name.toLowerCase()) {
                    case FONT_STYLES.BOLD:
                        labelStyle.fontWeight = FONT_STYLES.BOLD;
                        break;
                    case FONT_STYLES.UNDERLINE:
                        labelStyle.textDecoration = FONT_STYLES.UNDERLINE;
                        break;
                    case FONT_STYLES.ITALIC:
                        labelStyle.fontStyle = FONT_STYLES.ITALIC;
                        break;
                }
            });
        }

        this.Extend(labelStyle, this._labelStyle());
        this._labelStyle(labelStyle);
    }

    private Init(): void {
        if (this.IsRunTime && this.Screen) {
            this.Screen.On(SCREEN_EVENTS.DATA_CHANGED, this, (eventArgs) => {
                let screenData: IScreenVariable = eventArgs.data.ScreenVariable;
                var screenVariableCondition = ConditionBuilder.GetScreenVariableCondition(screenData, this._conditions);
                if (screenVariableCondition.length > 0) {
                    this.SetConditionScreenData(screenVariableCondition, screenData.Value);
                    if (screenData.Field.FieldTypeName === FIELD_TYPES.Text) {
                        this._searchTerm = screenData.Value;
                    }
                    this.LoadData();                        
                }

                if (screenData && this.FieldModel.FilterByField != 0 && this.HasDynamicField(screenData.Field.Id)) {
                    this.LoadData();
                }

                if (screenData && this.FieldModel.FilledById != 0 && this.HasDynamicField(screenData.Field.Id)) {
                    this.UpdateDependsOnValue();
                }
            });
        }

        this.InitChangeEvent();
    }

    SetValue(value: IControlValue): void {
        if (value.Data) {
            this._value = value.Data.Value === null && value.Data.DisplayValue ? -1 : +value.Data.Value;
            this._originalValue  = this._value;
            this._displayValue = this.FormatValue(value.Data.LabelOriginalValue, value.Data.DisplayValue, value.Data.ValFieldTypeName);

            let dropDownItemModel = new DropdownItemModel({
                RecordId: this._value,
                Label: this._displayValue
            });


            if (!this.IsEditMode) {
                this.PreselectOption(dropDownItemModel);
            } else if (this._value === -1) {
                this._selectedItem(dropDownItemModel);
            }
        }

        this._isEnableFilter(!this.HasValue || !this._showConditionToggle());

        this._subjectRecordId = value.SubjectRecordId;
        this._subjectRecordSpecsModel = value.RecordSpecsModel;

        if (this.IsEditMode) {
            this.LoadData();
        }
    }

    get HasValue(): boolean{
        return this._value != 0 && this._value != -1;
    }

    ShowOptionCreate(data, esc) {
        return `<div class="create">${this._label.ADD}&nbsp;<strong>&nbsp;${esc(data.input)}&nbsp;</strong>&hellip;</div>`;
    }

    InitChangeEvent() {
        this._selectedItem.subscribe((newValue) => {
            var value = newValue ? String(newValue.RecordId) : null;
            this._value = newValue?.RecordId;
            this.UpdateVariable({ Field: this.GetFieldModel(), ControlType: CONTROL_TYPES.Dropdown }, value);
        });
    }

    ResetSelection() {
        this._isReady(false);
		this._isCustomValueEntered(false);
		this.RemoveCustomOption();
        const recordId = 0;

        this._selectedItem(null);
        this._selectedValueId(null);
        this._selectedValueLabel(null);
        this._value = null;

        this.GetForm().GetScreen().UpdateDependsOnValues(recordId, this.FieldModel.Id);
        this.Trigger(EVENTS.DROPDOWN_VALUE_SELECTED, {recordId: recordId});
        this._isReady(true);

        this._select = this._el.parentElement.querySelector("select.dropdownselect");
        this._select && this._select.dispatchEvent(new Event("resizeTable", {
            bubbles: true,
            cancelable: true
        }));
    }

    get ShowAddButton(): boolean {
        return this.FieldModel.ValTableType !== TABLE_TYPES.Sub && (this.FieldModel.ValTableType !== TABLE_TYPES.Lookup || this.FieldModel.AllowCreatingRecords) && !this.IsCdTable(this.FieldModel.ValTableName) && this.FieldModel.AllowInsert;
    }

    get HasFilter(){
        return this.FieldModel.HasFilter || this._conditions?.HasAnyConditions;
    }

    ShowSearchScreen() {
        let attachedFieldModel = this.FieldModel;
        if (attachedFieldModel) {
            let params: ISearchScreenParam = {
                DropDownFilterState: this._isEnableFilter(),
                HasLinkingCondition: this.HasFilter,
                ConditionToggler: this.HasFilter,
                EntityId: attachedFieldModel.ValTableId,
                SearchTerm: this._searchTerm,
                EntityType: attachedFieldModel.ValTableType,
                SearchFieldId: attachedFieldModel.ValFieldId,
                ControlId: this.GetControlId(),
                FieldId: this.FieldModel.Id,
                ScreenData: this.GetDynamicFieldsData(),
                ConditionValues: this.MapConditionValues(),
                ButtonAdd: this.ShowAddButton
            };

            if (this._form &&
                (this.FieldModel.ValTableType === TABLE_TYPES.Entity || this.FieldModel.ValTableType === TABLE_TYPES.Lookup || this.FieldModel.ValTableType === TABLE_TYPES.Sub)
            ) {
                params.SubjectEntityId = this.GetForm().GetScreen().GetEntityId();
                params.SubjectRecordId = this.GetForm().GetScreen().GetRecordId();
            }

            if (this.IsLinkEditor) {
                const linkEditor = this.Screen as any;

                params.LeftEntityId = linkEditor.GetEntityId();
                params.RightEntityId = linkEditor.GetRelatedEntityId();
                params.LeftRecordId = linkEditor.GetRecordId();
                params.RightRecordId = linkEditor.GetRelatedRecordId();
                params.KSeq = linkEditor.KSeq;
            }

            this._searchScreen = new SearchScreen(params);
            this._searchScreen.On('RECORD_SELECTED', this, (evtArgs) => this.ReplaceSelectedValue(evtArgs.data.RecordId));
            this._searchScreen.On('NEW_RECORD', this, () => this.NewTypeScreen());
            this._searchScreen.Show();
        }
    }

    /**
     * dispatch event to parents
     * @param $drDown {[Element]} -> jQuery element
     */
    OnDropDownOpen = ($drDown) => {
        $drDown[0].dispatchEvent(new Event("resizeTable", {
            bubbles: true,
            cancelable: true
        }));
    };

    OnInitialize = () => {
        setTimeout(() => {
            this._select && this._select.dispatchEvent(new Event("resizeTable", {
                bubbles: true,
                cancelable: true
            }));
        }, 150);
    };

    /**
     * dispatch event to parents
     * @param $drDown {[Element]} -> jQuery element
     */
    OnDropDownClose = ($drDown) => {
        $drDown[0].dispatchEvent(new Event("onDropDownClose", {
            bubbles: true,
            cancelable: true
        }));
    };

    OnSelectControl(recordId) {
        recordId = +recordId;

        if (recordId !== -1) {
            this.RemoveCustomOption();
        }

        const selectedItem = _.find(this._items(), item => item.RecordId === recordId);
        this._selectedValueLabel(!!selectedItem ? selectedItem.Label : null);
        this.GetForm() && this.GetForm().GetScreen().UpdateDependsOnValues(recordId, this.FieldModel.Id, this._isPreselect());
        this.Trigger('DROPDOWN_VALUE_SELECTED', {recordId: recordId === -1 ? null : recordId});
        this._select && this._select.dispatchEvent(new Event("resizeTable", {
            bubbles: true,
            cancelable: true
        }));
    }

    OnCustomSearchFocused() {
        if (this._selectedValueLabel()) {
            const initialLabel = this._selectedValueLabel();
            this._initialValueLabel(initialLabel);
            this._customPlaceholder(initialLabel);
        }

        this._selectedValueLabel(null);
    }

    OnCustomSearchBlur() {
        if (!this._selectedValueLabel()) {
            this._selectedValueLabel(this._initialValueLabel());
        }

        this._initialValueLabel(null);
        this._customPlaceholder(this._defaultPlaceholder);
    }

    OnCustomValueEntered(element, event) {
        if (event.target.value) {
            this._isCustomValueEntered(true);
        } else {
            this._isCustomValueEntered(false);
        }
    }

    AddCustomValue(text: string) {
        this.RemoveCustomOption();

        const newCustomItem = this.AddCustomOption(text);

        this._selectedItem(newCustomItem);
        this._selectedValueId(newCustomItem.RecordId);
        this._selectedValueLabel(newCustomItem.Label);

        return {text: text, value: -1};
    }

    GetSelectedItem() {
        return this._selectedItem();
    }

    private PreselectOption(value: DropdownItemModel) {
        const optionFromList = _.find(this._items(), item => item.RecordId === value.RecordId);

        if (!optionFromList) {
            this._items.push(value);
        }

        this._isPreselect(true);

        this._selectedItem(value);
        this._selectedValueId(value.RecordId);
        this._selectedValueLabel(value.Label ? value.Label : LABELS.EMPTY_VALUE);

        this._isPreselect(false);
    }

    private RemoveCustomOption() {
        const oldCustomValueIndex = _.findIndex(this._items(), item => item.RecordId === -1);

        if (oldCustomValueIndex !== -1) {
            this._items.splice(oldCustomValueIndex, 1);
        }
    }

    private AddCustomOption(text) {
        const newCustomItem = new DropdownItemModel();

        newCustomItem.RecordId = -1;
        newCustomItem.Label = text;

        this._items.push(newCustomItem);
        return newCustomItem;
    }

    private ReplaceSelectedValue(recordId: number) {
        const valFieldId = this.FieldModel.ValFieldId;
        BlockUI.Block();

        RecordStore.GetRecord({TableId: this.FieldModel.ValTableId, RecordId: recordId})
            .always(() => BlockUI.Unblock())
            .then((record: any) => {
                this._isCustomValueEntered(false);

                if (this._showSearchScreenButton()) {
                    this._items([]);
                    this._selectedItem(null);
                    this._selectedValueId(null);
                    this._selectedValueLabel(null);
                }

                const selected = _.find(record.Fields, (item: any) => item.FieldId === valFieldId);

                if (selected) {
                    this.RemoveCustomOption();

                    selected.FieldValue = this.FormatValue(selected.FieldValue, selected.FieldValue, selected.FieldTypeName);
                    const itemTranslation = _.find(selected.FieldValueTranslations, (item: any) => item.Selected);
                    selected.FieldValue = itemTranslation && itemTranslation.Value ? itemTranslation.Value : selected.FieldValue;

                    const newItem = DropdownItemModel.Create(recordId, selected.FieldValue ? selected.FieldValue : LABELS.EMPTY_VALUE, true, true);
                    this._items.push(newItem);
                    this._selectedItem(newItem);
                    this._selectedValueId(newItem.RecordId);
                    this._selectedValueLabel(newItem.Label);

                    this._form && this.GetForm().GetScreen().UpdateDependsOnValues(recordId, this.FieldModel.Id);
                    this.Trigger('DROPDOWN_VALUE_SELECTED', {recordId: recordId});
                }
            })
            .fail((error) => {
                if (error.requestError === SERVER_REQUEST_ERRORS.NOT_FOUND) {
                    const newItem = DropdownItemModel.Create(recordId, NOTIFICATIONS.RECORD_NOT_FOUND, true, true);
                    this._selectedItem(newItem);
                    this._selectedValueId(newItem.RecordId);
                    this._selectedValueLabel(newItem.Label);
                } else if (error.requestError === SERVER_REQUEST_ERRORS.INTERNAL) {
                    Notifier.Failed('Error applying created record');
                }
            });
    }

    private FormatValue(value: string, displayValue: string, type: string) {
        let result = displayValue;
        if (this.IsDate(type)) {
            const dateTimeFormat = FormatConverter.GetDateFormatFromFieldModel(
                {Type: type, FormatName: this.FieldModel.ValFieldFormatName},
                true
            );

            if (this.HasTime(type))
                value = FormatConverter.CorrectTimezone(value);

            result = moment(value).isValid() ? moment(value).format(dateTimeFormat) : value;
        } else if (_.contains([FIELD_TYPES.Decimal, FIELD_TYPES.Integer], type)) {
            result = FormatConverter.LocalizeDecimalOrInteger(
                displayValue && this.FieldModel.ValFieldFormatName === 'Percentage'
                    ? (Number(displayValue.replace(/[.,]/g, '.')) * 100).toFixed(
                    this.FieldModel.ValFieldSize < 2 ? 0 : this.FieldModel.ValFieldSize - 2
                    )
                    : displayValue
            );
        }

        return result ? result : LABELS.EMPTY_VALUE;
    }

    private IsDate(fieldType: string) {
        return fieldType === FIELD_TYPES.Date ||
            fieldType === FIELD_TYPES.DateTime ||
            fieldType === FIELD_TYPES.Time ||
            fieldType === FIELD_TYPES.TimeSpan;
    }

    private HasTime(fieldType: string) {
        return fieldType === FIELD_TYPES.DateTime ||
            fieldType === FIELD_TYPES.Time;
    }

    private NewTypeScreen() {
        const attachedFieldModel = this.FieldModel;
        const typeScreen = new TypeScreen(attachedFieldModel.ValTableId);

        typeScreen.On('TYPES_NOT_FOUND', this, (eventArgs) => Notifier.Warning(eventArgs.data.Message || NOTIFICATIONS.TYPES_NOT_FOUND));
        typeScreen.On('TYPE_SELECTED', this, (eventArgs) => {
            const typeId = eventArgs.data.TypeId;
            const kindId = eventArgs.data.KindId;
            const exampleRecordId = eventArgs.data.ExampleRecordId;

            if (this._searchScreen) {
                this._searchScreen.Cancel();
            }

            this.NewRecord(typeId, kindId, exampleRecordId, attachedFieldModel.ValTableId, attachedFieldModel.AllowCreatingRecords);
        });

        typeScreen.Show();
    }

    private async NewRecord(tableTypeId: number, kindId: number, exampleRecordId: number, entityId: number, allowCreatingRecords: boolean) {
        if (allowCreatingRecords) {
            this.NewLookupRecord(entityId);
        } else {
            this.NewEntityRecord(tableTypeId, kindId, exampleRecordId, entityId);
        }
    }

    private NewLookupRecord(valTableId: number) {
        if (!valTableId) {
            return;
        }

        BlockUI.Block();
        TablesStore.GetLookup(valTableId)
            .then(async result => {
                if (!result.IsSuccessfull) {
                    BlockUI.Unblock();
                    Notifier.Warning(result.ErrorMessage);
                    return;
                }

                (await import("LookupEditor/NewRecord/NewRecordScreen")).NewRecordScreen
                .GenerateScreen(GenericDeserialize(result.ResultObject, LookupModel))
                    .always(() => {
                        BlockUI.Unblock();
                    })
                    .then(newLookupRecordScreen => {

                        newLookupRecordScreen.ShowInModal('newLookupRecord');

                        newLookupRecordScreen.On('SAVE_DATA', this, eventArgs => this.AddLookupRecord(eventArgs.data));

                        this._newLookupRecordScreen = newLookupRecordScreen;
                    });
            }).fail(error => {
                BlockUI.Unblock();
                Notifier.Failed(error.message);
            });
    }

    private AddLookupRecord(newRecordModel: NewRecordModel) {
        BlockUI.Block();
        LookupEditorStore.AddRecord(newRecordModel)
            .always(() => {
                BlockUI.Unblock();
            })
            .then((result) => {
                this._newLookupRecordScreen.Close();
                this._newLookupRecordScreen = null;

                const recordId = result;

                Notifier.Success(NOTIFICATIONS.RECORD_CREATED);

                this.ReplaceSelectedValue(recordId);
            }).fail(error => Notifier.Failed(error.message));
    }

    private async NewEntityRecord(tableTypeId: number, kindId: number, exampleRecordId: number, entityId: number) {
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
        screenManager.GetEditScreen({
            EntityId: entityId,
            TableTypeId: tableTypeId,
            KindId: kindId,
            RecordId: exampleRecordId,
            LoadAsExample: true
        })
            .then((screen: EditScreen) => {
                if (!screen) {
                    new Notifier($(this._el)).Warning(NOTIFICATIONS.EDIT_SCREEN_NOT_EXISTS);
                    return;
                }

                const editScreen = screen;

                editScreen.IsDataFromExample = exampleRecordId > 0;
                editScreen.UseLinking = true;

                screen.On('RECORD_SAVED', this, (eventArgs) => {
                    const recordId = eventArgs.data.RecordId;

                    Notifier.Success(NOTIFICATIONS.RECORD_CREATED);

                    this.ReplaceSelectedValue(recordId);
                });

                screen.ShowInModal();
            });
    }


    LoadData() {
        let attachedField = this.FieldModel;
        let getDataRequestModel: IGetDropdownControlDataRequestModel = {
            FieldId: attachedField.Id,
            RecordId: this._value,
            SubjectRecordId: this._subjectRecordId,
            SubjectTypeId: this._form && this._form.GetScreen().GetTableTypeId(),
            SubjectKindId: this._form && this._form.GetScreen().GetKindId(),
            SubjectStatusId: this._subjectRecordSpecsModel && this._subjectRecordSpecsModel.LifeStatusInfo ? this._subjectRecordSpecsModel.LifeStatusInfo.Id : 0,
            ScreenData: this._form && this.GetDynamicFieldsData(),
            ControlId: this.GetControlId(),
            IsEnableFilter: this._isEnableFilter(),
            ConditionValues: this.MapConditionValues()
        };

        const screen = this._form && this.GetForm().GetScreen();

        if (screen && screen.GetType() === ScreenTypes[ScreenTypes.LinkEditor]) {
            const linkEditor = screen as any;

            getDataRequestModel.LeftEntityId = linkEditor.GetEntityId();
            getDataRequestModel.RightEntityId = linkEditor.GetRelatedEntityId();
            getDataRequestModel.LeftRecordId = linkEditor.GetRecordId();
            getDataRequestModel.RightRecordId = linkEditor.GetRelatedRecordId();
            getDataRequestModel.KSeq = linkEditor.GetKseq();
        }

        this._clearAllowed(!(attachedField.IsRequired || attachedField.IsReadOnly || attachedField.IsSystem));

        this._isDataLoading(true);

        if (this.GetFieldModel().Name === TYPE_FIELD_NAME && !(this._form && this._form.GetScreen().IsLookupEditorNewRecord)) {
            const fieldEntityId = this.GetFieldModel().EntityId;
            const parentTypeId = this._form ?
                this.GetForm().GetScreen().GetEntityId() === fieldEntityId
                ? this._form.GetScreen().GetTableTypeId()
                : undefined
                : undefined;

               
            EntityTypesStore.GetTypes({
                EntityId: this.GetFieldModel().EntityId,
                EntityName: this.GetFieldModel().EntityName,
                ParentTypeId: parentTypeId,
                WithRoot: true, OnlyEnabled: true
            })
            .always(() => {
                this._isDataLoading(false);
            })
                .then((result) => {
                    this._items([]);
                    var selectedItem;
                    _.each(result.TableTypes,
                        (item) => {
                            const label = item.TranslatedName ? item.TranslatedName : item.Name;

                            var dropdownItem = new DropdownItemModel();
                            dropdownItem.RecordId = item.Id;
                            dropdownItem.Label = label ? label : LABELS.EMPTY_VALUE;
                            dropdownItem.IsEnabled = item.IsEnabled;
                            this._items.push(dropdownItem);
                        });

                    selectedItem = _.find(this._items(), item => item.RecordId === this._value);
                    if (selectedItem && selectedItem.IsEnabled) {
                        this.PreselectOption(selectedItem);
                    }else{
                        selectedItem =  this._autoSelectIfOneRecord() &&
                                        this._items().length === 1 &&
                                        this._items()[0];

                        this.PreselectOption(selectedItem);
                    }
                });
        } else {
            DropdownDataStore.Get(getDataRequestModel)
                .always(() => {
                    this._isDataLoading(false);
                })
                .then(data => {

					const isDisableDropdown = data.Items.length === 0 && !this.GetFieldModel().AllowCustomValue;
                    this._isDisableDropDown(isDisableDropdown);

                    _.each(data.Items, (item) => {
                        item.Label = this.FormatValue(item.Value, item.Label, item.Type);
                    });

                    this._items(data.Items);
                    this._totalRecordsCount = data.RecordsCount;
                    this._showSearchScreenButton(this._totalRecordsCount > this._searchScreenIfRecordsCount);
                    const selectedItem = this._value === -1
                        ? this._selectedItem()
                        : _.find(data.Items, item => item.RecordId === this._value);

                    if (selectedItem) {
                        this.PreselectOption(selectedItem);
                    } else {
                        const selectedItem = this._autoSelectIfOneRecord() &&
                            !this._showSearchScreenButton() &&
                            data.Items.length === 1 &&
                            data.Items[0];

                        if (selectedItem) {
                            this.PreselectOption(selectedItem);
                        } else if (this._value) {
                            let item = new DropdownItemModel();
                            item.Label = NOTIFICATIONS.RECORD_NOT_FOUND;
                            item.RecordId = this._value;

                            this.PreselectOption(item);
                        }
                    }

                    if (data.ErrorMessage) {
                        Notifier.Failed(NOTIFICATIONS.ERROR_GETTING_DROPDOWN_DATA.replace('{fieldName}', this.FieldModel.Name));
                    }
                }).fail(error => {
                    Notifier.Failed(NOTIFICATIONS.ERROR_GETTING_DROPDOWN_DATA.replace('{fieldName}', this.FieldModel.Name));
            });
        }
    }

    CheckCreateRecordAllowance(){
        if(!this.IsEditMode){
            return;
        }

        let field = this.GetFieldModel();
        if(field && field.ValTableId){
            TableSecurityStore
            .IsUserAllowedTo(
                {
                    TableId: this.GetFieldModel().ValTableId,
                    SecurityWord: TABLE_SECURITY_WORDS.CREATE
                }).then((result)=>{
                    this._allowInsert(result && (field.ValTableType === TABLE_TYPES.Entity || field.AllowCreatingRecords) && !this.IsCdTable(field.ValTableName) && !field.IsReadOnly && !field.IsSystem);
                });
        }
    }

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);
        this._select = el[0].parentElement.querySelector("select.dropdownselect");
    }

    SetOptionDisable(option, item) {
        if (!item) {
            ko.applyBindingsToNode(option, {disable: ko.observable(true)}, item);
        } else {
            ko.applyBindingsToNode(option, {disable: !item.IsEnabled && this.GetFieldModel().Name === TYPE_FIELD_NAME ? ko.observable(true) : ko.observable(false)}, item);
        }
    }

    Deserialize() {
        var field = _.first(this._model().Fields);
        var item = this._selectedItem();
        if (field && item) {
            if (!this._isReadonly) {
                const id = +this._selectedValueId() === -1 ? null : this._selectedValueId();
                const customValue = this.FieldModel.AllowCustomValue && this._selectedItem() && this._selectedItem().Label;
                return [`${field.EntityName}.${field.Name}`, id, customValue];
            } else {
                return [`${field.EntityName}.${field.Name}`, null, null];
            }
        } else {
            return [`${field.EntityName}.${field.Name}`, null, null];
        }
    }

    IsModified(): boolean {
        if (super.IsModified()) return true;

        if (this._selectedItem()) {
            return this._originalValue !== this._selectedItem().RecordId || this._displayValue !== this._selectedValueLabel();
        }

        return !!this._originalValue;
    }

    IsValid(): boolean {
        const isTypeField = this.GetFieldModel().Name === TYPE_FIELD_NAME;
        const isNotForNewRecordScreen = !(this._form && this._form.GetScreen().IsLookupEditorNewRecord);

        if (this._isRequired && !this._selectedItem()) {
            this._isValid(false);
        } else if (isTypeField && isNotForNewRecordScreen) {
            const typeSelected = !!this._selectedItem();
            this._isValid(typeSelected);
        } else {
            this._isValid(true);
        }
        return this._isValid();
    }

    GetValue() {
        if (this._selectedItem()) {
            return this._selectedItem().RecordId === -1 || isNaN(this._selectedItem().RecordId) ? null : this._selectedItem().RecordId;
        }

        return null;
    }

    GetDisplayValue(): string {
        if (this._selectedItem()) {
            return this._selectedItem().Label;
        }
        return null;
    }

    Dispose() {
        super.Dispose();
        if (this._searchScreen) {
            this._searchScreen.Close();
        }
    }

    DisableFilter() {
        this._isEnableFilter(!this._isEnableFilter());
        this.LoadData()
    }

    private UpdateDependsOnValue() {

        this.CalculateDependOnValue()
        .then(dependsOnValue=>{
            let val = Number(dependsOnValue);
            if(!isNaN(val)){
                this._value = val;
                this.LoadData();
            }
        });        
    }

    private IsCdTable(tableName: string) {
        return tableName && tableName.startsWith('CD_');
    }
}