import * as ko from 'knockout';
import * as _ from 'underscore';

import {BlockUI} from 'Core/Common/BlockUi';

import {IControl} from 'Core/Controls/IControl';
import {Modal} from 'Core/Common/Modal';
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel';
import {BaseControlEditor, CONTROL_DEFAULT_NAME} from "./BaseControlEditor";

import {EntitiesStore} from 'Core/Screens/DesignScreen/ControlEditor/Stores/EntitiesStore';
import {BlobFieldsStore} from 'Core/Screens/DesignScreen/ControlEditor/Stores/BlobFieldsStore';
import {LookupFieldsStore} from 'Core/Screens/DesignScreen/ControlEditor/Stores/LookupFieldsStore';
import {EntityModel} from 'Core/Screens/DesignScreen/ControlEditor/Models/EntityModel';
import {EntityTypeModel} from 'Core/Screens/DesignScreen/ControlEditor/Models/EntityTypeModel';
import {FieldModel} from 'Core/Screens/DesignScreen/ControlEditor/Models/FieldModel';
import {LabelPositions} from 'Core/Screens/DesignScreen/ControlEditor/Enums/LabelPositions';
import {ControlEditorModel} from 'Core/Screens/DesignScreen/ControlEditor/Models/ControlEditorModel';
import {Notifier} from 'Core/Common/Notifier';
import {Event} from 'Core/Common/Event';
import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel';
import {TabPage} from 'Core/Controls/TabPage/TabPage';
import {DesignScreen} from 'Core/Screens/DesignScreen/DesignScreen';
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';
import {TranslationModel} from 'Core/Controls/BaseControl/Models/TranslationModel';
import {IconManager} from 'Core/Components/IconManager/IconManager'
import {TABLE_TYPES, CONTROL_TYPES, FIELD_TYPES} from 'Core/Constant';

import Template from 'Core/Screens/DesignScreen/ControlEditor/Templates/ControlEditor.html'

ko.templates['Core/Screens/DesignScreen/ControlEditor/Templates/ControlEditor'] = Template;

import {LanguageModel} from "Core/Controls/BaseControl/Models/LanguageModel";
import {ComplexControl} from 'Core/Controls/ComplexControl/ComplexControl';
import {IconModel} from "Core/Controls/BaseControl/Models/IconModel";
import {NOTIFICATIONS, LABELS} from "Core/Components/Translation/Locales";
import {TranslationFieldEditor} from "Core/Components/TranslationFieldEditor/TranslationFieldEditor";
import {TranslationManager} from "Core/Components/Translation/TranslationManager";
import {Drop} from "Core/Controls/Drop/Drop";
import {TimeWriting} from "Core/Controls/TimeWriting/TimeWriting";
import {MobileChecker} from 'Core/Common/MobileChecker';
import {BlobFieldModel} from '../Models/BlobFieldModel';
import { ButtonTemplate } from 'Core/Controls/ButtonTemplate/ButtonTemplate';

export interface ILabelPosition {
    Value: number;
    Title: string;
}

var DEFAULT_CONTROL_NAME = "Default name";

export class ControlEditor extends Event {
    private _control: IControl;
    private _editControl: IControl;
    private _modal: Modal;
    private _controlName: KnockoutObservable<string>;
    private _controlTypeName: KnockoutObservable<string>;
    private _editControlModel: ControlModel;
    private _controlId: number;
    private _controlGuid: string;
    private _subjectEntityId: number;
    private _subjectSubEntityId: number;
    private _tableTypeId: number;
    private _entities: KnockoutObservableArray<EntityModel>;
    private _entityTypes: KnockoutObservableArray<EntityTypeModel>;
    private _subEntities: KnockoutObservableArray<EntityModel>;
    private _fields: KnockoutObservableArray<FieldModel>;
    private _selectedEntity: KnockoutObservable<EntityModel>;
    private _selectedEntityType: KnockoutObservable<EntityTypeModel>;
    private _selectedSubEntity: KnockoutObservable<EntityModel>;
    private _selectedField: KnockoutObservable<FieldModel>;
    private _labelPositions: KnockoutObservableArray<ILabelPosition>;
    private _selectedLabelPosition: KnockoutObservable<ILabelPosition>;
    private _controlEditorModel: ControlEditorModel;
    private _showEntityList: KnockoutObservable<boolean>;
    private _showEntityTypesList: KnockoutObservable<boolean>;
    private _showSubEntityList: KnockoutObservable<boolean>;
    private _allowSelectField: KnockoutObservable<boolean>;
    private _currentControlName: KnockoutObservable<TranslationModel>;
    private _controlNameTranslations: KnockoutObservableArray<TranslationModel>;
    private _currentControlDescription: KnockoutObservable<TranslationModel>;
    private _controlDescriptionTranslations: KnockoutObservableArray<TranslationModel>;

	private _blobFields: KnockoutObservableArray<BlobFieldModel>;
    private _useFieldName: KnockoutObservable<boolean>;

    private _instantaneousValue: KnockoutObservable<string>;
    private _isControlNameHasFocus: KnockoutObservable<boolean>;
    private _showLanguageList: KnockoutObservable<boolean>;
    private _showDescriptionsLanguageList: KnockoutObservable<boolean>;
    private _selectFieldSubscription: KnockoutSubscription;
    private _labels = LABELS;
    private _icon: KnockoutObservable<IconModel>;
    private _iconModel = IconModel;
    private _isValid: KnockoutObservable<boolean>;
    private _translationFieldEditor: TranslationFieldEditor;
    private _defaultEntityType: EntityTypeModel;

    private IsValidName: KnockoutObservable<boolean>;
    private ValidationMsgForName: KnockoutObservable<string>;

    constructor(control: IControl) {
        super();
        this._modal = new Modal();
        this._control = control;
        this._icon = ko.observable(control.GetIcon());

        this._editControl = control.Clone();
        this._editControlModel = this._editControl.GetModel();
        this._editControlModel.Id = control.GetControlId();
        this._controlName = ko.observable(this._editControlModel.Name);
		this._selectFieldSubscription = null;

		this._blobFields = ko.observableArray(null);
        this._useFieldName = ko.observable(this._editControlModel.UseFieldName);
		this._useFieldName.subscribe((newValue) => {
	        if (newValue) {
		        this.UseFieldNameForControlName();
	        }
        });

        const defaultName = new TranslationModel();
        defaultName.Language = new LanguageModel(-1);
        defaultName.Translation = this._editControlModel.Name || CONTROL_DEFAULT_NAME;
        this._editControlModel.NameTranslations.unshift(defaultName);

        const defaultTranslation = new TranslationModel();
        defaultTranslation.Language = new LanguageModel(-1);
        defaultTranslation.Translation = this._editControlModel.Description;
        this._editControlModel.DescriptionTranslations.unshift(defaultTranslation);

        this._controlNameTranslations = ko.observableArray(this._editControlModel.NameTranslations);
        this._controlDescriptionTranslations = ko.observableArray(this._editControlModel.DescriptionTranslations);

        this._defaultEntityType = new EntityTypeModel();
        this._defaultEntityType.Name = this._labels.ALL;

        this._subjectEntityId = this._editControl.GetForm().GetScreen().GetEntityId();
        this._tableTypeId = this._editControl.GetForm().GetScreen().GetTableTypeId();
        this._entities = ko.observableArray([]);
        this._entityTypes = ko.observableArray([this._defaultEntityType]);
        this._subEntities = ko.observableArray([]);
        this._fields = ko.observableArray([]);
        this._selectedEntity = ko.observable(null);
        this._selectedEntityType = ko.observable(this._defaultEntityType);
        this._selectedSubEntity = ko.observable(null);
        this._selectedField = ko.observable(null);
        this._controlEditorModel = new ControlEditorModel();
        this._showEntityList = ko.observable(true);
        this._showEntityTypesList = ko.observable(false);
        this._showSubEntityList = ko.observable(false);
        this._allowSelectField = ko.observable(true);
        this._isControlNameHasFocus = ko.observable(true);
        this._showLanguageList = ko.observable(false);
        this._showDescriptionsLanguageList = ko.observable(false);
        this._isValid = ko.observable(true);

        this._labelPositions = ko.observableArray([
            {Value: LabelPositions.NoLabel, Title: LabelPositions[LabelPositions.NoLabel]},
            {Value: LabelPositions.Left, Title: LabelPositions[LabelPositions.Left]},
            {Value: LabelPositions.UpperLeft, Title: LabelPositions[LabelPositions.UpperLeft]},
            {Value: LabelPositions.UpperMiddle, Title: LabelPositions[LabelPositions.UpperMiddle]},
            {Value: LabelPositions.UpperRight, Title: LabelPositions[LabelPositions.UpperRight]},
            {Value: LabelPositions.Right, Title: LabelPositions[LabelPositions.Right]},
            {Value: LabelPositions.LeftColon, Title: LabelPositions[LabelPositions.LeftColon]}
        ]);

        var uniqFieldsByEntityType = _.uniq(this._editControl.GetModel().Fields, false, field => field.EntityTypeId);

        _.forEach(uniqFieldsByEntityType,
            field => {
                if (field.EntityTypeName === TABLE_TYPES.Entity) {
                    this._subjectEntityId = field.EntityId;
                    this._tableTypeId = 0;
                }

                if (field.EntityTypeName === TABLE_TYPES.Sub) {
                    this._subjectSubEntityId = field.EntityId;
                }
            });

        var selectedLabelPosition = _.find(this._labelPositions(), item => {
            return item.Value === LabelPositions[this._editControlModel.LabelPosition]
        });
        this._selectedLabelPosition = ko.observable(selectedLabelPosition);

        this._selectedLabelPosition.subscribe(newValue => {
            this._editControlModel.LabelPosition = LabelPositions[newValue.Value];
        });

        this._selectedEntity.subscribe(newValue => {
            if (newValue) {
                let editControl = this._editControl;
                if (editControl instanceof ComplexControl) {
                    editControl.AutoSelectFields([newValue]);
                    this._fields([]);

                    if (this._editControl instanceof Drop) {
                        this._fields(_.filter(newValue.Fields, (field) => {
                            return field.FieldTypeName === FIELD_TYPES.Document;
                        }));
                        this._entityTypes(_.union([this._defaultEntityType], newValue.EntityTypes));
                        this._selectedEntityType(this._defaultEntityType);
                    }

                    BlockUI.Block();
                    EntitiesStore.GetSubEntities({EntityId: newValue.EntityId, TableTypeId: this._tableTypeId})
                        .always(() => {
                            BlockUI.Unblock();
                        })
                        .then(subEntities => {
                            if (this._editControl instanceof Drop) {
                                let documentFields = [];
                                _.each(subEntities, (subEntity) => {
                                    documentFields = documentFields.concat(
                                        _.filter(subEntity.Fields, (field) => {
                                            field.FullName = `${subEntity.EntityName}, ${field.FieldName}`;
                                            return field.FieldTypeName === FIELD_TYPES.Document;
                                        }));
                                });

                                this._fields(this._fields().concat(documentFields));
                                this._fields(_.uniq(this._fields(), false, field => field.FieldId));
                                this.SetCurrentField();
                            } else {
                                this._subEntities(subEntities);
                                let selectedSubEntity = _.find(subEntities, subEntity => {
                                    return subEntity.EntityId === this._subjectSubEntityId
                                });

                                if (selectedSubEntity) {
                                    this._selectedSubEntity(selectedSubEntity);
                                }
                            }
                        });
                } else if (this._editControlModel.IsLookupFieldAttached) {
                    this.LoadLookupFieldsForEntity(newValue);
                } else {
                    this._fields(newValue.Fields);
                }

                var generalProperties = this._editControl.GeneralProperties;
                if (generalProperties && this._editControl instanceof ButtonTemplate) {
                    generalProperties.ResetProperty('TemplateType');
                }
            }
        });

        this._selectedSubEntity.subscribe(newValue => {
            if (newValue) {
                let editControl = this._editControl;
                if (editControl instanceof ComplexControl) {
                    const selectedEntity = this.SelectedEntity();
                    editControl.AutoSelectFields([selectedEntity, newValue]);

                    if (editControl instanceof TimeWriting) {
                        const nameField = selectedEntity.Fields.find(field => field.FieldName === 'NAME');

                        if (nameField) {
                            const attachedFieldModel = new AttachedFieldModel();
                            attachedFieldModel.Id = nameField.FieldId;
                            attachedFieldModel.Name = nameField.FieldName;
                            attachedFieldModel.EntityName = selectedEntity.EntityName;
                            attachedFieldModel.EntityId = selectedEntity.EntityId;
                            attachedFieldModel.EntityTypeName = selectedEntity.TypeName;
                            attachedFieldModel.EntityTypeId = selectedEntity.TypeId;
                            attachedFieldModel.Sort = this._editControlModel.Fields.length * 10;
                            attachedFieldModel.FieldTypeName = nameField.FieldTypeName;
                            this._editControlModel.Fields.push(attachedFieldModel);
                        }
                    }
                }
            }
        });

        this._currentControlName = ko.observable(null);
        this._currentControlDescription = ko.observable(null);

        this.IsValidName = ko.observable(true);
        this.ValidationMsgForName = ko.observable(LABELS.THIS_FIELD_IS_REQUIRED);
    }

    LoadLookupFieldsForEntity(entity: EntityModel) {
        var requestModel = {
            ContextEntityId: entity.EntityId
        };
        LookupFieldsStore.GetLookup(requestModel)
            .always(() => {
                BlockUI.Unblock();
            })
            .then(data => {
                var fields = [];
                _.each(data, (item) => {
                    var field = new FieldModel();
                    field.FieldId = item.PrimaryTableLookupFieldId;
                    field.FieldName = `${item.PrimaryTableLookupFieldName}`;
                    field.ValTableId = item.ValTableId;
                    field.ValFieldId = item.ValFieldId;
                    field.FieldTypeName = FIELD_TYPES.Lookup;
                    fields.push(field);
                });
                this._fields(fields);
                this.SetCurrentField();
            });
    }

    SetCurrent() {
        let translationsModel = _.find(this._editControlModel.NameTranslations, language => language.Selected && language.Translation !== '' && language.Translation !== null)
            || _.first(this._controlNameTranslations());

        this._currentControlName(translationsModel);
    }

    SetCurrentDescription() {
        let translationsModel = _.find(this._editControlModel.DescriptionTranslations, language => language.Selected && language.Translation !== '' && language.Translation !== null)
            || _.first(this._controlDescriptionTranslations());

        this._currentControlDescription(translationsModel);
    }

    SelectTranslation(translationModel: TranslationModel) {
        this._currentControlName(translationModel);
        this._isControlNameHasFocus(true);
        this._showLanguageList(false);
    }

    SelectDescriptionTranslation(translationModel: TranslationModel) {
        this._currentControlDescription(translationModel);
        this._showDescriptionsLanguageList(false);
    }

    UpdateTranslationList() {
        let languages = this._controlNameTranslations().slice(0);
        this._controlNameTranslations([]);
        this._controlNameTranslations(languages);
    }

    UpdateDescriptionTranslationList() {
        let languages = this._controlDescriptionTranslations().slice(0);
        this._controlDescriptionTranslations([]);
        this._controlDescriptionTranslations(languages);
    }

    ChangeTranslation() {
        let current = _.find(this._controlNameTranslations(), (item) => {
            return item.Language.K_Language === this._currentControlName().Language.K_Language;
        });
        this._currentControlName(current);
    }

    ChangeDescriptionTranslation() {
        let current = _.find(this._controlDescriptionTranslations(), (item) => {
            return item.Language.K_Language === this._currentControlDescription().Language.K_Language;
        });
        this._currentControlDescription(current);
    }

    ShowLanguageList(controlEditor) {
        this._showLanguageList(!this._showLanguageList());
        this.SetFocusItem(this._controlNameTranslations());
    }

    ShowDescriptionLanguageList(controlEditor) {
        this._showDescriptionsLanguageList(!this._showDescriptionsLanguageList());
        this.SetFocusItem(this._controlDescriptionTranslations());
    }

    ToggleUseFieldName() {
	    this._useFieldName(!this._useFieldName());
    }

    // Add to focus the first blank item Name and item Description
    SetFocusItem(ArrTranslate: Array<TranslationModel>) {
        let focusEmptyElement = _.find(ArrTranslate, item => {
            if (item.Translation === undefined) {
                return item.Translation === undefined;
            } else if (item.Translation === null) {
                return item.Translation === null;
            } else if (item.Translation === '') {
                return item.Translation === '';
            }
        });
        let isMobile = MobileChecker.IsMobile();
        focusEmptyElement && focusEmptyElement.IsFocusedItem(!isMobile);
    }

    LoadData() {
        if (this._editControlModel.IsBlobFieldAttached) {
            this._showEntityList(false);
            return this.LoadBlobControlData();
        }

        if (this._editControlModel.IsLookupFieldAttached) {
            this._showEntityList(true);
            return this.LoadLookupControlData();
        }

        if (this._editControlModel.IsMultiSelectFieldAttached) {
            this._showEntityList(false);
            return this.LoadMultiSelectControlData();
        }

        if (this._editControlModel.IsFieldAttached) {
            return this.LoadGeneralControlData();
        }

        if (this._editControlModel.IsComplexControl) {
            if (this._editControl instanceof Drop) {
                this._allowSelectField(true);
                this._showEntityTypesList(true);
                this._showSubEntityList(false);
            } else {
                this._allowSelectField(false);
                this._showEntityTypesList(false);
                this._showSubEntityList(true);
            }
            return this.LoadGeneralControlData();
        }

        BlockUI.Unblock();
        this._allowSelectField(false);
    }

    LoadLookupControlData() {
        return EntitiesStore.Get({EntityId: this._subjectEntityId, TableTypeId: this._tableTypeId})
            .always(() => BlockUI.Unblock())
            .then(entities => {
                this._entities(entities);

                /*var selectedEntity = _.find(entities, entity => { return entity.EntityId === this._subjectEntityId });

                if (!selectedEntity) {
                    selectedEntity = _.first(entities);
                }

                if (selectedEntity) {
                    this.LoadLookupFieldsForEntity(selectedEntity);
                }*/
            });
    }

    LoadMultiSelectControlData() {
        var requestModel = {
            ContextEntityId: this._editControl.GetForm().GetScreen().GetEntityId()
        };
        var fields = [];
        return LookupFieldsStore.GetMultiSelect(requestModel)
            .always(() => BlockUI.Unblock())
            .then(data => {
                _.each(data, (item) => {
                    var field = new FieldModel();
                    field.FieldId = item.PrimaryTableLookupFieldId;
                    field.FieldName = `${item.LookupTableTableName} ${item.LookupTableValueFieldName}`;
                    fields.push(field);
                });
                this._fields(fields);
                this.SetCurrentField();
            });
    }

    LoadBlobControlData() {
        var isControlInMainSubForm = true;
        if (this._editControl.GetParentControl()) {
            isControlInMainSubForm = !(this._editControl.GetParentControl() instanceof TabPage);
        }

        var requestModel = {
            ControlTypeName: this._editControlModel.TypeName,
            ContextEntityId: this._editControl.GetForm().GetScreen().GetEntityId(),
            IsControlInMainSubForm: isControlInMainSubForm
        }
        return BlobFieldsStore.Get(requestModel)
            .always(() => BlockUI.Unblock())
            .then(data => {
                var fields = [];
                _.each(data, (item) => {
                    var field = new FieldModel();
                    field.FieldId = item.FieldId;
                    field.FieldName = item.EntityNameWithFieldName;
                    field.FullName = item.EntityNameWithFieldName;
                    fields.push(field);
				});

                this._blobFields(data);
                this._fields(fields);
                this.SetCurrentField();
            });
    }

    LoadGeneralControlData() {
        return EntitiesStore.Get({EntityId: this._subjectEntityId, TableTypeId: this._tableTypeId})
            .always(() => BlockUI.Unblock())
            .then(entities => {
                entities.forEach(entity => entity.Fields = entity.Fields.filter(field => field.FieldTypeName !== FIELD_TYPES.Property));

                if (this._editControl instanceof Drop) {
                    entities = _.filter(entities, entity => entity.TypeName !== TABLE_TYPES.Link);
                    _.each(entities, (entity) => {
                        _.each((entity.Fields), (field) => {
                            field.FullName = `${entity.EntityName}, ${field.FieldName}`;
                        });
                    });
                }

                let designScreen = <DesignScreen>this._editControl.GetForm().GetScreen();
                let isEditScreen = designScreen.GetType() === ScreenTypes[ScreenTypes.EditScreen];
                let isListScreen = designScreen.GetType() === ScreenTypes[ScreenTypes.ListScreen];
                let isOnlySubject =
                    (isEditScreen || isListScreen) && this._control.GetType() !== CONTROL_TYPES.Drop && !(this._editControl instanceof ComplexControl);

                if (isOnlySubject) {
                    let subjectEntity = _.find(entities, entity => {
                        return entity.EntityId === this._subjectEntityId
                    });

                    let existFieldIds = [];

                    _.each(designScreen.Controls,
                        (control) => {
                            if (control.GetModel().Fields.length === 1) {
                                existFieldIds.push(control.GetModel().Fields[0].Id);
                            }
                        });

                    let currentField = _.first(this._editControlModel.Fields);

                    if (currentField) {
                        _.each(subjectEntity.Fields, (field) => {
                            if ((_.contains(existFieldIds, field.FieldId) && currentField.Id !== field.FieldId)
                                || field.IsPrimaryKey() || (isEditScreen && field.IsSystem)) {
                                field.IsEnabled = false;
                            }
                        });
                    } else {
                        _.each(subjectEntity.Fields, (field) => {
                            if (_.contains(existFieldIds, field.FieldId) || field.IsPrimaryKey() || (isEditScreen && field.IsSystem)) {
                                field.IsEnabled = false;
                            }
                        });
                    }

                    this._entities([subjectEntity]);
                } else {
                    this._entities(entities);
                }

                let selectedEntity = _.find(entities, entity => {
                    return entity.EntityId === this._subjectEntityId
                });

                if (!selectedEntity) {
                    selectedEntity = _.first(entities);
                }

                if (selectedEntity) {
                    const subjectField = _.first(this._editControl.GetModel().Fields);

                    if (this._editControl instanceof Drop) {
                        const dropControlSubjectEntity = subjectField && _.find(this._entities(), (entity) => {
                            return !!_.find(entity.Fields, (entityField) => {
                                return entityField.FieldId === subjectField.Id;
                            });
                        });

                        this._selectedEntity(dropControlSubjectEntity || selectedEntity);

                        const destinationTypeId = this._editControl.GetModel().DestinationTypeId;
                        if(destinationTypeId && destinationTypeId > 0){
                            let entityType = _.find(this._entityTypes(), item => item.Id === destinationTypeId);
                            entityType = entityType? entityType : _.first(this._entityTypes());
                            this._selectedEntityType(entityType);
                        }else{
                            this._selectedEntityType(_.first(this._entityTypes()));
                        }

                        this._fields(_.filter(this._selectedEntity().Fields, (field) => {
                            return field.FieldTypeName === FIELD_TYPES.Document
                        }));
                    } else {
                        const controlSubjectEntity = subjectField && _.find(entities, entity =>
                            entity.EntityId === subjectField.ValTableId
                        );
                        if (controlSubjectEntity) {
                            selectedEntity = controlSubjectEntity;
                        }

                        this._selectedEntity(selectedEntity);
                        this._fields(selectedEntity.Fields);
                    }
                    this.SetCurrentField();
                }
            });
    }

    SetCurrentSubEntity() {
        var entity = _.first(this._subEntities());

        if (entity) {
            this._selectedSubEntity(entity);
        }
    }

    SetCurrentField() {
        let field = null;

        if (this._editControl instanceof Drop) {
            if (this._editControl.Files.length === 3) {
                field = this._editControlModel.Fields[2];
            }
        } else {
            field = _.first(this._editControlModel.Fields);
        }

        if (field) {
            let selectedField = _.find(this._fields(), item => {
                return item.FieldId === field.Id
            });
            if (selectedField) {
                this._selectedField(selectedField);
            }
        } else {
            let selectedField = _.find(this._fields(), (item) => {
                return item.IsEnabled
            });

            if (this._editControl instanceof Drop) {
                if (selectedField) {
                    let attachedFieldModel = new AttachedFieldModel();
                    attachedFieldModel.Id = selectedField.FieldId;
                    attachedFieldModel.Name = selectedField.FieldName;
                    attachedFieldModel.Sort = 2 * 10;
                    attachedFieldModel.FieldTypeName = selectedField.FieldTypeName;
                    this._editControl.GetModel().Fields[2] = attachedFieldModel;
                }
            }

            this._selectedField(selectedField);
			if (this._selectedField()) {
				var generalProperties = this._editControl.GeneralProperties;
                if (generalProperties && !(this._editControl instanceof Drop)) {
                    if (!this._editControl.GetFieldModel()) {
                        var attachedField = new AttachedFieldModel();
                        this._editControl.GetModel().Fields.push(attachedField);
                    }

                    this._editControl.GetFieldModel().Id = this._selectedField().FieldId;
                    this._editControl.GetFieldModel().ValTableId = this._selectedField().ValTableId;

                    generalProperties.ResetProperty('QueryCondition');
				}

                if (this._useFieldName()) {
	                this.UseFieldNameForControlName();
                }
            }
        }

        if (this._selectFieldSubscription) {
            this._selectFieldSubscription.dispose();
        }

        this._selectFieldSubscription = this._selectedField.subscribe(newValue => {

			if (newValue) {
				if (this._editControl instanceof Drop) {
					let attachedFieldModel = new AttachedFieldModel();
					attachedFieldModel.Id = newValue.FieldId;
					attachedFieldModel.Name = newValue.FieldName;
					attachedFieldModel.Sort = 2 * 10;
					attachedFieldModel.FieldTypeName = newValue.FieldTypeName;
					this._editControl.GetModel().Fields[2] = attachedFieldModel;
				} else {
					var generalProperties = this._editControl.GeneralProperties;
					if (generalProperties) {

						if (!this._editControl.GetFieldModel()) {
							var attachedField = new AttachedFieldModel();
							this._editControl.GetModel().Fields.push(attachedField);
						}

						this._editControl.GetFieldModel().Id = this._selectedField().FieldId;
						this._editControl.GetFieldModel().ValTableId = this._selectedField().ValTableId;

						generalProperties.ResetProperty('QueryCondition');
					}
					this._controlName(newValue.FieldName);
				}

				if (this._useFieldName()) {
					this.UseFieldNameForControlName();
				}
			}
            
        });

    }

    OpenIconManager() {
        const screen = this._control.GetForm().GetScreen() as DesignScreen;
        const icons = screen.DesignModel.Icons;

        let iconManager = new IconManager(icons);
        iconManager.Show();

        iconManager.On('ICON_SELECTED', this, (eventArgs) => {
            this._icon(eventArgs.data.Icon);
        });
    }

    GetFieldMaxLength(): number {
        if(!this._editControlModel) {
            return ;
        }
        const maxLength = this._editControlModel.NameMaxSize;

        return maxLength > 0 ? maxLength : -1;
    }

    NameValidate(that, event): boolean {
        const fieldMaxLength = this.GetFieldMaxLength();
        if(that.Translation && that.Translation.length > 0 && fieldMaxLength !== -1 && that.Translation.length >= fieldMaxLength){
            this.IsValidName(false);
            this.ValidationMsgForName(`${NOTIFICATIONS.MAXIMUM_LENGTH_OF_THIS_FIELD} ${fieldMaxLength}`);
        }else {
            this.IsValidName(true);
        }

        return this.IsValidName();
    }

    OnKeyUpUpdateTranslationList(that, event): boolean {
        this.UpdateTranslationList();

        return this.NameValidate(that, event);
    }

    OnKeyUpChangeTranslation(that, event): boolean {
        this.ChangeTranslation();

        return this.NameValidate(that, event);
    }

    get SelectedLabelPosition() {

        return this._selectedLabelPosition;
    }

    get LabelPositions() {
        return this._labelPositions;
    }

    get SelectedEntity() {
        return this._selectedEntity;
    }

    get SelectedEntityType() {
        return this._selectedEntityType;
    }

    get SelectedSubEntity() {
        return this._selectedSubEntity;
    }

    get SelectedField() {
        return this._selectedField;
    }

    get Entities() {
        return this._entities;
    }

    get EntityTypes() {
        return this._entityTypes;
    }

    get SubEntities() {
        return this._subEntities;
    }

    get Fields() {
        return this._fields;
    }

    get Id() {
        return this._editControlModel.Id === 0 || !this._editControlModel.Id ? this._editControl.GetGuid() : this._editControlModel.Id;
    }

    get Name() {
        return this._controlName;
    }

    get Translations() {
        return this._controlNameTranslations();
    }

    get DescriptionTranslations() {
        return this._controlDescriptionTranslations();
    }

    get TypeName() {
        return this._editControlModel.TypeName;
    }

    get TypeTranslatedName() {
        return this._editControlModel.TypeTranslatedName ? this._editControlModel.TypeTranslatedName : this.TypeName;
    }

    get Icon() {
        return this._icon();
    }

    get GeneralProperties() {
        return this._editControl.GeneralProperties;
    }

    GetTemplateName() {
        return 'Core/Screens/DesignScreen/ControlEditor/Templates/ControlEditor';
    }

    Show() {
        this._modal = new Modal({});
        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
    }

    AfterRender() {
        if (this._modal) {
            this._modal.Show();
        }
        BlockUI.Block();

        this.SetCurrent();
        this.SetCurrentDescription();
        this.LoadData();
    }

    Validate() {
        return this.IsValid() && this._editControl.IsPropertiesValid();
    }

    Save() {
        if (this.Validate()) {
            this.Deserialize();
            this.Trigger('CONTROL_SAVED', {UpdateControlModel: this._controlEditorModel});
            if (this._modal) {
                this._modal.Close();
            }
        } else {
            //todo create nice validation
            new Notifier().Warning(NOTIFICATIONS.PLEASE_SET_ALL_PROPERTIES);
        }

    }

    Deserialize() {
        let self = this;
        this._controlEditorModel.Name = this._controlName();
        this._controlEditorModel.K_Control = this._editControlModel.Id;
        this._controlEditorModel.F_Type = this._editControlModel.TypeId;
        this._controlEditorModel.TypeName = this._editControlModel.TypeName;
        this._controlEditorModel.LabelPosName = this._editControlModel.LabelPosition;
        this._controlEditorModel.LabelPos = LabelPositions[this._editControlModel.LabelPosition];
        var parentControl = this._editControl.GetParentControl();
        this._controlEditorModel.ParentControlId = parentControl ? parentControl.GetControlId() : 0;
        this._controlEditorModel.HasParentControl = parentControl ? true : false;
        this._controlEditorModel.FieldId = this._selectedField() ? this._selectedField().FieldId : 0;
        this._controlEditorModel.ValTableId = this._selectedField() ? this._selectedField().ValTableId : 0;
        this._controlEditorModel.ValFieldId = this._selectedField() ? this._selectedField().ValFieldId : 0;
        this._controlEditorModel.TypeName = this._selectedField() ? this._selectedField().FieldTypeName : '';
        this._controlEditorModel.FieldName = this._selectedField() ? this._selectedField().FieldName : '';
        this._controlEditorModel.EntityName = this._selectedEntity() ? this._selectedEntity().EntityName : this._editControl.GetForm().GetScreen().GetEntityName();

        this._controlEditorModel.EntityId = this._selectedEntity() ? this._selectedEntity().EntityId : this._editControl.GetForm().GetScreen().GetEntityId();
        this._controlEditorModel.DestinationTypeId = this._selectedEntityType() ? this._selectedEntityType().Id : 0;
        this._controlEditorModel.SubTableId = this._selectedSubEntity() && this._selectedSubEntity().EntityId;
        this._controlEditorModel.SubFormId = parentControl ? 0 : this._editControl.GetForm().GetFormId();
        this._controlEditorModel.Description = this._controlDescriptionTranslations()[0].Translation;
        this._controlEditorModel.Icon = this._icon();
        this._controlEditorModel.UseFieldName = this._useFieldName();

        var editControl = this._editControl;

        if (editControl instanceof ComplexControl) {
            this._controlEditorModel.AttachedFields = editControl.GetModel().Fields;
            _.forEach(this._controlEditorModel.AttachedFields, field => {
                let iconModel = new IconModel();
                iconModel.Name = this._selectedEntity().IconName;
                iconModel.FontName = this._selectedEntity().FontName;
                iconModel.Image = this._selectedEntity().GraphicalIcon;
                iconModel.IsIcon = this._selectedEntity().GraphicalIcon == null;
                iconModel.IsImage = !iconModel.IsIcon;

                field.EntityIcon = iconModel;
            });
        }

        _.each(self._editControlModel.NameTranslations, (item) => {
            if (item.Language.K_Language === -1) {
                self._controlEditorModel.Name = item.Translation;
            } else {
                self._controlEditorModel.NameTranslations.push(item);
            }
        });

        _.each(self._editControlModel.DescriptionTranslations, (item) => {
            if (item.Language.K_Language === -1) {
                self._controlEditorModel.Description = item.Translation;
            } else {
                self._controlEditorModel.DescriptionTranslations.push(item);
            }
        });

        let generalProperties = this._editControl.GeneralProperties;

        this._controlEditorModel.Properties = generalProperties ? generalProperties.Serialize() : null;
    }

    SetOptionDisable(option, item) {
        ko.applyBindingsToNode(option, {
            enableAttr: item.IsEnabled,
            css: {
                disabled: !item.IsEnabled
            }
        }, item);
    }

    Cancel() {
        this._modal.Close();
    }

    IsValid(): boolean {
        this.SetDefaultValueByTranslation();
        if (this.DefaultName.Translation) {
            this._isValid(true);
            return true;
        }
        this._isValid(false);
        return false;
    }

    get DefaultName() {
        return _.find(this.Translations, (item) => item.Language.K_Language === -1);
    }

    get DefaultDescription() {
        return _.find(this.DescriptionTranslations, (item) => item.Language.K_Language === -1);
    }

	get ShowUseFieldNameToggle() {
		return this.TypeName === CONTROL_TYPES.Document;
	}

    private SetDefaultValueByTranslation() {
        let defaultItem = this.DefaultName;
        if (defaultItem && defaultItem.Translation) {
            return true;
        } else if (defaultItem && !defaultItem.Translation) {
            let currentLang = TranslationManager.Instance.GetCurrentLanguage();
            let currentLangItem = _.find(this.Translations, (item) => {
                return currentLang.Id === item.Language.K_Language;
            });

            if (currentLangItem.Translation) {
                defaultItem.Translation = currentLangItem.Translation;
            } else {
                let first = _.find(this.Translations, (item) => {
                    return item.Translation;
                });
                if (first) {
                    defaultItem.Translation = first.Translation;
                }
            }
        }
	}

	private UseFieldNameForControlName() {
		if (this.TypeName === CONTROL_TYPES.Document) {
			const blobField = _.find(this._blobFields(), field => this._selectedField() && field.FieldId === this._selectedField().FieldId);
			if (blobField) {
				const controlName = `${blobField.EntityName}.${blobField.FieldName}`;
				this._controlName(controlName);

				_.each(this._controlNameTranslations(),
					translationModel => {
						if (translationModel.Language.K_Language === -1) {
							translationModel.Translation = controlName;
							this._currentControlName(translationModel);
							return;
						}

						const entityNameTranslation =
							_.find(blobField.EntityNameTranslations, entityTranslation => entityTranslation.Language.K_Language === translationModel.Language.K_Language);
						if (!entityNameTranslation || !entityNameTranslation.Translation) {
							translationModel.Translation = null;
							return;
						}

						const fieldNameTranslation =
							_.find(blobField.FieldNameTranslations, fieldTranslation => fieldTranslation.Language.K_Language === translationModel.Language.K_Language);
						if (!fieldNameTranslation || !fieldNameTranslation.Translation) {
							translationModel.Translation = null;
							return;
						}

						translationModel.Translation = `${entityNameTranslation.Translation}.${fieldNameTranslation.Translation}`;
					});

				this.UpdateTranslationList();
			}
		}
	}
}