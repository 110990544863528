import * as ko from 'knockout';

import {FormatConverter} from "FormatEditor/FormatConverter";

import {FeatureTypes} from "Core/Common/Enums/FeatureTypes";

import {SpimValueModel} from "Core/Controls/Grid/Models/GridDataModel/GridCellValueModel";

import {SpimValueViewModel} from "./SpimValueViewModel";

import RangeTemplate from '../Templates/FeatureTypes/Range.html';

export class RangeValueViewModel extends SpimValueViewModel {
    RangeLowDisplayValue: KnockoutObservable<string>;
    RangeHighDisplayValue: KnockoutObservable<string>;

    constructor(recordId: number, rangeLow: number, rangeHigh: number) {
        super(recordId);

        this.RangeLowDisplayValue = ko.observable(FormatConverter.ConvertDecimal(rangeLow && rangeLow.toString()));
        this.RangeHighDisplayValue = ko.observable(FormatConverter.ConvertDecimal(rangeHigh && rangeHigh.toString()));
    }

    GetTemplate(): any {
        return RangeTemplate;
    }

    GetValue(): any {
        const spimValue = new SpimValueModel();

        spimValue.RecordId = this.recordId;
        spimValue.FeatureType = FeatureTypes.Range;

        let lowValue = parseFloat(this.RangeLowDisplayValue() && this.RangeLowDisplayValue().replace(',', '.'));
        spimValue.RangeLow = isNaN(lowValue) ? null : lowValue;

        let highValue = parseFloat(this.RangeHighDisplayValue() && this.RangeHighDisplayValue().replace(',', '.'));
        spimValue.RangeHigh = isNaN(highValue) ? null : highValue;

        return spimValue;
    }
}