import {FastFilter} from 'Core/Controls/Grid/BaseGrid/FastFilter/FastFilter';
import {GridColumnModel} from 'Core/Controls/Grid/Models/GridDataModel/GridColumnModel';
import {EVENTS} from 'Core/Controls/Grid/BaseGrid/Events';
import {IGetGridDataRequestModel} from 'Core/Controls/Grid/Stores/GridStore';
import {FieldFormat} from 'Core/Common/FieldFormat';
import { FormatConverter } from 'FormatEditor/FormatConverter';
import { LABELS } from 'Core/Components/Translation/Locales';

import DecimalFilterTemplate from 'Core/Controls/Grid/BaseGrid/FastFilter/Filters/Decimal/DecimalFilter.html';

export class DecimalFilter extends FastFilter {
	constructor(model: GridColumnModel,
				recordId: number,
				tableViewId: number,
				getGridDataModel: IGetGridDataRequestModel,
				existedColumnAliases: string[]) {
		super(model, recordId, tableViewId, getGridDataModel, existedColumnAliases);

		this.Preselect();
	}

	protected Filter() {
		this.MapToSaveModel();

		this.Trigger(EVENTS.FAST_FILTER);
	}

	protected DataLoadCallback(data) {
		this._availableItems(this.SortItems(data, true));

		this.MapToViewModel();
	}

	FormatValue(value: string) {
        if (!value || value === LABELS.EMPTY_VALUE) {
			return LABELS.EMPTY_VALUE;
		}

		return FormatConverter.LocalizeDecimalOrInteger(
			this._model.FieldMetadata.FormatName === FieldFormat.PERCENTAGE
				? (Number(value) * 100).toFixed(
						this._model.FieldMetadata.Size < 2 ? 0 : this._model.FieldMetadata.Size - 2
				  )
				: value
		);
	}

	GetIconFromModel() {
		switch (this._model.FieldMetadata.FormatName) {
			case FieldFormat.PERCENTAGE:
				return 'fa fa-percent';
			case FieldFormat.CURRENCY:
				return 'fa fa-euro';
			default:
				return;
		}
	}

	GetTemplate() {
		return DecimalFilterTemplate;
	}
}