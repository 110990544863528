import * as ko from 'knockout';
import * as _ from 'underscore';

import {DEFAULT_ICONS, FIELD_TYPES, RenderModes, TABLE_TYPES} from "Core/Constant";

import {Icon} from "Core/Icon/Icon";

import {ComplexControl} from "../ComplexControl/ComplexControl";
import {RequiredFieldModel} from "../ComplexControl/Models/RequiredFieldModel";

import {IControlParam} from 'Core/Screens/IScreen';
import {IControlValue} from 'Core/Controls/BaseControl/BaseControl';

import {GanttChartComponent} from 'Core/Components/Controls/GanttChart/GanttChartComponent';

import {GeneralProperties} from 'Core/GeneralProperties/GeneralProperties';
import {IPropertyOption} from 'Core/GeneralProperties/Managers/BaseProperty';

import GanttChartConfig from 'Core/Controls/GanttChart/Configs/gantt-chart-config.json';

import ViewTemplate from 'Core/Controls/GanttChart/Templates/View.html';
import ToolBarTemplate from 'Core/Controls/GanttChart/Templates/ToolBar.html';
import { SubjectEntity } from '../FormDesigner/ToolBar/EntityToolBar/SubjectEntity';

ko.templates['Core/Controls/GanttChart/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/GanttChart/Templates/Design'] = ComplexControl.designTemplate;
ko.templates['Core/Controls/GanttChart/Templates/View'] = ViewTemplate;

export class GanttChart extends ComplexControl {
    private _ganttChartViewName: string;
    private _resourcesViewName: string;

    private _component: KnockoutObservable<GanttChartComponent>;
    private _initialValue: IControlValue;
    private _componentRendered: boolean;

    constructor(params: IControlParam) {
        super(params, GanttChartConfig);

        this._component = ko.observable(null);

        this.Init();
    }

    SetValue(value: IControlValue): void {
        this._initialValue = value;

        if (this._componentRendered) {
            this._component().Refresh({
                SourceTable: this._ganttChartViewName,
                ResourcesTable: this._resourcesViewName,
                RootId: this._initialValue.SubjectRecordId,
                SubjectEntityId: this._initialValue.SubjectEntityId
            });
        }
    }

    ApplyProperties(){}

    private Init() {        
        this.InitRuntime();
        this.InitDesignTime();
    }

    private InitRuntime() {
        if (!this.IsRunTime) {
            return;
        }
        this._ganttChartViewName = this.FieldModel.EntityName;
        this._resourcesViewName = _.find(this.Model.Fields, f => f.EntityId !== this.FieldModel.EntityId).EntityName;

        const defaultScale: IPropertyOption | undefined = this.GeneralProperties.GetPropertyValue('DefaultScale');

        this._component(new GanttChartComponent({ defaultScale: defaultScale && defaultScale.Value }));

        this._component().On('RENDERED', this, () => {
            this._componentRendered = true;

            if (this._initialValue) {
                this._component().Refresh({
                    SourceTable: this._ganttChartViewName,
                    ResourcesTable: this._resourcesViewName,
                    RootId: this._initialValue.SubjectRecordId,
                    SubjectEntityId: this._initialValue.SubjectEntityId
                });
            }
        });
    }

    private InitDesignTime() {
        if(!this.IsDesignTime){
            return;
        }
        this.SetDefaultIcon(new Icon(DEFAULT_ICONS.Agenda));
        this._requiredFields([
            new RequiredFieldModel('LEVEL0_ENTITY', FIELD_TYPES.Text, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('LEVEL0_ID', FIELD_TYPES.Integer, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('LEVEL0_NAME', FIELD_TYPES.Text, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('LEVEL0_STARTING', FIELD_TYPES.DateTime, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('LEVEL0_ENDING', FIELD_TYPES.DateTime, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('LEVEL0_COMPLETENESS', FIELD_TYPES.Decimal, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('LEVEL0_DEPENDENCIES', FIELD_TYPES.Text, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('LEVEL0_RESOURCE_ENTITY', FIELD_TYPES.Text, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('LEVEL0_RESOURCE_ID', FIELD_TYPES.Integer, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('LEVEL0_RESOURCE_NAME', FIELD_TYPES.Text, TABLE_TYPES.Sub, null),
            new RequiredFieldModel('LEVEL0_RESOURCE_EFFORT', FIELD_TYPES.Decimal, TABLE_TYPES.Sub, null),
        ]);
        this.InitRequiredFields();
    }
}