import { SecurityDataModel } from "../Form/Models/SecurityDataModel";

export class SecurityEditorModel {
	Owner: User;
	Users: User[];
	SecurityPass: SecurityPassConfig;

	constructor() {
		this.Users = [];
		this.SecurityPass = new SecurityPassConfig();
	}
}

export class User {
	Id: number;
	Name: string;
	SecurityData: SecurityDataModel;
	Level: number;
	Rights: number;
	OptionalRights: number;
	Deleted: boolean;
}

export class SecurityPassConfig {
	DoNotPass: boolean;
	PassRights: boolean;
	PassOptionalRights: boolean;
	PassInheritance: boolean;
	RefusePass: boolean;
	PassOwner: boolean;
}