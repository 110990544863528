import * as ko from 'knockout';
import * as _ from 'underscore';

import {ISearchScreenParam} from 'Core/Screens/IScreen';
import {Event} from 'Core/Common/Event';
import {Modal} from 'Core/Common/Modal';
import {SearchScreenStore} from 'Core/Screens/SearchScreen/Stores/SearchScreenStore';
import {SearchScreenModel} from 'Core/Screens/SearchScreen/Models/SearchScreenModel';
import {
    FilterByAlternativeEntitiesModel,
    ISearchRequestModel,
    SearchStore
} from 'Core/Screens/SearchScreen/Stores/SearchStore';
import {RecordTypes, SearchByMainRelationTypes} from 'Core/Screens/SearchScreen/Enums/Enums';
import {Paginator} from 'Core/Components/Paginator/Paginator';
import {GlobalManager, GLOBALS} from 'Core/GlobalManager/GlobalManager';
import {Metronic} from 'metronic';
import {UserVarsRecordModel} from 'Core/Screens/SearchScreen/Models/UserVarsRecordModel';
import {EVENTS as PAGINATOR_EVENTS} from 'Core/Components/Paginator/Constants';

import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';
import {BlockUI} from 'Core/Common/BlockUi';
import {SearchGroupItemModel, SearchModeModel} from 'Core/Screens/SearchScreen/Models/SearchModeModel';
import {SearchFieldModel} from 'Core/Screens/SearchScreen/Models/SearchFieldModel';
import {SearchByRelationModel} from 'Core/Screens/SearchScreen/Models/SearchByRelationModel';
import {Icon} from 'Core/Icon/Icon';
import {Notifier} from 'Core/Common/Notifier';
import {
    LifeStatusGroupIndexes,
    MappedSearchGroups,
    SearchGroups,
    SimpleFilterGroupsSet
} from 'Core/Enums/SearchGroups';
import {RECORD_SECURITY_WORDS} from 'Core/Constants/RecordSecurityWords';
import {LABELS, NOTIFICATIONS} from 'Core/Components/Translation/Locales';
import {OrderFieldModel} from 'Core/Screens/SearchScreen/Models/OrderFieldModel';
import {SYSTEM_TABLE_NAMES, TABLE_TYPES} from "Core/Constant";
import {SearchResultGrid} from 'Core/Screens/SearchScreen/SearchResultGrid/SearchResultGrid';
import {SearchResultRow} from "Core/Screens/SearchScreen/SearchResultGrid/SearchResultRow";
import {EntityModel} from "Core/GeneralProperties/Managers/AlternativeEntitiesProperty/Models/EntityModel";
import {MobileChecker} from 'Core/Common/MobileChecker';
import {BarcodeScanner} from 'Core/Components/BarcodeScanner/BarcodeScanner';

import {EVENTS as SEARCH_SCREEN_EVENTS} from "./Events";
import {SortOrder} from "../../Controls/Grid/BaseGrid/GridColumn/BaseColumn";
import {P} from "../../Common/Promise";
import {SearchResultRowModel} from './Models/SearchResultModel';
import {DeduplicationGrid} from "../../ProfilePage/Deduplication/DeduplicationEditor/DeduplicationGrid";
import {Actions, DeduplicationStore} from "Core/ProfilePage/Deduplication/Stores/DeduplicationStore";
import {EVENTS} from "Core/Controls/Grid/BaseGrid/Events";
import {DeduplicationCell} from "Core/ProfilePage/Deduplication/DeduplicationEditor/DeduplicationCell";
import {TABLE_SECURITY_WORDS} from "Core/Constants/TableSecurityWords";
import EDIT = TABLE_SECURITY_WORDS.EDIT;
import DELETE = TABLE_SECURITY_WORDS.DELETE;
import { IDynamicFieldData } from 'Core/Common/Stores/RecordStore';
import { IConditionValueDto } from 'Core/Common/Interfaces/IConditionValueDto';

// Templates
import SearchScreenTemplate from 'Core/Screens/SearchScreen/Templates/SearchScreen.html';
import AlternativeIconTemplate from 'Core/GeneralProperties/Managers/AlternativeEntitiesProperty/Templates/IconTemplate.html';
import SearchIconTemplate from 'Core/Screens/SearchScreen/Templates/IconTemplate.html';
ko.templates['Core/Screens/SearchScreen/Templates/SearchScreen'] = SearchScreenTemplate;
ko.templates['Core/GeneralProperties/Managers/AlternativeEntitiesProperty/Templates/IconTemplate'] = AlternativeIconTemplate;
ko.templates['Core/Screens/SearchScreen/Templates/IconTemplate'] = SearchIconTemplate;

interface IRecordType {
    Value: RecordTypes;
    Title: string;
    IsEnabled: boolean;
}

interface IDuplicatesType {
    Title: string;
    IsDisabled: boolean;
}

interface IAlternativeSubjectsType {
    Title: string;
    IsDisabled: boolean;
    IsChosen?: boolean;
}

const FILTER_BY_RELATION_CLASSES = {
    OFF_FILTER_BY_RELATIONS: 'filter-by-relations-btn off-filter-by-relations',
    BY_RELATIONS: 'filter-by-relations-btn by-relations',
    BY_MAIN_RELATIONS: 'filter-by-relations-btn by-main-relations',
    BY_CONDITION: 'filter-by-relations-btn by-condition',
    BY_LINKED_DOCUMENTS: 'filter-by-relations-btn by-linked-documents'
}


const TOGGLE_CLASSES = {
    ON: 'fa-toggle-on',
    OFF: 'fa-toggle-off'
}

interface ISearchGroup {
    Id: number,
    Name: string
}

export interface ISelectedRecord {
    TypeId: number,
    TypeName: string,
    RecordId: number,
    Name: string,
    TableId: number
}

export class SearchScreen extends Event {
    private _entityId: number;
    private _subjectEntityId: number;
    private _subjectTypeId: number;
    private _entityName: string;
    private _searchRegexp: any;
    private _sharing: boolean;

    private _fieldId: number;
    private _leftEntityId: number;
    private _rightEntityId: number;
    private _leftRecordId: number;
    private _rightRecordId: number;

    private _entityIcon: KnockoutObservable<Icon>;
    private _searchTerm: KnockoutObservable<string>;
    private _modal: Modal;
    private _searchTimer: any;
    private _model: SearchScreenModel;
    private _searchResult: KnockoutObservableArray<any>;
    private _searchResultMessage: KnockoutObservable<string>;
    private _nameFieldName: string;
    private _recent: Array<UserVarsRecordModel>;
    private _favorites: Array<UserVarsRecordModel>;
    private _recentAndFavoritesTabIsActive: boolean;
    private _closeAfterSelectingRecord: boolean;
    private _recentRecords: KnockoutObservable<boolean>;
    private _searchGroups: KnockoutObservableArray<ISearchGroup>;
    private _searchGroup: KnockoutObservable<number>;
    private _focusInput: KnockoutObservable<any>;
    private _recordTypes: KnockoutObservableArray<IRecordType>;
    private _recordType: KnockoutObservable<IRecordType>;
    private _paginator: Paginator;
    private _isFirstLoad: boolean;
    private _isModal: boolean;
    private _paginationInfo: KnockoutObservable<string>;
    private _el: HTMLElement;
    private _addButtonVisible: KnockoutObservable<boolean>;
    private _addButtonDisabled: KnockoutObservable<boolean>;
    private _isClick: KnockoutObservable<boolean>;
    private _isAddQueryButton: boolean;
    private _searchMode: KnockoutObservable<SearchModeModel>;
    private _selectedSearchMode: KnockoutObservable<SearchGroupItemModel>;
    private _searchLoad: boolean;
    private _adaptiveRecordsCount: number;
    private _orderFields: Array<OrderFieldModel>;
    private _labels = LABELS;
    private _controlId: number;
    private _altEntities: KnockoutObservableArray<any>;
    public SelectedAltEntityId: number;
    private _isSimpleLifeStatus: KnockoutObservable<boolean>;
    private _enableFilter: KnockoutObservable<boolean>;
    private _showConditionToggle: KnockoutObservable<boolean>;
    private _isBaseEntity: KnockoutObservable<boolean>;
    private _screenData?: Array<IDynamicFieldData>;
    private _subjectRecordId: number;
    private _searchByMainRelationType: KnockoutObservable<SearchByMainRelationTypes>;
    private _filterByMainRelationTooltip: KnockoutComputed<string>;
    private _filterByMainRelationClass: KnockoutComputed<string>;
    private _searchByTypes: Array<number>;
    private _searchByRelations: Array<SearchByRelationModel>;
    private _isBackLinkEnabled: KnockoutObservable<boolean>
    private _entityType: string;
    private _searchFieldId: number;
    private _typeToggleClass: KnockoutComputed<string>;
    private _searchResultGrid: KnockoutObservable<SearchResultGrid>;
    private _plannerLevelRecordId: number;
    private _plannerLevelEntityId: number;
    private _multiSelectMode: boolean;
    private _selectedRecords: KnockoutObservableArray<number>;
    private _hasSelectedRecords: KnockoutComputed<boolean>;
    private _hasBarcodeFields: KnockoutObservable<boolean>;
    private _enableBulkScan: boolean;
    private _isBulkBarcodeScanning: boolean;

    private _duplicatesToggleClass: KnockoutComputed<string>;
    private _duplicatesTypes: KnockoutObservableArray<IDuplicatesType>;
    private _duplicatesType: KnockoutObservable<IDuplicatesType>;
    private _hasTwoSelectedRecords: KnockoutComputed<boolean>;
    private _duplicatesVisible: KnockoutObservable<boolean>;
    private _selectedDuplicatesRecords: KnockoutObservableArray<ISelectedRecord>;
    private _querySubjectId: number;
    private _hasLinkingCondition: boolean;
    private _showSearchByLinkedDocuments: boolean;
    private _isParentLinking: boolean;
    private _relatedTableId: number;
    private _relatedTablePk: number;
    private _relatedTableFieldId: number;
    private _alternativeSubjectsTypes: KnockoutObservableArray<IAlternativeSubjectsType>;
    private _alternativeSubjectsToggleClass: KnockoutComputed<string>;
    private _alternativeSubjectsType: KnockoutObservable<IAlternativeSubjectsType>;

    private _recentRecordsSubscription: KnockoutSubscription;
    private _recordTypeSubscription: KnockoutSubscription;
    private _duplicatesTypeSubscription: KnockoutSubscription;
    private _selectedSearchModeSubscription: KnockoutSubscription;
    private _searchGroupSubscription: KnockoutSubscription;
    private _enableFilterSubscription: KnockoutSubscription;
    private _searchByMainRelationTypeSubscription: KnockoutSubscription;
    private _kSeq: number;
    private _isMobile: boolean;
    private _showSearchToolbarIcons: KnockoutObservable<boolean>;
    private _searchAlternativeEntitiesShow: KnockoutObservable<boolean>;

    private _grid: DeduplicationGrid;    
    private _dropDownFilterState: boolean;
    private _conditionValues: Array<IConditionValueDto>;

    constructor(params: ISearchScreenParam) {
        super();
        if (!params.EntityId && !params.EntityName) {
            throw new Error('EntityId or EntityName should be specified');
        }

        this._dropDownFilterState = params.DropDownFilterState;        
        this._entityId = params.EntityId;
        this._entityName = params.EntityName;
        this._subjectEntityId = params.SubjectEntityId || null;
        this._subjectTypeId = params.SubjectTypeId || null;
        this._subjectRecordId = params.SubjectRecordId || null;
        this._sharing = params.Sharing || false;
        this._controlId = params.ControlId;
        this._fieldId = params.FieldId;
        this._leftEntityId = params.LeftEntityId;
        this._rightEntityId = params.RightEntityId;
        this._leftRecordId = params.LeftRecordId;
        this._rightRecordId = params.RightRecordId;
        this._screenData = params.ScreenData;
        this._model = new SearchScreenModel();
        this._searchResult = ko.observableArray([]);
        this._searchResultMessage = ko.observable('');
        this._searchGroups = ko.observableArray([]);
        this._entityIcon = ko.observable(null);
        this._searchTerm = ko.observable(params.SearchTerm);
        this._focusInput = ko.observable(true);
        this._searchByTypes = params.SearchByTypes;
        this._paginator = new Paginator(1);
        this._isModal = false;
        this._searchRegexp = /[a-zA-Z0-9-_. ]/;
        this._conditionValues = params.ConditionValues;

        this._searchGroup = ko.observable(null);
        this._recent = Array<UserVarsRecordModel>(null);
        this._favorites = Array<UserVarsRecordModel>(null);
        this._recentAndFavoritesTabIsActive = params.RecentAndFavoritesActive;
        this._closeAfterSelectingRecord = params.CloseAfterSelectingRecord === undefined ? true : params.CloseAfterSelectingRecord;
        this._isFirstLoad = true;
        this._paginationInfo = ko.observable('');
        this._searchMode = ko.observable(new SearchModeModel());
        this._searchLoad = false;
        this._selectedSearchMode = ko.observable(null);
        this._addButtonVisible = ko.observable(params.ButtonAdd || params.ButtonAdd === undefined);
        this._addButtonDisabled = ko.observable(false);
        this._isClick = ko.observable(false);
        this._isAddQueryButton = params.IsAddQueryButton || false;
        this._adaptiveRecordsCount = 0;
        this._orderFields = [];
        this._isSimpleLifeStatus = ko.observable(false);
        this._enableFilter = ko.observable(true);
        this._showConditionToggle = ko.observable(params.ConditionToggler);
        this._isBaseEntity = ko.observable(true);
        this._searchByMainRelationType = params.SearchByMainRelationType
            ? ko.observable(params.SearchByMainRelationType)
            : ko.observable(SearchByMainRelationTypes.Off);
        this._searchByRelations = params.SearchByRelations;
        this._isBackLinkEnabled = ko.observable(true);
        this._entityType = params.EntityType;
        this._searchFieldId = params.SearchFieldId;
        this._altEntities = ko.observableArray([]);
        this._searchResultGrid = ko.observable(null);
        this._plannerLevelEntityId = params.PlannerLevelEntityId;
        this._plannerLevelRecordId = params.PlannerLevelRecordId;
        this._multiSelectMode = params.MultiSelectMode || false;
        this._selectedRecords = ko.observableArray(params.SelectedRecordIds);
        this._hasSelectedRecords = ko.computed(() => this._selectedRecords().length > 0);
        this._hasBarcodeFields = ko.observable();
        this._enableBulkScan = params.EnableBulkScan || false;
        this._querySubjectId = params.QuerySubjectId;
        this._hasLinkingCondition = params.HasLinkingCondition;
        this._showSearchByLinkedDocuments = params.ShowSearchByLinkedDocuments;
        this._isParentLinking = params.IsParentLinking || false;
        this._kSeq = params.KSeq;
        this._isMobile = MobileChecker.IsMobile();
        this._showSearchToolbarIcons = ko.observable(false);
        this._searchAlternativeEntitiesShow = ko.observable(false);

        this._filterByMainRelationClass = ko.computed(() => {
            switch (this._searchByMainRelationType()) {
                case SearchByMainRelationTypes.Off:
                    return FILTER_BY_RELATION_CLASSES.OFF_FILTER_BY_RELATIONS;
                case  SearchByMainRelationTypes.ByMainRelations:
                    return FILTER_BY_RELATION_CLASSES.BY_MAIN_RELATIONS;
                case SearchByMainRelationTypes.ByRelations:
                    return FILTER_BY_RELATION_CLASSES.BY_RELATIONS;
                case SearchByMainRelationTypes.ByConditions:
                    return FILTER_BY_RELATION_CLASSES.BY_CONDITION;
                case SearchByMainRelationTypes.ByLinkedDocuments:
                    return FILTER_BY_RELATION_CLASSES.BY_LINKED_DOCUMENTS;
                default:
                    return FILTER_BY_RELATION_CLASSES.OFF_FILTER_BY_RELATIONS
            }
        });

        this._filterByMainRelationTooltip = ko.computed(() => {
            switch (this._searchByMainRelationType()) {
                case SearchByMainRelationTypes.Off:
                    return this._labels.OFF_FILTER_BY_RELATIONS;
                case SearchByMainRelationTypes.ByMainRelations:
                    return this._hasLinkingCondition ? this._labels.FILTER_BY_MAIN_RELATIONS2 : this._labels.FILTER_BY_MAIN_RELATIONS;
                case SearchByMainRelationTypes.ByRelations:
                    return this._labels.FILTER_BY_RELATIONS;
                case SearchByMainRelationTypes.ByConditions:
                    return this._labels.FILTER_BY_LINKING_CONDITIONS;
                case SearchByMainRelationTypes.ByLinkedDocuments:
                    return this._labels.ONLY_LINKED_DOCUMENTS_ARE_SHOWN;
                default:
                    return this._labels.OFF_FILTER_BY_RELATIONS;
            }
        });

        let isRootForFormDesigner = true;
        if (params.IsRootForFormDesigner) {
            isRootForFormDesigner = !params.IsRootForFormDesigner;
        }

        this._recordTypes = ko.observableArray([
            {Title: LABELS.ROOT, Value: RecordTypes.Root, IsEnabled: true},
            {Title: LABELS.ALL, Value: RecordTypes.All, IsEnabled: isRootForFormDesigner}]);

        this._recordType = ko.observable(_.last(_.filter(this._recordTypes(), recordType => recordType.IsEnabled)));

        this._typeToggleClass = ko.computed(() => {
            return this._recordType() && this._recordType().Value === RecordTypes.Root ? TOGGLE_CLASSES.ON : TOGGLE_CLASSES.OFF;
        });

        this._recentRecords = ko.observable(false);

        this._relatedTableId = params.RelatedTableId;
        this._relatedTablePk = params.RelatedTablePk;
        this._relatedTableFieldId = params.RelatedTableFieldId;

        this.InitDuplicatesMod();
        this._grid = new DeduplicationGrid();
        this._grid.On(EVENTS.SAVE_CHANGES, this, () => this.SaveChangesDeduplication());
        this.InitAlternativeSubjectsMod();
    }

    InitDuplicatesMod() {
        this._duplicatesVisible = ko.observable(false);

        this._duplicatesTypes = ko.observableArray([
            {Title: 'Off', IsDisabled: true},
            {Title: 'On', IsDisabled: false}]);

        this._duplicatesType = ko.observable(_.last(_.filter(this._duplicatesTypes(), duplicatesTypes => duplicatesTypes.IsDisabled)));

        this._duplicatesToggleClass = ko.computed(() => {
            return this._duplicatesType() && this._duplicatesType().IsDisabled ? TOGGLE_CLASSES.OFF : TOGGLE_CLASSES.ON;
        });

        this._selectedDuplicatesRecords = ko.observableArray([]);
        this._hasTwoSelectedRecords = ko.computed(() => {
            return this._selectedDuplicatesRecords().length == 2;
        });

    }

    private AlternativeSubjectsToggle() {
        if (this._alternativeSubjectsType().Title != 'On') {
            this._alternativeSubjectsType(_.find(this._alternativeSubjectsTypes(), (type) => {
                type.IsChosen = true;
                return type.Title === 'On'
            }));
            return;
        } else {
            this._alternativeSubjectsType(_.find(this._alternativeSubjectsTypes(), (type) => {
                type.IsChosen = true;
                return type.Title === 'Off'
            }));
            return;
        }
    }

    InitAlternativeSubjectsMod() {

        this._alternativeSubjectsTypes = ko.observableArray([
            {Title: 'Off', IsDisabled: true, IsChosen: false},
            {Title: 'On', IsDisabled: false, IsChosen: false}]);

        this._alternativeSubjectsType = ko.observable(_.last(_.filter(this._alternativeSubjectsTypes(), alternativeSubjectsType => alternativeSubjectsType.IsDisabled)));

        this._alternativeSubjectsToggleClass = ko.computed(() => {
            let classToggle = this._alternativeSubjectsType() && this._alternativeSubjectsType().IsDisabled ? TOGGLE_CLASSES.OFF : TOGGLE_CLASSES.ON;

            if (this._alternativeSubjectsType().Title === 'On') {
                this.SearchRecords();
            } else {
                if (this._altEntities().length > 1) {

                    if (this._alternativeSubjectsType().IsChosen) {
                        this.SearchRecords();
                    }
                }
            }

            return classToggle;
        });

    }

    GetFilterByAlternativeEntities(): FilterByAlternativeEntitiesModel {
        const selectedMainEntityId = this._entityId;

        return {
            SubjectEntity: this._entityId,
            AlternativeEntities: _.filter(this._altEntities().map((altEntity) => {
                if (altEntity && altEntity.IsChecked && altEntity.IsChecked() && altEntity.EntityId !== selectedMainEntityId) {
                    return altEntity.EntityId;
                } else {
                    return null;
                }
            }), elem => {
                return elem !== null;
            })
        }
    }

    private ToggleType() {
        if (this._recordType().Value != RecordTypes.Root) {
            this._recordType(_.find(this._recordTypes(), (type) => {
                return type.Value === RecordTypes.Root
            }));
            return;
        }

        if (this._recordType().Value != RecordTypes.All) {
            this._recordType(_.find(this._recordTypes(), (type) => {
                return type.Value === RecordTypes.All
            }));
            return;
        }
    }

    private DuplicatesToggle() {
        if (this._duplicatesType().Title != 'On') {
            this._duplicatesType(_.find(this._duplicatesTypes(), (type) => {
                return type.Title === 'On'
            }));
            return;
        } else {
            this._duplicatesType(_.find(this._duplicatesTypes(), (type) => {
                return type.Title === 'Off'
            }));
            return;
        }
    }

    get Paginator(): Paginator {
        return this._paginator;
    }

    private HandleFilters() {
        this._recentRecordsSubscription = this._recentRecords.subscribe(() => {
            this.SearchRecords();
        });

        this._recordTypeSubscription = this._recordType.subscribe(() => {
            this._paginator.PageNumber = 1;
            this.SearchRecords();
        });

        this._duplicatesTypeSubscription = this._duplicatesType.subscribe(() => {
            this._selectedDuplicatesRecords([]);

            this.SearchRecords();
        });

        this._selectedSearchModeSubscription = this._selectedSearchMode.subscribe(() => {
            this.SearchRecords();
        });

        this._searchGroupSubscription = this._searchGroup.subscribe(() => {
            this.SearchRecords();
        });

        this._paginator.On(PAGINATOR_EVENTS.CHANGE, this, () => {
            this.SearchRecords();
        });

        this._enableFilterSubscription = this._enableFilter.subscribe(() => {
            this.SearchRecords();
        });

        this._searchByMainRelationTypeSubscription = this._searchByMainRelationType.subscribe((newValue) => {
            if (this._searchTimer) {
                clearTimeout(this._searchTimer);
            }
            this._searchTimer = setTimeout(() => {
                this.SearchRecords();
            }, 500);
        });

    }

    private UnHandleFilters() {
        this._recentRecordsSubscription.dispose();
        this._recordTypeSubscription.dispose();
        this._duplicatesTypeSubscription.dispose();
        this._selectedSearchModeSubscription.dispose();
        this._searchGroupSubscription.dispose();
        this._paginator.Off(PAGINATOR_EVENTS.CHANGE, this);
        this._enableFilterSubscription.dispose();
        this._searchByMainRelationTypeSubscription.dispose();
    }

    InputChange() {
        this._paginator.PageNumber = 1;
        this.SearchRecords();
    }

    GetFilterByRelationType(): SearchByMainRelationTypes {
        return this._searchByMainRelationType();
    }

    SetFilterByRelationType(filterType: SearchByMainRelationTypes) {
        this._searchByMainRelationType(filterType);
    }

    GetTemplateName(): string {
        return `Core/Screens/SearchScreen/Templates/SearchScreen`;
    }

    GetAlternativeIconTemplateName(): string {
        return 'Core/GeneralProperties/Managers/AlternativeEntitiesProperty/Templates/IconTemplate';
    }

    GetSearchIconTemplateName(): string {
        return 'Core/Screens/SearchScreen/Templates/IconTemplate';
    }

    ToggleToolbarIcons(){
        this._showSearchToolbarIcons(!this._showSearchToolbarIcons());
    }

    ToggleAlternativeEntities(){
        this._searchAlternativeEntitiesShow(!this._searchAlternativeEntitiesShow());
    }

    Render(options: any = null, isUseGlobal: boolean = true): void {
        let self = this;

        let mobileOptions = this._isMobile ? {
            minWidth: '100%',
            width: '100%',
            height: '100%',
        } : {};

        let defaultOptions = {
            addClass: 'search-modal-container showScrollModal',
            blockScroll: true,
            ...mobileOptions
        };

        this._modal = new Modal(_.extend(defaultOptions, options), this._isMobile ? false : isUseGlobal);

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
        this._isModal = true;
        this._modal.On('CLOSE', this, (eventArgs: any) => {
            $(window).off('keydown');
            self.GatherSettings();
        });
    }

    Close(): void {
        if (this._modal) {
            $(window).off('keydown');
            this._modal.Close();
        }
    }

    Show(options: any = null, isUseGlobal: boolean = true, alternativeEntities: Array<EntityModel> = []): P.Promise<SearchScreenModel> {
        return this.LoadSearchScreenModel({showParams: {options, isUseGlobal, alternativeEntities}});
    }

    LoadSearchScreenModel({showParams, altEntityId}: {
        showParams?: { options: any; isUseGlobal: boolean; alternativeEntities: Array<EntityModel> };
        altEntityId?: number
    }) {
        BlockUI.Block();
        let params = altEntityId ? {EntityId: altEntityId} : {
            EntityId: this._entityId,
            TableName: this._entityName,
            ControlId: this._controlId,
            Sharing: this._sharing,
            FieldId: this._fieldId,
            LeftEntityId: this._leftEntityId,
            RightEntityId: this._rightEntityId,
            LeftRecordId: this._leftRecordId,
            RightRecordId: this._rightRecordId
        };
        let getSearchScreenPromise = SearchScreenStore.GetScreenModel(params)

        getSearchScreenPromise
            .then(model => {
                this._entityId = model.EntityId;
                this._model = model;
                const baseEntity = {
                    EntityId: model.EntityId,
                    EntityName: model.EntityName,
                    EntityTranslatedName: model.EntityTranslatedName,
                    Icon: model.IconModel,
                    BaseEntity: true
                };

                if (baseEntity.EntityName === 'SYS_HISTORY')
                {
                    this._addButtonVisible(false);
                }

                this._duplicatesVisible((EDIT & model.TableSecurityRights) === EDIT &&
                    (DELETE & model.TableSecurityRights) === DELETE && model.AllowDeduplicationMode);

                if (showParams) {
                    this._altEntities([baseEntity, ..._.extend(model.AlternativeEntities, {}), ...showParams.alternativeEntities]);
                    const controlAltEntities = UserVarsManager.Instance.GetRecordAltEntities(this._controlId);

                    this._altEntities().map((altEntity) => {
                        if (!altEntity) return;
                        if (controlAltEntities && controlAltEntities.altEntities) {
                            if (controlAltEntities.altEntities.indexOf(altEntity.EntityId) !== -1) {
                                altEntity.IsChecked = ko.observable(true);
                            } else {
                                altEntity.IsChecked = ko.observable(false);
                            }
                        } else {
                            altEntity.IsChecked = ko.observable(true);
                        }
                        altEntity.RecordsCount = ko.observable('N/A');
                        altEntity.IsChecked && altEntity.IsChecked.subscribe((value) => {
                            if (!value) {
                                altEntity.RecordsCount('N/A');
                            }

                            const altEntitiesData = [];

                            this._altEntities().map((altEntity) => {
                                altEntity.IsChecked() && altEntitiesData.push(altEntity.EntityId);
                            });
                            UserVarsManager.Instance.SetRecordAltEntities(this._controlId, altEntitiesData);


                        });
                        if (altEntity.BaseEntity) {
                            altEntity.IsSelected = ko.observable(true);
                        } else {
                            altEntity.IsSelected = ko.observable(false);
                            altEntity.BaseEntity = false;
                        }

                    });
                }
                this.GenerateActions();
                this.AddSearchGroups(model.SearchGroups);
                this.AddSearchFields(model.SearchFields);
                this._entityIcon(new Icon(model.IconModel));

                const isBackLinkEnabled = this._model.EntityTypeName == TABLE_TYPES.Entity && this._model.EntityName !== SYSTEM_TABLE_NAMES.SYS_USERS;
                this._isBackLinkEnabled(isBackLinkEnabled);
                if (!isBackLinkEnabled) {
                    this._searchByMainRelationType(SearchByMainRelationTypes.Off);
                }

                this._hasBarcodeFields(model.HasBarcodeFields);

                if ((!this._model.SearchFields || this._model.SearchFields.length === 0) && this._entityType != TABLE_TYPES.Sub) {
                    new Notifier().Warning(NOTIFICATIONS.NO_SEARCHABLE_FIELDS);
                } else {
                    this.RestoreSearchSettings();

                    this.CheckAddQueryButton()
                        .then((shouldOpenScreen: boolean) => {
                            if (shouldOpenScreen) {
                                if (showParams) {
                                    this.Render(showParams.options, showParams.isUseGlobal);
                                }
                                this.SearchRecords();
                                this.HandleFilters();
                            } else {
                                new Notifier().Warning(NOTIFICATIONS.NO_QUERIES_FOUND);
                            }
                        })
                        .fail(error => {
                            if (error.value) {
                                new Notifier($(this._el)).Warning(error.value);
                            } else {
                                new Notifier($(this._el)).Failed(error.message);
                            }
                        });
                }
            }).always(() => BlockUI.Unblock());

        return getSearchScreenPromise;
    }

    private AddSearchFields(searchFields: Array<SearchFieldModel>) {
        this._searchMode().AddSearchFields(...searchFields);
    }

    private SetOptionClass(option, item) {
        if (this.IsGroupItem(item)) {
            $(option).addClass('group-option');
        }
    }

    RestoreSearchSettings() {
        var searchParams = UserVarsManager.Instance.GetSearchParams(this._entityId);

        if (searchParams) {
            const searchGroupValue = searchParams.lifeStatusId || SearchGroups[SearchGroups.Active];

            this._searchGroup(searchGroupValue);
            const recentRecords = searchParams.recentRecords;
            const recordTypeValue = searchParams.recordTypeValue;
            const searchModeValue = searchParams.searchModeValue;
            let searchByRelations = searchParams.searchByRelationsValue;

            if (this._hasLinkingCondition) {
                searchByRelations = SearchByMainRelationTypes.ByConditions;
            }
            else if (this._showSearchByLinkedDocuments) {
                searchByRelations = SearchByMainRelationTypes.ByLinkedDocuments;
            }
            else if (searchByRelations === SearchByMainRelationTypes.ByLinkedDocuments) {
                searchByRelations = SearchByMainRelationTypes.Off;
            }

            if (this._subjectEntityId && searchByRelations && this._isBackLinkEnabled()) {
                this._searchByMainRelationType(searchByRelations);
            }

            let filterState = this._dropDownFilterState != null ? this._dropDownFilterState : searchParams.enableFiltering;

            this._enableFilter(filterState);

            if (recentRecords === true) {
                this._recentRecords(recentRecords);
            } else {
                this._recentRecords(false);
            }

            let currentRecordType = _.find(this._recordTypes(), recordType => {
                return recordType.Value === recordTypeValue;
            });

            if (currentRecordType && this._recordType() && this._recordType().Value != currentRecordType.Value) {
                this._recordType(currentRecordType);
            }

            if (searchModeValue) {
                this._selectedSearchMode(this._searchMode().GetSearchItem(searchModeValue.Name) || this._searchMode().GetDefaultMode());
            } else {
                this._selectedSearchMode(this._searchMode().GetDefaultMode());
            }
        } else {
            this._selectedSearchMode(this._searchMode().GetDefaultMode());
        }
    }

    get Icon(): KnockoutObservable<Icon> {
        return this._entityIcon;
    }

    get SearchTerm(): KnockoutObservable<string> {
        return this._searchTerm;
    }

    get Recent(): Array<UserVarsRecordModel> {
        this._recent = this._model.GroupNameToRecords.recent;
        return this._recent;
    }

    get Favorites(): Array<UserVarsRecordModel> {
        this._favorites = this._model.GroupNameToRecords.favorites;
        return this._favorites;
    }

    get RecentRecords(): KnockoutObservable<boolean> {
        this._paginator.PageNumber = 1;
        return this._recentRecords;
    }

    SetRecentRecords(): void {
        this._recentRecords(!this._recentRecords());
    }

    ToggleEnableFilterValue(): void {
        this._enableFilter(!this._enableFilter());
    }

    get HasRecent(): boolean {
        return this.Recent.length > 0;
    }

    get HasFavorites(): boolean {
        return this.Favorites.length > 0;
    }

    get RecordTypes(): KnockoutObservableArray<IRecordType> {
        return this._recordTypes;
    }

    get RecordType(): KnockoutObservable<IRecordType> {
        return this._recordType;
    }

    EnterKey() {
        this._paginator.PageNumber = 1;
        this.SearchRecords();
        this._isClick(true);
    }

    SearchClick() {
        this._isClick(true);
        this._paginator.PageNumber = 1;
        this.SearchRecords();
    }

    OpenBarcodeScanner() {
        const barcodeScanner = new BarcodeScanner();

        barcodeScanner.On('SUBMIT', this, eventArgs => {
            this._searchTerm(eventArgs.data.value);
            this._selectedRecords([]);
            this._isClick(true);
            this._paginator.PageNumber = 1;
            this.SearchRecords(undefined, true);
        });

        barcodeScanner.Show();
    }

    OpenBarcodeScannerForBulkScan() {
        const barcodeScanner = new BarcodeScanner();

        let value;

        barcodeScanner.On('SUBMIT', this, eventArgs => {
            this._searchTerm(eventArgs.data.value);
            value = eventArgs.data.value;
            this._selectedRecords([]);
            this._isClick(true);
            this._paginator.PageNumber = 1;
            this.SearchRecords(undefined, true);
        });

        barcodeScanner.On('CLOSE', this, eventArgs => {
            if (!value) {
                this.StopBulkBarcodeScan();
            }
        });

        barcodeScanner.Show();
    }

    StartBulkBarcodeScan() {
        this._isBulkBarcodeScanning = true;

        this.Trigger('BULK_BARCODE_SCAN_STARTED');
    }

    StopBulkBarcodeScan() {
        this._isBulkBarcodeScanning = false;

        this.Trigger('BULK_BARCODE_SCAN_STOPPED');
    }

    DivideRecordsPerPage() {
        const popupHeight_Global = parseInt(GlobalManager.Instance.GetGlobal(GLOBALS.POPUP_HEIGHT_IN_PERCENT));
        const isSearchPagination: boolean = !!this._paginator.PageNumber;

        const paginationDetail_navigationBtn_height = 81,
            multiSelectBtnHeight = this._multiSelectMode ? 35 : 0,
            searchPaginationBoxHeight = paginationDetail_navigationBtn_height + multiSelectBtnHeight;

        const searchToolbarHeight: number = this._isMobile ? 40 : 0,
            paddingJBox: number = this._isMobile ? 15*2 : 0,
            boxShadowWrapperPadding: number = this._isMobile ? 10*2 : 0,
            searchResultMargin: number = this._isMobile ? 10*2 : 0,
            recalculationMarginPadding: number = paddingJBox + boxShadowWrapperPadding + searchResultMargin;

        const staticSpace = (this._isMobile && isSearchPagination) ? searchPaginationBoxHeight : 160;
        const rowHeight = 45;
        const calculatedAltSpace: any = $('.alternativeEntitiesContainer').outerHeight(true);
        const reservedSpace = !this._isMobile && calculatedAltSpace && calculatedAltSpace + staticSpace || staticSpace;
        const windowHeight = window.innerHeight; //get window height

        let popupHeight: number = this._isMobile ? windowHeight : windowHeight * (popupHeight_Global / 100); //get popup height
        const heightForRows = popupHeight - parseInt(reservedSpace) - searchToolbarHeight - recalculationMarginPadding; //get height for rows inside the popup
        const recordsPerPage = (heightForRows - rowHeight) / rowHeight; //get approximate amount of rows

        if (recordsPerPage < 5) { //can't be less than 5
            this._adaptiveRecordsCount = 5;
        } else {
            this._adaptiveRecordsCount = Math.floor(recordsPerPage);
        }
        this._paginator.RecordsPerPage = this._adaptiveRecordsCount;
    }

    SearchByAltEntity(altEntity) {
        this._selectedRecords([]);
        if (altEntity.IsSelected() !== true) {
            this._altEntities().map(entity => entity.IsSelected(false));
            altEntity.IsSelected(true);
            if (altEntity.BaseEntity) {
                this.SelectedAltEntityId = null;
            } else {
                this.SelectedAltEntityId = altEntity.EntityId;
            }
            this.RefreshScreenAfterAltEntityChange(altEntity.EntityId);

        }
        this._isBaseEntity(this._entityId === altEntity.EntityId);
    }

    RefreshScreenAfterAltEntityChange(altEntityId: number) {
        this.UnHandleFilters();

        this._searchMode(new SearchModeModel());
        this._recordType(this._recordTypes()[0]);
        this._orderFields = [];
        this._searchByMainRelationType(SearchByMainRelationTypes.Off);
        this._paginator.PageNumber = 1;

        this.LoadSearchScreenModel({altEntityId});
    }

    SearchRecords(entityId?: number, isFromBarcode?: boolean) {
        var self = this;
        let element = document.getElementById('searchScreen');

        BlockUI.Block({
            Target: element
        });

        const selectedMainEntityId = entityId || (this.SelectedAltEntityId || this._entityId);
        var requestModel = this.GetSearchRequestModel(entityId);

        SearchStore.Search(requestModel)
            .then(result => {
                if (result.RecordsCount == 0) {
                    this.Trigger('RECORDS_NOT_FOUND');
                }

                result.AlternativeSearchList && result.AlternativeSearchList.map((altEntity) => {
                    const selectedEntity = _.find(this._altEntities(), (item) => {
                        return item.EntityId === altEntity.EntityId;
                    })

                    selectedEntity && selectedEntity.RecordsCount(altEntity.RecordsCount);
                });

                let baseEntity = _.find(this._altEntities(), (item) => {
                    return item.EntityId === selectedMainEntityId
                });
                if (baseEntity) {
                    baseEntity.RecordsCount(result.RecordsCount);
                }

                let grid = new SearchResultGrid(
                    result,
                    this._selectedRecords,
                    requestModel,
                    this._recordType().Value === RecordTypes.Root,
                    this._multiSelectMode && this._isBaseEntity(),
                    this._duplicatesType().Title === 'On',
                    this._selectedDuplicatesRecords);

                grid.On(SEARCH_SCREEN_EVENTS.SELECT_RECORD, this, (eventArgs) => {
                    this.SelectRecord(eventArgs.data.Row, eventArgs.data.IsSelected, false, eventArgs.data.RowClick)
                });

                grid.On(SEARCH_SCREEN_EVENTS.DUPLICATES_RECORD_SELECTED, this, (eventArgs) => {
                    this.SelectDuplicatesRecord(eventArgs.data.Row, eventArgs.data.IsDuplicatesSelected);
                });

                grid.On(SEARCH_SCREEN_EVENTS.SORT, this, (eventArgs) => {
                    this._orderFields = eventArgs.data.OrderFields;
                    this.SearchRecords();
                });


                _.each(result.SearchFields, (column) => {
                    if (this._orderFields[0] && this._orderFields[0].Id === column.Id) {
                        column.SortDirection = Number(this._orderFields[0] && this._orderFields[0].Order || SortOrder.Asc);
                    } else {
                        column.SortDirection = Number(SortOrder.Both);
                    }

                    column.SortOrder = ko.observable(column.SortDirection);

                    column.SortOrderClassName = ko.computed({
                        owner: this,
                        read: () => {
                            if (!column.Id) {
                                return 'sort-null';
                            }
                            return `sort-${SortOrder[column.SortOrder()].toLowerCase()}`;
                        }
                    });

                    column.Sort = () => {
                        if (!column.Id) {
                            return;
                        }
                        switch (SortOrder[column.SortOrder()]) {
                            case 'Asc':
                                column.SortDirection = SortOrder.Desc;
                                break;
                            case 'Desc':
                                column.SortDirection = SortOrder.Both;
                                break;
                            case 'Both':
                                column.SortDirection = SortOrder.Asc;
                                break;
                        }
                        column.SortOrder(column.SortDirection);

                    };

                    column.SortOrder.subscribe((order) => {
                        this._orderFields = [{
                            Id: column.Id,
                            Order: order
                        }];

                        this.SearchRecords();
                    });
                });

                this._searchResultGrid(grid);
                this._searchResultGrid().SelectRecords(this._selectedRecords());

                this._searchResult(result.Rows);
                this._nameFieldName = result.NameField;
                this._paginator.TotalRecords = result.RecordsCount;
                this.UpdatePaginationInfo();
                $(window).trigger('resize');
                if (self._isClick()) {
                    $(element).find('.search-table-row:first-child').focus();
                    self._isClick(false);
                }

                if (isFromBarcode) {
                    if (result.Rows.length === 1) {
                        this.SelectRecord(result.Rows[0], false, isFromBarcode);
                    } else if (result.Rows.length === 0) {
                        this.StopBulkBarcodeScan();
                    } else {
                        const clickHandler = (event: MouseEvent) => {
                            document.removeEventListener('click', clickHandler);

                            const resultTables = element.querySelectorAll('table');

                            // check on click outside of tables with search results
                            if (![...resultTables].some(table => event.target instanceof Node && table.contains(event.target))) {
                                this.StopBulkBarcodeScan();
                            }
                        };

                        document.addEventListener('click', clickHandler);
                    }
                }
            }).fail(error => {
            if (error.value) {
                new Notifier($(this._el)).Warning(error.value);
            } else {
                new Notifier($(this._el)).Failed(error.message);
            }
        })
            .always(() => {
                this.UnBlockSearchScreen();
            });

        this._isFirstLoad = false;
    }

    CheckAddQueryButton(): P.Promise<boolean> {
        const deferred = P.defer<boolean>();

        if (!this._isAddQueryButton) {
            deferred.resolve(true);
            return deferred.promise();
        }

        var requestModel = this.GetSearchRequestModel();

        SearchStore.Search(requestModel)
            .then(result => {
                let areQueriesFound = result.Rows.length !== 0;
                deferred.resolve(areQueriesFound);
            })
            .fail(error => deferred.reject(error));

        return deferred.promise();
    }

    UpdatePaginationInfo() {
        if (this._searchResult() && this._searchResult().length === 0) {
            this._paginationInfo('');
        } else {
            let startRecordNumber = this._paginator.PageNumber * this._adaptiveRecordsCount - (this._adaptiveRecordsCount - 1);
            let endRecordNumber = startRecordNumber + (this._searchResult() && this._searchResult().length - 1);
            let label = LABELS.SEARCH_SCREEN_PAGINATION
                .replace('{StartRecordNumber}', startRecordNumber.toString())
                .replace('{EndRecordNumber}', endRecordNumber.toString())
                .replace('{TotalRecords}', this._paginator.TotalRecords.toString());

            this._paginationInfo(label);
        }
    }

    BlockSearchScreen() {
        var searchResultGrid = $(this._el).find('#searchScreen');
        Metronic.blockUI({target: searchResultGrid});
    }

    UnBlockSearchScreen() {
        BlockUI.Unblock(document.getElementById('searchScreen'));
        if (!(this._searchResult() && this._searchResult().length)) {
            this._searchResultMessage(this._labels.NO_RECORDS_FOUND);
        }
    }

    SelectRecord(selectedRow: SearchResultRowModel, isSelected: boolean, isFromBarcode: boolean, rowClick?: boolean) {
        let typeId = selectedRow.TypeId;
        let typeName = selectedRow.TypeName;
        let recordId = selectedRow.RecordId;
        let recordName = this.GetRowValue(selectedRow.RowValues, this._nameFieldName);

        BlockUI.Block();
        SearchStore.IsUserAllowedTo({
            TableId: this.SelectedAltEntityId || this._entityId,
            RecordId: recordId,
            SecurityWord: RECORD_SECURITY_WORDS.ReadOnly
        })
        .always(()=>{
            BlockUI.Unblock();
        })
        .then(isUserAllowed => {

            isUserAllowed = isUserAllowed || this._model.EntityName === 'SYS_USERS';

            if (isUserAllowed) {
                if (this.SelectedAltEntityId) {
                    var entityId = this.SelectedAltEntityId;
                    this.Trigger('ALT_ENTITY_RECORD_SELECTED', {
                        EntityId: entityId,
                        TypeId: typeId,
                        TypeName: typeName,
                        RecordId: recordId,
                        Name: recordName,
                        ShowInModal: false
                    });
                } else {
                    if (this._multiSelectMode && !isFromBarcode) {
                        let existsRecord = this._selectedRecords.indexOf(recordId) > -1;

                        if (isSelected) {
                            if (!existsRecord) {
                                this._selectedRecords.push(recordId);
                            }
                        } else {
                            this._selectedRecords.splice(this._selectedRecords.indexOf(recordId), 1);
                        }

                        if ( rowClick ){
                            this.OneSelect()
                        }
                    } else {
                        this.Trigger('RECORD_SELECTED', {
                            TypeId: typeId,
                            TypeName: typeName,
                            RecordId: recordId,
                            Name: recordName,
                            TableId: this._entityId,
                            SearchTerm: this._searchTerm()
                        });
                    }
                }


                if (this._closeAfterSelectingRecord && !this._isBulkBarcodeScanning && !this._multiSelectMode) {
                    this.Cancel();
                }
            } else {
                new Notifier().Warning(NOTIFICATIONS.RECORD_IS_SEARCH_ONLY);
            }
        }).fail(error => new Notifier().Failed(error.message));
    }

    SelectDuplicatesRecord(selectedRow: SearchResultRowModel, isDuplicatesSelected: boolean) {
        let typeId = selectedRow.TypeId,
            typeName = selectedRow.TypeName,
            recordId = selectedRow.RecordId,
            recordName = this.GetRowValue(selectedRow.RowValues, this._nameFieldName);

        let existsDuplicatesRecord = _.find(this._selectedDuplicatesRecords(), (item) => {
            return item.RecordId === recordId && item.TypeId === typeId;
        });

        if (isDuplicatesSelected) {
            if (!existsDuplicatesRecord) {
                this._selectedDuplicatesRecords.push({
                    TypeId: typeId,
                    TypeName: typeName,
                    RecordId: recordId,
                    Name: recordName,
                    TableId: this._entityId
                });
            }
        } else {
            if (!!existsDuplicatesRecord) {
                this._selectedDuplicatesRecords.splice(this._selectedDuplicatesRecords.indexOf(existsDuplicatesRecord), 1);
            }
        }

    }

    SelectRecordFromRecent(userVarsRecordModel: UserVarsRecordModel) {
        this.Trigger('RECORD_SELECTED', {
            TypeId: userVarsRecordModel.TypeId,
            RecordId: userVarsRecordModel.Id
        });

        if (this._closeAfterSelectingRecord && !this._isBulkBarcodeScanning) {
            $(window).off('keydown');
            this.Cancel();
        }
    }

    SelectRecordType(recordType: IRecordType) {
        this._recordType(recordType);
    }

    InfoSearchValueTooltip(): string {
        return this._labels.SPECIAL_CHARACTERS.replace(/\\n/g, '<br>');
    }

    NewRecord() {
        this.Trigger('NEW_RECORD');
    }

    Cancel() {
        if (this._modal) {
            this.GatherSettings();
            $(window).off('keydown');
            this._modal.Close();
        }
    }

    GatherSettings() {
        const searchModeValue = this._selectedSearchMode();
        const searchGroupName = this._searchGroup();
        const recordTypeValue = this.RecordType().Value;
        const enablingFiltering = this._enableFilter();
        UserVarsManager.Instance.SetSearchParams(
            this.SelectedAltEntityId || this._entityId,
            this._recordType,
            searchModeValue,
            searchGroupName,
            recordTypeValue,
            null,
            null,
            enablingFiltering,
            this._searchByMainRelationType()
        );
    }

    ShowModal() {
        if (this._modal) {
            this._modal.Show();
        }

    }

    AfterRender(el: HTMLElement) {
        this._el = el[0];

        this.ShowModal();
        this._focusInput(!this._isMobile);

        if (!this._isFirstLoad) {
            this.BlockSearchScreen();
        }
    }

    private GenerateActions() {
        this._addButtonDisabled(!this._model.AddingRecordAllowed);
    }

    private AddSearchGroups(searchGroups: Array<SearchGroups>) {
        const isSimpleLifeStatus = searchGroups.every((group, index) => {
            return group === SimpleFilterGroupsSet[index]
        });

        this._isSimpleLifeStatus(isSimpleLifeStatus);

        !this._isSimpleLifeStatus() && this.SortSearchGroups(searchGroups);

        const searchGroupsMapped = searchGroups.map((group) => {

            if (group === SearchGroups.All) {
                return {
                    Name: LABELS.ALL,
                    Id: group
                }
            } else if (group === SearchGroups.Active) {
                return {
                    Name: LABELS.ACTIVE_LABEL,
                    Id: group
                }
            } else if (group === SearchGroups.Archive) {
                return {
                    Name: LABELS.ARCHIVE_LABEL,
                    Id: group
                }
            } else if (group === SearchGroups.Example) {
                return {
                    Name: LABELS.MAKE_EXAMPLE,
                    Id: group
                }
            } else if (group === SearchGroups.Planned) {
                return {
                    Name: LABELS.RLANNED_LABEL,
                    Id: group
                }
            } else if (group === SearchGroups.Acquired) {
                return {
                    Name: LABELS.ACQUIRED_LABEL,
                    Id: group
                }
            } else if (group === SearchGroups.Maintained) {
                return {
                    Name: LABELS.MAINTAINED_LABEL,
                    Id: group
                }
            } else if (group === SearchGroups.Operational) {
                return {
                    Name: LABELS.OPERATIONAL_LABEL,
                    Id: group
                }
            } else if (group === SearchGroups.Retired) {
                return {
                    Name: LABELS.RETIRED_LABEL,
                    Id: group
                }
            }
        });

        this._searchGroups(searchGroupsMapped);
    }

    private IsGroupItem(dropdownItem: ISearchGroup): boolean {
        return !this._isSimpleLifeStatus() &&
            !!LifeStatusGroupIndexes
                .filter((mainGroup) => {
                    return SearchGroups[SearchGroups[mainGroup]] === dropdownItem.Id;
                }).length;
    }

    private SortSearchGroups(searchGroupsIndexes: number[]) {
        searchGroupsIndexes.sort((ind, nextInd) => {
            return MappedSearchGroups[SearchGroups[ind]] - MappedSearchGroups[SearchGroups[nextInd]];
        });
    }

    private SearchByMainRelation() {
        if (this._searchByMainRelationType() === SearchByMainRelationTypes.Off) {
            this._searchByMainRelationType(SearchByMainRelationTypes.ByRelations);
        } else if (this._searchByMainRelationType() === SearchByMainRelationTypes.ByRelations) {
            this._searchByMainRelationType(SearchByMainRelationTypes.ByMainRelations);
        } else if (this._searchByMainRelationType() === SearchByMainRelationTypes.ByMainRelations && this._hasLinkingCondition) {
            this._searchByMainRelationType(SearchByMainRelationTypes.ByConditions);
        } else if (this._showSearchByLinkedDocuments &&
            (this._searchByMainRelationType() === SearchByMainRelationTypes.ByMainRelations || this._searchByMainRelationType() === SearchByMainRelationTypes.ByConditions)) {
            this._searchByMainRelationType(SearchByMainRelationTypes.ByLinkedDocuments);
        } else {
            this._searchByMainRelationType(SearchByMainRelationTypes.Off);
        }
    }

    private FindSearchGroupName() {
        const id = this._searchGroup();
        const searchGroup = _.find(this._searchGroups(), group => group.Id === id);
        return searchGroup && searchGroup.Name;
    }

    private GetRowValue(rowValues: any, columnName: string) {
        const value = _.find(rowValues,
            (r: any) => {
                return r.Key === columnName
            });
        return value ? value.Value : null;
    }

    private OneSelect() {
        if (this._selectedRecords().length === 1) {
            this.Trigger('RECORDS_SELECTED', {Ids: this._selectedRecords(), TableId: this._entityId});
            this.Cancel();
        }
    }

    private Select() {
        if (this._selectedRecords().length > 0) {
            this.Trigger('RECORDS_SELECTED', {Ids: this._selectedRecords(), TableId: this._entityId});
            this.Cancel();
        }
    }

    private Merge() {
        if (this._selectedDuplicatesRecords && this._selectedDuplicatesRecords().length == 2) {
            this.InitLockWithRequestFromDeduplication(this._selectedDuplicatesRecords());
        }
    }

    private GetSearchRequestModel(entityId?: number) {
        this._searchResultMessage('');
        this.DivideRecordsPerPage();

        let searchByRelations;
        if (this._searchByMainRelationType() === SearchByMainRelationTypes.ByRelations) {
            searchByRelations = this._searchByRelations;
        } else if (this._searchByMainRelationType() === SearchByMainRelationTypes.ByMainRelations) {
            searchByRelations = _.filter(this._searchByRelations, item => item.IsMain);
        }

        const selectedMainEntityId = entityId || (this.SelectedAltEntityId || this._entityId);


        var requestModel: ISearchRequestModel = {
            ScreenData: this._screenData,
            EntityId: selectedMainEntityId,
            SearchType: this._selectedSearchMode().SearchType,
            SearchField: this._selectedSearchMode().DefaultName || this._selectedSearchMode().Name,
            SearchGroup: this._searchGroup(),
            SearchPhrase: this._searchTerm()?.trim(),
            RecentRecords: this._recentRecords(),
            RecordType: RecordTypes[this._recordType().Value],
            RecordsPerPage: this._paginator.RecordsPerPage,
            PageNumber: this._paginator.PageNumber,
            ParentEntityId: this._subjectEntityId,
            ParentTypeId: this._subjectTypeId,
            ParentRecordId: this._subjectRecordId,
            IsParentLinking: this._isParentLinking,
            OrderFields: this._orderFields,
            ControlId: this._controlId,
            FieldId: this._fieldId,
            LeftEntityId: this._leftEntityId,
            RightEntityId: this._rightEntityId,
            LeftRecordId: this._leftRecordId,
            RightRecordId: this._rightRecordId,
            KSeq: this._kSeq,
            EnableFilter: this._enableFilter(),
            SearchByMainRelationType: this._searchByMainRelationType(),
            SearchByRelations: searchByRelations,
            Sharing: this._sharing,
            SearchFieldId: this._searchFieldId,
            PlannerLevelEntityId: this._plannerLevelEntityId,
            PlannerLevelRecordId: this._plannerLevelRecordId,
            QuerySubjectId: this._querySubjectId,
            ConditionValues: this._conditionValues,
            AlternativeEntities: _.filter(this._altEntities().map((altEntity) => {
                if (altEntity && altEntity.IsChecked && altEntity.IsChecked() && altEntity.EntityId !== selectedMainEntityId) {
                    return altEntity.EntityId;
                } else {
                    return null;
                }
            }), elem => {
                return elem !== null;
            }),
            SearchByTypes: this._searchByTypes,
            RelatedTableFieldId: this._relatedTableFieldId,
            RelatedTableId: this._relatedTableId,
            RelatedTablePk: this._relatedTablePk
        };

        if (this._alternativeSubjectsType().Title === 'On') {
            requestModel.FilterByAlternativeEntities = this.GetFilterByAlternativeEntities();
        }

        return requestModel;
    }

    SaveChangesDeduplication() {
        const selectedRecords = this._selectedDuplicatesRecords();
        BlockUI.Block();

        const changesToSave = [];
        this._grid.Rows().map((row: any) => {
            if (row._disabled()) return;
            let fieldId = null;
            let actionType = null;
            row.Cells().map((cell: DeduplicationCell) => {
                if (cell._fieldId) {
                    fieldId = cell._fieldId;
                }
                if (cell._selectionType()) {
                    actionType = cell._selectionType();
                }
            });
            if (fieldId && actionType && actionType !== 'leftSide') {
                changesToSave.push({
                    FieldId: fieldId,
                    Action: Actions[actionType]
                });
            }
        });

        const params = {
            EntityId: this._entityId,
            LeftRecordId: selectedRecords[0].RecordId,
            RightRecordId: selectedRecords[1].RecordId,
            Changes: [...changesToSave]
        };

        DeduplicationStore.Deduplicate(params)
            .always(() => {
                BlockUI.Unblock();
            })
            .then(result => {
                if (!result.IsSuccessfull) {
                    new Notifier().Failed(result.ErrorMessage);
                    return;
                }

                this._selectedDuplicatesRecords.splice(0, 2);

                this.SearchRecords();
                this._grid.Cancel();

            }).fail((err) => {
            new Notifier().Failed(err.message);
        });
    }

    InitLockWithRequestFromDeduplication(selectedDuplicatesRecords: Array<ISelectedRecord>) {
        const params = {
            EntityId: this._entityId,
            LeftRecordId: selectedDuplicatesRecords[0].RecordId,
            RightRecordId: selectedDuplicatesRecords[1].RecordId
        };
        DeduplicationStore.GetDeduplicationRecordModel(params)
            .then(result => {
                if (!result.IsSuccessfull) {
                    new Notifier().Failed(result.ErrorMessage);
                    return;
                }

                this._grid.RenderModal();
                BlockUI.Unblock(this._grid.GetModal().Wrapper);

                this._grid.SetPopUpDataFromSearch(
                    result.ResultObject,
                    this._model.EntityName,
                    null,
                    this._entityId
                );

            }).fail((err) => {
            new Notifier().Failed(err.message);
        });
    }
}
