import * as ko from "knockout";
import {deserialize, deserializeAs} from 'libs/cerialize';
import {FieldModel} from 'QueryBuilder/Models/FieldModel';
import {IconModel} from 'Core/Controls/BaseControl/Models/IconModel';
import {Icon} from 'Core/Icon/Icon';
import {TranslationModel} from "Core/Controls/BaseControl/Models/TranslationModel";

enum RelationTypes
{
	OneToOneSubject,
	OneToOne,
	OneToMain,
	OneToMany
}

export class RelationTypeModel{
	Title: string;
	RelationType: RelationTypes;
}

export class EntityModel {

	@deserialize
	DbId: number;

	@deserialize
	EntityId: number;

	@deserialize
	ShortName: string;

	@deserialize
	EntityName: string;

	@deserialize
	EntityNameTranslation: string;

	@deserialize
	EntityFullName: string;

	@deserialize
	EntityFullNameTranslation: string;

	@deserializeAs(TranslationModel)
	Translations: Array<TranslationModel>;

	@deserialize
	TypeName: string;

	@deserializeAs(FieldModel)
	Fields: Array<FieldModel>;

	@deserializeAs(EntityModel)
	RelatedSubEntities: Array<EntityModel>;

	@deserializeAs(EntityModel)
	LinkEntity: EntityModel;

	@deserialize
	FontName: string;

	@deserialize
	IconName: string;

	@deserialize
	GraphicalIcon: string;

	@deserialize
	RelationTypes: Array<number>;

	@deserialize
	IsView: boolean;

	IsSelected: KnockoutObservable<boolean>;

	IsMain: KnockoutObservable<boolean>;
	
	RelationTypeTitle(relationType: number){
		return RelationTypes[relationType];
	}

	get EntityIcon(): Icon {
		if (!this._entityIcon) {
			this._entityIcon = this.GetEntityIcon();
			return this._entityIcon;
		}
		return this._entityIcon;
	}

	GetEntityIcon(): Icon {
		var isImage = !!this.GraphicalIcon;
		var iconModel = new IconModel();
		iconModel.IsIcon = !isImage;
		iconModel.IsImage = isImage;
		iconModel.Name = this.IconName;
		iconModel.Image = this.GraphicalIcon;
		iconModel.FontName = this.FontName;
		return new Icon(iconModel);
	}

	get GetRelationTypes(){
		var relations = [];
		_.each(this.RelationTypes, (item)=>{
			var type = new RelationTypeModel();
			type.Title = RelationTypes[item];
			type.RelationType = item;
		});
		return relations;
	}

	_entityIcon: Icon;

	constructor() {

		this.Fields = [];
		this.RelationTypes = [];
		this.IsSelected = ko.observable(false);
		this.IsMain = ko.observable(false);

	}
}