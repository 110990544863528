import * as ko from 'knockout';
import 'libs/ion.rangeSlider';

import { LABELS } from "Core/Components/Translation/Locales";
import {Event} from 'Core/Common/Event';
import {Modal} from "Core/Common/Modal";

import DownloadsTemplate from 'Core/ProfilePage/Downloads/Templates/Downloads.html';
ko.templates['Core/ProfilePage/Downloads/Templates/Downloads'] = DownloadsTemplate;

export class Downloads extends Event{
    private _isReady: KnockoutObservable<boolean>;
    private _labels = LABELS;
    private _modal: Modal;

    constructor() {
        super();
        this._isReady = ko.observable(true);
    }

    GetTemplateName() {
        return 'Core/ProfilePage/Downloads/Templates/Downloads';
    }

    RenderByTargetId(target: string): void {
        ko.cleanNode(document.getElementById(target));
        ko.applyBindings(this, document.getElementById(target));
    }

    ShowModal() {
        if (this._modal) {
            this._modal.Show();
        }
    }

    Render() {
        this._modal = new Modal({
            addClass: 'downloads-modal',
        }, false);
        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
        this.ShowModal();
    }

    AfterRender() {
    }
}