import * as ko from 'knockout';
import * as $ from 'jquery';
import 'libs/ion.rangeSlider';

import { BlockUI } from 'Core/Common/BlockUi';
import { DeduplicationStore } from 'Core/ProfilePage/Deduplication/Stores/DeduplicationStore';
import { DeduplicationGrid } from 'Core/ProfilePage/Deduplication/DeduplicationEditor/DeduplicationGrid';
import { Paginator } from 'Core/Components/Paginator/Paginator';
import { GlobalManager, GLOBALS } from 'Core/GlobalManager/GlobalManager';
import { EVENTS as PAGINATOR_EVENTS } from 'Core/Components/Paginator/Constants';
import { Notifier } from 'Core/Common/Notifier';

import { DeduplicationSubjectsModel } from 'Core/ProfilePage/Deduplication/Models/DeduplicationSubjectsModel';
import {Event} from 'Core/Common/Event';

import {
	ConfirmationDialog,
	EVENTS as ConfirmationDialogEvents,
	Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import { NOTIFICATIONS, LABELS, CONFIRMATIONS } from "Core/Components/Translation/Locales";

import DeduplicationTemplate from 'Core/ProfilePage/Deduplication/Templates/Deduplication.html';
import {SortModel} from "Core/Controls/Grid/Models/SortModel";

ko.templates['Core/ProfilePage/Deduplication/Templates/Deduplication'] = DeduplicationTemplate;



export class Deduplication extends Event{
	private _isReady: KnockoutObservable<boolean>;
	private _grid: DeduplicationGrid;
	private _paginator: Paginator;
	private _appliedFastFilters: KnockoutObservableArray<any>;
	private _minimalMatchRate: number;
	private _selectedEntity: KnockoutObservable<DeduplicationSubjectsModel>;
	private _recordsPerpage: number;
	private _deduplicationSubjects: KnockoutObservableArray<DeduplicationSubjectsModel>;
	private _matchRecordFieldId: number;
	private _selectedRangeFilterValue: KnockoutObservable<number>;
	private _defaultRangeFromValue: number;
	private _isDeduplicationEnabled: KnockoutObservable<boolean>;
    private _isMatchAllEnabled: KnockoutComputed<boolean>;
    private _sorting: Array<SortModel>;
    private _isRestoreScrollPosition: boolean;
    private _false: boolean = false;
	private _labels = LABELS;
	private _checkStatusTimer: number;

	private _matchStatus: KnockoutObservable<number>;
	private _isLoading: KnockoutObservable<boolean>;

    constructor() {

        super();

        this._isReady = ko.observable(true);
		this._selectedEntity = ko.observable(null);
		this._deduplicationSubjects = ko.observableArray([]);
		this._appliedFastFilters = ko.observableArray([]);
		this._minimalMatchRate = 0;
		this._matchRecordFieldId = null;
		this._defaultRangeFromValue = 1;
		this._selectedRangeFilterValue = ko.observable(this._defaultRangeFromValue);
		this._grid = new DeduplicationGrid();
		const otherGridPageLines = GlobalManager.Instance.GetGlobal(GLOBALS.OTHER_GRID_PAGE_LINES);
		this._recordsPerpage = parseInt(otherGridPageLines);
		this._paginator = new Paginator();
		this._isDeduplicationEnabled = ko.observable(false);
		this._isMatchAllEnabled = ko.computed(() => !!this._selectedEntity());
		this._paginator.RecordsPerPage = isNaN(this._recordsPerpage) ? 10 : this._recordsPerpage;
		this._paginator.On(PAGINATOR_EVENTS.CHANGE, this, (eventArgs) => {
			this.GetRecordsList();
		});

        this.Init();
		this._matchStatus = ko.observable(0);
		this._isLoading = ko.observable(false);

        this._grid.On('SORT', this, (eventArgs: any) =>  this.SortData(eventArgs.data.SortColumns));
	}

	Init() {

		BlockUI.Block();

		DeduplicationStore.GetDeduplicationSubjects().always(() => {
			BlockUI.Unblock();
		}).then((result: any) => {
			//Array < DeduplicationSubjectsModel >
			if (!result.IsSuccessfull) {
				new Notifier().Failed(result.ErrorMessage);
				return;
			}

			if (result.ResultList[0]) {
				this._selectedEntity(result.ResultList[0]);
				this._deduplicationSubjects(result.ResultList);
				this.GetRecordsList();
				this.StartCheckStatusTimer();
				this._selectedEntity.subscribe((entity) => {
					this.StartCheckStatusTimer();
					this.GetRecordsList();
				});
			}

		});

	}

    SortData(sorting: Array<SortModel>) {
        this._sorting = sorting;
        this.LoadData(true);
    }

    LoadData(restoreSortState: boolean = false) {
            if (this._paginator && this._paginator.RecordsPerPage != null && this._selectedEntity()) {
                if (this._paginator.RecordsPerPage === 0) {
                    this._paginator.RecordsPerPage = 5;
                }

                if (this._minimalMatchRate !== 0) {
                    this._paginator.PageNumber = 1
                }

                const params = {
                    EntityId: this._selectedEntity().Id,
                    PageNumber: this._paginator.PageNumber,
                    RecordsPerPage: this._paginator.RecordsPerPage,
                    MinimalMatchRate: this._minimalMatchRate,
                    Sort: this._sorting
                };

                DeduplicationStore.GetMatchedRecords(params).always(() => {
                    BlockUI.Unblock();
                }).then(result => {
                    if (!result.IsSuccessfull) {
                        new Notifier().Failed(result.ErrorMessage);
                        return;
                    }
                    this._grid.SetData(result.ResultObject, this._selectedEntity().Name, this, restoreSortState);
                    let totalRecords = result.ResultObject.TotalRecords;
                    this._paginator.TotalRecords = totalRecords;
                    this._isDeduplicationEnabled(totalRecords !== 0);
                    this._matchRecordFieldId = result.ResultObject.Columns[0].FieldMetadata.Id;

                }).fail((err) => {
                    new Notifier().Failed(err.message);
                });
            }
	}

	GetTemplateName() {
		return 'Core/ProfilePage/Deduplication/Templates/Deduplication';
	}

	GetRecordsList() {
		if (!this._selectedEntity()) return;
		BlockUI.Block();

		const params = {
			EntityId: this._selectedEntity().Id,
			PageNumber: this._paginator.PageNumber,
			RecordsPerPage: this._paginator.RecordsPerPage,
			//FastFilters: this._appliedFastFilters(),
			MinimalMatchRate: this._minimalMatchRate,
            Sort: this._sorting
		};

		DeduplicationStore.GetMatchedRecords(params).always(() => {
			BlockUI.Unblock();
		}).then(result => {
			if (!result.IsSuccessfull) {
				new Notifier().Failed(result.ErrorMessage);
				return;
			}

			this._grid.SetData(result.ResultObject, this._selectedEntity().Name, this, true);
			let totalRecords = result.ResultObject.TotalRecords;
			this._paginator.TotalRecords = totalRecords;
			this._isDeduplicationEnabled(totalRecords !== 0);
			this._matchRecordFieldId = result.ResultObject.Columns[0].FieldMetadata.Id;
		}).fail((err) => {
			new Notifier().Failed(err.message);
		});

	}

	get Grid() {
		return this._grid;
	}

	get Paginator() {
		return this._paginator;
	}

	RenderByTargetId(target: string): void {
		ko.cleanNode(document.getElementById(target));
		ko.applyBindings(this, document.getElementById(target));
	}

	DeduplicateAll() {

		const deduplicateConfirmText = CONFIRMATIONS.DEDUPLICATION_RECORDS_DELETION.replace('{matchQuantity}', this._paginator.TotalRecords.toString());

		let confirmationDialog = new ConfirmationDialog({
			Text: deduplicateConfirmText,
			Type: ConfirmationTypes.Warning,
			TextConfirm: LABELS.OK,
			TextDecline: LABELS.CANCEL
		});
		confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED,
			self,
			() => {
				BlockUI.Block();

				const params = {
					EntityId: this._selectedEntity().Id,
					MinimumRate: this._selectedRangeFilterValue()
				}

				DeduplicationStore.DeduplicateAll(params).always(() => {
					BlockUI.Unblock();
				}).then(result => {
					if (!result.IsSuccessfull) {
						new Notifier().Failed(result.ErrorMessage);
						return;
					}

					result.Warnings.forEach(warningMessage => {
						new Notifier().Warning(warningMessage);
					});

					this.GetRecordsList();
				}).fail((err) => {
					new Notifier().Failed(err.message);
				});
			}
		);
		confirmationDialog.Show();



	}

	MatchAll() {
		BlockUI.Block();

		const params = {
			EntityId: this._selectedEntity().Id
		};

		DeduplicationStore.MatchAll(params).always(() => {
			BlockUI.Unblock();
		}).then(() => {
			this.RefreshMatchStatus();
			this.GetRecordsList();
		}).fail((error) => {
			new Notifier().Failed(error);
		});
	}

	DeleteAll() {
		let confirmationDialog = new ConfirmationDialog({
			Text: CONFIRMATIONS.ARE_YOU_SURE_YOU_WANT_TO_DELETE_ALL_MATCH_RECORDS,
			Type: ConfirmationTypes.Question,
			TextConfirm: LABELS.YES,
			TextDecline: LABELS.NO
		});

		confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, () => {
			BlockUI.Block();

			const params = {
				EntityId: this._selectedEntity().Id
			};

			DeduplicationStore.DeleteAll(params).always(() => {
				BlockUI.Unblock();
			}).then(() => {
				this._grid.ResetRows();
				this._paginator.TotalRecords = 0;
				this._isDeduplicationEnabled(false);
			}).fail((error) => {
				new Notifier().Failed(error);
			});
		});

		confirmationDialog.Show();
	}

	StartCheckStatusTimer(){
		this._matchStatus(0);
		this.RefreshMatchStatus();
		clearInterval(this._checkStatusTimer);
		this._checkStatusTimer = setInterval(()=>{
			this.RefreshMatchStatus();
		}, 1000 * 60);
	}

	RefreshMatchStatus(){
		this._isLoading(true);
		DeduplicationStore.GetMatchingStatus(this._selectedEntity().Id)
		.always(()=>this._isLoading(false))
		.then((data)=>{
			this._matchStatus(data);
		});
	}

	AfterRender(el: Array<HTMLElement>) {

		ko.utils.domNodeDisposal.addDisposeCallback(el[0], function() {   
			clearInterval(this._checkStatusTimer);
        });

		$("#matchRateRange").ionRangeSlider({
			type: 'single',
			min: 1,
			max: 100,
			from: this._defaultRangeFromValue,
			onFinish: (data) => {
				this._selectedRangeFilterValue(data.from);
				this._minimalMatchRate = data.from;
				this.LoadData();
			}
		});
	}
}