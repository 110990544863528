import * as ko from 'knockout';

import {FeatureTypes} from "Core/Common/Enums/FeatureTypes";

import {SpimValueModel} from "Core/Controls/Grid/Models/GridDataModel/GridCellValueModel";

import {SpimValueViewModel} from "./SpimValueViewModel";

import LogicTemplate from '../Templates/FeatureTypes/Logic.html';

export class LogicValueViewModel extends SpimValueViewModel {
    private Checked: KnockoutObservable<boolean>;

    constructor(recordId: number, value: boolean) {
        super(recordId);

        this.Checked = ko.observable(value);
    }

    GetTemplate(): any {
        return LogicTemplate;
    }

    GetValue(): SpimValueModel {
        const spimValue = new SpimValueModel();

        spimValue.RecordId = this.recordId;
        spimValue.FeatureType = FeatureTypes.Logic;
        spimValue.LogicalValue = this.Checked();

        return spimValue;
    }
}