import {DataRoleModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/DataRoleModel";

export class FieldSecurityChangesModel {
	StartRoles: DataRoleModel[];
	NewRoles: DataRoleModel[];
	DeletedRoles: DataRoleModel[];

	constructor() {
		this.StartRoles = [];
		this.NewRoles = [];
		this.DeletedRoles = [];
	}
}