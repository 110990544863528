import { deserialize } from 'libs/cerialize';
import { deserializeAs } from 'libs/cerialize';

export class RecordKeyModel {
	@deserialize
	FieldId: number;

	@deserialize
	RecordId: number;
}

export class DocumentDataModel {

	constructor() {
		this.RecordKeys = [];
	}

	@deserialize
	Data: any;

	@deserializeAs(RecordKeyModel)
	RecordKeys: Array<RecordKeyModel>;

	@deserialize
	IsEnableMail: boolean;

	@deserialize
	ApprovalName: string;
}