import * as ko from 'knockout';
import * as _ from 'underscore';
import 'jquery-qrcode';

import { Events } from "Auth/Enums/Events";
import { Event } from "Core/Common/Event";
import {CONFIRMATIONS, LABELS} from "Core/Components/Translation/Locales";

import TotpPasswordFormTemplate from 'Auth/UI/TotpPasswordForm/Templates/TotpPasswordForm.html';
import { Modal } from 'Core/Common/Modal';
ko.templates['Auth/UI/TotpPasswordForm/Templates/TotpPasswordForm'] = TotpPasswordFormTemplate;

class TotpPassword {
    private _digits: KnockoutValidatedObservable<
        { _value: KnockoutObservable<string>; _hasFocus: KnockoutObservable<boolean> }[]
    >;

    constructor(){
        ko.validation.init({ insertMessages: false, grouping: { deep: true } }, true);

        this._digits = ko.validatedObservable(_.times(6, index => {
            const valueObservable = ko.observable('').extend({ validatable: true });

            valueObservable.subscribe((newValue) => {
                if(newValue === null || newValue === ''){
                    return;
                }

                this.ChangeFocus(index);
            });

            return {
                _value: valueObservable,
                _hasFocus: ko.observable(index === 0)
            };
        }));
    }

    private ChangeFocus(index: number){
        const digits = this._digits();

        const nextDigit = digits[index + 1];

        if (nextDigit) {
            for (const digit of digits) {
                digit._hasFocus(false);
            }

            nextDigit._hasFocus(true);
        }
    }

    HandleKeydown(_totpPassword: TotpPassword, event: KeyboardEvent) {
        const input = event.currentTarget as HTMLInputElement;
        const inputIndex = Number(input.dataset.id);

        if (event.key === 'Backspace' && input.selectionStart === 0 && input.selectionEnd === 0 && inputIndex > 0) {
            const digits = this._digits();

            for (const digit of digits) {
                digit._hasFocus(false);
            }

            digits[inputIndex - 1]._hasFocus(true);

            const container = input.closest('.totp-digit-group');
            container.querySelector<HTMLInputElement>(`[data-id="${inputIndex - 1}"]`).select();
        }

        return true;
    }

    Clear(){
        for (const digit of this._digits()) {
            digit._value('');
        }
    }

    get Password(){
        return this._digits().map(digit => digit._value()).join('');
    }

    IsValid() {
        return this._digits.isValid();
    }
}

export class TotpPasswordForm extends Event {
    private _modal: Modal;
    private _error: KnockoutObservable<string>;
    private _totpPassword: TotpPassword;
    public _confirmations = CONFIRMATIONS;
    public  _labels = LABELS;
    constructor(){
        super();
        this._totpPassword = new TotpPassword();
        this._error = ko.observable('');
        this.AddEvent(Events.SignIn);
    }

    IsValid(){
        return this._totpPassword.IsValid();
    }

    GetTemplateName(){
        return 'Auth/UI/TotpPasswordForm/Templates/TotpPasswordForm';
    }

    Load(title: string){
        this.Reset();
        this._modal = new Modal(
            {
                addClass: 'totp-form',
                minWidth: 400,
                minHeight: 240,
                maxHeight: 240,
                maxWidth: 400
            }, false);
        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
        this._modal.Show();
    };

    Reset(){
        this._totpPassword.Clear();
    }

    OnOk(){
        this.Trigger(Events.SignIn);
    }

    GetFormData(): string {
        return this._totpPassword.Password;
    }

    ShowError(errorText: string){
        this._error(errorText);
    }

    RemoveErrorSummary(){
        this._error(null);
    }

    Close(){
        this._modal.Close();
    }
}