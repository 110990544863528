import * as ko from 'knockout';

import {EventBus} from "Core/Common/EventBus/EventBus";

import {BackButton} from '../../../Components/BackButton/BackButton';

import {BaseProduct} from "./BaseProduct";

import {RootGroup} from "./RootGroup";
import {PriceList} from "./PriceList";
import {AlternativePriceModel} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/AlternativePriceModel';

import {ProductPart} from './ProductPart';

import { CachedLevel } from './CachedLevel';

import Template from '../Templates/Product.html';

export class Product extends BaseProduct {

    private _backButton: BackButton;

    PriceList: KnockoutObservable<PriceList>;

    MainProductPart: ProductPart;

    ViewModels: CachedLevel[];

    constructor(id: number,
                kSeq: number,
                name: string,
                nameTranslated: string,
                image: string,
                description: string,
                price: number,
                quantity: number,
                alternativePriceModel: AlternativePriceModel,
                isActiveLevelLocked: boolean
    ) {
        super(id, kSeq, name, nameTranslated, image, description, price, null);

        this.PriceList = ko.observable(new PriceList());
        this.PriceList().AddAlternativePriceModel(alternativePriceModel);
        this.PriceList().AddMainProductPriceGroup(id, name, nameTranslated, price, quantity);
        this.PriceList().IsActiveLevelLocked = isActiveLevelLocked;

        this.ViewModels = [];
    }

    LevelCacheAvailable() {
        return this.PriceList() &&
            this.PriceList().ActiveLevel != null &&
            this.PriceList().ActiveLevel != this.PriceList().MainLevel;
    }

    AttachBackButton(backButton: BackButton) {
        this._backButton = backButton;
    }

    AssignEventBus(bus: EventBus) {
        super.AssignEventBus(bus);
        this.PriceList().AssignEventBus(bus);
    }

    GetTemplate() {
        return Template;
    }

    RootGroupIs(rootGroup: RootGroup) {
        return false;
    }

    Dispose() {
        this.PriceList().Dispose();
        super.Dispose();
    }
}