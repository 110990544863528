import * as ko from "knockout";

import {Notifier} from "Core/Common/Notifier";
import {BlockUI} from "Core/Common/BlockUi";

import {LABELS} from "Core/Components/Translation/Locales";

import {IControl} from "Core/Controls/IControl";
import {BaseProperty, IPropertyDescription} from "Core/GeneralProperties/Managers/BaseProperty";


import {Store} from "./Store/Store";

import {ViewModel} from "./Models/ViewModel";

import BackLinkingPropertyTemplate
    from "Core/GeneralProperties/Managers/BackLinkingProperty/Templates/BackLinkingProperty.html";

ko.templates["Core/GeneralProperties/Managers/BackLinkingProperty/Templates/BackLinkingProperty"] = BackLinkingPropertyTemplate;

export class BackLinkingProperty extends BaseProperty {
    private _initValue: any[];
    private _control: IControl;

    private _viewModel: KnockoutObservable<ViewModel>;
    private _labels = LABELS;

    constructor(property: IPropertyDescription, propertyValue: any[], control: IControl) {
        super(property);

        this._initValue = propertyValue || this.GetDefaultValue();
        this._control = control;
        this._viewModel = ko.observable(null);

        this.Value = ko.observable(propertyValue);
    }

    GetTemplateName(): string {
        return "Core/GeneralProperties/Managers/BackLinkingProperty/Templates/BackLinkingProperty";
    }

    SetValue(propertyValue: any[]): void {

    }

    GetDefaultValue(): any[] {
        return [];
    }

    OnInit() {
        const fieldModel = this._control.GetFieldModel();

        if (fieldModel) {
            BlockUI.Block();

            Store.GetSubTableViews(this._control.GetFieldModel().EntityId)
                .then(dto => {
                    this._viewModel(new ViewModel(this._initValue, dto));
                    this._viewModel().On('SETTINGS_UPDATED', this, eventArgs => this.ApplyChanges(eventArgs.data));
                })
                .fail(error => new Notifier().Failed(JSON.parse(error.message).Message))
                .always(() => BlockUI.Unblock());
        }
    }

    private ApplyChanges(changes) {
        this.Value(changes.Settings);
    }
}