import * as ko from 'knockout';
import * as _ from 'underscore';

import {EventBusConsumer} from 'Core/Common/EventBus/EventBusConsumer';

import {Product} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/Product';
import {RootGroup} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/RootGroup';
import {ProductPrice} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/ProductPrice';
import {SelectedProducts} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/SelectedProducts';
import {CachedProducts} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/CachedProducts';
import {CachedLevel} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/CachedLevel';
import {ExtraGroup} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/ExtraGroup';
import {UndefinedGroup} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/UndefinedGroup';

export class ViewModel extends EventBusConsumer {
    SelectedProducts: SelectedProducts;
    CachedProducts: CachedProducts;

    ViewModels: CachedLevel[];

    Product: KnockoutObservable<Product>;
    _rootGroups: KnockoutObservableArray<RootGroup>;
    _mainGroup: KnockoutObservable<RootGroup>;
    _extraGroup: KnockoutObservable<ExtraGroup>;
    _undefinedGroup: KnockoutObservable<UndefinedGroup>;

    Level: string;
    compareLevels: KnockoutObservable<boolean>;

    constructor() {
        super();

        this.SelectedProducts = new SelectedProducts();
        this.CachedProducts = new CachedProducts();

        this.Product = ko.observable(null);
        this._rootGroups = ko.observableArray([]);
        this._mainGroup = ko.observable(null);
        this._extraGroup = ko.observable(null);
        this._undefinedGroup = ko.observable(null);
        this.compareLevels = ko.observable(false);
        this.ViewModels = [];
    }

    get RootGroups() {
        if (this.LevelCacheAvailable()) {
            return this.ViewModels.find(vm => vm.Level == this.Product().PriceList().ActiveLevel).RootGroups;
        }
        return this._rootGroups;
    }

    get MainGroup() {
        if (this.LevelCacheAvailable()) {
            return this.ViewModels.find(vm => vm.Level == this.Product().PriceList().ActiveLevel).MainGroup;
        }
        return this._mainGroup;
    }

    get ExtraGroup() {
        if (this.LevelCacheAvailable()) {
            return this.ViewModels.find(vm => vm.Level === this.Product().PriceList().ActiveLevel).ExtraGroup;
        }
        return this._extraGroup;
    }

    get UndefinedGroup() {
        if (this.LevelCacheAvailable()) {
            return this.ViewModels.find(vm => vm.Level === this.Product().PriceList().ActiveLevel).UndefinedGroup;
        }
        return this._undefinedGroup;
    }

    LevelCacheAvailable() {
        return this.Product() &&
            this.Product().PriceList() &&
            this.Product().PriceList().ActiveLevel != null &&
            this.Product().PriceList().ActiveLevel != this.Product().PriceList().MainLevel;
    }

    GetRootGroup(criteria: (rootGroup: RootGroup) => boolean) {
        return _.find(this.RootGroups(), rootGroup => criteria(rootGroup));
    }

    GetRootGroupByIdAndName(groupId: number, groupName: string) {
        return _.find(this.RootGroups(), group => group.Id === groupId && group.Name === groupName);
    }

    FindProduct(id: number, rootGroupId: number, rootGroupName: string, groupId: number, groupName: string, path: number[], kSeq: number, kSeqGuid: string) {
        let product = null;

        for (let index = 0; index < this.RootGroups().length; index++) {
            product = this.RootGroups()[index].FindProduct(id, rootGroupId, rootGroupName, groupId, groupName, path, kSeq, kSeqGuid);

            if (product) {
                return product;
            }
        }

        product = this.ExtraGroup().FindProduct(id, rootGroupId, rootGroupName, groupId, groupName, path, kSeq, kSeqGuid);

        if (product) {
            return product;
        }

        product = this.UndefinedGroup().FindProduct(id, rootGroupId, rootGroupName, groupId, groupName, path, kSeq, kSeqGuid);

        if (product) {
            return product;
        }

        return null;
    }

    FindProductGroup(groupId: number, groupName: string, parentId: number) {
        for (let index = 0; index < this.RootGroups().length; index++) {

            if (this.RootGroups()[index].Id == groupId && this.RootGroups()[index].Name == groupName) {
                return this.RootGroups()[index];
            }

            const foundGroup = this.RootGroups()[index].FindGroup(groupId, groupName, parentId);
            if (foundGroup) {
                return foundGroup;
            }
        }

        return null;
    }

    FindProductById(id: number) {
        for (let index = 0; index < this.RootGroups().length; index++) {
            const product = this.RootGroups()[index].FindProductById(id);

            if (product) {
                return product;
            }
        }

        return null;
    }

    FindSelectedProductsIncopatibleWith(id: number): number[] {
        let productIds = [];

        this.SelectedProducts.Selected.forEach(selected => {
            const currentProduct = this.FindProduct(selected.Id, selected.RootGroupId, selected.RootGroupName, selected.GroupId, selected.GroupName, selected.Path, selected.KSeq, selected.KSeqGuid);
            if (currentProduct.ExclusiveProducts.indexOf(id) != -1) {
                productIds.push(currentProduct.Id);
            }
        });
        return productIds;
    }

    FindAllSelectedProducts() {
        let products = [];

        this.SelectedProducts.Selected.forEach(selected => {
            const currentProduct = this.FindProduct(selected.Id, selected.RootGroupId, selected.RootGroupName, selected.GroupId, selected.GroupName, selected.Path, selected.KSeq, selected.KSeqGuid);
            products.push(currentProduct);
        });

        return products;
    }

    AddToPriceList(product: ProductPrice, groupId: number, groupName: string, groupNameTranslation: string) {
        this.Product().PriceList().AddToPriceList(product, groupId, groupName, groupNameTranslation);
    }

    AddToPriceListByLevel(product: ProductPrice, groupId: number, groupName: string, groupNameTranslation: string, level: string) {
        this.Product().PriceList().AddToPriceListByLevel(product, groupId, groupName, groupNameTranslation, level);
    }

    RemoveFromPriceList(rootGroupId: number, rootGroupName: string, productName: string, kSeq: number, kSeqGuid: string) {
        this.Product().PriceList().RemoveFromPriceList(rootGroupId, rootGroupName, productName, kSeq, kSeqGuid);
    }

    UpdatePriceInPriceList(product: ProductPrice, groupId: number, groupName: string) {
        this.Product().PriceList().UpdatePriceFor(product, groupId, groupName);
    }

    UpdateMainProductPrice(price: number) {
        this.Product().PriceList().UpdateMainProductPrice(price);
    }

    UpdateAssemblyQuantity(quantity: number) {
        this.Product().PriceList().UpdateAssemblyQuantity(quantity);
    }

    DisableSaveByAction(disableSaveByAction: boolean) {
        this.Product().PriceList().IsSaveDisabledByAction = disableSaveByAction;
    }

    Dispose() {
        if (this.Product()) {
            this.Product().Dispose();
        }

        this.RootGroups().forEach(group => group.Dispose());
        super.Dispose();
    }
}