import * as ko from "knockout";
import * as _ from 'underscore';

import { ConditionOperators } from "QueryBuilder/Enums";
import { Guid } from "Core/Common/Guid";
import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { QueryConditionGroupModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryConditionGroup';
import { ConditionItem } from 'QueryBuilder/QueryCondition/ConditionItem/ConditionItem';
import { Event } from 'Core/Common/Event'
import { ViewModes } from 'Core/Controls/Grid/BaseGrid/Enums/ViewModes';
import { QueryConditionItemModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryConditionItemModel';
import { AttachedFieldModel } from 'Core/Controls/BaseControl/Models/AttachedFieldModel';
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";
import { ComparisonOperators, ConditionValueTypes } from 'QueryBuilder/Enums';

import ConditionGroupTemplate from 'QueryBuilder/QueryCondition/ConditionGroup/Templates/ConditionGroup.html';
import { IControlAttachedField } from "Core/Screens/BaseScreen";

export interface IConditionOperator {
	Name: string,
	Value: ConditionOperators;
}

export class ConditionGroup extends Event {
	ConditionGroups: KnockoutObservableArray<ConditionGroup>;
	ConditionItems: KnockoutObservableArray<ConditionItem>;
	ConditionOperators: Array<IConditionOperator>;
	ConditionOperator: KnockoutObservable<ConditionOperators>;
	private _model: QueryConditionGroupModel;
	private _entities: KnockoutObservableArray<QueryEntityModel>;
	private _id: string;

	private _isRoot: boolean;
	private _screenFields: Array<IControlAttachedField>;
	private _isDesigntime: boolean;
	private _labels = LABELS;
	private _queryType: ViewModes;

	constructor(
		isRoot: boolean,
		model: QueryConditionGroupModel,
		queryType: ViewModes,
		entities: KnockoutObservableArray<QueryEntityModel>,
		screenFields: Array<IControlAttachedField> = [],
		isDesignTime: boolean = false,
	) {
		super();
		this._isDesigntime = isDesignTime;
		this._model = model;
		this._id = Guid.NewGuid();
		this._isRoot = isRoot;
		this.ConditionGroups = ko.observableArray([]);
		this.ConditionItems = ko.observableArray([]);
		this._entities = entities;
		this.ConditionOperator = ko.observable(null);
		this._screenFields = screenFields;
		this._queryType = queryType;
		this.ConditionOperators = [
			{ Name: LABELS.AND, Value: ConditionOperators.And },
			{ Name: LABELS.OR, Value: ConditionOperators.Or }
		];
		this.Init();
	}

	Init() {
		var conditionOperator = _.find(this.ConditionOperators, (operator) => { return operator.Value === this._model.Operator });
		if (conditionOperator) {
			this.ConditionOperator(conditionOperator.Value);
		} else {
			this.ConditionOperator(ConditionOperators.And);
		}

		_.each(this._model.Items, (item) => {
			let conditionItem = new ConditionItem(item, this._entities, this._screenFields, this._isDesigntime);
			if(conditionItem.HasMetadata) {
				conditionItem.IsEnableShowParam(this._queryType === ViewModes.Query || this._queryType === ViewModes.Spim);
				conditionItem.On('CHANGED', this, (eventArgs) => {
					this.RaiseChangeEvent();
				});
				this.ConditionItems.push(conditionItem);
			}
		});


		_.each(this._model.ConditionGroups, (group) => {
			var conditionGroup = new ConditionGroup(false, group, this._queryType, this._entities, this._screenFields, this._isDesigntime);
			conditionGroup.On('CHANGED', this, (eventArgs) => {
				this.RaiseChangeEvent();
			});
			this.ConditionGroups.push(conditionGroup);
		});

		this.AddEvent('CHANGED');
	}

	get IsRoot() {
		return this._isRoot;
	}

	GetTemplate() {
		return ConditionGroupTemplate;
	}

	get Model() {
		return this._model;
	}

	AddRule() {
		var model = new QueryConditionItemModel();
		model.ValueType = ConditionValueTypes.General;
		this._model.Items.push(model);
		var conditionItem = new ConditionItem(model, this._entities, this._screenFields, this._isDesigntime);
		conditionItem.IsEnableShowParam(this._queryType === ViewModes.Query || this._queryType === ViewModes.Spim);
		conditionItem.On('CHANGED', this, (eventArgs) => {
			this.RaiseChangeEvent();
		});
		this.ConditionItems.push(conditionItem);
		conditionItem.SelectFirstField();
		this.RaiseChangeEvent();
	}

	AddConditionGroup() {
		var queryConditionGroupModel = new QueryConditionGroupModel();
		this._model.ConditionGroups.push(queryConditionGroupModel);
		var conditionGroup = new ConditionGroup(false, queryConditionGroupModel, this._queryType, this._entities, this._screenFields, this._isDesigntime);
		conditionGroup.On('CHANGED', this, (eventArgs) => {
			this.RaiseChangeEvent();
		});
		this.ConditionGroups.push(conditionGroup);
		this.RaiseChangeEvent();
	}

	DeleteConditionGroup(group: ConditionGroup) {
		this._model.ConditionGroups.splice(this._model.ConditionGroups.indexOf(group.Model));
		this.ConditionGroups.splice(this.ConditionGroups.indexOf(group), 1);
		this.RaiseChangeEvent();
	}

	DeleteConditionRule(rule: ConditionItem) {
		this._model.Items.splice(this._model.Items.indexOf(rule.Model), 1);
		this.ConditionItems.splice(this.ConditionItems.indexOf(rule), 1);
		this.RaiseChangeEvent();
	}
	
	SetConditionOperator(operator: IConditionOperator) {
		this._model.Operator = operator.Value;
		this.RaiseChangeEvent();
	}

	RaiseChangeEvent() {
		this.Trigger('CHANGED');
	}

	AfterRender() {}
}