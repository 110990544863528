import * as ko from 'knockout';

import {BaseCheckItem} from "../BaseCheckItem";
import {ICheckItemParams} from "../ICheckItemParams";

import Template from 'Core/Components/ActionCheckList/CheckItems/Text/Templates/Template.html';

ko.templates['Core/Components/ActionCheckList/CheckItems/Text/Templates/Template'] = Template;

export class TextCheckItem extends BaseCheckItem {
    constructor(params: ICheckItemParams) {
        super(params);
    }

    GetTemplateName(): string {
        return 'Core/Components/ActionCheckList/CheckItems/Text/Templates/Template';
    }
}