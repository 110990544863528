import * as ko from 'knockout';

import {Modal} from 'Core/Common/Modal';
import {Event} from 'Core/Common/Event';
import {Notifier} from 'Core/Common/Notifier';

import {SelectUserModel} from 'Core/Controls/SelectUser/Models/SelectUserModel';
import {SelectUserFormModel} from 'Core/Controls/SelectUser/Models/SelectUserFormModel';
import {NOTIFICATIONS, LABELS} from 'Core/Components/Translation/Locales';
import {MobileChecker} from "Core/Common/MobileChecker";

import FormTemplate from 'Core/Controls/SelectUser/Form/Template/Form.html';
import {SelectUserFormViewModel} from "Core/Controls/SelectUser/Models/SelectUserFormViewModel";

ko.templates['Core/Controls/SelectUser/Form/Template/Form'] = FormTemplate;

export const EVENTS = {
	USERS_SELECTION_CHANGED: 'UsersSelectionChanged'
};

export class SelectUserForm extends Event {
	private _model: KnockoutObservable<SelectUserFormViewModel>;
	private _modal: Modal;
	private _labels = LABELS;

	constructor(model: SelectUserModel) {
		super();
		this._model = ko.observable(new SelectUserFormViewModel(model));
		this.Init();
	}

	Show() {
		this._modal.Show();
	}

	ApplySelection() {
		const model = this._model().ToSelectUserViewModel();
		new Notifier().Success(NOTIFICATIONS.SELECTION_APPLIED);
		this.Close();
		this.Trigger(EVENTS.USERS_SELECTION_CHANGED, model);
	}

	ResetSelection() {
		this._model().Users().forEach(user => user.Checked(false));
		this._model().UserGroups().forEach((userGroup ) => {
			userGroup.Checked(false);
			userGroup.Users().forEach(user => user.Checked(false))
		});
	}

	Close() {
		this._modal.Close();
	}

	GetTemplateName() {
		return 'Core/Controls/SelectUser/Form/Template/Form';
	}

	AfterRender(el) {
		$(document).ready(function () {
			// Add 'panel-open' class when the collapse is shown
			$('.panel-collapse').on('show.bs.collapse', function () {
				$(this).closest('.panel').addClass('panel-open');
			});

			// Remove 'panel-open' class when the collapse is hidden
			$('.panel-collapse').on('hide.bs.collapse', function () {
				$(this).closest('.panel').removeClass('panel-open');
			});
		});
	}

	private Init() {
		this._modal = new Modal({
			width: MobileChecker.IsMobile() ? '100vw' : '700',
			height: MobileChecker.IsMobile() ? '100vh' : '600',
			addClass: MobileChecker.IsMobile() ? 'mobileSelectUser' : 'desktop-selectUser',
			minHeight: '300'
		}, false);

		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);
	}
}