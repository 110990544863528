import * as _ from "underscore";

import {deserializeAs} from 'libs/cerialize';
import {EntityModel} from 'QueryBuilder/Models/EntityModel'
import {RelatedEntitiesModel} from 'QueryBuilder/Models/RelatedEntitiesModel'
import {RelatedSubEntitiesModel} from 'QueryBuilder/Models/RelatedSubEntitiesModel'

export class ConcreteEntityModel {

	@deserializeAs(EntityModel)
	MainEntity: EntityModel;

	@deserializeAs(RelatedEntitiesModel)
	RelatedEntities: RelatedEntitiesModel;

	@deserializeAs(RelatedSubEntitiesModel)
	RelatedSubEntitiesModel: Array<RelatedSubEntitiesModel>;

	Guid: string;

	constructor() {
		this.RelatedSubEntitiesModel = [];
	}

	GetLinkTable(relatedEntityId: number): EntityModel {
		const relatedEntity = _.find(this.RelatedEntities.Entities, (model: EntityModel) => {
			return model.EntityId === relatedEntityId;
		});
		if (!relatedEntity) {
			return null;
		}

		return relatedEntity.LinkEntity;
	}
}