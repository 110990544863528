import {TableModel} from "LookupEditor/Store/Models/TableModel";
import * as ko from "knockout";
import {Event} from "Core/Common/Event";
import {EVENTS} from "Core/Constant";
import {Guid} from "Core/Common/Guid";
import {IconModel} from "Core/Controls/BaseControl/Models/IconModel";

export class TreeNodeModel extends Event {
    Id: number;
    Guid: string;
    Name: string;
    Type: string;
    NestedNodes: KnockoutObservableArray<TreeNodeModel>;
    HasLookups: boolean;
    IsReady: KnockoutObservable<boolean>;
	Icon: IconModel;
	EntityColor: string;
	CanExpand: boolean;

    constructor() {
        super();
        this.Guid = Guid.NewGuid();
        this.NestedNodes = ko.observableArray([]);
        this.IsReady = ko.observable(false);
    }

    get IconClass(): string {
		if (this.Type === 'Entity') {
			return this.GetEntityIcon();
		}

		return this.GetLookupIcon();
    }

    GetTemplateName(): string {
        return 'LookupEditor/Templates/TreeNode';
    }

    AfterRender(el) {
        this.IsReady();
    }

    ToJson() {
        var nested = this.NestedNodes().map(node => node.ToJson());
        return {
            id: this.Guid,
            text: this.Name,
            icon: this.IconClass,
			li_attr: {
				"style": `color: ${this.EntityColor || "black"}`
			},
            children: !this.CanExpand ? [] : nested.length > 0 ? nested : true
        }
    }

    GetNode(guid: Guid, callback: (node: TreeNodeModel) => void) {
        if (this.Guid === guid) {
            callback(this);
        }

        this.NestedNodes().forEach(node => node.GetNode(guid, callback));
    }

	private GetEntityIcon() {
		if (this.Icon && this.Icon.Image === null) {
			return 'fa ' + this.Icon.Name + ' icon-lg';
		}

		return 'fa fa-folder icon-lg icon-state-warning';
	}

	private GetLookupIcon() {
		return 'fa fa-table icon-lg icon-state-info';
	}
} 