import { deserialize, deserializeAs, serialize } from 'libs/cerialize';

export function extractLookupValFieldMetadata(fieldMetadata: FieldMetadataModel) {
	return (
		fieldMetadata.LookupTable &&
		fieldMetadata.LookupTable.Fields.find(lookupTableField => lookupTableField.Id === fieldMetadata.ValFieldId)
	);
}

export class FieldMetadataModel {
	constructor(){
		this.FormatName = '';
	}

	@serialize
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	NameTranslation: string;

	@deserialize
	Type: string;

	@deserialize
	FormatName: string;

	@deserialize
	AllowCustomValue: boolean;

	@deserialize
	AllowCreatingRecords: boolean;

	@deserialize
	IsVirtual: boolean;

	@deserialize
	IsSystem: boolean;

	@deserialize
	IsReadOnly: boolean;

	@deserialize
	IsPrimaryKey: boolean;

	@deserialize
	IsRequired: boolean;

	@deserialize
	AllowInsert: boolean;

	@deserialize
	ValTableId: number;

	@deserialize
	ValTableName: string;

	@deserialize
	ValTableType: string;

	@deserialize
	Size: number;

	@deserialize
	IsClustered: boolean;

	@deserialize
	IsCustom: boolean;

	@deserialize
    ValFieldId: number;

	@deserialize
    DependsOn: number;

	@deserialize
    FilledById: number;

	@deserialize
    LookupTable: any;

    @deserialize
    Lifestatus: number;

    @deserialize
    LifestatusName: string;

	@deserialize
    HyperlinkEntityId: number;

	@deserialize
    HasSortDescription: boolean;
}