import {RecordSharingViewModel, RightsViewModel} from "Core/Controls/RecordSharing/Models/RecordSharingViewModel";

class RightUnitModel {
	UserGroupId: number;
	TableId: number;
	RightsValue: number;

	constructor(tableId: number, right: RightsViewModel) {
		this.TableId = tableId;
		this.UserGroupId = right.UserGroupId;
		this.RightsValue = right.IsShared() ? right.Value : 0;
	}
}

export class RecordSharingSerializedModel {
	SecurityProfileId: number;
	RightUnits: Array<RightUnitModel>;

	constructor(securityProfileId: number, recordSharingViewModel: RecordSharingViewModel) {
		this.SecurityProfileId = securityProfileId;
		this.RightUnits= [];

		recordSharingViewModel.Tables.forEach(table => {
			let tableRights = table.Rights().map(right => new RightUnitModel(table.Id, right));
			this.RightUnits = this.RightUnits.concat(tableRights);
		});
	}
}