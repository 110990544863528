import * as ko from 'knockout';

import HelpStatusFlowTemplate from 'HelpBook/HelpStatusFlow/Templates/HelpStatusFlow.html';
import {HelpBook} from 'HelpBook/HelpBook';

ko.templates['HelpBook/HelpStatusFlow/Templates/HelpStatusFlow'] = HelpStatusFlowTemplate;

export class HelpStatusFlow {
	private isStatusFlowActive: KnockoutObservable<boolean>;
	private _typeId: number;

	constructor(typeId: number) {
		this.isStatusFlowActive = ko.observable(true);
		this._typeId = typeId;
	}

	AfterRender() {
		HelpBook.Instance.BuildLifecicleDesignerFlow(this._typeId);
	}

	GetTemplateName(): string {
		return 'HelpBook/HelpStatusFlow/Templates/HelpStatusFlow';
	}
}