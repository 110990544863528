import * as ko from 'knockout';
import * as moment from 'moment';
import 'lockr';

import {BlockUI} from 'Core/Common/BlockUi';
import {Guid} from 'Core/Common/Guid';
import {MailStore, IDownloadAttachmentRequestModel} from 'Core/Controls/Mail/Stores/MailStore';
import {Modal} from 'Core/Common/Modal';
import {ZIndexManager} from 'Core/Common/ZIndexManager';
import {ComposeMail} from 'Core/Controls/Mail/ComposeMail';
import {MailItemExtendedModel} from 'Core/Controls/Mail//Models/MailItemExtendedModel';
import {COMPOSER_EVENTS} from 'Core/Controls/Mail/Events';
import {Event} from 'Core/Common/Event';
import {FileDownloader} from 'Core/Components/FileDownloader/FileDownloader';

import DetailTemplate from 'Core/Controls/Mail/Templates/DetailTemplate.html';
import enumerable from '../../Common/Decorators/EnumerableDecorator';
import {LABELS} from "../../Components/Translation/Locales";
import { IComposerOptionsModel } from './Models/ComposerOptionsModel';

ko.templates['Core/Controls/Mail/Templates/DetailTemplate'] = DetailTemplate;

export class Detail extends Event {
	private _labels: LABELS;
	private _mailItem: MailItemExtendedModel;
	private _showReplyEditor: KnockoutObservable<boolean>;
	private _replyTinyGuid: string;
	private _detailTinyGuid: string;
	private _mailBody: KnockoutObservable<string>;
	private _subject: KnockoutObservable<string>;
	private _dateTimeCreated: KnockoutObservable<string>;
	private _mailConnectionId: number;
	private _modal: Modal;
	private _mailComposer: KnockoutObservable<ComposeMail>;
	private _recipientsCount: number;
	private _toAdresses: string[];
	private _ccAdresses: string[];

	constructor(mailItem: MailItemExtendedModel) {
		super();

		this._replyTinyGuid = Guid.NewGuid();
		this._detailTinyGuid = Guid.NewGuid();
		this._showReplyEditor = ko.observable(false);
		this._mailComposer = ko.observable(null);
		this._mailItem = mailItem;
		this._mailConnectionId = null;
		this._mailBody = ko.observable('');
		this._subject = ko.observable('');
		this._dateTimeCreated = ko.observable(this.FormatDateCreated(this._mailItem.DateTimeCreated));
		this._labels = LABELS;

		this.CountRecipients();
	}

	@enumerable get MailComposer(): KnockoutObservable<ComposeMail> {
		return this._mailComposer;
	}

	GetTemplate() {
		return DetailTemplate;
	}

	GetTemplateName() {
		return 'Core/Controls/Mail/Templates/DetailTemplate';
	}

	ShowInModal(mailConnectionId) {
		this._mailConnectionId = mailConnectionId;
		this._modal = new Modal();

		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);

		this._modal.Show();
	}

	CountRecipients() {
		const destinationMails = [];

		this._mailItem.DestinationMails.forEach((el) => {
			if (el.DestinationMails.length) {
				el.DestinationMails.map((destMail) => {
					destinationMails.push(destMail.Mail);
				});
			} else {
				destinationMails.push(el.Address);
			}
		});

		let recipients = destinationMails.concat(this._mailItem.Cc);

		recipients = _.uniq(recipients);

		this._recipientsCount = recipients.length;

		const ccAdresses = _.uniq(this._mailItem.Cc);

		const toAdresses =
			_.uniq(destinationMails)
			.filter((el) => {
				let isInCc = false;

				ccAdresses.forEach((cc) => {
					if (cc === el) {
						isInCc = true;
					}
				});

				return !isInCc;
			});

		this._toAdresses = toAdresses;
		this._ccAdresses = ccAdresses;
	}

	Reply() {
		const composerOptions: IComposerOptionsModel = {
			ResponseMailItem: this._mailItem,
			MailConnectionId: this._mailConnectionId,
			IsReply: true,
			DateTimeCreated: this._dateTimeCreated()
		};

		const composer = new ComposeMail(composerOptions);

		this._mailComposer(composer);

		composer.On(COMPOSER_EVENTS.DISCARD, this, (eventArgs) => this._mailComposer(null));
		composer.On(COMPOSER_EVENTS.EMAIL_SENT, this, (eventArgs) => this._modal.Close());
	}

	ReplyAll() {
		const composerOptions: IComposerOptionsModel = {
			ResponseMailItem: this._mailItem,
			MailConnectionId: this._mailConnectionId,
			IsReplyAll: true,
			DateTimeCreated: this._dateTimeCreated()
		};

		const composer = new ComposeMail(composerOptions);

		this._mailComposer(composer);

		composer.On(COMPOSER_EVENTS.DISCARD, this, (eventArgs) => this._mailComposer(null));
		composer.On(COMPOSER_EVENTS.EMAIL_SENT, this, (eventArgs) => this._modal.Close());
	}

	Forward() {
		const composerOptions: IComposerOptionsModel = {
			ResponseMailItem: this._mailItem,
			MailConnectionId: this._mailConnectionId,
			IsForward: true,
			DateTimeCreated: this._dateTimeCreated()
		};

		const composer = new ComposeMail(composerOptions);

		this._mailComposer(composer);

		composer.On(COMPOSER_EVENTS.DISCARD, this, (eventArgs) => this._mailComposer(null));
		composer.On(COMPOSER_EVENTS.EMAIL_SENT, this, (eventArgs) => this._modal.Close());
	}

	CancelAction() {
		this._mailComposer(null);
	}

	private FormatDateCreated(date) {
		return moment(date).format('LLLL');
	}

	AfterRender() {
		if (!this._mailItem.IsRead) {
			this.Trigger(COMPOSER_EVENTS.EMAIL_WAS_READ, {guid: this._mailItem.Guid});
		}
	}

	AfterDetailTinyInit(editor) {
		editor.setContent(this._mailItem.Body || '');
	}

	AfterDetailTinyChange(content) {
	}

	Download(attachment: any) {
		const requestModel: IDownloadAttachmentRequestModel = {
			MailConnectionId: this._mailConnectionId,
			Name: attachment.Name,
			Guid: this._mailItem.Guid
		};

		BlockUI.Block({ZIndex: ZIndexManager.Instance.NextValue});
		MailStore
			.DownloadAttachment(requestModel)
			.always(() => {
				BlockUI.Unblock();
			})
			.then((data) => {
				FileDownloader.DownloadBase64(data.ResultObject, attachment.Name);
			});
	}
}