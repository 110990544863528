import {Event} from "Core/Common/Event";

export interface EventHandlerCallback {
	Handler: () => void;
}

export class PortletBarEventHandlersFactory<THandlerOwner extends Event, TEventEmmiter extends Event> {
	constructor(private _handerOwner: THandlerOwner, private _eventEmitter: TEventEmmiter, private _handlers: { [eventName: string]: EventHandlerCallback }) {

	}

	BindEvent(eventName: string) {
		if (this._handlers[eventName]) {
			this._eventEmitter.On(eventName, this, () => {
				this._handlers[eventName].Handler.apply(this._handerOwner);
			});
		}
	}
}