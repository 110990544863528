import {deserialize, deserializeAs} from 'libs/cerialize'
import { ReferenceModel } from "Core/ScreenManager/Models/ReferenceModel"
import {PropertyFieldConfigModel} from "./PropertyFieldConfigModel";

export class TranslationValue {
	@deserialize
	LanguageId: number;

	@deserialize
	Value: string;
}

export class LinkedFieldValueModel {
	constructor() {
		this.IsModified = false;
		this.Translations = [];
		this.DynamicFields = [];
	}

	@deserialize
	Id: number;

	@deserialize
	Value: string;

	@deserialize
	DisplayValue: string;

	@deserialize
	Name: string;

	@deserialize
	TypeId: number;

	@deserialize
	TypeName: string;

	@deserialize
	FormatName: string;

	@deserialize
	AllowCustomValue: boolean;

	@deserialize
	AllowCreatingRecords: boolean;

	@deserialize
	IsModified: boolean;

	@deserialize
	IsRequired: boolean;

	@deserialize
	IsReadOnly: boolean;

	@deserialize
    IsSystem: boolean;

    @deserialize
    IsVirtual: boolean;

	@deserialize
	AllowInsert: boolean;

	@deserialize
	ValTableType: string;

	@deserialize
	ValTableId: number;

	@deserialize
	ValTableName: string;

	@deserialize
	ValFieldId: number;

	@deserialize
	ValFieldTypeName: string;

	@deserialize
	ValFieldFormatName: string;

	@deserialize
	ValFieldSize: number;

	@deserialize
	PropertyFieldId: number;

	@deserializeAs(PropertyFieldConfigModel)
	PropertyConfig: PropertyFieldConfigModel;

	@deserialize
	FilledById: number;

	@deserialize
	HasDefaultValue: boolean;

	@deserialize
	FieldFlag: number;

	@deserialize
	Translations: Array<TranslationValue>;

	@deserialize
	NameTranslated: string;

    @deserialize
    Size: number;

    @deserialize
	Sort: number;

    @deserialize
    DependsOnId: number;

    @deserialize
	DynamicFields: Array<number>;

    @deserialize
	ResetDependsOnValue: boolean;

    @deserialize
    SkipTimeShifting: boolean;

    @deserialize
	FullWidth: boolean;

    @deserialize
	HasLinkEditorVisibility: boolean;

    @deserialize
	FontStyles: Array<{Id: number, Name: string}>;

    @deserialize
    FontColor: string;

    @deserialize
    Reference: ReferenceModel;

	@deserialize
	FilterByField: number;

	@deserialize
	HasFilter: boolean;
}