import * as joint from 'libs/rappid/build/rappid';
import {dia as Dia, shapes, dia} from 'libs/rappid/build/rappid';
import 'Core/Controls/CanvasDesigner/Shapes/Shapes';
import { FunctionParamInspector } from './FunctionParamInspector';

export class InspectorFactory {
    public static BuildInspector(shape: joint.shapes.cyberThing.ParamView, graph: Dia.Graph): joint.ui.Inspector {
        let parent = graph.get('cells').get(shape.model.get('parent'));
		if(parent instanceof shapes.cyberThing.CSharpFunction || parent instanceof shapes.cyberThing.JSFunction){
            return FunctionParamInspector.Build(shape, graph);            
        }
        return null;
    }	
}
