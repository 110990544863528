import * as ko from "knockout";
import {BaseControl, IControlValue} from "Core/Controls/BaseControl/BaseControl";
import {SubForm} from "Core/Screens/Forms/SubForm/SubForm";
import {IControlParam} from "Core/Screens/IScreen";

import ViewTemplate from "Core/Controls/DatabaseDesigner/Templates/View.html";
ko.templates["Core/Controls/DatabaseDesigner/Templates/View"] = ViewTemplate;

import ToolBarTemplate from "Core/Controls/DatabaseDesigner/Templates/ToolBar.html";
ko.templates["Core/Controls/DatabaseDesigner/Templates/ToolBar"] = ToolBarTemplate;

export class DatabaseDesigner extends BaseControl {

	constructor(params: IControlParam) {
		super(params);
		this.Init();
	}

	private Init(): void {
	}

	ApplyProperties(){}

	SetValue(value: IControlValue): void {
	}

	AfterRender(el: Array<HTMLElement>): void {
		super.AfterRender(el);
	}
}