import * as ko from 'knockout'
import * as moment from 'moment'
import * as stringTemplateEngine from 'stringTemplateEngine'

import 'knockoutValidation'
import 'lockr'

import {LOCAL_STORAGE} from 'Core/Common/Enums/LocalStorageItems';

import {P} from 'Core/Common/Promise'
import {CookieManager} from "Core/Common/CookieManager";

import {PasswordExpiredForm} from 'Auth/UI/PasswordExpiredForm/PasswordExpiredForm'
import {AuthForm} from 'Auth/UI/AuthForm/AuthForm'
import {LicenseForm} from 'Auth/UI/LicenseForm/LicenseForm'
import {LicenseExpiredSoonForm} from 'Auth/UI/LicenseExpiredSoonForm/LicenseExpiredSoonForm'
import {TwoFaConfigurationForm} from 'Auth/UI/TwoFaConfigurationForm/TwoFaConfigurationForm'
import { UnreportedTimeForm } from "Auth/UI/UnreportedTimeForm/UnreportedTimeForm"
import {SignInModel} from 'Auth/Models/SignInModel'
import {UpdatePasswordModel} from 'Auth/Models/UpdatePasswordModel'
import {SignInResultModel} from 'Auth/Models/SignInResultModel'
import { UnReportedTimeModel } from "Auth/Models/UnReportedTimeModel"

import OperationResult from 'Core/Results/ResultModels/OperationResult'
import {AuthStore} from 'Auth/Stores/AuthStore'
import {AuthResults} from 'Auth/Enums/AuthResults'
import {FormTitles} from 'Auth/Enums/FormTitles'
import {Event} from 'Core/Common/Event'
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager'
import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS, Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

import {Events} from "Auth/Enums/Events";
import {UserManager} from "User/UserManager";
import { ScreenTypes } from "Core/Common/Enums/ScreenTypes";
import {BlockUI} from 'Core/Common/BlockUi';
import {RequestResetPasswordModel} from "./Models/RequestResetPasswordModel";
import {PasswordResetForm} from "./UI/PasswordResetForm/PasswordResetForm";
import {Notifier} from "../Core/Common/Notifier";
import {ResetPasswordModel} from "./Models/ResetPasswordModel";
import { UpdateTotpSecretModel } from './Models/UpdateTotpSecretModel'
import { TotpPasswordForm } from './UI/TotpPasswordForm/TotpPasswordForm'
import {CONFIRMATIONS, LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";
import {TranslationManager} from "Core/Components/Translation/TranslationManager";
import {AgendaDataStore} from "Core/Controls/Agenda/Stores/AgendaDataStore";
import { PUB_SUB_EVENTS } from 'MenuManager/PubSubEvents';
import { PasswordLessConfigurationForm } from './UI/PasswordLessConfigurationForm'
import { WebAuthnStore } from './Stores/WebAuthnStore'
import { SavePasswordLessCredentialsDto } from './Models/SavePasswordLessCredentialsDto'

export class AuthManager extends Event {
    private _authForm: AuthForm;
    private _passwordResetForm: PasswordResetForm;
    private _passwordExpiredForm: PasswordExpiredForm;
    private _updatePasswordForm: PasswordExpiredForm;
    private _unreportedTimeForm: UnreportedTimeForm;
    private _licenseForm: LicenseForm;
    private _licenseExpiredSoonForm: LicenseExpiredSoonForm;
    private _twoFaConfigurationForm :TwoFaConfigurationForm;
    private _totpPasswordForm :TotpPasswordForm;
    private _passwordLessConfigurationForm: PasswordLessConfigurationForm;
    private _clientId: string;
    private _clientSecret: string;

    private _authFormOpened: boolean;

    private _signInModel: SignInModel;
    private _resultHandlers: {
        [errorMessage: string]: {
            Form: AuthForm | PasswordExpiredForm | LicenseForm | TwoFaConfigurationForm | TotpPasswordForm | PasswordLessConfigurationForm,
            Handler: (result: SignInResultModel | OperationResult, form: AuthForm | PasswordExpiredForm | LicenseForm | TwoFaConfigurationForm | TotpPasswordForm) => void;
        }
    };

    constructor() {
        super();

        this._authFormOpened = false;

        this.InitKnockout();
        this._authForm = new AuthForm();
        this._passwordResetForm = new PasswordResetForm();
        this._passwordExpiredForm = new PasswordExpiredForm();
        this._updatePasswordForm = new PasswordExpiredForm();
        this._unreportedTimeForm = new UnreportedTimeForm();
        this._licenseForm = new LicenseForm();
        this._licenseExpiredSoonForm = new LicenseExpiredSoonForm();
        this._twoFaConfigurationForm = new TwoFaConfigurationForm();
        this._totpPasswordForm = new TotpPasswordForm();
        this._passwordLessConfigurationForm = new PasswordLessConfigurationForm();

        this.BindResultHandlers();
        this.BindEvents();
        this.AddEvent('SIGN_IN');
    }

    private InitKnockout() {
        ko.setTemplateEngine(stringTemplateEngine.StringTemplateEngine);
    }

    public SignIn(modalWrapper?: HTMLElement) {
        BlockUI.Block(modalWrapper ? {Target: modalWrapper} : {});

        if (this._authForm.IsValid()) {
            this._authForm.PreventLogin();
            this._signInModel = this.BuildSignInModel();

            AuthStore.SignIn(this._signInModel)
                .then(signInResult => this.HandleSuccessSignIn(signInResult, modalWrapper))
                .fail(err => this.HandleFailedSignIn(SignInResultModel.CreateErrorResult(err.message, err.value), modalWrapper));
        } else {

            BlockUI.Unblock(modalWrapper);

            this._authForm.ShowError(NOTIFICATIONS.FORM_INVALID);
        }
    }

    public RequestResetPassword() {
        if (this._authForm.IsValidForPasswordReset()) {
            const confirmationDialog = new ConfirmationDialog({
                Text: CONFIRMATIONS.RESET_LINK_SENT.replace('{userName}', this._authForm.GetFormData().UserName()),
                Type: ConfirmationTypes.Question,
                TextConfirm: LABELS.OK,
                TextDecline: LABELS.CANCEL
            });

            confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
                BlockUI.Block();

                const resetPasswordModel = this.BuildRequestResetPasswordModel();

                AuthStore.RequestResetPassword(resetPasswordModel)
                    .then(result => new Notifier().Success(NOTIFICATIONS.RESET_PASSWORD_SENT))
                    .fail(error => new Notifier().Failed(JSON.parse(error.message).Message))
                    .always(() => BlockUI.Unblock());
            });

            confirmationDialog.Show();
        }
    }

    public SignOut(): P.Promise<any> {
        const deferredResult = P.defer<any>();

        const userVarsManager = UserVarsManager.Instance;
        const userVars = userVarsManager.GetAll();

        AuthStore.SignOut({UserVariables: userVars})
            .then((fileDir) => deferredResult.resolve(fileDir));

        return deferredResult.promise();
    }

    private BindResultHandlers() {
        this._resultHandlers = {};
        this._resultHandlers[AuthResults.InvalidTotpPassword] = {
            Form: this._totpPasswordForm,
            Handler: this.ShowForm.bind(this)
        };

        this._resultHandlers[AuthResults.TwoFaIsNotConfigured] = {
            Form: this._twoFaConfigurationForm,
            Handler: this.ShowForm.bind(this)
        };

        this._resultHandlers[AuthResults.PasswordLessIsNotConfigured] = {
            Form: this._passwordLessConfigurationForm,
            Handler: this.ShowForm.bind(this)
        };

        this._resultHandlers[AuthResults.PasswordReset] = {
            Form: this._updatePasswordForm,
            Handler: this.ShowForm.bind(this)
        };
        this._resultHandlers[AuthResults.PasswordExpired] = {
            Form: this._passwordExpiredForm,
            Handler: this.ShowForm.bind(this)
        };
        this._resultHandlers[AuthResults.LicenseNotFound] = {
            Form: this._licenseForm,
            Handler: this.ShowForm.bind(this)
        };
        this._resultHandlers[AuthResults.LicenseExpired] = {Form: this._licenseForm, Handler: this.ShowForm.bind(this)};
        this._resultHandlers[AuthResults.ErrorFindingLicenses] = {
            Form: this._licenseForm,
            Handler: this.ShowForm.bind(this)
        };
        this._resultHandlers[AuthResults.InvalidLicenseParams] = {
            Form: this._licenseForm,
            Handler: this.ShowForm.bind(this)
        };
        this._resultHandlers[AuthResults.InvalidLicenseNames] = {
            Form: this._licenseForm,
            Handler: this.ShowForm.bind(this)
        };
        this._resultHandlers[AuthResults.InvalidLicense] = {Form: this._licenseForm, Handler: this.ShowForm.bind(this)};

        this._resultHandlers[AuthResults.OnlyOneUserGroupIsAllowed] = {Form: this._licenseForm, Handler: this.ShowForm.bind(this)};
        this._resultHandlers[AuthResults.EditLimitIsGreaterThanUserDesignation] = {Form: this._licenseForm, Handler: this.ShowForm.bind(this)};
    }

    private BindEvents() {
        this._authForm.On(Events.PasswordLessSignIn, this, (eventArgs) => {
            this.HandleSuccessSignIn(eventArgs.data.SignInResult);
        });


        this._authForm.On(Events.SignIn, this, (eventArgs) => {
            this.SignIn(eventArgs.data.modalWrapper);
        });
        this._authForm.On(Events.ResetPassword, this, () => this.RequestResetPassword());

        this._passwordResetForm.On(Events.UpdatePassword, this, () => this.ResetPassword());
        this._passwordExpiredForm.On(Events.UpdatePassword, this, () => this.UpdateExpiredPassword());
        this._updatePasswordForm.On(Events.UpdatePassword, this, () => this.UpdatePassword());

        this._twoFaConfigurationForm.On(Events.SaveTotpSecred, this, () => this.UpdateTwoFaSecret());

        this._passwordLessConfigurationForm.On(Events.SavePasswordLessCredentials, this, () => this.SavePasswordLessCredentials());

        this._totpPasswordForm.On(Events.SignIn, this, () => {
            if(this._totpPasswordForm.IsValid()){
                this._totpPasswordForm.Close();
                this.SignIn();
                this._totpPasswordForm.Reset();
            }
        });
    }

    private GetDefaultResultHandler() {
        return {Form: this._authForm, Handler: this.SetError.bind(this)};
    }

    private HandleSuccessSignIn(signInResult: SignInResultModel, modalWrapper?: HTMLElement) {
        UserManager.Instance.SignIn({
            AccessToken: signInResult.AuthToken,
            RefreshToken: signInResult.RefreshToken,
            RefreshUserVars: true,
            SBIDesigner: signInResult.SBIDesigner
        }).then(() => {
            AgendaDataStore.GetUnreportedTime()
            .then(result => {
                Lockr.set(LOCAL_STORAGE.FILE_DIR, this._authForm.FileDir);

                if (signInResult.LicenseExpiresSoon) {
                    const expirationDate = moment(signInResult.LicenseExpiresIn).format('dddd, MMMM DD, YYYY');
                    this._licenseExpiredSoonForm.Load(expirationDate);
                    this._licenseExpiredSoonForm.One(Events.FormClosed, this, () => {

                        if (signInResult.PasswordExpiresSoon) {
                            this.ShowUpdatePasswordForm(signInResult, result, modalWrapper);
                        } else if (result && result.Hours > 0) {
                            this.ShowUnreportedTimeForm(result, signInResult, modalWrapper);
                        } else {
                            this.CloseAuthFormAndSignIn(signInResult.AuthToken, signInResult.RefreshToken, signInResult.SBIDesigner, modalWrapper);
                        }
                    });
                    return;
                }

                if (signInResult.PasswordExpiresSoon) {
                    this.ShowUpdatePasswordForm(signInResult, result, modalWrapper);
                    return;
                }

                if(result && result.Hours > 0) {
                    this.ShowUnreportedTimeForm(result, signInResult, modalWrapper);
                    return;
                }

                this.CloseAuthFormAndSignIn(signInResult.AuthToken, signInResult.RefreshToken, signInResult.SBIDesigner, modalWrapper);
            });
        });
    }

    private HandleFailedSignIn(signInResult: SignInResultModel, modalWrapper?: HTMLElement) {
        this._authForm.AllowLogin(modalWrapper);
        const resultHandler = this._resultHandlers[signInResult.ErrorCode] || this.GetDefaultResultHandler();
        resultHandler.Handler(signInResult, resultHandler.Form);
    }

    private CloseAuthFormAndSignIn(accessToken: string, refreshToken: string, sbiDesigner: boolean, modalWrapper?: HTMLElement) {
        this.Trigger('SIGN_IN', {AuthToken: accessToken, RefreshToken: refreshToken, SBIDesigner: sbiDesigner});
        this._authForm.Close();

        this._authForm.AllowLogin(modalWrapper);

        this._authFormOpened = false;
    }

    private ShowUpdatePasswordForm(signInResult: SignInResultModel, unReportedTimeModel: UnReportedTimeModel, modalWrapper?: HTMLElement) {
        BlockUI.Unblock(modalWrapper);

        signInResult.ErrorMessage = FormTitles.PasswordExpiresIn(signInResult.PasswordExpiresIn);
        this.ShowForm(signInResult, this._updatePasswordForm);

        this._updatePasswordForm.One(Events.FormClosed, this, () => {
            if(unReportedTimeModel && unReportedTimeModel.Hours > 0) {
                this.ShowUnreportedTimeForm(unReportedTimeModel, signInResult);
            }
            else {
                this.CloseAuthFormAndSignIn(signInResult.AuthToken, signInResult.RefreshToken, signInResult.SBIDesigner);
            }
        });
    }

    private ShowUnreportedTimeForm(unReportedTimeModel: UnReportedTimeModel, signInResult: SignInResultModel, modalWrapper?: HTMLElement) {
        BlockUI.Unblock(modalWrapper);

        unReportedTimeModel.ErrorMessage = CONFIRMATIONS.UNREPORTED_TIME
            .replace('{X}', `${unReportedTimeModel.Hours}`)
            .replace('{Y}', `${unReportedTimeModel.Days}`);
        this.ShowUnReportedTimeForm(unReportedTimeModel, this._unreportedTimeForm);

        this._unreportedTimeForm.One(Events.UnreportedTimeFormClosed, this, () => {
            this.CloseAuthFormAndSignIn(signInResult.AuthToken, signInResult.RefreshToken, signInResult.SBIDesigner);
        });

        this._unreportedTimeForm.One(Events.GoToAgenda, this, () => {
            this.GoToAgendaToReportHours(unReportedTimeModel)
        });
    }

    private ResetPassword() {
        if (this._passwordResetForm.IsValid()) {
            const formData = this._passwordResetForm.GetFormData();
            const resetPasswordModel = ResetPasswordModel.FillFromFormData(formData);

            BlockUI.Block();
            AuthStore.ResetPassword(resetPasswordModel, formData.Token)
                .then(() => {
                    this._passwordResetForm.Close();

                    new Notifier().Success(NOTIFICATIONS.PASSWORD_CHANGED);

                    CookieManager.Remove('ResetPasswordToken');
                })
                .fail(error => new Notifier(JSON.parse(error.message).Message))
                .always(() => BlockUI.Unblock());
        }
    }

    private UpdateExpiredPassword() {
        if (this._passwordExpiredForm.IsValid()) {
            let formData = this._passwordExpiredForm.GetFormData();
            let updatePasswordModel = UpdatePasswordModel.FillFromFormData(formData).MapFrom(this._signInModel);
            AuthStore.UpdateExpiredPassword(updatePasswordModel).then(updatePasswordResult => this.HandleUpdatePasswordResult(updatePasswordResult, this._passwordExpiredForm));
        }
    }

    private UpdatePassword() {
        if (this._updatePasswordForm.IsValid()) {
            let formData = this._updatePasswordForm.GetFormData();
            let updatePasswordModel = UpdatePasswordModel.FillFromFormData(formData).MapFrom(this._signInModel);
            AuthStore.UpdatePassword(updatePasswordModel).then(updatePasswordResult => this.HandleUpdatePasswordResult(updatePasswordResult, this._updatePasswordForm));
        }
    }

    private GoToAgendaToReportHours(unReportedTimeModel: UnReportedTimeModel) {
        PubSub.publish(PUB_SUB_EVENTS.GO_TO_SCREEN_BY_TYPE, { EntityId: unReportedTimeModel.AgendaEntityId, ScreenType: ScreenTypes.SpecialScreen, IsOpenInModal: false });

        this._unreportedTimeForm.Close();
    }

    private UpdateTwoFaSecret() {

        let formData = this._twoFaConfigurationForm.GetFormData();
        let updateTotpSecretModel = UpdateTotpSecretModel.FillFromFormData(formData).MapFrom(this._signInModel);
        AuthStore.UpdateTotpSecret(updateTotpSecretModel)
            .then(updateTotpResult => this.HandleSaveTotpSecrerResult(updateTotpResult, this._twoFaConfigurationForm));
    }

    private SavePasswordLessCredentials() {
        let formData = this._passwordLessConfigurationForm.GetFormData();
        let request = SavePasswordLessCredentialsDto.FillFromFormData(formData).MapFrom(this._signInModel);
        WebAuthnStore.SaveCredential(request);
    }

    private HandleSaveTotpSecrerResult(saveTotpSecretResult: OperationResult, form: TwoFaConfigurationForm) {
        if (!saveTotpSecretResult.IsSuccessfull) {
            this.SetError(saveTotpSecretResult, form);
            return;
        }

        form.Close();
    }

    private HandleUpdatePasswordResult(updatePasswordResult: OperationResult, form: PasswordExpiredForm) {
        if (!updatePasswordResult.IsSuccessfull) {
            this.SetError(updatePasswordResult, form);
            return;
        }

        form.Close();
    }

    private BuildSignInModel() {
        const formData = this._authForm.GetFormData();
        const model = new SignInModel();

        model.DatabaseName = formData.Database();
        model.UserName = formData.UserName();
        model.Password = formData.Password();
        model.Language = formData.Language;
        model.GrantType = 'password';
        model.TotpPassword = this._totpPasswordForm.GetFormData();

        model.ClientId = this._clientId;
        model.ClientSecret = this._clientSecret;

        return model;
    }

    private BuildRequestResetPasswordModel() {
        const formData = this._authForm.GetFormData();

        const model = new RequestResetPasswordModel();
        model.Username = formData.UserName();
        model.DatabaseName = formData.Database();

        return model;
    }

    private SetError(result: SignInResultModel | OperationResult, form: AuthForm | PasswordExpiredForm | TwoFaConfigurationForm) {
        form.ShowError(result.ErrorMessage);
        this._authForm.AllowLogin();
    }

    private ShowForm(result: SignInResultModel, form: PasswordExpiredForm | LicenseForm | TwoFaConfigurationForm | PasswordLessConfigurationForm) {
        form.Load(result.ErrorMessage, this._authForm.GetFormData().Database(), this._authForm.GetFormData().UserName());
    }

    private ShowUnReportedTimeForm(result: UnReportedTimeModel, form: UnreportedTimeForm) {
        form.Load(result.ErrorMessage, this._authForm.GetFormData().Database(), this._authForm.GetFormData().UserName());
    }

    ShowAuthForm(clientId: string = 'WebApplicationId', clientSecret: string = 'WebApplicationSecret'): P.Promise<any> {
        const deferred = P.defer();

        this._clientId = clientId;
        this._clientSecret = clientSecret;
        if (!this._authFormOpened) {            
            this._authForm.AllowLogin();
            this._authFormOpened = true;
            var db = this._authForm.GetLastLoginDb();
            if(db) {
                TranslationManager.Instance.LoadTranslationsForDb(db, this._authForm.GetLastLanguage(db))
                    .then(() => {
                        this._authForm.Close();                        
                        this._authForm.ShowInModal().then(()=>{
                            deferred.resolve(null);
                        });
                        
                    })
                    .fail((err) => {
                        new Notifier().Failed(err.message);                    
                        this._authForm.ShowInModal().then(()=>{
                            deferred.resolve(null);
                        });                        
                    });
            }else{
                this._authForm.ShowInModal().then(()=>{
                    deferred.resolve(null);   
                });                
            }                
        }
        return deferred.promise();
    }

    ShowResetPasswordForm() {
        this._passwordResetForm.Load();
    }
}