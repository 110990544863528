import { deserialize } from 'libs/cerialize';
import * as ko from 'knockout';

export class MailItemModel {
	IsSelected: KnockoutObservable<boolean>;
	IsReadObservable: KnockoutObservable<boolean>;

	constructor() {
		this.IsSelected = ko.observable(false);
		this.IsReadObservable = ko.observable();
	}

	@deserialize
	AttachmentsCount: number;

	@deserialize
	Body: string;

	@deserialize
	BodyText: string;

	@deserialize
	DateTimeCreated: string;

	@deserialize
	FromAddress: string;

	@deserialize
	FromName: string;

	@deserialize
	Guid: string;

	@deserialize
	HasAttachments: boolean;

	@deserialize
	IsInCyberbox: boolean;

	@deserialize
	IsRead: boolean;

	@deserialize
	MailType: number;

	@deserialize
	Pop3Index: number;

	@deserialize
	Subject: string;

	@deserialize
	TypeName: string;
}