import * as ko from 'knockout';
import * as _ from 'underscore';

import {EventBusConsumer} from 'Core/Common/EventBus/EventBusConsumer';

import {BaseProduct} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/BaseProduct';
import {ProductPart} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/ProductPart';

import {TranslationModel} from 'Core/Controls/BaseControl/Models/TranslationModel';

import {ConfigurationPageEvents, GroupProductsLoadedEventArgs} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Events/ConfigurationPageEvents';

export abstract class BaseProductGroup extends EventBusConsumer {
    protected container: HTMLElement;
    protected productsLoaded: boolean;
    protected conversionDropdownLoaded: boolean;

    Products: KnockoutObservableArray<ProductPart>;

    PageNumber: number = 1;
    RecordsPerPage: number = 10;
    AlternativesCount: number = 0;
    CanShowMore: KnockoutObservable<boolean>;
    ExtendedView: KnockoutObservable<boolean>;
    IsMain: KnockoutObservable<boolean>;
    IsHidden: KnockoutObservable<boolean>;
    _pricesMiscalculated: KnockoutComputed<boolean>;
    IsGroupDisabledByAction: KnockoutComputed<boolean>;

    Expanded: KnockoutObservable<boolean>;

    protected constructor(public Id: number, public Name: string, public TranslatedName: string, public Translations: TranslationModel[], public AddAsExtra: boolean, protected parentProduct: BaseProduct) {
        super();

        this.Expanded = ko.observable(false);

        this.CanShowMore = ko.observable(true);
        this.ExtendedView = ko.observable(false);
        this.IsMain = ko.observable(false);
        this.IsHidden = ko.observable(false);
        this.Products = ko.observableArray([]);

        this._pricesMiscalculated = ko.computed(() =>
            this.Products().find(product => product._priceMiscalculated()) != null);

        this.IsGroupDisabledByAction = ko.computed(() => {
            const selectedProduct = this.Products().find(product => product.Selected());
            return !!selectedProduct && selectedProduct.IsDisabledByAction();
        });
    }

    get Container() {
        return this.container;
    }

    get ParentProduct() {
        return this.parentProduct;
    }

    abstract get IsRoot();
    abstract get IsExtra();
    abstract get IsUndefined();

    abstract GetTemplate();

    abstract AfterInit();

    InitMainGroup(mainProduct: ProductPart) {
        this.ExtendedView(true);
        this.IsMain(true);
        this.Products([mainProduct]);
        this.productsLoaded = true;
        this.conversionDropdownLoaded = true;
    }

    abstract Expand(): void;

    abstract Collapse(): void;

    AfterRender(el: HTMLElement) {
        this.container = el[0];
    }

    GetActiveProduct() {
        return _.find(this.Products(), product => product.Selected());
    }

    ToggleView() {
        if (!this.IsMain()) {
            this.ExtendedView(!this.ExtendedView());
        }
    }

    GetActiveProductIndex() {
        return _.findIndex(this.Products(), product => product.Selected());
    }

    HasAlternatives() {
        return this.Products().length > 1;
    }

    get ConversionDropdownLoaded(): boolean {
        return this.conversionDropdownLoaded;
    }
    LoadDropdown(){
        this.conversionDropdownLoaded = true;
    }

    get IsGroupDisabled(): boolean {
        return this._pricesMiscalculated() || this.IsGroupDisabledByAction();
    }

    get IsGroupHidden(): boolean {
        return !this.GetActiveProduct() && this.IsHidden();
    }

    FillProducts(newProducts: ProductPart[], restored: boolean) {
        this.productsLoaded = true;

		if (this.AlternativesCount == 0) {
			this.CanShowMore(false);
		} else if (!restored) {
			this.PageNumber++;
		}

        this.Products.splice(this.Products().length, 0, ...newProducts);
        this.DispatchEvent(ConfigurationPageEvents.GroupProductsLoaded, new GroupProductsLoadedEventArgs(newProducts));
    }

    FindProduct(id: number, rootGroupId: number, rootGroupName: string, groupId: number, groupName: string, path: number[], kSeq: number, kSeqGuid: string): ProductPart {
        for (let productIndex = 0; productIndex < this.Products().length; productIndex++) {
            const product = this.Products()[productIndex];

            if (product.Id === id && product.ParentGroup.Id === groupId && product.ParentGroup.Name === groupName && _.isEqual(product.Path, path) && product.KSeq === kSeq && product.KSeqGuid === kSeqGuid) {
                return product;
            }

            const nestedProduct = product.FindNestedProduct(id, rootGroupId, rootGroupName, groupId, groupName, path, kSeq, kSeqGuid);

            if (nestedProduct) {
                return nestedProduct;
            }
        }

        return null;
    }

    FindGroup(groupId: number, groupName: string, parentId: number) {
        for (let productIndex = 0; productIndex < this.Products().length; productIndex++) {
            for (let productGroupIndex = 0; productGroupIndex < this.Products()[productIndex].Groups().length; productGroupIndex++) {
                if (this.Products()[productIndex].Groups()[productGroupIndex].Id == groupId
                    && this.Products()[productIndex].Groups()[productGroupIndex].Name == groupName
                    && this.Products()[productIndex].Id == parentId) {
                    return this.Products()[productIndex].Groups()[productGroupIndex];
                } else {
                    const resultGroup = this.Products()[productIndex].Groups()[productGroupIndex].FindGroup(groupId, groupName, parentId);
                    if (resultGroup != null) {
                        return resultGroup;
                    }
                }
            }
        }

        return null;
    }

    FindProductById(id: number): ProductPart {
        for (let productIndex = 0; productIndex < this.Products().length; productIndex++) {
            const product = this.Products()[productIndex];

            if (product.Id === id) {
                return product;
            }

            const nestedProduct = product.FindNestedProductById(id);

            if (nestedProduct) {
                return nestedProduct;
            }
        }

        return null;
    }

    UnSelectActiveProduct() {
        const activeProduct = this.GetActiveProduct();

        if (activeProduct) {
            this.DispatchEvent<ProductPart>(ConfigurationPageEvents.ProductPartUnSelecting, activeProduct);
        }

    }

    Dispose() {
        this.Products().forEach(product => product.Dispose());
        super.Dispose();
    }

    protected HasProduct(product: ProductPart) {
        return this.Products().indexOf(product) > -1;
    }

    CollapseActiveNestedGroup() {
        const activeProduct = this.GetActiveProduct();
        if (activeProduct) {
            const group = _.find(activeProduct.Groups(), g => g.Expanded());
            group && group.Collapse();
        }
    }
}