import * as ko from 'knockout';

import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

import {ColorModel} from "../ColorModel";

import {IColorSelectorOptions} from "../../IColorSelectorOptions";
import {ColorDescription} from "../../ColorDescription";

import Template from './Template.html';

export class HexColorModel extends ColorModel {
    private _selectedColor: KnockoutObservable<string>;
    private _inline: KnockoutObservable<boolean>;
    private _position: string;

    constructor(options: IColorSelectorOptions) {
        super(options);
        this._selectedColor = ko.observable(options.InitialColor);
        this._inline = ko.observable(!!options.InlineMod);
        this._position = options.Positions;
    }

    @enumerable get ColorValue() {
        return this._selectedColor();
    }

    SetColor(color: string) {
        this._selectedColor(color);
    }

    GetTemplate(): any {
        return Template;
    }

    SelectColor(color: string) {
        this._selectedColor(color);
        this.Trigger('COLOR_SET', new ColorDescription(color, color));
    }

    Reset() {
        this._selectedColor(null);
        this.Trigger('COLOR_RESET');
    }
}