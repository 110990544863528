import * as ko from 'knockout';
import * as _ from 'underscore';

import Template from 'QueryBuilder/QueryCondition/ConditionEditors/DocumentStatusLookup/Templates/DocumentStatusLookup.html'
import {DocumentStatus} from 'Core/Components/Controls/DocumentManager/DocumentManager';

export class DocumentStatusLookup {
	private _items: KnockoutObservableArray<string>;

	Value: KnockoutObservable<string>;

	constructor() {
		this.Value = ko.observable(null);
		this._items = ko.observableArray(
			[
				DocumentStatus[DocumentStatus.NoVersion],
				DocumentStatus[DocumentStatus.CheckIn],
				DocumentStatus[DocumentStatus.CheckOut],
				DocumentStatus[DocumentStatus.Locked],
				DocumentStatus[DocumentStatus.OwnerLock]
			]
		);
	}

	GetTemplate() {
		return Template;
	}

	AfterRender(el: Array<HTMLElement>) {
	}
} 