import {PasswordResetModel} from "Auth/UI/PasswordResetForm/Model/PasswordResetModel";

export class ResetPasswordModel {
	NewPassword: string;

    static FillFromFormData(formData: PasswordResetModel) {
        const model = new ResetPasswordModel();

        model.NewPassword = formData.NewPassword();

        return model;
    }
}