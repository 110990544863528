import * as ko from 'knockout';

import { Event } from "Core/Common/Event";
import { Modal } from "Core/Common/Modal";
import {CONFIRMATIONS, LABELS} from "Core/Components/Translation/Locales";

import { Events } from "Auth/Enums/Events";
import { PasswordExpiredModel } from "Auth/UI/PasswordExpiredForm/Model/PasswordExpiredModel";
import {MobileChecker} from 'Core/Common/MobileChecker';

import PasswordExpiredFormTemplate from 'Auth/UI/PasswordExpiredForm/Templates/PasswordExpiredForm.html';
ko.templates['Auth/UI/PasswordExpiredForm/Templates/PasswordExpiredForm'] = PasswordExpiredFormTemplate;

export class PasswordExpiredForm extends Event {
	private _model: KnockoutValidatedObservable<PasswordExpiredModel>;
	private _modal: Modal;
	private _hasFocus : KnockoutObservable<boolean>;
	public  _labels = LABELS;
	public _confirmations = CONFIRMATIONS;

	constructor() {
		super();
		this.Init();
		this._hasFocus = ko.observable(false);
	}

	Load(formTitle: string, dbName: string, login: string = '') {
		this.Show(formTitle);
	}

	IsValid() {
		if (!this._model.isValid()) {
			this._model.errors.showAllMessages();
		}

		return this._model.isValid();
	}

	GetFormData() {
		return this._model();
	}

	ShowError(errorText: string) {
		this._model().Error(errorText);
	}

	RemoveErrorSummary() {
		this._model().Error(null);
	}

	private Init() {
		ko.validation.init({
			insertMessages: false
		});
	}

	private Show(formTitle: string) {
		this._model = ko.validatedObservable(new PasswordExpiredModel());
		this._model().FormTitle(formTitle);

		this._modal = new Modal({
			addClass: 'login',
			width: '500',
			minHeight: '300'
		}, false);
		this._modal.One('CLOSE', this, () => { this.Close(); });
		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);

		this._modal.Show();
		let isMobile = MobileChecker.IsMobile();
		this._hasFocus(!isMobile);
	}

	Close() {
		this.Trigger(Events.FormClosed);
		this._modal.Close();
	}

	private OkButtonClicked() {
		this.Trigger(Events.UpdatePassword);
	}

	private CancelButtonClicked() {
		this.Close();
	}

	private GetTemplateName() {
		return 'Auth/UI/PasswordExpiredForm/Templates/PasswordExpiredForm';
	}

	private AfterRender() {

	}
} 