import {deserialize, deserializeAs} from 'libs/cerialize';
import * as ko from 'knockout';

export class ProfileCardViewModel {
	ProfileData: ProfileData;
	PasswordData: PasswordData;
}

export class ProfileData {
	UserId: number;
	Name: string;
	FirstName: string;
	MiddleName: string;
	AvatarData: string;

	get NameTitle() {
		return `${this.FirstName} ${this.Name}`;
	}
}

export class PasswordData {
	UserId: number;
	NewPassword: KnockoutObservable<string>;
	CurrentPassword: KnockoutObservable<string>;
	RetypeNewPassword: KnockoutObservable<string>;

	constructor() {
		this.NewPassword = ko.observable(null);
		this.CurrentPassword = ko.observable(null);
		this.RetypeNewPassword = ko.observable(null);
	}
}