import {P} from "Core/Common/Promise";
import {Request, IRequestOptions} from "Core/Common/Request";

export class UserAllowanceStore {
	static GetUserAllowanceScreen(userId: number): P.Promise<any> {
		let deferredResult = P.defer<any>();

		Request.Send({
				proxy: {
					url: '/api/UserAllowanceApi/GetUserAllowanceScreen',
					dataType: 'json',
					type: 'GET'
				},
				params: { id: userId }
			})
			.then(result => deferredResult.resolve(result))
			.fail(error => console.log(error));

		return deferredResult.promise();
	}
}