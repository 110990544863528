import {P} from "Core/Common/Promise";
import {Request, IRequestOptions} from "Core/Common/Request";
import {EntitiesListModel} from "Core/Controls/FormDesigner/Models/EntitiesListModel";
import {CreateNewFormRequestModel} from "Core/Controls/FormDesigner/Models/CreateNewFormRequestModel";
import {ResetFormRequestModel} from "Core/Controls/FormDesigner/Models/ResetFormRequestModel";
import { DesignScreenModel } from 'Core/Models/Screens/ScreenModel';
import { Deserialize } from 'libs/cerialize';

export class FormDesignerStore {
	static GetFormDesignerMetaData(): P.Promise<any> {
		let deferredResult = P.defer<any>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: 'api/FormDesignerApi/GetFormDesignerMetaData',
				dataType: 'json',
				type: 'GET'
			}
		};

		Request.Send(requestParams)
			.then(data => deferredResult.resolve(data));

		return deferredResult.promise();
	}

	static GetEntities(): P.Promise<EntitiesListModel> {
		let deferredResult = P.defer<any>();
		let requestParams: IRequestOptions = {
			proxy: {
				url: 'api/FormDesignerApi/GetFormDesignerEditScreenModel',
				dataType: 'json',
				type: 'GET'
			}
		};

		Request.Send(requestParams)
			.then(data => deferredResult.resolve(data));

		return deferredResult.promise();
	}

	static CreateNewForm(model: CreateNewFormRequestModel): P.Promise<DesignScreenModel> {
		let deferredResult = P.defer<any>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: 'api/FormDesignerApi/GetScreen',
				dataType: 'json',
				type: 'POST'
			}, params: model
		};

		Request.Send(requestParams)
			.then(result => {
				if (result.IsSuccessfull) {
					deferredResult.resolve(this.DeserializeDesignScreen(result));
				} else {
					deferredResult.reject({ message: result.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static ResetForm(model: ResetFormRequestModel): P.Promise<any> {
		let deferredResult = P.defer<any>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: 'api/FormDesignerApi/ResetScreenFromRoot',
				dataType: 'json',
				type: 'POST'
			},params: model
		};

		Request.Send(requestParams)
			.then(data => deferredResult.resolve(data));

		return deferredResult.promise();
	}

	static DeserializeDesignScreen(jsonObject: any): DesignScreenModel {
		var model = <DesignScreenModel>Deserialize(jsonObject.ResultObject, DesignScreenModel);
		return model;
	}
}