import * as ko from 'knockout';

import {Modal} from "Core/Common/Modal";
import {Event} from "Core/Common/Event";

import {PortletSpaceEditorModel} from "Core/Portlets/Modals/PortletSpaceEditorModal/PortletSpaceEditorModel";
import {PortletSpace} from "Core/Portlets/Models/Runtime/PortletSpace"

import {LABELS} from "Core/Components/Translation/Locales";

import Template from 'Core/Portlets/Modals/PortletSpaceEditorModal/PortletSpaceEditorModal.html';

ko.templates['Core/Portlets/Modals/PortletSpaceEditorModal/PortletSpaceEditorModal'] = Template;

export const EVENTS = {
    SAVE_PORTLET_SPACE: 'SAVE_PORTLET_SPACE'
};

export interface IPortletSpaceEditorParams {
    Name?: string;
    AllPortletSpaces?: Array<PortletSpace>;
}

export interface ISavePortletSpaceParams {
    Name: string;
    CopyFromId: number;
}

export class PortletSpaceEditorModal extends Event {
    private _model: KnockoutValidatedObservable<PortletSpaceEditorModel>;
    private _modal: Modal;
    private _labels = LABELS;

    constructor(params: IPortletSpaceEditorParams) {
        super();

        this._model = ko.validatedObservable(new PortletSpaceEditorModel());

        if (params.Name) {
            this._model().PortletSpaceName(params.Name);
        }

        if (params.AllPortletSpaces && _.any(params.AllPortletSpaces)) {
            const newPortletSpace = new PortletSpace();
            newPortletSpace.ObjectId = 0;
            newPortletSpace.Name = this._labels.NEW;

            let allPortletSpaces = [newPortletSpace];
            params.AllPortletSpaces.forEach(val => allPortletSpaces.push(Object.assign({}, val)));

            this._model().AllPortletSpaces(allPortletSpaces);
            this._model().CopyFromPortletSpace(newPortletSpace);
        }

        this._modal = new Modal({
            addClass: 'jBox-padding-15px',
            width: 350,
            minHeight: 200
        }, false);

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);

        this.InitValidation();
    }

    InitValidation() {
        ko.validation.init({ insertMessages: false }, true);
    }

    GetTemplateName() {
        return 'Core/Portlets/Modals/PortletSpaceEditorModal/PortletSpaceEditorModal';
    }

    AfterRender() {

    }

    Show() {
        this._modal.Show();
    }

    Close() {
        this._modal.Close();
    }

    IsValid() {
        if (!this._model.isValid()) {
            this._model.errors.showAllMessages();
        }

        return this._model.isValid();
    }

    SavePortletSpace() {
        if (this.IsValid()) {
            const model = this._model();

            const params: ISavePortletSpaceParams = {Name: model.PortletSpaceName(), CopyFromId: model.CopyFromPortletSpace() && model.CopyFromPortletSpace().ObjectId};
            this.Trigger(EVENTS.SAVE_PORTLET_SPACE, params);
        }
    }
}