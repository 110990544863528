import * as ko from 'knockout';
import * as _ from 'underscore';

import { QueryExpressionModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { QueryColumnModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryColumnModel';
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";
import { PRIORITY_LIST } from 'QueryBuilder/TableViewConfig/PriorityList';

const minPriority = Math.max.apply(null, PRIORITY_LIST.map(i => i.Value));

export class FieldInfo {
	IsWrapped: KnockoutObservable<boolean>;
	IsGroup: KnockoutObservable<boolean>;
	DisplayPriority: KnockoutObservable<any>;
	IsTotal: KnockoutObservable<boolean>;
	IsCount: KnockoutObservable<boolean>;
	IsSubTotal: KnockoutObservable<boolean>;
	IsAverage: KnockoutObservable<boolean>;
	IsEnableSort = true;

	constructor(
		public Column?: QueryColumnModel,
		public Entity?: QueryEntityModel,
		private _title = LABELS.FORMAT
	) {
		if (Column && Entity) {
			this.IsWrapped = ko.observable(Column.IsWrapped);
			this.IsWrapped.subscribe((newValue) => { Column.IsWrapped = newValue; });

			if (Column.DisplayPriority === 0) { // replace 0 priority with max
				Column.DisplayPriority = minPriority;
			}
			const priority = PRIORITY_LIST.find(item => item.Value === Column.DisplayPriority)

			this.DisplayPriority = ko.observable(priority);
			this.IsTotal = ko.observable(Column.IsTotal);
			this.IsCount = ko.observable(Column.IsCount);
			this.IsSubTotal = ko.observable(Column.IsSubTotal);
			this.IsAverage = ko.observable(Column.IsAverage);
			this.IsGroup = ko.observable(Column.IsGroup);

			this.IsAverage.subscribe((newValue) => { Column.IsAverage = newValue; });
			this.IsTotal.subscribe((newValue) => { Column.IsTotal = newValue; });
			this.IsCount.subscribe((newValue) => { Column.IsCount = newValue; });
			this.IsSubTotal.subscribe((newValue) => { Column.IsSubTotal = newValue; });
			this.IsGroup.subscribe((newValue) => { Column.IsGroup = newValue; });
			this.DisplayPriority.subscribe((newValue) => { Column.DisplayPriority = newValue.Value; });
		} else {
			this.IsEnableSort = false;
		}
	}

	get Title(): string {
		if (this.Entity && this.Column) {
			var entityName = this.Entity.Metadata.NameTranslation || this.Entity.Metadata.Name;
			if (this.Entity.Index > 1) {
				entityName = `${entityName}${this.Entity.Index}`;
			}

			return `${entityName}.${this.Column.Metadata.NameTranslation || this.Column.Metadata.Name}`;
		} else {
			return this._title;
		}
	}
}