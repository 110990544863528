import * as ko from 'knockout'
import * as _ from 'underscore'

import {CONTROL_TYPES, FIELD_TYPE_TO_CONTROL, FIELD_TYPES, RenderModes, SIMPLE_CONTROLS} from 'Core/Constant';
import {ClusterModes} from "Core/Constants/ClusterModes";

import {BaseScreen} from 'Core/Screens/BaseScreen'
import {ScreenModel} from 'Core/Models/Screens/ScreenModel'
import {SubFormModel} from 'Core/Models/Screens/SubFormModel'
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel'
import {BlockUI} from 'Core/Common/BlockUi'
import {Notifier} from 'Core/Common/Notifier'
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes'
import {IGetRelationTypesRequestModel, LinkEditorStore} from 'Core/Screens/Common/LinkEditor/Stores/LinkEditorStore'
import {LinkedFieldValueModel} from 'Core/Screens/Common/LinkEditor/Models/LinkedFieldValueModel'

import {LinkList} from 'Core/Controls/LinkList/LinkList'

import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel'
import {ScreenDataModel} from "Core/ScreenManager/Models/ScreenDataModel"
import {ControlDataModel} from "Core/ScreenManager/Models/ControlDataModel"
import {LinkEditorModel, LinkedRecordModel} from 'Core/Screens/Common/LinkEditor/Models/LinkEditorModel'
import {DataModel, FieldDataModel} from 'Core/Screens/Common/LinkEditor/Models/DataModel'

import {P} from 'Core/Common/Promise'
import LinkEditorTemplate from 'Core/Screens/Common/LinkEditor/Templates/LinkEditor.html'
import {IControl} from "../../../Controls/IControl";
import {SoftColorScheme, SoftColorsProvider} from "../../../Common/SoftColorsProvider";
import {LABELS} from "../../../Components/Translation/Locales";
import {TabTables} from './Components/TabTables/TabTables';
import { IScreen } from 'Core/Controls/Grid/BaseGrid/Index';
import { IconModel } from 'Core/Controls/BaseControl/Models/IconModel';
import { EditLinkDataModel } from 'Core/Controls/LinkList/Models/UpdateDataModel';

ko.templates['Core/Screens/Common/LinkEditor/Templates/LinkEditor'] = LinkEditorTemplate;

export interface ILoadScreenRelationModel {
    MainEntityId: number;
    MainRecordId: number;
    MainRecordTypeId?: number;
    MainRecordKindId?: number;

    RelatedEntityId: number;
    RelatedRecordId: number;
    RelatedRecordTypeId: number;

    KSeq?: number;
}

export interface ILinkEditor extends IScreen {
    LinkLeftEntityId: number;
    LinkRightEntityId: number;
    RelationTypeId: number;
}

export class LinkEditor extends BaseScreen implements ILinkEditor {

    private _linkEditorModel: LinkEditorModel;
    private _tabTables: TabTables;
    private _relatedEntityId: number;
    private _relatedRecordId: number;
    private _mainEntityId: number;
    private _mainRecordTypeId: number;
    private _mainRecordId: number;
    private _kSeq: number;
    private _editorDataModel: DataModel;
    private _leftLinkedEntityName: string;
    private _rightLinkedEntityName: string;
    private _closeAfterSave: boolean = true;
    private _relationTypes: Array<any>;
    private _selectedRelationTypeId: KnockoutObservable<number>;
    private _selectedRelationTypeName: KnockoutObservable<string>;
    private _defaultDisabled: KnockoutObservable<boolean>;
    private _linkListInstance: LinkList;
    private _hasRelations: KnockoutObservable<boolean>;
    private _relationTypeValuesEnabled: KnockoutObservable<boolean>;

    constructor(screenModel: ScreenModel, linkEditorModel: LinkEditorModel = null, relation: ILoadScreenRelationModel = null) {
        super(screenModel, RenderModes.Edit);
        this._linkEditorModel = linkEditorModel;
        this._relatedEntityId = relation.RelatedEntityId;
        this._relatedRecordId = relation.RelatedRecordId;
        this._kSeq = relation.KSeq;
        this._mainEntityId = relation.MainEntityId;
        this._mainRecordTypeId = relation.MainRecordTypeId;
        this._mainRecordId = relation.MainRecordId;
        this._renderMode = RenderModes.Edit;
        this._rightLinkedEntityName = '';
        this._leftLinkedEntityName = '';
        this._relationTypes = (screenModel as any).RelationTypes || [];
        this._selectedRelationTypeId = ko.observable(null);
        this.SetFirstEnabledRelationType();
        this._defaultDisabled = ko.observable(!!_.find(this._relationTypes, (type) => type.Enabled));
        (screenModel as any).SelectedRelationTypeId && this._selectedRelationTypeId((screenModel as any).SelectedRelationTypeId);
        this._selectedRelationTypeName = ko.observable((screenModel as any).SelectedRelationTypeName || null);
        this._linkListInstance = null;
        this._hasRelations = ko.observable(!!this._relationTypes.length);
        this._relationTypeValuesEnabled = ko.observable(false);
        this.AddEvents();
        this.Init();
        this.SetData(screenModel.Data);


        this._selectedRelationTypeId.subscribe((newValue) => {
            this.FireUpdateVariable(newValue.toString());
        });
    }
    
    get LinkLeftEntityId() {
        return this._linkEditorModel.LeftLinkedEntityId
    };

    get LinkRightEntityId(){
        return this._linkEditorModel.RightLinkedEntityId;
    }

    get RelationTypeId(){
        return this._selectedRelationTypeId();
    }

    FireUpdateVariable(value: string){
        if(this.RelationTypeField){
            let fieldModel = new AttachedFieldModel();
            fieldModel.Id = this.RelationTypeField.Id;
            this.UpdateVariable({ Field: fieldModel, ControlType: CONTROL_TYPES.Dropdown }, value);
        }                
    }

    GetEntityId(): number {
        return this._mainEntityId;
    }

    GetTableTypeId(): number {
        return this._mainRecordTypeId;
    }

    GetRecordId(): number {
        return this._mainRecordId;
    }

    GetKseq(): number {
        return this._kSeq;
    }

    GetRelatedEntityId(): number {
        return this._relatedEntityId;
    }

    GetRelatedRecordId(): number {
        return this._relatedRecordId;
    }

    get KSeq(): number {
        return this._kSeq;
    }

    SetRelationTypeValue(linkEditor, event) {
        this._selectedRelationTypeId(event.target.value);
    }

    SetFirstEnabledRelationType() {
        if (this._relationTypes.length && !(this._model as any).SelectedRelationTypeId) {
            const firstRel = _.find(this._relationTypes, (type) => type.Enabled);
            if (firstRel) {
                this._selectedRelationTypeId(firstRel.RecordId);
                this.FireUpdateVariable(firstRel.RecordId);
            }
        }
    }

    get ReferenceControls(): Array<IControl> {
        return _.filter(this.GetAllControls(), (control) => {
            return control.GetType() === CONTROL_TYPES.Text && control.GetFieldModel().FieldTypeName === FIELD_TYPES.Reference;
        });
    }

    private Init() {
        this.On('SAVE', this, (eventArgs: any) => {
            this.Save();
        });
    }

    AddEvents() {
        this.AddEvent("SAVE");
        this.AddEvent("CANCEL");
        this.AddEvent("SAVE_DATA");
        this.AddEvent("MODAL_CLOSE");
        this.AddEvent("DATA_CHANGED");
    }

    GetTemplateName() {
        return 'Core/Screens/Common/LinkEditor/Templates/LinkEditor';
    }

    SetFocusOnFirstControl() {
        const control = this.GetControlBy(c => c.IsFocusable());
        if (control) {
            control.SetFocus(true);
        }
    }

    AfterRender(el) {
        super.AfterRender(el);
        this.SetFocusOnFirstControl();
    }

    static LoadScreen(
        relationModel: ILoadScreenRelationModel,
        isNew: boolean,
        data: any,
        closeAfterSave: boolean = true,
        linkListInstance = null,
        requestedFromEditScreen: boolean = false
    ): P.Promise<LinkEditor> {
        var deferredResult = P.defer<LinkEditor>();
        BlockUI.Block();
        LinkEditorStore.GetData({
            MainEntityId: relationModel.MainEntityId,
            MainRecordId: relationModel.MainRecordId,
            MainRecordTypeId: relationModel.MainRecordTypeId,
            MainRecordKindId: relationModel.MainRecordKindId,
            RelatedEntityId: relationModel.RelatedEntityId,
            RelatedRecordId: relationModel.RelatedRecordId,
            Kseq: relationModel.KSeq,
            IsNew: isNew
        })
            .always(() => {
                BlockUI.Unblock();
            })
            .then(model => {
                if (model !== null) {
                    const FRelationFieldModel = _.where(model.Fields, {Name: 'F_RELATIONTYPEVALUE'})[0];

                    this.GetRelationTypesData({
                            MainEntityId: relationModel.MainEntityId,
                            MainRecordId: relationModel.MainRecordId,
                            MainRecordTypeId: relationModel.MainRecordTypeId,
                            RelatedEntityId: relationModel.RelatedEntityId,
                            RelatedRecordId: relationModel.RelatedRecordId,
                            RelatedRecordTypeId: relationModel.RelatedRecordTypeId,
                            SequenceId: relationModel.KSeq
                        }, FRelationFieldModel,
                        (relationTypes, relationTypeFieldData) => {
                            let screen = this.GenerateScreen(
                                model,
                                data,
                                closeAfterSave,
                                relationTypes,
                                relationTypeFieldData,
                                linkListInstance,
                                requestedFromEditScreen,
                                relationModel
                            );
                            if (screen) {
                                screen._editorDataModel = data;
                            }
                            deferredResult.resolve(screen);
                        }
                    );
                }
            })
            .fail(error => new Notifier().Failed(error.message));

        return deferredResult.promise();
    }

    static GetRelationTypesData(GetRelationTypesRequestModel: IGetRelationTypesRequestModel, FRelationFieldModel, callback) {
        if (FRelationFieldModel) {
            LinkEditorStore.GetRelationTypesData(GetRelationTypesRequestModel).then(model => {
                callback.call(this, model.Items,
                    {
                        FieldId: FRelationFieldModel.Id,
                        FieldName: FRelationFieldModel.Name,
                        FieldValue: FRelationFieldModel.Value,
                        RelatedRecordId: GetRelationTypesRequestModel.RelatedRecordId,
                        CurrentClusterMode: model.CurrentClusterMode,
                    });
            }).fail(error => new Notifier().Failed(error.message));
        } else {
            callback();
        }

    }

    private static GetControlDataModel(field: LinkedFieldValueModel, model: LinkEditorModel, data: DataModel) {
        const controlDataModel = new ControlDataModel();
        controlDataModel.Reference = field.Reference;
        controlDataModel.ControlId = field.Id;

        if (data) {
            const existsData = _.find(data.FieldValues, item => item.FieldId === field.Id);
            if (existsData) {
                controlDataModel.Value = existsData.FieldValue[1];
                controlDataModel.DisplayValue = existsData.FieldValue[1];
            } else {
                controlDataModel.Value = field.Value;
            }
        } else {
            controlDataModel.Translations = field.Translations;
            controlDataModel.Value = field.Value;
            controlDataModel.DisplayValue = field.DisplayValue;
        }

        return controlDataModel;
    }

    get RelationTypeField(): LinkedFieldValueModel{
        return this._linkEditorModel.Fields.find(field => field.Name === 'F_RELATIONTYPEVALUE');
    }

    static GenerateScreen(
        model: LinkEditorModel,
        data: DataModel,
        closeAfterSave: boolean = true,
        relationTypes,
        relationTypeFieldData,
        linkListInstance,
        requestedFromEditScreen: boolean,
        relation: ILoadScreenRelationModel
    ): LinkEditor {
        let screenModel = new ScreenModel();
        screenModel.EntityIcon = new IconModel();
        screenModel.EntityIcon.FontName = "1";		// TODO specify FontName
        screenModel.ScreenTypeName = ScreenTypes[ScreenTypes.LinkEditor];
        screenModel.ActionBar = new SubFormModel();
        screenModel.EntityName = model.LinkEntity;

        const subForm = new SubFormModel();
        screenModel.SubForms = [subForm];

        const dataModel = new ScreenDataModel();
        let relationTypeSelectedData = null;

        const propertyFields = model.Fields
            .filter(field => field.TypeName === FIELD_TYPES.Property)
            .map((field, index) => ({
                Field: field,
                BackgroundColor: SoftColorsProvider.GetColor(index, SoftColorScheme.RGBA)
            }));

        const controlledFields = model.Fields
            .filter(field => field.Name !== 'F_RELATIONTYPEVALUE')
            .filter(field => field.TypeName !== FIELD_TYPES.Property)
            .sort((field1, field2) => field2.Sort > field1.Sort ? -1 : 1);

        let controlsWithFields: ControlModel[] = [];
        _.each(controlledFields, field => {
            const controlModel = this.GetControlModel(field, model.LinkEntity);

            const propertyField = _.find(propertyFields, pField => pField.Field.Id === field.PropertyFieldId);
            if (propertyField) {
                controlModel.SetBackgroundColor(propertyField.BackgroundColor);
            }

            const controlDataModel = this.GetControlDataModel(field, model, data);

            dataModel.ControlsData.push(controlDataModel);
            controlsWithFields.push(controlModel);
        });

        controlsWithFields = _.chain(controlsWithFields).sortBy(control => control.Sort).sortBy(control => control.Fields[0].PropertyFieldId).value();

        let memoControl = _.find(controlsWithFields, control=>{
            return control.TypeName == CONTROL_TYPES.Memo;
        });

        if(memoControl){
            controlsWithFields.splice(controlsWithFields.indexOf(memoControl), 1);
            controlsWithFields.push(memoControl);
        }

        subForm.Controls.push(...controlsWithFields);

        if (data) {
            const relationTypeField = _.filter(data.FieldValues, field => field.FieldValue[0].split('.')[1] === 'F_RELATIONTYPEVALUE')[0];
            relationTypeSelectedData = relationTypeField && relationTypeField.FieldValue[1];
        }

        screenModel.Data = dataModel;

        if (relationTypes) {
            let listAvailabilityStatus = null;
            let SelectedRelationTypeName = null;

            const RelationTypes = relationTypes.map(relationType => {
                relationType.Id = relationTypeFieldData.FieldId;
                relationType.FieldName = relationTypeFieldData.FieldName;
                if (+relationType.RecordId === +relationTypeFieldData.FieldValue
                    || +relationType.RecordId === +relationTypeSelectedData) {
                    SelectedRelationTypeName = relationType.TypeName;
                }
                return relationType;
            });

            screenModel = _.extend(screenModel, {
                RelationTypes: RelationTypes,
                SelectedRelationTypeId: relationTypeFieldData.FieldValue,
                SelectedRelationTypeName: SelectedRelationTypeName,
            });

            if (linkListInstance) {
                listAvailabilityStatus = linkListInstance.GetLinkTypesState(model.RightLinkedEntityName, relationTypeFieldData.RelatedRecordId);
                listAvailabilityStatus && listAvailabilityStatus.map((linkType) => {
                    if (linkType.InstancingMaximum === 0) {
                        const targetedLinkType: any = _.where((screenModel as any).RelationTypes, {
                            TypeName: linkType.Name
                        });
                        if (targetedLinkType.length) {
                            targetedLinkType.map((linkType) => {
                                linkType.Enabled = false;
                            });
                        }
                    }
                });
            }
        }

        const screen = new LinkEditor(screenModel, model, relation);

        screen._linkListInstance = linkListInstance;
        screen._linkEditorModel = model;
        screen._tabTables = !requestedFromEditScreen && model.TabSettings.length > 0
            ? new TabTables({                
                Screen: screen,
                TableParams: model.TabSettings.map(tabSettings => {
                    const record = screen._linkEditorModel.LeftLinkedEntityId === tabSettings.TargetTableId
                        ? screen._linkEditorModel.LeftLinkedRecord
                        : screen._linkEditorModel.RightLinkedRecord;

                    const scopedRecord = screen._linkEditorModel.LeftLinkedEntityId === tabSettings.TargetTableId
                        ? screen._linkEditorModel.RightLinkedRecord.Id
                        : screen._linkEditorModel.LeftLinkedRecord.Id

                    const scopedTable = screen._linkEditorModel.LeftLinkedEntityId === tabSettings.TargetTableId
                        ? screen._linkEditorModel.RightLinkedEntityId
                        : screen._linkEditorModel.LeftLinkedEntityId

                    return {
                        ...tabSettings,
                        RecordId: record.Id,
                        RecordTypeId: record.TypeId,
                        RecordTypeName: record.TypeName,
                        LifeStatusId: record.LifeStatusId,
                        LifeStatusName: record.LifeStatusName,
                        ScopedRecordId: scopedRecord,
                        ScopedTableId: scopedTable
                    }
                })
            }) : null;

        if (screen._tabTables) {
            screen._tabTables.SelectTab(0);
        }

        screen.SetModalOptions({
            maxWidth: '700',
            addClass: 'link-editor-modal showScrollModal',
            minHeight: '550',
            height: '520',
            blockScroll: true
        });

        let hasRelations = relationTypes ? !!relationTypes.length : false;
        screen._hasRelations(hasRelations);

        if (model.Fields.length == 1 && model.Fields[0].Name === 'F_RELATIONTYPEVALUE' && !hasRelations) {
            return null;
        }

        if (hasRelations) {
            const relationTypeValuesEnabled = relationTypeFieldData.CurrentClusterMode === ClusterModes.Use;
            screen._relationTypeValuesEnabled(relationTypeValuesEnabled);
        }

        relationTypeSelectedData && screen._selectedRelationTypeId(relationTypeSelectedData);
        screen._leftLinkedEntityName = screen._linkEditorModel.LeftLinkedEntityTranslatedName;
        screen._rightLinkedEntityName = screen._linkEditorModel.RightLinkedEntityTranslatedName;
        screen.Show(closeAfterSave);
        return screen;
    }

    private Show(closeAfterSave: boolean = true) {
        super.ShowInModal();
        this._closeAfterSave = closeAfterSave;
    }

    static GetControlModel(fieldModel: LinkedFieldValueModel, entityName: string): ControlModel {
        const attachedField = new AttachedFieldModel();

        attachedField.Id = fieldModel.Id;
        attachedField.Name = fieldModel.Name;
        attachedField.FormatName = fieldModel.FormatName;
        attachedField.EntityName = entityName;
        attachedField.ValFieldId = fieldModel.ValFieldId;
        attachedField.ValFieldTypeName = fieldModel.ValFieldTypeName;
        attachedField.ValFieldFormatName = fieldModel.ValFieldFormatName;
        attachedField.ValFieldSize = fieldModel.ValFieldSize;
        attachedField.AllowCustomValue = fieldModel.AllowCustomValue;
        attachedField.AllowCreatingRecords = fieldModel.AllowCreatingRecords;
        attachedField.IsRequired = fieldModel.IsRequired;
        attachedField.IsReadOnly = fieldModel.IsReadOnly;
        attachedField.IsSystem = fieldModel.IsSystem;
        attachedField.IsVirtual = fieldModel.IsVirtual;
        attachedField.ValTableId = fieldModel.ValTableId;
        attachedField.ValTableName = fieldModel.ValTableName;
        attachedField.HasDefaultValue = fieldModel.HasDefaultValue;
        attachedField.FieldTypeName = fieldModel.TypeName;
        attachedField.Size = fieldModel.Size;
        attachedField.AllowInsert = fieldModel.AllowInsert;
        attachedField.ValTableType = fieldModel.ValTableType;
        attachedField.DependsOnId = fieldModel.DependsOnId;
        attachedField.FilledById = fieldModel.FilledById;
        attachedField.PropertyFieldId = fieldModel.PropertyFieldId;
        attachedField.DynamicFields = fieldModel.DynamicFields;
        attachedField.FontColor = fieldModel.FontColor;
        attachedField.FullWidth = fieldModel.FullWidth;
        attachedField.HasLinkEditorVisibility = fieldModel.HasLinkEditorVisibility;
        attachedField.FontStyles = fieldModel.FontStyles;
        attachedField.FilterByField = fieldModel.FilterByField;
        attachedField.HasFilter = fieldModel.HasFilter;

        const controlModel = new ControlModel();

        controlModel.Fields.push(attachedField);
        controlModel.Id = fieldModel.Id;
        controlModel.Name = fieldModel.Name;
        controlModel.Label = fieldModel.NameTranslated || fieldModel.Name;
        controlModel.LabelPosition = "Left";
        controlModel.TypeName = FIELD_TYPE_TO_CONTROL[fieldModel.TypeName];
        controlModel.IsRequired = fieldModel.IsRequired;
        controlModel.IsReadOnly = fieldModel.IsReadOnly;
        controlModel.FieldFlag = fieldModel.FieldFlag;
        controlModel.ResetDependsOnValue = fieldModel.ResetDependsOnValue;
        controlModel.SkipTimeShifting = fieldModel.SkipTimeShifting;
        controlModel.UseFieldName = true;

        return controlModel;
    }

    ValidateData() {
        var result = true;
        _.each(this._controls, control => {
            var isControlValid = control.IsValid();
            if (!isControlValid) {
                result = false;
            }
        });

        if(this._tabTables && !this._tabTables.ValidateData()){
            result = false;
        }

        return result;
    }

    Save() {
        if (this.ValidateData()) {
            var dataModel = this.DeserializeData();
            if (dataModel.FieldValues.length > 0 || dataModel.Grids.length > 0) {
                this.Trigger("SAVE_DATA", dataModel);
                if (this._closeAfterSave) {
                    this.Close();
                }
            } else {
                this.Close();
            }
        }
    }

    GetNotifier(): Notifier {
        return new Notifier($(this._el));
    }

    GetLinkedRecordLabel(linkedRecord: LinkedRecordModel) {
        if (!linkedRecord) {
            return '';
        }

        return `: ${linkedRecord.TranslatedName || linkedRecord.Name}${linkedRecord.Alias ? ` - ${linkedRecord.Alias}` : ''}`;
    }

    GetRelationTypeLabel(relationType) {
        const typeName = relationType.TypeNameTranslation || relationType.TypeName;
        const name = relationType.NameTranslation || relationType.Name;
        const reverse = relationType.ReverseTranslation || relationType.Reverse;

        return `${typeName} : ${name} > ${reverse}`;
    }

    DeserializeData(): DataModel {
        var dataModel = new DataModel();
        dataModel.EntityId = this._relatedEntityId;
        dataModel.RecordId = this._relatedRecordId;
        dataModel.KSeq = this._kSeq;
        _.each(this._controls, control => {
            let controlType = control.GetType();

            if (SIMPLE_CONTROLS.indexOf(controlType) >= 0) {
                if (control.IsModified() || control.GetFieldModel().HasDefaultValue) {
                    const data = control.Deserialize();
                    if (data) {
                        var fieldDataModel = new FieldDataModel();
                        fieldDataModel.FieldId = control.GetControlId();
                        fieldDataModel.FieldValue = data;
                        dataModel.FieldValues.push(fieldDataModel);
                    }
                }
            }
        });
        if (this._selectedRelationTypeId()) {
            const selectedRelationTypeField = _.where(this._relationTypes, {
                RecordId: +this._selectedRelationTypeId()
            })[0];
            let unselectedRelationtype = null;
            if (!selectedRelationTypeField) unselectedRelationtype = this._relationTypes[0];
            const fieldDataModel = new FieldDataModel();
            const FieldValueKey = `${this._linkEditorModel.LinkEntity}.${selectedRelationTypeField && selectedRelationTypeField.FieldName || unselectedRelationtype.FieldName}`;
            selectedRelationTypeField && this._linkListInstance && this._linkListInstance.UpdateLinkTypesState(this._linkEditorModel.RightLinkedEntityName, this._relatedRecordId, selectedRelationTypeField.TypeName, -1);
            fieldDataModel.FieldId = selectedRelationTypeField && selectedRelationTypeField.Id || unselectedRelationtype.Id;
            fieldDataModel.FieldValue = [FieldValueKey, selectedRelationTypeField && selectedRelationTypeField.RecordId || 0];
            dataModel.FieldValues.push(fieldDataModel);
        }

        dataModel.Grids = this._tabTables ? this._tabTables.Serialize() : [];

        return dataModel;
    }
}