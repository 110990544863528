export class NOTIFICATION_ACTIONS {
	static GET(labels) {
	 return [
		 {Name: labels.RECORD_VIEWED_LABEL, Weight: 1},
		 {Name: labels.RECORD_EDITED_LABEL, Weight: 2},
		 {Name: labels.RECORD_DELETED_LABEL, Weight: 4},
		 {Name: labels.LINK_CREATED_LABEL, Weight: 8},
		 {Name: labels.LINK_EDITED_LABEL, Weight: 16},
		 {Name: labels.LINK_DELETED_LABEL, Weight: 32}
	 ]
	}
}