import * as ko from 'knockout'
import * as _ from 'underscore';

import {Event} from 'Core/Common/Event'
import {TimeCellModel, PlannerLevelRowModel} from "../Models/PlannerLevelRowModel";
import {EVENTS} from "Core/Controls/PlannerLevel/Events";
import {Icon} from "Core/Icon/Icon";
import { FormatManager, FormatParams } from "Core/Components/FormatManager/FormatManager";
import {EVENTS as QUERY_RESULT_PAGE_EVENTS} from "Core/Controls/Grid/BaseGrid/QueryResultPage/Events";
import {LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";
import { GridRow } from "Core/Controls/Grid/BaseGrid/GridRow/GridRow";
import {
	ConfirmationDialog,
	EVENTS as CONFIRMATION_DIALOG_EVENTS, Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import {KEY_CODES} from "Core/Controls/PlannerLevel/KeyCodes";
import {FormatConverter} from "FormatEditor/FormatConverter";
import {ViewModes} from 'Core/Controls/Grid/BaseGrid/Enums/ViewModes';

import View from 'Core/Controls/PlannerLevel/RightBarCell/Templates/View.html';
import Edit from 'Core/Controls/PlannerLevel/RightBarCell/Templates/Edit.html';
import Query from 'Core/Controls/PlannerLevel/RightBarCell/Templates/Query.html';
import { QueryButtons } from './QueryButtons';
import { PUB_SUB_EVENTS } from 'MenuManager/PubSubEvents';

ko.templates['Core/Controls/PlannerLevel/RightBarCell/Templates/View'] = View;
ko.templates['Core/Controls/PlannerLevel/RightBarCell/Templates/Edit'] = Edit;
ko.templates['Core/Controls/PlannerLevel/RightBarCell/Templates/Query'] = Query;

export class RightBarCell extends Event{
    private _model: TimeCellModel;
    private _barModel: PlannerLevelRowModel;
    private _value: KnockoutObservable<string>;
    private _displayValue: KnockoutComputed<string>;
    private _cellClasses: KnockoutComputed<string>;
    public TemplateName: KnockoutComputed<string>;
    private _el: HTMLElement;
    private _query1Icon: KnockoutObservable<Icon>;
    private _query1RecordName: KnockoutObservable<string>;
    private _query2Icon: KnockoutObservable<Icon>;
    private _query2RecordName: KnockoutObservable<string>;
    private _dropdownShown: KnockoutObservable<boolean>;

    private _editorWidth: KnockoutObservable<string>;
    private _editorHeight: KnockoutObservable<string>;
    private _isHightResolutionPlanning: boolean;
    private _readOnly: boolean;
    private _hideQueryButtons: boolean;
    CopyCellMode: boolean;

    constructor(model: TimeCellModel, barModel: PlannerLevelRowModel, readonly: boolean, hideQueryButtons: boolean = false){
        super();
        this._barModel = barModel;
        this._hideQueryButtons = hideQueryButtons;
        this._query1RecordName = ko.observable(model.Button1RecordName);
        this._query2RecordName = ko.observable(model.Button2RecordName);
        this._query1Icon = ko.observable(null);
        this._query2Icon = ko.observable(null);
        this._dropdownShown = ko.observable(false);

        if(barModel.Button1Icon){
            this._query1Icon(new Icon(barModel.Button1Icon));            
        }
        
        if(barModel.Button2Icon){
            this._query2Icon(new Icon(barModel.Button2Icon));
        }        

        this._value = ko.observable(null);
        this._editorWidth = ko.observable('30px');
        this._editorHeight = ko.observable('30px');
        this._readOnly = readonly;

        this._displayValue = ko.computed(()=> this._value() === '0' ? '' : this._value());

        this._model = model;
        this._cellClasses = ko.computed(()=>{
            return `${this._model.IsActive() ? 'active-cell': ''} ${this._model.IsSelected() ? 'selected-cell': ''} ${this._model.IsCopyTo() ? 'copy-to-column': ''} ${this._model.IsCopyFrom() ? 'copy-from-column': ''}`;
        });

        this.TemplateName = ko.computed(()=>{
            if(this._barModel.Button1Icon || this._barModel.Button2Icon){
                return 'Core/Controls/PlannerLevel/RightBarCell/Templates/Query';
            }

           return this._model.IsEditMode() ? 'Core/Controls/PlannerLevel/RightBarCell/Templates/Edit' : 'Core/Controls/PlannerLevel/RightBarCell/Templates/View';
        });

        this._model.IsSelected.subscribe((newValue) => {
           if(!newValue){
               this._model.IsEditMode(false);
           }
        });

        this._model.IsEditMode.subscribe((newValue) =>{
           if(!newValue){
               if(this.HasChanges()){
                   this._model.TimeValue = this._value();
                   this.Trigger(EVENTS.UPDATE_PLAN, { CellModel: this._model, CellElement: this._el });
               }
           }
        });

        this.Init();
    }


    get ShowQueryButtons(): boolean{
        return (this._query1Icon() || this._query2Icon()) && this.HasData || !this._hideQueryButtons;
    }

    HasChanges(){
         return parseFloat(this._value().replace(',', '.')) !== parseFloat(this._model.TimeValue.replace(',','.'));
    }

    get HasData(){
        return parseFloat(this._value().replace(',', '.')) !== 0
         || this.Model.Button1RecordId != 0
         || this.Model.Button2RecordId != 0;
    }

    SetCopyToValue(
        value: string,
        button1TableId: number,
        button1RecordId: number,
        button2TableId: number,
        button2RecordId: number,
        overwrite: boolean,
        copyQueryButton1: boolean,
        copyQueryButton2: boolean
    ){
        if(this.HasData && !overwrite) return;
        this._value(value);
        this._model.TimeValue = this._value();
        this.Trigger(EVENTS.UPDATE_PLAN, {
            CellModel: this._model,
            Button1RecordId: copyQueryButton1 ? button1RecordId : null,
            Button1TableId: button1TableId,
            Button2RecordId: copyQueryButton2 ? button2RecordId : null,
            Button2TableId: button2TableId,
            CellElement: this._el
        });
    }

    set IsHightResolutionPlanning(value: boolean){
        this._isHightResolutionPlanning = value;
        if(this._isHightResolutionPlanning){
            this._model.IsEditMode(false);
        }
    }

    SelectCell(){
        if(!this.ShowQueryButtons && (this._query1Icon() || this._query2Icon())){
            if(this._query1Icon() && !this._query2Icon()){
                this.RunQuery1();
                return;
            }

            if(this._query2Icon() && !this._query1Icon()){
                this.RunQuery2();
                return;
            }

            this.ShowQueryButtonsModal();
            return;
        }

        if(this.CopyCellMode){
            this.IsCopyTo(!this.IsCopyTo());
            return;
        }

        if(!this._model.IsEditMode()){
            this.Trigger(EVENTS.UNSELECT_CELLS, { CellModel: this._model });
            this._model.IsSelected(true);
        }
    }

    ShowQueryButtonsModal(){
        let queryButtonsDropdown = new QueryButtons(this._barModel), target = event.target;
        queryButtonsDropdown.On(EVENTS.RUN_QUERY1, this, this.RunQuery1);
        queryButtonsDropdown.On(EVENTS.RUN_QUERY2, this, this.RunQuery2);
        queryButtonsDropdown.On(EVENTS.DROPDOWN_CLOSED, this, () => {this._dropdownShown(false)});
        if (!this._dropdownShown()) {
            queryButtonsDropdown.Show(target);
            this._dropdownShown(true);
        } else {
            queryButtonsDropdown.Close();
            this._dropdownShown(false)
        }
    }

    Init(){
        this._value(this.FormatValue(this._model.TimeValue));
        this.AddEvent(EVENTS.MOUSE_OVER);
        this.AddEvent(EVENTS.MOUSE_OUT);
        this.AddEvent(EVENTS.UNSELECT_CELLS);
        this.AddEvent(EVENTS.KEY_DOWN);
        this.AddEvent(EVENTS.UPDATE_PLAN);
        this.AddEvent(EVENTS.HIGHER_RESOLUTION_PLANNING);
        this.AddEvent(EVENTS.UNLINK_QUERY_RECORD);
        this.AddEvent(EVENTS.COPY_CELL);
    }

    MouseOver(){
        this.Trigger(EVENTS.MOUSE_OVER, { CellModel: this._model });
    }

    MouseOut(){
        this.Trigger(EVENTS.MOUSE_OUT, { CellModel: this._model });
    }

    StartEdit(){
        if(!this._readOnly) {
            if (!this._isHightResolutionPlanning) {
                if (!this._model.IsEditMode()) {
                    this._model.IsEditMode(true);
                }
            } else {
                this.Trigger(EVENTS.HIGHER_RESOLUTION_PLANNING, {CellModel: this._model});
            }
        }
    }

    KeyDown(cell: RightBarCell, event){
        if (event.keyCode !== KEY_CODES.ENTER
            && event.keyCode !== KEY_CODES.UP
            && event.keyCode !== KEY_CODES.LEFT
            && event.keyCode !== KEY_CODES.RIGHT
            && event.keyCode !== KEY_CODES.DOWN
        ) {

            if(this._isHightResolutionPlanning && !this._readOnly){
                this.Trigger(EVENTS.HIGHER_RESOLUTION_PLANNING, { CellModel: this._model });
                return false;
            }

            if (!this._readOnly && !this._model.IsEditMode()) {
                let positionInfo = this._el.getBoundingClientRect();
                this._editorHeight(`${positionInfo.height}px`);
                this._editorWidth(`${positionInfo.width}px`);

                this._model.IsEditMode(true);
                if (!isNaN(event.key)) {
                    this._value(event.key);
                    return false;
                }
            } else {
                return true;
            }

        } else {
            this.Trigger(EVENTS.KEY_DOWN, { CellModel: this._model, Event: event, CellElement: this._el });
        }

        return false;
    }

    get IsSelected(): KnockoutObservable<boolean>{
        return this._model.IsSelected;
    }

    get IsCopyTo(): KnockoutObservable<boolean>{
        return this._model.IsCopyTo;
    }

    get IsCopyFrom(): KnockoutObservable<boolean>{
        return this._model.IsCopyFrom;
    }

    get CellColor(): string {
        return this._model.CellColor;
    }

    get Value(): KnockoutObservable<string>{
        return this._value;
    }

    get TimeAxis(){
        return this._model.TimeAxis;
    }

    AfterRender(elements: Array<HTMLElement>){
        this._el = elements[0];
    }

    SetValue(model: TimeCellModel){
        this._model.TimeValue = model.TimeValue;
        this._model.Button1RecordId = model.Button1RecordId;
        this._model.Button2RecordId = model.Button2RecordId;
        this._model.Button1TableId = model.Button1TableId;
        this._model.Button2TableId = model.Button2TableId;
        this._query1RecordName(model.Button1RecordName);
        this._query2RecordName(model.Button2RecordName);
        this._value(this.FormatValue(this._model.TimeValue));
    }

    FormatValue(value: string){
        return FormatConverter.LocalizeDecimalOrInteger(value);
    }

    RunQuery1(_ = null, evt=null){
        
        if(evt && evt.ctrlKey && this._model.Button1RecordId){
            PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, { EntityId: this._model.Button1TableId, RecordId: this._model.Button1RecordId, IsOpenInModal: true});
            return;
        }

        if(this.CopyCellMode){
            return;
        }

        let formatManager = new FormatManager({Value: null, Format: null, TableId: null, RecordId: null});
        formatManager.RunQuery(this._barModel.Button1Query, ViewModes[ViewModes.Query], '', '', true, this._model.TimeAxis, false);

        formatManager.On(QUERY_RESULT_PAGE_EVENTS.RECORDS_SELECTED, this, (evtArgs) => {
            let gridRow = (evtArgs.data.SelectedRecords as Array<GridRow>)[0];

            if(gridRow){

                if(this._query1RecordName()){
                    this.ConfirmUnlink(gridRow.EntityId, gridRow.RecordId, null, null);
                }else{
                    this.LinkRecordFromQuery(gridRow.EntityId, gridRow.RecordId, null, null);
                }
            }
        });
    }

    RunQuery2(_ = null, evt=null){

        if(evt && evt.ctrlKey && this._model.Button2RecordId){
            PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, { EntityId: this._model.Button2TableId, RecordId: this._model.Button2RecordId, IsOpenInModal: true});
            return;
        }

        if(this.CopyCellMode){
            return;
        }

        let formatManager = new FormatManager({Value: null, Format: null, TableId: null, RecordId: null});
        formatManager.RunQuery( this._barModel.Button2Query, ViewModes[ViewModes.Query], '', '', true, this._model.TimeAxis, false);

        formatManager.On(QUERY_RESULT_PAGE_EVENTS.RECORDS_SELECTED, this, (evtArgs) => {
            let gridRow = (evtArgs.data.SelectedRecords as Array<GridRow>)[0];
            if(gridRow){
                if(this._query2RecordName()){
                    this.ConfirmUnlink(null, null, gridRow.EntityId, gridRow.RecordId);
                }else{
                    this.LinkRecordFromQuery(null, null, gridRow.EntityId, gridRow.RecordId);
                }
            }            
        });
    }

    ConfirmUnlink(
        button1TableId: number,
        button1RecordId: number,
        button2TableId: number,
        button2RecordId: number
    ){
        const confirmationDialog = new ConfirmationDialog({
            Text: NOTIFICATIONS.UNLINK_QUERY_RECORD,
            Type: ConfirmationTypes.Question,
            TextConfirm: LABELS.OK,
            TextDecline: LABELS.CANCEL
        });

        confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
            this.LinkRecordFromQuery(button1TableId, button1RecordId, button2TableId, button2RecordId);
        });
        
        confirmationDialog.Show();
    }

    LinkRecordFromQuery(
        button1TableId: number,
        button1RecordId: number,
        button2TableId: number,
        button2RecordId: number
    ){
        this.Trigger(EVENTS.UPDATE_PLAN, {
            CellModel: this._model,
            Button1RecordId: button1RecordId,
            Button1TableId: button1TableId,
            Button2RecordId: button2RecordId,
            Button2TableId: button2TableId,
            CellElement: this._el
        });
    }

    BuildButton1ContextMenu(cell: RightBarCell){

        let items = {
            "copy": { name: LABELS.COPY }
        };

        if(this._query1RecordName()){
            items["unlink"] = { name: LABELS.UNLINK_RECORD_LABEL }
        }

        return {
            callback: function(key) {
                if(key == 'copy'){
                    cell.Trigger(EVENTS.COPY_CELL);
                    cell.IsCopyFrom(true);
                }

                if(key == 'unlink'){
                    cell.Trigger(EVENTS.UNLINK_QUERY_RECORD, {
                        CellModel: cell._model,
                        ButtonRecordId: cell._model.Button1RecordId,
                        ButtonTableId: cell._model.Button1TableId,
                        CellElement: this._el
                    });    
                }
            },
            items: items
        };
    }

    BuildButton2ContextMenu(cell: RightBarCell){
        let items = {
            "copy": { name: LABELS.COPY }
        };

        if(this._query2RecordName()){
            items["unlink"] = { name: LABELS.UNLINK_RECORD_LABEL }
        }

        return {
            callback: function(key) {

                if(key == 'copy'){
                    cell.Trigger(EVENTS.COPY_CELL);
                    cell.IsCopyFrom(true);
                }

                if(key == 'unlink'){
                    cell.Trigger(EVENTS.UNLINK_QUERY_RECORD, {
                        CellModel: cell._model,
                        ButtonRecordId: cell._model.Button2RecordId,
                        ButtonTableId: cell._model.Button2TableId,
                        CellElement: this._el
                    });
                }

            },
            items: items
        };
    }

    BuildButtonContextMenu(cell: RightBarCell){
        let items = {
            "copy": { name: LABELS.COPY }
        };

        return {
            callback: function(key) {
                if(key == 'copy'){
                    cell.Trigger(EVENTS.COPY_CELL);
                    cell.IsCopyFrom(true);
                }
            },
            items: items
        };
    }

    get Model(): TimeCellModel{
        return this._model;
    }
}