export enum SoftColorScheme {
    RGBA
}

export class SoftColorsProvider {
    static GetColor(index: number, scheme: SoftColorScheme) {
        const colorPalette = this.GetColorPallet();

        while (index >= colorPalette.length) {
            index -= colorPalette.length;
        }

        return colorPalette[index].As(scheme);
    }

    private static GetColorPallet() {
        const colors = ['rgba(246,219,219,0.5)', 'rgba(242,227,198,0.5)', 'rgba(211,236,225,0.5)', 'rgba(194,238,199,0.5)', 'rgba(238,210,232,0.5)'];
        return colors.map(rgbaColor => new SoftColor(rgbaColor));
    }
}

export class SoftColor {
    constructor(private _rgba) {
    }

    As(scheme: SoftColorScheme) {
        switch (scheme) {
            case SoftColorScheme.RGBA:
                return this._rgba;

            default:
                return null;
        }
    }
}