export class FileDownloader {
	static DownloadBase64(base64: string, fileName: string) {
		const arrayBuffer = this.Base64ToArrayBuffer(base64);
		const blob = new Blob([arrayBuffer], { type: 'octet/stream' });
		this.DownloadFileData(blob, fileName);
	}

	static DownloadFile(fileName: string) {
		const url = '/api/FilesApi/GetFile?fileName=' + fileName;
		this.StartDownloadFile(url, fileName);
	}

	static DownloadFileData(blob: Blob, fileName: string) {
		const URL = window.URL || window['webkitURL'];
		const url = URL.createObjectURL(blob);
		this.StartDownloadFile(url, fileName);
	}

	private static StartDownloadFile(url: string, fileName: string) {
		const a = document.createElement('a');
		document.body.appendChild(a);
		a.setAttribute('style', 'display: none');

		a.href = url;
		a.download = fileName;
		a.click();
		URL.revokeObjectURL(url);
		a.remove();
	}

	private static Base64ToArrayBuffer(base64: string) {
		const binaryString = window.atob(base64);
		const binaryLen = binaryString.length;
		const bytes = new Uint8Array(binaryLen);
		for (let i = 0; i < binaryLen; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}
		return bytes;
	}
}