import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

import { LABELS } from "Core/Components/Translation/Locales";

export class Rights {
	constructor(public Name: string, public TranslatedName : string, public Value: number) {

	}

	@enumerable get Translation(): string {
		return this.TranslatedName;
	}
}

export class RecordSecurityRights {
	Select: Rights;
	NoAccess: Rights;
	SearchOnly: Rights;
	ReadOnly: Rights;
	Edit: Rights;
	EditDelete: Rights;
	EditDeleteSecurity: Rights;
	FullAccess: Rights;

	private static _instance;

	private constructor() {
		this.Select = new Rights("---Select rights---", `---${LABELS.SELECT_RIGHTS}---`, null);
		this.NoAccess = new Rights("No Access", LABELS.NO_ACCESS_LABEL, 0);
		this.SearchOnly = new Rights("Search Only", LABELS.SEARCH_ONLY_LABEL, 1);
		this.ReadOnly = new Rights("Read Only", LABELS.READ_ONLY_FLAG, 3);
		this.Edit = new Rights("Edit", LABELS.EDIT, 7);
		this.EditDelete = new Rights("Edit/Delete", LABELS.EDITDELETE_LABEL, 15);
		this.EditDeleteSecurity = new Rights("Edit/Delete/Security", LABELS.EDIT_DELETE_SECURITY_LABEL, 263);
		this.FullAccess = new Rights("Full Access", LABELS.FULL_ACCESS_LABEL, 271);
	}

	static get Instance() {
		return this._instance || (this._instance = new RecordSecurityRights());
	}

	GetAll() {
		return Object.keys(this)
			.filter(key => this[key] instanceof Rights)
			.map(key => this[key]);
	}
}