import * as ko from 'knockout';

import { BaseControl, IControlValue } from 'Core/Controls/BaseControl/BaseControl';
import { IControlParam } from 'Core/Screens/IScreen';
import { RenderModes } from "Core/Constant";

import ConfigJson from 'Core/Controls/IFrameExtension/Configs/config.json';
import { GeneralProperties } from 'Core/GeneralProperties/GeneralProperties';

import ViewTemplate from 'Core/Controls/IFrameExtension/Templates/View.html';
import ToolBarTemplate from 'Core/Controls/IFrameExtension/Templates/ToolBar.html';
import DesignTemplate from 'Core/Controls/IFrameExtension/Templates/Design.html';
import { CookieManager } from '../../Common/CookieManager';
import { frameExtensionFacade } from 'Core/Components/CustomFunctions/Facades/IFrameExtensionFacade';

ko.templates['Core/Controls/IFrameExtension/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/IFrameExtension/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/IFrameExtension/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/IFrameExtension/Templates/Design'] = DesignTemplate;

export interface IControlMounted{
	control: any;			
}

export class IFrameExtension extends BaseControl {
	private _url: KnockoutObservable<string>;
	private _heightPX: KnockoutObservable<number>;

	constructor(params: IControlParam) {
		super(params, ConfigJson);

		const token = CookieManager.GetAuthToken();
		this._url = ko.observable(`/api/IFrameExtensionApi/Index/${this.GetControlId()}/?access_token=${token}`);
		this.Init();
		this._model.subscribe(() => {
			if (this.Properties) {
				this.ApplyProperties();
			}
		});
	}

	Init(): void {
		this._heightPX = ko.observable(null);
		this.ApplyProperties();
	}

	GetDesignerHeight(): string {
		return `${+this._heightPX()}px`;
	}

	ApplyProperties() {
		if (this.Properties) {
			if (this.Properties.Height) {
				_.each(this.Properties.Height.Properties, (property: any) => {
					if (!!property.HeightPX){
						this._heightPX(property.HeightPX);
					}
				});
			}
		}
	}
	FrameLoaded(_, evt){
		const event = new CustomEvent('controlMounted', { detail: { control: frameExtensionFacade(this) } });
		evt.currentTarget.contentWindow.dispatchEvent(event);
	}
}