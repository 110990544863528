import {ProductDto} from "Core/Components/Controls/ProductConfigurator/Models/ProductDto";

import {ProductResponse} from "../Stores/Models/ProductResponse";

import {Product} from "../Models/Product";

export type AfterMap = (productResponse: ProductResponse, product: Product) => void;

export class StartPageMappings {
    MapToProductDto(product: Product) {
        return new ProductDto(product.Id, product.Name, product.NameTranslated, product.Image, product.ConfigurationId);
    }

    MapToProducts(configuredProductsDto: ProductResponse[], orderEntityId: number, controlId: number, productEntityId: number, annotations: any, afterMap?: AfterMap): Product[] {
        return configuredProductsDto.map(product => this.MapToProduct(product, orderEntityId, controlId, productEntityId, annotations, afterMap));
    }

    MapToProduct(configuredProductsDto: ProductResponse, orderEntityId: number, controlId: number, productEntityId: number, annotations: any, afterMap?: AfterMap): Product {
        const product = new Product(configuredProductsDto.Id, configuredProductsDto.Name, configuredProductsDto.NameTranslated, configuredProductsDto.Image,
            configuredProductsDto.ConfigurationId, configuredProductsDto.ConfigurationDescriptions, orderEntityId, controlId, productEntityId, annotations);

        product.Levels = configuredProductsDto.Levels;
        if (afterMap) {
            afterMap(configuredProductsDto, product);
        }

        return product;
    }
}