export const CULTURE_CODES = {
    ps_AF: {Country: 'Afghanistan', Language: 'Pashto', CultureCode: 'ps-AF'},
    prs_AF: {Country: 'Afghanistan', Language: 'Dari', CultureCode: 'prs-AF'},
    sq_AL: {Country: 'Albania', Language: 'Albanian', CultureCode: 'sq-AL'},
    ar_DZ: {Country: 'Algeria', Language: 'Arabic', CultureCode: 'ar-DZ'},
    es_AR: {Country: 'Argentina', Language: 'Spanish', CultureCode: 'es-AR'},
    hy_AM: {Country: 'Armenia', Language: 'Armenian', CultureCode: 'hy-AM'},
    en_AU: {Country: 'Australia', Language: 'English', CultureCode: 'en-AU'},
    de_AT: {Country: 'Austria', Language: 'German', CultureCode: 'de-AT'},
    ar_BH: {Country: 'Bahrain', Language: 'Arabic', CultureCode: 'ar-BH'},
    bn_BD: {Country: 'Bangladesh', Language: 'Bengali', CultureCode: 'bn-BD'},
    eu_ES: {Country: 'Basque', Language: 'Basque', CultureCode: 'eu-ES'},
    be_BY: {Country: 'Belarus', Language: 'Belarusian', CultureCode: 'be-BY'},
    fr_BE: {Country: 'Belgium', Language: 'French', CultureCode: 'fr-BE'},
    nl_BE: {Country: 'Belgium', Language: 'Dutch', CultureCode: 'nl-BE'},
    en_BZ: {Country: 'Belize', Language: 'English', CultureCode: 'en-BZ'},
    es_VE: {Country: 'Bolivarian Republic of Venezuela', Language: 'Spanish', CultureCode: 'es-VE'},
    quz_BO: {Country: 'Bolivia', Language: 'Quechua', CultureCode: 'quz-BO'},
    es_BO: {Country: 'Bolivia', Language: 'Spanish', CultureCode: 'es-BO'},
    pt_BR: {Country: 'Brazil', Language: 'Portuguese', CultureCode: 'pt-BR'},
    ms_BN: {Country: 'Brunei Darussalam', Language: 'Malay', CultureCode: 'ms-BN'},
    bg_BG: {Country: 'Bulgaria', Language: 'Bulgarian', CultureCode: 'bg-BG'},
    km_KH: {Country: 'Cambodia', Language: 'Khmer', CultureCode: 'km-KH'},
    fr_CA: {Country: 'Canada', Language: 'French', CultureCode: 'fr-CA'},
    en_CA: {Country: 'Canada', Language: 'English', CultureCode: 'en-CA'},
    en_029: {Country: 'Caribbean', Language: 'English', CultureCode: 'en-029'},
    ca_ES: {Country: 'Catalan', Language: 'Catalan', CultureCode: 'ca-ES'},
    arn_CL: {Country: 'Chile', Language: 'Mapudungun', CultureCode: 'arn-CL'},
    es_CL: {Country: 'Chile', Language: 'Spanish', CultureCode: 'es-CL'},
    es_CO: {Country: 'Colombia', Language: 'Spanish', CultureCode: 'es-CO'},
    es_CR: {Country: 'Costa Rica', Language: 'Spanish', CultureCode: 'es-CR'},
    hr_HR: {Country: 'Croatia', Language: 'Croatian', CultureCode: 'hr-HR'},
    az_Cyrl_AZ: {Country: 'Cyrillic, Azerbaijan', Language: 'Azeri', CultureCode: 'az-Cyrl-AZ'},
    sr_Cyrl_BA: {Country: 'Cyrillic, Bosnia and Herzegovina', Language: 'Serbian', CultureCode: 'sr-Cyrl-BA'},
    bs_Cyrl_BA: {Country: 'Cyrillic, Bosnia and Herzegovina', Language: 'Bosnian', CultureCode: 'bs-Cyrl-BA'},
    mn_MN: {Country: 'Cyrillic, Mongolia', Language: 'Mongolian', CultureCode: 'mn-MN'},
    sr_Cyrl_ME: {Country: 'Cyrillic, Montenegro', Language: 'Serbian', CultureCode: 'sr-Cyrl-ME'},
    sr_Cyrl_RS: {Country: 'Cyrillic, Serbia', Language: 'Serbian', CultureCode: 'sr-Cyrl-RS'},
    sr_Cyrl_CS: {Country: 'Cyrillic, Serbia and Montenegro (Former', Language: 'Serbian )', CultureCode: 'sr-Cyrl-CS'},
    tg_Cyrl_TJ: {Country: 'Cyrillic, Tajikistan', Language: 'Tajik', CultureCode: 'tg-Cyrl-TJ'},
    uz_Cyrl_UZ: {Country: 'Cyrillic, Uzbekistan', Language: 'Uzbek', CultureCode: 'uz-Cyrl-UZ'},
    cs_CZ: {Country: 'Czech Republic', Language: 'Czech', CultureCode: 'cs-CZ'},
    da_DK: {Country: 'Denmark', Language: 'Danish', CultureCode: 'da-DK'},
    es_DO: {Country: 'Dominican Republic', Language: 'Spanish', CultureCode: 'es-DO'},
    quz_EC: {Country: 'Ecuador', Language: 'Quechua', CultureCode: 'quz-EC'},
    es_EC: {Country: 'Ecuador', Language: 'Spanish', CultureCode: 'es-EC'},
    ar_EG: {Country: 'Egypt', Language: 'Arabic', CultureCode: 'ar-EG'},
    es_SV: {Country: 'El Salvador', Language: 'Spanish', CultureCode: 'es-SV'},
    et_EE: {Country: 'Estonia', Language: 'Estonian', CultureCode: 'et-EE'},
    am_ET: {Country: 'Ethiopia', Language: 'Amharic', CultureCode: 'am-ET'},
    fo_FO: {Country: 'Faroe Islands', Language: 'Faroese', CultureCode: 'fo-FO'},
    fi_FI: {Country: 'Finland', Language: 'Finnish', CultureCode: 'fi-FI'},
    sv_FI: {Country: 'Finland', Language: 'Swedish', CultureCode: 'sv-FI'},
    se_FI: {Country: 'Finland', Language: 'Sami, Northern', CultureCode: 'se-FI'},
    sms_FI: {Country: 'Finland', Language: 'Sami, Skolt', CultureCode: 'sms-FI'},
    smn_FI: {Country: 'Finland', Language: 'Sami, Inari', CultureCode: 'smn-FI'},
    mk_MK: {Country: 'Former Yugoslav Republic of Macedonia', Language: 'Macedonian', CultureCode: 'mk-MK'},
    fr_FR: {Country: 'France', Language: 'French', CultureCode: 'fr-FR'},
    br_FR: {Country: 'France', Language: 'Breton', CultureCode: 'br-FR'},
    oc_FR: {Country: 'France', Language: 'Occitan', CultureCode: 'oc-FR'},
    co_FR: {Country: 'France', Language: 'Corsican', CultureCode: 'co-FR'},
    gsw_FR: {Country: 'France', Language: 'Alsatian', CultureCode: 'gsw-FR'},
    gl_ES: {Country: 'Galician', Language: 'Galician', CultureCode: 'gl-ES'},
    ka_GE: {Country: 'Georgia', Language: 'Georgian', CultureCode: 'ka-GE'},
    de_DE: {Country: 'Germany', Language: 'German', CultureCode: 'de-DE'},
    hsb_DE: {Country: 'Germany', Language: 'Upper Sorbian', CultureCode: 'hsb-DE'},
    dsb_DE: {Country: 'Germany', Language: 'Lower Sorbian', CultureCode: 'dsb-DE'},
    el_GR: {Country: 'Greece', Language: 'Greek', CultureCode: 'el-GR'},
    kl_GL: {Country: 'Greenland', Language: 'Greenlandic', CultureCode: 'kl-GL'},
    qut_GT: {Country: 'Guatemala', Language: "K'iche", CultureCode: 'qut-GT'},
    es_GT: {Country: 'Guatemala', Language: 'Spanish', CultureCode: 'es-GT'},
    es_HN: {Country: 'Honduras', Language: 'Spanish', CultureCode: 'es-HN'},
    hu_HU: {Country: 'Hungary', Language: 'Hungarian', CultureCode: 'hu-HU'},
    is_IS: {Country: 'Iceland', Language: 'Icelandic', CultureCode: 'is-IS'},
    hi_IN: {Country: 'India', Language: 'Hindi', CultureCode: 'hi-IN'},
    bn_IN: {Country: 'India', Language: 'Bengali', CultureCode: 'bn-IN'},
    pa_IN: {Country: 'India', Language: 'Punjabi', CultureCode: 'pa-IN'},
    gu_IN: {Country: 'India', Language: 'Gujarati', CultureCode: 'gu-IN'},
    or_IN: {Country: 'India', Language: 'Oriya', CultureCode: 'or-IN'},
    ta_IN: {Country: 'India', Language: 'Tamil', CultureCode: 'ta-IN'},
    te_IN: {Country: 'India', Language: 'Telugu', CultureCode: 'te-IN'},
    kn_IN: {Country: 'India', Language: 'Kannada', CultureCode: 'kn-IN'},
    ml_IN: {Country: 'India', Language: 'Malayalam', CultureCode: 'ml-IN'},
    as_IN: {Country: 'India', Language: 'Assamese', CultureCode: 'as-IN'},
    mr_IN: {Country: 'India', Language: 'Marathi', CultureCode: 'mr-IN'},
    sa_IN: {Country: 'India', Language: 'Sanskrit', CultureCode: 'sa-IN'},
    kok_IN: {Country: 'India', Language: 'Konkani', CultureCode: 'kok-IN'},
    en_IN: {Country: 'India', Language: 'English', CultureCode: 'en-IN'},
    id_ID: {Country: 'Indonesia', Language: 'Indonesian', CultureCode: 'id-ID'},
    fa_IR: {Country: 'Iran', Language: 'Persian', CultureCode: 'fa-IR'},
    ar_IQ: {Country: 'Iraq', Language: 'Arabic', CultureCode: 'ar-IQ'},
    ga_IE: {Country: 'Ireland', Language: 'Irish', CultureCode: 'ga-IE'},
    en_IE: {Country: 'Ireland', Language: 'English', CultureCode: 'en-IE'},
    ur_PK: {Country: 'Islamic Republic of Pakistan', Language: 'Urdu', CultureCode: 'ur-PK'},
    he_IL: {Country: 'Israel', Language: 'Hebrew', CultureCode: 'he-IL'},
    it_IT: {Country: 'Italy', Language: 'Italian', CultureCode: 'it-IT'},
    en_JM: {Country: 'Jamaica', Language: 'English', CultureCode: 'en-JM'},
    ja_JP: {Country: 'Japan', Language: 'Japanese', CultureCode: 'ja-JP'},
    ar_JO: {Country: 'Jordan', Language: 'Arabic', CultureCode: 'ar-JO'},
    kk_KZ: {Country: 'Kazakhstan', Language: 'Kazakh', CultureCode: 'kk-KZ'},
    sw_KE: {Country: 'Kenya', Language: 'Kiswahili', CultureCode: 'sw-KE'},
    ko_KR: {Country: 'Korea', Language: 'Korean', CultureCode: 'ko-KR'},
    ar_KW: {Country: 'Kuwait', Language: 'Arabic', CultureCode: 'ar-KW'},
    ky_KG: {Country: 'Kyrgyzstan', Language: 'Kyrgyz', CultureCode: 'ky-KG'},
    lo_LA: {Country: 'Lao P.D.R.', Language: 'Lao', CultureCode: 'lo-LA'},
    tzm_Latn_DZ: {Country: 'Latin, Algeria', Language: 'Tamazight', CultureCode: 'tzm-Latn-DZ'},
    az_Latn_AZ: {Country: 'Latin, Azerbaijan', Language: 'Azeri', CultureCode: 'az-Latn-AZ'},
    hr_BA: {Country: 'Latin, Bosnia and Herzegovina', Language: 'Croatian', CultureCode: 'hr-BA'},
    bs_Latn_BA: {Country: 'Latin, Bosnia and Herzegovina', Language: 'Bosnian', CultureCode: 'bs-Latn-BA'},
    sr_Latn_BA: {Country: 'Latin, Bosnia and Herzegovina', Language: 'Serbian', CultureCode: 'sr-Latn-BA'},
    iu_Lat_CA: {Country: 'Latin, Canada', Language: 'Inuktitut', CultureCode: 'iu-Latn-CA'},
    sr_Latn_ME: {Country: 'Latin, Montenegro', Language: 'Serbian', CultureCode: 'sr-Latn-ME'},
    ha_Latn_NG: {Country: 'Latin, Nigeria', Language: 'Hausa', CultureCode: 'ha-Latn-NG'},
    sr_Latn_RS: {Country: 'Latin, Serbia', Language: 'Serbian', CultureCode: 'sr-Latn-RS'},
    sr_Latn_CS: {Country: 'Latin, Serbia and Montenegro (Former', Language: 'Serbian )', CultureCode: 'sr-Latn-CS'},
    uz_Latn_UZ: {Country: 'Latin, Uzbekistan', Language: 'Uzbek', CultureCode: 'uz-Latn-UZ'},
    lv_LV: {Country: 'Latvia', Language: 'Latvian', CultureCode: 'lv-LV'},
    ar_LB: {Country: 'Lebanon', Language: 'Arabic', CultureCode: 'ar-LB'},
    ar_LY: {Country: 'Libya', Language: 'Arabic', CultureCode: 'ar-LY'},
    de_LI: {Country: 'Liechtenstein', Language: 'German', CultureCode: 'de-LI'},
    lt_LT: {Country: 'Lithuania', Language: 'Lithuanian', CultureCode: 'lt-LT'},
    lb_LU: {Country: 'Luxembourg', Language: 'Luxembourgish', CultureCode: 'lb-LU'},
    de_LU: {Country: 'Luxembourg', Language: 'German', CultureCode: 'de-LU'},
    fr_LU: {Country: 'Luxembourg', Language: 'French', CultureCode: 'fr-LU'},
    ms_MY: {Country: 'Malaysia', Language: 'Malay', CultureCode: 'ms-MY'},
    en_MY: {Country: 'Malaysia', Language: 'English', CultureCode: 'en-MY'},
    dv_MV: {Country: 'Maldives', Language: 'Divehi', CultureCode: 'dv-MV'},
    mt_MT: {Country: 'Malta', Language: 'Maltese', CultureCode: 'mt-MT'},
    es_MX: {Country: 'Mexico', Language: 'Spanish', CultureCode: 'es-MX'},
    moh_CA: {Country: 'Mohawk', Language: 'Mohawk', CultureCode: 'moh-CA'},
    fr_MC: {Country: 'Monaco', Language: 'French', CultureCode: 'fr-MC'},
    ar_MA: {Country: 'Morocco', Language: 'Arabic', CultureCode: 'ar-MA'},
    ne_NP: {Country: 'Nepal', Language: 'Nepali', CultureCode: 'ne-NP'},
    nl_NL: {Country: 'Netherlands', Language: 'Dutch', CultureCode: 'nl-NL'},
    fy_NL: {Country: 'Netherlands', Language: 'Frisian', CultureCode: 'fy-NL'},
    mi_NZ: {Country: 'New Zealand', Language: 'Maori', CultureCode: 'mi-NZ'},
    en_NZ: {Country: 'New Zealand', Language: 'English', CultureCode: 'en-NZ'},
    es_NI: {Country: 'Nicaragua', Language: 'Spanish', CultureCode: 'es-NI'},
    yo_NG: {Country: 'Nigeria', Language: 'Yoruba', CultureCode: 'yo-NG'},
    ig_NG: {Country: 'Nigeria', Language: 'Igbo', CultureCode: 'ig-NG'},
    nb_NO: {Country: 'Norway', Language: 'Norwegian, BokmÃ¥l', CultureCode: 'nb-NO'},
    se_NO: {Country: 'Norway', Language: 'Sami, Northern', CultureCode: 'se-NO'},
    nn_NO: {Country: 'Norway', Language: 'Norwegian, Nynorsk', CultureCode: 'nn-NO'},
    smj_NO: {Country: 'Norway', Language: 'Sami, Lule', CultureCode: 'smj-NO'},
    sma_NO: {Country: 'Norway', Language: 'Sami, Southern', CultureCode: 'sma-NO'},
    ar_OM: {Country: 'Oman', Language: 'Arabic', CultureCode: 'ar-OM'},
    es_PA: {Country: 'Panama', Language: 'Spanish', CultureCode: 'es-PA'},
    es_PY: {Country: 'Paraguay', Language: 'Spanish', CultureCode: 'es-PY'},
    quz_PE: {Country: 'Peru', Language: 'Quechua', CultureCode: 'quz-PE'},
    es_PE: {Country: 'Peru', Language: 'Spanish', CultureCode: 'es-PE'},
    fil_PH: {Country: 'Philippines', Language: 'Filipino', CultureCode: 'fil-PH'},
    pl_PL: {Country: 'Poland', Language: 'Polish', CultureCode: 'pl-PL'},
    pt_PT: {Country: 'Portugal', Language: 'Portuguese', CultureCode: 'pt-PT'},
    bo_CN: {Country: 'PRC', Language: 'Tibetan', CultureCode: 'bo-CN'},
    ii_CN: {Country: 'PRC', Language: 'Yi', CultureCode: 'ii-CN'},
    ug_CN: {Country: 'PRC', Language: 'Uyghur', CultureCode: 'ug-CN'},
    es_PR: {Country: 'Puerto Rico', Language: 'Spanish', CultureCode: 'es-PR'},
    ar_QA: {Country: 'Qatar', Language: 'Arabic', CultureCode: 'ar-QA'},
    en_PH: {Country: 'Republic of the Philippines', Language: 'English', CultureCode: 'en-PH'},
    ro_RO: {Country: 'Romania', Language: 'Romanian', CultureCode: 'ro-RO'},
    ru_RU: {Country: 'Russia', Language: 'Russian', CultureCode: 'ru-RU'},
    tt_RU: {Country: 'Russia', Language: 'Tatar', CultureCode: 'tt-RU'},
    ba_RU: {Country: 'Russia', Language: 'Bashkir', CultureCode: 'ba-RU'},
    sah_RU: {Country: 'Russia', Language: 'Yakut', CultureCode: 'sah-RU'},
    rw_RW: {Country: 'Rwanda', Language: 'Kinyarwanda', CultureCode: 'rw-RW'},
    ar_SA: {Country: 'Saudi Arabia', Language: 'Arabic', CultureCode: 'ar-SA'},
    wo_SN: {Country: 'Senegal', Language: 'Wolof', CultureCode: 'wo-SN'},
    zh_CN: {Country: 'Simplified, PRC', Language: 'Chinese', CultureCode: 'zh-CN'},
    zh_SG: {Country: 'Simplified, Singapore', Language: 'Chinese', CultureCode: 'zh-SG'},
    en_SG: {Country: 'Singapore', Language: 'English', CultureCode: 'en-SG'},
    sk_SK: {Country: 'Slovakia', Language: 'Slovak', CultureCode: 'sk-SK'},
    sl_SI: {Country: 'Slovenia', Language: 'Slovenian', CultureCode: 'sl-SI'},
    tn_ZA: {Country: 'South Africa', Language: 'Setswana', CultureCode: 'tn-ZA'},
    xh_ZA: {Country: 'South Africa', Language: 'isiXhosa', CultureCode: 'xh-ZA'},
    zu_ZA: {Country: 'South Africa', Language: 'isiZulu', CultureCode: 'zu-ZA'},
    af_ZA: {Country: 'South Africa', Language: 'Afrikaans', CultureCode: 'af-ZA'},
    nso_ZA: {Country: 'South Africa', Language: 'Sesotho sa Leboa', CultureCode: 'nso-ZA'},
    en_ZA: {Country: 'South Africa', Language: 'English', CultureCode: 'en-ZA'},
    es_ES: {Country: 'Spain, International Sort', Language: 'Spanish', CultureCode: 'es-ES'},
    si_LK: {Country: 'Sri Lanka', Language: 'Sinhala', CultureCode: 'si-LK'},
    sv_SE: {Country: 'Sweden', Language: 'Swedish', CultureCode: 'sv-SE'},
    se_SE: {Country: 'Sweden', Language: 'Sami, Northern', CultureCode: 'se-SE'},
    smj_SE: {Country: 'Sweden', Language: 'Sami, Lule', CultureCode: 'smj-SE'},
    sma_SE: {Country: 'Sweden', Language: 'Sami, Southern', CultureCode: 'sma-SE'},
    rm_CH: {Country: 'Switzerland', Language: 'Romansh', CultureCode: 'rm-CH'},
    de_CH: {Country: 'Switzerland', Language: 'German', CultureCode: 'de-CH'},
    it_CH: {Country: 'Switzerland', Language: 'Italian', CultureCode: 'it-CH'},
    fr_CH: {Country: 'Switzerland', Language: 'French', CultureCode: 'fr-CH'},
    iu_Cans_CA: {Country: 'Syllabics, Canada', Language: 'Inuktitut', CultureCode: 'iu-Cans-CA'},
    syr_SY: {Country: 'Syria', Language: 'Syriac', CultureCode: 'syr-SY'},
    ar_SY: {Country: 'Syria', Language: 'Arabic', CultureCode: 'ar-SY'},
    th_TH: {Country: 'Thailand', Language: 'Thai', CultureCode: 'th-TH'},
    mn_Mong_CN: {Country: 'Traditional Mongolian, PRC', Language: 'Mongolian', CultureCode: 'mn-Mong-CN'},
    zh_HK: {Country: 'Traditional, Hong Kong S.A.R.', Language: 'Chinese', CultureCode: 'zh-HK'},
    zh_MO: {Country: 'Traditional, Macao S.A.R.', Language: 'Chinese', CultureCode: 'zh-MO'},
    zh_TW: {Country: 'Traditional, Taiwan', Language: 'Chinese', CultureCode: 'zh-TW'},
    en_TT: {Country: 'Trinidad and Tobago', Language: 'English', CultureCode: 'en-TT'},
    ar_TN: {Country: 'Tunisia', Language: 'Arabic', CultureCode: 'ar-TN'},
    tr_TR: {Country: 'Turkey', Language: 'Turkish', CultureCode: 'tr-TR'},
    tk_TM: {Country: 'Turkmenistan', Language: 'Turkmen', CultureCode: 'tk-TM'},
    ar_AE: {Country: 'U.A.E.', Language: 'Arabic', CultureCode: 'ar-AE'},
    uk_UA: {Country: 'Ukraine', Language: 'Ukrainian', CultureCode: 'uk-UA'},
    cy_GB: {Country: 'United Kingdom', Language: 'Welsh', CultureCode: 'cy-GB'},
    gd_GB: {Country: 'United Kingdom', Language: 'Scottish Gaelic', CultureCode: 'gd-GB'},
    en_GB: {Country: 'United Kingdom', Language: 'English', CultureCode: 'en-GB'},
    en_US: {Country: 'United States', Language: 'English', CultureCode: 'en-US'},
    es_US: {Country: 'United States', Language: 'Spanish', CultureCode: 'es-US'},
    es_UY: {Country: 'Uruguay', Language: 'Spanish', CultureCode: 'es-UY'},
    vi_VN: {Country: 'Vietnam', Language: 'Vietnamese', CultureCode: 'vi-VN'},
    ar_YE: {Country: 'Yemen', Language: 'Arabic', CultureCode: 'ar-YE'},
    en_ZW: {Country: 'Zimbabwe', Language: 'English', CultureCode: 'en-ZW'},
};
