import {Icon} from "Core/Icon/Icon";
import {LookupFieldTable} from "./LookupFieldTable";

export class LookupField {
    Id: number;
    Name: string;
    Icon: Icon;

    LinkTable: LookupFieldTable;
    LeftTable: LookupFieldTable;
    RightTable: LookupFieldTable;
}