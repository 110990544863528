import * as ko from 'knockout';
import * as _ from 'underscore';
import * as $ from "jquery";

import {QueryExpressionModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import { QueryEntityJoinModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityJoinModel';
import { EntityGroup } from 'QueryBuilder/TableViewConfig/EntityGroup';

import TableViewConfigTemplate from 'QueryBuilder/TableViewConfig/Templates/TableViewConfig.html';

export class TableViewConfig {
	private _expressionModel: QueryExpressionModel;
	private _groups: KnockoutObservable<EntityGroup>;
	private _el: HTMLElement;
	constructor() {
		this._groups = ko.observable(null);
		this._el = null;
	}

	set ExpressionModel(expressionModel: QueryExpressionModel) {
		this._expressionModel = expressionModel;
	}

	DoubleScroll(){
		const wrapper1 = $(this._el).find('.doubleScroll_Wrapper_1');
		const wrapper2 = $(this._el).find('.doubleScroll_Wrapper_2');
		const divScroll = $(wrapper1).find('.doubleScroll_Wrapper_1_DivScroll');

		setTimeout(()=>{
			divScroll.width($(wrapper2).find('.doubleScroll_Wrapper_2_width:first').width());
		},1000);

		$(wrapper1).scroll(()=> {
			$(wrapper2)
				.scrollLeft($(wrapper1).scrollLeft());
		});
		$(wrapper2).scroll(()=> {
			$(wrapper1)
				.scrollLeft($(wrapper2).scrollLeft());
		});
	}

	Update() {
		this._groups(this.GetAllQueryEntities(this._expressionModel));
		this.UpdateFields([this._groups()]);
		this.DoubleScroll();
	}

	UpdateFields(groups: Array<EntityGroup>){
		_.each(groups, (group) => {
			group.UpdateFieldList();
			this.UpdateFields(group.EntitiesGroups);
		});
	}

	GetTemplate() {
		return TableViewConfigTemplate;
	}

	AfterRender(el: HTMLElement){
		this._el = el;
	}


	private GetAllQueryEntities(expression: QueryExpressionModel): EntityGroup {
		let result: EntityGroup = new EntityGroup();

		let joins = [];
		joins = joins.concat(expression.EntityJoins)
		.concat(expression.ReferenceEntityJoins)
		.concat(expression.LookupEntityJoins)
		.concat(expression.SubEntityJoins)
		.concat(expression.ReferenceLookupEntityJoins);

		result.AddEntity(expression.Entity);
		_.each(joins,(join: QueryEntityJoinModel) => {
				if (!join.IsSubQuery) {
					if(join.LinkEntity){
						result.AddEntity(join.LinkEntity);
					}
					result.AddEntity(join.Entity);
					this.GetAllQueryEntitiesFromJoin(join, result);
				} else {
					let groupModel = new EntityGroup();
					if(join.LinkEntity){
						groupModel.AddEntity(join.LinkEntity);
					}
					groupModel.AddEntity(join.Entity);
					this.GetAllQueryEntitiesFromJoin(join, groupModel);
					result.EntitiesGroups.push(groupModel);
				}
			});

		return result;
	}

	private GetAllQueryEntitiesFromJoin(join: QueryEntityJoinModel, group: EntityGroup) {
		let joins = [];
		joins = joins
		.concat(join.Joins)
		.concat(join.LookupEntityJoins)
		.concat(join.ReferenceEntityJoins)
		.concat(join.SubEntityJoins)
		.concat(join.ReferenceLookupEntityJoins);
		
		_.each(joins,(item: QueryEntityJoinModel) => {
				if (!item.IsSubQuery) {
					if(item.LinkEntity){
						group.AddEntity(item.LinkEntity);
					}
					group.AddEntity(item.Entity);
					this.GetAllQueryEntitiesFromJoin(item, group);
				} else {
					let groupModel = new EntityGroup();
					if(item.LinkEntity){
						groupModel.AddEntity(item.LinkEntity);
					}
					groupModel.AddEntity(item.Entity);
					this.GetAllQueryEntitiesFromJoin(item, groupModel);
					group.EntitiesGroups.push(groupModel);
				}
			});
	}
}

