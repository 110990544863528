import * as _ from "underscore";
import * as ko from "knockout";

import {Notifier} from 'Core/Common/Notifier';
import {RecordStore} from 'Core/Common/Stores/RecordStore';

import {IPropertyDescription, IPropertyOption} from "Core/GeneralProperties/Managers/BaseProperty";
import {BaseSelectProperty} from "Core/GeneralProperties/Managers/BaseSelectProperty/BaseSelectProperty";
import {SearchScreen} from "Core/Screens/SearchScreen/SearchScreen";
import {LABELS} from "Core/Components/Translation/Locales";

import SelectPropertyTemplate
    from "Core/GeneralProperties/Managers/SelectProperty/Templates/SelectProperty.html";
import SelectPropertyTemplateSource
    from "Core/GeneralProperties/Managers/SelectProperty/Templates/SelectPropertySource.html";

ko.templates["Core/GeneralProperties/Managers/SelectProperty/Templates/SelectProperty"] = SelectPropertyTemplate;
ko.templates["Core/GeneralProperties/Managers/SelectProperty/Templates/SelectPropertySource"] = SelectPropertyTemplateSource;

export class SelectProperty extends BaseSelectProperty {
    private _templateName: string;
    private _isSearch: KnockoutObservable<boolean>;
    private _searchTerm: KnockoutObservable<string>;
    private _tableName: string;

    constructor(property: IPropertyDescription, propertyValue: IPropertyOption, getData: boolean = true) {
        super(property, propertyValue);
        this._templateName = "Core/GeneralProperties/Managers/SelectProperty/Templates/SelectProperty";
        this._isSearch = ko.observable(null);
        this._searchTerm = ko.observable('');
        this._tableName = '';

        if (property.Source && getData) {
            this._templateName = 'Core/GeneralProperties/Managers/SelectProperty/Templates/SelectPropertySource';
            this._tableName = property.Source.TableName;
            RecordStore.GetLookupData({
                TableName: property.Source.TableName,
                Value: property.Source.Value,
                DisplayValue: property.Source.DisplayValue
            }).then((result) => {
                let items: IPropertyOption[] = _.map(result.Data,
                    (item: IPropertyOption) => {
                        return {Name: item.Name, Value: item.Value}
                    });
                this._isSearch(result.Count > items.length);
                this.Init(items, propertyValue, result.Count > items.length);
            }).fail(error => {
                new Notifier().Failed(error.message);
            });
        } else {
            this.Init(property.Options || [], propertyValue);
        }
    }

    GetTemplateName(): string {
        return this._templateName;
    }

    SetValue(propertyValue: IPropertyOption, isSearchMode?: boolean): void {
        if (!propertyValue) {
            this.Value(this.GetDefaultValue());
            return;
        }

        let selectedValue: IPropertyOption = null;
        if (isSearchMode) {
            selectedValue = propertyValue;
        } else {
            selectedValue = _.find(this._options(), option => option.Value === propertyValue.Value);
        }

        this.Value(selectedValue || this.GetDefaultValue());
    }

    Clear () {
        const selectLabel = LABELS.SELECT_LABEL + '....';
        const empty: IPropertyOption = {Name: selectLabel, Value: null};
        this.Value(empty);
    }

    Search(): void {
        const searchScreen = new SearchScreen({
            EntityName: this._tableName,
            SearchTerm: this._searchTerm(),
            ButtonAdd: false,
        });

        searchScreen.On('RECORD_SELECTED', this, (eventArgs) => {
            this.Value({Name: `${eventArgs.data.Name}`, Value: `${eventArgs.data.RecordId}`});
        });

        searchScreen.Show();
    }
}