import * as ko from 'knockout';
import { BlockUI } from 'Core/Common/BlockUi';

import { HelpBookStore } from 'HelpBook/Store/HelpBookStore';
import { Notifier } from 'Core/Common/Notifier';
import { HelpDescription } from 'HelpBook/HelpDescription/HelpDescription';
import { HelpForm } from 'HelpBook/HelpForm/HelpForm';


import { HelpBook } from 'HelpBook/HelpBook';

import {
	EntityScreen
} from 'HelpBook/Store/Models/HelpBookModel';

import HelpScreenTemplate from 'HelpBook/HelpScreen/Templates/HelpScreen.html';
ko.templates['HelpBook/HelpScreen/Templates/HelpScreen'] = HelpScreenTemplate;

export class HelpScreen {
	private selectedTypeHasData: KnockoutObservable<boolean>;
	private selectedScreenHasData: KnockoutObservable<boolean>;
	private showSelectedScreenData: boolean;
	private skipBlockUi: boolean;
	public id: boolean;
	public selectedEntityScreens: KnockoutObservableArray<EntityScreen>;
	private HelpForm: KnockoutObservable<HelpForm>;

	constructor(type, skipBlock: boolean = false) {
		this.selectedTypeHasData = ko.observable(false);
		this.selectedScreenHasData = ko.observable(false);
		this.selectedEntityScreens = ko.observableArray([]);
		this.showSelectedScreenData = false;
		this.skipBlockUi = skipBlock;
		this.id = null;
		this.HelpForm = ko.observable(null);
		this.FetchData(type);
	}

	FetchData(type) {
		if (!this.skipBlockUi) {
			BlockUI.Block();
		}

		HelpBookStore.GetScreens({ entityId: type.selectedEntityId, tableTypeId: type.Id }).then(result => {
			if (!this.skipBlockUi) {
				BlockUI.Unblock();
			}

			if (!result.IsSuccessfull) {
				new Notifier().Failed(result.ErrorMessage);
				return;
			}
			this.selectedTypeHasData(result.HasData);
			this.selectedScreenHasData(false);

			if (result.HasData) {
				this.selectedEntityScreens(result.ResultObject.map((screen) => {
					return _.extend({}, screen, {
						isActive: ko.observable(false),
						HelpDescription: new HelpDescription(screen.Description)
					});
				}));
				if (this.selectedEntityScreens().length) {
					HelpBook.Instance.GetScreenInfo(_.where(this.selectedEntityScreens(), { Main: true })[0] || this.selectedEntityScreens()[0], true);
				}

				this.showSelectedScreenData = type.directScreen;
			}
		}).fail(() => {
			BlockUI.Unblock();
		});
	}
}