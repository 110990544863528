import {deserialize, deserializeAs} from 'libs/cerialize';
import {TagModel} from 'Core/Controls/Mail/Models/TagModel';

export class LinkFromMailBody {
    constructor() {
        this.Tags = [];
    }

    @deserialize
    EntityId: number;

    @deserialize
    RecordId: number;

    @deserialize
    Name: string;

    @deserializeAs(TagModel)
    Tags: TagModel[];
}