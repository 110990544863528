import * as ko from "knockout";

import { RecordExampleInfoModel, IRecordExampleInfoParams } from "Core/Components/Controls/MakeExample/Models/RecordExampleInfoModel";
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";
import {Event} from 'Core/Common/Event'
import {EVENTS} from "Core/Components/Controls/MakeExample/Events";

import MakeExampleTemplate from "Core/Components/Controls/MakeExample/Templates/MakeExample.html";


export class MakeExample extends Event {
	private _model: KnockoutObservable<RecordExampleInfoModel>;
	private _labels = LABELS;

	constructor(params?: IRecordExampleInfoParams) {
		super();
		this._model = ko.observable(new RecordExampleInfoModel(params));
		this.AddEvent(EVENTS.MAKE_EXAMPLE);
		this._model().ExampleSelected.subscribe((newValue) => {
			this.Trigger(EVENTS.MAKE_EXAMPLE, {IsExample: newValue });
		});
	}

	GetTemplate() {
		return MakeExampleTemplate;
	}

	GetExampleInfo(): RecordExampleInfoModel {
		return this._model();
	}

	get IsMakeExample(): boolean {
		return this._model() && (this._model().PossibleToExample() || this._model().AlreadyExample() || this._model().ExampleSelected())
	}
}