import * as joint from 'libs/rappid/build/rappid';

import { ICanvasItemSizeObject } from 'Core/Controls/CanvasDesigner/Interfaces/ICanvasItemSizeObject';
import { IPorts } from 'Core/Controls/CanvasDesigner/Interfaces/IPorts';
import { ICanvasItemPositionObject } from 'Core/Controls/CanvasDesigner/Interfaces/ICanvasItemPositionObject';
import { IOriginalCanvasItemSize } from 'Core/Controls/CanvasDesigner/Interfaces/IOriginalCanvasItemSize';
import { TextColour } from 'Core/Controls/CanvasDesigner/Enums/TextColour';
import { FontSize } from 'Core/Controls/CanvasDesigner/Enums/FontSize';
import { BackgroundColour } from 'Core/Controls/CanvasDesigner/Enums/BackgroundColour';
import { CanvasModelState } from 'Core/Controls/CanvasDesigner/Enums/CanvasModelState';
import { BorderColour } from 'Core/Controls/CanvasDesigner/Enums/BorderColour';
import { CANVAS_TYPES } from 'Core/Controls/CanvasDesigner/Constants/CanvasTypes';

import {
	CanvasItemModel,
	CanvasLinkModel,
	CanvasModel,
	CanvasIconModel,
} from 'Core/Controls/CanvasDesigner/Models/CanvasModel';

function wrapText({
	text,
	wrapWidth,
	resultWidth,
	wrapHeight,
	attributes
}: {
	text: string;
	wrapWidth: number;
	resultWidth: number;
	wrapHeight: number;
	attributes: joint.attributes.NativeSVGAttributes;
}) {
	if(!text){
		return text;
	}
	const wrappedText = joint.util.breakText(text, { width: wrapWidth, height: wrapHeight }, attributes);
	if (text !== wrappedText) {
		return `${joint.util.breakText(text, { width: resultWidth, height: wrapHeight }, attributes)}...`;
	}
	return text;
}

export class BasicCanvasItemViewModel {
	apiPackageId: number;
	id: number | string;
	replicationId: string;
	recordId: number;
	guid: string;
	type: string;
	size: ICanvasItemSizeObject;
	defaultSize: ICanvasItemSizeObject;
	attrs: any;
	ports: IPorts;
	position: ICanvasItemPositionObject;
	angle: number;
	z: number;
	state: number;
	newlyCreated: boolean;
	inPorts: Array<string>;
	outPorts: Array<string>;
	socketType: string;
	sort: number
	name: string;
	translatedName: string;
	originalName: string;
	protocolId: number;
	shapeId: number;
	shapeName: string;
	sideId: number;
	sideName: string;
	shapeSize: number;
	collapsible: boolean;
	toggled: boolean = false;
	originalSize: IOriginalCanvasItemSize = null;
	generalProperties: any;
	hidden: boolean = false;
	tabsArePresent: boolean = false;
	icon: CanvasIconModel;
	iconSize: ICanvasItemSizeObject;
	protocolName: string;
	defaultValue: string;
	properties: string;

	constructor(model: CanvasItemModel, defaultSize?: ICanvasItemSizeObject) {
		this.id = model.Guid;
		this.replicationId = model.ReplicationId;
		this.recordId = model.Id;
		this.guid = model.Guid;
		this.type = `cyberThing.${model.TypeName}`;
		this.size = defaultSize;
		this.defaultSize = defaultSize;
		this.originalName = model.Name;
		this.position = { 'x': model.X, 'y': model.Y };
		this.sort = model.Sort;
		this.angle = 0;
		this.z = model.Z;
		this.state = model.State;
		this.name = model.Name;
		this.translatedName = model.TranslatedName;
		this.protocolId = model.ProtocolId;
		this.shapeId = model.ShapeId;
		this.sideId = model.SideId;
		this.shapeName = model.ShapeName;
		this.sideName = model.SideName;
		this.shapeSize = model.Size
		this.newlyCreated = true;
		this.collapsible = model.Collapsible;
		this.icon = model.Icon;
		this.attrs = this.GetDefaultAttrs(model);
		this.protocolName = model.ProtocolName;
		this.defaultValue = model.DefaultValue;
		this.properties = model.Properties;

		const properties = JSON.parse(this.properties);

		this.apiPackageId = model.APIPackageId;
	}

	private GetDefaultAttrs(model) {

		const initialName = model.TranslatedName || model.Name;
		let name = initialName;
		const hasIcon = model.Icon && model.Icon.IsImage || model.Icon && model.Icon.IsIcon;

		const fontSettings = {
			"font-size": FontSize[model.TypeName],
			"font-family": "Roboto Condensed",
			"font-weight": 500
		};

		if (model.TypeName === CANVAS_TYPES.SOURCE || model.TypeName === CANVAS_TYPES.DESTINATION) {
			name = wrapText({
				text: initialName,
				wrapWidth: hasIcon ? 110 : 135,
				resultWidth: hasIcon ? 95 : 120,
				wrapHeight: 20,
				attributes: fontSettings
			});
		} else if (
			model.TypeName === CANVAS_TYPES.TABLE ||
			model.TypeName === CANVAS_TYPES.TRIGGER ||
			model.TypeName === CANVAS_TYPES.JS_FUNCTION ||
			model.TypeName === CANVAS_TYPES.CSHARP_FUNCTION
		) {
			name = wrapText({
				text: initialName,
				wrapWidth: hasIcon ? 115 : 145,
				resultWidth: hasIcon ? 100 : 130,
				wrapHeight: 25,
				attributes: fontSettings
			});
		} else if (model.TypeName === CANVAS_TYPES.PARAM) {
			name = wrapText({
				text: initialName,
				wrapWidth: hasIcon ? 105 : 130,
				resultWidth: hasIcon ? 95 : 120,
				wrapHeight: 20,
				attributes: { // from css class "label"
					"font-size": 13,
					"font-family": '"Open Sans", sans-serif',
					"font-weight": 300
				}
			});
		} else if (model.TypeName === CANVAS_TYPES.GROUP) {
			name = wrapText({
				text: initialName,
				wrapWidth: hasIcon ? 110 : 135,
				resultWidth: hasIcon ? 95 : 120,
				wrapHeight: 20,
				attributes: { ...fontSettings, "font-size": 14 }
			});
		}

		return {
			title: { text: `${initialName}, ${model.Id}` },
			".icons": {
				"text": "",
				"fill": "black"
			},
			".shape-label":
			{
				"fill": TextColour[model.TypeName],
				"text": name,
				...fontSettings,
				"stroke-width": 0,
				"y-alignment": "middle"
			},
			".":
			{
				"data-tooltip-position": "left",
				"data-tooltip-position-selector": ".joint-stencil",

			},
			".body":
			{
				"stroke": BorderColour[model.TypeName],
				"fill": BackgroundColour[model.TypeName],
				"rx": 0,
				"ry": 0,
				"stroke-width": 0.5,
				"stroke-dasharray": "0",
				"shape-type": model.ShapeId,
				"protocol": model.ProtocolId,
				"size": model.Size
			},
			"rect":
			{
				"stroke": BorderColour[model.TypeName],
				"fill": BackgroundColour[model.TypeName],
				"width": 30,
				"height": 50,
				"stroke-width": 0.5,
				"stroke-dasharray": "0"
			},
			'.tool-options': {
				'data-tooltip-class-name': 'small',
				'data-tooltip': initialName,
				'data-tooltip-position': 'left'
			},

		};
	}
}