import * as _ from 'underscore';
import * as ko from 'knockout';

import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { ToolbarQueryColumn } from 'Desktop/Toolbar/ToolbarQueryColumn/ToolbarQueryColumn';
import { EntityTypes } from 'Core/Controls/Grid/BaseGrid/Enums/EntityTypes';
import { SpheeresLink } from 'Desktop/Toolbar/SpheeresLink';
import { OpenRecordLink } from 'Desktop/Toolbar/OpenRecordLink';
import {LABELS} from "Core/Components/Translation/Locales";

import Template from 'Desktop/Toolbar/ToolbarQueryEntity/Templates/Template.html';


export class ToolbarQueryEntity {
	private _model: QueryEntityModel;
	private _columns: Array<ToolbarQueryColumn>;
	private _isExpanded: KnockoutObservable<boolean>;
	private _isDesktop: boolean;
	private _spheeresLink: SpheeresLink;
	private _openRecordLink: OpenRecordLink;
	private _labels = LABELS;

	constructor(
		model: QueryEntityModel,
		isDesktop: boolean
	) {
		this._model = model;
		this._isDesktop = isDesktop;
		this._columns = [];
		this._isExpanded = ko.observable(false);
		this.Init();
		this._spheeresLink = new SpheeresLink();
		this._openRecordLink = new OpenRecordLink();
	}

	Init() {
		if (this._model) {
			_.each(this._model.Columns, (column) => {
				var toolbarQueryColumn = new ToolbarQueryColumn(column, this._model.Metadata.Type === EntityTypes[EntityTypes.Link]);
				this._columns.push(toolbarQueryColumn);
			});
		}
	}

	Expand() {
		this._isExpanded(!this._isExpanded());
	}

	get IsExpanded(): KnockoutObservable<boolean> {
		return this._isExpanded;
	}

	get Name() {
		return this._model.Metadata.NameTranslation || this._model.Metadata.Name;
	}

	get Title() {
		var name = this._model.Metadata.NameTranslation || this._model.Metadata.Name;
		if (this._model.Index > 1) {
			name = `${name}${this._model.Index}`;
		}
		return name;
	}

	get Columns(): Array<ToolbarQueryColumn> {
		return this._columns;
	}

	get Model(): QueryEntityModel {
		return this._model;
	}

	GetTemplate() {
		return Template;
	}

	AfterRender() { }

	PasteTable() {}
}