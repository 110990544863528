import * as ko from 'knockout';

import Template from "LookupEditor/Templates/Columns/Password.html";

ko.templates["LookupEditor/Templates/Columns/Password"] = Template;

import { Notifier } from "Core/Common/Notifier";
import { NOTIFICATIONS } from "Core/Components/Translation/Locales";
import { PASSWORD } from 'Core/Constant'

import {ColumnEditor} from "../Base/ColumnEditor";
import {EditableColumnModel} from "../../Models/EditableColumnModel";

import {IValueUpdater} from "../../Interfaces/IValueUpdater";

export class PasswordColumnEditor extends ColumnEditor {
    private _currentOwner: KnockoutObservable<IValueUpdater>;
    private _editModeEnabled: KnockoutComputed<boolean>;
    private _value: KnockoutObservable<string>;

    constructor(column: EditableColumnModel) {
        super(column);

        this._currentOwner = ko.observable(null);
        this._editModeEnabled = ko.computed(() => this._currentOwner() !== null, this);
        this._value = ko.observable(null);
    }

    get EditModeEnabled() {
        return this._editModeEnabled;
    }

    get CurrentOwner() {
        return this._currentOwner;
    }

    get Value() {
        return this._value;
    }

    GetTemplateName(): string {
        return "LookupEditor/Templates/Columns/Password";
    }

    Click(owner: IValueUpdater) {
        this._currentOwner(owner);

        const value = owner.GetValue(this.column.Name);
        this._value(value);
    }

    SaveChanges() {
        if (this._value().length >= PASSWORD.MinimumLength) {
            this._currentOwner().UpdateValue({
                Name: this.column.Name,
                Value: this._value()
            });

            this._currentOwner(null);
        } else {
            new Notifier().Warning(NOTIFICATIONS.INCORECT_PASSWORD_LENGTH.replace('{MinimumLength}', PASSWORD.MinimumLength.toString()));
        }
    }

    CancelChanges() {
        this._currentOwner(null);
    }

    ToString() {
        return 'Password';
    }
}