import * as ko from 'knockout';
import { Modal } from 'Core/Common/Modal';
import { GlobalManager, GLOBALS } from "Core/GlobalManager/GlobalManager";
import { PreviewDocumentModel } from 'Core/Components/Controls/DocumentManager/Models/PreviewDocumentModel';
import { MobileChecker } from "Core/Common/MobileChecker";
import { FILE_EXTENTIONS } from 'Core/Components/Controls/DocumentManager/DocumentViewer/FileExtentions';
import { Notifier } from 'Core/Common/Notifier';
import {
    DocumentManagerStore,
    IPreveiwDocumentRequestModel, ISavePdfDocumentRequestModel
} from 'Core/Components/Controls/DocumentManager/Stores/DocumentManagerStore';
import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS,
    Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import { CONFIRMATIONS, LABELS, NOTIFICATIONS } from "Core/Components/Translation/Locales";
import { BlockUI } from 'Core/Common/BlockUi';

import DocumentTemplate from 'Core/Components/Controls/DocumentManager/DocumentViewer/Templates/DocumentViewer.html';

ko.templates['Core/Components/Controls/DocumentManager/DocumentViewer/Templates/DocumentViewer'] = DocumentTemplate;

export interface PDFJsToolbarButton {
    element: HTMLElement;
    eventName: string;
}

export class DocumentViewer {
    private _htmlData: KnockoutObservable<string>;
    private _pdfData: boolean;
    private _svgData: KnockoutObservable<string>;
    private _pdfViewerSrc: KnockoutObservable<string>;
    private _modal: Modal;
    _PDFViewerApplication: any;
    private _notifier: Notifier;
    private _pfdViewerFrame: HTMLIFrameElement;
    private _listener: any;
    private _isMobile: boolean;
    private _labels = LABELS;

    constructor(
        private _model: PreviewDocumentModel,
        private _requestModel: IPreveiwDocumentRequestModel
    ) {
        this._htmlData = ko.observable(null);
        this._svgData = ko.observable(null);
        this._PDFViewerApplication = null;
        this._pdfViewerSrc = ko.observable('');
        this._isMobile = MobileChecker.IsMobile();

        this._pdfData = _model.FileType.toLowerCase() === FILE_EXTENTIONS.PDF;

        if (_model.FileType.toLowerCase() === FILE_EXTENTIONS.SVG) {
            this._svgData(atob(_model.Content));
        }
        this._notifier = new Notifier();
    }

    PDFViewerSetContent(content: Uint8Array, title: string): void {
        if (this._PDFViewerApplication) {
            this._PDFViewerApplication.open({ data: content })
                .then(() => {
                    let openFileBtn: PDFJsToolbarButton = _.find(this._PDFViewerApplication.toolbar.buttons, (button: PDFJsToolbarButton) => button.eventName === 'openfile');
                    openFileBtn.element.style.display = 'none';
                    this._PDFViewerApplication.setTitleUsingUrl(title); // CYB-9613 fixed download button

                    //#region ***===-- Mozilla PDF.js (v3.6.172) pdfViewer all EventBus --===***
                    // const pdfViewer = this._PDFViewerApplication.pdfViewer;
                    // pdfViewer.eventBus.on('resize', (e) => {});
                    // pdfViewer.eventBus.on('hashchange', (e) => {});
                    // pdfViewer.eventBus.on('beforeprint', (e) => {});
                    // pdfViewer.eventBus.on('afterprint', (e) => {});
                    // pdfViewer.eventBus.on('pagerender', (e) => {});
                    // pdfViewer.eventBus.on('updateviewarea', (e) => {});
                    // pdfViewer.eventBus.on('scalechanging', (e) => {});
                    // pdfViewer.eventBus.on('rotationchanging', (e) => {});
                    // pdfViewer.eventBus.on('sidebarviewchanged', (e) => {});
                    // pdfViewer.eventBus.on('pagemode', (e) => {});
                    // pdfViewer.eventBus.on('namedaction', (e) => {});
                    // pdfViewer.eventBus.on('presentationmodechanged', (e) => {});
                    // pdfViewer.eventBus.on('presentationmode', (e) => {});
                    // pdfViewer.eventBus.on('switchannotationeditormode', (e) => {});
                    // pdfViewer.eventBus.on('print', (e) => {});
                    // pdfViewer.eventBus.on('download', (e) => {});
                    // pdfViewer.eventBus.on('openinexternalapp', (e) => {});
                    // pdfViewer.eventBus.on('firstpage', (e) => {});
                    // pdfViewer.eventBus.on('lastpage', (e) => {});
                    // pdfViewer.eventBus.on('nextpage', (e) => {});
                    // pdfViewer.eventBus.on('previouspage', (e) => {});
                    // pdfViewer.eventBus.on('zoomin', (e) => {});
                    // pdfViewer.eventBus.on('zoomout', (e) => {});
                    // pdfViewer.eventBus.on('zoomreset', (e) => {});
                    // pdfViewer.eventBus.on('pagenumberchanged', (e) => {});
                    // pdfViewer.eventBus.on('scalechanged', (e) => {});
                    // pdfViewer.eventBus.on('rotatecw', (e) => {});
                    // pdfViewer.eventBus.on('rotateccw', (e) => {});
                    // pdfViewer.eventBus.on('optionalcontentconfig', (e) => {});
                    // pdfViewer.eventBus.on('switchscrollmode', (e) => {});
                    // pdfViewer.eventBus.on('scrollmodechanged', (e) => {});
                    // pdfViewer.eventBus.on('switchspreadmode', (e) => {});
                    // pdfViewer.eventBus.on('spreadmodechanged', (e) => {});
                    // pdfViewer.eventBus.on('documentproperties', (e) => {});
                    // pdfViewer.eventBus.on('findfromurlhash', (e) => {});
                    // pdfViewer.eventBus.on('updatefindmatchescount', (e) => {});
                    // pdfViewer.eventBus.on('updatefindcontrolstate', (e) => {});
                    // pdfViewer.eventBus.on('pagerendered', (e) => {});
                    // pdfViewer.eventBus.on('pagechanging', (e) => {});
                    // pdfViewer.eventBus.on('fileinputchange', (e) => {});
                    // pdfViewer.eventBus.on('openfile', (e) => {});
                    //#endregion
                })
        }
    }

    Show() {
        this._modal = new Modal({
            addClass: 'jBox-no-padding pdf-modal',
            width: 'auto',
            heightInPercent: '100%',
            maxHeight: 'calc(100vh - 20px)',
            maxWidth: 'calc(100vw - 20px)',
            content: '',
            closeButton: false,
            closeOnEsc: false
        }, false, true);
        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
    }

    Close() {
        window.document.removeEventListener('webviewerloaded', this._listener);
        if (this._PDFViewerApplication) {
            this.SavePDF(null, null, true);
            return;
        }
        this._modal?.Close();
    }

    GetTemplateName() {
        return 'Core/Components/Controls/DocumentManager/DocumentViewer/Templates/DocumentViewer';
    }

    SavePDF($data?: DocumentViewer, evt?: JQuery.ClickEvent<HTMLElement>, isClose?: boolean): void {
        let targetButtonBox: HTMLElement = evt ? $(evt.target).closest('.save-pdf-button-box')[0] : null,
            isSaveButtonBox: boolean = $(targetButtonBox).hasClass('.save-pdf-button-box'),
            targetObj = isSaveButtonBox ? { Target: targetButtonBox } : null,
            targetElement = isSaveButtonBox ? targetButtonBox : null;

        if (evt) {
            BlockUI.Block(targetObj);
        }

        if (this._PDFViewerApplication) {
            this._PDFViewerApplication.pdfDocument.loadingTask.promise
                .then((pDFDocumentProxy) => {
                    if (evt) {
                        BlockUI.Block(targetObj);
                    }
                    pDFDocumentProxy.saveDocument()
                        .then((newPDFDocument: Uint8Array) => {
                            if (evt) {
                                BlockUI.Block(targetObj);
                            }
                            pDFDocumentProxy.getData()
                                .then((oldPDFDocument: Uint8Array) => {

                                    if (JSON.stringify(oldPDFDocument) !== JSON.stringify(newPDFDocument)) {
                                        const allFileName: string = `<b>${this._model.FileName}${this._model.FileType}</b>`;
                                        let confirmationText = isClose ? CONFIRMATIONS.SAVE_CHANGES_IN_FILE : CONFIRMATIONS.ARE_YOU_SURE_YOU_WANT_TO_SAVE_THE_CHANGES_TO_FILE_AND_CLOSE,
                                            confirmationQuestion = `${confirmationText}`;

                                        const confirmationDialog = new ConfirmationDialog({
                                            Text: confirmationQuestion.replace('{fileName}', allFileName),
                                            Type: ConfirmationTypes.Question,
                                            TextConfirm: LABELS.YES,
                                            TextDecline: LABELS.NO,
                                        });

                                        confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
                                            if (evt) {
                                                BlockUI.Block(targetObj);
                                            }

                                            this.ConvertToBase64(newPDFDocument)
                                                .then((base64String: string) => {
                                                    BlockUI.Block();
                                                    const requestModel: ISavePdfDocumentRequestModel = {
                                                        FieldId: this._requestModel.FieldId,
                                                        Content: base64String,
                                                        RecordKeys: this._requestModel.Recordkeys
                                                    }

                                                    DocumentManagerStore.SavePdfDocument(requestModel)
                                                        .always(() => { BlockUI.Unblock() })
                                                        .then((data) => {
                                                            this._notifier.Success('File Saved');
                                                            this._modal?.Close();

                                                        })
                                                        .fail(err => this._notifier.Failed(err.message));
                                                })
                                                .catch(err => this._notifier.Failed(err.message))
                                                .finally(() => {
                                                    if (evt) {
                                                        BlockUI.Unblock(targetElement);
                                                    }
                                                });
                                        });

                                        if (isClose) {
                                            confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.DISCARD_SELECTED, this, () => {
                                                this._modal?.Close();
                                            })
                                        }

                                        confirmationDialog.Show();
                                        return;
                                    }

                                    if (!isClose) {
                                        this._notifier.Warning('There are no changes in the file');
                                    } else {
                                        this._modal?.Close();
                                    }
                                })
                                .finally(() => {
                                    if (evt) {
                                        BlockUI.Unblock(targetElement);
                                    }
                                });
                        })
                        .finally(() => {
                            if (evt) {
                                BlockUI.Unblock(targetElement);
                            }
                        });
                })
                .finally(() => {
                    if (evt) {
                        BlockUI.Unblock(targetElement);
                    }
                });
        }
    }

    AfterRender(elements: Array<HTMLElement>) {

        const el = elements[0]


        if (this._model.FileType.toLowerCase() === FILE_EXTENTIONS.HTML) {
            this._htmlData(this._model.Content);
        }

        this._modal.Show();

        if (this._pdfData) {
            this._pfdViewerFrame = $(el).find('#pdf-viewer')[0] as HTMLIFrameElement;

            this._listener = this.WebViewerLoaded.bind(this);
            window.document.addEventListener('webviewerloaded', this._listener);

            const desktopLanguage = GlobalManager.Instance.GetGlobal(GLOBALS.DESKTOP_LANGUAGE).toLowerCase();

            const locale = desktopLanguage === 'en' ? 'en-US' : desktopLanguage;
            this._pdfViewerSrc(`${__webpack_public_path__}pdfjs/web/viewer.html?file=#locale=${locale}`);
        }

    }

    WebViewerLoaded(){
        const { contentWindow: { PDFViewerApplication } } : { contentWindow: Window & { PDFViewerApplication?: any }} = this._pfdViewerFrame;

        this._PDFViewerApplication = PDFViewerApplication;
        
        setTimeout(()=>{
            this.PDFViewerSetContent(this.Base64ToUint8Array(this._model.Content), `${this._model.FileName}${this._model.FileType}`)
        }, 1000);
    }

    Base64ToUint8Array(base64Pdf: string): Uint8Array {
        var pdfData = atob(base64Pdf);
        var array = new Uint8Array(new ArrayBuffer(pdfData.length));
        for (var i = 0; i < pdfData.length; i++) {
            array[i] = pdfData.charCodeAt(i);
        }
        return array;
    }

    ConvertToBase64(bytes) {
        return new Promise((resolve, reject) => {
            const chunkSize = 8192;
            let content = "";

            for (let i = 0; i < bytes.length; i += chunkSize) {
                const chunk = bytes.slice(i, i + chunkSize);
                content += String.fromCharCode(...new Uint8Array(chunk));
            }

            const base64String = btoa(content);
            if (!base64String) {
                reject("Error converting to base64");
            } else {
                resolve(base64String);
            }
        });
    }
}