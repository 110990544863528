import * as ko from "knockout";

import {ColumnEditor} from "LookupEditor/ColumnEditors/Base/ColumnEditor";

import NonEditableTemplate from "LookupEditor/Templates/Columns/NonEditable.html";
ko.templates["LookupEditor/Templates/Columns/NonEditable"] = NonEditableTemplate;


export class NonEditableColumnEditor extends ColumnEditor {

    GetTemplateName(): string {
        return 'LookupEditor/Templates/Columns/NonEditable';
    }

    AfterRender(el): void {

    }

    ToString() {
        return "NonEditable";
    }
}