import * as ko from "knockout";
import * as _ from "underscore";

import {BaseProperty, IPropertyDescription} from "Core/GeneralProperties/Managers/BaseProperty";
import {IControl} from 'Core/Controls/IControl';
import {BlockUI} from 'Core/Common/BlockUi';
import {TableTypeModel} from 'Core/Screens/TypeScreen/Models/TableTypeModel';
import { TemplateModel } from 'Core/Controls/ButtonTemplate/Models/TemplateModel';
import { ButtonTemplateStore, IMergeAndSaveRequestModel, IGetTemplatesRequestModel } from 'Core/Controls/ButtonTemplate/Stores/ButtonTemplateStore';

import Template from "Core/GeneralProperties/Managers/DocumentTemplateProperty/DocumentTemplatePropertyTemplate.html";
import { EntityTypesStore, IGetTypesRequestModel } from "Core/Screens/TypeScreen/Stores/EntityTypesStore";

ko.templates["Core/GeneralProperties/Managers/DocumentTemplateProperty/DocumentTemplatePropertyTemplate"] = Template;


export class DocumentTemplateProperty extends BaseProperty {
    private _el: HTMLElement;
    private _control: IControl;
    private _templates: KnockoutObservableArray<TemplateModel>;
    private _selectedTemplate: KnockoutObservable<TemplateModel>;
    private _selectedTypeSubscription: KnockoutSubscription;

    constructor(property: IPropertyDescription, propertyValue: string, control: IControl) {
        super(property);
        this._control = control;
        this._templates = ko.observableArray([]);
        this._selectedTemplate = ko.observable(null);
        this.Value = ko.observable(propertyValue || this.GetDefaultValue());

        this.IsEnabled.subscribe(newValue=>{
            if(!newValue){
                this.Value(null);
            }
        });

        this.IsEnabled(control.GeneralProperties.GetPropertyValue('Position').Value === 'Row');

        control.GeneralProperties.GetPropertyManager('Position').Value.subscribe((newValue)=>{
            this.IsEnabled(newValue.Value == 'Row');
        });
    }

    GetTemplateName(): string {
        return "Core/GeneralProperties/Managers/DocumentTemplateProperty/DocumentTemplatePropertyTemplate";
    }

    GetDefaultValue() {
        return null;
    }

    SetValue(propertyValue: string): void {
        this.Value(propertyValue);
    }

    OnInit(): void {
        const requestModel: IGetTemplatesRequestModel = {
			SubjectEntityId: (this._control as any).ContextEntityId,
			ObjectEntityId:  (this._control as any).ContextEntityId,
			ControlId: this._control.GetControlId()
		};

        ButtonTemplateStore.GetData(requestModel).then((templates)=>{
            this._templates(templates);

            if(this.Value()){
                let selectedTemplate = _.find(templates, (template) => template.Id === this.Value());
                if(selectedTemplate){
                    this._selectedTemplate(selectedTemplate);
                }else{
                    this.Value(null);
                }
            }

            this._selectedTemplate.subscribe((newValue)=> {
                if(newValue && newValue.Id){
                    this.Value(newValue.Id);
                }else{
                    this.Value(null);
                }
            });    
        });
    }

    Reset() {
        this.OnInit();
    }

    AfterRender(el: Array<HTMLElement>) {}
}