import * as ko from 'knockout';

import GeneralTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/General.html';
import GeneralEditTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/GeneralEdit.html';
import MemoTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Memo.html';
import SortDescriptionTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/SortDescription.html';
import ImageTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Image.html';
import ReferenceTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Reference.html';
import YesNoTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/YesNo.html';
import ColorTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Color.html';
import MultiSelectTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/MultiSelect.html';
import PasswordTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Password.html';
import DocumentTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Document.html';
import NoAccess from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/NoAccess.html';
import SortTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Sort.html';
import PhoneNumberTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/PhoneNumber.html';
import ClusteredTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Clustered.html';
import FormatTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Format.html';
import HyperLinkTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/HyperLink.html';
import CurrencyTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Currency.html';
import SpimTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Spim.html';
import EmptyTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Empty.html';

ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/General'] = GeneralTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/GeneralEdit'] = GeneralEditTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/Memo'] = MemoTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/SortDescription'] = SortDescriptionTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/Image'] = ImageTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/Reference'] = ReferenceTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/YesNo'] = YesNoTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/Color'] = ColorTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/MultiSelect'] = MultiSelectTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/Password'] = PasswordTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/Document'] = DocumentTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/NoAccess'] = NoAccess;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/Sort'] = SortTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/PhoneNumber'] = PhoneNumberTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/Clustered'] = ClusteredTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/Format'] = FormatTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/HyperLink'] = HyperLinkTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/Currency'] = CurrencyTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/Spim'] = SpimTemplate;
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/Empty'] = EmptyTemplate;