import * as ko from 'knockout';
import * as _ from 'underscore';

import {Event} from "Core/Common/Event";

import DropdownTemplate from 'Core/Components/TranslationFieldEditor/TranslationEditorDropdownTemplate.html';

import { TranslationManager } from 'Core/Components/Translation/TranslationManager';
import { ITranslationValue } from "Core/Components/TranslationFieldEditor/ITranslationValue";
import { TranslationItem } from "Core/Components/TranslationFieldEditor/TranslationItem";
import { ZIndexManager } from 'Core/Common/ZIndexManager';
import {MobileChecker} from "../../Common/MobileChecker";

export class TranslationFieldEditor extends Event {
	private _translationItems: KnockoutObservableArray<TranslationItem>;
	private _activeTranslationItem: KnockoutObservable<TranslationItem>;
	private _translationMaxSize: number;

	private _opened: KnockoutObservable<boolean>;
	private _subscription: any;
	private _zIndex: number;

	constructor() {
		super();

		this._zIndex = ZIndexManager.Instance.NextValue;

		this._translationItems = ko.observableArray([]);

		this._activeTranslationItem = ko.observable(null);

		this._opened = ko.observable(false);

		this._translationMaxSize = 0;
	}

	get TranslationItemsList() {
		return this._translationItems();
	}

	get ActiveTranslation() {
		return this._activeTranslationItem();
	}

	GetTranslationMaxSize(): number {
		return this._translationMaxSize > 0 ? this._translationMaxSize : -1;
	}

	LoadTranslationItems() {
		const translationItems = TranslationManager.Instance.Languages.map(language => new TranslationItem(language));
		translationItems.forEach(item => item.On("TranslationChanged", this, eventArgs => this.Trigger("TranslationChanged", eventArgs.data)));
		this._translationItems(translationItems);
		this._activeTranslationItem(translationItems[0]);
		this.SubscribeOnValueChanges(translationItems[0].Language.Id);
	}

	SubscribeOnValueChanges(id: number) {
        if (this._subscription) {
        	this._subscription.dispose();
		}
		if (id === this._activeTranslationItem().Language.Id) {
            this._subscription = this._activeTranslationItem().Value.subscribe((newValue) => {
                this.Trigger('TranslationSelected', {
                    LanguageId: this._activeTranslationItem().Language.Id,
                    Value: newValue
                });
            })
		}
	}

	SetTranslations(translations: ITranslationValue[], defaultValue: string) {
		if (translations) {
			translations.forEach(translation => this.SetTranslation(translation));
		}

		this.SetTranslation({
			LanguageId: TranslationManager.Instance.GetDefaultLanguage().Id,
			Value: defaultValue
		});
	}

	SetTranslationMaxSize(translationMaxSize: number) {
		this._translationMaxSize = translationMaxSize;
	}

	SetActiveTranslation(languageId: number) {
		const translationItem = this.GetTranslationById(languageId);
		this._activeTranslationItem(translationItem);
		this.SubscribeOnValueChanges(translationItem.Language.Id);
	}

	SetValue(value: string) {
		if (this._activeTranslationItem()) {
			this._activeTranslationItem().Value(value);
		}
	}

	GetTranslations(withDefault: boolean = true): ITranslationValue[] {
		const defaultLanguage = TranslationManager.Instance.GetDefaultLanguage();
		let translations = withDefault ? this._translationItems() : this._translationItems().filter(item => item.Language.Id !== defaultLanguage.Id);

		return translations.map(translation => {
			return {
				LanguageId: translation.Language.Id,
				Value: translation.Value()
			};
		});
	}

	GetDefaultTranslation(): ITranslationValue {
		const defaultLanguage = TranslationManager.Instance.GetDefaultLanguage();
		const translation = this.GetTranslationById(defaultLanguage.Id);

		return {
			LanguageId: translation.Language.Id,
			Value: translation.Value()
		};
	}

	GetTranslationById(id: number) : TranslationItem {
		return _.find<TranslationItem>(this._translationItems(), item => item.Language.Id === id);
	}

	Toggle() {
		this._opened(!this._opened());
		if (this._opened()) {
			this.SetFocus();
		}
	}

	// Add to focus the first blank item
	SetFocus() {
		let focusEmptyElement = _.find(this._translationItems(), item => item.Value() === null );
		let isMobile = MobileChecker.IsMobile();
		focusEmptyElement && focusEmptyElement.IsFocused(!isMobile);
	}

	SelectTranslation(translation: TranslationItem) {
		this._activeTranslationItem(translation);
		this.SubscribeOnValueChanges(translation.Language.Id);

        this.Trigger('TranslationSelected', {
			LanguageId: translation.Language.Id,
			Value: translation.Value()
		});

		this.Toggle();
	}

	GetTemplate() {
		return DropdownTemplate;
	}

	AfterRender(el): void {
	}

	private SetTranslation(translation: ITranslationValue) {
		const translationItem = _.find<TranslationItem>(this._translationItems(), item => item.Language.Id === translation.LanguageId);
		translationItem.SetTranslation(translation.Value);
	}
}