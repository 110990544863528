import { Guid } from "Core/Common/Guid";

export class  BaseModelClass {
	protected _guid: string;
	protected _isModified: boolean
	protected _lifeStatusName;

	constructor() {
		this._guid = Guid.NewGuid();
		this._isModified = false;
	}

	public get Guid() : string {
		return this._guid;
	}
	public set Guid(v : string) {
		this._guid = v;
	}
	public get IsModified() : boolean {
		return this._isModified;
	}
	public set IsModified(v : boolean) {
		this._isModified = v;
	}

	public get LifeStatusName() : string {
		return this._lifeStatusName;
	}
	public set LifeStatusName(v : string) {
		this._lifeStatusName = v;
	}
}

export function BaseModel() {
	this._guid = Guid.NewGuid();
	this._isModified = false;
	this._lifeStatusName = 4;
};

BaseModel.prototype.RegenerateGuid = function () {
	this._guid = Guid.NewGuid();
};

Object.defineProperty(BaseModel.prototype, 'Guid', {
	enumerable: true,
	get: function () {
		return this._guid;
	},
	set: function (val) {
		this._guid = val;
	}
});

Object.defineProperty(BaseModel.prototype, 'IsModified', {
	enumerable: true,
	get: function () {
		return this._isModified;
	},
	set: function (val) {
		this._isModified = val;
	}
});

Object.defineProperty(BaseModel.prototype, 'LifeStatusName', {
	enumerable: true,
	get: function () {
		return this._lifeStatusName;
	},
	set: function (val) {
		this._lifeStatusName = val;
	}
});
