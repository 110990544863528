import { BaseModel } from "DatabaseDesigner/Models/BaseModel";
import { CollectionModel } from "DatabaseDesigner/Models/CollectionModel";
import { TranslationModel } from "DatabaseDesigner/Models/TranslationModel";
import * as _ from 'underscore';
import { Guid } from "Core/Common/Guid";

export function LifeStatusModel() {
    this._guid = Guid.NewGuid();

    this._id = null;
    this._name = null;
    this._exampleId = null;
    this._translations = new CollectionModel({ model: TranslationModel });

    BaseModel.apply(this);
};

LifeStatusModel.prototype = Object.create(BaseModel.prototype);
LifeStatusModel.prototype.constructor = LifeStatusModel;

LifeStatusModel.prototype.FromJSON = function (data) {
    this._id = data.Id;
    this._name = data.Name;
    this._translations = TranslationModel.prototype.FromJSONArray(data.Translations);
};

LifeStatusModel.prototype.toJSON = function () {
    return {
        Guid: this.Guid,
        Id: this.Id,
        Name: this.Name,
        ExampleId: this.ExampleId,
        DisplaySort: this.DisplaySort,
        Translations: JSON.stringify(this.Translations && this.Translations),
    };
};

LifeStatusModel.prototype.CreateDefaultTranslations = function (languages) {
    if (languages) {
        var self = this;
        self._translations = new CollectionModel({ model: TranslationModel });

        _.forEach(languages, function (language) {
            var translation = new TranslationModel();
            translation.Language = language;
            self._translations.Add(translation);
        });
    }
};

LifeStatusModel.prototype.FromJSONArray = function (data) {
    var lifeStatusesList = new CollectionModel({ model: LifeStatusModel });

    for (var index = 0; index < data.length; index++) {
        var lifeStatusModel = new LifeStatusModel();

        lifeStatusModel.FromJSON(data[index]);
        lifeStatusesList.Add(lifeStatusModel);
    }

    return lifeStatusesList;
};

Object.defineProperty(LifeStatusModel.prototype, 'Guid', {
    enumerable: true,
    get: function () {
        return this._guid;
    },
    set: function (value) {
        this._guid = value;
    }
});

Object.defineProperty(LifeStatusModel.prototype, 'Id', {
    enumerable: true,
    get: function () {
        return this._id;
    },
    set: function (value) {
        this._id = value;
    }
});

Object.defineProperty(LifeStatusModel.prototype, 'Name', {
    enumerable: true,
    get: function () {
        return this._name;
    },
    set: function (value) {
        this._name = value;
    }
});

Object.defineProperty(LifeStatusModel.prototype, 'Translations', {
    enumerable: true,
    get: function () {
        return this._translations;
    },
    set: function (value) {
        this._translations = value;
    }
});

Object.defineProperty(LifeStatusModel.prototype, 'ExampleId', {
    enumerable: true,
    get: function () {
        return this._exampleId;
    },
    set: function (value) {
        this._exampleId = value;
    }
});
