export class BulkEmailEntity {
	Fields: BulkEmailField[];

	constructor(public Id: number, public Name: string) {
		this.Fields = [];
	}
}

export class BulkEmailField {
	constructor(public Id: number, public Name: string) {
	}
}