import * as ko from "knockout";

import {ColumnEditor} from "LookupEditor/ColumnEditors/Base/ColumnEditor";

import YesNoTemplate from "LookupEditor/Templates/Columns/Sort.html";
ko.templates["LookupEditor/Templates/Columns/Sort"] = YesNoTemplate;

export class SortColumnEditor extends ColumnEditor{
	Show(): void {}

	ToString() {
		return "Sort";
	}

	AfterRender(el) {}

	GetTemplateName(): string {
		return 'LookupEditor/Templates/Columns/Sort';
	}
} 