import { IGetEntityRequestModel } from "Core/Common/Interfaces/IGetEntityRequestModel";
import { IRetrieveRecordsModel } from "LookupEditor/Store/Interfaces/IRetrieveRecordsModel";
import { IDeleteRecordsRequestModel } from "LookupEditor/Store/Interfaces/IDeleteRecordsRequestModel";
import { ISaveLookupRequestModel } from "LookupEditor/Store/Interfaces/ISaveLookupRequestModel";
import { RetrievedRecordsModel } from "LookupEditor/Store/Models/RetrievedRecordsModel";
import { P } from "Core/Common/Promise";
import { URL } from "Core/Common/Url";
import { IRequestOptions, Request } from "Core/Common/Request";
import { NewRecordModel } from "LookupEditor/NewRecord/Models/NewRecordModel";

export class LookupEditorStore {
	static GetMetadata(): P.Promise<any> {
		let deferredResult = P.defer<any>();
		let requestParams: IRequestOptions = {
			proxy: {
				url: URL.LOOKUP_EDITOR.GET_LOOKUP_SCREEN_METADATA,
				type: 'GET',
				dataType: 'json'
			}
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
			});

		return deferredResult.promise();
	}

	static GetLookupData(retrieveArgs: IRetrieveRecordsModel) {
		var deferredResult = P.defer<any>();
		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.LOOKUP_EDITOR.GET_LOOKUP_DATA,
				dataType: 'json',
				type: 'POST'
			},
			params: retrieveArgs
		};

		Request.Send(requestParams)
			.then(getDataResult => {
				if (getDataResult.IsSuccessfull) {
					deferredResult.resolve(getDataResult.ResultObject);
				} else {
					deferredResult.reject({ message: getDataResult.ErrorMessage });
				}
			}).fail(() => deferredResult.reject({ message: "Error reading data for table" }));

		return deferredResult.promise();
	}

	static DeleteRecords(deleteArgs: IDeleteRecordsRequestModel) {
		var deferredResult = P.defer<any>();
		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.LOOKUP_EDITOR.DELETE_RECORDS,
				dataType: 'json',
				type: 'POST'
			},
			params: deleteArgs
		};

		Request.Send(requestParams)
			.then(deleteResult => deferredResult.resolve(deleteResult));

		return deferredResult.promise();
	}

	static SaveChanges(saveArgs: ISaveLookupRequestModel) {
		return Request.Send({
			proxy: {
				url: URL.LOOKUP_EDITOR.SAVE_CHANGES,
				type: 'POST'
			},
			params: saveArgs
		});
	}

	static AddRecord(model: NewRecordModel): P.Promise<any> {
		return Request.Send({
			proxy: {
				url: '/api/LookupEditorApi/AddRecord',
				type: 'POST',
			}, params: model
		})
	}

	static GetLookupRecordsCount(lookupFieldId: number): P.Promise<number> {
		let deferredResult = P.defer<number>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/LookupEditorApi/GetLookupRecordsCount',
				type: 'GET',
				dataType: 'json'
			}, params: { id: lookupFieldId }
		}

		Request.Send(requestParams)
			.then(result => {
				if (result.IsSuccessfull) {
					deferredResult.resolve(result.ResultObject);
				} else {
					deferredResult.reject({ message: result.ErrorMessage });
				}
			}).fail(() => deferredResult.reject({ message: "Error getting records count" }));

		return deferredResult.promise();
	}

	static GetRecord(tableId: number, recordId: number): P.Promise<any> {
		let deferredResult = P.defer<any>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/DataApi/GetDataForRecord',
				type: 'POST',
				dataType: 'json'
			},
			params: { tableId: tableId, recordId: recordId }
		}

		Request.Send(requestParams)
			.then(result => {
				if (result.IsSuccessfull) {
					deferredResult.resolve(result.ResultObject);
				} else {
					deferredResult.reject({ message: result.ErrorMessage });
				}
			}).fail(() => deferredResult.reject({ message: "Error reading record" }));

		return deferredResult.promise();
	}

	static GetRecords(tableId: number): P.Promise<any> {
		let deferredResult = P.defer<any>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/DataApi/GetDataForRecords',
				type: 'POST',
				dataType: 'json'
			},
			params: { tableId: tableId, usePagination: false }
		}

		Request.Send(requestParams)
			.then(result => {
				if (result.IsSuccessfull) {
					deferredResult.resolve(result.ResultList);
				} else {
					deferredResult.reject({ message: result.ErrorMessage });
				}
			}).fail(() => deferredResult.reject({ message: "Error reading record" }));

		return deferredResult.promise();
    }

    static GetRecordsByIds(tableId: number, recordsIds: number[]): P.Promise<any> {
        let deferredResult = P.defer<any>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: '/api/DataApi/GetDataForRecordsByIds',
                type: 'POST',
                dataType: 'json'
            },
            params: { tableId: tableId, recordsIds: recordsIds }
        }

        Request.Send(requestParams)
            .then(result => {
                if (result.IsSuccessfull) {
                    deferredResult.resolve(result.ResultList);
                } else {
                    deferredResult.reject({ message: result.ErrorMessage });
                }
            }).fail(() => deferredResult.reject({ message: "Error reading records" }));

        return deferredResult.promise();
    }

	static GetDefaultData(tableId: number) {
		return Request.Send({
			proxy: {
				url: '/api/LookupEditorApi/GetDefaultData',
				type: 'GET',
				dataType: 'json'
			},
			params: { tableId: tableId }
		});
	}
} 