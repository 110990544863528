import {URL} from "Core/Common/Url";
import {P} from "Core/Common/Promise";
import {IRequestOptions, Request} from "Core/Common/Request";
import {DatabaseModel} from 'Core/CyberConfigManager/Models/DatabaseModel';
import {Deserialize, GenericDeserialize} from 'libs/cerialize';

export class CyberConfigStore {
    static GetConfig(): P.Promise<Array<DatabaseModel>> {
        let deferredResult = P.defer<Array<DatabaseModel>>();
        let requestParams: IRequestOptions = {
            proxy: {
                url: URL.CYBERCONFIG_MANAGER.READ_CONFIG,
                type: 'GET',
                dataType: 'json'
            }
        };

        Request.Send(requestParams)
            .then(data => deferredResult.resolve(this.Deserialize(data)))
            .fail(() => deferredResult.reject({message: 'Error reading config'}));

        return deferredResult.promise();
    }

    static CreateConfig(model: DatabaseModel) {
        const deferredResult = P.defer<any>();
        const requestParams: IRequestOptions = {
            proxy: {
                url: URL.CYBERCONFIG_MANAGER.CREATE_CONFIG,
                type: 'POST'
            },
            params: model
        };

        Request.Send(requestParams)
            .then(() => deferredResult.resolve(null))
            .fail(() => deferredResult.reject({message: 'Error creating config'}))

        return deferredResult.promise();
    }

    static UpdateConfig(model: DatabaseModel) {
        const deferredResult = P.defer<any>();
        const requestParams: IRequestOptions = {
            proxy: {
                url: URL.CYBERCONFIG_MANAGER.UPDATE_CONFIG,
                type: 'POST'
            },
            params: model
        };

        Request.Send(requestParams)
            .then(() => deferredResult.resolve(null))
            .fail(() => deferredResult.reject({message: 'Error updating config'}));

        return deferredResult.promise();
    }

    static DeleteConfig(id: string): P.Promise<any> {
        let deferredResult = P.defer<any>();
        let requestParams: IRequestOptions = {
            proxy: {
                url: URL.CYBERCONFIG_MANAGER.DELETE_CONFIG,
                type: 'POST',
            },
            params: { Id: id }
        };

        Request.Send(requestParams)
            .then(data => deferredResult.resolve(null))
			.fail(() => deferredResult.reject({message: 'Error deleting config'}));

        return deferredResult.promise();
    }

    static Deserialize(jsonObject: any): any {
        var model = GenericDeserialize(jsonObject, DatabaseModel);
        return model;
    }

}