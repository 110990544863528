import {UserAllowanceViewModel} from "Core/Controls/UserAllowance/Models/UserAllowanceViewModel";

export class UserAllowanceSerializedModel {
	UserId: number;
	UserAllowanceValue: number;

	constructor(userId: number, userAllowanceViewModel: UserAllowanceViewModel) {
		this.UserId = userId;

		let rightsValue = 0;

		userAllowanceViewModel.Flags().forEach(right => rightsValue += right.UserHasFlag ? right.Value : 0);

		this.UserAllowanceValue = rightsValue;
	}
}