'use strict';

import * as ko from "knockout";

import {BaseControl} from "Core/Controls/BaseControl/BaseControl";
import {IControlParam} from "Core/Screens/IScreen";


import HelpViewTemplate from "Core/Controls/ButtonLinkedIn/Templates/HelpView.html";
import ViewTemplate from "Core/Controls/ButtonLinkedIn/Templates/View.html";
import ToolbarTemplate from "Core/Controls/ButtonLinkedIn/Templates/ToolBar.html";
import DesignTemplate from "Core/Controls/ButtonLinkedIn/Templates/Design.html";

ko.templates["Core/Controls/ButtonLinkedIn/Templates/HelpView"] = HelpViewTemplate;
ko.templates["Core/Controls/ButtonLinkedIn/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/ButtonLinkedIn/Templates/ToolBar"] = ToolbarTemplate;
ko.templates["Core/Controls/ButtonLinkedIn/Templates/Design"] = DesignTemplate;

export class ButtonLinkedIn extends BaseControl {

	constructor(params: IControlParam) {
		super(params);
		this.Init();
	}

	ApplyProperties(){}

	private Init(): void {
	}

	Click(): void {
	}

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}
}