import * as ko from 'knockout'
import * as _ from 'underscore'

import {BaseControl, IControlValue} from 'Core/Controls/BaseControl/BaseControl'
import {SubForm} from 'Core/Screens/Forms/SubForm/SubForm'
import {IControlParam} from 'Core/Screens/IScreen'
import {Guid} from 'Core/Common/Guid';
import {Notifier} from "Core/Common/Notifier"
import {RenderModes, PASSWORD, FONT_STYLES} from 'Core/Constant'

import PasswordConfig from 'Core/Controls/Password/Configs/password-config.json'
import {GeneralProperties} from 'Core/GeneralProperties/GeneralProperties'
import {NOTIFICATIONS, LABELS} from "Core/Components/Translation/Locales";

import HelpViewTemplate from 'Core/Controls/Password/Templates/HelpView.html'
import ViewTemplate from 'Core/Controls/Password/Templates/View.html'
import EditTemplate from 'Core/Controls/Password/Templates/Edit.html'
import ToolBarTemplate from 'Core/Controls/Password/Templates/ToolBar.html'
import DesignTemplate from 'Core/Controls/Password/Templates/Design.html'
import {ScreenTypes} from "../../Common/Enums/ScreenTypes";

ko.templates['Core/Controls/Password/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Password/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/Password/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Password/Templates/Edit'] = EditTemplate;
ko.templates['Core/Controls/Password/Templates/Design'] = DesignTemplate;

export const events = {PasswordApplied: "PasswordActionApplied"};

export enum PasswordActions {
    None,
    Revoke
};

export class Password extends BaseControl {
    private _value: KnockoutObservable<string>;
    private _labelStyle: KnockoutObservable<any>;
    private _textInputStyle: KnockoutObservable<any>;
    private _actionsIsVisible: boolean;
    private _action: number;
    private _isHidden: KnockoutObservable<boolean>;
    private _isFirstChange: boolean;
    private _isEyeEnable: KnockoutObservable<boolean>;
    private _isModified: KnockoutObservable<boolean>;

    constructor(params: IControlParam) {
        super(params, PasswordConfig);
        this._value = ko.observable(PASSWORD.DefaultValue);
        this.AddEvent(events.PasswordApplied);
        this._labelStyle = ko.observable(null);
        this._textInputStyle = ko.observable(null);
        this._action = PasswordActions.None;
        this._isHidden = ko.observable(true);
        this._isFirstChange = false;
        this._isEyeEnable = ko.observable(true);
        this._isModified = ko.observable(false);


        this.ApplyProperties();
        this._value.subscribe((value) => {
            this._action = PasswordActions.None;
            if (value && value[value.length - 1] === ' ') {
                this._value(value.trim());
            }
        });
        this.On(events.PasswordApplied, this, eventArgs => this.PasswordActionApplied(eventArgs.data.Action));
    }

    get SelectedAction() {
        return this._action;
    }

    private PasswordActionApplied(passwordAction: PasswordActions) {
        const notificationText = {};
        notificationText[PasswordActions.None] = '';
        notificationText[PasswordActions.Revoke] = NOTIFICATIONS.PASSWORD_REVOKED;

        var target = this.GetForm().GetScreen().GetWrapper();
        new Notifier($(target)).Success(notificationText[passwordAction]);
    }

    ApplyProperties() {
        if (this.Properties) {
            //Label
            if (this.Properties.Label) {
                var labelStyle = {backgroundColor: null, color: null};
                _.each(this.Properties.Label.Properties,
                    (property: any) => {
                        if (property.BackgroundColor) {
                            labelStyle.backgroundColor = property.BackgroundColor;
                        }

                        if (property.Color) {
                            labelStyle.color = property.Color;
                        }
                    });

                this._labelStyle(labelStyle);
            }

            //TextInput
            if (this.Properties.TextInput) {
                var textInputStyle = {backgroundColor: null};
                _.each(this.Properties.TextInput.Properties,
                    (property: any) => {
                        if (property.BackgroundColor) {
                            textInputStyle.backgroundColor = property.BackgroundColor;
                        }
                    });

                this._textInputStyle(textInputStyle);
            }

            //PasswordActions
            if (this.Properties.PasswordActions) {
                _.each(this.Properties.PasswordActions.Properties,
                    (property: any) => {
                        if (property.DisplayActions) {
                            this._actionsIsVisible = property.DisplayActions;
                        }
                    });
            }
        }
        if (this._form && (this._form.GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.LinkEditor])) {
            this.ApplyLinkEditorStyles();
        }
    }

    private ApplyLinkEditorStyles() {
        if (!this.FieldModel.HasLinkEditorVisibility) {
            return;
        }
        const labelStyle = {
            color: null,
            fontWeight: null,
            fontStyle: null,
            textDecoration: null
        };
        if (this.FieldModel.FontColor) {
            labelStyle.color = this.FieldModel.FontColor;
        }
        labelStyle.fontWeight = FONT_STYLES.NORMAL; //default fontWeight

        if (this.FieldModel.FontStyles) {
            _.forEach(this.FieldModel.FontStyles, (style) => {

                switch (style.Name.toLowerCase()) {
                    case FONT_STYLES.BOLD:
                        labelStyle.fontWeight = FONT_STYLES.BOLD;
                        break;
                    case FONT_STYLES.UNDERLINE:
                        labelStyle.textDecoration = FONT_STYLES.UNDERLINE;
                        break;
                    case FONT_STYLES.ITALIC:
                        labelStyle.fontStyle = FONT_STYLES.ITALIC;
                        break;
                }
            })
        }

        this.Extend(labelStyle, this._labelStyle());
        this._labelStyle(labelStyle);
    }

    PasswordFocused() {
        if (this._value() == PASSWORD.DefaultValue) {
            this._value('');
            this._isModified(true);
        }
        this._isEyeEnable(true);
    }

    TryToRefill() {
        if (!this._value()) {
            this._isEyeEnable(false);
            this._isHidden(true);
        }
    }

    PasswordRevoke(): void {
        this._value(null);
        this._action = PasswordActions.Revoke;
        this.Trigger(events.PasswordApplied, {Action: PasswordActions.Revoke});
    }

    SetValue(value: IControlValue): void {
        if (value.RecordSpecsModel && value.RecordSpecsModel.IsNewRecord) {
            this._isModified(true);
            if (value.RecordSpecsModel.IsExample) {
                return;
            }
        }

        if (value.Data) {
            this._value(value.Data.Value);
            this._isEyeEnable(false);
        }
    }

    AfterRender(el: Array<HTMLElement>): void {
        super.AfterRender(el);
    }

    Deserialize() {
        var field = _.first(this._model().Fields);
        if (field) {
            if (!this.GetCombinedReadOnly()) {
                return [`${field.EntityName}.${field.Name}`, this._value()];
            } else {
                return PASSWORD.DefaultValue;
            }
        }
        return null;
    }


    IsValid(): boolean {
        if (this._action === PasswordActions.Revoke) {
            this._isValid(true);
            return this._isValid();
        }

        if (!this._value()) {
            this._isValid(!this._isRequired);
            return this._isValid();
        }

        if (this._isModified() && this._value().length < PASSWORD.MinimumLength) {
            this._isValid(false);
            const passwordValidationMessage = NOTIFICATIONS.INCORECT_PASSWORD_LENGTH.replace('{MinimumLength}', PASSWORD.MinimumLength.toString());
            let wrapper = null;
            if (this.GetForm()) {
                wrapper = $(this.GetForm().GetScreen().GetWrapper());
            }
            new Notifier(wrapper).Warning(passwordValidationMessage);
            return this._isValid();
        }

        this._isValid(true);
        return this._isValid();
    }

    GetValue(): any {
        return this._value();
    }

    IsModified(): boolean {
        return this._isModified();
    }

    ShowOrHidePassword() {
        this._isHidden(!this._isHidden());
    }
}