import { deserialize } from 'libs/cerialize';

export class CompileErrorModel {
	constructor() {}

	@deserialize
	Error: string;

	@deserialize
	MethodName: string;
}