import * as ko from 'knockout';

import {FormatConverter} from "FormatEditor/FormatConverter";
import {FeatureTypes} from "Core/Common/Enums/FeatureTypes";

import {SpimValueModel} from "Core/Controls/Grid/Models/GridDataModel/GridCellValueModel";

import {SpimValueViewModel} from "./SpimValueViewModel";

import NumericTemplate from '../Templates/FeatureTypes/Numeric.html';

export class NumericValueViewModel extends SpimValueViewModel {
    DisplayValue: KnockoutObservable<string>;

    constructor(recordId: number, value: number) {
        super(recordId);

        this.DisplayValue = ko.observable(FormatConverter.ConvertDecimal(value && value.toString()));
    }

    GetTemplate() {
        return NumericTemplate;
    }

    GetValue() {
        const spimValue = new SpimValueModel();
        spimValue.RecordId = this.recordId;

        spimValue.FeatureType = FeatureTypes.Numeric;

        const value = parseFloat(this.DisplayValue() && this.DisplayValue().replace(',', '.'));

        if (!isNaN(value)) {
            spimValue.NumericValue = value;
        }

        return spimValue;
    }
}