import * as ko from 'knockout';
import { BlockUI } from 'Core/Common/BlockUi';

const Updater = (element: HTMLElement, valueAccessor) => {
	var block = ko.unwrap(valueAccessor());
	const isElementBlocked = element.dataset.isBlocked;
	if (block && !isElementBlocked) {
		element.dataset.isBlocked = "true";
		BlockUI.Block({ Target: element });
	} else if (!block && isElementBlocked == "true") {
		element.dataset.isBlocked = "";
		BlockUI.Unblock(element);
	}
}

export class BlockElementExtention {
	static Init() {
		ko.bindingHandlers.blockElement = {
			init: Updater,

			update: Updater
		};
	}
}