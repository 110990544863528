import { GenericButton } from "Core/Controls/GenericButton/GenericButton";
import { Grid } from "Core/Controls/Grid/Grid";
import { IControl } from "Core/Controls/IControl";

export const gridFacade = (control: Grid)=> {

    const getTotalRecords = () => {
        return control.TotalRecords;
    }

    return { getTotalRecords }
}