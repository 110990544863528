import {deserialize, deserializeAs, serialize, serializeAs} from 'libs/cerialize';
import { MsAccessColumnDto } from './MsAccessColumnDto';

export class MsAccessTableDto {

	constructor(){
		this.Columns = [];
	}

	@deserialize
	Name: string;

	@deserializeAs(MsAccessColumnDto)
	Columns: Array<MsAccessColumnDto>;
}