import * as ko from 'knockout';
import {FIELD_TYPES, RenderModes} from 'Core/Constant';
import {Guid} from 'Core/Common/Guid';
import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';

import {BaseControl} from '../BaseControl/BaseControl';
import {IControlParam} from '../../Screens/IScreen';

import ChartConfig from 'Core/Controls/Chart/Configs/chart-config.json';
import {GeneralProperties} from 'Core/GeneralProperties/GeneralProperties';

import {ChartStore} from 'Core/Controls/Chart/Stores/ChartStore';
import {ChartDataModel} from './Models/ChartDataModel';

import {PROPERTIES} from './Constants';
import {ResizeObserver} from 'Core/Common/ResizeObserver';

import ViewTemplate from './Templates/View.html'
import HelpViewTemplate from './Templates/HelpView.html'
import DesignTemplate from './Templates/Design.html'
import ToolBarTemplate from './Templates/ToolBar.html'

import {ChartBuilderFactory} from "../../Components/Controls/Chart/Factory/ChartBuilderFactory";
import {ChartBuilder} from "../../Components/Controls/Chart/Builders/ChartBuilder";
import {IChartBuilderParams} from "../../Components/Controls/Chart/IChartBuilderParams";

ko.templates['Core/Controls/Chart/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Chart/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/Chart/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/Chart/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/Chart/Templates/ToolBar'] = ToolBarTemplate;

const ResizeService = new ResizeObserver();

export class ChartControl extends BaseControl {
	private _wrapperId: string;
	private _store: ChartStore;
	private _chartBuilder: ChartBuilder;

	private _chartType: KnockoutObservable<string>;
	private _chartTitle: KnockoutObservable<string>;
	private _xAxis: KnockoutObservable<number>;
	private _xAxisLabel: KnockoutObservable<string>;
	private _yAxis: KnockoutObservable<number>;
	private _yAxisLabel: KnockoutObservable<string>;
	private _sortBy: KnockoutObservable<string>;
	private _series: KnockoutObservable<number>;
	private _xAxisExists: KnockoutObservable<boolean>;
	private _yAxisExists: KnockoutObservable<boolean>;
	private _seriesExists: KnockoutObservable<boolean>;
	private _chartOptions: KnockoutObservable<ChartDataModel>;
	private _preventResize: boolean = true;

	private _subjectEntityId: number | null = null;
	private _subjectRecordId: number | null = null;

	constructor(params: IControlParam) {
		super(params, ChartConfig);

		this._wrapperId = Guid.NewGuid();
		this._store = new ChartStore();

		this._chartType = ko.observable(null);
		this._chartTitle = ko.observable(null);
		this._xAxis = ko.observable(0);
		this._xAxisLabel = ko.observable(null);
		this._yAxis = ko.observable(0);
		this._yAxisLabel = ko.observable(null);
		this._sortBy = ko.observable(null);
		this._series = ko.observable(0);
		this._chartOptions = ko.observable(null);
		this._seriesExists = ko.observable(null);
		this._yAxisExists = ko.observable(null);
		this._xAxisExists = ko.observable(null);
    }

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);
        this._el = el[0];

        if (this._renderMode() === RenderModes.View || this._renderMode() === RenderModes.Edit) {
	        BlockUI.Block();

	        this.ApplyProperties();
			let chartParams: IChartBuilderParams = {
				ChartType: this._chartType(),
				ChartTitle: this._chartTitle(),
				XAxis: this._xAxis(),
				XAxisLabel: this._xAxisLabel(),
				YAxis: this._yAxis(),
				YAxisLabel: this._yAxisLabel(),
				SortBy: this._sortBy(),
				Series: this._series(),
				WrapperId: this._wrapperId,
				XAxisExists: this._xAxisExists(),
				YAxisExists: this._yAxisExists(),
				SeriesExists: this._seriesExists()
			};

			this._chartBuilder = ChartBuilderFactory.CreateChartBuilder(chartParams);

			if (this.IsConsultScreen && this._form && this._form.GetScreen()) {
				this._subjectEntityId = this._form.GetScreen().GetEntityId();
				this._subjectRecordId = this._form.GetScreen().GetRecordId();
			}

			this._store.GetChartData({
					EntityId: this._model().EntityId,
					SubjectEntityId: this._subjectEntityId,
					SubjectRecordId: this._subjectRecordId,
					XAxisField: this._xAxis(),
					YAxisField: this._yAxis(),
					SeriesField: this._series(),
					SortBy: this._sortBy(),
					XAxisExists: this._xAxisExists(),
					YAxisExists: this._yAxisExists(),
					SeriesExists: this._seriesExists(),
                    Type: this._chartType()
				})
				.then(options => {
					this._chartOptions(options);
					this._preventResize = false;
					this._chartBuilder.RenderChart(options);
				})
				.fail((err) => {
					new Notifier().Failed(err.message);
				})
		        .always(() => BlockUI.Unblock());

			const unbindResize = ResizeService.SubscribeWidth(this.OnResize, this._el); // subscribe on resize
			ko.utils.domNodeDisposal.addDisposeCallback(this._el, () => {
				unbindResize();
			});
        }
    }

	ApplyProperties() {
		if (this.Properties) {
			this.AssignProperty(PROPERTIES.SETTINGS, PROPERTIES.CHART_TYPE, this._chartType);
			this.AssignProperty(PROPERTIES.SETTINGS, PROPERTIES.CHART_TITLE, this._chartTitle);
			this.AssignProperty(PROPERTIES.SETTINGS, PROPERTIES.X_AXIS, this._xAxis);
			this.AssignProperty(PROPERTIES.SETTINGS, PROPERTIES.X_AXIS_LABEL, this._xAxisLabel);
			this.AssignProperty(PROPERTIES.SETTINGS, PROPERTIES.Y_AXIS, this._yAxis);
			this.AssignProperty(PROPERTIES.SETTINGS, PROPERTIES.Y_AXIS_LABEL, this._yAxisLabel);
			this.AssignProperty(PROPERTIES.SETTINGS, PROPERTIES.SORT_BY, this._sortBy);
			this.AssignProperty(PROPERTIES.SETTINGS, PROPERTIES.SERIES, this._series);
		}
	}

	private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
		if (this.Properties[groupedBy]) {
			_.each(this.Properties[groupedBy].Properties,
				(property: any) => {
					if (property.hasOwnProperty(propertyName)) {
						const propertyValue = property[propertyName].hasOwnProperty('Value')
							? property[propertyName].Value
							: property[propertyName];
						propertyHolder(propertyValue);
					}
				});
		}
	}

	OnResize = () => {
		if (this._preventResize) {
			this._preventResize = false;
			return;
		}
		if (this._chartOptions()){
			this._chartBuilder.RenderChart(this._chartOptions());
		}
	};
}