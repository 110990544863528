import * as ko from 'knockout'

import {BaseControl} from 'Core/Controls/BaseControl/BaseControl'
import {SubForm} from 'Core/Screens/Forms/SubForm/SubForm'
import {IControlParam} from 'Core/Screens/IScreen'
import {QueryConditionGroupModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryConditionGroup';
import {GenericDeserialize} from 'libs/cerialize';
import {RenderModes} from 'Core/Constant';
import {GeneralProperties} from "Core/GeneralProperties/GeneralProperties";
import SearchConfig from 'Core/Controls/Search/Configs/text-search-term-config.json';

import ViewTemplate from "Core/Controls/Search/Templates/View.html";
import HelpViewTemplate from "Core/Controls/Search/Templates/HelpView.html";
import DesignTemplate from "Core/Controls/Search/Templates/Design.html";
import ToolBarTemplate from "Core/Controls/Search/Templates/ToolBar.html";
import {MobileChecker} from 'Core/Common/MobileChecker';

ko.templates["Core/Controls/Search/Templates/Design"] = DesignTemplate;
ko.templates["Core/Controls/Search/Templates/Edit"] = ViewTemplate;
ko.templates["Core/Controls/Search/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/Search/Templates/HelpView"] = HelpViewTemplate;
ko.templates["Core/Controls/Search/Templates/ToolBar"] = ToolBarTemplate;

export class Search extends BaseControl {
	private _value: KnockoutObservable<string>;
	private _hasFocus: KnockoutObservable<boolean>;
	private _conditions: QueryConditionGroupModel;
	private _alternativeEntities: number[];

	constructor(params: IControlParam) {
		super(params, SearchConfig);
		this._value = ko.observable('');
		this._hasFocus = ko.observable(false);
		this._conditions = null;
		this.ApplyProperties();
	}

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
		let isMobile = MobileChecker.IsMobile();
		this._hasFocus(!isMobile);
	}
	private ShowSearch() {
		var screen = this._form.GetScreen();
		if (screen) {
			screen.Trigger('SEARCH_RECORD');
		}
	}

	ShowSearchHelp() {
		if (this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
		}
	}

	EnterKey() {
		this.ShowSearch();
	}

	Click() {
		if (this._renderMode() !== RenderModes.Design && this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
			return;
		}

		this.ShowSearch();
	}

	SetSearchTerm(value) {
		this._value(value);
	}

	GetValue(): string {
		return this._value();
	}

	ApplyProperties() {
		if (this.Properties) {
			//Condition
			if (this.Properties.Condition) {
				_.each(this.Properties.Condition.Properties, (property: any) => {
					if (property.QueryCondition) {
						let jsonObj = JSON.parse(property.QueryCondition);
						let conditions = GenericDeserialize<QueryConditionGroupModel>(jsonObj, QueryConditionGroupModel);
						this._conditions = conditions;
					}
				});
			}
			//AlternativeEntities
			if (this.Properties.AlternativeEntities) {
				_.each(this.Properties.AlternativeEntities.Properties, (property: any) => {
					if (property.AlternativeEntities) {
						this._alternativeEntities = property.AlternativeEntities;
					}
				});
			}
		}
	}

	HasConditions(): boolean {
		if (this._conditions) {
			return this._conditions.ConditionGroups.length > 0 || this._conditions.Items.length > 0;
		}
		return false;
	}
}