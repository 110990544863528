import * as _ from 'underscore';

import { URL } from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize, GenericDeserialize} from 'libs/cerialize';
import {TemplateModel} from 'Core/Controls/ButtonTemplate/Models/TemplateModel';
import {Notifier} from 'Core/Common/Notifier';

export interface IMergeAndSaveRequestModel {
	SubjectEntityId: number;
	SubjectRecordId: number;
	RecordId: number;
	ControlId: number;
	DestinationEntityId: number;
	DestinationTypeId: number;
	SaveDestinationTypeId: boolean;
	SaveIntoSubject: boolean;
	MailAddress: string;
	Client: string;
}

export interface IGetTemplatesRequestModel {
	SubjectEntityId: number;
	ObjectEntityId: number;
	ControlId: number;
}

export class ButtonTemplateStore {
	static GetData(params: IGetTemplatesRequestModel): P.Promise<Array<TemplateModel>> {
		var deferredResult = P.defer<Array<TemplateModel>>();

		var requestParams: IRequestOptions = {
			proxy: {
				cacheRequest: true,
				url: URL.CONTROLS.BUTTON_TEMPLATE.GET_TEMPLATES,
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (!data.IsSuccessfull) {
					deferredResult.reject({ message: data.ErrorMessage });
				} else {
					deferredResult.resolve(Deserialize(data.ResultObject, TemplateModel));
				}
			})
			.fail(error => deferredResult.reject(error));

		return deferredResult.promise();
	}

	static MergeAndSave(params: IMergeAndSaveRequestModel): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.BUTTON_TEMPLATE.MERGE_AND_SAVE,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (!data.IsSuccessfull) {
					if (data.IsServerError) {
						deferredResult.reject({ message: data.ErrorMessage });
					} else {
						var notifier = new Notifier(null);
						notifier.Failed(data.ErrorMessage);
						deferredResult.resolve(null);
					}

				} else {
					deferredResult.resolve(data);
				}
			})
			.fail(error => deferredResult.reject(error));

		return deferredResult.promise();
	}
}