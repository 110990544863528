import { ColorPicker } from './../../../Core/GeneralProperties/Managers/ColorPicker/ColorPicker';
import { messages, Notifier } from './../../../Core/Common/Notifier';
import { BlockUI } from "../../../Core/Common/BlockUi";
import { Modal } from "../../../Core/Common/Modal";
import { AssistantApi } from "./AssistantApi";

import Template   from "DatabaseDesigner/Components/Assistant/AssistantTemplate.html";
import { MessageDto } from "./Models/MessageDto";
import { DatabaseModel } from '../../Models/DatabaseDesignerModel';
import { GeneratedTables } from './GeneratedTables';
import {Event} from 'Core/Common/Event';
import { AIDBStructure } from './Models/AIDBStructure';
import { AITableDefinition } from './Models/AITableDefinition';
import { AIFieldDefinition } from './Models/AIFieldDefinition';
import { SortGroups, TABLE_TYPES } from 'Core/Constant';
import { LABELS } from 'Core/Components/Translation/Locales';


ko.templates["DatabaseDesigner/Components/Assistant/AssistantTemplate"] = Template;

export enum AssistantModes{
  Database,
  Help
}

export class Assistant extends Event {
    private _modal: Modal;
    private _messages: KnockoutObservableArray<MessageDto>;
    private _userInput: KnockoutObservable<string>;
    private _chatHistory: KnockoutComputed<string>;
    private _database: any;

    private _labels = LABELS;
    private _mode: AssistantModes;
    private _threadId: string;
    private _isShowApplyButton: boolean;
    private _welcomeText = `Hello, and welcome to Spheeres Assistant – your dedicated helper for all things Spheeres! I’m here to provide quick answers, guidance, and support to help you make the most of your experience with Spheeres.
    If you have questions or need assistance, just reach out – I'm here to help!
    Let’s make Spheeres work for you!`

    constructor({database, mode}: { database?: any, mode: AssistantModes }){
      super();
      this._mode = mode;
      this._database = database;
      this._isShowApplyButton = this._mode === AssistantModes.Database;
      this._userInput = ko.observable('');
      this._messages = ko.observableArray([]);
      
      this._chatHistory = ko.computed(()=>{
        let result = '';
        this._messages().forEach((message)=>{
          let msg = message.Content.replace(/\n/g, '<br>') + '<br>';
          if(message.Role.toLowerCase() == 'user'){
            msg = '<b>'+ '-' + msg+'</b>';
          }
          result += msg;
        });
        return result;
      });      
    }

    async LoadData(){
      BlockUI.Block();
      
      if(this._mode === AssistantModes.Database){
        this.SerializeDbStructure();
        let prompt = await AssistantApi
        .AssistantMessage({ ChatHistory: [], Database: this.SerializeDbStructure() })
        .always(()=>{
          BlockUI.Unblock();
        }).fail((err)=>Notifier.Failed(err.message));  
        this._messages(prompt.Messages);      
      }

      if(this._mode === AssistantModes.Help){
        let welcomeMessage = new MessageDto();
        welcomeMessage.Content = this._welcomeText;
        welcomeMessage.Role = 'assistant';        
        this._messages([welcomeMessage]);
        this._threadId = await AssistantApi
        .CreateThread()
        .always(()=>{
          BlockUI.Unblock();
        }).fail((err)=>Notifier.Failed(err.message));  
      }

    }

    SerializeDbStructure(): AIDBStructure{
      let dbStructure = new AIDBStructure();
      let tables = [...this._database.Tables, ...this._database.LinkTables];

      _.each(tables, (table: any)=>{
        if(table.Name.startsWith('SYS_')){
          return;
        }
     
        let aiTable = this.BuildTable(table);
        aiTable.Type = this._database.Tables.indexOf(table) >= 0 ? TABLE_TYPES.Entity : TABLE_TYPES.Link;

        dbStructure.Tables.push(aiTable);
      })

      return dbStructure;
    }

    BuildTable(table){
      let aiTable = new AITableDefinition();
      aiTable.Name = table.Name;

      _.each(table.Fields, (field: any)=>{ 
        if(!SortGroups.InCustom(field.Sort)){
          return;
        }
        let aiField = new AIFieldDefinition();
        aiField.Name = field.Name;
        aiField.Type = field.TypeName;
        aiTable.Fields.push(aiField);
       });
       return aiTable;
    }

    Show(){

      this._modal = new Modal({
        width: 450,
        minHeight: 600,
        minWidth: 600,
        maxHeight: 300
      }, false);

      ko.cleanNode(this._modal.Wrapper);
      ko.applyBindings(this, this._modal.Wrapper);      
      this._modal.Show();
      this.LoadData();
    }

    GetTemplateName(){
      return 'DatabaseDesigner/Components/Assistant/AssistantTemplate';
    }

    async SendMessage(){
      BlockUI.Block();
      let newMessage = new MessageDto();
      newMessage.Content = this._userInput();
      newMessage.Role = 'user';
      let messages = [...this._messages(), newMessage]
      if(this._mode === AssistantModes.Database){
        let prompt = await AssistantApi.AssistantMessage({ ChatHistory: messages, Database: this.SerializeDbStructure() })
        .always(()=>{
          BlockUI.Unblock();
        }).fail((err)=>Notifier.Failed(err.message));
  
        this._messages(prompt.Messages);
        this._userInput('');
      }

      if(this._mode === AssistantModes.Help){
        let prompt = await AssistantApi.AssistantMessage2({ Message: this._userInput(), threadId: this._threadId })
        .always(()=>{
          BlockUI.Unblock();
        }).fail((err)=>Notifier.Failed(err.message));
  
        this._messages(prompt.Messages);
        this._userInput('');
      }

      BlockUI.Unblock();
    }

    CreateTables(){
      BlockUI.Block();
      AssistantApi.GenerateStructure({ ChatHistory: this._messages(), Database: this.SerializeDbStructure() })
      .always(()=>BlockUI.Unblock())
      .fail((err)=>Notifier.Failed(err.message))
      .then((data)=>{
        this.ShowTablesModal(data);
      });
    }

    ShowTablesModal(json){
      let database = new DatabaseModel();
      database.FromJSON(json);

      let generatedTables = new GeneratedTables(database);
      generatedTables.On('SAVE', this, ()=>{
        this.Trigger('SAVE', database);
        this._modal.Close();
      });
      generatedTables.Show();
    }

    FormatMessage(message: MessageDto){
      return message.Content.replace(/\n/g, '<br>');
    }
}