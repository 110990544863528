import {P} from "Core/Common/Promise";
import {Request} from "Core/Common/Request";

import { NOTIFICATIONS } from "Core/Components/Translation/Locales";

import {ProductResponse} from "./Models/ProductResponse";
import {ListSlice} from '../../../../../../Common/Collections/ListSlice';

export class RootProductsPageStore {
    constructor(private _productsEntityId: number, private _rootProductsViewId?: number) {
    }

    GetRootProducts(sliceNumber: number, itemsInSlice: number): P.Promise<ListSlice<ProductResponse>> {
        const deferred = P.defer<ListSlice<ProductResponse>>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetRootProducts',
                type: 'GET',
                dataType: 'json',
            }, params: {
                EntityId: this._productsEntityId,
                RootViewId: this._rootProductsViewId,
                SliceNumber: sliceNumber,
                ItemsInSlice: itemsInSlice
            }
        })
            .then(productsDto => deferred.resolve(productsDto))
            .fail(err => deferred.reject(this.GetResponseError(err, NOTIFICATIONS.ERROR_GETTING_ROOT_PRODUCTS)));

        return deferred.promise();
    }

    GetNestedProductsOf(productId: number): P.Promise<ListSlice<ProductResponse>> {
        const deferred = P.defer<ListSlice<ProductResponse>>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetNestedProducts',
                type: 'GET',
                dataType: 'json',
            }, params: {EntityId: this._productsEntityId, RecordId: productId}
        })
            .then(productsDto => deferred.resolve(productsDto))
            .fail(err => deferred.reject(this.GetResponseError(err, NOTIFICATIONS.ERROR_GETTING_NESTED_PRODUCTS)));

        return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}