import * as ko from "knockout"
import { IPortletProperties } from "Core/Controls/PortletBar/Interfaces/IPortletBarProperties";

export class DefaultPortletProps implements IPortletProperties {
	ReadOnly: KnockoutObservable<boolean> = ko.observable(true);
	Search: KnockoutObservable<boolean> = ko.observable(true);

	PortletColor: KnockoutObservable<string> = ko.observable("#95a5a6");
	UsePortletBarColor: KnockoutObservable<boolean> = ko.observable(false);
	DataSpaceColor: KnockoutObservable<string> = ko.observable("#fff");
	Kind: KnockoutObservable<string> = ko.observable("light");
	Length: KnockoutObservable<number> = ko.observable(5);

	HeaderText: KnockoutObservable<string> = ko.observable(null);
	CollapseButton: KnockoutObservable<boolean> = ko.observable(true);
	ExpandButton: KnockoutObservable<boolean> = ko.observable(false);
	SettingsButton: KnockoutObservable<boolean> = ko.observable(true);
	ReloadButton: KnockoutObservable<boolean> = ko.observable(false);
	RemoveButton: KnockoutObservable<boolean> = ko.observable(true);

	UseBodySpace: KnockoutObservable<boolean> = ko.observable(true);

	private static _isInitialized: boolean = false;
	private static _props: DefaultPortletProps = null;

	static Props() {
		if (DefaultPortletProps._props === null) {
			DefaultPortletProps._props = new DefaultPortletProps();
		}

		return DefaultPortletProps._props;
	}

	constructor() {
		if (DefaultPortletProps._isInitialized) {
			throw new Error("Props cannot be initialized twice. Use DefaultPortletProps.Props() instead");
		}

		DefaultPortletProps._isInitialized = true;
	}
}