import * as ko from "knockout";
import * as _ from "underscore";

import { P } from "Core/Common/Promise";
import { BlockUI } from "Core/Common/BlockUi";

import { BaseScreen } from "Core/Screens/BaseScreen";
import { ScreenModel } from "Core/Models/Screens/ScreenModel";
import { SubFormModel } from 'Core/Models/Screens/SubFormModel'

import { ControlModel } from 'Core/Controls/BaseControl/Models/ControlModel';

import { AttachedFieldModel } from 'Core/Controls/BaseControl/Models/AttachedFieldModel';

import { RenderModes, FIELD_TYPE_TO_CONTROL } from "Core/Constant";
import { ScreenTypes } from 'Core/Common/Enums/ScreenTypes';

import {ScreenDataModel} from "Core/ScreenManager/Models/ScreenDataModel";
import {ControlDataModel} from "Core/ScreenManager/Models/ControlDataModel";
import {TranslationModel} from "Core/ScreenManager/Models/TranslationModel";

import { NewRecordModel, FieldModel, TranslationValueModel } from "LookupEditor/NewRecord/Models/NewRecordModel";
import { LookupModel, LookupFieldModel } from "LookupEditor/Store/Models/LookupModel";

import { DataModel, FieldDataModel } from 'Core/Screens/Common/LinkEditor/Models/DataModel';

import Template from "LookupEditor/NewRecord/Templates/NewRecord.html";
import { FlagResolver } from "Core/Common/FlagResolver";
import { FieldFlags } from "Core/Common/Enums/FieldFlags";

import { LABELS } from "Core/Components/Translation/Locales";
import { TranslationManager } from "Core/Components/Translation/TranslationManager";
import { LookupEditorStore } from "../Store/LookupEditorStore";
import { DefaultValueModel } from "../Store/Models/DefaultValueModel";
import { ColumnTypes } from "../Enums/ColumnTypes";
import { CONTROL_TYPES } from 'Core/Constant';
import { IconModel } from "Core/Controls/BaseControl/Models/IconModel";

ko.templates["LookupEditor/NewRecord/Templates/NewRecord"] = Template;

let SIMPLE_CONTROLS = [CONTROL_TYPES.Text,
	CONTROL_TYPES.DateTime,
	CONTROL_TYPES.Dropdown,
	CONTROL_TYPES.Memo,
	CONTROL_TYPES.Checkbox,
	CONTROL_TYPES.ColorSelector,
	CONTROL_TYPES.Password,
	CONTROL_TYPES.MultiSelect,
	CONTROL_TYPES.RadioButton];

export class NewRecordScreen extends BaseScreen {

	constructor(screen: ScreenModel) {
		super(screen, RenderModes.Edit);
		this.BindEvents();
	}

	GetTemplateName(): string {
		return "LookupEditor/NewRecord/Templates/NewRecord";
	}

	AfterRender(el): void {
		super.AfterRender(el);

	}

	ValidateData() {
		let result = true;
		_.each(this._controls, control => {
			const isControlValid = control.IsValid();
			if (!isControlValid) {
				result = false;
			}
		});
		return result;
	}

	Save() {
		if (this.ValidateData()) {
			let newRecordModel = this.GetData();
			this.Trigger("SAVE_DATA", newRecordModel);
		}
	}

	static GenerateScreen(model: LookupModel): P.Promise<NewRecordScreen> {
		const deferred = P.defer<NewRecordScreen>();

		BlockUI.Block();

		LookupEditorStore.GetDefaultData(model.Id)
			.then(defaultData => deferred.resolve(NewRecordScreen.InitScreen(model, defaultData)))
			.fail(() => deferred.resolve(NewRecordScreen.InitScreen(model, [])))
			.always(() => BlockUI.Unblock());

		return deferred.promise();
	}

	private static InitScreen(model: LookupModel, defaultData: DefaultValueModel[]) {
		let screenModel = new ScreenModel();
		screenModel.Data = new ScreenDataModel();
		screenModel.Data.RecordId = 0;
		screenModel.EntityIcon = new IconModel();
		screenModel.ScreenTypeName = ScreenTypes[ScreenTypes.LookupEditorNewRecord];
		screenModel.EntityId = model.Id;
		screenModel.EntityName = model.Name;
		screenModel.EntityNameTranslation = model.TranslatedName;

		screenModel.ActionBar = new SubFormModel();

		let subFormModel = new SubFormModel();
		screenModel.SubForms = [subFormModel];

		model.Fields.forEach(field => {
			if (!field.IsPrimaryKey && !FlagResolver.ContainsFlag(FieldFlags.System, field.Flags) && field.Type !== ColumnTypes[ColumnTypes.Binary]) {
				let controlModel = this.GetControlModel(field, model.Name, model.Id);
				subFormModel.Controls.push(controlModel);

				const defaultValueModel = defaultData.find(dv => dv.Id === field.Id);
				this.SetControlData(screenModel, controlModel, field, defaultValueModel && defaultValueModel.DefaultValue);
			}
		});

		screenModel.SubForms[0].Controls[0].Properties = _.extend({},
			screenModel.SubForms[0].Controls[0].Properties, {
				hasFocus: true
			});

		const screen = new NewRecordScreen(screenModel);
		screen.SetData(screenModel.Data);

		return screen;
	}

	private BindEvents() {
		this.On('SAVE', this, () => this.Save());
	}

	private static GetControlModel(fieldModel: LookupFieldModel, tableName: string, tableId: number): ControlModel {
		const attachedField = new AttachedFieldModel();
		attachedField.Id = fieldModel.Id;
		attachedField.Name = fieldModel.Name;
		attachedField.EntityName = tableName;
		attachedField.EntityId = tableId;
		attachedField.ValFieldId = fieldModel.ValFieldId;
		attachedField.ValFieldTypeName = fieldModel.ValFieldTypeName;
		attachedField.ValFieldFormatName = fieldModel.ValFieldFormatName;
		attachedField.ValFieldSize = fieldModel.ValFieldSize;
		attachedField.ValTableId = fieldModel.ValTableId;
		attachedField.IsRequired = FlagResolver.ContainsFlag(FieldFlags.Required, fieldModel.Flags);
		attachedField.FormatName = fieldModel.FormatName;
		attachedField.FieldTypeName = fieldModel.Type;
		attachedField.Size = fieldModel.Size;

		const controlModel = new ControlModel();
		controlModel.Fields.push(attachedField);
		controlModel.Id = fieldModel.Id;
		controlModel.Name = fieldModel.Name;
		controlModel.Label = fieldModel.Name;
		controlModel.LabelPosition = "Left";
		controlModel.TypeName = FIELD_TYPE_TO_CONTROL[fieldModel.Type];
		controlModel.FieldFlag = fieldModel.Flags;
		controlModel.IsRequired = FlagResolver.ContainsFlag(FieldFlags.Required, fieldModel.Flags);
		controlModel.UseFieldName = true;
		controlModel.IsReadOnly = fieldModel.IsVirtual;

		return controlModel;
	}

	private GetData(): NewRecordModel {
		let screenDataModel = this.GetScreenDataModel();

		let newRecordModel = new NewRecordModel();
        newRecordModel.TableId = screenDataModel.EntityId;
		newRecordModel.Fields = screenDataModel.FieldValues.map(fieldValuePair => {
			let field = new FieldModel();

			field.Id = fieldValuePair.FieldId;

			const [fieldName, value, ...translations] = fieldValuePair.FieldValue;

			field.Name = fieldName;

			field.Value = value;

			if (translations) {
				field.Translations = translations.filter(translation => !!translation).map(translation => {
					const translationSplit = translation.split('_');
					const [languageShortName, value] = translationSplit;
					return new TranslationValueModel(languageShortName, value);
				});
            }

			return field;
		});

		return newRecordModel;
	}

	private GetScreenDataModel(): DataModel {
		var dataModel = new DataModel();
		dataModel.EntityId = this.GetEntityId();
		this._controls.forEach(control => {
			let controlType = control.GetType();
            
			if (SIMPLE_CONTROLS.indexOf(controlType) >= 0) {
                const data = control.Deserialize();
                if (data) {
					var fieldDataModel = new FieldDataModel();
					fieldDataModel.FieldId = control.GetControlId();
					fieldDataModel.FieldValue = data;
					dataModel.FieldValues.push(fieldDataModel);
				}
			}
			if (controlType === CONTROL_TYPES.Image) {
				const controlAttachments = control.Deserialize();
				if (controlAttachments) {
					var fieldDataModel = new FieldDataModel();
					const value = controlAttachments['attachments'];
					fieldDataModel.FieldId = control.GetControlId();
					if (value && value.length > 0) {
						fieldDataModel.FieldValue = [controlAttachments['FieldName'], value[0].Data.Base64Data + '|' + value[0].Data.FileName];
					}
					dataModel.FieldValues.push(fieldDataModel);
				}
            }
		});

		return dataModel;
	}

	private static SetControlData(screenModel: ScreenModel, controlModel: ControlModel, field: LookupFieldModel, defaultValue: string) {
		const data = new ControlDataModel();
		data.ControlId = controlModel.Id;
		data.FieldName = field.Name;
		data.FieldId = field.Id;
		data.Value = defaultValue;
		data.DisplayValue = defaultValue;

		const fieldIsTranslatable = FlagResolver.ContainsFlag(FieldFlags.Translate, field.Flags);

		if (fieldIsTranslatable) {
			const languages = TranslationManager.Instance.Languages;

			data.Translations = languages
				.filter(language => !language.IsDefault)
				.map(language => {
					const translation = new TranslationModel();
					translation.LanguageId = language.Id;
					return translation;
				});
		}

		const controlIndex = screenModel.SubForms[0].Controls.indexOf(controlModel);
		screenModel.Data.ControlsData[controlIndex] = data;
	}
}