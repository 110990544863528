export const EVENTS = {
	SORT: 'SORT',
	ROW_SELECTION_CHANGED: 'ROW_SELECTION_CHANGED',
	OPEN_HYPERLINK: 'OPEN_HYPERLINK',
	DATA_SAVED: 'DATA_SAVED',
	DATA_REMOVED: 'DATA_REMOVED',
	NO_CHANGES: 'NO_CHANGES',
	EDIT_RECORD: 'EDIT_RECORD',
	EDIT_LINK: 'EDIT_LINK',
	CHANGE_VISIBLE_GROUP: 'CHANGE_VISIBLE_GROUP',
	EDIT_CLUSTERED_LINK: 'EDIT_CLUSTERED_LINK',
	SAVE_RECORD: 'SAVE_RECORD',
	SAVE_LIFESTATUS: 'SAVE_LIFESTATUS',
	DELETE_RECORD: 'DELETE_RECORD',
	UNLINK_RECORD: 'UNLINK_RECORD',
	CLICK_UNLINK_CHECKBOX: 'CLICK_UNLINK_CHECKBOX',
	CANCEL_EDIT	: 'CANCEL_EDIT',
	CHANGE_LIFESTATUS: 'CHANGE_LIFESTATUS',
	EXPAND: 'EXPAND',
	COLLAPSE: 'COLLAPSE',
	ADD_TO_BASKET: 'ADD_TO_BASKET',
	REMOVE_FROM_BASKET: 'REMOVE_FROM_BASKET',
	REFRESH: 'REFRESH',
	RECORD_DELETED: 'RECORD_DELETED',
	RECORD_EDITED: 'RECORD_EDITED',
	RECORD_SAVED: 'RECORD_SAVED',
	UNDO_EVERY_RECORD: 'UNDO_EVERY_RECORD',
	UPDATE_GRID: 'UPDATE_GRID',
	FAST_FILTER: 'FAST_FILTER',
	LINK_NEXT_RELATION: 'LINK_NEXT_RELATION',
	MOVE_ROW_UP: 'MOVE_ROW_UP',
	MOVE_ROW_DOWN: 'MOVE_ROW_DOWN',
	HOVER_SORT: 'HOVER_SORT',
	UN_HOVER_SORT: 'UN_HOVER_SORT',
	DROPDOWN_VALUE_SELECTED: 'DROPDOWN_VALUE_SELECTED',
	BACK_LINKING_POPUP_REQUESTED: 'BACK_LINKING_POPUP_REQUESTED',
	SELECT_RECORD: 'SELECT_RECORD',
	UPDATE_SET_ENABLE_ADD_BUTTON: 'UPDATE_SET_ENABLE_ADD_BUTTON',
	SAVE_CHANGES: "SAVE_CHANGES",
	SHOW_ORIGINAL_IMAGE: "SHOW_ORIGINAL_IMAGE",
	LOAD_SUB_GRID: "LOAD_SUB_GRID",
	PRINT_LABEL: "PRINT_LABEL",

	CREATE_OR_DELETE_SORT_DESCRIPTION: 'CREATE_OR_DELETE_SORT_DESCRIPTION'
};