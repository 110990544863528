import { ModelState } from "Core/Common/Enums/ModelState";
import { Guid } from "Core/Common/Guid";
import { BaseModel } from "DatabaseDesigner/Models/BaseModel";
import { CollectionModel } from "DatabaseDesigner/Models/CollectionModel";

export function RelationTypeFiltersModel() {
    this._typeId = null;
    this._kseq = null;
    this._leftTypeId = null;
    this._rightTypeId = null;
    this._leftTypeGuid = Guid.NewGuid();
    this._rightTypeGuid = Guid.NewGuid();
    this._state = 0;

    BaseModel.call(this);
}

RelationTypeFiltersModel.prototype = Object.create(BaseModel.prototype);
RelationTypeFiltersModel.prototype.constructor = RelationTypeFiltersModel;

RelationTypeFiltersModel.prototype.FromJSONArray = function (filters) {
    var filtersCollection = new CollectionModel({ model: RelationTypeFiltersModel });

    _.each(filters, function (filters) {
        var model = new RelationTypeFiltersModel();
        model.FromJSON(filters);
        filtersCollection.Add(model);
    });

    return filtersCollection;
};

RelationTypeFiltersModel.prototype.FromJSON = function (data) {
    this._typeId = data.TypeId;
    this._kseq = data.KSeq;
    this._leftTypeId = data.LeftTypeId;
    this._rightTypeId = data.RightTypeId;
};

RelationTypeFiltersModel.prototype.toJSON = function () {
    return {
        TypeId: this._typeId,
        KSeq: this._kseq,
        LeftTypeId: this._leftTypeId,
        RightTypeId: this._rightTypeId,
        LeftTypeGuid: this._leftTypeGuid,
        RightTypeGuid: this._rightTypeGuid,
        State: this._state
    };
};

RelationTypeFiltersModel.prototype.Reverse = function () {
    var newLeftTypeId = this._rightTypeId;
    var newRightTypeId = this._leftTypeId;
    var newLeftTypeGuid = this._rightTypeGuid;
    var newRightTypeGuid = this._leftTypeGuid;

    this._leftTypeId = newLeftTypeId;
    this._rightTypeId = newRightTypeId;
    this._leftTypeGuid = newLeftTypeGuid;
    this._rightTypeGuid = newRightTypeGuid;

    if (this._state !== ModelState.New || this._state !== ModelState.Deleted) {
        this._state = ModelState.Changed;
    }
};

Object.defineProperty(RelationTypeFiltersModel.prototype, 'TypeId', {
    enumerable: true,
    get: function () {
        return this._typeId;
    },
    set: function (val) {
        this._typeId = val;
    }
});

Object.defineProperty(RelationTypeFiltersModel.prototype, 'KSeq', {
    enumerable: true,
    get: function () {
        return this._kseq;
    },
    set: function (val) {
        this._kseq = val;
    }
});

Object.defineProperty(RelationTypeFiltersModel.prototype, 'LeftTypeId', {
    enumerable: true,
    get: function () {
        return this._leftTypeId;
    },
    set: function (val) {
        this._leftTypeId = val;
    }
});

Object.defineProperty(RelationTypeFiltersModel.prototype, 'RightTypeId', {
    enumerable: true,
    get: function () {
        return this._rightTypeId;
    },
    set: function (val) {
        this._rightTypeId = val;
    }
});

Object.defineProperty(RelationTypeFiltersModel.prototype, 'LeftTypeGuid', {
    enumerable: true,
    get: function () {
        return this._leftTypeGuid;
    },
    set: function (val) {
        this._leftTypeGuid = val;
    }
});

Object.defineProperty(RelationTypeFiltersModel.prototype, 'RightTypeGuid', {
    enumerable: true,
    get: function () {
        return this._rightTypeGuid;
    },
    set: function (val) {
        this._rightTypeGuid = val;
    }
});

Object.defineProperty(RelationTypeFiltersModel.prototype, 'State', {
    enumerable: true,
    get: function () {
        return this._state;
    },
    set: function (val) {
        this._state = val;
    }
});