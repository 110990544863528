import {deserialize, deserializeAs} from 'libs/cerialize';

export class PropertyTable {
    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    TranslatedName: string;
}

export class PropertyMode {
    @deserialize
    Id: number;

    @deserialize
    Name: string;
}

export class PropertyFieldConfigModel {
    @deserializeAs(PropertyTable)
    PropertyTable: PropertyTable;

    @deserializeAs(PropertyMode)
    PropertyMode: PropertyMode;
}