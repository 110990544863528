import { dia as Dia, shapes } from 'libs/rappid/build/rappid';

shapes.basic.Image.define('cyberThing.DecoratedRect',
	{
		type: 'cyberThing.DecoratedRect',
		size: { width: 53, height: 42 },
		attrs: {
			image: {
				width: 53,
				height: 42,
				'xlink:href': './assets/image-icon1.svg'
			},
			text: {
				text: '',
				'font-family': 'Roboto Condensed',
				'font-weight': 'Normal',
				'font-size': 9,
				display: '',
				stroke: '#000',
				'stroke-width': 0,
				'fill': '#222138'
			}
		}
	}
);