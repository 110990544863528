import {P} from "Core/Common/Promise";
import {Request, IRequestOptions} from "Core/Common/Request";

export class RecordSharingStore {
	static GetRecordSharingScreen(securityProfileId: number): P.Promise<any> {
		let deferredResult = P.defer<any>();

		Request.Send({
				proxy: {
					url: '/api/RecordSharingApi/GetRecordSharingScreen',
					dataType: 'json',
					type: 'GET'
				},
				params: { id: securityProfileId }
		}).then(result => deferredResult.resolve(result));

		return deferredResult.promise();
	}
}