import {Request, IRequestOptions} from "Core/Common/Request";
import {P} from "Core/Common/Promise";
import {IGetCanvasRequestModel} from 'Core/Controls/CanvasDesigner/Stores/CanvasDesignerStore';

export class UserMenuStore {
	static GetMetadata(): P.Promise<any> {
		let deferredResult = P.defer<any>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/MenuApi/GetUserMenuMetadata',
				type: 'GET',
				dataType: 'json'
			}
		};

		Request.Send(requestParams)
			.then(data => deferredResult.resolve(data));

		return deferredResult.promise();
	}

	static GetCanvas(params: IGetCanvasRequestModel): P.Promise<any> {
		let deferredResult = P.defer<any>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/CanvasApi/GetCanvas',
				type: 'POST',
				dataType: 'json'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => deferredResult.resolve(data));

		return deferredResult.promise();
	}

	static SaveCanvas(canvas:any): P.Promise<any> {
		let deferredResult = P.defer<any>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/CanvasApi/Save',
				type: 'POST',
				dataType: 'json'
			}, params: canvas
		};

		Request.Send(requestParams)
			.then(data => deferredResult.resolve(data));

		return deferredResult.promise();
	}
}