import * as ko from "knockout";

import {Guid} from "Core/Common/Guid"
import {IButtonConfig} from "Core/Controls/PortletBar/Interfaces/IButtonConfig"

export abstract class ButtonViewModel {
	Guid: Guid;
	Name: string;
	Properties: KnockoutObservable<IButtonConfig>;

	constructor(buttonName: string, buttonConfig: IButtonConfig) {
		this.Guid = Guid.NewGuid();
		this.Name = buttonName;
		this.Properties = ko.observable(buttonConfig);
	}
}