import * as ko from "knockout"
import 'jquery';

export class SearchExtention {
	static Init() {

		ko.bindingHandlers.searchExtention = {
			init: (element, valueAccessor, allBindings, viewModel) => {
				var options = valueAccessor();
				var typingTimer;
				var doneTypingInterval = 500;
				var $input = $(element);
				var backSpace = 8;
				var shiftCode = 16;
				var regExp = null;

				if (options.regexp) {
					regExp = options.regexp;
				}

				//on keyup, start the countdown
				$input.on('keyup', function (evt) {
					clearTimeout(typingTimer);
					var inp = String.fromCharCode(evt.keyCode);

					//need to move part with "regexp" somewhere
					if (regExp) {
						if (regExp.test(inp) || evt.keyCode == backSpace || evt.keyCode == shiftCode) {
							typingTimer = setTimeout(doneTyping, doneTypingInterval);
						}
					} else {
						typingTimer = setTimeout(doneTyping, doneTypingInterval);
					}
				});

				//on keydown, clear the countdown 
				$input.on('keydown', function (evt) {
					clearTimeout(typingTimer);
				});

				//user is "finished typing," do something
				function doneTyping() {
					if (options.search) {
						options.search();
					}
				}
			}
		};
	}
}