import * as ko from 'knockout';
import * as _  from 'underscore';

import {ControlToolBarModel} from 'Core/Controls/FormDesigner/ToolBar/ControlToolBar/Models/ControlToolBarModel';
import {ControlGroup} from 'Core/Controls/FormDesigner/ToolBar/ControlToolBar/ControlGroup';
import {ControlGroupModel} from "Core/Controls/FormDesigner/ToolBar/ControlToolBar/Models/ControlGroupModel";

import ControlToolBarTemplate from "Core/Controls/FormDesigner/ToolBar/ControlToolBar/Templates/ControlToolBar.html";
ko.templates["Core/Controls/FormDesigner/ToolBar/ControlToolBar/Templates/ControlToolBar"] = ControlToolBarTemplate;

export class ControlToolBar {
	private _groups: KnockoutObservableArray<ControlGroup>;
	private _currentControlGroup: ControlGroup;
	private _expanded: KnockoutObservable<boolean>;
	private _el: HTMLElement;

	constructor(groups: Array<ControlGroupModel>) {
		this._groups = ko.observableArray(groups.map(groupModel => new ControlGroup(groupModel)));
		this._expanded = ko.observable(false);
		this._expanded.subscribe(newValue => {
			if (newValue) {
				$(this._el).fadeIn(400);
			} else {
				if (this._currentControlGroup) {
					this._currentControlGroup.IsExpaned = false;
				}
			}
		});
	}

	set Expanded(isExpanded: boolean) {
		this._expanded(isExpanded);
	}

	get Expanded(): boolean {
		return this._expanded();
	}

	get Groups(): KnockoutObservableArray<ControlGroup> {
		return this._groups;
	}

	GetTemplateName() {
		return "Core/Controls/FormDesigner/ToolBar/ControlToolBar/Templates/ControlToolBar";
	}


	ControlGroupClick(controlGroup) {
		if (this._currentControlGroup != controlGroup) {
			if (this._currentControlGroup) {
				this._currentControlGroup.IsExpaned = false;
			}
		}
		this._currentControlGroup = controlGroup;
		this._currentControlGroup.IsExpaned = !this._currentControlGroup.IsExpaned;
	}
	AfterRender(el: Array<HTMLElement>): void {
		this._el = el[0];
	}
}