import {P} from "Core/Common/Promise";
import {Request} from 'Core/Common/Request';

import {IGetOptionsDto} from "../Models/Dto/IGetOptionsDto";
import {LookupOptionsDto} from "../Models/Dto/LookupOptionsDto";
import {ISaveSelectionDto} from "../Models/Dto/ISaveSelectionDto";

export class Store {
    static GetLookupOptions(dto: IGetOptionsDto): P.Promise<LookupOptionsDto> {
        return Request.Send({
            proxy: {
                url: '/api/BackLinkApi/GetLookupOptions',
                type: 'POST',
                dataType: 'json'
            },
            params: dto
        });
    }

    static SaveSelection(dto: ISaveSelectionDto): P.Promise<any> {
        return Request.Send({
            proxy: {
                url: '/api/BackLinkApi/SetLookupValue',
                type: 'POST'
            },
            params: dto
        });
    }
}