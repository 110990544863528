import {FIELD_TYPES} from "Core/Constant";
import {DATE_FORMATS} from 'Core/Constants/DateTimeFormats';

import {ProductCustomFieldDescription} from "../ProductCustomFieldDescription";

import {CustomFieldControl} from "../Controls/CustomFieldControl";
import {CustomIntegerControl} from "../Controls/Integer/CustomIntegerControl";
import {CustomDecimalControl} from "../Controls/Decimal/CustomDecimalControl";
import {CustomDateTimeControl} from "../Controls/DateTime/CustomDateTimeControl";
import {CustomCheckboxControl} from "../Controls/Checkbox/CustomCheckboxControl";
import {CustomLookupControl} from "../Controls/Lookup/CustomLookupControl";
import {CustomTextControl} from "../Controls/Text/CustomTextControl";
import {ProductPart} from "../../ProductPart";

export class ProductCustomFieldControlFactory {
	static CreateCustomControl(customField: ProductCustomFieldDescription, productPart: ProductPart): CustomFieldControl {
		switch (customField.Type) {
			case FIELD_TYPES.Integer:
				return new CustomIntegerControl(customField, productPart);

			case FIELD_TYPES.Decimal:
				return new CustomDecimalControl(customField, productPart);

			case FIELD_TYPES.DateTime:
				return new CustomDateTimeControl(customField, productPart, FIELD_TYPES.DateTime, DATE_FORMATS.DATE_TIME.Format);

			case FIELD_TYPES.Date:
				return new CustomDateTimeControl(customField, productPart, FIELD_TYPES.Date, DATE_FORMATS.SHORT_DATE.Format);

			case FIELD_TYPES.YesNo:
				return new CustomCheckboxControl(customField, productPart);

			case FIELD_TYPES.Lookup:
				return new CustomLookupControl(customField, productPart);

			default:
				return new CustomTextControl(customField, productPart);
        }
    }
}