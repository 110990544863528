import moment from "moment";
import {AdministrationStore} from "../AdministrationStore";
import {BlockUI} from "Core/Common/BlockUi";

import {LABELS} from "Core/Components/Translation/Locales";

import {EnvironmentDto} from "./EnvironmentDto";

import {FormatConverter} from 'FormatEditor/FormatConverter';
import {DATE_FORMATS} from "Core/Constants/DateTimeFormats";
import {ConfirmationDialog, EVENTS, Types} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

import Template from "Pages/AdministrationPage/Tabs/Environment.html";
import * as ko from "knockout";
import * as exports from "webpack";
import mergeRuntimeOwned = exports.util.runtime.mergeRuntimeOwned;

ko.templates["Pages/AdministrationPage/Tabs/Environment"] = Template;

export class Environment {
    private _environments: KnockoutObservableArray<EnvironmentDto>;
    private _environmentsWithoutChanges: KnockoutObservableArray<EnvironmentDto>;
    private _searchText: KnockoutObservable<string>;

    private _labels = LABELS;

    constructor(){
        this._environments = ko.observableArray([]);
        this._environmentsWithoutChanges = ko.observableArray([]);
        this._searchText = ko.observable('');

        this.LoadData();
    }

    GetTemplateName(){
        return 'Pages/AdministrationPage/Tabs/Environment';
    }

    LoadData(){
        BlockUI.Block();
        AdministrationStore.GetEnvironments().always(()=>{
            BlockUI.Unblock();
        }).then((data)=>{
            this._environments(data);
            this._environmentsWithoutChanges(data.slice());
        });
    }

    AfterRender(){

    }

    FormatDate(value: string){
        if(!value){
            return value;
        }
        return moment(FormatConverter.CorrectTimezone(value)).format(DATE_FORMATS.SAVE_FORMAT_FULL.MomentFormat);
    }

    ParseDate(dateString: string): Date | null {
        if (dateString === null) {
            return null;
        }

        const date = new Date(dateString);

        if (isNaN(date.getTime())) {
            return null;
        }

        return date;
    }

    MarkForDeletion(environmentDto: EnvironmentDto){
        const confirmationDialog = new ConfirmationDialog({
            Text: 'Are you sure? The system will be deleted in 3 days',
            Type: Types.Question
        });

        confirmationDialog.On(EVENTS.CONFIRM_SELECTED, this, () => {
            BlockUI.Block();
            AdministrationStore.MarkForDeletion(environmentDto.Id)
            .always(()=>BlockUI.Unblock())
            .then(()=>this.LoadData());
        });

        confirmationDialog.Show();
    }

    Delete(environmentDto: EnvironmentDto){
        BlockUI.Block();
        AdministrationStore.Delete(environmentDto.Id)
            .always(()=>BlockUI.Unblock())
            .then(()=>this._environments.remove(environmentDto));
    }

    UnmarkForDeletion(environmentDto: EnvironmentDto){
        BlockUI.Block();
        AdministrationStore.UnmarkForDeletion(environmentDto.Id)
        .always(()=>BlockUI.Unblock())
        .then(()=>this.LoadData());
    }

    Search() {
        let result = [];
        const searchValue = this._searchText().toLowerCase().trim();

        _.each(this._environmentsWithoutChanges(), row => {
            if (row.Url.toLowerCase().includes(searchValue) || row.DatabaseName.toLowerCase().includes(searchValue)) {
                result.push(row);
            }
        });

        this._environments(result);
    }

    Sort(column, data, event) {
        const target = $(event.target).closest('th');

        if (target.hasClass("sort-asc")) {
            target.removeClass("sort-asc").addClass("sort-desc");
            this._environments.sort((a, b) => {
                let aValue = a[column];
                let bValue = b[column];

                if (column === "Size") {
                    aValue = aValue ? parseFloat(aValue) : null;
                    bValue = bValue ? parseFloat(bValue) : null;

                    if (aValue && bValue) {
                        return bValue - aValue;
                    } else if (aValue === null && bValue !== null) {
                        return 1;
                    } else if (aValue !== null && bValue === null) {
                        return -1;
                    } else {
                        return 0;
                    }
                }

                if (column === "LastLoginDate") {
                    const aDate = this.ParseDate(aValue);
                    const bDate = this.ParseDate(bValue);

                    if (aDate && bDate) {
                        return bDate.getTime() - aDate.getTime();
                    } else if (aDate === null && bDate !== null) {
                        return 1;
                    } else if (aDate !== null && bDate === null) {
                        return -1;
                    } else {
                        return 0;
                    }
                }

                if (aValue && bValue) {
                    return bValue.localeCompare(aValue);
                } else if (aValue === null && bValue !== null) {
                    return 1;
                } else if (aValue !== null && bValue === null) {
                    return -1;
                } else {
                    return 0;
                }
            });
        } else if (target.hasClass("sort-desc")) {
            target.removeClass("sort-desc").addClass("sort-both");
            this._environments(this._environmentsWithoutChanges().slice());
        } else {
            target.removeClass("sort-both").addClass("sort-asc");
            this._environments.sort((a, b) => {
                let aValue = a[column];
                let bValue = b[column];

                if (column === "Size") {
                    aValue = aValue ? parseFloat(aValue) : null;
                    bValue = bValue ? parseFloat(bValue) : null;

                    if (aValue && bValue) {
                        return aValue - bValue;
                    } else if (aValue === null && bValue !== null) {
                        return 1;
                    } else if (aValue !== null && bValue === null) {
                        return -1;
                    } else {
                        return 0;
                    }
                }

                if (column === "LastLoginDate") {
                    const aDate = this.ParseDate(aValue);
                    const bDate = this.ParseDate(bValue);

                    if (aDate && bDate) {
                        return aDate.getTime() - bDate.getTime();
                    } else if (aDate === null && bDate !== null) {
                        return 1;
                    } else if (aDate !== null && bDate === null) {
                        return -1;
                    } else {
                        return 0;
                    }
                }

                if (aValue && bValue) {
                    return aValue.localeCompare(bValue);
                } else if (aValue === null && bValue !== null) {
                    return 1;
                } else if (aValue !== null && bValue === null) {
                    return -1;
                } else {
                    return 0;
                }
            });
        }

        $("th").not(target).removeClass("sort-asc sort-desc").addClass("sort-both");

    }
}