import {serialize, serializeAs} from 'libs/cerialize';

export class FollowUpActionSubjectModel {
	RecordId: number;
    LifestatusId: number;
    ConfirmationPassword: string;
}

export class FollowUpRecordRequestModel {
	RecordId: number;
    EntityId: number;
    RetireChildren: boolean;
    ActionSubject: FollowUpActionSubjectModel;
    ParentActionSubject: FollowUpActionSubjectModel;
}