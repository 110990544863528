export class InvoicingResultsViewModel {
	constructor(public Results: InvoicingResultViewModel[]) {

	}
}

export class InvoicingResultViewModel {
	constructor(
		public RecordId: number,
		public RecordName: string,
		public Result: string,
		public IsSuccessfull: boolean) {

	}
}