define(['FormatEditor/FormatConverter', 'jquery' ,'touchSpin'], function (formatConverterModule, $) {
    var FormatConverter = formatConverterModule.FormatConverter;
    (function ($) {
        'use strict';

        var TouchSpinDecimalExtension = function (options) {
            this.init('touchSpinDecimal', options, TouchSpinDecimalExtension.defaults);
        };

        $.fn.editableutils.inherit(TouchSpinDecimalExtension, $.fn.editabletypes.abstractinput);

        $.extend(TouchSpinDecimalExtension.prototype, {

            render: function () {
                var decimals = this.$input.editableutils.getConfigData($(this.options.scope)).decimals;

                this.$input = this.$tpl;
                this.$input.TouchSpin({
                    min: -Infinity,
                    max: Infinity,
                    step: 1 / Math.pow(10, decimals),
                    decimals: decimals,
                    callback_before_calculation: function (value) {
                        return value
                            .replace(new RegExp('[' + FormatConverter.GetGroupSeparator() + ']', 'g'), '')
                            .replace(new RegExp('[' + FormatConverter.GetSeparator() + ']', 'g'), '.');
                    }
                });
                this.$input.inputmask('patchedDecimal', {
                    radixPoint: FormatConverter.GetSeparator(),
                    digits: decimals,
                    integerDigits: 38 - decimals,
                    autoGroup: true,
                    groupSeparator: FormatConverter.GetGroupSeparator(),
                    autoUnmask: true,
                    rightAlign: false
                });
            },
            value2html: function (value, element) {

                if (!value) {
                    $(element).empty();
                    return;
                }

                var textValue = '';

                if (value.current != undefined && value.current != null) {
                    textValue = value.current;
                }

                if (value.default != undefined && value.default != null) {
                    textValue = value.default;
                }

                $(element).html(FormatConverter.LocalizeDecimalOrInteger(textValue.toString()));
            },
            value2input: function (value) {
                if (!value) {
                    return;
                }

                if (value.current != undefined && value.current != null) {
                    this.$input.val(value.current);
                    return;
                }

                if (value.default != undefined && value.default != null) {
                    this.$input.val(value.default);
                    return;
                }
                this.$input.val(value);
            },
            input2value: function () {
                return {
                    current: this.$input.val()
                }
            },
            activate: function () {
                this.$input.focus();
            }
        });

        TouchSpinDecimalExtension.defaults = $.extend({}, $.fn.editabletypes.abstractinput.defaults, {
            tpl: '<input type="text"/>'
        });

        $.fn.editabletypes.touchSpinDecimal = TouchSpinDecimalExtension;
    }($));
});