import { AfterDropValidation } from "Core/Screens/DesignScreen/AfterDropValidation/AfterDropValidation";
import { PortletAfterDropValidation } from "Core/Screens/DesignScreen/AfterDropValidation/PortletAfterDropValidation";
import { ScreenTypes } from "Core/Common/Enums/ScreenTypes";

export class ScreenTypeToDropControlValidation {
	static GetValidationDelegate(screenType: string): AfterDropValidation {
		switch (screenType) {
			case ScreenTypes[ScreenTypes.Portlet]:
				return new PortletAfterDropValidation();
			default:
				return new AfterDropValidation();
		}
	}
}