import { deserialize } from 'libs/cerialize';

export class SecurityDataModel {
	@deserialize
	ProfileId: number;

	@deserialize
	ProfileName: string;

	@deserialize
	UserAllowance: number;

	constructor(partial?: Partial<SecurityDataModel>) {
		if (partial) {
			this.ProfileId = partial.ProfileId;
			this.ProfileName = partial.ProfileName;
			this.UserAllowance = partial.UserAllowance;
		}
	}
}