import {deserialize, deserializeAs} from 'libs/cerialize';

import {IconModel} from "Core/Controls/BaseControl/Models/IconModel";
import { Icon } from 'Core/Icon/Icon';

export interface IActiveTimerModal {
    Id: number;
    Duration: KnockoutObservable<number>;
    DurationFormatted: string;
    MaxDuration: KnockoutComputed<number>;
    KnobColor: KnockoutComputed<string>;
    Overtime: KnockoutObservable<boolean>;
    Tooltip: KnockoutObservable<string>;
    SubjectName: string;
    Icon: Icon;
    EntityId: number;
    RecordId: number;
}

export interface IActiveTimer {
    Id: number;
    SubjectName: string;
    EntityId: number;
    RecordId: number;
}

class Subject {
    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    TranslatedName: string;

    @deserializeAs(IconModel)
    Icon: IconModel;
}

export class TimerModel {
    @deserialize
    Id: number;

    @deserialize
    Starting: string;

    @deserialize
    Overtime: boolean;

    @deserializeAs(Subject)
    Subject: Subject;

    @deserialize
    EntityId: number;

    @deserialize
    RecordId: number;
}
