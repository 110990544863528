import * as ko from 'knockout';
import {BlockUI} from 'Core/Common/BlockUi';

import {HelpBookStore} from "HelpBook/Store/HelpBookStore";
import {Notifier} from 'Core/Common/Notifier';
import {HelpDescription} from "HelpBook/HelpDescription/HelpDescription";

import {
    Control,
    SubFormControl
} from 'HelpBook/Store/Models/HelpBookModel';

import HelpFormTemplate from 'HelpBook/HelpForm/Templates/HelpForm.html';
import {LABELS} from "Core/Components/Translation/Locales";
import {AttachedFieldModel} from "../../Core/Controls/BaseControl/Models/AttachedFieldModel";
import {ControlModel} from "../../Core/Controls/BaseControl/Models/ControlModel";
import {TranslationModel} from 'Core/Controls/BaseControl/Models/TranslationModel';
import {LanguageModel} from 'Core/Controls/BaseControl/Models/LanguageModel';
import {TranslationManager} from "Core/Components/Translation/TranslationManager";
import {RenderModes} from "../../Core/Constant";
import {IControlParam} from "../../Core/Screens/IScreen";
import {IStaticControlsPosition, StaticControlsProvider} from '../../Core/Controls/StaticControlsProvider';

ko.templates['HelpBook/HelpForm/Templates/HelpForm'] = HelpFormTemplate;

export class HelpForm {
    private controls: KnockoutObservableArray<any>;
    private selectedScreenSubForms: KnockoutObservableArray<SubFormControl>;
    private selectedScreenHasData: KnockoutObservable<boolean>;
    private directTargetedScreen: KnockoutObservable<boolean>;
    private selectedScreenName: KnockoutObservable<string>;
    private selectedScreenDescription: KnockoutObservable<string>;
    private HelpDescription: KnockoutObservable<HelpDescription>;

    public selectedEntityId: number;
    private isActive: KnockoutObservable<boolean>;

    constructor() {
        this.controls = ko.observableArray([]);
        this.selectedScreenSubForms = ko.observableArray([]);
        this.selectedScreenHasData = ko.observable(false);
        this.directTargetedScreen = ko.observable(false);
        this.selectedScreenName = ko.observable('');
        this.selectedScreenDescription = ko.observable('');
        this.HelpDescription = ko.observable(null);
        this.isActive = ko.observable(false);
    }


	FetchData(screen, skipBlock: boolean = false) {
		return new Promise((res, rej) => {
			if (!skipBlock) {
				BlockUI.Block();
			}

			this.directTargetedScreen(screen.directScreen);

			HelpBookStore.GetScreenInfo({id: screen.Id}).then(async result => {
				if (!skipBlock) {
					BlockUI.Unblock();
				}

				if (!result.IsSuccessfull) {
					new Notifier().Failed(result.ErrorMessage);
					rej();
					return;
				}
				this.selectedScreenHasData(result.HasData);
				if (result.HasData) {
					const subForms = result.ResultObject.SubForms.map((form) => {
						if (form.Name === '') {
							form.Name = 'SubForm';
						}
						return _.extend({}, form, {
								isActive: ko.observable(true),
								isBar: ko.observable(form.Name.includes('ActionBar') || form.Name.includes('Bottom')),
								HelpDescription: new HelpDescription(form.Description),
								Click: (control) => {
									control.HelpDescription.ActivateDescription();
									control.isActive(!control.isActive());
								},
								GetTranslation: this.GetControlTranslation(form),
								ControlComponents: []
							});
					});

					const staticControlsPosition = StaticControlsProvider.ForScreen(result.ResultObject.ScreenTypeName, false, false);
					await this.RegisterStaticControls(subForms, staticControlsPosition);

					for(const form of subForms){
						await this.IterateOverNestedControls(form);
					};

					this.selectedScreenSubForms(subForms);
					screen.Description = ko.observable(result.ResultObject.Description);

					this.selectedScreenName(result.ResultObject.Name);
					this.selectedEntityId = result.ResultObject.EntityId;
					this.selectedScreenDescription(result.ResultObject.Description);
					this.HelpDescription(new HelpDescription(result.ResultObject.Description));
				}
				this.HelpDescription().ActivateDescription();
				this.isActive(true);
				(screen as any).dataFetched = true;
				res(null);
			}).fail(() => {
				BlockUI.Unblock();
			});
		});
	}

    SelectControl(control) {
        control.isActive(!control.isActive());
    }

    MapToControlModel(control: Control) {
		const attachedFields = control.AttachedFields.map(field => {
			let attachedFieldModel = new AttachedFieldModel({ Id: control.Id, Name: field.Name });
            attachedFieldModel.FieldNameTranslation = field.TranslatedName;
            attachedFieldModel.EntityIcon = field.EntityIcon;
            attachedFieldModel.FieldIcon = field.FieldIcon;
			return attachedFieldModel;
		});

		const currentLanguage = TranslationManager.Instance.GetCurrentLanguage();
		const currentLanguageModel = new LanguageModel(currentLanguage.Id);

		const translationModel = new TranslationModel();
		translationModel.Language = currentLanguageModel;
		translationModel.Selected = true;
		translationModel.Translation = control.TranslatedName;

        let model = new ControlModel();
        model.Fields = attachedFields;
        model.Name = control.Name;
        model.TypeName = control.TypeName;
		model.TranslatedName = control.TranslatedName;
		model.NameTranslations = [translationModel];
		model.UseFieldName = control.UseFieldName;
		model.IsFieldAttached = control.IsFieldAttached;
		model.IsLookupFieldAttached = control.IsLookupFieldAttached;
        model.IsMultiSelectFieldAttached = control.IsMultiSelectFieldAttached;
        model.LabelPosition = control.LabelPosition;
        model.Icon = control.Icon;

        if (control.Properties) {
            try {
                model.Properties = JSON.parse(control.Properties);
            } catch {
            }
        }

        model.SubControls = control.Controls.filter(control => this.CanBeRendered(control)).map(control => this.MapToControlModel(control));
        return model;
    }

    CanBeRendered(control: Control) {
        return control.TypeName.startsWith("Button")
            || control.TypeName === 'Search'
            || control.TypeName === 'DateTime'
            || control.TypeName === 'Text'
            || control.TypeName === 'Checkbox'
            || control.TypeName === 'Dropdown'
            || control.TypeName === 'Memo'
            || control.TypeName === 'Image'
            || control.TypeName === 'Drop'
            || control.TypeName === 'MultiSelect'
            || control.TypeName === 'GenericButton'
            || control.TypeName === 'Document'
            || control.TypeName === 'SelectUser'
            || control.TypeName === 'ColorSelector'
            || control.TypeName === 'Password'
            || control.TypeName === 'RadioButton'
            || control.TypeName === 'Tag'
            || control.TypeName === 'Alias'
            || control.TypeName === 'Label'
            || control.TypeName === 'Group'
            || control.TypeName === 'Currency'
            || control.TypeName === 'Grid'
            || control.TypeName === 'Tab'
            || control.TypeName === 'TabPage'
            || control.TypeName === 'ProductConfigurator'
            || control.TypeName === 'SPIMCopy'
            || control.TypeName === 'History'
			|| control.TypeName === 'Book'
			|| control.TypeName === 'BulkEmail'
            || control.TypeName === 'QueryResult'
            || control.TypeName === 'Invoicing'
            || control.TypeName === 'Timer'
            || control.TypeName === 'Signature'
            || control.TypeName === 'Spreadsheet'
    }


    async IterateOverNestedControls(item: SubFormControl) {
        let controls = [];
        for(const control of item.Controls){
            await this.IterateOverNestedControls(control);
            if (this.CanBeRendered(control)) {
                const controlComponent = await this.CreateControlComponent(this.MapToControlModel(control));

                if (item.ControlComponents && controlComponent) {
                    item.ControlComponents.push(controlComponent);
                }
            }

            controls.push(_.extend({}, control, {
				isActive: ko.observable(false),
				HelpDescription: new HelpDescription(control.Description),
				labels: LABELS,
				Click: (control) => {
					control.HelpDescription.ActivateDescription();
					control.isActive(!control.isActive());
				},
				GetTranslation: this.GetControlTranslation(control)
            }));

        };

        item.Controls = controls;
    }

	GetControlTranslation(control: Control): string {
		const controlName = control.Name;
		let controlTranslation = null;

		if (control.UseFieldName &&
			(control.IsFieldAttached || control.IsLookupFieldAttached || control.IsMultiSelectFieldAttached)) {
			const fieldAttached = _.first(control.AttachedFields);
			if (fieldAttached) {
				controlTranslation = fieldAttached.TranslatedName;
			}
		} else {
			controlTranslation = control.TranslatedName;
		}

		return controlTranslation || controlName;
	}

    GetTemplateName(): string {
        return 'HelpBook/HelpForm/Templates/HelpForm';
    }

    private async RegisterStaticControls(subForms, staticControlsPosition: IStaticControlsPosition) {
        const subFormsNumber = subForms.length;

        const actionBar = subForms[0];
        const mainBlocks = subForms.slice(1, subFormsNumber - 2);
        const bottomBar = subForms[subFormsNumber - 1];

        for(const controlModel of staticControlsPosition.ActionBar){
            const controlComponent = await this.CreateControlComponent(controlModel);
            actionBar.ControlComponents.push(controlComponent);
        };

        for(const subForm of staticControlsPosition.SubForms){
            for (const controlModel of subForm){
                const controlComponent = await this.CreateControlComponent(controlModel);
                mainBlocks[staticControlsPosition.SubForms.indexOf(subForm)].ControlComponents.push(controlComponent);
            };
        };

        for (const controlModel of staticControlsPosition.BottomBar) {
            const controlComponent = await this.CreateControlComponent(controlModel);
            bottomBar.ControlComponents.push(controlComponent);
        };
    }

    private async CreateControlComponent(controlModel: ControlModel) {
        const renderMode = RenderModes.HelpView;

        var controlParam: IControlParam = {
            Model: controlModel,
            RenderMode: renderMode,
            Form: null
        };

        let controlFactory = (await import("Core/Controls/ControlFactory")).ControlFactory;

        return controlFactory.CreateControl(controlParam);
    }
}