import {Event} from 'Core/Common/Event';

import {deserializeAs, serializeAs, Serialize} from 'libs/cerialize';

import {MainThemeDescriptor} from './MainThemeDescriptor';
import {CustomThemeDescriptor} from './CustomThemeDescriptor';
import {CustomThemeSetting} from './CustomThemeSetting';

export const EVENTS = {
    SETTINGS_CHANGED: 'SETTINGS_CHANGED'
};

export const DEFAULT_VALUES = {
    COLOR: 'darkblue',
    THEME: 'darkblue.css',
    SIDEBAR_BORDERS: 'noborders',
    SIDEBAR_STYLE_OPTION: 'default',
    STYLE: 'square',
    SIDEBAR_POS_OPTION: 'left',
    SIDEBAR_OPTION: 'fixed'
};

export class UISettings extends Event {

    @serializeAs(MainThemeDescriptor)
    @deserializeAs(MainThemeDescriptor)
    Settings: MainThemeDescriptor;

    @serializeAs(CustomThemeDescriptor)
    @deserializeAs(CustomThemeDescriptor)
    CustomThemes: CustomThemeDescriptor[];

    constructor() {
        super();
        this.Settings = new MainThemeDescriptor();
        this.CustomThemes = [];
    }

    GetCustomTheme(name: string): CustomThemeDescriptor {
        return this.CustomThemes.find(theme => theme.Name === name);
    }

    SetCustomTheme(name: string, settings: CustomThemeSetting[]) {
        let customTheme = this.GetCustomTheme(name);

        if (!customTheme) {
            this.RegisterCustomTheme(name, settings);
            this.Settings.CustomThemeName = name;
            this.Trigger(EVENTS.SETTINGS_CHANGED);
            return;
        }

        customTheme.Settings = settings;
        this.Settings.CustomThemeName = name;
        this.Trigger(EVENTS.SETTINGS_CHANGED);
    }

    SetThemeColor(theme: string, color: string) {
        this.Settings.Theme = theme;
        this.Settings.Color = color;
        this.Trigger(EVENTS.SETTINGS_CHANGED);
    }

    SetThemeStyle(style: string) {
        this.Settings.Style = style;
        this.Trigger(EVENTS.SETTINGS_CHANGED);
    }

    SetSidebarOption(sidebarOption: string) {
        this.Settings.SidebarOption = sidebarOption;
        this.Trigger(EVENTS.SETTINGS_CHANGED);
    }

    SetSidebarStyleOption(sidebarStyleOption: string) {
        this.Settings.SidebarStyleOption = sidebarStyleOption;
        this.Trigger(EVENTS.SETTINGS_CHANGED);
    }

    SetSidebarPosOption(sidebarPosOption: string) {
        this.Settings.SidebarPosOption = sidebarPosOption;
        this.Trigger(EVENTS.SETTINGS_CHANGED);
    }

    SetSidebarBorders(borders: string) {
        this.Settings.SidebarBorders = borders;
        this.Trigger(EVENTS.SETTINGS_CHANGED);
    }

    Serialize() {
        return Serialize(this);
    }

    ToJSON() {
        return JSON.stringify(this.Serialize());
    }

    private RegisterCustomTheme(name: string, settings: CustomThemeSetting[]) {
        const customTheme = CustomThemeDescriptor.Create(name, settings);
        this.CustomThemes.push(customTheme);

        return customTheme;
    }

    Equals(settings: UISettings) {
        return _.isEqual(Serialize(this), Serialize(settings));
    }
}