import * as ko from 'knockout'
import * as _ from 'underscore';

import {BaseControl, IControlValue} from 'Core/Controls/BaseControl/BaseControl'
import {IControlParam} from 'Core/Screens/IScreen'
import {
	ConfirmationDialog,
	EVENTS as ConfirmationDialogEvents,
	Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import { RenderModes } from "Core/Constant";
import { GeneralProperties } from "Core/GeneralProperties/GeneralProperties";

import SecurityResetButtonConfig from "Core/Controls/ButtonSecurityReset/Configs/security-reset-button-config.json";

import HelpViewTemplate from "Core/Controls/ButtonSecurityReset/Templates/HelpView.html"
import ViewTemplate from "Core/Controls/ButtonSecurityReset/Templates/View.html"
import DesignTemplate from "Core/Controls/ButtonSecurityReset/Templates/Design.html"
import ToolbarTemplate from "Core/Controls/ButtonSecurityReset/Templates/ToolBar.html"

ko.templates["Core/Controls/ButtonSecurityReset/Templates/ToolBar"] = ToolbarTemplate;
ko.templates["Core/Controls/ButtonSecurityReset/Templates/HelpView"] = HelpViewTemplate;
ko.templates["Core/Controls/ButtonSecurityReset/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/ButtonSecurityReset/Templates/Edit"] = ViewTemplate;
ko.templates["Core/Controls/ButtonSecurityReset/Templates/Design"] = DesignTemplate;

export class ButtonSecurityReset extends BaseControl {
    private _config: any;

	constructor(params: IControlParam) {
		super(params, SecurityResetButtonConfig);

        this._style = ko.computed(() => {
            return {
                backgroundColor: this._backgroundColor(),
                color: this._color(),
                borderColor: this._borderColor(),
                borderWidth: this._border() ? '1px' : '0',
                padding: this._border() ? '6px 14px' : '7px 14px'
            };
        });
		this.Init();
	}

	private Init(): void {
        this.ApplyProperties();
	}

	Click(): void {
		if (this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
		} else {
			var screen = this._form.GetScreen();
			if (screen) {
				let confirmationDialog = new ConfirmationDialog({
					Text: "Reset security?",
					Type: ConfirmationTypes.Question,
					TextConfirm: 'Yes'
				});
				confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED,
					this,
					eventArgs => {
						screen.Trigger('SECURITY_RESET');
					});
				confirmationDialog.Show();
			}
		}
	}

	SetValue(value: IControlValue): void {
		if (value.Data) {
			this._enabled(true);
		}
	}

    ApplyProperties() {
        if (this.Properties) {
            this.AssignProperty('Styling', 'BackgroundColor', this._backgroundColor);
            this.AssignProperty('Styling', 'TextColor', this._color);
            this.AssignProperty('Styling', 'Border', this._border);
            this.AssignProperty('Styling', 'BorderColor', this._borderColor);
        }
    }

    private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
        if (this.Properties[groupedBy]) {
            _.each(this.Properties[groupedBy].Properties,
                (property: any) => {
                    if (property.hasOwnProperty(propertyName)) {
                        propertyHolder(property[propertyName]);
                    }
                });
        }
    }

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}
}