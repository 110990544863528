import { deserialize } from 'libs/cerialize';

export class TemplateLanguageModel {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	HasTemplate: boolean;

	@deserialize
	IsDefault: boolean;
}