import { GenericButton } from "Core/Controls/GenericButton/GenericButton";
import { BaseGrid } from "Core/Controls/Grid/BaseGrid/BaseGrid";
import { Grid } from "Core/Controls/Grid/Grid";
import { IControl } from "Core/Controls/IControl";
import { ILinkEditor } from "Core/Screens/Common/LinkEditor/LinkEditor";
import { IForm } from "Core/Screens/IScreen";
import { linkEditorFacade } from "./LinkEditorFacade";

export const linkEditorTabGridFacade = (grid: BaseGrid, screen: ILinkEditor)=> {
    
    const getTotalRecords = () => {
        return grid.TotalRows;
    }

    const getScreen = ()=> {
        return linkEditorFacade(screen);
    }

    return { getTotalRecords, getScreen }
}