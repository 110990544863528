import * as ko from 'knockout';
import * as _ from "underscore";
import * as moment from 'moment';

import { Modal } from 'Core/Common/Modal';
import { Icon } from 'Core/Icon/Icon';

import { TimerStore } from 'Core/Controls/Timer/Stores/TimerStore';
import {Event} from 'Core/Common/Event';
import {BlockUI} from "Core/Common/BlockUi";

import { Notifier } from 'Core/Common/Notifier';
import { DATE_FORMATS } from 'Core/Constants/DateTimeFormats';
import {CONFIRMATIONS, LABELS} from 'Core/Components/Translation/Locales';

import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_EVENTS,
    Types
} from "../../../Components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import {PUB_SUB_EVENTS} from "../../../../MenuManager/PubSubEvents";
import {IActiveTimer, IActiveTimerModal, TimerModel} from "Core/Controls/Timer/Models/TimerModel";
import {StopTimerResponseModel} from 'Core/Controls/Timer/Models/StopTimerResponseModel';

import ActiveTimersModalTemplate from 'Core/Controls/Timer/ActiveTimersModal/Templates/ActiveTimersModal.html';
ko.templates['Core/Controls/Timer/ActiveTimersModal/Templates/ActiveTimersModal'] = ActiveTimersModalTemplate;

export class ActiveTimersModal extends Event{
    private _modal: Modal;
    private _labels = LABELS;
    private _activeTimersModal: KnockoutObservableArray<IActiveTimerModal>;
    private _el: HTMLElement;
    private _notifier: Notifier;

    constructor() {
        super();

        this._notifier = new Notifier();
        this._el = null;
        this._modal = new Modal(
            {
                width: 480,
                closeOnEsc: true,
                addClass: 'active-timers-modal-container jBox-padding-10px',
                blockScroll: true
            },
            false
        );
        this._activeTimersModal = ko.observableArray([]);
    }

    Show() {
        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
        this._modal.Show();
    }

    Close() {
        if (this._modal){
            this._modal.Close();
        }
    }

    OnOvertimeClick(timer: IActiveTimerModal) {
        const newOvertime = !timer.Overtime();
        TimerStore.SetOvertime({ Id: timer.Id, Overtime: newOvertime })
            .then(() => timer.Overtime(newOvertime))
            .fail(error => this._notifier.Failed(error.message));
    }

    StopTimer(timer: IActiveTimerModal){
        const dialog = new ConfirmationDialog({
            Text: CONFIRMATIONS.DO_YOU_WANT_TO_STOP_RECORD_NAME.replace(
                '{recordName}',
                `<b>${timer.SubjectName}</b>`
            ),
            Type: Types.Question
        });

        dialog.On(CONFIRMATION_EVENTS.CONFIRM_SELECTED, this, () =>{
            const activeTimersWrapper = $(this._el).find('.active-timers')[0];

            BlockUI.Block({Target: activeTimersWrapper});
            TimerStore.StopTimer({EntityId: timer.EntityId, RecordId: timer.RecordId })
                .always(() => {
                    BlockUI.Unblock(activeTimersWrapper);
                })
                .then((responseModel: StopTimerResponseModel) => {
                    _.each(responseModel.WarningMessages, message => this._notifier.Failed(message));

                    this._activeTimersModal.splice(this._activeTimersModal.indexOf(timer), 1);

                    const stopTimers: Array<IActiveTimer> = [this.GetActiveTimerFromActiveTimerModal(timer)];
                    this.Trigger('STOP_TIMER', stopTimers);

                    if (!this._activeTimersModal().length){
                        this.Close();
                    }
                })
                .fail(error => this._notifier.Failed(error.message));
        });

        dialog.Show();
    }

    GoToRecordScreen(timer: IActiveTimerModal, event){
        PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, {
            EntityId: timer.EntityId,
            RecordId: timer.RecordId,
            IsOpenInModal: event.ctrlKey
        });

        if (!event.ctrlKey){
            this.Close();
        }
    }

    GetTemplateName() {
        return 'Core/Controls/Timer/ActiveTimersModal/Templates/ActiveTimersModal';
    }

    GetActiveTimerFromActiveTimerModal(activeTimerModal: IActiveTimerModal | TimerModel): IActiveTimer {
        const timer = activeTimerModal as IActiveTimerModal | TimerModel;
        return {
            EntityId: timer.EntityId,
            RecordId: timer.RecordId,
            Id: timer.Id,
            SubjectName: timer instanceof TimerModel ? timer.Subject.TranslatedName || timer.Subject.Name : timer.SubjectName
        }
    }

    AfterRender(el: HTMLElement) {
        if (el){
            this._el = el[0];
        }

        const activeTimerWrapper = $(this._el).find('.active-timers')[0];
        BlockUI.Block({Target: activeTimerWrapper});
        TimerStore.GetActiveTimers()
            .always(()=>{BlockUI.Unblock(activeTimerWrapper);})
            .then((timers: TimerModel[]) => {
                this._activeTimersModal(
                    timers.map((timer: TimerModel ) => {
                        const duration = moment().diff(moment(`${timer.Starting}Z`));
                        const overtimeObservable = ko.observable(timer.Overtime);
                        const maxDurationComputed = ko.pureComputed(() => (overtimeObservable() ? 17 : 9) * 60 * 60 * 1000);

                        const timersMenu: Array<IActiveTimer> = [this.GetActiveTimerFromActiveTimerModal(timer)];
                        PubSub.publish('START_ACTIVE_TIMER', timersMenu);

                        return {
                            EntityId: timer.EntityId,
                            RecordId: timer.RecordId,
                            Id: timer.Id,
                            Duration: ko.observable(duration),
                            DurationFormatted: moment.utc(duration).format(DATE_FORMATS.LONG_TIME.Format),
                            MaxDuration: maxDurationComputed,
                            KnobColor: ko.pureComputed(() =>
                                duration > maxDurationComputed() ? '#e7505a' : overtimeObservable() ? '#5c9bd1' : '#28a745'
                            ),
                            Overtime: overtimeObservable,
                            Tooltip: ko.pureComputed(() =>
                                LABELS.SET_THE_MAXIMUM_DURATION_TO_HOURS.replace(
                                    '{hours}',
                                    `${overtimeObservable() ? 9 : 17}`
                                )
                            ),
                            SubjectName: timer.Subject.TranslatedName || timer.Subject.Name,
                            Icon: new Icon(timer.Subject.Icon)
                        };
                    })
                );
                this._modal.Reposition();
            });
    }
}
