import * as joint from 'libs/rappid/build/rappid';
import * as _ from 'underscore';

import {Notifier} from "Core/Common/Notifier";

import {CANVAS_TYPES} from 'Core/Controls/CanvasDesigner/Constants/CanvasTypes';
import {
    CanvasItemModel,
    CanvasLinkModel,
    CanvasModel,
    CanvasIconModel,
    ShapeModel,
} from 'Core/Controls/CanvasDesigner/Models/CanvasModel';
import {dia as Dia, shapes, dia} from 'libs/rappid/build/rappid';
import {SourceViewModel} from 'Core/Controls/CanvasDesigner/Models/SourceViewModel';
import {DestinationViewModel} from 'Core/Controls/CanvasDesigner/Models/DestinationViewModel';
import {TableViewModel} from 'Core/Controls/CanvasDesigner/Models/TableViewModel';
import {ParamViewModel} from 'Core/Controls/CanvasDesigner/Models/ParamViewModel';
import {JSFunctionViewModel} from 'Core/Controls/CanvasDesigner/Models/JSFunctionViewModel';
import {TriggerViewModel} from 'Core/Controls/CanvasDesigner/Models/TriggerViewModel';
import {CSharpFunctionViewModel} from 'Core/Controls/CanvasDesigner/Models/CSharpFunctionViewModel';
import {ArrowViewModel} from 'Core/Controls/CanvasDesigner/Models/ArrowViewModel';
import {APIMethodViewModel} from 'Core/Controls/CanvasDesigner/Models/APIMethodViewModel';
import {GroupViewModel} from 'Core/Controls/CanvasDesigner/Models/GroupViewModel';

import {GridLayout} from 'Core/Controls/CanvasDesigner/GridLayout';
import {SIDES} from "../Constants/Sides";
import {P} from 'Core/Common/Promise';
import {EVENTS} from "Core/Controls/CanvasDesigner/Shapes/Views/ParamView/Events";
import {CanvasModelState} from "../Enums/CanvasModelState";
import {Event} from "../../../Common/Event";
import {QueryBuilder} from "../../../../QueryBuilder/QueryBuilder";
import {EVENTS as QUERY_BUILDER_EVENTS} from "../../../../QueryBuilder/Events";
import {Modal} from "../../../Common/Modal";
import * as ko from "knockout";
import {GenericDeserialize} from "../../../../libs/cerialize";
import {QueryExpressionModel} from "../../Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel";
import {CanvasDesignerStore} from "../Stores/CanvasDesignerStore";
import {BlockUI} from "../../../Common/BlockUi";

export class ShapeFactory extends Event {
    private _graph: joint.dia.Graph;
    private _paper: joint.dia.Paper;
    private _paperScroller: joint.ui.PaperScroller;
    private _root: CanvasItemModel;

    constructor(graph: joint.dia.Graph, paper: joint.dia.Paper, paperScroller: joint.ui.PaperScroller) {
        super();
        this._graph = graph;
        this._paper = paper;
        this._paperScroller = paperScroller;
        this.AddEvent(EVENTS.BACK_TO_DATA_SELECTOR);
        this.AddEvent(EVENTS.SHOW_FUNCTION_DESIGNER_REQUESTED);
    }

    public InitRoot(model: CanvasModel){
        this._root = _.find(model.CanvasItems, (item)=> item.TypeName === CANVAS_TYPES.DW_PACKAGE);
    }

    get Root(): CanvasItemModel{
        return this._root;
    }

    public BuildShapes(model: CanvasModel): P.Promise<any> {
        const deferredResult = P.defer<any>();
        let sourceShape: Dia.Element = null;
        let destinationShape: Dia.Element = null;
        let cells = [];
        let embeds = [];
        let arrows = [];
        let renderElements = [];
        let groups = [];

        _.each(model.CanvasItems, (item) => {
            if (item.State === CanvasModelState.Deleted) {
                return;
            }

            if (item.TypeName === CANVAS_TYPES.PARAM) {
                let parent = this.GetParentByTypes(model, item, [CANVAS_TYPES.TABLE, CANVAS_TYPES.JS_FUNCTION, CANVAS_TYPES.API_METHOD, CANVAS_TYPES.CSHARP_FUNCTION, CANVAS_TYPES.TRIGGER]);
                if (parent) {
                    let isCollapsed = false;
                    try {
                        let properties = JSON.parse(parent.Properties);
                        if (properties.hasOwnProperty('Rappid') && properties.Rappid.hasOwnProperty('isCollapsed')) {
                            isCollapsed = properties.Rappid.isCollapsed;
                        }
                    } catch (e) {
                    }
                    if (!isCollapsed) {
                        renderElements.push(item)
                    }
                } else {
                    renderElements.push(item)
                }
            } else {
                renderElements.push(item);
            }
        });

        let dataSelectors = _.filter(this._graph.getCells(), (item) => {
            return item instanceof joint.shapes.cyberThing.DataSelector;
        });

        cells = cells.concat(dataSelectors);

        this._graph.removeCells(dataSelectors);

        this._paper.on('render:done', () => {
            this._graph.addCells(arrows);

            if (deferredResult.status === P.Status.Unfulfilled) {

                if (sourceShape) {
                    let position = sourceShape.get('position');
                    this.BuildLayout(sourceShape);
                    sourceShape.position(position.x, position.y, {deep: true});
                }

                if (destinationShape) {
                    let position = destinationShape.get('position');
                    this.BuildLayout(destinationShape);
                    destinationShape.position(position.x, position.y, {deep: true});
                }

                _.each(groups, (group) => {
                    let position = group.get('position');
                    this.BuildLayout(group);
                    group.position(position.x, position.y, {deep: true});
                    group.on('change:position', (element) => {
                        let position = element.get('position');
                        let canvasItem1Id = element.get('recordId');
                        let canvasItem1Guid = element.get('guid');

                        let canvasItem = _.find(model.CanvasItems, (item) => {
                            return item.Id == canvasItem1Id && item.Guid === canvasItem1Guid;
                        });

                        if (canvasItem) {
                            canvasItem.X = position.x;
                            canvasItem.Y = position.y;
                        }
                        if (canvasItem.State != CanvasModelState.New && canvasItem.State != CanvasModelState.Deleted) {
                            canvasItem.State = CanvasModelState.Changed;
                        }
                    });
                });

                _.each(cells, (cell: any) => {
                    cell.attr('./visibility', 'visible');
                });

                deferredResult.resolve(null);
                this._paper.off('render:done');
                this._paperScroller.scroll(0, 0);
            }
        });

        renderElements = _.sortBy(renderElements, (item) => {
            return item.Sort
        });

        let params = _.filter(renderElements, (item) => {
            return item.TypeName === CANVAS_TYPES.PARAM
        });
        let renderParams = [];

        _.each(params, (item) => {

            let parent = this.GetParentByType(model, item, CANVAS_TYPES.GROUP)
            if (parent) {
                renderParams.push(item);
            }

            if (item.SideName === SIDES.IN) {
                let parent = this.GetParentByType(model, item, CANVAS_TYPES.SOURCE)
                if (!parent) {
                    renderParams.push(item);
                }
            }

            if (item.SideName === SIDES.OUT) {
                let parent = this.GetParentByType(model, item, CANVAS_TYPES.DESTINATION)
                if (!parent) {
                    renderParams.push(item);
                }
            }
        });

        _.each(renderParams, (param) => {
            if (param.TypeName === CANVAS_TYPES.PARAM) {
                let paramViewModel = new shapes.cyberThing.Param(new ParamViewModel(param));
                cells.push(paramViewModel);

                paramViewModel.on(EVENTS.DEFAULT_VALUE_CHANGED, (value: string) => {

                    let canvasItem1Id = paramViewModel.get('recordId');
                    let canvasItem1Guid = paramViewModel.get('guid');

                    let canvasItem = _.find(model.CanvasItems, (item) => {
                        return item.Id == canvasItem1Id && item.Guid === canvasItem1Guid;
                    });

                    if (canvasItem) {
                        canvasItem.DefaultValue = value;
                    }

                    if (canvasItem.State != CanvasModelState.New && canvasItem.State != CanvasModelState.Deleted) {
                        canvasItem.State = CanvasModelState.Changed;
                    }
                });
            }
        });

        _.each(renderElements, (item) => {
            let view = this.GetViewModel(item);
            if (view) {
                cells.push(view);

                if (view instanceof shapes.cyberThing.Source) {
                    sourceShape = view;

                    view.on(EVENTS.BACK_TO_DATA_SELECTOR, () => {

                        let canvasItem1Id = view.get('recordId');
                        let canvasItem1Guid = view.get('guid');

                        let canvasItem = _.find(model.CanvasItems, (item) => {
                            return item.Id == canvasItem1Id && item.Guid === canvasItem1Guid;
                        });

                        this.DeleteItem(model, canvasItem, view.getEmbeddedCells());

                        this.Trigger(EVENTS.BACK_TO_DATA_SELECTOR, {Type: CANVAS_TYPES.SOURCE});

                        this._graph.removeCells([view as joint.dia.Cell]);

                    });

                    view.on(EVENTS.EDIT_QUERY, (query: JSON) => {
                        let queryBuilder = new QueryBuilder(true, false, false, true);
                        queryBuilder.EditFreeQuery(GenericDeserialize<QueryExpressionModel>(query, QueryExpressionModel), false);

                        queryBuilder.On(QUERY_BUILDER_EVENTS.CLOSE, this, () => {
                            modal.Close();
                        });
                        queryBuilder.On(QUERY_BUILDER_EVENTS.DATA_SAVED, this, (eventArgs: any) => {
                            BlockUI.Block();
                            this.GetByQuery(eventArgs.data.QueryText, CANVAS_TYPES.SOURCE)
                                .then((result) => {
                                    let canvasItem1Guid = view.get('guid');
                                    let dataSourceModel = _.find(model.CanvasItems, (item) => {
                                        return item.Guid === canvasItem1Guid;
                                    });

                                    let dataSourceToTableLinks = _.filter(model.CanvasLinks, (link) => link.CanvasItem1Guid === dataSourceModel.Guid);

                                    let paramToParamLinks = [];
                                    _.each(dataSourceToTableLinks, (dataSourceToTableLink) => {
                                        let tableModel = _.find(model.CanvasItems, (param) => param.Guid === dataSourceToTableLink.CanvasItem2Guid);

                                        let tableToParamLinks = _.filter(model.CanvasLinks, (link) => {
                                            let child = _.find(model.CanvasItems, (item) => item.Guid === link.CanvasItem2Guid && item.TypeName === CANVAS_TYPES.PARAM);
                                            return link.CanvasItem1Guid === tableModel.Guid && !!child;
                                        });

                                        _.each(tableToParamLinks, (tableToParamLink) => {

                                            let links = _.filter(model.CanvasLinks, (link) => {
                                                let child = _.find(model.CanvasItems, (item) => item.Guid === link.CanvasItem2Guid && item.TypeName === CANVAS_TYPES.PARAM);
                                                return link.CanvasItem1Guid === tableToParamLink.CanvasItem2Guid && !!child;
                                            });
                                            paramToParamLinks.push(...links);
                                        });
                                    });

                                    _.each(result.CanvasItems, (newQueryParam) => {
                                        let newQueryParamProperties = this.GetProperties(newQueryParam.Properties);

                                        let existsQueryParam = _.find(model.CanvasItems, (item) => {
                                                return item.SideName === newQueryParam.SideName
                                                    && this.GetProperties(item.Properties).QueryColumnGuid === newQueryParamProperties.QueryColumnGuid
                                                    && item.Name === newQueryParam.Name;
                                            }
                                        );

                                        if (existsQueryParam) {
                                            newQueryParamProperties.UseSubKey = this.GetProperties(existsQueryParam.Properties).UseSubKey;
                                            newQueryParam.Properties = JSON.stringify(newQueryParamProperties);
                                        }
                                    });

                                    let newLinks = [];

                                    _.each(paramToParamLinks, (link: CanvasLinkModel) => {
                                        let queryParam = _.find(model.CanvasItems, (param) => param.Guid === link.CanvasItem1Guid);
                                        let properties = this.GetProperties(queryParam.Properties);

                                        let newQueryParam = _.find(result.CanvasItems, (item) => item.SideName === 'OUT'
                                            && this.GetProperties(item.Properties).QueryColumnGuid === properties.QueryColumnGuid
                                            && item.Name === queryParam.Name
                                        );

                                        if (newQueryParam) {
                                            let newLink = new CanvasLinkModel();
                                            newLink.CanvasItem1Id = newQueryParam.Id;
                                            newLink.CanvasItem1Guid = newQueryParam.Guid;
                                            newLink.CanvasItem2Id = link.CanvasItem2Id;
                                            newLink.CanvasItem2Guid = link.CanvasItem2Guid;
                                            newLink.State = CanvasModelState.New;
                                            newLink.Properties = link.Properties;
                                            newLinks.push(newLink);


                                            let parent = this.GetParentByType(model, queryParam, CANVAS_TYPES.GROUP);

                                            if (!parent) {
                                                parent = this.GetParentByType(model, _.find(model.CanvasItems, (param) => param.Guid === link.CanvasItem2Guid), CANVAS_TYPES.GROUP);
                                            }

                                            if (parent) {
                                                newLink.RootRecordId = parent.Id;
                                                newLink.RootRecordGuid = parent.Guid;
                                            } else {
                                                newLink.RootRecordId = this._root.Id;
                                                newLink.RootRecordGuid = this._root.Guid;
                                            }

                                        }
                                    });

                                    this.DeleteItem(model, dataSourceModel, view.getEmbeddedCells());

                                    model.CanvasItems.push(...result.CanvasItems);
                                    model.CanvasLinks.push(...result.CanvasLinks, ...newLinks);
                                    this.BuildShapes(model)
                                        .fail(() => {
                                            BlockUI.Unblock();
                                        })
                                        .then(() => {
                                            BlockUI.Unblock();
                                            this._graph.removeCells([view as joint.dia.Cell]);
                                        });
                                });

                            modal.Close();

                        });

                        let modal = new Modal({});
                        ko.cleanNode(modal.Wrapper);
                        ko.applyBindings(queryBuilder, modal.Wrapper);
                        modal.Show();
                    });
                    view.on(EVENTS.SHOW_FUNCTION_DESIGNER, (packageId: number) => {
                        this.Trigger(EVENTS.SHOW_FUNCTION_DESIGNER_REQUESTED, { PackageId: packageId });
                    });

                }

                if (view instanceof shapes.cyberThing.Destination) {
                    destinationShape = view;

                    view.on(EVENTS.BACK_TO_DATA_SELECTOR, () => {

                        let canvasItem1Id = view.get('recordId');
                        let canvasItem1Guid = view.get('guid');

                        let canvasItem = _.find(model.CanvasItems, (item) => {
                            return item.Id == canvasItem1Id && item.Guid === canvasItem1Guid;
                        });

                        this.DeleteItem(model, canvasItem, view.getEmbeddedCells());

                        this.Trigger(EVENTS.BACK_TO_DATA_SELECTOR, {Type: CANVAS_TYPES.DESTINATION});

                        this._graph.removeCells([view as joint.dia.Cell]);
                    });

                    view.on(EVENTS.EDIT_QUERY, (query: JSON) => {
                        let queryBuilder = new QueryBuilder(true, false, false, true);

                        if(query){
                            queryBuilder.EditFreeQuery(GenericDeserialize<QueryExpressionModel>(query, QueryExpressionModel), false);
                        }else{
                            queryBuilder.NewFreeQuery();
                        }

                        queryBuilder.On(QUERY_BUILDER_EVENTS.CLOSE, this, () => {
                            modal.Close();
                        });
                        queryBuilder.On(QUERY_BUILDER_EVENTS.DATA_SAVED, this, (eventArgs: any) => {
                            BlockUI.Block();
                            this.GetByQuery(eventArgs.data.QueryText, CANVAS_TYPES.DESTINATION)
                                .then((result) => {
                                    let canvasItem1Guid = view.get('guid');
                                    let dataSourceModel = _.find(model.CanvasItems, (item) => {
                                        return item.Guid === canvasItem1Guid;
                                    });

                                    let dataSourceToTableLinks = _.filter(model.CanvasLinks, (link) => link.CanvasItem1Guid === dataSourceModel.Guid);

                                    let paramToParamLinks = [];
                                    _.each(dataSourceToTableLinks, (dataSourceToTableLink) => {
                                        let tableModel = _.find(model.CanvasItems, (param) => param.Guid === dataSourceToTableLink.CanvasItem2Guid);

                                        let tableToParamLinks = _.filter(model.CanvasLinks, (link) => {
                                            let child = _.find(model.CanvasItems, (item) => item.Guid === link.CanvasItem2Guid && item.TypeName === CANVAS_TYPES.PARAM);
                                            return link.CanvasItem1Guid === tableModel.Guid && !!child;
                                        });

                                        _.each(tableToParamLinks, (tableToParamLink) => {

                                            let links = _.filter(model.CanvasLinks, (link) => {
                                                let child = _.find(model.CanvasItems, (item) => item.Guid === link.CanvasItem2Guid && item.TypeName === CANVAS_TYPES.PARAM);
                                                return link.CanvasItem2Guid === tableToParamLink.CanvasItem2Guid && !!child;
                                            });
                                            paramToParamLinks.push(...links);
                                        });
                                    });

                                    _.each(result.CanvasItems, (newQueryParam) => {
                                        let newQueryParamProperties = this.GetProperties(newQueryParam.Properties);

                                        let existsQueryParam = _.find(model.CanvasItems, (item) => {
                                                return item.SideName === newQueryParam.SideName
                                                    && this.GetProperties(item.Properties).QueryColumnGuid === newQueryParamProperties.QueryColumnGuid
                                                    && item.Name === newQueryParam.Name;
                                            }
                                        );

                                        if (existsQueryParam) {
                                            newQueryParamProperties.UseSubKey = this.GetProperties(existsQueryParam.Properties).UseSubKey;
                                            newQueryParam.Properties = JSON.stringify(newQueryParamProperties);
                                        }
                                    });

                                    let newLinks = [];

                                    _.each(paramToParamLinks, (link: CanvasLinkModel) => {
                                        let queryParam = _.find(model.CanvasItems, (param) => param.Guid === link.CanvasItem2Guid);
                                        let properties = this.GetProperties(queryParam.Properties);

                                        let newQueryParam = _.find(result.CanvasItems, (item) => item.SideName === 'IN'
                                            && this.GetProperties(item.Properties).QueryColumnGuid === properties.QueryColumnGuid
                                            && item.Name === queryParam.Name
                                        );

                                        if (newQueryParam) {
                                            let newLink = new CanvasLinkModel();
                                            newLink.CanvasItem2Id = newQueryParam.Id;
                                            newLink.CanvasItem2Guid = newQueryParam.Guid;
                                            newLink.CanvasItem1Id = link.CanvasItem1Id;
                                            newLink.CanvasItem1Guid = link.CanvasItem1Guid;
                                            newLink.State = CanvasModelState.New;
                                            newLink.Properties = link.Properties;
                                            newLinks.push(newLink);


                                            let parent = this.GetParentByType(model, queryParam, CANVAS_TYPES.GROUP);

                                            if (!parent) {
                                                parent = this.GetParentByType(model, _.find(model.CanvasItems, (param) => param.Guid === link.CanvasItem2Guid), CANVAS_TYPES.GROUP);
                                            }

                                            if (parent) {
                                                newLink.RootRecordId = parent.Id;
                                                newLink.RootRecordGuid = parent.Guid;
                                            } else {
                                                newLink.RootRecordId = this._root.Id;
                                                newLink.RootRecordGuid = this._root.Guid;
                                            }

                                        }
                                    });

                                    this.DeleteItem(model, dataSourceModel, view.getEmbeddedCells());

                                    model.CanvasItems.push(...result.CanvasItems);
                                    model.CanvasLinks.push(...result.CanvasLinks, ...newLinks);
                                    this.BuildShapes(model)
                                        .fail(() => {
                                            BlockUI.Unblock();
                                        })
                                        .then(() => {
                                            BlockUI.Unblock();
                                            this._graph.removeCells([view as joint.dia.Cell]);
                                        });
                                });

                            modal.Close();

                        });

                        let modal = new Modal({});
                        ko.cleanNode(modal.Wrapper);
                        ko.applyBindings(queryBuilder, modal.Wrapper);
                        modal.Show();
                    });

                    view.on(EVENTS.SHOW_FUNCTION_DESIGNER, (packageId: number) => {
                        this.Trigger(EVENTS.SHOW_FUNCTION_DESIGNER_REQUESTED, { PackageId: packageId });
                    });
                }

				if (view instanceof shapes.cyberThing.Group) {
					groups.push(view);

                    view.on(EVENTS.SHOW_FUNCTION_DESIGNER, (packageId: number) => {
                        this.Trigger(EVENTS.SHOW_FUNCTION_DESIGNER_REQUESTED, { PackageId: packageId });
					});
				}

                if (view instanceof shapes.cyberThing.CSharpFunction || view instanceof shapes.cyberThing.JSFunction|| view instanceof shapes.cyberThing.APIMethod) {
                    view.on(EVENTS.SHOW_FUNCTION_DESIGNER, () => {                        
                        let parent = this._graph.getCell(view.get('parent'));
                        this.Trigger(EVENTS.SHOW_FUNCTION_DESIGNER_REQUESTED, { PackageId: parent.attributes.apiPackageId, FunctionId: view.attributes.recordId });
					});
				}
                
			}
		});

        this._graph.resetCells(cells);

        _.each(model.CanvasLinks, (link: CanvasLinkModel) => {
            if (link.State === CanvasModelState.Deleted) {
                return;
            }
            let parent = this._graph.getCell(link.CanvasItem1Guid);
            let child = this._graph.getCell(link.CanvasItem2Guid);

            if (parent && child) {
                if (parent instanceof shapes.cyberThing.Table && child instanceof shapes.cyberThing.Table) {

                } else if (parent instanceof shapes.cyberThing.Param && child instanceof shapes.cyberThing.Param) {
                    let arrowViewModel = new ArrowViewModel(link);
                    let arrowShape = new shapes.cyberThing.Arrow(arrowViewModel);
                    arrows.push(arrowShape);
                } else {
                    embeds.push({parent: parent, child: child});
                }
            }
        });

        _.each(embeds, (item) => {
            item.parent.embed(item.child);
        });

        return deferredResult.promise();
    }

    GetProperties(properties: string) {
        if (properties && properties != '') {
            return JSON.parse(properties);
        }
        return {}
    }

    public DeleteItem(
        model: CanvasModel,
        item: CanvasItemModel,
        embeddedCells: Array<any>
    ) {
        if (item.State === CanvasModelState.New) {
            model.CanvasItems.splice(model.CanvasItems.indexOf(item), 1);
        } else {
            item.State = CanvasModelState.Deleted;
        }

        let linksToDelete = _.filter(model.CanvasLinks, (link: CanvasLinkModel) => {
            return (link.CanvasItem1Guid === item.Guid || link.CanvasItem2Guid === item.Guid);
        });

        _.each(linksToDelete, (link: CanvasLinkModel) => {
            if (link.State === CanvasModelState.New) {
                model.CanvasLinks.splice(model.CanvasLinks.indexOf(link), 1);
            } else {
                link.State = CanvasModelState.Deleted;
            }
        });

        _.each(embeddedCells, (cell) => {
            let canvasItemGuid = cell.get('guid');

            let item = _.find(model.CanvasItems, (item: CanvasItemModel) => {
                return item.Guid === canvasItemGuid;
            });

            if (item) {
                if (item.State === CanvasModelState.New) {
                    model.CanvasItems.splice(model.CanvasItems.indexOf(item), 1);
                } else {
                    item.State = CanvasModelState.Deleted;
                }
            }

            let linksToDelete = _.filter(model.CanvasLinks, (link: CanvasLinkModel) => {
                return (link.CanvasItem1Guid === canvasItemGuid || link.CanvasItem2Guid === canvasItemGuid);
            });

            _.each(linksToDelete, (link: CanvasLinkModel) => {
                if (link.State === CanvasModelState.New) {
                    model.CanvasLinks.splice(model.CanvasLinks.indexOf(link), 1);
                } else {
                    link.State = CanvasModelState.Deleted;
                }

                let child = _.find(model.CanvasItems, (item: CanvasItemModel) => {
                    return item.Guid === link.CanvasItem2Guid;
                });

                let parent = _.find(model.CanvasItems, (item: CanvasItemModel) => {
                    return item.Guid === link.CanvasItem1Guid;
                });


                if (child && child.TypeName === CANVAS_TYPES.PARAM && ((parent && parent.TypeName != CANVAS_TYPES.PARAM) || _.isEmpty(parent))) {
                    if (child.State === CanvasModelState.New) {
                        model.CanvasItems.splice(model.CanvasItems.indexOf(child, 1), 1);
                    } else {
                        child.State = CanvasModelState.Deleted;
                    }
                }
            });
        });
    }

    private GetViewModel(canvasItem: CanvasItemModel) {
        let view = null
        if (canvasItem.TypeName === CANVAS_TYPES.SOURCE) {
            view = new shapes.cyberThing.Source(new SourceViewModel(canvasItem));
        }

        if (canvasItem.TypeName === CANVAS_TYPES.DESTINATION) {
            view = new shapes.cyberThing.Destination(new DestinationViewModel(canvasItem));
        }

        if (canvasItem.TypeName === CANVAS_TYPES.TABLE) {
            view = new shapes.cyberThing.Table(new TableViewModel(canvasItem));
        }

        if (canvasItem.TypeName === CANVAS_TYPES.JS_FUNCTION) {
            view = new shapes.cyberThing.JSFunction(new JSFunctionViewModel(canvasItem));
        }

        if (canvasItem.TypeName === CANVAS_TYPES.CSHARP_FUNCTION) {
            view = new shapes.cyberThing.CSharpFunction(new CSharpFunctionViewModel(canvasItem));
        }

        if (canvasItem.TypeName === CANVAS_TYPES.API_METHOD) {
            view = new shapes.cyberThing.APIMethod(new APIMethodViewModel(canvasItem));
        }

        if (canvasItem.TypeName === CANVAS_TYPES.TRIGGER) {
            view = new shapes.cyberThing.Trigger(new TriggerViewModel(canvasItem));
        }

        if (canvasItem.TypeName === CANVAS_TYPES.GROUP) {
            view = new shapes.cyberThing.Group(new GroupViewModel(canvasItem));
        }

        return view;
    }

    private GetParentByTypes(model: CanvasModel, item: CanvasItemModel, typeNames: Array<string>): CanvasItemModel {
        let result = null;
        _.each(typeNames, (typeName) => {
            let parent = this.GetParentByType(model, item, typeName);
            if (parent) {
                result = parent;
            }
        });
        return result;
    }

    GetParentByType(model: CanvasModel, item: CanvasItemModel, typeName: string): CanvasItemModel {
        let result = null;
        let parentLinks = _.filter(model.CanvasLinks, (link) => {
            return link.CanvasItem2Guid === item.Guid && link.CanvasItem2Id === item.Id
        });

        _.each(parentLinks, (parentLink) => {
            let parents = _.filter(model.CanvasItems, (canvasItem) => {
                return canvasItem.Id === parentLink.CanvasItem1Id && canvasItem.Guid === parentLink.CanvasItem1Guid;
            });

            _.each(parents, (parent) => {
                if (parent.TypeName === CANVAS_TYPES.PARAM) {
                    return;
                }

                if (parent.TypeName === typeName) {
                    result = parent;
                } else {
                    let parentParent = this.GetParentByType(model, parent, typeName);
                    if (parentParent) {
                        result = parentParent;
                    }
                }
            });
        });

        return result;
    }

    public static GetRoot(cell: any, graph: joint.dia.Graph) {
        let parentId = cell.get('parent');

        if (!parentId) {
            return null;
        }

        let parent = graph.get('cells').get(parentId);

        if (!parent.get('parent')) {
            return parent;
        } else {
            return this.GetRoot(parent, graph);
        }
    }

    public BuildLayout(cell: any) {
        let embeddeds = cell.getEmbeddedCells();
        embeddeds = _.sortBy(embeddeds, (item: any) => {
            return item.get('sort')
        });

        if (embeddeds.length > 0) {

            _.each(embeddeds, (embedded) => {
                this.BuildLayout(embedded);
            });

            GridLayout.layout(embeddeds, {
                parentRelative: true,
                deep: true,
                dy: 5,
                resizeToFit: true
            });

            cell.fitEmbeds({
                padding: {
                    top: 25,
                    left: 10,
                    right: 20,
                    bottom: 10
                }, deep: true
            });
        }
    }

    GetByQuery(query: string, canvasType: string): P.Promise<CanvasModel> {
        const deferredResult = P.defer<CanvasModel>();
        CanvasDesignerStore
            .GetNodesByQuery(query, this._root.Guid, canvasType)
            .always(() => {
            }).then((result) => {
            deferredResult.resolve(result);
        });
        return deferredResult.promise();
    }

    public Expand(cellView: any, model: CanvasModel, graph: joint.dia.Graph, paper: joint.dia.Paper) {
        let cells = [];
        let canvasItem1Id = cellView.model.get('recordId');
        let canvasItem1Guid = cellView.model.get('guid');
        let arrows = [];
        let items = [];
        let params = _.filter(model.CanvasLinks, (link) => {
            return link.CanvasItem1Guid === canvasItem1Guid && link.CanvasItem1Id === canvasItem1Id;
        });

        _.each(params, (param) => {
            let itemModel = _.find(model.CanvasItems, (item) => {
                return item.Id === param.CanvasItem2Id && item.Guid === param.CanvasItem2Guid;
            });

            if (itemModel) {
                if (itemModel.TypeName === CANVAS_TYPES.PARAM) {

                    items.push(itemModel);

                    if (itemModel.SideName === SIDES.IN) {
                        let parent = this.GetParentByType(model, itemModel, CANVAS_TYPES.SOURCE)
                        if (!parent) {
                            cells.push(new shapes.cyberThing.Param(new ParamViewModel(itemModel)));
                        }
                    }

                    if (itemModel.SideName === SIDES.OUT) {
                        let parent = this.GetParentByType(model, itemModel, CANVAS_TYPES.DESTINATION)
                        if (!parent) {
                            cells.push(new shapes.cyberThing.Param(new ParamViewModel(itemModel)));
                        }
                    }
                } else {
                    let view = this.GetViewModel(itemModel);
                    if (view) {
                        cells.push(view);
                    }
                }
            }
        });

        paper.on('render:done', () => {
            paper.off('render:done');

            graph.addCells(arrows);

            _.each(cells, (cell: any) => {
                cell.attr('./visibility', 'visible');
            });

            let parent = ShapeFactory.GetRoot(cellView.model, graph);
            if (parent) {
                let position = parent.get('position');
                GridLayout.layout(cellView.model.getEmbeddedCells(), {
                    parentRelative: true,
                    deep: true,
                    dy: 5,
                    resizeToFit: true
                });

                cellView.model.fitEmbeds({
                    padding: {
                        top: 25,
                        left: 10,
                        right: 20,
                        bottom: 10
                    }, deep: true
                });

                GridLayout.layout(parent.getEmbeddedCells(), {
                    parentRelative: true,
                    deep: true,
                    dy: 5,
                    resizeToFit: true
                });

                parent.fitEmbeds({
                    padding: {
                        top: 25,
                        left: 10,
                        right: 20,
                        bottom: 10
                    }, deep: true
                });

                parent.position(position.x, position.y, {deep: true});
            }
        });

        graph.addCells(cells);

        _.each(cells, (item) => {
            cellView.model.embed(item);
        });

        _.each(items, (itemModel) => {
            let links = _.filter(model.CanvasLinks, (link) => {
                return (link.CanvasItem1Id === itemModel.Id && link.CanvasItem1Guid === itemModel.Guid) || (link.CanvasItem2Id === itemModel.Id && link.CanvasItem2Guid === itemModel.Guid);
            });

            _.each(links, (link: CanvasLinkModel) => {
                let parent = graph.getCell(link.CanvasItem1Guid);
                let child = graph.getCell(link.CanvasItem2Guid);

                if (parent && child) {
                    if (parent instanceof shapes.cyberThing.Table && child instanceof shapes.cyberThing.Table) {

                    } else if (parent instanceof shapes.cyberThing.Param && child instanceof shapes.cyberThing.Param) {
                        let arrowViewModel = new ArrowViewModel(link);
                        let arrowShape = new shapes.cyberThing.Arrow(arrowViewModel);
                        arrows.push(arrowShape);
                    }
                }
            });
        });
    }
}