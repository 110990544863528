import * as ko from 'knockout';
import * as _ from 'underscore';

import {BaseProduct} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/BaseProduct';
import {ProductPart} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/ProductPart';

import {BreadCrumbsItem} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/BreadCrumbsItem';
import {BaseProductGroup} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/BaseProductGroup';

import {LABELS} from "Core/Components/Translation/Locales";

import Template from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Templates/UndefinedGroup.html';

export class UndefinedGroup extends BaseProductGroup {
    private _labels = LABELS;

    protected container: HTMLElement;

    Products: KnockoutObservableArray<ProductPart>;
    BreadCrumbs: KnockoutObservableArray<BreadCrumbsItem>;

    constructor(protected parentProduct: BaseProduct) {

        super(0, LABELS.UNDEFINED, LABELS.UNDEFINED, [], false, parentProduct);

        this.Products = ko.observableArray([]);
        this.BreadCrumbs = ko.observableArray([]);

        this.ExtendedView(true);
    }

    get IsRoot() {
        return true;
    }

    get IsExtra() {
        return false;
    }

    get IsUndefined() {
        return true;
    }

    get HasProducts() {
        return _.any(this.Products());
    }

    AfterInit() {
    }

    GetTemplate() {
        return Template;
    }

    AfterRender(el: HTMLElement) {
        this.container = el[0];
    }

    Dispose() {
        this.Products().forEach(product => product.Dispose());
        super.Dispose();
    }

    ToggleBody() {
        if (!this.Expanded()) {
            this.Expand();
        } else {
            this.Collapse();
        }
    }

    Collapse(): void {
        this.Expanded(false);
    }

    Expand(): void {
        this.Expanded(true);
    }
}