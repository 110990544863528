import * as ko from "knockout";

import { FIELD_TYPES } from "Core/Constant";

//Store models
import {DisapprovedReservationResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Disapproved/Response/DisapprovedReservationResponseModel";

//Utils
import {FormatConverter} from "FormatEditor/FormatConverter";

//ViewModels
import {DisapprovedReservation} from "Core/Components/Controls/TimeWriting/Models/View/DisapprovedDataViewModel"

//Mappings
import {SubjectMappings} from "Core/Components/Controls/TimeWriting/Mappings/Day/SubjectMappings";
import {LookupValueMappings} from "Core/Components/Controls/TimeWriting/Mappings/Common/LookupValueMappings";
import {OptionalFieldData} from "../../Models/View/DayDataViewModel";

export class DisapprovedReservationMappings {
    static OnViewModel(model: DisapprovedReservationResponseModel): DisapprovedReservation {
        const viewModel = new DisapprovedReservation();

        viewModel.Id = model.Id;
        viewModel.AgendaId = model.AgendaId;
        viewModel.Start(new Date(FormatConverter.CorrectTimezone(model.Start.toString())));
        viewModel.TimeSpent(model.TimeSpent);
        viewModel.Subject(SubjectMappings.OnViewModel(model.Subject));
        viewModel.Description(model.Description);
        viewModel.Ovw(model.OverWorkIndicator ? LookupValueMappings.OnViewModel(model.OverWorkIndicator) : null);
        viewModel.Pay(model.PaymentIndicator ? LookupValueMappings.OnViewModel(model.PaymentIndicator) : null);
        viewModel.HourKind(model.HourKindIndicator ? LookupValueMappings.OnViewModel(model.HourKindIndicator) : null);
        viewModel.Accepted = model.Accepted;
        viewModel.BookedCounter = model.BookedCounter;
        viewModel.DisapprovedReason = model.DisapprovedReason;
        viewModel.Comment = model.Comment;

        // viewModel.OptionalData = model.OptionalData.map(field => new OptionalFieldData({
        //         FieldName: field.FieldName,
        //         FieldType:
        //         field.FieldTypeName,
        //         FieldValue: ko.observable(field.FieldTypeName === FIELD_TYPES.Lookup ? {
        //             FieldValue: field.FieldValue,
        //             DisplayValue: field.DisplayValue
        //         } : field.FieldValue)
        //     }
        // ));

        return viewModel;
    }
}