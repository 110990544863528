//Libs
import * as ko from "knockout";

//Common
import {P} from "Core/Common/Promise";
import {Notifier} from "Core/Common/Notifier";

//Utils
import {DateTime, IDestructedDate} from "Core/Components/Controls/TimeWriting/Utils/DateTime";

//Views
import {TimeWritingView} from "Core/Components/Controls/TimeWriting/Views/TimeWritingView";

//State
import {State} from "Core/Components/Controls/TimeWriting/Views/Year/State";

//ViewModels
import {YearDataViewModel, Week} from "Core/Components/Controls/TimeWriting/Models/View/YearDataViewModel";

//Stores
import {TimeWritingStore} from "Core/Components/Controls/TimeWriting/Stores/TimeWritingStore";

//StoreModels
import {YearDataResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Year/Response/YearDataResponseModel";

//Mappings
import {YearMappingProfile} from "Core/Components/Controls/TimeWriting/Mappings/Year/YearMappingProfile";

//Templates
import YearTemplate from "Core/Components/Controls/TimeWriting/Templates/YearTemplate.html";
import {IYearViewSettings} from "Core/Components/Controls/TimeWriting/Views/Year/IYearViewSettings";
import {NOTIFICATIONS, LABELS} from "Core/Components/Translation/Locales";
import {UserManager} from "../../../../../../User/UserManager";
import {UsersResponseModel} from "../../Models/Store/Day/Response/UsersResponseModel";
import {UsersMappingProfile} from "../../Mappings/Day/UsersMappingProfile";
import {UsersViewModel, UserViewModel} from "../../Models/View/UsersViewModel";
import {BlockUI} from "Core/Common/BlockUi";

export class YearView extends TimeWritingView {
    private _currentDate: IDestructedDate;
    private _state: KnockoutObservable<State>;
    private _viewModel: KnockoutObservable<YearDataViewModel>;
    private _isPastYear: KnockoutComputed<boolean>;
    private _currentWeek: KnockoutObservable<number>;
    private _labels = LABELS;
    private _dataBySubject: KnockoutObservable<boolean>;

    constructor(private _settings: IYearViewSettings) {
        super();
        this._currentDate = DateTime.DestructDate(new Date());
        this._state = ko.observable(new State({Year: this._currentDate.Year}));
        this._viewModel = ko.observable(null);
        this._currentWeek = ko.observable(null);
        this._isPastYear = ko.computed(() => this._state().Year < this._currentDate.Year, this);
        this._dataBySubject = ko.observable(null);
    }

    GetTemplate() {
        return YearTemplate;
    }

    Show(year: number, userId?: number) {
        this._state(new State({
            Year: year
        }));

        const usersList = this._viewModel() && this._viewModel().Users;
        if (usersList && userId) {
            const selectedUser = usersList.Users().find(user => user.Id === userId);
            if (selectedUser) {
                usersList.SelectedUser(selectedUser);
            }
        }

        if (usersList) {
            this.LoadData(year, usersList.SelectedUser().Id)
                .then(model => this.RenderData(model, usersList))
                .fail(error => this.ShowError(error.message));
        } else {
            const loadDataPromise = this.LoadData(year, userId || UserManager.Instance.CurrentUser.Id);
            const loadUsersPromise = this.LoadUsers();

            loadDataPromise.fail(error => this.ShowError(error.message));
            loadUsersPromise.fail(error => this.ShowError(error.message));

            P.when(loadDataPromise, loadUsersPromise).then(result => this.RenderData(result[0], result[1], userId || UserManager.Instance.CurrentUser.Id));
        }
    }

    OnCurrentYearClick() {
        this.Show(this._viewModel().CurrentYear);
    }

    OnPrevYearClick() {
        const year = this._state().Year - 1;
        this.Show(year);
    }

    OnNextYearClick() {
        const year = this._state().Year + 1;
        this.Show(year);
    }

    OnWeekClick(week: Week) {
        if (this._viewModel().NavigationAllowed(week)) {
            this._state().SelectedWeek = week;
            this.Trigger("WeekSelected", {State: this._state(), UserId: this._viewModel().Users.SelectedUser().Id});
        }
    }

    private LoadData(year: number, userId: number) {
        return TimeWritingStore.GetYear({
            Year: year,
            EntityId: this._settings.EntityId,
            UserId: userId,
            SubjectEntityId: this._settings.SubjectEntityId,
            SubjectRecordId: this._settings.SubjectRecordId
        });
    }

    private RenderData(model: YearDataResponseModel, users: UsersViewModel, selectedUserId?: number) {
        const viewModel = YearMappingProfile.OnViewModel(model);

        viewModel.Year = this._state().Year;
        viewModel.CurrentWeek = this._currentDate.WeekNumber;
        viewModel.CurrentYear = this._currentDate.Year;
        viewModel.Users = users;

        if (selectedUserId) {
            const selectedUser = viewModel.Users.Users().find(user => user.Id === selectedUserId);
            if (selectedUser) {
                viewModel.Users.SelectedUser(selectedUser);
            }
        }

        this._currentWeek(viewModel.CurrentWeek);
        this._dataBySubject(viewModel.DataBySubject);

        this._viewModel(viewModel);

        BlockUI.Unblock();
    }

    private LoadUsers() {
        return TimeWritingStore.GetUsers(this._settings.EntityId)
            .then(users => users.Users.length === 0
                ? UsersResponseModel.CreateDefault()
                : UsersMappingProfile.OnViewModel(users)
            )
            .fail(error => new Notifier().Failed(error.message));
    }

    private UserSelected(user: UserViewModel) {
        this.Show(this._state().Year);
    }

    private ShowError(message: string) {
        new Notifier().Failed(message);
    }
}