import {Event} from 'Core/Common/Event';
import {EventHandler} from './EventHandler';

export interface IEventArgs<TData extends {}, TSource extends {}> {
    Data: TData;
    Source: TSource;
}

export class EventBus extends Event {
    constructor() {
        super();
    }

    Dispatch<TData extends {}, TSource extends {}>(eventName: string, args?: IEventArgs<TData, TSource>) {
        this.Trigger(eventName, args);
    }

    Handle<TData extends {}, TSource extends {}, TContext extends {}>(eventName: string, context: any): EventHandler<TData, TSource, TContext> {
        return new EventHandler(this, eventName, context);
    }
}