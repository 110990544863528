import { Week } from "Core/Components/Controls/TimeWriting/Models/View/YearDataViewModel";

export class State {
	Year: number;
	SelectedWeek: Week;

	constructor(initializers?: Partial<State>) {
		if (initializers) {
			this.Year = initializers.Year;
			this.SelectedWeek = initializers.SelectedWeek;
		}
	}
}