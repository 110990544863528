define(['jquery', 'moment', 'Core/Controls/DateTime/WeekFormatUtils'], function ($, moment, weekFormatUtils) {

    const WeekFormatUtils = weekFormatUtils.WeekFormatUtils;

    (function ($) {
        "use strict";

        function getEscapedFormat(format) {
            return format === WeekFormatUtils.GetLocalizedWeekFormat() ? 'L' : format;
        }

        var DateTimePickerBootstrap = function (options) {
            this.init('dateTimePickerBootstrap', options, DateTimePickerBootstrap.defaults);
            this.initPicker(options, DateTimePickerBootstrap.defaults);
        };

        $.fn.editableutils.inherit(DateTimePickerBootstrap, $.fn.editabletypes.abstractinput);

        $.extend(DateTimePickerBootstrap.prototype, {
            initPicker: function(options, defaults) {
                options.dateTimePickerBootstrap = $.fn.editableutils.tryParseJson(options.dateTimePickerBootstrap, true);
            },

            render: function () {
                const isWeekFormat = this.options.dateTimePickerBootstrap.format === WeekFormatUtils.GetLocalizedWeekFormat();

                this.$input = this.$tpl;

                if (isWeekFormat) {
                    WeekFormatUtils.PatchWeekFormatInput(this.$input.get(0), this.options.dateTimePickerBootstrap.format);
                }

                const pickerOptions = isWeekFormat
                    ? WeekFormatUtils.PatchWeekFormatOptions(this.options.dateTimePickerBootstrap)
                    : this.options.dateTimePickerBootstrap;

                this.$input.datetimepicker(pickerOptions);
            },
            value2html: function (value, element) {
                if (!value) {
                    $(element).empty();
                    return;
                }
                var textValue = '';
                if (value.current != undefined && value.current != null) {
                    textValue = value.current;
                }
                if (value.default != undefined && value.default != null) {
                    textValue = value.default;
                }
                $(element).html(textValue && moment(textValue).format(this.options.dateTimePickerBootstrap.format));
            },
            value2input: function (value) {
                if (!value) {
                    return;
                }

                const format = getEscapedFormat(this.options.dateTimePickerBootstrap.format);

                if (value.current != undefined && value.current != null) {
                    this.$input.val(moment(value.current).format(format));
                    return;
                }
                if (value.default != undefined && value.default != null) {
                    this.$input.val(moment(value.default).format(format));
                    return;
                }
            },
            input2value: function () {
                const value = this.$input.val();
                const format = getEscapedFormat(this.options.dateTimePickerBootstrap.format);

                return {
                    current: value ? moment(value, format).format() : undefined
                }
            },
            activate: function () {
                this.$input.focus();
            }
        });

        DateTimePickerBootstrap.defaults = $.extend({}, $.fn.editabletypes.abstractinput.defaults, {
            tpl: '<input type="text" class="editable-datetimepicker"/>',
            emptytext: 'Empty',
            dateTimePickerBootstrap: {
                locale: 'nl',
                format:'YYYY-MM-DD',
                widgetPositioning: {
                    horizontal: 'right',
                    vertical: 'bottom',
                    forceParse: false
                }
            }
        });

        $.fn.editabletypes.dateTimePickerBootstrap = DateTimePickerBootstrap;

    }($));
});