import * as ko from 'knockout';

import { CookieManager } from 'Core/Common/CookieManager';
import { Event } from 'Core/Common/Event';
import { Modal } from 'Core/Common/Modal';

import { Events } from "Auth/Enums/Events";

import { PasswordResetModel } from "./Model/PasswordResetModel";
import {MobileChecker} from 'Core/Common/MobileChecker';

import PasswordResetFormTemplate from 'Auth/UI/PasswordResetForm/Templates/PasswordResetForm.html';
ko.templates['Auth/UI/PasswordResetForm/Templates/PasswordResetForm'] = PasswordResetFormTemplate;

export class PasswordResetForm extends Event {
	private _model: KnockoutValidatedObservable<PasswordResetModel>;
	private _modal: Modal;
	private _hasFocus: KnockoutObservable<boolean>;

	constructor() {
		super();

		this.Init();
		this._hasFocus = ko.observable(false);

		this.AddEvent(Events.FormClosed);
		this.AddEvent(Events.UpdatePassword);
	}
R
	Load() {
		this.Show('Create new password');
	}

	Close() {
		this.Trigger(Events.FormClosed);
		this._modal.Close();
	}

	IsValid() {
		if (!this._model.isValid()) {
			this._model.errors.showAllMessages();
		}

		return this._model.isValid();
	}

	GetFormData() {
		return this._model();
	}

	private Init() {
		ko.validation.init({
			insertMessages: false
		});
	}

	private Show(formTitle: string) {
		const token = CookieManager.GetValue('ResetPasswordToken');
		this._model = ko.validatedObservable(new PasswordResetModel(token));
		this._model().FormTitle(formTitle);

		this._modal = new Modal({
			addClass: 'login',
			width: '500',
			minHeight: '300'
		}, false);

		this._modal.One('CLOSE', this, () => this.Close());

		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);

		this._modal.Show();
		let isMobile = MobileChecker.IsMobile();
		this._hasFocus(!isMobile);
	}

	private OkButtonClicked() {
		this.Trigger(Events.UpdatePassword);
		CookieManager.Remove('ResetPasswordToken');
	}

	private CancelButtonClicked() {
		this.Close();
		CookieManager.Remove('ResetPasswordToken');
	}

	private GetTemplateName() {
		return 'Auth/UI/PasswordResetForm/Templates/PasswordResetForm';
	}

	private AfterRender() {

	}
}