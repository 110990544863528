import * as ko from "knockout";

export class RadioButtonOptionViewModel {
	Text: string;
	Value: number;
	private _isSelected: KnockoutObservable<boolean> = ko.observable(false);
	private _isEnabled: boolean;

	constructor(params?: IRadioButtonViewModelParams) {
		if (params) {
			this.Text = params.Text;
			this.Value = params.Value;
		}
	}

	static Create(value: number, text: string, isEnabled: boolean, isSelected: boolean) {
		const item = new RadioButtonOptionViewModel();

		item.Value = value;
		item.Text = text;
		item._isEnabled = isEnabled;
		item._isSelected(isSelected);

		return item;
	}
}

export interface IRadioButtonViewModelParams {
	Text: string;
	Value: number;
}