import * as ko from "knockout"

import {BaseControl, IControlValue} from "Core/Controls/BaseControl/BaseControl"
import {IControlParam} from "Core/Screens/IScreen";
import {BlockUI} from 'Core/Common/BlockUi';

import {TypeSecurityStore} from "Core/Controls/TypeSecurity/Stores/TypeSecurityStore";
import {TypeSecurityModel} from "Core/Controls/TypeSecurity/Models/TypeSecurityModel";

import {
    TableViewModel,
    TypeSecurityViewModel,
    TypeViewModel
} from "Core/Controls/TypeSecurity/Models/TypeSecurityViewModel";
import {JBoxDropDown} from "Core/Components/JBoxDropdown/DropDown";
import {ZIndexManager} from "Core/Common/ZIndexManager";
import {TypeSecuritySerializedModel} from "./Models/TypeSecuritySerializedModel";
import {TypeModel} from "./Models/TypeModel";

import ViewTemplate from "Core/Controls/TypeSecurity/Templates/View.html"
import EditTemplate from "Core/Controls/TypeSecurity/Templates/Edit.html"
import ToolBarTemplate from "Core/Controls/TypeSecurity/Templates/ToolBar.html"
import DesignTemplate from "Core/Controls/TypeSecurity/Templates/Design.html"
import TypeSecurityDropdownTemplate from "Core/Controls/TypeSecurity/Templates/TypeSecurityDropdownTemplate.html";

ko.templates["Core/Controls/TypeSecurity/Templates/Edit"] = EditTemplate;
ko.templates["Core/Controls/TypeSecurity/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/TypeSecurity/Templates/ToolBar"] = ToolBarTemplate;
ko.templates["Core/Controls/TypeSecurity/Templates/Design"] = DesignTemplate;

export class TypeSecurity extends BaseControl {
    private _userId: number;
    private _data: KnockoutObservable<TypeSecurityViewModel>;
    private _typeData: KnockoutObservable<TableViewModel>;
    private _selectedTypes: Array<number>;
    private _dropDown: JBoxDropDown;
    private _allText: string;

    constructor(params: IControlParam) {
        super(params);
        this._data = ko.observable(null);
        this._typeData = ko.observable(null);
        this._selectedTypes = [];
        this._dropDown = null;
        this._allText = this._labels.ALL;
    }

    ApplyProperties(){}

    SetValue(value: IControlValue): void {
        this._userId = value.SubjectRecordId;

        BlockUI.Block();
        TypeSecurityStore.GetTypeSecurityData(value.SubjectRecordId)
            .always(() => {
                BlockUI.Unblock();
            })
            .then((result: TypeSecurityModel) => {
                let typeSecurityViewModel: TypeSecurityViewModel = new TypeSecurityViewModel(result.Tables);

                _.each(typeSecurityViewModel.TablesView, (table: TableViewModel)=> {
                    _.each(table.TypesView, (typeView: TypeViewModel)=> {
                        if (typeView.IsSelected()){
                            this._selectedTypes.push(typeView.Id);
                            table.SelectedAll(false);
                        }
                    })
                })
                this._data(typeSecurityViewModel);
            });
    }

    RemoveTypeItem(tablesView:TypeSecurityViewModel, typeItem:TypeViewModel) {
        let tableView = _.find(tablesView.TablesView, (table: TableViewModel)=> {
            return _.find(table.TypesView, (type)=>{
                return type.Id === typeItem.Id;
            })
        })

        typeItem.IsSelected(false);
        this._selectedTypes.splice(this._selectedTypes.indexOf(typeItem.Id), 1);

        tableView.SelectedAll(!_.some(tableView.TypesView, (type: TypeViewModel)=> type.IsSelected()));
    }
    SelectTypeItem(tableView:TableViewModel, typeItem:TypeViewModel): void {
        typeItem.IsSelected(true);
        this._selectedTypes.push(typeItem.Id);
        tableView.SelectedAll(false);
    }

    ShowTypeSecurityDropDown(tableView: TableViewModel, evt: MouseEvent){
        const target: any = evt.currentTarget;
        this._typeData(tableView);

        if (!this._dropDown) {
            this._dropDown =  new JBoxDropDown({
                bindComponent: this,
                target: target,
                otherOptions: {
                    addClass: "multi-select-dropdown",
                    attach: undefined,
                    pointer: "",
                    isolateScroll: true,
                    content: TypeSecurityDropdownTemplate as any,
                    position: {
                        x: "right",
                        y: "bottom"
                    },
                    height: 'auto',
                    zIndex: ZIndexManager.Instance.NextValue,
                    onCloseComplete: () => {
                        this._dropDown.Destroy()
                        this._dropDown = null;
                    },
                    maxHeight: 200
                },
                bindTarget: target,
                bindOnCreate: true
            });
        }

        this._dropDown.Toggle();
    }

    Deserialize(): TypeSecuritySerializedModel {
        return new TypeSecuritySerializedModel(this._userId, this._selectedTypes);
    }

    AfterRender(el: Array<HTMLElement>): void {
        super.AfterRender(el);
    }
}