import { CANVAS_TYPES } from 'Core/Controls/CanvasDesigner/Constants/CanvasTypes';
import { CanvasModel } from './Models/CanvasModel';
import * as ko from 'knockout';
import 'pubsub';
import * as $ from "jquery";

import {BlockUI} from 'Core/Common/BlockUi';
import {IControlParam} from 'Core/Screens/IScreen';
import {BaseControl} from 'Core/Controls/BaseControl/BaseControl';
import {CONTROL_TYPES, RenderModes} from 'Core/Constant';
import {SearchScreen} from 'Core/Screens/SearchScreen/SearchScreen';
import {TypeScreen} from 'Core/Screens/TypeScreen/TypeScreen';
import {CanvasDesignerStore} from 'Core/Controls/CanvasDesigner/Stores/CanvasDesignerStore';
import {CanvasDesignerMetadataModel} from 'Core/Controls/CanvasDesigner/Models/CanvasDesignerMetadataModel';
import {Notifier} from 'Core/Common/Notifier';
import {
    ConfirmationDialog,
    EVENTS as ConfirmationDialogEvents,
    Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';

import {ScreenManager} from 'Core/ScreenManager/ScreenManager';
import {EditScreen} from 'Core/Screens/EditScreen/EditScreen';
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';
import {MethodsList} from 'Core/Controls/CanvasDesigner/Views/MethodsList';
import {CanvasManager} from 'Core/Controls/CanvasDesigner/Views/CanvasManager';
import {CanvasItemModel, CanvasLinkModel} from 'Core/Controls/CanvasDesigner/Models/CanvasModel';
import {NOTIFICATIONS, CONFIRMATIONS, LABELS} from 'Core/Components/Translation/Locales';

import ViewTemplate from 'Core/Controls/CanvasDesigner/Templates/View.html';
import {FUNCTION_TYPES} from "../FunctionDesigner/FunctionTypes";
import {EVENTS as SEARCH_SCREEN_EVENTS} from "../../Screens/SearchScreen/Events";
import {EVENTS} from "./Shapes/Views/ParamView/Events";
import {CanvasModelState} from "./Enums/CanvasModelState";
import {FunctionDesignerMethodModel} from "../FunctionDesigner/Models/FunctionDesignerMethodModel";
import {LockManager} from "../../Components/Locker/LockManager";
import {EVENTS as EDIT_SCREEN_EVENTS} from "../../Screens/EditScreen/Events";
import {EntityTypesStore} from "../../Screens/TypeScreen/Stores/EntityTypesStore";
import {Modal} from "../../Common/Modal";
import {P} from "../../Common/Promise";
import {ControlModel} from "../BaseControl/Models/ControlModel";
import {FunctionDesigner} from "../FunctionDesigner/FunctionDesigner";
import {
    DecisionDialog,
    EVENTS as DECISION_DIALOG_EVENTS,
    Types as DecisionDialogTypes
} from "../../Components/Dialogs/DecisionDialog/DecisionDialog";


import ace from 'ace-builds';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-csharp';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/snippets/text';
import 'ace-builds/src-noconflict/worker-javascript';


import { SignalRNotificationManager } from 'Core/Components/SignalR/SignalRNotificationManager';
import { DataTypes } from './Constants/DataTypes';

import AdditionalSettingsDropDownTemplate from 'Core/Controls/CanvasDesigner/Templates/AdditionalSettingsDropDown.html';
import { ModelState } from '../../Common/Enums/ModelState';
ko.templates['Core/Controls/CanvasDesigner/Templates/AdditionalSettingsDropDown'] = AdditionalSettingsDropDownTemplate;

ko.templates['Core/Controls/CanvasDesigner/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/CanvasDesigner/Templates/Edit'] = ViewTemplate;

export class CanvasDesigner extends BaseControl {
    private _metadata: CanvasDesignerMetadataModel;
    private _isReady: KnockoutObservable<boolean>;
    private _recordId: number;
    private _hasData: KnockoutObservable<boolean>;
    private _searchTerm: KnockoutObservable<string>;
    private _canvas: CanvasManager;
    private _canvasRendered: KnockoutObservable<boolean>;
    private _canvasSelected: KnockoutObservable<boolean>;
    private _disableOnDelete: KnockoutObservable<boolean>;
    private _ignoreMissingSubKeys: KnockoutObservable<boolean>;
    private _dataModel: CanvasModel;
    private _packageName: KnockoutObservable<string>;
    private _debugMode: KnockoutObservable<boolean>;
    private _hasRollback: KnockoutObservable<boolean>;
    private _showRollbackButton: KnockoutObservable<boolean>;
    private _modal: Modal;

    validationIsActive: KnockoutObservable<boolean>;
    private _isForLifeStatusDesigner: KnockoutObservable<boolean>;

    constructor(params: IControlParam) {
        super(params);
        this._searchTerm = ko.observable('');
        this._isReady = ko.observable(true);
        this._hasData = ko.observable(false);
        this._canvasRendered = ko.observable(false);
        this._canvasSelected = ko.observable(false);
        this.validationIsActive = ko.observable(false);
        this._disableOnDelete = ko.observable(false);
        this._ignoreMissingSubKeys = ko.observable(false);
        this._packageName = ko.observable('');
        this._debugMode = ko.observable(false);
        this._hasRollback = ko.observable(false);
        this._showRollbackButton = ko.observable(false);
        this._isDataLoading = ko.observable(false);
        this._isForLifeStatusDesigner = ko.observable(false);
        this._packageName.subscribe((newValue)=>{
            this.Root.Name = newValue;
            if(this.Root.State != ModelState.New){
                this.Root.State = ModelState.Changed;
            }            
        });

        this.AddEvent('CANVAS_CREATED');
        this.AddEvent('ON_EDIT_CANVAS');
        this.AddEvent('ON_DELETE_CANVAS');
        this.AddEvent('CLOSE_MODAL');
        this.AddEvent('SAVE');
        this.Init();
    }

    ApplyProperties(){}

    private Init(): void {
        if (this._renderMode() !== RenderModes.Design && this._renderMode() !== RenderModes.ToolBar) {
            if(!this._isForLifeStatusDesigner()){
                CanvasDesignerStore.GetCanvasDesignerMetaData()
                .then((result) => {
                    if (!result.IsSuccessfull) {
                        new Notifier(this._el).Failed(result.ErrorMessage);
                        return;
                    }

                    this._metadata = result.ResultObject;
                }).fail(err => new Notifier().Failed(err.message));
            }
        }

        this._ignoreMissingSubKeys.subscribe((newValue) => {
            this.UpdateIgnoreSubKeyProperty(newValue);
        });

        this._debugMode.subscribe((newValue) => {
            this.UpdateDebugModeProperty(newValue);
        });
    }

    EditPackage() {
        BlockUI.Block();

        LockManager.Instance.TryLock(this._metadata.EntityId, this._recordId)
            .then(() => {
                ScreenManager.GetEditScreen({
                    EntityId: this._metadata.EntityId,
                    RecordId: this._recordId
                }).always(() => {
                    BlockUI.Unblock();
                }).then((screen: EditScreen) => {
                    screen.On(EDIT_SCREEN_EVENTS.RECORD_SAVED, this, (eventArgs) => {
                        this.Trigger('ON_EDIT_CANVAS', eventArgs.data);
                        const notifier = new Notifier($(this._el));
                        notifier.Success(NOTIFICATIONS.RECORD_SAVED);
                        UserVarsManager.Instance.AddRecent(this._metadata.EntityId, eventArgs.data.RecordId, this._recordId);
                        LockManager.Instance.ReleaseLock(this._metadata.EntityId, this._recordId);
                        this.LoadScreenFor(this._recordId, true);
                    });

                    screen.On('MODAL_CLOSE', this, () => LockManager.Instance.ReleaseLock(this._metadata.EntityId, this._recordId));
                    screen.ShowInModal();
                }).fail(error => {
                    new Notifier($(this._el)).Warning(error.message);
                });
            });
    }

    get Root(){
        return this._dataModel.CanvasItems.find(item=>item.TypeName === CANVAS_TYPES.DW_PACKAGE);
    }

    UpdateDebugModeProperty(value: boolean) {
        let root = _.find(this._dataModel.CanvasItems, (item) => {
            return item.Id == this._recordId;
        });

        if (root) {
            try {
                let properties = JSON.parse(root.Properties);
                if (properties.DebugMode === value) return;
                properties.DebugMode = value;
                root.Properties = JSON.stringify(properties);
            } catch (e) {
                root.Properties = JSON.stringify({DebugMode: value});
            }
            if (root.State != CanvasModelState.New && root.State != CanvasModelState.Deleted) {
                root.State = CanvasModelState.Changed;
            }
        }
    }

    ShowInModal(event) {
        let self = this;
        this._modal = new Modal({
            addClass: 'compose-email',
            closeOnEsc: false,
            onOpen: function () {
                $(this.closeButton[0]).off('click');
                this.closeButton[0].addEventListener('click', self.PreventCloseModal.bind(self, self._modal, event));
            }
        });

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);

        this._modal.Show();
    }

    PreventCloseModal(modal, event) {
        event.stopImmediatePropagation();

        let confirmationDialog = new ConfirmationDialog({
            Text: CONFIRMATIONS.CLOSE,
            Type: ConfirmationTypes.Question,
            TextConfirm: LABELS.YES,
            TextDecline: LABELS.NO
        });

        confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, (eventArgs) => {
            modal.Close();
            this.Trigger('CLOSE_MODAL', eventArgs.data);
        });
        confirmationDialog.Show();
    }

    UpdateIgnoreSubKeyProperty(value: boolean) {
        let root = _.find(this._dataModel.CanvasItems, (item) => {
            return item.Id == this._recordId;
        });

        if (root) {
            try {
                let properties = JSON.parse(root.Properties);
                if (properties.IgnoreMissingSubKey === value) return;
                properties.IgnoreMissingSubKey = value;
                root.Properties = JSON.stringify(properties);
            } catch (e) {
                root.Properties = JSON.stringify({IgnoreMissingSubKey: value});
            }
            if (root.State != CanvasModelState.New && root.State != CanvasModelState.Deleted) {
                root.State = CanvasModelState.Changed;
            }
        }
    }

    GetProperties() {
        let root = _.find(this._dataModel.CanvasItems, (item) => {
            return item.Id == this._recordId;
        });

        if (root) {
            try {
                return JSON.parse(root.Properties) || {};
            } catch (e) {
                return {};
            }
        }
    }

    private InitCanvas() {
        this._canvasSelected(true);
        $('#theme-container, #stencil-container, #papers-container,  #inspector-container, .tabs-buttons-container').empty();
        $('#papers-container').append('<div class="papers" id="paper-container"></div>');
        this._canvas = new CanvasManager(this._dataModel);
        this._packageName(this._canvas.PackageName);

        this._canvas.GetShapeFactory().On(EVENTS.SHOW_FUNCTION_DESIGNER_REQUESTED, this, (evtArgs) => {
            if (this.HasChanges()) {
                const decisionDialog = new DecisionDialog({
                    Text: LABELS.CONFIRMATION_FOR_UNSAVED_CHANGES,
                    Type: DecisionDialogTypes.Question,
                });

                decisionDialog.On(DECISION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
                    this.Save().then(() => this.ShowFunctionDesigner(evtArgs.data.PackageId, evtArgs.data.FunctionId));
                });
                decisionDialog.On(DECISION_DIALOG_EVENTS.DISCARD_SELECTED, this, () => {
                    this.ShowFunctionDesigner(evtArgs.data.PackageId, evtArgs.data.FunctionId);
                });
                decisionDialog.Show();
            } else {
                this.ShowFunctionDesigner(evtArgs.data.PackageId, evtArgs.data.FunctionId);
            }
        });
    }

    private ShowFunctionDesigner(packageId: number, functionId: number) {
        const controlModel = new ControlModel();
        controlModel.TypeName = CONTROL_TYPES.FunctionDesigner;

        const functionDesigner = new FunctionDesigner({
            Model: controlModel,
            Form: null,
            RenderMode: RenderModes.View
        });
        functionDesigner.GetPackage(packageId).then(()=>functionDesigner.SelectMethodById(functionId));
        functionDesigner.HideButtonContainer();
        functionDesigner._functionSelected(true);

        const modal = new Modal({});

        ko.cleanNode(modal.Wrapper);
        ko.applyBindings(functionDesigner, modal.Wrapper);

        modal.Show();

        modal.One("CLOSE", this, (eventArgs: any) => {
            modal.Close();
            BlockUI.Block();
            this.LoadScreenFor(this._recordId);
        });
    }

    get HasData() {
        return this._hasData;
    }

    get Canvas() {
        return this._canvas;
    }

    Save(): P.Promise<any> {

        if(this._isForLifeStatusDesigner()){
            this.Trigger('SAVE', { dwPackage: this._dataModel });
            this._modal.Close();
            return;
        }

        BlockUI.Block();
        let deferredResult = P.defer<any>();
        CanvasDesignerStore.SaveCanvas(this._dataModel)
            .then((result) => {
                new Notifier().Success(NOTIFICATIONS.CANVAS_UPDATED);
                this.LoadScreenFor(this._recordId);
                deferredResult.resolve(result);
            })
            .fail(err => {
                BlockUI.Unblock();
                new Notifier().Failed(err.message);
                deferredResult.reject({message: err.message});
            });

        return deferredResult.promise();
    }

    ShowFunctionList() {
        BlockUI.Block();
        EntityTypesStore.GetTypes({
            EntityId: this._metadata.EntityId,
            ParentTypeId: 0,
            WithRoot: false,
            OnlyEnabled: true
        })
        .always(()=>{
            BlockUI.Unblock();
        })
            .then((result) => {
                let apiPackageType = _.find(result.TableTypes, (item) => item.Name === FUNCTION_TYPES.API_PACKAGE);
                if (apiPackageType) {
                    const searchScreen = new SearchScreen({
                        EntityName: 'CD_CANVASES',
                        SearchTerm: this._searchTerm(),
                        IsRootForFormDesigner: true,
                        ButtonAdd: false,
                        SearchByTypes: [apiPackageType.Id]
                    });

                    searchScreen.On(SEARCH_SCREEN_EVENTS.RECORD_SELECTED, this, (eventArgs) => {
                        if (eventArgs.data.TypeName === FUNCTION_TYPES.API_PACKAGE) {
                            this.ShowMethodList(eventArgs.data.RecordId);
                        } else {
                            new Notifier().Warning(`${eventArgs.data.TypeName} is not allowed`);
                        }
                    });

                    searchScreen.Show();
                }
            });
    }

    ShowMethodList(apiPackageId: number) {
        CanvasDesignerStore.GetMethods(apiPackageId)
            .then((result: Array<CanvasItemModel>) => {
                let methodListModal = new MethodsList(result);
                methodListModal.On(EVENTS.METHOD_SELECTED, this, (evtArgs) => {
                    this.AddFunction(evtArgs.data.Id);
                    methodListModal.Close();
                });


                methodListModal.ShowInModal();
            });
    }

    AddFunction(id: number) {
        CanvasDesignerStore.GetFunctionGroup(this._recordId, id)
            .then((methodGroup: CanvasModel) => {
                this._canvas.BuildShapes(methodGroup);
            });
    }

    AddConstant() {
        BlockUI.Block();
        CanvasDesignerStore
            .GetConstantGroup(this._recordId)
            .always(() => {
                BlockUI.Unblock();
            })
            .then((data) => {
                this._canvas.BuildShapes(data);
            })
            .fail(err => {
                new Notifier().Failed(err.message)
            });
    }

    Delete() {
        const confirmationDialog = new ConfirmationDialog({
            Text: CONFIRMATIONS.ARE_YOUR_SURE_TO_DELETE_RECORDS,
            Type: ConfirmationTypes.Question,
        });

        confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, eventArgs => {
            BlockUI.Block();
            CanvasDesignerStore.DeleteCanvas({Id: this._recordId})
                .always(() => {
                    BlockUI.Unblock();
                })
                .then(() => {
                    this.Trigger('ON_DELETE_CANVAS', eventArgs.data);
                    new Notifier().Success(NOTIFICATIONS.RECORD_REMOVED);
                    PubSub.publish('GO_TO_CANVAS_DESIGNER', null);
                })
                .fail(err => new Notifier().Failed(err.message));
        });

        confirmationDialog.Show();
    }

    ZoomInCanvas() {
        //this.Canvas.paperScroller.zoom(0.2, { max: 5, grid: 0.2 });
    }

    ZoomOutCanvas() {
        //this.Canvas.paperScroller.zoom(-0.2, { min: 0.2, grid: 0.2 });
    }

    EnableSocketsProtocolValidation() {
        this.validationIsActive(!this.validationIsActive());
        //this.Canvas.enableSocketsProtocolValidation();
    }

    CheckForChanges() {
        const confirmationDialog = new ConfirmationDialog({
            Text: CONFIRMATIONS.CANVAS_DATA_HAS_BEEN_CHANGED,
            Type: ConfirmationTypes.Question
        });

        confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, eventArgs => {
            this.TriggerExecution();
        });

        if (this.HasChanges()) {
            confirmationDialog.Show();
        } else {
            this.TriggerExecution();
        }
    }

    Execute() {
        this.CheckForChanges();
    }

    TriggerExecution() {
        let modalHeight = this._debugMode() ? 800 : 100;
        let modal = new Modal(
            {
                closeButton: this._debugMode() ? 'box' : null,
                height: `'${modalHeight}'`,
                closeOnEsc: this._debugMode()
            }, false );            
        modal.On('CLOSE', this, ()=> SignalRNotificationManager.Instance.SetDebugEditor(null));

        let content = `<div class='row'>
                            <div style='text-align: center; background-color: white; width: 800px;' id='dw-progress'>
                                <h2>Execution...</h2>
                            </div>
                        </div>`;

        if(this._debugMode()){
            content +=`<div class='row'>
                <div id='dw-debugger' style='height: 500px; margin-right: 5px;'></div>
            </div>`;
        }

        modal.SetContent($(content));
        modal.Show();

		var editor = ace.edit(document.getElementById('dw-debugger'));
        SignalRNotificationManager.Instance.SetDebugEditor(editor);

        CanvasDesignerStore
            .Execute({Id: this._recordId})
                .then(() => {
                    const notifier = new Notifier();
                    notifier.Success(NOTIFICATIONS.DATAWIZARD_EXECUTED);
                })
                .fail(err => {
                    const notifier = new Notifier();
                    notifier.Failed(err.message);
                })
                .always(() => {
                    this.CheckHasRollback();
                    if(!this._debugMode()){
                        modal.Close();
                    }                    
               });
    }

    get CanvasRendered() {
        return this._canvasRendered;
    }

    SetRecordId(id) {
        this._recordId = id;
    }

    Search(): void {
        BlockUI.Block();        
        EntityTypesStore.GetTypes({
            EntityId: this._metadata.EntityId,
            ParentTypeId: 0,
            WithRoot: false,
            OnlyEnabled: true
        })
        .always(()=>{
            BlockUI.Unblock();
        })
            .then((result) => {

                let dwPackage = _.find(result.TableTypes, (item) => item.Name === CANVAS_TYPES.DW_PACKAGE);
                if (dwPackage) {

                    const searchScreen = new SearchScreen({
                        EntityId: this._metadata.EntityId,
                        SearchTerm: this._searchTerm(),
                        IsRootForFormDesigner: true,
                        SearchByTypes: [dwPackage.Id]
                    });

                    searchScreen.On('RECORD_SELECTED', this, (eventArgs) => {
                        if (eventArgs.data.TypeName === CANVAS_TYPES.DW_PACKAGE) {
                            this._canvasRendered(false);
                            this._recordId = eventArgs.data.RecordId;
                            BlockUI.Block();
                            CanvasDesignerStore.GetCanvas({Id: this._recordId})
                                .then((response) => {
                                    this._dataModel = response;
                                    this._ignoreMissingSubKeys(this.GetProperties().IgnoreMissingSubKey);
                                    this._debugMode(this.GetProperties().DebugMode);
                                    this._canvasRendered(true);
                                    this.InitCanvas();
                                    this.CheckHasRollback();
                                })
                                .fail(err => {
                                    BlockUI.Unblock();
                                    new Notifier().Failed(err.message)
                                });
                        } else {
                            new Notifier().Warning(`${eventArgs.data.TypeName} is not allowed`);
                        }
                    });

                    searchScreen.On('NEW_RECORD',
                        this,
                        () => {
                            this._canvasRendered(false);
                            let typeScreen = new TypeScreen(this._metadata.EntityId, 0, true);
                            typeScreen.On("TYPES_NOT_FOUND", this, (eventArgs) => {
								new Notifier().Warning(eventArgs.data.Message || NOTIFICATIONS.TYPES_NOT_FOUND);
                            });
                            typeScreen.On('TYPE_SELECTED', this, (eventArgs) => {
                                if (eventArgs.data.TypeName === CANVAS_TYPES.DW_PACKAGE) {
                                    const typeId = eventArgs.data.TypeId;
                                    const kindId = eventArgs.data.KindId;
                                    const exampleRecordId = eventArgs.data.ExampleRecordId;
                                    this.NewRecord(typeId, kindId, exampleRecordId);
                                } else {
                                    new Notifier().Warning(`${eventArgs.data.TypeName} is not allowed`);
                                }
                            });
                            this.On('CANVAS_CREATED', this, () => {
                                searchScreen.Cancel();
                            });
                            typeScreen.Show();
                        });
                    searchScreen.Show();
                }
            });
    }


    LoadScreenFor(recordId: number, resetCache: boolean = false) {
        this._isDataLoading(true);
        CanvasDesignerStore.GetCanvas({Id: recordId, ResetCache: resetCache})
            .always(()=>this._isDataLoading(false))
            .then((response) => {
                this._dataModel = response;
                this._ignoreMissingSubKeys(this.GetProperties().IgnoreMissingSubKey);
                this._debugMode(this.GetProperties().DebugMode);
                this._canvasRendered(true);
                this.InitCanvas();
            }).fail(err => new Notifier().Failed(err.message));
    }


    NewRecord(tableTypeId: number, kinId: number, exampleRecordId: number) {
        BlockUI.Block();
        ScreenManager.GetEditScreen({
            EntityId: this._metadata.EntityId,
            TableTypeId: tableTypeId,
            KindId: kinId,
            RecordId: exampleRecordId,
            LoadAsExample: exampleRecordId > 0
        })
            .always(() => {
                BlockUI.Unblock();
            })
            .then((screen: EditScreen) => {
                const editScreen = screen;
                editScreen.IsDataFromExample = exampleRecordId > 0;
                editScreen.ParentRecordId = exampleRecordId;
                editScreen.UseLinking = true;

                screen.On('RECORD_SAVED', this, (eventArgs) => {
                    this.Trigger('CANVAS_CREATED');
                    const notifier = new Notifier($(this._el));
                    notifier.Success(NOTIFICATIONS.RECORD_CREATED);
                    this.LoadScreenFor(eventArgs.data.RecordId);
                    this._recordId = eventArgs.data.RecordId;
                    UserVarsManager.Instance.AddRecent(this._metadata.EntityId, eventArgs.data.RecordId, tableTypeId);
                });

                screen.On('RECORD_REMOVED', this, (eventArgs) => {
                    const notifier = new Notifier($(this._el));
                    notifier.Success(NOTIFICATIONS.RECORD_REMOVED);
                    this.LoadScreenFor(null);
                });

                screen.ShowInModal();
            }).fail(error => {
            new Notifier($(this._el)).Warning(error.message);
        });
    }

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);
    }

    private HasChanges() {
        const hasNewItem = _.find(this._dataModel.CanvasItems, (item: CanvasItemModel) => {
            return item.State !== CanvasModelState.NoChanges
        });

        if (hasNewItem) {
            return true;
        }

        const hasNewLink = _.find(this._dataModel.CanvasLinks, (item: CanvasLinkModel) => {
            return item.State !== CanvasModelState.NoChanges
        });

        return hasNewLink;
    }

    ExecuteRollback(){
	
        let modalHeight = this._debugMode() ? 800 : 100;
        let modal = new Modal(
            {
                closeButton: this._debugMode() ? 'box' : null,
                height: `'${modalHeight}'`,
                closeOnEsc: this._debugMode()
            }, false );            
        modal.On('CLOSE', this, ()=> SignalRNotificationManager.Instance.SetDebugEditor(null));

        let content = `<div class='row'>
                            <div style='text-align: center; background-color: white; width: 800px;' id='dw-progress'>
                                <h2>Rolling back...</h2>
                            </div>
                        </div>`;

        if(this._debugMode()){
            content +=`<div class='row'>
                <div id='dw-debugger' style='height: 500px; margin-right: 5px;'></div>
            </div>`;
        }

        modal.SetContent($(content));
        modal.Show();

		var editor = ace.edit(document.getElementById('dw-debugger'));
        SignalRNotificationManager.Instance.SetDebugEditor(editor);
    
        BlockUI.Block();
		CanvasDesignerStore
			.ExecuteRollback(this._recordId)
            .then(()=>{

                if(!this._debugMode()){
                    modal.Close();
                }    

                new Notifier($(this._el)).Success(NOTIFICATIONS.ROLLBACK_APPLIED);
                this.CheckHasRollback();
            })
			.always(() => {
				BlockUI.Unblock();
			}).fail(error => {
                new Notifier($(this._el)).Warning(error.message);
            });
	}

    CheckHasRollback(){
        if(this._isForLifeStatusDesigner()){
            return;
        }
		BlockUI.Block();
		CanvasDesignerStore
			.HasRollback(this._recordId)
			.always(() => {
				BlockUI.Unblock();
			}).then((res)=>{
                this._showRollbackButton(this._canvas.DestinationDataType === DataTypes.Cyberbase);
                this._hasRollback(res);
            })
            .fail(error => {
                new Notifier($(this._el)).Warning(error.message);
            });
	}

    async BuildLifeStatusTriggerFlow(lifeStatusName: string){
        this._isForLifeStatusDesigner(true);
        let canvas = await CanvasDesignerStore.BuildLifeStatusTriggerFlow(lifeStatusName);
        this._dataModel = canvas;
        this._canvasRendered(true);
        this.InitCanvas();
        this.CheckHasRollback();
    }

    set IsForLifeStatusDesigner(value: boolean){
        this._isForLifeStatusDesigner(value);
    }

    SetDataModel(canvas: CanvasModel){
        this._dataModel = canvas;
        this._canvasRendered(true);
        this.InitCanvas();
        this.CheckHasRollback();
    }
}