import { dia as Dia, shapes, V, g } from 'libs/rappid/build/rappid';
import * as joint from 'libs/rappid/build/rappid';
import 'Core/Controls/CanvasDesigner/Shapes/Models/Table';
import { EVENTS } from "./ParamView/Events";
import { LABELS } from 'Core/Components/Translation/Locales';

const _labels = LABELS;


declare module 'libs/rappid/build/rappid' {
	namespace shapes {
		namespace cyberThing {
			class GroupView extends Dia.ElementView { }
		}
	}
}

const getTemplate = () => {
	return V('<g class="rotatable"><text class="shape-label"/><g class="scalable"><rect/></g></g>');
}

joint.shapes.cyberThing.GroupView = Dia.ElementView.extend({

	initialize: function () {
		joint.dia.ElementView.prototype.initialize.apply(this, arguments);

		if(this.model.attributes.position.x === 0){
			this.model.attributes.position.x = 600;
		}

		if(this.model.attributes.position.y === 0){
			this.model.attributes.position.y = 100;
		}

		this.model.position(this.model.attributes.position.x, this.model.attributes.position.y);

		this.model.attr('./visibility', 'hidden');
		this.model.get('attrs')['.shape-label']['ref-y'] = 0;
		this.model.get('attrs')['.shape-label'].refY2 = 10;

		this.template = getTemplate();

		this.bindEvents();
	},

	renderMarkup: function () {
		this.vel.append(this.template);
	},

	bindEvents: function () {

		let functionDesignerButtons = this.template.find('#showFunctionDesigner');
		_.each(functionDesignerButtons, (button: any) => {
			if (button && button.node) {
				$(button.node).on('click', () => {
					const temp = this.model.attributes.apiPackageId;
					this.model.trigger(EVENTS.SHOW_FUNCTION_DESIGNER, this.model.attributes.apiPackageId);
				});
			}
		});
	},

	render: function () {

		joint.dia.ElementView.prototype.render.apply(this, arguments);

		this.renderTools();
		this.update();

		return this;
	},

	renderTools: function () {

		var toolMarkup = this.model.toolMarkup || this.model.get('toolMarkup');

		if (toolMarkup) {

			var nodes = V(toolMarkup);
			V(this.el).append(nodes);

		}

		return this;
	},

});