import * as ko from "knockout";
import * as _ from "underscore"

import { IPortletProperties } from "Core/Controls/PortletBar/Interfaces/IPortletBarProperties"

import { DefaultPortletProps } from "Core/Controls/PortletBar/Defaults/DefaultPortletProps"

import { LABELS } from "Core/Components/Translation/Locales";
import { ButtonsFactory } from "Core/Controls/PortletBar/Utils/ButtonsFactory";
import { PortletBarButton } from "Core/Controls/PortletBar/Managers/Buttons/PortletBarButton";
import { IPortletRuntimeSettings } from "Core/Controls/PortletBar/Interfaces/IPortletRuntimeSettings";
import { IControlParam } from "Core/Screens/IScreen";
import { RenderModes, EVENTS } from "Core/Constant";
import { GeneralProperties } from "Core/GeneralProperties/GeneralProperties";
import { BaseControl } from "Core/Controls/BaseControl/BaseControl";

import { Icon } from "Core/Icon/Icon";
import {GlobalManager, GLOBALS} from "../../GlobalManager/GlobalManager";

import PortletBarConfig from "Core/Controls/PortletBar/Configs/portlet-bar-config.json";

import PortletBarTemplate from 'Core/Controls/PortletBar/Templates/View.html';
import PortletBarDesign from 'Core/Controls/PortletBar/Templates/Design.html';
import PortletBarToolBar from 'Core/Controls/PortletBar/Templates/ToolBar.html';

ko.templates["Core/Controls/PortletBar/Templates/View"] = PortletBarTemplate;
ko.templates["Core/Controls/PortletBar/Templates/Design"] = PortletBarDesign;
ko.templates["Core/Controls/PortletBar/Templates/ToolBar"] = PortletBarToolBar;

export class PortletBar extends BaseControl {
	private _config: any;
	_labels = LABELS;
	ObjectId: number;
	PortletIcon: Icon;
	Buttons: KnockoutObservableArray<PortletBarButton>;
	RuntimeSettings: KnockoutObservable<IPortletRuntimeSettings>;
	IsReady: KnockoutObservable<boolean>;
	constructor(params: IControlParam) {
		super(params, PortletBarConfig);

		this.Buttons = ko.observableArray([]);

		this.Init();
		this.RuntimeSettings = ko.observable(this.ExtendRuntimeSettings(null));

		this.BindEvents();
	}

	protected ApplyProperties() {}

	get ConfigProperties(): IPortletProperties {
		return this._config
			? this.GetConfigSettings()
			: new DefaultPortletProps();
	}

	get HeaderText() {
		const desktopLanguage = GlobalManager.Instance.GetGlobal(GLOBALS.DESKTOP_LANGUAGE);
		let portletControlName = this._model().Name;

		_.each(this.NameTranslations, item => {
			if (item.Translation && item.Translation !== '' && item.Translation !== null &&
				item.Language && item.Language.ShortName === desktopLanguage) {
				portletControlName = item.Translation;
				return;
			}
		});

		return portletControlName || this.EntityTranslatedName || this.EntityName;
	}

	get EntityName(): string {
		return this._form.GetScreen().GetScreenName();
	}

    get EntityTranslatedName(): string {
        return this._form.GetScreen().GetScreenTranslatedName();
    }
	AfterRender(el: Array<HTMLElement>): void {
		super.AfterRender(el);
	}

	ApplyRuntimeSettings(runtimeSettings: IPortletRuntimeSettings) {
		let self = this;
		self.RuntimeSettings = ko.observable(self.ExtendRuntimeSettings(runtimeSettings));
		self.Buttons().forEach(button => button.ViewModel.Properties().Visible(self.RuntimeSettings()[button.ViewModel.Name]()));
		this.Trigger(EVENTS.PORTLETS.RUNTIME_SETTINGS_APPLIED, self.RuntimeSettings());
	}

	SaveSettings() {
		this.ApplyRuntimeSettings(this.RuntimeSettings());
		this.Trigger(EVENTS.PORTLETS.RUNTIME_SETTINGS_UPDATED, this.RuntimeSettings());
	}

	private Init() {
		this.AddEvent(EVENTS.PORTLETS.PORTLET_BAR.CONTROL_BUTTONS.COLLAPSE_BUTTON_CLICKED);
		this.AddEvent(EVENTS.PORTLETS.PORTLET_BAR.CONTROL_BUTTONS.EXPAND_BUTTON_CLICKED);
		this.AddEvent(EVENTS.PORTLETS.PORTLET_BAR.CONTROL_BUTTONS.RELOAD_BUTTON_CLICKED);
		this.AddEvent(EVENTS.PORTLETS.PORTLET_BAR.CONTROL_BUTTONS.REMOVE_BUTTON_CLICKED);
		this.AddEvent(EVENTS.PORTLETS.PORTLET_BAR.CONTROL_BUTTONS.SETTINGS_BUTTON_CLICKED);
		this.AddEvent(EVENTS.PORTLETS.RUNTIME_SETTINGS_APPLIED);
		this.AddEvent(EVENTS.PORTLETS.RUNTIME_SETTINGS_UPDATED);

		this._config = this._model().Properties;

		if (this._renderMode() !== RenderModes.ToolBar) {
			this.PortletIcon = this._form.GetScreen().GetScreenIcon();
		}

		this.Buttons(this.GetButtonsFromConfig());
	}

	private BindEvents() {
		if (this._renderMode() !== RenderModes.Design) {
			let settingsButton = this.GetButton("SettingsButton");
			if (settingsButton) {
				settingsButton.On(EVENTS.PORTLETS.RUNTIME_SETTINGS_UPDATED, this, eventArgs => this.SaveSettings());
			}
		} else {
			this._model.subscribe(() => this.Init());
		}
	}

	private BindButtonClickEvent(button: PortletBarButton) {
		button.On(button.OnClickEvent(), this, () => {
			this.Trigger(button.OnClickEvent());
		});
	}

	private ExtendRuntimeSettings(runtimeSettings: IPortletRuntimeSettings) {
		let self = this;

		if (!runtimeSettings) {
			return self.GetConfigSettings();
		}

		runtimeSettings.Length = runtimeSettings.Length || DefaultPortletProps.Props().Length;
		runtimeSettings.CollapseButton = runtimeSettings.CollapseButton || DefaultPortletProps.Props().CollapseButton;
		runtimeSettings.ExpandButton = runtimeSettings.ExpandButton || DefaultPortletProps.Props().ExpandButton;

		runtimeSettings.ReadOnly(self.IsReadOnly());
		runtimeSettings.Search(self.IsSearchEnabled());
		runtimeSettings.PortletColor(self.GetPortletColor());
		runtimeSettings.DataSpaceColor(self.GetDataSpaceColor());
		runtimeSettings.Kind(self.GetKind());
		runtimeSettings.UseBodySpace(self.UseBodySpace());

		runtimeSettings.ReloadButton(self.IsButtonVisible("ReloadButton"));
		runtimeSettings.RemoveButton(self.IsButtonVisible("RemoveButton"));
		runtimeSettings.SettingsButton(self.IsButtonVisible("SettingsButton"));

		return runtimeSettings;
	}

	private GetConfigSettings(): IPortletRuntimeSettings {
		let settings: IPortletRuntimeSettings = {
			ReadOnly: ko.observable(this.IsReadOnly()),
			Search: ko.observable(this.IsSearchEnabled()),
			PortletColor: ko.observable(this.GetPortletColor()),
			UsePortletBarColor: ko.observable(this.UsePortletBarColor()),
			DataSpaceColor: ko.observable(this.GetDataSpaceColor()),
			Kind: ko.observable(this.GetKind()),
			HeaderText: ko.observable(this.GetHeaderText()),
			CollapseButton: ko.observable(this.IsButtonVisible("CollapseButton")),
			ExpandButton: ko.observable(this.IsButtonVisible("ExpandButton")),
			SettingsButton: ko.observable(this.IsButtonVisible("SettingsButton")),
			RemoveButton: ko.observable(this.IsButtonVisible("RemoveButton")),
			ReloadButton: ko.observable(this.IsButtonVisible("ReloadButton")),
			Length: ko.observable(this.GetLengthFromConfig()),
			UseBodySpace: ko.observable(this.UseBodySpace())
		};
		return settings;
	}

	private GetButton(buttonName: string) {
		let buttons = this.Buttons().filter(button => button.ViewModel.Name === buttonName);
		return buttons.length > 0 ? buttons[0] : null;
	}

	private IsButtonVisible(buttonName: string) {
		let buttonViewModels = this.Buttons().filter(button => button.ViewModel.Name === buttonName).map(button => button.ViewModel);

		let buttonViewModel = _.find(buttonViewModels, { Name: buttonName });

		return buttonViewModel ? buttonViewModel.Properties().Visible() : false;
	}

	private IsReadOnly(): boolean {
		if (!this._config) {
			return DefaultPortletProps.Props().ReadOnly();
		}

		let config = this._config.ReadOnly;

		if (!config) {
			return DefaultPortletProps.Props().ReadOnly();
		}

		return config.Properties[0].ReadOnly;
	}

	private IsSearchEnabled(): boolean {
		if (!this._config) {
			return DefaultPortletProps.Props().Search();
		}

		let config = this._config.Search;

		if (!config) {
			return DefaultPortletProps.Props().Search();
		}

		return config.Properties[0].Search;
	}

	private GetPortletColor(): string {
		if (!this._config) {
			return DefaultPortletProps.Props().PortletColor();
		}

		let config = this._config.PortletColor;

		if (!config || config.Properties[0].PortletColor === "") {
			return DefaultPortletProps.Props().PortletColor();
		}

		return config.Properties[0].PortletColor;
	}

	private UsePortletBarColor() {
		if (!this._config) {
			return DefaultPortletProps.Props().UsePortletBarColor();
		}

		let config = this._config.UsePortletBarColor;

		if (!config) {
			return DefaultPortletProps.Props().UsePortletBarColor();
		}

		return config.Properties[0].UsePortletBarColor;
	}

	private GetDataSpaceColor(): string {
		if (!this._config) {
			return DefaultPortletProps.Props().DataSpaceColor();
		}

		let config = this._config.UsePortletBarColor;

		if (!config || !config.Properties[0].UsePortletBarColor) {
			return DefaultPortletProps.Props().DataSpaceColor();
		}

		return this.GetPortletColor();
	}

	private UseBodySpace() {
		return this.GetPortletColor() !== this.GetDataSpaceColor();
	}

	private GetKind(): string {
		return "boxed";
	}

	private GetHeaderText(): string {
		if (!this._config) {
			return DefaultPortletProps.Props().HeaderText();
		}

		const config = this._config.Header;

		if (!config) {
			return DefaultPortletProps.Props().HeaderText();
		}

		return config.Properties[0].HeaderText;
	}

	private GetButtonsFromConfig(): Array<PortletBarButton> {
		if (!this._config) {
			return [];
		}
		let config = this._config.PortletBarButtons;

		if (!config) {
			return [];
		}

		let buttons: Array<PortletBarButton> = config.Properties.map(prop => {
			let name = Object.keys(prop)[1],
				visible = prop[name];

			let button = ButtonsFactory.CreateButton(name, {
				Visible: ko.observable(visible)
			});

			this.BindButtonClickEvent(button);

			return button;
		});

		return buttons;
	}

	private GetLengthFromConfig(): number {
		if (!this._config) {
			return DefaultPortletProps.Props().Length();
		}

		let config = this._config.Length;

		if (!config || config.Properties[0].Length === "") {
			return DefaultPortletProps.Props().Length();
		}

		return config.Properties[0].Length;
	}

	GetValue(): any {
		throw new Error('GetValue not implemented');
	}

	Deserialize(): any {
		return null;
	}
}
