import * as ko from 'knockout'
import * as _  from 'underscore'
import 'jquery';

import {ConcreteEntityModel} from 'QueryBuilder/Models/ConcreteEntityModel'
import {EntityModel} from 'QueryBuilder/Models/EntityModel'
import { DesignScreen } from 'Core/Screens/DesignScreen/DesignScreen';
import {LABELS} from "Core/Components/Translation/Locales";

import EntityToolBarTemplate from 'Core/Controls/FormDesigner/ToolBar/EntityToolBar/Templates/EntityToolBar.html'
import { ScreenStore } from 'Core/ScreenManager/Stores/ScreenStore';
ko.templates['Core/Controls/FormDesigner/ToolBar/EntityToolBar/Templates/EntityToolBar'] = EntityToolBarTemplate;

export class EntityToolBar {
	private _model: KnockoutObservable<ConcreteEntityModel>;
	private _expanded: KnockoutObservable<boolean>;
	private _expandedEntity: KnockoutObservable<EntityModel>;
	private _el: HTMLElement;
	private _scrollValue: KnockoutObservable<number>;
	private _screen: DesignScreen;
	private _isLoading: KnockoutObservable<boolean>;

	IsOpenFromDatabaseDesigner: KnockoutObservable<boolean>;

	private _labels: LABELS = LABELS;

	constructor() {
		this._isLoading = ko.observable(false);
		this._model = ko.observable(null);
		this._expanded = ko.observable(false);
		this._expandedEntity = ko.observable(null);
		this._scrollValue = ko.observable(0);
		this._expanded.subscribe(newValue => {
			if (newValue) {
				$(this._el).fadeIn(400);
			} else {
				
			}
		});

		this.IsOpenFromDatabaseDesigner = ko.observable(false);
	}

	get Model(): KnockoutObservable<ConcreteEntityModel> {
		return this._model;
	}

	set Expanded(isExpanded: boolean) {
		this._expanded(isExpanded);
	}

	get Expanded(): boolean {
		return this._expanded();
	}

	Init(entity: ConcreteEntityModel, screen: DesignScreen, isOpenFromDatabaseDesigner: boolean) {
		this._model(entity);
		this._screen = screen;
		this._expandedEntity(this._model().MainEntity);
		this._expanded(true);
		this.IsOpenFromDatabaseDesigner(isOpenFromDatabaseDesigner);
	}

	LoadData(){
		ScreenStore.GetSubjectEntity(this._screen.ScreenId)
		.always(()=>this._isLoading(false))
		.then((subjectEntity)=>{
			this._model(subjectEntity);
			this._screen.SubjectEntity = subjectEntity;
			this._expandedEntity(this._model().MainEntity);
			this._expanded(true);
		});
	}

	GetTemplateName() {
		return 'Core/Controls/FormDesigner/ToolBar/EntityToolBar/Templates/EntityToolBar';
	}

	AfterRender(el: Array<HTMLElement>): void {
		this._el = el[0];
	}

	async EditTable(table: EntityModel){
		let databaseDesigner = (await import ('DatabaseDesigner/DatabaseDesigner')).DatabaseDesigner;
		(databaseDesigner as any).dataBaseId = table.DbId;		
		(databaseDesigner as any).InitUI();
		(databaseDesigner as any).GetData(()=>{
			(databaseDesigner as any).ShowTableDesigner(this._model().MainEntity.EntityId, table.EntityId)
			.then(()=> {
				this._isLoading(true);
				(databaseDesigner as any).SaveData(false).then(()=>{
					(databaseDesigner as any).ExecuteDbDifferenceScript(()=> { 
						this.LoadData()
					});	
				});
			});
		});
	}

	EntityClick(model, evt) {
		var parentContainer = evt.currentTarget;
		var prevSiblings = 0;
		prevSiblings = $(parentContainer).prevAll().length;
		var staticHeight = 43;
		var scrollValue = prevSiblings * staticHeight + 20;
		this._scrollValue(scrollValue);

		if (this._expandedEntity() === model) {
			this._expandedEntity(null);
		} else {
			this._expandedEntity(model);
		}
		
	}
}