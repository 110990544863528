import {deserialize, deserializeAs, serialize} from 'libs/cerialize';
import {FieldMetadataModel} from 'Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel';

export class EntityMetadataModel {

	constructor() {
		this.Fields = [];
	}

	@serialize
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	ShortName: string;

	@deserialize
	NameTranslation: string;

	@deserialize
	Type: string;

	@deserialize
	IsView: boolean;

	@deserialize
	Lifestatus: string;

	@deserializeAs(FieldMetadataModel)
	Fields: Array<FieldMetadataModel>;
}