import * as ko from 'knockout';
import { Notifier } from 'Core/Common/Notifier';

import { Modal } from 'Core/Common/Modal';

import { P } from 'Core/Common/Promise';

import {EventBus} from "Core/Common/EventBus/EventBus";
import {EventBusConsumer} from "Core/Common/EventBus/EventBusConsumer";

import {NavigationStack} from './Stores/NavigationStack/NavigationStack';
import {NavigationStackEvents} from './Stores/NavigationStack/NavigationStackEvents';
import {NavigationItem, NavigationItemTypes} from './Stores/NavigationStack/NavigationItem';

import {ProductConfiguratorPage} from "./Pages/ProductConfiguratorPage";
import {IStartPageImageSizes, StartPage} from "./Pages/StartPage/StartPage";
import {IRootProductsPageImageSizes, RootProductsPage} from "./Pages/RootProducts/RootProductsPage";
import {ConfigurationPage, IConfigurationPageImageSizes} from "./Pages/ConfigurationPage/ConfigurationPage";
import {ConfigurationPageEvents} from "./Pages/ConfigurationPage/Events/ConfigurationPageEvents";

import {ProductDto} from "./Models/ProductDto";

import {Product as RootProduct} from './Pages/RootProducts/Models/Product';

import {RootProductsPageEvents} from "./Pages/RootProducts/Events/RootProductsPageEvents";
import {StartPageEvents} from "./Pages/StartPage/Events/StartPageEvents";

import {MobileChecker} from 'Core/Common/MobileChecker';
import {IScreen} from 'Core/Screens/IScreen';

import Template from 'Core/Components/Controls/ProductConfigurator/Templates/Template.html';
import { TabSecurityStore } from '../../../GeneralProperties/Managers/TabSecurity/Stores/TabSecurityStore';
import { UserManager } from '../../../../User/UserManager';
import { BusinessRoleDto } from '../../../GeneralProperties/Managers/TabSecurity/Stores/Models/BusinessRoleDto';
import { ConfigurationRole } from './Pages/ConfigurationPage/Models/ConfigurationRole';
import { ProductConfiguratorStore } from "./Stores/ProductConfiguratorStore";

import { BlockUI } from "Core/Common/BlockUi";

import {CONFIRMATIONS, NOTIFICATIONS} from "Core/Components/Translation/Locales";
import {ConfirmationDialog, EVENTS, Types} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import { ConfiguratorLevels } from 'Core/Components/Controls/ProductConfigurator/ConfiguratorLevels';
import {AlternativePriceModel} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/AlternativePriceModel';

ko.templates['Core/Components/Controls/ProductConfigurator/Templates/Template'] = Template;

export const PageName = {
    StartPage: 'StartPage',
    RootProductsPage: 'RootProductsPage',
    NewConfigurationPage: 'NewConfigurationPage',
    ConfiguredProductPage: 'ConfiguredProductPage'
}

export interface IProductConfiguratorParams {
    OrdersEntityId: number;
    ProductsEntityId: number;
    OrderId: number;
    ControlId: number;
    RootProductsViewId?: number;
    DescriptionFieldId?: number;
    Screen: IScreen;
    General: {
        ReloadScreen: boolean
    }
    HidePCFGROUPIfEmpty: {
        HiddenPCFGROUPSIfEmpty: number[]
    }
    ImageSizes: {
        ConfigurationPage: IConfigurationPageImageSizes,
        StartPage: IStartPageImageSizes,
        RootProductsPage: IRootProductsPageImageSizes
    }
    ConfigurationRoles: {
        Level1Value: any,
        Level2Value: any,
        LockLevel1: boolean
    }
    Annotations: {
        UseAnnotations: any,
        ShowAnnotationsModeByDefault: any
    }
    AlternativePrice: {
        EnableAlternativePriceCalculation: any,
        AlternativePriceCalculationRule: any
    }
}

export class ProductConfigurator extends EventBusConsumer {
    private _modal: Modal;
    private _store: ProductConfiguratorStore;
    private _navigationStack: NavigationStack;
    private _activePage: KnockoutObservable<ProductConfiguratorPage<any>>;
    private _userConfiguratorRoles: ConfigurationRole[];
    private CanUseConfigurator: KnockoutObservable<boolean>;
    private _pageNameProductConfigurator: KnockoutObservable<string>;
    private _alternativePriceModel: AlternativePriceModel;

    constructor(private _params: IProductConfiguratorParams) {
        super();

        const eventBus = new EventBus();

        this._navigationStack = new NavigationStack(eventBus);
        this._pageNameProductConfigurator = ko.observable(null);

        this._activePage = ko.observable(null);
        this._userConfiguratorRoles = [];

        this.AssignEventBus(eventBus);
        this.BindEvents();
        this.CanUseConfigurator = ko.observable(true);

        this._alternativePriceModel = this.MapToAlternativePriceModel();

        this._store = new ProductConfiguratorStore(this._params.OrdersEntityId, this._params.ProductsEntityId);

        if ((_params.ConfigurationRoles.Level1Value != null && _params.ConfigurationRoles.Level1Value.Value != null) ||
            (_params.ConfigurationRoles.Level2Value != null && _params.ConfigurationRoles.Level2Value.Value != null)) {
            var tabSecurityStore = new TabSecurityStore();

            var userId = UserManager.Instance.CurrentUser.Id;

            tabSecurityStore.GetUserBusinessRoles(userId)
                .then(roles => {

                    const userRoles = this.MapToRoles(roles);

                    userRoles.forEach(role => {
                        if (_params.ConfigurationRoles.Level1Value != null && role.Id == _params.ConfigurationRoles.Level1Value.Value){
                            role.Level = ConfiguratorLevels.Level1;
                            this._userConfiguratorRoles.push(role);
                        }
                        if (_params.ConfigurationRoles.Level2Value != null && role.Id == _params.ConfigurationRoles.Level2Value.Value){
                            role.Level = ConfiguratorLevels.Level2;
                            this._userConfiguratorRoles.push(role);
                        }
                    });
                    if (this._userConfiguratorRoles.length > 0) {
                        this._userConfiguratorRoles[0].Active(true);
                    } else {
                        this.CanUseConfigurator(false);
                    }

                    return P.resolve(null);
                });
        }
    }

    GetTemplateName() {
        return 'Core/Components/Controls/ProductConfigurator/Templates/Template';
    }

    AfterRender() {

    }

    ShowStartPage() {
        if (this.CanUseConfigurator()) {
            this._navigationStack.Flush();
            this.CloseActivePage();
            if (this._activePage() == null) {
                BlockUI.Block();
                this._store.CheckProductConfiguratorRelationType()
                    .then(data => {
                        this.SwitchToStartPage().then(() => {
                            this._pageNameProductConfigurator(PageName.StartPage);
                            this.ShowPopup();
                        });
					}).fail(err => {
						const parsedException = JSON.parse(err.message);
						if (parsedException && parsedException.InnerExceptions) {
							let index = 0;
							parsedException.InnerExceptions.forEach(ex => {
								let message = ex.Message + "<br>";
								ex.InnerExceptions.forEach(inner => {
									message += "<br>" + inner.Message;
								});
								setTimeout(() => new Notifier().Failed(message), 1000 * index++);
							});
						} else if (parsedException && parsedException.Message) {
							setTimeout(() => new Notifier().Failed(parsedException.Message), 0);
						}
                    }).always(() => BlockUI.Unblock());
            } else {
                this.SwitchToStartPage().then(() => {
                    this._pageNameProductConfigurator(PageName.StartPage);
                    this.ShowPopup();
                });
            }
        } else {
            let message = NOTIFICATIONS.YOU_DONT_HAVE_ANY_OF_REQUIRED_BUSINESS_ROLES;

            let roles = [];
            if (this._params.ConfigurationRoles.Level1Value.Value != null) {
                roles.push("&lt;" + this._params.ConfigurationRoles.Level1Value.Name + "&gt;");
            }
            if (this._params.ConfigurationRoles.Level2Value.Value != null) {
                roles.push("&lt;" + this._params.ConfigurationRoles.Level2Value.Name + "&gt;");
            }
            new Notifier().Warning(message + roles.join(', '));
        }
    }

    ShowRootProductsPage(product?: RootProduct, pageNumber?: number) {
        this.CloseActivePage();
        this.SwitchToRootProductsPage(product, pageNumber).then(() => {
            this._pageNameProductConfigurator(PageName.RootProductsPage);
            this.ShowPopup();
        });
    }

    ShowNewConfigurationPage(productId: number) {
        this.CloseActivePage();
        this.SwitchToNewConfigurationPage(productId).then(() => {
            this._pageNameProductConfigurator(PageName.NewConfigurationPage);
            this.ShowPopup();
        });
    }

    ShowConfiguredProductPage(productId: number, configurationId: number) {
        this.CloseActivePage();
        this.SwitchToConfiguredProductPage(productId, configurationId).then(() => {
            this._pageNameProductConfigurator(PageName.ConfiguredProductPage);
            this.ShowPopup();
        });
    }

    FlushNavigationStack() {
        this._navigationStack.Flush();
    }

    private BindEvents() {
        this.HandleEvent(StartPageEvents.ClosePopup)
            .Using(() => this.Close())
            .Always();

        this.HandleEvent(StartPageEvents.AddNewProduct)
            .Using(() => this.ShowRootProductsPage())
            .Always();

        this.HandleEvent<ProductDto>(StartPageEvents.ConfiguredProductSelected)
            .Using(eventArgs => this.ShowConfiguredProductPage(eventArgs.Data.Id, eventArgs.Data.ConfigurationId))
            .Always();

        this.HandleEvent<ProductDto>(RootProductsPageEvents.ConfigurableProductSelected)
            .Using(eventArgs => this.ShowNewConfigurationPage(eventArgs.Data.Id))
            .Always();

        this.HandleEvent(ConfigurationPageEvents.ConfigurationSaved)
            .Using(() => this.ShowStartPage())
            .Always();

        this.HandleEvent<NavigationItem[]>(NavigationStackEvents.Navigate)
            .Using(() => this.NavigateBack())
            .Always();

        this.HandleEvent(NavigationStackEvents.CheckPage)
            .Using(() => this.CheckPage())
            .Always();
    }

    private SwitchToStartPage() {
        const page = new StartPage({
            ProductsEntityId: this._params.ProductsEntityId,
            OrderEntityId: this._params.OrdersEntityId,
            OrderId: this._params.OrderId,
            ImageSizes: this._params.ImageSizes.StartPage,
            DescriptionFieldId: this._params.DescriptionFieldId,
            ConfigurationRoles: this._userConfiguratorRoles,
            ControlId: this._params.ControlId,
            Annotations: this._params.Annotations
        }, this._navigationStack, this.EventBus);

        this._activePage(page);
        return page.Refresh();
    }

    private SwitchToRootProductsPage(product: RootProduct, pageNumber?: number) {
        const page = new RootProductsPage({
            OrderEntityId: this._params.OrdersEntityId,
            ProductsEntityId: this._params.ProductsEntityId,
            RootProductsViewId: this._params.RootProductsViewId,
            OrderId: this._params.OrderId,
            ImageSizes: this._params.ImageSizes.RootProductsPage,
            ConfigurationRoles: this._userConfiguratorRoles
        }, this._navigationStack, this.EventBus);

        this._activePage(page);
        return page.Refresh(product, pageNumber);
    }

    private SwitchToNewConfigurationPage(productId: number) {
        const page = new ConfigurationPage({
            OrderEntityId: this._params.OrdersEntityId,
            ProductsEntityId: this._params.ProductsEntityId,
            OrderId: this._params.OrderId,
            ProductId: productId,
            ConfigurationId: null,
            HiddenPCFGROUPSIfEmpty: this._params.HidePCFGROUPIfEmpty.HiddenPCFGROUPSIfEmpty,
            ImageSizes: this._params.ImageSizes.ConfigurationPage,
            ConfigurationRoles: this._userConfiguratorRoles,
            LockLevel1: this._params.ConfigurationRoles.LockLevel1,
            AlternativePriceModel: this._alternativePriceModel
        }, this._navigationStack, this.EventBus);

        this._activePage(page);
        return page.ConfigureFromScratch();
    }

    private SwitchToConfiguredProductPage(productId: number, configurationId: number) {
        const page = new ConfigurationPage({
            OrderEntityId: this._params.OrdersEntityId,
            ProductsEntityId: this._params.ProductsEntityId,
            OrderId: this._params.OrderId,
            ProductId: productId,
            ConfigurationId: configurationId,
            HiddenPCFGROUPSIfEmpty: this._params.HidePCFGROUPIfEmpty.HiddenPCFGROUPSIfEmpty,
            ImageSizes: this._params.ImageSizes.ConfigurationPage,
            ConfigurationRoles: this._userConfiguratorRoles,
            LockLevel1: this._params.ConfigurationRoles.LockLevel1,
            AlternativePriceModel: this._alternativePriceModel
        }, this._navigationStack, this.EventBus);

        this._activePage(page);
        return page.AlterConfiguration();
    }

    private NavigateBack() {
        const navigationItem = this._navigationStack.Get(0);
        switch (navigationItem.Type) {
            case NavigationItemTypes.StartPage:
                this.ShowStartPage();
                break;
            case NavigationItemTypes.RootProducts:
                this.ShowRootProductsPage(navigationItem.Payload.Product, navigationItem.Payload.PageNumber);
                break;
            case NavigationItemTypes.NestedProducts:
                this.ShowRootProductsPage(navigationItem.Payload.Product, navigationItem.Payload.PageNumber);
                break;
        }
    }

    CloseModal(evt) {
        evt.preventDefault();
        if (this._modal) {
            if (this._pageNameProductConfigurator() === PageName.ConfiguredProductPage ||
                this._pageNameProductConfigurator() === PageName.NewConfigurationPage){
                const confirmationDialog = new ConfirmationDialog({
                    Text: CONFIRMATIONS.ALL_CHANGES_WILL_BE_LOST,
                    Type: Types.Question
                });

                confirmationDialog.On(EVENTS.CONFIRM_SELECTED, this, () => {
                    this.Close();
                });

                confirmationDialog.Show();
            } else {
                this._modal.Close();
                this.ReloadScreen();
            }
        }
    }

    private ShowPopup() {
        let isMobile = MobileChecker.IsMobile();
        let self = this;
        this._modal = new Modal({
            minWidth: isMobile ? '100%' : '0px',
            width: isMobile ? '100%' : '90vw',
            height:  isMobile ? '100%' : '90vh',
            addClass: isMobile ? 'mobile-padding configurator-modal startConfiguratorModal' : 'startConfiguratorModal',
            blockScroll: true,
            closeOnEsc: false,
            onOpen: function () {
                $(this.closeButton[0]).off('click');
                this.closeButton[0].addEventListener('click', self.CloseModal.bind(self));
            }
        }, false);

        this._modal.On('CLOSE', this, () => {
            this.DisposeActivePage();
            this._modal = null;
        });

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);

        this._modal.Show();
    }

    public Close() {
        if (this._modal){
            this.DisposeActivePage();
            this._modal.Close();
            this._modal = null;
            this.ReloadScreen();
        }
    }

    private CloseActivePage() {
        if (this._modal) {
            this._modal.Close({ignoreDelay: true});
        }
    }

    private DisposeActivePage() {
        if (this._activePage()) {
            this._activePage().Dispose();
            this._activePage(null);
        }
    }

    CheckPage() {
        if (this._activePage() instanceof ConfigurationPage) {
            this.DispatchEvent(NavigationStackEvents.CheckNavigatability);
        } else {
            this.DispatchEvent(NavigationStackEvents.Checked);
        }
    }

    private ReloadScreen() {
        if (this._params.General.ReloadScreen && this._params.Screen) {
            this._params.Screen.Refresh();
        }
    }

    private MapToRoles(rolesDto: BusinessRoleDto[]) {
        const roles = rolesDto.map(roleDto => new ConfigurationRole(roleDto.Id, roleDto.Name, roleDto.TranslatedName, ""));

        return roles;
    }

    private MapToAlternativePriceModel() {
        if (!this._params.AlternativePrice.EnableAlternativePriceCalculation) {
            return new AlternativePriceModel(false);
        }

        const calculationRule = this._params.AlternativePrice.AlternativePriceCalculationRule;

        const calculationRuleWithoutPrice = calculationRule && calculationRule.replaceAll("PRICE", "1");

        try {
            const isCalculationRuleValid = !!(calculationRuleWithoutPrice && !isNaN(eval(calculationRuleWithoutPrice)));
            return new AlternativePriceModel(true, calculationRule, isCalculationRuleValid);
        }
        catch {
            return new AlternativePriceModel(true, calculationRule, false);
        }
    }
}