import * as ko from "knockout";
import {BaseControl, IControlValue} from "Core/Controls/BaseControl/BaseControl";
import {SubForm} from "Core/Screens/Forms/SubForm/SubForm";
import {IControlParam} from "Core/Screens/IScreen";
import {BlockUI} from 'Core/Common/BlockUi';

import ViewTemplate from "Core/Controls/UserAllowance/Templates/View.html";
import EditTemplate from "Core/Controls/UserAllowance/Templates/Edit.html";
import ToolBarTemplate from "Core/Controls/UserAllowance/Templates/ToolBar.html";
import DesignTemplate from "Core/Controls/UserAllowance/Templates/Design.html";

import {UserAllowanceModel} from "Core/Controls/UserAllowance/Models/UserAllowanceModel";
import {UserAllowanceViewModel} from "Core/Controls/UserAllowance/Models/UserAllowanceViewModel";
import {UserAllowanceSerializedModel} from "Core/Controls/UserAllowance/Models/UserAllowanceSerializedModel";
import {UserAllowanceStore} from "Core/Controls/UserAllowance/Stores/UserAllowanceStore";


ko.templates["Core/Controls/UserAllowance/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/UserAllowance/Templates/Edit"] = EditTemplate;
ko.templates["Core/Controls/UserAllowance/Templates/ToolBar"] = ToolBarTemplate;
ko.templates["Core/Controls/UserAllowance/Templates/Design"] = DesignTemplate;

enum Statuses {
	Ok,
	Error
}

export class UserAllowance extends BaseControl {
	private _userProfileId: number;
	private _data: KnockoutObservable<UserAllowanceViewModel>;
	private _status: KnockoutObservable<Statuses>;

	constructor(params: IControlParam) {
		super(params);
		this._data = ko.observable(null);
		this._status = ko.observable(Statuses.Ok);
	}

	ApplyProperties(){}

	SetValue(value: IControlValue): void {
		this._userProfileId = value.SubjectRecordId;
		BlockUI.Block();
		UserAllowanceStore.GetUserAllowanceScreen(value.SubjectRecordId)
			.always(() => {
				BlockUI.Unblock();
			})
			.then(result => {
				if (!result.IsSuccessfull) {
					this._status(Statuses.Error);
					this._errorMessage(result.ErrorMessage);
					return;
				}

				let userAllowanceModel: UserAllowanceModel = result.ResultObject;
				let userAllowanceViewModel = new UserAllowanceViewModel(userAllowanceModel);

				this._data(userAllowanceViewModel);
				this._status(Statuses.Ok);
			});
	}

	AfterRender(el: Array<HTMLElement>): void {
		super.AfterRender(el);
	}

	Deserialize(): UserAllowanceSerializedModel {
		if (this._status() === Statuses.Error) {
			return null;
		}

		return new UserAllowanceSerializedModel(this._userProfileId, this._data());
	}
}