import * as ko from "knockout";

export interface IRecordExampleInfoParams {
	PossibleToExample: boolean;
	IsExample: boolean;
}

export class RecordExampleInfoModel {
	PossibleToExample: KnockoutObservable<boolean>;
	AlreadyExample: KnockoutObservable<boolean>;
	ExampleSelected: KnockoutObservable<boolean>;

	constructor(params?: IRecordExampleInfoParams) {
		let options = params || this.GetDefaultParams();
		this.PossibleToExample = ko.observable(options.PossibleToExample);
		this.AlreadyExample = ko.observable(options.IsExample);
		this.ExampleSelected = ko.observable(options.IsExample);
	}

	private GetDefaultParams(): IRecordExampleInfoParams {
		return {
			PossibleToExample: false,
			IsExample: false
		}
	}
}