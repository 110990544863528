import * as ko from 'knockout';

export class NewDEPRoleModel {
	FieldCollections: KnockoutObservableArray<FieldCollection>;
	SelectedFieldCollection: KnockoutObservable<FieldCollection>;

	constructor() {
		this.FieldCollections = ko.observableArray([]);
		this.SelectedFieldCollection = ko.observable(null);
	}
}

export class FieldCollection {
	Id: number;
	Name: string;
}