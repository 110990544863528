import * as ko from 'knockout';
import * as _ from 'underscore';

import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';

import {RecordStore} from 'Core/Common/Stores/RecordStore';

import {SearchScreen} from 'Core/Screens/SearchScreen/SearchScreen';

import {CustomFieldControl} from "../CustomFieldControl";
import {CustomLookupOptionModel} from './CustomLookupOptionModel';

import {ProductCustomFieldDescription} from "../../ProductCustomFieldDescription";
import {CustomFieldValue} from '../../../CustomFieldValue';
import {ProductPart} from '../../../ProductPart';

import Template from './Templates/CustomLookupControl.html';
import DropdownTemplate from './Templates/Dropdown.html';
import SearchTemplate from './Templates/Search.html';

export class CustomLookupControl extends CustomFieldControl {

    private _options: KnockoutObservableArray<CustomLookupOptionModel>;

    private _selectedOption: KnockoutObservable<CustomLookupOptionModel>;
    private _selectedOptionSubscription: KnockoutSubscription;
    private _selectedOptionId: KnockoutObservable<number>;
    private _selectedOptionLabel: KnockoutObservable<string>;
    private _initialOptionLabel: KnockoutObservable<string>;

    private _isCustomValueEntered: KnockoutObservable<boolean>;
    private _customPlaceholder: KnockoutObservable<string>;

	private _isReady: KnockoutObservable<boolean>;
    private _isDataLoading: KnockoutObservable<boolean>;

    private _searchScreen: SearchScreen;
    private _useSearch: KnockoutObservable<boolean>;
    private _allowCustomValue: boolean;
    private _dropdownPlugins: string[];
    private _defaultPlaceholder = `${this._labels.SELECT_LABEL}...`;

    private _actionExpressionValue: KnockoutObservable<number>;

    constructor(customField: ProductCustomFieldDescription, productPart: ProductPart) {
        super(customField, productPart);

        this._options = ko.observableArray([]);
		this._selectedOption = ko.observable(null);
        this._selectedOptionSubscription = null;
		this._selectedOptionId = ko.observable(null);
		this._selectedOptionLabel = ko.observable(null);
		this._initialOptionLabel = ko.observable(null);

		this._isCustomValueEntered = ko.observable(false);
		this._customPlaceholder = ko.observable(this._defaultPlaceholder);

		this._isReady = ko.observable(true);
		this._isDataLoading = ko.observable(true);
        this.HasContent = ko.observable(true);

        this._useSearch = ko.observable(false);
        this._allowCustomValue = customField.AllowCustomValue;

        this._actionExpressionValue = ko.observable(null);

        this.InitDropdownPlugins();
    }

    GetTemplate() {
        return Template;
    }

    GetDropdownTemplate() {
        return DropdownTemplate;
    }

    GetSearchTemplate() {
        return SearchTemplate;
    }

    private InitDropdownPlugins() {
        this._dropdownPlugins = [];

        if (!this._allowCustomValue) {
            this._dropdownPlugins.push('hidden_textfield', 'dropdown_direction');
        } else {
            this._dropdownPlugins.push('dropdown_direction');
        }
    }

    GetValueForSave(): CustomFieldValue {
        const selectedOptionId = this._selectedOption() ? this._selectedOption().Value : null;
        const selectedOptionLabel = this._selectedOption() ? this._selectedOption().Text : null;
		return new CustomFieldValue(this.Id, selectedOptionId, this.IsValid(selectedOptionLabel), selectedOptionLabel, Number(this._selectedOptionId()) === -1);
    }

	SetValue(customLookupValue: CustomFieldValue) {
		const optionId = customLookupValue && !Number.isNaN(customLookupValue.Value) ? Number(customLookupValue.Value) : null;
		const optionText = customLookupValue ? customLookupValue.Text : null;

		if (this._isDataLoading()) {
            this.DownloadOptions(optionText, optionId);
        } else if (this._useSearch) {
		    this.SetSelectedOption(new CustomLookupOptionModel(optionText, optionId))
        } else if (optionId === -1) {
            this.AddCustomOption(optionText);
        } else {
            this.SetSelectedOption(this._options().find(o => o.Value === optionId));
        }
	}

    SetDefaultValue() {
        const recordId = Number(this.DefaultValue());

        if (!recordId) {
            this.DownloadOptions(null, null);
            return;
        }

        RecordStore.GetRecord({TableName: this.customField.ValTable, RecordId: recordId})
            .always(() => BlockUI.Unblock())
            .then((record: any) => {
                const defaultOption = _.find(record.Fields, (item: any) => item.FieldName === this.customField.ValFieldName);

                if (defaultOption) {
                    this.SetValue(new CustomFieldValue(
                                                    this.Id,
                                                    recordId,
                                                    this.IsValid(defaultOption.FieldValue),
                                                    defaultOption.FieldValue));
                } else {
                    this.DownloadOptions(null, null);
                }
            })
            .fail(() => {
                this.DownloadOptions(null, null);
                new Notifier().Failed('Error applying default option');
            });
    }

    SetActionExpressionValue(recordId: number) {
        if (!recordId) {
            return;
        }

        this._actionExpressionValue(recordId);
        this.CheckAndUpdateActionExpressionValue();
    }

    private CheckAndUpdateActionExpressionValue() {
        if (!this._actionExpressionValue() || this._isDataLoading()) {
            return;
        }

        const recordId = this._actionExpressionValue();
        this._actionExpressionValue(null);

        if (this._selectedOptionId() && this._selectedOptionId() !== 0) {
            return;
        }

        if (!this._useSearch()) {
            this.SetSelectedOption(this._options().find(o => o.Value === recordId));
            return;
        }

        RecordStore.GetRecord({TableName: this.customField.ValTable, RecordId: recordId})
            .then((record: any) => {
                const defaultOption = _.find(record.Fields, (item: any) => item.FieldName === this.customField.ValFieldName);

                if (defaultOption) {
                    this.SetSelectedOption(new CustomLookupOptionModel(defaultOption.FieldValue, recordId))
                }
            })
            .fail(() => {
                this.ClearValue();
            });
    }

    IsValueCustom() {
	    return Number(this._selectedOptionId()) === -1;
    }

    ClearValue() {
		this._isReady(false);
		this._isCustomValueEntered(false);
		this.RemoveCustomOption();
		this.SetSelectedOption(null);
		this._isReady(true);
    }

    AddCustomValue(text: string) {
	    this.RemoveCustomOption();
	    this.AddCustomOption(text);

	    return { text: text, value: -1 };
    }

    OnSelectControl(recordId) {
	    const convertedRecordId = Number(recordId);

	    if (convertedRecordId !== -1) {
		    this.RemoveCustomOption();
	    }

	    const selectedItem = _.find(this._options(), item => item.Value === convertedRecordId);
	    this.SetSelectedOption(selectedItem);
    }

    OnCustomSearchFocused() {
	    if (this._selectedOptionLabel()) {
		    const initialLabel = this._selectedOptionLabel();
		    this._initialOptionLabel(initialLabel);
		    this._customPlaceholder(initialLabel);
	    }

	    this._selectedOptionLabel(null);
    }

    OnCustomSearchBlur() {
	    if (!this._selectedOptionLabel()) {
		    this._selectedOptionLabel(this._initialOptionLabel());
	    }

	    this._customPlaceholder(this._defaultPlaceholder);
	    this._initialOptionLabel(null);
    }

    OnCustomValueEntered(element, event) {
	    if (event.target.value) {
		    this._isCustomValueEntered(true);
	    } else {
		    this._isCustomValueEntered(false);
	    }
    }

    ShowSearchScreen() {
        const params = {
            SearchTerm: '',
            EntityName: this.customField.ValTable,
            EntityType: this.customField.ValTableType,
            SearchFieldId: this.customField.ValFieldId,
            ButtonAdd: false
        };

        this._searchScreen = new SearchScreen(params);
        this._searchScreen.On('RECORD_SELECTED', this, (evtArgs) => {
            const tableId = evtArgs.data.TableId;
            const recordId = evtArgs.data.RecordId;
            const fieldNameValue = evtArgs.data.Name;

            this.FindSelectedOption(tableId, recordId, fieldNameValue);
        });

        this._searchScreen.Show();
    }

    ShowOptionCreate(data, esc) {
	    return `<div class="create">${this._labels.ADD}&nbsp;<strong>&nbsp;${esc(data.input)}&nbsp;</strong>&hellip;</div>`;
    }

    private AddCustomOption(text) {
	    const newCustomOption = new CustomLookupOptionModel();

	    newCustomOption.Value = -1;
	    newCustomOption.Text = text;

	    this._options.push(newCustomOption);
	    this.SetSelectedOption(newCustomOption);
    }

    private RemoveCustomOption() {
	    const oldCustomValueIndex = _.findIndex(this._options(), item => item.Value === -1);

	    if (oldCustomValueIndex !== -1) {
		    this._options.splice(oldCustomValueIndex, 1);
	    }
    }

    private DownloadOptions(initialOptionText: string, initialOptionId: number) {
        if (!this._isDataLoading()){
            return;
        } else if (!this.customField.ValFieldName){
            this._isDataLoading(false);
            return;
        }

        RecordStore.GetLookupData({
            TableName: this.customField.ValTable,
            DisplayValue: this.customField.ValFieldName
        })
            .always(() => this._isDataLoading(false))
            .then(result => {
                if (result.Data && result.Data.length < result.Count) {
                    this._useSearch(true);
                    this.SetSelectedOption(new CustomLookupOptionModel(initialOptionText, initialOptionId));
                } else {
                    const options = result.Data.map(record => new CustomLookupOptionModel(record.Name, parseInt(record.Value)));
					this._options(options);

					if (initialOptionId === -1) {
						this.AddCustomOption(initialOptionText);
					} else {
						this.SetSelectedOption(options.find(o => o.Value === initialOptionId));
					}
                }

                this.StartListeningValueChanges();
            })
            .fail(err => new Notifier().Failed(err.message));
    }

    private FindSelectedOption(tableId: number, recordId: number, fieldNameValue: string) {
        const fieldName = this.customField.ValFieldName;

        if (fieldName === 'NAME') {
			this._isCustomValueEntered(false);
			this.SetSelectedOption(new CustomLookupOptionModel(fieldNameValue, recordId));
			return;
        }

        BlockUI.Block();

        RecordStore.GetRecord({TableId: tableId, RecordId: recordId})
            .always(() => BlockUI.Unblock())
            .then((record: any) => {
                const selected = _.find(record.Fields, (item: any) => item.FieldName === fieldName);

                if (selected) {
					this._isCustomValueEntered(false);
					this.SetSelectedOption(new CustomLookupOptionModel(selected.FieldValue, recordId));
                }
            })
            .fail(() => {
                new Notifier().Failed('Error applying selected option');
            });
    }

    private SetSelectedOption(selected: CustomLookupOptionModel) {
	    this._selectedOption(selected);
	    this._selectedOptionId(!!selected ? selected.Value : null);
	    this._selectedOptionLabel(!!selected ? selected.Text : null);

        this.CheckAndUpdateActionExpressionValue();
    }

    private StartListeningValueChanges() {
        if (this._selectedOptionSubscription == null) {
            this._selectedOptionSubscription = this._selectedOption.subscribe(() => {
                this.CheckValidControl();
                this.OnValueChanged();
            });
        }
    }

    IsValid(value?: any): boolean {
        let selectedOptionLabel = this._selectedOption() ? this._selectedOption().Text : null;
        return this.GetIsRequiredValidation(value ? value : selectedOptionLabel);
    }

    CheckValidControl() {
        let selectedOptionLabel = this._selectedOption() ? this._selectedOption().Text : null;
        this.isValidControl(this.GetIsRequiredValidRule(selectedOptionLabel));
    }
}