import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {ScreenModel, DesignScreenModel} from 'Core/Models/Screens/ScreenModel';
import {P} from 'Core/Common/Promise';
import {Deserialize, GenericDeserialize} from 'libs/cerialize';
import {DataModes} from "Core/Enums/DataModes";
import { ConcreteEntityModel } from '../../../QueryBuilder/Models/ConcreteEntityModel';

export interface IGetDesignScreenByIdRequestModel {
    FormId: number;
};

export interface IUpdateScreenNameRequestModel {
    FormId: number;
    Name: string;
};

export interface IGetScreenModelByIdRequestModel {
    ScreenId: number;
    RecordId?: number;
};

export interface IGetScreenByScreenType {
    EntityId: number;
    ScreenType: number;
    RecordId: number;
    DataMode: DataModes;
}

export interface IGetEditScreenRequestModel {
    EntityId: number;
	TableTypeId?: number;
	TableTypeName?: string;
	KindId?: number;
    RecordId: number;
    LoadAsExample: boolean;
    ParentRecordId?: number;
    DataMode?: DataModes;
    SubjectLifestatusId?: number;
}

export class ScreenStore {
    static GetScreenById(params: IGetScreenModelByIdRequestModel): P.Promise<ScreenModel> {
        var deferredResult = P.defer<ScreenModel>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: URL.GET_SCREEN_BY_ID,
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(data => {
                deferredResult.resolve(this.Deserialize(data));
            }).fail(() => deferredResult.reject({message: "Error getting screen"}));

        return deferredResult.promise();
    }

    static GetDesignScreen(params: IGetDesignScreenByIdRequestModel): P.Promise<DesignScreenModel> {
        var deferredResult = P.defer<DesignScreenModel>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: 'api/FormDesignerApi/GetScreen',
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(data => {
                if (data.IsSuccessfull) {
                    deferredResult.resolve(this.DeserializeDesignScreen(data));
                } else {
                    deferredResult.reject({message: data.ErrorMessage});
                }
            });

        return deferredResult.promise();
    }

    static GetSubjectEntity(screenId: number): P.Promise<ConcreteEntityModel> {
        var deferredResult = P.defer<ConcreteEntityModel>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: 'api/FormDesignerApi/GetSubjectEntity',
                type: 'GET'
            }, params: { ScreenId: screenId }
        };

        Request.Send(requestParams)
            .then(data => deferredResult.resolve(GenericDeserialize<ConcreteEntityModel>(data, ConcreteEntityModel)));

        return deferredResult.promise();
    }

    static UpdateScreenName(params: IUpdateScreenNameRequestModel): P.Promise<any> {
        var deferredResult = P.defer<DesignScreenModel>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: URL.UPDATE_SCREEN_NAME,
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(response => {
                if (response.IsSuccessfull) {
                    deferredResult.resolve(null);
                } else {
                    deferredResult.reject({message: response.ErrorMessage});
                }
            }).fail(() => deferredResult.reject({message: "Error deleting screen"}));

        return deferredResult.promise();
    }


    static DeleteScreen(params: IGetDesignScreenByIdRequestModel): P.Promise<any> {
        var deferredResult = P.defer<DesignScreenModel>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: URL.DELETE_SCREEN,
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(response => {
                if (response.IsSuccessfull) {
                    deferredResult.resolve(null);
                } else {
                    deferredResult.reject({message: response.ErrorMessage});
                }
            }).fail(() => deferredResult.reject({message: "Error deleting screen"}));

        return deferredResult.promise();
    }

    static GetScreenByScreenType(params: IGetScreenByScreenType): P.Promise<ScreenModel> {
        var deferredResult = P.defer<ScreenModel>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: URL.GET_SCREEN_BY_SCREEN_TYPE,
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(data => {
                if (data.IsSuccessfull) {
                    deferredResult.resolve(this.Deserialize(data.ResultObject));
                } else {
                    deferredResult.reject({message: data.ErrorMessage});
                }

            })
            .fail(err => deferredResult.reject(err));

        return deferredResult.promise();
    }

    static GetEditScreen(params: IGetEditScreenRequestModel): P.Promise<ScreenModel> {
        var deferredResult = P.defer<ScreenModel>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: URL.GET_EDIT_SCREEN,
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(data => {
                if (data.IsSuccessfull) {
                    deferredResult.resolve(this.Deserialize(data.ResultObject));
                } else {
                    deferredResult.reject({message: data.ErrorMessage});
                }
            });
        return deferredResult.promise();
    }

    static Deserialize(jsonObject: any): ScreenModel {
        var model = <ScreenModel>Deserialize(jsonObject, ScreenModel);
        return model;
    }

    static DeserializeDesignScreen(jsonObject: any): DesignScreenModel {
        var model = <DesignScreenModel>Deserialize(jsonObject.ResultObject, DesignScreenModel);
        return model;
    }

    static IsTypeTransformationRequired(params: any): P.Promise<boolean> {
        let deferredResult = P.defer<boolean>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: URL.IS_TYPE_TRANSFORMATION_REQUIRED,
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(data => {
                if (data.IsSuccessfull) {
                    deferredResult.resolve(data.ResultObject);
                } else {
                    deferredResult.reject({message: data.ErrorMessage});
                }
            });

        return deferredResult.promise();
    }
}