import * as ko from 'knockout';

export class MailAddressModel {
	MailAddress: KnockoutObservable<string>;

	constructor() {
		this.MailAddress = ko.observable('').extend({
			email: true,
			required: {
				message: 'Please provide an email'
			}
		});
	}
}