import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Notifier} from 'Core/Common/Notifier';
import { GenericDeserialize } from 'libs/cerialize';
import { EntityTypeModel } from 'Core/Controls/Kanban/Models/EntityTypeModel';
import {StatusModel, ActionModel, UserModel} from 'Core/Controls/Kanban/Models/StatusModel';
import { ViewListResponseModel } from 'Core/Controls/Kanban/Models/ViewListResponseModel';
import { IGetEntityRequestModel } from "Core/Common/Interfaces/IGetEntityRequestModel";
import {PROPERTIES} from "../Constants";
import {ICheckItemSerializedValue} from "../../../Components/ActionCheckList/CheckItems/ICheckItemSerializedValue";


export interface IGetKanbanBoardTypesRequestModel {
	EntityId: number;
	ScreenSubjectTypeId: number;
	ShowTypeHierarchy: boolean;
};

export interface IGetKanbanBoardStatusesRequestModel {
    EntityId: number;
	FieldId: number;
	TypeId?: number;
	SubjectTypeId?: number;
	RecordId? : number;
	RecordOwners: number[];
	ShowPlanned: boolean;
	FilterByTypeHierarchy?: boolean;
	FilterByOwners: boolean;
	UseSubjectEntity: boolean;
	FilterByEntityId?: number;
	FilterByRecordId?: number;
    WithTags: boolean;
    WithSubjectImage: boolean;
    WithPriorities: boolean;
    WithCalculatedPriority: boolean;
	WithCalculatedViaCalсPrioPriority: boolean;
    WithMails: boolean;
	WithColor: boolean;
	WithTimers?: boolean;
    ViewId?: number;
	IsViewForAction?: boolean;
    IsToDo: boolean;
    TodoModel?: {
        EntityId: number;
        TypeId: number;
        FieldId: number;
    };
	WithMemo: boolean;
	WithToDoProgressBar?: boolean;
};

interface IGetKanbanBoardViewListRequestModel {
	SubjectEntityId: number;
}

interface IGetUserAllowanceRequestModel {
	TableId: number;
	SecurityWord: number;
}

interface IBaseUpdateStatusRequestModel {
	SubjectEntityId: number;
	SubjectRecordId: number;
	NewStatusId: number;
	PreviousLifeStatusId: number;
	ConfirmationPassword?: string;
	CheckItems?: ICheckItemSerializedValue[];
}

export interface IUpdateStatusRequestModel extends IBaseUpdateStatusRequestModel {
	Actions: Array<ActionModel>;
};

export interface IUpdateActionStatusRequestModel extends IBaseUpdateStatusRequestModel {
	ActionsEntityId: number;
	ActionRecordId: number;
	Users: Array<UserModel>;
}

interface IUpdatePriorityRequestModel {
	EntityId: number;
	RecordId: number;
	PriorityId: number;
}

export class KanbanStore {
	static GetTypes(params: IGetKanbanBoardTypesRequestModel): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.KANBANBOARD.GET_TYPES,
				dataType: "json",
				type: "POST"
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				var model = GenericDeserialize<EntityTypeModel>(data.ResultObject, EntityTypeModel);
				deferredResult.resolve(model);
			});

		return deferredResult.promise();
	}

	static GetStatuses(params: IGetKanbanBoardStatusesRequestModel): P.Promise<StatusModel> {
		var deferredResult = P.defer<StatusModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.KANBANBOARD.GET_STATUSES,
				dataType: "json",
				type: "POST"
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					var model = GenericDeserialize<StatusModel>(data.ResultObject, StatusModel);
					deferredResult.resolve(model);

					for (const warningMessage of data.Warnings) {
						new Notifier().Warning(warningMessage);
					}
				} else {
					deferredResult.reject(data.ErrorMessage);
				}
			});

		return deferredResult.promise();
	}

	static GetViewList(params: IGetKanbanBoardViewListRequestModel) {
		const deferredResult = P.defer<ViewListResponseModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.KANBANBOARD.GET_VIEW_LIST,
				dataType: 'json',
				type: 'POST'
			},
			params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(GenericDeserialize(data, ViewListResponseModel));
			});

		return deferredResult.promise();
    }

	static GetDefaultKanbanViewMetaData(): P.Promise<IGetEntityRequestModel> {
		const deferredResult = P.defer<IGetEntityRequestModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.KANBANBOARD.GET_DEFAULT_VIEW_METADATA,
				dataType: 'json',
				type: 'GET'
			}
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
			})
			.fail(error => {
				deferredResult.reject(error)
			});

		return deferredResult.promise();
    }

	static GetUserAllowance(params: IGetUserAllowanceRequestModel) {
		const deferred = P.defer();

		Request.Send({
			proxy: {
				url: "api/TableSecurityApi/IsUserAllowedTo",
				type: "POST",
				dataType: "json"
			},
			params
		}).then(data => {
			if (data.IsSuccessfull) {
				deferred.resolve(data.ResultObject);
			} else {
				deferred.reject({message: data.ErrorMessage});
			}
		});

		return deferred.promise();
	}

	static UpdateStatus(params: IUpdateStatusRequestModel): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.KANBANBOARD.UPDATE_STATUS,
				dataType: "json",
				type: "POST"
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(null);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}

			});

		return deferredResult.promise();
	}

	static UpdateActionStatus(params: IUpdateActionStatusRequestModel): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.KANBANBOARD.UPDATE_ACTION_STATUS,
				dataType: "json",
				type: "POST"
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(null);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}

			});

		return deferredResult.promise();
	}

	static UpdatePriority(params: IUpdatePriorityRequestModel): P.Promise<any> {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.KANBANBOARD.UPDATE_PRIORITY,
				dataType: "json",
				type: "POST"
			}, params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(null);
			})
			.fail(err => {
				deferredResult.reject(err);
			});

		return deferredResult.promise();
	}
}