import {deserialize, deserializeAs, serialize, serializeAs} from "libs/cerialize";

export class ActionSubjectRecordModel {
    @deserialize
    ActionEntityId: number;

	@deserialize
	@serialize
	EntityId: number;

	@deserialize
	@serialize
	RecordId: number;

	@deserialize
	RecordName: string;

	@deserialize
	RecordTranslatedName: string;

	@deserialize
    RecordTypeName: string;

	@deserialize
	RecordTypeTranslatedName: string;

	@deserialize
	LifeStatusId: number;

	@deserialize
	LifeStatusName: string;

	@deserialize
	LifeStatusTranslatedName: string;

    @deserialize
    IsFollowUpDisabled: boolean;

    @deserialize
    DisabledReason: string;

    @deserialize
    FollowupModeId: number;

    @deserialize
    FollowupModeName: string;

    get DisplayRecordTypeName(): string{
    	return this.RecordTypeTranslatedName || this.RecordTypeName;
	}

	get DisplayRecordName(): string{
		return this.RecordTranslatedName || this.RecordName;
	}

	constructor(entityId?: number, recordId?: number) {
		this.EntityId = entityId;
		this.RecordId = recordId;
	}
}