import { AdministrationStore } from "../AdministrationStore";
import { BlockUI } from "../../../Core/Common/BlockUi";
import { SpaceStatusDto } from "./SpaceStatusDto";

import Template from "Pages/AdministrationPage/Tabs/ServiceStatus.html";
import { ServiceStatusDto } from "./ServiceStatusDto";
ko.templates["Pages/AdministrationPage/Tabs/ServiceStatus"] = Template;

export class ServiceStatus {
    private _statuses: KnockoutObservableArray<ServiceStatusDto>;

    constructor(){
        this._statuses = ko.observableArray([]);
        this.LoadData();
    }

    GetTemplateName(){
        return 'Pages/AdministrationPage/Tabs/ServiceStatus';
    }

    LoadData(){
        BlockUI.Block();
        AdministrationStore.GetServiceStatus().always(()=>{
            BlockUI.Unblock();
        }).then((data)=>{
            this._statuses(data);
        });
    }

    AfterRender(){

    }
}