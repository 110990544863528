import {deserialize, deserializeAs} from "libs/cerialize";

export class UserModel {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	UserName: string;

	@deserialize
	UserTypeName: string;

	@deserialize
	UserTypeNameTranslation: string;

	Checked: boolean;
}

export class UserGroupModel {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	UserIds: Array<number>;

	@deserialize
	TypeId: number;

	@deserialize
	TypeName: string;

	@deserialize
	TypeNameTranslation: string;

	Checked: boolean | null;

	Users?: Array<UserModel>
}

export class SelectUserModel {

	@deserializeAs(UserModel)
	Users: Array<UserModel>;

	@deserializeAs(UserGroupModel)
	UserGroups: Array<UserGroupModel>;
}
