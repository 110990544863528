import { BasicCanvasItemViewModel } from "./BasicCanvasItemViewModel";
import { CanvasItemModel } from "./CanvasModel";
import { Utils } from 'Core/Controls/CanvasDesigner/Utils';
import { CanvasShapeWidth } from "../Enums/CanvasShapeWidth";
import { JsonHubProtocol } from "@microsoft/signalr";

export class TableViewModel extends BasicCanvasItemViewModel {
	attrs: any;
	isCollapsed: boolean;

	constructor(model: CanvasItemModel) {
		super(model, { 'width': CanvasShapeWidth['Node'], 'height': 30 });
		this.z = Utils.GetRandomArbitrary(60, 80);
		this.attrs.cursor = 'grab';
		this.attrs.IsCollapsed = false;
		this.InitProperties(model.Properties);
	}

	private InitProperties(properties: string){
		try {

			let jsonProperties = JSON.parse(properties);

			if(jsonProperties.hasOwnProperty('Rappid') && !_.isNull(jsonProperties.Rappid) && !_.isUndefined(jsonProperties.Rappid) && jsonProperties.Rappid.hasOwnProperty('isCollapsed')){
				this.attrs.IsCollapsed = jsonProperties.Rappid.isCollapsed;
			}

			if(jsonProperties && jsonProperties.IsSearchExisting){
				this.attrs.IsSearchExisting = jsonProperties.IsSearchExisting;
			}


			if (jsonProperties && jsonProperties.UseMatching) {
				this.attrs.UseMatching = jsonProperties.UseMatching;
			}

			if (jsonProperties && jsonProperties.SkipCondition) {
				this.attrs.SkipCondition = jsonProperties.SkipCondition;
			}

			if (jsonProperties && jsonProperties.IsCheckOwnerProfile) {
				this.attrs.IsCheckOwnerProfile = jsonProperties.IsCheckOwnerProfile;
			}

			if (jsonProperties && jsonProperties.AddDaysToStart) {
				this.attrs.AddDaysToStart = jsonProperties.AddDaysToStart;
			}

			if (jsonProperties && jsonProperties.AddDaysToEnd) {
				this.attrs.AddDaysToEnd = jsonProperties.AddDaysToEnd;
			}

			if (jsonProperties && jsonProperties.ODataFilter) {
				this.attrs.ODataFilter = jsonProperties.ODataFilter;
			}

			if (jsonProperties && jsonProperties.ExcludeCancelled) {
				this.attrs.ExcludeCancelled = jsonProperties.ExcludeCancelled;
			}

		}catch (e) {
		}
	}
}