import * as _ from "underscore";
import * as moment from 'moment';

import {DATE_FORMATS} from "Core/Constants/DateTimeFormats";

import {CellEditor} from "../Utils/CellEditor";
import {ColumnEditor} from "../Utils/ColumnEditor";
import {SubGroupEditor} from "../Utils/SubGroupEditor";
import {Period} from "../Models/View/SchedulerViewModel";
import {HeaderColumnMapping} from "./HeaderColumnMapping";
import {SchedulerView} from "../Views/SchedulerView";
import {IRePlanningData} from "Core/Controls/Scheduler/Interfaces";

export interface ISubGroupsMapping {
    userEditor: SubGroupEditor,
    timeStructure: any,
    startDate: moment.Moment,
    endDate: moment.Moment,
    periods: Array<Period>,
    schedulerView: SchedulerView,
    scheduleStructure: any,
    freeDaysStructure: any,
    rePlanningData: IRePlanningData
}

export interface ISubResourcesMapping {
    subGroupEditor: SubGroupEditor,
    timeStructure: any,
    startDate: moment.Moment,
    endDate: moment.Moment,
    periods: Array<Period>,
    schedulerView: SchedulerView,
    scheduleStructure?: any,
    freeDaysStructure?: any,
    rePlanningData: IRePlanningData
}

export class SubGroupsMapping {
    static OnUserViewModel(model: ISubGroupsMapping) {
        const starting = model.startDate.clone();
        const diff = moment(model.endDate).diff(starting, "days");

        for (let i = 0; i <= diff; i++) {
            model.userEditor._columnsEditors.push(new ColumnEditor());

            const dayPeriods = HeaderColumnMapping.ConvertPeriodsToWeekDay(model.periods, starting);

            _.forEach(dayPeriods, (dayPeriod) => {
                const currentDate = moment(dayPeriod.Start);
                const endDate = currentDate.clone().add(dayPeriod.Duration, 'h');

                let workMinutes = 0;
                let workSchedMinutes = 0;
                let freeDaysMinutes = 0;

                while (currentDate.isBefore(endDate)) {
                    const currentTime = currentDate.format(DATE_FORMATS.DATE_TIME.Format);

                    workMinutes += model.timeStructure[currentTime] || 0;
                    workSchedMinutes += model.scheduleStructure[currentTime] || 0;
                    freeDaysMinutes += model.freeDaysStructure[currentTime] || 0;

                    currentDate.add(1, 'm');
                }

                const workHours = workMinutes / 60;
                let workSchedHours = workSchedMinutes / 60;
                let isAvailable = workSchedHours >= dayPeriod.Duration;

                const cellEditorModel = {
                    count: workHours,
                    isAvailable: isAvailable,
                    isFreeDay: !!freeDaysMinutes,
                    period: dayPeriod,
                    schedulerView: model.schedulerView,
                    rePlanningData: model.rePlanningData,
                    isSelected: model.userEditor._isSelected(),
                    columnIndex: `${i}-${model.userEditor._columnsEditors[i]._cellsEditors.length}`,
                    appointments: model.userEditor._agenda
                };

                const cellEditor = new CellEditor(cellEditorModel);

                model.userEditor._columnsEditors[i]._cellsEditors.push(cellEditor);

            });

            starting.add(1, 'd');
        }
    }

    static OnViewModel(model: ISubResourcesMapping) {
        const starting = model.startDate.clone();
        const diff = moment(model.endDate).diff(starting, "days");

        for (let i = 0; i <= diff; i++) {
            model.subGroupEditor._columnsEditors.push(new ColumnEditor());

            const dayPeriods = HeaderColumnMapping.ConvertPeriodsToWeekDay(model.periods, starting);

            _.forEach(dayPeriods, (dayPeriod) => {
                const currentDate = moment(dayPeriod.Start);
                const endDate = currentDate.clone().add(dayPeriod.Duration, 'h');

                let workMinutes = 0;
                let freeDaysMinutes = 0;
                while (currentDate.isBefore(endDate)) {
                    const currentTime = currentDate.format(DATE_FORMATS.DATE_TIME.Format);

                    workMinutes += model.timeStructure[currentTime] || 0;
                    currentDate.add(1, 'm');
                }

                const workHours = workMinutes / 60;

                const cellEditorModel = {
                    count: workHours,
                    isAvailable: true,
                    isFreeDay: !!freeDaysMinutes,
                    period: dayPeriod,
                    schedulerView: model.schedulerView,
                    rePlanningData: model.rePlanningData,
                    isSelected: model.subGroupEditor._isSelected(),
                    columnIndex: `${i}-${model.subGroupEditor._columnsEditors[i]._cellsEditors.length}`,
                    appointments: model.subGroupEditor._agenda
                };
                model.subGroupEditor._columnsEditors[i]._cellsEditors.push(new CellEditor(cellEditorModel));
            });
            starting.add(1, 'd');
        }
    }
}