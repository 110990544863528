import * as _ from 'underscore';
import * as ko from 'knockout';

import { TranslationRow } from 'Pages/TransaltionEditorPage/TranslationEditor/TranslationRow';
import { TranslationModel } from 'Pages/TransaltionEditorPage/Models/TranslationModel';
import { TranslationHeader } from 'Pages/TransaltionEditorPage/TranslationEditor/TranslationHeader';

import Template from 'Pages/TransaltionEditorPage/TranslationEditor/Templates/TranslationGrid.html';

export class TranslationGrid {
	private _rows: KnockoutObservableArray<TranslationRow>;
	private _headers: KnockoutObservableArray<TranslationHeader>;

	constructor() {
		this._rows = ko.observableArray([]);
		this._headers = ko.observableArray([]);
	}

	GetTemplate() {
		return Template;
	}

	AfterRender() {
		
	}

	SetData(translationColumns: Array<string>, translations: Array<TranslationModel>) {
		this._rows([]);
		this._headers([]);
		var actionHeader = new TranslationHeader('');
		var codeHeader = new TranslationHeader('CODE');
		var nameHeader = new TranslationHeader('NAME');
		this._headers.push(actionHeader);
		this._headers.push(codeHeader);
		this._headers.push(nameHeader);
		
		_.each(translationColumns, (translation) => {
			this._headers.push(new TranslationHeader(translation));
		});

		_.each(translations, (translation) => {
			var row = new TranslationRow(translation);
			this._rows.push(row);
		});
	}

	get Rows(): KnockoutObservableArray<TranslationRow> {
		return this._rows;
	}

	get Headers(): KnockoutObservableArray<TranslationHeader> {
		return this._headers;
	}
}