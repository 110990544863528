import * as _ from "underscore";
import {EventBus, IEventArgs} from "./EventBus";

export type EventHandlerCallback<TData extends {}, TSource extends {}> = (eventArgs: IEventArgs<TData, TSource>) => void;
export type EventHandlerCondition<TData extends {}, TSource extends {}> = (eventArgs: IEventArgs<TData, TSource>) => boolean;

export class EventHandler<TData extends {}, TSource extends {}, TContext extends {}> {
    private _handlers: EventHandlerCallback<TData, TSource>[];
    private _handlerConditions: EventHandlerCondition<TData, TSource>[];

    constructor(private _bus: EventBus, private _eventName: string, private _context: TContext) {
        this._handlers = [];
        this._handlerConditions = [];
    }

    get EventName() {
        return this._eventName;
    }

    When(condition: EventHandlerCondition<TData, TSource>) {
        this._handlerConditions.push(condition);
        return this;
    }

    Using(handler: EventHandlerCallback<TData, TSource>) {
        this._handlers.push(handler);
        return this;
    }

    Always() {
        this._bus.On(this._eventName, this._context, args => this.ExecuteHandlers(args.data));
        return this;
    }

    Once() {
        this._bus.One(this._eventName, this._context, args => this.ExecuteHandlers(args.data));
        return this;
    }

    UnSubscribe() {
        this._bus.Off(this._eventName, this._context);
        return this;
    }

    private ExecuteHandlers(eventArgs: IEventArgs<TData, TSource>) {
        const allConditionsAreMet = _.every(this._handlerConditions, condition => condition.call(this._context, eventArgs));

        if (allConditionsAreMet) {
            this._handlers.forEach(handler => handler.call(this._context, eventArgs));
            return true;
        }

        return false;
    }
}