import {Guid} from "Core/Common/Guid";

import {AssigneeModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/AssigneeModel";

export class FieldCollectionModel {
	Id: number;
	Name: string;
	Assignee: AssigneeModel;

	Guid: string;

	constructor(id: number, name: string, assignee: AssigneeModel){
		this.Id = id;
		this.Name = name;
		this.Assignee = assignee;

		this.Guid = Guid.NewGuid();
	}

	get AssigneeName() {
		return this.Assignee && this.Assignee.Name;
	}
}