export class SpreadsheetCellPosition {
    constructor(public Column: string, public Row: number) {

    }

    get PositionName() {
        return `${this.Column}${this.Row}`;
    }

    GetColumnIndex() {
        return this.GetColumnNumber() - 1;
    }

    GetColumnNumber() {
        let number = 0, pow = 1;

        for (let i = this.Column.length - 1; i >= 0; i--) {
            number += (this.Column.charCodeAt(i) - 'A'.charCodeAt(0) + 1) * pow;
            pow *= 26;
        }

        return number;
    }

    GetNextColumnName() {
        return SpreadsheetCellPosition.GetColumnNameByNumber(this.GetColumnNumber() + 1);
    }

    GetRowIndex() {
        return this.Row - 1;
    }

    IsSameAs(position: SpreadsheetCellPosition) {
        return this.Column == position.Column && this.Row === position.Row;
    }

    static Parse(cell: string) {
        const column = cell.match(/[A-Z]+/)[0];
        const row = +cell.split(column)[1];

        return new SpreadsheetCellPosition(column, row);
    }

    static GetColumnNameByNumber(n: number) {
        let columnName = '';

        while (n > 0) {
            const mod = Math.floor(n - 1) % 26;
            columnName = (String.fromCharCode(65 + mod)) + columnName;
            n = Math.floor((n - mod) / 26);
        }

        return columnName;
    }
}