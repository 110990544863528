import * as _ from "underscore";
import * as ko from "knockout";

import {TotalCell} from 'Core/Controls/Grid/BaseGrid/GridCell/TotalCell';
import {GridAverageRowModel} from 'Core/Controls/Grid/Models/GridDataModel/GridAverageRowModel';
import {GridColumnModel} from 'Core/Controls/Grid/Models/GridDataModel/GridColumnModel';
import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

export class GridAverageRow {
	private _dataCells: KnockoutObservableArray<TotalCell>;

	constructor(
		private _model: GridAverageRowModel,
		public _isEnableSelectRecord:  KnockoutObservable<boolean>,
		columns: GridColumnModel[]
	) {
		this._dataCells = ko.observableArray([]);
		_.each(this._model.Data, (item, index) => {
			let filterAverageColumns = _.filter(columns, column => !column.IsSortDescription);
			this._dataCells.push(new TotalCell(item, filterAverageColumns[index]));
		});
	}

	@enumerable
	get HasWrappedColumn(): boolean {
		var wrappedCell = _.find(this._dataCells(), (dataCell) => { return dataCell.IsWrapped });
		return wrappedCell != null;
	}

	@enumerable
	get HasData(): boolean {
		return this._model.Data.length > 0;
	}

	@enumerable
	get Guid(): string {
		return this._model.Guid;
	}
}