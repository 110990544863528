//Libs
import * as ko from "knockout";
import * as _ from "underscore";

//Common
import {P} from "Core/Common/Promise";
import {Notifier} from "Core/Common/Notifier";

//User
import {UserManager} from "User/UserManager";

//Components
import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS, Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

//Screens
import {SearchScreen} from "Core/Screens/SearchScreen/SearchScreen";

//Settings
import {IDayViewSettings} from "Core/Components/Controls/TimeWriting/Views/Day/IDayViewSettings";

import {
    RowEditor,
    CellEditor,
    ActionsCellEditor,
    ValueCellEditor,
    DropdownCellEditor,
    MultiSelectCellEditor,
    SubjectCellEditor,
    DescriptionCellEditor,
    State as RowEditorState,
    IntegerCellEditor,
    DecimalCellEditor,
    DateTimeCellEditor,
    DateCellEditor,
    TimeCellEditor,
    ValidationCellEditor
} from "Core/Components/Controls/TimeWriting/Utils/RowEditor";
import {DisapprovedEditor, DisapprovedInfo} from "Core/Components/Controls/TimeWriting/Utils/DisapprovedEditor";

//Views
import {TimeWritingView} from "Core/Components/Controls/TimeWriting/Views/TimeWritingView";

//State
import {State} from "Core/Components/Controls/TimeWriting/Views/Day/State";
import {ViewState} from "Core/Components/Controls/TimeWriting/Views/Day/ViewState";

//ViewModels
import {
    AllowedEntity, LookupField,
    Reservation,
    Subject,
} from "Core/Components/Controls/TimeWriting/Models/View/DayDataViewModel";

import {UsersViewModel, UserViewModel} from "Core/Components/Controls/TimeWriting/Models/View/UsersViewModel";

//Stores
import {TimeWritingStore} from "Core/Components/Controls/TimeWriting/Stores/TimeWritingStore";

//Store models
import {SaveReservationRequestModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Request/SaveReservationRequestModel";
import {DeleteReservationRequestModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Request/DeleteReservationRequestModel";

//Mappings
import {DayMappingProfile} from "Core/Components/Controls/TimeWriting/Mappings/Day/DayMappingProfile";
import {UsersMappingProfile} from "Core/Components/Controls/TimeWriting/Mappings/Day/UsersMappingProfile";

//Templates
import DisapprovedTemplate from "Core/Components/Controls/TimeWriting/Templates/DisapprovedTemplate.html";
//TextColumn
import TextViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Text/View.html";
import TextEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Text/Edit.html";

import DescriptionViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Description/View.html";
import DescriptionEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Description/Edit.html";
//Subject
import SubjectViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Subject/View.html";
import SelectableSubjectEditTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Subject/SelectableSubjectEdit.html";
import SelectedSubjectEditTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Subject/SelectedSubjectEdit.html";

//Integer
import IntegerViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Integer/View.html";
import IntegerEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Integer/Edit.html";

//Decimal
import DecimalViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Decimal/View.html";
import DecimalEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Decimal/Edit.html";

//DateTime
import DateTimeViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/DateTime/View.html";
import DateTimeEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/DateTime/Edit.html";

//YesNo
import YesNoViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/YesNo/View.html";
import YesNoEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/YesNo/Edit.html";

//Dropdown
import DropdownViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Dropdown/View.html";
import DropdownEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Dropdown/Edit.html";
//Time
import TimeViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Time/View.html";
import TimeEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Time/Edit.html";
//Actions
import ActionsViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Actions/Disapproved/View.html";
import ActionsEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Actions/Disapproved/Edit.html";
//Dash
import DashViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Dash/View.html";

import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';
import {UserVarsManager} from "Core/UserVarsManager/UserVarsManager";


import {NOTIFICATIONS, LABELS} from "Core/Components/Translation/Locales";
import {UsersResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Response/UsersResponseModel";
import {
    DisapprovedDataViewModel,
    DisapprovedReservation
} from "Core/Components/Controls/TimeWriting/Models/View/DisapprovedDataViewModel";
import {DisapprovedReportResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Disapproved/Response/DisapprovedReportResponseModel";
import {DisapprovedMappingProfile} from "Core/Components/Controls/TimeWriting/Mappings/Disapproved/DisapprovedMappingProfile";
import {DateTime} from "Core/Components/Controls/TimeWriting/Utils/DateTime";
import {AttachedFieldModel} from "Core/Controls/BaseControl/Models/AttachedFieldModel";
import {FIELD_TYPES} from "Core/Constant";
import {BlockUI} from "Core/Common/BlockUi";

export class DisapprovedView extends TimeWritingView {
    private _state: KnockoutObservable<State>;
    private _isEditable: KnockoutObservable<boolean>;
    private _viewModel: KnockoutObservable<DisapprovedDataViewModel>;

    private _viewState: KnockoutObservable<ViewState>;
    private _isReadState: KnockoutComputed<boolean>;
    private _isEditState: KnockoutComputed<boolean>;
    private _isNewState: KnockoutComputed<boolean>;

    private _disapprovedEditors: KnockoutObservableArray<DisapprovedEditor>;
    private _labels = LABELS;

    constructor(private _settings: IDayViewSettings) {
        super();
        this._state = ko.observable(new State({Date: new Date()}));
        this._isEditable = ko.observable(false);
        this._viewModel = ko.observable(null);

        this._viewState = ko.observable(ViewState.Read);
        this._isReadState = ko.computed(() => this._viewState() === ViewState.Read, this);
        this._isEditState = ko.computed(() => this._viewState() === ViewState.Edit, this);
        this._isNewState = ko.computed(() => this._viewState() === ViewState.New, this);
        this._disapprovedEditors = ko.observableArray([]);
    }

    GetTemplate() {
        return DisapprovedTemplate;
    }

    CanBeUpdated() {
        return this._viewState() === ViewState.Read;
    }

    Show(date: Date, userId?: number) {
        this._state(new State({
            Date: date
        }));

        const usersList = this._viewModel() && this._viewModel().Users;

        if (usersList && userId) {
            const selectedUser = usersList.Users().find(user => user.Id === userId);
            if (selectedUser) {
                usersList.SelectedUser(selectedUser);
            }
        }

        if (usersList) {
            this.LoadData(date, usersList.SelectedUser().Id)
                .then(disapprovedReportResponse => this.RenderData(disapprovedReportResponse, usersList))
                .fail(error => this.ShowError(error.message));
        } else {
            const loadDataPromise = this.LoadData(date, userId || UserManager.Instance.CurrentUser.Id);
            const loadUsersPromise = this.LoadUsers();

            loadDataPromise.fail(error => this.ShowError(error.message));
            loadUsersPromise.fail(error => this.ShowError(error.message));

            P.when(loadDataPromise, loadUsersPromise).then(result => this.RenderData(result[0], result[1], userId || UserManager.Instance.CurrentUser.Id));
        }
        BlockUI.Unblock();
    }

    OnDayViewClick() {
        const usersList = this._viewModel() && this._viewModel().Users;
        const selectedUser = usersList && usersList.SelectedUser().Id;

        if (this._viewState() === ViewState.Read) {
            this.Trigger("DayViewClicked", {State: this._state(), UserId: selectedUser});
            return;
        }

        this.AskSaveConfirmation(() => {
            this.SaveReservation()
                .then((rowEditor: RowEditor) => {
                    this.CommitChanges(rowEditor);
                    this.Trigger("DayViewClicked", {State: this._state(), UserId: selectedUser});
                })
                .fail(error => this.ShowError(error.message));
        }, () => {
            this.CancelChanges();
            this.Trigger("DayViewClicked", this._state());
        });
    }

    OnReservationEdit(guid: string) {
        const disapprovedEditor = _.find(this._disapprovedEditors(), editor => editor.RowEditor.Guid === guid);

        if (!disapprovedEditor) {
            new Notifier().Failed(NOTIFICATIONS.ROW_EDITOR_NOT_FOUND);
            return;
        }

        disapprovedEditor.RowEditor.SetState(RowEditorState.Edit);

        this._viewState(ViewState.Edit);
    }

    OnReservationDelete(guid: string) {
        const disapprovedEditor = _.find(this._disapprovedEditors(), editor => editor.RowEditor.Guid === guid);

        if (!disapprovedEditor) {
            this.ShowError("Row not found");
            return;
        }

        this.AskDeleteConfirmation(() => {
            const reservation = _.find(this._viewModel().RequestedDate.Reservations(), reservation => reservation.Guid === guid);

            const deleteModel = DayMappingProfile.OnDeleteModel(reservation);
            deleteModel.TimeTableId = this._settings.EntityId;

            this.DeleteReservation(deleteModel)
                .then(() => this.SaveAndReload(disapprovedEditor.RowEditor))
                .fail(error => this.ShowError(error.message));
        });
    }

    OnSaveReservationChanges() {
        BlockUI.Block();
        this.SaveReservation()
            .then(rowEditor => this.SaveAndReload(rowEditor))
            .fail(error => {
                this.ShowError(error.message);
                BlockUI.Unblock();
            });
    }

    OnReservationAccept(guid: string) {
        const disapprovedEditor = _.find(this._disapprovedEditors(), editor => editor.RowEditor.Guid === guid);

        if (!disapprovedEditor) {
            this.ShowError("Row not found");
            this._viewState(ViewState.Read);
            return;
        }

        const reservation = _.find(this._viewModel().RequestedDate.Reservations(), reservation => reservation.Guid === guid);

        const acceptModel = DayMappingProfile.OnSaveModel(reservation);

        acceptModel.TimeTableId = this._settings.EntityId;
        acceptModel.UserId = this._viewModel().SelectedUser.Id;

        this.AcceptReservation(acceptModel)
            .then(() => this.SaveAndReload(disapprovedEditor.RowEditor))
            .fail(error => this.ShowError(error.message));
    }

    CancelChanges() {
        const disapprovedEditor = _.find(this._disapprovedEditors(), editor => editor.RowEditor.IsEditState || editor.RowEditor.IsNewState);

        this.OnCancelReservationChanges(disapprovedEditor.RowEditor.Guid);
    }

    OnCancelReservationChanges(guid: string) {
        const disapprovedEditor = _.find(this._disapprovedEditors(), editor => editor.RowEditor.Guid === guid);

        if (!disapprovedEditor) {
            new Notifier().Failed(NOTIFICATIONS.ROW_EDITOR_NOT_FOUND);
            return;
        }

        if (disapprovedEditor.RowEditor.IsNewState) {
            this.CancelNewReservation(disapprovedEditor);
        } else {
            this.CancelReservationChanges(disapprovedEditor.RowEditor);
        }
    }

    OnAllowedEntityClick(entity: AllowedEntity) {
        const disapprovedEditor = _.find(this._disapprovedEditors(), editor => editor.RowEditor.IsNewState);

        if (!disapprovedEditor) {
            this.ShowError("Row not found");
            this._viewState(ViewState.Read);
            return;
        }

        const reservation = _.find(this._viewModel().RequestedDate.Reservations(), reservation => reservation.Guid === disapprovedEditor.RowEditor.Guid);

        this.SearchSubjectForEntity(entity, reservation);
    }

    SelectSubjectMode(reservation: Reservation) {
        reservation.Subject(Subject.NotSpecified());
    }

    private UserSelected(user: UserViewModel) {
        this.Show(this._state().Date);
    }

    private GetNewReservationSubject(): P.Promise<Subject> {
        const deferred = P.defer<Subject>();

        if (this._settings.SubjectEntityId === null) {
            deferred.resolve(null);
        } else {
            this.RequestNewReservationSubject().always(subject => deferred.resolve(subject))
        }

        return deferred.promise();
    }

    private RequestNewReservationSubject(): P.Promise<Subject> {
        const deferred = P.defer<Subject>();

        const subjectIsAllowed = _.any(this._viewModel().AllowedEntities, e => e.Id === this._settings.SubjectEntityId);
        if (!subjectIsAllowed) {
            deferred.resolve(null);
        } else {
            TimeWritingStore.GetSubject(this._settings.SubjectEntityId, this._settings.SubjectRecordId)
                .then(record => {
                    const nameField = _.find<any>((<any>record).Fields, field => field.FieldName.toUpperCase() === "NAME");
                    const subject = new Subject({
                        EntityId: this._settings.SubjectEntityId,
                        EntityName: this._settings.SubjectEntityName,
                        SubjectId: this._settings.SubjectRecordId,
                        SubjectName: nameField.FieldValue,
                        Icon: this._settings.SubjectEntityIcon
                    });
                    deferred.resolve(subject);
                })
                .fail(() => deferred.resolve(null));
        }
        return deferred.promise();
    }

    private CancelNewReservation(disapprovedEditor: DisapprovedEditor) {
        const disapprovedEditorIndex = this._disapprovedEditors.indexOf(disapprovedEditor);
        this._disapprovedEditors.splice(disapprovedEditorIndex, 1);

        this._viewState(ViewState.Read);
    }

    private CancelReservationChanges(rowEditor: RowEditor) {
        rowEditor.CancelChanges();
        rowEditor.SetState(RowEditorState.Read);

        this._viewState(ViewState.Read);
    }

    private LoadUsers() {
        return TimeWritingStore.GetUsers(this._settings.EntityId)
            .then(users => users.Users.length === 0
                ? UsersResponseModel.CreateDefault()
                : UsersMappingProfile.OnViewModel(users)
            )
            .fail(error => new Notifier().Failed(error.message));
    }

    private LoadData(date: Date, userId: number): P.Promise<DisapprovedReportResponseModel> {
        return TimeWritingStore.GetDisapproved({
            ControlId: this._settings.ControlId,
            TimeTableId: this._settings.EntityId,
            SubjectEntityId: this._settings.SubjectEntityId,
            SubjectRecordId: this._settings.SubjectRecordId,
            UserId: userId
        });
    }

    private RenderData(model: DisapprovedReportResponseModel, users: UsersViewModel, selectedUserId?: number) {
        const viewModel = DisapprovedMappingProfile.OnViewModel(model);
        viewModel.Users = users;

        if (selectedUserId) {
            const selectedUser = viewModel.Users.Users().find(user => user.Id === selectedUserId);
            if (selectedUser) {
                viewModel.Users.SelectedUser(selectedUser);
            }
        }

        this._isEditable(model.Editable);
        this._disapprovedEditors([]);
        this.InitRowEditors(viewModel);
        this._viewModel(viewModel);

        if (model.GlobalSettings.FreezeTime === undefined) {
            this.ShowWarning("Freeze time not specified");
        }

        BlockUI.Unblock();
    }

    private ShowError(message: string) {
        new Notifier().Failed(message);
    }

    private ShowWarning(message: string) {
        new Notifier().Warning(message);
    }

    private ShowNotification(message: string) {
        new Notifier().Success(message);
    }

    private InitRowEditors(viewModel: DisapprovedDataViewModel) {
        viewModel.RequestedDate.Reservations().forEach(reservation => {
            let weekNumber = DateTime.GetWeekNumber(reservation.Start());
            let date = DateTime.GetWeekDescription(reservation.Start());
            this.AddDisapprovedEditor(reservation, viewModel.LookupFields, viewModel.AllowedEntities, RowEditorState.Read, weekNumber, date);
        });
    }

    private AddDisapprovedEditor(reservation: DisapprovedReservation,
                                 lookupFields: LookupField[],
                                 allowedEntities: AllowedEntity[],
                                 state: RowEditorState,
                                 weekNumber: number,
                                 date: string) {
        const rowEditor = this.GenerateRowEditor(reservation, lookupFields, allowedEntities);
        rowEditor.SetState(state);
        const disapprovedInfo = new DisapprovedInfo(reservation.DisapprovedReason, reservation.Comment, reservation.BookedCounter, weekNumber, date);

        this._disapprovedEditors.push(new DisapprovedEditor(disapprovedInfo, rowEditor));
    }

    private GenerateCellEditor(field: AttachedFieldModel, reservation: Reservation, lookupFields: LookupField[]): CellEditor {
        if (field.FieldTypeName === FIELD_TYPES.Lookup) {
            const dropdownField = _.find(lookupFields, f => f.Name === field.Name);

            return new DropdownCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                Options: ko.observableArray(dropdownField.Values),
                RequiresSearch: dropdownField.RequiresSearch,
                ValTableId: field.ValTableId,
                ValFieldId: field.ValFieldId,
                ValFieldTypeName: field.ValFieldTypeName,
                ValFieldFormatName: field.ValFieldFormatName,
                ValFieldSize: field.ValFieldSize,
                ViewTemplate: DropdownViewCellTemplate,
                EditTemplate: DropdownEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'ovw',
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value && value.FieldValue
                }
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.MultiSelect) {
            const dropdownField = _.find(lookupFields, f => f.Name === field.Name);

            return new MultiSelectCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                Options: ko.observableArray(dropdownField.Values),
                RequiresSearch: dropdownField.RequiresSearch,
                ValTableId: field.ValTableId,
                ValFieldId: field.ValFieldId,
                ValFieldTypeName: field.ValFieldTypeName,
                ValFieldFormatName: field.ValFieldFormatName,
                ValFieldSize: field.ValFieldSize,
                ViewTemplate: DropdownViewCellTemplate,
                EditTemplate: DropdownEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'ovw',
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value && value.FieldValue
                }
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.Integer) {
            return new IntegerCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: IntegerViewCellTemplate,
                EditTemplate: IntegerEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: null,
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value !== null && value !== undefined
                }
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.Decimal) {
            return new DecimalCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: DecimalViewCellTemplate,
                EditTemplate: DecimalEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: null,
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value !== null && value !== undefined
                },
                Size: field.Size,
                FormatName: field.FormatName
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.DateTime) {
            return new DateTimeCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: DateTimeViewCellTemplate,
                EditTemplate: DateTimeEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'from',
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value !== null && value !== undefined
                },
                FormatName: field.FormatName
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.Date) {
            return new DateCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: DateTimeViewCellTemplate,
                EditTemplate: DateTimeEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'from',
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value !== null && value !== undefined
                },
                FormatName: field.FormatName
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.Time) {
            return new TimeCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: TimeViewCellTemplate,
                EditTemplate: TimeEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'DateTime',
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value !== null && value !== undefined
                }
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.TimeSpan) {
            return new ValidationCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: TimeViewCellTemplate,
                EditTemplate: TimeEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'from',
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value !== null && value !== undefined
                }
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.YesNo) {
            return new ValueCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: YesNoViewCellTemplate,
                EditTemplate: YesNoEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'description'
            });
        }

        return new ValidationCellEditor({
            Name: field.Name,
            Label: field.FieldNameTranslation,
            Value: reservation.GetOptionalValue(field.Name),
            ViewTemplate: TextViewCellTemplate,
            EditTemplate: TextEditCellTemplate,
            Editable: true,
            Visible: ko.observable(true),
            ClassName: 'description',
            RequiredRule: {
                Message: `${field.FieldNameTranslation} is required`,
                Validate: value => !field.IsRequired || value !== null && value !== undefined
            }
        });
    }

    private GenerateRowEditor(reservation: Reservation, lookupFields: LookupField[], allowedEntities: AllowedEntity[]) {
        // const customFieldCellEditors = this._settings.CustomFields.map(field => this.GenerateCellEditor(field, reservation, lookupFields));
        const customFieldCellEditors = [];
        const visibleFields = this._settings.Properties.Groups[0].Properties;

        const hourKindLookupField = _.find(lookupFields, f => f.Name === "F_HOURKIND");
        const ovwLookupField = _.find(lookupFields, f => f.Name === "F_OVERWORKINDICATOR");
        const payLookupField = _.find(lookupFields, f => f.Name === "F_PAYMENTINDICATOR");

        return new RowEditor(reservation.Guid,
            null,
            lookupFields,
            new SubjectCellEditor({
                Name: "Subject",
                Label: this._labels.SUBJECT,
                Value: reservation.Subject,
                ViewTemplate: SubjectViewCellTemplate,
                EditTemplate: reservation.Subject().NotSpecified ? SelectableSubjectEditTemplate : SelectedSubjectEditTemplate,
                Editable: true,
                Visible: ko.observable(true),
                OnEntityClick: this.OnAllowedEntityClick.bind(this),
                OnEntityIconClick: this.SelectSubjectMode.bind(this, reservation),
                OnSubjectClick: this.SearchSubject.bind(this, reservation),
                AllowedEntities: allowedEntities,
                IsNewState: this._isNewState,
                RequiredRule: {
                    Validate: value => !value.NotSpecified,
                    Message: this._labels.SELECT__RESERVATION_SUBJECT
                },
                ClassName: 'subject'
            }),
            new ValidationCellEditor({
                Name: "Start",
                Label: this._labels.FROM,
                Value: reservation.Start,
                ViewTemplate: TimeViewCellTemplate,
                EditTemplate: TimeEditCellTemplate,
                Editable: true,
                Visible: ko.observable(visibleFields.find(field => field.Name === 'START').Value),
                ClassName: 'from'
            }),
            new CellEditor({
                Name: "-",
                Label: "",
                ViewTemplate: DashViewCellTemplate,
                Visible: ko.observable(visibleFields.find(field => field.Name === 'START').Value && visibleFields.find(field => field.Name === 'TO').Value),
                ClassName: 'dash'
            }),
            new ValidationCellEditor({
                Name: "To",
                Label: this._labels.TO,
                Value: reservation.To,
                ViewTemplate: TimeViewCellTemplate,
                EditTemplate: TimeEditCellTemplate,
                Editable: false,
                Visible: ko.observable(visibleFields.find(field => field.Name === 'TO').Value),
                ClassName: 'to'
            }),
            new ValidationCellEditor({
                Name: "TimeSpent",
                Label: this._labels.DURATION,
                Value: reservation.TimeSpent,
                ViewTemplate: TimeViewCellTemplate,
                EditTemplate: TimeEditCellTemplate,
                Editable: true,
                Visible: ko.observable(visibleFields.find(field => field.Name === 'TIMESPENT').Value),
                ClassName: 'duration'
            }),
            new DescriptionCellEditor({
                Name: "Description",
                Label: this._labels.DESCRIPTION,
                Value: reservation.Description,
                ViewTemplate: DescriptionViewCellTemplate,
                EditTemplate: DescriptionEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                RequiredRule: {
                    Validate: value => value && value.length > 0,
                    Message: this._labels.SPECIFY_RESERVATION_DESCRIPTION
                },
                MinLengthRule: {
                    Validate: value => value.length >= 3,
                    Message: this._labels.DESCRIPTION_MIN_LENGTH.replace('{minLengthValidation}', '3')
                },
                MaxLenghtRule: {
                    Validate: value => value.length <= 80,
                    Message: this._labels.DESCRIPTION_MAX_LENGTH.replace('{maxLengthValidation}', '80')
                },
                ClassName: 'description'
            }),
            new DropdownCellEditor({
                Name: "Hour",
                Label: this._labels.HOUR,
                Value: reservation.HourKind,
                Options: ko.observableArray(hourKindLookupField.Values),
                RequiresSearch: false,
                ValTableId: hourKindLookupField.ValTableId,
                ValFieldId: hourKindLookupField.ValFieldId,
                ValFieldTypeName: hourKindLookupField.ValFieldTypeName,
                ValFieldFormatName: hourKindLookupField.ValFieldFormatName,
                ValFieldSize: hourKindLookupField.ValFieldSize,
                ViewTemplate: DropdownViewCellTemplate,
                EditTemplate: DropdownEditCellTemplate,
                Editable: true,
                Visible: ko.observable(visibleFields.find(field => field.Name === 'F_HOURKIND').Value),
                ClassName: 'hour'
            }),
            new DropdownCellEditor({
                Name: "Ovw",
                Label: this._labels.OVW,
                Value: reservation.Ovw,
                Options: ko.observableArray(ovwLookupField.Values),
                RequiresSearch: false,
                ValTableId: ovwLookupField.ValTableId,
                ValFieldId: ovwLookupField.ValFieldId,
                ValFieldTypeName: ovwLookupField.ValFieldTypeName,
                ValFieldFormatName: ovwLookupField.ValFieldFormatName,
                ValFieldSize: ovwLookupField.ValFieldSize,
                ViewTemplate: DropdownViewCellTemplate,
                EditTemplate: DropdownEditCellTemplate,
                Editable: true,
                Visible: ko.observable(visibleFields.find(field => field.Name === "F_OVERWORKINDICATOR").Value),
                ClassName: 'ovw'
            }),
            new DropdownCellEditor({
                Name: "Pay",
                Label: this._labels.PAY,
                Value: reservation.Pay,
                Options: ko.observableArray(payLookupField.Values),
                RequiresSearch: false,
                ValTableId: payLookupField.ValTableId,
                ValFieldId: payLookupField.ValFieldId,
                ValFieldTypeName: payLookupField.ValFieldTypeName,
                ValFieldFormatName: payLookupField.ValFieldFormatName,
                ValFieldSize: payLookupField.ValFieldSize,
                ViewTemplate: DropdownViewCellTemplate,
                EditTemplate: DropdownEditCellTemplate,
                Editable: true,
                Visible: ko.observable(visibleFields.find(field => field.Name === "F_PAYMENTINDICATOR").Value),
                ClassName: 'pay'
            }),
            ...customFieldCellEditors,
            new ActionsCellEditor({
                Name: "Actions",
                Label: this._labels.ACTIONS,
                ViewTemplate: ActionsViewCellTemplate,
                EditTemplate: ActionsEditCellTemplate,
                Visible: this._isEditable() ? this._isReadState : ko.observable(false),
                Accepted: reservation.Accepted,
                OnEditReservation: this.OnReservationEdit.bind(this, reservation.Guid),
                OnDeleteReservation: this.OnReservationDelete.bind(this, reservation.Guid),
                OnAcceptReservation: this.OnReservationAccept.bind(this, reservation.Guid),
                OnSaveReservationChanges: this.OnSaveReservationChanges.bind(this),
                OnCancelReservationChanges: this.OnCancelReservationChanges.bind(this, reservation.Guid),
                TimeStatus: reservation.TimeStatus(),
                ClassName: 'actions'
            })
        );
    }

    private SearchSubject(reservation: Reservation) {
        const subject = reservation.Subject();

        const searchScreen = new SearchScreen({
            EntityId: subject.EntityId,
            SearchTerm: "",
            ButtonAdd: false
        });

        searchScreen.On("RECORD_SELECTED", this, eventArgs => this.SelectSubject(eventArgs.data, reservation));
        searchScreen.On("ALT_ENTITY_RECORD_SELECTED", this, (eventArgs) => {
            const data = eventArgs.data;
            UserVarsManager.Instance.AddRecent(data.EntityId, data.RecordId, data.TypeId);


            data.IsOpenInModal = false;
            PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, data);
        });


        searchScreen.Show();
    }

    private SearchSubjectForEntity(entity: AllowedEntity, reservation: Reservation) {
        const searchScreen = new SearchScreen({
            ConditionToggler: true,
            ControlId: this._settings.ControlId,
            EntityId: entity.Id,
            SearchTerm: "",
            ButtonAdd: false
        });

        searchScreen.On("RECORD_SELECTED", this, eventArgs => this.SelectSubjectForEntity(eventArgs.data, entity, reservation));
        searchScreen.On("ALT_ENTITY_RECORD_SELECTED", this, (eventArgs) => {
            const data = eventArgs.data;
            UserVarsManager.Instance.AddRecent(data.EntityId, data.RecordId, data.TypeId);


            data.IsOpenInModal = false;
            PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, data);
        });


        searchScreen.Show();
    }

    private SelectSubject(subjectData: any, reservation: Reservation) {
        const oldSubject = reservation.Subject();

        const newSubject = new Subject({
            EntityId: oldSubject.EntityId,
            EntityName: oldSubject.EntityName,
            SubjectId: subjectData.RecordId,
            SubjectName: subjectData.Name,
            Icon: oldSubject.Icon
        });

        reservation.Subject(newSubject);
    }

    private SelectSubjectForEntity(subjectData: any, entity: AllowedEntity, reservation: Reservation) {
        const newSubject = new Subject({
            EntityId: entity.Id,
            EntityName: entity.Name,
            SubjectId: subjectData.RecordId,
            SubjectName: subjectData.Name,
            Icon: entity.Icon
        });

        reservation.Subject(newSubject);
    }

    private AcceptReservation(reservation: SaveReservationRequestModel) {
        return TimeWritingStore.AcceptReservation(reservation);
    }

    private UpdateReservation(reservation: SaveReservationRequestModel) {
        return TimeWritingStore.UpdateReservation(reservation);
    }

    private DeleteReservation(reservation: DeleteReservationRequestModel) {
        return TimeWritingStore.DeleteReservation(reservation);
    }

    private CommitChanges(rowEditor: RowEditor) {
        rowEditor.SaveChanges();
        rowEditor.SetState(RowEditorState.Read);
        this._viewState(ViewState.Read);
        this.ShowNotification(NOTIFICATIONS.CHANGES_APPLIED);
    }

    private SaveAndReload(rowEditor: RowEditor) {
        this.CommitChanges(rowEditor);
        this.Show(this._state().Date);
    }

    private AskConfirmation(message: string, onConfirm: () => void, onDecline: () => void) {
        const confirmationDialog = new ConfirmationDialog({
            Text: message,
            Type: ConfirmationTypes.Question,
            TextConfirm: "Yes",
            TextDecline: "Cancel"
        });

        confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => onConfirm());
        confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.DISCARD_SELECTED, this, () => onDecline());

        confirmationDialog.Show();
    }

    private AskSaveConfirmation(onConfirm: () => void, onDecline: () => void) {
        this.AskConfirmation("Save changes?", onConfirm, onDecline);
    }

    private AskDeleteConfirmation(onConfirm: () => void) {
        this.AskConfirmation("Are you sure you want delete this record?", onConfirm, () => {
        });
    }

    private GetActiveRow() {
        return _.find(this._disapprovedEditors(), (item: DisapprovedEditor) => item.RowEditor.IsNewState || item.RowEditor.IsEditState);
    }

    private SaveReservation(): P.Promise<RowEditor> {

        const result = P.defer<RowEditor>();

        const disapprovedEditor = this.GetActiveRow();

        if (!disapprovedEditor) {
            this._viewState(ViewState.Read);
            result.reject({message: "Row not found"});
            return result.promise();
        }

        if (!disapprovedEditor.RowEditor.Validate()) {
            result.reject({message: "Fill required data"});
            return result.promise();
        }


        const reservation = _.find(this._viewModel().RequestedDate.Reservations(), reservation => reservation.Guid === disapprovedEditor.RowEditor.Guid);
        reservation.ChangeDate(this._state().Date);

        const updateModel = DayMappingProfile.OnSaveModel(reservation);

        updateModel.TimeTableId = this._settings.EntityId;
        updateModel.UserId = this._viewModel().SelectedUser.Id;

        this.UpdateReservation(updateModel)
            .then(() => result.resolve(disapprovedEditor.RowEditor))
            .fail(error => result.reject({message: error.message}));

        return result.promise();
    }
}