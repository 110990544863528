import * as _ from "underscore";
import * as ko from 'knockout'

import {Serialize} from 'libs/cerialize';

import {P} from 'Core/Common/Promise'
import {Notifier} from "Core/Common/Notifier"

import {CONTROL_TYPES, FIELD_TYPES, FONT_NAME, RenderModes, SYSTEM_TABLE_NAMES} from 'Core/Constant'
import {SECURITY_LEVELS} from 'Core/Constants/SecurityLevels';

import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';

import {BaseControl, IControlValue} from 'Core/Controls/BaseControl/BaseControl'
import {IControlParam} from 'Core/Screens/IScreen'
import {LinkListRelationsStore} from 'Core/Controls/LinkList/Stores/LinkListStore'
import {UserModel} from 'Core/Controls/LinkList/Models/UserModel';
import {EntityRelations, LinkListRelationsModel} from 'Core/Controls/LinkList/Models/LinklistRelationsModel'
import {
    EntityRelationsViewModel,
    LinkListRelationsViewModel,
    RelationRecordViewModel,
    UserEntityRelationsViewModel
} from 'Core/Controls/LinkList/Models/LinkListRelationsViewModel';
import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel'
import {Icon} from 'Core/Icon/Icon'
import { SearchScreen, ISelectedRecord} from 'Core/Screens/SearchScreen/SearchScreen'
import {NewRelationStore} from 'Core/Controls/LinkList/Stores/NewRelationStore'
import {EditLinkDataModel, UpdateDataModel} from 'Core/Controls/LinkList/Models/UpdateDataModel'
import {UserManager} from "User/UserManager";
import {
    ConfirmationDialog,
    EVENTS as ConfirmationDialogEvents,
    Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import {BlockUI} from 'Core/Common/BlockUi';
import {DataModel as LinkEditorDataModel} from 'Core/Screens/Common/LinkEditor/Models/DataModel'

import {TypeScreen} from "Core/Screens/TypeScreen/TypeScreen";


import {LinkEditorStore} from 'Core/Screens/Common/LinkEditor/Stores/LinkEditorStore'

import {ActionSubjectRecordModel} from "Core/ScreenManager/Models/ActionSubjectRecordModel";
import {RecordSpecsModel} from "Core/ScreenManager/Models/RecordSpecsModel";
import {DataModes} from "Core/Enums/DataModes";
import { AutoLinkedRecordModel } from "Core/Controls/LinkList/Models/AutoLinkedRecordModel";
import { AutolinkModel } from "Core/Controls/LinkList/Models/AutoLinkModel";
import {CONFIRMATIONS, LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";
import {AGENDA_TYPES} from "Core/Constants/AgendaTypes";
import { NewRelationModel } from "./Models/NewRelationModel";
import { SearchByMainRelationTypes } from 'Core/Screens/SearchScreen/Enums/Enums';
import {EditScreen} from "Core/Screens/EditScreen/EditScreen";
import {UserVarsManager} from "Core/UserVarsManager/UserVarsManager";
import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";
import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

const RELATION_TYPE_VALUE_FIELD: string = 'F_RELATIONTYPEVALUE';

import LinkListConfig from 'Core/Controls/LinkList/Configs/link-list-config.json';

// Templates
import ViewTemplate from 'Core/Controls/LinkList/Templates/View.html'
import ToolBarTemplate from 'Core/Controls/LinkList/Templates/ToolBar.html'
import DesignTemplate from 'Core/Controls/LinkList/Templates/Design.html'
import { DataCell } from '../Grid/BaseGrid/Index';

ko.templates['Core/Controls/LinkList/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/LinkList/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/LinkList/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/LinkList/Templates/Design'] = DesignTemplate;

export class LinkList extends BaseControl {
    private _serverDataModel: LinkListRelationsModel;
    private _dataModel: KnockoutObservable<LinkListRelationsViewModel>;
    private _subjectRecordId: number;
    private _updateDataModel: UpdateDataModel;
    private _designFields: KnockoutObservableArray<AttachedFieldModel>;
    private _searchScreen: SearchScreen;
    private _openNumbers: Array<any>;
    private _linkTypes: Array<any>;
    private _linkTypesInstancesLeft: Array<any>;
    private _isAuoProperties: KnockoutObservable<boolean>;
    private _licenseName: string;

    private _actionGlobal: string;

    constructor(params: IControlParam) {
        super(params, LinkListConfig);
        this._designFields = ko.observableArray(this._model().Fields);
        this._isAuoProperties = ko.observable(null);

        this._model.subscribe(newValue => {
            this._designFields(this._model().Fields);
        });

        this._designFields.subscribe(fields => {
            _.each(fields, (field, index) => {
                field.Sort = index * 10;
            });
        });

        this._serverDataModel = null;
        this._dataModel = ko.observable(null);
        this._linkTypes = [];
        this._openNumbers = [];
        this._licenseName = null;

        this._actionGlobal = GlobalManager.Instance.GetGlobal(GLOBALS.ACTION_TABLE);

        this.Init();
    }

    ApplyProperties(){}

    IsAuoProperties(): boolean {
        _.each(this.GeneralProperties.Groups, (group) => {
            let propertyAuto = group.Properties.filter(property => property.Type === 'Auto');

            if (propertyAuto) {
                _.each(propertyAuto, (propertyModel)=>{
                    this._isAuoProperties(!!propertyModel.Value);
                });
            } else {
                this._isAuoProperties(false);
            }
        });
        return this._isAuoProperties();
    }

    get LinkListAutoProperty(): boolean {
        return this.IsAuoProperties();
    }

    GetDefaultName() {
        return "LinkList";
    }

    private Init(): void {
        this.AddEvent('LinkListDispose');
        this.AddEvent('NEW_RECORD_SAVED');
    }

    @enumerable get DesignFields(): KnockoutObservableArray<AttachedFieldModel> {
        return this._designFields;
    }

    SetValue(value: IControlValue): void {
        const dataMode = this.GetForm().GetScreen().GetDataMode();
        this._subjectRecordId =
            value.RecordSpecsModel.IsNewRecord || dataMode !== DataModes.Default ? 0 : value.SubjectRecordId;
        const subjectLifeStatusId = this.GetForm().GetScreen().GetSubjectLifeStatusId();
        this.LoadDataInDefaultMode(value, subjectLifeStatusId);
    }

    private SetInitialLinkTypesState(entities) {

        this._linkTypes = [];
        entities.map(entity => this._linkTypes.push(
            {
                EntityName: entity.EntityName == null ? entity.EntityName : entity.EntityNameTranslation,
                Records: entity.Records.map((record) => {
                    return {
                        RecordId: record.Id,
                        LinkTypes: entity.LinkTypes
                    };
                }),
                LinkTypes: entity.LinkTypes,
                EntityId: entity.EntityId,
            }
        ));

        const stringified = JSON.stringify(this._linkTypes);
        this._linkTypesInstancesLeft = JSON.parse(stringified);
    }

    UpdateLinkTypesState(entityName, recordId, typeName, valueToUpdateWith) {
        const selectedEntity = _.where(this._linkTypesInstancesLeft, {EntityName: entityName})[0];
        const relatedEntity: any = selectedEntity && _.where(selectedEntity.Records, {RecordId: recordId})[0] as any;
        if (!relatedEntity) {
            return null;
        }
        const selectedLinkType = relatedEntity && _.where(relatedEntity.LinkTypes, {Name: typeName});
        if (!selectedLinkType) {
            return null;
        }
        if (selectedLinkType.length) {
            selectedLinkType.map((linkType: any) => {
                linkType.InstancingMaximum += valueToUpdateWith;
            });
        }

        BlockUI.Unblock();
    }


    GetLinkTypesState(entityName, recordId) {
        const relatedEntity = _.where(this._linkTypesInstancesLeft, {EntityName: entityName})[0];
        let relatedRecord = null;
        if (relatedEntity) relatedRecord = _.where(relatedEntity.Records, {RecordId: recordId})[0];

        return relatedRecord && relatedRecord.LinkTypes || null;
    }

    private LoadDataInDefaultMode(value: IControlValue, subjectLifeStatusId: number) {
        const saveButton = this._form.GetScreen().GetControl(CONTROL_TYPES.ButtonSave);
        this.LoadData(value, subjectLifeStatusId)
            .always(() => {
                BlockUI.Unblock(saveButton.GetWrapper());
                BlockUI.Unblock(this._el);
            })
            .then(model => {

                $(saveButton.GetWrapper()).css('pointer-events', 'auto');
				if (model.Warnings && model.Warnings.length > 0) {
					model.Warnings.forEach(warning => {
						if (warning) {
							new Notifier().Failed(warning);
						}
					});
				}
                this.SetInitialLinkTypesState(model.Entities);

                this.ApplyData(model, value.RecordSpecsModel);
            })
            .fail(error => new Notifier().Failed(error.message));
    }

    private LoadData(value: IControlValue, subjectLifeStatusId?: number) {
        this._updateDataModel = new UpdateDataModel();

        const screenModel = this.GetForm().GetScreen();
        const dataMode = screenModel.GetDataMode();
        const actionSubjectRecord = screenModel.GetActionSubjectRecord();
        const tableTypeId = screenModel.GetTableTypeId();

        let subjectEntityId;
        let subjectRecordId;

        if (actionSubjectRecord) {
            subjectEntityId = actionSubjectRecord.EntityId;
            subjectRecordId = actionSubjectRecord.RecordId;
        }

		const checkOneParent = dataMode === DataModes.CopyWithRelations || dataMode === DataModes.CopyToType || dataMode === DataModes.FollowUp
			|| (value.RecordSpecsModel && value.RecordSpecsModel.IsExample);

        return LinkListRelationsStore.GetLinkedRecords({
            ControlId: this.GetControlId(),
            RecordId: value.SubjectRecordId,
            EntityId: value.SubjectEntityId,
            SubjectEntityId: subjectEntityId,
            SubjectRecordId: subjectRecordId,
            SubjectLifeStatusId: subjectLifeStatusId,
			WithoutRelations: dataMode === DataModes.Copy,
			CheckOneParent: checkOneParent,
            TableTypeId: tableTypeId
        });
    }

    private FireDataChangeEvent(model: LinkListRelationsModel){
        _.each(model.Entities, (entity)=>{
            _.each(entity.Records, (record)=>{
                let field = _.find(this.Fields, f=> f.EntityId === entity.EntityId);
                if(record.IsMain && field){
                    this.UpdateVariable({ Field: this.GetPrimaryKeyField(field), ControlType: CONTROL_TYPES.LinkList }, record.Id);
                }
            });
        });
    }

    GetPrimaryKeyField(attached: AttachedFieldModel): AttachedFieldModel{
        let primaryKey = new AttachedFieldModel();
        primaryKey.FieldTypeName = FIELD_TYPES.PKey;
        primaryKey.Name = attached.PrimaryKeyName;
        primaryKey.EntityName = attached.EntityName;
        return primaryKey;
    }

    private ApplyData(model: LinkListRelationsModel, recordSpecs: RecordSpecsModel) {
        const self = this;

        this._updateDataModel = new UpdateDataModel();
        this._updateDataModel.ActionSubjectRecord = model.ActionSubjectRecord;

        this._serverDataModel = model;
        this._dataModel(new LinkListRelationsViewModel({
            Model: model,
            RecordOwner: recordSpecs.RecordOwner,
            IsExample: recordSpecs.IsExample
        }));

        this.FireDataChangeEvent(model);

		if (recordSpecs.IsNewRecord || this._subjectRecordId === 0) {
			const editScreen = this.GetForm().GetScreen() as EditScreen;

			if (!editScreen.IsDataFromExample) {
				_.each(this._dataModel().Entities(), entity => {
					if (entity) {
						_.each(entity.Records(), record => {
							let newRecord = new RelationRecordViewModel();
							newRecord.Id = record.Id;
							newRecord.Guid = record.Guid;
							newRecord.Level = record.Level;
							newRecord.IsNew = true;
							newRecord.IsMain = record.IsMain;

							this._updateDataModel.AddNewRelation(self.GetControlId(), self._subjectRecordId, entity.EntityId, newRecord);

							if (record.IsMain()) {
								this.ChangeRelation(record, entity, false);
							}
						});
					}
				});
			}

			if (editScreen.GetEntityName() === 'AGENDA') {
			    if (editScreen.GetTableTypeName() === AGENDA_TYPES.Appointment) {
                    if (editScreen.IsDataFromExample) {
                        this.EnablePlanner();
                    }
                }
            }

			this.AddRecordOwner(recordSpecs)
                .then(() => this.Screen.Trigger('LINK_LIST_DATA_LOADED', {Control: this}));
            return;
        }
        this.Screen.Trigger('LINK_LIST_DATA_LOADED');
    }

    AfterRender(el: Array<HTMLElement>): void {
        if (el) {
            this._el = el[0];
        }

        if (this._renderMode() !== RenderModes.Design) {
            const saveButton = this._form.GetScreen().GetControl(CONTROL_TYPES.ButtonSave);
            $(saveButton.GetWrapper()).css('pointer-events', 'none');
            BlockUI.Block({Target: saveButton.GetWrapper()});
            BlockUI.Block({Target: this._el});
        }
        super.AfterRender(el);
    }

    @enumerable get DataModel() {
        return this._dataModel;
    }

    @enumerable get Fields() {
        return this._model().Fields;
    }

    @enumerable get Name(): string {
        return this._model().Name === SYSTEM_TABLE_NAMES.SYS_USERS ? 'USERS' : this._model().Name;
    }

    @enumerable get LicenseName() {
        return this._licenseName;
    }

    set LicenseName(licenseName) {
        this._licenseName = licenseName;
    }

    GetDesignIcon(model: AttachedFieldModel) {
        const entityIcon = model.EntityIcon;
        entityIcon.IsImage = entityIcon.IsImage ? !_.isEmpty(entityIcon.Name) : !_.isEmpty(entityIcon.Image);
        entityIcon.IsIcon = !_.isEmpty(entityIcon.Name) && entityIcon.IsIcon;

        var icon = new Icon(entityIcon);
        if (!icon.IsIcon && !icon.IsImage) {
            return new Icon({
                FontName: FONT_NAME.FONT_AWESOME,
                Image: null,
                IsIcon: false,
                IsImage: false,
                Name: "",
                Id: null
            });
        } else {
            return icon;
        }
    }

    GetEntityName(model: AttachedFieldModel): string {
        if (model.EntityName === SYSTEM_TABLE_NAMES.SYS_USERS && !!model.EntityNameTranslation){
            return model.EntityNameTranslation === SYSTEM_TABLE_NAMES.SYS_USERS ? 'USERS' : model.EntityNameTranslation;
        } else if (model.EntityName !== SYSTEM_TABLE_NAMES.SYS_USERS){
            return model.EntityNameTranslation || model.EntityName;
        } else if (model.EntityName === SYSTEM_TABLE_NAMES.SYS_USERS && !model.EntityNameTranslation){
            return 'USERS';
        }
    }

    GetDisplayedName(model: AttachedFieldModel): string {
        const entityName = this.GetEntityName(model);
        const fieldName = model.FullName;
        return `${entityName}.${fieldName}`;
    }

    GetIcon(model?: EntityRelationsViewModel) {
        var fieldByEntity = _.find(this._model().Fields, item => {
            return item.EntityId === model.EntityId
        });

        const entityIcon = fieldByEntity.EntityIcon;
        entityIcon.IsImage = entityIcon.IsImage ? !_.isEmpty(entityIcon.Name) : !_.isEmpty(entityIcon.Image);
        entityIcon.IsIcon = !_.isEmpty(entityIcon.Name) && entityIcon.IsIcon;

        var icon = new Icon(entityIcon);
        if (!icon.IsIcon && !icon.IsImage) {
            return new Icon({
                FontName: FONT_NAME.FONT_AWESOME,
                Image: null,
                IsIcon: false,
                IsImage: false,
                Name: "",
                Id: null
            });
        } else {
            return icon;
        }
    }

    GetIconByEntityId(entityId: number) {
        let model = _.find(this._dataModel().Entities(), entity => entity.EntityId === entityId);
        var fieldByEntity = _.find(this._model().Fields, item => {
            return item.EntityId === model.EntityId
        });
        var icon = new Icon(fieldByEntity.EntityIcon);
        return icon;
    }

    FindLinkByRecord(recordId: number, model: EntityRelationsViewModel) {
        return model.Records().find(record => record.Id === recordId);
    }

    AddRecord(recordId: number, model: EntityRelationsViewModel, newRelation: NewRelationModel, openLinkEditor: boolean = false) {
        let isRecordFirst = model.Records().length < 1;
        let isMain = newRelation.IsMain || isRecordFirst;

        const newRecord = new RelationRecordViewModel();
        newRecord.Id = newRelation.Id;
        newRecord.Name = newRelation.Name;
        newRecord.NameTranslation = newRelation.NameTranslation;
        newRecord.IsMain(isMain);
        newRecord.Level = newRelation.Level;
        newRecord.TypeName = newRelation.TypeName;
        newRecord.TypeTranslatedName = newRelation.TypeTranslatedName;
        newRecord.IsRecordOwner = newRelation.IsRecordOwner;
        newRecord.UserAllowance = newRelation.UserAllowance;
        newRecord.IsNew = true;
        newRecord.IsNewRecord = newRelation.IsNewRecord;

        const isActionsScreen = this.GetForm().GetScreen().GetEntityName() === this._actionGlobal;

        if (isActionsScreen && !this._updateDataModel.ActionSubjectRecord && model.EntityName !== 'SYS_USERS') {
            this._updateDataModel.ActionSubjectRecord = new ActionSubjectRecordModel(model.EntityId, recordId);
            new Notifier().Success(NOTIFICATIONS.REFERENCE_WAS_SET);
        }

        if (model.EntityName === 'SYS_USERS' && this._dataModel().UserRelation().SingleMode) {
            if (newRecord.SharingReady) {
                newRecord.Level -= SECURITY_LEVELS.SHARING_READY;
            }

            newRecord.Level += SECURITY_LEVELS.SHARED;
        }

        this._updateDataModel.AddNewRelation(this.GetControlId(), this._subjectRecordId, model.EntityId, newRecord);

        model.Records.push(newRecord);
        this._dataModel.valueHasMutated();

        if (model.EntityName === SYSTEM_TABLE_NAMES.SYS_USERS) {
            this._updateDataModel.ChangeUserLevel(model.EntityId, newRecord.Id, newRecord.Level);
        }

        if (isRecordFirst && model.EntityName !== SYSTEM_TABLE_NAMES.SYS_USERS) {
            this.ChangeRelation(newRecord, model);
        }

        this.AutoLink(model.AutoLinks, newRecord.Id);

        if (newRelation.HasCustomFields && openLinkEditor) {
            this.EditRecord(model, newRecord, this._linkTypes);
        }

        if(newRecord.IsMain()){
            if (this._form) {

                let field = _.find(this.Fields, f=> f.EntityId === model.EntityId);
                if(field){
                    this.UpdateVariable({ Field: this.GetPrimaryKeyField(field), ControlType: CONTROL_TYPES.LinkList }, newRecord.Id);
                }                
            }              
        }


        return newRecord.Guid;
    }

    UpdateCustomDataByGuid(guid: string, fieldValue: { FieldId: number, FieldValue: string }[]) {
        let linkEditorChanges = this._updateDataModel.LinkEditorChanges.find(link => link.Guid === guid);

        if (!linkEditorChanges) {
            const link = this.FindLink(guid);
            if (!link) {
                return;
            }

            const dataModel = new LinkEditorDataModel();
            dataModel.EntityId = link.EntityId;
            dataModel.RecordId = link.Link.Id;

            linkEditorChanges = new EditLinkDataModel(dataModel, guid);
            this._updateDataModel.LinkEditorChanges.push(linkEditorChanges);
        }

        for (const value of fieldValue) {
            let existingValue = linkEditorChanges.FieldValues.find(v => v.FieldId === value.FieldId);

            if (!existingValue) {
                linkEditorChanges.FieldValues.push({FieldId: value.FieldId, FieldValue: ['', value.FieldValue]});
                continue;
            }

            existingValue.FieldValue = ['', value.FieldValue];
        }
    }

    GetRelations() {
        var searchByRelations = [];
        _.each(this._dataModel().Entities(), entity => {
            _.each(entity.Records(), record => {
                if (record.IsNew) {
                    searchByRelations.push({EntityId: entity.EntityId, RecordId: record.Id, IsMain: record.IsMain()});
                }
            });

        });

        return searchByRelations;
    }

    LinkRecord(data, model: EntityRelationsViewModel, event) {
        event.stopPropagation();
        let firstSearchByMainRelations = true;
        let firstSearchByRelations = true;

        let isBulkBarcodeScanning = false;
        this._searchScreen = new SearchScreen({
            EntityId: model.EntityId,
            SearchTerm: '',
            Sharing: data.EntityName === SYSTEM_TABLE_NAMES.SYS_USERS,
            SubjectEntityId: this.GetForm().GetScreen().GetEntityId(),
            SubjectTypeId: this.GetForm().GetScreen().GetTableTypeId(),
            SubjectRecordId: this.GetForm().GetScreen().GetRecordId(),
            SearchByRelations: this.GetRelations(),
            SearchByMainRelationType: SearchByMainRelationTypes.ByMainRelations,
            EnableBulkScan: true,
            MultiSelectMode: true
        });

        this._searchScreen.On('NEW_RECORD', this, () => {
            const selfRelation = this.GetForm().GetScreen().GetEntityId() === model.EntityId;
            const typeScreen = new TypeScreen(
                model.EntityId,
                selfRelation ? this.GetForm().GetScreen().GetTableTypeId() : 0,
                false);

            typeScreen.On('TYPES_NOT_FOUND', this, (eventArgs) => new Notifier().Warning(eventArgs.data.Message || NOTIFICATIONS.SUB_TYPE_NOT_FOUND));

            typeScreen.On('TYPE_SELECTED', this, (eventArgs) => {
                const typeId = eventArgs.data.TypeId;
                const kindId = eventArgs.data.KindId;
                const exampleRecordId = eventArgs.data.ExampleRecordId;

                this.NewRecord(model.EntityId, typeId, kindId, exampleRecordId);
                this.One('NEW_RECORD_SAVED', this, eventArgs => {
                    this.LinkSelectedRecord(model, [eventArgs.data.RecordId], true);
                    this._searchScreen.Close();
                });
            });

            typeScreen.Show();
        });

        this._searchScreen.On("RECORDS_NOT_FOUND", this, (eventArgs) => {
            if (firstSearchByMainRelations && this._searchScreen.GetFilterByRelationType() === SearchByMainRelationTypes.ByMainRelations) {
                Notifier.Warning(NOTIFICATIONS.NO_DATA_FOUND_WITH_APPLIED_FILTER);
                this._searchScreen.SetFilterByRelationType(SearchByMainRelationTypes.Off);
                firstSearchByMainRelations = false;
            } else if (firstSearchByRelations && this._searchScreen.GetFilterByRelationType() === SearchByMainRelationTypes.ByRelations) {
                Notifier.Warning(NOTIFICATIONS.NO_DATA_FOUND_WITH_APPLIED_FILTER);
                this._searchScreen.SetFilterByRelationType(SearchByMainRelationTypes.Off);
                firstSearchByRelations = false;
            }
        });
        this._searchScreen.On("RECORD_SELECTED", this, (eventArgs) => this.LinkSelectedRecord(model, [eventArgs.data.RecordId], undefined, isBulkBarcodeScanning));
        this._searchScreen.On("RECORDS_SELECTED",
            this,
            (eventArgs) => this.LinkSelectedRecord(model, eventArgs.data.Ids, undefined,isBulkBarcodeScanning)
        );

        this._searchScreen.On("BULK_BARCODE_SCAN_STARTED", this, () => {
            isBulkBarcodeScanning = true;

            this._searchScreen.OpenBarcodeScannerForBulkScan();
        });
        this._searchScreen.On("BULK_BARCODE_SCAN_STOPPED", this, () => {
            isBulkBarcodeScanning = false;
        });
        this._searchScreen.Show();
    }

    private LinkSelectedRecord(model: EntityRelationsViewModel, recordIds: number[], isNewRecord: boolean = false, isBulkBarcodeScanning: boolean = false) {
        let isLinkingAllowed = true;
        let differenceIds = [];
        if (!model.HasSequence) {
            differenceIds = _.difference(recordIds, _.map(model.Records(), (item) => item.Id));
            isLinkingAllowed = differenceIds.length > 0;
        } else {
            differenceIds = recordIds;
        }

        if (model.EntityName === 'SYS_USERS' && this._dataModel().UserRelation().SingleMode) {
            isLinkingAllowed = true;
        }

        if (isLinkingAllowed) {
            this.AfterSelectRecord(differenceIds, model, isNewRecord, isBulkBarcodeScanning)
                .always(() => {
                    if (isBulkBarcodeScanning) {
                        this._searchScreen.OpenBarcodeScannerForBulkScan();
                    }
                });
        } else {
            let notifier = new Notifier($(this._el));
            notifier.Warning(NOTIFICATIONS.RECORD_ALREADY_EXISTS);

            if (isBulkBarcodeScanning) {
                this._searchScreen.OpenBarcodeScannerForBulkScan();
            }
        }
    }

    AfterSelectRecord(recordIds: number[], model: EntityRelationsViewModel, isNewRecord: boolean = false, isBulkBarcodeScanning: boolean = false) {
        BlockUI.Block();
        return NewRelationStore.GetNewRelation({
            ControlId: this.GetControlId(),
            EntityId: this.GetForm().GetScreen().GetEntityId(),
            RecordId: this._subjectRecordId,
            RelatedEntityId: model.EntityId,
            RelatedRecordIds: recordIds,
			SubjectRecordId: this._subjectRecordId
        })
            .then(newRelations => {
                let openLinkEditor = !isBulkBarcodeScanning && newRelations.length === 1;
                _.each(newRelations, (newRelation) => {
                    newRelation.IsNewRecord = isNewRecord;

                    if (model.EntityName === 'SYS_USERS') {
                        if (this._dataModel().UserRelation().SingleMode) {
                            const sharedUsers = _.filter(this._dataModel().UserRelation().Records(), user => user.Shared);
                            this.MakeSharingReady(model, sharedUsers);
                        } else {
                            newRelation.Level = SECURITY_LEVELS.SHARED;
                        }

                        this._dataModel.valueHasMutated();
                    }

                    const isValid: boolean = model._isValidEntity();
                    if (!isValid){
                        model._isValidEntity(true);
                    }

                    this.AddRecord(newRelation.Id, model, newRelation, openLinkEditor);
                })
			})
			.fail(error => {
                new Notifier($(this._el)).Failed(error.message);
            })
            .always(() => {
            BlockUI.Unblock();
        });
    }

    AutoLink(autoLinks: Array<AutolinkModel>, linkedRecordId: number) {
        _.each(autoLinks,
            entityToEntity => {
                var currentEntityFromList = _.find(this._dataModel().Entities(), entity => entity.EntityId === entityToEntity.RightEntityId);

                if (currentEntityFromList) {
                    BlockUI.Block();
                    NewRelationStore
                        .GetNewAutolinkRelation({
                            NewRelationRequestItem: {
                                ControlId: this.GetControlId(),
                                EntityId: this.GetForm().GetScreen().GetEntityId(),
                                RecordId: this._subjectRecordId,
                                RelatedEntityId: currentEntityFromList.EntityId,
								RelatedRecordIds: [-1],
								SubjectRecordId: this._subjectRecordId
                            },
                            RecordId: linkedRecordId,
                            LeftEntityId: entityToEntity.LeftEntityId,
                            RightEntityId: entityToEntity.RightEntityId
                        })

                        .then(newRelation => {
                            if (newRelation) {

                                if (_.filter(currentEntityFromList.Records(),
                                    item => item.Id === newRelation.Id).length > 0) {
                                    return;
                                }

                                let isRecordFirst = currentEntityFromList.Records().length < 1;
                                let isMain = newRelation.IsMain || isRecordFirst;
                                newRelation.Level = SECURITY_LEVELS.SHARED;

                                var newRecord = new RelationRecordViewModel();
                                newRecord.Id = newRelation.Id;
                                newRecord.Name = newRelation.Name;
                                newRecord.NameTranslation = newRelation.NameTranslation;
                                newRecord.IsMain(isMain);
                                newRecord.Level = newRelation.Level;
                                newRecord.TypeName = newRelation.TypeName;
                                newRecord.TypeTranslatedName = newRelation.TypeTranslatedName;

                                this._updateDataModel
                                    .AddNewRelation(this.GetControlId(), this._subjectRecordId, currentEntityFromList.EntityId, newRecord);

                                currentEntityFromList.Records.push(newRecord);
                                this.AddRelatedRecord(entityToEntity.LeftEntityId, newRecord.Id, entityToEntity.RightEntityId, newRecord.Name, newRecord.NameTranslation, linkedRecordId);
                                this._dataModel.valueHasMutated();

                                if (currentEntityFromList.EntityName === SYSTEM_TABLE_NAMES.SYS_USERS) {
                                    this._updateDataModel.ChangeUserLevel(currentEntityFromList.EntityId, newRecord.Id, newRecord.Level);
                                }

                                if (isRecordFirst) {
                                    this.ChangeRelation(newRecord, currentEntityFromList);
                                }

                                if (currentEntityFromList.EntityName && currentEntityFromList.EntityName.toUpperCase() === 'COMPANIES') {
                                    this.ChangeMainCompany();
                                }

                                this.AutoLink(currentEntityFromList.AutoLinks ,newRelation.Id);
                                new Notifier().Success(NOTIFICATIONS.RECORD_WAS_AUTOLINKED);
                            }
                        })
                        .always(() => {
                            BlockUI.Unblock();
                        });

                }
            }
        );
    }

    private FindLink(guid: string) {
        let link = this._dataModel().UserRelation().Records().find(r => r.Guid === guid);

        if (link) {
            return {
                Link: link,
                EntityId: this._dataModel().UserRelation().EntityId
            };
        }

        for (const entity of this._dataModel().Entities()) {
            link = entity.Records().find(r => r.Guid === guid);
            if (link) {
                return {
                    Link: link,
                    EntityId: entity.EntityId
                };
            }
        }
    }

    private async NewRecord(entityId: number, tableTypeId: number, kindId: number, exampleRecordId: number) {
        BlockUI.Block();

        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

        screenManager.GetEditScreen({
            EntityId: entityId,
            TableTypeId: tableTypeId,
            KindId: kindId,
            RecordId: exampleRecordId,
            LoadAsExample: exampleRecordId > 0,
            ParentRecordId: this.GetForm().GetScreen().GetRecordId()
        })
            .always(() => {
                BlockUI.Unblock();
            })
            .then((screen: EditScreen) => {
                const editScreen = screen;

                editScreen.IsDataFromExample = exampleRecordId > 0;
                editScreen.ParentRecordId = this.GetForm().GetScreen().GetRecordId();
                editScreen.UseLinking = true;

                screen.On('RECORD_SAVED', this, (eventArgs) => {
                    const notifier = new Notifier($(this._el));
                    notifier.Success(NOTIFICATIONS.RECORD_CREATED);

                    UserVarsManager.Instance.AddRecent(entityId, eventArgs.data.RecordId, tableTypeId);
                    this.Trigger('NEW_RECORD_SAVED', eventArgs.data);
                });

                screen.ShowInModal();
            })
            .fail(error => {
                new Notifier($(this._el)).Warning(error.message);
            });
    }

    private AddRelatedRecord(destinationEntityId: number, recordId: number, sourceEntityId: number, name: string, nameTranslation: string, linkedRecord: number) {
        let destinationEntity = _.find(this._dataModel().Entities(), entity => entity.EntityId === destinationEntityId);
        let relatedRecord = AutoLinkedRecordModel.Create(recordId, sourceEntityId, name, nameTranslation, linkedRecord);
        destinationEntity.AutoLinkedRecords.push(relatedRecord);
    }

    ChangeRelation(
        relationRecord: RelationRecordViewModel,
        entityRelations: EntityRelationsViewModel,
        needFindOldId: boolean = true
    ) {
        let newRelations = _.filter(this._updateDataModel.NewRelations, (item) => {
            return item.RelatedEntityId === entityRelations.EntityId
        });

        const isValid: boolean = entityRelations._isValidEntity();
        if (!isValid) {
            entityRelations._isValidEntity(true);
        }

        if (newRelations) {
            _.each(newRelations,
                item => {
                    item.IsMain = item.Guid === relationRecord.Guid;
                });
        }

        let entity = _.find(this._serverDataModel.Entities, (x) => x.EntityId === entityRelations.EntityId);
        let oldRecord = _.find(entity.Records, (x) => {
            return x.IsMain;
        });

        let oldId = !needFindOldId ? -1 : oldRecord ? oldRecord.Id : -1;
        let oldKSeq = !needFindOldId ? -1 : oldRecord ? oldRecord.KSeq : -1;
        this._updateDataModel.ChangeRelation(entityRelations.EntityId, oldId, relationRecord.Id, relationRecord.KSeq, relationRecord.Guid, oldKSeq);

        _.each(entityRelations.Records(),
            item => {
                item.IsMain(item.Guid === relationRecord.Guid);
            });

        if(relationRecord.IsMain()){
            let field = _.find(this.Fields, f=> f.EntityId === entity.EntityId);
            if(relationRecord.IsMain && field){
                this.UpdateVariable({ Field: this.GetPrimaryKeyField(field), ControlType: CONTROL_TYPES.LinkList }, relationRecord.Id);
            }
        }

        return true;
    }

    ChangeMainCompany() {
        const companiesModel = _.find(this._dataModel().Entities(), entity => entity.EntityName && entity.EntityName.toUpperCase() === 'COMPANIES');
        if (!companiesModel || !this.LicenseName) {
            return;
        }

        const mainCompany = _.find(companiesModel.Records(), record => record.IsMain());
        const isMainCompanySet = !!mainCompany;
        const isMainCompanyLicense = mainCompany && mainCompany.Name && mainCompany.Name.toUpperCase() === this.LicenseName.toUpperCase();

        const nonLicenseCompanies = _.filter(companiesModel.Records(), record => record.Name && record.Name.toUpperCase() !== this.LicenseName.toUpperCase());

        if ((isMainCompanySet && !isMainCompanyLicense) || !_.any(nonLicenseCompanies)) {
            return;
        }

        const firstNonLicenseCompany = _.first(nonLicenseCompanies);
        this.ChangeRelation(firstNonLicenseCompany, companiesModel);
    }

    UnLinkRecord(relationRecord: RelationRecordViewModel, entityRelations: EntityRelationsViewModel, displayDialog: boolean = true) {
        if (displayDialog) {
            let confirmationDialog = new ConfirmationDialog({
                Text: CONFIRMATIONS.UNLINK_RECORD,
                Type: ConfirmationTypes.Question
            });
            confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, () => this.UnLink(relationRecord, entityRelations));
            this.On('LinkListDispose', this, () => confirmationDialog.Close());
            confirmationDialog.Show();
        } else {
            this.UnLink(relationRecord, entityRelations)
        }
    }

    UnLinkUser(relationRecord: RelationRecordViewModel, entityRelations: EntityRelationsViewModel, displayDialog: boolean = true) {
        const anyUserExist = _.find(entityRelations.Records(), relation => relation.TypeName.toUpperCase() === "USER");
        const leftRelations = _.without(entityRelations.Records(), relationRecord);
        const anyUserLeft = !!_.find(leftRelations, relation => relation.TypeName.toUpperCase() === "USER");

        if (this._dataModel().UserRelation().SingleMode && relationRecord.IsRecordOwner) {
            relationRecord.Level ^= SECURITY_LEVELS.SHARED;
            relationRecord.Level ^= SECURITY_LEVELS.SHARING_READY;

            this._updateDataModel.ChangeUserLevel(entityRelations.EntityId, relationRecord.Id, relationRecord.Level);
            entityRelations.Records.valueHasMutated();
            return;
        }

        if (anyUserExist && !anyUserLeft) {
            new Notifier($(this._el)).Warning(NOTIFICATIONS.USER_SHOULD_BE_LINKED);
            return;
        }
        if (displayDialog) {
            const confirmationDialog = new ConfirmationDialog({
                Text: CONFIRMATIONS.UNLINK_USER,
                Type: ConfirmationTypes.Question
            });
            confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, () => this.UnLink(relationRecord, entityRelations));
            confirmationDialog.Show();
        } else {
            this.UnLink(relationRecord, entityRelations)
        }
    }

    EnablePlanner() {
        const recordOwner = this._dataModel().UserRelation().Records().find(r => r.IsRecordOwner);

        if (recordOwner.PlanningAllowed && !recordOwner.Planner) {
            this.TogglePlanner(recordOwner);
        }
    }

    TogglePlanner(relationRecord: RelationRecordViewModel) {
        if (!relationRecord.PlanningAllowed) return;

        relationRecord.Level ^= SECURITY_LEVELS.PLANNER;

        const entityId = this._dataModel().UserRelation().EntityId;
        this._updateDataModel.ChangeUserLevel(entityId, relationRecord.Id, relationRecord.Level);

        this._dataModel.valueHasMutated();
    }

    ToggleSharing(relationRecord: RelationRecordViewModel, entityRelations: EntityRelationsViewModel) {
        let entity: EntityRelations = _.find(this._serverDataModel.Entities, e => e.EntityId === entityRelations.EntityId);

        relationRecord.Level ^= SECURITY_LEVELS.SHARED;
        relationRecord.Level ^= SECURITY_LEVELS.SHARING_READY;

        this._updateDataModel.ChangeUserLevel(entity.EntityId, relationRecord.Id, relationRecord.Level);
        this._dataModel.valueHasMutated();

        return true;
    }

    MakeSharingReady(entity: EntityRelationsViewModel, users: RelationRecordViewModel[]) {
        users.forEach(user => {
            if (user.Shared) {
                user.Level ^= SECURITY_LEVELS.SHARED;
                user.Level ^= SECURITY_LEVELS.SHARING_READY;

                this._updateDataModel.ChangeUserLevel(entity.EntityId, user.Id, user.Level);
            }
        })
    }

    NavigateByLink(record: RelationRecordViewModel, entityRelations: EntityRelationsViewModel) {
        const data = {
            EntityId: entityRelations.EntityId,
            RecordId: record.Id,
            RecordTypeId: record.TypeId,
            IsOpenInModal: true,
            Owner: this._form.GetScreen()
        };

        PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, data);
    }

    OpenUserAssignment(userEntityRelations: UserEntityRelationsViewModel) {
        this._searchScreen = new SearchScreen({
            EntityId: userEntityRelations.EntityId,
            SearchTerm: '',
            Sharing: true,
            SubjectEntityId: this.GetForm().GetScreen().GetEntityId(),
            SubjectTypeId: this.GetForm().GetScreen().GetTableTypeId(),
            SubjectRecordId: this.GetForm().GetScreen().GetRecordId(),
            SearchByRelations: this.GetRelations()
        });

        this._searchScreen.On("RECORD_SELECTED", this, (eventArgs) => this.LinkSelectedRecord(userEntityRelations, [eventArgs.data.RecordId]));
        this._searchScreen.Show();
    }

    CollapsingLinks(number, data, event) {
        if (data.Records().length > 7) {
            $(event.currentTarget).toggleClass('open');
            $(event.currentTarget).next('.entity-records').toggleClass('open');
        }

        if (number) {
            if (_.contains(this._openNumbers, number)) {
                this._openNumbers = _.without(this._openNumbers, number)
            } else {
                this._openNumbers.push(number);
            }
        }
    }

    IsOpen(number): boolean {
        return number && _.contains(this._openNumbers, number);
    }

    Deserialize() {
        return Serialize(this._updateDataModel);
    }

    IsValid() {
        const linkRequiredFields = _.filter(this.GetModel().Fields, (field) => field.LinkRequired);
        const linkRequiredEntitiesIds = _.map(linkRequiredFields, (field) => field.EntityId);

        if(linkRequiredEntitiesIds.length > 0){
            let errorEntities = [];
            _.each(this._dataModel().Entities(), (entity) =>{
                if(_.contains(linkRequiredEntitiesIds, entity.EntityId) && entity.Records().length == 0){
                    entity._isValidEntity(false);
                   errorEntities.push(entity.EntityTranslatedName || entity.EntityName);
                }
            });

            if(errorEntities.length > 0){
                this.GetForm().ValidateExpandSubForm();
                new Notifier().Failed(NOTIFICATIONS.LINKS_TO_ENTITIES_MUST_BE_CREATED.replace('Entities', errorEntities.join(', ')));
                return false;
            }
        }
        return true;
    }

    UpdateRecordOwner(user: UserModel) {
        const userRelation = this._dataModel().UserRelation() as EntityRelationsViewModel;
        if (userRelation) {
            const recordOwner = userRelation.Records().find(u => u.IsRecordOwner);
            if (recordOwner.Id === user.Id) return;

            let recordOwnerLevel = SECURITY_LEVELS.NEW_OWNER;

            if (recordOwner) {
                if (recordOwner.Planner) {
                    recordOwner.Level -= SECURITY_LEVELS.PLANNER;
                    recordOwnerLevel += SECURITY_LEVELS.PLANNER;
                }

                recordOwner.IsRecordOwner = false;
                recordOwner.Level -= SECURITY_LEVELS.OWNER;

                this._updateDataModel.ChangeUserLevel(userRelation.EntityId, recordOwner.Id, recordOwner.Level);
            }

            const newRecordOwner = userRelation.Records().find(u => u.Id == user.Id);

            if (newRecordOwner) {
                newRecordOwner.IsRecordOwner = true;
                newRecordOwner.Level = recordOwnerLevel;
                newRecordOwner.UserAllowance = user.UserAllowance;
                this._dataModel.valueHasMutated();

                this._updateDataModel.ChangeUserLevel(userRelation.EntityId, newRecordOwner.Id, newRecordOwner.Level);
                return;
            }

            const newRecordOwnerRelation = new NewRelationModel();
            newRecordOwnerRelation.Name = user.Name;
            newRecordOwnerRelation.Id = user.Id;
            newRecordOwnerRelation.TypeName = 'User';
            newRecordOwnerRelation.IsRecordOwner = true;
            newRecordOwnerRelation.Level = recordOwnerLevel;
            newRecordOwnerRelation.UserAllowance = user.UserAllowance;

            this.AddRecord(user.Id, userRelation, newRecordOwnerRelation);
        }
    }

    @enumerable private get IsNewRecord(): boolean {
        return this._subjectRecordId === 0;
    }

    @enumerable get SubjectRecordId() {
        return this._subjectRecordId;
    }

    private AddRecordOwner(recordSpecs: RecordSpecsModel) {
        let userManager = UserManager.Instance,
            userId = userManager.CurrentUser.Id,
            userName = userManager.CurrentUser.Name;

        BlockUI.Block();
        return userManager.GetUserAllowance()
            .then((userAllowance: number) => {
                let relations = this._dataModel();
                let userRelation: EntityRelationsViewModel = relations.UserRelation();

				if (userRelation) {
					let securityLevel = SECURITY_LEVELS.NEW_OWNER;

					let exampleOwner = _.find(userRelation.Records(), (record: RelationRecordViewModel) => record.IsRecordOwner);
					if (exampleOwner) {
						securityLevel = exampleOwner.Level;
					}

                    let currentUserRelation = new RelationRecordViewModel({
                        Id: userId,
                        Name: userName,
                        NameTranslation: null,
						Level: securityLevel,
                        IsMain: true,
                        TypeName: "User",
                        TypeTranslatedName: "User",
                        TypeId: 0,
                        IsRecordOwner: true,
                        UserAllowance: userAllowance,
                        KSeq: 0
                    });

                    let userRelations = userRelation.Records();

                    if (recordSpecs.IsExample || exampleOwner) {

                        let currentUser = _.find(userRelation.Records(),
                            (record: RelationRecordViewModel) => record.Id === currentUserRelation.Id);

                        userRelations = _.without(userRelation.Records(), exampleOwner, currentUser);
                    }
                    userRelations = userRelations.filter(item => item.Id !== userId);
                    userRelations.splice(0, 0, currentUserRelation);
                    userRelations.sort((relation) => {
                        return relation.IsRecordOwner ? -1 : 1;
                    });

                    userRelation.Records(userRelations);

                    userRelations.forEach(userLink => {
                        this._updateDataModel.AddNewRelation(this.GetControlId(), this._subjectRecordId, userRelation.EntityId, userLink);
                        this._updateDataModel.ChangeUserLevel(userRelation.EntityId, userLink.Id, userLink.Level);
                    });
                    this._dataModel(relations);
                }
            })
            .fail(error => new Notifier().Failed(error.message))
            .always(() => BlockUI.Unblock());
    }

    private RemoveRelatedRecord(recordId: number, entityId: number) {
        _.each(this._dataModel().Entities(),
            entity => entity.AutoLinkedRecords.remove(el => el.RecordId === recordId && el.EntityId === entityId));
    }


    private async GetRecordData(entityRelations: EntityRelationsViewModel, relationRecord: RelationRecordViewModel, linkTypes, isUnlinkedRelationNew) {

        const linkEditor = (await import('Core/Screens/Common/LinkEditor/LinkEditor')).LinkEditor;

        let subjectEntityId = this._form.GetScreen().GetEntityId();
        let subjecRecordId = this._form.GetScreen().GetRecordId();
        let subjectTypeId = this._form.GetScreen().GetTableTypeId();
        let subjectKindId = this._form.GetScreen().GetKindId();

        let isNew = relationRecord.IsNew;
        let changedData = _.find(this._updateDataModel.LinkEditorChanges, item =>
            item.EntityId === entityRelations.EntityId && item.RecordId === relationRecord.Id && item.Guid === relationRecord.Guid);

        let relationModel = {
            MainEntityId: subjectEntityId,
            MainRecordId: subjecRecordId,
            MainRecordTypeId: subjectTypeId,
            MainRecordKindId: subjectKindId,
            RelatedEntityId: entityRelations.EntityId,
            RelatedRecordId: relationRecord.Id,
            KSeq: relationRecord.KSeq,
            RelationEntityName: entityRelations.EntityName
        };

        let selectedRecord = null;
        let relationTypeFieldSelectedValue = null;
        let relationTypeField: any = null;
        if (isUnlinkedRelationNew) {
            relationTypeField = _.filter((isUnlinkedRelationNew.FieldValues as any), (field: any) => {
                return field.FieldValue[0].split('.')[1] === RELATION_TYPE_VALUE_FIELD;
            })[0];
            const relationTypeFieldSelectedValue = relationTypeField && relationTypeField.FieldValue[1] || null;
            selectedRecord = relationTypeField ? relationTypeField.FieldValue[1] : null;
        }

        var deferredResult = P.defer();
        if (!selectedRecord && entityRelations.HasFields) {
            BlockUI.Block();

            LinkEditorStore.GetData({
                MainEntityId: relationModel.MainEntityId,
                MainRecordId: relationModel.MainRecordId,
                MainRecordTypeId: relationModel.MainRecordTypeId,
                MainRecordKindId: relationModel.MainRecordKindId,
                RelatedEntityId: relationModel.RelatedEntityId,
                RelatedRecordId: relationModel.RelatedRecordId,
                Kseq: relationModel.KSeq,
                IsNew: isNew
            })
                .always(() => {
                    BlockUI.Unblock();
                })
                .then(model => {
                    const FRelationFieldModel = _.where(model.Fields, {
                        Name: RELATION_TYPE_VALUE_FIELD
                    })[0];

                    this.GetLinkTypes(linkEditor, relationModel, FRelationFieldModel, model, changedData, null);

                })
                .fail(error => new Notifier().Failed(error.message));
        } else {
            this.GetLinkTypes(linkEditor, relationModel, relationTypeField, null, changedData, selectedRecord);
        }
    }

    private GetLinkTypes(linkEditor, relationModel, FRelationFieldModel, model, changedData, selectedRecord) {
        linkEditor.GetRelationTypesData({
                MainEntityId: relationModel.MainEntityId,
                MainRecordId: relationModel.MainRecordId,
                RelatedEntityId: relationModel.RelatedEntityId,
                RelatedRecordId: relationModel.RelatedRecordId,
                SequenceId: relationModel.KSeq
            }, FRelationFieldModel,
            (relationTypes, relationTypeFieldData) => {
                let selectedRecordValue = selectedRecord || null;
                if (!selectedRecordValue && changedData) {
                    selectedRecordValue = _.filter((changedData.FieldValues as any), (field: any) => {
                        return field.FieldValue[0].split('.')[1] === RELATION_TYPE_VALUE_FIELD;
                    })[0];

                    selectedRecordValue = selectedRecordValue && selectedRecordValue.FieldValue[1] || null
                }
                const requiredRelationType: any = _.where(relationTypes, {
                    RecordId: selectedRecordValue
                })[0];
                this.UpdateLinkTypesState(relationModel.RelationEntityName, relationModel.RelatedRecordId, requiredRelationType && requiredRelationType.TypeName || null, +1)
            }
        );
    }

    private UnLink(relationRecord: RelationRecordViewModel, entityRelations: EntityRelationsViewModel) {
        const isUnlinkedRelationNew = _.where(this._updateDataModel.LinkEditorChanges, {
            Guid: relationRecord.Guid
        })[0];

        if (isUnlinkedRelationNew) {
            this.GetRecordData(entityRelations, relationRecord, this._linkTypes, isUnlinkedRelationNew);
        } else {
            this.GetRecordData(entityRelations, relationRecord, this._linkTypes, null);
        }

        entityRelations.Records(_.without(entityRelations.Records(), relationRecord));

        this._updateDataModel.DeleteRelation({
            EntityId: entityRelations.EntityId,
            RecordId: relationRecord.Id,
            KSeq: relationRecord.KSeq,
            IsNew: relationRecord.IsNew,
            Guid: relationRecord.Guid
        });

        let field = _.find(this.Fields, f=> f.EntityId === entityRelations.EntityId);

        if (relationRecord.IsMain()) {
            let firstRecord = _.first(entityRelations.Records());
            if (firstRecord) {
                this.ChangeRelation(firstRecord, entityRelations, true);
            }
            
            let mainRecordId = firstRecord ? firstRecord.Id : null;
            this.UpdateVariable({ Field: this.GetPrimaryKeyField(field), ControlType: CONTROL_TYPES.LinkList }, mainRecordId);
        }

        if (this._updateDataModel.ActionSubjectRecord &&
            this._updateDataModel.ActionSubjectRecord.EntityId === entityRelations.EntityId &&
            this._updateDataModel.ActionSubjectRecord.RecordId === relationRecord.Id) {

            this._updateDataModel.ActionSubjectRecord = null;
            new Notifier().Warning(NOTIFICATIONS.REFERENCE_WAS_UNSET);
        }

        this._dataModel.valueHasMutated();
        this.RemoveRelatedRecord(relationRecord.Id, entityRelations.EntityId);
    }

    private CheckRelationTypeInstancing(entityRelations, linkTypes) {
        const relatedLinks = _.where(linkTypes, {
            EntityId: entityRelations.EntityId
        });
        return relatedLinks;
    }

    async EditRecord(entityRelations: EntityRelationsViewModel, relationRecord: RelationRecordViewModel, linkTypes) {
        if (!entityRelations.HasFields) {
            return;
        }

        const linkEditor = (await import('Core/Screens/Common/LinkEditor/LinkEditor')).LinkEditor;
        let subjectEntityId = this._form.GetScreen().GetEntityId();
        let subjectRecordId = this._form.GetScreen().GetRecordId();
        let subjectTypeId = this._form.GetScreen().GetTableTypeId();
        let subjectKindId = this._form.GetScreen().GetKindId();

        let isNew = relationRecord.IsNew;
        let data = _.find(this._updateDataModel.LinkEditorChanges, item =>
            item.EntityId === entityRelations.EntityId && item.RecordId === relationRecord.Id && item.Guid === relationRecord.Guid);

        let relation = {
            MainEntityId: subjectEntityId,
            MainRecordId: subjectRecordId,
            MainRecordTypeId: subjectTypeId,
            MainRecordKindId: subjectKindId,
            RelatedEntityId: entityRelations.EntityId,
            RelatedRecordId: relationRecord.Id,
            RelatedRecordTypeId: relationRecord.TypeId,
            KSeq: relationRecord.KSeq
        };

        linkEditor.LoadScreen(relation, isNew, data, true, this, true)
            .then(screen => {
                if (screen !== null) {
                    let linkEditor = screen;

                    linkEditor.On("SAVE_DATA", this, eventArgs => {

                        let data = <LinkEditorDataModel>eventArgs.data;
                        let existsChanges = _.find(this._updateDataModel.LinkEditorChanges, item =>
                            item.EntityId === data.EntityId && item.RecordId === data.RecordId && item.Guid === relationRecord.Guid);


                        if (existsChanges) {
                            _.each(data.FieldValues, item => {
                                let value = _.find(existsChanges.FieldValues, fieldValue => fieldValue.FieldId === item.FieldId);

                                if (value) {
                                    value.FieldValue = item.FieldValue;
                                } else {
                                    existsChanges.FieldValues.push(item);
                                }
                            });
                        } else {

                            let editRelationData = new EditLinkDataModel(data, relationRecord.Guid);

                            this._updateDataModel.LinkEditorChanges.push(editRelationData);

                            const targetedEntity = _.where(this._linkTypesInstancesLeft, {
                                EntityId: relation.RelatedEntityId
                            })[0];
                            if (targetedEntity) {
                                const existsRecords = _.where(targetedEntity.Records, {
                                    RecordId: relationRecord.Id
                                });


                                if (existsRecords.length === 0) {
                                    targetedEntity.Records.push({
                                        RecordId: relationRecord.Id,
                                        LinkTypes: _.extend([], targetedEntity.LinkTypes)
                                    });
                                }
                            }

                        }
                    });

                    this.On('LinkListDispose', this, () => linkEditor.Close());
                } else {
                    new Notifier().Warning(LABELS.TABLE_TEXT_NON_HAS_FIELDS);
                }
            })
            .fail(err => new Notifier($(this._el)).Warning(err.message));
    }

    ClickLinkRequired(field: AttachedFieldModel) {
        field.LinkRequiredObservable(field.LinkRequiredObservable());
        this.ControlModify();
    }

    Dispose() {
        super.Dispose();
        this.Trigger('LinkListDispose');
        if (this._searchScreen) {
            this._searchScreen.Close();
        }
    }
}