const Palette_383838 = '#383838';
export enum BorderColour {
	Source = Palette_383838,
	Destination = Palette_383838,
	Table = Palette_383838,
	Trigger = Palette_383838,
	JSFunction = Palette_383838,
	APIMethod = Palette_383838,
	CSharpFunction = Palette_383838,
	Param = '#A1ACB2',
	Group = Palette_383838
}