import * as ko from "knockout"
import 'jquery';

export class AnimateExtention {
	static Init() {
		ko.bindingHandlers.animate = {
			update: (element, valueAccessor) => {
				let options = valueAccessor();
				$(element).animate({ scrollTop: options._scrollValue() });
			}
		};
	}
}