import * as ko from 'knockout';

import {
    ConfirmationDialog,
    IConfirmationDialogOptions,
    EVENTS as CONFIRMATION_DIALOG_EVENTS, Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

import Template from 'Core/Controls/PlannerPage/Dialogs/CopyQueryButtonConfirmationDialog.html';

ko.templates['Core/Controls/PlannerPage/Dialogs/CopyQueryButtonConfirmationDialog'] = Template;

export interface ICopyQueryButtonConfirmationDialogOptions extends IConfirmationDialogOptions {
    Entity1?: string;
    Entity2?: string;
    Width?: number;
    ModalClass?: string;
}

export const EVENTS = {
	ENTITY1_SELECTED: 'Entity1Selected',
    ENTITY2_SELECTED: 'Entity2Selected',
    ENTITY1_AND_ENTITY2_SELECTED: 'Entity1AndEntity2Selected'
};

export class CopyQueryButtonConfirmationDialog extends ConfirmationDialog {
    private _textEntity1: KnockoutObservable<string>;
    private _textEntity2: KnockoutObservable<string>;
    private _textEntity1AndEntity2: KnockoutObservable<string>;

    constructor(options: ICopyQueryButtonConfirmationDialogOptions){
        super(options);
        this._textEntity1 = ko.observable(options.Entity1);
        this._textEntity2 = ko.observable(options.Entity2);
        this._textEntity1AndEntity2 = ko.observable(`${options.Entity1} and ${options.Entity2}`);
    }

    GetTemplateName() {
		return 'Core/Controls/PlannerPage/Dialogs/CopyQueryButtonConfirmationDialog';
    }

    OnEntity1Click(){
        this.Close();
        this.Trigger(EVENTS.ENTITY1_SELECTED);
    }

    OnEntity2Click(){
        this.Close();
        this.Trigger(EVENTS.ENTITY2_SELECTED);
    }

    OnEntity1AndEntity2Click(){
        this.Close();
        this.Trigger(EVENTS.ENTITY1_AND_ENTITY2_SELECTED);
    }
}