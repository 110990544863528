//View models
import {DisapprovedRequestedDate} from "Core/Components/Controls/TimeWriting/Models/View/DisapprovedDataViewModel";

//Mappings
import { DisapprovedReservationMappings } from "Core/Components/Controls/TimeWriting/Mappings/Disapproved/DisapprovedReservationMappings";


export class DisapprovedRequestedDateMappings {
    static OnViewModel(model: any): DisapprovedRequestedDate {
        const viewModel = new DisapprovedRequestedDate();

        const reservations = model.Reservations.map(reservation => DisapprovedReservationMappings.OnViewModel(reservation));
        viewModel.Reservations(reservations);

        return viewModel;
    }
}