import * as ko from "knockout";

import {IControl} from "Core/Controls/IControl";
import {BaseControlEditor} from "./BaseControlEditor";

import Template from 'Core/Screens/DesignScreen/ControlEditor/Templates/TabPageControlEditor.html';
import {TabSecurityDisplayView} from "../../../../GeneralProperties/Managers/TabSecurity/Views/DisplayView/TabSecurityDisplayView";

ko.templates['Core/Screens/DesignScreen/ControlEditor/Templates/TabPageControlEditor'] = Template;

export class TabPageControlEditor extends BaseControlEditor {

    private readonly _displayView: KnockoutObservable<TabSecurityDisplayView>;
	constructor(control: IControl) {
		super(control);
        this._displayView = ko.observable(null);
	}

	GetTemplateName() {
		return 'Core/Screens/DesignScreen/ControlEditor/Templates/TabPageControlEditor';
	}

    get GeneralProperties() {
        return this.EditControl.GeneralProperties;
    }
}