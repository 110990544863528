import * as ko from 'knockout';
import * as _ from 'underscore';

import {TABLE_TYPES} from 'Core/Constant';

import {GlobalManager, GLOBALS} from 'Core/GlobalManager/GlobalManager';

import {BlockUI} from 'Core/Common/BlockUi';

import {IControl} from 'Core/Controls/IControl';
import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel';
import {BaseControlEditor} from '../BaseControlEditor';

import {EntityModel} from '../../Models/EntityModel';
import {EntitiesStore} from '../../Stores/EntitiesStore';

import {EVENTS} from '../FieldControlEditor';

import Template from 'Core/Screens/DesignScreen/ControlEditor/Templates/SpreadsheetControlEditor.html';

ko.templates['Core/Screens/DesignScreen/ControlEditor/Templates/SpreadsheetControlEditor'] = Template;

export class SpreadsheetControlEditor extends BaseControlEditor {
    protected _entities: KnockoutObservableArray<EntityModel>;
    protected _selectedEntity: KnockoutObservable<EntityModel>;

    constructor(control: IControl) {
        super(control);

        this.InitEditControlData(control);
    }

    GetTemplateName(): string {
        return 'Core/Screens/DesignScreen/ControlEditor/Templates/SpreadsheetControlEditor';
    }

    AfterRender(el: HTMLElement) {
        const container = el[0]
        super.AfterRender(container);

        BlockUI.Block();

        this.LoadData()
            .then(entities => this.PopulateEntities(entities))
            .always(() => BlockUI.Unblock());
    }

    GetControlEditorModel() {
        const desktopLanguage = GlobalManager.Instance.GetGlobal(GLOBALS.DESKTOP_LANGUAGE);
        const controlEditorModel = super.GetControlEditorModel();

        const selectedEntity = this._selectedEntity();
        controlEditorModel.EntityId = selectedEntity.EntityId;
        controlEditorModel.EntityName = selectedEntity.EntityName;

        const selectedField = this.EditControl.GetFieldModel();
        controlEditorModel.FieldId = selectedField.Id;
        controlEditorModel.FieldName = selectedField.Name;
        controlEditorModel.FieldNameTranslation = selectedField.FieldNameTranslation;

        _.each(controlEditorModel.NameTranslations, (translation) => {
            if (translation.Selected || (desktopLanguage == translation.Language.ShortName)) {
                translation.Selected = true;
            }
        });

        return controlEditorModel;
    }

    LoadData() {
        return EntitiesStore.Get({
            EntityId: this.GetSubjectEntityId(),
            TableTypeId: this.GetSubjectTableTypeId()
        });
    }

    protected InitEditControlData(control: IControl) {
        super.InitEditControlData(control);
        this.InitEntities();
    }

    private InitEntities() {
        this._entities = ko.observableArray([]);
        this._selectedEntity = ko.observable(null);
    }

    private PopulateEntities(entities: EntityModel[]) {
        entities = _.chain(entities)
            .filter(entity => entity.TypeName == TABLE_TYPES.Sub && _.any(entity.Fields, f => f.FieldName === 'K_SEQ'))
            .uniq(false, entity => entity.EntityId)
            .value();

        this._entities(entities);

        const attachedField = _.first(this.EditControlModel.Fields);
        const attachedEntity = attachedField && _.find(entities, entity => entity.EntityId === attachedField.EntityId);

        if (attachedEntity) {
            this._selectedEntity(attachedEntity);
        } else {
            this.UpdateField();

            if (this._selectedEntity()) {
                this.ResetDimensions();
            }
        }

        this.BindEvents();
    }

    private BindEvents() {
        this._selectedEntity.subscribe(selectedEntity => {
            if (this._selectedEntity()) {
                this.Trigger(EVENTS.ENTITY_SELECTED, selectedEntity);
            }
        });

        this.On(EVENTS.ENTITY_SELECTED, this, () => this.OnEntityChange());
    }

    private OnEntityChange() {
        this.UpdateField();
        this.ResetDimensions();
    }

    private UpdateField() {
        if (!this._selectedEntity()) {
            return;
        }

        const primaryKey = this._selectedEntity().Fields.find(field => field.IsPrimaryKey() && field.FieldName !== 'K_SEQ');

        let fieldModel = this.EditControl.GetFieldModel();

        if (!fieldModel) {
            fieldModel = new AttachedFieldModel({ Id: primaryKey.FieldId, Name: primaryKey.FieldName, EntityId: this._selectedEntity().EntityId });
            this.EditControl.GetModel().Fields.push(fieldModel);
        } else {
            fieldModel.Id = primaryKey.FieldId;
            fieldModel.Name = primaryKey.FieldName;
            fieldModel.EntityId = this._selectedEntity().EntityId;
        }
    }

    private ResetDimensions() {
        this.GeneralProperties.ResetProperty('Dimensions');
    }
}