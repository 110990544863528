import * as ko from "knockout";
import "jquery";
import "xEditable";

import {Event} from "Core/Common/Event";

import {IValueUpdater} from "LookupEditor/Interfaces/IValueUpdater";
import {IColumnValue} from "LookupEditor/Interfaces/IColumnValue";
import { EditableColumnModel } from "LookupEditor/Models/EditableColumnModel";

import CommonTemplate from "LookupEditor/Templates/Columns/Common.html";
ko.templates["LookupEditor/Templates/Columns/Common"] = CommonTemplate;


export abstract class ColumnEditor extends Event {
	protected template: string;
    protected emptyLookupValue: string = '-';

    constructor(protected column: EditableColumnModel) {
		super();
		this.template = '';
	}

	GetTemplateName(): string {
		return 'LookupEditor/Templates/Columns/Common';
	}

	AfterRender(el, owner: IValueUpdater, columnName, format): void {
		($.fn.editable as any).defaults.mode = 'inline';
		$(el).editable({
			type: 'text',
			success: (response, newValue) => {
				const value: IColumnValue = {
					Name: columnName,
					Value: newValue
				};

				owner.UpdateValue(value);
			}
		});
	}

	abstract ToString();
}
