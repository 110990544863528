import clone from 'clone';

import { BaseModel } from "DatabaseDesigner/Models/BaseModel";
import { CollectionModel } from "DatabaseDesigner/Models/CollectionModel";

export function ScreenModel() {
	this._id = 0;
	this._typeName = '';
	this._count = 0;
	BaseModel.apply(this);
};

ScreenModel.prototype = Object.create(BaseModel.prototype);
ScreenModel.prototype.constructor = ScreenModel;

ScreenModel.prototype.FromJSONArray = function (screensJson) {
	var screens = new CollectionModel({ model: ScreenModel });
	_.each(screensJson, function (screen) {
		var model = new ScreenModel();
		model.FromJSON(screen);
		screens.Add(model);
	});
	return screens;
}

ScreenModel.prototype.Clone = function () {
	return clone(this);
}

ScreenModel.prototype.FromJSON = function (data) {
	this._id = data.Id;
	this._typeName = data.TypeName;
	this._count = data.Count;
}

Object.defineProperty(ScreenModel.prototype, 'Id', {
	enumerable: true,
	get: function () {
		return this._id;
	},
	set: function (val) {
		this._id = val;
	}
});

Object.defineProperty(ScreenModel.prototype, 'TypeName', {
	enumerable: true,
	get: function () {
		return this._typeName;
	},
	set: function (val) {
		this._typeName = val;
	}
});

Object.defineProperty(ScreenModel.prototype, 'Count', {
	enumerable: true,
	get: function () {
		return this._count;
	},
	set: function (val) {
		this._count = val;
	}
});
