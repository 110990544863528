import * as ko from 'knockout';
import _ from "underscore";

import {SelectUserModel, UserGroupModel, UserModel} from "./SelectUserModel";

export class SelectUserFormViewModel {
	UserGroups: KnockoutObservableArray<UserGroupViewModel>;
	Users: KnockoutObservableArray<UserViewModel>;
	AllUsersGroupChecked: KnockoutObservable<boolean>

	constructor(data: SelectUserModel) {
		this.UserGroups = ko.observableArray(data.UserGroups.map(group => new UserGroupViewModel(group, group.Users)));
		this.Users = ko.observableArray( data.Users.map(user=> new UserViewModel(user) ) );

		this.AllUsersGroupChecked = ko.observable(null);
		this.Users().forEach(user => {
			user.Checked.subscribe(() => this.UpdateAllUsersGroupChecked());
		});

		this.UpdateAllUsersGroupChecked();
	}

	UpdateAllUsersGroupChecked() {
		if (this.Users().some(user => user.Checked())) {
			this.AllUsersGroupChecked(true);
		} else {
			this.AllUsersGroupChecked(false);
		}
	}

	ToSelectUserViewModel() {
		const model = new SelectUserModel();
		model.Users = this.Users().map(user => user.ToUserViewModel());

		model.UserGroups = this.UserGroups().map((userGroup: UserGroupViewModel) => {
			return userGroup.ToUserViewGroupModel(userGroup);
		});
		return model
	}
}

export class UserViewModel {
	Id: number;
	Name: string;
	UserName: string;
	UserTypeName: string;
	Checked: KnockoutObservable<boolean>;

	constructor(data: UserModel) {
		this.Id = data.Id;
		this.Name = data.Name;
		this.UserName = data.UserName;
		this.UserTypeName = data.UserTypeName;
		this.Checked = ko.observable(data.Checked || false);
	}

	ToUserViewModel() {
		const user = new UserModel;
		user.Id = this.Id;
		user.Name = this.Name;
		user.UserTypeName = this.UserTypeName;
		user.Checked = this.Checked();

		return user;
	}
}

export class UserGroupViewModel {
	Id: number;
	Name: string;
	Checked: KnockoutObservable<boolean>;
	Users: KnockoutObservableArray<UserViewModel>;
	AllUsersChecked: KnockoutComputed<boolean>

	constructor(data: UserGroupModel, users: Array<UserModel>) {
		this.Id = data.Id;
		this.Name = data.Name;
		this.Checked = ko.observable(data.Checked || false);
		this.Users = ko.observableArray(users.map(user => new UserViewModel(user)));

		this.AllUsersChecked = ko.computed({
			read: () => {
				const users = this.Users();
				if (_.every(users, user => user.Checked())) {
					return true;
				} else if (_.some(users, user => user.Checked())) {
					return null; // a special intermediate state
				} else {
					return false;
				}
			},
			write: (value) => {
				if (value === true) {
					_.each(this.Users(), user => user.Checked(true));
				} else if (value === false) {
					_.each(this.Users(), user => user.Checked(false));
				}
			}
		});

		// Synchronize group checkbox with individual checkboxes
		_.each(this.Users(), user => {
			user.Checked.subscribe((value: boolean) => {
				const allUsersChecked = _.every(this.Users(), u => u.Checked());
				const someUsersChecked = _.some(this.Users(), u => u.Checked());

				if (allUsersChecked) {
					this.AllUsersChecked(true);
				} else if (someUsersChecked) {
					this.AllUsersChecked(null);
				} else {
					this.AllUsersChecked(false);
				}
			});
		});
	}

	ToUserViewGroupModel(userGroup: UserGroupViewModel): UserGroupModel {
		const newUserGroup = new UserGroupModel();
		newUserGroup.Id = userGroup.Id;
		newUserGroup.Name = userGroup.Name;
		newUserGroup.Users = userGroup.Users().map((user: UserViewModel) => user.ToUserViewModel());

		let groupChecked: boolean | null;
		if (_.every(newUserGroup.Users, user => user.Checked)) {
			groupChecked = true;
		} else if (_.some(newUserGroup.Users, user => user.Checked)) {
			groupChecked = null; // a special intermediate state
		} else {
			groupChecked = false;
		}
		newUserGroup.Checked = groupChecked;

		return newUserGroup;
	}
}