import * as ko from "knockout"
import 'jquery';

export class EnterKeyExtention {
	static Init() {
		var ENTER_KEY_CODE = 13;

		ko.bindingHandlers.enterKey = {
			init: (element, valueAccessor, allBindings, viewModel) => {
                let accessor = valueAccessor();
				$(element).keypress((event) => {
					let keyCode = (event.which ? event.which : event.keyCode);
                    if (keyCode === ENTER_KEY_CODE) {
                        if (accessor instanceof Function) {
                            accessor.call(viewModel, event);
                            return false;
                        }
                        accessor.handler.call(viewModel, event);
                        return accessor.bubble;
                    }
					return true;
				});
			}
		};
	}
}