import {ICheckItemParams} from "./ICheckItemParams";

import {BaseCheckItem} from "./BaseCheckItem";
import {TextCheckItem} from "./Text/TextCheckItem";
import {IntegerCheckItem} from "./Integer/IntegerCheckItem";
import {YesNoCheckItem} from "./YesNo/YesNoCheckItem";
import {LookupCheckItem} from "./Lookup/LookupCheckItem";

export class CheckItemFactory {
    static CreateCheckItem(params: ICheckItemParams) : BaseCheckItem {
        switch (params.TypeName) {
            case 'Text':
                return new TextCheckItem(params);

            case 'Integer':
                return new IntegerCheckItem(params);

            case 'YesNo':
                return new YesNoCheckItem(params);

            case 'Lookup':
                return new LookupCheckItem(params);

            default:
                console.error('Check item type not implemented');
        }
    }
}