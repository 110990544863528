import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';

export interface IAssignSpimCopyClassRequestModel {
    ClassFeaturesTableId: number;
    ProductFeaturesTableId: number;
    ProductId: number;
    ClassId: number;
}

export interface IUpdateVersionRequestModel {
    ClassFeaturesTableId: number;
    ProductFeaturesTableId: number;
    ProductId: number;
    ClassId: number;
}

export class SpimCopyStore {
    static GetClass(params: {ClassFeaturesTableId: number, ProductsEntityId: number, ProductId: number}): P.Promise<any> {
        const deferred  = P.defer<any>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: URL.CONTROLS.SPIM_COPY.GET_CLASS,
                dataType: "json",
                type: "GET"
            }, params: params
        };

        Request.Send(requestParams)
            .then(spimClass => deferred.resolve(spimClass))
            .fail(err => deferred.reject(this.GetResponseError(err, "Error getting class")));

        return deferred.promise();
    }

    static AssignClass(params: IAssignSpimCopyClassRequestModel): P.Promise<void> {
        const deferred = P.defer<void>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: URL.CONTROLS.SPIM_COPY.ASSIGN_CLASS,
                type: "POST"
            }, params: params
        };

        Request.Send(requestParams)
            .then(() => deferred.resolve(null))
            .fail(err => deferred.reject(this.GetResponseError(err, "Error assigning class")));

        return deferred.promise();
    }

    static UpdateVersion(params: IUpdateVersionRequestModel): P.Promise<void> {
        const deferred = P.defer<void>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: URL.CONTROLS.SPIM_COPY.UPDATE_VERSION,
                type: "POST"
            }, params: params
        };

        Request.Send(requestParams)
            .then(() => deferred.resolve(null))
            .fail(err => deferred.reject(this.GetResponseError(err, "Error updating version")));

        return deferred.promise();
    }

    private static GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError, status:err.status};
    }

}