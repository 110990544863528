import {deserialize, deserializeAs} from 'libs/cerialize';

export class OperationResultModel {
	@deserialize
	Id: number;

	@deserialize
	IsSuccessfull: boolean;

	@deserialize
	ErrorMessage: string;

	@deserialize
	IsServerError: boolean;

	@deserialize
	ErrorOccured: string;

	@deserializeAs(String)
	Exceptions: Array<String>;

	@deserialize
	Warnings: string[];
}