import {Guid} from 'Core/Common/Guid';

import {TABLE_TYPES} from 'Core/Constant';

import {ITabTableParams} from '../ITabTablesParams';

import {TabGrid} from '../../TabGrid/TabGrid';
import { ILinkEditor } from '../../../LinkEditor';

export class TabTableData {
    private _id: string;
    private _grid: TabGrid;

    constructor(private _params: ITabTableParams, screen: ILinkEditor) {
        this._id = Guid.NewGuid();
        this._grid = new TabGrid(this._params, screen);
    }

    get Id() {
        return this._id;
    }

    get TableName() {
        return this._params.TableName;
    }

    get TableTypeName() {
        return this._params.TableTypeName;
    }

    get TargetTableName() {
        return this._params.TargetTableName;
    }

    get LookupFieldId() {
        return this._params.LookupFieldId;
    }

    get LookupFieldName() {
        return this._params.LookupFieldName;
    }

    get TabName() {
        if (this.TableTypeName === TABLE_TYPES.Sub) {
            return this.TableName;
        }

        if (this.LookupFieldId) {
            return `${this.TableName} (${this.LookupFieldName} -> ${this.TargetTableName})`;
        }

        return `${this.TableName} (${this.TargetTableName})`;
    }

    get Grid() {
        return this._grid;
    }
}