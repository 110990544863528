import * as _ from'underscore';
import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {GenericDeserialize, deserialize } from 'libs/cerialize';
import {NewRelationModel} from 'Core/Controls/LinkList/Models/NewRelationModel';

export interface IGetNewRelationRequestModel {
	ControlId: number;
	RecordId: number;
	EntityId: number;
	RelatedEntityId: number;
    RelatedRecordIds: number[];
	SubjectRecordId: number;
};


export interface IGetNewAutolinkRequestModel{
	NewRelationRequestItem: IGetNewRelationRequestModel;
	RecordId: number;
	LeftEntityId: number;
	RightEntityId: number;
};

export class NewRelationStore {
	static GetNewRelation(params: IGetNewRelationRequestModel): P.Promise<NewRelationModel[]> {
		var deferredResult = P.defer<NewRelationModel[]>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.LINKLIST.GET_NEW_OR_EXISTING_RECORD,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
                    deferredResult.resolve(this.DeserializeArray(data.ResultObject));
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static GetNewAutolinkRelation(params: IGetNewAutolinkRequestModel): P.Promise<NewRelationModel> {
		var deferredResult = P.defer<NewRelationModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.LINKLIST.GET_AUTOLINK_RECORD,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(this.Deserialize(data.ResultObject));
				}
			});

		return deferredResult.promise();
	}

	static Deserialize(jsonObject: any): NewRelationModel {
		var model = GenericDeserialize<NewRelationModel>(jsonObject, NewRelationModel);
		return model;
    }

    static DeserializeArray(jsonObject: any): Array<NewRelationModel> {
        return jsonObject.map(item => GenericDeserialize(item, NewRelationModel));
    }
}