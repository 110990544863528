export class LanguageModel {
	constructor(public Id: number,
				public Name: string,
				public ShortName: string,
				public Flag: string,
				public Enabled: boolean,
				public IsDefault: boolean) {
	}

	Update(language: LanguageModel) {
		this.Id = language.Id;
		this.Name = language.Name;
		this.ShortName = language.ShortName;
		this.Flag = language.Flag;
		this.Enabled = language.Enabled;
	}
}