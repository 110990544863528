import {FIELD_TYPES} from 'Core/Constant';
import {ComparisonOperators} from 'QueryBuilder/Enums';

export const FUNCTIONS = {
	TODAY: '$Now',
	TODAY_ADD_DAYS: '$Now.AddDays(1)',
	USER: '$UserId',
	OWNER_PROFILE: '$OwnerProfileId',
	SUBJECT_RECORD_ID: '$SubjectRecordId',
	SUBJECT_TYPE_ID: '$SubjectTypeId',
	SUBJECT_STATUS_ID: '$SubjectStatusId',
	SUBJECT_KIND_ID: '$SubjectKindId',
    IMPORT_METHOD_ID: "$ImportMethodId"
}

interface IDictionary<T> {
	[k: string]: T;
}

export interface IConditionItemConfig {
	ShowParam: boolean;
	Operators: Array<ComparisonOperators>;
	Functions: Array<string>;
}

export interface ITypeCompatibility {
	Types: Array<string>;
}

export var CONFIG: IDictionary<IConditionItemConfig> = { };

export var TYPES_COMPATIBILITY: IDictionary<ITypeCompatibility> = { };

TYPES_COMPATIBILITY[FIELD_TYPES.Spim] = {
	Types: []
};

TYPES_COMPATIBILITY[FIELD_TYPES.Text] = {
	Types: [
		FIELD_TYPES.Text,
		FIELD_TYPES.Alias,
		FIELD_TYPES.Color,
		FIELD_TYPES.Icon
	]
};

TYPES_COMPATIBILITY[FIELD_TYPES.Alias] = {
	Types: [
		FIELD_TYPES.Text,
		FIELD_TYPES.Alias,
		FIELD_TYPES.Color,
		FIELD_TYPES.Icon
	]
};

TYPES_COMPATIBILITY[FIELD_TYPES.Color] = {
	Types: [
		FIELD_TYPES.Text,
		FIELD_TYPES.Alias,
		FIELD_TYPES.Color,
		FIELD_TYPES.Icon
	]
};

TYPES_COMPATIBILITY[FIELD_TYPES.Memo] = {
	Types: [
		FIELD_TYPES.Memo
	]
};

TYPES_COMPATIBILITY[FIELD_TYPES.Integer] = {
	Types: [
		FIELD_TYPES.Integer,
		FIELD_TYPES.PKey,
		FIELD_TYPES.YesNo,
		FIELD_TYPES.Sort,
		FIELD_TYPES.Decimal,
		FIELD_TYPES.Lookup,
		FIELD_TYPES.Radio
	]
};

TYPES_COMPATIBILITY[FIELD_TYPES.PKey] = {
	Types: [
		FIELD_TYPES.Integer,
		FIELD_TYPES.PKey,
		FIELD_TYPES.YesNo,
		FIELD_TYPES.Sort,
		FIELD_TYPES.Decimal,
		FIELD_TYPES.Lookup,
		FIELD_TYPES.Radio
	]
};

TYPES_COMPATIBILITY[FIELD_TYPES.YesNo] = {
	Types: [
		FIELD_TYPES.Integer,
		FIELD_TYPES.PKey,
		FIELD_TYPES.YesNo,
		FIELD_TYPES.Sort,
		FIELD_TYPES.Decimal,
		FIELD_TYPES.Lookup,
		FIELD_TYPES.Radio
	]
};

TYPES_COMPATIBILITY[FIELD_TYPES.Sort] = {
	Types: [
		FIELD_TYPES.Integer,
		FIELD_TYPES.PKey,
		FIELD_TYPES.YesNo,
		FIELD_TYPES.Sort,
		FIELD_TYPES.Decimal,
		FIELD_TYPES.Lookup,
		FIELD_TYPES.Radio
	]
};

TYPES_COMPATIBILITY[FIELD_TYPES.Decimal] = {
	Types: [
		FIELD_TYPES.Integer,
		FIELD_TYPES.PKey,
		FIELD_TYPES.YesNo,
		FIELD_TYPES.Sort,
		FIELD_TYPES.Decimal,
		FIELD_TYPES.Lookup,
		FIELD_TYPES.Radio
	]
};

TYPES_COMPATIBILITY[FIELD_TYPES.Lookup] = {
	Types: [
		FIELD_TYPES.Integer,
		FIELD_TYPES.PKey,
		FIELD_TYPES.YesNo,
		FIELD_TYPES.Sort,
		FIELD_TYPES.Decimal,
		FIELD_TYPES.Lookup,
		FIELD_TYPES.Radio
	]
};

TYPES_COMPATIBILITY[FIELD_TYPES.Radio] = {
	Types: [
		FIELD_TYPES.Integer,
		FIELD_TYPES.PKey,
		FIELD_TYPES.YesNo,
		FIELD_TYPES.Sort,
		FIELD_TYPES.Decimal,
		FIELD_TYPES.Lookup,
		FIELD_TYPES.Radio
	]
};

TYPES_COMPATIBILITY[FIELD_TYPES.Reference] = {
	Types: [
		FIELD_TYPES.Reference
	]
};

TYPES_COMPATIBILITY[FIELD_TYPES.MultiSelect] = {
	Types: [
		FIELD_TYPES.MultiSelect
	]
};

TYPES_COMPATIBILITY[FIELD_TYPES.Date] = {
	Types: [
		FIELD_TYPES.Date,
		FIELD_TYPES.DateTime,
		FIELD_TYPES.Time,
		FIELD_TYPES.TimeSpan
	]
};

TYPES_COMPATIBILITY[FIELD_TYPES.DateTime]= {
	Types: [
		FIELD_TYPES.Date,
		FIELD_TYPES.DateTime,
		FIELD_TYPES.Time,
		FIELD_TYPES.TimeSpan
	]
};

TYPES_COMPATIBILITY[FIELD_TYPES.Time]= {
	Types: [
		FIELD_TYPES.Date,
		FIELD_TYPES.DateTime,
		FIELD_TYPES.Time,
		FIELD_TYPES.TimeSpan
	]
};

TYPES_COMPATIBILITY[FIELD_TYPES.TimeSpan] = {
	Types: [
		FIELD_TYPES.Date,
		FIELD_TYPES.DateTime,
		FIELD_TYPES.Time,
		FIELD_TYPES.TimeSpan
	]
};

TYPES_COMPATIBILITY[FIELD_TYPES.Currency] = { Types: [] };
TYPES_COMPATIBILITY[FIELD_TYPES.Document] = { Types: [] };
TYPES_COMPATIBILITY[FIELD_TYPES.Image] = { Types: [] };
TYPES_COMPATIBILITY[FIELD_TYPES.Icon] = {
	Types: [
		FIELD_TYPES.Text,
		FIELD_TYPES.Alias,
		FIELD_TYPES.Color
	]
};

TYPES_COMPATIBILITY[FIELD_TYPES.Password]= { Types: [] };
TYPES_COMPATIBILITY[FIELD_TYPES.Guid] = {
	Types: [
		FIELD_TYPES.Guid
	]
};

CONFIG[FIELD_TYPES.Alias] = {
	ShowParam: false, Operators: [
		ComparisonOperators.IsNull,
		ComparisonOperators.IsNotNull,
		ComparisonOperators.Like,
		ComparisonOperators.StartsWith,
		ComparisonOperators.NotStartWith,
		ComparisonOperators.EndsWith,
		ComparisonOperators.NotEndWith,
		ComparisonOperators.NotLike
	], Functions: []
};

CONFIG[FIELD_TYPES.Color] = {
	ShowParam: false, Operators: [
		ComparisonOperators.Equals,
		ComparisonOperators.NotEquals
	], Functions: []
};

CONFIG[FIELD_TYPES.Date] = {
	ShowParam: true, Operators: [
		ComparisonOperators.Equals,
		ComparisonOperators.NotEquals,
		ComparisonOperators.In,
		ComparisonOperators.GreaterThen,
		ComparisonOperators.GreaterOrEqual,
		ComparisonOperators.LessThen,
		ComparisonOperators.LessOrEqual,
		ComparisonOperators.IsNull,
		ComparisonOperators.IsNotNull,
    ], Functions: [FUNCTIONS.TODAY, FUNCTIONS.TODAY_ADD_DAYS]
};

CONFIG[FIELD_TYPES.DateTime] = {
	ShowParam: true, Operators: [
		ComparisonOperators.Equals,
		ComparisonOperators.NotEquals,
		ComparisonOperators.In,
		ComparisonOperators.GreaterThen,
		ComparisonOperators.GreaterOrEqual,
		ComparisonOperators.LessThen,
		ComparisonOperators.LessOrEqual,
		ComparisonOperators.IsNull,
		ComparisonOperators.IsNotNull,
    ], Functions: [FUNCTIONS.TODAY, FUNCTIONS.TODAY_ADD_DAYS]
};

CONFIG[FIELD_TYPES.Time] = {
	ShowParam: true, Operators: [
		ComparisonOperators.Equals,
		ComparisonOperators.NotEquals,
		ComparisonOperators.In,
		ComparisonOperators.NotIn,
		ComparisonOperators.GreaterThen,
		ComparisonOperators.GreaterOrEqual,
		ComparisonOperators.LessThen,
		ComparisonOperators.LessOrEqual,
		ComparisonOperators.IsNull,
		ComparisonOperators.IsNotNull,
	], Functions: []
};

CONFIG[FIELD_TYPES.TimeSpan] = {
	ShowParam: true, Operators: [
		ComparisonOperators.Equals,
		ComparisonOperators.NotEquals,
		ComparisonOperators.In,
		ComparisonOperators.NotIn,
		ComparisonOperators.GreaterThen,
		ComparisonOperators.GreaterOrEqual,
		ComparisonOperators.LessThen,
		ComparisonOperators.LessOrEqual,
		ComparisonOperators.IsNull,
		ComparisonOperators.IsNotNull,
	], Functions: []
};

CONFIG[FIELD_TYPES.Decimal] = {
	ShowParam: true, Operators: [
		ComparisonOperators.Equals,
		ComparisonOperators.NotEquals,
		ComparisonOperators.In,
		ComparisonOperators.NotIn,
		ComparisonOperators.GreaterThen,
		ComparisonOperators.GreaterOrEqual,
		ComparisonOperators.LessThen,
		ComparisonOperators.LessOrEqual
	], Functions: []
};

CONFIG[FIELD_TYPES.Document] = {
	ShowParam: true, Operators: [
		ComparisonOperators.Equals,
		ComparisonOperators.NotEquals,
		ComparisonOperators.IsNull,
		ComparisonOperators.IsNotNull
	], Functions: []
};

CONFIG[FIELD_TYPES.FlagCheck] = {
	ShowParam: false, Operators: [
		ComparisonOperators.IsNull,
		ComparisonOperators.IsNotNull
	], Functions: []
};

CONFIG[FIELD_TYPES.Guid] = {
	ShowParam: false, Operators: [
		ComparisonOperators.IsNull,
		ComparisonOperators.IsNotNull
	], Functions: []
};

CONFIG[FIELD_TYPES.Icon] = {
	ShowParam: false, Operators: [
		ComparisonOperators.IsNull,
		ComparisonOperators.IsNotNull
	], Functions: []
};

CONFIG[FIELD_TYPES.Image] = {
	ShowParam: false, Operators: [
		ComparisonOperators.IsNull,
		ComparisonOperators.IsNotNull
	], Functions: []
};

CONFIG[FIELD_TYPES.Integer] = {
	ShowParam: true, Operators: [
		ComparisonOperators.Equals,
		ComparisonOperators.NotEquals,
		ComparisonOperators.In,
		ComparisonOperators.NotIn,
		ComparisonOperators.GreaterThen,
		ComparisonOperators.GreaterOrEqual,
		ComparisonOperators.LessThen,
		ComparisonOperators.LessOrEqual
	], Functions: [
		FUNCTIONS.USER,
		FUNCTIONS.SUBJECT_RECORD_ID,
		FUNCTIONS.SUBJECT_TYPE_ID,
		FUNCTIONS.SUBJECT_KIND_ID,
		FUNCTIONS.SUBJECT_STATUS_ID,
		FUNCTIONS.OWNER_PROFILE,
		FUNCTIONS.IMPORT_METHOD_ID
	]
};

CONFIG[FIELD_TYPES.Lookup] = {
	ShowParam: true, Operators: [
		ComparisonOperators.Equals,
		ComparisonOperators.NotEquals,
		ComparisonOperators.In,
		ComparisonOperators.NotIn,
		ComparisonOperators.IsNull,
		ComparisonOperators.IsNotNull
	], Functions: [
		FUNCTIONS.USER,
		FUNCTIONS.SUBJECT_RECORD_ID,
		FUNCTIONS.SUBJECT_TYPE_ID,
		FUNCTIONS.SUBJECT_KIND_ID,
		FUNCTIONS.SUBJECT_STATUS_ID,
		FUNCTIONS.OWNER_PROFILE,
		FUNCTIONS.IMPORT_METHOD_ID
	]
};

CONFIG[FIELD_TYPES.Memo] = {
	ShowParam: true, Operators: [
		ComparisonOperators.IsNull,
		ComparisonOperators.IsNotNull
	], Functions: []
};

CONFIG[FIELD_TYPES.MultiSelect] = {
	ShowParam: true, Operators: [
		ComparisonOperators.Equals,
		ComparisonOperators.IsNull,
		ComparisonOperators.IsNotNull
	], Functions: []
};

CONFIG[FIELD_TYPES.Password] = {
	ShowParam: false, Operators: [
		ComparisonOperators.IsNull,
		ComparisonOperators.IsNotNull
	], Functions: []
};

CONFIG[FIELD_TYPES.PKey] = {
	ShowParam: false, Operators: [
		ComparisonOperators.Equals,
		ComparisonOperators.NotEquals,
		ComparisonOperators.IsNull,
		ComparisonOperators.IsNotNull,
		ComparisonOperators.Like,
		ComparisonOperators.GreaterThen,
		ComparisonOperators.LessThen,
		ComparisonOperators.GreaterOrEqual,
		ComparisonOperators.LessOrEqual,
		ComparisonOperators.In,
		ComparisonOperators.StartsWith,
		ComparisonOperators.EndsWith,
		ComparisonOperators.NotIn,
		ComparisonOperators.NotStartWith,
		ComparisonOperators.NotEndWith,
		ComparisonOperators.NotLike
	], Functions: [
		FUNCTIONS.USER,
		FUNCTIONS.SUBJECT_RECORD_ID,
		FUNCTIONS.SUBJECT_TYPE_ID,
		FUNCTIONS.SUBJECT_KIND_ID,
		FUNCTIONS.SUBJECT_STATUS_ID,
		FUNCTIONS.IMPORT_METHOD_ID
	]
};

CONFIG[FIELD_TYPES.Radio] = {
	ShowParam: true, Operators: [
		ComparisonOperators.IsNull,
		ComparisonOperators.IsNotNull
	], Functions: []
};

CONFIG[FIELD_TYPES.Text] = {
	ShowParam: true, Operators: [
		ComparisonOperators.Like,
		ComparisonOperators.IsNull,
		ComparisonOperators.IsNotNull,
		ComparisonOperators.Equals,
		ComparisonOperators.NotEquals,
		ComparisonOperators.In,
		ComparisonOperators.NotIn,
		ComparisonOperators.GreaterThen,
		ComparisonOperators.GreaterOrEqual,
		ComparisonOperators.LessThen,
		ComparisonOperators.LessOrEqual,
		ComparisonOperators.StartsWith,
		ComparisonOperators.NotStartWith,
		ComparisonOperators.EndsWith,
		ComparisonOperators.NotEndWith,
		ComparisonOperators.NotLike
	], Functions: []
};

CONFIG[FIELD_TYPES.YesNo] = {
	ShowParam: true, Operators: [
		ComparisonOperators.Equals,
		ComparisonOperators.NotEquals,
		ComparisonOperators.GreaterThen,
		ComparisonOperators.GreaterOrEqual,
		ComparisonOperators.LessThen,
		ComparisonOperators.LessOrEqual
	], Functions: []
};


CONFIG[FIELD_TYPES.Spim] = {
	ShowParam: true, Operators: [
		ComparisonOperators.Equals
	], Functions: []
};