import * as ko from 'knockout';

import {PASSWORD} from "Core/Constant";

export class PasswordResetModel {
    FormTitle: KnockoutObservable<string>;
    NewPassword: KnockoutObservable<string>;
    PasswordConfirmation: KnockoutObservable<string>;
    Error: KnockoutObservable<string>;
    Token: string;

    constructor(token: string) {
        this.Token = token;

        this.FormTitle = ko.observable(null);
        this.NewPassword = ko.observable(null).extend({
            required: {
                message: 'Please, enter new password'
            },
            minLength: {
                params: PASSWORD.MinimumLength,
                message: `Please, enter at least ${PASSWORD.MinimumLength} characters`
            }
        });
        this.PasswordConfirmation = ko.observable(null).extend({
            required: {
                message: 'Please, confirm new password'
            },
            minLength: {
                params: PASSWORD.MinimumLength,
                message: `Please, enter at least ${PASSWORD.MinimumLength} characters`
            },
            areSame: {
                params: this.NewPassword,
                message: 'Passwords are not same'
            }
        });
        this.Error = ko.observable(null);
    }
}