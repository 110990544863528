//Libs
import * as ko from "knockout";

//Common
import {Event} from "Core/Common/Event";

//Params
import {ITimeWritingParams} from "Core/Components/Controls/TimeWriting/ITimeWritingParams";

//Utils
import {DateTime} from "Core/Components/Controls/TimeWriting/Utils/DateTime";

//Icon
import {Icon} from "Core/Icon/Icon";

//Views
import {TimeWritingView} from "Core/Components/Controls/TimeWriting/Views/TimeWritingView";
import {YearView} from "Core/Components/Controls/TimeWriting/Views/Year/YearView";
import {DayView} from "Core/Components/Controls/TimeWriting/Views/Day/DayView";
import {WeekView} from "Core/Components/Controls/TimeWriting/Views/Week/WeekView";
import {DisapprovedView} from "Core/Components/Controls/TimeWriting/Views/Day/DisapprovedView";


//States
import {State as DayViewState} from "Core/Components/Controls/TimeWriting/Views/Day/State";
import {State as YearViewState} from "Core/Components/Controls/TimeWriting/Views/Year/State";

//Templates
import TimeWritingTemplate from "Core/Components/Controls/TimeWriting/Templates/TimeWriting.html";
import {AttachedFieldModel} from "../../../Controls/BaseControl/Models/AttachedFieldModel";
import {ConfigModel} from "Core/GeneralProperties/Models/ConfigModel";
import {ZIndexManager} from "Core/Common/ZIndexManager";

ko.templates["Core/Components/Controls/TimeWriting/Templates/TimeWriting"] = TimeWritingTemplate;

export class TimeWriting extends Event {
    private _controlId: number;
    private _entityId: number;

    private _subjectEntityId: number;
    private _subjectEntityName: string;
    private _subjectEntityIcon: Icon;

    private _recordId: number | null;
    private _recordName: string;

    private _customFields: AttachedFieldModel[];
    private _properties: ConfigModel;

    private _view: KnockoutObservable<TimeWritingView>;
    private _isReady: KnockoutObservable<boolean>;
    private _currentDate: KnockoutObservable<Date>;
    private _zIndex: number;

    constructor(params: ITimeWritingParams) {
        super();

        this._controlId = params.ControlId;
        this._entityId = params.EntityId;

        this._subjectEntityId = params.SubjectEntityId;
        this._subjectEntityName = params.SubjectEntityName;
        this._subjectEntityIcon = params.SubjectEntityIcon;

        this._recordId = params.SubjectRecordId;
        this._recordName = params.SubjectRecordName;

        this._customFields = params.CustomFields;

        this._properties = params.Properties;

        this._view = ko.observable(null);
        this._isReady = ko.observable(false);
        this._currentDate = ko.observable(null);
        this._zIndex = ZIndexManager.Instance.NextValue;
    }

    get DayViewIsUsed() {
        return this._view() instanceof DayView;
    }

    Render(node: HTMLElement) {
        ko.cleanNode(node);
        ko.applyBindings(this, node);
    }

    GetTemplateName() {
        return "Core/Components/Controls/TimeWriting/Templates/TimeWriting";
    }

    AfterRender() {
        this._isReady(true);
    }

    Show(date: Date) {
        this.SwitchToDayView(date);
    }

    ShowStatic() {
        this.SwitchToStaticDayView();
    }

    CanBeUpdated() {
        return this._view().CanBeUpdated();
    }

    private SwitchToDayView(date: Date, userId?: number) {
        const dayView = new DayView({
            ControlId: this._controlId,
            EntityId: this._entityId,
            SubjectEntityId: this._subjectEntityId,
            SubjectEntityName: this._subjectEntityName,
            SubjectRecordId: this._recordId,
            SubjectRecordName: this._recordName,
            SubjectEntityIcon: this._subjectEntityIcon,
            CustomFields: this._customFields,
            Properties: this._properties
        });
        dayView.On('WeekClicked', this, eventArgs => this.RequestWeek(eventArgs.data.StartDate, eventArgs.data.EndDate, eventArgs.data.UserId));
        dayView.On("YearClicked", this, eventArgs => this.RequestYear(eventArgs.data.State, eventArgs.data.UserId));
        dayView.On('DisapprovedClicked', this, (eventArgs) => {
            if (eventArgs.data.State && eventArgs.data.UserId) {
                this.RequestDisapproved(eventArgs.data.State.Date, eventArgs.data.UserId);
            }
        });
        dayView.Show(date, userId);
        this._view(dayView);
    }

    private SwitchToWeekView(startDate: Date, endDate: Date, userId: number) {
        const weekView = new WeekView({
            ControlId: this._controlId,
            EntityId: this._entityId,
            SubjectEntityId: this._subjectEntityId,
            SubjectEntityName: this._subjectEntityName,
            SubjectRecordId: this._recordId,
            SubjectRecordName: this._recordName,
            CustomFields: this._customFields,
            Properties: this._properties
        });

        weekView.On('DayClicked', this, eventArgs => this.OnDayClicked(eventArgs.data.Date, eventArgs.data.UserId));
        weekView.On("YearClicked", this, eventArgs => this.RequestYear(eventArgs.data.State, eventArgs.data.UserId));

        weekView.Show({startDate, endDate}, userId);
        this._view(weekView);
    }

    private SwitchToStaticDayView() {
        const dayView = new DayView({
            ControlId: this._controlId,
            EntityId: this._entityId,
            SubjectEntityId: this._subjectEntityId,
            SubjectEntityName: this._subjectEntityName,
            SubjectRecordId: this._recordId,
            SubjectRecordName: this._recordName,
            SubjectEntityIcon: this._subjectEntityIcon,
            CustomFields: this._customFields,
            Properties: this._properties
        });
        dayView.ShowStatic();
        this._view(dayView);
    }

    private SwitchToYearView(year: number, userId?: number) {
        const yearView = new YearView({
            EntityId: this._entityId,
            SubjectEntityId: this._subjectEntityId,
            SubjectRecordId: this._recordId
        });
        yearView.On("WeekSelected", this, eventArgs => this.OnWeekClicked(eventArgs.data.State, eventArgs.data.UserId));
        yearView.Show(year, userId);
        this._view(yearView);
    }

    private SwitchToDisapprovedView(date: Date, userId?: number) {
        const disapprovedView = new DisapprovedView({
            ControlId: this._controlId,
            EntityId: this._entityId,
            SubjectEntityId: this._subjectEntityId,
            SubjectEntityName: this._subjectEntityName,
            SubjectRecordId: this._recordId,
            SubjectRecordName: this._recordName,
            SubjectEntityIcon: this._subjectEntityIcon,
            CustomFields: this._customFields,
            Properties: this._properties
        });

        disapprovedView.On("DayViewClicked", this, eventArgs => this.SwitchToDayView(eventArgs.data.State.Date, eventArgs.data.UserId));
        disapprovedView.Show(date, userId);
        this._view(disapprovedView);
    }

    private RequestYear(state: DayViewState, userId?: number) {
        this.SwitchToYearView(state.Year, userId);
    }

    private RequestDisapproved(date: Date, userId: number) {
        this.SwitchToDisapprovedView(date, userId);
    }

    private RequestWeek(startDate: Date, endDate: Date, userId: number) {
        this.SwitchToWeekView(startDate, endDate, userId);
    }

    private OnWeekClicked(state: YearViewState, userId: number) {
        const weekStartDate = DateTime.GetWeekStartByNumber(state.SelectedWeek.Number, state.Year);
        this.SwitchToDayView(weekStartDate, userId);
    }

    private OnDayClicked(date: Date, userId: number) {
        this.SwitchToDayView(date, userId);
    }
}