import { AssigneeDTOModel } from "Core/Controls/FieldSecurity/Shared/Models/DTO/Get/AssigneeDTOModel";
import { AssigneeModel } from "Core/Controls/FieldSecurity/Shared/Models/Business/AssigneeModel";

export class AssigneeMappings {
	static MapToAssignee(assigneeDto: AssigneeDTOModel): AssigneeModel {
		return new AssigneeModel(assigneeDto.Id, assigneeDto.Name);
	}

	static MapToNewAssignee(assignee: AssigneeModel) {
		return assignee && assignee.Id;
	}
}