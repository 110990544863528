export enum AuthResults {
	Success,
	InvalidTotpPassword,
	TwoFaIsNotConfigured,
	PasswordExpired,
	InvalidLicense,
	ErrorFindingLicenses,
	LicenseNotFound,
	LicenseExpired,
	InvalidLicenseNames,
	InvalidLicenseParams,
	PasswordReset,
	OnlyOneUserGroupIsAllowed,
	EditLimitIsGreaterThanUserDesignation,
	PasswordLessIsNotConfigured
};