import * as $ from 'jquery';
import "jqueryValidate";

export class CyberConfigValidateForm {

	private _$errorSummaryBlock: JQuery;
	private _$errorSummary: JQuery;
	private _$inputFields: JQuery;

	constructor(private _$form: JQuery) {
		this._$errorSummaryBlock = this._$form.find('.error-summary-block');
		this._$errorSummary = this._$errorSummaryBlock.find('.error-summary');
		this._$inputFields = this._$form.find('input');

		this.BindEvents();
	}
	
	public InitValidation() {
		this._$form.validate({
			errorElement: 'span',
			errorClass: 'help-block',
			focusInvalid: false,
			rules: {
				DatabaseName: {
					required: true
				},
				DBServerName: {
					required: true
				}
			},

			messages: {
				DatabaseName: {
					required: "Database name is required."
				},
				DBServerName: {
					required: "Connection string is required."
				}
			},
			highlight(element) { // hightlight error inputs
				$(element)
					.closest('div').addClass('has-error'); // set error class to the control group
			},
			success(label) {
				label.closest('div').removeClass('has-error');
				label.remove();
			},
			errorPlacement(error, element) {
				error.insertAfter(element.closest('div'));
			}
		});
	}

	private BindEvents() {
		this.InitValidation();
	}

	public IsValid() {
		return this._$form.valid();
	}
}