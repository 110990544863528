import { deserialize } from 'libs/cerialize';
import { deserializeAs } from 'libs/cerialize';

export class UrlCommandModel {
	@deserialize
	Id: string;

	@deserialize
	Name: string;

	@deserialize
	Type: string;
}
export class EditTemplateModel {

	constructor() {
		this.UrlCommands = [];
	}

	@deserialize
	Content: string;

	@deserialize
	FileName: string;

	@deserialize
	QueryId: number;

	@deserialize
	RecordId: number;

	@deserialize
	KSeq: number;

	@deserializeAs(UrlCommandModel)
	UrlCommands: Array<UrlCommandModel>;
}