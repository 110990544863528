import * as ko from 'knockout'
import {Modal} from 'Core/Common/Modal';
import {Event} from 'Core/Common/Event';
import {Guid} from 'Core/Common/Guid';

import _ from "underscore";
import {
    ConfirmationDialog, EVENTS as ConfirmationDialogEvents,
    Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import {LABELS, CONFIRMATIONS, NOTIFICATIONS} from "Core/Components/Translation/Locales";
import {IDeleteMemoRecordRequestModel} from "Core/Components/ProgressBar/Models/Request/IDeleteMemoRecordRequestModel";
import {FlowFolderMemosModel} from "Core/Components/ProgressBar/Models/Response/FlowFolderMemosModel";
import {MemoRecordModel} from "Core/Components/ProgressBar/Models/View/MemoRecordModel";
import {ICreateMemoRecordRequestModel} from "Core/Components/ProgressBar/Models/Request/ICreateMemoRecordRequestModel";
import {FlowFolderStore} from "Core/Components/ProgressBar/Stores/FlowFolderStore";
import {Notifier} from "Core/Common/Notifier";
import {MobileChecker} from 'Core/Common/MobileChecker';
import {IUpdateFlowMemoRecordRequestModel} from "Core/Components/ProgressBar/Models/Request/IUpdateFlowMemoRecordRequestModel";
import {ScreenParamsModel} from "Core/Controls/ButtonFollowUp/Models/ScreenParamsModel";

import AddMemoTemplate from "Core/Controls/ButtonFollowUp/Templates/AddMemo.html";
import MemoItems from 'Core/Controls/ButtonFollowUp/Templates/MemoItems.html';
import {BlockUI, TranslationManager} from '../Grid/BaseGrid/Index';
import {IUpdateFlowOperationRecordRequestModel} from "Core/Components/ProgressBar/Models/Request/IUpdateFlowOperationRecordRequestModel";
import {TranslationFieldEditor} from "../../Components/TranslationFieldEditor/TranslationFieldEditor";
import {TranslationModel} from "../BaseControl/Models/TranslationModel";
import {LanguageModel} from "../BaseControl/Models/LanguageModel";
import {GlobalManager, GLOBALS} from "../../GlobalManager/GlobalManager";


ko.templates["Core/Controls/ButtonFollowUp/Templates/AddMemo"] = AddMemoTemplate;
ko.templates["Core/Controls/ButtonFollowUp/Templates/MemoItems"] = MemoItems;

export class FlowFolderMemos extends Event {
    private _modal: Modal;
    private _guid: string;
    private _memoEditor: any;
    private _memo: KnockoutObservable<string>;
    private _initialMemo: KnockoutObservable<string>;
    private _isEditMode: KnockoutObservable<boolean>;
    private _entityId: number;
    private _editMemoEntityId: KnockoutObservable<number>;
    private _editMemoIndex: KnockoutObservable<number>;
    private _editMemoKseq: KnockoutObservable<number>;
    private _oldEditMemo: KnockoutObservable<string>;
    private _isMemoTranslatable: boolean;
    private _recordId: number;
    private _selectedValueId: number;
    private _flowFolderMemoArrays: KnockoutObservableArray<MemoRecordModel>;
    private _translationFieldEditor: TranslationFieldEditor;
    private _labels = LABELS;
    private _originalValue: string;
    private _oldMemoTranslations: KnockoutObservableArray<TranslationModel>;

    constructor(flowFolderMemosModel: FlowFolderMemosModel, screenParams: ScreenParamsModel) {
        super();
        this._guid = Guid.NewGuid();
        this._memo = ko.observable(null);
        this._initialMemo = ko.observable(null);
        this._isEditMode = ko.observable(null);
        this._editMemoEntityId = ko.observable(null);
        this._editMemoIndex = ko.observable(null);
        this._editMemoKseq = ko.observable(null);
        this._oldEditMemo = ko.observable(null);
        this._isMemoTranslatable = flowFolderMemosModel.IsMemoTranslatable;
        this._recordId = screenParams.RecordId;
        this._entityId = screenParams.EntityId;
        this._flowFolderMemoArrays = ko.observableArray([]);
        this._oldMemoTranslations = ko.observableArray([]);
        this._translationFieldEditor = new TranslationFieldEditor();
        this.Trigger('DELETE_FLOW_FOLDER_MEMO_RECORD');
        this.Trigger('CREATE_FLOW_FOLDER_MEMO_RECORD');
        this.Trigger('CHANGE_FLOW_FOLDER_MEMO_RECORD');
        this.FlowFolderMemosInit(flowFolderMemosModel);
    }

    GetTemplate() {
        return MemoItems;
    }

    AfterRender(el: HTMLElement): void{

    }

    FlowFolderMemosInit(flowFolderMemosModel: FlowFolderMemosModel){
        if (!!flowFolderMemosModel){
            _.each(flowFolderMemosModel.MemoItems, memoItem => {
                let memoRecordModel = new MemoRecordModel();
                memoRecordModel.Kseq = memoItem.Kseq;
                memoRecordModel.RecordId = memoItem.RecordId;
                memoRecordModel.EntityId = memoItem.EntityId;

                memoRecordModel.Memo(memoItem.Memo);
                memoRecordModel.MemoTranslations(memoItem.MemoTranslations);

                memoRecordModel.FlowMemosOperationsSelectID = memoItem.FlowMemosOperationId;
                memoRecordModel.FlowMemosOperations = memoItem.FlowMemosOperations;

                memoRecordModel.Init();

                this._flowFolderMemoArrays.push(memoRecordModel);
            });
        }
    }

    ShowInModal() {
        let self = this;
        this._modal = new Modal({
            addClass: 'addMemoModal',
            width: '60vw',
            height: '60vh',
            closeOnEsc: false,
            onOpen: function () {
                $(this.closeButton[0]).off('click');
                this.closeButton[0].addEventListener('click', self.CloseMemoModal.bind(self, false));
            }
        }, false);

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
        this._modal.Show();
    }

    ShowInMemoModal() {
        this.AddMemoMod();
        this.ShowInModal();

        const desktopLanguage = GlobalManager.Instance.GetGlobal(GLOBALS.DESKTOP_LANGUAGE);
        let memoTranslations = [], translations = this._translationFieldEditor.GetTranslations(false);

        _.each(translations, translation => {

            const selectedLanguage = TranslationManager.Instance.GetLanguageById(translation.LanguageId);

            let translationModel = new TranslationModel();
            translationModel.Translation = translation.Value;
            translationModel.Language = new LanguageModel(selectedLanguage.Id, selectedLanguage.Name, selectedLanguage.ShortName, Number(selectedLanguage.Enabled), selectedLanguage.Flag);
            translationModel.Selected = desktopLanguage === selectedLanguage.ShortName;

            memoTranslations.push(translationModel);
        });

        this._oldMemoTranslations(memoTranslations);
    }

    CloseMemoModal(isSave: boolean) {
        let newMemo = this._isMemoTranslatable ? this._translationFieldEditor.GetDefaultTranslation().Value : this._memo();
        let oldMemo = this._oldEditMemo();

        let currentTranslations = this._translationFieldEditor.GetTranslations(false), translationsHaveChanges = false, oldMemoTranslations = this._oldMemoTranslations();
        _.each(currentTranslations, translation => {
            if(translation.Value || oldMemoTranslations[currentTranslations.indexOf(translation)].Translation){
                if(translation.Value != oldMemoTranslations[currentTranslations.indexOf(translation)].Translation) {
                    translationsHaveChanges = true;
                }
            }
        });

        if (!isSave && (newMemo !== oldMemo || translationsHaveChanges)){
            let confirmationDialog = new ConfirmationDialog({
                Text: CONFIRMATIONS.ALL_CHANGES_WILL_BE_LOST,
                Type: ConfirmationTypes.Question,
                TextConfirm: LABELS.YES,
                TextDecline: LABELS.NO
            });

            confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, () => {
                if (this._modal) {
                    this.Trigger('CHANGE_FLOW_FOLDER_MEMO_RECORD');
                    this._modal.Close();
                }
            });
            confirmationDialog.Show();

        } else {
            if (this._modal) {
                this.Trigger('CHANGE_FLOW_FOLDER_MEMO_RECORD');
                this._modal.Close();
            }
        }
    }

    private GetLanguages() {
        if (this._isMemoTranslatable) {
            if (this._translationFieldEditor.TranslationItemsList.length == 0) {
                this._translationFieldEditor.LoadTranslationItems();
            }
            const languages = this._translationFieldEditor.TranslationItemsList.map(translation => translation.Language);
            const activeLanguage = this._translationFieldEditor.ActiveTranslation.Language;

            return {
                List: languages,
                Active: activeLanguage
            };
        }

        return null;
    }

    private OnChangeLanguage(id: number) {
        const selectedTranslation = this._translationFieldEditor.GetTranslationById(id);
        this._translationFieldEditor.SelectTranslation(selectedTranslation);

        const selectedValue = _.unescape(selectedTranslation.Value());
        this._memoEditor.setContent(selectedValue || '');
        this._memo(selectedValue);
    }

    EditMemo(data: MemoRecordModel) {
        this._isEditMode(true);

        this._editMemoEntityId(null);
        this._editMemoKseq(null);
        this._oldEditMemo(null);

        if (data.Memo){
            this._editMemoEntityId(data.EntityId);
            this._editMemoKseq(data.Kseq);
            this._oldEditMemo(data.Memo());
            this._oldMemoTranslations(data.MemoTranslations());
            this.SetMemo(data);
        }
        this.ShowInModal();
    }

    AddMemoMod(){
        this._isEditMode(false);
        this._memo(null);
        this._oldEditMemo('');
    }

    GetTemplateName(){
        return 'Core/Controls/ButtonFollowUp/Templates/AddMemo';
    }

    AfterChangeMemo(content) {
        this._memo(content);

        const currentValue = this._memo();

        if (this._isMemoTranslatable) {
            this._translationFieldEditor.ActiveTranslation.Value(currentValue);
        }
    }

    AfterInitMemo(editor) {
        this._memoEditor = editor;
        this._memoEditor.setContent(this._memo() || '');
    }

    SetMemo(data) {
        if(data) {
            this._initialMemo(this._memo());
            this._originalValue = data.Memo() && _.unescape(data.Memo()) || '';

            let currentValue = this._originalValue;

            if (this._isMemoTranslatable) {

                if(!this._translationFieldEditor) {
                    this._translationFieldEditor = new TranslationFieldEditor();
                }

                const memoTranslations = _.map(data.MemoTranslations(), (memoItem: TranslationModel) => {
                    return {
                        LanguageId: memoItem.Language.K_Language,
                        Value: memoItem.Translation
                    }
                });

                const translation = TranslationManager.Instance.GetTranslation(data.Memo(), memoTranslations);

                currentValue = translation.TranslatedValue || translation.Value;

                this._translationFieldEditor.LoadTranslationItems();
                this._translationFieldEditor.SetTranslations(memoTranslations, data.Memo() === null ? '' : data.Memo());
                this._translationFieldEditor.SetActiveTranslation(translation.Language.Id);
            }

            this._memo(_.unescape(currentValue));

            if (this._memoEditor) {
                this._memoEditor.setContent(this._memo() || '');
            }
        }
    }

    GetUnescapeMemo(memo, memoTranslations): string {
        const flowMemoTranslations = _.map(memoTranslations(), (memoItem: TranslationModel) => {
            return {
                LanguageId: memoItem.Language.K_Language,
                Value: memoItem.Translation
            }
        });

        const translation = TranslationManager.Instance.GetTranslation(memo(), flowMemoTranslations);
        return _.unescape(translation.TranslatedValue);
    }

    GetOptionsMemoTooltip(content, translations){
        let isMobile = MobileChecker.IsMobile();

        let options = {};
        if (isMobile){
            options = {};
        } else {
            options = {
                content: this.GetUnescapeMemo(content, translations),
                position: {x: 'left', y: 'center'},
                outside: 'x'
            };
        }

        return options;
    }

    get MemoHasChanges() {
        return this._memo() !== this._initialMemo();
    }

    SaveMemoData(data){
        const memo = this._isMemoTranslatable ? this._translationFieldEditor.GetDefaultTranslation().Value : this._memo(),
              desktopLanguage = GlobalManager.Instance.GetGlobal(GLOBALS.DESKTOP_LANGUAGE);
        let memoTranslations = [], translations = this._translationFieldEditor.GetTranslations(false);

        _.each(translations, translation => {

            const selectedLanguage = TranslationManager.Instance.GetLanguageById(translation.LanguageId);

            let translationModel = new TranslationModel();
            translationModel.Translation = translation.Value;
            translationModel.Language = new LanguageModel(selectedLanguage.Id, selectedLanguage.Name, selectedLanguage.ShortName, Number(selectedLanguage.Enabled), selectedLanguage.Flag);
            translationModel.Selected = desktopLanguage === selectedLanguage.ShortName;

            memoTranslations.push(translationModel);
        });

        if (this._isEditMode()){

            let params: IUpdateFlowMemoRecordRequestModel = {
                EntityId: this._editMemoEntityId(),
                Kseq: this._editMemoKseq(),
                Memo: memo,
                MemoTranslations: memoTranslations
            };
            this._editMemoIndex(null);

            let currentTranslations = this._translationFieldEditor.GetTranslations(false), translationsHaveChanges = false, oldMemoTranslations = this._oldMemoTranslations();
            _.each(currentTranslations, translation => {
                if(translation.Value !== oldMemoTranslations[currentTranslations.indexOf(translation)].Translation) {
                    translationsHaveChanges = true;
                }
            });

            if (this._oldEditMemo() !== memo || translationsHaveChanges){
                BlockUI.Block();
                FlowFolderStore.UpdateFlowMemoRecord(params)
                    .always(()=>BlockUI.Unblock())
                    .then(data => {
                        this._editMemoIndex(this._flowFolderMemoArrays().findIndex(record => record.Kseq == this._editMemoKseq()));
                        _.each(this._flowFolderMemoArrays(), (record, index)=> {
                            if (index === this._editMemoIndex()){
                                record.Memo(memo);
                                record.MemoTranslations(memoTranslations);
                            }
                        });
                        this.CloseMemoModal(true);
                        new Notifier().Success(NOTIFICATIONS.DATA_SAVED);
                    })
            } else {
                new Notifier().Warning('No changes');
            }

        } else {

            let params: ICreateMemoRecordRequestModel = {
                EntityId: this._entityId,
                RecordId: this._recordId,
                Memo: memo,
                MemoTranslations: memoTranslations
            };

            FlowFolderStore.CreateFlowMemo(params)
                .then( data => {
                    if (data){
                        BlockUI.Block();
                        FlowFolderStore.GetFlowMemo({EntityId: this._entityId, Kseq: data})
                            .always(()=>BlockUI.Unblock())
                            .then(memoData => {
                                let memoRecordModel = new MemoRecordModel();
                                memoRecordModel.RecordId = memoData.RecordId;
                                memoRecordModel.EntityId = memoData.EntityId;
                                memoRecordModel.Kseq = memoData.Kseq;

                                memoRecordModel.Memo(memoData.Memo);
                                memoRecordModel.MemoPlaintext = memoData.MemoPlaintext;
                                memoRecordModel.MemoTranslations(memoData.MemoTranslations);

                                memoRecordModel.FlowMemosOperations = memoData.FlowMemosOperations;
                                memoRecordModel.FlowMemosOperationsSelectID = memoData.FlowMemosOperationId;

                                this._flowFolderMemoArrays.unshift(memoRecordModel);
                                this.CloseMemoModal(true);
                                new Notifier().Success(NOTIFICATIONS.DATA_SAVED);
                            })
                    }

                })
                .fail(err => {
                    new Notifier().Failed(err.message);
                })
        }
    }

    ChangedMemosOperations(value, flowMemoModel, event){
        if (event.originalEvent) { //user changed
            const params: IUpdateFlowOperationRecordRequestModel = {
                EntityId: this._entityId,
                Kseq: flowMemoModel.Kseq,
                FlowMemosOperationId: value().Id
            }

            BlockUI.Block();
            FlowFolderStore.UpdateFlowOperationRecord(params)
                .always(() => BlockUI.Unblock());
        }
    }

    DeleteMemoItem(memoRecord){
        const params: IDeleteMemoRecordRequestModel = {
            EntityId: memoRecord.EntityId,
            Kseq: memoRecord.Kseq
        }

        let confirmationDialog = new ConfirmationDialog({
            Text: `${CONFIRMATIONS.DO_YOU_WANT_TO_DELETE}?`,
            Type: ConfirmationTypes.Question,
            TextConfirm: LABELS.YES,
            TextDecline: LABELS.NO
        });

        confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, () => {
            FlowFolderStore.DeleteFlowMemo(params)
                .then(data => {
                    this._flowFolderMemoArrays.splice(this._flowFolderMemoArrays().findIndex(record => record.Kseq === params.Kseq), 1);
                    this.Trigger('DELETE_FLOW_FOLDER_MEMO_RECORD', {data: null});
                    new Notifier().Success(LABELS.RECORD_DELETED_LABEL);
                })
        });
        confirmationDialog.Show();
    }
}