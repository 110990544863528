export class BusinessRoleRestrictions {
    static get View() {
        return new BusinessRoleRestriction('Hide', 2, 'fa-eye')
    }

    static get Create() {
        return new BusinessRoleRestriction('Create', 4, 'fa-plus-square-o')
    }

    static get Update() {
        return new BusinessRoleRestriction('Update', 8, 'fa-pencil')
    }

    static get Delete() {
        return new BusinessRoleRestriction('Delete', 16, 'fa-trash-o');
    }

    static AsList(value?: number) {
        const list = [this.View, this.Create, this.Update, this.Delete];
        if (!value && value !== 0) return list;

        return list.filter(restriction => (restriction.Value & value) === restriction.Value);
    }
}

class BusinessRoleRestriction {
    constructor(public readonly Name: string, public readonly Value: number, public readonly IconName: string) {
    }

    PresentIn(value: number) {
        return (this.Value & value) === this.Value;
    }
}