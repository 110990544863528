import {GenericDeserialize} from 'libs/cerialize'

import {URL} from 'Core/Common/Url'
import {Request, IRequestOptions} from 'Core/Common/Request'
import {P} from 'Core/Common/Promise'
import {TableTypesModel} from 'Core/Screens/TypeScreen/Models/TableTypesModel'
import {IsTypeAvailableResponseModel} from "Core/Screens/TypeScreen/Models/IsTypeAvailableResponseModel";

export interface IGetTypesRequestModel {
	EntityId: number;
	ParentTypeId: number;
	WithRoot: boolean;
	OnlyEnabled: boolean;
	EntityName?: string;
}

export interface IIsTypeAvailableRequestModel {
	TypeId: number;
	ExampleTypeId: number;
	EntityId: number;
}

export class EntityTypesStore {
	static GetTypes(params: IGetTypesRequestModel): P.Promise<TableTypesModel> {
		let deferredResult = P.defer<TableTypesModel>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: URL.GET_TYPES,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(this.Deserialize(data));
			});

		return deferredResult.promise();
	}

	static GetAllTypes(params: IGetTypesRequestModel): P.Promise<TableTypesModel> {
		let deferredResult = P.defer<TableTypesModel>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: URL.GET_ALL_TYPES,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(this.Deserialize(data));
			});

		return deferredResult.promise();
	}

	static IsTypeAvailable(params: IIsTypeAvailableRequestModel): P.Promise<IsTypeAvailableResponseModel> {
		const deferredResult = P.defer<IsTypeAvailableResponseModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/ScreenFormApi/IsTypeAvailable',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then((result) => {
				deferredResult.resolve(result);
			})
			.fail(error => deferredResult.reject(this.GetResponseError(error, 'Error checking if type is available')));

		return deferredResult.promise();
	}

	static Deserialize(jsonObject: any): TableTypesModel {
		return GenericDeserialize<TableTypesModel>(jsonObject, TableTypesModel);
	}

	static GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
		if (err.status === 400) {
			return {message: JSON.parse(err.message).Message};
		}

		return {message: generalError};
	}
}