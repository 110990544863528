import {deserializeAs} from 'libs/cerialize';

import {LifeStatusSimpleModel} from "Core/Controls/ButtonFollowUp/Models/LifestatusSimpleModel";
import {ActionSubjectRecordModel} from "Core/ScreenManager/Models/ActionSubjectRecordModel";
import {ActionCheckListDto} from "Core/Components/ActionCheckList/Models/Dto/ActionCheckListDto";

export class LifeStatusesModel {
    @deserializeAs(LifeStatusSimpleModel)
    CurrentLifeStatus: LifeStatusSimpleModel;

    @deserializeAs(LifeStatusSimpleModel)
    LifeStatuses: Array<LifeStatusSimpleModel>;

    @deserializeAs(ActionSubjectRecordModel)
    ActionSubjectRecord: ActionSubjectRecordModel;

    @deserializeAs(ActionCheckListDto)
    CheckLists: Array<ActionCheckListDto>;
}