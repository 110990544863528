import * as ko from 'knockout';

import { Event } from "Core/Common/Event";
import { BlockUI } from 'Core/Common/BlockUi'

import { SearchScreen } from "Core/Screens/SearchScreen/SearchScreen";

import {
	SecurityEditorForm,
	EVENTS as SECURITY_EDITOR_FORM_EVENTS
} from 'Core/Components/SecurityEditor/Form/SecurityEditorForm';
import { SecurityEditorModel } from "Core/Components/SecurityEditor/Models/SecurityEditorModel";
import {
	SecurityEditorFormModel, SecurityPassViewModel,
	User
} from "Core/Components/SecurityEditor/Form/Models/SecurityEditorFormModel";
import { SecurityEditorStore } from "Core/Components/SecurityEditor/Stores/SecurityEditorStore";

import SecurityEditorTemplate from "Core/Components/SecurityEditor/Templates/SecurityEditor.html";
import { Notifier } from "Core/Common/Notifier";
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";
import { SecurityDataModel } from './Form/Models/SecurityDataModel';

export class SecurityEditor extends Event {
	private _entityId: number;
	private _recordId: number;

	private _visible: KnockoutObservable<boolean>;
	private _readonly: KnockoutObservable<boolean>;

	private _data: SecurityEditorModel;
	private _form: SecurityEditorForm;
	private _formModel: SecurityEditorFormModel;
	private _searchScreen: SearchScreen;
	private _labels = LABELS;

	constructor(entityId: number, recordId: number) {
		super();

		this._entityId = entityId;
		this._recordId = recordId;

		this._visible = ko.observable(false);
		this._readonly = ko.observable(true);
	}

	Setup(visible: boolean, readonly: boolean) {
		this._visible(visible);
		this._readonly(readonly);
	}

	GetTemplate() {
		return SecurityEditorTemplate;
	}

	OnClick() {
		this.ShowEditor();
	}

	ShowEditor() {
		if (this._formModel) {
			this.CreateForm(this.MapToFormModel(this._data));
		} else {
			this.GetServerData().then(model => {
				model.Owner.SecurityData = new SecurityDataModel({ ProfileName: model.Owner.Profile });
				this._data = model;
				this._formModel = this.MapToFormModel(model);
				this.CreateForm(this._formModel);
			}).fail(error => new Notifier().Failed(error.message));
		}
	}

	CreateForm(formModel: SecurityEditorFormModel) {
		this._form = new SecurityEditorForm(formModel, this._readonly());

		this._form.On(SECURITY_EDITOR_FORM_EVENTS.ADDING_NEW_USER, this, () => this.SearchUsers());
		this._form.On(SECURITY_EDITOR_FORM_EVENTS.SELECT_OWNER, this, () => this.SelectOwner(formModel));
		this._form.On(SECURITY_EDITOR_FORM_EVENTS.SAVING_USERS, this, eventArgs => this.Save(eventArgs.data));
		this._form.Show();
	}


	Close() {
		if (this._form) {
			this._form.Close();

			if (this._searchScreen) {
				this._searchScreen.Close();
			}
		}
	}

	GetData() {
		return this._data;
	}

	private GetServerData() {
		return SecurityEditorStore.GetSecurityInfoForRecord({
			EntityId: this._entityId,
			RecordId: this._recordId
		});
	}

	private SearchUsers() {
		this._searchScreen = new SearchScreen({
			SearchTerm: '',
			EntityName: 'SYS_USERS',
			ButtonAdd: false,
			CloseAfterSelectingRecord: false
		});

		this._searchScreen.On('RECORD_SELECTED', this, eventArgs => {
			const validTypes = ['USER', 'USERGROUP'];

			if (validTypes.indexOf(eventArgs.data.TypeName.toUpperCase()) < 0) {
				new Notifier().Warning(NOTIFICATIONS.TYPE_IS_NOT_SUPPORTED);
				return;
			}

			const user = new User();

			user.Id = eventArgs.data.RecordId;
			user.Name = eventArgs.data.Name;

			this._form.AddUser(user);

			this._searchScreen.Close();
		});

		this._searchScreen.Show();
	}

	private SelectOwner(formModel: SecurityEditorFormModel) {

		this._searchScreen = new SearchScreen({
			SearchTerm: '',
			EntityName: 'SYS_USERS',
			ButtonAdd: false,
			CloseAfterSelectingRecord: false
		});

		this._searchScreen.On('RECORD_SELECTED', this, eventArgs => {
			const validTypes = ['USER'];

			if (validTypes.indexOf(eventArgs.data.TypeName.toUpperCase()) < 0) {
				new Notifier().Warning("This type is not supported");
				return;
			}

			BlockUI.Block();
			SecurityEditorStore.GetSecurityData({
				UserId: eventArgs.data.RecordId
			})
				.then(securityDataDto => {
					const securityData = new SecurityDataModel();
					securityData.UserAllowance = securityDataDto.UserAllowance;

					const mainProfile = securityDataDto.SecurityProfiles.find(profile => profile.Main);
					securityData.ProfileId = mainProfile.Id;
					securityData.ProfileName = mainProfile.Name;


					const owner = User.Create(eventArgs.data.RecordId, eventArgs.data.Name, securityData);
					formModel.Owner(owner);
					this._searchScreen.Close();
				})
				.fail(error => new Notifier().Failed(error.message))
				.always(() => { BlockUI.Unblock() });
		});

		this._searchScreen.Show();
	}


	private MapToFormModel(model: SecurityEditorModel) {
		const formModel = new SecurityEditorFormModel();
		const owner = <any>(model.Owner || {});

		const user = User.Create(owner.Id, owner.Name, owner.SecurityData);
		formModel.Owner(user);

		const users = model.Users.filter(user => user.Rights > 0 || user.OptionalRights > 0).map(user => User.Create(user.Id, user.Name, user.SecurityData, user.Rights, user.OptionalRights, user.Deleted));
		formModel.Users(users);

		formModel.SecurityPass = SecurityPassViewModel.Create(
			model.SecurityPass.DoNotPass,
			model.SecurityPass.PassRights,
			model.SecurityPass.PassOptionalRights,
			model.SecurityPass.PassInheritance,
			model.SecurityPass.RefusePass,
			model.SecurityPass.PassOwner
		);

		return formModel;
	}

	private Save(data: SecurityEditorModel) {
		this._data = data;
		this.Trigger('SAVING_CHANGES', data);
	}
}