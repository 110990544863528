import * as ko from "knockout"

export class Canvas {
	static Init() {
		ko.bindingHandlers.canvas = {
			init: (element, valueAccessor) => {
				let options = valueAccessor();
				if (options && options.context) {
					var context = element.getContext('2d');
					options.context(context);
				}
			}
		}
	}
}