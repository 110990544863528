import * as _ from "underscore";

import {Deserialize, deserialize} from 'libs/cerialize';

export class EnvironmentDto {
    @deserialize
    Url: string;

    @deserialize
    Type: string;

    @deserialize
    DatabaseName: string;

    @deserialize
    Size: string;
    
    @deserialize
    LastLoginDate: string;

    @deserialize
    ActiveSessionCount: number;

    @deserialize
    Id: number;

    @deserialize
    IsMarkedForDeletion: boolean;
}