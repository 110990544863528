export enum LifeStatusGroupEnum {
	Planned,
	Acquired,
	Operational,
	Maintained,
	Retired,
	Example,
	Error
}

export class LifeStatusGroups {
	private static LifeStatusGroupsArray = [
		{ Group: LifeStatusGroupEnum.Planned, Start: 1000, End: 1999 },
		{ Group: LifeStatusGroupEnum.Acquired, Start: 2000, End: 2999 },
		{ Group: LifeStatusGroupEnum.Operational, Start: 3000, End: 3999 },
		{ Group: LifeStatusGroupEnum.Maintained, Start: 4000, End: 4999 },
		{ Group: LifeStatusGroupEnum.Retired, Start: 5000, End: 5999 },
		{ Group: LifeStatusGroupEnum.Example, Start: 6000, End: 6999 },
		{ Group: LifeStatusGroupEnum.Error, Start: 7000, End: 7999 }
	];

	public static GetLifeStatusGroup(sort: number): LifeStatusGroupEnum {
		const selectedLifeStatusGroup =
			this.LifeStatusGroupsArray.find(element => element.Start <= sort && element.End >= sort);
		return selectedLifeStatusGroup && selectedLifeStatusGroup.Group;
	}
}