import { deserialize, deserializeAs, serialize, serializeAs } from 'libs/cerialize';
import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { CanvasPositionModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/CanvasPositionModel';
import { UseMainCondition, LinkDirectionCondition } from 'QueryBuilder/Enums';
import { JoinTypes } from 'QueryBuilder/Enums';
import { CanvasSizeModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/CanvasSizeModel';

export class QueryEntityJoinModel {
	constructor() {
		this.Joins = [];
		this.SubEntityJoins = [];
		this.LookupEntityJoins = [];
		this.ReferenceEntityJoins = [];
		this.ReferenceLookupEntityJoins = [];
		this.CanvasPosition = new CanvasPositionModel();
		this.CanvasSize = new CanvasSizeModel();
		this.JoinType = Number(JoinTypes.LeftJoin);
		this.UseMain = Number(UseMainCondition.NotUseMain);
		this.LinkDirection = Number(LinkDirectionCondition.LeftToRight);
	}

	@serializeAs(QueryEntityModel)
	@deserializeAs(QueryEntityModel)
	Entity: QueryEntityModel;

	@serialize
	@deserialize
	LookupFieldId: number;

	@serializeAs(QueryEntityModel)
	@deserializeAs(QueryEntityModel)
	LinkEntity: QueryEntityModel;

	@serialize
	@deserialize
	ReferenceFieldId: number;

	@serializeAs(QueryEntityJoinModel)
	@deserializeAs(QueryEntityJoinModel)
	Joins: Array<QueryEntityJoinModel>;

	@serialize
	@deserialize
	IsSubQuery: boolean;

	@serialize
	@deserialize
	Guid: string;

	@serializeAs(CanvasPositionModel)
	@deserializeAs(CanvasPositionModel)
	CanvasPosition: CanvasPositionModel;

	@serializeAs(CanvasSizeModel)
	@deserializeAs(CanvasSizeModel)
	CanvasSize: CanvasSizeModel;

	@serializeAs(JoinTypes)
	@deserializeAs(JoinTypes)
	JoinType: JoinTypes;

	@serialize
	@deserialize
	UseMain: number;

	@serialize
	@deserialize
	LinkDirection: number;

	@serializeAs(QueryEntityJoinModel)
	@deserializeAs(QueryEntityJoinModel)
	SubEntityJoins: Array<QueryEntityJoinModel>;

	@serializeAs(QueryEntityJoinModel)
	@deserializeAs(QueryEntityJoinModel)
	LookupEntityJoins: Array<QueryEntityJoinModel>;

	@serializeAs(QueryEntityJoinModel)
	@deserializeAs(QueryEntityJoinModel)
	ReferenceEntityJoins: Array<QueryEntityJoinModel>;

	@serializeAs(QueryEntityJoinModel)
	@deserializeAs(QueryEntityJoinModel)
	ReferenceLookupEntityJoins: Array<QueryEntityJoinModel>;
}