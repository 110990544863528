import * as ko from "knockout";
import {deserialize, deserializeAs} from 'libs/cerialize';
import {Icon} from 'Core/Icon/Icon'
import { IconModel } from 'Core/Controls/BaseControl/Models/IconModel'
import { TranslationModel } from "Core/Controls/BaseControl/Models/TranslationModel";

export class FieldModel {
	protected _fieldIcon: Icon;

	@deserialize
	FieldId: number;

	@deserialize
	FieldName: string;

	@deserialize
	FieldNameTranslation: string;

	@deserialize
	FieldTypeName: string;

	@deserialize
	IsPrimaryKey: boolean;

	@deserialize
    IsIndexed: boolean;

    @deserialize
    IsVirtual: boolean;

	@deserialize
    IsSystem: boolean;

	@deserialize
	Icon: string;

	@deserialize
	IconImage: string;

	@deserialize
	IconType: string;

	@deserialize
	FieldTypeIcon: string;

	@deserialize
	FieldTypeIconImage: string;

	@deserialize
	FieldTypeIconType: string;

	@deserialize
	FieldFormatIcon: string;

	@deserialize
	FieldFormatIconImage: string;

	@deserialize
	FieldFormatIconType: string;

	@deserialize
	Sort: number;
	
	@deserialize
	ValTableId: number;

	@deserialize
	ValFieldId: number;

	@deserializeAs(TranslationModel)
	Translations: Array<TranslationModel>;

	IsSelected: KnockoutObservable<boolean>;
	DisplayOrder: KnockoutObservable<number>;
    Total: KnockoutObservable<boolean>;
    Average: KnockoutObservable<boolean>;
    IsWrapped: KnockoutObservable<boolean>;

	constructor() {
        this.IsSelected = ko.observable(false);
        this.Total = ko.observable(false);
        this.DisplayOrder = ko.observable(0);
        this.IsWrapped = ko.observable(false);
        this.Average = ko.observable(false);
	}

	get FieldIcon(): Icon {
		if (!this._fieldIcon) {
			if (this.Icon || this.IconImage) {
				this._fieldIcon = new Icon(this.GetIconModel(this.Icon, this.IconType, this.IconImage));
			} else if (this.FieldTypeIcon || this.FieldTypeIconImage) {
				this._fieldIcon = new Icon(this.GetIconModel(this.FieldTypeIcon, this.FieldTypeIconType, this.FieldTypeIconImage));
			} else if (this.FieldFormatIcon || this.FieldFormatIconImage) {
				this._fieldIcon = new Icon(this.GetIconModel(this.FieldFormatIcon, this.FieldFormatIconType, this.FieldFormatIconImage));
			}
		}
		return this._fieldIcon;
	}

	GetIconModel(name, fontName, image): IconModel {		
		var isImage = !!image;
		var iconModel = new IconModel();
		iconModel.IsIcon = !isImage;
		iconModel.IsImage = isImage;
		iconModel.Name = name;
		iconModel.Image = image;
		iconModel.FontName = fontName;
		return iconModel;
	}
}