import {deserialize, deserializeAs, serialize, serializeAs} from 'libs/cerialize';

import {CustomThemeSetting} from './CustomThemeSetting';

export class CustomThemeDescriptor {
    @serialize
    @deserialize
    Name: string;

    @serializeAs(CustomThemeSetting)
    @deserializeAs(CustomThemeSetting)
    Settings: CustomThemeSetting[];

    constructor() {
        this.Settings = [];
    }

    static Create(name: string, settings: CustomThemeSetting[]) {
        const customTheme = new CustomThemeDescriptor();

        customTheme.Name = name;
        customTheme.Settings = settings;

        return customTheme;
    }
}

