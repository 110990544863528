import * as ko from "knockout";

export class PreventBubbleExtention {
	static Init() {
		ko.bindingHandlers.preventBubble = {
			init: (element, valueAccessor) => {
				var eventName = ko.utils.unwrapObservable(valueAccessor());
				var arr = eventName;
				if (!eventName.pop) {
					arr = [arr];
				}
				for (var p in arr) {
					ko.utils.registerEventHandler(element, arr[p], (event) => {
						event.cancelBubble = true;
						if (event.stopPropagation && event.type != 'beforeunload') {
							event.stopPropagation();
						}
					});
				}
			}
		};
	}
};