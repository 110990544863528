import {deserialize, deserializeAs, serialize, serializeAs} from 'libs/cerialize';
import { MsAccessTableDto } from './MsAccessTableDto';

export class MsAccessSchemaDto {
	
    constructor(){
        this.Tables = [];
    }

    @deserializeAs(MsAccessTableDto)
	Tables: Array<MsAccessTableDto>;

    @deserialize
    Path: string;
}