import * as ko from 'knockout'
import * as _ from 'underscore'

import { ComplexControl } from 'Core/Controls/ComplexControl/ComplexControl'
import { IControlParam } from 'Core/Screens/IScreen'
import { TemplateDesignerStore, IEditTemplateDto, ISaveTemplateDto, IGetLanguagesDto, ICreateTemplateDto, IPreviewHtmlTemplateDto } from 'Core/Controls/TemplateDesigner/Stores/TemplateDesignerStore';
import { IControlValue } from 'Core/Controls/BaseControl/BaseControl';
import { RequiredFieldModel } from 'Core/Controls/ComplexControl/Models/RequiredFieldModel';
import { Notifier } from 'Core/Common/Notifier';
import { RenderModes, FIELD_TYPES, TABLE_TYPES } from 'Core/Constant'
import {EmailSubject, QueryToolbar, Script, Variable} from 'Core/Controls/TemplateDesigner/QueryToolbar/QueryToolbar';
import { EVENTS as QEUERY_TOOLBAR_EVENTS } from 'Core/Controls/TemplateDesigner/QueryToolbar/Events';
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";
import { ToolbarQueryEntityJoin } from 'Desktop/Toolbar/ToolbarQueryEntityJoin/ToolbarQueryEntityJoin';
import { TemplateLanguageModel } from 'Core/Controls/TemplateDesigner/Models/TemplateLanguageModel';
import { EditTemplateModel } from 'Core/Controls/TemplateDesigner/Models/EditTemplateModel';
import { BlockUI } from 'Core/Common/BlockUi';
import { SearchScreen } from 'Core/Screens/SearchScreen/SearchScreen';
import { Modal } from 'Core/Common/Modal';

import ViewTemplate from 'Core/Controls/TemplateDesigner/Templates/View.html';
import ToolBarTemplate from 'Core/Controls/TemplateDesigner/Templates/ToolBar.html';
import FieldVariableTemplate from 'Core/Controls/TemplateDesigner/Templates/Variables/Field.html';
import TableVariableTemplate from 'Core/Controls/TemplateDesigner/Templates/Variables/Table.html';
import EmailSubjectTemplate from 'Core/Controls/TemplateDesigner/Templates/Variables/EmailSubject.html';
import OpenRecordLinkTemplate from 'Core/Controls/TemplateDesigner/Templates/Variables/OpenRecordLink.html';

import LoginURLCommandTemplate from 'Core/Controls/TemplateDesigner/Templates/Variables/LoginURLCommand.html';
import NextStatusURLCommandTemplate from 'Core/Controls/TemplateDesigner/Templates/Variables/NextStatusURLCommand.html';
import StepsNextStatusURLCommandTemplate from 'Core/Controls/TemplateDesigner/Templates/Variables/StepsNextStatusURLCommand.html';
import ConfirmedOptinURLCommandTemplate from 'Core/Controls/TemplateDesigner/Templates/Variables/ConfirmedOptinURLCommand.html';
import UnsubscribeMeURLCommandTemplate from 'Core/Controls/TemplateDesigner/Templates/Variables/UnsubscribeMeURLCommand.html';
import ScriptTemplate from 'Core/Controls/TemplateDesigner/Templates/Variables/Script.html';
import VariableTemplate from 'Core/Controls/TemplateDesigner/Templates/Variables/Variable.html';

ko.templates['Core/Controls/TemplateDesigner/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/TemplateDesigner/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/TemplateDesigner/Templates/Design'] = ComplexControl.designTemplate;
ko.templates['Core/Controls/TemplateDesigner/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/TemplateDesigner/Templates/NextStatusURLCommand'] = NextStatusURLCommandTemplate;
ko.templates['Core/Controls/TemplateDesigner/Templates/Variables/EmailSubject'] = EmailSubjectTemplate;

const HTML_TEMPLATE = 'html';

const TINYMCE_COMMANDS = {
	INSERT_CONTENT: 'mceInsertContent'
}

const URL_COMMANDS = {
	STEPS_NEXT_STATUS: 'StepsNextStatus',
	NEXT_STATUS: 'NextStatus',
	UNSUBSCRIBE_ME : 'UnsubscribeMe',
	CONFIRMED_OPTIN : 'ConfirmedOptin',
	LOGIN_GUEST: 'LoginGuest',
	LOGIN_GDPR: 'LoginGDPR',
	LOGIN_CONTACT: 'LoginContactUser'
}

declare function unescape(s:string): string;
declare function escape(s:string): string;

export class TemplateDesigner extends ComplexControl {
	private _enableEditButton: KnockoutObservable<boolean>;
	private _isWebTemplate: KnockoutObservable<boolean>;
	private _toolBar: KnockoutObservable<QueryToolbar>;
	private _tinyEditor: any;
	private _htmlTemplate: string;
	private _fileName: string;
	private _templateFileId: number;
	private _templateFileKSeq: number;
	private _templateLanguages: KnockoutObservableArray<TemplateLanguageModel>;
	private _hasData: KnockoutObservable<boolean>;

	constructor(params: IControlParam) {
		super(params);
		this._isWebTemplate = ko.observable(false);
		this._toolBar = ko.observable(null);
		this._templateLanguages = ko.observableArray([]);
		this._hasData = ko.observable(false);
		this.Init();
	}

	ApplyProperties() {}

	Init() {
		this._enableEditButton = ko.observable(false);
		if (this.IsRunTime) {
			if (this._form && this._form.GetScreen()) {
				if (this._form.GetScreen().GetTableTypeName().replace('.', '') === HTML_TEMPLATE) {
					this._isWebTemplate(true);
				}
			}
		}

		this._requiredFields([
			new RequiredFieldModel('NAME', FIELD_TYPES.Text, TABLE_TYPES.Entity, null),
			new RequiredFieldModel('F_TYPE', FIELD_TYPES.Lookup, TABLE_TYPES.Entity, null),
			new RequiredFieldModel('TEMPLATE', FIELD_TYPES.Document, TABLE_TYPES.Entity, null)
		]);

		this.InitRequiredFields();

		if (this._isWebTemplate()) {
			this._toolBar(new QueryToolbar());
			this._toolBar().On(QEUERY_TOOLBAR_EVENTS.DROP_FIELD,
				this,
				(eventArgs) => {
					if (eventArgs.data) {
						if (eventArgs.data.Field && eventArgs.data.Entity) {
							var entityName = eventArgs.data.Entity.Model.Metadata.Name;
							if (eventArgs.data.Entity.Model.Index > 1) {
								entityName = `${entityName}${eventArgs.data.Entity.Model.Index}`;
							}

							var variable = this.GetFieldTemplate(entityName, eventArgs.data.Field.Model.Metadata.Name, eventArgs.data.Field.Model.Metadata.NameTranslation);
							if (this._tinyEditor) {
								this._tinyEditor.execCommand(TINYMCE_COMMANDS.INSERT_CONTENT, false, variable);
							}
						}
					}
				});

			this._toolBar().On(QEUERY_TOOLBAR_EVENTS.DROP_ENTITY,
				this,
				(eventArgs) => {
					if (eventArgs.data) {
						if (eventArgs.data.Entity) {
							var table = this.GetTableTemplate(eventArgs.data.Entity);
							if (this._tinyEditor) {
								this._tinyEditor.execCommand(TINYMCE_COMMANDS.INSERT_CONTENT, false, table);
							}
						}
					}
				});

			this._toolBar().On(QEUERY_TOOLBAR_EVENTS.DROP_SPHEERES_LINK,
				this,
				(eventArgs) => {
					if (this._tinyEditor) {
						this._tinyEditor.execCommand(TINYMCE_COMMANDS.INSERT_CONTENT, false, this.GetSpheeresLinkTemplate());
					}
				});

			this._toolBar().On(QEUERY_TOOLBAR_EVENTS.DROP_OPEN_RECORD_LINK,
					this,
					(eventArgs) => {
						if (this._tinyEditor) {
							this._tinyEditor.execCommand(TINYMCE_COMMANDS.INSERT_CONTENT, false, this.GetOpenRecordLinkTemplate());
						}
					});

			this._toolBar().On(QEUERY_TOOLBAR_EVENTS.DROP_EMAIL_SUBJECT,
				this,
				(eventArgs) => {
					if (this._tinyEditor) {
						this._tinyEditor.execCommand(TINYMCE_COMMANDS.INSERT_CONTENT, false, this.GetEmailSubjectTemplate(eventArgs.data.EmailSubject));
					}
				});

			this._toolBar().On(QEUERY_TOOLBAR_EVENTS.DROP_CC,
				this,
				(eventArgs) => {
					if (this._tinyEditor) {
						this._tinyEditor.execCommand(TINYMCE_COMMANDS.INSERT_CONTENT, false, this.GetEmailSubjectTemplate(eventArgs.data.EmailSubject));
					}
				});

			this._toolBar().On(QEUERY_TOOLBAR_EVENTS.DROP_TO,
				this,
				(eventArgs) => {
					if (this._tinyEditor) {
						this._tinyEditor.execCommand(TINYMCE_COMMANDS.INSERT_CONTENT, false, this.GetEmailSubjectTemplate(eventArgs.data.EmailSubject));
					}
				});
	
			this._toolBar().On(QEUERY_TOOLBAR_EVENTS.DROP_BCC,
				this,
				(eventArgs) => {
					if (this._tinyEditor) {
							this._tinyEditor.execCommand(TINYMCE_COMMANDS.INSERT_CONTENT, false, this.GetEmailSubjectTemplate(eventArgs.data.EmailSubject));
						}
			});

			this._toolBar().On(QEUERY_TOOLBAR_EVENTS.DROP_SCRIPT,
				this,
				(eventArgs) => {
					if (this._tinyEditor) {
							this._tinyEditor.execCommand(TINYMCE_COMMANDS.INSERT_CONTENT, false, this.GetScriptTemplate(eventArgs.data.Script));
						}
			});

			this._toolBar().On(QEUERY_TOOLBAR_EVENTS.DROP_VARIABLE,
				this,
				(eventArgs) => {
					if (this._tinyEditor) {
							this._tinyEditor.execCommand(TINYMCE_COMMANDS.INSERT_CONTENT, false, this.GetVariableTemplate(eventArgs.data.Variable));
						}
			});

			this._toolBar().On(QEUERY_TOOLBAR_EVENTS.DROP_URL_COMMAND,
				this,
				(eventArgs) => {
					if (this._tinyEditor) {
						this._tinyEditor.execCommand(TINYMCE_COMMANDS.INSERT_CONTENT, false, this.GetUrlCommandTemplate(eventArgs.data.Id, eventArgs.data.Name, eventArgs.data.Type));

					}
				});


			this._toolBar().On(QEUERY_TOOLBAR_EVENTS.SAVE,
				this,
				(eventArgs) => {
					this.SaveTemplate();
				});

				this._toolBar().On(QEUERY_TOOLBAR_EVENTS.PREVIEW,
					this,
					(eventArgs) => {
						this.Preview(eventArgs.data.EntityId);
					});
		}
	}

	private Preview(entityId: number){
		const searchScreen = new SearchScreen({
			ButtonAdd: false,
			EntityId: entityId,
			SearchTerm: '',
			ControlId: 0,
			ConditionToggler: false
		});

		searchScreen.On('RECORD_SELECTED', this, (evtArgs) => {
			BlockUI.Block();
			TemplateDesignerStore
				.Preview({ RecordId: evtArgs.data.RecordId, QueryId: this._toolBar().QueryId, Data: this.ToBase64(this._tinyEditor.getContent()) })
				.always(() => {
					BlockUI.Unblock();
				})
				.then((data) => {
					let modal = new Modal();
					modal.SetContent(this.FromBase64(data));
					modal.Show();
				})
				.fail((err) => {
					var notifier = new Notifier(null);
					notifier.Failed(err.message);
				});

		});

		searchScreen.Show();
	}

	GetTableTemplate(join: any) {
		var fields = [];
		var titles = [];
		if (join instanceof ToolbarQueryEntityJoin) {
			_.each(join.Entity.Columns, (column) => {
				var entityName = join.Entity.Model.Metadata.Name;
				if (join.Entity.Model.Index > 1) {
					entityName = `${entityName}${join.Entity.Model.Index}`;
				}

				var translation = '';
				if (column.Model.Metadata.NameTranslation) {
					translation = `(${column.Model.Metadata.NameTranslation})`;
				};

				fields.push(`${entityName}.${column.Model.Metadata.Name}${translation}`);
				var title = column.Model.Metadata.NameTranslation ? column.Model.Metadata.NameTranslation : `${join.Entity.Model.Metadata.Name}.${column.Model.Metadata.Name}`;
				titles.push(title);
			});

			if(join.LinkEntity){
				_.each(join.LinkEntity.Columns, (column) => {
					var entityName = join.LinkEntity.Model.Metadata.Name;
					if (join.LinkEntity.Model.Index > 1) {
						entityName = `${entityName}${join.LinkEntity.Model.Index}`;
					}

					var translation = '';
					if (column.Model.Metadata.NameTranslation) {
						translation = `(${column.Model.Metadata.NameTranslation})`;
					};

					fields.push(`${entityName}.${column.Model.Metadata.Name}${translation}`);
					var title = column.Model.Metadata.NameTranslation ? column.Model.Metadata.NameTranslation : `${join.LinkEntity.Model.Metadata.Name}.${column.Model.Metadata.Name}`;
					titles.push(title);
				});
			}
		}

		var variables = [];

		_.each(fields, field => {
			variables.push(`{REPEAT ${field}}`);
		});

		return (ko as any).renderTemplateXHtml(TableVariableTemplate, { Columns: titles, Fields: variables });
	}

	GetFieldTemplate(entityName: string, fieldName: string, fieldNameTranslation: string) {
		var translation = '';
		if (fieldNameTranslation) { translation = `(${fieldNameTranslation})`; }
		return (ko as any).renderTemplateXHtml(FieldVariableTemplate, `{SINGLE ${entityName}.${fieldName}${translation}}`);
	}

	GetEmailSubjectTemplate(subject: EmailSubject) {
		return (ko as any).renderTemplateXHtml(EmailSubjectTemplate, `${subject.Value}`);
	}

	GetScriptTemplate(script: Script) {
		return (ko as any).renderTemplateXHtml(ScriptTemplate, `${script.Value}`);
	}

	GetVariableTemplate(variable: Variable) {
		return (ko as any).renderTemplateXHtml(VariableTemplate, `${variable.Value}`);
	}

	GetSpheeresLinkTemplate() {
		return (ko as any).renderTemplateXHtml(FieldVariableTemplate, '{SPHEERES_LINK}');
	}

	GetOpenRecordLinkTemplate(){
		return OpenRecordLinkTemplate;
	}

	GetUrlCommandTemplate(id: string, name: string, type: string) {

		if(URL_COMMANDS.STEPS_NEXT_STATUS === type){
			return (ko as any).renderTemplateXHtml(StepsNextStatusURLCommandTemplate, { id: id, name: name, type: type });
		}

		if(URL_COMMANDS.NEXT_STATUS === type){
			return (ko as any).renderTemplateXHtml(NextStatusURLCommandTemplate, { id: id, name: name, type: type });
		}

		if(URL_COMMANDS.CONFIRMED_OPTIN === type){
			return (ko as any).renderTemplateXHtml(ConfirmedOptinURLCommandTemplate, { id: id, name: name, type: type });
		}

		if(URL_COMMANDS.UNSUBSCRIBE_ME === type){
			return (ko as any).renderTemplateXHtml(UnsubscribeMeURLCommandTemplate, { id: id, name: name, type: type });
		}
		
		if(URL_COMMANDS.LOGIN_GUEST === type || URL_COMMANDS.LOGIN_CONTACT === type || URL_COMMANDS.LOGIN_GDPR === type){
			return (ko as any).renderTemplateXHtml(LoginURLCommandTemplate, { id: id, name: name, type: type });
		}

		return '';
	}

	SetValue(value: IControlValue): void {
		if (value && value.SubjectRecordId > 0) {
			this._enableEditButton(true);
			this.LoadData();
		}
	}

	LoadData() {
		let request: IGetLanguagesDto = {
			SubjectEntityId: this.GetForm().GetScreen().GetEntityId(),
			SubjectRecordId: this.GetForm().GetScreen().GetRecordId(),
			ControlId: this.GetControlId()
		};
		
		TemplateDesignerStore.GetLanguages(request).then((languages)=>{
			this._templateLanguages(languages);
		}).fail(err=>{
			new Notifier(null).Failed(err.message);
		});
	}

	UpdateTemplate(template: TemplateLanguageModel) {
		this.SelectTemplateLanguage();

		var requestModel: IEditTemplateDto = {
			SubjectRecordId: this._form.GetScreen().GetRecordId(),
			SubjectEntityId: this._form.GetScreen().GetEntityId(),
			LanguageId: template.Id,
			ControlId: this.GetControlId()
		};

		BlockUI.Block();
		TemplateDesignerStore
			.Edit(requestModel)
			.always(() => {
				BlockUI.Unblock();
			})
			.then((template) => {
				this.SetTemplate(template);
			})
			.fail((err) => {
				var notifier = new Notifier(null);
				notifier.Failed(err.message);
			});
	}

	SetTemplate(template: EditTemplateModel){
		if(this._isWebTemplate()){
			this._toolBar().QueryId = template.QueryId;
			this._fileName = template.FileName;
			this._templateFileId = template.RecordId
			this._templateFileKSeq = template.KSeq;
			this._toolBar().SetCommands(template.UrlCommands);
			this._toolBar().Refresh();

			if (this._tinyEditor) {
				this._tinyEditor.setContent(this.FromBase64(template.Content));
			} else {
				this._htmlTemplate = this.FromBase64(template.Content);
			}
			this._hasData(true);
		}
	}

	SaveTemplate() {
		var requestModel: ISaveTemplateDto = {
			FileName: this._fileName,
			Content: this.ToBase64(this._tinyEditor.getContent()),
			QueryId: this._toolBar().QueryId,
			ControlId: this.GetControlId(),
			RecordId: this._templateFileId,
			KSeq: this._templateFileKSeq
		}

		BlockUI.Block();
		TemplateDesignerStore
			.Save(requestModel)
			.always(() => {
				BlockUI.Unblock();
			})
			.then(() => {
				var notifier = new Notifier(null);
				notifier.Success(NOTIFICATIONS.RECORD_SAVED);
			})
			.fail((err) => {
				var notifier = new Notifier(null);
				notifier.Failed(err.message);
			});
	}

	private ToBase64(str: string): string {
		return window.btoa(unescape(encodeURIComponent(str)));
	}
	
	private FromBase64(str: string):string {
		return decodeURIComponent(escape(window.atob(str)));
	}

	AfterTinyInit(editor) {
		this._tinyEditor = editor;
		if (this._htmlTemplate) {
			this._tinyEditor.setContent(this._htmlTemplate);
		}
	}

	SelectTemplateLanguage(){
		this._hasData(false);
		this._tinyEditor = null;
	}

	CreateTemplate(templateLanguage: TemplateLanguageModel){

		var requestModel: ICreateTemplateDto = {
			SubjectRecordId: this._form.GetScreen().GetRecordId(),
			SubjectEntityId: this._form.GetScreen().GetEntityId(),
			LanguageId: templateLanguage.Id,
			ControlId: this.GetControlId()
		};

		BlockUI.Block();
		TemplateDesignerStore
			.Create(requestModel)
			.always(() => {
				BlockUI.Unblock();
				this.LoadData();
			})
			.then((template) => { 
				templateLanguage.HasTemplate = true;
				this._templateLanguages.valueHasMutated();
				this.SetTemplate(template);
			})
			.fail((err) => {
				var notifier = new Notifier(null);
				notifier.Failed(err.message);
			});
	}

	AfterTinyChange() { }
}