import * as ko from 'knockout'

import {RenderModes} from 'Core/Constant';

import {P} from 'Core/Common/Promise';
import {Notifier} from 'Core/Common/Notifier';
import {BlockUI} from 'Core/Common/BlockUi';

import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";

import {NOTIFICATIONS} from "Core/Components/Translation/Locales";

import {IFinancialDashboardParams} from 'Core/Components/Dashboards/FinancialDashboard/Params/IFinancialDashboardParams';

import {IReadyForInvoicingEventData} from './Components/ReadyForInvoicing/Events/IReadyForInvoicingEventData';
import {ISendToFPEventData} from './Components/ReadyForProcessing/Events/ISendToFPEventData';

import {IFinancialDashboardStore} from './Stores/IFinancialDashboardStore';

import {ReadyForInvoicing} from './Components/ReadyForInvoicing/ReadyForInvoicing';
import {ReadyForProcessing} from './Components/ReadyForProcessing/ReadyForProcessing';
import {Ledger} from './Components/Ledger/Ledger';
import {FinancialAPI} from "./Components/FinancialAPI/FinancialAPI";

import {InvoicingResults} from './Components/InvoicingResults/InvoicingResults';
import {ReadyForProcessingData} from "./Components/ReadyForProcessingData/ReadyForProcessingData";
import {ReadyForInvoicingData} from "./Components/ReadyForInvoicingData/ReadyForInvoicingData";

import {StoreFactory} from './Factories/StoreFactory';

import {FinancialDashboardDtoModel} from './Models/Dto/Response/FinancialDashboardDtoModel';
import {InvoicingResultsDtoModel} from './Models/Dto/Response/InvoicingResultsDtoModel';

import Template from 'Core/Components/Dashboards/FinancialDashboard/Templates/Template.html';

export class FinancialDashboard {
    private _store: IFinancialDashboardStore;

    private _readyForInvoicingComponent: KnockoutObservable<ReadyForInvoicing>;
    private _readyForProcessingComponent: KnockoutObservable<ReadyForProcessing>;
    private _ledgerComponent: KnockoutObservable<Ledger>;
    private _financialAPIComponent: KnockoutObservable<FinancialAPI>;
    private _loadInProgres: KnockoutObservable<boolean>;

    constructor(private _params: IFinancialDashboardParams) {
        this._store = StoreFactory.GetStore(this._params.RenderMode);

        this._readyForInvoicingComponent = ko.observable(null);
        this._readyForProcessingComponent = ko.observable(null);
        this._ledgerComponent = ko.observable(null);
        this._financialAPIComponent = ko.observable(null);
        this._loadInProgres = ko.observable(false);
    }

    get IsDesignMode() {
        return this._params.RenderMode === RenderModes.Design;
    }

    get IsViewMode() {
        return this._params.RenderMode === RenderModes.View;
    }

    GetTemplate() {
        return Template;
    }

    Render() {
        this.Refresh();
    }

    private InitComponents(model: FinancialDashboardDtoModel) {
        this._readyForInvoicingComponent(new ReadyForInvoicing({SourceTables: model.SourceTables}));

        this._readyForProcessingComponent(new ReadyForProcessing({
            FinancialProcessorRunners: model.FinancialProcessorRunners,
            JournalsState: model.JournalsState,
            AccountsState: model.AccountsState
        }));

        this._ledgerComponent(new Ledger({
            Ledger: model.Ledger,
            JournalsState: model.JournalsState,
            AccountsState: model.AccountsState
        }));

        this._financialAPIComponent(new FinancialAPI({
            RenderMode: this._params.RenderMode,
            FinancialButtons: this._params.FinancialButtons
        }));

        this.BindEvents();
    }

    private ShowBadRequestError(error: P.Rejection) {
        const errorMessage = JSON.parse(error.message).Message;
        new Notifier().Failed(errorMessage);
    }

    private BindEvents() {
        this._readyForInvoicingComponent().On('INVOICING_REQUESTED', this, eventArgs => this.RequestReadyForInvoicingData(eventArgs.data));
        this._readyForProcessingComponent().On('SEND_TO_FP_REQUESTED', this, eventArgs => this.RequestReadyForProcessingData(eventArgs.data));
    }

    private RequestReadyForInvoicingData(readyForInvoicingEventData: IReadyForInvoicingEventData) {
        if (!this.IsViewMode) return;

        const otherGridPageLines = GlobalManager.Instance.GetGlobal(GLOBALS.OTHER_GRID_PAGE_LINES);
        const recordsPerPage = +otherGridPageLines || 10;

        const readyForInvoicingDataModal = new ReadyForInvoicingData({
            ControlId: readyForInvoicingEventData.ControlId,
            TableId: readyForInvoicingEventData.TableId,
            RecordsPerPage: recordsPerPage,
            Store: this._store
        });

        readyForInvoicingDataModal.On('INVOICING_DONE', this, eventArgs => this.OnInvoicesCreated(eventArgs.data));
        readyForInvoicingDataModal.On('INVOICING_FAILED', this, eventArgs => this.ShowBadRequestError(eventArgs.data));

        readyForInvoicingDataModal.Show();
    }

    private RequestReadyForProcessingData(sendToFPEventData: ISendToFPEventData) {
        if (!this.IsViewMode) return;

        const readyForProcessingQuery = this._params.ReadyForProcessingQueries.find(q => q.QueryResultId === sendToFPEventData.QueryResultId);
        if (!readyForProcessingQuery || !readyForProcessingQuery.QueryExpression) {
            new Notifier().Warning(NOTIFICATIONS.QUERY_EXPRESSION_WAS_NOT_CREATED);
            return;
        }

        const otherGridPageLines = GlobalManager.Instance.GetGlobal(GLOBALS.OTHER_GRID_PAGE_LINES);
        const recordsPerPage = +otherGridPageLines || 10;

        const readyForProcessingDataModal = new ReadyForProcessingData({
            QueryResultId: sendToFPEventData.QueryResultId,
            QueryExpression: readyForProcessingQuery.QueryExpression,
            RecordsPerPage: recordsPerPage,
            Store: this._store
        });

        readyForProcessingDataModal.On('FINANCIAL_PROCESSING_STARTED', this, () => this.OnFinancialProcessingStarted());
        readyForProcessingDataModal.On('FINANCIAL_PROCESSING_FAILED', this, eventArgs => this.ShowBadRequestError(eventArgs.data));

        readyForProcessingDataModal.Show();
    }

    private Refresh() {
        this._loadInProgres(true);

        this._store.GetData(this._params.Control.GetControlId())
            .then(model => this.InitComponents(model))
            .fail(error => this.ShowBadRequestError(error))
            .always(() => {
                this._loadInProgres(false);
            });
    }

    private ShowInvoicingResults(model: InvoicingResultsDtoModel) {
        new InvoicingResults(model).Show();
    }

    private OnInvoicesCreated(model: InvoicingResultsDtoModel) {
        this.Refresh();
        this.ShowInvoicingResults(model);
    }

    private OnFinancialProcessingStarted() {
        new Notifier().Success(NOTIFICATIONS.DOCUMENTS_HAS_BEEN_SENT_TO_FINANCIAL_PROCESSOR);
        this.Refresh();
    }
}