export const EVENTS = {
	SPREADSHEET_SELECTED: 'SPREADSHEET_SELECTED',
	ENTITY_SELECTED: 'ENTITY_SELECTED',
	API_PACKAGE_SELECTED: 'API_PACKAGE_SELECTED',
	TRIGGER_SELECTED: 'TRIGGER_SELECTED',
	QUERY_SELECTED: 'QUERY_SELECTED',
	DW_PACKAGE_SELECTED: 'DW_PACKAGE_SELECTED',
	EXACT_ONLINE_ENTITY_SELECTED: 'EXACT_ONLINE_ENTITY_SELECTED',
	EWS_ENTITY_SELECTED: 'EWS_ENTITY_SELECTED',
	OCI_PUNCH_OUT_SELECTED: 'OCI_PUNCH_OUT_SELECTED',
	MS_ACCESS_ENTITY_SELECTED: 'MS_ACCESS_ENTITY_SELECTED',
	DESKTOP_FOLDER_SELECTED: 'DESKTOP_FOLDER_SELECTED'
}