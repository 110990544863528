import * as ko from "knockout"
import * as $ from 'jquery';
import 'jqery.nicescroll';
import {MobileChecker} from "Core/Common/MobileChecker";

export class NiceScrollExtention {
	static Init() {
		//JQuery nicescroll
		//default options: scrollForMobile: false, horizontalScrollWithShift: false
        //don't use it with dragula

		ko.bindingHandlers.niceScroll = {
			init: (element, valueAccessor) => {
				let options = valueAccessor();
				let isMobile = MobileChecker.IsMobile();
				if (!isMobile || options.scrollForMobile) {
					if (options.horizontalScrollWithShift) {
						element.addEventListener('wheel', (event: WheelEvent) => {
							if (!event.shiftKey) {
								return;
							}

							event.stopImmediatePropagation();
							element.scrollLeft += event.deltaY;
						});
					}

					$(element).niceScroll(options);

					if (options.horizontalCursorClass) {
						$(element).getNiceScroll(0).cursorh.addClass(options.horizontalCursorClass);
					}
				}
			}
		};
	}
}