import * as ko from "knockout"
import 'jquery';
import 'bootstrapSwitch';

export class BootstrapSwitchExtention {
	static Init() {
		ko.bindingHandlers.bootstrapSwitch = {
			init: (element, valueAccessor) => {
				let options = valueAccessor();
				options['onSwitchChange'] = (event, state) => {
					if (options.value) {
						options.value(state);
					}
				};
				$(element).bootstrapSwitch(options);
			},
			update(element, valueAccessor) {
				let options = valueAccessor();
				if (options.value) {
					$(element).bootstrapSwitch('state', options.value(), true);
				}
			}
		};
	}
}