import * as ko from 'knockout'
import {deserializeAs} from 'libs/cerialize';
import {deserialize} from 'libs/cerialize';
import {TranslationModel} from 'Core/Controls/BaseControl/Models/TranslationModel';
import {IconModel} from 'Core/Controls/BaseControl/Models/IconModel';

export class AttachedFieldModel {

	@deserialize
	FieldTypeName: string;

	@deserialize
	Size: number;

	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	FieldNameTranslation: string;

	@deserialize
	Memo: string;

	@deserialize
	MemoTranslation: string;

	@deserialize
	AllowCustomValue: boolean;

	@deserialize
	AllowCreatingRecords: boolean;

	@deserialize
	EntityName: string;

	@deserialize
	EntityNameTranslation: string;

	@deserialize
	EntityTypeName: string;

	@deserialize
	EntityTypeId: number;

	@deserialize
	PrimaryKeyName: string;

	@deserialize
    TableTypeId: number;

	@deserialize
	ValFieldId: number;

	@deserialize
	ValFieldTypeName: string;

	@deserialize
	ValFieldFormatName: string;

	@deserialize
	ValFieldSize: number;

	@deserialize
	ValTableId: number;

	@deserialize
	ValTableName: string;

	@deserialize
	FilledById: number;

	@deserialize
	DependsOnId: number;

	@deserialize
	ValTableType: string;

	//TODO remove
	@deserialize
	AllowInsert: boolean;

	@deserializeAs(IconModel)
	EntityIcon: IconModel;

	@deserializeAs(IconModel)
	FieldIcon: IconModel;

	@deserialize
	FormatName: string;

	@deserialize
	EntityId: number;

	@deserialize
	Sort: number;

	@deserialize
	IsReadOnly: boolean;

	@deserialize
	IsRequired: boolean;

	@deserialize
	IsVirtual: boolean;

	@deserialize
	IsSystem: boolean;

	@deserialize
	IsInteger: boolean;

	@deserialize
	IsDecimal: boolean;

	@deserialize
	IsText: boolean;

	@deserialize
	IsDate: boolean;

	@deserialize
	IsDatetime: boolean;

	@deserialize
	RelatedField: boolean;

	@deserialize
	PropertyFieldId: number;

	@deserialize
	DynamicFields: Array<number>;

	@deserialize
	SubTableField: boolean;

	@deserialize
	FilterByField: number;

	@deserialize
	LinkRequired: boolean;

	@deserialize
	HasFilter: boolean;

	LinkRequiredObservable: KnockoutObservable<boolean>;

	HasDefaultValue: boolean;
	HasLinkEditorVisibility: boolean;
	FullWidth: boolean;
	FontStyles: Array<{Id: number, Name: string}>;
	FontColor: string;

	get FullName(){
		return this.FieldNameTranslation || this.Name;
	}

	constructor(init?: Partial<AttachedFieldModel>) {		
		this.FormatName = '';
		this.Sort = 0;
		this.DynamicFields = [];
		Object.assign(this, init);  
		this.LinkRequiredObservable = ko.observable(false);
	}

	public static OnDeserialized(instance: AttachedFieldModel, json: any) : void {
		instance.LinkRequiredObservable = ko.observable(!!instance.LinkRequired);
	}
}