export const PROPERTIES = {
    QUERY_CONDITION: 'QueryCondition',
    SHOW_CONDITION_TOGGLE: 'ShowConditionToggle',
    AUTO_SELECT: 'AutoselectIfOneRecord',
    BACKGROUND_COLOR: 'BackgroundColor',
    COLOR: 'Color'
}

export const PROPERTY_GROUPS = {
    LABEL: 'Label',
    TEXT_INPUT: 'TextInput'
}

export const EVENTS = {
    DROPDOWN_VALUE_SELECTED: 'DROPDOWN_VALUE_SELECTED',
    REFRESH: 'REFRESH',
}