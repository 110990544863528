import * as ko from 'knockout';
import * as _ from 'underscore';

import {USER_ALLOWANCE} from 'Core/Constants/UserAllowance';
import {SECURITY_LEVELS} from 'Core/Constants/SecurityLevels';
import {SYSTEM_TABLE_NAMES} from 'Core/Constant'

import {LABELS} from "Core/Components/Translation/Locales";

import {
    LinkListRelationsModel,
    IRelationRecord,
    EntityRelations
} from 'Core/Controls/LinkList/Models/LinklistRelationsModel';
import {AutolinkModel} from "Core/Controls/LinkList/Models/AutoLinkModel";
import {AutoLinkedRecordModel} from "Core/Controls/LinkList/Models/AutoLinkedRecordModel";
import {Guid} from "Core/Common/Guid";
import {UserManager} from "User/UserManager";

import UsersNormalModeTemplate from '../Templates/UsersNormalMode.html';
import UsersSingleModeTemplate from '../Templates/UsersSingleMode.html';
import enumerable from '../../../Common/Decorators/EnumerableDecorator';

export class RelationRecordViewModel {
    constructor(record?: IRelationRecord) {
        this.IsMain = ko.observable(false);
        if (record) {
            this.Id = record.Id;
            this.Level = record.Level;
            this.IsMain(record.IsMain);
            this.Name = record.Name;
            this.NameTranslation = record.NameTranslation;
            this.TypeName = record.TypeName;
            this.TypeTranslatedName = record.TypeTranslatedName;
            this.TypeId = record.TypeId;
            this.IsRecordOwner = record.IsRecordOwner;
            this.UserAllowance = record.UserAllowance;
            this.KSeq = record.KSeq;
        }

        this.Guid = Guid.NewGuid();
        this.IsNew = false;
    }

    Id: number;
    Level: number;
    IsMain: KnockoutObservable<boolean>;
    Name: string;
    NameTranslation: string;
    TypeName: string;
    TypeTranslatedName: string;
    TypeId: number;
    IsNew: boolean;
    IsRecordOwner: boolean;
    UserAllowance: number;
    KSeq: number;
    Guid: string;
    IsNewRecord: boolean;

    GetName(): string {
        return this.NameTranslation ? this.NameTranslation : this.Name;
    }

    GetTypeName(): string {
        return this.TypeTranslatedName ? this.TypeTranslatedName : this.TypeName ? this.TypeName : '-';
    }
    @enumerable get IsShowPlanner(): boolean {
        return UserManager.Instance.CurrentUser.Id === this.Id && this.IsRecordOwner && this.PlanningAllowed;
    }


    @enumerable get PlanningAllowed(): boolean {
        return USER_ALLOWANCE.HasValue(USER_ALLOWANCE.PLANNING, this.UserAllowance);
    }

    @enumerable get Shared(): boolean {
        return (SECURITY_LEVELS.SHARED & this.Level) === SECURITY_LEVELS.SHARED;
    }

    @enumerable get SharingReady() {
        return (SECURITY_LEVELS.SHARING_READY & this.Level) === SECURITY_LEVELS.SHARING_READY;
    }

    @enumerable
     get Planner(): boolean {
        return (SECURITY_LEVELS.PLANNER & this.Level) === SECURITY_LEVELS.PLANNER;
    }

    @enumerable get PlannerTooltip(): string {
        return this.Planner
            ? LABELS.OWNER_IS_PLANNER
            : LABELS.OWNER_IS_NOT_PLANNER;
    }
}

export class EntityRelationsViewModel {
    public _isValidEntity: KnockoutObservable<boolean>
    constructor(model: EntityRelations) {
        this.EntityId = model.EntityId;
        this.EntityName = model.EntityName;
        this.EntityTranslatedName = model.EntityTranslatedName == null ? model.EntityName : model.EntityTranslatedName;
        this.TableIcon = model.TableIcon;
        this.TableIconType = model.TableIconType;
        this.HasRecords = model.HasRecords;
        this.HasFields = model.HasFields;
        this.HasSequence = model.HasSequence;
        this.NoFieldsMessage = LABELS.TABLE_TEXT_NON_HAS_FIELDS;
        this._isValidEntity = ko.observable(true);

        const records = model.Records.map(record => new RelationRecordViewModel(record))
            .sort((left, right) => {
                return right.IsRecordOwner ? 1 : -1
            });

        this.Records = ko.observableArray(records);
        this.AutoLinks = model.AutoLinks;
        this.AutoLinkedRecords = ko.observableArray(model.AutoLinkedRecords);

    }

    EntityId: number;

    EntityName: string;

    EntityTranslatedName: string;

    TableIcon: string;

    TableIconType: string;

    TableImage: string;

    HasRecords: boolean;

    HasFields: boolean;

    NoFieldsMessage: string;

    HasSequence: boolean;

    Records: KnockoutObservableArray<RelationRecordViewModel>;
    AutoLinks: Array<AutolinkModel>;
    AutoLinkedRecords: KnockoutObservableArray<AutoLinkedRecordModel>;
}

export class UserEntityRelationsViewModel extends EntityRelationsViewModel {
    RecordOwner: KnockoutComputed<RelationRecordViewModel>;
    SharedUsers: KnockoutComputed<RelationRecordViewModel[]>;

    SingleMode: boolean;

    constructor(model: EntityRelations, singleMode: boolean) {
        super(model);

        this.SingleMode = singleMode;

        this.RecordOwner = ko.computed(() => _.find(this.Records(), record => record.IsRecordOwner));
        this.SharedUsers = ko.computed(() => _.filter(this.Records(), user => user.Shared));
    }
}

interface ILinkListRelationsViewModelParams {
    Model: LinkListRelationsModel;
    RecordOwner: number;
    IsExample: boolean;
}

export class LinkListRelationsViewModel {
    constructor(params: ILinkListRelationsViewModelParams) {
        this.MainEntityId = params.Model.MainEntityId;
        this.ControlId = params.Model.ControlId;
        this.SingleMode = params.Model.SingleMode;

        const entities = params.Model.Entities.filter(e => e.EntityName !== 'SYS_USERS').map(entity => new EntityRelationsViewModel(entity));
        this.Entities = ko.observableArray(entities);

        const userRelation = _.find(params.Model.Entities, entity => entity.EntityName === "SYS_USERS");
        if (userRelation && userRelation.EntityName === SYSTEM_TABLE_NAMES.SYS_USERS ){
            if (!!userRelation.EntityTranslatedName){
                userRelation.EntityTranslatedName =
                    (userRelation.EntityTranslatedName.toUpperCase() === SYSTEM_TABLE_NAMES.SYS_USERS ? 'USERS' : userRelation.EntityTranslatedName);
            }
            if ( !userRelation.EntityTranslatedName || (userRelation.EntityTranslatedName.trim() === '') ) {
                userRelation.EntityTranslatedName = 'USERS';
            }
        }
        const userRelationViewModel = userRelation && new UserEntityRelationsViewModel(userRelation, params.Model.SingleMode);
        this.UserRelation = ko.observable(userRelationViewModel);
    }

    ControlId: number;

    MainEntityId: number;

    SingleMode: boolean;

    UserRelation: KnockoutObservable<UserEntityRelationsViewModel>;
    Entities: KnockoutObservableArray<EntityRelationsViewModel>;

    GetUsersNormalModeTemplate() {
        return UsersNormalModeTemplate;
    }

    GetUsersSingleModeTemplate() {
        return UsersSingleModeTemplate;
    }
}