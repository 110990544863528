import * as ko from 'knockout';
import * as _ from 'underscore';

import {FIELD_TYPES} from 'Core/Constant';

import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';
import {LABELS} from 'Core/Components/Translation/Locales';

import {TableStore} from 'Core/Common/Stores/TableStore';

import {IControl} from 'Core/Controls/IControl';

import {BaseProperty, IPropertyDescription} from '../BaseProperty';

import {Dimension} from './Models/Dimension';
import {DimensionField} from './Models/DimensionField';
import {DimensionalTablePropertyField, DimensionalTablePropertyValue} from './Models/DimensionalTablePropertyValue';

import DimensionalTablePropertyTemplate
    from "Core/GeneralProperties/Managers/DimensionalTableProperty/Templates/DimensionalTableProperty.html";

ko.templates["Core/GeneralProperties/Managers/DimensionalTableProperty/Templates/DimensionalTableProperty"] = DimensionalTablePropertyTemplate;

export class DimensionalTableProperty extends BaseProperty {
    private _control: IControl;
    private _el: HTMLElement;
    private _labels = LABELS;

    private _maxDimensions: number;
    private _tableFields: DimensionField[];

    private _valueFieldChangedSub: KnockoutSubscription;
    private _pageFieldChangeSub: KnockoutSubscription;

    ValueField: KnockoutObservable<DimensionField>;
    PageField: KnockoutObservable<DimensionField>;

    ValueFields: KnockoutObservableArray<DimensionField>;
    Dimensions: KnockoutObservableArray<Dimension>;
    PageFields: KnockoutObservableArray<DimensionField>;

    AnyDimensionSelected: KnockoutComputed<boolean>;

    constructor(property: IPropertyDescription, propertyValue: any, control: IControl) {
        super(property);

        this._control = control;
        this._maxDimensions = 4;
        this._tableFields = [];

        this.ValueField = ko.observable(null);
        this.PageField = ko.observable(null);

        this.ValueFields = ko.observableArray([]);
        this.Dimensions = ko.observableArray([]);
        this.PageFields = ko.observableArray([]);

        this.AnyDimensionSelected = ko.computed(() => this.Dimensions().length > 0, this);

        this.Value = ko.observable(propertyValue || this.GetDefaultValue());
    }

    IsValid(): boolean {
        return this.Value() && this.Value().ValueField && this.Value().DimensionFields.length > 0;
    }

    Reset() {
        this.Value(this.GetDefaultValue());
        this.OnInit();
    }

    GetTemplateName(): string {
        return 'Core/GeneralProperties/Managers/DimensionalTableProperty/Templates/DimensionalTableProperty';
    }

    AfterRender(el: Array<HTMLElement>) {
        this._el = el[0];
    }

    GetDefaultValue(): any {
        return null;
    }

    OnInit() {
        const fieldModel = this._control.GetFieldModel();
        if (fieldModel) {
            const tableId = fieldModel.EntityId;

            BlockUI.Block({Target: this._el});
            TableStore.GetStruct({TableId: tableId})
                .always(() => {
                    BlockUI.Unblock(this._el);
                })
                .then(tableStruct => {
                    this.InitDimensionFields(tableStruct);

                    if (this.Value()) {
                        this.FillViewModel();
                    } else {
                        this.Dimensions([]);
                    }

                    this.BindEvents();
                })
                .fail(error => {
                    new Notifier().Failed(error.message);
                });
        }
    }

    OnValueFieldChange() {
        //if deselected
        if (!this.ValueField()) {
            this.PageField(null);
            this.Dimensions([]);
            this.UpdateValue();
            this.UpdateAvailableFields();
            return;
        }

        //add dimension if possible
        if (this.Dimensions().length === 0) {
            this.AddDimension();
            this.UpdateValue();
            this.UpdateAvailableFields();
        }
    }

    OnPageFieldChange() {
        this.UpdateValue();
        this.UpdateAvailableFields();
    }

    OnDimensionalFieldSelect(dimension: Dimension) {
        const index = this.Dimensions().indexOf(dimension);

        //if deselected
        if (!dimension.SelectedField()) {
            this.Dimensions.splice(index + 1);
            this.UpdateValue();
            this.UpdateAvailableFields();
            return;
        }

        //add dimension if possible
        if (this.Dimensions().length < this._maxDimensions && index === this.Dimensions().length - 1) {
            this.AddDimension();
            this.UpdateValue();
            this.UpdateAvailableFields();
            return;
        }

        this.UpdateValue();
        this.UpdateAvailableFields();
    }

    private InitDimensionFields(tableStruct: any) {
        const allowedFieldTypes = [FIELD_TYPES.Integer, FIELD_TYPES.Decimal, FIELD_TYPES.Lookup, FIELD_TYPES.Text];

        const fields = _.chain(tableStruct.Fields)
            .filter(field => field.Sort >= 3000 && field.Sort <= 7999)
            .filter(field => field.IsPhysicalField)
            .filter(field => _.contains(allowedFieldTypes, field.Type))
            .map(field => new DimensionField(field.Id, field.Name))
            .value();

        this._tableFields = fields;

        this.ValueFields(fields);
        this.PageFields(fields);
    }

    private FillViewModel() {
        const valueField = this._tableFields.find(field => field.Id === this.Value().ValueField.Id);
        const pageField = this.Value().PageField && this._tableFields.find(field => field.Id === this.Value().PageField.Id);
        this.ValueField(valueField);
        this.PageField(pageField);

        this.Value().DimensionFields.forEach(field => this.AddDimension(field.Id));

        if (this.Value().DimensionFields.length < this._maxDimensions) {
            this.AddDimension();
        }

        this.UpdateAvailableFields();
    }

    private BindEvents() {
        if (this._valueFieldChangedSub) {
            this._valueFieldChangedSub.dispose();
            this._pageFieldChangeSub.dispose();
        }

        this._valueFieldChangedSub = this.ValueField.subscribe(() => this.OnValueFieldChange());
        this._pageFieldChangeSub = this.PageField.subscribe(() => this.OnPageFieldChange());
    }

    private AddDimension(selectedFieldId?: number) {
        const dimension = this.CreateDimension(selectedFieldId)
        this.Dimensions.push(dimension);
    }

    private CreateDimension(selectedFieldId?: number) {
        const dimension = new Dimension(this._tableFields, selectedFieldId);
        dimension.On('SelectedField', this, () => this.OnDimensionalFieldSelect(dimension));
        return dimension;
    }

    private UpdateValue() {
        if (!this.ValueField()) {
            this.Value(null);
            this.PageField(null);
            return;
        }

        const valueField = new DimensionalTablePropertyField(this.ValueField().Id, this.ValueField().Name);
        const pageField = this.PageField() ? new DimensionalTablePropertyField(this.PageField().Id, this.PageField().Name) : null;

        const dimensionFields: DimensionalTablePropertyField[] = this.Dimensions()
            .filter(dimension => dimension.SelectedField())
            .map(dimension => new DimensionalTablePropertyField(dimension.SelectedField().Id, dimension.SelectedField().Name));

        const value = new DimensionalTablePropertyValue(valueField, pageField, dimensionFields);
        this.Value(value);
    }

    private UpdateAvailableFields() {
        const dimensionFields = this.Dimensions().map(dimension => dimension.SelectedField());
        const valueField = this.ValueField();
        const pageField = this.PageField();
        const selectedFields = [valueField, pageField, ...dimensionFields].filter(field => !!field);

        const availableValueFields = _.without(this._tableFields, ...selectedFields.filter(field => field !== this.ValueField()))
        this.ValueFields(availableValueFields);

        this.Dimensions().forEach(dimension => {
            const otherSelectedFields = selectedFields.filter(field => field !== dimension.SelectedField());
            const availableFields = _.without(this._tableFields, ...otherSelectedFields);
            dimension.Fields(availableFields);
        });

        const availablePageFields = _.without(this._tableFields, ...selectedFields.filter(field => field !== this.PageField()))
        this.PageFields(availablePageFields);
    }
}