import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';

import OperationResult from "Core/Results/ResultModels/OperationResult";

import {ActionCheckListDto} from "../Models/Dto/ActionCheckListDto";
import {IActionsCheckListsSerializedData} from "./IActionsCheckListsSerializedData";

export class ActionCheckListStore {
    static GetActionsCheckLists(entityId: number, recordId: number): P.Promise<ActionCheckListDto[]> {
        const deferred = P.defer<ActionCheckListDto[]>();

        Request.Send({
            proxy: {
                url: 'api/FollowUpApi/GetRecordNextLifestatuses',
                type: 'POST',
                dataType: 'json',
            }, params: {
                entityId: entityId,
                recordId: recordId
            }
        }).then(result => {
            if (result.IsSuccessfull) {
                deferred.resolve(result.ResultObject.Child && result.ResultObject.Child.CheckLists);
            } else {
                deferred.reject({message: result.ErrorMessage});
            }
        });

        return deferred.promise();
    }

    static UpdateActionsCheckList(model: IActionsCheckListsSerializedData): P.Promise<OperationResult> {
        const deferred = P.defer<OperationResult>();

        Request.Send({
            proxy: {
                url: 'api/FollowUpApi/UpdateCheckLists',
                type: 'POST',
                dataType: 'json',
            }, params: model
        }).then(result => {
            if (result.IsSuccessfull) {
                deferred.resolve(null);
            } else {
                deferred.reject({message: result.ErrorMessage});
            }
        });

        return deferred.promise();
    }
}