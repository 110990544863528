export const PORT_ATTRIBUTES = {
	"groups":
	{
		"in":
		{
			"markup": "<circle class=\"port-body\" r=\"10\"/>",
			"attrs":
			{
				".port-body":
				{
					"fill": "#5e738b",
					"strokeWidth": 0,
					"stroke": "#000",
					"r": 10,
					"magnet": true
				},
				".port-label":
				{
					"fontSize": 11,
					"fill": "#5e738b",
					"fontWeight": 800
				}
			},
			"label":
			{
				"position": {
					"name": "left",
					"args": {
						"y": 0
					}
				}
			},
			"type": "in",
			"position": {
				"name": "left"
			}
		},
		"out":
		{
			"markup": "<circle class=\"port-body\" r=\"10\"/>",
			"attrs":
			{
				".port-body":
				{
					"fill": "#5e738b",
					"strokeWidth": 0,
					"stroke": "#000",
					"r": 10,
					"magnet": true
				},
				".port-label":
				{
					"fontSize": 11,
					"fill": "#5e738b",
					"fontWeight": 800
				}
			},
			"label":
			{
				"position":
				{
					"name": "right",
					"args": {
						"y": 0
					}
				}
			},
			"type": "out",
			"position": {
				"name": "right"
			}
		}
	},
	"items": [
		{ "id": "in1", "group": "in", "attrs": { ".port-label": { "text": "" } } },
		{ "id": "out", "group": "out", "attrs": { ".port-label": { "text": "" } } }
	]
};