import * as ko from 'knockout';
import * as _ from 'underscore';

import {IConfigurationPageParams} from '../ConfigurationPage';

import {BaseProduct} from './BaseProduct';
import {ProductPart} from './ProductPart';

import {BreadCrumbsItem} from './BreadCrumbsItem';
import {BaseProductGroup} from './BaseProductGroup';

import {ISelectedRecord, SearchScreen} from 'Core/Screens/SearchScreen/SearchScreen';

import {ConfigurationPageStore} from '../Stores/ConfigurationPageStore';
import {ConfigurationPageVariablesStore} from '../Stores/ConfigurationPageVariablesStore';

import {ConfigurationPageMappings} from '../Mappings/ConfigurationPageMappings';
import {LABELS} from "Core/Components/Translation/Locales";

import Template from '../Templates/ExtraGroup.html';
import {Notifier} from '../../../../../../Common/Notifier';

export class ExtraGroup extends BaseProductGroup {
    private _labels = LABELS;
    private _mappings: ConfigurationPageMappings;
    private _store: ConfigurationPageStore;

    protected container: HTMLElement;

    Products: KnockoutObservableArray<ProductPart>;
    BreadCrumbs: KnockoutObservableArray<BreadCrumbsItem>;

    constructor(protected parentProduct: BaseProduct,
                protected params: IConfigurationPageParams,
                protected variablesStore: ConfigurationPageVariablesStore,
                protected readOnly: boolean,
                protected activeLevel: string) {

        super(0, LABELS.EXTRA_PRODUCTS, LABELS.EXTRA_PRODUCTS, [], false, parentProduct);

        this._store = new ConfigurationPageStore(params.OrderEntityId, params.ProductsEntityId, params.ProductId)
        this._mappings = new ConfigurationPageMappings(params.OrderEntityId, params.ProductsEntityId);

        this.Products = ko.observableArray([]);
        this.BreadCrumbs = ko.observableArray([]);

        this.ExtendedView(true);
    }

    get IsRoot() {
        return true;
    }

    get IsExtra() {
        return true;
    }

    get IsUndefined() {
        return false;
    }

    Init(products: ProductPart[]) {
        this.Products(products);
    }

    AfterInit() {
    }

    GetTemplate() {
        return Template;
    }

    AfterRender(el: HTMLElement) {
        this.container = el[0];
    }

    Dispose() {
        this.Products().forEach(product => product.Dispose());
        super.Dispose();
    }

    ToggleBody() {
        if (!this.Expanded()) {
            this.Expand();
        } else {
            this.Collapse();
        }
    }

    Collapse(): void {
        this.Expanded(false);
    }

    Expand(): void {
        this.Expanded(true);
    }

    SearchExtraProducts() {
        const searchScreen = new SearchScreen({
            SearchTerm: '',
            ButtonAdd: false,
            MultiSelectMode: true,
            EntityId: this.params.ProductsEntityId
        });

        searchScreen.On('RECORDS_SELECTED', this, eventArgs => this.AddFoundProductRecords(eventArgs.data));

        searchScreen.Show({addClass: 'productConfigurator-extraProducts-search-modal search-modal-container showScrollModal'});
    }

    AddFoundProductRecords(selection: { Ids: number[] }) {
        let selectedProductsId = selection.Ids;

        if (selectedProductsId.length > 0) {
            this._store.GetExtraProductsInfo(selectedProductsId, this.activeLevel)
                .then(productsInfoDto => {
                    const productParts = this._mappings.MapToProductParts(productsInfoDto, this, (productInfoDto, productPart) => {
                        productPart.SetInitialPropertyValues();
                        productPart.SetInitialCustomFieldValues();
                        productPart.TileImageSize = this.params.ImageSizes.Tiles;
                        productPart.SelectedImageSize = this.params.ImageSizes.SelectedComponent;
                        productPart.AssignVariableStore(this.variablesStore);
                        productPart.AssignEventBus(this.EventBus);
                        productPart.AfterInit();

                        productPart.SetPropertyValues(productInfoDto.PropertyValues);
                        productPart.SetCustomFieldValues(productInfoDto.CustomFieldValues);
                        productPart.InitActionDefaultExpressions();
                        this.SetActionDependsOnValues(productPart);

                        productPart.Price(productInfoDto.Price);
                        productPart.Quantity(productInfoDto.Quantity);
                        productPart.GenerateKSeqGuid();
                    });

                    this.Products.push(...productParts);
                    productParts.forEach(productPart => productPart.Select());
                });
        }
    }

    private SetActionDependsOnValues(product: ProductPart) {
        const actionDependsOnDto = product.GetActionDependsOnDto();
        if (!actionDependsOnDto) {
            return;
        }

        this._store.GetActionDependsOnValues(actionDependsOnDto)
            .then(actionDependsOnValues => {
                product.InitActionDependsOnExpressions(actionDependsOnValues)
            })
            .fail(err => {
                new Notifier().Failed(err.message)
            });
    }

    private GetProductsById(ids: number[]) {
        return this.Products().filter(product => ids.indexOf(product.Id) > -1);
    }
}