import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';

export class ThemeBuilderStore {
    static SetUISettings(uiSettingsRequestModel: any) {
        const deferred = P.defer();

        Request.Send({
            proxy: {
                url: '/api/ThemeApi/SetUISettings',
                type: 'POST'
            },
            params: uiSettingsRequestModel
        })
            .then(() => deferred.resolve(null))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error saving theme changes')));

        return deferred.promise();
    }

    private static GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}