import {SignInModel} from 'Auth/Models/SignInModel';
import { PasswordExpiredModel } from "Auth/UI/PasswordExpiredForm/Model/PasswordExpiredModel";
import { serialize } from '../../libs/cerialize';

export class UpdateTotpSecretModel {
	DatabaseName: string;
	ReturnUrl: string;
	Login: string;
	Password: string;
	TotpSecret: string;

	__RequestVerificationToken: string;

	MapFrom(signInModel: SignInModel) {
		for (let property in signInModel) {
			if (signInModel.hasOwnProperty(property)) {
				this[property] = signInModel[property];
			}
		}

		return this;
	}

	static FillFromFormData(secret: string) {
		let model = new UpdateTotpSecretModel();

		model.TotpSecret = secret;
		return model;
	}
}