export const URL = {
	ADMIN: {
		LOGIN: '/api/AdminApi/Login',
		GET_FILE_DIRS: '/api/AdminApi/GetFileDirs',
		GET_QUARTZ_DASHBOARDS: '/api/AdminApi/GetQuartzDashboards',
		GET_QUARTZ_DASHBOARD: '/api/AdminApi/GetQuartzDashboard',
		GET_MIGRATION_PREVIEW: '/api/AdminApi/GetMigrationsPreview',
		APPLY_MIGRATION: '/api/AdminApi/ApplyMigration',
		APPLY_EMBEDDED_MIGRATION: '/api/AdminApi/ApplyEmbeddedMigration',
		UPDATE_VIEWS: '/api/AdminApi/UpdateViews',
		UPDATE_TRIGGERS: '/api/AdminApi/UpdateTriggers',
		HAS_CHANGES: '/api/AdminApi/HasChanges',
	},

	AUTH: {
		LOGIN: '/api/AuthApi/Login',
		LOGOUT: '/api/AuthApi/Logout',
		LOCK_SESSION: '/api/AuthApi/LockSession',
		UNLOCK_SESSION: '/api/AuthApi/UnLockSession',
		RESET_PASSWORD: '/api/AuthApi/ResetPassword',

		UPDATE_EXPIRED_PASSWORD: '/api/UserApi/UpdatePassword',
		UPDATE_TOTP_SECRET: '/api/UserApi/UpdateTotpSecret',
		UPDATE_PASSWORD: '/api/UserApi/ChangePassword',
		
		GET_CACHED_LICENSE: '/api/LicenseApi/GetCachedLicenseInfo',
		UPLOAD_LICENSE_LOGO: '/api/LicenseApi/UploadLicenseLogo'
	},

	GET_SCREEN_BY_ID: '/api/ScreenFormApi/GetScreenById',
	GET_SCREEN_BY_SCREEN_TYPE: '/api/ScreenFormApi/GetScreenByScreenType',
	GET_MULTISELECT_CONTROL_DATA: '/api/ScreenFormApi/GetMultiSelectData',
	GET_EDIT_SCREEN: '/api/ScreenFormApi/GetEditScreen',
	GET_TABLE_TYPES: '/api/ScreenFormApi/GetTableTypes',
	GET_TYPES: '/api/ScreenFormApi/GetTypes',
	GET_ALL_TYPES: '/api/ScreenFormApi/GetAllTypes',
	IS_TYPE_TRANSFORMATION_REQUIRED: '/api/ScreenFormApi/IsTypeTransformationRequired',
	DELETE_SCREEN: '/api/FormDesignerApi/GetDeleteScreen',

	UPDATE_SCREEN_NAME: '/api/FormDesignerApi/UpdateScreenName',

	ICON: {
		GET_IMAGE_ICON: '/api/ScreenFormApi/GetImageIcon',
	},

	LOOKUP_EDITOR: {
		GET_LOOKUP_SCREEN_METADATA: '/api/LookupEditorApi/GetMetadata',
		GET_ENTITY: '/api/LookupEditorApi/GetEntity',
		GET_LOOKUP_DATA: '/api/LookupEditorApi/GetDataForTable',
		DELETE_RECORDS: '/api/LookupEditorApi/DeleteRecordsFromLookup',
		SAVE_CHANGES: '/api/LookupEditorApi/SaveChanges',
		GET_LOOKUPS: '/api/LookupEditorApi/GetLookups',
		GET_ENTITIES: '/api/LookupEditorApi/GetEntities',
		GET_LOOKUP: '/api/LookupEditorApi/GetLookup'
	},
	QUERY_BUILDER: {
		GET_ENTITY: '/VisualQueryBuilder/GetEntity',
		RUN_QUERY: '/api/VisualQueryBuilderApi/RunQuery',
		CREATE_QUERY: '/api/VisualQueryBuilderApi/CreateNewQuery',
		UPDATE_QUERY: '/api/VisualQueryBuilderApi/UpdateQuery',
		DELETE_QUERY: '/api/VisualQueryBuilderApi/DeleteQuery',
		LOCK_QUERY: '/api/VisualQueryBuilderApi/LockQuery',
		UNLOCK_QUERY: '/api/VisualQueryBuilderApi/UnlockQuery',
		GET_QUERY: '/api/VisualQueryBuilderApi/GetQuery',
		EDIT_QUERY: '/api/VisualQueryBuilderApi/EditQuery',
		GET_TOOLBAR_QUERY: '/api/VisualQueryBuilderApi/GetToolbarQuery',
		NEW_QUERY: '/api/VisualQueryBuilderApi/NewQuery',
		GET_QUERY_SCREENS: '/api/VisualQueryBuilderApi/GetQueryScreens',
		GET_CARD_SCREENS: '/api/VisualQueryBuilderApi/GetCardScreens',

	},
	COMPONENTS: {
        RECORDLINKER: {
            IS_LINK_WITH_RECORD_EXIST: "/api/ScreenFormApi/IsLinkWithRecordExist",
            GET_EXISTING_LINKS_WITH_RECORDS: "/api/ScreenFormApi/GetExistingLinksWithRecords",
            LINK_WITH_RECORD: "/api/ScreenFormApi/LinkWithRecord",
            LINK_WITH_RECORDS: "/api/ScreenFormApi/LinkWithRecords",
            ADD_AND_LINK_RECORD: "/api/ScreenFormApi/AddAndLinkRecord",
			COPY_LAST_LINKED_RECORD: "/api/ScreenFormApi/CopyLastLinkedRecord",
		}
	},
	CONTROLS: {
		GET_HIDE_IF_CONDITION_VALUE: 'api/ControlsApi/GetHideIfConditionValue',
		GET_GRID_DATA: "/api/ScreenFormApi/GetGridData",
		NEW_RECORD: "/api/GridApi/NewRecord",
		GET_VIEW_GRID_LIST: "/api/VisualQueryBuilderApi/GetGridViewList",
		GET_INVOICING_GRID_VIEW_LIST: "/api/VisualQueryBuilderApi/GetInvoicingGridViewList",
		GET_LINK_EDITOR_VIEW_GRID_LIST: "/api/VisualQueryBuilderApi/GetLinkEditorGridViewList",
		UPDATE_GRID_ROW_DATA: "/api/ScreenFormApi/UpdateGridRow",
		UPDATE_GRID_ROWS_DATA: "/api/ScreenFormApi/UpdateGridRows",
		DELETE_GRID_ROW_DATA: "/api/ScreenFormApi/DeleteGridRow",
		UNLINK_GRID_ROW: "/api/ScreenFormApi/UnlinkGridRow",
		UNLINK_GRID_ROWS: "/api/ScreenFormApi/UnlinkGridRows",
		INSERT_GRID_ROW_DATA: "/api/ScreenFormApi/InsertGridRow",
		UPDATE_GRID_ROW_LIFESTATUS: "/api/ScreenFormApi/UpdateGridRowLifestatus",
		LINK_WITH_NEXT_RELATION: '/api/ScreenFormApi/LinkWithNextRelationType',
		GET_DEFAULT_TABLE_VIEW: "/api/GridApi/GetDefaultTableView",
		GET_DEFAULT_TABLE_VIEW_METADATA: "/api/GridApi/GetDefaultTableViewMetaData",
		EXPORT_DATA: '/api/ScreenFormApi/ExportGridData',
		GET_FAST_FILTER_DATA: '/api/GridApi/GetFilterItems',
		GET_FAST_FILTER_TIME_DATA: '/api/GridApi/GetTimeFilterItems',
		GET_FAST_FILTER_DATE_DATA: '/api/GridApi/GetDateFilterItems',
		GET_FAST_FILTER_DATETIME_DATA: '/api/GridApi/GetDateTimeFilterItems',
		UPDATE_SORT: '/api/GridApi/UpdateSort',
		FOLLOWUP: {
			GET_NEXT_LIFESTATUSES: "/api/FollowUpApi/GetRecordNextLifestatuses",
			CONFIRM_PASSWORD: "/api/FollowUpApi/ConfirmPassword",
			CHECK_RETIRED_CHILDREN: "/api/FollowUpApi/CheckRetiredChildren"
		},
		KANBANBOARD: {
			GET_TYPES: "/api/KanbanBoardApi/GetTypes",
			GET_STATUSES: "/api/KanbanBoardApi/GetStatuses",
			GET_VIEW_LIST: "/api/KanbanBoardApi/GetViewList",
			UPDATE_STATUS: "/api/KanbanBoardApi/UpdateStatus",
			UPDATE_ACTION_STATUS: "/api/KanbanBoardApi/UpdateActionStatus",
			UPDATE_PRIORITY: "/api/KanbanBoardApi/UpdatePriority",
			GET_DEFAULT_VIEW_METADATA: "/api/KanbanBoardApi/GetDefaultKanbanViewMetaData"
		},
		LINKLIST: {
			GET_AUTOLINK_RECORD: "/api/ControlsApi/GetAutolinkRecord",
			GET_NEW_OR_EXISTING_RECORD: '/api/ControlsApi/GetNewOrExistingLink',
			GET_LINKLIST_RELATIONS: '/api/ControlsApi/GetLinkListRelations'
		},
		IMAGE: {
			SAVE_UPLOADED_FILE: "/ScreenForm/SaveUploadedFile"
		},
		RSSFEED: {
			GET_RSS_FEED: '/api/ControlsApi/GetRssFeed'
		},
		DROPDOWN: {
			GET_DROPDOWN_DATA: '/api/ScreenFormApi/GetDropdownData'
		},
		TAG: {
			GET_TAGS: '/api/ControlsApi/GetTags',
			GET_ALL_TAGS: '/api/ControlsApi/GetAllTags'
		},
		BUTTON_TEMPLATE: {
			GET_TEMPLATES: '/api/ButtonTemplateApi/GetTemplates',
			MERGE_AND_SAVE: '/api/ButtonTemplateApi/MergeAndSave'
		},
		GANNT_CHART: {
			GET_GANTT_CHART: '/api/GanttChartApi/GetGanttChart',
			UPDATE: '/api/GanttChartApi/Update'
		},
		SPIM_COPY: {
			GET_CLASS: '/api/SpimCopyApi/GetClass',
			ASSIGN_CLASS: '/api/SpimCopyApi/AssignClass',
			UPDATE_VERSION: '/api/SpimCopyApi/UpdateVersion'
		}
	},
	USER: {
		GET_CURRENT_USER: '/api/UserApi/GetCurrentUser',
		GET_SECURITY_PROFILES: '/api/UserApi/GetSecurityProfiles',
		SELECT_MAIN_PROFILE: '/api/UserApi/SelectMainProfile',
		GET_SECURITY_PROFILE: '/api/UserApi/GetSecurityProfile',
		GET_SECURITY_DATA: '/api/UserApi/GetSecurityData'
	},
	PORTLETS: {
		GET_PORTLET_SPACES: '/api/PortletsApi/GetPortletSpaces',
		GET_PORTLET_SPACE: '/api/PortletsApi/GetPortletSpace',
		GET_PORTLET_SPACE_BY_ID: '/api/PortletsApi/PortletSpaceById',
		GET_AVAILABLE_PORTLETS: '/api/PortletsApi/GetAvailablePortlets',
		UPDATE_PORTLET_SPACE: '/api/PortletsApi/UpdatePortletSpace',
		CREATE_PORTLET_SPACE: '/api/PortletsApi/AddPortletSpace',
		COPY_PORTLET_SPACE: '/api/PortletsApi/CopyPortletSpace',
		UPDATE_PORTLET_SPACE_INFO: '/api/PortletsApi/UpdatePortletSpaceInfo',
		BIND_TO_CONTROL: '/api/PortletsApi/BindToControl',
		DELETE_PORTLET_SPACE: '/api/PortletsApi/DeletePortletSpace'
	},
	LINK_EDITOR: {
		GET_DATA: '/api/LinkEditorApi/GetLinkData',
		GET_RELATION_TYPES: '/api/ScreenFormApi/GetRelationTypesData'
	},
	CYBERCONFIG_MANAGER: {
		GET_DATABASES: '/api/CyberConfigApi/GetDatabases',
		READ_CONFIG: '/api/CyberConfigApi/ReadConfig',
		CREATE_CONFIG: '/api/CyberConfigApi/CreateConfig',
		UPDATE_CONFIG: '/api/CyberConfigApi/UpdateConfig',
		DELETE_CONFIG: '/api/CyberConfigApi/DeleteConfig',
		CREATE_ENVIRONMENT: '/api/CyberConfigApi/CreateEnvironment'
	},
	SECURITY_EDITOR: {
		GET_SECURITY_INFO_FOR_RECORD: '/api/RecordSecurityApi/GetSecurityInfoForRecord'
	},
	FIELD_COLLECTION_DESIGNER: {
		GET_DATA_ROLE: '/api/FieldCollectionDesignerApi/GetUserCollection',
		GET_ENTITIES: '/api/FieldCollectionDesignerApi/GetEntities',
		GET_FIELD_COLLECTION: '/api/FieldCollectionDesignerApi/GetFieldCollections',
		SAVE_FIELD_COLLECTION: '/api/FieldCollectionDesignerApi/SaveChanges',
		UPDATE_VIEWS: '/api/FieldCollectionDesignerApi/UpdateViews'
	},
	FORM_DESIGNER: {
		GET_ALTERNATIVE_ENTITIES: '/api/FormDesignerApi/GetAlternativeEntities'
	},
	DATABASE_DESIGNER: {
		GET_VIEW_TEMPLATE: '/api/DatabaseDesignerApi/GetViewTemplate',
		VALIDATE_VIEW: '/api/DatabaseDesignerApi/ValidateView',
		EXECUTE_QUERY: '/api/DatabaseDesignerApi/ExecuteQuery',
	},
	BULK_EMAIL: {
		GET_CONFIG: '/api/BulkEmailApi/GetConfig',
		GET_TEMPLATES: '/api/BulkEmailApi/GetTemplates',
		START: '/api/BulkEmailApi/Start',
		STOP: '/api/BulkEmailApi/Stop',
		CONFIGURE_BULK_EMAILS: '/api/BulkEmailApi/ConfigureBulkEmails',
		CREATE_NEW_GROUP: '/api/BulkEmailApi/CreateNewGroup'
    },

    CANVAS: {
        GET_DW_PACKAGES: 'api/CanvasApi/GetDataWizardPackages'
    },

	BI: {
		GET_SETTINGS: 'api/BIApi/GetSettings',
		GET_ITEMS: 'api/BIApi/GetItems',
		GET_TOKEN: 'api/BIApi/GetToken'
	}
};