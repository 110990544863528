import * as ko from "knockout";
import * as _ from "underscore";

import {BaseProperty, IPropertyDescription} from "Core/GeneralProperties/Managers/BaseProperty";
import {ConditionBuilder} from "QueryBuilder/QueryCondition/ConditionBuilder/ConditionBuilder";
import {IControl} from 'Core/Controls/IControl';
import {EntityMetadataStore} from 'QueryBuilder/Stores/EntityMetadataStore';
import {QueryConditionGroupModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryConditionGroup';
import {CONTROL_TYPES, TABLE_TYPES} from "Core/Constant";
import {GenericDeserialize, Serialize} from 'libs/cerialize';
import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel';
import {BlockUI} from 'Core/Common/BlockUi';

import Template
    from "Core/GeneralProperties/Managers/QueryConditionProperty/Templates/QueryConditionPropertyTemplate.html";
import {EntityTypes} from "Core/Controls/Grid/BaseGrid/Enums/EntityTypes";
import {ScreenTypes} from "Core/Common/Enums/ScreenTypes";
import { IControlAttachedField } from "Core/Screens/BaseScreen";
import { GENERAL_PROPERTIES } from "../Constants";
import { GLOBALS, GlobalManager } from "Core/GlobalManager/GlobalManager";
import { TableStore } from "Core/Common/Stores/TableStore";

ko.templates["Core/GeneralProperties/Managers/QueryConditionProperty/Templates/QueryConditionPropertyTemplate"] = Template;


interface IAllowedEntity{
    Id: number;
    Name: string;
}

export class QueryConditionProperty extends BaseProperty {
    private _conditionBuilder: KnockoutObservable<ConditionBuilder>;
    private _control: IControl;
    private _el: HTMLElement;
    private _allowedEntities: KnockoutObservableArray<IAllowedEntity>;
    private _allowedEntity: KnockoutObservable<IAllowedEntity>;

    constructor(property: IPropertyDescription, propertyValue: string, control: IControl) {
        super(property);
        this.Value = ko.observable(propertyValue || this.GetDefaultValue());
        this._control = control;
        this._allowedEntities = ko.observableArray([]);
        this._allowedEntity = ko.observable(null);
        this._conditionBuilder = ko.observable(null);
    }

    GetTemplateName(): string {
        return "Core/GeneralProperties/Managers/QueryConditionProperty/Templates/QueryConditionPropertyTemplate";
    }

    GetDefaultValue() {
        return '';
    }

    SetValue(propertyValue: string): void {
        this.Value(propertyValue);
    }

    Reset() {
        this.Value('');
        this.Init();
    }

    private Init() {

        if (this._control.GetType() === CONTROL_TYPES.TimeWriting) {
            this.InitAllowedEntityCondition();
            return;
        }

        if(this.Name === GENERAL_PROPERTIES.HIDE_IF_CONDITION){
            this.InitShowIfConditions();
            return;
        }

        if (this._control.GetType() === CONTROL_TYPES.Search) {
            this.InitSearchConditions();
            return;
        }

        if (this._control.GetType() === CONTROL_TYPES.Dropdown || this._control.GetType() === CONTROL_TYPES.MultiSelect) {
            this.InitDropdownConditions();
            return;
        }

        if (this._control.GetType() === CONTROL_TYPES.Grid) {
            this.InitGridConditions();
            return;
        }

        if (this._control.GetType() === CONTROL_TYPES.GenericButton
            || this._control.GetType() === CONTROL_TYPES.Basket
            || this._control.GetType() === CONTROL_TYPES.Invoicing
            || this._control.GetType() === CONTROL_TYPES.Timer) {
            this.InitSearchConditions();
            return;
        }

        if (this._control.GetType() === CONTROL_TYPES.Book) {
            this.InitBookConditions();
            return;
        }
    }

    private InitSearchConditions() {
        if (this._control) {
            let entityId = this._control.GetForm().GetScreen().GetEntityId();
            this.InitConditionBuilder(entityId, []);
        }
    }


    private InitGridConditions() {
        let screenFields = this.GetScreenFields();
        if (this._control) {
            let gridSubjectEntityId = null;
            if (this._control.GetForm().GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.QueryScreen]) {
                gridSubjectEntityId = this._control.GetForm().GetScreen().GetEntityId();
            } else {
                let fieldModel = _.find(this._control.GetModel().Fields, (field) => {
                    return field.EntityTypeName === TABLE_TYPES.Entity
                });

                if (!fieldModel) {
                    fieldModel = _.find(this._control.GetModel().Fields, (field) => {
                        return field.EntityTypeName === TABLE_TYPES.Sub
                    });
                }

                if (fieldModel) {
                    gridSubjectEntityId = fieldModel.EntityId;
                }
            }

            if (gridSubjectEntityId) {
                this.InitGridConditionBuilder(gridSubjectEntityId, screenFields);
            }
        }
    }

    private InitBookConditions() {
        const fieldModel = _.first(this._control.GetModel().Fields);

        if (fieldModel) {
            this.InitConditionBuilder(fieldModel.EntityId, []);
        }
    }

    private InitDropdownConditions() {
        var screenFields = this.GetScreenFields();
        const entityId = this._control.GetForm().GetScreen().GetEntityId();

        if (this._control) {
            var fieldModel = this._control.GetFieldModel();
            if (fieldModel && fieldModel.ValTableId) {
                screenFields = _.filter(screenFields, (item) => {
                    return item.Field.Id !== fieldModel.Id
                });
                this.InitConditionBuilder(fieldModel.ValTableId, screenFields);
            } else {
                this.InitConditionBuilder(entityId, []);
            }
        }
    }

    private async InitAllowedEntityCondition(){
        const global = await GlobalManager.Instance.GetGlobal(GLOBALS.ALLOWED_ENTITIES);
        const entities = global.split('|');
        for (const entity of entities) {
            let table = await TableStore.GetStruct({ TableName: entity }) as any;
            this._allowedEntities.push({ Id: table.Id, Name: table.Name });
        }

        if(!this.Value() && this._allowedEntities().length > 0){
            this.InitConditionBuilder(this._allowedEntities()[0].Id, []);
        }

        if(this.Value() && this._allowedEntities().length > 0){
            let jsonObj = JSON.parse(this.Value());
            let conditions = GenericDeserialize<QueryConditionGroupModel>(jsonObj, QueryConditionGroupModel);
            this._allowedEntity(_.find(this._allowedEntities(), (entity)=>entity.Id === conditions.EntityId));
            this.InitConditionBuilder(conditions.EntityId, []);
        }

        this._allowedEntity.subscribe((newValue)=>{            
            this.Value(null);
            this.InitConditionBuilder(newValue.Id, []);
        });
    }

    private InitShowIfConditions() {
        var screenFields = this.GetScreenFields();
        const entityId = this._control.GetForm().GetScreen().GetEntityId();
        if (this._control) {
            var fieldModel = this._control.GetFieldModel();
            if (fieldModel) {
                screenFields = _.filter(screenFields, (item) => {
                    return item.Field.Id !== fieldModel.Id
                });
                this.InitConditionBuilder(entityId, screenFields);
            }
        }
    }

    private GetScreenFields(): Array<IControlAttachedField> {
        var screenFields: Array<IControlAttachedField> = [];
        if (this._control.GetForm()) {
            var excludedControlTypes = [CONTROL_TYPES.Grid, CONTROL_TYPES.Document, CONTROL_TYPES.Image];
            screenFields = this._control.GetForm().GetScreen().GetAllAttachedFields(excludedControlTypes, false);
        }
        return screenFields;
    }

    private InitConditionBuilder(entityId: number, screenFields: Array<IControlAttachedField>) {
        this._conditionBuilder(new ConditionBuilder(null, screenFields, false));
        var conditions = new QueryConditionGroupModel();

        if (this.Value()) {
            let jsonObj = JSON.parse(this.Value());
            conditions = GenericDeserialize<QueryConditionGroupModel>(jsonObj, QueryConditionGroupModel);
        }

        const blockTarget = this._el.parentNode as HTMLElement;
        BlockUI.Block({ Target: blockTarget });
        EntityMetadataStore.GetEntityMetadata({EntityId: entityId})
            .always(() => {
                BlockUI.Unblock(blockTarget);
            })
            .then((entityMetadata) => {
                this._conditionBuilder().InitByEntity(entityMetadata.EntityMetadata, conditions, screenFields);
            });

        this._conditionBuilder().On('CHANGED', this, () => {
            this.Value(JSON.stringify(Serialize(this._conditionBuilder().ConditionModel)));
        });
    }

    private InitGridConditionBuilder(entityId: number, screenFields: Array<IControlAttachedField>) {
        this._conditionBuilder(new ConditionBuilder(null, [], false));
        var conditions = new QueryConditionGroupModel();

        if (this.Value()) {
            var jsonObj = JSON.parse(this.Value());
            conditions = GenericDeserialize<QueryConditionGroupModel>(jsonObj, QueryConditionGroupModel);
        }

        EntityMetadataStore.GetEntityMetadata({EntityId: entityId})
            .always(() => {
            })
            .then((entityMetadata) => {
                let entities = [];
                entities.push(entityMetadata.EntityMetadata);
                if (entityMetadata.EntityMetadata.Type === EntityTypes[EntityTypes.Entity]) {
                    if (this._control.GetForm().GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.EditScreen] || this._control.GetForm().GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.ConsultScreen]) {
                        let subjectEntityId = this._control.GetForm().GetScreen().GetEntityId();
                        let screenSubjectEntity = _.find(entityMetadata.RelatedEntitiesMetadata, (item) => {
                            return item.EntityMetadata.Id === subjectEntityId
                        });
                        entities.push(screenSubjectEntity.LinkEntityMetadata);
                    }
                }
                this._conditionBuilder().InitByEntities(entities, conditions, screenFields);
            });

        this._conditionBuilder().On('CHANGED', this, () => {
            this.Value(JSON.stringify(Serialize(this._conditionBuilder().ConditionModel)));
        });
    }

    AfterRender(el: Array<HTMLElement>) {
        this._el = el[0];
        this.Init();
    }
}