import * as ko from "knockout";
import * as _ from 'underscore';

import { BaseControl, IControlValue } from "Core/Controls/BaseControl/BaseControl";
import { IControlParam } from "Core/Screens/IScreen";
import { Notifier } from 'Core/Common/Notifier';
import { UserVarsManager } from 'Core/UserVarsManager/UserVarsManager';
import { NOTIFICATIONS} from "Core/Components/Translation/Locales";
import { RenderModes } from "Core/Constant";
import { GeneralProperties } from "Core/GeneralProperties/GeneralProperties";

import FavoriteButtonConfig from "Core/Controls/ButtonFavorite/Configs/favorite-button-config.json";
import ViewTemplate from "Core/Controls/ButtonFavorite/Templates/View.html";
import HelpViewTemplate from "Core/Controls/ButtonFavorite/Templates/HelpView.html";
import ToolBarTemplate from "Core/Controls/ButtonFavorite/Templates/ToolBar.html";
import DesignTemplate from "Core/Controls/ButtonFavorite/Templates/Design.html";

ko.templates["Core/Controls/ButtonFavorite/Templates/Design"] = DesignTemplate;
ko.templates["Core/Controls/ButtonFavorite/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/ButtonFavorite/Templates/HelpView"] = HelpViewTemplate;
ko.templates["Core/Controls/ButtonFavorite/Templates/ToolBar"] = ToolBarTemplate;
export class ButtonFavorite extends BaseControl {
	private _isFavorite: KnockoutObservable<boolean>;
	private _isEnabled: KnockoutObservable<boolean>;
    private _config: any;

	constructor(params: IControlParam) {
		super(params, FavoriteButtonConfig);
        this._style = ko.computed(() => {
            return {
                backgroundColor: this._backgroundColor(),
                color: this._color(),
                borderColor: this._borderColor(),
                borderWidth: this._border() ? '1px' : '0'
            };
        });
		this.Init();
	}

	private Init(): void {
		this._isFavorite = ko.observable(false);
		this._isEnabled = ko.observable(false);
        this.ApplyProperties();
	}

	Click(): void {
		if (this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
		} else {

			var screen = this._form.GetScreen();
			var self = this;
			if (this._isEnabled()) {
				if (self._isFavorite()) {
					UserVarsManager.Instance.RemoveFromFavorites(
						screen.GetRecordId(),
						screen.GetEntityId(),
						screen.GetTableTypeId()
					);

					self._isFavorite(false);
				} else {
					UserVarsManager.Instance.AddToFavorites(
						screen.GetRecordId(),
						screen.GetEntityId(),
						screen.GetTableTypeId()
					);

					self._isFavorite(true);
				}
			} else {
				Notifier.Warning(NOTIFICATIONS.PLEASE_SELECT_ANY_RECORD);
			}
		}
	}

	SetValue(value: IControlValue): void {
		var screen = this._form.GetScreen();
		let isRecordOnScreen = screen.GetRecordId() !== 0;
		this._isEnabled(isRecordOnScreen);

		var isInFavorites = UserVarsManager.Instance.IsInFavorites(
			screen.GetRecordId(),
			screen.GetEntityId(),
			screen.GetTableTypeId()
		);
		this._isFavorite(isInFavorites);
	}

    ApplyProperties() {
        if (this.Properties) {
            this.AssignProperty('Styling', 'BackgroundColor', this._backgroundColor);
            this.AssignProperty('Styling', 'TextColor', this._color);
            this.AssignProperty('Styling', 'Border', this._border);
            this.AssignProperty('Styling', 'BorderColor', this._borderColor);
        }
    }

    private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
        if (this.Properties[groupedBy]) {
            _.each(this.Properties[groupedBy].Properties,
                (property: any) => {
                    if (property.hasOwnProperty(propertyName)) {
                        propertyHolder(property[propertyName]);
                    }
                });
        }
    }

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}
}