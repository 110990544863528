import * as $ from 'jquery';
import * as ko from "knockout"

import 'cropper';

export class CropperExtention {
    // README v2.2.5
    // https://github.com/fengyuanchen/cropper/blob/v2.2.5/README.md

    static Init() {
        ko.bindingHandlers.cropper = {
            init: (element, valueAccessor, allBindings, viewModel) => {
                let self = this;
                let options = valueAccessor();

                options.built = () => {
                    if (options.afterCrop) {
                        options.afterCrop($(element).cropper('getCroppedCanvas').toDataURL(options.type));
                    }
                };


                $(element).on('cropend.cropper', function (e: JQuery.Event) {
                    self.CroppedCanvas(options, this);
                });

                $(element).on('zoom.cropper', function (e: JQuery.Event) {
                    self.CroppedCanvas(options, this);
                });

                $(element).cropper(options);
            }
        }
    }

    private static CroppedCanvas(options, $this){
        if (options.afterCrop) {
            if (options.type === 'image/jpeg' ||
                options.type === 'image/jpg' ||
                options.type === 'image/jpe' ) {
                options.afterCrop($($this).cropper('getCroppedCanvas', {fillColor: '#fff'}).toDataURL(options.type));
            } else {
                options.afterCrop($($this).cropper('getCroppedCanvas').toDataURL(options.type));
            }
        }
    }
}