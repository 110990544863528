import { deserialize } from 'libs/cerialize';
import { deserializeAs } from 'libs/cerialize';

export class LifestatusModel {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	ApprovalName: string;

	@deserialize
	Memo: string;

	@deserialize
	MemoTranslation: string;

	@deserialize
	TranslatedName: string;
}

export class LifecycleModel {
	@deserializeAs(LifestatusModel)
	CurrentLifeStatus: LifestatusModel;

	@deserializeAs(LifestatusModel)
	LifeStatuses: Array<LifestatusModel>;
}

export class BasketRecordModel {
	constructor() {
		this.Records = [];
	}

	@deserialize
	IsDeletingAllowed: boolean;

	@deserialize
	IsEditingAllowed: boolean;

	@deserialize
	Name: string;

	@deserialize
	Id: number;

	@deserialize
	TypeId: number;

	@deserialize
	Records: Array<number>;

	@deserializeAs(LifecycleModel)
	LifeStatuses: LifecycleModel;
}

export class BasketDataModel {
	constructor() {
		this.Records = [];
	}

	@deserialize
	IsAddingRecordAllowed: boolean;

	@deserializeAs(BasketRecordModel)
	Records: Array<BasketRecordModel>;
}