import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Notifier} from 'Core/Common/Notifier';
import {BlockUI} from 'Core/Common/BlockUi';
import {NOTIFICATIONS} from 'Core/Components/Translation/Locales';
import { GenericDeserialize } from 'libs/cerialize';
import { LifecycleDto } from './Models/DTO/LifecycleDto';

export class LifecycleDesignerStore {
    
    static GetLifeCycleHelpData(typeId) {
        const deferredResult = P.defer<any>();

        const dataRequest = {'typeId': typeId};

        BlockUI.Block();

        Request.Send({
            proxy: {
                url: '/api/HelpPagesApi/GetLifecycle',
                type: 'Get',
                dataType: 'json',
            },
            params: dataRequest
        })
            .then(function (data) {
                if (data.IsSuccessfull) {
                    deferredResult.resolve(data.ResultObject || []);
                } else {
                    deferredResult.reject({message: data.ErrorMessage});
                }
            })
            .fail(function (error) {
                deferredResult.reject(error);
            })
            .always(function () {
                BlockUI.Unblock();
            });

        return deferredResult.promise();
    }
}