import {IconModel} from "Core/Controls/BaseControl/Models/IconModel";
import {Icon} from "Core/Icon/Icon";

export class AccountsViewModel {
    constructor(
        public TableId: number,
        public Icon: IconModel) {
    }

    GetIconTemplate() {
        let classes: string = this.Icon && this.Icon.IsImage ? 'height-20x20' : '';
        return new Icon(this.Icon).GetIconTemplate(classes);
    }
}