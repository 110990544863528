import * as _ from 'underscore';

import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize} from 'libs/cerialize';
import { OperationResultModel } from 'Core/Models/OperationResultModel';
import { ITranslationValue } from "Core/Components/TranslationFieldEditor/ITranslationValue";

export interface ISessionEvent{
    events: string;
	sessionId: string;
	timestamp: number;
}

export interface IGetEventsDto{
	sessionId: string;
}

export class SessionStore {
	static Update(params: ISessionEvent): P.Promise<any> {

		var deferredResult = P.defer<any>();
		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/EventApi/Event',
				type: 'POST'
			}, params: params
		};
		Request.Send(requestParams)
			.then(data => deferredResult.resolve(null));

		return deferredResult.promise();
	}

	static GetEvents(params: IGetEventsDto): P.Promise<any> {

		var deferredResult = P.defer<any>();
		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/EventApi/GetEvents',
				dataType: 'json',
				type: 'GET'
			}, params: params
		};
		Request.Send(requestParams)
			.then(data => deferredResult.resolve(data));

		return deferredResult.promise();
	}

	static GetSessions(pageNumber: number): P.Promise<any> {

		var deferredResult = P.defer<any>();
		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/EventApi/GetSessions',
				dataType: 'json',
				type: 'GET'
			},
			params: { PageNumber: pageNumber }
		};
		Request.Send(requestParams)
			.then(data => deferredResult.resolve(data));

		return deferredResult.promise();
	}
}  