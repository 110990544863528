import { deserialize } from 'libs/cerialize';
import { deserializeAs } from 'libs/cerialize';

export class AttachmentModel {
	@deserialize
	Name: string;

	@deserialize
	Content: string;
}

export class MailRelatedUserData {
	@deserialize
	Id: number;

	@deserialize
	Name: string;
}

export class UnknownMail{
	@deserialize
	Mail: string;

	@deserialize
	AddressTypeId: number;

	@deserialize
	AddressTypeName: string;
}

export class MailRelatedItem {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	TypeId: number;

	@deserialize
	TypeName: string;

	@deserialize
	AddressTypeId: number;

	@deserialize
	AddressTypeName: string;
}

export class MailRelatedTableData {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	AddressTypeFieldId: number;

	@deserializeAs(MailRelatedItem)
	Items: MailRelatedItem[];

	constructor() {
		this.Items = [];
	}
}

export class MailRelatedData {
	@deserializeAs(MailRelatedUserData)
	Users: MailRelatedUserData[];

	@deserializeAs(MailRelatedTableData)
	Entities: MailRelatedTableData[];

	@deserializeAs(UnknownMail)
	UnknownMails: Array<UnknownMail>;

	constructor() {
		this.Users = [];
		this.Entities = [];
		this.UnknownMails = []
	}
}

export class MailItemExtendedModel {
	constructor() {
		this.Attachments = [];
	}

	@deserializeAs(AttachmentModel)
	Attachments: Array<AttachmentModel>;

	@deserialize
	AttachmentsCount: number;

	@deserialize
	Body: string;

	@deserialize
	BodyText: string;

	@deserialize
	Cc: string[];

	@deserialize
	DateTimeCreated: string;

	@deserialize
	DestinationMails: any[];

	@deserialize
	FromAddress: string;

	@deserialize
	FromName: string;

	@deserialize
	Guid: string;

	@deserialize
	HasAttachments: boolean;

	@deserialize
	IsInCyberbox: boolean;

	@deserialize
	IsRead: boolean;

	@deserialize
	MailType: number;

	@deserialize
	Subject: string;

	@deserialize
	TypeName: string;

	@deserialize
	Pop3Index: number;

	@deserializeAs(MailRelatedData)
	MailRelatedData: MailRelatedData;
}