import {deserializeAs} from 'libs/cerialize';
import {deserialize} from 'libs/cerialize';
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel'

export class ControlGroupModel {

	constructor() {
		this.Controls = [];
	}

	@deserializeAs(ControlModel)
	Controls: Array<ControlModel>;

	@deserialize
	Name: string;
}