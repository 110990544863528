import * as ko from "knockout";
import * as _ from "underscore";
import { PersonalSettingsModel } from 'Core/ProfilePage/PersonalSettings/Models/PersonalSettingsModel';


import Template from 'Core/ProfilePage/PersonalSettings/PersonalSettingsEditors/YesNo/Templates/YesNo.html'
ko.templates['Core/ProfilePage/PersonalSettings/PersonalSettingsEditors/YesNo/Templates/YesNo.html'] = Template;

export class YesNo {
	Value: KnockoutObservable<boolean>;
	HasChanges: KnockoutObservable<boolean>;
	Disabled: boolean;
	Name: string;
	Id: number;
	Description: string;


	constructor(model: PersonalSettingsModel, changeHandler, PersonalSettingsInstance) {
		this.Value = ko.observable(Boolean(+model.Value));
		this.HasChanges = ko.observable(false);
        this.Name = model.TranslatedName ? model.TranslatedName : model.Name;
		this.Disabled = model.LockToDefault;
		this.Id = model.Id;
		this.Description = model.DescriptionTranslation ? model.DescriptionTranslation : model.Description;

		this.Value.subscribe((newValue) => {
			this.HasChanges(true);
			changeHandler.call(PersonalSettingsInstance);
		});
	}

	GetTemplateName() {
		return 'Core/ProfilePage/PersonalSettings/PersonalSettingsEditors/YesNo/Templates/YesNo.html';
	}

	AfterRender(el: Array<HTMLElement>) {
	}
} 