import {deserialize, deserializeAs} from 'libs/cerialize';
import {EntityMetadataModel} from 'Core/Controls/Grid/Models/GridDataModel/EntityMetadataModel';

export class RelatedEntityMetadataModel {
	@deserializeAs(EntityMetadataModel)
	EntityMetadata: EntityMetadataModel;

	@deserializeAs(EntityMetadataModel)
	LinkEntityMetadata: EntityMetadataModel;
}

export class RelatedSubEntityMetadataModel {
	@deserializeAs(EntityMetadataModel)
	EntityMetadata: EntityMetadataModel;
}

export class EntityRelationshipsMetadataModel {
	constructor() {
		this.RelatedEntitiesMetadata = [];
		this.RelatedSubEntitiesMetadata = [];
		this.ReferenceEntities = [];
		this.TablesRelatedToLookup = [];
	}

	@deserializeAs(EntityMetadataModel)
	EntityMetadata: EntityMetadataModel;

	@deserializeAs(RelatedEntityMetadataModel)
	RelatedEntitiesMetadata: Array<RelatedEntityMetadataModel>;

	@deserializeAs(RelatedSubEntityMetadataModel)
	RelatedSubEntitiesMetadata: Array<RelatedSubEntityMetadataModel>;

	@deserializeAs(EntityMetadataModel)
	ReferenceEntities: Array<EntityMetadataModel>;

	@deserializeAs(EntityMetadataModel)
	TablesRelatedToLookup: Array<EntityMetadataModel>;
}