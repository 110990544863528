import {Request, IRequestOptions} from "Core/Common/Request";
import {P} from "Core/Common/Promise";
import {URL} from "Core/Common/Url";
import {GenericDeserialize} from "libs/cerialize";
import {MultiSelectListModel} from "Core/Controls/MultiSelect/Models/MultiSelectListModel";
import { IDynamicFieldData } from "Core/Common/Stores/RecordStore";

export interface IGetMultiSelectControlDataRequestModel {
    ControlId: number;
    FieldId: number;
    RecordIds: Array<number>;
    ScreenVariables?: any;
    LeftEntityId?: number;
    RightEntityId?: number;
    LeftRecordId?: number;
    RightRecordId?: number;
    ScreenData?: Array<IDynamicFieldData>;
}

export class MultiSelectDataStore {
    static GetOptions(requestData: IGetMultiSelectControlDataRequestModel): P.Promise<any> {
        let deferredResult = P.defer();

        let requestParams: IRequestOptions = {
            proxy: {
                url: URL.GET_MULTISELECT_CONTROL_DATA,
                dataType: 'json',
                type: 'POST'
            },
            params: requestData
        };
        Request.Send(requestParams)
            .then(data => {
                if (data.IsSuccessfull) {
                    var test = GenericDeserialize<MultiSelectListModel>(data.ResultObject, MultiSelectListModel);

                    deferredResult.resolve(test);
                } else {
                    deferredResult.reject({message: data.ErrorMessage});
                }
            });

        return deferredResult.promise();
    }
}