import * as ko from 'knockout';
import { deserialize, deserializeAs } from 'libs/cerialize';
import { KanbanParentTypeModel } from 'Core/Controls/Kanban/Models/KanbanParentTypeModel';

export class EntityTypeModel {
	constructor() {
		this.HasFlowFolder = null;
		this.ParentTypeModels = [];
	}

	@deserialize
	K_Type: number;

	@deserialize
    Name: string;

    @deserialize
    TranslatedName: string;

	@deserialize
	HasFlowFolder: boolean;

	@deserialize
	ShowTypeHierarchy: boolean;

	@deserializeAs(KanbanParentTypeModel)
	ParentTypeModels: Array<KanbanParentTypeModel>;
}