import { deserialize } from 'libs/cerialize';

export class PreviewDocumentModel {
	@deserialize
	FileName: string;

	@deserialize
	FileType: string;

	@deserialize
	Content: string;

	@deserialize
	IsWebDocument: boolean;
}