import { deserializeAs } from "libs/cerialize";
import {FlowItemModel} from "./FlowItemModel";
import {FlowOperationModel} from "./FlowOperationModel";

export class FlowFolderModel {

	@deserializeAs(FlowItemModel)
	FlowItems: FlowItemModel[];

	@deserializeAs(FlowOperationModel)
	FlowOperations: FlowOperationModel[];

	constructor() {
		this.FlowItems = [];
		this.FlowOperations = [];
	}
}