import * as ko from "knockout";
import Template from 'Core/GeneralProperties/Managers/AlternativeEntitiesProperty/Templates/AlternativeEntitiesProperty.html';

import { BlockUI } from 'Core/Common/BlockUi';
import { Notifier } from 'Core/Common/Notifier';
import { IControl } from "Core/Controls/IControl";
import { BaseProperty, IPropertyDescription } from "Core/GeneralProperties/Managers/BaseProperty";
import { EntityModel } from "Core/GeneralProperties/Managers/AlternativeEntitiesProperty/Models/EntityModel";
import { AlternativeEntitiesPropertyStore }
	from "Core/GeneralProperties/Managers/AlternativeEntitiesProperty/Stores/AlternativeEntitiesPropertyStore";
import { LABELS } from "Core/Components/Translation/Locales";

ko.templates["Core/GeneralProperties/Managers/AlternativeEntitiesProperty/Templates/AlternativeEntitiesProperty"] = Template;

import AlternativeIconTemplate from 'Core/GeneralProperties/Managers/AlternativeEntitiesProperty/Templates/IconTemplate.html';
ko.templates['Core/GeneralProperties/Managers/AlternativeEntitiesProperty/Templates/IconTemplate'] = AlternativeIconTemplate;


export class AlternativeEntitiesProperty extends BaseProperty {
	private _control: IControl;
	private _showOptions: KnockoutObservable<boolean>;
	private _labels = LABELS;

	Entities: KnockoutObservableArray<EntityModel>;
	SelectedEntities: KnockoutObservableArray<EntityModel>;


	constructor(property: IPropertyDescription, propertyValue: string, control: IControl, getData: boolean = true) {
		super(property);

		this.Value = ko.observable(propertyValue || this.GetDefaultValue());

		//property is stored into value after that it will be saved automaticly
		this.Value([]);

		this._control = control;

		this._showOptions = ko.observable(true);

		this.Init(getData);

		this.Entities = ko.observableArray([]);
		this.SelectedEntities = ko.observableArray([]);
	}

	Init(getData: boolean = true) {
		if(getData) {
			this.GetAlternativeEntities();
		}
	}

	GetTemplateName(): string {
		return "Core/GeneralProperties/Managers/AlternativeEntitiesProperty/Templates/AlternativeEntitiesProperty";
	}

	GetDefaultValue() {
		return null;
	}

	SelectEntity(entity: EntityModel) {
		entity.SelectedStatus(true);
		this.SelectedEntities.push(entity);
		this.Value().push(entity.EntityId);
	}

	DeselectEntity(entity: EntityModel) {
		entity.SelectedStatus(false);
		const filteredSelecteEntities = _.filter(this.SelectedEntities(), (selectedEntity: EntityModel) => {
			return selectedEntity.EntityId !== entity.EntityId;
		});
		this.Value(_.filter(this.Value(),
			(entityId: number) => {
				return entityId !== entity.EntityId;
			})
		);
		this.SelectedEntities(filteredSelecteEntities);

	}

	GetIconTemplateName(): string {
		return "Core/GeneralProperties/Managers/AlternativeEntitiesProperty/Templates/IconTemplate";
	}

	GetAlternativeEntities() {
		BlockUI.Block();
		let entityId = this._control.GetForm().GetScreen().GetEntityId();
		AlternativeEntitiesPropertyStore.GetAlternativeEntities(entityId)
			.then(entities => {
				const alternativeEntities = (this._control as any)._alternativeEntities;
				alternativeEntities && alternativeEntities.map((entityId) => {
					this.Value().push(entityId);
				});
				this.Entities(entities.map((entity) => {

					const mappedEntity = _.extend(entity, {
						SelectedStatus: this.Value().indexOf(entity.EntityId) !== -1 ?
							ko.observable(true) :
							ko.observable(false)
					});

					this.Value().indexOf(entity.EntityId) !== -1 && this.SelectedEntities.splice(this.Value().indexOf(entity.EntityId), 1, mappedEntity);

					return mappedEntity;
				}));


			})
			.always(() => BlockUI.Unblock())
			.fail((err) => new Notifier().Failed(err.message));
	}
}