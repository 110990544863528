import * as _ from 'underscore';

import {UsersResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Response/UsersResponseModel";
import {UsersViewModel} from "Core/Components/Controls/TimeWriting/Models/View/UsersViewModel";
import {UserMappings} from "Core/Components/Controls/TimeWriting/Mappings/Day/UserMappings";
import { UserManager } from "User/UserManager";

export class UsersMappingProfile {
	static OnViewModel(model: UsersResponseModel) {
		const viewModel = new UsersViewModel();

		const users = model.Users.map(user => UserMappings.OnViewModel(user));
		const selectedUser = _.find(users, user => user.Id === UserManager.Instance.CurrentUser.Id);

		viewModel.Users(users);
		viewModel.SelectedUser(selectedUser);

		return viewModel;
	}
}