import * as ko from 'knockout';

import {GridColumnModel} from 'Core/Controls/Grid/Models/GridDataModel/GridColumnModel';
import {Event} from 'Core/Common/Event';
import {EVENTS} from 'Core/Controls/Grid/BaseGrid/Events';

export enum SortOrder {
	Asc,
	Desc,
	Both
}

export const DISPLAY_PRIORITY_TO_CSS = {
	1: 'priority-prehigh',
	2: 'priority-medium',
	3: 'priority-premedium',
	4: 'priority-low'
};

export class BaseColumn extends Event {
	protected _sortOrder: KnockoutObservable<number>;
	protected _sortOrderClassName: KnockoutComputed<string>;
	protected _isFastFilterEnabled: KnockoutObservable<boolean> = ko.observable(false);

	constructor(protected _model: GridColumnModel) {
		super();

		this._sortOrder = ko.observable(_model.SortDirection);
		this.AddEvent(EVENTS.SORT);

		this._sortOrderClassName = ko.computed({
			owner: this,
			read: () => `sort-${ SortOrder[this._sortOrder()] && SortOrder[this._sortOrder()].toLowerCase()}`
		});
	}

	get Title(): string {
		return `${this._model.Title || this._model.Alias}`;
	}

	get GroupTitle(): string {
		return `${this._model.GroupTitle}`;
	}

	get HasGroupTitle(){
		return this._model.GroupTitle != null && this._model.GroupTitle !== "null" && this._model.GroupTitle !== "undefined";
	}


	get Model(): GridColumnModel {
		return this._model;
	}

	Sort() {
	}

	get DisplayPriorityClass(): string {
		return DISPLAY_PRIORITY_TO_CSS[this._model.DisplayPriority];
	}
}