import {ChartDataModel} from "Core/Controls/Chart/Models/ChartDataModel";
import {ChartPointModel} from "Core/Controls/Chart/Models/ChartPointModel";

import {FIELD_TYPES} from "Core/Constant";
import {LABELS} from "Core/Components/Translation/Locales";

import * as Highcharts from "highcharts";

import {ChartBuilder} from "Core/Components/Controls/Chart/Builders/ChartBuilder"
import {IChartBuilderParams} from "../IChartBuilderParams";

export class AreaChartBuilder extends ChartBuilder {
    constructor(params: IChartBuilderParams) {
        super(params);
    }

    RenderChart(options: ChartDataModel) {
        const self = this;
        const series = this.GetSeries(options);

        const xAxisLabel = this._xAxisLabel ? this._xAxisLabel : options.XAxisInfoModel.AxisDefaultName;
        const yAxisLabel = this._yAxisLabel ? this._yAxisLabel : options.YAxisInfoModel.AxisDefaultName;

        Highcharts.chart(this._wrapperId, {
            chart: {
                type: this._chartType
            },
            title: {
                text: self._chartTitle
            },
            tooltip: {
                formatter() {
                    return self.GetToolTip(options, this.x, this.y, xAxisLabel, yAxisLabel, this.series.name);
                }
            },
            xAxis: {
                title: {
                    text: xAxisLabel
                },
                labels: {
                    formatter() {
                        return self.ApplyLabelFormatter(this.value, options.XAxisInfoModel.FieldType, options.XAxisInfoModel.FormatName);
                    }
                },
                categories: options.XAxisInfoModel.FieldType === FIELD_TYPES.Text ? self.GetXAxisCategory(options.Points) : null
            },
            yAxis: {
                title: {
                    text: yAxisLabel
                },
                labels: {
                    formatter() {
                        return self.ApplyLabelFormatter(this.value, options.YAxisInfoModel.FieldType, options.YAxisInfoModel.FormatName);
                    }
                },
                categories: options.YAxisInfoModel.FieldType === FIELD_TYPES.Text ? self.GetYAxisCategory(options.Points) : null
            },
            series: series
        }, () => {
        });
    }

    private GetSeries(options: ChartDataModel) {
        let series = [];
        const points = options.Points;

        if (!points || !_.any(points)) {
            return series;
        }

        if (options.XAxisInfoModel.FieldType === FIELD_TYPES.Text) {
            const xCategories = this.GetXAxisCategory(options.Points);

            _.forEach(_.groupBy(points, point => { return point.Line; }),
                groupedPoints => {
                    const line = _.first(groupedPoints).Line ? _.first(groupedPoints).Line : options.XAxisInfoModel.AxisDefaultName;
                    let groupData = [];

                    _.forEach(groupedPoints, point => {
                        groupData.push([xCategories.indexOf(point.XAxis), this.GetFormattedValue(point.YAxis, options.YAxisInfoModel.FieldType)]);
                    });

                    series.push({ name: line, findNearestPointBy: 'xy', data: groupData });
                });
        } else if (options.YAxisInfoModel.FieldType === FIELD_TYPES.Text) {
            const yCategories = this.GetYAxisCategory(options.Points);

            _.forEach(_.groupBy(points, point => { return point.Line; }),
                groupedPoints => {
                    const line = _.first(groupedPoints).Line ? _.first(groupedPoints).Line : options.XAxisInfoModel.AxisDefaultName;
                    let groupData = [];

                    _.forEach(groupedPoints, point => {
                        groupData.push([this.GetFormattedValue(point.XAxis, options.XAxisInfoModel.FieldType), yCategories.indexOf(point.YAxis)]);
                    });

                    series.push({ name: line, findNearestPointBy: 'xy', data: groupData });
                });
        } else {
            _.forEach(_.groupBy(points, point => { return point.Line; }),
                groupedPoints => {
                    const line = _.first(groupedPoints).Line ? _.first(groupedPoints).Line : options.XAxisInfoModel.AxisDefaultName;
                    let groupData = [];

                    _.forEach(groupedPoints, point => {
                        groupData.push({
                            type: null,
                            x: this.GetFormattedValue(point.XAxis, options.XAxisInfoModel.FieldType),
                            y: this.GetFormattedValue(point.YAxis, options.YAxisInfoModel.FieldType)
                        });
                    });

                    series.push({ name: line, findNearestPointBy: 'xy', data: groupData });
                });
        }

        return series;
    }

    private GetToolTip(options: ChartDataModel, x: number, y: number, xLabel: string, yLabel: string, series: string) {
        let xValue = x;
        if (options.XAxisInfoModel.FieldType === FIELD_TYPES.Text) {
            xValue = this.GetXAxisCategory(options.Points)[xValue];
        }

        let yValue = y;
        if (options.YAxisInfoModel.FieldType === FIELD_TYPES.Text) {
            yValue = this.GetYAxisCategory(options.Points)[yValue];
        }

        return `${LABELS.SERIES}: ${series}<br>${
            xLabel}: ${this.ApplyLabelFormatter(xValue, options.XAxisInfoModel.FieldType, options.XAxisInfoModel.FormatName)}<br>${
            yLabel}: ${this.ApplyLabelFormatter(yValue, options.YAxisInfoModel.FieldType, options.YAxisInfoModel.FormatName)}`;
    }

    private GetXAxisCategory(points: Array<ChartPointModel>) {
        return [...new Set(points.map(point => point.XAxis))];
    }

    private GetYAxisCategory(points: Array<ChartPointModel>) {
        return [...new Set(points.map(point => point.YAxis))];
    }
}