import {IconModel} from "Core/Controls/BaseControl/Models/IconModel";
import {FlowOperationModel} from "../Response/FlowOperationModel";
import * as ko from 'knockout'

export class FlowRecordModel {
    EntityId:number;
    RecordId: number;
    TypeId: number;
    Id: number;
    Icon: IconModel;
    Name: string;
    FlowOperations: FlowOperationModel[];
    FlowOperationsSelectID: number;
    FlowOperationsSelect: KnockoutObservable<FlowOperationModel>

    constructor() {
        this.FlowOperations = [];
        this.FlowOperationsSelect = ko.observable(null);
    }
    Init(){
        this.FlowOperationsSelect(_.find(this.FlowOperations, select => select.Id == this.FlowOperationsSelectID ));
    }
}