import * as ko from 'knockout';
import * as _ from 'underscore';

import {Guid} from 'Core/Common/Guid';
import {Notifier} from "Core/Common/Notifier";
import {EventBusConsumer} from "Core/Common/EventBus/EventBusConsumer";

import {NOTIFICATIONS, LABELS} from 'Core/Components/Translation/Locales';

import {SaveConfigurationDataEventArgs, StartPageEvents} from "Core/Components/Controls/ProductConfigurator/Pages/StartPage/Events/StartPageEvents";
import {ImageTableTypes} from "Core/Components/Controls/ProductConfigurator/Components/ProductImageViewer/Enums/ImageTableTypes";
import {ConfiguratorLevels} from 'Core/Components/Controls/ProductConfigurator/ConfiguratorLevels';

import {ConfigurationImageGallery} from 'Core/Components/Controls/ProductConfigurator/Pages/StartPage/Components/ConfigurationImageGallery/ConfigurationImageGallery';
import {ConfigurationDataImage} from 'Core/Components/Controls/ProductConfigurator/Pages/StartPage/Stores/Models/ConfigurationDataImage';
import {ConfigurationDescription} from "Core/Components/Controls/ProductConfigurator/Pages/StartPage/Stores/Models/ConfigurationDescription";
import { ImagePreloader } from "../../../../../../Common/Image";

import Template from 'Core/Components/Controls/ProductConfigurator/Pages/StartPage/Templates/Product.html';

export class Product extends EventBusConsumer {
    _labels = LABELS;

    private _guid: string;
    private _imageSize: number;
    private _imageSizeClassName: string;
    private _hookLoaded: boolean;
    private _memoEditor: any;

    public Levels: string[];
    public readOnly: KnockoutComputed<boolean>;

    private _activeConfigurationLevel: KnockoutObservable<string>;

    private _tabSelected: KnockoutObservable<boolean>;

    private _memo: KnockoutObservable<string>;
    private _initialMemo: KnockoutObservable<string>;
    private _memoVisible: KnockoutObservable<boolean>;

    private _showConfigurationDescription: boolean;
    private _configurationImageGallery: ConfigurationImageGallery;
    public Image: string;

    constructor(public Id: number,
                public Name: string,
                public NameTranslated: string,
                image: string,
                public ConfigurationId: number,
                public ConfigurationDescriptions: ConfigurationDescription[],
                public OrderEntityId: number,
                public ControlId: number,
                public ProductEntityId: number,
                public Annotations: any) {
        super();

        this._guid = Guid.NewGuid();
        this._imageSize = null;
        this._imageSizeClassName = null;
        this._hookLoaded = false;
        this.Image = image ? `api/FilesApi/GetImage/${image}` : null;
        ImagePreloader.Preload(this.Image);

        this._activeConfigurationLevel = ko.observable(null);

        this.readOnly = ko.computed(() => false);
        this._tabSelected = ko.observable(false);

        this._memo = ko.observable(null);
        this._initialMemo = ko.observable(null);
        this._memoVisible = ko.observable(false);

        this._configurationImageGallery = new ConfigurationImageGallery({
            OrderEntityId: OrderEntityId,
            ProductsEntityId: ProductEntityId,
            ControlId: ControlId,
            Annotations: Annotations
        });
    }

    set ImageSize(value: number) {
        this._imageSize = value;
    }

    GetTemplate() {
        return Template;
    }

    get HookLoaded() {
        return this._hookLoaded;
    }

    set HookLoaded(value: boolean) {
        this._hookLoaded = value;
    }

    get MemoHasChanges() {
        return this._memo() !== this._initialMemo();
    }

    get MemoVisible() {
        return this._memoVisible();
    }

    get ConfigurationDataHasChanges() {
        return this.MemoHasChanges || this._configurationImageGallery.HasChanges;
    }

    get TabSelected() {
        return this._tabSelected();
    }

    get ImageSizeClassName(): string {
        return this.GetImageSizeClassName();
    }

    get HighestLevel(): string | null {
        const [highest] = this.Levels.slice(-1);

        if (!highest) {
            return null;
        }

        const levelText: string = highest.slice(0, -2);
        const levelNumber: string = highest.slice(-1);

        return `<span class="level-text">${levelText}</span><span class="level-number">${levelNumber}</span>`;
    }

    get HighestLevelClass(): string | null {
        const [highest] = this.Levels.slice(-1);

        if (!highest) {
            return null;
        }
        const levelNumber: string = highest.slice(-1);

        return `js-level__${levelNumber}`;
    }

    GetImageSizeClassName() {
        switch (this._imageSize) {
            case 60:
                return this._imageSizeClassName = 'imageSize-60';
            case 70:
                return this._imageSizeClassName = 'imageSize-70';
            case 80:
                return this._imageSizeClassName = 'imageSize-80';
            case 90:
                return this._imageSizeClassName = 'imageSize-90';
            default:
                return this._imageSizeClassName = 'imageSize-100';
        }
    }

    get ImageTableType() {
        return ImageTableTypes.Product;
    }

    get ActiveConfigurationLevel() {
        return this._activeConfigurationLevel();
    }

    set ActiveConfigurationLevel(value: string) {
        this._activeConfigurationLevel(value);
    }

    get ShowConfigurationDescription() {
        return this._showConfigurationDescription;
    }

    set ShowConfigurationDescription(value: boolean) {
        this._showConfigurationDescription = value;
    }

    get TabDescription() {
        const level2DescriptionModel = this.ConfigurationDescriptions && _.find(this.ConfigurationDescriptions, description => description.Level === ConfiguratorLevels.Level2);
        const description = level2DescriptionModel && (level2DescriptionModel.DescriptionTranslation || level2DescriptionModel.Description);
        const name = this.NameTranslated || this.Name || this._labels.NO_NAME;

        return this.ShowConfigurationDescription && description ? description : name;
    }

    get DisplayConfigurationDescription() {
        const activeLevelDescriptionModel = this.ConfigurationDescriptions
            && ((this.ActiveConfigurationLevel &&_.find(this.ConfigurationDescriptions, configuration => configuration.Level === this.ActiveConfigurationLevel))
            || _.find(this.ConfigurationDescriptions, configuration => configuration.Level === ConfiguratorLevels.Level1)
            || _.first(this.ConfigurationDescriptions));

        return activeLevelDescriptionModel && (activeLevelDescriptionModel.DescriptionTranslation || activeLevelDescriptionModel.Description);
    }

    OnClick() {
        this.Select();
    }

    Select() {
        this.DispatchEvent<Product>(StartPageEvents.Selected, this);
    }

    Copy() {
        this.DispatchEvent<Product>(StartPageEvents.Copied, this);
    }

    Remove() {
        this.DispatchEvent<Product>(StartPageEvents.Removed, this);
    }

    SelectTab() {
        this._tabSelected(true);
        this.DispatchEvent<Product>(StartPageEvents.TabSelected, this);
    }

    UnSelectTab() {
        this._tabSelected(false);
    }

    SetMemo(memo: string) {
        this._memo(_.unescape(memo));
        this._initialMemo(this._memo());

        if (this._memoEditor) {
            this._memoEditor.setContent(this._memo() || '');
        }
    }

    SetGalleryImages(imageGalleryExists: boolean, configurationImages: ConfigurationDataImage[]) {
        this._configurationImageGallery.SetGalleryImages(imageGalleryExists, configurationImages);
    }

    AfterInitMemo(editor) {
        this._memoEditor = editor;
        this._memoEditor.setContent(this._memo() || '');

        this._memoVisible(true);
    }

    AfterChangeMemo(content) {
        this._memo(content);
    }

    GetSaveConfigurationDataArgs() {
        const params = new SaveConfigurationDataEventArgs(
            this._memo(),
            this._configurationImageGallery.AddedImageAttachments,
            this._configurationImageGallery.RemovedImageIds,
            this._configurationImageGallery.UpdatedAnnotations
        );

        this._configurationImageGallery.ClearImageAttachments();
        return params;
    }

    SaveConfigurationData() {
        setTimeout(() => {
            if (!this.ConfigurationDataHasChanges){
                new Notifier().Warning(NOTIFICATIONS.NO_PENDING_CHANGES);
                return;
            }

            const params = this.GetSaveConfigurationDataArgs();
            this.DispatchEvent(StartPageEvents.SaveConfigurationData, params);
        }, 1);

    }
}