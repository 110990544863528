import * as ko from 'knockout';
import * as _ from 'underscore';
import clone from 'clone'

import { ConditionGroup } from "QueryBuilder/QueryCondition/ConditionGroup/ConditionGroup";
import { Guid } from "Core/Common/Guid";
import { QueryExpressionModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { EntityMetadataModel } from 'Core/Controls/Grid/Models/GridDataModel/EntityMetadataModel';
import { Event } from 'Core/Common/Event';
import { ViewModes } from 'Core/Controls/Grid/BaseGrid/Enums/ViewModes';
import { QueryConditionGroupModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryConditionGroup';
import { AttachedFieldModel } from 'Core/Controls/BaseControl/Models/AttachedFieldModel';
import { IControlAttachedField, IScreenVariable } from 'Core/Screens/BaseScreen';
import { Util } from 'QueryBuilder/Util';
import { QueryConditionItemModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryConditionItemModel';
import {Deserialize} from 'libs/cerialize';
import ConditionBuilderTemplate from 'QueryBuilder/QueryCondition/ConditionBuilder/Templates/ConditionBuilder.html';
import {ConditionValueTypes} from "../../Enums";
import { ConditionItem } from '../ConditionItem/ConditionItem';
import { LABELS } from "Core/Components/Translation/Locales";

export class ConditionBuilder extends Event {
	ConditionGroup: KnockoutObservable<ConditionGroup>;
	private _entities: KnockoutObservableArray<QueryEntityModel>;
	private _id: string;
	private _isForGrid: boolean;
	private _model: QueryExpressionModel;
	private _queryType: ViewModes;
	private _screenFields: Array<IControlAttachedField>;
	private _top: KnockoutObservable<number>;
	private _labels: LABELS = LABELS;
	private _showRecordsLimitInput: boolean;

	constructor(queryType: ViewModes, screenFields: Array<IControlAttachedField>, showRecordsLimitInput: boolean = true) {
		super();
		this._showRecordsLimitInput = showRecordsLimitInput;
		this._queryType = queryType;
		this._id = Guid.NewGuid();
		this._entities = ko.observableArray([]);
		this._isForGrid = false;
		this.ConditionGroup = ko.observable(null);
		this._screenFields = screenFields;
		this._top = ko.observable(null);
		this._top.subscribe(()=>{
			this._model.Top = this._top();
		});
		this.AddEvent('CHANGED');
	}

	InitByQueryExpression(model: QueryExpressionModel) {
		this._isForGrid = true;
		this._model = model;
		this.RefreshEntityList();

		if (!this._model.Condition) {
			this._model.Condition = new QueryConditionGroupModel();
		}

		this._top(model.Top);
		this.ConditionGroup(new ConditionGroup(true, this._model.Condition, this._queryType, this._entities, this._screenFields));
	}

	InitByEntity(entity: EntityMetadataModel, condition: QueryConditionGroupModel, screenFields: Array<IControlAttachedField>) {
		var queryEntity = new QueryEntityModel();
		queryEntity.Metadata = entity;
		this._entities.push(queryEntity);

		this._model = new QueryExpressionModel();
		if (!this._model.Condition) {
			this._model.Condition = condition ? condition : new QueryConditionGroupModel();
			this._model.Condition.EntityId = entity.Id;
		}

		_.each(this.GetAllConditionItems(this._model.Condition), (item) => { item.Column.QueryEntityGuid = queryEntity.Guid; });

		var conditionGroup = new ConditionGroup(true, this._model.Condition, this._queryType, this._entities, screenFields, true);
		conditionGroup.On('CHANGED',
			this,
			(eventArgs) => {
				this.Trigger('CHANGED');
			});

		this.ConditionGroup(conditionGroup);
	}

	InitByEntities(entities: Array<EntityMetadataModel>, condition: QueryConditionGroupModel, screenFields: Array<IControlAttachedField>) {

		_.each(entities, (entity) => {
			let queryEntity = new QueryEntityModel();
			queryEntity.Metadata = entity;
			this._entities.push(queryEntity);
		});

		this._model = new QueryExpressionModel();
		if (!this._model.Condition) {
			this._model.Condition = condition ? condition : new QueryConditionGroupModel();
		}

		_.each(this.GetAllConditionItems(this._model.Condition), (item) => {
			_.each(this._entities(), (entity: QueryEntityModel) => {
				_.each(entity.Metadata.Fields, (field) => {
					if(field.Id === item.Column.Metadata.Id){
						item.Column.QueryEntityGuid = entity.Guid;
					}
				});
			});
		});

		let conditionGroup = new ConditionGroup(true, this._model.Condition, this._queryType, this._entities, screenFields, true);
		conditionGroup.On('CHANGED',
			this,
			() => {
				this.Trigger('CHANGED');
			});

		this.ConditionGroup(conditionGroup);
	}

	GetAllConditionItems(model: QueryConditionGroupModel): Array<QueryConditionItemModel> {
		var result = [];
		_.each(model.Items, (item) => {
			result.push(item);
		});

		_.each(model.ConditionGroups, (group) => {
			result = result.concat(this.GetAllConditionItems(group));
		});

		return result;
	}

	GetTemplate() {
		return ConditionBuilderTemplate;
	}

	RefreshEntityList() {
		this._entities(Util.GetAllQueryEntities(this._model));
	}

	AfterRender() { }

	get ConditionModel(): QueryConditionGroupModel {
		return this._model.Condition;
	}

	public static GetScreenVariableCondition(screenVariable: IScreenVariable, condition: QueryConditionGroupModel): Array<QueryConditionItemModel> {
		let screenVariableConditions = [];
		if (condition) {
			_.each(condition.Items,
				(item) => {
					if(item.ValueType === ConditionValueTypes.QueryBuilder){
						let query = this.DeserializeQueryExpressionModel(item.Value);
						if(query){
							screenVariableConditions = screenVariableConditions.concat(this.GetScreenVariableCondition(screenVariable, query.Condition));
						}
					}else if (screenVariable.FullName === item.Value) {
						screenVariableConditions.push(item);
					}
				});
			_.each(condition.ConditionGroups, conditionGroup => {
				screenVariableConditions = screenVariableConditions.concat(this.GetScreenVariableCondition(screenVariable, conditionGroup));
			});
		}
		return screenVariableConditions;
	}

	private static DeserializeQueryExpressionModel(query: string): QueryExpressionModel{
		try{
			let jsonQuery = JSON.parse(query);
			return Deserialize(jsonQuery, QueryExpressionModel);
		}catch (e) {
			return null;
		}
	}
}