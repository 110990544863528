import * as ko from 'knockout';
import * as $ from "jquery";
import * as _ from 'underscore';
import 'knockout-selectize';
import 'hidden_textfield';
import 'dropdown_direction';
import 'scroll_parent';

import { Text } from 'QueryBuilder/QueryCondition/ConditionEditors/Text/Text';
import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { FieldMetadataModel } from 'Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel';
import { QueryConditionItemModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryConditionItemModel';
import { ComparisonOperators, ConditionValueTypes } from 'QueryBuilder/Enums';
import { CONFIG, FUNCTIONS, TYPES_COMPATIBILITY } from 'QueryBuilder/QueryCondition/ConditionItem/ConditionItemConfig';
import { YesNo } from 'QueryBuilder/QueryCondition/ConditionEditors/YesNo/YesNo';
import { Spim } from 'QueryBuilder/QueryCondition/ConditionEditors/Spim/Spim';
import { Date as DateEditor } from 'QueryBuilder/QueryCondition/ConditionEditors/Date/Date';
import { Lookup } from 'QueryBuilder/QueryCondition/ConditionEditors/Lookup/Lookup';
import { CONTROL_TYPES, FIELD_TYPES } from 'Core/Constant';
import { Event } from 'Core/Common/Event';
import { QueryColumnModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryColumnModel';
import { AttachedFieldModel } from 'Core/Controls/BaseControl/Models/AttachedFieldModel';
import { DocumentStatusLookup } from 'QueryBuilder/QueryCondition/ConditionEditors//DocumentStatusLookup/DocumentStatusLookup';
import { FormatConverter } from 'FormatEditor/FormatConverter';
import { COMPARISON_OPERATOR_LABELS } from 'QueryBuilder/Constants';
import { ConditionItemField } from 'QueryBuilder/QueryCondition/ConditionItem/ConditionItemField';
import { QueryBuilder } from 'QueryBuilder/QueryCondition/ConditionEditors/QueryBuilder/QueryBuilder';
import { LABELS } from "Core/Components/Translation/Locales";
import {LifeStatusEnum} from "common/life-status-enum";
import {JBoxDropDown} from "Core/Components/JBoxDropdown/DropDown";

import * as moment from 'moment';

import ConditionItemTemplate from 'QueryBuilder/QueryCondition/ConditionItem/Templates/ConditionItem.html';
import ConditionItemSimpleTemplate from 'QueryBuilder/QueryCondition/ConditionItem/Templates/ConditionItemSimple.html';
import ConditionItemSPIMTemplate from 'QueryBuilder/QueryCondition/ConditionItem/Templates/ConditionItemSPIM.html';
import PasteVariableDropdownTemplate from 'QueryBuilder/QueryCondition/ConditionItem/Templates/PasteVariableDropdownTemplate.html'
import {ZIndexManager} from "../../../Core/Common/ZIndexManager";
import { IControlAttachedField } from 'Core/Screens/BaseScreen';
import { StaticText } from '../ConditionEditors/StaticText/StaticText';

ko.templates["QueryBuilder/QueryCondition/ConditionItem/Templates/PasteVariableDropdownTemplate"] = PasteVariableDropdownTemplate;


export class ConditionItem extends Event {
	QueryEntities: KnockoutObservableArray<QueryEntityModel>;

	Fields: KnockoutObservableArray<ConditionItemField>;
	SelectedField: KnockoutObservable<ConditionItemField>;
	SelectedFieldId: KnockoutObservable<any>;

	ValueFields: KnockoutObservableArray<ConditionItemField>;
	SelectedValueField: KnockoutObservable<ConditionItemField>;
	SelectedValueFieldId: KnockoutObservable<any>;

	IsEnableShowParam: KnockoutObservable<boolean>;
	ShowParam: KnockoutObservable<boolean>;
	IsEnableAddMultipleValues: KnockoutObservable<boolean>;
	IsEnableQueryBuilder: KnockoutObservable<boolean>;
	IsEnableResetButton: KnockoutObservable<boolean>;
	ShowValueEditorButton: KnockoutObservable<boolean>;
	ShowValueFieldListButton: KnockoutObservable<boolean>;
	HasMetadata: boolean;

	private _fieldValueEditors: KnockoutObservableArray<any>;
	private _model: QueryConditionItemModel;
	private _comparisonOperators: KnockoutObservableArray<any>;
	private _operator: KnockoutObservable<any>;
	private _screenFields: KnockoutObservableArray<IControlAttachedField>;
	private _originalScreenFields: Array<IControlAttachedField>;
	private _selectedField: ConditionItemField;
	private _functions: KnockoutObservableArray<string>;
	private _isDesigntime: boolean;
	private _labels = LABELS;
	private _dropDown: JBoxDropDown;

	constructor(model: QueryConditionItemModel,
		entities: KnockoutObservableArray<QueryEntityModel>,
		screenFields: Array<IControlAttachedField>,
		isDesignTime: boolean) {
		super();

		this._model = model;
		this._isDesigntime = isDesignTime;
		this._fieldValueEditors = ko.observableArray(null);
		this._operator = ko.observable(null);
		this._comparisonOperators = ko.observableArray();
		this._screenFields = ko.observableArray(screenFields);
		this._originalScreenFields = screenFields;
		this._functions = ko.observableArray([]);

		this.QueryEntities = entities;
		this.Fields = ko.observableArray([]);
		this.ValueFields = ko.observableArray([]);

		this.IsEnableShowParam = ko.observable(false);
		this.ShowParam = ko.observable(this._model.ShowParam);
		this.SelectedField = ko.observable(null);
		this.SelectedFieldId = ko.observable(null);

		this.SelectedValueField = ko.observable(null);
		this.SelectedValueFieldId = ko.observable(null);

		this.IsEnableAddMultipleValues = ko.observable(false);
		this.IsEnableQueryBuilder = ko.observable(false);
		this.IsEnableResetButton = ko.observable(false);

		this.ShowValueEditorButton = ko.observable(this._model.ValueType === ConditionValueTypes.Column);
		this.ShowValueFieldListButton = ko.observable(this._model.ValueType !== ConditionValueTypes.Column);
		this.HasMetadata = false;

		this.Init();
		this.QueryEntities.subscribe((newValue) => this.UpdateFieldList());
	}

	UpdateOperators(fieldType: string) {
		const config = CONFIG[fieldType];

		if (config) {
			let operators = [];

			_.each(config.Operators, (operator) => {
				operators.push({ Value: operator, Label: COMPARISON_OPERATOR_LABELS[ComparisonOperators[operator]] });
			});

			this._comparisonOperators(operators);
			this._operator(_.find(this._comparisonOperators(), (operator) => operator.Value === this._model.Operator));
		}
	}

	UpdateFunctions(fieldType: string) {
		const config = CONFIG[fieldType];

		if (config) {
			const functions = [];

			_.each(config.Functions, (func) => functions.push(func));
			this._functions(functions);
		}
	}

	SelectFirstField() {
		const selectedField = _.first(this.Fields());

		if (selectedField) {
			this.SelectedField(selectedField);
			this.SelectedFieldId(selectedField.id);
			this.Update(selectedField.FieldMetadata.Type);
		}
	}

	Update(fieldType: string) {
		this.UpdateOperators(fieldType);
		this.UpdateFunctions(fieldType);
		this.UpdateEditor();
		this.UpdateScreenFields();
	}

	UpdateScreenFields() {

		let compatibleFields = _.filter(this._originalScreenFields, (item) =>{
			return TYPES_COMPATIBILITY[this.SelectedField().FieldMetadata.Type].Types.indexOf(item.Field.FieldTypeName) >= 0;
		});

		this._screenFields(compatibleFields);
	}


	Init() {
		this.UpdateFieldList();

		this.ShowParam.subscribe((newValue) => this._model.ShowParam = newValue);

		if (this._model.Column) {
			const selectedField = _.find(this.Fields(), (field) => {
				return field.QueryEntityGuid === this._model.Column.QueryEntityGuid && field.FieldMetadata.Id === this._model.Column.Metadata.Id;
			});

			if (selectedField) {
				this._model.Column.Metadata = selectedField.FieldMetadata;
				this.SelectedField(selectedField);
				this._selectedField = selectedField;
				this.SelectedFieldId(selectedField.id);
				this.HasMetadata = true;

				this.Update(selectedField.FieldMetadata.Type);
			}
		}

		this.UpdateValueFieldList();

		this.SelectedField.subscribe((newValue) => {
			if (newValue !== this._selectedField) {
				this._model.Value = '';
			}

			this.Update(newValue.FieldMetadata.Type);
			this._model.Column = new QueryColumnModel();
			this._model.Column.Metadata = newValue.FieldMetadata;
			this._model.Column.QueryEntityGuid = newValue.QueryEntityGuid;
			this.UpdateValueFieldList();
			this.RaiseChangeEvent();
			this.RemoveDeletedField();
		});

		
		this.SelectedValueField.subscribe((newValue) => {
			this._model.ValueColumn = new QueryColumnModel();
			this._model.ValueColumn.Metadata = newValue.FieldMetadata;
			this._model.ValueColumn.QueryEntityGuid = newValue.QueryEntityGuid;
			this._model.ValueType = ConditionValueTypes.Column;
			this._model.Value = null;
			this.RaiseChangeEvent();
		});

		this._operator.subscribe((newValue) => {

			if(newValue?.Value === ComparisonOperators.IsNotNull || newValue?.Value === ComparisonOperators.IsNull){
				this._model.ValueType = ConditionValueTypes.General;
				this._model.Value = null;
			}

			if ((this._model.Operator === ComparisonOperators.In && newValue.Value !== ComparisonOperators.In) || (this._model.Operator === ComparisonOperators.NotIn && newValue.Value !== ComparisonOperators.NotIn)) {
				this._model.Value = '';
				this._model.ValueType = this._model.ValueType !== ConditionValueTypes.Column ? ConditionValueTypes.General : this._model.ValueType;
			}

			if (newValue) {
				this._model.Operator = newValue.Value;

				const useMultipleValues = newValue.Value === ComparisonOperators.In || newValue.Value === ComparisonOperators.NotIn;
				this.IsEnableAddMultipleValues(useMultipleValues);
				this.IsEnableQueryBuilder(useMultipleValues);
			}

			setTimeout(this.UpdateEditor.bind(this), 0);
			this.RaiseChangeEvent();
		});
	}

	RaiseChangeEvent() {
		this.Trigger('CHANGED');
	}

	private InitPasteVariableDropdown(el: HTMLElement) {
		this._dropDown = new JBoxDropDown({
			target: el,
			bindTarget: el,
			bindComponent: this,
			otherOptions: {
				addClass: "pasteVariable-dropdown",
				closeOnClick: true,
				attach: undefined,
				height: 'auto',
				maxHeight: 300,
				isolateScroll: true,
				pointer: "",
				maxWidth: 400,
				onCloseComplete: () => {},
				zIndex: ZIndexManager.Instance.NextValue,
				position: {
					x: "right",
					y: "bottom"
				}
			},

			onCreated: () => {
				this._dropDown.SetContent({ content: PasteVariableDropdownTemplate as any });
			},

			onOpen: () => {}
		});
	}

	ShowPasteVariableDropdown(data, event){
		let target: HTMLElement,
			isPasteVariableDropdownBox = $(event.target).parents('.pasteVariableDropdownBox').length > 0;

		target = isPasteVariableDropdownBox ? $(event.target).parents('.pasteVariableDropdownBox')[0] : event.currentTarget;

		if (this._screenFields().length && this._functions().length && this.IsEnableQueryBuilder()){
			this.InitPasteVariableDropdown(target);
			this._dropDown.Open();
		} else {
			if (this._dropDown) {
				this._dropDown.Toggle();
			} else {
				this.InitPasteVariableDropdown(target);
				this._dropDown.Open();
			}
		}
	}

	AddEditor() {
		const editor = this.GetEditor('');

		if (editor) {
			this._fieldValueEditors.push(editor);
		}
	}

	RemoveEditor(editor) {
		this._fieldValueEditors.splice(this._fieldValueEditors().indexOf(editor), 1);
		this._model.Value = '';

		_.each(this._fieldValueEditors(), (editor) => {
			if (editor && editor.Value()) {
				this._model.Value += this._model.Value === '' ? editor.Value() : `;${editor.Value()}`;
			}
		});
		this.RaiseChangeEvent();
	}

	UpdateEditor() {
		this._fieldValueEditors([]);
		if ((this._operator() &&
			(this._operator().Value === ComparisonOperators.NotIn || this._operator().Value === ComparisonOperators.In)
				&&
			this._model.Value 
				&&
			this._model.Value.split
				&&
			this._model.ValueType === ConditionValueTypes.General) || ((this.SelectedField() ? this.SelectedField().FieldMetadata.Type === FIELD_TYPES.MultiSelect : false) && this._operator().Value === ComparisonOperators.Equals)) {

			const values = this._model.Value ? this._model.Value.split(';') : [];

			if(values.length > 0)
			{
				_.each(values, (value) => {
					const editor = this.GetEditor(value);

					if (editor) {
						this._fieldValueEditors.push(editor);
					}
				});
			}else{
				const editor = this.GetEditor('-1');
				if (editor) {
					this._fieldValueEditors.push(editor);
				}
			}
		} else {
			const editor = this.GetEditor(this._model.Value);

			if (editor) {
				this._fieldValueEditors.push(editor);
			}
		}
	}

	GetEditor(value: string): any {
		let editor = null;
		let dateTimeFormat;
		let fieldType;

		if (this.SelectedField() && this._operator() && this._model.Column && this._model.Column.Metadata.Name) {
			fieldType = this.SelectedField().FieldMetadata.Type;

			const operator = this._operator().Value;

			if (this._model.ValueType === ConditionValueTypes.Function) {
				editor = new Text();
				editor.Value(value);
				this.IsEnableAddMultipleValues(false);
				this.IsEnableResetButton(true);
			} else if ((this._model.Operator === ComparisonOperators.In || this._model.Operator === ComparisonOperators.NotIn) && this._model.ValueType === ConditionValueTypes.QueryBuilder) {
				editor = new QueryBuilder(this.SelectedField().FieldMetadata, this._originalScreenFields);
				editor.Value(value);
				this.IsEnableAddMultipleValues(false);
				this.IsEnableResetButton(true);
			} else if (this._model.ValueType === ConditionValueTypes.ScreenVariable) {
				editor = new Text();
				editor.Value(value);
				this.IsEnableResetButton(true);
			} else if (operator === ComparisonOperators.IsNull || operator === ComparisonOperators.IsNotNull) {
			} else if (fieldType === FIELD_TYPES.Text || fieldType === FIELD_TYPES.PKey || fieldType === FIELD_TYPES.Integer || fieldType === FIELD_TYPES.Decimal || fieldType === FIELD_TYPES.Alias) {
				editor = new Text();
				editor.Value(value);
			} else if (fieldType === FIELD_TYPES.YesNo) {
				editor = new YesNo();
				editor.Value(value);
			} else if (fieldType === FIELD_TYPES.Date || fieldType === FIELD_TYPES.DateTime || fieldType === FIELD_TYPES.Time || fieldType === FIELD_TYPES.TimeSpan) {
				dateTimeFormat = FormatConverter.GetDateFormatFromFieldModel(this._model.Column.Metadata, true);
				const date = value === '' ? value : moment(value).format(dateTimeFormat);

				editor = new DateEditor(this._model, dateTimeFormat, date);
			} else if (fieldType === FIELD_TYPES.Lookup) {
				editor = new Lookup(this.SelectedField().FieldMetadata);
				editor.Value(value);
			} else if (fieldType === FIELD_TYPES.MultiSelect) {
				editor = new Lookup(this.SelectedField().FieldMetadata);
				editor.Value(value);
				this.IsEnableAddMultipleValues(true);
			} else if (fieldType === FIELD_TYPES.Document && (operator === ComparisonOperators.NotEquals || operator === ComparisonOperators.Equals)) {
				editor = new DocumentStatusLookup();
				editor.Value(value);
			} else if (fieldType === FIELD_TYPES.Spim ) {
				editor = new Spim(this.SelectedField().FieldMetadata);
				editor.Value(value);
			}
		}

		if (editor) {
			editor.Value.subscribe((newValue) => {
				if (editor instanceof DateEditor) {
					newValue = this.ConvertDateTimeValue(newValue, dateTimeFormat, fieldType);
				}

				if (editor instanceof QueryBuilder) {
					this._model.Value = newValue;
					this._model.SubQuery = null;
					this._model.ValueType = ConditionValueTypes.QueryBuilder;
				}else if (this._operator().Value === ComparisonOperators.In || this._operator().Value === ComparisonOperators.NotIn || ((this.SelectedField() ? this.SelectedField().FieldMetadata.Type === FIELD_TYPES.MultiSelect : false) && this._operator().Value === ComparisonOperators.Equals )) {
					this._model.Value = '';

					_.each(this._fieldValueEditors(), (editor) => {
						if (editor && editor.Value()) {
							this._model.Value += this._model.Value === '' ? editor.Value() : `;${editor.Value()}`;
						}
					});
				} else {
					this._model.Value = newValue;
					this._model.ValueType = ConditionValueTypes.General;
				}

				this.RaiseChangeEvent();
			});

			if(editor instanceof Spim){
				this._model.SPIMFeatureType = editor.FeatureType();
				editor.FeatureType.subscribe((newValue) => {
					this._model.SPIMFeatureType = newValue;
				});		
			}
		}

		return editor;
	}

	ConvertDateTimeValue(value, dateTimeFormat: string, fieldType: string) {
		let utcValue = FormatConverter.ConvertToUTC(value, dateTimeFormat);
		if(fieldType === FIELD_TYPES.DateTime || fieldType === FIELD_TYPES.Time){
			return moment.utc(utcValue).format()
		}
		
		return utcValue;
	}

	GetTemplate() {
		return ConditionItemTemplate;
	}

	GetSimpleTemplate() {
		return ConditionItemSimpleTemplate;
	}

	GetSPIMTemplate() {
		return ConditionItemSPIMTemplate;
	}

	UpdateFieldList() {
		_.each(this.QueryEntities(), (entity: QueryEntityModel) => {
			_.each(entity.Metadata.Fields, (fieldMetadata: FieldMetadataModel) => {
				const existsField = _.find(this.Fields(), (field) => {
					return field.QueryEntityGuid === entity.Guid && field.FieldMetadata.Id === fieldMetadata.Id;
				});

				if (!existsField) {

					if(fieldMetadata.LifestatusName !== LifeStatusEnum.Delete || fieldMetadata.Id === this._model?.Column?.Metadata?.Id){
						this.Fields.push(new ConditionItemField(entity, fieldMetadata));
					}					
				}
			});
		});
	}

	private RemoveDeletedField(){
		_.each(this.Fields(), field=>{
			if(field.FieldMetadata.LifestatusName === LifeStatusEnum.Delete && field.FieldMetadata.Id !== this.SelectedField()?.FieldMetadata.Id){
				this.Fields.splice(this.Fields.indexOf(field), 1);
			}
		})
	}

	UpdateValueFieldList() {
		this.ValueFields([]);

		if (this.SelectedField()) {
			_.each(this.QueryEntities(), (entity: QueryEntityModel) => {
				_.each(entity.Metadata.Fields, (fieldMetadata: FieldMetadataModel) => {
					const existsField = _.find(this.ValueFields(), (field) => {
						return field.QueryEntityGuid === entity.Guid && field.FieldMetadata.Id === fieldMetadata.Id;
					});

					if (!existsField && TYPES_COMPATIBILITY[this.SelectedField().FieldMetadata.Type].Types.indexOf(fieldMetadata.Type) >=0 ) {
						this.ValueFields.push(new ConditionItemField(entity, fieldMetadata));
					}
				});
			});

			if (this._model.ValueColumn && this._model.ValueType === ConditionValueTypes.Column) {
				const selectedField = _.find(this.ValueFields(),
					(field) => {
						return field.QueryEntityGuid === this._model.ValueColumn.QueryEntityGuid &&
							field.FieldMetadata.Id === this._model.ValueColumn.Metadata.Id;
					});

				if (selectedField) {
					this._model.ValueColumn.Metadata = selectedField.FieldMetadata;
					this.SelectedValueField(selectedField);
					this.SelectedValueFieldId(selectedField.id);
				} else {
					const firstField = _.first(this.ValueFields());
					if (firstField) {
						this.SelectedValueField(firstField);
						this.SelectedValueFieldId(firstField.id);
					}
				}
			}
		}
	}

	get Model() {
		return this._model;
	}

	AfterRender() {
	}

	PasteVariable(controlField: string) {
		const editor = new Text();

		editor.Value(controlField);
		this._fieldValueEditors([editor]);
		this._model.ValueType = ConditionValueTypes.ScreenVariable;
		this._model.Value = controlField;
		this.IsEnableResetButton(true);

		editor.Value.subscribe((newValue) => {
			this._model.Value = newValue;
			this.RaiseChangeEvent();
		});
		this.RaiseChangeEvent();
	}

	FormatControlField(controlField: IControlAttachedField){
		if(controlField.ControlType === CONTROL_TYPES.LinkList){
			return `$[LinkList].[Main].[${controlField.Field.EntityName}].[${controlField.Field.Name}]`;
		}

		return `$[${controlField.Field.EntityName}].[${controlField.Field.Name}]`;
	}

	PasteFunction(func: string) {
		const editor = new Text();

		editor.Value(func);
		this._fieldValueEditors([editor]);
		this._model.ValueType = ConditionValueTypes.Function;
		this._model.Value = func;
		this.IsEnableResetButton(true);

		editor.Value.subscribe((newValue) => {
			this._model.Value = newValue;
			this.RaiseChangeEvent();
		});

		this.RaiseChangeEvent();
	}

	PasteQuery() {
		const editor = new QueryBuilder(this.SelectedField().FieldMetadata, this._originalScreenFields);

		this._fieldValueEditors([editor]);
		editor.ShowQueryBuilder();
		this._model.ValueType = ConditionValueTypes.QueryBuilder;
		this.IsEnableAddMultipleValues(false);

		editor.Value.subscribe((newValue) => {
			this._model.Value = newValue;

			this.RaiseChangeEvent();
		});

		this.IsEnableResetButton(true);
	}

	Reset() {
		this._model.ValueType = ConditionValueTypes.General;
		this._model.Value = '';
		this.UpdateEditor();
		this.IsEnableResetButton(false);
		this.IsEnableAddMultipleValues(this._model.Operator === ComparisonOperators.In || (this.SelectedField() ? this.SelectedField().FieldMetadata.Type === FIELD_TYPES.MultiSelect : false) );
	}


	ShowValueFieldList() {
		this.ShowValueEditorButton(true);
		this.ShowValueFieldListButton(false);

		if (this.SelectedField()) {
			this.UpdateValueFieldList();
		}
	}

	ShowValueEditor() {
		this.ShowValueFieldListButton(true);
		this.ShowValueEditorButton(false);
	}
}