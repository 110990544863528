import {P} from 'Core/Common/Promise';
import {Request, IRequestOptions} from 'Core/Common/Request';
import { EntitiesListModel } from 'Core/Controls/CanvasDesigner/Models/EntitiesListModel';
import { FunctionDesignerPackageModel } from 'Core/Controls/FunctionDesigner/Models/FunctionDesignerPackageModel';
import { GenericDeserialize } from 'libs/cerialize';
import { FunctionDesignerMethodModel } from 'Core/Controls/FunctionDesigner/Models/FunctionDesignerMethodModel';
import { FunctionExecutionModel } from 'Core/Controls/FunctionDesigner/Models/FunctionExecutionModel';
import { FunctionValidationModel } from 'Core/Controls/FunctionDesigner/Models/FunctionValidationModel';

export interface IGetPackageRequestModel {
	PackageId: number;
}

export interface IGetMethodRequestModel {
	MethodId: number;
}


export interface IUpdateCodeRequestModel {
	Id: number;
	Code: string
}

export interface IExecuteRequestModel {
	PackageId: number;
	MethodId: number;
	IsRawResult?: boolean;
	ObjectId: string;
}

export interface IValidateRequestModel {
	PackageId: number;
	MethodId: number;
	Code: string;
}

export interface ITestRequestModel {
	PackageId: number;
	MethodId: number;
	Code: string;
	ObjectId: string;
}

export interface ICancelExecuteRequestModel {
	ErrorMessage: string;
}

export interface IContinueExecuteRequestModel {
	Param: any;
}


export class FunctionDesignerStore {

	static GetFunctionDesignerMetaData(): P.Promise<any> {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/FunctionDesignerApi/GetFunctionDesignerMetaData',
				dataType: 'json',
				type: 'GET'
			}
		};

		Request.Send(requestParams)
			.then(data => deferredResult.resolve(data));

		return deferredResult.promise();
	}

	static GetPackage(params: IGetPackageRequestModel): P.Promise<FunctionDesignerPackageModel> {
		const deferredResult = P.defer<FunctionDesignerPackageModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/FunctionDesignerApi/GetPackage',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(result => {
				if (result.IsSuccessfull) {
					deferredResult.resolve(GenericDeserialize<FunctionDesignerPackageModel>(result.ResultObject, FunctionDesignerPackageModel));
				}
			});

		return deferredResult.promise();
	}


	static DeletePackage(params: IGetPackageRequestModel): P.Promise<FunctionDesignerPackageModel> {
		const deferredResult = P.defer<FunctionDesignerPackageModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/FunctionDesignerApi/DeletePackage',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(result => {
				if (result.IsSuccessfull) {
					deferredResult.resolve(GenericDeserialize<FunctionDesignerPackageModel>(result.ResultObject, FunctionDesignerPackageModel));
				}
			});

		return deferredResult.promise();
	}

	static GetMethod(params: IGetMethodRequestModel): P.Promise<FunctionDesignerMethodModel> {
		const deferredResult = P.defer<FunctionDesignerMethodModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/FunctionDesignerApi/GetMethod',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(result => {
				if (result.IsSuccessfull) {
					deferredResult.resolve(GenericDeserialize<FunctionDesignerMethodModel>(result.ResultObject, FunctionDesignerMethodModel));
				}
			});

		return deferredResult.promise();
	}

	static UpdateCode(params: IUpdateCodeRequestModel): P.Promise<FunctionDesignerMethodModel> {
		const deferredResult = P.defer<FunctionDesignerMethodModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/FunctionDesignerApi/UpdateCode',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(result => {
				if (result.IsSuccessfull) {
					deferredResult.resolve(null);
				} else {
					deferredResult.reject({ message: result.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static Validate(params: IValidateRequestModel): P.Promise<FunctionValidationModel> {
		const deferredResult = P.defer<FunctionValidationModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/FunctionDesignerApi/Validate',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(result => {
				if (result.IsSuccessfull) {
					deferredResult.resolve(GenericDeserialize<FunctionValidationModel>(result.ResultObject, FunctionValidationModel));
				} else {
					deferredResult.reject({ message: result.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static Test(params: ITestRequestModel): P.Promise<FunctionExecutionModel> {
		const deferredResult = P.defer<FunctionExecutionModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/FunctionDesignerApi/Test',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(result => {
				if (result.IsSuccessfull) {
					deferredResult.resolve(GenericDeserialize<FunctionExecutionModel>(result.ResultObject, FunctionExecutionModel));
				} else {
					deferredResult.reject({ message: result.ErrorMessage })
				}
			});

		return deferredResult.promise();
	}

	static Execute(params: IExecuteRequestModel): P.Promise<FunctionExecutionModel> {
		const deferredResult = P.defer<FunctionExecutionModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/FunctionDesignerApi/Execute',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(result => {
				if (result.IsSuccessfull) {
					deferredResult.resolve(GenericDeserialize<FunctionExecutionModel>(result.ResultObject, FunctionExecutionModel));
				} else {
					deferredResult.reject({ message: result.ErrorMessage })
				}
			});

		return deferredResult.promise();
	}	
}