import { Modal } from "Core/Common/Modal";
import * as ko from 'knockout';
import Template from 'Core/Controls/CanvasDesigner/Templates/MethodsList.html';
import {CanvasItemModel} from 'Core/Controls/CanvasDesigner/Models/CanvasModel';
import {Event} from 'Core/Common/Event'
import {EVENTS} from "../Shapes/Views/ParamView/Events";

ko.templates['Core/Controls/CanvasDesigner/Templates/MethodsList'] = Template;

export class MethodsList extends Event {
	private _modal: Modal;
	private _methods: Array<CanvasItemModel>;

	constructor(methods: Array<CanvasItemModel>) {
		super();
		this._methods = methods;
		this.AddEvent(EVENTS.METHOD_SELECTED);
	}

	ShowInModal() {
		let modal = new Modal({
			width: '350',
			minHeight: '300'
		}, false);

		this._modal = modal
		ko.cleanNode(modal.Wrapper);
		ko.applyBindings(this, modal.Wrapper);
		modal.Show();
	}

	Close(){
		if(this._modal){
			this._modal.Close();
		}
	}

	GetTemplateName() {
		return 'Core/Controls/CanvasDesigner/Templates/MethodsList';
	}

	AfterRender(){}

	SelectMethod(item: CanvasItemModel){
		this.Trigger(EVENTS.METHOD_SELECTED, { Id: item.Id })
	}
}