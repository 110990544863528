import { dia as Dia, shapes } from 'libs/rappid/build/rappid';

shapes.devs.Model.define('cyberThing.Param', {
	ports: {
		groups: {
			'in': {
				markup: '<circle class="port-body" r="10"/>',
				attrs: {
					'.port-body': {
						fill: '#61549C',
						strokeWidth: 0
					},
					'.port-label': {
						fontSize: 11,
						fill: '#61549C',
						fontWeight: 800
					}
				},
				label: {
					position: {
						name: 'left',
						args: {
							y: 0
						}
					}
				},
				type: 'in'
			},
			'out': {
				markup: '<circle class="port-body" r="10"/>',
				attrs: {
					'.port-body': {
						fill: '#61549C',
						strokeWidth: 0
					},
					'.port-label': {
						fontSize: 11,
						fill: '#61549C',
						fontWeight: 800
					}
				},
				label: {
					position: {
						name: 'right',
						args: {
							y: 0
						}
					}
				},
				type: 'out'
			}
		}
	}
});