import * as moment from "moment";

import {ISchedularResponseModel} from "../Models/Store/Response/SchedulerResponseModel";
import {HeaderColumn, SchedulerViewModel} from "../Models/View/SchedulerViewModel";
import {SchedulerView} from "../Views/SchedulerView";
import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";
import {PeriodsMapping} from "./PeriodsMappings";
import {HeaderColumnMapping} from "./HeaderColumnMapping";
import {GroupsMapping} from "./GroupsMapping";
import {DATE_FORMATS} from "Core/Constants/DateTimeFormats";
import {IRePlanningData} from "Core/Controls/Scheduler/Interfaces";
import {FormatConverter} from "FormatEditor/FormatConverter";

export interface ISchedulerMappingProfile {
    model: ISchedularResponseModel,
    startDate: moment.Moment,
    endDate: moment.Moment,
    numberOfPeriods: number,
    periodDuration: number,
    startingTime: string,
    schedulerView: SchedulerView,
    rePlanningData: IRePlanningData
}

export class SchedulerMappingProfile {
    static OnViewModel(model: ISchedulerMappingProfile): SchedulerViewModel {
        const viewModel = new SchedulerViewModel();
        const userGroups = GlobalManager.Instance.GetGlobal(GLOBALS.USER_GROUPS);
        viewModel.NumberOfPeriods = model.numberOfPeriods;
        viewModel.StartingTime = model.startingTime;
        viewModel.PeriodDuration = model.periodDuration;
        viewModel.Periods = PeriodsMapping.OnViewModel(model.startingTime, model.periodDuration, model.numberOfPeriods);
        viewModel.HeaderColumns = HeaderColumnMapping.OnViewModel(model.startDate, model.endDate, viewModel.Periods, model.schedulerView._screen);
        viewModel.Groups = GroupsMapping.OnViewModel({
            responseModel: model.model,
            userGroups: userGroups,
            periods: viewModel.Periods,
            startDate: model.startDate,
            endDate: model.endDate,
            schedulerView: model.schedulerView,
            rePlanningData: model.rePlanningData
        });

        if (model.rePlanningData) {
            let selectedRows: Array<{id: number, rows: any}> = [];
            _.forEach(viewModel.Groups, (group) => {
                _.forEach(group._subGroupEditors, (subGroup) => {
                    if (subGroup._isSelected()) {
                        const selectedRow = {
                            id: subGroup._columnFirst._id,
                            rows: {}
                        };
                        _.forEach(subGroup._columnsEditors, (column, columnIter) => {
                            _.forEach(column._cellsEditors, (cell, cellIter) => {
                                if (!selectedRow.rows[columnIter]) {
                                    selectedRow.rows[columnIter] = {};
                                }
                                selectedRow.rows[columnIter][cellIter] = cell._isAvailableForReplan();
                            });
                        });
                        selectedRows.push(selectedRow);
                    }
                });
            });

            const uniqSelectedRows = _.uniq(selectedRows, (row) => row.id);
            let availableModel = [];
            if (uniqSelectedRows.length) {
                availableModel = selectedRows[0].rows;
                for (let i = 1; i < uniqSelectedRows.length; i++) {
                    _.forEach(uniqSelectedRows[i].rows, (column: Array<boolean>, columnIter) => {
                        _.forEach(column, (cell, cellIter) => {
                            availableModel[columnIter][cellIter] = availableModel[columnIter][cellIter] && cell;
                        });
                    });
                }
            }

            const agenda = {};

            _.forEach(viewModel.Groups, (group) => {
                _.forEach(group._subGroupEditors, (subGroup) => {
                    if (subGroup._isSelected()) {
                        _.forEach(subGroup._columnsEditors, (column, columnIter) => {
                            if (!agenda[columnIter]) {
                                agenda[columnIter] = {};
                            }
                            _.forEach(column._cellsEditors, (cell, cellIter) => {
                                if (!agenda[columnIter][cellIter]) {
                                    agenda[columnIter][cellIter] = [];
                                }
                                cell._isAvailableForReplan(availableModel[columnIter][cellIter]);
                                if (availableModel[columnIter][cellIter]) {
                                    if (subGroup._agenda.length) {
                                        _.forEach(subGroup._agenda, (item) => {
                                            const start = moment(FormatConverter.CorrectTimezone(item.Starting));
                                            const end = moment(start.clone()).add(moment.duration(moment(item.Duration).format(DATE_FORMATS.TIME.Format)));

                                            const periodStart = moment(cell._period.Start);
                                            const periodEnd = periodStart.clone().add(cell._period.Duration, 'h');

                                            if ((start < periodEnd) && (end > periodStart)){
                                                agenda[columnIter][cellIter].push({
                                                    id: item.Id,
                                                    start: moment(FormatConverter.CorrectTimezone(item.Starting)),
                                                    end: moment(start.clone()).add(moment.duration(moment(item.Duration).format(DATE_FORMATS.TIME.Format)))
                                                });
                                            }
                                        });
                                    }
                                }

                                if (agenda[columnIter][cellIter].length > 1) {
                                    agenda[columnIter][cellIter] = _.uniq(agenda[columnIter][cellIter], (ag: any) => ag.id);
                                }
                            });
                        });
                    }
                });
            });
            viewModel.AvailablePeriods = this.AvailablePeriodsForReplanning(agenda, viewModel.HeaderColumns, model.rePlanningData);

            _.forEach(viewModel.Groups, (group) => {
                _.forEach(group._subGroupEditors, (subGroup) => {
                    if (subGroup._isSelected()) {
                        _.forEach(subGroup._columnsEditors, (column, columnIter) => {
                            _.forEach(column._cellsEditors, (cell, cellIter) => {
                                cell._availableForReplanRanges = _.map(viewModel.AvailablePeriods[columnIter][cellIter], (range) => {
                                    return {
                                        Start: range.a.clone(),
                                        End: range.b.clone(),
                                        DisplayValue: `${range.a.clone().format(DATE_FORMATS.TIME.Format)} - ${range.b.clone().format(DATE_FORMATS.TIME.Format)}`
                                    }
                                });
                            })
                        })
                    }
                });
            });
        }

        viewModel.AgendaConnection = model.model.AgendaConnection;
        viewModel.CreatingData = model.model.CreatingData;
        viewModel.IsValid = model.model.IsValid;
		viewModel.ReadingData = model.model.ReadingData;
	    viewModel.ScreenSubjectRecordName = model.model.ScreenSubjectRecordName;
        viewModel.Errors = model.model.Errors;
        viewModel.Month = moment(model.startDate).format(DATE_FORMATS.MONTH_AND_YEAR.Format);
        viewModel.MonthDayYear = moment(model.startDate).format(DATE_FORMATS.SHORT_DATE_SHORT_WEEK.Format)+', '+ moment(model.startDate).format(DATE_FORMATS.ONLY_A_YEAR.Format);
        viewModel.CurrentDate = moment(model.startDate).format();
        return viewModel;
    }


    static AvailablePeriodsForReplanning(agenda: any, headerColumns: Array<HeaderColumn>, rePlanningData: IRePlanningData) {
        const base = {};
        _.forEach(agenda, (column: Array<any>, colIndex) => {
            if (!base[colIndex]) {
                base[colIndex] = {};
            }
            _.forEach(column, (cell: Array<any>, cellIndex) => {
                if (!base[colIndex][cellIndex]) {
                    base[colIndex][cellIndex] = [];
                }
                const period = headerColumns[colIndex].Periods[cellIndex];
                base[colIndex][cellIndex] = [Object.assign({},{a: moment(period.Start).clone(), b: moment(period.Start).clone().add(period.Duration, 'hours')})];

                for (let i =0; i<cell.length; i++){
                    let newBase = [];
                    for(let j=0; j<base[colIndex][cellIndex].length; j++) {
                        const {start:c, end:d} = cell[i];
                        newBase = newBase.concat(this.Compare(base[colIndex][cellIndex][j],{c, d}));
                    }
                    base[colIndex][cellIndex] = _.filter(newBase, (item) => {
                        return item.b.diff(item.a, 'hours', true) >=
                            rePlanningData.EndTime.diff(rePlanningData.StartTime, 'hours', true);
                    });

                }
            })
        });
        return base;
    }

    static Compare({a, b}, {c, d}) {
        if ( (c > a) && (b > d) )
            return [{a,b:c}, {a:d,b}];
        if ( (a >= c) && (d >= b) )
            return [{a,b}];
        if ( (a >= c) && ( b > d) && (d > a))
            return [{a:d,b}];
        if ( (c > a) && (d >= b) && (b > c))
            return [{a,b:c}];
        return [{a,b}];
    }
}