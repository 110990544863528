import { GridStore } from 'Core/Controls/Grid/Stores/GridStore';
import * as _ from 'underscore';
import * as moment from 'moment';
import * as ko from 'knockout'

import {LifeStatuses} from "Core/Enums/LifeStatuses";
import {RenderModes, CONTROL_TYPES} from "Core/Constant";
import {DATE_FORMATS} from 'Core/Constants/DateTimeFormats';

import {IProgressBarParams} from "Core/Components/ProgressBar/IProgressBarParams";

import {ActionSubjectRecordModel} from "Core/ScreenManager/Models/ActionSubjectRecordModel";
import {LifeStatusInfo} from "Core/ScreenManager/Models/LifeStatusInfo";
import {RecordSpecsModel} from "Core/ScreenManager/Models/RecordSpecsModel";
import {LifeStatusViewModel} from "Core/Components/ProgressBar/Models/View/LifeStatusViewModel";
import {FlowFolderStore} from "Core/Components/ProgressBar/Stores/FlowFolderStore";
import {HelpBook} from 'HelpBook/HelpBook';

import ViewTemplate from 'Core/Components/ProgressBar/Templates/View.html';
import EditTemplate from 'Core/Components/ProgressBar/Templates/Edit.html';
import {NOTIFICATIONS, LABELS} from "Core/Components/Translation/Locales";
import {FormatConverter} from "FormatEditor/FormatConverter";
import { ActionCheckList } from "../ActionCheckList/ActionCheckList";
import { LifeStatusesGeneralModel } from "Core/Controls/ButtonFollowUp/Models/LifeStatusesGeneralModel";
import { FollowUpRecordModel } from "Core/Controls/ButtonFollowUp/Models/FollowUpRecordModel";
import { FollowUpParentRecordModel } from "Core/Controls/ButtonFollowUp/Models/FollowUpParentRecordModel ";
import { FollowUpLifeStatuses } from "Core/Controls/ButtonFollowUp/FollowUpLifeStatuses";
import { LifeStatusesModel } from 'Core/Controls/ButtonFollowUp/Models/LifeStatusesModel';
import { FollowUpOptionModel } from "Core/Controls/ButtonFollowUp/Models/FollowUpOptionModel";
import { BaseScreen } from "Core/Screens/BaseScreen";
import { SubjectActionModel } from "Core/Controls/ButtonFollowUp/Models/SubjectActionModel";


import {Notifier} from "../../Common/Notifier";
import {FlowFolderModel} from "./Models/Response/FlowFolderModel";
import {ScreenParamsModel} from "Core/Controls/ButtonFollowUp/Models/ScreenParamsModel";
import { BlockUI } from 'Core/Common/BlockUi';
import { ProgressBarInfoModel } from './Models/Response/ProgressBarInfoModel';
import { LifeStatusSimpleModel } from 'Core/Controls/ButtonFollowUp/Models/LifestatusSimpleModel';
import { ActionDropdown } from './ActionDropdown/ActionDropdown';
import type { StepsScreen } from 'Core/Screens/StepsScreen/StepsScreen';

interface IProgressBarActiveParams {
    RenderMode: RenderModes;
    ActiveLifeStatus: LifeStatusInfo;
}

export class ProgressBar {
    private _entityId: number;
	private _recordId: number;
	private _typeId: number;
    private _actionSubject: ActionSubjectRecordModel;
    private _recordSpecs: RecordSpecsModel;
    private _renderMode: RenderModes;
	private _lifeStatus: LifeStatusViewModel;
	private _help: HelpBook;
    private _title: KnockoutObservable<string>;
    private _tableTypeName: KnockoutObservable<string>;
    private _checkList: ActionCheckList;
    private _labels = LABELS;
    private _flowFolder: boolean;
    private _isEditScreen: boolean;

    private _followUpRecord: FollowUpRecordModel;
    private _followUpLifeStatuses: FollowUpLifeStatuses;
    private _lifeStatuses: LifeStatusesModel;
    private _screen: BaseScreen;
    private _el: HTMLElement;
    private _data: ProgressBarInfoModel;

    constructor(params: IProgressBarParams) {
        this._title = ko.observable(null);
        this._screen = params.Screen;
        const tableTypeName = this._screen.GetTableTypeNameTranslation()
            ? this._screen.GetTableTypeNameTranslation()
            : this._screen.GetTableTypeName();

        if (tableTypeName && tableTypeName !== '-') {
            this._tableTypeName = ko.observable(tableTypeName +', ');
        }

        this._recordSpecs = params.RecordSpecs;

        this._entityId = params.EntityId;
		this._recordId = params.RecordId;
		this._typeId = params.TypeId;
        this._actionSubject = this._screen.GetActionSubjectRecord();

        this._flowFolder = params.Screen.GetTableTypeFlowFolder();
        this._isEditScreen = params.Screen.IsEditScreen;

        const activeParams = this.GetActiveParams(params);

        this._renderMode = activeParams.RenderMode;

        this._lifeStatus = this.MapToViewModel(activeParams.ActiveLifeStatus);
        this._lifeStatus.Enabled.subscribe(() => this.ModifyLifeStatus());

        this._lifeStatuses = null;
        this._followUpLifeStatuses = null;

		this._help = HelpBook.Instance;
    }

    GetTemplate() {
        switch (this._renderMode) {
            case RenderModes.View:
                return this.GetViewTemplate();
            case RenderModes.Edit:
                return this.GetEditTemplate();
            default:
                throw new Error(`Render mode ${RenderModes[this._renderMode]} is not supported`);
        }
    }

    GetLifeStatus() {
        const lifeStatus = new LifeStatusInfo();

        lifeStatus.Name = this._lifeStatus.Enabled() ? LifeStatuses.Enabled.Name : LifeStatuses.Disabled.Name;
        lifeStatus.LastStatusDate = new Date(this._lifeStatus.Date());
        lifeStatus.Modified = this._lifeStatus.Modified;

        return lifeStatus;
    }

	RequestCheckList() {
		if (this._help.IsHelpButtonPressed()) {
			this._help.ShowTypeHelp(this);
			return;
        }

        if (this._screen.GetDataModel().RecordSpecs.IsDisabled) {
            new Notifier().Warning(this._labels.RECORD_IS_DISABLED);
            return;
        }

        if (!this._recordId){
            return;
        }

        BlockUI.Block();
        FlowFolderStore.GetProgressBarInfo({ EntityId: this._entityId, RecordId: this._recordId })
            .always(()=>BlockUI.Unblock())
            .then(result => {
                this._data = result;
                this.DoProgressBarProcess();
            })
            .fail((err) => new Notifier().Failed(err.message));
    }

    DoProgressBarProcess(){
        let lifeStatuses = _.filter(this._data.NextLifeStatuses.Child.LifeStatuses, (lifeStatus)=>lifeStatus.StepsScreenId != 0);
        let showStatusSelector = this._data.NextLifeStatuses.Child.LifeStatuses.length != lifeStatuses.length;
        if(lifeStatuses.length > 0){
            this.ShowActionDropdown({ lifeStatuses, showStatusSelector });
            return;
        }
        if (this._data.NextLifeStatuses.SubjectAction) {
            if (this._screen.IsConsultScreen) {
                this.ProcessingStatuses(this._data.NextLifeStatuses, this._data.FlowFolder);
            } else {
                this.ProcessingCheckList(this._data.NextLifeStatuses);
            }

        } else {
            new Notifier().Warning(this._labels.ACTIVE_ACTIONS_NOT_FOUND);
        }
       
    }

    ShowStatusSelector(){
        this._data.NextLifeStatuses.Child.LifeStatuses = _.filter(this._data.NextLifeStatuses.Child.LifeStatuses, (lifeStatus)=>lifeStatus.StepsScreenId == 0);

        if (this._data.NextLifeStatuses.SubjectAction) {
            if (this._screen.IsConsultScreen) {
                this.ProcessingStatuses(this._data.NextLifeStatuses, this._data.FlowFolder);
            } else {
                this.ProcessingCheckList(this._data.NextLifeStatuses);
            }

        } else {
            new Notifier().Warning(this._labels.ACTIVE_ACTIONS_NOT_FOUND);
        }
    }

    async ShowStepsScreen(status: LifeStatusSimpleModel){
        BlockUI.Block();
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
        screenManager.GetScreenById({ ScreenId: status.StepsScreenId })
        .always(()=>BlockUI.Unblock())
        .then((screen: StepsScreen)=>{
            screen.On('FINISH_PROCESS', this, ()=>this.SwitchToNextStatus(status.Id));
            screen.LoadStepControlData({ recordId: this._recordId });
            screen.ShowInModal();            
        });
    }

    SwitchToNextStatus(nextStatusId: number){
        GridStore.SwitchToNextStatus({ EntityId: this._entityId, RecordId: this._recordId, NextStatusId: nextStatusId }).then(()=>this._screen.Refresh());
    }

    ShowActionDropdown({ lifeStatuses, showStatusSelector }: { lifeStatuses: Array<LifeStatusSimpleModel>, showStatusSelector: boolean }){
        let actionDropdown = new ActionDropdown(lifeStatuses, showStatusSelector);
        actionDropdown.On('SHOW_STATUS_SELECTOR', this, ()=>this.ShowStatusSelector());
        actionDropdown.On('RECORD_SELECTED', this, (evtArgs)=>this.ShowStepsScreen(evtArgs.data.status));
        actionDropdown.Show(this._el);        
    }

    ProcessingCheckList(model: LifeStatusesGeneralModel) {
        if (!this._checkList) {
            this._checkList = new ActionCheckList({
                EntityId: this._entityId,
                RecordId: this._recordId,
                FollowUpMode: false,
                CurrentLifestatus: model.Child.CurrentLifeStatus
            });
        }

        this._checkList.ProvideData(model.Child && model.Child.CheckLists);
        if (this._checkList.HasAnyCheckedStatuses()) {
            this._checkList.Show();
        } else {
            new Notifier().Warning(this._labels.THERE_ARE_NO_NEXT_STATUSES_WITH_CHECK_LIST);
        }
    }

    ProcessingStatuses(result: LifeStatusesGeneralModel, flowFolderModel?: FlowFolderModel) {
        if (result == null || !result.Child) {
            return;
        }

        if (!this._followUpRecord) {
            this._followUpRecord = new FollowUpRecordModel();
        }

        if (!this._followUpLifeStatuses){
            this._followUpLifeStatuses = new FollowUpLifeStatuses();
        }
        const screenParams: ScreenParamsModel = { EntityId: this._entityId, RecordId: this._recordId };

        if (result.Child.CurrentLifeStatus === null && result.Child.LifeStatuses.length === 1) {
            const firstStatus = result.Child.LifeStatuses[0];
            this._followUpRecord.LifeStatusId = firstStatus.Id;
            this._followUpRecord.LifeStatusSort = firstStatus.Sort;
            this._followUpRecord.LifeStatusName = firstStatus.Name;
            this._followUpRecord.LifeStatusNoActionNode = firstStatus.NoActionNode;
            this.FireFollowUp(result.SubjectAction);
            return;
        }

        this._followUpRecord.CurrentLifeStatus = result.Child.CurrentLifeStatus;

        this._lifeStatuses = result.Child;
        const showCheckLists = _.any(this._lifeStatuses.CheckLists, checkList => checkList.CheckItems.length > 0)
            && (!result.IsManyActions || result.Child.CurrentLifeStatus.MultipleActions);

        if (!result.IsManyActions && showCheckLists) {
            this.ShowCheckLists(result, this._flowFolder && !this._isEditScreen, screenParams, flowFolderModel);
            return;
        }

        let infiniteLoop = result.Parent && result.Child && (result.Parent.ActionSubjectRecord.RecordId === result.Child.ActionSubjectRecord.RecordId);
        if(!result.IsManyActions && infiniteLoop && showCheckLists) {
            this.ShowCheckLists(result, this._flowFolder && !this._isEditScreen, screenParams, flowFolderModel);
            return;
        }

        if (result.IsManyActions && !result.Child.CurrentLifeStatus.MultipleActions) {
            result.Child.LifeStatuses = [result.Child.CurrentLifeStatus];
            this._followUpLifeStatuses.Caption = LABELS.EXIST_ACTIVE_ACTIONS;
        } else if (showCheckLists && result.Child.CurrentLifeStatus.MultipleActions) {
            this.ShowCheckLists(result, this._flowFolder && !this._isEditScreen, screenParams, flowFolderModel);
            return;
        }

        const followUpOption = new FollowUpOptionModel();
        followUpOption.LifeStatuses = result.Child;

        let followUpParentOption: FollowUpOptionModel;
        if (result.Parent) {
            followUpParentOption = new FollowUpOptionModel();
            followUpParentOption.LifeStatuses = result.Parent;
        }

        if (this._lifeStatuses.LifeStatuses.length === 0) {
            new Notifier().Warning(NOTIFICATIONS.NEXT_STATUS_NOT_AVAILABLE);
        }


        this._followUpLifeStatuses.Init(followUpOption, followUpParentOption, screenParams);
        this._followUpLifeStatuses.InitFlowFolder(this._flowFolder && !this._isEditScreen, this._entityId, flowFolderModel, result);

        this.ShowStandardFollowUp(result);
    }

    private ShowCheckLists(result: LifeStatusesGeneralModel, isFlowFolder: boolean, screenParams, flowFolderModel: FlowFolderModel) {
        const actionSubject = result.Child.ActionSubjectRecord;
        const checkLists = result.Child.CheckLists;

        if (!this._checkList) {
            this._checkList = new ActionCheckList({
                EntityId: actionSubject.EntityId,
                RecordId: actionSubject.RecordId,
                FollowUpMode: true,
                CurrentLifestatus: result.Child.CurrentLifeStatus
            });
            this._checkList.On('LIFESTATUS_CHOOSEN',
                this,
                eventArgs => {
                    this._followUpRecord.LifeStatusId = eventArgs.data.LifeStatus.Id || eventArgs.data.LifeStatus.LifeStatusId;
                    this._followUpRecord.LifeStatusSort = eventArgs.data.LifeStatus.Sort || eventArgs.data.LifeStatus.LifeStatusSort;
                    this._followUpRecord.LifeStatusName = eventArgs.data.LifeStatus.Name || eventArgs.data.LifeStatus.LifeStatusName;
                    this._followUpRecord.LifeStatusNoActionNode = eventArgs.data.LifeStatus.NoActionNode || eventArgs.data.LifeStatus.LifeStatusNoActionNode;
                    this._followUpRecord.ConfirmationPassword = eventArgs.data.Password;
                    this._followUpRecord.RetireChildren = eventArgs.data.RetireChildren;
                    this.FireFollowUp(result.SubjectAction);
                });
        }

        this._checkList.ProvideData(checkLists);
        this._checkList.InitFlowFolder(isFlowFolder, screenParams.EntityId, flowFolderModel, result);
        this._checkList.Show();
    }

    private ShowStandardFollowUp(result: LifeStatusesGeneralModel) {
        this._followUpLifeStatuses.On('LIFESTATUS_CHOOSEN',
            this,
            eventArgs => {
                this._followUpRecord.LifeStatusId = eventArgs.data.LifeStatus.Id || eventArgs.data.LifeStatus.LifeStatusId;
                this._followUpRecord.LifeStatusSort = eventArgs.data.LifeStatus.Sort || eventArgs.data.LifeStatus.LifeStatusSort;
                this._followUpRecord.LifeStatusName = eventArgs.data.LifeStatus.Name || eventArgs.data.LifeStatus.LifeStatusName;
                this._followUpRecord.LifeStatusNoActionNode = eventArgs.data.LifeStatus.NoActionNode || eventArgs.data.LifeStatus.LifeStatusNoActionNode;
                this._followUpRecord.ConfirmationPassword = eventArgs.data.Password;
                this._followUpRecord.RetireChildren = eventArgs.data.RetireChildren;
                this.FireFollowUp(result.SubjectAction);
            });

        this._followUpLifeStatuses.On('PARENT_LIFESTATUS_CHOOSEN',
            this,
            eventArgs => {
                const parentRecord = new FollowUpParentRecordModel();
                parentRecord.ParentActionSubject = result.Parent.ActionSubjectRecord;
                parentRecord.CurrentLifeStatus = result.Parent.CurrentLifeStatus;
                parentRecord.LifeStatusId = eventArgs.data.LifeStatus.Id || eventArgs.data.LifeStatus.LifeStatusId;
                parentRecord.LifeStatusSort = eventArgs.data.LifeStatus.Sort || eventArgs.data.LifeStatus.LifeStatusSort;
                parentRecord.LifeStatusName = eventArgs.data.LifeStatus.Name || eventArgs.data.LifeStatus.LifeStatusName;
                parentRecord.LifeStatusNoActionNode = eventArgs.data.LifeStatus.NoActionNode || eventArgs.data.LifeStatus.LifeStatusNoActionNode;
                parentRecord.ConfirmationPassword = eventArgs.data.Password;
                this._followUpRecord.FollowUpParentRecord = parentRecord;
                this.FireFollowUp(result.SubjectAction);
            });

        this._followUpLifeStatuses.On('CHILD_LIFESTATUS_CHOOSEN',
            this,
            eventArgs => {
                this._followUpRecord.LifeStatusId = eventArgs.data.LifeStatus.Id || eventArgs.data.LifeStatus.LifeStatusId;
                this._followUpRecord.LifeStatusSort = eventArgs.data.LifeStatus.Sort || eventArgs.data.LifeStatus.LifeStatusSort;
                this._followUpRecord.LifeStatusName = eventArgs.data.LifeStatus.Name || eventArgs.data.LifeStatus.LifeStatusName;
                this._followUpRecord.LifeStatusNoActionNode = eventArgs.data.LifeStatus.NoActionNode || eventArgs.data.LifeStatus.LifeStatusNoActionNode;
                this._followUpRecord.ConfirmationPassword = eventArgs.data.Password;
            });

        this._followUpLifeStatuses.On('DONE',
            this,
            () => {
                this._screen.Trigger('FOLLOWUP_DONE');
            });

        this._followUpLifeStatuses.ShowInModal();
    }

    CloseActionCheckList() {
        if (this._checkList) {
            this._checkList.Close();
        }
    }

    private GetActiveParams(params: IProgressBarParams) {
        switch (params.RenderMode) {
            case RenderModes.View:
                return this.GetViewActiveParams(params.RecordSpecs);
            case RenderModes.Edit:
                return this.GetEditActiveParams(params.MigratedRequired, params.RecordSpecs);
            default:
                throw new Error(`Render mode ${RenderModes[this._renderMode]} is not supported`);
        }
    }

    private GetViewActiveParams(recordSpecs: RecordSpecsModel): IProgressBarActiveParams {
        let activeRenderMode = RenderModes.View;
        let activeLifeStatus = recordSpecs.LifeStatusInfo;

        return {
            RenderMode: activeRenderMode,
            ActiveLifeStatus: activeLifeStatus
        };
    }

    private GetEditActiveParams(migratedRequired: boolean, recordSpecs: RecordSpecsModel) {
        let activeRenderMode: RenderModes;
        let activeLifeStatus = recordSpecs.LifeStatusInfo;

        if (recordSpecs.IsNewRecord) {
            activeRenderMode = RenderModes.View;

            return {
                RenderMode: activeRenderMode,
                ActiveLifeStatus: activeLifeStatus
            };
        }

        if (migratedRequired) {
            activeRenderMode = RenderModes.View;
            this._title(NOTIFICATIONS.TITLE_CONVERTED_TYPE_OF_RECORD);
            return {
                RenderMode: activeRenderMode,
                ActiveLifeStatus: activeLifeStatus
            };
        }

        const enabledDisabledStatuses = [LifeStatuses.Enabled.Name.toUpperCase(), LifeStatuses.Disabled.Name.toUpperCase()];


        const validStatus = !!activeLifeStatus.Name
            ? _.find<string>(enabledDisabledStatuses, status => status === activeLifeStatus.Name.toUpperCase())
            : null;
        activeRenderMode = validStatus ? RenderModes.Edit : RenderModes.View;

        return {
            RenderMode: activeRenderMode,
            ActiveLifeStatus: activeLifeStatus
        };
    }

    private GetViewTemplate() {
        return ViewTemplate;
    }

    private GetEditTemplate() {
        return EditTemplate;
    }

    private FireFollowUp(subjectAction: SubjectActionModel) {
        if (subjectAction) {
            this._screen.Trigger('FOLLOWUP_FROM_SUBJECT',
                { followUpRecord: this._followUpRecord, subjectAction: subjectAction });
        }
    }

    private MapToViewModel(lifeStatus: LifeStatusInfo) {
        const viewModel = new LifeStatusViewModel();

        viewModel.Name(lifeStatus.TranslatedName || lifeStatus.Name);
        viewModel.Date(this.FormatDate(lifeStatus.Name && FormatConverter.CorrectTimezone(lifeStatus.LastStatusDate.toString())));
        viewModel.Enabled(lifeStatus.Name && lifeStatus.Name.toUpperCase() === LifeStatuses.Enabled.Name.toUpperCase());

        return viewModel;
    }

    private ModifyLifeStatus() {
        this._lifeStatus.Modified = true;

        const lifeStatusName = this._lifeStatus.Enabled() ? LifeStatuses.Enabled.Name : LifeStatuses.Disabled.Name;

        this._lifeStatus.Name(lifeStatusName);
        this._lifeStatus.Date(this.FormatDate(new Date()));
    }

    private FormatDate(date: string | Date) {
        const momentDate = moment(date);
        return date && momentDate.isValid() ? momentDate.format(DATE_FORMATS.FULL_DATETIME.MomentFormat) : 'Date undefined';
    }

    AfterRender(elements: HTMLElement[]) {
        this._el = _.find(elements, (element) => element.nodeType === 1);
        
        if (this._flowFolder) {
            const flowFolderIcon = elements[2];

            $(flowFolderIcon).droppable({
                accept: '.recent-block-list-container__flow-folder-popover, .grid-relative-container__flow-folder-popover',
                tolerance: 'touch',
                drop: (event, ui) => {
                    FlowFolderStore.CreateFlowRecord({
                        EntityId: this._entityId,
                        RecordId: this._recordId,
                        ReferenceEntityId: ui.draggable.data('EntityId'),
                        ReferenceRecordId: ui.draggable.data('RecordId')
                    })
                        .then(result => {
                            if (result.Warnings.length > 0) {
                                result.Warnings.forEach(warningMessage => {
                                    new Notifier().Warning(warningMessage);
                                });
                            } else if (!result.IsSuccessfull) {
                                new Notifier().Failed(result.ErrorMessage);
                            } else {
                                new Notifier().Success(NOTIFICATIONS.DATA_SAVED);
                            }
                        })
                        .fail(error => {
                            new Notifier().Failed(error.message);
                        });
                }
            });
        }
    }
}