import { Request, IRequestOptions } from "Core/Common/Request";
import { P } from "Core/Common/Promise";
import { URL } from "Core/Common/Url";
import {GenericDeserialize, Deserialize} from "libs/cerialize";
import {TagModel} from 'Core/Controls/Tag/Models/TagsScreenDataModel';

export class TagStore {
	static GetTags(expression: string, tagScope: number, controlId: number): P.Promise<any> {
		let deferredResult = P.defer();

		let requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.TAG.GET_TAGS,
				dataType: 'json',
				type: 'POST'
			},
			params: {
				Expression: expression,
				TagScopeId: tagScope,
				ControlId: controlId
			}
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(data.ResultList);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			}).fail((err) => deferredResult.reject({ message: err.message }));

		return deferredResult.promise();
	}

	static GetAllTags(tagScope: number, controlId: number, byScopedRecords: boolean, hideDisabled: boolean = false): P.Promise<Array<TagModel>> {
		let deferredResult = P.defer<Array<TagModel>>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.TAG.GET_ALL_TAGS,
				dataType: 'json',
				type: 'POST'
			},
			params: {
				ControlId: controlId,
				TagScopeId: tagScope,
				ByScopedRecords: byScopedRecords,
				HideDisabled: hideDisabled
			}
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(Deserialize(data.ResultList, TagModel));
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			}).fail((err) => deferredResult.reject({ message: err.message }));

		return deferredResult.promise();
	}
}