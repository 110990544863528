export enum FieldFlags {
    System = 1,
    Required = 2,
    ReadOnly = 4,
    Indexed = 8,
    Unique = 16,
    Virtual = 32,
    Global = 64,
    Hidden = 128,
    Translate = 256
}