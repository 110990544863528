import * as ko from 'knockout';
import * as _ from 'underscore';
import * as dragula from 'dragula';

export class DragulaExtention {
    private static FOREACH_OPTIONS_PROPERTIES = ['afterAdd', 'afterMove', 'afterRender', 'as', 'beforeRemove'];
    private static LIST_KEY = 'ko_dragula_list';
    private static AFTER_DROP_KEY = 'ko_dragula_afterDrop';
    private static AFTER_DELETE_KEY = 'ko_dragula_afterDelete';
    private static AFTER_CLONE_KEY = 'ko_dragula_afterClone';
    private static DRAG_KEY = 'ko_dragula_drag';
    private static DRAG_END_KEY = 'ko_dragula_dragEnd';

    private static unwrap = ko.unwrap;
    private static setData = ko.utils.domData.set;
    private static getData = ko.utils.domData.get;
    private static foreachBinding = ko.bindingHandlers.foreach;
    private static fastForEachBinding = ko.bindingHandlers.fastForEach;
    private static addDisposeCallback = ko.utils.domNodeDisposal.addDisposeCallback;
    private static cloneBackup;
    private static copying;

    private static groups = [];

    static Init() {
        this.FOREACH_OPTIONS_PROPERTIES = ['afterAdd', 'afterMove', 'afterRender', 'as', 'beforeRemove'];
        this.LIST_KEY = 'ko_dragula_list';
        this.AFTER_DROP_KEY = 'ko_dragula_afterDrop';
        this.AFTER_DELETE_KEY = 'ko_dragula_afterDelete';
        this.AFTER_CLONE_KEY = 'ko_dragula_afterClone';
        this.DRAG_KEY = 'ko_dragula_drag';

        this.unwrap = ko.unwrap;
        this.setData = ko.utils.domData.set;
        this.getData = ko.utils.domData.get;
        this.foreachBinding = ko.bindingHandlers.foreach;
        this.addDisposeCallback = ko.utils.domNodeDisposal.addDisposeCallback;
        this.groups = [];
        var self = this;

        ko.bindingHandlers.dragula = {
            init: (element, valueAccessor, allBindings, viewModel, bindingContext) => {
                var options = self.unwrap(valueAccessor()) || {};
                var foreachOptions = self.MakeForeachOptions(valueAccessor, options);
                self.setData(element, self.LIST_KEY, foreachOptions.data);
                self.setData(element, self.AFTER_DROP_KEY, options.afterDrop);
                self.setData(element, self.AFTER_DELETE_KEY, options.afterDelete);
                self.setData(element, self.DRAG_KEY, options.onDrag);
                self.setData(element, self.DRAG_END_KEY, options.onDragEnd);

                if (options.useFastForEach) {
                    self.fastForEachBinding.init(element, () => {
                        return foreachOptions;
                    }, allBindings, viewModel, bindingContext);
                } else {
                    self.foreachBinding.init(element, () => {
                        return foreachOptions;
                    }, allBindings, viewModel, bindingContext);
                }

                if (options.disabled) {
                    return {controlsDescendantBindings: true};
                }

                if (options.group) {
                    self.CreateOrUpdateDrakeGroup(element, options);
                } else {
                    (() => {
                        var drake = self.CreateDrake(element, options);
                        self.addDisposeCallback(element, () => {
                            return drake.destroy();
                        });
                    })();
                }

                return {
                    controlsDescendantBindings: true
                };
            },

            update(element, valueAccessor, allBindings, viewModel, bindingContext) {
                var options = self.unwrap(valueAccessor()) || {};
                var foreachOptions = self.MakeForeachOptions(valueAccessor, options);

                self.setData(element, self.LIST_KEY, foreachOptions.data);
                self.setData(element, self.AFTER_DROP_KEY, options.afterDrop);
                self.setData(element, self.DRAG_KEY, options.onDrag);
                self.setData(element, self.DRAG_END_KEY, options.onDragEnd);

                if (options.useFastForEach) {
                    self.fastForEachBinding.init(element, () => {
                        return foreachOptions;
                    }, allBindings, viewModel, bindingContext);
                } else {
                    self.foreachBinding.update(element, () => {
                        return foreachOptions;
                    }, allBindings, viewModel, bindingContext);
                }
            }
        };
    }

    static MakeForeachOptions(valueAccessor, options) {
        var templateOptions = {
            data: options.data || valueAccessor()
        };

        this.FOREACH_OPTIONS_PROPERTIES.forEach((option) => {
            if (options.hasOwnProperty(option)) {
                templateOptions[option] = options[option];
            }
        });

        return templateOptions;
    }

    static CreateOrUpdateDrakeGroup(container, options) {
        var group = this.FindGroup(options.group);
        if (group) {
            group.drake.containers.push(container);
        } else {
            group = this.AddGroup(options.group, this.CreateDrake(container, options));
        }

        this.addDisposeCallback(container, () => {
            return this.RemoveContainer(group, container);
        });
    }

    static AddGroupWithOptions(name, options) {
        var drake = dragula(options);
        this.RegisterEvents(drake);
        return this.AddGroup(name, drake);
    }

    static FindGroup(name) {
        for (var i = 0; i < this.groups.length; i++) {
            if (this.groups[i].name === name) {
                return this.groups[i];
            }
        }
        return null;
    }

    static FindGroups(name) {
        var result = [];
        for (var i = 0; i < this.groups.length; i++) {
            if (this.groups[i].name === name) {
                result.push(this.groups[i]);
            }
        }
        return result;
    }

    static AddGroup(name, drake) {
        var group = {
            name: name, drake: drake
        };
        this.groups.push(group);
        return group;
    }

    static RegisterEvents(drake) {
        drake.on('drop', this.OnDrop.bind(this));
        drake.on('drag', this.OnDrag.bind(this));
        drake.on('remove', this.OnRemove.bind(this));
        drake.on('cancel', this.OnCancel.bind(this));
        drake.on('cloned', this.OnCloned.bind(this));
    }

    static RemoveContainer(group, container) {
        var index = group.drake.containers.indexOf(container);
        group.drake.containers.splice(index, 1);

        if (!group.drake.containers.length) {
            this.DestroyGroup(group);
        }
    }

    static DestroyGroup(group) {
        var index = this.groups.indexOf(group);
        this.groups.splice(index, 1);
        group.drake.destroy();
    }

    static CreateDrake(element, options) {
        var drake = dragula([element], options);
        this.RegisterEvents(drake);
        return drake;
    }

    static OnDrop(el, target, source) {
        if (!target) {
            return;
        }
        var item = ko.dataFor(el);
        if (this.copying) {
            item = ko.dataFor(this.cloneBackup);
            this.copying = false;
        }
        var context = ko.contextFor(el);

        var sourceItems = this.getData(source, this.LIST_KEY);

        if (!sourceItems) {
            target.removeChild(el);
            return;
        }

        var sourceIndex = sourceItems.indexOf(item);

        var targetItems = this.getData(target, this.LIST_KEY);
        var targetIndex = Array.prototype.indexOf.call(target.children, el);
        var sourceContext = ko.dataFor(source);
        var targetContext = ko.dataFor(target);

        target.removeChild(el);

        /*if (this.copying) {
            var koCopy = item.Clone ? item.Clone() : mapping.fromJS(mapping.toJS(item));
            sourceItems.splice(sourceIndex, 1);
            sourceItems.splice(sourceIndex, 0, koCopy);
            targetItems.splice(targetIndex, 0, item);
            this.copying = false;
        } else {
            sourceItems.splice(sourceIndex, 1);
            targetItems.splice(targetIndex, 0, item);
        }*/

        var afterDrop = this.getData(target, this.AFTER_DROP_KEY);
        if (afterDrop) {
            afterDrop.call(context, item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext);
        }

        var onDragEnd = this.getData(target, this.DRAG_END_KEY);
        if (onDragEnd) {
            onDragEnd.call(context, item);
        }
    }

    static OnDrag(el, source) {
        var item = ko.dataFor(el);
        var context = ko.contextFor(el);
        var sourceItems = this.getData(source, this.LIST_KEY);
        var onDrag = this.getData(source, this.DRAG_KEY);
        if (onDrag) {
            onDrag.call(context, item, sourceItems);
        }
    }

    static OnRemove(el, container) {
        var item = ko.dataFor(el);
        var sourceItems = this.getData(container, this.LIST_KEY);
        var sourceIndex = sourceItems.indexOf(item);
        var context = ko.contextFor(el);

        sourceItems.splice(sourceIndex, 1);

        var afterDelete = this.getData(container, this.AFTER_DELETE_KEY);
        if (afterDelete) {
            afterDelete.call(context, item, sourceIndex, sourceItems);
        }

        var onDragEnd = this.getData(container, this.DRAG_END_KEY);
        if (onDragEnd) {
            onDragEnd.call(context, item);
        }
    }

    static OnCancel(el, container) {
        if (container) {
            var item = ko.dataFor(el);
            var sourceItems = this.getData(container, this.LIST_KEY);
            var sourceIndex = sourceItems.indexOf(item);

            container.removeChild(el);

            sourceItems.splice(sourceIndex, 1);
            sourceItems.splice(sourceIndex, 0, item);

            var onDragEnd = this.getData(container, this.DRAG_END_KEY);
            if (onDragEnd) {
                onDragEnd.call(ko.contextFor(el), item);
            }
        }
    }

    static OnCloned(clone, original, type) {
        if (type === 'copy') {
            this.copying = true;
            this.cloneBackup = original;
        }
    }
}