export const EVENTS = {
	NO_ACCESS: 'NO_ACCESS',
	CLOSE: 'CLOSE',
	SAVE_AND_CLOSE: 'SAVE_AND_CLOSE',
	DATA_SAVED: 'DATA_SAVED',
	DELETED: 'DELETED',
	AFTER_RENDER: 'AFTER_RENDER',
	ENTITY_SELECTED: 'ENTITY_SELECTED',
	SELECTED: 'SELECTED',
	ENTITY_UNSELECTED: 'ENTITY_UNSELECTED',
	LOOKUP_ENTITY_SELECTED: 'LOOKUP_ENTITY_SELECTED',
	LOOKUP_ENTITY_UNSELECTED: 'LOOKUP_ENTITY_UNSELECTED',
	REFERENCE_LOOKUP_ENTITY_SELECTED: 'REFERENCE_LOOKUP_ENTITY_SELECTED',
	REFERENCE_LOOKUP_ENTITY_UNSELECTED: 'REFERENCE_LOOKUP_ENTITY_UNSELECTED',
	REFERENCE_ENTITY_SELECTED: 'REFERENCE_ENTITY_SELECTED',
	REFERENCE_ENTITY_UNSELECTED: 'REFERENCE_ENTITY_UNSELECTED',
	IS_ITERATOR_CHANGED: 'IS_ITERATOR_CHANGED',
	CREATE_SUB_QUERY: 'CREATE_SUB_QUERY',
	REMOVE_SUB_QUERY: 'REMOVE_SUB_QUERY',
	REMOVE_QUERY: 'REMOVE_QUERY',
	DRAG: 'DRAG',
	DRAG_STOP: 'DRAG_STOP',
	MOUSE_OVER: 'MOUSE_OVER',
	CLICK: 'CLICK',
	JOIN_TYPE_CHANGED: 'JOIN_TYPE_CHANGED',
	COLUMN_SELECTED: 'COLUMN_SELECTED',
	UPDATE_SORT: 'UPDATE_SORT'
};