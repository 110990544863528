import * as ko from "knockout";
import * as _ from "underscore";

import {TagViewModel} from 'Core/Controls/Tag/ViewModels/TagViewModel';

export class TagGroupViewModel {
	private _tags: KnockoutObservableArray<TagViewModel>;
	
	constructor(tags: Array<TagViewModel>, name: string) {
		this._tags = ko.observableArray(tags);
		this.GroupName = name;
	}

	GroupId: number;

	GroupName: string;

	get Tags(): Array<TagViewModel> {
		return this._tags();
	}

	get SelectedTags() {
		return this._tags().filter(tag => tag.IsSelected()).length;
	}
}