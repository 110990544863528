import {P} from "Core/Common/Promise";
import {IRequestOptions, Request} from "Core/Common/Request";
import {URL} from 'Core/Common/Url';

export class LockScreenStore {
	static Unlock(password: string): P.Promise<any>{
		let deferredResult = P.defer<boolean>();
		let data = {
			Password: password
		};
		let requestParams: IRequestOptions = {
			proxy: {
				url: URL.AUTH.UNLOCK_SESSION,
				type: 'POST',
				dataType: 'json'
			}, params: data
		};

		Request.Send(requestParams)
			.then(data => {
				if (!data.IsSuccessfull) {
					deferredResult.reject({ message: data.ErrorMessage});
				} else {
					deferredResult.resolve(data);
				}
			});

		return deferredResult.promise();
	}

	static Lock(): P.Promise<any>{
		let requestParams: IRequestOptions = {
			proxy: {
				url: URL.AUTH.LOCK_SESSION,
				type: 'GET',
				dataType: 'json'
			}
		};

		let deferredResult = P.defer<any>();
		Request.Send(requestParams)
			.then(data => {
				if (!data.IsSuccessfull) {
					deferredResult.reject({ message: data.ErrorMessage });
				} else {
					deferredResult.resolve(data);
				}
			});

		return deferredResult.promise();
	}
}