import { TranslationModel } from "Core/Controls/BaseControl/Models/TranslationModel";
import { deserialize, deserializeAs } from "libs/cerialize";

export class LIfecycleLinkDto {
    @deserialize
    Id: number;

    @deserialize
    ApprovalId: number;

    @deserialize
    Guid: string;

    @deserialize
    GuidStepFrom: string;

    @deserialize
    GuidStepTo: string;

    @deserialize
    IdStepFrom: number;

    @deserialize
    IdStepTo: number;

    @deserialize
    IsNew: boolean;

    @deserialize
    Json: string;

    @deserialize
    Label: string;

    @deserialize
    NextAllowed: boolean;

    @deserialize
    TestCondition: number;

    @deserializeAs(TranslationModel)
    Translations: Array<TranslationModel>

    @deserialize
    UserId: number;

    @deserialize
    StepsScreenId: number;
}