import * as ko from 'knockout';
import {Events} from "Auth/Enums/Events";
import {Event} from "Core/Common/Event";
import {Modal} from "Core/Common/Modal";
import {LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";

import LicenseExpiredSoonFormTemplate from 'Auth/UI/LicenseExpiredSoonForm/Templates/LicenseExpiredSoonForm.html';
ko.templates['Auth/UI/LicenseExpiredSoonForm/Templates/LicenseExpiredSoonForm'] = LicenseExpiredSoonFormTemplate;

export class LicenseExpiredSoonForm extends Event {
	private _modal: Modal;
	private _formTitle: string;
	public _labels = LABELS;
	
	constructor() {
		super();
	}

	public Load(formTitle: string) {
		this._formTitle =  NOTIFICATIONS.LICENSE_WILL_BE_EXPIRED.replace('{date}', formTitle);
		this.Show();
	}

	public IsValid() {
		//return this._$form.valid();
	}

	public Close() {
		if (this._modal){
			this.Trigger(Events.FormClosed);
			this._modal.Close();
		}
	}	

	private Show() {
		this._modal = new Modal({
			width: '400',
			addClass: 'login',
			minHeight: '300'
		}, false);
		this._modal.One('CLOSE', this, () => this.Close());
		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);

		this._modal.Show();
	}

	GetTemplateName(): string {
		return 'Auth/UI/LicenseExpiredSoonForm/Templates/LicenseExpiredSoonForm';
	}

	AfterRender(el: HTMLElement) {
	}

	//Form Event handlers
	private OkButtonClicked() {
		this.Close();
	}
} 