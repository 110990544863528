export const PROPERTIES = {
    REFRESH_SCREEN: 'RefreshScreen'
};

export const SCHEDULER_EVENTS = {
    CREATE_SCREEN: "CreateScreenFromScheduler",
    OPEN_DEFAULT_SCREEN: "OpenDefaultScreen",
    RETURN_TO_AGENDA: "ReturnToAgenda",
    SWITCH_TO_DEFAULT: "SwitchToDefault",
    REPLAN_APPOINTMENT: "ReplanAppointment",
    SET_DATA: "SetData",
};