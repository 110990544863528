import * as ko from "knockout";
import {IButtonConfig} from "Core/Controls/PortletBar/Interfaces/IButtonConfig";
import {ButtonViewModel} from "Core/Controls/PortletBar/Models/Buttons/ButtonViewModel";

import ExpandButtonTemplate from "Core/Controls/PortletBar/Templates/Buttons/Expand/Button.html";
import ExpandPropsTemplate from "Core/Controls/PortletBar/Templates/Buttons/Expand/Props.html";

ko.templates["Core/Controls/PortletBar/Templates/Buttons/Expand/Button"] = ExpandButtonTemplate;
ko.templates["Core/Controls/PortletBar/Templates/Buttons/Expand/Design"] = ExpandButtonTemplate;
ko.templates["Core/Controls/PortletBar/Templates/Buttons/Expand/Props"] = ExpandPropsTemplate;

export class ExpandButtonViewModel extends ButtonViewModel {
	IsExpanded: boolean;

	constructor(buttonName: string, buttonConfig: IButtonConfig) {
		super(buttonName, buttonConfig);
	}
}