import * as ko from 'knockout';

import {GridColumnModel} from 'Core/Controls/Grid/Models/GridDataModel/GridColumnModel';
import {LABELS} from "Core/Components/Translation/Locales";
import {FieldFormat} from 'Core/Common/FieldFormat';

import SearchTemplate from 'Core/Controls/Grid/BaseGrid/FastFilter/Search/FastFilterSearch.html'

export class FastFilterSearch {
	private _element: HTMLElement;
	private _model: GridColumnModel;
	private _searchValue: KnockoutObservable<string>;
	private _changeTimerId: number;
	private _filterType: string;
	private _inputType: string;
	private _labels = LABELS;

	constructor(model: GridColumnModel, callback: any) {
		this._labels = LABELS;
		this._searchValue = ko.observable('');
		this._model = model;
		this._inputType = 'text';

		this._searchValue.subscribe((value) => {
			if (this._changeTimerId) {
				clearTimeout(this._changeTimerId);

				this._changeTimerId = setTimeout(() => {
					this._changeTimerId = null;
					this._searchValue(this.FormatInputValue(value));

					callback(this._searchValue());
				}, 500);
			} else {
				this._changeTimerId = setTimeout(() => callback(value), 600);
			}
		});
	}

	private FormatInputValue(value) {
		switch (this._filterType) {
			case 'datetime':
				if (value > 9999) {
					const newValue = value.toString();

					value = +newValue.substring(0, 4);
				} else if (value < 0) {
					value = '';
				}
		}

		return value;
	}

	// fix zero-length value handling
	AddEventListenerChangeValue(parentEl) {
		const $input = $(parentEl).find('.fast-filter-search-input')[0];

		if (!$input) return;

		$input.onkeyup = () => {
			const actualValue: any = $($input).val();

			if (!actualValue.length) {
				this._searchValue(actualValue);
			}
		};
	}

	GetTemplate(arg?) {
		this._filterType = arg && arg.filterType;

		if (this._filterType === 'datetime') {
			this._inputType = 'number';
		} else {
			this._inputType = 'text';
		}

		return SearchTemplate;
	}

	AfterRender(el: HTMLElement) {
		this._element = el;
		this.AddEventListenerChangeValue(this._element);
	}
}