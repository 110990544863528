import { ConfigStructModel } from "Core/GeneralProperties/Models/ConfigStructModel";
import { ConfigModel } from "Core/GeneralProperties/Models/ConfigModel";
import { IControl } from 'Core/Controls/IControl';

export class GeneralProperties {
	private _configStruct: ConfigStructModel;
	private _isConfigStructOk: boolean;

	private _config: ConfigModel;
	private _isConfigOk: boolean;

	constructor(jsonConfig: any, properties: any, control: IControl) {
		this.Init(jsonConfig, properties, control);
	}

	get ConfigStruct() {
		return this._configStruct;
	}

	get Config() {
		return this._config;
	}

	private Init(jsonConfig: any, properties: any, control: IControl) {
		this.InitConfigStruct(jsonConfig);

		this.InitConfig(properties, control);
	}

	private InitConfigStruct(jsonConfig: any) {
		try {
			this._configStruct = jsonConfig ? ConfigStructModel.Deserialize(jsonConfig) : null;
			this._isConfigStructOk = true;
		} catch (e) {
			this._configStruct = new ConfigStructModel();
			console.error(e.message);
			this._isConfigStructOk = false;
		}
	}

	private InitConfig(properties: any, control: IControl) {
		if (!this._configStruct || !this._isConfigStructOk) {
			return;
		}

		this._config = new ConfigModel(this._configStruct, properties, control);
	}
}   