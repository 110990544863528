import {PathRunnerRecordModel} from "Core/Components/PathRunner/Models/Response/PathRunnerRecordModel";
import {PathRunnerNodeViewModel} from "Core/Components/PathRunner/Models/View/PathRunnerNodeViewModel";

export class PathRunnerNodeViewModelMapping {
	static MapFromResponse(record: PathRunnerRecordModel) {
		if (!record) {
			return null;
		}

		const nodeViewModel = new PathRunnerNodeViewModel();

		nodeViewModel.Id = record.Id;
		nodeViewModel.Name = record.Name || "-";
		nodeViewModel.TranslatedName = record.TranslatedName;
		nodeViewModel.TypeId = record.TypeId;
		nodeViewModel.TypeName = record.TypeName;
        nodeViewModel.TypeTranslatedName = record.TypeTranslatedName;
        nodeViewModel.LifeStatusId = record.LifeStatusId;
		nodeViewModel.LifeStatusName = record.LifeStatusName;
		nodeViewModel.LifeStatusGroup = record.LifeStatusGroup;
		nodeViewModel.IsSubject = record.IsSubject;
		nodeViewModel.HasChildren = record.HasChildren;

		const children = record.Children.map(childRecord => this.MapFromResponse(childRecord));
		nodeViewModel.AddRange(children);

		return nodeViewModel;
	}

	static Copy(record: PathRunnerNodeViewModel) {
		const nodeViewModel = new PathRunnerNodeViewModel();

		nodeViewModel.Id = record.Id;
		nodeViewModel.Name = record.Name;
		nodeViewModel.TranslatedName = record.TranslatedName;
		nodeViewModel.TypeId = record.TypeId;
		nodeViewModel.TypeName = record.TypeName;
        nodeViewModel.TypeTranslatedName = record.TypeTranslatedName;
        nodeViewModel.LifeStatusId = record.LifeStatusId;
		nodeViewModel.LifeStatusName = record.LifeStatusName;
		nodeViewModel.LifeStatusGroup = record.LifeStatusGroup;
		nodeViewModel.IsSubject = record.IsSubject;
		nodeViewModel.IsInitialNode = record.IsInitialNode;

		const children = record.Children.map(childRecord => this.Copy(childRecord));
		nodeViewModel.AddRange(children);

		return nodeViewModel;
	}
}