

import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize} from 'libs/cerialize';
import {OperationResultModel} from 'Core/Models/OperationResultModel';
import {ViewModes} from 'Core/Controls/Grid/BaseGrid/Enums/ViewModes';
import {NewQueryDto} from 'QueryBuilder/Models/NewQueryDto';

export interface ICreateNewQueryRequestModel {
	TypeName: ViewModes;
	SubjectEntityId: number;
	GridSubjectEntityId: number;
	Name: string;
	QueryText: String;
};

export class NewQueryStore {
	static New(name: string): P.Promise<NewQueryDto> {

		var deferredResult = P.defer<NewQueryDto>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.QUERY_BUILDER.NEW_QUERY,
				type: 'GET'
			},
			params: { name : name }
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(<NewQueryDto>Deserialize(data, NewQueryDto));
			}).fail(data => { 
				let jsonResult = JSON.parse(data.message);
				deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			 });;

		return deferredResult.promise();
	}

	static Create(params: ICreateNewQueryRequestModel): P.Promise<OperationResultModel> {

		var deferredResult = P.defer<OperationResultModel>();
		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.QUERY_BUILDER.CREATE_QUERY,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};
		Request.Send(requestParams)
			.then(data => deferredResult.resolve(this.Deserialize(data)));

		return deferredResult.promise();
	}

	static Deserialize(jsonObject: any): OperationResultModel {
		const model = <OperationResultModel>Deserialize(jsonObject, OperationResultModel);
		return model;
	}
} 