import * as ko from 'knockout';
import * as _ from 'underscore';

import {FieldMetadataModel} from 'Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel';
import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

export class FieldListItem {
	private _isSelected: KnockoutObservable<boolean>;

	constructor(private _model: FieldMetadataModel, isSelected: boolean = false) {
		this._isSelected = ko.observable(isSelected);
	}

	@enumerable get Name(): string{
		return this._model.NameTranslation || this._model.Name;
	}

	@enumerable get Model(): FieldMetadataModel {
		return this._model;
	}

	@enumerable get IsSelected(): KnockoutObservable<boolean> {
		return this._isSelected;
	}
}