import {TranslationModel} from 'Core/Controls/BaseControl/Models/TranslationModel';
import {serializeAs} from 'libs/cerialize';
import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel';
import {IconModel} from "Core/Controls/BaseControl/Models/IconModel";

export class ControlEditorModel {
	constructor() {
		this.NameTranslations = [];
		this.DescriptionTranslations = [];
		this.AttachedFields = [];
	}
	Name: string;
	NameMaxSize: number;
	FieldName: string;
    FieldNameTranslation: string;
    EntityName: string;
	F_Type: number;
	TypeName: string;
    TypeTranslatedName: string;
    K_Control: number;
	FieldId: number;
	ValTableId: number;
	ValFieldId: number;
	SubFormId: number;
	Memo: string;
	MaskId: number;
	HasParentControl: boolean;
	ParentControlId: number;
	ParenControlType: string;
	ParentControlSubFormId: number;
	LabelPos: number;
	LabelPosName: string;
	Properties: string;
	EntityId: number;
	SubTableId: number;
	RecordLifeStatusId: number;
	DestinationTypeId: number;
	Description: string;
	Icon: IconModel;
	@serializeAs(TranslationModel)
	NameTranslations: Array<TranslationModel>;
    @serializeAs(TranslationModel)
    DescriptionTranslations: Array<TranslationModel>;
    UseFieldName: boolean;
    TitleFieldId: number;
    KanbanSubjectId: number;
    TodoEntityId: number;
    TodoTypeId: number;
    TodoFieldId: number;

	AttachedFields: Array<AttachedFieldModel>;

	ScreenId: number;
}