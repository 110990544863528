import {P} from 'Core/Common/Promise';
import {Request} from 'Core/Common/Request';
import {Deserialize, GenericDeserialize} from 'libs/cerialize';
import {QueryExpressionModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';

import {GridDataModel} from 'Core/Controls/Grid/Models/GridDataModel/GridDataModel';
import {SortModel} from 'Core/Controls/Grid/Models/SortModel';

export interface IGetTabGridDataParams {
    TableId: number;
    TargetTableId: number;
    LookupFieldId: number;
    RecordId: number;
    PageNumber: number;
    RecordsPerPage: number;
    TableViewId: number;
    Sort: Array<SortModel>;
    ScopedRecordId: number;
    ScopedTableId: number;
}


export interface IGetLinkEditorDefaultTableViewDto {
    TableId: number;
    TargetTableId: number;
    LookupFieldId: number;
}

export class TabGridStore {
    GetGridData(params: IGetTabGridDataParams): P.Promise<GridDataModel> {
        const deferred = P.defer<GridDataModel>();

        Request.Send({
            proxy: {
                type: 'POST',
                dataType: 'json',
                url: '/api/GridApi/GetLinkEditorGridData'
            },
            params
        }).then(gridData => {
            deferred.resolve(GenericDeserialize(gridData, GridDataModel));
        }).fail(error => {
            deferred.reject(this.GetResponseError(error, 'Error reading grid data'));
        });

        return deferred.promise();
    }


    GetDefaultTableView(params: IGetLinkEditorDefaultTableViewDto): P.Promise<QueryExpressionModel> {
        const deferred = P.defer<QueryExpressionModel>();

        Request.Send({
            proxy: {
                type: 'GET',
                dataType: 'json',
                url: '/api/GridApi/GetLinkEditorDefaultTableView'
            },
            params
        }).then(gridData => {
            deferred.resolve(GenericDeserialize(gridData, QueryExpressionModel));
        }).fail(error => {
            deferred.reject(this.GetResponseError(error, 'Error get default table view'));
        });

        return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string) {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}