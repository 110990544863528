import * as ko from 'knockout';
import * as _ from 'underscore';

import {Event} from 'Core/Common/Event';
import {Modal} from 'Core/Common/Modal';
import {EntityTypesStore} from 'Core/Screens/TypeScreen/Stores/EntityTypesStore';
import {TableTypesModel} from 'Core/Screens/TypeScreen/Models/TableTypesModel';
import {TableTypeModel} from 'Core/Screens/TypeScreen/Models/TableTypeModel';
import {BlockUI} from 'Core/Common/BlockUi';
import {ExampleRecordModel} from 'Core/Screens/TypeScreen/Models/ExampleRecordModel';
import {Notifier} from 'Core/Common/Notifier';
import {EVENTS} from 'Core/Screens/TypeScreen/Events';
import {NOTIFICATIONS, LABELS} from "Core/Components/Translation/Locales";

import SearchScreenTemplate from 'Core/Screens/TypeScreen/Templates/TypeScreen.html'
import {KindModel} from "./Models/KindModel";

ko.templates['Core/Screens/TypeScreen/Templates/TypeScreen'] = SearchScreenTemplate;

export class TypeScreen extends Event {
    private _modal: Modal;
    private _model: KnockoutObservable<TableTypesModel>;
    private _entityId: number;
    private _entityName: string;
    private _parentTypeId: number;
    private _withRoot: boolean;
    private _selectedType: KnockoutObservable<TableTypeModel>;
    private _selectedKind: KnockoutObservable<KindModel>;
    private _selectedExample: KnockoutObservable<ExampleRecordModel>;
    private _kinds: KnockoutObservableArray<KindModel>;
    private _examples: KnockoutObservableArray<ExampleRecordModel>;
    private _noExample: ExampleRecordModel;
    private _allowExamples: KnockoutObservable<boolean>;
    private _isSaveDisabled: KnockoutObservable<boolean>;
	private _isOkButtonEnabled: KnockoutComputed<boolean>;
	private _showKindSelector: KnockoutComputed<boolean>;
    private _showExampleSelector: KnockoutComputed<boolean>;
    private _onlyTypes: Array<string | Partial<TableTypeModel>>;
    private _labels = LABELS;

    constructor(entityId: number,
                parentTypeId: number = 0,
                withRoot: boolean = false,
                allowExamples: boolean = true,
                entityName: string = null,
                onlyTypes?: Array<string | Partial<TableTypeModel>>) {
        super();

        this._entityId = entityId;
        this._entityName = entityName;
        this._parentTypeId = parentTypeId;
        this._withRoot = withRoot;
        this._model = ko.observable(null);
        this._selectedType = ko.observable(null);
        this._selectedExample = ko.observable(null);
        this._selectedKind = ko.observable(null);
        this._kinds = ko.observableArray([]);
        this._examples = ko.observableArray([]);
        this._noExample = new ExampleRecordModel();
        this._noExample.Id = 0;
        this._noExample.Name = LABELS.NO_EXAMPLE_LABEL;
        this._allowExamples = ko.observable(allowExamples);
        this._isSaveDisabled = ko.observable(false);
		this._isOkButtonEnabled = ko.computed(() => !this._isSaveDisabled());
        this._onlyTypes = onlyTypes;

		this._showKindSelector = ko.computed(() => this._kinds().length > 1);
		this._showExampleSelector = ko.computed(() => this._allowExamples() && this._examples().length > 1);

        this._selectedType.subscribe(newValue => {
            if (newValue) {
                this._kinds(newValue.Kinds);
                const selectedKind = _.first(newValue.Kinds);
                this._selectedKind(selectedKind);

                if (selectedKind && selectedKind.Id === 0) {
                    var examplesByType = _.filter(this._model().ExampleRecords, item => {
                        return item.F_Kind === 0 && item.F_Type === newValue.Id
                    });
                    examplesByType.unshift(this._noExample);
                    this._examples(examplesByType);
                    this._selectedExample(_.first(examplesByType));
				}
            }
        });

        this._selectedKind.subscribe(newValue => {
            if (newValue) {
                const examplesByKind = _.filter(this._model().ExampleRecords, item => item.F_Kind === newValue.Id
					&& item.F_Type === this._selectedType().Id);
                examplesByKind.unshift(this._noExample);
                this._examples(examplesByKind);
                this._selectedExample(_.first(examplesByKind));
            }
        });

        this.Init();
    }

    private Init() {
        this.AddEvent(EVENTS.TYPES_NOT_FOUND);
        this.AddEvent(EVENTS.TYPES_FOUND);
        this.AddEvent(EVENTS.TYPE_SELECTED);
        this.AddEvent(EVENTS.CANCELLED);

        this._modal = new Modal({
            addClass: 'typeScreenModal jBox-padding-10px',
            width: '350',
            minHeight: '300'
        }, false);
    }

    Show() {
        BlockUI.Block();
        EntityTypesStore.GetTypes({
            EntityId: this._entityId,
            ParentTypeId: this._parentTypeId,
            WithRoot: this._withRoot,
            OnlyEnabled: false,
            EntityName: this._entityName
        })
            .always(() => {
                BlockUI.Unblock();
            })
            .then(tableTypesModel => {
                if (!tableTypesModel.TableTypes.length) {
                    this.Trigger(EVENTS.TYPES_NOT_FOUND, {
                        Message: tableTypesModel.WarningMessage
                    });
                    return;
                }

                
                if (this._onlyTypes && this._onlyTypes.length > 0) {
                    tableTypesModel.TableTypes = _.filter(tableTypesModel.TableTypes,
                        type => this._onlyTypes.some(typeToMatch => {
                            if (_.isString(typeToMatch)) {
                                return type.Name === typeToMatch;
                            }

                            return _.isMatch(type, typeToMatch);
                        }));
                }

                tableTypesModel.TableTypes.forEach(type => {
					type.Kinds.unshift(KindModel.CreateDashKind());
					type.Kinds = _.filter(type.Kinds, kind => kind.IsEnabled);
				});

				this._model(tableTypesModel);

				let firstEnabledType = _.first(tableTypesModel.TableTypes);

                if (!firstEnabledType) {
                    firstEnabledType = new TableTypeModel();
                    firstEnabledType.Id = 0;
                    firstEnabledType.Name = '-';
                    firstEnabledType.Kinds = [KindModel.CreateDashKind()];
                }

                this.Trigger(EVENTS.TYPES_FOUND);
                this._selectedType(firstEnabledType);

				let enabledKinds = _.flatten(tableTypesModel.TableTypes.map(type => type.Kinds));

                let examplesByType = _.filter(tableTypesModel.ExampleRecords, item => {
	                return item.F_Type === this._selectedType().Id;
                });

				if (tableTypesModel.TableTypes.length <= 1 && enabledKinds.length <= 1 && (!this._allowExamples() || !_.any(examplesByType))) {
                    this.Trigger(EVENTS.TYPE_SELECTED, {
                        TypeId: this._selectedType().Id,
                        TypeName: this._selectedType().Name,
                        KindId: this._selectedKind().Id,
                        KindName: this._selectedKind().Name,
                        ExampleRecordId: this._noExample.Id,
                        EntityId: tableTypesModel.TableId
                    });
                    return;
                }

                this.Render();
            });
    }

    get Kinds(): KnockoutObservableArray<KindModel> {
        return this._kinds;
    }

    get Examples(): KnockoutObservableArray<ExampleRecordModel> {
        return this._examples;
    }

    get SelectedType(): KnockoutObservable<TableTypeModel> {
        return this._selectedType;
    }

    get TableTypes() {
        return this._model().TableTypes;
    }

    get ShowTypeSelector() {
        return this._model().ShowTypeSelector;
    }

    get SelectedKind(): KnockoutObservable<KindModel> {
        return this._selectedKind;
    }

    get SelectedExample(): KnockoutObservable<ExampleRecordModel> {
        return this._selectedExample;
    }

    GetTemplateName(): string {
        return `Core/Screens/TypeScreen/Templates/TypeScreen`;
    }

    Render(): void {
        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
    }

    SetButtonDisable(enabledTypesExist: boolean) {
        this._isSaveDisabled(!enabledTypesExist);
    }

    SelectExample(item) {
        this._selectedExample(item);
        this.Save();
    }

    Save() {
        if (this._modal) {
            if (this._selectedType().IsEnabled) {
                this.Trigger(EVENTS.TYPE_SELECTED, {
                    TypeId: this._selectedType().Id,
                    TypeName: this._selectedType().Name,
                    KindId: this._selectedKind().Id,
                    KindName: this._selectedKind().Name,
                    ExampleRecordId: this._selectedExample().Id,
                    EntityId: this._model().TableId
                });
                this._modal.Close();
            } else {
                var message = NOTIFICATIONS.TYPE_IS_NOT_AVAILABLE.replace('{TypeName}', this._selectedType().TranslatedName);
                new Notifier().Warning(message);
            }
        }
    }

    Cancel() {
        if (this._modal) {
            this.Trigger(EVENTS.CANCELLED);
            this._modal.Close();
        }
    }

    AfterRender() {
        if (this._modal) {
            this._modal.Show();
        }
    }
}