import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {ChartControlOptions} from '../Models/ChartControlOptions';

import {CONTROL_TYPES} from "Core/Constant";
import {NOTIFICATIONS} from "Core/Components/Translation/Locales";

export class ChartControlEditorStore {

    GetControlOptions() {
        const deferred = P.defer<ChartControlOptions>()

        Request.Send({
            proxy: {
                url: '/api/ChartApi/GetControlOptions',
                type: 'GET',
                dataType: 'json',
            }
        }).then(options => {
            deferred.resolve(options);
        }).fail(err => {
			const generalError = NOTIFICATIONS.ERROR_GETTING_CONTROL_OPTIONS.replace('{controlName}', CONTROL_TYPES.Chart);
			deferred.reject(this.GetResponseError(err, generalError));
        });

        return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}