import * as ko from "knockout";
import {Event} from 'Core/Common/Event';
import {Modal} from 'Core/Common/Modal';
import {NOTIFICATIONS, LABELS} from 'Core/Components/Translation/Locales';

import {CreateRecipeVersionDto} from "./Models/Dto/CreateRecipeVersionDto";
import {RecipeEditorStore} from "./Stores/RecipeEditorStore";
import {IRecipeEditorEditParams, RecipeEditorEdit} from "./RecipeEditorEdit";
import {GetRecipeDataDto} from "./Models/Dto/GetRecipeDataDto";
import {Notifier} from "Core/Common/Notifier";
import {CopyRecipeVersionDto} from "./Models/Dto/CopyRecipeVersionDto";
import {ITypePropertyModel} from "Core/GeneralProperties/Managers/TypesProperty/TypesProperty";
import {BlockUI} from "Core/Common/BlockUi";
import {RecipeDataModel} from "./Models/RecipeDataModel";

import RecipeCreate from "Core/Controls/RecipeEditor/Templates/RecipeCreate.html";
ko.templates["Core/Controls/RecipeEditor/Templates/RecipeCreate"] = RecipeCreate;

export interface ICopyRecipeVersion {
    NewVersionName: string;
    CopiedVersionId: number;
}

export interface IRecipeEditorCreateParams {
    RecordId: number;
    EntityId: number;
    ControlId: number;
    IsRecipeCreated: boolean;
    IsCreateRecipeVersion: boolean;
    IsAddingAllowed: boolean;
    IsEditingAllowed: boolean;
    IsDeletingAllowed: boolean;
    ICopyRecipeVersion: ICopyRecipeVersion;
    TypesProperty?: ITypePropertyModel[];
}

export class RecipeEditorCreate extends Event {
    _labels = LABELS;
    private _modal: Modal;
    private _isRecipeCreated: KnockoutObservable<boolean>
    private _value: KnockoutObservable<string>;
    private _recordId: number;
    private _entityId: number;
    private _controlId: number;
    private _recipeEditorEdit: KnockoutObservable<RecipeEditorEdit>
    private _isCreateRecipeVersion: boolean;
    private _isAddingAllowed: boolean;
    private _isEditingAllowed: boolean;
    private _isDeletingAllowed: boolean;
    private _copyRecipeVersion: ICopyRecipeVersion;
    private _typesProperty: ITypePropertyModel[];

    constructor(params: IRecipeEditorCreateParams) {
        super();
        this._copyRecipeVersion = params.ICopyRecipeVersion;
        this._isRecipeCreated = ko.observable(null);
        this._value = ko.observable(this._copyRecipeVersion ? this._copyRecipeVersion.NewVersionName: '');
        this._recordId = params.RecordId;
        this._entityId = params.EntityId;
        this._controlId = params.ControlId;
        this._recipeEditorEdit = ko.observable(null);
        this._isCreateRecipeVersion = params.IsCreateRecipeVersion;
        this._isAddingAllowed = params.IsAddingAllowed;
        this._isEditingAllowed = params.IsEditingAllowed;
        this._isDeletingAllowed = params.IsDeletingAllowed;
        this._typesProperty = params.TypesProperty ? params.TypesProperty : null;
        this.Init(params.IsRecipeCreated);
        this.AddEvent("RECIPE_EDITOR_CREATE");
        this.AddEvent("RECIPE_EDITOR_VERSION");
        this.AddEvent("COPY_RECIPE_EDITOR_VERSION");
        this.AddEvent("RECIPE_EDITOR_EDIT_CLOSE");
    }

    Init(isRecipeCreated: boolean) {
        this._isRecipeCreated(isRecipeCreated);
    }

    GetTemplateName() {
        return 'Core/Controls/RecipeEditor/Templates/RecipeCreate';
    }

    AfterRender(el: HTMLElement): void{

    }

    ShowInModal(){
        let self = this;
        this._modal = new Modal({
            addClass: 'recipeEditorCreateModal',
            width: '400px',
            height: '167px',
            closeOnEsc: false,
            onOpen: function () {
                $(this.closeButton[0]).off('click');
                this.closeButton[0].addEventListener('click', self.Close.bind(self, false));
            }
        }, false);

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
        this._modal.Show();
    }

    Close(){
        if (this._modal) {
            this._modal.Close();
            this.Off('RECIPE_EDITOR_CREATE');
        }
    }

    CreateClick(data, event){
        if (this._value().length >= 4){
            BlockUI.Block({Target: event.currentTarget});
            if (this._copyRecipeVersion){
                let copyRecipeParams: CopyRecipeVersionDto = {
                    NewVersionName: this._value(),
                    CopiedVersionId: this._copyRecipeVersion.CopiedVersionId,
                    RootRecordId: this._recordId,
                    RootEntityId: this._entityId,
                    ControlId: this._controlId
                }
                RecipeEditorStore.CopyRecipeVersion(copyRecipeParams)
                    .always(() => {
                        BlockUI.Unblock(event.currentTarget);
                    })
                    .then((result:RecipeDataModel) => {
                        this.Trigger('COPY_RECIPE_EDITOR_VERSION', result);
                        this.Close();
                    })
                    .fail(error => {
                        new Notifier().Failed(JSON.parse(error.message).Message);
                    })
            } else {
                let createRecipeParams: CreateRecipeVersionDto = {
                    VersionName: this._value(),
                    RootRecordId: this._recordId,
                    RootEntityId: this._entityId,
                    ControlId: this._controlId
                }

                RecipeEditorStore.CreateRecipeVersion(createRecipeParams)
                    .always(() => {
                        BlockUI.Unblock(event.currentTarget);
                    })
                    .then((result: RecipeDataModel) => {
                        let params: IRecipeEditorEditParams = {
                            EntityId: this._entityId,
                            RecordId: result.RecipeRecord.Id,
                            ControlId: this._controlId,
                            IsAddingAllowed: this._isAddingAllowed,
                            IsEditingAllowed: this._isEditingAllowed,
                            IsDeletingAllowed: this._isDeletingAllowed,
                            TypesProperty: this._typesProperty
                        }
                        if (!this._isCreateRecipeVersion){
                            this._recipeEditorEdit(new RecipeEditorEdit(params));
                            this._recipeEditorEdit().On('RECIPE_EDITOR_EDIT_CLOSE', this, (handler)=> {
                                this.Trigger('RECIPE_EDITOR_EDIT_CLOSE');
                            })
                            this.Trigger('RECIPE_EDITOR_CREATE');
                        } else if (this._isCreateRecipeVersion){
                            this.Trigger('RECIPE_EDITOR_VERSION', result);
                        }
                        this.Close();

                    })
                    .fail(error => {
                        new Notifier().Failed(JSON.parse(error.message).Message);
                    })
            }
        } else {
            new Notifier().Failed('Minimum length of this field is 4');
        }
    }

    CancelClick(){
        this.Close();
    }
}