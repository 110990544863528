import {OnViewModelMappings} from './OnViewModelMappings';
import {OnDtoModelMappings} from './OnDtoModelMappings';

export class GanttChartMappings {
    private _onViewModel: OnViewModelMappings;
    private _onDtoModel: OnDtoModelMappings;

    constructor() {
        this._onViewModel = new OnViewModelMappings();
        this._onDtoModel = new OnDtoModelMappings();
    }

    get OnViewModel() {
        return this._onViewModel;
    }

    get OnDtoModel() {
        return this._onDtoModel;
    }
}