import * as _ from 'underscore';
import * as moment from 'moment';

import {DATE_FORMATS} from 'Core/Constants/DateTimeFormats';

import {Assignment, Task} from 'ganttChart';

import {TaskDto} from '../Stores/Models/TaskDto';
import {AssignmentDto} from '../Stores/Models/AssignmentDto';
import {DependencyDto} from '../Stores/Models/DependencyDto';
import { TaskParentDto } from '../Stores/Models/TaskParentDto';

export class OnDtoModelMappings {
    AsTasksDto(tasks: Task<number>[]): TaskDto[] {
        const dtos = tasks.map(task => this.AsTaskDto(task));
        this.ResolveDependencies(dtos, tasks);

        return dtos;
    }

    AsTaskDto(task: Task<number>): TaskDto {
        const dto = new TaskDto();

        if(task.parentEntity && task.parentRecordId){
            let taskParent = new TaskParentDto();
            taskParent.Entity = task.parentEntity;
            taskParent.Id = task.parentRecordId;
            dto.Parent = taskParent;
        }

        dto.Entity = task.entity;
        dto.Id = task.id;
        dto.Name = task.name;
        dto.Duration = task.duration;
        dto.Level = task.level;
        dto.Starting = this.AsUtc(task.start);
        dto.Ending = this.AsUtc(task.end);
        dto.StartIsMilestone = task.startIsMilestone;
        dto.EndIsMilestone = task.endIsMilestone;
        dto.Completeness = task.progress / 100;
        dto.Assignments = this.AsAssigneesDto(task.assigs);

        return dto;
    }

    private AsAssigneesDto(assignments: Assignment<string, number, number>[]): AssignmentDto[] {
        return assignments.map(assignment => this.AsAssigneeDto(assignment));
    }

    private AsAssigneeDto(assignment: Assignment<string, number, number>): AssignmentDto {
        const dto = new AssignmentDto();

        dto.Id = assignment.resourceId;
        dto.Entity = assignment.entity;
        dto.RoleId = assignment.roleId;
        dto.Effort = assignment.effort / 60 / 60 / 1000;
        return dto;
    }

    private ResolveDependencies(taskDtos: TaskDto[], tasks: Task<number>[]) {
        tasks.forEach(task => {
            if (!task.depends) return;

            const taskDto = _.find(taskDtos, d => d.Id === task.id && d.Entity === task.entity);

            const dependencyStrings = task.depends.split(',');
            dependencyStrings.forEach(dependencyString => {
                const depSplit = dependencyString.split(':');
                const depIndex = +depSplit[0] - 1;
                const startAfterDays = depSplit[1] && +depSplit[1];

                const depTaskDto = taskDtos[depIndex];
                const dependency = this.AsDependencyDto(depTaskDto.Id, depTaskDto.Entity, startAfterDays);

                taskDto.Dependencies.push(dependency);
            });
        });
    }

    private AsDependencyDto(id: number, entity: string, startAfterDays: number) {
        const dto = new DependencyDto();

        dto.Entity = entity;
        dto.RecordId = id;
        dto.StartAfterDays = startAfterDays;

        return dto;
    }

    private AsUtc(milliseconds: number): string {
        return moment(milliseconds).set({hours: 0, minutes: 0, seconds: 0}).utcOffset(0, true).format();
    }
}