import * as ko from 'knockout';

import {ColumnEditor} from "LookupEditor/ColumnEditors/Base/ColumnEditor";

import ImageTemplate from "LookupEditor/Templates/Columns/NonEditableImage.html";
import {IValueUpdater} from "../../Interfaces/IValueUpdater";

ko.templates["LookupEditor/Templates/Columns/NonEditableImage"] = ImageTemplate;


export class NonEditableImageColumnEditor extends ColumnEditor {

	AfterRender(el, owner: IValueUpdater, columnName): void {
	}

	GetTemplateName(): string {
		return "LookupEditor/Templates/Columns/NonEditableImage";
	}

	ToString() {
		return "NonEditableImage";
	}
} 