import * as moment from 'moment';
import 'inputmask';

import {GlobalManager, GLOBALS} from 'Core/GlobalManager/GlobalManager';
import {DATE_FORMATS} from 'Core/Constants/DateTimeFormats';
import {FieldFormat} from 'Core/Common/FieldFormat';
import {Format} from '../DatabaseDesigner/FieldConfig';

export class FormatConverter {
    static SetLocale(): void {
        moment.locale(GlobalManager.Instance.GetCulture());
    }

    static GetLocale(): string {
        return GlobalManager.Instance.GetCulture();
    }

    static ConvertFromDefaultFormat(initialDate: string, toFormat: string): string {
        if (!initialDate) return null;

        const _format = toFormat || DATE_FORMATS.SHORT_DATE.Format;

        let outputDate = moment(initialDate).isValid() ? moment(initialDate).format(_format) : initialDate;

        if (!moment(outputDate).isValid() && !moment('2018-08-28T' + outputDate).isValid()) {
            outputDate = moment(initialDate, DATE_FORMATS.SAVE_FORMAT.Format).format(_format);
        }

        return outputDate;
    }

    static ConvertToDefaultFormat(initialDate: string, initialFormat: string) {
        if (!initialDate) return null;

        return moment(initialDate, initialFormat).format(DATE_FORMATS.SAVE_FORMAT.Format);
    }

    static ConvertToUTC(initialDate: string, initialFormat: string) {
        if (!initialDate) return null;

        return moment(initialDate, initialFormat).format();
    }

    static ConvertDecimal(value: string): string {
        if (!value) return null;

        const n = 1.1;
        const separator: string =
            n.toLocaleString(FormatConverter.GetLocale()).substring(1, 2);

        return value.replace(/[.,]/g, separator);
    }

    static LocalizeDecimalOrInteger(value: string) {
        if (!value) {
            return null;
        }

        return Inputmask.format(value.replace(/[.,]/g, '.'), {
            alias: 'decimal',
            radixPoint: FormatConverter.GetSeparator(),
            autoGroup: true,
            groupSeparator: FormatConverter.GetGroupSeparator()
        });
    }

    static GetSeparator() {
        const culture = FormatConverter.GetLocale();
        const n = 1.1;
        return n.toLocaleString(culture).substring(1, 2);
    }

    static GetGroupSeparator() {
        const culture = FormatConverter.GetLocale();
        const n = 11111.1;
        const formatted = n.toLocaleString(culture);
        const [groupSeparator] = /\D/.exec(formatted);

        return groupSeparator;
    }

    static GetDateLocalizedSeparator() {
        const formatter = new Intl.DateTimeFormat(FormatConverter.GetLocale(), {month: '2-digit', day: '2-digit'});
        return formatter.format(new Date("11/11/1111")).replace(/1/g, "") || "-";
    }

    private static GetISODateFormat(formatName: string, formatType: string): string {
        let format;
        switch (formatName) {
            case FieldFormat.MMM_YYYY:
            case FieldFormat.DD_MM:
            case FieldFormat.DD_MMM:
            case FieldFormat.MM_YY:
            case FieldFormat.DD_MMM_YYYY:
            case FieldFormat.YYYY_WW: {

                const formatEnum = FieldFormat.GetFormat(formatName);
                format = DATE_FORMATS[formatEnum][formatType].replace(/-/g, FormatConverter.GetDateLocalizedSeparator());
                break;
            }
            default:
                break;
        }
        return format;
    }

    static GetDateFormatFromFieldModel(model, returnFormatWithLocale: boolean = true, extendedModel?): string {
        if (!model) {
            return;
        }

        const formatType = returnFormatWithLocale ? 'MomentFormat' : 'Format';

        const fieldType = model.Type || model.FieldTypeName;

        if (!fieldType) {
            return DATE_FORMATS.SHORT_DATE.MomentFormat;
        }

        const formatName = model.FormatName || model.FieldTypeFormat;

        let format;

        switch (fieldType) {
            case FieldFormat.DATE: {
                format = FormatConverter.GetISODateFormat(formatName, formatType);
                if (format) break;

                if (FieldFormat.IsLongDate(formatName)) {
                    format = DATE_FORMATS.LONG_DATE[formatType];
                } else {
                    format = DATE_FORMATS.SHORT_DATE[formatType];
                }
                break;
            }
            case FieldFormat.DATETIME:
                format = FormatConverter.GetISODateFormat(formatName, formatType);
                if (format) break;

                if (FieldFormat.IsLongDate(formatName)) {
                    format = DATE_FORMATS.FULL_DATETIME[formatType];
                } else {
                    format = DATE_FORMATS.DATE_TIME[formatType];
                }

                break;
            case FieldFormat.TIME:
                if (FieldFormat.IsLongTime(formatName)) {
                    format = DATE_FORMATS.LONG_TIME[formatType];
                } else {
                    format = DATE_FORMATS.TIME[formatType];
                }

                break;
            case FieldFormat.TIMESPAN:
                if (FieldFormat.IsLongTime(formatName)) {
                    format = DATE_FORMATS.TIME_SPAN_LONG[formatType];
                } else {
                    format = DATE_FORMATS.TIME_SPAN[formatType];
                }

                break;
            default:
                break;
        }

        return format;
    }

    static CorrectTimezone(date: string, reverseShift?: boolean): string {

        if ((moment(date) as any)._tzm) {
            return date;
        } else {
            return this.ShiftTimeZone(date, reverseShift);
        }
    }

    static ShiftTimeZone(date: string, reverseShift?: boolean): string {
        let timeZoneShift = moment(date).utcOffset() / 60;

        if (!moment(date).isValid() || !isNaN(+date)) {
            return date;
        }

        if (reverseShift) {
            timeZoneShift *= -1;
        }

        return moment(date).add(timeZoneShift, 'hours').format();
    }

    static FormatToDay(date: string, format?: string): any {
        let correctDate = moment(this.CorrectTimezone(date)).hours(0).minutes(0).seconds(0);
        if (format) {
            return correctDate.format(format);
        }
        return correctDate;
    }
}