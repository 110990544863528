import * as ko from 'knockout';
import * as _ from 'underscore';
import * as moment from 'moment';

import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';

import {YesNo} from 'Core/ProfilePage/PersonalSettings/PersonalSettingsEditors/YesNo/YesNo';
import {Text} from 'Core/ProfilePage/PersonalSettings/PersonalSettingsEditors/Text/Text';
import {Time} from "Core/ProfilePage/PersonalSettings/PersonalSettingsEditors/Time/Time";
import {UserManager} from "User/UserManager";
import {GlobalManager} from 'Core/GlobalManager/GlobalManager';
import {GlobalModel} from 'Core/GlobalManager/Models/GlobalModel';
import {GLOBALS} from 'Core/GlobalManager/GlobalManager';
import {LOCAL_STORAGE} from 'Core/Common/Enums/LocalStorageItems';
import {NOTIFICATIONS, LABELS, CONFIRMATIONS} from "Core/Components/Translation/Locales";


import {PersonalSettingsStore} from 'Core/ProfilePage/PersonalSettings/Stores/PersonalSettingsStore';
import {PersonalSettingsModel} from 'Core/ProfilePage/PersonalSettings/Models/PersonalSettingsModel';

import PersonalSettingsTemplate from 'Core/ProfilePage/PersonalSettings/Templates/PersonalSettings.html';
import enumerable from '../../Common/Decorators/EnumerableDecorator';

ko.templates['Core/ProfilePage/PersonalSettings/Templates/PersonalSettings'] = PersonalSettingsTemplate;

export class PersonalSettings {
    private _el: HTMLElement;
    private _models: KnockoutObservableArray<PersonalSettingsModel>;
    private _controls: KnockoutObservableArray<any>;
    private _hasChanges: KnockoutObservable<boolean>;
    private _databaseName: string;
    private _saveButtonLabel: string;
    private _labels = LABELS;


    constructor() {
        this._el = null;
        this._models = ko.observableArray([]);
        this._controls = ko.observableArray([]);
		this._hasChanges = ko.observable(false);
		this._databaseName = UserManager.Instance.CurrentUser.DbName;
        this._saveButtonLabel = LABELS.SAVE;
        this.Init();
    }

    Init() {
        BlockUI.Block();
        PersonalSettingsStore.GetPersonalSettingsModel()
            .always(() => BlockUI.Unblock())
            .then((model: any) => {
                this._models(model);
                this._models().map((model) => {
                    switch (model.TypeName) {
                        case 'Text':
                            this._controls.push(new Text(model, this.changesHadler, this));
                            break;
                        case 'Integer':
                            this._controls.push(new Text(model, this.changesHadler, this));
                            break;
                        case 'YesNo':
                            this._controls.push(new YesNo(model, this.changesHadler, this));
                            break;
                        case 'Time':
                            this._controls.push(new Time(model, this.changesHadler, this));
                            break;
                        default:
                            this._controls.push(new Text(model, this.changesHadler, this));
                    }

                });
            }).fail(error => new Notifier().Failed(error.message));
    }

    GetTemplateName() {
        return 'Core/ProfilePage/PersonalSettings/Templates/PersonalSettings';
    }


    changesHadler() {
        this._hasChanges(true);
    }

    @enumerable get Controls(): KnockoutObservableArray<any> {
        return this._controls;
    }

    Update() {
        this._hasChanges(false);
        BlockUI.Block();
        const dataToUpdate = this.Controls()
            .filter((control) => {
            return control.HasChanges();
        }).map(control => (
            {
                Id: control.Id,
                Value: typeof control.Value() === 'boolean' ? Number(control.Value()) : control.Value()
            }
        ));

        PersonalSettingsStore.UpdateGlobals(dataToUpdate)
            .always(() => BlockUI.Unblock())
            .then(() => {
                dataToUpdate.map((global) => {
                    const globalName = _.where(this._models(), {Id: global.Id})[0].Name;
                    GlobalManager.Instance.AddGlobal({Name: globalName, Value: global.Value} as GlobalModel);
                });
            });

        this.SaveLanguage();
        new Notifier().Success(NOTIFICATIONS.GLOBALS_UPDATED);
    }

	SaveLanguage() {
		const languageControl = _.find(this.Controls(), control => control.Name === GLOBALS.DESKTOP_LANGUAGE);

		if (!languageControl || !languageControl.HasChanges()) {
			return;
		}

		var language = Lockr.get<any>(LOCAL_STORAGE.LANGUAGE);
		if (language) {
			try {
				language = JSON.parse(language);
			} catch (err) {
				language = {};
			}
		} else {
			language = {};
		}

		language[this._databaseName] = languageControl.Value();
		Lockr.set(LOCAL_STORAGE.LANGUAGE, JSON.stringify(language));
	}

    AfterUpload(file, data) {

    }

    AfterRender(el: Array<HTMLElement>) {
        this._el = el[0];
    }
}