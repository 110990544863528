import * as ko from 'knockout';
import * as _ from 'underscore';

import { DropdownDataStore } from 'Core/Controls/Dropdown/Stores/DropDownDataStore';
import { DropdownListModel } from 'Core/Controls/Dropdown/Models/DropdownListModel';
import { GlobalManager, GLOBALS } from 'Core/GlobalManager/GlobalManager';
import { SearchScreen } from 'Core/Screens/SearchScreen/SearchScreen';
import { EVENTS as SEARCH_SCREEN_EVENTS } from 'Core/Screens/SearchScreen/Events';
import { FieldMetadataModel } from 'Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel';

import Template from 'QueryBuilder/QueryCondition/ConditionEditors/Lookup/Templates/Lookup.html'
import {DropdownItemModel} from 'Core/Controls/Dropdown/Models/DropdownListModel';

export class Lookup {
	private _items: KnockoutObservableArray<DropdownItemModel>;
	private _selectedItem: KnockoutObservable<DropdownItemModel>;
	private _showSearchScreenButton: KnockoutObservable<boolean>;
	private _searchScreenIfRecordsCount: number;

	private _fieldId: FieldMetadataModel;
	private _firstLoad: boolean;

	Value: KnockoutObservable<string>;

	constructor(
		field: FieldMetadataModel
	) {
		this._fieldId = field;
		this.Value = ko.observable(null);
		this._selectedItem = ko.observable(null);
		this._showSearchScreenButton = ko.observable(false);
		this._items = ko.observableArray([]);
		this._firstLoad = true;

		var searchScreenIfRecordsCount = parseInt(GlobalManager.Instance.GetGlobal(GLOBALS.SEARCH_SCREEN_IF_RECORDS_COUNT));
		this._searchScreenIfRecordsCount = Number.isNaN(searchScreenIfRecordsCount) ? 10 : searchScreenIfRecordsCount;

		this.Value.subscribe((newValue) => {
			if (this._firstLoad) {
				this.LoadData();
			}
		});

		this._selectedItem.subscribe((newValue: DropdownItemModel) => {
			if (newValue) {
				this.Value(`${newValue.RecordId}`);
			}
		});
	}

	LoadData() {
		DropdownDataStore.Get({ FieldId: this._fieldId.Id, RecordId: parseInt(this.Value()), SubjectRecordId: 0, SubjectTypeId: 0, SubjectKindId: 0, SubjectStatusId: 0, IsEnableFilter: false })
			.then((dropDownData: DropdownListModel) => {
				this._showSearchScreenButton(dropDownData.RecordsCount > this._searchScreenIfRecordsCount);
				this._firstLoad = false;
				this._items(dropDownData.Items);
				this._selectedItem(_.find(dropDownData.Items,(item: DropdownItemModel) => {
					return item.RecordId === parseInt(this.Value());
				}));
		});
	}

	GetTemplate() {
		return Template;
	}

	AfterRender(el: Array<HTMLElement>) {
	}

	get FieldId(): number {
		return this._fieldId.Id;
	}

	ShowSearchScreen() {
		const searchScreen = new SearchScreen({ EntityId: this._fieldId.ValTableId, SearchTerm: '', ButtonAdd: false });
		searchScreen.On(SEARCH_SCREEN_EVENTS.RECORD_SELECTED, this, (eventArgs) => {
			this.Value(eventArgs.data.RecordId);
			this.LoadData();
		});
		searchScreen.Show();
	}
} 