import * as ko from 'knockout';
import * as _ from 'underscore';

import {LABELS} from 'Core/Components/Translation/Locales';

import {BaseProductGroup} from "./BaseProductGroup";

import {BaseProduct} from "./BaseProduct";
import {ProductPart} from "./ProductPart";
import {BreadCrumbsItem} from "./BreadCrumbsItem";

import {TranslationModel} from "Core/Controls/BaseControl/Models/TranslationModel";

import {
    ConfigurationPageEvents,
    ProductPartSelectingEventArgs,
    ConversionsDropdownArgs
} from "../Events/ConfigurationPageEvents";

import Template from '../Templates/RootGroup.html';

import {
    CONVERSION_DROPDOWN_EVENTS,
    ConversionDropdown
} from "../../../Components/ConversionDropdown/ConversionDropdown";
export class RootGroup extends BaseProductGroup {
    private _labels = LABELS;
    private _clickedAlternativeBtn: KnockoutObservable<boolean>;

    BreadCrumbs: KnockoutObservableArray<BreadCrumbsItem>;

    HasProducts: KnockoutComputed<boolean>;

    ConversionDropdowns: ConversionDropdown;

    constructor(id: number, name: string, TranslatedName: string, Translations: TranslationModel[], addAsExtra: boolean, parentProduct: BaseProduct) {
        super(id, name, TranslatedName, Translations, addAsExtra, parentProduct);

        this.BreadCrumbs = ko.observableArray([]);

        this.HasProducts = ko.computed(() => this.Products().length > 0);

        this._clickedAlternativeBtn = ko.observable(null);

        this.ConversionDropdowns = new ConversionDropdown();
    }

    GetConversionDropdown(): ConversionDropdown {
        return this.ConversionDropdowns;
    }
    GetIsInitConversionDropdown(): boolean {
        return this.ConversionDropdowns.IsInit
    }

    get ClickedAlternativeBtn(): boolean {
        return this._clickedAlternativeBtn();
    }

    get IsRoot() {
        return true;
    }

    get IsExtra() {
        return false;
    }

    get IsUndefined() {
        return false;
    }

    GetTemplate() {
        return Template;
    }

    ToggleBody() {
        if (!this.Expanded()) {
            this.Expand();
        } else {
            this.Collapse();
        }
    }

    Expand() {

        if (this.GetIsInitConversionDropdown()){
            if (this.ConversionDropdowns.DropDownParams.length){
                if (!this.conversionDropdownLoaded) {
                    this.DispatchEvent<RootGroup>(ConfigurationPageEvents.NotInitRootGroupExpanded, this);
                    return;
                }

                this.DispatchEvent<RootGroup>(ConfigurationPageEvents.RootGroupExapanding, this);
                // this.CanShowMore(false);
                this.Expanded(true);
                return;
            }
        }
        if (this.productsLoaded && this.conversionDropdownLoaded && !this.ConversionDropdowns.DropDownParams.length && !this.GetActiveProduct()) {
            this.DispatchEvent<RootGroup>(ConfigurationPageEvents.RootShowMore, this);
            this.Expanded(true);
            return;
        }

        if (!this.productsLoaded) {
            this.DispatchEvent<RootGroup>(ConfigurationPageEvents.NotInitRootGroupExpanded, this);
            return;
        }

        if (!this.GetActiveProduct() && this.AlternativesCount > 0 && this.Products().length == 1) {
            this.DispatchEvent<RootGroup>(ConfigurationPageEvents.RootShowMore, this);
        }

        if (this.GetActiveProduct() && !this.ExtendedView() || !this.GetActiveProduct() && this.ExtendedView()) {
            this.ToggleView();
        }

        this.DispatchEvent<RootGroup>(ConfigurationPageEvents.RootGroupExapanding, this);
        this.Expanded(true);
    }

    Collapse() {
        this.Expanded(false);
        this.CollapseActiveNestedGroup();
    }

    AfterInit() {
        this.BindEvents();
    }

    Dispose() {
        this.Products().forEach(product => product.Dispose());
        super.Dispose();
    }

    private BindEvents() {
        this.ConversionDropdowns.On(CONVERSION_DROPDOWN_EVENTS.SELECTED, this, (eventArgs: any) => {
            let args = {
                _eventArgs: eventArgs,
                _this: this
            }
            this.DispatchEvent<ConversionsDropdownArgs>(ConfigurationPageEvents.RootGroupConversionsDropdown,
                new ConversionsDropdownArgs(args))
        })

        this.ConversionDropdowns.On(CONVERSION_DROPDOWN_EVENTS.SYNCHRONIZATION_VALUE, this, (eventArgs: any) => {
            let args = {
                _eventArgs: eventArgs,
                _this: this
            }
            this.DispatchEvent<ConversionsDropdownArgs>(ConfigurationPageEvents.RootGroupConversionsDropdownSynchronization,
                new ConversionsDropdownArgs(args))
        })

        this.ConversionDropdowns.On(CONVERSION_DROPDOWN_EVENTS.SYNCHRONIZATION_RESET, this, (eventArgs: any) => {
            let args = {
                _eventArgs: eventArgs,
                _this: this
            }
            this.DispatchEvent<ConversionsDropdownArgs>(ConfigurationPageEvents.RootGroupConversionsDropdownSynchronization,
                new ConversionsDropdownArgs(args))
        })

        this.HandleEvent<ProductPartSelectingEventArgs>(ConfigurationPageEvents.ProductPartSelecting)
            .When(eventArgs => this.HasProduct(eventArgs.Data.NewProduct))
            .Using(() => this.UnSelectActiveProduct())
            .Always();

        this.HandleEvent<ProductPart>(ConfigurationPageEvents.ProductPartSelected)
            .When(eventArgs => eventArgs.Data.RootGroupIs(this))
            .Using(eventArgs => {
                const breadCrumbsItem = new BreadCrumbsItem(eventArgs.Data.Id, eventArgs.Data.Name, eventArgs.Data.NameTranslated, eventArgs.Data.ParentGroup.Id, eventArgs.Data.ParentGroup.Name, eventArgs.Data.Path);
                this.AddToBreadCrumbs(breadCrumbsItem);
            })
            .Always();

        this.HandleEvent<ProductPart>(ConfigurationPageEvents.ProductPartUnSelected)
            .When(eventArgs => eventArgs.Data.RootGroupIs(this))
            .Using(eventArgs => {
                const breadCrumbsItem = new BreadCrumbsItem(eventArgs.Data.Id, eventArgs.Data.Name, eventArgs.Data.NameTranslated, eventArgs.Data.ParentGroup.Id, eventArgs.Data.ParentGroup.Name, eventArgs.Data.Path);
                this.RemoveFromBreadCrumbs(breadCrumbsItem);
            })
            .Always();
    }

    AddBreadCrumbs(breadCrumbsItem: BreadCrumbsItem[]) {
        this.BreadCrumbs.push(...breadCrumbsItem);
    }

    AddToBreadCrumbs(breadCrumbsItem: BreadCrumbsItem) {
        const existingBreadCrumb = this.GetBreadCrumb(breadCrumbsItem.Id, breadCrumbsItem.Path);

        if (existingBreadCrumb) {
            return;
        }

        this.BreadCrumbs.push(breadCrumbsItem);
    }

    RemoveFromBreadCrumbs(breadCrumbsItem: BreadCrumbsItem) {
        const dependencies = this.GetBreadCrumbsDependenciesOf(breadCrumbsItem);
        dependencies.forEach(dependency => this.RemoveFromBreadCrumbs(dependency));

        const index = _.findIndex(this.BreadCrumbs(),
            item => item.Id === breadCrumbsItem.Id && item.GroupId === breadCrumbsItem.GroupId && item.GroupName === breadCrumbsItem.GroupName && item.Path && _.isEqual(item.Path, breadCrumbsItem.Path));

        if (index > -1) {
            this.BreadCrumbs.splice(index, 1);
        }
    }

    private GetBreadCrumb(productId: number, productPath: number[]) {
        return _.find(this.BreadCrumbs(), breadCrumbItem => breadCrumbItem.Id === productId && _.isEqual(breadCrumbItem.Path, productPath));
    }

    private GetBreadCrumbsDependenciesOf(breadCrumbsItem: BreadCrumbsItem): BreadCrumbsItem[] {
        return this.BreadCrumbs().filter(breadCrumbsItemDependency => breadCrumbsItem.Id === _.last(breadCrumbsItemDependency.Path));
    }

    ShowMore(clickedAlternativeBtn?: boolean) {
        this._clickedAlternativeBtn(clickedAlternativeBtn);

        // if (this.ExtendedView() && this.Products().length > 1) {
        if (this.ExtendedView() && this.Products().length > 1 && this._clickedAlternativeBtn()) {
            this.ToggleView();
        } else {
            this.DispatchEvent<RootGroup>(ConfigurationPageEvents.RootShowMore, this);
        }
    }
}