import * as _ from 'underscore';

import {PropertyEvaluationContext} from "../Enums/PropertyEvaluationContext";

export class PropertyEvaluationExpression {
    constructor(public Id: number,
                public Destination: string,
                public Expression: string,
                public SkipEvaluation?: boolean,
                public SkipError?: boolean) {

    }

    HasEvaluationContext() {
        return _.any([PropertyEvaluationContext.$this, PropertyEvaluationContext.$parent, PropertyEvaluationContext.$root], context => this.Expression.indexOf(context) > -1);
    }

    HasVariableDestination() {
        return this.Destination.startsWith('$')
            && !this.Destination.startsWith(`${PropertyEvaluationContext.$this}.`)
            && !this.Destination.startsWith(`${PropertyEvaluationContext.$parent}.`)
            && !this.Destination.startsWith(`${PropertyEvaluationContext.$root}.`)
            && !this.Destination.startsWith(`${PropertyEvaluationContext.$main}.`);
    }
}