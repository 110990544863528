import * as ko from 'knockout';

import { Modal } from "Core/Common/Modal";
import { Event } from 'Core/Common/Event';
import { MailAddressModel } from "Core/Controls/Mail/Models/MailAddressModel";
import { MailStore, IAddMailAddressRequestModel } from "Core/Controls/Mail/Stores/MailStore";
import { Notifier} from 'Core/Common/Notifier';
import { BlockUI } from 'Core/Common/BlockUi';
import { ADD_EMAIL_EVENTS } from "Core/Controls/Mail/Events";

import AddEmailAddressTemplate from "Core/Controls/Mail/Templates/AddMailAddress.html"
import enumerable from '../../Common/Decorators/EnumerableDecorator';
ko.templates["Core/Controls/Mail/Templates/AddMailAddress"] = AddEmailAddressTemplate;

export class AddEmailAddress extends Event {
	private _modal: Modal;
	private _recordId: number;
	private _entityId: number;
	private _model: KnockoutValidatedObservable<MailAddressModel>;

	constructor(recordId: number, entityId: number) {
		super();
		this._modal = new Modal();
		this._recordId = recordId;
		this._entityId = entityId;
		this._model = ko.validatedObservable(new MailAddressModel());
		this.InitValidation();
		this.AddEvent(ADD_EMAIL_EVENTS.EMAIL_SAVED);
	}

	InitValidation() {
		ko.validation.init({ insertMessages: false }, true);
	}

	GetTemplateName() {
		return 'Core/Controls/Mail/Templates/AddMailAddress';
	}

	@enumerable get Model(): KnockoutValidatedObservable<MailAddressModel> {
		return this._model;
	}

	@enumerable get MailAddress(): string {
		return this._model().MailAddress();
	}

	ShowInModal() {
		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);
		this._modal.Show();
	}

	AfterRender() { }

	Save() {
		if (this.IsValid()) {
			var requestModel: IAddMailAddressRequestModel = {
				RecordId: this._recordId,
				EntityId: this._entityId,
				MailAddress: this._model().MailAddress()
			};

			BlockUI.Block();
			MailStore.AddMailAddress(requestModel)
				.always(() => {
					BlockUI.Unblock();
				})
				.fail((err) => {
					var notifier = new Notifier(null);
					notifier.Failed(err.message);
				})
				.then((result) => {
					this.Trigger(ADD_EMAIL_EVENTS.EMAIL_SAVED, { EmailAddress: this._model().MailAddress() });
					this.Close();
				});
		}
	}

	Close() {
		this._modal.Close();
	}

	IsValid() {
		if (!this._model.isValid()) {
			this._model.errors.showAllMessages();
		}

		return this._model.isValid();
	}
}