import clone from 'clone';

import { CollectionModel } from "DatabaseDesigner/Models/CollectionModel";
import { ScreenModel } from "DatabaseDesigner/Models/ScreenModel";
import { TableBaseModel } from "DatabaseDesigner/Models/TableBaseModel";
import { TableTypeModel } from "DatabaseDesigner/Models/TableTypeModel";
import { TranslationModel } from "DatabaseDesigner/Models/TranslationModel";
import { TranslationModel as MemoTranslationModel} from "Core/Controls/BaseControl/Models/TranslationModel";
import { LanguageModel } from "Core/Controls/BaseControl/Models/LanguageModel";

export function TableModel() {
            this._enableDefaultSecurity = false;
            this._subjectAreaId = 0;
            this._subTypeId = 0;
            this._sort = 0;
            this._types = new CollectionModel({model: TableTypeModel});
            this._screens = new CollectionModel({model: ScreenModel});
            this._subTables = new CollectionModel({model: TableBaseModel});
            this._translations = new CollectionModel({model: TranslationModel});
            this._memo = "";
            this._memoTranslations = new CollectionModel({model: TranslationModel});

            TableBaseModel.apply(this);
        };

        TableModel.prototype = Object.create(TableBaseModel.prototype);
        TableModel.prototype.constructor = TableModel;
        TableModel.prototype.__super__ = TableBaseModel;

        TableModel.prototype.FromJSONArray = function (tables) {
            var tableList = new CollectionModel({model: TableModel});
            _.each(tables, function (table) {
                var tableModel = new TableModel();
                tableModel.FromJSON(table);
                tableList.Add(tableModel);
            });
            return tableList;
        }

        TableModel.prototype.Clone = function () {
            return clone(this);
        }

        TableModel.prototype.FromJSON = function (data) {
            var self = this;
            this._subTypeId = data.SubTypeId;
            this._sort = data.Sort;
            this.__super__.prototype.FromJSON.call(this, data);
            this._types = TableTypeModel.prototype.FromJSONArray(data.Types);
            this._subTables = TableBaseModel.prototype.FromJSONArray(data.SubTables);
            this._screens = ScreenModel.prototype.FromJSONArray(data.Screens);
            this._translations = TranslationModel.prototype.FromJSONArray(data.Translations);
            this._memo = data.Memo;
            this._memoTranslations = data.MemoTranslations;

           _.each(this.Types.Items, function (type: any) {
                if (type._parentId !== 0) {
                    var model = _.find(self.Types.Items, parentType => parentType.Id === type._parentId && parentType !== type) as any;
                    if (model) {
                        type.ParentGuid = model.Guid;
                        type.UniqueParentGuid = model.UniqueGuid;
                    }
                }
            });

            _.each(this.Fields.Items, function (field: any) {
                _.each(field.TableFiels.Items, function (tableFiel: any) {
                    var tableType = _.find(self.Types.Items, function (type: any) {
                        return type.Id === tableFiel.TableTypeId
                    });
                    if (tableType) {
                        tableFiel.TableTypeGuid = tableType.Guid;
                    }
                });
            });
        };

        TableModel.prototype.toJSON = function (onlyModified) {
            var result = this.__super__.prototype.toJSON.call(this, onlyModified);
            if (!result) {
                result = {};
            }
            result.ShortName = this.ShortName;
            result.Sort = this.Sort;
            result.TypeId = this.TypeId;
            result.SubTypeId = this._subTypeId;
            result.IsModified = this._isModified;
            result.EnableDefaultSecurity = this._enableDefaultSecurity;
            result.Types = this.Types.toJSON(onlyModified),
                result.Translations = this.Translations.toJSON(),
                result.SubTables = this.SubTables.toJSON(onlyModified);
            result.MemoTranslations = this.MemoTranslations;
            result.Memo = this.Memo;
            window['DatabaseDesigner'].IsModified(this._isModified);
            if (this.SubjectAreaId !== 0)
                result.SubjectAreaId = this.SubjectAreaId;

            if (onlyModified && onlyModified === true) {
                if (this._isModified !== true) {
                    result = null;
                }
            }
            return result;
        }


        TableModel.prototype.CreateDefaultTranslations = function (languages) {
            if (languages) {
                var self = this;
                self._translations = new CollectionModel({model: TranslationModel});

                _.forEach(languages, function (language) {
                    var translation = new TranslationModel();
                    translation.Language = language;
                    self._translations.Add(translation);
                });
            }
        }


        TableModel.prototype.CreateMemoTranslations = function (languages) {
            if (languages) {
                var self = this;
                self._memoTranslations = [];

                _.forEach(languages, function (language: LanguageModel) {
                    var translation = new MemoTranslationModel();
                    translation.Language = new LanguageModel(language.K_Language, language.Name, language.ShortName, language.Enabled, language.FlagBase64);
                    translation.Translation = "";
                    self._memoTranslations.push(translation);
                });
            }
        };

        Object.defineProperty(TableModel.prototype, 'EnableDefaultSecurity', {
            enumerable: true,
            get: function () {
                return this._enableDefaultSecurity;
            },
            set: function (val) {
                this._enableDefaultSecurity = val;
            }
        });

        Object.defineProperty(TableModel.prototype, 'SubTypeId', {
            enumerable: true,
            get: function () {
                return this._subTypeId;
            },
            set: function (val) {
                this._subTypeId = val;
            }
        });

        Object.defineProperty(TableModel.prototype, 'SubTables', {
            enumerable: true,
            get: function () {
                return this._subTables;
            },
            set: function (val) {
                this._subTables = val;
            }
        });

        Object.defineProperty(TableModel.prototype, 'Types', {
            enumerable: true,
            get: function () {
                return this._types;
            },
            set: function (val) {
                this._types = val;
            }
        });

        Object.defineProperty(TableModel.prototype, 'Sort', {
            enumerable: true,
            get: function () {
                return this._sort;
            },
            set: function (val) {
                this._sort = val;
            }
        });

        Object.defineProperty(TableModel.prototype, 'SubjectAreaId', {
            enumerable: true,
            get: function () {
                return this._subjectAreaId;
            },
            set: function (val) {
                this._subjectAreaId = val;
            }
        });

        Object.defineProperty(TableModel.prototype, 'Screens', {
            enumerable: true,
            get: function () {
                return this._screens;
            },
            set: function (val) {
                this._screens = val;
            }
        });


        Object.defineProperty(TableModel.prototype, 'Translations', {
            enumerable: true,
            get: function () {
                return this._translations;
            },
            set: function (val) {
                this._translations = val;
            }
        });

        Object.defineProperty(TableModel.prototype, 'Memo', {
            enumerable: true,
            get: function () {
                return this._memo;
            },
            set: function (val) {
                this._memo = val;
            }
        });

        Object.defineProperty(TableModel.prototype, 'MemoTranslations', {
            enumerable: true,
            get: function () {
                return this._memoTranslations;
            },
            set: function (val) {
                this._memoTranslations = val;
            }
        });