import { deserialize } from 'libs/cerialize';

export class AutoLinkedRecordModel {
	@deserialize
	RecordId: number;

	@deserialize
	EntityId: number;

	@deserialize
	Name: string;

	@deserialize
	NameTranslation: string;

	@deserialize
	EntityRecordId : number;

	constructor(partial?: Partial<AutoLinkedRecordModel>) {
		if (partial) {
			this.RecordId = partial.RecordId;
			this.EntityId = partial.EntityId;
			this.Name = partial.Name;
			this.NameTranslation = partial.NameTranslation
			this.EntityRecordId = partial.EntityRecordId;
		}
	}

	static Create(recordId: number, entityId: number, name: string, nameTranslation: string, entityRecordId: number) {
		return new AutoLinkedRecordModel({
			RecordId: recordId,
			EntityId: entityId,
			Name: name,
			NameTranslation: nameTranslation,
			EntityRecordId: entityRecordId
		});
	}
}