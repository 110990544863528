import {deserialize, GenericDeserialize} from "libs/cerialize";

export class ColorPaletteItem {
    @deserialize
    Name: string;

    @deserialize
    Value: string;

    static Deserialize(json: any): Array<ColorPaletteItem> {
        return Array.prototype.map.call(json, item => GenericDeserialize(item, ColorPaletteItem));
    }
}