import {EventBusConsumer} from "Core/Common/EventBus/EventBusConsumer";

import {RootProductsPageEvents} from "../Events/RootProductsPageEvents";
import {ImageTableTypes} from "../../../Components/ProductImageViewer/Enums/ImageTableTypes";
import { ImagePreloader } from "../../../../../../Common/Image";
import { LABELS } from "Core/Components/Translation/Locales";

import Template from '../Templates/Product.html';

export class Product extends EventBusConsumer {
    private _imageSize: number;
    private _imageSizeClassName: string;
    _labels: LABELS;
    public Image: string;

    constructor(public Id: number,
                public Name: string,
                public NameTranslated: string,
                image: string,
                public Description: string,
                public GroupId: number,
                public GroupName: string) {
        super();
                        
        this.Image = image ? `api/FilesApi/GetImage/${image}` : null;

        ImagePreloader.Preload(this.Image);

        this._imageSize = null;
        this._imageSizeClassName = null;
        this._labels = LABELS;
    }

    set ImageSize(value: number) {
        this._imageSize = value;
    }

    get ImageSizeClassName(): string{
        return this.GetImageSizeClassName();
    }

    GetImageSizeClassName(){
        switch (this._imageSize) {
            case 60:
                return this._imageSizeClassName = 'imageSize-60';
            case 70:
                return this._imageSizeClassName = 'imageSize-70';
            case 80:
                return this._imageSizeClassName = 'imageSize-80';
            case 90:
                return this._imageSizeClassName = 'imageSize-90';
            default:
                return this._imageSizeClassName = 'imageSize-100';
        }
    }

    get ImageTableType() {
	    return ImageTableTypes.Product;
    }

    GetTemplate() {
        return Template;
    }

    OnClick() {
        this.Select();
    }

    Select() {
        this.DispatchEvent<Product>(RootProductsPageEvents.Selected, this);
    }
}