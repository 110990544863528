import { MessageDto } from './MessageDto';
import { deserialize, deserializeAs } from "libs/cerialize";

export class PromptDto {
    constructor(){
        this.Messages = []; 
    }
    @deserializeAs(MessageDto)
    Messages: Array<MessageDto>;
}
