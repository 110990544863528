import * as ko from 'knockout';
import * as _ from 'underscore';
import {RecordModel, LinkModel, StepModel, ActionItemModel} from 'Core/Controls/Kanban/Models/StatusModel';

import MainLinksListTemplate from 'Core/Controls/Kanban/Templates/Default/MainLinksListTemplate.html';
import NoMainLinksTemplate from 'Core/Controls/Kanban/Templates/Default/NoMainLinksTemplate.html';

import { NOTIFICATIONS } from "Core/Components/Translation/Locales";

export class KanbanStep {
	private _records: KnockoutObservableArray<RecordModel>;
	private _links: Array<LinkModel>;
	private _isCollapsed: KnockoutObservable<boolean>;

	private _model: StepModel;
	constructor(step: StepModel, links: Array<LinkModel>) {
		this._records = ko.observableArray(step.Records);

		this._isCollapsed = ko.observable(false);
		this._model = step;
		this._links = links;

	}

	get ActionItems(): Array<ActionItemModel> {
		return this._model && this._model.ActionItems;
	}

	get Records(): KnockoutObservableArray<RecordModel> {
		return this._records;
	}

	get Model(): StepModel {
		return this._model && this._model;
	}

	get IsRetired(): boolean {
		return this._model.IsRetired;
	}

	get Memo(): string {
		return this._model.Memo;
	}

	get MemoTranslation(): string {
		return this._model.MemoTranslation;
	}

	get TranslatedName(): string {
		return this._model.TranslatedName || this._model.Label;
	}

	get Label(): string {
		return this._model.Label;
	}

	get Id(): number {
		return this._model.Id;
    }

    get MultipleActions(): boolean {
        return this._model.MultipleActions;
    }

	AddRecord(record: RecordModel) {
		this._model.Records.push(record);
		this._records.push(record);
	}

	ClearRecords() {
		this._model.Records = [];
		this._records([]);
	}

	public HasNextStatus(currentStatus: number): boolean {
		var nextStatus = _.find(this._links, (link) => { return link.IdStepFrom === currentStatus });
		if (nextStatus) {
			return true;
		}
		return false;
	}

	get IsCollapsed(): boolean {
		return this._isCollapsed();
	}

	set IsCollapsed(isCollapsed: boolean) {
		this._isCollapsed(isCollapsed);
	}

	GetTestTemplate(record: RecordModel) {
		if (record.MainLinks.length === 0) {
			return NoMainLinksTemplate;
		} else {
			return MainLinksListTemplate;
		}
	}

	Copy() {
		return new KanbanStep(this._model.Copy(), [...this._links]);
	}
}