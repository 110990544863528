import * as _ from 'underscore';
import * as ko from 'knockout';

import {SearchResultRowModel} from 'Core/Screens/SearchScreen/Models/SearchResultModel';
import {SearchResultGrid} from 'Core/Screens/SearchScreen/SearchResultGrid/SearchResultGrid';
import {SearchResultCell} from "Core/Screens/SearchScreen/SearchResultGrid/SearchResultCell";
import {SearchFieldModel} from "Core/Screens/SearchScreen/Models/SearchFieldModel";
import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

export class SearchResultRow {
    private _model: SearchResultRowModel;
    private _subGrid: KnockoutObservable<SearchResultGrid>;
    private _toggleButtonClassName: KnockoutComputed<string>;
    private _cells: Array<SearchResultCell>;
    private _columns: Array<SearchFieldModel>;
    public IsSelected: KnockoutObservable<boolean>;
    public IsSelectedDuplicatesRecord: KnockoutObservable<boolean>;
    public IsDisableDuplicateRecord: KnockoutObservable<boolean>;
    Expanded: KnockoutObservable<boolean>;
    RowClick: KnockoutObservable<boolean>;

    constructor(model: SearchResultRowModel, columns: Array<SearchFieldModel>){
        this.IsSelected = ko.observable(false);
        this.RowClick = ko.observable(false);
        this.IsSelectedDuplicatesRecord = ko.observable(false);
        this.IsDisableDuplicateRecord = ko.observable(false);
        this._model = model;
        this._subGrid = ko.observable(null);
        this.Expanded = ko.observable(false);
        this._cells = [];
        this._columns = columns;
        this._toggleButtonClassName = ko.computed(()=>{
            return this.Expanded() ? 'fa-minus-circle' : 'fa-plus-circle';
        });
        this.Init();
    }

    private Init(){
        _.each(this._columns, (column) =>{
           let columnValue = _.find(this._model.RowValues, (item) =>{
                return item.Key === column.Name;
           });

           if(columnValue){
               this._cells.push(new SearchResultCell(columnValue.Value, column.Id));
           }
        });
    }

    @enumerable get HasChildren(): boolean {
        return this._model.HasChildren;
    }

    @enumerable get RecordId(): number{
        return this._model.RecordId;
    }

    set SubGrid(subGrid: SearchResultGrid){
        this._subGrid(subGrid);
    }

    @enumerable get Model(): SearchResultRowModel{
        return this._model;
    }
}