import * as ko from "knockout";
import {Modal} from "Core/Common/Modal";
import {Event} from "Core/Common/Event";
import { LABELS } from "Core/Components/Translation/Locales";
import {BlockUI} from 'Core/Common/BlockUi';
import { InexItem, InexProduct } from "../../Models/InexItem";
import { ProductPart } from "../../Models/ProductPart";

import ColorPalettePopupTemplate from './Templates/ColorPalettePopupView.html';
import {
	EVENTS
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { ColorModel } from "../../Models/Properties/Controls/ColorPropertyControl/Models/ColorModel";
import { ImageModel } from "../../Models/Properties/Controls/Image/Models/ImageModel";
import { ProductImageViewer } from '../../../../Components/ProductImageViewer/ProductImageViewer';

ko.templates["./Templates/ColorPalettePopupView"] = ColorPalettePopupTemplate;

const TEMPLATES = {
	VIEW: "./Templates/ColorPalettePopupView"
};

export interface IColorPalettePopupOptions {
	Colors: ColorModel[];
	SelectedColor: ColorModel;
	Images: ImageModel[];
	SelectedImage: ImageModel;
	ImagePropertyViewer?: ProductImageViewer;
	Width?: string;
	Height?: string;
	MinHeight?: number;
	TextDecline?: string;
	Timeout?: number;
	ModalClass?: string;
	blockScroll?: boolean;
	closeButton?: any;
}

export class ColorPalettePopup extends Event {
	private _labels = LABELS;
	private _modal: Modal;
	private _text: KnockoutObservable<string>;
	private ProductId: number; 
	private _imagePropertyViewer: ProductImageViewer;
	private _textConfirm: KnockoutObservable<string>;
	private _textDecline: KnockoutObservable<string>;
	private _options: IColorPalettePopupOptions;
	private _modalClass: string;

	Colors: KnockoutObservableArray<ColorModel>;
	Images: KnockoutObservableArray<ImageModel>;
	SelectedColor: KnockoutObservable<ColorModel>;
	SelectedImage: KnockoutObservable<ImageModel>;

	constructor(options: IColorPalettePopupOptions) {
		super();
		this._options = options;
		this._imagePropertyViewer = options.ImagePropertyViewer;
		this.Colors = ko.observableArray(options.Colors);
		this.SelectedColor = ko.observable(options.SelectedColor);
		this.Images = ko.observableArray(options.Images);
		this.Images.sort(function (a, b) {
			if(a.DisplayName < b.DisplayName) { return -1; }
			if(a.DisplayName > b.DisplayName) { return 1; }
			return 0;
		});
		this.SelectedImage = ko.observable(options.SelectedImage);

		this.Init();
	}

	private Init() {
		this._textDecline = ko.observable(this._options.TextDecline || LABELS.CANCEL);
		this._modalClass = '';
	}

	private InitModal() {
		this._modal = new Modal({
			width: this._options.Width || '405',
			minHeight: this._options.Height || '200',
			closeButton: this._options.closeButton ? this._options.closeButton : false,
			closeOnEsc: false,
			addClass: this._options.ModalClass === undefined ? '' : this._options.ModalClass + "",
			appendTo: 'body:first',
			blockScroll: this._options.blockScroll || false
		}, false);
	}

	Show() {
		this.InitModal();
		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);
	}

	GetTemplateName() {
		return TEMPLATES.VIEW;
	}

	OnClick(item: ColorModel | ImageModel) {
		this.Trigger(EVENTS.CONFIRM_SELECTED, item);
		this.Close();
	}

	OnCancelClick() {
		this.Close();
	}

	Close() {
		this._modal.Close();
	}

	private AfterRender() {
		this._modal.Show();
		BlockUI.Unblock();
	}
}