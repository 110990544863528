import {FIELD_TYPES} from "Core/Constant";

import {IControl} from "Core/Controls/IControl";
import {EntityModel} from "Core/Screens/DesignScreen/ControlEditor/Models/EntityModel";
import {FieldControlEditor} from "Core/Screens/DesignScreen/ControlEditor/Editors/FieldControlEditor";

export class CurrencyControlEditor extends FieldControlEditor {
    constructor(control: IControl) {
        super(control);
    }

    protected FilterEntities(entities: EntityModel[]) {
        let filteredEntities = [];

        for (let entity of entities) {
            const filteredFields = entity.Fields.filter(field => field.FieldTypeName === FIELD_TYPES.Currency);

            if (filteredFields.length > 0) {
                entity.Fields = filteredFields;
                filteredEntities.push(entity);
            }
        }

        return super.FilterEntities(filteredEntities);
    }
}