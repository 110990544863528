import * as ko from 'knockout';

import {
	NotificationDesignerModel,
	EntityModel
} from 'Core/ProfilePage/NotificationDesigner/Models/NotificationDesignerModel';
import {
	EntityViewModel,
	EntityActionModel
} from 'Core/ProfilePage/NotificationDesigner/Models/NotificationDesignerViewModel';
import {NotificationDesignerStore} from 'Core/ProfilePage/NotificationDesigner/Stores/NotificationDesignerStore';
import {NOTIFICATION_ACTIONS} from 'Core/ProfilePage/NotificationDesigner/Constants/Constants';
import {Notifier} from 'Core/Common/Notifier';
import {BlockUI} from 'Core/Common/BlockUi';
import {
	ConfirmationDialog,
	EVENTS as CONFIRMATION_EVENTS,
	Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';

import NotificationDesignerTemplate from 'Core/ProfilePage/NotificationDesigner/Templates/NotificationDesigner.html';
import {LABELS} from "Core/Components/Translation/Locales";

ko.templates['Core/ProfilePage/NotificationDesigner/Templates/NotificationDesigner'] = NotificationDesignerTemplate;

export class NotificationDesigner {
	private _initialEntities: EntityModel[];
	private _entities: KnockoutObservableArray<EntityViewModel>;
	private _notificationActions: any[];
	private _saveModel: NotificationDesignerModel;
	private _hasUnsavedChanges: KnockoutObservable<boolean>;
	private _isListNotEmpty: KnockoutComputed<boolean>;
	private _labels = LABELS;

	constructor() {
		this._entities = ko.observableArray([]);
		this._notificationActions = NOTIFICATION_ACTIONS.GET(this._labels);
		this._hasUnsavedChanges = ko.observable(false);
		this._isListNotEmpty = ko.computed(() => !!this._entities().length);

		this.Init();
	}

	Init() {
		BlockUI.Block();

		NotificationDesignerStore.GetSettings()
			.then((data) => {
				this._initialEntities = data.Settings.map((el) => el);
				this._entities(this.MapToViewModel(data.Settings));
			})
			.fail((error) => {
				new Notifier().Failed(error.message);
			})
			.always(() => {
				BlockUI.Unblock();
			});
	}

	MapToViewModel(model: EntityModel[]): EntityViewModel[] {
		function convertDecToViewValue(decimal: number, maxBinLength?: number): string[] {
			const binValue = decimal.toString(2).split('').reverse();

			while (binValue.length < maxBinLength) {
				binValue.push('0');
			}

			return binValue;
		}

		const highestWeight = this._notificationActions[this._notificationActions.length - 1].Weight;
		const binValueLength = convertDecToViewValue(highestWeight).length;

		return model.map((entity) => {
			const binViewValue: string[] = convertDecToViewValue(entity.Value, binValueLength);

			const actionsMapped: EntityActionModel[] = binViewValue.map((value, index) => {
				return {
					Weight: this._notificationActions[index].Weight,
					IsChecked: ko.observable(value === '1')
				}
			});

			return {
				Id: entity.EntityId,
				Name: entity.EntityName,
				Actions: actionsMapped
			}
		});
	}

	ChangeSettingsValue(entity, action, newState): boolean {
		action.IsChecked(newState);

		this.CheckChangesExistance();

		return true;
	}

	MapToSaveModel(viewModel: EntityViewModel[]): NotificationDesignerModel {
		const settings = viewModel.map((entity) => {
			const binaryValue =
				entity.Actions
					.map((action) => action.IsChecked() ? '1' : '0')
					.reverse()
					.join('');

			const decValue = Number.parseInt(binaryValue, 2);

			const saveEntity: EntityModel = {
				EntityId: entity.Id,
				EntityName: entity.Name,
				Value: decValue
			};

			return saveEntity;
		});

		return {
			Settings: settings
		};
	}

	Save(): void {
		BlockUI.Block();

		NotificationDesignerStore.SaveSettings(this._saveModel)
			.then((data) => {
				this._hasUnsavedChanges(false);
				this._initialEntities = this._saveModel.Settings;

				new Notifier().Success('Settings saved');
			})
			.fail((error) => {
				this.RevertChanges();

				new Notifier().Failed(error.message);
			})
			.always(() => {
				BlockUI.Unblock();
			});
	}

	CheckChangesExistance(): void {
		this._saveModel = this.MapToSaveModel(this._entities());

		const changedElementsCount = this._saveModel.Settings.filter((el, index) => {
			return el.Value !== this._initialEntities[index].Value;
		}).length;

		this._hasUnsavedChanges(!!changedElementsCount);
	}

	RevertChanges(): void {
		const confirmCallback = () => {
			this._entities(this.MapToViewModel(this._initialEntities));
			this._hasUnsavedChanges(false);
		};

		const discardCallback = () => {
			return;
		};

		const message = 'Are you sure to revert all unsaved changes?';

		this.ConfirmationDialog(message, confirmCallback, discardCallback);
	}

	ConfirmationDialog(message: string, confirmCallback, discardCallback): void {
		const dialog = new ConfirmationDialog({
			Text: message,
			Type: ConfirmationTypes.Question,
			Width: 500,
			MinHeight: 160
		});

		dialog.On(CONFIRMATION_EVENTS.CONFIRM_SELECTED, this, () => {
			confirmCallback();
		});

		dialog.On(CONFIRMATION_EVENTS.DISCARD_SELECTED, this, () => {
			discardCallback();
		});

		dialog.Show();
	}

	GetTemplateName() {
		return 'Core/ProfilePage/NotificationDesigner/Templates/NotificationDesigner';
	}

	AfterRender(el: Array<HTMLElement>) {

	}
}