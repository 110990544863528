import {GenericDeserialize} from 'libs/cerialize';
import {P} from 'Core/Common/Promise';
import {Request} from 'Core/Common/Request';

import {SupportInfoModel} from 'Core/Components/ReportIssueModal/Models/SupportInfoModel';

export class SupportStore {
    static GetSupportInfo(params: { DataBaseName: string }) {
        return Request.Send({
            proxy: {
                url: '/api/SupportApi/GetSupportInfo',
                type: 'GET'
            },
            params
        }).then((data: { IsSuccessfull: boolean; ErrorMessage: string | null; ResultObject: any }) => {
            if (!data.IsSuccessfull) {
                return P.reject<SupportInfoModel>({message: data.ErrorMessage});
            }

            return P.resolve(GenericDeserialize(data.ResultObject, SupportInfoModel));
        });
    }

    static CreateSupportTicket(params) {
        const deferred = P.defer();

        Request.Send({
            proxy: {
                url: '/api/SupportApi/CreateSupportTicket',
                type: 'POST'
            },
            params
        }).then((data: { IsSuccessfull: boolean; ErrorMessage: string | null; ResultObject: any }) => {
            if (data.IsSuccessfull) {
                return deferred.resolve(data.ResultObject);
            }

            return deferred.reject({message: data.ErrorMessage});
        }).fail(error => {
            if (error.status === 400) {
                return deferred.reject({message: JSON.parse(error.message).Message});
            }

            return deferred.reject(error);
        });

        return deferred.promise();
    }
}
