import * as ko from 'knockout';
import 'jquery';
import 'jqueryAutogrow';

export class TextAreAutogrowExtention {
	static Init() {
		ko.bindingHandlers.autogrow = {
			init: (element, valueAccessor, allBindings, viewModel, bindingContext) => {
				var timerId = setInterval(() => {
					if (element.offsetWidth > 0 || element.offsetHeight > 0) {
						($(element) as any).autogrow({ vertical: true, horizontal: false });
						clearInterval(timerId);
					}
				}, 200);
				ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
					clearInterval(timerId);
					const shadowId = $(element).data("shadow-id");
					if (shadowId) {
						$("#" + shadowId).remove();
					}
				});
			}
		};
	}
}