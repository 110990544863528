import {ColumnEditor} from "LookupEditor/ColumnEditors/Base/ColumnEditor";
import {DateColumnEditor} from "LookupEditor/ColumnEditors/Concrete/DateColumnEditor";
import {DateTimeColumnEditor} from "LookupEditor/ColumnEditors/Concrete/DateTimeColumnEditor";
import {BinaryColumnEditor} from "LookupEditor/ColumnEditors/Concrete/BinaryColumnEditor";
import { TimeColumnEditor } from "LookupEditor/ColumnEditors/Concrete/TimeColumnEditor";
import { TimeSpanColumnEditor } from "LookupEditor/ColumnEditors/Concrete/TimeSpanColumnEditor";
import {GuidColumnEditor} from "LookupEditor/ColumnEditors/Concrete/GuidColumnEditor";
import {CurrencyColumnEditor} from "LookupEditor/ColumnEditors/Concrete/CurrencyColumnEditor";
import {DocumentColumnEditor} from "LookupEditor/ColumnEditors/Concrete/DocumentColumnEditor";
import {FlagCheckColumnEditor} from "LookupEditor/ColumnEditors/Concrete/FlagCheckColumnEditor";
import {ImageColumnEditor} from "LookupEditor/ColumnEditors/Concrete/ImageColumnEditor";
import {IntegerColumnEditor} from "LookupEditor/ColumnEditors/Concrete/IntegerColumnEditor";
import {PKeyColumnEditor} from "LookupEditor/ColumnEditors/Concrete/PKeyColumnEditor";
import {MultiSelectColumnEditor} from "LookupEditor/ColumnEditors/Concrete/MultiSelectColumnEditor";
import {ColorColumnEditor} from "LookupEditor/ColumnEditors/Concrete/ColorColumnEditor";
import {LookupColumnEditor} from "LookupEditor/ColumnEditors/Concrete/LookupColumnEditor";
import {IconColumnEditor} from "LookupEditor/ColumnEditors/Concrete/IconColumnEditor";
import {SortColumnEditor} from "LookupEditor/ColumnEditors/Concrete/SortColumnEditor";
import {TextColumnEditor} from "LookupEditor/ColumnEditors/Concrete/TextColumnEditor";
import {TypeColumnEditor} from "LookupEditor/ColumnEditors/Concrete/TypeColumnEditor";
import {YesNoColumnEditor} from "LookupEditor/ColumnEditors/Concrete/YesNoColumnEditor";
import {MemoColumnEditor} from "LookupEditor/ColumnEditors/Concrete/MemoColumnEditor";
import { NonEditableColumnEditor } from "LookupEditor/ColumnEditors/Concrete/NonEditableColumnEditor";
import { NonEditableImageColumnEditor } from "LookupEditor/ColumnEditors/Concrete/NonEditableImageColumnEditor";
import {DecimalColumnEditor} from "LookupEditor/ColumnEditors/Concrete/DecimalColumnEditor";

import {EditableColumnModel} from "LookupEditor/Models/EditableColumnModel";
import {SystemFields} from "LookupEditor/Enums/SystemFields";
import {ColumnTypes} from "LookupEditor/Enums/ColumnTypes";
import {PasswordColumnEditor} from "../ColumnEditors/Concrete/PasswordColumnEditor";
import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

export class ColumnEditorFactory {
    private static allowInstantiation: boolean = false;
    private static instance: ColumnEditorFactory = null;
    private _systemFieldToEditor: { [fieldName: string]: (column: EditableColumnModel) => ColumnEditor };
    private _columnTypeToEditor: { [fieldTypeName: string]: (column: EditableColumnModel) => ColumnEditor };

    constructor() {
        if (ColumnEditorFactory.allowInstantiation) {
            this._systemFieldToEditor = {};
            this._systemFieldToEditor[SystemFields[SystemFields.ENABLED]] = (column: EditableColumnModel) => new YesNoColumnEditor(column);
            this._systemFieldToEditor[SystemFields[SystemFields.SORT]] = (column: EditableColumnModel) => new SortColumnEditor(column);

            this._systemFieldToEditor[SystemFields[SystemFields.CREATEDATE]] = (column: EditableColumnModel) => new NonEditableColumnEditor(column);
            this._systemFieldToEditor[SystemFields[SystemFields.CREATEDBY]] = (column: EditableColumnModel) => new NonEditableColumnEditor(column);
            this._systemFieldToEditor[SystemFields[SystemFields.CHANGEDATE]] = (column: EditableColumnModel) => new NonEditableColumnEditor(column);
            this._systemFieldToEditor[SystemFields[SystemFields.CHANGEDBY]] = (column: EditableColumnModel) => new NonEditableColumnEditor(column);

            this._systemFieldToEditor[SystemFields[SystemFields.F_DICTIONARY]] = (column: EditableColumnModel) => new NonEditableColumnEditor(column);
            this._systemFieldToEditor[SystemFields[SystemFields.REPLICATIONID]] = (column: EditableColumnModel) => new NonEditableColumnEditor(column);

            this._columnTypeToEditor = {};
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.Decimal]] = (column: EditableColumnModel) => new DecimalColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.Date]] = (column: EditableColumnModel) => new DateColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.DateTime]] = (column: EditableColumnModel) => new DateTimeColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.Binary]] = (column: EditableColumnModel) => new BinaryColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.Time]] = (column: EditableColumnModel) => new TimeColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.TimeSpan]] = (column: EditableColumnModel) => new TimeSpanColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.Guid]] = (column: EditableColumnModel) => new GuidColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.Radio]] = (column: EditableColumnModel) => new LookupColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.Currency]] = (column: EditableColumnModel) => new CurrencyColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.Document]] = (column: EditableColumnModel) => new DocumentColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.FlagCheck]] = (column: EditableColumnModel) => new FlagCheckColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.Image]] = (column: EditableColumnModel) => new ImageColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.Integer]] = (column: EditableColumnModel) => new IntegerColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.PKey]] = (column: EditableColumnModel) => new PKeyColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.MultiSelect]] = (column: EditableColumnModel) => new MultiSelectColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.Color]] = (column: EditableColumnModel) => new ColorColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.Lookup]] = (column: EditableColumnModel) => new LookupColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.Icon]] = (column: EditableColumnModel) => new IconColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.Sort]] = (column: EditableColumnModel) => new SortColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.Text]] = (column: EditableColumnModel) => new TextColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.Type]] = (column: EditableColumnModel) => new TypeColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.YesNo]] = (column: EditableColumnModel) => new YesNoColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.Memo]] = (column: EditableColumnModel) => new MemoColumnEditor(column);
            this._columnTypeToEditor[ColumnTypes[ColumnTypes.Password]] = (column: EditableColumnModel) => new PasswordColumnEditor(column);

            ColumnEditorFactory.instance = this;
        } else {
            throw new Error("Instance already initialized");
        }
    }

    static get Instance(): ColumnEditorFactory {
        if (ColumnEditorFactory.instance === null) {
            ColumnEditorFactory.allowInstantiation = true;
            ColumnEditorFactory.instance = new ColumnEditorFactory();
            ColumnEditorFactory.allowInstantiation = false;
        }

        return ColumnEditorFactory.instance;
    }

    GetEditor(column: EditableColumnModel): ColumnEditor {
        let columnEditorFunction = this._systemFieldToEditor[column.Name.toUpperCase()];

        if (columnEditorFunction) {
            return columnEditorFunction(column);
        }

        if (column.IsSystem || column.IsReadOnly) {
            if (column.TypeName == ColumnTypes[ColumnTypes.Image]) {
                return new NonEditableImageColumnEditor(column);
            }
            return new NonEditableColumnEditor(column);
        }

        return this._columnTypeToEditor[column.TypeName](column);
    }
}