import * as ko from 'knockout';
import * as moment from 'moment';

import {CustomFieldControl} from "../CustomFieldControl";

import Template from './Template.html';
import {ProductCustomFieldDescription} from "../../ProductCustomFieldDescription";
import {CustomFieldValue} from '../../../CustomFieldValue';
import {ProductPart} from '../../../ProductPart';

import {FormatConverter} from 'FormatEditor/FormatConverter';
import {FIELD_TYPES} from 'Core/Constant';
import {ICON_CLASSES} from 'Core/Controls/DateTime/Icons';

export class CustomDateTimeControl extends CustomFieldControl {
	private _displayValue: KnockoutObservable<string>;
	private _datetimeFormat: string;
	private _typeName: string;

	constructor(customField: ProductCustomFieldDescription, productPart: ProductPart, typeName: string, datetimeFormat: string) {
		super(customField, productPart);
		
		this.HasContent = ko.observable(true);
		this._displayValue = ko.observable(null);
		this._typeName = typeName;
		this._datetimeFormat = datetimeFormat;
	}

	GetTemplate() {
		return Template;
	}

	GetValueForSave(): any {
		return new CustomFieldValue(this.Id, this.ConvertDateToUTC(this._displayValue()), this.IsValid(this._displayValue()));
	}

	SetValue(customFieldValue: CustomFieldValue) {
		if (customFieldValue && customFieldValue.Value) {
			const valueWithTimezone = this._typeName === FIELD_TYPES.Date ? customFieldValue.Value : FormatConverter.CorrectTimezone(customFieldValue.Value);

			this._displayValue(moment(valueWithTimezone).format(this._datetimeFormat));
		} else {
			this._displayValue(null);
		}

		this.StartListeningValueChanges();
	}

	SetDefaultValue() {
		const defaultFieldValue = new CustomFieldValue(this.Id, this.DefaultValue(), this.IsValid(this.DefaultValue()));
		this.SetValue(defaultFieldValue);
	}

	ClearValue() {
		this._displayValue(null);
	}

	GetLocale() {
		return moment.locale();
	}

	GetDateFormatIconClass(): string {
		const iconClassName = (this._typeName && ICON_CLASSES[this._typeName]);

		return `${iconClassName}`;
	}

	AfterRender(el: Array<HTMLElement>) {

		// set picker value in datepicker input after rendering control
		$(el).find('.datepickerinput').val(this._displayValue);
	}

	ConvertDateToUTC(date: string): string {
		return FormatConverter.ConvertToUTC(date, this._datetimeFormat);
	}

	private StartListeningValueChanges() {
		if (this._displayValue.getSubscriptionsCount() == 0) {
			this._displayValue.subscribe(() => {
				this.CheckValidControl();
				this.OnValueChanged();
			});
		}
	}

	IsValid(value?: any): boolean {
		return this.GetIsRequiredValidation(value ? value : this._displayValue());
	}

	CheckValidControl() {
		this.isValidControl(this.GetIsRequiredValidRule(this._displayValue()));
	}
}