import * as ko from "knockout";
import { BaseProperty, IPropertyDescription } from "Core/GeneralProperties/Managers/BaseProperty";

import InputTextPropertyTemplate from "Core/GeneralProperties/Managers/InputTextProperty/Templates/InputTextProperty.html";
ko.templates["Core/GeneralProperties/Managers/InputTextProperty/Templates/InputTextProperty"] = InputTextPropertyTemplate;

export class InputTextProperty extends BaseProperty {
	private _oldValue: string;
	private _isValid: KnockoutObservable<boolean>;

	constructor(property: IPropertyDescription, propertyValue: string) {
		super(property);
		this.Value = ko.observable(propertyValue || '');
		this._oldValue = this.Value();
		this._isValid = ko.observable(true);
	}

	GetTemplateName(): string {
		return "Core/GeneralProperties/Managers/InputTextProperty/Templates/InputTextProperty";
	}

	SetValue(propertyValue: string): void {
		if (propertyValue) {
			this.Value(propertyValue);
			this._oldValue = propertyValue;
		}
	}

	IsValid(): boolean {
		return this.CheckValidInput();
	}

	private CheckValidInput(): boolean {
		if (this.ValueRequired) {
			const value = this.Value();
			const isValid = value === this._oldValue || this.Value().toString() !== '';

			this._isValid(isValid);
			return isValid;
		}

		return true;
	}
}