import * as ko from 'knockout'
import * as _ from 'underscore';

import { IControlValue } from 'Core/Controls/BaseControl/BaseControl'
import { IControlParam } from 'Core/Screens/IScreen'
import { TypeScreen } from 'Core/Screens/TypeScreen/TypeScreen';
import { BlockUI } from 'Core/Common/BlockUi';
import { Notifier } from 'Core/Common/Notifier';
import { ButtonTemplateStore, IMergeAndSaveRequestModel, IGetTemplatesRequestModel } from 'Core/Controls/ButtonTemplate/Stores/ButtonTemplateStore';
import { ComplexControl } from 'Core/Controls/ComplexControl/ComplexControl';
import { RequiredFieldModel } from 'Core/Controls/ComplexControl/Models/RequiredFieldModel';
import { FIELD_TYPES, TABLE_TYPES, CONTROL_TYPES, SYSTEM_FIELD_NAMES } from 'Core/Constant';
import { TemplateModel } from 'Core/Controls/ButtonTemplate/Models/TemplateModel';
import { EntityTypes } from 'Core/Controls/Grid/BaseGrid/Enums/EntityTypes';
import { Grid } from 'Core/Controls/Grid/Grid';
import { EVENTS as TYPE_SCREEN_EVENTS } from 'Core/Screens/TypeScreen/Events';
import { ComposeMail } from 'Core/Controls/Mail/ComposeMail';
import { RecordLinker } from 'Core/Components/RecordLinker/RecordLinker';
import { MailStore } from '../Mail/Stores/MailStore';
import { MailTabModel } from '../Mail/Models/MailTabModel';
import { COMPOSER_EVENTS } from 'Core/Controls/Mail/Events';
import { Guid } from 'Core/Common/Guid';
import { AttachedFieldModel } from '../BaseControl/Models/AttachedFieldModel';
import { RecordStore } from 'Core/Common/Stores/RecordStore';
import { LABELS, NOTIFICATIONS, CONFIRMATIONS } from 'Core/Components/Translation/Locales';
import { JBoxDropDown } from "Core/Components/JBoxDropdown/DropDown";
import { ZIndexManager } from "Core/Common/ZIndexManager";

import { Icon } from "Core/Icon/Icon";
import { DEFAULT_ICONS } from "Core/Constant";
import {
	ConfirmationDialog,
	EVENTS as CONFIRMATION_DIALOG_EVENTS, Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

import TemplateButtonConfig from "Core/Controls/ButtonTemplate/Configs/template-button-config.json";

import ViewTemplate from 'Core/Controls/ButtonTemplate/Templates/View.html';
import HelpViewTemplate from 'Core/Controls/ButtonTemplate/Templates/HelpView.html';
import DesignTemplate from 'Core/Controls/ButtonTemplate/Templates/Design.html';
import ToolBarTemplate from 'Core/Controls/ButtonTemplate/Templates/ToolBar.html';
import GridRowTemplate from 'Core/Controls/ButtonTemplate/Templates/GridRow.html';
import DropdownTemplate from 'Core/Controls/ButtonTemplate/Templates/Dropdown.html';
import DropdownSubMenuTemplate from 'Core/Controls/ButtonTemplate/Templates/DropdownSubMenu.html';
import {MobileChecker} from "../../Common/MobileChecker";

ko.templates['Core/Controls/ButtonTemplate/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/ButtonTemplate/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/ButtonTemplate/Templates/GridRow'] = GridRowTemplate;
ko.templates['Core/Controls/ButtonTemplate/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/ButtonTemplate/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/ButtonTemplate/Templates/Design'] = ComplexControl.designTemplate;

const INTERNAL_MAIL_CLIENT = 'Internal';

export class ButtonTemplate extends ComplexControl {
	private _typeScreen: TypeScreen;
	private _items: KnockoutObservableArray<TemplateModel>;
	private _selectedItem: KnockoutObservable<TemplateModel>;
	private _hasData: KnockoutObservable<boolean>;
	private _mails: KnockoutObservableArray<MailTabModel>;
	private _button: HTMLElement;
	private _buttonId: string;

    private _config: any;
	private _tooltip: KnockoutObservable<string>;
	private _dropDown: JBoxDropDown;
	private _dropDownSubmenu: JBoxDropDown;
	private _currentHtmlTemplate:	KnockoutObservable<TemplateModel>;
	private _isMobile: MobileChecker;
	private _dropDownOpened: boolean;
	private _dropDownSubmenuOpen: boolean;

	constructor(params: IControlParam) {
		super(params, TemplateButtonConfig);
		this._items = ko.observableArray([]);
		this._selectedItem = ko.observable(null);
		this._hasData = ko.observable(false);
		this._mails = ko.observableArray([]);
		this._buttonId = Guid.NewGuid();
        this._currentHtmlTemplate = ko.observable(null);
        this._border = ko.observable(false);
		this._tooltip = ko.observable(LABELS.GENERATE_DOCUMENT_FROM_TEMPLATE);
		this._isMobile = MobileChecker.IsMobile();
		this._dropDownOpened = false;
		this._dropDownSubmenuOpen = false;
        this._style = ko.computed(() => {
        	return {
				backgroundColor: this._backgroundColor(),
				color: this._color(),
				border: this._border() ? `1px` : '0',
				borderColor: this._borderColor(),
				borderStyle: 'initial'
			}
		});

		this.Init();
	}

	private Init(): void {
		this.SetDefaultIcon(new Icon(DEFAULT_ICONS.ButtonTemplate));
        this.ApplyProperties();
		this._requiredFields([
			new RequiredFieldModel('F_TYPE', FIELD_TYPES.Lookup, TABLE_TYPES.Entity, null),
			new RequiredFieldModel('NAME', FIELD_TYPES.Text, TABLE_TYPES.Entity, null),
			new RequiredFieldModel('TEMPLATE', FIELD_TYPES.Document, TABLE_TYPES.Entity, null)
		]);

		this.InitRequiredFields();
	}

	private InitButtonTemplateDropdown(target) {
		this._dropDown = new JBoxDropDown({
			target: target,
			bindTarget: target,
			bindComponent: this,
			otherOptions: {
				addClass: "js-button-template-dropdown",
				closeOnClick: this._isMobile ? 'body' : true,
				attach: undefined,
				height: 'auto',
				adjustPosition: true,
				adjustTracker: true,
				isolateScroll: true,
				pointer: "center",
				maxWidth: 380,
				maxHeight: this._isMobile ? 170 : undefined,
				onCloseComplete: () => {
					this._dropDownOpened = false;
				},
				zIndex: ZIndexManager.Instance.NextValue,
				position: {
					x: "center",
					y: "bottom"
				}
			},

			onCreated: () => {
				this._dropDown.SetContent({ content: DropdownTemplate as any });
			},

			onOpen: () => {}
		});
	}

	private InitButtonTemplateDropdownSubmenu(target) {
		this._dropDownSubmenu = new JBoxDropDown({
			target: target,
			bindTarget: target,
			bindComponent: this,
			otherOptions: {
				addClass: "js-button-template-dropdown-submenu",
				closeOnClick: true,
				attach: undefined,
				height: 'auto',
				adjustPosition: true,
				adjustTracker: true,
				isolateScroll: true,
				closeOnMouseleave: true,
				pointer: "top",
				maxWidth: 300,
				maxHeight: this._isMobile ? 170 : undefined,
				onCloseComplete: () => {
					if (!this._isMobile) {
						$("body").off('mousemove.jBoxButtonTemplate');
					} else {
						this._dropDownSubmenuOpen = false;
					}
				},
				zIndex: ZIndexManager.Instance.NextValue,
				position: {
					x: this._isMobile ? "left" : "right",
					y: "center"
				},
				outside: 'x',
				offset: { x: 0, y: 0 }
			},

			onCreated: () => {
				this._dropDownSubmenu.SetContent({ content: DropdownSubMenuTemplate as any });
			},

			onOpen: () => {
				if (!this._isMobile) {
					$("body").on("mousemove.jBoxButtonTemplate", (event) => {
						if (!event.target.closest(".dropdown-submenu-button-template") && !event.target.closest(".js-button-template-dropdown-submenu")) {
							this.HideDropdownSubmenu();
						}
					});
				}
			}
		});
	}

	MergeAndSave(
		template: TemplateModel,
		destinationEntityId: number,
		destinationTypeId: number,
		saveIntoSubject: boolean = false,
		mail: MailTabModel,
		saveDestinationTypeId: boolean = false
	) {
		let requestModel: IMergeAndSaveRequestModel = {
			SubjectEntityId: this.SubjectEntityId,
			SubjectRecordId: this.SubjectRecordId,
			RecordId: template.Id,
			ControlId: this.GetControlId(),
			DestinationEntityId: destinationEntityId,
			DestinationTypeId: destinationTypeId,
			SaveDestinationTypeId: saveDestinationTypeId,
			SaveIntoSubject: saveIntoSubject,
			MailAddress: mail ? mail.Login : null,
			Client: mail ? mail.Client : null
		};

		if (mail && (mail.Client === null || mail.Client === '')){
			mail.Client = INTERNAL_MAIL_CLIENT;
		}


		BlockUI.Block({ Target: this._button });
		ButtonTemplateStore
			.MergeAndSave(requestModel)
			.fail(err => {
				var notifier = new Notifier(null);
				notifier.Failed(err.message);
			})
			.always(() => BlockUI.Unblock(this._button))
			.then((result) => {
				if (this._parentControl) {
					if (this._parentControl instanceof Grid) {
						this._parentControl.LoadData();
					}

					if (saveDestinationTypeId) {
						this.LoadData();
					}
				}

				if (mail && mail.Client === INTERNAL_MAIL_CLIENT) {
					_.each(result.ResultObject, (item: any) => {
						let composeMail = new ComposeMail({ SubjectEntityId: this.SubjectEntityId, SubjectRecordId: this.SubjectRecordId, SubjectTypeId: this.SubjectTypeId });

						_.each(item.Recipients, (item: any) => {
							if (item.Mail) {
								composeMail.AddMailWithRecord(item.Mail, item.TableId, item.RecordId);
							}
						});

						composeMail.AddBody(item.Content);
						composeMail.AddScreen(this._form.GetScreen());
						if(item.CC){
							_.each(item.CC.split(','), (cc: string) => composeMail.AddMailCC('', cc))
						}

						if(item.BCC){
							_.each(item.BCC.split(','), (bcc: string) => composeMail.AddMailBCC('', bcc))
						}
						
						composeMail.AddSubject(item.Subject || template.Name);
						composeMail.ShowInModal(mail.MailConnectionId);

					});
				} else {
					this._form.GetScreen().Refresh();
				}
			});
	}

	SelectTemplate(template: TemplateModel, mail: MailTabModel) {
		if (this._parentControl && this._parentControl.GetType() === CONTROL_TYPES.Grid) {			
			let destinationEntityId = this.GetDestinationEntityId(template);

			if (destinationEntityId) {
				if (mail) {
					this.MergeAndSave(template, destinationEntityId, 0, false, mail);
				} else {
					if (template.EntityTypeId) {
						this.MergeAndSave(template, destinationEntityId, template.EntityTypeId, false, mail);
					} else {
						this.ShowTypeScreen(template, mail, destinationEntityId);
					}
				}
			}
		} else {
			this.MergeAndSave(template, 0, 0, true, mail);
		}
	}

	GetDestinationEntityId(template: TemplateModel){
		if(this._gridRow){
			return template.DestinationEntityId;
		}
		var gridSubjectField = _.find(this._parentControl.GetModel().Fields, (field) => field.EntityTypeName !== EntityTypes[EntityTypes.Link] );
		return gridSubjectField?.EntityId;
	}

	ShowTypeScreen(template: TemplateModel, mail: MailTabModel, entityId: number){
		var typeScreen = new TypeScreen(entityId, 0, false, false);
		typeScreen.On(TYPE_SCREEN_EVENTS.TYPE_SELECTED,
			this,
			(eventArgs) => {
				this.MergeAndSave(template, entityId, eventArgs.data.TypeId, false, mail, true);
			});

		typeScreen.On(TYPE_SCREEN_EVENTS.TYPES_NOT_FOUND,
			this,
			() => {
				this.MergeAndSave(template, entityId, 0, false, mail);
			});

		typeScreen.Show();
	}	

	SetValue(value: IControlValue): void {
		if (value.SubjectRecordId !== 0 || this._gridRow) {
			this.LoadData();
		}
	}

    ApplyProperties() {
        if (this.Properties) {
            this.AssignProperty('Styling', 'BackgroundColor', this._backgroundColor);
            this.AssignProperty('Styling', 'TextColor', this._color);
            this.AssignProperty('Styling', 'Border', this._border);
            this.AssignProperty('Styling', 'BorderColor', this._borderColor);
        }
    }

    private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
        if (this.Properties[groupedBy]) {
            _.each(this.Properties[groupedBy].Properties,
                (property: any) => {
                    if (property.hasOwnProperty(propertyName)) {
                        propertyHolder(property[propertyName]);
                    }
                });
        }
    }

	get SubjectRecordId(): number {
		if(this._gridRow){
			return this._gridRow.RecordId;
		}
		return this._form.GetScreen().GetRecordId();
	}

	get SubjectEntityId(): number {
		if(this._gridRow){
			return this._gridRow.EntityId;
		}
		return this._form.GetScreen().GetEntityId();
	}

	get SubjectTypeId(): number {
		if(this._gridRow){
			return this._gridRow.TypeId;
		}
		return this._form.GetScreen().GetTableTypeId();
	}

	get ContextEntityId(): number {
		if(this._gridRow){
			return this._gridRow.EntityId;
		}
		return this._parentControl && this._parentControl.GetType() === CONTROL_TYPES.Grid
		? this._parentControl.GetModel().EntityId
		: this._form.GetScreen().GetEntityId();
	}

	LoadData() {
		const requestModel: IGetTemplatesRequestModel = {
			SubjectEntityId: this.SubjectEntityId,
			ObjectEntityId:  this.ContextEntityId,
			ControlId: this.GetControlId()
		};

		ButtonTemplateStore
			.GetData(requestModel)
			.then((data) => {
				let selectedTemplate = this.GeneralProperties.GetPropertyValue('DocumentTemplate');
				
				if(selectedTemplate){
					data = [_.find(data, (template)=> template.Id === selectedTemplate)];
				}				

				this._items(data);
				this._hasData(this._items().length > 0);
			});


		MailStore.GetMailTabs()
			.then((tabs: any) => {
				_.map(tabs, (tab: any) => {
					let mail = new MailTabModel();
					mail.Login = tab.Login;
					mail.MailConnectionId = tab.MailConnectionId;
					mail.Client = tab.Client;
					this._mails.push(mail);
				});
			})
			.always(() => this._button? BlockUI.Unblock(): null)
			.fail((err) => new Notifier().Failed(err.message));
	}

	ShowTemplateHelp(data, event): void {
		if (this._help.IsHelpButtonPressed()) {
			event.stopPropagation();
			this._help.ShowControlHelp(this);
		}

		let target = event.target.closest(".template-btn");

		if (this._dropDown) {
			if (this._dropDownOpened) {
				this._dropDown.Close();
				this._dropDownOpened = false;
			} else {
				this._dropDown.Open(target);
				this._dropDownOpened = true;
			}
		} else {
			this.InitButtonTemplateDropdown(target);
			this._dropDown.Open(target);
			this._dropDownOpened = true;
		}
	}

	ShowDropdownSubmenu(element, template): void {
		let target = element.closest(".dropdown-submenu-button-template");

		if (this._isMobile) {
			if (this._dropDownSubmenu) {
				if (this._dropDownSubmenuOpen) {
					this._dropDownSubmenu.Close();
					this._dropDownSubmenuOpen = false;
				} else {
					this._dropDownSubmenu.Open(element);
					this._dropDownSubmenuOpen = true;
				}
			} else {
				this.InitButtonTemplateDropdownSubmenu(element);
				this._dropDownSubmenu.Open(element);
				this._dropDownSubmenuOpen = true;
			}

			this._currentHtmlTemplate(template);
			return;
		}

		if (this._dropDownSubmenu) {
			this._dropDownSubmenu.Open(target);
		} else {
			this.InitButtonTemplateDropdownSubmenu(target);
			this._dropDownSubmenu.Open(target);
		}

		this._currentHtmlTemplate(template);
	}

	HideDropdownSubmenu(): void {
		if (this._dropDownSubmenu instanceof JBoxDropDown) {
			this._dropDownSubmenu.Close();
		}
	}

	get Items(): KnockoutObservableArray<TemplateModel> {
		return this._items;
	}

	get Mails(): KnockoutObservableArray<MailTabModel> {
		return this._mails;
	}

	AfterRender(el: Array<HTMLElement>) {
		this._button = document.getElementById(this._buttonId);
		super.AfterRender(el);
	}

	async MergeAndSaveForGridRow(){

		BlockUI.Block();
		let record = await RecordStore.GetRecord({ TableId: this.SubjectEntityId, RecordId: this.SubjectRecordId });
		BlockUI.Unblock();

		let template = _.first(this._items());
		let mail = _.first(this._mails());
		if(!template){
			return;
		}

		let nameField = _.find(record.Fields, (field: any) => field.FieldName === 'NAME');

		let confirmationDialog = new ConfirmationDialog({
			Text: CONFIRMATIONS.GENERATE_DOCUMENT_FROM_TEMPLATE.replace('{templateName}', template.Name).replace('{recordName}', nameField.FieldValue || ''),
			Type: ConfirmationTypes.Question,
			TextConfirm: LABELS.YES,
			TextDecline: LABELS.NO
		});

		if(template.TypeName != 'html'){
			mail = null;
		}

		confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, ()=>this.SelectTemplate(template, mail));

		confirmationDialog.Show();
	}
} 