import {CurrentLifeStatusViewModel} from "./CurrentLifeStatusViewModel";
import {ActionCheckListViewModel} from "./ActionCheckListViewModel";

export class ActionsCheckListsViewModel {
    CurrentLifeStatus: CurrentLifeStatusViewModel;
    CheckStatuses: ActionCheckListViewModel[];
    NonCheckStatuses: ActionCheckListViewModel[];
    FollowUpMode: boolean;

    constructor() {
        this.CheckStatuses = [];
        this.NonCheckStatuses = [];
    }
}