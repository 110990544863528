import * as ko from 'knockout';

import FieldTableTemplate from 'FieldCollection/FieldTable/FieldTable.html';

import {FieldTableRow} from 'FieldCollection/FieldTableRow/FieldTableRow';
import {PriorityManager} from 'FieldCollection/PriorityManager/PriorityManager';
import {IViewModel, IViewModelType, IViewModelField} from 'FieldCollection/Models/IViewModel';
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";
import {Event} from 'Core/Common/Event';

export class FieldTable extends Event {
	private _fieldsInitial: IViewModelField[];
	private _pertableTypes: KnockoutObservableArray<IViewModelType>;
	private _otherTypes: KnockoutObservableArray<IViewModelType>;
	private _fieldTableRows: KnockoutObservable<FieldTableRow>[];
	private _priorityManager: PriorityManager;
	private _labels = LABELS;

	constructor(fieldsInitial: IViewModel) {
		super();

		this._fieldsInitial = JSON.parse(JSON.stringify(fieldsInitial.Fields));
		this._pertableTypes = ko.observableArray(fieldsInitial.Types.PerTable);
		this._otherTypes = ko.observableArray(fieldsInitial.Types.Other);
		this._priorityManager = new PriorityManager();
		this._fieldTableRows = this.BuildRowComponents(this._fieldsInitial);
	}

	BuildRowComponents(rowsModel: IViewModelField[]) {
		return rowsModel.map((row: IViewModelField) => {
			this._priorityManager.RestrictDowngrade(row, true);

			const rowComponent = new FieldTableRow(row);
			const observableRowComponent = ko.observable(rowComponent);

			rowComponent.On('FieldToggled', this, (eventArgs) => this.RowChanged(observableRowComponent, eventArgs.data.Data));

			return observableRowComponent;
		});
	}

	GetTemplate() {
		return FieldTableTemplate;
	}

	private RowChanged(row: KnockoutObservable<FieldTableRow>, data: any): void {
		this.Trigger('FieldToggled', {Data: row()._fieldRow});

		row.valueHasMutated();
	}
}