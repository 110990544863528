import {deserialize, serializeAs} from 'libs/cerialize';
import {deserializeAs} from 'libs/cerialize';
import {Icon} from "Core/Icon/Icon";
import {TranslationModel} from "./TranslationModel";

export class FieldModel {
	constructor() {
		this.IsEnabled = true;
		this.Translations = [];
	}

	@deserialize
	FieldId: number;

	@deserialize
    FieldName: string;

    @deserialize
    FieldNameTranslation: string;

	@deserialize
	FieldTypeName: string;

	@deserialize
	TypeId: number;

	@deserialize
	TypeName: string;

	@deserialize
	ValTableId: number;

	@deserialize
	ValFieldId: number;

	@deserialize
	IsSystem: boolean;

	IsEnabled: boolean;

	EntityIcon: Icon;

	IsPrimaryKey(): boolean {
		return this.FieldTypeName === 'PKey';
	};

	@deserializeAs(TranslationModel)
	Translations: Array<TranslationModel>;

	@serializeAs('FieldName')
	FullName: string;
}