import * as ko from "knockout";

import 'bootstrapSwitch';

import {IValueUpdater} from "LookupEditor/Interfaces/IValueUpdater";
import {IColumnValue} from "LookupEditor/Interfaces/IColumnValue";
import {ColumnEditor} from "LookupEditor/ColumnEditors/Base/ColumnEditor";

import YesNoTemplate from "LookupEditor/Templates/Columns/YesNo.html";
ko.templates["LookupEditor/Templates/Columns/YesNo"] = YesNoTemplate;

export class YesNoColumnEditor extends ColumnEditor {
    Show(): void { }

    GetTemplateName(): string {
        return 'LookupEditor/Templates/Columns/YesNo';
    }

	AfterRender(el, owner: IValueUpdater, columnName): void {

		// Due to dynamic nature of grid cell rendering and no possibility to detect
		// it end or any definitive stage at this point, setTimeout is used to put
		// bootstrapSwitch in the end of queue.
		setTimeout(() => {
			$(el).bootstrapSwitch({
				onSwitchChange: (event, state) => {
					var value: IColumnValue = {
						Name: columnName,
						Value: state
					};
					owner.UpdateValue(value);
				}
			})
		}, 100);

    }

    ToString() {
        return "YesNo";
    }
} 