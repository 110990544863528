import * as _ from 'underscore';

import {P} from 'Core/Common/Promise';

import {TranslationStore, ITranslation} from "Core/Components/Translation/Stores/TranslationStore/TranslationStore";

import {LanguageResponseModel} from "Core/Components/Translation/Stores/TranslationStore/LanguageResponseModel";
import {LanguageModel} from "Core/Components/Translation/Models/LanguageModel";
import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";
import {ITranslationValue} from "Core/Components/TranslationFieldEditor/ITranslationValue";
import enumerable from '../../Common/Decorators/EnumerableDecorator';
import { BlockUI } from "Core/Common/BlockUi";

export class TranslationManager {
	private static instance: TranslationManager;
	private static allowInstantiation: boolean;
	private _translations: ITranslation;
	private _languages: LanguageModel[];

	constructor() {
		if (!TranslationManager.allowInstantiation) {
			throw new Error("Use TranslationManager.Instance instead");
		}
		this._translations = [];
		this._languages = [];
	}

	static get Instance(): TranslationManager {
		if (TranslationManager.instance == null) {
			TranslationManager.allowInstantiation = true;
			TranslationManager.instance = new TranslationManager();
			TranslationManager.allowInstantiation = false;
		}

		return TranslationManager.instance;
	}

	get Languages() {
		return this._languages;
	}

	UpdateLanguages(activeLanguages: number[]) {
		const currentActiveLanguages = activeLanguages.sort();
		currentActiveLanguages.splice(0, 0, this.GetDefaultLanguage().Id);

		const cachedLanguages = this._languages.map(l => l.Id).sort();
		const languagesAreUpToDate = JSON.stringify(cachedLanguages) === JSON.stringify(activeLanguages.sort());

		if(!languagesAreUpToDate) {
			return this.LoadLanguages();
		}

		const deferred = P.defer<any>();
		deferred.resolve(null);
		return deferred.promise();
	}

	GetCurrentLanguage() {
		const desktopLanguage = GlobalManager.Instance.GetGlobal(GLOBALS.DESKTOP_LANGUAGE);
		return this.GetLanguageByShortName(desktopLanguage);
	}

	GetLanguageById(id: number) {
		return _.find(this._languages, language => language.Id === id);
	}

	GetLanguageByShortName(shortname: string) {
		return _.find(this._languages, language => language.ShortName === shortname);
	}

	GetDefaultLanguage() {
		return new LanguageModel(
			0,
			'Default',
			'DEFAULT',
			'/img/Custom/language-default-icon.png',
			true,
			true
		);
	}

	GetTranslation(value: string, translations: ITranslationValue[]) {
		const currentLanguage = this.GetCurrentLanguage();
		const currentTranslation = _.find(translations, translation => translation.LanguageId === currentLanguage.Id);
		const translatedValue = currentTranslation && currentTranslation.Value || value;
		const language = currentTranslation && currentTranslation.Value && currentLanguage || this.GetDefaultLanguage();

		return {
			Value: value,
			TranslatedValue: translatedValue,
			Language: language
		};
	}

	AddLanguage(language: LanguageModel) {
		this._languages.push(language);
	}

	DeleteLanguage(id: number) {
		const languageToRemoveIndex = _.findIndex(this._languages, language => language.Id === id);

		if (languageToRemoveIndex > -1) {
			this._languages.splice(languageToRemoveIndex, 1);
		}
	}

	UpdateLanguage(language: LanguageModel) {
		const languageToUpdate = _.find(this._languages, language => language.Id === language.Id);

		if (languageToUpdate) {
			languageToUpdate.Update(language);
		}
	}

	LoadTranslations(): P.Promise<any> {
		var deferredResult = P.defer<any>();

		TranslationStore.Get()
			.then(translations => {
				this._translations = translations;
				deferredResult.resolve(null);
			});

		return deferredResult.promise();
	}

	LoadTranslationsForDb(dbName: string, language: string): P.Promise<any> {
		var deferredResult = P.defer<any>();

		TranslationStore.GetTranslationsForDb({
			DbName: dbName,
			Language: language
		})
			.then(translations => {
				this._translations = translations;
				deferredResult.resolve(null);
			}).fail((err)=>{
				deferredResult.reject({ message: err.message });
			});

		return deferredResult.promise();
	}

	LoadLanguages() {
		BlockUI.Block();
		const promise = TranslationStore.GetLanguages();

		promise.always(()=>{
			BlockUI.Unblock();
		}).then(languages => {
			this.SaveLanguages(languages);
			
		});

		return promise;
	}

	GetLocalizedString(code: number, defaultValue: string = null) {
		var localeString = this._translations[code];
		return localeString || defaultValue || `${code}`;
	}

	private SaveLanguages(languagesFromServer: LanguageResponseModel[]) {
		this._languages = languagesFromServer.map(language => this.MapToLanguageModel(language));
		this._languages.splice(0, 0, this.GetDefaultLanguage());
	}

	private MapToLanguageModel(language: LanguageResponseModel) {
		return new LanguageModel(language.K_Language,
			language.Name,
			language.ShortName,
			language.FlagBase64,
			language.Enabled,
			false
		);
	}
}