import * as joint from "../../libs/rappid/build/rappid";
import {
    CELL_CUSTOM_ATTRS, CELL_OPTIONS, CELL_OPTIONS_START, COLORS,
    LABEL_MARKUP,
    LIFECYCLE_EVENTS,
    LINK_CUSTOM_ATTRS, TEST_CONDITION_CUSTOM_ATTRS,
    TOOL_MARKUP
} from "./Constants/Constants";
import {Notifier} from "../Common/Notifier";
import {TranslationModel} from "../Controls/BaseControl/Models/TranslationModel";
import {UserVarsManager} from "../UserVarsManager/UserVarsManager";
import {LifecycleDesignerStore} from "./LifecycleDesignerStore";
import * as _ from "underscore";
import {LIFE_STATUS_GROUPS} from "../Constant";
import {GlobalManager} from "../GlobalManager/GlobalManager";

export class LifecycleDesignerFlow {
    private _graph: joint.dia.Graph;
    private _paper: joint.dia.Paper;
    private _tableType: any;
    private _deletedSteps: Array<string>;
    private _deletedLinks: Array<string>;
    private _flowDesignerEl: JQuery;
    private _container: JQuery;
    private _notifier: Notifier;
    private _halo: joint.ui.Halo;
    private _paperScroller: joint.ui.PaperScroller;
    private _inspector: joint.ui.Inspector;
    private _freeTransform: joint.ui.FreeTransform;
    private _commandManager: joint.dia.CommandManager;
    private _toolbar: joint.ui.Toolbar;
    private _isAdvancedMode: boolean;
    private _steps: Array<any> = [];

    constructor(params: {
        Container: string,
        TableTypes
    }) {
        let container = $(params.Container);
        if (container.length > 1){
            this._container = $(container[container.length - 1])
        } else {
            this._container = container;
        }
        this._notifier = new Notifier(this._container);
        this._graph = null;
        this._paper = null;
        this._deletedSteps = [];
        this._deletedLinks = [];
        this._halo = null;
        this._inspector = null;
        this._freeTransform = null;
        this._toolbar = null;
        this._isAdvancedMode = UserVarsManager.Instance.GetAdvancedLifestatus();
        this.Init();
    }

    Init() {
        joint.setTheme('modern');
        this._flowDesignerEl = $(this._container).find('.flow-designer');
        this._notifier = new Notifier();
    }

    InitJsPoint(data) {
	    this._deletedSteps = [];
        this._deletedLinks = [];

        if (this._graph == null) {
            this._graph = new joint.dia.Graph();
            this._paper = new joint.dia.Paper({
                el: this._flowDesignerEl,
                width: this._container.width(),
                gridSize: 2,
                model: this._graph,
                linkPinning: false,
                multiLinks: false,
                snapLinks: true,
                defaultLink: new joint.dia.Link({
                    labels: [{position: .5, attrs: {text: {text: '', 'font-weight': 'bold'}}}],
                    vertices: []
                }),
                defaultConnector: {
                    name: 'smooth'
                },
                interactive: (cellView) => {
                    if (cellView.model instanceof joint.dia.Link) {
                        return {
                            labelMove: false,
                            linkMove: false
                        };
                    }
                    if (cellView.model instanceof joint.shapes.basic.Circle || cellView.model instanceof joint.shapes.lifecycle.RectWithImage) {
                        return {elementMove: false};
                    }
                    return true;
                },
                linkView: joint.dia.LinkView.extend({
                    pointerdown: function (evt, x, y) {

                        joint.dia.CellView.prototype.pointerdown.apply(this, arguments);
                        this.notify('link:pointerdown', evt, x, y);

                        this._dx = x;
                        this._dy = y;

                        if (evt.target.getAttribute('magnet') != null) return;

                        var className = joint.util.removeClassNamePrefix(evt.target.getAttribute('class'));
                        var parentClassName = joint.util.removeClassNamePrefix(evt.target.parentNode.getAttribute('class'));
                        var labelNode;
                        if (parentClassName === 'label') {
                            className = parentClassName;
                            labelNode = evt.target.parentNode;
                        } else {
                            labelNode = evt.target;
                        }

                        switch (className) {

                            case 'marker-vertex':
                                if (this.can('vertexMove')) {
                                    this._action = 'vertex-move';
                                    this._vertexIdx = evt.target.getAttribute('idx');
                                }
                                break;

                            case 'marker-vertex-remove':
                            case 'marker-vertex-remove-area':
                                if (this.can('vertexRemove')) {
                                    this.removeVertex(evt.target.getAttribute('idx'));
                                }
                                break;

                            case 'marker-arrowhead':
                                if (this.can('arrowheadMove')) {
                                    this.startArrowheadMove(evt.target.getAttribute('end'));
                                }
                                break;

                            case 'label':
                                if (this.can('labelMove')) {
                                    this._action = 'label-move';
                                    this._labelIdx = parseInt(joint.V(labelNode).attr('label-idx'), 10);
                                    this._samples = this._V.connection.sample(1);
                                    this._linkLength = this._V.connection.node.getTotalLength();
                                }
                                break;

                            default:
                                if (this.can('vertexAdd') && evt.button === 2) {
                                    this._vertexIdx = this.addVertex({x: x, y: y});
                                    this._action = 'vertex-move';
                                }
                        }
                    }
                }),
            });

            this._paper.setTheme('default');

            this._commandManager = new joint.dia.CommandManager({
                graph: this._graph
            });

            this.InitPaperScroller();
            $(window).trigger(LIFECYCLE_EVENTS.WINDOW_RESIZE);
        }

        this._graph.clear();

        this.MovePaperScrollerToStartNode();

        if (data.Steps && data.Steps.length > 0) {
            this.LoadData(data);
            this._commandManager.reset();
            return;
        }
        this.AddStartCell();
        this._commandManager.reset();
    }

    MovePaperScrollerToStartNode() {
        if (this._paperScroller) {
            const paperOrigin = this._paper.options.origin;
            const visibleArea = this._paperScroller.getVisibleArea();
            this._paperScroller.center(paperOrigin.x + visibleArea.width / 2, paperOrigin.y + visibleArea.height / 2);
        }
    }

    AddStartCell() {
        let options: any = CELL_OPTIONS_START;
        let cell;
        cell = new joint.shapes.basic.Circle(options);
        this._graph.addCell(cell);
        return cell;
    }

    InitPaperScroller() {
        this._paperScroller = new joint.ui.PaperScroller({
            paper: this._paper,
            cursor: 'grab',
            autoResizePaper: true,
            contentOptions: {
                allowNewOrigin: 'any'
            },
            padding: 0
        });
        let percent = +GlobalManager.Instance.GetGlobal('PopupHeightInPercent');
        let paperHeight = $(window).height() / 100 * percent - 500;
        this._paperScroller.setCursor('grab').$el.css({
            width: this._flowDesignerEl.width(),
            height: paperHeight,
        }).appendTo(this._container.find("#paper-scroller"));
        this._paperScroller.render();

        const paperWrapper = this._paperScroller.$el.find('.paper-scroller-background');
        this.ScrollFixer(paperWrapper);
        paperWrapper.on(LIFECYCLE_EVENTS.MOUSEWHEEL, (event: any) => {
            this.PaperZoomOnScroll(event, event.deltaY);
        });
    }

    PaperZoomOnScroll(event, delta: number) {
        if (event.ctrlKey) {
            event.preventDefault();
            const step = 0.2;
            const oldScale = joint.V(this._paper.viewport).scale().sx;
            if ((delta > 0 && oldScale < 5) || ((delta < 0) && (oldScale > 0.2))) {
                this._paperScroller.zoom(step * delta);
            }
        }
    }

    private ScrollFixer(element: JQuery) {
        $(element).bind('mousewheel DOMMouseScroll', function (e) {
            e.stopPropagation();
        });
    }

    LoadData(data) {
        this._steps = data.Steps;
        _.each(data.Steps, (step: any) => {
            if (!step.Json) {
                return;
            }
            this.InitStepData(step);
        });

        _.each(data.Links, (link: any) => {
            this.InitLinkData(link);
        });
    }

    InitStepData(step) {
        const stepJson = JSON.parse(step.Json.replace('\"', '"'));
        const selectedTranslation = _.find(step.Translations, (translation: TranslationModel) => translation.Selected === true);

        stepJson.attrs.id = step.Id;
        stepJson.attrs.sort = step.Sort !== undefined ? step.Sort.toString() : step.sort.toString();
        stepJson.disabled = !step.Enabled;
        stepJson.attrs.OnLifestatusSetTrigger = step.Trigger;
        stepJson.attrs.ActionItems = step.ActionItems;
        stepJson.attrs.NoActionNode = step.NoActionNode;
        stepJson.attrs.MultipleActions = step.MultipleActions;
        stepJson.attrs.Packages = step.Trigger?.DwPackages;
        stepJson.attrs.NoActionNodeDisable = step.NoActionNodeDisable; //NoActionNode .prop("disabled")
        stepJson.attrs.DateElapsed = step.DateElapsed;
        stepJson.attrs.DeadlineElapsed = step.DeadlineElapsed;
        stepJson.attrs.translations = step.Translations;
        stepJson.attrs.name = step.Label;

        if (stepJson.type !== 'basic.Circle' && selectedTranslation && selectedTranslation.Selected && selectedTranslation.Translation !== null) {
            stepJson.attrs.text.text = selectedTranslation.Translation;
            stepJson.attrs.translatableName = selectedTranslation.Translation;
        } else {
            stepJson.attrs.text.text = step.Label;
        }

        if (stepJson.attrs.OnLifestatusSetTrigger) {
            stepJson.attrs.NoActionNodeDisable = false;
        } else {
            stepJson.attrs.NoActionNodeDisable = true;
        }

        if (stepJson.attrs[CELL_CUSTOM_ATTRS.TRIGGER]) {
            stepJson.attrs[CELL_CUSTOM_ATTRS.TRIGGER].visibility = stepJson.attrs.OnLifestatusSetTrigger ? 'visible' : 'hidden';
        }
        if (stepJson.attrs[CELL_CUSTOM_ATTRS.RETIRED]) {
            stepJson.attrs[CELL_CUSTOM_ATTRS.RETIRED].visibility = (stepJson.attrs.sort === LIFE_STATUS_GROUPS.RETIRED.toString()) ? 'visible' : 'hidden';
        }
        if (stepJson.attrs[CELL_CUSTOM_ATTRS.NO_ACTION]) {
            stepJson.attrs[CELL_CUSTOM_ATTRS.NO_ACTION].visibility = stepJson.attrs.NoActionNode ? 'visible' : 'hidden';
        }
        if (stepJson.attrs[CELL_CUSTOM_ATTRS.MULTIPLE_ACTIONS]) {
            stepJson.attrs[CELL_CUSTOM_ATTRS.MULTIPLE_ACTIONS].visibility = stepJson.attrs.MultipleActions ? 'visible' : 'hidden';
        }
        stepJson.ports = {items: []};
        this._graph.addCell(stepJson);

        if (stepJson.disabled) {
            this._paper.findViewByModel(stepJson).model.attr('./opacity', 0.5);
        }
    }

    InitLinkData(link) {
        const selectedTranslation = _.find(link.Translations, (translation: TranslationModel) => translation.Selected === true);
        const linkJson = JSON.parse(link.Json.replace('\"', '"'));
        linkJson.attrs.id = link.Id;
        linkJson.attrs.nextAllowed = link.NextAllowed;
        linkJson.attrs.approvalId = link.ApprovalId.toString() + '000';
        linkJson.attrs.stepsScreenId = link.StepsScreenId ? link.StepsScreenId.toString() : '0';
        linkJson.attrs.testCondition = link.TestCondition.toString() + '0000';

        if (!linkJson.toolMarkup) {
            linkJson.toolMarkup = TOOL_MARKUP;
        }

        linkJson.labelMarkup = LABEL_MARKUP;

        linkJson.labels[0].attrs = {
            'text': linkJson.labels[0].attrs.text,
            [LINK_CUSTOM_ATTRS.PASSWORD]: {
                visibility: link.ApprovalId.toString() === '2' ? 'visible' : 'hidden'
            },
            [LINK_CUSTOM_ATTRS.YESNO]: {
                visibility: link.ApprovalId.toString() === '3' ? 'visible' : 'hidden'
            },
            [LINK_CUSTOM_ATTRS.STEPSSCREENICON]: {
                visibility: link.StepsScreenId.toString() !== '0' ? 'visible' : 'hidden'
            },
            [TEST_CONDITION_CUSTOM_ATTRS.VALID]: {
                visibility: link.TestCondition.toString() === '1' ? 'visible' : 'hidden'
            },
            [TEST_CONDITION_CUSTOM_ATTRS.NOT_VALID]: {
                visibility: link.TestCondition.toString() === '2' ? 'visible' : 'hidden'
            },
            [TEST_CONDITION_CUSTOM_ATTRS.ERROR]: {
                visibility: link.TestCondition.toString() === '3' ? 'visible' : 'hidden'
            }
        };

        if (selectedTranslation && selectedTranslation.Selected && selectedTranslation.Translation !== null && linkJson.labels[0].attrs.text) {
            linkJson.labels[0].attrs.text.text = selectedTranslation.Translation;
        }

        this._graph.addCell(linkJson);

        if (link.NextAllowed) {
            this.InitAsNextAllowed(this._paper.findViewByModel(linkJson));
        }
    }

    private InitAsNextAllowed(cellView) {
        const color = COLORS.GREEN;
        cellView.model.attributes.attrs['.connection'].stroke = color;
        this.SetArrowColor(color, cellView);
    }

    private SetArrowColor(color: string, cellView) {
        cellView.model.attributes.attrs['.marker-target'] = {
            d: "M 0 -5 L -10 0 L 0 5 Z",
            fill: color,
            stroke: color
        };
    }

    LoadDataType(typeId) {
        return LifecycleDesignerStore.GetLifeCycleHelpData(typeId);
    };
}