import * as ko from 'knockout';

import {LABELS} from "Core/Components/Translation/Locales";

import { Event } from "Core/Common/Event";
import { Modal } from "Core/Common/Modal";

import { InvoicingResultsDtoModel } from 'Core/Components/Dashboards/FinancialDashboard/Models/Dto/Response/InvoicingResultsDtoModel';

import { InvoicingResultsViewModel } from 'Core/Components/Dashboards/FinancialDashboard/Models/View/InvoicingResultsViewModel';

import { FinancialDashboardMappingProfile } from 'Core/Components/Dashboards/FinancialDashboard/Mappings/FinancialDashboardMappings';

import Template from 'Core/Components/Dashboards/FinancialDashboard/Components/InvoicingResults/Templates/Template.html';

ko.templates['Core/Components/Dashboards/FinancialDashboard/Components/InvoicingResults/Templates/Template'] = Template;

export class InvoicingResults extends Event {
	private _modal: Modal;
	private _model: InvoicingResultsViewModel;
	private _labels = LABELS;

	constructor(model: InvoicingResultsDtoModel) {
		super();

		this._model = FinancialDashboardMappingProfile.MapToInvoicingResultsViewModel(model);
	}

	Show() {
		this._modal = new Modal();

		ko.applyBindings(this, this._modal.Wrapper);

		this._modal.Show();
	}

	GetTemplateName() {
		return 'Core/Components/Dashboards/FinancialDashboard/Components/InvoicingResults/Templates/Template';
	}

	AfterRender() {

	}
}