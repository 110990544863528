import {FIELD_TYPES} from "Core/Constant";

//Store models
import {TimeWritingReportResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Response/TimeWritingReportResponseModel";
import {SaveReservationRequestModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Request/SaveReservationRequestModel";
import {DeleteReservationRequestModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Request/DeleteReservationRequestModel";

//View models
import {
    DayDataViewModel,
    DefaultValue,
    Reservation,
    TranslatedField
} from "Core/Components/Controls/TimeWriting/Models/View/DayDataViewModel"

//Mappings
import {RequestedDateMappings} from "Core/Components/Controls/TimeWriting/Mappings/Day/RequestedDateMappings";
import {DayMappings} from "Core/Components/Controls/TimeWriting/Mappings/Day/DayMappings";
import {EntityMappings} from "Core/Components/Controls/TimeWriting/Mappings/Day/EntityMappings";
import {ReservationMappings} from "Core/Components/Controls/TimeWriting/Mappings/Day/ReservationMappings";
import {LookupFieldMappings} from "Core/Components/Controls/TimeWriting/Mappings/Common/LookupFieldMappings";
import {FormatConverter} from "../../../../../../FormatEditor/FormatConverter";
import {DayReportResponseModel} from '../../Models/Store/Day/Response/DayReportResponseModel';
import {WeekReportResponseModel} from '../../Models/Store/Day/Response/WeekReportResponseModel';

export class DayMappingProfile {
    static OnViewModel(dayReport: DayReportResponseModel, weekReport: WeekReportResponseModel): DayDataViewModel {
        const viewModel = new DayDataViewModel();
        viewModel.RequestedDate = RequestedDateMappings.OnViewModel(dayReport.DayReservations);
        viewModel.StartBySchedule = dayReport.DaySchedule && new Date(FormatConverter.CorrectTimezone(dayReport.DaySchedule.Starting.toString()));

        viewModel.DaysOfWeek = weekReport.DayReports.map(day => DayMappings.OnViewModel(day));
        viewModel.AllowedEntities = dayReport.GlobalSettings.AllowedEntities.map(entity => EntityMappings.OnViewModel(entity));
        viewModel.LookupFields = dayReport.Fields.filter(field => field.TypeName === FIELD_TYPES.Lookup || field.TypeName === FIELD_TYPES.MultiSelect).map(field => LookupFieldMappings.OnViewModel(field));

        viewModel.DefaultValues = dayReport.Fields.filter(field => field.DefaultValue).map(field => new DefaultValue({
            Id: field.Id,
            DefaultValue: field.DefaultValue,
            Name: field.Name,
            TypeName: field.TypeName
        }));
        viewModel.TranslatedFields = dayReport.Fields.filter(field => field.IsTranslate).map(field => new TranslatedField({
            Id: field.Id,
        }));
        viewModel.HasDisapprovals = dayReport.HasDisapprovals;

        return viewModel;
    }

    static OnSaveModel(model: Reservation): SaveReservationRequestModel {
        return ReservationMappings.OnSaveModel(model);
    }

    static OnDeleteModel(model: Reservation): DeleteReservationRequestModel {
        return ReservationMappings.OnDeleteModel(model);
    }
}