import { AIFieldDefinition } from "./AIFieldDefinition";

export class AITableDefinition{
    constructor(){
        this.Fields = [];
    }
    Name: string;
    Type: string;

    Fields: Array<AIFieldDefinition>;
}