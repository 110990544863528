import * as ko from "knockout";
import * as $ from "jquery";
import {LockScreenStore} from "LockScreen/Stores/LockScreenStore";
import {UserManager} from 'User/UserManager';
import {Event} from 'Core/Common/Event';
import {Modal} from "Core/Common/Modal";
import {UserModel} from "User/Store/Models/UserModel";
import {Notifier} from "Core/Common/Notifier";
import LockScreenTemplate from "LockScreen/Templates/LockScreen.html";
ko.templates["LockScreen/Templates/LockScreen"] = LockScreenTemplate;
import 'pubsub';
import {LockScreenModel} from "LockScreen/Models/LockScreenModel";
import {BlockUI as BlockUi} from "Core/Common/BlockUi";
import {AuthManager} from "Auth/AuthManager";
import { LOCAL_STORAGE } from "Core/Common/Enums/LocalStorageItems";
import {LABELS} from "Core/Components/Translation/Locales";
import { TranslationManager } from "Core/Components/Translation/TranslationManager";
import { MenuManager } from "MenuManager/MenuManager";
const SLIDES = [
	'/img/Metronic/bg/1.jpg',
	'/img/Metronic/bg/2.jpg',
	'/img/Metronic/bg/3.jpg',
	'/img/Metronic/bg/4.jpg'
];

export class LockScreen extends Event {
	private _labels = LABELS;
	private _modal: Modal;
	private _userManager: UserManager;
	private _currentUser: KnockoutObservable<UserModel>;
	private _errorMessage: KnockoutObservable<string>;
	private _isOpenedFlag: boolean;
	private _model: KnockoutValidatedObservable<LockScreenModel>;
	private _history: Historyjs = <any>History;
	private _broadcastChannel: BroadcastChannel;

	constructor() {
		super();
		this._userManager = UserManager.Instance;
		this._currentUser = ko.observable(this._userManager.CurrentUser);
		this._errorMessage = ko.observable('');
		this._broadcastChannel = new BroadcastChannel('lock_channel');

		this._broadcastChannel.onmessage = (event) => {
			if(event.data == 'unlock'){
				window.location.reload();
			}
		};
	}

	get UserName() {
		return this._currentUser().NameTranslation || this._currentUser().Name;
	}
	Lock() {
		LockScreenStore.Lock().then(() => {
			this._broadcastChannel.postMessage("lock");
			this.ShowInModal();			
		}).fail(err => new Notifier().Failed(err.message));
	}

	GetTemplateName() {
		return "LockScreen/Templates/LockScreen";
	}

	AfterRender() {
	}

	Unlock() {
		if (this.IsValid()) {
			LockScreenStore.Unlock(this._model().Password())
			.then((result) => {
				this._broadcastChannel.postMessage("unlock");				
				Lockr.set(LOCAL_STORAGE.LOCK_DATE, new Date().getTime());
				window.location.reload();
			})
			.fail(err => this._errorMessage(err.message)
			);
		}
	}

	Relogin() {
		let authManager = new AuthManager();
		authManager.SignOut()
			.then(() => {
				PubSub.publish('UNAUTHORIZE', null);
				this.Close();
			})
			.always(() => BlockUi.Unblock());
	}

	IsValid() {
		if (!this._model.isValid()) {
			this._model.errors.showAllMessages();
		}
		return this._model.isValid();
	}

	InitValidation() {
		ko.validation.init({ insertMessages: false }, true);
	}

	ShowInModal() {
		if (this._isOpenedFlag) {
			return;
		}
		this._model = ko.validatedObservable(new LockScreenModel());

		this._isOpenedFlag = true;
		this._modal = new Modal({
				addClass: 'login',
				width: '400',
				minHeight: '300',
				fade: 0,
				animation: null,
				closeButton: false,
				createOnInit: true,
				closeOnEsc: false,
				blockScroll: true
			},
			false);

		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);
		this._modal.Show();
		$(this._modal.Overlay()).css({ 'background': '#666' });
		$(this._modal.Container()).css({ 'background': 'none' });
		$(this._modal.Overlay()).backstretch(SLIDES,
			{
				fade: 1000,
				duration: 8000
			});

		this.RemoveOverlay();
	}

	RemoveOverlay() {
		let $preloader = $('.preloader');
		if ($preloader) {
			$preloader.detach();
		}
	}

	Close() {
		const state = this._history.getState();
		if (state) {
			document.title = state.title;
		}
		this._isOpenedFlag = false;
		$(this._modal.Overlay()).backstretch('destroy');
		this._modal.Close();
	}

	RemoveErrorSummary() {
		this._errorMessage(null);
	}
}
