/// <reference path="../../libs/typings/jqueryUi.d.ts"/>
/// <reference path="../../libs/typings/cropper.d.ts"/>

import * as ko from "knockout";
import "knockoutSelect2";
import * as $ from "jquery";
import "jqueryUi";


import 'jqueryUniform';

import 'knockout-sortable';


import { DragulaExtention } from 'Core/KnockoutExtentions/DragulaExtention';
import { DropzoneExtenion } from 'Core/KnockoutExtentions/DropzoneExtention';
import { FullCalendarExtention } from 'Core/KnockoutExtentions/FullCalendarExtention';
import { InputMaskExtention, MaskFormater } from 'Core/KnockoutExtentions/InputMaskExtention';
import { IntlTelInputExtention } from 'Core/KnockoutExtentions/IntlTelInputExtention';
import { DebugExtention } from 'Core/KnockoutExtentions/DebugExtention';
import { ViewerJsExtention } from 'Core/KnockoutExtentions/ViewerJsExtention';
import { DirtyFlagExtention } from 'Core/KnockoutExtentions/DirtyFlagExtention';
import { ErrorHandlingBindingProvider } from 'Core/KnockoutExtentions/ErrorHandlingBindingProvider';
import { RenderTemplateXExtention } from 'Core/KnockoutExtentions/RenderTemplateXExtention';
import { SubformResizeExtention } from 'Core/KnockoutExtentions/SubformResizeExtention';
import { PreventBubbleExtention } from 'Core/KnockoutExtentions/PreventBubbleExtention';
import { NiceScrollExtention } from 'Core/KnockoutExtentions/NiceScrollExtention';
import { SlimScrollExtention } from 'Core/KnockoutExtentions/SlimScrollExtention';
import { ResizableExtention } from 'Core/KnockoutExtentions/ResizableExtention';
import { CustomScrollbarExtention } from 'Core/KnockoutExtentions/CustomScrollbarExtention';
import { ToggleExtention } from 'Core/KnockoutExtentions/ToggleExtention';
import { DatetimepickerExtention } from 'Core/KnockoutExtentions/DatetimepickerExtention';
import { EnterKeyExtention } from 'Core/KnockoutExtentions/EnterKeyExtention';
import { EscKeyExtension } from "Core/KnockoutExtentions/EscKeyExtension";
import { KnobExtention } from 'Core/KnockoutExtentions/KnobExtention';
import { CropperExtention } from 'Core/KnockoutExtentions/CropperExtention';
import { EnableAttrExtention } from 'Core/KnockoutExtentions/EnableAttrExtention';
import { AnimateExtention } from 'Core/KnockoutExtentions/AnimateExtention';
import { TinymceExtention } from 'Core/KnockoutExtentions/TinymceExtention';
import { MiniColorsExtention } from 'Core/KnockoutExtentions/MiniColorsExtention';
import { OptionExtention } from 'Core/KnockoutExtentions/OptionExtention';
import { TooltipExtention } from 'Core/KnockoutExtentions/TooltipExtention';
import { EllipsisTooltipExtention } from 'Core/KnockoutExtentions/EllipsisTooltipExtention';
import { BootstrapSwitchExtention } from 'Core/KnockoutExtentions/BootstrapSwitchExtention';
import { BootstrapPopoverExtention } from 'Core/KnockoutExtentions/BootstrapPopoverExtention';
import { ScrollFixerExtention } from 'Core/KnockoutExtentions/ScrollFixerExtention';
import { CustomTableExtention } from 'Core/KnockoutExtentions/CustomTableExtention';
import { UniformExtention } from 'Core/KnockoutExtentions/UniformExtention';
import { SmoothHeightExtention } from 'Core/KnockoutExtentions/SmoothHeightExtention';
import { KnockoutValidationExtensions } from "Core/KnockoutExtentions/KnockoutValidationExtensions";
import { ScreenHeightExtention } from 'Core/KnockoutExtentions/ScreenHeightExtention';
import { TabNavigationExtention } from 'Core/KnockoutExtentions/TabNavigationExtention';
import { AllowBindingsExtention } from 'Core/KnockoutExtentions/AllowBindingsExtention';
import { Canvas } from 'Core/KnockoutExtentions/Canvas';
import { BlockElementExtention } from 'Core/KnockoutExtentions/BlockElement';
import { AutoCompleteExtention } from 'Core/KnockoutExtentions/AutoComplete';
import { SearchExtention } from 'Core/KnockoutExtentions/SearchExtention';
import { TextAreAutogrowExtention } from 'Core/KnockoutExtentions/TextAreAutogrowExtention';
import { DropdownExtention } from 'Core/KnockoutExtentions/DropdownExtention';
import { AlignDropdown } from 'Core/KnockoutExtentions/AlignDropdown';
import { BootstrapMultiselect } from 'Core/KnockoutExtentions/BootstrapMultiselect';
import { BootstrapCollapseExtention } from 'Core/KnockoutExtentions/BootstrapCollapseExtention';
import { ScrollSynchronizingExtention } from 'Core/KnockoutExtentions/ScrollSynchronizingExtention';
import { ForEachPropExtention } from 'Core/KnockoutExtentions/ForEachPropExtention';
import { GroupedCheckboxExtention } from 'Core/KnockoutExtentions/GroupedCheckboxExtention'
import { MultilineEllipsis } from 'Core/KnockoutExtentions/MultilineEllipsis';
import { AddCssForAllExtention } from 'Core/KnockoutExtentions/AddCssForAllExtention'
import { IframeContentExtention } from 'Core/KnockoutExtentions/IframeContentExtention'
import { HasScrollBarExtention } from 'Core/KnockoutExtentions/HasScrollBarExtention';
import { FreezeTableExtention } from 'Core/KnockoutExtentions/FreezeTableExtention';
import { DecimalInputMaskExtension } from "Core/KnockoutExtentions/InputMask/DecimalInputMask";
import { IntegerInputMaskExtension } from "Core/KnockoutExtentions/InputMask/IntegerInputMask";
import { TippyExtention } from "Core/KnockoutExtentions/TippyExtention";
import { ContextMenu } from 'Core/KnockoutExtentions/ContextMenu';
import { PrismExtension } from "../../AdminDashboard/KnockoutExtensions/PrismExtension";
import { JsBarcodeExtension } from "Core/KnockoutExtentions/JsBarcodeExtension";


export class KnockoutExtenions {
	static Init() {
		DragulaExtention.Init();
		DropzoneExtenion.Init();
		FullCalendarExtention.Init();
		InputMaskExtention.Init();
		IntegerInputMaskExtension.Init();
		DecimalInputMaskExtension.Init();
		IntlTelInputExtention.Init();
		DebugExtention.Init();
		ViewerJsExtention.Init();
		DirtyFlagExtention.Init();
		RenderTemplateXExtention.Init();
		SubformResizeExtention.Init();
		PreventBubbleExtention.Init();
		NiceScrollExtention.Init();
		SlimScrollExtention.Init();
		ResizableExtention.Init();
		CustomScrollbarExtention.Init();
		ToggleExtention.Init();
		DatetimepickerExtention.Init();
		EnterKeyExtention.Init();
		EscKeyExtension.Init();
		KnobExtention.Init();
		CropperExtention.Init();
		EnableAttrExtention.Init();
		AnimateExtention.Init();
		TinymceExtention.Init();
		MiniColorsExtention.Init();
		OptionExtention.Init();
		TooltipExtention.Init();
		EllipsisTooltipExtention.Init();
		BootstrapSwitchExtention.Init();
		BootstrapPopoverExtention.Init();
		ScrollFixerExtention.Init();
		CustomTableExtention.Init();
		UniformExtention.Init();
		SmoothHeightExtention.Init();
		KnockoutValidationExtensions.Init();
		ScreenHeightExtention.Init();
		TabNavigationExtention.Init();
		AllowBindingsExtention.Init();
		Canvas.Init();
		BlockElementExtention.Init();
		AutoCompleteExtention.Init();
		SearchExtention.Init();
		TextAreAutogrowExtention.Init();
		DropdownExtention.Init();
		AlignDropdown.Init();
		BootstrapMultiselect.Init();
		BootstrapCollapseExtention.Init();
		ScrollSynchronizingExtention.Init();
		ForEachPropExtention.Init();
		GroupedCheckboxExtention.Init();
		MultilineEllipsis.Init();
		AddCssForAllExtention.Init();
		IframeContentExtention.Init();
		HasScrollBarExtention.Init();
		FreezeTableExtention.Init();
		TippyExtention.Init();
		ContextMenu.Init();
		PrismExtension.Init();
		JsBarcodeExtension.Init();

		ko.bindingProvider.instance = new ErrorHandlingBindingProvider();

		//JQuery draggable
		ko.bindingHandlers.draggable = {
			init: (element, valueAccessor, allBindings, viewModel, bindingContext) => {
				var options = valueAccessor();
				$(element).draggable(options);
				$(element).disableSelection();
				if (options.topPosition) {
					$(element).css('top', options.topPosition());
				}
				if (options.leftPosition) {
					$(element).css('left', options.leftPosition());
				}
			},

			update(element, valueAccessor) {
				var options = valueAccessor();
				if (options.enabled) {
					$(element).draggable(options.enabled() ? "enable" : "disable");
				}
			}
		};

		//JQuery droppable
		ko.bindingHandlers.droppable = {
			init: (element, valueAccessor, allBindings, viewModel) => {
				var options = valueAccessor();
				$(element).droppable(options);
			}
		};
	}
}