import {deserialize, deserializeAs} from 'libs/cerialize';

export class SignInResultModel {
    @deserializeAs(v => v === 'True')
    public LicenseExpiresSoon: boolean;

    @deserialize
    public LicenseExpiresIn: Date;

    @deserializeAs(v => v === 'True')
    public PasswordExpiresSoon: boolean;

    @deserialize
    public PasswordExpiresIn: number;

    @deserializeAs(v => v === 'True')
    public SBIDesigner: boolean;

    @deserializeAs('access_token')
    public AuthToken: string;

    @deserializeAs('refresh_token')
    public RefreshToken: string;

    public ErrorMessage: string;

    public ErrorCode: number;

    static CreateErrorResult(errorMessage: string, errorCode: number) {
        const model = new SignInResultModel();
        model.ErrorMessage = errorMessage;
        model.ErrorCode = errorCode;

        return model;
    }
}