import {deserializeAs, GenericDeserialize} from 'libs/cerialize';

import {SpreadsheetDescriptorDto} from './SpreadsheetDescriptorDto';
import {SpreadsheetPageDataDto} from './SpreadsheetPageDataDto';

export class SpreadsheetDataDto {
    @deserializeAs(SpreadsheetDescriptorDto)
    Descriptor: SpreadsheetDescriptorDto;

    @deserializeAs(SpreadsheetPageDataDto)
    Pages: SpreadsheetPageDataDto[];

    constructor() {
        this.Pages = [];
    }

    static Deserialize(jsonObj): SpreadsheetDataDto {
        return GenericDeserialize<SpreadsheetDataDto>(jsonObj, SpreadsheetDataDto);
    }
}