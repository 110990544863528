import {FastFilter} from 'Core/Controls/Grid/BaseGrid/FastFilter/FastFilter';
import {GridColumnModel} from 'Core/Controls/Grid/Models/GridDataModel/GridColumnModel';
import {EVENTS} from 'Core/Controls/Grid/BaseGrid/Events';
import {IGetGridDataRequestModel} from 'Core/Controls/Grid/Stores/GridStore';
import {FastFilterValue, FastFilterItem} from 'Core/Controls/Grid/BaseGrid/FastFilter/FastFilterModels';

import YesnoFilterTemplate from 'Core/Controls/Grid/BaseGrid/FastFilter/Filters/Yesno/YesnoFilter.html';

export class YesnoFilter extends FastFilter {
	constructor(model: GridColumnModel,
				recordId: number,
				tableViewId: number,
				getGridDataModel: IGetGridDataRequestModel,
				existedColumnAliases: string[]) {
		super(model, recordId, tableViewId, getGridDataModel, existedColumnAliases);

		this._initialDataMapped.subscribe((value) => {
			// const viewModel =
			// 	this._viewModelItem()
			// 		.map((item) => {
			// 			return {
			// 				Value: item.Value === 'True' ? '1' : '0',
			// 				DisplayValue: item.Value === 'True' ? 'True' : 'False',
			// 				Checked: item.Value === 'True'
			// 			};
			// 		})
			// 		.reduce((acum, item) => {
			// 			return acum.findIndex((elem) => elem.Value === item.Value) < 0
			// 				? [...acum, item]
			// 				: acum
			// 		}, []);
			//
			// this._viewModelItem(viewModel);
		});

		this.Preselect();
	}

	protected MapToViewModel() {
		const viewModelList: FastFilterItem[] =
			this._availableItems().map((item: FastFilterValue) => {
				const wasItemChecked = this.RestoreSelectedCheckbox(item);
				let value;

				switch (item.Value) {
					case 'True':
					case 1:
						value = 1;
						break;
					case 'False':
					case 0:
						value = 0;
						break;
					case null:
						value = null;
						break;
					default:
						value = null;
						break;
				}

				const viewModelItem: FastFilterItem = {
					Value: value,
					DisplayValue: item.DisplayValue || this._labels.EMPTY_VALUE,
					Checked: wasItemChecked
				};

				this._preselectedRecords().forEach((preselected) => {
					if (value === preselected.Value && !this._storedCheckboxes) {
						viewModelItem.Checked = true;
					}
				});

				return viewModelItem;
			});

		this._viewModelItem(viewModelList);
		this._initialDataMapped(true);
	}

	protected Filter() {
		this.MapToSaveModel();

		this.Trigger(EVENTS.FAST_FILTER);
	}

	GetTemplate() {
		return YesnoFilterTemplate;
	}
}