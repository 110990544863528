import * as ko from 'knockout';

import {ConfiguratorLevels} from 'Core/Components/Controls/ProductConfigurator/ConfiguratorLevels';

import {PriceItem} from "./PriceItem";
import {PriceList} from "./PriceList";
import {
    ConfigurationPageEvents,
    PriceProductNavigatedEventArgs,
    SelectProductPartEventArgs
} from "../Events/ConfigurationPageEvents";

export class ProductPrice extends PriceItem {
    Quantity: KnockoutObservable<number>;
    ComparisonQuantity: KnockoutObservable<number>;

    constructor(id: number, name: string, nameTranslated: string, price: number, quantity: number, public GroupId: number, public GroupName: string, public GroupNameTranslation: string, public RootGroupId: number, public RootGroupName: string, public Path: number[], public KSeq: number, public KSeqGuid: string) {
        super(id, name, nameTranslated, price);

        this.Quantity = ko.observable(quantity);
        this.ComparisonQuantity = ko.observable(null);
    }

    get PositionPrice() {
        return this.Price() * this.Quantity();
    }

    get ComparisonPositionPrice() {
        return this.ComparisonPrice() * this.ComparisonQuantity();
    }

    get PositionAlternativePrice() {
        return this.AlternativePrice() * this.Quantity();
    }

    get ComparisonPositionAlternativePrice() {
        return this.ComparisonAlternativePrice() * this.ComparisonQuantity();
    }

    CannotRemovePriceItem(priceList: PriceList) {
        const isNotMainLevel = priceList.MainLevel && priceList.ActiveLevel && priceList.MainLevel !== priceList.ActiveLevel;
        return this._inactive() || priceList.IsActiveLevelLocked || isNotMainLevel;
    }

    private RemovePriceItem(product: ProductPrice) {
        let isRemoveFromPriceItem: boolean = true;

        this.DispatchEvent<SelectProductPartEventArgs>(ConfigurationPageEvents.UnselectProduct,
            new SelectProductPartEventArgs(product.Id, product.GroupId, product.GroupName, product.Path, product.KSeq, product.KSeqGuid, isRemoveFromPriceItem));

        if (product.GroupId === product.RootGroupId && product.GroupName === product.RootGroupName) {
            this.DispatchEvent(ConfigurationPageEvents.LoadConversionDropdownsFromRemovePriceItem, product);
        }
    }

    CanNavigateToProduct(product: ProductPrice, priceList: PriceList) {
        const onlyOneLevel = !_.any(priceList.Levels());
        if (onlyOneLevel) {
            return product.Price() != null;
        }

        if (!priceList.ActiveLevel) {
            return false;
        }

        const activeLevelPrice = priceList.ActiveLevel === priceList.MainLevel
            ? product.Price()
            : product.ComparisonPrice();
        return activeLevelPrice != null;
    }

    CannotNavigateToProduct(product: ProductPrice, priceList: PriceList) {
        return !this.CanNavigateToProduct(product, priceList);
    }

    private NavigateToProduct(priceList: PriceList, product: ProductPrice) {
        const canNavigateToProduct = product.CanNavigateToProduct(product, priceList);
        if (canNavigateToProduct) {
            const onlyOneLevel = !_.any(priceList.Levels());

            this.DispatchEvent(ConfigurationPageEvents.ProductNavigatedInPriceList,
                new PriceProductNavigatedEventArgs(product.Id, product.GroupId, product.GroupName, product.RootGroupId, product.RootGroupName, product.Path, product.KSeq, product.KSeqGuid, onlyOneLevel, priceList.ActiveLevel));
        }
    }
}