(function ($) {
	"use strict";

	var TimePickerBootstrap = function (options) {
		this.init('timePickerBootstrap', options, TimePickerBootstrap.defaults);
		this.initPicker(options, TimePickerBootstrap.defaults);
	};

	$.fn.editableutils.inherit(TimePickerBootstrap, $.fn.editabletypes.abstractinput);

	$.extend(TimePickerBootstrap.prototype, {
		initPicker: function(options, defaults) {
			options.timePickerBootstrap = $.fn.editableutils.tryParseJson(options.timePickerBootstrap, true);
		},

		render: function () {
			this.$input = this.$tpl;
			this.$input.datetimepicker(this.options.timePickerBootstrap);
		},
		value2html: function (value, element) {
			if (!value) {
				$(element).empty();
				return;
			}
			var textValue = '';
			if (value.current != undefined && value.current != null) {
				textValue = value.current;
			}
			if (value.default != undefined && value.default != null) {
				textValue = value.default;
			}
			$(element).html(textValue);
		},
		value2input: function (value) {
			if (!value) {
				return;
			}
			if (value.current != undefined && value.current != null) {
				this.$input.val(value.current);
				return;
			}
			if (value.default != undefined && value.default != null) {
				this.$input.val(value.default);
				return;
			}
		},
		input2value: function () {
			return {
				current: this.$input.val()
			}
		},
		activate: function () {
			this.$input.focus();
		}
	});

	TimePickerBootstrap.defaults = $.extend({}, $.fn.editabletypes.abstractinput.defaults, {
		tpl: '<input type="text" class="editable-datetimepicker"/>',
		emptytext: 'Empty',
		timePickerBootstrap: {
			locale: 'nl',
			format:'YYYY-MM-DD',
			widgetPositioning: {
				horizontal: 'right',
				vertical: 'bottom',
				forceParse: false
			}
		}
	});

	$.fn.editabletypes.timePickerBootstrap = TimePickerBootstrap;

}(window.jQuery));
