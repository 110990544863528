import * as ko from 'knockout';
import * as _ from 'underscore';
import {BasketRecordModel} from 'Core/Controls/Basket/Models/BasketDataModel';
import {FollowUpLifeStatuses} from 'Core/Controls/ButtonFollowUp/FollowUpLifeStatuses';
import {Event} from 'Core/Common/Event';
import {FollowUpStore} from "Core/Controls/ButtonFollowUp/Stores/FollowUpStore";
import {FollowUpRecordModel} from "Core/Controls/ButtonFollowUp/Models/FollowUpRecordModel";
import { FollowUpOptionModel } from "Core/Controls/ButtonFollowUp/Models/FollowUpOptionModel";

export class BasketRecord extends Event {
	private _records: KnockoutObservableArray<number>;
	private _isSelected: KnockoutObservable<boolean>;
	private _recordsCount: KnockoutComputed<number>;
	private _nextStatusSelector: FollowUpLifeStatuses;
	private _recordId: number;
	private _typeId: number;
	private _title: string;

	constructor(model: BasketRecordModel, entityId: number) {
		super();
		this.AddEvent('LIFESTATUS_CHOOSEN');
		this._recordId = model.Id;
		this._typeId = model.TypeId;
		this._title = model.Name;
		this._records = ko.observableArray(model.Records);
		this._isSelected = ko.observable(false);
		this._recordsCount = ko.computed(() => {
			return this._records().length;
		});
        
        if (model.LifeStatuses && model.LifeStatuses.CurrentLifeStatus && model.LifeStatuses.LifeStatuses.length > 0) {
			this._nextStatusSelector = new FollowUpLifeStatuses();

			const followUpOption = new FollowUpOptionModel();
			followUpOption.LifeStatuses = <any>model.LifeStatuses;
			this._nextStatusSelector.Init(followUpOption, null, null);

			this._nextStatusSelector.On('LIFESTATUS_CHOOSEN', this, (eventargs) => {
				if (eventargs.data.LifeStatus.Id) {
					this.Trigger('LIFESTATUS_CHOOSEN', {LifestatusId: eventargs.data.LifeStatus.Id});
				}
			});
		}
	}

	get RecordId(): number {
		return this._recordId;
	}

	get TypeId(): number {
		return this._typeId;
	}

	get Title(): string {
		return this._title;
	}

	get Records(): KnockoutObservableArray<number> {
		return this._records;
	}

	SetIsSelected(value: boolean) {
		this._isSelected(value);
	}

	get IsSelected(): KnockoutObservable<boolean> {
		return this._isSelected;
	}

	get RecordsCount(): KnockoutComputed<number> {
		return this._recordsCount;
	}

	get NextStatusSelector(): FollowUpLifeStatuses {
		return this._nextStatusSelector;
	}
}