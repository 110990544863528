import {deserialize, deserializeAs, GenericDeserialize} from 'libs/cerialize';
import {GridDataModel} from 'Core/Controls/Grid/Models/GridDataModel/GridDataModel';
import {GridCountCellValueModel} from 'Core/Controls/Grid/Models/GridDataModel/GridCountCellValueModel';

export class GridCountRowModel {

	@deserializeAs(GridCountCellValueModel)
	Data: Array<GridCountCellValueModel>;

	@deserialize
	Guid: string;

	constructor() {
		this.Data = [];
	}
}