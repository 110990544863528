import * as ko from 'knockout';

import 'intl-tel-input';
import 'intl-tel-input-utils';
import {ZIndexManager} from 'Core/Common/ZIndexManager';

export class IntlTelInputExtention {
    static Init() {
        ko.bindingHandlers.intlTelInput = {
            init: (element, valueAccessor) => {
                const params = valueAccessor();
                const options = params.options;
                const isModal: boolean = !!options.isModal;
                const isDropdownContainer: boolean = !!options.dropdownContainer;
                const screen = options.screen;

                const iti = window.intlTelInput(element, options);
                const country = iti.getSelectedCountryData();

                if (country) {
                    if (params.listener) {
                        params.listener(iti.getSelectedCountryData(), false);
                    }
                }

                $(element).on('countrychange', () => {
                    $(element).val('');

                    params.listener(iti.getSelectedCountryData(), true);
                });

                if (isModal && isDropdownContainer){
                    $(iti.dropdown).css('zIndex', ZIndexManager.Instance.NextValue);

                    $(screen._modal._wrapper).parents('.jBox-content').on('scroll', () => this.OnScroll(iti));
                }
            }
        }
    }

    static OnScroll(iti) {
        iti._2();   //in the library this is key: "_closeDropdown";
                    //here you can take the developer file: https://cdnjs.com/libraries/intl-tel-input/14.0.6
    }

    static IsCountryValid(country: string): boolean {
        const allCountries = window.intlTelInputGlobals.getCountryData();
        const selectedCountry = _.find(allCountries, item => item.iso2 === country);
        return !!selectedCountry;
    }

    static GetCodeByIso2(iso2: string): string {
        const allCountries = window.intlTelInputGlobals.getCountryData();
        const selectedCountry = _.find(allCountries, item => item.iso2 === iso2);

        return selectedCountry ? selectedCountry.dialCode: '31';
    }
}