import {deserialize, deserializeAs} from 'libs/cerialize';
import {GenericButtonModel} from 'QueryBuilder/Models//GenericButtonModel';

export class QueryScreenModel {

	constructor(){
		this.GenericButtons = [];
	}

	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserializeAs(GenericButtonModel)
	GenericButtons: Array<GenericButtonModel>;
}