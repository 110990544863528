import * as ko from 'knockout';
import * as _ from 'underscore';
import * as $ from 'jquery';

import {GenericDeserialize, Serialize} from 'libs/cerialize';

import 'pubsub';
import 'lockr';

import {LOCAL_STORAGE} from 'Core/Common/Enums/LocalStorageItems';
import {FileDownloader} from 'Core/Components/FileDownloader/FileDownloader';
import {
    GridStore,
    IExportDataRequestModel,
    IGetGridDataRequestModel, IUnlinkMultipleRecordsRequestModel,
    IUnlinkRecordRequestModel
} from 'Core/Controls/Grid/Stores/GridStore';
import {IGetGridViewListDto, ViewGridListStore} from 'Core/Controls/Grid/Stores/ViewGridListStore';
import {ViewGridModel} from 'Core/Controls/Grid/Models/ViewGridModel';
import {GridDataModel} from 'Core/Controls/Grid/Models/GridDataModel/GridDataModel';
import {GridColumnModel} from 'Core/Controls/Grid/Models/GridDataModel/GridColumnModel';
import {QueryBuilder} from '../../../QueryBuilder/QueryBuilder';
import {QueryBuilder as QueryBuilderControl} from 'Core/Controls/QueryBuilder/QueryBuilder';
import {Notifier} from 'Core/Common/Notifier';
import {OperationResultModel} from 'Core/Models/OperationResultModel';
import {BaseGrid} from 'Core/Controls/Grid/BaseGrid/BaseGrid';
import {Paginator} from 'Core/Components/Paginator/Paginator';
import {CONTROL_TYPES, RenderModes, TABLE_TYPES} from 'Core/Constant';
import {GeneralProperties} from 'Core/GeneralProperties/GeneralProperties';
import {BaseControl, IControlValue} from 'Core/Controls/BaseControl/BaseControl';
import {IControlParam} from 'Core/Screens/IScreen';
import {IControl} from 'Core/Controls/IControl';
import {Toolbar} from 'Core/Controls/Grid/Toolbar/Toolbar';
import {EVENTS as TOOLBAR_EVENTS} from 'Core/Controls/Grid/Toolbar/Constants';
import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel';
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel';
import {SearchScreen, ISelectedRecord} from 'Core/Screens/SearchScreen/SearchScreen';
import {INewLinkedRecord, RecordLinker} from 'Core/Components/RecordLinker/RecordLinker';
import {TypeScreen} from 'Core/Screens/TypeScreen/TypeScreen';
import {DataModel} from 'Core/Screens/EditScreen/Models/DataModel';
import {QueryResultPage} from 'Core/Controls/Grid/BaseGrid/QueryResultPage/QueryResultPage';
import {ILinkRecordsRequestModel, LinkRecordsStore} from 'Core/Controls/Grid/Stores/LinkRecordsStore';
import {GlobalManager, GLOBALS} from 'Core/GlobalManager/GlobalManager';
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';
import {ViewModes} from 'Core/Controls/Grid/BaseGrid/Enums/ViewModes';
import {BlockUI} from 'Core/Common/BlockUi';
import {EVENTS as BASE_GRID_EVENTS} from 'Core/Controls/Grid/BaseGrid/Events';
import {EVENTS as PAGINATOR_EVENTS} from 'Core/Components/Paginator/Constants';
import {EntityTypes} from 'Core/Controls/Grid/BaseGrid/Enums/EntityTypes';
import {EditLinkDataModel, UpdateDataModel} from 'Core/Controls/LinkList/Models/UpdateDataModel';
import {InsertOrUpdateRecordStore} from 'Core/Screens/EditScreen/Stores/InsertOrUpdateRecordStore';
import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';
import {EVENTS as BASKET_EVENTS} from 'Core/Controls/Basket/Events';

import GridConfig from 'Core/Controls/Grid/Configs/grid-config.json';

import ViewTemplate from 'Core/Controls/Grid/Templates/View.html';
import HelpViewTemplate from 'Core/Controls/Grid/Templates/HelpView.html';
import DesignTemplate from 'Core/Controls/Grid/Templates/Design.html';
import ToolBarTemplate from 'Core/Controls/Grid/Templates/ToolBar.html';

import {QueryExpressionModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import {LOCK_EVENTS, LockManager} from 'Core/Components/Locker/LockManager';
import {ILoadScreenRelationModel, LinkEditor} from 'Core/Screens/Common/LinkEditor/LinkEditor';
import {EVENTS as CONSULT_SCREEN_EVENTS} from 'Core/Screens/ConsultScreen/Events';
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';
import {GridRow} from 'Core/Controls/Grid/BaseGrid/GridRow/GridRow';
import {QueryConditionGroupModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryConditionGroup';
import {ConditionBuilder} from 'QueryBuilder/QueryCondition/ConditionBuilder/ConditionBuilder';
import {DeleteQueryStore} from 'QueryBuilder/Stores/DeleteQueryStore';
import {LockQueryStore} from 'QueryBuilder/Stores/LockQueryStore';
import {P} from 'Core/Common/Promise';

import {IParams as BackLinkingParams} from "Core/Components/BackLinking/IParams";
import {BackLinking} from "Core/Components/BackLinking/BackLinking";

import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS,
    Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import {BaseScreen, IScreenVariable} from 'Core/Screens/BaseScreen';
import {Basket} from 'Core/Controls/Basket/Basket';
import {GridRowDataModel} from 'Core/Controls/Grid/Models/UpdateDataModel/UpdateDataModel';
import {CONFIRMATIONS, LABELS, NOTIFICATIONS} from 'Core/Components/Translation/Locales';
import {RecordSpecsModel} from 'Core/ScreenManager/Models/RecordSpecsModel';
import {ActionSubjectRecordModel} from 'Core/ScreenManager/Models/ActionSubjectRecordModel';
import {SortModel} from 'Core/Controls/Grid/Models/SortModel';
import {EditScreen} from 'Core/Screens/EditScreen/EditScreen';
import {TRIGGER_ACTIONS} from 'Core/Constants/TriggerActions';
import {TriggerModel} from 'Core/Controls/BaseControl/Models/TriggerModel';
import {GridCellValueModel, RecordKey} from 'Core/Controls/Grid/Models/GridDataModel/GridCellValueModel';
import {LinkList} from "../LinkList/LinkList";
import {BarcodeScanner} from "Core/Components/BarcodeScanner/BarcodeScanner";
import {DataModes} from "Core/Enums/DataModes";
import {MobileChecker} from "Core/Common/MobileChecker";
import {EVENTS as QUERY_RESULT_GRID_EVENTS} from "./BaseGrid/QueryResultPage/Events";
import {GridRowModel} from "./Models/GridDataModel/GridRowModel";
import {PivotDetailsDropdown} from "./BaseGrid/PivotDetaillsDropdown/PivotDetailsDropdown";
import {GenericButton} from "../GenericButton/GenericButton";
import {ButtonCopy} from '../ButtonCopy/ButtonCopy';
import {DEFAULT_RECORDS_PER_PAGE, PROPERTIES, SECURITY_FILTERS} from "./Constants";
import {QueryEntityJoinModel} from './Models/GridDataModel/QueryExpression/QueryEntityJoinModel';
import {TableStore} from "Core/Common/Stores/TableStore";
import {EntityTypesStore} from 'Core/Screens/TypeScreen/Stores/EntityTypesStore';
import {States} from './BaseGrid/GridRow/States';
import {UserManager, UserRoles, UserTypes} from '../../../User/UserManager';
import {ProfileSelectorScreen} from "Core/Screens/ProfileSelectorScreen/ProfileSelectorScreen";
import { TriggerManager } from 'Core/Components/Triggers/TriggerManager';
import { FunctionBuilder } from 'Core/Components/CustomFunctions/FunctionBuilder';
import { gridFacade } from 'Core/Components/CustomFunctions/Facades/GridFacade';
import { EVENTS as SCREEN_EVENTS } from 'Core/Screens/Events';
import { Util } from 'QueryBuilder/Util';
import {FastFilterSaveModel, QueryColumnModel, RelationshipTypes} from './BaseGrid/Index';
import {CheckedUserAndGroup} from "Core/Controls/SelectUser/SelectUser";
import { RecordStore } from '../../Common/Stores/RecordStore';

ko.templates['Core/Controls/Grid/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/Grid/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Grid/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/Grid/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/Grid/Templates/ToolBar'] = ToolBarTemplate;

export interface ILinkForEditScreenOptions{
    recordIds: number[],
    updateLinkRecord?: boolean,
    isLinkParent?: boolean
}

export class Grid extends BaseControl {
    private _gridEntityId: number;
    private _subjectEntityName: string;
    private _subjectEntityId: number;
    private _subjectRecordId: number;
    private _subjectRecordSpecsModel: RecordSpecsModel;
    private _dataModel: GridDataModel;
    private _tableView: KnockoutObservable<ViewGridModel>;
    private _viewGridList: KnockoutObservableArray<ViewGridModel>;
    private _queryBuilder: KnockoutObservable<QueryBuilder>;
    private _enableLink: KnockoutObservable<boolean>;
    private _enableUnlinkMultiple: KnockoutObservable<boolean>;
    private _enableLinkParent: KnockoutObservable<boolean>;
    private _hasOneParent: KnockoutObservable<boolean>;
    private _enableAddAndLink: KnockoutObservable<boolean>;
    private _enableAddFromQuery: KnockoutObservable<boolean>;
    private _enableScanAndLink: KnockoutObservable<boolean>;
    private _showTableViewElement: KnockoutObservable<boolean>;
    private _enableNewRecord: KnockoutObservable<boolean>;
    private _enableBasket: KnockoutObservable<boolean>;
    private _showSearchInput: KnockoutObservable<boolean>;
    private _userVarsManager: UserVarsManager;
    private _viewMode: ViewModes;
    private _baseGrid: BaseGrid;
    private _sorting: Array<SortModel>;
    private _searchPhrase: KnockoutObservable<string>;
    private _isEditable: KnockoutObservable<boolean>;
    private _hideLifeStatusToggle: KnockoutObservable<boolean>;
    private _hideUnlink: KnockoutObservable<boolean>;
    private _hideEdit: KnockoutObservable<boolean>;
    private _enableTableViewLookup: boolean;
    private _enableFunctionLookup: boolean;
    private _toolbar: Toolbar;
    private _loadInProgress: KnockoutObservable<boolean>;
    private _designFields: KnockoutObservableArray<AttachedFieldModel>;
    private _isRestoreScrollPosition: boolean;
    private _queryExpression: QueryExpressionModel;
    private _tableViewId: number;
    private _addingRecordAllowed: KnockoutObservable<boolean>;
    private _linkingRecordAllowed: KnockoutObservable<boolean>;
    private _exportDataAllowed: KnockoutObservable<boolean>;
    private _scanAndLinkAllowed: KnockoutObservable<boolean>;
    private _conditions: QueryConditionGroupModel;
    private _isLinkEditorEnabled: boolean;
    private _filterByOwners: boolean;
    private _backLinkingButtons: KnockoutObservableArray<any>;
    private _selectedUsers: number[];
    private _showPlanned: boolean;
    private _selectedTags: any;
    private _applyFilter: boolean;
    private _maxRowHeight: KnockoutObservable<string>;
    private _basket: Basket;
    private _showTogglePrioritiesButton: boolean;
    private _isPrioritiesDisabled: boolean;
    private _isTableViewUpdating: KnockoutObservable<boolean>;
    private _showBulkEditButton: KnockoutObservable<boolean>;
    private _disabledShowAllColumnsBtn: boolean;
    private _disableBulkEditButton: KnockoutObservable<boolean>;
    private _showResetFilterButton: KnockoutObservable<boolean>;
    private _showRetiredRecords: boolean;
    private _inlineControls: Array<IControl>;
    private _tableViewUpdatingSubscription: KnockoutSubscription;
    private _searchScreen: SearchScreen;
    private _toolbarBlock: HTMLElement;
    private _hasData: boolean;
    private _rowColorEnabled: KnockoutObservable<boolean>;
    private _hasLinkingCondition: boolean = false;
    private _prevPageNumber = 1;
    private _disabledCopyButton: KnockoutObservable<boolean>;
    private _notifications = NOTIFICATIONS;
    private _isDirectionCorrect: boolean;
    private _refreshScreenAfterManipulations: boolean;
    private _isMultipleUnlinkModeActive: KnockoutObservable<boolean> = ko.observable(false);
    private _isFastFilter: boolean
    private _isEnableSelectRecord: KnockoutObservable<boolean>;

    constructor(param: IControlParam) {
        super(param, GridConfig);

        this._isEnableSelectRecord = ko.observable(false);
        this._isTableViewUpdating = ko.observable(false);
        this._designFields = ko.observableArray(this._model().Fields);    
        this._model.subscribe(_ => this._designFields(this._model().Fields));
        this._designFields.subscribe(fields => _.each(fields, (field, index) => field.Sort = index * 10));
        this._tableView = ko.observable(null);
        this._addingRecordAllowed = ko.observable(false);
        this._linkingRecordAllowed = ko.observable(false);
        this._exportDataAllowed = ko.observable(false);
        this._scanAndLinkAllowed = ko.observable(false);
        this._isEditable = ko.observable(true);
        this._hideLifeStatusToggle = ko.observable(false);
        this._hideUnlink = ko.observable(false);
        this._hideEdit = ko.observable(false);
        this._enableLink = ko.observable(false);
        this._enableUnlinkMultiple = ko.observable(false);
        this._enableLinkParent = ko.observable(false);
        this._hasOneParent = ko.observable(false);
        this._enableAddAndLink = ko.observable(false);
        this._enableAddFromQuery = ko.observable(false);
        this._enableScanAndLink = ko.observable(false);
        this._showTableViewElement = ko.observable(false);
        this._showSearchInput = ko.observable(true);
        this._enableNewRecord = ko.observable(false);
        this._enableBasket = ko.observable(false);
        this._loadInProgress = ko.observable(false);
        this._maxRowHeight = ko.observable(null);
        this._showBulkEditButton = ko.observable(false);
        this._disabledShowAllColumnsBtn = false;
        this._disableBulkEditButton = ko.observable(false);
        this._showResetFilterButton = ko.observable(false);
        this._filterByOwners = false;
        this._backLinkingButtons = ko.observableArray([]);
        this._applyFilter = true;
        this._showTogglePrioritiesButton = this.ScreenType !== ScreenTypes[ScreenTypes.Portlet];
        this._isPrioritiesDisabled = !this._showTogglePrioritiesButton;
        this._showRetiredRecords = false;
        this._inlineControls = [];
        this._userVarsManager = UserVarsManager.Instance;
        this._hasData = false;
        this._rowColorEnabled = ko.observable(false);
        this._disabledCopyButton = ko.observable(true);
        this._refreshScreenAfterManipulations = this.IsConsultScreen;
        this._isFastFilter = this.GeneralProperties.GetPropertyValue(PROPERTIES.FAST_FILTER);

        this.AddEvent('START_DATA_LOAD');
        this.AddEvent('DATA_LOADED');

        if (this._renderMode() !== RenderModes.Design && this._renderMode() !== RenderModes.ToolBar) {
            this._viewMode = this.GetTableView();
            this._searchPhrase = ko.observable('');

            this._enableTableViewLookup = this.ScreenType !== ScreenTypes[ScreenTypes.QueryScreen] && this._model().EntityTypeName !== EntityTypes[EntityTypes.Sub];
            this._enableFunctionLookup = this.ScreenType !== ScreenTypes[ScreenTypes.QueryScreen] && this._model().EntityTypeName !== EntityTypes[EntityTypes.Sub];

            this._selectedUsers = [];

            this._dataModel = new GridDataModel();
            this._queryBuilder = ko.observable(null);
            this._viewGridList = ko.observableArray([]);

            this._isEnableSelectRecord(_.any(this.GetToolbarControls(), (item) => item instanceof GenericButton));

            PubSub.subscribe(PUB_SUB_EVENTS.EXECUTE_SCRIPT, (_, data) => {
                if(data && data.ObjectId === this.GetGuid()){
                     FunctionBuilder.Execute(data.Code, data.Params, gridFacade(this));
                }
             });

            this._baseGrid = new BaseGrid({
                isEnableSelectRecord: this._isEnableSelectRecord,
                isEditable: this._isEditable,
                hideLifeStatusToggle: this._hideLifeStatusToggle,
                hideUnlink: this._hideUnlink,
                hideEdit: this._hideEdit,
                enableBasket: this._enableBasket,
                backLinkingButtons: this._backLinkingButtons,
                screenType: this.ScreenType,
                maxRowHeight: this.MaxRowHeight(),
                isPrioritiesDisabled: this.GetGridPriorityToggle(),
                form: this._form,
                ToggleAllShownColumns: this.ToggleAllShownColumns.bind(this),
                properties: this.GeneralProperties
            });

            this._baseGrid.On(BASE_GRID_EVENTS.UPDATE_GRID, this, eventArgs => {
                if (eventArgs.data.updateFromFastFilters) {
                    if (this.Paginator.PageNumber != 1) {
                        this.Paginator.Reset();
                    } else {
                        this.LoadData(true);
                    }
                }
            });

            const defaultColumns = [];

            _.each(this._model().Fields, (item: any) => {
                const columnModel = new GridColumnModel();

                columnModel.Title = `${item.Name}`;
                defaultColumns.push(columnModel);
            });

            this._baseGrid.AddColumns(defaultColumns);

            this._baseGrid.On(TOOLBAR_EVENTS.LINK_RECORD, this, (eventArgs) => {
                this.LinkSubGridRecord(eventArgs.data.MainEntityId,eventArgs.data.MainRecordId, eventArgs.data.RelatedEntityId);
            });

            this._baseGrid.On(TOOLBAR_EVENTS.LINK_PARENT_RECORD, this, (eventArgs) => {
                this.LinkSubGridParentRecord(eventArgs.data.Row, eventArgs.data.RelatedEntityId);
            });

            this._baseGrid.On(TOOLBAR_EVENTS.ADD_AND_LINK_RECORD, this, async (eventArgs) => {
                let recordSpecs = await RecordStore.GetRecordSpecs({ TableId: eventArgs.data.MainEntityId, RecordId: eventArgs.data.MainRecordId });
                this.OnAddAndSubGridLinkRecord(eventArgs.data.MainEntityId, eventArgs.data.MainRecordId, recordSpecs.TypeId, recordSpecs.LifestatusId, eventArgs.data.RelatedEntityId);
            });

            this._baseGrid.On(TOOLBAR_EVENTS.SCAN_AND_LINK_RECORD, this, (eventArgs) => {
                this.ScanAndLinkSubGridRecord(eventArgs.data.MainEntityId, eventArgs.data.MainRecordId, eventArgs.data.RelatedEntityId);
            });

            this._baseGrid.On(BASE_GRID_EVENTS.SORT, this, (eventArgs: any) => {
                this.SortData(eventArgs.data.SortColumns);
            });

            this._baseGrid.On(BASE_GRID_EVENTS.OPEN_HYPERLINK, this, (eventArgs: any) => {
                UserVarsManager.Instance.AddRecent(eventArgs.data.EntityId, eventArgs.data.RecordId, eventArgs.data.RecordTypeId);
                Lockr.set(LOCAL_STORAGE.HYPERLINK_SOURCE, this.GetForm().GetScreen().GetEntityId().toString())

                this.GoToRecordScreen(eventArgs.data);
            });

            this._baseGrid.On(BASE_GRID_EVENTS.DATA_SAVED, this, (eventArgs: any) => {
                const updateResult: OperationResultModel = eventArgs.data.UpdateResult;
                let notifier: Notifier;

                if (updateResult.IsSuccessfull) {
                    notifier = new Notifier(null);
                    notifier.Success(NOTIFICATIONS.DATA_SAVED);
                    this.LoadData(false, eventArgs.data.SubGrid, eventArgs.data.ParentRowId, eventArgs.data.UpdateRow);
                } else {
                    notifier = new Notifier(null);
                    const message = eventArgs.data.UpdateResult.ErrorMessage? eventArgs.data.UpdateResult.ErrorMessage : NOTIFICATIONS.DATA_IS_NOT_SAVED;
                    notifier.Failed(message);
                }
            });

            this._baseGrid.On(BASE_GRID_EVENTS.REFRESH, this, (eventArgs) => {
                if (this._refreshScreenAfterManipulations) {
                    this.GetForm().GetScreen().Refresh();
                    return;
                }

                if (this._baseGrid.RowsCount == 0) {
                    this._toolbar.Paginator().Reset();
                } else {
                    this.LoadData(false, eventArgs.data.SubGrid, eventArgs.data.ParentRowId, eventArgs.data.UpdateRow);
                }
            });

            this._baseGrid.On(BASE_GRID_EVENTS.NO_CHANGES, this, (eventArgs: any) => {
                const notifier = new Notifier(null);

                notifier.Success(NOTIFICATIONS.NO_PENDING_CHANGES);
            });

            this._baseGrid.On(BASE_GRID_EVENTS.EDIT_LINK, this, (eventArgs: any) => {
                let gridRow: GridRow = eventArgs.data.gridRow;
                let mainEntityId: number = eventArgs.data.mainEntityId;
                let mainRecordId: number = eventArgs.data.mainRecordId;
                const KSeq = gridRow.KSeq;

                if(gridRow && mainEntityId && mainRecordId){
                    this.UpdateSubGridLinkRecord(mainEntityId, mainRecordId, gridRow);
                }
                else if (this._queryExpression.IsCrossTable) {
                    this.ShowCrossTableDetail(gridRow).then((selectedRow) => {
                        this.UpdateLinkRecord(gridRow.EntityId, gridRow.RecordId, gridRow.RecordTypeId, selectedRow.KSeq, gridRow.RelationshipType);
                    });
                } else {
                    this.UpdateLinkRecord(gridRow.EntityId, gridRow.RecordId, gridRow.RecordTypeId, KSeq, gridRow.RelationshipType);
                }
            });

            this._baseGrid.On(BASE_GRID_EVENTS.EDIT_CLUSTERED_LINK, this, (eventArgs: any) => {
                let gridRow: GridRow = eventArgs.data.gridRow;
                let grid: BaseGrid = eventArgs.data.grid;
                if (gridRow && grid) {
                    this.UpdateLinkRecord(
                        grid.SubjectEntityId,
                        grid.SubjectRecordId,
                        0,
                        gridRow.KSeq,
                        1,
                        gridRow.EntityId,
                        gridRow.RecordId
                    )
                }
            });

            this._baseGrid.On(BASE_GRID_EVENTS.LINK_NEXT_RELATION, this, (eventArgs: any) => {
                let gridRow: GridRow = eventArgs.data.gridRow;
                const RecordKeys = eventArgs.data.field.RecordKeys as Array<RecordKey>;
                if (RecordKeys.length < 3) {
                    return;
                }

                GridStore.LinkWithNextRelationType(RecordKeys)
                    .then((data) => {
                        const newKSeq = data.Sequence;

                        var updateLinkRecordPromise = this.UpdateLinkRecord(
                            data.MainEntityId,
                            data.MainRecordId,
                            data.MainRecordTypeId,
                            data.Sequence,
                            gridRow.RelationshipType,
                            data.RelatedEntityId,
                            data.RelatedRecordId,
                            0,
                            true
                        );

                        if (updateLinkRecordPromise) {
                            updateLinkRecordPromise.then(async () => {

                                var gridCellValue = eventArgs.data.field as GridCellValueModel;

                                var kSeqMetadata = _.find(gridCellValue.EntityMetadata.Fields, (item) => {
                                    return item.Name === 'K_SEQ'
                                });

                                var oldKSeqValue = _.find(RecordKeys, (item) => {
                                    return item.FieldId === kSeqMetadata.Id
                                });

                                await this.ExecuteClusteredPivotFinishedTrigger(data.MainEntityId, data.MainRecordId, data.RelatedEntityId, data.RelatedRecordId, newKSeq, oldKSeqValue.RecordId);
                            });
                        }

                        this.LoadData(false);
                    })
                    .fail((error) => {
                        new Notifier($(this._el)).Warning(error.message);
                    });

            });

            this._baseGrid.On(BASE_GRID_EVENTS.ADD_TO_BASKET, this, (eventArgs) => {
                if (this._basket) {
                    const basketControl = this._basket as Basket;
                    const gridRow = eventArgs.data.Row as GridRow;

                    basketControl.AddRecordToBasket(gridRow.RecordId, this._gridEntityId).then((result) => {
                        gridRow.RecordInBasket = true;

                        const relation: ILoadScreenRelationModel = {
                            MainEntityId: basketControl.Model.EntityId,
                            MainRecordId: basketControl.SelectedRecord().RecordId,
                            MainRecordTypeId: basketControl.SelectedRecord().TypeId,
                            RelatedEntityId: this._gridEntityId,
                            RelatedRecordId: gridRow.RecordId,
                            RelatedRecordTypeId: gridRow.RecordTypeId,
                            KSeq: result
                        };

                        this.UpdateLinkRecordGeneral(relation);
                    });
                }
            });

            this._baseGrid.On(BASE_GRID_EVENTS.REMOVE_FROM_BASKET, this, (eventArgs) => {
                if (this._basket) {
                    const basketControl = this._basket as Basket;
                    const gridRow = eventArgs.data.Row as GridRow;

                    basketControl.RemoveRecordFromBasket(gridRow.RecordId, this._gridEntityId).then(() => {
                        gridRow.RecordInBasket = false;
                    });
                }
            });

            this._baseGrid.On(BASE_GRID_EVENTS.RECORD_DELETED, this, (eventArgs) => {
                this._toolbar.SetEnabledAddButton(this.IsVisibleAddButton(this._baseGrid.Model));
                this._toolbar.SetEnableEditAllButton(this.IsVisibleEditAllButton(this._baseGrid.Model));
            });

            this._baseGrid.On(BASE_GRID_EVENTS.RECORD_SAVED, this, (eventArgs) => {
                this._toolbar.EditAll = true;
                this._disabledCopyButton(false);
            });

            this._baseGrid.On(BASE_GRID_EVENTS.RECORD_EDITED, this, (eventArgs) => {
                this._toolbar.SetEnableEditAllButton(!this.IsListScreen && this.IsEditAll);
                this._toolbar.EditAll = false;
            });

            this._baseGrid.On(BASE_GRID_EVENTS.UNDO_EVERY_RECORD, this, (eventArgs) => {
                this._toolbar.SetEnableEditAllButton(!this.IsListScreen && this.IsEditAll);
                this._toolbar.EditAll = true;
            });

            this._baseGrid.On(BASE_GRID_EVENTS.UPDATE_SET_ENABLE_ADD_BUTTON, this, (eventArgs) => {
                this._toolbar.SetEnabledAddButton(this.IsVisibleAddButton(this._baseGrid.Model));
            });

            this._baseGrid.On(BASE_GRID_EVENTS.LOAD_SUB_GRID, this, (eventArgs) => {
                this.LoadData(false, eventArgs.data.SubGrid, eventArgs.data.ParentRowId);
            });

            this._baseGrid.On(BASE_GRID_EVENTS.BACK_LINKING_POPUP_REQUESTED, this, eventArgs => {
                const params: BackLinkingParams = {
                    Header: eventArgs.data.ButtonName,
                    SubTableView: eventArgs.data.SubTableView,
                    Intention: eventArgs.data.Intention,
                    LeftTableId: eventArgs.data.LeftTableId,
                    RightTableId: eventArgs.data.RightTableId,
                    LeftRecordId: eventArgs.data.LeftRecordId,
                    RightRecordId: eventArgs.data.RightRecordId,
                    Sequence: eventArgs.data.Sequence
                };

                const backLinking = new BackLinking(params);
                backLinking.On('CHANGES_SAVED', this, () => this.LoadData());
                backLinking.ShowPopup();
            });

            this._baseGrid.On(BASE_GRID_EVENTS.CHANGE_VISIBLE_GROUP, this, (eventArgs: any) => {
                let gridRow: GridRow = eventArgs.data.gridRow;
            });

            this._basket = _.find(this._subControls(), (subControl) => {
                return subControl.GetType() === CONTROL_TYPES.Basket;
            }) as Basket;

            if (this._basket && !(this._basket).IsDisabledByCondition) {
                this._enableBasket(true);

                this._basket.On(BASKET_EVENTS.BASKET_SELECTED, this, (eventArgs) => {
                    this._baseGrid.MarkRecodsInBasket(eventArgs.data.Records);
                });
            }

            const type = this.GetForm() && this.GetForm().GetScreen().GetType();
            this._toolbar = new Toolbar(
                this._enableTableViewLookup,
                this._enableFunctionLookup,
                this._enableLink,
                this._enableUnlinkMultiple,
                this._enableLinkParent,
                this._hasOneParent,
                this._enableAddAndLink,
                this._enableScanAndLink,
                this._enableAddFromQuery,
                this._showSearchInput,
                this._isEditable,
                this._enableNewRecord,
                this._exportDataAllowed,
                this._showTableViewElement,
                type,
                false,
                this._showTogglePrioritiesButton,
                this._showBulkEditButton,
                this._disableBulkEditButton,
                this._showResetFilterButton,
                this._disabledShowAllColumnsBtn,
                this.ShowRetired,
                this.GetToolbarControls()
            );

            if (this._renderMode() !== RenderModes.HelpView) {
                this.ApplyProperties();
                this.InitShowAllColumns();
            }

            this._toolbar.On(TOOLBAR_EVENTS.NEW_TABLE_VIEW, this, (eventArgs: any) => this.NewTableView());

            this._toolbar.On(TOOLBAR_EVENTS.BULK_EDIT, this, (eventArgs: any) => this.BulkEdit());

            this._toolbar.On(TOOLBAR_EVENTS.NEW_RECORD, this, (eventArgs: any) => this.CreateRecord());

            this._toolbar.On(TOOLBAR_EVENTS.TOGGLE_RETIRED, this, (eventArgs: any) => {
                this._showRetiredRecords = eventArgs.data.State;
                this.LoadData();
            });

            this._toolbar.On(TOOLBAR_EVENTS.EDIT_TABLE_VIEW, this, (eventArgs: any) => {
                const tableView = _.find(this._viewGridList(), item => item.Id === eventArgs.data.Id);

                if (tableView) {
                    this.EditTableView();
                }
            });

            this._toolbar.On(TOOLBAR_EVENTS.COPY_TABLE_VIEW, this, (eventArgs: any) => {
                const tableView = _.find(this._viewGridList(), item => item.Id === eventArgs.data.Id);

                if (tableView) {
                    this.CopyTableView();
                }
            });

            this._toolbar.On(TOOLBAR_EVENTS.DELETE_TABLE_VIEW, this, (eventArgs: any) => {
                const tableView = _.find(this._viewGridList(), item => item.Id === eventArgs.data.Id);

                if (tableView) {
                    this.DeleteTableView();
                }
            });

            this._toolbar.On(TOOLBAR_EVENTS.LOCK_TABLE_VIEW, this, eventArgs => {
                this.LockTableView();
            });

            this._toolbar.On(TOOLBAR_EVENTS.UNLOCK_TABLE_VIEW, this, eventArgs => {
                this.UnlockTableView();
            });

            this._toolbar.On(TOOLBAR_EVENTS.LINK_RECORD, this, () => this.LinkRecord());

            this._toolbar.On(TOOLBAR_EVENTS.UNLINK_MULTIPLE_RECORDS, this, () => {
                this._baseGrid.GridUnlinkMultipleRecords();
                this._isMultipleUnlinkModeActive(true);
            });

            this._toolbar.On(TOOLBAR_EVENTS.CONFIRM_MULTIPLE_UNLINK, this, () => {
                this.UnlinkMultipleRecords();
                this._baseGrid.GridConfirmMultipleUnlinking();
                this._isMultipleUnlinkModeActive(false);
            });

            this._toolbar.On(TOOLBAR_EVENTS.CANCEL_MULTIPLE_UNLINK, this, () => {
                this._baseGrid.GridCancelMultipleUnlinking();
                this._isMultipleUnlinkModeActive(false);
            });

            this._toolbar.On(TOOLBAR_EVENTS.LINK_PARENT_RECORD, this, () => this.LinkParentRecord());

            this._toolbar.On(TOOLBAR_EVENTS.COPY_LATEST_RECORD, this, () => {
                if (this._disabledCopyButton()) {
                    return;
                }

                const isEntityGrid = this.GetGridSubject().Entity.Metadata.Type === TABLE_TYPES.Entity;
                const isSubGrid = this.GetGridSubject().Entity.Metadata.Type === TABLE_TYPES.Sub;

                if (isEntityGrid) {
                    this.CopyLatestLink();
                    return;
                }

                if (isSubGrid) {
                    this.CopyLatestSubRecord();
                    return;
                }
            });

            this._toolbar.On(TOOLBAR_EVENTS.ADD_AND_LINK_RECORD, this, (eventArgs: any) => this.OnAddAndLinkRecord());

            this._toolbar.On(TOOLBAR_EVENTS.SCAN_AND_LINK_RECORD, this, eventArgs => {
                this.ScanAndLinkRecord();
            });

            this._toolbar.On(TOOLBAR_EVENTS.ADD_QUERY, this, (eventArgs: any) => this.AddQuery());

            this._toolbar.On(TOOLBAR_EVENTS.SEARCH_BY_PHRASE, this, (eventArgs: any) => {
                this._searchPhrase(eventArgs.data.SearchPhrase);
                this.EnterKey();
            });

            this._toolbar.On(TOOLBAR_EVENTS.EXPORT_DATA, this, eventArgs => this.ExportData(eventArgs.data.Destination));

            this._toolbar.On(TOOLBAR_EVENTS.TOGGLE_PRIORITIES, this, (eventArgs: any) => this.TogglePriorities(eventArgs.data.state));

            this._toolbar.On(TOOLBAR_EVENTS.RESET_FILTER, this, (eventArgs: any) => this.ResetFilter());

            this._toolbar.On(TOOLBAR_EVENTS.EDIT_ALL, this, () => this.EditAll());
            this._toolbar.On(TOOLBAR_EVENTS.SAVE_ALL, this, () => this.SaveAll());

            this.Paginator.On(PAGINATOR_EVENTS.CHANGE, this, (eventArgs: any) => {
                if (eventArgs.data.pageNumber === this._prevPageNumber) {
                    return;
                }

                if (this._baseGrid.GetEditRows().length > 0 || this._baseGrid.GetNewAndLinkRows().length > 0) {
                    const confirmationDialog = new ConfirmationDialog({
                        Text: CONFIRMATIONS.ALL_CHANGES_WILL_BE_LOST,
                        Type: ConfirmationTypes.Warning
                    });

                    confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
                        this.LoadData(true);

                        this._prevPageNumber = eventArgs.data.pageNumber;
                    });

                    confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.DISCARD_SELECTED, this, () => {
                        this.Paginator.PageNumber = this._prevPageNumber;
                    });

                    confirmationDialog.Show();
                } else {
                    this.LoadData(true);
                    this._prevPageNumber = eventArgs.data.pageNumber;
                }
            });

            if (this._renderMode() === RenderModes.View) {
                this.GetForm().GetScreen().On(CONSULT_SCREEN_EVENTS.REFRESH_GRID, this, (eventArgs) => {
                    if (eventArgs.data) {
                        let entityId = eventArgs.data.EntityId;
                        if (entityId === this._gridEntityId) {
                            this.LoadData(false);
                        }
                    }
                });
            }

            this.Init();
        }

        if (this._renderMode() !== RenderModes.Design && this._renderMode() !== RenderModes.ToolBar && this._renderMode() !== RenderModes.HelpView && this._filterByOwners) {
            this.GetForm().GetScreen().On('UsersSelectionChanged', this, eventArgs => {
                const groupIds = _.filter(_.map(eventArgs.data?.SelectedUsersAndGroups, (item: CheckedUserAndGroup) => item.GroupId), groupId => groupId !== null) as number[];
                const userIds = _.flatten(_.map(eventArgs.data?.SelectedUsersAndGroups, (item: CheckedUserAndGroup) => item.UserIds));
                const combinedIds = groupIds.concat(userIds);

                this._selectedUsers = _.uniq(combinedIds);
                this._showPlanned = eventArgs.data.ShowPlanned;
                if (this._isRendered()) {
                    this.LoadData(false);
                }
            });
        }
    }

    get ShowRetired(): boolean {
        if (this._renderMode() === RenderModes.HelpView) {
            return false;
        } else {
            return this.IsListScreen
                || this._form.GetScreen().IsQueryScreen
                || this._form.GetScreen().IsSpecialScreen
                || this._form.GetScreen().IsDashboard;
        }
    }

    get IsConsultScreen(): boolean {
        return this._form && this._form.GetScreen().IsConsultScreen;
    }

    get IsListScreen(): boolean {
        return this._form && this._form.GetScreen().IsListScreen;
    }

    get CrossTableValueColumn(): QueryColumnModel{
        return _.find(Util.GetAllQueryColumns(this._queryExpression), col=>col.IsCrossValue);
    }

    ShowCrossTableDetail(gridRow: GridRow): P.Promise<GridRowModel> {
        let deferredResult = P.defer<GridRowModel>();
        BlockUI.Block();
        GridStore.GetCrossTableDetail({
            SubjectEntityId: this._form.GetScreen().GetEntityId(),
            SubjectRecordId: this._form.GetScreen().GetRecordId(),
            RelatedEntityId: gridRow.Model.EntityId,
            RelatedRecordId: gridRow.Model.RecordId,
            KSeqs: gridRow.Model.KSeqs,
            DisplayFields: this.CrossTableValueColumn?.DisplayFields
        }).always(() => {
            BlockUI.Unblock();
        }).then((result) => {
            let pivotDetailsDropDown = new PivotDetailsDropdown();

            pivotDetailsDropDown.On(BASE_GRID_EVENTS.REFRESH, this, () => {
                pivotDetailsDropDown.Close();
                this.LoadData();
            });

            pivotDetailsDropDown.On(BASE_GRID_EVENTS.EDIT_CLUSTERED_LINK, this, (eventArgs) => {
                let gridRow = eventArgs.data.gridRow;
                this.UpdateLinkRecord(gridRow.EntityId, gridRow.RecordId, gridRow.RecordTypeId, gridRow.KSeq, gridRow.RelationshipType);
            });

            pivotDetailsDropDown.On(QUERY_RESULT_GRID_EVENTS.RECORD_SELECTED, this, (eventArgs) => {
                if (eventArgs.data.SelectedRecord.Model) {
                    let row = eventArgs.data.SelectedRecord.Model as GridRowModel;
                    pivotDetailsDropDown.Close();
                    deferredResult.resolve(row);
                }
            });

            pivotDetailsDropDown.SetData(result);

            if (pivotDetailsDropDown.Rows.length == 1) {
                deferredResult.resolve(pivotDetailsDropDown.Rows[0].Model);
                return;
            }

            pivotDetailsDropDown.Show(gridRow.El);
        });

        return deferredResult.promise();
    }

    GetGridPriorityToggle(): boolean {
        if (MobileChecker.IsMobile() || this._renderMode() === RenderModes.HelpView) {
            return this._isPrioritiesDisabled;
        }
        const subjectEntityId = this.GetForm().GetScreen().GetEntityId();
        const gridSubjectEntityId = this._viewMode === ViewModes.ListView ? 0 : this._model().EntityId;
        return this._userVarsManager.GetGridPriority(subjectEntityId, gridSubjectEntityId, this._isPrioritiesDisabled);
    }

    get OnClusteredPivotFinishedTrigger(){
        return _.find(this._model().Triggers, (trigger: TriggerModel) => {
            return trigger.Action === TRIGGER_ACTIONS.ON_CLUSTERED_PIVOT_FINISHED
        });
    }

    async ExecuteClusteredPivotFinishedTrigger(
        mainEntityId: number,
        mainRecordId: number,
        relatedEntityId: number,
        relatedRecordId: number,
        newKSeq: number,
        oldKSeq: number
    ) {
        if (this.OnClusteredPivotFinishedTrigger) {
            this.BlockUI();
            await TriggerManager.OnClusteredPivotFinished({
                MainEntityId: mainEntityId,
                RelatedEntityId: relatedEntityId,
                MainRecordId: mainRecordId,
                RelatedRecordId: relatedRecordId,
                NewKSeq: newKSeq,
                OldKSeq: oldKSeq,
                ControlId: this.GetControlId()
            }, this.GetGuid(), this.OnClusteredPivotFinishedTrigger.Id);
            this.UnBlockUI();
        }
    }

    get QueryExpression(): QueryExpressionModel {
        return this._queryExpression;
    }

    ToggleAllShownColumns(canHide: boolean, canShow: boolean) {
        this._toolbar && this._toolbar.ToggleAllShownColums.call(this._toolbar, canHide, canShow);
    }

    UpdateLinkRecordGeneral(
        relation: ILoadScreenRelationModel,
        gridRow: GridRow = null,
        isNew: boolean = false,
        isNewCreated: boolean = false,
        isNewParent: boolean = false
    ): Promise<any> {
        let data = null;
        return new Promise(async (resolve, reject) => {
            const linkEditor = (await import('Core/Screens/Common/LinkEditor/LinkEditor')).LinkEditor;

            const requestedFromEditScreen = this._form && this._form.GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.EditScreen];
            linkEditor.LoadScreen(relation, isNew, data, false, null, requestedFromEditScreen)
                .then(screen => {
                    if (screen !== null) {
                        const linkEditor = screen as LinkEditor;

                        screen.One('MODAL_CLOSE', this, () => {
                            if (this._refreshScreenAfterManipulations) {
                                this.GetForm().GetScreen().Refresh();
                            }
                        });

                        linkEditor.One('CANCEL', this, () => {
                            if (this._refreshScreenAfterManipulations) {
                                if (isNewCreated) {
                                    this._baseGrid.UnlinkRecordByRelation(relation, isNewParent);
                                }
                            }
                        });

                        linkEditor.On('SAVE_DATA', this, eventArgs => {
                            const dataModel = new DataModel();

                            dataModel.EntityId = relation.MainEntityId;
                            dataModel.RecordId = relation.MainRecordId;

                            const updateDataModel = new UpdateDataModel();

                            let editLinkData: EditLinkDataModel = eventArgs.data;
                            updateDataModel.LinkEditorChanges.push(editLinkData);
                            dataModel.LinklistChanges = Serialize(updateDataModel);

                            if (!gridRow || this._refreshScreenAfterManipulations) {
                                BlockUI.Block();
                                InsertOrUpdateRecordStore.UpdateData(dataModel)
                                    .always(() => {
                                        BlockUI.Unblock();
                                    })
                                    .fail((error) => {
                                        new Notifier($(this._el)).Failed(error.message);
                                    })
                                    .then(result => {
                                        if (result.IsSuccessfull) {
                                            new Notifier().Success(NOTIFICATIONS.RECORD_UPDATED);
                                            screen.Off('MODAL_CLOSE');
                                            linkEditor.Close();

                                            if (this._refreshScreenAfterManipulations) {
                                                this.GetForm().GetScreen().Refresh();
                                            } else {
                                                this.LoadData(false);
                                            }
                                            resolve(null);
                                        } else {
                                            linkEditor.GetNotifier().Failed(result.ErrorMessage);
                                            reject({message: result.ErrorMessage});
                                        }
                                    });
                            } else {
                                gridRow.LinkEditorData = updateDataModel;
                                gridRow.SaveLinkEditorData(linkEditor.GetAllControls());
                                linkEditor.Close();
                                resolve(null);
                            }
                        });
                    } else if (this._refreshScreenAfterManipulations) {
                        this.GetForm().GetScreen().Refresh();
                        resolve(null);
                    }
                }).fail(err => {
                    new Notifier($(this._el)).Warning(err.message);
                    reject(err);
            });
        });
    }

    UpdateSubGridLinkRecord(parentEntityId: number, parentRecordId: number, contextRow: GridRow): Promise<any> {
        const relation: ILoadScreenRelationModel =
             {
                MainEntityId: parentEntityId,
                MainRecordId: parentRecordId,
                RelatedEntityId: contextRow.EntityId,
                RelatedRecordId: contextRow.RecordId,
                RelatedRecordTypeId: contextRow.RecordTypeId,
                KSeq: contextRow.KSeq,
            }

        return this.UpdateLinkRecordGeneral(relation, null, false);
    }

    UpdateLinkRecord(entityId: number,
                     recordId: number,
                     recordTypeId: number,
                     kSeq: number,
                     familyType: number = 0,
                     relatedEntityId?: number,
                     relatedRecordId?: number,
                     relatedRecordTypeId?: number,
                     isNewCreated?: boolean,
                     isNewParent?: boolean): Promise<any> {

        if (entityId === 0 || recordId === 0) return;

        const relation: ILoadScreenRelationModel = familyType != 1
            ? {
                MainEntityId: relatedEntityId || this._form.GetScreen().GetEntityId(),
                MainRecordId: relatedRecordId || this._form.GetScreen().GetRecordId(),
                MainRecordTypeId: relatedRecordTypeId || this._form.GetScreen().GetTableTypeId(),

                RelatedEntityId: entityId,
                RelatedRecordId: recordId,
                RelatedRecordTypeId: recordTypeId,

                KSeq: kSeq
            }
            : {
                MainEntityId: entityId,
                MainRecordId: recordId,
                MainRecordTypeId: recordTypeId,

                RelatedEntityId: relatedEntityId || this._form.GetScreen().GetEntityId(),
                RelatedRecordId: relatedRecordId || this._form.GetScreen().GetRecordId(),
                RelatedRecordTypeId: relatedRecordTypeId || this._form.GetScreen().GetTableTypeId(),
                KSeq: kSeq
            };

        return this.UpdateLinkRecordGeneral(relation, null, false, isNewCreated, isNewParent);
    }

    CreateRecord() {
        this._baseGrid.SelectType();
        this._toolbar.SetEnabledAddButton(this.IsVisibleAddButton(this._baseGrid.Model));
        this._toolbar.SetEnableEditAllButton(this.IsEditAll);
        this._toolbar.EditAll = false;
    }

    GetTableView(): ViewModes {
        if (this._renderMode() === RenderModes.HelpView) {
            return ViewModes.TableView;
        }
        switch (this.GetForm().GetScreen().GetType()) {
            case ScreenTypes[ScreenTypes.ProcessCardPage]:
                return ViewModes.TableView;
            case ScreenTypes[ScreenTypes.QueryScreen]:
                return ViewModes.Query;
            case ScreenTypes[ScreenTypes.ConsultScreen]:
                return ViewModes.TableView;
            case ScreenTypes[ScreenTypes.EditScreen]:
                return ViewModes.TableView;
            default:
                return ViewModes.ListView;
        }
    }

    SortData(sorting: Array<SortModel>) {
        this._sorting = sorting;
        this.LoadData(true);
    }

    InitShowAllColumns() {
        const showAllColumnsValue = this.GeneralProperties.GetPropertyValue(PROPERTIES.SHOW_ALL_COLUMNS);
        if (showAllColumnsValue) {

            switch (showAllColumnsValue.Value) {
                case 'On':
                    this.TogglePriorities(true);
                    this._disabledShowAllColumnsBtn = false;
                    break;
                case 'Off':
                    this.TogglePriorities(false);
                    this._disabledShowAllColumnsBtn = false;
                    break;
                case 'Always on':
                    this.TogglePriorities(true);
                    this._disabledShowAllColumnsBtn = true;
                    break;
                case 'Always off':
                    this.TogglePriorities(false);
                    this._disabledShowAllColumnsBtn = true;
                    break;
            }
        }
    }

    get HasLinkingCondition(): boolean {
        let linkingConditionProperty = this.GeneralProperties.GetPropertyValue(PROPERTIES.LINKING_CONDITION);

        if (linkingConditionProperty) {
            let linkingCondition = GenericDeserialize<QueryConditionGroupModel>(JSON.parse(linkingConditionProperty), QueryConditionGroupModel);
            if (linkingCondition.ConditionGroups.length > 0 || linkingCondition.Items.length > 0) {
                return true;
            }
        }

        return false;
    }

    ApplyProperties() {

        const hideLifeStatusToggle = this.GeneralProperties.GetPropertyValue(PROPERTIES.HIDE_LIFESTATUS_TOGGLE);
        const hideUnlink = this.GeneralProperties.GetPropertyValue(PROPERTIES.HIDE_UNLINK);
        const hideEdit = this.GeneralProperties.GetPropertyValue(PROPERTIES.HIDE_EDIT);
        const condition = this.GeneralProperties.GetPropertyValue(PROPERTIES.QUERY_CONDITION);
        const backLinking = this.GeneralProperties.GetPropertyValue(PROPERTIES.BACK_LINKING);

        this._hideLifeStatusToggle(hideLifeStatusToggle);
        this._hideUnlink(hideUnlink);
        this._hideEdit(hideEdit);

        if (condition) {
            const jsonObj = JSON.parse(condition);

            this._conditions = GenericDeserialize<QueryConditionGroupModel>(jsonObj, QueryConditionGroupModel);
        }

        if (backLinking) {
            this._backLinkingButtons(backLinking);
        }

        if (this._renderMode() !== RenderModes.Design && this._renderMode() !== RenderModes.ToolBar && this._renderMode() !== RenderModes.HelpView) {
            const isSubjectScreen = this.IsConsultScreen || this.IsEditScreen || this.IsProcessCardPageScreen;

            if (!isSubjectScreen) {
                let securityFilter = this._form.GetScreen().IsQueryScreen ? SECURITY_FILTERS.RECORD_SECURITY : SECURITY_FILTERS.SHARING_SECURITY;
                const securityFilterPropValue = this.GeneralProperties.GetPropertyValue(PROPERTIES.SECURITY_FILTER);
                if (securityFilterPropValue) {
                    securityFilter = securityFilterPropValue.Value;
                }
                this._filterByOwners = securityFilter === SECURITY_FILTERS.SHARING_SECURITY;
            }
        }

        if (this._baseGrid)
            this._baseGrid.IsFastFilterEnabled = this.GeneralProperties.GetPropertyValue(PROPERTIES.FAST_FILTER);

        this._isEditable(!this.GeneralProperties.GetPropertyValue(PROPERTIES.READONLY));

        this._showSearchInput(this.GeneralProperties.GetPropertyValue(PROPERTIES.SEARCH) && !this.HasCardScreen);

        if (this._form) {
            let isEnableNewRecord: boolean;
            const maxRowHeight: string = this.GeneralProperties.GetPropertyValue(PROPERTIES.MAX_ROW_HEIGHT);

            this._maxRowHeight(maxRowHeight);

            if (this.IsConsultScreen ||
                this.IsEditScreen ||
                this.IsProcessCardPageScreen
            ) {

                if (this._model().EntityTypeName === EntityTypes[EntityTypes.Sub]) {
                    this._enableNewRecord(true);

                    if (this._renderMode() === RenderModes.Design) {
                        this.GeneralProperties.SetPropertyValue(PROPERTIES.ENABLE_LINK, false);
                        this.GeneralProperties.SetPropertyEnabled(PROPERTIES.ENABLE_LINK, false);
                        this.GeneralProperties.SetPropertyValue(PROPERTIES.ENABLE_UNLINK_MULTIPLE, false);
                        this.GeneralProperties.SetPropertyEnabled(PROPERTIES.ENABLE_UNLINK_MULTIPLE, false);
                        this.GeneralProperties.SetPropertyValue(PROPERTIES.ENABLE_ADD_AND_LINK, false);
                        this.GeneralProperties.SetPropertyEnabled(PROPERTIES.ENABLE_ADD_AND_LINK, false);
                        this.GeneralProperties.SetPropertyValue(PROPERTIES.ENABLE_SCAN_AND_LINK, false);
                        this.GeneralProperties.SetPropertyEnabled(PROPERTIES.ENABLE_SCAN_AND_LINK, false);
                    }
                } else {
                    this._enableLink(this._linkingRecordAllowed() && this.GeneralProperties.GetPropertyValue(PROPERTIES.ENABLE_LINK));

                    this._enableUnlinkMultiple(this.GeneralProperties.GetPropertyValue(PROPERTIES.ENABLE_UNLINK_MULTIPLE));

                    this._enableAddAndLink(
                        this._addingRecordAllowed() &&
                        this.GeneralProperties.GetPropertyValue(PROPERTIES.ENABLE_ADD_AND_LINK)
                    );

                    this._enableScanAndLink(
                        this._linkingRecordAllowed() && this._scanAndLinkAllowed() &&
                        this.GeneralProperties.GetPropertyValue(PROPERTIES.ENABLE_SCAN_AND_LINK)
                    );

                    isEnableNewRecord = this._addingRecordAllowed() && !this._enableAddAndLink();
                    this._enableNewRecord(isEnableNewRecord);
                }
            } else if (this._subjectRecordId === 0 && (this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.EditScreen] || this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.ProcessCardPage])) {
                this._enableNewRecord(false);
            } else {
                if (this._model().EntityTypeName === EntityTypes[EntityTypes.Sub]) {
                    this._enableNewRecord(true);
                }

                isEnableNewRecord = this._addingRecordAllowed() &&
                    (this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.ListScreen] ||
                        this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.SpecialScreen] ||
                        this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.Dashboard] ||
                        this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.ProcessCardPage] ||
                        this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.EditScreen]);
                this._enableNewRecord(isEnableNewRecord);
            }
        }

        this._showTableViewElement(this.GeneralProperties.GetPropertyValue(PROPERTIES.SHOW_TABLE_VIEW));
        this._enableAddFromQuery(
            this.GeneralProperties.GetPropertyValue(PROPERTIES.ENABLE_ADD_QUERY) &&
            (
                this._form && this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.ConsultScreen] ||
                this._form && this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.EditScreen] ||
                this._form && this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.ProcessCardPage]
            )
        );

        this._enableLinkParent(
            this._linkingRecordAllowed() &&
            this.GeneralProperties.GetPropertyValue(PROPERTIES.ENABLE_LINK_PARENT) &&
            this._form && this._form.GetScreen().GetEntityId() === this._model().EntityId &&
            (
                this._form.GetScreen().IsConsultScreen || this._form.GetScreen().IsEditScreen || this._form.GetScreen().IsProcessCardPageScreen
            )
        );

        this._showBulkEditButton(this.IsAbleBulkEdit() && this._model().EntityTypeName !== TABLE_TYPES.Sub);
        this._rowColorEnabled(this.GeneralProperties.GetPropertyValue(PROPERTIES.ROW_COLOR));
    }

    IsAbleBulkEdit(): boolean {
        if (this._form && (this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.ConsultScreen]
            || this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.ListScreen]
            || this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.SpecialScreen]
            || this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.Dashboard]
            || this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.EditScreen]
            || this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.QueryScreen])) {
            return this.GeneralProperties.GetPropertyValue(PROPERTIES.BULK_EDIT);
        }
        return false;
    }

    ChangeProperty(type: string, value): void {
        super.ChangeProperty(type, value);
        this.ApplyProperties();
    }

    UpdateTableViewList() {
        this._toolbar.Paginator().Reset();
        this.BlockToolbar();
        this._isTableViewUpdating(true);

        if (this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.ConsultScreen]
            || this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.ListScreen]
            || this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.SpecialScreen]
            || this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.Dashboard]
            || this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.EditScreen]
            || this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.Portlet]) {

            const requestModel: IGetGridViewListDto = {
                ControlId: this._viewMode === ViewModes.ListView ? 0 : this.GetControlId(),
                SubjectEntityId: this._subjectEntityId,
                ViewMode: this._viewMode,
                ScreenType: ScreenTypes[this.ScreenType]
            };

            ViewGridListStore.GetGridViewList(requestModel).then(data => {
                let defaultView = new ViewGridModel(0, LABELS.DEFAULT_VIEW);
                defaultView.IsEditingAllowed = false;

                let tableViewList = [defaultView];

                data.GridViews.forEach(view => view.Init());
                tableViewList = data.GridViews ? tableViewList.concat(data.GridViews) : tableViewList;

                this._viewGridList(tableViewList);

                let tableView = _.find(this._viewGridList(), (item) => item.Id === this._tableViewId);

                if (tableView) {
                    this._tableView(tableView);
                } else {
                    const lockedTableView = _.find(this._viewGridList(), item => item.Locked);

                    if (lockedTableView && !UserManager.Instance.IsUserInRole(UserRoles.SuperUser)) {
                        tableView = lockedTableView;
                        this._tableView(tableView);
                    } else {
                        const tableViewId = this._userVarsManager.GetGridView(this.GetControlId());
                        tableView = _.find(this._viewGridList(), (item) => item.Id === tableViewId);
                        this._tableView(tableView || _.first(this._viewGridList()));
                    }
                }

                this._toolbar.Off(TOOLBAR_EVENTS.SELECT_TABLE_VIEW);
                this._toolbar.SetTableViewList(this._viewGridList(), this._tableView(), data.IsAddingRecordAllowed);
                this.LoadData(false);

                this._toolbar.On(TOOLBAR_EVENTS.SELECT_TABLE_VIEW, this, (eventArgs: any) => {
                    if (eventArgs.data.Id !== this._tableView().Id) {
                        const tableView = _.find(this._viewGridList(), item => item.Id === eventArgs.data.Id);

                        if (tableView) {
                            this._tableView(tableView);
                            this._userVarsManager.SetGridView(this.GetControlId(), this._tableView().Id);
                            this._toolbar.Paginator().Reset();
                            this.LoadData(false);
                        }
                    }
                });

            }).fail(() => {
                this.UnBlockToolbar();
            }).always(() => {
                this._isTableViewUpdating(false);
            });

        } else {
            this._isTableViewUpdating(false);
            this.LoadData(false);
        }
    }

    Init() {
        this.InitRecordPerPage();

        const screen = this.GetForm() && this.GetForm().GetScreen();

        if (screen) {
            this._subjectEntityId = screen.GetEntityId();
            this._subjectEntityName = screen.GetEntityName();

            if (screen.GetTypeName() === ScreenTypes[ScreenTypes.ListScreen]
                || screen.GetTypeName() === ScreenTypes[ScreenTypes.SpecialScreen]
                || screen.GetTypeName() === ScreenTypes[ScreenTypes.Dashboard]) {
                screen.On('TAG_SELECTION_CHANGED', this, eventArgs => {
                    this._selectedTags = eventArgs.data;
                    this.LoadData(false);
                });
            }

            if (this._form) {
                screen.On(SCREEN_EVENTS.DATA_CHANGED, this, (eventArgs) => {

                    let screenData: IScreenVariable = eventArgs.data.ScreenVariable;
                    
                    const column = _.find(this._baseGrid.Columns, (column) => {
                        if (column.Model && column.Model.FieldMetadata) {
                            return column.Model.FieldMetadata.Id ===screenData.Field.Id;
                        }
                    });

                    if (column) {
                        const screenVariableCondition = ConditionBuilder.GetScreenVariableCondition(screenData, this._conditions);

                        if (screenVariableCondition.length > 0) {
                            this.SetConditionScreenData(screenVariableCondition, screenData.Value);
                            this.Paginator.PageNumber = 1;
                            this._applyFilter = true;
                            this.LoadData(false);
                        }
                    }
                });
            }
        }

        this._inlineControls = this.GetInlineControls();
    }

    InitRecordPerPage() {
        const recordsPerPageGlobalName = this.GetRecordPerPageGlobalName();

        if (recordsPerPageGlobalName != null) {
            const recordsPerPageGlobalValue = GlobalManager.Instance.GetGlobal(this.GetRecordPerPageGlobalName());

            this.Paginator.RecordsPerPage = parseInt(recordsPerPageGlobalValue) || DEFAULT_RECORDS_PER_PAGE;
        }
    }

    GetRecordPerPageGlobalName(): string {
        let recordsPerPageGlobalName = null;

        switch (this.ScreenType) {
            case ScreenTypes[ScreenTypes.ConsultScreen]:
                recordsPerPageGlobalName = GLOBALS.CONSULT_GRID_PAGE_LINES;
                break;
            case ScreenTypes[ScreenTypes.ListScreen]:
                recordsPerPageGlobalName = GLOBALS.LIST_GRID_PAGE_LINES;
                break;
            case ScreenTypes[ScreenTypes.SpecialScreen]:
                recordsPerPageGlobalName = GLOBALS.LIST_GRID_PAGE_LINES;
                break;
            case ScreenTypes[ScreenTypes.Dashboard]:
                recordsPerPageGlobalName = GLOBALS.LIST_GRID_PAGE_LINES;
                break;
            case ScreenTypes[ScreenTypes.Portlet]:
                recordsPerPageGlobalName = null;
                break;
            default:
                recordsPerPageGlobalName = GLOBALS.OTHER_GRID_PAGE_LINES;
        }

        return recordsPerPageGlobalName;
    }

    get ScreenType(): string {
        if (this.GetForm()) {
            if (this.GetForm().GetScreen()) {
                return this.GetForm().GetScreen().GetType();
            }
            return ScreenTypes[ScreenTypes.ConsultScreen];
        }

        return null;
    }

    get MaxRowHeight(): KnockoutObservable<string> {
        return this._maxRowHeight;
    }

    SelectTableView() {
        this.Paginator.PageNumber = 1;
        this.LoadData(false);
    }

    ResetFilter() {
        UserVarsManager.Instance.ResetGridFilters(this._subjectRecordId);
        this.LoadData();
    }

    EditAll() {
        this._baseGrid.EditAll();
    }

    SaveAll() {
        this._toolbar.EditAll = this._baseGrid.SaveAll();
    }

    get DataMode(): DataModes {
        //Forbidden loading grid during followUp an copying
        return this._form.GetScreen().GetType() !== ScreenTypes[ScreenTypes.EditScreen] ? DataModes.Default : this._form.GetScreen().GetDataMode();
    }

    get TableViewId() {
        return this._tableView() ? this._tableView().Id : 0;
    }

    CheckingFilters(): void {
        let tableViewId = this._tableView() ? this._tableView().Id : 0;
        let userVarsFastFilter: FastFilterSaveModel[] = UserVarsManager.Instance.GetGridColumnFilters(this._subjectRecordId);
        let thisUserVarsViewFastFilters: FastFilterSaveModel[] =  _.filter(userVarsFastFilter, fastFilter=> fastFilter.Values.length && tableViewId === fastFilter.TableViewId);

        if ( !this._isFastFilter || !thisUserVarsViewFastFilters.length ) {
            UserVarsManager.Instance.ResetGridFilters(this._subjectRecordId);
        }
    }

    LoadData(
        restoreSortState: boolean = false,
        subGrid?: BaseGrid,
        parentRowId?: string,
        updateRow?: GridRow
    ) {

        const getData = (defaultTableViewId?) => {
            if (subGrid) {
                this.BlockUI();
            } else {
                this.BlockToolbar();
            }

            this.Trigger('START_DATA_LOAD');

            let windowScrollPosition;
            let contextGrid = subGrid || this._baseGrid;

            if (this._isRestoreScrollPosition) {
                windowScrollPosition = $(window).scrollTop();
            }

            if (this.Paginator && this.Paginator.RecordsPerPage != null) {
                if (this.Paginator.RecordsPerPage === 0) {
                    this.Paginator.RecordsPerPage = DEFAULT_RECORDS_PER_PAGE;
                }

                contextGrid.HideGrid();

                let tableViewId = this._tableView() ? this._tableView().Id : 0;

                let fastFilters = this._baseGrid.FilterSaveModels;

                UserVarsManager.Instance.SetGridColumnFilters(this._subjectRecordId, fastFilters);

                this.CheckingFilters();

                const preselectedFilters = UserVarsManager.Instance.GetGridColumnFilters(this._subjectRecordId);

                if (preselectedFilters && preselectedFilters.length) {
                    preselectedFilters.map((preselectedFilter) => {
                        if (preselectedFilter.TableViewId === tableViewId
                            && fastFilters.findIndex((el) => el.FieldAlias === preselectedFilter.FieldAlias) < 0) {
                            fastFilters.push(preselectedFilter);
                        }
                    });
                }

                let pageNumber = subGrid ? subGrid.Paginator.PageNumber : this.Paginator.PageNumber;

                const requestModel: IGetGridDataRequestModel = {
                    ControlId: this._model().Id,
                    TableViewId: defaultTableViewId >= 0 ? defaultTableViewId : tableViewId,
                    SubjectEntityId: this._subjectEntityId,
                    SubjectRecordId: this._subjectRecordId,
                    SubjectTypeId: this._form.GetScreen().GetTableTypeId(),
                    SubjectKindId: this._form.GetScreen().GetKindId(),
                    SubjectStatusId: this._subjectRecordSpecsModel ? this._subjectRecordSpecsModel.LifeStatusInfo.Id : 0,
                    ViewMode: this._viewMode,
                    PageNumber: pageNumber,
                    RecordsPerPage: this.Paginator.RecordsPerPage,
                    Sort: this._sorting,
                    SearchPhrase: this._searchPhrase(),
                    FilterByOwners: this._filterByOwners,
                    RecordOwners: this._selectedUsers,
                    ShowPlanned: this._showPlanned,
                    SelectedTags: this._selectedTags,
                    ShowRetired: this._showRetiredRecords,
                    ParentRowId: parentRowId,
                    SubQueryGuid: subGrid ? subGrid.Model.SubQueryGuid : null,
                    ScreenData: this._applyFilter ? this.GetDynamicFieldsData() : null,
                    Query: this.IsQueryScreen ?  this._queryExpression : null,
                    DataMode: this.DataMode,
                    RowId: updateRow ? updateRow.Model.RowId : null,                    
                    ConditionValues: this.MapConditionValues(),
                    FastFilters: fastFilters
                        .filter((filter) => filter.Values.length)
                        .map((filter) => {
                            filter.Values.forEach(value => {
                                if (value.DisplayValue === LABELS.EMPTY_VALUE) {
                                    value.DisplayValue = null;
                                }
                            });
                            return {
                                FieldAlias: filter.FieldAlias,
                                Values: filter.Values,
                                TimeZone: (new Date().getTimezoneOffset()) / 60
                            };
                        })
                };

                this._baseGrid.SetGridDataModel(requestModel);

                GridStore.GetData(requestModel)
                    .then(gridDataModel => {
                        if (subGrid) {
                            subGrid.SetData(gridDataModel, null, null, null, null, updateRow);
                            subGrid.SetRecordsPerPage(this.Paginator.RecordsPerPage)
                        } else {
                            this._hasData = true;
                            this._isLinkEditorEnabled = gridDataModel.IsLinkEditorEnabled;
                            this._isDirectionCorrect = gridDataModel.IsDirectionCorrect;
                            this.Paginator.TotalRecords = gridDataModel.TotalRecords;
                            this._queryExpression = gridDataModel.QueryExpression;
                            this.InitToolbar(gridDataModel);
                            this.ApplyProperties();

                            this._gridEntityId = this.GetGridEntityId(gridDataModel);

                            gridDataModel.SubjectEntityId = this._subjectEntityId;
                            gridDataModel.SubjectRecordId = this._subjectRecordId;

                            this._baseGrid.SetData(gridDataModel, restoreSortState, this.GetGridPriorityToggle(), this._tableView() && this._tableView().Id || 0, this._inlineControls, updateRow);

                            this._showBulkEditButton(this.IsAbleBulkEdit()
                                && gridDataModel.Rows.length > 0
                                && this._model().EntityTypeName !== TABLE_TYPES.Sub);

                            var even = _.find(gridDataModel.Rows, (row) => row.Rights.IsEditingAllowed);
                            this._disableBulkEditButton(!even);

                            this._dataModel = gridDataModel;

                            this.LoadBasket();

                            if (this._isRestoreScrollPosition) {
                                $(window).scrollTop(windowScrollPosition);
                            } else {
                                this._isRestoreScrollPosition = true;
                            }
                        }
                    })
                    .always(() => {
                        contextGrid.ShowGrid();
                        this._toolbar.Enable();
                        this.Trigger('DATA_LOADED');

                        if (subGrid) {
                            this.UnBlockUI();
                        } else {
                            this.UnBlockToolbar();
                        }

                        this._baseGrid.ResizeTableCellsAfterRender();

                    })
                    .fail(data => {
                        const notifier = new Notifier(null);
                        notifier.Failed(`${NOTIFICATIONS.ERROR_GET_GRID_DATA}.<br>${data.message}`);
                    });
            }
        };

        if (this._isTableViewUpdating()) {
            if (this._tableViewUpdatingSubscription) {
                this._tableViewUpdatingSubscription.dispose();
            }
            this._tableViewUpdatingSubscription = this._isTableViewUpdating.subscribe((value) => {
                if (!value) {
                    getData();
                }
            });
        } else {
            getData();
        }
    }

    InitToolbar(gridData: GridDataModel) {
        if (this.HasCardScreen) {
            this._toolbar.ShowSearchInput(false);
            this._linkingRecordAllowed(false);
            this._hasOneParent(false);
            this._addingRecordAllowed(false);
            this._scanAndLinkAllowed(false);
            this._toolbar.SetEnabledAddButton(false);
            this._toolbar.SetEnableEditAllButton(false);
            this._toolbar.ShowRetiredButton(false);
            this._toolbar.ShowControls(false);
            this._toolbar.ShowTogglePrioritiesButton(false);
            this._showResetFilterButton(false);
        } else {
            this._toolbar.ShowSearchInput(this._showSearchInput());
            this._linkingRecordAllowed(gridData.IsLinkingAllowed);
            this._hasOneParent(gridData.HasOneParent);
            this._addingRecordAllowed(gridData.IsAddAndLinkAllowed);
            this._exportDataAllowed(gridData.IsExportDataAllowed);
            this._scanAndLinkAllowed(gridData.IsScanAndLinkAllowed);
            this._toolbar.SetEnabledAddButton(this.IsVisibleAddButton(gridData));
            this._toolbar.SetEnableEditAllButton(this.IsVisibleEditAllButton(gridData));
            this._toolbar.ShowRetiredButton(this.ShowRetired);
            this._toolbar.ShowControls(true);
            this._toolbar.ShowTogglePrioritiesButton(gridData.QueryExpression.IsCrossTable ? false : this._showTogglePrioritiesButton);
            this._showResetFilterButton(UserVarsManager.Instance.HasFastFilter(this._subjectRecordId));

            this.CheckingFilters();
        }

        const gridSubject = this.GetGridSubject();
        const gridSubjectEntityMetadata = gridSubject && gridSubject.Entity && gridSubject.Entity.Metadata;
        this._toolbar.SetGridSubjectEntityMetadata(gridSubjectEntityMetadata);
    }

    get HasCardScreen() {

        if (this._queryExpression && this._queryExpression.CardScreenId > 0) {
            return true;
        }

        if (!!this.GeneralProperties.GetPropertyValue(PROPERTIES.CARD_SCREEN)
            && this.GeneralProperties.GetPropertyValue(PROPERTIES.CARD_SCREEN) !== 0
            && (this.TableViewId == 0)
        ) {
            return true;
        }
        ;

        return false;
    }

    private LoadBasket() {
        if (this._basket) {
            this._basket.LoadData(this._gridEntityId);
        }
    }

    private BlockToolbar() {
        if (this._toolbarBlock) {
            BlockUI.Block({Target: this._toolbarBlock});
            if (this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.EditScreen]) {
                this._toolbar.EditAll = true;
            }
        }
    }

    private UnBlockToolbar() {
        BlockUI.Unblock(this._toolbarBlock);
    }

    private BlockUI() {
        BlockUI.Block({Target: this._el});
    }

    private UnBlockUI() {
        BlockUI.Unblock(this._el);
    }

    private IsVisibleAddButton(gridDataModel: GridDataModel): boolean {
        if (this.GeneralProperties.GetPropertyValue(PROPERTIES.ENABLE_NEW_LINK)) {
            if (this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.EditScreen]) {
                if (gridDataModel.QueryExpression.EntityJoins.length == 0 && gridDataModel.QueryExpression.SubEntityJoins.length > 0 && gridDataModel.QueryExpression.SubEntityJoins[0].Entity.Metadata.Type === TABLE_TYPES.Sub) {
                    let isKseqExit = _.find(gridDataModel.QueryExpression.SubEntityJoins[0].Entity.Metadata.Fields,
                        field => field.Name === 'K_SEQ');

                    if (isKseqExit) {
                        return true;
                    } else {
                        return gridDataModel.Rows && gridDataModel.Rows.length !== 1;
                    }
                }

                if (!this._addingRecordAllowed()) {
                    return false;
                }

                if (gridDataModel.QueryExpression.EntityJoins.length > 0 &&
                    gridDataModel.QueryExpression.SubEntityJoins.length == 0 &&
                    gridDataModel.QueryExpression.EntityJoins[0].Entity.Metadata.Type === TABLE_TYPES.Entity
                ) {
                    return true;
                }
            }
        }

        return false;
    }

    private IsVisibleEditAllButton(gridDataModel: GridDataModel): boolean {
        if (this.GeneralProperties.GetPropertyValue(PROPERTIES.EDIT_ALL)) {
            if (this._form.GetScreen().GetType() === ScreenTypes[ScreenTypes.EditScreen]) {
                let gridSubject = this.GetGridSubject();
                return gridSubject && gridSubject.Entity.Metadata.Type == TABLE_TYPES.Sub
                    && gridDataModel.Rows
                    && gridDataModel.Rows.length !== 0;
            }
        }
        return false;
    }

    private GetGridEntityId(gridDataModel: GridDataModel) {
        let entityId: number;

        if (this._viewMode === ViewModes.ListView || this._viewMode === ViewModes.Query) {
            entityId = gridDataModel.QueryExpression.Entity.Metadata.Id;
        } else {
            const joinExist = _.any(gridDataModel.QueryExpression.EntityJoins);

            entityId = joinExist ? gridDataModel.QueryExpression.EntityJoins[0].Entity.Metadata.Id : 0;
        }

        return entityId;
    }

    AfterRender(el) {
        super.AfterRender(el);

        if (this._toolbar) {
            this._toolbarBlock = document.getElementById('control-' + this._guid);
        }

        if (this._renderMode() !== RenderModes.Design && this._renderMode() !== RenderModes.ToolBar && this._renderMode() !== RenderModes.HelpView) {
            this.ListenForWrapperResize();
        }
    }

    NewTableView() {
        BlockUI.Block();

        GridStore.GetDefaultTableViewMetaData(ScreenTypes[this.ScreenType])
            .then(async data => {
                const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
                screenManager.GetEditScreen({
                    EntityId: data.TableId,
                    TableTypeId: data.TableTypeId
                })
                    .always(() => {
                        BlockUI.Unblock();
                    })
                    .fail(error => {
                        let notifier = new Notifier($(this._el));

                        notifier.Warning(error.message);
                    })
                    .then((screen) => {
                        const editScreen = screen as BaseScreen;
                        editScreen.SetIsQueryBuilderScreen(true);

                        editScreen.ShowInModal();

                        GridStore.GetDefaultTableView({
                            ControlId: this.GetControlId(),
                            ViewMode: this._viewMode,
                            SubjectEntityId: this._subjectEntityId
                        })
                            .then(data => {
                                const queryBuilderControl = editScreen.GetControl(CONTROL_TYPES.QueryBuilder) as QueryBuilderControl;

                                if (queryBuilderControl == null) {
                                    const notifier = new Notifier($(this._el));

                                    notifier.Failed(NOTIFICATIONS.QUERY_BUILDER_NOT_FOUND);
                                } else {
                                    queryBuilderControl.SetSubjectEntityId(this._subjectEntityId);
                                    queryBuilderControl.SetSubjectRecordId(this._subjectRecordId);
                                    queryBuilderControl.SetQueryTypeName(this._viewMode);
                                    queryBuilderControl.SetFilterByOwners(this._filterByOwners);
                                    queryBuilderControl.SetGridId(this.GetControlId());
                                    queryBuilderControl.InitQuery(data);

                                    editScreen.On('RECORD_SAVED', this, (eventArgs) => {
                                        this._tableViewId = eventArgs.data.RecordId;
                                        this.UpdateTableViewList();

                                        this._userVarsManager.SetGridView(this.GetControlId(), this._tableViewId);
                                    });
                                }
                            });

                    });
            })
            .fail(err => {
                const notifier = new Notifier($(this._el));

                notifier.Failed(err.message);
            })
            .always(() => {
                BlockUI.Unblock();
            });
    }

    CopyTableView() {
        if (this._tableView().Id > 0) {
            BlockUI.Block();

            GridStore.GetDefaultTableViewMetaData(ScreenTypes[this.ScreenType])
                .then(async data => {
                    BlockUI.Block();
                    const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
                    screenManager.GetEditScreen({
                        EntityId: data.TableId,
                        TableTypeId: data.TableTypeId,
                        RecordId: this._tableView().Id,
                        LoadAsExample: true
                    })
                        .always(() => {
                            BlockUI.Unblock();
                        })
                        .fail(error => {
                            let notifier = new Notifier($(this._el));
                            notifier.Warning(error.message);
                        })
                        .then((screen) => {
                            const editScreen = screen as EditScreen;
                            editScreen.SetIsQueryBuilderScreen(true);
                            editScreen.IsDataFromExample = true;
                            editScreen.ShowInModal();

                            const queryBuilderControl = editScreen.GetControl(CONTROL_TYPES.QueryBuilder) as QueryBuilderControl;

                            if (queryBuilderControl == null) {
                                const notifier = new Notifier($(this._el));

                                notifier.Failed(NOTIFICATIONS.QUERY_BUILDER_NOT_FOUND);
                            } else {
                                queryBuilderControl.SetSubjectRecordId(this._subjectRecordId);
                                queryBuilderControl.SetQueryTypeName(this._viewMode);
                                queryBuilderControl.SetFilterByOwners(this._filterByOwners);
                                queryBuilderControl.SetGridId(this.GetControlId());

                                editScreen.On('RECORD_SAVED', this, (eventArgs) => {
                                    this._tableViewId = eventArgs.data.RecordId;
                                    this.UpdateTableViewList();

                                    this._userVarsManager.SetGridView(this.GetControlId(), this._tableViewId);
                                });
                            }
                        });
                })
                .fail(err => {
                    const notifier = new Notifier($(this._el));

                    notifier.Failed(err.message);
                    BlockUI.Unblock();
                });
        }
    }

    EditTableView() {
        if (this._tableView().Id > 0) {
            BlockUI.Block();

            GridStore.GetDefaultTableViewMetaData(ScreenTypes[this.ScreenType])
                .then(data => {

                    LockManager.Instance.TryLock(data.TableId, this._tableView().Id)
                        .then(async () => {

                            BlockUI.Block();
                            const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
                            screenManager.GetEditScreen({
                                EntityId: data.TableId,
                                TableTypeId: data.TableTypeId,
                                RecordId: this._tableView().Id
                            })
                                .always(() => {
                                    BlockUI.Unblock();
                                })
                                .fail(error => {
                                    let notifier = new Notifier($(this._el));
                                    notifier.Warning(error.message);
                                })
                                .then((screen) => {
                                    LockManager.Instance.On(LOCK_EVENTS.RELEASED, this, (eventArgs) => {
                                        if (eventArgs.data.TableId === data.TableId && eventArgs.data.RecordId === this._tableView().Id) {
                                            screen.Close();
                                        }
                                    });
                                    screen.On('MODAL_CLOSE', this, () => {
                                        LockManager.Instance.ReleaseLock(data.TableId, this._tableView().Id);
                                    });

                                    const editScreen = screen as BaseScreen;
                                    editScreen.SetIsQueryBuilderScreen(true);

                                    editScreen.ShowInModal();

                                    editScreen.On('RECORD_DELETED', this, (eventArgs) => {
                                        this.UpdateTableViewList();
                                    });

                                    const queryBuilderControl = editScreen.GetControl(CONTROL_TYPES.QueryBuilder) as QueryBuilderControl;

                                    if (queryBuilderControl == null) {
                                        const notifier = new Notifier($(this._el));

                                        notifier.Failed(NOTIFICATIONS.QUERY_BUILDER_NOT_FOUND);
                                    } else {
                                        queryBuilderControl.SetQueryTypeName(this._viewMode);
                                        queryBuilderControl.SetSubjectRecordId(this._subjectRecordId);
                                        queryBuilderControl.SetFilterByOwners(this._filterByOwners);
                                        queryBuilderControl.SetGridId(this.GetControlId());

                                        editScreen.On('RECORD_SAVED', this, (eventArgs) => {
                                            this.UpdateTableViewList();
                                        });
                                    }
                                });
                        });
                })
                .fail(err => {
                    const notifier = new Notifier($(this._el));

                    notifier.Failed(err.message);
                })
                .always(() => {
                    BlockUI.Unblock();
                });
        }
    }

    DeleteTableView() {
        if (this._tableView().Id > 0) {
            const confirmationDialog = new ConfirmationDialog({
                Text: 'Are you sure?',
                Type: ConfirmationTypes.Question,
                TextConfirm: 'Yes',
                TextDecline: 'Cancel'
            });

            confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
                this._loadInProgress(true);

                DeleteQueryStore.Delete({Id: this._tableView().Id})
                    .always(() => {
                        this._loadInProgress(false);
                    })
                    .then(() => {
                        this._queryBuilder(null);
                        this.UpdateTableViewList();
                    });
            });
            confirmationDialog.Show();
        }
    }

    LockTableView() {
        if (this._tableView().Id > 0) {
            this._loadInProgress(true);
            LockQueryStore.Lock({Id: this._tableView().Id})
                .always(() => {
                    this._loadInProgress(false);
                })
                .then(() => {
                    this._tableView().Lock();
                });
        }
    }

    UnlockTableView() {
        if (this._tableView().Id > 0) {
            this._loadInProgress(true);
            LockQueryStore.Unlock({Id: this._tableView().Id})
                .always(() => {
                    this._loadInProgress(false);
                })
                .then(() => {
                    this._tableView().Unlock();
                });
        }
    }

    GetGridSubject(): QueryEntityJoinModel {
        if (this._viewMode === ViewModes.TableView && this._queryExpression) {
            let subject = _.first(this._queryExpression.EntityJoins);

            if (!subject) {
                return _.first(this._queryExpression.SubEntityJoins);
            }

            return subject;
        }

        return null;
    }

    async LinkSubGridRecord(mainEntityId: number, mainRecordId: number, relatedEntityId: number) {
        BlockUI.Block();
        let recordSpecs = await RecordStore.GetRecordSpecs({ TableId: mainEntityId, RecordId: mainRecordId });
        BlockUI.Unblock();

        let searchScreen = new SearchScreen({
            EntityId: relatedEntityId,
            SearchTerm: '',
            SubjectEntityId: mainEntityId,
            SubjectTypeId: recordSpecs.TypeId,
            SubjectRecordId: mainRecordId,
            ButtonAdd: this._enableAddAndLink(),
            MultiSelectMode: true,
            EnableBulkScan: true
        });

        let isBulkBarcodeScanning = false;

        this._searchScreen = searchScreen;

        searchScreen.On('RECORD_SELECTED', this, eventArgs => {
            this.SelectFromLinkSubGridRecord({
                mainEntityId,
                mainRecordId,
                linkedEntityId: relatedEntityId,
                linkedRecordId: eventArgs.data.RecordId,
                linkedRecordTypeId: eventArgs.data.TypeId,
                isBulkBarcodeScanning,
                onRecordSelect: () => {
                    searchScreen.OpenBarcodeScannerForBulkScan();
                },
            });
        });

        searchScreen.On('RECORDS_SELECTED', this, eventArgs => {
            this.SelectFromLinkSubGridRecords({
                mainEntityId,
                mainRecordId,
                linkedEntityId: relatedEntityId,
                linkedRecordIds: eventArgs.data.Ids,
                isBulkBarcodeScanning,
                onRecordSelect: () => {
                    searchScreen.OpenBarcodeScannerForBulkScan();
                }
            });
        });

        searchScreen.On('BULK_BARCODE_SCAN_STARTED', this, () => {
            isBulkBarcodeScanning = true;

            searchScreen.OpenBarcodeScannerForBulkScan();
        });

        searchScreen.On('BULK_BARCODE_SCAN_STOPPED', this, () => {
            isBulkBarcodeScanning = false;
        });

        searchScreen.On('NEW_RECORD', this, () => {
            this.OnAddAndSubGridLinkRecord(mainEntityId, mainRecordId, recordSpecs.TypeId, recordSpecs.LifeStatusId, relatedEntityId);
        });

        searchScreen.Show();
    }

    SelectFromLinkSubGridRecord({
        mainEntityId,
        mainRecordId,
        linkedEntityId,
        linkedRecordId,
        linkedRecordTypeId,
        isBulkBarcodeScanning,
        onRecordSelect
    }: {
        mainEntityId: number;
        mainRecordId: number
        linkedEntityId: number;
        linkedRecordId: number;
        linkedRecordTypeId?: number;
        isBulkBarcodeScanning: boolean;
        onRecordSelect: () => void;
    }) {
        if (isBulkBarcodeScanning) {
            BlockUI.Block();
        }
        RecordLinker.LinkRecord({
            MainTableId: mainEntityId,
            MainRecordId: mainRecordId,
            LinkedTableId: linkedEntityId,
            LinkedRecordId: linkedRecordId
        })
            .always(() => {
                if (isBulkBarcodeScanning) {
                    BlockUI.Unblock();
                    onRecordSelect();
                }
            })
            .then(result => {
                const notifier = new Notifier();

                if (!result.IsSuccessfull) {
                    notifier.Failed(result.ErrorMessage);
                    return;
                }

                let sequence = result.ResultObject.Sequence;

                notifier.Success(result.OperationMessage);

                this.LoadData(false);

                if (!isBulkBarcodeScanning) {
                    this.One('DATA_LOADED', this, () => {
                        this.UpdateLinkRecordById(linkedEntityId, linkedRecordId, linkedRecordTypeId, sequence, mainEntityId, mainRecordId);
                    });
                }
            });
    }

    SelectFromLinkSubGridRecords({
        mainEntityId,
        mainRecordId,
        linkedEntityId,
        linkedRecordIds,
        isBulkBarcodeScanning,
        onRecordSelect
    }: {
        mainEntityId: number;
        mainRecordId: number;
        linkedEntityId: number;
        linkedRecordIds: number[];
        isBulkBarcodeScanning: boolean;
        onRecordSelect: () => void;
    }) {

        if (linkedRecordIds && linkedRecordIds.length == 1) {
            this.SelectFromLinkSubGridRecord({
                mainEntityId,
                mainRecordId,
                linkedEntityId: linkedEntityId,
                linkedRecordId: linkedRecordIds[0],
                isBulkBarcodeScanning: isBulkBarcodeScanning,
                onRecordSelect: onRecordSelect
            });
            return;
        }
        
        BlockUI.Block();

        RecordLinker.LinkRecords({
            MainTableId: mainEntityId,
            MainRecordId: mainRecordId,
            LinkedTableId: linkedEntityId,
            LinkedRecordIds: linkedRecordIds
        })
            .always(() => {
                BlockUI.Unblock();

                if (isBulkBarcodeScanning) {
                    onRecordSelect();
                }
            })
            .then(result => {
                const notifier = new Notifier();

                if (!result.IsSuccessfull) {
                    notifier.Failed(result.ErrorMessage);
                    return;
                }

                notifier.Success(NOTIFICATIONS.RECORDS_LINKED);

                this.LoadData(false);
            });
    }

    LinkSubGridParentRecord(gridSubjectRow: GridRow, relatedEntityId: number) {
        let notifier = new Notifier($(this._el));

        let searchScreen = new SearchScreen({
            EntityId: relatedEntityId,
            SearchTerm: '',
            SubjectEntityId: gridSubjectRow.EntityId,
            SubjectTypeId: gridSubjectRow.RecordTypeId,
            SubjectRecordId: gridSubjectRow.RecordId,
            ButtonAdd: false,
            IsParentLinking: true
        });

        this._searchScreen = searchScreen;

        searchScreen.On('RECORD_SELECTED', this, eventArgs => {
            const childEntityId = gridSubjectRow.EntityId;
            const childRecordId = gridSubjectRow.RecordId;
            const childRecordTypeId = gridSubjectRow.RecordTypeId;

            const parentEntityId = relatedEntityId;
            const parentRecordId = eventArgs.data.RecordId;
            const parentRecordTypeId = eventArgs.data.TypeId;

            RecordLinker.LinkRecord({
                MainTableId: parentEntityId,
                MainRecordId: parentRecordId,
                LinkedTableId: childEntityId,
                LinkedRecordId: childRecordId
            }).then(result => {
                if (!result.IsSuccessfull) {
                    notifier.Failed(result.ErrorMessage);
                    return;
                }

                let sequence = result.ResultObject.Sequence;

                notifier.Success(result.OperationMessage);

                this.LoadData(false);

                this.One('DATA_LOADED', this, () => {
                    const seq = !!sequence ? sequence : 0;
                    if (this._isLinkEditorEnabled) {
                        this.UpdateLinkRecord(parentEntityId, parentRecordId, parentRecordTypeId, seq, 1, childEntityId, childRecordId, childRecordTypeId, true, true);
                    }
                });
            });
        });

        searchScreen.Show();
    }

    OnAddAndSubGridLinkRecord(
        mainEntityId: number,
        mainRecordId: number,
        mainRecordTypeId: number,
        mainRecordStatusId: number,
        relatedEntityId: number
    ) {
        const selfRelation = mainEntityId === relatedEntityId;

        const typeScreen = new TypeScreen(relatedEntityId, selfRelation ? mainRecordTypeId : null, false);

        typeScreen.On('TYPE_SELECTED', this, async eventArgs => {
            const typeId = eventArgs.data.TypeId;
            const kindId = eventArgs.data.KindId;
            const exampleRecordId = eventArgs.data.ExampleRecordId;

            await this.AddAndSubGidLinkRecord(typeId, kindId, exampleRecordId, mainEntityId, mainRecordId, mainRecordStatusId, relatedEntityId);
        })
            .On('TYPES_NOT_FOUND', this, (eventArgs) => new Notifier($(this._el)).Warning(eventArgs.data.Message || NOTIFICATIONS.SUB_TYPE_NOT_FOUND));

        typeScreen.Show();
    }

    ScanAndLinkSubGridRecord(mainEntityId: number, mainRecordId: number, relatedEntityId: number) {
        const barcodeScanner = new BarcodeScanner({isContinuous: true});

        barcodeScanner.On('SUBMIT', this, eventArgs => {
            const {value} = eventArgs.data;

            const linkedEntityId = relatedEntityId;

            GridStore.SearchByBarcode({EntityId: linkedEntityId, Barcode: value})
                .then(linkedRecordId => {
                    this.SelectFromLinkSubGridRecord({
                        mainEntityId,
                        mainRecordId,
                        linkedEntityId,
                        linkedRecordId,
                        isBulkBarcodeScanning: true,
                        onRecordSelect: () => {
                            barcodeScanner.Reenable();
                        },
                    });
                })
                .fail(error => {
                    barcodeScanner.Reenable();
                    new Notifier($(this._el)).Failed(error.message);
                });
        });

        barcodeScanner.Show();
    }

    LinkForEditScreen({ recordIds, updateLinkRecord = true, isLinkParent = false }: ILinkForEditScreenOptions) {
        if (recordIds.length === 0) {
            return;
        }

        const requestModel: IGetGridDataRequestModel = {
            ControlId: this._model().Id,

            TableViewId: this.ScreenType === ScreenTypes[ScreenTypes.Portlet] ?
                0 :
                this._tableView() ? this._tableView().Id : 0,

            SubjectEntityId: this._subjectEntityId,
            SubjectRecordId: this._subjectRecordId,
            SubjectTypeId: 0,
            SubjectKindId: 0,
            SubjectStatusId: 0,
            ViewMode: this._viewMode,
            PageNumber: this.Paginator.PageNumber,
            RecordsPerPage: this.Paginator.RecordsPerPage,
            Sort: this._sorting,
            SearchPhrase: this._searchPhrase(),
            FilterByOwners: this._filterByOwners,
            RecordOwners: this._selectedUsers,
            ShowPlanned: this._showPlanned,
            SelectedTags: this._selectedTags,
            GridSubjectRecordIds: recordIds
        };

        this._loadInProgress(true);
        return GridStore.GetData(requestModel)
            .always(() => {
                this._loadInProgress(false);
            })
            .then(gridDataModel => {
                let rows = this._baseGrid.AddLinkedRows(gridDataModel);

                rows.forEach(row=>row.IsLinkParent = isLinkParent);

                if (updateLinkRecord) {
                    let firstRow = _.first(rows);
                    if (firstRow) {

                        const mainEntityId = this._form.GetScreen().GetEntityId();
                        const mainRecordId = this._form.GetScreen().GetRecordId();
                        const mainRecordTypeId = this._form.GetScreen().GetTableTypeId();
                        const mainRecordKindId = this._form.GetScreen().GetKindId();

                        const relatedEntityId = firstRow.EntityId;
                        const relatedRecordId = firstRow.RecordId;
                        const relatedRecordTypeId = firstRow.RecordTypeId;
                        const relatedRecordKindId = firstRow.RecordKindId;

                        let loadScreenRelationModel: ILoadScreenRelationModel = {
                            MainRecordId: isLinkParent ? relatedRecordId : mainRecordId,
                            MainEntityId: isLinkParent ? relatedEntityId : mainEntityId,
                            MainRecordTypeId: isLinkParent ? relatedRecordTypeId : mainRecordTypeId,
                            MainRecordKindId: isLinkParent ? relatedRecordKindId : mainRecordKindId,
                            RelatedEntityId: isLinkParent ? mainEntityId : relatedEntityId,
                            RelatedRecordId: isLinkParent ? mainRecordId : relatedRecordId,
                            RelatedRecordTypeId: isLinkParent ? mainRecordTypeId : relatedRecordTypeId,
                            KSeq: firstRow.KSeq
                        };
                        this.UpdateLinkRecordGeneral(loadScreenRelationModel, firstRow, true, false, isLinkParent);
                        this._disabledCopyButton(false);
                    }
                } else {
                    new Notifier().Success(NOTIFICATIONS.RECORDS_LINKED);
                }
            });
    }

    LinkRecord() {
        const gridSubject = this.GetGridSubject();

        if (gridSubject) {
            let isBulkBarcodeScanning = false;

            let searchScreen = new SearchScreen({
                EntityId: gridSubject.Entity.Metadata.Id,
                SearchTerm: '',
                SubjectEntityId: this._subjectEntityId,
                SubjectTypeId: this.GetForm().GetScreen().GetTableTypeId(),
                SubjectRecordId: this.GetForm().GetScreen().GetRecordId(),
                ButtonAdd: this._enableAddAndLink(),
                ControlId: this.GetControlId(),
                HasLinkingCondition: this.HasLinkingCondition,
                EnableBulkScan: true,
                MultiSelectMode: true
            });

            this._searchScreen = searchScreen;

            searchScreen.On('RECORD_SELECTED', this, eventArgs => {
                this.SelectFromLinkRecord({
                    linkedEntityId: gridSubject.Entity.Metadata.Id,
                    linkedRecordIds: [eventArgs.data.RecordId],
                    linkedRecordTypeId: eventArgs.data.TypeId,
                    isBulkBarcodeScanning,
                    onRecordSelect: () => {
                        searchScreen.OpenBarcodeScannerForBulkScan();
                    }
                });
            });

            searchScreen.On('RECORDS_SELECTED', this, eventArgs => {
                this.SelectFromLinkRecord({
                    linkedEntityId: gridSubject.Entity.Metadata.Id,
                    linkedRecordIds: eventArgs.data.Ids,
                    isBulkBarcodeScanning,
                    onRecordSelect: () => {
                        searchScreen.OpenBarcodeScannerForBulkScan();
                    }
                });
            });

            searchScreen.On('BULK_BARCODE_SCAN_STARTED', this, () => {
                isBulkBarcodeScanning = true;

                searchScreen.OpenBarcodeScannerForBulkScan();
            });

            searchScreen.On('BULK_BARCODE_SCAN_STOPPED', this, () => {
                isBulkBarcodeScanning = false;
            });

            searchScreen.On('NEW_RECORD', this, () => {
                this.OnAddAndLinkRecord();
            });

            searchScreen.Show();
        } else {
            new Notifier().Failed(NOTIFICATIONS.GRID_SUBJECT_NOT_FOUND);
        }
    }

    SelectFromLinkRecord({linkedEntityId, linkedRecordIds, linkedRecordTypeId, isBulkBarcodeScanning, onRecordSelect}: {
        linkedEntityId: number;
        linkedRecordIds: number[];
        linkedRecordTypeId?: number;
        isBulkBarcodeScanning: boolean;
        onRecordSelect: () => void;
    }) {
        //TODO refactor

        if (this.IsEditScreen || this.IsProcessCardPageScreen) {
            let kSeqValue = _.find(this._queryExpression.EntityJoins[0].LinkEntity.Metadata.Fields,
                field => field.Name === 'K_SEQ');
            if (kSeqValue) {
                if (isBulkBarcodeScanning) {
                    BlockUI.Block();
                }
                this.LinkForEditScreen({ recordIds: linkedRecordIds, updateLinkRecord: !isBulkBarcodeScanning })
                    .always(() => {
                        if (isBulkBarcodeScanning) {
                            BlockUI.Unblock();
                            onRecordSelect();
                        }
                    });
            } else {
                let deletedRows = this._baseGrid.GetUnlinkedGridRowsByRecordIds(linkedRecordIds);

                if (isBulkBarcodeScanning) {
                    BlockUI.Block();
                }
                RecordLinker.GetExistingLinksWithRecords({
                    MainTableId: this._subjectEntityId,
                    MainRecordId: this._subjectRecordId,
                    LinkedTableId: linkedEntityId,
                    LinkedRecordIds: linkedRecordIds
                })
                    .always(() => {
                        if (isBulkBarcodeScanning) {
                            BlockUI.Unblock();
                            onRecordSelect();
                        }
                    })
                    .then(result => {
                        if (!result.IsSuccessfull) {
                            new Notifier().Failed(result.ErrorMessage);
                            return;
                        }

                        _.each(result.ResultObject, (id) => {
                            let deletedRow = _.find(deletedRows, (row) => row.RecordId === id)
                            if (deletedRow) {
                                deletedRow.LinkEditorData.DeletedRelations = _.reject(deletedRow.LinkEditorData.DeletedRelations,
                                    (item) => item.EntityId === deletedRow.EntityId
                                        && item.RecordId === deletedRow.RecordId
                                        && item.KSeq === deletedRow.KSeq);

                                this._baseGrid.AddRow(deletedRow);

                                setTimeout(() => {
                                    this._baseGrid.ResizeTableCellsAfterRender();
                                }, 0);
                            }
                        });

                        let newLinks = _.difference(linkedRecordIds, result.ResultObject);
                        this.LinkForEditScreen({ recordIds: newLinks, updateLinkRecord: !isBulkBarcodeScanning });
                    });
            }
        } else {

            BlockUI.Block();

            if (linkedRecordIds.length === 1) {
                RecordLinker.LinkRecord({
                    MainTableId: this._subjectEntityId,
                    MainRecordId: this._subjectRecordId,
                    LinkedTableId: linkedEntityId,
                    LinkedRecordId: linkedRecordIds[0]
                })
                    .always(() => {
                        BlockUI.Unblock();                        
                        if (isBulkBarcodeScanning) {
                                onRecordSelect();
                        }
                    })
                    .then(result => {
                        if (!result.IsSuccessfull) {
                            new Notifier().Failed(result.ErrorMessage);
                            return;
                        }

                        const notifier = new Notifier();

                        if (result.Warnings.length > 0) {
                            result.Warnings.forEach(w => notifier.Warning(w.replace("/n", "<br>")));
                        }

                        let sequence = result.ResultObject.Sequence;

                        notifier.Success(result.OperationMessage);

                        this._disabledCopyButton(false);

                        if (!isBulkBarcodeScanning) {
                            this.UpdateLinkRecordById(linkedEntityId, linkedRecordIds[0], linkedRecordTypeId, sequence);
                        }
                    });
            } else {
                RecordLinker.LinkRecords({
                    MainTableId: this._subjectEntityId,
                    MainRecordId: this._subjectRecordId,
                    LinkedTableId: linkedEntityId,
                    LinkedRecordIds: linkedRecordIds
                })
                    .always(() => {
                        BlockUI.Unblock();
                        
                        if (isBulkBarcodeScanning) {
                            onRecordSelect();
                        }
                    })
                    .then(result => {
                        if (!result.IsSuccessfull) {
                            new Notifier().Failed(result.ErrorMessage);
                            return;
                        }

                        const notifier = new Notifier();

                        if (result.Warnings.length > 0) {
                            result.Warnings.forEach(w => notifier.Warning(w.replace("/n", "<br>")));
                        }

                        notifier.Success(result.OperationMessage);

                        this.LoadData(false);
                        this._disabledCopyButton(false);
                    });
            }
        }
    }

    LinkParentRecord() {
        const gridSubject = this.GetGridSubject();
        let notifier = new Notifier($(this._el));

        if (gridSubject) {
            let searchScreen = new SearchScreen({
                EntityId: gridSubject.Entity.Metadata.Id,
                SearchTerm: '',
                SubjectEntityId: this._subjectEntityId,
                SubjectTypeId: this.GetForm().GetScreen().GetTableTypeId(),
                SubjectRecordId: this.GetForm().GetScreen().GetRecordId(),
                ButtonAdd: false,
                ControlId: this.GetControlId(),
                HasLinkingCondition: this.HasLinkingCondition,
                IsParentLinking: true
            });

            this._searchScreen = searchScreen;

            searchScreen.On('RECORD_SELECTED', this, eventArgs => {
                const childEntityId = this._subjectEntityId;
                const childRecordId = this._subjectRecordId;
                const childRecordTypeId = this.GetForm().GetScreen().GetTableTypeId();

                const parentEntityId = gridSubject.Entity.Metadata.Id;
                const parentRecordId = eventArgs.data.RecordId;
                const parentRecordTypeId = eventArgs.data.TypeId;

                if (this.ScreenType === ScreenTypes[ScreenTypes.EditScreen]) {
                    let hasKSeq = _.any(this._queryExpression.EntityJoins[0].LinkEntity.Metadata.Fields,
                        field => field.Name === 'K_SEQ');
                    if (hasKSeq) {
                        this.LinkForEditScreen({ recordIds: [parentRecordId], isLinkParent: true })
                    } else {
                        let deletedRows = this._baseGrid.GetUnlinkedGridRowsByRecordIds([parentRecordId]);
        
                        RecordLinker.GetExistingLinksWithRecords({
                            MainTableId: parentEntityId,
                            MainRecordId: parentRecordId,
                            LinkedTableId: this._subjectEntityId,
                            LinkedRecordIds: [this._subjectRecordId]
                        })
                            .then(result => {
                                if (!result.IsSuccessfull) {
                                    new Notifier().Failed(result.ErrorMessage);
                                    return;
                                }
        
                                _.each(result.ResultObject, (id) => {
                                    let deletedRow = _.find(deletedRows, (row) => row.RecordId === id)
                                    if (deletedRow) {
                                        deletedRow.LinkEditorData.DeletedRelations = _.reject(deletedRow.LinkEditorData.DeletedRelations,
                                            (item) => item.EntityId === deletedRow.EntityId
                                                && item.RecordId === deletedRow.RecordId
                                                && item.KSeq === deletedRow.KSeq);
        
                                        this._baseGrid.AddRow(deletedRow);
        
                                        setTimeout(() => {
                                            this._baseGrid.ResizeTableCellsAfterRender();
                                        }, 0);
                                    }
                                });
        
                                let newLinks = _.difference([], result.ResultObject);
                                this.LinkForEditScreen({ recordIds: newLinks, isLinkParent: true });
                            });
                    }
                } else {

                    RecordLinker.LinkRecord({
                        MainTableId: parentEntityId,
                        MainRecordId: parentRecordId,
                        LinkedTableId: childEntityId,
                        LinkedRecordId: childRecordId
                    })
                    .then(result => {
                        if (!result.IsSuccessfull) {
                            notifier.Failed(result.ErrorMessage);
                            return;
                        }

                        const sequence = result.ResultObject.Sequence;

                        notifier.Success(result.OperationMessage);

                        this.LoadData(false);

                        this.One('DATA_LOADED', this, () => {
                            const seq = !!sequence ? sequence : 0;
                            if (this._isLinkEditorEnabled) {
                                this.UpdateLinkRecord(parentEntityId, parentRecordId, parentRecordTypeId, seq, 1, childEntityId, childRecordId, childRecordTypeId, true, true);
                            } else if (this._refreshScreenAfterManipulations) {
                                this.GetForm().GetScreen().Refresh();
                            }
                        });
                    });
                }
            });

            searchScreen.Show();
        } else {
            notifier.Failed(NOTIFICATIONS.GRID_SUBJECT_NOT_FOUND);
        }
    }

    CopyLatestLink(): void {
        const screen = this._form.GetScreen();
        const subjectEntityId = screen.GetEntityId();
        const subjectRecordId = screen.GetRecordId();
        const relatedEntityId = this.GetGridSubject().Entity.Metadata.Id;

        if (this._form.GetScreen().GetRecordId()) {

            const firstRow = this._baseGrid.GetRowByIndex(0);
            if (!firstRow) {
                return new Notifier().Warning(NOTIFICATIONS.NOTHING_TO_COPY);
            }

            if (firstRow.State === States.New || firstRow.State === States.Link || firstRow.State === States.LinkAndEdit) {
                this.CopyEntityRowRecord(0);
                return;
            }

            BlockUI.Block();
            LinkRecordsStore.GetLastLinkedRecordEditScreen({
                MainTableId: subjectEntityId,
                LinkedTableId: relatedEntityId,
                MainRecordId: subjectRecordId
            }).then(async linkedRecord => {

                if (!linkedRecord) {
                    return new Notifier().Warning(NOTIFICATIONS.LINK_RECORDS_NOT_FOUND);
                }

                await this.CopyLinkedRecord({
                    RecordId: linkedRecord.RightRecordId,
                    TypeId: linkedRecord.RightRecordTypeId,
                    KindId: linkedRecord.RightRecordKindId,
                    IsCorrectDirection: linkedRecord.IsCorrectDirection
                }, DataModes.Copy);
            }).fail(err => {
                new Notifier().Failed(err.message)
            }).always(() => {
                BlockUI.Unblock();
            });
        } else {
            // When new record without example
            this.CopyEntityRowRecord(0);
            return;
        }
    }

    CopyLatestSubRecord(): void {
        const screen = this._form.GetScreen();
        const subjectEntityId = screen.GetEntityId();
        const subjectRecordId = screen.GetRecordId();
        const relatedSubEntityId = this.GetGridSubject().Entity.Metadata.Id;

        if (this._form.GetScreen().GetRecordId()) {
            const firstRow = this._baseGrid.GetRowByIndex(0);
            if (!firstRow) {
                return new Notifier().Warning(NOTIFICATIONS.NOTHING_TO_COPY);
            }

            if (firstRow.State === States.New || firstRow.State === States.Link || firstRow.State === States.LinkAndEdit) {
                this.CopySubEntityRowRecord(0);
                return;
            }

            if (this.ScreenType === ScreenTypes[ScreenTypes.ConsultScreen]) {
                this.RecreateLatestSubRecord();
                return;
            }

            if (this.ScreenType === ScreenTypes[ScreenTypes.EditScreen]) {
                this.CopyLatestSubRecordRow();
                return;
            }

        } else {
            this.CopySubEntityRowRecord(0);
            return;
        }
    }

    async CopyEntityRowRecord(rowIndex: number) {
        const rowToDuplicate = this._baseGrid.GetRowByIndex(rowIndex);
        if (!rowToDuplicate) {
            return new Notifier().Warning(NOTIFICATIONS.NOTHING_TO_COPY);
        }

        const rowRecordId = rowToDuplicate.Model.RecordId;
        const rowRecordTypeId = rowToDuplicate.Model.TypeId;
        const rowRecordKindId = rowToDuplicate.Model.KindId;
        if (!rowRecordId) {
            this._baseGrid.DuplicateRow(rowToDuplicate);
            return;
        }

        await this.CopyLinkedRecord({
            RecordId: rowRecordId,
            TypeId: rowRecordTypeId,
            KindId: rowRecordKindId,
            IsCorrectDirection: this._isDirectionCorrect
        }, DataModes.Copy);
    }

    OnAddAndLinkRecord() {
        const gridSubject = this.GetGridSubject();

        if (gridSubject) {
            const selfRelation = gridSubject.Entity.Metadata.Id === this._subjectEntityId;

            const showTypeSelector = () => {
                const parentTypeId = this.GetForm().GetScreen().GetTableTypeId();
                const typeScreen = new TypeScreen(gridSubject.Entity.Metadata.Id,
                    selfRelation ? parentTypeId : null,
                    false);

                typeScreen.On('TYPE_SELECTED', this, async eventArgs => {
                    const typeId = eventArgs.data.TypeId;
                    const kindId = eventArgs.data.KindId;
                    const exampleRecordId = eventArgs.data.ExampleRecordId;

                    await this.AddAndLinkRecord(typeId, kindId, exampleRecordId);
                })
                    .On('TYPES_NOT_FOUND', this, eventArgs => new Notifier($(this._el)).Warning(eventArgs.data.Message || NOTIFICATIONS.SUB_TYPE_NOT_FOUND));

                typeScreen.Show();
            }

            if (this.ScreenType === ScreenTypes[ScreenTypes.ConsultScreen] && this._form && !this._form.GetScreen().IsInModal()) {

                const profileSelectorScreen = new ProfileSelectorScreen(gridSubject.Entity.Metadata.Id, gridSubject.Entity.Metadata.Name);

                profileSelectorScreen
                    .On('PROFILES_NOT_FOUND', this, () => {
                        new Notifier().Warning('Profiles with create possibilities not found');
                    })
                    .On('PROFILE_SELECTION_CANCELLED', this, () => {
                        profileSelectorScreen.Close();
                    })
                    .On('PROFILE_SELECTED', this, () => {
                        profileSelectorScreen.Close();
                        showTypeSelector();
                    })
                    .On('USED_CURRENT_PROFILE', this, () => {
                        profileSelectorScreen.Close();
                        showTypeSelector();
                    });

                profileSelectorScreen.ShowIfNeeded();
            } else {
                showTypeSelector();
            }
        } else {
            const notifier = new Notifier($(this._el));

            notifier.Failed(NOTIFICATIONS.GRID_SUBJECT_NOT_FOUND);
        }
    }

    UnlinkMultipleRecords() {
        let selectedRows: Array<IUnlinkRecordRequestModel> = [];
        let selectedRowsRequestModel: IUnlinkMultipleRecordsRequestModel = null;

        _.each(this._dataModel.Rows, row => {
           if (row.IsUnlinkCheckboxChecked) {
               let requestRowModel: IUnlinkRecordRequestModel = {
                   MainEntityId: this._subjectEntityId,
                   MainRecordId: this._subjectRecordId,
                   RelatedEntityId: row.EntityId,
                   RelatedRecordId: row.RecordId,
                   KSeq: row.KSeq,
                   RelationshipType: row.RelationshipType
               };

               if (row.RelationshipType == RelationshipTypes.Parent) {
                   requestRowModel.MainEntityId = row.EntityId;
                   requestRowModel.MainRecordId = row.RecordId;
                   requestRowModel.RelatedEntityId = this._subjectEntityId;
                   requestRowModel.RelatedRecordId = this._subjectRecordId;
                   requestRowModel.RelationshipType = row.RelationshipType;
               }

               selectedRows.push(requestRowModel);

               selectedRowsRequestModel = {
                   Rows: selectedRows
               }

               //row.IsUnlinkCheckboxChecked = false;
           }
        });

        const notifier = new Notifier(null);

        if (selectedRowsRequestModel) {
            BlockUI.Block();

            GridStore.UnlinkMultipleRecords(selectedRowsRequestModel)
                .always(() => {
                    BlockUI.Unblock();
                })
                .then(result => {

                    if (result.IsSuccessfull) {
                        if (this._refreshScreenAfterManipulations) {
                            this.GetForm().GetScreen().Refresh();

                            return;
                        }

                        if (this._baseGrid.RowsCount == 0) {
                            this._toolbar.Paginator().Reset();
                        } else {
                            this.LoadData(false);
                        }
                    } else if (result.Warnings && result.Warnings.length > 0) {
                        _.each(result.Warnings, (warning) => notifier.Warning(warning));
                    } else {
                        notifier.Failed(result.ErrorMessage);
                    }
                });

            selectedRowsRequestModel = null;
        } else {
            notifier.Warning(LABELS.NO_RECORD_SELECTED);
        }
    }

    ScanAndLinkRecord() {
        const gridSubject = this.GetGridSubject();

        if (!gridSubject) {
            new Notifier().Failed(NOTIFICATIONS.GRID_SUBJECT_NOT_FOUND);
            return;
        }

        const barcodeScanner = new BarcodeScanner({isContinuous: true});

        barcodeScanner.On('SUBMIT', this, eventArgs => {
            const {value} = eventArgs.data;

            const linkedEntityId = gridSubject.Entity.Metadata.Id;

            GridStore.SearchByBarcode({EntityId: linkedEntityId, Barcode: value})
                .then(linkedRecordId => {
                    this.SelectFromLinkRecord({
                        linkedEntityId,
                        linkedRecordIds: [linkedRecordId],
                        isBulkBarcodeScanning: true,
                        onRecordSelect: () => {
                            barcodeScanner.Reenable();
                        }
                    });
                })
                .fail(error => {
                    barcodeScanner.Reenable();
                    new Notifier().Failed(error.message);
                });
        });

        barcodeScanner.Show();
    }

    EnableRecordSelection(){
        this._isEnableSelectRecord(true);
    }

    DisableRecordSelection(){
        this._isEnableSelectRecord(true);
    }

    AddQuery() {
        BlockUI.Block();
        TableStore.Get({TableName: 'QUERIES'}).then((table: any) => {
            EntityTypesStore.GetTypes({
                EntityId: table.Id,
                ParentTypeId: 0,
                WithRoot: true,
                OnlyEnabled: true
            })
                .always(() => {
                    BlockUI.Unblock();
                })
                .then(tableTypesModel => {

                    let queryType = _.find(tableTypesModel.TableTypes, (type) => {
                        return type.Name === 'Query';
                    });

                    let searchScreen = new SearchScreen({
                        EntityId: table.Id,
                        SearchTerm: '',
                        SubjectEntityId: this._subjectEntityId,
                        SubjectTypeId: this.GetForm().GetScreen().GetTableTypeId(),
                        ButtonAdd: false,
                        IsAddQueryButton: true,
                        SearchByTypes: [queryType.Id],
                        QuerySubjectId: this.GetGridSubject().Entity.Metadata.Id
                    });

                    searchScreen.On('RECORD_SELECTED', this, eventArgs => {
                        this.ShowQueryResultScreen(eventArgs.data.RecordId);
                    });

                    searchScreen.Show();
                });
        });
    }

    ShowQueryResultScreen(queryId: number) {
        const queryResultPage = new QueryResultPage(true);
        queryResultPage.ShowAddQueryResult(queryId);
        queryResultPage.On('RECORDS_SELECTED', this, (eventArgs: any) => {
            this.LinkRecords(eventArgs.data.SelectedRecords);
        });
    }

    LinkRecords(selectedRecords: Array<GridRow>) {
        const linkedRecordIds = [];

        _.each(selectedRecords, (item: GridRow) => {
            linkedRecordIds.push(item.RecordId);
        });

        const requestModel: ILinkRecordsRequestModel = {
            MainTableId: this._subjectEntityId,
            LinkedTableId: this._model().EntityId,
            MainRecordId: this._subjectRecordId,
            LinkedRecordIds: linkedRecordIds
        };

        const notifier = new Notifier();

        if (linkedRecordIds.length > 0) {
            BlockUI.Block();

            LinkRecordsStore.LinkWithRecords(requestModel)
                .always(() => {
                    BlockUI.Unblock();
                })
                .then(result => {
                    if (result.IsSuccessfull) {
                        this.LoadData(false);
                        notifier.Success(NOTIFICATIONS.RECORDS_LINKED);
                        this._disabledCopyButton(false);
                    } else {
                        notifier.Failed(result.ErrorMessage);
                    }
                });
        } else {
            notifier.Warning(NOTIFICATIONS.NO_SELECTED_RECORDS);
        }
    }

    GoToRecordScreen(data: any) {
        data.IsOpenInModal = data.ShowInModal ? data.ShowInModal : this._form.GetScreen().IsInModal();
        data.Owner = this._form.GetScreen();
        PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, data);
    }

    SetValue(value: IControlValue): void {
        if (value.SubjectRecordId && (this._form.GetScreen().IsConsultScreen || this._form.GetScreen().IsPortlet)) {
            this._viewMode = ViewModes.TableView;
        }

        this._subjectRecordId = value.SubjectRecordId;
        this._subjectRecordSpecsModel = value.RecordSpecsModel;

        if (this._isRendered()) {
            this.UpdateTableViewList();
        }

        this._isRendered.subscribe(() => this.UpdateTableViewList());
    }

    SetSubjectRecord(recordId: number) {
        this._subjectRecordId = recordId;
    }

    EnterKey() {
        if (this.Paginator) {
            this.Paginator.PageNumber = 1;
        }
        this.LoadData(false);
    }

    SetRecordsPerPage(recordsPerPage: number) {
        this.UpdateTableViewList();
        this.Paginator.RecordsPerPage = recordsPerPage;
    }

    SetPageNumber(pageNumber: number) {
        this.Paginator.PageNumber = pageNumber;
    }

    AddStyle(name: string, value: string): void {
    }

    IsReady: KnockoutObservable<boolean>;

    get Fields() {
        return this._model().Fields;
    }

    get DesignFields(): KnockoutObservableArray<AttachedFieldModel> {
        return this._designFields;
    }

    private CopySubEntityRowRecord(rowIndex: number) {
        const rowToDuplicate = this._baseGrid.GetRowByIndex(rowIndex);
        if (!rowToDuplicate) {
            return new Notifier().Warning(NOTIFICATIONS.NOTHING_TO_COPY);
        }

        this._baseGrid.DuplicateRow(rowToDuplicate);
    }

    private RecreateLatestSubRecord() {
        const screen = this._form.GetScreen();
        const subjectEntityId = screen.GetEntityId();
        const subjectRecordId = screen.GetRecordId();
        const relatedSubEntityId = this.GetGridSubject().Entity.Metadata.Id;

        BlockUI.Block();
        LinkRecordsStore.CopyLastSubRecord({
            MainTableId: subjectEntityId,
            SubTableId: relatedSubEntityId,
            MainRecordId: subjectRecordId
        }).then(() => {
            this.LoadData(false);
        })
            .fail(err => new Notifier().Failed(err.message))
            .always(() => BlockUI.Unblock());
    }

    private CopyLatestSubRecordRow() {
        const screen = this._form.GetScreen();
        const subjectEntityId = screen.GetEntityId();
        const subjectRecordId = screen.GetRecordId();
        const relatedSubEntityId = this.GetGridSubject().Entity.Metadata.Id;

        BlockUI.Block();
        LinkRecordsStore.GetLastSubRecordSpecs({
            SubTableId: relatedSubEntityId,
            MainRecordId: subjectRecordId
        }).then(recordSpecs => {
            const requestModel: IGetGridDataRequestModel = {
                ControlId: this._model().Id,

                TableViewId: this.ScreenType === ScreenTypes[ScreenTypes.Portlet] ?
                    0 :
                    this._tableView() ? this._tableView().Id : 0,

                SubjectEntityId: this._subjectEntityId,
                SubjectRecordId: this._subjectRecordId,
                SubjectTypeId: 0,
                SubjectKindId: 0,
                SubjectStatusId: 0,
                ViewMode: this._viewMode,
                PageNumber: this.Paginator.PageNumber,
                RecordsPerPage: this.Paginator.RecordsPerPage,
                Sort: this._sorting,
                SearchPhrase: this._searchPhrase(),
                FilterByOwners: this._filterByOwners,
                RecordOwners: this._selectedUsers,
                ShowPlanned: this._showPlanned,
                SelectedTags: this._selectedTags,
                GridSubjectRecordIds: [recordSpecs.SequenceId]
            };

            this._loadInProgress(true);
            GridStore.GetData(requestModel)
                .then(gridDataModel => {
                    this._baseGrid.AddRowsFrom(gridDataModel);
                })
                .fail(err => {
                    new Notifier().Failed(err.message);
                })
                .always(() => {
                    this._loadInProgress(false);
                });
        }).fail(err => {
            new Notifier().Failed(err.message);
        }).always(() => {
            BlockUI.Unblock()
        });
    }

    private async AddAndSubGidLinkRecord(
        tableTypeId: number,
        kindId: number,
        exampleRecordId: number,
        mainEntityId: number,
        mainRecordId: number,
        mainRecordStatusId: number,
        relatedEntityId: number
    ) {
        BlockUI.Block();
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
        screenManager.GetEditScreen({
            EntityId: relatedEntityId,
            TableTypeId: tableTypeId,
            KindId: kindId,
            RecordId: exampleRecordId,
            LoadAsExample: exampleRecordId > 0,
            SubjectLifestatusId: mainRecordStatusId
        }).always(() => {
            BlockUI.Unblock();
        })
            .fail(error => {
                const notifier = new Notifier($(this._el));

                notifier.Warning(error.message);
            }).then((screen) => {
            const editScreen = screen as EditScreen;
            editScreen.IsDataFromExample = exampleRecordId > 0;

            if (this.ScreenType === ScreenTypes[ScreenTypes.EditScreen]) {

            } else {
                editScreen.SaveImmediately = false;
                screen.On('READY_TO_SAVE', this, (eventArgs) => {
                    this.LinkSubGridCreatedRecord(eventArgs.data, mainEntityId, mainRecordId, relatedEntityId);
                    if (this._searchScreen) {
                        this._searchScreen.Close();
                    }
                });

            }
            screen.ShowInModal();
        });
    }

    private async CopyLinkedRecord(linkRecordSpecs: { TypeId: number, KindId: number, RecordId: number, IsCorrectDirection: boolean }, dataMode: DataModes) {
        const linkedEntityId = this.GetGridSubject().Entity.Metadata.Id;

        BlockUI.Block();
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
        screenManager.GetEditScreen({
            EntityId: linkedEntityId,
            TableTypeId: linkRecordSpecs.TypeId,
            KindId: linkRecordSpecs.KindId,
            RecordId: linkRecordSpecs.RecordId,
            LoadAsExample: true,
            DataMode: dataMode
        }).always(() => {
            BlockUI.Unblock()
        }).then((screen: EditScreen) => {
            screen.IsDataFromExample = true;

            let subjectAlreadyLinked = false;
            if (this.ScreenType === ScreenTypes[ScreenTypes.ConsultScreen] && screen.GetControl(CONTROL_TYPES.LinkList) && linkRecordSpecs.IsCorrectDirection === false) {
                screen.On('LINK_LIST_DATA_LOADED',
                    this,
                    eventArgs => {
                        let linkListControl = eventArgs.data.Control as LinkList;
                        let model = _.find(linkListControl.DataModel().Entities(), entity => entity.EntityId === this._form.GetScreen().GetEntityId());
                        if (model) {
                            linkListControl.AfterSelectRecord([this._form.GetScreen().GetRecordId()], model);
                            subjectAlreadyLinked = true;
                        }
                    });
            }

            screen.On('RECORD_SAVED', this, eventArgs => {
                if (this.ScreenType === ScreenTypes[ScreenTypes.ConsultScreen]) {
                    if (subjectAlreadyLinked) {
                        return this.LoadData(false);
                    }
                    return this.LinkCreatedRecord(eventArgs.data, !linkRecordSpecs.IsCorrectDirection);
                }

                if (this.ScreenType === ScreenTypes[ScreenTypes.EditScreen]) {
                    this.LinkForEditScreen({ recordIds: eventArgs.data.RecordId, updateLinkRecord: false });
                }
            });

            screen.ShowInModal();
        });
    }

    private async AddAndLinkRecord(tableTypeId: number, kindId: number, exampleRecordId: number) {
        const gridSubject = this.GetGridSubject();
        const globalManager = GlobalManager.Instance;
        const actionGlobal = globalManager.GetGlobal(GLOBALS.ACTION_TABLE);
        const actionSubjectRecord = gridSubject.Entity.Metadata.Name === actionGlobal
            ? new ActionSubjectRecordModel(this._subjectEntityId, this._subjectRecordId)
            : null;
        if (gridSubject) {
            BlockUI.Block();
            const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
            screenManager.GetEditScreen({
                EntityId: gridSubject.Entity.Metadata.Id,
                TableTypeId: tableTypeId,
                KindId: kindId,
                RecordId: exampleRecordId,
                LoadAsExample: exampleRecordId > 0,
                SubjectLifestatusId: this._subjectRecordSpecsModel.LifeStatusInfo.Id,
                ActionSubjectRecord: actionSubjectRecord
            })
                .always(() => {
                    BlockUI.Unblock();
                })
                .fail(error => {
                    const notifier = new Notifier($(this._el));

                    notifier.Warning(error.message);
                })
                .then((screen) => {
                    const editScreen = screen as EditScreen;

                    editScreen.IsDataFromExample = exampleRecordId > 0;

                    //TODO refactor
                    if (this.ScreenType === ScreenTypes[ScreenTypes.EditScreen]) {
                        screen.On('RECORD_SAVED', this, (eventArgs) => {
                            const requestModel: IGetGridDataRequestModel = {
                                ControlId: this._model().Id,

                                TableViewId: this.ScreenType === ScreenTypes[ScreenTypes.Portlet] ?
                                    0 :
                                    this._tableView() ?
                                        this._tableView().Id : 0,

                                SubjectEntityId: this._subjectEntityId,
                                SubjectRecordId: this._subjectRecordId,
                                SubjectTypeId: 0,
                                SubjectKindId: 0,
                                SubjectStatusId: 0,
                                ViewMode: this._viewMode,
                                PageNumber: this.Paginator.PageNumber,
                                RecordsPerPage: this.Paginator.RecordsPerPage,
                                Sort: this._sorting,
                                SearchPhrase: this._searchPhrase(),
                                FilterByOwners: this._filterByOwners,
                                RecordOwners: this._selectedUsers,
                                ShowPlanned: this._showPlanned,
                                SelectedTags: this._selectedTags,
                                GridSubjectRecordIds: [eventArgs.data.RecordId]
                            };

                            this._loadInProgress(true);
                            GridStore.GetData(requestModel)
                                .always(() => {
                                    this._loadInProgress(false);
                                })
                                .then(gridDataModel => {
                                    let rows = this._baseGrid.AddLinkedRows(gridDataModel);

                                    let firstRow = _.first(rows);
                                    if (firstRow) {
                                        const subjectEntityId = this._form.GetScreen().GetEntityId();
                                        const subjectRecordId = this._form.GetScreen().GetRecordId();
                                        const subjectRecordTypeId = this._form.GetScreen().GetTableTypeId();
                                        const subjectRecordKindId = this._form.GetScreen().GetKindId();

                                        let loadScreenRelationModel: ILoadScreenRelationModel = {
                                            MainRecordId: subjectRecordId,
                                            MainEntityId: subjectEntityId,
                                            MainRecordTypeId: subjectRecordTypeId,
                                            MainRecordKindId: subjectRecordKindId,
                                            RelatedEntityId: firstRow.EntityId,
                                            RelatedRecordId: firstRow.RecordId,
                                            RelatedRecordTypeId: firstRow.RecordTypeId,
                                            KSeq: firstRow.KSeq
                                        };
                                        this.UpdateLinkRecordGeneral(loadScreenRelationModel, firstRow, true);
                                    }
                                    this._disabledCopyButton(false);
                                });
                        });
                    } else if (gridSubject.Entity.Metadata.Id === this._subjectEntityId) {
                        editScreen.SaveImmediately = false;
                        screen.On('READY_TO_SAVE', this, (eventArgs) => {
                            this.LinkCreatedRecord(eventArgs.data);
                            if (this._searchScreen) {
                                this._searchScreen.Close();
                            }
                            this._disabledCopyButton(false);
                        });
                    } else {
                        if (screen.GetControl(CONTROL_TYPES.LinkList)) {
                            screen.On('LINK_LIST_DATA_LOADED',
                                this,
                                eventArgs => {
                                    let linkListControl = eventArgs.data.Control as LinkList;
                                    let model = _.find(linkListControl.DataModel().Entities(), entity => entity.EntityId === this._subjectEntityId);
                                    if (model) {
                                        linkListControl.AfterSelectRecord([this._subjectRecordId], model);
                                        editScreen.SaveImmediately = true;
                                    } else {
                                        editScreen.SaveImmediately = false;
                                    }
                                });
                        } else {
                            editScreen.SaveImmediately = false;
                        }

                        screen.On('READY_TO_SAVE', this, eventArgs => this.LinkCreatedRecord(eventArgs.data));

                        screen.On('RECORD_SAVED',
                            this,
                            () => {
                                this.LoadData(false);
                            });
                    }
                    screen.ShowInModal();
                });
        } else {
            const notifier = new Notifier($(this._el));

            notifier.Failed(NOTIFICATIONS.GRID_SUBJECT_NOT_FOUND);
        }
    }

    private LinkCreatedRecord(record: DataModel, isSubjectChild?: boolean) {
        const gridSubject = this.GetGridSubject();

        if (gridSubject) {
            const linkRecordModel = isSubjectChild === true
                ? {
                    MainTableId: gridSubject.Entity.Metadata.Id,
                    LinkedTableId: this._subjectEntityId,
                    LinkedRecordId: this._subjectRecordId
                }
                : {
                    MainTableId: this._subjectEntityId,
                    MainRecordId: this._subjectRecordId,
                    LinkedTableId: gridSubject.Entity.Metadata.Id
                };

            const addAndLinkRecordModel: INewLinkedRecord = {
                InsertOrUpdateRecordModel: record,
                LinkRecordModel: linkRecordModel
            };

            BlockUI.Block();

            RecordLinker.AddAndLinkRecord(addAndLinkRecordModel)
                .always(() => {
                    BlockUI.Unblock();
                })
                .then(result => {
                    const notifier = new Notifier($(this._el));

                    if (!result.IsSuccessfull) {
                        notifier.Failed(result.ErrorMessage);

                        return;
                    }

                    notifier.Success(result.OperationMessage);
                    this.LoadData(false);

                    this.One('DATA_LOADED', this, (eventArgs) => {
                        let typeId = this.GetForm().GetScreen().GetTableTypeId();
                        this.UpdateLinkRecordById(gridSubject.Entity.Metadata.Id, result.ResultObject.RecordId, typeId, result.ResultObject.Kseq);
                    });
                });
        }
    }

    private LinkSubGridCreatedRecord(record: DataModel, mainEntityId: number, mainRecordId: number, relatedEntityId: number) {
        const addAndLinkRecordModel: INewLinkedRecord = {
            InsertOrUpdateRecordModel: record,
            LinkRecordModel: {
                MainTableId: mainEntityId,
                MainRecordId: mainRecordId,
                LinkedTableId: relatedEntityId
            }
        };

        BlockUI.Block();

        RecordLinker.AddAndLinkRecord(addAndLinkRecordModel)
            .always(() => {
                BlockUI.Unblock();
            })
            .then(result => {
                const notifier = new Notifier($(this._el));

                if (!result.IsSuccessfull) {
                    notifier.Failed(result.ErrorMessage);
                } else {

                    notifier.Success(result.OperationMessage);
                    this.LoadData(false);

                    this.One('DATA_LOADED', this, () => {
                        let typeId = this.GetForm().GetScreen().GetTableTypeId();

                        UserVarsManager.Instance.AddRecent(relatedEntityId, result.ResultObject.RecordId, typeId);
                        this.UpdateLinkRecordById(relatedEntityId, result.ResultObject.RecordId, typeId, result.ResultObject.Kseq);
                    });
                }
            });
    }

    get IsEditAll(): boolean {
        return this._baseGrid.IsSubEntity && this.GeneralProperties.GetPropertyValue(PROPERTIES.EDIT_ALL);
    }

    FilterByTags(selectedTags: any) {
        this._selectedTags = selectedTags;
        this.LoadData(false);
    }

    GetRecordIds(): Array<number> {
        return this._baseGrid.GetRecordIds();
    }


    GetAllRecordKeys() {
        return this._baseGrid.GetAllRecordKeys();
    }

    GetSelectRecords(): Array<GridRow> {
        return this._baseGrid.GetSelectRecords();
    }

    Serialize(): Array<GridRowDataModel> {
        return this._baseGrid.SerializeRows();
    }

    SerializeLinkEditorData() {
        return this._baseGrid.SerializeLinkEditorData();
    }

    private UpdateLinkRecordById(
        relatedEntityId: number,
        relatedRecordId: number,
        relatedRecordTypeId: number,
        sequence?: number,
        mainEntityId?: number,
        mainRecordId?: number
    ) {
        let seq = !!sequence ? sequence : 0;

        if (this._isLinkEditorEnabled) {
            this.UpdateLinkRecord(relatedEntityId, relatedRecordId, relatedRecordTypeId, seq, 0, mainEntityId, mainRecordId, null, true);
        } else if (this._refreshScreenAfterManipulations) {
            this.GetForm().GetScreen().Refresh();
        }
    }

    FormatControlName(control: ControlModel) {
        let translatedName = _.find(control.NameTranslations, (translation) => translation.Selected);
        if (translatedName && translatedName.Translation !== null && translatedName.Translation !== '') {
            return translatedName.Translation;
        } else {
            return !!control.Label ? control.Label : control.Name;
        }
    }

    FormatFieldName(field: AttachedFieldModel) {
        let entityName: KnockoutObservable<string> = ko.observable(null);
        let fieldName: KnockoutObservable<string> = ko.observable(null);

        entityName(field.EntityNameTranslation || field.EntityName);
        fieldName(field.FieldNameTranslation || field.Name);

        return `${entityName()}.${fieldName()}`;
    }

    IsValid(): boolean {
        if (this._baseGrid && this._baseGrid.HasEditRows()) {
            this._errorMessage('Please save record');
            this._isValid(false);
        } else {
            this._errorMessage('');
            this._isValid(true);
        }
        return this._isValid();
    }

    SaveEditRows() {
        this._baseGrid.SaveEditRows();
    }

    ReleaseLockRows() {
        this._baseGrid.ReleaseLockRows();
    }

    private ExportData(destination: string) {
        const recordId = this.GetForm().GetScreen().GetRecordId();

        const exportParams: IExportDataRequestModel = {
            Query: this._queryExpression,
            Destination: destination,
            ViewMode: this._viewMode,
            FilterByOwners: this._filterByOwners,
            RecordOwners: this._selectedUsers,
            ShowPlanned: this._showPlanned,
            RecordId: recordId,
            ShowRetired: this._showRetiredRecords
        };

        BlockUI.Block();

        GridStore.ExportData(exportParams)
            .always(() => BlockUI.Unblock())
            .then((fileName: string) => FileDownloader.DownloadFile(fileName))
            .fail(error => new Notifier().Failed(error.message));
    }

    TogglePriorities(state) {
        const subjectEntityId = this.GetForm() && this.GetForm().GetScreen().GetEntityId();
        const gridSubjectEntityId = this._viewMode === ViewModes.ListView ? 0 : this._model().EntityId;
        !MobileChecker.IsMobile() && this._userVarsManager.SetGridPriority(this._subjectEntityId ? this._subjectEntityId : subjectEntityId, gridSubjectEntityId, state);
        this._isPrioritiesDisabled = state;
        this.LoadData(false);
    }

    BulkEdit() {
        this._baseGrid.ShowBulkEditor();
    }

    private ListenForWrapperResize() {
        const gridContainerWrapper = this._el.querySelector<HTMLDivElement>('.grid-relative-container');
        const resizeObserver = new ResizeObserver(() => {
            $(gridContainerWrapper)
                .find('.flex-grid-nested-table')
                .each((index, element) => {
                    $(element).width(
                        $(gridContainerWrapper).width() - $(element).parents('.flex-grid-nested-table').length * 6 - 14
                    );
                });
        });
        resizeObserver.observe(gridContainerWrapper);
    }

    GetInlineControls() {
        var result = [];
        _.each(this._subControls(), (subControl) => {
            if (subControl.GetType() === CONTROL_TYPES.GenericButton || subControl.GetType() === CONTROL_TYPES.ButtonTemplate || subControl.GetType() === CONTROL_TYPES.ButtonPlan ) {
                var control = subControl as BaseControl;

                if(subControl.GetType() === CONTROL_TYPES.ButtonPlan){
                    result.push(subControl.Clone());
                }else{
                    let position = control.GeneralProperties.GetPropertyValue('Position');
                             
                    if (position && position.Value === 'Row') {
                        if(subControl.GetType() === CONTROL_TYPES.ButtonTemplate){
                            result.push(subControl);
                        }else{
                            result.push(subControl.Clone());
                        }                                
                    }
                }
            }
        });
        return result;
    }

    GetToolbarControls() {
        var result = [];
        _.each(this._subControls(), (subControl) => {
            if (subControl.GetType() === CONTROL_TYPES.GenericButton || subControl.GetType() === CONTROL_TYPES.ButtonTemplate) {
                var control = subControl as BaseControl;
                let position = control.GeneralProperties.GetPropertyValue('Position');       

                if (position && position.Value === 'Menu') {
                    if(subControl.GetType() === CONTROL_TYPES.ButtonTemplate){
                        result.push(subControl);
                    }else{
                        result.push(subControl.Clone());
                    }                                
                }
            }

            if (subControl.GetType() === CONTROL_TYPES.Basket || subControl.GetType() === CONTROL_TYPES.SpimCopy || subControl.GetType() === CONTROL_TYPES.ButtonCopy) {
                result.push(subControl);
            }
        });

        return result;
    }

    SetQuery(query: QueryExpressionModel) {
        this._queryExpression = query;
    }

    get BaseGrid(): BaseGrid {
        return this._baseGrid;
    }

    Dispose() {
        if (this._searchScreen) {
            this._searchScreen.Close();
        }
    }

    get HasData(): boolean {
        return this._hasData;
    }

    get Paginator(): Paginator {
        return this._toolbar.Paginator();
    }

    get TotalRecords(): number{
        return this._toolbar.Paginator().TotalRecords + this._baseGrid.NewRows; 
    }

    ShouldShowBottomPagination() {
        return this.ScreenType === ScreenTypes[ScreenTypes.ListScreen]
            || this.ScreenType === ScreenTypes[ScreenTypes.SpecialScreen]
            || this.ScreenType === ScreenTypes[ScreenTypes.Dashboard];
    }
}