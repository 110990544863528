import {OptionsGroup} from "./OptionsGroup";
import {LinkTable} from "./LinkTable";

export class ViewModel {
    Header: string;
    Groups: OptionsGroup[];
    LinkTable: LinkTable;

    GetSelectedOptions() {
        const selectedOptions = [];

        for (const group of this.Groups) {
            if (group.SelectedOption()) {
                selectedOptions.push(group.SelectedOption());
                continue;
            }

            selectedOptions.push(...group.Options
                .filter(option => option.IsSelected())
                .map(option => option.Id));
        }

        return selectedOptions;
    }
}