import * as _ from 'underscore';

import { QueryColumnModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryColumnModel';
import Template from 'Desktop/Toolbar/ToolbarQueryColumn/Templates/Template.html';
import 'pubsub';

export class ToolbarQueryColumn {

	private _model: QueryColumnModel;

	constructor(model: QueryColumnModel, private _isLinkColumn = false) {
		this._model = model;
	}

	get Name(): string {
		return this._model.Metadata.NameTranslation || this._model.Metadata.Name;
	}

	GetTemplate() {
		return Template;
	}

	AfterRender() { }

	PasteFieldCode(entityName: string, entityIndex: number, element: HTMLElement) {
		if (entityIndex > 1) {
			entityName = `${entityName}${entityIndex}`;
		}

		var translation = '';
		if (this._model.Metadata.NameTranslation) {
			translation = `(${this._model.Metadata.NameTranslation})`;
		};

		PubSub.publish('PASTE_FIELD_CODE', { EntityName: entityName, FieldName: `${this._model.Metadata.Name}${translation}`, Element: element });
	}

	get IsLinkColumn(): boolean {
		return this._isLinkColumn;
	}

	get Model(): QueryColumnModel {
		return this._model;
	}
}