import * as ko from "knockout";
import * as _ from 'underscore';
import {Modal} from "Core/Common/Modal";
import {Event} from "Core/Common/Event";
import {Timer, TIMER_EVENTS} from "Core/Common/Timer";
import {LABELS} from "Core/Components/Translation/Locales";

import ConfirmationDialogTemplate from 'Core/Components/Dialogs/ConfirmationDialog/Templates/View.html';

ko.templates["Core/Components/Dialogs/ConfirmationDialog/Templates/View"] = ConfirmationDialogTemplate;

const TEMPLATES = {
	VIEW: "Core/Components/Dialogs/ConfirmationDialog/Templates/View"
};

export const EVENTS = {
	CONFIRM_SELECTED: "ConfirmSelected",
	DISCARD_SELECTED: "DiscardSelected",
	SKIP_SELECTED: "SkipSelected",
	TIMER_EXPIRED: "TimerExpired"
};

export enum Types {
	Question,
	Warning,
	Error
}

export interface IConfirmationDialogOptions {
	Text: string;
	Type: Types;
	Width?: number;
	MinHeight?: number;
	TextConfirm?: string;
	TextDecline?: string;
	TextSkip?: string;
	Timeout?: number;
	ModalClass?: string;
}

export class ConfirmationDialog extends Event {
	private _modal: Modal;
	private _text: KnockoutObservable<string>;
	private _textConfirm: KnockoutObservable<string>;
	private _textDecline: KnockoutObservable<string>;
	private _textSkip: KnockoutObservable<string>;
	private _options: IConfirmationDialogOptions;
	private _timer: Timer;
	private _modalClass: string;
	private _declineClass: KnockoutComputed<string>;

	constructor(options: IConfirmationDialogOptions) {
		super();
		this._options = options;
		this._options.ModalClass += ' jBox-padding-5px';
		this.Init();

		if (options.Timeout > 0) {
			this.InitTimer(options.Timeout);
		}
	}

	get IsQuestion() {
		return this._options.Type === Types.Question;
	}

	get IsWarning() {
		return this._options.Type === Types.Warning;
	}

	get IsError() {
		return this._options.Type === Types.Error;
	}

	private get IsTimerOn() {
		return this._timer != null;
	}

	private Init() {
		this._text = ko.observable(this._options.Text);
		this._textConfirm = ko.observable(this._options.TextConfirm || LABELS.OK);
		this._textDecline = ko.observable(this._options.TextDecline || LABELS.CANCEL);
		this._textSkip = ko.observable(this._options.TextSkip || null);
		this._timer = null;
		this._modalClass = '';
		this._declineClass = ko.computed(() => _.isEqual(this._textDecline(), LABELS.CANCEL) ? "btn btn-cancel" : "btn btn-danger");
	}

	private InitModal() {
		this._modal = new Modal({
			width: this._options.Width || '405',
			minHeight: this._options.MinHeight || '200',
			closeButton: false,
			closeOnEsc: false,
			addClass: this._options.ModalClass === undefined ? '' : this._options.ModalClass + "",
			appendTo: 'body:first'
		}, false);
	}

	AddTimer(seconds: number) {
		this.InitTimer(seconds);
	}

	Show() {
		this.InitModal();
		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);
		if (this.IsTimerOn) {
			this._timer.Start();
		}
	}

	GetTemplateName() {
		return TEMPLATES.VIEW;
	}

	OnOkClick() {
		this.Close();
		this.Trigger(EVENTS.CONFIRM_SELECTED);
	}

	OnCancelClick() {
		this.Close();
		this.Trigger(EVENTS.DISCARD_SELECTED);
	}

	OnSkipClick(){
		this.Close();
		this.Trigger(EVENTS.SKIP_SELECTED);
	}

	Close() {
		this._modal.Close();
		if (this._timer) {
			this._timer.Stop();
		}
	}

	private AfterRender() {
		this._modal.Show();
	}

	private InitTimer(interval: number) {
		this._timer = new Timer(interval);
		this._timer.On(TIMER_EVENTS.TIMER_EXPIRED, this, () => this.Trigger(EVENTS.TIMER_EXPIRED));
	}
}