import {P} from "Core/Common/Promise";
import {Request} from 'Core/Common/Request';

export class ProductConfiguratorStore {
    constructor(private _orderEntityId: number, private _productEntityId: number) {
    }

    CheckProductConfiguratorRelationType(){
        const deferred = P.defer();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/CheckProductConfiguratorRelationType',
                type: 'POST'
            }, params: {
                OrderEntityId: this._orderEntityId,
                ProductEntityId: this._productEntityId
            }
        })
        .then(() => deferred.resolve(null))
        .fail(err => deferred.reject(err));

        return deferred.promise();
    }

    GetOriginalImage(recordId: number): P.Promise<string> {
	    const deferred = P.defer<string>();

	    Request.Send({
			    proxy: {
				    url: '/api/ProductConfiguratorApi/GetOriginalImage',
				    type: 'POST'
			    }, params: {
				    OrderEntityId: this._orderEntityId,
					ProductEntityId: this._productEntityId,
					ProductId: recordId
			    }
		    })
		    .then((image) => deferred.resolve(image))
			.fail(err => deferred.reject(this.GetResponseError(err, 'Error getting original image')));

	    return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}