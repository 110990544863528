import Template from "Pages/AdministrationPage/AdministrationPageTemplate.html";
import { SpaceStatus } from "./Tabs/SpaceStatus";
import { ServiceStatus } from "./Tabs/ServiceStatus";
import { Environment } from "./Tabs/Environment";
ko.templates["Pages/AdministrationPage/AdministrationPageTemplate"] = Template;


export class AdministrationPage {

	private _isReady: KnockoutObservable<boolean>;
	private _spaceStatusTab: KnockoutObservable<SpaceStatus>;
	private _serviceStatusTab: KnockoutObservable<ServiceStatus>;
	private _systemsTab: KnockoutObservable<Environment>;

    constructor(){
        this._isReady = ko.observable(true);
		this._spaceStatusTab = ko.observable(new SpaceStatus());
		this._serviceStatusTab = ko.observable();
		this._systemsTab = ko.observable();
    }

    Render(target): void {
		ko.cleanNode(document.getElementById(target));
		ko.applyBindings(this, document.getElementById(target));
	}

    GetTemplateName(): string {
		return 'Pages/AdministrationPage/AdministrationPageTemplate';
	}

	ShowSpaceStatus(){
		this.ResetTabs();
		this._spaceStatusTab(new SpaceStatus());
	}

	ShowServiceStatus(){
		this.ResetTabs();
		this._serviceStatusTab(new ServiceStatus());
	}

	ShowSystemsStatus(){
		this.ResetTabs();
		this._systemsTab(new Environment());
	}

	ResetTabs(){
		this._spaceStatusTab(null);
		this._serviceStatusTab(null);
		this._systemsTab(null);
	}

	AfterRender(){}
}