import * as ko from 'knockout';

import {TranslationManager} from "Core/Components/Translation/TranslationManager";
import {TranslationFieldEditor} from "Core/Components/TranslationFieldEditor/TranslationFieldEditor";
import {ITranslationValue} from "Core/Components/TranslationFieldEditor/ITranslationValue";

import {IValueUpdater} from "LookupEditor/Interfaces/IValueUpdater";
import {EditableColumnModel} from "LookupEditor/Models/EditableColumnModel";
import {ColumnEditor} from "LookupEditor/ColumnEditors/Base/ColumnEditor";

import Template from "LookupEditor/Templates/Columns/Text.html";

ko.templates["LookupEditor/Templates/Columns/Text"] = Template;

export class TextColumnEditor extends ColumnEditor {
	private _currentOwner: KnockoutObservable<IValueUpdater>;
	private _editModeEnabled: KnockoutComputed<boolean>;
	private _value: KnockoutObservable<string>;
	private _translationFieldEditor: TranslationFieldEditor;

	get EditModeEnabled() {
		return this._editModeEnabled;
	}

	get CurrentOwner() {
		return this._currentOwner;
	}

	get Value() {
		return this._value;
	}

	constructor(column: EditableColumnModel) {
		super(column);

		this._currentOwner = ko.observable(null);
		this._editModeEnabled = ko.computed(() => this._currentOwner() !== null, this);
		this._value = ko.observable(null);

		this._value.subscribe(newValue => this._translationFieldEditor.SetValue(newValue));
	}

	ToString() {
		return "Text";
	}

	GetTemplateName() {
		return "LookupEditor/Templates/Columns/Text";
	}

	AfterRender(el, owner: IValueUpdater, columnName): void {
	}

	Click(owner: IValueUpdater) {
		const columnData = owner.GetColumnData(this.column.Name);
		const value = owner.GetDbValue(this.column.Name);
		const translation = TranslationManager.Instance.GetTranslation(value, columnData.FieldValueTranslations);

		this._translationFieldEditor = new TranslationFieldEditor();
		this._translationFieldEditor.LoadTranslationItems();
		this._translationFieldEditor.On('TranslationSelected', this, eventArgs => this.ChangeTranslation(eventArgs.data));

		this._translationFieldEditor.SetTranslations(columnData.FieldValueTranslations, value);
		this._translationFieldEditor.SetActiveTranslation(translation.Language.Id);

		this._currentOwner(owner);
		this._value(translation.TranslatedValue || translation.Value);
	}

	ToggleTranslations() {
		this._translationFieldEditor.Toggle();
	}

	SaveChanges() {
		this._currentOwner().UpdateValue({
			Name: this.column.Name,
			Value: this._translationFieldEditor.GetDefaultTranslation().Value,
			Translations: this._translationFieldEditor.GetTranslations(false)
		});

		this._currentOwner(null);
	}

	CancelChanges() {
		this._currentOwner(null);
	}

	Show(): void {
	}

	private ChangeTranslation(translation: ITranslationValue) {
		this._value(translation.Value);
	}
}
