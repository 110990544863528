import {deserialize} from 'libs/cerialize';

export class ActionCheckListItemDto {
    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    NameTranslation: string;

    @deserialize
    TypeName: string;

    @deserialize
    Value: any;

    @deserialize
    Required: boolean;

    @deserialize
    ValField: number;
}