import * as _ from 'underscore';

import {EventBusConsumer} from "Core/Common/EventBus/EventBusConsumer";

import {ConfigurationPageVariable} from "./Models/ConfigurationPageVariable";
import {ConfigurationPageEvents} from "../Events/ConfigurationPageEvents";

export class ConfigurationPageVariablesStore extends EventBusConsumer {
    private _variables: ConfigurationPageVariable[];

    constructor() {
        super();
        this._variables = [];
    }

    Declare(name: string, value: any) {
        const existingVariableIndex = _.findIndex(this._variables, v => v.Name === name);

        if (existingVariableIndex > -1) {
            this._variables.splice(existingVariableIndex, 1);
        }

        this._variables.push(new ConfigurationPageVariable(name, value))
    }

    Get(name: string) {
        return _.find(this._variables, v => v.Name === name);
    }

    GetList() {
        return this._variables;
    }

    Set(name: string, value: any) {
        const variable = _.find(this._variables, v => v.Name === name);

        if (!variable) {
            console.error(`Variable ${name} not declared`);
            return this;
        }

        variable.Value = value;
        this.DispatchEvent(ConfigurationPageEvents.VariablesChanged, variable);
        return this;
    }
}