import * as ko from 'knockout';
import { JoinTypes, UseMainCondition, LinkDirectionCondition } from 'QueryBuilder/Enums';
import { Event } from 'Core/Common/Event'
import { EVENTS } from 'QueryBuilder/QueryEntityJoin/JoinOptionsMenu/Events';
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";
import Template from 'QueryBuilder/QueryEntityJoin/JoinOptionsMenu/Templates/JoinOptionsMenu.html';

export interface IJointTypeOption {
	Name: string;
	Value: JoinTypes;
}

export interface IUseMainOption {
	Name: string;
	Value: UseMainCondition;
}

export interface ILinkDirectionOption {
	Name: string;
	Value: LinkDirectionCondition;
}

export class JoinOptionsMenu extends Event {
	private _jointTypeOptions: Array<IJointTypeOption>;
	private _useMainOptions: Array<IUseMainOption>;
	private _linkDirectionOptions: Array<ILinkDirectionOption>;
	private _joinTypeValue: KnockoutObservable<JoinTypes>;
	private _useMainValue: KnockoutObservable<UseMainCondition>;
	private _enableUseMain: KnockoutObservable<boolean>;
	private _linkDirectionValue: KnockoutObservable<LinkDirectionCondition>;
	private _enableLinkDirection: KnockoutObservable<boolean>;
	private _labels = LABELS;

	constructor(joinType: JoinTypes, useMain: UseMainCondition = null, enableUseMain = false, linkDirection: LinkDirectionCondition = null,  enableLinkDirection = false) {
		super();
		this.AddEvent(EVENTS.JOIN_TYPE_CHANGED);
		this.AddEvent(EVENTS.USE_MAIN_CHANGED);
		this.AddEvent(EVENTS.LINK_DIRECTION_CHANGED);
		this._enableUseMain = ko.observable(enableUseMain);
		this._enableLinkDirection = ko.observable(enableLinkDirection);

		this._jointTypeOptions = [
			{ Name: LABELS.NORMAL, Value: JoinTypes.InnerJoin },
			{ Name: LABELS.EXCLUSIVE, Value: JoinTypes.LeftJoin },
			{ Name: LABELS.INCLUSIVE, Value: JoinTypes.OuterJoin }
		];

		this._useMainOptions = [
			{ Name: LABELS.MAIN_ONLY, Value: UseMainCondition.MainTrue },
			{ Name: LABELS.EXCLUDE_MAIN, Value: UseMainCondition.MainFalse },
			{ Name: LABELS.ALL, Value: UseMainCondition.NotUseMain }
		];

		this._linkDirectionOptions = [
			{ Name: LABELS.LEFT_TO_RIGHT, Value: LinkDirectionCondition.LeftToRight },
			{ Name: LABELS.RIGHT_TO_LEFT, Value: LinkDirectionCondition.RightToLeft },
			{ Name: LABELS.BOTH, Value: LinkDirectionCondition.Both }
		];

		this._joinTypeValue = ko.observable(joinType);
		this._useMainValue = ko.observable(useMain);
		this._linkDirectionValue = ko.observable(linkDirection);
	}

	get JoinTypeValue(): KnockoutObservable<JoinTypes> {
		return this._joinTypeValue;
	}

	get UseMainValue(): KnockoutObservable<UseMainCondition> {
		return this._useMainValue;
	}

	get LinkDirectionValue(): KnockoutObservable<LinkDirectionCondition> {
		return this._linkDirectionValue;
	}

	get EnableUseMain(): KnockoutObservable<boolean> {
		return this._enableUseMain;
	}

	get EnableLinkDirection(): KnockoutObservable<boolean> {
		return this._enableLinkDirection;
	}

	ChangeJoinType(joinValue: IJointTypeOption) {
		this.Trigger(EVENTS.JOIN_TYPE_CHANGED, { JoinType: joinValue.Value });
	}

	ChangeUseMain(useMain: IUseMainOption) {
		this.Trigger(EVENTS.USE_MAIN_CHANGED, { UseMain: useMain.Value });
	}

	ChangeLinkDirection(linkDirection: ILinkDirectionOption) {
		this.Trigger(EVENTS.LINK_DIRECTION_CHANGED, { LinkDirection: linkDirection.Value });
	}

	GetTemplateHtml() {
		return Template;
	}

	AfterRender() { }

	MouseLeave(element: HTMLElement) {
		$(element).parent().removeClass('open');
	}
}