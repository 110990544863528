import * as ko from "knockout"
import 'jquery';

export class SubformResizeExtention {
	static Init() {
		ko.bindingHandlers.subformResize = {
			init: (element, valueAccessor) => {
				var subForms = $(element).find('.mainSubForm');
				var subformFirst = $(subForms.eq(0));
				var subformSecond = $(subForms.eq(1));
				var subformThird = $(subForms.eq(2));
				var subformsParent = $(subForms).find('.row.form-wrapper');
				var adaptiveBlockClassName = 'adaptive-block';
				var bodyTag = 'body';
				var closedMenuClassName = 'page-sidebar-closed';

				var subformFirstHeight = $(subformFirst).height();
				var subformSecondHeight = $(subformSecond).height();
				var subformThirdHeight = $(subformThird).height();

				var smallerBlock = 0;

				if ($(window).width() < 992 && $(window).width() > 768) {
					if (!$(subformThird).hasClass(adaptiveBlockClassName)) {
						$(subformThird).addClass(adaptiveBlockClassName);
					}

					if (subformFirstHeight > subformSecondHeight) {
						smallerBlock = subformSecondHeight;
						$(subformThird).css('right', '0');
					} else {
						smallerBlock = subformFirstHeight;
					}

					$(subformsParent).css('min-height', smallerBlock + subformThirdHeight + 30);
					$(subformThird).css('top', smallerBlock + 15);
				}

				$(window).on('resize', function () {
					if (($(window).width() < 992 && $(window).width() > 768) || (!$(bodyTag).hasClass(closedMenuClassName) && $(window).width() < 1300 && $(window).width() > 768)) {
						if (!$(subformThird).hasClass(adaptiveBlockClassName)) {
							$(subformThird).addClass(adaptiveBlockClassName);
						}

						if (subformFirstHeight > subformSecondHeight) {
							smallerBlock = subformSecondHeight;
							$(subformThird).css('right', '0');
						} else {
							smallerBlock = subformFirstHeight;
						}

						$(subformsParent).css('min-height', smallerBlock + subformThirdHeight + 30);
						$(subformThird).css('top', smallerBlock + 15);
					} else {
						$(subformThird).removeClass(adaptiveBlockClassName);
						$(subformThird).css({
							'top': '',
							'right': ''
						});
						$(subformsParent).css('min-height', '');
					}
				});
			}
		};
	}
};