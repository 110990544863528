import {Request, IRequestOptions} from "Core/Common/Request";
import {P} from "Core/Common/Promise";
import {URL} from "Core/Common/Url";

import {GenericDeserialize} from "libs/cerialize";

import {IGetRadioButtonControlDataRequestModel} from "Core/Controls/RadioButton/Models/Request/IGetRadioButtonControlDataRequestModel";
import {RadioButtonOptionsModel} from "Core/Controls/RadioButton/Models/Data/RadioButtonOptionsModel";


export class RadioButtonDataStore {
    static GetOptions(params: IGetRadioButtonControlDataRequestModel): P.Promise<any> {
        let deferredResult = P.defer();

        let requestParams: IRequestOptions = {
            proxy: {
                url: '/api/ScreenFormApi/GetRadioData',
                dataType: 'json',
                type: 'POST'
            },
            params
        };

        Request.Send(requestParams)
            .then(data => {
                if (data.IsSuccessfull) {
                    deferredResult.resolve(this.Deserialize(data.ResultObject));
                } else {
                    deferredResult.reject({message: data.ErrorMessage});
                }
            });

        return deferredResult.promise();
    }

    static Deserialize(jsonObject: any): RadioButtonOptionsModel {
        var model = GenericDeserialize<RadioButtonOptionsModel>(jsonObject, RadioButtonOptionsModel);
        return model;
    }
}