import {FieldResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Common/Response/FieldResponseModel";
import {LookupField} from "Core/Components/Controls/TimeWriting/Models/View/DayDataViewModel";
import {LookupValueMappings} from "./LookupValueMappings";

export class LookupFieldMappings {
    static OnViewModel(field: FieldResponseModel): LookupField {
        const lookupField = new LookupField();

        lookupField.Id = field.Id;
        lookupField.Name = field.Name;
        lookupField.RequiresSearch = field.RequiresSearch;
        lookupField.Values = field.Values.map(value => LookupValueMappings.OnViewModel(value));
        lookupField.ValFieldTypeName = field.ValFieldTypeName;
        lookupField.ValFieldFormatName = field.ValFieldFormatName;
        lookupField.ValFieldSize = field.ValFieldSize;

        return lookupField;
    }
}