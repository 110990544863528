import * as _ from 'underscore'
import {RecipeRecordModel} from "../Response/RecipeRecordModel";
import {RecipeNodeViewModel} from "../Models/View/RecipeNodeViewModel";
import {ITypePropertyModel} from "Core/GeneralProperties/Managers/TypesProperty/TypesProperty";


export class RecipeEditorTreeNodeViewModelMapping {
    static MapFromResponse(record: RecipeRecordModel, typesProperty: ITypePropertyModel[]) {
        if (!record) {
            return null;
        }
        const nodeViewModel = new RecipeNodeViewModel();
        nodeViewModel.Id = record.Id;
        nodeViewModel.Name = record.Name || "-";
        nodeViewModel.NameTranslation = record.NameTranslation;
        nodeViewModel.TypeId = record.TypeId;
        nodeViewModel.TypeName = record.TypeName;
        nodeViewModel.TypeNameTranslation = record.TypeNameTranslation;
        nodeViewModel.LifeStatusId = record.LifeStatusId;
        nodeViewModel.LifeStatusName = record.LifeStatusName;
        nodeViewModel.LifeStatusGroup = record.LifeStatusGroup;
        nodeViewModel.IsLocked = record.IsLocked;
        nodeViewModel.HasChildren = record.HasChildren;
        nodeViewModel.TypeColor = record.TypeColor;

        if (!!typesProperty && !!typesProperty.length){
            _.each(typesProperty, (type:ITypePropertyModel)=>{
                if (type.TypeId === record.TypeId){
                    nodeViewModel.TypeColor = type.TypeColor
                }
            })
        }

        const children = record.Children.map(childRecord => this.MapFromResponse(childRecord, typesProperty));
        nodeViewModel.AddRange(children);

        return nodeViewModel;
    }


    static Copy(record: RecipeNodeViewModel) {
        const nodeViewModel = new RecipeNodeViewModel();

        nodeViewModel.Id = record.Id;
        nodeViewModel.Name = record.Name;
        nodeViewModel.NameTranslation = record.NameTranslation;
        nodeViewModel.TypeId = record.TypeId;
        nodeViewModel.TypeName = record.TypeName;
        nodeViewModel.TypeNameTranslation = record.TypeNameTranslation;
        nodeViewModel.LifeStatusId = record.LifeStatusId;
        nodeViewModel.LifeStatusName = record.LifeStatusName;
        nodeViewModel.LifeStatusGroup = record.LifeStatusGroup;
        nodeViewModel.IsInitialNode = record.IsInitialNode;
        nodeViewModel.IsLocked = record.IsLocked;
        nodeViewModel.TypeColor = record.TypeColor;

        const children = record.Children.map(childRecord => this.Copy(childRecord));
        nodeViewModel.AddRange(children);

        return nodeViewModel;
    }
}