import * as ko from "knockout";
import { Guid } from "Core/Common/Guid";
import { LABELS } from "Core/Components/Translation/Locales";
import {LifeStatusGroupEnum, LifeStatusGroups} from "../../../../../Common/Enums/LifeStatusGroups";

import ItemNameTemplate from "Core/Controls/RecipeEditor/RecipeEditorTree/Templates/ItemNameTemplate.html";
import {RecipeEditorTreeNodeViewModelMapping} from "../../Mappings/RecipeEditorTreeNodeViewModelMapping";
ko.templates["Core/Controls/RecipeEditor/RecipeEditorTree/Templates/ItemNameTemplate"] = ItemNameTemplate;

export class RecipeNodeViewModel {
    Guid: string;
    Id: number;
    Name: string;
    NameTranslation: string;
    TypeId: number;
    TypeName: string;
    TypeNameTranslation: string;
    LifeStatusId: number;
    LifeStatusName: string;
    LifeStatusGroup: number;
    IsInitialNode: boolean;
    IsLocked: boolean;
    HasChildren: boolean;
    OutlineColor?: string;
    TypeColor?: string;
    LockedTooltip?: string;


    private _children: RecipeNodeViewModel[];
    private _labels = LABELS;

    constructor() {
        this.Guid = Guid.NewGuid();
        this._children = [];
        if (!this.OutlineColor){
            this.OutlineColor = null;
        }
        if (!this.LockedTooltip){
            this.LockedTooltip = null;
        }
    }

    get Children() {
        return this._children;
    }

    GetOutlineColor() {
        return this.TypeColor ? `1px solid ${this.TypeColor}` : null;
    }

    GetTypeColor() {
        return this.TypeColor ? this.TypeColor : null;
    }

    GetNameTranslation() {
        return this.NameTranslation ? this.NameTranslation : this.Name;
    }

    GetTypeName() {
        return this.TypeNameTranslation ? this.TypeNameTranslation : this.TypeName ? this.TypeName : '-';
    }

    Copy() {
        return RecipeEditorTreeNodeViewModelMapping.Copy(this);
    }

    GetTemplateName() {
        return "Core/Controls/RecipeEditor/RecipeEditorTree/Templates/ItemNameTemplate";
    }

    AfterRender() {

    }

    Add(node: RecipeNodeViewModel) {
        this._children.push(node);
    }

    AddRange(nodes: RecipeNodeViewModel[]) {
        nodes.forEach(this.Add.bind(this));
    }

    GetNode(id: string, callback: (matchedNode: RecipeNodeViewModel) => void) {
        if (this.Guid === id) {
            callback(this);
        }

        this._children.forEach(child => child.GetNode(id, callback));
    }

    GetNodeByRecordId(id: number, callback: (matchedNode: RecipeNodeViewModel) => void) {
        if (this.Id === id) {
            callback(this);
        }

        this._children.forEach(child => child.GetNodeByRecordId(id, callback));
    }

    SetInitialNode(initialNodeId: number) {
        this.IsInitialNode = this.Id === initialNodeId;
        this._children.forEach(childRecord => childRecord.SetInitialNode(initialNodeId));
    }

    IsRetired() {
        return this.LifeStatusName === 'Disabled' ||
            (this.LifeStatusGroup && LifeStatusGroups.GetLifeStatusGroup(this.LifeStatusGroup) === LifeStatusGroupEnum.Retired);
    }


    ToJson() {
        const nestedNodes = this._children.map(child => child.ToJson());
        let children: object[] | boolean;

        // if (this.IsSubject || nestedNodes.length > 0) {
        if (nestedNodes.length > 0) {
            children = nestedNodes;
        } else {
            children = this.HasChildren;
        }

        return {
            id: this.Guid,
            text: (ko as any).renderTemplateXHtml(ItemNameTemplate,
                {
                    TypeName: this.GetTypeName(),
                    Name: this.GetNameTranslation(),
                    Tooltip: this._labels.RECORD_DISABLED,
                    IsRetired: this.IsRetired(),
                    IsInitialNode: this.IsInitialNode,
                    IsLocked: this.IsLocked,
                    LockedTooltip: this._labels.LOCKED_THE_ROOT_NODE_IS_LINKED_TO_THE_LOCK_ENTITY,
                    OutlineColor: this.GetOutlineColor(),
                    TypeColor: this.GetTypeColor(),
                    Guid: this.Guid
                }),
            children: children,
            record: this
        };
    }
}