import * as ko from 'knockout';
import {BlockUI} from 'Core/Common/BlockUi';

import {HelpBookStore} from "HelpBook/Store/HelpBookStore";
import {Notifier} from 'Core/Common/Notifier';
import {HelpDescription} from "HelpBook/HelpDescription/HelpDescription";
import {HelpStatusFlow} from "HelpBook/HelpStatusFlow/HelpStatusFlow";

import {HelpBook} from 'HelpBook/HelpBook';

import HelpEntityTemplate from 'HelpBook/HelpEntity/Templates/HelpEntity.html';
import {LABELS} from "../../Core/Components/Translation/Locales";

ko.templates['HelpBook/HelpEntity/Templates/HelpEntity'] = HelpEntityTemplate;

export class HelpEntity {
    private selectedEntityName: KnockoutObservable<string>;
    private selectedEntityTranslatedName: KnockoutObservable<string>;
    private selectedEntityDescription: KnockoutObservable<string>;
    private selectedEntityHasData: KnockoutObservable<boolean>;
    private selectedEntityHasNoData: KnockoutObservable<boolean>;
    public selectedEntityTypes: KnockoutObservableArray<any>;
    public selectedEntityTypesList: KnockoutObservableArray<any>;
    public selectedEntityId: KnockoutObservable<number>;
    private HelpDescription: KnockoutObservable<HelpDescription>;

    private Icon: string;
    private IconId: number;
    private IconImage: string;
    private IconType: string;

    private _labels = LABELS;

    constructor() {
        this.selectedEntityTypes = ko.observableArray([]);
        this.selectedEntityTypesList = ko.observableArray([]);
        this.selectedEntityName = ko.observable(null);
        this.selectedEntityTranslatedName = ko.observable(null);
        this.selectedEntityDescription = ko.observable(null);

        this.selectedEntityHasData = ko.observable(false);
        this.selectedEntityHasNoData = ko.observable(false);
        this.HelpDescription = ko.observable(null);
    }

    FetchData(entity) {
        return new Promise((res, rej) => {
            BlockUI.Block();
            this.Icon = entity.Icon;
            this.IconId = entity.IconId;
            this.IconImage = entity.IconImage;
            this.IconType = entity.IconType;

            HelpBookStore.GetEntityInfo({id: entity.Id}).always(() => {
                BlockUI.Unblock();
            }).then(result => {
                if (!result.IsSuccessfull) {
                    new Notifier().Failed(result.ErrorMessage);
                    rej();
                    return;
                }
                this.selectedEntityHasData(result.HasData);
                this.selectedEntityHasNoData(!result.HasData);
                if (result.HasData) {
                    this.selectedEntityName(result.ResultObject.Name);
                    this.selectedEntityTranslatedName(result.ResultObject.TranslatedName);
                    this.selectedEntityDescription(result.ResultObject.Description);
                    this.HelpDescription(new HelpDescription(this.selectedEntityDescription()));
                    this.HelpDescription().ActivateDescription();

                    this.selectedEntityTypes(result.ResultObject.Types.map((type) => {
                        type.noDescription = ko.observable('');
                        type.previewText = ko.observable('');
                        type.showFullDescription = ko.observable(false);
                        return _.extend({}, type, {
                            isActive: ko.observable(false),
							selectedEntityId: result.ResultObject.Id,
                            Click: (type) => {
                                HelpBook.Instance.GetScreens(type);
                                type.HelpDescription.ActivateDescription();
							},
							HelpDescription: new HelpDescription(type.Description),
                            HelpStatusFlow: new HelpStatusFlow(type.Id)
                        });
                    }));
                }
                res(null);
            });
        });
    }

    GetTemplateName(): string {
        return 'HelpBook/HelpEntity/Templates/HelpEntity';
    }

    _selectedEntityId() {
        return this.selectedEntityId();
    }


}