import {deserialize, deserializeAs, GenericDeserialize} from 'libs/cerialize';
import {GridDataModel} from 'Core/Controls/Grid/Models/GridDataModel/GridDataModel';
import {GridAverageCellValueModel} from 'Core/Controls/Grid/Models/GridDataModel/GridAverageCellValueModel';

export class GridAverageRowModel {

	@deserializeAs(GridAverageCellValueModel)
	Data: Array<GridAverageCellValueModel>;

	@deserialize
	Guid: string;

	constructor() {
		this.Data = [];
	}
}