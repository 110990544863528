import * as ko from 'knockout';
import * as _ from "underscore";
import * as moment from "moment";

const TimeRegex = /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/; //hh:mm:ss

export class LiveTimer {
    private _timerIntervalId: number | null = null;
    private _pausedDuration: number = 0;
    private _initTimer: KnockoutObservable<boolean>;
    private _formattedDuration: KnockoutObservable<string>

    public CurrentDuration: KnockoutObservable<number>;

    // Test: KnockoutComputed<string>;

    constructor(currentDuration: string | null = null) {
        this._initTimer = ko.observable(!!currentDuration);
        const totalSeconds = currentDuration ? this.GetDurationInSeconds(currentDuration) : 0;
        this.CurrentDuration = ko.observable(totalSeconds);
        this._formattedDuration = ko.observable(null);

        this._formattedDuration.subscribe((newValue) => {
        });
    }

    get InitTimer(): boolean {
        return this._initTimer();
    }

    HasValidDuration(duration: string): boolean {
        return TimeRegex.test(duration);
    }

    StartTimer() {
        if (this._timerIntervalId) {
            clearInterval(this._timerIntervalId);
            this._timerIntervalId = null;
        }

        this._timerIntervalId = setInterval(() => {
            this.CurrentDuration(this.CurrentDuration() + 1);
            this._formattedDuration(this.FormattedDuration(this.CurrentDuration()));
        }, 1000);
    }

    PauseTimer() {
        if (this._timerIntervalId) {
            clearInterval(this._timerIntervalId);
            this._timerIntervalId = null;
            this._pausedDuration = this.CurrentDuration();
        }
    }

    StopTimer() {
        if (this._timerIntervalId) {
            clearInterval(this._timerIntervalId);
            this._timerIntervalId = null;
        }

        this._pausedDuration = 0;
        this.CurrentDuration(0);
        this._formattedDuration('00:00:00');
        this._initTimer(false);
    }

    UpdateCurrentDuration(currentDuration: string = '00:00:00') {
        this._initTimer(true);
        const totalSeconds = this.GetDurationInSeconds(currentDuration);
        this.CurrentDuration(totalSeconds);
    }

    private GetDurationInSeconds(duration: string): number {
        const [hours, minutes, seconds] = duration.split(':');
        return (+hours * 3600) + (+minutes * 60) + (+seconds);
    }

    private TimerIsPaused() {
        return this._timerIntervalId === null;
    }

    private FormattedDuration(currentDuration?: number): string {
        const currDuration = this.TimerIsPaused() ? this._pausedDuration : currentDuration;
        const hours = Math.floor(currDuration / 3600);
        const minutes = Math.floor((currDuration % 3600) / 60);
        const seconds = currDuration % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
}