import {Event} from "Core/Common/Event";

export class PulseDateTracker extends Event {
	private _pulseDate: Date;
	private _trackInterval: number;

	private _isActive: boolean;

	Start() {
		if (!this._isActive) {
			this._trackInterval = setInterval(() => {
				const pulseDateExpired = this._pulseDate <= new Date();
				if (pulseDateExpired) {
					this.Stop();
					this.Trigger(PULSE_TRACKER_EVENTS.PULSE_DATE_EXPIRED);
				}
			}, 1000);

			this._isActive = true;
		}
	}

	Stop() {
		if(this._isActive) {
			clearInterval(this._trackInterval);
			this._isActive = false;
		}
	}

	SetPulseDate(pulseDate: Date) {
		this._pulseDate = pulseDate;
	}
}

export const PULSE_TRACKER_EVENTS = {
	PULSE_DATE_EXPIRED: "PulseDateExpired"
};