import * as ko from 'knockout';
import * as _ from 'underscore';

import {Notifier} from "Core/Common/Notifier";

import {RenderModes} from "Core/Constant";

import {BaseControl} from "Core/Controls/BaseControl/BaseControl";
import {IControlParam} from "Core/Screens/IScreen";

import {IFieldSecuritySettings} from "Core/Controls/FieldSecurity/Shared/Interfaces/IFieldSecuritySettings";

import {ViewModes} from "Core/Controls/FieldSecurity/Shared/Enums/ViewModes";
import {DataRole, DataRoles} from "Core/Controls/FieldSecurity/Shared/Enums/DataRoles";

import {DataRoleModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/DataRoleModel";

import {FieldSecurityStore} from "Core/Controls/FieldSecurity/Stores/FieldSecurityStore";
import {FieldSecurityMappings} from "Core/Controls/FieldSecurity/Mappings/FieldSecurityMappings";

import {HeaderComponent} from "Core/Controls/FieldSecurity/Components/Header/HeaderComponent";
import {DataRoleComponent} from "Core/Controls/FieldSecurity/Components/DataRole/DataRoleComponent";

import {GetDataRolesResponseModel} from "Core/Controls/FieldSecurity/Shared/Models/DTO/Get/GetDataRolesResponseModel";
import {FieldSecurityChangesModel} from "Core/Controls/FieldSecurity/Shared/Models/State/FieldSecurityChangesModel";
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";

import { NewThirdPartyDataRoleModel } from "Core/Controls/FieldSecurity/Shared/Models/Business/NewThirdPartyDataRoleModel";
import { AssigneeModel } from "Core/Controls/FieldSecurity/Shared/Models/Business/AssigneeModel";

import ViewTemplate from 'Core/Controls/FieldSecurity/Templates/View.html';
import EditTemplate from 'Core/Controls/FieldSecurity/Templates/Edit.html';
import ToolBarTemplate from 'Core/Controls/FieldSecurity/Templates/ToolBar.html';
import DesignTemplate from 'Core/Controls/FieldSecurity/Templates/Design.html';

ko.templates['Core/Controls/FieldSecurity/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/FieldSecurity/Templates/Edit'] = EditTemplate;
ko.templates['Core/Controls/FieldSecurity/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/FieldSecurity/Templates/Design'] = DesignTemplate;

export class FieldSecurity extends BaseControl {
    private _params: IControlParam;

    private _changes: FieldSecurityChangesModel;

    private _header: HeaderComponent;
    private _dataRoles: DataRoleComponent[];


    constructor(params: IControlParam) {
        super(params);

        this._params = params;
        this._changes = new FieldSecurityChangesModel();

        this._header = this.CreateHeaderComponent();
        this._dataRoles = this.CreateDataRoleComponents();
       
        this._dataRoles.forEach(dataRoleComponent => {
            dataRoleComponent.On('DataRoleAdding', this, () => this.ChangeRolesManipulations(false));
            dataRoleComponent.On('AddingCancelled', this, () => this.ChangeRolesManipulations(true));
            dataRoleComponent.On('DataRoleAdded', this, eventArgs => this.AddNewDataRole(dataRoleComponent, eventArgs.data.DataRole));
            dataRoleComponent.On('DataRoleRemoved', this, eventArgs => this.RemoveDataRole(eventArgs.data.DataRole));

            dataRoleComponent.On('UsersLoaded', this, eventArgs => this.OnUsersLoad(eventArgs.data.DataRole, eventArgs.data.Model, eventArgs.data.Users));
        });
    }

	ApplyProperties() {	}

    AfterRender(el) {
        super.AfterRender(el);

        if (this.GetForm().GetScreen().GetDataModel() && _.contains(this.GetOperationalModes(), this._params.RenderMode)) {
            this.RequestDataRoles();
        }
    }

    Deserialize() {
        return FieldSecurityMappings.MapToPostModel(this._changes);
    }

    private GetOperationalModes() {
        return [RenderModes.View, RenderModes.Edit];
    }

    private GetInitModes() {
        return this.GetOperationalModes().concat(RenderModes.Design);
    }

    private GetSettings(): IFieldSecuritySettings {
        return {
            ViewMode: this.GetViewMode(this._params.RenderMode)
        }
    }

    private GetViewMode(renderMode: RenderModes) {
        const renderViewModeMapping = {};

        renderViewModeMapping[RenderModes.View] = ViewModes.View;
        renderViewModeMapping[RenderModes.Edit] = ViewModes.Edit;
        renderViewModeMapping[RenderModes.Design] = ViewModes.Design;

        return renderViewModeMapping[renderMode];
    }

    private RequestDataRoles() {
        FieldSecurityStore.GetDataRoles(this.GetForm().GetScreen().GetRecordId())
            .then(response => this.DataRolesLoaded(response))
            .fail(error => new Notifier().Failed(error.message));
    }

    private CreateHeaderComponent(): HeaderComponent {
        if (_.contains(this.GetInitModes(), this._params.RenderMode)) {
            return new HeaderComponent(this.GetSettings());
        }

        return null;
    }

    private CreateDataRoleComponents(): DataRoleComponent[] {
        if (_.contains(this.GetInitModes(), this._params.RenderMode)) {
            const dataRoles = DataRoles.Instance.GetAllDataRoles();
            return dataRoles.map(dataRole => new DataRoleComponent(this.GetSettings(), dataRole));
        }

        return [];
    }

    private DataRolesLoaded(response: GetDataRolesResponseModel) {
        const userDataRoles = response.ResultList.map(dataRole => FieldSecurityMappings.MapToDataRoles(dataRole));
        this._changes.StartRoles = userDataRoles;

        this._dataRoles.forEach(dataRoleComponent => {
            const particularDataRole = userDataRoles.filter(userDataRole => DataRoles.Instance[userDataRole.Name] === dataRoleComponent.DataRole);
            const fieldCollections = particularDataRole.map(userDataRole => userDataRole.FieldCollection);
            dataRoleComponent.AddCollections(fieldCollections);
        });
    }

    private OnUsersLoad(dataRole: DataRole, model: NewThirdPartyDataRoleModel, users: AssigneeModel[]) {
        const newAssigneesIds = _.chain(this._changes.NewRoles)
            .filter(newRole => newRole.Name === dataRole.ShortName)
            .map(newRole => newRole.FieldCollection.Assignee.Id)
            .value();

        const deleteAssignees = _.chain(this._changes.DeletedRoles)
            .filter(deletedRole => deletedRole.Name === dataRole.ShortName)
            .map(deletedRole => deletedRole.FieldCollection.Assignee)
            .value();

        users = _.union(users, deleteAssignees);
        users = _.filter(users, user => !_.contains(newAssigneesIds, user.Id));
        
        model.Users(users); 
    }

    private AddNewDataRole(component: DataRoleComponent, dataRole: DataRoleModel) {
        const validationError = this.ValidateDataRole(dataRole);

        if (validationError) {
            new Notifier().Warning(validationError);
            return;
        }

        const deletedDataRole = _.find(this._changes.DeletedRoles, deletedRole => deletedRole.FieldCollection.Guid === dataRole.FieldCollection.Guid);
        if (deletedDataRole) {
            const deletedDataRoleIndex = this._changes.DeletedRoles.indexOf(deletedDataRole);
            this._changes.DeletedRoles.splice(deletedDataRoleIndex, 1);
        }

        this._changes.NewRoles.push(dataRole);
        component.AddCollections([dataRole.FieldCollection]);
        this.ChangeRolesManipulations(true);

        if (dataRole.Name === DataRoles.Instance.DEP.ShortName) {
            new Notifier().Warning(NOTIFICATIONS.PLEASE_UPDATE_VIEW);
        }
    }

    private RemoveDataRole(dataRole: DataRoleModel) {
        const newDataRole = _.find(this._changes.NewRoles, newRole => newRole.FieldCollection.Guid === dataRole.FieldCollection.Guid);

        if (newDataRole) {
            const newDataRoleIndex = this._changes.NewRoles.indexOf(newDataRole);
            this._changes.NewRoles.splice(newDataRoleIndex, 1);
        } else {
            this._changes.DeletedRoles.push(dataRole);

            const deputyComponent = _.find(this._dataRoles, component => component.DataRole === DataRoles.Instance.DEP);
            deputyComponent.RemoveCollectionComponent(dataRole.FieldCollection.Id);
        }
    }

    private ChangeRolesManipulations(enable: boolean) {
        this._dataRoles.forEach(component => component.ChangeManipulations(enable));
    }

    private ValidateDataRole(dataRole: DataRoleModel) {
        return this.ValidateDeputy(dataRole)
            || this.ValidateUniqueDataRole(dataRole)
            || this.ValidateThirdPartyDataRole(dataRole);
    }

    private ValidateDeputy(dataRole: DataRoleModel) {
        const alreadyAssignedCollection = _.find(this._dataRoles, component => component.HasFieldCollection(dataRole.FieldCollection.Name));
        return alreadyAssignedCollection && `${dataRole.FieldCollection.Name} is assigned more than once`;
    }

    private ValidateUniqueDataRole(dataRole: DataRoleModel) {
        const isUnique = !_.any(this._dataRoles, component => component.HasFieldCollection(dataRole.FieldCollection.Name));
        return isUnique ? null : `Field collection ${dataRole.FieldCollection.Name} is not unique`;
    }

    private ValidateThirdPartyDataRole(dataRole: DataRoleModel) {
        const thirdPartyDataRoles = DataRoles.Instance.GetThirdPartyDataRoles();
        const dataRoleIsThirdParty = _.any(thirdPartyDataRoles, (thirdPartyDataRole: DataRole) => thirdPartyDataRole.ShortName === dataRole.Name);
        const hasAssignee = dataRoleIsThirdParty && _.any(this._dataRoles, component => component.HasAssignee(dataRole.FieldCollection.Assignee.Id));
        return hasAssignee && `${dataRole.FieldCollection.Assignee.Name} should have only one field collection`;
    }
}