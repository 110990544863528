import { ModelState } from "Core/Common/Enums/ModelState";
import { TranslationManager } from "Core/Components/Translation/TranslationManager";

export function LanguageModel() {
	this._k_Language = 0;
	this._name = "";
	this._shortName = "";
	this._flagBase64 = "";
	this._state = ModelState.New;
	this._enabled = 0;
	this._fileName = "";
	this._fileType = "";
};

LanguageModel.prototype.FromJSON = function (data) {
	this._name = data.Name;
	this._shortName = data.ShortName;
	this._flagBase64 = data.FlagBase64;
	this._k_Language = data.K_Language;
	this._state = data.State;
	this._enabled = data.Enabled;
	this._fileName = data.FileName;
	this._fileType = data.FileType;
}

LanguageModel.prototype.toJSON = function () {
	const kLanguage = this._state === ModelState.New ? 0 : this._k_Language;
	return {
		K_Language: kLanguage,
		ShortName: this._shortName,
		Name: this._name,
		Enabled: this._enabled,
		State: this._state,
		FlagBase64: this.FlagBase64,
		FileName: this._fileName,
		FileType: this._fileType
	};
}

LanguageModel.prototype.Clone = function () {
	var language = new LanguageModel();
	language.K_Language = this._k_Language;
	language.ShortName = this._shortName;
	language.Name = this._name;
	language.Enabled = this._enabled;
	language.State = this._state;
	language.Attachment = this._attachment;
	language.FlagBase64 = this._flagBase64;
	language.FileName = this._fileName;
	language.FileType = this._fileType;

	return language;
}

Object.defineProperty(LanguageModel.prototype, 'K_Language', {
	enumerable: true,
	get: function () {
		return this._k_Language;
	},
	set: function (val) {
		this._k_Language = val;
	}
});


Object.defineProperty(LanguageModel.prototype, 'Name', {
	enumerable: true,
	get: function () {
		let lang = TranslationManager.Instance.GetLanguageById(this.K_Language);
		if(lang){
			return lang.Name;
		}
		return this._name;
	},
	set: function (val) {
		this._name = val;
	}
});

Object.defineProperty(LanguageModel.prototype, 'ShortName', {
	enumerable: true,
	get: function () {
		let lang = TranslationManager.Instance.GetLanguageById(this.K_Language);
		if(lang){
			return lang.ShortName;
		}
		return this._shortName;
	},
	set: function (val) {
		this._shortName = val;
	}
});

Object.defineProperty(LanguageModel.prototype, 'State', {
	enumerable: true,
	get: function () {
		return this._state;
	},
	set: function (val) {
		this._state = val;
	}
});

Object.defineProperty(LanguageModel.prototype, 'Enabled', {
	enumerable: true,
	get: function () {
		return this._enabled;
	},
	set: function (val) {
		this._enabled = val;
	}
});

Object.defineProperty(LanguageModel.prototype, 'FlagBase64', {
	enumerable: true,
	get: function () {

		if(this._flagBase64){
			return this._flagBase64;
		}

		let lang = TranslationManager.Instance.GetLanguageById(this.K_Language);
		if(lang){
			return lang.Flag;
		}
		
		return null;
	},
	set: function (val) {
		this._flagBase64 = val;
	}
});

Object.defineProperty(LanguageModel.prototype, 'FileName', {
	enumerable: true,
	get: function () {
		return this._fileName;
	},
	set: function (val) {
		this._fileName = val;
	}
});

Object.defineProperty(LanguageModel.prototype, 'FileType', {
	enumerable: true,
	get: function () {
		return this._fileType;
	},
	set: function (val) {
		this._fileType = val;
	}
});