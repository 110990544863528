import * as ko from 'knockout';

import {TranslationManager} from "Core/Components/Translation/TranslationManager";

import {LanguageModel} from "./Models/LanguageModel";
import {UserManager} from "../../User/UserManager";
import {CookieManager} from "../../Core/Common/CookieManager";
import {LanguageSwitcherStore} from "./Stores/LanguageSwitcherStore";

import Template from './Templates/Template.html';
import {SwitchLanguageModel} from './Models/SwitchLanguageModel';
import {LOCAL_STORAGE} from 'Core/Common/Enums/LocalStorageItems';
import {Notifier} from '../../Core/Common/Notifier';

export class LanguageMenu {
    IsAuthenticated: KnockoutObservable<boolean>;
    private _languages: LanguageModel[];
    private _language: LanguageModel;
    private _databaseName: string;

    constructor() {
        this._languages = TranslationManager.Instance.Languages
            .filter(language => language.IsDefault !== true)
            .map(language => new LanguageModel(language.Id, language.Name, language.ShortName, language.Flag));

        this._language = TranslationManager.Instance.GetCurrentLanguage();
        this._databaseName = UserManager.Instance.CurrentUser.DbName;
        this.IsAuthenticated = ko.observable(UserManager.Instance.IsAuthenticated);
    }

    GetTemplate() {
        return Template;
    }

    AfterRender() {

    }

    SelectLanguage(language: LanguageModel) {
        if (this._language.ShortName !== language.ShortName) {
			this._language = language;
			this.SaveLanguage();

            LanguageSwitcherStore.ChangeLanguage(this.BuildSwitchLanguageModel())
                .then(result => {
                    CookieManager.SetAuthToken(result.AccessToken);
                    CookieManager.SetRefreshToken(result.RefreshToken);

                    window.location.reload();
                })
                .fail(err => {
	                new Notifier().Failed(err.message);
                });
        }
    }

	SaveLanguage() {
		var language = Lockr.get<any>(LOCAL_STORAGE.LANGUAGE);
		if (language) {
			try {
				language = JSON.parse(language);
			} catch (err) {
				language = {};
			}
		} else {
			language = {};
		}

		language[this._databaseName] = this._language.ShortName;
		Lockr.set(LOCAL_STORAGE.LANGUAGE, JSON.stringify(language));
	}

    Render(targetId: string) {
        const element = document.getElementById(targetId);

        ko.cleanNode(element);
        ko.applyBindings(this, element);
    }

    private BuildSwitchLanguageModel() {
        const model = new SwitchLanguageModel();

        model.AccessToken = CookieManager.GetAuthToken();
        model.DatabaseName = this._databaseName;
        model.Language = this._language.ShortName;

        return model;
    }
}