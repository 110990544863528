import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

export class ElementZIndexManager {
	private static instance: ElementZIndexManager;
	private _zIndex: number;
	private _element: HTMLElement;

	constructor() {
		this._zIndex = 1;
	}

	static get Instance() {
		if (this.instance === null || this.instance === undefined) {
			this.instance = new ElementZIndexManager();
		}
		return this.instance;
	}

	get NextValue(): number {
		return this._zIndex++;
	}

	ToTop(element: HTMLElement) {
		$(element).css({ 'z-index': this.NextValue });
	}
}