const Num_190 = 190;
const Num_205 = 205;
export enum CanvasShapeWidth {
	Zone = Num_205,
	Node = Num_190,
	Param = 160,
	FunctionNode = 355,
	FunctionZone = 375,
	Source = Num_205,
	Destination = Num_205,
	Trigger = Num_190,
	JSFunction = Num_190,
	CSharpFunction = Num_190,
	Table = Num_190
}