import {deserialize, deserializeAs, serializeAs} from 'libs/cerialize';

export class CustomColumnHeaderModel {
    @deserialize
    Title: string;

    @deserialize
    FieldType: string;

    @deserialize
    FieldName: string;
}