import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";
import enumerable from '../../../../Common/Decorators/EnumerableDecorator';
export class DataRole {
	constructor(public Name: string, public ShortName: string, public Type: string, public Sort: number) {
	}
}

export class DataRoles {
	DPO: DataRole;
	VO: DataRole;
	SO: DataRole;
	DO: DataRole;
	PO: DataRole;
	DEP: DataRole;

	private static _instance;

	private constructor() {
		this.DPO = new DataRole(LABELS.DATA_PROTECTION_OFFICER, "DPO", "Gdpr", 0);
		this.VO = new DataRole(LABELS.VAULT_OWNER, "VO", "Vault", 1);
		this.SO = new DataRole(LABELS.SAFE_OVNER, "SO", "Safe", 2);
		this.DO = new DataRole(LABELS.DISCLOSURE_OFFICER, "DO", "Disclosed", 3);
		this.PO = new DataRole(LABELS.PUBLICATION_OFFICER, "PO", "Public", 4);
		this.DEP = new DataRole(LABELS.DEPUTY, "DEP", null, 5);
	}

	static get Instance() {
		return this._instance || (this._instance = new DataRoles());
	}

	GetAllDataRoles() {
		return Object.keys(this).filter(key => this[key] instanceof DataRole)
			.sort((leftKey, rightKey) => this[leftKey].Sort - this[rightKey].Sort)
			.map(key => this[key]);
	}

	GetThirdPartyDataRoles(){
		return [this.PO, this.DO, this.DPO];
	}
}