import {deserialize} from 'libs/cerialize'

export class LinkEditorTabSettingsModel {
    @deserialize
    Id: number;

    @deserialize
    TableId: number;

    @deserialize
    TableName: string;

    @deserialize
    TableTypeName: string;

    @deserialize
    TargetTableId: number;

    @deserialize
    TargetTableName: string;

    @deserialize
    LookupFieldId: number;

    @deserialize
    LookupFieldName: string;
}