import * as ko from 'knockout';
import * as _  from 'underscore';

import {ControlFactory} from 'Core/Controls/ControlFactory'
import {RenderModes} from "Core/Constant"
import {IControl} from "Core/Controls/IControl"
import {ControlToolBar} from "Core/Controls/FormDesigner/ToolBar/ControlToolBar/ControlToolBar"
import {EntityToolBar} from "Core/Controls/FormDesigner/ToolBar/EntityToolBar/EntityToolBar"
import {ConcreteEntityModel} from 'QueryBuilder/Models/ConcreteEntityModel';
import {ControlGroup} from "Core/Controls/FormDesigner/ToolBar/ControlToolBar/ControlGroup";
import {ControlGroupModel} from "Core/Controls/FormDesigner/ToolBar/ControlToolBar/Models/ControlGroupModel";
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";

import ToolBarTemplate from "Core/Controls/FormDesigner/ToolBar/Templates/ToolBar.html";
ko.templates["Core/Controls/FormDesigner/ToolBar/Templates/ToolBar"] = ToolBarTemplate;

export class ToolBar {
	private _controlToolBar: ControlToolBar;
	private _entityToolBar: EntityToolBar;
	private _isEnabled: KnockoutObservable<boolean>;
	private _labels = LABELS;

	constructor(groups: Array<ControlGroupModel>) {
		this._controlToolBar = new ControlToolBar(groups);
		this._entityToolBar = new EntityToolBar();
		this._isEnabled = ko.observable(false);
	}

	set IsEnabled(isEnabled: boolean) {
		this._isEnabled(isEnabled);
	}

	EnableToolBar(): boolean {
		return this._isEnabled();
	}

	get ControlToolBar(): ControlToolBar {
		return this._controlToolBar;
	}

	get EntityToolBar(): EntityToolBar {
		return this._entityToolBar;
	}

	GetTemplateName(): string {
		return "Core/Controls/FormDesigner/ToolBar/Templates/ToolBar";
	}

	AfterRender() { }

	EntitiesBarClick() {
		if (this._isEnabled()) {
			this._controlToolBar.Expanded = false;
			this._entityToolBar.Expanded = !this._entityToolBar.Expanded;
		}
	}

	ControlsBarClick() {
		if (this._isEnabled()) {
			this._controlToolBar.Expanded = !this._controlToolBar.Expanded;
			this._entityToolBar.Expanded = false;
		}
	}

	CollapseAll() {
		this._controlToolBar.Expanded = false;
		//this._entityToolBar.Expanded = false;
	}
}