import { URL } from 'Core/Common/Url';
import { Request, IRequestOptions } from 'Core/Common/Request';
import { P } from 'Core/Common/Promise';
import { Deserialize } from 'libs/cerialize';
import { EntityModel } from "Core/GeneralProperties/Managers/AlternativeEntitiesProperty/Models/EntityModel";

export class AlternativeEntitiesPropertyStore {
	static GetAlternativeEntities(entityId: number): P.Promise<EntityModel[]>{
		var deferredResult = P.defer<any>();

		let params = {
			EntityId: entityId
		};

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.FORM_DESIGNER.GET_ALTERNATIVE_ENTITIES,
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};
		Request.Send(requestParams).then(data => {
			deferredResult.resolve(data);
		});

		return deferredResult.promise();
	}
}