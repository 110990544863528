import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {ImageResponseModel} from "../Models/ImageResponseModel";

export class ImagePropertyControlStore {
    constructor(private _ordersEntityId: number, private _productsEntityId: number) {
    }
    GetAvailableImages(productId: number, fieldId: number): P.Promise<ImageResponseModel[]> {
        const deferred = P.defer<ImageResponseModel[]>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetAvailableImages',
                type: 'GET',
                dataType: 'json'
            }, params: {
                OrderEntityId: this._ordersEntityId,
                ProductEntityId: this._productsEntityId,
                ProductId: productId,
                FieldId: fieldId
            }
        })
            .then(images => deferred.resolve(images))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error getting available images')))

        return deferred.promise();
    }

    GetOriginalImageProperty(imageId: number, fieldId: number): P.Promise<string> {
	    const deferred = P.defer<string>();

	    Request.Send({
			    proxy: {
				    url: '/api/ProductConfiguratorApi/GetOriginalImageProperty',
				    type: 'POST',
			    }, params: {
				    OrderEntityId: this._ordersEntityId,
				    ProductEntityId: this._productsEntityId,
				    ImageId: imageId,
				    FieldId: fieldId
			    }
		    })
		    .then(images => deferred.resolve(images))
		    .fail(err => deferred.reject(this.GetResponseError(err, 'Error getting original image property')));

	    return deferred.promise();
    }

	GetOriginalSavedProductImage(imageId: number, fieldId: number): P.Promise<string> {
		const deferred = P.defer<string>();

		Request.Send({
			proxy: {
				url: '/api/ProductConfiguratorApi/GetOriginalSavedProductImage',
				type: 'POST'
			}, params: {
				OrderEntityId: this._ordersEntityId,
				ProductEntityId: this._productsEntityId,
				ImageId: imageId,
				FieldId: fieldId
			}
		})
			.then((image) => deferred.resolve(image))
			.fail(err => deferred.reject(this.GetResponseError(err, 'Error getting original hook image')));

		return deferred.promise();
	}

	GetOriginalSavedPartImage(imageId: number, fieldId: number): P.Promise<string>  {
	    const deferred = P.defer<string>();

	    Request.Send({
			    proxy: {
				    url: '/api/ProductConfiguratorApi/GetOriginalSavedPartImage',
				    type: 'POST'
			    }, params: {
				    OrderEntityId: this._ordersEntityId,
					ProductEntityId: this._productsEntityId,
					ImageId: imageId,
					FieldId: fieldId
			    }
		    })
		    .then((image) => deferred.resolve(image))
			.fail(err => deferred.reject(this.GetResponseError(err, 'Error getting original hook image')));

	    return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}