import * as ko from "knockout"
import * as _ from 'underscore'
import * as Viewer from "viewer";
import {MarkerArea} from "markerjs2";

import {Guid} from 'Core/Common/Guid';
import {Event} from 'Core/Common/Event';
import {BlockUI as BlockUi} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';
import {ZIndexManager} from 'Core/Common/ZIndexManager';
import TruncateFileName from "Core/Common/TruncateFileName";
import {MobileChecker} from "Core/Common/MobileChecker";
import {ViewerJsExtention} from 'Core/KnockoutExtentions/ViewerJsExtention';

import {LABELS} from 'Core/Components/Translation/Locales';

import {FileModel, ImageDataModel} from "Core/Controls/Image/Models/ImageDataModel";
import {AttachmentModel} from 'Core/Controls/Image/Models/AttachmentModel';
import {ConfigurationDataImage} from "../../Stores/Models/ConfigurationDataImage";
import {UpdateAnnotationsDto} from "./Models/UpdateAnnotationsDto";
import {ImageFromCamera} from "Core/Controls/Image/ImageFromCamera/ImageFromCamera";
import {ImageCropperScreen} from "Core/Controls/Image/ImageCropperScreen/ImageCropperScreen";
import {UploadChunkModel} from "Core/Controls/Drop/Models/UploadChunkModel";
import {DropDataStore} from "Core/Controls/Drop/Stores/DropDataStore";
import {StartPageStore} from "../../Stores/StartPageStore";

import Template from './Templates/ConfigurationImageGallery.html';
import { error } from "jquery";
ko.templates['Core/Components/Controls/ProductConfigurator/Pages/StartPage/Components/ConfigurationImageGallery/Templates/ConfigurationImageGallery'] = Template;

export const CLIPBOARD_IMAGES_TOPIC = 'clipboardImages';

export interface IConfigurationImageGallery {
    OrderEntityId: number;
    ProductsEntityId: number;
    ControlId: number;
    Annotations: any;
}

export class ConfigurationImageGallery extends Event {
    private _labels = LABELS;

    private _el: HTMLElement;
    private _dropzoneInstance: any;
    private _isRendered: KnockoutObservable<boolean>;

    private _dataModel: KnockoutObservable<ImageDataModel>;
    private _addedImageAttachments: Array<AttachmentModel>;
    private _removedImageIds: Array<number>;
    private _clipboardImages: KnockoutObservable<Array<File>>;
    private _imageGalleryVisible: KnockoutObservable<boolean>;
    private _markerArea: MarkerArea;
    private _markerButton: Element;
    private _annotations: Array<FileModel>;
    private _annotationsShown: KnockoutObservable<boolean>;

    private _isMobile: boolean;
    private _maxFileSize: number;

    private _imageGalleryContainer: HTMLElement;
    private _imageGalleryViewer: Viewer;
    private _startPageStore: StartPageStore;
    private _mutationObserver: MutationObserver;
    private _uploadedFilesObjects: Array<any>;
    private _controlId: number;
    private _useAnnotations: boolean;
    private _showAnnotations: boolean;

    constructor(options: IConfigurationImageGallery) {
        super();

        this._el = null;
        this._isRendered = ko.observable(false);

        this._dataModel = ko.observable(null);
        this._addedImageAttachments = [];
        this._removedImageIds = [];
        this._clipboardImages = ko.observable();
        this._imageGalleryVisible = ko.observable(false);

        this._annotations = [];
        this._markerButton = null;
        this._annotationsShown = ko.observable(false);
        this._uploadedFilesObjects = [];

        this._isMobile = MobileChecker.IsMobile();
        this._maxFileSize = 15;

        this._controlId = options.ControlId;

        this._startPageStore = new StartPageStore(options.OrderEntityId, options.ProductsEntityId);

        this._useAnnotations = options.Annotations.UseAnnotations;
        this._showAnnotations = options.Annotations.ShowAnnotationsModeByDefault;
    }

    get IsImageGallery(): boolean {
        return this._imageGalleryVisible();
    }

    get MaxFileSize(): number {
        return this._maxFileSize;
    }

    get AddedImageAttachments() {
        return this._addedImageAttachments;
    }

    get RemovedImageIds() {
        return this._removedImageIds;
    }

    get UpdatedAnnotations() {
        return _.map(this._annotations, annotation => new UpdateAnnotationsDto(Number(annotation.Id), annotation.Annotations, annotation.TempName, annotation.TempOriginalName));
    }

    get HasChanges(): boolean {
        return _.any(this._addedImageAttachments) || _.any(this._removedImageIds) || _.any(this._annotations);
    }

    AfterRender(el: Array<HTMLElement>): void {
        if (el) {
            this._el = el[0];
        }

        this._isRendered(true);
    }

    private BlockUi() {
        if (this._el) {
            BlockUi.Block({Target: this._el});
        }
    }

    private UnBlockUi() {
        BlockUi.Unblock(this._el);
    }

    public IsImage(fileName: string){
        let imageExtensions = ['bpm', 'jpg', 'jpeg', 'png', 'gif', 'jpe', 'jif', 'jfif', 'jfi', 'webp'],
            extension = _.last(fileName.split('.'));

        if(extension){
            if(imageExtensions.indexOf(extension.toLowerCase())>=0){
                return true;
            }
        }
        return false;
    }

    SetGalleryImages(imageGalleryExists: boolean, configurationImages: ConfigurationDataImage[]) {
        if (this._isRendered()) {
            this.LoadImagesOnInit(imageGalleryExists, configurationImages);
        } else {
            this._isRendered.subscribe(() => this.LoadImagesOnInit(imageGalleryExists, configurationImages));
        }
    }

    LoadImagesOnInit(imageGalleryExists: boolean, configurationImages: ConfigurationDataImage[]) {
        BlockUi.Block();

        this._imageGalleryVisible(imageGalleryExists);

        if (!this._imageGalleryVisible()) {
            this._dataModel(null);
            BlockUi.Unblock();
            return;
        }

        if (this._dataModel()) {
            this._dropzoneInstance.removeAllFiles();
            ko.utils.domData.set(this._dropzoneInstance.element, 'preloadFinished', false);

            this._addedImageAttachments = [];
            this._removedImageIds = [];
        }

        let dataModel = new ImageDataModel();
        _.each(configurationImages, (image)=>{
            const fileModel = new FileModel();
            fileModel.Id = image.Id;
            fileModel.Name = image.Name;
            fileModel.ImageData = image.ImageData;
            fileModel.Annotations = image.Annotations;
            fileModel.Size = image.Size;

            dataModel.Files.push(fileModel);
        });

        this.InitImageGalleryViewer();
        this.InitDropzone();
        this.InitPasteEvents();

        this._dataModel(dataModel);
        BlockUi.Unblock();
    }

    AfterUpload(file, data) {
        const attachmentModel = new AttachmentModel();
        attachmentModel.FileName = file.name;

        if (file.preloaded) {
            this.AddImageInGalleryMemory(file);
            file.previewElement.addEventListener('click', () => {
                this.GetOriginalImage(file, this._imageGalleryViewer);
            });
            return;
        }

        file.OriginalImageData = file.dataURL;

        BlockUi.Block();

        if (!this._dataModel()) {
            this._dataModel(new ImageDataModel());
        }

        if (file.status === 'error' || file.status === 'removed') {
            BlockUi.Unblock();
            return;
        }

        const fileModel = new FileModel();

        fileModel.ImageData = window.URL.createObjectURL(file);
        fileModel.OriginalImageData = fileModel.ImageData;

        if (file && file.status === "queued") {
            fileModel.CacheFile = file;
        }

        this._dataModel().Files.push(fileModel);
        this._dataModel.valueHasMutated();

        attachmentModel.FileName = TruncateFileName(file.name);
        attachmentModel.TempName = `${Guid.NewGuid()}_${attachmentModel.FileName}`;
        attachmentModel.Index = this._imageGalleryViewer.images.length;

        fileModel.Attachment = attachmentModel;
        fileModel.Id = Guid.NewGuid();
    
        const chunks = this.CutByChunks(file);

        this.UploadFileChunks(chunks, attachmentModel.TempName, 1, chunks.length)
            .finally(()=>{
                BlockUi.Unblock();
            })
            .then((result) => {
                if(!result){                                        
                    this._dropzoneInstance.removeFile(file);
                    return;
                }
                this.AddImageInGalleryMemory(fileModel);
                file.previewElement.addEventListener('click', () => {
                    this.GetOriginalImage(fileModel, this._imageGalleryViewer);
                });            
                this._uploadedFilesObjects.push({Id: fileModel.Id, File: file});
                this._addedImageAttachments.push(attachmentModel);
            });
    }

    private InitDropzone() {
        if (this._dropzoneInstance) {
            return;
        }

        this._dropzoneInstance = ko.utils.domData.get(
            this._el.querySelector('.dropzone'),
            'dropZoneInstance'
        );
    }

    private InitImageGalleryViewer() {
        const self = this;
        const $container = $("<div/>");
        this._imageGalleryContainer = $container[0];

        const opt = {
            markerable: this._useAnnotations,
            button: true,
            inline: false,
            navbar: false,
            title: false,
            toolbar: true,
            tooltip: true,
            movable: true,
            zoomable: true,
            rotatable: true,
            scalable: true,
            transition: true,
            fullscreen: true,
            keyboard: true,
            url: 'data-original',
            zIndex: ZIndexManager.Instance.NextValue,
            filter() {
                return true;
            },
            hide() {
                if (self._showAnnotations) {
                    self._annotationsShown(false);
                } else {
                    self._annotationsShown(true);
                }
                self._mutationObserver?.disconnect();
            },
            marker() {
                if (self._showAnnotations) {
                    self._markerButton = (self._imageGalleryViewer.image.parentNode.parentNode as HTMLElement).getElementsByClassName("viewer-marker")[0];
                    self.ShowMarkerArea(self._imageGalleryViewer.image);
                } else {
                    if (self._annotationsShown()) {
                        self._markerButton = (self._imageGalleryViewer.image.parentNode.parentNode as HTMLElement).getElementsByClassName("viewer-marker")[0];
                        self.ShowMarkerArea(self._imageGalleryViewer.image);

                        return;
                    }

                    self._annotationsShown(true);
                }
            },
            viewed() {
                if (!self._annotationsShown() && opt.markerable) {
                    opt.marker();
                    if (self._markerButton) {
                        self._markerButton.classList.add("disabled");
                        self._markerButton.setAttribute('style', 'pointer-events: none;');
                    }
                }
            }
        };

        this._imageGalleryViewer = new Viewer(this._imageGalleryContainer, opt);

        this._imageGalleryViewer.next = () => {
            if (this._imageGalleryViewer.index + 1 < this._dataModel().Files.length) {

                const image = this._dataModel().Files[this._imageGalleryViewer.index + 1];
                if (image.OriginalImageData == null) {
                    this.GetOriginalImage(image, this._imageGalleryViewer);
                } else {
                    this._imageGalleryViewer.view(Math.min(this._imageGalleryViewer.index + 1, this._imageGalleryViewer.images.length - 1));
                }
                return this._imageGalleryViewer;
            }
        }

        this._imageGalleryViewer.prev = () => {
            if (this._imageGalleryViewer.index > 0) {

                let image = this._dataModel().Files[this._imageGalleryViewer.index - 1];
                if (image.OriginalImageData == null) {
                    this.GetOriginalImage(image, this._imageGalleryViewer);
                } else {
                    this._imageGalleryViewer.view(Math.max(this._imageGalleryViewer.index - 1, 0));
                }
            }
            return this._imageGalleryViewer;
        }

        (this._imageGalleryViewer as any).clickOnImage = (uniqueId) => {
            const index = this._dataModel().Files.findIndex(file => file.UniqueId === uniqueId);
            const file = this._dataModel().Files[index];

            const img = this._imageGalleryViewer.images[index];
            $(img).attr('data-original', file.OriginalImageData);

            this._imageGalleryViewer.update();
            this._imageGalleryViewer.view(index);

            ViewerJsExtention.CustomTooltip();
        };
    }

    private InitPasteEvents() {
        PubSub.subscribe(CLIPBOARD_IMAGES_TOPIC, (message, data: Array<File> | undefined) => {
            this._clipboardImages(data);
        });

        this._el.addEventListener(
            'click',
            event => {
                const clipboardImages = this._clipboardImages();

                if (clipboardImages) {
                    event.stopPropagation();

                    const images = this._dropzoneInstance.options.maxFiles
                        ? clipboardImages.slice(
                            0,
                            this._dropzoneInstance.options.maxFiles - this._dropzoneInstance.files.length
                        )
                        : clipboardImages;

                    this._dropzoneInstance.emit('addedfiles', images);
                    this._dropzoneInstance.handleFiles(images);

                    PubSub.publish(CLIPBOARD_IMAGES_TOPIC, undefined);
                }
            },
            true
        );

        document.addEventListener('click', event => {
            if (
                this._clipboardImages() &&
                event.target instanceof Node &&
                !this._el.contains(event.target) // click outside
            ) {
                PubSub.publish(CLIPBOARD_IMAGES_TOPIC, undefined);
            }
        });
    }

    private AddImageInGalleryMemory(file) {
        const image = $("<img/>");
        image.prop('src', file.ImageData);
        image.attr('data-id', file.Id);

        $(this._imageGalleryContainer).append(image);
        this._imageGalleryViewer.update();
    }

    private RemoveImageFromGalleryMemory(file) {
        const image = $(this._imageGalleryContainer).find(`img[data-id="${file.Id}"][src="${file.ImageData}"]`);
        if(!image.length){
            return;
        }

        image.remove();
        this._imageGalleryViewer.update();
    }

    public AddFiles(files: File[]){
        this._dropzoneInstance.emit('addedfiles', files);
        this._dropzoneInstance.handleFiles(files);
    }

    private CutByChunks(file) {
        const chunkSizeInBytes = 1 * 1024 * 1024;

        let streamPosition = 0;
        let streamEndPosition = chunkSizeInBytes;

        if (chunkSizeInBytes >= file.size) {
            return [file];
        }

        const chunks = [];

        while (streamEndPosition < file.size) {
            const chunk = file.slice(streamPosition, streamEndPosition);
            chunks.push(chunk);

            streamPosition = streamEndPosition;
            streamEndPosition += chunkSizeInBytes;
        }
        const chunk = file.slice(streamPosition, file.size);
        chunks.push(chunk);

        return chunks;
    }

    private UploadFileChunks(fileChunks: any[], fileName: string, currentPart: number, totalPart: number) {
        return new Promise(resolve => {
            const uploadModel = new UploadChunkModel(fileName, fileChunks[currentPart - 1]);
            DropDataStore.UploadFileChunk(uploadModel)            
                .then(result => {
                    if (!result.IsSuccessfull) {
                        resolve(false);
                    } else {
                        if (totalPart >= currentPart) {
                            if (totalPart === currentPart) {
                                //Whole file uploaded
                                resolve(true);
                            } else {
                                //Show uploading progress
                                this.UploadFileChunks(fileChunks, fileName, currentPart + 1, totalPart).then(result => {
                                    resolve(result);
                                });
                            }
                        }
                    }
                }).fail((error)=>{
                    resolve(false);
                });
        });
    }

    GetOriginalImage(file: FileModel, viewer) {
        if (file.OriginalImageData) {
            viewer.clickOnImage(file.UniqueId);
            return;
        }

        this.BlockUi();

        this._startPageStore.GetOriginalConfigurationImage(Number(file.Id), this._controlId)
            .then(imageData => {
                file.OriginalImageData = imageData;
                this._dataModel.valueHasMutated();
                viewer.clickOnImage(file.UniqueId);
            })
            .fail(err => new Notifier().Failed(err.message))
            .always(() => {
                this.UnBlockUi();
            });
    }

    ImageDelete(file: FileModel) {
        if(!this._dataModel() || !this._dataModel().Files || this._dataModel().Files.length === 0){
            return;
        }

        let fileIndex = this._dataModel().Files.indexOf(file);
        let viewerImage = file;
        let galleryAttachment = file.Attachment;

        if (fileIndex === -1) {
            if (!this.IsImageGallery)
                return;

            const cachedFileModel = _.find(this._dataModel().Files,
                (fileWithCache) => {
                    return fileWithCache.CacheFile === file
                });
            if (!cachedFileModel) {
                return;
            }

            const cachedFileIndex = this._dataModel().Files.indexOf(cachedFileModel);
            fileIndex = cachedFileIndex;
            viewerImage = cachedFileModel;

            if (!galleryAttachment) {
                galleryAttachment = cachedFileModel.Attachment;
            }
        }

        this._dataModel().Files.splice(fileIndex, 1);
        this.RemoveImageFromGalleryMemory(viewerImage);

        if (file.Id === null || file.Id === undefined) {
            this._addedImageAttachments.splice(this._addedImageAttachments.indexOf(galleryAttachment), 1);
        } else {
            this._removedImageIds.push(Number(file.Id));

            const removedAnnotations = _.find(this._annotations, annotation => annotation.Id == file.Id);
            this._annotations = _.without(this._annotations, removedAnnotations);
        }

        this._dataModel.valueHasMutated();
    }

    ClearImageAttachments() {
        this._addedImageAttachments = [];
        this._removedImageIds = [];
        this._annotations = [];
    }

    GetImageFromCamera() {
        const self = this;
        const camera = new ImageFromCamera();

        const attachmentModel = new AttachmentModel();
        attachmentModel.FileName = `Camera_${Date.now()}.png`;

        camera.On('SAVE', this, (eventArgs) => {
            let imageCropperScreen = new ImageCropperScreen(true);

            imageCropperScreen.Image = eventArgs.data.Image;
            imageCropperScreen.Show();

            imageCropperScreen.On("IMAGE_CROPPED", self, (eventArgs: any) => {

                if (eventArgs.data.CropedImage) {
                    attachmentModel.Base64Data = eventArgs.data.CropedImage.split(',')[1];

                    let files = [];
                    if(attachmentModel.Base64Data && attachmentModel.Base64Data != ''){
                        if(self.IsImage(attachmentModel.FileName)){
                            files.push(this.ConvertBase64ToFile(attachmentModel.Base64Data, attachmentModel.FileName));
                        }
                    }
                    self.AddFiles(files);
                }
            });
        });
    }

    public ConvertBase64ToFile (base64Data: string, fileName: string): File {
        let byteString = atob(base64Data),
            ab = new ArrayBuffer(byteString.length),
            ia = new Uint8Array(ab),
            type = 'plain/text';

        for (let i = 0; i < byteString.length; i += 1) {
            ia[i] = byteString.charCodeAt(i);
        }

        if(this.IsImage(fileName)){
            type = `image/${_.last(fileName.split('.'))}`
        }

        return new File([ia.buffer], fileName, { type: type });
    };

    ShowMarkerArea(target) {
        const self = this;
        this.DisableViewerControls();

        const annotations = this._dataModel().Files[this._imageGalleryViewer.index].Annotations;

        this._markerArea = new MarkerArea(target);
        this._markerArea.renderAtNaturalSize  = true;
        this._markerArea.renderImageType = 'image/png';
        this._markerArea.renderMarkersOnly = true;
        this._markerArea.uiStyleSettings.zIndex = ZIndexManager.Instance.NextValue;

        this._mutationObserver = new MutationObserver((mutations)=> {
            _.each(mutations, (mutation)=>{
                if (mutation.type === "attributes") {
                    setTimeout(() => {
                        this._markerArea.positionUI();
                    }, 300);
                }
            });
        });

        this._mutationObserver.observe(target, { attributes: true });

        this._markerArea.addRenderEventListener((imgURL, state) => {

            let svgList = $('.__markerjs2_').find('svg'), svg = svgList[svgList.length - 1], svgString, svgBase64;
            svgString = new XMLSerializer().serializeToString(svg);
            svgBase64 = `data:image/svg+xml;base64,${window.btoa(svgString)}`;

            const fileModel = this._dataModel().Files[this._imageGalleryViewer.index];

            let images = $('.viewer-canvas').find('img');
            let img = images[images.length - 1];

            let canvas = document.createElement("canvas"), context, width = img.offsetWidth, height = img.offsetHeight;
            canvas.width = width;
            canvas.height = height;

            context = canvas.getContext("2d");
            context.rect(0 , 0 , canvas.width , canvas.height);

            let originalImage = document.createElement('img');
            originalImage.src = fileModel.OriginalImageData;
            originalImage.crossOrigin = 'Anonymous';

            originalImage.onload = function(){
                context.drawImage(originalImage , 0 , 0 , width , height);

                let svgImage = document.createElement('img');
                svgImage.src = svgBase64;
                svgImage.crossOrigin = 'Anonymous';

                svgImage.onload = function(){
                    context.drawImage(svgImage , 0 , 0 , width , height);
                    let base64 = canvas.toDataURL("image/png");

                    const fileForPreview = self._dataModel().Files[self._imageGalleryViewer.index] as any;
                    let prevEl = fileForPreview.previewElement;

                    if (!prevEl) {
                        _.map(self._uploadedFilesObjects, (fileObject) => {
                            if (fileObject.Id == fileModel.Id) {
                                prevEl = fileObject.File.previewElement;
                            }
                        });
                    }
                    let previewImageElement = $(prevEl).find('.dz-image > img');
                    previewImageElement.attr('src', base64);

                    const attachmentModel = new AttachmentModel(), attachment = self._addedImageAttachments.find(a => a.Index == self._imageGalleryViewer.index),
                        annotation = self._annotations.find(a => a.Id === fileModel.Id);

                    if (fileModel.Name) {
                        attachmentModel.FileName = TruncateFileName(fileModel.Name);
                    } else {
                        attachmentModel.FileName = TruncateFileName(attachment.FileName);
                    }

                    attachmentModel.Base64Data = base64.replace('data:image/png;base64,', '');
                    attachmentModel.TempName = `${Guid.NewGuid()}_${attachmentModel.FileName}`;

                    let file;
                    if(attachmentModel.Base64Data && attachmentModel.Base64Data != ''){
                        file = self.ConvertBase64ToFile(attachmentModel.Base64Data, attachmentModel.FileName);

                        let chunks = self.CutByChunks(file);
                        self.UploadFileChunks(chunks, attachmentModel.TempName, 1, chunks.length)
                            .then(() => BlockUi.Unblock());
                    }

                    if (fileModel.Attachment) {
                        fileModel.TempOriginalName = fileModel.Attachment.TempName;
                    } else {
                        let originalImageFile, originalImageBase64 = fileModel.OriginalImageData.replace('data:', '').replace(/^.+,/, '');
                        fileModel.TempOriginalName = `${Guid.NewGuid()}_${fileModel.Name}`;

                        if (originalImageBase64 && originalImageBase64 != '') {
                            originalImageFile = self.ConvertBase64ToFile(originalImageBase64, fileModel.Name);

                            let chunks = self.CutByChunks(originalImageFile);
                            self.UploadFileChunks(chunks, fileModel.TempOriginalName, 1, chunks.length)
                                .then(() => BlockUi.Unblock());
                        }
                    }

                    fileModel.TempName = attachmentModel.TempName;
                    fileModel.Annotations = JSON.stringify(state);

                    if (annotation) {
                        annotation.Annotations = fileModel.Annotations;
                        annotation.TempName = fileModel.TempName;
                        annotation.TempOriginalName = fileModel.TempOriginalName;
                    } else {
                        const attachment = self._addedImageAttachments.find(a => a.Index == self._imageGalleryViewer.index);
                        if (attachment) {
                            attachment.Annotations = fileModel.Annotations;
                            attachment.TempName = fileModel.TempName;
                            attachment.TempOriginalName = fileModel.TempOriginalName;
                        } else {
                            const newAnnotation = new FileModel();
                            newAnnotation.Id = fileModel.Id;
                            newAnnotation.Annotations = fileModel.Annotations;
                            newAnnotation.TempName = fileModel.TempName;
                            newAnnotation.TempOriginalName = fileModel.TempOriginalName;
                            self._annotations.push(newAnnotation);
                        }
                    }

                    self.EnableViewerControls();
                }
            };
        });

        this._markerButton.classList.add("disabled");
        this._markerButton.setAttribute('style', 'pointer-events: none;');
        this._markerArea.addCloseEventListener(() => {
            this.EnableViewerControls();
            this._annotationsShown(true);
            this._markerButton.classList.remove("disabled");
            this._markerButton.setAttribute('style', 'pointer-events: auto;');
        });

        this._markerArea.show();
        this._annotationsShown(false);

        if (annotations) {
            this._markerArea.restoreState(JSON.parse(annotations));
        }
    }

    DisableViewerControls() {
        this._imageGalleryViewer.options.movable = false;
        this._imageGalleryViewer.options.zoomable = false;
        this._imageGalleryViewer.options.rotatable = false;
        this._imageGalleryViewer.options.flippable = false;
    }

    EnableViewerControls() {
        this._imageGalleryViewer.options.movable = true;
        this._imageGalleryViewer.options.zoomable = true;
        this._imageGalleryViewer.options.flippable = true;
    }

    GetTemplateName() {
        return 'Core/Components/Controls/ProductConfigurator/Pages/StartPage/Components/ConfigurationImageGallery/Templates/ConfigurationImageGallery';
    }
}