import * as ko from 'knockout';
import * as _ from 'underscore';

import { QueryExpressionModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { QueryColumnModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryColumnModel';
import { QueryEntityJoinModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityJoinModel';
import { AGGREGATE_FUNCTIONS } from 'QueryBuilder/Constants';
import { DisplayField } from './DisplayField';
import { FIELD_TYPES } from 'Core/Constant';

var FieldTypesToAggregate = {
	YesNo: [AGGREGATE_FUNCTIONS.COUNT],
	Text: [AGGREGATE_FUNCTIONS.COUNT],
	Currency: [AGGREGATE_FUNCTIONS.COUNT, AGGREGATE_FUNCTIONS.SUM, AGGREGATE_FUNCTIONS.MIN, AGGREGATE_FUNCTIONS.MAX, AGGREGATE_FUNCTIONS.AVG],
	Date: [AGGREGATE_FUNCTIONS.COUNT],
	DateTime: [AGGREGATE_FUNCTIONS.COUNT],
	Integer: [AGGREGATE_FUNCTIONS.COUNT, AGGREGATE_FUNCTIONS.SUM, AGGREGATE_FUNCTIONS.MIN, AGGREGATE_FUNCTIONS.MAX, AGGREGATE_FUNCTIONS.AVG],
	Binary: [AGGREGATE_FUNCTIONS.COUNT],
	Guid: [AGGREGATE_FUNCTIONS.COUNT],
	Document: [],
	Image: [],
	Radio: [AGGREGATE_FUNCTIONS.COUNT],
	MultiSelect: [AGGREGATE_FUNCTIONS.COUNT],
	Icon: [AGGREGATE_FUNCTIONS.COUNT],
	Type: [AGGREGATE_FUNCTIONS.COUNT],
	Time: [AGGREGATE_FUNCTIONS.COUNT],
	PKey: [AGGREGATE_FUNCTIONS.COUNT],
	FlagCheck: [AGGREGATE_FUNCTIONS.COUNT],
	Lookup: [AGGREGATE_FUNCTIONS.COUNT],
	Sort: [AGGREGATE_FUNCTIONS.COUNT],
	Memo: [AGGREGATE_FUNCTIONS.COUNT],
	Password: [AGGREGATE_FUNCTIONS.COUNT],
	Color: [AGGREGATE_FUNCTIONS.COUNT],
	Decimal: [AGGREGATE_FUNCTIONS.COUNT, AGGREGATE_FUNCTIONS.SUM, AGGREGATE_FUNCTIONS.MIN, AGGREGATE_FUNCTIONS.MAX, AGGREGATE_FUNCTIONS.AVG],
	Alias: [AGGREGATE_FUNCTIONS.COUNT]
}

const RELATION_TYPE_VALUE = 'F_RELATIONTYPEVALUE';
const DELETED_STATUS = 'Delete';

export class FieldInfo {
	private _isCrossRow: KnockoutObservable<boolean>;
	private _functions: Array<string>;
	private _function: KnockoutObservable<string>;

	private _displayFields: KnockoutObservableArray<DisplayField>;
	private _selectedDisplayFieldsId: KnockoutObservableArray<number>;


	constructor(
		public Column?: QueryColumnModel,
		public Entity?: QueryEntityModel,
	) {
		this._displayFields = ko.observableArray([]);
		this._selectedDisplayFieldsId = ko.observableArray([]);

		this._isCrossRow = ko.observable(Column.IsCrossRow);
		this._isCrossRow.subscribe((newValue) => {
			this.Column.IsCrossRow = newValue;
		});

		this._functions = FieldTypesToAggregate[this.Column.Metadata.Type];
		this._function = ko.observable(this.Column.AggregateFunction);

		this._function.subscribe((newValue) => { this.Column.AggregateFunction = newValue; });

		_.each(this.Entity?.Metadata.Fields, f=>{
			if(f.Id != this.Column.Metadata.Id && f.IsCustom && f.Name != RELATION_TYPE_VALUE && f.LifestatusName !== DELETED_STATUS && f.Type !== FIELD_TYPES.Sort){
				let displayField = new DisplayField(f);
				this._displayFields.push(displayField);

				if(this.Column.DisplayFields.indexOf(f.Id)>=0){
					this._selectedDisplayFieldsId.push(displayField.Id);
				}
			}
		});

		this._selectedDisplayFieldsId.subscribe((newValue)=>{
			this.Column.DisplayFields = newValue
		});
	}

	get Title(): string {
		var entityName = `${this.Entity.Metadata.NameTranslation || this.Entity.Metadata.Name}`;
		if (this.Entity.Index > 1) {
			entityName = `${entityName}${this.Entity.Index}`;
		}

		return `${entityName}.${this.Column.Metadata.NameTranslation || this.Column.Metadata.Name}`;
	}

	get IsCrossRow(): KnockoutObservable<boolean> {
		return this._isCrossRow;
	}

	get Functions(): Array<string> {
		return this._functions;
	}

	get Function(): KnockoutObservable<string> {
		return this._function;
	}
}