import { BaseModelClass } from "DatabaseDesigner/Models/BaseModel";
import { CollectionModel } from "DatabaseDesigner/Models/CollectionModel";

export class TypeRestrictionModel extends BaseModelClass {

    _id: any;
    _typeId: any;
    _typeGuid: any;
    _roleId: any;
    _roleGuid: any;
    _value: any;

    constructor() {
        super();

        this._id = null;
        this._typeId = null;
        this._typeGuid = null;
        this._roleId = null;
        this._roleGuid = null;
        this._value = null;
    }

    get Id() {
        return this._id;
    }

    set Id(id) {
        this._id = id;
    }

    get TypeId() {
        return this._typeId;
    }

    set TypeId(id) {
        this._typeId = id;
    }

    get TypeGuid() {
        return this._typeGuid;
    }

    set TypeGuid(guid) {
        this._typeGuid = guid;
    }

    get RoleId() {
        return this._roleId;
    }

    set RoleId(id) {
        this._roleId = id;
    }

    get RoleGuid() {
        return this._roleGuid;
    }

    set RoleGuid(guid) {
        this._roleGuid = guid;
    }

    get Value() {
        return this._value;
    }

    set Value(value) {
        this._value = value;
    }

    FromJSON(data) {
        this._id = data.Id;
        this._typeId = data.TypeId;
        this._roleId = data.RoleId;
        this._value = data.Value;
    }

    toJSON() {
        return {
            Id: this._id,
            Guid: this._guid,
            TypeId: this._typeId,
            TypeGuid: this._typeGuid,
            RoleId: this._roleId,
            RoleGuid: this._roleGuid,
            Value: this._value
        };
    }

    static FromJSONArray(restrictions) {
        const restrictionsCollection = new CollectionModel({ model: TypeRestrictionModel });

        _.each(restrictions, restriction => {
            const model = new TypeRestrictionModel();
            model.FromJSON(restriction);
            restrictionsCollection.Add(model);
        });

        return restrictionsCollection;
    }
}