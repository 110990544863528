//Units
import { PathRunnerUnit } from "Core/Components/PathRunner/PathRunnerUnit";
import enumerable from '../../Common/Decorators/EnumerableDecorator';

export class PathRunner {
	private static _instance: PathRunner;
	private static _initialized: boolean;

	private _entityToUnit: { [enityId: number]: PathRunnerUnit };

	constructor() {
		if (PathRunner._initialized) {
			throw new Error("Path runner already initialized");
		}

		PathRunner._initialized = true;
		this._entityToUnit = {};
	}

	static get Instance() {
		if (PathRunner._instance) {
			return PathRunner._instance;
		}

		PathRunner._instance = new PathRunner();

		return PathRunner._instance;
	}

    GetUnit(entityId: number, isSpecialScreenExist: boolean) {
        let unit = this._entityToUnit[entityId];

        if (!unit) {
            unit = new PathRunnerUnit(entityId, isSpecialScreenExist);
            this._entityToUnit[entityId] = unit;
		}

		return unit;
	}
}