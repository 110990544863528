import { deserialize } from 'libs/cerialize';
import { deserializeAs } from 'libs/cerialize';

export class LookupFieldModel {
	@deserialize
	LookupTableTableId: number;

	@deserialize
	LookupTableTableName: string;

	@deserialize
	LookupTableValueFieldId: number;

	@deserialize
	LookupTableValueFieldName: string;

	@deserialize
	PrimaryTableLookupFieldName: string;
	
	@deserialize
	PrimaryTableLookupFieldId: number;

	@deserialize
	ValTableId: number;

	@deserialize
	ValFieldId: number;
}