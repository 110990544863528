import * as ko from 'knockout';

import { Event } from 'Core/Common/Event';

import { UserManager } from 'User/UserManager';
import { UserModel } from "User/Store/Models/UserModel";

import HeaderTemplate from 'MenuManager/Header/Templates/Header.html';
ko.templates['MenuManager/Header/Templates/Header'] = HeaderTemplate;

export class Header extends Event {
	private _user: KnockoutObservable<UserModel>;
	private _dbName: KnockoutObservable<string>;
	IsAuthenticated: KnockoutObservable<boolean>;
	LogoImage: KnockoutObservable<string>;

	constructor() {
		super();

		this._user = ko.observable(null);
		this._dbName = ko.observable('');
		this.IsAuthenticated = ko.observable(false);
		this.LogoImage = ko.observable(null);
		this.BindEvents();
	}

	Render(target: string) {
		const container = document.getElementById(target);
		if (container) {
			ko.cleanNode(container);
			ko.applyBindings(this, container);
		}
	}

	GetTemplateName() {
		return 'MenuManager/Header/Templates/Header';
	}

	NavigateToHomePage() {
		this.Trigger('NAVIGATE_TO_HOME_PAGE');
	}

	ChangeLogoImage(profileImage: string) {
		this.LogoImage(profileImage || (this._user() && this._user().LicenseImage));
	}

	private BindEvents() {
		this.IsAuthenticated.subscribe(isAuthenticated => this.OnSessionChanged(isAuthenticated));
	}

	private OnSessionChanged(isAuthenticated: boolean) {
		if (isAuthenticated) {
			this.RefreshUser();
		} else {
			this._user(null);
		}
	}

	private RefreshUser() {
		this._user(UserManager.Instance.CurrentUser);
		this._user() && this._dbName(this._user().DbName);
	}
}