import {IDisposable, IOnInit} from "Core/Common/Interfaces/LifeCycleHooks";
import {EventBus} from "./EventBus";
import {EventHandler} from "./EventHandler";

export class EventBusConsumer implements IOnInit, IDisposable {
    private _eventHandlerExecutors: EventHandler<any, any, any>[];

    private _eventBus: EventBus;

    constructor() {
        this._eventHandlerExecutors = [];
    }

    get EventBus() {
        return this._eventBus;
    }

    AssignEventBus(bus: EventBus) {
        this._eventHandlerExecutors.forEach(handler => handler.UnSubscribe());
        this._eventHandlerExecutors = [];

        this._eventBus = bus;
    }

    DispatchEvent<TData extends {}>(eventName: string, eventData?: TData) {
        this._eventBus.Dispatch(eventName, {Data: eventData, Source: this});
    }

    HandleEvent<TData extends {}>(eventName: string): EventHandler<TData, any, any>;
    HandleEvent<TData extends {}>(eventName: string): EventHandler<TData, any, any>;
    HandleEvent<TData extends {}, TSource extends {}>(eventName: string): EventHandler<TData, TSource, any>;
    HandleEvent<TData extends {}, TSource extends {}, TContext extends {}>(eventName: string): EventHandler<TData, TSource, TContext> {
        const eventHandlerExecutor = this._eventBus.Handle(eventName, this);
        this._eventHandlerExecutors.push(eventHandlerExecutor);

        return eventHandlerExecutor as EventHandler<TData, TSource, TContext>;
    }

    StopHandlingEvent(eventName: string) {
        const eventHandlerExecutors = this.FindEventHandlerExecutors(eventName);
        eventHandlerExecutors.forEach(executor => executor.UnSubscribe());
    }

    OnInit() {
    }

    Dispose() {
        this._eventHandlerExecutors.forEach(executor => executor.UnSubscribe());
        this._eventHandlerExecutors = [];
    }

    private FindEventHandlerExecutors(eventName: string): EventHandler<any, any, any>[] {
        return this._eventHandlerExecutors.filter(handler => handler.EventName === eventName);
    }
}