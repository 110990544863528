import { deserialize, deserializeAs } from "libs/cerialize";

export class FollowupModeDto{
    @deserialize
    Id: number;
    
    @deserialize
    Name: string;

    @deserialize
    TranslatedName: string;
}