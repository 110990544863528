import * as ko from "knockout";

import {Event} from "Core/Common/Event";
import {LABELS} from "Core/Components/Translation/Locales";

import {ISchedulerParams} from "./ISchedulerParams";
import {SchedulerView} from "Core/Components/Controls/Scheduler/Views/SchedulerView";

import SchedulerTemplate from "Core/Components/Controls/Scheduler/Templates/Scheduler.html";
import AgendaConnection from "Core/Components/Controls/Scheduler/Templates/AgendaConnection.html";
import ReadingData from "Core/Components/Controls/Scheduler/Templates/ReadingData.html";
import UsersNotDefined from "Core/Components/Controls/Scheduler/Templates/UsersNotDefined.html";
import GlobalNotValid from "Core/Components/Controls/Scheduler/Templates/GlobalNotValid.html";

export class Scheduler extends Event {
    _entityId: number;
    _screenSubjectEntityId: number;
    _screenSubjectRecordId: number | null;
    _view: KnockoutObservable<SchedulerView>;

    private _isReady: KnockoutObservable<boolean>;
    private _currentDate: KnockoutObservable<Date>;
    private _viewModel: SchedulerView;
    private _labels = LABELS;

    constructor(params: ISchedulerParams) {
        super();

        this._entityId = params.EntityId;
        this._screenSubjectEntityId = params.ScreenSubjectEntityId;
        this._screenSubjectRecordId = params.ScreenSubjectRecordId;
        this._isReady = ko.observable(false);
        this._currentDate = ko.observable(null);

        this._viewModel = new SchedulerView({
            EntityId: this._entityId,
            ScreenSubjectEntityId: this._screenSubjectEntityId,
            ScreenSubjectRecordId: this._screenSubjectRecordId,
            RePlanningData: params.RePlanningData,
            ControlId: params.ControlId
        });
        this._view = ko.observable(this._viewModel);
    }

    get SetupValid() {
        const view = this._view();
        return view.AgendaConnection() && view.ReadingData() && view.GlobalValid();
    }

    GetScreenSubjectRecordName() {
        return this._viewModel.GetScreenSubjectRecordName();
    }

    UpdateSchedulerView() {
        this._view().Show();
    }

    Render(node: HTMLElement) {
        ko.cleanNode(node);
        ko.applyBindings(this, node);
    }

    GetSchedulerTemplate() {
        return SchedulerTemplate;
    }

    AfterRender() {
        this._isReady(true);
    }

    GetInvalidSetupTemplate() {
        const view = this._view();

        if (!view.AgendaConnection()) {
            return AgendaConnection;
        }

        if (!view.ReadingData()) {
            return ReadingData;
        }

        if (!view.GlobalValid()) {
            return GlobalNotValid;
        }
    }

    GetAgendaConnectionTemplate() {
        return "Core/Components/Controls/Scheduler/Templates/AgendaConnection";
    }

    GetReadingDataTemplate() {
        return "Core/Components/Controls/Scheduler/Templates/ReadingData";
    }

    GetNotDefinedUsersTemplate() {
        return "Core/Components/Controls/Scheduler/Templates/UsersNotDefined";
    }

    GetGlobalNotValidTemplate() {
        return "Core/Components/Controls/Scheduler/Templates/GlobalNotValid";
    }
}