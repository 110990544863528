import {deserializeAs, deserialize} from 'libs/cerialize';
import {QueryExpressionModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import {EntityRelationshipsMetadataModel} from 'Core/Controls/Grid/Models/GridDataModel/Metadata/EntityRelationshipsMetadataModel';
import { TranslationModel } from 'Core/ScreenManager/Models/TranslationModel';

export class EditQueryModel {
	constructor() {
		this.EntitiesRelationships = [];
	}

	@deserialize
	Name: string;

	@deserializeAs(EntityRelationshipsMetadataModel)
	EntitiesRelationships: Array<EntityRelationshipsMetadataModel>;

	@deserializeAs(QueryExpressionModel)
	Query: QueryExpressionModel;

	@deserializeAs(TranslationModel)
	NameTranslations: Array<TranslationModel>;
}