import * as ko from "knockout";
import {IControlParam, ISearchScreenParam} from "Core/Screens/IScreen";
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel';
import { RenderModes, CONTROL_TYPES } from "Core/Constant";
import { Modal } from 'Core/Common/Modal';
import { BlockUI } from 'Core/Common/BlockUi';
import {FormDesigner as FormDesignerControl} from "Core/Controls/FormDesigner/FormDesigner";
import {
    ConfirmationDialog, EVENTS as ConfirmationDialogEvents,
    Types as ConfirmationTypes
} from "../Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import {CONFIRMATIONS} from "../Core/Components/Translation/Locales";

import FormDesignerTemplate from "Pages/Templates/FormDesigner.html";
ko.templates["Pages/Templates/FormDesigner"] = FormDesignerTemplate;

export class FormDesignerPage {
	private _formDesignerControl: FormDesignerControl;
	private _isReady: KnockoutObservable<boolean>;
	protected _el: HTMLElement;
	private _modal: Modal;

	constructor() {
		this._isReady = ko.observable(false);
		var controlModel = new ControlModel();
		controlModel.TypeName = CONTROL_TYPES.FormDesigner;
		var controlsParam: IControlParam = {
			Model: controlModel,
			Form: null,
			RenderMode: RenderModes.View
		}
		this._formDesignerControl = new FormDesignerControl(controlsParam);
	}

	Render(target): void {
		ko.cleanNode(document.getElementById(target));
		ko.applyBindings(this, document.getElementById(target));
	}

	async SetScreenId(screenId: number) {
		BlockUI.Block({ Target: $(this._el).closest('.jBox-content')[0], JBoxBlockButton: true});
		await this._formDesignerControl.SetValue(screenId);
		BlockUI.Unblock($(this._el).closest('.jBox-content')[0], true);
	}

	GetTemplateName(): string {
		return 'Pages/Templates/FormDesigner';
	}

	get FormDesignerControl(): FormDesignerControl {
		return this._formDesignerControl;
	}

	AfterRender(el: Array<HTMLElement>) {
		if (el) {
			this._el = el[0];
		}
		this._isReady(true);
	}

	RemoveControl() {
	}

	ShowInModal() {
		this._modal = new Modal({
			onClose: () => {
				if (this._formDesignerControl.Screen.HasChanges) {
                    const confirmationDialog = new ConfirmationDialog({
                        Text: CONFIRMATIONS.SAVE_CHANGES,
                        Type: ConfirmationTypes.Question
                    });
                    confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, () => {
                        this._formDesignerControl.Save();
                    });
                    confirmationDialog.Show();
				}
			},
			addClass: 'screen-in-modal formDesignerModal',
			blockScroll: true
		}, true);
		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);
		this._modal.Show();
	}
}