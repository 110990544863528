import * as ko from "knockout";

export abstract class BaseProperty {
    private _name: string;
	private _type: string;
	private _valueRequired: boolean;
	protected _isEnabled: KnockoutObservable<boolean>;
	private _warningMessage: KnockoutObservable<string>;
	private _propertyDescriptionType: string;

    Value: KnockoutObservable<any>;

	constructor(property: IPropertyDescription, isEnabled: boolean = true) {
        this._name = property.Name;
		this._type = property.PropertyManager;
		this._propertyDescriptionType = property.Type;
		this._valueRequired = property.ValueRequired;
		this._isEnabled = ko.observable(isEnabled);
		this._warningMessage = ko.observable(null);
	}

	get PropertyDescriptionType() {
		return this._propertyDescriptionType
	}

	OnInit() {

	}

    GetTemplateName(): string {
        return "";
    }

	AfterRender(el: Array<HTMLElement>) {

	}

	get IsEnabled(): KnockoutObservable<boolean> {
		return this._isEnabled;
	}

	SetEnabled(enable: boolean) {
		this._isEnabled(enable);
	}

    get Name() {
        return this._name;
    }

    get Type() {
        return this._type;
	}

	get ValueRequired() {
		return this._valueRequired;
	}

	set WarningMessage(warningMessage: string) {
		this._warningMessage(warningMessage);
	}

	get WarningMessage(): string {
		return this._warningMessage();
	}

    GetDefaultValue(): any { }

	SetValue(propertyValue: any) { }

	IsValid(): boolean { return true; }

	ErrorMessage(): string { return null; }

	Reset() {};
}

export interface IPropertyOption {
	Name: string;
	Value: any;
}

export interface IPropertyDescription {
    Name: string;
	Type: string;
	PropertyManager: string;
	ValueRequired: boolean;
    Options: Array<IPropertyOption>;
    Source: ISource;
	DefaultValue?: any;
}

export interface IPropertySortOption {
	FieldId: number;
	SortOrder: number;
}

export interface ISource {
    TableName: string;
    Value: string;
    DisplayValue: string;
}