import {deserialize, deserializeAs, serialize, serializeAs} from "libs/cerialize";

export class LifeStatusInfo {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	LastStatusDate: Date;

	@deserialize
	Memo: string;

	@deserialize
	MemoTranslation: string;

	Modified: boolean;

	@deserialize
	TranslatedName: string;

	constructor(name?: string, lastStatusDate?: Date) {
		this.Name = name;
		this.LastStatusDate = lastStatusDate;
	}
}