import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';

import {GetLookupCheckListRequestModel} from "Core/Components/ActionCheckList/CheckItems/Lookup/Models/GetLookupCheckListRequestModel";
import {GetLookupCheckListResponseModel} from "Core/Components/ActionCheckList/CheckItems/Lookup/Models/GetLookupCheckListResponseModel";

export class LookupCheckItemStore {
    static GetLookupCheckListData(params: GetLookupCheckListRequestModel): P.Promise<GetLookupCheckListResponseModel> {
        let deferredResult = P.defer<GetLookupCheckListResponseModel>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: 'api/FollowUpApi/GetLookupCheckListData',
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(result => deferredResult.resolve(result))
            .fail(error => deferredResult.reject(this.GetResponseError(error, 'Error getting lookup checklist data')));

        return deferredResult.promise();
    }

    static GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return { message: JSON.parse(err.message).Message };
        }
        if (err.status === 500) {
            return { message: JSON.parse(err.message).ExceptionMessage };
        }

        return { message: generalError };
    }
}