import * as ko from 'knockout';

import {BlockUI} from 'Core/Common/BlockUi';
import {P} from 'Core/Common/Promise';
import {Notifier} from 'Core/Common/Notifier';

import {BaseProperty, IPropertyDescription} from '../BaseProperty';
import {ITabSecurityValue} from './ITabSecurityValue';

import {TabSecurityDisplayView} from './Views/DisplayView/TabSecurityDisplayView';
import {TabSecurityEditView} from './Views/EditView/TabSecurityEditView';

import {TabSecurityStore} from './Stores/TabSecurityStore';

import {BusinessRoleDto} from './Stores/Models/BusinessRoleDto';
import {BusinessRole} from './Models/BusinessRole';


import Template from 'Core/GeneralProperties/Managers/TabSecurity/Templates/Template.html'

ko.templates['Core/GeneralProperties/Managers/TabSecurity/Templates/Template'] = Template;

export class TabSecurity extends BaseProperty {
    private readonly _store: TabSecurityStore;
    private readonly _displayView: KnockoutObservable<TabSecurityDisplayView>;

    private _el: HTMLElement;
    private _roles: BusinessRole[];

    constructor(property: IPropertyDescription, propertyValue: any) {
        super(property);

        this._store = new TabSecurityStore();
        this._displayView = ko.observable(null);

        this.Value = ko.observable(propertyValue || this.GetDefaultValue());
    }

    SetValue(propertyValue: any) {
        if (propertyValue) {
            this.Value(propertyValue);
        }
    }

    GetTemplateName(): string {
        return 'Core/GeneralProperties/Managers/TabSecurity/Templates/Template';
    }

    AfterRender(el: Array<HTMLElement>) {
        this._el = el[0];
        this.LoadRestrictions()
            .then(() => this.InitDisplayView());
    }

    GetDefaultValue(): ITabSecurityValue {
        return {
            TabSecuritySettings: []
        }
    }

    InitDisplayView() {
        const displayView = new TabSecurityDisplayView({
            Roles: this._roles,
            Settings: this.Value().TabSecuritySettings
        });

        displayView.On('RequestEditView', this, () => this.OpenEditView());

        this._displayView(displayView);
    }

    OpenEditView() {
        const editView = new TabSecurityEditView({
            Roles: this._roles,
            Settings: this.Value().TabSecuritySettings
        });

        editView.On('SaveChanges', this, eventArgs => {
            this.Value().TabSecuritySettings = eventArgs.data.Settings;
            this.Value.valueHasMutated();

            editView.Close();
            this.InitDisplayView();
        });

        editView.Show();
    }

    private LoadRestrictions() {
        BlockUI.Block({Target: this._el});
        return this._store.GetBusinessRoles()
            .then(roles => {
                this._roles = this.MapToRoles(roles);
                return P.resolve(null);
            })
            .fail(error => new Notifier().Failed(error.message))
            .always(() => BlockUI.Unblock(this._el));
    }

    private MapToRoles(rolesDto: BusinessRoleDto[]) {
        const roles = rolesDto.map(roleDto => new BusinessRole(roleDto.Id, roleDto.Name, roleDto.TranslatedName));
        roles.unshift(new BusinessRole(null, 'Without Role', null));

        return roles;
    }
}