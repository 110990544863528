import * as ko from "knockout";
import "jquery";
import "xEditable";
import "TinyMceExtension";

import {IValueUpdater} from "LookupEditor/Interfaces/IValueUpdater";
import {IColumnValue} from "LookupEditor/Interfaces/IColumnValue";
import {ColumnEditor} from "LookupEditor/ColumnEditors/Base/ColumnEditor";


import PKeyTemplate from "LookupEditor/Templates/Columns/Memo.html";
ko.templates["LookupEditor/Templates/Columns/Memo"] = PKeyTemplate;

export class MemoColumnEditor extends ColumnEditor {
	Show(): void { }

	GetTemplateName(): string {
		return 'LookupEditor/Templates/Columns/Memo';
	}

	AfterRender(el, owner: IValueUpdater, columnName): void {

		const field = owner.GetColumnData(columnName);

		($.fn.editable as any).defaults.mode = 'popup';
		$(el).editable({
			type: 'memo',
			placement: 'bottom',
			showbuttons: 'bottom',
			translations: field.FieldValueTranslations,
			value: {
				default: '',
				current: $(el).data('text')
			},
			success: (response, newValue) => {
				var value: IColumnValue = {
					Name: columnName,
					Value: newValue.current,
					Translations: newValue.translates
				};

				owner.UpdateValue(value);
			}
		});
	}

	ToString() {
		return "Memo";
	}
}