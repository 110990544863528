import * as ko from 'knockout';
import * as _ from 'underscore';

import {Spreadsheet} from 'spreadsheet';

import {RenderModes} from 'Core/Constant';

import {Guid} from 'Core/Common/Guid';
import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';

import {GeneralProperties} from 'Core/GeneralProperties/GeneralProperties';

import {BaseControl, IControlValue} from '../BaseControl/BaseControl';
import {IControlParam} from 'Core/Screens/IScreen';

import ConfigJson from 'Core/Controls/Spreadsheet/Configs/spreadsheet-config.json';
import {DimensionalTablePropertyField} from '../../GeneralProperties/Managers/DimensionalTableProperty/Models/DimensionalTablePropertyValue';

import {SpreadsheetStore} from './Store/SpreadsheetStore';

import {SpreadsheetDataDto} from './Models/Dto/SpreadsheetDataDto';
import {SpreadsheetDescriptorDto} from './Models/Dto/SpreadsheetDescriptorDto';

import {SpreadsheetSaveDto} from './Models/Dto/SpreadsheetSaveDto';
import {SpreadsheetPageRemoveDto} from './Models/Dto/SpreadsheetPageRemoveDto';
import {SpreadsheetNewPageDto} from './Models/Dto/SpreadsheetNewPageDto';
import {SpreadsheetRecordDto} from './Models/Dto/SpreadsheetRecordDto';
import {SpreadsheetRecordFieldDto} from './Models/Dto/SpreadsheetRecordFieldDto';

import {SpreadsheetViewMappingProfile} from './Mappings/SpreadsheetViewMappingProfile';

import {SpreadsheetData} from './Models/View/SpreadsheetData';
import {SpreadsheetDescriptor} from './Models/View/SpreadsheetDescriptor';
import {SpreadsheetDimensionDescriptor} from './Models/View/SpreadsheetDimensionDescriptor';
import {SpreadsheetPageData} from './Models/View/SpreadsheetPageData';
import {SpreadsheetCellPosition} from './Models/View/SpreadsheetCellPosition';
import {SpreadsheetCellData} from './Models/View/SpreadsheetCellData';
import {SpreadsheetDimensionDirections} from './Enums/SpreadsheetDimensionDirections';
import {SpreadsheetCellRange} from './Models/View/SpreadsheetCellRange';

import {RenamePagePopup} from './Components/RenamePagePopup/RenamePagePopup';
import {PageName} from './Components/RenamePagePopup/Models/View/PageName';

import ToolBarTemplate from './Templates/ToolBar.html';
import DesignTemplate from './Templates/Design.html';
import ViewTemplate from './Templates/View.html';
import EditTemplate from './Templates/Edit.html';
import HelpViewTemplate from './Templates/HelpView.html';
import LegendTemplate from './Templates/Legend.html';


ko.templates['Core/Controls/Spreadsheet/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Spreadsheet/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/Spreadsheet/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Spreadsheet/Templates/Edit'] = EditTemplate;
ko.templates['Core/Controls/Spreadsheet/Templates/HelpView'] = HelpViewTemplate;

export class SpreadsheetControl extends BaseControl {
    private _readonly: boolean;
    private _setValueWasRequested: boolean;
    private _isEditing: boolean;

    private _store: SpreadsheetStore;
    private _viewMappings: SpreadsheetViewMappingProfile;
    private _spreadsheetDescriptor: SpreadsheetDescriptor;

    private _renamePagePopup: RenamePagePopup;

    private _removedPages: string[];

    private _styles: any;
    private _rowsCount: number;
    private _colsCount: number;
    private _originCellValue: string;

    private _spreadsheet: Spreadsheet;

    IsLegendReady: KnockoutObservable<boolean>;
    Error: KnockoutObservable<string>;
    Dimension1: KnockoutObservable<string>;
    Dimension1Translated: KnockoutObservable<string>;

    Dimension2: KnockoutObservable<string>;
    Dimension2Translated: KnockoutObservable<string>;

    Dimension3: KnockoutObservable<string>;
    Dimension3Translated: KnockoutObservable<string>;

    Dimension4: KnockoutObservable<string>;
    Dimension4Translated: KnockoutObservable<string>;

    PageField: KnockoutObservable<string>;
    PageFieldTranslated: KnockoutObservable<string>;

    ValueField: KnockoutObservable<string>;
    ValueFieldTranslated: KnockoutObservable<string>;

    constructor(params: IControlParam) {
        super(params, ConfigJson);
        this.Init();
    }

    GetLegendTemplate() {
        return (ko as any).renderTemplateXHtml(LegendTemplate, this);
    }

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);

        if (this._renderMode() === RenderModes.Design) {
            return;
        }

        if (this._renderMode() === RenderModes.View) {
            this.AfterViewRender();
        }

        if (this._renderMode() === RenderModes.Edit) {
            this.AfterEditRender();
        }
    }

    SetValue(value: IControlValue) {
        this._setValueWasRequested = true;

        if (this._isRendered()) {
            this.LoadData();
        }
    }

    IsValid(): boolean {
        if (!this._isRendered()) {
            return true;
        }

        if (this._spreadsheetDescriptor.PageField && this._spreadsheetDescriptor.PageField.IsLookup) {
            const pagesWithoutDbId = this._spreadsheet.getSheets()
                .filter(sheet => !this.GetPageName(sheet.id))
                .map(sheet => this.GetPageDisplayName(sheet.id));

            if (pagesWithoutDbId.length > 0) {
                this.Error('Select the name for these sheets using Rename menu: ' + pagesWithoutDbId.join(', '));
                return false;
            }
        }

        if (this._isEditing) {
            this.Error('Please, finish editing on the spreadsheet');
            return false;
        }

        this.Error(null);
        return true;
    }

    Deserialize(): SpreadsheetSaveDto {
        if (!this._isRendered()) {
            return null;
        }

        if (this._spreadsheetDescriptor.IsView) {
            return null;
        }

        const newPages: SpreadsheetNewPageDto[] = this._spreadsheet.getSheets()
            .filter(sheet => this.GetPage(sheet.id))
            .map(sheet => {
                const origin = this.GetOriginPosition(sheet.id);
                let pageName = this.GetPageName(sheet.id);

                if (pageName === SpreadsheetPageData.GetDefaultPageId() || pageName === SpreadsheetPageData.GetDefaultPageName()) {
                    pageName = null;
                }

                return new SpreadsheetNewPageDto(
                    this.GetFieldModel().EntityId,
                    this.GetEntityRecordId(),
                    this.PageField(),
                    pageName,
                    this.ValueField(),
                    this.GetCellsData(sheet.id, origin)
                );
            });

        const removedPages: SpreadsheetPageRemoveDto[] = this._removedPages.map(pageName => {
            const pageNameDto = pageName === SpreadsheetPageData.GetDefaultPageName() ? null : pageName;
            return new SpreadsheetPageRemoveDto(
                this.GetFieldModel().EntityId,
                this.GetEntityRecordId(),
                this.PageField(),
                pageNameDto
            );
        })

        const paged = !!this.PageField();
        return new SpreadsheetSaveDto(
            this.GetFieldModel().EntityId, this.GetEntityRecordId(),
            newPages, removedPages,
            paged
        );
    }

    private Init() {
        this._setValueWasRequested = false;
        this._isEditing = false;

        this._store = new SpreadsheetStore();
        this._viewMappings = new SpreadsheetViewMappingProfile();

        this._removedPages = [];

        this._styles = {
            origin: {
                textAlign: 'center',
                'font-weight': 'bold',
                color: '#ddd'
            },
            dimension1: {
                background: '#1c87d9',
                color: '#fff'
            },
            dimension2: {
                background: '#ff9b00',
                color: '#fff'
            },
            dimension3: {
                background: '#4f4083',
                color: '#fff'
            },
            dimension4: {
                background: '#ccc',
                color: '#fff',
                'font-weight': 'bold'
            },
            value: {
                'font-weight': 'bold'
            }
        };

        this.IsLegendReady = ko.observable(false);

        if (this._renderMode() === RenderModes.View || this._renderMode() === RenderModes.HelpView) {
            this.InitForView();
        }

        if (this._renderMode() === RenderModes.Edit) {
            this.InitForEdit();
        }

        if (this._renderMode() === RenderModes.Design) {
            this.InitForDesign();
        }
    }

    private AfterViewRender() {
        this._readonly = true;

        this.RenderSpreadsheet();

        if (this._setValueWasRequested) {
            this.LoadData();
        }
    }

    private AfterEditRender() {
        this._readonly = false;

        this.RenderSpreadsheet();

        if (this._setValueWasRequested) {
            this.LoadData();
        }
    }

    private RenderSpreadsheet() {
        this._colsCount = 100;
        this._rowsCount = 100;
        this._originCellValue = '@ORIGIN';

        this._spreadsheet = new Spreadsheet(
            this._el.querySelector<HTMLDivElement>('.spreadsheet'), {
                multiSheets: !!this.PageField(),
                colsCount: this._colsCount,
                rowsCount: this._rowsCount
            });
    }

    private BindEvents() {
        if (this._readonly) {
            this._spreadsheet.events.on('beforeSheetAdd', () => false);
            this._spreadsheet.events.on('beforeSheetRemove', () => false);
            this._spreadsheet.events.on('beforeSheetRename', () => false);

            this._spreadsheet.events.on("beforeEditStart", () => false);
            this._spreadsheet.events.on("beforeValueChange", () => false);
            this._spreadsheet.events.on("beforeStyleChange", () => false);

            this._spreadsheet.events.on("beforecolumndelete", () => false);
            this._spreadsheet.events.on("beforerowdelete", () => false);
            this._spreadsheet.events.on("beforecolumnadd", () => false);
            this._spreadsheet.events.on("beforerowadd", () => false);
        } else {
            if (this._spreadsheetDescriptor.IsPaged && this._spreadsheetDescriptor.PageField.IsLookup) {
                this.BindLookupPageContextMenuEvents();
            }

            this._spreadsheet.events.on('beforeSheetRemove', sheet => {
                const pageName = this.GetPageName(sheet.id);

                if (pageName === SpreadsheetPageData.GetDefaultPageId() || pageName === SpreadsheetPageData.GetDefaultPageName()) {
                    this._removedPages.push(null);
                    return;
                }

                this._removedPages.push(pageName);
            });

            this._spreadsheet.events.on('beforeSheetRename', sheet => {
                const pageName = this.GetPageName(sheet.id);

                if (pageName === SpreadsheetPageData.GetDefaultPageId() || pageName === SpreadsheetPageData.GetDefaultPageName()) {
                    this._removedPages.push(null);
                    return;
                }

                this._removedPages.push(pageName);
            });

            this._spreadsheet.events.on('afterSheetRename', sheet => {
                if (!this._spreadsheetDescriptor.PageField.IsLookup) {
                    const pageName = this.GetPageName(sheet.id)
                    this._spreadsheet._sheets.update(sheet.id, {
                        pageDbId: pageName,
                        value: pageName
                    });
                }
            });

            this._spreadsheet.events.on('afterSheetAdd', sheet => {
                const newPageId = this._spreadsheet._dataStore._pageName2ID.get(sheet.name);
                const newPage = this._spreadsheet._dataStore.getPage(newPageId);
                const firstPage = this._spreadsheet._dataStore.getPage(0);

                let copiedPageData = JSON.parse(JSON.stringify(firstPage._data));
                _.each(copiedPageData, (data: any) => {
                    _.each(data, (cell: any) => {
                        if (cell && cell.css !== 'origin'){
                            cell.value = null;
                        }
                    });
                });

                newPage._data = copiedPageData;
            });

            this._spreadsheet.events.on('afterEditStart', () => this._isEditing = true);
            this._spreadsheet.events.on('afterEditEnd', () => this._isEditing = false);
            this._spreadsheet.events.on('beforeValueChange', (id, value) => {

            });
            this._spreadsheet.events.on('afterValueChange', (id, value) => {

            });
        }
    }

    private BindLookupPageContextMenuEvents() {
        this._spreadsheet.sheetContextMenu.events.detach('click');
        this._spreadsheet.sheetContextMenu.events.on('click', id => {
            const sheetId = this.GetFocusedSheetId();
            switch (id) {
                case 'delete':
                    this.RemovePage(sheetId);
                    return;
                case 'rename':
                    this.ShowRenamePopup(sheetId);
                    return;
            }
        });
    }

    private RemovePage(sheetId: string) {
        this._spreadsheet.removeSheet(sheetId);
    }

    private ShowRenamePopup(sheetId: string) {
        const pageName = this.GetPageName(sheetId);
        const pageDisplayName = this.GetPageDisplayName(sheetId);

        this._renamePagePopup = new RenamePagePopup(this._spreadsheetDescriptor.PageField, pageName, pageDisplayName);
        this._renamePagePopup.On('PAGE_NAME_SAVE', this, eventArgs => this.RenamePageTo(sheetId, eventArgs.data));

        this._renamePagePopup.Show();
    }

    private RenamePageTo(sheetId: string, pageName: PageName) {
        const currentPageDisplayName = this.GetPageDisplayName(sheetId);
        if (currentPageDisplayName === pageName.PageDisplayName) {
            return;
        }

        const nameIsNotUnique = !!this._spreadsheet.getSheets().find(sheet => {
            const pageDisplayName = this.GetPageDisplayName(sheet.id);
            return pageDisplayName === pageName.PageDisplayName;
        });
        if (nameIsNotUnique) {
            this._renamePagePopup.SetError(`A sheet ${pageName.PageDisplayName} already exists`);
            return;
        }

        this._spreadsheet._renameSheet(sheetId, pageName.PageDisplayName);
        this._spreadsheet._sheets.update(sheetId, {pageDbId: pageName.PageName, value: pageName.PageDisplayName});

        this._renamePagePopup.Close();
    }

    ApplyProperties(){}

    private InitForView() {
        this.Error = ko.observable(null);
        this.InitFields();
        this.ApplyRuntimeProperties();
    }

    private InitForEdit() {
        this.Error = ko.observable(null);
        this.InitFields();
        this.ApplyRuntimeProperties();
    }

    private InitForDesign() {
        this.InitFields();
        this.ApplyDesignProperties();
    }

    private InitFields() {
        this.Dimension1 = ko.observable(null);
        this.Dimension1Translated = ko.observable(null);

        this.Dimension2 = ko.observable(null);
        this.Dimension2Translated = ko.observable(null);

        this.Dimension3 = ko.observable(null);
        this.Dimension3Translated = ko.observable(null);

        this.Dimension4 = ko.observable(null);
        this.Dimension4Translated = ko.observable(null);

        this.PageField = ko.observable(null);
        this.PageFieldTranslated = ko.observable(null);

        this.ValueField = ko.observable(null);
        this.ValueFieldTranslated = ko.observable(null);
    }

    private ApplyDesignProperties() {
        const dimensionsProperty = this.GeneralProperties.GetPropertyValue('Dimensions');

        const valueField = dimensionsProperty && dimensionsProperty.ValueField;
        const pageField = dimensionsProperty && dimensionsProperty.PageField;
        const dimensions = dimensionsProperty && dimensionsProperty.DimensionFields || [];

        this.ValueField(valueField ? valueField.Name : 'Value');
        this.PageField(pageField ? pageField.Name : null);

        this.Dimension1(this.GetDimensionFieldName(dimensions, 0));
        this.Dimension2(this.GetDimensionFieldName(dimensions, 1));
        this.Dimension3(this.GetDimensionFieldName(dimensions, 2));
        this.Dimension4(this.GetDimensionFieldName(dimensions, 3));
    }

    private ApplyRuntimeProperties() {
        const dimensionsProperty = this.GeneralProperties.GetPropertyValue('Dimensions');

        const valueField = dimensionsProperty && dimensionsProperty.ValueField;
        const pageField = dimensionsProperty && dimensionsProperty.PageField;
        const dimensions = dimensionsProperty && dimensionsProperty.DimensionFields || [];

        this.ValueField(valueField.Name);
        this.PageField(pageField ? pageField.Name : null);

        if (dimensions.length > 0) {
            this.Dimension1(this.GetDimensionFieldName(dimensions, 0));
            this.Dimension2(this.GetDimensionFieldName(dimensions, 1));
            this.Dimension3(this.GetDimensionFieldName(dimensions, 2));
            this.Dimension4(this.GetDimensionFieldName(dimensions, 3));
        }
    }

    private GetDimensionFieldName(dimensions: DimensionalTablePropertyField[], index: number) {
        if (dimensions.length === 0) {
            return 'Dimension ' + (index + 1);
        }

        if (!dimensions[index]) {
            return null;
        }

        return dimensions[index].Name;
    }

    private LoadData() {
        const entityRecordId = this.GetForm().GetScreen().GetRecordId();

        if (!entityRecordId) {
            this.LoadEmptySpreadsheet();
            return;
        }

        this.LoadSpreadsheetData();
    }

    private LoadEmptySpreadsheet() {
        BlockUI.Block({Target: this._el});
        this._store.GetSpreadsheetDescriptor(this.GetRequestSpreadsheetDescriptor())
            .then((descriptorDto: SpreadsheetDescriptorDto) => {
                const descriptor = this._viewMappings.ToSpreadsheetDescriptor(descriptorDto);
                const emptyPage = SpreadsheetPageData.Empty(descriptor);
                const spreadsheetData = new SpreadsheetData([emptyPage]);

                this._spreadsheetDescriptor = descriptor;
                this.DisplaySpreadsheet(spreadsheetData);
                BlockUI.Unblock(this._el);
                this.ForceFocusOnTextControl();
            }).fail(error => {
            BlockUI.Unblock(this._el);
            new Notifier().Failed(error.message);
        });
    }

    ForceFocusOnTextControl(){
        if(this._renderMode() === RenderModes.Edit) {

            let isFocusableControl = _.find(this._form.GetScreen().GetAllControls(), c => c.IsFocusable());
            isFocusableControl && isFocusableControl.ForceFocus();

        }
    }

    private LoadSpreadsheetData() {
        BlockUI.Block({Target: this._el});
        this._store.GetSpreadsheet(this.GetRequestSpreadsheetParams())
            .then((spreadsheet: SpreadsheetDataDto) => {
                const descriptor = this._viewMappings.ToSpreadsheetDescriptor(spreadsheet.Descriptor);
                const pages = this._viewMappings.ToPagesData(spreadsheet.Pages, descriptor);
                const spreadsheetData = new SpreadsheetData(pages);

                if (spreadsheetData.Pages.length === 0) {
                    spreadsheetData.Pages.push(SpreadsheetPageData.Empty(descriptor));
                }

                this._spreadsheetDescriptor = descriptor;
                this.DisplaySpreadsheet(spreadsheetData);
                BlockUI.Unblock(this._el)

                this.ForceFocusOnTextControl();
            })
            .fail(error => {
                BlockUI.Unblock(this._el);
                new Notifier().Failed(error.message);
            });
    }

    private DisplaySpreadsheet(spreadsheetData: SpreadsheetData) {
        if (this._spreadsheetDescriptor.IsView) {
            this._readonly = true;
        }

        const pageDataSets = spreadsheetData.Pages.map(page => this.BuildPageDataset(page));
        const spreadsheetDataset = {
            sheets: spreadsheetData.Pages.map((page, index) => ({
                name: this._spreadsheetDescriptor.PageField ? page.Descriptor.PageDisplayName : SpreadsheetPageData.GetDefaultPageName(),
                id: Guid.NewGuid(),
                data: pageDataSets[index].data
            }))
        };

        const dataset = {styles: this._styles, ...spreadsheetDataset};
        this._spreadsheet.parse(dataset);

        dataset.sheets.forEach((sheet, index) => {
            this._spreadsheet._dataStore.getPage(index).setMeta({toolbarId: sheet.id});
            if (this._spreadsheetDescriptor.PageField) {
                this._spreadsheet._sheets.update(sheet.id, {pageDbId: spreadsheetData.Pages[index].Descriptor.PageName});
            }
        });

        this.TranslateLegend();
        this.BindEvents();
    }

    private TranslateLegend() {
        this.ValueFieldTranslated(this._spreadsheetDescriptor.ValueField.TranslatedName || this._spreadsheetDescriptor.ValueField.Name);

        if (this._spreadsheetDescriptor.PageField) {
            this.PageFieldTranslated(this._spreadsheetDescriptor.PageField.TranslatedName || this._spreadsheetDescriptor.PageField.Name);
        }

        const dimensionObservables = [this.Dimension1Translated, this.Dimension2Translated, this.Dimension3Translated, this.Dimension4Translated];
        this._spreadsheetDescriptor.Dimensions.forEach((dimension) => {
            const observableIndex = dimension.Number - 1;
            dimensionObservables[observableIndex](dimension.Field.TranslatedName || dimension.Field.Name);
        });

        this.IsLegendReady(true);
    }

    private GetHorizontalDimensionFields() {
        return [this.Dimension1(), this.Dimension3()].filter(fieldName => !!fieldName);
    }

    private GetVerticalDimensionFields() {
        return [this.Dimension2(), this.Dimension4()].filter(fieldName => !!fieldName);
    }

    private GetEntityRecordId() {
        return this.GetForm().GetScreen().GetRecordId();
    }

    private GetRequestSpreadsheetDescriptor() {
        return {
            TableId: this.GetFieldModel().EntityId,
            HorizontalDimensionFields: this.GetHorizontalDimensionFields(),
            VerticalDimensionFields: this.GetVerticalDimensionFields(),
            ValueField: this.ValueField(),
            PageField: this.PageField(),
        }
    }

    private GetRequestSpreadsheetParams() {
        return {
            TableId: this.GetFieldModel().EntityId,
            EntityRecordId: this.GetEntityRecordId(),
            HorizontalDimensionFields: this.GetHorizontalDimensionFields(),
            VerticalDimensionFields: this.GetVerticalDimensionFields(),
            ValueField: this.ValueField(),
            PageField: this.PageField(),
        }
    }

    private BuildPageDataset(page: SpreadsheetPageData) {
        this.AddStylesToPage(page);
        this.AddOrigin(page);

        return {
            data: page.Cells
        };
    }

    private AddStylesToPage(page: SpreadsheetPageData) {
        page.Dimensions.forEach(dimension => {
            if (dimension.Direction === SpreadsheetDimensionDirections.Horizontal) {
                this.AddStylesToHorizontalDimension(page, dimension);
            } else {
                this.AddStylesToVerticalDimension(page, dimension);
            }
        });

        this.AddStylesToValueCells(page);
    }

    private GetCellsData(sheetId: string, origin: SpreadsheetCellPosition): SpreadsheetRecordDto[] {
        const fields =
            _.chain(this.GetHorizontalDimensionFields())
                .zip(this.GetVerticalDimensionFields())
                .flatten()
                .filter(field => !!field).value();

        const dimensionIndexes = Array.from(Array(fields.length).keys());
        const dimensionRanges = dimensionIndexes.map(index => this.GetDimensionRange(sheetId, index + 1, origin));

        const dimension1Range = dimensionRanges[0];
        const dimension2Range = dimensionRanges[1];

        const valuesBlockStart = new SpreadsheetCellPosition(dimension1Range.Start.Column, dimension1Range.Start.Row + 1);
        const valuesBlockEnd = fields.length > 1
            ? new SpreadsheetCellPosition(dimension1Range.End.Column, dimension2Range.End.Row)
            : new SpreadsheetCellPosition(dimension1Range.End.Column, dimension1Range.End.Row + 1);

        const valuesBlockRange = new SpreadsheetCellRange(valuesBlockStart, valuesBlockEnd);

        const cellsRewrite: SpreadsheetRecordDto[] = [];

        this.EachCell(sheetId, (rowIndex, columnIndex, cell) => {
            const cellPosition = new SpreadsheetCellPosition(SpreadsheetCellPosition.GetColumnNameByNumber(columnIndex + 1), rowIndex + 1);
            if (!valuesBlockRange.ContainsCell(cellPosition)) {
                return;
            }

            if (cell.value === null) {
                return;
            }

            if (cell.value.trim && cell.value.trim() === '') {
                return;
            }

            const dimensionRewrite = _.chain(dimensionIndexes)
                .map((index) => this.BuildDimensionRewrite(sheetId, index + 1, dimensionRanges[index], cellPosition, fields))
                .uniq(dimension => dimension.FieldName)
                .value();

            const cellRewrite = new SpreadsheetRecordDto(cell.value, dimensionRewrite);

            cellsRewrite.push(cellRewrite);
        }, valuesBlockRange.RangeName);

        return cellsRewrite;
    }

    private BuildDimensionRewrite(sheetId: string, dimensionNumber: number, dimensionRange: SpreadsheetCellRange, valueCellPosition: SpreadsheetCellPosition, fields: string[]) {
        const direction = this.GetDimensionDirection(dimensionNumber);
        const dimensionCellPosition = direction === SpreadsheetDimensionDirections.Horizontal
            ? new SpreadsheetCellPosition(valueCellPosition.Column, dimensionRange.Start.Row)
            : new SpreadsheetCellPosition(dimensionRange.Start.Column, valueCellPosition.Row);

        const dimensionValue = this.GetCellValue(sheetId, dimensionCellPosition);
        const fieldName = fields[dimensionNumber - 1];

        return new SpreadsheetRecordFieldDto(fieldName, dimensionValue);
    }

    private GetDimensionDirection(dimensionNumber: number) {
        return dimensionNumber % 2 === 0 ? SpreadsheetDimensionDirections.Vertical : SpreadsheetDimensionDirections.Horizontal;
    }

    private AddOrigin(page: SpreadsheetPageData) {
        const originCell = new SpreadsheetCellData(this._spreadsheetDescriptor.Origin.PositionName, this._originCellValue, null);
        originCell.css = 'origin';

        page.Cells.push(originCell);
    }

    private GetOriginPosition(sheetId: string) {
        const start = new SpreadsheetCellPosition('A', 1);
        const end = new SpreadsheetCellPosition(SpreadsheetCellPosition.GetColumnNameByNumber(this._colsCount), this._rowsCount);
        const searchRange = `${start.PositionName}:${end.PositionName}`;

        let rowNumber: number = null, columnNumber: number = null;
        this.EachCell(sheetId, (rowIndex, columnIndex, cell) => {
            if (cell.value === this._originCellValue) {
                rowNumber = rowIndex + 1;
                columnNumber = columnIndex + 1;
            }
        }, searchRange);

        if (rowNumber === null || columnNumber === null) return null;
        return new SpreadsheetCellPosition(SpreadsheetCellPosition.GetColumnNameByNumber(columnNumber), rowNumber);
    }

    private GetDimensionRange(sheetId: string, dimensionNumber: number, origin: SpreadsheetCellPosition): SpreadsheetCellRange {
        const dimensionStyles = this.GetStylesForDimension(dimensionNumber);

        const start = this.GetDimensionStart(sheetId, origin, dimensionStyles);
        if (!start) return null;

        const end = this.GetDimensionEnd(sheetId, start, dimensionStyles);
        return new SpreadsheetCellRange(start, end);
    }

    private GetDimensionStart(sheetId: string, origin: SpreadsheetCellPosition, dimensionStyles: any) {
        const end = this.GetEndOfSpreadsheetPosition();
        let searchRange = `${origin.PositionName}:${end.PositionName}`;

        let dimensionStartCell = null;
        this.EachCell(sheetId, (rowIndex, columnIndex, cell) => {
            if (dimensionStartCell) {
                return;
            }

            const cellPosition = new SpreadsheetCellPosition(SpreadsheetCellPosition.GetColumnNameByNumber(columnIndex + 1), rowIndex + 1);
            const cellStyles = this.GetStyle(sheetId, cellPosition) as any;
            if (cellStyles && cellStyles.background === dimensionStyles.background) {
                dimensionStartCell = cellPosition;
            }
        }, searchRange);

        return dimensionStartCell;
    }

    private GetDimensionEnd(sheetId: string, start: SpreadsheetCellPosition, dimensionStyles: any) {
        const end = this.GetEndOfSpreadsheetPosition();
        let searchRange = `${start.PositionName}:${end.PositionName}`;

        let dimensionEndCell = null;
        this.EachCell(sheetId, (rowIndex, columnIndex, cell) => {
            const cellPosition = new SpreadsheetCellPosition(SpreadsheetCellPosition.GetColumnNameByNumber(columnIndex + 1), rowIndex + 1);
            const cellStyles = this.GetStyle(sheetId, cellPosition) as any;
            if (cellStyles && cellStyles.background === dimensionStyles.background) {
                dimensionEndCell = cellPosition;
            }
        }, searchRange);

        return dimensionEndCell;
    }

    private GetEndOfSpreadsheetPosition() {
        return new SpreadsheetCellPosition(SpreadsheetCellPosition.GetColumnNameByNumber(this._colsCount), this._rowsCount);
    }

    private AddStylesToHorizontalDimension(page: SpreadsheetPageData, dimension: SpreadsheetDimensionDescriptor) {
        const iterableCell = new SpreadsheetCellPosition(dimension.StartPosition.Column, dimension.StartPosition.Row);
        const endCell = new SpreadsheetCellPosition(SpreadsheetCellPosition.GetColumnNameByNumber(this._colsCount), iterableCell.Row);
        const endCellColumnNumber = endCell.GetColumnNumber();

        while (iterableCell.GetColumnNumber() <= endCellColumnNumber) {
            const valueCell = page.GetCell(iterableCell.PositionName);
            if (valueCell) {
                valueCell.css = `dimension${dimension.Number}`;
            } else {
                const cell = new SpreadsheetCellData(iterableCell.PositionName, null, dimension.Number);
                cell.css = `dimension${dimension.Number}`;
                page.Cells.push(cell);
            }
            iterableCell.Column = iterableCell.GetNextColumnName();
        }
    }

    private AddStylesToVerticalDimension(page: SpreadsheetPageData, dimension: SpreadsheetDimensionDescriptor) {
        const iterableCell = new SpreadsheetCellPosition(dimension.StartPosition.Column, dimension.StartPosition.Row);
        const endCell = new SpreadsheetCellPosition(dimension.StartPosition.Column, this._rowsCount);

        while (iterableCell.Row <= endCell.Row) {
            let valueCell = page.GetCell(iterableCell.PositionName);
            if (valueCell) {
                valueCell.css = `dimension${dimension.Number}`;
            } else {
                valueCell = new SpreadsheetCellData(iterableCell.PositionName, null, dimension.Number);
                valueCell.css = `dimension${dimension.Number}`;
                page.Cells.push(valueCell);
            }
            iterableCell.Row++;
        }
    }

    private AddStylesToValueCells(page: SpreadsheetPageData) {
        const dimension1Start = page.Dimensions.find(d => d.Number === 1).StartPosition;
        const valuePosition = new SpreadsheetCellPosition(dimension1Start.Column, dimension1Start.Row + 1);

        while (valuePosition.Row <= this._rowsCount) {
            while (valuePosition.GetColumnNumber() <= this._colsCount) {
                let valueCell = page.GetCell(valuePosition.PositionName);

                if (valueCell) {
                    valueCell.css = 'value';
                } else {
                    valueCell = new SpreadsheetCellData(valuePosition.PositionName, null, null);
                    valueCell.css = 'value';
                    page.Cells.push(valueCell);
                }

                valuePosition.Column = valuePosition.GetNextColumnName();
            }

            valuePosition.Column = dimension1Start.Column;
            valuePosition.Row++;
        }
    }

    private GetStylesForDimension(dimensionNumber: number) {
        return this._styles['dimension' + dimensionNumber];
    }

    private GetCellValue(sheetId: string, position: SpreadsheetCellPosition) {
        return this.GetPage(sheetId).getValue(position.GetRowIndex(), position.GetColumnIndex());
    }

    private GetStyle(sheetId: string, position: SpreadsheetCellPosition) {
        const pageName = this.GetPageDisplayName(sheetId);

        let absoluteCell = `${pageName}!${position.PositionName}`;
        let style = this._spreadsheet.getStyle(absoluteCell);

        if (!style) {
            absoluteCell = `'${pageName}'!${position.PositionName}`;
            style = this._spreadsheet.getStyle(absoluteCell);
        }

        return style;
    }

    private EachCell(sheetId: string, iterator: (rowIndex: number, columnIndex: number, cell: any) => void, searchRange: string) {
        this.GetPage(sheetId).eachCell(iterator, searchRange);
    }

    private GetPage(sheetId: string) {
        return this._spreadsheet._dataStore._pages
            .filter(page => !!page)
            .find(page => page.getMeta().toolbarId === sheetId);
    }

    private GetPageName(sheetId: string): string {
        if (this._spreadsheetDescriptor.IsPaged && this._spreadsheetDescriptor.PageField.IsLookup) {
            return this._spreadsheet._sheets._pull[sheetId].pageDbId;
        }

        return this._spreadsheet.getSheets().find(sheet => sheet.id === sheetId).name;
    }

    private GetPageDisplayName(sheetId: string) {
        if (this._spreadsheetDescriptor.IsPaged && this._spreadsheetDescriptor.PageField.IsLookup) {
            return this._spreadsheet._sheets._pull[sheetId].value;
        }

        return this._spreadsheet.getSheets().find(sheet => sheet.id === sheetId).name;
    }

    private GetFocusedSheetId() {
        return this._spreadsheet._tabbar.data.getItem(this._spreadsheet._focusedSheet).id;
    }
}