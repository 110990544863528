import {deserialize, deserializeAs} from 'libs/cerialize';
import {Guid} from 'Core/Common/Guid';
import {AttachmentModel} from 'Core/Controls/Image/Models/AttachmentModel';

export class FileModel {
	constructor() {
		this.UniqueId = Guid.NewGuid();
		this.ImageData = null;
		this.Name = null;
		this.OriginalImageData = null;
		this.CacheFile = null;
		this.Annotations = null;
		this.TempName = null;
		this.TempOriginalName = null;
	}

	UniqueId: string;

	@deserialize
	ImageData: string;

	@deserialize
	Name: string;

	@deserialize
	Size: number;

	@deserialize
	OriginalImageData: string;

	@deserialize
	Id: number | string;

	@deserialize
	Annotations: string;

	@deserialize
    TempName?: string;

    @deserialize
    TempOriginalName?: string;

	Attachment: AttachmentModel;

	CacheFile?: any;
}

export class ImageDataModel {

	constructor() {
		this.Files = [];
	}

	@deserializeAs(FileModel)
	Files: Array<FileModel>;
}