import { deserialize, deserializeAs } from 'libs/cerialize'
import {KindModel} from "./KindModel";

export class TableTypeModel {
	@deserialize
	Id: number;

	@deserialize
    Name: string;

    @deserialize
    TranslatedName: string;

	@deserialize
	LifeStatus: number;

	@deserialize
	IsEnabled: boolean;

	@deserializeAs(KindModel)
    Kinds: KindModel[];

    GetName(): string {
        return this.TranslatedName ? this.TranslatedName : this.Name
    }
}