import * as ko from "knockout";
import * as _ from "underscore";
import {BaseProperty, IPropertyDescription, IPropertyOption} from "Core/GeneralProperties/Managers/BaseProperty";

import WebSizePropertyTemplate
    from "Core/GeneralProperties/Managers/WebSizeProperty/Templates/WebSizeProperty.html";

ko.templates["Core/GeneralProperties/Managers/WebSizeProperty/Templates/WebSizeProperty"] = WebSizePropertyTemplate;

export interface IWebSizeValue {
    Value: number;
    Unit: {
        Name: string;
        Value: any;
    }
}

export class WebSizeProperty extends BaseProperty{
    private _options: KnockoutObservableArray<IPropertyOption>;

    constructor(property: IPropertyDescription, propertyValue: IWebSizeValue) {
        super(property);
        this.Value = ko.observable(propertyValue || this.GetDefaultValue());

        this._options = ko.observableArray([]);

        this.Init(property.Options || [], propertyValue);
    }

    GetTemplateName(): string {
        return "Core/GeneralProperties/Managers/WebSizeProperty/Templates/WebSizeProperty";
    }

    private Init(options: IPropertyOption[], propertyValue: IWebSizeValue) {
        this.SetOptions(options);
        this.SetValue(propertyValue);
    }

    private SetOptions(options: IPropertyOption[]) {
        this._options(options);
    }

    get Options(): KnockoutObservableArray<IPropertyOption> {
        return this._options;
    }

    SetValue(propertyValue: IWebSizeValue): void {
        if (propertyValue) {
            if (propertyValue.Unit){
                propertyValue.Unit = _.find(this._options(), option => option.Value === propertyValue.Unit.Value);
            } else {
                let unit = _.find(this._options(), option => option.Value === propertyValue.Value)
                propertyValue = {
                    Value: 100,
                    Unit: unit
                }
            }

            this.Value(propertyValue);
        }
    }

    GetDefaultValue(): any {
        return {
            Value: 100,
            Unit: '%'
        };
    }

}