import {deserialize} from 'libs/cerialize';
import {deserializeAs} from 'libs/cerialize';
import {serialize} from 'libs/cerialize';
import {serializeAs} from 'libs/cerialize';

export class LanguageModel {
	@deserialize
	@serialize
	K_Language: number;

	@deserialize
	@serialize
	Name: string;

	@deserialize
	@serialize
	ShortName: string;

	@deserialize
	@serialize
	Enabled: number;

	@deserialize
	FlagBase64: string;

	constructor(k_Language?: number, name?: string, shortName?: string, enabled?: number, flagBase64?: string) {
		this.K_Language = k_Language;
		this.Name = name;
		this.ShortName = shortName;
		this.Enabled = enabled;
		this.FlagBase64 = flagBase64;
	}
}