import * as _ from "underscore";
import * as moment from "moment";
import clone from 'clone';

import {DATE_FORMATS} from "Core/Constants/DateTimeFormats";
import {HeaderColumn, Period} from "../Models/View/SchedulerViewModel";

export class HeaderColumnMapping {
    static OnViewModel(startDate: moment.Moment, endDate: moment.Moment, periods: Period[], screen: string): HeaderColumn[] {
        const days: HeaderColumn[] = [];
        const daysAmount = endDate.diff(startDate, 'days');

        const starting = startDate.clone();

        for (let i = 0; i <= daysAmount; i++) {
            const dayPeriods = HeaderColumnMapping.ConvertPeriodsToWeekDay(periods, starting.clone());
            days.push(new HeaderColumn(starting.clone(), dayPeriods, screen));
            starting.add(1, 'd');
        }

        return days;
    }

    static ConvertPeriodsToWeekDay(periods: Period[], day: moment.Moment) {
        const dayPeriods = _.map(periods, item => clone(item));

        _.forEach(dayPeriods, (period) => {
            let date = day.clone().format(DATE_FORMATS.SHORT_DATE.Format);
            if (moment(period.Start).isValid()) {
                period.Start = moment(date + " " + moment(period.Start).format(DATE_FORMATS.TIME.Format)).format(DATE_FORMATS.DATE_TIME.Format);
            } else {
                period.Start = moment(date + " " + period.Start).format(DATE_FORMATS.DATE_TIME.Format);
            }
        });

        return dayPeriods;
    }
}