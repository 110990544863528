import * as ko from "knockout";

import {BaseProperty, IPropertyDescription} from "Core/GeneralProperties/Managers/BaseProperty";

import {ColorModel} from "Core/Components/ColorSelector/ColorModels/ColorModel";
import {HexColorModel} from "Core/Components/ColorSelector/ColorModels/Hex/HexColorModel";

import ColorPickerTemplate from "Core/GeneralProperties/Managers/ColorPicker/Templates/ColorPicker.html";

ko.templates["Core/GeneralProperties/Managers/ColorPicker/Templates/ColorPicker"] = ColorPickerTemplate;

export class ColorPicker extends BaseProperty {
    private _colorModel: ColorModel;

    constructor(property: IPropertyDescription, propertyValue: string) {
        super(property);
        this.Value = ko.observable(propertyValue || this.GetDefaultValue());
        this.Init();
    }

    GetTemplateName(): string {
        return "Core/GeneralProperties/Managers/ColorPicker/Templates/ColorPicker";
    }

    GetDefaultValue() {
        return '';
    }

    SetValue(propertyValue: string): void {
        this.Value(propertyValue);
        this._colorModel.SetColor(this.Value());
    }

    private Init() {
        this._colorModel = new HexColorModel({Label: this.Name, InitialColor: this.Value()});

        this._colorModel.On("COLOR_SET", this, eventArgs => this.Value(eventArgs.data.Name));
        this._colorModel.On('COLOR_RESET', this, () => this.Value(null));
    }
}

