import {deserialize} from 'libs/cerialize';
import {deserializeAs} from 'libs/cerialize';

export class UserVarsRecordModel {

	constructor() {
	}

	@deserialize
	Id: number;

	@deserialize
	TypeId: number;

	@deserialize
	Name: string;

	@deserialize
	TypeName: string;
}