export default function (fileName) {

	if (fileName.length > 80) {
		const extension = fileName.substring(fileName.lastIndexOf('.'), fileName.length);
		const name = fileName.substring(0, 80 - extension.length);

		return name + extension;
	}

	return fileName;
}