import {GridRowDataModel} from 'Core/Controls/Grid/Models/UpdateDataModel/UpdateDataModel';
import {UpdateDataModel} from 'Core/Controls/LinkList/Models/UpdateDataModel';

export class FieldDataModel {
    constructor() {
        this.FieldValue = [];
    }

    FieldId: number;
    FieldValue: Array<string>;
}

export class GridDataModel {
    Rows: GridRowDataModel[];
    LinkListChanges: UpdateDataModel[];
}

export class DataModel {
    constructor() {
        this.FieldValues = [];
        this.Grids = [];
    }

    FieldValues: Array<FieldDataModel>;
    Grids: GridDataModel[];

    EntityId: number;
    RecordId: number;
    KSeq?: number;
}