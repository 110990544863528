import * as ko from 'knockout';
import * as _ from 'underscore';

import {Event} from 'Core/Common/Event';
import {SubTableViewDto} from "../Store/Models/SubTableViewDto";
import {TranslationModel} from "Core/Controls/BaseControl/Models/TranslationModel";
import {TranslationManager} from "Core/Components/Translation/TranslationManager";
import {LanguageModel} from "Core/Controls/BaseControl/Models/LanguageModel";

export class ViewModel extends Event {
    BackLinkSettings: KnockoutObservableArray<BackLinkingSettings>;
    SubTableViews: SubTableView[];

    constructor(propertyValue: any[], subTableViews: SubTableViewDto[]) {
        super();

        this.SubTableViews = this.MapSubTableViews(subTableViews);

        const settings = this.MapSettings(propertyValue);
        settings.forEach(s => s.On('SETTINGS_UPDATED', this, () => this.PublishChanges()));

        this.BackLinkSettings = ko.observableArray(settings);
    }

    AddSettings() {
        const settings = new BackLinkingSettings({});
        settings.On('SETTINGS_UPDATED', this, () => this.PublishChanges());

        this.BackLinkSettings.push(settings);
        this.PublishChanges();
    }

    RemoveSettings(settings: BackLinkingSettings) {
        const index = this.BackLinkSettings.indexOf(settings);
        this.BackLinkSettings.splice(index, 1);
        this.PublishChanges();
    }

    private MapSettings(propertyValue: any[]) {
        if (!propertyValue) return [];
        return propertyValue.map(settings => BackLinkingSettings.FromJSON(settings, this.SubTableViews));
    }

    private MapSubTableViews(subTableViews: SubTableViewDto[]) {
        return subTableViews.map(view => new SubTableView(view.Id, view.TranslatedName || view.Name));
    }

    private PublishChanges() {
        const changes = {
            Settings: this.BackLinkSettings().map(settings => {
                return {
                    ButtonName: settings.NameTranslations().length && settings.NameTranslations()[0].Translation,
                    SubTableView: settings.SubTableView() && settings.SubTableView().Id,
                    Intention: settings.Intention(),
                    NameTranslations:  settings.NameTranslations().length && _.map(settings.NameTranslations().slice(1), (item: any) => {
                        return {Id: item.Language.K_Language, Value: item.Translation}
                    })
                };
            })
        };

        this.Trigger('SETTINGS_UPDATED', changes);
    }
}

export interface IBackLinkingSettingsOptions {
    ButtonName?: string,
    SubTableView?: SubTableView,
    Intention?: string,
    Translations?: Array<{Id: number, Value: string}>
}

export class BackLinkingSettings extends Event {
    ButtonName: KnockoutObservable<string>;
    SubTableView: KnockoutObservable<SubTableView>;
    Intention: KnockoutObservable<string>;
    NameTranslations: KnockoutObservableArray<TranslationModel>;
    protected SelectedName: KnockoutObservable<TranslationModel>;
    protected NameTranslationsOpened: KnockoutObservable<boolean>;

    constructor(model: IBackLinkingSettingsOptions) {
        super();

        this.ButtonName = ko.observable(model.ButtonName);
        this.ButtonName.subscribe(() => this.PublishChanges());

        this.SubTableView = ko.observable(model.SubTableView);
        this.SubTableView.subscribe(() => this.PublishChanges());

        this.Intention = ko.observable(model.Intention);
        this.Intention.subscribe(() => this.PublishChanges());

        this.NameTranslationsOpened = ko.observable(false);
        this.SelectedName = ko.observable(null);

        this.NameTranslations = ko.observableArray(null);
        this.InitTranslations(model.Translations);

        this.NameTranslations.subscribe(() => this.PublishChanges());
    }

    private InitTranslations(translations) {
        const translationItems = TranslationManager.Instance.Languages.map(language => {
            const model = new TranslationModel();
            model.Language = new LanguageModel(language.Id);
            model.Language.FlagBase64 = language.Flag;
            model.Language.ShortName = language.ShortName;
            model.Language.Name = language.Name;
            model.Translation = '';
            if (!model.Language.K_Language) {
                model.Translation = this.ButtonName();
            }
            return model;
        });

        this.NameTranslations(translationItems);
        this.SelectedName(this.GetNameTranslation());

        if (translations) {
            _.forEach(translations, (item: any) => {
                const current = _.find(this.NameTranslations(), (translation) => translation.Language.K_Language === item.Id);
                if (current) {
                    current.Translation = item.Value;
                }
            });
        }
        this.SetDefaultTranslation();
    }

    private GetNameTranslation() {
		const name = _.find(this.NameTranslations(), language => language.Selected && language.Translation !== '' && language.Translation !== null);
        return name || _.first(this.NameTranslations());
    }


    static FromJSON(settings, subTableViews: SubTableView[]) {
        const subTableView = _.find(subTableViews, view => view.Id === settings.SubTableView);
        return new BackLinkingSettings( {
            ButtonName: settings.ButtonName,
            SubTableView: subTableView,
            Intention: settings.Intention,
            Translations: settings.NameTranslations
        });
    }

    private PublishChanges() {
        this.Trigger('SETTINGS_UPDATED', {
            ButtonName: this.NameTranslations().length && this.NameTranslations()[0].Translation,
            SubTableView: this.SubTableView() && this.SubTableView().Id,
            Intention: this.Intention(),
            Translations: this.NameTranslations().length && _.map(this.NameTranslations().slice(1), (item: any) => {
                return {Id: item.Language.K_Language, Value: item.Translation}
            })
        });
    }

    get DefaultTranslation() {
        return _.find(this.NameTranslations, (item) => item.Language.K_Language === -1);
    }

    ShowNameTranslations() {
        this.NameTranslationsOpened(true);
    }

    ToggleNameTranslations() {
        if (this.NameTranslationsOpened()) {
            this.HideNameTranslations();
        } else {
            this.ShowNameTranslations();
        }
    }

    HideNameTranslations() {
        this.NameTranslationsOpened(false);
    }

    SelectNameTranslation(translationModel: TranslationModel) {
        this.SelectedName(translationModel);
        this.HideNameTranslations();
    }

    UpdateTranslationList() {
        let languages = this.NameTranslations().slice();
        this.NameTranslations([]);
        this.NameTranslations(languages);
    }

    ChangeTranslation() {
        let current = _.find(this.NameTranslations(), (item) => {
            return item.Language.K_Language === this.SelectedName().Language.K_Language;
        });
        this.SelectedName(current);
        this.NameTranslations.valueHasMutated();
    }

    private SetDefaultTranslation() {
        const defLang = TranslationManager.Instance.GetCurrentLanguage();
        let defTranslation = _.find(this.NameTranslations(), (translation) => translation.Language.K_Language === defLang.Id);
        if (defTranslation && defTranslation.Translation) {
            this.SelectedName(defTranslation);
        }
    }
}

export class SubTableView {
    constructor(public Id: number, public Name: string) {
    }
}