import { deserialize, deserializeAs } from "libs/cerialize";
import { LIfecycleLinkDto } from "./LifecycleLinkDto";
import { LifecycleStepDto } from "./LifecycleStepDto";

export class LifecycleDto {
    @deserializeAs(LifecycleStepDto)
    Steps: Array<LifecycleStepDto>;

    @deserializeAs(LIfecycleLinkDto)
    Links: Array<LIfecycleLinkDto>;

    @deserialize
    IsModified: boolean;
}