import * as ko from "knockout";

import {Event} from 'Core/Common/Event';
import {EVENTS} from 'Core/Controls/Grid/BaseGrid/Events';

import Template from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/SelectRow.html';
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/SelectRow'] = Template;

export class SelectRowCell extends Event{
    constructor() {
        super();
        this.AddEvent(EVENTS.SELECT_RECORD);
    }

    SelectRow(){
        this.Trigger(EVENTS.SELECT_RECORD);
    }

    GetTemplateName(): string {
        return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/SelectRow';
    }
}