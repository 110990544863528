import { Modal } from "Core/Common/Modal";
import * as ko from 'knockout';
import Template from 'Core/Controls/FunctionDesigner/Templates/FunctionResult.html';
import {FunctionExecutionModel, ParamValueModel} from 'Core/Controls/FunctionDesigner/Models/FunctionExecutionModel';

ko.templates['Core/Controls/FunctionDesigner/Templates/FunctionResult'] = Template;

export class FunctionResult {
	private _model: FunctionExecutionModel;
	private _allRows: KnockoutObservableArray<Array<ParamValueModel>>;
	private _paginatedRows: KnockoutComputed<Array<Array<ParamValueModel>>>;
	private _recordsPerPage: number;
	private _pageNumber: KnockoutObservable<number>;
	private _totalPages: KnockoutComputed<number>;
	private _hasPrevious: KnockoutComputed<boolean>;
	private _hasNext: KnockoutComputed<boolean>;
	private _paginatorInputWidth: KnockoutComputed<string>;

	constructor(model: FunctionExecutionModel) {
		this._model = model;
		this._allRows = ko.observableArray(model.Data);
		this._recordsPerPage = 20;
		this._pageNumber = ko.observable(0);
		let self = this;
		this._paginatedRows = ko.computed(() => {
			let first = this._pageNumber() * this._recordsPerPage;
			return this._allRows.slice(first, first + this._recordsPerPage);
		});

		this._totalPages = ko.computed(() => {
			let div = Math.floor(self._allRows().length / self._recordsPerPage);
			div += self._allRows().length % self._recordsPerPage > 0 ? 1 : 0;
			return div - 1;
		});

		this._hasPrevious = ko.computed(function() {
			return self._pageNumber() !== 0;
		});

		this._hasNext = ko.computed(function() {
			return self._pageNumber() !== self._totalPages();
		});


		this._paginatorInputWidth = ko.computed({
			owner: this,
			read: () => {
				return `${( self._pageNumber().toString().length + 1)*7}px`;
			}
		});
	}

	ShowInModal() {
		let modal = new Modal({
			addClass: 'executeModal'
		});
		ko.cleanNode(modal.Wrapper);
		ko.applyBindings(this, modal.Wrapper);
		modal.Show();
	}

	GetTemplateName() {
		return 'Core/Controls/FunctionDesigner/Templates/FunctionResult';
	}

	get Model() {
		return this._model;
	}

	Next()  {
		if(this._pageNumber() < this._totalPages()) {
			this._pageNumber(this._pageNumber() + 1);
		}
	}

	Previous() {
		if(this._pageNumber() != 0) {
			this._pageNumber(this._pageNumber() - 1);
		}
	}

	AfterRender() {
	}
}