import { P } from "Core/Common/Promise";
import { URL } from "Core/Common/Url";
import { IRequestOptions, Request } from "Core/Common/Request";

export class UserVarsStore {
	static UpdateUserVars(model): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: "api/UserVariablesApi/UpdateUserVars",
				dataType: 'json',
				type: 'POST'
			},
			params: model
		};
		Request.Send(requestParams)
			.then(data =>
				deferredResult.resolve(data));

		return deferredResult.promise();

	}

} 

