import {deserialize, deserializeAs, serialize, serializeAs} from "libs/cerialize";
import { LifeStatusInfo } from "./LifeStatusInfo";
import { RecordExampleModel } from "./RecordExampleModel";
import { RecordPersonalizationModel } from "./RecordPersonalizationModel";

export interface IRecordSpecsParams {
	RecordPersonalization: RecordPersonalizationModel;
	LifeStatusInfo: LifeStatusInfo;
	RecordExampleInfo: RecordExampleModel;
}

export class RecordSpecsModel {
	@deserialize
	IsNewRecord: boolean;

	@deserializeAs(RecordPersonalizationModel)
	RecordPersonalization: RecordPersonalizationModel;

	@deserializeAs(RecordExampleModel)
	RecordExampleInfo: RecordExampleModel;

	@deserializeAs(LifeStatusInfo)
	LifeStatusInfo: LifeStatusInfo;

	@deserialize
	RecordOwner: number;

	@deserialize
	IsExample: boolean;

	@deserialize
	IsEnabled: boolean;

	@deserialize
	IsDisabled: boolean;

	constructor(params?: IRecordSpecsParams) {
		this.IsNewRecord = true;

		if (params) {
			this.RecordPersonalization = params.RecordPersonalization;
			this.LifeStatusInfo = params.LifeStatusInfo;
			this.RecordExampleInfo = params.RecordExampleInfo;
		}
	}

	private GetDefaultParams(): IRecordSpecsParams {
		return {
			RecordPersonalization: new RecordPersonalizationModel(),
			RecordExampleInfo: new RecordExampleModel(),
			LifeStatusInfo: new LifeStatusInfo()
		}
	}
}
