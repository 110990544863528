import {deserializeAs, deserialize} from 'libs/cerialize';

import {SpreadsheetCellPositionDto} from './SpreadsheetCellPositionDto';

export class SpreadsheetCellDataDto {
    @deserializeAs(SpreadsheetCellPositionDto)
    Position: SpreadsheetCellPositionDto;

    @deserialize
    Value: any;

    @deserialize
    DimensionNumber: number;

    get PositionName() {
        return `${this.Position.Column}${this.Position.Row}`;
    }
}