import * as _ from 'underscore';

export class CachedProduct {
    constructor(public Id: number,
                public GroupId: number,
                public GroupName: string,
                public RootGroupId: number,
                public RootGroupName: string,
                public Path: number[],
                public KSeq: number,
                public KSeqGuid: string) {
    }

    Equals(product: CachedProduct) {
        return this.Id === product.Id
            && this.GroupId === product.GroupId
            && this.GroupName === product.GroupName
            && this.RootGroupId === product.RootGroupId
            && this.RootGroupName === product.RootGroupName
            && _.isEqual(this.Path, product.Path)
            && this.KSeq === product.KSeq
            && this.KSeqGuid === product.KSeqGuid;
    }
}