'use strict';

import * as ko from "knockout";

import {BaseControl} from "Core/Controls/BaseControl/BaseControl";
import {IControlParam} from "Core/Screens/IScreen";

import 'Core/Controls/ButtonTree/Templates/Edit.html';
import ViewTemplate from "Core/Controls/ButtonTree/Templates/View.html";
import HelpViewTemplate from "Core/Controls/ButtonTree/Templates/HelpView.html";
import ToolbarTemplate from "Core/Controls/ButtonTree/Templates/ToolBar.html";
import DesignTemplate from "Core/Controls/ButtonTree/Templates/Design.html";

ko.templates["Core/Controls/ButtonTree/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/ButtonTree/Templates/HelpView"] = HelpViewTemplate;
ko.templates["Core/Controls/ButtonTree/Templates/ToolBar"] = ToolbarTemplate;
ko.templates["Core/Controls/ButtonTree/Templates/Design"] = DesignTemplate;

export class ButtonTree extends BaseControl {

	constructor(params: IControlParam) {
		super(params);
		this.Init();
	}

	private Init(): void {
	}

	ApplyProperties(){}


	Click(): void {
		if (this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
		} 
	}

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}
}