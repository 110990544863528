import * as ko from "knockout"
import * as $ from 'jquery';

export class AddCssForAllExtention {
    static Init() {
        ko.bindingHandlers.addCssForAll = {
            init: (element, valueAccessor) => {
                const $current = $(element);
                const params: AddCssForAllParams = ko.unwrap(valueAccessor());
                $current.on(params.eventAdd, (event) => {
                    $(`.${params.class}`).addClass(params.toggleClass);
                });
                $current.on(params.eventRemove, (event) => {
                    $(`.${params.class}`).removeClass(params.toggleClass);
                });
            }
        }
    }
}

export interface AddCssForAllParams {
    eventAdd: string,
    eventRemove: string,
    class: string,
    toggleClass: string,
}