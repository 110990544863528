import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {URL} from 'Core/Common/Url';

export class IconStore {
    static GetImageIcon(iconId: number): P.Promise<string> {
        const deferredResult = P.defer<string>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: URL.ICON.GET_IMAGE_ICON,
                type: 'GET',
                xhrFields:{
					responseType: 'blob'
				}
            }, params: { IconId: iconId }
        };

        Request.Send(requestParams)
            .then(data =>{
                var blob = new Blob([data], { type: 'application/octet-stream' });
                var urlCreator = window.URL || window.webkitURL;
                var imageUrl = urlCreator.createObjectURL(blob);
                deferredResult.resolve(imageUrl);
            }).fail(data => {});

        return deferredResult.promise();
    }
}