import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

export class ConfigurationPageVariable {
    private _value: any;

    constructor(public Name: string, value: any) {
        this.Value = value;
    }

    @enumerable get Value() {
        return this._value;
    }

    set Value(value: any) {
        this._value = value;
    }
}