import * as ko from 'knockout';

import {FormatConverter} from "FormatEditor/FormatConverter";
import {FieldFormat} from 'Core/Common/FieldFormat';

import {CustomFieldControl} from "../CustomFieldControl";

import Template from './Template.html';
import {ProductCustomFieldDescription} from "../../ProductCustomFieldDescription";
import {CustomFieldValue} from '../../../CustomFieldValue';
import {ProductPart} from '../../../ProductPart';

export class CustomDecimalControl extends CustomFieldControl {
	private _displayValue: KnockoutObservable<string>;

	constructor(customField: ProductCustomFieldDescription, productPart: ProductPart) {
		super(customField, productPart);
		
		this.HasContent = ko.observable(true);
		this._displayValue = ko.observable(null);
	}

	GetTemplate() {
		return Template;
	}

	get DecimalMaskSize() {
		if (FieldFormat.IsPercentage(this.FormatName)) {
			return Math.max(this.Size - 2, 0);
		}

		return this.Size;
	}

	get IsCurrencyIcon() : boolean {
		return FieldFormat.IsCurrency(this.FormatName);
	}

	get IsPercentageIcon() : boolean {
		return !!(this._displayValue() && this._displayValue().length !== 0 && FieldFormat.IsPercentage(this.FormatName));
	}

	GetConvertedValue() {
		const value = this._displayValue();

		let convertedValue = !value && value !== '0' ? null : +value.replace(',', '.');

		convertedValue = isNaN(convertedValue) ? null : convertedValue;

		if (convertedValue && FieldFormat.IsPercentage(this.FormatName)) {
			return (convertedValue / 100).toFixed(this.Size);
		}

		return convertedValue;
	}

	GetValueForSave(): any {
		return new CustomFieldValue(this.Id, this.GetConvertedValue(), this.IsValid(this.GetConvertedValue()));
	}

	SetValue(customFieldValue: CustomFieldValue) {
		let formattedValue = null;

		if (customFieldValue && customFieldValue.Value && FieldFormat.IsPercentage(this.FormatName)) {
			const percentageValue = (Number(String(customFieldValue.Value).replace(',', '.')) * 100)
				.toFixed((this.Size < 2) ? 0 : this.Size - 2);
			formattedValue = FormatConverter.ConvertDecimal(percentageValue);
		}
		else if (customFieldValue && customFieldValue.Value) {
			formattedValue = FormatConverter.ConvertDecimal(customFieldValue.Value.toString());
		}

		this._displayValue(formattedValue);
		this.StartListeningValueChanges();
	}

	SetDefaultValue() {
		const defaultFieldValue = new CustomFieldValue(this.Id, this.DefaultValue(), this.IsValid(true));
		this.SetValue(defaultFieldValue);
	}

	ClearValue() {
		this._displayValue(null);
	}

	private StartListeningValueChanges() {
		if (this._displayValue.getSubscriptionsCount() == 0) {
			this._displayValue.subscribe(() => {
				this.CheckValidControl();
				this.OnValueChanged();
			});
		}
	}

	IsValid(value: any): boolean {
		return this.GetDecimalIntegerValid(value);
	}

	CheckValidControl() {
		this.isValidControl(this.GetDecimalIntegerValidRules(this.GetConvertedValue()));
	}
}