export class BusinessRole {
    constructor(
        public Id: number,
        public Name: string,
        public TranslatedName: string) {
    }

    get DisplayName() {
        return this.TranslatedName || this.Name;
    }
}