//Common
import * as ko from 'knockout';
import * as $ from 'jquery';
import * as _ from "underscore";
import 'tree';

import {P} from "Core/Common/Promise";

import {Event} from 'Core/Common/Event';
import {Modal} from 'Core/Common/Modal';

import {CONFIRMATIONS, LABELS} from "../../Components/Translation/Locales";
import {IControlParam} from "../../Screens/IScreen";
import {RecipeDataModel} from "./Models/RecipeDataModel";
import {GetRecipeDataDto} from "./Models/Dto/GetRecipeDataDto";
import {RecipeEditorStore} from "./Stores/RecipeEditorStore";
import {IGetNewDataParams, RecipeEditorTreeUnit} from "Core/Controls/RecipeEditor/RecipeEditorTree/RecipeEditorTreeUnit";
import {RecipeRecordModel} from "Core/Controls/RecipeEditor/RecipeEditorTree/Response/RecipeRecordModel";
import {RecipeEditorTree} from "./RecipeEditorTree/RecipeEditorTree";
import {RecipeVersionModel} from "./Models/RecipeVersionModel";
import {Notifier} from "../../Common/Notifier";
import {IRecipeEditorCreateParams, RecipeEditorCreate} from "Core/Controls/RecipeEditor/RecipeEditorCreate";
import {JBoxDropDown} from "Core/Components/JBoxDropdown/DropDown";
import {DeleteRecipeVersionDto} from "./Models/Dto/DeleteRecipeVersionDto";
import {
    ConfirmationDialog, EVENTS as ConfirmationDialogEvents,
    Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import {GetRecipeDataByVersionDto} from "./Models/Dto/GetRecipeDataByVersionDto";
import {SaveRecipeDataDto} from "Core/Controls/RecipeEditor/Models/Dto/SaveRecipeDataDto";
import {ZIndexManager} from 'Core/Common/ZIndexManager';
import {BlockUI} from 'Core/Common/BlockUi';
import {MobileChecker} from 'Core/Common/MobileChecker';
import {ITypePropertyModel} from "Core/GeneralProperties/Managers/TypesProperty/TypesProperty";

import RecipeEdit from "Core/Controls/RecipeEditor/Templates/RecipeEdit.html";
import RecipeVersionDropdownTemplate from "Core/Controls/RecipeEditor/Templates/RecipeVersionDropdownTemplate.html";
ko.templates["Core/Controls/RecipeEditor/Templates/RecipeVersionDropdownTemplate"] = RecipeVersionDropdownTemplate;
ko.templates["Core/Controls/RecipeEditor/Templates/RecipeEdit"] = RecipeEdit;

interface IActiveVersionType {
    Title: string;
    IsDisabled: boolean;
    IsChosen?: boolean;
    ActiveVersionId?: boolean;
}

const TOGGLE_CLASSES = {
    ON: 'fa-toggle-on',
    OFF: 'fa-toggle-off'
}

export interface IRecipeEditorTreeParams {
    RecordId: number,
    RecipeVersions: KnockoutObservableArray<RecipeVersionModel>,
    RecipeDataModel?: RecipeDataModel
}

export interface IRecipeEditorEditParams {
    RecordId: number;
    EntityId: number;
    ControlId: number;
    IsAddingAllowed: boolean;
    IsEditingAllowed: boolean;
    IsDeletingAllowed: boolean;
    TypesProperty?: ITypePropertyModel[];
}

export class RecipeEditorEdit extends Event {
    _labels = LABELS;
    private _modal: Modal;
    private _recordId: number;
    private _entityId: number;
    private _controlId: number;
    private _isAddingAllowed: boolean;
    private _isEditingAllowed: boolean;
    private _isDeletingAllowed: boolean;
    private _recipeDataModel: RecipeDataModel;
    private _recipeEditorTree: KnockoutObservable<RecipeEditorTreeUnit>
    private _recipeEditorCreate: KnockoutObservable<RecipeEditorCreate>;
    private _dropDown: JBoxDropDown;
    private _availableRecipeVersions: KnockoutObservableArray<RecipeVersionModel>;
    private _selectedRecipeVersion: KnockoutObservable<RecipeVersionModel>;
    private _toggleActiveVersionTypes: KnockoutObservableArray<IActiveVersionType>;
    private _toggleActiveVersionType: KnockoutObservable<IActiveVersionType>;
    private _activeVersionToggleClass: KnockoutComputed<string>;
    private _newVersionActive: KnockoutObservable<boolean>;
    private _typesProperty: ITypePropertyModel[];

    constructor(params: IRecipeEditorEditParams) {
        super();
        this._recordId = params.RecordId;
        this._entityId = params.EntityId;
        this._controlId = params.ControlId;
        this._isAddingAllowed = params.IsAddingAllowed;
        this._isEditingAllowed = params.IsEditingAllowed;
        this._isDeletingAllowed = params.IsDeletingAllowed;
        this._recipeDataModel = null;
        this._recipeEditorTree = ko.observable(null);
        this._recipeEditorCreate = ko.observable(null);
        this._availableRecipeVersions = ko.observableArray([]);
        this._selectedRecipeVersion = ko.observable(null);
        this._newVersionActive = ko.observable(false);
        this._typesProperty = params.TypesProperty ? params.TypesProperty : null;
        this.AddEvent("RECIPE_EDITOR_EDIT_CLOSE");
        this.GetRecipeDataModel();

    }

    GetRecipeDataModel(){
        this._recipeEditorTree = ko.observable(RecipeEditorTree.Instance.GetUnit(this._entityId, this._controlId, this._typesProperty));

        const getRecipeDataParams: GetRecipeDataDto = {
            RootEntityId: this._entityId,
            RootRecordId: this._recordId,
            ControlId: this._controlId
        }

        RecipeEditorStore.GetRecipeData(getRecipeDataParams)
            .then((result: RecipeDataModel) => {
                this._recipeDataModel = result;
                this._recipeDataModel.RecipeRecord = this.MapRecord(result.RecipeRecord, this._typesProperty);

                const defaultRecipeVersion = _.find(result.RecipeVersions, (version: RecipeVersionModel) => version.IsActive);
                _.forEach(result.RecipeVersions, (version: RecipeVersionModel)=>{
                    this._availableRecipeVersions.push({
                        VersionId: version.VersionId,
                        VersionName: version.VersionName,
                        IsActive: version.IsActive,
                        IsSelected: ko.observable(version.VersionId === defaultRecipeVersion.VersionId)
                    })
                })
                if (defaultRecipeVersion){
                    this._selectedRecipeVersion({
                        VersionId: defaultRecipeVersion.VersionId,
                        VersionName: defaultRecipeVersion.VersionName,
                        IsActive: defaultRecipeVersion.IsActive,
                        IsSelected: ko.observable(true)
                    })
                    this.InitToggleActiveVersionMod();
                }
                this.ShowInModal();
                let params: IRecipeEditorTreeParams = {
                    RecordId: null,
                    RecipeVersions: null,
                    RecipeDataModel: this._recipeDataModel
                }
                this.LoadRecipeEditorTree(params);

            })
            .fail(() => {
                this.DataLoadFailed.bind(this)
            });
    }

    MapRecord(result: RecipeRecordModel, typesProperty: ITypePropertyModel[]): RecipeRecordModel {
        if (!result) {
            return null;
        }
        let recordModel = new RecipeRecordModel();
        recordModel.Id = result.Id;
        recordModel.Name = result.Name;
        recordModel.NameTranslation = result.NameTranslation;
        recordModel.TypeId = result.TypeId;
        recordModel.TypeName = result.TypeName;
        recordModel.TypeNameTranslation = result.TypeNameTranslation;
        recordModel.LifeStatusId = result.LifeStatusId;
        recordModel.LifeStatusName = result.LifeStatusName;
        recordModel.LifeStatusGroup = result.LifeStatusGroup;
        recordModel.IsLocked = result.IsLocked;
        recordModel.HasChildren = result.HasChildren;
        if (!!typesProperty && !!typesProperty.length){
            _.each(typesProperty, (type:ITypePropertyModel)=>{
                if (type.TypeId === result.TypeId){
                    recordModel.TypeColor = type.TypeColor
                }
            })
        }
        recordModel.Children = result.Children.map(childRecord => this.MapRecord(childRecord, typesProperty));

        return recordModel;
    }

    private DataLoadFailed(error: any): void {
        new Notifier().Failed(error.message);
    }

    GetValue() {
        let versionValue: RecipeVersionModel = {
            VersionId: this._selectedRecipeVersion().VersionId,
            VersionName: this._selectedRecipeVersion().VersionName,
            IsActive: this._selectedRecipeVersion() && this._selectedRecipeVersion().IsActive,
            IsSelected: ko.observable(this._selectedRecipeVersion().IsSelected())
        }
        return versionValue;
    }

    ShowInModal(){
        let self = this;
        let isMobile = MobileChecker.IsMobile();
        this._modal = new Modal({
            addClass: 'recipeEditorEditModal',
            width: isMobile ? '90vw' : '60vw',
            height: '60vh',
            closeOnEsc: false,
            onOpen: function () {
                $(this.closeButton[0]).off('click');
                this.closeButton[0].addEventListener('click', self.Close.bind(self, false));
            }
        }, false);

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
        this._modal.Show();
    }

    Close(){
        if (this._modal) {
            this._modal.Close();
        }
    }

    GetTemplateName() {
        return 'Core/Controls/RecipeEditor/Templates/RecipeEdit';
    }

    AfterRender(el: HTMLElement): void{

    }

    AddVersion(){
        let recipeEditorCreateParams: IRecipeEditorCreateParams = {
            RecordId: this._recordId,
            EntityId: this._entityId,
            ControlId: this._controlId,
            IsRecipeCreated: null,
            IsCreateRecipeVersion: true,
            IsAddingAllowed: this._isAddingAllowed,
            IsEditingAllowed: this._isEditingAllowed,
            IsDeletingAllowed: this._isDeletingAllowed,
            ICopyRecipeVersion: null,
        }
        this._recipeEditorCreate(new RecipeEditorCreate(recipeEditorCreateParams));
        this._recipeEditorCreate().ShowInModal();
        this._recipeEditorCreate().On('RECIPE_EDITOR_VERSION', this, (event)=>{
            let recipeDataModel: RecipeDataModel = event.data;
            recipeDataModel.RecipeRecord = this.MapRecord(event.data.RecipeRecord, this._typesProperty);
            let params: IRecipeEditorTreeParams = {
                RecordId: null,
                RecipeVersions: null,
                RecipeDataModel: recipeDataModel
            }
            this.Update(params, recipeDataModel.RecipeVersions);
            this.SetActiveVersionType_On();
        });
    }

    CopyVersion(){
        let recipeEditorCreateParams: IRecipeEditorCreateParams = {
            RecordId: this._recordId,
            EntityId: this._entityId,
            ControlId: this._controlId,
            IsRecipeCreated: null,
            IsCreateRecipeVersion: true,
            IsAddingAllowed: this._isAddingAllowed,
            IsEditingAllowed: this._isEditingAllowed,
            IsDeletingAllowed: this._isDeletingAllowed,
            ICopyRecipeVersion: {
                NewVersionName: `${this._selectedRecipeVersion().VersionName} - copy`,
                CopiedVersionId: this._selectedRecipeVersion().VersionId
            },
        }
        if (this._recipeEditorCreate()){
            this._recipeEditorCreate = ko.observable(null);
        }
        this._recipeEditorCreate(new RecipeEditorCreate(recipeEditorCreateParams))
        this._recipeEditorCreate().On('COPY_RECIPE_EDITOR_VERSION', this, (event)=>{
            let recipeDataModel: RecipeDataModel = event.data;
            recipeDataModel.RecipeRecord = this.MapRecord(event.data.RecipeRecord, this._typesProperty);
            let params: IRecipeEditorTreeParams = {
                RecordId: null,
                RecipeVersions: null,
                RecipeDataModel: recipeDataModel
            }
            this.Update(params, recipeDataModel.RecipeVersions);
            this.SetActiveVersionType_On();
        });

        this._recipeEditorCreate().ShowInModal();
    }

    SetActiveVersionType_On() {
        if (this._toggleActiveVersionType().Title != 'On'){
            this._toggleActiveVersionType(_.find(this._toggleActiveVersionTypes(), (type) => {
                type.IsChosen = true;
                return type.Title === 'On'
            }));
        }
    }

    DeleteVersion(){
        let DeleteRecipeVersionParams: DeleteRecipeVersionDto = {
            VersionId: this._selectedRecipeVersion().VersionId,
            RootEntityId: this._entityId,
            RootRecordId: this._recordId,
            ControlId: this._controlId
        }
        const confirmationText = CONFIRMATIONS.ARE_YOU_SURE_YOU_WANT_TO_DELETE_VERSION
            .replace('{VersionName}', this._selectedRecipeVersion().VersionName);
        let confirmationDialog = new ConfirmationDialog({
            Text: confirmationText,
            Type: ConfirmationTypes.Question,
            TextConfirm: LABELS.YES,
            TextDecline: LABELS.NO
        });
        confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, () => {
            RecipeEditorStore.DeleteRecipeVersion(DeleteRecipeVersionParams)
                .then((result: RecipeDataModel) => {
                    result.RecipeRecord = this.MapRecord(result.RecipeRecord, this._typesProperty);
                    if (result.RecipeVersions.length){
                        let params: IRecipeEditorTreeParams = {
                            RecordId: null,
                            RecipeVersions: null,
                            RecipeDataModel: result
                        }
                        this.Update(params, result.RecipeVersions);
                        this.SetActiveVersionType_On();
                    } else {
                        this.Trigger('RECIPE_EDITOR_EDIT_CLOSE');
                        this.Close();
                    }
                })
                .fail(error => {
                    new Notifier().Failed(JSON.parse(error.message).Message);
                })
        });
        confirmationDialog.Show();
    }

    Update(params: IRecipeEditorTreeParams, recipeVersions: Array<RecipeVersionModel>){
        this.SetAvailableRecipeVersions(recipeVersions);
        this.LoadRecipeEditorTree(params);
    }

    ClickSaveBtn(data, event) {
        if (this._newVersionActive()){

            let saveRecipeDataParams: SaveRecipeDataDto = {
                ActiveVersionId: this._selectedRecipeVersion().VersionId,
                RootEntityId: this._entityId,
                RootRecordId: this._recordId,
                ControlId: this._controlId
            }

            BlockUI.Block({Target: event.currentTarget});
            RecipeEditorStore.SaveRecipeData(saveRecipeDataParams)
                .then((result: RecipeDataModel) => {
                    result.RecipeRecord = this.MapRecord(result.RecipeRecord, this._typesProperty);
                    if (result.RecipeVersions){
                        let params: IRecipeEditorTreeParams = {
                            RecordId: null,
                            RecipeVersions: null,
                            RecipeDataModel: result
                        }
                        this.Update(params, result.RecipeVersions);
                        this._newVersionActive(false);
                    }
                })
                .fail(error => {
                    new Notifier().Failed(JSON.parse(error.message).Message);
                })
                .always(() => {
                    BlockUI.Unblock(event.currentTarget);
                    $(event.currentTarget).blur();
                });
        }
    }

    ReturnToDefaultType() {
        if (this._toggleActiveVersionType().Title == 'On'){
            this._toggleActiveVersionType(_.find(this._toggleActiveVersionTypes(), (type) => {
                type.IsChosen = false;
                return type.Title === 'Off'
            }));
        }
    }

    ToggleActiveVersion(){
        if (!this._isEditingAllowed || (this._selectedRecipeVersion() && this._selectedRecipeVersion().IsActive)){
            return;
        }

        if (this._toggleActiveVersionType().Title != 'On'){
            this._toggleActiveVersionType(_.find(this._toggleActiveVersionTypes(), (type) => {
                type.IsChosen = true;
                return type.Title === 'On'
            }));
            return;
        } else if (this._toggleActiveVersionType().Title == 'On'){
            this._toggleActiveVersionType(_.find(this._toggleActiveVersionTypes(), (type) => {
                type.IsChosen = true;
                return type.Title === 'Off'
            }));
            return;
        }
    }

    InitToggleActiveVersionMod(){
        this._toggleActiveVersionTypes = ko.observableArray([
            {Title: 'Off', IsDisabled: false, IsChosen: false},
            {Title: 'On', IsDisabled: true, IsChosen: false}
        ]);

        this._toggleActiveVersionType = ko.observable(_.last(_.filter(this._toggleActiveVersionTypes(), activeVersionType => activeVersionType.IsDisabled)));

        this._activeVersionToggleClass = ko.computed(() =>{
            let toggleClass = (this._toggleActiveVersionType() && !this._toggleActiveVersionType().IsDisabled) && this._toggleActiveVersionType().Title === 'Off' ? TOGGLE_CLASSES.OFF : TOGGLE_CLASSES.ON;

            if (this._selectedRecipeVersion() && !this._selectedRecipeVersion().IsActive){

                if (this._toggleActiveVersionType().Title === 'On') {
                    this._newVersionActive(true);
                } else {
                    this._newVersionActive(false);
                }
            } else if (this._toggleActiveVersionType().Title === 'On'){
                this._newVersionActive(false);
            }

            return toggleClass;
        });
    }

    OnSelectVersion(version: RecipeVersionModel){
        if (version.IsSelected()){
            return;
        }

        if (this.IsModified()){
            let confirmationDialog = new ConfirmationDialog({
                Text: CONFIRMATIONS.ALL_CHANGES_WILL_BE_LOST,
                Type: ConfirmationTypes.Question,
                TextConfirm: LABELS.YES,
                TextDecline: LABELS.NO
            });

            confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, () => {
                this.SelectVersion(version);
            });
            confirmationDialog.On(ConfirmationDialogEvents.DISCARD_SELECTED, this, () => {
                return;
            });
            confirmationDialog.Show();
        } else {
            this.SelectVersion(version)
        }
    }

    SelectVersion(version: RecipeVersionModel) {
        _.forEach(this._availableRecipeVersions(), (data: RecipeVersionModel)=>{
            if (version.VersionId === data.VersionId){
                data.IsSelected(true)
            } else {
                data.IsSelected(false);
            }
        })

        let GetRecipeDataByVersionParams: GetRecipeDataByVersionDto ={
            VersionId: version.VersionId,
            RootEntityId: this._entityId,
            RootRecordId: this._recordId,
            ControlId: this._controlId
        }
        RecipeEditorStore.GetRecipeDataByVersion(GetRecipeDataByVersionParams)
            .then((result: RecipeDataModel) => {
                if (result.RecipeVersions){
                    result.RecipeRecord = this.MapRecord(result.RecipeRecord, this._typesProperty);
                    if (version.IsActive){
                        this._selectedRecipeVersion(version);
                        this._toggleActiveVersionType(_.find(this._toggleActiveVersionTypes(), (type) => {
                            type.IsChosen = false;
                            return type.Title === 'On'
                        }));
                        this._newVersionActive(false);
                    } else {
                        this.ReturnToDefaultType();
                        this._selectedRecipeVersion(version);
                    }

                    let params: IRecipeEditorTreeParams = {
                        RecordId: null,
                        RecipeVersions: null,
                        RecipeDataModel: result
                    }

                    this.LoadRecipeEditorTree(params);
                    this._dropDown.Close();
                }
            })
            .fail(error => {
                new Notifier().Failed(JSON.parse(error.message).Message);
            })
    }

    private InitRecipeVersionDropdown(el: HTMLElement) {
        this._dropDown = new JBoxDropDown({
            target: el,
            bindTarget: el,
            bindComponent: this,
            otherOptions: {
                addClass: "recipeVersion-dropdown",
                closeOnClick: 'body',
                attach: undefined,
                height: 'auto',
                maxHeight: 300,
                isolateScroll: true,
                pointer: "",
                maxWidth: 400,
                onCloseComplete: () => {},
                zIndex: ZIndexManager.Instance.NextValue,
                position: {
                    x: "left",
                    y: "bottom"
                }
            },
            onCreated: () => {
                this._dropDown.SetContent({ content: RecipeVersionDropdownTemplate as any });
            },
            onOpen: () => {}
        });
    }

    SetAvailableRecipeVersions(response: Array<RecipeVersionModel>){
        if (!response.length){
            return;
        }
        this._availableRecipeVersions([]);
        this._selectedRecipeVersion(null);
        const defaultRecipeVersion = _.find(response, (version: RecipeVersionModel) => version.IsActive);
        _.forEach(response, (version: RecipeVersionModel)=>{
            this._availableRecipeVersions.push({
                VersionId: version.VersionId,
                VersionName: version.VersionName,
                IsActive: version.IsActive,
                IsSelected: ko.observable(version.VersionId === defaultRecipeVersion.VersionId)
            })
        })

        if (defaultRecipeVersion){
            this._selectedRecipeVersion({
                VersionId: defaultRecipeVersion.VersionId,
                VersionName: defaultRecipeVersion.VersionName,
                IsActive: defaultRecipeVersion.IsActive,
                IsSelected: ko.observable(true)
            })
        }
    }

    ShowAvailableRecipeVersions(data, event){
        let target: HTMLElement,
            isRecipeVersionDropdownBox = $(event.target).parents('.recipeVersionDropdownBox').length > 0;

        target = isRecipeVersionDropdownBox ? $(event.target).parents('.recipeVersionDropdownBox')[0] : event.currentTarget;

        if (!this._availableRecipeVersions().length){
            this.InitRecipeVersionDropdown(target);
            this._dropDown.Open();
        } else {
            if (this._dropDown) {
                this._dropDown.Toggle();
            } else {
                this.InitRecipeVersionDropdown(target);
                this._dropDown.Open();
            }
        }
    }

    private LoadRecipeEditorTree(params: IRecipeEditorTreeParams) {

        if (!this._recipeEditorTree()) {
            return P.resolve(null);
        }

        let getNewDataParams: IGetNewDataParams = {
            recipeDataModel: params.RecipeDataModel,
            recipeSelectedVersion: this._selectedRecipeVersion()
        }

        if (params.RecipeDataModel){
            return this._recipeEditorTree().GetNewData(getNewDataParams);
        } else {
            return this._recipeEditorTree().LoadNewData(params.RecordId, params.RecipeVersions);
        }
    }

    get ActiveVersionTooltipText(): string {
        return this._selectedRecipeVersion() && this._selectedRecipeVersion().IsActive ? this._labels.CANNOT_MAKE_AN_ACTIVE_VERSION_INACTIVE : '';
    }

    get RecipeVersionActive(): boolean {
        return !this._isEditingAllowed || (this._selectedRecipeVersion() && this._selectedRecipeVersion().IsActive);
    }

    IsModified(): boolean {
        return this._newVersionActive();
    }

    IsAddingAllowed(): boolean {
        return this._isAddingAllowed;
    }

    IsCopyingAllowed(): boolean {
        return this._isAddingAllowed && this._isEditingAllowed;
    }

    IsSavingAllowed(): boolean {
        return this._isEditingAllowed && this.IsModified();
    }

    IsDeletingAllowed(): boolean {
        return this._isDeletingAllowed;
    }
}