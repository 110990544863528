import {deserialize, deserializeAs} from 'libs/cerialize';

import {DesignedPortlet} from "Core/Portlets/Models/Store/DesignedPortlet";
import {PortletSpace} from "Core/Portlets/Models/Runtime/PortletSpace"

export class DesignedPortletSpace {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	IsMain: boolean;

	@deserialize
	Memo: string;

	@deserializeAs(DesignedPortlet)
	Portlets: Array<DesignedPortlet>;

	constructor() {
		this.Portlets = Array<DesignedPortlet>();
	}

	static Map(portletSpace: PortletSpace): DesignedPortletSpace {
		let portletSpaceStoreModel = new DesignedPortletSpace();

		portletSpaceStoreModel.Id = portletSpace.ObjectId;
		portletSpaceStoreModel.Name = portletSpace.Name;
		portletSpaceStoreModel.IsMain = portletSpace.IsMain;
		portletSpaceStoreModel.Memo = portletSpace.Memo;

		return portletSpaceStoreModel;
	}
}