import * as ko from "knockout";
import {Event} from "Core/Common/Event";
import {EVENTS} from "Core/Constant";

import {PortletsCollection} from "Core/Portlets/Models/Design/Explorer/PortletsCollection"
import PortleteExplolerTemplate from "Core/Portlets/Templates/PortletExplorer.html";
ko.templates["Core/Portlets/Templates/PortletExplorer"] = PortleteExplolerTemplate;

export class PortletsExplorer extends Event {
	private _container: HTMLElement;
	private _portletsCollection: KnockoutObservable<PortletsCollection>;

	constructor(portletsCollection: PortletsCollection) {
		super();
		this._portletsCollection = ko.observable(portletsCollection);
	}

	GetTemplateName() {
		return "Core/Portlets/Templates/PortletExplorer";
	}

	AfterRender() {
		this.Trigger(EVENTS.PORTLETS.EXPLORER_LOADED);
	}
} 