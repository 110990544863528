import * as _ from'underscore';
import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {GenericDeserialize} from 'libs/cerialize';
import {LinkListRelationsModel} from 'Core/Controls/LinkList/Models/LinklistRelationsModel';

export interface IGetLinkListRelationsRequestModel {
	ControlId: number;
	RecordId?: number;
	EntityId?: number;
	SubjectEntityId?: number;
    SubjectRecordId?: number;
    SubjectLifeStatusId?: number;
	WithoutRelations: boolean;
	CheckOneParent: boolean;
	TableTypeId: number;
};

export class LinkListRelationsStore {
	static GetLinkedRecords(params: IGetLinkListRelationsRequestModel): P.Promise<LinkListRelationsModel> {
		var deferredResult = P.defer<LinkListRelationsModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.LINKLIST.GET_LINKLIST_RELATIONS,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(this.Deserialize(data));
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static Deserialize(jsonObject: any): LinkListRelationsModel {
		var model = GenericDeserialize<LinkListRelationsModel>(jsonObject.ResultObject, LinkListRelationsModel);
		if (jsonObject.Warnings) {
			model.Warnings = jsonObject.Warnings;
		}
		return model;
	}
}