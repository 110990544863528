import * as ko from "knockout";

import {PropertyControl} from "../PropertyControl";

import Template from "./Template.html";
import {ProductPropertyDescription} from "../../ProductPropertyDescription";
import { ProductPart } from "../../../ProductPart";
import { PropertyValue } from '../../../PropertyValue';

export class IntegerPropertyControl extends PropertyControl {
    private _displayValue: KnockoutObservable<string>;

    constructor(property: ProductPropertyDescription, productPart: ProductPart) {
        super(property, productPart);

        this.HasContent = ko.observable(true);
        this._displayValue = ko.observable(null);
    }

    GetTemplate() {
        return Template;
    }

    GetConvertedValue(){
        const value = this._displayValue();

        if (!value && value !== '0') {
            return null;
        }

        let convertedValue = +value;

        convertedValue = isNaN(convertedValue) ? null : convertedValue;

        return convertedValue;
    }

    GetValueForSave(): any {
        return this.GetConvertedValue();
    }

    SetValue(propertyValue: PropertyValue) {
        if (propertyValue) {
            this._displayValue(propertyValue.Value);
        }
        this.StartListeningValueChanges();
    }

    ClearValue() {
        this._displayValue(null);
    }

    private StartListeningValueChanges() {
        if (this._displayValue.getSubscriptionsCount() == 0) {
            this._displayValue.subscribe(() => {
                this.CheckValidControl();
                this.OnValueChanged();
            });
        }
    }

    IsValid(value?: any): boolean {
        return this.GetDecimalIntegerValid(this.GetConvertedValue());
    }

    CheckValidControl() {
        this.isValidControl(this.GetDecimalIntegerValidRules(this.GetConvertedValue()));
    }
}