import * as ko from 'knockout';
import * as $ from 'jquery';
import 'datetimepicker';
import * as moment from 'moment';
import {WeekFormatUtils} from 'Core/Controls/DateTime/WeekFormatUtils';
import {DATE_FORMATS} from 'Core/Constants/DateTimeFormats';

const timeFormats = [
    DATE_FORMATS.TIME.Format,
    DATE_FORMATS.LONG_TIME.Format,
    DATE_FORMATS.TIME_SPAN.Format,
    DATE_FORMATS.TIME_SPAN_LONG.Format
];

export class DatetimepickerExtention {
    static Init() {
        
        const valHook = ($.valHooks.input as any);
        // Extension of jQuery.fn.val
        // Inspired by https://github.com/RobinHerbots/Inputmask
        const valHookGet =
            $.valHooks.input && valHook.get
                ? valHook.get
                : function (element: HTMLInputElement) {
                      return element.value;
                  };

        const valHookSet =
            $.valHooks.input && valHook.set
                ? valHook.set
                : function (element: HTMLInputElement, value: string) {
                      element.value = value;
                      return value;
                  };

        $.valHooks.input = {
            get: function (element: HTMLInputElement) {
                const dataGetInputValue: ((value: string) => string) | undefined = $(element).data('getWeekFormat');
                if (dataGetInputValue) {
                    const savedValue = $(element).data('savedValue');
                    if (savedValue) {
                        return savedValue;
                    }
                    return dataGetInputValue(element.value) || '';
                }

                return valHookGet(element);
            },
            set: function (element: HTMLInputElement, value: string) {
                const dataSetInputValue: ((value: string) => string) | undefined = $(element).data('setWeekFormat');
                if (dataSetInputValue) {
                    $(element).data('savedValue', value);

                    const formattedValue = dataSetInputValue(value);
                    if (!formattedValue) {
                        return undefined;
                    }

                    element.value = formattedValue;
                    return formattedValue;
                }

                return valHookSet(element, value);
            }
        };

        ko.bindingHandlers.datetimepicker = {
            init: (element, valueAccessor) => {
                const options = valueAccessor();
                const updateValueCallback = options.onUpdate || (value => {
                });

                this.SetInputParse(options);

                let scrollContainer;
                let gridScrollContainer;
                let listener;

                function resizePicker() {
                    const picker: HTMLDivElement = this.parentElement.querySelector(".bootstrap-datetimepicker-widget");
                    const {top, left, width, height} = this.querySelector(".time-picker-button").getBoundingClientRect();
                    const pickerHeight = picker.offsetHeight;
                    const pickerWidth = picker.offsetWidth;
                    picker.style.right = "auto";
                    picker.style.left = left + (width / 2 - pickerWidth / 2) + "px";

                    if (window.innerHeight < top + pickerHeight) {
                        picker.style.top = (top - pickerHeight) + "px";
                        picker.classList.add("icon-bottom");
                    } else {
                        picker.style.top = (top + height) + "px";
                        picker.classList.remove("icon-bottom");
                    }
                    scrollContainer = this.closest(".jBox-content");
                    gridScrollContainer = this.closest(".grid-container-wrapper");
                }


                if (options.options) {
                    const isWeekFormat = options.options.format === WeekFormatUtils.GetLocalizedWeekFormat();

                    if (isWeekFormat) {
                        const input = element instanceof HTMLInputElement ? element : element.querySelector('input');

                        WeekFormatUtils.PatchWeekFormatInput(input, options.options.format);
                    }

                    const pickerOptions = isWeekFormat ? WeekFormatUtils.PatchWeekFormatOptions(options.options) : options.options;

                    $(element).datetimepicker(pickerOptions)
                        .on('dp.hide, dp.change', (e: any) => {
                            if (!e.date && (options.options.format == 'HH:mm')) {
                                updateValueCallback("0");
                            }
                            if (e.date && e.date._d) {
                                updateValueCallback(e.date.toDate());
                            }
                        })
                        .on('dp.show', function (e: any) {

                            $('#Condition, #Filtering, .table-scrollable, .tag-control-list')
                                .css('overflow', 'visible');

                            // if datetimepicker is inside grid
                            let gridCell = $(this).closest('.flex-grid-tdata');

                            if (gridCell.length > 0) {

                                resizePicker.call(this);
                                listener = resizePicker.bind(this);

                                window.addEventListener("resize", listener, false);
                                window.addEventListener("scroll", listener, false);
                                scrollContainer && scrollContainer.addEventListener("scroll", listener, false);
                                gridScrollContainer && gridScrollContainer.addEventListener("scroll", listener, false);
                            }
                        })
                        .on('dp.hide', function (e: any) {
                            $('#Condition, #Filtering, .table-scrollable, .tag-control-list')
                                .css({'overflow-x': 'auto', 'overflow-y': 'auto'});

                            scrollContainer && scrollContainer.removeEventListener("scroll", listener, false);
                            gridScrollContainer && gridScrollContainer.removeEventListener("scroll", listener, false);
                            window.removeEventListener("resize", listener, false);
                            window.removeEventListener("scroll", listener, false);
                        });


                    $(element).find('input')
                        .on('keyup', function (e: any) {
                            if (!$(this).val()) {
                                updateValueCallback('');
                            }
                        });

                    if (options.changeTheCaret) {
                        $(element).find('input')
                            .on('keydown', function (e: any) {
                                let caretPos = this.selectionStart;

                                switch (e.which) {
                                    case 37:
                                        e.preventDefault();
                                        this.setSelectionRange(caretPos - 1, caretPos - 1);
                                        break;
                                    case 39:
                                        e.preventDefault();
                                        this.setSelectionRange(caretPos + 1, caretPos + 1);
                                        break;
                                }
                            });
                    }
                }
            },

            update(element, valueAccessor) {
                const options = valueAccessor();
                const updateValueCallback = options.onUpdate || (value => {
                });
                const clearValueCallback = options.onClear || (value => {
                });
                const resetBtn = $(element).find('.date-remove');

                if (options.value) {
                    let dateTimeValue = options.value instanceof Function ? options.value() : options.value;

                    if (
                        (!options.notFormatted && options.options.format !== WeekFormatUtils.GetLocalizedWeekFormat()) ||
                        (dateTimeValue instanceof Date && timeFormats.indexOf(options.options.format) > -1)
                    ) {
                        dateTimeValue = moment(dateTimeValue).format(options.options.format);
                    }

                    $(element).data('DateTimePicker').date(dateTimeValue || null);

                    $(resetBtn).on('click', () => {
                        $(element).data('DateTimePicker').clear();
                        clearValueCallback();
                        updateValueCallback();
                    });
                }
            }
        };
    }

    private static SetInputParse(options) {
        const currentFormat = options.options.format;

        if (!currentFormat) {
            return;
        }

        if (timeFormats.indexOf(currentFormat) > -1) {
            const fallbackMomentOfTime = moment('23:59', DATE_FORMATS.TIME.Format);

            options.options.parseInputDate = (inputValue) => {
                if (inputValue === 'Invalid date') {
                    inputValue = '0';
                }

                if (inputValue && inputValue.indexOf(':') === -1) {
                    const value = inputValue.replace(",", ".");
                    const minsRest = value % 1;

                    const hours = Math.floor(value);
                    const minutes = Math.round((minsRest % 1) * 60);

                    if (hours < 0 || hours >= 24 || minutes < 0 || minutes >= 60) {
                        return fallbackMomentOfTime;
                    }

                    const hrs = (hours < 10) ? ("0" + hours) : hours.toString();
                    const mins = (minutes < 10) ? ("0" + minutes) : minutes.toString();

                    const res = hrs + ":" + mins;
                    const momentOfTime = moment(res, 'hh:mm');

                    if (!momentOfTime.isValid()) {
                        return fallbackMomentOfTime;
                    }

                    return momentOfTime;
                }

                return moment(inputValue, currentFormat);
            };
        }
    }
}