define(['require', 'module', 'exports', 'jquery', 'FormatEditor/FormatConverter'], function (
    require,
    module,
    exports,
    $,
    formatConverterModule
) {
    var FormatConverter = formatConverterModule.FormatConverter;
    (function ($) {
        'use strict';

        var PercentageExtantion = function (options) {
            this.init('percentage', options, PercentageExtantion.defaults);
        };

        $.fn.editableutils.inherit(PercentageExtantion, $.fn.editabletypes.abstractinput);

        $.extend(PercentageExtantion.prototype, {
            render: function () {
                var decimals = this.$input.editableutils.getConfigData($(this.options.scope)).decimals;
                const digits = Math.max(decimals - 2, 0);
                this.$input = this.$tpl;
                this.$input.inputmask('patchedDecimal', {
                    radixPoint: FormatConverter.GetSeparator(),
                    digits: digits,
                    integerDigits: 38 - digits,
                    autoGroup: true,
                    groupSeparator: FormatConverter.GetGroupSeparator(),
                    autoUnmask: true,
                    rightAlign: false
                });
            },

            value2html: function (value, element) {

                if (!value) {
                    $(element).empty();
                    return;
                }

                var textValue = '';

                if (value.current != undefined && value.current != null) {
                    textValue = value.current;
                }

                if (value.default != undefined && value.default != null) {
                    textValue = value.default;
                }

                $(element).html(FormatConverter.LocalizeDecimalOrInteger(textValue.toString()));
            },
            value2input: function (value) {
                if (!value) {
                    return;
                }

                if (value.current != undefined && value.current != null) {
                    this.$input.val(value.current);
                    return;
                }

                if (value.default != undefined && value.default != null) {
                    this.$input.val(value.default);
                    return;
                }
                this.$input.val(value);
            },
            input2value: function () {
                return {
                    current: this.$input.val()
                }
            },

            activate: function () {
                this.$input.focus();
            }
        });

        PercentageExtantion.defaults = $.extend({}, $.fn.editabletypes.abstractinput.defaults, {
            tpl: '<input type="text"/>'
        });

        $.fn.editabletypes.percentage = PercentageExtantion;
    }($));
});
