import * as ko from 'knockout'
import * as _ from 'underscore';
import {GenericDeserialize } from 'libs/cerialize';

import { BlockUI } from "Core/Common/BlockUi";
import { Notifier } from "Core/Common/Notifier";

import { BaseControlEditor, EVENTS as BASE_CONTROL_EDITOR_EVENTS } from "../BaseControlEditor";
import { IControl } from "Core/Controls/IControl";

import { AttachedFieldModel } from "Core/Controls/BaseControl/Models/AttachedFieldModel";

import {
	InvoicingControlConfigOptions,
	InvoiceTable,
	InvoiceType,
	LifeStatusModel
} from "./Models/InvoicingControlConfigOptions";


import { FinancialControlStore } from "Core/Screens/DesignScreen/ControlEditor/Stores/Controls/FinancialControlStore";

import Template from 'Core/Screens/DesignScreen/ControlEditor/Templates/FinancialControlEditor.html';

ko.templates['Core/Screens/DesignScreen/ControlEditor/Templates/FinancialControlEditor'] = Template;

export class InvoicingControlEditor extends BaseControlEditor {
	private _configIssue: string;

	private _sourceTables: KnockoutObservableArray<InvoiceTable>;
	private _selectedSourceTable: KnockoutObservable<InvoiceTable>;

	private _sourceLifeStatuses: KnockoutObservableArray<LifeStatusModel>;
	private _selectedSourceLifeStatus: KnockoutObservable<LifeStatusModel>;

	private _destinationTables: KnockoutObservableArray<InvoiceTable>;
	private _selectedDestinationTable: KnockoutObservable<InvoiceTable>;

	private _destinationTypes: KnockoutObservableArray<InvoiceType>;
	private _selectedDestinationType: KnockoutObservable<InvoiceType>;

	constructor(control: IControl) {
		super(control);

		this._sourceTables = ko.observableArray([]);
		this._selectedSourceTable = ko.observable(null);

		this._sourceLifeStatuses = ko.observableArray([]);
		this._selectedSourceLifeStatus = ko.observable(null);

		this._destinationTables = ko.observableArray([]);
		this._selectedDestinationTable = ko.observable(null);

		this._destinationTypes = ko.observableArray([]);
		this._selectedDestinationType = ko.observable(null);
	}

	Save(): void {
		if (this._configIssue) {
			new Notifier().Warning(this._configIssue);
			return;
		}

		const validationError = this.Validate();
		if (validationError) {
			new Notifier().Warning(validationError);
			return;
		}

		const controlEditorModel = this.GetControlEditorModel();
		this.Trigger(BASE_CONTROL_EDITOR_EVENTS.CONTROL_SAVED, { UpdateControlModel: controlEditorModel });

		this.Close();
	}

	GetControlEditorModel() {
		const controlEditorModel = super.GetControlEditorModel();

		const sourceTable = this._selectedSourceTable();
		const sourceField = new AttachedFieldModel({ Id: sourceTable.PrimaryFieldId, Name: sourceTable.PrimaryFieldName, EntityId: sourceTable.Id });

		const destinationTable = this._selectedDestinationTable();
		controlEditorModel.EntityId = destinationTable.Id;
		controlEditorModel.EntityName = destinationTable.Name;

		const destinationField = new AttachedFieldModel({ Id: destinationTable.PrimaryFieldId, Name: destinationTable.PrimaryFieldName, EntityId: destinationTable.Id });
		controlEditorModel.FieldId = destinationField.Id;
		controlEditorModel.FieldName = destinationField.Name;

		controlEditorModel.RecordLifeStatusId = this._selectedSourceLifeStatus().Id;
		controlEditorModel.DestinationTypeId = this._selectedDestinationType().Id;
		controlEditorModel.AttachedFields = [destinationField, sourceField];

		controlEditorModel.Icon = this.EditControlModel.Icon;

		return controlEditorModel;
	}

	Validate() {
		const validationError = super.Validate();
		if (validationError) {
			return validationError;
		}

		if (!this._selectedSourceTable()) {
			this.IsValid(false);
			return this.Labels.PLEASE_SELECT_DATA_SOURCE;
		}

		if (!this._selectedSourceLifeStatus()) {
			this.IsValid(false);
			return this.Labels.PLEASE_SELECT_WAITING_FOR_INVOICE_LIFESTATUS;
		}

		if (!this._selectedDestinationType()) {
			this.IsValid(false);
			return this.Labels.PLEASE_SELECT_INVOICE_ENTITY;
		}

		if (!this._selectedDestinationType()) {
			this.IsValid(false);
			return this.Labels.PLEASE_SELECT_OUTPUT_RECORD_TYPE;
		}
	}

	GetTemplateName(): string {
		return 'Core/Screens/DesignScreen/ControlEditor/Templates/FinancialControlEditor';
	}

	AfterRender(el: HTMLElement) {
		const container = el[0];
		super.AfterRender(container);

		BlockUI.Block();

		this.LoadData()
            .then(configOptionsResponse => {
                const configOptions = GenericDeserialize<InvoicingControlConfigOptions>(configOptionsResponse, InvoicingControlConfigOptions);
                this.ApplyConfigOptions(configOptions);
            })
			.always(() => BlockUI.Unblock());
	}

	private LoadData() {
		const subjectTypeId = this.EditControl.GetForm().GetScreen().GetTableTypeId();
		return FinancialControlStore.GetConfigOptions(subjectTypeId)
	}

	private ApplyConfigOptions(configOptions: InvoicingControlConfigOptions) {
		if (configOptions.Issue) {
			this._configIssue = configOptions.Issue;
			new Notifier().Warning(configOptions.Issue);
			return;
		}

		const controlModel = this.EditControl.GetModel();
		const attachedFields = controlModel.Fields;
		const recordLifeStatusId = controlModel.RecordLifeStatusId;
		const destinationTypeId = controlModel.DestinationTypeId;

		const defaultLifestatus = new LifeStatusModel();
		defaultLifestatus.Id = null;
		defaultLifestatus.Name = 'Select lifestatus';

		const translatedSourceLifeStatus = configOptions.SourceLifeStatuses.find(t => t.TranslatedName !== null);
		if (translatedSourceLifeStatus) {
			translatedSourceLifeStatus.Name = translatedSourceLifeStatus.TranslatedName;
		}

		configOptions.SourceLifeStatuses.unshift(defaultLifestatus);

		this._sourceTables(configOptions.SourceTables);

		const selectedSourceTable = configOptions.SourceTables.find(table => _.any(attachedFields, field => field.EntityId === table.Id));
		this._selectedSourceTable(selectedSourceTable);

		this._sourceLifeStatuses(configOptions.SourceLifeStatuses);

		const selectedSourceLifeStatus = configOptions.SourceLifeStatuses.find(lifeStatus => lifeStatus.Id === recordLifeStatusId);
		this._selectedSourceLifeStatus(selectedSourceLifeStatus);
		this._selectedSourceLifeStatus.subscribe(lifeStatus => this.EditControlModel.RecordLifeStatusId = lifeStatus.Id);

		this._destinationTables(configOptions.DestinationTables);

		const selectedDestinationTable = configOptions.DestinationTables.find(table => _.any(attachedFields, field => field.EntityId === table.Id));
		this._selectedDestinationTable(selectedDestinationTable);
		this._selectedDestinationTable.subscribe(destinationTable => this.UpdateDestinationTypes(destinationTable, configOptions), this);

		if(selectedDestinationTable) {
			const destinationTypes = configOptions.DestinationTypes.filter(type => type.TableId === selectedDestinationTable.Id)
			this._destinationTypes(destinationTypes);

			this._selectedDestinationType(destinationTypes.find(type => type.Id === destinationTypeId));
		}
		else {
			this._selectedDestinationType(configOptions.DestinationTypes.find(type => type.Id === destinationTypeId));
			const selectedDestinationTableId = this._selectedDestinationType()
				? this._selectedDestinationType().TableId
				: _.any(configOptions.DestinationTypes) ? configOptions.DestinationTypes[0].TableId : 0;
			const destinationTypes = configOptions.DestinationTypes.filter(type => type.TableId === selectedDestinationTableId)
			this._destinationTypes(destinationTypes);
		}

		this._selectedDestinationType.subscribe(type => this.EditControlModel.DestinationTypeId = type.Id);
	}

	private UpdateDestinationTypes(destinationTable: InvoiceTable, configOptions: InvoicingControlConfigOptions) {
		const destinationTypes = configOptions.DestinationTypes.filter(type => type.TableId === destinationTable.Id);
		this._destinationTypes(destinationTypes);
		this._selectedDestinationType(destinationTypes[0]);
	}
}