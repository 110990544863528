import * as ko from 'knockout';

import {ColumnEditor} from "LookupEditor/ColumnEditors/Base/ColumnEditor";

import ImageTemplate from "LookupEditor/Templates/Columns/Image.html";
import {IValueUpdater} from "../../Interfaces/IValueUpdater";

ko.templates["LookupEditor/Templates/Columns/Image"] = ImageTemplate;


export class ImageColumnEditor extends ColumnEditor {
	Show(): void {
	}

	AfterRender(el, owner: IValueUpdater, columnName): void {
	}

	GetTemplateName(): string {
		return "LookupEditor/Templates/Columns/Image";
	}

	ToString() {
		return "Image";
	}
} 