import * as ko from "knockout"
import 'jquery';

export class EnableAttrExtention {
	static Init() {
		ko.bindingHandlers.enableAttr = {
			update: (element, valueAccessor) => {
				var enabled = ko.unwrap(valueAccessor());
				if (enabled) {
					$(element).removeAttr('disabled');
				}
				else {
					$(element).attr('disabled', 'disabled');
				}
			}
		};
	}
}