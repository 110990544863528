import * as ko from "knockout"
import 'jquery';
export class BootstrapPopoverExtention {
	static RenderHtmlString(html, data) {
		const node = new DOMParser().parseFromString(html, "text/html");
		ko.applyBindings(data, node.body);
		var res = node.body.innerHTML.toString();
		ko.cleanNode(node);
		return res;
	}

	static Init() {
		ko.bindingHandlers.popover = {
			init: (element, valueAccessor, allBindings) => {
				let options = valueAccessor();
				const source = allBindings.get('popoverTitle');
				const triggerMode = allBindings.get('triggerMode') || 'click';
				const htmlMode = allBindings.get('htmlMode') || false;
				const sourceUnwrapped = ko.unwrap(source);

				let result = options;

				if (allBindings.get('koData')) {
					result = this.RenderHtmlString(options, allBindings.get('koData'));
				}

				($(element) as any).popover({
					trigger: triggerMode,
					content: result,
					title: sourceUnwrapped,
					html: htmlMode
				});


			},
			update(element, valueAccessor, allBindings, viewModel, bindingContext) {
				var value = valueAccessor();
				ko.bindingHandlers.value.update(element, valueAccessor, allBindings, viewModel, bindingContext);
			}
		};
	}
}