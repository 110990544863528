import {Notifier} from './Notifier';

export class NetworkMonitor {
    private static _timeout = 3000;
    private static _timeoutActive = false;

    static Init() {
        window.addEventListener('offline', () => {
            this.ShowOfflineMessage();
        });

        window.addEventListener('online', () => {
            this.ShowOnlineMessage();
        });
    }

    static ShowOfflineMessage() {
        this.ShowConnectionMessage(() => {
            new Notifier().Warning('Looks like you are offline 🥺');
        });
    }

    static ShowOnlineMessage() {
        this.ShowConnectionMessage(() => {
            new Notifier().Success('Welcome back again 😍')
        });
    }

    private static ShowConnectionMessage(showMessageFunc) {
        if (!this._timeoutActive) {
            showMessageFunc();

            this._timeoutActive = true;

            setTimeout(() => {
                this._timeoutActive = false;
            }, this._timeout);
        }
    }
}