import {deserializeAs, GenericDeserialize} from 'libs/cerialize';

export class SwitchLanguageResult {
    @deserializeAs('access_token')
    AccessToken: string;

    @deserializeAs('refresh_token')
    RefreshToken: string;

    static Deserialize(obj): SwitchLanguageResult {
        return GenericDeserialize(obj, SwitchLanguageResult);
    }
}