import {deserialize, deserializeAs} from 'libs/cerialize';
import {GridDataModel} from 'Core/Controls/Grid/Models/GridDataModel/GridDataModel';
import {IconModel} from 'Core/Controls/BaseControl/Models/IconModel';


export class PivotDetailModel {

    @deserializeAs(GridDataModel)
    Data: GridDataModel;

    @deserialize
    LeftRecordName: string;

    @deserialize
    RightRecordName: string;

    @deserializeAs(IconModel)
    LeftEntityIcon: IconModel;

    @deserializeAs(IconModel)
    RightEntityIcon: IconModel;


    @deserialize
    RelationType: string;
}