import { deserialize, deserializeAs, serialize } from 'libs/cerialize';

export class CanvasSizeModel {
	constructor() {
		this.Height = '0';
		this.Width = '0';
	}

	@serialize
	@deserialize
	Height: string;

	@serialize
	@deserialize
	Width: string;
}