import {TableSecurityViewModel, RightViewModel} from "Core/Controls/TableSecurity/Models/TableSecurityViewModel";

class RightUnitModel {
	constructor(
		public TableId: number,
		public RightsValue: number) { }
}

export class TableSecuritySerializedModel {
	UserGroupId: number;
	RightUnits: Array<RightUnitModel>;

	constructor(userGroupId: number, tableSecurityViewModel: TableSecurityViewModel) {
		this.UserGroupId = userGroupId;

		this.RightUnits = tableSecurityViewModel.Tables.map(table => {
			const tableId = table.Id;
			let rightsValue = 0;

			table.Rights().forEach(right => rightsValue += right.IsChecked() ? right.Value : 0);

			return new RightUnitModel(tableId, rightsValue);
		});
	}
}