import {CONTROL_TYPES} from "Core/Constant";

import {IControl} from "Core/Controls/IControl";
import {ProductConfigurator} from 'Core/Controls/ProductConfigurator/ProductConfigurator';
import {KanbanBoard} from 'Core/Controls/Kanban/KanbanBoard';
import {Book} from 'Core/Controls/Book/Book';

import {ControlEditor} from "../Editors/ControlEditor";

import {BaseControlEditor} from "../Editors/BaseControlEditor";
import {FieldControlEditor} from "../Editors/FieldControlEditor";

import {GridControlEditor} from '../Editors/GridControlEditor';
import {InvoicingControlEditor} from "../Editors/InvoicingControl/InvoicingControlEditor";
import {GenericButtonControlEditor} from '../Editors/GenericButtonControlEditor';
import {CurrencyControlEditor} from "../Editors/CurrencyControlEditor";
import {BasketControlEditor} from "../Editors/BasketControlEditor";
import {PlannerLevelControlEditor} from "../Editors/PLannerLevelControl/PlannerLevelControlEditor";
import {GanttChartControlEditor} from '../Editors/GanttChartControl/GanttChartControlEditor';
import {ProductConfiguratorControlEditor} from '../Editors/ProductConfigurator/ProductConfiguratorControlEditor';
import {SignatureControlEditor} from "../Editors/SignatureControlEditor";
import {BookControlEditor} from '../Editors/BookControl/BookControlEditor';
import {TabPageControlEditor} from "../Editors/TabPageControlEditor";
import {SpreadsheetControlEditor} from '../Editors/SpreadsheetControl/SpreadsheetControlEditor';
import {ImageControlEditor} from '../Editors/Image/ImageControlEditor';
import {KanbanControlEditor} from '../Editors/KanbanControl/KanbanControlEditor';
import {ChartControlEditor} from '../Editors/ChartControl/ChartControlEditor';
import {RecipeEditorControlEditor} from "../Editors/RecipeEditorControl/RecipeEditorControlEditor";
import {MapsControlEditor} from "../Editors/MapsControl/MapsControlEditor";
import { StepPageControlEditor } from "../Editors/StepPageControlEditor";
import {ButtonCopyControlEditor} from 'Core/Screens/DesignScreen/ControlEditor/Editors/ButtonCopyControl/ButtonCopyControlEditor';

export class ControlEditorFactory {
    static GetEditor(control: IControl) {
        switch (control.GetType()) {
            case CONTROL_TYPES.StepPage:
                return new StepPageControlEditor(control);
            case CONTROL_TYPES.Grid:
                return new GridControlEditor(control);

            case CONTROL_TYPES.Basket:
                return new BasketControlEditor(control);

            case CONTROL_TYPES.Invoicing:
                return new InvoicingControlEditor(control);

            case CONTROL_TYPES.GenericButton:
                return new GenericButtonControlEditor(control);

            case CONTROL_TYPES.MultiSelect:
                return new FieldControlEditor(control);

            case CONTROL_TYPES.Image:
                return new ImageControlEditor(control);

            case CONTROL_TYPES.Signature:
                return new SignatureControlEditor(control);

            case CONTROL_TYPES.PlannerLevel:
                return new PlannerLevelControlEditor(control);

            case CONTROL_TYPES.GanttChart:
                return new GanttChartControlEditor(control);

            case CONTROL_TYPES.ProductConfigurator:
                return new ProductConfiguratorControlEditor(control as ProductConfigurator);

            case CONTROL_TYPES.Book:
                return new BookControlEditor(control as Book);

            case CONTROL_TYPES.TabPage:
                return new TabPageControlEditor(control);

            case CONTROL_TYPES.Spreadsheet:
                return new SpreadsheetControlEditor(control);

            case CONTROL_TYPES.Kanban:
                return new KanbanControlEditor(control as KanbanBoard);

            case CONTROL_TYPES.Chart:
				return new ChartControlEditor(control);

            case CONTROL_TYPES.RecipeEditor:
                return new RecipeEditorControlEditor(control);

            case CONTROL_TYPES.Maps:
                return new MapsControlEditor(control);

            case CONTROL_TYPES.ButtonCopy:
                return new ButtonCopyControlEditor(control);
        }

        const controlModel = control.GetModel();

        if (controlModel.TypeName === CONTROL_TYPES.Currency) {
            return new CurrencyControlEditor(control);
        }

        if (controlModel.IsFieldAttached || controlModel.IsLookupFieldAttached || controlModel.IsColorFieldAttached) {
            return new FieldControlEditor(control);
        }

        if (controlModel.IsBlobFieldAttached || control.GetModel().IsComplexControl) {
            return new ControlEditor(control);
        }

        return new BaseControlEditor(control);
    }
}