//Libs
import * as ko from "knockout";
import * as _ from "underscore";
import * as moment from "moment";

//Constants
import {FIELD_TYPES} from "Core/Constant";

//Common
import {P} from "Core/Common/Promise";
import {Notifier} from "Core/Common/Notifier";
import {RecordStore} from "Core/Common/Stores/RecordStore";
import {SERVER_REQUEST_ERRORS} from "Core/Common/Enums/ServerRequestErrors";

//User
import {UserManager} from "User/UserManager";

//Components
import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS,
    Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

//Screens
import {SearchScreen} from "Core/Screens/SearchScreen/SearchScreen";

//Constants
import {DAYS_OF_WEEK} from "Core/Components/Controls/TimeWriting/Constants/DaysOfWeek";
import {TimeStatus} from "Core/Components/Controls/TimeWriting/Constants/TimeStatus";


//Settings
import {IDayViewSettings} from "Core/Components/Controls/TimeWriting/Views/Day/IDayViewSettings";

//Utils
import {DateTime} from "Core/Components/Controls/TimeWriting/Utils/DateTime";

import {
    ActionsCellEditor,
    CellEditor,
    DateCellEditor,
    DateTimeCellEditor,
    DecimalCellEditor,
    DescriptionCellEditor,
    DropdownCellEditor,
    IntegerCellEditor,
    MultiSelectCellEditor,
    RowEditor,
    State as RowEditorState,
    SubjectCellEditor,
    TextCellEditor,
    TimeCellEditor,
    ValidationCellEditor,
    ValueCellEditor
} from "Core/Components/Controls/TimeWriting/Utils/RowEditor";

//Views
import {TimeWritingView} from "Core/Components/Controls/TimeWriting/Views/TimeWritingView";
import {WeekView} from "Core/Components/Controls/TimeWriting/Views/Week/WeekView";

//State
import {State} from "Core/Components/Controls/TimeWriting/Views/Day/State";
import {ViewState} from "Core/Components/Controls/TimeWriting/Views/Day/ViewState";

//ViewModels
import {
    AllowedEntity,
    DayDataViewModel,
    DefaultValue,
    LookupField,
    OptionalFieldData,
    Reservation,
    Subject
} from "Core/Components/Controls/TimeWriting/Models/View/DayDataViewModel";

import {UsersViewModel, UserViewModel} from "Core/Components/Controls/TimeWriting/Models/View/UsersViewModel";

//Stores
import {TimeWritingStore} from "Core/Components/Controls/TimeWriting/Stores/TimeWritingStore";

//Store models
import {TimeWritingReportResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Response/TimeWritingReportResponseModel";
import {SaveReservationRequestModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Request/SaveReservationRequestModel";
import {DeleteReservationRequestModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Request/DeleteReservationRequestModel";

//Mappings
import {DayMappingProfile} from "Core/Components/Controls/TimeWriting/Mappings/Day/DayMappingProfile";
import {UsersMappingProfile} from "Core/Components/Controls/TimeWriting/Mappings/Day/UsersMappingProfile";

//Templates
import DayTemplate from "Core/Components/Controls/TimeWriting/Templates/DayTemplate.html";
//TextColumn
import TextViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Text/View.html";
import TextEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Text/Edit.html";

import DescriptionViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Description/View.html";
import DescriptionEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Description/Edit.html";

//Integer
import IntegerViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Integer/View.html";
import IntegerEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Integer/Edit.html";

//Decimal
import DecimalViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Decimal/View.html";
import DecimalEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Decimal/Edit.html";

//Subject
import SubjectViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Subject/View.html";
import SelectableSubjectEditTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Subject/SelectableSubjectEdit.html";
import SelectedSubjectEditTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Subject/SelectedSubjectEdit.html";
//Dropdown
import  DropdownViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Dropdown/View.html";
import DropdownEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Dropdown/Edit.html";

//Multiselect
import MultiselectViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Multiselect/View.html";
import MultiselectEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/Multiselect/Edit.html";

//DateTime
import DateTimeViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/DateTime/View.html";
import DateTimeEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/DateTime/Edit.html";

//Time
import TimeViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Time/View.html";
import TimeEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Time/Edit.html";

//YesNo
import YesNoViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/YesNo/View.html";
import YesNoEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Base/YesNo/Edit.html";

//Actions
import ActionsViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Actions/Day/View.html";
import ActionsEditCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Actions/Day/Edit.html";
//Dash
import DashViewCellTemplate
    from "Core/Components/Controls/TimeWriting/Templates/CellEditors/Specific/Dash/View.html";

import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';
import {UserVarsManager} from "Core/UserVarsManager/UserVarsManager";


import {CONFIRMATIONS, LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";
import {UsersResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Response/UsersResponseModel";
import {AttachedFieldModel} from "Core/Controls/BaseControl/Models/AttachedFieldModel";
import {BlockUI} from "Core/Common/BlockUi";
import {DATE_FORMATS} from "Core/Constants/DateTimeFormats";
import {FormatConverter} from "FormatEditor/FormatConverter";
import { DayReportResponseModel } from "../../Models/Store/Day/Response/DayReportResponseModel";
import { QueryConditionGroupModel } from "Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryConditionGroup";
import { GenericDeserialize } from "libs/cerialize";

export class DayView extends TimeWritingView {
    private _state: KnockoutObservable<State>;
    private _currentDate: Date;
    private _currentValue;
    private _isPastWeek: KnockoutComputed<boolean>;
    private _isEditable: KnockoutObservable<boolean>;
    private _viewModel: KnockoutObservable<DayDataViewModel>;

    private _viewState: KnockoutObservable<ViewState>;
    private _isReadState: KnockoutComputed<boolean>;
    private _isEditState: KnockoutComputed<boolean>;
    private _isNewState: KnockoutComputed<boolean>;
    private _isFutureDay: KnockoutObservable<boolean>;
    private _isDisableWeekBtn: KnockoutObservable<boolean>;

    private _countOfVisibleColumns: number;
    private _isFromVisible: KnockoutObservable<boolean>;
    private _isDashVisible: KnockoutObservable<boolean>;
    private _isToVisible: KnockoutObservable<boolean>;
    private _isDurationVisible: KnockoutObservable<boolean>;
    private _isHourVisible: KnockoutObservable<boolean>;
    private _isOvwVisible: KnockoutObservable<boolean>;
    private _isPayVisible: KnockoutObservable<boolean>;

    private _rowEditors: KnockoutObservableArray<RowEditor>;
    private _compareCurrentDate: Date;
    private _labels = LABELS;
    private _confirmations = CONFIRMATIONS;
    private _timeStatus = TimeStatus;

    private _initialDateSet = true;
    private _userId: number;

    constructor(private _settings: IDayViewSettings) {
        super();
        this._currentDate = new Date();
        this._state = ko.observable(new State({Date: new Date()}));
        this._isPastWeek = ko.computed(() => DateTime.GetWeekYear(this._state().Date) < DateTime.GetWeekYear(this._currentDate) || DateTime.GetWeekNumber(this._state().Date) < DateTime.GetWeekNumber(this._currentDate), this);
        this._isEditable = ko.observable(false);
        this._viewModel = ko.observable(null);

        this._viewState = ko.observable(ViewState.Read);
        this._isReadState = ko.computed(() => this._viewState() === ViewState.Read, this);
        this._isEditState = ko.computed(() => this._viewState() === ViewState.Edit, this);
        this._isNewState = ko.computed(() => this._viewState() === ViewState.New, this);
        this._rowEditors = ko.observableArray([]);
        this._compareCurrentDate = this._currentDate;
        this._compareCurrentDate.setHours(0, 0, 0, 0);
        this._isFutureDay = ko.observable(false);
        this._isDisableWeekBtn = ko.observable(false);

        const visibleFields = this._settings.Properties.Groups.find(group => group.Name === "VisibleFields").Properties;
        const dashVisibility = visibleFields.find(field => field.Name === "START").Value && visibleFields.find(field => field.Name === "TO").Value;

        this._countOfVisibleColumns = visibleFields.filter(f => f.Value === true).length + dashVisibility + this._settings.CustomFields.length;

        this._isFromVisible = ko.observable(visibleFields.find(field => field.Name === "START").Value);
        this._isDashVisible = ko.observable(dashVisibility);
        this._isToVisible = ko.observable(visibleFields.find(field => field.Name === "TO").Value);
        this._isDurationVisible = ko.observable(visibleFields.find(field => field.Name === "TIMESPENT").Value);
        this._isHourVisible = ko.observable(visibleFields.find(field => field.Name === "F_HOURKIND").Value);
        this._isOvwVisible = ko.observable(visibleFields.find(field => field.Name === "F_OVERWORKINDICATOR").Value);
        this._isPayVisible = ko.observable(visibleFields.find(field => field.Name === "F_PAYMENTINDICATOR").Value);
    }

    private GetTimeCloseDateVisibility() {
        const timeCloseDateOption = this._settings.Properties.Groups.find(group => group.Name === 'TimeCloseDateVisibility').Properties[0].Value;
        return timeCloseDateOption && timeCloseDateOption.Value || 'PerDay';
    }

    private InitAllowedEntities(dayReport: DayReportResponseModel) {
       let condition = this._settings.Properties.GetPropertyValue('AllowedEntityQueryCondition');

       if(condition){
        let conditions = GenericDeserialize<QueryConditionGroupModel>(JSON.parse(condition), QueryConditionGroupModel);
        if(conditions){
            dayReport.GlobalSettings.AllowedEntities = [_.find(dayReport.GlobalSettings.AllowedEntities, (entity)=> entity.Id === conditions.EntityId )];
        }
       }
    }

    get TimeCloseDateVisible() {
        const visibility = this.GetTimeCloseDateVisibility();
        return visibility !== 'Hidden';
    }

    get TimeCloseDateVisiblePerDay() {
        const visibility = this.GetTimeCloseDateVisibility();
        return visibility === 'PerDay';
    }

    get TimeCloseDateVisiblePerWeek() {
        const visibility = this.GetTimeCloseDateVisibility();
        return visibility === 'PerWeek';
    }

    get TimeCloseDateVisiblePerDayAndWeek() {
        const visibility = this.GetTimeCloseDateVisibility();
        return visibility === 'PerDayAndWeek';
    }

    GetTemplate() {
        return DayTemplate;
    }

    CanBeUpdated() {
        return this._viewState() === ViewState.Read;
    }

    Show(date: Date, userId?: number) {
        this._userId = userId;
        this._initialDateSet = true;
        this._compareCurrentDate.setHours(0, 0, 0, 0);
        this._state(new State({
            Date: date
        }));

        BlockUI.Block();

        const usersList = this._viewModel() && this._viewModel().Users;
        if (usersList && userId) {
            const selectedUser = usersList.Users().find(user => user.Id === userId);
            if (selectedUser) {
                usersList.SelectedUser(selectedUser);
            }
        }
        if (usersList) {
            this.LoadData(date, usersList.SelectedUser().Id)
                .then(dayReportResponse => this.RenderData(dayReportResponse, usersList))
                .fail(error => {
                    this.ShowError(error.message);
                    BlockUI.Unblock();
                });
        } else {
            const loadDataPromise = this.LoadData(date, userId || UserManager.Instance.CurrentUser.Id);
            const loadUsersPromise = this.LoadUsers();

            loadDataPromise.fail(error => this.ShowError(error.message));
            loadUsersPromise.fail(error => this.ShowError(error.message));

            P.when(loadDataPromise, loadUsersPromise).then(result => this.RenderData(result[0], result[1], userId || UserManager.Instance.CurrentUser.Id)).fail(() => BlockUI.Unblock());
        }
    }

    ShowStatic() {
        this._state(new State({
            Date: new Date()
        }));

        this.RenderStaticData();
    }

    OnDisapprovedClick() {
        const usersList = this._viewModel() && this._viewModel().Users;
        const selectedUser = usersList && usersList.SelectedUser().Id;

        if (this._viewState() === ViewState.Read) {
            this.Trigger("DisapprovedClicked", {State: this._state(), UserId: selectedUser});
            return;
        }

        this.AskSaveConfirmation(() => {
            this.SaveReservation()
                .then((rowEditor: RowEditor) => {
                    this.CommitChanges(rowEditor);
                    this.Trigger("DisapprovedClicked", {State: this._state(), UserId: selectedUser});
                })
                .fail(error => this.ShowError(error.message));
        }, () => {
            this.CancelChanges();
            this.Trigger("DisapprovedClicked", this._state());
        });
    }

    OnPrevWeekClick() {
        const prevWeekStartDate = DateTime.GetPrevWeekStartDate(this._state().Date);
        if (this._viewState() === ViewState.Read) {
            this.Show(prevWeekStartDate);
            return;
        }

        this.AskSaveConfirmation(() => {
            this.SaveReservation()
                .then((rowEditor: RowEditor) => {
                    this.CommitChanges(rowEditor);
                    this.Show(prevWeekStartDate);
                })
                .fail(error => this.ShowError(error.message));
        }, () => {
            this.CancelChanges();
            this.Show(prevWeekStartDate);
        });
    }

    private SelectDate(date: Date) {
        if (!this._initialDateSet) {
            this.Show(date, this._userId);
        } else {
            this._initialDateSet = false;
        }
        return true;
    }

    OnYearClick() {
        if (this._viewState() === ViewState.Read) {
            this.Trigger("YearClicked", {State: this._state(), UserId: this._viewModel().SelectedUser.Id});
            return;
        }

        this.AskSaveConfirmation(() => {
            this.SaveReservation()
                .then((rowEditor: RowEditor) => {
                    this.CommitChanges(rowEditor);
                    this.Trigger("YearClicked", {State: this._state(), UserId: this._viewModel().SelectedUser.Id});
                })
                .fail(error => this.ShowError(error.message));
        }, () => {
            this.CancelChanges();
            this.Trigger("YearClicked", {State: this._state(), UserId: this._viewModel().SelectedUser.Id});
        });
    }

    OnWeekClick() {
        const startDate = DateTime.GetWeekStart(this._state().Date);
        const endDate = moment(startDate).add(7, 'days').toDate();

        if (this._viewState() === ViewState.Read) {
            this.Trigger('WeekClicked', {
                StartDate: startDate,
                EndDate: endDate,
                UserId: this._viewModel().SelectedUser.Id
            });
            return;
        }

        this.AskSaveConfirmation(() => {
            this.SaveReservation()
                .then((rowEditor: RowEditor) => {
                    this.CommitChanges(rowEditor);
                    this.Trigger('WeekClicked', {
                        StartDate: startDate,
                        EndDate: endDate,
                        UserId: this._viewModel().SelectedUser.Id
                    });
                })
                .fail(error => this.ShowError(error.message));
        }, () => {
            this.CancelChanges();
            this.Trigger('WeekClicked', {
                StartDate: startDate,
                EndDate: endDate,
                UserId: this._viewModel().SelectedUser.Id
            });
        });
    }

    OnNextWeekClick() {
        const nextWeekStartDate = DateTime.GetNextWeekStartDate(this._state().Date);
        if (this._viewState() === ViewState.Read) {
            this.Show(nextWeekStartDate);
            return;
        }

        this.AskSaveConfirmation(() => {
            this.SaveReservation()
                .then((rowEditor: RowEditor) => {
                    this.CommitChanges(rowEditor);
                    this.Show(nextWeekStartDate);
                })
                .fail(error => this.ShowError(error.message));
        }, () => {
            this.CancelChanges();
            this.Show(nextWeekStartDate);
        });
    }

    OnCloseDayClicked(evt, date: Date) {
        if (!evt.target.control.disabled) {
            const confirmationText = CONFIRMATIONS.SELECTED_DAYS_WILL_NO_LONGER_TO_BE_EDITABLE;
            const confirmationDialog = new ConfirmationDialog({
                Text: confirmationText,
                Type: ConfirmationTypes.Question,
                TextConfirm: this._labels.YES,
                TextDecline: this._labels.CANCEL
            });

            confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => this.CloseDate(date));
            confirmationDialog.Show();
        }
    }

    OnCloseWeekClicked(evt) {
        if (!evt.target.control.disabled) {
            const confirmationText = CONFIRMATIONS.SELECTED_DAYS_WILL_NO_LONGER_TO_BE_EDITABLE;
            const confirmationDialog = new ConfirmationDialog({
                Text: confirmationText,
                Type: ConfirmationTypes.Question,
                TextConfirm: this._labels.YES,
                TextDecline: this._labels.CANCEL
            });

            confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
                const dateToClose = this._viewModel().DaysOfWeek.slice().reverse().find(day => moment().diff(moment(day.Date)) >= 0).Date;
                this.CloseDate(dateToClose);
            });
            confirmationDialog.Show();
        }
    }

    private UpdateWeekCheckbox() {
        let daysOfWeekUntilToday = this._viewModel().DaysOfWeek.filter(day => moment().diff(moment(day.Date)) >= 0);
        this._isDisableWeekBtn(_.every(daysOfWeekUntilToday, dow => dow.ClosedDate));
    }

    private CloseDate(date: Date) {
        const usersList = this._viewModel() && this._viewModel().Users;
        const selectedUser = usersList && usersList.SelectedUser().Id;
        TimeWritingStore.CloseDate({
            TimeCloseDate: moment(date).format(),
            UserId: selectedUser
        })
            .then(() => this.Show(date, selectedUser))
            .fail(error => new Notifier().Failed(error.message));
    }

    OnDayClick(date: Date) {
        if (this._viewState() === ViewState.Read) {
            this.Show(date);
            return;
        }

        this.AskSaveConfirmation(() => {
            this.SaveReservation()
                .then((rowEditor: RowEditor) => {
                    this.CommitChanges(rowEditor);
                    this.Show(date);
                })
                .fail(error => this.ShowError(error.message));
        }, () => {
            this.CancelChanges();
            this.Show(date);
        });
    }

    OnAddLineClick() {
        const defaultTimeSpentValue = this._viewModel().DefaultValues.find(item => item.Name === 'TIMESPENT');
        const durationInMinutes = () => {
            const durationMoment = moment(defaultTimeSpentValue.DefaultValue);
            return durationMoment.hours() * 60 + durationMoment.minutes();
        };
        const duration = defaultTimeSpentValue ? durationInMinutes() : 60;

        const lastReservationTime = this.GetLastReservationTime();
        const timeOverflow = (lastReservationTime.getHours() === 0 && lastReservationTime.getMinutes() === 0);

        if (!lastReservationTime || timeOverflow) {
            new Notifier().Warning(NOTIFICATIONS.TIME_OVERFLOW);
            return;
        }

        const timeSpentMoment = moment(new Date(this._state().Date.toString())).hours(0).minutes(duration).seconds(0)

        const timeSpent = timeSpentMoment.toDate();

        this.GetNewReservationSubject().then(reservationSubject => {
            const reservation = Reservation.ReserveFor(lastReservationTime, timeSpent, reservationSubject, this._settings.CustomFields);
            this._viewModel().RequestedDate.Reservations.push(reservation);

            this._viewState(ViewState.New);
            this.AddRowEditor(reservation, this._viewModel().LookupFields, this._viewModel().AllowedEntities, RowEditorState.New, this._viewModel());
        });
    }

    OnReservationEdit(guid: string) {
        const rowEditor = _.find(this._rowEditors(), editor => editor.Guid === guid);

        if (!rowEditor) {
            new Notifier().Failed(NOTIFICATIONS.ROW_EDITOR_NOT_FOUND);
            return;
        }

        rowEditor.SetState(RowEditorState.Edit);

        this._viewState(ViewState.Edit);
    }

    OnReservationDelete(guid: string) {
        const rowEditor = _.find(this._rowEditors(), editor => editor.Guid === guid);

        if (!rowEditor) {
            new Notifier().Failed(NOTIFICATIONS.ROW_EDITOR_NOT_FOUND);
            return;
        }

        this.AskDeleteConfirmation(() => {
            const reservation = _.find(this._viewModel().RequestedDate.Reservations(), reservation => reservation.Guid === guid);

            const deleteModel = DayMappingProfile.OnDeleteModel(reservation);
            deleteModel.TimeTableId = this._settings.EntityId;

            this.DeleteReservation(deleteModel)
                .then(() => this.SaveAndReload(rowEditor))
                .fail(error => this.ShowError(error.message));
        });
    }

    OnSaveReservationChanges() {
        BlockUI.Block();
        this.SaveReservation()
            .then(rowEditor => this.SaveAndReload(rowEditor))
            .fail(error => {
                this.ShowError(error.message);
                BlockUI.Unblock();
            });
    }

    OnReservationAccept(guid: string) {
        const rowEditor = _.find(this._rowEditors(), editor => editor.Guid === guid);

        if (!rowEditor) {
            new Notifier().Failed(NOTIFICATIONS.ROW_EDITOR_NOT_FOUND);
            this._viewState(ViewState.Read);
            return;
        }

        const reservation = _.find(this._viewModel().RequestedDate.Reservations(), reservation => reservation.Guid === guid);

        const acceptModel = DayMappingProfile.OnSaveModel(reservation);

        acceptModel.TimeTableId = this._settings.EntityId;
        acceptModel.UserId = this._viewModel().SelectedUser.Id;

        this.AcceptReservation(acceptModel)
            .then(() => this.SaveAndReload(rowEditor))
            .fail(error => this.ShowError(error.message));
    }

    CancelChanges() {
        const editRow = _.find(this._rowEditors(), editor => editor.IsEditState || editor.IsNewState);
        this.OnCancelReservationChanges(editRow.Guid);
    }

    OnCancelReservationChanges(guid: string) {
        const rowEditor = _.find(this._rowEditors(), editor => editor.Guid === guid);

        if (!rowEditor) {
            new Notifier().Failed(NOTIFICATIONS.ROW_EDITOR_NOT_FOUND);
            return;
        }

        if (rowEditor.IsNewState) {
            this._viewModel().RequestedDate.Reservations().pop();
            this.CancelNewReservation(rowEditor);
        } else {
            this.CancelReservationChanges(rowEditor);
        }
    }

    IsFutureDate(date: Date) {
        return moment().diff(moment(date)) < 0;
    }

    HoverOnDayClose(evt, date) {
        if (evt.type === "mouseover" && evt.target.control && !evt.target.control.disabled) {
            this._viewModel().DaysOfWeek.forEach(day => {
                if (!day.ClosedDate) {
                    day.Hovered(moment(day.Date).diff(moment(date)) <= 0);
                }
            });
        }
    }

    HoverOutDayClose(evt) {
        if (evt.type === "mouseout" && evt.target.control && !evt.target.control.disabled) {
            this._viewModel().DaysOfWeek.forEach(day => {
                day.Hovered(false);
            });
        }
    }

    HoverOnWeekClose(evt) {
        if (evt.type === "mouseover") {
            this._viewModel().DaysOfWeek.forEach(day => {
                if (!this._viewModel().ClosedWeek) {
                    day.Hovered(moment().diff(moment(day.Date)) >= 0)
                }
            });
        }
    }

    HoverOutWeekClose(evt) {
        if (evt.type === "mouseout") {
            this._viewModel().DaysOfWeek.forEach(day => day.Hovered(false));
        }
    }

    OnAllowedEntityClick(entity: AllowedEntity) {
        const rowEditor = _.find(this._rowEditors(), editor => editor.IsNewState);

        if (!rowEditor) {
            new Notifier().Failed(NOTIFICATIONS.ROW_EDITOR_NOT_FOUND);
            this._viewState(ViewState.Read);
            return;
        }

        const reservation = _.find(this._viewModel().RequestedDate.Reservations(), reservation => reservation.Guid === rowEditor.Guid);

        this.SearchSubjectForEntity(entity, reservation);
    }

    SelectSubjectMode(reservation: Reservation) {
        reservation.Subject(Subject.NotSpecified());
    }

    private UserSelected(user: UserViewModel) {
        this.Show(this._state().Date);
    }

    private GetNewReservationSubject(): P.Promise<Subject> {
        const deferred = P.defer<Subject>();

        if (this._settings.SubjectEntityId === null) {
            deferred.resolve(null);
        } else {
            this.RequestNewReservationSubject().always(subject => deferred.resolve(subject))
        }

        return deferred.promise();
    }

    private RequestNewReservationSubject(): P.Promise<Subject> {
        const deferred = P.defer<Subject>();

        const subjectIsAllowed = _.any(this._viewModel().AllowedEntities, e => e.Id === this._settings.SubjectEntityId);
        if (!subjectIsAllowed) {
            deferred.resolve(null);
        } else {
            TimeWritingStore.GetSubject(this._settings.SubjectEntityId, this._settings.SubjectRecordId)
                .then(record => {
                    const nameField = _.find<any>((<any>record).Fields, field => field.FieldName.toUpperCase() === "NAME");
                    const subject = new Subject({
                        EntityId: this._settings.SubjectEntityId,
                        EntityName: this._settings.SubjectEntityName,
                        SubjectId: this._settings.SubjectRecordId,
                        SubjectName: nameField.FieldValue,
                        Icon: this._settings.SubjectEntityIcon
                    });
                    deferred.resolve(subject);
                })
                .fail(() => deferred.resolve(null));
        }
        return deferred.promise();
    }

    private CancelNewReservation(rowEditor: RowEditor) {
        const rowEditorIndex = this._rowEditors.indexOf(rowEditor);
        this._rowEditors.splice(rowEditorIndex, 1);

        this._viewState(ViewState.Read);
    }

    private CancelReservationChanges(rowEditor: RowEditor) {
        rowEditor.CancelChanges();
        rowEditor.SetState(RowEditorState.Read);

        this._viewState(ViewState.Read);
    }

    private LoadUsers() {
        return TimeWritingStore.GetUsers(this._settings.EntityId)
            .then(users => users.Users.length === 0
                ? UsersResponseModel.CreateDefault()
                : UsersMappingProfile.OnViewModel(users)
            )
            .fail(error => new Notifier().Failed(error.message));
    }

    private LoadData(date: Date, userId: number): P.Promise<TimeWritingReportResponseModel> {
        return TimeWritingStore.GetDay({
            ControlId: this._settings.ControlId,
            TimeTableId: this._settings.EntityId,
            SubjectEntityId: this._settings.SubjectEntityId,
            SubjectRecordId: this._settings.SubjectRecordId,
            Date: moment(date).set({hours: 0, minutes: 0, seconds: 0}).utcOffset(0, true).format(),
            UserId: userId,
        });
    }

    private RenderData(model: TimeWritingReportResponseModel, users: UsersViewModel, selectedUserId?: number) {
        this.InitAllowedEntities(model.DayReports[0]);
        const viewModel = DayMappingProfile.OnViewModel(model.DayReports[0], model.WeekReports[0]);

        viewModel.Date = this._state().Date;
        viewModel.Day = DAYS_OF_WEEK[this._state().Date.getDay()];
        viewModel.Year = this._state().Year;
        viewModel.Week = this._state().Week;
        viewModel.Users = users;
        viewModel.TimeClosedDate = new Date();

        if (selectedUserId) {
            const selectedUser = viewModel.Users.Users().find(user => user.Id === selectedUserId);
            if (selectedUser) {
                viewModel.Users.SelectedUser(selectedUser);
            }
        }

        const dayReport = model.DayReports[0];

        viewModel.HasDisapprovals = dayReport.HasDisapprovals;

        for (const reservation of viewModel.RequestedDate.Reservations()) {
            if (!_.any(reservation.OptionalData)) {
                this._settings.CustomFields.forEach(field => reservation.OptionalData.push(new OptionalFieldData({
                    FieldName: field.Name,
                    FieldValue: ko.observable(null),
                    FieldType: field.FieldTypeName,
                    Translations: ko.observableArray([])
                })));
            }
        }

        this._isFutureDay(this.IsFuture());
        this._isEditable(dayReport.Editable);
        this._viewState(ViewState.Read);
        this._rowEditors([]);
        this.InitRowEditors(viewModel);
        this._initialDateSet = true;
        this._viewModel(viewModel);

        if (dayReport.GlobalSettings.FreezeTime === undefined) {
            new Notifier().Warning(NOTIFICATIONS.FREEZE_TIME_NOT_SPECIFIED);
        }
        this.UpdateWeekCheckbox();
        BlockUI.Unblock();
    }

    GetShortDateFormat() {
        return DATE_FORMATS.SHORT_DATE['Format'];
    }

    private GetLocale() {
        return moment.locale();
    }

    private IsFuture() {
        return moment().diff(moment(this._state().Date)) < 0;
    }

    private RenderStaticData() {
        const viewModel = new DayDataViewModel();
        viewModel.Date = this._state().Date;
        viewModel.Day = DAYS_OF_WEEK[this._state().Date.getDay()];
        viewModel.Year = this._state().Year;
        viewModel.Week = this._state().Week;
        viewModel.Users = new UsersViewModel();

        this._isEditable(false);
        this._rowEditors([]);
        this._initialDateSet = true;
        this._viewModel(viewModel);
    }

    private ShowError(message: string) {
        new Notifier().Failed(message);
    }

    private ShowWarning(message: string) {
        new Notifier().Warning(message);
    }

    private ShowNotification(message: string) {
        new Notifier().Success(message);
    }

    private GetCustomFieldNames() {
        return this._settings.CustomFields.map(field => {
            return {Name: field.FieldNameTranslation, Type: field.FieldTypeName}
        });
    }

    private InitRowEditors(viewModel: DayDataViewModel) {
        viewModel.RequestedDate.Reservations().forEach(reservation => this.AddRowEditor(reservation, viewModel.LookupFields, viewModel.AllowedEntities, RowEditorState.Read, viewModel));
    }

    private AddRowEditor(reservation: Reservation, lookupFields: LookupField[], allowedEntities: AllowedEntity[], state: RowEditorState, viewModel: DayDataViewModel) {
        const rowEditor = this.GenerateRowEditor(reservation, lookupFields, allowedEntities, viewModel);
        rowEditor.SetState(state);
        this._rowEditors.push(rowEditor);
    }

    private GenerateOptionalCellEditor(field: AttachedFieldModel, reservation: Reservation, lookupFields: LookupField[], viewModel: DayDataViewModel): CellEditor {
        if (this._isNewState()) {
            const defaultValue = viewModel.DefaultValues.find(item => item.Name === field.Name) || new DefaultValue();
            if (field.FieldTypeName === FIELD_TYPES.Lookup) {
                const dropdownField = _.find(lookupFields, f => f.Name === field.Name);
                const dropdownFieldValue = +defaultValue.DefaultValue;
                const dropdownValue = _.find(dropdownField.Values, value => value.FieldValue === dropdownFieldValue);
                reservation.SetOptionalValue(field.Name, dropdownValue);
            } else {
                reservation.SetOptionalValue(field.Name, defaultValue.DefaultValue);
            }
        }

        if (field.FieldTypeName === FIELD_TYPES.Lookup) {
            const dropdownField = _.find(lookupFields, f => f.Name === field.Name);

            return new DropdownCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                Options: ko.observableArray(dropdownField.Values),
                RequiresSearch: dropdownField.RequiresSearch,
                ValTableId: field.ValTableId,
                ValFieldId: field.ValFieldId,
                ValFieldTypeName: field.ValFieldTypeName,
                ValFieldFormatName: field.ValFieldFormatName,
                ValFieldSize: field.ValFieldSize,
                ViewTemplate: DropdownViewCellTemplate,
                EditTemplate: DropdownEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'ovw',
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value && value.FieldValue
                }
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.MultiSelect) {
            const multiSelectField = _.find(lookupFields, f => f.Name === field.Name);

            return new MultiSelectCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                Options: ko.observableArray(multiSelectField.Values),
                RequiresSearch: multiSelectField.RequiresSearch,
                ValTableId: field.ValTableId,
                ValFieldId: field.ValFieldId,
                ValFieldTypeName: field.ValFieldTypeName,
                ValFieldFormatName: field.ValFieldFormatName,
                ValFieldSize: field.ValFieldSize,
                ViewTemplate: MultiselectViewCellTemplate,
                EditTemplate: MultiselectEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'ovw',
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value && value.FieldValue
                }
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.Integer) {
            return new IntegerCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: IntegerViewCellTemplate,
                EditTemplate: IntegerEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: null,
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value !== null && value !== undefined
                }
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.Decimal) {
            return new DecimalCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: DecimalViewCellTemplate,
                EditTemplate: DecimalEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: null,
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value !== null && value !== undefined
                },
                Size: field.Size,
                FormatName: field.FormatName
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.DateTime) {
            return new DateTimeCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: DateTimeViewCellTemplate,
                EditTemplate: DateTimeEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'DateTime',
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value !== null && value !== undefined
                },
                FormatName: field.FormatName
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.Date) {
            return new DateCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: DateTimeViewCellTemplate,
                EditTemplate: DateTimeEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'DateTime',
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value !== null && value !== undefined
                },
                FormatName: field.FormatName
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.Time) {
            return new TimeCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: TimeViewCellTemplate,
                EditTemplate: TimeEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'DateTime',
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value !== null && value !== undefined
                }
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.TimeSpan) {
            return new ValidationCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: TimeViewCellTemplate,
                EditTemplate: TimeEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'DateTime',
                RequiredRule: {
                    Message: `${field.FieldNameTranslation} is required`,
                    Validate: value => !field.IsRequired || value !== null && value !== undefined
                }
            });
        }

        if (field.FieldTypeName === FIELD_TYPES.YesNo) {
            return new ValueCellEditor({
                Name: field.Name,
                Label: field.FieldNameTranslation,
                Value: reservation.GetOptionalValue(field.Name),
                ViewTemplate: YesNoViewCellTemplate,
                EditTemplate: YesNoEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                ClassName: 'description'
            });
        }

        return new TextCellEditor({
            Name: field.Name,
            Label: field.FieldNameTranslation,
            Value: reservation.GetOptionalValue(field.Name),
            ViewTemplate: TextViewCellTemplate,
            EditTemplate: TextEditCellTemplate,
            Editable: true,
            Visible: ko.observable(true),
            ClassName: 'description',
            RequiredRule: {
                Message: `${field.FieldNameTranslation} is required`,
                Validate: value => !field.IsRequired || value !== null && !!value
            },
            Size: field.Size,
            Translations: reservation.GetTranslations(field.Name),
            IsTranslate: _.some(viewModel.TranslatedFields, item => item.Id === field.Id)
        });
    }

    private GenerateRowEditor(reservation: Reservation, lookupFields: LookupField[], allowedEntities: AllowedEntity[], viewModel: DayDataViewModel) {
        const customFieldCellEditors = this._settings.CustomFields.map(field => this.GenerateOptionalCellEditor(field, reservation, lookupFields, viewModel));

        const hourKindLookupField = _.find(lookupFields, f => f.Name === "F_HOURKIND");
        const ovwLookupField = _.find(lookupFields, f => f.Name === "F_OVERWORKINDICATOR");
        const payLookupField = _.find(lookupFields, f => f.Name === "F_PAYMENTINDICATOR");

        return new RowEditor(reservation.Guid,
            null,
            lookupFields,
            new SubjectCellEditor({
                Name: "Subject",
                Label: this._labels.SUBJECT,
                Value: reservation.Subject,
                ViewTemplate: SubjectViewCellTemplate,
                EditTemplate: reservation.Subject().NotSpecified ? SelectableSubjectEditTemplate : SelectedSubjectEditTemplate,
                Editable: true,
                Visible: ko.observable(true),
                OnEntityClick: this.OnAllowedEntityClick.bind(this),
                OnEntityIconClick: this.SelectSubjectMode.bind(this, reservation),
                OnSubjectClick: this.SearchSubject.bind(this, reservation),
                AllowedEntities: allowedEntities,
                IsNewState: this._isNewState,
                RequiredRule: {
                    Validate: value => !value.NotSpecified,
                    Message: this._labels.SELECT__RESERVATION_SUBJECT
                },
                ClassName: 'subject'
            }),
            new ValidationCellEditor({
                Name: "Start",
                Label: this._labels.FROM,
                Value: reservation.Start,
                ViewTemplate: TimeViewCellTemplate,
                EditTemplate: TimeEditCellTemplate,
                Editable: true,
                Visible: this._isFromVisible,
                ClassName: 'from'
            }),
            new CellEditor({
                Name: "-",
                Label: "",
                ViewTemplate: DashViewCellTemplate,
                Visible: this._isDashVisible,
                ClassName: 'dash'
            }),
            new ValidationCellEditor({
                Name: "To",
                Label: this._labels.TO,
                Value: reservation.To,
                ViewTemplate: TimeViewCellTemplate,
                EditTemplate: TimeEditCellTemplate,
                Editable: true,
                Visible: this._isToVisible,
                ClassName: 'to'
            }),
            new ValidationCellEditor({
                Name: "TimeSpent",
                Label: this._labels.TIME,
                Value: reservation.TimeSpent,
                ViewTemplate: TimeViewCellTemplate,
                EditTemplate: TimeEditCellTemplate,
                Editable: true,
                Visible: this._isDurationVisible,
                ClassName: 'duration'
            }),
            new DescriptionCellEditor({
                Name: "Description",
                Label: this._labels.DESCRIPTION,
                Value: reservation.Description,
                ViewTemplate: DescriptionViewCellTemplate,
                EditTemplate: DescriptionEditCellTemplate,
                Editable: true,
                Visible: ko.observable(true),
                RequiredRule: {
                    Validate: value => value && value.length > 0,
                    Message: this._labels.SPECIFY_RESERVATION_DESCRIPTION
                },
                MinLengthRule: {
                    Validate: value => value.length >= 3,
                    Message: this._labels.DESCRIPTION_MIN_LENGTH.replace('{minLengthValidation}', '3')
                },
                MaxLenghtRule: {
                    Validate: value => value.length <= 80,
                    Message: this._labels.DESCRIPTION_MAX_LENGTH.replace('{maxLengthValidation}', '80')
                },
                ClassName: 'description'
            }),
            new DropdownCellEditor({
                Name: "Hour",
                Label: this._labels.HOUR,
                Value: reservation.HourKind,
                Options: ko.observableArray(hourKindLookupField.Values),
                RequiresSearch: false,
                ValTableId: hourKindLookupField.ValTableId,
                ValFieldId: hourKindLookupField.ValFieldId,
                ValFieldTypeName: hourKindLookupField.ValFieldTypeName,
                ValFieldFormatName: hourKindLookupField.ValFieldFormatName,
                ValFieldSize: hourKindLookupField.ValFieldSize,
                ViewTemplate: DropdownViewCellTemplate,
                EditTemplate: DropdownEditCellTemplate,
                Editable: true,
                Visible: this._isHourVisible,
                ClassName: 'hour'
            }),
            new DropdownCellEditor({
                Name: "Ovw",
                Label: this._labels.OVW,
                Value: reservation.Ovw,
                Options: ko.observableArray(ovwLookupField.Values),
                RequiresSearch: false,
                ValTableId: ovwLookupField.ValTableId,
                ValFieldId: ovwLookupField.ValFieldId,
                ValFieldTypeName: ovwLookupField.ValFieldTypeName,
                ValFieldFormatName: ovwLookupField.ValFieldFormatName,
                ValFieldSize: ovwLookupField.ValFieldSize,
                ViewTemplate: DropdownViewCellTemplate,
                EditTemplate: DropdownEditCellTemplate,
                Editable: true,
                Visible: this._isOvwVisible,
                ClassName: 'ovw'
            }),
            new DropdownCellEditor({
                Name: "Pay",
                Label: this._labels.PAY,
                Value: reservation.Pay,
                Options: ko.observableArray(payLookupField.Values),
                RequiresSearch: false,
                ValTableId: payLookupField.ValTableId,
                ValFieldId: payLookupField.ValFieldId,
                ValFieldTypeName: payLookupField.ValFieldTypeName,
                ValFieldFormatName: payLookupField.ValFieldFormatName,
                ValFieldSize: payLookupField.ValFieldSize,
                ViewTemplate: DropdownViewCellTemplate,
                EditTemplate: DropdownEditCellTemplate,
                Editable: true,
                Visible: this._isPayVisible,
                ClassName: 'pay'
            }),
            ...customFieldCellEditors,
            new ActionsCellEditor({
                Name: "Actions",
                Label: this._labels.ACTIONS,
                ViewTemplate: ActionsViewCellTemplate,
                EditTemplate: ActionsEditCellTemplate,
                Visible: this._isEditable() ? this._isReadState : ko.observable(false),
                Accepted: reservation.Accepted,
                OnEditReservation: this.OnReservationEdit.bind(this, reservation.Guid),
                OnDeleteReservation: this.OnReservationDelete.bind(this, reservation.Guid),
                OnAcceptReservation: this.OnReservationAccept.bind(this, reservation.Guid),
                OnSaveReservationChanges: this.OnSaveReservationChanges.bind(this),
                OnCancelReservationChanges: this.OnCancelReservationChanges.bind(this, reservation.Guid),
                TimeStatus: reservation.TimeStatus(),
                ClassName: 'actions',
            })
        );
    }

    private GetLastReservationTime(): Date {
        const reservations = this._viewModel().RequestedDate.Reservations();
        const lastReservation = _.last(reservations);

        if (lastReservation) {
            const lastReservationTime = lastReservation.To();
            return lastReservationTime;
        }

        let startingDateTime = this._viewModel().StartBySchedule;

        //If startingTime by schedule is in future
        if (startingDateTime && startingDateTime > new Date()) {
            startingDateTime = null;
        }

        if (!startingDateTime) {
            const defaultStartingValue = this._viewModel().DefaultValues.find(item => item.Name === 'START');
            startingDateTime = defaultStartingValue && defaultStartingValue.DefaultValue && moment(FormatConverter.CorrectTimezone(defaultStartingValue.DefaultValue)).toDate();
        }

        if (startingDateTime) {
            const hours = moment(startingDateTime).hours();
            const minutes = moment(startingDateTime).minutes();
            const reservationTime = moment().hours(hours).minutes(minutes).seconds(0);
            return reservationTime.toDate();
        }

        const defaultReservationTime = moment(this._state().Date).hours(8).minutes(0).seconds(0).toDate();
        return defaultReservationTime;
    }

    private SearchSubject(reservation: Reservation) {
        const subject = reservation.Subject();

        const searchScreen = new SearchScreen({
            EntityId: subject.EntityId,
            SearchTerm: "",
            ButtonAdd: false,
            CloseAfterSelectingRecord: false
        });

        searchScreen.On("RECORD_SELECTED", this, eventArgs => this.SelectSubject(eventArgs.data, reservation, searchScreen));
        searchScreen.On("ALT_ENTITY_RECORD_SELECTED", this, (eventArgs) => {
            const data = eventArgs.data;
            UserVarsManager.Instance.AddRecent(data.EntityId, data.RecordId, data.TypeId);


            data.IsOpenInModal = false;
            PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, data);
        });


        searchScreen.Show();
    }

    private SearchSubjectForEntity(entity: AllowedEntity, reservation: Reservation) {
        const searchScreen = new SearchScreen({
            ConditionToggler: true,
            ControlId: this._settings.ControlId,
            EntityId: entity.Id,
            SearchTerm: "",
            ButtonAdd: false,
            CloseAfterSelectingRecord: false
        });

        searchScreen.On("RECORD_SELECTED", this, eventArgs => this.SelectSubjectForEntity(eventArgs.data, entity, reservation, searchScreen));
        searchScreen.On("ALT_ENTITY_RECORD_SELECTED", this, (eventArgs) => {
            const data = eventArgs.data;
            UserVarsManager.Instance.AddRecent(data.EntityId, data.RecordId, data.TypeId);


            data.IsOpenInModal = false;
            PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, data);
        });


        searchScreen.Show();
    }

    private SelectSubject(subjectData: any, reservation: Reservation, searchScreen: SearchScreen) {
        const oldSubject = reservation.Subject();

        const newSubject = new Subject({
            EntityId: oldSubject.EntityId,
            EntityName: oldSubject.EntityName,
            SubjectId: subjectData.RecordId,
            SubjectName: subjectData.Name,
            Icon: oldSubject.Icon
        });

        TimeWritingStore.ValidateTimeWritingSubject(subjectData.TableId, newSubject.SubjectId)
            .then(validationModel => {
                if (validationModel.IsValid) {
                    reservation.Subject(newSubject);
                    searchScreen.Close();
                } else {
                    const allowedTypes = validationModel.LowestLevelTypes.map(item => item.Name).join(', ');
                    new Notifier().Warning(NOTIFICATIONS.ONLY_FOLLOWING_TYPES_ARE_ALLOWED.replace('{LowestLevelTypes}', allowedTypes));
                }
            })
            .fail(error => new Notifier().Failed(error.message));
    }

    private SelectSubjectForEntity(subjectData: any, entity: AllowedEntity, reservation: Reservation, searchScreen: SearchScreen) {
        const newSubject = new Subject({
            EntityId: entity.Id,
            EntityName: entity.Name,
            SubjectId: subjectData.RecordId,
            SubjectName: subjectData.Name,
            Icon: entity.Icon
        });

        TimeWritingStore.ValidateTimeWritingSubject(subjectData.TableId, newSubject.SubjectId)
            .then(validationModel => {
                if (validationModel.IsValid) {
                    if (subjectData.Name) {
                        reservation.Subject(newSubject);
                    } else {
                        BlockUI.Block();

                        this.GetSelectedSubjectData(entity.Id, subjectData.RecordId)
                            .always(() => BlockUI.Unblock())
                            .then((subjectRecord: any) => {
                                const nameField = subjectRecord.Fields.find(f => f.FieldName === 'NAME');
                                if (nameField) {
                                    newSubject.SubjectName = nameField.FieldValue;
                                    reservation.Subject(newSubject);
                                }
                            });
                    }
                    searchScreen.Close();
                } else {
                    const allowedTypes = validationModel.LowestLevelTypes.map(item => item.Name).join(', ');
                    new Notifier().Warning(NOTIFICATIONS.ONLY_FOLLOWING_TYPES_ARE_ALLOWED.replace('{LowestLevelTypes}', allowedTypes));
                }
            })
            .fail(error => new Notifier().Failed(error.message));
    }

    private CreateReservation(reservation: SaveReservationRequestModel) {
        return TimeWritingStore.CreateReservation(reservation);
    }

    private AcceptReservation(reservation: SaveReservationRequestModel) {
        return TimeWritingStore.AcceptReservation(reservation);
    }

    private UpdateReservation(reservation: SaveReservationRequestModel) {
        return TimeWritingStore.UpdateReservation(reservation);
    }

    private DeleteReservation(reservation: DeleteReservationRequestModel) {
        return TimeWritingStore.DeleteReservation(reservation);
    }

    private CommitChanges(rowEditor: RowEditor) {
        rowEditor.SaveChanges();
        rowEditor.SetState(RowEditorState.Read);
        this._viewState(ViewState.Read);
        this.ShowNotification(NOTIFICATIONS.CHANGES_APPLIED);
    }

    private SaveAndReload(rowEditor: RowEditor) {
        this.CommitChanges(rowEditor);
        this.Show(this._state().Date);
    }

    private AskConfirmation(message: string, onConfirm: () => void, onDecline: () => void) {
        const confirmationDialog = new ConfirmationDialog({
            Text: message,
            Type: ConfirmationTypes.Question,
            TextConfirm: this._labels.YES,
            TextDecline: this._labels.CANCEL
        });

        confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => onConfirm());
        confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.DISCARD_SELECTED, this, () => onDecline());

        confirmationDialog.Show();
    }

    private AskSaveConfirmation(onConfirm: () => void, onDecline: () => void) {
        this.AskConfirmation(this._confirmations.SAVE_CHANGES, onConfirm, onDecline);
    }

    private AskDeleteConfirmation(onConfirm: () => void) {
        this.AskConfirmation(this._confirmations.ARE_YOUR_SURE_TO_DELETE_RECORDS, onConfirm, () => {
        });
    }

    private GetActiveRow() {
        return _.find(this._rowEditors(), (rowEditor: RowEditor) => rowEditor.IsNewState || rowEditor.IsEditState);
    }

    private SaveReservation(): P.Promise<RowEditor> {

        const result = P.defer<RowEditor>();

        const rowEditor = this.GetActiveRow();

        if (!rowEditor) {
            this._viewState(ViewState.Read);
            result.reject({message: NOTIFICATIONS.ROW_EDITOR_NOT_FOUND});
            return result.promise();
        }

        if (!rowEditor.Validate()) {
            result.reject({message: NOTIFICATIONS.FILL_REQUIRED_DATA});
            return result.promise();
        }

        const reservation = _.find(this._viewModel().RequestedDate.Reservations(), reservation => reservation.Guid === rowEditor.Guid);
        if (this._state().Date) {
            reservation.ChangeDate(this._state().Date);
        }

        const endTime = reservation.Start().getHours() +
            reservation.Start().getMinutes() / 60 +
            reservation.TimeSpent().getHours() +
            reservation.TimeSpent().getMinutes() / 60;

        if (endTime > 24) {
            result.reject({message: NOTIFICATIONS.DURATION_IS_TOO_LONG});
            return result.promise();
        }

        const updateModel = DayMappingProfile.OnSaveModel(reservation);

        updateModel.TimeTableId = this._settings.EntityId;
        updateModel.UserId = this._viewModel().SelectedUser.Id;

        const isNewReservation = rowEditor.IsNewState;

        if (isNewReservation) {
            this.CreateReservation(updateModel)
                .then(() => result.resolve(rowEditor))
                .fail(error => result.reject({message: error.message}));
        } else {
            this.UpdateReservation(updateModel)
                .then(() => result.resolve(rowEditor))
                .fail(error => result.reject({message: error.message}));
        }

        return result.promise();
    }

    private GetSelectedSubjectData(entityId: number, recordId: number) {
        return RecordStore.GetRecord({TableId: entityId, RecordId: recordId})
            .fail((error) => {
                if (error.requestError === SERVER_REQUEST_ERRORS.NOT_FOUND) {
                    new Notifier().Warning(LABELS.NO_ACCESS_LABEL);
                } else if (error.requestError === SERVER_REQUEST_ERRORS.INTERNAL) {
                    new Notifier().Failed('Error getting record');
                }
            });
    }
}