import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize, GenericDeserialize} from 'libs/cerialize';
import {LookupFieldModel} from 'Core/Screens/DesignScreen/ControlEditor/Models/LookupFieldModel';
import { ProcessCardPageModel } from '../Models/ProcessCardPageModel';

export class StepPageStore {
	static GetProcessCardPageScreens(): P.Promise<Array<ProcessCardPageModel>> {
		var deferredResult = P.defer<Array<ProcessCardPageModel>>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: "/api/FormDesignerApi/GetProcessCardPageScreens",
				dataType: "json",
				type: "GET"
			}
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(Deserialize(data, ProcessCardPageModel));
			});

		return deferredResult.promise();
	}

}