import * as ko from 'knockout';
import * as $ from 'jquery';
import * as _ from 'underscore';
import {IFormParam} from 'Core/Screens/IScreen'
import {IControl} from 'Core/Controls/IControl'
import {RenderModes} from 'Core/Constant'
import {BaseForm} from "Core/Screens/Forms/Base/BaseForm";

import ViewTemplate from 'Core/Screens/Forms/SubForm/Templates/View.html'
import DesignTemplate from 'Core/Screens/Forms/SubForm/Templates/Design.html'

ko.templates['Core/Screens/Forms/SubForm/Templates/View'] = ViewTemplate;
ko.templates['Core/Screens/Forms/SubForm/Templates/Design'] = DesignTemplate;

export class SubForm extends BaseForm {
    private _guid: string;
    private _showRemoveButton: boolean;
    private _showExpandButton: KnockoutObservable<boolean>;
    private _configuringLayout : KnockoutObservable<boolean>;

    constructor(params: IFormParam) {
        super(params);

        this._showRemoveButton = params.Screen.IsDashboard && params.RenderMode === RenderModes.Design;
        this._showExpandButton = ko.observable(false);
        this._configuringLayout = ko.observable(false);
        this._isExpanded = ko.observable(false);

        this.AddEvent("EXPANDED");
        this.AddEvent("COLLAPSED");
        this.AddEvent("AFTER_DROP_CONTROL");
        this.AddEvent("CONTROL_EDITOR_REQUESTED");
        this.AddEvent("REMOVE_SUB_FORM");
    }

    RenderDynamically(targetId: string, context) {
        const $target = $(`#${targetId}`);

        const wrapper = document.createElement('div');
        wrapper.innerHTML = `
            <!-- ko with: _subForm -->
                <!-- ko template: {name: GetTemplateName.bind($data)} -->
                <!-- /ko -->
            <!-- /ko -->
        `;
        ko.applyBindings(_.create(context, { _subForm: this }), wrapper);

        $target.append(wrapper);
    }

    GetTemplateName(): string {
        return this._renderMode === RenderModes.Design
            ? 'Core/Screens/Forms/SubForm/Templates/Design'
            : 'Core/Screens/Forms/SubForm/Templates/View';
    }

    get IsNew() {
        return !this._model.Id;
    }

    get ConfiguringLayout() {
        return this._configuringLayout();
    }

    set ShowExpandButton(showButton: boolean) {
        this._showExpandButton(showButton);
    }

    get IsExpanded(): boolean {
        return this._isExpanded();
    }

    StartLayoutConfiguration() {
        this._configuringLayout(true);
    }

    ApplyLayout() {
        this._configuringLayout(false);
    }

    Expand() {
        if (this._showExpandButton()) {
            this._isExpanded(!this._isExpanded());
            if (this._isExpanded()) {
                this.Trigger("EXPANDED");
            } else {
                this.Trigger("COLLAPSED");
            }
        }
    }

    ValidateExpandSubForm() {
        if (this._showExpandButton() && !this._isExpanded()) {
            this.Trigger("EXPANDED");
            this._isExpanded(true);
        }
    }

    ExpandOnControlClicking() {
        if (!this._isExpanded()) {
            this.Trigger("EXPANDED");
            this._isExpanded(!this._isExpanded());
        }
    }

    AfterDropControl(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext) {
        this.Trigger('AFTER_DROP_CONTROL', {
            item,
            sourceIndex,
            sourceItems,
            sourceContext,
            targetIndex,
            targetItems,
            targetContext
        });
    }

    ShowControlEditor(control: IControl) {
        this.Trigger('CONTROL_EDITOR_REQUESTED', {control});
    }

    Remove() {
        this.Trigger('REMOVE_SUB_FORM', {subForm: this});
    }
}