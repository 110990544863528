define(["jquery", "Core/Common/Guid"],  function (jQuery, guid) {
	(function ($) {
	"use strict";

	var MemoExtension = function (options) {
		this.init('memo', options, MemoExtension.defaults);
		this.editor = null;
	};

	$.fn.editableutils.inherit(MemoExtension, $.fn.editabletypes.abstractinput);
	$.extend(MemoExtension.prototype, {
		render: function () {
			var self = this;

			var uniqueId = guid.Guid.NewGuid();
			this.$input = this.$tpl;
			this.$input.attr('id', uniqueId);
			tinymce.baseURL = `${__webpack_public_path__}tinymce`;

			var tinymceOptions = {
				selector: '#' + uniqueId,
				plugins: [
					'advlist autolink lists link charmap print preview anchor',
					'searchreplace visualblocks code fullscreen'
				],
				toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | translations',
				content_css: [
					`${__webpack_public_path__}codepen.css`
				]
			};

			if (self.options.translations.length > 0) {
				self.options.newTranslations = [];

				self.options.translations.forEach(function (item, index) {
					self.options.newTranslations[index] = _.clone(item);
				});
				tinymceOptions.setup = function (editor) {
					var languages = [];
					self.options.newTranslations.forEach(function (item, index) {
						languages.push({
							type: 'menuitem',
							text: _.unescape(item.LanguageShortName),
							onAction: function () {

								var langObject = $(editor.editorContainer)
									.find('.tox-toolbar')
									.find('.tox-toolbar__group')
									.last()
									.find('.tox-tbtn__select-label');

								self.options.newTranslations[self.options.activeLangIndex].Value = self.editor.getContent();

								self.options.activeLangIndex = index;

								self.editor.setContent(_.unescape(self.options.newTranslations[self.options.activeLangIndex].Value));

								langObject.text(item.LanguageShortName);
							}
						});
					});

					var languageActive = languages[0].text;
					editor.ui.registry.addMenuButton('translations', {
						text: languageActive,
						fetch: function (callback) {
							var items = languages;
							callback(items);
						},
					});
				}
			}

			tinymce.init(tinymceOptions);

			this.editor = tinyMCE.get(uniqueId);

		},
		value2html: function (value, element) {
		},
		value2input: function (value) {

			var self = this;

			if (!value) {
				return;
			}

			if (this.options.translations.length > 0) {
				this.options.activeLangIndex = 0;
			}

			if (value.current != undefined && value.current != null) {
				var currentUnescaped = _.unescape(value.current);
				setTimeout( function() {
					self.editor.setContent(currentUnescaped);
				}, 100);
				return currentUnescaped;
			}

			if (value.default != undefined && value.default != null) {
				var defaultUnescaped = _.unescape(value.default);
				this.editor.setContent(defaultUnescaped);
			}
		},
		input2value: function () {
			var self = this;

			var content = self.editor.getContent();

			if (self.options.translations.length > 0) {
				self.options.newTranslations[self.options.activeLangIndex].Value = content;

				content = self.options.newTranslations[0].Value;

				self.options.newTranslations.forEach(function (item, index) {
					self.options.translations[index] = _.clone(item);
				});

				return {
					current: _.escape(content),
					translates: self.options.newTranslations

				}

			}

			return {
				current: _.escape(content)

			}

		}
	});

	MemoExtension.defaults = $.extend({}, $.fn.editabletypes.abstractinput.defaults, {
		tpl: '<textarea rows="5"></textarea>',
		newTranslations: null,
		translations: null,
		value: {
			default: '',
			current: ''
		},
		activeLangIndex: 0
	});

	$.fn.editabletypes.memo = MemoExtension;
}(jQuery))});