import * as ko from 'knockout'
import * as _ from 'underscore';
import {Event} from 'Core/Common/Event'
import {PlannerLevelRowModel, TimeCellModel} from "../Models/PlannerLevelRowModel";
import {RightBarCell} from "Core/Controls/PlannerLevel/RightBarCell/RightBarCell";
import {EVENTS} from "Core/Controls/PlannerLevel/Events";
import {PlannerLevel} from "../PlannerLevel";

import Template from 'Core/Controls/PlannerLevel/RightBarRow/Templates/RightBarRow.html';
ko.templates['Core/Controls/PlannerLevel/RightBarRow/Templates/RightBarRow'] = Template;

export interface IRightBarRowOptions {
    Model: PlannerLevelRowModel;
    Level: number;
    ReadOnly: boolean,
    HideQueryButtons: boolean;
}

export class RightBarRow extends Event{
    private _model: PlannerLevelRowModel;
    private _cells: KnockoutObservableArray<RightBarCell>;
    private _level: number;
    public _barClasses: KnockoutComputed<string>;
    private _readOnly: boolean;
    private _copyCellMode: boolean;
    private _hideQueryButtons: boolean;

    constructor(options: IRightBarRowOptions){
        super();
        this._model = options.Model;
        this._level = options.Level;
        this._hideQueryButtons = options.HideQueryButtons;
        this._cells = ko.observableArray([]);
        this._readOnly = options.ReadOnly;
        this._barClasses = ko.computed(()=>{
           return `level-${this._level} ${this._model.IsExpanded() ? 'open': ''} ${this._model.IsActive() ? 'active-row' : ''}`;
        });
        this.Init();
    }

    Init(){
        this.AddEvent(EVENTS.MOUSE_OVER);
        this.AddEvent(EVENTS.MOUSE_OUT);
        this.AddEvent(EVENTS.UNSELECT_CELLS);
        this.AddEvent(EVENTS.KEY_DOWN);
        this.AddEvent(EVENTS.UPDATE_PLAN);
        this.AddEvent(EVENTS.HIGHER_RESOLUTION_PLANNING);
        this.AddEvent(EVENTS.UNLINK_QUERY_RECORD);
        this.AddEvent(EVENTS.COPY_CELL);

        let cells = _.map(this._model.TimeLine, (timeCell) => {
            let cell = new RightBarCell(timeCell, this._model, this._readOnly, this._hideQueryButtons);
            cell.On(EVENTS.MOUSE_OVER, this, (eventArgs) =>{
                this.Trigger(EVENTS.MOUSE_OVER, { CellModel: eventArgs.data.CellModel } );
                this._model.IsActive(true);
            });

            cell.On(EVENTS.MOUSE_OUT, this, (eventArgs) =>{
                this.Trigger(EVENTS.MOUSE_OUT, { CellModel: eventArgs.data.CellModel } );
                this._model.IsActive(false);
            });

            cell.On(EVENTS.UNSELECT_CELLS, this, (eventArgs) =>{
                this.Trigger(EVENTS.UNSELECT_CELLS, { CellModel: eventArgs.data.CellModel } );
            });

            cell.On(EVENTS.KEY_DOWN, this, (eventArgs) =>{
                this.Trigger(EVENTS.KEY_DOWN, { CellModel: eventArgs.data.CellModel, Event: eventArgs.data.Event, RowModel: this._model, CellElement: eventArgs.data.CellElement } );
            });

            cell.On(EVENTS.UPDATE_PLAN, this, (eventArgs) =>{
                this.Trigger(EVENTS.UPDATE_PLAN, {
                    CellModel: eventArgs.data.CellModel,
                    Button1RecordId: eventArgs.data.Button1RecordId,
                    Button1TableId: eventArgs.data.Button1TableId,
                    Button2RecordId: eventArgs.data.Button2RecordId,
                    Button2TableId: eventArgs.data.Button2TableId,
                    RowModel: this._model,
                    CellElement: eventArgs.data.CellElement
                });
            });

            
            cell.On(EVENTS.UNLINK_QUERY_RECORD, this, (eventArgs) =>{
                this.Trigger(EVENTS.UNLINK_QUERY_RECORD, {
                    CellModel: eventArgs.data.CellModel,
                    ButtonRecordId: eventArgs.data.ButtonRecordId,
                    ButtonTableId: eventArgs.data.ButtonTableId,
                    RowModel: this._model,
                    CellElement: eventArgs.data.CellElement
                });
            });

            cell.On(EVENTS.HIGHER_RESOLUTION_PLANNING, this, (eventArgs) =>{
                this.Trigger(EVENTS.HIGHER_RESOLUTION_PLANNING, { CellModel: eventArgs.data.CellModel, RowModel: this._model } );
            });

            cell.On(EVENTS.COPY_CELL, this, () => {
                this.Trigger(EVENTS.COPY_CELL);
                
                this._copyCellMode = true;
                _.each(this._cells(), (item)=>{

                    item.CopyCellMode = true;
                    if(cell != item){
                        item.IsCopyFrom(false);
                    }                    
                    item.IsCopyTo(false);
                });

            });

            return cell;
        });
        this._cells(cells);
    }

    GetTemplateName(){
        return 'Core/Controls/PlannerLevel/RightBarRow/Templates/RightBarRow';
    }

    get IsExpanded(): KnockoutObservable<boolean>{
        return this._model.IsExpanded;
    }

    get SubControls(){
        return this._model.SubControls;
    }

    set IsHightResolutionPlanning(value: boolean){
        _.each(this._cells(), (cell) => {
            cell.IsHightResolutionPlanning = value;
        });

        _.each(this._model.SubControls(), (subControl)=>{
            if(subControl instanceof PlannerLevel){
                subControl.IsHightResolutionPlanning = value;
            }
        });
    }

    SelectCell(cellIndex: number){
        let cell = this._model.TimeLine[cellIndex];
        if(cell){
            cell.IsSelected(true);
        }
    }

    IsCopyToCellSelected(cellIndex: number){
        let selected = false;
        let cell = this._model.TimeLine[cellIndex];

        if(cell){
            selected = cell.IsCopyTo();
        }
        return selected;
    }

    SelectCopyToCell(cellIndex: number){
        let cell = this._model.TimeLine[cellIndex];
        if(cell){
            cell.IsCopyTo(true);
        }
    }

    SelectCopyFromCell(cellIndex: number){
        let cell = this._model.TimeLine[cellIndex];
        if(cell){
            cell.IsCopyFrom(true);
        }
    }

    get CopyFromCellIndex(): number{
        let copyFromCell = _.find(this._model.TimeLine, (item)=>{ return item.IsCopyFrom() });
        return this._model.TimeLine.indexOf(copyFromCell);
    }

    UnSelectCopyFromCells(){
        _.each(this._cells(), cell =>{
            cell.IsCopyFrom(false);
            cell.CopyCellMode = false;
        });
    }

    UnSelectCopyToCell(cellIndex: number){
        let cell = this._model.TimeLine[cellIndex];
        if(cell){
            cell.IsCopyTo(false);
        }
    }

    UnSelectCopyToCells(){
        _.each(this._cells(), cell =>{
            cell.IsCopyTo(false);
        });
    }

    get BarId(){
        return this._model.BarId;
    }

    UpdateCells(cellModels: Array<TimeCellModel>){
        _.each(cellModels, (cellModel) => {
            let cellToUpdate = _.find(this._cells(), (cell) =>{
                return cell.TimeAxis === cellModel.TimeAxis;
            });

            if(cellToUpdate){
                cellToUpdate.SetValue(cellModel);
            }
        });
    }

    get Height(): KnockoutObservable<number>{
        return this._model.RowHeight;
    }

    get RowColor(){
        return this._model.RowColor;
    }

    AfterRender(){}

    CopyCell(copyFromIndex: number, overwrite: boolean, copyQueryButton1: boolean, copyQueryButton2: boolean){
        let copyFromCell = this._cells()[copyFromIndex];
        _.each(this._cells(), (cell)=>{
            if(cell.IsCopyTo()){
                cell.SetCopyToValue(
                    copyFromCell.Value(),
                    copyFromCell.Model.Button1TableId,
                    copyFromCell.Model.Button1RecordId,
                    copyFromCell.Model.Button2TableId,
                    copyFromCell.Model.Button2RecordId,
                    overwrite,
                    copyQueryButton1,
                    copyQueryButton2
                );
                cell.IsCopyTo(false);
            }
        });
    }

    get HasData(): boolean{
        let hasData = false;
        _.each(this._cells(), (cell) =>{
            if(cell.IsCopyTo() && cell.HasData){
                hasData = true;
            }
        });
        return hasData;
    }

    get HasAnyData(): boolean{
        let hasData = false;
        _.each(this._cells(), (cell) =>{
            if(cell.HasData){
                hasData = true;
            }
        });
        return hasData;
    }
}