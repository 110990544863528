import { CanvasModelState } from 'Core/Controls/CanvasDesigner/Enums/CanvasModelState';
import { ICanvasLinkTargets } from 'Core/Controls/CanvasDesigner/Interfaces/ICanvasLinkTargets';
import { CanvasLinkModel } from 'Core/Controls/CanvasDesigner/Models/CanvasModel';

export class ArrowViewModel {
	id: string;
	type: string = 'cyberThing.Arrow';
	state: number = CanvasModelState.NoChanges;
	source: ICanvasLinkTargets;
	target: ICanvasLinkTargets;
	vertices: Array<string>;

	constructor(link: CanvasLinkModel) {
		this.vertices = [];
		this.id = link.CanvasItem1Guid + '' + link.CanvasItem2Guid;

		this.source = {
			id: link.CanvasItem1Guid,
			selector: '.port-body'
		};

		this.target = {
			id: link.CanvasItem2Guid,
			selector: '.port-body'
		};

		this.InitProperties(link.Properties);
	}

	private InitProperties(properties: string){
		try {
			let jsonProperties = JSON.parse(properties);
			if(jsonProperties.Rappid && jsonProperties.Rappid.vertices){
				this.vertices = jsonProperties.Rappid.vertices
			}
		}catch (e) {}
	}
}