import * as ko from 'knockout';

export class UnreportedModel {
    FormTitle: KnockoutObservable<string>;
    Error: KnockoutObservable<string>;

    constructor() {
        this.FormTitle = ko.observable(null);
        this.Error = ko.observable(null);
    }
}