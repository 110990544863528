import {RecordSecurityViewModel} from "Core/Controls/RecordSecurity/Models/RecordSecurityViewModel";

class RightUnitModel {
	constructor(
		public UserGroupId: number,
		public TableId: number,
		public RightsValue: number) { }
}

export class RecordSecuritySerializedModel {
	SecurityProfileId: number;
	RightUnits: Array<RightUnitModel>;
	CurrentUserGroup: number;

	ProfileReservationGroups: Array<number>;
	ProfileUnReservationGroups: Array<number>;

	constructor(securityProfileId: number, recordSecurityViewModel: RecordSecurityViewModel) {
		this.SecurityProfileId = securityProfileId;
		this.CurrentUserGroup = recordSecurityViewModel.UserGroupSelector.CurrentUserGroup().Id;
		this.RightUnits = [];
		this.ProfileReservationGroups = _.map(_.filter(recordSecurityViewModel.UserGroups, (userGroup) => userGroup.ProfileReservationId() > 0), (group) => group.Id);
		this.ProfileUnReservationGroups = _.map(_.filter(recordSecurityViewModel.UserGroups, (userGroup) => userGroup.ProfileReservationId() == 0 || userGroup.ProfileReservationId() == null), (group) => group.Id);

		recordSecurityViewModel.Tables.forEach(table => {
			let tableRights = table.Rights().map(right => new RightUnitModel(right.UserGroupId, table.Id, right.SelectedOption().Value));
			this.RightUnits = this.RightUnits.concat(tableRights);
		});
	}
}