import {deserialize, deserializeAs, serialize, serializeAs} from "libs/cerialize";

export interface IRecordPersonalizationParams {
	IsPersonalized: boolean;
	PossibleToPersonalize: boolean;
}

export class RecordPersonalizationModel {
	@deserialize
	IsPersonalized: boolean;
	@deserialize
	PossibleToPersonalize: boolean;

	constructor(params?: IRecordPersonalizationParams) {
		if (params) {
			this.IsPersonalized = params.IsPersonalized;
			this.PossibleToPersonalize = params.PossibleToPersonalize;
		}
	}
}