import * as ko from 'knockout'
import 'pdfjs-dist/build/pdf';

import { BaseControl, IControlValue} from 'Core/Controls/BaseControl/BaseControl'
import {IControlParam} from 'Core/Screens/IScreen'
import {CrystalReportViewerStore} from 'Core/Controls/CrystalViewer/Stores/CrystalReportViewerStore';
import { BlockUI } from 'Core/Common/BlockUi';
import { RenderModes } from 'Core/Constant';
import { FileDownloader } from 'Core/Components/FileDownloader/FileDownloader';
import { Notifier } from 'Core/Common/Notifier';

import { GeneralProperties } from 'Core/GeneralProperties/GeneralProperties';
import ConfigJson from 'Core/Controls/CrystalViewer/Configs/crystal-report-viewer-config.json';

import ViewTemplate from 'Core/Controls/CrystalViewer/Templates/View.html'
import ToolBarTemplate from 'Core/Controls/CrystalViewer/Templates/ToolBar.html'
import DesignTemplate from 'Core/Controls/CrystalViewer/Templates/Design.html'

ko.templates['Core/Controls/CrystalViewer/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/CrystalViewer/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/CrystalViewer/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/CrystalViewer/Templates/Edit'] = ViewTemplate;

interface IExportFormat {
	Title: string;
	Value: number;
	Extention: string;
}

const EXPORT_FORMATS: Array<IExportFormat> = [
	{
		Title: 'PDF',
		Value: 5,
		Extention: '.pdf'
	},
	{
		Title: 'Excel',
		Value: 4,
		Extention: '.xls'
	},
	{
		Title: 'Word',
		Value: 3,
		Extention: '.doc'
	}
];

const PROPERTIES = {
	CRYSTAL_REPORT: 'CrystalReport'
}

//TODO move PDF viewer to components
export class CrystalReportViewer extends BaseControl  {
	private _content: Uint8Array;
	private _canvasContext: KnockoutObservable<CanvasRenderingContext2D>;
	private _canvasWidth: KnockoutObservable<number>;
	private _canvasHeight: KnockoutObservable<number>;
	private _pdfDocument: PDFDocumentProxy;
	private _loadInProgress: boolean;
	private _currentPage: KnockoutObservable<number>;
	private _totalPages: KnockoutObservable<number>;
	private _scale: number;
	private _exportFormats = EXPORT_FORMATS;
	private _exportFormat: KnockoutObservable<IExportFormat>;
	private _enablePreviousPageButton: KnockoutObservable<boolean>;
	private _enableNextPageButton: KnockoutObservable<boolean>;
	private _fileName: KnockoutObservable<string>;
	
	private _hasData: KnockoutObservable<boolean>;

	constructor(params: IControlParam) {
		super(params, ConfigJson);
		this._exportFormat = ko.observable(null);
		this._canvasContext = ko.observable(null);
		this._canvasWidth = ko.observable(0);
		this._canvasHeight = ko.observable(0);
		this._pdfDocument = null;
		this._loadInProgress = false;
		this._currentPage = ko.observable(null);
		this._totalPages = ko.observable(null);
		this._scale = 1;
		this._enablePreviousPageButton = ko.observable(false);
		this._enableNextPageButton = ko.observable(false);
		this._hasData = ko.observable(false);
		this._fileName = ko.observable('');

		if (this._renderMode() === RenderModes.View) {

			this._isRendered.subscribe(() => { this.LoadReport(); });

			this._exportFormat.subscribe((newValue) => {
				if (newValue) {
					this.ExportReport(newValue);
				}
			});
		}

		this._currentPage.subscribe((newValue) => {
			if (newValue) {
				this._enablePreviousPageButton(newValue > 1);
				this._enableNextPageButton(newValue + 1<= this._totalPages());
			}
		});

		this._canvasContext.subscribe((newValue) => {
			if (newValue) {
				if (this._hasData()) {
					this.RenderPage();
				}
			}
		});

		this.Init();
	}

	private Init(): void {
		this.ApplyProperties();
	}

	ApplyProperties() {
		const crystalReport = this.GeneralProperties.GetPropertyValue(PROPERTIES.CRYSTAL_REPORT);

		if (crystalReport) {
			this._fileName(crystalReport.FileName);
		}
	}

	SetValue(value: IControlValue) {
	}

	LoadReport() {
		this._loadInProgress = true;
		CrystalReportViewerStore.RunReport({ ControlId: this.GetControlId(), ExportFormat: 5 })
			.always(() => {
				this._loadInProgress = false;
				BlockUI.Unblock( this._el );
			})
			.then((content) => {
				this.DisplayReport(content);
			}).fail((err) => {
				var notifier = new Notifier(null);
				notifier.Failed(err.message);
			});
	}

	ExportReport(format: IExportFormat) {
		BlockUI.Block({ Target: this._el });
		CrystalReportViewerStore.RunReport({ ControlId: this.GetControlId(), ExportFormat: format.Value })
			.always(() => {
				BlockUI.Unblock(this._el);
			})
			.then((base64) => {
				this.DownloadReport(base64, format.Extention);
			}).fail((err) => {
				var notifier = new Notifier(null);
				notifier.Failed(err.message);
			});;
	}

	DownloadReport(base64: string, extention: string) {
		FileDownloader.DownloadBase64(base64, `report${extention}`);
	}

	DisplayReport(base64Pdf: string) {
		var byteArray = this.Base64ToUint8Array(base64Pdf);
		PDFJS.disableWorker = true;
		PDFJS.getDocument({ data: byteArray }).then((pdf) => {
			this._pdfDocument = pdf;
			this._totalPages(pdf.numPages);
			this._hasData(true);
			this._currentPage(1);
			this.RenderPage();
		});
	}

	RenderPage() {
		this._pdfDocument.getPage(this._currentPage()).then((page) => {
			var viewport = page.getViewport(this._scale);
			this._canvasWidth(viewport.width);
			this._canvasHeight(viewport.height);

			if (this._canvasContext()) {
				var renderContext = {
					canvasContext: this._canvasContext(),
					viewport: viewport
				};

				var renderTask = page.render(renderContext);
				renderTask.then(() => { });
			}
		});
	}

	Base64ToUint8Array(base64Pdf: string): Uint8Array {
		var pdfData = atob(base64Pdf);
		var array = new Uint8Array(new ArrayBuffer(pdfData.length));
		for (var i = 0; i < pdfData.length; i++) {
			array[i] = pdfData.charCodeAt(i);
		};
		return array;
	}

	AfterRender(el: Array<HTMLElement>): void {
		super.AfterRender(el);

		if (this._loadInProgress) {
			BlockUI.Block({ Target: this._el });
		}
	}

	FirstPage() {
		this._currentPage(1);
		this.RenderPage();
	}

	LastPage() {
		this._currentPage(this._totalPages());
		this.RenderPage();
	}

	PreviousPage() {
		if (this._enablePreviousPageButton()) {
			this._currentPage(this._currentPage() - 1);
			this.RenderPage();
		}
	}

	NextPage() {
		if (this._enableNextPageButton()) {
			this._currentPage(this._currentPage() + 1);
			this.RenderPage();
		}
	}

	ZoomOut() {
		if(this._scale === 0.5) {
			return;
		}
		this._scale = this._scale - 0.5;
		this.RenderPage();
	}

	ZoomIn() {
		if(this._scale === 4) {
			return;
		}
		this._scale = this._scale + 0.5;
		this.RenderPage();
	}
}