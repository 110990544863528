import * as _ from 'underscore';
import { TableSecurityUnitModel } from 'QueryBuilder/Models/TableSecurityUnitModel';
import { Event } from 'Core/Common/Event';
import { ENTITY_COLORS } from 'QueryBuilder/Constants';
import Template from 'QueryBuilder/QueryBootstrap/Templates/QueryBootstrap.html';
import { EVENTS } from 'QueryBuilder/QueryBootstrap/Events';

export class QueryBootstrap extends Event {
	private _entities: Array<TableSecurityUnitModel>;


	constructor(entities: Array<TableSecurityUnitModel>) {
		super();
		this._entities = _.filter(entities, (entity)=>{
			return !entity.Name.startsWith('SYS_');
		});

		this.AddEvent(EVENTS.ENTITY_SELECTED);
		this.InitDropdownEvents();
	}

	get BackgroundColor(): string {
		return ENTITY_COLORS.MAIN_ENTITY;
	}

	private InitDropdownEvents() {
		setTimeout(() => {
			$('#entitiesDropdownQueryBootstrap').on('show.bs.dropdown', function () {
				const styling: any = {
					position: 'absolute',
					left: 0
				};
				const $dropdownMenu = $('#entitiesDropdownQueryBootstrap .dropdown-menu');
				styling.top = 43;
				styling['box-shadow'] = '5px 5px rgba(102, 102, 102, 0.5)';
				$dropdownMenu.css(styling).addClass('queryBuilderDropdown');
			});
		});
	}

	GetTemplateHtml() {
		return Template;
	}

	SelectEntity(entity: TableSecurityUnitModel) {
		this.Trigger(EVENTS.ENTITY_SELECTED, { EntityId: entity.Id });
		$('.jBox-content').css('overflow', 'auto');

	}
}