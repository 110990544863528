import * as ko from 'knockout';
import {IControlParam} from "Core/Screens/IScreen";
import {BaseControl} from "Core/Controls/BaseControl/BaseControl";
import ViewTemplate from 'Core/Controls/PortletSpace/Templates/View.html';
import ToolBarTemplate from 'Core/Controls/PortletSpace/Templates/ToolBar.html';
import DesignTemplate from 'Core/Controls/PortletSpace/Templates/Design.html';
import {RenderModes} from "Core/Constant";
import {Guid} from "Core/Common/Guid";

ko.templates['Core/Controls/PortletSpace/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/PortletSpace/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/PortletSpace/Templates/Design'] = DesignTemplate;

export class PortletSpace extends BaseControl {
	private _portletSpaceManager: KnockoutObservable<any>;

	constructor(params: IControlParam) {
		super(params);

		this._portletSpaceManager = ko.observable(null);
	}

	ApplyProperties(){}

	AfterRender(container: Array<HTMLElement>): void {
		super.AfterRender(container);

		if (this._renderMode() === RenderModes.View) {
			const portletSpaceManagerModule = require('Core/Portlets/Managers/Runtime/PortletSpaceManager');

			const screen = this.GetForm().GetScreen();
			const portletSpaceManager = new portletSpaceManagerModule.PortletSpaceManager({
				Subject: screen.IsDashboard ? null : {
					EntityId: screen.GetEntityId(),
					RecordId: screen.GetRecordId()
				},
				Control: {
					Id: this._model().Id
				}
			});

			portletSpaceManager.Load();

			this._portletSpaceManager(portletSpaceManager);
		}
	}
}