import * as ko from 'knockout';
import * as _ from 'underscore';
import {BlockUI} from 'Core/Common/BlockUi';

import {Text} from 'Core/Controls/Text/Text'
import {ICell} from 'Core/ProfilePage/Deduplication/DeduplicationEditor/ICell';
import {DeduplicationStore, Actions} from 'Core/ProfilePage/Deduplication/Stores/DeduplicationStore';
import {Notifier} from 'Core/Common/Notifier';
import {DeduplicationGrid} from 'Core/ProfilePage/Deduplication/DeduplicationEditor/DeduplicationGrid';

import Template from 'Core/ProfilePage/Deduplication/DeduplicationEditor/Templates/DeduplicationCell.html';

import {FIELD_TYPES} from 'Core/Constant'
import {EVENTS} from "Core/Controls/Grid/BaseGrid/Events";
import enumerable from '../../../Common/Decorators/EnumerableDecorator';


export class DeduplicationCell implements ICell {
	private _grid: DeduplicationGrid;

	private _fieldName: string;
	public _fieldId: number;
	private _value: KnockoutObservable<string>;
	private _type: string;
	private _types = FIELD_TYPES;
	private _isEditMode: KnockoutObservable<boolean>;
	private _editor: KnockoutObservable<Text>;
	private _popUpTriggerAction: boolean = false;
	private _popUpAction: boolean = false;
	private _kSeq: number;
	private _entityId: number;
	private _entityName: string;
	public _selectionType: KnockoutObservable<string>;
	private _selectedAlignedTd: KnockoutObservable<boolean>;
	private _parentRow: any;
	private _deduplicationInstance: any;
	private _tooltipValue: KnockoutObservable<string>;
	private _leftRecordId: number;
	private _rightRecordId: number;

	constructor(fieldId: number,
				value: string,
				type: string,
				row?: any,
				IsSelected?: boolean,
				SelectionType?: string,
				actionType?: string,
				kSeq?: number,
				entityId?: number,
				entityName?: string,
				deduplicationInstance?: any) {
		this._type = type;

		let endValue = value;
		this._tooltipValue = ko.observable('');

		if (endValue && endValue.length > 25) {
			endValue = endValue.substr(0, 25) + '...';
			this._tooltipValue(value);
			if (this._type === FIELD_TYPES.Memo) {
				endValue = '...';
			}
		}

		this._value = ko.observable(endValue);
		this._kSeq = kSeq;
		this._entityId = entityId;
		this._entityName = entityName;
		this._selectedAlignedTd = ko.observable(false);
		this._parentRow = row;
		this._fieldId = fieldId;
		this._selectionType = ko.observable(SelectionType);
		this._deduplicationInstance = deduplicationInstance;

		if (actionType === 'popUpTrigger') {
			this._popUpTriggerAction = true;
		} else if (actionType === 'popUpAction') {


			this._popUpAction = true;

		} else if (actionType === 'popUpData') {
			if (IsSelected) {
				this._selectedAlignedTd(true)
			}
		}
		this._grid = new DeduplicationGrid();
		this._grid.On(EVENTS.SAVE_CHANGES, this, ()=> this.SaveChanges());
	}

	GetTemplate() {
		return Template;
	}


	SaveChanges() {
		BlockUI.Block();

		const changesToSave = [];
		let Deduplication = this._grid.Rows()[0].Model.Deduplication;
		this._grid.Rows().map((row: any) => {
			if (row._disabled()) return;
			let fieldId = null;
			let actionType = null
			row.Cells().map((cell: DeduplicationCell) => {
				if (cell._fieldId) {
					fieldId = cell._fieldId;
				}
				if (cell._selectionType()) {
					actionType = cell._selectionType();

				}
			});
			if (fieldId && actionType && actionType !== 'leftSide') {
				changesToSave.push({
					FieldId: fieldId,
					Action: Actions[actionType]
				});
			}

		});

        
        const params = {
            EntityId: this._entityId,
            KSeq: this._kSeq,
            Changes: [...changesToSave]
        }

		DeduplicationStore.DeduplicateMatched(params).always(() => {
			BlockUI.Unblock();
		}).then(result => {
			if (!result.IsSuccessfull) {
				new Notifier().Failed(result.ErrorMessage);
				return;
			}

			this._grid.Cancel();
			this._deduplicationInstance.GetRecordsList();

		}).fail((err) => {
			new Notifier().Failed(err.message);
		});
	}


	GetDeduplicationRecord() {

		this.InitLockWithRequest();


	}

	InitLockWithRequest() {
		const params = {
			EntityId: this._entityId,
			KSeq: this._kSeq
		};

		DeduplicationStore.GetDeduplicationMatchedRecordModel(params).then(result => {
			if (!result.IsSuccessfull) {
				new Notifier().Failed(result.ErrorMessage);
				return;
			}

			this._grid.RenderModal();
			BlockUI.Unblock(this._grid.GetModal().Wrapper);

			this._grid.SetPopUpData(
				result.ResultObject,
				this._entityName,
				this._deduplicationInstance,
				this._entityId,
				this._kSeq
			);
		}).fail((err) => {
			new Notifier().Failed(err.message);
		});
	}


	SelectAction(selectionToActivate) {
		const cells: Array<DeduplicationCell> = this._parentRow.Cells()
		const indexOfActionCell = _.findIndex(cells,
			(cell: DeduplicationCell) => {
				return cell._popUpAction;
			});
		this._selectionType(selectionToActivate);
		cells && cells.map((cell) => {
			cell._selectedAlignedTd(false);
		});
		if (selectionToActivate === 'leftSide') {
			cells[indexOfActionCell - 1]._selectedAlignedTd(true);
		} else if (selectionToActivate === 'merge') {
			cells[indexOfActionCell - 1]._selectedAlignedTd(true);
			cells[indexOfActionCell + 1]._selectedAlignedTd(true);
		} else if (selectionToActivate === 'rightSide') {
			cells[indexOfActionCell + 1]._selectedAlignedTd(true);
		}
	}


	AfterRender() {
	}

	@enumerable get Value(): KnockoutObservable<string> {
		return this._value;
	}
}