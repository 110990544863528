import * as ko from "knockout"
import 'jquery';

export class OptionExtention {
	static Init() {
		ko.bindingHandlers.option = {
			update: (element, valueAccessor) => {
				var value = ko.utils.unwrapObservable(valueAccessor());
				ko.selectExtensions.writeValue(element, value);
			}
		};
	}
}