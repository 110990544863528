import * as ko from 'knockout';
import * as _ from 'underscore';

import {BaseControlEditor} from "../BaseControlEditor";
import {IControl} from 'Core/Controls/IControl';

import Template from 'Core/Screens/DesignScreen/ControlEditor/Templates/RecipeEditorControlEditor.html';
import {BlockUI} from "../../../../../Common/BlockUi";
ko.templates['Core/Screens/DesignScreen/ControlEditor/Templates/RecipeEditorControlEditor'] = Template;

export class RecipeEditorControlEditor extends BaseControlEditor {

    constructor(control: IControl) {
        super(control);

        this.InitEditControlData(control);
    }

    protected InitEditControlData(control: IControl) {
        super.InitEditControlData(control);
        // this.InitEntities();
    }

    GetTemplateName(): string {
        return 'Core/Screens/DesignScreen/ControlEditor/Templates/RecipeEditorControlEditor';
    }

    AfterRender(el: HTMLElement) {
        const container = el[0];
        super.AfterRender(container);

        // BlockUI.Block();
        // this.LoadData()
        //     .then(entities => {
        //         this.PopulateEntities(entities)
        //     })
        //     .always(() => BlockUI.Unblock());
    }

    // private InitEntities() {
    //     this._entities = ko.observableArray([]);
    //     this._selectedEntity = ko.observable(null);
    //
    //     this._fields = ko.observableArray([]);
    //     this._selectedField = ko.observable(null);
    // }
}