import * as ko from 'knockout';
import * as _ from 'underscore';

import {BaseControl, IControlValue} from 'Core/Controls/BaseControl/BaseControl';
import {IControlParam} from 'Core/Screens/IScreen';

import EditTemplate from 'Core/Controls/Checkbox/Templates/Edit.html';
import ViewTemplate from 'Core/Controls/Checkbox/Templates/View.html';
import HelpViewTemplate from 'Core/Controls/Checkbox/Templates/HelpView.html';
import ToolBarTemplate from 'Core/Controls/Checkbox/Templates/ToolBar.html';
import DesignTemplate from 'Core/Controls/Checkbox/Templates/Design.html';
import {ScreenTypes} from "../../Common/Enums/ScreenTypes";
import {CONTROL_TYPES, FONT_STYLES} from "../../Constant";
import Config from 'Core/Controls/Checkbox/Configs/config.json';

ko.templates['Core/Controls/Checkbox/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Checkbox/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Checkbox/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/Checkbox/Templates/Edit'] = EditTemplate;
ko.templates['Core/Controls/Checkbox/Templates/Design'] = DesignTemplate;

export class Checkbox extends BaseControl {
	private _checked: KnockoutObservable<boolean>;
	private _originalValue: boolean;
	private _labelStyle: KnockoutObservable<any>;
	private _textInputStyle: KnockoutObservable<any>;

	constructor(params: IControlParam) {
		super(params, Config);
		this._checked = ko.observable(false);
		this._labelStyle = ko.observable(null);
		this._textInputStyle = ko.observable(null);
		this.Init();

		this.ApplyProperties();
	}

	ApplyProperties() {
		if (this.Properties) {
			//BackgroundColor
			if (this.Properties.BackgroundColor) {
				const backgroundColor = this.Properties.BackgroundColor;
				this._backgroundColor(backgroundColor);
			}

			//Label
			if (this.Properties.Label) {
				var labelStyle = {backgroundColor: null, color: null};
				_.each(this.Properties.Label.Properties, (property: any) => {
					if (property.BackgroundColor) {
						labelStyle.backgroundColor = property.BackgroundColor;
					}

					if (property.Color) {
						labelStyle.color = property.Color;
					}
				});

				this._labelStyle(labelStyle);
			}
		}
		if (this.Properties) {
			//TextInput
			if (this.Properties.TextInput) {
				var textInputStyle = {backgroundColor: null};
				_.each(this.Properties.TextInput.Properties, (property: any) => {
					if (property.BackgroundColor) {
						textInputStyle.backgroundColor = property.BackgroundColor;
					}
				});

				this._textInputStyle(textInputStyle);
			}
		}
		if (this._form && (this._form.GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.LinkEditor])) {
			this.ApplyLinkEditorStyles();
		}

		if(this.HideIfCondition){
            if(this.IsRunTime){
                this._isVisible(false);
            }
        }
	}

	private ApplyLinkEditorStyles() {
		if(!this.FieldModel.HasLinkEditorVisibility){
			return;
		}
		const labelStyle = {
			color: null,
			fontWeight: null,
			fontStyle: null,
			textDecoration: null
		};
		if (this.FieldModel.FontColor) {
			labelStyle.color = this.FieldModel.FontColor;
		}
		labelStyle.fontWeight = FONT_STYLES.NORMAL;

		if (this.FieldModel.FontStyles) {
			_.forEach(this.FieldModel.FontStyles, (style) => {

				switch ( style.Name.toLowerCase() ) {
					case FONT_STYLES.BOLD:
						labelStyle.fontWeight = FONT_STYLES.BOLD;
						break;
					case FONT_STYLES.UNDERLINE:
						labelStyle.textDecoration = FONT_STYLES.UNDERLINE;
						break;
					case FONT_STYLES.ITALIC:
						labelStyle.fontStyle = FONT_STYLES.ITALIC;
						break;
				}
			})
		}

		this.Extend(labelStyle, this._labelStyle());
		this._labelStyle(labelStyle);
	}

	private Init(): void {
	}

	SetValue(value: IControlValue): void {
		var isChecked = value.Data ? value.Data.Value === 'True'
			|| value.Data.Value === true : false;
		this._originalValue = isChecked;

		this._checked(isChecked);

		this._checked.subscribe(() => 
			this.UpdateVariable({ Field: this.GetFieldModel(), ControlType: CONTROL_TYPES.Checkbox }, this._checked().toString())
		);
	}

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}

	get IsChecked(): KnockoutObservable<boolean> {
		return this._checked;
	}

	Deserialize() {
		var field = _.first(this._model().Fields);
		if (field) {
			return [`${field.EntityName}.${field.Name}`, this._checked() ? 'True' : 'False'];
		}
		return null;
	}

	IsModified(): boolean {
		return super.IsModified() || this._originalValue !== this._checked();
	}

	GetValue(): any {
		return this._checked();
    }

    IsValid(): boolean {
      
        if (this._isRequired) {
            this._isValid(this._checked());
        } else {
            this._isValid(true);
        }

        return this._isValid();
    }
}