import * as _ from 'underscore';

import { URL } from 'Core/Common/Url';
import { Request, IRequestOptions } from 'Core/Common/Request';
import { P } from 'Core/Common/Promise';
import { GenericDeserialize } from 'libs/cerialize';
import { BlockUI } from "Core/Common/BlockUi";

export interface ITranslation {
	[code: number]: string;
}

export class TranslationStore {
	static Get(): P.Promise<ITranslation> {
		var deferredResult = P.defer<ITranslation>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/UserApi/GetTranslations',
				dataType: 'json',
				type: 'GET'
			}
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data.ResultObject);
			});

		return deferredResult.promise();
	}

	static GetTranslationsForDb(params: { DbName: string, Language: string }): P.Promise<ITranslation> {
		var deferredResult = P.defer<ITranslation>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/UserApi/GetTranslationsForDb',
				dataType: 'json',
				type: 'GET'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data.ResultObject);
			}).fail((data)=>{
				let jsonResult = JSON.parse(data.message);
				deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			});

		return deferredResult.promise();
	}

	static GetLanguages() {
		return Request.Send({
			proxy: {
				url: '/api/UserApi/GetLanguages',
				type: 'GET'
			}
		});
	}
}