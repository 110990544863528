import {deserialize, deserializeAs} from 'libs/cerialize';

export class LanguageModel {
	@deserialize
	K_Language: number;
}

export class TranslationModel {
	@deserialize
	Translation: string;


	@deserialize
	Language: LanguageModel;
}