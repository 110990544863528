import {deserialize, deserializeAs, serialize, serializeAs} from "libs/cerialize";
import { ReferenceTableModel } from "./ReferenceTableModel";
import { SelectedEntityRecordModel } from "./SelectedEntityRecordModel";

export class ReferenceModel {
	@deserializeAs(SelectedEntityRecordModel)
	SelectedEntityRecord: SelectedEntityRecordModel;

	@deserializeAs(ReferenceTableModel)
	ReferenceTable: ReferenceTableModel;

	@deserialize
	IsModified: boolean;

	@deserialize
	Name: string;

	@deserialize
	RecordId: number;
}