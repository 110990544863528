import {Event} from 'Core/Common/Event';
import {PriorityManager} from 'FieldCollection/PriorityManager/PriorityManager';

import FieldTableRowTemplate from 'FieldCollection/FieldTableRow/FieldTableRow.html';

import {IViewModelField} from 'FieldCollection/Models/IViewModel';

export class FieldTableRow extends Event {
	public _fieldRow: IViewModelField;
	private _priorityManager: PriorityManager;

	constructor(fieldRow: IViewModelField) {
		super();

		this._fieldRow = fieldRow;
	}

	GetTemplate() {
		return FieldTableRowTemplate;
	}

	ToggleActive(field): void {
		if (this._fieldRow.isRequired) return;

		this._priorityManager = new PriorityManager();

		this._priorityManager.On('DowngradeDone', this, () => {
			this.Trigger('FieldToggled', {Data: 'Success'});
		});

		this._priorityManager.RestrictDowngrade(this._fieldRow, false, field);
	}
}