export class CurrentLifeStatusDto {
    Id: number;
    Name: string;
    Memo: string;
    MemoTranslation: string;
    LifeStatusTranslatedName: string;
    LifestatusNoActionNode: boolean;
    IsFollowUpDisabled: boolean;
    DisabledReason: string;

    constructor(partial?: Partial<CurrentLifeStatusDto>) {
        if (partial) {
            this.Id = partial.Id;
            this.Name = partial.Name;
            this.Memo = partial.Memo;
            this.MemoTranslation = partial.MemoTranslation;
            this.LifeStatusTranslatedName = partial.LifeStatusTranslatedName;
            this.LifestatusNoActionNode = partial.LifestatusNoActionNode;
            this.DisabledReason = partial.DisabledReason;
            this.IsFollowUpDisabled = partial.IsFollowUpDisabled;
        }
    }
}