import * as _ from 'underscore';

import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import { P } from 'Core/Common/Promise';
import { Deserialize } from 'libs/cerialize';
import { TranslationModel } from 'Pages/TransaltionEditorPage/Models/TranslationModel';
import { TranslationTypeModel } from "Pages/TransaltionEditorPage/Models/TranslationTypeModel";
import { UpdateTranslationModel } from 'Pages/TransaltionEditorPage/Models/UpdateTranslationModel';

export interface IGetTranslationRequestModel {
	SearchValue?: string;
	PageNumber: number;
	RecordsPerPage: number;
	TranslationType?: number;
}

export interface IGetTranslationResponseModel {
	Translations: Array<TranslationModel>;
	TranslationColumns: Array<string>,
	TotalRecords: number;
}

export class TranslationEditorStore {
	static GetTranslations(params: IGetTranslationRequestModel): P.Promise<IGetTranslationResponseModel> {
		var deferredResult = P.defer<IGetTranslationResponseModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/TranslationsrApi/GetTranslations',
				dataType: "json",
				type: "POST"
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (!data.IsSuccessfull) {
					deferredResult.reject({ message: data.ErrorMessage });
				} else {
					deferredResult.resolve({
						Translations: this.Deserialize(data.ResultObject.Translations),
						TranslationColumns: data.ResultObject.TranslationColumns,
						TotalRecords: parseInt(data.ResultObject.TotalRecords)
					});
				}
			});

		return deferredResult.promise();
	}

	static GetTranslationTypes(): P.Promise<TranslationTypeModel> {
		const deferredResult = P.defer<TranslationTypeModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/TranslationsrApi/GetTranslationTypes',
				dataType: "json",
				type: "POST"
			}
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
			}).fail(data => {
				let jsonResult = JSON.parse(data.message);
				deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			});

		return deferredResult.promise();
	}

	static Update(params: UpdateTranslationModel): P.Promise<Array<any>> {
		var deferredResult = P.defer<Array<any>>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/TranslationsrApi/UpdateTranslation',
				dataType: "json",
				type: "POST"
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (!data.IsSuccessfull) {
					deferredResult.reject({ message: data.ErrorMessage });
				} else {
					deferredResult.resolve(null);
				}
			});

		return deferredResult.promise();
	}

	static Deserialize(jsonObject: any): Array<TranslationModel> {
		var model = Deserialize(jsonObject, TranslationModel);
		return model;
	}
}