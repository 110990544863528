import {deserialize, deserializeAs, serialize} from 'libs/cerialize';

export class PersonalSettingsModel {
	@deserialize
    Name: string;

    @deserialize
    TranslatedName: string;

	@deserialize
	Id: number;

	@deserialize
	TypeId: number;

	@deserialize
	Value: string | boolean | number;

	@deserialize
	TypeName: string;

	@deserialize
	Description: string;

	@deserialize
	DescriptionTranslation: string;

	@deserialize
	KeepInSession: boolean;

	@deserialize
	LockToDefault: boolean;

}

export class PersonalSettingsUpdateModel {

	@serialize
	Id: number;

	@serialize
	Value: string | boolean | number;

}