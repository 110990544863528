import * as ko from 'knockout';

import {EVENTS} from "Core/Constant";

import {IButtonConfig} from "Core/Controls/PortletBar/Interfaces/IButtonConfig";
import {PortletBarButton} from "Core/Controls/PortletBar/Managers/Buttons/PortletBarButton";
import {ExpandButtonViewModel} from "Core/Controls/PortletBar/Models/Buttons/ExpandButtonViewModel";
import {ITooltipConfig} from "Core/KnockoutExtentions/TooltipExtention";
import {LABELS} from "Core/Components/Translation/Locales";

export class ExpandButton extends PortletBarButton {
    ViewModel: ExpandButtonViewModel;

    constructor(buttonName: string, buttonConfig: IButtonConfig) {
        super(buttonConfig);
        this.ViewModel = new ExpandButtonViewModel(buttonName, buttonConfig);
        this.Tooltip = ko.observable<ITooltipConfig>({content: LABELS.MAXIMIZE, outside: 'y', position: {x: 'center', y: LABELS.MAXIMIZE.length <= 8 ? 'top' : 'bottom'}});
    }

    OnClickEvent() {
        return EVENTS.PORTLETS.PORTLET_BAR.CONTROL_BUTTONS.EXPAND_BUTTON_CLICKED;
    }

    OnClick() {
        this.ViewModel.IsExpanded = !this.ViewModel.IsExpanded;
        this.UpdateTooltip();
        this.Trigger(this.OnClickEvent());
    }

    protected GetButtonTemplateFolder() {
        return `${super.GetButtonTemplateFolder()}/Expand`;
    }

    private UpdateTooltip() {
        if (this.ViewModel.IsExpanded) {
            this.Tooltip({content: LABELS.MINIMIZE});
            return;
        }

        this.Tooltip({content: LABELS.MAXIMIZE});
    }
}