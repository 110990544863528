import { BasicCanvasItemViewModel } from "./BasicCanvasItemViewModel";
import { CanvasItemModel } from "./CanvasModel";
import { Utils } from 'Core/Controls/CanvasDesigner/Utils';
import { CanvasShapeWidth } from "../Enums/CanvasShapeWidth";

export class GroupViewModel extends BasicCanvasItemViewModel {
	attrs: any;

	constructor(model: CanvasItemModel) {
		super(model, { 'width': CanvasShapeWidth.Table, 'height': 30 });
		this.z = Utils.GetRandomArbitrary(60, 80);
	}
}