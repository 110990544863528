import {FIELD_TYPES} from "Core/Constant";

import {ProductPropertyResponse} from "Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Stores/Models/ProductPropertyResponse";
import {ProductPropertyDescription} from "../ProductPropertyDescription";

import {PropertyControl} from "../Controls/PropertyControl";
import {TextPropertyControl} from "../Controls/Text/TextPropertyControl";
import {DecimalPropertyControl} from "../Controls/Decimal/DecimalPropertyControl";
import {CheckboxPropertyControl} from "../Controls/Checkbox/CheckboxPropertyControl";
import {ColorPropertyControl} from "../Controls/ColorPropertyControl/ColorPropertyControl";
import {IntegerPropertyControl} from "../Controls/Integer/IntegerPropertyControl";
import {ImagePropertyControl} from "../Controls/Image/ImagePropertyControl";
import {ProductPart} from "../../ProductPart";
import { LookupPropertyControl } from "../Controls/Lookup/LookupPropertyControl";

export class ProductPropertyControlFactory {
    static CreatePropertyControl(property: ProductPropertyDescription, productPart: ProductPart, orderEntityId: number, productEntityId: number): PropertyControl {
        switch (property.Type) {
            case FIELD_TYPES.Integer:
                return new IntegerPropertyControl(property, productPart);

            case FIELD_TYPES.Decimal:
                return new DecimalPropertyControl(property, productPart);

            case FIELD_TYPES.YesNo:
                return new CheckboxPropertyControl(property, productPart);

            case FIELD_TYPES.Color:
                return new ColorPropertyControl(property, productPart, orderEntityId, productEntityId);

            case FIELD_TYPES.Image:
                return new ImagePropertyControl(property, productPart, orderEntityId, productEntityId);

            case FIELD_TYPES.Lookup:
                return new LookupPropertyControl(property, productPart, orderEntityId, productEntityId);

            default:
                return new TextPropertyControl(property, productPart);
        }
    }
}