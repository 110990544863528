import * as ko from 'knockout';

import { InvoicingControlViewModel } from 'Core/Components/Dashboards/FinancialDashboard/Models/View/InvoicingControlViewModel';

export class SourceTableTypeViewModel {
	Expanded: KnockoutObservable<boolean>;

	constructor(
		public Id: number,
		public Name: string,
		public Controls: InvoicingControlViewModel[]) {

		this.Expanded = ko.observable(true);
	}

	get TotalAmount() {
		return this.Controls.map(control => control.RecordsAmount).reduce((sum, current) => sum + current, 0);
	}

	ToggleExpansion() {
		this.Expanded(!this.Expanded());
	}
}
