import * as ko from 'knockout';
import {Events} from "Auth/Enums/Events";
import {Event} from "Core/Common/Event";
import {Modal} from "Core/Common/Modal";
import {UnreportedModel} from "Auth/UI/UnreportedTimeForm/Model/UnreportedModel";
import {LABELS} from "Core/Components/Translation/Locales";

import UnreportedTimeFormTemplate from 'Auth/UI/UnreportedTimeForm/Templates/UnreportedTimeForm.html';
ko.templates['Auth/UI/UnreportedTimeForm/Templates/UnreportedTimeForm'] = UnreportedTimeFormTemplate;

export class UnreportedTimeForm extends Event {
    private _model: KnockoutValidatedObservable<UnreportedModel>;
    private _modal: Modal;
    private _hasFocus : KnockoutObservable<boolean>;
    public _labels = LABELS;

    constructor() {
        super();
        this.Init();
        this._hasFocus = ko.observable(false);
    }

    Load(formTitle: string, dbName: string, login: string = '') {
        this.Show(formTitle);
    }

    IsValid() {
        if (!this._model.isValid()) {
            this._model.errors.showAllMessages();
        }

        return this._model.isValid();
    }

    GetFormData() {
        return this._model();
    }

    ShowError(errorText: string) {
        this._model().Error(errorText);
    }

    RemoveErrorSummary() {
        this._model().Error(null);
    }

    private Init() {
        ko.validation.init({
            insertMessages: false
        });
    }

    private Show(formTitle: string) {
        this._model = ko.validatedObservable(new UnreportedModel());
        this._model().FormTitle(formTitle);

        this._modal = new Modal({
            addClass: 'login unreportedTimeForm-modal',
            width: '500',
            minHeight: '300'
        }, false);
        this._modal.One('CLOSE', this, () => { this.Close(); });
        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);

        this._modal.Show();
    }

    Close() {
        this.Trigger(Events.UnreportedTimeFormClosed);
        this._modal.Close();
    }

    private ReportButtonClicked() {
        this.Trigger(Events.GoToAgenda);
    }

    private SkipButtonClicked() {
        this.Close();
    }

    private GetTemplateName() {
        return 'Auth/UI/UnreportedTimeForm/Templates/UnreportedTimeForm';
    }

    private AfterRender() {

    }
}