import * as ko from 'knockout';
import * as _ from 'underscore';

import { FieldListItem } from 'QueryBuilder/FieldListMenu/FieldListItem';

import FieldListMenuTemplate from 'QueryBuilder/FieldListMenu/Templates/FieldListMenu.html';
import { ZIndexManager } from 'Core/Common/ZIndexManager';
import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { Event } from 'Core/Common/Event';
import { QueryColumnModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryColumnModel';
import { LABELS } from "Core/Components/Translation/Locales";
import {EVENTS} from 'QueryBuilder/Events';
import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

const DELETE_LIFESTATUS = 'Delete';
const DISABLE_LIFESTATUS = 'Disabled';

export class FieldListMenu extends Event {
	private _items: KnockoutObservableArray<FieldListItem>;
	private _isOpen: KnockoutObservable<boolean>;
	private _enableLeave: boolean;
	SelectedColumnsCount: KnockoutComputed<number>;
	IsAllSelected: KnockoutObservable<boolean>;
	private _labels = LABELS;
	private _draggAppendTarget;

	constructor(
		private _queryEntityModel: QueryEntityModel,
		private _isEnableSubQuery = true,
		private _isInSubQuery = false
	) {
		super();
		this.AddEvent(EVENTS.CREATE_SUB_QUERY);
		this.AddEvent(EVENTS.REMOVE_SUB_QUERY);
		this.AddEvent(EVENTS.COLUMN_SELECTED);
		this.IsAllSelected = ko.observable(false);
		this._items = ko.observableArray([]);
		this._isOpen = ko.observable(false);
		this.SelectedColumnsCount = ko.computed(() => {
			var selectedColumns = _.filter(this._items(), item => { return item.IsSelected() });
			return selectedColumns.length;
		});
		this._enableLeave = true;
	}

	private IsRetired(statusName: string){
		return statusName == DELETE_LIFESTATUS || statusName == DISABLE_LIFESTATUS;
	}

    AddItem(item: FieldListItem) {
        if (!this.IsRetired(item.Model.LifestatusName) || item.IsSelected()) {
            this._items.push(item);
        }
        
		this.IsAllSelected(this._items().length === this._queryEntityModel.Columns.length);
	}

	GetTemplateHtml() {
		return FieldListMenuTemplate;
	}

	@enumerable get Items(): KnockoutObservableArray<FieldListItem> {
		return this._items;
	}

	Select(item: FieldListItem) {
		item.IsSelected(!item.IsSelected());
		if (item.IsSelected()) {
			this.AddColumn(item.Model.Id);
			this.Trigger(EVENTS.COLUMN_SELECTED, { Id: item.Model.Id });
		} else {
            this.RemoveColumn(item.Model.Id);
            if (item.Model.LifestatusName === DELETE_LIFESTATUS) {
                this.Items().splice(this.Items().indexOf(item), 1);
            }
        }
		this.IsAllSelected(this._items().length === this._queryEntityModel.Columns.length);
	}

	AddColumn(fieldId: number) {
		var fieldMetadata = _.find(this._queryEntityModel.Metadata.Fields, field => field.Id === fieldId);
		if (fieldMetadata) {
			var queryColumnModel = new QueryColumnModel();
			queryColumnModel.Metadata = fieldMetadata;
			this._queryEntityModel.Columns.push(queryColumnModel);
		}
	}

	RemoveColumn(fieldId: number) {
		var queryColumn = _.find(this._queryEntityModel.Columns, item => item.Metadata.Id === fieldId);
		if (queryColumn) {
			this._queryEntityModel.Columns.splice(this._queryEntityModel.Columns.indexOf(queryColumn), 1);
		}
	}

	DraggableHelper(item: FieldListItem, evt, ui) {
		return $(`<div class="box-shadow--6dp" style="margin-left: 10px; height: 40px; cursor: move; z-index: ${ZIndexManager.Instance.NextValue}; position: relative;">
						<div class="panel color-view bg-blue bg-font-blue" style="height: 40px;">
							<div style="cursor: move; padding: 0; display: inline-block; padding-left: 5px; padding-right: 5px;" class="panel-heading text-center">
								<h5 class="bold">
									${this._queryEntityModel.Metadata.Name}.${item.Name}
								</h5>
							</div>
						</div>
					</div>`);
	}

	StartDrag(item: FieldListItem, evt, ui) {

		ko.utils.domData.set(evt.target, 'ko_dragItem', { FieldMetadataModel: item.Model, QueryEntityModel: this._queryEntityModel });
		$('.queryBuilderDropdown').parent().removeClass('open');
		this._draggAppendTarget.css('overflow', 'auto');

	}


	Drag(item: FieldListItem, evt, ui) {
		this._draggAppendTarget.scrollTop((evt.pageY - 400));
		$("#QuerySorting").sortable("refresh");
	}

	@enumerable get AppendTo() {
		return this._draggAppendTarget|| '.jBox-Modal .jBox-content';
	}

	AfterRender(args) {
		if (args && args[1]) {
			const $parentNode = $(args[1].parentNode);
			const $jboxContent = $parentNode.parent('.jBox-content');
			this._draggAppendTarget= $jboxContent;

			$parentNode.on('show.bs.dropdown', function () {
                const styling: any = {
                    position: 'absolute',
                    left: 0
                };
                styling.top = 43;
				styling['box-shadow'] = '5px -5px rgba(102, 102, 102, 0.5)';
				$(args[0]).css(styling).addClass('queryBuilderDropdown');
			});
		}
	}

	SelectAll() {
		this.IsAllSelected(!this.IsAllSelected());
		if (this.IsAllSelected()) {
			_.each(this._items(),
				(item) => {
					if (!item.IsSelected()) {
						item.IsSelected(true);
						this.AddColumn(item.Model.Id);
					}
				});
		} else {
			_.each(this._items(),
				(item) => {
					item.IsSelected(false);
					this.RemoveColumn(item.Model.Id);
				});
		}
	}

	CreateSubQuery() {
		this._isInSubQuery = true;
		this.Trigger(EVENTS.CREATE_SUB_QUERY);
	}

	RemoveSubQuery() {
		this._isInSubQuery = false;
		this.Trigger(EVENTS.REMOVE_SUB_QUERY);
	}

	set IsInSubQuery(value: boolean){
		this._isInSubQuery = value;
	}

	@enumerable get IsEnableSubQuery(): boolean {
		return this._isEnableSubQuery;
	}

	@enumerable get IsInSubQuery(): boolean {
		return this._isInSubQuery;
	}
}