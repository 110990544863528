import * as ko from 'knockout';
import {GridColumnModel} from 'Core/Controls/Grid/Models/GridDataModel/GridColumnModel';
import {EVENTS} from 'Core/Controls/Grid/BaseGrid/Events';
import {LABELS} from 'Core/Components/Translation/Locales';
import {BlockUI as BlockUi} from 'Core/Common/BlockUi';
import {GlobalManager, GLOBALS} from 'Core/GlobalManager/GlobalManager';
import {Event} from 'Core/Common/Event';
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';
import {
	IGetFastFilterRequestModel,
	FastFilterSaveModel,
	FastFilterValue,
	FastFilterItem
} from 'Core/Controls/Grid/BaseGrid/FastFilter/FastFilterModels';
import {GridStore, IGetGridDataRequestModel} from 'Core/Controls/Grid/Stores/GridStore';
import {Notifier} from 'Core/Common/Notifier';
import { FastFilterSearch } from 'Core/Controls/Grid/BaseGrid/FastFilter/Search/FastFilterSearch';
import { JBoxDropDown } from 'Core/Components/JBoxDropdown/DropDown';
import enumerable from '../../../../Common/Decorators/EnumerableDecorator';

export class FastFilter extends Event {
	private _isItemsLoaded: KnockoutObservable<boolean>;
	private _showSearchScreenButton: KnockoutObservable<boolean>;
	protected _isOpenFastFilter: KnockoutObservable<boolean>;
	private _searchTerm: KnockoutObservable<string>;
	protected _isFilterApplied: KnockoutObservable<boolean>;
	private _searchScreenIfRecordsCount: number;

	// list of all available values
	protected _availableItems: KnockoutObservableArray<FastFilterValue>;
	// view model that recreates after getting data and updatin search value
	protected _viewModelItem: KnockoutObservableArray<FastFilterItem>;
	// save model that goes to local storage and is used for GetGridData request
	protected _saveModel: FastFilterSaveModel;
	// other enabled filters excluding current one, is used for GetFilters request
	protected _allFilters: FastFilterSaveModel[];
	// selected checkboxes store to be used between search value updates
	protected _storedCheckboxes: FastFilterItem[];

	protected _recordId: number;
	protected _tableViewId: number;
	protected _preselectedRecords: KnockoutObservableArray<any>;
	private _invalidMessage: KnockoutObservable<string>;
	private _getGridDataModel: IGetGridDataRequestModel;
	protected _model: GridColumnModel;
	protected _search: FastFilterSearch;
	protected _searchValue: string;
	protected _initialDataMapped: KnockoutObservable<boolean>;
	protected _existedColumnAliases: string[];
	protected _filterId : string;

	protected _element: HTMLElement;
	protected _labels = LABELS;
	protected _dropDown: JBoxDropDown;

	protected _originalViewModelItems: FastFilterItem[];
	protected _regex: RegExp;
	private _isNewItemsLoaded: KnockoutObservable<boolean>;
	protected _pageNumber: number;
	private _recordsPerPage: number;
	private _isSearch: KnockoutObservable<boolean>;

	constructor(model: GridColumnModel,
				recordId: number,
				tableViewId: number,
				getGridDataModel: IGetGridDataRequestModel,
				existedColumnAliases: string[],) {
		super();

		this._model = model;
		this._getGridDataModel = getGridDataModel;
		this._recordId = recordId;
		this._tableViewId = tableViewId;
		this._isOpenFastFilter = ko.observable(false);
		this._isFilterApplied = ko.observable(false);
		this._searchTerm = ko.observable('');
		this._isItemsLoaded = ko.observable(false);
		this._showSearchScreenButton = ko.observable(false);
		const searchScreenIfRecordsCount = parseInt(GlobalManager.Instance.GetGlobal(GLOBALS.SEARCH_SCREEN_IF_RECORDS_COUNT));
		this._searchScreenIfRecordsCount = isNaN(searchScreenIfRecordsCount) ? 10 : searchScreenIfRecordsCount;
		this._preselectedRecords = ko.observableArray([]);
		this._availableItems = ko.observableArray([]);
		this._viewModelItem = ko.observableArray([]);
		this._saveModel = null;
		this._allFilters = [];

		this._storedCheckboxes = null;
		this._initialDataMapped = ko.observable(false);
		this._existedColumnAliases = existedColumnAliases;

		this._search = new FastFilterSearch(this._model, this.SearchChangeCallback.bind(this));

		this._searchValue = '';
		this._invalidMessage = ko.observable('');
		this._filterId = JBoxDropDown.GetDropDownId();

		this._originalViewModelItems = null;
		this._regex = null;
		this._isNewItemsLoaded = ko.observable(true);
		this._pageNumber = 1;
		this._recordsPerPage = 100;
		this._isSearch = ko.observable(false);

		this.AddEvent(EVENTS.FAST_FILTER);
	}

	protected Preselect() {
		const columnFilters = UserVarsManager.Instance.GetGridColumnFilters(this._recordId);

		if (columnFilters && columnFilters.length) {
			for (let i = 0; i < columnFilters.length; i++) {
				if (columnFilters[i].FieldAlias === this._model.Alias
					&& columnFilters[i].TableViewId === this._tableViewId) {

					if (columnFilters[i].Values.length) {
						if (columnFilters[i].Values.find(item => item.Value && item.Value.split && item.Value.split(";").length > 1)) {
							columnFilters[i].Values.map(item => item.Value = item.Value.split(";")[0]);
                        }
						this._preselectedRecords(columnFilters[i].Values);
					}

					const isFilterApplied = columnFilters[i].Values.length;

					this._isFilterApplied(isFilterApplied);
				}
			}
		}

		this._allFilters = columnFilters || [];

		this._allFilters = this._allFilters.filter((filter) => {
			return this._existedColumnAliases.some((existedAlias) => {
				return existedAlias === filter.FieldAlias;
			});
		});
	}

	protected ReopenFastFilter() {
		this._isOpenFastFilter(false);
		this._isOpenFastFilter(true);
	}

	protected MapToViewModel(isSearch?: boolean, isEmptySearchValue?: boolean) {
		const viewModelList: FastFilterItem[] =
			this._availableItems().map((item: FastFilterValue) => {
				const wasItemChecked = this.RestoreSelectedCheckbox(item);

				const viewModelItem: FastFilterItem = {
					Value: item.Value && item.Value.toString(),
					DisplayValue: item.DisplayValue || (item.Value && item.Value.toString()) || this._labels.EMPTY_VALUE,
					Checked: wasItemChecked
				};

				this._preselectedRecords().forEach((preselected) => {
					if (viewModelItem.Value === preselected.Value && viewModelItem.DisplayValue === preselected.DisplayValue && !this._storedCheckboxes) {
						viewModelItem.Checked = true;
					}
				});

				return viewModelItem;
			});

		if (this._viewModelItem().length && !isSearch && !isEmptySearchValue) {
			this._viewModelItem.push(...viewModelList);
		} else {
			this._viewModelItem(viewModelList);
		}

		this._initialDataMapped(true);
	}

	protected MapToSaveModel() {
		this.StoreSelectedCheckboxes();

		const listFromStore: FastFilterValue[] = [];

		// create prev selected checkboxes list that are checked and doesn't exist in view model
		if (this._storedCheckboxes) {
			this._storedCheckboxes.forEach((storedItem) => {
				let isItemOnlyInStore = !this._viewModelItem().some((viewItem) => {
					return viewItem.Value === storedItem.Value;
				});

				if (isItemOnlyInStore && storedItem.Checked) {
					listFromStore.push({
						Value: storedItem.Value,
						DisplayValue: storedItem.DisplayValue
					});
				}
			});
		}

		let valuesList: FastFilterValue[] = this._viewModelItem().filter((item) => {
			if (item.Checked) {
				delete item.Checked;

				return true;
			}
		});

		// extend valuesList with previously selected and currently unvisible items
		valuesList = valuesList.concat(listFromStore);

		this._saveModel = {
			FieldAlias: this._model.Alias,
			Values: valuesList,
			FieldId: this._model.FieldMetadata.Id,
			TableViewId: this._tableViewId
		};

		this._allFilters.forEach((filter) => {
			if (filter.FieldAlias === this._model.Alias && filter.TableViewId === this._tableViewId) {
				filter.Values = valuesList;
			}
		});

		// clear store
		this._storedCheckboxes = null;
	}

	get IsItemsLoaded() {
		return this._isItemsLoaded();
	}

	set IsItemsLoaded(value) {
		this._isItemsLoaded(value);
	}

	get ShowSearchScreenButton() {
		return this._showSearchScreenButton();
	}

	set ShowSearchScreenButton(value) {
		this._showSearchScreenButton(value);
	}

	get FilterModel(): FastFilterSaveModel {
		return null;
	}

	get FilterSaveModel(): FastFilterSaveModel {
		return this._saveModel;
	}

	protected Clear() {
		this._preselectedRecords([]);
		this.MapToSaveModel();
		this._saveModel.Values = [];

		this._viewModelItem().forEach((item) => item.Checked = false);
		this._isFilterApplied(false);

		this.Trigger(EVENTS.FAST_FILTER);
	}

	_Clear() {
		this.Clear();
		this._dropDown.Close();
	}

	protected Filter() {
		this.Trigger(EVENTS.FAST_FILTER);
	}

	_Filter() {
		this.Filter();
		this._dropDown.Close();
	}

	protected LoadFastFilterData(dropDownElement?: HTMLElement, removeBlockUi?: boolean, visibleBeginningSpinner?: boolean, isEmptySearchValue?: boolean) {
		this._isOpenFastFilter(!this._isOpenFastFilter());

		// this.PopupToggleHandler();

		if (this.IsItemsLoaded) {
			return Promise.resolve();
		}

		if (removeBlockUi){
			this.CursorWait(dropDownElement, true);
		} else {
			BlockUi.Block({Target: dropDownElement});

			if (visibleBeginningSpinner) {
				this.CursorWait(dropDownElement, true, visibleBeginningSpinner);
			}
		}

		return GridStore.GetFastFilterData(this.GetRequestParams())
			.always(() => {

				if (removeBlockUi){
					this.CursorWait(dropDownElement,false);
				} else {
					BlockUi.Unblock(dropDownElement);

					if (visibleBeginningSpinner) {
						this.CursorWait(dropDownElement,false, visibleBeginningSpinner);
					}
				}

			})
			.then((result) => {
				this.DataLoadCallback(result.Items, false, isEmptySearchValue);
				this.CheckPositionScroll(dropDownElement, result.TotalRecords, this._recordsPerPage, isEmptySearchValue);
			})
			.fail((error) => {
				new Notifier().Failed(error.message);
			});
	}

	protected SearchChangeCallback(value) {
		let dropDownElement = this._dropDown.GetContentElement().childNodes[0];
		this._searchValue = value;
		this.StoreSelectedCheckboxes();
		this._pageNumber = 1;

		if (value === ''){
			this._isSearch(false);
			this.IsItemsLoaded = false;
			this.LoadFastFilterData(dropDownElement, false, true, true).then(()=> {
				this._isNewItemsLoaded(true);
			});

		} else {
			this._isSearch(true);
			BlockUi.Block(dropDownElement ? {Target: dropDownElement} : {});

			GridStore.GetFastFilterData(this.GetRequestParams())
				.always(() => {
					BlockUi.Unblock(dropDownElement);
				})
				.then((result) => {
					this.DataLoadCallback(result.Items, true, false);

					let fastFilterList = $(dropDownElement).find('.fast-filter-list');
					this.RangeHighlight(fastFilterList, value);
				})
				.fail((error) => {
					new Notifier().Failed(error.message);
				});
		}
	}

	protected DataLoadCallback(data, isSearch?: boolean, isEmptySearchValue?: boolean) {
		this._availableItems(this.SortItems(data));
		this.MapToViewModel(isSearch, isEmptySearchValue);
		this.IsItemsLoaded = true;
	}

	protected GetRequestParams(): IGetFastFilterRequestModel {
		return {
			FilterColumnGuid: this._model.QueryColumnGuid,
			FilterSearchPhrase: this._searchValue,
			TimeZone: (new Date().getTimezoneOffset()) / 60,
			ControlId: this._getGridDataModel.ControlId ,
			TableViewId: this._getGridDataModel.TableViewId,
			SubjectEntityId: this._getGridDataModel.SubjectEntityId,
			SubjectRecordId: this._getGridDataModel.SubjectRecordId,
			SubjectKindId: this._getGridDataModel.SubjectKindId,
			SubjectTypeId: this._getGridDataModel.SubjectTypeId,
			SubjectStatusId: this._getGridDataModel.SubjectStatusId,
			ViewMode: this._getGridDataModel.ViewMode,
			PageNumber: this._pageNumber,
			RecordsPerPage: this._recordsPerPage,
			Sort: null,
			SearchPhrase: this._getGridDataModel.SearchPhrase,
			ScreenData: this._getGridDataModel.ScreenData,
			SelectedTags: this._getGridDataModel.SelectedTags,
			FilterByOwners: this._getGridDataModel.FilterByOwners,
			RecordOwners: this._getGridDataModel.RecordOwners,
			ShowPlanned: this._getGridDataModel.ShowPlanned,
			Query: this._getGridDataModel.Query,
			FastFilters: this._allFilters
				.filter((filter) => {
					return filter.FieldAlias !== this._model.Alias
						&& filter.TableViewId === this._tableViewId
						&& filter.Values.length;
				})
				.map((filter) => {
					filter.Values.forEach(value => {
						if (value.DisplayValue === LABELS.EMPTY_VALUE) {
							value.DisplayValue = null;
						}
					});
					return {
						FieldAlias: filter.FieldAlias,
						Values: filter.Values
					};
				})
		};
	}

	protected SortItems(itemsList: FastFilterValue[], isNumericSort?: boolean): FastFilterValue[] {
		if (!itemsList || !itemsList.length) return [];

		itemsList.map((item) => {
			if (item.Childs && item.Childs.length) {
				this.SortItems(item.Childs)
			}
		});

		return itemsList.sort((a, b) => {
			const valueA = a.DisplayValue || a.Value;
			const valueB = b.DisplayValue || b.Value;

			if (isNumericSort) {
				if (valueA != null && +valueA - +valueB) {
					return -1;
				} else if (valueA == null) {
					return -1;
				} else {
					return 1;
				}
			}

			if (valueA && valueA < valueB) {
				return -1;
			} else if (!valueA) {
				return -1;
			} else {
				return 1;
			}
		});
	}

	// synchronize _storedCheckboxes with view model
	protected StoreSelectedCheckboxes() {
		if (!this._storedCheckboxes) {
			this._storedCheckboxes = [];
		}
		this._viewModelItem().forEach((item) => {
			let existedItemIndex;

			const existedItem = this._storedCheckboxes.find((storedItem, index) => {
				if (storedItem.Value === item.Value) {
					existedItemIndex = index;

					return true;
				}
			});

			if (!existedItem && item.Checked) {
				this._storedCheckboxes.push({
					Value: item.Value,
					Checked: true
				});
			} else if (existedItem && typeof existedItemIndex === 'number') {
				this._storedCheckboxes[existedItemIndex].Checked = item.Checked;
			}
		});
	}

	protected RestoreSelectedCheckbox(listItem): boolean {
		return this._storedCheckboxes
			&& this._storedCheckboxes.some((storedItem) => {
				if (storedItem.Value === listItem.Value) {
					return storedItem.Checked;
				}
			});
	}

	GetTemplate() { }

	GetPopUpTemplate() {}

	OpenFilter() {
		this._dropDown.Open();
		let dropDownElement = this._dropDown.GetContentElement().childNodes[0];

		this._isNewItemsLoaded(false);
		return this.LoadFastFilterData(dropDownElement, false, true).then(()=> {
			this._isNewItemsLoaded(true);
		})
	}

	RangeHighlight(list: JQuery, text: string) {
		let listArr = list.find('.fast-filter-list-item-label span');
		_.each(listArr, (labelText)=> {
			let root = labelText.firstChild,
				content = root.nodeValue;

			if (~content.indexOf(text)) {
				if (document.createRange) {

					let rng = document.createRange();
					rng.setStart(root, content.indexOf(text));
					rng.setEnd(root, content.indexOf(text) + text.length);

					let highlightDiv = document.createElement('span');
					highlightDiv.style.boxShadow = 'inset 0 1px 1px rgba(0,0,0,.075),0 0 4px var(--main-color-hover-1-rgba-06)';

					rng.surroundContents(highlightDiv);
				} else {
					console.log('Your Browser does not support Range');
				}
			}

		})
	}

	CheckPositionScroll(dropDownElement: HTMLElement, recordsCount: number, recordsPerPage: number, isEmptySearchValue?: boolean) {

		const percentValue: number = 80; // at what percentage of the height of the element to start the trigger

		let list = $(dropDownElement).find('.fast-filter-list'),
			scrollHeight: number = 0,
			isScroll: number = 0,
			lastPages: number = Math.ceil(recordsCount / recordsPerPage),
			counterPage: number = 1;

		if (list.length) {

			if (this._isSearch() || isEmptySearchValue){
				list.off('scroll');
				// console.log('off scroll event '+' Search - '+ this._isSearch());
			}

			list.on('scroll', (handler)=> {

				if (this._isSearch()){
					list.off('scroll');
					// console.log('off scroll event '+' Search - '+ this._isSearch());
					return;
				}

				if (counterPage === lastPages) {
					list.off('scroll');
					console.log('fast filter - all data has been loaded');
					return;
				}

				scrollHeight = list.prop("scrollHeight") - list.height();
				let targetScroll = Math.ceil( (percentValue / 100) * scrollHeight );

				if(isScroll === 0 && list.scrollTop() >= targetScroll) {
					isScroll = 1;
					if (this._isNewItemsLoaded()){
						counterPage ++;
						this._pageNumber = counterPage;
						this.IsItemsLoaded = false;
						this.LoadFastFilterData(list[0], true).then(()=>{
							this._isNewItemsLoaded(true);
						});
					}
					this._isNewItemsLoaded(false);
					// console.log('counterPage - ' + this._pageNumber);
				} else if(isScroll === 1 && list.scrollTop() < targetScroll) {
					isScroll = 0;
				}
			})
		}
	}

	CursorWait(dropDownElement: HTMLElement, addCursor: boolean, visibleBeginningSpinner?: boolean){
		if (dropDownElement){
			let fastFilterList = $(dropDownElement),
				isFastFilterList = $(dropDownElement).hasClass('fast-filter-list');

			if (!isFastFilterList && visibleBeginningSpinner) {
				fastFilterList = $(dropDownElement).find('.fast-filter-list');
			}

			if (addCursor){
				fastFilterList.addClass('cursorWait');
			} else {
				fastFilterList.removeClass('cursorWait');
			}

		}
	}

	AfterRender(el: HTMLElement) {
		this._element = el[0];
		const selector = `.${this._filterId}`;
		this._dropDown = new JBoxDropDown({
			target: selector,
			onCreated: () => {
				this._dropDown.SetContent({ content: this.GetPopUpTemplate() as any });
			},
			bindTarget: this._element,
			bindComponent: this,
			otherOptions: {
				attach: undefined,
				position: {
					x: 'left',
					y: 'center'
				},
				adjustPosition: true,
				adjustTracker: true,
				outside: 'x',
				adjustDistance: {top: 55, right: 5, bottom: 5, left: 5},
				zIndex: 11000
			}
		});
	}
}