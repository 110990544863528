

import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize} from 'libs/cerialize';
import {ViewGridResponseModel} from "Core/Controls/Grid/Models/ViewGridResponseModel";

export interface IGetGridViewListDto {
	SubjectEntityId: number;
	ControlId: number;
	ViewMode: number;
	ScreenType: number;
};

export interface IGetLinkEditorGridViewListDto {
	TableId: number;
	TargetTableId: number;
	LookupFieldId: number;
}

export class ViewGridListStore {
	static GetGridViewList(params: IGetGridViewListDto): P.Promise<ViewGridResponseModel> {

		var deferredResult = P.defer<ViewGridResponseModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.GET_VIEW_GRID_LIST,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(<ViewGridResponseModel>Deserialize(data, ViewGridResponseModel));
			});

		return deferredResult.promise();
	}

	static GetInvoicingGridViewList(entityId: number): P.Promise<ViewGridResponseModel> {

		var deferredResult = P.defer<ViewGridResponseModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.GET_INVOICING_GRID_VIEW_LIST,
				dataType: 'json',
				type: 'POST'
			}, params: {EntityId: entityId}
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(<ViewGridResponseModel>Deserialize(data, ViewGridResponseModel));
			});

		return deferredResult.promise();
	}

	static GetLinkEditorGridViewList(params: IGetLinkEditorGridViewListDto): P.Promise<ViewGridResponseModel> {

		var deferredResult = P.defer<ViewGridResponseModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.GET_LINK_EDITOR_VIEW_GRID_LIST,
				type: 'GET',
				dataType: 'json'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(<ViewGridResponseModel>Deserialize(data, ViewGridResponseModel));
			});

		return deferredResult.promise();
	}
} 