import * as ko from "knockout";
import * as moment from "moment";
import "BootstrapDateTimeExtension";

import {ColumnEditor} from "LookupEditor/ColumnEditors/Base/ColumnEditor";
import {IValueUpdater} from "LookupEditor/Interfaces/IValueUpdater";
import {IColumnValue} from "LookupEditor/Interfaces/IColumnValue";
import {FormatConverter} from "FormatEditor/FormatConverter";

import ColumnTemplate from "LookupEditor/Templates/Columns/DateTime.html";
ko.templates["LookupEditor/Templates/Columns/DateTime"] = ColumnTemplate;

export class DateColumnEditor extends ColumnEditor {
    Show(): void { }

    GetTemplateName(): string {
        return 'LookupEditor/Templates/Columns/DateTime';
    }

    AfterRender(el, owner: IValueUpdater, columnName): void {
        const format = FormatConverter.GetDateFormatFromFieldModel(owner.GetColumnData(columnName));

        const value = owner.GetValue(columnName);

        ($.fn.editable as any).defaults.mode = 'inline';
        $(el).editable({
            type: 'dateTimePickerBootstrap',
            value: {
                current: value
            },
            dateTimePickerBootstrap: {
                locale: moment.locale(),
                format,
                widgetPositioning: {
                    horizontal: 'right',
                    vertical: 'bottom',
                    forceParse: false
                }
            },
            success: (response, newValue) => {
                const value: IColumnValue = {
                    Name: columnName,
                    Value: newValue.current
                };
                owner.UpdateValue(value);
            }
        });

        if (value) {
            el[0].textContent = moment(value).format(format);
        }
    }

    ToString() {
        return "Date";
    }
} 