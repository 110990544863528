import * as ko from 'knockout';
import { deserialize, deserializeAs } from 'libs/cerialize';
import { ActionModel } from "Core/Controls/Kanban/Models/StatusModel";
import { LifeStatusSimpleModel } from "Core/Controls/ButtonFollowUp/Models/LifestatusSimpleModel";

export class FollowUpBaseRecordModel {
	constructor(password?: string) {
		this.ConfirmationPassword = password;
	}
	
	@deserialize
    LifeStatusId: number;

    CurrentLifeStatus: LifeStatusSimpleModel;

    LifeStatusSort?: number;

    LifeStatusName?: string;

    LifeStatusNoActionNode: boolean;

    Actions?: Array<ActionModel>;

    ConfirmationPassword?: string;

    RetireChildren?: boolean;
}