import * as ko from 'knockout';

import {Notifier} from "Core/Common/Notifier";
import {BlockUI} from "Core/Common/BlockUi";
import {Modal} from "Core/Common/Modal";

import {IPopup} from "../IPopup";
import {IParams} from "./IParams";

import {ViewModel} from "./Models/View/ViewModel";

import {Store} from "./Stores/Store";
import {LookupOptionsDto} from "./Models/Dto/LookupOptionsDto";

import {Mappings} from "./Mappings/Mappings";

import Template from 'Core/Components/BackLinking/Popup/FillLookup/Templates/Template.html';

ko.templates['Core/Components/BackLinking/Popup/FillLookup/Templates/Template'] = Template;

export class FillLookupPopup implements IPopup {
    private _viewModel: ViewModel;
    private _modal: Modal;

    constructor(private _params: IParams) {
    }

    GetTemplateName() {
        return 'Core/Components/BackLinking/Popup/FillLookup/Templates/Template';
    }

    AfterRender() {

    }

    Show() {
        BlockUI.Block();

        this.RequestOptions()
            .then((dto: LookupOptionsDto) => {
                if (dto.Options.length === 0) {
                    new Notifier().Warning('There are no options for such intention');
                    return;
                }

                this._viewModel = Mappings.MapToViewModel(dto);
                this._viewModel.Header = this._params.Header;

                this.ShowModal();
            })
            .fail(error => new Notifier().Failed(JSON.parse(error.message).Message))
            .always(() => BlockUI.Unblock());
    }

    Save() {
        if (!this._viewModel.SelectedOption()) {
            this._viewModel.ErrorSummary('Please select any option');
            return;
        }

        this._viewModel.ErrorSummary(null);

        BlockUI.Block();

        this.SaveSelection()
            .then(() => {
                new Notifier().Success('Success');
                this._modal.Close();
            })
            .fail(error => new Notifier().Failed(JSON.parse(error.message).Message))
            .always(() => BlockUI.Unblock());
    }

    Cancel() {
        this._modal.Close();
    }

    private RequestOptions() {
        return Store.GetLookupOptions({
            SubTableView: this._params.SubTableView,
            LeftTableId: this._params.LeftTableId,
            RightTableId: this._params.RightTableId,
            LeftRecordId: this._params.LeftRecordId,
            RightRecordId: this._params.RightRecordId,
            Sequence: this._params.Sequence
        });
    }

    private SaveSelection() {
        return Store.SaveSelection({
            LookupFieldId: this._viewModel.LookupField.Id,
            SelectedOption: this._viewModel.SelectedOption(),
            LeftTableId: this._viewModel.LookupField.LeftTable.Id,
            RightTableId: this._viewModel.LookupField.RightTable.Id,
            LeftRecordId: this._params.LeftRecordId,
            RightRecordId: this._params.RightRecordId,
            Sequence: this._params.Sequence
        });
    }

    private ShowModal() {
        this._modal = new Modal({
            minWidth: 400
        }, false);

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);

        this._modal.Show();
    }
}