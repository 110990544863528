import {P} from 'Core/Common/Promise';
import {URL} from 'Core/Common/Url';
import {IRequestOptions, Request} from 'Core/Common/Request';

import {OperationResultModel} from 'Core/Models/OperationResultModel';

export interface ILockQueryRequestModel {
    Id: number;
}

export class LockQueryStore {
    static Lock(params: ILockQueryRequestModel) {
        const deferredResult = P.defer<OperationResultModel>();
        const requestParams: IRequestOptions = {
            proxy: {
                url: URL.QUERY_BUILDER.LOCK_QUERY,
                dataType: 'json',
                type: 'GET'
            }, params: params
        };
        Request.Send(requestParams)
            .then(data => {
                if (data.IsSuccessfull) {
                    deferredResult.resolve(null);
                } else {
                    deferredResult.reject({ message: data.ErrorMessage });
                }

            });

        return deferredResult.promise();
    }

    static Unlock(params: ILockQueryRequestModel) {
        const deferredResult = P.defer<OperationResultModel>();
        const requestParams: IRequestOptions = {
            proxy: {
                url: URL.QUERY_BUILDER.UNLOCK_QUERY,
                dataType: 'json',
                type: 'GET'
            }, params: params
        };
        Request.Send(requestParams)
            .then(data => {
                if (data.IsSuccessfull) {
                    deferredResult.resolve(null);
                } else {
                    deferredResult.reject({ message: data.ErrorMessage });
                }

            });

        return deferredResult.promise();
    }
}