import { deserialize, deserializeAs, serializeAs, serialize } from 'libs/cerialize';
import { QueryColumnModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryColumnModel';

export class QuerySortModel {

	@serializeAs(QueryColumnModel)
	@deserializeAs(QueryColumnModel)
	Column: QueryColumnModel;

	@serialize
	@deserialize
	SortOrder: number;
}