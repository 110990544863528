import * as _ from 'underscore'

import {URL} from 'Core/Common/Url'
import {Request, IRequestOptions} from 'Core/Common/Request'
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel'
import {P} from 'Core/Common/Promise'
import {Deserialize} from 'libs/cerialize'
import {SearchScreenModel} from 'Core/Screens/SearchScreen/Models/SearchScreenModel'
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager'

export interface IGetSearchScreenRequestModel {
	EntityId?: number;
	TableName?: string;
	СontrolId?: number;
	Sharing?: boolean;
	//,
	//GroupNameToRecords: any
}

export class SearchScreenStore {
	static GetScreenModel(params: IGetSearchScreenRequestModel): P.Promise<SearchScreenModel> {
		var deferredResult = P.defer<SearchScreenModel>();
		var records = UserVarsManager.Instance.GetRecentAndFavourites(params.EntityId);

		params["GroupNameToRecords"] = [];

		params["GroupNameToRecords"].push({
			key: "recent",
			value: records["recent"]
		});
		params["GroupNameToRecords"].push({
			key: "favorites",
			value: records["favorites"]
		});

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/SearchApi/GetSearchScreenModel',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => deferredResult.resolve(this.Deserialize(data)));

		return deferredResult.promise();
	}

	static Deserialize(jsonObject: any): SearchScreenModel {
		return <SearchScreenModel>Deserialize(jsonObject, SearchScreenModel);
	}
}