import * as ko from 'knockout';
import {deserialize, deserializeAs} from 'libs/cerialize';

export class AlternativeSearchModel {
    @deserialize
    EntityId: number;

    @deserialize
    RecordsCount: number;
}

export class RowValueModel {
    @deserialize
    Key: string;

    @deserialize
    Value: string;
}

export class SearchFieldModel {
    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    DefaultName: string;

    SortDirection: number;
    SortOrder: KnockoutObservable<number>;
    SortOrderClassName: KnockoutComputed<string>;
    Sort: Function;

    @deserialize
    TypeName: string;

    @deserialize
    FormatName: string;

    @deserialize
    Size: number;

    @deserialize
    ValFieldTypeName: string;

    @deserialize
    ValFieldFormatName: string;

    @deserialize
    ValFieldSize: number;
}

export class SearchResultRowModel {

    constructor(){
        this.RowValues = [];
        this.NestedData = ko.observable(null);
    }

    @deserialize
    RecordId: number;

    @deserialize
    TypeId: number;

    @deserialize
    TypeName: string;

    @deserialize
    HasChildren: boolean;

    @deserializeAs(RowValueModel)
    RowValues: Array<RowValueModel>;

    NestedData: KnockoutObservable<SearchResultModel>;
}

export class SearchResultModel {

    constructor(){
        this.Rows = [];
        this.SearchFields = [];
        this.AlternativeSearchList = [];
    }

    @deserialize
    RecordsCount: number;

    @deserializeAs(SearchFieldModel)
    SearchFields: Array<SearchFieldModel>;

    @deserializeAs(SearchResultRowModel)
    Rows: Array<SearchResultRowModel>;

    @deserializeAs(AlternativeSearchModel)
    AlternativeSearchList: Array<AlternativeSearchModel>

    @deserialize
    ErrorMessage: string;

    @deserialize
    NameField: string;
}