import { record } from "rrweb";
import { SessionStore } from "./SessionStore";
import { Guid } from "../Core/Common/Guid";
import { UserManager } from '../User/UserManager';
import { pack } from '@rrweb/packer';

let events = [];
let sessionId = null;
let interval;
let stopFn = null;
let recording = false;

export const addCustomEvent = (name: string, payload: any) =>{
  if(recording){
    try{
      record.addCustomEvent(name,payload);
    }catch(error){
      console.error(error);
    }    
  }
}

export const startRecording = () =>{
  if(window.location.host != 'paulenpaul.spheeres.com'){
    return;
  }

  recording = true;
  stopRecording()
  sessionId = Guid.NewGuid();
  window.onbeforeunload = () => {
    save()
    clearInterval(interval);
  };

  stopFn = record({
    emit(event) {
      events.push(event);
    },
    plugins: [
        require('rrweb-snapshot'),
    ],
    packFn: pack,
    collectFonts: true,
    recordCanvas: true
  });

  interval = setInterval(save, 10 * 1000);
}

export const stopRecording = () =>{
    if(stopFn){
      stopFn();
    };
    save()
    clearInterval(interval);
}

function save() {
  
  if(events.length == 0 || !sessionId || (UserManager.Instance && UserManager.Instance.CurrentUser && UserManager.Instance.CurrentUser.IsLocked)){
    return;
  }

  let eventsString = JSON.stringify(events);
  events = [];
  SessionStore.Update({ sessionId: sessionId, events: eventsString, timestamp: Date.now() });
}