import { dia as Dia, shapes, V, g } from 'libs/rappid/build/rappid';
import * as joint from 'libs/rappid/build/rappid';
import * as _ from 'underscore';
import 'Core/Controls/CanvasDesigner/Shapes/Models/APIMethod';
import { LABELS } from 'Core/Components/Translation/Locales';
import { EVENTS } from "./ParamView/Events";
const _labels = LABELS;

const getTemplate = () => {
	return V('<g class="rotatable">' +
		'<g class="scalable"><rect/></g>' +
		'<foreignObject class="fobj" id="v-9" width="40" height="21">' +
		'    <div xmlns="http://www.w3.org/1999/xhtml" class="content" style="padding-top: 2px; padding-left: 25px" display="none" xml:space="preserve">' +
		`      <a class="stateButton" id="showFunctionDesigner" title="${_labels.FUNCTION_DESIGNER}"><i class="fa fa-external-link"></i></a>` +
		'    </div>' +
		`</foreignObject><a title="${_labels.FUNCTION_DESIGNER}"><text class="shape-label"/></a>` +
		'<text class="toggle-icon fa"/><title/>' +
		'</g>');
}

declare module 'libs/rappid/build/rappid' {
	namespace shapes {
		namespace cyberThing {
			class APIMethodView extends Dia.ElementView { }
		}
	}
}

joint.shapes.cyberThing.APIMethodView = Dia.ElementView.extend({

	initialize: function () {
		joint.dia.ElementView.prototype.initialize.apply(this, arguments);
		this.model.get('attrs')['.shape-label']['ref-y'] = 0;
		this.model.get('attrs')['.shape-label'].refY2 = 15;
		this.model.position(0, 0, { parentRelative: true });
		this.model.attr('./visibility', 'hidden');
	},

	renderMarkup: function () {
		this.initIcons();
		this.template = getTemplate();
		this.vel.append(this.template);
		this.bindEvents();
	},

	bindEvents: function () {

		let functionDesignerButtons = this.template.find('#showFunctionDesigner');
		_.each(functionDesignerButtons, (button: any) => {
			if (button && button.node) {
				$(button.node).on('click', () => {
					this.model.trigger(EVENTS.SHOW_FUNCTION_DESIGNER, this.model.attributes.recordId);
				});
			}
		});
	},

	initIcons: function(){
		let icon = '\uf062';

		if(this.model.attributes.attrs.IsCollapsed){
			icon = '\uf063';
		}

		this.model.attr({
			'.toggle-icon': {
				"ref-y": 0,
				refY2: 10,
				"ref-x": 0,
				refX2: 10,
				text: icon
			}
		});

	}
});