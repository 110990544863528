import * as ko from "knockout";

import {EVENTS} from "Core/Constant";
import {IButtonConfig} from "Core/Controls/PortletBar/Interfaces/IButtonConfig"
import {PortletBarButton} from "Core/Controls/PortletBar/Managers/Buttons/PortletBarButton"
import {SettingsButtonViewModel} from "Core/Controls/PortletBar/Models/Buttons/SettingsButtonViewModel"
import {ITooltipConfig} from "Core/KnockoutExtentions/TooltipExtention";
import {LABELS} from "Core/Components/Translation/Locales";

export class SettingsButton extends PortletBarButton {
    ViewModel: SettingsButtonViewModel;

    constructor(buttonName: string, buttonConfig: IButtonConfig) {
		super(buttonConfig);
		this.ViewModel = new SettingsButtonViewModel(buttonName, buttonConfig);
		this.Tooltip = ko.observable<ITooltipConfig>({content: LABELS.PORTLET_SETTINGS, outside: 'y', position: {x: 'center', y: LABELS.PORTLET_SETTINGS.length <= 8 ? 'top' : 'bottom'}});
	}

	OnClickEvent() {
		return EVENTS.PORTLETS.PORTLET_BAR.CONTROL_BUTTONS.SETTINGS_BUTTON_CLICKED;
	}

	OnClick() {
		this.Trigger(this.OnClickEvent());
		this.ViewModel.PropsOpened(!this.ViewModel.PropsOpened());
	}

	SaveSettings() {
		this.ViewModel.PropsOpened(false);
		this.Trigger(EVENTS.PORTLETS.RUNTIME_SETTINGS_UPDATED);
	}

	protected GetButtonTemplateFolder() {
		return `${super.GetButtonTemplateFolder()}/Settings`;
	}
}