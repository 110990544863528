import {dia as Dia, V} from 'libs/rappid/build/rappid';
import * as joint from 'libs/rappid/build/rappid';
import 'Core/Controls/CanvasDesigner/Shapes/Models/Source';
import {DataTypes, DATA_TYPES} from 'Core/Controls/CanvasDesigner/Constants/DataTypes';
import {EVENTS} from "./ParamView/Events";
import {LABELS} from 'Core/Components/Translation/Locales';

const _labels = LABELS;
let properties =  null;

declare module 'libs/rappid/build/rappid' {
    namespace shapes {
        namespace cyberThing {
            class SourceView extends Dia.ElementView {
            }
        }
    }
}
const getBackButtonTemplate = () => {

    let shapeLabel = '<text class="shape-label"/>';
    if (properties && properties.DataType === 5) {
        shapeLabel = '<foreignObject class="fobj" id="v-9" width="40" height="21">' +
            '    <div xmlns="http://www.w3.org/1999/xhtml" class="content" style="padding-top: 2px; padding-left: 25px" display="none" xml:space="preserve">' +
            `      <a class="stateButton" id="showFunctionDesigner" title="${_labels.FUNCTION_DESIGNER}"><i class="fa fa-external-link"></i></a>` +
            '    </div>' +
            `</foreignObject><a title="${_labels.FUNCTION_DESIGNER}"><text class="shape-label"/></a>`;
    }

	return V('<g class="rotatable">' +
        '<g class="scalable"><rect/></g>' + shapeLabel + '<title/>' +
		'<foreignObject class="fobj back" id="v-9" width="40" height="21">' +
		'    <div xmlns="http://www.w3.org/1999/xhtml" class="content" style="padding-top: 2px; padding-left: 25px" display="none" xml:space="preserve">' +
		`      <a class="stateButton" id="backButton"  title="${_labels.BACK}"><i class="fa fa-arrow-left"></i></a>` +
		'    </div>' +
		'</foreignObject>' +
        ((properties && properties.DataType === DataTypes.ApiPackage) ? '' : `<text class="propertyIcon fa ${properties && properties.DataType === 10 ? 'icon-boxing-glove' : ''}"></text>`) +
		'</g>');
};

const getEditQueryButtonTemplate = () => {

	return V('<g class="rotatable">' +
		'<g class="scalable"><rect/></g><text class="shape-label"/><title/>' +
		'<foreignObject class="back" id="v-9" width="50" height="21">' +
		`    <div xmlns="http://www.w3.org/1999/xhtml" class="content"  title="${_labels.EDIT}" style="padding-top: 2px" display="none" xml:space="preserve">` +
		`      <a class="stateButton" id="editQueryButton" title="${_labels.EDIT}"><i class="fa fa-edit"></i></a>` +
		`      <a class="stateButton" id="backButton"  title="${_labels.BACK}"><i class="fa fa-arrow-left"></i></a>` +
		'    </div>' +
		'</foreignObject>'+
        '<text class="propertyIcon fa"></text>' +
		'</g>');
};

joint.shapes.cyberThing.SourceView = Dia.ElementView.extend({
    template: {},
    query: {},

    initialize: function () {
        joint.dia.ElementView.prototype.initialize.apply(this, arguments);
        this.model.position(100, 100);
        this.model.get('attrs')['.shape-label']['ref-y'] = 0;
        this.model.get('attrs')['.shape-label'].refY2 = 10;
        this.model.get('attrs')['.back'] = {'ref-y': 0, 'ref-x': 0, refY2: 0, refX2: 160};

        this.model.attr('./visibility', 'hidden');

        this.template = getBackButtonTemplate();
        this.initProperties();

        this.bindEvents();
    },

    renderMarkup: function () {
        this.initIcons();
        this.vel.append(this.template);
    },

    initIcons: function(){
        let properties = JSON.parse(this.model.attributes.properties);
        let icon;

        switch (properties.DataType) {
            case DataTypes.Cyberbase : icon = "\uf1c0";
                break;
            case DataTypes.File : icon = "\uf1c3";
                break;
            case DataTypes.ApiPackage: icon = "\uf233";
                break;
            case DataTypes.Query: icon = "\uf121";
                break;
            case DataTypes.Trigger: icon = "\uf0e7";
                break;
            case DataTypes.DwPackage: icon = "\uf1b2";
                break;
            case DataTypes.ExactOnline: icon = "\uf206";
                break;
            case DataTypes.EWS: icon = "\uf0e0";
                break;
            case DataTypes.OCIPunchOut: icon = "\ue900";
                break;
            case DataTypes.MsAccess: icon = "\uf039";
                break;
            case DataTypes.DesktopFolder: icon = "\uf07c";
                break;

            default: icon = 'null';
        }

        this.model.attr({
            '.propertyIcon': {
                "ref-y": 0,
                refY2: 10,
                "ref-x": 10,
                refX2: 10,
                text: icon
            }
        });

    },

    initProperties: function () {
        try {
            properties = JSON.parse(this.model.attributes.properties);

            if (properties.DataType === DataTypes.Query) {
                this.template = getEditQueryButtonTemplate();
                this.query = properties.Query;
            } else if (properties.DataType === DataTypes.Trigger) {
                this.model.attr({
                    '.shape-label': {
                        text: DATA_TYPES.TRIGGER.Name
                    }
                });
            }
        } catch (e) {
        }
    },

    bindEvents: function () {
        let backButton = this.template.findOne('#backButton');
        if (backButton && backButton.node) {
            $(backButton.node).on('click', () => {
                this.model.trigger(EVENTS.BACK_TO_DATA_SELECTOR);
            });
        }

        let functionDesignerButtons = this.template.find('#showFunctionDesigner');
        _.each(functionDesignerButtons, (button : any) => {
            if (button && button.node) {
                $(button.node).on('click', () => {
                    this.model.trigger(EVENTS.SHOW_FUNCTION_DESIGNER, this.model.attributes.apiPackageId);
                });
            }
        });

        let editQueryButton = this.template.find('#editQueryButton')[0];
        if (editQueryButton && editQueryButton.node) {
            $(editQueryButton.node).on('click', () => {
                this.model.trigger(EVENTS.EDIT_QUERY, this.query);
            });
        }
    }
});