import * as ko from 'knockout';
import * as _ from 'underscore';

import {UserManager} from 'User/UserManager';
import {USER_ALLOWANCE} from 'Core/Constants/UserAllowance';

import {BaseControl} from 'Core/Controls/BaseControl/BaseControl';
import {IControlParam as ControlParam} from 'Core/Screens/IScreen';
import {EVENTS as SELECT_USER_FORM_EVENTS, SelectUserForm} from 'Core/Controls/SelectUser/Form/SelectUserForm';
import {SelectUserStore} from 'Core/Controls/SelectUser/Stores/SelectUserStore';
import {Notifier} from 'Core/Common/Notifier';
import {BlockUI} from 'Core/Common/BlockUi';
import {SelectUserModel, UserGroupModel, UserModel} from 'Core/Controls/SelectUser/Models/SelectUserModel';
import {SelectUserByBusinessRoleModel} from 'Core/Controls/SelectUser/Models/SelectUserByBusinessRoleModel';
import {ZIndexManager} from "Core/Common/ZIndexManager";

import HelpViewTemplate from 'Core/Controls/SelectUser/Templates/HelpView.html';
import ViewTemplate from 'Core/Controls/SelectUser/Templates/View.html';
import ToolBarTemplate from 'Core/Controls/SelectUser/Templates/ToolBar.html';
import DesignTemplate from 'Core/Controls/SelectUser/Templates/Design.html';
import SelectUserPopUpContent from 'Core/Controls/SelectUser/Templates/SelectUserPopUp.html';
import {RenderModes} from 'Core/Constant';
import {ISelection, IUserSelection, UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';
import {GlobalManager, GLOBALS} from 'Core/GlobalManager/GlobalManager';
import {Guid} from 'Core/Common/Guid';
import {JBoxDropDown} from 'Core/Components/JBoxDropdown/DropDown';
import {util} from "../../../libs/rappid/build/rappid";
import string = util.format.string;

ko.templates['Core/Controls/SelectUser/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/SelectUser/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/SelectUser/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/SelectUser/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/SelectUser/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/SelectUser/Templates/SelectUserPopUp'] = SelectUserPopUpContent;

export const EVENTS = {
    USERS_SELECTION_CHANGED: 'UsersSelectionChanged'
};

export interface CheckedUserAndGroup {
    GroupId: number | null;
    UserIds: number[];
}

export type SelectedUserType = { name: string; userTypeName: string; };

class SelectUserState {
    ShowPlanned: boolean;
	SelectedUsersAndGroups: CheckedUserAndGroup[];
	AppointmentAttendees: SelectUserModel;

    constructor() {
        this.SelectedUsersAndGroups = [];
    }

    UserSelected(userId: number, userGroupId: number | null) {
        return _.some(this.SelectedUsersAndGroups, group => {
            if (group.GroupId === userGroupId) {
                return group.UserIds.indexOf(userId) > -1;
            }
        });
    }

    UserGroupSelected(userId: number, userGroupId: number | null) {
        return _.some(this.SelectedUsersAndGroups, group => {
            if (userGroupId !== null && group.GroupId === userGroupId) {
                return group.UserIds.indexOf(userId) > -1;
            }
            return false
        });
    }
}

export class SelectUser extends BaseControl {
    private _initialized: boolean;
    private _selectUserForm: SelectUserForm;
    private _state: SelectUserState;
    private _entityId: number;
    private _userSelection: KnockoutObservable<ISelection>;
    private _isShowRememberMe: KnockoutObservable<boolean>;
    private _isRememberMe: KnockoutObservable<boolean>;
    private _isShowPlanner: KnockoutObservable<boolean>;
    private _isPlanner: KnockoutObservable<boolean>;
    private _activeSelection: KnockoutObservable<any>;
    private _dropDown: JBoxDropDown;
    private _dropDownTooltip: JBoxDropDown;
    private _id: string;

    constructor(params: ControlParam) {
        super(params);

        this._initialized = false;

        this._userSelection = ko.observable(null);
        this._isShowRememberMe = ko.observable(this.RememberMeGlobalEnabled());
        this._isRememberMe = ko.observable(null);
        this._isShowPlanner = ko.observable(false);
        this._isPlanner = ko.observable(false);
        this._activeSelection = ko.observable(null);

        if (this.GetTemplateName() !== 'Core/Controls/SelectUser/Templates/HelpView') {
            this.Init();
        }
        this._id = JBoxDropDown.GetDropDownId();
    }

    ApplyProperties(){}

    AfterRender(el) {
        super.AfterRender(el);

        const target = "." + this._id;
        let contentAdded = false;
        this._dropDown = new JBoxDropDown({
            bindTarget: el[0],
            bindComponent: this,
            target: target,
            onOpen: () => {
                if (contentAdded) return;
                contentAdded = true;
                this._dropDown.SetContent();
            },
            otherOptions: {
                addClass: "selectUser-dropdown",
                closeOnClick: 'body',
                position: {
                    x: "left",
                    y: "bottom"
                },
                offset: {
                    x: 15
                },
            }
        });
    }

    TogglePlanner() {
        this._state.ShowPlanned = this._isPlanner();

        if (this._state.ShowPlanned) {
			this._state.SelectedUsersAndGroups = [];
			this.SetAppointmentAttendees(null);
            this._activeSelection(null);
        }

        const screen = this.GetForm().GetScreen();

        if (screen) {
            screen.Trigger(EVENTS.USERS_SELECTION_CHANGED, this._state);
        }

        return true;
    }

    AddingUsersToGroups(model: SelectUserModel): Array<UserGroupModel>{
        return model.UserGroups.map((group: UserGroupModel) => {
            let userGroup = new UserGroupModel();
            userGroup.Id = group.Id;
            userGroup.Name = group.Name;
            userGroup.TypeId = group.TypeId;
            userGroup.TypeName = group.TypeName;
            userGroup.TypeNameTranslation = group.TypeNameTranslation;
            userGroup.UserIds = group.UserIds;
            userGroup.Users = _.filter(model.Users, (item)=>  _.includes(group.UserIds, item.Id))
                .map((user: UserModel) => {
                    let userModel = new UserModel();
                    userModel.Id = user.Id;
                    userModel.Name = user.Name;
                    userModel.UserName = user.UserName;
                    userModel.UserTypeName = user.UserTypeName;
                    userModel.UserTypeNameTranslation = user.UserTypeNameTranslation;
                    userModel.Checked = null; // userModel.Checked = user.Checked;
                    return userModel;
                })
            return userGroup;
        });
    }

    Click() {
        BlockUI.Block();
        SelectUserStore.GetSelectUserModel(this._entityId)
            .then((model: SelectUserModel)  => {
                model.UserGroups = this.AddingUsersToGroups(model);
                this.ShowModal(this.RestoreState(model));
                this._dropDown.Close();
                this._dropDownTooltip.Close();
            })
            .fail(error => new Notifier().Failed(error.message))
            .always(() => BlockUI.Unblock());
    }

    ShowSelectUserTooltip(){
        this.IsShowPlanner();
        const targetTooltip = ".selectUserTooltip" + this._id;
        this._dropDownTooltip = new JBoxDropDown({
            target: targetTooltip,
            otherOptions: {
                addClass: "selectUser-dropdown",
                attach: undefined,
                closeOnClick: 'body',
                position: {
                    x: "left",
                    y: "bottom"
                },
                onCloseComplete: () => this._dropDownTooltip.Destroy(),
                zIndex: ZIndexManager.Instance.NextValue
            },
            onOpen: () => {
                this._dropDownTooltip.SetContent({ content: SelectUserPopUpContent as any });
            },
            bindComponent: this,
        });

        this._dropDownTooltip.Open();
    }

    get Initialized() {
        return this._initialized;
    }

    get IsRememberMe() {
        return this._isRememberMe();
    }

    private Init() {
        if (this._renderMode() !== RenderModes.Design && this._renderMode() !== RenderModes.ToolBar) {
            const screen = this.GetForm().GetScreen();

            this._state = new SelectUserState();
            this._entityId = screen.GetEntityId();

            this.AddEvent(EVENTS.USERS_SELECTION_CHANGED);

            this.GetForm().GetScreen().On('SELECT_USERS_BY_BUSINESS_ROLE', this, eventArgs => this.SelectByBusinessRole(eventArgs.data.SelectUserByBusinessRoleModel));

            this.GetSelection();

            this._isRememberMe(this._isShowRememberMe() && this.GetRememberMeValue());

            if (!screen.GetDataModel()) {
                return;
            }
            this.IsShowPlanner();
        }
    }

    private RestoreState(model: SelectUserModel) {
        model.UserGroups.forEach((userGroup) => {
            // userGroup.Checked = this._state.UserSelected(userGroup.Id)
            userGroup.Users?.length && userGroup.Users.forEach((user: UserModel) => {
                user.Checked = this._state.UserGroupSelected(user.Id, userGroup.Id);
            });
        });
        model.Users.forEach(user => user.Checked = this._state.UserSelected(user.Id, null));

        return model;
    }

    private ShowModal(model: SelectUserModel) {
        this._selectUserForm = new SelectUserForm(model);

        this._selectUserForm.On(
            SELECT_USER_FORM_EVENTS.USERS_SELECTION_CHANGED,
            this,
            eventArgs => this.ApplySelection(eventArgs.data)
        );

        this._selectUserForm.Show();

    }

    private ApplySelection(model: SelectUserModel, byBusinessRole: boolean = false) {
        const allCheckedUserIds: number[] = model.Users
            .filter(user => user.Checked)
            .map(user => user.Id);
        const combinedCheckedUserGroup: CheckedUserAndGroup = {
            GroupId: null,
            UserIds: allCheckedUserIds
        };

        const selectedUserGroups: CheckedUserAndGroup[] = _.chain(model.UserGroups)
            .map(group => {
                const checkedUsers = _.pluck(_.where(group.Users, { Checked: true }), 'Id');
                return { GroupId: group.Id, UserIds: checkedUsers };
            })
            .filter(group => group.UserIds.length > 0)
            .value();

        let combinedArray: CheckedUserAndGroup[] = selectedUserGroups.concat(combinedCheckedUserGroup);
        if (combinedArray.length === 1 && combinedArray[0].GroupId === null && combinedArray[0].UserIds.length == 0){
            combinedArray = []
        }
		this._state.SelectedUsersAndGroups = combinedArray;
		this.SetAppointmentAttendees(model);

        const screen = this.GetForm().GetScreen();
        let users: SelectedUserType[] = []; // let users: [{name: string, userTypeName: string}] = [{name: '', userTypeName: ''}]

        model.UserGroups.forEach((group) => {
            const selectedGroup = this._state.SelectedUsersAndGroups.find(
                (checkedGroup: CheckedUserAndGroup) => checkedGroup.GroupId === group.Id
            );
            if (selectedGroup) {
                users.push({
                    name: group.Name,
                    userTypeName: ''
                });
                selectedGroup.UserIds.forEach((userId) => {
                    const userInGroup = model.Users.find(user => user.Id === userId);
                    if (userInGroup) {
                        users.push({
                            name: userInGroup.Name,
                            userTypeName: userInGroup.UserTypeName
                        });
                    } else {
                        users.push({
                            name: group.Name,
                            userTypeName: ''
                        });
                    }
                });
            }
        });

        model.Users.forEach((user) => {
            const selectedUser = this._state.SelectedUsersAndGroups.find(
                (checkedGroup: CheckedUserAndGroup) => checkedGroup.GroupId === null
            );
            if (selectedUser) {
                selectedUser.UserIds.forEach((userId) => {
                    const userInGroup = user.Id === userId;
                    if (userInGroup) {
                        users.push({
                            name: user.Name,
                            userTypeName: user.UserTypeName
                        });
                    }
                });
            }
        });
        users = _.uniq(users, (user) => `${user.name}-${user.userTypeName}`);

        const usersWithGroups = combinedArray;
        const screenId = this.GetForm().GetScreen().GetId();
        const userVarsManager = UserVarsManager.Instance;

        if (byBusinessRole) {
            this._isRememberMe(false);
            const guid = Guid.NewGuid();

            const selectionByBusinessRole: IUserSelection = {
                Guid: guid,
                Users: users,
                UserGroups: usersWithGroups,
                Checked: true
            };

            if (this._userSelection()) {
                this._userSelection().UserSelection.unshift(selectionByBusinessRole);
            } else {
                this._userSelection({
                    RememberMe: false,
                    ScreenId: screenId,
                    UserSelection: [selectionByBusinessRole]
                });
            }

            if (screen) {
                screen.Trigger(EVENTS.USERS_SELECTION_CHANGED, this._state);
            }

            this._activeSelection({Guid: guid, Users: users});
            return;
        } else if (usersWithGroups.length) {
            userVarsManager.AddSelection(screenId, usersWithGroups, users, this._isRememberMe());
            this.CreateNewSelection();
        } else {
            this._isRememberMe(false);
            this.SetRememberMe();

            if (screen) {
                screen.Trigger(EVENTS.USERS_SELECTION_CHANGED, this._state);
            }
        }

        this._activeSelection({Guid: Guid.NewGuid(), Users: users});
    }

    private CreateNewSelection() {
        const userVarsManager = UserVarsManager.Instance;
        const userSelection = userVarsManager.GetUserSelection(this.GetForm().GetScreen().GetId());
        this.ChooseSelection(_.first(userSelection.UserSelection));

        this._userSelection(userSelection);

        if (userSelection) {
            this._isRememberMe(userSelection.RememberMe);
        }
    }

    private GetSelection() {
        const userVarsManager = UserVarsManager.Instance;
        const userSelection = userVarsManager.GetUserSelection(this.GetForm().GetScreen().GetId());

        let filteredUserSelection = userSelection;
        if (filteredUserSelection) {
            filteredUserSelection.UserSelection = _.filter(filteredUserSelection.UserSelection, item => item.UserGroups !== undefined);

            filteredUserSelection.UserSelection.forEach((user) => {
                if (!user.Guid) {
                    user.Guid = Guid.NewGuid();
                }
            });
        }

        this._userSelection(filteredUserSelection);

        const screen = this.GetForm().GetScreen();

        if (!this._isShowRememberMe()) {
            this._initialized = true;
            screen.Trigger(EVENTS.USERS_SELECTION_CHANGED, this._state);
            return;
        }

        if (filteredUserSelection) {
            this._isRememberMe(filteredUserSelection.RememberMe);
        }

        if (filteredUserSelection && filteredUserSelection.RememberMe && this._isShowRememberMe()) {
            const checkedSelection = _.find(filteredUserSelection.UserSelection, item => item.Checked);

            if (checkedSelection) {
                this.ChooseSelection(checkedSelection);
            } else {
                this._initialized = true;
                screen.Trigger(EVENTS.USERS_SELECTION_CHANGED, this._state);
            }
        } else {
            this._initialized = true;
        }
    }

    private ChooseSelection(data: IUserSelection) {
        SelectUserStore.GetSelectUserModel(this._entityId)
            .then((model: SelectUserModel) => {
                model.UserGroups = this.AddingUsersToGroups(model);
                model = this.RestoreState(model);

                const users = _.intersection(model.Users.map(user => user.Id), _.flatten(data.UserGroups.map(group => group.UserIds)));
                const userGroups = _.intersection(model.UserGroups.map(group => group.Id), data.UserGroups.map(group => group.GroupId));
                this._state.SelectedUsersAndGroups = data.UserGroups
                    .filter(group => userGroups.includes(group.GroupId) || _.intersection(group.UserIds, users).length > 0);

				this.SetAppointmentAttendees(model);

                const screen = this.GetForm().GetScreen();
                const screenId = screen && screen.GetId();

                this._isPlanner(false);
                this._state.ShowPlanned = false;

                if (screen) {
                    this._activeSelection({Guid: data.Guid, Name: data.Users});

                    // Change order of selection on UI
                    const selectionUIIndex = this._userSelection().UserSelection.findIndex(s => s.Guid === data.Guid);
                    const selectionUI = this._userSelection().UserSelection[selectionUIIndex];

                    this._userSelection().UserSelection.splice(selectionUIIndex, 1);
                    this._userSelection().UserSelection.unshift(selectionUI);
                    this._userSelection.valueHasMutated();

                    // Change order of selection in user vars
                    const userVarsManager = UserVarsManager.Instance;

                    const userSelectionData = userVarsManager.GetUserSelections();
                    let selectionByScreenId = _.find(userSelectionData, (selection: ISelection) => selection.ScreenId === screenId);

                    if (this._isRememberMe()) {
                        _.forEach(selectionByScreenId.UserSelection, (item: IUserSelection) => {
                            item.Checked = item.Guid === data.Guid;
                        });
                    }

                    const selectionIndex = selectionByScreenId.UserSelection.findIndex(s => s && s.Guid === data.Guid);
                    if (selectionIndex !== -1) {
                        const selection = selectionByScreenId.UserSelection[selectionIndex];

                        selectionByScreenId.UserSelection.splice(selectionIndex, 1);
                        selectionByScreenId.UserSelection.unshift(selection);

                        userVarsManager.SetUserSelection(screenId, userSelectionData);
                    }

                    this._initialized = true;
                    screen.Trigger(EVENTS.USERS_SELECTION_CHANGED, this._state);

                    if(this._dropDownTooltip) {
                        this._dropDownTooltip.Close();
                    }
                }

                this._initialized = true;
            })
            .fail(error => {
                new Notifier().Failed(error.message);
                this._initialized = true;
            })
            .always(() => BlockUI.Unblock());

    }

    private IsShowPlanner(){
        UserManager.Instance.GetUserAllowance()
            .then((userAllowance: number) => {
                const planningAllowed = USER_ALLOWANCE.PlanningAllowed(userAllowance);
                this._isShowPlanner(planningAllowed);
            });
    }

    private RemoveSelection(data: IUserSelection) {

        //Remove clicked selection from list
        const userSelection = this._userSelection();

        const index = userSelection.UserSelection.indexOf(data);
        userSelection.UserSelection.splice(index, 1);

        this._userSelection(userSelection);

        //Remove clicked selection from user vars
        const screenId = this.GetForm().GetScreen().GetId();
        const userSelectionData = UserVarsManager.Instance.GetUserSelections();

        const selectionByScreenIdIndex = _.findIndex(userSelectionData, (selection: ISelection) => selection.ScreenId === screenId);
        userSelectionData.splice(selectionByScreenIdIndex, 1, userSelection);

        UserVarsManager.Instance.SetUserSelection(screenId, userSelectionData);

        //Active selection has been removed
        if (this._activeSelection() && this._activeSelection().Guid === data.Guid) {
            this._activeSelection(null);

			this._state.SelectedUsersAndGroups = [];
			this.SetAppointmentAttendees(null);
            this.GetForm().GetScreen().Trigger(EVENTS.USERS_SELECTION_CHANGED, this._state);
        }
    }

    private ClearSelection() {
        const triggerChanges = !!this._activeSelection();

        this._activeSelection(null);
		this._state.SelectedUsersAndGroups = [];
		this.SetAppointmentAttendees(null);

        if (this._isShowRememberMe()) {
            this.SetRememberMe();
        }

        if (triggerChanges) {
            this.GetForm().GetScreen().Trigger(EVENTS.USERS_SELECTION_CHANGED, this._state);
        }
    }

    private RememberMeGlobalEnabled(): boolean {
        const globalManager = GlobalManager.Instance;
        const global = globalManager.GetGlobal(GLOBALS.REMEMBER_SELECT_USER);

        return !!parseInt(global);
    }

    private SetRememberMe() {
        if (!this._activeSelection()) {
            this._isRememberMe(false);
        }

        const screenId = this.GetForm().GetScreen().GetId();
        const userVarsManager = UserVarsManager.Instance;
        userVarsManager.SetRememberUserSelection(screenId, this._isRememberMe());

        return true;
    }

    private GetRememberMeValue(): boolean {
        const screenId = this.GetForm().GetScreen().GetId();
        const userVarsManager = UserVarsManager.Instance;
        let data = userVarsManager.GetUserSelection(screenId);

        return data && _.find(data.UserSelection, us => us.Checked) ? data.RememberMe : false;
	}

	private SetAppointmentAttendees(model: SelectUserModel) {
		if (!model) {
			this._state.AppointmentAttendees = null;
			return;
		}

        const appointmentAttendees = new SelectUserModel();
        const selectedAllUserIds = _.flatten(
            _.pluck(
                _.filter(this._state.SelectedUsersAndGroups, group => group.GroupId === null),
                'UserIds'
            )
        );
        const users: Array<UserModel> = _.filter(model.Users, user => _.includes(selectedAllUserIds, user.Id));

        const selectedGroups: UserGroupModel[] = _.filter(model.UserGroups, userGroup => _.some(this._state.SelectedUsersAndGroups, selectedGroup => selectedGroup.GroupId === userGroup.Id));
        const usersByGroup: UserGroupModel[] = selectedGroups.map(group => {
            const selectedGroup = _.find(this._state.SelectedUsersAndGroups, sg => sg.GroupId === group.Id);
            if (selectedGroup) {
                group.Users = _.filter(group.Users, user => _.includes(selectedGroup.UserIds, user.Id))
            }
            return group;
        });

        appointmentAttendees.Users = _.uniq([
            ...users,
            ..._.flatten(usersByGroup.map(group => group.Users))
        ], false, user => user.Id);
        appointmentAttendees.UserGroups = selectedGroups;

		_.each(appointmentAttendees.Users, user => user.Checked = false);
		_.each(appointmentAttendees.UserGroups, userGroup => userGroup.Checked = false);

		this._state.AppointmentAttendees = appointmentAttendees;
	}

	private SelectByBusinessRole(selectUserByBusinessRoleModel: SelectUserByBusinessRoleModel) {
		if (!selectUserByBusinessRoleModel || !selectUserByBusinessRoleModel.SelectedUsers || !_.any(selectUserByBusinessRoleModel.SelectedUsers)) {
			return;
		}

		let model = this.MapToSelectUserModel(selectUserByBusinessRoleModel);
		if (!_.any(model.Users, user => user.Checked) && !_.any(model.UserGroups, userGroup => userGroup.Checked)) {
			return;
		}

		this.ApplySelection(model, true);
	}

	private MapToSelectUserModel(selectUserByBusinessRoleModel: SelectUserByBusinessRoleModel) {
		const selectedUserIds = selectUserByBusinessRoleModel.SelectedUsers;

		let model = new SelectUserModel();
		model.Users = selectUserByBusinessRoleModel.Users;
		model.UserGroups = selectUserByBusinessRoleModel.UserGroups;
		model.UserGroups = this.AddingUsersToGroups(model);

		model.Users.forEach(user => user.Checked = _.contains(selectedUserIds, user.Id));
		model.UserGroups.forEach(group => {
			const groupIsChecked = _.contains(selectedUserIds, group.Id);
			group.Checked = groupIsChecked;
			group.Users.forEach(user => user.Checked = groupIsChecked);
		});

		return model;
	}
}