import {SpreadsheetDescriptorDto} from '../Models/Dto/SpreadsheetDescriptorDto';
import {SpreadsheetFieldDescriptorDto} from '../Models/Dto/SpreadsheetFieldDescriptorDto';
import {SpreadsheetDimensionDescriptorDto} from '../Models/Dto/SpreadsheetDimensionDescriptorDto';
import {SpreadsheetCellPositionDto} from '../Models/Dto/SpreadsheetCellPositionDto';
import {SpreadsheetPageDataDto} from '../Models/Dto/SpreadsheetPageDataDto';
import {SpreadsheetCellDataDto} from '../Models/Dto/SpreadsheetCellDataDto';

import {SpreadsheetDescriptor} from '../Models/View/SpreadsheetDescriptor';
import {SpreadsheetFieldDescriptor} from '../Models/View/SpreadsheetFieldDescriptor';
import {SpreadsheetDimensionDescriptor} from '../Models/View/SpreadsheetDimensionDescriptor';
import {SpreadsheetCellPosition} from '../Models/View/SpreadsheetCellPosition';
import {SpreadsheetPageData} from '../Models/View/SpreadsheetPageData';
import {SpreadsheetCellData} from '../Models/View/SpreadsheetCellData';
import {SpreadsheetPageDescriptorDto} from '../Models/Dto/SpreadsheetPageDescriptorDto';
import {SpreadsheetPageDescriptor} from '../Models/View/SpreadsheetPageDescriptor';

export class SpreadsheetViewMappingProfile {
    ToSpreadsheetDescriptor(descriptor: SpreadsheetDescriptorDto): SpreadsheetDescriptor {
        const valueField = this.ToFieldDescriptor(descriptor.ValueField);
        const pageField = this.ToFieldDescriptor(descriptor.PageField);
        const origin = this.ToCellPosition(descriptor.Origin);
        const dimensions = this.ToDimensionDescriptors(descriptor.Dimensions);

        return new SpreadsheetDescriptor(valueField, pageField, origin, dimensions, descriptor.IsView);
    }

    ToFieldDescriptor(descriptor: SpreadsheetFieldDescriptorDto): SpreadsheetFieldDescriptor {
        return descriptor
            ? new SpreadsheetFieldDescriptor(descriptor.Id,
                descriptor.Name, descriptor.TranslatedName,
                descriptor.TypeId, descriptor.Type,
                descriptor.ValTableName, descriptor.ValFieldName)
            : null;
    }

    ToDimensionDescriptors(descriptors: SpreadsheetDimensionDescriptorDto[]): SpreadsheetDimensionDescriptor[] {
        return descriptors.map(descriptor => this.ToDimensionDescriptor(descriptor));
    }

    ToDimensionDescriptor(descriptor: SpreadsheetDimensionDescriptorDto): SpreadsheetDimensionDescriptor {
        const field = this.ToFieldDescriptor(descriptor.Field);
        const startPosition = this.ToCellPosition(descriptor.StartPosition);

        return new SpreadsheetDimensionDescriptor(field, descriptor.Direction, descriptor.Number, startPosition);
    }

    ToCellPosition(position: SpreadsheetCellPositionDto): SpreadsheetCellPosition {
        return new SpreadsheetCellPosition(position.Column, position.Row);
    }

    ToPagesData(pagesData: SpreadsheetPageDataDto[], spreadsheetDescriptor: SpreadsheetDescriptor) {
        return pagesData.map(pageData => this.ToPageData(pageData, spreadsheetDescriptor));
    }

    ToPageData(pageData: SpreadsheetPageDataDto, spreadsheetDescriptor: SpreadsheetDescriptor): SpreadsheetPageData {
        const cellsData = this.ToCellsData(pageData.CellsData);
        const pageDescriptor = spreadsheetDescriptor.PageField ? this.ToPageDescriptor(pageData.Descriptor, spreadsheetDescriptor.PageField) : null;
        return new SpreadsheetPageData(cellsData, spreadsheetDescriptor.Dimensions, pageDescriptor);
    }

    ToCellsData(cellsData: SpreadsheetCellDataDto[]): SpreadsheetCellData[] {
        return cellsData.map(cellData => this.ToCellData(cellData));
    }

    ToCellData(cellData: SpreadsheetCellDataDto): SpreadsheetCellData {
        return new SpreadsheetCellData(cellData.PositionName, cellData.Value, cellData.DimensionNumber);
    }

    ToPageDescriptor(descriptor: SpreadsheetPageDescriptorDto, pageFieldDescriptor: SpreadsheetFieldDescriptor): SpreadsheetPageDescriptor {
        const {defaultPageName, defaultPageDisplayName} =
            pageFieldDescriptor.IsLookup ? {
                defaultPageName: SpreadsheetPageData.GetDefaultPageId(),
                defaultPageDisplayName: SpreadsheetPageData.GetDefaultPageName()
            } : {
                defaultPageName: SpreadsheetPageData.GetDefaultPageName(),
                defaultPageDisplayName: SpreadsheetPageData.GetDefaultPageName()
            };

        return new SpreadsheetPageDescriptor(descriptor.PageName || defaultPageName, descriptor.PageDisplayName || defaultPageDisplayName);
    }
}