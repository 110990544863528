export class PropertyValueDto {
    Id: number;
    Value: any;
    IsValid: boolean;

    constructor(id?: number, value?: string, valid?:boolean) {
        this.Id = id;
        this.Value = value;
        this.IsValid = valid;
    }
}