import { BasicCanvasItemViewModel } from "Core/Controls/CanvasDesigner/Models/BasicCanvasItemViewModel";
import { ICanvasItemPositionObject } from "Core/Controls/CanvasDesigner/Interfaces/ICanvasItemPositionObject";
import { CanvasItemModel, CanvasLinkModel } from "Core/Controls/CanvasDesigner/Models/CanvasModel";
import { Utils } from 'Core/Controls/CanvasDesigner/Utils';
import { CanvasShapeWidth } from "Core/Controls/CanvasDesigner/Enums/CanvasShapeWidth";
import { CANVAS_TYPES } from 'Core/Controls/CanvasDesigner/Constants/CanvasTypes';

export class TriggerViewModel extends BasicCanvasItemViewModel {
	parent: number | string;
	embeddingInvalid: boolean = false;
	embedded: boolean = true;
	embeds: Array<number | string> = [];
	parentedNode: boolean = true;
	childSocketsIds: Array<number> = [];
	relatedIconId: string;
	attrs: any;
	toggled: boolean;
	sourceType: string;
	connectedNode: number | string;
	initialPosition: ICanvasItemPositionObject;

	constructor(model: CanvasItemModel) {
		super(model, { width: CanvasShapeWidth[CANVAS_TYPES.TRIGGER], height: 35 });
		this.z = Utils.GetRandomArbitrary(60, 80);
		this.attrs.cursor = 'grab';
		this.attrs.IsCollapsed = false;
		this.InitProperties(model.Properties);
	}

	SetEmbedds(link: CanvasLinkModel): void {
		this.embeds.push(link.CanvasItem2Id || link.CanvasItem2Guid);
	}

	SetSize(size): void {
		this.size = { 'width': size.width, 'height': size.height };
	}

	SetPosition(position: ICanvasItemPositionObject): void {
		this.position = position;
	}

	SetParentId(link: CanvasLinkModel): void {
		this.parent = link.CanvasItem1Id || link.CanvasItem1Guid;
	}

	private InitProperties(properties: string){
		try {
			let jsonProperties = JSON.parse(properties);
			if(jsonProperties.hasOwnProperty('Rappid') && jsonProperties.Rappid.hasOwnProperty('isCollapsed')){
				this.attrs.IsCollapsed = jsonProperties.Rappid.isCollapsed;
			}

			if(jsonProperties && jsonProperties.IsSearchExisting){
				this.attrs.IsSearchExisting = jsonProperties.IsSearchExisting;
			}

		}catch (e) {}
	}
}