import * as ko from 'knockout';
import * as _ from 'underscore';

import {FIELD_TYPES} from 'Core/Constant';

import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';

import {IControl} from 'Core/Controls/IControl';
import {ComplexControl} from 'Core/Controls/ComplexControl/ComplexControl';

import {BaseControlEditor, EVENTS as BASE_CONTROL_EDITOR_EVENTS} from '../BaseControlEditor';

import {GanttChartControlEditorStore} from './Stores/GanttChartControlEditorStore';
import {GanttChartDesignOptions} from './Models/GanttChartDesignOptions';

import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel';

import Template from 'Core/Screens/DesignScreen/ControlEditor/Templates/GanttChartControlEditor.html';
import {ChartControlEditorStore} from '../ChartControl/Stores/ChartControlEditorStore';

ko.templates['Core/Screens/DesignScreen/ControlEditor/Templates/GanttChartControlEditor'] = Template;

export class GanttChartControlEditor extends BaseControlEditor {
    private _control: ComplexControl;
    private _store: GanttChartControlEditorStore;

    private _selectedEntity: any;

    private _ganttViews: KnockoutObservableArray<any>;
    private _selectedGanttView: KnockoutObservable<any>;

    private _resourcesViews: KnockoutObservableArray<any>;
    private _selectedResourcesView: KnockoutObservable<any>;

    constructor(control: IControl) {
        super(control);

        this._control = this.EditControl as ComplexControl;
        this._store = new GanttChartControlEditorStore();

        this._selectedEntity = {Id: this.GetSubjectEntityId(), Name: this.GetSubjectEntityName()}

        this._ganttViews = ko.observableArray([]);
        this._selectedGanttView = ko.observable(null);

        this._resourcesViews = ko.observableArray([]);
        this._selectedResourcesView = ko.observable(null);
    }

    GetTemplateName(): string {
        return 'Core/Screens/DesignScreen/ControlEditor/Templates/GanttChartControlEditor';
    }

    AfterRender(el: HTMLElement) {
        const container = el[0];
        super.AfterRender(container);
        this.LoadData();
    }

    Save() {
        const validationError = this.Validate();
        if (validationError) {
            new Notifier().Warning(validationError);
            return;
        }

        const controlEditorModel = this.GetControlEditorModel();
        this.Trigger(BASE_CONTROL_EDITOR_EVENTS.CONTROL_SAVED, {UpdateControlModel: controlEditorModel});

        this.Close();
    }

    Validate() {
        const validationError = super.Validate();
        if (validationError) {
            return validationError;
        }

        if (!this._selectedGanttView()) {
            this.IsValid(false);
            return 'Please select Gantt View';
        }

        if (!this._selectedResourcesView()) {
            this.IsValid(false);
            return 'Please select Resources View';
        }
    }

    GetControlEditorModel() {
        const controlEditorModel = super.GetControlEditorModel();

        this._control.AutoSelectFieldsFromStruct([this._selectedGanttView()]);

        const resourcesViewPkey = _.find(this._selectedResourcesView().Fields, (field: any) => field.Type === FIELD_TYPES.PKey);
        const fieldSort = this._control.Model.Fields[this._control.Model.Fields.length - 1].Sort + 10;
        const resourcesAttachedField = this.CreateAttachedField(this._selectedResourcesView(), resourcesViewPkey, fieldSort);

        controlEditorModel.AttachedFields.push(...this._control.Model.Fields, resourcesAttachedField);

        return controlEditorModel;
    }

    private LoadData() {
        BlockUI.Block();

        this._store.GetDesignOptions(this.GetSubjectEntityId())
            .then(options => this.PopulateData(options))
            .fail(err => new Notifier().Failed(err.message))
            .always(() => BlockUI.Unblock());
    }

    private PopulateData(options: GanttChartDesignOptions) {
        this._ganttViews(options.GanttViews);
        this._resourcesViews(options.ResourcesViews);

        const attachedSubTableViews = _.chain(this.EditControlModel.Fields).map(f => f.EntityId).uniq().value();

        if (attachedSubTableViews && attachedSubTableViews.length) {
            const selectedGanttView = _.find(options.GanttViews, t => t.Id === attachedSubTableViews[0]);
            this._selectedGanttView(selectedGanttView);

            const selectedResourcesView = _.find(options.ResourcesViews, t => t.Id === attachedSubTableViews[1]);
            this._selectedResourcesView(selectedResourcesView);
        }
    }

    private CreateAttachedField(subTableView: any, field: any, sort: number) {
        const attachedField = new AttachedFieldModel({ Id: field.Id, Name: field.Name, EntityId: subTableView.Id});
        attachedField.EntityTypeName = subTableView.Type;
        attachedField.EntityName = subTableView.Name;
        attachedField.Sort = 10;

        return attachedField;
    }
}