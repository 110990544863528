import * as ko from "knockout"
import 'jquery';

export class UniformExtention {
	static Init() {
		ko.bindingHandlers.uniform = {
			init: (element, valueAccessor) => {
				$(element).uniform();
				var value = valueAccessor();
				var isChecked = false;
				if (value instanceof Function) {
					isChecked = value();
				} else {
					isChecked = value;
				}

				if (isChecked) {
					$(element).parent("span").addClass('checked');
				} else {
					$(element).parent("span").removeClass('checked');
				}
			},
			update: (element, valueAccessor) => {
				var value = valueAccessor();

				var isChecked = false;
				if (value instanceof Function) {
					isChecked = value();
				} else {
					isChecked = value;
				}

				if (isChecked) {
					$(element).parent("span").addClass('checked');
				} else {
					$(element).parent("span").removeClass('checked');
				}
			}
		};
	}
}