import * as _ from 'underscore';

import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {GenericDeserialize} from 'libs/cerialize';
import { LicenseHolderModel } from 'Core/ProfilePage/LicenseHolder/Models/LicenseHolderModel';
import { AttachmentModel } from "Core/Controls/Image/Models/AttachmentModel";

export class LicenseHolderStore {
	static GetLicenseHolderModel(): P.Promise<LicenseHolderModel> {
		var deferredResult = P.defer<LicenseHolderModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.AUTH.GET_CACHED_LICENSE,
				dataType: "json",
				type: "GET"
			}
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(this.Deserialize(data.ResultObject));
				} else {
					deferredResult.reject(data.ErrorMessage);
				}
			});

		return deferredResult.promise();
	}

	static UploadLicenseLogo(params: AttachmentModel): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.AUTH.UPLOAD_LICENSE_LOGO,
				dataType: "json",
				type: "POST"
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(null);
				} else {
					deferredResult.reject(data.ErrorMessage);
				}
			});

		return deferredResult.promise();
	}

	static Deserialize(jsonObject: any): LicenseHolderModel {
		var model = GenericDeserialize<LicenseHolderModel>(jsonObject, LicenseHolderModel);
		return model;
	}
	
}