export class Utils {
	static GetEnumString(_enum: any, key: any): string {
		console.log(_enum);
		console.log(key);
		return _enum[_enum[key]];
	}
}

export function IsTrue(value: any): boolean {
	if (typeof (value) === 'string') {
		value = value.trim().toLowerCase();
	}
	switch (value) {
		case true:
		case "true":
		case 1:
		case "1":
		case "on":
		case "True":
		case "yes":
			return true;
		default:
			return false;
	}
}