import * as _ from "underscore";
import * as ko from "knockout";

import {deserialize} from 'libs/cerialize';
import {deserializeAs} from 'libs/cerialize';
import {DesignedScreenModel} from 'MenuManager/MenuAreas/Models/DesignedScreenModel';
import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

export class MenuAreaModel {
    @deserialize
    Id: number;

    @deserialize
	Name: string;

	@deserialize
	TranslatedName: string;

    @deserialize
    Icon: string;

    @deserialize
    IconType: string;

    @deserialize
    Image: string;

    @deserializeAs(DesignedScreenModel)
    Screens: Array<DesignedScreenModel>;

    IsSelected: KnockoutObservable<boolean>;
    IsActive: KnockoutObservable<boolean>;

    @enumerable public get AreaDisplayName() {
	    return this.TranslatedName || this.Name;
    }

    constructor() {
        this.Screens = [];
        this.IsSelected = ko.observable(false);
        this.IsActive = ko.observable(false);
    }
}