//Libs
import * as _ from "underscore";

//Store models
import { YearDataResponseModel } from "Core/Components/Controls/TimeWriting/Models/Store/Year/Response/YearDataResponseModel";

//View models
import { YearDataViewModel, Quater } from "Core/Components/Controls/TimeWriting/Models/View/YearDataViewModel";

//Mappings
import { WeekMappings } from "Core/Components/Controls/TimeWriting/Mappings/Year/WeekMappings";

export class YearMappingProfile {
	static OnViewModel(model: YearDataResponseModel): YearDataViewModel {
		const viewModel = new YearDataViewModel();

		const weeks = model.Summaries.map(week => WeekMappings.OnViewModel(week));

		// it was "-" instead of "0" while you have no record data Booked && Saldo
		let firstRecord = _.find(weeks, function (week) { return week.Booked != 0 });

		let emptyWeeks = weeks.every(function (elem) {
			var bookedCheck = (elem.Booked == 0 && elem.Saldo == 0);
			if (bookedCheck) {
				elem.Booked = " - ";
				elem.Saldo = " - ";
			}
			return bookedCheck;
		});

		_.each(weeks, week => {
			if (week.Saldo == 0) {
				week.Booked = " - ";
				week.Saldo = " - ";
			}
		});

		const chunkedWeeks = this.Chunk(weeks, 4);

		viewModel.DataBySubject = model.DataBySubject;
		viewModel.Quaters = chunkedWeeks.map(weeksOfQuater => new Quater({ Weeks: weeksOfQuater }));
        return viewModel;
	}
	
	private static Chunk<T>(array: Array<T>, chunks: number): T[][] {
		const elementsInChunk = array.length / chunks;

		const dictionary = _.groupBy(array, (element, index) => Math.floor(index / elementsInChunk));

		const chunkedArray = Object.keys(dictionary).map(key => dictionary[key]);
		return chunkedArray;
	}
}