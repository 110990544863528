import { deserialize } from 'libs/cerialize';
import { deserializeAs } from 'libs/cerialize';
import { SearchFieldModel } from 'Core/Screens/SearchScreen/Models/SearchFieldModel';
import { GroupNameToRecordsModel } from 'Core/Screens/SearchScreen/Models/GroupNameToRecordsModel';
import { IconModel } from "Core/Controls/BaseControl/Models/IconModel";
import { EntityModel } from "Core/GeneralProperties/Managers/AlternativeEntitiesProperty/Models/EntityModel";

import { SearchGroups } from "Core/Enums/SearchGroups";
import { SearchTypeModel } from 'Core/Screens/SearchScreen/Models/SearchTypeModel';

export class SearchScreenModel {

	constructor() {
		this.SearchGroups = [];
		this.SearchFields = [];
		this.GroupNameToRecords = new GroupNameToRecordsModel();
	}

	public get Name() {
		return this.EntityTranslatedName === null ? this.EntityName : this.EntityTranslatedName;
	}

	@deserialize
    EntityId: number;

    @deserialize
    TableSecurityRights: number;

    @deserialize
    AllowDeduplicationMode: boolean;

	@deserialize
	EntityName: string;

	@deserialize
	EntityTypeName: string;

	@deserialize
	EntityTranslatedName: string;

	@deserialize
	SearchTerm: string;

	@deserialize
	Sharing: boolean;

	@deserialize
	AddingRecordAllowed: boolean;

	@deserialize
	AlternativeEntities: Array<EntityModel>

	@deserializeAs(SearchGroups)
	SearchGroups: Array<SearchGroups>;

	@deserializeAs(GroupNameToRecordsModel)
	GroupNameToRecords: GroupNameToRecordsModel;

	@deserializeAs(SearchFieldModel)
    SearchFields: Array<SearchFieldModel>;

    @deserializeAs(SearchTypeModel)
    Types: Array<SearchTypeModel>;

	@deserializeAs(IconModel)
	IconModel: IconModel;

	@deserialize
	HasBarcodeFields: boolean;
}