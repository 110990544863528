import * as ko from 'knockout';
import * as $ from 'jquery';

import 'jqueryInputmask';
import 'inputmaskNumeric';

export class IntegerInputMaskExtension {
    static Init() {
        ko.bindingHandlers.integerInputMask = {
            init: (element, valueAccessor: KnockoutObservable<object>) => {
                $(element).inputmask('integer', valueAccessor());
            }
        }
    }
}