'use strict';

import * as ko from "knockout";
import * as _ from 'underscore';

import { BaseControl, IControlValue } from 'Core/Controls/BaseControl/BaseControl'
import { IControlParam } from "Core/Screens/IScreen";
import { RenderModes } from "Core/Constant";
import { GeneralProperties } from "Core/GeneralProperties/GeneralProperties";
import {CHECKBOX_PROPERTIES} from "Core/GeneralProperties/Managers/CheckboxProperty/Constants";

import ListButtonConfig from "Core/Controls/ButtonListScreen/Configs/list-button-config.json";

import 'Core/Controls/ButtonListScreen/Templates/Edit.html';

import ViewTemplate from "Core/Controls/ButtonListScreen/Templates/View.html";
import HelpViewTemplate from "Core/Controls/ButtonListScreen/Templates/HelpView.html";
import ToolBarTemplate from "Core/Controls/ButtonListScreen/Templates/ToolBar.html";
import DesignTemplate from "Core/Controls/ButtonListScreen/Templates/Design.html";

ko.templates["Core/Controls/ButtonListScreen/Templates/HelpView"] = HelpViewTemplate;
ko.templates["Core/Controls/ButtonListScreen/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/ButtonListScreen/Templates/Edit"] = ViewTemplate;
ko.templates["Core/Controls/ButtonListScreen/Templates/ToolBar"] = ToolBarTemplate;
ko.templates["Core/Controls/ButtonListScreen/Templates/Design"] = DesignTemplate;

export class ButtonListScreen extends BaseControl {
    private _config: any;

	constructor(params: IControlParam) {
		super(params, ListButtonConfig);

		this._enabled = ko.observable(this.IsListScreenExist);
        this._style = ko.computed(() => {
            return {
                backgroundColor: this._backgroundColor(),
                color: this._color(),
                borderColor: this._borderColor(),
                borderWidth: this._border() ? '1px' : '0',
                padding: this._border() ? '6px 14px' : '7px 14px'
            };
        });
		this.Init();
	}

	private Init(): void {
        this.ApplyProperties();
    }      

	Click(): void {
		if (this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
		} else {
			var screen = this._form.GetScreen();
			if (screen) {
				screen.Trigger("SHOW_LIST_SCREEN");
			}
		}
	}

	SetValue(value: IControlValue): void {
	}

    SetHideCaption(data: boolean) {
        this.HideCaption(this.HideCaptionFromProperty() ? this.HideCaptionFromProperty() : data);
    }

    GetHideCaption(): boolean {
        return this.HideCaption();
    }

    ApplyProperties() {
        if (this.Properties) {
            this.AssignProperty('Styling', 'BackgroundColor', this._backgroundColor);
            this.AssignProperty('Styling', 'TextColor', this._color);
            this.AssignProperty('Styling', 'Border', this._border);
            this.AssignProperty('Styling', 'BorderColor', this._borderColor);
            this.AssignProperty('Styling', CHECKBOX_PROPERTIES.HIDE_CAPTION, this.HideCaptionFromProperty);
            this.HideCaption(this.HideCaptionFromProperty());
        }
    }

    private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
        if (this.Properties[groupedBy]) {
            _.each(this.Properties[groupedBy].Properties,
                (property: any) => {
                    if (property.hasOwnProperty(propertyName)) {
                        propertyHolder(property[propertyName]);
                    }
                });
        }
    }

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}
}