import { deserialize } from 'libs/cerialize';
import { deserializeAs } from 'libs/cerialize';

import { TranslationModel } from "./TranslationModel";

export class BlobFieldModel {
	@deserialize
	FieldId: number;

	@deserialize
	FieldName: string;

	@deserializeAs(TranslationModel)
	FieldNameTranslations: Array<TranslationModel>;

	@deserialize
	EntityId: number;

	@deserialize
	EntityName: string;

	@deserializeAs(TranslationModel)
	EntityNameTranslations: Array<TranslationModel>;

	@deserialize
	EntityNameWithFieldName: string;
}