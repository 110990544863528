import * as ko from 'knockout';
import * as _ from 'underscore';

import {BlockUI} from "Core/Common/BlockUi";
import {ScreenTypes} from "Core/Common/Enums/ScreenTypes";

import {BaseControlEditor, EVENTS} from "./BaseControlEditor";
import {IControl} from "Core/Controls/IControl";

import {ILabelPosition} from "./ControlEditor";
import {LabelPositions} from "../Enums/LabelPositions";

import {EntityModel} from "../Models/EntityModel";

import {EntitiesStore} from "../Stores/EntitiesStore";

import Template from 'Core/Screens/DesignScreen/ControlEditor/Templates/EntityControlEditor.html';
import {AttachedFieldModel} from '../../../../Controls/BaseControl/Models/AttachedFieldModel';

ko.templates['Core/Screens/DesignScreen/ControlEditor/Templates/EntityControlEditor'] = Template;

export class EntityControlEditor extends BaseControlEditor {
    protected LabelPositions: KnockoutObservableArray<ILabelPosition>;
    protected SelectedLabelPosition: KnockoutObservable<ILabelPosition>;

    protected Entities: KnockoutObservableArray<EntityModel>;
    protected SelectedEntity: KnockoutObservable<EntityModel>;

    constructor(control: IControl) {
        super(control);

        this.InitLabelPositions();
        this.InitEntities();
    }

    GetTemplateName(): string {
        return 'Core/Screens/DesignScreen/ControlEditor/Templates/EntityControlEditor';
    }

    AfterRender(el: HTMLElement) {
        const container = el[0];
        super.AfterRender(container);

        BlockUI.Block();

        this.LoadData()
            .then(entities => this.PopulateEntities(entities))
            .always(() => BlockUI.Unblock());
    }

    Validate(): string {
        const errorMessage = super.Validate();

        if (errorMessage) {
            this.IsValid(false);
            return errorMessage;
        }

        if (!this.SelectedEntity()) {
            this.IsValid(false);
            return 'Please, select entity';
        }
    }

    GetControlEditorModel() {
        const controlEditorModel = super.GetControlEditorModel();

        controlEditorModel.LabelPosName = this.SelectedLabelPosition().Title;
        controlEditorModel.LabelPos = this.SelectedLabelPosition().Value;

        const selectedEntity = this.SelectedEntity();
        if (selectedEntity) {
            controlEditorModel.EntityId = selectedEntity.EntityId;
            controlEditorModel.EntityName = selectedEntity.EntityName;
        }

        const selectedField = this.GetSelectedField();
        if (selectedField) {
            controlEditorModel.FieldId = selectedField.FieldId;
        }

        return controlEditorModel;
    }

    protected InitLabelPositions() {
        this.LabelPositions = ko.observableArray([
            {Value: LabelPositions.NoLabel, Title: LabelPositions[LabelPositions.NoLabel]},
            {Value: LabelPositions.Left, Title: LabelPositions[LabelPositions.Left]},
            {Value: LabelPositions.UpperLeft, Title: LabelPositions[LabelPositions.UpperLeft]},
            {Value: LabelPositions.UpperMiddle, Title: LabelPositions[LabelPositions.UpperMiddle]},
            {Value: LabelPositions.UpperRight, Title: LabelPositions[LabelPositions.UpperRight]},
            {Value: LabelPositions.Right, Title: LabelPositions[LabelPositions.Right]},
            {Value: LabelPositions.LeftColon, Title: LabelPositions[LabelPositions.LeftColon]}
        ]);

        const selectedLabelPosition = _.find(this.LabelPositions(), item => item.Value === LabelPositions[this.EditControlModel.LabelPosition]);
        this.SelectedLabelPosition = ko.observable(selectedLabelPosition);
    }

    protected InitEntities() {
        this.Entities = ko.observableArray([]);
        this.SelectedEntity = ko.observable(null);
    }

    private GetSelectedField() {
        return this.SelectedEntity() && this.SelectedEntity().Fields.find(f => f.IsPrimaryKey());
    }

    protected BindEvents() {
        this.SelectedEntity.subscribe(entity => this.InitField(entity));
    }

    protected LoadData() {
        return EntitiesStore.Get({
            EntityId: this.GetSubjectEntityId(),
            TableTypeId: this.GetSubjectTableTypeId()
        });
    }

    protected PopulateEntities(entities: EntityModel[]) {
        const onlySubjectAcceptable = this.IsScreenOfType(ScreenTypes.EditScreen, ScreenTypes.ListScreen);

        if (onlySubjectAcceptable) {
            entities = entities.filter(entity => entity.EntityId === this.SubjectEntityId);
        }

        entities = _.uniq(entities, false, entity => entity.EntityId);

        this.Entities(entities);

        const attachedField = _.first(this.EditControlModel.Fields);
        const attachedEntity = attachedField && _.find(entities, entity => entity.EntityId === attachedField.EntityId);

        if (attachedEntity) {
            this.SelectedEntity(attachedEntity);
        } else {
            const subjectEntity = _.find(entities, entity => entity.EntityId === this.SubjectEntityId);
            this.SelectedEntity(subjectEntity);
        }

        this.InitField(this.SelectedEntity());
        this.BindEvents();
    }

    protected InitField(entity: EntityModel) {
        const selectedField = this.GetSelectedField();
        if (!selectedField) {
            return;
        }

        let controlFieldModel = this.EditControl.GetFieldModel();
        if (!controlFieldModel) {
            controlFieldModel = new AttachedFieldModel();
            this.EditControl.GetModel().Fields.push(controlFieldModel);
        }

        controlFieldModel.EntityId = entity.EntityId;
        controlFieldModel.EntityName = entity.EntityName;

        controlFieldModel.Id = selectedField.FieldId;
        controlFieldModel.Name = selectedField.FieldName;
    }
}