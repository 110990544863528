import enumerable from './Decorators/EnumerableDecorator';

export class ZIndexManager {
	private static inst: ZIndexManager;
	private _zIndex: number;

	constructor() {
		this._zIndex = 10000;
	}

	static get Instance() {
		if (this.inst === null || this.inst === undefined) {
			this.inst = new ZIndexManager();
		}
		return this.inst;
	}

	get NextValue(): number {
		return this._zIndex ++ ;
	}

	get ResetValue(): number {
		return this._zIndex = 10000;
	}

}