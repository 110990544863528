const Palette_945d60 = '#945d60';
const Palette_CCCCCC = '#CCCCCC';
export enum BackgroundColour {
	Source = Palette_945d60,
	Destination = Palette_945d60,
	Table = Palette_CCCCCC,
	Group = Palette_CCCCCC,
	Trigger = Palette_CCCCCC,
	JSFunction = Palette_CCCCCC,
	CSharpFunction = Palette_CCCCCC,
	APIMethod = Palette_CCCCCC,
	Param = '#EAC67A'
}