import {P} from "Core/Common/Promise";
import {URL} from 'Core/Common/Url';

import {Request} from 'Core/Common/Request';

import {PagedResult} from "Core/Results/ResultModels/PagedResult";

import {IFinancialDashboardStore} from './IFinancialDashboardStore';

import {IGetGridDataRequestModel} from "QueryBuilder/QueryResultGrid/Stores/QueryResultStore";
import {ICreateInvoiceDtoModel} from '../Models/Dto/Request/ICreateInvoiceDtoModel';
import {IGetFinancialProcessorsDtoModel} from "../Models/Dto/Request/IGetFinancialProcessorsDtoModel";
import {ISendToFPDtoModel} from '../Models/Dto/Request/ISendToFPDtoModel';
import {IGetReadyForInvoicingDataDtoModel} from "../Models/Dto/Request/IGetReadyForInvoicingDataDtoModel";

import {FinancialProcessorDtoModel} from "../Models/Dto/Response/FinacialProcessorDtoModel";
import {ReadyForProcessingDataDtoModel} from "../Models/Dto/Response/ReadyForProcessingDataDtoModel";
import {ReadyForInvoicingDataDtoModel} from "../Models/Dto/Response/ReadyForInvoicingDataDtoModel";

export class FinancialDashboardStore implements IFinancialDashboardStore {
    GetData(controlId: number) {
        return Request.Send({
            proxy: {
                url: 'api/FinancialApi/GetDashboardData',
                type: 'GET',
                dataType: 'json',
            },
            params: {controlId: controlId}
        });
    }

    Invoice(createInvoiceParams: ICreateInvoiceDtoModel) {
        return Request.Send({
            proxy: {
                url: 'api/FinancialApi/ConvertToInvoiceBatch',
                type: 'POST',
                dataType: 'json'
            }, params: createInvoiceParams
        });
    }

    GetFinancialProcessors(params: IGetFinancialProcessorsDtoModel): P.Promise<PagedResult<FinancialProcessorDtoModel>> {
        return Request.Send({
            proxy: {
                url: 'api/FinancialApi/GetFinancialProcessors',
                dataType: 'json',
                type: 'GET',
            }, params: params
        });
    }

    GetReadyForInvoicingData(params: IGetReadyForInvoicingDataDtoModel): P.Promise<ReadyForInvoicingDataDtoModel> {
        return Request.Send({
            proxy: {
                url: 'api/FinancialApi/GetReadyForInvoicingData',
                dataType: 'json',
                type: 'GET'
            }, params: params
        });
    }

    GetReadyForProcessingData(params: IGetGridDataRequestModel): P.Promise<ReadyForProcessingDataDtoModel> {
        const deferred = P.defer<ReadyForProcessingDataDtoModel>();

        Request.Send({
            proxy: {
                url: URL.CONTROLS.GET_GRID_DATA,
                dataType: 'json',
                type: 'POST'
            }, params: params
        }).then(result => {
            if (result.IsSuccessfull) {
                deferred.resolve(result.ResultObject);
            } else {
                deferred.reject({message: result.ErrorMessage});
            }
        }).fail(() => deferred.reject({message: 'Error loading ready for processing data'}));

        return deferred.promise();
    }

    SendToFinancialProcessor(sendToFPParams: ISendToFPDtoModel) {
        return Request.Send({
            proxy: {
                url: 'api/FinancialApi/SendToFinancialProcessor',
                type: 'POST'
            }, params: sendToFPParams
        });
    }
}

