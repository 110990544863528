import * as ko from 'knockout';
import {BlockUI} from 'Core/Common/BlockUi';
import {Guid} from "Core/Common/Guid";
import {HelpDescription} from "HelpBook/HelpDescription/HelpDescription";

import {HelpBookStore} from "HelpBook/Store/HelpBookStore";
import {Notifier} from 'Core/Common/Notifier';

import {
    AttachedField,
    SubFormControl,
    Control
} from 'HelpBook/Store/Models/HelpBookModel';

import HelpControlTemplate from 'HelpBook/HelpControl/Templates/HelpControl.html';
import {LABELS} from "../../Core/Components/Translation/Locales";
import {RenderModes} from "../../Core/Constant";
import {IControlParam} from "../../Core/Screens/IScreen";
import {AttachedFieldModel} from "../../Core/Controls/BaseControl/Models/AttachedFieldModel";
import {ControlModel} from "../../Core/Controls/BaseControl/Models/ControlModel";
import {TranslationModel} from 'Core/Controls/BaseControl/Models/TranslationModel';
import {LanguageModel} from 'Core/Controls/BaseControl/Models/LanguageModel';
import {TranslationManager} from "Core/Components/Translation/TranslationManager";

ko.templates['HelpBook/HelpControl/Templates/HelpControl'] = HelpControlTemplate;

export class HelpControl {
    private selectedControlSubControls: KnockoutObservableArray<SubFormControl>;
    private subControlsPresent: KnockoutObservable<boolean>;
    private selectedControlHasData: KnockoutObservable<boolean>;
    private selectedControlName: KnockoutObservable<string>;
    private selectedControlTypeName: KnockoutObservable<string>;
    private selectedControlDescription: KnockoutObservable<string>;
    private attachedFields: KnockoutObservableArray<AttachedField>;
    private controlComponents;
    private noDescription: KnockoutObservable<boolean>;
    private isActive: KnockoutObservable<boolean>;
    private HelpDescription: KnockoutObservable<HelpDescription>;

    private selectedEntityId: number;

    private _labels = LABELS;

    constructor(screen) {
        this.selectedControlSubControls = ko.observableArray([]);
        this.selectedControlHasData = ko.observable(false);
        this.selectedControlName = ko.observable('');
        this.selectedControlTypeName = ko.observable('');
        this.selectedControlDescription = ko.observable('');
        this.attachedFields = ko.observableArray([]);
        this.controlComponents = [];
        this.isActive = ko.observable(true);
        this.subControlsPresent = ko.observable(false);
        this.HelpDescription = ko.observable(null);

        this.FetchData(screen);
    }

    FetchData(control) {
        BlockUI.Block();

        HelpBookStore.GetControlInfo({id: control.Id}).always(() => {
            BlockUI.Unblock();
        }).then(async result => {
            if (!result.IsSuccessfull) {
                new Notifier().Failed(result.ErrorMessage);
                return;
            }
            if (result.HasData) {
                const subControls = result.ResultObject.Controls.filter((control) => {
                    return control.Name !== null;
                }).map((control) => {
                    return _.extend({}, control, {
                        isActive: ko.observable(false),
                        Click: (control) => {
                            control.isActive(!control.isActive());
                        },
                        HelpDescription: new HelpDescription(result.ResultObject.Description)
                    });
                });

                this.selectedControlSubControls(subControls);
                this.subControlsPresent(Boolean(subControls.length));

			}

            const controlTranslatedName = this.GetControlTranslation(result.ResultObject);
			this.selectedControlName(controlTranslatedName);

            this.selectedControlTypeName(result.ResultObject.TypeName);
            this.selectedControlDescription(result.ResultObject.Description);
            const controlComponents = [];
            controlComponents.push(await this.IterateOverNestedControls(result.ResultObject));
            this.attachedFields(result.ResultObject.AttachedFields);
            this.controlComponents = controlComponents;
            this.selectedControlHasData(result.HasData);
            this.HelpDescription(new HelpDescription(result.ResultObject.Description));
            this.HelpDescription().ActivateDescription();

        });
    }

    Click() {
        this.isActive(!this.isActive());
    };

    CanBeRendered(control: Control) {
        return control.TypeName.startsWith("Button")
            || control.TypeName === 'Search'
            || control.TypeName === 'DateTime'
            || control.TypeName === 'Text'
            || control.TypeName === 'Checkbox'
            || control.TypeName === 'Dropdown'
            || control.TypeName === 'Memo'
            || control.TypeName === 'Image'
            || control.TypeName === 'Drop'
            || control.TypeName === 'MultiSelect'
            || control.TypeName === 'GenericButton'
            || control.TypeName === 'Document'
            || control.TypeName === 'SelectUser'
            || control.TypeName === 'ColorSelector'
            || control.TypeName === 'Password'
            || control.TypeName === 'RadioButton'
            || control.TypeName === 'Tag'
            || control.TypeName === 'Alias'
            || control.TypeName === 'Label'
            || control.TypeName === 'Group'
            || control.TypeName === 'Currency'
            || control.TypeName === 'Grid'
            || control.TypeName === 'Tab'
            || control.TypeName === 'TabPage'
            || control.TypeName === 'ProductConfigurator'
            || control.TypeName === 'SPIMCopy'
            || control.TypeName === 'History'
			|| control.TypeName === 'Book'
            || control.TypeName === 'BulkEmail'
            || control.TypeName === 'QueryResult'
            || control.TypeName === 'Invoicing'
            || control.TypeName === 'Timer'
            || control.TypeName === 'Signature'
			|| control.TypeName === 'Spreadsheet'
			|| control.TypeName === 'Chart'
    };

    MapToControlModel(control: Control) {
		const attachedFields = control.AttachedFields.map(field => {
            let attachedFieldModel = new AttachedFieldModel({ Id: control.Id, Name: field.Name });
            attachedFieldModel.FieldNameTranslation = field.TranslatedName;
            attachedFieldModel.EntityIcon = field.EntityIcon;
            attachedFieldModel.FieldIcon = field.FieldIcon;

			return attachedFieldModel;
        });

		const currentLanguage = TranslationManager.Instance.GetCurrentLanguage();
		const currentLanguageModel = new LanguageModel(currentLanguage.Id);

		const translationModel = new TranslationModel();
		translationModel.Language = currentLanguageModel;
		translationModel.Selected = true;
		translationModel.Translation = control.TranslatedName;

		let model = new ControlModel();
        model.Fields = attachedFields;
        model.Name = control.Name;
        model.TypeName = control.TypeName;
		model.TranslatedName = control.TranslatedName;
		model.NameTranslations = [translationModel];
        model.UseFieldName = control.UseFieldName;
        model.LabelPosition = control.LabelPosition;
		model.IsFieldAttached = control.IsFieldAttached;
		model.IsLookupFieldAttached = control.IsLookupFieldAttached;
		model.IsMultiSelectFieldAttached = control.IsMultiSelectFieldAttached;
		model.Icon = control.Icon;

        if (control.Properties) {
            try {
                model.Properties = JSON.parse(control.Properties);
            } catch {
            }
        }
        model.SubControls = control.Controls.filter(control => this.CanBeRendered(control)).map(control => this.MapToControlModel(control));
        return model;
    }

    async IterateOverNestedControls(item) {
        if (this.CanBeRendered(item)) {

            const renderMode = RenderModes.HelpView;

            var controlParam: IControlParam = {
                Model: this.MapToControlModel(item),
                RenderMode: renderMode,
                Form: null
            };

            let controlFactory = (await import( "../../Core/Controls/ControlFactory")).ControlFactory;

            return controlFactory.CreateControl(controlParam);
        }
    }

	private GetControlTranslation(control: Control): string {
		const controlName = control.Name;
		let controlTranslation = null;

		if (control.UseFieldName) {
			let fieldAttached = _.first(control.AttachedFields);
			if (fieldAttached) {
				controlTranslation = fieldAttached.TranslatedName;
			}
		} else {
			controlTranslation = control.TranslatedName;
		}

		return controlTranslation || controlName;
	}

    GetTemplateName(): string {
        return 'HelpBook/HelpControl/Templates/HelpControl';
    }

}