import * as ko from 'knockout';
import 'knockoutValidation';

import {Events} from "Auth/Enums/Events";
import {Event} from "Core/Common/Event";
import {Modal} from "Core/Common/Modal";
import {MobileChecker} from 'Core/Common/MobileChecker';

import LicenseFormTemplate from 'Auth/UI/LicenseForm/Templates/LicenseForm.html';
ko.templates['Auth/UI/LicenseForm/Templates/LicenseForm'] = LicenseFormTemplate;

export class LicenseForm extends Event {
	private _isVisible: KnockoutObservable<boolean>;
	private _formTitle: KnockoutObservable<string>;
	private _modal: Modal;
	private _hasFocus: KnockoutObservable<boolean>;
	
	constructor() {
		super();
		this._isVisible = ko.observable(false);
		this._formTitle = ko.observable('Please, input a new one');
		this.Init();
		this._hasFocus = ko.observable(false);
	}

	public Load(formTitle: string, dbName: string, login: string = '') {
		this._formTitle(formTitle);
		this.Show();
	}

	public Close() {
		this._modal.Close();
	}

	private Init() {
		ko.validation.init({
			insertMessages: false
		});
	}

	private Show() {
		this._modal = new Modal({
			width: '400',
			addClass: 'login',
			minHeight: '300'
		}, false);

		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);

		this._modal.Show();
		let isMobile = MobileChecker.IsMobile();
		this._hasFocus(!isMobile);
	}

	GetTemplateName(): string {
		return 'Auth/UI/LicenseForm/Templates/LicenseForm';
	}

	AfterRender(el: HTMLElement) {
	}

	ToggleErrorBlockVisibility() {
		this._isVisible(!this._isVisible());
	}

	//Form Event handlers
	private OkButtonClicked() {
		this._modal.Close();
		this.Trigger(Events.FormClosed);
	}
} 