import * as ko from "knockout"
import 'jquery';

export class ScrollFixerExtention {
	static Init() {
		ko.bindingHandlers.scrollFixer = {
			init: (element, valueAccessor) => {
				let isChrome = !!window['chrome'] && !!window['chrome'].webstore;
				let isIE = /*@cc_on!@*/false || !!document['documentMode'];
				let isEdge = !isIE && !!window['StyleMedia'];
				let isOpera = (!!window['opr'] && !!window['addons']) || !!window['opera'] || navigator.userAgent.indexOf(' OPR/') >= 0;
				if (isChrome || isOpera || isEdge) {
					$(element).bind('mousewheel DOMMouseScroll', function (e) {
						var scrollTo = 0;
						e.preventDefault();
						if (e.type == 'mousewheel') {
							scrollTo = (e.originalEvent['wheelDelta'] * -1);
						} else if (e.type == 'DOMMouseScroll') {
							scrollTo = 40 * e.originalEvent['detail'];
						}
						$(this).scrollTop(scrollTo + $(this).scrollTop());
					});
				}
			}
		};
	}
}