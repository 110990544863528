import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import { StepDto } from '../Models/StepRecordDto';
import { GenericDeserialize } from '../../../../libs/cerialize';

export interface GetStepRecordDto {
	PreviousStepEntityId: number;
	PreviousStepRecordId: number;

	StepScreenId: Number;
}

export class StepApi {

	static  GetStepRecord(params: GetStepRecordDto): P.Promise<StepDto>{
		let deferredResult = P.defer<StepDto>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/StepApi/GetStepRecord',
				dataType: 'json',
				type: 'GET'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(GenericDeserialize<StepDto>(data, StepDto));
			})
			.fail(error => deferredResult.reject(error));

		return deferredResult.promise();
	}
}