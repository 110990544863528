export const SECURITY_LEVELS = {
	NO: 0,
	SHARING_READY: 2,
	SHARED: 4,
	PLANNER: 8,
	OWNER: 512,

	SECURITY_PASS: -2,

	get NEW_OWNER() {
		return SECURITY_LEVELS.SHARED + SECURITY_LEVELS.OWNER;
	}
};