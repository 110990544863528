import {SpreadsheetRecordDto} from './SpreadsheetRecordDto';

export class SpreadsheetNewPageDto {
    constructor(public TableId: number,
                public EntityRecordId: number,
                public PageField: string,
                public PageName: string,
                public ValueField: string,
                public CellsData: SpreadsheetRecordDto[]) {
    }
}