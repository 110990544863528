import * as ko from 'knockout';
import * as _ from 'underscore';

import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

import {Event} from "Core/Common/Event";

import {JBoxDropDown} from "Core/Components/JBoxDropdown/DropDown";

import {IColorSelectorOptions} from "./IColorSelectorOptions";

import {Guid} from "Core/Common/Guid";
import {ZIndexManager} from "Core/Common/ZIndexManager";

import {ColorModel} from "./ColorModels/ColorModel";
import {HexColorModel} from "./ColorModels/Hex/HexColorModel";
import {PaletteColorModel} from "./ColorModels/Palette/PaletteColorModel";

import Template from './Templates/Template.html';
import ColumnTemplate from './Templates/ColumnTemplate.html';
import DropdownTemplate from './Templates/DropdownTemplate.html';
import {ColorDescription} from "./ColorDescription";
import {ColorPalettes} from "./ColorModels/Palette/Enums/ColorPalettes";

export class ColorSelector extends Event {
    private _id: string;

    private _colorSelectorDropdown: JBoxDropDown;
    private _colorModels: string[];
    private _colorModel: KnockoutObservable<string>;

    private _selectedColorName: KnockoutObservable<string>;

    private _selectedColorValue: KnockoutObservable<string>;
    private _colorModelImpl: KnockoutObservable<ColorModel>;

    constructor(private _options: IColorSelectorOptions) {
        super();
        this._id = Guid.NewGuid();

        this._colorModels = ['HEX', 'RAL', 'Palette'];

        this._colorModelImpl = ko.observable(null);

        this._selectedColorName = ko.observable(null);
        this._selectedColorValue = ko.observable(null);

        this.Init();
    }

    @enumerable get SelectedColorDescription() {
        return new ColorDescription(this.SelectedColorName, this.SelectedColorValue);
    }

    @enumerable get SelectedColorName() {
        return this._selectedColorName();
    }

    @enumerable get SelectedColorValue() {
        return this._selectedColorValue();
    }

    GetTemplate() {
        return Template;
    }

    GetColumnTemplate() {
        return ColumnTemplate;
    }

    SetColor(color: string) {
        this._options.InitialColor = color;
        this.Init();
    }

    private Init() {
        this.SelectInitialModel(this._options.InitialColor);
        this.SelectModelImpl(this._options);

        this._selectedColorName(this._options.InitialColor);
        this._selectedColorValue(this._colorModelImpl().ColorValue);

        this._colorModel.subscribe(() => this.SelectModelImpl({
            Label: this._options.Label,
            InitialColor: this._selectedColorName()
        }));
    }

    private SelectInitialModel(color: string) {
        if (!color || color.startsWith('#')) {
            const model = _.find(this._colorModels, modelName => modelName == 'HEX');
            this._colorModel = ko.observable(model);
            return;
        }

        if (color.startsWith('RAL')) {
            const model = _.find(this._colorModels, modelName => modelName == 'RAL');
            this._colorModel = ko.observable(model);
            return;
        }

        const model = _.find(this._colorModels, modelName => modelName == 'Palette');
        this._colorModel = ko.observable(model);
    }

    private SelectModelImpl(options: IColorSelectorOptions) {
        let colorModel: ColorModel;

        switch (this._colorModel()) {
            case 'HEX':
                colorModel = new HexColorModel({...options, Label: null});
                break;

            case 'RAL':
                colorModel = new PaletteColorModel({...options, Palette: ColorPalettes.RAL});
                break;

            case 'Palette':
                colorModel = new PaletteColorModel({...options, Palette: ColorPalettes.Library});
                break;
        }

        colorModel.On('COLOR_SET', this, eventArgs => {
            this._selectedColorName(eventArgs.data.Name);
            this._selectedColorValue(eventArgs.data.Color);
            this.Trigger('COLOR_SET', eventArgs.data);
        });
        colorModel.On('COLOR_RESET', this, () => {
            this._selectedColorValue(null);
            this._selectedColorName(null);
            this.Trigger('COLOR_RESET');
        });

        this._colorModelImpl(colorModel);
    }

    ShowSelector() {
        this._colorSelectorDropdown = this.PrepareSelector();
        this._colorSelectorDropdown.Open();
    }

    private PrepareSelector() {
        return new JBoxDropDown({
            target: '#' + this._id,
            otherOptions: {
                attach: undefined,
                closeOnClick: 'body',
                addClass: 'color-picker-overflow',
                position: {
                    x: 'left',
                    y: 'bottom'
                },
                adjustDistance: 50,
                onCloseComplete: () => this._colorSelectorDropdown.Destroy(),
                zIndex: ZIndexManager.Instance.NextValue
            },
            onOpen: () => {
                this._colorSelectorDropdown.SetContent({content: DropdownTemplate as any});
            },
            bindComponent: this,
        });
    }
}