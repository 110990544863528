import * as ko from 'knockout';

import {BaseCheckItem} from "../BaseCheckItem";
import {ICheckItemParams} from "../ICheckItemParams";

import Template from 'Core/Components/ActionCheckList/CheckItems/Integer/Templates/Template.html';

ko.templates['Core/Components/ActionCheckList/CheckItems/Integer/Templates/Template'] = Template;


export class IntegerCheckItem extends BaseCheckItem {
    constructor(params: ICheckItemParams) {
        super(params);
    }

    GetTemplateName(): string {
        return 'Core/Components/ActionCheckList/CheckItems/Integer/Templates/Template';
    }
}