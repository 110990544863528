import * as _ from 'underscore';

import {Notifier} from 'Core/Common/Notifier';
import {EventBusConsumer} from 'Core/Common/EventBus/EventBusConsumer';
import {EventBus} from 'Core/Common/EventBus/EventBus';

import {LABELS} from 'Core/Components/Translation/Locales';

import {
    NetworkDesignerEvents,
    NewRemoteDatabaseAccess,
    RemoteDatabaseAccessChanges
} from '../Events/NetworkDesignerEvents';

import {RemoteDatabaseAccess} from './RemoteDatabaseAccess';

import {RemoteDatabaseAccessData} from '../Models/RemoteDatabaseAccessData';

export class RemoteDatabase extends EventBusConsumer {
    private _labels = LABELS
    private _accessDataLoaded: boolean;

    private _expanded: KnockoutObservable<boolean>;
    private _error: KnockoutObservable<string>;
    private _access: KnockoutObservableArray<RemoteDatabaseAccess>;

    constructor(public Id: number, public Name: string, public NetworkKey: string) {
        super();

        this._accessDataLoaded = false;

        this._expanded = ko.observable(false);
        this._error = ko.observable(null);
        this._access = ko.observableArray([]);
    }

    AssignEventBus(bus: EventBus) {
        super.AssignEventBus(bus);

        this.HandleEvent<any, RemoteDatabaseAccess>(NetworkDesignerEvents.RemovingAccessRequested)
            .When(eventArgs => this.ContainsAccess(eventArgs.Source))
            .Using(eventArgs => this.RemoveAccess(eventArgs.Source))
            .Always();

        this.HandleEvent<RemoteDatabaseAccessChanges, RemoteDatabaseAccess>(NetworkDesignerEvents.SavingAccessChangesRequested)
            .When(eventArgs => this.ContainsAccess(eventArgs.Source))
            .Using(eventArgs => this.SaveAccessChanges(eventArgs.Source, eventArgs.Data))
            .Always();

        this.HandleEvent<any, RemoteDatabaseAccess>(NetworkDesignerEvents.CreatingAccessCancelled)
            .When(eventArgs => this.ContainsAccess(eventArgs.Source))
            .Using(eventArgs => this.RemoveNewAccess(eventArgs.Source))
            .Using(() => this.UpdateErrors())
            .Always();

        this.HandleEvent<any, RemoteDatabaseAccess>(NetworkDesignerEvents.EditingAccessCancelled)
            .When(eventArgs => this.ContainsAccess(eventArgs.Source))
            .Using(() => this.UpdateErrors())
            .Always();

        this.HandleEvent<NewRemoteDatabaseAccess, RemoteDatabaseAccess>(NetworkDesignerEvents.SavingAccessRequested)
            .When(eventArgs => this.ContainsAccess(eventArgs.Source))
            .Using(eventArgs => this.SaveAccess(eventArgs.Source, eventArgs.Data))
            .Always();
    }

    get DisplayName() {
        return `${this.Name} (${this.NetworkKey})`;
    }

    get DataLoaded() {
        return this._accessDataLoaded;
    }

    Dispose() {
        this._access().forEach(a => a.Dispose());
        super.Dispose();
    }

    HeaderClicked() {
        const expanded = this._expanded();
        this._expanded(!expanded);

        this.DispatchEvent(NetworkDesignerEvents.HeaderClicked);
    }

    AddButtonClicked() {
        const access = RemoteDatabaseAccess.Create(this.EventBus);
        this._access.push(access);
    }

    GetAccess(id: number) {
        return _.find(this._access(), a => a.Id === id);
    }

    GetAccessByUniqueId(uniqueId: string) {
        return _.find(this._access(), a => a.UniqueId === uniqueId);
    }

    ContainsAccess(access: RemoteDatabaseAccess) {
        return this._access().indexOf(access) > -1;
    }

    FillAccess(access: RemoteDatabaseAccess[]) {
        access.forEach(a => a.AssignEventBus(this.EventBus));

        this._access(access);
        this._accessDataLoaded = true;
    }

    RemoveAccess(access: RemoteDatabaseAccess) {
        access.Block();
        this.DispatchEvent(NetworkDesignerEvents.AccessRemoving, access);
    }

    RemoveNewAccess(access: RemoteDatabaseAccess) {
        this._access.remove(access);
    }

    SaveAccess(access: RemoteDatabaseAccess, newAccessData: NewRemoteDatabaseAccess) {
        const accessData = new RemoteDatabaseAccessData(null, newAccessData.UserId, newAccessData.RemoteUserName);
        if (this.HasDuplicate(accessData)) {
            access.UnBlock();
            this.SetAccessError(access, this._labels.ACCESS_RECORD_EXISTS);
            return;
        }

        access.Block();
        this.DispatchEvent(NetworkDesignerEvents.AccessSaving, newAccessData)
    }

    SaveAccessChanges(access: RemoteDatabaseAccess, changes: RemoteDatabaseAccessChanges) {
        const accessData = new RemoteDatabaseAccessData(changes.Id, changes.UserId, changes.RemoteUserName);

        if (this.HasDuplicate(accessData)) {
            access.UnBlock();
            this.SetAccessError(access, this._labels.ACCESS_RECORD_EXISTS);
            return;
        }

        access.Block();
        this.DispatchEvent(NetworkDesignerEvents.AccessСhangesSaving, changes);
    }

    CommitCreateAccess(access: RemoteDatabaseAccess, accessId: number) {
        access.ClearError();
        this.ClearError();

        access.Id = accessId;

        access.CommitChanges();
        access.UnBlock();
    }

    CommitChanges(access: RemoteDatabaseAccess) {
        access.ClearError();
        this.ClearError();

        access.CommitChanges();
        access.UnBlock();
    }

    CancelRemoveAccess(access: RemoteDatabaseAccess) {
        access.UnBlock();
    }

    CommitRemoveAccess(access: RemoteDatabaseAccess) {
        access.UnBlock();
        this._access.remove(access);
    }

    SetError(error: string) {
        this._error(error);
        new Notifier().Failed(error);
    }

    SetAccessError(access: RemoteDatabaseAccess, error: string) {
        this.SetError(error);
        access.SetError(error);
    }

    UpdateErrors() {
        const hasInvalidAccess = _.any(this._access(), a => a.HasError);

        if (!hasInvalidAccess) {
            this.ClearError();
        }
    }

    ClearError() {
        this._error(null);
    }

    private HasDuplicate(data: RemoteDatabaseAccessData) {
        const access = this._access().filter(a => a.Id && a.Id !== data.Id);
        return _.any(access, a => a.ContainsData(data));
    }
}