import {deserialize, deserializeAs, serialize} from 'libs/cerialize';

export class CanvasPositionModel {
	constructor() {
		this.Top = 0;
		this.Left = 0;
		this.ZIndex = 0;
	}

	@serialize
	@deserialize
	Top: number;

	@serialize
	@deserialize
	Left: number;

	@serialize
	@deserialize
	ZIndex: number;
}