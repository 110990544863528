

import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {GenericDeserialize} from 'libs/cerialize';
import {ScreenDataModel} from "Core/ScreenManager/Models/ScreenDataModel";
import { FollowUpRecordRequestModel } from "Core/Screens/ConsultScreen/Models/FollowUpRecordRequestModel";
import { FollowUpRecordResponseModel } from "Core/Screens/ConsultScreen/Models/FollowUpRecordResponseModel";
import { UpdateRecordLifestatusRequestModel } from "Core/Screens/ConsultScreen/Models/UpdateRecordLifestatusRequestModel";
import { UpdateRecordLifestatusResponseModel } from "Core/Screens/ConsultScreen/Models/UpdateRecordLifestatusResponseModel";

export interface IGetConsultScreenDataRequestModel {
	EntityId: number;
	ScreenId: number;
	RecordId: number;
};

export class ScreenDataStore {
	static GetData(params: IGetConsultScreenDataRequestModel): P.Promise<ScreenDataModel> {
		var deferredResult = P.defer<ScreenDataModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: 'api/ScreenFormApi/GetScreenData',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(this.Deserialize(data.ResultObject));
			});

		return deferredResult.promise();
    }

    static FollowUp(params: FollowUpRecordRequestModel): P.Promise<FollowUpRecordResponseModel> {
        var deferredResult = P.defer<FollowUpRecordResponseModel>();
        
        var requestParams: IRequestOptions = {
            proxy: {
                url: 'api/FollowUpApi/FollowUpRecord',
                dataType: 'json',
                type: 'POST'
            }, params: params
        };


        Request.Send(requestParams)
            .then(result => deferredResult.resolve(result))
            .fail(error => deferredResult.reject(this.GetResponseError(error, 'Error followUp record')));

        return deferredResult.promise();
    }

    static UpdateRecordLifestatus(params: UpdateRecordLifestatusRequestModel): P.Promise<UpdateRecordLifestatusResponseModel> {
        var deferredResult = P.defer<FollowUpRecordResponseModel>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: 'api/RecordsApi/UpdateRecordLifestatus',
                dataType: 'json',
                type: 'POST'
            }, params: params
        };


        Request.Send(requestParams)
            .then(result => deferredResult.resolve(result))
            .fail(error => deferredResult.reject(this.GetResponseError(error, 'Error followUp record')));

        return deferredResult.promise();
    }

	static Deserialize(jsonObject: any): ScreenDataModel {
		var model = GenericDeserialize<ScreenDataModel>(jsonObject, ScreenDataModel);
		return model;
    }

    static GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return { message: JSON.parse(err.message).Message };
        }
        if (err.status === 500) {
            return { message: JSON.parse(err.message).ExceptionMessage };
        }

        return { message: generalError };
    }
}
