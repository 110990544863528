import * as ko from "knockout";
import * as _ from "underscore";

import {
	BaseProperty,
	IPropertyDescription,
	IPropertyOption,
	IPropertySortOption
} from "Core/GeneralProperties/Managers/BaseProperty";
import {IControl} from "Core/Controls/IControl";
import { SortOrder } from 'QueryBuilder/Enums';

import QuerySortPropertyTemplate from "Core/GeneralProperties/Managers/QuerySortProperty/Templates/QuerySortProperty.html";
import {AttachedFieldModel} from "Core/Controls/BaseControl/Models/AttachedFieldModel";
import { LABELS } from 'Core/Components/Translation/Locales';
import { FIELD_TYPES } from "Core/Constant";

ko.templates["Core/GeneralProperties/Managers/QuerySortProperty/Templates/QuerySortProperty"] = QuerySortPropertyTemplate;

export class QuerySortProperty extends BaseProperty {
	private _control: IControl;
	private _fields: KnockoutObservableArray<AttachedFieldModel>;
	private _selectedField: KnockoutObservable<AttachedFieldModel>;
	private _orderByDescending: KnockoutObservable<boolean>;
	private _labels = LABELS;

	constructor(property: IPropertyDescription, propertyValue: IPropertySortOption, control: IControl) {
		super(property);
		this._control = control;
		this._fields = ko.observableArray([]);
		this._selectedField = ko.observable(null);
		this.Value = ko.observable(null);
		this._orderByDescending = ko.observable(false);

		this.Init(propertyValue);

		this._selectedField.subscribe((newValue)=>{
			if( newValue) {
				this.Value( { FieldId: newValue.Id, SortDirection: this._orderByDescending() ? SortOrder.Descending : SortOrder.Ascending });
			}
		});

		this._orderByDescending.subscribe((newValue) => {
			this.Value().SortOrder = newValue ? SortOrder.Descending : SortOrder.Ascending;
		});
	}

	private Init(propertyValue: IPropertySortOption) {

        if (this._control) {
            const types = [
                FIELD_TYPES.Currency, FIELD_TYPES.Document, FIELD_TYPES.Icon, FIELD_TYPES.Image, FIELD_TYPES.Reference,
                FIELD_TYPES.Memo, FIELD_TYPES.MultiSelect, FIELD_TYPES.Password
            ];

            const attachedFields = this._control.GetModel().Fields;

            const sortingFields = _.filter(attachedFields,
                field => !_.contains(types, field.FieldTypeName) && !field.IsVirtual);

            this._fields(sortingFields);
		}

		this.SetValue(propertyValue);
	}

	GetTemplateName(): string {
		return "Core/GeneralProperties/Managers/QuerySortProperty/Templates/QuerySortProperty";
	}

	FormatFieldName(field: AttachedFieldModel){
		return field.FieldNameTranslation || field.Name;
	}

	GetDefaultValue() {
		let firstField = _.first(this._fields());
		if(firstField){
			return {
				FieldId: firstField.Id,
				SortOrder: SortOrder.Ascending
			}
		}
	}

	SetValue(propertyValue: IPropertySortOption): void {
		if (!propertyValue) {
			this.Value(this.GetDefaultValue());
			return;
		}

		let value: IPropertySortOption = {
			FieldId : 0,
			SortOrder: SortOrder.Ascending
		}

		this.Value({ FieldId: 0, SortOrder: SortOrder.Ascending } );

		let field = _.find(this._fields(), (field)=>{
			return field.Id === propertyValue.FieldId;
		});

		if(field) {
			value.FieldId = field.Id;
			this._selectedField(field);
		}

		let orderByDescending = propertyValue.SortOrder === SortOrder.Descending ? true : false;

		value.SortOrder = propertyValue.SortOrder;
		this.Value(value);

		this._orderByDescending(orderByDescending);
	}
}