export let Events = {
	ResetPassword: 'ResetPassword',
	SignIn: 'SignIn',
	FormClosed: "FormClosed",
	UpdatePassword: "UpdatePassword",
	SecurityCodeProvided: "SecurityCodeProvided",
	SaveTotpSecred: 'SaveTotpSecred',
	UnreportedTimeFormClosed: 'UnreportedTimeFormClosed',
	GoToAgenda: 'GoToAgenda',
	SavePasswordLessCredentials: 'SavePasswordLessCredentials',
	PasswordLessSignIn: 'PasswordLessSignIn',
};