import * as ko from 'knockout';
import * as _ from 'underscore';

import {BaseCheckItem} from "../../CheckItems/BaseCheckItem";
import {IActionCheckListSerializedData} from "../../IActionCheckListSerializedData";

export class ActionCheckListViewModel {
    LifeStatusId: number;
    LifeStatusName: string;
    LifeStatusMemo: string;
    LifeStatusMemoTranslation: string;
    LifeStatusTranslatedName: string;
    LifeStatusApprovalName: string;
    LifeStatusNoActionNode: boolean;
    LifeStatusSort: number;
    CheckItems: KnockoutObservableArray<BaseCheckItem>;
    Valid: KnockoutComputed<boolean>;
    IsFollowUpDisabled: boolean;
    DisabledReason: string;

    constructor() {
        this.CheckItems = ko.observableArray([]);
        this.Valid = ko.computed(() => !_.any(this.CheckItems(), checkItem => !checkItem.Valid()));
    }

    EnableValidation() {
        this.CheckItems().forEach(checkItem => checkItem.EnableValidation());
    }

	SerializeData(): IActionCheckListSerializedData {
        const checkItems = this.CheckItems().map(checkItem => checkItem.SerializeData());
        return {
            LifeStatusId: this.LifeStatusId,
            CheckItems: checkItems
        };
    }
}