import {ChartDataModel} from "Core/Controls/Chart/Models/ChartDataModel";
import {ChartPointModel} from "Core/Controls/Chart/Models/ChartPointModel";

import * as Highcharts from "highcharts";

import {ChartBuilder} from "Core/Components/Controls/Chart/Builders/ChartBuilder"
import {IChartBuilderParams} from "../IChartBuilderParams";
import {ChartTypeEnum} from "../Enums/ChartTypeEnum";

export class StackedAreaChartBuilder extends ChartBuilder {
    private _xCategories: any;

    constructor(params: IChartBuilderParams) {
        super(params);
    }

    RenderChart(options: ChartDataModel) {
        const self = this;

        const xAxisLabel = this._xAxisLabel ? this._xAxisLabel : options.XAxisInfoModel.AxisDefaultName;
        const yAxisLabel = this._yAxisLabel ? this._yAxisLabel : options.YAxisInfoModel.AxisDefaultName;

        this._xCategories = self.GetXAxisCategory(options.Points);
        Highcharts.chart(this._wrapperId, {
            chart: {
                type: ChartTypeEnum.Area
            },
            title: {
                text: this._chartTitle
            },
            tooltip:{
                split: true,
                pointFormatter: function() {
                    return self.GetToolTip(options, this.y, this.series.name);
                }
            },
            xAxis: {
                title: {
                    text: xAxisLabel
                },
                labels: {
                    formatter() {
                        return self.ApplyLabelFormatter(this.value, options.XAxisInfoModel.FieldType, options.XAxisInfoModel.FormatName);
                    }
                },
                categories: this._xCategories
            },
            yAxis: {
                title: {
                    text: yAxisLabel
                },
                labels: {
                    formatter() {
                        return self.ApplyLabelFormatter(this.value, options.YAxisInfoModel.FieldType, options.YAxisInfoModel.FormatName);
                    }
                }
            },
            plotOptions: {
                area: {
                    stacking: 'normal',
                    lineColor: '#666666',
                    lineWidth: 1,
                    marker: {
                        lineWidth: 1,
                        lineColor: '#666666'
                    }
                }
            },
            series: this.GetSeries(options)
        }, () => {
        });
    }

    private GetSeries(options: ChartDataModel) {
        let series = [];
        const points = options.Points;

        if (!points || !_.any(points)) {
            return series;
        }

        _.forEach(_.groupBy(points, point => { return point.Line; }),
            groupedPoints => {
                const line = _.first(groupedPoints).Line ? _.first(groupedPoints).Line : options.YAxisInfoModel.AxisDefaultName;
                let data  = [];

                _.forEach(this._xCategories, xAxisValue => {
                    const yAxisValues = groupedPoints.filter(point => point.XAxis === xAxisValue);
                    let yAxisValue: any = 0;

                    if(_.any(yAxisValues)){
                        yAxisValue = Math.max(...yAxisValues.map(item => this.GetFormattedValue(item.YAxis, options.YAxisInfoModel.FieldType)));
                    }
                    data.push(yAxisValue);
                });

                series.push({ name: line, data:  data });
            });

        return series;
    }

    private GetToolTip(options: ChartDataModel, y: number, series: string) {
        return `<b>${series}</b>: ${this.ApplyLabelFormatter(y, options.YAxisInfoModel.FieldType, options.YAxisInfoModel.FormatName)}`;
    }

    private GetXAxisCategory(points: Array<ChartPointModel>) {
        return [...new Set(points.map(point => point.XAxis))];
    }

    private GetYAxisCategory(points: Array<ChartPointModel>) {
        return [...new Set(points.map(point => point.YAxis))];
    }
}