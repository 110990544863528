import * as ko from "knockout"
import 'jquery';

export class CustomScrollbarExtention {
	static Init() {
		ko.bindingHandlers.customScrollbar = {
			init: (element, valueAccessor) => {
				var options = valueAccessor();
				$(element).customScrollbar(options);
			}
		};
	}
}