export class DimensionalTablePropertyValue {
    constructor(public ValueField: DimensionalTablePropertyField,
                public PageField: DimensionalTablePropertyField,
                public DimensionFields: DimensionalTablePropertyField[]) {
    }
}

export class DimensionalTablePropertyField {
    constructor(public Id: number, public Name: string) {
    }
}