import * as ko from 'knockout'

import {TABLE_TYPES} from 'Core/Constant';

import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';

import {ProductConfigurator} from 'Core/Controls/ProductConfigurator/ProductConfigurator';
import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel';

import {BaseControlEditor} from '../BaseControlEditor';
import {ControlEditorModel} from '../../Models/ControlEditorModel';

import {BaseProperty} from "Core/GeneralProperties/Managers/BaseProperty";
import {BaseSelectProperty} from "Core/GeneralProperties/Managers/BaseSelectProperty/BaseSelectProperty";

import {ProductConfiguratorControlEditorStore} from './Stores/ProductConfiguratorControlEditorStore';
import {ProductsEntity, ProductsView} from './Models/ProductConfiguratorDesignOptions';

import Template
    from 'Core/Screens/DesignScreen/ControlEditor/Templates/ProductConfiguratorControlEditor.html';

ko.templates['Core/Screens/DesignScreen/ControlEditor/Templates/ProductConfiguratorControlEditor'] = Template;

export class ProductConfiguratorControlEditor extends BaseControlEditor {
    private _control: ProductConfigurator;
    private _store: ProductConfiguratorControlEditorStore;

    private _tables: KnockoutObservableArray<ProductsEntity>;

    private _selectedTable: KnockoutObservable<ProductsEntity>;
    private _selectedView: KnockoutObservable<ProductsView>;
    private _useView: KnockoutObservable<boolean>;

    private _selectedProductDescription: KnockoutObservable<any>;
    private _useProductDescription: KnockoutObservable<boolean>;

    constructor(control: ProductConfigurator) {
        super(control);

        this._control = this.EditControl as ProductConfigurator;
        this._store = new ProductConfiguratorControlEditorStore();

        this._tables = ko.observableArray([]);

        this._selectedTable = ko.observable(null);
        this._selectedView = ko.observable(null);
        this._useView = ko.observable(false);

        this._selectedProductDescription = ko.observable(null);
        this._useProductDescription = ko.observable(false);
    }

    GetTemplateName(): string {
        return 'Core/Screens/DesignScreen/ControlEditor/Templates/ProductConfiguratorControlEditor';
    }

    AfterRender(el: HTMLElement) {
        const container = el[0];
        super.AfterRender(container);

        this.LoadData()
    }

    GetControlEditorModel(): ControlEditorModel {
        const controlEditorModel = super.GetControlEditorModel();

        this._control.AutoSelectFieldsFromStruct([this._selectedTable()]);

        if (this._useView()) {
            const attachedFields = this._control.Model.Fields;
            const viewField = this._selectedView().Fields.find(f => f.IsPrimaryKey);
            const viewFieldSort = attachedFields[attachedFields.length - 1].Sort + 10;

            const attachedField = this.CreateAttachedField(this._selectedView(), viewField, viewFieldSort);
            this._control.Model.Fields.push(attachedField);
        }

        if (this._useProductDescription()) {
            const attachedFields = this._control.Model.Fields;
            const descriptionFieldSort = attachedFields[attachedFields.length - 1].Sort + 10;

            const attachedField = this.CreateAttachedField(this._selectedTable().LinkEntity, this._selectedProductDescription(), descriptionFieldSort);
            this._control.Model.Fields.push(attachedField);
        }

        controlEditorModel.AttachedFields.push(...this._control.Model.Fields);

        return controlEditorModel;
    }

    Validate() {
        const validationError = super.Validate();

        if (validationError) {
            return validationError;
        }

        if (!this._selectedTable()) {
            this.IsValid(false);
            return 'Please, select table'
        }

        if (this._useView() && !this._selectedView()) {
            this.IsValid(false);
            return 'Please, select sub table view'
        }

        if (this._useProductDescription() && !this._selectedProductDescription()) {
            this.IsValid(false);
            return 'Please, select description'
        }

        return null;
    }

    CheckForDescriptionFields() {
        return this._selectedTable() && this._selectedTable().LinkEntity
            && _.any(this._selectedTable().LinkEntity.Fields, field => field.IsText);
    }

    GetDescriptionFields() {
        return _.filter(this._selectedTable().LinkEntity.Fields, field => field.IsText);
    }

    private LoadData() {
        BlockUI.Block();

        this._store.GetDesignOptions(this.GetSubjectEntityId())
            .then(options => this.PopulateData(options.Entities))
            .fail(error => new Notifier().Failed(error.message))
            .always(() => BlockUI.Unblock());


        let annotation = this.GeneralProperties.GetPropertyManager('Annotations');
        let showAnnotations = this.GeneralProperties.GetPropertyManager('ShowAnnotations');

        if(annotation && showAnnotations){
            showAnnotations.SetEnabled(annotation.Value());

            if(!annotation.Value()){
                showAnnotations.Value(annotation.Value());
            }

            annotation.Value.subscribe((val)=>{
                if(!val){
                    showAnnotations.Value(val);
                }
                
                showAnnotations.SetEnabled(val);
            });
        }

        let enableAlternativePriceCalculation = this.GeneralProperties.GetPropertyManager('EnableAlternativePriceCalculation');
        let alternativePriceCalculationRule = this.GeneralProperties.GetPropertyManager('AlternativePriceCalculationRule');

        if (enableAlternativePriceCalculation && alternativePriceCalculationRule) {
            alternativePriceCalculationRule.SetEnabled(enableAlternativePriceCalculation.Value());
            alternativePriceCalculationRule.WarningMessage = this.Labels.THE_TRANSLATION_ALTERNATIVE_PRICE_WITH_CODE_WILL_BE_USED_ON_PCF
                .replace('{CODE}', '1467');

            if (!enableAlternativePriceCalculation.Value()) {
                alternativePriceCalculationRule.Value(null);
            }

            enableAlternativePriceCalculation.Value.subscribe((val)=>{
                if (!val) {
                    alternativePriceCalculationRule.Value(null);
                }

                alternativePriceCalculationRule.SetEnabled(val);
            });
        }

        const level1BaseProperty = this.GeneralProperties.GetPropertyManager('Level1');
        const level1 = level1BaseProperty && level1BaseProperty as BaseSelectProperty;

        const level2BaseProperty = this.GeneralProperties.GetPropertyManager('Level2');
        const level2 = level2BaseProperty && level2BaseProperty as BaseSelectProperty;

        const lockLevel1 = this.GeneralProperties.GetPropertyManager('LockLevel1');

        const self = this;

        if (level1 && level2 && lockLevel1) {
            level1.Value.subscribe(()=>{
                self.SetLockLevel1(lockLevel1, level1, level2);
            });

            level2.Value.subscribe(()=>{
                self.SetLockLevel1(lockLevel1, level1, level2);
            });

            level1.IsInitiated.subscribe(()=>{
                self.SetLockLevel1(lockLevel1, level1, level2);
            });

            level2.IsInitiated.subscribe(()=>{
                self.SetLockLevel1(lockLevel1, level1, level2);
            });
        }
    }

    SetLockLevel1(lockLevel1: BaseProperty, level1: BaseSelectProperty, level2: BaseSelectProperty) {
        const isLevel1Initiated = level1.IsInitiated();
        const isLevel2Initiated = level2.IsInitiated();

        if (!isLevel1Initiated || !isLevel2Initiated) {
            lockLevel1.SetEnabled(false);
            return;
        }

        const isLevel1Set = !!(level1 && level1.Value() && level1.Value().Value);
        const isLevel2Set = !!(level2 && level2.Value() && level2.Value().Value);

        if (!isLevel1Set || !isLevel2Set) {
            lockLevel1.Value(false);
        }

        lockLevel1.SetEnabled(isLevel1Set && isLevel2Set);
    }

    private PopulateData(entities: ProductsEntity[]) {
        this._tables(entities);

        const attachedFields = this.EditControlModel.Fields;

        if (attachedFields.length > 0) {
            const entityId = attachedFields[0].EntityId;

            for (let entity of entities) {
                if (entity.Id === entityId) {
                    this._selectedTable(entity);
                    break;
                }
            }

            if (!this._selectedTable()) {
                return;
            }

            const lastField = attachedFields[attachedFields.length - 1];
            if (lastField.EntityTypeName === TABLE_TYPES.Link) {
                this.PopulateProductDescription(lastField);

                const viewField = attachedFields[attachedFields.length - 2];
                if (viewField.EntityTypeName === TABLE_TYPES.Sub) {
                    this.PopulateProductView(viewField);
                }
            }
            else if (lastField.EntityTypeName === TABLE_TYPES.Sub) {
                this.PopulateProductView(lastField);
            }
        }
    }

    private PopulateProductView(viewField: AttachedFieldModel){
        for (let view of this._selectedTable().Views) {
            if (view.Id === viewField.EntityId) {
                this._selectedView(view);
                this._useView(true);
                return;
            }
        }
    }

    private PopulateProductDescription(descriptionField: AttachedFieldModel){
        if(!this.CheckForDescriptionFields) {
            return;
        }

        for (let description of this.GetDescriptionFields()) {
            if (description.Id === descriptionField.Id) {
                this._selectedProductDescription(description);
                this._useProductDescription(true);
                return;
            }
        }
    }

    private CreateAttachedField(table: any, field: any, sort: number) {
        const attachedField = new AttachedFieldModel({ Id: field.Id, Name: field.Name, EntityId: table.Id });

        attachedField.EntityId = table.Id;
        attachedField.EntityTypeName = table.Type;
        attachedField.EntityName = table.Name;
        attachedField.Sort = sort;

        return attachedField;
    }
}