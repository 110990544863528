import * as _ from 'underscore';

import { GridRow } from 'Core/Controls/Grid/BaseGrid/GridRow/GridRow';
import { PathRunner } from "Core/Components/PathRunner/PathRunner";

import { LABELS, NOTIFICATIONS } from "Core/Components/Translation/Locales";

import enumerable from '../../Common/Decorators/EnumerableDecorator';
import { GlobalManager, GLOBALS } from '../../GlobalManager/GlobalManager';
import { MenuItemModel } from './Models/MenuItemModel';
import { PUB_SUB_EVENTS } from 'MenuManager/PubSubEvents';

import ViewTemplate from 'Core/Components/ScreenSwitcher/Templates/View.html';


export class ScreenSwitcher {
	private _previousScreen: GridRow;
	private _nextScreen: GridRow;
	private _gridRowData: any;
	private _currentRow: GridRow;
	private _screen: any;
	private _labels = LABELS;
	private _notifications = NOTIFICATIONS;

	private _enabled: boolean;
	private _menuItems: Array<MenuItemModel>;

	constructor(currentGridRow: GridRow, gridRowData: any, screen: any) {
		this._gridRowData = gridRowData;
		this._screen = screen;
		this._menuItems = [];

		this._currentRow = currentGridRow.Model
			? _.find(gridRowData, currentGridRow.Model)
			: _.find(gridRowData, currentGridRow);

		const indexOfCurrentRow = this._gridRowData.indexOf(this._currentRow);
		if (indexOfCurrentRow === 0) {
			this._previousScreen = null;
			this._nextScreen = this._gridRowData[indexOfCurrentRow + 1];
		} else if (indexOfCurrentRow === this._gridRowData.length - 1) {
			this._previousScreen = this._gridRowData[indexOfCurrentRow - 1];
			this._nextScreen = null;
		} else {
			this._previousScreen = this._gridRowData[indexOfCurrentRow - 1];
			this._nextScreen = this._gridRowData[indexOfCurrentRow + 1];
		}

		const searchScreenIfRecordsCount = parseInt(GlobalManager.Instance.GetGlobal(GLOBALS.SEARCH_SCREEN_IF_RECORDS_COUNT));
		if (this._gridRowData.length > searchScreenIfRecordsCount) {
			this._enabled = false;
		}
		else {
			this._enabled = true;
			this._menuItems = this._gridRowData.map(row => this.GetMenuItem(row));
		}
	}

    private GetMenuItem(row: any): MenuItemModel {
		let nameField = row.Data
			? row.Data.find(s => s.FieldMetadata.Name === "NAME")
			: row.Model.Data.find(s => s.FieldMetadata.Name === "NAME");
        const displayValue = nameField ? nameField.TranslatedValue ? nameField.TranslatedValue : nameField.DisplayValue : null;

		return new MenuItemModel(row, displayValue, row == this._currentRow)
    }

    GetTemplate() {
		return ViewTemplate;
	}

	GetEnabled():boolean {
		return this._enabled;
	}

	@enumerable get GridRowData() {
		return this._gridRowData;
	}

	@enumerable get NextScreen() {
		return this._nextScreen;
	}
	@enumerable get CurrentRow() {
		return this._currentRow;
	}

	GetPreviousScreen() {
		this._screen._modal.Close();
		const isSpecialScreenExist = this._previousScreen.Form && this._previousScreen.Form.GetScreen() && this._previousScreen.Form.GetScreen().IsSpecialScreenExist;
		PathRunner.Instance.GetUnit(this._previousScreen.EntityId, isSpecialScreenExist).LoadNewData(this._previousScreen.RecordId);
		PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, {
			EntityId: this._previousScreen.EntityId,
			RecordId: this._previousScreen.RecordId,
			RecordTypeId: this._previousScreen.RecordTypeId, 
			IsOpenInModal: true,
			Owner: null, 
			CurrentRow: this._previousScreen,
			RowList: this._gridRowData,
			IsOpenAgenda: false,
			IsHyperlink: true
		});
	};

	GetNextScreen() {
		this._screen._modal.Close();
		const isSpecialScreenExist = this._nextScreen.Form && this._nextScreen.Form.GetScreen() && this._nextScreen.Form.GetScreen().IsSpecialScreenExist;
		PathRunner.Instance.GetUnit(this._nextScreen.EntityId, isSpecialScreenExist).LoadNewData(this._nextScreen.RecordId);
		PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, {
			EntityId: this._nextScreen.EntityId,
			RecordId: this._nextScreen.RecordId,
			RecordTypeId: this._nextScreen.RecordTypeId,
			IsOpenInModal: true,
			Owner: null,
			CurrentRow: this._nextScreen,
			RowList: this._gridRowData,
			IsOpenAgenda: false,
			IsHyperlink: true
		});
	};

	Click(item: MenuItemModel) {
		this._screen._modal.Close();
		const isSpecialScreenExist = item.row.Form && item.row.Form.GetScreen() && item.row.Form.GetScreen().IsSpecialScreenExist;
		PathRunner.Instance.GetUnit(item.row.EntityId, isSpecialScreenExist).LoadNewData(item.row.RecordId);
		PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, {
			EntityId: item.row.EntityId,
			RecordId: item.row.RecordId,
			RecordTypeId: item.row.RecordTypeId,
			IsOpenInModal: true,
			Owner: null,
			CurrentRow: item.row,
			RowList: this._gridRowData,
			IsOpenAgenda: false,
			IsHyperlink: true
		});
	};
}