import * as ko from 'knockout';

import {MenuManager} from 'MenuManager/MenuManager';

import {IScreen} from 'Core/Screens/IScreen';
import {CONTROL_TYPES} from 'Core/Constant';
import {GlobalManager, GLOBALS} from 'Core/GlobalManager/GlobalManager';
import {LABELS} from 'Core/Components/Translation/Locales';

import BreadcrumbsTemplate from 'MenuManager/Breadcrumbs/Templates/Breadcrumbs.html';
import {FieldDataModel} from "../../Core/Screens/Models/RecordDataModel";
import * as _ from "underscore";
import {ITranslationValue} from "Core/Components/TranslationFieldEditor/ITranslationValue";

ko.templates['MenuManager/Breadcrumbs/Templates/Breadcrumbs'] = BreadcrumbsTemplate;

export const BREADCRUMBS_HYPERLINK_TOPIC = 'BREADCRUMBS_HYPERLINK';
export const BREADCRUMBS_NEW_VALUE_TOPIC = 'BREADCRUMBS_NEW_VALUE';
export const BREADCRUMBS_FIELD_RECORD = 'BREADCRUMBS_FIELD_RECORD';
export const BREADCRUMBS_UPDATE_RECORD_NAME = 'BREADCRUMBS_UPDATE_RECORD_NAME';

interface NewValueData {
    Screen?: IScreen;
    LookForPreviousScreen?: boolean;
    CleanRecords?: boolean;
    BreadcrumbIndex?: number;
    DailyStuff?: boolean;
}

interface Record {
    Screen?: IScreen;
    Name: KnockoutObservable<string>;
}

function getScreenName({ records, data }: { records: Record[]; data: NewValueData }) {
    if (data.BreadcrumbIndex !== undefined) {
        const record = records[data.BreadcrumbIndex];

        if (record) {
            return record.Name();
        }
    }

    const nameField = data.Screen.GetControlByFieldNameOnScreenEntity('NAME', CONTROL_TYPES.Text);
    let nameValue: string;
    try {
        nameValue = nameField && nameField.GetValue();
    } catch (error) {}

    return nameValue || data.Screen.GetEntityNameTranslation();
}

function getRecordsBaseArray({ records, data }: { records: Record[]; data: NewValueData }) {
    if (data.CleanRecords) {
        return [];
    }

    if (data.LookForPreviousScreen) {
        const previousRecord = records[records.length - 2];
        const isPreviousRecordCorrect =
            previousRecord &&
            previousRecord.Screen &&
            previousRecord.Screen.GetId() === data.Screen.GetId() &&
            previousRecord.Screen.GetRecordId() === data.Screen.GetRecordId();

        return isPreviousRecordCorrect ? records.slice(0, records.length - 2) : [];
    }

    if (data.BreadcrumbIndex !== undefined) {
        return records.slice(0, data.BreadcrumbIndex);
    }

    return records;
}

export class Breadcrumbs {
    private _recordsMap = ko.observable<{ [historyState: string]: Record[] }>({});
    private _currentHistoryState = ko.observable<string>();

    IsAuthenticated = ko.observable(true);

    private _el: HTMLElement;
    private _isEnabledInSettings = GlobalManager.Instance.GetGlobal(GLOBALS.SHOW_BREADCRUMBS) === '1';

    _isEnabled = ko.pureComputed(() => this.IsAuthenticated() && this._isEnabledInSettings);

    _records = ko.pureComputed(() => {
        const recordsMap = this._recordsMap();
        const currentHistoryState = this._currentHistoryState();

        return recordsMap[currentHistoryState] || [];
    });

    private _isScrolledToRight = true;
    private _field = ko.observable<FieldDataModel>(null);

    Render(target: string) {
        const container = document.getElementById(target);
        if (container) {
            ko.cleanNode(container);
            ko.applyBindings(this, container);
        }
    }

    OnItemClick(index: number, record: Record) {
        MenuManager.Instance.OpenBreadcrumb({ record, index });
    }

    OnScroll(context, event: Event) {
        if (event.currentTarget instanceof HTMLElement) {
            this._isScrolledToRight =
                event.currentTarget.scrollWidth <= event.currentTarget.scrollLeft + event.currentTarget.offsetWidth;
        }
    }

    GetTemplateName() {
        return 'MenuManager/Breadcrumbs/Templates/Breadcrumbs';
    }

    AfterRender(el: HTMLElement[]) {
        if (this._isEnabled()) {
            this._el = el[2];

            PubSub.subscribe(BREADCRUMBS_HYPERLINK_TOPIC, (message, data: { Screen: IScreen; Name: string }) => {
                const records = this._records();
                const selectedRecord = records[records.length - 1];

                if (
                    selectedRecord &&
                    selectedRecord.Screen &&
                    selectedRecord.Screen.GetId() === data.Screen.GetId() &&
                    selectedRecord.Screen.GetRecordId() === data.Screen.GetRecordId()
                ) {
                    selectedRecord.Name(data.Name);

                    this.CheckScroll();
                }
            });

            PubSub.subscribe(BREADCRUMBS_NEW_VALUE_TOPIC, (message, data: NewValueData) => {
                const recordsMap = this._recordsMap();

                const historyState = (History as any).getState().id;

                if (!recordsMap.hasOwnProperty(historyState)) {
                    const records = this._records();

                    const recordsBaseArray = getRecordsBaseArray({ records, data });

                    const newRecord = data.Screen
                        ? {
                              Screen: data.Screen,
                              Name: ko.observable(getScreenName({ records, data }))
                          }
                        : data.DailyStuff
                            ? { Name: ko.observable(LABELS.MY_DAILY_STUFF) }
                            : undefined;

                    recordsMap[historyState] = newRecord ? [...recordsBaseArray, newRecord] : recordsBaseArray;
                    this._recordsMap(recordsMap);
                }

                this._currentHistoryState(historyState);

                this.CheckScroll();
            });

            PubSub.subscribe(BREADCRUMBS_UPDATE_RECORD_NAME, (message, data: { Screen: IScreen; Name: string }) => {
                const records = this._records();
                const selectedRecord = records[records.length - 1];

                if (
                    selectedRecord && data &&
                    selectedRecord.Screen && data.Screen &&
                    selectedRecord.Screen.GetEntityId() === data.Screen.GetEntityId() &&
                    selectedRecord.Screen.GetRecordId() === data.Screen.GetRecordId()
                ) {
                    selectedRecord.Name(data.Name);
                }                
            });
        } else {
            this.Field = null;
            PubSub.subscribe(BREADCRUMBS_FIELD_RECORD, (message, data) => {
                const field : FieldDataModel = data.Field;
                this.Field = field ? data.Field : null;
            });
        }
    }

    CheckScroll() {
        if (this._isScrolledToRight) {
            this._el.scrollLeft = Number.MAX_SAFE_INTEGER; // Scroll to the right
        }
    }

    get FieldValue(): string {
        const itemTranslation: ITranslationValue = _.find(this._field().FieldValueTranslations, (item: any) => item.Selected);
        return itemTranslation?.Value ? itemTranslation.Value : this._field().FieldValue;
    }

    set Field(field: FieldDataModel){
        this._field(field);
    }
}