import {Request, IRequestOptions} from 'Core/Common/Request'
import {P} from 'Core/Common/Promise'
import {SearchTypes, SearchByMainRelationTypes} from 'Core/Screens/SearchScreen/Enums/Enums'
import {SearchGroups} from "Core/Enums/SearchGroups";
import { OrderFieldModel } from "Core/Screens/SearchScreen/Models/OrderFieldModel";
import { SearchByRelationModel } from 'Core/Screens/SearchScreen/Models/SearchByRelationModel';
import {SearchResultModel} from 'Core/Screens/SearchScreen/Models/SearchResultModel';
import {GenericDeserialize} from 'libs/cerialize';
import { IDynamicFieldData } from 'Core/Common/Stores/RecordStore';
import { IConditionValueDto } from 'Core/Common/Interfaces/IConditionValueDto';

export interface ISearchRequestModel {
	SearchType: SearchTypes;
	SearchField: string;
	EntityId: number;
	SearchGroup: SearchGroups;
	SearchPhrase: string;
	RecentRecords: boolean;
	RecordType: string;
	RecordsPerPage: number;
	PageNumber: number;
	ParentEntityId?: number;
	ParentTypeId?: number;
	ParentRecordId?: number;
	IsParentLinking?: boolean;
	OrderFields?: Array<OrderFieldModel>;
	ControlId?: number;
	FieldId?:number;
	LeftEntityId?:number;
	RightEntityId?:number;
	LeftRecordId?:number;
	RightRecordId?:number;
	AlternativeEntities?: Array<number>;
	EnableFilter?: boolean;
	ScreenData?: Array<IDynamicFieldData>;
    SearchByMainRelationType: SearchByMainRelationTypes;
    SearchByTypes?: Array<number>;
    SearchByRelations?: Array<SearchByRelationModel>;
    Sharing?:boolean;
    SearchFieldId: number;
	SubGridParentRecordId?: number;
	PlannerLevelEntityId?: number;
	PlannerLevelRecordId?: number;
	QuerySubjectId?: number;
	RelatedTableId?: number;
	RelatedTableFieldId?: number;
	RelatedTablePk?: number;
	FilterByAlternativeEntities?: FilterByAlternativeEntitiesModel;
	KSeq?: number;

	ConditionValues: Array<IConditionValueDto>;
}

export interface FilterByAlternativeEntitiesModel{
	SubjectEntity: number;
	AlternativeEntities: Array<number>
}

export interface ICheckRightsRequestModel {
	TableId: number;
	RecordId: number;
	SecurityWord: number;
}

export class SearchStore {
	static Search(params: ISearchRequestModel): P.Promise<SearchResultModel> {
		var deferredResult = P.defer<SearchResultModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/SearchApi/Search',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.fail(err=>{
				deferredResult.reject({ message: err.message });
			})
			.then(data => {
				if (data.IsSuccessfull && data.IsSuccessfull === true) {
					deferredResult.resolve(GenericDeserialize(data, SearchResultModel));
				} else {
					deferredResult.reject({message: data.ErrorMessage, value: data.OperationMessage});
				}
			});

		return deferredResult.promise();
	}

	static IsUserAllowedTo(params: ICheckRightsRequestModel) {
		let deferredResult = P.defer<any>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/RecordSecurityApi/IsUserAllowedTo',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull && data.IsSuccessfull === true) {
					deferredResult.resolve(data.ResultObject);
				} else {
					deferredResult.reject({ message: data.ErrorMessage});
				}
			});

		return deferredResult.promise();
	}
}