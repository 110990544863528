import * as _ from 'underscore';

export class EventHandlerItem {
    constructor(public type: string,
                public data: any,
                public context: any,
                public repeat: boolean,
                public action: (args: EventArgs) => void) {
    }
}

export class EventArgs {
    constructor(
        public source: Event,
        public type: string,
        public data: any) {
    }
}

export class Event {

    private _events: EventHandlerItem[] = [];
    private _allowedEvents: string[] = [];
    private _name = "";

    protected SetName(name: string): void {
        if (name.lastIndexOf(".") !== name.length - 1)
            name = name + ".";
        this._name = name;
    }

    protected AddEvent(type: string) {
        type = this.PrefixName(type);
        this._allowedEvents.push(type);
    }

    private PrefixName(type: string) {
        if (this.StartsWith(type, this._name))
            return type;
        else
            return this._name + type;
    }

    private StartsWith(longerString: string, shorterString: string): boolean {
        return longerString.length >= shorterString.length && shorterString == longerString.substr(0, shorterString.length);
    }

    On(type: string, context: any, handler: (args: EventArgs) => void, data?: any, repeat?: boolean): Event {
        if (typeof (repeat) === "undefined")
            repeat = true;

        var allowed = true;
        type = this.PrefixName(type);
        if (this._allowedEvents.length > 0) {
            allowed = false;
            this._allowedEvents.forEach((ev) => {
                if (ev === type) allowed = true;
            });
        }

        if (!allowed)
            throw new RangeError("Error:'" + type + "' is not a known event for this object");

        var hdlr = new EventHandlerItem(type, data || {}, context, repeat, handler);
        this._events.push(hdlr);
        return this;
    }

    One(type: string, context: any, handler: (args: EventArgs) => void, data?: any) {
        return this.On(type, context, handler, data, false);
    }

    Off(type: string, context?: any, handler?: (args: EventArgs) => void): Event {
        var newhdlrs: EventHandlerItem[] = [];

        type = this.PrefixName(type);
        if (typeof (handler) !== "undefined")
            this._events.forEach(ehi => {
                if (ehi.type !== type || ehi.context !== context || ehi.action !== handler) newhdlrs.push(ehi);
            });
        else if (typeof (context) !== "undefined")
            this._events.forEach(ehi => {
                if (ehi.type !== type || ehi.context !== context) newhdlrs.push(ehi);
            });
        else
            this._events.forEach(ehi => {
                if (ehi.type !== type) newhdlrs.push(ehi);
            });

        this._events = newhdlrs;
        return this;
    }

    Trigger(type: string, extraData?: any) {
        type = this.PrefixName(type);

        const handlersToExecute = this._events.filter(ehi => ehi.type === type);
        handlersToExecute.forEach(ehi => {
            const data = ehi.data || {};
            this.Extend(data, extraData || {});
            ehi.action.apply(ehi.context, [new EventArgs(this, ehi.type, data)])
        });

        const handlersToRemove = handlersToExecute.filter(ehi => !ehi.repeat);
        this._events = _.without(this._events, ...handlersToRemove);
    }

    Extend(obj1: any, obj2: any): any {
        for (var attrname in obj2) {
            obj1[attrname] = obj2[attrname];
        }
    }
} 