import * as ko from 'knockout';

import {Event} from 'Core/Common/Event';
import {Modal} from 'Core/Common/Modal';
import {Notifier} from 'Core/Common/Notifier';
import {BlockUI} from 'Core/Common/BlockUi';
import {RecordStore} from 'Core/Common/Stores/RecordStore';

import {SearchScreen} from 'Core/Screens/SearchScreen/SearchScreen';

import {RenamePageState} from './Models/State/RenamePageState';

import {PageName} from './Models/View/PageName';
import {SpreadsheetFieldDescriptor} from '../../Models/View/SpreadsheetFieldDescriptor';
import {SpreadsheetPageData} from '../../Models/View/SpreadsheetPageData';

import Template from './Templates/Template.html';
import SearchTemplate from './Templates/SearchTemplate.html';
import DropdownTemplate from './Templates/DropdownTemplate.html';

ko.templates['Core/Controls/Spreadsheet/Components/RenamePagePopup/Templates/Template'] = Template;

export class RenamePagePopup extends Event {
    private _modal: Modal;
    private _currentPageName: PageName;

    State: KnockoutObservable<RenamePageState>;

    constructor(private _pageField: SpreadsheetFieldDescriptor, currentName: string, currentDisplayName: string) {
        super();
        this._currentPageName = new PageName(currentName, currentDisplayName);

        this.State = ko.observable(null);
    }

    Show() {
        this.InitState()
            .then(() => {
                this._modal = new Modal({width: 400}, false);

                ko.cleanNode(this._modal.Wrapper);
                ko.applyBindings(this, this._modal.Wrapper);

                this._modal.Show();
            });
    }

    Close() {
        this._modal.Close();
        this._modal.Destroy();
    }

    GetTemplateName() {
        return 'Core/Controls/Spreadsheet/Components/RenamePagePopup/Templates/Template';
    }

    GetSearchTemplate() {
        if (!this.State()) {
            return null;
        }

        return this.State().UseSearch ? SearchTemplate : DropdownTemplate;
    }

    AfterRender() {

    }

    SetError(error: string) {
        this.State().Error(error);
    }

    private InitState() {
        BlockUI.Block();

        return RecordStore.GetLookupData({
            TableName: this._pageField.ValTableName,
            DisplayValue: this._pageField.ValFieldName
        }).then(lookupData => {
            if (lookupData.Count > lookupData.Data.length) {
                this.State(new RenamePageState(this._currentPageName, [], true));
            } else {
                const nameOptions = lookupData.Data.map(record => new PageName(record.Value, record.Name));
                this.State(new RenamePageState(this._currentPageName, nameOptions, false));
            }

            BlockUI.Unblock();
        }).fail(error => {
            BlockUI.Unblock();
            new Notifier().Failed(error.message)
        })
    }

    private OnSave() {
        if (!this.State().SelectedName() || !this.State().SelectedName().PageDisplayName) {
            this.SetError('Page name cannot be empty');
            return;
        }

        this.Trigger('PAGE_NAME_SAVE', this.State().SelectedName());
    }

    private OnCancel() {
        this.Close();
    }

    private SearchForPageName() {
        const search = new SearchScreen({EntityName: this._pageField.ValTableName, SearchTerm: ''});

        search.On('RECORD_SELECTED', this, eventArgs => this.SelectPageName(eventArgs.data.RecordId, eventArgs.data.Name));

        search.Show();
    }

    private SelectPageName(pageName: string, pageDisplayName) {
        const selectedPageName = new PageName(pageName.toString(), pageDisplayName);
        this.State().SelectedName(selectedPageName)
    }
}