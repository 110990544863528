import {TableModel} from "Core/Controls/TypeSecurity/Models/TableModel";
import {TypeModel} from "./TypeModel";

export class TypeSecurityViewModel {
    TablesView: TableViewModel[]
    constructor(tables: TableModel[]) {
        this.TablesView = tables.map((table: TableModel)=> {
            let tableView = new TableViewModel();
            tableView.Id = table.Id;
            tableView.Name = table.Name;
            tableView.NameTranslation = table.NameTranslation;
            tableView.DisplayName = table.NameTranslation || table.Name;

            tableView.TypesView = table.Types.map((tableType: TypeModel)=> {
                let tableTypeView: TypeViewModel = new TypeViewModel();
                tableTypeView.Id = tableType.Id;
                tableTypeView.Name = tableType.Name;
                tableTypeView.NameTranslation = tableType.NameTranslation;
                tableTypeView.DisplayName = tableType.NameTranslation || tableType.Name;
                tableTypeView.IsSelected(tableType.IsSelected);

                return tableTypeView;
            });

            tableView.NotTypes = !table.Types.length;
            tableView.NotSelectedTypes = table.Types.length && !_.some(table.Types, (type: TypeModel)=> type.IsSelected);
            tableView.SelectedAll(!table.Types.length || tableView.NotSelectedTypes);

            return tableView;
        })
    }
}

export class TableViewModel {
    Id: number;
    Name: string;
    NameTranslation: string;
    DisplayName: string;
    TypesView: Array<TypeViewModel>;
    NotSelectedTypes: boolean;
    NotTypes: boolean;
    SelectedAll: KnockoutObservable<boolean>;

    constructor() {
        this.SelectedAll = ko.observable(null);
    }
}

export class TypeViewModel {
    Id: number;
    Name: string;
    NameTranslation: string;
    DisplayName: string;
    IsSelected: KnockoutObservable<boolean>;

    constructor() {
        this.IsSelected = ko.observable(null);
    }
}