import {AreaChartBuilder} from '../Builders/AreaChartBuilder';
import {LineChartBuilder} from '../Builders/LineChartBuilder';
import {IChartBuilderParams} from "../IChartBuilderParams";
import {ChartTypeEnum} from "../Enums/ChartTypeEnum";
import {PieChartBuilder} from "../Builders/PieChartBuilder";
import {StackedAreaChartBuilder} from "../Builders/StackedAreaBuilder";
import {Funnel3DBuilder} from "../Builders/Funnel3DBuilder";

export class ChartBuilderFactory {
    static CreateChartBuilder(params: IChartBuilderParams) {
        switch (params.ChartType) {
            case ChartTypeEnum.Line:
                return new LineChartBuilder(params);

            case ChartTypeEnum.Area:
                return new AreaChartBuilder(params);

            case ChartTypeEnum.StackedArea:
                return new StackedAreaChartBuilder(params);

            case ChartTypeEnum.Pie:
                return new PieChartBuilder(params);

            case ChartTypeEnum.Funnel3D:
                return new Funnel3DBuilder(params);

            default:
                return new LineChartBuilder(params);
        }
    }
}