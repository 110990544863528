import {BackLinkOptionsDto} from "../Models/Dto/BackLinkOptionsDto";
import {OptionsGroupDto} from "../Models/Dto/OptionsGroupDto";
import {BackLinkOptionDto} from "../Models/Dto/BackLinkOptionDto";
import {LinkTableDto} from "../Models/Dto/LinkTableDto";

import {ViewModel} from "../Models/View/ViewModel";
import {OptionsGroup} from "../Models/View/OptionsGroup";
import {Option} from "../Models/View/Option";
import {LinkTable} from "../Models/View/LinkTable";
import {TableDto} from "../Models/Dto/TableDto";
import {Table} from "../Models/View/Table";

export class Mappings {
    static MapToViewModel(dto: BackLinkOptionsDto): ViewModel {
        const viewModel = new ViewModel();

        viewModel.Groups = dto.Groups            .map(group => this.MapToOptionsGroup(group))

        viewModel.LinkTable = this.MapToLinkTable(dto.LinkTable);

        return viewModel;
    }

    static MapToLinkTable(dto: LinkTableDto): LinkTable {
        const linkTable = new LinkTable();

        linkTable.Id = dto.Id;
        linkTable.Name = dto.Name;
        linkTable.LeftTable = this.MapToTable(dto.LeftTable);
        linkTable.RightTable = this.MapToTable(dto.RightTable);

        return linkTable;
    }

    static MapToTable(dto: TableDto): Table {
        const table = new Table();

        table.Id = dto.Id;
        table.Name = dto.Name;

        return table;
    }

    static MapToOptionsGroup(dto: OptionsGroupDto): OptionsGroup {
        const group = new OptionsGroup();

        group.GroupName = dto.GroupName;

        group.Options = dto.Options.map(option => this.MaptToOption(option));
        group.MultipleSelection = dto.MultipleSelection;

        if (dto.MultipleSelection) {
            group.Options.forEach(option =>
                option.IsSelected(dto.SelectedOptions.indexOf(option.Id) > -1));
        } else {
            group.SelectedOption(dto.SelectedOptions[0] || null);
        }

        return group;
    }

    static MaptToOption(dto: BackLinkOptionDto): Option {
        const option = new Option();

        option.Id = dto.Id;
        option.Name = dto.Name;

        return option;
    }
}