import {deserializeAs} from 'libs/cerialize'
import {EntityModel} from "./EntityModel";

export class PlannerLevelEntityModel {

    constructor(){
        this.SubEntities = [];
    }

    @deserializeAs(EntityModel)
    Entity: EntityModel;

    @deserializeAs(EntityModel)
    SubEntities: Array<EntityModel>;

}