import * as ko from "knockout";
import * as _ from "underscore";

import {LABELS} from 'Core/Components/Translation/Locales';

import {BaseProperty, IPropertyDescription, IPropertyOption} from "Core/GeneralProperties/Managers/BaseProperty";

export class BaseSelectProperty extends BaseProperty {
    protected _options: KnockoutObservableArray<IPropertyOption>;
    private _initialValue: KnockoutObservable<IPropertyOption>;
    private _labels = LABELS;

    Value: KnockoutObservable<IPropertyOption>;
    IsInitiated: KnockoutObservable<boolean>;

    constructor(property: IPropertyDescription, propertyValue: IPropertyOption) {
        super(property);

        this._options = ko.observableArray([]);
        this._initialValue = ko.observable(propertyValue);
        this.Value = ko.observable(null);
        this.IsInitiated = ko.observable(false);
    }

    get Options(): KnockoutObservableArray<IPropertyOption> {
        return this._options;
    }

    GetDefaultValue() {
        return _.first(this._options());
    }

    SetValue(propertyValue: IPropertyOption, isSearchMode?: boolean): void {
        if (!propertyValue) {
            this.Value(this.GetDefaultValue());
            return;
        }

        let selectedValue = _.find(this._options(), option => option.Value === propertyValue.Value);

        this.Value(selectedValue || this.GetDefaultValue());
    }

    IsValid(): boolean {
        if (this.ValueRequired) {
            return this.Value() && this.Value().Value;
        }

        return true;
    }

    Init(options: IPropertyOption[], propertyValue: IPropertyOption, isSearchMode?: boolean) {
        this.SetOptions(options);
        this.SetValue(propertyValue, isSearchMode);
        this.IsInitiated(true);
    }

    private SetOptions(options: IPropertyOption[]) {
        if (!this.ValueRequired) {
            const selectLabel = LABELS.SELECT_LABEL + '....';
            const empty: IPropertyOption = {Name: selectLabel, Value: null};

            let firstElem =  _.first(options);
            if (firstElem && firstElem.Name !== selectLabel && !!firstElem.Value){
                options.unshift(empty);
            }
        }

        this._options(options);
    }

    RewriteOptions(options: IPropertyOption[]) {
        this.SetOptions(options);

        if (this._initialValue()) {
            this.SetValue(this._initialValue());
            this._initialValue(null);
        }
    }
}