import * as ko from 'knockout';

import {ImageTableTypes} from "../../../Components/ProductImageViewer/Enums/ImageTableTypes";
import {FormatConverter} from 'FormatEditor/FormatConverter';
import { ImagePreloader } from 'Core/Common/Image';

export class InexProduct {
    private _imageSize: number;
    private _imageSizeClassName: string;
    private _incompatibleIds: number[];
    public Hidden: KnockoutObservable<boolean>;
    public DelayItem: KnockoutObservable<boolean>;
    public Reason: KnockoutObservable<string>;
    public Exchange: KnockoutObservable<boolean>;
    public Image: string;

    constructor(public Id: number,
                public ParentId: number,
                image: string,
                public Name: string,
                public NameTranslated: string,
                public Price: number,
                public Quantity: number) {

        this.Image = ImagePreloader.FormatImageUrl(image);
        this._imageSize = null;
        this._imageSizeClassName = null;
        this._incompatibleIds = [];

        this.Hidden = ko.observable(true);
        this.DelayItem = ko.observable(true);
        this.Exchange = ko.observable(false);
        this.Reason = ko.observable("");
    }

    get PriceLabel(): string {
        const priceValue = this.Price.toFixed(2);
        const culturalPrice = FormatConverter.LocalizeDecimalOrInteger(priceValue);
        return `€ ${culturalPrice}`;
    }

    set ImageSize(value: number) {
        this._imageSize = value;
    }

    get ImageSizeInExClassName(): string {
        return this.GetImageSizeClassName();
    }

    get ImageTableType() {
	    return ImageTableTypes.Product;
    }

    get IncompatibleIds(): number[] {
        return this._incompatibleIds;
    }

    AddIncompatibleId(incompatibleId: number) {
        this._incompatibleIds.push(incompatibleId);
    }

    GetImageSizeClassName() {
        switch (this._imageSize) {
            case 60:
                return this._imageSizeClassName = 'imageSize-60';
            case 70:
                return this._imageSizeClassName = 'imageSize-70';
            case 80:
                return this._imageSizeClassName = 'imageSize-80';
            case 90:
                return this._imageSizeClassName = 'imageSize-90';
            default:
                return this._imageSizeClassName = 'imageSize-100';
        }
    }
}

export class InexItem {

    public Checked: KnockoutObservable<boolean>;
    public Hidden: KnockoutObservable<boolean>;
    public DelayItem: KnockoutObservable<boolean>;
    public Default: KnockoutObservable<boolean>;
    public InexExclusiveItems: KnockoutObservableArray<InexProduct>;

    constructor(public Id: number, public InexInclusiveItem: InexProduct, inexExclusiveItems: InexProduct[], public GroupId: number, public GroupName: string, public Path: number[], public IsMain: boolean, public DependsOn: number, public InexParentIds: number[]) {
        this.Checked = ko.observable(false);
        this.Hidden = ko.observable(false);
        this.DelayItem = ko.observable(false);
        this.Default = ko.observable(false);
        this.InexExclusiveItems = ko.observableArray(inexExclusiveItems);
    }
}