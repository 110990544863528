import * as ko from 'knockout';
import * as _ from 'underscore';
import * as $ from 'jquery';
import * as moment from 'moment';

import { BlockUI } from 'Core/Common/BlockUi';
import { Notifier } from 'Core/Common/Notifier';
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";

import { LicenseHolderStore } from 'Core/ProfilePage/LicenseHolder/Stores/LicenseHolderStore';
import { LicenseHolderModel } from 'Core/ProfilePage/LicenseHolder/Models/LicenseHolderModel';

import LicenseHolderTemplate from 'Core/ProfilePage/LicenseHolder/Templates/LicenseHolder.html';
import {FileModel} from "Core/Controls/Image/Models/ImageDataModel";
import { AttachmentModel } from "Core/Controls/Image/Models/AttachmentModel";
import enumerable from '../../Common/Decorators/EnumerableDecorator';
ko.templates['Core/ProfilePage/LicenseHolder/Templates/LicenseHolder'] = LicenseHolderTemplate;

export class LicenseHolder {
	
	private _el: HTMLElement;
	private _model: LicenseHolderModel;
	private _name: KnockoutObservable<string>;
	private _expirationDate: KnockoutObservable<string>;
	private _activeUsersNumber: KnockoutObservable<number>;
	private _licenseUsersNumber: KnockoutObservable<number>;
	private _activePortalUsersNumber: KnockoutObservable<number>;
	private _licensePortalUsersNumber: KnockoutObservable<number>;
	private _activeGDPRUsersNumber: KnockoutObservable<number>;
	private _licenseGDPRUsersNumber: KnockoutObservable<number>;
	private _activeConnectUsersNumber: KnockoutObservable<number>;
	private _licenseConnectUsersNumber: KnockoutObservable<number>;
	private _activeRemoteUsersNumber: KnockoutObservable<number>;
	private _licenseRemoteUsersNumber: KnockoutObservable<number>;
	private _activeLimited1UsersNumber: KnockoutObservable<number>;
	private _licenseLimited1UsersNumber: KnockoutObservable<number>;
	private _activeLimited2UsersNumber: KnockoutObservable<number>;
	private _licenseLimited2UsersNumber: KnockoutObservable<number>;
	private _activeLimited3UsersNumber: KnockoutObservable<number>;
	private _licenseLimited3UsersNumber: KnockoutObservable<number>;
	private _maxRole: KnockoutObservable<string>;
	private _logo: KnockoutObservable<string>;
	private _defaultLogo: string;
	private _isCloseVisible: KnockoutObservable<boolean>;
	private _maxLicenseImageSize: number;
	private _labels = LABELS;
	//private _model: KnockoutObservable<LicenseHolderModel>;

	constructor() {
		this._el = null;
		this._model = null;
		this._name = ko.observable('');
		this._logo = ko.observable('');
		this._expirationDate = ko.observable(null);
		this._activeUsersNumber = ko.observable(0);
		this._licenseUsersNumber = ko.observable(0);
		this._activePortalUsersNumber = ko.observable(0);
		this._licensePortalUsersNumber = ko.observable(0);
		this._activeGDPRUsersNumber = ko.observable(0);
		this._licenseGDPRUsersNumber = ko.observable(0);
		this._activeConnectUsersNumber = ko.observable(0);
		this._licenseConnectUsersNumber = ko.observable(0);
		this._activeRemoteUsersNumber = ko.observable(0);
		this._licenseRemoteUsersNumber = ko.observable(0);
		this._activeLimited1UsersNumber = ko.observable(0);
		this._licenseLimited1UsersNumber = ko.observable(0);
		this._activeLimited2UsersNumber = ko.observable(0);
		this._licenseLimited2UsersNumber = ko.observable(0);
		this._activeLimited3UsersNumber = ko.observable(0);
		this._licenseLimited3UsersNumber = ko.observable(0);
		this._maxRole = ko.observable('');
		this._isCloseVisible = ko.observable(false);
		this._defaultLogo = '../../../../../img/Custom/logo_upload.png';
		this._maxLicenseImageSize = 1;
		this.Init();
	}

	Init() {
		BlockUI.Block();
		LicenseHolderStore.GetLicenseHolderModel()
			.always(() => {
				BlockUI.Unblock();
			})
			.then(model => {
				this._model = model;
				this.MapData();
			}).fail(error => new Notifier().Failed(error));
	}

	GetTemplateName() {
		return 'Core/ProfilePage/LicenseHolder/Templates/LicenseHolder';
	}

	MapData() {
		this._name(this._model.Name);
		this._expirationDate(moment(this._model.ExpirationDate).format('DD MMMM YYYY'));
		this._activeUsersNumber(this._model.ActiveUsersNumber);
		this._licenseUsersNumber(this._model.LicenseUsersNumber);
		this._activeConnectUsersNumber(this._model.ActiveConnectUsersNumber);
		this._licenseConnectUsersNumber(this._model.LicenseConnectUsersNumber);
		this._activeRemoteUsersNumber(this._model.ActiveRemoteUsersNumber);
		this._licenseRemoteUsersNumber(this._model.LicenseRemoteUsersNumber);
		this._activeLimited1UsersNumber(this._model.ActiveLimited1UsersNumber);
		this._licenseLimited1UsersNumber(this._model.LicenseLimited1UsersNumber);
		this._activeLimited2UsersNumber(this._model.ActiveLimited2UsersNumber);
		this._licenseLimited2UsersNumber(this._model.LicenseLimited2UsersNumber);
		this._activeLimited3UsersNumber(this._model.ActiveLimited3UsersNumber);
		this._licenseLimited3UsersNumber(this._model.LicenseLimited3UsersNumber);
		this._activePortalUsersNumber(this._model.ActivePortalUsersNumber);
		this._licensePortalUsersNumber(this._model.LicensePortalUsersNumber);
		this._activeGDPRUsersNumber(this._model.ActiveGDPRUsersNumber);
		this._licenseGDPRUsersNumber(this._model.LicenseGDPRUsersNumber);
		this._maxRole(this._model.UserMaxRole);
		this._logo(this._model.Image || this._defaultLogo);

		this._isCloseVisible(this._model.Image ? true : false);
	}

	AfterUpload(file, data) {
		var attachmentModel = new AttachmentModel();
		attachmentModel.FileName = file.name;
		var reader = new FileReader();
	
		reader.onloadend = () => {
			if (file.status === 'error') {
				var message = NOTIFICATIONS.FILE_SIZE_IS_TOO_BIG.replace('{MaxFileSize}', this._maxLicenseImageSize.toString());
				new Notifier().Failed(message);
				return;
			}

			if (file.status === 'removed') {
				BlockUI.Unblock();
				return;
			}

			var base64ImageData = (reader.result as string).split(',')[1];
			attachmentModel.Base64Data = base64ImageData;
			
			LicenseHolderStore.UploadLicenseLogo(attachmentModel)
				.always(() => {
					BlockUI.Unblock();
				})
				.then(message => {
					new Notifier().Success(NOTIFICATIONS.LOGO_UPDATED);
					this._model.Image = reader.result as string;
					this._logo(this._model.Image);
					this._isCloseVisible(true);
				})
				.fail(error => new Notifier().Failed(error.message));
		};

		reader.readAsDataURL(file);
	}

	ImageDelete() {
		
		var attachmentModel = new AttachmentModel();
		attachmentModel.FileName = '';
		LicenseHolderStore.UploadLicenseLogo(attachmentModel)
			.then(message => {
				this._model.Image = null;
				this._logo(this._defaultLogo);
				this._isCloseVisible(false);
			})

		
	}

	AfterRender(el: Array<HTMLElement>) {
		this._el = el[0];
	}

	@enumerable get MaxLicenseImageSize(): number {
		return this._maxLicenseImageSize;
	}
}