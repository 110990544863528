import { deserialize } from 'libs/cerialize';
import { deserializeAs } from 'libs/cerialize';
import { LifeStatusesModel } from "Core/Controls/ButtonFollowUp/Models/LifeStatusesModel";
import { SubjectActionModel } from "Core/Controls/ButtonFollowUp/Models/SubjectActionModel";

export class LifeStatusesGeneralModel {
	@deserializeAs(LifeStatusesModel)
	Parent: LifeStatusesModel;

	@deserializeAs(LifeStatusesModel)
    Child: LifeStatusesModel;

    @deserializeAs(Boolean)
    IsManyActions: Boolean;

    @deserializeAs(SubjectActionModel)
    SubjectAction: SubjectActionModel;

    @deserialize
    IsFollowUpDisabled: boolean;

    @deserialize
    DisabledReason: string;
}