import * as ko from "knockout"
import 'jquery';
import 'jquerySlimscroll';

export class SlimScrollExtention {
	static Init() {
		//JQuery slimscroll
		ko.bindingHandlers.slimScroll = {
			init: (element, valueAccessor) => {
				var options = valueAccessor();
				$(element).slimScroll(options);
			}
		};
	}
}