import * as ko from "knockout";
import * as _ from 'underscore';

import {Event} from 'Core/Common/Event';

import {ITabSecurityParams} from 'Core/GeneralProperties/Managers/TabSecurity/ITabSecurityParams';

import {BusinessRolesDisplaySettings} from './Models/BusinessRolesDisplaySettings';

import Template from 'Core/GeneralProperties/Managers/TabSecurity/Views/DisplayView/Templates/Template.html'

export class TabSecurityDisplayView extends Event {
    private _settings: KnockoutObservableArray<BusinessRolesDisplaySettings>;
    private _restrictionsIcons: KnockoutObservableArray<BusinessRolesDisplaySettings>;

    constructor(params: ITabSecurityParams) {
        super();
        this._settings = ko.observableArray(this.MapToSettings(params));
        this._restrictionsIcons = ko.observableArray(this.MapToRestrictionsIcon(params));
    }

    GetTemplate() {
        return Template;
    }

    RequestEditView() {
        this.Trigger('RequestEditView');
    }

    private MapToSettings(params: ITabSecurityParams) {
        return params.Settings.map(settings => {
            const role = params.Roles.find(role => role.Id === settings.RoleId);
            return role
                ? new BusinessRolesDisplaySettings(role, settings.Value)
                : null;
        }).filter(settings => settings !== null);
    }

    private MapToRestrictionsIcon(params: ITabSecurityParams) {
        let mapToRestrictions = this.MapToSettings(params).map(settings => {
            return settings._restrictions;
        });
        return _.uniq(_.flatten(mapToRestrictions, true), false, restrictions => restrictions.Name);
    }
}