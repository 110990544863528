import * as ko from 'knockout';

import {Event} from 'Core/Common/Event';
import {IColorSelectorOptions} from "../IColorSelectorOptions";

export abstract class ColorModel extends Event {
    protected label: string;
    protected enabled: KnockoutObservable<boolean>;

    protected constructor(options: IColorSelectorOptions) {
        super();

        this.label = options.Label;
        this.enabled = ko.observable(true);
    }

    Enable(enabled: boolean) {
        this.enabled(enabled);
    }

    abstract get ColorValue();

    abstract SetColor(color: string);

    abstract Reset();

    abstract GetTemplate(): any;
}
