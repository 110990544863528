import {IButtonConfig} from "Core/Controls/PortletBar/Interfaces/IButtonConfig"
import {PortletBarButton} from "Core/Controls/PortletBar/Managers/Buttons/PortletBarButton";
import {CollapseButton} from "Core/Controls/PortletBar/Managers/Buttons/CollapseButton";
import {ExpandButton} from "Core/Controls/PortletBar/Managers/Buttons/ExpandButton";
import {ReloadButton} from "Core/Controls/PortletBar/Managers/Buttons/ReloadButton";
import {RemoveButton} from "Core/Controls/PortletBar/Managers/Buttons/RemoveButton";
import {SettingsButton} from "Core/Controls/PortletBar/Managers/Buttons/SettingsButton";

export class ButtonsFactory {
	static CreateButton(buttonName: string, buttonConfig: IButtonConfig): PortletBarButton {
		return this[buttonName](buttonName, buttonConfig);
	}

	private static CollapseButton(buttonName: string, buttonConfig: IButtonConfig): PortletBarButton {
		return new CollapseButton(buttonName, buttonConfig);
	}

	private static ExpandButton(buttonName: string, buttonConfig: IButtonConfig) {
		return new ExpandButton(buttonName, buttonConfig);
	}

	private static ReloadButton(buttonName: string, buttonConfig: IButtonConfig) {
		return new ReloadButton(buttonName, buttonConfig);
	}

	private static RemoveButton(buttonName: string, buttonConfig: IButtonConfig) {
		return new RemoveButton(buttonName, buttonConfig);
	}

	private static SettingsButton(buttonName: string, buttonConfig: IButtonConfig) {
		return new SettingsButton(buttonName, buttonConfig);
	}
}