import * as _ from "underscore";

import {SelectedProduct} from "./SelectedProduct";
import {PropertyValue} from "./PropertyValue";
import {CustomFieldValue} from "./CustomFieldValue";

export class SelectedProducts {
    private _restored: SelectedProduct[];
    private _new: SelectedProduct[];
    private _removed: SelectedProduct[];
    private _selected: SelectedProduct[];

    private _initialMainPropertyValues: PropertyValue[];

    constructor() {
        this._restored = [];
        this._new = [];
        this._removed = [];
        this._selected = [];
        this._initialMainPropertyValues = [];
    }

    get Restored() {
        return [...this._restored];
    }

    get New() {
        return [...this._new];
    }

    get Removed() {
        return [...this._removed];
    }

    get Altered() {
        return _.chain(this.Restored)
            .filter(restored => !_.any(this.New, newPart => newPart.Id === restored.Id &&
                newPart.KSeq === restored.KSeq &&
                newPart.KSeqGuid === restored.KSeqGuid))
            .filter(restored => !_.any(this.Removed, removedPart => removedPart.Id === restored.Id &&
                removedPart.KSeq === restored.KSeq &&
                removedPart.KSeqGuid === restored.KSeqGuid))
            .filter(restored => _.any(this.Selected, selectedPart => selectedPart.Id === restored.Id &&
                selectedPart.KSeq === restored.KSeq &&
                selectedPart.KSeqGuid === restored.KSeqGuid &&
                (!_.isEqual(selectedPart.PropertyValues, restored.PropertyValues) ||
                    !_.isEqual(selectedPart.CustomFieldValues, restored.CustomFieldValues)) ||
                selectedPart.Quantity != restored.Quantity ||
                selectedPart.Price != restored.Price))
            .value();
    }

    get InitialMainPropertyValues() {
        return [...this._initialMainPropertyValues];
    }

    get Selected() {
        return [...this._selected];
    }

    IsSelectedProduct(productId: number, groupId: number, groupName: string, rootGroupId: number, rootGroupName: string, path: number[], kSeq: number, kSeqGuid: string) {
        return !!this.GetSelectedProduct(productId, groupId, groupName, rootGroupId, rootGroupName, path, kSeq, kSeqGuid);
    }

    Restore(productId: number, groupId: number, groupName: string, rootGroupId: number, rootGroupName: string, path: number[], kSeq: number, kSeqGuid: string, price: number, quantity: number, propertyValues: PropertyValue[], customFieldValues: CustomFieldValue[]) {
        if (!this.IsSelectedProduct(productId, groupId, groupName, rootGroupId, rootGroupName, path, kSeq, kSeqGuid)) {
            this._restored.push(new SelectedProduct(productId, groupId, groupName, rootGroupId, rootGroupName, path, kSeq, kSeqGuid, price, quantity, propertyValues, customFieldValues));
            this._selected.push(new SelectedProduct(productId, groupId, groupName, rootGroupId, rootGroupName, path, kSeq, kSeqGuid, price, quantity, propertyValues, customFieldValues));
        }
    }

    InitMainPropertyValues(mainPropertyValues: PropertyValue[]) {
        this._initialMainPropertyValues = mainPropertyValues;
    }

    Add(productId: number, groupId: number, groupName: string, rootGroupId: number, rootGroupName: string, path: number[], kSeq: number, kSeqGuid: string, quantity: number, price: number, propertyValues: PropertyValue[], customFieldValues: CustomFieldValue[]) {
        if (this.IsSelectedProduct(productId, groupId, groupName, rootGroupId, rootGroupName, path, kSeq, kSeqGuid)) {
            return;
        }

        this._selected.push(new SelectedProduct(productId, groupId, groupName, rootGroupId, rootGroupName, path, kSeq, kSeqGuid, price, quantity, propertyValues, customFieldValues));

        if (this.IsRestoredProduct(productId, groupId, groupName, rootGroupId, rootGroupName, path, kSeq, kSeqGuid)) {
            this.RemoveFromDeleted(productId, groupId, groupName, rootGroupId, rootGroupName, path, kSeq, kSeqGuid)
        } else {
            this._new.push(new SelectedProduct(productId, groupId, groupName, rootGroupId, rootGroupName, path, kSeq, kSeqGuid));
        }
    }

    Remove(productId: number, groupId: number, groupName: string, rootGroupId: number, rootGroupName: string, path: number[], kSeq, kSeqGuid: string) {
        if (this.IsRemovedProduct(productId, groupId, groupName, rootGroupId, rootGroupName, path, kSeq, kSeqGuid)) {
            return;
        }

        const dependencies = this.GetSelectedDependenciesOf(productId, rootGroupId, rootGroupName, path, kSeq, kSeqGuid);
        dependencies.forEach(dependency => this.Remove(dependency.Id, dependency.GroupId, dependency.GroupName, dependency.RootGroupId, dependency.RootGroupName, dependency.Path, dependency.KSeq, dependency.KSeqGuid));

        if (this.IsRestoredProduct(productId, groupId, groupName, rootGroupId, rootGroupName, path, kSeq, kSeqGuid)) {
            this._removed.push(new SelectedProduct(productId, groupId, groupName, rootGroupId, rootGroupName, path, kSeq, kSeqGuid));
        }

        const selectedProduct = this.GetSelectedProduct(productId, groupId, groupName, rootGroupId, rootGroupName, path, kSeq, kSeqGuid);
        const selectedIndex = this._selected.indexOf(selectedProduct);

        if (selectedIndex > -1) {
            this._selected.splice(selectedIndex, 1);
        }

        this.RemoveFromNew(productId, groupId, groupName, rootGroupId, rootGroupName, path, kSeq, kSeqGuid);
    }

    GetRestoredProduct(productId: number, groupId: number, groupName: string, rootGroupId: number, rootGroupName: string, path: number[], kSeq, kSeqGuid: string) {
        return _.find(this._restored, product =>
            product.Id === productId &&
            product.GroupId === groupId &&
            product.GroupName === groupName &&
            product.RootGroupId === rootGroupId &&
            product.RootGroupName === rootGroupName &&
            _.isEqual(product.Path, path) &&
            product.KSeq === kSeq &&
            product.KSeqGuid === kSeqGuid
        );
    }

    GetSelectedProduct(productId: number, groupId: number, groupName: string, rootGroupId: number, rootGroupName: string, path: number[], kSeq: number, kSeqGuid: string) {
        return _.find(this._selected, product =>
            product.Id === productId &&
            product.GroupId === groupId &&
            product.GroupName === groupName &&
            product.RootGroupId === rootGroupId &&
            product.RootGroupName === rootGroupName &&
            _.isEqual(product.Path, path) &&
            product.KSeq === kSeq &&
            product.KSeqGuid === kSeqGuid
        );
    }

    private IsRestoredProduct(productId: number, groupId: number, groupName: string, rootGroupId: number, rootGroupName: string, path: number[], kSeq: number, kSeqGuid: string) {
        return !!this.GetRestoredProduct(productId, groupId, groupName, rootGroupId, rootGroupName, path, kSeq, kSeqGuid);
    }

    private IsRemovedProduct(productId: number, groupId: number, groupName: string, rootGroupId: number, rootGroupName: string, path: number[], kSeq: number, kSeqGuid: string) {
        return !!this.GetRemovedProduct(productId, groupId, groupName, rootGroupId, rootGroupName, path, kSeq, kSeqGuid);
    }

    private RemoveFromNew(productId: number, groupId: number, groupName: string, rootGroupId: number, rootGroupName: string, path: number[], kSeq: number, kSeqGuid: string) {
        const newProduct = this.GetNewProduct(productId, groupId, groupName, rootGroupId, rootGroupName, path, kSeq, kSeqGuid);
        const newIndex = this._new.indexOf(newProduct);

        if (newIndex > -1) {
            this._new.splice(newIndex, 1);
        }
    }

    private RemoveFromDeleted(productId: number, groupId: number, groupName: string, rootGroupId: number, rootGroupName: string, path: number[], kSeq: number, kSeqGuid: string) {
        const removedProduct = this.GetRemovedProduct(productId, groupId, groupName, rootGroupId, rootGroupName, path, kSeq, kSeqGuid);

        const removedIndex = this._removed.indexOf(removedProduct);

        if (removedIndex > -1) {
            this._removed.splice(removedIndex, 1);
        }
    }

    private GetNewProduct(productId: number, groupId: number, groupName: string, rootGroupId: number, rootGroupName: string, path: number[], kSeq: number, kSeqGuid: string) {
        return _.find(this._new, product =>
            product.Id === productId &&
            product.GroupId === groupId &&
            product.GroupName === groupName &&
            product.RootGroupId === rootGroupId &&
            product.RootGroupName === rootGroupName &&
            _.isEqual(product.Path, path) &&
            product.KSeq === kSeq &&
            product.KSeqGuid === kSeqGuid
        );
    }

    private GetRemovedProduct(productId: number, groupId: number, groupName: string, rootGroupId: number, rootGroupName: string, path: number[], kSeq: number, kSeqGuid: string) {
        return _.find(this._removed, product =>
            product.Id === productId &&
            product.GroupId === groupId &&
            product.GroupName === groupName &&
            product.RootGroupId === rootGroupId &&
            product.RootGroupName === rootGroupName &&
            _.isEqual(product.Path, path) &&
            product.KSeq === kSeq &&
            product.KSeqGuid === kSeqGuid
        );
    }

    private GetSelectedDependenciesOf(productId: number, rootGroupId: number, rootGroupName: string, path: number[], kSeq: number, kSeqGuid: string): SelectedProduct[] {
        return this._selected.filter(part =>
            part.RootGroupId === rootGroupId &&
            part.RootGroupName === rootGroupName &&
            productId === _.last(part.Path) &&
            part.KSeq === kSeq &&
            part.KSeqGuid === kSeqGuid);
    }
}