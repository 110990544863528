function isZero(stringifiedNumber: string) {
    const [integerPart, decimalPart] = stringifiedNumber.replace('-', '').split('.');

    return [...integerPart].every(digit => digit === '0') && (!decimalPart || [...decimalPart].every(digit => digit === '0'));
}

// Based on onBeforeWrite function from inputmask.numeric.extensions.js
// Taken off additional conversions from string to number and from number to string which don't work well on large numbers
export const AdditionalAliases = {
    'patchedDecimal': {
        alias: 'decimal',
        onBeforeWrite: function (e, buffer, caretPos, opts) {
            var rslt;
            if (e && (e.type === "blur" || e.type === "checkval" || e.type === "keydown")) {
                var maskedValue = opts.numericInput ? buffer.slice().reverse().join("") : buffer.join(""),
                    processValue = maskedValue.replace(opts.prefix, "");
                processValue = processValue.replace(opts.suffix, "");
                processValue = processValue.replace(new RegExp(Inputmask.escapeRegex(opts.groupSeparator), "g"), "");
                if (opts.radixPoint === ",") processValue = processValue.replace(opts.radixPoint, ".");
                //handle negation symbol
                var isNegative = processValue.match(new RegExp("[-" + Inputmask.escapeRegex(opts.negationSymbol.front) + "]", "g"));
                isNegative = isNegative !== null && isNegative.length === 1;
                processValue = processValue.replace(new RegExp("[-" + Inputmask.escapeRegex(opts.negationSymbol.front) + "]", "g"), "");
                processValue = processValue.replace(new RegExp(Inputmask.escapeRegex(opts.negationSymbol.back) + "$"), "");
                //strip placeholder at the end
                if (isNaN(opts.placeholder)) {
                    processValue = processValue.replace(new RegExp(Inputmask.escapeRegex(opts.placeholder), "g"), "");
                }
                processValue = processValue === opts.negationSymbol.front ? processValue + "0" : processValue;

                if (processValue !== "" && isFinite(processValue)) {
                    var absoluteProcessValue = processValue;
                    var signedProcessValue = isNegative ? `-${processValue}` : processValue;
                    if (opts.min !== null && isFinite(opts.min) && parseFloat(signedProcessValue) < parseFloat(opts.min)) {
                        absoluteProcessValue = opts.min.replace('-', '');
                        isNegative = opts.min < 0;
                        maskedValue = undefined;
                    } else if (opts.max !== null && isFinite(opts.max) && parseFloat(signedProcessValue) > parseFloat(opts.max)) {
                        absoluteProcessValue = opts.max.replace('-', '');
                        isNegative = opts.max < 0;
                        maskedValue = undefined;
                    }

                    processValue = absoluteProcessValue.replace(".", opts.radixPoint).split('');
                    if (isFinite(opts.digits)) {
                        var radixPosition = $.inArray(opts.radixPoint, processValue);
                        var rpb = $.inArray(opts.radixPoint, maskedValue);
                        if (radixPosition === -1) {
                            processValue.push(opts.radixPoint);
                            radixPosition = processValue.length - 1;
                        }
                        for (var i = 1; i <= opts.digits; i++) {
                            if (!opts.digitsOptional && (processValue[radixPosition + i] === undefined || processValue[radixPosition + i] === opts.placeholder.charAt(0))) {
                                processValue[radixPosition + i] = "0";
                            } else if (rpb !== -1 && maskedValue[rpb + i] !== undefined) {
                                processValue[radixPosition + i] = processValue[radixPosition + i] || maskedValue[rpb + i];
                            }
                        }

                        if (processValue[processValue.length - 1] === opts.radixPoint) {
                            delete processValue[processValue.length - 1];
                        }
                    }

                    if (isNegative && (!isZero(absoluteProcessValue) || e.type !== "blur")) {
                        processValue.unshift(opts.negationSymbol.front);
                        processValue.push(opts.negationSymbol.back);
                    }
                    processValue = (opts.prefix + processValue.join("")).split("");
                    if (opts.numericInput) processValue = processValue.reverse();
                    rslt = opts.postFormat(processValue, opts.numericInput ? caretPos : caretPos - 1, opts);
                    if (rslt.buffer) rslt.refreshFromBuffer = rslt.buffer.join("") !== buffer.join("");
                    return rslt;
                }
            }
            if (opts.autoGroup) {
                rslt = opts.postFormat(buffer, opts.numericInput ? caretPos : caretPos - 1, opts);
                rslt.caret = caretPos <= opts.prefix.length ? rslt.pos : rslt.pos + 1;
                return rslt;
            }
        }
    }
};
