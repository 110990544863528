import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {SubTableViewDto} from "./Models/SubTableViewDto";

export class Store {
    static GetSubTableViews(entityId: number): P.Promise<SubTableViewDto[]> {
        return Request.Send({
            proxy: {
                url: '/api/BackLinkApi/GetSubTableViews',
                type: 'GET',
                dataType: 'json'
            }, params: {entityId: entityId}
        });
    }
}