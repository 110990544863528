import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

export class ColorDescription {
    constructor(private _name: string, private _color: string) {
    }

    @enumerable get Name() {
        return this._name;
    }

    @enumerable get Color() {
        return this._color;
    }
}