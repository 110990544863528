import {ChartDataModel} from "Core/Controls/Chart/Models/ChartDataModel";

import {FIELD_TYPES} from "Core/Constant";

import * as Highcharts from "highcharts";
import {ChartBuilder} from "./ChartBuilder"
import {IChartBuilderParams} from "../IChartBuilderParams";
import {ChartAxisInfoModel} from "Core/Controls/Chart/Models/ChartAxisInfoModel";
import {ChartTypeEnum} from "../Enums/ChartTypeEnum";

export class PieChartBuilder extends ChartBuilder {
    private  _mainAxis: ChartAxisInfoModel;
    private _secondaryAxis: ChartAxisInfoModel;
    private  _mainAxisLabel: string;
    private _secondaryAxisLabel: string;

    constructor(params: IChartBuilderParams) {
        super(params);
    }

    RenderChart(options: ChartDataModel) {
        const self = this;

        this._mainAxis = this.GetMainAxis(options.XAxisInfoModel, options.YAxisInfoModel);
        this._secondaryAxis = this._mainAxis === options.XAxisInfoModel
            ? options.YAxisInfoModel : options.XAxisInfoModel;

        const series = this.GetSeries(options);

        const xAxisLabel = this._xAxisLabel ? this._xAxisLabel : options.XAxisInfoModel.AxisDefaultName;
        const yAxisLabel = this._yAxisLabel ? this._yAxisLabel : options.YAxisInfoModel.AxisDefaultName;

        Highcharts.chart(this._wrapperId, {
            chart: {
                type: this._chartType
            },
            title: {
                text: self._chartTitle
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            tooltip: {
                formatter() {
                    return self.GetToolTip(options, this.point, xAxisLabel, yAxisLabel);
                }
            },
            series: series
        }, () => {
        });
    }

    private GetSeries(options: ChartDataModel) {
        let series = [];
        const points = options.Points;

        if (!points || !_.any(points)) {
            return series;
        }

        if (this._secondaryAxis.AxisExist){
            let groupData = [];
            _.forEach(points,
                point => {
                    const mainAxisPoint = this._mainAxis === options.XAxisInfoModel ? point.XAxis : point.YAxis;
                    const secondaryAxisPoint = mainAxisPoint === point.XAxis ? point.YAxis : point.XAxis;

                    groupData.push({name: point.Line,
                        y: this.GetFormattedValue(mainAxisPoint, this._mainAxis.FieldType),
                        z: this.GetFormattedValue(secondaryAxisPoint, this._secondaryAxis.FieldType)});
                });
            series.push({ type: ChartTypeEnum.Pie, innerSize: '20%', zMin: 0, data: groupData });
        }
        else{
            let groupData = [];
            _.forEach(points,
                point => {
                    const mainAxisPoint = this._mainAxis === options.XAxisInfoModel ? point.XAxis : point.YAxis;

                    groupData.push({
                        name: point.Line,
                        y: this.GetFormattedValue(mainAxisPoint, this._mainAxis.FieldType),
                    });
                });
            series.push({ type: ChartTypeEnum.Pie, innerSize: '20%', zMin: 0, data: groupData });
        }

        return series;
    }

    private GetMainAxis(xAxisModel: ChartAxisInfoModel, yAxisModel: ChartAxisInfoModel){
        return xAxisModel.AxisExist && xAxisModel.FieldType !== FIELD_TYPES.Text
            ? xAxisModel : yAxisModel;
    }

    private GetToolTip(options: ChartDataModel, point, xLabel: string, yLabel: string) {
        if (this._mainAxis === options.XAxisInfoModel){
            this._mainAxisLabel = xLabel || this._mainAxis.AxisDefaultName;
            this._secondaryAxisLabel = yLabel || this._secondaryAxis.AxisDefaultName;
        }
        else {
            this._mainAxisLabel = yLabel || this._secondaryAxis.AxisDefaultName;
            this._secondaryAxisLabel = xLabel || this._mainAxis.AxisDefaultName;
        }

        if (this._secondaryAxis.AxisExist){
            return `<span style="color:${point.color}">\u25CF </span><b>${point.name}</b><br/> 
                ${this._mainAxisLabel}: <b>${this.ApplyLabelFormatter(point.y, this._mainAxis.FieldType, this._mainAxis.FormatName)}</b><br/> 
                ${this._secondaryAxisLabel}: <b>${this.ApplyLabelFormatter(point.z, this._secondaryAxis.FieldType, this._secondaryAxis.FormatName)}</b><br/>`;
        }
        else {
            return `<span style="color:${point.color}">\u25CF</span><b>${point.name}</b><br/> 
            ${this._mainAxisLabel}: <b>${this.ApplyLabelFormatter(point.y, this._mainAxis.FieldType, this._mainAxis.FormatName)}</b><br/>`;
        }
    }
}