import { deserialize, deserializeAs } from 'libs/cerialize';

class SupportInfoCategory {
    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    NameTranslation: string;

    get DisplayName() {
        return this.NameTranslation || this.Name;
    }
}

class SupportInfoUrgency {
    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    NameTranslation: string;

    get DisplayName() {
        return this.NameTranslation || this.Name;
    }
}

export class SupportInfoModel {
    @deserializeAs(SupportInfoCategory)
    Categories: SupportInfoCategory[];

    @deserializeAs(SupportInfoUrgency)
    Urgencies: SupportInfoUrgency[];
}
