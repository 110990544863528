import * as ko from 'knockout';

import {Notifier} from 'Core/Common/Notifier';
import {EventBus} from 'Core/Common/EventBus/EventBus';
import {EventBusConsumer} from 'Core/Common/EventBus/EventBusConsumer';

import {LABELS} from 'Core/Components/Translation/Locales';
import {ConfirmationDialog, EVENTS, Types} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';

import {
    NetworkDesignerEvents,
    NewRemoteDatabaseAccess,
    RemoteDatabaseAccessChanges
} from './Events/NetworkDesignerEvents';

import {NetworkDesignerStore} from './Stores/NetworkDesignerStore';
import {UpdateRemoteDatabaseAccessDto} from './Stores/Models/UpdateRemoteDatabaseAccessDto';
import {CreateRemoteDatabaseAccessDto} from './Stores/Models/CreateRemoteDatabaseAccessDto';

import {NetworkDesignerMappings} from './Mappings/NetworkDesignerMappings';
import {RemoteDatabase} from './Components/RemoteDatabase';
import {RemoteDatabaseAccess} from './Components/RemoteDatabaseAccess';

import Template from './Templates/Template.html';

ko.templates['Core/NetworkDesigner/Templates/Template'] = Template;

export class NetworkDesigner extends EventBusConsumer {
    private _labels = LABELS
    private _store: NetworkDesignerStore;
    private _mappings: NetworkDesignerMappings;

    private _isReady: KnockoutObservable<boolean>;
    private _databases: KnockoutObservableArray<RemoteDatabase>;

    constructor() {
        super();

        this._store = new NetworkDesignerStore();
        this._mappings = new NetworkDesignerMappings();

        this._isReady = ko.observable(false);
        this._databases = ko.observableArray([]);

        this.AssignEventBus(new EventBus())
    }

    AssignEventBus(bus: EventBus) {
        super.AssignEventBus(bus);

        this.HandleEvent<any, RemoteDatabase>(NetworkDesignerEvents.HeaderClicked)
            .When(eventArgs => !eventArgs.Source.DataLoaded)
            .Using(eventArgs => this.GetDatabaseAccess(eventArgs.Source))
            .Always();

        this.HandleEvent<RemoteDatabaseAccess, RemoteDatabase>(NetworkDesignerEvents.AccessRemoving)
            .Using(eventArgs => this.RemoveDatabaseAccess(eventArgs.Source, eventArgs.Data))
            .Always();

        this.HandleEvent<RemoteDatabaseAccessChanges>(NetworkDesignerEvents.AccessСhangesSaving)
            .Using(eventArgs => this.SaveDatabaseAccessChanges(eventArgs.Source, eventArgs.Data))
            .Always();

        this.HandleEvent<NewRemoteDatabaseAccess>(NetworkDesignerEvents.AccessSaving)
            .Using(eventArgs => this.CreateDatabaseAccess(eventArgs.Source, eventArgs.Data))
            .Always();
    }

    Render(elId: string) {
        const container = document.getElementById(elId);
        ko.cleanNode(container);
        ko.applyBindings(this, container);
    }

    GetTemplateName() {
        return 'Core/NetworkDesigner/Templates/Template';
    }

    AfterRender() {
        this._isReady(true);
        this.GetDatabases();
    }

    Dispose() {
        this._databases().forEach(database => database.Dispose());
        super.Dispose();
    }

    private GetDatabases() {
        this._store.GetRemoteDatabases()
            .then(databasesDto => {
                const databases = this._mappings.MapToRemoteDatabases(databasesDto, database => {
                    database.AssignEventBus(this.EventBus)
                });

                this._databases(databases);
            });
    }

    private GetDatabaseAccess(database: RemoteDatabase) {
        this._store.GetRemoteDatabaseAccess(database.Id)
            .then(accessDto => {
                const access = this._mappings.MapToRemoteDatabaseAccess(accessDto);
                database.FillAccess(access);
            })
            .fail(err => new Notifier().Failed(err.message));
    }

    private SaveDatabaseAccessChanges(database: RemoteDatabase, accessChanges: RemoteDatabaseAccessChanges) {
        const access = database.GetAccess(accessChanges.Id);

        const params = new UpdateRemoteDatabaseAccessDto(access.Id, accessChanges.UserId, accessChanges.RemoteUserName);
        this._store.UpdateRemoteDatabaseAccess(params)
            .then(() => database.CommitChanges(access))
            .fail(err => {
                access.UnBlock();
                database.SetAccessError(access, err.message)
            });
    }

    private CreateDatabaseAccess(database: RemoteDatabase, accessData: NewRemoteDatabaseAccess) {
        const access = database.GetAccessByUniqueId(accessData.UniqueId);

        const params = new CreateRemoteDatabaseAccessDto(database.Id, accessData.UserId, accessData.RemoteUserName);
        this._store.CreateRemoteDatabaseAccess(params)
            .then(accessId => database.CommitCreateAccess(access, accessId))
            .fail(err => {
                access.UnBlock();
                database.SetAccessError(access, err.message)
            });
    }

    private RemoveDatabaseAccess(database: RemoteDatabase, access: RemoteDatabaseAccess) {
        const dialog = new ConfirmationDialog({
            Type: Types.Question,
            Text: 'Are you sure you want to delete this record?'
        });

        dialog.On(EVENTS.CONFIRM_SELECTED, this, () => {
            this._store.RemoveRemoteDatabaseAccess(access.Id)
                .then(() => database.CommitRemoveAccess(access))
                .fail(err => {
                    access.UnBlock();
                    database.SetAccessError(access, err.message)
                });
        });

        dialog.On(EVENTS.DISCARD_SELECTED, this, () => {
            database.CancelRemoveAccess(access);
        });

        dialog.Show();
    }
}