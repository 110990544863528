import * as ko from "knockout";
import * as moment from 'moment';

import {FormatConverter} from "FormatEditor/FormatConverter";
import {Period} from "../Models/View/SchedulerViewModel";
import {IAppointment} from "../Models/Store/Response/SchedulerResponseModel";
import {DATE_FORMATS} from "Core/Constants/DateTimeFormats";
import {JBoxDropDown} from "Core/Components/JBoxDropdown/DropDown";
import {SchedulerView} from "../Views/SchedulerView";
import {IRePlanningData} from "Core/Controls/Scheduler/Interfaces";
import {LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";
import {TypeScreen} from "Core/Screens/TypeScreen/TypeScreen";
import {Notifier} from "Core/Common/Notifier";

export interface ICellEditor {
    count: number,
    isAvailable: boolean,
    isFreeDay: boolean,
    period: Period,
    schedulerView: SchedulerView,
    isGroupCell?: boolean,
    rePlanningData?: IRePlanningData,
    isSelected: boolean,
    columnIndex: string
    appointments: Array<IAppointment>;
}

export class CellEditor {
    _count: number;
    _isAvailable: boolean;
    _IsGroupCell: boolean;
    _isFreeDay: boolean;
    _period: Period;
    _appointments: KnockoutObservableArray<IAppointment>;
    _isAvailableForReplan: KnockoutObservable<boolean>;
    _view: SchedulerView;
    _isReplanningCell: KnockoutObservable<boolean>;
    _availableForReplanRanges: Array<{Start: moment.Moment, End: moment.Moment, DisplayValue: string}>;
    private _dropDown: JBoxDropDown;
    private _replanningData: IRePlanningData;
    private _columnIndex: string;
    private _labels = LABELS;

    constructor(model: ICellEditor) {
        this._count = model.count;
        this._isAvailable = model.isAvailable;
        this._isFreeDay = model.isFreeDay;
        this._IsGroupCell = model.isGroupCell || false;
        this._period = model.period;
        this._appointments = ko.observableArray([]);
        this._view = model.schedulerView;
        this._replanningData = model.rePlanningData;
        this._isReplanningCell = ko.observable(false);
        this._isAvailableForReplan = ko.observable(false);
        this._columnIndex = `column-${model.columnIndex}`;
        this._availableForReplanRanges = [];
        this.InitAppointments(model.appointments);

        if (model.isSelected) {
            this.IsReplanAppointment();
            this.IsAvailableForReplan();
        }
    }

    IsAvailableForReplan() {
        if (this._isAvailable) {
            const appointDuration = this._replanningData.EndTime.diff(this._replanningData.StartTime, 'hours', true);
            if ((this._period.Duration - this._count) >= appointDuration) {
                this._isAvailableForReplan(true);
            }
        }
    }

    private IsReplanAppointment() {
        const start = this._replanningData.StartTime;
        const end = this._replanningData.EndTime;
        const periodStart = moment(this._period.Start);
        const periodEnd = periodStart.clone().add(this._period.Duration, 'h');
        this._isReplanningCell((start < periodEnd) && (end > periodStart));
    }

    GetClassName(qty: number): string {
        let classes = this._columnIndex;
        if (this._isFreeDay) {
            classes = classes + ' subcol--red';
        }
        if (this._IsGroupCell && this._count) {
            classes = classes + ' subcol--green';
        } else if (this._IsGroupCell && !this._count) {
            classes = classes + ' subcol--white';
        }
        if (this._isAvailable) {
            if (this._count) {
                classes = classes + ' subcol--blue';
            } else {
                classes = classes + ' subcol--white';
            }
        } else {
            classes = classes + ' subcol--grey'
        }
        classes = classes + ' subcol-' + qty;
        return classes;
    }

	get DisplayValue(): string {
        return this._count ? this.ConvertValue(this._count) : null;
    }

    private ConvertValue(value: number): string {
        let rounded = Math.round(value * 100) / 100;
        return FormatConverter.ConvertDecimal(rounded.toString());
    }

    private DisplayRanges(ranges: Array<{Start: moment.Moment, End: moment.Moment, DisplayValue: string}>, cell, event) {
        if (this._availableForReplanRanges.length > 1) {
            this.InitDropdown(event.currentTarget);
            this._dropDown.Toggle();
        }
    }

    private InitAppointments(agenda: Array<IAppointment>) {
        if (this._count && agenda.length && !this._appointments().length) {
            let appointments = [];
            _.forEach(agenda, (item) => {
                const start = moment(FormatConverter.CorrectTimezone(item.Starting));
                const end = moment(start.clone()).add(moment.duration(moment(item.Duration).format(DATE_FORMATS.TIME.Format)));

                const periodStart = moment(this._period.Start);
                const periodEnd = periodStart.clone().add(this._period.Duration, 'h');

                if ((start < periodEnd) && (end > periodStart)){
                    appointments.push({
                        Id: item.Id,
                        Name: item.Name,
                        Duration: start.format(DATE_FORMATS.TIME.Format) + ' - ' + end.format(DATE_FORMATS.TIME.Format),
                        Subjects: item.AgendaSubjects
                    });
                }
            });
            this._appointments(appointments);
        }
    }

    private DisplayAppointments(cell, event) {
        if (this._replanningData)
            return;

        if (this._appointments().length) {
            this.InitDropdown(event.currentTarget);
            this._dropDown.Toggle();
        }
    }

    private InitDropdown(el: HTMLElement) {
        if (!this._dropDown) {
            const self = this;
            this._dropDown = new JBoxDropDown({
                onCreated: () => {
                    this._dropDown.SetContent();
                },
                target: el,
                bindTarget: el,
                bindComponent: this,
                onOpen: function() {
                    $(this.container.parent()).on('mouseleave.Appointment', function (ev) {
                        self._dropDown && self._dropDown.Close();
                    }.bind(this));

                    $(el).on('mouseleave.Appointment', function (ev) {
                        if(!$(ev.toElement).parent(this.container.parent()).is(this.container.parent()) && !$(ev.toElement).is(this.container.parent())) {
                            self._dropDown && self._dropDown.Close();
                        }
                    }.bind(this));
                },
                otherOptions: {
                    closeOnMouseleave: true,
                    closeOnClick: false,
                    attach: $(el),
                    position: {
                        x: "left",
                        y: "bottom"
                    },
                    onCloseComplete: function() {
                        self._dropDown.Destroy();
                        self._dropDown = null;
                        $(el).off('mouseleave.Appointment');
                        $(this.container.parent()).off('mouseleave.Appointment');
                    },
                    offset: {
                        x: 15
                    },
                    pointer: 'left',
                    addClass: 'scheduler-appointments-dropdown',
                    maxWidth: 200,
                    zIndex: 10
                }
            });
        }
    }

    ShowAppointment(data, event) {
        const typeScreen = new TypeScreen(this._view.GetEntityId(), 0, false, false, null, ['Appointment']);

        typeScreen
            .On('TYPE_SELECTED', this, eventArgs => {
                this._view.OnShowDefaultScreen(data, eventArgs.data.EntityId, event, eventArgs.data.TypeId);
            })
            .On('TYPES_NOT_FOUND', this, (eventArgs) => new Notifier().Warning(eventArgs.data.Message || NOTIFICATIONS.SUB_TYPE_NOT_FOUND));

        typeScreen.Show();
    }

    private ShowDefaultScreen(entityId, data, event) {
        this._view.OnShowDefaultScreen(data, entityId, event);
    }

    private OnReplan(start, allowClick,  el, event) {
        if (allowClick) {
            this._view.OnReplan(moment(start));
        } else if (this._availableForReplanRanges.length === 1) {
            this._view.OnReplan(moment(start));
        }
    }

    private CheckIsAfter():boolean {
        return moment().isAfter(this._period.Start);
    }
}
