import { deserialize } from 'libs/cerialize';
import { deserializeAs } from 'libs/cerialize';

export class TranslationValueModel {
	@deserialize
	Key: string;

	@deserialize
	Value: string;
}

export class TranslationModel {
	constructor() {
		this.Translations = [];
	}

	IsFocused: boolean;

	@deserialize
	Id: number;

	@deserialize
	Code: number;

	@deserialize
	Name: string;

	@deserializeAs(TranslationValueModel)
	Translations: Array<TranslationValueModel>;
}