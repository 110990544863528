import * as ko from 'knockout';

import {NewDataRoleComponent} from "Core/Controls/FieldSecurity/Components/DataRole/NewDataRole/NewDataRoleComponent";
import {DataRoleModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/DataRoleModel";
import {FieldCollectionModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/FieldCollectionModel";
import {DataRole} from "Core/Controls/FieldSecurity/Shared/Enums/DataRoles";
import {NewPersonalDataRoleModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/NewPersonalDataRoleModel";

import Template from 'Core/Controls/FieldSecurity/Shared/Templates/NewDataRole/NewPersonalDataRole.html';

export class NewPersonalDataRoleComponent extends NewDataRoleComponent {
	protected Model: KnockoutValidatedObservable<NewPersonalDataRoleModel>;
	protected DataRole: DataRole;

	constructor(dataRole: DataRole) {
		super(Template);

		this.Model = ko.validatedObservable(new NewPersonalDataRoleModel());
		this.DataRole = dataRole;
	}

	AfterRender() {
	}

	ValidateForm() {
		const valid = this.Model.isValid();

		if (!valid) {
			this.Model.errors.showAllMessages();
		}

		return valid;
	}

	protected MapToNewDataRole(): DataRoleModel {
		const fieldCollection = new FieldCollectionModel(null, this.Model().FieldCollectionName(), null);
		return new DataRoleModel(null, this.DataRole.ShortName, fieldCollection);
	}
}
