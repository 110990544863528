import {deserialize, deserializeAs} from 'libs/cerialize';
import {ScreenModel} from 'Core/Models/Screens/ScreenModel';

export class PageResponse {
    @deserialize
    PageId: number;

    @deserialize
    PageNumber: number;

    @deserialize
    NumberOfPages: number;

    @deserializeAs(ScreenModel)
    Screen: ScreenModel;
}