import { FastFilter } from 'Core/Controls/Grid/BaseGrid/FastFilter/FastFilter';
import { GridColumnModel } from 'Core/Controls/Grid/Models/GridDataModel/GridColumnModel';
import { EVENTS } from 'Core/Controls/Grid/BaseGrid/Events';
import { GridStore, IGetGridDataRequestModel } from 'Core/Controls/Grid/Stores/GridStore';
import { FastFilterItem, FastFilterValue } from 'Core/Controls/Grid/BaseGrid/FastFilter/FastFilterModels';
import { BlockUI as BlockUi } from 'Core/Common/BlockUi';
import { FormatConverter } from 'FormatEditor/FormatConverter';
import 'tree';
import * as moment from 'moment';

import DateTimeFilterTemplate from 'Core/Controls/Grid/BaseGrid/FastFilter/Filters/DateTime/DateTimeFilter.html';
import { Notifier } from 'Core/Common/Notifier';

export class DateTimeFilter extends FastFilter {
	private _jsTreeModel: any;
	private _jsTreeSaveModel: any;
	private _jsTree: any;

	constructor(model: GridColumnModel,
		recordId: number,
		tableViewId: number,
		getGridDataModel: IGetGridDataRequestModel,
		existedColumnAliases: string[]) {
		super(model, recordId, tableViewId, getGridDataModel, existedColumnAliases);

		this._jsTreeModel = {};
		this._jsTreeSaveModel = {};

		this.Preselect();
		this.OpenFilter = () => {
			super.OpenFilter.call(this).then(() => {
				this.MapToJstreeModel();
			});
		}
	}

	protected MapToViewModel() {
		const viewModelList: FastFilterItem[] =
			this._availableItems().map((item: FastFilterValue) => {
				const wasItemChecked = this.RestoreSelectedCheckbox(item);

				const viewModelItem: FastFilterItem = {
					Value: !item.Value || (item.Value === '0') ? null : item.Value.toString(),
					DisplayValue: !item.Value || (item.Value === '0') ? this._labels.EMPTY_VALUE : item.Value.toString(),
					Checked: wasItemChecked,
					Childs: item.Childs && item.Childs.map((child) => {
						const wasItemChecked = this.RestoreSelectedCheckbox(child);

						const viewModelItem: FastFilterItem = {
							Value: child.Value && child.Value.toString(),
							DisplayValue: moment(child.Value && child.Value.toString(), 'M').format('MMMM'),
							Checked: wasItemChecked,
							Childs: child.Childs && child.Childs.map((child) => {
								const wasItemChecked = this.RestoreSelectedCheckbox(child);

								const viewModelItem: FastFilterItem = {
									Value: child.Value && child.Value.toString(),
									DisplayValue: child.Value.toString(),
									OriginValue: child.OriginValue,
									Checked: wasItemChecked
								};

								return viewModelItem;
							})
						};

						return viewModelItem;
					})
				};

				IdentifyChecked.call(this, this._preselectedRecords(), item, viewModelItem);

				return viewModelItem;
			});

		function IdentifyChecked(preselectedList, currentItem, viewModelItem) {
			preselectedList.forEach((preselected) => {
				if (!this._storedCheckboxes
					&& ((!viewModelItem.Value && !preselected.Value)
						|| (viewModelItem.Value && viewModelItem.Value.toString() === preselected.Value))) {

					viewModelItem.Checked = true;

					if (preselected.Childs && preselected.Childs.length && viewModelItem.Childs && viewModelItem.Childs.length) {
						preselected.Childs.forEach((child) => {
							viewModelItem.Childs.forEach((viewChild) => {
								IdentifyChecked.call(this, preselected.Childs, child, viewChild);
							});
						});
					}
				}
			});
		}

		this._viewModelItem(viewModelList);
		if (this._originalViewModelItems == null)
			this._originalViewModelItems = _.uniq(viewModelList, false, time => time.DisplayValue);
	}

	private MapToJstreeModel() {
		this._jsTreeModel = this._viewModelItem().map((item) => {
			return {
				text: item.DisplayValue,
				data: {
					value: item.Value,
				},
				state: {
					opened: true,
					disabled: false,
					selected: !item.Value && item.Checked
				},
				children: item.Childs.map((child) => {
					return {
						text: child.DisplayValue,
						data: {
							value: child.Value,
						},
						state: {
							opened: true,
							disabled: false,
							selected: false
						},
						children: child.Childs.map((child) => {
							return {
								text: child.DisplayValue,
								data: {
									value: child.Value,
									originValue: child.OriginValue,
								},
								state: {
									opened: true,
									disabled: false,
									selected: child.Checked
								}
							};
						})
					};
				})
			};
		});

		this.RenderTree();
	}

	private RenderTree() {
		const jstreeOptions = {
			plugins: ['checkbox'],
			checkbox : {
				keep_selected_style : false,
				visible: true,
				three_state: true,

			},
			core: {
				data: this._jsTreeModel,
				multiple: true,
				themes: {
					icons: false,
					dots: true
				},
				check_callback: true
			},
			defaults: {
				checkbox: {
					visible: true
				}
			}
		};

		const $jsTreeElement = $(this._dropDown.GetContentElement()).find('#fast-filter-datetime-tree-container');

		if (this._jsTree) {
			$jsTreeElement.jstree(true).settings.core.data = this._jsTreeModel;
			$jsTreeElement.jstree(true).refresh();
		} else {
			this._jsTree = $jsTreeElement.bind('ready.jstree', () => {
				this._dropDown.Position();
			}).jstree(jstreeOptions);

			this._jsTree
				.on('changed.jstree', (event, newtree) => {
					this._jsTreeSaveModel = $jsTreeElement.jstree().get_json();
					this._dropDown.Position();
				});
		}
	}

    protected MapToSaveModel() {
        this.StoreSelectedCheckboxes();
        const valuesList = mapNodes(this._jsTreeSaveModel);
        function mapNodes(nodesList) {
            const nodes = [];

            if (!(nodesList && nodesList.length)) return [];

            nodesList && nodesList.forEach((node) => {
                const mappedNode = {
                    Value: node.data.value || null,
                    OriginValue: node.data.originValue || null
                } as any;

                if (node.children && node.children.length) {
                    mappedNode.Childs = mapNodes(node.children);
                }

                if (node.state.selected || (mappedNode.Childs && mappedNode.Childs.length)) {
                    nodes.push(mappedNode);
                }
            });

            return nodes;
        }

        this._saveModel = {
            FieldAlias: this._model.Alias,
            Values: valuesList,
            FieldId: this._model.FieldMetadata.Id,
            TableViewId: this._tableViewId
		};

        this._allFilters.forEach((filter) => {
            if (filter.FieldAlias === this._model.Alias && filter.TableViewId === this._tableViewId) {
				filter.Values = valuesList;
            }
        });
    }

	protected LoadFastFilterData(dropDownElement?: HTMLElement) {
		this._isOpenFastFilter(!this._isOpenFastFilter());

		if (this.IsItemsLoaded) {
			return Promise.resolve();
		}

		BlockUi.Block({Target: dropDownElement});

		return GridStore.GetFastFilterDateTimeData(this.GetRequestParams())
			.always(() => {
				BlockUi.Unblock(dropDownElement);
			})
			.then((result) => {
				this._availableItems(this.SortYears(this.SortItems(result.Dates)));

				this.MapToViewModel();
				this.IsItemsLoaded = true;
			})
			.fail((error) => {
				new Notifier().Failed(error.message);
			});
	}

	protected SearchChangeCallback(value) {
		this._searchValue = value.toString();
		this._regex = RegExp(value);
		this.StoreSelectedCheckboxes();

		BlockUi.Block();

		const items: FastFilterItem[] =
			this._originalViewModelItems.map((item: FastFilterItem) => {

				const viewModelItem: FastFilterItem = {
					Value: item.Value,
					DisplayValue: item.DisplayValue,
					Childs: item.Childs && item.Childs.map((child) => {

						const viewModelItem: FastFilterItem = {
							Value: child.Value,
							DisplayValue: child.DisplayValue,
							Childs: child.Childs && child.Childs.map((child) => {

								const viewModelItem: FastFilterItem = {
									Value: child.Value,
									DisplayValue: child.DisplayValue
								};
								return viewModelItem;
							})
						};
						return viewModelItem;
					})
				};
				return viewModelItem;
			});

		this._availableItems(this.SortYears(this.SortItems(this.FilterItems(items))));

		this.MapToViewModel();
		this.MapToJstreeModel();

		this.IsItemsLoaded = true;

		BlockUi.Unblock();
	}

	private FilterItems(data: FastFilterItem[]) {

		for (var i = 0; i < data.length; i++) {
			const item = data[i];
			if (!this._regex.test(item.DisplayValue.toLowerCase()) && item.Childs && item.Childs.length) {
				item.Childs = this.FilterItems(item.Childs);
			}
		}

		return data.filter(f => this._regex.test(f.DisplayValue.toLowerCase()) || (f.Childs && f.Childs.length));
	}

	private SortYears(itemsList: FastFilterValue[]) {
		if (!itemsList || !itemsList.length) return [];

		return itemsList.sort((a, b) => {
			const valueA = a.DisplayValue || a.Value;
			const valueB = b.DisplayValue || b.Value;

			if (valueA && valueA > valueB) {
				return -1;
			} else if (!valueA) {
				return -1;
			} else {
				return 1;
			}
		});
	}

	protected Filter() {
		this.MapToSaveModel();

		this.Trigger(EVENTS.FAST_FILTER);
	}

	GetTemplate() {
		return DateTimeFilterTemplate;
	}
}