import * as ko from 'knockout';
import * as _ from 'underscore';

import {CustomFieldControl} from "../CustomFields/Controls/CustomFieldControl";
import {CustomFieldValue} from "../CustomFieldValue";

import Template from './Template.html';

export class CustomFieldsGroup {
    HasContent: KnockoutComputed<boolean>;

    constructor(public CustomFieldsControls: CustomFieldControl[]) {
        this.HasContent = ko.computed(() => _.any(this.CustomFieldsControls, control => control.HasContent()));
    }

    GetTemplate() {
        return Template;
    }

    GetCustomFieldValuesForPrice(): CustomFieldValue[] {
		return this.CustomFieldsControls.filter(control => control.PriceInfluence).map((control: CustomFieldControl) => control.GetValueForPrice());
    }

    GetCustomFieldValues(): CustomFieldValue[] {
        return this.CustomFieldsControls.map((control: CustomFieldControl) => control.GetValueForPrice());
    }

    ClearCustomFieldValues() {
        this.CustomFieldsControls.forEach(control => control.ClearValue());
    }

    get IsHiddenByAction(): boolean {
        return _.every(this.CustomFieldsControls, control => control.IsHiddenByAction);
    }
}