import { dia as Dia, shapes } from 'libs/rappid/build/rappid';

shapes.basic.Rect.define('cyberThing.Source',
	{
		type: 'cyberThing.Source',
		attrs: {
			rect: {
				stroke: 'none',
				'fill-opacity': 0
			}
		}
	}
);