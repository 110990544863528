import { dia as Dia, shapes, V } from 'libs/rappid/build/rappid';

shapes.basic.Rect.define('cyberThing.CSharpFunction',
	{
		type: 'cyberThing.CSharpFunction',
		attrs: {
			rect: {
				stroke: 'none',
				'fill-opacity': 0.3
			},
			text: {
				fill: '#383838'
			}
		}
	}
);