export class SaveReservationRequestModel {
    Id: number;
    AgendaId: number;
    Start: string;
    TimeSpent: string;
    Subject: SaveSubjectRequestModel;
    Description: string;
    OverWorkIndicatorId: number;
    PaymentIndicatorId: number;
    HourKindIndicatorId: number;

    TimeTableId: number;
    UserId: number;

    OptionalData: SaveOptionalDataRequestModel[];

    constructor() {
        this.OptionalData = [];
    }
}

export class SaveSubjectRequestModel {
    EntityId: number;
    SubjectId: number;
}

export class FieldDataTranslation {
    constructor(public Language: LanguageData,
    public Translation: string){

    }
}

export class LanguageData {
    constructor(public K_Language: number,
        public Name: string,
        public ShortName: string){

    }
}

export class SaveOptionalDataRequestModel {
    constructor(public FieldName: string, public FieldValue: string, public Translations: FieldDataTranslation[]) {

    }
}