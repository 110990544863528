import * as ko from 'knockout';
import * as _ from 'underscore';

import { TranslationCell } from "Pages/TransaltionEditorPage/TranslationEditor/TranslationCell";
import { TranslationModel } from "Pages/TransaltionEditorPage/Models/TranslationModel";
import { TranslationActionCell } from 'Pages/TransaltionEditorPage/TranslationEditor/TranslationActionCell';
import { ICell } from 'Pages/TransaltionEditorPage/TranslationEditor/ICell';
import { EVENTS } from 'Pages/TransaltionEditorPage/TranslationEditor/Events';
import { UpdateTranslationModel } from 'Pages/TransaltionEditorPage/Models/UpdateTranslationModel';
import { IDictionary } from 'Core/Screens/BaseScreen';
import { TranslationEditorStore } from 'Pages/TransaltionEditorPage/Stores/TranslationEditorStore';
import { IKeyValuePair } from 'Core/Portlets/Utils/GuidToPortletInfoMap';
import { NOTIFICATIONS, LABELS, CONFIRMATIONS } from "Core/Components/Translation/Locales";
import { Notifier } from 'Core/Common/Notifier';
import { BlockUI } from 'Core/Common/BlockUi';

import Template from 'Pages/TransaltionEditorPage/TranslationEditor/Templates/TranslationRow.html';

export class TranslationRow {
	private _cells: KnockoutObservableArray<ICell>;
	private _model: TranslationModel;

	constructor(model: TranslationModel) {
		this._model = model;
		this._cells = ko.observableArray([]);
		this.Init();
	}

	Init() {
		var editCells = [];
		var actionCell = new TranslationActionCell();

		actionCell.On(EVENTS.EDIT, this, (eventArgs) => {
			_.each(editCells, (cell: TranslationCell) => {
				cell.IsEditMode = true;
			});
		});

		actionCell.On(EVENTS.CANCEL, this, (eventArgs) => {
			_.each(editCells, (cell: TranslationCell) => {
				cell.CancelEdit();
			});
		});

		actionCell.On(EVENTS.SAVE, this, (eventArgs) => {
			var updateModel = new UpdateTranslationModel();
			updateModel.Id = this._model.Id;
			
			_.each(editCells, (cell: TranslationCell) => {
				var translations: IKeyValuePair<string, string> = {
					Key: cell.FieldName,
					Value: cell.GetEditValue()
				};
				cell.IsEditMode = false;
				updateModel.Translations.push(translations);
			});

			BlockUI.Block();
			TranslationEditorStore.Update(updateModel)
				.always(() => {
					BlockUI.Unblock();
				})
				.then(() => {
					new Notifier().Success(NOTIFICATIONS.RECORD_SAVED);
				})
				.fail((err) => {
					new Notifier().Failed(err.message);
				})
		});

		var codeCell = new TranslationCell('CODE', this._model.Code.toString());
		var nameCell = new TranslationCell('NAME', this._model.Name);

		this._cells.push(actionCell);
		this._cells.push(codeCell);
		this._cells.push(nameCell);

		_.each(this._model.Translations, (translation) => {
			var cell = new TranslationCell(translation.Key, translation.Value);
			this._cells.push(cell);
			editCells.push(cell);
		});
	}

	GetTemplate() {
		return Template;
	}

	AfterRender() {

	}

	get Cells(): KnockoutObservableArray<ICell> {
		return this._cells;
	}
}