import {deserialize} from 'libs/cerialize';
import {deserializeAs} from 'libs/cerialize';

export class Dashboard{
    
    @deserialize
    Id: string;

    @deserialize
    Name: string;
    
}