import {RowEditor} from "Core/Components/Controls/TimeWriting/Utils/RowEditor";

export class DisapprovedEditor {
    RowEditor: RowEditor;
    DisapprovedInfo: DisapprovedInfo;

    constructor(disapprovedInfo: DisapprovedInfo, rowEditor: RowEditor) {
        this.RowEditor = rowEditor;
        this.DisapprovedInfo = disapprovedInfo;
    }
}

export class DisapprovedInfo {
    DisapprovedReason: string;
    Comment: string;
    BookedCounter: number;
    WeekNumber: number;
    Date: string;

    constructor(disapprovedReason: string, comment: string, bookedCounter: number, weekNumber: number, date: string) {
        this.DisapprovedReason = disapprovedReason;
        this.Comment = comment;
        this.BookedCounter = bookedCounter;
        this.WeekNumber = weekNumber;
        this.Date = date;
    }
}