import * as ko from 'knockout';

import {Notifier} from 'Core/Common/Notifier';
import {BlockUI} from 'Core/Common/BlockUi';

import {GlobalManager, GLOBALS} from 'Core/GlobalManager/GlobalManager';

import { ScreenTypes } from 'Core/Common/Enums/ScreenTypes';

import {LABELS, NOTIFICATIONS} from 'Core/Components/Translation/Locales';
import {Paginator} from 'Core/Components/Paginator/Paginator';

import {EVENTS as PAGINATOR_EVENTS} from 'Core/Components/Paginator/Constants';
import {EVENTS as BASE_GRID_EVENTS} from 'Core/Controls/Grid/BaseGrid/Events';
import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';

import { BaseGrid } from 'Core/Controls/Grid/BaseGrid/BaseGrid';
import { GridDataModel as BaseGridDataModel } from 'Core/Controls/Grid/Models/GridDataModel/GridDataModel';
import {DEFAULT_RECORDS_PER_PAGE} from 'Core/Controls/Grid/Constants';
import {IBaseGridOptions} from 'Core/Controls/Grid/Models/GridDataModel/BaseGridModel';
import {GridStore, IGetGridDataRequestModel} from 'Core/Controls/Grid/Stores/GridStore';
import {ViewModes} from 'Core/Controls/Grid/BaseGrid/Enums/ViewModes';
import {LockQueryStore} from 'QueryBuilder/Stores/LockQueryStore';

import {ITabTableParams} from '../TabTables/ITabTablesParams';

import {GridDataModel} from '../../Models/DataModel';

import {IGetTabGridDataParams, TabGridStore} from './Stores/TabGridStore';

import { UserManager, UserRoles } from 'User/UserManager';
import { RecordLinker } from 'Core/Components/RecordLinker/RecordLinker';

import {ActionSubjectRecordModel} from 'Core/ScreenManager/Models/ActionSubjectRecordModel';
import { SearchScreen, ISelectedRecord} from 'Core/Screens/SearchScreen/SearchScreen';
import {TypeScreen} from 'Core/Screens/TypeScreen/TypeScreen';
import {EditScreen} from 'Core/Screens/EditScreen/EditScreen';
import {BaseScreen} from 'Core/Screens/BaseScreen';
import {QueryBuilder as QueryBuilderControl} from 'Core/Controls/QueryBuilder/QueryBuilder';
import {CONTROL_TYPES} from 'Core/Constant';
import {ViewGridModel} from 'Core/Controls/Grid/Models/ViewGridModel';
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';
import {IGetLinkEditorGridViewListDto, ViewGridListStore} from 'Core/Controls/Grid/Stores/ViewGridListStore';
import {LOCK_EVENTS, LockManager} from 'Core/Components/Locker/LockManager';
import {SortModel} from 'Core/Controls/Grid/Models/SortModel';
import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS,
    Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import {DeleteQueryStore} from 'QueryBuilder/Stores/DeleteQueryStore';
import { BarcodeScanner } from 'Core/Components/BarcodeScanner/BarcodeScanner';

import Template from './Templates/TabGrid.html'
import { Guid } from 'Core/Common/Guid';
import { TriggerStore } from 'Core/Components/Triggers/TriggerStore';
import { IOnLinkEditorGridSaveTrigger, IOnLinkEditorUnlinkTrigger, TriggerManager } from 'Core/Components/Triggers/TriggerManager';
import { ILinkEditor } from '../../LinkEditor';
import { FunctionBuilder } from 'Core/Components/CustomFunctions/FunctionBuilder';
import { linkEditorTabGridFacade } from 'Core/Components/CustomFunctions/Facades/LinkEditorTabGridFacade';

export class TabGrid {
    private _labels = LABELS;

    private _dataLoaded: boolean;
    private _prevPageNumber: number;

    private _isEditable: KnockoutObservable<boolean>;
    private _showAddButton: KnockoutObservable<boolean>;
    private _showAddAndLinkButton: KnockoutObservable<boolean>;
    private _showScanAndLinkButton: KnockoutObservable<boolean>;
    private _showLinkButton: KnockoutObservable<boolean>;

    private _el: HTMLElement;

    private _store: TabGridStore;

    private _paginator: Paginator;
    private _baseGrid: BaseGrid;
    private _isValid: KnockoutObservable<boolean>;
    private _errorMessage: KnockoutObservable<string>;
    private _sorting: Array<SortModel>;

    
    private _tableView: KnockoutObservable<ViewGridModel> = ko.observable(null);
    private _showTableViewElement: KnockoutObservable<boolean> = ko.observable(true);
    private _showTableViewLookup: KnockoutObservable<boolean> = ko.observable(true);
    private _showFunctionLookup: KnockoutObservable<boolean> = ko.observable(true);
    private _isEnabled: KnockoutObservable<boolean> = ko.observable(true);
    private _viewGridList: KnockoutObservableArray<ViewGridModel> = ko.observableArray([]);
    private _isAddingRecordAllowed : KnockoutObservable<boolean> = ko.observable(true);
    private _enableEditTableView: KnockoutObservable<boolean> = ko.observable(false);
    private _enableDeleteTableView: KnockoutObservable<boolean> = ko.observable(false);
    private _enableLockItem: KnockoutObservable<boolean> = ko.observable(false);
    private _exportDataAllowed: KnockoutObservable<boolean> = ko.observable(false);
    private _showLockItem: boolean = UserManager.Instance.IsUserInRole(UserRoles.SuperUser);
    private _userVarsManager: UserVarsManager;
    private _isTableViewUpdating: KnockoutObservable<boolean>;
    private _tableViewUpdatingSubscription: KnockoutSubscription;
    private _showEditAllButton: KnockoutObservable<boolean>;
    private _editAll: KnockoutObservable<boolean>;
    private _guid: string;
    private _isDataLoading: KnockoutObservable<boolean>;

    constructor(private _params: ITabTableParams, private _screen: ILinkEditor) {
        this._dataLoaded = false;
        this._prevPageNumber = 1;

        this._isEditable = ko.observable(false);
        this._showAddButton = ko.observable(false);
        this._showAddAndLinkButton = ko.observable(false);
        this._showLinkButton = ko.observable(false);
        this._isValid = ko.observable(true);
        this._errorMessage = ko.observable('');
        this._store = new TabGridStore();
        this._viewGridList = ko.observableArray([]);
        this._userVarsManager = UserVarsManager.Instance;

        this._paginator = new Paginator();
        this._baseGrid = new BaseGrid(this.GetGridOptions());
        this._isTableViewUpdating = ko.observable(false);
        this._showScanAndLinkButton = ko.observable(false);
        this._showEditAllButton = ko.observable(true);
        this._editAll = ko.observable(true);
        this._guid = Guid.NewGuid();
        this._isDataLoading = ko.observable(false);

        this.Init();
        this.BindEvents();

        this._tableView.subscribe((newValue) => {
            this.SaveTableViewId(newValue.Id);
            this._enableEditTableView(newValue.IsEditingAllowed);
            this._enableDeleteTableView(newValue.IsDeletingAllowed);
            this._enableLockItem(this._showLockItem && this._tableView().Id > 0);
            this.LoadData();
        });

        this.UpdateTableViewList();

    }

    get IsValid(): boolean {
        this._baseGrid.SaveEditRows();

        if (this._baseGrid && this._baseGrid.HasEditRows()) {
            this._errorMessage('Please save record');
            this._isValid(false);
        } else {
            this._errorMessage('');
            this._isValid(true);
        }
        return this._isValid();
    }

    get ShowTableViewList(){
        let isSubTable = this._baseGrid.DataModel.QueryExpression.SubEntityJoins.length > 0;
        return !isSubTable;
    }

    get IsEditable() {
        const isSubTableView =
            this._baseGrid.DataModel.QueryExpression.SubEntityJoins.length > 0
            && this._baseGrid.DataModel.QueryExpression.SubEntityJoins[0].Entity.Metadata.IsView;

        return !isSubTableView;
    }

    get ShowAddButton() {
        if(this._params.LookupFieldId){
            return true;
        }

        const isSubTable = this._baseGrid.DataModel.QueryExpression.SubEntityJoins.length > 0;

        if (!isSubTable) {
            return false;
        }

        const isSubTableView = this._baseGrid.DataModel.QueryExpression.SubEntityJoins[0].Entity.Metadata.IsView;
        return !isSubTableView;
    }

    get ShowAddAndLinkButton() {
        const isEntity = this._baseGrid.DataModel.QueryExpression.EntityJoins.length > 0;

        if (isEntity) {
            return true;
        }

        if(this._params.LookupFieldId){
            return true;
        }

        return false;
    }

    get ShowLinkButton() {
        const isEntity = this._baseGrid.DataModel.QueryExpression.EntityJoins.length > 0;

        if (!isEntity) {
            return false;
        }

        return !this._params.LookupFieldId;        
    }

    ToggleEditAll(){
        if(this._editAll()){
            this._baseGrid.EditAll();
            this._editAll(false);
        }else{
            this._baseGrid.SaveAll();
            this._editAll(true);
        }
    }

    Init() {
        this.InitRecordPerPage();
    }

    ToggleLock() {
        if (this._tableView().Locked) {
            this.UnlockTableView();
        } else {
            this.LockTableView();
        }
    }

    LockTableView() {
        if (this._tableView().Id > 0) {    
            LockQueryStore.Lock({Id: this._tableView().Id})
                .always(() => {

                })
                .then(() => {
                    this._tableView().Lock();
                });
        }
    }

    UnlockTableView() {
        if (this._tableView().Id > 0) {
            LockQueryStore.Unlock({Id: this._tableView().Id})
                .always(() => {

                })
                .then(() => {
                    this._tableView().Unlock();
                });
        }
    }

    BindEvents() {
        this._paginator.On(PAGINATOR_EVENTS.CHANGE, this, (eventArgs: any) => {
            this.LoadData();
            this._prevPageNumber = eventArgs.data.pageNumber;
        });

        this._baseGrid.On(BASE_GRID_EVENTS.OPEN_HYPERLINK, this, (eventArgs: any) => {
            UserVarsManager.Instance.AddRecent(eventArgs.data.EntityId, eventArgs.data.RecordId, eventArgs.data.RecordTypeId);
            this.GoToRecordScreen(eventArgs.data);
        });

        this._baseGrid.On(BASE_GRID_EVENTS.SORT, this, (eventArgs: any) => {
            this._sorting = eventArgs.data.SortColumns;
            this.LoadData();
        });

        this._baseGrid.On(BASE_GRID_EVENTS.UNDO_EVERY_RECORD, this, () => {
	        this._editAll(true);
        });

        this._baseGrid.On(BASE_GRID_EVENTS.RECORD_DELETED, this, async() => {
	        await this.ExecuteOnLinkEditorUnlinkTrigger();
        });

        this._baseGrid.On(BASE_GRID_EVENTS.UNLINK_RECORD, this,  async() => {
	        await this.ExecuteOnLinkEditorUnlinkTrigger();
        });

        this._baseGrid.On(BASE_GRID_EVENTS.RECORD_SAVED, this, async () => {

            let options: IOnLinkEditorGridSaveTrigger = {
                LeftEntityId: this._screen.LinkLeftEntityId,
                RightEntityId: this._screen.LinkRightEntityId,
                RelationTypeId: this._screen.RelationTypeId,
                GridEntityId: this._params.TableId
            }
            this._isDataLoading(true);
	        await TriggerManager.ExecuteOnLinkEditorGridSaveTrigger(options, this._guid);            
            this._isDataLoading(false);
        });
        
        PubSub.subscribe(PUB_SUB_EVENTS.EXECUTE_SCRIPT, (_, data) => {
            if(data && data.ObjectId === this._guid){
                 FunctionBuilder.Execute(data.Code, data.Params, linkEditorTabGridFacade(this._baseGrid, this._screen));
            }
         });
    }

    async ExecuteOnLinkEditorUnlinkTrigger(){
        let options: IOnLinkEditorUnlinkTrigger = {
            LeftEntityId: this._screen.LinkLeftEntityId,
            RightEntityId: this._screen.LinkRightEntityId,
            RelationTypeId: this._screen.RelationTypeId,
            GridEntityId: this._params.TableId
        }
        this._isDataLoading(true);
        await TriggerManager.ExecuteOnLinkEditorUnlinkTrigger(options, this._guid);            
        this._isDataLoading(false);
    }

    GoToRecordScreen(data: any) {
        data.IsOpenInModal = true;
        PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, data);
    }


    InitRecordPerPage() {
        const recordsPerPageGlobalValue = GlobalManager.Instance.GetGlobal(GLOBALS.CONSULT_GRID_PAGE_LINES);
        this._paginator.RecordsPerPage = parseInt(recordsPerPageGlobalValue) || DEFAULT_RECORDS_PER_PAGE;
    }

    GetTemplate() {
        return Template;
    }

    AfterRender(el: HTMLElement) {
        this._el = el;
    }

    Serialize(): GridDataModel {
        const dataModel = new GridDataModel();

        dataModel.Rows = this._baseGrid.SerializeRows();

        _.each(dataModel.Rows, (item)=>{
            item.ReferenceLookupFieldId = this._params.LookupFieldId;
            item.ScopedRecordId = this._params.ScopedRecordId;
            item.ScopedTableId = this._params.ScopedTableId;
        });

        dataModel.LinkListChanges = this._baseGrid.SerializeLinkEditorData();
        dataModel.LinkListChanges.forEach(linkEditor => {
            linkEditor.MainEntityId = this._baseGrid.Model.SubjectEntityId;
            linkEditor.MainRecordId = this._baseGrid.Model.SubjectRecordId;
            linkEditor.ReferenceLookupFieldId = this._params.LookupFieldId;
        });

        return dataModel;
    }

    NewRecord() {
        this._baseGrid.SelectType();
    }

    AddAndLinkRecord() {
        const selfRelation = this._params.TargetTableId === this._params.TableId;
        const typeScreen = new TypeScreen(this._params.TableId,
            selfRelation && !this._params.LookupFieldId ? this._params.RecordTypeId : null,
            false);

        typeScreen
            .On('TYPE_SELECTED', this, async eventArgs => {
                const typeId = eventArgs.data.TypeId;
                const kindId = eventArgs.data.KindId;
                const exampleRecordId = eventArgs.data.ExampleRecordId;

                const globalManager = GlobalManager.Instance;
                const actionGlobal = globalManager.GetGlobal(GLOBALS.ACTION_TABLE);
                const actionSubjectRecord = this._params.TableName === actionGlobal
                    ? new ActionSubjectRecordModel(this._params.TargetTableId, this._params.RecordId)
                    : null;

                this.BlockUI();
                const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

                screenManager.GetEditScreen({
                    EntityId: this._params.TableId,
                    TableTypeId: typeId,
                    KindId: kindId,
                    RecordId: exampleRecordId,
                    LoadAsExample: exampleRecordId > 0,
                    SubjectLifestatusId: this._params.LifeStatusId,
                    ActionSubjectRecord: actionSubjectRecord
                }).fail(error=>{
                    new Notifier().Failed(error.message);
                })
                .then(screen => {
                    const editScreen = screen as EditScreen;
                    editScreen.IsDataFromExample = exampleRecordId > 0;

                    screen.On('RECORD_SAVED', this, (eventArgs) => {
                        const createdRecordId = eventArgs.data.RecordId;
                        this.LinkSelectedRecord([createdRecordId]);
                    });

                    this.UnblockUI();
                    screen.ShowInModal();
                }).always(() => {
                    this.UnblockUI()
                });
            })
            .On('TYPES_NOT_FOUND', this, (eventArgs) => new Notifier().Warning(eventArgs.data.Message || NOTIFICATIONS.SUB_TYPE_NOT_FOUND));

        typeScreen.Show();
    }

    LinkRecord() {
        const searchScreen = new SearchScreen({
            EntityId: this._params.TableId,
            SearchTerm: '',
            ButtonAdd: true,
            HasLinkingCondition: false,
            EnableBulkScan: true,
            MultiSelectMode: true,
        });

        let isBulkBarcodeScanning = false;

        searchScreen.On('RECORD_SELECTED', this, eventArgs => {
            const selectedRecordId = eventArgs.data.RecordId;
            this.LinkSelectedRecord([selectedRecordId], {
                isBulkBarcodeScanning,
                onAfterLinking: () => {
                    searchScreen.OpenBarcodeScannerForBulkScan();
                }
            });
        });

        searchScreen.On('RECORDS_SELECTED',
            this,
            eventArgs => {
                this.PrepareRecordsToSelect({
                    linkedRecordIds: eventArgs.data.Ids,
                    isBulkBarcodeScanning: isBulkBarcodeScanning,
                    onAfterLinking: () => {
                        searchScreen.OpenBarcodeScannerForBulkScan();
                    }
                });
            });

        searchScreen.On('BULK_BARCODE_SCAN_STARTED', this, () => {
            isBulkBarcodeScanning = true;

            searchScreen.OpenBarcodeScannerForBulkScan();
        });

        searchScreen.On('BULK_BARCODE_SCAN_STOPPED', this, () => {
            isBulkBarcodeScanning = false;
        });

        searchScreen.On('NEW_RECORD', this, () => {
            searchScreen.Close();
            this.AddAndLinkRecord();
        });

        searchScreen.Show();
    }


    private PrepareRecordsToSelect({ linkedRecordIds, isBulkBarcodeScanning, onAfterLinking }: {
        linkedRecordIds: number[];
        isBulkBarcodeScanning: boolean;
        onAfterLinking?: () => void;
    }) {
        let kSeqValue = _.find(this._baseGrid.Model.QueryExpression.EntityJoins[0].LinkEntity.Metadata.Fields,
            field => field.Name === 'K_SEQ');
        if (kSeqValue) {
            if (isBulkBarcodeScanning) {
                BlockUI.Block();
            }
            this.LinkSelectedRecord(linkedRecordIds, {
                isBulkBarcodeScanning,
                onAfterLinking: onAfterLinking
            });
        } else {
            let deletedRows = this._baseGrid.GetUnlinkedGridRowsByRecordIds(linkedRecordIds);

            if (isBulkBarcodeScanning) {
                BlockUI.Block();
            }
            
            RecordLinker.GetExistingLinksWithRecords({
                MainTableId: this._params.TargetTableId,
                MainRecordId: this._params.RecordId,
                LinkedTableId: this._params.TableId,
                LinkedRecordIds: linkedRecordIds
            })
                .always(() => {
                    if (isBulkBarcodeScanning) {
                        BlockUI.Unblock();
                        onAfterLinking();
                    }
                })
                .then(result => {
                    if (!result.IsSuccessfull) {
                        new Notifier().Failed(result.ErrorMessage);
                        return;
                    }

                    _.each(result.ResultObject, (id) => {
                        let deletedRow = _.find(deletedRows, (row) => row.RecordId === id)
                        if (deletedRow) {
                            deletedRow.LinkEditorData.DeletedRelations = _.reject(deletedRow.LinkEditorData.DeletedRelations,
                                (item) => item.EntityId === deletedRow.EntityId
                                    && item.RecordId === deletedRow.RecordId
                                    && item.KSeq === deletedRow.KSeq);

                            this._baseGrid.AddRow(deletedRow);

                            setTimeout(() => {
                                this._baseGrid.ResizeTableCellsAfterRender();
                            }, 0);
                        }
                    });

                    let newLinks = _.difference(linkedRecordIds, result.ResultObject);
                    this.LinkSelectedRecord(newLinks, {
                        isBulkBarcodeScanning,
                        onAfterLinking: onAfterLinking
                    });
                });
        }
    }

    private LinkSelectedRecord(
        recordIds: number[],
        { isBulkBarcodeScanning, onAfterLinking }: { isBulkBarcodeScanning?: boolean; onAfterLinking?: () => void } = {}
    ) {
        const requestModel: IGetGridDataRequestModel = {
            ControlId: 0,
            TableViewId: this._tableView() ? this._tableView().Id : 0,
            SubjectEntityId: this._params.TargetTableId,
            SubjectRecordId: this._params.RecordId,
            SubjectTypeId: this._params.RecordTypeId,
            SubjectKindId: 0,
            SubjectStatusId: this._params.LifeStatusId,
            ViewMode: ViewModes.TableView,
            PageNumber: this._paginator.PageNumber,
            RecordsPerPage: this._paginator.RecordsPerPage,
            Sort: [],
            SearchPhrase: '',
            FilterByOwners: false,
            RecordOwners: [],
            ShowPlanned: false,
            SelectedTags: [],
            GridSubjectEntityId: this._params.TableId,
            GridSubjectRecordIds: recordIds,
            ReferenceLookupFieldId: this._params.LookupFieldId,
            ScopedRecordId: this._params.ScopedRecordId,
            ScopedTableId: this._params.ScopedTableId
        };

        if (isBulkBarcodeScanning) {
            BlockUI.Block();
        }
        GridStore.GetData(requestModel)
            .always(() => {
                if (isBulkBarcodeScanning) {
                    BlockUI.Unblock();
                    onAfterLinking();
                }
            })
            .then(gridDataModel => {
                this._baseGrid.AddLinkedRows(gridDataModel);
            });
    }

    private LoadData() {
        const getData = () => {
            this.BlockUI();
            this._store.GetGridData(this.GetLoadData())
                .then(gridDataModel => {
                    gridDataModel.SubjectEntityId = this._params.TargetTableId;
                    gridDataModel.SubjectRecordId = +gridDataModel.QueryExpression.Condition.Items[0].Value;

                    this._paginator.TotalRecords = gridDataModel.TotalRecords;
                    this._baseGrid.SetData(gridDataModel, true, true);
                    this._isEditable(this.IsEditable);
                    this._showAddButton(this.ShowAddButton);
                    this._showAddAndLinkButton(this.ShowAddAndLinkButton);
                    this._showLinkButton(this.ShowLinkButton);
                    this._showScanAndLinkButton(gridDataModel.IsScanAndLinkAllowed);

                    this._dataLoaded = true;

                    this._showTableViewElement(this.ShowTableViewList);
                    this._showTableViewLookup(this.ShowTableViewList);
                    this._showFunctionLookup(this.ShowTableViewList);

                    this.UnblockUI();
                })
                .fail(err => {
                    new Notifier().Failed(err.message);
                    this.UnblockUI();
                });
        }

        if (this._isTableViewUpdating()) {
            if (this._tableViewUpdatingSubscription) {
                this._tableViewUpdatingSubscription.dispose();
            }
            this._tableViewUpdatingSubscription = this._isTableViewUpdating.subscribe((value) => {
                if (!value) {
                    getData();
                }
            });
        } else {
            getData();
        }
    }

    private ScanAndLinkRecord(){
        let isBulkBarcodeScanning = true;
        const barcodeScanner = new BarcodeScanner({ isContinuous: false });

        barcodeScanner.On('SUBMIT', this, eventArgs => {
            const { value } = eventArgs.data;

            GridStore.SearchByBarcode({ EntityId: this._params.TableId, Barcode: value })
                .then(linkedRecordId => {
                    this.LinkSelectedRecord([linkedRecordId], {
                        isBulkBarcodeScanning,
                        onAfterLinking: () => {}
                    });
                })
                .fail(error => {
                    barcodeScanner.Reenable();
                    new Notifier().Failed(error.message);
                });
        });

        barcodeScanner.Show();
    }

    private BlockUI() {
        BlockUI.Block({Target: this._el});
    }

    private UnblockUI() {
        BlockUI.Unblock(this._el);
    }

    private GetGridOptions(): IBaseGridOptions {
        return {
            isEditable: this._isEditable,
            screenType: ScreenTypes[ScreenTypes.EditScreen]
        };
    }

    private GetLoadData(): IGetTabGridDataParams {
        return {
            TableViewId: this._tableView() ? this._tableView().Id : 0,
            TableId: this._params.TableId,
            TargetTableId: this._params.TargetTableId,
            LookupFieldId: this._params.LookupFieldId,
            RecordId: this._params.RecordId,
            PageNumber: this._paginator.PageNumber,
            RecordsPerPage: this._paginator.RecordsPerPage,
            Sort: this._sorting,
            ScopedRecordId: this._params.ScopedRecordId,
            ScopedTableId: this._params.ScopedTableId
        };
    }

    DeleteTableView() {
        if (this._tableView().Id > 0) {
            const confirmationDialog = new ConfirmationDialog({
                Text: 'Are you sure?',
                Type: ConfirmationTypes.Question,
                TextConfirm: 'Yes',
                TextDecline: 'Cancel'
            });

            confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
                BlockUI.Block();
                DeleteQueryStore.Delete({Id: this._tableView().Id})
                    .always(() => {
                        BlockUI.Unblock();
                    })
                    .then(() => {
                        this.UpdateTableViewList();
                    });
            });
            confirmationDialog.Show();
        }
    }

    EditTableView(){
        if (this._tableView().Id > 0) {
            BlockUI.Block();

            GridStore.GetDefaultTableViewMetaData(ScreenTypes.LinkEditor)
                .then(data => {

                    LockManager.Instance.TryLock(data.TableId, this._tableView().Id)
                        .then(async() => {

                            BlockUI.Block();
                            const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

                            screenManager.GetEditScreen({
                                EntityId: data.TableId,
                                TableTypeId: data.TableTypeId,
                                RecordId: this._tableView().Id
                            })
                                .always(() => {
                                    BlockUI.Unblock();
                                })
                                .fail(error => {
                                    let notifier = new Notifier($(this._el));
                                    notifier.Warning(error.message);
                                })
                                .then((screen) => {
                                    LockManager.Instance.On(LOCK_EVENTS.RELEASED, this, (eventArgs) => {
                                        if (eventArgs.data.TableId === data.TableId && eventArgs.data.RecordId === this._tableView().Id) {
                                            screen.Close();
                                        }
                                    });
                                    screen.On('MODAL_CLOSE', this, () => {
                                        LockManager.Instance.ReleaseLock(data.TableId, this._tableView().Id);
                                    });

                                    const editScreen = screen as BaseScreen;

                                    editScreen.ShowInModal();

                                    editScreen.On('RECORD_DELETED', this, (eventArgs) => {
                                        this.UpdateTableViewList();
                                    });

                                    const queryBuilderControl = editScreen.GetControl(CONTROL_TYPES.QueryBuilder) as QueryBuilderControl;

                                    if (queryBuilderControl == null) {
                                        const notifier = new Notifier($(this._el));

                                        notifier.Failed(NOTIFICATIONS.QUERY_BUILDER_NOT_FOUND);
                                    } else {
                                        queryBuilderControl.SetSubjectRecordId(this._params.RecordId);
                                        queryBuilderControl.SetQueryTypeName(ViewModes.TableView);

                                        editScreen.On('RECORD_SAVED', this, (eventArgs) => {
                                            this.UpdateTableViewList();
                                        });
                                    }
                                });
                        });
                })
                .fail(err => {
                    const notifier = new Notifier($(this._el));

                    notifier.Failed(err.message);
                })
                .always(() => {
                    BlockUI.Unblock();
                });
        }
    }

    NewTableView() {
        BlockUI.Block();
        GridStore.GetDefaultTableViewMetaData(ScreenTypes.LinkEditor)
            .then(async(data) => {
                const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

                screenManager.GetEditScreen({
                    EntityId: data.TableId,
                    TableTypeId: data.TableTypeId
                }).always(() => {
                    BlockUI.Unblock();
                }).fail(error => {
                        let notifier = new Notifier($(this._el));

                        notifier.Warning(error.message);
                }).then(screen => this.ShowQueryEditScreen(screen as BaseScreen));
                    
            })
            .fail(err => {
                new Notifier($(this._el)).Failed(err.message);
            })
            .always(() => {
                BlockUI.Unblock();
            });
    }

    CopyTableView() {
        if (this._tableView().Id > 0) {
            BlockUI.Block();

            GridStore.GetDefaultTableViewMetaData(ScreenTypes.LinkEditor)
                .then(async(data) => {
                    BlockUI.Block();

                    const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
                    screenManager.GetEditScreen({
                        EntityId: data.TableId,
                        TableTypeId: data.TableTypeId,
                        RecordId: this._tableView().Id,
                        LoadAsExample: true
                    })
                        .always(() => {
                            BlockUI.Unblock();
                        })
                        .fail(error => {
                            let notifier = new Notifier($(this._el));
                            notifier.Warning(error.message);
                        })
                        .then((screen) => {
                            const editScreen = screen as EditScreen;
                            editScreen.IsDataFromExample = true;
                            editScreen.ShowInModal();

                            const queryBuilderControl = editScreen.GetControl(CONTROL_TYPES.QueryBuilder) as QueryBuilderControl;

                            if (queryBuilderControl == null) {
                                const notifier = new Notifier($(this._el));

                                notifier.Failed(NOTIFICATIONS.QUERY_BUILDER_NOT_FOUND);
                            } else {
                                queryBuilderControl.SetSubjectRecordId(this._params.RecordId);
                                queryBuilderControl.SetQueryTypeName(ViewModes.TableView);

                                editScreen.On('RECORD_SAVED', this, (eventArgs) => {
                                    this.SaveTableViewId(eventArgs.data.RecordId);
                                    this.UpdateTableViewList();
                                });
                            }
                        });
                })
                .fail(err => {
                    const notifier = new Notifier($(this._el));

                    notifier.Failed(err.message);
                    BlockUI.Unblock();
                });
        }
    }

    private SaveTableViewId(id: number){
        let key = `${this._params.TableId}_${this._params.TargetTableId}_${this._params.LookupFieldId}`;
        this._userVarsManager.SetLinkEditorGridView(key, id);
    }

    private GetTableViewId(){
        let key = `${this._params.TableId}_${this._params.TargetTableId}_${this._params.LookupFieldId}`;
        return this._userVarsManager.GetLinkEditorGridView(key);
    }

    private ShowQueryEditScreen(editScreen: BaseScreen){
        editScreen.ShowInModal();
                        
        this._store.GetDefaultTableView({
            TableId: this._params.TableId,
            TargetTableId: this._params.TargetTableId,
            LookupFieldId: this._params.LookupFieldId
        }).then(data => {
                const queryBuilderControl = editScreen.GetControl(CONTROL_TYPES.QueryBuilder) as QueryBuilderControl;

                if (queryBuilderControl == null) {
                    const notifier = new Notifier($(this._el));

                    notifier.Failed(NOTIFICATIONS.QUERY_BUILDER_NOT_FOUND);
                } else {
                    queryBuilderControl.SetSubjectEntityId(this._params.TargetTableId);
                    queryBuilderControl.SetSubjectRecordId(this._params.RecordId);
                    queryBuilderControl.SetQueryTypeName(ViewModes.TableView);

                    queryBuilderControl.InitQuery(data);

                    editScreen.On('RECORD_SAVED', this, (eventArgs) => {
                       this.SaveTableViewId(eventArgs.data.RecordId);
                       this.UpdateTableViewList();
                    });
                }
            });
    }

    private UpdateTableViewList(){
        this._isTableViewUpdating(true);

        ViewGridListStore.GetLinkEditorGridViewList({
            TableId: this._params.TableId,
            TargetTableId: this._params.TargetTableId,
            LookupFieldId: this._params.LookupFieldId
        })
        .always(()=>{
            this._isTableViewUpdating(false);
        })
        .then(data => {
            
            let defaultView = new ViewGridModel(0, LABELS.DEFAULT_VIEW);
            defaultView.IsEditingAllowed = false;
            let tableViewList = [defaultView];
            data.GridViews.forEach(view => view.Init());
            tableViewList = data.GridViews ? tableViewList.concat(data.GridViews) : tableViewList;
            this._viewGridList(tableViewList);

            let tableViewId = this.GetTableViewId();
            if(tableViewId){
                let tableView = _.find(this._viewGridList(), (view) => view.Id == tableViewId) || defaultView;
                this._tableView(tableView);
            }else{
                this._tableView(defaultView);
            }
            
        });
    }
}