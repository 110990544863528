import * as ko from 'knockout';
import * as _ from 'underscore';

import { Icon } from "Core/Icon/Icon";
import { DEFAULT_ICONS } from "Core/Constant";

import { IControlParam } from 'Core/Screens/IScreen';
import { RenderModes } from 'Core/Constant';
import { TypeScreen } from 'Core/Screens/TypeScreen/TypeScreen';
import { Notifier } from 'Core/Common/Notifier';
import { BlockUI } from 'Core/Common/BlockUi';
import { BasketStore } from 'Core/Controls/Basket/Stores/BasketStore';
import { EVENTS as TYPE_SCREEN_EVENTS } from 'Core/Screens/TypeScreen/Events';
import { EVENTS as EDIT_SCREEN_EVENTS } from 'Core/Screens/EditScreen/Events';
import { RecordLinker } from 'Core/Components/RecordLinker/RecordLinker';
import { EVENTS } from 'Core/Controls/Basket/Events';
import { P } from 'Core/Common/Promise';
import { EVENTS as SCREEN_EVENTS } from 'Core/Screens/Events';
import { UserVarsManager } from 'Core/UserVarsManager/UserVarsManager';
import { BasketRecord } from 'Core/Controls/Basket/BasketRecord';
import { BasketDataModel } from 'Core/Controls/Basket/Models/BasketDataModel';
import { BasketRecordModel } from 'Core/Controls/Basket/Models/BasketDataModel';
import { GeneralProperties } from 'Core/GeneralProperties/GeneralProperties';
import Config from 'Core/Controls/Basket/Configs/basket-config.json';
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";

import ViewTemplate from 'Core/Controls/Basket/Templates/View.html';
import ToolBarTemplate from 'Core/Controls/Basket/Templates/ToolBar.html';
import DesignTemplate from 'Core/Controls/Basket/Templates/Design.html';
import { LOCK_EVENTS, LockManager } from "Core/Components/Locker/LockManager";
import { BaseControl } from "../BaseControl/BaseControl";

ko.templates['Core/Controls/Basket/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Basket/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Basket/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/Basket/Templates/Design'] = DesignTemplate;

export class Basket extends BaseControl {
    private _records: KnockoutObservableArray<BasketRecord>;
    private _selectedRecord: KnockoutObservable<BasketRecord>;
    private _enableAddButton: KnockoutObservable<boolean>;
    private _selectedRecordId: number;
    private _gridEntityId: number;

    constructor(params: IControlParam) {
        super(params, Config);        
        this.Init();
    }

    ApplyProperties(){}

    Init() {
        this.SetDefaultIcon(new Icon(DEFAULT_ICONS.Basket));
        this.AddEvent(EVENTS.BASKET_SELECTED);
        this._records = ko.observableArray([]);
        this._selectedRecord = ko.observable(null);
        this._enableAddButton = ko.observable(false);
        this._selectedRecord.subscribe((newValue) => {
            if (newValue) {
                this._selectedRecordId = newValue.RecordId;
                this.Trigger(EVENTS.BASKET_SELECTED, { Records: newValue.Records() });
                UserVarsManager.Instance.SaveBasket(this.GetFieldModel().EntityId, newValue.RecordId, this._gridEntityId);
            } else {
                this.Trigger(EVENTS.BASKET_SELECTED, { Records: [] });
                UserVarsManager.Instance.SaveBasket(this.GetFieldModel().EntityId, 0, this._gridEntityId);
            }
        });
    }

    NewRecord() {
        if (this._enableAddButton()) {
            const typeScreen = new TypeScreen(this.GetFieldModel().EntityId, 0, false, false, null);
            typeScreen.On(TYPE_SCREEN_EVENTS.TYPES_NOT_FOUND, this, (eventArgs) => {
				new Notifier().Warning(eventArgs.data.Message || NOTIFICATIONS.TYPES_NOT_FOUND);
            });
            typeScreen.On(TYPE_SCREEN_EVENTS.TYPE_SELECTED, this, (eventArgs) => {
                const typeId = eventArgs.data.TypeId;
                const kindId = eventArgs.data.KindId;

                this.CreateRecord(typeId, kindId);
            });
            typeScreen.Show();
        }
    }

    async CreateRecord(typeId: number, kindId: number) {
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

        screenManager.GetEditScreen({
            EntityId: this.GetFieldModel().EntityId,
            TableTypeId: typeId,
            KindId: kindId,
            RecordId: 0,
            LoadAsExample: false
        }).always(() => {
            BlockUI.Unblock();
        }).fail(error => {
            let notifier = new Notifier($(this._el));
            notifier.Warning(error.message);
        }).then((screen) => {
            screen.On(EDIT_SCREEN_EVENTS.RECORD_SAVED, this, eventArgs => {
                this._selectedRecordId = eventArgs.data.RecordId;
                this.LoadData();
            });
            screen.ShowInModal();
        });
    }

    LoadData(subjectEntityId: number = null) {
        BlockUI.Block({ Target: this._el });

        this._records([]);
        if (!this._gridEntityId) {
            this._gridEntityId = subjectEntityId;
        }

        if (!this._selectedRecordId) {
            var selectedRecord = UserVarsManager.Instance.GetBasket(this.GetFieldModel().EntityId, this._gridEntityId);
            if (selectedRecord) {
                this._selectedRecordId = selectedRecord;
            }
        }

        BasketStore.GetData({ ControlId: this.GetControlId(), SubjectEntityId: this._gridEntityId })
            .always(() => {
                BlockUI.Unblock(this._el);
            })
            .fail((error) => {
                var notifier = new Notifier();
                notifier.Failed(error.message);
            })
            .then((data: BasketDataModel) => {
                this._enableAddButton(data.IsAddingRecordAllowed);
                _.each(data.Records, (record: BasketRecordModel) => {
                    var basketRecord = new BasketRecord(record, this._gridEntityId);
                    this._records.push(basketRecord);

                    if (basketRecord.RecordId === this._selectedRecordId) {
                        this.SelectRecord(basketRecord);
                    }

                    basketRecord.On('LIFESTATUS_CHOOSEN', this, (eventargs) => {
                        this.UpdateStatus(eventargs.data.LifestatusId);
                    });
                });
            });
    }

    UpdateStatus(lifestatusId: number) {
        BlockUI.Block();
        BasketStore.UpdateStatus({
            EntityId: this.GetFieldModel().EntityId,
            RecordId: this._selectedRecord().RecordId,
            LifestatusId: lifestatusId
        }).then(() => {
            this.LoadData();
        })
            .always(() => {
                BlockUI.Unblock();
            })
            .fail((error) => {
                let notifier = new Notifier($(this._el));
                notifier.Warning(error.message);
            });
    }

    async EditRecord(basketRecord: BasketRecord) {
        BlockUI.Block();
        LockManager.Instance.TryLock(this.GetFieldModel().EntityId, basketRecord.RecordId)
            .then(async () => {
                const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
                screenManager.GetEditScreen({
                    EntityId: this.GetFieldModel().EntityId,
                    TableTypeId: basketRecord.TypeId,
                    RecordId: basketRecord.RecordId,
                    LoadAsExample: false
                }).always(() => {
                    BlockUI.Unblock();
                }).fail(error => {
                    let notifier = new Notifier($(this._el));
                    notifier.Warning(error.message);
                }).then((screen) => {
                    LockManager.Instance.On(LOCK_EVENTS.RELEASED, this, (eventArgs) => {
                        if (eventArgs.data.EntityId === this.GetFieldModel().EntityId &&
                            eventArgs.data.RecordId === basketRecord.RecordId) {
                            screen.Close();
                        }
                    });
                    screen.On(SCREEN_EVENTS.MODAL_CLOSE, this, () => {
                        LockManager.Instance.ReleaseLock(this.GetFieldModel().EntityId, basketRecord.RecordId);
                    });
                    screen.On(EDIT_SCREEN_EVENTS.RECORD_SAVED, this, eventArgs => this.LoadData());
                    screen.On(EDIT_SCREEN_EVENTS.RECORD_DELETED, this, eventArgs => this.RemoveRecord(eventArgs.data.RecordId));
                    screen.ShowInModal();
                });
            }).always(() => BlockUI.Unblock());
    }

    RemoveRecord(recordId: number) {
        if (this._selectedRecord() && this._selectedRecord().RecordId === recordId) {
            this._selectedRecord(null);
        }

        var deletedRecord = _.find(this._records(), (item: BasketRecord) => {
            return item.RecordId === recordId
        });
        if (deletedRecord) {
            this._records.splice(this._records.indexOf(deletedRecord), 1);
        }
    }

    get Records(): KnockoutObservableArray<BasketRecord> {
        return this._records;
    }

    get SelectedRecord(): KnockoutObservable<BasketRecord> {
        return this._selectedRecord;
    }

    SelectRecord(basketRecord: BasketRecord) {
        if (this._selectedRecord()) {
            this._selectedRecord().SetIsSelected(false);
        }
        basketRecord.SetIsSelected(true);
        this._selectedRecord(basketRecord);
    }

    AddRecordToBasket(recordId: number, entityId: number): P.Promise<any> {
        var deferredResult = P.defer<any>();
        var notifier = new Notifier();

        if (this._selectedRecord()) {
            BlockUI.Block();
            RecordLinker.LinkRecord({
                MainTableId: this._model().EntityId,
                MainRecordId: this._selectedRecord().RecordId,
                LinkedTableId: entityId,
                LinkedRecordId: recordId
            }).always(() => {
                BlockUI.Unblock();
            })
                .then(result => {
                    if (!result.IsSuccessfull) {
                        notifier.Failed(result.ErrorMessage);
                        return;
                    }
                    this._selectedRecord().Records.push(recordId);
                    notifier.Success(NOTIFICATIONS.RECORD_ADDED_TO_BASKET);
                    deferredResult.resolve(result.ResultObject.Sequence);
                });
        } else {
            notifier.Warning(NOTIFICATIONS.PLEASE_CREATE_OR_SELECT_BASKET_RECORD);
        }

        return deferredResult.promise();
    }

    RemoveRecordFromBasket(recordId: number, entityId: number): P.Promise<any> {
        var deferredResult = P.defer<any>();
        var notifier = new Notifier();

        if (this._selectedRecord()) {
            BlockUI.Block();
            BasketStore.RemoveFromBasket({
                BasketEntityId: this._model().EntityId,
                BasketRecordId: this._selectedRecord().RecordId,
                RelatedEntityId: entityId,
                RelatedRecordId: recordId
            }).always(() => {
                BlockUI.Unblock();
            }).then(() => {
                this._selectedRecord().Records.splice(this._selectedRecord().Records.indexOf(recordId), 1);
                notifier.Success(NOTIFICATIONS.RECORD_REMOVED_FROM_BASKET);
                deferredResult.resolve(null);
            }).fail((error) => {
                let notifier = new Notifier($(this._el));
                notifier.Warning(error.message);
            });
        } else {
            notifier.Warning(NOTIFICATIONS.PLEASE_SELECT_BASKET_RECORD);
        }

        return deferredResult.promise();
    }

    get EnableAddButton(): KnockoutObservable<boolean> {
        return this._enableAddButton;
    }
}