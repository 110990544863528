import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';

import {ProfileSelectorItem} from '../Models/ProfileSelectorItem';

export class ProfileSelectorStore {

    GetCreatorProfiles(entityId: number) {
        const deferred = P.defer<ProfileSelectorItem[]>()

        Request.Send({
            proxy: {
                url: '/api/SecurityApi/GetCreatorProfiles',
                type: 'GET',
                dataType: 'json'
            },
            params: {entityId}
        })
            .then(creatorProfiles => deferred.resolve(creatorProfiles))
            .fail(error => this.GetResponseError(error, 'Error getting creator profiles'));

        return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}