import * as _ from "underscore";
import * as ko from "knockout";
import {TreeNodeModel} from "LookupEditor/Models/TreeNodeModel";
import {TableModel} from "LookupEditor/Store/Models/TableModel";
import {Guid} from "Core/Common/Guid";
import {BlockUI} from 'Core/Common/BlockUi';
import {TablesStore} from 'LookupEditor/Store/TablesStore';
import {Event} from "Core/Common/Event";
import {EVENTS} from "Core/Constant";
import "jquery";
import "tree";

export class TreeModel extends Event {
    private _$container: JQuery;
    Nodes: KnockoutObservableArray<TreeNodeModel>;

    constructor(nodes?: Array<TreeNodeModel>) {
        super();

        this.Nodes = ko.observableArray(nodes || []);

		this.BindEvents();
    }

    get NodesJson() {
        return this.Nodes().map(node => node.ToJson());
    }

    GetTemplateName(): string {
        return 'LookupEditor/Templates/Tree';
    }

    AfterRender(el) {
        this._$container = $('#tree');
        this._$container
            .jstree({
                core: {
                    animation: false,
                    data: (expandedNode, callback) => this.UpdateTree(expandedNode, callback)
                }
            })
            .on('select_node.jstree ', (event, el) => {
                this.GetNode(el.node.id, (node: TreeNodeModel) => {
                    this.Trigger(EVENTS.ON_TREE_NODE_SELECTED,
                        {
                            Node: el.node,
                            NodeModel: node
                        });
                });
            });
    }

    GetNode(guid: Guid, callback: (node: TreeNodeModel) => void) {
        this.Nodes().forEach(node => node.GetNode(guid, callback));
    }

    GetRootNode(id: number) {
        var rootNodes = this.Nodes().filter(node => node.Id === id);
        return rootNodes.length > 0 ? rootNodes[0] : null;
    }

    private BindEvents() {
		this.On(EVENTS.ON_TREE_NODE_SELECTED, this, args => {
            var nodeModel = args.data.NodeModel;
			if (nodeModel.Type === 'Entity' || nodeModel.Type === 'Sub' || nodeModel.Type === 'Link') {
                this._$container.jstree(true).close_all();
                var rootNodeModel = this.GetRootNode(nodeModel.Id);
                if (rootNodeModel == null) {
                    this.AddRootNode(nodeModel);
                } else {
                    this._$container.jstree(true).open_node(rootNodeModel.Guid);
                }
            }
        });
    }

    private UpdateTree(parentNode, callback) {
        if (parentNode.id === '#') {
            callback.call(parentNode, this.NodesJson);
        }
        else {
            this.LoadChildrenFor(parentNode, callback);
        }
    }

    private LoadChildrenFor(parentNode, callback) {
        this.GetNode(parentNode.id, (parentModel: TreeNodeModel) => {
			BlockUI.Block();
			const isRoot = parentNode.parent === '#';

            TablesStore.GetRelatedTables(parentModel.Id, parentModel.Type, isRoot)
				.always(() => {
					BlockUI.Unblock();
				})
				.then(nodes => this.AddNewNodes(parentNode, parentModel, nodes, callback));
        });
    }

	private AddNewNodes(parentNode: any, parentModel: TreeNodeModel, nodes: Array<TableModel>, callback) {
        var models = nodes.map(node => {
            var model = new TreeNodeModel();
            model.Id = node.Id;
            model.Name = node.TranslatedName || node.Name;
            model.Type = node.Type;
			model.Icon = node.Icon;
			model.EntityColor = node.EntityColor;
	        model.CanExpand = parentNode.parents.length < 2;
			model.HasLookups = node.HasLookups;
            return model;
        });

        models.forEach(node => parentModel.NestedNodes.push(node));
        callback.call(parentNode, models.map(node => node.ToJson()));
    }

    private AddRootNode(node: TreeNodeModel) {
        var newNode = new TreeNodeModel();

        newNode.Id = node.Id;
        newNode.Name = node.Name;
        newNode.Type = node.Type;
		newNode.Icon = node.Icon;
		newNode.EntityColor = node.EntityColor;
		newNode.HasLookups = node.HasLookups;
		newNode.CanExpand = node.HasLookups;

        this.Nodes.push(newNode);

        var treeRoot = this._$container.jstree(true).get_node('#');
        this._$container.jstree('create_node', treeRoot, newNode.ToJson(), "first", false, false);
        this._$container.jstree(true).refresh();
    }
}