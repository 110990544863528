import * as ko from "knockout";
import {IButtonConfig} from "Core/Controls/PortletBar/Interfaces/IButtonConfig";
import {ButtonViewModel} from "Core/Controls/PortletBar/Models/Buttons/ButtonViewModel";

import ReloadButtonTemplate from "Core/Controls/PortletBar/Templates/Buttons/Reload/Button.html";
import ReloadPropsTemplate from "Core/Controls/PortletBar/Templates/Buttons/Reload/Props.html";

ko.templates["Core/Controls/PortletBar/Templates/Buttons/Reload/Button"] = ReloadButtonTemplate;
ko.templates["Core/Controls/PortletBar/Templates/Buttons/Reload/Design"] = ReloadButtonTemplate;
ko.templates["Core/Controls/PortletBar/Templates/Buttons/Reload/Props"] = ReloadPropsTemplate;

export class ReloadButtonViewModel extends ButtonViewModel {
	constructor(buttonName: string, buttonConfig: IButtonConfig) {
		super(buttonName, buttonConfig);
	}
}