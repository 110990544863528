import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize, GenericDeserialize} from 'libs/cerialize';
import {GlobalModel} from 'Core/GlobalManager/Models/GlobalModel';

export interface IGetGlobalByNameRequestModel {
    GlobalName: string;
};

export interface IGetGlobalByNamesRequestModel {
    GlobalNames: Array<string>;
};

export class GlobalStore {
    static GetGlobalByNames(params: IGetGlobalByNamesRequestModel): P.Promise<Array<GlobalModel>> {
        const deferredResult = P.defer<Array<GlobalModel>>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: '/api/GlobalsApi/GetGlobals',
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(data => deferredResult.resolve(this.DeserializeGlobals(data)))
            .fail(err => deferredResult.reject(this.GetResponseError(err, 'Error loading globals')));

        return deferredResult.promise();
    }

    static GetGlobalByName(params: IGetGlobalByNameRequestModel): P.Promise<GlobalModel> {
        const deferredResult = P.defer<GlobalModel>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: 'api/GlobalsApi/GetGlobal',
                dataType: 'json',
                type: 'GET'
            }, params: params
        };

        Request.Send(requestParams)
            .then(global => deferredResult.resolve(this.Deserialize(global)))
            .fail(err => deferredResult.reject(this.GetResponseError(err, `Error getting global ${params.GlobalName}`)));

        return deferredResult.promise();
    }

    static UpdateUserGlobals(userGlobals: GlobalModel[]) {
        const deferredResult = P.defer<void>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: '/api/GlobalsApi/UpdateUserGlobals',
                type: 'POST'
            }, params: {UserGlobals: userGlobals}
        };

        Request.Send(requestParams)
            .then(() => deferredResult.resolve(null))
            .fail(err => deferredResult.reject(this.GetResponseError(err, `Error updating user globals`)));

        return deferredResult.promise();
    }

    static DeserializeGlobals(jsonObject: any): any {
        return GenericDeserialize(jsonObject, GlobalModel);
    }

    static Deserialize(jsonObject: any): GlobalModel {
        return <GlobalModel>Deserialize(jsonObject, GlobalModel);
    }

    private static GetResponseError(err: P.Rejection, generalError: string) {
        if (err.status === 400) {
            return JSON.parse(err.message).Message;
        }

        return generalError;
    }
}