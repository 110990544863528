import { deserialize } from 'libs/cerialize';
import { deserializeAs } from 'libs/cerialize';
import * as ko from 'knockout';

export class FunctionDesignerMethodParamModel {
	constructor() {
	}

	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	TypeId: number;

	@deserialize
	TypeName: string;

	@deserialize
	SideName: string;
}