import {BusinessRole} from 'Core/GeneralProperties/Managers/TabSecurity/Models/BusinessRole';
import {BusinessRoleRestrictions} from 'Core/GeneralProperties/Managers/TabSecurity/Enums/BusinessRoleRestrictions';

export class BusinessRolesDisplaySettings {
    public _restrictions: { Name: string; IconName: string }[];

    constructor(public Role: BusinessRole, value: number) {
        this._restrictions = BusinessRoleRestrictions
            .AsList(value)
            .map((restriction) => {
                return {
                    Name: restriction.Name,
                    IconName: restriction.IconName
                }
            });
    }

    get Restrictions() {
        return this._restrictions.slice();
    }
}