import * as ko from 'knockout'
import 'jquery'

import Dropzone from 'dropzone'
import {Notifier} from 'Core/Common/Notifier';
import {NOTIFICATIONS, LABELS} from "Core/Components/Translation/Locales";

export class DropzoneExtenion {
    static Init() {

        ko.bindingHandlers.dropzone = {
            init: (element, valueAccessor, allBindings, viewModel, bindingContext) => {
                const options = valueAccessor();
                const dropzoneInstance = new Dropzone(element, options);
                let rawData = ko.unwrap(bindingContext.$rawData);

                dropzoneInstance.on('addedfiles', (files) => {
                    setTimeout(() => {                         
                        if (dropzoneInstance.files.length > bindingContext.$rawData.MaxFilesQty) {
                            Array.prototype.forEach.call(files, file => dropzoneInstance.removeFile(file));
                            new Notifier().Failed(NOTIFICATIONS.ALLOWED_FILES_AMOUNT_EXCEEDED.replace('{amount}', bindingContext.$rawData.MaxFilesQty));
                            return;
                        }

                        Array.prototype.forEach.call(files, file => {
                            if (file.size === 0) {
                                dropzoneInstance.removeFile(file);
                                new Notifier().Failed(NOTIFICATIONS.EMPTY_SIZE_FILES_RESTRICTION);
                                return;
                            }
                            if ((file.size / (1024 * 1024)) > rawData.MaxFileSize) {
                                return;
                            }
                        });

                        const uploadSessionSize = dropzoneInstance.files.filter(file => file.status === 'queued').map(file => file.size).reduce((prevFileSize, currentFileSize) => prevFileSize + currentFileSize, 0);
                        if ((uploadSessionSize / (1024 * 1024)) > rawData.MaxUploadingFilesSize) {
                            Array.prototype.forEach.call(files, function (file) {
                                if (file.previewElement.parentNode != null) {
                                    dropzoneInstance.removeFile(file)
                                }
                            });
                            new Notifier().Failed(NOTIFICATIONS.ALLOWED_FILES_SIZE_EXCEEDED.replace('{size}', bindingContext.$rawData.MaxUploadingFilesSize));
                            return;
                        }

                        const combinedSize = dropzoneInstance.files.map(file => file.size).reduce((prevFileSize, currentFileSize) => prevFileSize + currentFileSize, 0);
                        if ((combinedSize / (1024 * 1024)) > bindingContext.$rawData.MaxCombinedSize) {
                            Array.prototype.forEach.call(files, function (file) {
                                if (file.previewElement.parentNode != null) {
                                    dropzoneInstance.removeFile(file)
                                }
                            });
                            new Notifier().Failed(NOTIFICATIONS.OVERAL_FILE_SIZE_EXCEEDED);
                            return;
                        }

                        const acceptedTypes: Array<string> = options && options.acceptedFiles
                            ? options.acceptedFiles.split(',') : null;
                        if (acceptedTypes) {
                            let invalidFiles: Array<string> = [];
                            Array.prototype.forEach.call(files, file => {
                                if (!_.any(acceptedTypes, extension => file.name.toLowerCase().endsWith(extension))) {
                                    dropzoneInstance.removeFile(file);
                                    invalidFiles.push(file.name);
                                }
                            });

                            if (invalidFiles.length > 0) {
                                const message = `${NOTIFICATIONS.ALLOWED_FILE_EXTENSIONS_ARE_EXTENSIONS}. ${NOTIFICATIONS.CANNOT_UPLOAD_FILES}`
                                    .replace('{FileExtensions}', acceptedTypes.join(', '))
                                    .replace('{Files}', invalidFiles.join(', '));
                                new Notifier().Failed(message);
                                return;
                            }
                        }

                        if (bindingContext.$rawData && bindingContext.$rawData.AfterUpload) {
                            Array.prototype.forEach.call(files, file => bindingContext.$rawData.AfterUpload(file));
                        }
                    })
                });

                dropzoneInstance.on('addedfile', (file) => {
                    if (file.status === 'preloaded' && bindingContext.$rawData && bindingContext.$rawData.AfterUpload) {
                        bindingContext.$rawData.AfterUpload(file);
                    }
                });

                ko.utils.domData.set(element, 'preloadFinished', false);

                dropzoneInstance.on('removedfile', (file) => {
                    file.status = 'removed';

                    if (typeof bindingContext.$rawData.ImageDelete === "function") {
                        bindingContext.$rawData.ImageDelete(file);
                    }
                });

                dropzoneInstance.on('error', (file) => {
                    if ((file.size / (1024 * 1024)) > rawData.MaxFileSize) {
                        dropzoneInstance.removeFile(file);
                        new Notifier().Failed(NOTIFICATIONS.FILE_SIZE_IS_TOO_BIG.replace('{MaxFileSize}', rawData.MaxFileSize));
                    }
                });

                ko.utils.domData.set(element, 'dropZoneInstance', dropzoneInstance);

                ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                    dropzoneInstance.off();
                    dropzoneInstance.destroy();
                });
            },
            update: (element, valueAccessor, allBindings, viewModel, bindingContext) => {
                if (!bindingContext.$data.IsImageGallery) {
                    return;
                }
                const images = bindingContext.$rawData._dataModel && bindingContext.$rawData._dataModel() && bindingContext.$rawData._dataModel().Files || null;
                if (images !== null && !ko.utils.domData.get(element, 'preloadFinished')) {
                    const dropzoneInstance = ko.utils.domData.get(element, 'dropZoneInstance');
                    if (images) {
                        for (var i = 0; i < images.length; i++) {
                            const imageToInject = _.extend(images[i], {
                                preloaded: true,
                                accepted: true,
                                status: 'preloaded',
                                upload: {filename: images[i].CacheFile ? images[i].CacheFile.name : (images[i].Name ? images[i].Name : 'prev' + i)},
                                size: images[i].Size ? images[i].Size : images[i].CacheFile.size
                            });
                            dropzoneInstance.emit('addedfile', imageToInject);
                            if (!!imageToInject.CacheFile) {
                                dropzoneInstance.emit('thumbnail', imageToInject, imageToInject.CacheFile.dataURL || 'data:image/jpeg;base64,' + imageToInject.CacheFile.dataURL);
                            } else {
                                dropzoneInstance.emit('thumbnail', imageToInject, imageToInject.ImageData || 'data:image/jpeg;base64,' + imageToInject.ImageData);
                            }
                            if (imageToInject.ImageData) {
                                dropzoneInstance.emit('complete', imageToInject);
                            }
                            dropzoneInstance.files.push(imageToInject);
                        }
                    }
                    ko.utils.domData.set(element, 'preloadFinished', true);

                }
            }
        };
    }
}