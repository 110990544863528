import * as ko from "knockout";

import {ColumnEditor} from "LookupEditor/ColumnEditors/Base/ColumnEditor";

import PKeyTemplate from "LookupEditor/Templates/Columns/PKey.html";
ko.templates["LookupEditor/Templates/Columns/PKey"] = PKeyTemplate;

export class PKeyColumnEditor extends ColumnEditor {

	GetTemplateName(): string {
		return 'LookupEditor/Templates/Columns/PKey';
	}

	AfterRender(el): void { }

	ToString() {
		return "Primary key";
	}
} 