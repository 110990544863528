import * as ko from 'knockout';
import * as _ from 'underscore';
import { LABELS } from "Core/Components/Translation/Locales";
import { BlockUI } from 'Core/Common/BlockUi';
import { QueryExpressionModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import { QueryBuilderStore } from 'QueryBuilder/Stores/QueryBuilderStore';
import { CardScreenModel } from 'QueryBuilder/Models/CardScreenModel';
import { ViewModes } from 'Core/Controls/Grid/BaseGrid/Enums/ViewModes';

import Template from 'QueryBuilder/TemplateScreen/Templates/TemplateScreen.html';

export class TemplateScreen {
	private _expressionModel: QueryExpressionModel;
	private _labels = LABELS;
	private _subjectEntityId: number;
	private _screens: KnockoutObservableArray<CardScreenModel>;
	private _selectedScreen: KnockoutObservable<CardScreenModel>;

	private _showInPortlet: KnockoutObservable<boolean>;
	private _queryTypeName: ViewModes;

	constructor(queryType: ViewModes) {
		this._queryTypeName = queryType;
		this._screens = ko.observableArray([]);
		this._selectedScreen = ko.observable(null);
		this._showInPortlet = ko.observable(false);

		this._selectedScreen.subscribe((newValue) => {
			if (this._expressionModel) {
				if (newValue) {
					this._expressionModel.CardScreenId = newValue.Id;
				} else {
					this._expressionModel.CardScreenId = null;
				}
			}
		});
	}

	get IsEnabledShowInPortletCheckBox(): boolean{
		return this._queryTypeName === ViewModes.ListView;
	}

	set ExpressionModel(value: QueryExpressionModel) {
		this._expressionModel = value;
	}

	set SubjectEntityId(value: number) {
		this._subjectEntityId = value;
	}

	Update() {
		BlockUI.Block();
		QueryBuilderStore.GetCardScreens({ EntityId: this._subjectEntityId })
			.always(() => {
				BlockUI.Unblock();
			})
			.then((screens) => {
				this._screens(screens);
				let selectedScreen = _.find(this._screens(), (screen) => {
					return screen.Id === this._expressionModel.CardScreenId;
				});
				if (selectedScreen) {
					this._selectedScreen(selectedScreen);
				}
			});
	}

	OptionAfterRender(option, item) {
		if (!item) {
			ko.applyBindingsToNode(option, { disable: true }, item);
		}
	}

	Clear() {
		this._selectedScreen(null);
	}

	GetTemplate() {
		return Template;
	}

	AfterRender() {
	}

	get ShowInPotlet() {
		return this._showInPortlet();
	}

	set ShowInPotlet(value: boolean) {
		this._showInPortlet(value);
	}

}