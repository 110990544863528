//Store models
import { DayReservationsResponseModel } from "Core/Components/Controls/TimeWriting/Models/Store/Day/Response/DayReservationsResponseModel";

//View models
import { RequestedDate } from "Core/Components/Controls/TimeWriting/Models/View/DayDataViewModel"

//Mappings
import { ReservationMappings } from "./ReservationMappings";

export class RequestedDateMappings {
	static OnViewModel(model: DayReservationsResponseModel): RequestedDate {
		const viewModel = new RequestedDate();

		const reservations = model.Reservations.map(reservation => ReservationMappings.OnViewModel(reservation));

		viewModel.Reservations(reservations);

		return viewModel;
	}
}