import {Guid} from 'Core/Common/Guid';

import {deserialize, serialize, serializeAs} from 'libs/cerialize';
import {deserializeAs} from 'libs/cerialize';
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel'
import {SubFormProperties} from 'Core/Screens/DesignScreen/Models/FormProperties';

export class SubFormModel {
    constructor() {
        this.Guid = Guid.NewGuid();
        this.Controls = [];
        this.Properties = new SubFormProperties();
        this.Removable = false;
    }

    @serialize
    @deserialize
    Guid: string;

    @deserialize
    @serialize
    Id: number;

    @serialize
    @deserialize
    Name: string;

    @deserializeAs(ControlModel)
    Controls: Array<ControlModel>;

    @serializeAs(SubFormProperties)
    @deserializeAs(SubFormProperties)
    Properties: SubFormProperties;

    Removable: boolean;
}