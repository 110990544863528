import {deserialize} from 'libs/cerialize'
import {deserializeAs} from 'libs/cerialize'
import {LinkedFieldValueModel} from 'Core/Screens/Common/LinkEditor/Models/LinkedFieldValueModel'
import {LinkEditorTabSettingsModel} from './LinkEditorTabSettingsModel';

export class LinkedRecordModel {
    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    TranslatedName: string;

    @deserialize
    TypeId: number;

    @deserialize
    TypeName: string;

    @deserialize
    LifeStatusId: number;

    @deserialize
    LifeStatusName: string;

    @deserialize
    Alias: string;
}

export class LinkEditorModel {
    @deserialize
    LinkTableId: number;

    @deserialize
    RecordId: number;

    @deserialize
    Subject: string;

    @deserialize
    LinkEntity: string;

    @deserialize
    LinkEntityTranslatedName: string;

    @deserialize
    RightLinkedEntityId: number;

    @deserialize
    RightLinkedEntityName: string;

    @deserialize
    RightLinkedEntityTranslatedName: string;

    @deserialize
    LeftLinkedEntityId: number;

    @deserialize
    LeftLinkedEntityName: string;

    @deserialize
    LeftLinkedEntityTranslatedName: string;

    @deserialize
    HasCustomFields: boolean;

    @deserializeAs(LinkedFieldValueModel)
    Fields: Array<LinkedFieldValueModel>;

    @deserializeAs(LinkedRecordModel)
    LeftLinkedRecord: LinkedRecordModel;

    @deserializeAs(LinkedRecordModel)
    RightLinkedRecord: LinkedRecordModel;

    @deserializeAs(LinkEditorTabSettingsModel)
    TabSettings: LinkEditorTabSettingsModel[];
}