import {deserialize} from 'libs/cerialize'
import {deserializeAs} from 'libs/cerialize'
import {FieldModel} from 'Core/Screens/DesignScreen/ControlEditor/Models/FieldModel'
import {EntityTypeModel} from 'Core/Screens/DesignScreen/ControlEditor/Models/EntityTypeModel'

export class EntityModel {
	@deserialize
	EntityId: number;

	@deserialize
	EntityName: string;

	@deserialize
	EntityFullName: string;

	@deserialize
	EntityFullNameTranslation: string;

	@deserializeAs(EntityTypeModel)
	EntityTypes: Array<EntityTypeModel>;

	@deserialize
	TypeName: string;

	@deserialize
	TypeId: number;

	@deserialize
	FontName: string;

	@deserialize
	IconName: string;

	@deserialize
	GraphicalIcon: string;
	
	@deserializeAs(FieldModel)
	Fields: Array<FieldModel>;

	@deserialize
	IsView: boolean;
}