import {deserialize, deserializeAs} from 'libs/cerialize';
import {Guid} from "Core/Common/Guid"

import {Portlet} from "Core/Portlets/Models/Runtime/Portlet"
import {PortletStates} from "Core/Portlets/Enums/PortletStates"
import {IPosition} from "Core/Portlets/Models/Common/Interfaces/IPosition"
import {ScreenModel} from 'Core/Models/Screens/ScreenModel';

export class DesignedPortlet {
	@deserialize
	Guid: Guid;

	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
    ScreenId: number;

    @deserializeAs(ScreenModel)
    ScreenModel: ScreenModel;

	@deserialize
	RowPosition: number;

	@deserialize
	ColPosition: number;

	State: PortletStates;

	static Map(portletToSave: Portlet, position: IPosition, state: PortletStates) {
		let portletStoreModel = new DesignedPortlet();

		portletStoreModel.Guid = portletToSave.Guid;
		portletStoreModel.Id = portletToSave.ObjectId;
		portletStoreModel.Name = portletToSave.Name || portletToSave.ScreenName;
		portletStoreModel.ScreenId = portletToSave.ScreenId;
		portletStoreModel.RowPosition = position.RowNumber;
		portletStoreModel.ColPosition = position.ColNumber;
		portletStoreModel.State = state;

		return portletStoreModel;
	}
} 