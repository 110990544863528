import { deserialize, deserializeAs } from "libs/cerialize";
import { DwPackageDto } from "./DwPackageDto";
import { ModelState } from "../../../Common/Enums/ModelState";

export class TriggerDto {

    constructor(init?: Partial<TriggerDto>){
        Object.assign(this, init);
    }

    @deserialize
    ExecuteSync: boolean;

    @deserializeAs(DwPackageDto)
    DwPackages: Array<DwPackageDto>;
    
    @deserialize
    State: ModelState;
}