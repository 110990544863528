import Template from "Pages/AdministrationPage/Tabs/SpeceStatus.html";
import { AdministrationStore } from "../AdministrationStore";
import { BlockUI } from "../../../Core/Common/BlockUi";
import { SpaceStatusDto } from "./SpaceStatusDto";
ko.templates["Pages/AdministrationPage/Tabs/SpaceStatus"] = Template;

export class SpaceStatus {

    private _statuses: KnockoutObservableArray<SpaceStatusDto>;

    constructor(){
        this._statuses = ko.observableArray([]);
        this.LoadData();
    }

    GetTemplateName(){
        return 'Pages/AdministrationPage/Tabs/SpaceStatus';
    }

    LoadData(){
        BlockUI.Block();
        AdministrationStore.GetSpaceStatus().always(()=>{
            BlockUI.Unblock();
        }).then(data=> this._statuses(data));
    }

    AfterRender(){

    }
}