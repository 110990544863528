import {deserialize, deserializeAs} from 'libs/cerialize';

export class GridTotalCellValueModel {
    @deserialize
    Value: string;

    @deserialize
    IsWrapped: boolean;

    @deserialize
    QueryColumnGuid: string;
}