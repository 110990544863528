import * as ko from "knockout";

import {IButtonConfig} from "Core/Controls/PortletBar/Interfaces/IButtonConfig";
import {ButtonViewModel} from "Core/Controls/PortletBar/Models/Buttons/ButtonViewModel";

import RemoveButtonTemplate from "Core/Controls/PortletBar/Templates/Buttons/Remove/Button.html";
import RemoveButtonDesignTemplate from "Core/Controls/PortletBar/Templates/Buttons/Remove/Design.html";
import RemovePropsTemplate from "Core/Controls/PortletBar/Templates/Buttons/Remove/Props.html";

ko.templates["Core/Controls/PortletBar/Templates/Buttons/Remove/Button"] = RemoveButtonTemplate;
ko.templates["Core/Controls/PortletBar/Templates/Buttons/Remove/Design"] = RemoveButtonDesignTemplate;
ko.templates["Core/Controls/PortletBar/Templates/Buttons/Remove/Props"] = RemovePropsTemplate;

export class RemoveButtonViewModel extends ButtonViewModel {
	DisplayValue: KnockoutObservable<boolean>;

	constructor(buttonName: string, buttonConfig: IButtonConfig) {
		super(buttonName, buttonConfig);

		this.DisplayValue = ko.observable(false);
	}
}