import {SignInModel} from 'Auth/Models/SignInModel';
import { PasswordExpiredModel } from "Auth/UI/PasswordExpiredForm/Model/PasswordExpiredModel";

export class UpdatePasswordModel {
	DatabaseName: string;
	ReturnUrl: string;
	Login: string;
	CurrentPassword: string;
	NewPassword: string;

	__RequestVerificationToken: string;

	MapFrom(signInModel: SignInModel) {
		for (let property in signInModel) {
			if (signInModel.hasOwnProperty(property)) {
				this[property] = signInModel[property];
			}
		}

		return this;
	}

	static FillFromFormData(formData: PasswordExpiredModel) {
		let model = new UpdatePasswordModel();

		model.CurrentPassword = formData.OldPassword();
		model.NewPassword = formData.NewPassword();

		return model;
	}
}