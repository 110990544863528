import * as ko from "knockout"
import 'jquery';

export class BootstrapCollapseExtention {

    static Init() {
        ko.bindingHandlers.collapse = {
            init: (element, valueAccessor) => {
                var value: any = valueAccessor();
                let $collapse = $(element).collapse();

                $collapse.on('hidden.bs.collapse', () => value.onClose());
                $collapse.on('show.bs.collapse', () => value.onOpen());

                value.onReady($collapse);
            }
        };
    }
}