import * as _ from "underscore";

export class CollectionModel {
  private _items: Array<any>;
  private _type: any;

  constructor(options: any) {
    this._items = [];
    this._type = options.model;
  }

  Clone() {
    var result = new CollectionModel({ model: this._type });
    _.each(this._items, (item: any) => {
      result.Add(item.Clone());
    });
    return result;
  }

  toJSON(onlyModified: boolean) {
    var result = [];
    _.each(this._items, (item: any) => {
      var jsonData = item.toJSON(onlyModified);
      if (jsonData) {
        result.push(jsonData);
      }
    });
    return result;
  }

  GetItemBy(property, val) {
    for (var index = 0; index < this.Items.length; index++) {
      var item = this.Items[index];
      if (item[property] === val) {
        return item;
      }
    }
    return null;
  }

  SetItemBy(property, val, object) {
    for (var index = 0; index < this.Items.length; index++) {
      var item = this.Items[index];
      if (item[property] === val) {
        this.Items[index] = object;
      }
    }
  }

  SetItemsToDeleteBy(property, val) {
    var items = [];
    for (var index = 0; index < this.Items.length; index++) {
      var item = this.Items[index];
      if (item[property] === val) {
        if (item._id !== 0) {
          item._isModified = true;
          item._lifeStatusName = 'Delete';
          items.push(item);
        }
      }
      else {
        items.push(item);
      }
    }
    this.Items = items;
  }

  SetItemsToDelete() {
    this.DeleteItemsBy('Id', 0);
    for (var index = 0; index < this.Items.length; index++) {
      this.Items[index]._isModified = true;
      this.Items[index]._lifeStatusName = 'Delete';
    }
  }

  DeleteItemBy(property, val) {
    var items = this.Items;
    for (var index = 0; index < this.Items.length; index++) {
      var item = this.Items[index];
      if (item[property] === val) {
        items.splice(index, 1);
      }
    }
    this.Items = items;
  }

  DeleteItemsBy(property, val) {
    var item = this.GetItemBy(property, val);
    while (item) {
      this.DeleteItemBy(property, val);
      item = this.GetItemBy(property, val);
    }
  }

  GetItemsBy(property, val) {
    var result = [];
    for (var index = 0; index < this.Items.length; index++) {
      var item = this.Items[index];
      if (item[property] === val) {
        result.push(item);
      }
    }
    return result;
  }

  GetItemsWhereIn(property, values) {
    var result = [];
    for (var index = 0; index < this.Items.length; index++) {
      var item = this.Items[index];
      if (_.contains(values, item[property])) {
        result.push(item);
      }
    }
    return result;
  }

  GetItemByProperties(propertyValuePairs) {
    for (var index = 0; index < this.Items.length; index++) {
      var item = this.Items[index];

      var allPropertiesMatched = true;
      for (
        var pairIndex = 0;
        pairIndex < propertyValuePairs.length;
        pairIndex++
      ) {
        var propertyValuePair = propertyValuePairs[pairIndex];

        if (item[propertyValuePair.Property] !== propertyValuePair.Value) {
          allPropertiesMatched = false;
          break;
        }
      }

      if (allPropertiesMatched) {
        return item;
      }
    }

    return null;
  }

  GetItemsByProperties(propertyValuePairs) {
    var result = [];
    for (var index = 0; index < this.Items.length; index++) {
      var item = this.Items[index];

      var allPropertiesMatched = true;
      for (
        var pairIndex = 0;
        pairIndex < propertyValuePairs.length;
        pairIndex++
      ) {
        var propertyValuePair = propertyValuePairs[pairIndex];

        if (item[propertyValuePair.Property] !== propertyValuePair.Value) {
          allPropertiesMatched = false;
          break;
        }
      }

      if (allPropertiesMatched) {
        result.push(item);
      }
    }
    return result;
  }

  OrderBy(property) {
    this._items = _.sortBy(this._items, function (item) {
      return item[property];
    });
  }

  Add(item) {
    if (item instanceof this._type) {
      item._index = this._items.length;
      this._items.push(item);
    } else {
      throw new Error("Invalid item type ");
    }
  }

  Count() {
    return this._items.reduce(function (prev, curr) {
      if (
        !_.find(prev, (el: any) => {
          return el._id === curr._id;
        })
      ) {
        prev.push(curr);
      }
      return prev;
    }, []).length;
  }

  ForEach(callback) {
    this._items.forEach(callback);
  }

  Reset() {
    this._items = [];
  }

  get Items(): Array<any> {
    return this._items;
  }

  set Items(val: Array<any>) {
    this._items = val;
  }
}
