import * as ko from 'knockout'
import * as $ from 'jquery'

export class RenderTemplateXExtention {
    static Init() {
        (ko as any).renderTemplateX = (htmlTemplate, data) => {
            var temp = document.createElement('DIV');
            ko.applyBindingsToNode(temp, {template: {html: htmlTemplate, data: data}});
            var html = temp.childNodes;
            temp.remove();
            return html;
        };

        (ko as any).renderTemplateXHtml = (htmlTemplate, data) => {
            var temp = document.createElement('DIV');
            ko.applyBindingsToNode(temp, {template: {html: htmlTemplate, data: data}});
            var result = $(temp).html();
            temp.remove();
            return result;
        }

        (ko as any).renderTemplateXByTemplateName = (templateName, data) => {
            const wrapper = document.createElement('div');
            wrapper.setAttribute('data-bind', `template: { name: '${templateName}'}`);

            ko.applyBindings(data, wrapper);
            return wrapper;
        };
    }
}