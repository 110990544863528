

import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize} from 'libs/cerialize';
import {ImageDataModel} from 'Core/Controls/Image/Models/ImageDataModel';
import {AttachmentModel} from 'Core/Controls/Image/Models/AttachmentModel';

export interface IGetImageControlDataRequestModel {
	ControlId?: number;
	FieldId: number;
	RecordId: number | string;
	FirstRecordOnly?: boolean;
};

export class ImageDataStore {
	static Get(params: IGetImageControlDataRequestModel): P.Promise<ImageDataModel> {
		var deferredResult = P.defer<ImageDataModel>();

		var requestParams: IRequestOptions = {
			proxy: {
                url: '/api/ScreenFormApi/GetImageControlData',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(this.Deserialize(data));
			});

		return deferredResult.promise();
	}

	static GetOriginal(params: IGetImageControlDataRequestModel): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/ScreenFormApi/GetOriginalImage',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(this.DeserializeAttachment(data.ResultObject));
				} else {
					deferredResult.reject({message: data.ErrorMessage});
				}
			}).fail(error => deferredResult.reject({message: 'Error getting original image'}));

		return deferredResult.promise();
	}


	static SaveCropedImage(params: any): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.IMAGE.SAVE_UPLOADED_FILE,
				dataType: 'json',
				type: "POST",
				cache: false,
				contentType: false,
				processData: false
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
			});

		return deferredResult.promise();
	}

	static Deserialize(jsonObject: any): ImageDataModel {
		var model = <ImageDataModel>Deserialize(jsonObject, ImageDataModel);
		return model;
	}

	static DeserializeAttachment(jsonObject: any): AttachmentModel {
		var model = <AttachmentModel>Deserialize(jsonObject, AttachmentModel);
		return model;
	}
}