import * as ko from 'knockout';

import {Option} from "./Option";

export class OptionsGroup {
    GroupName: string;
    MultipleSelection: boolean;

    Options: Option[];
    SelectedOption: KnockoutObservable<number>;

    constructor() {
        this.SelectedOption = ko.observable(null);
    }
}