define(["require", "exports", "jquery", "metronic", "metronicLayout", "libs/cerialize", "Core/GlobalManager/GlobalManager", "Core/Common/Themes/UISettings", "Core/Common/Themes/CustomThemeDescriptor", "Core/Common/Themes/CustomThemeSetting"],
    function (require, exports, $, metronic, layout, cerialize, globalManager, uiSettings, customThemeDescriptor, customThemeSetting) {

        var Metronic = metronic.Metronic;
        var Layout = layout.Layout;
        var GenericDeserialize = cerialize.GenericDeserialize;
        var GlobalManager = globalManager.GlobalManager;
        var GLOBALS = globalManager.GLOBALS;
        var UISettings = uiSettings.UISettings;
        var DEFAULT_UI_SETTINGS = uiSettings.DEFAULT_VALUES;
        var CustomThemeDescriptor = customThemeDescriptor;
        var CustomThemeSetting = customThemeSetting;

        var Demo = (function () {
            function Demo() {
                this._uiSettings = null;
            }

            Demo.init = function () {
                // handles style customer tool
                // set layout style from cookie
                if ($.cookie && $.cookie('layout-style-option') === 'rounded') {
                    Demo.SetThemeStyle($.cookie('layout-style-option'));
                    $('.theme-panel .layout-style-option').val($.cookie('layout-style-option'));
                }

                if (window.Preloader) {
                    setTimeout(function () {
                        window.Preloader.Close()
                    }, 1000);
                }
            }

            Demo.handleTheme = function () {

                // handle layout style change
                $('.theme-panel .layout-style-option')
                    .change(function () {
                        Demo.SetThemeStyle($(this).val());
                    });

                $('.theme-panel .borders-option')
                    .change(function () {
                        Demo.SetThemeBorders($(this).val());
                    });

                var layoutCssPath = __webpack_public_path__;

                var panel = $('.theme-panel');

                if ($('body').hasClass('page-boxed') === false) {
                    $('.layout-option', panel).val("fluid");
                }

                $('.sidebar-option', panel).val("default");
                $('.page-header-option', panel).val("fixed");
                $('.page-footer-option', panel).val("default");
                if ($('.sidebar-pos-option').attr("disabled") === false) {
                    $('.sidebar-pos-option', panel).val(Metronic.isRTL() ? 'right' : 'left');
                }


                //handle theme layout
                var resetLayout = function () {
                    $("body")
                        .removeClass("page-boxed")
                        .removeClass("page-footer-fixed")
                        .removeClass("page-sidebar-fixed")
                        .removeClass("page-header-fixed")
                        .removeClass("page-sidebar-reversed");

                    $('.page-header > .page-header-inner').removeClass("container");

                    if ($('.page-container').parent(".container").length === 1) {
                        $('.page-container').insertAfter('body > .clearfix');
                    }

                    if ($('.page-footer > .container').length === 1) {
                        $('.page-footer').html($('.page-footer > .container').html());
                    } else if ($('.page-footer').parent(".container").length === 1) {
                        $('.page-footer').insertAfter('.page-container');
                        $('.scroll-to-top').insertAfter('.page-footer');
                    }

                    $(".top-menu > .navbar-nav > li.dropdown").removeClass("dropdown-dark");

                    $('body > .container').remove();
                };

                var lastSelectedLayout = '';

                var setLayout = function () {

                    var layoutOption = $('.layout-option', panel).val();
                    var sidebarOption = $('.sidebar-option', panel).val();
                    var headerOption = $('.page-header-option', panel).val();
                    var footerOption = $('.page-footer-option', panel).val();
                    var sidebarPosOption = $('.sidebar-pos-option', panel).val();
                    var sidebarStyleOption = $('.sidebar-style-option', panel).val();
                    var sidebarMenuOption = $('.sidebar-menu-option', panel).val();
                    var headerTopDropdownStyle = $('.page-header-top-dropdown-style-option', panel).val();


                    if (sidebarOption == "fixed" && headerOption == "default") {
                        alert('Default Header with Fixed Sidebar option is not supported. Proceed with Fixed Header with Fixed Sidebar.');
                        $('.page-header-option', panel).val("fixed");
                        $('.sidebar-option', panel).val("fixed");
                        sidebarOption = 'fixed';
                        headerOption = 'fixed';
                    }

                    resetLayout(); // reset layout to default state

                    if (layoutOption === "boxed") {
                        $("body").addClass("page-boxed");

                        // set header
                        $('.page-header > .page-header-inner').addClass("container");
                        var cont = $('body > .clearfix').after('<div class="container"></div>');

                        // set content
                        $('.page-container').appendTo('body > .container');

                        // set footer
                        if (footerOption === 'fixed') {
                            $('.page-footer').html('<div class="container">' + $('.page-footer').html() + '</div>');
                        } else {
                            $('.page-footer').appendTo('body > .container');
                        }
                    }

                    if (lastSelectedLayout != layoutOption) {
                        //layout changed, run responsive handler:
                        Metronic.runResizeHandlers();
                    }
                    lastSelectedLayout = layoutOption;

                    //header
                    if (headerOption === 'fixed') {
                        $("body").addClass("page-header-fixed");
                        $(".page-header").removeClass("navbar-static-top").addClass("navbar-fixed-top");
                    } else {
                        $("body").removeClass("page-header-fixed");
                        $(".page-header").removeClass("navbar-fixed-top").addClass("navbar-static-top");
                    }

                    //sidebarOption
                    Demo.SetSidebarMode(sidebarOption);


                    // top dropdown style
                    if (headerTopDropdownStyle === 'dark') {
                        $(".top-menu > .navbar-nav > li.dropdown").addClass("dropdown-dark");
                    } else {
                        $(".top-menu > .navbar-nav > li.dropdown").removeClass("dropdown-dark");
                    }

                    //footer
                    if (footerOption === 'fixed') {
                        $("body").addClass("page-footer-fixed");
                    } else {
                        $("body").removeClass("page-footer-fixed");
                    }

                    //sidebar style
                    Demo.SetSidebarStyle(sidebarStyleOption);

                    //sidebar menu
                    if (sidebarMenuOption === 'hover') {
                        if (sidebarOption == 'fixed') {
                            $('.sidebar-menu-option', panel).val("accordion");
                            alert("Hover Sidebar Menu is not compatible with Fixed Sidebar Mode. Select Default Sidebar Mode Instead.");
                        } else {
                            $(".page-sidebar-menu").addClass("page-sidebar-menu-hover-submenu");
                        }
                    } else {
                        $(".page-sidebar-menu").removeClass("page-sidebar-menu-hover-submenu");
                    }

                    Demo.SetSidebarPosition(sidebarPosOption);

                    Layout.fixContentHeight(); // fix content height
                    Layout.initFixedSidebar(); // reinitialize fixed sidebar
                };

                // handle theme colors
                var setColor = function (color) {
                    var style = (Metronic.isRTL() ? color + '-rtl' : color);
                    $('#style_color').attr("href", layoutCssPath + style + ".css");
                    return {style: style + ".css", color: color};
                };

                $('.toggler', panel)
                    .click(function () {
                        $('.toggler').hide();
                        $('.toggler-close').show();
                        $('.theme-panel > .theme-options').show();
                    });

                $('.toggler-close', panel)
                    .click(function () {
                        $('.toggler').show();
                        $('.toggler-close').hide();
                        $('.theme-panel > .theme-options').hide();
                    });

                $('.theme-colors > ul > li', panel)
                    .click(function () {
                        var color = $(this).attr("data-style");
                        var currentLayoutStyle = setColor(color);
                        $('ul > li', panel).removeClass("current");
                        $(this).addClass("current");

                        Demo._uiSettings.SetThemeColor(currentLayoutStyle.style, currentLayoutStyle.color);
                    });

                $('.layout-style-option')
                    .on('change',
                        function () {
                            var valLayoutOption = $('.layout-style-option option:selected').val();
                            Demo._uiSettings.SetThemeStyle(valLayoutOption);
                        });

                $('.sidebar-option')
                    .on('change',
                        function () {
                            var valOption = $('.sidebar-option option:selected').val();
                            Demo._uiSettings.SetSidebarOption(valOption);
                        });

                $('.sidebar-style-option')
                    .on('change',
                        function () {
                            var valStyleOption = $('.sidebar-style-option option:selected').val();
                            Demo._uiSettings.SetSidebarStyleOption(valStyleOption);
                        });

                $('.sidebar-pos-option')
                    .on('change',
                        function () {
                            var valPosOption = $('.sidebar-pos-option option:selected').val();
                            Demo._uiSettings.SetSidebarPosOption(valPosOption);
                        });

                $('.borders-option')
                    .on('change',
                        function () {
                            var valBorders = $('.borders-option option:selected').val();
                            Demo._uiSettings.SetSidebarBorders(valBorders);
                        });

                // set default theme options:

                if ($("body").hasClass("page-boxed")) {
                    $('.layout-option', panel).val("boxed");
                }

                if ($("body").hasClass("page-sidebar-fixed")) {
                    $('.sidebar-option', panel).val("fixed");
                }

                if ($("body").hasClass("page-header-fixed")) {
                    $('.page-header-option', panel).val("fixed");
                }

                if ($("body").hasClass("page-footer-fixed")) {
                    $('.page-footer-option', panel).val("fixed");
                }

                if ($("body").hasClass("page-sidebar-reversed")) {
                    $('.sidebar-pos-option', panel).val("right");
                }

                if ($(".page-sidebar-menu").hasClass("page-sidebar-menu-light")) {
                    $('.sidebar-style-option', panel).val("light");
                }

                if ($(".page-sidebar-menu").hasClass("page-sidebar-menu-hover-submenu")) {
                    $('.sidebar-menu-option', panel).val("hover");
                }

                $('.layout-option, .page-header-option, .page-header-top-dropdown-style-option, .sidebar-option, .page-footer-option, .sidebar-pos-option, .sidebar-style-option, .sidebar-menu-option, .borders-option', panel).change(setLayout);
            };

            Demo.GetUISettings = function () {
                return Demo._uiSettings;
            };

            Demo.SetUISettings = function (settings) {
                Demo._uiSettings = settings;
                // Demo.SetThemeColor(Demo._uiSettings.settings.theme, Demo._uiSettings.color);
                // Demo.SetThemeStyle(Demo._uiSettings.settings.style);
                // Demo.SetSidebarMode(Demo._uiSettings.settings.sidebarOption);
                // Demo.SetSidebarStyle(Demo._uiSettings.settings.sidebarStyleOption);
                // Demo.SetSidebarPosition(Demo._uiSettings.settings.sidebarPosOption);
                // Demo.SetThemeBorders(DEFAULT_UI_SETTINGS.SIDEBAR_BORDERS);



                if (Demo._uiSettings.Settings.Theme){
                    Demo.SetThemeColor(Demo._uiSettings.Settings.Theme, Demo._uiSettings.Settings.Color);
                }

                if (Demo._uiSettings.Settings.Style){
                    Demo.SetThemeStyle(Demo._uiSettings.Settings.Style);
                }

                if (Demo._uiSettings.Settings.SidebarOption){
                    Demo.SetSidebarMode(Demo._uiSettings.Settings.SidebarOption);
                }

                if (Demo._uiSettings.Settings.SidebarStyleOption){
                    Demo.SetSidebarStyle(Demo._uiSettings.Settings.SidebarStyleOption);
                }

                if (Demo._uiSettings.Settings.SidebarPosOption){
                    Demo.SetSidebarPosition(Demo._uiSettings.Settings.SidebarPosOption);
                }

                if (Demo._uiSettings.Settings.SidebarBorders) {
                    Demo.SetThemeBorders(Demo._uiSettings.Settings.SidebarBorders);
                }

                if (!Demo._uiSettings.Settings.CustomThemeName) {
                    Demo.SetCustomTheme([]);
                    return;
                }

                var customThemeName = Demo._uiSettings.Settings.CustomThemeName;
                var customTheme = Demo._uiSettings.GetCustomTheme(customThemeName);
                var customThemeSettings = customTheme ? customTheme.Settings : [];

                Demo.SetCustomTheme(customThemeSettings);
            };

            Demo.InitUI = function () {
                var uiSettingsJson = GlobalManager.Instance.GetGlobal(GLOBALS.THEME);
                var uiSettings = GenericDeserialize(JSON.parse(uiSettingsJson), UISettings) || new UISettings();
                Demo.SetUISettings(uiSettings);
            }

            Demo.SetCustomTheme = function (customThemeSettings) {
                var $style = $('#custom-theme');

                var css = '';

                var open = '{';
                var close = '}';

                for (var themeCss of customThemeSettings) {
                    var selector = themeCss.Selector;
                    var rule = themeCss.Rule;
                    var val = themeCss.Val;
                    var style = `${selector} ${open} ${rule}: ${val}; ${close}`;

                    css += style;
                }

                $style.empty();
                $style.append(document.createTextNode(css));
            }

            Demo.SetThemeColor = function (theme, color) {
                var themeUrl = __webpack_public_path__ + DEFAULT_UI_SETTINGS.THEME;
                if (theme){
                    themeUrl = __webpack_public_path__ + theme;
                }

                var themeColor = color || DEFAULT_UI_SETTINGS.COLOR;
                $('#style_color').attr('href', themeUrl);

                if (Demo._uiSettings) {
                    Demo._uiSettings.SetThemeColor(theme || DEFAULT_UI_SETTINGS.THEME, themeColor);
                }
            };

            // handle theme style
            Demo.SetThemeStyle = function (style) {
                var themeStyle = style || DEFAULT_UI_SETTINGS.STYLE;
                var file = (themeStyle === 'rounded' ? 'componentsRounded' : 'components');
                var layoutCssPath = __webpack_public_path__;

                file = (Metronic.isRTL() ? file + '-rtl' : file);
                var filePath = layoutCssPath + file + ".css";

                $('#style_components').attr("href", filePath);

                if ($.cookie) {
                    $.cookie('layout-style-option', themeStyle);
                }

                if (Demo._uiSettings) {
                    Demo._uiSettings.SetThemeStyle(themeStyle);
                }
            };

            // handle theme borders
            Demo.SetThemeBorders = function (borders) {
                var themeBorders = borders || DEFAULT_UI_SETTINGS.SIDEBAR_BORDERS;

                if (themeBorders === 'borders') {
                    $("body").addClass("border-theme");
                } else {
                    $("body").removeClass("border-theme");
                }

                if ($.cookie) {
                    $.cookie('borders-option', themeBorders);
                }

                if (Demo._uiSettings) {
                    Demo._uiSettings.SetSidebarBorders(themeBorders);
                }
            };


            Demo.SetSidebarMode = function (sidebarOption) {
                var themeSidebarOption = sidebarOption || DEFAULT_UI_SETTINGS.SIDEBAR_OPTION;
                if ($('body').hasClass('page-full-width') === false) {
                    if (themeSidebarOption === 'fixed') {
                        $("body").addClass("page-sidebar-fixed");
                        $("page-sidebar-menu").addClass("page-sidebar-menu-fixed");
                        $("page-sidebar-menu").removeClass("page-sidebar-menu-default");
                    } else {
                        $("body").removeClass("page-sidebar-fixed");
                        $("page-sidebar-menu").addClass("page-sidebar-menu-default");
                        $("page-sidebar-menu").removeClass("page-sidebar-menu-fixed");
                        $('.page-sidebar-menu').unbind('mouseenter').unbind('mouseleave');
                    }
                }

                if (Demo._uiSettings) {
                    Demo._uiSettings.SetSidebarOption(themeSidebarOption);
                }
            };


            Demo.SetSidebarStyle = function (sidebarStyleOption) {
                var themeSidebarStyle = sidebarStyleOption || DEFAULT_UI_SETTINGS.SIDEBAR_STYLE;
                //sidebar style
                if (themeSidebarStyle === 'light') {
                    $(".page-sidebar-menu").addClass("page-sidebar-menu-light");
                } else {
                    $(".page-sidebar-menu").removeClass("page-sidebar-menu-light");
                }

                if (Demo._uiSettings) {
                    Demo._uiSettings.SetSidebarStyleOption(themeSidebarStyle);
                }
            };

            Demo.SetSidebarPosition = function (sidebarPosOption) {
                var themeSidebarPosition = sidebarPosOption || DEFAULT_UI_SETTINGS.SIDEBAR_POSITION;
                //sidebar position
                if (Metronic.isRTL()) {
                    if (themeSidebarPosition === 'left') {
                        $("body").addClass("page-sidebar-reversed");
                        $('#frontend-link')
                            .tooltip('destroy')
                            .tooltip({
                                placement: 'right'
                            });
                    } else {
                        $("body").removeClass("page-sidebar-reversed");
                        $('#frontend-link')
                            .tooltip('destroy')
                            .tooltip({
                                placement: 'left'
                            });
                    }
                } else {
                    if (themeSidebarPosition === 'right') {
                        $("body").addClass("page-sidebar-reversed");
                        $('#frontend-link')
                            .tooltip('destroy')
                            .tooltip({
                                placement: 'left'
                            });
                    } else {
                        $("body").removeClass("page-sidebar-reversed");
                        $('#frontend-link')
                            .tooltip('destroy')
                            .tooltip({
                                placement: 'right'
                            });
                    }
                }

                if (Demo._uiSettings) {
                    Demo._uiSettings.SetSidebarPosOption(themeSidebarPosition);
                }
            }

            return Demo;
        })();
        exports.Demo = Demo;
    });