export const AGENDA_TYPES = {
	Todo: 'Todo',
	Appointment: 'Appointment',
	Task: 'Task',
	SpecialDay: 'SpecialDay',
	Holiday: 'Holiday',
	Illness: 'Illness',
	Recurrent: 'Recurrent'
};

export const AGENDA_LIFE_STATUSES = {
	Requested: 'Requested',
	Confirmed: 'Confirmed',
	Denied: 'Denied'
};
