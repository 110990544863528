import * as ko from 'knockout';
import * as _ from "underscore";

import {RenderModes, EVENTS} from 'Core/Constant'

import {LABELS} from "Core/Components/Translation/Locales";
import {TranslationManager} from "Core/Components/Translation/TranslationManager";

import {GeneralProperties} from 'Core/GeneralProperties/GeneralProperties';

import {BaseControl} from 'Core/Controls/BaseControl/BaseControl'
import {IControl} from 'Core/Controls/IControl';
import {IControlParam} from 'Core/Screens/IScreen'

import TabPageConfigJson  from "Core/Controls/TabPage/Config/tab-page-config.json";

import ViewTemplate from 'Core/Controls/TabPage/Templates/View.html'
import HelpViewTemplate from 'Core/Controls/TabPage/Templates/HelpView.html'
import DesignTemplate from 'Core/Controls/TabPage/Templates/Design.html'

ko.templates['Core/Controls/TabPage/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/TabPage/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/TabPage/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/TabPage/Templates/Edit'] = ViewTemplate;

export class TabPage extends BaseControl {
	
	private _isTabSecurityProperties: KnockoutObservable<boolean>;
	_tabSpreadsheetBackground: KnockoutObservable<string>;
	_hasSpreadsheet: boolean;

	constructor(params: IControlParam) {
		super(params, TabPageConfigJson);
		this._isTabSecurityProperties = ko.observable(false);
		this._tabSpreadsheetBackground = ko.observable(null);
		this._hasSpreadsheet = null;
		this.Init();

		this.GetTemplateName = ko.computed(() => {
			var template = RenderModes[this._renderMode()];
			var templateName = `Core/Controls/TabPage/Templates/${template}`;
			return templateName;
		});

	}

	ApplyProperties() {	}

	private Init(): void {
		this._isActive = ko.observable(false);
		this.AddEvent(EVENTS.ON_TAB_CLICK);
	}

	get IsActiveTabPage(): boolean {
		return this._isActive();
	}

	SetSpreadsheetBackground(color: string): void {
		this._tabSpreadsheetBackground(color);
	}
	SetHasSpreadsheet(data: boolean): void {
		this._hasSpreadsheet = data;
	}

	SetValue(value: any): void {}

	Click(): void {
		if (this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
		} else {
			this.Trigger(EVENTS.ON_TAB_CLICK);
		}
	}

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}

    private GetName(): string {
        var translationList = this._model().NameTranslations;
        let currentLang = TranslationManager.Instance.GetCurrentLanguage();
        let currentLangItem = _.find(translationList, (item) => {
            return currentLang.Id === item.Language.K_Language;
        });
        if (currentLangItem) {
            this._getCurrentName = currentLangItem.Translation;
        }

        if (this._getCurrentName) {
            return this._getCurrentName;
        } else {
            return this._model().Name;
        }
    }

	get Title(): string {
		return this.GetName() || TabPage.GetDefaultName();
	}

	IsTabSecurityProperties(): boolean {
		_.each(this.GeneralProperties.Groups, (group) => {
			let tabSecurityProperty = group.Properties.filter(property => property.Type === 'TabSecurity');

			if (tabSecurityProperty) {
				_.each(tabSecurityProperty, (propertyModel)=>{

					if (!!propertyModel.Value && propertyModel.Value.TabSecuritySettings.length >= 1){
						this._isTabSecurityProperties(true);
					} else {
						this._isTabSecurityProperties(false);
					}
				});

			} else {
				this._isTabSecurityProperties(false);
			}
		});
		return this._isTabSecurityProperties();
	}

	get TabSecurityProperties(): boolean {
		return this.IsTabSecurityProperties();
	}

	static GetDefaultName() {
		return LABELS.NEW_TAB;
	}

	IsValid(): boolean {
		var isValid = true;
		_.each(this._subControls(), control => {
			if (!control.IsValid()) {
				isValid = false;
				this.SetIsVisible(true);
			}
		});
		this._isValid(isValid);
		return true;
	}

	AddSubControl(control: IControl) {
		this._subControls.push(control);
	}
}