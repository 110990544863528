import * as $ from 'jquery';
import * as moment from 'moment';

import { DATE_FORMATS } from 'Core/Constants/DateTimeFormats';
import { FormatConverter } from 'FormatEditor/FormatConverter';

export class WeekFormatUtils{

    public static GetLocalizedWeekFormat() {
        return DATE_FORMATS.YYYY_WW.MomentFormat.replace(/-/g, FormatConverter.GetDateLocalizedSeparator());
    }

    public static PatchWeekFormatOptions(options: object) {
        return { ...options, format: 'L', calendarWeeks: true };
    }

    public static PatchWeekFormatInput(input: HTMLInputElement, format: string) {
        $(input)
            .data('getWeekFormat', (value: string) => {
                const valueMoment = moment(value, format);
                return valueMoment.isValid() ? valueMoment.format('L') : undefined;
            })
            .data('setWeekFormat', (value: string) => {
                const valueMoment = moment(value, 'L');
                return valueMoment.isValid() ? valueMoment.format(format) : undefined;
            });

        input.addEventListener(
            'input',
            () => {
                // Reset saved value if manually changed
                $(input).data('savedValue', null);
            },
            true
        );
    }
}
