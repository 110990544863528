import * as ko from 'knockout';

import {EventBus} from "Core/Common/EventBus/EventBus";
import {EventBusConsumer} from "Core/Common/EventBus/EventBusConsumer";

import {Product} from "./Product";

export class ViewModel extends EventBusConsumer {
    Products: KnockoutObservableArray<Product>;

    constructor(eventBus: EventBus) {
        super();

        this.Products = ko.observableArray([]);
        this.AssignEventBus(eventBus);
    }

    Dispose() {
        this.Products().forEach(product => product.Dispose());
        super.Dispose();
    }
}