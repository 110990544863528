import * as _ from 'underscore';

import { URL } from 'Core/Common/Url';
import { Request, IRequestOptions } from 'Core/Common/Request';
import { P } from 'Core/Common/Promise';
import { GenericDeserialize } from 'libs/cerialize';
import { DeduplicationSubjectsModel, GetMatchedRecords } from 'Core/ProfilePage/Deduplication/Models/DeduplicationSubjectsModel';
//import { MatchedRecordsModel } from 'Core/ProfilePage/Deduplication/Models/MatchedRecordsModel';



interface IMatchedRecordsModel {
	EntityId: number;
	PageNumber: number;
	RecordsPerPage: number;
	Sort: Array<any>;
}

interface IMatchAllModel {
	EntityId: number;
}

interface IDeleteAllModel {
	EntityId: number;
}

export enum Actions {
	leftSide = 0,
	rightSide = 1,
	merge = 2
}



interface IDeduplicationMatchedRecordModel {
	EntityId: number,
	KSeq: number
}

interface IDeduplicationRecordModel {
    EntityId: number,
    LeftRecordId: number,
    RightRecordId: number
}


interface IDeduplicationAllModel {
	EntityId: number,
	MinimumRate: number
}

interface IDeduplicationFieldModel {
	Action: number,
	FieldId: number
}

interface IDeduplicationMatchedModel {
	EntityId: number,
	KSeq: number,
	Changes: Array<IDeduplicationFieldModel>
}

interface IDeduplicationModel {
    EntityId: number,
    LeftRecordId: number,
    RightRecordId: number,
    Changes: Array<IDeduplicationFieldModel>
}


export class DeduplicationStore {
	static GetDeduplicationSubjects(): P.Promise<Array<DeduplicationSubjectsModel>> {
		var deferredResult = P.defer<Array<DeduplicationSubjectsModel>>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: "api/DeduplicationApi/GetCurrentEntityTypeDeduplicationSubjects",
				dataType: "json",
				type: "GET"
			}
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
			});

		return deferredResult.promise();
	}

	static GetMatchedRecords(params: IMatchedRecordsModel): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: "api/DeduplicationApi/GetMatchedRecords",
				dataType: "json",
				type: "POST"
			}, params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
			});

		return deferredResult.promise();
	}


	static DeduplicateAll(params: IDeduplicationAllModel): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: "api/DeduplicationApi/DeduplicateAll",
				dataType: "json",
				type: "POST"
			}, params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
			});

		return deferredResult.promise();
	}

	static MatchAll(params: IMatchAllModel): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: "api/DeduplicationApi/MatchAll",
				type: "POST"
			}, params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
			})
			.fail(error => {
				deferredResult.reject(JSON.parse(error.message).Message);
			});

		return deferredResult.promise();
	}

	static DeleteAll(params: IDeleteAllModel): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: "api/DeduplicationApi/DeleteAll",
				type: "POST"
			}, params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
			})
			.fail(error => {
				deferredResult.reject(JSON.parse(error.message).Message);
			});

		return deferredResult.promise();
	}

	static GetDeduplicationMatchedRecordModel(params: IDeduplicationMatchedRecordModel): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
                url: "api/DeduplicationApi/GetDeduplicationMatchedRecordModel",
				dataType: "json",
				type: "POST"
			}, params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
			});

		return deferredResult.promise();
    }

    static GetDeduplicationRecordModel(params: IDeduplicationRecordModel): P.Promise<any> {
        var deferredResult = P.defer<any>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: "api/DeduplicationApi/GetDeduplicationRecordModel",
                dataType: "json",
                type: "POST"
            }, params
        };

        Request.Send(requestParams)
            .then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(data);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
            });

        return deferredResult.promise();
    }



	static DeduplicateMatched(params: IDeduplicationMatchedModel): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: "api/DeduplicationApi/DeduplicateMatched",
				dataType: "json",
				type: "POST"
			}, params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
			});

		return deferredResult.promise();
    }

    static Deduplicate(params: IDeduplicationModel): P.Promise<any> {
        var deferredResult = P.defer<any>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: "api/DeduplicationApi/Deduplicate",
                dataType: "json",
                type: "POST"
            }, params
        };

        Request.Send(requestParams)
            .then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(data);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
            });

        return deferredResult.promise();
    }



	static GetMatchingStatus(entityId: number): P.Promise<any> {
        var deferredResult = P.defer<any>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: "api/DeduplicationApi/GetMatchingStatus",
                dataType: "json",
                type: "GET"
			}, params: { EntityId: entityId }
        };

        Request.Send(requestParams)
            .then(data => deferredResult.resolve(data));

        return deferredResult.promise();
    }	
}