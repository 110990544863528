import * as ko from 'knockout';
import {deserialize} from 'libs/cerialize';

export class CustomColumnValueModel {

    constructor(){
        this.Value = ko.observable('');
    }

    Value: KnockoutObservable<string>;

    @deserialize
    FieldType: string;

    @deserialize
    FieldName: string;

    @deserialize
    FieldFormat: string;

    public static OnDeserialized(instance : CustomColumnValueModel, json : any){
        instance.Value(json.Value);
    }
}