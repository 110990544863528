import * as ko from 'knockout';

import { IconModel } from 'Core/Controls/BaseControl/Models/IconModel';

import { SourceTableTypeViewModel } from "./SourceTableTypeViewModel";
import {Icon} from "Core/Icon/Icon";

export class SourceTableViewModel {
	Expanded: KnockoutObservable<boolean>;

	constructor(
		public Id: number,
		public Name: string,
		public TranslatedName: string,
		public Icon: IconModel,
		public Types: SourceTableTypeViewModel[]) {

		this.Expanded = ko.observable(true);
	}

	get TotalAmount() {
		return this.Types.map(type => type.TotalAmount).reduce((sum, current) => sum + current, 0);
	}

	ToggleExpansion() {
		this.Expanded(!this.Expanded());
	}

	GetIconTemplate() {
		let classes: string = this.Icon && this.Icon.IsImage ? 'height-20x20' : '';
		return new Icon(this.Icon).GetIconTemplate(classes);
	}
}