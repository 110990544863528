import {deserialize} from 'libs/cerialize'
import {deserializeAs} from 'libs/cerialize'

export class EntityTypeModel {
    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    IsRoot: boolean;

    @deserialize
    LifeStatusId: number;

    @deserialize
    LifeStatusName: string;
}