import * as ko from "knockout";

import {IButtonConfig} from "Core/Controls/PortletBar/Interfaces/IButtonConfig";
import {ButtonViewModel} from "Core/Controls/PortletBar/Models/Buttons/ButtonViewModel";

import CollapseButtonTemplate from "Core/Controls/PortletBar/Templates/Buttons/Collapse/Button.html";
import CollapsePropsTemplate from "Core/Controls/PortletBar/Templates/Buttons/Collapse/Props.html";

ko.templates["Core/Controls/PortletBar/Templates/Buttons/Collapse/Button"] = CollapseButtonTemplate;
ko.templates["Core/Controls/PortletBar/Templates/Buttons/Collapse/Design"] = CollapseButtonTemplate;
ko.templates["Core/Controls/PortletBar/Templates/Buttons/Collapse/Props"] = CollapsePropsTemplate;

export class CollapseButtonViewModel extends ButtonViewModel {
	IsCollapsed: boolean;

	constructor(buttonName: string, buttonConfig: IButtonConfig) {
		super(buttonName, buttonConfig);
    }
}