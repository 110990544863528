import * as ko from 'knockout';
import * as _ from 'underscore';

import {Event} from 'Core/Common/Event';
import {Modal} from 'Core/Common/Modal';

import {Resource, Task} from 'ganttChart';

import {AssignmentsPopupModel} from './Models/AssignmentsPopupModel';
import {AssignmentModel} from './Models/AssignmentModel';

import Template from './Templates/Template.html';

ko.templates['Core/Components/Controls/GanttChart/AssignmentsPopup/Templates/Template'] = Template;

export class AssignmentsPopup extends Event {
    private _model: AssignmentsPopupModel;
    private _modal: Modal;

    constructor(private _task: Task<number>, resources: Resource<number>[]) {
        super();

        const assignments = this._task.assigs.map(a => {
            const resource = _.find(resources, r => r.id === a.resourceId && r.entity === a.entity);
            return new AssignmentModel(a.id, resource.id, resource.name, resource.entity, a.effort);
        });

        this._model = new AssignmentsPopupModel(assignments, resources);
    }

    GetTemplateName() {
        return 'Core/Components/Controls/GanttChart/AssignmentsPopup/Templates/Template';
    }

    AfterRender() {

    }

    Show() {
        this._modal = new Modal({
            width: 700,
            height: 500
        }, false);

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);

        this._modal.Show();
    }

    Close() {
        this._modal.Close()
    }

    SaveChanges() {
        this._task.assigs = this._model.GetAssignments().map(a => {
            return {
                id: a.Id,
                resourceId: a.ResourceId,
                entity: a.ResourceEntity,
                effort: a.Effort * 60 * 60 * 1000,
                roleId: null
            }
        });

        this.Trigger('SAVE_CHANGES', this._task);
    }
}