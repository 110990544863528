//Store models
import { WeekDataResponseModel } from "Core/Components/Controls/TimeWriting/Models/Store/Year/Response/WeekDataResponseModel";

//View models
import { Week } from "Core/Components/Controls/TimeWriting/Models/View/YearDataViewModel";

export class WeekMappings {
	static OnViewModel(model: WeekDataResponseModel): Week {
        const week = new Week();

		week.Number = model.WeekNumber;
		week.Booked = Math.round(model.Booked * 100) / 100;
		week.Saldo = Math.round(model.Saldo * 100) / 100;
		week.IsEditable = model.IsEditable;
		week.Empty = true;
		week.HasDisapprovedHours = model.HasDisapprovedHours;

		return week;
	}
}