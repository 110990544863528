import * as _ from 'underscore';

import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize} from 'libs/cerialize';
import {OperationResultModel} from 'Core/Models/OperationResultModel';
import {EditQueryModel} from 'QueryBuilder/Models/EditQueryModel';

export interface IGetQueryRequestModel {
	Id: number;
};

export class GetQueryStore {
	static Get(params: IGetQueryRequestModel): P.Promise<EditQueryModel> {

		var deferredResult = P.defer<EditQueryModel>();
		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.QUERY_BUILDER.EDIT_QUERY,
				dataType: 'json',
				type: 'GET'
			}, params: params
		};
		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(this.Deserialize(data));
			}).fail(error=>{
				const errorObject = JSON.parse(error.message);
				const errorMessage = errorObject.Message;
				deferredResult.reject({message: errorMessage});
			});

		return deferredResult.promise();
	}

	static GetToolbarQuery(params: IGetQueryRequestModel): P.Promise<EditQueryModel> {
		var deferredResult = P.defer<EditQueryModel>();
		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.QUERY_BUILDER.GET_TOOLBAR_QUERY,
				dataType: 'json',
				type: 'GET'
			}, params: params
		};
		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(this.Deserialize(data));
			}).fail(error=>{
				const errorObject = JSON.parse(error.message);
				const errorMessage = errorObject.Message;
				deferredResult.reject({message: errorMessage});
			});

		return deferredResult.promise();
	}

	static Deserialize(jsonObject: any): EditQueryModel {
		const model = <EditQueryModel>Deserialize(jsonObject, EditQueryModel);
		return model;
	}
}