//Libs
import * as ko from 'knockout';
import * as _ from 'underscore';
import * as moment from "moment";

//Constants
import {FIELD_TYPES} from "Core/Constant";
import {DATE_FORMATS} from "Core/Constants/DateTimeFormats";

//Translations
import {TranslationItem} from "Core/Components/TranslationFieldEditor/TranslationItem";
import {LanguageModel} from "Core/Components/Translation/Models/LanguageModel";
import {ITranslationValue} from "Core/Components/TranslationFieldEditor/ITranslationValue";

//Utils
import {FormatConverter} from "FormatEditor/FormatConverter";

//Store models
import {DayReservationResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Response/DayReservationResponseModel";
import {
    FieldDataTranslation,
    LanguageData,
    SaveOptionalDataRequestModel,
    SaveReservationRequestModel
} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Request/SaveReservationRequestModel";
import {DeleteReservationRequestModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Request/DeleteReservationRequestModel";

//ViewModels
import {OptionalFieldData, Reservation} from "Core/Components/Controls/TimeWriting/Models/View/DayDataViewModel"

//Mappings
import {SubjectMappings} from "Core/Components/Controls/TimeWriting/Mappings/Day/SubjectMappings";
import {LookupValueMappings} from "Core/Components/Controls/TimeWriting/Mappings/Common/LookupValueMappings";
import {TranslationManager} from "../../../../Translation/TranslationManager";

export class ReservationMappings {
    static OnViewModel(model: DayReservationResponseModel): Reservation {
        const viewModel = new Reservation();

        viewModel.Id = model.Id;
        viewModel.AgendaId = model.AgendaId;
        viewModel.Start(new Date(FormatConverter.CorrectTimezone(model.Start.toString())));
        viewModel.TimeSpent(model.TimeSpent);
        viewModel.Subject(SubjectMappings.OnViewModel(model.Subject));
        viewModel.Description(model.Description);
        viewModel.Ovw(model.OverWorkIndicator ? LookupValueMappings.OnViewModel(model.OverWorkIndicator) : null);
        viewModel.Pay(model.PaymentIndicator ? LookupValueMappings.OnViewModel(model.PaymentIndicator) : null);
        viewModel.HourKind(model.HourKindIndicator ? LookupValueMappings.OnViewModel(model.HourKindIndicator) : null);
        viewModel.Accepted = model.Accepted;
        viewModel.TimeStatus(model.TimeStatus);
        viewModel.OptionalData = model.OptionalData.map(field => {
            let value: any = field.FieldValue;
            if (field.FieldTypeName === FIELD_TYPES.Lookup) {
                value = {
                    FieldValue: field.FieldValue,
                    DisplayValue: field.DisplayValue
                }
            } else if (field.FieldTypeName === FIELD_TYPES.MultiSelect) {
                value = {
                    FieldValue: field.FieldValue,
                    DisplayValue: field.DisplayValue
                }
            }else if (field.FieldTypeName === FIELD_TYPES.YesNo) {
                value = !!field.FieldValue && field.FieldValue !== "False";
            } else if (field.FieldTypeName === FIELD_TYPES.DateTime) {
                value = field.FieldValue!= null ? new Date(FormatConverter.CorrectTimezone(field.FieldValue)) : null;
            } else if (field.FieldTypeName === FIELD_TYPES.Date) {
                value = field.FieldValue && new Date(field.FieldValue);
            }

            return new OptionalFieldData({
                FieldName: field.FieldName,
                FieldType: field.FieldTypeName,
                FieldValue: ko.observable(value),
                Translations: ko.observableArray(field.FieldValueTranslations.map(translationValue => this.OnTranslationItem(translationValue)))
            })
        });
        return viewModel;
    }

    static OnTranslationItem(translationValue: ITranslationValue) {
        const language = _.find(TranslationManager.Instance.Languages, language => language.Id === translationValue.LanguageId);
        const translationItem = new TranslationItem(language);
        translationItem.Value(translationValue.Value);

        return translationItem;
    }

    static OnSaveModel(model: Reservation): SaveReservationRequestModel {
        const requestModel = new SaveReservationRequestModel();

        requestModel.Id = model.Id;
        requestModel.AgendaId = model.AgendaId;
        requestModel.Start = FormatConverter.ConvertToUTC(moment(model.Start()).format(DATE_FORMATS.DATE_TIME.Format), DATE_FORMATS.DATE_TIME.Format);
        requestModel.TimeSpent = moment(model.TimeSpent()).format(DATE_FORMATS.DATE_TIME.Format);
        requestModel.Subject = SubjectMappings.OnSaveModel(model.Subject());
        requestModel.Description = model.Description();

        const ovwId = (model.Ovw() && model.Ovw().FieldValue) ? model.Ovw().FieldValue : null;
        const payId = (model.Pay() && model.Pay().FieldValue) ? model.Pay().FieldValue : null;
        const hourKindId = (model.HourKind() && model.HourKind().FieldValue) ? model.HourKind().FieldValue : null;

        requestModel.OverWorkIndicatorId = ovwId;
        requestModel.PaymentIndicatorId = payId;
        requestModel.HourKindIndicatorId = hourKindId;

        requestModel.OptionalData = model.OptionalData.map(data => {
            let value = null;

            switch (data.FieldType) {
                case FIELD_TYPES.Lookup:
                    value = data.FieldValue() && data.FieldValue().FieldValue;
                    break;

                case FIELD_TYPES.MultiSelect:
                    value = data.FieldValue() && data.FieldValue().FieldValue;
                    break;

                case FIELD_TYPES.DateTime:
                    const dateTime = moment(data.FieldValue()).utc();
                    value = dateTime.isValid() ? dateTime.format(DATE_FORMATS.DATE_TIME.Format) : null;
                    break;

                case FIELD_TYPES.Time:
                    value = data.FieldValue() && moment(data.FieldValue(), DATE_FORMATS.TIME.MomentFormat).format(DATE_FORMATS.TIME.Format);
                    break;

                case FIELD_TYPES.Date:
                    const date = moment(data.FieldValue());
                    value = date.isValid() ? date.format(DATE_FORMATS.SHORT_DATE.Format) : null;
                    break;

                case FIELD_TYPES.TimeSpan:
                    value = data.FieldValue() && moment(data.FieldValue(), DATE_FORMATS.DATE_TIME.MomentFormat).format(DATE_FORMATS.DATE_TIME.Format);
                    break;

                default:
                    value = data.FieldValue();
                    break;
            }

            const translations = data.Translations().map(item =>
                new FieldDataTranslation(
                    new LanguageData(item.Language.Id, item.Language.Name, item.Language.ShortName),
                    item.Value()));

            return new SaveOptionalDataRequestModel(data.FieldName, value, translations);
        });

        return requestModel;
    }

    static OnDeleteModel(model: Reservation): DeleteReservationRequestModel {
        const requestModel = new DeleteReservationRequestModel();

        requestModel.Id = model.Id;

        return requestModel;
    }
}