import { Request, IRequestOptions } from "Core/Common/Request";
import { P } from "Core/Common/Promise";
import { URL } from "Core/Common/Url";
import { GenericDeserialize } from "libs/cerialize";
import { RssFeedModel } from "Core/Controls/RssFeed/Models/RssFeedModel";
import { IRssFeedRequestModel } from "Core/Controls/RssFeed/Models/IRssFeedRequestModel";

export class RssFeedStore {
	static GetRssFeed(params: IRssFeedRequestModel): P.Promise<any> {
		let deferredResult = P.defer();

		let requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.RSSFEED.GET_RSS_FEED,
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					var result = GenericDeserialize<RssFeedModel>(data.ResultObject, RssFeedModel);
					deferredResult.resolve(result);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			}).fail((err) => deferredResult.reject({message: err.message}));

		return deferredResult.promise();
	}
}