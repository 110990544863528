import * as ko from 'knockout'

import { BaseControl } from "Core/Controls/BaseControl/BaseControl";
import { IControlParam } from "Core/Screens/IScreen";

import ConsultScreenButtonConfig from "Core/Controls/ButtonConsultScreen/Configs/consult-screen-button-config.json";

import DesignTemplate from "Core/Controls/ButtonConsultScreen/Templates/Design.html";
import EditTemplate from "Core/Controls/ButtonConsultScreen/Templates/View.html";
import ToolBarTemplate from "Core/Controls/ButtonConsultScreen/Templates/Toolbar.html";
import ViewTemplate from "Core/Controls/ButtonConsultScreen/Templates/View.html";
import HelpViewTemplate from "Core/Controls/ButtonConsultScreen/Templates/HelpView.html";
import * as _ from "underscore";

ko.templates["Core/Controls/ButtonConsultScreen/Templates/Design"] = DesignTemplate;
ko.templates["Core/Controls/ButtonConsultScreen/Templates/Edit"] = EditTemplate;
ko.templates["Core/Controls/ButtonConsultScreen/Templates/ToolBar"] = ToolBarTemplate;
ko.templates["Core/Controls/ButtonConsultScreen/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/ButtonConsultScreen/Templates/HelpView"] = HelpViewTemplate;


export class ButtonConsultScreen extends BaseControl {
	private _config: any;

	constructor(params: IControlParam) {
        super(params, ConsultScreenButtonConfig);
        this._enabled = ko.observable(this.IsConsultScreenExist);
		this._style = ko.computed(() => {
			return {
				backgroundColor: this._backgroundColor(),
				color: this._color(),
				borderColor: this._borderColor(),
				borderWidth: this._border() ? '1px' : '0',
				padding: this._border() ? '6px 14px' : '7px 14px'
			};
		});

		this.Init();
	}

	private Init(): void {
		this.ApplyProperties();
	}

	ApplyProperties(){
		if (this.Properties) {
			this.AssignProperty('Styling', 'BackgroundColor', this._backgroundColor);
			this.AssignProperty('Styling', 'TextColor', this._color);
			this.AssignProperty('Styling', 'Border', this._border);
			this.AssignProperty('Styling', 'BorderColor', this._borderColor);
		}
	}

	private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
		if (this.Properties[groupedBy]) {
			_.each(this.Properties[groupedBy].Properties,
				(property: any) => {
					if (property.hasOwnProperty(propertyName)) {
						propertyHolder(property[propertyName]);
					}
				});
		}
	}

	OnClick() {
		if (this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
		} else {
			const screen = this._form.GetScreen();
			screen.Trigger("SHOW_CONSULT_SCREEN");
		}
	}
}