import { deserialize } from "libs/cerialize";

export class TriggerDto {
    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    Description: string;

    @deserialize
    Group: string;
}