import * as _ from 'underscore';

import { FieldInfo } from 'QueryBuilder/CrossTableConfig/FieldInfo';
import { RelationTypeValueModel } from 'QueryBuilder/Models/RelationTypeValueModel';
import { QueryBuilderStore } from 'QueryBuilder/Stores/QueryBuilderStore';
import { CrossValueSettingsModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryColumnModel';
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";

export class CrossValueConfig{
    private _field: FieldInfo;
    private _relationTypes: KnockoutObservableArray<RelationTypeValueModel>;
    private _labels = LABELS;

    constructor(field: FieldInfo){
        this._field = field;
        this._relationTypes = ko.observableArray([]);

        (async () => await this.LoadTypes())();
    }

    async LoadTypes(){
        if(!this._field) return;
        
		let relationTypes = await QueryBuilderStore.GetRelationTypes({ LinkTableId:  this._field.Entity.Metadata.Id });

        _.each(relationTypes, (type)=>{
            let config = _.find(this._field.Column.CrossValueSettings, (setting) => setting.RelationTypeValueId === type.RelationTypeValueId);
            
            if(config){
                type.ShowEditRelationButton(config.ShowEditRelationButton);
                type.ShowNexRelationButton(config.ShowNexRelationButton);
            }else{
                type.ShowEditRelationButton(true);
                type.ShowNexRelationButton(true);
            }

            if(!config){
                config = new CrossValueSettingsModel({ RelationTypeValueId: type.RelationTypeValueId, ShowNexRelationButton: true, ShowEditRelationButton: true });
                this._field.Column.CrossValueSettings.push(config);
            } 

            type.ShowEditRelationButton.subscribe((newValue)=>{
                config.ShowEditRelationButton = newValue;
            });

            type.ShowNexRelationButton.subscribe((newValue)=>{
                config.ShowNexRelationButton = newValue;
            });

        });

        this._relationTypes(relationTypes);
    }
}