import {deserialize, serialize} from 'libs/cerialize';

export class CustomThemeSetting {
    @serialize
    @deserialize
    Id: string;

    @serialize
    @deserialize
    Selector: string;

    @serialize
    @deserialize
    Rule: string;

    @serialize
    @deserialize
    Val: string;

    @serialize
    @deserialize
    Transparent: string;
}