import {Request} from 'Core/Common/Request';

import {IConvertToInvoiceParams} from "./Models/IConvertToInvoiceParams";

export class InvoicingStore {
	static CreateInvoices(params: IConvertToInvoiceParams) {
		return Request.Send({
			proxy: {
				url: 'api/FinancialApi/CreateInvoices',
				type: 'POST'
			},
			params: params
		});
	}
}