import * as ko from 'knockout';

import {ConsultScreen} from "Core/Screens/ConsultScreen/ConsultScreen";
import {ScreenModel} from 'Core/Models/Screens/ScreenModel';
import {LABELS} from "Core/Components/Translation/Locales";
import {ScreenDataModel} from "Core/ScreenManager/Models/ScreenDataModel";
import {RightsModel} from "Core/ScreenManager/Models/RightsModel";
import {ScreenTypes} from "Core/Common/Enums/ScreenTypes";
import {SubFormModel} from "Core/Models/Screens/SubFormModel";
import {ControlModel} from "Core/Controls/BaseControl/Models/ControlModel";
import {CONTROL_TYPES} from "Core/Constant";

import EmptyScreenTemplate
    from 'Core/Screens/Templates/AdditionalConsultScreen/EmptyScreen/EmptyScreen.html';

ko.templates['Core/Screens/Templates/AdditionalConsultScreen/EmptyScreen/EmptyScreen'] = EmptyScreenTemplate;

export class EmptyScreen extends ConsultScreen {
    private _emptyBar: boolean;

    constructor(screenModel: ScreenModel) {
        super(screenModel);
        this._emptyBar = true;
    }

    GetTemplateName() {
        return 'Core/Screens/Templates/AdditionalConsultScreen/EmptyScreen/EmptyScreen';
    }

    AfterRender(el: HTMLElement[]) {
        super.AfterRender(el);
        this.SearchRecord();
    }

    static EmptyScreenModel(model): ScreenModel {
        let screenModel = new ScreenModel();
        screenModel.Data = new ScreenDataModel();
        screenModel.Data.RecordId = 0;
        screenModel.Data.Rights = new RightsModel();
        screenModel.Data.Rights.IsRecordSecurityOk = true;

        screenModel.EntityIcon = model.EntityIcon;
        screenModel.ScreenTypeName = ScreenTypes[ScreenTypes.EmptyScreen];
        screenModel.EntityId = model.EntityId;
		screenModel.EntityName = model.EntityName;
		screenModel.EntityNameTranslation = model.EntityNameTranslation;
        screenModel.ActionBar = new SubFormModel();

        let controlModel = new ControlModel();

        const searchControl = _.find(model.ActionBar.Controls, control => {
            const searchControlModel = control as ControlModel;
            return searchControlModel && searchControlModel.TypeName === CONTROL_TYPES.Search;
        }) as ControlModel;

        if (searchControl) {
            controlModel.Id = searchControl.Id;
            controlModel.Properties = searchControl.Properties;
        }

        controlModel.TypeName = CONTROL_TYPES.Search;

        screenModel.ActionBar.Controls = [controlModel]

        return screenModel;
    }

    OpenSearch(){
        this.SearchRecord();
    }
}