import {ScreenTypes} from "Core/Common/Enums/ScreenTypes";
import {BaseControl} from "Core/Controls/BaseControl/BaseControl";
import {BaseScreen} from "Core/Screens/BaseScreen";
import {IControlParam} from "Core/Screens/IScreen";
import {RenderModes, CONTROL_TYPES } from "Core/Constant";
import {ControlModel} from "Core/Controls/BaseControl/Models/ControlModel";
import {Search} from "Core/Controls/Search/Search";
import {IForm} from "Core/Screens/IScreen";
import {ButtonSave} from "Core/Controls/ButtonSave/ButtonSave";
import {ButtonCancel} from "Core/Controls/ButtonCancel/ButtonCancel";
import { ButtonFollowUp } from "Core/Controls/ButtonFollowUp/ButtonFollowUp";
import {IStaticControlsPosition, StaticControlsProvider} from './StaticControlsProvider';
import {ControlFactory} from './ControlFactory';

export class StaticControlsInitializer {
	private static _initialized: boolean;
	private static _controlsDictionaryDelegate: { [screenType: string]: (screen: BaseScreen) => void };

	static AddStaticControls(screen: BaseScreen) {
		this.Init();
		let screenType = screen.GetType();
		return this._controlsDictionaryDelegate[screenType](screen);
	}

	private static Init() {
		if (!this._initialized) {
			this._controlsDictionaryDelegate = {};
			this._controlsDictionaryDelegate[ScreenTypes[ScreenTypes.ConsultScreen]] = this.AddForConsultScreen.bind(this);
			this._controlsDictionaryDelegate[ScreenTypes[ScreenTypes.SpecialScreen]] = this.AddForSpecialScreen.bind(this);
			this._controlsDictionaryDelegate[ScreenTypes[ScreenTypes.Dashboard]] = this.AddForDashboard.bind(this);
			this._controlsDictionaryDelegate[ScreenTypes[ScreenTypes.ListScreen]] = this.AddForListScreen.bind(this);
			this._controlsDictionaryDelegate[ScreenTypes[ScreenTypes.EditScreen]] = this.AddForEditScreen.bind(this);
			this._controlsDictionaryDelegate[ScreenTypes[ScreenTypes.Portlet]] = this.AddForPortlet.bind(this);
			this._controlsDictionaryDelegate[ScreenTypes[ScreenTypes.FormDesigner]] = this.AddForFormDesigner.bind(this);
			this._controlsDictionaryDelegate[ScreenTypes[ScreenTypes.LinkEditor]] = this.AddForLinkEditor.bind(this);
			this._controlsDictionaryDelegate[ScreenTypes[ScreenTypes.LookupEditorNewRecord]] = this.AddForLookupEditorNewRecord.bind(this);
			this._controlsDictionaryDelegate[ScreenTypes[ScreenTypes.QueryScreen]] = this.AddForQueryScreen.bind(this);
			this._controlsDictionaryDelegate[ScreenTypes[ScreenTypes.CardScreen]] = this.AddForCardScreen.bind(this);
			this._controlsDictionaryDelegate[ScreenTypes[ScreenTypes.ProcessCardPage]] = this.AddForProcessCardPage.bind(this);
			this._controlsDictionaryDelegate[ScreenTypes[ScreenTypes.StepsScreen]] = this.AddForStepsScreen.bind(this);
			this._controlsDictionaryDelegate[ScreenTypes[ScreenTypes.EmptyScreen]] = this.AddForEmptyScreenScreen.bind(this);
		}
	}

	private static AddForQueryScreen(screen: BaseScreen) {}

	private static AddForCardScreen(screen: BaseScreen) {}

	private static AddForEmptyScreenScreen(screen: BaseScreen) {}

    private static AddForConsultScreen(screen: BaseScreen) {
        const staticControlsPosition = StaticControlsProvider.ForConsultScreen(screen.ShowFollowUp);
        this.RegisterStaticControls(staticControlsPosition, screen);
	}

	private static AddForSpecialScreen(screen: BaseScreen) {
	}

	private static AddForDashboard(screen: BaseScreen) {
	}

	private static AddForListScreen(screen: BaseScreen) {
	}

	private static AddForEditScreen(screen: BaseScreen) {
		const staticControlsPosition = StaticControlsProvider.ForEditScreen(screen.ShowFollowUp, screen.IsDashMain);
		this.RegisterStaticControls(staticControlsPosition, screen);
	}

	private static AddForLinkEditor(screen: BaseScreen) {
		const staticControlsPosition = StaticControlsProvider.ForLinkEditor();
		this.RegisterStaticControls(staticControlsPosition, screen);
	}

	private static AddForLookupEditorNewRecord(screen: BaseScreen) {
		const staticControlsPosition = StaticControlsProvider.AddForLookupEditorNewRecord();
		this.RegisterStaticControls(staticControlsPosition, screen);
	}

	private static AddForPortlet(screen: BaseScreen) { }

	private static AddForFormDesigner(screen: BaseScreen) { }

	private static AddForProcessCardPage(screen: BaseScreen) { }

	private static AddForStepsScreen(screen: BaseScreen) { }

	private static BuildControl(model: ControlModel, renderMode: RenderModes, subForm: IForm) {
	    return ControlFactory.CreateControl({
            Model: model,
            Form: subForm,
            RenderMode: renderMode,
            IsStatic: true
        });
    }

    private static RegisterStaticControls(staticControlsPosition: IStaticControlsPosition, screen: BaseScreen) {
		staticControlsPosition.ActionBar.reverse().forEach(controlModel => {
            const control = this.BuildControl(controlModel, screen.RenderMode, screen.ActionBar);
			screen.ActionBar && screen.ActionBar.AddStaticControl(control);
        });

		staticControlsPosition.SubForms.forEach((subForm, subFormIndex) => subForm.reverse().forEach(controlModel => {
            const control = this.BuildControl(controlModel, screen.RenderMode, screen.SubForms[subFormIndex]);
            screen.SubForms[subFormIndex].AddStaticControl(control);
        }));

		staticControlsPosition.BottomBar.reverse().forEach(controlModel => {
            const control = this.BuildControl(controlModel, screen.RenderMode, screen.BottomBar);
			screen.BottomBar && screen.BottomBar.AddStaticControl(control);
        })
    }
}