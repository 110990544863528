import * as ko from "knockout";
import * as $ from "jquery";

export class MultilineEllipsis {
    static Init() {
        ko.bindingHandlers.truncate = {
            update: (element, valueAccessor) => {
                var value = valueAccessor();
                var $element = $(element);
                $element.text(value());
                while (element.scrollHeight > element.offsetHeight) {
                    $element.text(function (index, text) {
                        return text.replace(/\W*\s(\S)*$/, '...');
                    });
                }
            }
        };
    }
}