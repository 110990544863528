import * as ko from 'knockout';

export class NewPersonalDataRoleModel {
	FieldCollectionName: KnockoutObservable<string>;

	constructor() {
		this.FieldCollectionName = ko.observable(null).extend({
			required: {
				message: 'Collection name required'
			}
		});
	}
}