export var PUB_SUB_EVENTS = {
	GO_TO_RECORD_SCREEN: 'GO_TO_RECORD_SCREEN',
	GO_TO_EDIT_SCREEN: 'GO_TO_EDIT_SCREEN',
	GO_TO_CONSULT_SCREEN: 'GO_TO_CONSULT_SCREEN',
	RELOAD_SCREEN: 'RELOAD_SCREEN',
	SCREEN_OPENED: 'SCREEN_OPENED',
	SCREEN_CLOSED: 'SCREEN_CLOSED',
	CHANGE_SCREEN: 'CHANGE_SCREEN',
	REFRESH_MAIN_MENU: 'REFRESH_MAIN_MENU',
	GO_TO_SCREEN_BY_TYPE: 'GO_TO_SCREEN_BY_TYPE',
	REFRESH_SECURITY_PROFILE: 'REFRESH_SECURITY_PROFILE',
	CLOSE_POPUPS: 'CLOSE_POPUPS',
	EXECUTE_SCRIPT: 'EXECUTE_SCRIPT'
};