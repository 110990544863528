import * as ko from 'knockout';

import {BaseControl, IControlValue} from "Core/Controls/BaseControl/BaseControl";
import {IControlParam} from "Core/Screens/IScreen";
import {LABELS} from "Core/Components/Translation/Locales";
import {RenderModes} from "Core/Constant";
import {IRecipeEditorCreateParams, RecipeEditorCreate} from "Core/Controls/RecipeEditor/RecipeEditorCreate";
import {IRecipeEditorEditParams, IRecipeEditorTreeParams, RecipeEditorEdit} from "./RecipeEditorEdit";
import {GetRecipeDataDto} from "./Models/Dto/GetRecipeDataDto";
import {CheckIfRecipeIsCreatedDto} from "./Models/Dto/CheckIfRecipeIsCreatedDto";

import {RecipeEditorStore} from "./Stores/RecipeEditorStore";
import {Notifier} from 'Core/Common/Notifier';

import {GeneralProperties} from "Core/GeneralProperties/GeneralProperties";
import RecipeEditorConfig from 'Core/Controls/RecipeEditor/Config/recipe-editor-config.json';

import ViewTemplate from "Core/Controls/RecipeEditor/Templates/View.html";
import ToolBarTemplate from "Core/Controls/RecipeEditor/Templates/ToolBar.html";
import DesignTemplate from "Core/Controls/RecipeEditor/Templates/Design.html";
import EditTemplate from "Core/Controls/RecipeEditor/Templates/Edit.html";
import HelpViewTemplate from "Core/Controls/RecipeEditor/Templates/HelpView.html";
import {ITypePropertyModel} from "../../GeneralProperties/Managers/TypesProperty/TypesProperty";
import { PROPERTIES } from './Constants';

ko.templates['Core/Controls/RecipeEditor/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/RecipeEditor/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/RecipeEditor/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/RecipeEditor/Templates/Edit'] = EditTemplate;
ko.templates['Core/Controls/RecipeEditor/Templates/HelpView'] = HelpViewTemplate;

export class RecipeEditor extends BaseControl {
    _labels = LABELS;
    private _iControlParams: IControlParam;
    private _isRecipeCreated: KnockoutObservable<boolean>;
    private _recipeEditorCreate: KnockoutObservable<RecipeEditorCreate>;
    private _recipeEditorEdit: KnockoutObservable<RecipeEditorEdit>;
    private _typesProperty: ITypePropertyModel[];

    private _isAddingAllowed: KnockoutObservable<boolean>;
    private _isEditingAllowed: KnockoutObservable<boolean>;
    private _isDeletingAllowed: KnockoutObservable<boolean>;
    private _isButtonEnabled: KnockoutComputed<boolean>;

    constructor(params: IControlParam) {
        super(params, RecipeEditorConfig);

        this._iControlParams = params;
        this._isRecipeCreated = ko.observable(null);
        this._recipeEditorCreate = ko.observable(null);
        this._recipeEditorEdit = ko.observable(null);

        this._isAddingAllowed = ko.observable(false);
        this._isEditingAllowed = ko.observable(false);
        this._isDeletingAllowed = ko.observable(false);
        this._isButtonEnabled = ko.computed(() => this._isRecipeCreated() ? this._isEditingAllowed() : this._isAddingAllowed());

        this.ApplyProperties();
    }

    CheckIfRecipeIsCreated(params: CheckIfRecipeIsCreatedDto) {
        RecipeEditorStore.CheckIfRecipeIsCreated(params)
            .then((result: boolean) => {
                this._isRecipeCreated(result);
            })
            .fail(error => {
                new Notifier().Failed(JSON.parse(error.message).Message);
            });
    }

    SetValue(value: IControlValue): void {
        if (value.Data && value.Data.Rights && value.Data.Rights.IsRecordSecurityOk) {
            this._isAddingAllowed(value.Data.Rights.IsAddingRecordAllowed);
            this._isEditingAllowed(value.Data.Rights.IsEditingAllowed);
            this._isDeletingAllowed(value.Data.Rights.IsDeletingAllowed);
        }
    }

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);

        const entityId = this.GetForm() && this.GetForm().GetScreen().GetEntityId();
        const recordId = this.GetForm() && this.GetForm().GetScreen().GetRecordId();

        if ( (this._renderMode() === RenderModes.View) && (entityId && recordId) ) {
            this.CheckIfRecipeIsCreated({
                RootEntityId: entityId,
                RootRecordId: recordId
            });
        }
    }

    ApplyProperties() {
        this._typesProperty =  this.GeneralProperties.GetPropertyValue(PROPERTIES.TYPES);
    }

    Click(){
        if (this._renderMode() === RenderModes.View){
            if (this._isRecipeCreated()){
                this.Edit();
            } else {
                this.Create();
            }
        }
    }

    Edit(){
        let params: IRecipeEditorEditParams = {
            EntityId: this.GetForm().GetScreen().GetEntityId(),
            RecordId: this.GetForm().GetScreen().GetRecordId(),
            ControlId: this.GetControlId(),
            IsAddingAllowed: this._isAddingAllowed(),
            IsEditingAllowed: this._isEditingAllowed(),
            IsDeletingAllowed: this._isDeletingAllowed(),
            TypesProperty: this._typesProperty
        }

        this._recipeEditorEdit(new RecipeEditorEdit(params));
        this._recipeEditorEdit().On('RECIPE_EDITOR_EDIT_CLOSE', this, (handler)=> {
            this._isRecipeCreated(false);
        })
    }

    Create(){
        let recipeEditorCreateParams: IRecipeEditorCreateParams = {
            RecordId: this.GetForm().GetScreen().GetRecordId(),
            EntityId: this.GetForm().GetScreen().GetEntityId(),
            ControlId: this.GetControlId(),
            IsRecipeCreated: this._isRecipeCreated(),
            IsCreateRecipeVersion: null,
            IsAddingAllowed: this._isAddingAllowed(),
            IsEditingAllowed: this._isEditingAllowed(),
            IsDeletingAllowed: this._isDeletingAllowed(),
            ICopyRecipeVersion: null,
            TypesProperty: this._typesProperty
        }

        this._recipeEditorCreate(new RecipeEditorCreate(recipeEditorCreateParams));
        this._recipeEditorCreate().ShowInModal();
        this._recipeEditorCreate().On('RECIPE_EDITOR_CREATE', this, (handler)=>{
            this._isRecipeCreated(true);
        });
        this._recipeEditorCreate().On('RECIPE_EDITOR_EDIT_CLOSE', this, (handler)=>{
            this._isRecipeCreated(false);
        })
    }
}