import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import { SavePasswordLessCredentialsDto } from '../Models/SavePasswordLessCredentialsDto';
import { GenericDeserialize } from '../../libs/cerialize';
import { SignInResultModel } from '../Models/SignInResultModel';

export interface IMakeAssertionDto{
    ClientResponse: string;
    DatabaseName: string;
    UserName: string;
}

export class WebAuthnStore {

    public static GetAssertionOptions(userName: string, databaseName: string): P.Promise<null> {
        let deferredResult = P.defer<null>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: '/api/WebAuthnApi/GetAssertionOptions',
                dataType: 'json',
                type: 'POST'
            },
            params: { username: userName, databaseName: databaseName }
        }

        Request.Send(requestParams)
            .then((result) => {                                
                deferredResult.resolve(result);
            });

        return deferredResult.promise();
    }

    public static BuildCredentialOptions(userName: string, databaseName: string): P.Promise<null> {
        let deferredResult = P.defer<null>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: '/api/WebAuthnApi/BuildCredentialOptions',
                dataType: 'json',
                type: 'POST'
            },
            params: { username: userName, databaseName: databaseName }
        }

        Request.Send(requestParams)
            .then((result) => {                                
                deferredResult.resolve(result);
            });

        return deferredResult.promise();
    }

    public static SaveCredential(params: SavePasswordLessCredentialsDto): P.Promise<null> {
        let deferredResult = P.defer<null>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: '/api/WebAuthnApi/SaveCredential',
                type: 'POST'
            },
            params: params
        }

        Request.Send(requestParams)
            .then((result) => {                                
                deferredResult.resolve(result);
            });

        return deferredResult.promise();
    }

    public static MakeAssertion(params: IMakeAssertionDto): P.Promise<SignInResultModel> {
        let deferredResult = P.defer<SignInResultModel>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: '/api/WebAuthnApi/MakeAssertion',
                dataType: 'json',
                type: 'POST'
            },
            params: params
        }

        Request.Send(requestParams)
            .then((result) => {          
                deferredResult.resolve(GenericDeserialize(result, SignInResultModel))
            });

        return deferredResult.promise();
    }
}