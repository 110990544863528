export const PHONE_NUMBER_LENGTH = [
    {iso2: 'af', min: 9, max: 9},
    {iso2: 'al', min: 6, max: 9},
    {iso2: 'dz', min: 8, max: 9},
    {iso2: 'as', min: 10, max: 10},
    {iso2: 'ad', min: 6, max: 9},
    {iso2: 'ao', min: 9, max: 9},
    {iso2: 'ai', min: 10, max: 10},
    {iso2: 'ag', min: 10, max: 10},
    {iso2: 'ar', min: 10, max: 11},
    {iso2: 'am', min: 8, max: 8},
    {iso2: 'aw', min: 7, max: 7},
    {iso2: 'au', min: 5, max: 10},
    {iso2: 'at', min: 4, max: 13},
    {iso2: 'az', min: 7, max: 9},
    {iso2: 'bs', min: 7, max: 11},
    {iso2: 'bh', min: 8, max: 8},
    {iso2: 'bd', min: 6, max: 10},
    {iso2: 'bb', min: 7, max: 11},
    {iso2: 'by', min: 5, max: 11},
    {iso2: 'be', min: 8, max: 9},
    {iso2: 'bz', min: 7, max: 11},
    {iso2: 'bj', min: 8, max: 8},
    {iso2: 'bm', min: 7, max: 11},
    {iso2: 'bt', min: 6, max: 8},
    {iso2: 'bo', min: 7, max: 9},
    {iso2: 'ba', min: 6, max: 9},
    {iso2: 'bw', min: 7, max: 8},
    {iso2: 'br', min: 8, max: 11},
    {iso2: 'io', min: 7, max: 7},
    {iso2: 'vg', min: 7, max: 11},
    {iso2: 'bn', min: 7, max: 7},
    {iso2: 'bg', min: 4, max: 9},
    {iso2: 'bf', min: 8, max: 8},
    {iso2: 'bi', min: 8, max: 8},
    {iso2: 'kh', min: 6, max: 10},
    {iso2: 'cm', min: 8, max: 9},
    {iso2: 'ca', min: 11, max: 11},
    {iso2: 'cv', min: 7, max: 7},
    {iso2: 'bq', min: 7, max: 8},
    {iso2: 'ky', min: 7, max: 11},
    {iso2: 'cf', min: 8, max: 8},
    {iso2: 'td', min: 8, max: 8},
    {iso2: 'cl', min: 9, max: 11},
    {iso2: 'cn', min: 7, max: 17},
    {iso2: 'cx', min: 5, max: 10},
    {iso2: 'cc', min: 5, max: 10},
    {iso2: 'co', min: 7, max: 11},
    {iso2: 'km', min: 7, max: 7},
    {iso2: 'cd', min: 7, max: 9},
    {iso2: 'cg', min: 9, max: 9},
    {iso2: 'ck', min: 5, max: 5},
    {iso2: 'cr', min: 8, max: 10},
    {iso2: 'ci', min: 8, max: 8},
    {iso2: 'hr', min: 6, max: 9},
    {iso2: 'cu', min: 4, max: 8},
    {iso2: 'cw', min: 7, max: 8},
    {iso2: 'cy', min: 8, max: 8},
    {iso2: 'cz', min: 9, max: 12},
    {iso2: 'dk', min: 8, max: 8},
    {iso2: 'dj', min: 8, max: 8},
    {iso2: 'dm', min: 7, max: 11},
    {iso2: 'do', min: 8, max: 11},
    {iso2: 'ec', min: 7, max: 11},
    {iso2: 'eg', min: 7, max: 10},
    {iso2: 'sv', min: 7, max: 11},
    {iso2: 'gq', min: 9, max: 9},
    {iso2: 'er', min: 6, max: 7},
    {iso2: 'ee', min: 7, max: 10},
    {iso2: 'et', min: 7, max: 9},
    {iso2: 'fk', min: 5, max: 5},
    {iso2: 'fo', min: 6, max: 6},
    {iso2: 'fj', min: 7, max: 11},
    {iso2: 'fi', min: 5, max: 10},
    {iso2: 'fr', min: 9, max: 9},
    {iso2: 'gf', min: 9, max: 9},
    {iso2: 'pf', min: 6, max: 8},
    {iso2: 'ga', min: 7, max: 8},
    {iso2: 'gm', min: 7, max: 7},
    {iso2: 'ge', min: 6, max: 9},
    {iso2: 'de', min: 4, max: 15},
    {iso2: 'gh', min: 7, max: 9},
    {iso2: 'gi', min: 8, max: 8},
    {iso2: 'gr', min: 10, max: 10},
    {iso2: 'gl', min: 6, max: 6},
    {iso2: 'gd', min: 7, max: 11},
    {iso2: 'gp', min: 9, max: 9},
    {iso2: 'gu', min: 7, max: 11},
    {iso2: 'gt', min: 8, max: 11},
    {iso2: 'gg', min: 4, max: 10},
    {iso2: 'gn', min: 8, max: 9},
    {iso2: 'gw', min: 7, max: 9},
    {iso2: 'gy', min: 7, max: 7},
    {iso2: 'ht', min: 8, max: 8},
    {iso2: 'us', min: 11, max: 11},
    {iso2: 'gb', min: 7, max: 10},
    {iso2: 'it', min: 6, max: 11},
    {iso2: 'lv', min: 8, max: 8},
    {iso2: 'lu', min: 4, max: 11},
    {iso2: 'lt', min: 8, max: 8},
    {iso2: 'md', min: 8, max: 8},
    {iso2: 'mc', min: 8, max: 9},
    {iso2: 'nl', min: 5, max: 10},
    {iso2: 'nz', min: 8, max: 10},
    {iso2: 'no', min: 5, max: 8},
    {iso2: 'ph', min: 6, max: 13},
    {iso2: 'pl', min: 6, max: 9},
    {iso2: 'pt', min: 9, max: 9},
    {iso2: 'ru', min: 10, max: 10},
    {iso2: 'sm', min: 6, max: 10},
    {iso2: 'sg', min: 8, max: 11},
    {iso2: 'sk', min: 6, max: 9},
    {iso2: 'si', min: 5, max: 8},
    {iso2: 'es', min: 9, max: 9},
    {iso2: 'lk', min: 7, max: 9},
    {iso2: 'se', min: 6, max: 12},
    {iso2: 'ch', min: 9, max: 12},
    {iso2: 'tr', min: 7, max: 10},
    {iso2: 'ua', min: 9, max: 9},
    {iso2: 've', min: 10, max: 10},
    {iso2: 'zm', min: 3, max: 10},
    {iso2: 'il', min: 7, max: 12},
    {iso2: 'jp', min: 8, max: 17},
    {iso2: 'mx', min: 10, max: 11},
    {iso2: 'ro', min: 6, max: 9}
];