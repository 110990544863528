import * as ko from "knockout";
import {IControlParam, ISearchScreenParam} from "Core/Screens/IScreen";
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel';
import {RenderModes, CONTROL_TYPES} from "Core/Constant";

import {CanvasDesigner as CanvasDesignerControl} from "Core/Controls/CanvasDesigner/CanvasDesigner";
import CanvasDesignerTemplate from "Pages/Templates/CanvasDesigner.html";
ko.templates["Pages/Templates/CanvasDesigner"] = CanvasDesignerTemplate;

export class CanvasDesignerPage {
	private _CanvasDesignerControl: CanvasDesignerControl;
	private _isReady: KnockoutObservable<boolean>;

	constructor() {
		this._isReady = ko.observable(false);
		var controlModel = new ControlModel();
		controlModel.TypeName = CONTROL_TYPES.CanvasDesigner;
		var controlsParam: IControlParam = {
			Model: controlModel,
			Form: null,
			RenderMode: RenderModes.View
		}
		this._CanvasDesignerControl = new CanvasDesignerControl(controlsParam);
		this._CanvasDesignerControl.SetValue(null);
	}

	Render(target): void {
		ko.cleanNode(document.getElementById(target));
		ko.applyBindings(this, document.getElementById(target));
	}

	GetTemplateName(): string {
		return "Pages/Templates/CanvasDesigner";
	}

	get CanvasDesignerControl(): CanvasDesignerControl {
		return this._CanvasDesignerControl;
	}

	AfterRender() {
		this._isReady(true);
	}

	RemoveControl() {
	}
}