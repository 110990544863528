import { deserialize, deserializeAs } from 'libs/cerialize';
export class AttachmentModel {
	constructor() {
		this.FileName = null;
		this.Base64Data = null;
	}

	@deserialize
	FileName: string;

	@deserialize
	Index: number;

	@deserialize
	Base64Data: string;

	@deserialize
	TempName: string;

	@deserialize
	TempOriginalName: string;

	@deserialize
	Annotations: string;
}