import {P} from 'Core/Common/Promise';
import {Request} from 'Core/Common/Request';

import {RemoteDatabaseConnectionDto} from './Models/RemoteDatabaseConnectionDto';
import {GetRemoteDatabaseConnectionUrlDto} from './Models/GetRemoteDatabaseConnectionUrlDto';
import {SetConnectedDto} from './Models/SetConnectedDto';
import {DisconnectDto} from './Models/DisconnectDto';
import { BlockUI } from '../../../Common/BlockUi';

export class RemoteDatabasesStore {
    GetConnections(): P.Promise<RemoteDatabaseConnectionDto[]> {
        const deferred = P.defer<RemoteDatabaseConnectionDto[]>();

        Request.Send({
            proxy: {
                url: '/api/RemoteDatabaseConnectionsApi/GetConnections',
                type: 'GET'
            }
        }).then(connections => {
            deferred.resolve(connections)
        }).fail(err => {
            const error = this.GetResponseError(err, 'Error getting remote connections');
            deferred.reject(error);
        });

        return deferred.promise();
    }

    GetConnectionUrl(params: GetRemoteDatabaseConnectionUrlDto) {
        const deferred = P.defer<string>();

        BlockUI.Block();
        Request.Send({
            proxy: {
                url: '/api/RemoteDatabaseConnectionsApi/GetConnectionUrl',
                type: 'POST'
            },
            params
        }).then(connectionUrl => {
            deferred.resolve(connectionUrl);
        }).fail(err => {
            const error = this.GetResponseError(err, 'Error getting connection URL');
            deferred.reject(error);
        }).always(()=>BlockUI.Unblock());

        return deferred.promise();
    }

    SetConnected(params: SetConnectedDto) {
        Request.Send({
            proxy: {
                url: '/api/RemoteDatabaseConnectionsApi/SetConnected',
                type: 'POST'
            },
            params
        });
    }

    Disconnect(params: DisconnectDto) {
        BlockUI.Block();
        Request.Send({
            proxy: {
                url: '/api/RemoteDatabaseConnectionsApi/Disconnect',
                type: 'POST'
            },
            params
        }).always(()=>BlockUI.Unblock());
    }

    private GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}