import * as _ from 'underscore';
import * as ko from 'knockout';

import {BaseColumn} from 'Core/Controls/Grid/BaseGrid/GridColumn/BaseColumn';
import {GridColumnModel} from 'Core/Controls/Grid/Models/GridDataModel/GridColumnModel';
import {Event} from 'Core/Common/Event';
import {EVENTS} from 'Core/Controls/Grid/BaseGrid/Events';
import {SortOrder} from 'Core/Controls/Grid/BaseGrid/GridColumn/BaseColumn';

export class GroupColumn extends BaseColumn {
	constructor(model: GridColumnModel) {
		super(model);
		this._sortOrder(SortOrder.Asc);
	}

	ResetSort() {
		this._sortOrder(SortOrder.Asc);
	}

	get IsGroup(): boolean {
		return this._model.IsGroup;
	}

	get IsEnableSort(): boolean {
		return false;
	}

	Sort() {
		 if (this._sortOrder() === SortOrder.Asc) {
			this._sortOrder(SortOrder.Desc);
		} else if (this._sortOrder() === SortOrder.Desc) {
			this._sortOrder(SortOrder.Asc);
		}

		this.Trigger(EVENTS.SORT, { Column: this._model.Alias, SortOrder: this._sortOrder() });
	}
}