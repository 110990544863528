import * as ko from 'knockout'

import {BaseControl} from 'Core/Controls/BaseControl/BaseControl'
import {IControlParam} from 'Core/Screens/IScreen'

import {RenderModes} from 'Core/Constant';

import {GeneralProperties} from 'Core/GeneralProperties/GeneralProperties';
import LabelConfig from "Core/Controls/Label/Configs/label-config.json";


import HelpViewTemplate from 'Core/Controls/Label/Templates/HelpView.html'
import ViewTemplate from 'Core/Controls/Label/Templates/View.html'
import ToolBarTemplate from 'Core/Controls/Label/Templates/ToolBar.html'
import DesignTemplate from 'Core/Controls/Label/Templates/Design.html'

ko.templates['Core/Controls/Label/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Label/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/Label/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Label/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/Label/Templates/Design'] = DesignTemplate;

export class Label extends BaseControl {
	private _isUnderlined: KnockoutObservable<boolean>;
	private _alignment: KnockoutObservable<string>;
	private _selectHeader: KnockoutObservable<string>;

	constructor(params: IControlParam) {
		super(params, LabelConfig);

		this._isUnderlined = ko.observable(null);
		this._selectHeader = ko.observable('h3');
		this._alignment = ko.observable(null);

		this.ApplyProperties();
	}

	ApplyProperties() {
		let headerProperty = this.GeneralProperties.GetPropertyValue("SelectHeader");
		let underlinedButtonProperty = this.GeneralProperties.GetPropertyValue("UnderlinedButton");
		let selectAlignmentProperty = this.GeneralProperties.GetPropertyValue("SelectAlignment");
			
		this._isUnderlined(underlinedButtonProperty);
		this._selectHeader(headerProperty ? headerProperty.Value : null);
		this._alignment(selectAlignmentProperty ? selectAlignmentProperty.Value : null);
	}

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}
}