import {deserialize} from 'libs/cerialize';
import {deserializeAs} from 'libs/cerialize';

import * as ko from 'knockout';

export class DropdownItemModel {
    @deserialize
    RecordId: number;

    @deserialize
    Label: string;

    @deserialize
    Value: string | any;

    @deserialize
    Type: string;

    disable?: boolean;

    private _isSelected: KnockoutObservable<boolean> = ko.observable(false);
    private _isEnabled: boolean;

    constructor(partial?: Partial<DropdownItemModel>) {
        if (partial) {
            this.RecordId = partial.RecordId;
            this.Label = partial.Label;
            this.Value = partial.Value;
        }
    }

    get IsSelected() {
        return this._isSelected();
    }

    set IsSelected(value: boolean) {
        this._isSelected(value);
    }

    get IsEnabled() {
        return this._isEnabled;
    }

    set IsEnabled(value: boolean) {
        this._isEnabled = value;
    }

    static Create(recordId: number, label: string, isEnabled: boolean, isSelected: boolean) {
        const item = new DropdownItemModel();

        item.RecordId = recordId;
        item.Label = label;
        item._isEnabled = isEnabled;
        item._isSelected(isSelected);

        return item;
    }
}

export class DropdownListModel {
    constructor() {
        this.Items = [];
        this.RecordsCount = 0;
    }

    @deserialize
    ErrorMessage: string;

    @deserialize
    RecordsCount: number;

    @deserializeAs(DropdownItemModel)
    Items: Array<DropdownItemModel>;
}

export class FastFilterRangeModel {

    @deserialize
    MaxValue: string;

    @deserialize
    MinValue: string;

    @deserialize
    ErrorMessage: string;

}