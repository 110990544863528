import {EventBus} from 'Core/Common/EventBus/EventBus';
import {EventBusConsumer} from 'Core/Common/EventBus/EventBusConsumer';

import {NavigationItem, NavigationItemTypes} from './NavigationItem';
import { NavigationStackEvents } from './NavigationStackEvents';

export class NavigationStack extends EventBusConsumer {
    private _items: NavigationItem[];

    constructor(eventBus: EventBus) {
        super();

        this._items = [];
        this.AssignEventBus(eventBus);
    }

    GetAll() {
        return [...this._items];
    }

    Get(index: number): NavigationItem {
        return this._items[index];
    }

    Push(name: string, type: NavigationItemTypes, payload: any) {
        const item = new NavigationItem(name, type, payload, payload.PageNumber);
        const topItem = this._items[0];

        if (topItem && topItem.Equals(item)) {
            return;
        }

        this._items.unshift(item);
        this.DispatchEvent(NavigationStackEvents.Push, item);
    }

    Update(name: string, type: NavigationItemTypes, payload: any) {
        const index = 0;
        const oldItem = this._items[index];
        const newItem = new NavigationItem(name, type, payload, payload.PageNumber);

        this._items.splice(index, 1, newItem);
        this.DispatchEvent(NavigationStackEvents.Update, {OldItem: oldItem, NewItem: newItem});
    }

    Upsert(name: string, type: NavigationItemTypes, payload: any) {
        const index = 0;
        const oldItem = this._items[index];

        if (!oldItem || !oldItem.Is(type)) {
            this.Push(name, type, payload);
        }

        this.Update(name, type, payload);
    }

    Pop(): NavigationItem {
        const item = this.RemoveTop();

        this.DispatchEvent(NavigationStackEvents.Pop, item);
        return item;
    }

    Flush() {
        this._items = [];
    }

    NavigateBack(): NavigationItem {
        const removedItem = this.RemoveTop();
        this.DispatchEvent(NavigationStackEvents.Navigate, [removedItem]);
        return removedItem;
    }

    NavigateTo(item: NavigationItem): NavigationItem[] {
        const index = this._items.indexOf(item);
        const poppedItems = this._items.splice(0, index);

        this.DispatchEvent(NavigationStackEvents.Navigate, poppedItems);
        return poppedItems;
    }

    private RemoveTop() {
        const item = this._items[0];
        this._items.splice(0, 1);

        return item;
    }
}