import * as ko from 'knockout';

export class DirtyFlagExtention {
	private static flag : Result;
	static Init() {
		(ko as any).dirtyFlag = <T>(currentState: T, isInitiallyDirty: boolean = false) => new Result(currentState, isInitiallyDirty);
	}
}

class Result {
	private _currentState : KnockoutObservable<any>;
	private _initialState: KnockoutObservable<string>;
	private _isInitiallyDirty: boolean;

	IsDirty: KnockoutComputed<boolean>;

	constructor(currentState: any, isInitiallyDirty: boolean = false) {
		this._currentState = ko.observable(currentState);
		this._initialState = ko.observable(ko.toJSON(this._currentState()));
		this._isInitiallyDirty = isInitiallyDirty;

		this.IsDirty = ko.computed(() => {
			return this._isInitiallyDirty || (this._initialState() !== ko.toJSON(this._currentState()));
		}, this);
	}

	Commit() {
		this._initialState(ko.toJSON(this._currentState()));
		this._isInitiallyDirty = false;
	}

	GetInitialState() {
		return ko.toJS(this._initialState());
	}
}