import * as _ from 'underscore';

import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize} from 'libs/cerialize';
import { OperationResultModel } from 'Core/Models/OperationResultModel';
import { ITranslationValue } from "Core/Components/TranslationFieldEditor/ITranslationValue";

export interface IUpdateQueryRequestModel {
	Id: number;
	SubjectEntityId: number;
	QueryText: String;
	Name: string;
	NameTranslations: Array<ITranslationValue>
};

export class UpdateQueryStore {
	static Update(params: IUpdateQueryRequestModel): P.Promise<OperationResultModel> {

		var deferredResult = P.defer<OperationResultModel>();
		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.QUERY_BUILDER.UPDATE_QUERY,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};
		Request.Send(requestParams)
			.then(data => deferredResult.resolve(this.Deserialize(data)));

		return deferredResult.promise();
	}

	static Deserialize(jsonObject: any): OperationResultModel {
		const model = <OperationResultModel>Deserialize(jsonObject, OperationResultModel);
		return model;
	}
}  