import * as ko from 'knockout';
import {deserialize, deserializeAs} from 'libs/cerialize';
import {IControl} from "../../IControl";
import {CustomColumnValueModel} from "./CustomColumnValueModel";
import {IconModel} from 'Core/Controls/BaseControl/Models/IconModel';

export class TimeCellModel {

    constructor(){
        this.IsEditMode = ko.observable(null);
        this.IsActive = ko.observable(false);
        this.IsSelected = ko.observable(false);    
        this.IsCopyTo = ko.observable(false); 
        this.IsCopyFrom = ko.observable(false);
    }

    @deserialize
    TimeValue: string;

    @deserialize
    CellColor: string;

    @deserialize
    IsWeekend: boolean;

    @deserialize
    TimeAxis: string;

    @deserialize
    Button1RecordId: number;

    @deserialize
    Button1TableId: number;

    @deserialize
    Button1RecordName: string;

    @deserialize
    Button2RecordId: number;

    @deserialize
    Button2TableId: number;

    @deserialize
    Button2RecordName: string;

    IsActive: KnockoutObservable<boolean>;
    IsEditMode: KnockoutObservable<boolean>;
    IsSelected: KnockoutObservable<boolean>;
    IsCopyTo: KnockoutObservable<boolean>;
    IsCopyFrom: KnockoutObservable<boolean>;
}

export class PlannerLevelRowModel {

    constructor(){
        this.IsExpanded = ko.observable(false);
        this.TimeLine = [];
        this.SubControls = ko.observableArray([]);
        this.CustomValues = [];
        this.IsActive = ko.observable(false);
        this.RowHeight = ko.observable(null);
        this.IsBlocked = ko.observable(false);
    }

    IsActive: KnockoutObservable<boolean>;

    @deserialize
    ControlId: number;

    @deserialize
    RecordId: number;

    @deserialize
    SubjectTypeId: number;

    @deserialize
    TotalRecords: number;

    @deserialize
    BarId: string;

    @deserialize
    SubjectId: number;

    @deserialize
    BarName: string;

    @deserialize
    RowColor: string;

    IsExpanded: KnockoutObservable<boolean>;

    @deserializeAs(TimeCellModel)
    TimeLine: Array<TimeCellModel>;

    @deserializeAs(CustomColumnValueModel)
    CustomValues: Array<CustomColumnValueModel>;
    
    @deserializeAs(IconModel)
    Button1Icon: IconModel;

    @deserialize
    Button1Query: string;

    @deserializeAs(IconModel)
    Button2Icon: IconModel;

    @deserialize
    Button2Query: string;

    @deserialize
    Button1TableName: string;

    @deserialize
    Button2TableName: string;

    SubControls: KnockoutObservableArray<IControl>;

    RowHeight: KnockoutObservable<number>;

    IsBlocked: KnockoutObservable<boolean>;
}