import * as ko from 'knockout';

import {LABELS} from "Core/Components/Translation/Locales";
import {PortletSpaceManager} from "Core/Portlets/Managers/Runtime/PortletSpaceManager";

import Template from 'Core/Portlets/Templates/PortletsPage.html';

ko.templates['Core/Portlets/Templates/PortletsPage'] = Template;


export class PortletsPage {
	private _labels: LABELS;
	private _portletSpace: PortletSpaceManager;
	private _isReady: KnockoutObservable<boolean>;

	constructor() {
		this._labels = LABELS;
		this._portletSpace = new PortletSpaceManager({Subject: null});
		this._isReady = ko.observable(false);
	}

	GetTemplateName() {
		return 'Core/Portlets/Templates/PortletsPage';
	}

	Render(containerId: string) {
		const container = document.getElementById(containerId);

		ko.cleanNode(container);
		ko.applyBindings(this, container);
	}

	AfterRender() {
		this._portletSpace.Load().always(() => this._isReady(true));
	}
}