import * as ko from 'knockout';

export class LifeStatusViewModel {
	Name: KnockoutObservable<string>;
	Date: KnockoutObservable<string>;
	Enabled: KnockoutObservable<boolean>;

	Modified: boolean;

	constructor() {
		this.Name = ko.observable(null);
		this.Date = ko.observable(null);
		this.Enabled = ko.observable(false);
	}
}