import * as ko from "knockout";

import {LABELS} from 'Core/Components/Translation/Locales';

import {BaseProductGroup} from "./BaseProductGroup";

import {ProductPart} from "./ProductPart";

import {TranslationModel} from "Core/Controls/BaseControl/Models/TranslationModel";

import {
    CONVERSION_DROPDOWN_EVENTS,
    ConversionDropdown
} from "../../../Components/ConversionDropdown/ConversionDropdown";

import {
    ConfigurationPageEvents, ConversionsDropdownArgs,
    GroupActivatingEventArgs, ProductPartSelectingEventArgs
} from "../Events/ConfigurationPageEvents";

import Template from '../Templates/ProductGroup.html';

export class ProductGroup extends BaseProductGroup {
    private _labels = LABELS;
    private _groupSelectedImageSize: number;
    private _groupImageSizeClassName: string;
    private _groupTileImageSize: number;
    private _clickedAlternativeBtn: KnockoutObservable<boolean>;

    ConversionDropdowns: ConversionDropdown;

    HasProducts: KnockoutComputed<boolean>;

    constructor(id: number, name: string, translatedName: string, translations: TranslationModel[], addAsExtra: boolean, parentProduct: ProductPart) {
            super(id, name, translatedName, translations, addAsExtra, parentProduct);

        this.HasProducts = ko.computed(() => this.Products().length > 0);
        this.CanShowMore = ko.observable(true);
        this._groupSelectedImageSize = null;
        this._groupImageSizeClassName = null;
        this._groupTileImageSize = null;

        this._clickedAlternativeBtn = ko.observable(null);
        this.ConversionDropdowns = new ConversionDropdown();
    }

    get ProductListMarginSizeClassName(): string {
        if (this.Products().length >= 1){
            _.each(this.Products(), (product, index)=>{
                if (index === 0){
                    this._groupSelectedImageSize = product._selectedImageSize;
                    this._groupTileImageSize = product._tileImageSize;
                }
            })
        }

        if (this.ExtendedView()) {
            return this.GetImageSizeClassName(this._groupSelectedImageSize);
        } else {
            return this.GetImageSizeClassName(this._groupTileImageSize);
        }
    }

    GetConversionDropdown(): ConversionDropdown {
        return this.ConversionDropdowns;
    }
    GetIsInitConversionDropdown(): boolean {
        return this.ConversionDropdowns.IsInit
    }

    GetImageSizeClassName(imageSize: number) {
        switch (imageSize) {
            case 60:
                return this._groupImageSizeClassName = 'productListMarginSize-60';
            case 70:
                return this._groupImageSizeClassName = 'productListMarginSize-70';
            case 80:
                return this._groupImageSizeClassName = 'productListMarginSize-80';
            case 90:
                return this._groupImageSizeClassName = 'productListMarginSize-90';
            default:
                return this._groupImageSizeClassName = 'productListMarginSize-100';
        }
    }

    get ClickedAlternativeBtn(): boolean {
        return this._clickedAlternativeBtn();
    }

    get IsRoot() {
        return false;
    }

    get IsExtra() {
        return false;
    }

    get IsUndefined() {
        return false;
    }

    GetTemplate() {
        return Template;
    }

    ToggleActive() {
        if (this.Expanded()) {
            this.Deactivate();
        } else {
            this.Activate();
        }
    }

    Expand() {
        this.Activate();
    }

    Collapse() {
        this.Deactivate();
        this.CollapseActiveNestedGroup();
    }

    Activate() {
        if (this.GetIsInitConversionDropdown()){
            if (this.ConversionDropdowns.DropDownParams.length){
                const parentProduct = this.parentProduct as ProductPart;

                this.DispatchEvent<GroupActivatingEventArgs>(ConfigurationPageEvents.GroupActivating,
                    new GroupActivatingEventArgs(parentProduct.SelectedGroup(), this));

                this.DispatchEvent<ProductGroup>(ConfigurationPageEvents.GroupActivated, this);
                this.Expanded(true);
                return;
            }

            if (!this.ConversionDropdowns.DropDownParams.length && this.conversionDropdownLoaded) {
                this.DispatchEvent<ProductGroup>(ConfigurationPageEvents.ShowMore, this);
                return;
            }
        }

        if (this.productsLoaded && this.conversionDropdownLoaded && !this.ConversionDropdowns.DropDownParams.length && !this.GetActiveProduct()) {
            this.DispatchEvent<ProductGroup>(ConfigurationPageEvents.ReloadingConversionDropdowns, this);
            this.Expanded(true);
            return;
        }

        if (this.productsLoaded && !this.conversionDropdownLoaded && !this.GetActiveProduct() && this.Products().length == 1) {
            this.DispatchEvent<ProductGroup>(ConfigurationPageEvents.NotInitGroupActivated, this);
            // this.DispatchEvent<ProductGroup>(ConfigurationPageEvents.ShowMore, this);
            // this.Expanded(true);
            // return;
        }

        if (!this.productsLoaded) {
            this.DispatchEvent<ProductGroup>(ConfigurationPageEvents.NotInitGroupActivated, this);
            return;
        }

        if (!this.GetActiveProduct() && this.AlternativesCount > 0 && this.Products().length == 1) {
            this.DispatchEvent<ProductGroup>(ConfigurationPageEvents.ShowMore, this);
        }

        if (this.GetActiveProduct() && !this.ExtendedView() || !this.GetActiveProduct() && this.ExtendedView()) {
            this.ToggleView()
        }

        const parentProduct = this.parentProduct as ProductPart;

        this.DispatchEvent<GroupActivatingEventArgs>(ConfigurationPageEvents.GroupActivating,
            new GroupActivatingEventArgs(parentProduct.SelectedGroup(), this));

        this.DispatchEvent<ProductGroup>(ConfigurationPageEvents.GroupActivated, this);
        this.Expanded(true);
    }

    Deactivate() {
        this.Expanded(false);
    }

    AfterInit() {
        this.BindEvents();
    }

    Dispose() {
        this.Products().forEach(product => product.Dispose());
        super.Dispose();
    }

    private BindEvents() {
        this.ConversionDropdowns.On(CONVERSION_DROPDOWN_EVENTS.SELECTED, this, (eventArgs: any) => {
            let args = {
                _eventArgs: eventArgs,
                _this: this
            }
            this.DispatchEvent<ConversionsDropdownArgs>(ConfigurationPageEvents.GroupConversionsDropdown,
                new ConversionsDropdownArgs(args))
        });

        this.ConversionDropdowns.On(CONVERSION_DROPDOWN_EVENTS.SYNCHRONIZATION_VALUE, this, (eventArgs: any) => {
            let args = {
                _eventArgs: eventArgs,
                _this: this
            }
            this.DispatchEvent<ConversionsDropdownArgs>(ConfigurationPageEvents.GroupConversionsDropdownSynchronization,
                new ConversionsDropdownArgs(args))
        })

        this.ConversionDropdowns.On(CONVERSION_DROPDOWN_EVENTS.SYNCHRONIZATION_RESET, this, (eventArgs: any) => {
            let args = {
                _eventArgs: eventArgs,
                _this: this
            }
            this.DispatchEvent<ConversionsDropdownArgs>(ConfigurationPageEvents.GroupConversionsDropdownSynchronization,
                new ConversionsDropdownArgs(args))
        })

        this.HandleEvent<ProductPartSelectingEventArgs>(ConfigurationPageEvents.ProductPartSelecting)
            .When(eventArgs => this.HasProduct(eventArgs.Data.NewProduct))
            .Using(() => this.UnSelectActiveProduct())
            .Always();
    }

    ShowMore(clickedAlternativeBtn?: boolean) {
        this._clickedAlternativeBtn(clickedAlternativeBtn);

        if (this.conversionDropdownLoaded) {
            if (!this.ConversionDropdowns.IsInit && this.Products().length > 1 && !this._clickedAlternativeBtn()) {
                this.DispatchEvent<ProductGroup>(ConfigurationPageEvents.ShowMore, this);
                return
            }

            if (this.ExtendedView() && this.Products().length > 1) {
                this.ToggleView();
                return;
            }

            if (!this.ConversionDropdowns.DropDownParams.length){
                this.DispatchEvent<ProductGroup>(ConfigurationPageEvents.ShowMore, this);
                return;
            }
        }

        if (this.ExtendedView() && this.Products().length > 1) {
            this.ToggleView();
        } else {
            this.DispatchEvent<ProductGroup>(ConfigurationPageEvents.ShowMore, this);
        }
    }

    GetNestedSelectedProducts() {
        let nested = [];
        this.Products().forEach(p => {
            if (p.Selected()) {
                nested.push(p);
            }
            p.Groups().forEach(g => {
                _.union(nested, g.GetNestedSelectedProducts());
            })

        });
        return nested;
    }
}