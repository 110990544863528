import * as ko from "knockout";
import {Modal} from "Core/Common/Modal";
import {Event} from "Core/Common/Event";

import SelectDialogTemplate from 'Core/Components/Dialogs/SelectDialog/Templates/View.html';
ko.templates["Core/Components/Dialogs/SelectDialog/Templates/View"] = SelectDialogTemplate;

const TEMPLATES = {
	VIEW: 'Core/Components/Dialogs/SelectDialog/Templates/View'
}

export const EVENTS = {
	SAVE: 'Save',
	CANCEL: 'Cancel'
}

export interface ISelectorOption<TValue> {
	Text: string;
	Value: TValue;
}

export interface ISelectDialogOptions<TSelectorValue> {
	Label: string;
	Options: Array<ISelectorOption<TSelectorValue>>;
	SelectedOption?: ISelectorOption<TSelectorValue>;
}

export class SelectDialog<TSelectorValue> extends Event {
	private _modal: Modal;
	private _selectorLabel: string;
	private _selectorOptions: KnockoutObservableArray<ISelectorOption<TSelectorValue>>;
	private _selectedOption: KnockoutObservable<ISelectorOption<TSelectorValue>>;

	constructor(options: ISelectDialogOptions<TSelectorValue>) {
		super();
		this.Init(options);
	}

	private Init(options: ISelectDialogOptions<TSelectorValue>) {
		this._modal = new Modal({
			addClass: 'jBox-padding-15px selectDialogPopup',
			width: '350',
			minHeight: '200'
		}, false);
		this._selectorLabel = options.Label;
		this._selectorOptions = ko.observableArray(options.Options);

		this._selectedOption = options.SelectedOption
			? ko.observable(options.SelectedOption)
			: ko.observable(options.Options.length > 0 ? options.Options[0] : null);
	}

	Show() {
		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);
	}

	OnSave() {
		this.Trigger(EVENTS.SAVE, this._selectedOption());
		this._modal.Close();
	}

	OnCancel() {
		this.Trigger(EVENTS.CANCEL);
		this._modal.Close();
	}

	private GetTemplateName(): string {
		return TEMPLATES.VIEW;
	}

	private AfterRender() {
		this._modal.Show();
	}
}