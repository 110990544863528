import * as ko from 'knockout';
import * as _ from 'underscore';

import { Text } from 'Core/Controls/Text/Text'
import { ICell } from 'Pages/TransaltionEditorPage/TranslationEditor/ICell';
import { RenderModes, CONTROL_TYPES } from 'Core/Constant';
import { IControlParam } from 'Core/Screens/IScreen';
import { ControlModel } from 'Core/Controls/BaseControl/Models/ControlModel'
import { ControlDataModel} from 'Core/ScreenManager/Models/ControlDataModel';
import { RecordSpecsModel} from 'Core/ScreenManager/Models/RecordSpecsModel';
import { IControlValue } from 'Core/Controls/BaseControl/BaseControl';

import Template from 'Pages/TransaltionEditorPage/TranslationEditor/Templates/TranslationCell.html';
import {AttachedFieldModel} from "../../../Core/Controls/BaseControl/Models/AttachedFieldModel";

export class TranslationCell implements ICell {
	private _fieldName: string;
	private _value: KnockoutObservable<string>;
	private _isEditMode: KnockoutObservable<boolean>;
	private _editor: KnockoutObservable<Text>;

	constructor(fieldName: string, value: string) {
		this._fieldName = fieldName;
		this._value = ko.observable(value);
		this._editor = ko.observable(null);
		this._isEditMode = ko.observable(false);
		this._isEditMode.subscribe((newValue) => {
			if (newValue) {
				let fieldModel = new AttachedFieldModel();
				let model = new ControlModel();
				model.Fields = [fieldModel];
				model.TypeName = CONTROL_TYPES.Text;

				var controlParam: IControlParam = {
					Model: model,
					RenderMode: RenderModes.Edit,
					Form: null
				};

				this._editor(new Text(controlParam));
				var controlData = new ControlDataModel();
				controlData.Value = this._value();
				var controlValue: IControlValue = {
					SubjectRecordId: 0,
					SubjectEntityId: 0,
					Data: controlData,
					RecordSpecsModel: new RecordSpecsModel()
				}
				this._editor().SetValue(controlValue);
			} else {
				if (this._editor()) {
					this._value(this._editor().GetValue());
				}
			}
		})
	}

	GetTemplate() {
		return Template;
	}

	AfterRender() {}

	get Value(): KnockoutObservable<string> {
		return this._value;
	}

	set IsEditMode(value: boolean) {
		this._isEditMode(value);
	}

	GetEditValue(): string {
		return this._editor().GetValue();
	}

	get FieldName(): string {
		return this._fieldName;
	}

	CancelEdit() {
		this._editor(null);
		this._isEditMode(false);
	}
}