import * as ko from 'knockout'
import * as _ from 'underscore';

import {BaseControl, IControlValue} from 'Core/Controls/BaseControl/BaseControl'
import {IControlParam} from 'Core/Screens/IScreen'
import {CHECKBOX_PROPERTIES} from "Core/GeneralProperties/Managers/CheckboxProperty/Constants";

import {SelectUserStore} from 'Core/Controls/SelectUser/Stores/SelectUserStore';
import {RecordStore} from 'Core/Common/Stores/RecordStore';

import Config from "Core/Controls/ButtonPlan/config.json";

import ViewTemplate from "Core/Controls/ButtonPlan/Templates/View.html"
import HelpViewTemplate from "Core/Controls/ButtonPlan/Templates/HelpView.html"
import DesignTemplate from "Core/Controls/ButtonPlan/Templates/Design.html"
import ToolbarTemplate from "Core/Controls/ButtonPlan/Templates/ToolBar.html"
import { TableStore } from '../../Common/Stores/TableStore';
import { BlockUI } from '../../Common/BlockUi';
import { CONTROL_TYPES, GridStore, NOTIFICATIONS, Notifier, ScreenTypes } from '../Grid/BaseGrid/Index';
import { RecordLinker } from '../../Components/RecordLinker/RecordLinker';
import { RenderModes } from '../../Constant';


ko.templates["Core/Controls/ButtonPlan/Templates/ToolBar"] = ToolbarTemplate;
ko.templates["Core/Controls/ButtonPlan/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/ButtonPlan/Templates/GridRow"] = ViewTemplate;
ko.templates["Core/Controls/ButtonPlan/Templates/HelpView"] = HelpViewTemplate;
ko.templates["Core/Controls/ButtonPlan/Templates/Edit"] = ViewTemplate;
ko.templates["Core/Controls/ButtonPlan/Templates/Design"] = DesignTemplate;

export class ButtonPlan extends BaseControl {
	private _businessRoleId: KnockoutObservable<number>;
	private _exampleAppointmentId: KnockoutObservable<number>;

	constructor(params: IControlParam) {
		super(params, Config);
		this._businessRoleId = ko.observable(0);
		this._exampleAppointmentId = ko.observable(0);
        this._style = ko.computed(() => {
            return {
                backgroundColor: this._backgroundColor(),
                color: this._color(),
                borderColor: this._borderColor(),
                borderWidth: this._border() ? '1px' : '0',
                padding: this._border() ? '6px 14px' : '7px 14px'
            };
        });
		this.Init();

        this._renderMode.subscribe((newValue)=>{            
            if(newValue === RenderModes.GridRow && this._model().SubFlowTypeId != 0){                
                this._isVisible(this._gridRow.RecordTypeId == this._model().SubFlowTypeId);
            }
        });
	}

	private Init(): void {
        this.ApplyProperties();
        this._enabled(true);

        this.AddEvent('SELECT_USERS_BY_BUSINESS_ROLE');
	}

	Click(): void {
		if (this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
		} else {
            this.ShowAgendaScreen();
		}
	}

    async ShowAgendaScreen(){
        BlockUI.Block();
        let agenda = await TableStore.Get({ TableName: "AGENDA" });
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
        let screen = await screenManager.GetScreenByScreenType(agenda.Id, ScreenTypes.SpecialScreen);

        if (screen.ScreenDoesNotExistError) {
            Notifier.Failed(screen.ScreenDoesNotExistErrorMessage);
        }else{
            if (this._businessRoleId()) {
                const selectUserByBusinessRoleModel = await SelectUserStore.GetSelectUsersByBusinessRole(agenda.Id, Number(this._businessRoleId()));
                screen.Trigger('SELECT_USERS_BY_BUSINESS_ROLE', { SelectUserByBusinessRoleModel: selectUserByBusinessRoleModel });
            }

            if (this._exampleAppointmentId()) {
                const recordExists = await RecordStore.RecordExists({ TableId: agenda.Id, RecordId: this._exampleAppointmentId() });
                if (recordExists) {
                    screen.ExampleAppointmentId = this._exampleAppointmentId();
                }
            }

            screen.On('RECORD_CREATED', this, async (eventArgs)=>{
                await this.LinkAgenda(eventArgs.data.RecordId,agenda.Id);
                await this.SwitchToSubFlowStatus();
                this.Screen.Refresh();
                screen.Close();
            });

            screen.ShowInModal();
        }

        BlockUI.Unblock();
    }

    get SubjectEntityId(): number {
        if(this.IsInGrid){
            return this._gridRow.EntityId;
        };

        return this.Screen.GetEntityId();
    }

    get  SubjectRecordId(): number {
        if(this.IsInGrid){
            return this._gridRow.RecordId;
        };

        return this.Screen.GetRecordId();
    }

    get IsInGrid(): boolean {
        return this._parentControl && this._parentControl.GetType() === CONTROL_TYPES.Grid;
    }

    async LinkAgenda(agendaRecordId: number, agendaEntityId: number){
        await RecordLinker.LinkRecord({ MainTableId: this.SubjectEntityId, MainRecordId: this.SubjectRecordId, LinkedTableId: agendaEntityId, LinkedRecordId: agendaRecordId })
        .then((result) => {
            if (!result.IsSuccessfull) {
                Notifier.Failed(NOTIFICATIONS.COULD_NOT_PROCEED_PLEASE_CREATE_THE_LINK_BETWEEN_ENTITIES
                    .replace('{entity1}', this.Screen.GetEntityName())
                    .replace('{entity2}', 'AGENDA'));
            }
        });
    }

    async SwitchToSubFlowStatus(){
        let subFlowId = this.GeneralProperties.GetPropertyValue("SubFlow");
        if(subFlowId && !isNaN(subFlowId.Value)){
            await GridStore.SwitchToNextStatus({ EntityId: this.SubjectEntityId, RecordId: this.SubjectRecordId, NextStatusId: subFlowId.Value});
        }        
    }

	SetValue(value: IControlValue): void {
	}

    SetHideCaption(data: boolean) {
        this.HideCaption(this.HideCaptionFromProperty() ? this.HideCaptionFromProperty() : data);
    }

    GetHideCaption(): boolean {
        return this.HideCaption();
    }

    ApplyProperties() {
        if (this.Properties) {
            this.AssignProperty('General', 'BusinessRole', this._businessRoleId);
            this.AssignProperty('General', 'ExampleAppointment', this._exampleAppointmentId);
            this.AssignProperty('Styling', 'BackgroundColor', this._backgroundColor);
            this.AssignProperty('Styling', 'TextColor', this._color);
            this.AssignProperty('Styling', 'Border', this._border);
            this.AssignProperty('Styling', 'BorderColor', this._borderColor);
            this.AssignProperty('Styling', CHECKBOX_PROPERTIES.HIDE_CAPTION, this.HideCaptionFromProperty);
            this.HideCaption(this.HideCaptionFromProperty());
        }
    }

    private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
        if (this.Properties[groupedBy]) {
            _.each(this.Properties[groupedBy].Properties,
                (property: any) => {
                    if (property.hasOwnProperty(propertyName)) {
                        const propertyValue = property[propertyName].hasOwnProperty('Value')
                            ? property[propertyName].Value
                            : property[propertyName];
                        propertyHolder(propertyValue);
                    }
                });
        }
    }

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}
}