import {UsersViewModel} from "./UsersViewModel";

export class YearDataViewModel {
	Year: number;
	Quaters: Quater[];

	CurrentYear: number;
	CurrentWeek: number;
    DataBySubject: boolean;

	Users: UsersViewModel;

	constructor() {
		this.Quaters = [];
		this.DataBySubject = false;
	}

	IsCurrentYear() {
		return this.Year === this.CurrentYear;
	}

	IsCurrentWeek(week: Week) {
		return this.IsCurrentYear() && week.Number === this.CurrentWeek;
	}

	NavigationAllowed(week: Week) {
		return this.Year < this.CurrentYear ||
			(this.Year === this.CurrentYear && week.Number <= this.CurrentWeek);
	}
}

export class Quater {
	Weeks: Week[];

	constructor(initializers?: Partial<Quater>) {
		let weeks = [];

		if (initializers) {
			weeks = initializers.Weeks || [];
		}

		this.Weeks = weeks;
	}
}

export class Week {
	Number: number;
	Booked: any;
	Saldo: any;
	IsEditable: boolean;
	Empty: boolean;
    HasDisapprovedHours: boolean;
}