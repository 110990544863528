import {BulkEmailEntity} from "./BulkEmailEntity";
import {BulkEmailTemplate} from './BulkEmailTemplate';

export class BulkEmailConfig {
	MailFieldId: number;
	TemplateId: number;
	RequestTemplateId: number;
	ProcessStatusId: number;
	TemplateEntityId: number;
	Registered: boolean;
	Running: boolean;

	Subjects: BulkEmailEntity[];
	Templates: BulkEmailTemplate[];
}