import * as ko from 'knockout';
import * as _ from 'underscore';
import Dropzone from 'dropzone';

import {Modal} from 'Core/Common/Modal';
import {Event} from "Core/Common/Event";
import {URL} from 'Core/Common/Url';
import {Request} from 'Core/Common/Request';
import {Notifier} from 'Core/Common/Notifier';
import { BlockUI } from 'Core/Common/BlockUi';
import {IconModel} from "Core/Controls/BaseControl/Models/IconModel";
import {FONT_NAME, ICON_NAME} from 'Core/Constant';

// Templates
import IconManagerTemplate from 'Core/Components/IconManager/Templates/IconManager.html'
ko.templates['Core/Components/IconManager/Templates/IconManager'] = IconManagerTemplate;

export class IconManager extends Event {
    private _modal: Modal;
    private _icons: IconModel[];
    private _entityColor: string;
    private _groupedIcons: KnockoutObservable<any>;
    private _dropzoneShown: KnockoutObservable<boolean>;
    private _uploadetImages: any[];
    private dropZone: any;
    private FONT_NAME = FONT_NAME;
    private _iconModel = IconModel;

    constructor(icons: IconModel[]) {
        super();

        this._icons = icons;
        this._entityColor = null;
        this._groupedIcons = ko.observable(null);
        this._dropzoneShown = ko.observable(false);
        this._uploadetImages = [];
        this.AddEvent('ICON_SELECTED');
        this.InitUI();
    }

    RenderTemplate() {
        let i = 0;
        this._groupedIcons(_.groupBy(this._icons, (item) => item.FontName));

        _.each(this._groupedIcons(), (value: IconModel[], key: any) => {
            if (i === 0) {
                value.splice(0, 0, {
                    Name: ICON_NAME.EMPTY_ICON,
                    FontName: key,
                    Image: null,
                    IsIcon: true,
                    IsImage: false,
                    Id: 0
                });
                i++;
            }
        });
    }

    SetIcon(icon: IconModel) {
        this.Trigger('ICON_SELECTED', {Icon: icon});
        this._modal.Close();
    }

    InitUI() {
        this._modal = new Modal();
        this.RenderTemplate();
    }

    Show() {
        this._modal = new Modal({});
        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
    }

    AfterRender() {
        if (this._modal) {
            this._modal.Show();
        }
    }

    OpenDropzone() {
        this._dropzoneShown(true);
        this.InitDropzone();
    }

    HideDropzone() {
        this._dropzoneShown(false);
    }

    private InitDropzone() {
        var self = this;
        var dz = new Dropzone('.imagedropzone',
            {
                url: URL.CONTROLS.IMAGE.SAVE_UPLOADED_FILE,
                autoProcessQueue: false,
                addRemoveLinks: true,
                dictRemoveFile: '',
                acceptedFiles: '.png,.gif,.jpg,.jpeg,.jpe,.jif,.jfif,.jfi',
            });

        dz.on('addedfile', self.AfterUpload.bind(self));
        dz.on('removedfile', (file) => {
            var self = this;
            let fileIndex = self._uploadetImages.indexOf(file);
            if (fileIndex === -1) {
                const fileToRemove = self._uploadetImages.find(image => image.Image === (file as any).dataURL && image.Name === file.name);
                fileIndex = self._uploadetImages.indexOf(fileToRemove);
            }
            self._uploadetImages.splice(fileIndex, 1);
        });
        self.dropZone = dz;
    }

    AfterUpload(file) {
        var self = this;
        var reader = new FileReader();
        reader.onloadend = () => {
            self._uploadetImages.push({
                Name: file.name,
                FontName: 'Graphical',
                Image: reader.result
            });
        };

        reader.readAsDataURL(file);
    };

    Upload() {
        var self = this;
        if (this._uploadetImages.length > 0) {
            BlockUI.Block();
            Request.Send({
                proxy: {
                    contentType: 'application/json; charset=utf-8',
                    type: 'POST',
                    url: '/api/DataBaseDesignerApi/SaveIcons'
                }, params: JSON.stringify({ IconsList : this._uploadetImages })
            }).always(() => BlockUI.Unblock())
            .then((response) => {
                self._uploadetImages = [];
                self.dropZone.removeAllFiles(true);
                self._dropzoneShown(false);
                new Notifier().Success('Successfully saved.');
                self._icons.push(...response);
                self._groupedIcons(_.groupBy(self._icons, (item) => item.FontName));
            }).fail(data => { 
                let jsonResult = JSON.parse(data.message);
				new Notifier().Failed(jsonResult.Message);
			 });
        }
    };

    GetTemplateName() {
        return 'Core/Components/IconManager/Templates/IconManager';
    }
}