import * as ko from 'knockout';
import * as _ from 'underscore';

import {GenericDeserialize} from "libs/cerialize";

import {CONTROL_TYPES, RenderModes} from 'Core/Constant';

import {BaseControl} from "Core/Controls/BaseControl/BaseControl";
import {IControlParam} from "Core/Screens/IScreen";

import {QueryResult} from "Core/Controls/QueryResult/QueryResult";
import {GenericButton} from "Core/Controls/GenericButton/GenericButton";

import {QueryExpressionModel} from "Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel";

import {IReadyForProcessingQuery} from "Core/Components/Dashboards/FinancialDashboard/Params/IFinancialDashboardParams";
import {FinancialDashboard as FinancialDashboardComponent} from 'Core/Components/Dashboards/FinancialDashboard/FinancialDashboard';

import ToolBarTemplate from 'Core/Controls/FinancialDashboard/Templates/ToolBar.html';
import DesignTemplate from 'Core/Controls/FinancialDashboard/Templates/Design.html';
import ViewTemplate from 'Core/Controls/FinancialDashboard/Templates/View.html';

ko.templates['Core/Controls/FinancialDashboard/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/FinancialDashboard/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/FinancialDashboard/Templates/View'] = ViewTemplate;

export class FinancialDashboard extends BaseControl {
    private _component: FinancialDashboardComponent;

    constructor(params: IControlParam) {
        super(params);

        this.Init();
    }

    ApplyProperties(){}

    private Init() {
        if (this._renderMode() !== RenderModes.ToolBar) {
            this._component = new FinancialDashboardComponent({
                Control: this,
                RenderMode: this._renderMode(),
                ReadyForProcessingQueries: this.GetReadyForProcessingQueries(),
                FinancialButtons: this.GetFinancialButtons(),
            });

            this._component.Render();
        }
    }

    private GetReadyForProcessingQueries(): IReadyForProcessingQuery[] {
        return this._subControls()
            .filter(subControl => subControl.GetType() == CONTROL_TYPES.QueryResult)
            .map(queryResult => this.GetReadyForProcessingQuery(queryResult as QueryResult))
    }

    private GetFinancialButtons(): GenericButton[] {
        return <GenericButton[]>this._subControls()
            .filter(subControl => subControl.GetType() === CONTROL_TYPES.GenericButton)
    }

    private GetReadyForProcessingQuery(queryResult: QueryResult): IReadyForProcessingQuery {
        const queryProperties = queryResult.Properties
            && queryResult.Properties.Query
            && queryResult.Properties.Query.Properties;

        const queryExpressionProperty = _.find<any>(queryProperties, property => property.Query);

        if (queryExpressionProperty) {
            const queryExpression = GenericDeserialize<QueryExpressionModel>(JSON.parse(queryExpressionProperty.Query), QueryExpressionModel);

            return {
                QueryResultId: queryResult.GetControlId(),
                QueryExpression: queryExpression
            };
        }
    }
}