import * as ko from "knockout";

import TagbookTemplate from "Core/Controls/Tag/Tagbook/TagbookTemplate.html";

ko.templates["Core/Controls/Tag/Tagbook/TagbookTemplate"] = TagbookTemplate;

import {TagbookList} from "Core/Controls/Tag/Tagbook/TagbookList/TagbookList";
import {TagModel, TagGroupModel} from "Core/Controls/Tag/Models/TagsScreenDataModel";
import {TagGroupViewModel} from 'Core/Controls/Tag/ViewModels/TagGroupViewModel';
import {TagViewModel} from 'Core/Controls/Tag/ViewModels/TagViewModel';
import {ChangeModel} from 'Core/Controls/Tag/Tagbook/ChangeModel';
import {IModalComponent} from 'Core/Common/Interfaces/IModalComponent';
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";

import {Event} from 'Core/Common/Event';

export class Tagbook extends Event implements IModalComponent {
	private _firstLetters: KnockoutObservableArray<string>;
	private _activeLetter: KnockoutObservable<string>;
	private _tagbookList: KnockoutObservable<TagbookList>;
	private _taglist: TagViewModel[];
	private _term: string;
	private _groups: any[];
	private _selectedTagsLength: KnockoutComputed<number>;
	private _selectedTagsInitial: TagModel[];
	private _changeModel: ChangeModel;
	private _mode: KnockoutObservable<string>;
	private _currentGroup: KnockoutObservable<any>;
	private _labels = LABELS;

	constructor() {
		super();

		this._mode = ko.observable('book');
		this._changeModel = new ChangeModel();
		this._activeLetter = ko.observable(null);
		this._firstLetters = ko.observableArray([]);
		this._currentGroup = ko.observable(null);
		this._term = null;
		this._taglist = [];
		this._groups = [];
		this._selectedTagsLength = ko.computed(() => this._changeModel.SelectedTags.length);
		this._tagbookList = ko.observable(null);
	}

	ChangeMode(nextMode: string):void {
		this._mode(nextMode);
		this._tagbookList().ChangeMode(nextMode);
		this._activeLetter(LABELS.ALL);
		this._currentGroup(null);

		this._tagbookList().FilterList(LABELS.ALL, this._currentGroup());

		this.SetLettersList(this._taglist);
	}

	SetTagsList(tagsModel: TagGroupViewModel[], selectedTags: TagModel[], term: string) {
		this._selectedTagsInitial = selectedTags;
		this._changeModel.SelectedTags = [...selectedTags];

		tagsModel.forEach((group: TagGroupViewModel) => {
			this._taglist = this._taglist.concat(group.Tags);
			this._groups.push({Name: group.GroupName, Id: group.Tags[0].GroupId})
		});

		this.SetLettersList(this._taglist);

		const takbookList = new TagbookList(this._taglist, this._groups);

		this._tagbookList(takbookList);

		takbookList.On('TagSeleted', this, (data) => this.SelectTag(data.data));
		takbookList.On('GroupSeleted', this, (data) => this.SelectGroup(data.data));

		if (term !== null) {
			this.SelectLetter(term[0]);
		}
	}

	private SelectTag(tag: any): void {
		const tagModel = tag.data;
		const isTagAlreadySelected = this._changeModel.SelectedTags.filter((tag_) => tag_.TagId === tagModel.TagId).length;

		if (!isTagAlreadySelected) {
			const mappedModel = this.MapToTagModel(tagModel);

			this._changeModel.SelectTag(mappedModel);
		}
	}

	RemoveTag(data: TagModel): void {
		let selectedTags = this._changeModel.SelectedTags;

		selectedTags = selectedTags.filter((tag) => tag !== data);

		this._changeModel.SelectedTags = selectedTags;

		this._changeModel.RemoveTag(data);
	}

	SelectGroup(data) {
		const group = data.data;

		this._tagbookList().ChangeMode('book');

		this._currentGroup(group);

		const list = this._taglist.filter((tag) => tag.GroupId === group.Id);

		this.SetLettersList(list);

		this._tagbookList().FilterList(LABELS.ALL, group);
	}

	ClearCurrentGroup() {
		this._currentGroup(null);
		this.ChangeMode('group');
	}

	private SetLettersList(taglist): void {
		const letters = this.GenerateFirstLetters(taglist);

		this._activeLetter(letters[0]);
		this._firstLetters(letters);
	}

	private GenerateFirstLetters(tagslist: TagViewModel[]): string[] {
		const firstLetters = [LABELS.ALL];

		tagslist.sort((a, b) => {
			const nameA = a.Name.toUpperCase();
			const nameB = b.Name.toUpperCase();

			if (nameA < nameB) return -1;

			if (nameA > nameB) return 1;

			return 0;
		});

		let currentLetter;

		tagslist.forEach((tag: TagViewModel) => {
			const nextLetter = tag.Name[0].toUpperCase();

			if (nextLetter !== currentLetter) {
				tag.IsFirstWord = true;

				currentLetter = nextLetter;
				firstLetters.push(currentLetter);
			} else {
				tag.IsFirstWord = false;
			}
		});

		return firstLetters;
	}

	Save() {
		this.Trigger('Save', {
			addedTags: this._changeModel.AddedList,
			deletedTags: this._changeModel.DeletedList,
			selectedTags: this._changeModel.SelectedTags
		});
	}

	Cancel() {
		this.Trigger('Cancel');
	}

	Reset() {
		this._changeModel.SelectedTags = [...this._selectedTagsInitial];
	}

	SelectLetter(letter: string): void {
		this._activeLetter(letter);

		this._tagbookList().FilterList(this._activeLetter(), this._currentGroup());
	}

	IsActiveLetter(letter: string): boolean {
		return this._activeLetter() === letter;
	}

	MapToTagModel(model: TagViewModel): TagModel {
		const group = this._groups.filter((el) => el.Id === model.GroupId)[0];

		const tagModel: TagModel = {
			GroupId: model.GroupId,
			GroupName: group && group.Name,
			Name: model.Name,
			TagId: model.TagId,
			TypeId: model.TypeId
		};

		return tagModel;
	}

	GetTemplateName(): string {
		return 'Core/Controls/Tag/Tagbook/TagbookTemplate';
	}

	AfterRender() {
	}

	CloseComponentInModal() {
		this.Trigger('Cancel');
	}
}
