import * as ko from 'knockout';
import * as _ from 'underscore';
import * as moment from 'moment';

import 'editableCell';

import {BaseControl} from 'Core/Controls/BaseControl/BaseControl';
import {IControlParam} from 'Core/Screens/IScreen';

import {IControl} from "../IControl";
import {CONTROL_TYPES, EVENTS} from "../../Constant";
import {TranslationManager} from "../../Components/Translation/TranslationManager";

import {PlannerLevel} from "../PlannerLevel/PlannerLevel";
import {PlannerLevelRowModel, TimeCellModel} from "../PlannerLevel/Models/PlannerLevelRowModel";
import {GlobalManager, GLOBALS} from "../../GlobalManager/GlobalManager";
import {CONFIRMATIONS, LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";

import DesignTemplate from 'Core/Controls/PlannerPage/Templates/Design.html';
import ViewTemplate from 'Core/Controls/PlannerPage/Templates/View.html';
import {IGetPlannerPageDataRequestModel, PlannerPageStore} from "./Store/PlannerPageStore";
import {BlockUI} from "../../Common/BlockUi";
import {CustomColumnHeaderModel} from "./Models/CustomColumnHeaderModel";
import {TimeLineColumnModel} from "./Models/PlannerPageModel";
import {ProjectManagerModel} from "./Models/ProjectManagerModel";
import {UserVarsManager, IPlannerPageParams} from 'Core/UserVarsManager/UserVarsManager';
import {Notifier} from 'Core/Common/Notifier';
import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS, Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { CopyQueryButtonConfirmationDialog, EVENTS as QUERY_BUTTON_CONFIRMATION_DIALOG_EVENTS } from 'Core/Controls/PlannerPage/CopyQueryButtonConfirmationDialog';

ko.templates['Core/Controls/PlannerPage/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/PlannerPage/Templates/View'] = ViewTemplate;

export interface IMonth {
	Title: string;
	IsActive: KnockoutObservable<boolean>;
}

export interface IMonthHeader {
	Year: string;
	Months: Array<IMonth>;
}

export interface IDay {
	DayNum: string;
	DayName: string;
	IsWeekend: boolean;
	IsActive: KnockoutObservable<boolean>;
}

export interface IWeek {
	Title: string;
	IsActive: KnockoutObservable<boolean>;
}

export interface IDayHeader {
	Title: string;
	Week: string;
	Year: string;
	StartDay: string;
	Days: Array<IDay>;
}

export interface IWeekHeader {
	Title: string;
	Weeks: Array<IWeek>;
}

export const PLANNER_TYPES = {
	MONTH: 'Month',
	DAY: 'Day',
	WEEK: 'Week'
}

export interface IViewType {
	Value: string,
	Title: string
}

export const PROPERTIES = {
	ENABLE_MONTH_VIEW: 'EnableMonthView',
	ENABLE_WEEK_VIEW: 'EnableWeekView',
	ENABLE_DAY_VIEW: 'EnableDayView',
	ENABLE_PROJECT_MANAGER_FILTER: 'EnableProjectManagerFilter'
}

export class PlannerPage extends BaseControl{
	private _plannerLevels: KnockoutObservableArray<IControl>;
	private _startDate: KnockoutObservable<string>;
	private _weeks: Array<number>;
	private _selectedWeek: KnockoutObservable<number>;
	private _initialState: boolean;
	private _monthHeaders: KnockoutObservableArray<IMonthHeader>;
	private _dayHeaders: KnockoutObservableArray<IDayHeader>;
	private _weekHeaders: KnockoutObservableArray<IWeekHeader>;
	private _customColumnHeaders: KnockoutObservableArray<CustomColumnHeaderModel>;
	private _viewTypes: KnockoutObservableArray<IViewType>;
	private _selectedView: KnockoutObservable<IViewType>;
	private _renderViewMode: KnockoutObservable<string>;
	private _timeRangeLabel: KnockoutObservable<string>;
	private _weekPlanning: KnockoutObservable<boolean>;
	private _weekPlanningToggleClass: KnockoutComputed<string>;
	private _selectedDate: string;
	private _managers : KnockoutObservableArray<ProjectManagerModel>;
	private _manager: KnockoutObservable<ProjectManagerModel>;
	protected _labels = LABELS;
	private _copyColumnMode: KnockoutObservable<boolean>;
	private _copyCellMode: KnockoutObservable<boolean>;
	private _copyFromColumnIndex: number;
	private _showManagerFilter: boolean;

	constructor(params: IControlParam){
		super(params)
		this._weekPlanning = ko.observable(this.GetDefaultIsWeekPlanning());
		this._plannerLevels = ko.observableArray([]);
		this._startDate = ko.observable(this.GetDefaultStartDate());
		this._weeks = [];
		this._selectedWeek = ko.observable(this.GetDefaultPeriod());
		this._initialState = true;
		this._monthHeaders = ko.observableArray([]);
		this._dayHeaders = ko.observableArray([]);
		this._weekHeaders = ko.observableArray([]);
		this._managers = ko.observableArray([]);
		this._manager = ko.observable(null);
		this._copyColumnMode = ko.observable(false);
		this._copyCellMode = ko.observable(false);
		this._copyFromColumnIndex = null;
		this._viewTypes = ko.observableArray([]);
		this.ApplyProperties();
		
		this._customColumnHeaders = ko.observableArray([]);
		this._timeRangeLabel = ko.observable('');

		this._selectedView = ko.observable(this.GetDefaultViewType());
		this._renderViewMode = ko.observable(this._selectedView()?.Value);

		this._weekPlanningToggleClass = ko.computed(()=>{
			return this._weekPlanning() ? 'fa fa-toggle-on' : 'fa fa-toggle-off'
		});

		this._selectedView.subscribe((newValue: IViewType) => {
			this.SetDefaultViewType(newValue.Value);
			this._weekPlanning(false);
			this.LoadData();

			if(newValue.Value === PLANNER_TYPES.MONTH || newValue.Value === PLANNER_TYPES.WEEK){
				this._timeRangeLabel(`${this._labels.MONTHS}:`);
			}

			if(newValue.Value === PLANNER_TYPES.DAY){
				this._timeRangeLabel(`${this._labels.WEEKS}:`);
			}
		});

		this._weekPlanning.subscribe((newValue) => {
			this.SetDefaultIsWeekPlanning(newValue);
			_.each(this._subControls(), (subControl) => {
				if(subControl instanceof PlannerLevel){
					subControl.IsHightResolutionPlanning = newValue;
				}
			});
		})

		this._selectedWeek.subscribe(() =>{
			this.SetDefaultPerion(this._selectedWeek());
			this.LoadData();
		});

		for(let weekNumber=1; weekNumber <= 36;weekNumber++){
			this._weeks.push(weekNumber);
		}

		_.each(this._subControls(), (subControl) => {
			if(subControl instanceof PlannerLevel){
				subControl.ShowDivideRow = true;
			}
		});

		this.AddEvent(EVENTS.ON_PLANNER_PAGE_CLICK);
		this.Init();
	}

	ApplyProperties(){
		let parentProperties = this._parentControl.GeneralProperties;       
		if(parentProperties.GetPropertyValue(PROPERTIES.ENABLE_MONTH_VIEW)){
			this._viewTypes.push({ Value: PLANNER_TYPES.MONTH, Title: this._labels.MONTH });
		}

		if(parentProperties.GetPropertyValue(PROPERTIES.ENABLE_DAY_VIEW)){
			this._viewTypes.push({ Value: PLANNER_TYPES.DAY, Title: this._labels.DAY });
		}

		if(parentProperties.GetPropertyValue(PROPERTIES.ENABLE_WEEK_VIEW)){
			this._viewTypes.push({ Value: PLANNER_TYPES.WEEK, Title: this._labels.WEEK });
		}

		this._showManagerFilter = parentProperties.GetPropertyValue(PROPERTIES.ENABLE_PROJECT_MANAGER_FILTER);

		if(this._viewTypes().length === 0){
			Notifier.Failed(NOTIFICATIONS.NO_ANY_VIEW_OPTIONS);
		}
	}

	private ResetSubControlState(){
		_.each(this._subControls(), (item)=>{
			if(item instanceof PlannerLevel){
				item.ResetDefaultExpandedState();
			}
		})
	}

	private GetDefaultStartDate(): string {		
		let userVars = this.GetUserVars();
		let startDate = null;
		if(userVars){
			startDate = userVars.StartDate;
		}
		return startDate || moment().format();
	}

	private SetDefaultStartDate(value: string){		
		let userVars = this.GetUserVars();
		if(userVars.StartDate != value){
			userVars.StartDate = value;
			this.ResetSubControlState();
			this.SetUserVars(userVars);	
		}
	}

	private GetDefaultIsWeekPlanning(): boolean {		
		let userVars = this.GetUserVars();
		if(userVars){
			return userVars.IsWeekPlannnig;
		}
		return false;
	}

	private SetDefaultIsWeekPlanning(value: boolean){		
		let userVars = this.GetUserVars();
		userVars.IsWeekPlannnig = value;
		this.ResetSubControlState();
		this.SetUserVars(userVars);
	}

	private SetDefaultPerion(period: number){		
		let userVars = this.GetUserVars();
		userVars.Period = period;
		this.ResetSubControlState();
		this.SetUserVars(userVars);
	}

	private GetDefaultPeriod(): number {		
		let userVars = this.GetUserVars();
		if(userVars){
			return userVars.Period;
		}
		return 36;
	}

	private SetDefaultProjectManagerId(projectManagerId: number){		
		let userVars = this.GetUserVars();
		userVars.ProjectManagerId = projectManagerId;
		this.ResetSubControlState();
		this.SetUserVars(userVars);
	}

	private GetDefaultProjectManagerId(): number {		
		let userVars = this.GetUserVars();
		if(userVars){
			return userVars.ProjectManagerId;
		}
		return null;
	}

	private GetDefaultViewType(): IViewType {		
		let userVars = this.GetUserVars();
		if(userVars){
			let viewType = _.find(this._viewTypes(), (type)=> type.Value === userVars.ViewType);
			if(viewType) {
				return viewType;
			}
		}
		return this._viewTypes()[0];
	}

	private SetDefaultViewType(viewTypeValue: string){		
		let userVars = this.GetUserVars();
		userVars.ViewType = viewTypeValue;
		this.ResetSubControlState();
		this.SetUserVars(userVars);
	}

	private GetUserVars(): IPlannerPageParams {
		return UserVarsManager.Instance.GetPlannerPageParams(this.GetControlId());
	}

	private SetUserVars(params: IPlannerPageParams) {
		UserVarsManager.Instance.SetPlannerPageParams(this.GetControlId(), params);
	}

	ToggleWeekPlanning(){
		this._weekPlanning(!this._weekPlanning());
	}

	GetLocale() {
		return moment.locale();
	}

	Next(){
		if(this._selectedView().Value === PLANNER_TYPES.WEEK){
			this._startDate(moment(this._startDate()).add(1, 'week').format());
		}else{
			this._startDate(moment(this._startDate()).add(1, 'M').format());
		}
	}

	NextYear(){
		this._startDate(moment(this._startDate()).add(1, 'y').format());
	}

	Previous(){
		if(this._selectedView().Value === PLANNER_TYPES.WEEK){
			this._startDate(moment(this._startDate()).subtract(1, 'week').format());
		}else{
			this._startDate(moment(this._startDate()).subtract(1, 'M').format());
		}
	}

	PreviousYear(){
		this._startDate(moment(this._startDate()).subtract(1, 'y').format());
	}

	get MonthHeaders(){
		return this._monthHeaders;
	}

	get DayHeaders(){
		return this._dayHeaders;
	}

	get WeekHeaders(){
		return this._weekHeaders;
	}

	get CustomColumnHeaders(){
		return this._customColumnHeaders;
	}

	SetHeader(timeLine: Array<TimeLineColumnModel>){
		this._monthHeaders([]);
		this._dayHeaders([]);
		this._weekHeaders([]);

		if(this._selectedView().Value === PLANNER_TYPES.MONTH) {
			this.BuildMonthHeader(timeLine);
		}

		if(this._selectedView().Value === PLANNER_TYPES.DAY) {
			this.BuildDayHeader(timeLine);
		}

		if(this._selectedView().Value === PLANNER_TYPES.WEEK) {
			this.BuildWeekHeader(timeLine);
		}
	}

	BuildWeekHeader(timeLine: Array<TimeLineColumnModel>){
		if (timeLine) {
			let byWeek = [];
			let currentGroup = null;

			let days = [];
			_.each(timeLine, (timeValue: TimeLineColumnModel) => {
				if(currentGroup !== null && moment(currentGroup).month() !== moment(timeValue.TimeValue).month()){
					byWeek.push({ Month: currentGroup, Days: days });
					days = [];
				}

				currentGroup = timeValue.TimeValue;
				days.push(timeValue);

				if(_.last(timeLine) === timeValue){
					byWeek.push({ Month: currentGroup, Days: days });
				}
			});
			_.each(byWeek, (value) => {
				let header: IWeekHeader = {
					Title: `${moment(value.Month).format('MMMM')}, ${moment(value.Month).year()}`,
					Weeks: _.map(value.Days, (month: TimeLineColumnModel) => {
						return { Title: `${this._labels.WEEK[0]}${moment(month.TimeValue).isoWeek()}`, StartDate: moment(month.TimeValue).format('DD'), IsActive: ko.observable(false) };
					})
				};

				this._weekHeaders.push(header);
			});
		}
	}

	BuildMonthHeader(timeLine: Array<TimeLineColumnModel>){
		if (timeLine) {
			let byYear = _.groupBy(timeLine, (timeValue) => {
				return moment(timeValue.TimeValue).year();
			});

			_.each(byYear, (value, key) => {
				let header: IMonthHeader = {
					Year: key,
					Months: _.map(value, (month) => {
						return { Title: moment(month.TimeValue).format('MMM'), IsActive: ko.observable(false) };
					})
				};

				this._monthHeaders.push(header);
			});
		}
	}

	BuildDayHeader(timeLine: Array<TimeLineColumnModel>){
		let byWeek = [];
		let currentGroup = null;
		if (timeLine) {

			let days = [];
			_.each(timeLine, (timeValue: TimeLineColumnModel) => {

				if(currentGroup !== null && currentGroup !== moment(timeValue.TimeValue).isoWeek()){
					byWeek.push({ Week: currentGroup, Days: days });
					days = [];
				}

				currentGroup = moment(timeValue.TimeValue).isoWeek();

				days.push(timeValue);

				if(_.last(timeLine) === timeValue){
					byWeek.push({ Week: currentGroup, Days: days });
				}
			});

			_.each(byWeek, (value) => {
				let firstDayOfWeek = _.first(value.Days) as TimeLineColumnModel;

				let header: IDayHeader = {
					Title: `${this._labels.WEEK} ${value.Week}: ${ moment(firstDayOfWeek.TimeValue).format('DD')} ${moment(firstDayOfWeek.TimeValue).format('MMM')} ${moment(firstDayOfWeek.TimeValue).format('YYYY')}`,
					Week: value.Week,
					StartDay: moment(_.first(value.Days)).format('DD'),
					Year: value.Week,
					Days: _.map(value.Days, (item: TimeLineColumnModel) => {
						return { IsActive: ko.observable(false), DayName: moment(item.TimeValue).format('ddd'), DayNum: moment(item.TimeValue).format('DD'), IsWeekend: item.IsWeekend }
					})
				};

				this._dayHeaders.push(header);
			});
		}
	}

	ActivateColumn(index: number){
		_.each(this._subControls(), (control) => {
			if(control instanceof PlannerLevel){
				control.ActivateColumn(index);
			}
		});

		if(this._selectedView().Value === PLANNER_TYPES.DAY) {
			let allDays: Array<IDay> = [];
			_.each(this._dayHeaders(), (item) => {
				allDays = allDays.concat(item.Days);
			});

			let activeDay = allDays[index];
			if (activeDay) {
				activeDay.IsActive(true);
			}
		}

		if(this._selectedView().Value === PLANNER_TYPES.MONTH) {
			let allMonths: Array<IMonth> = [];
			_.each(this._monthHeaders(), (item) => {
				allMonths = allMonths.concat(item.Months);
			});

			let activeMonth = allMonths[index];
			if (activeMonth) {
				activeMonth.IsActive(true);
			}
		}

		if(this._selectedView().Value === PLANNER_TYPES.WEEK) {
			let allMonths: Array<IMonth> = [];
			_.each(this._weekHeaders(), (item) => {
				allMonths = allMonths.concat(item.Weeks);
			});

			let activeMonth = allMonths[index];
			if (activeMonth) {
				activeMonth.IsActive(true);
			}
		}
	}

	DeactivateColumn(index: number){
		_.each(this._subControls(), (control) => {
			if(control instanceof PlannerLevel){
				control.DeactivateColumn(index);
			}
		});

		if(this._selectedView().Value === PLANNER_TYPES.DAY) {
			let allDays: Array<IDay>  = [];
			_.each(this._dayHeaders(), (item) => {
				allDays = allDays.concat(item.Days);
			});

			let activeDay = allDays[index];
			if (activeDay) {
				activeDay.IsActive(false);
			}
		}

		if(this._selectedView().Value === PLANNER_TYPES.MONTH) {
			let allMonths: Array<IMonth> = [];
			_.each(this._monthHeaders(), (item) => {
				allMonths = allMonths.concat(item.Months);
			});

			let activeMonth = allMonths[index];
			if (activeMonth) {
				activeMonth.IsActive(false);
			}
		}

		if(this._selectedView().Value === PLANNER_TYPES.WEEK ) {
			let allMonths: Array<IMonth> = [];
			_.each(this._weekHeaders(), (item) => {
				allMonths = allMonths.concat(item.Weeks);
			});

			let activeMonth = allMonths[index];
			if (activeMonth) {
				activeMonth.IsActive(false);
			}
		}
	}

	UnselectCells(exceptCell: TimeCellModel){
		_.each(this._subControls(), (control) => {
			if(control instanceof PlannerLevel){
				control.UnselectCells(exceptCell);
			}
		});
	}


	SelectDate(date: string){
		this._selectedDate = moment(date).format();
		this.SetDefaultStartDate(this._selectedDate);
		this.LoadData();
	}

	private IsFilterBySubject(): boolean {		
		let subControl = _.first(this._subControls());
		if(subControl){
			return this._form.GetScreen().IsConsultScreen && this._form.GetScreen().GetEntityId() === subControl.GetFieldModel().EntityId;
		}

		return false;
	}

	LoadData(){
		if(!this._selectedView()){
			return;
		}
		
		this.CancelCopy();
		let requestModel: IGetPlannerPageDataRequestModel = {
			StartDate: this._selectedDate,
			ControlId: this.GetControlId(),
			WeekNumber: this._selectedWeek(),
			RecordsPerPage: 20,
			PageNumber: 1,
			ViewType: this._selectedView().Value,
			ManagerId: this._manager() ? this._manager().Id : this.GetDefaultProjectManagerId(),
			SubjectRecordId: this.IsFilterBySubject() ? this._form.GetScreen().GetRecordId() : null
		};

		BlockUI.Block();
		PlannerPageStore.GetData(requestModel)
			.always(()=>{
				BlockUI.Unblock();
			})
			.then((result) =>{
				if(this._showManagerFilter && this._managers().length === 0){
					this._managers(result.ProjectManagers);
					this._manager(_.find(this._managers(), (item)=> item.Id === this.GetDefaultProjectManagerId()));

					this._manager.subscribe(() =>{
						this.SetDefaultProjectManagerId(this._manager() ? this._manager().Id : null);
						this.LoadData();
					});
				}

				this.SetHeader(result.TimeLineColumns);
				this._customColumnHeaders(result.CustomColumns);
				_.each(this._subControls(), (subControl) => {
					if(subControl instanceof PlannerLevel){
						subControl.SetWeekNumber(this._selectedWeek());
						let managerId = this._manager() ? this._manager().Id : null;
						subControl.SetProjectManager(managerId);
						subControl.SetStartDate(this._selectedDate);
						subControl.SetViewType(this._selectedView().Value);
						subControl.SetCustomColumnHeaders(this._customColumnHeaders());
						let controlData = _.filter(result.PlannerLevels, (item) =>{
							return item.ControlId === subControl.GetControlId();
						});

						subControl.PopulateData(controlData);
						subControl.Paginator().PageNumber = 1;
						subControl.Paginator().RecordsPerPage = 20;
					}
				});

				this._renderViewMode(this._selectedView().Value);
				
				setTimeout(() => {
					($('.left-planner-table') as any).freezeTable('update');
					($('.right-planner-table') as any).freezeTable('update');
				},0);

			});
	}

	Init(){
		let planerLevels  = _.filter(this._subControls(), (subControl) => {
			return subControl.GetType() === CONTROL_TYPES.PlannerLevel;
		});

		this._plannerLevels(planerLevels);

		this._subControls.subscribe(()=>{
			let planerLevels  = _.filter(this._subControls(), (subControl) => {
				return subControl.GetType() === CONTROL_TYPES.PlannerLevel;
			});

			this._plannerLevels(planerLevels);
		});

		if(this._selectedView()?.Value === PLANNER_TYPES.MONTH){
			this._timeRangeLabel(`${this._labels.MONTHS}:`);
		}

		if(this._selectedView()?.Value === PLANNER_TYPES.DAY){
			this._timeRangeLabel(`${this._labels.WEEKS}:`);
		}
	}

	Click(): void {
		if (this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
		} else {
			this.Trigger(EVENTS.ON_PLANNER_PAGE_CLICK);
		}
	}

	private GetName(): string {
		let translationList = this._model().NameTranslations;
		let currentLang = TranslationManager.Instance.GetCurrentLanguage();
		let currentLangItem = _.find(translationList, (item) => {
			return currentLang.Id === item.Language.K_Language;
		});
		if (currentLangItem) {
			this._getCurrentName = currentLangItem.Translation;
		}

		if (this._getCurrentName) {
			return this._getCurrentName;
		} else {
			return this._model().Name;
		}
	}

	get Title(): string {
		return this.GetName() || this.GetDefaultName();
	}

	static GetDefaultName() {
		return 'New page';
	}

	AddSubControl(control: IControl) {
		this._subControls.push(control);
	}

	BuildColumnContextMenu(column: IMonth | IDay | IWeek, plannerPage: PlannerPage){
        return {
            callback: function() {
				let index = plannerPage.GetColumnIndex(column);
				plannerPage._copyFromColumnIndex = index;
				plannerPage.CancelCopyToSelection();
				plannerPage.SelectCopyFromColumn(plannerPage);
				let notifiler = new Notifier();	
				notifiler.Warning(NOTIFICATIONS.SELECT_PERIOD.replace('{periodName}', plannerPage._selectedView().Title));
				plannerPage._copyColumnMode(true);
			},
			items: {
				"copyRow": { name: LABELS.COPY_PERIOD.replace('{periodName}', plannerPage._selectedView().Title) },
            }
        };
	}
	
	GetColumnIndex(column: IMonth | IDay | IWeek): number{
		let allItems: Array<IMonth | IDay | IWeek> = [];

		if(this._renderViewMode() === 'Month'){
			_.each(this._monthHeaders(), (header) => {
				allItems = allItems.concat(header.Months);
			});
		}


		if(this._renderViewMode() === 'Day'){
			_.each(this._dayHeaders(), (header) => {
				allItems = allItems.concat(header.Days);
			});
		}

		if(this._renderViewMode() === 'Week'){
			_.each(this._weekHeaders(), (header) => {
				allItems = allItems.concat(header.Weeks);
			});
		}

		return allItems.indexOf(column);
	}

	SelectColumn(index: number){
		if(this._copyFromColumnIndex === index) return;

		_.each(this._subControls(), (subControl) => {
			if(subControl instanceof PlannerLevel){
				subControl.SelectCopyToCell(index);
			}
		});
	}

	SelectCopyFromColumn(page: PlannerPage){
		_.each(page._subControls(), (subControl) => {
			if(subControl instanceof PlannerLevel){
				subControl.SelectCopyFromCell(page._copyFromColumnIndex);
			}
		});
	}

	UnSelectCopyFromColumn(){
		_.each(this._subControls(), (subControl) => {
			if(subControl instanceof PlannerLevel){
				subControl.UnSelectCopyFromCell();
			}
		});
	}

	GetButton1TableName(): string {
		let button1TableName = null;
		_.each(this._subControls(), (control) => {
			if(control instanceof PlannerLevel && control.Button1TableName){
				button1TableName = control.Button1TableName;
			}
		});
		return button1TableName;
	}

	GetButton2TableName(): string {
		let button1TableName = null;
		_.each(this._subControls(), (control) => {
			if(control instanceof PlannerLevel && control.Button2TableName){
				button1TableName = control.Button2TableName;
			}
		});
		return button1TableName;
	}

	CopyColumn(){
		this.ShowSelectCellSubjectConfirmation();
	}

	ShowSelectCellSubjectConfirmation(){
		let button1TableName = this.GetButton1TableName();
		let button2TableName = this.GetButton2TableName();

		if(button1TableName || button2TableName){
			let copyQueryButtonConfirmationDialog = new CopyQueryButtonConfirmationDialog({
				Text: CONFIRMATIONS.SELECT_CELL_SUBJECT,
				Type: ConfirmationTypes.Question,
				Entity1: button1TableName,
				Entity2: button2TableName,
				Width: 500,
				ModalClass: 'copyQueryButtonConfirmationDialog',
				TextDecline: LABELS.CANCEL
			});
			copyQueryButtonConfirmationDialog.Show();

			copyQueryButtonConfirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.DISCARD_SELECTED, this, ()=>{
				this.CancelCopyToSelection();
			});

			copyQueryButtonConfirmationDialog.On(QUERY_BUTTON_CONFIRMATION_DIALOG_EVENTS.ENTITY1_SELECTED, this, ()=>{
				this.ShowOverwriteDataConfirmation(true, false);
			});

			copyQueryButtonConfirmationDialog.On(QUERY_BUTTON_CONFIRMATION_DIALOG_EVENTS.ENTITY2_SELECTED, this, ()=>{
				this.ShowOverwriteDataConfirmation(false, true);
			});
				
			copyQueryButtonConfirmationDialog.On(QUERY_BUTTON_CONFIRMATION_DIALOG_EVENTS.ENTITY1_AND_ENTITY2_SELECTED, this, ()=>{
				this.ShowOverwriteDataConfirmation(true, true);
			});
		}else{
			this.ShowOverwriteDataConfirmation(false, false);
		}
	}

	ShowOverwriteDataConfirmation(copyQueryButton1: boolean, copyQueryButton2: boolean){
		if(this.IsCopyToCellHasData){
			const confirmationDialog = new ConfirmationDialog({
				Text: NOTIFICATIONS.COPY_PLANNER_DATA,
				Type: ConfirmationTypes.Question,
				TextConfirm: LABELS.OVERWRITE,
				TextSkip: LABELS.SKIP_EXISTING,
				TextDecline: LABELS.CANCEL
			});

			confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, ()=>{
				this.CopyData(true, copyQueryButton1, copyQueryButton2);
			});

			confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.SKIP_SELECTED, this, ()=>{
				this.CopyData(false, copyQueryButton1, copyQueryButton2);
			});
			
			confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.DISCARD_SELECTED, this, ()=>{
				this.CancelCopyToSelection();
			});

			confirmationDialog.Show();			

		}else{
			this.CopyData(true, copyQueryButton1, copyQueryButton2);
		}
	}

	CancelCopyToSelection(){
		this._copyColumnMode(false);
		this._copyCellMode(false);
		this.UnSelectCopyFromColumn();
		_.each(this._subControls(), (subControl) => {
			if(subControl instanceof PlannerLevel){
				subControl.UnSelectCopyToCells();
			}
		});
	}

	CopyData(overwrite: boolean, copyQueryButton1: boolean, copyQueryButton2: boolean){
		if(this._copyColumnMode()){
			_.each(this._subControls(), (subControl) => {
				if(subControl instanceof PlannerLevel){
					subControl.CopyColumn(this._copyFromColumnIndex, overwrite, copyQueryButton1, copyQueryButton2);
				}
			});

			this._copyColumnMode(false);
			this._copyFromColumnIndex = null;
			this.UnSelectCopyFromColumn();
		}

		if(this._copyCellMode()){
			_.each(this._subControls(), (subControl) => {
				if(subControl instanceof PlannerLevel){
					subControl.CopyCell(overwrite, copyQueryButton1, copyQueryButton2);
				}
			});

			this._copyCellMode(false);
			this.CancelCopyToSelection();
		}
	}

	get IsCopyToCellHasData(){
		let hasData = false;

		_.each(this._subControls(), (subControl) => {
			if(subControl instanceof PlannerLevel && subControl.HasData){
				hasData = true;
			}
		});

		return hasData;
	}

	CancelCopy(){
		this.CancelCopyToSelection();
	}
	
	RightBarTableClick(page: PlannerPage, event: any){	
		if(!page._copyColumnMode()) return;	
		if($(event.target).data('index') != undefined){
			this.SelectColumn($(event.target).data('index'));
		};
	}	

	CalculateIndex(column: IMonth | IDay | IWeek){
		return this.GetColumnIndex(column);
	}

	SetCopyCellMode(){
		this.CancelCopyToSelection();		
		this._copyCellMode(true);
	}

	CopyCell(){	
		this.ShowSelectCellSubjectConfirmation();
	}
}