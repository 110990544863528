import * as ko from 'knockout';
import * as _ from 'underscore';

import {P} from 'Core/Common/Promise';
import {Notifier} from "Core/Common/Notifier";
import {BlockUI} from "Core/Common/BlockUi";

import {NOTIFICATIONS} from 'Core/Components/Translation/Locales';
import {SelectDialog, EVENTS as SELECT_DIALOG_EVENTS} from "Core/Components/Dialogs/SelectDialog/SelectDialog";

import {IControlParam} from "Core/Screens/IScreen";
import {IControlValue} from "Core/Controls/BaseControl/BaseControl";

import {ComplexControl} from "Core/Controls/ComplexControl/ComplexControl";
import {RequiredFieldModel} from "Core/Controls/ComplexControl/Models/RequiredFieldModel";
import {FIELD_TYPES, TABLE_TYPES, DEFAULT_ICONS, RenderModes} from "Core/Constant";

import {BulkEmailStore} from "./Store/BulkEmailStore";
import {BulkEmailConfigDto} from "./Store/Models/BulkEmailConfigDto";
import {BulkEmailTemplateDto} from "./Store/Models/BulkEmailTemplateDto";
import {ConfigureBulkEmailDto} from "./Store/Models/ConfigureBulkEmailDto";
import {CreateNewGroupDto} from "./Store/Models/CreateNewGroupDto";
import {NewGroupDto} from "./Store/Models/NewGroupDto";

import {BulkEmailConfig} from "./Models/BulkEmailConfig";
import {BulkEmailEntity, BulkEmailField} from "Core/Controls/BulkEmail/Models/BulkEmailEntity";
import {BulkEmailTemplate} from "Core/Controls/BulkEmail/Models/BulkEmailTemplate";
import {Icon} from "Core/Icon/Icon";

import ToolBarTemplate from 'Core/Controls/BulkEmail/Templates/ToolBar.html';
import HelpViewTemplate from 'Core/Controls/BulkEmail/Templates/HelpView.html';
import ViewTemplate from 'Core/Controls/BulkEmail/Templates/View.html';
import {LockManager} from "../../Components/Locker/LockManager";

ko.templates['Core/Controls/BulkEmail/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/BulkEmail/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/BulkEmail/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/BulkEmail/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/BulkEmail/Templates/Design'] = ComplexControl.designTemplate;

export class BulkEmail extends ComplexControl {
    private _subjectEntityId: number;
    private _subjectRecordId: number;
    private _headerLabel: KnockoutObservable<string>;
    private _bulkEntities: KnockoutObservableArray<BulkEmailEntity>;
    private _selectedBulkEmailEntity: KnockoutObservable<BulkEmailEntity>;

    private _bulkFields: KnockoutObservableArray<BulkEmailField>;
    private _selectedBulkEmailField: KnockoutObservable<BulkEmailField>;

    private _templates: KnockoutObservableArray<BulkEmailTemplate>;
    private _selectedTemplate: KnockoutObservable<BulkEmailTemplate>;
    private _selectedRequestTemplate: KnockoutObservable<BulkEmailTemplate>;

    private _registered: KnockoutObservable<boolean>;
    private _running: KnockoutObservable<boolean>;

    constructor(params: IControlParam) {
        super(params);
        this._headerLabel = ko.observable(this._labels.BULK_EMAIL);
        this._bulkEntities = ko.observableArray([]);
        this._selectedBulkEmailEntity = ko.observable(null);

        this._bulkFields = ko.observableArray([]);
        this._selectedBulkEmailField = ko.observable(null);

        this._templates = ko.observableArray([]);
        this._selectedTemplate = ko.observable(null);
        this._selectedRequestTemplate = ko.observable(null);

        this._registered = ko.observable(false);
        this._running = ko.observable(false);

        this.Init();

        if (this._renderMode() === RenderModes.Design) {
            this.BindDesignEvents();
        }
    }

    ApplyProperties(){}

    SetValue(value: IControlValue): void {
        this._subjectEntityId = value.SubjectEntityId;
        this._subjectRecordId = value.SubjectRecordId;

        if (this._subjectRecordId) {
            this.GetConfig()
                .then(config => {
                    if (config.Registered) {
                        this.PrepareExistingConfiguration(config);
                    } else {
                        this.PrepareNewConfiguration(config);
                    }

                    this.BindRuntimeEvents();
                })
                .fail(error => new Notifier().Failed(error.message));
        }
    }

    private Init() {
        this.SetDefaultIcon(new Icon(DEFAULT_ICONS.BulkMail));

        this._requiredFields([
            new RequiredFieldModel('F_TYPE', FIELD_TYPES.Lookup, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('NAME', FIELD_TYPES.Text, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('TEMPLATE', FIELD_TYPES.Document, TABLE_TYPES.Entity, null)
        ]);

        this.InitRequiredFields();
        this.RefreshHeaderLabel();
    }

    private BindDesignEvents() {
        this._model.subscribe(() => {
            this.RefreshHeaderLabel();
        });
    }

    private BindRuntimeEvents() {
        this._selectedBulkEmailEntity.subscribe(entity => {
            if (entity) {
                this._bulkFields(entity.Fields);
                this._selectedBulkEmailField(entity.Fields[0]);

                this.GetTemplates(entity.Id).then(templates => {
                    this._templates(templates);
                    this._selectedTemplate(templates[0]);
                    this._selectedRequestTemplate(templates[0]);
                });
            }
        });
    }

    private RefreshHeaderLabel() {
        this._headerLabel(this.Name);
    }

    private GetConfig() {
        const deferred = P.defer<BulkEmailConfig>();

        const templateTableId = this.GetFieldModel().EntityId;
        BulkEmailStore.GetConfig(this._subjectEntityId, this._subjectRecordId, templateTableId)
            .then(result => {
                if (result.IsSuccessfull) {
                    deferred.resolve(this.MapToConfig(result.ResultObject));
                } else {
                    deferred.reject({message: result.ErrorMessage});
                }
            });

        return deferred.promise();
    }

    private GetTemplates(entityId: number) {
        const templateTableId = this.GetFieldModel().EntityId;

        if (!templateTableId) {
            new Notifier().Warning(NOTIFICATIONS.TABLE_IS_NOT_SELECTED);
            return;
        }

        const deferred = P.defer<BulkEmailTemplate[]>();
        BulkEmailStore.GetTemplates(entityId, templateTableId)
            .then(result => {
                if (result.IsSuccessfull) {
                    const templates = this.MapToTemplates(result.ResultObject);
                    this._enabled(templates.length > 0);
                    deferred.resolve(templates);
                } else {
                    deferred.reject({message: result.ErrorMessage});
                }
            });

        return deferred.promise();
    }

    private MapToConfig(configDto: BulkEmailConfigDto) {
        const config = new BulkEmailConfig();

        config.MailFieldId = configDto.MailFieldId;
        config.TemplateId = configDto.TemplateId;
        config.RequestTemplateId = configDto.RequestTemplateId;
        config.ProcessStatusId = configDto.ProcessStatusId;
        config.TemplateEntityId = configDto.TemplateEntityId;
        config.Running = configDto.Running;
        config.Registered = configDto.Registered;

        config.Subjects = configDto.Subjects.map(entityDto => {
            const entity = new BulkEmailEntity(entityDto.Id, entityDto.TranslatedName || entityDto.Name);
            entity.Fields = entityDto.Fields && entityDto.Fields.map(fieldDto => new BulkEmailField(fieldDto.Id, fieldDto.TranslatedName || fieldDto.Name));
            return entity;
        });

        config.Templates = this.MapToTemplates(configDto.Templates);

        return config;
    }

    private MapToTemplates(templatesDto: BulkEmailTemplateDto[]) {
        return templatesDto.map(template => new BulkEmailTemplate(template.Id, template.TranslatedName || template.Name));
    }

    private PrepareNewConfiguration(config: BulkEmailConfig) {
        const currentEntity = config.Subjects[0];
        const currentField = currentEntity && currentEntity.Fields[0];
        const currentTemplate = config.Templates[0];
        const currentRequestTemplate = config.Templates[0];

        this._bulkEntities(config.Subjects);
        this._selectedBulkEmailEntity(currentEntity);

        this._bulkFields(currentEntity && currentEntity.Fields);
        this._selectedBulkEmailField(currentField);

        this._templates(config.Templates);
        this._selectedTemplate(currentTemplate);
        this._selectedRequestTemplate(currentRequestTemplate);

        this._registered(false);
        this._running(false);

        const enabled = !!(currentEntity && currentField && currentTemplate && currentRequestTemplate);
        this._enabled(enabled);
    }


    private PrepareExistingConfiguration(config: BulkEmailConfig) {
        const currentEntity = _.find(config.Subjects, subject => _.any(subject.Fields, field => field.Id === config.MailFieldId));
        const currentField = _.find(currentEntity.Fields, field => field.Id === config.MailFieldId);
        const currentTemplate = _.find(config.Templates, template => template.Id === config.TemplateId);
        const currentRequestTemplate = _.find(config.Templates, template => template.Id === config.RequestTemplateId);

        this._bulkEntities(config.Subjects);
        this._selectedBulkEmailEntity(currentEntity);

        this._bulkFields(currentEntity.Fields);
        this._selectedBulkEmailField(currentField);

        this._templates(config.Templates);
        this._selectedTemplate(currentTemplate);
        this._selectedRequestTemplate(currentRequestTemplate);

        this._registered(true);
        this._running(config.Running);

        const enabled = !!(currentEntity && currentField && currentTemplate && currentRequestTemplate);
        this._enabled(enabled);
    }

    private OnCopyClick() {
        if (this._help.IsHelpButtonPressed()) {
            return;
        }

        const templates = this._templates().map(template => {
            return {
                Text: template.Name,
                Value: template.Id
            }
        });

        const dialog = new SelectDialog({
            Label: 'Select new template',
            Options: templates
        });

        dialog.On(SELECT_DIALOG_EVENTS.SAVE, this, eventArgs => this.CreateNewGroup(eventArgs.data.Value));

        dialog.Show();
    }

    private OnConfigureClick() {
        if (this._help.IsHelpButtonPressed()) {
            return;
        }

        BlockUI.Block({Target: this._el});

        const mailFieldId = this._selectedBulkEmailField().Id;
        const templateId = this._selectedTemplate().Id;
        const requestTemplateId = this._selectedRequestTemplate().Id;
        const templateEntityId = this._selectedBulkEmailEntity().Id;

        const sendModel = new ConfigureBulkEmailDto(
            this._subjectEntityId,
            this._subjectRecordId,
            mailFieldId,
            templateId,
            requestTemplateId,
            templateEntityId
        );

        BulkEmailStore.ConfigureBulkEmails(sendModel)
            .then(result => {
                if (result.IsSuccessfull) {
                    new Notifier().Success(NOTIFICATIONS.MAILING_CONFIGURED);
                    this._registered(true);
                } else {
                    new Notifier().Failed(result.ErrorMessage);
                }
            })
            .always(() => BlockUI.Unblock(this._el));
    }

    private OnRunningToggle() {
        if (this._help.IsHelpButtonPressed()) {
            return;
        }

        BlockUI.Block({Target: this._el});

        const promise = this._running()
            ? BulkEmailStore.Stop(this._subjectEntityId, this._subjectRecordId)
            : BulkEmailStore.Start(this._subjectEntityId, this._subjectRecordId);

        promise.then(result => {
            if (result.IsSuccessfull) {
                this._running(!this._running());
            } else {
                new Notifier().Failed(result.ErrorMessage);
            }
        }).always(() => BlockUI.Unblock(this._el));
    }

    private CreateNewGroup(templateId: number) {
        BlockUI.Block();

        const createGroupPromise = BulkEmailStore.CreateNewGroup(new CreateNewGroupDto(
            this._subjectEntityId,
            this._subjectRecordId,
            templateId)
        );

        createGroupPromise
            .then(result => {
                    const showScreenPromise = P.defer<NewGroupDto>();

                    if (!result.IsSuccessfull) {
                        showScreenPromise.reject({message: result.ErrorMessage});
                    } else {
                        const group: NewGroupDto = result.ResultObject;
                        LockManager.Instance.TryLock(group.EntityId, group.RecordId)
                            .then(() => showScreenPromise.resolve(group))
                            .fail(error => showScreenPromise.reject(error));
                    }

                    return showScreenPromise.promise();
                }
            )
            .then((group: NewGroupDto) => this.ShowEditScreen(group)
            .then(screen => {
                if (!screen) {
                    new Notifier($(this._el)).Warning(NOTIFICATIONS.EDIT_SCREEN_NOT_EXISTS);
                    return;
                }
                screen.ShowInModal();
            }))            
            .fail(error => new Notifier().Failed(error.message))
            .always(() => BlockUI.Unblock());
    }

    private ShowBulkEmailHelp(data, event): void {
        if (this._help.IsHelpButtonPressed()) {
            $(event.target).blur();
            this._help.ShowControlHelp(this);
        }
    }

    private async ShowEditScreen(group: NewGroupDto) {
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
        return screenManager.GetEditScreen({
            EntityId: group.EntityId,
            RecordId: group.RecordId
        });
    }
}