import {PropertyValue} from "./PropertyValue";
import {CustomFieldValue} from "./CustomFieldValue";

export class SelectedProduct {
    constructor(public Id: number,
                public GroupId: number,
                public GroupName: string,
                public RootGroupId: number,
                public RootGroupName: string,
                public Path: number[],
                public KSeq: number,
                public KSeqGuid: string,
                public Price?: number,
                public Quantity?: number,
                public PropertyValues?: PropertyValue[],
				public CustomFieldValues?: CustomFieldValue[]) {
    }
}