import {RemoteDatabaseDto} from '../Stores/Models/RemoteDatabaseDto';
import {RemoteDatabaseAccessDto} from '../Stores/Models/RemoteDatabaseAccessDto';

import {RemoteDatabase} from '../Components/RemoteDatabase';
import {RemoteDatabaseAccess} from '../Components/RemoteDatabaseAccess';

type AfterMapRemoteDatabase = (database: RemoteDatabase) => void;

export class NetworkDesignerMappings {
    MapToRemoteDatabases(databasesDto: RemoteDatabaseDto[], afterMap: AfterMapRemoteDatabase) {
        return databasesDto.map(dbDto => {
            const remoteDatabase = this.MapToRemoteDatabase(dbDto);

            afterMap(remoteDatabase);

            return remoteDatabase;
        });
    }

    MapToRemoteDatabase(databaseDto: RemoteDatabaseDto) {
        return new RemoteDatabase(databaseDto.Id, databaseDto.Name, databaseDto.NetworkKey);
    }

    MapToRemoteDatabaseAccess(accessDto: RemoteDatabaseAccessDto[]) {
        return accessDto.map(dto => new RemoteDatabaseAccess(dto.Id, dto.UserId, dto.UserName, dto.RemoteUserName));
    }
}
