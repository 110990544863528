export class BinaryUtils{
    public static CoerceToArrayBuffer(thing, name = null) {
        if (typeof thing === "string") {
            thing = thing.replace(/-/g, "+").replace(/_/g, "/");
    
            var str = window.atob(thing);
            var bytes = new Uint8Array(str.length);
            for (var i = 0; i < str.length; i++) {
                bytes[i] = str.charCodeAt(i);
            }
            thing = bytes;
        }
    
        if (Array.isArray(thing)) {
            thing = new Uint8Array(thing);
        }
    
        if (thing instanceof Uint8Array) {
            thing = thing.buffer;
        }
    
        if (!(thing instanceof ArrayBuffer)) {
            throw new TypeError("could not coerce '" + name + "' to ArrayBuffer");
        }
    
        return thing;
    }

    public static CoerceToBase64Url (thing) {
        if (Array.isArray(thing)) {
            thing = Uint8Array.from(thing);
        }
    
        if (thing instanceof ArrayBuffer) {
            thing = new Uint8Array(thing);
        }
    
        if (thing instanceof Uint8Array) {
            var str = "";
            var len = thing.byteLength;
    
            for (var i = 0; i < len; i++) {
                str += String.fromCharCode(thing[i]);
            }
            thing = window.btoa(str);
        }
    
        if (typeof thing !== "string") {
            throw new Error("could not coerce to string");
        }
    
        thing = thing.replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/g, "");
    
        return thing;
    };
}