import * as ko from "knockout";

import {IButtonConfig} from "Core/Controls/PortletBar/Interfaces/IButtonConfig"
import {ButtonViewModel} from "Core/Controls/PortletBar/Models/Buttons/ButtonViewModel"

import SettingsButtonTemplate from "Core/Controls/PortletBar/Templates/Buttons/Settings/Button.html";
import SettingsButtonDesignTemplate from "Core/Controls/PortletBar/Templates/Buttons/Settings/Design.html";
import SettingsPropsTemplate from "Core/Controls/PortletBar/Templates/Buttons/Settings/Props.html";

ko.templates["Core/Controls/PortletBar/Templates/Buttons/Settings/Button"] = SettingsButtonTemplate;
ko.templates["Core/Controls/PortletBar/Templates/Buttons/Settings/Design"] = SettingsButtonDesignTemplate;
ko.templates["Core/Controls/PortletBar/Templates/Buttons/Settings/Props"] = SettingsPropsTemplate;

export class SettingsButtonViewModel extends ButtonViewModel {
	DisplayValue: KnockoutObservable<boolean>;
    PropsOpened: KnockoutObservable<boolean>;

    constructor(buttonName: string, buttonConfig: IButtonConfig) {
		super(buttonName, buttonConfig);
		this.DisplayValue = ko.observable(this.Properties().Visible());
        this.PropsOpened = ko.observable(false);
	}
}