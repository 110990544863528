import {Event} from "Core/Common/Event";

export abstract class TimeWritingView extends Event {

	constructor() {
		super();
	}

	abstract GetTemplate();

	abstract Show(params: any): void;

	AfterRender(): void {

	}

	CanBeUpdated() {
		return true;
	}
}