import * as ko from 'knockout';
import {BaseControl} from 'Core/Controls/BaseControl/BaseControl';
import {IControlParam} from 'Core/Screens/IScreen';
import {RenderModes} from 'Core/Constant';

import {GeneralProperties} from 'Core/GeneralProperties/GeneralProperties';
import GroupConfig from 'Core/Controls/Group/Configs/group-config.json';
import {PROPERTIES} from "Core/Controls/Group/Constants";
import {ResizeObserver} from 'Core/Common/ResizeObserver';

import 'Core/Controls/Group/Templates/Edit.html';

import HelpViewTemplate from 'Core/Controls/Group/Templates/HelpView.html';
import ViewTemplate from 'Core/Controls/Group/Templates/View.html';
import ToolBarTemplate from 'Core/Controls/Group/Templates/ToolBar.html';
import DesignTemplate from 'Core/Controls/Group/Templates/Design.html';
import EditTemplate from 'Core/Controls/Group/Templates/Edit.html';

ko.templates['Core/Controls/Group/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Group/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/Group/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Group/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/Group/Templates/Edit'] = EditTemplate;

const ResizeService = new ResizeObserver();

export class Group extends BaseControl {
	private _controlContainerWidth: KnockoutObservable<number>
	private _controlLabelWidth: KnockoutObservable<number>
	private _elem: KnockoutObservableArray<HTMLElement>;

	constructor(params: IControlParam) {
		super(params, GroupConfig);
		this._controlContainerWidth = ko.observable(null);
		this._controlLabelWidth = ko.observable(null);
		this._elem = ko.observableArray(null);
		this.Init();
		this.BindEvents();
	}

	private BindEvents() {
		if (this._renderMode() === RenderModes.Design) {
			this._model.subscribe(() => this.Init());
		}
	}

	private Init(): void {
		this.ApplyProperties();
	}

	ApplyProperties() {
		if (this.Properties) {
			if (this.Properties.Appearance && this.Properties.Appearance.Properties) {
				this._webSizeValue = this.GeneralProperties.GetPropertyValue(PROPERTIES.GROUP_WEB_SIZE);
				this._isWrapped(this.GeneralProperties.GetPropertyValue(PROPERTIES.GROUP_WRAPPED));

				this._form && this._form.Wrap(this, this._isWrapped());
				if (this._parentControl) {
					this._parentControl.SetWebSizeValue(this._webSizeValue);
					this._parentControl.SetIsWrapped(this._isWrapped());
				}
			}
		}
	}

	GetWidth(): string {
		if (this._webSizeValue){
			if (this._isWrapped()){

				if (this._webSizeValue.Value == 100 && this._webSizeValue.Unit.Value == '%'){
					return `${this._webSizeValue.Value}${this._webSizeValue.Unit.Value}`;
				} else {
					return `calc(${this._webSizeValue.Value}${this._webSizeValue.Unit.Value} - 5px)`;
				}

			} else {
				return `${this._webSizeValue.Value}${this._webSizeValue.Unit.Value}`;
			}
		} else {
			return "100%";
		}
	}

	GetWrapped():boolean{
		if (this._isWrapped() && this._webSizeValue){
			return !(this._webSizeValue.Value == 100 && this._webSizeValue.Unit.Value == '%');
		} else {
			return this._isWrapped();
		}
	}

	get IsWrapped():boolean{
		return this.GetWrapped();
	}

	get Width(): string {
		return this.GetWidth();
	}

	SetValue(value: any): void {
	}

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);

		if (this._elem().length === 0){
			this._elem(el);
		}
	}
}