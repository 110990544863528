import {EVENTS} from "Core/Constant";

import {IButtonConfig} from "Core/Controls/PortletBar/Interfaces/IButtonConfig";
import {PortletBarButton} from "Core/Controls/PortletBar/Managers/Buttons/PortletBarButton";
import {CollapseButtonViewModel} from "Core/Controls/PortletBar/Models/Buttons/CollapseButtonViewModel";
import {LABELS} from "Core/Components/Translation/Locales";
import {ITooltipConfig} from "Core/KnockoutExtentions/TooltipExtention";
import * as ko from "knockout";

export class CollapseButton extends PortletBarButton {
    ViewModel: CollapseButtonViewModel;

    constructor(buttonName: string, buttonConfig: IButtonConfig) {
        super(buttonConfig);

        this.ViewModel = new CollapseButtonViewModel(buttonName, buttonConfig);
        this.Tooltip = ko.observable<ITooltipConfig>({content: LABELS.COLLAPSE, outside: 'y', position: {x: 'center', y: LABELS.COLLAPSE.length <= 8 ? 'top' : 'bottom'}});
    }

    OnClickEvent() {
        return EVENTS.PORTLETS.PORTLET_BAR.CONTROL_BUTTONS.COLLAPSE_BUTTON_CLICKED;
    }

    OnClick() {
        this.ViewModel.IsCollapsed = !this.ViewModel.IsCollapsed;
        this.UpdateTooltip();
        this.Trigger(this.OnClickEvent());
    }

    protected GetButtonTemplateFolder() {
        return `${super.GetButtonTemplateFolder()}/Collapse`;
    }

    private UpdateTooltip() {
        if (this.ViewModel.IsCollapsed) {
            this.Tooltip({content: LABELS.EXPAND});
            return;
        }

        this.Tooltip({content: LABELS.COLLAPSE});
    }
}