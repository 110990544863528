import * as ko from "knockout"
import * as _ from 'underscore'
import * as Viewer from "viewer";
import * as Markerjs2 from "markerjs2";

import {P} from 'Core/Common/Promise';

import {ZIndexManager} from 'Core/Common/ZIndexManager';
import {ViewerJsExtention} from 'Core/KnockoutExtentions/ViewerJsExtention';

import {BaseControl, IControlValue} from "Core/Controls/BaseControl/BaseControl"
import {IControlParam} from "Core/Screens/IScreen"
import {IGetImageControlDataRequestModel, ImageDataStore} from 'Core/Controls/Image/Stores/ImageDataStore'
import {FileModel, ImageDataModel} from 'Core/Controls/Image/Models/ImageDataModel'
import {ImageCropperScreen} from 'Core/Controls/Image/ImageCropperScreen/ImageCropperScreen'
import {CONTROL_TYPES, RenderModes, TABLE_TYPES} from 'Core/Constant'
import {GeneralProperties} from "Core/GeneralProperties/GeneralProperties";
import {MobileChecker} from "Core/Common/MobileChecker";

import ImageConfig from "Core/Controls/Image/Configs/image-config.json";

import {BlockUI as BlockUi} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';
import {ScreenTypes} from "../../Common/Enums/ScreenTypes";

import ViewTemplate from 'Core/Controls/Image/Templates/View.html'
import HelpViewTemplate from 'Core/Controls/Image/Templates/HelpView.html'
import ToolBarTemplate from 'Core/Controls/Image/Templates/ToolBar.html'
import DesignTemplate from 'Core/Controls/Image/Templates/Design.html'
import EditTemplate from 'Core/Controls/Image/Templates/Edit.html'

import {AttachmentModel} from 'Core/Controls/Image/Models/AttachmentModel';
import {ImageFromCamera} from 'Core/Controls/Image/ImageFromCamera/ImageFromCamera';
import TruncateFileName from 'Core/Common/TruncateFileName';
import {DropDataStore} from "../Drop/Stores/DropDataStore";
import {UploadChunkModel} from "../Drop/Models/UploadChunkModel";
import {Guid} from "../../Common/Guid";
import {MarkerArea} from "markerjs2";

ko.templates['Core/Controls/Image/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/Image/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Image/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Image/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/Image/Templates/Edit'] = EditTemplate;

export const CLIPBOARD_IMAGES_TOPIC = 'clipboardImages';

export class Image extends BaseControl {
    private _dataModel: KnockoutObservable<ImageDataModel>;
    private _image: KnockoutObservable<FileModel>;
    private _imageCropperScreen: ImageCropperScreen;
    private _attachments: Array<AttachmentModel>;
    private _removeAttachments: Array<FileModel>;
    private _dropzoneInstance: any;
    private _cropedImageClass: string;
    private _isImageGallery: boolean;
    private _maxFileSize: number;
    private _maxUploadingFilesSize: number;
    private _maxCombinedSize: number;
	private _imageHeight: KnockoutObservable<string>;
    private _hidden: KnockoutObservable<boolean>;
    private _isMobile: boolean;
    private _originalValue: string;
    private _newAttachments: Array<any>;
    private _isNewRecord: boolean;
    private _clipboardImages: KnockoutObservable<Array<File>>;
    private _editGalleryContainer: HTMLElement;
    private _editGalleryViewer: Viewer;
    private _markerArea: MarkerArea;
    private _markerButton: Element;
    private _annotationsEnabled: KnockoutObservable<boolean>;
    private _annotations: Array<FileModel>;
    private _annotationsShown: KnockoutObservable<boolean>;
    private _showAnnotations: KnockoutObservable<boolean>;
    private _uploadedFilesObjects: Array<any>;
    private _disableCropping: KnockoutObservable<boolean>;

    constructor(params: IControlParam) {
        super(params, ImageConfig);

        const isInTab = params.ParentControl ? params.ParentControl.GetType() === CONTROL_TYPES.TabPage : false;
        this._isWrapped = ko.observable(false);
        this._dataModel = ko.observable(null);
        this._image = ko.observable(null);
        this._imageCropperScreen = new ImageCropperScreen(!isInTab);
        this._isImageGallery = params.Model.EntityTypeName === TABLE_TYPES.Sub;
        this._attachments = [];
        this._removeAttachments = [];
		this._imageHeight = ko.observable('');
        this._hidden = ko.observable(false);
        this._cropedImageClass = isInTab ? 'gallery' : 'portrait';
        this._maxFileSize = 15;
        this._maxUploadingFilesSize = 150;
        this._maxCombinedSize = 750;
        this._clipboardImages = ko.observable();
        this._annotationsEnabled = ko.observable(false);
        this._annotations = [];
        this._markerButton = null;
        this._annotationsShown = ko.observable(false);
        this._showAnnotations = ko.observable(true);
        this._uploadedFilesObjects = [];
        this._disableCropping = ko.observable(null);

        this._isMobile = MobileChecker.IsMobile();

        this.Init();
        this.BindEvents();
        this.AddEvent('DISPOSE');
    }

    private Init(): void {
        this.ApplyProperties();
    }

    get IsImageFile():boolean{
        return this._dataModel() && !!this._dataModel().Files.length;
    }

    private BindEvents() {
        this._model.subscribe(() => this.Init());
    }

    ApplyProperties() {
        if (this.Properties) {
            if (this.Properties.General) {
                _.each(this.Properties.General.Properties, (item: any) => {
                    this._disableCropping(item.DisableCropping);
                });
            }
            if (this.Properties.IsWrapped) {
                _.each(this.Properties.IsWrapped.Properties, (item: any) => {
                    this.ApplyWrapTextProperty(item.WrapText);
                });
            }

            if (this.Properties.Annotations) {
                this.ApplyAnnotationsProperty(this.Properties.Annotations.Properties[0].Annotations);
                this.ApplyShowAnnotationProperty(this.GeneralProperties.GetPropertyValue('ShowAnnotations'));
            }

            if (this.Properties.ImageHeight) {
                this._imageHeight(this.Properties.ImageHeight.Properties[0].ImageHeight.Value);
            }

            if (this.Properties.HideIfEmpty) {
                const hideIfEmpty = this.Properties.HideIfEmpty.Properties[0].HideIfEmpty
                    && !this.IsImageFile
                    && this._renderMode() === RenderModes.View;
                this._hidden(hideIfEmpty);
			}

			if (this._form && (this._form.GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.LinkEditor]) && this.Properties.BackgroundColor) {
				this._backgroundColor(this.Properties.BackgroundColor);
            }
        }
    }

    ApplyWrapTextProperty(wrapText) {
        this._isWrapped(wrapText);
        this._form && this._form.Wrap(this, wrapText);
    }

    ApplyAnnotationsProperty(annotationsEnabled) {
        this._annotationsEnabled(annotationsEnabled);
    }

    ApplyShowAnnotationProperty(showAnnotationsEnabled) {
        this._showAnnotations(showAnnotationsEnabled);
    }

    GetImageClass() {
        this.ApplyProperties();

        return `
            ${this._imageHeight()}
            ${this._isWrapped() ? 'wrapped-image' : 'galleryBox'}
            ${this._hidden() ? 'hidden' : null}
            ${this._clipboardImages() ? 'highlighted' : ''}
        `;
    }

    SetValue(data: IControlValue): void {
        if (data.RecordSpecsModel) {
            this._isNewRecord = data.RecordSpecsModel && data.RecordSpecsModel.IsNewRecord;
        }

        this._isRendered.subscribe((newValue) => {
            this._hidden(false);

            if (data.Data.Value) {
                if (this.IsImageGallery) {
                    this.LoadGalleryData(data)
                        .then(dataModel => this.PrepareForGallery(dataModel));
                } else {
                    this.LoadSingleImageData(data)
                        .then(dataModel => this.PrepareForSingleImage(dataModel));
                }

                return;
            }

            if (this.IsImageGallery) {
                this.PrepareForGallery(null);
            } else {
                this.PrepareForSingleImage(null);
            }
        });
    }

    IsModified(): boolean {
        this.Deserialize();

        if (super.IsModified() || this._isNewRecord) {
            return true;
        }

        if (!this._isNewRecord && this._newAttachments.length == 0) {
            return false;
        }

        return true;
    }

    private PrepareForSingleImage(dataModel: ImageDataModel) {

        if (dataModel) {
            if (!dataModel.Files[0]) {
                this._dataModel(dataModel);
            }

            if (dataModel.Files[0] && dataModel.Files[0].ImageData) {
                this._dataModel(dataModel);
            }
        }

        let fileModel = dataModel && dataModel.Files[0] || null;

        if (fileModel && !fileModel.ImageData) {
            fileModel = null;
        }

        if (!fileModel && this.IsEditMode) {
            fileModel = new FileModel();
        }

        this._image(fileModel);

        if (this.IsEditMode) {
            this.InitDropzone();
            this.InitPasteEvents();
        }

        //Save attachment from example
        if (this._isNewRecord && this._dataModel()) {
            const [file] = this._dataModel().Files;

            if (file) {
                const exampleImage = new AttachmentModel();

                exampleImage.FileName = file.Name;
                exampleImage.Base64Data = file.ImageData;

                this._attachments.push(exampleImage);
            }
        }
    }

    private PrepareForGallery(dataModel: ImageDataModel) {
        if (this.IsEditMode) {
            this.InitEditGalleryViewer();
        }

        this._dataModel(dataModel);

        if (this.IsEditMode) {
            this.InitDropzone();
            this.InitPasteEvents();
        }
    }

    private LoadSingleImageData(data: IControlValue): P.Promise<ImageDataModel> {
        const deferred = P.defer<ImageDataModel>();

        this.BlockUi();

        const requestModel: IGetImageControlDataRequestModel = {
            ControlId: this.GetControlId(),
            FieldId: this.GetFieldId(),
            RecordId: +data.Data.Value,
            FirstRecordOnly: true
        };

        ImageDataStore.Get(requestModel)
            .then(data => deferred.resolve(data))
            .fail(err => deferred.reject(err))
            .always(() => this.UnBlockUi());

        return deferred.promise();
    }

    private LoadGalleryData(data: IControlValue) {
        const deferred = P.defer<ImageDataModel>();

        this.BlockUi();

        const requestModel: IGetImageControlDataRequestModel = {
            ControlId: this.GetControlId(),
            FieldId: this.GetFieldId(),
            RecordId: data.SubjectRecordId,
            FirstRecordOnly: false
        };

        ImageDataStore.Get(requestModel)
            .then(data => deferred.resolve(data))
            .fail(err => deferred.reject(err))
            .always(() => this.UnBlockUi());

        return deferred.promise();
    }

    private InitDropzone() {
        this._dropzoneInstance = ko.utils.domData.get(
            this._el.querySelector('.image-item, .dropzone'),
            'dropZoneInstance'
        );

        if (this.GetCombinedReadOnly()) {
            this._dropzoneInstance.disable();
        }
    }

    public IsImage(fileName: string){
        let imageExtensions = ['bpm', 'jpg', 'jpeg', 'png', 'gif', 'jpe', 'jif', 'jfif', 'jfi'],
            extension = _.last(fileName.split('.'));

        if(extension){
            if(imageExtensions.indexOf(extension.toLowerCase())>=0){
                return true;
            }
        }
        return false;
    }

    public ConvertBase64ToFile (base64Data: string, fileName: string): File {
        let byteString = atob(base64Data),
            ab = new ArrayBuffer(byteString.length),
            ia = new Uint8Array(ab),
            type = 'plain/text';

        for (let i = 0; i < byteString.length; i += 1) {
            ia[i] = byteString.charCodeAt(i);
        }

        if(this.IsImage(fileName)){
            type = `image/${_.last(fileName.split('.'))}`
        }

        return new File([ia.buffer], fileName, { type: type });
    };

    public AddFiles(files: File[]){
        this._dropzoneInstance.emit('addedfiles', files);
        this._dropzoneInstance.handleFiles(files);
    }

    private InitPasteEvents() {
        PubSub.subscribe(CLIPBOARD_IMAGES_TOPIC, (message, data: Array<File> | undefined) => {
            this._clipboardImages(data);
        });

        this._el.addEventListener(
            'click',
            event => {
                const clipboardImages = this._clipboardImages();

                if (clipboardImages) {
                    event.stopPropagation();

                    const images = this._dropzoneInstance.options.maxFiles
                        ? clipboardImages.slice(
                            0,
                            this._dropzoneInstance.options.maxFiles - this._dropzoneInstance.files.length
                        )
                        : clipboardImages;

                    this._dropzoneInstance.emit('addedfiles', images);
                    this._dropzoneInstance.handleFiles(images);

                    PubSub.publish(CLIPBOARD_IMAGES_TOPIC, undefined);
                }
            },
            true
        );

        document.addEventListener('click', event => {
            if (
                this._clipboardImages() &&
                event.target instanceof Node &&
                !this._el.contains(event.target) // click outside
            ) {
                PubSub.publish(CLIPBOARD_IMAGES_TOPIC, undefined);
            }
        });
    }

    private BlockUi() {
        if (this._el) {
            BlockUi.Block({Target: this._el});
        }
    }

    private UnBlockUi() {
        BlockUi.Unblock(this._el);
    }

    get Image(): KnockoutObservable<FileModel> {
        return this._image;
    }

    get IsImageGallery(): boolean {
        return this._isImageGallery;
    }

    get MaxFileSize(): number {
        return this._maxFileSize;
    }

    get MaxCombinedSize(): number {
        return this._maxCombinedSize;
    }

    get MaxUploadingFilesSize(): number {
        return this._maxUploadingFilesSize;
    }

    UploadSingleImageCrop(file: File, attachmentModel: AttachmentModel, data): void {
        BlockUi.Block();

        const imageCropperScreen = new ImageCropperScreen(true);

        imageCropperScreen.Type = file.type;
        imageCropperScreen.Image = window.URL.createObjectURL(file);
        imageCropperScreen.Show();

        BlockUi.Unblock();

        imageCropperScreen.On("IMAGE_CROPPED",
            self,
            (eventArgs: any) => {
                if (eventArgs.data.CropedImage) {
                    if (!this._dataModel()) {
                        this._dataModel(new ImageDataModel());
                    }

                    const fileModel = new FileModel();

                    fileModel.ImageData = eventArgs.data.CropedImage;
                    this._image(fileModel);

                    this._dataModel().Files.push(fileModel);
                    this._dataModel.valueHasMutated();

                    attachmentModel.Base64Data = eventArgs.data.CropedImage.split(',')[1];
                    fileModel.Attachment = attachmentModel;
                    this._attachments.push(attachmentModel);
                }
            });
    }

    UploadSingleImageNotCrop(file: File, attachmentModel: AttachmentModel, imageData?: string): void {

        if (imageData) {
            const fileModel = new FileModel();
            fileModel.ImageData = imageData;

            this._image(fileModel);
            if (!this._dataModel()) {
                this._dataModel(new ImageDataModel())
            }
            this._dataModel().Files.push(fileModel);
            this._dataModel.valueHasMutated();

            attachmentModel.Base64Data = imageData.split(',')[1];
            fileModel.Attachment = attachmentModel;
            this._attachments.push(attachmentModel);

            return;
        }

        if (file) {
            let result: string | ArrayBuffer = '';
            const reader: FileReader = new FileReader();
            const self = this;
            reader.onload = function(event) {
                result = event.target.result;
                if (!self._dataModel()) {
                    self._dataModel(new ImageDataModel());
                }

                const fileModel = new FileModel();
                let base64 = result as string;

                fileModel.ImageData = base64;
                self._image(fileModel);

                self._dataModel().Files.push(fileModel);
                self._dataModel.valueHasMutated();

                attachmentModel.Base64Data = base64.split(',')[1];
                fileModel.Attachment = attachmentModel;
                self._attachments.push(attachmentModel);
            };
            reader.readAsDataURL(file);
        }
    }

    AfterUpload(file, data) {
        const self = this;
        const attachmentModel = new AttachmentModel();

        attachmentModel.FileName = file.name;

        if (!self.IsImageGallery) {
            this.AfterUploadSingleImage(file, attachmentModel, data);
        } else if (self.IsImageGallery) {
            this.AfterUploadInGallery(file, attachmentModel, data);
        }
    }

    private AddImageInGalleryMemory(file) {
        const image = $("<img/>");
        image.prop('src', file.ImageData);
        image.attr('data-id', file.Id);

        $(this._editGalleryContainer).append(image);
        this._editGalleryViewer.update();
    }

    private RemoveImageFromGalleryMemory(file) {
        const image = $(this._editGalleryContainer).find(`img[data-id="${file.Id}"][src="${file.ImageData}"]`);
        if(!image.length){
            return;
        }

        image.remove();
        this._editGalleryViewer.update();
    }

    private InitEditGalleryViewer() {
        const self = this;
        const $container = $("<div/>");
        this._editGalleryContainer = $container[0];

        const opt = {
            markerable: this._annotationsEnabled(),
            button: true,
            inline: false,
            navbar: false,
            title: false,
            toolbar: true,
            tooltip: true,
            movable: true,
            zoomable: true,
            rotatable: true,
            scalable: true,
            transition: true,
            fullscreen: true,
            keyboard: true,
            url: 'data-original',
            zIndex: ZIndexManager.Instance.NextValue,
            filter() {
                return true;
            },
            hide() {
                if (self._markerArea) {
                    self._markerArea.close();
                }

                if (self._showAnnotations()) {
                    self._annotationsShown(false);
                } else {
                    self._annotationsShown(true);
                }
            },
            marker() {
                if (self._showAnnotations()) {
                    self._markerButton = (self._editGalleryViewer.image.parentNode.parentNode as HTMLElement).getElementsByClassName("viewer-marker")[0];
                    self.ShowMarkerArea(self._editGalleryViewer.image);
                } else {
                    if (self._annotationsShown()) {
                        self._markerButton = (self._editGalleryViewer.image.parentNode.parentNode as HTMLElement).getElementsByClassName("viewer-marker")[0];
                        self.ShowMarkerArea(self._editGalleryViewer.image);

                        return;
                    }

                    self._annotationsShown(true);
                }

            },
            viewed() {
                if (!self._annotationsShown() && opt.markerable) {
                    opt.marker();
                    if (self._markerButton) {
                        self._markerButton.classList.add("disabled");
                        self._markerButton.setAttribute('style', 'pointer-events: none;');
                    }
                }
            }
        };

        this._editGalleryViewer = new Viewer(this._editGalleryContainer, opt);

        this._editGalleryViewer.next = () => {
            if (this._editGalleryViewer.index + 1 < this._dataModel().Files.length) {

                let image = this._dataModel().Files[this._editGalleryViewer.index + 1];
                if (image.OriginalImageData == null) {
                    this.GetOriginalImage(image, this._editGalleryViewer);
                } else {
                    this._editGalleryViewer.view(Math.min(this._editGalleryViewer.index + 1, this._editGalleryViewer.images.length - 1));
                }
                return this._editGalleryViewer;
            }
        }

        this._editGalleryViewer.prev = () => {
            if (this._editGalleryViewer.index > 0) {

                let image = this._dataModel().Files[this._editGalleryViewer.index - 1];
                if (image.OriginalImageData == null) {
                    this.GetOriginalImage(image, this._editGalleryViewer);
                } else {
                    this._editGalleryViewer.view(Math.max(this._editGalleryViewer.index - 1, 0));
                }
            }
            return this._editGalleryViewer;
        }

        (this._editGalleryViewer as any).clickOnImage = (uniqueId) => {
            const index = this._dataModel().Files.findIndex(file => file.UniqueId === uniqueId);
            const file = this._dataModel().Files[index];

            const img = this._editGalleryViewer.images[index];
            $(img).attr('data-original', file.OriginalImageData);

            this._editGalleryViewer.update();
            this._editGalleryViewer.view(index);

            ViewerJsExtention.CustomTooltip();
        };
    }

    private AfterUploadSingleImage(file, attachmentModel: AttachmentModel, data) {
        if (this._disableCropping()) {
            this.UploadSingleImageNotCrop(file, attachmentModel);
        } else {
            this.UploadSingleImageCrop(file, attachmentModel, data);
        }
    }

    private AfterUploadInGallery(file, attachmentModel: AttachmentModel, data) {
        if (file.preloaded) {
            this.AddImageInGalleryMemory(file);
            file.previewElement.addEventListener('click', () => {
                this.GetOriginalImage(file, this._editGalleryViewer);
            });
            return;
        }

        file.OriginalImageData = file.dataURL;

        BlockUi.Block();

        if (!this._dataModel()) {
            this._dataModel(new ImageDataModel());
        }

        if (file.status === 'error' || file.status === 'removed') {
            BlockUi.Unblock();
            return;
        }

        const fileModel = new FileModel();

        fileModel.ImageData = window.URL.createObjectURL(file);
        fileModel.OriginalImageData = fileModel.ImageData;

        if (file && file.status === "queued") {
            fileModel.CacheFile = file;
        }

        this._dataModel().Files.push(fileModel);
        this._dataModel.valueHasMutated();

        attachmentModel.FileName = TruncateFileName(file.name);
        attachmentModel.TempName = `${Guid.NewGuid()}_${attachmentModel.FileName}`;
        attachmentModel.Index = this._editGalleryViewer.images.length;

        fileModel.Attachment = attachmentModel;
        fileModel.Id = Guid.NewGuid();
       
        const chunks = this.CutByChunks(file);

        this.UploadFileChunks(chunks, attachmentModel.TempName, 1, chunks.length)
            .finally(()=>{
                BlockUi.Unblock();
            })
            .then((result) => {
                if(!result){                                        
                    this._dropzoneInstance.removeFile(file);
                    return;                
                }
                this.AddImageInGalleryMemory(fileModel);
                file.previewElement.addEventListener('click', () => {
                    this.GetOriginalImage(fileModel, this._editGalleryViewer);
                });            
                this._uploadedFilesObjects.push({Id: fileModel.Id, File: file});
                this._attachments.push(attachmentModel);
            });
    }

    private CutByChunks(file) {
        const chunkSizeInBytes = 1 * 1024 * 1024;

        let streamPosition = 0;
        let streamEndPosition = chunkSizeInBytes;

        if (chunkSizeInBytes >= file.size) {
            return [file];
        }

        const chunks = [];

        while (streamEndPosition < file.size) {
            const chunk = file.slice(streamPosition, streamEndPosition);
            chunks.push(chunk);

            streamPosition = streamEndPosition;
            streamEndPosition += chunkSizeInBytes;
        }
        const chunk = file.slice(streamPosition, file.size);
        chunks.push(chunk);

        return chunks;
    }

    private UploadFileChunks(fileChunks: any[], fileName: string, currentPart: number, totalPart: number) {
        return new Promise(resolve => {
            const uploadModel = new UploadChunkModel(fileName, fileChunks[currentPart - 1]);
            DropDataStore.UploadFileChunk(uploadModel)
                .then(result => {
                    if (!result.IsSuccessfull) {
                        resolve(false);
                    } else {
                        if (totalPart >= currentPart) {
                            if (totalPart === currentPart) {
                                //Whole file uploaded
                                resolve(true);
                            } else {
                                //Show uploading progress
                                this.UploadFileChunks(fileChunks, fileName, currentPart + 1, totalPart).then(result => {
                                    resolve(result);
                                });
                            }
                        }
                    }
                }).fail((error)=>{
                    resolve(false);
                });
        });
    }

    Deserialize() {
        const data = {attachments: [], removeAttachments: [], annotations: []};
        const field = _.first(this._model().Fields);

        _.each(this._attachments, item => {
            data['attachments'].push({FieldId: field.Id, Data: item});
        });

        _.each(this._removeAttachments, item => {
            data['removeAttachments'].push({FieldId: field.Id, Data: {Id: item.Id, IsImage: true}});
        });

        _.each(this._annotations, item => {
            data['annotations'].push({FieldId: field.Id, Data: item});
        });

        this._newAttachments = data.attachments;
        data['FieldName'] = `${field.EntityName}.${field.Name}`;

        return data;
    }

    GetOriginalImage(file: FileModel, viewer) {
        if (file.OriginalImageData) {
            viewer.clickOnImage(file.UniqueId);
            return;
        }

        this.BlockUi();

        const requestModel: IGetImageControlDataRequestModel = {
            ControlId: this.GetControlId(),
            FieldId: this.GetFieldId(),
            RecordId: file.Id,
            FirstRecordOnly: !this.IsImageGallery
        };

        ImageDataStore.GetOriginal(requestModel)
            .then(attachment => {
                file.OriginalImageData = attachment.Base64Data;
                this._dataModel.valueHasMutated();
                viewer.clickOnImage(file.UniqueId);
            })
            .fail(err => new Notifier().Failed(err.message))
            .always(() => {
                this.UnBlockUi();
            });
    }

    ImageDelete(file: FileModel) {
        if(!this._dataModel() || !this._dataModel().Files || this._dataModel().Files.length === 0){
            return;
        }

        let fileIndex = this._dataModel().Files.indexOf(file);
        let viewerImage = file;
        let galleryAttachment = file.Attachment;

        if (fileIndex === -1) {
            if (!this.IsImageGallery)
                return;

            const cachedFileModel = _.find(this._dataModel().Files,
                (fileWithCache) => {
                    return fileWithCache.CacheFile === file
                });
            if (!cachedFileModel) {
                return;
            }

            const cachedFileIndex = this._dataModel().Files.indexOf(cachedFileModel);
            fileIndex = cachedFileIndex;
            viewerImage = cachedFileModel;

            if (!galleryAttachment) {
                galleryAttachment = cachedFileModel.Attachment;
            }
        }

        this._dataModel().Files.splice(fileIndex, 1);

        if (this.IsImageGallery) {
            this.RemoveImageFromGalleryMemory(viewerImage);

            if (file.Id === null || file.Id === undefined) {
                this._attachments.splice(this._attachments.indexOf(galleryAttachment), 1);
            } else {
                this._removeAttachments.push(file);
            }
        } else {
            this._image(new FileModel());
            const attachmentModel = new AttachmentModel();

            attachmentModel.FileName = null;
            attachmentModel.Base64Data = null;
            this._attachments = [attachmentModel];
        }

        this._dataModel.valueHasMutated();
    }

    Dispose(): void {
        this.Trigger('DISPOSE');
    }

    GetImageFromCamera(): void {
        const self = this;
        const camera = new ImageFromCamera();

        const attachmentModel = new AttachmentModel();

        attachmentModel.FileName = `Camera_${Date.now()}.png`;

        this._form.GetScreen().AttachModalComponent(camera);

        camera.On('SAVE', this, (eventArgs) => {

            if (this._disableCropping() && !this.IsImageGallery){
                this.UploadSingleImageNotCrop(null, attachmentModel, eventArgs.data.Image);
                return;
            }

            let imageCropperScreen = new ImageCropperScreen(true);

            imageCropperScreen.Image = eventArgs.data.Image;
            imageCropperScreen.Show();

            imageCropperScreen.On("IMAGE_CROPPED", self, (eventArgs: any) => {

                if (eventArgs.data.CropedImage) {
                    attachmentModel.Base64Data = eventArgs.data.CropedImage.split(',')[1];

                    if (!this.IsImageGallery){
                        const fileModel = new FileModel();
                        fileModel.ImageData = eventArgs.data.CropedImage;

                        this._image(fileModel);
                        if (!this._dataModel()) {
                            this._dataModel(new ImageDataModel())
                        }
                        this._dataModel().Files.push(fileModel);
                        this._dataModel.valueHasMutated();

                        fileModel.Attachment = attachmentModel;
                        this._attachments.push(attachmentModel);
                    }

                    if (this.IsImageGallery){
                        let files = [];
                        if(attachmentModel.Base64Data && attachmentModel.Base64Data != ''){
                            if(self.IsImage(attachmentModel.FileName)){
                                files.push(this.ConvertBase64ToFile(attachmentModel.Base64Data, attachmentModel.FileName));
                            }
                        }
                        self.AddFiles(files);
                    }
                }
            });
        });
    }

    ShowMarkerArea(target) {
        const self = this;
        this.DisableViewerControls();

        const annotations = this._dataModel().Files[this._editGalleryViewer.index].Annotations;
        this._markerArea = new MarkerArea(target);
        this._markerArea.renderImageType = 'image/png';
        this._markerArea.renderMarkersOnly = true;
        this._markerArea.uiStyleSettings.zIndex = ZIndexManager.Instance.NextValue,

            this._markerArea.addRenderEventListener((imgURL, state) => {

                let svgList = $('.__markerjs2_').find('svg'), svg = svgList[svgList.length - 1], svgString, svgBase64;
                svgString = new XMLSerializer().serializeToString(svg);
                svgBase64 = `data:image/svg+xml;base64,${window.btoa(svgString)}`;

                const fileModel = this._dataModel().Files[this._editGalleryViewer.index];

                let images = $('.viewer-canvas').find('img');
                let img = images[images.length - 1];

                let canvas = document.createElement("canvas"), context, width = img.offsetWidth, height = img.offsetHeight;
                canvas.width = width;
                canvas.height = height;

                context = canvas.getContext("2d");
                context.rect(0 , 0 , canvas.width , canvas.height);

                let originalImage = document.createElement('img');
                originalImage.src = fileModel.OriginalImageData;
                originalImage.crossOrigin = 'Anonymous';

                originalImage.onload = function(){
                    context.drawImage(originalImage , 0 , 0 , width , height);

                    let svgImage = document.createElement('img');
                    svgImage.src = svgBase64;
                    svgImage.crossOrigin = 'Anonymous';

                    svgImage.onload = function(){
                        context.drawImage(svgImage , 0 , 0 , width , height);
                        let base64 = canvas.toDataURL("image/png");

                        const fileForPreview = self._dataModel().Files[self._editGalleryViewer.index] as any;
                        let prevEl = fileForPreview.previewElement;

                        if (!prevEl) {
                            _.map(self._uploadedFilesObjects, (fileObject) => {
                                if (fileObject.Id == fileModel.Id) {
                                    prevEl = fileObject.File.previewElement;
                                }
                            });
                        }
                        let previewImageElement = $(prevEl).find('.dz-image > img');
                        previewImageElement.attr('src', base64);

                        const attachmentModel = new AttachmentModel(), attachment = self._attachments.find(a => a.Index == self._editGalleryViewer.index),
                              annotation = self._annotations.find(a => a.Id === fileModel.Id);

                        if (fileModel.Name) {
                            attachmentModel.FileName = TruncateFileName(fileModel.Name);
                        } else {
                            attachmentModel.FileName = TruncateFileName(attachment.FileName);
                        }

                        attachmentModel.Base64Data = base64.replace('data:image/png;base64,', '');
                        attachmentModel.TempName = `${Guid.NewGuid()}_${attachmentModel.FileName}`;

                        let file;
                        if(attachmentModel.Base64Data && attachmentModel.Base64Data != ''){
                                file = self.ConvertBase64ToFile(attachmentModel.Base64Data, attachmentModel.FileName);

                                let chunks = self.CutByChunks(file);
                                self.UploadFileChunks(chunks, attachmentModel.TempName, 1, chunks.length)
                                    .then(() => BlockUi.Unblock());
                        }

                        if (fileModel.Attachment) {
                            fileModel.TempOriginalName = fileModel.Attachment.TempName;
                        } else {
                            let originalImageFile, originalImageBase64 = fileModel.OriginalImageData.replace('data:', '').replace(/^.+,/, '');
                            fileModel.TempOriginalName = `${Guid.NewGuid()}_${fileModel.Name}`;

                            if (originalImageBase64 && originalImageBase64 != '') {
                                    originalImageFile = self.ConvertBase64ToFile(originalImageBase64, fileModel.Name);

                                    let chunks = self.CutByChunks(originalImageFile);
                                    self.UploadFileChunks(chunks, fileModel.TempOriginalName, 1, chunks.length)
                                        .then(() => BlockUi.Unblock());
                            }
                        }

                        fileModel.TempName = attachmentModel.TempName;
                        fileModel.Annotations = JSON.stringify(state);

                        if (annotation) {
                            annotation.Annotations = fileModel.Annotations;
                            annotation.TempName = fileModel.TempName;
                            annotation.TempOriginalName = fileModel.TempOriginalName;
                        } else {
                            const attachment = self._attachments.find(a => a.Index == self._editGalleryViewer.index);
                            if (attachment) {
                                attachment.Annotations = fileModel.Annotations;
                                attachment.TempName = fileModel.TempName;
                                attachment.TempOriginalName = fileModel.TempOriginalName;
                            } else {
                                const newAnnotation = new FileModel();
                                newAnnotation.Id = fileModel.Id;
                                newAnnotation.Annotations = fileModel.Annotations;
                                newAnnotation.TempName = fileModel.TempName;
                                newAnnotation.TempOriginalName = fileModel.TempOriginalName;
                                self._annotations.push(newAnnotation);
                            }
                        }

                        self.EnableViewerControls();
                    }
                };
            });

        this._markerButton.classList.add("disabled");
        this._markerButton.setAttribute('style', 'pointer-events: none;');
        this._markerArea.addCloseEventListener(() => {
            this.EnableViewerControls();
            this._annotationsShown(true);
            this._markerButton.classList.remove("disabled");
            this._markerButton.setAttribute('style', 'pointer-events: auto;');
        });

        this._markerArea.show();
        this._annotationsShown(false);

        if (annotations) {
            this._markerArea.restoreState(JSON.parse(annotations));
        }
    }

    DisableViewerControls() {
        this._editGalleryViewer.options.movable = false;
        this._editGalleryViewer.options.zoomable = false;
        this._editGalleryViewer.options.rotatable = false;
        this._editGalleryViewer.options.flippable = false;
    }

    EnableViewerControls() {
        this._editGalleryViewer.options.movable = true;
        this._editGalleryViewer.options.zoomable = true;
        this._editGalleryViewer.options.flippable = true;
    }
}