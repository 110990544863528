import * as ko from 'knockout';
import * as _ from 'underscore';

import {
    SearchFieldModel,
    SearchResultModel
} from "../Models/SearchResultModel";
import {SearchResultRow} from "./SearchResultRow";
import {Event} from 'Core/Common/Event';
import {EVENTS as SEARCH_SCREEN_EVENTS} from 'Core/Screens/SearchScreen/Events';
import {LABELS} from 'Core/Components/Translation/Locales';
import {ISearchRequestModel, SearchStore} from "Core/Screens/SearchScreen/Stores/SearchStore";
import {RecordTypes, SearchByMainRelationTypes} from "Core/Screens/SearchScreen/Enums/Enums";
import {BlockUI} from "Core/Common/BlockUi";
import {SortOrder} from "Core/Controls/Grid/BaseGrid/GridColumn/BaseColumn";
import {FormatConverter} from "FormatEditor/FormatConverter";
import { ISelectedRecord } from 'Core/Screens/SearchScreen/SearchScreen';

// Templates
import SearchResultGridTemplate from 'Core/Screens/SearchScreen/SearchResultGrid/Templates/SearchResultGrid.html';
ko.templates['Core/Screens/SearchScreen/SearchResultGrid/Templates/SearchResultGrid'] = SearchResultGridTemplate;

export class SearchResultGrid extends Event{

    private _hasData: KnockoutObservable<boolean>;
    private _searchResultMessage: KnockoutObservable<string>;
    private _searchResult: SearchResultModel;
    private _columns: KnockoutObservableArray<SearchFieldModel>;
    private _rows: KnockoutObservableArray<SearchResultRow>;
    private _isSmallTable: KnockoutObservable<boolean>;
    private _searchRequest: ISearchRequestModel;
    private _container: HTMLElement;
    private _orderFields: Array<any>;
	private _showExpandButton: boolean;
	private _labels = LABELS;
    private _multiselectMode: boolean;
    private _selectedRecords: KnockoutObservableArray<number>;
    private _selectedDuplicatesRecords: KnockoutObservableArray<ISelectedRecord>;
    private _showDuplicatesMode: boolean;

    constructor(
        searchResult: SearchResultModel,
        selectedRecords: KnockoutObservableArray<number>,
        searchRequest: ISearchRequestModel,
        showExpandButton: boolean,
        multiselectMode: boolean,
        showDuplicatesMode: boolean,
        selectedDuplicatesRecords: KnockoutObservableArray<ISelectedRecord>,
    ){
        super();
        this._showExpandButton = showExpandButton;
        this._showDuplicatesMode = showDuplicatesMode;
        this._hasData = ko.observable(searchResult.Rows.length > 0);
        this._searchResultMessage = ko.observable(searchResult.ErrorMessage || this._labels.NO_RECORDS_FOUND);
        this._searchResult = searchResult;
        this._columns = ko.observableArray(searchResult.SearchFields);
        this._rows = ko.observableArray([]);
        this._isSmallTable = ko.observable(searchResult.SearchFields.length <= 2);
        this._searchRequest = searchRequest;
        this._orderFields = [];
        this._multiselectMode = multiselectMode;
        this._selectedRecords = selectedRecords;
        this._selectedDuplicatesRecords = selectedDuplicatesRecords;

        let rows = [];
        _.each(searchResult.Rows, (item) => {
            item.RowValues.map((row) => {
                if(row.Value) {
                    const fieldMetadata = _.find(searchResult.SearchFields, (item) => {return item.Name == row.Key});
                    if(fieldMetadata) {
                        row.Value = this.PrepareFormattedValue({ value: row.Value, fieldMetadata });
                    }
                }
            });

            let row = new SearchResultRow(item, searchResult.SearchFields);
            row.IsSelected.subscribe((newValue)=>{
                this.Trigger(SEARCH_SCREEN_EVENTS.SELECT_RECORD, { Row: row.Model, IsSelected: newValue, RowClick: row.RowClick() });
            });

            let isSelected = _.any(this._selectedRecords(), id => id === row.RecordId);

            row.IsSelected(!!isSelected);

            row.IsSelectedDuplicatesRecord.subscribe(newValue => {
                this.Trigger(SEARCH_SCREEN_EVENTS.DUPLICATES_RECORD_SELECTED, {Row: row.Model, IsDuplicatesSelected: newValue});
            });
            let isSelectedDuplicatesRecord = _.any(this._selectedDuplicatesRecords(), (item: ISelectedRecord) => {
                return item.RecordId === row.RecordId && item.TableId === this._searchRequest.EntityId;
            });
            if (this._selectedDuplicatesRecords().length == 2){
                row.IsDisableDuplicateRecord(!isSelectedDuplicatesRecord);
            }
            row.IsSelectedDuplicatesRecord(isSelectedDuplicatesRecord);

            rows.push(row);
        });
        this._rows(rows);
        this._selectedDuplicatesRecords.subscribe((newValue)=>{
            _.each(this._rows(), (row) => {
                let isSelectedDuplicatesRecord = _.any(this._selectedDuplicatesRecords(), (item: ISelectedRecord) => {
                    return item.RecordId === row.RecordId && item.TableId === this._searchRequest.EntityId;
                });
                if (this._selectedDuplicatesRecords().length == 2){
                    row.IsDisableDuplicateRecord(!isSelectedDuplicatesRecord);
                } else {
                    if (this._selectedDuplicatesRecords().length <= 1){
                        row.IsDisableDuplicateRecord(false);
                        row.IsSelectedDuplicatesRecord(isSelectedDuplicatesRecord);
                    }
                }
                row.IsSelectedDuplicatesRecord(isSelectedDuplicatesRecord);
            });
        });
        this.AddEvent(SEARCH_SCREEN_EVENTS.SELECT_RECORD);
        this.AddEvent(SEARCH_SCREEN_EVENTS.SORT);
        this.AddEvent(SEARCH_SCREEN_EVENTS.DUPLICATES_RECORD_SELECTED);
    }

    PrepareFormattedValue({ value, fieldMetadata }: { value: string; fieldMetadata: SearchFieldModel }) {
        if (_.contains(['Date', 'Time', 'DateTime'], fieldMetadata.TypeName)) {
            value = FormatConverter.CorrectTimezone(value);

            const datetimeFormat = FormatConverter.GetDateFormatFromFieldModel(
                { Type: fieldMetadata.TypeName, FormatName: fieldMetadata.FormatName },
                true
            );

            return FormatConverter.ConvertFromDefaultFormat(value, datetimeFormat);
        }

        if (_.contains(['Decimal', 'Integer'], fieldMetadata.TypeName)) {
            return this.FormatDecimalOrInteger({
                value,
                formatName: fieldMetadata.FormatName,
                size: fieldMetadata.Size
            });
        }

        if (fieldMetadata.TypeName === 'Lookup') {
            if (_.contains(['Decimal', 'Integer'], fieldMetadata.ValFieldTypeName)) {
                return this.FormatDecimalOrInteger({
                    value,
                    formatName: fieldMetadata.ValFieldFormatName,
                    size: fieldMetadata.ValFieldSize
                });
            }
        }

        return value;
    }

    FormatDecimalOrInteger({ value, formatName, size }: { value: string; formatName: string; size: number }) {
        return FormatConverter.LocalizeDecimalOrInteger(
            value && formatName === 'Percentage'
                ? (Number(value.replace(/[.,]/g, '.')) * 100).toFixed(size < 2 ? 0 : size - 2)
                : value
        );
    }

    GetTemplateName() {
        return 'Core/Screens/SearchScreen/SearchResultGrid/Templates/SearchResultGrid'
    }

    AfterRender(elements: Array<HTMLElement>){
        this._container = elements[0];
    }

    SelectRecord(row: SearchResultRow){
        if (row.IsSelected()){
            row.RowClick(false);
        } else {
            row.RowClick(true);
            row.IsSelected(true);
        }
    }

    MultiSelectRecord(row: SearchResultRow){
        row.IsSelected(!row.IsSelected());
    }

    SelectRecords(ids: number[]) {
        if (ids && ids.length > 0 && this._multiselectMode) {
            _.each(this._rows(), (row) => {
                if (_.contains(ids, row.RecordId)) {
                    this.SelectRecord(row);
                }
            });
        }
    }

    ToggleExpand(row: SearchResultRow) {
        if(row.HasChildren) {
            row.Expanded(!row.Expanded());

            if (row.Expanded()) {
                this.GetSubGriData(row);
            } else {
                row.SubGrid = null;
            }
        }
    }

    GetSubGriData(row: SearchResultRow){
        BlockUI.Block({ Target: this._container });
        this._searchRequest.SubGridParentRecordId = row.RecordId;
        this._searchRequest.SearchPhrase = '';
        this._searchRequest.AlternativeEntities = [];
        this._searchRequest.RecordType = RecordTypes[RecordTypes.All];
        this._searchRequest.SearchByMainRelationType = SearchByMainRelationTypes.Off;
        this._searchRequest.EnableFilter = false;
        this._searchRequest.ControlId = 0;
        this._searchRequest.ParentEntityId = 0;
        this._searchRequest.ParentRecordId = 0;
        this._searchRequest.PageNumber = null;
        this._searchRequest.RecordsPerPage = null;
        this._searchRequest.SearchByTypes = [];
        this._searchRequest.OrderFields = this._orderFields;

        SearchStore.Search(this._searchRequest)
            .always(()=>{
                BlockUI.Unblock(this._container);
            })
            .then(result => {

                _.each(result.SearchFields, (column) =>{
                    if (this._orderFields[0] && this._orderFields[0].Id === column.Id) {
                        column.SortDirection = Number(this._orderFields[0] && this._orderFields[0].Order || SortOrder.Asc);
                    } else {
                        column.SortDirection = Number(SortOrder.Both);
                    }

                    column.SortOrder = ko.observable(column.SortDirection);

                    column.SortOrderClassName = ko.computed({
                        owner: this,
                        read: () => {
                            if (!column.Id) {
                                return 'sort-null';
                            }
                            return `sort-${SortOrder[column.SortOrder()].toLowerCase()}`;
                        }
                    });

                    column.Sort = () => {
                        if (!column.Id) {
                            return;
                        }
                        switch (SortOrder[column.SortOrder()]) {
                            case 'Asc':
                                column.SortDirection = SortOrder.Desc;
                                break;
                            case 'Desc':
                                column.SortDirection = SortOrder.Both;
                                break;
                            case 'Both':
                                column.SortDirection = SortOrder.Asc;
                                break;
                        }
                        column.SortOrder(column.SortDirection);

                    };

                    column.SortOrder.subscribe((order) => {
                        this._orderFields = [{
                            Id: column.Id,
                            Order: order
                        }];

                        this.GetSubGriData(row);
                    });
                });

                let subGrid = new SearchResultGrid(
                    result,
                    this._selectedRecords,
                    this._searchRequest,
                    this._showExpandButton,
                    this._multiselectMode,
                    this._showDuplicatesMode,
                    this._selectedDuplicatesRecords);
                row.SubGrid = subGrid;

                subGrid.On(SEARCH_SCREEN_EVENTS.SELECT_RECORD, this, (eventArgs) => {
                    this.Trigger(SEARCH_SCREEN_EVENTS.SELECT_RECORD, { Row: eventArgs.data.Row, IsSelected: eventArgs.data.IsSelected });
                });

                subGrid.On(SEARCH_SCREEN_EVENTS.DUPLICATES_RECORD_SELECTED, this, (eventArgs) => {
                    this.Trigger(SEARCH_SCREEN_EVENTS.DUPLICATES_RECORD_SELECTED, { Row: eventArgs.data.Row, IsDuplicatesSelected: eventArgs.data.IsDuplicatesSelected });
                });

                $(window).trigger('resize');
            });
    }
}
