import {P} from 'Core/Common/Promise';
import {Request} from 'Core/Common/Request';
import {MenuQueryMetadata} from '../Models/MenuQueryMetadata';


export class MenuQueriesStore {
    constructor(private _entityId: number) {

    }

    GetMenuQueriesMetadata() {
        const deferred = P.defer<MenuQueryMetadata[]>();

        Request.Send({
            proxy: {
                type: 'GET',
                url: '/api/MenuApi/GetMenuQueriesMetadata',
                dataType: 'json'
            },
            params: {tableId: this._entityId}
        }).then(result => {
            if (result.IsSuccessfull) {
                deferred.resolve(result.ResultList);
            } else {
                deferred.reject({message: result.ErrorMessage});
            }
        }).fail(() => {
            deferred.reject({message: 'Error getting menu queries'})
        });

        return deferred.promise();
    }
}