import * as ko from "knockout";
import {BaseProperty, IPropertyDescription} from "Core/GeneralProperties/Managers/BaseProperty";
import { EntityTypesStore } from 'Core/Screens/TypeScreen/Stores/EntityTypesStore';
import { P } from "Core/Common/Promise";
import { TableStore } from "Core/Common/Stores/TableStore";
import { SearchScreen, ISelectedRecord } from 'Core/Screens/SearchScreen/SearchScreen';
import {RecordStore} from "Core/Common/Stores/RecordStore";
import {BlockUI} from "Core/Common/BlockUi";

import Template from "Core/GeneralProperties/Managers/QuerySelectorProperty/Templates/QuerySelectorProperty.html";

ko.templates["Core/GeneralProperties/Managers/QuerySelectorProperty/Templates/QuerySelectorProperty"] = Template;

export class QuerySelectorProperty extends BaseProperty {
    QueryName: KnockoutObservable<string>;

    constructor(property: IPropertyDescription, propertyValue: string, getData: boolean = true) {
        super(property, true);
        this.QueryName = ko.observable(null);
        this.Value = ko.observable(propertyValue);
        this.Value.subscribe(()=>{
            this.LoadData(getData);
        });
        this.LoadData(getData);
	}

    GetTemplateName(): string {
        return "Core/GeneralProperties/Managers/QuerySelectorProperty/Templates/QuerySelectorProperty";
    }

    GetDefaultValue() {
        return null;
    }

    SetValue(propertyValue: string): void {
        this.Value(propertyValue);
    }

    LoadData(getData: boolean = true){
        if(this.Value() && getData){
            TableStore
                .Get({ TableName: 'Queries' })
                .then((table: any) => {
                    BlockUI.Block();

                    RecordStore.GetRecord({TableId: table.Id, RecordId: this.Value()})
                        .always(() => BlockUI.Unblock())
                        .then((record: any) => {
                            let nameField = _.find(record.Fields, (field: any)=> field.FieldName === 'NAME');
                            if(nameField){
                                this.QueryName(nameField.FieldValue);
                            }
                        });
                });
        }
    }
    
    Add(){
        EntityTypesStore.GetTypes({
            EntityId: 0,
            ParentTypeId: 0,
            WithRoot: true,
            OnlyEnabled: true,
            EntityName: 'Queries'
        }).then(tableTypesModel => {
            
            let queryType = _.find(tableTypesModel.TableTypes, (type) => type.Name === 'Query');

            this.ShowSearchScreen( 'Queries', false, [queryType.Id]).then((searchScreen) => {
                searchScreen.On('RECORD_SELECTED', this, (evtArgs) => {
                    let record = evtArgs.data as ISelectedRecord;
                    this.Value(record.RecordId);
                });
            });
        })
    }

    ShowSearchScreen(
		tableName: string,
		multiSelect: boolean = false,
		searchTypes: Array<number> = []
	): P.Promise<SearchScreen> {
		const deferredResult = P.defer<SearchScreen>();
		TableStore.Get({ TableName: tableName }).then((table: any) => {

			const searchScreen = new SearchScreen({
				ButtonAdd: false,
				EntityId: table.Id,
				SearchTerm: '',
				ControlId: 0,
				ConditionToggler: false,
				MultiSelectMode: multiSelect,
				SearchByTypes: searchTypes
			});

			searchScreen.Show();
			deferredResult.resolve(searchScreen);
		});

		return deferredResult.promise();
	}

    Clear(){
        this.Value(null);
        this.QueryName(null);
    }
}