import * as ko from 'knockout'

import { HistoryLogsModel, LogModel } from 'Core/Controls/History/Models/HistoryLogsModel';
import { DATE_FORMATS } from 'Core/Constants/DateTimeFormats';
import { FormatConverter } from 'FormatEditor/FormatConverter'


import HistoryContentTemplate from "Core/Controls/History/Templates/HistoryContent.html"
import * as moment from "moment";


ko.templates["Core/Controls/History/Templates/HistoryContent"] = HistoryContentTemplate;


export interface IHistoryRequestModel {
	EntityId: number;
	RecordId: number;
}


export class HistoryLogs {
	private _enabled: KnockoutObservable<boolean>;
	private _historyLogs: Array<LogModel>;
	private _historyThs: string[]

	constructor(history: any) {
		let allowed = ['Type', 'Format']; //remove to history
		this._enabled = ko.observable(true);
		this._historyLogs = history;
		this._historyThs = _.keys(history[0])
			.filter(key => !_.contains(allowed, key)) //remove['Type', 'Format']
			.map(key => key === 'HistoryId' ? '#' : key);
		this.Init();
	}

	private Init(): void {
	}

	GetHistoryValues(data) {
		const allowed = ['EntityName', 'NewValue', 'Operation', 'FieldName', 'ChangedBy', 'ChangeDate']; //leave in data

		let newHistoryArgument = _.extend({}, data, {
			ChangeDate: this.FormatDateTimezone( data.ChangeDate.toString() ),
			NewValue: data.Type === 'DateTime' && data.NewValue ? this.FormatDateTimezone( data.NewValue.toString() ) : data.NewValue
		} );

		return _.values( _.pick( newHistoryArgument, allowed ) );
	}

	private FormatDateTimezone(date: string) {
		return  FormatConverter.ConvertFromDefaultFormat(FormatConverter.CorrectTimezone( date ), DATE_FORMATS.DATE_TIME.MomentFormat );
	}


	GetTemplateName() {
		return 'Core/Controls/History/Templates/HistoryContent';
	}


	AfterRender() {
	}
}