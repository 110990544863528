import { BasicCanvasItemViewModel } from './BasicCanvasItemViewModel';
import { CanvasItemModel, CanvasLinkModel } from 'Core/Controls/CanvasDesigner/Models/CanvasModel';
import { Utils } from 'Core/Controls/CanvasDesigner/Utils';
import { CanvasShapeWidth } from 'Core/Controls/CanvasDesigner/Enums/CanvasShapeWidth';
import { PORT_ATTRIBUTES } from 'Core/Controls/CanvasDesigner/Constants/PortAttributes';
import { SIDES } from 'Core/Controls/CanvasDesigner/Constants/Sides';
import { SHAPES } from 'Core/Controls/CanvasDesigner/Constants/Shapes';
import {dia as Dia, shapes, dia} from 'libs/rappid/build/rappid';

export class ParamViewModel extends BasicCanvasItemViewModel {

	parent: number | string = 0;
	embeddingInvalid: boolean = false;
	embedded: boolean = true;
	embeds: Array<number | string> = [];
	parentedNode: boolean = true;
	relatedIconId: string;
	childSocketsIds: Array<number> = [];
	attrs: any;
	ports: any;
	inPorts: Array<string> = [];
	outPorts: Array<string> = [];

	useSubKey: boolean;
	filledFromField: number;
	filledFromTable: number;

	constructor(model: CanvasItemModel) {
		super(model, { 'width': CanvasShapeWidth['Param'], 'height': 30 });

		this.useSubKey = false;

		this.z = Utils.GetRandomArbitrary(80, 100);
		this.ports = PORT_ATTRIBUTES;

		if (model.SideName === SIDES.IN) {
			this.inPorts.push('');
		}

		if (model.SideName === SIDES.OUT) {
			this.outPorts.push('');
		}

		if (model.ShapeName === SHAPES.LINK_FIELD) {
			this.attrs.rect.fill = '#B8C4CC';
		}

		this.filledFromField = model.FilledFromField;
		this.filledFromTable = model.FilledFromTable;		
		this.InitProperties(model.Properties)
	}

	SetPorts(type, port): void {
		this[`${type}Ports`].push(port);
	}


	SetSize(size): void {
		this.size = { 'width': size.width, 'height': size.height };
	}

	SetParentId(link: CanvasLinkModel): void {
		this.parent = link.CanvasItem1Id || link.CanvasItem1Guid;
	}

	private InitProperties(properties: string){
		try {
			let jsonProperties = JSON.parse(properties);
			if(jsonProperties && jsonProperties.UseSubKey){
				this.useSubKey = jsonProperties.UseSubKey;
			}

			if(jsonProperties && jsonProperties.IsRecordIdentifier){
				this.attrs.IsRecordIdentifier = jsonProperties.IsRecordIdentifier;
			}

			if(jsonProperties && jsonProperties.IsSearchBySubKey){
				this.attrs.IsSearchBySubKey = jsonProperties.IsSearchBySubKey;
			}

			if(jsonProperties && jsonProperties.UseSubKey){
				this.attrs.UseSubKey = jsonProperties.UseSubKey;
			}

			if(jsonProperties && jsonProperties.FileLocation){
				this.attrs.FileLocation = jsonProperties.FileLocation;
			}

			if(jsonProperties && jsonProperties.UsePrimaryKey){
				this.attrs.UsePrimaryKey = jsonProperties.UsePrimaryKey;
			}

			if(jsonProperties && jsonProperties.ImageFileLocation){
				this.attrs.ImageFileLocation = jsonProperties.ImageFileLocation;
			}

			if(jsonProperties && jsonProperties.FilledFrom){
				this.attrs.FilledFrom = jsonProperties.FilledFrom;
			}

		}catch (e) {}
	}
}