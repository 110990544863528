import { dia as Dia, shapes, V } from 'libs/rappid/build/rappid';
import * as joint from 'libs/rappid/build/rappid';
import 'Core/Controls/CanvasDesigner/Shapes/Models/Param';
import {EVENTS} from "../ParamView/Events";
import { ShapeFactory } from '../../../Views/ShapeFactory';

let generalTemplate = V('<g class= "rotatable"><rect class="body"/><text class="label shape-label"/><text class="icons fa"/><title/></g>');

let constantTemplate =  V('<g class= "rotatable"><rect class="body"/>' +
	'<foreignObject class="fobj" id="v-9" width="160" height="30">' +
	'      <input xmlns="http://www.w3.org/1999/xhtml" type="text" id="defaultValueEditor"  style="width: 160px; height:30px;" />' +
	'</foreignObject>'+
	'</g>');

declare module 'libs/rappid/build/rappid' {
	namespace shapes {
		namespace cyberThing {
			class ParamView extends Dia.ElementView { }
		}
	}
}

joint.shapes.cyberThing.ParamView = Dia.ElementView.extend({

	template: {},

	initialize: function () {
		joint.dia.ElementView.prototype.initialize.apply(this, arguments);
		this.model.position(0, 0, { parentRelative: true });
		this.model.get('attrs')['.shape-label']['ref-y'] = 0.5;
		this.model.attr('./visibility', 'hidden');

		if(this.model.attributes.shapeName === 'Constant'){
			this.template = constantTemplate.clone();
		}else{
			this.template = generalTemplate.clone();
		}

		this.bindEvents();
	},

	renderMarkup: function () {
		this.initIcons();
		this.vel.append(this.template);
	},

	initIcons: function(){
		let icons = '';

		if(this.model.attributes.attrs.UseSubKey){
			icons = '\uf112';
		}

		if(this.model.attributes.attrs.IsRecordIdentifier){
			icons += '\uf084';
		}

		if(this.model.attributes.attrs.IsSearchBySubKey){
			icons += '\uf002';
		}

		if(this.model.attributes.filledFromField){
			icons += '\uf08e';
		}
		if(this.model.attributes.attrs.FilledFrom){

			let root = ShapeFactory.GetRoot(this.model, this.paper.model);
			root.get('replicationId');

			let filledFrom = this.model.attributes.attrs.FilledFrom[root.get('replicationId')] || '';
			if(filledFrom != ''){
				icons += '\uf058';
			}
		}


		this.model.attr({
			'.icons': {
				'ref-x': 0.84,
				refY2: 5,
				text: icons
			}
		});
	},

	bindEvents: function(){
		let self = this;

		let defaultValueEditor = this.template.findOne('#defaultValueEditor');
		if(defaultValueEditor && defaultValueEditor.node){
			$(defaultValueEditor.node).val(this.model.attributes.defaultValue);
			$(defaultValueEditor.node).on('click', ()=>{
				$(defaultValueEditor.node).focus();
			});

			$(defaultValueEditor.node).on("input", function(){
				self.model.trigger(EVENTS.DEFAULT_VALUE_CHANGED, $(this).val());
			});
		}
	}
});