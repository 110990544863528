import * as ko from 'knockout';
import * as _ from 'underscore';

import {BaseControl} from 'Core/Controls/BaseControl/BaseControl';
import {IControlParam} from 'Core/Screens/IScreen';
import {IControl} from "../IControl";
import {PlannerPage} from "../PlannerPage/PlannerPage";
import {EVENTS} from 'Core/Constant'
import {LABELS} from "Core/Components/Translation/Locales";
import {UserVarsManager} from "Core/UserVarsManager/UserVarsManager";
import {BlockUI} from "Core/Common/BlockUi";
import Split from 'split.js';
import Config from 'Core/Controls/Planner/Config.json';

import DesignTemplate from 'Core/Controls/Planner/Templates/Design.html';
import ToolBarTemplate from 'Core/Controls/Planner/Templates/ToolBar.html';
import ViewTemplate from 'Core/Controls/Planner/Templates/View.html';

ko.templates['Core/Controls/Planner/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Planner/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/Planner/Templates/View'] = ViewTemplate;

export class Planner extends BaseControl {
	private _currentPlannerPage: KnockoutObservable<PlannerPage> = ko.observable(null);
	private _currentTabIndex: number;
	private _plannerHtmlTable: HTMLTableElement;
	private _selection: KnockoutObservableArray<any>;
	private _blockElements: Array<HTMLElement>;
	protected _labels = LABELS;

	constructor(params: IControlParam) {
		super(params, Config);
		this._currentTabIndex = 0;
		this._blockElements = [];
		this._selection = ko.observableArray([]);
		this._selection.subscribe((newValue)=>{
			if(this._plannerHtmlTable && (this._plannerHtmlTable as any)._cellSelection){
				let input = (this._plannerHtmlTable as any)._cellSelection.view.inputElement;
				if(input){
					$(input).inputmask('Regex', {regex: '^[0-9]+$'});
				}
			}
		});
		this.Init();
	}

	ApplyProperties(){}

	ResetSelection(){
		this._selection([]);
	}

	BlockSelection(){
		_.each(this._selection(), (item) => {
			this._blockElements.push(item.cell);
			BlockUI.Block({ Target: item.cell } );
		});
	}

	UnblockSelection(){
		_.each(this._blockElements, (item) => {
			BlockUI.Unblock(item);
		});
	}

	private Init(): void {
		const subControls = this._subControls();
		const controlId = this.GetControlId();

		subControls.forEach((plannerPage: IControl) => {
			let page = <PlannerPage>plannerPage;
			page.On(EVENTS.ON_PLANNER_PAGE_CLICK, this, (args: any) => {
				let plannerPage = <PlannerPage>args.source;
				this.SetActiveTabPage(plannerPage, args.source._parentControl.GetControlId());
				this._plannerHtmlTable = $(this._el).find('.planner-table')[0] as HTMLTableElement;
			});
		});

		const plannerTabsParams = UserVarsManager.Instance.GetPlannerTabsParams();
		const activityStorage = plannerTabsParams ? plannerTabsParams.plannerTabsActivityStorage || {} : {};

		if (_.isEmpty(activityStorage[controlId])) {
			const startDate = new Date();
			startDate.setDate(startDate.getDate() - 1);
			activityStorage[controlId] = {};
			subControls.forEach(control => activityStorage[controlId][control.GetControlId()] = startDate.getTime());

			UserVarsManager.Instance.SetPlannerTabsParams(null, activityStorage);
		}

		const activeTab = this.GetPreselectTab();

		if (activeTab) {
			activeTab.SetIsVisible(true);

			this.SetActiveTab(subControls.indexOf(activeTab));
			this.SetActiveTabPage(<PlannerPage>activeTab);

		} else {
			const firstVisible = subControls.find(subControl => subControl.GetIsVisible());

			if (firstVisible) {
				this.SetActiveTabPage(<PlannerPage>firstVisible);
			} else if (subControls.length >= 1) {
				subControls[0].SetIsVisible(true);
				this.SetActiveTabPage(<PlannerPage>subControls[0]);
			}
		}
	}

	private GetPreselectTab() {
		let preSelectedPlannerTab = UserVarsManager.Instance.GetPlannerTabsParams();
		if (preSelectedPlannerTab && this._form) {
			const preselectedPlannerTabData: any = _.where(preSelectedPlannerTab.activePlannerTabPageCIDs, {screenId: this._form.GetScreen().GetScreenId()})[0];
			if (!preselectedPlannerTabData) {
				return null;
			}
			let plannerTabToSelect = <PlannerPage>this._subControls().filter((control) => {
				return control.GetControlId() === preselectedPlannerTabData.activePlannerTabPageCID;
			})[0];
			if (plannerTabToSelect) {
				return plannerTabToSelect;
			}
		}

		return null;
	}

	SetActiveTabPage(plannerPage: PlannerPage, parentControlId?: number) {
		const screen = this._form.GetScreen();
		const currentPlannerPage = this._currentPlannerPage();
		const tabControlId = plannerPage.GetControlId();
		const plannerTabsParams = UserVarsManager.Instance.GetPlannerTabsParams();
		const activityStorage = plannerTabsParams ? plannerTabsParams.plannerTabsActivityStorage : {};

		this._subControls().forEach(control => control.SetIsActive(false));

		if(currentPlannerPage){
			currentPlannerPage.SetIsActive(false);
		}

		plannerPage.SetIsActive(true);
		this._currentTabIndex = this._subControls().indexOf(plannerPage);
		this._currentPlannerPage(plannerPage);

		if (parentControlId) {
			if (!activityStorage[parentControlId]) {
				activityStorage[parentControlId] = {};
			}
			activityStorage[parentControlId][tabControlId] = Date.now();
		}

		UserVarsManager.Instance.SetPlannerTabsParams({
			screenId: screen.GetScreenId(),
			activePlannerTabPageCID: tabControlId
		}, activityStorage);

		if (screen) {
			screen.SetState( {ActiveControlId: tabControlId});
		}
		this.InitSplitter();
	}


	AddSubControl(control: IControl) {
		let plannerPage = control as PlannerPage;
		this._subControls.push(control);

		if (this._currentPlannerPage()) {
			this._currentPlannerPage().SetIsActive(false);
		}

		plannerPage.SetIsActive(true);
		this._currentPlannerPage(plannerPage);

		plannerPage.On(EVENTS.ON_PLANNER_PAGE_CLICK, this, (args: any) => {
			let tabPage = <PlannerPage>args.source;

			if (this._currentPlannerPage()) {
				this._currentPlannerPage().SetIsActive(false);
			}
			plannerPage.SetIsActive(true);
			this._currentPlannerPage(tabPage);
		});
	}

	RemoveControl(control: IControl) {
		let wrappers = new Array<Element>(control.GetWrapper());

		_.each(this._currentPlannerPage().GetAllSubControls(),
			(subControl) => {
				wrappers.push(subControl.GetWrapper());
			});

		wrappers.map((el) => {
			$(control.GetWrapper()).add(el).fadeOut('fast', () => {
				let subControls = this._subControls();
				let tabPage = control as PlannerPage;
				this._subControls.remove(control);

				if (tabPage.GetIsActive()) {
					if (this._currentTabIndex <= subControls.length - 1) {
						this.SetActiveTab(this._currentTabIndex);
					} else if (this._currentTabIndex > 0) {
						this.SetActiveTab(this._currentTabIndex - 1);
					} else {
						this._currentPlannerPage(null);
					}
				} else {
					this.SetActiveTabPage(this._currentPlannerPage());
				}
			});
		});
	}

	SetActiveTab(tabIndex: number) {
		const subControls = this._subControls();
		if (subControls[tabIndex]) {
			const tab = <PlannerPage>subControls[tabIndex];
			if (this._currentPlannerPage()) {
				this._currentPlannerPage().SetIsActive(false);
			}
			tab.SetIsActive(true);

			this._currentPlannerPage(tab);
			this._currentTabIndex = tabIndex;
			this.InitSplitter();
		}
	}

	private InitSplitter(){
		let leftView = $(this._el).find('.left-planner-table')[0];
		let rightView = $(this._el).find('.right-planner-table')[0];

		if(leftView && rightView){
			Split([leftView, rightView], {
				sizes: [35, 65],
				elementStyle: (dimension, size, gutterSize) => {	
					
					($('.left-planner-table') as any).freezeTable('resize');
					($('.right-planner-table') as any).freezeTable('resize');

					if(size < 20){
						size = 20;
					}

					if(size > 80){
						size = 80;
					}

					return ({
						'flex-basis': `calc(${size}% - ${gutterSize}px)`,
					})
				},
				gutterStyle: (dimension, gutterSize) => ({
					'flex-basis':  `${gutterSize}px`,
				}),
			});
		}
	}

	AfterRender(el: Array<HTMLElement>): void {
		super.AfterRender(el);
		this._plannerHtmlTable = $(this._el).find('.planner-table')[1] as HTMLTableElement;
		this.InitSplitter();
	}
}