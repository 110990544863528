import * as ko from "knockout";
import * as _ from "underscore";

import {
	BaseProperty,
	IPropertyDescription,
	IPropertyOption,
	IPropertySortOption
} from "Core/GeneralProperties/Managers/BaseProperty";
import {IControl} from "Core/Controls/IControl";
import { QueryBuilderStore } from 'QueryBuilder/Stores/QueryBuilderStore';
import { CardScreenModel } from 'QueryBuilder/Models/CardScreenModel';
import {CONTROL_TYPES, TABLE_TYPES} from "Core/Constant";

import {EntityTypes} from "Core/Controls/Grid/BaseGrid/Enums/EntityTypes";
import {ScreenTypes} from "Core/Common/Enums/ScreenTypes";

import Template from "Core/GeneralProperties/Managers/CardScreenProperty/Templates/Template.html";
import { LABELS } from 'Core/Components/Translation/Locales';
import { BlockUI } from "../../../Common/BlockUi";

ko.templates['Core/GeneralProperties/Managers/CardScreenProperty/Templates/Template'] = Template;

export class CardScreenProperty extends BaseProperty {
	private _control: IControl;
	private _labels = LABELS;
	private _screens: KnockoutObservableArray<CardScreenModel>;
	private _selectedScreen: KnockoutObservable<CardScreenModel>;
	private _el: HTMLElement;


	constructor(property: IPropertyDescription, propertyValue: number, control: IControl, getData: boolean = true) {
		super(property);
		this._control = control;
		this.Value = ko.observable(null);
		this._screens = ko.observableArray([]);
		this._selectedScreen = ko.observable(null);
		this.SetValue(propertyValue, getData);

		this._selectedScreen.subscribe((newValue) => {
			if(newValue){
				this.Value(newValue.Id);
			}
			else
			{
				this.Value(null);
			}
		});
	}

	AfterRender(el: Array<HTMLElement>) {
		this._el = el[0];
		super.AfterRender(el);
	}

	SetValue(propertyValue: number, getData: boolean = true){
		if(getData) {
			QueryBuilderStore.GetCardScreens({EntityId: this.GridSubjectEntityId})
				.always(() => {
				})
				.then((screens) => {

					this._screens(screens);

					let selectedScreen = _.find(screens, (screen) => {
						return screen.Id === propertyValue;
					});

					if (selectedScreen) {
						this._selectedScreen(selectedScreen);
					}

				});
		}

		this.Value(propertyValue);
	}

	get GridSubjectEntityId() {
        if (this._control) {
            let gridSubjectEntityId = null;
            if (this._control.GetForm().GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.QueryScreen] || this._control.GetForm().GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.ListScreen]) {
                gridSubjectEntityId = this._control.GetForm().GetScreen().GetEntityId();
            } else {
                let fieldModel = _.find(this._control.GetModel().Fields, (field) => {
                    return field.EntityTypeName === TABLE_TYPES.Entity
                });

                if (!fieldModel) {
                    fieldModel = _.find(this._control.GetModel().Fields, (field) => {
                        return field.EntityTypeName === TABLE_TYPES.Sub
                    });
                }

                if (fieldModel) {
                    gridSubjectEntityId = fieldModel.EntityId;
                }
            }

          return gridSubjectEntityId;
        }
    }

	GetTemplateName(): string {
		return 'Core/GeneralProperties/Managers/CardScreenProperty/Templates/Template';
	}
}