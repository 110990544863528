import {DesignedPortletSpace} from "Core/Portlets/Models/Store/DesignedPortletSpace";

export class PortletSpace {
    ObjectId: number;
    Name: string;
    IsMain: boolean;
    Memo: string;

    static Map(userPortletSpace: DesignedPortletSpace) : PortletSpace {
        let portletSpace = new PortletSpace();

        portletSpace.ObjectId = userPortletSpace.Id;
        portletSpace.Name = userPortletSpace.Name;
        portletSpace.IsMain = userPortletSpace.IsMain;
        portletSpace.Memo = userPortletSpace.Memo;


        return portletSpace;
    }
}
