import * as ko from "knockout"
import 'jquery'
import * as Viewer from "viewer";

import {BlockUI as BlockUi} from 'Core/Common/BlockUi';
import {LABELS} from "Core/Components/Translation/Locales";
import {ZIndexManager} from 'Core/Common/ZIndexManager';
import {MarkerArea} from "markerjs2";

export class ViewerJsExtention {
    static viewModel = null;
    static markerArea = null;
    static annotationsShown = false;
    static markerButton = null;
    static currentImage = null;

    static Init() {
        ko.bindingHandlers.viewerJs = {
            init: (element, valueAccessor, allBindings, viewModel, bindingContext) => {
                const self = this;
                this.viewModel = viewModel;
                const options = valueAccessor();
                let viewer = null;
                const template =
                    `<!--ko with: $data -->
						<!--ko foreach: Images -->
							<div class="gallery-item">
								<div class="mix mix_all" >
									<div class="mix-inner">
										<img data-bind="attr: { src: ImageData, 'data-original': OriginalImageData, 'data-id': UniqueId }, 
                                                        class: $parent.ImageClasses,
                                                        style: {'max-height': $parent.GetHeightImage}" 
										     class="max-width-50p" width="" height="" alt="No Image">
										<a class="image-open-btn" data-bind="click: $parent.ShowImage"></a>
									</div>
								</div>
							</div>
						<!-- /ko -->
					<!-- /ko -->`;

                const data = {
                    Images: options.images,
                    ImageClasses: options.imageClasses,
                    AnnotationsEnabled: options.annotationsEnabled,
                    ShowImage: (image, evt) => {
                        self.currentImage = image
                        options.getOriginalImage(image, viewer);
                    },
                    GetHeightImage: options.getHeightImage || function (){},
                    ShowAnnotations: options.showAnnotations
                };

                //if (options.template) {
                //ko.templates['Template'] = options.template;
                ko.templates['Template'] = template;
                ko.renderTemplate('Template', data, null, element);
                //}


                const opt = {
                    markerable: data.AnnotationsEnabled || false, //markerjs button for annotations available
                    button: true, // Show the button on the top-right of the viewer
                    inline: false, // Enable inline mode
                    navbar: false, // Show the navbarwer
                    title: false, // Show the title
                    toolbar: true, // Show the toolbar
                    tooltip: true, // Show the tooltip with image ratio (percentage) when zoom in or zoom out
                    movable: true, // Enable to move the image
                    zoomable: true, // Enable to zoom the image
                    rotatable: true, // Enable to rotate the image
                    scalable: true, // Enable to scale the image
                    transition: true, // Enable CSS3 Transition for some special elements
                    fullscreen: true, // Enable to request fullscreen when play
                    keyboard: true, // Enable keyboard support
                    url: 'data-original',
                    zIndex: ZIndexManager.Instance.NextValue,
                    filter() { // Initiate correct array for non loaded original images
                        return true;
                    },
                    marker() {
                        let annotations = self.viewModel.Files[viewer.index].Annotations || self.currentImage.Annotations;

                        if (annotations && opt.markerable) {
                            if (data.ShowAnnotations) {
                                self.DisableViewerControls(viewer);

                                self.markerArea = new MarkerArea(viewer.image);
                                self.markerButton = viewer.image.parentNode.parentNode.getElementsByClassName("viewer-marker")[0];
                                self.markerArea.uiStyleSettings.editingControlsInactive = true;
                                self.markerArea.uiStyleSettings.zIndex = ZIndexManager.Instance.NextValue;
                                if (self.markerButton) {
                                    self.markerButton.classList.add("disabled");
                                    self.markerButton.style.pointerEvents = "none";
                                }
                                self.markerArea.addCloseEventListener(() => {
                                    self.EnableViewerControls(viewer);
                                    self.annotationsShown = false;
                                    self.markerButton.classList.remove("disabled");
                                    self.markerButton.style.pointerEvents = "auto";
                                });

                                self.markerArea.show(true);
                                self.annotationsShown = true;

                                self.markerArea.restoreState(JSON.parse(annotations));
                            } else {

                                if (self.annotationsShown) {
                                    self.DisableViewerControls(viewer);

                                    self.markerArea = new MarkerArea(viewer.image);
                                    self.markerButton = viewer.image.parentNode.parentNode.getElementsByClassName("viewer-marker")[0];
                                    self.markerArea.uiStyleSettings.editingControlsInactive = true;
                                    self.markerArea.uiStyleSettings.zIndex = ZIndexManager.Instance.NextValue;
                                    if (self.markerButton) {
                                        self.markerButton.classList.add("disabled");
                                        self.markerButton.style.pointerEvents = "none";
                                    }
                                    self.markerArea.addCloseEventListener(() => {
                                        self.EnableViewerControls(viewer);
                                        self.annotationsShown = true;
                                        self.markerButton.classList.remove("disabled");
                                        self.markerButton.style.pointerEvents = "auto";
                                    });

                                    self.markerArea.show(true);
                                    self.annotationsShown = false;

                                    self.markerArea.restoreState(JSON.parse(annotations));

                                    return;
                                }

                                self.annotationsShown = true;
                            }
                        }
                    },
                    view(e) {
                        let annotations = self.viewModel.Files[e.detail.index].Annotations || self.currentImage.Annotations;
                        if (annotations == null) {
                            viewer.options.markerable = false;
                        } else {
                            viewer.options.markerable = true;
                        }
                    },
                    viewed() {
                        if (!self.annotationsShown && opt.markerable) {
                            opt.marker();

                            if (self.markerButton) {
                                self.markerButton.classList.add("disabled");
                                self.markerButton.style.pointerEvents = "none";
                            }
                        }
                    },
                    hide() {
                        if (self.markerArea) {
                            self.markerArea.close(true);
                        }

                        if (data.ShowAnnotations){
                            self.annotationsShown = false;
                        } else {
                            self.annotationsShown = true;
                        }
                    }
                };

                viewer = new Viewer(element, opt);

                viewer.next = () => {
                    if (viewer.index + 1 < options.images.length) {

                        let image = options.images[viewer.index + 1];
                        if (image.OriginalImageData == null) {
                            BlockUi.Block();
                            options.getOriginalImage(image, viewer, true);
                        } else {
                            viewer.view(Math.min(viewer.index + 1, viewer.length - 1));
                        }
                    }
                }
                viewer.prev = () => {
                    if (viewer.index > 0) {

                        let image = options.images[viewer.index - 1];
                        if (image.OriginalImageData == null) {
                            BlockUi.Block();
                            options.getOriginalImage(image, viewer, false);
                        } else {
                            viewer.view(Math.max(viewer.index - 1, 0));
                        }
                    }
                }
                viewer.clickOnImage = (imageId: number, direction: boolean) => {

                    BlockUi.Unblock();
                    const im = $('body').find('.mix-inner').find("img[data-id=" + imageId + "]");
                    viewer.hide(); // hide viewer when image was changed;
                    if (im) {
                        im.click();
                    }
                    self.CustomTooltip();
                }
                return {controlsDescendantBindings: true};
            }
        };
    }

    static CustomTooltip() {
        let toolbar = $('body').find('.viewer-toolbar');

        if (toolbar.length != 0) {
            this.BootstrapTooltip(toolbar.find('.viewer-zoom-in'), LABELS.ZOOM_IN);
            this.BootstrapTooltip(toolbar.find('.viewer-zoom-out'), LABELS.ZOOM_OUT);
            this.BootstrapTooltip(toolbar.find('.viewer-one-to-one'), LABELS.ACTUAL_SIZE);
            this.BootstrapTooltip(toolbar.find('.viewer-reset'), LABELS.ROTATE_FLIP);
            this.BootstrapTooltip(toolbar.find('.viewer-prev'), LABELS.PREVIOUS);
            this.BootstrapTooltip(toolbar.find('.viewer-next'), LABELS.NEXT);
            this.BootstrapTooltip(toolbar.find('.viewer-rotate-left'), LABELS.ROTATE_LEFT);
            this.BootstrapTooltip(toolbar.find('.viewer-rotate-right'), LABELS.ROTATE_RIGHT);
            this.BootstrapTooltip(toolbar.find('.viewer-flip-horizontal'), LABELS.FLIP_HORIZONTALLY);
            this.BootstrapTooltip(toolbar.find('.viewer-flip-vertical'), LABELS.FLIP_VERTICALLY);
            this.BootstrapTooltip(toolbar.find('.viewer-marker'), LABELS.ANNOTATIONS);
        }
    }

    static BootstrapTooltip(element: JQuery, text: string) {
        let options = {
            container: 'body',
            template: '<div class="tooltip customZIndex" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
        };

        let tooltipElement = $(element)
            .attr('data-toggle', 'tooltip')
            .attr('title', text);
        tooltipElement.tooltip(options);
    }

    static DisableViewerControls(viewer: Viewer) {
        viewer.options.movable = false;
        viewer.options.zoomable = false;
        viewer.options.rotatable = false;
        viewer.options.flippable = false;
    }

    static EnableViewerControls(viewer: Viewer) {
        viewer.options.movable = true;
        viewer.options.zoomable = true;
        viewer.options.rotatable = true;
        viewer.options.flippable = true;
    }
}
