import * as ko from 'knockout';
import {PASSWORD} from 'Core/Constant';
import {NOTIFICATIONS} from "Core/Components/Translation/Locales";

export class PasswordExpiredModel {
	FormTitle: KnockoutObservable<string>;
	OldPassword: KnockoutObservable<string>;
	NewPassword: KnockoutObservable<string>;
	PasswordConfirmation: KnockoutObservable<string>;
	Error: KnockoutObservable<string>;

	constructor() {
		this.FormTitle = ko.observable(null);
		this.OldPassword = ko.observable(null).extend({
			required: {
				message: NOTIFICATIONS.ENTER_CURRENT_PASSWORD
			}
		});
		this.NewPassword = ko.observable(null).extend({
			required: {
				message: NOTIFICATIONS.ENTER_NEW_PASSWORD
			},
			minLength: {
				params: PASSWORD.MinimumLength,
				message: NOTIFICATIONS.ENTER_AT_LEAST_MORE_CHARACTERS.replace('{minLength}', `${PASSWORD.MinimumLength}`)
			}
		});
		this.PasswordConfirmation = ko.observable(null).extend({
			required: {
				message: NOTIFICATIONS.CONFIRM_NEW_PASSWORD
			},
			minLength: {
				params: PASSWORD.MinimumLength,
				message: NOTIFICATIONS.ENTER_AT_LEAST_MORE_CHARACTERS.replace('{minLength}', `${PASSWORD.MinimumLength}`)
			},
			areSame: {
				params: this.NewPassword,
				message: NOTIFICATIONS.PASSWORDS_ARE_NOT_SAME
			}
		});
		this.Error = ko.observable(null);
	}
}