import * as ko from 'knockout';
import { deserialize, deserializeAs } from 'libs/cerialize';
import { TagModel } from "Core/Controls/Kanban/Models/TagModel";
import { IconModel } from "Core/Controls/BaseControl/Models/IconModel";
import { TimerModel } from "Core/Controls/Timer/Models/TimerModel";

export class UserModel {

	@deserialize
	UserId: number;

	@deserialize
	ContactId: number;

	@deserialize
	UserName: string;

	@deserialize
	UserTypeId: number;

	@deserialize
	UserTypeName: string;

	@deserialize
	IsOwner: boolean;
}

export class MainLink {

	@deserialize
	Icon: IconModel;

	@deserialize
	RecordId: number;

	@deserialize
	RecordName: string;

	@deserialize
	TableId: number;
}

export class PriorityModel {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	TranslatedName: string;

	@deserialize
	Color: string;
}

export class CardThumbnailModel {
	@deserialize
	Id: number;

	@deserialize
	Image: string;

	@deserialize
	FieldId: number;
}

export class ParentOrChildRecordModel {
	@deserialize
	Id: number;

	@deserialize
	IsChild: boolean;

	@deserialize
	IsParent: boolean;

	@deserialize
	Name: string;

	@deserialize
	NameTranslation: string;

	@deserialize
	TypeName: string;

	@deserialize
	TypeNameTranslation: string;
}

export class ActionModel {
	constructor() {
		this.Users = [];
		this.HighlightedAction = null;
		this.Tags = [];
		this.IsDisabledCard = null;
		this.NewActiveTimer = null;
	}
	NewActiveTimer: TimerModel;

	IsDisabledCard: boolean;

	HighlightedAction: boolean;

	@deserializeAs(UserModel)
	Users: Array<UserModel>;

	@deserializeAs(TagModel)
	Tags: Array<TagModel>;

	@deserialize
	ActionName: string;

	@deserialize
	ActionsEntityId: number;

	@deserialize
	ActionRecordId: number;

	@deserialize
	ActionTypeId: number;

	@deserialize
	ActionTypeName: string;

	@deserialize
	ActionLifestatusId: number;

	@deserialize
	ActionMemo: string;

	@deserialize
	ActionDate: string;

	@deserialize
	ActionDeadline: string;

	@deserialize
	IsDisabled: boolean;

	@deserialize
	DisabledReason: string;

	@deserializeAs(PriorityModel)
	Priority: PriorityModel;

	@deserializeAs(CardThumbnailModel)
	Thumbnail: CardThumbnailModel;

	@deserialize
	MailsCount: number;

	@deserializeAs(TimerModel)
	ActiveTimer: TimerModel;

	@deserialize
	Color: string;

	@deserialize
	Warnings: string[];

	@deserialize
	ActionAlias: string;

	set NewActiveTimerModel(timer: TimerModel) {
		this.NewActiveTimer = timer;
	}

	get ReplaceActionMemoText(): string {
		if (this.ActionMemo){
			//Convert "\r\" to "<br>" and remove the first and last "<br>"
			return this.ActionMemo.replace(/(?:\r\n|\r|\n)/g, '<br>').replace(/^(?:<br>)+|(?:<br>)+$/g, "");
		} else {
			return null
		}
	}
}

export class RecordModel {
	AriaExpanded: KnockoutObservable<boolean>

	constructor() {
		this.Users = [];
		this.AriaExpanded = ko.observable(true);
		this.IsDisabledCard = null;
	}

	ToggleAccordion(data){
		data.record.AriaExpanded(!data.record.AriaExpanded());
	}

	IsDisabledCard: boolean;

	@deserialize
	EntityId: number;

	@deserialize
	LifestatusId: number;

	@deserialize
	LifestatusName: string;

	@deserialize
	LifeStatusTranslatedName: string;

	@deserialize
	Name: string;

	@deserialize
	LinkName: string;

	@deserialize
	Memo: string;

	@deserialize
	RecordId: number;

	@deserialize
	TypeId: number;

	@deserialize
	TypeName: string;

	@deserialize
	IsDisabled: boolean;

	@deserialize
	DisabledReason: string;

	@deserialize
	ActionsEntityId: number;

	@deserializeAs(UserModel)
	Users: Array<UserModel>;

	@deserializeAs(TagModel)
	Tags: Array<TagModel>;

	@deserialize
	HasAction: boolean;

	@deserializeAs(ActionModel)
	Actions: Array<ActionModel>;

	@deserializeAs(MainLink)
	MainLinks: Array<MainLink>;

	@deserializeAs(PriorityModel)
	Priority: PriorityModel;

	@deserializeAs(CardThumbnailModel)
	Thumbnail: CardThumbnailModel;

	@deserialize
	MailsCount: number;

	@deserialize
	Color: string;

	@deserialize
	RetiredTodoActionsNumber: number;

	@deserialize
	TotalTodoActionsNumber: number;

	@deserialize
	Warnings: string[];

	@deserializeAs(ParentOrChildRecordModel)
	ParentOrChildRecords: Array<ParentOrChildRecordModel>;

	@deserialize
	Alias: string;

	get ReplaceMemoText(): string {
		if (this.Memo){
			//Convert "\r\" to "<br>" and remove the first and last "<br>"
			return this.Memo.replace(/(?:\r\n|\r|\n)/g, '<br>').replace(/^(?:<br>)+|(?:<br>)+$/g, "");
		} else {
			return null
		}
	}
}

export class ActionItemModel {

	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	NameTranslation: string;

	@deserialize
	TypeId: number;

	@deserialize
	IsRequired: boolean;

	@deserialize
	Sort: number;

	@deserialize
	State: number;
}

export class StepModel {
	constructor(init?: Partial<StepModel>) {
		if (init) {
			this.Label = init.Label;
		}
		this.Records = [];
	}

	@deserialize
	Id: number;

	@deserialize
    IsRetired: boolean;

    @deserialize
    MultipleActions: boolean;

	@deserializeAs(ActionItemModel)
	ActionItems: Array<ActionItemModel>;

	@deserialize
	Label: string;

	@deserialize
	Sort: number;

	@deserialize
	UserId: number;

	Records: Array<RecordModel>;

	@deserialize
	Enabled: boolean;

	@deserialize
	Memo: string;

	@deserialize
	MemoTranslation: string;

	@deserialize
	TranslatedName: string;

	Copy() {
		const copy = new StepModel();

		copy.Id = this.Id;
		copy.Label = this.Label;
		copy.Enabled = this.Enabled;
		copy.IsRetired = this.IsRetired;
		copy.UserId = this.UserId;
		copy.Sort = this.Sort;
		copy.Records = [...this.Records];
        copy.TranslatedName = this.TranslatedName;
        copy.MultipleActions = this.MultipleActions;
        copy.ActionItems = [...this.ActionItems];

		return copy;
	}
}

export class ThumbnailModel {
	@deserialize
	Id: number;

	@deserialize
	Image: string;
}

export class LinkModel {
	@deserialize
	Id: number;

	@deserialize
	IdStepFrom: number;

	@deserialize
	IdStepTo: number;

	@deserialize
	Label: string;

	@deserialize
	UserId: number;
}

export class StatusModel {
	@deserializeAs(RecordModel)
	Records: Array<RecordModel>;

	@deserializeAs(StepModel)
	Steps: Array<StepModel>;

	@deserializeAs(ThumbnailModel)
	Thumbnails: Array<ThumbnailModel>;

	@deserializeAs(LinkModel)
	Links: Array<LinkModel>;

	@deserializeAs(PriorityModel)
	Priorities: Array<PriorityModel>;

	@deserialize
	HasTodoType: boolean;

	@deserialize
	IsTodoEnabled: boolean;

	@deserialize
	IsTypeHierarchyCorrect: boolean;

	@deserialize
	MailEntityId: number;
}