import * as ko from "knockout";
import * as moment from "moment";
import "BootstrapTimeExtension";

import {ColumnEditor} from "LookupEditor/ColumnEditors/Base/ColumnEditor";
import {IValueUpdater} from "LookupEditor/Interfaces/IValueUpdater";
import {DATE_FORMATS} from "Core/Constants/DateTimeFormats";
import {IColumnValue} from "LookupEditor/Interfaces/IColumnValue";
import {FormatConverter} from "FormatEditor/FormatConverter";

import ColumnTemplate from "LookupEditor/Templates/Columns/Time.html";
ko.templates["LookupEditor/Templates/Columns/Time"] = ColumnTemplate;

export class TimeSpanColumnEditor extends ColumnEditor {
    format:string = DATE_FORMATS.TIME_SPAN.Format;

    Show(): void { }

    GetTemplateName(): string {
        return 'LookupEditor/Templates/Columns/Time';
    }

    AfterRender(el, owner: IValueUpdater, columnName): void {
        ($.fn.editable as any).defaults.mode = 'inline';
        $(el).editable({
            type: 'timePickerBootstrap',
            value: {
            	default: '',
                current: owner.GetValue(columnName) ? owner.GetValue(columnName) : ''
            },
            timePickerBootstrap: {
                locale: moment.locale(),
                format: this.format,
                widgetPositioning: {
                    horizontal: 'right',
                    vertical: 'bottom',
                    forceParse: false
                }
            },
            success: (response, newValue) => {
                const value: IColumnValue = {
                    Name: columnName,
                    Value: newValue.current
                };
                owner.UpdateValue(value);
            }
        });
    }

    ToString() {
        return "TimeSpan";
    }
} 