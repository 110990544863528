import { deserialize, deserializeAs } from 'libs/cerialize';
import { RegisteredLock } from "./RegisteredLock";

export class RegisteredLocks {
    constructor() {
        this.Successful = [];
    }

    @deserializeAs(RegisteredLock)
    Successful: RegisteredLock[];
}