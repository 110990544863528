import * as ko from 'knockout';
import * as _ from 'underscore';

import {BaseControl, IControlValue} from 'Core/Controls/BaseControl/BaseControl';
import {IControlParam} from 'Core/Screens/IScreen';
import {MultiSelectListModel, ItemModel} from 'Core/Controls/MultiSelect/Models/MultiSelectListModel';
import {BlockUI} from 'Core/Common/BlockUi';
import {
    IGetMultiSelectControlDataRequestModel,
    MultiSelectDataStore
} from 'Core/Controls/MultiSelect/Stores/MultiSelectDataStore';
import {Notifier} from 'Core/Common/Notifier';
import {GlobalManager, GLOBALS} from 'Core/GlobalManager/GlobalManager';

import ViewTemplate from 'Core/Controls/MultiSelect/Templates/View.html';
import HelpViewTemplate from 'Core/Controls/MultiSelect/Templates/HelpView.html';
import EditTemplate from 'Core/Controls/MultiSelect/Templates/Edit.html';
import ToolBarTemplate from 'Core/Controls/MultiSelect/Templates/ToolBar.html';
import DesignTemplate from 'Core/Controls/MultiSelect/Templates/Design.html';
import DropDownContentTemplate from 'Core/Controls/MultiSelect/Templates/DropDownContent.html';
import {CONTROL_TYPES, FIELD_TYPES, FONT_STYLES, RenderModes, TABLE_TYPES} from 'Core/Constant';
import {SearchScreen, ISelectedRecord} from 'Core/Screens/SearchScreen/SearchScreen';
import {TypeScreen} from "Core/Screens/TypeScreen/TypeScreen";
import {EditScreen} from "Core/Screens/EditScreen/EditScreen";
import {NOTIFICATIONS} from "Core/Components/Translation/Locales";
import {LockManager} from "Core/Components/Locker/LockManager";
import {JBoxDropDown} from "Core/Components/JBoxDropdown/DropDown";
import {ScreenTypes} from "../../Common/Enums/ScreenTypes";
import {ZIndexManager} from "Core/Common/ZIndexManager";
import {FieldFormat} from "Core/Common/FieldFormat";
import {FormatConverter} from "FormatEditor/FormatConverter";
import { EVENTS as SCREEN_EVENTS } from 'Core/Screens/Events';

import MultiSelectConfig from 'Core/Controls/MultiSelect/Configs/multiselect-config.json';

import {GeneralProperties} from "../../GeneralProperties/GeneralProperties";
import {ConditionBuilder} from "../../../QueryBuilder/QueryCondition/ConditionBuilder/ConditionBuilder";
import {GenericDeserialize} from "../../../libs/cerialize";
import {QueryConditionGroupModel} from "../Grid/Models/GridDataModel/QueryExpression/QueryConditionGroup";
import { IScreenVariable } from 'Core/Screens/BaseScreen';

ko.templates['Core/Controls/MultiSelect/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/MultiSelect/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/MultiSelect/Templates/Edit'] = EditTemplate;
ko.templates['Core/Controls/MultiSelect/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/MultiSelect/Templates/Design'] = DesignTemplate;

export class MultiSelect extends BaseControl {
    Items: KnockoutObservableArray<ItemModel>;
    SelectedItems: KnockoutObservableArray<ItemModel>;
    private _searchScreenIfRecordsCount: number;
    private _separator: string;
    private _labelStyle: KnockoutObservable<any>;
    private _textInputStyle: KnockoutObservable<any>;
    private _showSearchScreen: KnockoutObservable<boolean>;
    private _searchScreen: SearchScreen;
    private _value: Array<number>;
    private _originalValue: Array<number>;
    private _totalRecordsCount: number;
    private _anySelectedItems: KnockoutComputed<boolean>;
    private _tooltip: JBoxDropDown;
    private _showMultiSelectText: KnockoutObservable<boolean>;
    private _conditions: QueryConditionGroupModel;

    constructor(params: IControlParam) {
        super(params, MultiSelectConfig);
        
        this.Items = ko.observableArray([]);
        this._separator = ';';
        this.SelectedItems = ko.observableArray([]);
        this._labelStyle = ko.observable(null);
        this._textInputStyle = ko.observable(null);
        this._showSearchScreen = ko.observable(false);
        this._value = [];
        this._originalValue = [];
        this._totalRecordsCount = 0;
        this._showMultiSelectText = ko.observable(true);
        this._conditions = null;

        this._anySelectedItems = ko.computed(() => this.SelectedItems().length > 0, this);

        this.Init();
        this.ApplyProperties();

        var searchScreenIfRecordsCount = parseInt(GlobalManager.Instance.GetGlobal(GLOBALS.SEARCH_SCREEN_IF_RECORDS_COUNT));
        this._searchScreenIfRecordsCount = Number.isNaN(searchScreenIfRecordsCount) ? 10 : searchScreenIfRecordsCount;
    }

    ApplyProperties() {

        if (this.Properties) {
            if (this.Properties.Label) {
                var labelStyle = {backgroundColor: null, color: null};
                _.each(this.Properties.Label.Properties, (property: any) => {
                    if (property.BackgroundColor) {
                        labelStyle.backgroundColor = property.BackgroundColor;
                    }

                    if (property.Color) {
                        labelStyle.color = property.Color;
                    }
                });

                this._labelStyle(labelStyle);
            }
            if (this.Properties.TextInput) {
                var textInputStyle = {backgroundColor: null};
                _.each(this.Properties.TextInput.Properties, (property: any) => {
                    if (property.BackgroundColor) {
                        textInputStyle.backgroundColor = property.BackgroundColor;
                    }
                });

                this._textInputStyle(textInputStyle);
            }

            //Condition
            if (this.Properties.Condition) {
                let condition = _.find(this.Properties.Condition.Properties, (item) => item.hasOwnProperty('QueryCondition')) as any;

                if (condition && condition.QueryCondition) {
                    let jsonObj = JSON.parse(condition.QueryCondition);
                    let conditions = GenericDeserialize<QueryConditionGroupModel>(jsonObj, QueryConditionGroupModel);
                    this._conditions = conditions;
                }
            }
        }
        if (this._form && (this._form.GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.LinkEditor])) {
            this.ApplyLinkEditorStyles();
        }
    }

    private ApplyLinkEditorStyles() {
        if (!this.FieldModel.HasLinkEditorVisibility) {
            return;
        }
        const labelStyle = {
            color: null,
            fontWeight: null,
            fontStyle: null,
            textDecoration: null
        };
        if (this.FieldModel.FontColor) {
            labelStyle.color = this.FieldModel.FontColor;
        }
        labelStyle.fontWeight = FONT_STYLES.NORMAL; //default fontWeight

        if (this.FieldModel.FontStyles) {
            _.forEach(this.FieldModel.FontStyles, (style) => {

                switch (style.Name.toLowerCase()) {
                    case FONT_STYLES.BOLD:
                        labelStyle.fontWeight = FONT_STYLES.BOLD;
                        break;
                    case FONT_STYLES.UNDERLINE:
                        labelStyle.textDecoration = FONT_STYLES.UNDERLINE;
                        break;
                    case FONT_STYLES.ITALIC:
                        labelStyle.fontStyle = FONT_STYLES.ITALIC;
                        break;
                }
            })
        }

        this.Extend(labelStyle, this._labelStyle());
        this._labelStyle(labelStyle);
    }

    ShowListSearchScreen(model, evt: MouseEvent) {
        if (this._totalRecordsCount > this._searchScreenIfRecordsCount) {
            evt.stopPropagation();
            evt.preventDefault();

            let attachedFieldModel = this.FieldModel;

            if (attachedFieldModel && attachedFieldModel.ValTableId) {
                this._searchScreen = new SearchScreen({
                    EntityId: attachedFieldModel.ValTableId,
                    ControlId: this.GetControlId(),
                    ScreenData: this._form && this.GetDynamicFieldsData(),
                    SearchTerm: '',
                    ButtonAdd: attachedFieldModel.ValTableType !== TABLE_TYPES.Lookup,
                    MultiSelectMode: true,
					SelectedRecordIds: _.map(this.SelectedItems(), (item) => item.RecordId)
                });

                this._searchScreen.On('RECORD_SELECTED', this, (evtArgs) => {
                    if (this._value.indexOf(evtArgs.data.RecordId) < 0) {
                        this._value.push(evtArgs.data.RecordId);
                    }
                    this.LoadData(this._value);
                });

                this._searchScreen.On('RECORDS_SELECTED', this, (eventArgs) => {
                    let selectedRecordIds = eventArgs.data.Ids;

                    if (!_.isEqual([...this._value].sort(), selectedRecordIds.sort())) {
                        this._value = selectedRecordIds;
                        this.LoadData(this._value);
                    }
                });
            }

            this._searchScreen.On('NEW_RECORD', this, (eventArgs) => {
                const typeScreen = new TypeScreen(attachedFieldModel.ValTableId);

                typeScreen.On('TYPES_NOT_FOUND', this, (eventArgs) => new Notifier().Warning(eventArgs.data.Message || NOTIFICATIONS.TYPES_NOT_FOUND));

                typeScreen.On('TYPES_FOUND', this, (eventArgs) => this._searchScreen.Cancel());

                typeScreen.On('TYPE_SELECTED', this, (eventArgs) => {
                    const typeId = eventArgs.data.TypeId;
                    const kindId = eventArgs.data.KindId;
                    const exampleRecordId = eventArgs.data.ExampleRecordId;
                    this.NewRecord(typeId, kindId, exampleRecordId, attachedFieldModel.ValTableId);
                });

                typeScreen.Show();
            });
            this._searchScreen.Show();
        } else {
            if (this.Items().length > 0 && !this.GetCombinedReadOnly()) {

                if (!this._tooltip) {
                    const target: any = evt.currentTarget;
                    this._tooltip = new JBoxDropDown({
                        bindComponent: this,
                        target: target,
                        otherOptions: {
                            addClass: "multi-select-dropdown",
                            attach: undefined,
                            pointer: "",
                            isolateScroll: true,
                            content: DropDownContentTemplate as any,
                            position: {
                                x: "right",
                                y: "bottom"
                            },
                            height: 'auto',
                            zIndex: ZIndexManager.Instance.NextValue,
                            maxHeight: 200
                        },
                        bindTarget: target,
                        bindOnCreate: true
                    });
                }

                this._tooltip.Toggle();
            }
        }
    }

    private Init() {
        if(this.IsRunTime){
            this.Screen.On(SCREEN_EVENTS.DATA_CHANGED, this, (eventArgs) => {
                let screenData: IScreenVariable = eventArgs.data.ScreenVariable;
                let screenVariableCondition = ConditionBuilder.GetScreenVariableCondition(screenData, this._conditions);
                if (screenVariableCondition.length > 0) {
                    this.SetConditionScreenData(screenVariableCondition, screenData.Value);
                    this.LoadData([]);
                }
            });

            this.InitChangeEvent();
        }
    }

    private async NewRecord(tableTypeId: number, kindId: number, exampleRecordId: number, entityId: number) {
        
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

        screenManager.GetEditScreen({
            EntityId: entityId,
            TableTypeId: tableTypeId,
            KindId: kindId,
            RecordId: exampleRecordId,
            LoadAsExample: true
        })
            .then((screen: EditScreen) => {
                if (!screen) {
                    new Notifier($(this._el)).Warning(NOTIFICATIONS.EDIT_SCREEN_NOT_EXISTS);
                    return;
                }
                const editScreen = screen;

                editScreen.IsDataFromExample = exampleRecordId > 0;
                editScreen.UseLinking = true;

                screen.On('RECORD_SAVED', this, (eventArgs) => {
                    const recordId = eventArgs.data.RecordId;

                    const notifier = new Notifier($(this._el));
                    notifier.Success(NOTIFICATIONS.RECORD_CREATED);

                    this._value.push(recordId);

                    this.SetValue({
                        SubjectEntityId: this.FieldModel.ValTableId,
                        SubjectRecordId: recordId,
                        RecordSpecsModel: null
                    });
                });

                screen.ShowInModal();
            });
    }

    SetValue(value: IControlValue): void {
        if (value.Data && value.Data.Value) {
            this._value = this.ParseOptionsValue(value.Data.Value);
            this._originalValue = _.map(this._value, val => val);
        }

        if (this._renderMode() === RenderModes.Edit) {
            this.LoadData(this._value);
        } else {
            if (value.Data && (value.Data.DisplayValue || value.Data.DisplayValue === "")) {
                var selectedItemsDisplayValue = this.ParseOptionsLabels(value.Data.DisplayValue);
                var selectedItems = [];
                _.each(selectedItemsDisplayValue,
                    item => {
                        var selectedItem = new ItemModel();
                        selectedItem.Label = item;
                        selectedItems.push(selectedItem);
                    });
                this.SelectedItems(selectedItems);
                if (selectedItems.length == 0) {
                    this._showMultiSelectText(true);
                } else {
                    this._showMultiSelectText(false);
                }
            }
        }
    }

    InitChangeEvent() {
        this.SelectedItems.subscribe((newValue) => {
            var ids = _.map(newValue, (value) => value.RecordId);
            this.UpdateVariable({ Field: this.GetFieldModel(), ControlType: CONTROL_TYPES.MultiSelect }, ids.join(';'));
        });
    }

    FormatValue(value: string) {
        if (!value) {
            return this._labels.EMPTY_VALUE;
        }

        if (_.contains([FIELD_TYPES.Decimal, FIELD_TYPES.Integer], this.FieldModel.ValFieldTypeName)) {
            return FormatConverter.LocalizeDecimalOrInteger(
                this.FieldModel.ValFieldFormatName === FieldFormat.PERCENTAGE
                    ? (Number(value.replace(/[.,]/g, '.')) * 100).toFixed(
                        this.FieldModel.ValFieldSize < 2 ? 0 : this.FieldModel.ValFieldSize - 2
                    )
                    : value
            );
        }

        return value;
    }

    ParseOptionsValue(selectedOptionsString: string): Array<number> {
        if (selectedOptionsString) {
            return selectedOptionsString.split(this._separator)
                .map(id => parseInt(id));
        }

        return [];
    }

    ParseOptionsLabels(selectedOptionsString: string): Array<string> {
        if (selectedOptionsString) {
            return selectedOptionsString.split(this._separator);
        } else if (selectedOptionsString === "") {
            return [""];
        }

        return [];
    }

    private LoadData(recordIds: Array<number>) {
        var attachedField: any = _.first(this._model().Fields);
        if (this._el) {
            BlockUI.Block({Target: this._el});
        }

        const requestData: IGetMultiSelectControlDataRequestModel = {
            FieldId: attachedField.Id,
            RecordIds: recordIds,
            ControlId: this.GetControlId(),
            ScreenData: this._form && this.GetDynamicFieldsData(),
        };

        const screen = this._form && this.GetForm().GetScreen();

        if (screen && screen.GetType() === ScreenTypes[ScreenTypes.LinkEditor]) {
            const linkEditor = screen as any;

            requestData.LeftEntityId = linkEditor.GetEntityId();
            requestData.RightEntityId = linkEditor.GetRelatedEntityId();
            requestData.LeftRecordId = linkEditor.GetRecordId();
            requestData.RightRecordId = linkEditor.GetRelatedRecordId();
        }

        MultiSelectDataStore.GetOptions(requestData)
            .always(() => {
                BlockUI.Unblock(this._el);
            })
            .then((dataModel: MultiSelectListModel) => {
                this._totalRecordsCount = dataModel.RecordsCount;
                _.each(dataModel.Items,
                    item => {
                        item.IsSelected(recordIds.indexOf(item.RecordId) >= 0);
                    });
                this.Items(dataModel.Items);
                this.UpdateSelectedItems();

                if (this._totalRecordsCount > this._searchScreenIfRecordsCount) {
                    this._showSearchScreen(true);
                } else {
                    this._showSearchScreen(false);
                }
            }).fail(error => {
            new Notifier().Failed(error.message);
        });
    }

    SelectedOptionsToString(): string {
        var selectedItems = _.filter(this.Items(), item => {
            return item.IsSelected()
        });
        var value = '';
        _.each(selectedItems, item => {
            value = value + item.RecordId + this._separator;
        });
        return value;
    }

    Deserialize() {
        let field = _.first(this._model().Fields);

        if (field) {
            if (!this.GetCombinedReadOnly()) {
                let result = this.SelectedOptionsToString();
                return [`${field.EntityName}.${field.Name}`, result];
            } else {
                return null;
            }
        }

        return null;
    }

    IsValid(): boolean {
        var selectedItems = _.filter(this.Items(), item => {
            return item.IsSelected()
        });
        if (this._isRequired) {
            this._isValid(selectedItems && selectedItems.length > 0);
        } else {
            this._isValid(true);
        }

        return this._isValid();
    }

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);
    }

    SelectItem(item: ItemModel) {
        item.IsSelected(true);
        this.UpdateSelectedItems();
        this._value.push(item.RecordId);
    }

    RemoveItem(item: ItemModel) {
        item.IsSelected(false);
        this.UpdateSelectedItems();
        this._value.splice(this._value.indexOf(item.RecordId), 1);
    }

    UpdateSelectedItems() {
        var selectedItems = [];
        _.each(this.Items(),
            item => {
                if (item.IsSelected()) {
                    selectedItems.push(item);
                }
            });
        this.SelectedItems(selectedItems);
        if (selectedItems.length == 0) {
            this._showMultiSelectText(true);
        } else {
            this._showMultiSelectText(false);
        }
    }

    GetValue(): any {
        let result = this.SelectedOptionsToString();
        return result;
    }

    IsModified(): boolean {
        let rightDifference = _.difference(this._value, this._originalValue);
        let leftDifference = _.difference(this._originalValue, this._value);

        return super.IsModified() || rightDifference.length > 0 || leftDifference.length > 0;

    }
}
