import {deserialize, deserializeAs, serialize, serializeAs} from "libs/cerialize";

export interface IRecordExampleParams {
	IsExample: boolean;
	PossibleToExample: boolean;
}

export class RecordExampleModel {
	@deserialize
	IsExample: boolean;
    
	@deserialize
	PossibleToExample: boolean;

	constructor(params?: IRecordExampleParams) {
		if (params) {
			this.IsExample = params.IsExample;
			this.PossibleToExample = params.PossibleToExample;
		}
	}
}