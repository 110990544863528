import * as ko from 'knockout';

import {AssigneeModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/AssigneeModel";
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";

export class NewThirdPartyDataRoleModel {
	Users: KnockoutObservableArray<AssigneeModel>;
	SelectedUser: KnockoutObservable<AssigneeModel>;

	FieldCollectionName: KnockoutObservable<string>;

	constructor() {
		this.Users = ko.observableArray([]);

		this.SelectedUser = ko.observable(null).extend({
			required: {
				message: LABELS.USER + ' ' + NOTIFICATIONS.IS_REQUIRED
			},
		});
		this.SelectedUser.subscribe(user => this.FieldCollectionName(user && user.Name));

		this.FieldCollectionName = ko.observable(null).extend({
			required: {
				message: LABELS.COLLECTION_NAME + ' ' + NOTIFICATIONS.IS_REQUIRED
			},
		});
	}
}