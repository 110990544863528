import * as ko from "knockout";
import * as moment from 'moment';
import { LABELS } from "Core/Components/Translation/Locales";

import {Period} from "../Models/View/SchedulerViewModel";

export interface ILinkedModel {
    GroupEntityId: number;
    ResourceId: number;
    Name: string;
    TypeId: number;
    TypeName: string;
}

export class ButtonCellEditor {
    _isAvailable: KnockoutObservable<boolean>;
    _linkedSubGroups: ILinkedModel[];
    _period: Period;
    IsDisabled: boolean;

    constructor(isAvailable: boolean, period: Period, isDisabled: boolean) {
        this._period = period;
        this._linkedSubGroups = [];
        this.IsDisabled = isDisabled || this.CheckIsAfter();
        this._isAvailable = ko.observable(isAvailable);
    }

    GetClassName(qty: number): string {
        return ' subcol-' + qty;
    }

    private CheckIsAfter():boolean {
        return moment().isAfter(this._period.Start);
    }

    private GetTooltipMessage(): string {
		return this.CheckIsAfter() ? LABELS.NO_POSSIBLE_TO_CREATE_APPOINTMENT_IN_THE_PAST : '';
    }
}