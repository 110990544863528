export const CANVAS_TYPES = {
	DW_PACKAGE: 'DWPackage',
	SOURCE: 'Source',
	TABLE: 'Table',
	PARAM: 'Param',
	DESTINATION: 'Destination',
	TRIGGER: 'Trigger',
	API_PACKAGE: 'APIPackage',
	API_METHOD: 'APIMethod',
	CSHARP_FUNCTION: 'CSharpFunction',
	JS_FUNCTION: 'JSFunction',
	GROUP: 'Group'
}