import * as ko from 'knockout';
import * as _ from 'underscore';

import { TranslationEditorStore } from 'Pages/TransaltionEditorPage/Stores/TranslationEditorStore';
import { BlockUI } from 'Core/Common/BlockUi';
import { TranslationGrid } from 'Pages/TransaltionEditorPage/TranslationEditor/TranslationGrid';
import { Notifier } from 'Core/Common/Notifier';
import { P } from "Core/Common/Promise";
import { GlobalManager, GLOBALS } from 'Core/GlobalManager/GlobalManager';
import { Paginator } from 'Core/Components/Paginator/Paginator';
import { EVENTS as PAGINATOR_EVENTS } from 'Core/Components/Paginator/Constants';
import { LABELS } from "Core/Components/Translation/Locales";

import { TranslationTypeModel } from "Pages/TransaltionEditorPage/Models/TranslationTypeModel";

import Template from 'Pages/TransaltionEditorPage/Templates/TranslationEditor.html';
import DropdownTemplate from 'Pages/TransaltionEditorPage/Templates/Dropdown.html';

ko.templates['Pages/TransaltionEditorPage/Templates/TranslationEditor'] = Template;
ko.templates['Pages/TransaltionEditorPage/Templates/Dropdown'] = DropdownTemplate;

export class TranslationEditorPage {
	private _isReady: KnockoutObservable<boolean>;
	private _el: HTMLElement;
	private _grid: TranslationGrid;
	private _paginator: Paginator;
	private _searchValue = ko.observable('');

	private _translationTypes: KnockoutObservableArray<TranslationTypeModel>;
	private _selectedTranslationType: KnockoutObservable<TranslationTypeModel>;
	private _selectedTranslationTypeId: KnockoutObservable<number>;

	private _labels = LABELS;

	constructor() {
		this._isReady = ko.observable(false);
		this._grid = new TranslationGrid();
		var otherGridPageLines = GlobalManager.Instance.GetGlobal(GLOBALS.OTHER_GRID_PAGE_LINES);
		var recordsPerpage = parseInt(otherGridPageLines);
		this._paginator = new Paginator();
		this._paginator.RecordsPerPage = isNaN(recordsPerpage) ? 10 : recordsPerpage;

		this._translationTypes = ko.observableArray([]);
		this._selectedTranslationType = ko.observable(null);
		this._selectedTranslationTypeId = ko.observable(0);

		this._paginator.On(PAGINATOR_EVENTS.CHANGE, this, (eventArgs) => {
			this.LoadTranslations(this._searchValue());
		});
	}

	Render(target): void {
		ko.cleanNode(document.getElementById(target));
		ko.applyBindings(this, document.getElementById(target));
	}

	EnterKey() {
		this._paginator.PageNumber = 1;
		this.LoadTranslations(this._searchValue());
	}

	GetTemplateName(): string {
		return 'Pages/TransaltionEditorPage/Templates/TranslationEditor';
	}

	AfterRender(el: Array<HTMLElement>) {
		if (el) {
			this._el = el[0];
		}
		this._isReady(true);
		this.LoadData();
	}

	GetDropdownTemplate() {
		return DropdownTemplate;
	}

	LoadData() {
		BlockUI.Block();
		const loadTranslationsParams = { RecordsPerPage: this._paginator.RecordsPerPage, PageNumber: this._paginator.PageNumber };

		const loadTranslationsPromise = TranslationEditorStore.GetTranslations(loadTranslationsParams);
		const loadTranslationTypesPromise = TranslationEditorStore.GetTranslationTypes();

		loadTranslationsPromise.fail(error => this.ShowError(error.message));
		loadTranslationTypesPromise.fail(error => this.ShowError(error.message));

		P.when(loadTranslationsPromise, loadTranslationTypesPromise)
			.then(result => {
				const translations = result[0];
				this._translationTypes(result[1]);

				this._grid.SetData(translations.TranslationColumns, translations.Translations);
				this._paginator.TotalRecords = translations.TotalRecords;
			})
			.always(() => BlockUI.Unblock());
	}

	LoadTranslations(searchValue: string) {
		BlockUI.Block();
		const loadTranslationsParams = { RecordsPerPage: this._paginator.RecordsPerPage, PageNumber: this._paginator.PageNumber, SearchValue: searchValue, TranslationType: this._selectedTranslationTypeId() };

		TranslationEditorStore.GetTranslations(loadTranslationsParams)
			.fail((error) => this.ShowError(error.message))
			.then((translations) => {
				this._grid.SetData(translations.TranslationColumns, translations.Translations);
				this._paginator.TotalRecords = translations.TotalRecords;
			})
			.always(() => BlockUI.Unblock());
	}

	OnSelectTranslationType(typeId) {
		this._paginator.PageNumber = 1;
		const convertedTypeId = Number(typeId);
		const selectedType = _.find(this._translationTypes(), item => item.TypeId === convertedTypeId);

		this.SetSelectedTranslationType(selectedType);
		this.LoadTranslations(this._searchValue());

	}

	ClearTranslationType() {
		this._paginator.PageNumber = 1;
		this._isReady(false);
		this.SetSelectedTranslationType(null);
		this._isReady(true);
		this.LoadTranslations(this._searchValue());
	}

	private SetSelectedTranslationType(selectedType: TranslationTypeModel) {
		this._selectedTranslationType(selectedType);
		this._selectedTranslationTypeId(!!selectedType ? selectedType.TypeId : null);
	}

	private ShowError(message: string) {
		Notifier.Failed(message);
	}

	get Grid(): TranslationGrid{
		return this._grid;
	}

	get Paginator(): Paginator {
		return this._paginator;
	}
}