import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import { BlockUI } from "Core/Common/BlockUi";
import { GetServerRequestError } from "Core/Common/Enums/ServerRequestErrors";

export interface IGetTableParams {
	TableId?: number,
	TableTypeId?: number,
	TableName?: string
}

export class TableStore {

	static Get(params: IGetTableParams): P.Promise<any> {
		const deferredResult = P.defer();
		Request.Send({
			proxy: {
				url: "api/TablesApi/Get",
				type: "GET",
				dataType: "json"
			},
			params: params
		}).then(response => deferredResult.resolve(response))
		  .fail((error) => this.RejectResponse(deferredResult, error));

		return deferredResult.promise();
	}

	static GetStruct(params: IGetTableParams) {
		const deferredResult = P.defer();
		Request.Send({
			proxy: {
				url: "api/TablesApi/GetStruct",
				type: "GET",
				dataType: "json"
			},
			params: params
		}).then(response => deferredResult.resolve(response))
			.fail((error) => this.RejectResponse(deferredResult, error));

		return deferredResult.promise();
	}

	private static RejectResponse<T>(deferredResult: P.Deferred<T>, error: P.Rejection) {
		const requestError = GetServerRequestError(error.status);
		deferredResult.reject({ message: error.message, status: error.status, requestError: requestError });
	}
}