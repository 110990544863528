import * as ko from 'knockout'
import * as _ from 'underscore';

import { BaseControl, IControlValue} from 'Core/Controls/BaseControl/BaseControl'
import { IControlParam } from 'Core/Screens/IScreen'
import { EditScreen } from 'Core/Screens/EditScreen/EditScreen';

import HelpViewTemplate from 'Core/Controls/Alias/Templates/HelpView.html'
import ViewTemplate from 'Core/Controls/Alias/Templates/View.html'
import ToolbarTemplate from 'Core/Controls/Alias/Templates/ToolBar.html'
import EditTemplate from 'Core/Controls/Alias/Templates/Edit.html'
import DesignTemplate from 'Core/Controls/Alias/Templates/Design.html'
import {ScreenTypes} from "../../Common/Enums/ScreenTypes";
import {FIELD_TYPES, FONT_STYLES, TABLE_TYPES} from "../../Constant";
import {PUB_SUB_EVENTS} from "../../../MenuManager/PubSubEvents";

ko.templates['Core/Controls/Alias/Templates/ToolBar'] = ToolbarTemplate;
ko.templates['Core/Controls/Alias/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Alias/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/Alias/Templates/Edit'] = EditTemplate;
ko.templates['Core/Controls/Alias/Templates/Design'] = DesignTemplate;

export class Alias extends BaseControl {
	private _value: string;
	private _labelStyle: KnockoutObservable<any>;
    private _isHyperlink: KnockoutObservable<boolean>;
    private _dataRecordId: number;

	constructor(params: IControlParam) {
		super(params);
		this.Init();
		this._labelStyle = ko.observable(null);
        this._isHyperlink = ko.observable(false);
		this.BindEvents();
		this._model.subscribe(() => {
			if (this.Properties) {
				this.ApplyProperties();
			}
		});
		this.ApplyProperties();
		this._value = '';
	}

	GetValue() {
		return this._value;
	}

	 ApplyProperties() {
		if (this.Properties) {
			//Label
			if (this.Properties.Label) {
				var labelStyle = { backgroundColor: null, color: null };
				_.each(this.Properties.Label.Properties, (property: any) => {
					if (property.BackgroundColor) {
						labelStyle.backgroundColor = property.BackgroundColor;
					}

					if (property.Color) {
						labelStyle.color = property.Color;
					}
				});

				this._labelStyle(labelStyle);
			}
		}

		if (this._form && (this._form.GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.LinkEditor])) {
			this.ApplyLinkEditorStyles();
		}
	}

	private ApplyLinkEditorStyles() {
		if(!this.FieldModel.HasLinkEditorVisibility){
			return;
		}
		const labelStyle = {
			color: null,
			fontWeight: null,
			fontStyle: null,
			textDecoration: null
		};
		if (this.FieldModel.FontColor) {
			labelStyle.color = this.FieldModel.FontColor;
		}
		labelStyle.fontWeight = FONT_STYLES.NORMAL; //default fontWeight

		if (this.FieldModel.FontStyles) {
			_.forEach(this.FieldModel.FontStyles, (style) => {

				switch ( style.Name.toLowerCase() ) {
					case FONT_STYLES.BOLD:
						labelStyle.fontWeight = FONT_STYLES.BOLD;
						break;
					case FONT_STYLES.UNDERLINE:
						labelStyle.textDecoration = FONT_STYLES.UNDERLINE;
						break;
					case FONT_STYLES.ITALIC:
						labelStyle.fontStyle = FONT_STYLES.ITALIC;
						break;
				}
			})
		}

		this.Extend(labelStyle, this._labelStyle());
		this._labelStyle(labelStyle);
	}

	private GetClassName(): string {
		const classes = [this.FormatName, this._isIconVisible && 'with-icon' ];
		return classes.join(' ');
	}

	SetValue(value: IControlValue): void {
		if (value.Data) {
            this._dataRecordId = value.Data.RecordId;

            let currentValue = this.ApplyAliasSuffix(value.Data.DisplayValue || value.Data.Value);
            currentValue = this.ApplyMaxLengthFormatter(currentValue);

            this._isHyperlink(
                this._form &&
                this._form.GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.ConsultScreen] &&
                this.FormatName === 'None' &&
                this.FieldModel.FieldTypeName === FIELD_TYPES.Alias &&
                this.FieldModel.EntityId !== this._form.GetScreen().GetEntityId() &&
                this.FieldModel.EntityTypeName === TABLE_TYPES.Entity &&
                !!currentValue
            );

			this._value = currentValue;
		}
	}

	ApplyAliasSuffix(value: string): string {
		if (!this._form || this._form.GetScreen().GetTypeName() !== ScreenTypes[ScreenTypes.EditScreen]) {
			return value;
		}

		const screen = this._form.GetScreen() as EditScreen;
		const aliasSuffix = screen.AliasSuffix;

		if (!aliasSuffix) {
			return value;
		}

		return `${value || ''}${aliasSuffix}`;
	}

	ApplyMaxLengthFormatter(value: string) {
		const maxFieldSize = this.GetFieldMaxLength();

		if (!value || maxFieldSize <= 0 || value.length <= maxFieldSize) {
			return value;
		}

		return value.slice(0, maxFieldSize);
	}

	GetFieldMaxLength(): number {
		const fieldData = this._model().Fields[0];
		if (!fieldData) {
			return;
		}

		const maxLength = (fieldData.FieldTypeName === FIELD_TYPES.Alias) ? fieldData.Size : -1;

		return maxLength > 0 ? maxLength : -1;
	}

	Deserialize() {
		const field = _.first(this._model().Fields);
		if (!field) {
			return null;
		}

		return [`${field.EntityName}.${field.Name}`, this._value];
	}

    OpenRecordScreen(_, data, evt) {
        PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, {EntityId: this.FieldModel.EntityId, RecordId: this._dataRecordId, IsOpenInModal: evt.ctrlKey });
    }

    private Init(): void {
	}

	private BindEvents() {

	}

	AliasValue() {
		return this._value || this.ExampleAlias;
	}

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}
}