import {Deserialize} from 'libs/cerialize';

import {P} from 'Core/Common/Promise';
import {Request} from 'Core/Common/Request';

import {TimerModel} from 'Core/Controls/Timer/Models/TimerModel';
import {StartTimerResponseModel} from 'Core/Controls/Timer/Models/StartTimerResponseModel';
import {StopTimerResponseModel} from 'Core/Controls/Timer/Models/StopTimerResponseModel';

import {NOTIFICATIONS} from 'Core/Components/Translation/Locales';

export class TimerStore {
    static GetIsTimerStarted(params: { EntityId: number; RecordId: number }) {
        const deferred = P.defer<boolean>();

        Request.Send({
            proxy: {
                url: '/api/TimerApi/GetFirstTimer',
                type: 'GET'
            },
            params
        }).then(() => {
            return deferred.resolve(true);
        }).fail(error => {
            if (error.status === 404) {
                return deferred.resolve(false);
            }

            return deferred.reject(this.GetResponseError(error, NOTIFICATIONS.ERROR_READING_TIMER_STATE));
        });

        return deferred.promise();
    }

    static GetActiveTimers() {
        const deferred = P.defer<TimerModel[]>();

        Request.Send({
            proxy: {
                url: '/api/TimerApi/GetActiveTimers',
                type: 'GET'
            }
        }).then(data => {
                const result: TimerModel[] = Deserialize(data, TimerModel);
                deferred.resolve(result);
            })
            .fail(error => deferred.reject(this.GetResponseError(error, NOTIFICATIONS.ERROR_GETTING_ACTIVE_TIMERS)));

        return deferred.promise();
    }

    static CheckActiveTimers(params: { EntityId: number; RecordId: number; ActiveTimers: string; DefaultHourkind: number }) {
        const deferred = P.defer<boolean>();

        Request.Send({
            proxy: {
                url: '/api/TimerApi/CheckActiveTimers',
                type: 'POST'
            },
            params
        }).then(data => {
            const result: boolean = Deserialize(data);
            deferred.resolve(result);
        })
            .fail(error => deferred.reject(this.GetResponseError(error, "Error checking active timers")));

        return deferred.promise();
    }

    static StartTimer(params: { EntityId: number; RecordId: number; ActiveTimers: string, DefaultHourkind: number }) {
        const deferred = P.defer<StartTimerResponseModel>();

        Request.Send({
            proxy: {
                url: '/api/TimerApi/StartTimer',
                type: 'POST'
            },
            params
        }).then((data: StartTimerResponseModel) => deferred.resolve(data))
            .fail(error => deferred.reject(this.GetResponseError(error, NOTIFICATIONS.ERROR_STARTING_TIMER)));

        return deferred.promise();
    }

    static StopTimer(params: { EntityId: number; RecordId: number }) {
        const deferred = P.defer<any>();

        Request.Send({
            proxy: {
                url: '/api/TimerApi/StopTimer',
                type: 'POST'
            },
            params
        })
            .then((data: StopTimerResponseModel) => deferred.resolve(data))
            .fail(error => deferred.reject(this.GetResponseError(error, NOTIFICATIONS.ERROR_STOPPING_TIMER)));

        return deferred.promise();
    }

    static SetOvertime(params: { Id: number; Overtime: boolean }) {
        const deferred = P.defer<any>();

        Request.Send({
            proxy: {
                url: '/api/TimerApi/SetOvertime',
                type: 'POST'
            },
            params
        }).then(data => deferred.resolve(null))
            .fail(error => deferred.reject(this.GetResponseError(error, NOTIFICATIONS.ERROR_SETTING_OVERTIME)));

        return deferred.promise();
    }

    private static GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
           return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}
