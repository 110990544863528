import {deserialize, deserializeAs, serializeAs, serialize} from 'libs/cerialize';
import {QueryEntityModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import {QueryEntityJoinModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityJoinModel';
import { QueryConditionGroupModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryConditionGroup';
import { QuerySortModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QuerySortModel';
import {GenericButtonConfigModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/GenericButtonConfigModel';

export class QueryExpressionModel {
	constructor() {
		this.EntityJoins = [];
		this.SubEntityJoins = [];
		this.LookupEntityJoins = [];
		this.ReferenceEntityJoins = [];
		this.ReferenceLookupEntityJoins = [];
		this.IsCrossTable = false;
		this.Sorts = [];
		this.GenericButtonConfigs = [];
	}

	@serialize
	@deserialize
	Top: number;

	@serializeAs(QueryEntityModel)
	@deserializeAs(QueryEntityModel)
	Entity: QueryEntityModel;

	@serializeAs(QueryEntityJoinModel)
	@deserializeAs(QueryEntityJoinModel)
	EntityJoins: Array<QueryEntityJoinModel>;

	@serializeAs(QueryEntityJoinModel)
	@deserializeAs(QueryEntityJoinModel)
	SubEntityJoins: Array<QueryEntityJoinModel>;

	@serializeAs(QueryEntityJoinModel)
	@deserializeAs(QueryEntityJoinModel)
	LookupEntityJoins: Array<QueryEntityJoinModel>;
	
	@serializeAs(QueryEntityJoinModel)
	@deserializeAs(QueryEntityJoinModel)
	ReferenceLookupEntityJoins: Array<QueryEntityJoinModel>;

	@serializeAs(QueryEntityJoinModel)
	@deserializeAs(QueryEntityJoinModel)
	ReferenceEntityJoins: Array<QueryEntityJoinModel>;

	@serializeAs(QueryConditionGroupModel)
	@deserializeAs(QueryConditionGroupModel)
	Condition: QueryConditionGroupModel;

	@serializeAs(QueryConditionGroupModel)
	@deserializeAs(QueryConditionGroupModel)
	SpimCondition: QueryConditionGroupModel;

	@serialize
	@deserialize
	IsCrossTable: boolean;

	@serialize
	@deserialize
	QueryScreenId: number;

	@serialize
	@deserialize
	CardScreenId: number;

	@serializeAs(QuerySortModel)
	@deserializeAs(QuerySortModel)
	Sorts: Array<QuerySortModel>;

	@serializeAs(GenericButtonConfigModel)
	@deserializeAs(GenericButtonConfigModel)
	GenericButtonConfigs: Array<GenericButtonConfigModel>;
}