import * as ko from "knockout"
import * as $ from 'jquery';

export class GroupedCheckboxExtention {
    static Init() {
        ko.bindingHandlers.groupedCheckbox = {
            init: (element) => {
                const $group = $(element);

                const updateGroupState = ($nestedCheckbox: JQuery) => {
                    const groupId = $nestedCheckbox.attr('data-option');
                    const $group = $('#' + groupId);
                    const $nestedCheckboxes = $('[data-option="' + groupId + '"]');

                    const allCheckboxesChecked = $nestedCheckboxes.filter(':checked').length === $nestedCheckboxes.length;
                    $group.prop('checked', allCheckboxesChecked);

                    const anyCheckboxChecked = $nestedCheckboxes.filter(':checked:first').length > 0;
                    $group.prop({indeterminate: !allCheckboxesChecked && anyCheckboxChecked});
                };

                const updateOtherCheckboxes = ($nestedCheckbox: JQuery) => {
                    const checkboxId = $nestedCheckbox.attr('checkbox-id');
                    const checkboxType = $nestedCheckbox.attr('data-type');
                    const checkboxChecked = $nestedCheckbox.is(':checked');

                    const $otherCheckboxes = $('[checkbox-id="' + checkboxId + '"][data-type="'+checkboxType+'"]');
                    $otherCheckboxes.prop('checked', checkboxChecked);

                    $otherCheckboxes.each((index, otherCheckbox) => updateGroupState($(otherCheckbox)));
                };

                $(() => {
                    const $nestedCheckboxes = $('[data-option="' + $group.attr('id') + '"]');
                    const $nestedCheckboxesChecked = $nestedCheckboxes.filter(':checked');

                    if ($nestedCheckboxesChecked.length && ($nestedCheckboxesChecked.length < $nestedCheckboxes.length)) {
                        $group.prop({indeterminate: true});
                    }

                    $group.change(() => {
                        const groupChecked = $group.is(':checked');
                        $nestedCheckboxes.prop('checked', groupChecked);
                        $nestedCheckboxes.each((index, nestedCheckbox) => updateOtherCheckboxes($(nestedCheckbox)));
                    });

                    $nestedCheckboxes.change(event => {
                        const $checkbox = $(event.target);
                        updateGroupState($checkbox);
                        updateOtherCheckboxes($checkbox);
                    });
                });
            }
        }
    }
}