import * as ko from 'knockout';

import { ColumnEditor } from "LookupEditor/ColumnEditors/Base/ColumnEditor";
import { EditableColumnModel } from "LookupEditor/Models/EditableColumnModel";
import { ColorSelector as Component } from 'Core/Components/ColorSelector/ColorSelector';

import ColorTemplate from "LookupEditor/Templates/Columns/Color.html";
import {IValueUpdater} from "../../Interfaces/IValueUpdater";
import { IColumnValue } from "../../Interfaces/IColumnValue";
import { ColorDescription } from "../../../Core/Components/ColorSelector/ColorDescription";

ko.templates["LookupEditor/Templates/Columns/Color"] = ColorTemplate;

export class ColorColumnEditor extends ColumnEditor {
	private _columnName: string;
	private _editModeEnabled: KnockoutObservable<boolean>;
	private _currentOwner: KnockoutObservable<IValueUpdater>;
	private _component: Component;
	private _value: KnockoutObservable<string>;

	constructor(column: EditableColumnModel) {
		super(column);
		this._columnName = column.Name;
		this._currentOwner = ko.observable(null);
		this._editModeEnabled = ko.computed(() => this._currentOwner() !== null, this);
		this._value = ko.observable(null);
	}

	Show(): void {
	}

	Click(owner: IValueUpdater, el: HTMLElement) {
		const value = owner.GetValue(this._columnName);
		this._value(value);
		this._component = new Component({
			Label: "",
			InitialColor: value
		});
		this._currentOwner(owner);
	}

	AfterRender(el, owner: IValueUpdater, columnName, format): void {
		this._columnName = columnName;
		const value = owner.GetValue(this._columnName);
		this._component = new Component({
			Label: "",
			InitialColor: value
		});
	}

	get CurrentOwner() {
		return this._currentOwner;
	}

	get EditModeEnabled() {
		return this._editModeEnabled;
	}

	get Value() {
		return this._value;
	}

	get Component() {
		return this._component;
	}

	SaveChanges() {
		this._currentOwner().UpdateValue({
			Name: this.column.Name,
			Value: this._component.SelectedColorName
		});

		this._currentOwner(null);
	}

	CancelChanges() {
		this._currentOwner(null);
	}

	GetTemplateName(): string {
		return "LookupEditor/Templates/Columns/Color";
	}

	ToString() {
		return "Color";
	}
}