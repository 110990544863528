import {MemoItemModel} from "./MemoItemModel";
import {MemoOperationModel} from "./MemoOperationModel";

export class FlowFolderMemosModel {
    IsMemoTranslatable: boolean;
    MemoItems: MemoItemModel[];
    FlowMemosOperations: MemoOperationModel[];

    constructor() {
        this.MemoItems = [];
        this.FlowMemosOperations = [];
    }
}