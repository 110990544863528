import { Modal } from "Core/Common/Modal";
import * as ko from "knockout";

import BoldBIDesigner from "Pages/Templates/BoldBIDesigner.html";
import EmbedDesigner from "Pages/Templates/EmbedDesigner.html";
import { BoldBISettings } from "./Models/BISettings";
import { Dashboard } from "./Models/Dashboard";
import { BIStore } from "./Stores/BIStore";
import { CookieManager } from "../../Core/Common/CookieManager";
ko.templates["Pages/Templates/BoldBIDesigner"] = BoldBIDesigner;

export class BoldBiDesignerPage
{
    private _isReady: KnockoutObservable<boolean>;
    private _dashboards: KnockoutObservableArray<Dashboard>;
    private _selectedDashboard: KnockoutObservable<Dashboard>;
    private _selectedDashboardSubscription: KnockoutSubscription;
    private _dashboardContainer: any;
    private _showEmbedLink: KnockoutObservable<boolean>;
    private _showFullScreenButton: KnockoutObservable<boolean>;
    private _isLoading: KnockoutObservable<boolean>;

    constructor(){
        this._isReady = ko.observable(true);
        this._dashboards = ko.observableArray([]);
        this._selectedDashboard = ko.observable(null);        
        this._showEmbedLink = ko.observable(false);
        this._showFullScreenButton = ko.observable(false);
        this._isLoading = ko.observable(false);
    }

    GetTemplateName(){
        return "Pages/Templates/BoldBIDesigner";
    }

    GetIFrame(elements: Array<HTMLElement>): HTMLElement{
        let result: HTMLElement = null;
        _.each(elements, el=>{
            let iFrames = $( el ).find( "iframe" );
            if(iFrames.length > 0){
                result = iFrames[0];
            }
        });
        
        return result;
    }

    AfterRender(elements: Array<HTMLElement>){        
        this._dashboardContainer = this.GetIFrame(elements);       
        this.LoadDashboardList();
       
        const listener = this.MessageListener.bind(this);

        ko.utils.domNodeDisposal.addDisposeCallback(elements[0], function() {            
            window.removeEventListener("message", listener);
        });

        window.addEventListener("message", listener);
    }

    SubscribeSelectedDashboard(){
        this._selectedDashboardSubscription = this._selectedDashboard.subscribe((newValue)=>{
            this._showEmbedLink(!!newValue?.Id);
            this.RenderDesigner();
        });
    }

    UnSubscribeSelectedDashboard(){
        this._selectedDashboardSubscription?.dispose();
    }

    MessageListener(evt){
        if(evt.data.action === 'saving'){        
           this.LoadDashboardList(false);
        }        
    }

    LoadDashboardList(isRenderDesigner: boolean = true){
        this.UnSubscribeSelectedDashboard();
        this._showFullScreenButton(true);
        this._isLoading(true);
        BIStore.GetItems()
        .always(()=>this._isLoading(false))
        .then(items=>{
            let selectedDashboardId = this._selectedDashboard()?.Id;
            this._dashboards(items);
            
            this._selectedDashboard(_.find(items,  dashboard=>
                dashboard.Id === selectedDashboardId
            ));
            this.SubscribeSelectedDashboard();
            if(isRenderDesigner){
                this.RenderDesigner();
            }            
        });
    }

    RenderDesigner(){
        BIStore.GetSettings().then(settings=>{
            let parent = this._dashboardContainer.parentNode;
            this._dashboardContainer.parentNode.removeChild(this._dashboardContainer);
            let $iframe = $(this._dashboardContainer.cloneNode());
            $iframe.appendTo(parent);
            this._dashboardContainer = $iframe[0];


            let frameDoc = this._dashboardContainer.document;

            if(this._dashboardContainer.contentWindow)
                frameDoc = this._dashboardContainer.contentWindow.document;
                    
            let template = EmbedDesigner
                .replace('{access_token}', CookieManager.GetAuthToken())
                .replace('{RootUrl}', settings.RootUrl)
                .replace('{SiteIdentifier}', settings.SiteIdentifier)
                .replace('{DashboardId}', this._selectedDashboard() && this._selectedDashboard().Id ? this._selectedDashboard().Id : '' );
                    
            frameDoc.open();
            frameDoc.writeln(template);
            frameDoc.close();
        });
    }

    Render(targetId: string){
        let element = document.getElementById(targetId);
        ko.cleanNode(element);
        ko.applyBindings(this, element);
    }

    ShowEmbedLink(){
        let modal = new Modal({ minHeight: '50px', minWidth: '400px',}, false);
        let link = `/api/BIApi/GetDashboard?dashboardId=${this._selectedDashboard().Id}`;
        modal.SetContent(`${link}`);
        modal.Show();
    }

    FullScreen(){
        if(this._dashboardContainer.requestFullscreen) {
            this._dashboardContainer.requestFullscreen();
          } else if(this._dashboardContainer.webkitrequestFullscreen) {
            this._dashboardContainer.webkitRequestFullscreen();
          } else if(this._dashboardContainer.mozRequestFullscreen) {
            this._dashboardContainer.mozRequestFullScreen();
          }
    }
}
