import { CollectionModel } from "DatabaseDesigner/Models/CollectionModel";
import { LanguageModel } from "DatabaseDesigner/Models/LanguageModel";

export function TranslationModel() {
	this._translation = "";
	this._selected = "";
	this._language = null;
};

TranslationModel.prototype.FromJSONArray = function (data) {
	var translations = new CollectionModel({ model: TranslationModel });
	_.each(data, function (translation) {
		var model = new TranslationModel();
		model.FromJSON(translation);
		translations.Add(model);
	});
	return translations;
};

TranslationModel.prototype.FromJSON = function (data) {
	this._translation = data.Translation;
	this._selected = data.Selected;
	this._language = new LanguageModel();
	this._language.FromJSON(data.Language);
};

TranslationModel.prototype.toJSON = function () {
	return {
		Translation: this._translation,
		Language: this._language.toJSON()
	};
};

Object.defineProperty(TranslationModel.prototype, 'Translation', {
	enumerable: true,
	get: function () {
		return this._translation;
	},
	set: function (val) {
		this._translation = val;
	}
});

Object.defineProperty(TranslationModel.prototype, 'Selected', {
	enumerable: true,
	get: function () {
		return this._selected;
	},
	set: function (val) {
		this._selected = val;
	}
});

Object.defineProperty(TranslationModel.prototype, 'Language', {
	enumerable: true,
	get: function () {
		return this._language;
	},
	set: function (val) {
		this._language = val;
	}
});