import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {GenericDeserialize} from 'libs/cerialize';
import { DropdownListModel } from 'Core/Controls/Dropdown/Models/DropdownListModel';
import { IDynamicFieldData } from 'Core/Common/Stores/RecordStore';
import { IConditionValueDto } from 'Core/Common/Interfaces/IConditionValueDto';

export interface IGetDropdownControlDataRequestModel {
	FieldId: number;
	RecordId?: number;
	SubjectRecordId?: number;
	SubjectTypeId?: number;
	SubjectKindId?: number;
	SubjectStatusId?: number;
	ScreenData?: Array<IDynamicFieldData>;
	ControlId?: number;
	LeftEntityId?:number;
	RightEntityId?:number;
	LeftRecordId?:number;
	RightRecordId?:number;
    KSeq?: number;
	IsEnableFilter?: boolean;

	ConditionValues?: Array<IConditionValueDto>;
};

export class DropdownDataStore {
	static Get(params: IGetDropdownControlDataRequestModel): P.Promise<DropdownListModel> {
		let deferredResult = P.defer<DropdownListModel>();

		Request
		.For(URL.CONTROLS.DROPDOWN.GET_DROPDOWN_DATA)
		.UseCacheRequest()
		.Post(params)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(this.Deserialize(data));
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static Deserialize(jsonObject: any): DropdownListModel {
		let model = GenericDeserialize<DropdownListModel>(jsonObject.ResultObject, DropdownListModel);
		model.ErrorMessage = jsonObject.ErrorMessage;
		return model;
	}
}