import * as ko from 'knockout'
import 'dropzone'

import { IControlValue } from 'Core/Controls/BaseControl/BaseControl'
import { IControlParam } from 'Core/Screens/IScreen'

import { DEFAULT_ICONS } from 'Core/Constant'
import { ComplexControl } from 'Core/Controls/ComplexControl/ComplexControl';
import { RequiredFieldModel } from 'Core/Controls/ComplexControl/Models/RequiredFieldModel';
import { FIELD_TYPES, TABLE_TYPES } from 'Core/Constant'
import {CanvasDesignerStore} from 'Core/Controls/CanvasDesigner/Stores/CanvasDesignerStore';
import {BlockUI} from 'Core/Common/BlockUi';

import { DropDataModel } from 'Core/Controls/Drop/Models/DropDataModel';
import { DropFileModel } from 'Core/Controls/Drop/Models/DropFileModel';
import {Notifier} from 'Core/Common/Notifier';

import { DropFilesModal } from 'Core/Controls/Drop/DropFilesModal';

import { ScreenTypes } from 'Core/Common/Enums/ScreenTypes';
import { EVENTS as DROP_EVENTS } from 'Core/Controls/Drop/Events';

import DesignTemplate from 'Core/Controls/Drop/Templates/Design.html'
import ViewTemplate from 'Core/Controls/Drop/Templates/View.html'
import HelpViewTemplate from 'Core/Controls/Drop/Templates/HelpView.html'
import EditTemplate from 'Core/Controls/Drop/Templates/Edit.html'
import ToolBarTemplate from 'Core/Controls/Drop/Templates/ToolBar.html'
import { LockManager, LOCK_EVENTS } from "Core/Components/Locker/LockManager";
import { BlockUI as BlockUi } from 'Core/Common/BlockUi';
import { Icon } from "Core/Icon/Icon";
import { HelpBook } from 'HelpBook/HelpBook';
import DropConfig from 'Core/Controls/Drop/Configs/drop-config.json';
import { GeneralProperties } from 'Core/GeneralProperties/GeneralProperties';
import {NOTIFICATIONS, CONFIRMATIONS, LABELS} from 'Core/Components/Translation/Locales';


ko.templates['Core/Controls/Drop/Templates/Edit'] = EditTemplate;
ko.templates['Core/Controls/Drop/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Drop/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/Drop/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Drop/Templates/Design'] = DesignTemplate;

const PROPERTIES = {
	DATA_IMPORT: "DataImport",
	CANVAS: "Canvas"
}

export class Drop extends ComplexControl {
	private _value: IControlValue;
	private _files: KnockoutObservableArray<DropFileModel>;
	private _previewFileName: KnockoutObservable<string>;
	private _previewFileImage: KnockoutObservable<string>;
	private _previewFileSize: KnockoutObservable<string>;
	private _noLabel: KnockoutObservable<boolean>;
	private _uploadingStarted: KnockoutObservable<boolean>;
	private _hasData: KnockoutObservable<boolean>;
	private _isLinkedDocumentsAmountLimitReached: KnockoutObservable<boolean>;
	private _uploadCount: number = 0;
	private _modal: DropFilesModal;
	private _hasDwPackage: boolean;
	private _maxFiles: number;
	private _acceptedFiles: string;
	private _hasRollbackData: KnockoutObservable<boolean>;
	private _destinationTypeId: number;
	private _hideModal: boolean;

	constructor(params: IControlParam) {
		super(params, DropConfig);

		this._maxFiles = null;
		this._acceptedFiles = null;
		this._previewFileName = ko.observable('');
		this._previewFileImage = ko.observable('');
		this._previewFileSize = ko.observable('');
		this._files = ko.observableArray([]);
		this._uploadingStarted = ko.observable(false);
		this._noLabel = ko.observable(params.Model.LabelPosition === 'NoLabel');
		this._hasData = ko.observable(false);
		this._isLinkedDocumentsAmountLimitReached = ko.observable(params.Model.IsLinkedDocumentsAmountLimitReached);
		this._destinationTypeId = params.Model.DestinationTypeId;

		this._hideModal = false;

		this.SetDefaultIcon(new Icon(DEFAULT_ICONS.Drop));
		this.Init();
		this._hasRollbackData = ko.observable(false);


		this.ApplyProperties();
		if(this._hasDwPackage){
			this.CheckRollback();
		}		
		LockManager.Instance.One(LOCK_EVENTS.RELEASED, this, this.CleanData);
	}

	CleanData = () => {
		this._modal && this._modal.Destroy();
		this._modal = null;
	}

	ApplyProperties() {
		const dataImport = this.GeneralProperties.GetPropertyValue(PROPERTIES.DATA_IMPORT);
        const dwPackage = this.GeneralProperties.GetPropertyValue(PROPERTIES.CANVAS);
        this._hasDwPackage = dataImport && dwPackage > 0;

		if(this._hasDwPackage){
			this._maxFiles = 1;
			this._acceptedFiles = '.xlsx';
        }

		if(this.GetForm()){
			const screenEntityId = this.GetForm().GetScreen().GetEntityId();
			if (this.DocumentEntityId === screenEntityId) {
				this._maxFiles = 1;
			}
		}
    }

	private GetDwPackageId(): number{
		return this.GeneralProperties.GetPropertyValue(PROPERTIES.CANVAS);
	}

	ExecuteRollback(){
		if (!this._hasRollbackData()){
			return;
		}
		BlockUI.Block();
		CanvasDesignerStore
			.ExecuteRollback(this.GetDwPackageId())
            .then(()=>{
                new Notifier().Success(NOTIFICATIONS.ROLLBACK_APPLIED);
                this.CheckRollback();
            })
			.always(() => {
				BlockUI.Unblock();
			}).fail(error => {
                new Notifier().Warning(error.message);
            });
	}

	CheckRollback(){
		CanvasDesignerStore
			.HasRollback(this.GetDwPackageId())
			.always(() => {
			}).then((res)=>{
                this._hasRollbackData(res);
            })
            .fail(error => {
                new Notifier().Warning(error.message);
            });
	}

	private Init(): void {
		this.AddEvent(DROP_EVENTS.DOCUMENT_UPLOADED);

		this._requiredFields([
			new RequiredFieldModel('NAME', FIELD_TYPES.Text, TABLE_TYPES.Entity, null),
			new RequiredFieldModel('F_TYPE', FIELD_TYPES.Lookup, TABLE_TYPES.Entity, null),
			new RequiredFieldModel('DOCUMENT', FIELD_TYPES.Document, TABLE_TYPES.Sub, null)
		]);

		this.InitRequiredFields();
	}

	SetValue(value: IControlValue): void {
		this._value = value;

		if (value.SubjectRecordId > 0) {
			this._hasData(true);
		}
		if (this._value.Data.Values) {
			_.each(this._value.Data.Values, (file: any) => {

				const attachment = new DropFileModel();

				attachment.Name = file.FileName;
				attachment.Base64Data = file.Content;

				this._files.push(attachment);
			});
		}
	}

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
		ko.utils.domNodeDisposal.addDisposeCallback(el[0].parentElement, this.CleanData)
	}

	Click(help, event){
		if (HelpBook.Instance.IsHelpButtonPressed()){
			event.stopImmediatePropagation();
			HelpBook.Instance.ShowControlHelp(help);
		} else if (help && help.Model && help.Model.IsLinkedDocumentsAmountLimitReached){
			event.stopImmediatePropagation();
		}
	}

	private BlockUi() {
		BlockUi.Block({ Target: this._el });
	}

	private UnBlockUi() {
		BlockUi.Unblock(this._el);
	}

	get Files(): KnockoutObservableArray<DropFileModel> {
		return this._files;
	}

	get DocumentEntityId(): number {
		return this.FieldModel && this.FieldModel.EntityId;
	}

	get DocumentSubEntityId(): number {
		let id;

		_.each(this.GetModel().Fields,
			field => {
				if (field.EntityId !== this.DocumentEntityId) {
					id = field.EntityId;
				}
			});

		return id;
	}

	GetDropZoneLabel() {
		if(this._isLinkedDocumentsAmountLimitReached()){
			return this._labels.MAXIMUM_AMOUNT_OF_LINKED_DOCUMENTS_REACHED;
		}

		return this._hasDwPackage ? this._labels.DROP_EXCEL_DOCUMENT_HERE : this._labels.DROP_DOCUMENT_HERE;
	}

	DragOver(data, element) {
		$(element).addClass('dz-drag-hover');
	}

	DragLeave(element, data) {
		$(element).removeClass('dz-drag-hover');
	}

	OpenModal(modalHide?: boolean) {
		this.CreateModal();
		if (!modalHide){
			this._modal.Open();
		}
	}

	private CreateModal(){
		if (!this._modal) {
			const screenEntityId = this.GetForm().GetScreen().GetEntityId();
			const screenSubjectTypeId = this.GetForm().GetScreen().GetTableTypeId();
			const documentTypeId = this.DocumentEntityId === screenEntityId ? screenSubjectTypeId: undefined;

			this._modal = new DropFilesModal({
				DocumentEntityId: this.DocumentEntityId,
				DocumentTypeId: documentTypeId,
				OnOkayCallBack: this.OnOkayCallBack,
				OnCloseCallBack: this.OnCloseCallBack,
				ScreenTypeName: this._form.GetScreen().GetTypeName(),
				HasDwPackage: this._hasDwPackage,
				DestinationTypeId: this._destinationTypeId
			});
		}
	}

	OnOkayCallBack = (files) => {
		this.Files(files);
	}

	OnCloseCallBack = () => {
		this._form.GetScreen().Refresh();
	}

	AfterUpload(file, data) {
		if (this._form.GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.ConsultScreen] ||
			this._form.GetScreen().GetTypeName() === ScreenTypes[ScreenTypes.EditScreen]) {
			this._uploadCount++;
			const typeId = this._destinationTypeId? this._destinationTypeId: 0
			this.UploadFile(file, typeId);
		}
	}

	public UploadFile(file: File, typeId: number, hideModal = false): Promise<void> {
		this._hideModal = hideModal;
		this.OpenModal(this._hideModal);
		const dropDataModel = new DropDataModel();

		dropDataModel.EntityId = this._value.SubjectEntityId;
		dropDataModel.EntityRecordId = this._value.SubjectRecordId;
		dropDataModel.DocumentEntityId = this.DocumentEntityId;
		dropDataModel.DocumentSubEntityId = this.DocumentSubEntityId;
		dropDataModel.ControlId = this.GetControlId();
		dropDataModel.FType = typeId;
		dropDataModel.Name = file.name;
		dropDataModel.Extension = /[^.]+$/.exec(file.name)[0];

		this._modal.AddFile(dropDataModel, file);

		if (this._hideModal){
			return this._modal.OnOkay(this._hideModal);
		}
	}

	Deserialize() {
		const fieldIds = [];

		_.each(this._model().Fields, (field) => {
			fieldIds.push({ EntityId: field.EntityId, FieldId: field.Id });
		});

		const files = [];

		_.each(this._files(), (file) => {
            files.push({ FileName: file.Name, TypeId: file.TypeId, TempName: file.TempName });
		});

		return { FieldIds: fieldIds, Files: files };
	}
}