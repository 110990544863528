import * as ko from 'knockout'
import {Event} from 'Core/Common/Event';
import {FlowFolderModel} from "Core/Components/ProgressBar/Models/Response/FlowFolderModel";
import {FlowFolderStore} from "Core/Components/ProgressBar/Stores/FlowFolderStore";
import {FlowRecordModel} from "Core/Components/ProgressBar/Models/View/FlowRecordModel";
import {IconModel} from "Core/Controls/BaseControl/Models/IconModel";
import {Icon} from "Core/Icon/Icon";
import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';
import type {IDeleteFlowRecordRequestModel} from "Core/Components/ProgressBar/Models/Request/IDeleteFlowRecordRequestModel";
import type {IUpdateFlowRecordRequestModel} from "Core/Components/ProgressBar/Models/Request/IUpdateFlowRecordRequestModel";
import {
    ConfirmationDialog, EVENTS as ConfirmationDialogEvents,
    Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import {CONFIRMATIONS, LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";
import {FONT_NAME} from "Core/Constant";

import Template from 'Core/Controls/ButtonFollowUp/Templates/FlowFolderView.html';
ko.templates['Core/Controls/ButtonFollowUp/Templates/FlowFolderView'] = Template;

export class FlowFolder extends Event{
    private _isFlowFolder: boolean;
    private _flowFolder: KnockoutObservableArray<FlowRecordModel>
    private _entityId: number;
    private _labels = LABELS;

    constructor() {
        super();
        this._isFlowFolder = null;
        this._flowFolder = ko.observableArray([]);
        this._entityId = null;

        this.Trigger('CLOSE_FOLLOW_UP_LIFE_STATUSES');
    }

    Init(isFlowFolder:boolean, entityId, flowFolderModel?: FlowFolderModel ){
        this._isFlowFolder = isFlowFolder;

        if (this._isFlowFolder){
            this._entityId = entityId;
            this.FlowFolderInit(flowFolderModel);
        }
    }

    FlowFolderInit(flowFolderModel: FlowFolderModel){
        if (!!flowFolderModel){
            _.each(flowFolderModel.FlowItems, item => {
                let flowRecordModel = new FlowRecordModel();
                flowRecordModel.EntityId = item.EntityId;
                flowRecordModel.RecordId = item.RecordId;
                flowRecordModel.TypeId = item.TypeId;
                flowRecordModel.Id = item.Id;
                flowRecordModel.Icon = this.SelectIcon(item.Icon);
                flowRecordModel.Name = item.Name;
                flowRecordModel.FlowOperationsSelectID = item.FlowOperationId;

                flowRecordModel.FlowOperations = flowFolderModel.FlowOperations;
                flowRecordModel.Init();
                this._flowFolder.push(flowRecordModel);
            })
        }

    }

    SelectIcon(model: IconModel): Icon {
        if (model && model.Id > 0) {
            return new Icon(model);
        }

        const defaultIconModel = new IconModel();

        defaultIconModel.Name = 'fa-file';
        defaultIconModel.FontName = FONT_NAME.FONT_AWESOME;
        defaultIconModel.IsIcon = true;
        defaultIconModel.IsImage = false;

        return new Icon(defaultIconModel);
    }

    Click(data, event) {
        PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, data);
        this.Close();
    }

    ChangedOperations(value, flowModel, event){
        if (event.originalEvent) { //user changed
            const params: IUpdateFlowRecordRequestModel = {
                EntityId: this._entityId,
                FlowRecordId: flowModel.Id,
                FlowOperationId: value().Id
            }
            FlowFolderStore.UpdateFlowRecord(params)
                .then(result => result);
        }
    }

    DeleteFlowFolderItem(flowRecord){
        let confirmationDialog = new ConfirmationDialog({
            Text: `${CONFIRMATIONS.DO_YOU_WANT_TO_DELETE}?`,
            Type: ConfirmationTypes.Question,
            TextConfirm: LABELS.YES,
            TextDecline: LABELS.NO
        })

        const params: IDeleteFlowRecordRequestModel = {
            EntityId: this._entityId,
            FlowRecordId: flowRecord.Id
        }
        confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, (eventArgs) => {
            FlowFolderStore.DeleteFlowRecord(params)
                .then(()=> {
                    this._flowFolder.splice(this._flowFolder().findIndex(record => record.Id == flowRecord.Id), 1)
                })
                .fail(error => {})
        })
        confirmationDialog.Show();
    }

    Close() {
        this.Trigger('CLOSE_FOLLOW_UP_LIFE_STATUSES',{data: null})
    }

    AfterRender(el: HTMLElement): void{

    }

    GetTemplateName(){
        return 'Core/Controls/ButtonFollowUp/Templates/FlowFolderView';
    }
}