import * as ko from "knockout";
import Template from 'Core/GeneralProperties/Managers/DWPackageProperty/Templates/DWPackageProperty.html';

import { BlockUI } from 'Core/Common/BlockUi';
import { Notifier } from 'Core/Common/Notifier';
import { BaseProperty, IPropertyDescription, IPropertyOption } from "Core/GeneralProperties/Managers/BaseProperty";
import { DWPackageModel } from "Core/GeneralProperties/Managers/DWPackageProperty/Models/DWPackageModel";
import { DWPackagePropertyStore }
    from "Core/GeneralProperties/Managers/DWPackageProperty/Stores/DWPackagePropertyStore";
import { LABELS } from "Core/Components/Translation/Locales";

ko.templates["Core/GeneralProperties/Managers/DWPackageProperty/Templates/DWPackageProperty"] = Template;

export class DWPackageProperty extends BaseProperty {
    private _options: KnockoutObservableArray<IPropertyOption>;
    private SelectedOption: KnockoutObservable<IPropertyOption>;

    constructor(property: IPropertyDescription, propertyValue: number, getData: boolean = true) {
        super(property);

        this._options = ko.observableArray([]);
        this.Value = ko.observable(null);
        this.SelectedOption = ko.observable(null);

        if(getData) {
            DWPackagePropertyStore.GetDataWizardPackages().then((result) => {
                let items: IPropertyOption[] = _.map(result,
                    (item: DWPackageModel) => {
                        return { Name: item.Name, Value: item.Id }
                    });
                this.Init(items, propertyValue);
            }).fail(error => {
                new Notifier().Failed(error.message);
            });
        }
    }

    get Options(): KnockoutObservableArray<IPropertyOption> {
        return this._options;
    }

    GetTemplateName(): string {
        return "Core/GeneralProperties/Managers/DWPackageProperty/Templates/DWPackageProperty";
    }

    GetDefaultValue() {
        return _.first(this._options());
    }

    SetValue(propertyValue: number): void {
        this.SelectedOption.subscribe((newValue)=>{
            if(newValue){
                this.Value(newValue.Value)
            }
        });
        
        if (!propertyValue) {
            this.SelectedOption(this.GetDefaultValue());
        }else{
            let selectedValue = _.find(this._options(), option => option.Value === propertyValue);
            this.SelectedOption(selectedValue);    
        }
    }

    IsValid(): boolean {
        if (this.ValueRequired) {
            return this.Value();
        }

        return true;
    }

    private Init(options: IPropertyOption[], propertyValue: number) {
        this.SetOptions(options);
        this.SetValue(propertyValue);
    }

    private SetOptions(options: IPropertyOption[]) {
        if (!this.ValueRequired) {
            const empty: IPropertyOption = { Name: LABELS.SELECT_LABEL + '....', Value: null };
            options.unshift(empty);
        }

        this._options(options);
    }
}