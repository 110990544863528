import { FieldMetadataModel } from "Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel";

export class DisplayField{
    private _field: FieldMetadataModel;

    constructor(field: FieldMetadataModel){
        this._field = field;
    }

    get Title(): string{
        return `${this._field.NameTranslation || this._field.Name}`;
    }

    get Id(): number{
        return this._field.Id;
    }
}