import { Grid } from 'Core/Controls/Grid/Grid';
import * as _ from 'underscore';

import {Notifier} from 'Core/Common/Notifier';
import {BlockUI} from 'Core/Common/BlockUi';

import {IPropertyDescription, IPropertyOption} from 'Core/GeneralProperties/Managers/BaseProperty';
import {BaseSelectProperty} from 'Core/GeneralProperties/Managers/BaseSelectProperty/BaseSelectProperty';
import {EntityTypesStore} from 'Core/Screens/TypeScreen/Stores/EntityTypesStore';

import {TableTypeModel} from 'Core/Screens/TypeScreen/Models/TableTypeModel';
import {IControl} from 'Core/Controls/IControl';

import Template from 'Core/GeneralProperties/Managers/SubFlowProperty/Template.html';
import { SubFlowStore } from './SubFlowStore';
import { SubFlowDto } from './SubFlowDto';
import { CONTROL_TYPES, TABLE_TYPES } from '../../../Constant';

ko.templates['Core/GeneralProperties/Managers/SubFlowProperty/Template'] = Template;

export class SubFlowProperty extends BaseSelectProperty {
    private _control: IControl;
    private _propertyValue: IPropertyOption;
    private _isValid: KnockoutObservable<boolean>;
    private _el: HTMLElement;

    constructor(property: IPropertyDescription, propertyValue: IPropertyOption, control: IControl) {
        super(property, propertyValue);

        this._control = control;
        this._propertyValue = propertyValue;
        this._isValid = ko.observable(true);
    }

    IsValid(): boolean {
        return this._isValid();
    }

    GetTemplateName(): string {
        return 'Core/GeneralProperties/Managers/SubFlowProperty/Template';
    }

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);
        this._el = el[0];

        this._isValid(false);

        BlockUI.Block({Target: this._el});
        this.LoadData()
            .then(subFlows => {
                this.PopulateData(subFlows);
            })
            .fail(error => {
                Notifier.Failed(error.message);
            })
            .always(() => {
                this._isValid(true);
                BlockUI.Unblock(this._el);
            });
    }

    private LoadData()  {
        return SubFlowStore.GetSubFlows({ EntityId: this.EntityId, TypeId: this.TypeId });
    }

    get EntityId(){
        let parentControl = this._control.GetParentControl();
        if(!parentControl){
            return this._control.Screen.GetEntityId();
        }

        if(parentControl && parentControl.GetType() == CONTROL_TYPES.Grid){
            return this.GridSubjectEntityId;
        }
    }

    get TypeId(){
        let parentControl = this._control.GetParentControl();

        if(!parentControl){
            return this._control.Screen.GetTableTypeId();
        }

        if(parentControl && parentControl.GetType() == CONTROL_TYPES.Grid){
            return 0;
        }
    }

    get GridSubjectEntityId(){
        let gridSubjectField = _.find(this._control.GetParentControl().GetModel().Fields, (field) => {
            return field.EntityTypeName === TABLE_TYPES.Entity
        });

        if(gridSubjectField){
            return gridSubjectField.EntityId;
        }
        return null;
    }

    private PopulateData(subFlows: SubFlowDto[]){

        let items: IPropertyOption[] = _.map(subFlows,
            (item: SubFlowDto) => {
                return {Name: item.Name, Value: item.Id}
            });

        this.Init(items, this._propertyValue);
    }
}