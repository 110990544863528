import * as ko from "knockout"
import 'jquery';
import 'minicolors';

export class MiniColorsExtention {
    static Init() {
        ko.bindingHandlers.miniColors = {
            init: (element, valueAccessor) => {
                let options = valueAccessor();
                $(element)
                    .minicolors({
                        theme: options.theme,
                        defaultValue: '',
                        change: (colorValue: string) => options.changed(colorValue),
                        changeDelay: 200,
                        animationEasing: 'swing',
                        inline: !!options.inline,
                        position: !!options.position ? options.position : 'bottom left'
                    });
            },
            update: (element, valueAccessor) => {
                let options = valueAccessor();
                if (options.value) {
                    const value = options.value() || '';
                    if ($(element).minicolors('value') !== value) {
                        $(element).minicolors('value', value);
                    }
                } else {
                    $(element).val('');
                    $(element).minicolors('destroy');
                    $(element)
                        .minicolors({
                            theme: options.theme,
                            change: (colorValue: string) => options.changed(colorValue),
                            changeDelay: 200,
                            animationEasing: 'swing',
                            inline: !!options.inline,
                            position: !!options.position ? options.position : 'bottom left'
                        });
                }
            }
        };
    }
}