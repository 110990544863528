import {BaseColumn} from 'Core/Controls/Grid/BaseGrid/GridColumn/BaseColumn';
import {GridColumnModel} from 'Core/Controls/Grid/Models/GridDataModel/GridColumnModel';
import {EVENTS} from 'Core/Controls/Grid/BaseGrid/Events';
import {SortOrder} from 'Core/Controls/Grid/BaseGrid/GridColumn/BaseColumn';
import {LABELS} from 'Core/Components/Translation/Locales';
import {SortModel} from 'Core/Controls/Grid/Models/SortModel';
import {FastFilter} from 'Core/Controls/Grid/BaseGrid/FastFilter/FastFilter';
import {DecimalFilter} from 'Core/Controls/Grid/BaseGrid/FastFilter/Filters/Decimal/DecimalFilter';
import {IntegerFilter} from 'Core/Controls/Grid/BaseGrid/FastFilter/Filters/Integer/IntegerFilter';
import {TextFilter} from 'Core/Controls/Grid/BaseGrid/FastFilter/Filters/Text/TextFilter';
import {DateFilter} from 'Core/Controls/Grid/BaseGrid/FastFilter/Filters/Date/DateFilter';
import {LookupFilter} from 'Core/Controls/Grid/BaseGrid/FastFilter/Filters/Lookup/LookupFilter';
import {YesnoFilter} from 'Core/Controls/Grid/BaseGrid/FastFilter/Filters/Yesno/YesnoFilter';
import {TimespanFilter} from 'Core/Controls/Grid/BaseGrid/FastFilter/Filters/Timespan/TimespanFilter';
import {TimeFilter} from 'Core/Controls/Grid/BaseGrid/FastFilter/Filters/Time/TimeFilter';
import {DateTimeFilter} from 'Core/Controls/Grid/BaseGrid/FastFilter/Filters/DateTime/DateTimeFilter';
import {FIELD_TYPES} from 'Core/Constant';
import {IGetGridDataRequestModel} from 'Core/Controls/Grid/Stores/GridStore';
import {FastFilterSaveModel} from 'Core/Controls/Grid/BaseGrid/FastFilter/FastFilterModels';
import enumerable from '../../../../Common/Decorators/EnumerableDecorator';

export class HeaderColumn extends BaseColumn {
	private _recordId: number;
	private _tableViewId: number;
	private _element: HTMLElement;
	private _isClustered: boolean;
	private _gridDataModel: IGetGridDataRequestModel;
	private _existedColumnAliases: string[];
	private _labels = LABELS;

	private _fastFilter: FastFilter;

	constructor(model: GridColumnModel,
				recordId: number,
				tableViewId: number,
				gridDataModel: IGetGridDataRequestModel,
				existedColumnAliases: string[]) {
		super(model);

		this._recordId = recordId;
		this._tableViewId = tableViewId;
		this._isClustered = this._model.FieldMetadata && this._model.FieldMetadata.IsClustered;
		this._gridDataModel = gridDataModel;
		this._existedColumnAliases = existedColumnAliases;
		this._fastFilter = null;

		this.AddEvent(EVENTS.FAST_FILTER);

		this.InitFastFilter();
	}

	private InitFastFilter() {
		const isFastFilterAllowed = this._model.FieldMetadata && !this._model.FieldMetadata.IsVirtual;

		if (!isFastFilterAllowed) return;

		this._fastFilter = this.CreateFastFilter();

		if (!this._fastFilter) return;

		this._fastFilter.On(EVENTS.FAST_FILTER, this, (eventArgs: any) => {
			this.Trigger(EVENTS.FAST_FILTER);
		});
	}

	private CreateFastFilter(): FastFilter {
		const fastFilterParams = [this._model, this._recordId, this._tableViewId, this._gridDataModel, this._existedColumnAliases] as const;

		switch(this._model.FieldMetadata.Type) {
			case FIELD_TYPES.Decimal:
				return new DecimalFilter(...fastFilterParams);
			case FIELD_TYPES.Integer:
				return new IntegerFilter(...fastFilterParams);
			case FIELD_TYPES.Date:
				return new DateFilter(...fastFilterParams);
			case FIELD_TYPES.DateTime:
				return new DateTimeFilter(...fastFilterParams);
			case FIELD_TYPES.TimeSpan:
				return new TimespanFilter(...fastFilterParams);
			case FIELD_TYPES.Time:
				return new TimeFilter(...fastFilterParams);
			case FIELD_TYPES.Text:
				return new TextFilter(...fastFilterParams);
			case FIELD_TYPES.Alias:
				return new TextFilter(...fastFilterParams);
			case FIELD_TYPES.YesNo:
				return new YesnoFilter(...fastFilterParams);
			case FIELD_TYPES.Lookup:
				if (this._model.FieldMetadata.ValTableId <= 0) return;
				return new LookupFilter(...fastFilterParams);
			default:
				return null;
		}
	}

	ResetSort() {
		this._sortOrder(SortOrder.Both);
	}

	get IsWrapped(): boolean {
		return this._model.IsWrapped;
	}

	get IsSortDescription(): boolean {
		return this._model.IsSortDescription;
	}
	
	get IsOrdinary(){
        return !this.IsWrapped && !this.IsSortDescription;
    }

	get IsEnableSort(): boolean {
		return this._model.IsEnableSort;
	}

	get DisplayOrder(): number {
		return this._model.DisplayOrder;
	}

	get SortOrder() {
		return this._sortOrder();
	}

	set SortOrder(order) {
		this._sortOrder(order);
	}

	get IsFastFilterEnabled() {
		return this._isFastFilterEnabled() && this._model.IsEnableFastFilter;
	}

	set IsFastFilterEnabled(value) {
		this._isFastFilterEnabled(value);
	}

	get FilterSaveModel(): FastFilterSaveModel {
		return this._fastFilter ? this._fastFilter.FilterSaveModel : null;
	}

	Sort() {
		if (this._sortOrder() === SortOrder.Both) {
			this._sortOrder(SortOrder.Asc);
		} else if (this._sortOrder() === SortOrder.Asc) {
			this._sortOrder(SortOrder.Desc);
		} else if (this._sortOrder() === SortOrder.Desc) {
			this._sortOrder(SortOrder.Both);
		}

		this.Trigger(EVENTS.SORT);
	}

	GetSortModel(): SortModel {
		const model = new SortModel();

		model.OrderColumn = this._model.Alias;
		model.SortOrder = this._sortOrder();
		model.OrderColumnGuid = this._model.QueryColumnGuid;

		return model;
	}

	GetTemplate() {
		return this._fastFilter && this._fastFilter.GetTemplate();
	}

	AfterRender(el: HTMLElement) {
		this._element = el;
	}
}