import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize} from 'libs/cerialize';
import {GenericDeserialize} from 'libs/cerialize';
import { MailItemExtendedModel, MailRelatedData } from 'Core/Controls/Mail/Models/MailItemExtendedModel';
import {GetMailsResponseModel} from 'Core/Controls/Mail/Models/GetMailsResponseModel';
import { LinkFromMailBody } from 'Core/Controls/Mail/Models/LinkFromMailBody';

export interface IGetMailFolderRequestModel {
	MailConnectionId: number;
	PageSize: number;
	Page: number;
}

export interface ISaveMailToCyberBoxRequestModel {
	Guid: string;
}

export interface IRecipientModel {
	MailConnectionId: number;
	ContactId: number;
	MailTo: string;
}

export interface IDestinationMail {
	Name?: string;
	RecordId: number;
	TableId: number;
	Mail: string;
}

export interface ICcRecipientModel {
	Name: string;
	RecordId: number;
	TableId: number;
	Mail: string;
}

export interface IBccRecipientModel {
	Name: string;
	RecordId: number;
	TableId: number;
	Mail: string;
}

export interface IAttachmentModel {
	DocumentId: number;
	Name: string;
}

export interface IScreenshotModel {
	Content: string;
	Name: string;
}

export interface IDownloadAttachmentRequest {
	Guid: string;
	Name: string;
	MailConnectionId: number;
}

export interface IGetMailRelatedDataRequest {
	From: string;
	To: Array<string>;
	CC: Array<string>;
	BCC: Array<string>;
	SubjectEntityId: number;
	SubjectRecordId: number;
}

export interface ISendMailRequestModel {
	MailConnectionId: number;
	MessageId: string;
	Subject: string;
	Body: string;
	SubjectEntityId: number;
	SubjectRecordId: number;
	CcList?: Array<IDestinationMail>;
	BccList?: Array<IDestinationMail>;
	Recipients: Array<IDestinationMail>;
	Attachements: Array<IAttachmentModel>;
	Screenshots?: Array<IScreenshotModel>;
	AttachmentsRequest: Array<IDownloadAttachmentRequest>;

}

export interface IGetMailById {
	MailConnectionId: number;
	Guid: string;
	IsIncludeAttachmentContent: boolean;
}

export interface IMailListModelRequestModel {
	MailConnectionId: number;
	Guids: Array<string>;
	IsCyberBox: boolean;
}

export interface IDownloadAttachmentRequestModel {
	MailConnectionId: number;
	Guid: string;
	Name: string;
}

export interface IDownloadAttachmentsRequestModel {
	MailConnectionId: number;
	Guid: string;
	Names: Array<string>;
}

export interface IAddMailAddressRequestModel {
	RecordId: number;
	EntityId: number;
	MailAddress: string;
}

export interface GetLinkDto {
	MailBody: string;
	Subject: string;
}

export class MailStore {
	static GetMailFolder(params: IGetMailFolderRequestModel): P.Promise<any> {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/MailApi/GetMails',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					const model = GenericDeserialize<GetMailsResponseModel>(data.ResultObject, GetMailsResponseModel);

					deferredResult.resolve(model);
				} else {
					deferredResult.reject({message: data.ErrorMessage});
				}
			});

		return deferredResult.promise();
	}

	static GetDestinationMails(params: any): P.Promise<any> {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/MailApi/GetDestinationMails',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {

					deferredResult.resolve(data.ResultObject);
				} else {
					deferredResult.reject({message: data.ErrorMessage});
				}
			});

		return deferredResult.promise();
	}

	static GetMailRelatedData(params: IGetMailRelatedDataRequest): P.Promise<MailRelatedData> {
		const deferredResult = P.defer<MailRelatedData>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/MailApi/GetMailRelatedData',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					const model = GenericDeserialize<MailRelatedData>(data.ResultObject, MailRelatedData);
					deferredResult.resolve(model);
				} else {
					deferredResult.reject({message: data.ErrorMessage});
				}
			});

		return deferredResult.promise();
	}

	static GetMailTabs(): P.Promise<any> {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				cacheRequest: true,
				url: '/api/MailApi/GetMailTabs',
				dataType: 'json',
				type: 'GET'
			}
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					const model = Deserialize(data.ResultList);

					deferredResult.resolve(model);
				} else {
					deferredResult.reject({message: data.ErrorMessage});
				}
			});

		return deferredResult.promise();
	}

	static SaveMailToCyberBox(params: ISaveMailToCyberBoxRequestModel): P.Promise<any> {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/MailApi/SaveMailToCyberBox',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(null);
				} else {
					deferredResult.reject({message: data.ErrorMessage});
				}
			});

		return deferredResult.promise();
	}

	static SendMail(params: ISendMailRequestModel, type: string = 'SendMail'): P.Promise<any> {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: `/api/MailApi/${type}`,
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(data);
				} else {
					deferredResult.reject({message: data.ErrorMessage});
				}
			});

		return deferredResult.promise();
	}

	static GetMailById(params: IGetMailById): P.Promise<MailItemExtendedModel> {
		const deferredResult = P.defer<MailItemExtendedModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/MailApi/GetMailById',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					const model = GenericDeserialize<MailItemExtendedModel>(data.ResultObject, MailItemExtendedModel);
					deferredResult.resolve(model);
				} else {
					deferredResult.reject({message: data.ErrorMessage});
				}
			});

		return deferredResult.promise();
	}

	static MarkAsRead(params: IMailListModelRequestModel): P.Promise<any> {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/MailApi/MarkAsRead',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(null);
				} else {
					deferredResult.reject({message: data.ErrorMessage});
				}
			});

		return deferredResult.promise();
	}

	static MarkAsUnRead(params: IMailListModelRequestModel): P.Promise<any> {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/MailApi/MarkAsUnRead',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(null);
				} else {
					deferredResult.reject({message: data.ErrorMessage});
				}
			});

		return deferredResult.promise();
	}

	static DeleteMails(params: IMailListModelRequestModel): P.Promise<any> {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/MailApi/DeleteMails',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(null);
				} else {
					deferredResult.reject({message: data.ErrorMessage});
				}
			});

		return deferredResult.promise();
	}

	static DownloadAttachment(params: IDownloadAttachmentRequestModel): P.Promise<any> {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/MailApi/DownloadAttachment',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(data);
				} else {
					deferredResult.reject({message: data.ErrorMessage});
				}
			});
		return deferredResult.promise();
	}

	static DownloadAttachments(params: IDownloadAttachmentsRequestModel): P.Promise<any> {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/MailApi/DownloadAttachments',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(data);
				} else {
					deferredResult.reject({message: data.ErrorMessage});
				}
			});

		return deferredResult.promise();
	}

	static AddMailAddress(params: IAddMailAddressRequestModel): P.Promise<any> {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/MailApi/AddMailAddress',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(data);
				} else {
					deferredResult.reject({message: data.ErrorMessage});
				}
			});

		return deferredResult.promise();
	}

	static GetLink(params: GetLinkDto): P.Promise<LinkFromMailBody> {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/MailApi/GetLinkFromMailBody',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(GenericDeserialize<LinkFromMailBody>(data, LinkFromMailBody));
			});

		return deferredResult.promise();
	}
}