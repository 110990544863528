import * as ko from "knockout";
import * as _ from 'underscore';
import {Event} from 'Core/Common/Event';

export interface MutationObserverConfig {
    childList?: boolean,
    attributes?: boolean,
    subtree?: boolean,
    characterData?: boolean,
    attributeOldValue?: boolean,
    characterDataOldValue?: boolean,
    attributeFilter?: Array<string>
}

export class RepositionModalObserver extends Event {
    private _observer: MutationObserver;
    private _target: HTMLElement;
    private _config: MutationObserverConfig;
    private _time: number = 1;
    constructor(target: HTMLElement, mutationObserverConfig: MutationObserverConfig, time?: number) {
        // documentations: https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
        // demo: https://codepen.io/webgeeker/pen/YjrZgg?editors=1010
        super();
        this._observer = null;
        this._target = target;
        this._config = mutationObserverConfig;
        this._time = time && time;
        this.Init();
    }

    Init(){
        this._observer = new MutationObserver((mutations)=> {
            _.each(mutations, (mutation)=>{
                if (mutation.type === "attributes") {
                    setTimeout(() => {
                        $(window).trigger('resize');
                    }, this._time);
                }
            })
        });
        this._observer.observe(this._target, this._config);
    }

    Disconnect() { //documentation https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/disconnect
        if (this._observer instanceof MutationObserver){
            this._observer.disconnect();
        }
    }

    TakeRecords() { //documentation https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/takeRecords

    }
}