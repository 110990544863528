//Common
import * as ko from 'knockout';
import * as $ from 'jquery';
import 'tree';

import {Guid} from "../../../Common/Guid";
import {RecipeNodeViewModel} from "./Models/View/RecipeNodeViewModel";
import {UserVarsManager} from "Core/UserVarsManager/UserVarsManager";
import {LABELS} from "Core/Components/Translation/Locales"

import {P} from "Core/Common/Promise";
import {GetLinkedRecordsDto, RecipeEditorStore} from "../Stores/RecipeEditorStore";
import {GetRecipeDataDto} from "../Models/Dto/GetRecipeDataDto";
import {RecipeResponseModel} from "./Response/RecipeResponseModel";
import {RecipeEditorTreeViewModelMapping} from "./Mappings/RecipeEditorTreeViewModelMapping";
import {RecipeViewModel} from "./Models/View/RecipeViewModel";
import {Notifier} from "../../../Common/Notifier";
import {RecipeVersionModel} from "../Models/RecipeVersionModel";
import {RecipeEditorTreeNodeViewModelMapping} from "./Mappings/RecipeEditorTreeNodeViewModelMapping";
import {RecipeDataModel} from "../Models/RecipeDataModel";
import {ITypePropertyModel} from "Core/GeneralProperties/Managers/TypesProperty/TypesProperty";
import {ZIndexManager} from 'Core/Common/ZIndexManager';

//Templates
import Template from "Core/Controls/RecipeEditor/RecipeEditorTree/Templates/Template.html";
import ItemTemplate from "Core/Controls/RecipeEditor/RecipeEditorTree/Templates/ItemTemplate.html";
import {EllipsisTooltipExtention} from "../../../KnockoutExtentions/EllipsisTooltipExtention";

ko.templates["Core/Controls/RecipeEditor/RecipeEditorTree/Templates/Template"] = Template;
ko.templates["Core/Controls/RecipeEditor/RecipeEditorTree/Templates/ItemTemplate"] = ItemTemplate;

export interface IGetNewDataParams {
    recipeDataModel: RecipeDataModel,
    recipeSelectedVersion: RecipeVersionModel
}

export class RecipeEditorTreeUnit {
    private _entityId: number;
    private _recordId: KnockoutObservable<number>;
    private _controlId: number;
    private _expanded: KnockoutObservable<boolean>;
    private _model: KnockoutObservable<RecipeViewModel>;
    private _userVars: UserVarsManager;
    private _labels = LABELS;

    private _el: HTMLElement;
    private _recipeEditorTree: HTMLElement;
    private _recipeEditorTreeId: string;
    private _recipeEditorTreeRendered: boolean;
    private _treeRendered: boolean;
    private _dataLoaded: KnockoutObservable<boolean>;
    private _selectedRecipeVersion: KnockoutObservable<RecipeVersionModel>
    private _jbox: jBox;

    private _recipeVersionModel: KnockoutObservableArray<RecipeVersionModel>
    private _typesProperty: ITypePropertyModel[]

    constructor(entityId: number, controlId: number, typesProperty: ITypePropertyModel[]) {
        this._recipeEditorTreeId = Guid.NewGuid();
        this._entityId = entityId;
        this._recordId = ko.observable(null);
        this._controlId = controlId;
        this._model = ko.observable(null);
        this._recipeVersionModel = ko.observableArray([]);
        this._typesProperty = typesProperty;

        // let isExpanded = this.GetSettingsFromLockStorage();
        // this._expanded = ko.observable(isExpanded);
        this._expanded = ko.observable(false);
        this._selectedRecipeVersion = ko.observable(null);

        this._dataLoaded = ko.observable(false);
        this._dataLoaded.subscribe(dataLoaded => {
            if (dataLoaded) {
                this.RenderTree();
            }
        });
    }

    ExpandCollapse() {
        this._expanded(!this._expanded());
        // this.SetSettingsToLockStorage();
    }

    GetTemplateName() {
        return "Core/Controls/RecipeEditor/RecipeEditorTree/Templates/Template";
    }

    AfterRender(el: HTMLElement[]) {
        this._recipeEditorTree = document.getElementById(this._recipeEditorTreeId);
        this._el = el[0];
        this.RenderTree();
    }

    LoadNewData(recordId: number, recipeVersions: KnockoutObservableArray<RecipeVersionModel>){
        const deferred = P.defer();
        this._dataLoaded(false);
        this._treeRendered = false;

        this._recipeVersionModel(recipeVersions());

        this._recordId(recordId);

        if (recordId){
            let params: GetRecipeDataDto = {
                RootEntityId: this._entityId,
                RootRecordId: recordId,
                ControlId: this._controlId
            }
            RecipeEditorStore.GetRecipeData(params)
                .then((data: RecipeDataModel) => {
                    let roots: RecipeResponseModel = new RecipeResponseModel()
                    roots.Roots.push(data.RecipeRecord);
                    this.DataLoaded(roots);
                    deferred.resolve(null);
                })
                .fail(() => {
                    this.DataLoadFailed.bind(this)
                    deferred.resolve(null);
                });
        }

    }

    GetNewData(params: IGetNewDataParams){
        const deferred = P.defer();
        this._dataLoaded(false);
        this._treeRendered = false;
        this._selectedRecipeVersion({
            VersionId: params.recipeSelectedVersion.VersionId,
            VersionName: params.recipeSelectedVersion.VersionName,
            IsActive: params.recipeSelectedVersion.IsActive,
            IsSelected: ko.observable(params.recipeSelectedVersion.IsSelected())
        })

        this._recipeVersionModel(params.recipeDataModel.RecipeVersions);
        this._recordId(params.recipeDataModel.RecipeRecord.Id);

        if (params.recipeDataModel){

            const promise = new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve('Success');
                }, 100);
            });
            promise.then((value) => {
                let roots: RecipeResponseModel = new RecipeResponseModel();
                roots.Roots.push(params.recipeDataModel.RecipeRecord);

                this.DataLoaded(roots);
                deferred.resolve(null);
            });

        }

    }

    private DataLoadFailed(error) {
        new Notifier().Failed(error.message);
    }

    private DataLoaded(responseModel: RecipeResponseModel){
        if (responseModel) {
            const data = RecipeEditorTreeViewModelMapping.MapFromResponse(responseModel, this._typesProperty);
            this._model(data);
        } else {
            this.Clear();
        }

        if (this._model()) {
            // this._model().GetNodeByRecordId(this._recordId(),
            //     record => {
            //         if (!this._model().CurrentNode && record.IsSubject) {
            //             this._model().CurrentNode = record;
            //         }
            //     });

            this._model().SetInitialNode(this._recordId());

            this._treeRendered = true;
            this._dataLoaded(true);
        }
    }

    Clear() {
        if (this._model()) {
            this._model(null);
        }
    }

    Destroy(){
        const $treeRoot = $(this._el)
            .next()
            .find('.recipeEditor-body');
        this.Clear();
        $treeRoot.jstree('destroy');
    }

    Refresh(){
        const $treeRoot = $(this._el)
            .next()
            .find('.recipeEditor-body');
        $treeRoot.jstree(true).refresh();
    }

    AddEllipsisTooltip(data) {
        this._model().GetNode(data.node.id, record => {
            this._jbox = EllipsisTooltipExtention.CreateEllipsisTooltipForPathRunner(record);
        });
    }

    private RenderTree() {
        const $treeRoot = $(this._el)
            .next()
            .find('.recipeEditor-body');

        $treeRoot.jstree('destroy');
        $treeRoot.jstree({
            state: {key: 'recipeEditor-state'},
            plugins: ['state'],
            core: {
                animation: false,
                data: this.ExtendTree.bind(this),
                multiple: false,
                themes: {
                    icons: false
                }
            }
        });

        $treeRoot
            .on('ready.jstree', (event, data) => this.OpenCurrentNode(data))
            // .on('activate_node.jstree ', (event, data) => this.Navigate(data))
            .on('open_node.jstree', (event, data) => this.PathRunnerShowChildren(data))
            .on('close_node.jstree', (event, data) => this.PathRunnerHideChildren(data))
            .on('hover_node.jstree', (event, data) => {
                if (!$(`.${data.node.original.record.Guid}_tooltip`).length) {
                    this.AddEllipsisTooltip(data);
                }
            })
    }

    PathRunnerShowChildren(data) {
        if ($(`.${data.node.id}_tooltip`).length) {
            $(`.${data.node.id}_tooltip`).remove();
        }
    }

    PathRunnerHideChildren(data) {
        if ($(`.${data.node.id}_tooltip`).length) {
            $(`.${data.node.id}_tooltip`).remove();
        }

        _.each(data.node.children, child => {
            if ($(`.${child}_tooltip`).length) {
                $(`.${child}_tooltip`).remove();
            }
        })
    }

    private OpenCurrentNode(data) {
        const currentRecord = this._model().RootNode;

        if (currentRecord) {
            if (currentRecord.HasChildren) {
                const currentNode = currentRecord.Guid;
                data.instance._open_to(currentNode);
                data.instance.open_node(currentNode);
                data.instance.disable_node(currentNode);
            }
        }
    }

    private ExtendTree(node, callback) {
        if (node.id === '#') {
            if (this._model()) {
                callback.call(node, this._model().ToJson());
            }
        } else {
            this.LoadLinkedRecords(node, this._selectedRecipeVersion())
                .then(linkedRecords => this.AddLinkedRecords(linkedRecords, node, callback))
                .fail(this.DataLoadFailed.bind(this));
        }
    }

    private LoadLinkedRecords(node, selectedRecipeVersionModel) {
        const deferredResult = P.defer<RecipeNodeViewModel[]>();

        const record = node.original.record;
        let nestedNodes = [];

        this._model().GetNodeByRecordId(record.Id, existingRecord => {
            if (existingRecord.Children.length > 0 && nestedNodes.length === 0) {
                nestedNodes = existingRecord.Children.map(nestedNode => nestedNode.Copy());
            }
        });

        if (nestedNodes.length === 0) {
            let parsms: GetLinkedRecordsDto = {
                RootEntityId: this._entityId,
                ParentRecordId: record.Id,
                VersionId: selectedRecipeVersionModel.VersionId,
            }

            RecipeEditorStore.GetLinkedRecords(parsms)
                .then(linkedRecords => {
                    nestedNodes = linkedRecords.map(nestedNode => RecipeEditorTreeNodeViewModelMapping.MapFromResponse(nestedNode, this._typesProperty));
                    deferredResult.resolve(nestedNodes);
                })
                .fail(error => deferredResult.reject(error));

        } else {
            deferredResult.resolve(nestedNodes);
        }

        return deferredResult.promise();

    }

    private AddLinkedRecords(linkedRecords: RecipeNodeViewModel[], node, callback) {
        const nestedNodes = linkedRecords.map(nestedNode => nestedNode.ToJson());
        this._model().GetNode(node.id, record => {
            record.AddRange(linkedRecords);
        });
        callback.call(node, nestedNodes);
    }


}