import {deserialize, deserializeAs} from 'libs/cerialize'
import {ImageFieldModel} from 'Core/Screens/DesignScreen/ControlEditor/Models/ImageFieldModel'
import {TranslationModel} from 'Core/Controls/BaseControl/Models/TranslationModel';

export class TableWithImagesModel {
    @deserialize
    Id: number;

    @deserialize
    Type: string;

    @deserializeAs(ImageFieldModel)
    ImageFields: Array<ImageFieldModel>;

    @deserialize
    Name: string;

    @deserialize
    NameTranslated: string;

    @deserializeAs(TranslationModel)
    NameTranslations: Array<TranslationModel>;
}