import {deserialize, deserializeAs, serializeAs} from 'libs/cerialize';
import {PlannerLevelRowModel, TimeCellModel} from "../../PlannerLevel/Models/PlannerLevelRowModel";
import {CustomColumnHeaderModel} from "./CustomColumnHeaderModel";
import {ProjectManagerModel} from "./ProjectManagerModel";

export class TimeLineColumnModel {
    @deserialize
    TimeValue: string;

    @deserialize
    IsWeekend: boolean;
}

export class PlannerPageModel {
    constructor(){
        this.PlannerLevels = [];
        this.CustomColumns = [];
        this.TimeLineColumns = [];
        this.ProjectManagers = [];
    }

    @deserializeAs(TimeLineColumnModel)
    TimeLineColumns: Array<TimeLineColumnModel>;

    @deserializeAs(PlannerLevelRowModel)
    PlannerLevels: Array<PlannerLevelRowModel>;

    @deserializeAs(CustomColumnHeaderModel)
    CustomColumns: Array<CustomColumnHeaderModel>;

    @deserializeAs(ProjectManagerModel)
    ProjectManagers: Array<ProjectManagerModel>;
}