import {P} from 'Core/Common/Promise';
import {Request} from 'Core/Common/Request';

import {ProductConfiguratorDesignOptions} from '../Models/ProductConfiguratorDesignOptions';

export class ProductConfiguratorControlEditorStore {
    GetDesignOptions(entityId: number): P.Promise<ProductConfiguratorDesignOptions> {
        const deferred = P.defer<ProductConfiguratorDesignOptions>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetDesignOptions',
                type: 'GET',
                dataType: 'json'
            },
            params: {entityId}
        })
            .then(options => deferred.resolve(options))
            .fail(error => deferred.reject(this.GetResponseError(error, 'Error reading design options')));

        return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}