import * as ko from 'knockout';
import * as _ from 'underscore';

import {FeatureTypes} from "Core/Common/Enums/FeatureTypes";

import {SpimValueModel} from "Core/Controls/Grid/Models/GridDataModel/GridCellValueModel";

import {SpimValueViewModel} from "./SpimValueViewModel";
import {DropdownValueDto, DropdownValuesDto} from "../Store/Models/DropdownValuesDto";
import {LABELS} from 'Core/Components/Translation/Locales';

import AlphaNumericTemplate from '../Templates/FeatureTypes/AlphaNumeric.html';

export class AlphaNumericValueViewModel extends SpimValueViewModel {
    private _items: KnockoutObservableArray<DropdownValueDto>;
    private _selectedItem: KnockoutObservable<DropdownValueDto>;
    private _selectedItemId: KnockoutObservable<number>;
    private _label = LABELS;

    constructor(recordId: number, value: number, displayValue: string, dropdownValues: DropdownValuesDto) {
        super(recordId);

        this._items = ko.observableArray(dropdownValues.Items);

        const selectedValue = _.find(dropdownValues.Items, item => item.RecordId === value);
        this._selectedItem = ko.observable(selectedValue);
        this._selectedItemId = ko.observable(selectedValue && selectedValue.RecordId);
    }

    GetTemplate(): any {
        return AlphaNumericTemplate;
    }

    GetValue(): SpimValueModel {
        const spimValue = new SpimValueModel();

        spimValue.RecordId = this.recordId;
        spimValue.FeatureType = FeatureTypes.AlphaNumeric;
        spimValue.AlphaNumericValue = this._selectedItem().RecordId;
        spimValue.AlphaNumericDisplayValue = this._selectedItem().Label;

        return spimValue;
    }
}