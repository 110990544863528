import * as ko from 'knockout';
import * as $ from 'jquery';
import * as _ from 'underscore';

import {Guid} from "Core/Common/Guid";
import {EventBus} from "Core/Common/EventBus/EventBus";

import {FormatConverter} from 'FormatEditor/FormatConverter';

import {PropertyEvaluationContext} from "../Enums/PropertyEvaluationContext";

import {ConfigurationPageVariablesStore} from "../Stores/ConfigurationPageVariablesStore";
import {ConfigurationPageVariable} from "../Stores/Models/ConfigurationPageVariable";

import {BaseProduct} from "./BaseProduct";

import {BaseProductGroup} from "./BaseProductGroup";
import {RootGroup} from "./RootGroup";
import {ProductGroup} from "./ProductGroup";
import {ProductPropertyGroup} from "./Properties/ProductPropertyGroup";
import {CustomFieldsGroup} from "./CustomFields/CustomFieldsGroup";

import {PropertyValue} from "./PropertyValue";
import {PropertyControl} from "./Properties/Controls/PropertyControl";
import {LookupPropertyControl} from "Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/Properties/Controls/Lookup/LookupPropertyControl";
import {CustomFieldValue} from "./CustomFieldValue";
import {CustomFieldControl} from "Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/CustomFields/Controls/CustomFieldControl";
import {CustomLookupControl} from "Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/CustomFields/Controls/Lookup/CustomLookupControl";

import {GetActionDependsOnValuesDto} from "Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Stores/Params/GetActionDependsOnValuesDto";
import {ActionDependsOnFieldValueResponse} from "Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Stores/Models/ActionDependsOnFieldValueResponse";

import {ProductPropertyGroupDescription} from "./Properties/ProductPropertyGroupDescription";
import {ProductPropertyControlFactory} from "./Properties/Factory/ProductPropertyControlFactory";
import {ProductCustomFieldControlFactory} from "./CustomFields/Factory/ProductCustomFieldControlFactory";
import {PropertyEvaluationExpression} from "./PropertyEvaluationExpression";
import {ProductCustomFieldDescription} from "./CustomFields/ProductCustomFieldDescription";

import {LABELS} from 'Core/Components/Translation/Locales';

import {
    ConfigurationPageEvents,
    GroupActivatingEventArgs,
    ProductPartSelectingEventArgs,
    SelectProductPartEventArgs, PriceProductNavigatedEventArgs
} from "../Events/ConfigurationPageEvents";

import Template from '../Templates/ProductPart.html';

export class ProductPart extends BaseProduct {
    private _labels = LABELS;

    private _guid: string;
    private _kSeqGuid: string;
    private _path: number[];
    private _variablesStore: ConfigurationPageVariablesStore;
    private _calculatePriceTimeout: number;
    private _quantityChangeSubscription: KnockoutSubscription;
    private _priceChangeSubscription: KnockoutSubscription;

    private _baseQuantity: number;

    Price: KnockoutObservable<number>;
    Selected: KnockoutObservable<boolean>;
    Level: string;

    PropertyGroups: ProductPropertyGroup[];
    PropertyEvaluationExpressions: PropertyEvaluationExpression[];
    CustomFieldsGroup: CustomFieldsGroup;

    InclusiveProducts: number[];
    ExclusiveProducts: number[];
    Defaults: number[];

    Groups: KnockoutObservableArray<ProductGroup>;
    SelectedGroup: KnockoutObservable<ProductGroup>;
    IsMain: KnockoutObservable<boolean>;

    Quantity: KnockoutObservable<number>;

    QuantityCanBeChanged: boolean;
    AlternativesCount: number;

    _tileImageSize: number;
    _selectedImageSize: number;
    _imageSizeClassName: string;

    _isPriceInDestination: KnockoutObservable<boolean>;

    readOnly: KnockoutObservable<boolean>;
    _priceMiscalculated: KnockoutObservable<boolean>;

    IsDisabled: boolean;

    HasCollapseGroup: KnockoutObservable<boolean>;

    IsDisabledByAction: KnockoutObservable<boolean>;
    IsSelectingDisabledByAction: KnockoutComputed<boolean>;

    constructor(private _orderEntityId: number,
                private _productEntityId: number,
                id: number,
                kSeq: number,
                name: string,
                nameTranslated: string,
                image: string,
                description: string,
                basePrice: number,
                price: number,
                level: string,
                propertyGroupsDescription: ProductPropertyGroupDescription[],
                propertyEvaluationExpressions: PropertyEvaluationExpression[],
                groups: ProductGroup[],
                parentGroup: BaseProductGroup,
                productCustomFieldsDescription: ProductCustomFieldDescription[],
                inclusiveProducts: number[],
                exclusiveProducts: number[],
                defaults: number[],
                quantity: number,
                quantityCanBeChanged: boolean,
                readOnly: boolean
    ) {

        super(id, kSeq, name, nameTranslated, image, description, basePrice, parentGroup);

        this.readOnly = ko.observable(readOnly);

        this._guid = Guid.NewGuid();
        this._kSeqGuid = null;
        this._path = this.BuildPathToRoot();

        this.Price = ko.observable(price || basePrice);
        this.Level = level;

        this.PropertyGroups = this.CreatePropertyGroups(propertyGroupsDescription);
        this.PropertyEvaluationExpressions = propertyEvaluationExpressions;
        this.CustomFieldsGroup = this.CreateCustomFieldsGroup(productCustomFieldsDescription);
        this.DefinePropertyAccessors();
        this.DefineCustomFieldAccessors();
        this.DefineQuantityAccessor();
        this.DefinePriceAccessor();

        this.InclusiveProducts = inclusiveProducts;
        this.ExclusiveProducts = exclusiveProducts;
        this.Defaults = defaults;

        this.Selected = ko.observable(false);
        this.Groups = ko.observableArray(groups);
        this.SelectedGroup = ko.observable(null);

        this._baseQuantity = quantity;
        this.Quantity = ko.observable(quantity);
        this.QuantityCanBeChanged = quantityCanBeChanged;
        this.IsMain = ko.observable(false);

        this._tileImageSize = null;
        this._selectedImageSize = null;
        this._imageSizeClassName = null;

        this._isPriceInDestination = ko.observable(this.IsPriceInDestination());

        this._priceMiscalculated = ko.observable(false);

        this.HasCollapseGroup = ko.observable(false);

        this.IsDisabledByAction = ko.observable(null);
        this.IsSelectingDisabledByAction = ko.computed(() => this.IsDisabledByAction() && !this.Selected());
    }

    set TileImageSize(value: number) {
        this._tileImageSize = value;
    }

    set SelectedImageSize(value: number) {
        this._selectedImageSize = value;
    }

    get IsExtra() {
        return this.ParentGroup.IsExtra;
    }

    get ImageSizeSelectedTileClassName(): string {
        if (this.ParentGroup.ExtendedView()) {
            return this.GetImageSizeClassName(this._selectedImageSize);
        } else {
            return this.GetImageSizeClassName(this._tileImageSize);
        }
    }

    GetImageSizeClassName(imageSize: number) {
        switch (imageSize) {
            case 60:
                return this._imageSizeClassName = 'imageSize-60';
            case 70:
                return this._imageSizeClassName = 'imageSize-70';
            case 80:
                return this._imageSizeClassName = 'imageSize-80';
            case 90:
                return this._imageSizeClassName = 'imageSize-90';
            default:
                return this._imageSizeClassName = 'imageSize-100';
        }
    }

    get PositionPrice(): number {
        return this.Price() * +this.Quantity();
    }

    get PriceLabel(): string {
        const priceValuePerPiece = this.Price().toFixed(2);
        const culturalPricePerPiece = FormatConverter.LocalizeDecimalOrInteger(priceValuePerPiece);
        return `€ ${culturalPricePerPiece}`;
    }

    GenerateKSeqGuid() {
        this._kSeqGuid = Guid.NewGuid();
    }

    get KSeqGuid(): string {
        return this._kSeqGuid;
    }

    get Path(): number[] {
        return this._path;
    }

    AssignEventBus(bus: EventBus) {
        super.AssignEventBus(bus);
        this.PropertyGroups.forEach(group => group.PropertyControls.forEach(control => control.AssignEventBus(this.EventBus)));
    }

    AssignVariableStore(store: ConfigurationPageVariablesStore) {
        this._variablesStore = store;
    }

    GetTemplate() {
        return Template;
    }

    ToggleSelection() {
        if (!this.IsMain()) {
            this.DispatchEvent<ProductPart>(ConfigurationPageEvents.CheckAvailabilityToToggle, this);
        }
    }

    Toggle() {
        if (this.Selected()) {
            this.ParentGroup.Products(this.ParentGroup.Products().sort(function (a, b) {
                return (a.Id - b.Id);
            }));

            // if (this.ParentGroup.ExtendedView()) {
            //     this.DispatchEvent<BaseProductGroup>(ConfigurationPageEvents.ShowingMore, this.ParentGroup);
            // }
            this.DispatchEvent<BaseProductGroup>(ConfigurationPageEvents.ShowingMore, this.ParentGroup);

            this.DispatchEvent<ProductPart>(ConfigurationPageEvents.ProductPartUnSelecting, this);
        } else {
            this.ParentGroup.Products().unshift(this.ParentGroup.Products().splice(this.ParentGroup.Products().indexOf(this), 1)[0]);

            if (!this.ParentGroup.ExtendedView()) {
                this.ParentGroup.ToggleView();
            }

            this.DispatchEvent(ConfigurationPageEvents.BeforeSelectingProductPartEvent, this);
        }
    }

    Select() {
        this.DispatchEvent<ProductPartSelectingEventArgs>(ConfigurationPageEvents.ProductPartSelecting,
            new ProductPartSelectingEventArgs(this.parentGroup.GetActiveProduct(), this));

        this.MakeSelected();
        this.InitPropertyValues();
        this.InitCustomFieldValues();

        this.DispatchEvent<ProductPart>(ConfigurationPageEvents.ProductPartSelected, this);
        this.DispatchEvent<ProductPart>(ConfigurationPageEvents.CheckSaveDisabilityByAction, this);
    }

    MakeSelected() {
        this.Selected(true);

        this.InitPropertyGroups();

        if (!this.readOnly()) {
            this.DeclareVariables();
            this.CheckActionVariableExpressions();
        }
    }

    EvaluateCalculatedExpressionsOnUnselect(productPart: ProductPart) {
        const parentGroup: BaseProductGroup = productPart.ParentGroup;

        (productPart && productPart._priceMiscalculated()) && productPart._priceMiscalculated(false);
        
        this.PropertyEvaluationExpressions
            .filter(expressionDescriptor => expressionDescriptor.Destination === `$group(\'${parentGroup.Name}\')`)
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action.clearOnUnselect') > -1)
            .forEach(expressionDescriptor => {
                const newDestination = expressionDescriptor.Expression
                    .replace(/^(\$action.clearOnUnselect\()+|(\))+$/g, "");
                const newValue = null;

                const newExpressionDescriptor = new PropertyEvaluationExpression(expressionDescriptor.Id, newDestination, newValue);

                const propertyControl = this.GetPropertyControlByExpression(newExpressionDescriptor);
                if (propertyControl) {
                    propertyControl.ClearValue();
                }

                const customFieldControl = this.GetCustomFieldControlByExpression(newExpressionDescriptor);
                if (customFieldControl) {
                    customFieldControl.ClearValue();
                }
            });
    }

    SetInitialPropertyValues() {
        this.PropertyGroups.forEach(propertyGroup => {
            propertyGroup.PropertyControls.forEach(control => control.SetValue(null));
        });
    }

    SetPropertyValues(propertyValues: PropertyValue[]) {
        this.PropertyGroups.forEach(propertyGroup => {
            propertyGroup.PropertyControls.forEach(propertyControl => {
                const propertyValue = propertyValues.find(pv => pv.Id == propertyControl.Id);

                if (propertyValue) {
                    if (this.readOnly()) {
                        propertyControl.readOnly(true);
                    }
                    propertyControl.SetValue(propertyValue);
                } else {
                    propertyControl.SetValue(null);
                }
            });
        });
    }

    SetInitialCustomFieldValues() {
        this.CustomFieldsGroup.CustomFieldsControls.forEach(control => control.SetDefaultValue());
    }

    SetCustomFieldValues(customFieldValues: CustomFieldValue[]) {
        this.CustomFieldsGroup.CustomFieldsControls.forEach(customFieldControl => {
            const customFieldValue = customFieldValues.find(pv => pv.Id == customFieldControl.Id);

            if (customFieldValue) {
                if (this.readOnly()) {
                    customFieldControl.readOnly(true);
                }
                customFieldControl.SetValue(customFieldValue);
            } else {
                customFieldControl.SetValue(null);
            }
        });
    }

    GetPropertyValues(): PropertyValue[] {
        return _.chain(this.PropertyGroups).map(propertyGroup => propertyGroup.GetPropertyValues()).flatten().value() as PropertyValue[];
    }

    GetPropertyValuesForPrice(): PropertyValue[] {
        return _.chain(this.PropertyGroups).map(propertyGroup => propertyGroup.GetPropertyValuesForPrice()).flatten().value() as PropertyValue[];
    }

    GetPropertyValuesForPreview(): PropertyValue[] {
        return _.chain(this.PropertyGroups).map(propertyGroup => propertyGroup.GetPropertyValuesForPreview()).flatten().value() as PropertyValue[];
    }

    GetCustomFieldValuesForPrice(): CustomFieldValue[] {
        return this.CustomFieldsGroup.GetCustomFieldValuesForPrice();
    }

    GetCustomFieldValues(): CustomFieldValue[] {
        return this.CustomFieldsGroup.GetCustomFieldValues();
    }

    FindNestedProduct(id: number, rootGroupId: number, rootGroupName: string, groupId: number, groupName: string, path: number[], kSeq: number, kSeqGuid: string): ProductPart {
        for (let groupIndex = 0; groupIndex < this.Groups().length; groupIndex++) {
            const currentGroup = this.Groups()[groupIndex];
            const product = currentGroup.FindProduct(id, rootGroupId, rootGroupName, groupId, groupName, path, kSeq, kSeqGuid);

            if (product) {
                return product;
            }
        }

        return null;
    }

    FindNestedProductById(id: number): ProductPart {
        for (let groupIndex = 0; groupIndex < this.Groups().length; groupIndex++) {
            const currentGroup = this.Groups()[groupIndex];
            const product = currentGroup.FindProductById(id);

            if (product) {
                return product;
            }
        }

        return null;
    }

    UnSelect() {
        this.Selected(false);

        this.UnSelectDependencies();
        this.ClearPropertyValues();
        this.ClearCustomFieldValues();
        this.SetBasePrice();
        this.SetBaseQuantity();

        this.DispatchEvent<ProductPart>(ConfigurationPageEvents.ProductPartUnSelected, this);

        if (this.IsDisabled) {
            this.ParentGroup.Products(this.ParentGroup.Products().filter(p => p.Id != this.Id || p.KSeq != this.KSeq || p.KSeqGuid != this.KSeqGuid));
        }

        if (this.ParentGroup.IsExtra) {
            this.ParentGroup.Products(this.ParentGroup.Products().filter(p => p.Id != this.Id || p.KSeq != this.KSeq || p.KSeqGuid != this.KSeqGuid));
        }

        this.DispatchEvent<ProductPart>(ConfigurationPageEvents.CheckSaveDisabilityByAction, this);
    }

    Remove() {
        this.DispatchEvent<ProductPart>(ConfigurationPageEvents.ProductPartUnSelecting, this);
    }

    ZoomButtonClicked() {
        this.DispatchEvent(ConfigurationPageEvents.ZoomButtonClicked, this);
    }

    OnAddAsExtraClicked() {
        this.DispatchEvent<ProductPart>(ConfigurationPageEvents.ProductPartAddingAsExtra, this);
    }

    RootGroupIs(group: RootGroup) {
        return (this.parentGroup.Id === group.Id && this.parentGroup.Name === group.Name) || this.parentGroup.ParentProduct.RootGroupIs(group);
    }

    AfterInit() {
        this.BindEvents();

        if (!this.readOnly()) {
            this.InitActionExpressions();
        }
    }

    Dispose() {
        this.Groups().forEach(group => group.Dispose());
        this.PropertyGroups.forEach(group => group.Dispose());
        this._quantityChangeSubscription.dispose();
        this._priceChangeSubscription.dispose();

        super.Dispose();
    }

    private CreatePropertyGroups(propertyGroupsDescription: ProductPropertyGroupDescription[]) {
        return propertyGroupsDescription.map(propertyGroupDescription => {
            const propertyControls = propertyGroupDescription.Properties.map(property =>
                ProductPropertyControlFactory.CreatePropertyControl(property, this, this._orderEntityId, this._productEntityId));

            propertyControls.forEach(control => control.readOnly(this.readOnly()));

            return new ProductPropertyGroup(propertyGroupDescription.TranslatedName || propertyGroupDescription.Name, propertyControls);
        });
    }

    private CreateCustomFieldsGroup(productCustomFieldsDescription: ProductCustomFieldDescription[]) {
        const customFieldControls = productCustomFieldsDescription.map(productCustomFieldDescription => {
            const customFieldControl = ProductCustomFieldControlFactory.CreateCustomControl(productCustomFieldDescription, this);
            customFieldControl.readOnly(this.readOnly());

            return customFieldControl;
        });

        return new CustomFieldsGroup(customFieldControls);
    }

    private DefinePropertyAccessors() {
        this.GetPropertyControls().forEach(control => {
            Object.defineProperty(this, control.Name, {
                enumerable: false,
                get: () => control.GetValueForPrice()
            });
        });
    }

    private DefineCustomFieldAccessors() {
        this.GetCustomFieldControls().forEach(control => {
            Object.defineProperty(this, control.Name, {
                enumerable: false,
				get: () => control.GetValueForPrice() && control.GetValueForPrice().Value
            });
        });
    }

    private DefineQuantityAccessor() {
        Object.defineProperty(this, 'QUANTITY', {
            enumerable: false,
            get: () => this.Quantity(),
            set: (value) => this.Quantity(value)
        });
    }

    private DefinePriceAccessor() {
        Object.defineProperty(this, 'PRICE', {
            enumerable: false,
            get: () => this.Price(),
            set: (value) => this.Price(value)
        });
    }

    private BindEvents() {
        this._quantityChangeSubscription = this.Quantity
            .subscribe(() => {
                if (this._calculatePriceTimeout) {
                    clearTimeout(this._calculatePriceTimeout);
                }

                this._calculatePriceTimeout = setTimeout(() => this.DispatchEvent(ConfigurationPageEvents.QuantityChanged), 300);
            });

        this._priceChangeSubscription = this.Price
            .subscribe(() => {
                if (this._calculatePriceTimeout) {
                    clearTimeout(this._calculatePriceTimeout);
                }

                this._calculatePriceTimeout = setTimeout(() => this.DispatchEvent(ConfigurationPageEvents.PriceChanged), 300);
            });

        this.HandleEvent<GroupActivatingEventArgs>(ConfigurationPageEvents.GroupActivating)
            .When(eventArgs => eventArgs.Data.OldGroup && eventArgs.Data.OldGroup.ParentProduct === this)
            .Using(eventArgs => eventArgs.Data.OldGroup.Deactivate())
            .Always();

        this.HandleEvent<ProductGroup, ProductGroup>(ConfigurationPageEvents.GroupActivated)
            .When(eventArgs => eventArgs.Source.ParentProduct === this)
            .Using(eventArgs => this.SelectedGroup(eventArgs.Data))
            .Always();

        this.HandleEvent<ConfigurationPageVariable>(ConfigurationPageEvents.VariablesChanged)
            .When(() => !this.readOnly())
            .Using(eventArgs => this.SyncOnVariableValueChange(eventArgs.Data))
            .Always();

        this.HandleEvent<any, PropertyControl>(ConfigurationPageEvents.PropertyValueChanged)
            .When(() => !this.readOnly())
            .When(eventArgs => eventArgs.Source.IsPropertyOwner(this))
            .Using(eventArgs => this.SyncOnControlValueChange(eventArgs.Source, PropertyEvaluationContext.$this))
            .Always();

        this.HandleEvent<any, PropertyControl>(ConfigurationPageEvents.PropertyValueChanged)
            .When(() => !this.readOnly())
            .When(eventArgs => eventArgs.Source.IsPropertyOwner(this.GetParent()))
            .Using(eventArgs => this.SyncOnControlValueChange(eventArgs.Source, PropertyEvaluationContext.$parent))
            .Always();

        this.HandleEvent<any, PropertyControl>(ConfigurationPageEvents.PropertyValueChanged)
            .When(() => !this.readOnly())
            .When(eventArgs => !eventArgs.Source.IsPropertyOwner(this) && eventArgs.Source.IsPropertyOwner(this.GetRoot()))
            .Using(eventArgs => this.SyncOnControlValueChange(eventArgs.Source, PropertyEvaluationContext.$root))
            .Always();

        this.HandleEvent<any, PropertyControl>(ConfigurationPageEvents.PropertyValueChanged)
            .When(() => !this.readOnly())
            .When(eventArgs => !eventArgs.Source.IsPropertyOwner(this) &&
                eventArgs.Source.IsPropertyOwner(this.GetRoot().GetParent().MainProductPart))
            .Using(eventArgs => this.SyncOnControlValueChange(eventArgs.Source, PropertyEvaluationContext.$main))
            .Always();

        this.HandleEvent<any, CustomFieldControl>(ConfigurationPageEvents.CustomFieldValueChanged)
            .When(() => !this.readOnly())
            .When(eventArgs => eventArgs.Source.IsFieldOwner(this))
            .Using(eventArgs => this.SyncOnCustomFieldValueChange(eventArgs.Source, PropertyEvaluationContext.$this))
            .Always();

        this.HandleEvent<any, CustomFieldControl>(ConfigurationPageEvents.CustomFieldValueChanged)
            .When(() => !this.readOnly())
            .When(eventArgs => eventArgs.Source.IsFieldOwner(this.GetParent()))
            .Using(eventArgs => this.SyncOnCustomFieldValueChange(eventArgs.Source, PropertyEvaluationContext.$parent))
            .Always();

        this.HandleEvent<any, CustomFieldControl>(ConfigurationPageEvents.CustomFieldValueChanged)
            .When(() => !this.readOnly())
            .When(eventArgs => !eventArgs.Source.IsFieldOwner(this) && eventArgs.Source.IsFieldOwner(this.GetRoot()))
            .Using(eventArgs => this.SyncOnCustomFieldValueChange(eventArgs.Source, PropertyEvaluationContext.$root))
            .Always();

        this.HandleEvent<any, CustomFieldControl>(ConfigurationPageEvents.CustomFieldValueChanged)
            .When(() => !this.readOnly())
            .When(eventArgs => !eventArgs.Source.IsFieldOwner(this) &&
                eventArgs.Source.IsFieldOwner(this.GetRoot().GetParent().MainProductPart))
            .Using(eventArgs => this.SyncOnCustomFieldValueChange(eventArgs.Source, PropertyEvaluationContext.$main))
            .Always();

        this.HandleEvent<any, ProductPart>(ConfigurationPageEvents.QuantityChanged)
            .When(() => !this.readOnly())
            .When(eventArgs => eventArgs.Source === this)
            .Using(eventArgs => this.SyncOnQuantityChange(eventArgs.Source, PropertyEvaluationContext.$this))
            .Always();

        this.HandleEvent<any, ProductPart>(ConfigurationPageEvents.QuantityChanged)
            .When(() => !this.readOnly())
            .When(eventArgs => eventArgs.Source === this.GetParent())
            .Using(eventArgs => this.SyncOnQuantityChange(eventArgs.Source, PropertyEvaluationContext.$parent))
            .Always();

        this.HandleEvent<any, ProductPart>(ConfigurationPageEvents.QuantityChanged)
            .When(() => !this.readOnly())
            .When(eventArgs => eventArgs.Source === this.GetRoot())
            .Using(eventArgs => this.SyncOnQuantityChange(eventArgs.Source, PropertyEvaluationContext.$root))
            .Always();

        this.HandleEvent<any, ProductPart>(ConfigurationPageEvents.QuantityChanged)
            .When(() => !this.readOnly())
            .When(eventArgs => eventArgs.Source === this.GetRoot().GetParent().MainProductPart)
            .Using(eventArgs => this.SyncOnQuantityChange(eventArgs.Source, PropertyEvaluationContext.$main))
            .Always();

        this.HandleEvent<any, ProductPart>(ConfigurationPageEvents.PriceChanged)
            .When(() => !this.readOnly())
            .When(eventArgs => eventArgs.Source === this)
            .Using(eventArgs => this.SyncOnPriceChange(eventArgs.Source, PropertyEvaluationContext.$this))
            .Always();

        this.HandleEvent<any, ProductPart>(ConfigurationPageEvents.PriceChanged)
            .When(() => !this.readOnly())
            .When(eventArgs => eventArgs.Source === this.GetParent())
            .Using(eventArgs => this.SyncOnPriceChange(eventArgs.Source, PropertyEvaluationContext.$parent))
            .Always();

        this.HandleEvent<any, ProductPart>(ConfigurationPageEvents.PriceChanged)
            .When(() => !this.readOnly())
            .When(eventArgs => eventArgs.Source === this.GetRoot())
            .Using(eventArgs => this.SyncOnPriceChange(eventArgs.Source, PropertyEvaluationContext.$root))
            .Always();

        this.HandleEvent<any, ProductPart>(ConfigurationPageEvents.PriceChanged)
            .When(() => !this.readOnly())
            .When(eventArgs => eventArgs.Source === this.GetRoot().GetParent().MainProductPart)
            .Using(eventArgs => this.SyncOnPriceChange(eventArgs.Source, PropertyEvaluationContext.$main))
            .Always();

        this.HandleEvent<any, SelectProductPartEventArgs>(ConfigurationPageEvents.UnselectProduct)
            .When(eventArgs => this.Selected() &&
                this.Id == eventArgs.Data.Id &&
                this.parentGroup.Id == eventArgs.Data.GroupId &&
                this.parentGroup.Name == eventArgs.Data.GroupName &&
                this.KSeq == eventArgs.Data.KSeq &&
                this.KSeqGuid == eventArgs.Data.KSeqGuid &&
                _.isEqual(this._path, eventArgs.Data.Path) && !this.readOnly() && eventArgs.Data.isRemoveFromPriceItem)
            .Using(() => {
                this.HasCollapseGroup(true);
                this.DispatchEvent<ProductPart>(ConfigurationPageEvents.ProductPartUnSelecting, this);
            })
            .Always();

        this.HandleEvent<any, SelectProductPartEventArgs>(ConfigurationPageEvents.UnselectProduct)
            .When(eventArgs => this.Selected() &&
                this.Id == eventArgs.Data.Id &&
                this.parentGroup.Id == eventArgs.Data.GroupId &&
                this.parentGroup.Name == eventArgs.Data.GroupName &&
                this.KSeq == eventArgs.Data.KSeq &&
                this.KSeqGuid == eventArgs.Data.KSeqGuid &&
                _.isEqual(this._path, eventArgs.Data.Path) && !this.readOnly())
            .Using(() => this.DispatchEvent<ProductPart>(ConfigurationPageEvents.ProductPartUnSelecting, this))
            .Always();

        this.HandleEvent<PriceProductNavigatedEventArgs>(ConfigurationPageEvents.ProductNavigatedInPriceList)
            .When(eventArgs => this.Selected() &&
                this.Id == eventArgs.Data.Id &&
                this.parentGroup.Id == eventArgs.Data.GroupId &&
                this.parentGroup.Name == eventArgs.Data.GroupName &&
                this.KSeq == eventArgs.Data.KSeq &&
                this.KSeqGuid == eventArgs.Data.KSeqGuid &&
                _.isEqual(this._path, eventArgs.Data.Path) &&
                (eventArgs.Data.OnlyOneLevel || this.Level === eventArgs.Data.ActiveLevel))
            .Using(() => this.NavigateToThis())
            .Always();

        this.HandleEvent<any, SelectProductPartEventArgs>(ConfigurationPageEvents.SelectProduct)
            .When(eventArgs => !this.Selected() &&
                this.Id == eventArgs.Data.Id &&
                this.parentGroup.Id == eventArgs.Data.GroupId &&
                this.parentGroup.Name == eventArgs.Data.GroupName &&
                _.isEqual(this._path, eventArgs.Data.Path) && !this.readOnly())
            .Using(() => this.Select())
            .Always();

        this.HandleEvent<any, SelectProductPartEventArgs>(ConfigurationPageEvents.ToggleSelection)
            .When(eventArgs => {
                return this.Id == eventArgs.Data.Id &&
                    this.parentGroup.Id == eventArgs.Data.parentGroup.Id &&
                    this.parentGroup.Name == eventArgs.Data.parentGroup.Name &&
                    _.isEqual(this._path, eventArgs.Data.Path) && !this.readOnly();
            })
            .Using(() => this.Toggle())
            .Always();

        this.HandleEvent<ProductPart>(ConfigurationPageEvents.EvaluateCalculatedExpressionsOnUnselect)
            .Using((eventArgs) => this.EvaluateCalculatedExpressionsOnUnselect(eventArgs.Data))
            .Always();
    }

    private UnSelectDependencies() {
        this.Groups().forEach(group => {
            group.UnSelectActiveProduct();
            group.Deactivate();
        });
    }

    private InitPropertyGroups() {
        this.PropertyGroups.forEach(group => group.OnInit());
    }

    private DeclareVariables() {
        this.PropertyEvaluationExpressions
            .filter(expressionDescriptor => expressionDescriptor.Destination.startsWith('$'))
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action') === -1)
            .filter(expressionDescriptor => this.IsExpressionCorrect(expressionDescriptor))
            .forEach(expressionDescriptor => this._variablesStore.Declare(expressionDescriptor.Destination, this.EvaluateExpression(expressionDescriptor)));
    }

    private CheckActionVariableExpressions() {
        const actionExpressionsWithVariables = this.PropertyEvaluationExpressions
            .filter(expressionDescriptor => expressionDescriptor.Destination.startsWith('$'))
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action') > -1);

        this.PropertyEvaluationExpressions
            .filter(expressionDescriptor => _.any(actionExpressionsWithVariables, actionExpressionsWithVariables =>
                expressionDescriptor.Expression.indexOf(actionExpressionsWithVariables.Destination) > -1))
            .forEach(expressionDescriptor => {
                expressionDescriptor.SkipError = true;
            });
    }

    private ClearPropertyValues() {
        this.PropertyGroups.forEach(propertyGroup => propertyGroup.ClearPropertyValues());
    }

    private ClearCustomFieldValues() {
        this.CustomFieldsGroup.ClearCustomFieldValues();
    }

    private SetBasePrice() {
        this.Price(this.basePrice);
    }

    private SetBaseQuantity() {
        this.Quantity(this._baseQuantity);
    }

    private InitPropertyValues() {
        this.PropertyEvaluationExpressions
            .filter(expressionDescriptor => expressionDescriptor.Destination.indexOf('$') === -1)
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action') === -1)
            .forEach(expressionDescriptor => this.EvaluateExpressionForControl(expressionDescriptor));
    }

    private InitCustomFieldValues() {
        this.PropertyEvaluationExpressions
            .filter(expressionDescriptor => expressionDescriptor.Destination.indexOf('$') === -1)
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action') === -1)
            .forEach(expressionDescriptor => this.EvaluateExpressionForCustomField(expressionDescriptor));
    }

    private InitActionExpressions() {
        this.PropertyEvaluationExpressions
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action') > -1)
            .forEach(expressionDescriptor => this.EvaluateActionExpression(expressionDescriptor));
    }

    InitActionDependsOnExpressions(actionDependsOnValues: ActionDependsOnFieldValueResponse[]) {
        this.PropertyEvaluationExpressions
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action.dependsOn') > -1)
            .forEach(expressionDescriptor => {
                const fieldName = expressionDescriptor.Expression
                    .replace(/^(\$action.dependsOn\(\')+|(\'\))+$/g, "")
                    ?.split('.')[1];
                const fieldValue = _.find(actionDependsOnValues, actionDependsOnValue => actionDependsOnValue.Name === fieldName)?.Value;

                const newExpressionDescriptor = new PropertyEvaluationExpression(expressionDescriptor.Id, expressionDescriptor.Destination, fieldValue);

                const propertyControl = this.GetPropertyControlByExpression(newExpressionDescriptor);
                if (propertyControl && !propertyControl.GetValueForSave()) {
                    if (propertyControl instanceof LookupPropertyControl) {
                        const actionExpressionValue = Number(newExpressionDescriptor.Expression);
                        propertyControl.SetActionExpressionValue(actionExpressionValue);
                        return;
                    }

                    propertyControl.SetValue(new PropertyValue(0, newExpressionDescriptor.Expression));
                }

                const customFieldControl = this.GetCustomFieldControlByExpression(newExpressionDescriptor);
                if (customFieldControl && !customFieldControl.GetValueForSave().Value) {
                    if (customFieldControl instanceof CustomLookupControl) {
                        const actionExpressionValue = Number(newExpressionDescriptor.Expression)
                        customFieldControl.SetActionExpressionValue(actionExpressionValue);
                        return;
                    }

                    customFieldControl.SetValue(new CustomFieldValue(0, newExpressionDescriptor.Expression));
                }

                if (newExpressionDescriptor.Destination.startsWith('$')) {
                    this._variablesStore.Declare(newExpressionDescriptor.Destination, newExpressionDescriptor.Expression);
                    this.PropertyEvaluationExpressions
                        .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf(newExpressionDescriptor.Destination) > -1)
                        .forEach(expressionDescriptor => {
                            expressionDescriptor.SkipEvaluation = true;
                            this.EvaluateExpressionForControl(expressionDescriptor);
                            this.EvaluateExpressionForCustomField(expressionDescriptor);
                        });
                }
            });
    }

    InitActionDefaultExpressions() {
        this.PropertyEvaluationExpressions
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action.default') > -1)
            .forEach(expressionDescriptor => {
                const defaultValue = expressionDescriptor.Expression
                    .replace(/^(\$action.default\()+|(\))+$/g, "")
                    .replace(/^(\')+|(\')+$/g, "");

                const newExpressionDescriptor = new PropertyEvaluationExpression(expressionDescriptor.Id, expressionDescriptor.Destination, defaultValue);

                const propertyControl = this.GetPropertyControlByExpression(newExpressionDescriptor);
                if (propertyControl && !propertyControl.GetValueForSave()) {
                    if (propertyControl instanceof LookupPropertyControl) {
                        const actionExpressionValue = Number(newExpressionDescriptor.Expression);
                        propertyControl.SetActionExpressionValue(actionExpressionValue);
                        return;
                    }

                    propertyControl.SetValue(new PropertyValue(0, newExpressionDescriptor.Expression));
                }

                const customFieldControl = this.GetCustomFieldControlByExpression(newExpressionDescriptor);
                if (customFieldControl && !customFieldControl.GetValueForSave().Value) {
                    if (customFieldControl instanceof CustomLookupControl) {
                        const actionExpressionValue = Number(newExpressionDescriptor.Expression);
                        customFieldControl.SetActionExpressionValue(actionExpressionValue);
                        return;
                    }

                    customFieldControl.SetValue(new CustomFieldValue(0, newExpressionDescriptor.Expression));
                }

                if (newExpressionDescriptor.Destination.startsWith('$')) {
                    this._variablesStore.Declare(newExpressionDescriptor.Destination, newExpressionDescriptor.Expression);
                    this.PropertyEvaluationExpressions
                        .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf(newExpressionDescriptor.Destination) > -1)
                        .forEach(expressionDescriptor => {
                            expressionDescriptor.SkipEvaluation = true;
                            this.EvaluateExpressionForControl(expressionDescriptor);
                            this.EvaluateExpressionForCustomField(expressionDescriptor);
                        });
                }
            });
    }

    GetActionDependsOnDto() {
        if (this.readOnly()){
            return null;
        }

        const actionDependsOnExpressions = this.PropertyEvaluationExpressions
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action.dependsOn') > -1)

        if (!_.any(actionDependsOnExpressions)){
            return null;
        }

        const entityName = actionDependsOnExpressions[0].Expression
            .replace(/^(\$action.dependsOn\(\')+|(\'\))+$/g, "")
            ?.split('.')[0];

        if (!entityName){
            return null;
        }

        let fieldNames = [];
        _.each(actionDependsOnExpressions, expression => {
            const fieldName = expression.Expression
                .replace(/^(\$action.dependsOn\(\')+|(\'\))+$/g, "")
                ?.split('.')[1];
            if (!fieldName) {
                return;
            }

            fieldNames.push(fieldName);
        });

        return new GetActionDependsOnValuesDto(entityName, fieldNames, this.Id);
    }

    private EvaluateExpressionForControl(expressionDescriptor: PropertyEvaluationExpression) {
        const control = this.GetPropertyControlByExpression(expressionDescriptor);

        if (!control || !this.IsExpressionCorrect(expressionDescriptor)) {
            return;
        }

        control.SetValue(new PropertyValue(0, this.EvaluateExpression(expressionDescriptor)));
    }

    private EvaluateExpressionForCustomField(expressionDescriptor: PropertyEvaluationExpression) {
        let customFieldControl: CustomFieldControl;

        if (expressionDescriptor.Destination.indexOf('$main') > -1) {
            if (expressionDescriptor.Destination.endsWith('.QUANTITY')) {
                if (this.IsExpressionCorrect(expressionDescriptor)) {
                    this.GetRoot().GetParent().MainProductPart.Quantity(Number(this.EvaluateExpression(expressionDescriptor)));
                }
                return;
            }

            customFieldControl = this.GetRoot().GetParent().MainProductPart.GetCustomFieldControl(expressionDescriptor.Destination.replace("$main.", ""));
        } else if (expressionDescriptor.Destination.indexOf('$root') > -1) {
            if (expressionDescriptor.Destination.endsWith('.QUANTITY')) {
                if (this.IsExpressionCorrect(expressionDescriptor)) {
                    this.GetRoot().Quantity(Number(this.EvaluateExpression(expressionDescriptor)));
                }
                return;
            }

            customFieldControl = this.GetRoot().GetCustomFieldControl(expressionDescriptor.Destination.replace("$root.", ""));
        } else if (expressionDescriptor.Destination.indexOf('$parent') > -1) {
            const parentElement = this.ParentGroup.IsExtra ? this.GetRoot() : this.GetParent();

            if (expressionDescriptor.Destination.endsWith('.QUANTITY')) {
                if (this.IsExpressionCorrect(expressionDescriptor)) {
                    parentElement.Quantity(Number(this.EvaluateExpression(expressionDescriptor)));
                }
                return;
            }

            customFieldControl = parentElement.GetCustomFieldControl(expressionDescriptor.Destination.replace("$parent.", ""));
        } else {
            if (expressionDescriptor.Destination === 'QUANTITY') {
                if (this.IsExpressionCorrect(expressionDescriptor)) {
                    this.Quantity(Number(this.EvaluateExpression(expressionDescriptor)));
                }
                return;
            } else if (expressionDescriptor.Destination === 'PRICE') {
                if (this.IsExpressionCorrect(expressionDescriptor)) {
                    this.Price(Number(this.EvaluateExpression(expressionDescriptor)));
                }
                return;
            }

            customFieldControl = this.GetCustomFieldControl(expressionDescriptor.Destination);
        }

        if (!customFieldControl || !this.IsExpressionCorrect(expressionDescriptor)) {
            return;
        }

        customFieldControl.SetValue(new CustomFieldValue(0, this.EvaluateExpression(expressionDescriptor)));
    }

    private EvaluateActionExpression(expressionDescriptor: PropertyEvaluationExpression) {
        if (expressionDescriptor.Expression.indexOf('$action.disable') > -1 &&
            expressionDescriptor.Destination === `$group(\'${this.ParentGroup.Name}\')` &&
            this.IsExpressionCorrect(expressionDescriptor)) {
            this.IsDisabledByAction(!!this.EvaluateExpression(expressionDescriptor));
        } else if (expressionDescriptor.Expression.indexOf('$action.hide') > -1 &&
            (expressionDescriptor.Destination === `$group(\'${this.ParentGroup.Name}\')` ||
                (expressionDescriptor.Destination === '$group($main)' && this.IsMain()))) {
            const fieldName = expressionDescriptor.Expression.replace(/^(\$action.hide\(\')+|(\'\))+$/g, "");

            _.each(this.CustomFieldsGroup.CustomFieldsControls, customFieldControl => {
                if (customFieldControl.Name && customFieldControl.Name.toUpperCase() === fieldName.toUpperCase()) {
                    customFieldControl.IsHiddenByAction = true;
                }
            });

            _.each(this.PropertyGroups, propertyGroup => {
                _.each(propertyGroup.PropertyControls, propertyControl => {
                    if (propertyControl.Name && propertyControl.Name.toUpperCase() === fieldName.toUpperCase()) {
                        propertyControl.IsHiddenByAction = true;
                    }
                });
            });
        }
    }

    private GetPropertyControlByExpression(expressionDescriptor: PropertyEvaluationExpression) {
        let control: PropertyControl;
        if (expressionDescriptor.Destination.indexOf('$main') > -1) {
            control = this.GetRoot().GetParent().MainProductPart.GetPropertyControl(expressionDescriptor.Destination.replace("$main.", ""));
        } else if (expressionDescriptor.Destination.indexOf('$root') > -1) {
            control = this.GetRoot().GetPropertyControl(expressionDescriptor.Destination.replace("$root.", ""));
        } else if (expressionDescriptor.Destination.indexOf('$parent') > -1) {
            const parentElement = this.ParentGroup.IsExtra ? this.GetRoot() : this.GetParent();
            control = parentElement.GetPropertyControl(expressionDescriptor.Destination.replace("$parent.", ""));
        } else {
            control = this.GetPropertyControl(expressionDescriptor.Destination);
        }

        return control;
    }

    private GetCustomFieldControlByExpression(expressionDescriptor: PropertyEvaluationExpression) {
        let customFieldControl: CustomFieldControl;

        if (expressionDescriptor.Destination.indexOf('$main') > -1) {
            customFieldControl = this.GetRoot().GetParent().MainProductPart.GetCustomFieldControl(expressionDescriptor.Destination.replace("$main.", ""));
        } else if (expressionDescriptor.Destination.indexOf('$root') > -1) {
            customFieldControl = this.GetRoot().GetCustomFieldControl(expressionDescriptor.Destination.replace("$root.", ""));
        } else if (expressionDescriptor.Destination.indexOf('$parent') > -1) {
            const parentElement = this.ParentGroup.IsExtra ? this.GetRoot() : this.GetParent();
            customFieldControl = parentElement.GetCustomFieldControl(expressionDescriptor.Destination.replace("$parent.", ""));
        } else {
            customFieldControl = this.GetCustomFieldControl(expressionDescriptor.Destination);
        }

        return customFieldControl;
    }

    private SyncOnControlValueChange(triggeredPropertyControl: PropertyControl, evaluationContext: string) {
        let variablesEvaluationExpressions = [];

        if (triggeredPropertyControl.IsPropertyOwner(this)) {
            variablesEvaluationExpressions = this.PropertyEvaluationExpressions
                .filter(expressionDescriptor => expressionDescriptor.HasVariableDestination() &&
                    expressionDescriptor.Expression.indexOf(`${evaluationContext}.${triggeredPropertyControl.Name}`) > -1);
        }

        variablesEvaluationExpressions
            .filter(expressionDescriptor => this.IsExpressionCorrect(expressionDescriptor))
            .forEach(expressionDescriptor => this._variablesStore.Set(expressionDescriptor.Destination, this.EvaluateExpression(expressionDescriptor)));

        const changedVariables = variablesEvaluationExpressions.map(expressionDescriptor => expressionDescriptor.Destination);
        let expressionsToReevaluate = this.PropertyEvaluationExpressions
            .filter(expressionDescriptor => !(triggeredPropertyControl.IsPropertyOwner(this) && expressionDescriptor.Destination === triggeredPropertyControl.Name) ||
                (!triggeredPropertyControl.IsPropertyOwner(this) && expressionDescriptor.Expression.indexOf("$main") > -1))
            .filter(expressionDescriptor => !expressionDescriptor.HasEvaluationContext() ||
                expressionDescriptor.Expression.indexOf(`${evaluationContext}.${triggeredPropertyControl.Name}`) > -1)
            .filter(expressionDescriptor => _.all(changedVariables, changedVariable => expressionDescriptor.Expression.indexOf(changedVariable) === -1));

        let actionExpressionsToReevaluate = expressionsToReevaluate
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action') > -1);

        if (actionExpressionsToReevaluate && _.any(actionExpressionsToReevaluate)) {
            actionExpressionsToReevaluate.forEach(expressionDescriptor => this.EvaluateActionExpression(expressionDescriptor));
            this.DispatchEvent<ProductPart>(ConfigurationPageEvents.CheckSaveDisabilityByAction, this);
        }

        if (!this.Selected()) {
            return;
        }

        let nonActionExpressionsToReevaluate = expressionsToReevaluate
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action') === -1);

        nonActionExpressionsToReevaluate.forEach(expressionDescriptor => this.EvaluateExpressionForControl(expressionDescriptor));
        nonActionExpressionsToReevaluate.forEach(expressionDescriptor => this.EvaluateExpressionForCustomField(expressionDescriptor));

        this.DispatchEvent(ConfigurationPageEvents.ProductPropertyValueChanged, this);

        if (!triggeredPropertyControl.PriceInfluence) {
            return;
        }

        if (nonActionExpressionsToReevaluate.length > 0 || triggeredPropertyControl.IsPropertyOwner(this)) {
            this.CalculatePrice();
        }
    }

    private SyncOnCustomFieldValueChange(triggeredCustomFieldControl: CustomFieldControl, evaluationContext: string) {
        let variablesEvaluationExpressions = [];

        if (triggeredCustomFieldControl.IsFieldOwner(this)) {
            variablesEvaluationExpressions = this.PropertyEvaluationExpressions
                .filter(expressionDescriptor => expressionDescriptor.HasVariableDestination() &&
                    expressionDescriptor.Expression.indexOf(`${evaluationContext}.${triggeredCustomFieldControl.Name}`) > -1);
        }

        variablesEvaluationExpressions
            .filter(expressionDescriptor => this.IsExpressionCorrect(expressionDescriptor))
            .forEach(expressionDescriptor => this._variablesStore.Set(expressionDescriptor.Destination, this.EvaluateExpression(expressionDescriptor)));

        const changedVariables = variablesEvaluationExpressions.map(expressionDescriptor => expressionDescriptor.Destination);
        let expressionsToReevaluate = this.PropertyEvaluationExpressions
            .filter(expressionDescriptor => !(triggeredCustomFieldControl.IsFieldOwner(this) && expressionDescriptor.Destination === triggeredCustomFieldControl.Name) ||
                (!triggeredCustomFieldControl.IsFieldOwner(this) && expressionDescriptor.Expression.indexOf("$main") > -1))
            .filter(expressionDescriptor => !expressionDescriptor.HasEvaluationContext() ||
                expressionDescriptor.Expression.indexOf(`${evaluationContext}.${triggeredCustomFieldControl.Name}`) > -1)
            .filter(expressionDescriptor => _.all(changedVariables, changedVariable => expressionDescriptor.Expression.indexOf(changedVariable) === -1));

        let actionExpressionsToReevaluate = expressionsToReevaluate
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action') > -1);

        if (actionExpressionsToReevaluate && _.any(actionExpressionsToReevaluate)) {
            actionExpressionsToReevaluate.forEach(expressionDescriptor => this.EvaluateActionExpression(expressionDescriptor));
            this.DispatchEvent<ProductPart>(ConfigurationPageEvents.CheckSaveDisabilityByAction, this);
        }

        if (!this.Selected()) {
            return;
        }

        let nonActionExpressionsToReevaluate = expressionsToReevaluate
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action') === -1);

        nonActionExpressionsToReevaluate.forEach(expressionDescriptor => this.EvaluateExpressionForControl(expressionDescriptor));
        nonActionExpressionsToReevaluate.forEach(expressionDescriptor => this.EvaluateExpressionForCustomField(expressionDescriptor));

        this.DispatchEvent(ConfigurationPageEvents.ProductCustomFieldValueChanged, this);

        if (!triggeredCustomFieldControl.PriceInfluence) {
            return;
        }

        if (nonActionExpressionsToReevaluate.length > 0 || triggeredCustomFieldControl.IsFieldOwner(this)) {
            this.CalculatePrice();
        }
    }

    private SyncOnVariableValueChange(variable: ConfigurationPageVariable) {
        let expressionsToReevaluate = this.PropertyEvaluationExpressions
            .filter(expressionDescriptor => expressionDescriptor.HasVariableDestination())
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf(variable.Name) > -1);

        let actionExpressionsToReevaluate = expressionsToReevaluate
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action') > -1);

        if (actionExpressionsToReevaluate && _.any(actionExpressionsToReevaluate)) {
            actionExpressionsToReevaluate.forEach(expressionDescriptor => this.EvaluateActionExpression(expressionDescriptor));
            this.DispatchEvent<ProductPart>(ConfigurationPageEvents.CheckSaveDisabilityByAction, this);
        }

        if (!this.Selected()) {
            return;
        }

        let nonActionExpressionsToReevaluate = expressionsToReevaluate
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action') === -1);

        nonActionExpressionsToReevaluate.forEach(expressionDescriptor => {
            this.EvaluateExpressionForControl(expressionDescriptor);
            this.EvaluateExpressionForCustomField(expressionDescriptor);
        });
    }

    private SyncOnQuantityChange(triggeredIn: ProductPart, evaluationContext: string) {
        let variablesEvaluationExpressions = [];

        if (triggeredIn === this) {
            variablesEvaluationExpressions = this.PropertyEvaluationExpressions
                .filter(expressionDescriptor => expressionDescriptor.HasVariableDestination() &&
                    expressionDescriptor.Expression.indexOf(`${evaluationContext}.QUANTITY`) > -1);
        }

        variablesEvaluationExpressions
            .filter(expressionDescriptor => this.IsExpressionCorrect(expressionDescriptor))
            .forEach(expressionDescriptor => this._variablesStore.Set(expressionDescriptor.Destination, this.EvaluateExpression(expressionDescriptor)));

        const changedVariables = variablesEvaluationExpressions.map(expressionDescriptor => expressionDescriptor.Destination);
        let expressionsToReevaluate = this.PropertyEvaluationExpressions
            .filter(expressionDescriptor => !(triggeredIn === this && expressionDescriptor.Destination === 'QUANTITY') ||
                (triggeredIn !== this && expressionDescriptor.Expression.indexOf("$main") > -1))
            .filter(expressionDescriptor => !expressionDescriptor.HasEvaluationContext() ||
                expressionDescriptor.Expression.indexOf(`${evaluationContext}.QUANTITY`) > -1)
            .filter(expressionDescriptor => _.all(changedVariables, changedVariable => expressionDescriptor.Expression.indexOf(changedVariable) === -1));

        let actionExpressionsToReevaluate = expressionsToReevaluate
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action') > -1);

        if (actionExpressionsToReevaluate && _.any(actionExpressionsToReevaluate)) {
            actionExpressionsToReevaluate.forEach(expressionDescriptor => this.EvaluateActionExpression(expressionDescriptor));
            this.DispatchEvent<ProductPart>(ConfigurationPageEvents.CheckSaveDisabilityByAction, this);
        }

        if (!this.Selected()) {
            return;
        }

        let nonActionExpressionsToReevaluate = expressionsToReevaluate
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action') === -1);

        nonActionExpressionsToReevaluate.forEach(expressionDescriptor => {
            this.EvaluateExpressionForControl(expressionDescriptor);
            this.EvaluateExpressionForCustomField(expressionDescriptor);
        });
    }

    private SyncOnPriceChange(triggeredIn: ProductPart, evaluationContext: string) {
        let variablesEvaluationExpressions = [];

        if (triggeredIn === this || (triggeredIn.IsMain && evaluationContext === PropertyEvaluationContext.$main)) {
            variablesEvaluationExpressions = this.PropertyEvaluationExpressions
                .filter(expressionDescriptor => expressionDescriptor.HasVariableDestination() &&
                    expressionDescriptor.Expression.indexOf(`${evaluationContext}.PRICE`) > -1);
        }

        variablesEvaluationExpressions
            .filter(expressionDescriptor => this.IsExpressionCorrect(expressionDescriptor))
            .forEach(expressionDescriptor => this._variablesStore.Set(expressionDescriptor.Destination, this.EvaluateExpression(expressionDescriptor)));

        const changedVariables = variablesEvaluationExpressions.map(expressionDescriptor => expressionDescriptor.Destination);
        let expressionsToReevaluate = this.PropertyEvaluationExpressions
            .filter(expressionDescriptor => !(triggeredIn === this && expressionDescriptor.Destination === 'PRICE') ||
                (triggeredIn !== this && expressionDescriptor.Expression.indexOf("$main") > -1))
            .filter(expressionDescriptor => !expressionDescriptor.HasEvaluationContext() ||
                expressionDescriptor.Expression.indexOf(`${evaluationContext}.PRICE`) > -1)
            .filter(expressionDescriptor => _.all(changedVariables, changedVariable => expressionDescriptor.Expression.indexOf(changedVariable) === -1));

        let actionExpressionsToReevaluate = expressionsToReevaluate
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action') > -1);

        if (actionExpressionsToReevaluate && _.any(actionExpressionsToReevaluate)) {
            actionExpressionsToReevaluate.forEach(expressionDescriptor => this.EvaluateActionExpression(expressionDescriptor));
            this.DispatchEvent<ProductPart>(ConfigurationPageEvents.CheckSaveDisabilityByAction, this);
        }

        if (!this.Selected()) {
            return;
        }

        let nonActionExpressionsToReevaluate = expressionsToReevaluate
            .filter(expressionDescriptor => expressionDescriptor.Expression.indexOf('$action') === -1);

        nonActionExpressionsToReevaluate.forEach(expressionDescriptor => {
            this.EvaluateExpressionForControl(expressionDescriptor);
            this.EvaluateExpressionForCustomField(expressionDescriptor);
        });
    }

    private IsPriceInDestination() {
        return this.PropertyEvaluationExpressions &&
            _.any(this.PropertyEvaluationExpressions, expressionDescriptor => expressionDescriptor.Destination === 'PRICE');
    }

    private CalculatePrice() {
        if (this._isPriceInDestination()) {
            return;
        }

        this.CalculatePriceWithDelay(500);
    }

    private CalculatePriceWithDelay(delay: number) {
        if (this._calculatePriceTimeout) {
            clearTimeout(this._calculatePriceTimeout);
        }

        this._calculatePriceTimeout = setTimeout(() => this.DispatchEvent(ConfigurationPageEvents.PriceCalculationRequested), delay);
    }

    private BuildJsExpression(expressionDescriptor: PropertyEvaluationExpression) {
        const declaredVariables = this._variablesStore.GetList()
            .sort((v1, v2) => v1.Name.length > v2.Name.length ? 1 : -1);

        let newExpression = expressionDescriptor.Expression
            .split(PropertyEvaluationContext.$this).join('this')
            .split(PropertyEvaluationContext.$parent).join('this.GetParent()')
            .split(PropertyEvaluationContext.$root).join('this.GetRoot()')
            .split(PropertyEvaluationContext.$main).join('this.GetRoot().GetParent().MainProductPart')
            .split('$action.disable').join('');

        declaredVariables.forEach(v => newExpression = newExpression.replace(v.Name, v.Value));
        return newExpression;
    }

    private IsExpressionCorrect(expressionDescriptor) {
        try {
            if (expressionDescriptor.SkipEvaluation) {
                return true;
            }

            const jsExpression = this.BuildJsExpression(expressionDescriptor);
            eval(jsExpression);
            return true;
        }
        catch (error) {
            if (!expressionDescriptor.SkipError) {
                console.error(error);
            }
            return false;
        }
    }

    private EvaluateExpression(expressionDescriptor: PropertyEvaluationExpression) {
		const jsExpression = this.BuildJsExpression(expressionDescriptor);
		if (expressionDescriptor.SkipEvaluation) {
		    return jsExpression;
        }

		const evaluatedValue = eval(jsExpression);
		return evaluatedValue || evaluatedValue === 0 || evaluatedValue === '' ? evaluatedValue : null;
    }

    private GetParent() {
        return this.parentGroup.ParentProduct as ProductPart;
    }

    private GetRoot() {
        if (this.ParentGroup.IsRoot) {
            return this;
        }

        const parentProduct = this.GetParent();
        return parentProduct.GetRoot();
    }

    private BuildPathToRoot(): number[] {
        if (this.parentGroup.IsRoot) {
            return [];
        }

        const parentProduct = this.GetParent();
        return [...parentProduct.BuildPathToRoot(), parentProduct.Id];
    }

    private NavigateToThis() {
        let products: ProductPart[] = [];
        let product = this as ProductPart;

        while (product instanceof ProductPart) {
            products.unshift(product);
            product = product.GetParent();
        }

        products.forEach(product => product.ParentGroup.Expand());
        window.location.hash = `#${this._guid}`;
    }

    private HasPropertyControl(propertyControl: PropertyControl) {
        return !!this.PropertyGroups.find(group => group.HasPropertyControl(propertyControl));
    }

    private GetPropertyControls(): PropertyControl[] {
        return _.chain(this.PropertyGroups).map(propertyGroup => propertyGroup.PropertyControls).flatten().value() as PropertyControl[];
    }

    private GetCustomFieldControls(): CustomFieldControl[] {
        return this.CustomFieldsGroup.CustomFieldsControls;
    }

    private GetPropertyControl(name: string): PropertyControl {
        return _.find(this.GetPropertyControls(), c => c.Name === name);
    }

    private GetCustomFieldControl(name: string): CustomFieldControl {
        return _.find(this.GetCustomFieldControls(), c => c.Name === name);
    }
}