import {deserialize} from "libs/cerialize";
import { deserializeAs } from "libs/cerialize";
import * as ko from "knockout";

export class TagModel {

	@deserialize
	Name: string;

	@deserialize
	TagId: number;

	@deserialize
	TypeId: number;

	@deserialize
	GroupId: number;

	@deserialize
	GroupName: string;
}

export class TagGroupModel {
	constructor() {
		this.Tags = [];
		this.GroupName = '';
	}

	GroupId: number;

	GroupName: string;

	Tags: Array<TagModel>;
}

export class TagListScreenModel {
	@deserialize
	ScreenId: number;

	@deserialize
	EntityName: number;
}

export class TagsScreenDataModel {
	constructor() {
		this.Tags = [];
		this.TagListScreens = [];
	}

	@deserialize
	ControlId: number;

	@deserializeAs(TagModel)
	Tags: Array<TagModel>;

	@deserializeAs(TagListScreenModel)
	TagListScreens: Array<TagListScreenModel>;
}

