import {deserialize, deserializeAs} from "libs/cerialize";

export enum Rights {
	Show = 2,
	Edit = 4,
	Delete = 8,
	Create = 1024
}

export class TalbeModel {
	Id: number;
	Name: string;
}

export class RightModel {
	@deserialize
	Name: string;

	@deserialize
	Value: number;

	@deserialize
	IsChecked: boolean;
}

export class TableModel {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserializeAs(RightModel)
	Rights: Array<RightModel>;

	constructor() {
		this.Rights = [];
	}
}

export class TableSecurityModel {
	@deserializeAs(TableModel)
	Tables: Array<TableModel>;

	constructor() {
		this.Tables = [];
	}
}