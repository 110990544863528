import * as ko from 'knockout'
import * as _ from 'underscore';

import { BaseControl, IControlValue } from 'Core/Controls/BaseControl/BaseControl'
import { SubForm } from 'Core/Screens/Forms/SubForm/SubForm'
import { IControlParam } from 'Core/Screens/IScreen'
import { BlockUI } from 'Core/Common/BlockUi';
import { HistoryLogsStore, IHistoryRequestModel } from 'Core/Controls/History/Stores/HistoryLogsStore';
import { HistoryLogsModel } from 'Core/Controls/History/Models/HistoryLogsModel';
import { HistoryLogs } from 'Core/Controls/History/HistoryLogs';
import { Modal } from 'Core/Common/Modal'
import { RenderModes } from "Core/Constant";
import { GeneralProperties } from "Core/GeneralProperties/GeneralProperties";
import {CHECKBOX_PROPERTIES} from "Core/GeneralProperties/Managers/CheckboxProperty/Constants";

import HistoryButtonConfig from "Core/Controls/History/Configs/history-button-config.json";

import ViewTemplate from "Core/Controls/History/Templates/View.html"
import HelpViewTemplate from "Core/Controls/History/Templates/HelpView.html"
import DesignTemplate from "Core/Controls/History/Templates/Design.html"
import ToolbarTemplate from "Core/Controls/History/Templates/ToolBar.html"
import HistoryContentTemplate from "Core/Controls/History/Templates/HistoryContent.html"


ko.templates["Core/Controls/History/Templates/ToolBar"] = ToolbarTemplate;
ko.templates["Core/Controls/History/Templates/HistoryContent"] = HistoryContentTemplate;

ko.templates["Core/Controls/History/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/History/Templates/HelpView"] = HelpViewTemplate;
ko.templates["Core/Controls/History/Templates/Edit"] = ViewTemplate;
ko.templates["Core/Controls/History/Templates/Design"] = DesignTemplate;


export class History extends BaseControl {
	private _modal: Modal;
	private _historyLogsData: HistoryLogsModel;
	private _historyLogs: HistoryLogs;
	private _recordId: KnockoutObservable<number>;
	private _hasHistory: KnockoutObservable<boolean>;

    private _config: any;


	constructor(params: IControlParam) {
		super(params, HistoryButtonConfig);
		this._recordId = ko.observable(null);
		this._hasHistory = ko.observable(false);
        this._style = ko.computed(() => {
            return {
                backgroundColor: this._backgroundColor(),
                color: this._color(),
                borderColor: this._borderColor(),
                borderWidth: this._border() ? '1px' : '0',
                padding: this._border() ? '6px 14px' : '7px 14px'
            };
        });
		this.Init();
	}

	private Init(): void {
		if (!this._form) return;
        this.ApplyProperties();
	}

	SetValue(value: IControlValue): void {
		this._recordId(value.SubjectRecordId);
		this.CheckHistory()
    }

	CheckHistory(){
		const requestModel = {
			EntityId: this._form.GetScreen().GetEntityId(),
			RecordId: this._form.GetScreen().GetRecordId()
		};

		(async ()=> { this._hasHistory(await HistoryLogsStore.HasHistory(requestModel)); })();
	}

	Click(): void {
		if (!this._recordId() || !this._hasHistory()) {
			return
		}
		if (this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
		} else {

			const requestModel = {
				EntityId: this._form.GetScreen().GetEntityId(),
				RecordId: this._form.GetScreen().GetRecordId()
			}

			this.GetHistoryData(requestModel);
		}
	}


	GetContentTemplateName() {
		return 'Core/Controls/History/Templates/HistoryContent';
	}

	GetHistoryData(requestModel: IHistoryRequestModel) {
		BlockUI.Block();
		HistoryLogsStore.GetHistory(requestModel)
			.then(model => {
				this._historyLogsData = model.ResultObject;
				this.Render();
			}).always(() => BlockUI.Unblock());
	}

	Render(): void {
		var self = this;

		this._historyLogs = new HistoryLogs(this._historyLogsData);

		this._modal = new Modal({
			addClass: 'history-logs-modal jBox-padding-15px'
		});

		this._modal.On("CLOSE", this, (eventArgs: any) => {
			$(window).off('keydown');
			//self.GatherSettings();
		});
		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this._historyLogs, this._modal.Wrapper);

		this.ShowModal();

	}

	Close(): void {
		$(window).off('keydown');
		this._modal.Close();
	}

	ShowModal() {
		if (this._modal) {
			this._modal.Show();
		}
	}

	SetHideCaption(data: boolean) {
		this.HideCaption(this.HideCaptionFromProperty() ? this.HideCaptionFromProperty() : data);
	}

	GetHideCaption(): boolean {
		return this.HideCaption();
	}

    ApplyProperties() {
        if (this.Properties) {
            this.AssignProperty('Styling', 'BackgroundColor', this._backgroundColor);
            this.AssignProperty('Styling', 'TextColor', this._color);
            this.AssignProperty('Styling', 'Border', this._border);
            this.AssignProperty('Styling', 'BorderColor', this._borderColor);
			this.AssignProperty('Styling', CHECKBOX_PROPERTIES.HIDE_CAPTION, this.HideCaptionFromProperty);
			this.HideCaption(this.HideCaptionFromProperty());
        }
    }

    private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
        if (this.Properties[groupedBy]) {
            _.each(this.Properties[groupedBy].Properties,
                (property: any) => {
                    if (property.hasOwnProperty(propertyName)) {
                        propertyHolder(property[propertyName]);
                    }
                });
        }
    }

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}
}