export class CustomFieldValueDto {
	Id: number;
	Value: any;
	IsCustomValue: boolean;
	IsValid: boolean;

	constructor(id?: number, value?: any, isCustomValue?: boolean, valid?: boolean) {
		this.Id = id;
		this.Value = value;
		this.IsCustomValue = isCustomValue;
		this.IsValid = valid;
	}
}