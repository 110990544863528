import {deserialize} from 'libs/cerialize';
import {deserializeAs} from 'libs/cerialize';
import {serialize} from 'libs/cerialize';
import {serializeAs} from 'libs/cerialize';
import {LanguageModel} from 'Core/Controls/BaseControl/Models/LanguageModel';
import * as ko from "knockout";

export class TranslationModel {

	IsFocusedItem: KnockoutObservable<boolean>;

	constructor(){
		this.IsFocusedItem = ko.observable(false);
	}

	@deserializeAs(LanguageModel)
	@serializeAs(LanguageModel)
	Language: LanguageModel;

	@deserialize
	@serialize
	Translation: string;

	@deserialize
	@serialize
	Selected: boolean;

	get IsDefault() {
		return this.Language.K_Language === -1;
	}
}