import {Notifier} from "Core/Common/Notifier";

import {NewThirdPartyDataRoleComponent} from "Core/Controls/FieldSecurity/Components/DataRole/NewDataRole/NewThirdPartyDataRoleComponent";

import {UserTypes} from "User/UserManager";
import {DataRoles} from "Core/Controls/FieldSecurity/Shared/Enums/DataRoles";

export class NewDPORoleComponent extends NewThirdPartyDataRoleComponent {
	constructor() {
		super(DataRoles.Instance.DPO);
	}

	AfterRender() {
		this.LoadUsers(UserTypes.Gdpr)
            .then(users => this.UsersLoaded(users))
			.fail(error => new Notifier().Failed(error.message));
	}
}