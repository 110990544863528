import {deserialize, deserializeAs} from 'libs/cerialize';

export class ProfileCardModel {
	
	@deserialize
	FirstName: string;

	@deserialize
	MiddleName: string;

	@deserialize
	Name: string;

	@deserialize
	UserId: number;

	@deserialize
	AvatarData: string;

	get NameTitle(){
		return `${this.FirstName} ${this.Name}`;
	}
}