import * as _ from'underscore';
import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {GenericDeserialize} from 'libs/cerialize';
import {LinkListRelationsModel} from 'Core/Controls/LinkList/Models/LinklistRelationsModel';

interface IGetAddressDto {
	SubjectEntityId: number;
	SubjectRecordId: number;
	ControlId: number;
}

export class MapsStore {
	public static GetAddress(params: IGetAddressDto): P.Promise<string> {
		var deferredResult = P.defer<string>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: 'api/MapsApi/GetAddress',
				type: 'GET'
			}, params: params
		};

		Request
		.Send(requestParams)
		.then(data => deferredResult.resolve(data));

		return deferredResult.promise();
	}

	public static CheckGoogleMapsKey(key: string): P.Promise<boolean> {
		var deferredResult = P.defer<boolean>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: 'api/MapsApi/CheckKey',
				type: 'GET'
			}, params: { key : key }
		};

		Request
		.Send(requestParams)
		.then(data =>  {
			 deferredResult.resolve(data);
		});

		return deferredResult.promise();
	}

}