import * as ko from 'knockout';
import * as _ from "underscore";
import * as moment from "moment";

import {BaseControl, IControlValue} from '../BaseControl/BaseControl';
import {IControlParam, IForm, IScreen} from 'Core/Screens/IScreen';

import TimerConfigJson from 'Core/Controls/Timer/Configs/timer-config.json';
import {GeneralProperties} from 'Core/GeneralProperties/GeneralProperties';
import {ActiveTimersModal} from 'Core/Controls/Timer/ActiveTimersModal/ActiveTimersModal';
import {TimerStore} from 'Core/Controls/Timer/Stores/TimerStore';

import {
    ConfirmationDialog, EVENTS as CONFIRMATION_DIALOG_EVENTS, EVENTS as ConfirmationDialogEvents,
    Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import {CONFIRMATIONS, LABELS} from "Core/Components/Translation/Locales";
import {DATE_FORMATS} from "Core/Constants/DateTimeFormats";
import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";

import {ActiveTimers} from "./Constants/ActiveTimers";

import {BlockUI} from "Core/Common/BlockUi";
import {Notifier} from 'Core/Common/Notifier';

import {LiveTimer} from "Core/Controls/Timer/LiveTimer";
import {TimerModel, IActiveTimer} from "Core/Controls/Timer/Models/TimerModel";
import {StartTimerResponseModel} from 'Core/Controls/Timer/Models/StartTimerResponseModel';
import {StopTimerResponseModel} from 'Core/Controls/Timer/Models/StopTimerResponseModel';
import {EventArgs} from "Core/Common/Event";

import {KanbanCard} from "Core/Controls/Kanban/KanbanCard"
import {ActionModel} from "Core/Controls/Kanban/Models/StatusModel";

import ViewTemplate from 'Core/Controls/Timer/Templates/View.html';
import EditTemplate from 'Core/Controls/Timer/Templates/Edit.html';
import DesignTemplate from 'Core/Controls/Timer/Templates/Design.html';
import ToolBarTemplate from 'Core/Controls/Timer/Templates/ToolBar.html';
import HelpViewTemplate from 'Core/Controls/Timer/Templates/HelpView.html';
import LiveTimerForKanbanTemplate from 'Core/Controls/Timer/Templates/LiveTimerForKanban.html';

ko.templates['Core/Controls/Timer/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/Timer/Templates/Edit'] = EditTemplate;
ko.templates['Core/Controls/Timer/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/Timer/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/Timer/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/Timer/Templates/LiveTimerForKanban'] = LiveTimerForKanbanTemplate;

export class Timer extends BaseControl {
    HasTimerStarted = ko.observable(false);
    private _defaultHourkind: number;
    private _defaultActiveTimers: string;

    private _activeTimers: KnockoutObservableArray<IActiveTimer>;

    private _timerEntityId: KnockoutObservable<number>;
    private _timerRecordId: KnockoutObservable<number>;
    private _currentDuration: KnockoutObservable<string>;

    private _liveTimer: KnockoutObservable<LiveTimer>;
    private _kanbanMode: boolean;
    private _notifier: Notifier;
    private _actionRecord: ActionModel;

    private _timerAutoStart: boolean;
    constructor(params: IControlParam) {
        super(params, TimerConfigJson);

        this._notifier = new Notifier();
        this._style = ko.computed(() => {
            return {
                backgroundColor: this._backgroundColor(),
                color: this._color(),
                borderColor: this._borderColor(),
                borderWidth: this._border() ? '1px' : '0'
            };
        });

        this._activeTimers = ko.observableArray([]);

        this._timerEntityId = ko.observable(null);
        this._timerRecordId = ko.observable(null);
        this._currentDuration = ko.observable(null);

        this._timerAutoStart = false;

        this.ApplyProperties();

        this._liveTimer = ko.observable(new LiveTimer(null));
        this._kanbanMode = false;
        this._actionRecord = null;

        this.BindEvents();
    }

    private BindEvents() {
        const self = this;

        PubSub.subscribe('STOP_ACTIVE_TIMERS',(message, data: Array<IActiveTimer>) =>{
            const stopTimer: IActiveTimer = data[0];

            if (stopTimer && this.TimerEntityId === stopTimer.EntityId && this.TimerRecordId === stopTimer.RecordId){
                self.HasTimerStarted(false);
                self.ResetTimersInActionRecord(self.ActionRecord);
                self.StopLiveTimer(self);
            }

            const idToDelete = stopTimer.Id;
            const indexToDelete = self._activeTimers().findIndex((item) => item.Id === idToDelete);
            if (indexToDelete !== -1) {
                self._activeTimers.splice(indexToDelete, 1);
            }
        })

        PubSub.subscribe('START_ACTIVE_TIMERS', (message, data: Array<TimerModel>) => {
            const startTimer: TimerModel = data[0];

            if (startTimer && this.TimerEntityId === startTimer.EntityId && this.TimerRecordId === startTimer.RecordId){
                this.HasTimerStarted(true);

                if (this.KanbanMode) {
                    this.ActionRecord.NewActiveTimerModel = startTimer;
                }
                this.StartLiveTimer(startTimer);

                const activeTimers: Array<IActiveTimer> = _.map(data, (timer: TimerModel) => {
                    let activeTimer: IActiveTimer = {
                        Id: timer.Id,
                        EntityId: timer.EntityId,
                        RecordId: timer.RecordId,
                        SubjectName: timer.Subject.TranslatedName || timer.Subject.Name
                    }
                    return activeTimer;
                });

                const activeTimerIds = new Set(this._activeTimers().map(timer => timer.Id));
                this._activeTimers.push(...activeTimers.filter(timer => !activeTimerIds.has(timer.Id)));

                PubSub.publish('START_ACTIVE_TIMER', activeTimers);
            } else {
                if (this._defaultActiveTimers !== ActiveTimers.NoLimitations && this._activeTimers().length){
                    _.each(this._activeTimers(), (timer: IActiveTimer) => {
                        if (this.TimerRecordId === timer.RecordId) {
                            PubSub.publish('STOP_ACTIVE_TIMERS', [timer]);
                        }
                    })
                }
            }
        })

    }
    get ActiveTimers() : KnockoutObservableArray<IActiveTimer> {
        return this._activeTimers;
    }

    get TimerAutostart(): boolean {
        return this._timerAutoStart
    }

    set TimerEntityId(entityId: number) {
        this._timerEntityId(entityId);
    };

    get TimerEntityId(): number {
        const form: IForm = this.GetForm();
        return this._timerEntityId() ||
            (form && form.GetScreen() && form.GetScreen().GetEntityId());
    };

    set TimerRecordId(recordId: number) {
        this._timerRecordId(recordId);
    };

    get TimerRecordId(): number {
        const form: IForm = this.GetForm();
        return this._timerRecordId() ||
            (form && form.GetScreen() && form.GetScreen().GetRecordId());
    };

    set ActionRecord(record: ActionModel) {
        this._actionRecord = record;
    }

    get ActionRecord(): ActionModel {
        return this._actionRecord
    }

    set KanbanMode(data: boolean) {
        this._kanbanMode = data;
    }

    get KanbanMode(): boolean {
        return this._kanbanMode
    }

    set CurrentDuration(starting: string) {
        const durationInt = moment().diff(moment(`${starting}Z`));
        const durationFormatted = moment.utc(durationInt).format(DATE_FORMATS.LONG_TIME.Format);

        this._currentDuration(durationFormatted);
    }

    get CurrentDuration(): string {
        return this._currentDuration();
    }

    get TooltipValue(): string {
        return LABELS.START_TIMER;
    }

    SetValue(value: IControlValue): void {
        if (!this.TimerEntityId || !this.TimerRecordId) {
            this._model().IsDisabledByCondition = true;
        }
    }

    ShowConfirmModalForCheckActiveTimers(target?: HTMLElement){
        let confirmationDialog = new ConfirmationDialog({
            Text: CONFIRMATIONS.THE_TIMER_IS_ALREADY_STARTED,
            Type: ConfirmationTypes.Question,
            TextConfirm: LABELS.YES,
            TextDecline: LABELS.NO
        });

        confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {

            BlockUI.Block(BlockUI.GetTargetObjectFromTarget(target));
            TimerStore.StartTimer({EntityId: this.TimerEntityId, RecordId: this.TimerRecordId, ActiveTimers: this._defaultActiveTimers, DefaultHourkind: this._defaultHourkind })
                .always(() => {
                    BlockUI.Unblock(target ? target : null);
                })
                .then((responseModel: StartTimerResponseModel) => {
                    _.each(responseModel.WarningMessages, message => this._notifier.Failed(message));

                    this.HasTimerStarted(true);
                    PubSub.publish('START_ACTIVE_TIMERS', [responseModel.ActiveTimer]);
                })
                .fail(error => this._notifier.Failed(error.message));
        });

        confirmationDialog.Show();
    }

    InitTimer() {
        if (this.HasTimerStarted()) {
            this.StopTimer(this.TimerEntityId, this.TimerRecordId);
            return;
        }

        if (this._defaultActiveTimers === ActiveTimers.NoLimitations) {
            this.StartTimer(this.TimerEntityId, this.TimerRecordId, this._el);
            return;
        }

        this.CheckActiveTimers(this.TimerEntityId, this.TimerRecordId);
    }

    OnTimerClick() {
        if (this._help.IsHelpButtonPressed()) {
            this._help.ShowControlHelp(this);
            return;
        }

        this.InitTimer();
    }

    OpenActiveTimersModal() {
        if (this._help.IsHelpButtonPressed()) {
            this._help.ShowControlHelp(this);
            return;
        }

        const activeTimersModal = new ActiveTimersModal();
        activeTimersModal.On('STOP_TIMER', this, (data: EventArgs)=>{
            const activeTimer: IActiveTimer = data.data;
            if (this.TimerEntityId === activeTimer.EntityId && this.TimerRecordId === activeTimer.RecordId){
                this.HasTimerStarted(false);
            }
            PubSub.publish('STOP_ACTIVE_TIMERS', activeTimer);
        })
        activeTimersModal.Show();
    }

    AutoStopActiveTimer(): void {
        if (this.Screen.AllowTimerAutoStop && this.IsRunTime && this.IsConsultScreen ) {
            if (this._timerAutoStart && this.HasTimerStarted()) {
                const stopTimer: IActiveTimer = _.find(this._activeTimers(), timer=> timer.RecordId === this.TimerRecordId && timer.EntityId === this.TimerEntityId);
                const confirmationDialog = new ConfirmationDialog({
                    Text: LABELS.STOP_THE_TIMER_FOR.replace('{recordName}', `<b>${stopTimer.SubjectName}</b>`),
                    Type: ConfirmationTypes.Question,
                    TextConfirm: LABELS.YES,
                    TextDecline: LABELS.NO
                });
                confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
                    this.StopTimer(this.TimerEntityId, this.TimerRecordId);
                });

                confirmationDialog.Show();
            }
        }
    }

    AutoStartTimer() {
        if (!this.HasTimerStarted() && this.IsRunTime && this.IsConsultScreen) {
            if (this._timerAutoStart) {
                this.OnTimerClick();
            }
        }
    }

    AfterRender(el: Array<HTMLElement>) {
        const self = this;
        if (el) {
            this._el = el[0];
        }

        if (this.TimerEntityId && this.TimerRecordId && !this.KanbanMode) {
            TimerStore.GetIsTimerStarted({EntityId: this.TimerEntityId, RecordId: this.TimerRecordId})
                .then((isTimerStarted: boolean) => {
                    this.HasTimerStarted(isTimerStarted);
                    this.AutoStartTimer();
                })
                .fail((error) => {
                    this._notifier.Failed(error.message)
                });
            this.GetActiveTimers();
        }

        ko.utils.domNodeDisposal.addDisposeCallback(this._el, () => {
            self.StopLiveTimer(self);
            self.AutoStopActiveTimer();
        });
    }

    GetActiveTimers(){
        TimerStore.GetActiveTimers()
            .then(timers => {
                this._activeTimers(
                    timers.map(timer => {
                        return {
                            EntityId: timer.EntityId,
                            RecordId: timer.RecordId,
                            Id: timer.Id,
                            SubjectName: timer.Subject.TranslatedName || timer.Subject.Name
                        };
                    })
                );
                if (this._activeTimers().length){
                    PubSub.publish('START_ACTIVE_TIMERS', timers);
                }
            })
    }

    StopTimer(_entityId: number = null, _recordId: number = null, target?: HTMLElement): void{
        const entityId = _entityId ? _entityId : this.TimerEntityId;
        const recordId = _recordId ? _recordId : this.TimerRecordId;

        BlockUI.Block(BlockUI.GetTargetObjectFromTarget(target));
        TimerStore.StopTimer({EntityId: entityId, RecordId: recordId })
            .always(() => {
                BlockUI.Unblock(target ? target : null);
            })
            .then((responseModel: StopTimerResponseModel) => {
                _.each(responseModel.WarningMessages, message => this._notifier.Failed(message));

                this.HasTimerStarted(false);
                const stopTimer: IActiveTimer = _.find(this._activeTimers(), timer=> timer.RecordId === recordId && timer.EntityId === entityId);
                if (stopTimer){
                    const timers: Array<IActiveTimer> = [stopTimer]
                    PubSub.publish('STOP_ACTIVE_TIMERS', timers);
                }
            })
            .fail(error => this._notifier.Failed(error.message));
    }

    StartTimer(_entityId: number = null, _recordId: number = null, target?: HTMLElement): void {
        const entityId = _entityId ? _entityId : this.TimerEntityId;
        const recordId = _recordId ? _recordId : this.TimerRecordId;

        BlockUI.Block(BlockUI.GetTargetObjectFromTarget(target));
        TimerStore.StartTimer({EntityId: entityId, RecordId: recordId, ActiveTimers: this._defaultActiveTimers, DefaultHourkind: this._defaultHourkind})
            .always(() => {
                BlockUI.Unblock(target ? target : null);
            })
            .then((responseModel: StartTimerResponseModel) => {
                _.each(responseModel.WarningMessages, message => this._notifier.Failed(message));

                if (this._timerAutoStart) {
                    let timerName: string = `<b>${responseModel.ActiveTimer.Subject.TranslatedName ||responseModel.ActiveTimer.Subject.Name}</b>`;
                    new Notifier().Success(LABELS.THE_TIMER_FOR_STARTED_AUTOMATICALLY.replace('{recordName}', timerName));
                }
                PubSub.publish('START_ACTIVE_TIMERS', [responseModel.ActiveTimer]);
            })
            .fail(error => this._notifier.Failed(error.message));
    }

    CheckActiveTimers(_entityId: number = null, _recordId: number = null, target?: HTMLElement): void {
        const entityId = _entityId ? _entityId : this.TimerEntityId;
        const recordId = _recordId ? _recordId : this.TimerRecordId;

        BlockUI.Block();
        TimerStore.CheckActiveTimers({EntityId: entityId, RecordId: recordId, ActiveTimers: this._defaultActiveTimers, DefaultHourkind: this._defaultHourkind})
            .always(() => {
                BlockUI.Unblock();
            })
            .then(result => {
                if (result){
                    this.ShowConfirmModalForCheckActiveTimers(target);
                } else {
                    this.HasTimerStarted(true);
                    this.GetActiveTimers();
                }
            })
            .fail(error => {
                this._notifier.Failed(error.message)
            });
    }

    ApplyProperties() {
        if (this.Properties) {
            if (this.Properties.Hourkinds) {

                _.each(this.Properties.Hourkinds.Properties, (property: any) => {
                    if (property.DefaultHourkind) {
                        this._defaultHourkind = parseInt(property.DefaultHourkind.Value) || null;
                    }
                });
            }

            if (this.Properties.ActiveTimers) {

                _.each(this.Properties.ActiveTimers.Properties, (property: any) => {
                    if (property.DefaultActiveTimers) {
                        this._defaultActiveTimers = property.DefaultActiveTimers.Value || null;
                    }
                });
            }

            if (this.Properties.AutoStart) {
                _.each(this.Properties.AutoStart.Properties, (property: any) => {
                    this._timerAutoStart = property.TimerAutoStart || this._model().IsAutoStartByCondition;
                });
            }

            this.AssignProperty('Styling', 'BackgroundColor', this._backgroundColor);
            this.AssignProperty('Styling', 'TextColor', this._color);
            this.AssignProperty('Styling', 'Border', this._border);
            this.AssignProperty('Styling', 'BorderColor', this._borderColor);
        }
    }

    private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
        if (this.Properties[groupedBy]) {
            _.each(this.Properties[groupedBy].Properties,
                (property: any) => {
                    if (property.hasOwnProperty(propertyName)) {
                        propertyHolder(property[propertyName]);
                    }
                });
        }
    }

    StopLiveTimer(_self?: Timer){
        const liveTimer = _self?.KanbanMode ? _self._liveTimer() : this.KanbanMode && this._liveTimer();

        if (liveTimer?.InitTimer) {
            liveTimer.StopTimer();
        }
    }

    StartLiveTimer(timer: TimerModel){
        if (this.KanbanMode){
            const durationInt = moment().diff(moment(`${timer.Starting}Z`));
            const durationFormatted = moment.utc(durationInt).format(DATE_FORMATS.LONG_TIME.Format);
            this.SetLiveTimerCurrentDuration(durationFormatted);
        }
    }

    SetLiveTimerCurrentDuration(currentDuration: string): void {
        const liveTimer = this._liveTimer();
        if ( liveTimer.HasValidDuration(currentDuration) ) {
            liveTimer.UpdateCurrentDuration(currentDuration);
            liveTimer.StartTimer();
        }
    }

    SetKanbanActiveTimer(timer: TimerModel) {
        const durationInt = moment().diff(moment(`${timer.Starting}Z`));
        const durationFormatted = moment.utc(durationInt).format(DATE_FORMATS.LONG_TIME.Format);
        this.HasTimerStarted(true);

        this._activeTimers().push({
            EntityId: timer.EntityId,
            RecordId: timer.RecordId,
            Id: timer.Id,
            SubjectName: timer.Subject.TranslatedName || timer.Subject.Name
        });

        this.SetLiveTimerCurrentDuration(durationFormatted);
    }

    ResetTimersInActionRecord(actionRecord: ActionModel): void {
        if (actionRecord) {
            actionRecord.NewActiveTimerModel = null;
            actionRecord.ActiveTimer = null;
        }
    }

    OnTimerClickFromKanbanCard(kanbanCard: KanbanCard, timer: Timer, event: JQuery.ClickEvent<HTMLElement>): void {
        if (this._help.IsHelpButtonPressed()) {
            this._help.ShowControlHelp(this);
            return;
        }

        const target: HTMLElement = kanbanCard && kanbanCard.Element as HTMLElement;

        if (this.HasTimerStarted()) {
            this.StopTimer(this.TimerEntityId, this.TimerRecordId, target);
            return;
        }

        if (this._defaultActiveTimers === ActiveTimers.NoLimitations) {
            this.StartTimer(this.TimerEntityId, this.TimerRecordId, target);
            return;
        }

        this.CheckActiveTimers(this.TimerEntityId, this.TimerRecordId, target);
    }

    GetLiveTimerForKanbanTemplateName() {
        return 'Core/Controls/Timer/Templates/LiveTimerForKanban';
    }
}