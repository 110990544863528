import * as ko from 'knockout';
import * as $ from 'jquery';

import {BlockUI} from "Core/Common/BlockUi";
import {Notifier} from "Core/Common/Notifier";

import {IKnockoutDirty} from 'Core/KnockoutExtentions/DirtyFlagExtention.d';
import {CONFIRMATIONS, LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";

import {
    ConfirmationDialog, EVENTS as ConfirmationDialogEvents,
    Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

import {CyberConfigManagerViewModel, DatabaseViewModel, States} from "../ViewModels/CyberConfigManagerViewModel";
import {DatabaseModel} from "../Models/DatabaseModel";
import {CyberConfigValidateForm} from "../UI/CyberConfigValidateForm";
import {CyberConfigStore} from "../Store/CyberConfigStore";

import View from "Core/CyberConfigManager/Templates/CyberConfig.html";
import ErrorPage from "Core/CyberConfigManager/Templates/ErrorPage.html";
import CyberConfigForm from "Core/CyberConfigManager/Templates/CyberConfigForm.html";

import {AdminPage} from "../../../AdminDashboard/Pages/AdminPage";
import TemplateTest from "Core/CyberConfigManager/Templates/CyberConfig.html";

ko.templates["Core/CyberConfigManager/Templates/CyberConfig"] = View;
ko.templates["Core/CyberConfigManager/Templates/ErrorPage"] = ErrorPage;
ko.templates["Core/CyberConfigManager/Templates/CyberConfigForm"] = CyberConfigForm;

ko.templates["Core/CyberConfigManager/Templates/CyberConfig"] = TemplateTest;

export class CyberConfig extends AdminPage {
    private _model: KnockoutObservable<CyberConfigManagerViewModel>;
    private _backupDb: KnockoutObservable<DatabaseViewModel>;
    private _isOk: KnockoutObservable<boolean>;
    private _errorMessage: KnockoutObservable<string>;
    private _validationErrors: KnockoutObservableArray<string>;
    private _hasValidationErrors: KnockoutComputed<boolean>;
    private _state: KnockoutObservable<number>;
    private _dbWatcher: IKnockoutDirty;
    private _isCreateMode: KnockoutComputed<boolean>;
    private _databaseModel: DatabaseModel;
    private _validateForm: CyberConfigValidateForm;

    private _cyberConfigValidateForm: CyberConfigValidateForm;
    private _labels = LABELS;

    constructor() {
        super();
        this._model = ko.observable(null);
        this._backupDb = ko.observable(null);
        this._dbWatcher = (ko as any).dirtyFlag(this._backupDb);
        this._isOk = ko.observable(false);
        this._errorMessage = ko.observable("");
        this._validationErrors = ko.observableArray([]);
        this._hasValidationErrors = ko.computed(() => {
            return this._validationErrors() && this._validationErrors().length > 0;
        });
        this._state = ko.observable(States.Update);
        this._isCreateMode = ko.computed(() => {
            return this._state() === States.Create;
        });

        this.Init();
    }

    GetTemplate() {
        return TemplateTest;
    }

    GetTemplateName(): string {
        return 'Core/CyberConfigManager/Templates/CyberConfig';
    }

    AfterRender(el) {
        var validateForm = $(el).find('.cyberconfig-manager');
        this._validateForm = new CyberConfigValidateForm(validateForm);
    }

    public ValidateForm() {

    }

    private DatabaseChanged() {
        this._backupDb(this._model().CurrentDb().Copy());
        this._dbWatcher.Commit();
    }

    Save() {
        if (this._validateForm.IsValid()) {
            let model = this._backupDb().GetModel();
            model.OriginalName = this._model().CurrentDb().Name();
            model.State = this._state();
            BlockUI.Block();

            const promise = model.State === States.Create
                ? CyberConfigStore.CreateConfig(model)
                : CyberConfigStore.UpdateConfig(model);

            promise
                .always(() => BlockUI.Unblock())
                .then(() => {
                    new Notifier().Success(NOTIFICATIONS.CONFIGURATION_SAVED);

                    if (this._state() === States.Create) {
                        this._model().Databases.push(this._backupDb());

                        this._model().CurrentDb(this._backupDb());
                        this._backupDb(this._model().CurrentDb().Copy());
                        this._validationErrors([]);
                    } else {
                        this.ChangeCurrentDatabase(model);
                    }
                    this._state(States.Update);
                    this._dbWatcher.Commit();
                })
                .fail(err => {
                    let errorValue = err.value;
                    if (errorValue) {
                        this._validationErrors(errorValue);
                    }
                    new Notifier().Failed(err.message);
                });
        }
    }

    Delete() {
        if (this._state() === States.Create) {
            return;
        }

        let model = this._backupDb().GetModel();
        model.OriginalName = this._model().CurrentDb().Name();
        model.Id = this._model().CurrentDb().Id;
        BlockUI.Block();
        CyberConfigStore.DeleteConfig(model.Id)
            .always(() => {
                BlockUI.Unblock();
            })
            .then(() => {
                new Notifier().Success(NOTIFICATIONS.DATABASE_DELETED);

                this.Init();
            }).fail(err => new Notifier().Failed(err.message));
        this._validationErrors([]);
    }

    DeleteClick() {
        let dialog = new ConfirmationDialog({
            Text: CONFIRMATIONS.DELETE_DATABASE_CONFIGURATION,
            Type: ConfirmationTypes.Question
        });
        dialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, () => this.Delete());
        dialog.Show();
    }

    ResetClick() {
        if (this._dbWatcher.IsDirty()) {
            let dialog = new ConfirmationDialog({
                Text: CONFIRMATIONS.ALL_CHANGES_WILL_BE_LOST,
                Type: ConfirmationTypes.Question
            });
            dialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, () => this.Reset());
            dialog.Show();
        } else {
            this.Reset();
        }
    }

    private Reset() {
        this._backupDb(this._model().CurrentDb().Copy());
        this._state(States.Update);
        this._dbWatcher.Commit();
        this._validationErrors([]);
    }

    CreateClick() {
        if (this._dbWatcher.IsDirty()) {
            let dialog = new ConfirmationDialog({
                Text: CONFIRMATIONS.ALL_CHANGES_WILL_BE_LOST,
                Type: ConfirmationTypes.Question
            });
            dialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, () => this.Create());
            dialog.Show();
        } else {
            this.Create();
        }
    }

    private Create() {
        this._backupDb(new DatabaseViewModel(new DatabaseModel()));
        this._state(States.Create);
        this._dbWatcher.Commit();
        this._validationErrors([]);
    }

    GetErrorPage(): string {
        return 'Core/CyberConfigManager/Templates/ErrorPage';
    }

    GetCyberConfigForm(): string {
        return 'Core/CyberConfigManager/Templates/CyberConfigForm';
    }

    Init() {
        BlockUI.Block();
        return CyberConfigStore.GetConfig()
            .always(() => {
                BlockUI.Unblock();
            })
            .then(databases => {
                if (!databases) {
                    this._errorMessage(NOTIFICATIONS.DATABASE_NOT_FOUND);
                }
                this._model(new CyberConfigManagerViewModel(databases));
                this._backupDb(this._model().CurrentDb().Copy());
                this._dbWatcher.Commit();
                this._isOk(true);
            })
            .fail(err => this._errorMessage(err.message))
    }

    private ChangeCurrentDatabase(model: DatabaseModel) {
        this._model().CurrentDb().Name(model.Name);
        this._model().CurrentDb().ConnectionString(model.ConnectionString);
    }
}