import { P } from "Core/Common/Promise";
import { URL } from "Core/Common/Url";
import { IRequestOptions, Request } from "Core/Common/Request";
import { IRequestUserOptions } from "../Options/IRequestUserOptions";

export class UserStore {
	static GetCurrentUser(options: IRequestUserOptions): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.USER.GET_CURRENT_USER,
				dataType: 'json',
				type: 'POST'
			},
			params: options
		};

		Request.Send(requestParams)
			.then(data =>
				deferredResult.resolve(data)).fail(()=>	deferredResult.reject(null));

		return deferredResult.promise();
	}

	static GetUserAllowance(userId: number) {
		return Request.Send({
			proxy: {
				url: '/api/UserAllowanceApi/GetUserAllowanceInfo',
				type: 'GET'
			},
			params: { id: userId }
		});
	}
} 