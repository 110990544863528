import * as ko from 'knockout';

import {Notifier} from "Core/Common/Notifier";

import {NewDataRoleComponent} from "Core/Controls/FieldSecurity/Components/DataRole/NewDataRole/NewDataRoleComponent";

import {DataRoles} from "Core/Controls/FieldSecurity/Shared/Enums/DataRoles";

import {FieldSecurityStore} from "Core/Controls/FieldSecurity/Stores/FieldSecurityStore";
import {FieldCollectionDTOModel} from "Core/Controls/FieldSecurity/Shared/Models/DTO/Get/FieldCollectionDTOModel";

import {NewDEPRoleModel} from "Core/Controls/FieldSecurity/Components/DataRole/NewDataRole/NewDEPRole/NewDEPRoleModel";

import {DataRoleModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/DataRoleModel";
import {FieldCollectionModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/FieldCollectionModel";

import Template from 'Core/Controls/FieldSecurity/Components/DataRole/NewDataRole/NewDEPRole/NewDEPRole.html'
import {FieldCollectionMappings} from "Core/Controls/FieldSecurity/Mappings/FieldCollectionMappings";

ko.templates['Core/Controls/FieldSecurity/Components/DataRole/NewDataRole/NewDEPRole/NewDEPRole'] = Template;

export class NewDEPRoleComponent extends NewDataRoleComponent {
	private _model: KnockoutValidatedObservable<NewDEPRoleModel>;

	constructor() {
		super(Template);

		this._model = ko.validatedObservable(new NewDEPRoleModel());
	}

	AfterRender() {
		FieldSecurityStore.GetUserFieldCollections()
			.then(response => this.FieldCollectionsLoaded(response.FieldCollections))
			.fail(error => new Notifier().Failed(error.message));
	}

	ValidateForm() {
		const valid = this._model.isValid();

		if(!valid){
			this._model.errors.showAllMessages();
		}

		return valid;
	}

	private FieldCollectionsLoaded(fieldCollectionDto: FieldCollectionDTOModel[]){
		const fieldCollections = fieldCollectionDto
			.filter(collection => collection.Type === DataRoles.Instance.VO.Type)
			.map(collection => FieldCollectionMappings.MapToFieldCollection(collection));
		
		this._model().FieldCollections(fieldCollections);
	}

	protected MapToNewDataRole(): DataRoleModel {
		const selectedFieldCollection = this._model().SelectedFieldCollection();

		const fieldCollection = new FieldCollectionModel(
			selectedFieldCollection.Id,
			selectedFieldCollection.Name,
			null);

		return new DataRoleModel(null, DataRoles.Instance.DEP.ShortName, fieldCollection);
	}

}