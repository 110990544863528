export const DATE_FORMATS = {
	SHORT_DATE: {Name: 'Short Date', Format: 'YYYY-MM-DD', MomentFormat: 'L'},
	LONG_DATE: {Name: 'Long Date', Format: 'dddd, YYYY-MM-DD', MomentFormat: 'dddd, L'},
    LONG_DATE_SHORT_WEEK: {Name: 'Long Date', Format: 'dd, YYYY-MM-DD', MomentFormat: 'dd, L'},
	LONG_TIME: {Name: 'Long Time', Format: 'HH:mm:ss', MomentFormat: 'HH:mm:ss'},
	TIME: {Name: 'Time', Format: 'HH:mm', MomentFormat: 'HH:mm'},
	DATE_TIME: {Name: 'DateTime', Format: 'YYYY-MM-DD HH:mm', MomentFormat: 'L HH:mm'},
	FULL_DATETIME: {Name: 'FullDateTime', Format: 'YYYY-MM-DD HH:mm:ss', MomentFormat: 'dddd, L HH:mm:ss'},
	WEEKDAY_FULLNAME: {Name: 'WeekdayFullName', Format: 'dddd', MomentFormat: 'dddd'},
    WEEKDAY_SHORTNAME: {Name: 'WeekdayShortName', Format: 'dd', MomentFormat: 'dd'},
	SAVE_FORMAT: {Name: 'Save Format', Format: 'YYYY-MM-DD HH:mm:ss', MomentFormat: 'L HH:mm:ss'},
	SAVE_FORMAT_FULL: {Name: 'Save Format Full', Format: 'YYYY-MM-DDTHH:mm:ss', MomentFormat: 'L HH:mm:ss'},
	TIME_SPAN: {Name: 'Time Span', Format: 'H:mm', MomentFormat: 'H:mm'},
	TIME_SPAN_LONG: {Name: 'Time Span Long', Format: 'H:mm:ss', MomentFormat: 'H:mm:ss'},
	SHORT_DATE_SHORT_WEEK: {Name: 'Short Date Short Week', Format: 'DD MMM', MomentFormat: 'DD MMM'},
	MONTH_AND_YEAR: {name: 'Month and Year', Format: 'MMMM, YYYY', MomentFormat: 'MMMM, YYYY'},
	DD_MMM: {name: 'Short date and Short Month', Format: 'DD-MMM', MomentFormat: 'DD-MMM'},
	DD_MM: {name: 'Short date and number Month', Format: 'DD-MM', MomentFormat: 'DD-MM'},
	DAY_OF_WEK_ddd: {name: 'Sun Mon ... Fri Sat', Format: 'ddd', MomentFormat: 'ddd'},
	WEEK_OF_YEAR_WW: {name: 'Week of Year - 1 2 ... 52 53', Format: 'WW', MomentFormat: 'WW'},
	MMM_YYYY: {name: 'Short Month and number Year', Format: 'MMM-YYYY', MomentFormat: 'MMM-YYYY'},
	MM_YY: {name: 'Number Month and Short Year', Format: 'MM-YY', MomentFormat: 'MM-YY'},
	YYYY_WW: {name: 'Full Year and Week of Year', Format: 'YYYY-WW', MomentFormat: 'GGGG-[W]WW'},
	DD_MMM_YYYY: {Name: 'Name DD-MMM-YYYY', Format: 'DD-MMM-YYYY', MomentFormat: 'DD-MMM-YYYY'},
	ONLY_A_YEAR: {name: 'Only a Year', Format: 'YYYY', MomentFormat: 'YYYY'},
};