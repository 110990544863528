import { FastFilter } from 'Core/Controls/Grid/BaseGrid/FastFilter/FastFilter';
import { GridColumnModel } from 'Core/Controls/Grid/Models/GridDataModel/GridColumnModel';
import { EVENTS } from 'Core/Controls/Grid/BaseGrid/Events';
import { FastFilterItem, FastFilterValue } from 'Core/Controls/Grid/BaseGrid/FastFilter/FastFilterModels';
import { GridStore, IGetGridDataRequestModel } from 'Core/Controls/Grid/Stores/GridStore';

import * as moment from 'moment';
import { FormatConverter } from 'FormatEditor/FormatConverter';
import { Notifier } from 'Core/Common/Notifier';
import { BlockUI as BlockUi } from 'Core/Common/BlockUi';

import TimespanFilterTemplate from 'Core/Controls/Grid/BaseGrid/FastFilter/Filters/Timespan/TimespanFilter.html';

export class TimespanFilter extends FastFilter {
	constructor(model: GridColumnModel,
		recordId: number,
		tableViewId: number,
		getGridDataModel: IGetGridDataRequestModel,
		existedColumnAliases: string[]) {
		super(model, recordId, tableViewId, getGridDataModel, existedColumnAliases);

		this.Preselect();
	}
	public MapToViewModel() {
		const viewModelList: FastFilterItem[] =
			this._availableItems().map((item: FastFilterValue) => {

				const wasItemChecked = this.RestoreSelectedCheckbox(item);

				const viewModelItem: FastFilterItem = {
					Value: !item.Value || (item.Value === '0') ? null : item.Value.toString(),
					DisplayValue: !item.Value || (item.Value === '0') ? this._labels.EMPTY_VALUE : moment(item.Value.toString()).format('HH:mm'),
					Checked: wasItemChecked
				};

				IdentifyChecked.call(this, this._preselectedRecords(), item, viewModelItem);

				return viewModelItem;
			});


		function IdentifyChecked(preselectedList, currentItem, viewModelItem) {
			preselectedList.forEach((preselected) => {

				var item = !viewModelItem.Value || (viewModelItem.Value === '0') ? null :moment(viewModelItem.Value.toString()).format('HH:mm');
				if (!this._storedCheckboxes
					&& ((!item && !preselected.Value)
						|| (item != null && item === preselected.Value))) {

					viewModelItem.Checked = true;
				}
			});
		}

		this._viewModelItem(_.uniq(viewModelList, false, time => time.DisplayValue));
		if (this._originalViewModelItems == null)
			this._originalViewModelItems = _.uniq(viewModelList, false, time => time.DisplayValue);

	}
	protected LoadFastFilterData(dropDownElement?: HTMLElement) {
		this._isOpenFastFilter(!this._isOpenFastFilter());

		if (this.IsItemsLoaded) {
			return Promise.resolve();
		}

		BlockUi.Block({Target: dropDownElement});

		return GridStore.GetFastFilterTimeData(this.GetRequestParams())
			.always(() => {
				BlockUi.Unblock(dropDownElement);
			})
			.then((result) => {
				this.DataLoadCallback(result.Items);
			})
			.fail((error) => {
				new Notifier().Failed(error.message);
			});
	}

	protected SearchChangeCallback(value) {
		this._searchValue = value;
		this._regex = RegExp(value);
		this.StoreSelectedCheckboxes();

		BlockUi.Block();


		this.DataLoadCallback(this._originalViewModelItems.filter(el => this._regex.test(el.DisplayValue)));
		BlockUi.Unblock();
	}

	protected MapToSaveModel() {
		this.StoreSelectedCheckboxes();

		const listFromStore: FastFilterValue[] = [];

		// create prev selected checkboxes list that are checked and doesn't exist in view model
		if (this._storedCheckboxes) {
			this._storedCheckboxes.forEach((storedItem) => {
				let isItemOnlyInStore = !this._viewModelItem().some((viewItem) => {
					return viewItem.Value === storedItem.Value;
				});
				
				if (isItemOnlyInStore && storedItem.Checked) {
					listFromStore.push({
						Value: storedItem.Value.toString()
					});
				}
			});
		}

		let valuesList: FastFilterValue[] = this._viewModelItem().filter((item) => {
			if (item.Checked) {
				delete item.Checked;
				delete item.DisplayValue;

				return true;
			}
		});

		// extend valuesList with previously selected and currently unvisible items
		valuesList = valuesList.concat(listFromStore);
		valuesList.forEach((item) => {
			//item.Value = moment(item.Value.toString()).subtract(moment().utcOffset() / 60, 'hours').format('HH:mm');
			item.Value = !item.Value || (item.Value === '0')? null : moment(item.Value.toString()).format('HH:mm');
		});

		this._saveModel = {
	FieldAlias: this._model.Alias,
	Values: valuesList,
	FieldId: this._model.FieldMetadata.Id,
	TableViewId: this._tableViewId
};

this._allFilters.forEach((filter) => {
	if (filter.FieldAlias === this._model.Alias && filter.TableViewId === this._tableViewId) {
		filter.Values = valuesList;
	}
});

// clear store
this._storedCheckboxes = null;
	}

	protected Filter() {
	this.MapToSaveModel();

	this.Trigger(EVENTS.FAST_FILTER);
	}

	protected DataLoadCallback(data) {
		this._availableItems(this.SortItems(data));
		this.MapToViewModel();
		this.IsItemsLoaded = true;
	}

GetTemplate() {
	return TimespanFilterTemplate;
}
}