import * as ko from 'knockout';

import {RootGroup} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/RootGroup';
import {ExtraGroup} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/ExtraGroup';
import {UndefinedGroup} from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/UndefinedGroup';

export class CachedLevel {
    RootGroups: KnockoutObservableArray<RootGroup>;
    MainGroup: KnockoutObservable<RootGroup>;
    ExtraGroup: KnockoutObservable<ExtraGroup>;
    UndefinedGroup: KnockoutObservable<UndefinedGroup>

    constructor(public Level: string) {
        this.RootGroups = ko.observableArray([]);
        this.MainGroup = ko.observable(null);
        this.ExtraGroup = ko.observable(null);
        this.UndefinedGroup = ko.observable(null);
    }
}