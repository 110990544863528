import {Width} from 'Core/Screens/DesignScreen/Models/FormProperties';

export function isWidthValid(width: Width) {
    return (
        width && (
            (width.Unit.Value === 'px' && width.Value > 0) ||
            (width.Unit.Value === '%' && width.Value > 0 && width.Value <= 100)
        )
    );
}

export function getWidthValue(width: Width) {
    if (!isWidthValid(width)) {
        return '100%';
    }

    return `${width.Value}${width.Unit.Value}`;
}
