import * as ko from 'knockout';

export class SPIMCopyControlModel {
    IsDefaultState: KnockoutObservable<boolean>;
    UpdateAvailable: KnockoutObservable<boolean>;
    EtimLabel: KnockoutObservable<string>;
    CurrentVersionNumber: KnockoutObservable<number>;

    constructor() {
        this.IsDefaultState = ko.observable(false);
        this.UpdateAvailable = ko.observable(false);
        this.EtimLabel = ko.observable('');
        this.CurrentVersionNumber = ko.observable(null);
    }
}