import * as ko from "knockout";
import "jquery";
import "xEditable";
import "TouchSpinExtension";

import {IValueUpdater} from "LookupEditor/Interfaces/IValueUpdater";
import {IColumnValue} from "LookupEditor/Interfaces/IColumnValue";
import {ColumnEditor} from "LookupEditor/ColumnEditors/Base/ColumnEditor";

import ColumnTemplate from "LookupEditor/Templates/Columns/Integer.html";
import debug = log4javascript.logLog.debug;
ko.templates["LookupEditor/Templates/Columns/Integer"] = ColumnTemplate;

export class IntegerColumnEditor extends ColumnEditor {
	GetTemplateName(): string {
		return 'LookupEditor/Templates/Columns/Integer';
	}

	AfterRender(el, owner: IValueUpdater, columnName): void {
		($.fn.editable as any).defaults.mode = 'inline';
		$(el).editable({
			type: 'touchSpin',
			value: {
				default: 0,
				current: $(el).text()
			},
			success: (response, newValue) => {
				var value: IColumnValue = {
					Name: columnName,
					Value: newValue.current
				};
				owner.UpdateValue(value);
			}
		});
	}

	ToString() {
		return "Integer";
	}
}