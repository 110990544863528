import clone from 'clone';

import { ClusterSettingsModel } from "DatabaseDesigner/Models/ClusterSettingsModel";
import { CollectionModel } from "DatabaseDesigner/Models//CollectionModel";
import { LinkEditorVisibilityModel } from "DatabaseDesigner/Models/LinkEditorVisibilityModel";
import { TranslationModel } from "DatabaseDesigner/Models/TranslationModel";
import * as moment from 'moment';
import { LifeStatusEnum } from "common/life-status-enum";
import { DATE_FORMATS } from "Core/Constants/DateTimeFormats"
import { FormatConverter } from "FormatEditor/FormatConverter";
import { BaseModel } from "DatabaseDesigner/Models//BaseModel";
import { TableFieldModel } from "DatabaseDesigner/Models/TableFielModel";
import { Guid } from "../../Core/Common/Guid";

export function FieldModel() {
    this._id = 0;
    this._name = '';
    this._typeId = 0;
    this._typeName = null;
    this._isPrimaryKey = false;
    this._isSystem = false;
    this._isRequired = false;
    this._isReadOnly = false;
    this._isIndexed = false;
    this._isVirtual = false;
    this._isGlobal = false;
    this._isTranslate = false;
    this._isModified = false;
    this._isHidden = false;
    this._isOptional = false;
    this._isClustered = false;
    this._isUnique = false;
    this._flags = 0;
    this._calculatedExpression = null;
    this._memo = '';
    this._size = 0;
    this._formatId = 0;
    this._guid = Guid.NewGuid();
    this._tableFiels = new CollectionModel({ model: TableFieldModel });
    this._linkEditorVisibilitySettings = new CollectionModel({ model: LinkEditorVisibilityModel });
    this._defaultValue = '';
    this._hasDefaultValue = true;
    this._sort = 0;
    this._icon = '';
    this._iconType = '';
    this._iconId = null;
    this._iconImage = null;
    this._lifeStatusName = null;
    this._translations = new CollectionModel({ model: TranslationModel });
    this._translationsMemo = new CollectionModel({ model: TranslationModel });
    this._oldName = '';
    this._oldLifeStatusName = '';
    this._propertyFieldId = null;
    this._propertyFieldGuid = null;
    this._propertyModeId = null;
    this._matching = null;
    this._clusterSettings = new CollectionModel({ model: ClusterSettingsModel });
    this._allowCustomValue = false;
    this._allowCreatingRecords = false;
    this._filterSubTable = false;
    this._filterSubTableFieldId = null;
    this._filterSubTableFieldGuid = null;
    this._filterSubTableKseq = false;
    this._reference = null;
    this._referenceGuid = null;
    this._isEditEnabled = false;
    this._hyperlinkEntity = 0;
    this._hyperlinkEntityGuid = '';
    this._hyperlinkPkey = 0;
    this._hyperlinkPkeyGuid = '';
    this._filterByField= 0;
    this._filterByFieldGuid = '';
    this.SortDescription = false;
    this.SequenceValue = null;

    BaseModel.apply(this);
};

FieldModel.prototype = Object.create(BaseModel.prototype);
FieldModel.prototype.constructor = FieldModel;

FieldModel.prototype.toJSON = function (onlyModified) {
    var isFieldRestored = this._oldLifeStatusName === LifeStatusEnum.Delete && this.LifeStatusName === LifeStatusEnum.Enabled;
    var name = this.Id == 0 || isFieldRestored ? this.Name : this._oldName;

    if (onlyModified && !this._isModified) {
        return null;
    }

    var isFullDateTime = moment(this.DefaultValue, DATE_FORMATS.FULL_DATETIME.Format).format(DATE_FORMATS.FULL_DATETIME.Format) === this.DefaultValue;
    var isLongTime = moment(this.DefaultValue, DATE_FORMATS.LONG_TIME.Format).format(DATE_FORMATS.LONG_TIME.Format) === this.DefaultValue;

    if (onlyModified && this.TypeName === 'DateTime' && isFullDateTime) {
        this.DefaultValue = moment(this.DefaultValue, DATE_FORMATS.FULL_DATETIME.Format).utc().format(DATE_FORMATS.FULL_DATETIME.Format);
    } else if (onlyModified && this.TypeName === 'Time' && isLongTime) {
        this.DefaultValue = moment(this.DefaultValue, DATE_FORMATS.LONG_TIME.Format).utc().format(DATE_FORMATS.LONG_TIME.Format);
    }

    return {
        Id: this.Id,
        Name: name,
        TypeId: this.TypeId,
        TypeName: this.TypeName,
        IsPrimaryKey: this.IsPrimaryKey,
        IsSystem: this.IsSystem,
        IsRequired: this.IsRequired,
        IsReadOnly: this.IsReadOnly,
        IsIndexed: this.IsIndexed,
        IsVirtual: this.IsVirtual,
        IsTranslate: this.IsTranslate,
        IsGlobal: this.IsGlobal,
        IsModified: this.IsModified,
        IsHidden: this.IsHidden,
        IsOptional: this.IsOptioanl,
        IsClustered: this.IsClustered,
        IsUnique: this.IsUnique,
        Flags: this.Flags,
        Memo: this.Memo,
        Size: this.Size,
        CalculatedExpression: this.CalculatedExpression,
        FormatId: this.FormatId,
        Sort: this.Sort,
        Icon: this.Icon,
        IconId: this.IconId,
        IconImage: this.IconImage,
        LifeStatusName: this.LifeStatusName,
        PropertyFieldId: this.PropertyFieldId,
        PropertyFieldGuid: this.PropertyFieldGuid,
        PropertyModeId: this.PropertyModeId,
        LinkEditorVisibilitySettings: this.LinkEditorVisibilitySettings.toJSON(),
        ClusterSettings: this.ClusterSettings.toJSON(),
        TableFiels: this.TableFiels.toJSON(onlyModified),
        Translations: this.Translations.toJSON(),
        MemoTranslations: this.MemoTranslations.toJSON(),
        DefaultValue: this.DefaultValue,
        HasDefaultValue: this.HasDefaultValue,
        Guid: this._guid,
        Matching: this._matching,
        AllowCustomValue: this._allowCustomValue,
        AllowCreatingRecords: this._allowCreatingRecords,
        FilterSubTable: this._filterSubTable,
        FilterSubTableFieldId: this._filterSubTableFieldId,
        FilterSubTableFieldGuid: this._filterSubTableFieldGuid,
        FilterSubTableKseq: this._filterSubTableKseq,
        Reference: this._reference,
        ReferenceGuid: this._referenceGuid,
        HyperlinkEntity: this._hyperlinkEntity,
        HyperlinkEntityGuid: this._hyperlinkEntityGuid,
        HyperlinkPkey: this._hyperlinkPkey,
        HyperlinkPkeyGuid: this._hyperlinkPkeyGuid,
        FilterByField: this._filterByField,
        FilterByFieldGuid: this._filterByFieldGuid,
        SortDescription: this._sortDescription,
        SequenceValue: this.SequenceValue
    };
};

FieldModel.prototype.FromJSON = function (data) {
    this.Id = data.Id;
    this.Name = data.Name;
    this.TypeId = data.TypeId;
    this.TypeName = data.TypeName;
    this.IsPrimaryKey = data.IsPrimaryKey;
    this.IsSystem = data.IsSystem;
    this.IsRequired = data.IsRequired;
    this.IsReadOnly = data.IsReadOnly;
    this.IsIndexed = data.IsIndexed;
    this.IsVirtual = data.IsVirtual;
    this.IsTranslate = data.IsTranslate;
    this.IsGlobal = data.IsGlobal;
    this.IsHidden = data.IsHidden;
    this.IsOptional = data.IsOptional;
    this.IsClustered = data.IsClustered;
    this.IsUnique = data.IsUnique;
    this.Flags = data.Flags;
    this.Memo = data.Memo;
    this.Size = data.Size;
    this.FormatId = data.FormatId;
    this.CalculatedExpression = data.CalculatedExpression;
    this.Sort = data.Sort;
    this.Icon = data.Icon;
    this.IconType = data.IconType;
    this.IconId = data.IconId;
    this.IconImage = data.IconImage;
    this.LifeStatusName = data.LifeStatusName;
    this.PropertyFieldId = data.PropertyFieldId;
    this.PropertyModeId = data.PropertyModeId;
    this._tableFiels = TableFieldModel.prototype.FromJSONArray(data.TableFiels);
    this._translations = TranslationModel.prototype.FromJSONArray(data.Translations);
    this._translationsMemo = TranslationModel.prototype.FromJSONArray(data.MemoTranslations);
    this.Reference = data.Reference;
    this.ReferenceGuid = data.ReferenceGuid;
    this.HyperlinkEntity = data.HyperlinkEntity;
    this.HyperlinkEntityGuid = data.HyperlinkEntityGuid;
    this.HyperlinkPkey = data.HyperlinkPkey;
    this.HyperlinkPkeyGuid = data.HyperlinkPkeyGuid;
    this.FilterByField = data.FilterByField;
    this.FitlerByFieldGuid = data.FitlerByFieldGuid;
    this.SortDescription = data.SortDescription;
    this.SequenceValue = data.SequenceValue;

    var isFullDateTime = moment(data.DefaultValue, DATE_FORMATS.FULL_DATETIME.Format).format(DATE_FORMATS.FULL_DATETIME.Format) === data.DefaultValue;
    var isLongTime = moment(data.DefaultValue, DATE_FORMATS.LONG_TIME.Format).format(DATE_FORMATS.LONG_TIME.Format) === data.DefaultValue;

    if (data.TypeName === 'DateTime' && isFullDateTime) {
        this.DefaultValue = moment(FormatConverter.CorrectTimezone(data.DefaultValue)).format(DATE_FORMATS.FULL_DATETIME.Format);
    } else if (data.TypeName === 'Time' && isLongTime) {
        this.DefaultValue = moment.utc(data.DefaultValue, DATE_FORMATS.LONG_TIME.Format).local().format(DATE_FORMATS.LONG_TIME.Format);
    } else {
        this.DefaultValue = data.DefaultValue;
    }

    this.HasDefaultValue = data.HasDefaultValue;
    this._oldName = this.Name;
    this._oldLifeStatusName = this.LifeStatusName;
    this.Matching = data.Matching;
    this.ClusterSettings = ClusterSettingsModel.prototype.FromJSONArray(data.ClusterSettings);
    this.AllowCustomValue = data.AllowCustomValue;
    this.AllowCreatingRecords = data.AllowCreatingRecords;
    this.FilterSubTable = data.FilterSubTable;
    this.FilterSubTableFieldId = data.FilterSubTableFieldId;
    this.FilterSubTableFieldGuid = data.FilterSubTableFieldGuid;
    this.FilterSubTableKseq = data.FilterSubTableKseq;
    this._linkEditorVisibilitySettings = LinkEditorVisibilityModel.prototype.FromJSONArray(data.LinkEditorVisibilitySettings);
};

FieldModel.prototype.Clone = function () {
    return clone(this);
};

FieldModel.prototype.FromJSONArray = function (data) {
    var fieldList = new CollectionModel({ model: FieldModel });

    for (var index = 0; index < data.length; index++) {
        var fieldModel = new FieldModel();

        fieldModel.FromJSON(data[index]);
        fieldList.Add(fieldModel);
    }

    return fieldList;
};

FieldModel.prototype.CreateNewField = function (fieldConfig) {
    var newField = this.Clone();

    Object.keys(fieldConfig).forEach(function (key) {
        if (fieldConfig.hasOwnProperty(key)) {
            newField[key] = fieldConfig[key];
        }
    });

    newField.Id = 0;
    newField.RegenerateGuid();

    newField.TableFiels.Items = this.TableFiels.GetItemsBy('TableTypeGuid', '-');
    newField.TableFiels.Items.forEach(function (item) {
        item.RegenerateGuid();
    });

    newField.IsModified = true;
    return newField;
};

FieldModel.prototype.CreateDefaultTranslations = function (languages) {
    if (languages) {
        var self = this;

        self._translations = new CollectionModel({ model: TranslationModel });

        _.forEach(languages, function (language) {
            var translation = new TranslationModel();

            translation.Language = language;
            self._translations.Add(translation);
        });
    }
};

FieldModel.prototype.CreateDefaultMemoTranslations = function (languages) {
    if (languages) {
        var self = this;

        self._translationsMemo = new CollectionModel({ model: TranslationModel });

        _.forEach(languages, function (language) {
            var translation = new TranslationModel();

            translation.Language = language;
            self._translationsMemo.Add(translation);
        });
    }
};

Object.defineProperty(FieldModel.prototype, 'Id', {
    enumerable: true,
    get: function () {
        return this._id;
    },
    set: function (val) {
        this._id = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'Name', {
    enumerable: true,
    get: function () {
        return this._name;
    },
    set: function (val) {
        this._name = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'Memo', {
    enumerable: true,
    get: function () {
        return this._memo;
    },
    set: function (val) {
        this._memo = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'TypeId', {
    enumerable: true,
    get: function () {
        return this._typeId;
    },
    set: function (val) {
        this._typeId = parseInt(val);
    }
});

Object.defineProperty(FieldModel.prototype, 'TypeName', {
    enumerable: true,
    get: function () {
        return this._typeName;
    },
    set: function (val) {
        this._typeName = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'IsPrimaryKey', {
    enumerable: true,
    get: function () {
        return this._isPrimaryKey;
    },
    set: function (val) {
        this._isPrimaryKey = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'IsOptional', {
    enumerable: true,
    get: function () {
        return this._isOptional;
    },
    set: function (val) {
        this._isOptional = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'IsSystem', {
    enumerable: true,
    get: function () {
        return this._isSystem;
    },
    set: function (val) {
        this._isSystem = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'IsRequired', {
    enumerable: true,
    get: function () {
        return this._isRequired;
    },
    set: function (val) {
        this._isRequired = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'IsReadOnly', {
    enumerable: true,
    get: function () {
        return this._isReadOnly;
    },
    set: function (val) {
        this._isReadOnly = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'IsIndexed', {
    enumerable: true,
    get: function () {
        return this._isIndexed;
    },
    set: function (val) {
        this._isIndexed = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'IsVirtual', {
    enumerable: true,
    get: function () {
        return this._isVirtual;
    },
    set: function (val) {
        this._isVirtual = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'IsGlobal', {
    enumerable: true,
    get: function () {
        return this._isGlobal;
    },
    set: function (val) {
        this._isGlobal = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'IsTranslate', {
    enumerable: true,
    get: function () {
        return this._isTranslate;
    },
    set: function (val) {
        this._isTranslate = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'IsHidden', {
    enumerable: true,
    get: function () {
        return this._isHidden;
    },
    set: function (val) {
        this._isHidden = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'IsClustered', {
    enumerable: true,
    get: function () {
        return this._isClustered;
    },
    set: function (val) {
        this._isClustered = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'IsUnique', {
    enumerable: true,
    get: function () {
        return this._isUnique;
    },
    set: function (val) {
        this._isUnique = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'Flags', {
    enumerable: true,
    get: function () {
        return this._flags;
    },
    set: function (val) {
        this._flags = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'IsNew', {
    enumerable: true,
    get: function () {
        return this._id === 0;
    }
});

Object.defineProperty(FieldModel.prototype, 'Size', {
    enumerable: true,
    get: function () {
        return this._size;
    },
    set: function (val) {
        this._size = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'CalculatedExpression', {
    enumerable: true,
    get: function () {
        return this._calculatedExpression;
    },
    set: function (val) {
        this._calculatedExpression = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'TableFiels', {
    enumerable: true,
    get: function () {
        return this._tableFiels;
    },
    set: function (val) {
        this._tableFiels = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'LinkEditorVisibilitySettings', {
    enumerable: true,
    get: function () {
        return this._linkEditorVisibilitySettings;
    },
    set: function (value) {
        this._linkEditorVisibilitySettings = value;
    }
});

Object.defineProperty(FieldModel.prototype, 'Guid', {
    enumerable: true,
    get: function () {
        return this._guid;
    }
});

Object.defineProperty(FieldModel.prototype, 'DefaultValue', {
    enumerable: true,
    get: function () {
        return this._defaultValue;
    },
    set: function (val) {
        this._defaultValue = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'HasDefaultValue', {
    enumerable: true,
    get: function () {
        return this._hasDefaultValue;
    },
    set: function (val) {
        this._hasDefaultValue = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'Sort', {
    enumerable: true,
    get: function () {
        return this._sort;
    },
    set: function (val) {
        this._sort = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'SortGroup', {
    enumerable: true,
    get: function () {
        if (_.includes(_.range(1000, 1999), this._sort)) {
            return 'Header';
        }
        if (_.includes(_.range(2000, 2999), this._sort)) {
            return 'OptionalHeader';
        }
        if (_.includes(_.range(3000, 7999), this._sort)) {
            return 'Custom';
        }
        if (_.includes(_.range(8000, 8999), this._sort)) {
            return 'OptionalFooter';
        }
        if (_.includes(_.range(9000, 9999), this._sort)) {
            return 'Footer';
        }
        if (_.includes([0], this._sort)) {
            return 'Undefined';
        }

        return null;
    }
});

Object.defineProperty(FieldModel.prototype, 'Icon', {
    enumerable: true,
    get: function () {
        return this._icon;
    },
    set: function (val) {
        this._icon = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'IconType', {
    enumerable: true,
    get: function () {
        return this._iconType;
    },
    set: function (val) {
        this._iconType = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'IconId', {
    enumerable: true,
    get: function () {
        return this._iconId;
    },
    set: function (val) {
        this._iconId = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'IconImage', {
    enumerable: true,
    get: function () {
        return this._iconImage;
    },
    set: function (val) {
        this._iconImage = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'Translations', {
    enumerable: true,
    get: function () {
        return this._translations;
    },
    set: function (val) {
        this._translations = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'MemoTranslations', {
    enumerable: true,
    get: function () {
        return this._translationsMemo;
    },
    set: function (val) {
        this._translationsMemo = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'LifeStatusName', {
    enumerable: true,
    get: function () {
        return this._lifeStatusName;
    },
    set: function (val) {
        this._lifeStatusName = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'PropertyFieldId', {
    enumerable: true,
    get: function () {
        return this._propertyFieldId;
    },
    set: function (val) {
        this._propertyFieldId = val
    }
});

Object.defineProperty(FieldModel.prototype, 'PropertyFieldGuid', {
    enumerable: true,
    get: function () {
        return this._propertyFieldGuid;
    },
    set: function (val) {
        this._propertyFieldGuid = val
    }
});

Object.defineProperty(FieldModel.prototype, 'PropertyModeId', {
    enumerable: true,
    get: function () {
        return this._propertyModeId;
    },
    set: function (val) {
        this._propertyModeId = val
    }
});

Object.defineProperty(FieldModel.prototype, 'Matching', {
    enumerable: true,
    get: function () {
        return this._matching;
    },
    set: function (val) {
        this._matching = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'ClusterSettings', {
    enumerable: true,
    get: function () {
        return this._clusterSettings;
    },
    set: function (val) {
        this._clusterSettings = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'AllowCustomValue', {
    enumerable: true,
    get: function () {
        return this._allowCustomValue;
    },
    set: function (val) {
        this._allowCustomValue = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'AllowCreatingRecords', {
    enumerable: true,
    get: function () {
        return this._allowCreatingRecords;
    },
    set: function (val) {
        this._allowCreatingRecords = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'FilterSubTable', {
    enumerable: true,
    get: function () {
        return this._filterSubTable;
    },
    set: function (val) {
        this._filterSubTable = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'FilterSubTableFieldId', {
    enumerable: true,
    get: function () {
        return this._filterSubTableFieldId;
    },
    set: function (val) {
        this._filterSubTableFieldId = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'FilterSubTableFieldGuid', {
    enumerable: true,
    get: function () {
        return this._filterSubTableFieldGuid;
    },
    set: function (val) {
        this._filterSubTableFieldGuid = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'FilterSubTableKseq', {
    enumerable: true,
    get: function () {
        return this._filterSubTableKseq;
    },
    set: function (val) {
        this._filterSubTableKseq = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'Reference', {
    enumerable: true,
    get: function () {
        return this._reference;
    },
    set: function (val) {
        this._reference = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'ReferenceGuid', {
    enumerable: true,
    get: function () {
        return this._referenceGuid;
    },
    set: function (val) {
        this._referenceGuid = val;
    }
});


Object.defineProperty(FieldModel.prototype, 'HyperlinkEntity', {
    enumerable: true,
    get: function () {
        return this._hyperlinkEntity;
    },
    set: function (val) {
        this._hyperlinkEntity = val;
    }
});


Object.defineProperty(FieldModel.prototype, 'HyperlinkPkey', {
    enumerable: true,
    get: function () {
        return this._hyperlinkPkey;
    },
    set: function (val) {
        this._hyperlinkPkey = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'HyperlinkEntityGuid', {
    enumerable: true,
    get: function () {
        return this._hyperlinkEntityGuid;
    },
    set: function (val) {
        this._hyperlinkEntityGuid = val;
    }
});


Object.defineProperty(FieldModel.prototype, 'HyperlinkPkeyGuid', {
    enumerable: true,
    get: function () {
        return this._hyperlinkPkeyGuid;
    },
    set: function (val) {
        this._hyperlinkPkeyGuid = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'FilterByField', {
    enumerable: true,
    get: function () {
        return this._filterByField;
    },
    set: function (val) {
        this._filterByField = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'FilterByFieldGuid', {
    enumerable: true,
    get: function () {
        return this._filterByFieldGuid;
    },
    set: function (val) {
        this._filterByFieldGuid = val;
    }
});

Object.defineProperty(FieldModel.prototype, 'SortDescription', {
    enumerable: true,
    get: function () {
        return this._sortDescription;
    },
    set: function (val) {
        this._sortDescription = val;
    }
});