import * as moment from 'moment';

import {TimeWritingReportResponseModel} from '../../Models/Store/Day/Response/TimeWritingReportResponseModel';
import {DayOfWeekViewModel, WeekDataViewModel} from '../../Models/View/WeekDataViewModel';
import {DayReportResponseModel} from '../../Models/Store/Day/Response/DayReportResponseModel';
import {ReservationMappings} from '../Day/ReservationMappings';
import {EntityMappings} from '../Day/EntityMappings';
import {FIELD_TYPES} from '../../../../../Constant';
import {LookupFieldMappings} from '../Common/LookupFieldMappings';
import {TranslationManager} from '../../../../Translation/TranslationManager';
import {TranslatedField} from '../../Models/View/DayDataViewModel';
import {DayMappings} from '../Day/DayMappings';

export class WeekMappingProfile {
    static OnViewModel(model: TimeWritingReportResponseModel): WeekDataViewModel {
        const viewModel = new WeekDataViewModel();

        viewModel.Days = model.DayReports.map(day => DayOfWeekMappingProfile.OnViewModel(day));
        viewModel.DaysOfWeek = model.WeekReports[0].DayReports.map(day => DayMappings.OnViewModel(day));

        viewModel.AllowedEntities = model.DayReports[0].GlobalSettings.AllowedEntities.map(entity => EntityMappings.OnViewModel(entity));
        viewModel.LookupFields = model.DayReports[0].Fields
            .filter(field => field.TypeName === FIELD_TYPES.Lookup || field.TypeName === FIELD_TYPES.MultiSelect)
            .map(field => LookupFieldMappings.OnViewModel(field));
        viewModel.TranslatedFields = model.DayReports[0].Fields
            .filter(field => field.IsTranslate)
            .map(field => new TranslatedField({
                Id: field.Id,
            }));

        return viewModel;
    }
}

export class DayOfWeekMappingProfile {
    static OnViewModel(model: DayReportResponseModel): DayOfWeekViewModel {
        const viewModel = new DayOfWeekViewModel();

        viewModel.Day = moment(model.DayDate).locale(TranslationManager.Instance.GetCurrentLanguage().ShortName).format('ddd');
        viewModel.Reservations = model.DayReservations.Reservations.map(reservation => ReservationMappings.OnViewModel(reservation));

        return viewModel;
    }
}