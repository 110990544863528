export const SECURITY_LEVELS = {
	Public: 1,
	Disclosed: 1,
	Safe: 2,
	Vault: 3,
	GDPR: 4
};

export const DATA_ROLE_NAMES = {
	Public: 'PO',
	Disclosed: 'DO',
	Safe: 'SO',
	Vault: 'VO',
	GDPR: 'DPO'
};