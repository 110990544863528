import * as ko from "knockout";
import * as _ from 'underscore';

import {ColumnEditor} from "./ColumnEditor";
import {ColumnFirstEditor} from "./ColumnFirstEditor";
import {GroupEditor} from "./GroupEditor";
import {IAppointment} from "../Models/Store/Response/SchedulerResponseModel";

export class SubGroupEditor {
    _columnFirst: ColumnFirstEditor;
    _columnsEditors: ColumnEditor[];
    _isSelected: KnockoutObservable<boolean>;
    _group: GroupEditor;
    _agenda: Array<IAppointment>;

    constructor(group: GroupEditor, appointments: Array<IAppointment>, isSelected: boolean) {
        this._columnsEditors = [];
        this._isSelected = ko.observable(isSelected);
        this._group = group;
        this._agenda = appointments;
    }

    GetGroup() {
        return this._group;
    }

    CloneToNewGroup(group: GroupEditor, appointments: Array<IAppointment>, isSelected) {
        const newSubGroupEditor = new SubGroupEditor(group, appointments, isSelected);

        if (this._isSelected()) {
            newSubGroupEditor._isSelected = this._isSelected;
        }

        newSubGroupEditor._columnFirst = _.clone(this._columnFirst);
        newSubGroupEditor._columnsEditors = _.map(this._columnsEditors, _.clone);
        newSubGroupEditor._agenda = _.map(this._agenda, _.clone);

        return newSubGroupEditor;
    }
}