import {ZIndexManager} from 'Core/Common/ZIndexManager';
import {MobileChecker} from 'Core/Common/MobileChecker';
import {GlobalManager} from 'Core/GlobalManager/GlobalManager';
import 'jBox';
import 'attrchange';
import 'attrchange_ext';
import {Event} from 'Core/Common/Event';
import * as ko from "knockout";
import { Counter } from "Core/Common/Counter";

interface Options {
    closeOnEsc?: boolean;
    height?: number | string;
    width?: number | string;
    widthInPercent?: any;
    heightInPercent?: any;
    minHeight?: number | string;
    maxHeight?: number | string;
    maxWidth?: number | string;
    minWidth?: number | string;
    closeButton?: boolean | string;
    addClass?: string;
    createOnInit?: boolean;
    animation?: boolean;
    blockScrol?: boolean;
    isolateScroll?: boolean;
    reposition?: boolean;
    onCreated?: Function;
    onClose?: Function;
    onOpen?: Function;
    onCloseComplete?: Function;
    fade?: number;
    closeCallback?: Function;
    closeOnClick?: boolean | string;
    appendTo?: any;
    content?: any;
    blockScroll?: boolean;
    outside?: any;
    position?: {
        x: string,
        y: string
    }
}

interface ResizePopupOptions {
    widthInPercent: number;
    heightInPercent: number;
}

export class Modal extends Event {
    private _jbox: jBox;
    private _wrapper: HTMLDivElement;

    constructor(customOptions?: Options,
                isUseGlobal: boolean = true,
                isDragOnMouseDown: boolean = false,
                resizePopup?: ResizePopupOptions) {
        super();

        if (!customOptions) {
            customOptions = {}
        }

        this.AddEvent("CLOSE");

        let $window = $(window);
        let options = customOptions || {};
        let self = this;
        let defaultWidth,
            recalculateTimerId,
            defaultOptions = {
                zIndex: ZIndexManager.Instance.NextValue,
                repositionOnOpen: true,
                repositionOnContent: true,
                closeOnEsc: customOptions.closeOnEsc || true,
                overlay: true,
                adjustPosition: true,
                minHeight: customOptions.height,
                minWidth: customOptions.minWidth || 50,
                height: customOptions.height,
                width: customOptions.width,
                widthInPercent: customOptions.widthInPercent,
                heightInPercent: customOptions.heightInPercent,
                maxHeight: customOptions.maxHeight,

                addClass: customOptions.addClass,
                createOnInit: customOptions.createOnInit || false,
                animation: customOptions.animation || {open: 'zoomIn', close: 'zoomOut'},
                fade: customOptions.fade || 0.4,
                blockScroll: customOptions.blockScroll || false,
                closeOnClick: false,
                closeButton: customOptions.closeButton || 'box',
                closeCallback: customOptions.closeCallback || null,
                outside: customOptions.outside || null,
                position: {
                    x: customOptions.position && customOptions.position.x || 'center',
                    y: customOptions.position && customOptions.position.y || 'center'
                },

                onClose: function () {
                    ko.cleanNode(self.Wrapper);
                    $('#' + this.id).detach();
                    $('#jBox-overlay' + this.id).detach();
                    defaultOptions.closeCallback && defaultOptions.closeCallback();
                    $(self).trigger('close');
                    self.Trigger("CLOSE");
                    self.Clear(this.id);
                    Counter.Instance.ResetValue;
                },

                appendTo: 'body:first'
            };

        const recalculate = ($item, state, width, height) => {
            let getDefaultWidth,
                defaultStyle,
                getHeight,
                widthPercent;

            clearInterval(recalculateTimerId);

            let isMobile = MobileChecker.IsMobile();

            if (isMobile) {
                width = 100;
                defaultOptions.widthInPercent = 100;
            } else {
                if (width != 'undefined') {
                    defaultWidth = width;
                } else {
                    width = defaultWidth;
                }

                getDefaultWidth = () => {
                    let pixels,
                        screenWidth,
                        percentage;

                    if (state) {
                        pixels = $item.width(),
                            screenWidth = $window.width(),
                            percentage = (screenWidth - pixels) / screenWidth * 100;

                        return 100 - percentage;
                    }
                }
            }

            defaultStyle = () => {
                let windowHeight = $window.height(),
                    itemHeight = $item.height(),
                    top = (windowHeight - itemHeight) / 2,
                    scripts = $item.find('script');

                $item
                    .css('margin', '0 auto')
                    .css('left', '0')
                    .css('right', '0')
                    .css('top', top + 'px');

                if (scripts.length > 0) {
                    scripts.css('display', 'none');
                }
            };

            widthPercent = (percent) => {
                if (percent > 0 && percent <= 100) {
                    $item.css('width', percent + '%');
                } else {
                    $item.css('width', width + '%');
                }
            };

            if (width === 0) {
                width = getDefaultWidth();
            }

            if (state) {
                defaultStyle();
            }

            widthPercent(width);

            $item.removeClass('opacity-0-imp');

            getHeight = () => {
                let jBoxContent,
                    searchResultsScroll,
                    searchScreen,
                    alternativeEntitiesContainerHeight,
                    searchPaginationHeight = 0,
                    searchToolbarFormGroupHeight = 0,
                    fluffHeight = 127;

                jBoxContent = $item.find('.jBox-content');
                searchResultsScroll = $item.find('.search-results-scroll');
                searchScreen = $item.find('.searchScreenContainer');

                if (searchResultsScroll.length > 0) {
                    let alternativeEntitiesContainer: any = $('.alternativeEntitiesContainer').outerHeight(true);

                    alternativeEntitiesContainerHeight = alternativeEntitiesContainer && alternativeEntitiesContainer || 0;
                    searchToolbarFormGroupHeight = searchScreen.find('.searchToolbarFormGroup').outerHeight(true);
                    searchPaginationHeight = searchScreen.find('.search-pagination').outerHeight(true);

                    let reservedHeight = alternativeEntitiesContainerHeight + searchToolbarFormGroupHeight + searchPaginationHeight + fluffHeight;
                    searchResultsScroll.find('tbody').css('max-height', (height - reservedHeight) + 'px');

                    jBoxContent.css({
                        'overflow': 'hidden',
                        'max-height': height
                    })
                }

                jBoxContent.css({'min-height': height, 'max-height': height});
            };

            if (height !== undefined) {
                getHeight();
                defaultStyle();
            }
        };

        const getMaxHeight = () => {
            let popupHeightInPercent = resizePopup && resizePopup.heightInPercent || defaultOptions.heightInPercent || GlobalManager.Instance.GetGlobal('PopupHeightInPercent'),
                percentHeight = parseInt(popupHeightInPercent),
                maxHeight,
                getHeight;

            getHeight = (percent) => {
                return $(window).height() / 100 * percent;
            };

            if (!isNaN(percentHeight) && percentHeight !== 0) {
                maxHeight = getHeight(percentHeight);
            } else {
                maxHeight = getHeight(80);
            }

            return maxHeight;
        };

        const initRecalculate = function () {
            let popupWidthInPercent = null;

            if (isUseGlobal){
                popupWidthInPercent = GlobalManager.Instance.GetGlobal('PopupWidthInPercent');
            } else if (defaultOptions.widthInPercent){
                popupWidthInPercent = defaultOptions.widthInPercent;
            } else if (resizePopup) {
                popupWidthInPercent = resizePopup.widthInPercent;
            } else {
                popupWidthInPercent = 80;
            }

            const percentWidth = parseInt(popupWidthInPercent);

            if (!isNaN(percentWidth)) {
                recalculateTimerId = setInterval(recalculate.bind(this, this.wrapper, true, percentWidth, getMaxHeight()), 50);

                const resizeFn = function () {
                    recalculate(this.wrapper, false, percentWidth, getMaxHeight());
                }.bind(this);

                this.wrapper.addClass('opacity-0-imp');

                recalculate(this.wrapper, false, percentWidth, getMaxHeight());

                setTimeout(() => $window.on('resize', resizeFn), 0);
            }
        };

        const maxHeight = getMaxHeight();

        options.maxHeight = maxHeight;
        options.reposition = true;

        if (isUseGlobal || defaultOptions.widthInPercent || resizePopup) {
            options.onCreated = initRecalculate;
        }
        if (isUseGlobal || resizePopup) {
            options.height = maxHeight;
            options.minHeight = (options.minHeight && options.minHeight <= maxHeight) ? options.minHeight : maxHeight;
        } else if (defaultOptions.maxHeight) {
            options.maxHeight = defaultOptions.maxHeight;
        }

        if (isDragOnMouseDown) {
            options.onOpen = () => {
                let curDown = false,
                    curYPos = 0,
                    curXPos = 0;
                const scrolledElement = $(this.Wrapper).parent('.jBox-content');
                scrolledElement.mousemove(function (m) {
                    if (curDown) {
                        scrolledElement.scrollTop(curYPos - m.pageY);
                        scrolledElement.scrollLeft(curXPos - m.pageX);
                    }
                });

                scrolledElement.mousedown(function (m) {
                    const parentOffset = $(this).children().position();
                    curDown = true;
                    curYPos = m.pageY - parentOffset.top;
                    curXPos = m.pageX - parentOffset.left;
                });

                scrolledElement.mouseup(function (m) {
                    curDown = false;
                });
            }
        }

        if (options.width === 'auto') {
            this._wrapper = document.createElement('div');
            this._wrapper.setAttribute('data-bind', 'template: { name: GetTemplateName.bind($data), afterRender: $data.AfterRender ? $data.AfterRender.bind($data) : function(){} }');
            options.content = $(this._wrapper);
            this._jbox = new jBox('Modal', $.extend({}, defaultOptions, options));
        } else {
            this._jbox = new jBox('Modal', $.extend({}, defaultOptions, options));
            this._wrapper = document.createElement('div');
            this._wrapper.setAttribute('data-bind', 'template: { name: GetTemplateName.bind($data), afterRender: $data.AfterRender ? $data.AfterRender.bind($data) : function(){} }');
            this._jbox.setContent($(this._wrapper));
        }
    }

    AddClass(className: string){
        this._jbox.container.find('.jBox-content').addClass(className);
    }

    RemoveClass(className: string){
        this._jbox.container.find('.jBox-content').removeClass(className);
    }

    get Wrapper(): HTMLDivElement {
        return this._wrapper;
    }

    SetContent(content: JQuery | string): void {
        $(this._wrapper).append(content);
    }

    Clear(id?:string){
        $(this._wrapper).empty();
        if (id){
            $(`#${id}-overlay`).remove();
        }
    }

    Show(): void {
        this._jbox.open();
    }

    Close(options?: { ignoreDelay?: boolean }): void {
        this._jbox.close(options);
    }

    Reposition(): void {
        this._jbox.position();
    }

    Overlay() {
        return this._jbox.overlay;
    }

    Container() {
        return this._jbox.container;
    }

    Destroy() {
        this._jbox.destroy();
    }

    AfterRender() {

    }

    OneRepositionModal(time: number = 300){
        setTimeout(() => {
            $(window).trigger('resize');
        }, time);
    }
}
