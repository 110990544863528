import {Guid} from 'Core/Common/Guid';

import {FieldCollectionModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/FieldCollectionModel";

export class DataRoleModel {
	Id: number;
	Name: string;
	FieldCollection: FieldCollectionModel;

	Guid: string;

	constructor(id: number, name: string, fieldCollection: FieldCollectionModel){
		this.Id = id;
		this.Name = name;
		this.FieldCollection = fieldCollection;

		this.Guid = Guid.NewGuid();
	}
}