import * as _ from "underscore";

import {Deserialize, deserialize} from 'libs/cerialize';

export class StatusDto {
    @deserialize
    Name: string;

    @deserialize
    Description: string;
}

export class ServiceStatusDto {
    @deserialize
    Environment: string;

    Statuses: Array<StatusDto>;

    public static OnDeserialized(instance : ServiceStatusDto, json : any) : void {
        instance.Statuses = Deserialize(JSON.parse(json.ServiceStatus), StatusDto);
    }
}