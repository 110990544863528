import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';

import {DropdownValuesDto} from "./Models/DropdownValuesDto";

export class SpimStore {
    GetAlphaNumericValues(params: { FieldId: number, RecordId: number }): P.Promise<DropdownValuesDto> {
        const deferred = P.defer<DropdownValuesDto>();

        Request.Send({
            proxy: {
                url: 'api/ScreenFormApi/GetAlphaNumericValues',
                type: 'GET',
                dataType: 'json',
            },
            params: params
        }).then(result => {
            if (result.IsSuccessfull) {
                deferred.resolve(result.ResultObject);
            } else {
                deferred.reject({message: 'Error getting alphanumeric values'})
            }
        });

        return deferred.promise();
    }
}