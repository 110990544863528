import * as ko from "knockout";

import {EVENTS} from "Core/Constant";
import {Guid} from "Core/Common/Guid";

import {RenderModes} from "Core/Constant";
import {BaseScreen} from "Core/Screens/BaseScreen";
import {ScreenModel} from "Core/Models/Screens/ScreenModel";
import {IPosition} from "Core/Portlets/Models/Common/Interfaces/IPosition";
import {PortletBar} from "Core/Controls/PortletBar/PortletBar";
import {IPortletRuntimeSettings} from "Core/Controls/PortletBar/Interfaces/IPortletRuntimeSettings";
import {Grid} from "Core/Controls/Grid/Grid";
import {ControlModel} from "Core/Controls/BaseControl/Models/ControlModel";

import PortletTemplate from 'Core/Portlets/Templates/Portlet.html';
import {PortletStates} from "Core/Portlets/Enums/PortletStates";

ko.templates["Core/Portlets/Templates/Portlet"] = PortletTemplate;

export class Portlet extends BaseScreen {
    private _portletBarControl: PortletBar;
    private _grid: Grid;

    ObjectId: number;
    Name: string;
    State: PortletStates;
    Position: IPosition;
    OldPosition: IPosition;

    get Guid(): Guid {
        return this._guid;
    }

    get ScreenId(): number {
        return this.GetScreenId();
    }

    get ScreenName(): string {
        return this.GetScreenName();
    }

    get ScreenTranslatedName(): string {
        return this.GetScreenTranslatedName();
    }

    get EntityId(): number {
        return this._model.EntityId;
    }

    get PortletBar(): PortletBar {
        return this._portletBarControl;
    }

    constructor(screen: ScreenModel) {
        let [portletBar, ...otherControls] = screen.SubForms[0].Controls;
        screen.SubForms[0].Controls = otherControls;

        super(screen, RenderModes.View);

        this.Init(screen, portletBar);

        this.BindPortletBarEvents();
    }

    GetTemplateName(): string {
        return "Core/Portlets/Templates/Portlet";
    }

    AfterRender(el): void {
    }

    AddRuntimeSettings(runtimeSettings: IPortletRuntimeSettings) {
        this.Trigger(EVENTS.PORTLETS.RUNTIME_SETTINGS_UPDATED, runtimeSettings);
    }

    ApplyRuntimeSettings(runtimeSettings: IPortletRuntimeSettings) {
        this._portletBarControl.ApplyRuntimeSettings(runtimeSettings);
        let appliedSettings = this._portletBarControl.RuntimeSettings();
        this.GetAllControls().forEach(control => {
            control.ChangeProperty('Search', appliedSettings.Search());
            control.ChangeProperty('ReadOnly', appliedSettings.ReadOnly());
        });
    }

    GetRuntimeSettings(): IPortletRuntimeSettings {
        return this._portletBarControl.RuntimeSettings();
    }

    Refresh() {
        if (this._grid) {
            this._grid.LoadData();
        }
    }

    private Init(screen: ScreenModel, portletBar: ControlModel) {
        this.Name = this.ScreenTranslatedName || this.ScreenName;
        this._guid = Guid.NewGuid();
        this.State = PortletStates.NoChanges;

        this._portletBarControl = new PortletBar({
            Model: portletBar,
            Form: this.SubForms[0],
            RenderMode: RenderModes.View
        });

        this._portletBarControl.ObjectId = this.ObjectId;

        this._grid = this.GetControl<Grid>("Grid");
    }

    private BindPortletBarEvents() {
        let events = EVENTS.PORTLETS.PORTLET_BAR.CONTROL_BUTTONS;

        Object.keys(events).forEach(event => {
            let eventName = events[event];
            this._portletBarControl.On(eventName, this, () => this.Trigger(eventName));
        });

        this._portletBarControl.One(EVENTS.PORTLETS.RUNTIME_SETTINGS_APPLIED, this, eventArgs => this.ApplyControlSettings(eventArgs.data));
        this._portletBarControl.On(EVENTS.PORTLETS.RUNTIME_SETTINGS_UPDATED, this, eventArgs => this.UpdateRuntimeSettings(eventArgs.data));
    }


    private ApplyControlSettings(runtimeSettings: IPortletRuntimeSettings) {
        if (this._grid) {
            this._grid.SetRecordsPerPage(runtimeSettings.Length());

            if (this._model.Data) {
                this._grid.SetValue({
                    SubjectEntityId: this._model.EntityId,
                    SubjectRecordId: this._model.Data.RecordId,
                    RecordSpecsModel: null
                });
            } else {
                this._grid.LoadData();
            }

            ['EnableLinkButton', 'EnableAdd&LinkButton', 'EnableAddQueryButton']
                .forEach(property => this._grid.ChangeProperty(property, false));
        }
    }

    private UpdateRuntimeSettings(runtimeSettings: IPortletRuntimeSettings) {
        this.ApplyControlSettings(runtimeSettings);
        this.Trigger(EVENTS.PORTLETS.RUNTIME_SETTINGS_UPDATED, runtimeSettings);
    }
}