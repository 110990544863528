import * as ko from "knockout"
import {BaseControl, IControlValue} from "Core/Controls/BaseControl/BaseControl"
import {SubForm} from "Core/Screens/Forms/SubForm/SubForm"
import {IControlParam} from "Core/Screens/IScreen"
import {BlockUI} from 'Core/Common/BlockUi'

import ViewTemplate from "Core/Controls/RecordSharing/Templates/View.html"
import EditTemplate from "Core/Controls/RecordSharing/Templates/Edit.html"
import ToolBarTemplate from "Core/Controls/RecordSharing/Templates/ToolBar.html"
import DesignTemplate from "Core/Controls/RecordSharing/Templates/Design.html"

import {RecordSharingModel} from "Core/Controls/RecordSharing/Models/RecordSharingModel"
import {RecordSharingViewModel} from "Core/Controls/RecordSharing/Models/RecordSharingViewModel"
import {RecordSharingSerializedModel} from "Core/Controls/RecordSharing/Models/RecordSharingSerializedModel"
import { RecordSharingStore } from "Core/Controls/RecordSharing/Stores/RecordSharingStore"
import { UserGroupViewModel } from "Core/Controls/RecordSharing/Models/RecordSharingViewModel"


ko.templates["Core/Controls/RecordSharing/Templates/ToolBar"] = ToolBarTemplate;
ko.templates["Core/Controls/RecordSharing/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/RecordSharing/Templates/Edit"] = EditTemplate;
ko.templates["Core/Controls/RecordSharing/Templates/Design"] = DesignTemplate;

enum Statuses {
	Ok,
	Error
}

export class RecordSharing extends BaseControl {
	private _profileId: number;
	private _data: KnockoutObservable<RecordSharingViewModel>;
	private _status: KnockoutObservable<Statuses>;

	constructor(params: IControlParam) {
		super(params);
		this._data = ko.observable(null);
		this._status = ko.observable(Statuses.Ok);
	}

	ApplyProperties(){}

	SetValue(value: IControlValue): void {
		this._profileId = value.SubjectRecordId;
		BlockUI.Block();
		RecordSharingStore.GetRecordSharingScreen(value.SubjectRecordId)
			.always(() => {
				BlockUI.Unblock();
			})
			.then(result => {
				if (!result.IsSuccessfull) {
					this._status(Statuses.Error);
					this._errorMessage(result.ErrorMessage);
					return;
				}

				let recordSharingModel: RecordSharingModel = result.ResultObject;
				let recordSharingViewModel = new RecordSharingViewModel(recordSharingModel);

				this._data(recordSharingViewModel);

				_.each(this._data().UserGroups, (userGroup) => {
					let countCheckedValues = 0;

					_.each(this._data().Tables, (tableItem) => {
						const selectedRight = _.findWhere(tableItem.Rights(), { UserGroupId: userGroup.Id });
						if (selectedRight && selectedRight.IsShared()) {
							countCheckedValues++;
						}
					});

					const isUserGroupChecked = countCheckedValues === this._data().Tables.length;
					userGroup.AllSelected(isUserGroupChecked);
				});

				_.each(this._data().Tables, (table) => {
					_.each(table.Rights(), (right) => {
						right.IsShared.subscribe(() => {
							let countCheckedValues = 0;
							const selectedUserGroup = _.findWhere(this._data().UserGroups, { Id: right.UserGroupId });

							_.each(this._data().Tables, (tableItem) => {
								const selectedRight = _.findWhere(tableItem.Rights(), { UserGroupId: selectedUserGroup.Id });
								if (selectedRight && selectedRight.IsShared()) {
									countCheckedValues++;
								}
							});

							const isUserGroupChecked = countCheckedValues === this._data().Tables.length;
							selectedUserGroup.AllSelected(isUserGroupChecked);
						});
					});
				});

				this._status(Statuses.Ok);
			});
	}

	AfterRender(el: Array<HTMLElement>): void {
		super.AfterRender(el);
	}

	CheckAll(userGroup: UserGroupViewModel) {
		this.SelectAll(userGroup);
		return true;
	}

	SelectAll(userGroup: UserGroupViewModel) {
		const isUserGroupChecked = !userGroup.AllSelected();
		_.each(this._data().Tables, (table) => {
			_.each(table.Rights(), (rightItem) => {
				if (rightItem.UserGroupId === userGroup.Id) {
					rightItem.IsShared(isUserGroupChecked);
				}
			});
		});
	}

	Deserialize(): RecordSharingSerializedModel {
		if (this._status() === Statuses.Error) {
			return null;
		}

		return new RecordSharingSerializedModel(this._profileId, this._data());
	}
}