import * as ko from "knockout"
import {BaseControl, IControlValue} from "Core/Controls/BaseControl/BaseControl"
import {SubForm} from "Core/Screens/Forms/SubForm/SubForm"
import {IControlParam} from "Core/Screens/IScreen"
import {BlockUI} from 'Core/Common/BlockUi'

import ViewTemplate from "Core/Controls/TableSecurity/Templates/View.html"
import EditTemplate from "Core/Controls/TableSecurity/Templates/Edit.html"
import ToolBarTemplate from "Core/Controls/TableSecurity/Templates/ToolBar.html"
import DesignTemplate from "Core/Controls/TableSecurity/Templates/Design.html"

import {TableSecurityModel} from "Core/Controls/TableSecurity/Models/TableSecurityModel";
import { TableSecurityViewModel, TableViewModel, RightViewModel, TableGroupViewModel} from "Core/Controls/TableSecurity/Models/TableSecurityViewModel";
import {TableSecuritySerializedModel} from "Core/Controls/TableSecurity/Models/TableSecuritySerializedModel";
import {TableSecurityStore} from "Core/Controls/TableSecurity/Stores/TableSecurityStore";

ko.templates["Core/Controls/TableSecurity/Templates/Edit"] = EditTemplate;
ko.templates["Core/Controls/TableSecurity/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/TableSecurity/Templates/ToolBar"] = ToolBarTemplate;
ko.templates["Core/Controls/TableSecurity/Templates/Design"] = DesignTemplate;

enum Statuses {
	Ok,
	Error
}

export class TableSecurity extends BaseControl {
	private _userGroupId: number;
	private _data: KnockoutObservable<TableSecurityViewModel>;
	private _status: KnockoutObservable<Statuses>;

	constructor(params: IControlParam) {
		super(params);
		this._data = ko.observable(null);
		this._status = ko.observable(Statuses.Ok);
	}

	ApplyProperties(){}

	SetValue(value: IControlValue): void {
		this._userGroupId = value.SubjectRecordId;
		BlockUI.Block();
		TableSecurityStore.GetTableSecurityScreen(value.SubjectRecordId)
			.then(result => {
				if (!result.IsSuccessfull) {
					this._status(Statuses.Error);
					this._errorMessage(result.ErrorMessage);
					return;
				}

				let tableSecurityModel: TableSecurityModel = result.ResultObject;
				let tableSecurityViewModel = new TableSecurityViewModel(tableSecurityModel);

				this._data(tableSecurityViewModel);

				_.each(this._data().Rights, (right) => {
					let countCheckedValues = 0;
					_.each(this._data().Tables, (table) => {
						_.each(table.Rights(), (rightItem) => {
							if (rightItem.IsChecked() && rightItem.TableGroupId === right.Id) {
								countCheckedValues += 1;
							}
						});
						if (countCheckedValues === this._data().Tables.length) {
							right.AllSelected(true);
						}
					});
				});

				_.each(this._data().Tables, (table) => {
					_.each(table.Rights(), (right) => {
						right.IsChecked.subscribe((val) => {
							let countCheckedValues = 0;
							_.each(this._data().Tables, (tableItem) => {
								_.each(tableItem.Rights(), (rightItem) => {
									if (right.TableGroupId === rightItem.TableGroupId && rightItem.IsChecked()) {
										countCheckedValues += 1;
									}
								});
							});
							if (countCheckedValues === this._data().Tables.length) {
								_.each(this._data().Rights, (tableGroup) => {
									if (tableGroup.Id === right.TableGroupId) {
										tableGroup.AllSelected(true);
									}
								});
							}
							else {
								_.each(this._data().Rights, (tableGroup) => {
									if (tableGroup.Id === right.TableGroupId) {
										tableGroup.AllSelected(false);
									}
								});
							}
						});
					});
				});

				this._status(Statuses.Ok);
			}).always(() => BlockUI.Unblock());
	}

	AfterRender(el: Array<HTMLElement>): void {
		super.AfterRender(el);
	}

	CheckAll(tableGroup: TableGroupViewModel) {
		this.SelectAll(tableGroup);
		return true;
	}

	SelectAll(tableGroup: TableGroupViewModel) {
		let tableChecking = !tableGroup.AllSelected();
		_.each(this._data().Tables, (table) => {
			_.each(table.Rights(), (rightItem) => {
				if (rightItem.TableGroupId === tableGroup.Id) {
					rightItem.IsChecked(tableChecking);
				}
			});
		});
	}

	Deserialize(): TableSecuritySerializedModel {
		if (this._status() === Statuses.Error) {
			return null;
		}

		return new TableSecuritySerializedModel(this._userGroupId, this._data());
	}
}