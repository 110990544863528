export class CurrentLifeStatusViewModel {
    Id: number;
    Name: string;
    Memo: string;
    MemoTranslation: string;
    LifeStatusTranslatedName: string;
    NoActionNode: boolean;
    IsFollowUpDisabled: boolean;
    DisabledReason: string;

    Valid() {
        return true;
    }
}