export var LifeStatusEnum = {
	Enabled: 'Enabled',
	Active: 'Active',
	Example: "Example",
	ReadyToProcess: "ReadyToProcess",
	Retired: "Retired",
	Maintained: "Maintained",
	Acquired: "Acquired",
	Planned: "Planned",
	Disabled: "Disabled",
    Delete: 'Delete',
    SubExample: "SubExample"
};
