import * as ko from 'knockout';

export class UsersViewModel {
	Users: KnockoutObservableArray<UserViewModel>;
	SelectedUser: KnockoutObservable<UserViewModel>;

	constructor() {
		this.Users = ko.observableArray([]);
		this.SelectedUser = ko.observable(null);
	}
}

export class UserViewModel {
	Id: number;
	Name: string;
}