import * as ko from 'knockout';
import * as _ from 'underscore';

import { SortItem } from 'QueryBuilder/QuerySorting/SortItem/SortItem';
import { FieldMetadataModel } from 'Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel';
import { QueryExpressionModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import { QuerySortModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QuerySortModel';
import { SortOrder } from 'QueryBuilder/Enums';
import { QueryColumnModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryColumnModel';
import { Util } from 'QueryBuilder/Util';

import QuerySortingTemplate from 'QueryBuilder/QuerySorting/Templates/QuerySorting.html';
import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

export class QuerySorting {
	private _expressionModel: QueryExpressionModel;
	private _items: KnockoutObservableArray<SortItem>;
	private _isMoving: KnockoutObservable<boolean>;

	constructor() {
		this._items = ko.observableArray([]);
		this._isMoving = ko.observable(false);

	}

	set ExpressionModel(expressionModel: QueryExpressionModel) {
		this._expressionModel = expressionModel;

		var entities = Util.GetAllQueryEntities(this._expressionModel);

		_.each(this._expressionModel.Sorts, (item) => {
			var queryEntity = _.find(entities, (entity) => { return entity.Guid === item.Column.QueryEntityGuid });
			if (queryEntity) {
				var fieldMetadata = _.find(queryEntity.Metadata.Fields, (field) => { return field.Id === item.Column.Metadata.Id });
				if (fieldMetadata) {
					item.Column.Metadata = fieldMetadata;
					var sortItem = new SortItem(item, queryEntity);
					this._items.push(sortItem);
				}
			}
		});
	}

	GetSortItemTemplateName() {
		return SortItem.GetTemplateName();
	}

	GetTemplateHtml() {
		return QuerySortingTemplate;
	}

	AddSortColumn(item, evt, ui) {
		let model = new QuerySortModel();
		model.Column = new QueryColumnModel();
		model.Column.Metadata = item.FieldMetadataModel;
		model.Column.QueryEntityGuid = item.QueryEntityModel.Guid;
		model.SortOrder = SortOrder.Ascending;
		if (!this._expressionModel.Sorts) {
			this._expressionModel.Sorts = [];
		}
		this._expressionModel.Sorts.push(model);
		let sortItem = new SortItem(model, item.QueryEntityModel);
		return sortItem;
	}

	Update(){
		var entities = Util.GetAllQueryEntities(this._expressionModel);

		this._items().forEach((sortItem)=>{
			if(!_.any(entities,(entity)=>entity.Guid === sortItem.Model.Column.QueryEntityGuid)){
				this.RemoveSortColumn(sortItem);
			}
		});
	}

	StartSort() {
		this._isMoving(true);
	}

	StopSort() {
		this._isMoving(false);
		this._expressionModel.Sorts = _.map(this._items(), (item) => {
			return item.Model;
		});
	}

	@enumerable get Items(): KnockoutObservableArray<SortItem> {
		return this._items;
	}

	RemoveSortColumn(sortItem: SortItem) {
		this._expressionModel.Sorts.splice(this._expressionModel.Sorts.indexOf(sortItem.Model), 1);
		this._items.remove(sortItem);
	}
}