import { dia as Dia, shapes, V } from 'libs/rappid/build/rappid';
import * as joint from 'libs/rappid/build/rappid';
import Template from 'Core/Controls/CanvasDesigner/Shapes/Views/DataSelectorView/Templates/DataSelectorViewTemplate.html'
import { DataSelector } from 'Core/Controls/CanvasDesigner/Shapes/Views/DataSelectorView/DataSelector';
import { EVENTS } from 'Core/Controls/CanvasDesigner/Shapes/Views/DataSelectorView/Events';

declare module 'libs/rappid/build/rappid' {
	namespace shapes {
		namespace cyberThing {
			class DataSelectorView extends Dia.ElementView { }
		}
	}
}

joint.shapes.cyberThing.DataSelectorView = Dia.ElementView.extend({

	template: Template,

	initialize: function () {
		joint.dia.ElementView.prototype.initialize.apply(this, arguments);
		this.$box = $(this.template);

		if (!this.model._events.remove) {
			this.model.on('remove', this.removeBox, this);
		}
	},

	render: function () {
		joint.dia.ElementView.prototype.render.apply(this, arguments);
		this.paper.$el.prepend(this.$box);
		let dataSelector = new DataSelector(this.model.title, this.model.dataTypes);
		dataSelector.Render(this.$box[0]);
		dataSelector.On(EVENTS.SPREADSHEET_SELECTED, this, (eventArgs) => {
			this.model.trigger(EVENTS.SPREADSHEET_SELECTED, eventArgs.data.File);
		});

		dataSelector.On(EVENTS.OCI_PUNCH_OUT_SELECTED, this, () => {
			this.model.trigger(EVENTS.OCI_PUNCH_OUT_SELECTED);
		});

		dataSelector.On(EVENTS.TRIGGER_SELECTED, this, (eventArgs) => {
			this.model.trigger(EVENTS.TRIGGER_SELECTED, eventArgs.data.Id);
		});

		dataSelector.On(EVENTS.API_PACKAGE_SELECTED, this, (eventArgs) => {
			this.model.trigger(EVENTS.API_PACKAGE_SELECTED, eventArgs.data.Id);
		});

		dataSelector.On(EVENTS.ENTITY_SELECTED, this, (eventArgs) => {
			this.model.trigger(EVENTS.ENTITY_SELECTED, eventArgs.data.Id);
		});

		dataSelector.On(EVENTS.QUERY_SELECTED, this, (eventArgs) => {
			this.model.trigger(EVENTS.QUERY_SELECTED, eventArgs.data.Query);
		});

		dataSelector.On(EVENTS.DW_PACKAGE_SELECTED, this, (eventArgs) => {
			this.model.trigger(EVENTS.DW_PACKAGE_SELECTED, eventArgs.data.Id);
		});

		dataSelector.On(EVENTS.EXACT_ONLINE_ENTITY_SELECTED, this, (eventArgs) => {
			this.model.trigger(EVENTS.EXACT_ONLINE_ENTITY_SELECTED, eventArgs.data.Name);
		});

		dataSelector.On(EVENTS.EWS_ENTITY_SELECTED, this, (eventArgs) => {
			this.model.trigger(EVENTS.EWS_ENTITY_SELECTED, eventArgs.data.Name);
		});

		dataSelector.On(EVENTS.MS_ACCESS_ENTITY_SELECTED, this, (eventArgs) => {
			this.model.trigger(EVENTS.MS_ACCESS_ENTITY_SELECTED, eventArgs.data.Table, eventArgs.data.Path);
		});


		dataSelector.On(EVENTS.DESKTOP_FOLDER_SELECTED, this, (eventArgs) => {
			this.model.trigger(EVENTS.DESKTOP_FOLDER_SELECTED, eventArgs.data.Path);
		});
		
		this.updateBox();
		return this;
	},

	updateBox: function () {
		let bbox = this.model.getBBox();
		this.$box.css({
			width: bbox.width,
			height: bbox.height,
			left: bbox.x,
			top: bbox.y,
			transform: 'rotate(' + (this.model.get('angle') || 0) + 'deg)'
		});
	},

	removeBox: function () {
		this.$box.remove();
	}
});