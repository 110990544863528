import {Deserialize, deserialize} from 'libs/cerialize';

export class DatabaseModel {

	State: number;

	OriginalName: string;

	@deserialize
	Id: string;

	@deserialize
	Name: string;

	@deserialize
	ConnectionString: string;
}