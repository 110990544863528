import * as ko from 'knockout';
import * as _ from "underscore";
import * as $ from "jquery";

import {BlockUI} from "Core/Common/BlockUi";

import {SearchScreenStore} from 'Core/Screens/SearchScreen/Stores/SearchScreenStore';
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';
import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';
import {Event} from 'Core/Common/Event';
import {LABELS} from 'Core/Components/Translation/Locales';
import {JBoxDropDown} from "../JBoxDropdown/DropDown";
import {MobileChecker} from "../../Common/MobileChecker";

import RecentAndFavoritesTemplate from 'Core/Components/RecentAndFavorites/RecentAndFavorites.html';

export class RecentAndFavorites extends Event {
	private _entityId: number;
	private _entityName: string;
	private _recentList: KnockoutObservableArray<any>;
	private _favoritesList: KnockoutObservableArray<any>;
	private LABELS: any;
	private dataLoaded: KnockoutObservable<boolean>;
	private _flowFolder: boolean;
	private _dropDown: any;
	private _targetElem: HTMLElement;
	private _isMobile: KnockoutObservable<boolean>;

	constructor(entity, target, flowFolder: boolean) {
		super();

		this._entityId = entity.Table.Id;
		this._entityName = entity.Table.Name;
		this._recentList = ko.observableArray([]);
		this._favoritesList = ko.observableArray([]);
		this._flowFolder = flowFolder;
		this._targetElem = target;
		this._isMobile = ko.observable(MobileChecker.IsMobile());

		this.LABELS = LABELS;
		this.dataLoaded = ko.observable(false);

		this.Init();
	}

	Init() {

		if (!this._isMobile()) {
			this.InitRecentAndFavoritesDropdown(this._targetElem);
		}
		let elem = $(".recent-and-favorites-dropdown .jBox-container");
		BlockUI.Block({Target: elem.get(0)});

		SearchScreenStore.GetScreenModel({EntityId: this._entityId, TableName: this._entityName})
			.then((data) => {
				this._recentList(data.GroupNameToRecords.recent);
				this._favoritesList(data.GroupNameToRecords.favorites);
				this.dataLoaded(true);
				if (!this._isMobile()) {

					if (this._dropDown) {
						this._dropDown.SetContent({ content: this.GetTemplate() as any});
					}

					if(this._flowFolder) {
						const recentListContainer = $('.recent-block-list');
						let recentListItems = recentListContainer.children();

						_.each(recentListItems, el => {
							const $el: any = $(el);
							setTimeout(() => {

								const resizeObserver = new ResizeObserver(() => {
									$el.popover('destroy');

									$el.popover({
										content: '<i class="fa fa-ellipsis-v" /><i class="fa fa-ellipsis-v" />',
										template: `
									<div class="popover recent-block-list-container__flow-folder-popover">
										<div class="popover-content" />
									</div>
                            	`,
										html: true,
										placement: 'right',
										trigger: 'manual',
										animation: false,
										container: recentListContainer,
										viewport: recentListContainer
									});

									$el.on('inserted.bs.popover', () => {
										const $grip = $(`#${$el.attr('aria-describedby')}`);

										$grip.data('EntityId', this._entityId);
										$grip.data('RecordId', this._recentList()[recentListItems.index(el)].Id);

										$grip.draggable({
											helper: () =>
												$('<div />')
													.append($grip.clone().removeAttr('id')),
											classes: { 'ui-draggable-dragging': 'flow-folder-dragging-helper' },
											appendTo: 'body',
											scrollSensitivity: 75,
											zIndex: 100000
										});
									});

									$el.popover('show');
								});

								resizeObserver.observe(el);

								ko.utils.domNodeDisposal.addDisposeCallback($el[0], () => {
									$el.popover('destroy');

									resizeObserver.unobserve(el);
								});
							}, 500);
						});
					}
				}
			})
			.always(() => BlockUI.Unblock(elem.get(0)));
	}

	private InitRecentAndFavoritesDropdown(el: HTMLElement) {
		let listNavItem = el.closest('.recent-and-favorites-attach-to');

		this._dropDown = new JBoxDropDown({
			target: el,
			bindTarget: el,
			bindComponent: this,
			otherOptions: {
				addClass: "recent-and-favorites-dropdown",
				closeOnClick: 'body',
				attach: undefined,
				target: $(listNavItem),
				adjustPosition: true,
				adjustTracker: true,
				isolateScroll: true,
				pointer: "top",
				maxWidth: 200,
				minWidth: 200,
				minHeight: 100,
				onCloseComplete: () => {
					if (this._dropDown){
						this._dropDown.Destroy();
						this._dropDown = null;

						if ($('.page-sidebar-menu').hasClass("page-sidebar-menu-closed")) {
							const newTarget = $(listNavItem).closest(".subject-area").length ? $(listNavItem).closest(".subject-area") : listNavItem;
							$(newTarget).removeClass("recent-and-favorites-active");
						}
					}
				},
				zIndex: 1000,
				position: {
					x: "left",
					y: "center"
				},
				outside: 'x'
			},

			onOpen: () => {}
		});

		this._dropDown.Open();
	}

	DestroyRecentAndFavoritesDropdown() {
		this._dropDown.Destroy();
	}

	SelectUser(user) {
		UserVarsManager.Instance.AddRecent(this._entityId, user.Id, user.TypeId);

		PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, {
			EntityId: this._entityId,
			RecordId: user.Id,
			RecordTypeId: user.TypeId
		});

		this.Trigger('RECORD_SELECTED');
	}

	GetTemplate() {
		return RecentAndFavoritesTemplate;
	}

	AfterRender(el): void {
	}
}