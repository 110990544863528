import {GenericDeserialize} from "libs/cerialize";

import {P} from "Core/Common/Promise";
import {Request} from "Core/Common/Request";

import {RegisteredLock} from "./Models/RegisteredLock";
import { RegisteredLocks } from "./Models/RegisteredLocks";
import {NewLock} from "./Models/NewLock";
import { NewLocks } from "./Models/NewLocks";
import {Lock} from "./Models/Lock";
import {ContinuedLock} from "./Models/ContinuedLock";

export class LockStore {
	RequestLock(lock: NewLock): P.Promise<RegisteredLock> {
		const deferred = P.defer<RegisteredLock>();

		Request.Send({
			proxy: {
				url: '/api/LockingApi/TryLock',
				type: 'POST',
				dataType: 'json'
			},
			params: lock
		})
			.then(registeredLockJson => deferred.resolve(GenericDeserialize<RegisteredLock>(registeredLockJson, RegisteredLock)))
			.fail(error => {
				const errorMessage = this.GetErrorMessage(error.message);
				deferred.reject({message: errorMessage});
			});

		return deferred.promise();
    }

    RequestLocks(locks: NewLocks): P.Promise<RegisteredLocks> {
        const deferred = P.defer<RegisteredLocks>();
       
        Request.Send({
                proxy: {
                    url: 'api/LockingApi/TryLocks',
                    type: 'POST',
                    dataType: 'json'
                },
                params: locks
            })
            .then(registeredLocksJson => deferred.resolve(GenericDeserialize<RegisteredLocks>(registeredLocksJson, RegisteredLocks)))
            .fail(error => {
                const errorMessage = this.GetErrorMessage(error.message);
                deferred.reject({ message: errorMessage });
            });

        return deferred.promise();
    }

	ContinueLocks(lastEventDate: string): P.Promise<ContinuedLock> {
		const deferred = P.defer<ContinuedLock>();

		Request.Send({
			proxy: {
				url: 'api/LockingApi/ContinueLock',
				type: 'POST',
				dataType: 'json'
			},params: {
				LastEvent: lastEventDate
			}
		})
			.then(continuedLockJson => deferred.resolve(GenericDeserialize<ContinuedLock>(continuedLockJson, ContinuedLock)))
			.fail(error => {
				const errorMessage = this.GetErrorMessage(error.message);
				deferred.reject({message: errorMessage});
			});

		return deferred.promise();
	}

	ReleaseLocks(locks: Lock[]): P.Promise<any> {
		return Request.Send({
			proxy: {
				url: '/api/LockingApi/ReleaseLock',
				type: 'POST',
				dataType: 'json'
			},
			params: {
				LockModels: locks
			}
		});
	}

	private GetErrorMessage(errorJson: string) {
		const error = JSON.parse(errorJson);
		return error.Message;
	}
}