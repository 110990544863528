import * as ko from 'knockout'
import * as _ from 'underscore';

import { BaseControl } from 'Core/Controls/BaseControl/BaseControl'
import { IControlParam } from 'Core/Screens/IScreen'
import { RenderModes } from "Core/Constant";
import { GeneralProperties } from "Core/GeneralProperties/GeneralProperties";

import ReturnButtonConfig from "Core/Controls/ButtonReturn/Configs/return-button-config.json";

import ViewTemplate from 'Core/Controls/ButtonReturn/Templates/View.html'
import HelpViewTemplate from 'Core/Controls/ButtonReturn/Templates/HelpView.html'
import ToolBarTemplate from 'Core/Controls/ButtonReturn/Templates/ToolBar.html'

ko.templates['Core/Controls/ButtonReturn/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/ButtonReturn/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/ButtonReturn/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/ButtonReturn/Templates/Design'] = ViewTemplate;
ko.templates['Core/Controls/ButtonReturn/Templates/ToolBar'] = ToolBarTemplate;

export class ButtonReturn extends BaseControl {
    private _config: any;

	constructor(params: IControlParam) {
		super(params, ReturnButtonConfig);
        this._style = ko.computed(() => {
            return {
                backgroundColor: this._backgroundColor(),
                color: this._color(),
                borderColor: this._borderColor(),
                borderWidth: this._border() ? '1px' : '0',
                padding: this._border() ? '6px 14px' : '7px 14px'
            };
        });
		this.Init();
	}

	private Init(): void {
        this.ApplyProperties();
	}

    Click(): void {
		if (this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
		} else {
			var screen = this._form.GetScreen();
			if (screen) {
				screen.Trigger("SHOW_PREVIOUS_SCREEN");
			}
		}
	}

    ApplyProperties() {
        if (this.Properties) {
            this.AssignProperty('Styling', 'BackgroundColor', this._backgroundColor);
            this.AssignProperty('Styling', 'TextColor', this._color);
            this.AssignProperty('Styling', 'Border', this._border);
            this.AssignProperty('Styling', 'BorderColor', this._borderColor);
        }
    }

    private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
        if (this.Properties[groupedBy]) {
            _.each(this.Properties[groupedBy].Properties,
                (property: any) => {
                    if (property.hasOwnProperty(propertyName)) {
                        propertyHolder(property[propertyName]);
                    }
                });
        }
    }

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}
}