//Common
import * as moment from "moment";
import {Notifier} from "Core/Common/Notifier";
import {Event} from "Core/Common/Event";
import {FormatConverter} from "FormatEditor/FormatConverter";

//State
import {LockChain} from "./State/LockChain";
import {ILock} from "./State/ILock";
import {PulseDateTracker, PULSE_TRACKER_EVENTS} from "./State/PulseDateTracker";

//Store
import {LockStore} from "./Store/LockStore";

//Store models
import {RegisteredLock} from "./Store/Models/RegisteredLock";
import {RegisteredLocks} from "./Store/Models/RegisteredLocks";
import {NewLock} from "./Store/Models/NewLock";
import {NewLocks} from "./Store/Models/NewLocks";
import {Lock} from "./Store/Models/Lock";

import {
    ContinueLockConfirmation,
    CONTINUE_LOCK_CONFIRMATION_EVENTS
} from "./Modals/ContinueConfirmation/ContinueLockConfirmation";
import {P} from "Core/Common/Promise";
import enumerable from '../../Common/Decorators/EnumerableDecorator';


export class LockManager extends Event {
    private static _instance: LockManager;

    private _lastEventTime: number;
    private _lockRequestDateTime: Date;
    private _pulseDate: Date;

    private _chain: LockChain;
    private _store: LockStore;

    private _pulseDateTracker: PulseDateTracker;
    private _continueConfirmation: ContinueLockConfirmation;

    private constructor() {
        super();

        this._chain = new LockChain();
        this._store = new LockStore();

        this._pulseDateTracker = new PulseDateTracker();
        this._pulseDateTracker.On(PULSE_TRACKER_EVENTS.PULSE_DATE_EXPIRED, this, this.OnPulseDateExpired);

        this._continueConfirmation = new ContinueLockConfirmation(30000);

        this._continueConfirmation.On(CONTINUE_LOCK_CONFIRMATION_EVENTS.CONTINUE, this, () => this.ContinueLock(new Date()));
        this._continueConfirmation.On(CONTINUE_LOCK_CONFIRMATION_EVENTS.STOP, this, this.ReleaseAllLocks);

        this.On(LOCK_EVENTS.ADDED, this, this.StartPulseDateTracking);
        this.On(LOCK_EVENTS.RELEASED, this, eventArgs => this.OnLockRelease(eventArgs.data));
    }

    static get Instance() {
        if (!LockManager._instance) {
            LockManager._instance = new LockManager();
        }

        return LockManager._instance;
    }

    TryLock(tableId: number, recordId: number): P.Promise<void> {
        const lock: ILock = {TableId: tableId, RecordId: recordId};

        const existingLock = this._chain.FindLock(lock);
        if (!existingLock) {
            const newLock = new NewLock(lock.TableId, lock.RecordId);

            this._chain.AddLock({
                TableId: lock.TableId,
                RecordId: lock.RecordId
            });

            return this.RequestLock(newLock).fail(() => this._chain.RemoveLock(lock));
        } else {
            const defer = P.defer<void>();

            setTimeout(() => {
                defer.resolve(null);
            }, 0);

            return defer.promise();
        }
    }

    TryLocks(tableId: number, recordIds: number[]): P.Promise<number[]> {
        return this.RequestLocks(new NewLocks(tableId, recordIds))
            .then(registeredLocks => {
                const ids = [];
                _.each(registeredLocks.Successful,
                    registeredLock => {
                        const lock: ILock = {TableId: registeredLock.TableId, RecordId: registeredLock.RecordId};
                        if (!this._chain.HasLock(lock)) {
                            this._chain.AddLock({
                                TableId: registeredLock.TableId,
                                RecordId: registeredLock.RecordId
                            });
                        }
                        ids.push(registeredLock.RecordId);
                    });
                return ids;
            });
    }

    ReleaseLock(tableId: number, recordId: number) {
        const lock = new Lock(tableId, recordId);

        const existingLock = this._chain.FindLock(lock);
        if (existingLock && !existingLock.Releasing) {
            existingLock.Releasing = true;

            this._store.ReleaseLocks([existingLock]).always(() => {
                this.Trigger(LOCK_EVENTS.RELEASED, lock);
            });
        }
    }

    ReleaseLocks(tableId: number, recordIds: number[]) {
        const locks = _.map(recordIds, item => new Lock(tableId, item));
        const lockToRelease = this._chain.FindLocks(locks).filter(lock => !lock.Releasing);

        lockToRelease.forEach(lock => lock.Releasing = true);

        this._store.ReleaseLocks(lockToRelease);

        _.each(lockToRelease,
            releasedLock => {
                this.Trigger(LOCK_EVENTS.RELEASED, releasedLock);
            });
    }

    ReleaseAllLocks() {
        const locks = this._chain.TakeAllLocks().map(this.MapToLock);

        if (locks.length > 0) {
            this._store.ReleaseLocks(locks);
        }

        locks.forEach(lock => this.Trigger(LOCK_EVENTS.RELEASED, lock));
    }

    RegisterEvent() {
        this._lastEventTime = new Date().getTime();
    }

    private StartPulseDateTracking() {
        this._pulseDateTracker.SetPulseDate(this._pulseDate);
        this._pulseDateTracker.Start();
    }

    private StopPulseDateTracking() {
        this._pulseDateTracker.Stop();
    }

    private OnPulseDateExpired() {
        if (this.UserIsActive()) this.ContinueLock(new Date(this._lastEventTime));
        else this._continueConfirmation.Show();
    }

    private OnLockRelease(lock: Lock) {
        this._chain.RemoveLock(lock);

        if (!this._chain.HasAnyLock()) {
            this.StopPulseDateTracking();
        }
    }

    private RequestLock(lock: NewLock): P.Promise<void> {
        return this._store.RequestLock(lock)
            .then(registeredLock => this.SaveLock(registeredLock))
            .fail((error: any) => this.ShowError(error.message));
    }

    private RequestLocks(locks: NewLocks): P.Promise<RegisteredLocks> {
        return this._store.RequestLocks(locks)
            .then(registeredLocks => {
                this.SaveLocks(registeredLocks);
                return registeredLocks;
            })
            .fail((error: any) => this.ShowError(error.message));
    }

    private SaveLock(lock: RegisteredLock) {
        this._lockRequestDateTime = new Date();
        const newPulseDate = new Date(FormatConverter.CorrectTimezone(lock.PulseDate));

        this._pulseDate = newPulseDate;

        this.Trigger(LOCK_EVENTS.ADDED);
    }

    private SaveLocks(locks: RegisteredLocks) {
        this._lockRequestDateTime = new Date();

        _.each(locks.Successful, lock => {
            const newPulseDate = new Date(FormatConverter.CorrectTimezone(lock.PulseDate));
            this._pulseDate = newPulseDate;
            this.Trigger(LOCK_EVENTS.ADDED);
        })

    }

    private UserIsActive() {
        return this._lastEventTime >= this._lockRequestDateTime.getTime();
    }

    private ContinueLock(lastEventTime: Date) {
        const lastEventDate = FormatConverter.ConvertToUTC(moment(lastEventTime).format(), '');
        this._store.ContinueLocks(lastEventDate)
            .then(continuedLock => {
                this._lockRequestDateTime = new Date();
                this._pulseDate = new Date(FormatConverter.CorrectTimezone(continuedLock.PulseDate));
                this.StartPulseDateTracking();

                this.Trigger(LOCK_EVENTS.CONTINUED);
            }).fail((error: any) => this.ShowError(error.message));
    }

    private MapToLock(lock: ILock) {
        return new Lock(lock.TableId, lock.RecordId);
    }

    private ShowError(error: string) {
        new Notifier().Failed(error);
    }
}

export const LOCK_EVENTS = {
    ADDED: 'Added',
    CONTINUED: 'Continued',
    RELEASED: 'Released'
};