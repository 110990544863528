import {deserialize} from 'libs/cerialize';
import {deserializeAs} from 'libs/cerialize';

import { SecurityDataModel } from 'Core/Components/SecurityEditor/Form/Models/SecurityDataModel';

export class NewRelationModel {

	constructor() {
		this.Id = null;
		this.IsMain = false;
		this.TypeName = null;
        this.TypeTranslatedName = null;
        this.Name = null;
        this.NameTranslation = null;
		this.Level = null;
		this.LinkFieldId = null;
		this.IsNewLink = false;
		this.IsRecordOwner = false;
	}

	@deserialize
	Id: number;

	@deserialize
	IsMain: boolean;

	@deserialize
    TypeName: string;

    @deserialize
    TypeTranslatedName: string;

	@deserialize
	Name: string;

	@deserialize
	NameTranslation: string;

	@deserialize
	Level: number;

	@deserialize
	LinkFieldId: number;

	@deserialize
	IsNewLink: boolean;

	@deserialize
	HasCustomFields: boolean;

	@deserialize
	IsRecordOwner: boolean;

	@deserialize
	IsNewRecord: boolean;

	UserAllowance: number;
}