const Cultures = {
    'nl-nl': 'nl'
};

export class FullCalendarCultures {
    static Get(culture: string) {
        if (Cultures.hasOwnProperty(culture.toLowerCase())) {
            return Cultures[culture.toLowerCase()];
        }
        return culture;
    }
}
