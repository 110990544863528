import * as ko from "knockout";
import * as _ from "underscore";

import {IControl} from "Core/Controls/IControl";
import {BaseControlEditor} from "./BaseControlEditor";

import Template from 'Core/Screens/DesignScreen/ControlEditor/Templates/GridControlEditor.html';

ko.templates['Core/Screens/DesignScreen/ControlEditor/Templates/GridControlEditor'] = Template;

export class GridControlEditor extends BaseControlEditor {
	constructor(control: IControl) {
		super(control);
	}

	GetTemplateName() {
		return 'Core/Screens/DesignScreen/ControlEditor/Templates/GridControlEditor';
	}
}