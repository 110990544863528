import "jBox"
import "jBox.Notice"
import {ZIndexManager} from 'Core/Common/ZIndexManager'
import {LABELS} from "Core/Components/Translation/Locales";

interface IMessageType {
    Color: string;
    Title: string;
}

export class MessageTypes {
    get Warning(): IMessageType {
        return {Color: 'yellow', Title: this.GetTitle(LABELS.NOTIFIER_WARNING, 'Warning')};
    }

    get Success(): IMessageType {
        return {Color: 'green', Title: this.GetTitle(LABELS.NOTIFIER_SUCCESS, 'Success')};
    }

    get Fail(): IMessageType {
        return {Color: 'red', Title: this.GetTitle(LABELS.NOTIFIER_FAIL, 'Fail')};
    }

    private GetTitle(title: string, fallback: string): string {
        return isNaN(+title) ? title : fallback;
    }
}

interface INotification {
    Message: string;
    Type: IMessageType;
    ZIndex?: number
}

export class Notifier {
    private _container: any;
    private _messageTypes: MessageTypes;

    constructor(container?: any) {
        this._container = container;
        this._messageTypes = new MessageTypes();
    }

    Show(notification: INotification) {
        var options = {
            target: this._container || 'body',
            title: notification.Type.Title,
            content: notification.Message,
            theme: 'NoticeBorder',
            position: {
                x: 'right',
                y: 'top'
            },
            zIndex: notification.ZIndex || 1000000,
            color: notification.Type.Color,
            animation: 'flip',
            offset: null
        };

        if (!this._container) {
            options.offset = {
                y: 50
            }
        }
        return new jBox('Notice', options);
    }

    Success(message) {
        this.Show({Message: message, Type: this._messageTypes.Success});

    }

    Failed(message) {
        this.Show({Message: message, Type: this._messageTypes.Fail});

    }

    Warning(message) {
        this.Show({Message: message, Type: this._messageTypes.Warning});
    }
    
    static Failed(message) {
        new Notifier().Failed(message);
    }

    static Warning(message) {
        new Notifier().Warning(message);
    }

    static Success(message) {
        new Notifier().Success(message);
    }
}

export var messages = {
    GENERAL: {
        RECORD_CREATED: {
            title: "Success",
            message: "Record created",
            type: "success"
        },

        RECORD_UPDATED: {
            title: "Sucesss",
            message: "Record updated",
            type: "success"
        },

        RECORD_SAVED: {
            title: "Sucesss",
            message: "Record saved",
            type: "success"
        }
    },

    FORM_RESETED: {
        title: "Failed",
        message: "Form reseted.",
        type: "error"
    },

    QUERY_BUILDER: {
        SELECTED_FIELD: {
            title: "Failed",
            message: "Please select fields.",
            type: "error"
        },
        QUERY_EXECUTED: {
            title: "Success",
            message: "Query executed",
            type: "success"
        }
    }
};