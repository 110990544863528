import * as ko from 'knockout';

import {CustomFieldControl} from "../CustomFieldControl";

import Template from './Template.html';
import {ProductCustomFieldDescription} from "../../ProductCustomFieldDescription";
import {CustomFieldValue} from '../../../CustomFieldValue';
import {ProductPart} from '../../../ProductPart';

export class CustomIntegerControl extends CustomFieldControl {
	private _displayValue: KnockoutObservable<string>;

	constructor(customField: ProductCustomFieldDescription, productPart: ProductPart) {
		super(customField, productPart);

		this.HasContent = ko.observable(true);
		this._displayValue = ko.observable(null);
	}

	GetTemplate() {
		return Template;
	}

	GetConvertedValue(){
		const value = this._displayValue();
		let convertedValue = !value && value !== '0' ? null : +value;

		convertedValue = isNaN(convertedValue) ? null : convertedValue;

		return convertedValue;
	}

	GetValueForSave(): any {
		return new CustomFieldValue(this.Id, this.GetConvertedValue(), this.IsValid( this.GetConvertedValue() ));
	}

	SetValue(customFieldValue: CustomFieldValue) {
		if (customFieldValue) {
			this._displayValue(customFieldValue.Value);
		}

		this.StartListeningValueChanges();
	}

	SetDefaultValue() {
		const defaultFieldValue = new CustomFieldValue(this.Id, this.DefaultValue(), this.IsValid(this.DefaultValue()));
		this.SetValue(defaultFieldValue);
	}

	ClearValue() {
		this._displayValue(null);
	}

	private StartListeningValueChanges() {
		if (this._displayValue.getSubscriptionsCount() == 0) {
			this._displayValue.subscribe(() => {
				this.CheckValidControl();
				this.OnValueChanged();
			});
		}
	}

	IsValid(value: any): boolean {
		return this.GetDecimalIntegerValid(value);
	}

	CheckValidControl() {
		this.isValidControl(this.GetDecimalIntegerValidRules(this.GetConvertedValue()));
	}
}