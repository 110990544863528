import {P} from 'Core/Common/Promise';
import {Request} from 'Core/Common/Request';

export interface IKanbanTodoType {
    Id: number;
    Name: string;
}

export interface IKanbanField {
    Id: number;
    Name: string;
}

export interface IKanbanSubjectEntity {
    Id: number;
    Name: string;
    Fields: IKanbanField[];
}

export interface IKanbanTodoEntity {
    Id: number;
    Name: string;
    Types: IKanbanTodoType[];
    Fields: IKanbanField[];
}

interface DesignOptionsResponseModel {
    ScreenSubjectFields: IKanbanField[];
    KanbanSubjectEntities: IKanbanSubjectEntity[];
    TodoEntities: IKanbanTodoEntity[];
}

export class KanbanControlEditorStore {
    GetDesignOptions(subjectEntityId: number) {
        const deferred = P.defer<DesignOptionsResponseModel>();

        Request.Send({
            proxy: {
                url: '/api/KanbanBoardApi/GetDesignOptions',
                type: 'GET',
                dataType: 'json'
            },
            params: {subjectEntityId}
        })
            .then((options: DesignOptionsResponseModel) => deferred.resolve(options))
            .fail(error => deferred.reject(this.GetResponseError(error, 'Error reading design options')));

        return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}