import * as ko from 'knockout';

import {PropertyControl} from "../PropertyControl";

import Template from './Template.html';
import {ProductPropertyDescription} from "../../ProductPropertyDescription";
import {ProductPart} from "../../../ProductPart";
import { PropertyValue } from '../../../PropertyValue';

export class CheckboxPropertyControl extends PropertyControl {
    private _displayValue: KnockoutObservable<boolean>;

    constructor(property: ProductPropertyDescription, productPart: ProductPart) {
        super(property, productPart);

        this.onValueChangedTimeoutValue = 0;

        this.HasContent = ko.observable(true);
        this._displayValue = ko.observable(false);
    }

    GetTemplate() {
        return Template;
    }

    GetValueForSave(): any {
        return this._displayValue();
    }

    SetValue(propertyValue: PropertyValue) {
        if (propertyValue) {
            const displayValue = propertyValue.Value ? propertyValue.Value.toString().toLowerCase() === 'true' : false;
            this._displayValue(displayValue);
        }
        this.StartListeningValueChanges();
    }

    ClearValue() {
        this._displayValue(false);
    }

    private StartListeningValueChanges() {
        if (this._displayValue.getSubscriptionsCount() == 0) {
            this._displayValue.subscribe(() => {
                this.CheckValidControl();
                this.OnValueChanged();
            });
        }
    }

    IsValid(value?: any): boolean {
        return this.GetIsRequiredValidation(value ? value : this.GetValueForSave(), 'YesNo');
    }

    CheckValidControl() {
        this.isValidControl(this.GetIsRequiredValidRule(this.GetValueForSave(), 'YesNo'));
    }
}