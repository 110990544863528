import * as ko from 'knockout';
import * as _ from 'underscore';
import {LanguageModel} from 'Auth/UI/AuthForm/Models/LanguageModel';
import {Event} from 'Core/Common/Event'
import LanguageSelectorTemplate from 'Auth/UI/AuthForm/Templates/LanguageSelector.html';

ko.templates['Auth/UI/AuthForm/Templates/LanguageSelector'] = LanguageSelectorTemplate;

export class LanguageSelector extends Event {
    private _languages: KnockoutObservableArray<LanguageModel>;
    private _language: KnockoutObservable<LanguageModel>;

    constructor() {
        super();
        this._languages = ko.observableArray([]);
        this._language = ko.observable(null);
        this.AddEvent('LANGUAGE_SELECTED');
    }

    SetLanguages(languages: Array<LanguageModel>, lastLanguage: string) {
        this._languages(languages);
        var defaultLanguage = _.find(this._languages(), (item: LanguageModel) => item.ShortName === lastLanguage);
        if (!defaultLanguage) {
            defaultLanguage = _.first(this._languages());
        }
        this._language(defaultLanguage);
    }

    get ActiveLanguage(): LanguageModel {
        return this._language();
    }

    GetTemplateName() {
        return 'Auth/UI/AuthForm/Templates/LanguageSelector';
    }

    RenderLanguageItem(data, escape) {
        return `<div><img src=${data.FlagBase64}/></span><span>${data.ShortName}</span></div>`;
    }

    SelectLanguage(language: LanguageModel) {
        this._language(language);
        this.Trigger('LANGUAGE_SELECTED', {Language: language.ShortName});
    }
}