import * as ko from "knockout";
import * as _ from "underscore";

import { Notifier } from "Core/Common/Notifier";
import { LABELS, NOTIFICATIONS } from "Core/Components/Translation/Locales";

import { GlobalManager, GLOBALS } from "Core/GlobalManager/GlobalManager";

import { FIELD_TYPES } from "Core/Constant";
import { extractLookupValFieldMetadata } from "Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel";

import { SearchScreen } from "Core/Screens/SearchScreen/SearchScreen";

import { ColumnEditor } from "LookupEditor/ColumnEditors/Base/ColumnEditor";
import { IValueUpdater } from "LookupEditor/Interfaces/IValueUpdater";

import { LookupEditorStore } from "LookupEditor/Store/LookupEditorStore";

import Template from "LookupEditor/Templates/Columns/Lookup.html";
import { EditableColumnModel } from "LookupEditor/Models/EditableColumnModel";
import { EditableRowModel } from "LookupEditor/Models/EditableRowModel";
import { UserVarsManager } from "Core/UserVarsManager/UserVarsManager";
import { PUB_SUB_EVENTS } from 'MenuManager/PubSubEvents';
import { FormatConverter } from "FormatEditor/FormatConverter";

ko.templates["LookupEditor/Templates/Columns/Lookup"] = Template;

interface IDropdownOption {
	ConvertedDisplayValue: string;
	DisplayValue: string;
	Value: string;
}

export class LookupColumnEditor extends ColumnEditor {
	private _columnName: string;
	private _isRequired: boolean;
	private _optionsLoaded: KnockoutObservable<boolean>;
	private _editModeEnabled: KnockoutComputed<boolean>;
	private _availableOptions: KnockoutObservableArray<IDropdownOption>;
	private _selectedOption: KnockoutObservable<IDropdownOption>;
	private _selectedOptionId: KnockoutObservable<string>;
	private _currentOwner: KnockoutObservable<IValueUpdater>;

	private _dropdownPlugins: string[];
	private _labels = LABELS;

	constructor(column: EditableColumnModel) {
		super(column);
		this._isRequired = column.IsRequired;
		this._optionsLoaded = ko.observable(false);
		this._editModeEnabled = ko.computed(() => this._optionsLoaded() && this._currentOwner() !== null, this);
		this._availableOptions = ko.observableArray([]);
		this._selectedOption = ko.observable(null);
		this._selectedOptionId = ko.observable(null);
		this._currentOwner = ko.observable(null);

		this._dropdownPlugins = ['hidden_textfield', 'dropdown_direction'];
		this.On("REQUEST_FAILED", this, eventArgs => new Notifier().Failed(eventArgs.data.message));
	}

	Show(): void {
	}

	Click(owner: IValueUpdater, el: HTMLElement) {
		this.DisableEditMode();
		this._currentOwner(owner);
		LookupEditorStore.GetLookupRecordsCount(this.column.Id)
			.then(recordsCount => this.ShowOptions(recordsCount, owner, el))
			.fail(error => this.Trigger("REQUEST_FAILED", error));
	}

	GetTemplateName(): string {
		return "LookupEditor/Templates/Columns/Lookup";
	}

	AfterRender(el, owner: IValueUpdater, columnName): void {
		this._currentOwner(owner);
		this._columnName = columnName;
	}

	ToString() {
		return "Lookup";
	}

	get EditModeEnabled() {
		return this._editModeEnabled;
	}

	get AvailableOptions() {
		return this._availableOptions;
	}

	get SelectedOption() {
		return this._selectedOption;
	}

	get SelectedOptionId() {
		return this._selectedOptionId;
	}

	get CurrentOwner() {
		return this._currentOwner;
	}

	private ShowOptions(recordsCount: number, owner: IValueUpdater, el: HTMLElement) {
		let searchScreenIfNumber = parseInt(GlobalManager.Instance.GetGlobal(GLOBALS.SEARCH_SCREEN_IF_RECORDS_COUNT));

		if (recordsCount >= searchScreenIfNumber) {
			this.ShowSearchScreen(owner, el);
		} else {
			this.ShowDropdown(owner, el);
		}
	}

	private ShowSearchScreen(owner: IValueUpdater, el: HTMLElement) {
		let searchScreen = new SearchScreen({ EntityId: this.column.ValTableId, SearchTerm: '' });
		searchScreen.On("RECORD_SELECTED", this, eventArgs => this.UpdateValue(eventArgs.data, owner, el));

		searchScreen.On("ALT_ENTITY_RECORD_SELECTED", this, (eventArgs) => {
			const data = eventArgs.data;
			UserVarsManager.Instance.AddRecent(data.EntityId, data.RecordId, data.TypeId);


			data.IsOpenInModal = false;
			PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, data);
		});

		searchScreen.Show();
	}

	private ShowDropdown(owner: IValueUpdater, el: HTMLElement) {
		this.GetRecords()
			.then(records => {
				this._availableOptions(this.GetOptions(records));

				const currentOption = this.GetCurrentOption(this._availableOptions(), owner.GetDbValue(this.column.Name));
				this.SetSelectedOption(currentOption);

				this.EnableEditMode();
			}).fail(error => new Notifier().Failed(error.message));
	}

	private UpdateValue(record: any, owner: IValueUpdater, el: HTMLElement) {
		this.GetRecord(record.RecordId)
			.then(recordData => {
				let displayField = _.find(recordData.Fields, (field: any) => field.FieldId === this.column.ValFieldId);
				let displayValue = displayField.FieldValue;

				owner.UpdateValue({
					Name: this._columnName,
					Value: record.RecordId,
					DisplayValue: displayValue
				});
				$(el).text(displayValue);
			}).fail(error => new Notifier().Failed(error.message));
	}

	private GetRecord(recordId: number) {
		return LookupEditorStore.GetRecord(this.column.ValTableId, recordId);
	}

	private GetRecords() {
		return LookupEditorStore.GetRecords(this.column.ValTableId);
	}

	private GetOptions(records: Array<any>): Array<IDropdownOption> {
		if (!records || records.length === 0) {
			return [];
		}

		return records.map(record => {
			let valueField = _.find(record.Fields, (field: any) => field.IsPrimaryKey) || {};
			let displayField = _.find(record.Fields, (field: any) => field.FieldId === this.column.ValFieldId) || {};
			let convertedDisplayValue = this.GetConvertedDisplayValue(displayField.FieldValue);

			return {
				Value: valueField.FieldValue,
				DisplayValue: displayField.FieldValue,
				ConvertedDisplayValue: convertedDisplayValue
		}
		});
	}

	private GetConvertedDisplayValue(displayValue: string) {
		if (!displayValue) {
            return this.emptyLookupValue;
		}

		const owner = this._currentOwner();

		if (owner instanceof EditableRowModel) {
			const column = owner.GetColumn(this._columnName);

			const validationFieldMetadata = extractLookupValFieldMetadata(column);

			if (
				validationFieldMetadata &&
				_.contains([FIELD_TYPES.Decimal, FIELD_TYPES.Integer], validationFieldMetadata.Type)
			) {
				if (validationFieldMetadata.FormatName === "Percentage") {
					return FormatConverter.LocalizeDecimalOrInteger(String(parseFloat(displayValue) * 100));
				}
				return FormatConverter.LocalizeDecimalOrInteger(displayValue);
			}
		}

		return displayValue;
	}

	private GetCurrentOption(options: Array<IDropdownOption>, currentDisplayValue: string) {
		return _.find(options, option => option.Value === currentDisplayValue);
	}

	private OptionSelected(owner: IValueUpdater) {
        const selectedOption = this._availableOptions().find(opt => opt.Value === this._selectedOptionId())
		const value = selectedOption ? selectedOption.Value : null;
		const displayedValue = selectedOption ? selectedOption.DisplayValue : null;
		
		owner.UpdateValue({
			Name: this._columnName,
			Value: value,
			DisplayValue: displayedValue
		});

		this.DisableEditMode();
	}

	private EnableEditMode() {
		this._optionsLoaded(true);
	}

	private AcceptOption() {
		if (this._isRequired && !this._selectedOption()) {
			new Notifier().Warning(NOTIFICATIONS.SELECT_ANY_OPTION);
		} else {
			this.OptionSelected(this._currentOwner());
		}
	}

	private ClearOption(owner: IValueUpdater) {
		this._optionsLoaded(false);
		this.SetSelectedOption(null);
		this._optionsLoaded(true);
	}

	private SetSelectedOption(option: IDropdownOption) {
		this._selectedOption(option);
		this._selectedOptionId(option ? option.Value : null);
	}

	private DisableEditMode() {
		this._optionsLoaded(false);
		this._currentOwner(null);
	}
}
