import * as ko from "knockout";

import {Event} from 'Core/Common/Event';
import {Modal} from 'Core/Common/Modal';

import {ITabSecurityEditParams} from './ITabSecurityEditParams';

import {IBusinessRoleSettings} from '../../ITabSecurityValue';
import {BusinessRolesEditSettings} from './Models/BusinessRolesEditSettings';

import EditTemplate from 'Core/GeneralProperties/Managers/TabSecurity/Views/EditView/Templates/Template.html'

ko.templates['Core/GeneralProperties/Managers/TabSecurity/Templates/Edit'] = EditTemplate;

export class TabSecurityEditView extends Event {
    private _settings: KnockoutObservableArray<BusinessRolesEditSettings>;
    private _modal: Modal;

    constructor(params: ITabSecurityEditParams) {
        super();
        this._settings = ko.observableArray(this.MapToSettings(params));
    }

    GetTemplateName() {
        return 'Core/GeneralProperties/Managers/TabSecurity/Templates/Edit';
    }

    AfterRender() {

    }

    Show() {
        this._modal = new Modal({
            addClass: 'jBox-padding-15px',
            maxWidth: 470,
            maxHeight: 450,
            closeButton: false,
            closeOnClick: false,
            closeOnEsc: false
        }, false);

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);

        this._modal.Show();
    }

    Close() {
        this._modal.Close();
        this._modal.Destroy();
    }

    Save() {
        const settings: IBusinessRoleSettings[] = this._settings()
            .filter(settings => settings.Value > 0)
            .map(settings => {
                return {RoleId: settings.Role.Id, Value: settings.Value}
            });

        this.Trigger('SaveChanges', {Settings: settings});
    }

    Cancel() {
        this.Close();
    }

    private MapToSettings(params: ITabSecurityEditParams) {
        return params.Roles.map(role => {
            const restriction = params.Settings.find(restriction => restriction.RoleId === role.Id);
            const value = restriction ? restriction.Value : 0;
            return new BusinessRolesEditSettings(role, value);
        });
    }
}