import * as _ from 'underscore';

import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import { P } from 'Core/Common/Promise';
import { Deserialize, GenericDeserialize } from 'libs/cerialize';
import { SpaceStatusDto } from './Tabs/SpaceStatusDto';
import { ServiceStatusDto } from './Tabs/ServiceStatusDto';
import { EnvironmentDto } from './Tabs/EnvironmentDto';

export class AdministrationStore {
	static GetSpaceStatus(): P.Promise<Array<SpaceStatusDto>> {
		var deferredResult = P.defer<Array<SpaceStatusDto>>();

		Request
		.For('/api/NetworkAdministrationApi/GetSpaceStatus')
		.Get()
			.then(data => deferredResult.resolve(Deserialize(data, SpaceStatusDto)));

		return deferredResult.promise();
	}

	static GetServiceStatus(): P.Promise<Array<ServiceStatusDto>> {
		var deferredResult = P.defer<Array<ServiceStatusDto>>();

		Request
		.For('/api/NetworkAdministrationApi/GetServiceStatus')
		.Get()
			.then(data => deferredResult.resolve(Deserialize(data, ServiceStatusDto)));

		return deferredResult.promise();
	}
	static GetEnvironments(): P.Promise<Array<EnvironmentDto>> {
		var deferredResult = P.defer<Array<EnvironmentDto>>();

		Request
		.For('/api/NetworkAdministrationApi/GetEnvironments')
		.Get()
			.then(data => deferredResult.resolve(Deserialize(data, EnvironmentDto)));

		return deferredResult.promise();
	}

	static MarkForDeletion(databaseId: number): P.Promise<null> {
		var deferredResult = P.defer<null>();

		Request
		.For('/api/NetworkAdministrationApi/MarkForDeletion')
		.Post({DatabaseId: databaseId})
		.then(() => {
			deferredResult.resolve(null);
		});

		return deferredResult.promise();
	}

	static Delete(databaseId: number): P.Promise<null> {
		var deferredResult = P.defer<null>();

		Request
		.For('/api/NetworkAdministrationApi/Delete')
		.Post({DatabaseId: databaseId})
		.then(() => {
			deferredResult.resolve(null);
		});

		return deferredResult.promise();
	}

	static UnmarkForDeletion(databaseId: number): P.Promise<null> {
		var deferredResult = P.defer<null>();

		Request
		.For('/api/NetworkAdministrationApi/UnMarkForDeletion')
		.Post({DatabaseId: databaseId})
		.then(() => {
			deferredResult.resolve(null);
		});

		return deferredResult.promise();
	}

}