import { Guid } from "Core/Common/Guid";
import { CollectionModel } from "DatabaseDesigner/Models/CollectionModel";

export function ClusterSettingsModel() {
    this._id = null;
    this._guid = Guid.NewGuid();

    this._leftTypeId = null;
    this._leftTypeGuid = null;

    this._rightTypeId = null;
    this._rightTypeGuid = null;

    this._leftStatusId = null;
    this._leftStatusGuid = null;

    this._rightStatusId = null;
    this._rightStatusGuid = null;

    this._relationTypeId = null;
    this._relationTypeGuid = null;

    this._clusterMode = null;
};

ClusterSettingsModel.prototype.FromJSON = function (data) {
    this._id = data.Id;
    this._guid = data.Guid || this._guid;

    this._leftTypeId = data.LeftTypeId;
    this._leftTypeGuid = data.LeftTypeGuid;

    this._rightTypeId = data.RightTypeId;
    this._rightTypeGuid = data.RightTypeGuid;

    this._leftStatusId = data.LeftStatusId;
    this._leftStatusGuid = data.LeftStatusGuid;

    this._rightStatusId = data.RightStatusId;
    this._rightStatusGuid = data.RightStatusGuid;

    this._relationTypeId = data.RelationTypeId;
    this._relationTypeGuid = data.RelationTypeGuid;

    this._relationTypeValueId = data.RelationTypeValueId;
    this._relationTypeValueGuid = data.RelationTypeValueGuid;

    this._clusterMode = data.ClusterMode;
};

ClusterSettingsModel.prototype.FromJSONArray = function (data) {
    var clusterSettingsList = new CollectionModel({ model: ClusterSettingsModel });

    for (var index = 0; index < data.length; index++) {
        var clusterSettings = new ClusterSettingsModel();

        clusterSettings.FromJSON(data[index]);
        clusterSettingsList.Add(clusterSettings);
    }

    return clusterSettingsList;
};

ClusterSettingsModel.prototype.toJSON = function () {
    return {
        Id: this.Id,
        Guid: this.Guid,
        LeftTypeId: this.LeftTypeId,
        LeftTypeGuid: this.LeftTypeGuid,
        LeftStatusId: this.LeftStatusId,
        LeftStatusGuid: this.LeftStatusGuid,
        RightTypeId: this.RightTypeId,
        RightTypeGuid: this.RightTypeGuid,
        RightStatusId: this.RightStatusId,
        RightStatusGuid: this.RightStatusGuid,
        RelationTypeId: this.RelationTypeId,
        RelationTypeGuid: this.RelationTypeGuid,
        RelationTypeValueId: this.RelationTypeValueId,
        RelationTypeValueGuid: this.RelationTypeValueGuid,
        ClusterMode: this.ClusterMode,
    };
};

ClusterSettingsModel.prototype.Reverse = function () {
    var newLeftTypeId = this._rightTypeId;
    var newRightTypeId = this._leftTypeId;
    var newLeftTypeGuid = this._rightTypeGuid;
    var newRightTypeGuid = this._leftTypeGuid;
    var newLeftStatusId = this._rightStatusId;
    var newRightStatusId = this._leftStatusId;
    var newLeftStatusGuid = this._rightStatusGuid;
    var newRightStatusGuid = this._leftStatusGuid;

    this._leftTypeId = newLeftTypeId;
    this._rightTypeId = newRightTypeId;
    this._leftTypeGuid = newLeftTypeGuid;
    this._rightTypeGuid = newRightTypeGuid;
    this._leftStatusId = newLeftStatusId;
    this._rightStatusId = newRightStatusId;
    this._leftStatusGuid = newLeftStatusGuid;
    this._rightStatusGuid = newRightStatusGuid;
};

Object.defineProperty(ClusterSettingsModel.prototype, 'Id', {
    enumerable: true,
    get: function () {
        return this._id;
    }
});

Object.defineProperty(ClusterSettingsModel.prototype, 'Guid', {
    enumerable: true,
    get: function () {
        return this._guid;
    }
});

Object.defineProperty(ClusterSettingsModel.prototype, 'LeftTypeId', {
    enumerable: true,
    get: function () {
        return this._leftTypeId;
    },
    set: function (value) {
        this._leftTypeId = value;
    }
});

Object.defineProperty(ClusterSettingsModel.prototype, 'LeftTypeGuid', {
    enumerable: true,
    get: function () {
        return this._leftTypeGuid;
    },
    set: function (value) {
        this._leftTypeGuid = value;
    }
});

Object.defineProperty(ClusterSettingsModel.prototype, 'RightTypeId', {
    enumerable: true,
    get: function () {
        return this._rightTypeId;
    },
    set: function (value) {
        this._rightTypeGuid = value;
    }
});

Object.defineProperty(ClusterSettingsModel.prototype, 'RightTypeGuid', {
    enumerable: true,
    get: function () {
        return this._rightTypeGuid;
    },
    set: function (value) {
        this._rightTypeGuid = value;
    }
});

Object.defineProperty(ClusterSettingsModel.prototype, 'LeftStatusId', {
    enumerable: true,
    get: function () {
        return this._leftStatusId;
    },
    set: function (value) {
        this._leftStatusId = value;
    }
});

Object.defineProperty(ClusterSettingsModel.prototype, 'LeftStatusGuid', {
    enumerable: true,
    get: function () {
        return this._leftStatusGuid;
    },
    set: function (value) {
        this._leftStatusGuid = value;
    }
});

Object.defineProperty(ClusterSettingsModel.prototype, 'RightStatusId', {
    enumerable: true,
    get: function () {
        return this._rightStatusId;
    },
    set: function (value) {
        this._rightStatusId = value;
    }
});

Object.defineProperty(ClusterSettingsModel.prototype, 'RightStatusGuid', {
    enumerable: true,
    get: function () {
        return this._rightStatusGuid;
    },
    set: function (value) {
        this._rightStatusGuid = value;
    }
});

Object.defineProperty(ClusterSettingsModel.prototype, 'RelationTypeId', {
    enumerable: true,
    get: function () {
        return this._relationTypeId;
    },
    set: function (value) {
        this._relationTypeId = value;
    }
});

Object.defineProperty(ClusterSettingsModel.prototype, 'RelationTypeGuid', {
    enumerable: true,
    get: function () {
        return this._relationTypeGuid;
    },
    set: function (value) {
        this._relationTypeGuid = value;
    }
});

Object.defineProperty(ClusterSettingsModel.prototype, 'RelationTypeValueId', {
    enumerable: true,
    get: function () {
        return this._relationTypeValueId;
    },
    set: function (value) {
        this._relationTypeValueId = value;
    }
});

Object.defineProperty(ClusterSettingsModel.prototype, 'RelationTypeValueGuid', {
    enumerable: true,
    get: function () {
        return this._relationTypeValueGuid;
    },
    set: function (value) {
        this._relationTypeValueGuid = value;
    }
});

Object.defineProperty(ClusterSettingsModel.prototype, 'ClusterMode', {
    enumerable: true,
    get: function () {
        return this._clusterMode;
    },
    set: function (value) {
        this._clusterMode = value;
    }
});