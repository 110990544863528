import { dia as Dia, shapes } from 'libs/rappid/build/rappid';

Dia.Link.define('cyberThing.Arrow', {
	type: 'cyberThing.Arrow',
	router: {
		name: 'normal'
	},
	connector: {
		name: 'normal'
	},
	attrs: {
		'.marker-source': {
			d: 'M 10 0 L 0 5 L 10 10 z',
			stroke: 'transparent',
			fill: '#222138',
			transform: 'scale(0.001)'
		},
		'.marker-target': {
			d: 'M 10 0 L 0 5 L 10 10 z',
			stroke: 'transparent',
			fill: '#222138',
			transform: 'scale(1)'
		},
		'.connection': {
			stroke: '#222138',
			strokeDasharray: '0',
			strokeWidth: 1,
			fill: 'none'
		},
		'.connection-wrap': {
			fill: 'none'
		}
	},
	toolMarkup: [
		'<g class="link-tool">',
        '<g class="tool-remove" event="remove">',
		'<circle r="11" />',
		'<path transform="scale(.8) translate(-16, -16)" d="M24.778,21.419 19.276,15.917 24.777,10.415 21.949,7.585 16.447,13.087 10.945,7.585 8.117,10.415 13.618,15.917 8.116,21.419 10.946,24.248 16.447,18.746 21.948,24.248z" />',
		'<title>Remove link.</title>',
		'</g>',
		'</g>'
	].join('')
});