import {URL} from "Core/Common/Url";
import {P} from "Core/Common/Promise";
import {IRequestOptions, Request} from "Core/Common/Request";
import {CreateEnvironmentModel} from 'Core/CyberConfigManager/Models/CreateEnvironmentModel';
import {Deserialize, GenericDeserialize} from 'libs/cerialize';
import {DatabaseModel} from "../Models/DatabaseModel";

export class EnvironmentsPageStore {
    CreateEnvironment(model : CreateEnvironmentModel){
        let deferredResult = P.defer<any>();
        let requestParams: IRequestOptions = {
            proxy: {
                url: URL.CYBERCONFIG_MANAGER.CREATE_ENVIRONMENT,
                type: 'POST',
                dataType: 'json'
            },
            params: model
        };

        Request.Send(requestParams)
            .then(() => deferredResult.resolve(null))
            .fail((data) => {
                let jsonResult = JSON.parse(data.message);
				deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
            });

        return deferredResult.promise();
    }
}