import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {GenericDeserialize} from 'libs/cerialize';
import {LookupFieldModel} from 'Core/Screens/DesignScreen/ControlEditor/Models/LookupFieldModel';

export interface IGetLookupFieldsRequestModel {
	ContextEntityId: number;
};

export class LookupFieldsStore {
	static GetLookup( params: IGetLookupFieldsRequestModel ): P.Promise<Array<LookupFieldModel>> {
		var deferredResult = P.defer<Array<LookupFieldModel>>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: "/api/FormDesignerApi/GetLookupFields",
				dataType: "json",
				type: "POST"
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if ( data.IsSuccessfull ) {
					deferredResult.resolve(this.Deserialize(data));
				}
			});

		return deferredResult.promise();
	}

	static GetMultiSelect(params: IGetLookupFieldsRequestModel): P.Promise<Array<LookupFieldModel>> {
		var deferredResult = P.defer<Array<LookupFieldModel>>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: "/api/FormDesignerApi/GetMultiSelectFields",
				dataType: "json",
				type: "POST"
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(this.Deserialize(data));
				}
			});

		return deferredResult.promise();
	}

	static Deserialize( jsonObject: any ): any {
		var model = GenericDeserialize<LookupFieldModel>(jsonObject.Data, LookupFieldModel);
		return model;
	}
}