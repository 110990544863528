import * as ko from 'knockout';
import * as _ from 'underscore';
import { LABELS } from "Core/Components/Translation/Locales";
import { BlockUI } from 'Core/Common/BlockUi';
import { QueryExpressionModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import { QueryBuilderStore } from 'QueryBuilder/Stores/QueryBuilderStore';
import { QueryScreenModel } from 'QueryBuilder/Models/QueryScreenModel';
import { GenericButtonModel } from 'QueryBuilder/Models/GenericButtonModel';
import { Util } from 'QueryBuilder/Util';
import {PrimaryKeyField} from 'QueryBuilder/QueryScreen/PrimaryKeyField';
import {FIELD_TYPES} from 'Core/Constant';

import Template from 'QueryBuilder/QueryScreen/Templates/QueryScreen.html';
import {GenericButtonConfigModel} from "../../Core/Controls/Grid/Models/GridDataModel/QueryExpression/GenericButtonConfigModel";


export class QueryScreen {
	private _expressionModel: QueryExpressionModel;
	private _labels = LABELS;
	private _subjectEntityId: number;
	private _screens: KnockoutObservableArray<QueryScreenModel>;
	private _showInMenu: KnockoutObservable<boolean>;
	private _genericButtons: KnockoutObservableArray<GenericButtonModel>;
	private _selectedScreen: KnockoutObservable<QueryScreenModel>;
	private _primaryKeyFields: KnockoutObservableArray<PrimaryKeyField>;
	private _genericButtonToFieldValue: any;

	constructor() {
		this._screens = ko.observableArray([]);
		this._showInMenu = ko.observable(false);
		this._genericButtons = ko.observableArray([]);
		this._selectedScreen = ko.observable(null);
		this._primaryKeyFields = ko.observableArray([]);
		this._genericButtonToFieldValue = {};

		this._selectedScreen.subscribe((newValue) => {
			if (this._expressionModel) {
				if (newValue) {
					this._expressionModel.QueryScreenId = newValue.Id;
				} else {
					this._expressionModel.QueryScreenId = null;
				}
				this.UpdateGenericButtonList();
			}
		});
	}

	set ExpressionModel(value: QueryExpressionModel) {
		this._expressionModel = value;
	}

	set SubjectEntityId(value: number) {
		this._subjectEntityId = value;
	}

	get ShowInMenu() {
		return this._showInMenu();
	}

	set ShowInMenu(value: boolean) {
		this._showInMenu(value);
	}

	Update() {
		BlockUI.Block();
		QueryBuilderStore.GetQueryScreens({ EntityId: this._subjectEntityId })
			.always(() => {
				BlockUI.Unblock();
			})
			.then((screens) => {
				this._screens(screens);
				let selectedScreen = _.find(this._screens(), (screen) => {
					return screen.Id === this._expressionModel.QueryScreenId;
				});
				if (selectedScreen) {
					this._selectedScreen(selectedScreen);
				}
				this.UpdateGenericButtonList();
			});
	}

	private UpdateGenericButtonList(){
		this._genericButtons([]);
		this._genericButtonToFieldValue = {};
		if(this._selectedScreen()) {
			let allEntities = Util.GetAllQueryEntities(this._expressionModel);
			let primaryKeyFields = [];
			_.each(allEntities, (item) => {
				_.each(item.Metadata.Fields, (field) => {
					if (field.Type === FIELD_TYPES.PKey) {
						primaryKeyFields.push(new PrimaryKeyField(item, field));
					}
				});
			});

			_.each(this._selectedScreen().GenericButtons, (button)=>{
				let fieldValue = ko.observable(null);

				let genericButtonConfig = _.find(this._expressionModel.GenericButtonConfigs, (config) => {
					return config.GenericButtonId === button.Id;
				});

				if(genericButtonConfig){
					let pkField = _.find(primaryKeyFields, (field: PrimaryKeyField) => {
						return field.FieldMetadata.Id === genericButtonConfig.FieldId;
					});

					if(pkField){
						fieldValue(pkField);
					}
				}

				fieldValue.subscribe((newValue: PrimaryKeyField) => {
					this.UpdateGenericButtonConfig(newValue, button);
				});

				this._genericButtonToFieldValue[button.Id] = fieldValue;
			});

			this._primaryKeyFields(primaryKeyFields);
			this._genericButtons(this._selectedScreen().GenericButtons);

		}else {
			this._expressionModel.GenericButtonConfigs = [];
		}
	}

	UpdateGenericButtonConfig(field: PrimaryKeyField, genericButton: GenericButtonModel){
		let value = _.find(this._expressionModel.GenericButtonConfigs, (item)=> {
			return item.GenericButtonId === genericButton.Id;
		});

		if(field){
			if(value){
				value.FieldId = field.FieldMetadata.Id;
				value.QueryEntityGuid = field.QueryEntityGuid;
				value.EntityId = field.EntityMetadata.Id;
			}else{
				let config = new GenericButtonConfigModel();
				config.GenericButtonId = genericButton.Id;
				config.FieldId = field.FieldMetadata.Id;
				config.QueryEntityGuid = field.QueryEntityGuid;
				config.EntityId = field.EntityMetadata.Id;
				this._expressionModel.GenericButtonConfigs.push(config);
			}
		}else{
			if(value){
				this._expressionModel.GenericButtonConfigs.splice(this._expressionModel.GenericButtonConfigs.indexOf(value), 1);
			}
		}
	}

	OptionAfterRender(option, item) {
		if (!item) {
			ko.applyBindingsToNode(option, { disable: true }, item);
		}
	}

	Clear() {
		this._selectedScreen(null);
	}

	GetTemplate() {
		return Template;
	}

	AfterRender() {
	}
}