//Common
import {P} from "Core/Common/Promise";
import {Request} from "Core/Common/Request";
import {Deserialize} from 'libs/cerialize';

//UI

//Models
import {YearDataResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Year/Response/YearDataResponseModel";
import {SaveReservationRequestModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Request/SaveReservationRequestModel";
import {TimeWritingReportResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Response/TimeWritingReportResponseModel";
import {DeleteReservationRequestModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Request/DeleteReservationRequestModel";
import {UsersResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Response/UsersResponseModel";
import {DisapprovedReportResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Disapproved/Response/DisapprovedReportResponseModel";
import {TimeCloseReportResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Request/TimeCloseReportResponseModel";
import {Notifier} from "Core/Common/Notifier";


export class TimeWritingStore {
    static GetUsers(timeTableId: number) {
        const deferredResult = P.defer<UsersResponseModel>();


        Request.Send({
            proxy: {
                url: "/api/ControlsApi/GetTimeWritingUsers",
                type: "GET",
                dataType: "json"
            }, params: {timeTableId: timeTableId}
        }).then(response => this.ResolveObjectResponse(response, deferredResult))
            .fail(() => this.RejectResponse('Error getting users', deferredResult));

        return deferredResult.promise();
    }

    static GetDay(params: {
        ControlId: number,
        TimeTableId: number,
        SubjectEntityId: number | null,
        SubjectRecordId: number | null,
        Date: string,
        UserId: number
    }): P.Promise<TimeWritingReportResponseModel> {
        const deferredResult = P.defer<TimeWritingReportResponseModel>();

        Request.Send({
            proxy: {
                url: "/api/ControlsApi/GetTimeWritingReportForDay",
                type: "POST",
                dataType: "json"
            },
            params: params
        }).then(response => {
            this.ResolveObjectResponse(response, deferredResult);
            if (response.ResultObject.WeekReports[0].Messages.length > 0) {
                response.ResultObject.WeekReports[0].Messages.forEach(message => {
                    switch (message.Type) {
                        case "Warning":
                            new Notifier().Warning(message.Message);
                            break;
                        case "Success":
                            new Notifier().Success(message.Message);
                            break;
                        case "Failed":
                            new Notifier().Failed(message.Message);
                            break;
                    }
                })
            }
        }).fail(() => this.RejectResponse("Error getting day data", deferredResult));

        return deferredResult.promise();
    }

    static GetDays(params: {
        ControlId: number,
        TimeTableId: number,
        SubjectEntityId: number | null,
        SubjectRecordId: number | null,
        StartDate: string,
        EndDate: string,
        UserId: number
    }): P.Promise<TimeWritingReportResponseModel> {
        const deferredResult = P.defer<TimeWritingReportResponseModel>();

        Request.Send({
            proxy: {
                url: "/api/ControlsApi/GetTimeWritingReportForDays",
                type: "POST",
                dataType: "json"
            },
            params: params
        }).then(response => {
            this.ResolveObjectResponse(response, deferredResult);
        }).fail(() => this.RejectResponse("Error getting day data", deferredResult));
        return deferredResult.promise();
    }

    static CloseDate(params: {
        TimeCloseDate: string,
        UserId: number
    }): P.Promise<TimeCloseReportResponseModel> {
        const deferredResult = P.defer<TimeCloseReportResponseModel>();


        Request.Send({
            proxy: {
                url: "/api/ControlsApi/CloseDate",
                type: "POST",
                dataType: "json"
            },
            params: params
        }).then(response => this.ResolveObjectResponse(response, deferredResult))
            .fail(() => this.RejectResponse("Error setting time close date", deferredResult));

        return deferredResult.promise();
    }

    static GetDisapproved(params: {
        ControlId: number,
        TimeTableId: number,
        SubjectEntityId: number | null,
        SubjectRecordId: number | null,
        UserId: number
    }): P.Promise<DisapprovedReportResponseModel> {
        const deferredResult = P.defer<DisapprovedReportResponseModel>();


        Request.Send({
            proxy: {
                url: "/api/ControlsApi/GetDisapprovedReport",
                type: "POST",
                dataType: "json"
            },
            params: params
        }).then(response => this.ResolveObjectResponse(response, deferredResult))
            .fail(() => this.RejectResponse("Error getting disapproved data", deferredResult));

        return deferredResult.promise();
    }

    static GetYear(model: { Year: number, EntityId: number, UserId: number, SubjectEntityId: number, SubjectRecordId: number }): P.Promise<YearDataResponseModel> {
        const deferredResult = P.defer<YearDataResponseModel>();

        Request.Send({
            proxy: {
                url: "/api/ControlsApi/GetYearReport",
                type: "POST",
                dataType: "json"
            },
            params: model
        }).then(response => {
            this.ResolveObjectResponse(response, deferredResult);
        })
            .fail(() => this.RejectResponse("Error getting reservation by year", deferredResult));
        return deferredResult.promise();
    }

    static CreateReservation(model: SaveReservationRequestModel) {
        const deferredResult = P.defer();

        Request.Send({
            proxy: {
                url: "/api/ControlsApi/CreateReservation",
                type: "POST",
                dataType: "json"
            },
            params: model
        }).then(response => this.ResolveResponse(response, deferredResult))
            .fail(() => this.RejectResponse("Error updating reservation", deferredResult));

        return deferredResult.promise();
    }

    static AcceptReservation(model: SaveReservationRequestModel) {
        const deferredResult = P.defer();

        Request.Send({
            proxy: {
                url: "/api/ControlsApi/AcceptReservation",
                type: "POST",
                dataType: "json"
            },
            params: model
        }).then(response => this.ResolveResponse(response, deferredResult))
            .fail(() => this.RejectResponse("Error updating reservation", deferredResult));

        return deferredResult.promise();
    }

    static UpdateReservation(model: SaveReservationRequestModel): P.Promise<any> {
        const deferredResult = P.defer();

        Request.Send({
            proxy: {
                url: "/api/ControlsApi/UpdateReservation",
                type: "POST",
                dataType: "json"
            },
            params: model
        }).then(response => this.ResolveResponse(response, deferredResult))
            .fail(() => this.RejectResponse("Error updating reservation", deferredResult));

        return deferredResult.promise();
    }

    static DeleteReservation(model: DeleteReservationRequestModel): P.Promise<any> {
        const deferredResult = P.defer();

        Request.Send({
            proxy: {
                url: "/api/ControlsApi/DeleteReservation",
                type: "POST",
                dataType: "json"
            },
            params: model
        }).then(response => this.ResolveResponse(response, deferredResult))
            .fail(() => this.RejectResponse("Error updating reservation", deferredResult));

        return deferredResult.promise();
    }

    static GetSubject(entityId: number, recordId: number) {
        const deferredResult = P.defer();

        Request.Send({
            proxy: {
                url: "/api/RecordsApi/Get",
                type: "GET",
                dataType: "json"
            },
            params: {
                TableId: entityId,
                RecordId: recordId
            }
        })
            .then(response => deferredResult.resolve(response))
            .fail(() => this.RejectResponse("Error updating reservation", deferredResult));

        return deferredResult.promise();
    }

    static ValidateTimeWritingSubject(tableId: number, recordId: number) {
        const deferredResult = P.defer<any>();

        Request.Send({
            proxy: {
                url: "/api/ControlsApi/ValidateTimeWritingSubject",
                type: "GET",
                dataType: "json"
            },
            params: {
                tableId: tableId,
                recordId: recordId
            }
        })
            .then(response => this.ResolveObjectResponse(response, deferredResult))
            .fail(() => this.RejectResponse("Error validating time writing subject", deferredResult));

        return deferredResult.promise();
    }

    private static ResolveResponse(response, deferredResult: P.Deferred<any>) {
        if (response.IsSuccessfull) {
            deferredResult.resolve(null);
        } else {
            deferredResult.reject({message: response.ErrorMessage});
        }
    }

    private static ResolveObjectResponse<T>(response, deferredResult: P.Deferred<T>) {
        if (response.IsSuccessfull) {
            deferredResult.resolve(response.ResultObject);
        } else {
            deferredResult.reject({message: response.ErrorMessage});
        }
    }

    private static RejectResponse<T>(message: string, deferredResult: P.Deferred<T>) {
        deferredResult.reject({message: message});
    }
}