import {FIELD_TYPES} from 'Core/Constant';

export class SpreadsheetFieldDescriptor {
    constructor(public Id: number,
                public Name: string,
                public TranslatedName: string,
                public TypeId: number,
                public Type: string,
                public ValTableName: string,
                public ValFieldName: string) {
    }

    get IsLookup() {
        return this.Type === FIELD_TYPES.Lookup;
    }
}