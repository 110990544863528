import {deserialize, deserializeAs} from 'libs/cerialize';
import {FieldMetadataModel} from 'Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel';
import {EntityMetadataModel} from 'Core/Controls/Grid/Models/GridDataModel/EntityMetadataModel';
import {ReferenceModel} from "Core/ScreenManager/Models/ReferenceModel";
import {IconModel} from "../../../BaseControl/Models/IconModel";
import {FeatureTypes} from "../../../../Common/Enums/FeatureTypes";

export class RecordKey {
    @deserialize
    EntityId: number;

    @deserialize
    FieldId: number;

    @deserialize
    RecordId: number;

    @deserialize
    QueryEntityGuid: string;
}

export interface IRecordModel {
    EntityId: number;
    RecordId: number;
}

export class TranslationModel {
    @deserialize
    LanguageId: number;

    @deserialize
    Value: string;
}

export class CurrencyModel {
    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    IsoCode: string;

    @deserializeAs(IconModel)
    Icon: IconModel;
}

export class CurrencyValueModel {
    @deserializeAs(CurrencyModel)
    Currency: CurrencyModel;

    @deserialize
    RateDate: string;

    @deserialize
    Value: number;
}

export class ConvertedCurrencyModel {

    @deserializeAs(CurrencyValueModel)
    Origin: CurrencyValueModel;

    @deserializeAs(CurrencyValueModel)
    Converted: CurrencyValueModel;
}

export class DocumentValueModel {
    @deserialize
    IsEnableSwitchVersion: boolean;
}

export class SpimValueModel {
    @deserialize
    RecordId: number;

    @deserialize
    FeatureType: string = null;

    @deserialize
    AlphaNumericValue: number = null;

    @deserialize
    AlphaNumericDisplayValue: string = null;

    @deserialize
    NumericValue: number = null;

    @deserialize
    LogicalValue: boolean = null;

    @deserialize
    RangeLow: number = null;

    @deserialize
    RangeHigh: number = null;

    get IsNumeric() {
        return this.FeatureType === FeatureTypes.Numeric;
    }

    get IsAlphaNumeric() {
        return this.FeatureType === FeatureTypes.AlphaNumeric;
    }

    get IsLogic() {
        return this.FeatureType === FeatureTypes.Logic;
    }

    get IsRange() {
        return this.FeatureType === FeatureTypes.Range;
    }

    get FeatureTypeIsSet() {
        return !!this.FeatureType;
    }
}

export class GridCellValueModel {

    constructor() {
        this.Details = [];
        this.CrossWrapped = [];
        this.RecordKeys = [];
    }

    @deserialize
    HasDescription: boolean;

    @deserializeAs(ConvertedCurrencyModel)
    Currency: ConvertedCurrencyModel;

    @deserializeAs(SpimValueModel)
    Spim: SpimValueModel;

    @deserializeAs(DocumentValueModel)
    Document: DocumentValueModel;

    @deserialize
    Value: string;

    @deserialize
    Values: any;

    @deserialize
    DisplayValue: string;

    @deserializeAs('DisplayValue')
    OriginalDisplayValue: string;

    TranslatedValue: string;

    @deserializeAs(RecordKey)
    RecordKeys: Array<RecordKey>;

    @deserializeAs(GridCellValueModel)
    Details: Array<GridCellValueModel>;

    @deserializeAs(GridCellValueModel)
    CrossWrapped: Array<GridCellValueModel>;

    @deserializeAs(ReferenceModel)
    Reference: ReferenceModel;

    @deserialize
    IsHyperLink: boolean;

    @deserialize
    RecordTypeId: number;

    @deserialize
    IsWrapped: boolean;

    @deserialize
    WrapGroup: boolean;

    @deserialize
    IsSortDescription: boolean;

    @deserialize
    IsGroup: boolean;

    @deserialize
    IsEnableEdit: boolean;

    @deserialize
    QueryColumnGuid: string;

    @deserialize
    DisplayPriority: number;

    @deserialize
    IsEnableMail: boolean;

    @deserialize
    IsCrossValue: boolean;

    @deserialize
    IsCrossRow: boolean;

    MaxRowHeight: string;

    FieldMetadata: FieldMetadataModel;

    EntityMetadata: EntityMetadataModel;

    @deserialize
    Type: string;

    @deserialize
    Flags: number;

    @deserializeAs(TranslationModel)
    Translations: TranslationModel[];

    @deserializeAs(IconModel)
    Icon: IconModel;

    width?: any;

    GetRecordIds(): IRecordModel {
        if (this.RecordKeys && this.RecordKeys[0]) {
            return {
                EntityId: this.RecordKeys[0].EntityId,
                RecordId: this.RecordKeys[0].RecordId
            };
        }
        return null;
    }

    @deserialize
    RelationType: RelationTypeModel;

    @deserialize
    HyperlinkRecordId: number;

    SortDirection: number;
}

export interface RelationTypeModel {
    Id: number;
    Name: string;
    HasNext: boolean;
}