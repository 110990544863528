
export class Counter {
	private static inst: Counter;
	private _counter: number;

	constructor() {
		this._counter = 0;
	}

	static get Instance() {
		if (this.inst === null || this.inst === undefined) {
			this.inst = new Counter();
		}
		return this.inst;
	}

	get NextValue(): number {
		return ++ this._counter  ;
	}

	get PrevValue(): number {
		return -- this._counter ;
	}

	get ResetValue(): number {
		return this._counter = 0 ;
	}
}