import {deserialize, serialize} from 'libs/cerialize';

export class MainThemeDescriptor {
    @serialize
    @deserialize
    Color: string;

    @serialize
    @deserialize
    Theme: string;

    @serialize
    @deserialize
    SidebarBorders: string;

    @serialize
    @deserialize
    SidebarStyleOption: string;

    @serialize
    @deserialize
    Style: string;

    @serialize
    @deserialize
    SidebarPosOption: string;

    @serialize
    @deserialize
    SidebarOption: string;

    @serialize
    @deserialize
    CustomThemeName: string;
}