import { Event } from 'Core/Common/Event';

import {LABELS} from "Core/Components/Translation/Locales";

import { IReadyForInvoicingParams } from './Params/IReadyForInvoicingParams';

import { IReadyForInvoicingEventData } from './Events/IReadyForInvoicingEventData';

import { SourceTableViewModel } from 'Core/Components/Dashboards/FinancialDashboard/Models/View/SourceTableViewModel';
import { InvoicingControlViewModel } from 'Core/Components/Dashboards/FinancialDashboard/Models/View/InvoicingControlViewModel';

import { FinancialDashboardMappingProfile } from 'Core/Components/Dashboards/FinancialDashboard/Mappings/FinancialDashboardMappings';

import Template from 'Core/Components/Dashboards/FinancialDashboard/Components/ReadyForInvoicing/Templates/Template.html';
import NoControlsTemplate from 'Core/Components/Dashboards/FinancialDashboard/Components/ReadyForInvoicing/Templates/NoControlsTemplate.html';

export class ReadyForInvoicing extends Event {
	private _labels = LABELS;

	SourceTables: SourceTableViewModel[];

	constructor(params: IReadyForInvoicingParams) {
		super();

		this.Init(params)
	}

	private Init(params: IReadyForInvoicingParams) {
		this.SourceTables = params.SourceTables.map(sourceTable => FinancialDashboardMappingProfile.MapToSourceTableViewModel(sourceTable));
	}

	OnInvoiceBtnClick(control: InvoicingControlViewModel) {
		const eventData: IReadyForInvoicingEventData = { ControlId: control.Id, TableId: control.TableId };
		this.Trigger('INVOICING_REQUESTED', eventData);
	}

	GetTemplate() {
		if (this.SourceTables.length === 0) {
			return NoControlsTemplate;
		}

		return Template;
	}
}