import * as ko from "knockout";
import "jquery";
import "xEditable";
import "TouchSpinDecimalExtension";
import "PercentageExtantion";

import { IValueUpdater } from "LookupEditor/Interfaces/IValueUpdater";
import { ColumnEditor } from "LookupEditor/ColumnEditors/Base/ColumnEditor";

import ColumnTemplate from "LookupEditor/Templates/Columns/Decimal.html";
import {TranslationFieldEditor} from "../../../Core/Components/TranslationFieldEditor/TranslationFieldEditor";
import {EditableColumnModel} from "../../Models/EditableColumnModel";
import {FormatConverter} from "FormatEditor/FormatConverter";
import {TranslationManager} from "../../../Core/Components/Translation/TranslationManager";
import {ITranslationValue} from "../../../Core/Components/TranslationFieldEditor/ITranslationValue";

ko.templates["LookupEditor/Templates/Columns/Decimal"] = ColumnTemplate;

export class DecimalColumnEditor extends ColumnEditor {
	private _currentOwner: KnockoutObservable<IValueUpdater>;
	private _editModeEnabled: KnockoutComputed<boolean>;
	private _value: KnockoutObservable<string>;
	private _translationFieldEditor: TranslationFieldEditor;

	get EditModeEnabled() {
		return this._editModeEnabled;
	}

	get CurrentOwner() {
		return this._currentOwner;
	}

	get Value() {
		return this._value;
	}

	constructor(column: EditableColumnModel) {
		super(column);

		this._currentOwner = ko.observable(null);
		this._editModeEnabled = ko.computed(() => this._currentOwner() !== null, this);
		this._value = ko.observable(null);

		this._value.subscribe(newValue => this._translationFieldEditor.SetValue(newValue));
	}

	GetTemplateName(): string {
		return "LookupEditor/Templates/Columns/Decimal";
	}

	AfterRender(el, owner: IValueUpdater, columnName): void {
	}

	Click(owner: IValueUpdater) {
		const columnData = owner.GetColumnData(this.column.Name);
		const value = owner.GetDbValue(this.column.Name);

		const translation = TranslationManager.Instance.GetTranslation(value, columnData.FieldValueTranslations);

		this._translationFieldEditor = new TranslationFieldEditor();
		this._translationFieldEditor.LoadTranslationItems();
		this._translationFieldEditor.On('TranslationSelected', this, eventArgs => this.ChangeTranslation(eventArgs.data));

		this._translationFieldEditor.SetTranslations(columnData.FieldValueTranslations, value);
		this._translationFieldEditor.SetActiveTranslation(translation.Language.Id);

		if (columnData.FieldTypeFormat === 'Percentage') {
			this._value(FormatConverter.LocalizeDecimalOrInteger(String((parseFloat(value.replace(',', '.')) * 100).toFixed(columnData.FieldSize < 2 ? 0 : columnData.FieldSize - 2))));
		}else {
			this._value(translation.TranslatedValue || translation.Value);
		}

		this._currentOwner(owner);

	}

	SaveChanges() {
		let value = null;
		let columnInfo = this._currentOwner().GetColumnData(this.column.Name);
		if (columnInfo.FieldTypeFormat === 'Percentage') {
			value = FormatConverter.LocalizeDecimalOrInteger(String((parseFloat(this._translationFieldEditor.GetDefaultTranslation().Value.replace(',', '.'))/100).toFixed(columnInfo.FieldSize < 2 ? 0 : columnInfo.FieldSize)));
		}else {
			value = this._translationFieldEditor.GetDefaultTranslation().Value;
		}
		this._currentOwner().UpdateValue({
			Name: this.column.Name,
			Value: value
		});

		this._currentOwner(null);
	}

	CancelChanges() {
		this._currentOwner(null);
	}

	Show(): void {
	}

	private ChangeTranslation(translation: ITranslationValue) {
		this._value(translation.Value);
	}

	ToString() {
		return "Decimal";
	}
}