export enum SearchGroups {
	All,			
	Active,			
	Archive,		
	Example,		

	Planned,
	Acquired,
	Maintained,
	Operational,
	Retired
}

export const LifeStatusGroupIndexes = [0, 1, 2, 3];

export const SimpleFilterGroupsSet = [0, 1, 2, 3];

export const MappedSearchGroups = {
	[SearchGroups[0]]: 0,
	[SearchGroups[1]]: 1,
	[SearchGroups[2]]: 6,
	[SearchGroups[3]]: 8,

	[SearchGroups[4]]: 2,
	[SearchGroups[5]]: 3,
	[SearchGroups[6]]: 4,
	[SearchGroups[7]]: 5,
	[SearchGroups[8]]: 7,
	[SearchGroups[9]]: 1
};