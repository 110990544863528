import * as ko from 'knockout';

import { Notifier } from "Core/Common/Notifier";

import { BaseProperty, IPropertyDescription } from "Core/GeneralProperties/Managers/BaseProperty";
import { IControl } from "Core/Controls/IControl";
import { QueryBuilder } from "QueryBuilder/QueryBuilder";
import { EVENTS as QUERY_BUILDER_EVENTS } from "QueryBuilder/Events";
import { ViewModes } from 'Core/Controls/Grid/BaseGrid/Enums/ViewModes';

import Template from "Core/GeneralProperties/Managers/QueryBuilderProperty/Template.html";

ko.templates['Core/GeneralProperties/Managers/QueryBuilderProperty/Templates/Template'] = Template;

export class QueryBuilderProperty extends BaseProperty {
	private _control: IControl;
	private _queryBuilder: QueryBuilder;

	constructor(property: IPropertyDescription, propertyValue: string, control: IControl) {
		super(property);
		this.Value = ko.observable(propertyValue || this.GetDefaultValue());

		this._control = control;
		this._queryBuilder = this.InitQueryBuilder();
	}

	GetDefaultValue() {
		return null;
	}

	GetTemplateName() {
		return 'Core/GeneralProperties/Managers/QueryBuilderProperty/Templates/Template';
	}

	AfterRender() {
		this.LoadQueryExpression();
	}

	private InitQueryBuilder() {
		const queryBuilder = new QueryBuilder(true, false, false);
		queryBuilder.QueryType = ViewModes.Query;

		queryBuilder.On(QUERY_BUILDER_EVENTS.DATA_SAVED, this, eventArgs => {
			this.Value(eventArgs.data.QueryText);
			new Notifier().Success('Query saved');
		});
		queryBuilder.On(QUERY_BUILDER_EVENTS.CLOSE, this, () => this.LoadQueryExpression());

		return queryBuilder;
	}

	private LoadQueryExpression() {
		if (this.Value() === this.GetDefaultValue()) {
			this.InitDefaultQuery();
		} else {
			const expressionModel = JSON.parse(this.Value());
			this._queryBuilder.EditFreeQuery(expressionModel, false);
		}
	}

	private InitDefaultQuery() {
		const screen = this._control.GetForm().GetScreen();

		if (screen.IsSubjectScreen()) {
			const subjectEntityId = screen.GetEntityId();
			this._queryBuilder.InitQueryByEntityId(subjectEntityId);

			return;
		}

		this._queryBuilder.NewFreeQuery();
	}
}