import { Request, IRequestOptions } from 'Core/Common/Request';
import { P } from 'Core/Common/Promise';
import { URL } from 'Core/Common/Url';
import { IConditionValueDto } from 'Core/Common/Interfaces/IConditionValueDto';

export interface GetHideIfConditionValueDto {
	ControlId: number;
	SubjectEntityId: number;
	SubjectRecordId: number;
	ConditionValues: Array<IConditionValueDto>
}

export class BaseControlStore {
	static GetHideIfConditionValue(params: GetHideIfConditionValueDto): P.Promise<boolean> {
		let deferredResult = P.defer<boolean>();

		Request
			.For(URL.CONTROLS.GET_HIDE_IF_CONDITION_VALUE)
			.Post(params)
			.then(data => deferredResult.resolve(data));

		return deferredResult.promise();
	}
}