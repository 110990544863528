import {JournalsState} from "Core/Components/Dashboards/FinancialDashboard/Enums/JournalsState";
import {AccountsState} from "Core/Components/Dashboards/FinancialDashboard/Enums/AccountsState";

import {AccountsViewModel} from "./AccountsViewModel";
import {SubAdministrationViewModel} from "./SubAdministrationViewModel";

export class LedgerViewModel {
	constructor(
		public Accounts: AccountsViewModel,
        public SubAdministrations: SubAdministrationViewModel[],
        public JournalsState: JournalsState,
        public AccountsState: AccountsState) {
    }

    get AccountsBtnEnabled() {
        return this.JournalsState=== JournalsState.Ok && this.AccountsState === AccountsState.Ok;
    }

    get AccountDisabledReason() {
        switch (this.JournalsState) {
            case JournalsState.Deleted:
                return 'JOURNALS table is deleted';

            case JournalsState.Disabled:
                return 'JOURNALS table is disabled';

            case JournalsState.NotExist:
                return 'JOURNALS table does not exist';
        }

        switch (this.AccountsState) {
            case AccountsState.Deleted:
                return 'ACCOUNTS table is deleted';

            case AccountsState.Disabled:
                return 'ACCOUNTS table is disabled';

            case AccountsState.NotExist:
                return 'ACCOUNTS table does not exist';

            case AccountsState.NotLinkedToJournals:
                return 'ACCOUNTS not linked to JOURNALS';
        }

        return 'Will be implemented soon';
    }
}