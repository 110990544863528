import * as ko from 'knockout';
import 'jquery';
import 'fullcalendar';
import 'fullcalendar-scheduler';
import 'libs/FullCalendar/locale-all';

export class FullCalendarExtention {

    static Init() {
        ko.bindingHandlers.fullCalendar = {
            init: (element, valueAccessor, allBindings, viewModel, bindingContext) => {
                let ctrlIsPressed = false;
                const $element = $(element);
                const options = valueAccessor();

                function setEventsCopyable(isCopyable) {
                    ctrlIsPressed = !ctrlIsPressed;
                    $(".fc-appointment").draggable().draggable("option", "disabled", !isCopyable);
                }

                if (options.options) {
                    const eventAfterAllRender = function (view) {
                        $(".fc-appointment").each(function() {
                            const $event = $(this);
                            const event = $event.data("fcSeg").footprint.eventDef;
                            $event.data("eventObj", event);
                            $event.draggable({
                                disabled: true,
                                helper: "clone",
                                revert: true,
                                revertDuration: 0,
                                zIndex: 9999,
                                start: (event, ui) => {
                                    $(ui.helper).css({ height: $(this).height(), width: $(this).width(), opacity: 0.7});
                                },
                                stop(event, ui) {
                                    if (ctrlIsPressed) {
                                        setEventsCopyable(true);
                                    }
                                }
                            });
                        });
                    };

                    $(document).on('keydown.fullCalendar', function(e) {
                        if (e.ctrlKey && !ctrlIsPressed) {
                            setEventsCopyable(true);
                        }
                    });

                    $(document).on('keyup.fullCalendar',function(e) {
                        if (ctrlIsPressed) {
                            setEventsCopyable(false);
                        }
                    });

                    if (options.options.eventAfterAllRender) {
                        options.options.eventAfterAllRender = function (view) {
                            options.options.eventAfterAllRender(view);
                            eventAfterAllRender(view);
                        }
                    } else {
                        options.options.eventAfterAllRender = eventAfterAllRender;
                    }

                    $element.fullCalendar(options.options);
                    $element.on("remove", () => {
                        $(document).off('keydown.fullCalendar');
                        $(document).off('keyup.fullCalendar');
                    })

                    if (ko.isObservable(options.groupByResource)) {
                        options.groupByResource.subscribe(newValue => {
                            $(element).fullCalendar('option', 'groupByResource', newValue);
                            $(element).fullCalendar('rerenderEvents');
                            $(element).fullCalendar('getCalendar') && $(element).fullCalendar('getCalendar').updateViewSize(true);
                            // ($(element) as any).fullCalendar('updateSize');
                        });
                    }

                    if (ko.isObservable(options.resources)) {
                        options.resources.subscribe(newResources => {
                            $(element).fullCalendar('getCalendar') && $(element).fullCalendar('getCalendar').updateViewSize(true);
                            // $(element).fullCalendar('refetchResources');
                            // $(element).fullCalendar('option', 'resources', newResources);
                            // $(element).fullCalendar('rerenderEvents');
                        });
                    }
                }
            },

            update: (element, valueAccessor) => {
                const $element = $(element);
                const options = valueAccessor();

                const resources = options.resources && options.resources() || [];
                const specialDays = options.specialDays && options.specialDays() || [];
                const freeDays = resources.filter(resource => resource.freeDay).map(resource => {
                    return {
                        resourceId: resource.id,
                        rendering: 'background',
                        backgroundColor: 'red',
                        start: resource.start,
                        end: resource.end
                    }
                });

                const events = (options.events && options.events() || []).concat(specialDays).concat(freeDays);

                $element.fullCalendar('removeEvents', null);
                $element.fullCalendar('addEventSource', events);
                $element.fullCalendar('refetchResources');
                $element.fullCalendar('option', 'businessHours', options.businessHours());
                $element.fullCalendar('addEventSource', options.specialDays());

            }
        }
    }
}