import {deserialize, deserializeAs, serialize, serializeAs} from "libs/cerialize";

export class SelectedRecordModel {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	Translation: string;

	@deserialize
	Memo: string;

	@deserialize
	MemoTranslation: string;

	@deserialize
	TypeId: number;

	@deserialize
	TypeName: string;
}