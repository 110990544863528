import * as _ from 'underscore';

import { Request, IRequestOptions } from 'Core/Common/Request';
import { P } from 'Core/Common/Promise';
import { Deserialize } from 'libs/cerialize';
import { EditTemplateModel } from 'Core/Controls/TemplateDesigner/Models/EditTemplateModel';
import { TemplateLanguageModel } from 'Core/Controls/TemplateDesigner/Models/TemplateLanguageModel';

export interface IGetLanguagesDto {
	SubjectEntityId: number;
	SubjectRecordId: number;
	ControlId: number;
};

export interface ICreateTemplateDto {
	SubjectEntityId: number;
	SubjectRecordId: number;
	LanguageId: number;
	ControlId: number;
};

export interface IEditTemplateDto {
	SubjectEntityId: number;
	SubjectRecordId: number;
	LanguageId: number;
	ControlId: number;
};

export interface ISaveTemplateDto {
	FileName: string;
	Content: string;
	QueryId: number;
	ControlId: number;
	RecordId: number;
	KSeq: number;
};

export interface IPreviewHtmlTemplateDto {
	Data: string;
	RecordId: number;
	QueryId: number;
};

export class TemplateDesignerStore {

	static GetLanguages(params: IGetLanguagesDto): P.Promise<Array<TemplateLanguageModel>> {
		var deferredResult = P.defer<Array<TemplateLanguageModel>>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/DocumentTemplatesApi/GetLanguages',
				dataType: 'json',
				type: 'GET'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
					deferredResult.resolve(Deserialize(data, TemplateLanguageModel));
			}).fail(data => { deferredResult.reject({ message: JSON.parse(data.message).Message }) });

		return deferredResult.promise();
	}

	static Edit(params: IEditTemplateDto): P.Promise<EditTemplateModel> {
		var deferredResult = P.defer<EditTemplateModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/DocumentTemplatesApi/Edit',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(Deserialize(data, EditTemplateModel));
			}).fail(data => { deferredResult.reject({ message: JSON.parse(data.message).Message }) });

		return deferredResult.promise();
	}

	static Create(params: ICreateTemplateDto): P.Promise<EditTemplateModel> {
		var deferredResult = P.defer<EditTemplateModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/DocumentTemplatesApi/Create',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
		.then((data) => deferredResult.resolve(Deserialize(data, EditTemplateModel)))
		.fail(err => { deferredResult.reject({ message: JSON.parse(err.message).Message }) });

		return deferredResult.promise();
	}

	static Save(params: ISaveTemplateDto): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/DocumentTemplatesApi/Save',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
		.then(() => deferredResult.resolve(null))
		.fail(err => { deferredResult.reject({ message: JSON.parse(err.message).Message }) });

		return deferredResult.promise();
	}

	static Preview(params: IPreviewHtmlTemplateDto): P.Promise<string> {
		var deferredResult = P.defer<string>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/DocumentTemplatesApi/PreviewHtmlTemplate',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
		.then((data) => {
			deferredResult.resolve(Deserialize(data, String))
		})
		.fail(err => { deferredResult.reject({ message: JSON.parse(err.message).Message }) });

		return deferredResult.promise();
	}
}