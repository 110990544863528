import {P} from "Core/Common/Promise";
import {Request} from 'Core/Common/Request';
import {NOTIFICATIONS} from 'Core/Components/Translation/Locales';

import {ProductResponse} from "./Models/ProductResponse";
import {ConfigurationDataResponse} from './Models/ConfigurationDataResponse';
import {SaveConfigurationDataDto} from 'Core/Components/Controls/ProductConfigurator/Pages/StartPage/Stores/Params/SaveConfigurationDataDto';
import {SaveAllConfigurationDataDto} from 'Core/Components/Controls/ProductConfigurator/Pages/StartPage/Stores/Params/SaveAllConfigurationDataDto';

import { Deserialize } from "libs/cerialize";

export class StartPageStore {
    constructor(private _orderEntityId: number, private _productEntityId: number, private _descriptionFieldId?: number) {
    }

    GetConfiguredProducts(dto: { OrderEntityId: number, ProductEntityId: number, OrderId: number }): P.Promise<ProductResponse[]> {
        const deferred = P.defer<ProductResponse[]>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetConfiguredProducts',
                type: 'GET',
                dataType: 'json',
            }, params: {
                OrderEntityId: dto.OrderEntityId,
                ProductEntityId: dto.ProductEntityId,
                DescriptionFieldId: this._descriptionFieldId,
                OrderId: dto.OrderId
            }
        })
            .then(products => deferred.resolve(Deserialize(products, ProductResponse)))
            .fail(err => deferred.reject(this.GetResponseError(err, NOTIFICATIONS.ERROR_GETTING_CONFIGURED_PRODUCTS)));

        return deferred.promise();
    }

    GetConfigurationData(configurationId: number): P.Promise<ConfigurationDataResponse> {
        const deferred = P.defer<ConfigurationDataResponse>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetConfigurationData',
                type: 'GET'
            },
            params: {
                OrderEntityId: this._orderEntityId,
                ProductEntityId: this._productEntityId,
                ConfigurationId: configurationId
            }
        })
            .then(hookData => deferred.resolve(hookData))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error getting configuration data')));

        return deferred.promise();
    }

    GetOriginalConfigurationImage(imageId: number, controlId: number) {
        const deferred = P.defer<any>();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/GetOriginalConfigurationImage',
                type: 'POST',
            }, params: {
                OrderEntityId: this._orderEntityId,
                ProductEntityId: this._productEntityId,
                ImageId: imageId,
                ControlId: controlId
            }
        })
            .then(image => deferred.resolve(image))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error getting original image property')));

        return deferred.promise();
    }

    CopyConfiguredProduct(configurationId: number) {
        const deferred = P.defer();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/CopyConfiguredProduct',
                type: 'POST'
            }, params: {
                OrderEntityId: this._orderEntityId,
                ProductEntityId: this._productEntityId,
                ConfigurationId: configurationId
            }
        })
            .then(() => deferred.resolve(null))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error copying product')));

        return deferred.promise();
    }

    RemoveConfiguredProduct(configurationId: number) {
        const deferred = P.defer();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/RemoveConfiguredProduct',
                type: 'POST'
            }, params: {
                OrderEntityId: this._orderEntityId,
                ProductEntityId: this._productEntityId,
                ConfigurationId: configurationId
            }
        })
            .then(() => deferred.resolve(null))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error removing product')));

        return deferred.promise();
    }

    RemoveConfiguredProductByLevel(configurationId: number, level: string) {
        const deferred = P.defer();

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/RemoveConfiguredProductByLevel',
                type: 'POST'
            }, params: {
                OrderEntityId: this._orderEntityId,
                ProductEntityId: this._productEntityId,
                ConfigurationId: configurationId,
                Level: level
            }
        })
            .then(() => deferred.resolve(null))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error removing product')));

        return deferred.promise();
    }

    SaveConfigurationData(dto: SaveConfigurationDataDto) {
        const deferred = P.defer();

        dto.OrderEntityId = this._orderEntityId;
        dto.ProductEntityId = this._productEntityId;

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/SaveConfigurationData',
                type: 'POST'
            },
            params: dto
        })
            .then(() => deferred.resolve(null))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error saving configuration data')));

        return deferred.promise();
    }

    SaveAllConfigurationData(dto: SaveAllConfigurationDataDto) {
        const deferred = P.defer();

        _.each(dto.SaveConfigurationDataDtos, saveConfigurationDataDto => {
            saveConfigurationDataDto.OrderEntityId = this._orderEntityId;
            saveConfigurationDataDto.ProductEntityId = this._productEntityId;
        });

        Request.Send({
            proxy: {
                url: '/api/ProductConfiguratorApi/SaveAllConfigurationData',
                type: 'POST'
            },
            params: dto
        })
            .then(() => deferred.resolve(null))
            .fail(err => deferred.reject(this.GetResponseError(err, 'Error saving all configuration data')));

        return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}