import * as ko from 'knockout';
import 'pubsub';
import 'bootstrap';
import 'signalR';

import {P} from 'Core/Common/Promise';
import {BlockUI} from 'Core/Common/BlockUi';
import {IControlParam} from 'Core/Screens/IScreen';
import {BaseControl} from 'Core/Controls/BaseControl/BaseControl';
import {SearchScreen} from 'Core/Screens/SearchScreen/SearchScreen';
import {TypeScreen} from 'Core/Screens/TypeScreen/TypeScreen';

import {Notifier} from 'Core/Common/Notifier';
import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS,
    Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';

import {ScreenManager} from 'Core/ScreenManager/ScreenManager';
import {EditScreen} from 'Core/Screens/EditScreen/EditScreen';
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';
import {FunctionDesignerStore} from 'Core/Controls/FunctionDesigner/Stores/FunctionDesignerStore';
import {FunctionDesignerPackageModel} from 'Core/Controls/FunctionDesigner/Models/FunctionDesignerPackageModel';
import {FunctionDesignerMethodModel} from 'Core/Controls/FunctionDesigner/Models/FunctionDesignerMethodModel';
import {LockManager} from 'Core/Components/Locker/LockManager';
import {FunctionDesignerMethodParamModel} from 'Core/Controls/FunctionDesigner/Models/FunctionDesignerMethodParamModel';
import {FunctionResult} from 'Core/Controls/FunctionDesigner/FunctionResult';

import {NOTIFICATIONS, CONFIRMATIONS} from "Core/Components/Translation/Locales";
import {EVENTS as SEARCH_SCREEN_EVENTS} from "Core/Screens/SearchScreen/Events";
import {EVENTS as TYPE_SCREEN_EVENTS} from "Core/Screens/TypeScreen/Events";
import {EVENTS as EDIT_SCREEN_EVENTS} from "Core/Screens/EditScreen/Events";

import {CodeEditor} from 'Core/Controls/FunctionDesigner/CodeEditor';
import {FUNCTION_TYPES} from 'Core/Controls/FunctionDesigner/FunctionTypes';

import ViewTemplate from 'Core/Controls/FunctionDesigner/Templates/View.html';
import {EntityTypesStore} from "../../Screens/TypeScreen/Stores/EntityTypesStore";
import { FunctionBuilder } from 'Core/Components/CustomFunctions/FunctionBuilder';
import { PUB_SUB_EVENTS } from 'MenuManager/PubSubEvents';

ko.templates['Core/Controls/FunctionDesigner/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/FunctionDesigner/Templates/Edit'] = ViewTemplate;

export class FunctionDesigner extends BaseControl {
    private _metadata: any;
    private _recordId: number;
    private _hasData: KnockoutObservable<boolean>;
    private _searchTerm: KnockoutObservable<string>;
    private _package: KnockoutObservable<FunctionDesignerPackageModel>;
    private _currentMethod: KnockoutObservable<FunctionDesignerMethodModel>;
    private _rawResult: KnockoutObservable<boolean>;
    private _showRawResultCheckbox: KnockoutComputed<boolean>;
    private _showButtonContainer: KnockoutObservable<boolean>;
    _functionSelected: KnockoutObservable<boolean>;

    constructor(params: IControlParam) {
        super(params);
        this._searchTerm = ko.observable('');
        this._hasData = ko.observable(false);
        this.AddEvent('FUNCTION_CREATED');
        this.Init();
        this._package = ko.observable(null);
        this._currentMethod = ko.observable(null);
        this._rawResult = ko.observable(false);
        this._showRawResultCheckbox = ko.computed(() => {
            return this._currentMethod() && this._currentMethod().TypeName === FUNCTION_TYPES.API_METHOD;
        });
        this._showButtonContainer = ko.observable(true);
        this._functionSelected = ko.observable(false);

        PubSub.subscribe(PUB_SUB_EVENTS.EXECUTE_SCRIPT, (_, data) => {
            if(data && data.ObjectId === this.GetGuid()){
                FunctionBuilder.Execute(data.Code, data.Params);
            }
        });
    }

    ApplyProperties(){}

    get Package(): KnockoutObservable<FunctionDesignerPackageModel> {
        return this._package;
    }

    get CurrentMethod(): KnockoutObservable<FunctionDesignerMethodModel> {
        return this._currentMethod;
    }

    get ShowButtonContainer(): KnockoutObservable<boolean> {
        return this._showButtonContainer;
    }

    HideButtonContainer() {
        this._showButtonContainer = ko.observable(false);
    }

    private Init(): void {
        BlockUI.Block();
        FunctionDesignerStore.GetFunctionDesignerMetaData()
            .always(() => {
                BlockUI.Unblock();
            })
            .then((result) => {
                if (!result.IsSuccessfull) {
                    new Notifier(this._el).Failed(result.ErrorMessage);
                    return;
                }
                this._metadata = result.ResultObject;
            });
    }

    ShowCodeEditor() {
        var codeEditor = new CodeEditor(
            this.CurrentMethod(),
            this._package().Id
        );
        codeEditor.Show();
    }


    SelectMethod(method: FunctionDesignerMethodModel) {
        this._currentMethod(method);
    }

    SelectMethodById(functionId: number){
        let func = _.find(this._package().Methods, (method) => method.Id === functionId);
        this.SelectMethod(func);
    }

    GetMethod(methodId: number): P.Promise<FunctionDesignerMethodModel> {
        const deferredResult = P.defer<FunctionDesignerMethodModel>();
        BlockUI.Block();
        FunctionDesignerStore.GetMethod({MethodId: methodId})
            .always(() => {
                BlockUI.Unblock();
            })
            .then((method) => {
                this._currentMethod(method);
                let existingMethod = _.find(this._package().Methods, (item) => {
                    return item.Id === method.Id;
                });

                if (existingMethod) {
                    this._package().Methods[this._package().Methods.indexOf(existingMethod)] = method;
                }
                this._package.valueHasMutated();

                deferredResult.resolve(method);
            }).fail(err => new Notifier().Failed(err.message));

        return deferredResult.promise();
    }

    GetPackage(packageId: number): P.Promise<any> {
        let deferredResult = P.defer<any>();
        BlockUI.Block();
        FunctionDesignerStore.GetPackage({PackageId: packageId})
            .always(() => {
                BlockUI.Unblock();
            })
            .then((functionPackage) => {
                this._package(functionPackage);
                this.CurrentMethod(null);
                this._hasData(true);
                deferredResult.resolve(null);
            }).fail(err => new Notifier().Failed(err.message));
            return deferredResult.promise();
    }

    DeletePackage() {
        const confirmationText = CONFIRMATIONS.FUNCTION_PACKAGE_DELETION;

        const confirmationDialog = new ConfirmationDialog({
            Text: confirmationText,
            Type: ConfirmationTypes.Question
        });

        confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED,
            this,
            () => {

                BlockUI.Block();
                FunctionDesignerStore.DeletePackage({PackageId: this._package().Id})
                    .always(() => {
                        BlockUI.Unblock();
                    })
                    .then((functionPackage) => {
                        this._package(null);
                        this.CurrentMethod(null);
                        this._hasData(false);

                    }).fail(err => new Notifier().Failed(err.message));
            });

        confirmationDialog.Show();

    }


    EditMethod(method: FunctionDesignerMethodModel) {
        BlockUI.Block();

        LockManager.Instance.TryLock(this._metadata.EntityId, method.Id)
            .then(() => {
                ScreenManager.GetEditScreen({
                    EntityId: this._metadata.EntityId,
                    TableTypeId: method.TypeId,
                    RecordId: method.Id
                }).always(() => {
                    BlockUI.Unblock();
                }).then((screen: EditScreen) => {
                    screen.On(EDIT_SCREEN_EVENTS.RECORD_SAVED, this, (eventArgs) => {
                        const notifier = new Notifier($(this._el));
                        notifier.Success(NOTIFICATIONS.RECORD_SAVED);
                        UserVarsManager.Instance.AddRecent(this._metadata.EntityId, eventArgs.data.RecordId, method.TypeId);
                        LockManager.Instance.ReleaseLock(this._metadata.EntityId, method.Id);
                        this.GetMethod(method.Id);
                    });

                    screen.On('MODAL_CLOSE', this, () => LockManager.Instance.ReleaseLock(this._metadata.EntityId, method.Id));

                    screen.On(EDIT_SCREEN_EVENTS.RECORD_DELETED, this, (eventArgs) => {
                        this._currentMethod(null);
                        this.GetPackage(this._package().Id);
                    });

                    screen.ShowInModal();
                }).fail(error => {
                    new Notifier($(this._el)).Warning(error.message);
                });
            });
    }

    EditPackage(packag: FunctionDesignerPackageModel) {
        BlockUI.Block();
        LockManager.Instance.TryLock(this._metadata.EntityId, packag.Id)
            .then(() => {
                ScreenManager.GetEditScreen({
                    EntityId: this._metadata.EntityId,
                    TableTypeId: packag.TypeId,
                    RecordId: packag.Id
                }).always(() => {
                    BlockUI.Unblock();
                }).then((screen: EditScreen) => {

                    screen.On(EDIT_SCREEN_EVENTS.RECORD_SAVED, this, (eventArgs) => {
                        const notifier = new Notifier($(this._el));
                        notifier.Success(NOTIFICATIONS.RECORD_SAVED);
                        UserVarsManager.Instance.AddRecent(this._metadata.EntityId, eventArgs.data.RecordId, packag.TypeId);
                        LockManager.Instance.ReleaseLock(this._metadata.EntityId, packag.Id);
                        this.GetPackage(packag.Id);
                    });

                    screen.On('MODAL_CLOSE', this, () => LockManager.Instance.ReleaseLock(this._metadata.EntityId, packag.Id));

                    screen.On(EDIT_SCREEN_EVENTS.RECORD_DELETED, this, (eventArgs) => {
                        this._package(null);
                        this._currentMethod(null);
                    });

                    screen.ShowInModal();
                }).fail(error => {
                    new Notifier($(this._el)).Warning(error.message);
                });
            });
    }

    EditParam(param: FunctionDesignerMethodParamModel, method: FunctionDesignerMethodModel) {
        BlockUI.Block();
        LockManager.Instance.TryLock(this._metadata.EntityId, param.Id)
            .then(() => {
                ScreenManager.GetEditScreen({
                    EntityId: this._metadata.EntityId,
                    TableTypeId: param.TypeId,
                    RecordId: param.Id
                }).always(() => {
                    BlockUI.Unblock();
                }).then((screen: EditScreen) => {
                    screen.On(EDIT_SCREEN_EVENTS.RECORD_SAVED, this, (eventArgs) => {
                        const notifier = new Notifier($(this._el));
                        notifier.Success(NOTIFICATIONS.RECORD_SAVED);
                        UserVarsManager.Instance.AddRecent(this._metadata.EntityId, eventArgs.data.RecordId, param.TypeId);
                        LockManager.Instance.ReleaseLock(this._metadata.EntityId, param.Id);
                        this.GetMethod(method.Id);
                    });

                    screen.On('MODAL_CLOSE', this, () => LockManager.Instance.ReleaseLock(this._metadata.EntityId, param.Id));

                    screen.On(EDIT_SCREEN_EVENTS.RECORD_DELETED, this, (eventArgs) => {
                        this.GetMethod(method.Id);
                    });
                    screen.ShowInModal();
                }).fail(error => {
                    new Notifier($(this._el)).Warning(error.message);
                });
            });
    }

    SelectParameter(item) {

    }

    SetValue(value: any): void {
    }

    Search(): void {
        EntityTypesStore.GetTypes({
            EntityId: this._metadata.EntityId,
            ParentTypeId: 0,
            WithRoot: false,
            OnlyEnabled: true
        })
            .then((result) => {
                let apiPackageType = _.find(result.TableTypes, (item) => item.Name === FUNCTION_TYPES.API_PACKAGE);
                if (apiPackageType) {
                    const searchScreen = new SearchScreen({
                        EntityId: this._metadata.EntityId,
                        SearchTerm: this._searchTerm(),
                        IsRootForFormDesigner: true,
                        SearchByTypes: [apiPackageType.Id]
                    });
                    searchScreen.On(SEARCH_SCREEN_EVENTS.RECORD_SELECTED, this, (eventArgs) => {
                        if (eventArgs.data.TypeName === FUNCTION_TYPES.API_PACKAGE) {
                            this._functionSelected(true)
                            this.GetPackage(eventArgs.data.RecordId);
                        } else {
                            new Notifier().Warning(`${eventArgs.data.TypeName} is not allowed`);
                        }
                    });

                    searchScreen.On(SEARCH_SCREEN_EVENTS.NEW_RECORD,
                        this,
                        (eventArgs) => {
                            var typeScreen = new TypeScreen(this._metadata.EntityId, 0, true);
                            typeScreen.On(TYPE_SCREEN_EVENTS.TYPES_NOT_FOUND, this, (eventArgs) => {
                                new Notifier().Warning(eventArgs.data.ErrorMessage);
                            });
                            typeScreen.On(TYPE_SCREEN_EVENTS.TYPE_SELECTED, this, (eventArgs) => {
                                if (eventArgs.data.TypeName === FUNCTION_TYPES.API_PACKAGE) {
                                    const typeId = eventArgs.data.TypeId;
                                    const kindId = eventArgs.data.KindId;
                                    const exampleRecordId = eventArgs.data.ExampleRecordId;

                                    this.NewRecord(typeId, kindId, exampleRecordId);
                                } else {
                                    new Notifier().Warning(`${eventArgs.data.TypeName} is not allowed`);
                                }
                            });

                            this.On('FUNCTION_CREATED', this, () => {
                                searchScreen.Cancel();
                            });
                            typeScreen.Show();
                        });
                    searchScreen.Show();
                }
            });
    }

    NewParameter() {
        if (this._currentMethod()) {
            var typeScreen = new TypeScreen(this._metadata.EntityId, this._currentMethod().TypeId, false);
            typeScreen.On(TYPE_SCREEN_EVENTS.TYPES_NOT_FOUND, this, (eventArgs) => {
                new Notifier().Warning(eventArgs.data.Message || NOTIFICATIONS.TYPES_NOT_FOUND);
            });
            typeScreen.On(TYPE_SCREEN_EVENTS.TYPE_SELECTED, this, (eventArgs) => {
                const typeId = eventArgs.data.TypeId;
                const kindId = eventArgs.data.KindId;
                const exampleRecordId = eventArgs.data.ExampleRecordId;

                this.AddAndLink(typeId, kindId, exampleRecordId, this._currentMethod().Id).then(() => {
                    this.GetMethod(this._currentMethod().Id);
                });
            });
            typeScreen.Show();
        }
    }

    NewMethod() {
        if (this._package()) {
            var typeScreen = new TypeScreen(this._metadata.EntityId, this._package().TypeId, false);
            typeScreen.On(TYPE_SCREEN_EVENTS.TYPES_NOT_FOUND, this, (eventArgs) => {
                new Notifier().Warning(eventArgs.data.Message || NOTIFICATIONS.TYPES_NOT_FOUND);
            });
            typeScreen.On(TYPE_SCREEN_EVENTS.TYPE_SELECTED, this, (eventArgs) => {
                const typeId = eventArgs.data.TypeId;
                const kindId = eventArgs.data.KindId;
                const exampleRecordId = eventArgs.data.ExampleRecordId;

                this.AddAndLink(typeId, kindId, exampleRecordId, this._package().Id).then((methodId) => {
                    this.GetMethod(methodId).then((method) => {
                        this._package().Methods.push(method);
                        this._package.valueHasMutated();
                    });
                });
            });
            typeScreen.Show();
        }
    }

    AddAndLink(tableTypeId: number, kindId: number, exampleRecordId: number, parentRecordId: number): P.Promise<number> {
        const deferredResult = P.defer<number>();
        BlockUI.Block();
        ScreenManager.GetEditScreen({
            EntityId: this._metadata.EntityId,
            TableTypeId: tableTypeId,
            KindId: kindId,
            RecordId: exampleRecordId,
            LoadAsExample: exampleRecordId > 0
        })
            .always(() => {
                BlockUI.Unblock();
            })
            .then((screen: EditScreen) => {
                const editScreen = screen;
                editScreen.IsDataFromExample = exampleRecordId > 0;
                editScreen.ParentRecordId = parentRecordId;
                editScreen.UseLinking = true;

                screen.On(EDIT_SCREEN_EVENTS.RECORD_SAVED, this, (eventArgs) => {
                    const notifier = new Notifier($(this._el));
                    notifier.Success(NOTIFICATIONS.RECORD_CREATED);
                    UserVarsManager.Instance.AddRecent(this._metadata.EntityId, eventArgs.data.RecordId, tableTypeId);
                    deferredResult.resolve(eventArgs.data.RecordId)
                });

                screen.ShowInModal();
            }).fail(error => {
            new Notifier($(this._el)).Warning(error.message);
        });
        return deferredResult.promise();
    }

    NewRecord(tableTypeId: number, kindId: number, exampleRecordId: number) {
        BlockUI.Block();
        ScreenManager.GetEditScreen({
            EntityId: this._metadata.EntityId,
            TableTypeId: tableTypeId,
            KindId: kindId,
            RecordId: exampleRecordId,
            LoadAsExample: exampleRecordId > 0
        })
            .always(() => {
                BlockUI.Unblock();
            })
            .then((screen: EditScreen) => {
                const editScreen = screen;
                editScreen.IsDataFromExample = exampleRecordId > 0;

                screen.On(EDIT_SCREEN_EVENTS.RECORD_SAVED, this, (eventArgs) => {
                    this.Trigger('FUNCTION_CREATED');
                    const notifier = new Notifier($(this._el));
                    notifier.Success(NOTIFICATIONS.RECORD_CREATED);
                    this._recordId = eventArgs.data.RecordId;
                    UserVarsManager.Instance.AddRecent(this._metadata.EntityId, eventArgs.data.RecordId, tableTypeId);
                    this._functionSelected(true);
                    this.GetPackage(eventArgs.data.RecordId);
                });

                screen.ShowInModal();
            }).fail(error => {
            new Notifier($(this._el)).Warning(error.message);
        });
    }

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);
    }


    Execute() {
        BlockUI.Block();
        FunctionDesignerStore.Execute({
            PackageId: this._package().Id,
            MethodId: this._currentMethod().Id,
            IsRawResult: this._rawResult(),
            ObjectId: this.GetGuid()
        }).always(() => {
            BlockUI.Unblock();
        }).then((result) => {
            var functionResult = new FunctionResult(result);
            functionResult.ShowInModal();
        }).fail(err => new Notifier().Failed(err.message));
    }
}