import * as ko from 'knockout';
import * as _ from 'underscore';

import { DeduplicationCell } from "Core/ProfilePage/Deduplication/DeduplicationEditor/DeduplicationCell";
import { DeduplicationModel } from "Core/ProfilePage/Deduplication/Models/DeduplicationModel";
import { DeduplicationActionCell } from 'Core/ProfilePage/Deduplication/DeduplicationEditor/DeduplicationActionCell';
import { ICell } from 'Core/ProfilePage/Deduplication/DeduplicationEditor/ICell';
import { EVENTS } from 'Core/ProfilePage/Deduplication/DeduplicationEditor/Events';
//import { UpdateDeduplicationModel } from 'Core/ProfilePage/Deduplication/Models/UpdateDeduplicationModel';
import { IDictionary } from 'Core/Screens/BaseScreen';
import { DeduplicationStore } from 'Core/ProfilePage/Deduplication/Stores/DeduplicationStore';

import { IKeyValuePair } from 'Core/Portlets/Utils/GuidToPortletInfoMap';
import { NOTIFICATIONS, LABELS, CONFIRMATIONS } from "Core/Components/Translation/Locales";
import { Notifier } from 'Core/Common/Notifier';
import { BlockUI } from 'Core/Common/BlockUi';

import Template from 'Core/ProfilePage/Deduplication/DeduplicationEditor/Templates/DeduplicationRow.html';
import enumerable from '../../../Common/Decorators/EnumerableDecorator';

export class DeduplicationRow {
	private _cells: KnockoutObservableArray<ICell>;
	private _model: any;
	private _mainGrid: boolean;
	private _disabled: KnockoutObservable<boolean>;
	private _mergedEnabled: KnockoutObservable<boolean>;
	private _entityName: string;
	private deduplicationIntance: any;


	constructor(model: any, mainGrid?: boolean, entityName?: string, deduplicationIntance?: any) {
		this._mainGrid = mainGrid || false;
		this._model = model;
		this._cells = ko.observableArray([]);
		this._disabled = ko.observable(false);
		this._mergedEnabled = ko.observable(false);
		this._entityName = entityName;
		this.deduplicationIntance = this._model.DeduplicationInstance || deduplicationIntance;
		this.Init();
	}

	@enumerable get Model() {
		return this._model;
	}

	Init() {
		const editCells = [];

		_.each(this._model.Data, (rowCell: any, i: number) => {
			if (rowCell.DisplayValue === 'Action') {
				this._cells.push(new DeduplicationCell(
					rowCell.FieldId,
					'',
					'',
					this,
					null,
					this._model.SelectionType,
					'popUpAction',
					null,
					null,
					null,
					this.deduplicationIntance)
				);
			} else {
				if (i === 1 && this._mainGrid) {
					return;
				}
				const cell = new DeduplicationCell(
					rowCell.FieldId,
					rowCell.DisplayValue,
					rowCell.Type,
					this,
					rowCell.IsSelected,
					null,
					'popUpData',
					null,
					null,
					null,
					this.deduplicationIntance);
				this._cells.push(cell);
				editCells.push(cell);
			}

		});
		this._disabled(this._model.IsDisabled);
		this._mergedEnabled(this._model.IsMergeEnabled);
		if (this._model.EntityId) {
			const kSeq = this._model.Data[1].DisplayValue;
			const entityId = this._model.EntityId;
			this._cells.push(new DeduplicationCell(
				null,
				'',
				'',
				this,
				null,
				null,
				'popUpTrigger',
				kSeq,
				entityId,
				this._entityName,
				this.deduplicationIntance));
		}

	}

	GetTemplate() {
		return Template;
	}

	AfterRender() {

	}

	@enumerable get Cells(): KnockoutObservableArray<ICell> {
		return this._cells;
	}
}