import * as ko from 'knockout';

export class Option {
    Id: number;
    Name: string;

    IsSelected: KnockoutObservable<boolean>;

    constructor() {
        this.IsSelected = ko.observable(false);
    }
}