import {P} from "Core/Common/Promise";
import {URL} from "Core/Common/Url";
import {IRequestOptions, Request} from "Core/Common/Request";

import {IDatarole} from 'FieldCollection/Models/IDataRole';
import {IEntity} from 'FieldCollection/Models/IEntity';
import {IFields} from 'FieldCollection/Models/IFields';
import {ICollectionSave} from 'FieldCollection/Models/ICollectionSave';

export class FieldCollectionStore {

	GetDataRole(): P.Promise<IDatarole[]> {
		const deferredResult = P.defer<IDatarole[]>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.FIELD_COLLECTION_DESIGNER.GET_DATA_ROLE,
				type: 'GET',
				dataType: 'json'
			}
		};

		Request.Send(requestParams)
			.then((data) => deferredResult.resolve(data.FieldCollections))
			.fail((err) => deferredResult.reject(err));

		return deferredResult.promise();
	}

	GetEntities(): P.Promise<IEntity[]> {
		const deferredResult = P.defer<IEntity[]>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.FIELD_COLLECTION_DESIGNER.GET_ENTITIES,
				type: 'GET',
				dataType: 'json'
			}
		};

		Request.Send(requestParams)
			.then((data) => deferredResult.resolve(data))
			.fail((err) => deferredResult.reject(err));

		return deferredResult.promise();
	}

	GetFieldCollection(entityId: number, selectedCollectionId: number): P.Promise<IFields> {
		const deferredResult = P.defer<IFields>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.FIELD_COLLECTION_DESIGNER.GET_FIELD_COLLECTION,
				type: 'POST',
				dataType: 'json'
			},
			params: {
				EntityId: entityId,
				SelectedCollectionId: selectedCollectionId
			}
		};

		Request.Send(requestParams)
			.then((data: IFields) => deferredResult.resolve(data))
			.fail((err) => deferredResult.reject(err));

		return deferredResult.promise();
	}

	SaveFieldCollection(fieldCollection: ICollectionSave): P.Promise<any> {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.FIELD_COLLECTION_DESIGNER.SAVE_FIELD_COLLECTION,
				type: 'POST',
				dataType: 'json'
			},
			params: fieldCollection
		};

		Request.Send(requestParams)
			.then((data) => deferredResult.resolve(data))
			.fail((err) => deferredResult.reject(err));

		return deferredResult.promise();
	}

	UpdateViews() {
		const deferred = P.defer<any>();

		Request.Send({
			proxy: {
				url: URL.FIELD_COLLECTION_DESIGNER.UPDATE_VIEWS,
				type: 'GET'
			}
		}).then(() => deferred.resolve(null))
			.fail(error => deferred.reject(error));

		return deferred.promise();
	}
}