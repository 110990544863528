import * as ko from 'knockout';
import * as _ from 'underscore';

import { Guid } from 'Core/Common/Guid';
import { NOTIFICATIONS, LABELS } from 'Core/Components/Translation/Locales';
import { PRIORITY_LIST } from 'QueryBuilder/TableViewConfig/PriorityList';
import { FieldInfo } from 'QueryBuilder/TableViewConfig/FieldInfo';
import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { GlobalManager, GLOBALS } from "Core/GlobalManager/GlobalManager";

import TableViewFieldConfigTemplate from 'QueryBuilder/TableViewConfig/Templates/TableViewFieldConfig.html';

export class EntityGroup {
	private _fields: KnockoutObservableArray<FieldInfo>;
	private _displayPriorityList: KnockoutObservableArray<any>;
	private _labels = LABELS;
	private _guid = Guid.NewGuid();
	Entities: Array<QueryEntityModel>;
	private _basicMode: boolean;

	constructor() {
		this._fields = ko.observableArray([]);
		this._displayPriorityList = ko.observableArray(PRIORITY_LIST);
		this.Entities = [];
		this.EntitiesGroups = [];
		this._basicMode = GlobalManager.Instance.GetGlobal(GLOBALS.BASIC_MODE) === '1';
	}

	AddEntity(entity: QueryEntityModel) {
		this.Entities.push(entity);
	}

	EntitiesGroups: Array<EntityGroup>;

	GetTemplate() {
		return TableViewFieldConfigTemplate;
	}

	UpdateSortPositionValues() {
		var indx = 0;
		_.each(this._fields(), (field: FieldInfo) => {
			if (field.Column) {
				field.Column.DisplayOrder = indx;
				indx++;
			}
		});
	}

	StartOrder(evt, ui) {
		ui.placeholder.html(ui.item.html());
	}

	StopOrder() {
		this.UpdateSortPositionValues();
	}

	CheckTotal(model: FieldInfo) {
		model.IsTotal(!model.Column.IsTotal);
	}

	CheckCount(model: FieldInfo) {
		model.IsCount(!model.Column.IsCount);
	}

	CheckSubTotal(model: FieldInfo) {
		model.IsSubTotal(!model.Column.IsSubTotal);
	}

	CheckAverage(model: FieldInfo) {
		model.IsAverage(!model.Column.IsAverage);
	}

	CheckWrapped(model: FieldInfo) {
		model.IsWrapped(!model.IsWrapped());
	}

	CheckGroup(model: FieldInfo) {
		model.IsGroup(!model.IsGroup());
	}

	UpdateFieldList() {
		var fields = [];
		_.each(this.Entities,
			entity => {
				_.each(entity.Columns,
					column => {
						if(column.Metadata.Type) {
							let field = new FieldInfo(column, entity);
							fields.push(field);
						}
					});
			});

		fields = _.sortBy(fields, (fieldModel: FieldInfo) => { return fieldModel.Column.DisplayOrder; });

		for (var indx = 0; indx < fields.length; indx++) {
			fields[indx].Column.DisplayOrder = indx;
		}

		var formatField = new FieldInfo();
		fields.unshift(formatField);

		this._fields(fields);
	}
}