import * as ko from 'knockout';
import * as _ from 'underscore';

import {ProductPrice} from "./ProductPrice";
import { PriceItem } from "./PriceItem";
import { ConfigurationPageEvents } from "../Events/ConfigurationPageEvents";

export class PriceGroup extends PriceItem {
    Products: KnockoutObservableArray<ProductPrice>;

    constructor(id: number, name: string, price: number, public nameTranslated: string) {
        super(id, name, nameTranslated, price);

        this.Products = ko.observableArray([]);
    }

    HasProducts() {
        return this.Products().length > 0;
    }

    GetProduct(productId: number, rootGroupId: number, rootGroupName: string, kSeq: number, kSeqGuid: string) {
        return _.find(this.Products(), product => product.Id === productId && product.RootGroupId === rootGroupId && product.RootGroupName === rootGroupName && product.KSeq === kSeq && product.KSeqGuid === kSeqGuid);
    }

    GetDependenciesOf(productId: number): ProductPrice[] {
        return this.Products().filter(product => _.last(product.Path) === productId);
    }

    AddProduct(productPrice: ProductPrice) {
        this.Products.push(productPrice);
        this.Price(this.Price() + productPrice.PositionPrice);
        this.AlternativePrice(this.AlternativePrice() + productPrice.PositionAlternativePrice);

        if (this._compare()) {
            this.ComparisonPrice(this.ComparisonPrice() + productPrice.ComparisonPositionPrice);
            this.ComparisonAlternativePrice(this.ComparisonAlternativePrice() + productPrice.ComparisonPositionAlternativePrice);
        }
    }

    RemoveProduct(productPrice: ProductPrice) {
        const productIndex = _.findIndex(this.Products(),
            p => p.Id === productPrice.Id && p.GroupId === productPrice.GroupId && p.GroupName === productPrice.GroupName && _.isEqual(p.Path, productPrice.Path) && p.KSeq === productPrice.KSeq && p.KSeqGuid === productPrice.KSeqGuid);

        this.Products.splice(productIndex, 1);
        this.Price(this.Price() - productPrice.PositionPrice);
        this.AlternativePrice(this.AlternativePrice() - productPrice.PositionAlternativePrice);
    }
}