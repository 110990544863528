import {IChartBuilderParams} from "../IChartBuilderParams";
import {FIELD_TYPES} from "Core/Constant";
import {FormatConverter} from "FormatEditor/FormatConverter";
import * as moment from "moment";

export abstract class ChartBuilder{
    protected _chartType: string;
    protected  _chartTitle: string;
    protected  _xAxis: number;
    protected _yAxis: number;
    protected _xAxisLabel: string;
    protected _yAxisLabel: string;
    protected _sortBy: string;
    protected _series: number;
    protected _wrapperId: string;
    protected _xAxisExists: boolean;
    protected _yAxisExists: boolean;
    protected _seriesExists: boolean;

    constructor(params: IChartBuilderParams){
        this._chartType = params.ChartType;
        this._chartTitle = params.ChartTitle;
        this._xAxis = params.XAxis;
        this._xAxisLabel = params.XAxisLabel;
        this._yAxis = params.YAxis;
        this._yAxisLabel = params.YAxisLabel;
        this._sortBy = params.SortBy;
        this._series = params.Series;
        this._wrapperId = params.WrapperId;
        this._xAxisExists = params.XAxisExists;
        this._yAxisExists = params.YAxisExists;
        this._seriesExists = params.SeriesExists;
    }

    abstract RenderChart(ChartDataModel): void;

    ApplyLabelFormatter(labelValue: any, fieldType: string, labelFormat: string) {
        if (fieldType === FIELD_TYPES.Date || fieldType === FIELD_TYPES.DateTime) {
            const dateFormat = FormatConverter.GetDateFormatFromFieldModel({
                FieldTypeName: fieldType,
                FormatName: labelFormat
            });
            return moment(labelValue).format(dateFormat);
        }

        return labelValue;
    }

    GetFormattedValue(pointValue: any, fieldType: string) {
        if (!pointValue) {
            return pointValue;
        }

        if (fieldType === FIELD_TYPES.Integer || fieldType === FIELD_TYPES.Decimal) {
            return Number(String(pointValue).replace(",","."));
        } else if (fieldType === FIELD_TYPES.Date || fieldType === FIELD_TYPES.DateTime) {
            return moment(pointValue).valueOf();
        }

        return pointValue;
    }
}