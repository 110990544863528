import { CONTROL_TYPES } from './../../Constant';
import * as ko from 'knockout'
import * as _ from 'underscore';

import { BaseControl, IControlValue } from 'Core/Controls/BaseControl/BaseControl'
import { IControlParam } from 'Core/Screens/IScreen';
import {Behavior} from 'Core/Controls/ButtonCopy/Constants/Behavior';
import {RenderModes} from "Core/Constant";
import {GeneralProperties} from "Core/GeneralProperties/GeneralProperties";
import {MenuItemModel} from 'Core/Controls/ButtonCopy/Models/MenuItemModel';
import {IconModel} from 'Core/Controls/BaseControl/Models/IconModel'
import type { Grid } from 'Core/Controls/Grid/Grid';

import CopyButtonConfig from "Core/Controls/ButtonCopy/Configs/copy-button-config.json";

import ViewTemplate from 'Core/Controls/ButtonCopy/Templates/View.html'
import HelpViewTemplate from 'Core/Controls/ButtonCopy/Templates/HelpView.html'
import DesignTemplate from 'Core/Controls/ButtonCopy/Templates/Design.html'
import ToolBarTemplate from 'Core/Controls/ButtonCopy/Templates/ToolBar.html'
import { Notifier } from '../../Common/Notifier';
import { NOTIFICATIONS } from '../../Components/Translation/Locales';
import { ButtonCopyApi, CopyRecordsDto } from './Stores/ButtonCopyApi';
import { BlockUI } from '../../Common/BlockUi';

ko.templates['Core/Controls/ButtonCopy/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/ButtonCopy/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/ButtonCopy/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/ButtonCopy/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/ButtonCopy/Templates/Edit'] = ViewTemplate;

export class ButtonCopy extends BaseControl {
	private _menuItems: KnockoutObservableArray<MenuItemModel>;
    private _config: any;
	private _mainRecordId: number;
	private _linkToSource: KnockoutObservable<boolean>;
	private _behavior: KnockoutObservable<string>;
	private _type: KnockoutObservable<number>;
	private _aliasSuffix: KnockoutObservable<string>;

	private _buttonClassName: KnockoutObservable<string>;
	private _iconModel = IconModel;
	private _isCopyingInGrid: boolean;

	constructor(params: IControlParam) {
		super(params, CopyButtonConfig);
        this._style = ko.computed(() => {
            return {
                backgroundColor: this._backgroundColor(),
                color: this._color(),
                borderColor: this._borderColor(),
                borderWidth: this._border() ? '1px' : '0',
                padding: this._border() ? '6px 14px' : '7px 14px'
            };
        });

        this._linkToSource = ko.observable(false);
        this._behavior = ko.observable(null);
        this._type = ko.observable(null);
        this._aliasSuffix = ko.observable('');
		this._isCopyingInGrid = false;

		this.Init();

		this._icon(this._model().Icon);
		this._buttonClassName = ko.observable(this._iconModel.GetClasses(this._model().Icon?.FontName, this._model().Icon?.Name));
		this._icon.subscribe(() => {
			this._buttonClassName(this._iconModel.GetClasses(this._model().Icon?.FontName, this._model().Icon?.Name))
		});
	}

	private Init(): void {
        this.ApplyProperties();
        
		let copyMenuItem = new MenuItemModel(0, 'Copy', this._labels.COPY);
		let copyWithRelationMenuItem = new MenuItemModel(1, 'Copy with relation', this._labels.COPY_WITH_RELATIONS);

		this._menuItems = ko.observableArray([copyMenuItem, copyWithRelationMenuItem]);
	}

	get MenuItems(): KnockoutObservableArray<MenuItemModel> {
		return this._menuItems;
	}

	Click(menuItem: MenuItemModel) {
		
		if (this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
		} else {
			var screen = this._form.GetScreen();
			if (screen) {
				if(menuItem.Id === 0){
					if(this._isCopyingInGrid){
						this.CopyInGrid({ linkToSource: this._linkToSource() });
					}else{
						screen.Trigger('COPY_RECORD', { linkToSource: this._linkToSource() })
					}
				}else{
					if(this._isCopyingInGrid){
						this.CopyInGrid({ copyRelations: true, linkToSource: this._linkToSource() });
					}else{
						screen.Trigger('COPY_RECORD_WITH_RELATIONS', { linkToSource: this._linkToSource() });
					}
				}  
			}
		}
	}

	private ClickRootCopyBtn(data?, event?): void {
		const screen = this._form.GetScreen();
		if(this.IsInGrid && !this._isCopyingInGrid){
			this.Grid.EnableRecordSelection();
			this._isCopyingInGrid = true;	
			event.stopPropagation();
		}else if (this._help.IsHelpButtonPressed()) {
			event.stopPropagation();
			this._help.ShowControlHelp(this);
		} else if (this._behavior() === Behavior.Copy) {
			event.stopPropagation();
			if(this._isCopyingInGrid){
				this.CopyInGrid({ linkToSource: this._linkToSource() });
			}else{
				screen && screen.Trigger('COPY_RECORD', { linkToSource: this._linkToSource() });
			}
		} else if (this._behavior() === Behavior.CopyWithRelations) {
			event.stopPropagation();
			if(this._isCopyingInGrid){
				this.CopyInGrid({ copyRelations: true, linkToSource: this._linkToSource() });
			}else{
				screen && screen.Trigger('COPY_RECORD_WITH_RELATIONS', { linkToSource: this._linkToSource() });
			}
		} else if (this._behavior() === Behavior.CopyToType && this._type()) {
			event.stopPropagation();
			if(this._isCopyingInGrid){
				this.CopyInGrid({ copyRelations: true, copyToType: this._type(), linkToSource: this._linkToSource(), aliasSuffix: this._aliasSuffix() });
			}else{
				screen && screen.Trigger('COPY_TO_TYPE', { linkToSource: this._linkToSource(), copyToType: this._type(), aliasSuffix: this._aliasSuffix() });
			}
		}
	}

	async CopyInGrid({ copyRelations = false, linkToSource = false, copyToType = 0, aliasSuffix = '' }: { copyRelations?: boolean, linkToSource?: boolean,  copyToType?: number, aliasSuffix?: string }){
		let selectedRecords = this.Grid.GetSelectRecords();
		let recordsToCopy = _.map(selectedRecords, (selectedRecord) => {
			return {
				Id: selectedRecord.RecordId,
				TypeId: selectedRecord.RecordTypeId
			};
		});

		if(recordsToCopy.length != 0){
			BlockUI.Block();
			let params: CopyRecordsDto = {
				SubjectEntityId: this.Screen.GetEntityId(),
				SubjectRecordId: this.Screen.GetRecordId(),
				SubjectTypeId: this.Screen.GetTableTypeId(),
				EntityIdToCopy: this.GridEntityId,
				RecordsToCopy: recordsToCopy,
				CopyRelations: copyRelations,
				IsLinkToSource: linkToSource,
				CopyToTypeId: copyToType,
				AliasSuffix: aliasSuffix
			};

			await ButtonCopyApi.CopyRecords(params).always(()=>BlockUI.Unblock()).fail((err)=>Notifier.Failed(err.message));

			this.Grid.DisableRecordSelection();
			this._isCopyingInGrid = false;

			this.Screen.Refresh();
		}else{
			Notifier.Warning(NOTIFICATIONS.NO_SELECTED_RECORDS);
		};
	}

	get GridEntityId(): number{
		if(this.IsConsultScreen || this.IsEditScreen){
			return this.Grid.GetGridSubject().Entity.Metadata.Id;
		}
		if(this.IsListScreen){
			return this.Screen.GetEntityId();
		}

		return null;
	}

	get Grid(): Grid{
		if(this._parentControl?.GetType() === CONTROL_TYPES.Grid){
			return this._parentControl as Grid;
		}
		return null;
	}

	SetValue(value: IControlValue): void {
		if(this.IsInGrid){
			this._enabled(true);
		}else if (value.Data && value.Data.Rights && !value.RecordSpecsModel?.IsNewRecord) {
			this._enabled(value.Data.Rights.IsAddingRecordAllowed && value.Data.Rights.IsEditingAllowed && value.Data.Rights.IsRecordSecurityOk);
		} else if (value.RecordSpecsModel.IsNewRecord) {
			this._enabled(true);
		} 
	}

	get IsInGrid(): boolean {
		return this._parentControl?.GetType() === CONTROL_TYPES.Grid;
	}

    ApplyProperties() {
        if (this.Properties) {
        	this.AssignProperty('General', 'LinkToSource', this._linkToSource);
        	this.AssignProperty('General', 'Behavior', this._behavior);
			this.AssignProperty('General', 'Type', this._type);
			this.AssignProperty('General', 'AliasSuffix', this._aliasSuffix);
            this.AssignProperty('Styling', 'BackgroundColor', this._backgroundColor);
            this.AssignProperty('Styling', 'TextColor', this._color);
            this.AssignProperty('Styling', 'Border', this._border);
            this.AssignProperty('Styling', 'BorderColor', this._borderColor);
        }
    }

    private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
        if (this.Properties[groupedBy]) {
            _.each(this.Properties[groupedBy].Properties,
                (property: any) => {
                    if (property.hasOwnProperty(propertyName)) {
						const propertyValue = property[propertyName].hasOwnProperty('Value')
							? property[propertyName].Value
							: property[propertyName];
						propertyHolder(propertyValue);
                    }
                });
        }
    }

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}
} 