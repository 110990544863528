import * as ko from "knockout";

import {Event} from "Core/Common/Event";

import {DataRoleModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/DataRoleModel";

export abstract class NewDataRoleComponent extends Event {
	protected Template: any;

	constructor(template: any) {
		super();

		this.Template = template;

		this.InitValidation();
	}

	GetTemplate() {
		return this.Template;
	}

	abstract AfterRender();

	abstract ValidateForm();

	protected OnRoleAdding() {
		const valid = this.ValidateForm();

		if (valid) {
			const dataRole = this.MapToNewDataRole();
			this.Trigger('DataRoleAdded', {DataRole: dataRole});
		}
	}

	protected OnRoleAddingCancel() {
		this.Trigger('AddingCancelled');
	}

	protected abstract MapToNewDataRole(): DataRoleModel;

	private InitValidation() {
		ko.validation.init({insertMessages: false}, true);
	}
}