import * as ko from 'knockout';
import {inheritSerialization, autoserialize, autoserializeAs} from 'libs/cerialize';

export class TableSecurityUnitModel {

	@autoserialize
	Id: number;

	@autoserialize
	Name: string;

	@autoserialize
	NameTranslation: string;
}