import * as ko from 'knockout';

var original = new ko.bindingProvider();
export class ErrorHandlingBindingProvider implements KnockoutBindingProvider {
	getBindingAccessors(node: Node, bindingContext: KnockoutBindingContext): { [index: string]: string; } {
		var result = {};
		
		//catch general errors parsing binding syntax
		try {
			result = original.getBindingAccessors(node, bindingContext);
		}
		catch (e) {
			if (console && console.log) {
				console.log("Error in binding syntax: " + e.message, node);   
			}
		}
		
		//catch errors when actually evaluating the value of a binding
		ko.utils.objectForEach(result, (key, value) => {
			result[key] = () => {
				var result = null;

				try {
					result = value();  
				}
				catch (e) {
					if (console && console.log) {
						console.log("Error in \"" + key + "\" binding: " + e.message);
						console.log('Node :', node);
						if (bindingContext && bindingContext.$data) {
							console.log('Model :', bindingContext.$data);
						}
					}
				}
				
				return result;
			};
		});

		return (result) as any;
	}

	nodeHasBindings = original.nodeHasBindings;
	getBindings = original.getBindings;

	
}