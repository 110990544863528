import { Modal } from "Core/Common/Modal";
import {Event} from 'Core/Common/Event'
import { PlannerLevelRowModel, TimeCellModel } from "../Models/PlannerLevelRowModel";
import { Icon } from "Core/Icon/Icon";
import { EVENTS } from "../Events";

import Template from 'Core/Controls/PlannerLevel/RightBarCell/Templates/QueryButtons.html';
import {JBoxDropDown} from "Core/Components/JBoxDropdown/DropDown";
import {ZIndexManager} from "Core/Common/ZIndexManager";
ko.templates['Core/Controls/PlannerLevel/RightBarCell/Templates/QueryButtons'] = Template;

export class QueryButtons extends Event {
    private readonly _id: string;
    private _dropDown: JBoxDropDown;

    private _query1Icon: Icon;
    private _query2Icon: Icon;

    constructor(barModel: PlannerLevelRowModel){
        super();
        this._id = JBoxDropDown.GetDropDownId();
        this._dropDown = new JBoxDropDown({});

        if(barModel.Button1Icon){
            this._query1Icon = new Icon(barModel.Button1Icon);            
        }
        
        if(barModel.Button2Icon){
            this._query2Icon = new Icon(barModel.Button2Icon);            
        }        
        this.AddEvent(EVENTS.RUN_QUERY1);
        this.AddEvent(EVENTS.RUN_QUERY2);
        this.AddEvent(EVENTS.DROPDOWN_CLOSED);
    }

    GetTemplateName(): string{
        return 'Core/Controls/PlannerLevel/RightBarCell/Templates/QueryButtons';
    }

    AfterRender(){}

    Show(target: any){
        this._dropDown = new JBoxDropDown({
            target: target || "." + this._id,
            otherOptions: {
                attach: undefined,
                closeOnClick: true,
                position: {
                    x: "left",
                    y: "bottom"
                },
                onCloseComplete: () => this._dropDown.Destroy(),
                zIndex: ZIndexManager.Instance.NextValue,
                blockScroll: false,
                maxWidth: "calc(100% - 7px)"
            },
            onOpen: () => {
                this._dropDown.SetContent({ content: Template as any });
            },
            onClose: () => {
                this.Trigger(EVENTS.DROPDOWN_CLOSED);
            },
            bindComponent: this,
        });

        this._dropDown.Open();
    }

    Close() {
        this._dropDown.Close();
    }

    RunQuery1(_, evt){
        this._dropDown.Close();
        this.Trigger(EVENTS.RUN_QUERY1);        
    }

    RunQuery2(_, evt){
        this._dropDown.Close();
        this.Trigger(EVENTS.RUN_QUERY2);
    }
}