import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize} from 'libs/cerialize';
import {OperationResultModel} from 'Core/Models/OperationResultModel';


export interface ILinkRecordsRequestModel {
    MainTableId: number;
    LinkedTableId: number;
    MainRecordId: number;
    LinkedRecordIds: Array<number>;
}

export interface IGetLatestLinkRequestModel {
    MainTableId: number;
    LinkedTableId: number;
    MainRecordId: number;
}

export interface IGetLatestSubRequestModel {
    SubTableId: number;
    MainRecordId: number
}

export interface ICopyLatestSubRequestModel {
    MainTableId: number;
    SubTableId: number;
    MainRecordId: number
}

export interface ICopySubRequestModel {
    SubTableId: number;
    SequenceId: number;
}

export class LinkRecordsStore {
    static LinkWithRecords(params: ILinkRecordsRequestModel): P.Promise<OperationResultModel> {
        const deferredResult = P.defer<OperationResultModel>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: 'api/ScreenFormApi/LinkWithRecords',
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(result => {
                deferredResult.resolve(<OperationResultModel>Deserialize(result, OperationResultModel));
            });

        return deferredResult.promise();
    }

    static GetLastLinkedRecordEditScreen(params: IGetLatestLinkRequestModel) {
        const deferred = P.defer<any>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: 'api/ScreenFormApi/GetLastLinkedRecordEditScreen',
                dataType: 'json',
                type: 'GET'
            }, params: params
        };

        Request.Send(requestParams)
            .then(result => {
                if (result.IsSuccessfull) {
                    deferred.resolve(result.ResultObject)
                } else {
                    deferred.reject({message: result.ErrorMessage});
                }
            })
            .fail(err => deferred.reject(err));

        return deferred.promise();
    }

    static GetLastSubRecordSpecs(params: IGetLatestSubRequestModel) {
        const deferred = P.defer<any>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: 'api/ScreenFormApi/GetLastSubRecordSpecs',
                dataType: 'json',
                type: 'GET'
            }, params: params
        };

        Request.Send(requestParams)
            .then(result => {
                if (result.IsSuccessfull) {
                    deferred.resolve(result.ResultObject)
                } else {
                    deferred.reject({message: result.ErrorMessage});
                }
            })
            .fail(err => deferred.reject(err));

        return deferred.promise();
    }

    static CopyLastSubRecord(params: ICopyLatestSubRequestModel) {
        const deferred = P.defer<any>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: 'api/ScreenFormApi/CopyLastSubRecord',
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(result => {
                if (result.IsSuccessfull) {
                    deferred.resolve(result.ResultObject)
                } else {
                    deferred.reject({message: result.ErrorMessage});
                }
            })
            .fail(err => deferred.reject(err));

        return deferred.promise();
    }

    static CopySubRecord(params: ICopySubRequestModel) {
        const deferred = P.defer<any>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: 'api/ScreenFormApi/CopySubRecord',
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(result => {
                if (result.IsSuccessfull) {
                    deferred.resolve(result.ResultObject)
                } else {
                    deferred.reject({message: result.ErrorMessage});
                }
            })
            .fail(err => deferred.reject(err));

        return deferred.promise();
    }
}