export const EVENTS = {
	DROP_FIELD: 'DROP_FIELD',
	DROP_ENTITY: 'DROP_ENTITY',
	DROP_SPHEERES_LINK: 'DROP_SPHEERES_LINK',
	DROP_OPEN_RECORD_LINK: 'DROP_OPEN_RECORD_LINK',
	DROP_URL_COMMAND: 'DROP_URL_COMMAND',
	DROP_EMAIL_SUBJECT: 'DROP_EMAIL_SUBJECT',
	DROP_VARIABLE: 'DROP_VARIABLE',
	DROP_CC: 'DROP_CC',
	DROP_BCC: 'DROP_BCC',
	DROP_TO: 'DROP_TO',
	DROP_SCRIPT: 'DROP_SCRIPT',
	SAVE: 'SAVE',
	PREVIEW: 'PREVIEW'
}