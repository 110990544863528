import * as ko from 'knockout';
import * as _ from 'underscore';

import {Notifier} from "Core/Common/Notifier";
import {BlockUI} from 'Core/Common/BlockUi';

import {NOTIFICATIONS} from "Core/Components/Translation/Locales";

import {PropertyControl} from "../PropertyControl";

import {ImagePropertyControlStore} from "./Stores/ImagePropertyControlStore";

import {ImageModel} from "./Models/ImageModel";
import {ImageTableTypes} from "../../../../../../Components/ProductImageViewer/Enums/ImageTableTypes";

import Template from './Template.html';
import {ProductPropertyDescription} from "../../ProductPropertyDescription";
import {ProductPart} from "../../../ProductPart";

import {EVENTS as CONFIRMATION_DIALOG_EVENTS} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import {ColorPalettePopup} from '../../../../Modals/ColorPalettePopup/ColorPalettePopup';

import {ConfigurationPageEvents} from "Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Events/ConfigurationPageEvents";
import {ImagePropertyValue} from '../../../ImagePropertyValue';
import {ProductImageViewer} from '../../../../../../Components/ProductImageViewer/ProductImageViewer';


export class ImagePropertyControl extends PropertyControl {
    private _store: ImagePropertyControlStore;
    private _imagePropertyViewer: ProductImageViewer;

    private _selectedImageName: string;
    private _subscriptionReady: boolean;
    private _showAll: KnockoutObservable<boolean>;

    Images: KnockoutObservableArray<ImageModel>;
    SelectedImage: KnockoutObservable<ImageModel>;
    ImageObservableArray: KnockoutObservableArray<any>

    constructor(property: ProductPropertyDescription, productPart: ProductPart, ordersEntityId, productEntityId: number) {
        super(property, productPart);

        this._store = new ImagePropertyControlStore(ordersEntityId, productEntityId);
        this._imagePropertyViewer = new ProductImageViewer({
            ProductsEntityId: productEntityId,
            OrderEntityId: ordersEntityId,
            ImageFieldId: this.ValueHolder
        });

        this.Images = ko.observableArray([]);
        this.SelectedImage = ko.observable(null);
        this._showAll = ko.observable(false);

        this._selectedImageName = null;
        this.ImageObservableArray = ko.observableArray([]);
    }

    OnInit() {
        if (!this.HasContent()) {
            if (!this.readOnly()) {
                this.LoadImages();
            }
        } else {
            if (this.Images().length > 9) {
                this._showAll(false);
            } else {
                this._showAll(true);
            }
        }

    }

    ClearValue() {
        this.SelectedImage(null);
    }

    GetTemplate() {
        return Template;
    }

    GetValueForPrice(): any {
        return this.SelectedImage() && this.SelectedImage().Name;
    }

    GetValueForSave(): any {
        if (this.SelectedImage() && this.SelectedImage().Id) {
            return this.SelectedImage().Id;
        }
        return null;
    }

    SetValue(propertyValue: ImagePropertyValue) {
        if (propertyValue) {
            this._selectedImageName = propertyValue.Value;
            if (propertyValue.Thumbnail != null && this.readOnly()) {
                const imageTableType = this.product.IsMain() ? ImageTableTypes.OrderProduct : ImageTableTypes.Hook;
                this.Images().push(new ImageModel(propertyValue.ImageId, propertyValue.Value, propertyValue.Thumbnail, imageTableType));
                this.HasContent(true);
                this.UpdateSelectedImageReference();
            }
        }
    }

    SelectImage(data){
        if ( _.some(this.Images(), (item) => item.id === data.id) ) {
            let selectedItem = _.find(this.Images(), (item)=> item.id === data.id);
            this.Select(selectedItem);
        }
    }

    Select(image: ImageModel) {
        if (image == null) {
            this.UnSelect();
        } else if (_.filter(this.Images(), item => item.Name?.toUpperCase() === image.Name?.toUpperCase()).length > 1) {
            Notifier.Warning(NOTIFICATIONS.PROPERTY_VALUE_SHOULD_HAVE_THE_UNIQUE_NAME);
            this.UnSelect();
        } else {
            this._selectedImageName = image.Name;
            this.UpdateSelectedImageReference();
        }
    }

    UnSelect() {
        this._selectedImageName = null;
        this.UpdateSelectedImageReference();
    }

    private OnImageClick(image: ImageModel) {
        if (this._showAll()) {
            if (this.SelectedImage() === image) {
                this.UnSelect();
                return;
            }

            this.Select(image);
        } else {
            BlockUI.Block({ZIndex: 11000});

            const confirmationDialog = new ColorPalettePopup({
                ModalClass: 'jBox-padding-15px',
                Colors: null,
                SelectedColor: null,
                Images: this.Images(),
                SelectedImage: this.SelectedImage(),
                ImagePropertyViewer: this._imagePropertyViewer,
                Width: '90vw',
                Height: '90vh',
                blockScroll: true,
                closeButton: 'box'
            });

            confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, (eventArgs: any) => {
                this.Select(eventArgs.data);
            });

            confirmationDialog.Show();
        }

    }

    private StartListeningValueChanges() {
        if (!this._subscriptionReady) {
            this.SelectedImage.subscribe(() => {
                this._selectedImageName = this.SelectedImage() && this.SelectedImage().Name;
                this.CheckValidControl();
                this.OnValueChanged();
            });

            this._subscriptionReady = true;
        }
    }

    private LoadImages() {
        this.DispatchEvent(ConfigurationPageEvents.PropertyContentLoading);
        this._store.GetAvailableImages(this.property.ProductId, this.ValueHolder)
            .then(imagesResponse => {
                const images = imagesResponse.map(image => new ImageModel(image.Id, image.Name, image.Thumbnail, ImageTableTypes.ProductProperty));

                if (this.readOnly()) {
                    this.Images([images.find(image => image.Name == this._selectedImageName)]);
                } else {
                    this.Images(images);
                }
                this.UpdateSelectedImageReference();
                this.StartListeningValueChanges();

                this.HasContent(this.Images().length > 0);

                if (this.Images().length > 9) {
                    this._showAll(false);
                } else {
                    this._showAll(true);
                }

                let imagesMap = _.map(images, image=>{
                    return {
                        label: image.Name,
                        id: image.Id
                    }
                });
                this.ImageObservableArray(imagesMap);

            })
            .fail(err => new Notifier().Failed(err.message))
            .always(() => this.DispatchEvent(ConfigurationPageEvents.PropertyContentLoaded));
    }

    get GetImageObservableArray(){
        return this.ImageObservableArray();
    }

    private UpdateSelectedImageReference() {
        const selectedImage = _.find(this.Images(), image => image.Name?.toUpperCase() === this._selectedImageName?.toUpperCase());
        this.SelectedImage(selectedImage);
    }

    IsValid(value?: any): boolean {
        return this.GetIsRequiredValidation(value ? value : this.GetValueForSave());
    }

    CheckValidControl() {
        this.isValidControl(this.GetIsRequiredValidRule(this.GetValueForSave()));
    }
}