import * as ko from "knockout";
import {Event} from "./Event";

export class Timer extends Event {
	private readonly _timeout: number;
	private _intervalId: number;

	TimerCounter: KnockoutObservable<number>;

	constructor(seconds: number) {
		super();

		this._timeout = seconds > 0 ? Math.round(seconds / 1000) : 0;
		this.TimerCounter = ko.observable(0);
	}

	Start() {
		this.TimerCounter(this._timeout);
		this._intervalId = setInterval(() => {
			if (this.TimerCounter() > 0) {
				this.TimerCounter(this.TimerCounter() - 1);
			} else {
				this.OnTimerExpired();
			}
		}, 1000);
	}

	Stop() {
		clearInterval(this._intervalId);
	}

	private OnTimerExpired() {
		this.Stop();
		this.Trigger(TIMER_EVENTS.TIMER_EXPIRED);
	}
}

export const TIMER_EVENTS = {
	TIMER_EXPIRED: "TimerExpired"
};