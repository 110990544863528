import * as _ from 'underscore';
import * as ko from 'knockout';
import * as moment from 'moment';

'Core/ProfilePage/Deduplication/DeduplicationEditor'

import { DeduplicationRow } from 'Core/ProfilePage/Deduplication/DeduplicationEditor/DeduplicationRow';
import { DeduplicationHeader } from 'Core/ProfilePage/Deduplication/DeduplicationEditor/DeduplicationHeader';

import Template from 'Core/ProfilePage/Deduplication/DeduplicationEditor/Templates/DeduplicationGrid.html';
import {Event} from "Core/Common/Event";
import {EVENTS} from "Core/Controls/Grid/BaseGrid/Events";
import {CONTROL_TYPES} from 'Core/Constant';
import {DATE_FORMATS} from 'Core/Constants/DateTimeFormats';
import {GridDataModel} from "Core/Controls/Grid/Models/GridDataModel/GridDataModel";
import {GridColumnModel} from "Core/Controls/Grid/Models/GridDataModel/GridColumnModel";
import {Modal} from "Core/Common/Modal";
import {LABELS} from "Core/Components/Translation/Locales";
import {BlockUI} from "Core/Common/BlockUi";

import DeduplicationGridTemplate from 'Core/ProfilePage/Deduplication/DeduplicationEditor/Templates/DeduplicationGridPopUp.html';
import enumerable from '../../../Common/Decorators/EnumerableDecorator';

ko.templates['Core/ProfilePage/Deduplication/DeduplicationEditor/Templates/DeduplicationGridPopUp'] = DeduplicationGridTemplate;

export class DeduplicationGrid extends Event{
	private _modal: Modal;
	private _labels = LABELS;
	private _rows: KnockoutObservableArray<DeduplicationRow>;
	private _headerColumns: KnockoutObservableArray<DeduplicationHeader>;


	private _entityId: number;
	private _kSeq: number;

	constructor() {
		super();

		this._rows = ko.observableArray([]);
		this._headerColumns = ko.observableArray([]);

        this.AddEvent(EVENTS.SORT);
        this.AddEvent(EVENTS.SAVE_CHANGES);
    }

	GetTemplate() {
		return Template;
	}

    @enumerable get Columns() {
        return this._headerColumns();
    }

    GetModal(){
		return this._modal;
	}

    ResetSort(excludecolumn: DeduplicationHeader) {
        _.each(this._headerColumns(), item => {
            if (item !== excludecolumn) {
                item.ResetSort();
            }
        });
    }

	AfterRender() {}

	SetData(deduplications: GridDataModel, entityName: string, deduplicationInstance, restoreSortState?: boolean) {
		if (!deduplications) return;
        const headerColumns = [];
		this._rows([]);

		_.each(deduplications.Columns, (deduplicationHeader: any, i: number) => {
			if (i !== 1) {
				const headerName = i === 0 && '%' || deduplicationHeader.FieldMetadata.NameTranslated || deduplicationHeader.FieldMetadata.Name;
                const headerColumn = new DeduplicationHeader(deduplicationHeader, headerName);
                const oldHeader = this._headerColumns()[i];

                if (oldHeader) {
                    if (restoreSortState) {
                        headerColumn.SortOrder = oldHeader.SortOrder;
                    }
                }

                headerColumn.On(EVENTS.SORT, this, (eventArgs: any) => {
                    this.ResetSort(eventArgs.source);
                    var sortColumns = [];
                    _.each(this.Columns, (column) => {
                        if (column.IsEnableSort) {
                            sortColumns.push(column.GetSortModel());
                        }
                    });
                    this.Trigger(EVENTS.SORT, { SortColumns: sortColumns });
                });

                headerColumns.push(headerColumn);
			}
		});

        headerColumns.push(new DeduplicationHeader(new GridColumnModel(), ''));

        this._headerColumns(headerColumns);

		_.each(deduplications.Rows, (deduplication) => {
			const row = new DeduplicationRow(deduplication, true, entityName, deduplicationInstance);
			this._rows.push(row);
		});
	}

	GetSelectionType(leftRecordRowCell): string {
		if (leftRecordRowCell.Type === 'MultiSelect' || leftRecordRowCell.Type === 'Memo') {
			return 'merge';
		}

		const fieldName = leftRecordRowCell.DefaultFieldName;

		if (fieldName === 'CHANGEDATE' || fieldName === 'CHANGEDBY') {
			return 'notApplicable';
		}

		if (fieldName === 'CREATEDATE' || fieldName === 'CREATEDBY') {
			return 'rightSide';
		}

		return 'leftSide';
	}


	SetPopUpData(deduplications: any, entityName: string, deduplicationInstance: any, entityId: number, kSeq: number) {
		if (!deduplications) return;

		this._entityId = entityId;
		this._kSeq = kSeq;

		this._rows([]);
		this._headerColumns([]);
		const headers: Array<string> = [entityName, 'Record 1', 'Action', 'Record 2'];

		_.each(headers, (deduplicationHeader: string) => {
			this._headerColumns.push(new DeduplicationHeader(new GridColumnModel(), deduplicationHeader));
		});

		_.each(deduplications.LeftRecord.Data, (leftRecordRowCell: any, i) => {
			const selectionType = this.GetSelectionType(leftRecordRowCell);
			const rowToInject = {
				Data: [
					{
						FieldId: leftRecordRowCell.FieldId,
						DisplayValue: leftRecordRowCell.FieldName
					},
					{
						FieldId: leftRecordRowCell.FieldId,
						DisplayValue: this.FormatValue(leftRecordRowCell),
						IsSelected: selectionType === 'leftSide' || selectionType === 'merge',
                        Type: leftRecordRowCell.Type
					},
					{
						DisplayValue: 'Action'
					},
					{
						FieldId: deduplications.RightRecord.Data[i].FieldId,
						DisplayValue: this.FormatValue(deduplications.RightRecord.Data[i]),
						IsSelected: selectionType === 'rightSide' || selectionType === 'merge',
                        Type: leftRecordRowCell.Type
					}
				],
				IsDisabled: leftRecordRowCell.IsSystem,
				IsMergeEnabled: leftRecordRowCell.Type === 'MultiSelect' || leftRecordRowCell.Type === 'Text' || leftRecordRowCell.Type === 'Memo',
				SelectionType: selectionType,
				DeduplicationInstance: deduplicationInstance,
				LeftRecordId: deduplications.LeftRecord.Id,
				RightRecordId: deduplications.RightRecord.Id
			};
			var row = new DeduplicationRow(rowToInject);
			this._rows.push(row);
		});
	}

	SetPopUpDataFromSearch(deduplications: any, entityName: string, deduplicationInstance: any, entityId: number) {
		if (!deduplications) return;

		this._entityId = entityId;

		this._rows([]);
		this._headerColumns([]);
		const headers: Array<string> = [entityName, 'Record 1', 'Action', 'Record 2'];
		_.each(headers, (deduplicationHeader: string) => {
			this._headerColumns.push(new DeduplicationHeader(new GridColumnModel(), deduplicationHeader));
		});

		_.each(deduplications.LeftRecord.Data, (leftRecordRowCell: any, i) => {
			const selectionType = this.GetSelectionType(leftRecordRowCell);
			const rowToInject = {
				Data: [
					{
						FieldId: leftRecordRowCell.FieldId,
						DisplayValue: leftRecordRowCell.FieldName
					},
					{
						FieldId: leftRecordRowCell.FieldId,
						DisplayValue: this.FormatValue(leftRecordRowCell),
						IsSelected: selectionType === 'leftSide' || selectionType === 'merge',
						Type: leftRecordRowCell.Type
					},
					{
						DisplayValue: 'Action'
					},
					{
						FieldId: deduplications.RightRecord.Data[i].FieldId,
						DisplayValue: this.FormatValue(deduplications.RightRecord.Data[i]),
						IsSelected: selectionType === 'rightSide' || selectionType === 'merge',
						Type: leftRecordRowCell.Type
					}
				],
				IsDisabled: leftRecordRowCell.IsSystem,
				IsMergeEnabled: leftRecordRowCell.Type === 'MultiSelect' || leftRecordRowCell.Type === 'Text' || leftRecordRowCell.Type === 'Memo',
				SelectionType: selectionType,
				DeduplicationInstance: deduplicationInstance,
				LeftRecordId: deduplications.LeftRecord.Id,
				RightRecordId: deduplications.RightRecord.Id
			};
			var row = new DeduplicationRow(rowToInject);
			this._rows.push(row);
		});
	}

	FormatValue(record: any): string {
		if (record.Type == CONTROL_TYPES.TimeSpan && record.Value) {
			return moment(record.Value).format(DATE_FORMATS.LONG_TIME.Format);
		}
		return record.Value;
	}


	@enumerable get Rows(): KnockoutObservableArray<DeduplicationRow> {
		return this._rows;
	}

	@enumerable get Headers(): KnockoutObservableArray<DeduplicationHeader> {
		return this._headerColumns;
	}

	SaveChanges(){
		this.Trigger(EVENTS.SAVE_CHANGES);
	}

	Cancel() {
		this._modal.Close();
	}

	ResetRows() {
		this._rows([]);
	}

	ShowModal() {
		if (this._modal) {
			this._modal.Show();
			BlockUI.Block({ Target: this._modal.Wrapper });
		}
	}

	RenderModal() {

		this._modal = new Modal({
			addClass: 'deduplication-modal jBox-padding-20px'
		});

		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);

		this.ShowModal();

	}

	GetTemplateName() {
		return 'Core/ProfilePage/Deduplication/DeduplicationEditor/Templates/DeduplicationGridPopUp';
	}
}