import { deserialize, deserializeAs, serialize, serializeAs } from 'libs/cerialize';
import { QueryColumnModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryColumnModel';
import { EntityMetadataModel } from 'Core/Controls/Grid/Models/GridDataModel/EntityMetadataModel';
import { CanvasPositionModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/CanvasPositionModel';
import { Guid } from 'Core/Common/Guid';

export class QueryEntityModel {
	constructor() {
		this.Columns = [];
		this.CanvasPosition = new CanvasPositionModel();
		this.Guid = Guid.NewGuid();
		this.IsIterator = false;
		this.Index = 0;
	}

	@serializeAs(QueryColumnModel)
	@deserializeAs(QueryColumnModel)
	Columns: Array<QueryColumnModel>;

	@serialize
	@deserialize
	Guid: string;

	@serialize
	@deserialize
	Id: number;

	@serializeAs(EntityMetadataModel)
	@deserializeAs(EntityMetadataModel)
	Metadata: EntityMetadataModel;

	@serializeAs(CanvasPositionModel)
	@deserializeAs(CanvasPositionModel)
	CanvasPosition: CanvasPositionModel;

	@serialize
	@deserialize
	IsIterator: boolean;

	@serialize
	@deserialize
	IsGridSubject: boolean;

	@serialize
	@deserialize
	Index: number;
}