import {deserialize, deserializeAs, Deserialize} from 'libs/cerialize';
import {DropFileModel} from 'Core/Controls/Drop/Models/DropFileModel';



export class DropDataModel {
	constructor() {
		this.DropFileModels = [];
	}
	@deserialize
	EntityRecordId: number;

	@deserialize
	DocumentRecordId: number;

	@deserialize
	EntityId: number;

	@deserialize
	DocumentEntityId: number;

	@deserialize
	DocumentSubEntityId: number;

	@deserialize
	ControlId: number;

	@deserialize
	Name: string;

	@deserialize
	FType: number;

	@deserialize
	Extension: string;

	@deserialize
	FileError: string = "";

	@deserialize
	LoadingProgress: number = 0;
	
	@deserializeAs(DropFileModel)
	DropFileModels: Array<DropFileModel>;
}