import {deserialize, deserializeAs} from 'libs/cerialize';
import {FieldMetadataModel} from 'Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel';
import {EntityMetadataModel} from 'Core/Controls/Grid/Models/GridDataModel/EntityMetadataModel';
import {SortOrder} from 'Core/Controls/Grid/BaseGrid/GridColumn/BaseColumn';

export class GridColumnModel {
	constructor() {
		this.SortDirection = Number(SortOrder.Both);
	}

	@deserialize
	GroupTitle: string;

	@deserialize
	Title: string;

	@deserialize
	Alias: string;

	@deserialize
	IsWrapped: boolean;

	@deserialize
	IsSortDescription: boolean

	@deserialize
	IsGroup: boolean;

	@deserialize
	IsEnableEdit: boolean;

	@deserialize
	DisplayPriority: number;

	@deserialize
	DisplayOrder: number;

	@deserialize
	IsEnableSort: boolean;

	@deserialize
	SortDirection: number;

	@deserializeAs(FieldMetadataModel)
	FieldMetadata: FieldMetadataModel;

	@deserializeAs(EntityMetadataModel)
	EntityMetadata: EntityMetadataModel;

	@deserialize
	QueryColumnGuid: string;

	width?: number; // max text width of column

	additionalWidth: number; // additional width for calculation elements inside header column

	styleWidth: any; // calculated style width based on additionalWidth and width properties

	@deserialize
	IsEnableFastFilter: boolean;
} 