//Libs
import * as ko from "knockout";
import * as _ from "underscore";
import * as moment from "moment";
import {RecordStore} from "Core/Common/Stores/RecordStore";

//Models
import {
    Subject,
    AllowedEntity,
    LookupField,
    LookupValue
} from "Core/Components/Controls/TimeWriting/Models/View/DayDataViewModel";

//Templates
import RowEditorTemplate from "Core/Components/Controls/TimeWriting/Templates/RowEditorTemplate.html";

import {LABELS} from "Core/Components/Translation/Locales";

import {TimeStatus} from "Core/Components/Controls/TimeWriting/Constants/TimeStatus";
import {FormatConverter} from "FormatEditor/FormatConverter";
import {DATE_FORMATS} from "Core/Constants/DateTimeFormats";
import {FIELD_TYPES} from "Core/Constant";
import {FieldFormat} from "Core/Common/FieldFormat";
import {SearchScreen} from "Core/Screens/SearchScreen/SearchScreen";
import {TranslationFieldEditor} from "../../../TranslationFieldEditor/TranslationFieldEditor";
import {ITranslationValue} from "../../../TranslationFieldEditor/ITranslationValue";
import {TranslationManager} from "../../../Translation/TranslationManager";
import {TranslationItem} from "../../../TranslationFieldEditor/TranslationItem";
import {JBoxDropDown} from "Core/Components/JBoxDropdown/DropDown";
import DropDownContentTemplate from 'Core/Controls/MultiSelect/Templates/DropDownContent.html';
import {ZIndexManager} from "Core/Common/ZIndexManager";
import {ItemModel} from 'Core/Controls/MultiSelect/Models/MultiSelectListModel';
import {Notifier} from 'Core/Common/Notifier';
import {SERVER_REQUEST_ERRORS} from "Core/Common/Enums/ServerRequestErrors";
import {BlockUI} from "Core/Common/BlockUi";

export class RowEditor {
    private _guid: string;
    public _day: string;
    private _lookupFields: LookupField[];
    private _cellEditors: CellEditor[];
    private _state: State;

    constructor(guid: string, day: string, lookupFields: LookupField[], ...cellEditors: CellEditor[]) {
        this._guid = guid;
        if (day) {
            this._day = day;
        }
        this._lookupFields = lookupFields;
        this._cellEditors = cellEditors;
    }

    get Guid() {
        return this._guid;
    }

    get IsEditState() {
        return this._state === State.Edit;
    }

    get IsNewState() {
        return this._state === State.New;
    }

    SetState(state: State) {
        this._state = state;
        this._cellEditors.forEach(editor => editor.SetState(state));
    }

    CancelChanges() {
        this.GetValueCellEditors()
            .forEach((editor: ValueCellEditor<any>) => editor.RollbackChanges());
    }

    SaveChanges() {
        this.GetValueCellEditors()
            .forEach((editor: ValueCellEditor<any>) => editor.CommitChanges());
    }

    GetTemplate() {
        return RowEditorTemplate;
    }

    Validate() {
        const validationEditors = this.GetValidationCellEditors();

        if (!_.any(validationEditors)) {
            return true;
        }

        const validationResults = validationEditors.map((editor: ValidationCellEditor<any>) => editor.Valid());
        const allEditorsAreValid = _.all(validationResults, result => result);

        return allEditorsAreValid;
    }

    private GetValueCellEditors() {
        return this._cellEditors
            .filter(editor => (editor instanceof ValueCellEditor));
    }

    private GetValidationCellEditors() {
        return this._cellEditors
            .filter(editor => (editor instanceof ValidationCellEditor));
    }
}

export class WeekRowEditor {
    private _guid: string;
    public _day: string;
    private _lookupFields: LookupField[];
    private _cellEditors: CellEditor[];

    constructor(guid: string, day: string, lookupFields: LookupField[], ...cellEditors: CellEditor[]) {
        this._guid = guid;
        if (day) {
            this._day = day;
        }
        this._lookupFields = lookupFields;
        this._cellEditors = cellEditors;
    }

    get Guid() {
        return this._guid;
    }

    get Day() {
        return this._day;
    }

    GetTemplate() {
        return RowEditorTemplate;
    }
}

export interface ICell {
    Name: string;
    Label;
    ViewTemplate: any;
    Visible: KnockoutObservable<boolean>;
    ClassName: string;
    Size?: any;
}

export interface IDynamicCell extends ICell {
    EditTemplate: any;
    Size?: any;
}

export interface IValueCell<T> extends IDynamicCell {
    Value: KnockoutObservable<T>;
    Editable: boolean;
}

interface IValidationRule<T> {
    Validate: (value: T) => boolean;
    Message?: string;
}

interface IValidated<T> {
    Valid: KnockoutObservable<boolean>;
    Rule: IValidationRule<T>;
}

export interface IValidationCell<T> extends IValueCell<T> {
    RequiredRule?: IValidationRule<T>;
    MinLengthRule?: IValidationRule<T>;
    MaxLenghtRule?: IValidationRule<T>;
}

interface IDropdownOption {
    FieldValue: number;
    DisplayValue: string;
}

interface ITextCell extends IValidationCell<string> {
    Translations: KnockoutObservableArray<TranslationItem>;
    IsTranslate: boolean;
}

export interface IDropdownCell<T extends IDropdownOption> extends IValidationCell<T> {
    Options: KnockoutObservableArray<T>;
    RequiresSearch: boolean;
    ValTableId: number;
    ValFieldId: number;
    ValFieldTypeName: string;
    ValFieldFormatName: string;
    ValFieldSize: number;
}

interface IDecimalCell extends IValidationCell<number> {
    FormatName: string;
}

interface IDateCell extends IValidationCell<Date> {
    FormatName: string;
}

export interface ISubjectCell extends IValidationCell<Subject> {
    IsNewState: KnockoutObservable<boolean>;
    AllowedEntities: AllowedEntity[];
    OnEntityClick: (entity: AllowedEntity) => void;
    OnEntityIconClick: () => void;
    OnSubjectClick: () => void;
}

export interface IActionsCell extends IDynamicCell {
    Accepted: boolean;
    OnAcceptReservation: (guid: string) => void;
    OnEditReservation: (guid: string) => void;
    OnDeleteReservation: (guid: string) => void;
    OnSaveReservationChanges: (guid: string) => void;
    OnCancelReservationChanges: (guid: string) => void;
    TimeStatus: string;
}


export class CellEditor {
    protected _cell: ICell;
    protected _state: KnockoutObservable<State>;
    protected _template: KnockoutObservable<any>;

    constructor(cell: ICell) {
        this._cell = cell;
        this._state = ko.observable(State.Read);
        this._template = ko.observable(this._cell.ViewTemplate);
    }

    GetState() {
        return this._state();
    }

    SetState(state: State) {
        this._state(state);
    }
}

export class DynamicCellEditor extends CellEditor {
    protected _cell: IDynamicCell;
    protected _labels = LABELS;
    private _timeStatus = TimeStatus;

    constructor(cell: IDynamicCell) {
        super(cell);
        this._state.subscribe(() => this._template(this.GetTemplate()), this);

        if (cell.Size) {
            this._cell.Size = cell.Size;
        }
    }

    protected GetTemplate() {
        switch (this._state()) {
            case State.Read:
                return this._cell.ViewTemplate;
            case State.Edit:
            case State.New:
                return this._cell.EditTemplate;
            default:
                return this._cell.ViewTemplate;
        }
    }
}

export class ValueCellEditor<T> extends DynamicCellEditor {
    protected _cell: IValueCell<T>;
    protected _originalValue: KnockoutObservable<T>;

    constructor(cell: IValueCell<T>) {
        super(cell);

        this._originalValue = ko.observable(cell.Value());
    }

    RollbackChanges() {
        if (this._cell.Editable) {
            this._cell.Value(this._originalValue());
        }
    }

    CommitChanges() {
        if (this._cell.Editable) {
            this._originalValue(this._cell.Value());
        }
    }

    protected GetTemplate() {
        switch (this._state()) {
            case State.Read:
                return this._cell.ViewTemplate;
            case State.Edit:
            case State.New:
                return this._cell.Editable ? this._cell.EditTemplate : this._cell.ViewTemplate;
            default:
                return this._cell.ViewTemplate;
        }
    }
}

export class ValidationCellEditor<T> extends ValueCellEditor<T> {
    protected _requiredValidation: IValidated<T>;
    protected _minLengthValidation: IValidated<T>;
    protected _maxLengthValidation: IValidated<T>;
    protected _startValue: string;

    protected _valid: KnockoutComputed<boolean>;

    constructor(protected _cell: IValidationCell<T>) {
        super(_cell);

        const requiredRule = _cell.RequiredRule || {Validate: () => true};
        const minLengthRule = _cell.MinLengthRule || {Validate: () => true};
        const maxLengthRule = _cell.MaxLenghtRule || {Validate: () => true};

        this._requiredValidation = {
            Valid: ko.observable(true),
            Rule: requiredRule
        };
        this._minLengthValidation = {
            Valid: ko.observable(true),
            Rule: minLengthRule
        };
        this._maxLengthValidation = {
            Valid: ko.observable(true),
            Rule: maxLengthRule
        };

        this._valid = ko.computed(() => this._requiredValidation.Valid() && this._minLengthValidation.Valid() && this._maxLengthValidation.Valid(), this);

        this._cell.Value.subscribe(() => this.Validate());
    }

    Valid() {
        this.Validate();
        return this._valid();
    }

    PreventLetters(d, e) {
        const code = e.keyCode;
        const character = String.fromCharCode(code);
        const digitsOnly = /[1234567890.,:]/g;
        if (character.match(digitsOnly)) {
            return true;
        } else {
            return false;
        }
    }

    private Validate() {
        const validations = [
            this._requiredValidation,
            this._minLengthValidation,
            this._maxLengthValidation
        ];

        const value = this._cell.Value();

        validations.forEach(validation => validation.Valid(true));
        const failedValidation = _.find(validations, (validation: IValidated<T>) => !validation.Rule.Validate(value));

        if (failedValidation) {
            failedValidation.Valid(false);
        }
    }
}

export class TextCellEditor extends ValidationCellEditor<string> {
    private _translationFieldEditor: TranslationFieldEditor;
    private _translations: KnockoutObservableArray<TranslationItem>;
    private _isTranslate: boolean;
    private _displayValue: KnockoutObservable<string>;

    constructor(cell: ITextCell) {
        super(cell);
        this._translations = cell.Translations;
        this._isTranslate = cell.IsTranslate;
        this._displayValue = ko.observable(cell.Value());

        if (this._isTranslate) {

            if (this._translations().length === 0) {
                this._translations(TranslationManager.Instance.Languages.filter(language => language.Id > 0).map(language => new TranslationItem(language)));
            }

            this._translationFieldEditor = new TranslationFieldEditor();
            this._translationFieldEditor
                .On("TranslationChanged", this, eventArgs => this.SetTranslation(eventArgs.data))
                .On('TranslationSelected', this, eventArgs => this.ChangeTranslation(eventArgs.data));
            this._translationFieldEditor.LoadTranslationItems();

            const translationValues: ITranslationValue[] = this._translations().map(item => {
                return {
                    LanguageId: item.Language.Id,
                    Value: item.Value()
                }
            });

            this._translationFieldEditor.SetTranslations(translationValues, this._cell.Value());
            const activeTranslation = TranslationManager.Instance.GetTranslation(this._cell.Value(), translationValues);
            this._translationFieldEditor.SetActiveTranslation(activeTranslation.Language.Id);
            this._displayValue(activeTranslation.TranslatedValue);

            this._displayValue.subscribe(newValue => this._translationFieldEditor.SetValue(newValue));
        } else {
            this._displayValue.subscribe(value => this._cell.Value(value));
        }
    }

    ToggleTranslations() {
        this._translationFieldEditor.Toggle();
    }

    private SetTranslation(translation: TranslationItem) {
        if (translation.Language.Id !== 0) {
            const fieldTranslation = _.find(this._translations(), item => item.Language.Id === translation.Language.Id);
            fieldTranslation.Value(translation.Value());
        } else {
            this._cell.Value(translation.Value());
        }
    }

    private ChangeTranslation(translation: ITranslationValue) {
        if (translation.LanguageId === 0) {
            this._cell.Value(translation.Value);
        }
        this._displayValue(translation.Value);
    }
}

export class DropdownCellEditor<T extends IDropdownOption> extends ValidationCellEditor<T> {
    protected _cell: IDropdownCell<T>;
    private _currentValue: KnockoutObservable<T>;

    constructor(cell: IDropdownCell<T>) {
        super(cell);

        this._cell.Value(cell.Value());
        this._currentValue = ko.observable(null);
        this._currentValue.subscribe(value => this._cell.Value(value));
    }

    FormatValue(value: string) {
        if (!value) {
            return LABELS.EMPTY_VALUE;
        }

        if (_.contains([FIELD_TYPES.Decimal, FIELD_TYPES.Integer], this._cell.ValFieldTypeName)) {
            return FormatConverter.LocalizeDecimalOrInteger(
                this._cell.ValFieldFormatName === FieldFormat.PERCENTAGE
                    ? (Number(value) * 100).toFixed(this._cell.ValFieldSize < 2 ? 0 : this._cell.ValFieldSize - 2)
                    : value
            );
        }

        return value;
    }

    FormatSelectValue(dropdownOption: IDropdownOption) {
        return this.FormatValue(dropdownOption.DisplayValue);
    }

    SetState(state: State) {
        switch (state) {
            case State.Read:
                this.SetReadState(this._cell.Value());
                break;

            case State.New:
                this.SetNewState();
                break;

            case State.Edit:
                this.SetEditState(this._cell.Value());
                break;
        }

        super.SetState(state);
    }

    ShowSearchScreen() {
        const searchScreen = new SearchScreen({
            ButtonAdd: false,
            EntityId: this._cell.ValTableId,
            SearchTerm: ''
        });

        searchScreen.On('RECORD_SELECTED', this, eventArgs => {
            if (this._cell.ValFieldId) {
                this.ReplaceSelectedValue(<number>this._cell.ValTableId,
                    <number>this._cell.ValFieldId,
                    <number>eventArgs.data.RecordId);
            } else {
                const selectedRecord = {
                    FieldValue: <number>eventArgs.data.RecordId,
                    DisplayValue: <string>eventArgs.data.Name
                };

                this._cell.Value(<any>selectedRecord);
            }
        });

        searchScreen.Show();
    }

    protected ReplaceSelectedValue(tableId: number, fieldId: number, recordId: number) {
        BlockUI.Block();

        RecordStore.GetRecord({TableId: tableId, RecordId: recordId})
            .always(() => BlockUI.Unblock())
            .then((record: any) => {

                const selected = _.find(record.Fields, (item: any) => item.FieldId === fieldId);

                if (selected) {
                    const selectedRecord = {
                        FieldValue: recordId,
                        DisplayValue: selected.FieldValue
                    };

                    this._cell.Value(<any>selectedRecord);
                }
            })
            .fail((error) => {
                if (error.requestError === SERVER_REQUEST_ERRORS.NOT_FOUND) {
                    new Notifier().Warning('You can not see created record');
                } else if (error.requestError === SERVER_REQUEST_ERRORS.INTERNAL) {
                    new Notifier().Failed('Error applying created record');
                }

            });
    }

    private FindOption(id: number) {
        return _.find(this._cell.Options(), option => option.FieldValue === id);
    }

    private SetReadState(requestedValue: LookupValue) {
        this._currentValue(<any>requestedValue);
        this._cell.Value(<any>requestedValue);
    }

    private SetNewState() {
        const value = !this._cell.RequiresSearch && _.first(this._cell.Options()) || null;
        this._currentValue(value);
    }

    private SetEditState(requestedValue: LookupValue) {
        if (!this._cell.RequiresSearch) {
            const value = requestedValue && this.FindOption(requestedValue.FieldValue) || _.first(this._cell.Options());
            this._currentValue(value);
        }
    }
}

export class MultiSelectCellEditor<T extends IDropdownOption> extends ValidationCellEditor<T> {
    Items: KnockoutObservableArray<any>;
    SelectedItems: KnockoutComputed<Array<ItemModel>>;
    protected _cell: IDropdownCell<T>;
    private _currentValue: KnockoutObservable<T>;
    private _showEmptyValue: KnockoutObservable<boolean>;

    private _tooltip: JBoxDropDown;

    constructor(cell: IDropdownCell<T>) {
        super(cell);

        this.Items = ko.observableArray([]);
        this.SelectedItems = ko.computed(() => this.Items().filter(item => item.IsSelected), this);

        const items = cell.Value() && cell.Value().FieldValue != null ? cell.Value().FieldValue.toString().split(";") : [];

        if (!this._cell.RequiresSearch) {
            this.Items = ko.observableArray(_.map(this._cell.Options().filter(option => option.FieldValue != 0), option => {
                return {
                    RecordId: option.FieldValue,
                    Label: option.DisplayValue,
                    IsSelected: ko.observable(items.indexOf(option.FieldValue.toString()) !== -1)
                }
            }));
        } else {
            if (items.length > 0) {
                const labels = cell.Value().DisplayValue != null ? cell.Value().DisplayValue.toString().split(";") : [];
                this.Items = ko.observableArray(items.map(function (item, i) {
                    return {RecordId: item, Label: labels[i], IsSelected: ko.observable(true)}
                }));
            }
        }

        this._cell.Value(cell.Value());
        this._currentValue = ko.observable(null);
        this._currentValue.subscribe(value => this._cell.Value(value));
        this._showEmptyValue = ko.observable(true);
    }

    FormatValue(value: string) {
        if (!value) {
            return LABELS.EMPTY_VALUE;
        }

        if (_.contains([FIELD_TYPES.Decimal, FIELD_TYPES.Integer], this._cell.ValFieldTypeName)) {
            const splitValues = value.split(';');

            const formattedValues = splitValues.map(singleValue =>
                FormatConverter.LocalizeDecimalOrInteger(
                    this._cell.ValFieldFormatName === FieldFormat.PERCENTAGE
                        ? (Number(singleValue) * 100).toFixed(
                        this._cell.ValFieldSize < 2 ? 0 : this._cell.ValFieldSize - 2
                        )
                        : singleValue
                )
            );

            return formattedValues.join(';');
        }

        return value;
    }

    FormatSingleValue(value: string) {
        if (!value) {
            return LABELS.EMPTY_VALUE;
        }

        if (_.contains([FIELD_TYPES.Decimal, FIELD_TYPES.Integer], this._cell.ValFieldTypeName)) {
            return FormatConverter.LocalizeDecimalOrInteger(
                this._cell.ValFieldFormatName === FieldFormat.PERCENTAGE
                    ? (Number(value) * 100).toFixed(this._cell.ValFieldSize < 2 ? 0 : this._cell.ValFieldSize - 2)
                    : value
            );
        }

        return value;
    }

    SetState(state: State) {
        switch (state) {
            case State.Read:
                this.SetReadState(this._cell.Value());
                break;

            case State.New:
                this.SetNewState();
                break;

            case State.Edit:
                this.SetEditState(this._cell.Value());
                break;
        }

        const items = this.Items().filter(item => item.IsSelected());
        this.CheckEmptyMultiSelect(items);
        super.SetState(state);
    }

    ShowSearchScreen(model, evt: MouseEvent) {
        if (this._cell.RequiresSearch) {

            const searchScreen = new SearchScreen({
                ButtonAdd: false,
                EntityId: this._cell.ValTableId,
                SearchTerm: ''
            });

            searchScreen.On('RECORD_SELECTED', this, eventArgs => {
                const selectedTtem = this.Items().find(item => item.RecordId == <number>eventArgs.data.RecordId);
                if (!selectedTtem) {
                    this.ReplaceSelectedValue(this._cell.ValTableId, <number>this._cell.ValFieldId, eventArgs.data.RecordId, <string>eventArgs.data.Name);
                } else {
                    selectedTtem.IsSelected(true);
                }
            });

            searchScreen.Show();
        } else {
            if (!this._tooltip) {
                const target: any = evt.currentTarget;
                this._tooltip = new JBoxDropDown({
                    bindComponent: this,
                    target: target,
                    otherOptions: {
                        addClass: "multi-select-dropdown",
                        attach: undefined,
                        pointer: "",
                        isolateScroll: true,
                        content: DropDownContentTemplate as any,
                        position: {
                            x: "right",
                            y: "bottom"
                        },
                        height: 'auto',
                        zIndex: ZIndexManager.Instance.NextValue,
                        maxHeight: 200
                    },
                    bindTarget: target,
                    bindOnCreate: true
                });
            }
            this._tooltip.Toggle();
        }
    }

    protected ReplaceSelectedValue(tableId: number, fieldId: number, recordId: number, name: string) {
        RecordStore.GetRecord({TableId: tableId, RecordId: recordId})
            .then((record: any) => {

                const selected = _.find(record.Fields, (item: any) => item.FieldId === fieldId);

                this.Items.push({
                    RecordId: recordId,
                    Label: selected ? selected.FieldValue : name,
                    IsSelected: ko.observable(true)
                });
                const items = this.Items().filter(item => item.IsSelected);
                const selectedRecord = {
                    FieldValue: <string>items.map(a => a.RecordId).join(";"),
                    DisplayValue: <string>items.map(a => a.Label).join(";")
                };

                this.CheckEmptyMultiSelect(items);
                this._cell.Value(<any>selectedRecord);
            })
            .fail((error) => {
                if (error.requestError === SERVER_REQUEST_ERRORS.NOT_FOUND) {
                    new Notifier().Warning('You can not see created record');
                } else if (error.requestError === SERVER_REQUEST_ERRORS.INTERNAL) {
                    new Notifier().Failed('Error applying created record');
                }

            });
    }

    private FindOption(id: number) {
        return _.find(this._cell.Options(), option => option.FieldValue == id);
    }

    private SetReadState(requestedValue: LookupValue) {
        this._currentValue(<any>requestedValue);
        this._cell.Value(<any>requestedValue);
    }

    private SetNewState() {
    }

    private SetEditState(requestedValue: LookupValue) {
    }

    SelectItem(item: ItemModel) {
        item.IsSelected(true);
        const items = this.Items().filter(item => item.IsSelected());
        const selectedRecord = {
            FieldValue: <string>items.map(a => a.RecordId).join(";"),
            DisplayValue: <string>items.map(a => a.Label).join(";")
        };

        this.CheckEmptyMultiSelect(items);
        this._cell.Value(<any>selectedRecord);
    }

    RemoveItem(item: ItemModel) {
        item.IsSelected(false);
        const items = this.Items().filter(item => item.IsSelected());
        const selectedRecord = {
            FieldValue: <string>items.map(a => a.RecordId).join(";"),
            DisplayValue: <string>items.map(a => a.Label).join(";")
        };

        this.CheckEmptyMultiSelect(items);
        this._cell.Value(<any>selectedRecord);
    }

    CheckEmptyMultiSelect(selectedItems: Array<ItemModel>) {
        if (selectedItems.length === 0) {
            this._showEmptyValue(true);
        } else {
            this._showEmptyValue(false);
        }
    }
}

export class IntegerCellEditor extends ValidationCellEditor<number> {
    constructor(cell: IValidationCell<number>) {
        super(cell);
    }

    FormatValue(value: string) {
        return value && FormatConverter.LocalizeDecimalOrInteger(value);
    }
}

export class DecimalCellEditor extends ValidationCellEditor<number> {
    protected _cell: IDecimalCell;

    constructor(cell: IDecimalCell) {
        super(cell);
    }

    FormatValue(value: string) {
        return (
            value &&
            FormatConverter.LocalizeDecimalOrInteger(
                this._cell.FormatName === FieldFormat.PERCENTAGE
                    ? (Number(value) * 100).toFixed(this._cell.Size < 2 ? 0 : this._cell.Size - 2)
                    : value
            )
        );
    }
}

export class DateTimeCellEditor extends ValidationCellEditor<Date> {
    protected _cell: IDateCell;

    constructor(cell: IDateCell) {
        super(cell);
    }

    GetLocale() {
        return moment.locale();
    }

    GetFormat() {
        const format = FormatConverter.GetDateFormatFromFieldModel({
            FieldTypeName: FIELD_TYPES.DateTime,
            FormatName: this._cell.FormatName
        });
        return format || DATE_FORMATS.DATE_TIME['MomentFormat'];
    }

    DateChanged(event) {
        this._cell.Value(event || null);
    }
}

export class DateCellEditor extends ValidationCellEditor<Date> {
    protected _cell: IDateCell;

    constructor(cell: IDateCell) {
        super(cell);
    }

    GetLocale() {
        return moment.locale();
    }

    GetFormat() {
        const format = FormatConverter.GetDateFormatFromFieldModel({
            FieldTypeName: FIELD_TYPES.Date,
            FormatName: this._cell.FormatName
        });
        return format || DATE_FORMATS.SHORT_DATE['MomentFormat'];
    }

    DateChanged(event) {
        this._cell.Value(event || null);
    }
}

export class TimeCellEditor extends ValidationCellEditor<string> {
    constructor(cell: IValidationCell<string>) {
        super(cell);
    }

    GetLocale() {
        return moment.locale();
    }

    GetFormat() {
        return DATE_FORMATS.TIME['MomentFormat'];
    }

    DateChanged(event) {
        const newDate = event ? moment(event).format(this.GetFormat()) : null;
        this._cell.Value(newDate);
    }
}

export class SubjectCellEditor extends ValidationCellEditor<Subject> {
    constructor(cell: ISubjectCell) {
        super(cell);
    }
}

export class DescriptionCellEditor extends ValidationCellEditor<string> {
    constructor(protected _cell: IValidationCell<string>) {
        super(_cell);
    }
}

export class ActionsCellEditor extends DynamicCellEditor {
    constructor(cell: IActionsCell) {
        super(cell);
    }
}

export enum State {
    Read,
    Edit,
    New
}