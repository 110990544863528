import {ProductPart} from "../Models/ProductPart";
import {ProductGroup} from "../Models/ProductGroup";
import {ProductPrice} from "../Models/ProductPrice";

export const ConfigurationPageEvents = {
    RootGroupConversionsDropdownSynchronization: 'RootGroupConversionsDropdownSynchronization',
    GroupConversionsDropdownSynchronization: 'GroupConversionsDropdownSynchronization',
    RootGroupConversionsDropdown: 'RootGroupConversionsDropdown',
    GroupConversionsDropdown: 'GroupConversionsDropdown',
    ReloadingConversionDropdowns: 'ReloadingConversionDropdowns',
    LoadConversionDropdownsFromRemovePriceItem: 'LoadConversionDropdownsFromRemovePriceItem',

    NotInitRootGroupExpanded: 'ConfigurationPageEvents_NotInitRootGroupExpanded',
    RootGroupExapanding: 'ConfigurationPageEvents_RootGroupExapanding',

    ProductPartSelecting: 'ConfigurationPageEvents_ProductPartSelecting',
    ProductPartSelected: 'ConfigurationPageEvents_ProductPartSelected',

    ProductPartUnSelecting: 'ConfigurationPageEvents_ProductPartUnSelecting',
    ProductPartUnSelected: 'ConfigurationPageEvents_ProductPartUnSelected',

    ProductPartAddingAsExtra: 'ConfigurationPageEvents_ProductPartAddingAsExtra',

    QuantityChanged: 'ConfigurationPageEvents_QuantityChanged',
    PriceChanged: 'ConfigurationPageEvents_PriceChanged',

    GroupActivating: 'ConfigurationPageEvents_GroupActivating',
    GroupActivated: 'ConfigurationPageEvents_GroupActivated',

    NotInitGroupActivated: 'ConfigurationPageEvents_NotInitGroupActivated',

    PropertyValueChanged: 'ConfigurationPageEvents_PropertyValueChanged',
    ProductPropertyValueChanged: 'ConfigurationPageEvents_ProductPropertyValueChanged',

	CustomFieldValueChanged: 'ConfigurationPageEvents_CustomFieldValueChanged',
	ProductCustomFieldValueChanged: 'ConfigurationPageEvents_ProductCustomFieldValueChanged',

    PriceCalculationRequested: 'ConfigurationPageEvents_PriceCalculationRequest',

    VariablesChanged: 'ConfigurationPageEvents_VariablesChanged',

    UnselectProduct: 'ConfigurationPageEvents_UnselectProduct',
    SelectProduct: 'ConfigurationPageEvents_SelectProduct',
    ToggleSelection: 'ConfigurationPageEvents_ToggleSelection',
    EvaluateCalculatedExpressionsOnUnselect: 'ConfigurationPageEvents_EvaluateCalculatedExpressionsOnUnselect',
    ProductRemovedFromPriceList: 'ConfigurationPageEvents_ProductRemovedFromPriceList',
    ProductNavigatedInPriceList: 'ConfigurationPageEvents_ProductNavigatedInPriceList',

    SaveConfigurationRequested: 'ConfigurationPageEvents_SaveConfigurationRequested',
    ConfigurationSaved: 'ConfigurationPageEvents_ConfigurationSaved',

    GroupProductsLoaded: 'ConfigurationPageEvents_GroupProductsLoaded',
    RootShowMore: 'ConfigurationPageEvents_RootShowMore',
    ShowMore: 'ConfigurationPageEvents_ShowMore',
    ShowingMore: 'ConfigurationPageEvents_ShowingMore',

    BeforeSelectingProductPartEvent: 'ConfigurationPageEvents_BeforeSelectingProductPartEvent',
    CheckAvailabilityToToggle: 'ConfigurationPageEvents_CheckAvailabilityToToggle',
    CheckSaveDisabilityByAction: 'ConfigurationPageEvents_CheckSaveDisabilityByAction',
    PropertyContentLoading: 'ConfigurationPageEvents_PropertyContentLoading',
	PropertyContentLoaded: 'ConfigurationPageEvents_PropertyContentLoaded',

	ZoomButtonClicked: 'ConfigurationPageEvents_ZoomButtonClicked'
};

export class ProductPartSelectingEventArgs {
    constructor(public OldProduct: ProductPart, public NewProduct: ProductPart) {
    }
}

export class GroupActivatingEventArgs {
    constructor(public OldGroup: ProductGroup, public NewGroup: ProductGroup) {
    }
}

export class SelectProductPartEventArgs {
    constructor(public Id: number, public GroupId: number, public GroupName: string, public Path: number[], public KSeq: number, public KSeqGuid: string, public isRemoveFromPriceItem?: boolean) {
    }
}

export class ConversionsDropdownArgs {
    constructor(public args: any) {
    }
}

export class PriceProductNavigatedEventArgs {
    constructor(public Id: number, public GroupId: number, public GroupName: string, public RootGroupId: number, public RootGroupName: string, public Path: number[], public KSeq: number, public KSeqGuid: string, public OnlyOneLevel: boolean, public ActiveLevel: string) {
    }
}

export class GroupProductsLoadedEventArgs {
    constructor(public Products: ProductPart[]) {
    }
}