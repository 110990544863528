import {SpreadsheetCellData} from './SpreadsheetCellData';
import {SpreadsheetDimensionDescriptor} from './SpreadsheetDimensionDescriptor';
import {SpreadsheetDescriptor} from './SpreadsheetDescriptor';
import {SpreadsheetPageDescriptor} from './SpreadsheetPageDescriptor';

export class SpreadsheetPageData {
    constructor(public Cells: SpreadsheetCellData[], public Dimensions: SpreadsheetDimensionDescriptor[], public Descriptor: SpreadsheetPageDescriptor) {
    }

    GetCell(name: string): SpreadsheetCellData {
        return this.Cells.find(c => c.cell === name);
    }

    static GetDefaultPageId() {
        return '<No id>';
    }

    static GetDefaultPageName() {
        return '<No name>';
    }

    static Empty(spreadsheetDescriptor: SpreadsheetDescriptor) {
        const pageDescriptor = new SpreadsheetPageDescriptor(this.GetDefaultPageId(), this.GetDefaultPageName());
        return new SpreadsheetPageData([], spreadsheetDescriptor.Dimensions, pageDescriptor);
    }
}