import * as ko from "knockout"
import 'jquery';

export class TabNavigationExtention {
	static Init() {
		ko.bindingHandlers.tabNavigation = {
			update: (element, valueAccessor) => {
				$(window).off('keydown');
				let arrowTop = 38;
				let arrowBottom = 40;
				let tabBtn = 9;
				let viewBody = document.getElementById('viewBody');
				var parentContainer = null;
				var closestSearch = null;

				$("body").on('keydown', '.tab-key-navigation tr', function (e) {
					if (e.keyCode == arrowBottom) {
						$(e.target).next().focus();
					}
					if (e.keyCode == arrowTop) {
						$(e.target).prev().focus();
					}
				});

				$(window).on('keydown', function (e) {
					if (e.keyCode == tabBtn) {
						$('.focus-guard').on('focus', function () {
							parentContainer = null;
							parentContainer = null;
							var firstElement = null;
							parentContainer = $(this).offsetParent();
							closestSearch = $(parentContainer).find('.search-input');
							if ($(closestSearch).length) {
								$(closestSearch).focus();
							} else {
								firstElement = $(parentContainer).find("[tabindex='1']").first();
								$(firstElement).focus();
							}
						});
					}
				})
			}
		};
	}
}