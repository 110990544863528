import { Guid } from "Core/Common/Guid";
import { BaseModel } from "DatabaseDesigner/Models/BaseModel";
import { CollectionModel } from "DatabaseDesigner/Models/CollectionModel";

export function RelationTypeValueModel() {
    this._id = null;
    this._guid = Guid.NewGuid();
    this._name = null;
    this._nameTranslations = [];
    this._reverse = null;
    this._reverseTranslations = [];
    this._enabled = null;
    this._typeId = null;
    this._typeGuid = null;
    this._sort = null;
    this._isNewSort = false;

    BaseModel.call(this);
}

RelationTypeValueModel.prototype = Object.create(BaseModel.prototype);
RelationTypeValueModel.prototype.constructor = RelationTypeValueModel;

RelationTypeValueModel.prototype.FromJSONArray = function (values) {
    var valuesCollection = new CollectionModel({ model: RelationTypeValueModel });

    _.each(values, function (value) {
        var model = new RelationTypeValueModel();
        model.FromJSON(value);
        valuesCollection.Add(model);
    });

    return valuesCollection;
};

RelationTypeValueModel.prototype.FromJSON = function (data) {
    this._id = data.Id;
    this._name = data.Name;
    this._nameTranslations = data.NameTranslations;
    this._reverse = data.Reverse;
    this._reverseTranslations = data.ReverseTranslations;
    this._enabled = data.Enabled;
    this._sort = data.Sort;
    this._isNewSort = data.IsNewSort;
};

RelationTypeValueModel.prototype.toJSON = function () {
    return {
        Id: this._id,
        Guid: this._guid,
        Name: this._name,
        NameTranslations: this.NameTranslations.map((translation) =>{
            return {
                Translation: translation.Translation,
                Language: {
                    K_Language: translation.Language.K_Language,
                    Name: translation.Language.Name,
                    ShortName: translation.Language.ShortName
                }
            }}),
        Reverse: this._reverse,
        ReverseTranslations: this.ReverseTranslations.map((translation) =>{
            return {
                Translation: translation.Translation,
                Language: {
                    K_Language: translation.Language.K_Language,
                    Name: translation.Language.Name,
                    ShortName: translation.Language.ShortName
                }
            }}),
        Enabled: this._enabled,
        Sort: this._sort,
        IsNewSort: this._isNewSort
    };
};

Object.defineProperty(RelationTypeValueModel.prototype, 'Id', {
    enumerable: true,
    get: function () {
        return this._id;
    },
    set: function (val) {
        this._id = val;
    }
});

Object.defineProperty(RelationTypeValueModel.prototype, 'Name', {
    enumerable: true,
    get: function () {
        return this._name;
    },
    set: function (val) {
        this._name = val;
    }
});

Object.defineProperty(RelationTypeValueModel.prototype, 'NameTranslations', {
    enumerable: true,
    get: function () {
        return this._nameTranslations;
    },
    set: function (val) {
        this._nameTranslations = val;
    }
});

Object.defineProperty(RelationTypeValueModel.prototype, 'Reverse', {
    enumerable: true,
    get: function () {
        return this._reverse;
    },
    set: function (val) {
        this._reverse = val;
    }
});

Object.defineProperty(RelationTypeValueModel.prototype, 'ReverseTranslations', {
    enumerable: true,
    get: function () {
        return this._reverseTranslations;
    },
    set: function (val) {
        this._reverseTranslations = val;
    }
});

Object.defineProperty(RelationTypeValueModel.prototype, 'Enabled', {
    enumerable: true,
    get: function () {
        return this._enabled;
    },
    set: function (val) {
        this._enabled = val;
    }
});

Object.defineProperty(RelationTypeValueModel.prototype, 'Sort', {
    enumerable: true,
    get: function () {
        return this._sort;
    },
    set: function (val) {
        this._sort = val;
    }
});

Object.defineProperty(RelationTypeValueModel.prototype, 'IsNewSort', {
    enumerable: true,
    get: function () {
        return this._isNewSort;
    },
    set: function (val) {
        this._isNewSort = val;
    }
});