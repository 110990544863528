import { deserialize, deserializeAs } from "libs/cerialize";
import { CanvasModel } from "Core/Controls/CanvasDesigner/Models/CanvasModel";
import { ModelState } from "Core/Common/Enums/ModelState";

export class DwPackageDto{

    constructor(init?: Partial<DwPackageDto>){        
        Object.assign(this, init);
    }

    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserializeAs(CanvasModel)
    Canvas: CanvasModel;

    @deserialize
    State: ModelState;
}