import { deserialize, deserializeAs } from 'libs/cerialize';
import { MailItemModel } from "Core/Controls/Mail/Models/MailItemModel";

export class GetMailsResponseModel {
	constructor() {
		this.Items = [];
	}

	@deserializeAs(MailItemModel)
	Items: MailItemModel[];



	@deserialize
	TotalItems: number;
}