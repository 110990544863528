import * as ko from "knockout"

import {BaseControl, IControlValue} from "Core/Controls/BaseControl/BaseControl"
import {SubForm} from "Core/Screens/Forms/SubForm/SubForm"
import {IControlParam} from "Core/Screens/IScreen"

import ViewTemplate from "Core/Controls/RecordSecurity/Templates/View.html"
import EditTemplate from "Core/Controls/RecordSecurity/Templates/Edit.html"
import ToolBarTemplate from "Core/Controls/RecordSecurity/Templates/ToolBar.html"
import DesignTemplate from "Core/Controls/RecordSecurity/Templates/Design.html"
import {BlockUI} from 'Core/Common/BlockUi'

import {RecordSecurityModel} from "Core/Controls/RecordSecurity/Models/RecordSecurityModel"
import {RecordSecurityViewModel, UserGroupViewModel} from "Core/Controls/RecordSecurity/Models/RecordSecurityViewModel"
import {RecordSecuritySerializedModel} from "Core/Controls/RecordSecurity/Models/RecordSecuritySerializedModel"
import {RecordSecurityStore} from "Core/Controls/RecordSecurity/Stores/RecordSecurityStore";
import { UserManager, UserRoles } from "../../../User/UserManager"

ko.templates["Core/Controls/RecordSecurity/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/RecordSecurity/Templates/Edit"] = EditTemplate;
ko.templates["Core/Controls/RecordSecurity/Templates/ToolBar"] = ToolBarTemplate;
ko.templates["Core/Controls/RecordSecurity/Templates/Design"] = DesignTemplate;

enum Statuses {
    Ok,
    Error
}

export class RecordSecurity extends BaseControl {
    private _profileId: number;
    private _data: KnockoutObservable<RecordSecurityViewModel>;
    private _status: KnockoutObservable<Statuses>;

    constructor(params: IControlParam) {
        super(params);
        this.Init();
    }

    private Init(): void {
        this._data = ko.observable(null);
        this._status = ko.observable(Statuses.Ok);
    }

    ApplyProperties(){}

    SetValue(value: IControlValue): void {
        this._profileId = value.SubjectRecordId;
        BlockUI.Block();
        RecordSecurityStore.GetRecordSecurityScreen(value.SubjectRecordId)
            .always(() => {
                BlockUI.Unblock();
            })
            .then(result => {
                if (!result.IsSuccessfull) {
                    this._status(Statuses.Error);
                    this._errorMessage(result.ErrorMessage);
                    return;
                }

                let recordSecurityModel: RecordSecurityModel = result.ResultObject;
                let recordSecurityViewModel = new RecordSecurityViewModel(this.Screen.GetRecordId(), recordSecurityModel);

                this._data(recordSecurityViewModel);

                this._data().UserGroups.map((userGroup) => {
                    userGroup.SelectedOption.subscribe((option) => {
                        if (userGroup.Options.indexOf(option) == 0) {
                            return;
                        }

                        const allRightsAreSetAlready = _.every(this._data().Tables, table => {
                            const selectedRight = _.findWhere(table.Rights(), {UserGroupId: userGroup.Id});
                            return selectedRight.SelectedOption().Value == option.Value;
                        });

                        if (allRightsAreSetAlready) {
                            return;
                        }

                        this._data().Tables.map((tableITem) => {
                            const selectedRight = _.findWhere(tableITem.Rights(), {UserGroupId: userGroup.Id});
                            selectedRight && selectedRight.SelectedOption(option);
                        });

                    });
                });

                this._status(Statuses.Ok);
            });
    }

    AfterRender(el: Array<HTMLElement>): void {
        super.AfterRender(el);
    }

    Deserialize(): RecordSecuritySerializedModel {
        if (this._status() === Statuses.Error) {
            return null;
        }

        return new RecordSecuritySerializedModel(this._profileId, this._data());
    }

    Reset() {

    }

    async Reserve(userGroup: UserGroupViewModel){
        if(!this.IsEditScreen){
            return;
        }
        if(this.IsConsultScreen){
            await RecordSecurityStore.ReserveGroup(this.Screen.GetRecordId(), userGroup.Id);
        }
        userGroup.ProfileReservationId(this.Screen.GetRecordId());
    }

    async UnReserve(userGroup: UserGroupViewModel){
        if(!this.IsEditScreen){
            return;
        }
        if(this.IsConsultScreen){
            await RecordSecurityStore.ReserveGroup(null, userGroup.Id);
        }
        userGroup.ProfileReservationId(null);
    }

    GetIsVisibleReservationToggler(userGroup: UserGroupViewModel){     
        return this.Screen.GetTableTypeName() === 'Profile' && userGroup.Name != 'Owner'
            &&
        UserManager.Instance.IsUserInRole(UserRoles.SuperUser)
    }

    GetBanTitle(userGroup: UserGroupViewModel){
        return this._labels.USED_IN_PROFILES.replace('{Profiles}', userGroup.UsesInGroups.join(', '));
    }
}