import * as ko from "knockout";

import {FieldSecurityComponent} from "Core/Controls/FieldSecurity/Components/Base/FieldSecurityComponent";
import {IFieldSecuritySettings} from "Core/Controls/FieldSecurity/Shared/Interfaces/IFieldSecuritySettings";

import {DataRole} from "Core/Controls/FieldSecurity/Shared/Enums/DataRoles";

import ViewTemplate from 'Core/Controls/FieldSecurity/Components/DataRole/Header/View.html';
import EditTemplate from 'Core/Controls/FieldSecurity/Components/DataRole/Header/Edit.html';

ko.templates['Core/Controls/FieldSecurity/Components/DataRole/Header/View'] = ViewTemplate;
ko.templates['Core/Controls/FieldSecurity/Components/DataRole/Header/Edit'] = EditTemplate;

export class HeaderComponent extends FieldSecurityComponent {
	private _dataRole: DataRole;

	constructor(settings: IFieldSecuritySettings, dataRole: DataRole) {
		super(settings);

		this._dataRole = dataRole;

		this.Templates = {View: ViewTemplate, Edit: EditTemplate, Design: ViewTemplate};
	}

	OnDataRoleAdding() {
		this.Trigger('DataRoleAdding');
	}
}