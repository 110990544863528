import {P} from 'Core/Common/Promise';
import {Request} from 'Core/Common/Request';

import {RemoteDatabaseDto} from './Models/RemoteDatabaseDto';
import {RemoteDatabaseAccessDto} from './Models/RemoteDatabaseAccessDto';
import {UpdateRemoteDatabaseAccessDto} from './Models/UpdateRemoteDatabaseAccessDto';
import {CreateRemoteDatabaseAccessDto} from './Models/CreateRemoteDatabaseAccessDto';

export class NetworkDesignerStore {
    GetRemoteDatabases(): P.Promise<RemoteDatabaseDto[]> {
        const deferred = P.defer<RemoteDatabaseDto[]>()

        Request.Send({
            proxy: {
                url: '/api/NetworkDesignerApi/GetRemoteDatabases',
                type: 'GET'
            }
        }).then(dbs => {
            deferred.resolve(dbs)
        }).fail(err => {
            const error = this.GetResponseError(err, 'Error getting remote databases');
            deferred.reject(error);
        });

        return deferred.promise();
    }

    GetRemoteDatabaseAccess(dbId: number): P.Promise<RemoteDatabaseAccessDto[]> {
        const deferred = P.defer<RemoteDatabaseAccessDto[]>();

        Request.Send({
            proxy: {
                url: '/api/NetworkDesignerApi/GetRemoteDatabaseAccess',
                type: 'GET'
            },
            params: {dbId}
        }).then(access => {
            deferred.resolve(access);
        }).fail(err => {
            const error = this.GetResponseError(err, 'Error getting remote database access');
            deferred.reject(error);
        });

        return deferred.promise();
    }

    CreateRemoteDatabaseAccess(params: CreateRemoteDatabaseAccessDto) : P.Promise<number> {
        const deferred = P.defer<number>();

        Request.Send({
            proxy: {
                url: '/api/NetworkDesignerApi/CreateRemoteDatabaseAccess',
                type: 'POST'
            },
            params
        }).then(accessId => {
            deferred.resolve(accessId);
        }).fail(err => {
            const error = this.GetResponseError(err, 'Error creating access');
            deferred.reject(error);
        });

        return deferred.promise();
    }

    UpdateRemoteDatabaseAccess(params: UpdateRemoteDatabaseAccessDto): P.Promise<void> {
        const deferred = P.defer<void>();

        Request.Send({
            proxy: {
                url: '/api/NetworkDesignerApi/UpdateRemoteDatabaseAccess',
                type: 'POST'
            },
            params
        }).then(() => {
            deferred.resolve(null);
        }).fail(err => {
            const error = this.GetResponseError(err, 'Error updating access');
            deferred.reject(error);
        });

        return deferred.promise();
    }


    RemoveRemoteDatabaseAccess(accessId: number): P.Promise<void> {
        const deferred = P.defer<void>();

        Request.Send({
            proxy: {
                url: '/api/NetworkDesignerApi/RemoveRemoteDatabaseAccess',
                type: 'POST'
            },
            params: {accessId}
        }).then(() => {
            deferred.resolve(null);
        }).fail(err => {
            const error = this.GetResponseError(err, 'Error removing access');
            deferred.reject(error);
        });

        return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}