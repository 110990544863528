import * as ko from 'knockout';

import {JBoxDropDown} from "Core/Components/JBoxDropdown/DropDown";
import {ZIndexManager} from 'Core/Common/ZIndexManager';
import {FIELD_TYPES} from "Core/Constant";
import {CONFIRMATIONS, NOTIFICATIONS} from "Core/Components/Translation/Locales";

import {
    ConversionDropdownResponse,
    ConversionDropdownValue
} from "../../Pages/ConfigurationPage/Stores/Models/ConversionDropdownResponse";
import {
    ConversionDropdownView, ConversionDropdownViewValue
} from 'Core/Components/Controls/ProductConfigurator/Components/ConversionDropdown/Models/ConversionDropdownView';
import {SearchByConversionDto} from 'Core/Components/Controls/ProductConfigurator/Components/ConversionDropdown/Params/SearchByConversionDto';

import {Event} from "Core/Common/Event";

import ConversionDropdownViewTemplate from 'Core/Components/Controls/ProductConfigurator/Components/ConversionDropdown/Templates/ConversionDropdownView.html';
import ConversionDropdownTemplate from 'Core/Components/Controls/ProductConfigurator/Components/ConversionDropdown/Templates/ConversionDropdown.html';
import _ from "underscore";
ko.templates["Core/Components/Controls/ProductConfigurator/Components/ConversionDropdown/Templates/ConversionDropdownView"] = ConversionDropdownViewTemplate;
ko.templates["Core/Components/Controls/ProductConfigurator/Components/ConversionDropdown/Templates/ConversionDropdown"] = ConversionDropdownTemplate;

export interface IConversions {
    FieldId: number,
    Value: string
}

const TEMPLATES = {
    POP_UP: "Core/Components/Controls/ProductConfigurator/Components/ConversionDropdown/Templates/ConversionDropdown",
    VIEW: "Core/Components/Controls/ProductConfigurator/Components/ConversionDropdown/Templates/ConversionDropdownView"
};

export const CONVERSION_DROPDOWN_EVENTS = {
    SELECTED: "Selected",
    SYNCHRONIZATION_VALUE: "Synchronization_value",
    SYNCHRONIZATION_RESET: "Synchronization_Reset",
};
export class ConversionDropdown extends Event {
    private _el: HTMLElement;
    private _dropDown: JBoxDropDown;
    private _dropdownData: KnockoutObservable<ConversionDropdownView>;
    private _params: KnockoutObservableArray<ConversionDropdownView>;
    private _isInit: KnockoutObservable<boolean>;
    private _conversions: KnockoutObservableArray<SearchByConversionDto>;
    private _uniqConversions: KnockoutObservableArray<IConversions>;
    private _message: KnockoutObservable<string>;

    constructor() {
        super();

        this._params = ko.observableArray(null);
        this._isInit = ko.observable(null);
        this._dropdownData = ko.observable(null);
        this._conversions = ko.observableArray(null);
        this._uniqConversions = ko.observableArray(null);
        this._message = ko.observable(null);

        this.BindEvents();
    }

    get IsInit(): boolean {
        return this._isInit()
    }
    get DropDownParams(){
        return this._params()
    }
    get UniqConversions() {
        return this._uniqConversions()
    }

    GetTemplateName() {
        return TEMPLATES.VIEW;
    }

    SetMessage(value: string){
        this._message(value);
    }

    ResetMessage(){
        this._message(null);
    }

    Init(params: ConversionDropdownView[]) {
        if (!this._isInit()) {
            this._isInit(true);
        }
        this._params(params);
    }

    ResetSelectedValue(): void {
        _.each(this._params(), (param: ConversionDropdownView)=> {
            param.SelectValue(null);
            _.each(param.Values(), (value: ConversionDropdownViewValue)=> {
                value.IsActive(false);
                value.IsDisabled(false);
            })
        })
    }

    Reset(){
        this.Close();
        this.ResetMessage();
        this._isInit(false);

        this._conversions([]);
        this._uniqConversions([]);
        this.ResetSelectedValue();

        this._params([]);
    }
    ResetConversions() {
        this.Close();
        this._dropdownData(null);

        this._conversions([]);
        this._uniqConversions([]);
        this.ResetSelectedValue();
    }

    Show(data: ConversionDropdownView, event) {
        let target: HTMLElement = event.currentTarget;

        if (this._dropDown){
            this._dropdownData().IsOpenDropdown(false);
            this._dropDown.Close();
        }

        this._dropdownData(data);
        if (!this._dropDown) {
            this.InitDropdown(target);
        }
        this._dropDown?.Toggle();
    }

    Open(){
        // this._dropDown?.Open();
    }

    OpenDropdown(data, event): void {
        this.Show(data, event);
    }

    SelectValue(data: ConversionDropdownViewValue, event) {
        if (data.IsActive() && !data.IsDisabled()) {
            this._conversions([]);
            this._uniqConversions([]);
            this.Trigger(CONVERSION_DROPDOWN_EVENTS.SYNCHRONIZATION_RESET);
            this.Close();
            return;
        }

        if (data.IsDisabled()) {
            return;
        }

        _.each(this._dropdownData().Values(), (value: ConversionDropdownViewValue)=> {
            value.IsActive(false);
        })
        this._dropdownData().SelectValue(data.Value);

        data.IsActive(true);

        const newElem: IConversions = {FieldId: this._dropdownData().FieldId, Value: data.Value};

        this._conversions(_.reject(this._conversions(), item => item.FieldId === newElem.FieldId));
        this._conversions().push(new SearchByConversionDto(this._dropdownData().FieldId, data.Value));

        this._uniqConversions(_.uniq(this._conversions(), (conversion)=> conversion.FieldId));

        if (this._uniqConversions().length === this._params().length){
            this.Close();
            this.Trigger(CONVERSION_DROPDOWN_EVENTS.SELECTED, {Conversions: this._uniqConversions()})
        } else {
            this.Trigger(CONVERSION_DROPDOWN_EVENTS.SYNCHRONIZATION_VALUE, {SelectedConversions: this._uniqConversions()});
            this.Close();
        }
    }

    SelectGridValue(gridData: ConversionDropdownView, data: ConversionDropdownViewValue, event) {
        if (data.IsActive() && !data.IsDisabled()) {
            this._conversions([]);
            this._uniqConversions([]);
            this.Trigger(CONVERSION_DROPDOWN_EVENTS.SYNCHRONIZATION_RESET);
            return;
        }

        if (data.IsDisabled()){
            return
        }

        let _gridData: ConversionDropdownView = gridData;
        let selectedValue: boolean = data.IsActive();
        _.each(_gridData.Values(), (value: ConversionDropdownViewValue)=> {
            value.IsActive(false);
        });

        data.IsActive(!selectedValue);

        _gridData.SelectValue(!selectedValue ? data.Value : null);

        const newElem: IConversions = {FieldId: _gridData.FieldId, Value: data.Value};
        this._conversions(_.reject(this._conversions(), item => item.FieldId === newElem.FieldId));

        if (!selectedValue) {
            this._conversions().push(new SearchByConversionDto(_gridData.FieldId, data.Value));
        }

        this._uniqConversions(_.uniq(this._conversions(), (conversion)=> conversion.FieldId));

        if (this._uniqConversions().length === this._params().length){
            this.Trigger(CONVERSION_DROPDOWN_EVENTS.SELECTED, {Conversions: this._uniqConversions()})
        } else {
            this.Trigger(CONVERSION_DROPDOWN_EVENTS.SYNCHRONIZATION_VALUE, {SelectedConversions: this._uniqConversions()})
        }
    }

    InitDropdown(el: HTMLElement): void {
        this._dropDown = new JBoxDropDown({
            target: el,
            bindTarget: el,
            bindComponent: this,
            otherOptions: {
                closeOnClick: 'body',
                attach: undefined,
                height: 'auto',
                maxHeight: 300,
                isolateScroll: true,
                pointer: "right",
                maxWidth: 250,
                position: {
                    x: "right",
                    y: "bottom"
                },
                addClass: `jBox-padding-10px jBox-fix-pointer-position-right-15px`,
                zIndex: ZIndexManager.Instance.NextValue,
                onCloseComplete: () => {
                    this._dropdownData().IsOpenDropdown(false);
                    this._dropDown.Destroy();
                    this._dropDown = null;
                }
            },
            onCreated: () => {
                this._dropDown.SetContent({ content: ConversionDropdownTemplate as any });
            },
            onOpen: () => {
                this._dropdownData().IsOpenDropdown(true);
            }
        })
    }

    Close() {
        this._dropDown?.Close();
    }

    MapConversionDropdowns(conversionDropdowns: ConversionDropdownResponse[]): ConversionDropdownView[] {
        return _.map(conversionDropdowns, (conversionDropdown: ConversionDropdownResponse)=> {
            const sortedValues:ConversionDropdownValue[] = this.SortConversionDropdownValues(conversionDropdown.Values, conversionDropdown.FieldType);
            const isGridMode: boolean = conversionDropdown.Values.length <= 9;
            const selectedValues: ConversionDropdownValue = _.find(conversionDropdown.Values, (dropdownValue:ConversionDropdownValue) => dropdownValue.IsActive);

            const dropdownView: ConversionDropdownView = new ConversionDropdownView();

            dropdownView.FieldId = conversionDropdown.FieldId;
            dropdownView.FieldName = conversionDropdown.FieldName;
            dropdownView.FieldNameTranslation = conversionDropdown.FieldNameTranslation;
            dropdownView.FieldType = conversionDropdown.FieldType;
            dropdownView.SelectValue(selectedValues && selectedValues.Value);
            dropdownView.IsGridMode = isGridMode;
            dropdownView.Values(sortedValues.map((dropdownValue: ConversionDropdownValue)=> ({Value: dropdownValue.Value, IsActive: ko.observable(dropdownValue.IsActive), IsDisabled: ko.observable(dropdownValue.IsDisabled)})));

            return dropdownView;
        })
    }

    ValueSynchronization(conversionDropdowns: ConversionDropdownResponse[]): void {
        _.each(this._params(), (dropdownView: ConversionDropdownView):void => {
            const dropdownResponse: ConversionDropdownResponse = _.findWhere(conversionDropdowns, { FieldId: dropdownView.FieldId });
            if (dropdownResponse) {
                const sortedValues:ConversionDropdownValue[] = this.SortConversionDropdownValues(dropdownResponse.Values, dropdownResponse.FieldType);
                const selectedValues: ConversionDropdownValue = _.find(sortedValues, (dropdownValue:ConversionDropdownValue) => dropdownValue.IsActive);

                dropdownView.IsDisabledValuesGroup(_.every(sortedValues, (value: ConversionDropdownValue)=> value.IsDisabled === true));
                dropdownView.SetMessage(dropdownView.IsDisabledValuesGroup() ? `${NOTIFICATIONS.RECORD_NOT_FOUND} :(`: null);

                dropdownView.SelectValue(selectedValues && selectedValues.Value);
                dropdownView.Values(sortedValues.map((dropdownValue: ConversionDropdownValue)=> ({Value: dropdownValue.Value, IsActive: ko.observable(dropdownValue.IsActive), IsDisabled: ko.observable(dropdownValue.IsDisabled)})));
            }
        });
    }

    private SortConversionDropdownValues(values: ConversionDropdownValue[], fieldType: string): ConversionDropdownValue[] {
        if (fieldType === FIELD_TYPES.Integer || fieldType === FIELD_TYPES.Decimal) {
            return values.sort((a: ConversionDropdownValue, b: ConversionDropdownValue): number => {
                const isA: string = a.Value;
                const isB: string = b.Value;

                const aIsNotNumber: boolean = Number.isNaN(isA);
                const aIsNumber: boolean = !aIsNotNumber;

                const bIsNotNumber: boolean = Number.isNaN(isB);
                const bIsNumber: boolean = !bIsNotNumber;

                if (aIsNotNumber && bIsNumber){
                    return 1;
                } else if (aIsNumber && bIsNotNumber) {
                    return -1;
                } else if (aIsNotNumber && bIsNotNumber) {
                    return isA > isB ? 1 : -1;
                }

                const aNumber: number = Number(isA.replace(/[.,]/g, '.'));
                const bNumber: number = Number(isB.replace(/[.,]/g, '.'));

                return aNumber > bNumber ? 1 : -1;
            });
        }

        return values.sort((a: ConversionDropdownValue, b: ConversionDropdownValue): number => a.Value > b.Value ? 1 : -1);
    }

    AfterRender(el: Array<HTMLElement>) {
        this._el = el[0];
    }

    private BindEvents() {

    }

}