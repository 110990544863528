import {NewDataRoleDTOModel} from "Core/Controls/FieldSecurity/Shared/Models/DTO/Post/NewDataRoleDTOModel";
import {NewDeputyDTOModel} from "Core/Controls/FieldSecurity/Shared/Models/DTO/Post/NewDeputyDTOModel";
import {DeletedRoleDTOModel} from "Core/Controls/FieldSecurity/Shared/Models/DTO/Post/DeletedRoleDTOModel";

export class PostDataRolesRequestModel {
	NewRoles: NewDataRoleDTOModel[];
	NewDeputies: NewDeputyDTOModel[];

	DeletedRoles: DeletedRoleDTOModel[];
	DeletedDeputies: DeletedRoleDTOModel[];

	constructor(
		newRoles: NewDataRoleDTOModel[],
		newDeputies: NewDeputyDTOModel[],
		deletedRoles: DeletedRoleDTOModel[],
		deletedDeputies: DeletedRoleDTOModel[]) {

		this.NewRoles = newRoles;
		this.NewDeputies = newDeputies;

		this.DeletedRoles = deletedRoles;
		this.DeletedDeputies = deletedDeputies;
	}
}
