import { URL } from 'Core/Common/Url';
import { Request, IRequestOptions } from 'Core/Common/Request';
import { P } from 'Core/Common/Promise';
import { Deserialize } from 'libs/cerialize';
import { DWPackageModel } from "Core/GeneralProperties/Managers/DWPackageProperty/Models/DWPackageModel";

export class DWPackagePropertyStore {
    static GetDataWizardPackages(): P.Promise<DWPackageModel[]>{
		var deferredResult = P.defer<any>();
        
		var requestParams: IRequestOptions = {
			proxy: {
                url: URL.CANVAS.GET_DW_PACKAGES,
				dataType: 'json',
				type: 'GET'
			}
		};
		Request.Send(requestParams).then(data => {
            deferredResult.resolve(data.ResultObject);
		});

		return deferredResult.promise();
	}
}