import * as ko from 'knockout';
import * as $ from 'jquery';
import 'tree';

import {Notifier} from "Core/Common/Notifier";
import {LABELS} from "Core/Components/Translation/Locales"
import {P} from "Core/Common/Promise";
import {Guid} from 'Core/Common/Guid';

import {UserVarsManager} from "Core/UserVarsManager/UserVarsManager";
import {GlobalManager, GLOBALS} from 'Core/GlobalManager/GlobalManager';
import {Tooltip} from "Core/Common/Tooltip";
import {ScreenTypes} from "Core/Common/Enums/ScreenTypes";

import {PathRunnerStore} from "Core/Components/PathRunner/Stores/PathRunnerStore";
import {PathRunnerResponseModel} from "Core/Components/PathRunner/Models/Response/PathRunnerResponseModel";
import {PathRunnerNodeViewModel} from "Core/Components/PathRunner/Models/View/PathRunnerNodeViewModel";
import {PathRunnerViewModel} from "Core/Components/PathRunner/Models/View/PathRunnerViewModel";
import {EllipsisTooltipExtention} from "Core/KnockoutExtentions/EllipsisTooltipExtention";

import {PathRunnerViewModelMapping} from "Core/Components/PathRunner/Mappings/PathRunnerViewModelMapping";
import {PathRunnerNodeViewModelMapping} from "Core/Components/PathRunner/Mappings/PathRunnerNodeViewModelMapping";
import { PUB_SUB_EVENTS } from 'MenuManager/PubSubEvents';

import Template from "Core/Components/PathRunner/Templates/Template.html";
import ItemTemplate from "Core/Components/PathRunner/Templates/ItemTemplate.html";
import SpecialScreenIconTemplate from "Core/Components/PathRunner/Templates/SpecialScreenIconTemplate.html";


ko.templates["Core/Components/PathRunner/Templates/Template"] = Template;
ko.templates["Core/Components/PathRunner/Templates/ItemTemplate"] = ItemTemplate;
ko.templates["Core/Components/PathRunner/Templates/SpecialScreenIconTemplate"] = SpecialScreenIconTemplate;

export class PathRunnerUnit {
    private _entityId: number;
    private _recordId: KnockoutObservable<number>;
    private _expanded: KnockoutObservable<boolean>;
    private _isSpecialScreenExist: KnockoutObservable<boolean>;
    private _model: KnockoutObservable<PathRunnerViewModel>;
    private _userVars: UserVarsManager;
    private _labels = LABELS;

    private _el: HTMLElement;
    private _pathRunner: HTMLElement;
    private _pathRunnerId: string;
    private _treeRendered: boolean;
    private _dataLoaded: KnockoutObservable<boolean>;
    private _pathRunnerNodes: any;

    private _tooltip: Tooltip;
    private _jbox: jBox;

    constructor(entityId: number, isSpecialScreenExist: boolean) {
        this._pathRunnerId = Guid.NewGuid();
        this._entityId = entityId;
        this._recordId = ko.observable(null);
        this._model = ko.observable(null);

        let isExpanded = this.GetSettingsFromLockStorage();
        this._expanded = ko.observable(isExpanded);
        this._isSpecialScreenExist = ko.observable(isSpecialScreenExist);
        this._dataLoaded = ko.observable(false);
        this._pathRunnerNodes = [];

        this._tooltip = null;

        this._dataLoaded.subscribe(dataLoaded => {
            if (dataLoaded) {
                this.RenderTree();
            }
        });
    }

    ExpandCollapse() {
        this._expanded(!this._expanded());
        this.SetSettingsToLockStorage();
    }

    OpenSpecialScreen() {
        PubSub.publish(PUB_SUB_EVENTS.GO_TO_SCREEN_BY_TYPE, { EntityId: this._entityId, ScreenType: ScreenTypes.SpecialScreen, IsOpenInModal: false });
    }

    GetTemplateName() {
        return "Core/Components/PathRunner/Templates/Template";
    }

    GetSpecialScreenIconTemplate() {
        return "Core/Components/PathRunner/Templates/SpecialScreenIconTemplate";
    }

    AfterRender(el: HTMLElement[]) {
        this._pathRunner = document.getElementById(this._pathRunnerId);
        this._el = el[0];
        this.RenderTree();
    }

    AddEllipsisTooltip(data) {
        this._jbox = EllipsisTooltipExtention.CreateEllipsisTooltipForPathRunner(data.node.original.record);
    }

    LoadNewData(recordId: number) {
        const deferred = P.defer();

        const pathRunnerParentsAmount = GlobalManager.Instance.GetGlobal(GLOBALS.PATH_RUNNER_PARENTS_AMOUNT);
        if (parseInt(pathRunnerParentsAmount) === 0) {
            this.Clear();
            return deferred.promise();
        }

        this._dataLoaded(false);
        this._treeRendered = false;

        this._recordId(recordId);

        if (recordId) {
            PathRunnerStore.GetData({EntityId: this._entityId, RecordId: recordId})
                .then((data) => {
                    this.DataLoaded(data);
                    deferred.resolve(null);
                })
                .fail(() => {
                    this.DataLoadFailed.bind(this)
                    deferred.resolve(null);
                });
        }

        return deferred.promise();
    }

    Clear() {
        if (this._model()) {
            this._model(null);
        }
    }

    private RenderTree() {
        const $treeRoot = $(this._el)
            .next()
            .find('.path-runner-body');

        $treeRoot.jstree('destroy');
        $treeRoot.jstree({
            state: {key: 'path-runner-state'},
            plugins: ['state'],
            core: {
                animation: false,
                data: this.ExtendTree.bind(this),
                multiple: false,
                themes: {
                    icons: false
                }
            }
        });

        $treeRoot
            .on('ready.jstree', (event, data) => this.OpenCurrentNode(data))
            .on('activate_node.jstree ', (event, data) => this.Navigate(data))
            .on('hover_node.jstree', (event, data) => {
                this._model().AddEllipsisTooltip(this._pathRunnerNodes, data);
            })
            .on('dehover_node.jstree', (event, data) => {
                this._model().DestroyEllipsisTooltip(this._pathRunnerNodes, data);
            })
    }

    private ExtendTree(node, callback) {
        if (node.id === '#') {
            if (this._model()) {
                callback.call(node, this._model().ToJson());
            }
        } else {
            this.LoadLinkedRecords(node)
                .then(linkedRecords => this.AddLinkedRecords(linkedRecords, node, callback))
                .fail(this.DataLoadFailed.bind(this));
        }
    }

    private OpenCurrentNode(data) {
        const currentRecord = this._model().CurrentNode;
        if (currentRecord) {
            const currentNode = currentRecord.Guid;
            if (currentRecord.IsSubject) {
                data.instance._open_to(currentNode);
                data.instance.open_node(currentNode);
                data.instance.disable_node(currentNode);
            }
        } else {
            this._model().GetNodeByRecordId(this._recordId(),
                record => {
                    if (!this._model().CurrentNode && record.IsSubject) {
                        if (record.IsSubject) {
                            data.instance._open_to(record.Guid);
                            data.instance.open_node(record.Guid);
                            data.instance.disable_node(record.Guid);
                        }
                    }
                });
        }
    }

    private SetSettingsToLockStorage() {
        this._userVars.SetPathRunnerSettings({
            Place: this._entityId.toString(),
            Expanded: this._expanded()
        });
    }

    private GetSettingsFromLockStorage(): boolean {
        if (!this._userVars) this._userVars = UserVarsManager.Instance;

        let lockStorageSettings = this._userVars.GetPathRunnerSettings(this._entityId.toString());
        return lockStorageSettings ? lockStorageSettings.Expanded : false;
    }

    private DataLoaded(responseModel: PathRunnerResponseModel) {
        if (responseModel) {
            const data = PathRunnerViewModelMapping.MapFromResponse(responseModel);
            this._model(data);
        } else {
            this.Clear();
        }

        if (this._model()) {
            this._model().GetNodeByRecordId(this._recordId(),
                record => {
                    if (!this._model().CurrentNode && record.IsSubject) {
                        this._model().CurrentNode = record;
                    }
                });

            this._model().SetInitialNode(this._recordId());
            this._pathRunnerNodes = this._model().GetNodes();

            this._treeRendered = true;
            this._dataLoaded(true);
        }
    }

    IsPathRunner(): boolean {
        return this._dataLoaded();
    }

    IsSpecialScreenExist(): boolean {
        return this._isSpecialScreenExist();
    }

    private DataLoadFailed(error) {
        new Notifier().Failed(error.message);
    }

    private Navigate(data) {
        const record = data.node.original.record;

        if (record.Guid !== this._model().CurrentNode.Guid) {
            data.instance.enable_node(this._model().CurrentNode.Guid);
            this._model().CurrentNode = record;
            PubSub.publish('PATH_RUNNER_NAVIGATE', {
                EntityId: this._entityId,
                RecordId: record.Id,
                RecordTypeId: record.TypeId,
                IsOpenInModal: false,
                LifeStatusName: record.LifeStatusName
            });
        }

        if ($(".path-runner-tooltip").length) {
            $(".path-runner-tooltip").remove();
        }

    }

    private LoadLinkedRecords(node) {
        const deferredResult = P.defer<PathRunnerNodeViewModel[]>();

        const record = node.original.record;
        let nestedNodes = [];

        this._model().GetNodeByRecordId(record.Id, existingRecord => {
            if (existingRecord.Children.length > 0 && nestedNodes.length === 0) {
                nestedNodes = existingRecord.Children.map(nestedNode => PathRunnerNodeViewModelMapping.Copy(nestedNode));
            }
        });

        if (nestedNodes.length === 0) {
            PathRunnerStore.GetLinkedRecords({EntityId: this._entityId, RecordId: record.Id})
                .then(linkedRecords => {
                    nestedNodes = linkedRecords.map(nestedNode => PathRunnerNodeViewModelMapping.MapFromResponse(nestedNode));
                    deferredResult.resolve(nestedNodes);
                })
                .fail(error => deferredResult.reject(error));
        } else {
            deferredResult.resolve(nestedNodes);
        }

        return deferredResult.promise();
    }

    private AddLinkedRecords(linkedRecords: PathRunnerNodeViewModel[], node, callback) {
        const nestedNodes = linkedRecords.map(nestedNode => nestedNode.ToJson());
        this._model().GetNode(node.id, record => {
            record.AddRange(linkedRecords);
        });
        callback.call(node, nestedNodes);
    }
}