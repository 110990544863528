import * as ko from 'knockout';

import {Event} from "Core/Common/Event";
import {Notifier} from "Core/Common/Notifier";
import {BlockUI} from "Core/Common/BlockUi";
import {Modal} from "Core/Common/Modal";

import {IPopup} from "../IPopup";
import {IParams} from "./IParams";

import {ViewModel} from './Models/View/ViewModel';

import {Store} from "./Stores/Store";
import {BackLinkOptionsDto} from "./Models/Dto/BackLinkOptionsDto";

import Template from 'Core/Components/BackLinking/Popup/MakeBackLink/Templates/Template.html';
import {Mappings} from "./Mappings/Mappings";

ko.templates['Core/Components/BackLinking/Popup/MakeBackLink/Templates/Template'] = Template;

export class MakeBackLinkPopup extends Event implements IPopup {
    private _viewModel: ViewModel;
    private _modal: Modal;

    constructor(private _params: IParams) {
        super();
    }

    GetTemplateName(): string {
        return 'Core/Components/BackLinking/Popup/MakeBackLink/Templates/Template';
    }

    AfterRender() {

    }

    Save() {
        BlockUI.Block();

        this.SaveSelection()
            .then(() => {
                new Notifier().Success('Success');
                this._modal.Close();
                this.Trigger('CHANGES_SAVED');
            })
            .fail(error => new Notifier().Failed(JSON.parse(error.message).Message))
            .always(() => BlockUI.Unblock())
    }

    Cancel() {
        this._modal.Close();
    }

    Show(): void {
        BlockUI.Block();

        this.RequestOptions()
            .then((dto: BackLinkOptionsDto) => {
                if (dto.Groups.length === 0) {
                    new Notifier().Warning('There are no options for such intention');
                    return;
                }

                this._viewModel = Mappings.MapToViewModel(dto);
                this._viewModel.Header = this._params.Header;

                this.ShowModal();
            })
            .fail(error => new Notifier().Failed(JSON.parse(error.message).Message))
            .always(() => BlockUI.Unblock());
    }

    private ShowModal() {
        this._modal = new Modal({
            minWidth: 400
        }, false);

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);

        this._modal.Show();
    }

    private RequestOptions() {
        return Store.GetBackLinkOptions({
            SubTableView: this._params.SubTableView,
            LeftTableId: this._params.LeftTableId,
            RightTableId: this._params.RightTableId,
            LeftRecordId: this._params.LeftRecordId,
            RightRecordId: this._params.RightRecordId,
            Sequence: this._params.Sequence
        });
    }

    private SaveSelection() {
        return Store.SaveSelection({
            SubTableView: this._params.SubTableView,
            LeftTableId: this._params.LeftTableId,
            RightTableId: this._params.RightTableId,
            LeftRecordId: this._params.LeftRecordId,
            RightRecordId: this._params.RightRecordId,
            SelectedOptions: this._viewModel.GetSelectedOptions()
        });
    }
}