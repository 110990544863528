import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize, GenericDeserialize} from 'libs/cerialize';
import {EntityModel} from 'Core/Screens/DesignScreen/ControlEditor/Models/EntityModel';
import {PlannerLevelEntityModel} from "../Models/PlannerLevelEntityModel";
import {TableWithImagesModel} from "../Models/TableWithImagesModel";

export interface IGetEntitiesRequestModel {
	EntityId: number;
	TableTypeId: number;
}

export interface IGetSignatureEntitiesRequestModel {
	SubjectTableId: number;
}

export interface IGetImageEntitiesRequestModel {
	SubjectTableId: number;
}

export class EntitiesStore {
	static Get(params: IGetEntitiesRequestModel): P.Promise<Array<EntityModel>> {
		var deferredResult = P.defer<Array<EntityModel>>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: "/api/FormDesignerApi/GetEntities",
				dataType: "json",
				type: "POST"
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(this.Deserialize(data));
			});

		return deferredResult.promise();
	}

	static GetImageEntities(params: IGetImageEntitiesRequestModel) {
		const deferredResult = P.defer<Array<TableWithImagesModel>>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: "/api/ImageApi/GetImageControlConfig",
				dataType: "json",
				type: "GET"
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(this.ImageDeserialize(data.Tables));
			});

		return deferredResult.promise();
	}

	static GetSignatureEntities(params: IGetSignatureEntitiesRequestModel): P.Promise<Array<TableWithImagesModel>> {

		var deferredResult = P.defer<Array<TableWithImagesModel>>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: "/api/SignatureApi/GetSignatureControlConfig",
				dataType: "json",
				type: "GET"
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => deferredResult.resolve(this.SignatureDeserialize(data.Tables)));

		return deferredResult.promise();
	}

	static GetPlanerLevelEntities(params: IGetEntitiesRequestModel): P.Promise<Array<PlannerLevelEntityModel>> {
		let deferredResult = P.defer<Array<PlannerLevelEntityModel>>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: "/api/FormDesignerApi/GetPlannerLevelEntities",
				dataType: "json",
				type: "POST"
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(Deserialize(data.ResultObject, PlannerLevelEntityModel));
			});

		return deferredResult.promise();
	}

	static GetSubEntities(params: IGetEntitiesRequestModel): P.Promise<Array<EntityModel>> {
		var deferredResult = P.defer<Array<EntityModel>>();
		var requestParams: IRequestOptions = {
			proxy: {
				url: "/api/FormDesignerApi/GetSubEntities",
				dataType: "json",
				type: "POST"
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(this.Deserialize(data));
			});

		return deferredResult.promise();
	}

	static Deserialize(jsonObject: any): any {
		var model = GenericDeserialize<EntityModel>(jsonObject, EntityModel);
		return model;
	}

	static SignatureDeserialize(jsonObject: any): any {
		var model = GenericDeserialize<TableWithImagesModel>(jsonObject, TableWithImagesModel);
		return model;
	}

	static ImageDeserialize(jsonObject: any): any {
		return GenericDeserialize<TableWithImagesModel>(jsonObject, TableWithImagesModel);
	}
}