import {Request} from 'Core/Common/Request';
import {AttachmentModel} from 'Core/Controls/Image/Models/AttachmentModel';

export class GuestPageStore {
	UploadImage(attachmentModel: AttachmentModel) {
        return Request.Send({
            proxy: {
                url: '/Guest/UploadImage',
                type: 'POST'
            }, params: attachmentModel
        });
    }

    RemoveImage() {
        return Request.Send({
            proxy: {
                url: '/Guest/RemoveImage',
                type: 'POST'
            }
        });
    }
}