import * as ko from "knockout";
import {Guid} from "Core/Common/Guid"
import {Event} from "Core/Common/Event";
import {EVENTS} from "Core/Constant";

import {PortletsCollection} from "Core/Portlets/Models/Design/Explorer/PortletsCollection"
import {PortletsExplorer} from "Core/Portlets/Models/Design/Explorer/PortletsExplorer"
import {IPosition} from "Core/Portlets/Models/Common/Interfaces/IPosition"
import {EntityPortlet} from "Core/Portlets/Models/Design/Explorer/EntityPortlet"
import {PortletManager} from "Core/Portlets/Managers/Runtime/PortletManager"
import {PortletStates} from "Core/Portlets/Enums/PortletStates"
import {GuidToPortletInfoMap} from "Core/Portlets/Utils/GuidToPortletInfoMap"
import {IPortletInfo} from "Core/Portlets/Models/Common/Interfaces/IPortletInfo";

export interface IDraggable {
	Guid: Guid;
	PrevPosition: IPosition;
	NewPosition: IPosition;
}

export class PortletDesignManager extends Event {
	private _portletsCollection: PortletsCollection;
	private _portletExplorer: KnockoutObservable<PortletsExplorer>;
	private _draggedFromExplorer: boolean;

	private _portletExplorereLoaded: boolean;
	private _draggablePortlets: Array<JQuery>;
	private _attachedPortlets: JQuery;

	constructor(portletsCollection: PortletsCollection) {
		super();

		portletsCollection.Entities.map(entity => entity.Portlets.forEach(portlet => portlet.Guid = Guid.NewGuid()));

		this._portletsCollection = portletsCollection;
		this._portletExplorer = ko.observable(new PortletsExplorer(portletsCollection));
		this._draggedFromExplorer = false;

		this._draggablePortlets = [];

		this.BindEvents();
	}

	DestroyDragAndDrop() {
		this._draggablePortlets.forEach((draggable: JQuery) => draggable.sortable("destroy"));
	}

	InitDesignMode() {
		let $explorer = $(".portlet-explorer");
		let $exploredPortlets = $explorer.find(".portlet-draggable");
		let $attachedPortlets = $(".portlet-attached");

		this._draggablePortlets = new Array<JQuery>($exploredPortlets, $attachedPortlets);
		this._attachedPortlets = $attachedPortlets;

		var self = this;
		$exploredPortlets.sortable({
			connectWith: $attachedPortlets,
			helper(event, portlet: JQueryUI.Sortable) {
				this.copyHelper = portlet.clone().insertAfter(portlet);

				$(this).data('copied', true);

				return portlet.clone();
			},
			start() {
				self._draggedFromExplorer = true;
			},
			beforeStop(event, portlet) {
				let copied = $(this).data('copied');
				let droppedIntoExplorer = portlet.item.closest('.portlet-explorer').length > 0;

				if (copied && droppedIntoExplorer) {
					this.copyHelper.remove();
					this.copyHelper = null;
				}
			},
			stop() {
				let copied = $(this).data('copied');

				if (!copied) {
					this.copyHelper.remove();
				}

				this.copyHelper = null;
			}
		}).disableSelection();

		$attachedPortlets.sortable({
			connectWith: $attachedPortlets,
			handle: ".portlet-title",
			placeholder: "portlet-sortable-placeholder",
			opacity: .8,
			revert: 250,
			forcePlaceholderSize: true,
			tolerance: "pointer",
			cancel: ".portlet-fullscreen",
			start(event, portlet) {
				self._draggedFromExplorer = false;

				let rowNumber = portlet.item.index();
				let colNumber = portlet.item.closest('.portlet-block').data('number');

				portlet.item.attr('data-prev-row', rowNumber);
				portlet.item.attr('data-prev-col', colNumber);
			},
			update: (event, portlet) => {
				let screenId = parseInt(portlet.item.attr('screenId'));
				let guid = portlet.item.attr('guid');
				let rowPositon = portlet.item.index();
				let colPositon = portlet.item.closest('.portlet-block').data('number');

				let position: IPosition = {
					RowNumber: rowPositon,
					ColNumber: colPositon
				};

				if (self._draggedFromExplorer) {
					portlet.item.remove();
					self.AddNewPortlet(guid, screenId, position);
				} else {
					let prevRowPosition = portlet.item.data('prevRow');
					let prevColPosition = portlet.item.data('prevCol');

					let prevPosition: IPosition = {
						RowNumber: prevRowPosition,
						ColNumber: prevColPosition
					};

					self.ChangePortletPosition(guid, prevPosition, position);
				}

			}
		}).disableSelection();
	}

	GetPositions(): GuidToPortletInfoMap {
		let guidToPosition = new GuidToPortletInfoMap();

		this._attachedPortlets.toArray().forEach(portletScope => {
			let $portletScope = $(portletScope);
			let $portlets = $portletScope.find('.portlet');

			$portlets.toArray().forEach(portlet => {
				let $portlet = $(portlet);
				let guid = $portlet.attr('guid');

				let position: IPosition = {
					RowNumber: $portlet.index(),
					ColNumber: $portlet.closest('.portlet-attached').data('number')
				};

				let state = $portlet.attr('state');

				let portletInfo: IPortletInfo = {
					Position: position,
					State: PortletStates[state]
				};

				guidToPosition.Add(guid, portletInfo);
			});
		});

		return guidToPosition;
	}

	private OnPortletExlorerLoaded() {
		if (!this._portletExplorereLoaded) {
			this.InitDesignMode();
		}
		this._portletExplorereLoaded = true;
	}

	private BindEvents() {
		this._portletExplorer().On(EVENTS.PORTLETS.EXPLORER_LOADED, this, () => this.OnPortletExlorerLoaded());
	}

	private AddNewPortlet(guid: Guid, screenId: number, position: IPosition) {
		let portlet = new EntityPortlet();
		portlet.Guid = guid;
		portlet.ScreenId = screenId;
		portlet.Position = position;

		this.Trigger(EVENTS.PORTLETS.NEW_PORTLET_ADDED, portlet);
	}

	private ChangePortletPosition(guid: Guid, prevPosition: IPosition, newPosition: IPosition) {
		let droppedObject: IDraggable = {
			Guid: guid,
			PrevPosition: prevPosition,
			NewPosition: newPosition
		};

		this.Trigger(EVENTS.PORTLETS.PORTLET_POSITION_CHANGED, droppedObject);
	}
}