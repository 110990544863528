import {deserializeAs} from 'libs/cerialize';
import {deserialize} from 'libs/cerialize';
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel'
import {ControlGroupModel} from 'Core/Controls/FormDesigner/ToolBar/ControlToolBar/Models/ControlGroupModel'

export class ControlToolBarModel {

	constructor() {
		this.Groups = [];
	}

	@deserializeAs(ControlGroupModel)
	Groups: Array<ControlGroupModel>;
}