import * as _ from 'underscore';

import {Notifier} from 'Core/Common/Notifier';
import {BlockUI} from 'Core/Common/BlockUi';

import {IPropertyDescription, IPropertyOption} from 'Core/GeneralProperties/Managers/BaseProperty';
import {BaseSelectProperty} from 'Core/GeneralProperties/Managers/BaseSelectProperty/BaseSelectProperty';

import {ExampleAppointmentStore} from 'Core/GeneralProperties/Managers/ExampleAppointmentProperty/Stores/ExampleAppointmentStore';
import {ExampleAppointmentResponseModel} from 'Core/GeneralProperties/Managers/ExampleAppointmentProperty/Stores/Models/ExampleAppointmentResponseModel';

import Template from 'Core/GeneralProperties/Managers/ExampleAppointmentProperty/Templates/ExampleAppointmentProperty.html';

ko.templates['Core/GeneralProperties/Managers/ExampleAppointmentProperty/Templates/ExampleAppointmentProperty'] = Template;

export class ExampleAppointmentProperty extends BaseSelectProperty {
    private _propertyValue: IPropertyOption;
    private _isValid: KnockoutObservable<boolean>;
    private _el: HTMLElement;

    constructor(property: IPropertyDescription, propertyValue: IPropertyOption) {
        super(property, propertyValue);

        this._propertyValue = propertyValue;
        this._isValid = ko.observable(true);
    }

    IsValid(): boolean {
        return this._isValid();
    }

    GetTemplateName(): string {
        return 'Core/GeneralProperties/Managers/ExampleAppointmentProperty/Templates/ExampleAppointmentProperty';
    }

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);
        this._el = el[0];

        this._isValid(false);

        BlockUI.Block({Target: this._el});
        this.LoadData()
            .then(exampleAppointments => {
                this.PopulateData(exampleAppointments);
            })
            .fail(error => {
                Notifier.Failed(error.message);
            })
            .always(() => {
                this._isValid(true);
                BlockUI.Unblock(this._el);
            });
    }

    private LoadData()  {
        return ExampleAppointmentStore.GetExampleAppointments();
    }

    private PopulateData(exampleAppointments: ExampleAppointmentResponseModel[]){
        let items: IPropertyOption[] = _.map(exampleAppointments,
            (item: ExampleAppointmentResponseModel) => {
                return {Name: item.Name, Value: item.Id}
            });

        this.Init(items, this._propertyValue);
    }
}