import { ILinkEditor } from "Core/Screens/Common/LinkEditor/LinkEditor";
import { controlFacade } from "./ControlFacade";

export const linkEditorFacade = (screen: ILinkEditor)=> {

    const getControlByFieldName = (fieldName: string) => {
        let control = _.find(screen.GetAllControls(), (c) => c.GetFieldModel() && c.GetFieldModel().Name === fieldName);
        if(control){
            return controlFacade(control);
        }
        return null;
    }
    return { getControlByFieldName }
}