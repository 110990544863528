import {FieldSecurityComponent} from "Core/Controls/FieldSecurity/Components/Base/FieldSecurityComponent";
import {IFieldSecuritySettings} from "Core/Controls/FieldSecurity/Shared/Interfaces/IFieldSecuritySettings";

import {FieldCollectionModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/FieldCollectionModel";

import ViewTemplate from 'Core/Controls/FieldSecurity/Components/DataRole/FieldCollection/View.html';
import EditTemplate from 'Core/Controls/FieldSecurity/Components/DataRole/FieldCollection/Edit.html';

export class FieldCollectionComponent extends FieldSecurityComponent {
	private _model: FieldCollectionModel;

	constructor(settings: IFieldSecuritySettings, model: FieldCollectionModel) {
		super(settings);

		this._model = model;

		this.Templates = {View: ViewTemplate, Edit: EditTemplate, Design: ViewTemplate};
	}

	get FieldCollectionId() {
		return this._model.Id;
	}

	get FieldCollectionName() {
		return this._model.Name;
	}

	get Assignee() {
		return this._model.Assignee;
	}

	OnDataRoleRemoving() {
		this.Trigger('DataRoleRemoving', {DataRole: this._model});
	}
}