import { URL } from 'Core/Common/Url';
import { Request, IRequestOptions } from 'Core/Common/Request';
import { P } from 'Core/Common/Promise';
import { SecurityDataDtoModel } from '../Models/SecurityDataDtoModel';

export class SecurityEditorStore {
	static GetSecurityInfoForRecord(params: { EntityId: number, RecordId: number }): P.Promise<any> {

		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.SECURITY_EDITOR.GET_SECURITY_INFO_FOR_RECORD,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => this.ResolveObjectResponse(data, deferredResult))
			.fail(() => this.RejectResponse("Error getting security information", deferredResult));

		return deferredResult.promise();
	}

	static GetSecurityData(params: { UserId: number }): P.Promise<SecurityDataDtoModel> {

		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.USER.GET_SECURITY_DATA,
				dataType: 'json',
				type: 'GET'
			},
			params: params
		};

		Request.Send(requestParams).then(data => {
			deferredResult.resolve(data);
		}).fail(data => { deferredResult.reject({ message: JSON.parse(data.message).Message }) });

		return deferredResult.promise();
	}

	private static ResolveObjectResponse<T>(response, deferredResult: P.Deferred<T>) {
		if (response.IsSuccessfull) {
			deferredResult.resolve(response.ResultObject);
		} else {
			deferredResult.reject({ message: response.ErrorMessage });
		}
	}

	private static RejectResponse<T>(message: string, deferredResult: P.Deferred<T>) {
		deferredResult.reject({ message: message });
	}

}