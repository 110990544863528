import { RenderModes } from "Core/Constant";

import { IFinancialDashboardStore } from "../Stores/IFinancialDashboardStore";
import { FinancialDashboardMockStore } from "../Stores/FinancialDashboardMockStore";
import { FinancialDashboardStore } from "../Stores/FinancialDashboardStore";

export class StoreFactory {
	static GetStore(renderMode: RenderModes): IFinancialDashboardStore {
		return renderMode === RenderModes.Design
			? new FinancialDashboardMockStore()
			: new FinancialDashboardStore();
	}
}