import * as ko from "knockout";
import {UserAllowanceModel, FlagModel} from "Core/Controls/UserAllowance/Models/UserAllowanceModel";
import {LABELS} from "../../../Components/Translation/Locales";

class FlagViewModel {
	Name: string;
	Value: number;
	UserHasFlag: boolean;

	constructor(model: FlagModel) {
		this.Name = model.Name;
		this.Value = model.Value;
		this.UserHasFlag = model.UserHasFlag;
	}

	//Translation for User Allowance - Flag Name
	get TranslationFlagName(){
		if(this.Value === 1){
			return LABELS.OVERRIDE_SECURITY;
		}else if(this.Value === 2){
			return LABELS.CHANGE_RECORD_SECURITY;
		}else if(this.Value === 4){
			return LABELS.NATIVE_SQL;
		}else if(this.Value === 8){
			return LABELS.PLANNING;
		}else if(this.Value === 32){
			return LABELS.MAKE_PERSONAL_LABEL;
		}else if(this.Value === 64){
			return LABELS.EXPORT_DATA;
		}else if(this.Value === 128){
			return LABELS.MAKE_EXAMPLE_LABLE;
		}

		return this.Name;
	}
}

export class UserAllowanceViewModel {
	UserId: number;
	UserName: string;
	Flags: KnockoutObservableArray<FlagViewModel>;

	constructor(model: UserAllowanceModel) {
		this.UserId = model.UserId;
		this.UserName = model.UserName;

		let flags = model.Flags.map(flag => new FlagViewModel(flag));
		this.Flags = ko.observableArray(flags);
	}
}