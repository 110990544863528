import * as ko from "knockout";
import {ControlFactory} from "Core/Controls/ControlFactory";
import {IScreen, IFormParam, IControlParam, IForm} from "Core/Screens/IScreen";
import {IControl} from "Core/Controls/IControl";
import {SubFormModel} from 'Core/Models/Screens/SubFormModel'
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel'
import {RenderModes} from "Core/Constant";
import {BaseForm} from "Core/Screens/Forms/Base/BaseForm";

import 'Core/Screens/Forms/BottomBar/Templates/BottomBar.html'

export class BottomBar extends BaseForm {

	constructor(params: IFormParam) {
		super(params);
	}

	GetTemplateName(): string {
		return `Core/Screens/Forms/BottomBar/Templates/BottomBar`;
	}
}