import { QueryExpressionModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { QueryEntityJoinModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityJoinModel';
import {QueryColumnModel} from "../Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryColumnModel";
import { QueryConditionGroupModel } from '../Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryConditionGroup';
import { ConditionValueTypes } from 'QueryBuilder/Enums';
import { QueryConditionItemModel } from '../Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryConditionItemModel';
import { g } from 'libs/rappid/build/rappid';

export class Util {

	public static GetParentEntity(entity: QueryEntityModel, expression: QueryExpressionModel): QueryEntityModel{
		let result: QueryEntityModel = null;
		if(expression.Entity === entity) return  null;

		let joins = expression.EntityJoins
			.concat(expression.LookupEntityJoins)
			.concat(expression.ReferenceEntityJoins)
			.concat(expression.ReferenceLookupEntityJoins)
			.concat(expression.SubEntityJoins);

		_.each(joins,
			join => {
				if(join.LinkEntity === entity) {
					result = expression.Entity;
				}
				if(join.Entity === entity){
					result = expression.Entity;
				}

				result = result ? result : this.GetParentEntityFromJoin(join, entity);
			});

		return result;
	}


	static GetParentEntityFromJoin(join: QueryEntityJoinModel, entity: QueryEntityModel): QueryEntityModel {
		let result: QueryEntityModel = null;
		let joins = join.Joins.concat(join.LookupEntityJoins).concat(join.ReferenceEntityJoins).concat(join.SubEntityJoins);

		_.each(joins,
			item => {
				if(item.LinkEntity){
					if(item.LinkEntity === entity){
						result = join.Entity;
					}
				}
				if(item.Entity === entity){
					result = join.Entity;
				};
				result = result ? result : this.GetParentEntityFromJoin(item, entity);
			});

		return result;
	}

	public static GetAllQueryColumns(expression: QueryExpressionModel): Array<QueryColumnModel>{
		let result = [];
		let allEntities = this.GetAllQueryEntities(expression);
		_.each(allEntities, (entity) => {
			result = result.concat(entity.Columns);
		});

		return  result;
	}

	public static GetAllQueryEntities(expression: QueryExpressionModel): Array<QueryEntityModel> {
		let result = [];
		result.push(expression.Entity);

		let allJoins = expression.EntityJoins
			.concat(expression.LookupEntityJoins)
			.concat(expression.ReferenceLookupEntityJoins)
			.concat(expression.ReferenceEntityJoins)
			.concat(expression.SubEntityJoins);

		_.each(allJoins,
			join => {
				if(join){
					if(join.LinkEntity){
						result.push(join.LinkEntity);
					}
					
					result.push(join.Entity);
					result = result.concat(this.GetAllQueryEntitiesFromJoin(join));
				}
			});
		
		let conditions = _.filter(this.GetAllConditions(expression.Condition), item=> item.ValueType === ConditionValueTypes.QueryBuilder);

		_.each(conditions, (condition)=>{
			if(condition.SubQuery){
				result = result.concat(this.GetAllQueryEntities(condition.SubQuery))
			}
		})

		return result;
	}

	static GetAllQueryEntitiesFromJoin(join: QueryEntityJoinModel): Array<QueryEntityModel> {
		let result = [];
		let joins = join.Joins
			.concat(join.LookupEntityJoins)
			.concat(join.ReferenceEntityJoins)
			.concat(join.SubEntityJoins)
			.concat(join.ReferenceLookupEntityJoins ? join.ReferenceLookupEntityJoins : join.ReferenceLookupEntityJoins = []);

		_.each(joins,
			item => {
				if(item.LinkEntity){
					result.push(item.LinkEntity);
				}
				result.push(item.Entity);
				result = result.concat(this.GetAllQueryEntitiesFromJoin(item));
			});

		return result;
	}

	static GetAllConditions(condition: QueryConditionGroupModel): Array<QueryConditionItemModel>{
		var result = [];

		if(!condition){
			return result;
		}

		_.each(condition.Items, (item) => {
			result.push(item);

			if(item.ValueType === ConditionValueTypes.QueryBuilder && item.SubQuery){
				result = result.concat(this.GetAllConditions(item.SubQuery.Condition));
			}
		});

		_.each(condition.ConditionGroups, (group) => {
			result = result.concat(this.GetAllConditions(group));
		});
		return result;
	}

	public static GetAllJoins(expression: QueryExpressionModel): Array<QueryEntityJoinModel> {
		let result = [];
	
		result = [...expression.EntityJoins, ...expression.SubEntityJoins, ...expression.LookupEntityJoins, ...expression.ReferenceLookupEntityJoins, ...expression.ReferenceEntityJoins];

		_.each(result, (join)=>{
			result = result.concat(this.GetAllJoinsFromJoin(join))				
		});

		return result;
	}

	public static GetAllJoinsFromJoin(queryJoin: QueryEntityJoinModel): Array<QueryEntityJoinModel> {
		let result = [];
	
		result = [...queryJoin.Joins, ...queryJoin.SubEntityJoins, ...queryJoin.LookupEntityJoins, ...queryJoin.ReferenceLookupEntityJoins, ...queryJoin.ReferenceEntityJoins];

		_.each(result, (join)=>{
			result = result.concat(this.GetAllJoinsFromJoin(join));
		});

		return result;
	}

	public static GetEntityByGuid(guid: string, query: QueryExpressionModel){
		return _.find(this.GetAllQueryEntities(query), (e)=>e.Guid === guid);
	}
}