export enum JoinTypes {
	InnerJoin,
	LeftJoin,
	RightJoin,
	OuterJoin
}

export enum UseMainCondition {
	MainTrue,
	MainFalse,
	NotUseMain
}

export enum LinkDirectionCondition {
	LeftToRight,
	RightToLeft,
	Both
}

export enum ConditionOperators {
	And,
	Or
}

export enum ComparisonOperators {
	Equals,
	NotEquals,
	IsNull,
	IsNotNull,
	Like,
	GreaterThen,
	LessThen,
	GreaterOrEqual,
	LessOrEqual,
	In,
	StartsWith,
	EndsWith,
	NotIn,
	NotStartWith,
	NotEndWith,
	NotLike
}

export enum SortOrder {
	Ascending,
	Descending
}

export enum ConditionValueTypes {
	General,
	ScreenVariable,
	Function,
	QueryBuilder,
	Column
}
