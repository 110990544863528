import * as ko from 'knockout';
import * as _ from 'underscore';

import { ISelectedEntity, QueryEntity } from 'QueryBuilder/QueryEntity/QueryEntity';
import { QueryEntityJoinModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityJoinModel';
import { QUERY_BUILDER } from 'QueryBuilder/Constants';
import { EVENTS } from 'QueryBuilder/Events';
import { EntityRelationshipsMetadataModel } from 'Core/Controls/Grid/Models/GridDataModel/Metadata/EntityRelationshipsMetadataModel';
import { EntityMetadataStore } from 'QueryBuilder/Stores/EntityMetadataStore';
import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { CanvasPositionModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/CanvasPositionModel';
import { BlockUI } from 'Core/Common/BlockUi';
import { EVENTS as QUERY_LINK_ENTITY_EVENTS } from 'QueryBuilder/QueryEntityJoin/QueryLinkEntity/Events';
import { ZIndexManager } from 'Core/Common/ZIndexManager';
import { ElementZIndexManager } from 'QueryBuilder/ElementZIndexManager';
import { QueryLinkEntity } from 'QueryBuilder/QueryEntityJoin/QueryLinkEntity/QueryLinkEntity';
import { EntityTypes } from 'Core/Controls/Grid/BaseGrid/Enums/EntityTypes';
import { IQueryEntityParams } from 'QueryBuilder/QueryEntity/IQueryEntityParams';
import { IQueryLinkEntityParams } from 'QueryBuilder/QueryEntityJoin/QueryLinkEntity/IQueryLinkEntityParams';
import { IQueryEntityJoinParams } from 'QueryBuilder/QueryEntityJoin/IQueryEntityJoinParams';
import { CanvasSizeModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/CanvasSizeModel';
import { IObjectIndex } from 'QueryBuilder/IObjectIndex';
import { Event } from 'Core/Common/Event';

import QueryEntityJoinTemplate from 'QueryBuilder/QueryEntityJoin/Templates/QueryEntityJoin.html';
import SubQueryEntityJoinTemplate from 'QueryBuilder/QueryEntityJoin/Templates/SubQueryEntityJoin.html';

const DELETE_LIFESTATUS = 'Delete';

export class QueryEntityJoin extends Event {
	private _joins: KnockoutObservableArray<QueryEntityJoin>;
	private _subEntityJoins: KnockoutObservableArray<QueryEntityJoin>;
	private _lookupEntityJoins: KnockoutObservableArray<QueryEntityJoin>;
	private _referenceLookupEntityJoins: KnockoutObservableArray<QueryEntityJoin>;
	private _referenceEntityJoins: KnockoutObservableArray<QueryEntityJoin>;
	private _entity: KnockoutObservable<QueryEntity>;
	private _jsPlumbConnection: any;
	private _minWidth: KnockoutObservable<string>;
	private _minHeight: KnockoutObservable<string>;
	private _el: HTMLElement;
	private _overlayId: string;
	private _canvasWidth: KnockoutObservable<string>;
	private _canvasHeight: KnockoutObservable<string>;
	private _model: QueryEntityJoinModel;
	private _parentEntity: QueryEntity;
	private _jsPlumb: KnockoutObservable<jsPlumbInstance>;
	private _entitiesRelationships: Array<EntityRelationshipsMetadataModel>;
	private _isSubject: boolean;
	private _overlayWrapper: HTMLElement;
	private _overlayBody: HTMLElement;
	private _overlayTitle: HTMLElement;
	private _enabledColumnTypes: Array<string>;
	private _objectIndexes: Array<IObjectIndex>;

	private _joinSource: string | number;

	CanvasHeight: KnockoutComputed<string>;
	CanvasWidth: KnockoutComputed<string>;

	GetTemplateHtml: KnockoutObservable<any>;

	constructor(params: IQueryEntityJoinParams) {
		super();
		this._model = params.Model;
		this._parentEntity = params.ParentEntity;
		this._jsPlumb = params.JsPlumb;
		this._entitiesRelationships = params.EntitiesRelationships;
		this._isSubject = params.IsSubject || false;
		this._overlayWrapper = params.OverlayWrapper;
		this._overlayBody = $(this._overlayWrapper).find('.portlet-body') && $(this._overlayWrapper).find('.portlet-body')[0];
		this._overlayTitle = $(this._overlayWrapper).find('.portlet-title') && $(this._overlayWrapper).find('.portlet-title')[0];
		this._enabledColumnTypes = params.EnabledColumnTypes;
		this._entity = ko.observable(null);
		this._joins = ko.observableArray([]);
		this._subEntityJoins = ko.observableArray([]);
		this._lookupEntityJoins = ko.observableArray([]);
		this._referenceLookupEntityJoins = ko.observableArray([]);
		this._referenceEntityJoins = ko.observableArray([]);
		this._minWidth = ko.observable('0');
		this._minHeight = ko.observable('0');
		this._canvasHeight = ko.observable(this._model.CanvasSize ? this._model.CanvasSize.Height : null);
		this._canvasWidth = ko.observable(this._model.CanvasSize ? this._model.CanvasSize.Width : null);
		this._objectIndexes = params.ObjectIndexes;
		this._joinSource = params.JoinSource;
		
		this.Init();
		var template = this._model.IsSubQuery ? SubQueryEntityJoinTemplate : QueryEntityJoinTemplate;
		this.GetTemplateHtml = ko.observable(template);

		this.CanvasWidth = ko.computed(() => {
			return `${this._canvasWidth()}px`;
		});

		this.CanvasHeight = ko.computed(() => {
			return `${this._canvasHeight()}px`;
		});


		this._joins.subscribe(()=>this.Trigger(EVENTS.UPDATE_SORT));
		this._subEntityJoins.subscribe(()=>this.Trigger(EVENTS.UPDATE_SORT));
		this._lookupEntityJoins.subscribe(()=>this.Trigger(EVENTS.UPDATE_SORT));
		this._referenceLookupEntityJoins.subscribe(()=>this.Trigger(EVENTS.UPDATE_SORT));
		this._referenceEntityJoins.subscribe(()=>this.Trigger(EVENTS.UPDATE_SORT));

		this.AddEvent(EVENTS.COLUMN_SELECTED);
		this.AddEvent(EVENTS.UPDATE_SORT);
	}

	Init() {

		let params: IQueryEntityParams = {
			Model: this._model.Entity,
			JsPlumb: this._jsPlumb,
			EntitiesRelationships: this._entitiesRelationships,
			IsSubject: this._isSubject,
			GridSubjectEntityId: null,
			IsEnableSubQuery: !this._isSubject,
			IsInSubQuery: this._model.IsSubQuery,
			EnabledColumnTypes: this._enabledColumnTypes,
			ObjectIndexes: this._objectIndexes,
			JoinType: this._model.JoinType,
			IsLookupJoin: this.IsLookupJoin,
			IsReferenceJoin: this.IsReferenceJoin
		};

		let queryEntity = new QueryEntity(params);

		if(queryEntity.Model.Metadata.Lifestatus === DELETE_LIFESTATUS && this._parentEntity){
			this._parentEntity.AddDeleteEntityToMenu(this._model.Entity.Metadata);
		}

		queryEntity.On(EVENTS.ENTITY_SELECTED, this, (eventArgs: any) => {
			this.AddJoin(eventArgs.data.EntityId);
		});

		queryEntity.On(EVENTS.ENTITY_UNSELECTED, this, (eventArgs: any) => {
			this.RemoveJoin(eventArgs.data.EntityId, eventArgs.data.Type);
		});

		queryEntity.On(EVENTS.LOOKUP_ENTITY_SELECTED, this, (eventArgs: any) => {
			this.AddLookupJoin(eventArgs.data.EntityId, eventArgs.data.LookupFieldId, 'Lookup');
		});

		queryEntity.On(EVENTS.LOOKUP_ENTITY_UNSELECTED, this, (eventArgs: any) => {
			this.RemoveLookupJoin(eventArgs.data.EntityId, eventArgs.data.LookupFieldId);
		});

        queryEntity.On(EVENTS.REFERENCE_LOOKUP_ENTITY_SELECTED, this, (eventArgs: any) => {
            this.AddReferenceLookupJoin(eventArgs.data.EntityId, eventArgs.data.ReferenceLookupFieldId, 'Lookup');
        });

        queryEntity.On(EVENTS.REFERENCE_LOOKUP_ENTITY_UNSELECTED, this, (eventArgs: any) => {
            this.RemoveReferenceLookupJoin(eventArgs.data.EntityId, eventArgs.data.ReferenceLookupFieldId);
        });

		queryEntity.On(EVENTS.REFERENCE_ENTITY_SELECTED, this, (eventArgs: any) => {
			this.AddReferenceJoin(eventArgs.data.EntityId, eventArgs.data.ReferenceFieldId);
		});

		queryEntity.On(EVENTS.REFERENCE_ENTITY_UNSELECTED, this, (eventArgs: any) => {
			this.RemoveReferenceJoin(eventArgs.data.EntityId, eventArgs.data.ReferenceFieldId);
		});

		queryEntity.On(EVENTS.CREATE_SUB_QUERY, this, (eventArgs: any) => {
			this.DestroyConnections();
			this._model.IsSubQuery = true;
			this._model.CanvasPosition.Left = this._entity().Model.CanvasPosition.Left;
			this._model.CanvasPosition.Top = this._entity().Model.CanvasPosition.Top;
			this._entity().Model.CanvasPosition.Left = QUERY_BUILDER.CELL_SIZE * 8;
			this._entity().Model.CanvasPosition.Top = QUERY_BUILDER.CELL_SIZE * 3;
			this.GetTemplateHtml(SubQueryEntityJoinTemplate);
			this.CalculateMinBoxSize();
		});

		queryEntity.On(EVENTS.REMOVE_SUB_QUERY, this, () => {
			this.RemoveSubQuery();
		});

		queryEntity.On(EVENTS.DRAG, this, () => {
			this._model.CanvasPosition.ZIndex = ZIndexManager.Instance.NextValue;
		});

		queryEntity.On(EVENTS.DRAG_STOP, this, () => {
			this._model.CanvasPosition.ZIndex = ZIndexManager.Instance.NextValue;
		});

		queryEntity.On(EVENTS.CLICK, this, () => {
			ElementZIndexManager.Instance.ToTop(this._jsPlumbConnection.canvas);
			var overlay = this._jsPlumbConnection.getOverlay(this._overlayId);
			if (overlay) {
				ElementZIndexManager.Instance.ToTop(overlay.canvas);
			}
		});

		queryEntity.On(EVENTS.MOUSE_OVER, this, () => {
			ElementZIndexManager.Instance.ToTop(this._jsPlumbConnection.canvas);
			var overlay = this._jsPlumbConnection.getOverlay(this._overlayId);
			if (overlay) {
				ElementZIndexManager.Instance.ToTop(overlay.canvas);
			}
		});

		queryEntity.On(QUERY_LINK_ENTITY_EVENTS.JOIN_TYPE_CHANGED, this, (eventArgs: any) => {
			this._model.JoinType = eventArgs.data.JoinType;
		});

		this._entity(queryEntity);

		let ids: Array<ISelectedEntity> = [];
		_.each(this._model.Joins, item => ids.push({ Id: item.Entity.Metadata.Id, LookupFieldId: null }));
		_.each(this._model.SubEntityJoins, item => ids.push({ Id: item.Entity.Metadata.Id, LookupFieldId: null}));
		_.each(this._model.LookupEntityJoins, item => ids.push({ Id: item.Entity.Metadata.Id, LookupFieldId: item.LookupFieldId}));
		_.each(this._model.ReferenceEntityJoins, item => ids.push({ Id: item.Entity.Metadata.Id, ReferenceFieldId: item.ReferenceFieldId}));
		_.each(this._model.ReferenceLookupEntityJoins, item => ids.push({ Id: item.Entity.Metadata.Id, ReferenceLookupField: item.LookupFieldId }));
		
		queryEntity.SetSelectedEntities(ids);

		this._entity().On(EVENTS.AFTER_RENDER, this, (eventArgs: any) => {
			this._joins([]);
			this._subEntityJoins([]);
			this._lookupEntityJoins([]);
			this._referenceEntityJoins([]);
			this._referenceLookupEntityJoins([]);
			
			this.BuildConnection();
			_.each(this._model.Joins, joinModel => {
				var params: IQueryEntityJoinParams = {
					Model: joinModel,
					ParentEntity: this._entity(),
					JsPlumb: this._jsPlumb,
					EntitiesRelationships: this._entitiesRelationships,
					IsSubject: false,
					OverlayWrapper: this._overlayWrapper,
					EnabledColumnTypes: this._enabledColumnTypes,
					ObjectIndexes: this._objectIndexes
				};

				this._joins.push(new QueryEntityJoin(params));
			});

			_.each(this._model.SubEntityJoins, (joinModel, index) => {
				var params: IQueryEntityJoinParams = {
					Model: joinModel,
					ParentEntity: this._entity(),
					JsPlumb: this._jsPlumb,
					EntitiesRelationships: this._entitiesRelationships,
					IsSubject: false,
					OverlayWrapper: this._overlayWrapper,
					EnabledColumnTypes: this._enabledColumnTypes,
					ObjectIndexes: this._objectIndexes,
					JoinSource: 'Lookup'
				};

				this._subEntityJoins.push(new QueryEntityJoin(params));
			});

			_.each(this._model.LookupEntityJoins, (joinModel) => {
				var params: IQueryEntityJoinParams = {
						Model: joinModel,
						ParentEntity: this._entity(),
						JsPlumb: this._jsPlumb,
						EntitiesRelationships: this._entitiesRelationships,
						IsSubject: false,
						OverlayWrapper: this._overlayWrapper,
						EnabledColumnTypes: this._enabledColumnTypes,
						ObjectIndexes: this._objectIndexes,
						JoinSource: 'Lookup'
					};

					params.JoinSource = this._model.LinkEntity && _.find(this._model.LinkEntity.Metadata.Fields, (field)=> field.Id === joinModel.LookupFieldId) ? 'LinkLookup': 'Lookup';

				this._lookupEntityJoins.push(new QueryEntityJoin(params));
			});

			_.each(this._model.ReferenceLookupEntityJoins, (joinModel) => {
                let params: IQueryEntityJoinParams = {
                    Model: joinModel,
                    ParentEntity: this._entity(),
                    JsPlumb: this._jsPlumb,
                    EntitiesRelationships: this._entitiesRelationships,
                    IsSubject: false,
                    OverlayWrapper: this._overlayWrapper,
                    EnabledColumnTypes: this._enabledColumnTypes,
                    ObjectIndexes: this._objectIndexes,
                    JoinSource: 'Lookup'
                };

                this._referenceLookupEntityJoins.push(new QueryEntityJoin(params));
            });

			_.each(this._model.ReferenceEntityJoins, (joinModel) => {
				let params: IQueryEntityJoinParams = {
					Model: joinModel,
					ParentEntity: this._entity(),
					JsPlumb: this._jsPlumb,
					EntitiesRelationships: this._entitiesRelationships,
					IsSubject: false,
					OverlayWrapper: this._overlayWrapper,
					EnabledColumnTypes: this._enabledColumnTypes,
					ObjectIndexes: this._objectIndexes,
					JoinSource: joinModel.ReferenceFieldId
				};

				this._referenceEntityJoins.push(new QueryEntityJoin(params));
			});
		});
	}

	get IsLookupJoin(): boolean {
		return this._model.LookupFieldId != null && this._model.LookupFieldId != 0;
	}

	get IsReferenceJoin(): boolean{
		return this._model.ReferenceFieldId != null && this._model.ReferenceFieldId != 0;
	}

	private BuildConnection() {

		let source = this._parentEntity.Wrapper;
		let leftAnchor = 'RightMiddle';

		if(this._joinSource === 'Lookup' || !this._joinSource){
			source = $(this._parentEntity.Wrapper).find('.entity-list-menu')[0];
		}else if(this._joinSource === 'LinkLookup'){
			source = $(this._parentEntity.Wrapper).find('.entity-link-list-menu')[0];
		}else if(this._joinSource){
			source = $(this._parentEntity.Wrapper).find(`.${this._joinSource}`)[0];
		}

		let fix = this._jsPlumb().connect({
			source: this._parentEntity.Wrapper,
			target:  this._entity().Wrapper,
			connector: ['Flowchart', { stub: [10, 10], gap: 0, cornerRadius: 5, alwaysRespectStubs: true }],
			endpoint: 'Blank',
			anchors: [leftAnchor, 'TopCenter'],
			paintStyle: {
				stroke: 'gray', strokeWidth: 5}
		});

		this._jsPlumbConnection = this._jsPlumb().connect({
			source: source,
			target:  this._entity().Wrapper,
			connector: ['Flowchart', { stub: [10, 10], gap: 0, cornerRadius: 5, alwaysRespectStubs: true }],
			endpoint: 'Blank',
			anchors: ['BottomCenter', 'LeftMiddle'],
			paintStyle: {
				stroke: 'gray', strokeWidth: 5}
		});

		this._jsPlumb().deleteConnection(fix);

		if(this._model.LinkEntity){
			var params: IQueryLinkEntityParams = {
				Parent: this._parentEntity.Model,
				Model: this._model.LinkEntity,
				BackBackgroundColor: this._entity().BackgroundColor,
				JoinType: this._model.JoinType,
				UseMain: this._model.UseMain,
				LinkDirection: this._model.LinkDirection,
				EntitiesRelationships: this._entitiesRelationships,
				EnabledColumnTypes: this._enabledColumnTypes,
				ObjectIndexes: this._objectIndexes
			};

			var linkEntity = new QueryLinkEntity(params);
			linkEntity.On(QUERY_LINK_ENTITY_EVENTS.JOIN_TYPE_CHANGED, this, (eventArgs: any) => {
				this._model.JoinType = eventArgs.data.JoinType;
			});

			linkEntity.On(QUERY_LINK_ENTITY_EVENTS.USE_MAIN_CHANGED, this, (eventArgs: any) => {
				this._model.UseMain = eventArgs.data.UseMain;
			});

			linkEntity.On(QUERY_LINK_ENTITY_EVENTS.LINK_DIRECTION_CHANGED, this, (eventArgs: any) => {
				this._model.LinkDirection = eventArgs.data.LinkDirection;
			});

			linkEntity.On(QUERY_LINK_ENTITY_EVENTS.CLICK, this, () => {
				ElementZIndexManager.Instance.ToTop(this._jsPlumbConnection.canvas);
				if (this._entity()) {
					this._entity().ToTop();
				}
				var overlay = this._jsPlumbConnection.getOverlay(this._overlayId);
				if (overlay) {
					ElementZIndexManager.Instance.ToTop(overlay.canvas);
				}
			});

			linkEntity.On(QUERY_LINK_ENTITY_EVENTS.MOUSE_OVER, this, () => {
				ElementZIndexManager.Instance.ToTop(this._jsPlumbConnection.canvas);
				if (this._entity()) {
					this._entity().ToTop();
				}
				var overlay = this._jsPlumbConnection.getOverlay(this._overlayId);
				if (overlay) {
					ElementZIndexManager.Instance.ToTop(overlay.canvas);
				}
			});

			linkEntity.On(EVENTS.LOOKUP_ENTITY_SELECTED, this, (eventArgs: any) => {
				this.AddLookupJoin(eventArgs.data.EntityId, eventArgs.data.LookupFieldId, 'LinkLookup');
			});

			linkEntity.On(EVENTS.LOOKUP_ENTITY_UNSELECTED, this, (eventArgs: any) => {
				this.RemoveLookupJoin(eventArgs.data.EntityId, eventArgs.data.LookupFieldId);
			});

			linkEntity.On(EVENTS.REFERENCE_ENTITY_SELECTED, this, (eventArgs: any) => {
				this.AddReferenceJoin(eventArgs.data.EntityId, eventArgs.data.ReferenceFieldId);
			});

			linkEntity.On(EVENTS.REFERENCE_ENTITY_UNSELECTED, this, (eventArgs: any) => {
				this.RemoveReferenceJoin(eventArgs.data.EntityId, eventArgs.data.ReferenceFieldId);
			});

			let ids: Array<ISelectedEntity> = [];
			_.each(this._model.LookupEntityJoins, item => ids.push({ Id: item.Entity.Metadata.Id, LookupFieldId: item.LookupFieldId}));
			_.each(this._model.ReferenceEntityJoins, item => ids.push({ Id: item.Entity.Metadata.Id, ReferenceFieldId: item.ReferenceFieldId}));

			linkEntity.SetSelectedEntities(ids);

			linkEntity.On(EVENTS.COLUMN_SELECTED, this, (eventArgs) =>{ this.Trigger(EVENTS.COLUMN_SELECTED, {Id: eventArgs.data.Id});	});
			this._entity().LinkEntity(linkEntity);
		}

		this._jsPlumb().repaintEverything();

	}

	DestroyConnections() {
		this._jsPlumb().deleteConnection(this._jsPlumbConnection);
		_.each(this.Joins(), join => {
			join.Destroy();
		});
	}

	Destroy() {
		this._jsPlumb().deleteConnection(this._jsPlumbConnection);
		_.each(this._joins(), join => {
			join.Destroy();
		});
		this._jsPlumb().repaintEverything();
	}

	get Entity(): KnockoutObservable<QueryEntity> {
		return this._entity;
	}

	get Model(): QueryEntityJoinModel {
		return this._model;
	}

	get Joins(): KnockoutObservableArray<QueryEntityJoin> {
		return this._joins;
	}

	get SubEntityJoins(): KnockoutObservableArray<QueryEntityJoin> {
		return this._subEntityJoins;
	}

	get LookupEntityJoins(): KnockoutObservableArray<QueryEntityJoin> {
		return this._lookupEntityJoins;
	}

	get ReferenceEntityJoins(): KnockoutObservableArray<QueryEntityJoin> {
		return this._referenceEntityJoins;
	}

	RemoveSubQuery(){
		this.DestroyConnections();
		this._model.IsSubQuery = false;
		this._entity().Model.CanvasPosition.Left = this._model.CanvasPosition.Left;
		this._entity().Model.CanvasPosition.Top = this._model.CanvasPosition.Top;
		this._entity().RemoveSubQuery();
		this.GetTemplateHtml(QueryEntityJoinTemplate);
	}

	AddJoin(entityId: number) {
		this.BlockOverlayBodyAndTitle();
		EntityMetadataStore.GetEntityMetadata({ EntityId: entityId })
			.always(() => {
				this.UnBlockOverlayBodyAndTitle();
			})
			.then((data) => {
				var queryEntityModel = new QueryEntityModel();
				queryEntityModel.Metadata = data.EntityMetadata;

				if (data.EntityMetadata.Type === EntityTypes[EntityTypes.Entity]) {
					var entityJoinModel = new QueryEntityJoinModel();
					entityJoinModel.Entity = queryEntityModel;

					var relationship = _.find(data.RelatedEntitiesMetadata,
						item => item.EntityMetadata.Id === this._entity().Model.Metadata.Id);
					if (relationship) {
						var queryLikEntityModel = new QueryEntityModel();
						queryLikEntityModel.Metadata = relationship.LinkEntityMetadata;
						entityJoinModel.LinkEntity = queryLikEntityModel;
					}

					entityJoinModel.Entity.CanvasPosition = this.CalculatePosition();
					this._model.Joins.push(entityJoinModel);

					var entityJoinParams: IQueryEntityJoinParams = {
						Model: entityJoinModel,
						ParentEntity: this._entity(),
						JsPlumb: this._jsPlumb,
						EntitiesRelationships: [data],
						IsSubject: false,
						OverlayWrapper: this._overlayWrapper,
						EnabledColumnTypes: this._enabledColumnTypes,
						ObjectIndexes: this._objectIndexes
					};

					this._joins.push(new QueryEntityJoin(entityJoinParams));
					this.CalculateMinBoxSize();
				}

				if (data.EntityMetadata.Type === EntityTypes[EntityTypes.Sub]) {
					var subEntityJoinModel = new QueryEntityJoinModel();
					subEntityJoinModel.Entity = queryEntityModel;
					subEntityJoinModel.Entity.CanvasPosition = this.CalculatePosition();

					var subEntityJoinParams: IQueryEntityJoinParams = {
						Model: subEntityJoinModel,
						ParentEntity: this._entity(),
						JsPlumb: this._jsPlumb,
						EntitiesRelationships: [data],
						IsSubject: false,
						OverlayWrapper: this._overlayWrapper,
						EnabledColumnTypes: this._enabledColumnTypes,
						ObjectIndexes: this._objectIndexes,
						JoinSource: 'Lookup'
					};

					var subEntityJoin = new QueryEntityJoin(subEntityJoinParams);
					this._model.SubEntityJoins.push(subEntityJoinModel);
					this._subEntityJoins.push(subEntityJoin);
				}
			});
	}

	AddLookupJoin(entityId: number, lookupFieldId: number, source: string) {
		this.BlockOverlayBodyAndTitle();
		EntityMetadataStore.GetEntityMetadata({ EntityId: entityId })
			.always(() => {
				this.UnBlockOverlayBodyAndTitle();
			})
			.then((data) => {
				let queryEntityModel = new QueryEntityModel();
				queryEntityModel.Metadata = data.EntityMetadata;

					let entityJoinModel = new QueryEntityJoinModel();
					entityJoinModel.Entity = queryEntityModel;
					entityJoinModel.LookupFieldId = lookupFieldId;

					entityJoinModel.Entity.CanvasPosition = this.CalculatePosition();
					this._model.LookupEntityJoins.push(entityJoinModel);

					var entityJoinParams: IQueryEntityJoinParams = {
						Model: entityJoinModel,
						ParentEntity: this._entity(),
						JsPlumb: this._jsPlumb,
						EntitiesRelationships: [data],
						IsSubject: false,
						OverlayWrapper: this._overlayWrapper,
						EnabledColumnTypes: this._enabledColumnTypes,
						ObjectIndexes: this._objectIndexes,
						JoinSource: source
					};

					this._lookupEntityJoins.push(new QueryEntityJoin(entityJoinParams));
					this.CalculateMinBoxSize();
			});
	}

	AddReferenceJoin(entityId: number, referenceFieldId: number) {
		BlockUI.Block({ Target: this._overlayWrapper, ZIndex: ZIndexManager.Instance.NextValue});
		EntityMetadataStore.GetEntityMetadata({ EntityId: entityId })
			.always(() => {BlockUI.Unblock(this._overlayWrapper);})
			.then((data) => {
				let queryEntityModel = new QueryEntityModel();
				queryEntityModel.Metadata = data.EntityMetadata;

				let entityJoinModel = new QueryEntityJoinModel();
				entityJoinModel.Entity = queryEntityModel;
				entityJoinModel.ReferenceFieldId = referenceFieldId;

				entityJoinModel.Entity.CanvasPosition = this.CalculatePosition();
				this._model.ReferenceEntityJoins.push(entityJoinModel);

				var entityJoinParams: IQueryEntityJoinParams = {
					Model: entityJoinModel,
					ParentEntity: this._entity(),
					JsPlumb: this._jsPlumb,
					EntitiesRelationships: [data],
					IsSubject: false,
					OverlayWrapper: this._overlayWrapper,
					EnabledColumnTypes: this._enabledColumnTypes,
					ObjectIndexes: this._objectIndexes,
					JoinSource: referenceFieldId
				};

				this._referenceEntityJoins.push(new QueryEntityJoin(entityJoinParams));
				this.CalculateMinBoxSize();
			});
	}

    AddReferenceLookupJoin(entityId: number, lookupFieldId: number, source: string) {
        BlockUI.Block({ Target: this._overlayWrapper, ZIndex: ZIndexManager.Instance.NextValue});
        EntityMetadataStore.GetEntityMetadata({ EntityId: entityId })
            .always(() => {BlockUI.Unblock(this._overlayWrapper);})
            .then((data) => {
                let queryEntityModel = new QueryEntityModel();
                queryEntityModel.Metadata = data.EntityMetadata;

                let entityJoinModel = new QueryEntityJoinModel();
                entityJoinModel.Entity = queryEntityModel;
                entityJoinModel.LookupFieldId = lookupFieldId;

                entityJoinModel.Entity.CanvasPosition = this.CalculatePosition();
                this._model.ReferenceLookupEntityJoins.push(entityJoinModel);

                var entityJoinParams: IQueryEntityJoinParams = {
                    Model: entityJoinModel,
                    ParentEntity: this._entity(),
                    JsPlumb: this._jsPlumb,
                    EntitiesRelationships: [data],
                    IsSubject: false,
                    OverlayWrapper: this._overlayWrapper,
                    EnabledColumnTypes: this._enabledColumnTypes,
                    ObjectIndexes: this._objectIndexes,
                    JoinSource: source
                };

                this._referenceLookupEntityJoins.push(new QueryEntityJoin(entityJoinParams));
            });
    }

    RemoveReferenceLookupJoin(entityId: number, lookupFieldId: number) {
        let entityJoin = _.find(this._referenceLookupEntityJoins(), join => { return join.Model.Entity.Metadata.Id === entityId && join.Model.LookupFieldId == lookupFieldId });
        entityJoin.Destroy();
        let entityJoinModel = _.find(this._model.ReferenceLookupEntityJoins, join => { return join.Entity.Metadata.Id === entityId && join.LookupFieldId == lookupFieldId });
        this._model.ReferenceLookupEntityJoins.splice(this._model.ReferenceLookupEntityJoins.indexOf(entityJoinModel), 1);
        this._referenceLookupEntityJoins.splice(this._referenceLookupEntityJoins().indexOf(entityJoin), 1);
	}

	CalculatePosition(): CanvasPositionModel {
		let canvasPosition = new CanvasPositionModel();
		let joins = [];
		joins = joins.concat(this._joins()).concat(this._subEntityJoins()).concat(this._lookupEntityJoins()).concat(this._referenceEntityJoins());
		let maxTopJoin = _.max(joins, (item: QueryEntityJoin) => item.Model.Entity.CanvasPosition.Top);

		if (!_.isEmpty(maxTopJoin)) {
			canvasPosition.Left = maxTopJoin.Model.Entity.CanvasPosition.Left;
			canvasPosition.Top =  +maxTopJoin.Model.Entity.CanvasPosition.Top + QUERY_BUILDER.CELL_SIZE * 3;
		} else {
			canvasPosition.Left = +this._entity().Model.CanvasPosition.Left + QUERY_BUILDER.CELL_SIZE * 14;
			canvasPosition.Top = +this._entity().Model.CanvasPosition.Top + QUERY_BUILDER.CELL_SIZE * 3;
		}

		return canvasPosition;
	}


	RemoveJoin(entityId: number, type: string) {
		if (type === EntityTypes[EntityTypes.Entity]) {
			var entityJoin = _.find(this._joins(), join => { return join.Model.Entity.Metadata.Id === entityId });
			entityJoin.Destroy();
			var entityJoinModel = _.find(this._model.Joins, join => { return join.Entity.Metadata.Id === entityId });
			this._model.Joins.splice(this._model.Joins.indexOf(entityJoinModel), 1);
			this._joins.splice(this._joins.indexOf(entityJoin), 1);
			this.CalculateMinBoxSize();
			this.DecreaseObjectIndex(entityJoinModel.Entity.Metadata.Id, entityJoinModel.Entity.Index);
		}

		if (type === EntityTypes[EntityTypes.Sub]) {
			let subEntityJoin = _.find(this._subEntityJoins(), join => { return join.Model.Entity.Metadata.Id === entityId });
			if (subEntityJoin) {
				subEntityJoin.Destroy();
				let subEntityJoinModel = _.find(this._model.SubEntityJoins, join => { return join.Entity.Metadata.Id === entityId });
				this._model.SubEntityJoins.splice(this._model.SubEntityJoins.indexOf(subEntityJoinModel), 1);
				this._subEntityJoins.splice(this._subEntityJoins.indexOf(subEntityJoin), 1);
				this.DecreaseObjectIndex(subEntityJoinModel.Entity.Metadata.Id, subEntityJoinModel.Entity.Index);
			}
		}
	}

	DecreaseObjectIndex(entityId: number, index: number) {
		let objIndex = _.find(this._objectIndexes, (indx) => {
			return indx.Id === entityId;
		});
		if(objIndex && objIndex.Index === index){
			objIndex.Index = +objIndex.Index - 1;
		}
	}

	RemoveLookupJoin(entityId: number, lookupFieldId: number) {
		let entityJoin = _.find(this._lookupEntityJoins(), join => { return join.Model.Entity.Metadata.Id === entityId && join.Model.LookupFieldId == lookupFieldId });
		entityJoin.Destroy();
		let entityJoinModel = _.find(this._model.LookupEntityJoins, join => { return join.Entity.Metadata.Id === entityId && join.LookupFieldId == lookupFieldId });
		this._model.LookupEntityJoins.splice(this._model.LookupEntityJoins.indexOf(entityJoinModel), 1);
		this._lookupEntityJoins.splice(this._lookupEntityJoins().indexOf(entityJoin), 1);
		this.CalculateMinBoxSize();
		this.DecreaseObjectIndex(entityJoinModel.Entity.Metadata.Id, entityJoinModel.Entity.Index);
	}

	RemoveReferenceJoin(entityId: number, referenceFieldId: number) {
		let entityJoin = _.find(this._referenceEntityJoins(), join => { return join.Model.Entity.Metadata.Id === entityId && join.Model.ReferenceFieldId == referenceFieldId });
		if(entityJoin){
			entityJoin.Destroy();
			let entityJoinModel = _.find(this._model.ReferenceEntityJoins, join => { return join.Entity.Metadata.Id === entityId && join.ReferenceFieldId == referenceFieldId });
			this._model.ReferenceEntityJoins.splice(this._model.ReferenceEntityJoins.indexOf(entityJoinModel), 1);
			this._referenceEntityJoins.splice(this._referenceEntityJoins().indexOf(entityJoin), 1);
			this.DecreaseObjectIndex(entityJoinModel.Entity.Metadata.Id, entityJoinModel.Entity.Index);
			this.CalculateMinBoxSize();
		}
	}

	AfterRender(el) {
		if (this._model.IsSubQuery) {
			this._el = el[0];
			this.CalculateMinBoxSize();
			var parent = $(this._el).parent();
			var isRoot = $(parent).hasClass('querybuilder-root-canvas');

			$(this._el).draggable({
				containment: isRoot ? false : 'parent',
				grid: [20, 20],
				drag: () => {
					this._jsPlumb().repaintEverything();
				},
				stop: (evt, ui) => {
					this._jsPlumb().repaintEverything();
					this._model.CanvasPosition.Top = ui.position.top;
					this._model.CanvasPosition.Left = ui.position.left;
				}
			});

		}
	}

	StartResize() {
		this.CalculateMinBoxSize();
	}

	StopResize(evt, ui) {
		if (!this._model.CanvasSize) {
			this._model.CanvasSize = new CanvasSizeModel();
		}

		this._model.CanvasSize.Height = ui.size.height;
		this._model.CanvasSize.Width = ui.size.width;
	}

	GetLeftPosition(): string {
		return `${this._model.CanvasPosition.Left}px`;
	}

	GetTopPosition(): string {
		return `${this._model.CanvasPosition.Top}px`;
	}

	get MinWidth(): KnockoutObservable<string> {
		return this._minWidth;
	}

	get MinHeight(): KnockoutObservable<string> {
		return this._minHeight;
	}

	private CalculateMinBoxSize() {
		var wrappers = this.GetAllWrappers();
		var maxLeftWrapper = _.max(wrappers, wrapper => { return $(wrapper).position().left });
		var maxTopWrapper = _.max(wrappers, wrapper => { return $(wrapper).position().top });
		this._minWidth(String($(maxLeftWrapper).position().left + $(maxLeftWrapper).width() + (QUERY_BUILDER.CELL_SIZE * 5)));
		this._minHeight(String($(maxTopWrapper).position().top + $(maxTopWrapper).height() + (QUERY_BUILDER.CELL_SIZE)));

		if (+this._minWidth() > +this._canvasWidth()){
			this._canvasWidth(this._minWidth());
		}

		if (+this._minHeight() > +this._canvasHeight()) {
			this._canvasHeight(this._minHeight());
		}
	}

	private BlockOverlayBodyAndTitle() {
		if(!this._overlayTitle && !this._overlayBody) {
			BlockUI.Block({ Target: this._overlayWrapper, ZIndex: ZIndexManager.Instance.NextValue });
			return;
		}

		if(this._overlayBody) {
			BlockUI.Block({ Target: this._overlayBody, ZIndex: ZIndexManager.Instance.NextValue });
		}

		if(this._overlayTitle) {
			BlockUI.Block( { Target: this._overlayTitle, ZIndex: ZIndexManager.Instance.NextValue });
		}
	}

	private UnBlockOverlayBodyAndTitle() {
		if(!this._overlayTitle && !this._overlayBody) {
			BlockUI.Unblock(this._overlayWrapper);
			return;
		}

		if(this._overlayBody) {
			BlockUI.Unblock(this._overlayBody);
		}

		if(this._overlayTitle) {
			BlockUI.Unblock(this._overlayTitle);
		}
	}

	GetAllWrappers() {
		var wrappers = [];
		wrappers.push(this._entity().Wrapper);

		_.each(this.AllJoins, join_ => {
			wrappers = wrappers.concat(this.GetAllJoinWrappers(join_));
		});
		return wrappers;
	}

	GetAllJoinWrappers(join: QueryEntityJoin) {
		var wrappers = [];
		let wrapper = join.Model.IsSubQuery ? join._el : join._entity().Wrapper;
		wrappers.push(wrapper);
		_.each(join.AllJoins, join_ => {
			wrappers = wrappers.concat(this.GetAllJoinWrappers(join_));
		});
		return wrappers;
	}

	get AllJoins(): Array<QueryEntityJoin>{
		return [...this._joins(), ...this._lookupEntityJoins(), ...this._subEntityJoins(), ...this._referenceLookupEntityJoins(), ...this._referenceEntityJoins()];
	}

	get ReferenceLookupEntityJoins(): KnockoutObservableArray<QueryEntityJoin> {
		return this._referenceLookupEntityJoins;
	}
}