export const USER_ALLOWANCE = {
	OVERRIDE_SECURITY: 1,
	CHANGE_RECORD_SECURITY: 2,
	NATIVE_SQL: 4,
	PLANNING: 8,
	MAKE_PERSONAL: 32,
	EXPORT_DATA: 64,
	MAKE_EXAMPLE: 128,

	HasValue: (value: number, userAllowance: number) => (value & userAllowance) === value,
	PlanningAllowed: (userAllowance: number) => (USER_ALLOWANCE.PLANNING & userAllowance) === USER_ALLOWANCE.PLANNING
};