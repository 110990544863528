import {deserialize, deserializeAs} from 'libs/cerialize';
export class UploadChunkModel {
	@deserialize
	FileName: string;

	@deserialize
	FileChunk: any;

	constructor(fileName?: string, fileChunk?: any) {
		this.FileName = fileName;
		this.FileChunk = fileChunk;
	}
}