import clone from 'clone';

import { CollectionModel } from "DatabaseDesigner/Models/CollectionModel";
import { LinkEditorTabSettingsModel } from "DatabaseDesigner/Models/LinkEditorTabSettingsModel";
import { TableBaseModel } from "DatabaseDesigner/Models/TableBaseModel";
import { TableTypeModel } from "DatabaseDesigner/Models/TableTypeModel";
import { TranslationModel } from "DatabaseDesigner/Models/TranslationModel";
import {util} from "../../libs/rappid/build/rappid";
import string = util.format.string;
import {LanguageModel} from "Core/Controls/BaseControl/Models/LanguageModel";
import {TranslationModel as MemoTranslationModel} from "Core/Controls/BaseControl/Models/TranslationModel";

export function LinkTableModel() {
    this._k1TableId = 0;
    this._k2TableId = 0;
    this._k1TableGuid = 0;
    this._k1TableGuid = 0;
    this._linkListRelations = null;
    this._reversePassSecurity = false;
    this._oneParent = false;
    this._types = new CollectionModel({ model: TableTypeModel });
    this._linkEditorTabSettings = new CollectionModel({ model: LinkEditorTabSettingsModel });
    this._translations = new CollectionModel({ model: TranslationModel });
    this._memo = "";
    this._memoTranslations = new CollectionModel({ model: TranslationModel });

    TableBaseModel.apply(this);
}

LinkTableModel.prototype = Object.create(TableBaseModel.prototype);
LinkTableModel.prototype.constructor = LinkTableModel;
LinkTableModel.prototype.__super__ = TableBaseModel;

LinkTableModel.prototype.Clone = function () {
    return clone(this);
};

LinkTableModel.prototype.FromJSONArray = function (tables) {
    var tableList = new CollectionModel({ model: LinkTableModel });
    _.each(tables, function (table) {
        var tableModel = new LinkTableModel();
        tableModel.FromJSON(table);
        tableList.Add(tableModel);
    });
    return tableList;
}

LinkTableModel.prototype.toJSON = function (onlyModified) {
    var result = this.__super__.prototype.toJSON.call(this, onlyModified);
    if (!result) {
        result = {};
    }
    result.K1TableId = this.K1TableId;
    result.K2TableId = this.K2TableId;
    result.K1TableGuid = this.K1TableGuid;
    result.K2TableGuid = this.K2TableGuid;
    result.ReversePassSecurity = this.ReversePassSecurity;
    result.OneParent = this.OneParent;
    result.LinkListRelations = this.LinkListRelations;
    result.Types = this.Types.toJSON(onlyModified);
    result.LinkEditorTabSettings = this.LinkEditorTabSettings.toJSON();
    result.Translations = this.Translations.toJSON();
    result.MemoTranslations = this.MemoTranslations;
    result.Memo = this.Memo;


    if (onlyModified && onlyModified === true) {
        if (this._isModified !== true) {
            result = null;
        }
    }
    return result;
};

LinkTableModel.prototype.FromJSON = function (table) {
    var self = this;

    self.__super__.prototype.FromJSON.call(this, table);
    self._k1TableId = table.K1TableId;
    self._k2TableId = table.K2TableId;
    self._reversePassSecurity = table.ReversePassSecurity;
    self._oneParent = table.OneParent;

    self._types = TableTypeModel.prototype.FromJSONArray(table.Types);
    self._linkEditorTabSettings = LinkEditorTabSettingsModel.prototype.FromJSONArray(table.LinkEditorTabSettings);
    self._translations = TranslationModel.prototype.FromJSONArray(table.Translations);
    self._memo = table.Memo;
    self._memoTranslations = table.MemoTranslations;


    _.each(self.Fields.Items, function (field: any) {
        _.each(field.TableFiels.Items, function (tableFiel: any) {
            var tableType = _.find(self.Types.Items, function (type: any) {
                return type.Id === tableFiel.TableTypeId
            });
            if (tableType) {
                tableFiel.TableTypeGuid = tableType.Guid;
            }
        });
    });
};

LinkTableModel.prototype.CreateDefaultTranslations = function (languages) {
    if (languages) {
        var self = this;
        self._translations = new CollectionModel({ model: TranslationModel });

        _.forEach(languages, function (language) {
            var translation = new TranslationModel();
            translation.Language = language;
            self._translations.Add(translation);
        });
    }
}

LinkTableModel.prototype.CreateMemoTranslations = function (languages) {
    if (languages) {
        var self = this;
        self._memoTranslations = [];

        _.forEach(languages, function (language: LanguageModel) {
            var translation = new MemoTranslationModel();
            translation.Language = new LanguageModel(language.K_Language, language.Name, language.ShortName, language.Enabled, language.FlagBase64);
            translation.Translation = "";
            self._memoTranslations.push(translation);
        });
    }
};

Object.defineProperty(LinkTableModel.prototype, 'LinkListRelations', {
    enumerable: true,
    get: function () {
        return this._linkListRelations;
    },
    set: function (val) {
        this._linkListRelations = val;
    }
});

Object.defineProperty(LinkTableModel.prototype, 'K1TableId', {
    enumerable: true,
    get: function () {
        return this._k1TableId;
    },
    set: function (val) {
        this._k1TableId = val;
    }
});

Object.defineProperty(LinkTableModel.prototype, 'K2TableId', {
    enumerable: true,
    get: function () {
        return this._k2TableId;
    },
    set: function (val) {
        this._k2TableId = val;
    }
});

Object.defineProperty(LinkTableModel.prototype, 'K1TableGuid', {
    enumerable: true,
    get: function () {
        return this._k1TableGuid;
    },
    set: function (val) {
        this._k1TableGuid = val;
    }
});

Object.defineProperty(LinkTableModel.prototype, 'K2TableGuid', {
    enumerable: true,
    get: function () {
        return this._k2TableGuid;
    },
    set: function (val) {
        this._k2TableGuid = val;
    }
});

Object.defineProperty(LinkTableModel.prototype, 'Types', {
    enumerable: true,
    get: function () {
        return this._types;
    },
    set: function (val) {
        this._types = val;
    }
});

Object.defineProperty(LinkTableModel.prototype, 'LinkEditorTabSettings', {
    enumerable: true,
    get: function () {
        return this._linkEditorTabSettings;
    },
    set: function (value) {
        this._linkEditorTabSettings = value;
    }
});

Object.defineProperty(LinkTableModel.prototype, 'Translations', {
    enumerable: true,
    get: function () {
        return this._translations;
    },
    set: function (val) {
        this._translations = val;
    }
});

Object.defineProperty(LinkTableModel.prototype, 'MemoTranslations', {
    enumerable: true,
    get: function () {
        return this._memoTranslations;
    },
    set: function (val) {
        this._memoTranslations = val;
    }
});

Object.defineProperty(LinkTableModel.prototype, 'ReversePassSecurity', {
    enumerable: true,
    get: function () {
        return this._reversePassSecurity;
    },
    set: function (val) {
        this._reversePassSecurity = val;
    }
});

Object.defineProperty(LinkTableModel.prototype, 'OneParent', {
    enumerable: true,
    get: function () {
        return this._oneParent;
    },
    set: function (val) {
        this._oneParent = val;
    }
});

Object.defineProperty(LinkTableModel.prototype, 'IsSelfRelation', {
    enumerable: true,
    get: function () {
        return this._k1TableGuid === this._k2TableGuid;
    }
});

Object.defineProperty(LinkTableModel.prototype, 'IsUserRelation', {
    enumerable: true,
    get: function () {
        return this.Name.startsWith('SYS_USER_') || this.Name.endsWith('_USER');
    }
});