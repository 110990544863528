import * as ko from "knockout";
import 'jquery';
import { ZIndexManager } from 'Core/Common/ZIndexManager';

export class AutoCompleteExtention {

    static Init() {
        ko.bindingHandlers.autoComplete = {
            init: (element, valueAccessor) => {
                let source = valueAccessor().source;
                if (source.length != 0){
                    this.InitAutocomplete(element, valueAccessor);
                }
            },

            update: (element, valueAccessor, allBindings, viewModel, bindingContext) => {
                let valueUnwrapped = ko.unwrap(valueAccessor());
                if (valueUnwrapped.source.length != 0){
                    this.InitAutocomplete(element, valueUnwrapped, true);
                }
            }
        };
    }

    private static InitAutocomplete(element, valueAccessor, update?: boolean){
        const OnSelect = update ? valueAccessor.onSelect : valueAccessor().onSelect;
        let source = update ? valueAccessor.source : valueAccessor().source;
        let options = update ? valueAccessor.options : valueAccessor().options;

        $(element).autocomplete({
            minLength: options && options.minLength ? options.minLength : null,
            autoFocus: options && options.autoFocus ? options.autoFocus : false,
            source: source,
            open: function(){
                $(this).autocomplete('widget').css({
                    'z-index': ZIndexManager.Instance.NextValue,
                    'max-width': $(this).outerWidth() + 'px'
                });
                return false;
            },
            select: function (event, ui) {
                OnSelect(ui.item);
            }
        });
    }
}