import {deserialize, deserializeAs, serialize, serializeAs} from "libs/cerialize";
import { SelectedEntityModel } from "./SelectedEntityModel";
import { SelectedRecordModel } from "./SelectedRecordModel";

export class SelectedEntityRecordModel {
	@deserializeAs(SelectedEntityModel)
	SelectedEntity: SelectedEntityModel;

	@deserializeAs(SelectedRecordModel)
	SelectedRecord: SelectedRecordModel;

	@deserialize
	IsValid: boolean;

	@deserialize
	ValidationError: string;
}