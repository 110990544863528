import * as ko from "knockout";
import * as _ from 'underscore';
import * as moment from "moment";

import {BlockUI} from 'Core/Common/BlockUi';
import {Event} from "Core/Common/Event";
import {Notifier} from "Core/Common/Notifier";
import {P} from "Core/Common/Promise";

import {LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";
import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";
import {GlobalModel} from "Core/GlobalManager/Models/GlobalModel";
import {ISchedulerParams} from "../ISchedulerParams";
import {SchedulerMappingProfile} from "../Mappings/SchedulerMappingProfile";
import {IResources, ISchedulerRequestModel} from "../Models/Store/Request/SchedularRequestModel";
import {ISchedularResponseModel} from "../Models/Store/Response/SchedulerResponseModel";
import {SchedulerViewModel} from "../Models/View/SchedulerViewModel";
import {SchedulerStore} from "../Stores/SchedulerStore";
import {ButtonCellEditor, ILinkedModel} from "../Utils/ButtonCellEditor";
import {ColumnButtonsEditor} from "../Utils/ColumnButtonsEditor";
import {GroupEditor} from "../Utils/GroupEditor";
import {SubGroupEditor} from "../Utils/SubGroupEditor";
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';
import {DATE_FORMATS} from "Core/Constants/DateTimeFormats";
import {FormatConverter} from "FormatEditor/FormatConverter";
import {IconModel} from "Core/Controls/BaseControl/Models/IconModel";
import {PROPERTIES, SCHEDULER_EVENTS} from "Core/Controls/Scheduler/Constants";
import { IRePlanningData } from "Core/Controls/Scheduler//Interfaces";

//Templates
import SchedulerTemplate from "Core/Components/Controls/Scheduler/Templates/Scheduler.html";

export class SchedulerCultures {
    static Get(culture: string) {
        const Cultures = {
            'nl-nl': 'nl'
        };

        if (Cultures.hasOwnProperty(culture.toLowerCase())) {
            return Cultures[culture.toLowerCase()];
        }
        return culture;
    }
}

export class SchedulerView extends Event {
    private _viewModel: SchedulerViewModel;
    private _lastCollapsed: KnockoutObservable<number>;
    private _groupEditors: KnockoutObservableArray<GroupEditor>;
    private _labels = LABELS;
    _screen: string;
    private _startDate: moment.Moment;
    private _endDate: moment.Moment;
    private _baseDatePicker: moment.Moment;
    private _baseDateStartClickingArrow: moment.Moment;
    private _settings: ISchedulerParams;
    private _freeCapacity: KnockoutObservableArray<ColumnButtonsEditor>;
    private _commonFreeTime: KnockoutObservableArray<ColumnButtonsEditor>;
    private _notValidGlobals: KnockoutObservableArray<string>;
    private _ready: KnockoutObservable<boolean>;
    private _loadInProgres: KnockoutObservable<boolean>;
    private _parentElement: HTMLElement = null;
    private _numberOfPeriods: KnockoutObservable<number>;
    private _periodsDuration: KnockoutObservable<number>;
    private _starting: KnockoutObservable<string>;
    private _columnToScroll: number;
    private _isRePlanMode: boolean;

    private _updateTimeout: any;
    private _updateTimeoutValue: number;
    private _globalsToUpdate: GlobalModel[];
    private _subjectIcon: IconModel;
    private IconModel = IconModel;
    private _subjectEntityId: number;
    private _isUnblocked: KnockoutObservable<boolean>;
    private _controlId: number;
    private recentLoaded: boolean = false;
    _subjectGlobalValue: KnockoutObservable<boolean>;
    _subjectGlobal: string;
    private _subjectTooltip: string;

    _rePlanningData: IRePlanningData;
    Views: Array<string>;
    GlobalValid: KnockoutObservable<boolean>;
    AgendaConnection: KnockoutObservable<boolean>;
    ReadingData: KnockoutObservable<boolean>;

    constructor(settings: ISchedulerParams) {
        super();

        this._controlId = settings.ControlId;
        this._screen = null;
        this._groupEditors = ko.observableArray([]);

        this._lastCollapsed = ko.observable(0);

        this._startDate = moment().startOf('isoWeek');
        this._endDate = moment().endOf('isoWeek').subtract(2, 'days');

        this._settings = settings;
        this._freeCapacity = ko.observableArray([]);
        this._commonFreeTime = ko.observableArray([]);
        this.AgendaConnection = ko.observable(true);
        this.ReadingData = ko.observable(true);
        this._notValidGlobals = ko.observableArray([]);
        this._ready = ko.observable(false);
        this._starting = ko.observable(GlobalManager.Instance.GetGlobal(GLOBALS.STARTING_TIME));

        this.InitPeriodDuration();
        this.InitNumberOfPeriods();

        this.GlobalValid = ko.observable(this.IsGlobalValid());
        this._loadInProgres = ko.observable(false);
        this.Views = [
            this._labels.AGENDA_MONTH,
            this._labels.AGENDA_WORK_WEEK,
            this._labels.AGENDA_WEEK,
            this._labels.AGENDA_DAY];
        this._columnToScroll = 0;
        this._isRePlanMode = false;
        this._updateTimeoutValue = 1000;
        this._globalsToUpdate = [];
        this._subjectGlobal = null;
        this._subjectGlobalValue = ko.observable(UserVarsManager.Instance.GetSchedulerSubjectToggle(this._controlId));
        this._subjectIcon = null;
        this._subjectEntityId = null;
        this._isUnblocked = ko.observable(false);
        this._baseDatePicker = null;
        this._baseDateStartClickingArrow = null;
        this._subjectTooltip = null;

        if (settings.RePlanningData) {
            this.SetDefaultValues(settings.RePlanningData);
        }

        this.AddEvent(SCHEDULER_EVENTS.CREATE_SCREEN);
        this.AddEvent(SCHEDULER_EVENTS.OPEN_DEFAULT_SCREEN);
        this.AddEvent(SCHEDULER_EVENTS.RETURN_TO_AGENDA);
        this.AddEvent(SCHEDULER_EVENTS.SWITCH_TO_DEFAULT);
        this.AddEvent(SCHEDULER_EVENTS.REPLAN_APPOINTMENT);
        this.AddEvent(SCHEDULER_EVENTS.SET_DATA);
    }

    Capitalize(s: string) {
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    }

    GetScreenSubjectRecordName() {
        return this._viewModel.ScreenSubjectRecordName;
    }

    GetDisplayPeriodDuration() {
        return moment().startOf('day').add(this._periodsDuration(), 'hours').format(DATE_FORMATS.TIME.Format);
    }

    SetLastDate() {
        this.SetDate(this._controlId, this._startDate.format());
    }

    SetDate(controlId: number, date: string) {
        UserVarsManager.Instance.SetSchedulerDate(controlId, date);
        this.Trigger(SCHEDULER_EVENTS.SET_DATA, {Date: date});
    }

    SetDefaultValues(defaultValues) {
        this._isRePlanMode = true;
        this._startDate = defaultValues.StartTime.clone().startOf('isoWeek');
        this._endDate = defaultValues.StartTime.clone().endOf('isoWeek').subtract(2, 'days');
        this._rePlanningData = defaultValues;
    }

    GetEntityId() {
        return this._settings.EntityId;
    }

    GetTemplate() {
        return SchedulerTemplate;
    }

    GetClass() {
        let classes = '';
        if (this._subjectGlobalValue() && (this._numberOfPeriods() <= 2)) {
            classes = 'scheduler-subject ';
        }

        switch (this._screen) {
            case this._labels.AGENDA_WEEK :
                classes = classes + 'scheduler--week';
                break;

            case this._labels.AGENDA_WORK_WEEK :
                classes = classes + 'scheduler--work-week';
                break;

            case this._labels.AGENDA_MONTH :
                classes = classes + 'scheduler--month';
                break;
            case this._labels.AGENDA_DAY :
                classes = classes + 'scheduler--day';
                break;
        }
        return classes;
    }

    GetActiveClass(screen: string) {
        if (this._screen == screen) {
            return 'active';
        }
    }

    ChangeView(view: string) {
        if (this._screen === view) return;

        switch (view) {
            case this._labels.AGENDA_MONTH :
                this.ChangeDateAndSortDates('month', null, null);
                break;

            case this._labels.AGENDA_WEEK :
                this.ChangeDateAndSortDates('isoWeek', null, null);
                break;

            case this._labels.AGENDA_WORK_WEEK :
                this.ChangeDateAndSortDates('isoWeek', 'days', 2);
                break;

            case this._labels.AGENDA_DAY :
                this.ChangeDateAndSortDates('day', null, null);
                break;
        }

        UserVarsManager.Instance.SetSchedulerView(this._controlId, view);

        this._screen = view;

        this.Show();
    }

    ChangeDateAndSortDates(sort: moment.unitOfTime.StartOf, period?: moment.DurationInputArg2, subtract?: moment.DurationInputArg1){
        if (this._baseDateStartClickingArrow){                                                      // is arrow click Date
            this._startDate = moment(this._baseDateStartClickingArrow).startOf(sort);
            if (!!period && !!subtract){
                this._endDate = moment(this._baseDateStartClickingArrow).endOf(sort).subtract(subtract, period);
            } else{
                this._endDate = moment(this._baseDateStartClickingArrow).endOf(sort);
            }

        } else if (this._baseDatePicker) {                                                          // is Picker click Date
            this._startDate = moment(this._baseDatePicker).startOf(sort);
            if (!!period && !!subtract){
                this._endDate = moment(this._baseDatePicker).endOf(sort).subtract(subtract, period);
            } else{
                this._endDate = moment(this._baseDatePicker).endOf(sort);
            }

        } else {                                                                                    // is default click date
            this._startDate = moment(this._startDate).startOf(sort);
            if (!!period && !!subtract){
                this._endDate = moment(this._startDate).endOf(sort).subtract(subtract, period);
            } else{
                this._endDate = moment(this._startDate).endOf(sort);
            }

        }
    }

    private RenderData(model: ISchedularResponseModel) {
        this.AgendaConnection(model.AgendaConnection);
        this.ReadingData(model.ReadingData);

        if (!model.AgendaConnection || !model.ReadingData) {
            this._ready(true);
            this._groupEditors().forEach(editor => editor.OpenGroup()); //if you need to open only the first 2 groups, then do - this._groupEditors().slice(0, 2)
            return;
        }

        const schedulerMappingProfile = {
            model: model,
            startDate: this._startDate,
            endDate: this._endDate,
            numberOfPeriods: this._numberOfPeriods(),
            periodDuration: this._periodsDuration(),
            startingTime: this._starting(),
            rePlanningData: this._rePlanningData,
            schedulerView: this
        };

        if (model.SubjectTable) {
            if (!model.SubjectIsValid) {
                this._subjectGlobalValue(false);
                new Notifier().Warning(NOTIFICATIONS.INVALID_SCHEDULER_SUBJECT);
            } else if (model.SubjectTable.Name !== 'AGENDA') {
                this._subjectIcon = model.SubjectTable.Icon;
                this._subjectEntityId = model.SubjectTable.Id;
                this._subjectGlobal = this.Capitalize(model.SubjectTable.TranslatedName || model.SubjectTable.Name);
                this._subjectTooltip = this._labels.SUBJECT_TOOLTIP.replace('{entityname}', this._subjectGlobal);
            } else if (model.SubjectTable.Name === 'AGENDA') {
                this._subjectGlobalValue(false);
            }
        }

        if (schedulerMappingProfile.numberOfPeriods > 2) {
            this._subjectGlobalValue(false);
        }

        const viewModel = SchedulerMappingProfile.OnViewModel(schedulerMappingProfile);

        if (this._viewModel) {
            this.RestoreSelectedItems(viewModel);
            this.UpdateRow(this._freeCapacity);
            this.UpdateRow(this._commonFreeTime, true, !!this._rePlanningData);
        }

        this._groupEditors(viewModel.Groups);
        viewModel.Week = this._startDate.isoWeek();

        this._viewModel = viewModel;
        this.InitGroupEditors(viewModel);

        _.forEach(model.Errors, err => this.ShowError(err));

        this._ready(true);
        this._groupEditors().forEach(editor => editor.OpenGroup()); //if you need to open only the first 2 groups, then do - this._groupEditors().slice(0, 2)
        this.InitSchedulerDateTimePicker(this._viewModel, this._parentElement);
    }

    private RestoreSelectedItems(viewModel: SchedulerViewModel) {
        const selectedItems = _.flatten(this._viewModel.Groups
            .map(group => group._subGroupEditors
                .filter(subGroup => subGroup._isSelected())));

        for (const group of viewModel.Groups) {
            for (const subGroup of group._subGroupEditors) {
                const selectedItem = _.find(selectedItems, i =>
                    i._columnFirst._entityId === subGroup._columnFirst._entityId && i._columnFirst._id === subGroup._columnFirst._id);

                if (selectedItem) {
                    subGroup._isSelected(true);
                }
            }

            group.UpdateSelectedCount();

            if (group.GetSelectedCount() === group._subGroupEditors.length) {
                group._isSelected(true);
            }
        }
    }

    private SortGroups(x: GroupEditor, y: GroupEditor) {
        return x._sortOrder() - y._sortOrder();
    }

    BlockUI() {
        this._isUnblocked(false);
        BlockUI.Block({Target: this._parentElement});
    }


    UnBlockUI() {
        BlockUI.Unblock(this._parentElement, false,() => {
            this._isUnblocked(true);
        });
    }

    AfterRender(elementArray: HTMLElement[]): void {
        this._parentElement = elementArray[0].parentElement;
        if (this.recentLoaded) {
            return;
        }
        this.recentLoaded = true;

        setTimeout(() => {
            this.recentLoaded = false;
        }, 5000);

        const lastView = UserVarsManager.Instance.GetSchedulerView(this._controlId);

        this.SetLastDate();

        if (lastView) {
            this.ChangeView(lastView);
        } else {
            this.ChangeView(this._labels.AGENDA_WEEK);
        }
        if (this._rePlanningData) {
            this._columnToScroll = this._rePlanningData.StartTime.diff(this._startDate, 'days');
        }
    }

    OnPrevClick() {
        this._columnToScroll = 0;
        this._baseDatePicker = null;
        switch (this._screen) {
            case this._labels.AGENDA_MONTH :
                this._baseDateStartClickingArrow = moment(this._startDate).subtract(1, 'months').startOf('day');
                this._startDate.subtract(1, 'months');
                this._endDate.subtract(1, 'months');
                break;

            case this._labels.AGENDA_WEEK :
                this._baseDateStartClickingArrow = moment(this._startDate).subtract(7, 'days').startOf('day');
                this._startDate.subtract(7, 'days');
                this._endDate.subtract(7, 'days');
                break;

            case this._labels.AGENDA_WORK_WEEK :
                this._baseDateStartClickingArrow = moment(this._startDate).subtract(7, 'days').startOf('day');
                this._startDate.subtract(7, 'days');
                this._endDate.subtract(7, 'days');
                break;

            case this._labels.AGENDA_DAY :
                this._baseDateStartClickingArrow = moment(this._startDate).subtract(1, 'days').startOf('day');
                this._startDate.subtract(1, 'days');
                this._endDate.subtract(1, 'days');
                break;
        }
        this.SetDate(this._controlId, this._startDate.format());
        this.Show();
    }

    OnNextClick() {
        this._columnToScroll = 0;
        this._baseDatePicker = null;
        switch (this._screen) {
            case this._labels.AGENDA_MONTH :
                this._baseDateStartClickingArrow = moment(this._startDate).add(1, 'months').startOf('day');
                this._startDate.add(1, 'months');
                this._endDate.add(1, 'months');
                break;

            case this._labels.AGENDA_WEEK :
                this._baseDateStartClickingArrow = moment(this._startDate).add(7, 'days').startOf('day');
                this._startDate.add(7, 'days');
                this._endDate.add(7, 'days');
                break;

            case this._labels.AGENDA_WORK_WEEK :
                this._baseDateStartClickingArrow = moment(this._startDate).add(7, 'days').startOf('day');
                this._startDate.add(7, 'days');
                this._endDate.add(7, 'days');
                break;

            case this._labels.AGENDA_DAY :
                this._baseDateStartClickingArrow = moment(this._startDate).add(1, 'days').startOf('day');
                this._startDate.add(1, 'days');
                this._endDate.add(1, 'days');
                break;
        }
        this.SetDate(this._controlId, this._startDate.format());
        this.Show();
    }

    GetDateFromTimePicker(view: SchedulerViewModel, pickerDate: any){
        let newPickerDateShortFormat = moment(pickerDate.date.toDate().toString()).format(DATE_FORMATS.SHORT_DATE.Format),
            currentDateShortFormat = moment(view.CurrentDate).format(DATE_FORMATS.SHORT_DATE.Format),
            newDate = moment(pickerDate.date.toDate().toString()),
            isWeekOrWorkWeek = this._screen === this._labels.AGENDA_WEEK || this._screen === this._labels.AGENDA_WORK_WEEK,
            dateComparison: boolean;

        if (isWeekOrWorkWeek){
            if ( moment(newPickerDateShortFormat).isSame(moment(new Date()).format(DATE_FORMATS.SHORT_DATE.Format)) ){ // is current week
                return false
            }
            let newDateWeek = moment(moment(newDate).startOf('isoWeek').toDate()).format(DATE_FORMATS.SHORT_DATE.Format);
            dateComparison = moment(currentDateShortFormat).isSame(newDateWeek);
        } else if (this._screen === this._labels.AGENDA_MONTH) {
            let firstDayCurrentMonth = moment(currentDateShortFormat).startOf('month').format();
            let firstDayNewDateMonth = moment(newDate).startOf('month').format();

            dateComparison = moment(firstDayCurrentMonth).isSame(firstDayNewDateMonth);
        } else {
            dateComparison = moment(currentDateShortFormat).isSame(newDate);
        }

        if (!dateComparison){
            this._columnToScroll = 0;
            let isOK: boolean;
            if(this._baseDateStartClickingArrow){
                this._baseDateStartClickingArrow = null;
            }

            switch (this._screen){
                case this._labels.AGENDA_MONTH :
                    this._baseDatePicker = moment(newDate).startOf('day');
                    this._startDate = moment(newDate).startOf('month');
                    this._endDate = moment(newDate).endOf('month');
                    isOK = true;
                    break;

                case this._labels.AGENDA_WEEK :
                    this._baseDatePicker = moment(newDate).startOf('day');
                    this._startDate = moment(newDate).startOf('isoWeek');
                    this._endDate = moment(newDate).endOf('isoWeek');
                    isOK = true;
                    break;

                case this._labels.AGENDA_WORK_WEEK :
                    this._baseDatePicker = moment(newDate).startOf('day');
                    this._startDate = moment(newDate).startOf('isoWeek');
                    this._endDate = moment(newDate).endOf('isoWeek').subtract(2, 'days');
                    isOK = true;
                    break;

                case this._labels.AGENDA_DAY :
                    this._baseDatePicker = moment(newDate).startOf('day');
                    this._startDate = moment(newDate).startOf('day');
                    this._endDate = moment(newDate).endOf('day');
                    isOK = true;
                    break;
            }

            if (isOK){
                this.SetDate(this._controlId, this._startDate.format());
                this.Show();
            }
        }
    }

    InitSchedulerDateTimePicker(view: SchedulerViewModel, element){
        let self = this,
            pickerPaginationBtn = $(element).find('.schedulerPickerPagination'),
            pickerBtn = $(pickerPaginationBtn);

        if (pickerBtn.length === 0) return;

        if (!pickerBtn.data("DateTimePicker")) {
            pickerBtn.datetimepicker({
                minDate: '1900-01-01'
            });

            pickerBtn.on('dp.change', function (e) {
                self.GetDateFromTimePicker(view, e);
            });

            pickerBtn.on('click', function () {
                let currentDate;
                if(!!self._baseDatePicker){
                    currentDate = moment(self._baseDatePicker);

                } else if (!!self._baseDateStartClickingArrow) {
                    currentDate = moment(self._baseDateStartClickingArrow);

                } else {
                    currentDate = moment(view.CurrentDate);
                }
                $(this).data("DateTimePicker").date(currentDate);
                $(this).data("DateTimePicker").toggle();
            });
            pickerBtn.on('mouseleave', function () {
                $(this).data("DateTimePicker").hide();
			});
        }

        switch (this._screen) {
            case this._labels.AGENDA_MONTH :
                pickerBtn.data("DateTimePicker").options({
                    viewMode: 'months',
                    locale: SchedulerCultures.Get(FormatConverter.GetLocale()),
                    format: DATE_FORMATS.MONTH_AND_YEAR.Format,
                });
                break;

            case this._labels.AGENDA_WEEK :
                pickerBtn.data("DateTimePicker").options({
                    viewMode: 'days',
                    locale: SchedulerCultures.Get(FormatConverter.GetLocale()),
                    format: DATE_FORMATS.SHORT_DATE.Format,
                    daysOfWeekDisabled: false,
                });
                break;

            case this._labels.AGENDA_WORK_WEEK :
                pickerBtn.data("DateTimePicker").options({
                    viewMode: 'days',
                    locale: SchedulerCultures.Get(FormatConverter.GetLocale()),
                    format: DATE_FORMATS.SHORT_DATE.Format,
                    daysOfWeekDisabled: [0, 6],
                });
                break;

            case this._labels.AGENDA_DAY :
                pickerBtn.data("DateTimePicker").options({
                    viewMode: 'days',
                    locale: SchedulerCultures.Get(FormatConverter.GetLocale()),
                    format: DATE_FORMATS.SHORT_DATE.Format,
                    daysOfWeekDisabled: false,
                });
                break;
        }

    }

    DelayedUpdate(target: string, value: string) {
        if (this._updateTimeout) clearTimeout(this._updateTimeout);
        this._updateTimeout = setTimeout(() => {
            this.AddGlobalToUpdate(target, value);
            this.Show();
        }, this._updateTimeoutValue);
    }

    OnIncreasePeriods() {
        let newNumberOfPeriods = this._numberOfPeriods() + 1;

        if (newNumberOfPeriods > 48) {
            newNumberOfPeriods = 1;
        }

        this._numberOfPeriods(newNumberOfPeriods);
        this._periodsDuration(this.CalculatePeriodDuration(newNumberOfPeriods));

        this.DelayedUpdate(GLOBALS.NUMBER_OF_PERIODS, this._numberOfPeriods().toString())

        if (newNumberOfPeriods >= 5 && this._screen !== this._labels.AGENDA_DAY){
            this.ChangeView(this._labels.AGENDA_DAY);
        }
    }

    OnDecreasePeriods() {
        let newNumberOfPeriods = this._numberOfPeriods() - 1;

        if (newNumberOfPeriods === 0) {
            newNumberOfPeriods = 48;
        }

        this._numberOfPeriods(newNumberOfPeriods);
        this._periodsDuration(this.CalculatePeriodDuration(newNumberOfPeriods));

        this.DelayedUpdate(GLOBALS.NUMBER_OF_PERIODS, this._numberOfPeriods().toString())

        if (newNumberOfPeriods >= 5 && this._screen !== this._labels.AGENDA_DAY){
            this.ChangeView(this._labels.AGENDA_DAY);
        }
    }

    OnIncreasePeriodDuration() {
        let defaultStep_30_minutes = 30 / 60, // 30 minutes ÷ 60 minutes = 0.5 hours.
            step_15_minutes = 15 / 60, // 15 minutes ÷ 60 minutes = 0.25 hours.
            step_10_minutes = 10 / 60, // 10 minutes ÷ 60 minutes = 0.16666666667 hours.
            step_05_minutes = 5 / 60; // 5 minutes ÷ 60 minutes = 0.08333333333 hours.

        let newPeriodDuration = this._periodsDuration() + defaultStep_30_minutes,
            fixed_10_minutes = +step_10_minutes.toFixed(3),
            fixed_05_minutes = +step_05_minutes.toFixed(3),
            calculate;

        switch (this._periodsDuration()) {
            case fixed_10_minutes :
                calculate = this._periodsDuration() + fixed_05_minutes;
                newPeriodDuration = +calculate.toFixed(3);
                break;
            case step_15_minutes :
                calculate = this._periodsDuration() + step_15_minutes;
                newPeriodDuration = +calculate.toFixed(3);
                break;
            default:
                break;
        }

        if (newPeriodDuration === 24) {
            newPeriodDuration = defaultStep_30_minutes;
        }

        this._periodsDuration(newPeriodDuration);
        this.CalculateDecreasePeriods();
        this.DelayedUpdate(GLOBALS.PERIOD_DURATION, this._periodsDuration().toString())
    }

    OnDecreasePeriodDuration() {
        let defaultStep_30_minutes = 30 / 60, // 30 minutes ÷ 60 minutes = 0.5 hours.
            step_15_minutes = 15 / 60, // 15 minutes ÷ 60 minutes = 0.25 hours.
            step_10_minutes = 10 / 60, // 10 minutes ÷ 60 minutes = 0.16666666667 hours.
            step_05_minutes = 5 / 60; // 5 minutes ÷ 60 minutes = 0.08333333333 hours.

        let newPeriodDuration = this._periodsDuration() - defaultStep_30_minutes,
            fixed_10_minutes = +step_10_minutes.toFixed(3),
            fixed_05_minutes = +step_05_minutes.toFixed(3),
            calculate;

        switch (this._periodsDuration()) {
            case defaultStep_30_minutes :
                calculate = this._periodsDuration() - step_15_minutes;
                newPeriodDuration = +calculate.toFixed(3);
                break;
            case step_15_minutes :
                calculate = this._periodsDuration() - fixed_05_minutes;
                newPeriodDuration = +calculate.toFixed(3);
                break;
            case fixed_10_minutes :
                return false;
            default:
                break;
        }


        if (newPeriodDuration === 0) {
            newPeriodDuration = 23.5;
        }

        this._periodsDuration(newPeriodDuration);

        this.DelayedUpdate(GLOBALS.PERIOD_DURATION, this._periodsDuration().toString())
    }

    DisableBtnDecreasePeriodBtn(): boolean {
        let step_10_minutes = 10 / 60, // 10 minutes ÷ 60 minutes = 0.16666666667 hours.
            fixed_10_minutes = +step_10_minutes.toFixed(3);

        return this._periodsDuration() === fixed_10_minutes;
    }

    OnIncreaseStarting() {
        if (this._starting() === '23:00') {
            this._starting('00:00');
        } else {
            this._starting(moment(moment().format(DATE_FORMATS.SHORT_DATE.Format) + " " + this._starting()).add(1, 'hours').format(DATE_FORMATS.TIME.Format));
        }
        this.CalculateDecreasePeriods();
        this.DelayedUpdate(GLOBALS.STARTING_TIME, this._starting().toString());
    }

    OnDecreaseStarting() {
        if (this._starting() === '00:00') {
            this._starting('23:00');
        } else {
            this._starting(moment(moment().format(DATE_FORMATS.SHORT_DATE.Format) + " " + this._starting()).subtract(1, 'hours').format(DATE_FORMATS.TIME.Format));
        }

        this.DelayedUpdate(GLOBALS.STARTING_TIME, this._starting().toString());
    }

    ChangeProvideSubject() {
        if (this._numberOfPeriods() > 2)
            return;
        this._subjectGlobalValue(!this._subjectGlobalValue());
        UserVarsManager.Instance.SetSchedulerSubjectToggle(this._controlId, this._subjectGlobalValue());
        this.Show();
    }

    RoundHalf(num: number): number {
        return Math.round(num * 2) / 2;
    }

    CalculateDecreasePeriods() {
        const periods = this._numberOfPeriods();
        const duration = this._periodsDuration();
        const start = +this._starting().substr(0, 2);
        const end = start + duration * periods;

        let maxPeriods = Math.min(periods, Math.floor((24 - start) / duration));
        if(maxPeriods < 1){
            maxPeriods = 1;
        }

        if (end > 24 && periods > 1) {
	        this._numberOfPeriods(maxPeriods);
        }
    }

    Show() {
        this._ready(false);

        if (this._globalsToUpdate.length > 0) {
            GlobalManager.Instance.UpdateUserGlobals(this._globalsToUpdate)
                .then(() => this._globalsToUpdate = [])
                .fail(() => new Notifier().Warning('Error updating scheduler globals'));
        }

        this.BlockUI();
        this.LoadData()
            .then((schedularResponseModel: ISchedularResponseModel) => this.RenderData(schedularResponseModel))
            .fail(error => this.ShowError(error.message))
            .always(() => this.UnBlockUI());
    }

    private CalculatePeriodDuration(numberOfPeriods: number) {
        let periodDuration = 8 / numberOfPeriods;

        if (periodDuration < 0.5) {
            periodDuration = 0.5;
        }

        return Math.round(periodDuration * 2) / 2;
    }

    private InitNumberOfPeriods() {
        let globalValue = Math.round(+GlobalManager.Instance.GetGlobal(GLOBALS.NUMBER_OF_PERIODS));

        if (globalValue <= 0) {
            globalValue = 1;
        }

        if (globalValue > 48) {
            globalValue = 48;
        }

        this._numberOfPeriods = ko.observable(globalValue);
        this.CalculateDecreasePeriods();
    };

    private InitPeriodDuration() {
        let globalValue = Math.round(+GlobalManager.Instance.GetGlobal(GLOBALS.PERIOD_DURATION));

        if (globalValue <= 0) {
            globalValue = 0.5;
        }

        if (globalValue > 23.5) {
            globalValue = 23.5;
        }

        this._periodsDuration = ko.observable(globalValue);
    }

    private IsGlobalValid(): boolean {
        this._notValidGlobals([]);
        let isValid = true;
        if (isNaN(this._numberOfPeriods()) || !this.IsInteger(this._numberOfPeriods()) || this._numberOfPeriods() <= 0) {
            this._notValidGlobals.push(GLOBALS.NUMBER_OF_PERIODS);
            isValid = false;
        }
        if (isNaN(this._periodsDuration()) || !this.IsDecimal(this._periodsDuration()) || this._periodsDuration() <= 0) {
            this._notValidGlobals.push(GLOBALS.PERIOD_DURATION);
            isValid = false;
        }

        if (!moment(this._starting(), DATE_FORMATS.TIME.Format).isValid()) {
            this._notValidGlobals.push(GLOBALS.STARTING_TIME);
            isValid = false;
        }

        return isValid;
    }

    private AddGlobalToUpdate(name: string, value: string) {
        let globalToUpdate = _.find(this._globalsToUpdate, global => global.Name === name);

        if (globalToUpdate) {
            globalToUpdate.Value = value;
        } else {
            globalToUpdate = new GlobalModel();

            globalToUpdate.Name = name;
            globalToUpdate.Value = value;

            this._globalsToUpdate.push(globalToUpdate);
        }
    }

    private IsInteger(num: number) {
        return (num ^ 0) === num;
    }

    private IsDecimal(num: number) {
        return num % 1 != 0 || this.IsInteger(num);
    }

    private LoadData(): P.Promise<ISchedularResponseModel> {
        let params: ISchedulerRequestModel = {
            Ending: this._endDate.format(),
            Starting: this._startDate.format(),
            ScreenSubjectEntityId: this._settings.ScreenSubjectEntityId,
            ScreenSubjectRecordId: this._settings.ScreenSubjectRecordId,
            ProvideSubjects: this._subjectGlobalValue()
        };
        return SchedulerStore.GetScheduler(params, false);
    }

    UpdateRow(row: KnockoutObservableArray<ColumnButtonsEditor>, isFreeTime: boolean = false, recountAvailable: boolean = false) {
        const newRow = this.GenerateRow();

        const anyResourceSelected = _.any(this._groupEditors(), (groupEditor: GroupEditor) => groupEditor._selectedCount() > 0);
        if (!anyResourceSelected) {
            row(newRow);
            return;
        }

        const freeCapacity: { columnEditorIndex: number, cellEditorIndex: number, available: boolean }[] = [];

        let selectedRows: Array<{ id: number, rows: any }> = [];

        if (recountAvailable) {
            this._rePlanningData.LinkedSubGroups = [];
        }

        _.forEach(this._groupEditors(), (groupEditor) => {
            if (groupEditor._sortOrder() < 0 && groupEditor._isSelected() || isFreeTime) {
                _.forEach(groupEditor._subGroupEditors, (subGroupEditor) => {
                    if (subGroupEditor._isSelected()) {

                        const selectedRow = {
                            id: subGroupEditor._columnFirst._id,
                            rows: {}
                        };

                        _.forEach(subGroupEditor._columnsEditors, (columnEditor, columnEditorIter) => {
                            _.forEach(columnEditor._cellsEditors, (cellEditor, cellEditorIter, arr) => {
                                recountAvailable && cellEditor.IsAvailableForReplan();

                                if (!selectedRow.rows[columnEditorIter]) {
                                    selectedRow.rows[columnEditorIter] = {};
                                }
                                selectedRow.rows[columnEditorIter][cellEditorIter] = cellEditor._isAvailableForReplan();

                                // if group is closed and subGroup is selected
                                let freeCapacityCell = newRow[columnEditorIter]._cellsEditors[cellEditorIter];
                                if (cellEditor._isAvailable && !cellEditor._isFreeDay && !cellEditor._count) {
                                    let linkedModel: ILinkedModel = {
                                        GroupEntityId: subGroupEditor._columnFirst._entityId,
                                        ResourceId: subGroupEditor._columnFirst._id,
                                        Name: subGroupEditor._columnFirst._name,
                                        TypeId: subGroupEditor._columnFirst._recordTypeId,
                                        TypeName: subGroupEditor._columnFirst._recordTypeName
                                    };

                                    freeCapacityCell._linkedSubGroups.push(linkedModel);
                                    if (this._rePlanningData) {
                                        this._rePlanningData.LinkedSubGroups.push(linkedModel);
                                    }
                                }

                                //make freecapacity button available if subgroupCell available
                                let freeCapacitySpecs = freeCapacity.find(fc => fc.columnEditorIndex === columnEditorIter && fc.cellEditorIndex === cellEditorIter);

                                if (isFreeTime) {
                                    if (!freeCapacitySpecs) {
                                        freeCapacitySpecs = {
                                            columnEditorIndex: columnEditorIter,
                                            cellEditorIndex: cellEditorIter,
                                            available: cellEditor._isAvailable && !cellEditor._isFreeDay && !cellEditor._count
                                        };
                                        freeCapacity.push(freeCapacitySpecs);
                                    } else {
                                        freeCapacitySpecs.available = freeCapacitySpecs.available && !cellEditor._isFreeDay && cellEditor._isAvailable && !cellEditor._count;
                                    }
                                } else {
                                    if (!freeCapacitySpecs) {
                                        freeCapacitySpecs = {
                                            columnEditorIndex: columnEditorIter,
                                            cellEditorIndex: cellEditorIter,
                                            available: cellEditor._isAvailable || !cellEditor._isFreeDay && !cellEditor._count
                                        };
                                        freeCapacity.push(freeCapacitySpecs);
                                    } else {
                                        freeCapacitySpecs.available = freeCapacitySpecs.available || !cellEditor._isFreeDay && cellEditor._isAvailable && !cellEditor._count;
                                    }
                                }
                            });
                        });

                        selectedRows.push(selectedRow);
                    } else {
                        _.forEach(subGroupEditor._columnsEditors, (columnEditor, columnEditorIter) => {
                            _.forEach(columnEditor._cellsEditors, (cellEditor, cellEditorIter, arr) => {
                                cellEditor._isAvailableForReplan(false);
                            });
                        });
                    }
                });
            }
        });

        if (recountAvailable && this._isRePlanMode) {
            const uniqSelectedRows = _.uniq(selectedRows, (row) => row.id);

            if (uniqSelectedRows.length > 1) {
                let availableModel = selectedRows[0].rows;
                for (let i = 1; i < uniqSelectedRows.length; i++) {
                    _.forEach(uniqSelectedRows[i].rows, (column: Array<boolean>, columnIter) => {
                        _.forEach(column, (cell, cellIter) => {
                            availableModel[columnIter][cellIter] = availableModel[columnIter][cellIter] && cell;
                        });
                    });
                }

                const agenda = {};

                _.forEach(this._groupEditors(), (group) => {
                    _.forEach(group._subGroupEditors, (subGroup) => {
                        if (subGroup._isSelected()) {
                            _.forEach(subGroup._columnsEditors, (column, columnIter) => {
                                if (!agenda[columnIter]) {
                                    agenda[columnIter] = {};
                                }
                                _.forEach(column._cellsEditors, (cell, cellIter) => {
                                    if (!agenda[columnIter][cellIter]) {
                                        agenda[columnIter][cellIter] = [];
                                    }
                                    cell._isAvailableForReplan(availableModel[columnIter][cellIter]);

                                    if (availableModel[columnIter][cellIter]) {
                                        if (subGroup._agenda.length) {
                                            _.forEach(subGroup._agenda, (item) => {
                                                const start = moment(FormatConverter.CorrectTimezone(item.Starting));
                                                const end = moment(start.clone()).add(moment.duration(moment(item.Duration).format(DATE_FORMATS.TIME.Format)));

                                                const periodStart = moment(cell._period.Start);
                                                const periodEnd = periodStart.clone().add(cell._period.Duration, 'h');

                                                if ((start < periodEnd) && (end > periodStart)) {
                                                    agenda[columnIter][cellIter].push({
                                                        id: item.Id,
                                                        start: moment(FormatConverter.CorrectTimezone(item.Starting)),
                                                        end: moment(start.clone()).add(moment.duration(moment(item.Duration).format(DATE_FORMATS.TIME.Format)))
                                                    });
                                                }
                                            });
                                        }
                                    }

                                    if (agenda[columnIter][cellIter].length > 1) {
                                        agenda[columnIter][cellIter] = _.uniq(agenda[columnIter][cellIter], (ag: any) => ag.id);
                                    }
                                });
                            });
                        }
                    });
                });
                //recount available periods for cell
                this._viewModel.AvailablePeriods = SchedulerMappingProfile.AvailablePeriodsForReplanning(agenda, this._viewModel.HeaderColumns, this._rePlanningData);

                _.forEach(this._groupEditors(), (group) => {
                    _.forEach(group._subGroupEditors, (subGroup) => {
                        if (subGroup._isSelected()) {
                            _.forEach(subGroup._columnsEditors, (column, columnIter) => {
                                _.forEach(column._cellsEditors, (cell, cellIter) => {
                                    cell._availableForReplanRanges = _.map(this._viewModel.AvailablePeriods[columnIter][cellIter], (range) => {
                                        return {
                                            Start: range.a,
                                            End: range.b,
                                            DisplayValue: `${range.a.format(DATE_FORMATS.TIME.Format)} - ${range.b.format(DATE_FORMATS.TIME.Format)}`
                                        }
                                    });
                                })
                            })
                        }
                    });
                });
                //recount available periods for cell
            }
            this._rePlanningData.LinkedSubGroups = _.uniq(this._rePlanningData.LinkedSubGroups, (record) => record.ResourceId && record.Name);
        }

        if (freeCapacity && _.any(this._groupEditors(), ge => ge._sortOrder() < 0)) {
            const collapsedGroups = this._groupEditors()
                .filter(ge => ge._sortOrder() < 0)
                .map(ge => ge._sortOrder());

            this._lastCollapsed(collapsedGroups.length ? _.max(collapsedGroups) : 0);
        }

        freeCapacity.forEach(freeCapacitySpecs => {
            const freeCapacityCell = newRow[freeCapacitySpecs.columnEditorIndex]._cellsEditors[freeCapacitySpecs.cellEditorIndex];
            freeCapacityCell._isAvailable(freeCapacitySpecs.available);
        });

        row(newRow);
    }

    private GenerateRow() {
        let row: Array<ColumnButtonsEditor> = [];
        if (this._groupEditors()[0]) {
            _.forEach(this._groupEditors()[0]._columnsEditors, (col, i) => {
                row.push(new ColumnButtonsEditor());
                _.forEach(col._cellsEditors, (cell, j) => {
                    let period = this._viewModel.HeaderColumns[i].Periods[j];
                    row[i]._cellsEditors.push(new ButtonCellEditor(false, period, !this._viewModel.CreatingData));
                });
            });
        }
        return row;
    }

    private ChangeSameSubgroups(subgroup) {
        _.forEach(this._groupEditors(), (group) => {
            _.forEach(group._subGroupEditors, (item) => {
                if (item._columnFirst._id === subgroup._columnFirst._id && item._columnFirst._type === subgroup._columnFirst._type) {
                    item._isSelected(subgroup._isSelected());
                }
            });
            if (group.GetSelectedCount()) {
                group._isSelected(true);
            } else {
                group._isSelected(false);
            }
        })
    }

    private InitGroupEditors(viewModel: SchedulerViewModel) {
        viewModel.Groups.sort(this.SortGroups);
        this.UpdateRow(this._commonFreeTime, true);
        _.forEach(viewModel.Groups, (groupEditor: GroupEditor) => {
            groupEditor._sortOrder.subscribe(() => {
                this.UpdateRow(this._freeCapacity);
                this._groupEditors.sort(this.SortGroups);

            });
        });

        this._groupEditors(viewModel.Groups);
    }

    OnGroupClick(group: GroupEditor) {
        group.ToggleSelection();
        _.forEach(group._subGroupEditors, (subGroup) => {
            this.ChangeSameSubgroups(subGroup);
        });

        this.UpdateRow(this._freeCapacity);
        this.UpdateRow(this._commonFreeTime, true, !!this._rePlanningData);
        group._selectedCount(group.GetSelectedCount());
        return true;
    }

    OnSubGroupClick(subGroup: SubGroupEditor) {
        const group = subGroup.GetGroup();
        group.UpdateSelectedCount();
        if (group.GetSelectedCount()) {
            group._isSelected(true);
        } else {
            group._isSelected(false);
        }
        this.ChangeSameSubgroups(subGroup);
        this.UpdateRow(this._freeCapacity);
        this.UpdateRow(this._commonFreeTime, true, !!this._rePlanningData);
        return true;
    }

    OnCreateEvent(data: ButtonCellEditor) {
        if (!data.IsDisabled) {
            data._linkedSubGroups = _.uniq(data._linkedSubGroups);
            this.Trigger(SCHEDULER_EVENTS.CREATE_SCREEN, data);
        }
    }

    OnReplan(start: moment.Moment) {
        this.Trigger(SCHEDULER_EVENTS.REPLAN_APPOINTMENT, {Start: start});
    }

    OnShowDefaultScreen(data, entityId: number, event, typeId?: number) {
        this.Trigger(SCHEDULER_EVENTS.OPEN_DEFAULT_SCREEN, {
            Id: data.Id,
            Event: event,
            EntityId: entityId,
            TypeId: typeId
        });
    }

    private ShowError(message: string) {
        new Notifier().Failed(message);
    }

    private ReturnToAgenda() {
        this.Trigger(SCHEDULER_EVENTS.RETURN_TO_AGENDA);
    }

    private SwitchToDefaultMode() {
        this.Trigger(SCHEDULER_EVENTS.SWITCH_TO_DEFAULT);
    }

    GetBottomColumnWidthClass(): string {
        let periodsLength = this._viewModel && this._viewModel.GetPeriods().length;
        return 'bottom__subcolumn--width-' + periodsLength;
    }
}