import { AccountsDtoModel } from "./AccountsDtoModel";
import { SubAdministrationDtoModel } from "./SubAdministrationDtoModel";

export class LedgerDtoModel {
	Accounts: AccountsDtoModel;
	SubAdministrations: SubAdministrationDtoModel[];

	constructor() {
		this.SubAdministrations = [];
	}
}