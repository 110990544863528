import * as joint from 'libs/rappid/build/rappid';
import {dia as Dia, shapes, dia} from 'libs/rappid/build/rappid';
import { DropdownDataStore } from 'Core/Controls/Dropdown/Stores/DropDownDataStore';
import {SearchScreen} from 'Core/Screens/SearchScreen/SearchScreen';
import { RecordStore } from '../../../Common/Stores/RecordStore';
import { ShapeFactory } from '../Views/ShapeFactory';
import { BlockUI } from '../../../Common/BlockUi';
export class FunctionParamInspector {
    public static Build(shape: joint.shapes.cyberThing.ParamView, graph: Dia.Graph): joint.ui.Inspector{
        
		let tableId = parseInt(shape.model.attributes.filledFromTable);

		if(Number.isNaN(tableId) || tableId == 0){
			return;
		}

        const inspector = joint.ui.Inspector.create('#inspector-container', {
			cell: shape.model,
			theme: 'default',
			inputs: {
				attrs: {
					'FilledFrom': {
						type: 'filledFrom',
						label: 'Filled from',
						group: 'Options',
						index: 1
					}
				}
			},
			groups: {
				'Options': {
					label: 'Options',
					index: 1
				}
			},
            renderFieldContent: function(options, path, value) {
				let fillFromValue = '';
				let root = ShapeFactory.GetRoot(shape.model, graph);
				if(value){
					fillFromValue = value[root.get('replicationId')] || '';
				}

				if(path === 'attrs/FilledFrom'){
					var $buttonSet = $('<div>');

                    const $searchButton = $(`
                        <div class="field select-field">
                            <label>Fill from</label>
                            <div class="form-control">
								<input style="width: 90%;" type="text" value='${fillFromValue}'/>
                                <a class="search-magnifier"><span class="icon-magnifier"></span></a>
                            </div>
                        </div>`
					)
            
					$buttonSet.append([$searchButton]);

					$searchButton.find('input').on("keyup change", (evt)=>{
						inspector.trigger('change:attrs/FilledFrom', (evt.target as HTMLInputElement).value);
					});

					$searchButton.find('.search-magnifier').on('click', function() {
                      const searchScreen = new SearchScreen({ EntityId: shape.model.attributes.filledFromTable });
                      searchScreen.On('RECORD_SELECTED', this, (evtArgs) => {

						BlockUI.Block({ Target: $buttonSet[0] });
                        RecordStore
                        .GetRecord({ TableId: shape.model.attributes.filledFromTable, RecordId: evtArgs.data.RecordId })
                        .always(()=>BlockUI.Unblock($buttonSet[0]))
						.then(record=>{
                            const field = _.find(record.Fields, (field: any)=>field.FieldId === shape.model.attributes.filledFromField);
                            let $fillValueInput = $searchButton.find('input');
                            $fillValueInput.val(field.FieldValue);
                            inspector.trigger('change:attrs/FilledFrom', field.FieldValue);
                        });
                      });
                      searchScreen.Show();
					});

					return $buttonSet;
				}

				return null;
			}
		});

        return inspector;
    }
}