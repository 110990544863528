import * as ko from 'knockout';
import * as moment from 'moment';

import {Detail} from 'Core/Controls/Mail/Detail';
import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';
import {MailStore, IMailListModelRequestModel} from 'Core/Controls/Mail/Stores/MailStore';
import {MailItemModel} from 'Core/Controls/Mail/Models/MailItemModel';
import {MailFolder} from 'Core/Controls/Mail/Enums/MailFolder';
import {Event} from 'Core/Common/Event';
import {MAIL_LIST_EVENTS} from 'Core/Controls/Mail/Events';
import {NOTIFICATIONS, LABELS} from 'Core/Components/Translation/Locales';

import MailListTemplate from 'Core/Controls/Mail/Templates/MailListTemplate.html';

export class MailList extends Event {
	private _selectAll: KnockoutObservable<boolean>;
	private _el: HTMLElement;
	private _mailItems: KnockoutObservableArray<MailItemModel>;
	private _detailView: KnockoutObservable<Detail>;
	private _mailFolder: MailFolder;
	private _mailConnectionId: number;
	private _labels = LABELS;

	constructor(mailItems: Array<MailItemModel>, mailFolder: MailFolder, mailConnectionId) {
		super();

		this._selectAll = ko.observable(false);
		this._mailFolder = mailFolder;
		this._mailItems = ko.observableArray(mailItems);
		this.FormatDateCreated(this._mailItems());
		this._detailView = ko.observable(null);
		this._mailConnectionId = mailConnectionId;
		this.Init();
	}

	Init() {
		this.AddEvent(MAIL_LIST_EVENTS.SAVE_TO_CYBER_BOX);
		this.AddEvent(MAIL_LIST_EVENTS.LOAD_DATA);

		this._mailItems().forEach(item => item.IsReadObservable(item.IsRead));
	}

	GetTemplate() {
		return MailListTemplate;
	}

	get Title(): string {
		return MailFolder[this._mailFolder];
	}

	SelectAll() {
		this._mailItems().forEach(item => item.IsSelected(this._selectAll()));

		return true;
	}

	MarkAsReadByGuid(guid: string): void {
		const requestModel = {
			MailConnectionId: this._mailConnectionId,
			Guids: [guid],
			IsCyberBox: false
		};

		MailStore.MarkAsRead(requestModel)
			.then(() => {
				this._mailItems().forEach(item => {
					if (item.Guid === guid) {
						item.IsReadObservable(true);
					}
				});
			})
			.fail((err) => {
				new Notifier().Warning(err.message);
			});
	}

	MarkAsRead() {
		const requestModel = this.GetActionModel();

		if (requestModel) {
			BlockUI.Block();

			MailStore.MarkAsRead(requestModel)
				.always(() => {
					BlockUI.Unblock(this._el);
				})
				.then(() => {
					this.Trigger(MAIL_LIST_EVENTS.LOAD_DATA);

					new Notifier().Success(NOTIFICATIONS.MARKED_AS_READ);
				})
				.fail((err) => {
					new Notifier().Warning(err.message);
				});
		}
	}

	MarkAsUnRead() {
		const requestModel = this.GetActionModel();

		if (requestModel) {
			BlockUI.Block();
			MailStore.MarkAsUnRead(requestModel)
				.always(() => {
					BlockUI.Unblock(this._el);
				})
				.then(() => {
					this.Trigger(MAIL_LIST_EVENTS.LOAD_DATA);

					new Notifier().Success(NOTIFICATIONS.MARKED_AS_UNREAD);
				})
				.fail((err) => {
					new Notifier().Warning(err.message);
				});
		}
	}

	private FormatDateCreated(mailitems: MailItemModel[]): void {
		const dateNow: moment.Moment = moment();

		mailitems.forEach((item) => {
			const date: moment.Moment = moment(item.DateTimeCreated);
			let formattedDate: string;

			const isToday: boolean = dateNow.format('L') === date.format('L');

			if (isToday) {
				formattedDate = date.format('LT');
			} else {
				const isThisYear: boolean = dateNow.format('YYYY') === date.format('YYYY');

				if (!isThisYear) {
					formattedDate = date.format('L');
				} else {
					const _date: string = date.format('ll');
					const yearSeparatorIndex: number = _date.indexOf(' ', 5);

					formattedDate = _date.slice(0, yearSeparatorIndex);
				}
			}

			item.DateTimeCreated = formattedDate;
		});
	}

	Delete() {
		const requestModel = this.GetActionModel();

		if (requestModel) {
			BlockUI.Block();

			MailStore.DeleteMails(requestModel)
				.always(() => {
					BlockUI.Unblock(this._el);
				})
				.then(() => {
					new Notifier().Success(NOTIFICATIONS.RECORD_REMOVED);

					_.each(requestModel.Guids,
						(guid) => {
							const deleteItem = _.find(this._mailItems(), (item) => item.Guid === guid);

							if (deleteItem) {
								this._mailItems.splice(this._mailItems.indexOf(deleteItem), 1);
							}
						});

					this.Trigger('LOAD_DATA', 'delete');
				})
				.fail((err) => {
					new Notifier().Warning(err.message);
				});
		}
	}

	GetActionModel(): IMailListModelRequestModel {
		const items = <any>this._mailItems();
		let guids;

		const selectedItems = _.filter(items, (mailItem: MailItemModel) => mailItem.IsSelected());

		if (selectedItems.length === 0) {
			new Notifier().Warning(NOTIFICATIONS.PLEASE_SELECT_ANY_EMAIL);

			return null;
		} else {
			guids = selectedItems.map(item => item.Guid);
		}

		const requestModel: IMailListModelRequestModel = {
			MailConnectionId: this._mailConnectionId,
			Guids: guids,
			IsCyberBox: false
		};

		return requestModel;
	}

	SaveToCyberBox(itemData: MailItemModel) {
		this.Trigger(MAIL_LIST_EVENTS.SAVE_TO_CYBER_BOX, itemData);
	}

	AfterRender(el: Array<HTMLElement>) {
	}
}