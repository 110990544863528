import {AccountsState} from "Core/Components/Dashboards/FinancialDashboard/Enums/AccountsState";
import {JournalsState} from "Core/Components/Dashboards/FinancialDashboard/Enums/JournalsState";

import { SourceTableDtoModel } from "./SourceTableDtoModel";
import { FinancialProcessorRunnerDtoModel } from "./FinancialProcessorRunnerDtoModel";
import {LedgerDtoModel} from "./LedgerDtoModel";

export class FinancialDashboardDtoModel {
	JournalsState: JournalsState;
	AccountsState: AccountsState;

	SourceTables: SourceTableDtoModel[];
	FinancialProcessorRunners: FinancialProcessorRunnerDtoModel[];
	Ledger: LedgerDtoModel;
}