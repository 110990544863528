import {deserialize, deserializeAs} from 'libs/cerialize';

import {SpreadsheetCellPositionDto} from './SpreadsheetCellPositionDto';
import {SpreadsheetDimensionDirections} from '../../Enums/SpreadsheetDimensionDirections';
import {SpreadsheetFieldDescriptorDto} from './SpreadsheetFieldDescriptorDto';

export class SpreadsheetDimensionDescriptorDto {
    @deserialize
    Number: number;

    @deserializeAs(SpreadsheetCellPositionDto)
    StartPosition: SpreadsheetCellPositionDto;

    @deserialize
    Direction: SpreadsheetDimensionDirections;

    @deserializeAs(SpreadsheetFieldDescriptorDto)
    Field: SpreadsheetFieldDescriptorDto;
}