import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {BusinessRoleDto} from './Models/BusinessRoleDto';

export class TabSecurityStore {

    GetBusinessRoles() {
        const deferred = P.defer<BusinessRoleDto[]>();

        Request.Send({
            proxy: {
                url: '/api/BusinessRolesApi/GetBusinessRoles',
                type: 'GET'
            }
        })
            .then(roles => deferred.resolve(roles))
            .fail(error => deferred.reject(this.GetResponseError(error, 'Error getting business roles')));

        return deferred.promise();
    }

    GetUserBusinessRoles(userId: number) {
        const deferred = P.defer<BusinessRoleDto[]>();

        Request.Send({
            proxy: {
                url: '/api/BusinessRolesApi/GetUserBusinessRoles',
                type: 'GET'
            },
            params: { userId }
        })
            .then(roles => deferred.resolve(roles))
            .fail(error => deferred.reject(this.GetResponseError(error, 'Error getting business roles')));

        return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}