import * as ko from 'knockout';

import {deserialize, deserializeAs} from "libs/cerialize";

export class ItemModel {

	constructor() {
		this.IsSelected = ko.observable(false);
	}

	@deserialize
	RecordId: number;

	@deserialize
	Label: string;

	IsSelected: KnockoutObservable<boolean>;
}

export class MultiSelectListModel {
	@deserializeAs(ItemModel)
	Items: Array<ItemModel>;

	@deserialize
	RecordsCount: number;

	constructor() {
		this.Items = [];
	}
}
