import * as ko from 'knockout';

import {PageName} from '../View/PageName';

export class RenamePageState {
    UseSearch: boolean;
    NameOptions: KnockoutObservableArray<PageName>;
    SelectedName: KnockoutObservable<PageName>;
    Error: KnockoutObservable<string>;

    constructor(selectedName: PageName, nameOptions: PageName[], useSearch: boolean) {
        this.UseSearch = useSearch;

        const value = useSearch ? selectedName : nameOptions.find(option => option.PageName === selectedName.PageName);

        this.NameOptions = ko.observableArray(nameOptions);
        this.SelectedName = ko.observable(value);

        this.Error = ko.observable(null);
    }
}