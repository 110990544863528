import {serialize} from "libs/cerialize";

export class TagListChanges {
	@serialize
	ControlId: number;
	@serialize
	DeletedTags: number[];
	@serialize
	AddedTags: number[];

	private _oldValues: number[];

	constructor(controlId: number, oldValues: number[]) {
		this.ControlId = controlId;
		this._oldValues = !!oldValues ? oldValues : [];
		this.AddedTags = [];
		this.DeletedTags = [];
	}

	AddTag(tagId: number) {
		this.DeletedTags = _.without(this.DeletedTags, tagId);
		
		if (this._oldValues.indexOf(tagId) < 0) {
			this.AddedTags.push(tagId);
		}
	}

	DeleteTag(tagId: number) {
		this.AddedTags = _.without(this.AddedTags, tagId);
		
		if (this._oldValues.indexOf(tagId) > -1) {
			this.DeletedTags.push(tagId);
		}
	}
}