import { deserialize, deserializeAs } from 'libs/cerialize';

export class ShapeModel {
	@deserialize
	Id: number;

	@deserialize
	Name: string;
}

export class CanvasIconModel {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	FontName: string;

	@deserialize
	Image: string;

	@deserialize
	IsIcon: boolean;

	@deserialize
	IsImage: boolean;
}

export class CanvasItemModel {

	@deserialize
	APIPackageId: number;

	@deserialize
	Id: number;

	@deserialize
	ReplicationId: string;

	@deserialize
	Guid: string;

	@deserialize
	Name: string;

	@deserialize
	TranslatedName: string;

	@deserialize
	TypeName: string;

	@deserialize
	LifeStatus: number;

	@deserialize
	Properties: string;

	@deserialize
	X: number;

	@deserialize
	Y: number;

	@deserialize
	Z: number;

	@deserialize
	ProtocolId: number;

	@deserialize
	ProtocolName: string;

	@deserialize
	ShapeId: number;

	@deserialize
	ShapeName: string;

	@deserialize
	SideId: number;

	@deserialize
	SideName: string;

	@deserialize
	Size: number;

	@deserialize
	Sort: number;

	@deserialize
	Collapsible: boolean;

	@deserializeAs(CanvasIconModel)
	Icon: CanvasIconModel;

	@deserialize
	FilledFromField: number;

	@deserialize
	FilledFromTable: number;

	@deserialize
	State: number;

	@deserialize
	DefaultValue: string;

	get JsonProperties(){
		return JSON.parse(this.Properties) || {};
	}
}

export class CanvasLinkModel {
	@deserialize
	CanvasItem1Id: number;

	@deserialize
	CanvasItem2Id: number;

	@deserialize
	CanvasItem1Guid: string;

	@deserialize
	CanvasItem2Guid: string;

	@deserialize
	Properties: string;

	@deserialize
	State: number;

	@deserialize
	RootRecordId: number;

	@deserialize
	RootRecordGuid: string;
}

export class CanvasModel {
	@deserializeAs(CanvasItemModel)
	CanvasItems: Array<CanvasItemModel>;

	@deserializeAs(CanvasLinkModel)
	CanvasLinks: Array<CanvasLinkModel>;

	@deserializeAs(ShapeModel)
	Shapes: Array<ShapeModel>;

	constructor() {
		this.CanvasItems = [];
		this.CanvasLinks = [];
		this.Shapes = [];
	}
}