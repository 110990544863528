import * as ko from 'knockout';

import {CustomFieldControl} from "../CustomFieldControl";

import Template from './Template.html';
import {ProductCustomFieldDescription} from "../../ProductCustomFieldDescription";
import {CustomFieldValue} from '../../../CustomFieldValue';
import {ProductPart} from '../../../ProductPart';

export class CustomCheckboxControl extends CustomFieldControl {
	private _displayValue: KnockoutObservable<boolean>;

	constructor(customField: ProductCustomFieldDescription, productPart: ProductPart) {
		super(customField, productPart);
		
		this.HasContent = ko.observable(true);
		this._displayValue = ko.observable(false);
	}

	GetTemplate() {
		return Template;
	}

	GetValueForSave(): any {
		return new CustomFieldValue(this.Id, this._displayValue(), this.IsValid(this._displayValue()));
	}

	SetValue(customFieldValue: CustomFieldValue) {
		if (customFieldValue) {
			const displayValue = customFieldValue.Value ? customFieldValue.Value.toString().toLowerCase() === 'true' : false;
			this._displayValue(displayValue);
		}

		this.StartListeningValueChanges();
	}

	SetDefaultValue() {
		const defaultFieldValue = new CustomFieldValue(this.Id, this.DefaultValue(), this.IsValid(this.DefaultValue()));
		this.SetValue(defaultFieldValue);
	}

	ClearValue() {
		this._displayValue(false);
	}

	private StartListeningValueChanges() {
		if (this._displayValue.getSubscriptionsCount() == 0) {
			this._displayValue.subscribe(() => {
				this.CheckValidControl();
				this.OnValueChanged();
			});
		}
	}

	IsValid(value?: any): boolean {
		return this.GetIsRequiredValidation(value ? value : this._displayValue(), this._fieldTypes.YesNo);
	}

	CheckValidControl() {
		this.isValidControl(this.GetIsRequiredValidRule(this._displayValue(), this._fieldTypes.YesNo));
	}
}