import * as ko from 'knockout';

import Template from './Templates/Template.html';

export class MainMenuButton {
    IsAuthenticated: KnockoutObservable<boolean>;

    constructor(isAuthenticated: boolean) {
        this.IsAuthenticated = ko.observable(isAuthenticated);
    }

    GetTemplate() {
        return Template;
    }

    AfterRender() {

    }

    Render(targetId: string) {
        const element = document.getElementById(targetId);

        ko.cleanNode(element);
        ko.applyBindings(this, element);
    }
}