import * as ko from "knockout";

import {EVENTS} from "Core/Constant";

import {IButtonConfig} from "Core/Controls/PortletBar/Interfaces/IButtonConfig"
import {PortletBarButton} from "Core/Controls/PortletBar/Managers/Buttons/PortletBarButton"
import {ReloadButtonViewModel} from "Core/Controls/PortletBar/Models/Buttons/ReloadButtonViewModel"
import {ITooltipConfig} from "Core/KnockoutExtentions/TooltipExtention";
import {LABELS} from "Core/Components/Translation/Locales";

export class ReloadButton extends PortletBarButton {
	constructor(buttonName: string, buttonConfig: IButtonConfig) {
		super(buttonConfig);
		this.ViewModel = new ReloadButtonViewModel(buttonName, buttonConfig);
		this.Tooltip = ko.observable<ITooltipConfig>({content: LABELS.REFRESH, outside: 'y', position: {x: 'center', y: LABELS.REFRESH.length <= 8 ? 'top' : 'bottom'}});
	}

	OnClickEvent() {
		return EVENTS.PORTLETS.PORTLET_BAR.CONTROL_BUTTONS.RELOAD_BUTTON_CLICKED;
	}

	OnClick() {
		this.Trigger(this.OnClickEvent());
	}

	protected GetButtonTemplateFolder() {
		return `${super.GetButtonTemplateFolder()}/Reload`;
	}
} 