import * as ko from "knockout";

import {CyberConfig} from "./Components/CyberConfig";

import Template from "Core/CyberConfigManager/Templates/CyberConfigManager.html";
import {GuestPage} from "./Components/GuestPage";
import {EnvironmentsPage} from "./Components/EnvironmentsPage";
import {LABELS} from 'Core/Components/Translation/Locales';

ko.templates["Core/CyberConfigManager/Templates/CyberConfigManager"] = Template;

export class CyberConfigManager {
    private _isReady: KnockoutObservable<boolean>;

    private _config: CyberConfig;
    private _guestPage: any;
    private _environmentsPage: EnvironmentsPage;
	private _labels = LABELS;

    constructor() {
        this._isReady = ko.observable(false);

        this._config = new CyberConfig();
        this._guestPage = new GuestPage();
        this._environmentsPage = new EnvironmentsPage();
    }

    GetTemplateName() {
        return 'Core/CyberConfigManager/Templates/CyberConfigManager';
    }

    AfterRender() {
        this._config.Init().always(() => this._isReady(true));
    }

    Render(elId: string) {
        let container = document.getElementById(elId);
        ko.cleanNode(container);
        ko.applyBindings(this, container);
    }
}