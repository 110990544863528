//UI
import {Icon} from "Core/Icon/Icon";
import {IconModel} from "Core/Controls/BaseControl/Models/IconModel";
import {FONT_NAME} from "Core/Constant";

//Store models
import {AllowedEntityResponseModel} from "Core/Components/Controls/TimeWriting/Models/Store/Day/Response/AllowedEntityResponseModel";

//View models
import {AllowedEntity} from "Core/Components/Controls/TimeWriting/Models/View/DayDataViewModel"

export class EntityMappings {
    static OnViewModel(model: AllowedEntityResponseModel): AllowedEntity {
        const entity = new AllowedEntity();

        entity.Id = model.Id;
        entity.Name = model.Name;
        entity.Icon = this.SelectIcon(model.Icon);

        return entity;
    }

    private static SelectIcon(model: IconModel): Icon {
        if (model && model.Id > 0) {
            return new Icon(model);
        }

        const defaultIconModel = new IconModel();

        defaultIconModel.Name = 'fa-file';
        defaultIconModel.FontName = FONT_NAME.FONT_AWESOME;
        defaultIconModel.IsIcon = true;
        defaultIconModel.IsImage = false;

        return new Icon(defaultIconModel);
    }
}