import * as _ from 'underscore';
import {deserialize, deserializeAs, serializeAs, serialize} from 'libs/cerialize';
import {QueryConditionItemModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryConditionItemModel';

export class QueryConditionGroupModel {
	constructor() {
		this.Items = [];
		this.ConditionGroups = [];
		this.Operator = 0;
	}

	@serialize
	@deserialize
	EntityId: number;

	@serializeAs(QueryConditionItemModel)
	@deserializeAs(QueryConditionItemModel)
	Items: Array<QueryConditionItemModel>;

	@serializeAs(QueryConditionGroupModel)
	@deserializeAs(QueryConditionGroupModel)
	ConditionGroups: Array<QueryConditionGroupModel>;

	@serialize
	@deserialize
	Operator: number;

	get HasAnyConditions(): boolean{
		return this.Items.length > 0 || this.ConditionGroups.length > 0;
	}
}