import * as ko from 'knockout';

export class ConfigurationLevel{
    Active: KnockoutObservable<boolean>;
    private _level: string;

    constructor(level: string) {
        this._level = level;
        this.Active = ko.observable(false);
    }

    set Level(levelName: string) {
        this._level = levelName;
    }
    get Level() {
        return this._level;
    }
}