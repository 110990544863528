import {SaveSignatureDataDto} from "../Signature";
import {P} from 'Core/Common/Promise'
import {IRequestOptions, Request} from 'Core/Common/Request'


export class SignatureStore {
    static SaveSignatureData(params: SaveSignatureDataDto): P.Promise<any> {
        let deferredResult = P.defer<any>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: '/api/SignatureApi/SaveSignatureData',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(result => {
                deferredResult.resolve(result);
            })
            .fail(err => {
                deferredResult.reject(this.GetResponseError(err, 'Error saving signature data'));
            });

        return deferredResult.promise();
    }

    private static GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}