import * as ko from "knockout";
import * as _ from "underscore";

import { BaseControl, IControlValue } from "Core/Controls/BaseControl/BaseControl";
import { IControlParam } from "Core/Screens/IScreen";
import { BlockUI } from "Core/Common/BlockUi";

import { UserRoles } from 'User/UserManager';

import ViewTemplate from "Core/Controls/UserSecurity/Templates/View.html";
import EditTemplate from "Core/Controls/UserSecurity/Templates/Edit.html";
import ToolBarTemplate from "Core/Controls/UserSecurity/Templates/ToolBar.html";
import DesignTemplate from "Core/Controls/UserSecurity/Templates/Design.html";

import { UserSecurityModel } from "Core/Controls/UserSecurity/Models/UserSecurityModel";
import { UserSecurityViewModel, RoleUnitViewModel } from "Core/Controls/UserSecurity/Models/UserSecurityViewModel";
import { UserSecuritySerializedModel } from "Core/Controls/UserSecurity/Models/UserSecuritySerializedModel";
import { UserSecurityStore } from "Core/Controls/UserSecurity/Stores/UserSecurityStore";

ko.templates["Core/Controls/UserSecurity/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/UserSecurity/Templates/Edit"] = EditTemplate;
ko.templates["Core/Controls/UserSecurity/Templates/ToolBar"] = ToolBarTemplate;
ko.templates["Core/Controls/UserSecurity/Templates/Design"] = DesignTemplate;

enum Statuses {
	Ok,
	Error
}

let UserTypes = {
	User: "User",
	Portal: "Portal"
}

export class UserSecurity extends BaseControl {

	private _userId: number;
	private _data: KnockoutObservable<UserSecurityViewModel>;
	private _status: KnockoutObservable<Statuses>;

	constructor(params: IControlParam) {
		super(params);
		this._data = ko.observable(null);
		this._status = ko.observable(Statuses.Ok);
	}

	ApplyProperties() {}

	SetValue(value: IControlValue): void {
		this._userId = value.SubjectRecordId;
		BlockUI.Block();
		UserSecurityStore.GetUserSecurityData(value.SubjectRecordId)
			.always(() => {
				BlockUI.Unblock();
			})
			.then(result => {
				if (!result.IsSuccessfull) {
					this._status(Statuses.Error);
					this._errorMessage(result.ErrorMessage);
					return;
				}

				let userSecurityModel: UserSecurityModel = result.ResultObject;
				let userSecurityViewModel = new UserSecurityViewModel(userSecurityModel.UserId,
					userSecurityModel.UserName,
					userSecurityModel.RoleUnits);

				if (value.RecordSpecsModel.IsNewRecord) {
					this.SelectRole(userSecurityViewModel);
				}

				this._data(userSecurityViewModel);
				this._status(Statuses.Ok);
			});
	}

	AfterRender(el: Array<HTMLElement>): void {
		super.AfterRender(el);
	}

	Deserialize(): UserSecuritySerializedModel {
		if (this._status() === Statuses.Error) {
			return null;
		}

		let userRolesKey = this._data()
			.RoleUnits
			.reduce((suma, current) => {
				return current.IsUserInRole()
					? suma + current.RoleValue
					: suma;
			}, 0);

		return new UserSecuritySerializedModel(this._userId, userRolesKey);
	}

	private SelectRole(userSecurityViewModel: UserSecurityViewModel) {
		let recordType = this.GetForm().GetScreen().GetTableTypeName();
		if (recordType === UserTypes.User) {
			userSecurityViewModel.SelectRole(recordType);
		}
	}
}