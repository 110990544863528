import { Guid } from "Core/Common/Guid";
import { CollectionModel } from "DatabaseDesigner/Models/CollectionModel";

export function LinkEditorVisibilityModel() {
    this._guid = Guid.NewGuid();

    this._leftTypeId = null;
    this._leftTypeGuid = null;

    this._rightTypeId = null;
    this._rightTypeGuid = null;

    this._leftKindId = null;
    this._leftKindGuid = null;

    this._rightKindId = null;
    this._rightKindGuid = null;

    this._leftStatusId = null;
    this._leftStatusGuid = null;

    this._rightStatusId = null;
    this._rightStatusGuid = null;

    this._fullWidth = false;
    this._fontStylesId = [];
    this._fontColor = null;
}

LinkEditorVisibilityModel.prototype.FromJSON = function (data) {
    this._leftTypeId = data.LeftTypeId;
    this._leftTypeGuid = data.LeftTypeGuid;

    this._rightTypeId = data.RightTypeId;
    this._rightTypeGuid = data.RightTypeGuid;

    this._leftKindId = data.LeftKindId;
    this._leftKindGuid = data.LeftKindGuid;

    this._rightKindId = data.RightKindId;
    this._rightKindGuid = data.RightKindGuid;

    this._leftStatusId = data.LeftStatusId;
    this._leftStatusGuid = data.LeftStatusGuid;

    this._rightStatusId = data.RightStatusId;
    this._rightStatusGuid = data.RightStatusGuid;

    this._fullWidth = data.FullWidth;
    this._fontStylesId = data.FontStylesId;
    this._fontColor = data.FontColor;
};

LinkEditorVisibilityModel.prototype.toJSON = function () {
    return {
        Guid: this.Guid,
        LeftTypeId: this.LeftTypeId,
        LeftTypeGuid: this.LeftTypeGuid,
        LeftKindId: this.LeftKindId,
        LeftKindGuid: this.LeftKindGuid,
        LeftStatusId: this.LeftStatusId,
        LeftStatusGuid: this.LeftStatusGuid,
        RightTypeId: this.RightTypeId,
        RightTypeGuid: this.RightTypeGuid,
        RightKindId: this.RightKindId,
        RightKindGuid: this.RightKindGuid,
        RightStatusId: this.RightStatusId,
        RightStatusGuid: this.RightStatusGuid,
        FullWidth: this.FullWidth,
        FontStylesId: this.FontStylesId,
        FontColor: this.FontColor
    };
};

LinkEditorVisibilityModel.prototype.Reverse = function () {
    var newLeftTypeId = this._rightTypeId;
    var newRightTypeId = this._leftTypeId;
    var newLeftTypeGuid = this._rightTypeGuid;
    var newRightTypeGuid = this._leftTypeGuid;
    var newLeftKindId = this._rightKindId;
    var newRightKindId = this._leftKindId;
    var newLeftKindGuid = this._rightKindGuid;
    var newRightKindGuid = this._leftKindGuid;
    var newLeftStatusId = this._rightStatusId;
    var newRightStatusId = this._leftStatusId;
    var newLeftStatusGuid = this._rightStatusGuid;
    var newRightStatusGuid = this._leftStatusGuid;

    this._leftTypeId = newLeftTypeId;
    this._rightTypeId = newRightTypeId;
    this._leftTypeGuid = newLeftTypeGuid;
    this._rightTypeGuid = newRightTypeGuid;
    this._leftKindId = newLeftKindId;
    this._rightKindId = newRightKindId;
    this._leftKindGuid = newLeftKindGuid;
    this._rightKindGuid = newRightKindGuid;
    this._leftStatusId = newLeftStatusId;
    this._rightStatusId = newRightStatusId;
    this._leftStatusGuid = newLeftStatusGuid;
    this._rightStatusGuid = newRightStatusGuid;
};

LinkEditorVisibilityModel.prototype.FromJSONArray = function (data) {
    var linkEditorVisibilityList = new CollectionModel({ model: LinkEditorVisibilityModel });

    for (var index = 0; index < data.length; index++) {
        var linkEditorVisibilityModel = new LinkEditorVisibilityModel();

        linkEditorVisibilityModel.FromJSON(data[index]);
        linkEditorVisibilityList.Add(linkEditorVisibilityModel);
    }

    return linkEditorVisibilityList;
};

Object.defineProperty(LinkEditorVisibilityModel.prototype, 'Guid', {
    enumerable: true,
    get: function () {
        return this._guid;
    }
});

Object.defineProperty(LinkEditorVisibilityModel.prototype, 'LeftTypeId', {
    enumerable: true,
    get: function () {
        return this._leftTypeId;
    },
    set: function (value) {
        this._leftTypeId = value;
    }
});

Object.defineProperty(LinkEditorVisibilityModel.prototype, 'LeftTypeGuid', {
    enumerable: true,
    get: function () {
        return this._leftTypeGuid;
    },
    set: function (value) {
        this._leftTypeGuid = value;
    }
});

Object.defineProperty(LinkEditorVisibilityModel.prototype, 'RightTypeId', {
    enumerable: true,
    get: function () {
        return this._rightTypeId;
    },
    set: function (value) {
        this._rightTypeGuid = value;
    }
});

Object.defineProperty(LinkEditorVisibilityModel.prototype, 'RightTypeGuid', {
    enumerable: true,
    get: function () {
        return this._rightTypeGuid;
    },
    set: function (value) {
        this._rightTypeGuid = value;
    }
});

Object.defineProperty(LinkEditorVisibilityModel.prototype, 'LeftKindId', {
    enumerable: true,
    get: function () {
        return this._leftKindId;
    },
    set: function (value) {
        this._leftKindId = value;
    }
});

Object.defineProperty(LinkEditorVisibilityModel.prototype, 'LeftKindGuid', {
    enumerable: true,
    get: function () {
        return this._leftKindGuid;
    },
    set: function (value) {
        this._leftKindGuid = value;
    }
});

Object.defineProperty(LinkEditorVisibilityModel.prototype, 'RightKindId', {
    enumerable: true,
    get: function () {
        return this._rightKindId;
    },
    set: function (value) {
        this._rightKindId = value;
    }
});

Object.defineProperty(LinkEditorVisibilityModel.prototype, 'RightKindGuid', {
    enumerable: true,
    get: function () {
        return this._rightKindGuid;
    },
    set: function (value) {
        this._rightKindGuid = value;
    }
});

Object.defineProperty(LinkEditorVisibilityModel.prototype, 'LeftStatusId', {
    enumerable: true,
    get: function () {
        return this._leftStatusId;
    },
    set: function (value) {
        this._leftStatusId = value;
    }
});

Object.defineProperty(LinkEditorVisibilityModel.prototype, 'LeftStatusGuid', {
    enumerable: true,
    get: function () {
        return this._leftStatusGuid;
    },
    set: function (value) {
        this._leftStatusGuid = value;
    }
});

Object.defineProperty(LinkEditorVisibilityModel.prototype, 'RightStatusId', {
    enumerable: true,
    get: function () {
        return this._rightStatusId;
    },
    set: function (value) {
        this._rightStatusId = value;
    }
});

Object.defineProperty(LinkEditorVisibilityModel.prototype, 'RightStatusGuid', {
    enumerable: true,
    get: function () {
        return this._rightStatusGuid;
    },
    set: function (value) {
        this._rightStatusGuid = value;
    }
});

Object.defineProperty(LinkEditorVisibilityModel.prototype, 'FullWidth', {
    enumerable: true,
    get: function () {
        return this._fullWidth;
    },
    set: function (value) {
        this._fullWidth = value;
    }
});

Object.defineProperty(LinkEditorVisibilityModel.prototype, 'FontStylesId', {
    enumerable: true,
    get: function () {
        return this._fontStylesId;
    },
    set: function (value) {
        this._fontStylesId = value;
    }
});

Object.defineProperty(LinkEditorVisibilityModel.prototype, 'FontColor', {
    enumerable: true,
    get: function () {
        return this._fontColor;
    },
    set: function (value) {
        this._fontColor = value;
    }
});