import { IControl } from "Core/Controls/IControl";
import { ControlDataModel } from "Core/ScreenManager/Models/ControlDataModel";
import { IControlDataModelFacade } from "./Models/ControlDataModelFacade";

export const controlFacade = (control: IControl) => {
    
    const contextControl = control;

    const setData = (data: IControlDataModelFacade) =>{
        let controlData = new ControlDataModel();
        controlData.Value = `${data.value}`;
        contextControl.SetData(controlData);
    }

    const getData = () =>{
        return {
            value: contextControl.GetValue()
        }
    }

    const setBackgroundColor = (color: string)=>{
        contextControl.BackgroundColor = color;
    }

    return { setData, getData, setBackgroundColor }
}