import * as ko from 'knockout';

import { FIELD_TYPES } from "Core/Constant";

import {EventBusConsumer} from "Core/Common/EventBus/EventBusConsumer";

import {ProductPart} from "../../ProductPart";

import {ConfigurationPageEvents} from "Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Events/ConfigurationPageEvents";
import {ProductPropertyDescription} from "../ProductPropertyDescription";
import { PropertyValue } from '../../PropertyValue';
import { LABELS, NOTIFICATIONS } from "Core/Components/Translation/Locales";

export abstract class PropertyControl extends EventBusConsumer {
    private _fieldTypes = FIELD_TYPES;

    private _onValueChangedTimeout: number;
    protected onValueChangedTimeoutValue: number;

    HasContent: KnockoutObservable<boolean>;
    readOnly: KnockoutObservable<boolean>;
    protected validationMessage: KnockoutObservable<string>;
    protected isValidControl: KnockoutObservable<boolean>;
    private _isHiddenByAction: KnockoutObservable<boolean>;
    protected _labels = LABELS;
    protected _notifications = NOTIFICATIONS;

    protected constructor(protected property: ProductPropertyDescription, protected product: ProductPart) {
        super();
        this._labels = LABELS;
        this._notifications = NOTIFICATIONS;

        this.onValueChangedTimeoutValue = 300;
        this.HasContent = ko.observable(false);

        this.readOnly = ko.observable(false);
        this.validationMessage = ko.observable(this.GetTranslationMessages('VALID'));
        this.isValidControl = ko.observable(true);
        this._isHiddenByAction = ko.observable(false);

        this.AssignEventBus(this.product.ParentGroup.EventBus);
    }

    get Id() {
        return this.property.Id;
    }

    get Name() {
        return this.property.Name;
    }

    get DisplayName() {
        return this.property.TranslatedName || this.property.Name;
    }

    get ValueHolder() {
        return this.property.ValueHolderId;
    }

    get PriceInfluence() {
        return this.property.PriceInfluence;
    }

    get FormatName() {
        return this.property.FormatName;
    }

    get Size() {
        return this.property.Size;
    }

    get IsRequired(): boolean {
        return this.property.IsRequired;
    }

    get IsValidControl(): boolean {
        return this.isValidControl();
    }

    get ValidMessage(): any {
        return this.validationMessage();
    }

    get IsHiddenByAction(): boolean {
        return this._isHiddenByAction();
    }

    set IsHiddenByAction(value: boolean) {
        this._isHiddenByAction(value);
    }

    get SkipValidationOnSave(): boolean {
        return this.validationMessage() === this.GetTranslationMessages('MAX_SIZE');
    }

    OnValueChanged() {
        if (this.onValueChangedTimeoutValue > 0) {
            this.ClearOnValueChangedTimeout();
            this._onValueChangedTimeout = setTimeout(() => {
                this.DispatchEvent(ConfigurationPageEvents.PropertyValueChanged, this.GetValueForPrice());
            }, this.onValueChangedTimeoutValue);
        } else {
            this.DispatchEvent(ConfigurationPageEvents.PropertyValueChanged, this.GetValueForPrice());
        }
    }

    GetValueForPrice() {
        return this.GetValueForSave();
    }

    GetValueForPreview() {
        return this.GetValueForSave();
    }

    IsPropertyOwner(product: ProductPart) {
        return this.product === product;
    }

    Dispose() {
        this.ClearOnValueChangedTimeout();
        super.Dispose();
    }

    abstract GetTemplate();

    abstract GetValueForSave(): any;

    abstract SetValue(propertyValue: PropertyValue);

    abstract ClearValue();

    IsValid(value?: any): boolean {
        return true
    }

    private ClearOnValueChangedTimeout() {
        clearTimeout(this._onValueChangedTimeout);
    }

    GetDecimalIntegerValidRules(value: any): boolean {
        this.validationMessage(this.GetTranslationMessages('VALID'));
        return this.GetIsRequiredValidRule(value) && !this.GetIsNaNValidRule(value);
    }

    GetIsRequiredValidRule(value: any, controlType?: string): boolean {
        this.validationMessage(this.GetTranslationMessages('VALID'));
        if (!this.IsRequired){
            return true;
        }

        let isEmptyValue: boolean;
        if (controlType === this._fieldTypes.YesNo){
            isEmptyValue = value === false;
        } else {
            isEmptyValue = value === '' || value === null;
        }

        if (isEmptyValue){
            this.validationMessage(this.GetTranslationMessages('REQUIRED'));
            return false;
        }
        return true;
    }

    GetIsNaNValidRule(value: any): boolean{
        const isNaNValue: boolean = isNaN(value);
        this.validationMessage(this.GetTranslationMessages('VALID'));

        if (!isNaNValue){
            return false;
        }

        if (isNaNValue){
            this.validationMessage(this.GetTranslationMessages('NOT_VALID'));
            return true;
        }
    }

    GetIsSizeValidRule(value: any): boolean {
        const size = value && value.length;

        if (size >= this.Size && this.Size >= 0) {
            this.validationMessage(this.GetTranslationMessages('MAX_SIZE'));
            return false;
        } else {
            this.validationMessage(this.GetTranslationMessages('VALID'));
            return true;
        }
    }

    GetDecimalIntegerValid(value: any): boolean {
        return this.GetIsRequiredValidation(value) && !this.GetIsNaNValid(value);
    }

    GetIsNaNValid(value: any): boolean{
        const isNaNValue: boolean = isNaN(value);
        if (!isNaNValue){
            return false;
        }
        if (isNaNValue){
            return true;
        }
    }
    GetIsRequiredValidation(value: any, controlType?: string): boolean {
        if (!this.IsRequired){
            return true;
        }

        let isEmptyValue: boolean;
        if (controlType === this._fieldTypes.YesNo){
            isEmptyValue = value === false;
        } else {
            isEmptyValue = value === '' || value === null;
        }
        return !isEmptyValue;
    }

    GetSizeValidation(value: any): boolean {
        const size = value && value.length;
        return size <= this.Size || this.Size < 0;
    }

    CheckValidControl(): void{

    }

    GetTranslationMessages(key: string): any {
        const validMassages = {
            'VALID':        {Id: 0, Name: null, TranslationName: null},
            'NOT_VALID':    {Id: 1, Name: 'Not valid', TranslationName: 'Not valid'},
            'REQUIRED':     {Id: 2, Name: 'Required*', TranslationName: this._labels.REQUIRED_FLAG},
            'MAX_SIZE':     {Id: 3, Name: `Maximum length of this field is ${this.Size}`, TranslationName: `${this._notifications.MAXIMUM_LENGTH_OF_THIS_FIELD} ${this.Size}`}
        };
        return validMassages[key].TranslationName;
    }
}