import * as ko from 'knockout';

import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';
import {Guid} from 'Core/Common/Guid';

import {EventBus} from 'Core/Common/EventBus/EventBus';
import {EventBusConsumer} from 'Core/Common/EventBus/EventBusConsumer';

import {NOTIFICATIONS} from 'Core/Components/Translation/Locales';

import {SearchScreen} from 'Core/Screens/SearchScreen/SearchScreen';

import {RemoteAccessUser} from '../Models/RemoteAccessUser';
import {RemoteDatabaseAccessData} from '../Models/RemoteDatabaseAccessData';

import {LABELS} from 'Core/Components/Translation/Locales';

import {
    NetworkDesignerEvents,
    NewRemoteDatabaseAccess,
    RemoteDatabaseAccessChanges
} from '../Events/NetworkDesignerEvents';

import Template from '../Templates/AccessTemplate.html';

ko.templates['Core/NetworkDesigner/Templates/AccessTemplate'] = Template;

export class RemoteDatabaseAccess extends EventBusConsumer {
    private _el: HTMLElement;
    private _uniqueId: string;

    private _error: KnockoutObservable<string>;
    private _editing: KnockoutObservable<boolean>;
    private _selectedUser: KnockoutObservable<RemoteAccessUser>;
    private _remoteUserName: KnockoutObservable<string>;
    private _saveClicked: KnockoutObservable<boolean>;

    _labels = LABELS;

    constructor(public Id: number, public UserId: number, public UserName: string, public RemoteUserName: string) {
        super();

        this._uniqueId = Guid.NewGuid();

        this._error = ko.observable(null);
        this._editing = ko.observable(false);
        this._selectedUser = ko.observable(null);
        this._remoteUserName = ko.observable(null);
        this._saveClicked = ko.observable(false);
    }

    static Create(eventBus: EventBus) {
        const access = new RemoteDatabaseAccess(null, null, null, null);

        access.AssignEventBus(eventBus);
        access.EnableEditMode();

        return access;
    }

    get UniqueId() {
        return this._uniqueId;
    }

    get HasError() {
        return !!this._error();
    }

    GetTemplateName() {
        return 'Core/NetworkDesigner/Templates/AccessTemplate';
    }

    AfterRender(el: HTMLElement) {
        this._el = el[0];
    }

    Block() {
        BlockUI.Block({
            Target: this._el
        });
    }

    UnBlock() {
        BlockUI.Unblock(this._el);
    }

    ContainsData(data: RemoteDatabaseAccessData) {
        if (!this.Id) {
            return false;
        }

        if (this._editing()) {
            const sameUser = this._selectedUser() && this._selectedUser().Id === data.UserId;
            const sameRemoteUser = this._remoteUserName() === data.RemoteUserName;

            return sameUser && sameRemoteUser;
        }

        return this.UserId == data.UserId && this.RemoteUserName === data.RemoteUserName;
    }

    SetError(error: string) {
        this._error(error);
    }

    ClearError() {
        this._error(null);
    }

    EditClicked() {
        this.EnableEditMode();
    }

    CancelClicked() {
        this.ClearError();
        this.DisableEditMode();

        if (!this.Id) {
            this.DispatchEvent(NetworkDesignerEvents.CreatingAccessCancelled);
            return;
        }

        this.DispatchEvent(NetworkDesignerEvents.EditingAccessCancelled);
    }

    SaveClicked() {
        this._saveClicked(true);

        const userSelected = !!this._selectedUser();
        const remoteUserSpecified = !!this._remoteUserName();

        if (!userSelected || !remoteUserSpecified) {
            return;
        }

        if (!this.Id) {
            const eventArgs = new NewRemoteDatabaseAccess(this._uniqueId, this._selectedUser().Id, this._remoteUserName());
            this.DispatchEvent(NetworkDesignerEvents.SavingAccessRequested, eventArgs);

            return;
        }

        const eventArgs = new RemoteDatabaseAccessChanges(this.Id, this._selectedUser().Id, this._remoteUserName());
        this.DispatchEvent(NetworkDesignerEvents.SavingAccessChangesRequested, eventArgs);
    }

    DeleteClicked() {
        this.DispatchEvent(NetworkDesignerEvents.RemovingAccessRequested);
    }

    CommitChanges() {
        this.UserId = this._selectedUser().Id;
        this.UserName = this._selectedUser().Name;
        this.RemoteUserName = this._remoteUserName();

        this.DisableEditMode();
    }

    private ShowSearchUserScreen() {
        const screen = new SearchScreen({
            SearchTerm: '',
            EntityName: 'SYS_USERS',
            ButtonAdd: false,
            CloseAfterSelectingRecord: false
        });

        screen.On('RECORD_SELECTED', this, eventArgs => {
            const validTypes = ['USER'];

            if (validTypes.indexOf(eventArgs.data.TypeName.toUpperCase()) < 0) {
                new Notifier().Warning(NOTIFICATIONS.TYPE_IS_NOT_SUPPORTED);
                return;
            }

            this._selectedUser(new RemoteAccessUser(eventArgs.data.RecordId, eventArgs.data.Name));

            screen.Close();
        });

        screen.Show();
    }

    private EnableEditMode() {
        if (this.UserId) {
            this._selectedUser(new RemoteAccessUser(this.UserId, this.UserName));
            this._remoteUserName(this.RemoteUserName);
        }

        this._editing(true);
    }

    private DisableEditMode() {
        this._editing(false);
        this._selectedUser(null);
        this._remoteUserName(null);
        this._saveClicked(false);
    }
}