import * as ko from 'knockout';

import { Notifier } from "Core/Common/Notifier";

import {ColorConverter} from "Core/Components/ColorSelector/ColorConverter";

import {PropertyControl} from "../PropertyControl";

import {ColorModel} from "./Models/ColorModel";

import {ColorPropertyControlStore} from "./Stores/ColorPropertyControlStore";
import {Guid} from "Core/Common/Guid";

import Template from './Template.html';
import * as _ from "underscore";
import {ProductPropertyDescription} from "../../ProductPropertyDescription";
import { ProductPart } from "../../../ProductPart";
import {BlockUI} from 'Core/Common/BlockUi';

import {
    EVENTS as CONFIRMATION_DIALOG_EVENTS, Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { ColorPalettePopup } from '../../../../Modals/ColorPalettePopup/ColorPalettePopup';

import { ConfigurationPageEvents } from "Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Events/ConfigurationPageEvents";
import { PropertyValue } from '../../../PropertyValue';

export class ColorPropertyControl extends PropertyControl {
    private _store: ColorPropertyControlStore;
    private _selectedColorName: string;
    private _subscriptionReady: boolean;
    private _showAll: KnockoutObservable<boolean>;

    Colors: KnockoutObservableArray<ColorModel>;
    SelectedColor: KnockoutObservable<ColorModel>;
    ColorObservableArray: KnockoutObservableArray<any>

    constructor(property: ProductPropertyDescription, productPart: ProductPart, ordersEntityId: number, productEntityId: number) {
        super(property, productPart);

        this._store = new ColorPropertyControlStore(ordersEntityId, productEntityId);

        this.Colors = ko.observableArray([]);
        this.SelectedColor = ko.observable(null);

        this._showAll = ko.observable(false);
        this._selectedColorName = null;
        this.ColorObservableArray = ko.observableArray([]);
    }

    OnInit() {
        if (!this.HasContent()) {
            if (!this.readOnly()) {
                this.LoadColors();
            }
        } else {

            if (this.Colors().length > 9) {
                this._showAll(false);
            } else {
                this._showAll(true);
            }
        }
    }

    GetTemplate() {
        return Template;
    }

    GetValueForSave(): any {
        if (this.SelectedColor() && this.SelectedColor().Name) {
            return this.SelectedColor().Name;
        }
        return null;
    }

    GetValueForPreview(): any {
        if (this.SelectedColor() && this.SelectedColor().Name) {
            return ColorConverter.ToHex(this.SelectedColor().Name);
        }
        return null;
    }

    SetValue(propertyValue: PropertyValue) {
        if (propertyValue) {
            this._selectedColorName = propertyValue.Value;

            if (this.readOnly()) {
                this.Colors().push(new ColorModel(propertyValue.Value, ColorConverter.ToHex(propertyValue.Value)))
                this.HasContent(true);
                this.UpdateSelectedColorReference();
            }
        }
    }

    ClearValue() {
        this.SelectedColor(null);
    }

    SelectColor(data){
        if ( _.some(this.ColorObservableArray(), (item)=> item.id === data.id) ){
            let selectedItem = _.find(this.Colors(), (item)=> item.Id === data.id);
            this.Select(selectedItem);
        }
    }

    Select(color: ColorModel) {
        if (color == null) {
            this.UnSelect();
        } else {
            this._selectedColorName = color.Name;
            this.UpdateSelectedColorReference()
        }
    }

    UnSelect() {
        this._selectedColorName = null;
        this.UpdateSelectedColorReference();
    }

    private OnColorClick(color: ColorModel) {
        if (this._showAll()) {
            if (this.SelectedColor() === color) {
                this.UnSelect();
                return;
            }

            this.Select(color);
        }
        else {
            BlockUI.Block({ZIndex: 11000});

            const confirmationDialog = new ColorPalettePopup({
                ModalClass: 'jBox-padding-15px',
                Colors: this.Colors(),
                SelectedColor: this.SelectedColor(),
                Images: null,
                SelectedImage: null,
                Width: '90vw',
                Height: '90vh',
                closeButton: 'box'
            });

            confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, (eventArgs: any) => {
                this.Select(eventArgs.data);
            });

            confirmationDialog.Show();
        }

    }

    private StartListeningValueChanges() {
        if (!this._subscriptionReady) {
            this.SelectedColor.subscribe(() => {
                this._selectedColorName = this.SelectedColor() && this.SelectedColor().Name;
                this.CheckValidControl();
                this.OnValueChanged();
            });

            this._subscriptionReady = true;
        }
    }

    private LoadColors() {
        this.DispatchEvent(ConfigurationPageEvents.PropertyContentLoading);
        this._store.GetAvailableColors(this.property.ProductId, this.ValueHolder)
            .then(colorsResponse => {
                const colors = colorsResponse.map(color => new ColorModel(color.Name, ColorConverter.ToHex(color.Name), Guid.NewGuid()));
                this.Colors(colors);
                this.UpdateSelectedColorReference();
                this.StartListeningValueChanges();

                this.HasContent(this.Colors().length > 0);

                if (this.Colors().length > 9) {
                    this._showAll(false);
                } else {
                    this._showAll(true);
                }

                let colorsMap = _.map(colors, color =>{
                    return {
                        label: color.Name,
                        id: color.Id
                    }
                });
                this.ColorObservableArray(colorsMap);
            })
            .fail(err => new Notifier().Failed(err.message))
            .always(() => this.DispatchEvent(ConfigurationPageEvents.PropertyContentLoaded));
    }

    get GetColorObservableArray(){
        return this.ColorObservableArray();
    }

    private UpdateSelectedColorReference() {
        const selectedColor = _.find(this.Colors(), color => color.Name === this._selectedColorName);
        this.SelectedColor(selectedColor);
    }

    IsValid(value?: any): boolean {
        return this.GetIsRequiredValidation(value ? value : this.GetValueForSave());
    }

    CheckValidControl() {
        this.isValidControl(this.GetIsRequiredValidRule(this.GetValueForSave()));
    }
}