import * as ko from "knockout";

import {GridCellValueModel} from 'Core/Controls/Grid/Models/GridDataModel/GridCellValueModel';


import Template from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/FavoriteCell.html';
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/FavoriteCell'] = Template;

export class FavoriteCell {
	private _isFavorite: KnockoutObservable<boolean>;

	constructor() {
		this._isFavorite =  ko.observable(false);
	}

	set IsFavorite(value: boolean) {
		this._isFavorite(value);
	}

	GetTemplateName(): string {
		return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/FavoriteCell';
	}
}