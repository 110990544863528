import * as ko from "knockout"
import 'jquery';
import * as JsBarcode from "jsbarcode";

export class JsBarcodeExtension {
    static Init() {
        ko.bindingHandlers.jsBarcode = {
            init: (element, valueAccessor) => {
                const options = ko.unwrap(valueAccessor()) as IBarcodeConfig;
                const otherOptions = options.options;

                JsBarcode(element, options.code, otherOptions);
            }
        };
    }

    static Validate(barcode) {
        let isValid = false;

        if (barcode) {
            if (/^[\x00-\x7F\xC8-\xD3]+$/.test(barcode)) {
                isValid = true;
            }
        }

        return isValid;
    }
}

export interface IBarcodeConfig {
    code: string;
    options: any;
}