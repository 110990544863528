import * as ko from 'knockout';

import {Event} from 'Core/Common/Event';
import {EVENTS} from 'Core/ProfilePage/Events/Events';
import {ThemeBuilder} from 'Core/ProfilePage/ThemeBuilder/ThemeBuilder';
import {LicenseHolder} from 'Core/ProfilePage/LicenseHolder/LicenseHolder';
import {PersonalSettings} from 'Core/ProfilePage/PersonalSettings/PersonalSettings';

import {NotificationDesigner} from 'Core/ProfilePage/NotificationDesigner/NotificationDesigner';
import {ProfileCard} from 'Core/ProfilePage/ProfileCard/ProfileCard';
import {RemoteDatabases} from 'Core/ProfilePage/NetworkDatabases/RemoteDatabases';
import {LABELS} from "Core/Components/Translation/Locales";

import ProfilePageTemplate from 'Core/ProfilePage/Templates/ProfilePage.html';
import {UserManager, UserRoles} from '../../User/UserManager';

ko.templates['Core/ProfilePage/Templates/ProfilePage'] = ProfilePageTemplate;

export class ProfilePage extends Event {
    private _themeBuilder: ThemeBuilder;
    private _licenseHolder: LicenseHolder;
    private _personalSettings: PersonalSettings;
    private _profileCard: ProfileCard;
    private _notificationDesigner: NotificationDesigner;
    private _remoteDatabases: RemoteDatabases;
    private _enabledLicenseHolder: KnockoutObservable<boolean>;
    private _enabledNetwork: KnockoutObservable<boolean>;
    private _isReady: KnockoutObservable<boolean>;
    private _labels = LABELS;


    constructor() {
        super()
        this.AddEvent(EVENTS.PROFILE_CARD_IMAGE_CLICKED);

        this._isReady = ko.observable(true);
        this._enabledLicenseHolder = ko.observable(false);
        this._enabledNetwork = ko.observable(false);

        this.Init();
    }


    Init() {
        this._themeBuilder = new ThemeBuilder();

        const isLicenseHolderVisible = UserManager.Instance.IsUserInRole(UserRoles.SuperUser) || UserManager.Instance.IsUserInRole(UserRoles.US);

        if (isLicenseHolderVisible) {
            this._enabledLicenseHolder(true);
            this._licenseHolder = new LicenseHolder();
        }

        this._personalSettings = new PersonalSettings();
        this._notificationDesigner = new NotificationDesigner();

        this._profileCard = new ProfileCard();
        this._profileCard.On('IMAGE_CLICKED', this, (eventArgs) => {
            this.Trigger(EVENTS.PROFILE_CARD_IMAGE_CLICKED);
        });

        const isRemoteSession = UserManager.Instance.CurrentUser.IsRemote;
        if (!isRemoteSession) {
            this._enabledNetwork(true);
            this._remoteDatabases = new RemoteDatabases();
        }
    }

    RenderByTargetId(target: string): void {
        ko.cleanNode(document.getElementById(target));
        ko.applyBindings(this, document.getElementById(target));
    }

    GetTemplateName() {
        return 'Core/ProfilePage/Templates/ProfilePage';
    }

    AfterRender(el: Array<HTMLElement>) {

    }

    get LicenseHolder(): LicenseHolder {
        return this._licenseHolder;
    }

    get PersonalSettings(): PersonalSettings {
        return this._personalSettings;
    }

    get ThemeBuilder(): ThemeBuilder {
        return this._themeBuilder;
    }

    get ProfileCard(): ProfileCard {
        return this._profileCard;
    }

    get RemoteDatabases(): RemoteDatabases {
        return this._remoteDatabases;
    }

    get NotificationDesigner(): NotificationDesigner {
        return this._notificationDesigner;
    }
} 