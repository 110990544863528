import * as ko from 'knockout';
import { Guid } from "Core/Common/Guid";

import HelpDescriptionTemplate from 'HelpBook/HelpDescription/Templates/HelpDescription.html';
ko.templates['HelpBook/HelpDescription/Templates/HelpDescription'] = HelpDescriptionTemplate;

export class HelpDescription {

	private noDescription: KnockoutObservable<boolean>;
	private _guid: string;
	private _editor: any;
	private description: KnockoutObservable<string>;
	private previewText: KnockoutObservable<string>;
	private showFullDescription: KnockoutObservable<boolean>;

	private isActive: KnockoutObservable<boolean>;

	constructor(description) {
		this.noDescription = ko.observable(false);
		this._guid = Guid.NewGuid();
		this.isActive = ko.observable(false);
		this.description = ko.observable(description);
		this.showFullDescription = ko.observable(false);
		this.previewText = ko.observable('');
		if (!this.description() || !this.description().trim()) {
			this.noDescription(true);
		}

	}

	ToggleDescription() {
		this.showFullDescription(!this.showFullDescription());
	}

	AfterTinyInit(editor) {

		this._editor = editor;
		editor.setMode('readonly');
		editor.setContent(this.description());
		this.previewText(this.description().replace(/<[^>]*>/g, "").substr(0, 45) + '...');
	}

	GetEditor() {
		return this._editor;
	}

	ActivateDescription() {
		this.isActive(!this.isActive());
	}

	GetTemplateName(): string {
		return 'HelpBook/HelpDescription/Templates/HelpDescription';
	}

}