import * as ko from "knockout";
import * as _ from "underscore";

import {LABELS} from "Core/Components/Translation/Locales";
import {RoleUnitModel} from "Core/Controls/UserSecurity/Models/UserSecurityModel";
import {UserRoles} from 'User/UserManager';

export class UserSecurityViewModel {
    UserId: number;
    UserName: string;
    RoleUnits: RoleUnitViewModel[];

    constructor(userId: number, userName: string, roleUnits: RoleUnitModel[]) {
        this.UserId = userId;
        this.UserName = userName;

        const roleUnitsOrder = Object.values(UserRoles);
        this.RoleUnits = roleUnits
            .map(roleUnit => RoleUnitViewModel.Map(roleUnit))
            .sort((a, b) => roleUnitsOrder.indexOf(a.RoleValue) - roleUnitsOrder.indexOf(b.RoleValue));
    }

    SelectRole(roleName: string) {
        let selectedRole = _.find(this.RoleUnits, unit => unit.RoleName === roleName);
        if (selectedRole) {
            selectedRole.IsUserInRole(true);
        }
    }
}

export class RoleUnitViewModel {
    RoleName: string;
    RoleValue: number;
    IsUserInRole: KnockoutObservable<boolean>;
    CouldBeChanged: KnockoutObservable<boolean>;

    constructor() {
        this.IsUserInRole = ko.observable(false);
        this.CouldBeChanged = ko.observable(false);
    }

    //Translation for UserSecurity - Role
    get TranslationUserSecurityRoleName() {
        switch (this.RoleValue) {
            case UserRoles.User :
                return LABELS.USER;
            case UserRoles.SuperUser :
                return LABELS.SUPER_USER;
            case UserRoles.DataRole :
                return LABELS.DATA_ROLE;
            case UserRoles.DesignerLevel1 :
                return LABELS.DESIGNER_LEVEL1;
            case UserRoles.DesignerLevel2 :
                return LABELS.DESIGNER_LEVEL2;
            case UserRoles.DesignerLevel3 :
                return LABELS.DESIGNER_LEVEL3;
            case UserRoles.NetworkManager:
                return LABELS.NETWORK_MANAGER;
            case UserRoles.US :
                return LABELS.US;
            default :
                return this.RoleName;
        }
    }

    static Map(roleUnit: RoleUnitModel) {
        const viewModel = new RoleUnitViewModel();

        viewModel.RoleName = roleUnit.RoleName;
        viewModel.RoleValue = roleUnit.RoleValue;
        viewModel.IsUserInRole(roleUnit.IsUserInRole);
        viewModel.CouldBeChanged(roleUnit.CouldBeChanged);

        return viewModel;
    }
}