import {P} from "Core/Common/Promise";
import {Request} from "Core/Common/Request";
import Rejection = P.Rejection;

import {GetDataRolesResponseModel} from "Core/Controls/FieldSecurity/Shared/Models/DTO/Get/GetDataRolesResponseModel";
import { GetFieldCollectionsResponseModel } from "Core/Controls/FieldSecurity/Shared/Models/DTO/Get/GetFieldCollectionsResponseModel";

import {AssigneeDTOModel} from "Core/Controls/FieldSecurity/Shared/Models/DTO/Get/AssigneeDTOModel";


export class FieldSecurityStore {
	static GetDataRoles(userId): P.Promise<GetDataRolesResponseModel> {
		const deferred = P.defer<GetDataRolesResponseModel>();

		Request.Send({
			proxy: {
				url: 'api/ControlsApi/GetDataRoles',
				type: 'Get',
				dataType: 'json'
			}, params: {id: userId}
		}).then(response => this.ResolveResponse(deferred, response))
			.fail(error => this.RejectResponse(deferred, error));

		return deferred.promise();
	}

	static GetUsers(userTypeName): P.Promise<AssigneeDTOModel[]> {
		const deferred = P.defer<AssigneeDTOModel[]>();

		Request.Send({
			proxy: {
				url: 'api/ControlsApi/GetUsersWithoutCollections',
				type: 'GET',
				dataType: 'json'
			}, params: {
				typeName: userTypeName
			}
		}).then(response => response.IsSuccessfull
			? this.ResolveResponse(deferred, response.ResultObject)
			: this.RejectResponse(deferred, {message: response.ErrorMessage})
		).fail(error => this.RejectResponse(deferred, error));

		return deferred.promise();
	}

	static GetUserFieldCollections(): P.Promise<GetFieldCollectionsResponseModel> {
		const deferred = P.defer<GetFieldCollectionsResponseModel>();

		Request.Send({
			proxy: {
				url: 'api/FieldCollectionDesignerApi/GetUserCollection',
				type: 'GET',
				dataType: 'json'
			}
		}).then(response => this.ResolveResponse(deferred, response))
			.fail(error => this.RejectResponse(deferred, error));

		return deferred.promise();
	}

	private static ResolveResponse<T>(deferred: P.Deferred<T>, response: T) {
		deferred.resolve(response);
	}

	private static RejectResponse<T>(deferred: P.Deferred<T>, error: Rejection) {
		deferred.reject(error);
	}
}