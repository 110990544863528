import {deserialize, deserializeAs, GenericDeserialize} from 'libs/cerialize';
import {GridDataModel} from 'Core/Controls/Grid/Models/GridDataModel/GridDataModel';
import {GridTotalCellValueModel} from 'Core/Controls/Grid/Models/GridDataModel/GridTotalCellValueModel';

export class GridTotalRowModel {

	@deserializeAs(GridTotalCellValueModel)
	Data: Array<GridTotalCellValueModel>;

	@deserialize
	Guid: string;

	constructor() {
		this.Data = [];
	}
}