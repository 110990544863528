import {FlagResolver} from "Core/Common/FlagResolver";
import {FieldFlags} from "Core/Common/Enums/FieldFlags";

import {TranslationManager} from "Core/Components/Translation/TranslationManager";
import {TranslationFieldEditor} from "Core/Components/TranslationFieldEditor/TranslationFieldEditor";
import {LABELS} from "Core/Components/Translation/Locales";

import {ColumnEditorFactory} from "LookupEditor/Utils/ColumnEditorFactory";
import {ColumnEditor} from "LookupEditor/ColumnEditors/Base/ColumnEditor";
import {IValueUpdater} from "LookupEditor/Interfaces/IValueUpdater";
import { LookupFieldModel } from "LookupEditor/Store/Models/LookupModel";
import {ZIndexManager} from "../../Core/Common/ZIndexManager";

export class EditableColumnModel {
	private _id: number;
	private _name: string;
	private _nameTranslation: string;
	private _valFieldId: number;
	private _valTableId: number;
	private _isPrimaryKey: boolean;
	private _isRequired: boolean;
	private _type: string;
	private _flags: number;
	private _columnEditor: ColumnEditor;
	private _owner: any;
	private _fieldTableId: number;
	private _format: string;
	private _labels = LABELS;

	constructor(fieldTableId: number, field: LookupFieldModel) {
		this._id = field.Id;
		this._name = field.Name;
		this._nameTranslation = field.NameTranslated;
		this._valFieldId = field.ValFieldId;
		this._valTableId = field.ValTableId;
		this._isPrimaryKey = field.IsPrimaryKey;
		this._isRequired = FlagResolver.ContainsFlag(FieldFlags.Required, field.Flags);;
		this._type = field.Type;
		this._flags = field.Flags;
		this._format = field.FormatName;
		this._fieldTableId = fieldTableId;


		this._columnEditor = ColumnEditorFactory.Instance.GetEditor(this);
	}

	get Id() {
		return this._id;
	}

	get Name() {
		return this._name;
	}

	get ValFieldId() {
		return this._valFieldId;
	}

	get ValTableId() {
		return this._valTableId;
	}

	get IsPrimaryKey() {
		return this._isPrimaryKey;
	}

	get IsRequired() {
		return this._isRequired;
	}

	get TypeName() {
		return this._type;
	}

	get Flags() {
		return this._flags;
	}

	get ColumnEditor() {
		return this._columnEditor;
	}

	get TableId() {
		return this._fieldTableId;
	}

	get HasTranslations() {
		return FlagResolver.ContainsFlag(FieldFlags.Translate, this._flags);
	}

	get IsReadOnly() {
		return (FieldFlags.ReadOnly & this.Flags) === FieldFlags.ReadOnly;
	}

	get IsSystem() {
		return (FieldFlags.System & this.Flags) === FieldFlags.System;
	}

	GetTemplateName(): string {
		return this._columnEditor.GetTemplateName();
	}

	AfterRender(owner: IValueUpdater, el) {
		this.ColumnEditor.AfterRender(el, owner, this.Name, this._format);
	}
}
