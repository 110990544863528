import * as ko from "knockout";

export class TagViewModel {
	constructor(
		public Name: string,
		public TagId: number,
		public GroupId: number,
		public TypeId: number
	) {
		this.IsSelected = ko.observable(false);
		this.IsExcluded = ko.observable(false);
		this.IsEnabled = ko.observable(true);
		this.IsFirstWord = false;
	}

	IsSelected: KnockoutObservable<boolean>;
	IsEnabled: KnockoutObservable<boolean>;
	IsExcluded: KnockoutObservable<boolean>;
	IsFirstWord: boolean;
}