import * as ko from 'knockout';

import {BusinessRoleRestrictions} from 'Core/GeneralProperties/Managers/TabSecurity/Enums/BusinessRoleRestrictions';
import {BusinessRole} from 'Core/GeneralProperties/Managers/TabSecurity/Models/BusinessRole';

export class BusinessRolesEditSettings {
    View: KnockoutObservable<boolean>;
    Create: KnockoutObservable<boolean>;
    Update: KnockoutObservable<boolean>;
    Delete: KnockoutObservable<boolean>;

    constructor(public Role: BusinessRole, value: number) {
        this.View = ko.observable(BusinessRoleRestrictions.View.PresentIn(value));
        this.Create = ko.observable(BusinessRoleRestrictions.Create.PresentIn(value));
        this.Update = ko.observable(BusinessRoleRestrictions.Update.PresentIn(value));
        this.Delete = ko.observable(BusinessRoleRestrictions.Delete.PresentIn(value));
    }

    get Value() {
        return (this.View() && BusinessRoleRestrictions.View.Value) +
            (this.Create() && BusinessRoleRestrictions.Create.Value) +
            (this.Update() && BusinessRoleRestrictions.Update.Value) +
            (this.Delete() && BusinessRoleRestrictions.Delete.Value);
    }

    get AnyRestrictions() {
        return this.View() || this.Create() || this.Update() || this.Delete();
    }
}