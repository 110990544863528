import * as ko from "knockout";

import { LABELS } from 'Core/Components/Translation/Locales';

import Template from 'QueryBuilder/QueryCondition/ConditionEditors/YesNo/Templates/YesNo.html'

export class YesNo {
	Value: KnockoutObservable<boolean>;

	private _labels = LABELS;

	constructor() {
		this.Value = ko.observable(false);
	}

	GetTemplate() {
		return Template;
	}

	AfterRender(el: Array<HTMLElement>) {

	}
} 