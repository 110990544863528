import * as ko from 'knockout'
import * as _ from 'underscore';

import {BaseControl, IControlValue} from 'Core/Controls/BaseControl/BaseControl'
import {IControlParam} from 'Core/Screens/IScreen'
import { RenderModes } from "Core/Constant";
import { GeneralProperties } from "Core/GeneralProperties/GeneralProperties";
import {CHECKBOX_PROPERTIES} from "Core/GeneralProperties/Managers/CheckboxProperty/Constants";

import EditButtonConfig from "Core/Controls/ButtonEdit/Configs/edit-button-config.json";

import ViewTemplate from "Core/Controls/ButtonEdit/Templates/View.html"
import HelpViewTemplate from "Core/Controls/ButtonEdit/Templates/HelpView.html"
import DesignTemplate from "Core/Controls/ButtonEdit/Templates/Design.html"
import ToolbarTemplate from "Core/Controls/ButtonEdit/Templates/ToolBar.html"

ko.templates["Core/Controls/ButtonEdit/Templates/ToolBar"] = ToolbarTemplate;
ko.templates["Core/Controls/ButtonEdit/Templates/View"] = ViewTemplate;
ko.templates["Core/Controls/ButtonEdit/Templates/HelpView"] = HelpViewTemplate;
ko.templates["Core/Controls/ButtonEdit/Templates/Edit"] = ViewTemplate;
ko.templates["Core/Controls/ButtonEdit/Templates/Design"] = DesignTemplate;

export class ButtonEdit extends BaseControl {
    private _config: any;

	constructor(params: IControlParam) {
		super(params, EditButtonConfig);
        this._style = ko.computed(() => {
            return {
                backgroundColor: this._backgroundColor(),
                color: this._color(),
                borderColor: this._borderColor(),
                borderWidth: this._border() ? '1px' : '0',
                padding: this._border() ? '6px 14px' : '7px 14px'
            };
        });
		this.Init();
	}

	private Init(): void {
        this.ApplyProperties()
	}

	Click(): void {
		if (this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
		} else {
			var screen = this._form.GetScreen();
			if (screen) {
				screen.Trigger('EDIT_RECORD');
			}
		}
	}

	SetValue(value: IControlValue): void {
		if (value.Data && value.Data.Rights) {
			var condition = value.Data.Rights.IsEditingAllowed && value.Data.Rights.IsRecordSecurityOk && this.IsEditScreenExist;
			this._enabled(condition);
		}
	}

    SetHideCaption(data: boolean) {
        this.HideCaption(this.HideCaptionFromProperty() ? this.HideCaptionFromProperty() : data);
    }

    GetHideCaption(): boolean {
        return this.HideCaption();
    }

    ApplyProperties() {
        if (this.Properties) {
            this.AssignProperty('Styling', 'BackgroundColor', this._backgroundColor);
            this.AssignProperty('Styling', 'TextColor', this._color);
            this.AssignProperty('Styling', 'Border', this._border);
            this.AssignProperty('Styling', 'BorderColor', this._borderColor);
            this.AssignProperty('Styling', CHECKBOX_PROPERTIES.HIDE_CAPTION, this.HideCaptionFromProperty);
            this.HideCaption(this.HideCaptionFromProperty());
        }
    }

    private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
        if (this.Properties[groupedBy]) {
            _.each(this.Properties[groupedBy].Properties,
                (property: any) => {
                    if (property.hasOwnProperty(propertyName)) {
                        propertyHolder(property[propertyName]);
                    }
                });
        }
    }

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}
}