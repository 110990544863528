import * as ko from 'knockout';
import 'jqueryInputmask';
import 'inputmaskExtensions';
import 'inputmaskRegEx';
import 'inputmaskNumeric';
import 'jqueryInputmask';
import * as moment from 'moment';
import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel';
import {FieldFormat} from 'Core/Common/FieldFormat';
import {FIELD_TYPES} from 'Core/Constant';
import {FormatConverter} from 'FormatEditor/FormatConverter';
import {AdditionalAliases} from 'Core/KnockoutExtentions/InputMask/AdditionalAliases';

Inputmask.extendAliases(AdditionalAliases);

export class MaskFormater {
	public static Format(el: HTMLElement, fieldModel: AttachedFieldModel, value: any, fieldType?: string, fieldNumExceptNegative?: boolean, fieldSize?: number) {
		const defaultValue = value;

		if (value == null) {
			value = '';
		}

		let formatName;
		let size = 2;
		if(fieldSize){
			size = fieldSize;
		}
		if (defaultValue) {
			$(el).val(value);
		}

		if (fieldModel) {
			formatName = fieldModel.FormatName;
			if (fieldModel.FieldTypeName === FIELD_TYPES.Decimal && fieldModel.FormatName === 'None') {
				formatName = FieldFormat.DECIMAL;
			}

			if (fieldModel.FieldTypeName === FIELD_TYPES.Currency) {
				formatName = FieldFormat.CURRENCY;
			}
			size = fieldModel.Size;
		} else if (fieldType) {
			formatName = fieldType;
		}

		const decimalSeparator = FormatConverter.GetSeparator();
		const groupSeparator = FormatConverter.GetGroupSeparator();

		const decimalInputMask = {
			radixPoint: decimalSeparator,
			digits: size,
			integerDigits: 29 - size,
			autoGroup: true,
			groupSeparator,
			autoUnmask: true,
			rightAlign: false
		};

		(moment as any).createFromInputFallback = config => config._d = new Date(config._i);

		if (FieldFormat.IsUppercase(formatName)) {
			value = value.toUpperCase();
			$(el).css('text-transform', 'uppercase');
		} else if (FieldFormat.IsLowercase(formatName)) {
			value = value.toLowerCase();
			$(el).css('text-transform', 'lowercase');
		} else if (FieldFormat.IsStartswithcapital(formatName)) {
			value = value.charAt(0).toUpperCase() + value.substr(1);
		}

		else if (FieldFormat.IsShortDate(formatName)) {
			let time = value ? moment(value) : moment();
			value = time.format('YYYY-MM-DD');
		} else if (FieldFormat.IsLongDate(formatName)) {
			let time = value ? moment(value) : moment();
			value = time.format('dddd, YYYY-MM-DD HH:mm');
		} else if (FieldFormat.IsLongTime(formatName)) {
			let time = value ? moment(value) : moment();
			value = time.format('HH:mm:ss');
		} else if (FieldFormat.IsTime(formatName)) {
			let time = value ? moment(value) : moment();
			value = time.format('HH:mm');
		} else if (FieldFormat.IsDateTime(formatName)) {
			let time = value ? moment(value) : moment();
			value = time.format('YYYY-MM-DD HH:mm');
		}

		else if (FieldFormat.IsCurrency(formatName)) {
			if (el.dataset.withNumberGroupSeparators === 'true') {
				$(el).inputmask('patchedDecimal', decimalInputMask);
			} else {
				$(el).inputmask('Regex', {
					regex: '-?[0-9]{0,' + (29 - size) + '}[' + decimalSeparator + ']{1}[0-9]{0,' + size + '}'
				});
			}
		} else if (FieldFormat.IsPercentage(formatName)) {
			if (el.dataset.withNumberGroupSeparators === 'true') {
				$(el).inputmask('patchedDecimal', { ...decimalInputMask, digits: Math.max(size - 2, 0) });
			} else if (size > 2) {
				$(el).inputmask('Regex', {regex: '-?[0-9]{0,' + (29 - size + 2) + '}[' + decimalSeparator + ']{1}[0-9]{0,' + (size - 2) + '}'});
			} else {
				$(el).inputmask('Regex', {regex: '-?[0-9]{0,' + 29 + '}$'});
			}
		} else if (FieldFormat.IsDecimal(formatName)) {
			if (el.dataset.withNumberGroupSeparators === 'true') {
				$(el).inputmask('patchedDecimal', decimalInputMask);
			} else {
				$(el).inputmask('Regex', {
					regex: '-?[0-9]{0,' + (29 - size) + '}[' + decimalSeparator + ']{1}[0-9]{0,' + size + '}'
				});
			}
		} else if ((fieldModel && fieldModel.FieldTypeName === FIELD_TYPES.Integer) || FieldFormat.IsInteger(formatName)) {
			try {
                if (el.dataset.withNumberGroupSeparators === 'true') {
                    $(el).inputmask('integer', {
                        integerDigits: 10,
                        autoGroup: true,
                        groupSeparator,
                        autoUnmask: true,
                        rightAlign: false
                    });
                } else if (fieldNumExceptNegative) {
                    $(el).inputmask('Regex', {regex: '^[0-9]+$'});
                } else {
                    $(el).inputmask('Regex', {regex: '^-?[0-9]+$'});
                }
			} catch (error) {
				console.log(error);
			}
		}

		else if (FieldFormat.IsIPAddress(formatName)) {
			MaskFormater.IpAddress(el);
		} else if (FieldFormat.IsPhonenumber(formatName)) {
			$(el).inputmask('+9{1,10}(9{1,10}) 9-999 99 999', {numericInput: false});
		} else if (FieldFormat.IsMail(formatName)) {
			$(el).inputmask({
                mask: '*{1,64}@[*{1,254}.*{2,254}[.*{1,2}]]*{4,254}',
                greedy: false,
                onBeforePaste: (pastedValue, option) => {
                    pastedValue = pastedValue.toLowerCase();

                    return pastedValue.replace('mailto:', '');
                },
                definitions: {
                    '*': {
                        validator: "[0-9A-Za-z!#$\.%&'*+/=?^_`{|}~\-]",
                        cardinality: 1,
                        casing: "lower"
                    }
                }
            });
		} else if (FieldFormat.IsDutchPostalCode(formatName)) {
			$(el).inputmask({
				mask: '9{4} a{2}',
				greedy: false,
				definitions: {
					'9': {
						validator: "[0-9]",
						cardinality: 1,
						casing: "lower"
					},
					'a': {
						validator: "[A-Za-z]",
						cardinality: 1,
						casing: "upper"
					}
				}
			});
		}

	}

	private static IpAddress(el: HTMLElement) {
		$(el).inputmask({
			alias: 'ip',
			'placeholder': '_'
		});

		function validateIPAddress(inputID) {
			$(inputID).on('focusout', function () {
				const $this = $(this);
				const entered_ip = $this.val().toString();

				if (entered_ip) {
					const ip_fields_array = entered_ip.split('.');
					const formatted_ip = entered_ip.replace(/_/g, '');
					const ip_error = document.createElement('span');

					ip_error.setAttribute('id', 'ip_error');

					const error_message = document.createTextNode(formatted_ip + ' is an incorrect IPv4 address');

					ip_error.appendChild(error_message);

					const ip_correct = document.createElement('span');

					ip_correct.setAttribute('id', 'ip_success');

					const success_message = document.createTextNode(formatted_ip + ' is a correct IPv4 address');

					ip_correct.appendChild(success_message);

					const array_length = ip_fields_array.length;
					const regex = /^0([0-9])+/;

					for (let i = 0; i < array_length; i++) {
						const ip_field = ip_fields_array[i];
						const regex_result = regex.test(ip_field.replace(/_/g, ''));

						if (ip_field == '___' || regex_result) {
							$this
								.siblings('#ip_success')
								.remove();

							if (!$this.siblings('#ip_error').length) {
								$this
									.parent()
									.append(ip_error);

								$this
									.removeClass('has-success')
									.addClass('has-error');
							}

							return;
						} else if (!$this.siblings('#ip_success').length) {
							$this
								.parent()
								.append(ip_correct);

							$this
								.removeClass('has-error')
								.addClass('has-success');
						}
					}
				}
			});

			$(inputID).on('focus', function () {
				$(this)
					.siblings('#ip_success, #ip_error')
					.remove();
			});
		}

		validateIPAddress(el);
	}
}

export class InputMaskExtention {
	static Init() {
		ko.bindingHandlers.inputMask = {
			init: (element, valueAccessor) => {
				const options = valueAccessor();
				if (options.fieldModel) {
				    const fieldModel = options.fieldModel as AttachedFieldModel;
				    MaskFormater.Format(element, fieldModel, options.value());
				} else if (options.numExceptNegative) {
					MaskFormater.Format(element, null, options.value(), options.type, options.numExceptNegative);
                } else if(options.decimalSize){
					const value = options.value instanceof Function ? options.value() : options.value;
					MaskFormater.Format(element, null, value, options.type, null, options.decimalSize);
				} else if (options.type) {
					MaskFormater.Format(element, null, options.value(), options.type);
                }

			}
		};
	}
}