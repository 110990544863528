import * as ko from 'knockout';
import * as moment from 'moment';
import { BlockUI } from 'Core/Common/BlockUi';

import { Modal } from "Core/Common/Modal";
import { HelpBookStore } from "HelpBook/Store/HelpBookStore";
import { Notifier } from 'Core/Common/Notifier';

import { HelpEntity } from 'HelpBook/HelpEntity/HelpEntity';
import { HelpScreen } from 'HelpBook/HelpScreen/HelpScreen';
import { HelpForm } from 'HelpBook/HelpForm/HelpForm';
import { HelpControl } from 'HelpBook/HelpControl/HelpControl';
import { HelpType } from 'HelpBook/HelpType/HelpType';
import { LABELS } from "Core/Components/Translation/Locales";

import { CANVAS_TYPES } from "Core/Controls/CanvasDesigner/Constants/CanvasTypes";

import {
	EntityModel
} from 'HelpBook/Store/Models/HelpBookModel';

import HelpBookTemplate from 'HelpBook/Templates/HelpBook.html';
ko.templates['HelpBook/Templates/HelpBook'] = HelpBookTemplate;

import HelpIconTemplate from 'HelpBook/Templates/HelpIconTemplate.html';
import { BaseControl } from "Core/Controls/BaseControl/BaseControl";
import { LifecycleDesignerFlow } from "../Core/LifecycleDesigner/LifecycleDesignerFlow";
import * as _ from "underscore";
import { TranslationModel } from "../Core/Controls/BaseControl/Models/TranslationModel";
import { UserModel } from "../User/Store/Models/UserModel";
import { UserManager } from "../User/UserManager";
import {MobileChecker} from "../Core/Common/MobileChecker";
import {Events} from "../Auth/Enums/Events";
ko.templates['HelpBook/Templates/HelpIconTemplate'] = HelpIconTemplate;


export class HelpBook {
	private _modal: Modal;
	private _user: KnockoutObservable<UserModel>;
	private IsAuthenticated: KnockoutObservable<boolean>;
	private static _instance: HelpBook;
	private static _allowInstantiation: boolean;
	private entityList: Array<EntityModel>;

	private _labels = LABELS;
	private _canvasTypes = CANVAS_TYPES;

	private helpButtonPressed: boolean;
	private helpButtonPressedTime: Date;
	private dataIsRendered: KnockoutObservable<boolean>;
	private isClosed: KnockoutObservable<boolean>;

	public HelpEntity: KnockoutObservable<HelpEntity>;
	private ActiveHelpScreen: KnockoutObservable<HelpScreen>;
	private HelpScreens: KnockoutObservableArray<HelpScreen>;
	private HelpForm: KnockoutObservable<HelpForm>;
	private HelpControl: KnockoutObservable<HelpControl>;
	private HelpType: KnockoutObservable<HelpType>;
	private NoDataPlaceholder: string;
	private lifestatuses: KnockoutObservableArray<any>;
	private screenIsActive: KnockoutObservable<boolean>;
	private _timeSpan: any;

	private activeEntity: EntityModel;
	private activeEntityGroup: any;

	private history: KnockoutObservableArray<EntityModel>;

	private isActive: KnockoutObservable<boolean>;
	private canReturn: KnockoutComputed<boolean>;

	constructor() {
		if (!HelpBook._allowInstantiation) {
			throw new Error('Use HelpBook.Instance instead');
		}

		this._user = ko.observable(null);
		this.IsAuthenticated = ko.observable(false);

		this.helpButtonPressed = false;
		this.helpButtonPressedTime = null;
		this.screenIsActive = ko.observable(false);
		this.isClosed = ko.observable(MobileChecker.IsMobile());

		this.HelpEntity = ko.observable(null);
		this.ActiveHelpScreen = ko.observable(null);
		this.lifestatuses = ko.observableArray([]);
		this.HelpScreens = ko.observableArray([]);
		this.HelpForm = ko.observable(null);
		this.HelpControl = ko.observable(null);
		this.HelpType = ko.observable(null);
		this.dataIsRendered = ko.observable(true);
		this.NoDataPlaceholder = this._labels.THERE_IS_NO_DESCRIPTION_AVAILABLE;

		this.activeEntity = null;
		this.activeEntityGroup = null;

		this.history = ko.observableArray([]);

		this.canReturn = ko.computed(() => {
			return this.history().length > 0;
		})

		this.Init();
	}

	get Designers() {
		var designers = new EntityModel();
		(designers as any).entities = [
			{
				Name: this._labels.DOCUMENTATION,
				isActive: ko.observable(false),
				Link: 'http://confluence-cyberthing.gisua.com/display/CYB/Spheeres.com'
			}
		];
		designers.SubjectAreaName = this._labels.DESIGNERS;
		designers.isActive = ko.observable(false);
		designers.isOpen = ko.observable(false);
		return designers;
	}

	CleanHelpBook() {
		this.HelpEntity(null);
		this.ActiveHelpScreen(null);
		this.HelpScreens([]);
		this.HelpForm(null);
		this.HelpControl(null);
		this.HelpType(null);
	}

	Deactivate(entity) {
		if (entity != null) {
			entity.isActive(false);
		}
	}

	Show(entityId?) {
		this.ExitHelpCursorMode(true);

		this.CleanHelpBook();
		this.activeEntity = null;
		this.activeEntityGroup = null;

		HelpBookStore.GetEntities().always(() => {
			BlockUI.Unblock();
		}).then(result => {
			if (!result.IsSuccessfull) {
				new Notifier().Failed(result.ErrorMessage);
				this.dataIsRendered(false);
				return;
			}
			this.entityList = result.ResultObject.reduce((acc, entity: EntityModel) => {
				const entitiesGroup: any = _.where(acc, { subjectAreaId: entity.SubjectAreaId })[0];
				entity.isActive = ko.observable(entityId === entity.Id);

				if (!entitiesGroup) {
					const entityList = [
						...acc, {
							subjectAreaIconType: entity.SubjectAreaIconType,
							subjectAreaIconId: entity.SubjectAreaIconId,
							subjectAreaIconImage: entity.SubjectAreaIconImage,
							subjectAreaIcon: entity.SubjectAreaIcon,
							subjectAreaId: entity.SubjectAreaId,
							subjectAreaName: entity.SubjectAreaName,
							entities: [entity],
							isActive: ko.observable(entityId === entity.Id),
							isOpen: ko.observable(false)
						}
					];
					if (entity.isActive()) {
						this.activeEntity = entity;
					}
					return entityList;
				} else {
					entitiesGroup.entities.push(entity);
					if (entity.isActive()) {
						entitiesGroup.isActive(true);
						entitiesGroup.isOpen(true);
						this.activeEntityGroup = entitiesGroup;
						this.activeEntity = entity;
					}
					return acc;
				}
			},
				[]).filter((entity) => {
					return entity.subjectAreaName;
				});
			this.Render();
		});
	}

	ShowWithUserMenu(entityId?: number) {
		this.helpButtonPressed = false;
		this.GetScreenInfo({ Id: entityId, directScreen: true } as EntityModel);
		return true;
	}

	EntityGroupClick(entityGroup) {
		entityGroup.isOpen(!entityGroup.isOpen());
	}

	Init() {
		var f1KeyCode = 112;
		var dblf1Key = 0;
		window.addEventListener('keydown',
			async (evt) => {
		        if (evt.keyCode === f1KeyCode) {
                    dblf1Key++
                    if (dblf1Key >= 2) {
                        evt.preventDefault();
                        const isSelectedScreen = (await import('MenuManager/MenuManager')).MenuManager.Instance.GetCurrentScreen();
                        if (isSelectedScreen){
                            this.ShowWithUserMenu(isSelectedScreen.GetId());
                        } else {
                            this.Show();
                        }
                    } else {
                        evt.preventDefault();

                        if (!this.dataIsRendered()) return;

                        if (this.helpButtonPressed) {
                            this.ExitHelpCursorMode(true);
                            this.dataIsRendered(true);
                        } else {
                            this.helpButtonPressed = true;
                            this.helpButtonPressedTime = new Date();
                            this.EnterHelpCursorMode();
                        }
                    }
                    setTimeout(() => dblf1Key = 0, 500);
                }
		    }
		);
	}

	GetTemplateName(): string {
		return 'HelpBook/Templates/HelpBook';
	}

	GetIconTemplateName(): string {
		return 'HelpBook/Templates/HelpIconTemplate';
	}

	GetScreenTemplateName(): string {
		return 'HelpBook/HelpScreen/Templates/HelpScreen';
	}

	GetFormTemplateName(): string {
		return 'HelpBook/HelpForm/Templates/HelpForm';
	}

	GetDescriptionTemplateName() {
		return 'HelpBook/Templates/HelpDescriptionTemplate';
	}

	GetStatusFlowTemplateName(): string {
		return 'HelpBook/HelpStatusFlow/Templates/HelpStatusFlow';
	}

	Render() {
		this._user((UserManager.Instance.CurrentUser));
		this.IsAuthenticated(UserManager.Instance.IsAuthenticated);
		let resizePopupOptions = {
			widthInPercent: 95,
			heightInPercent: 90
		};
		this._modal = new Modal({
			addClass: 'help-book-modal',
			closeOnEsc: true,
			onClose: () => {
				this.history([]);
				this.dataIsRendered(true);
				this.activeEntity = null;
				this.activeEntityGroup = null;
            },
			onCloseComplete: () => {
				this.Destroy();
			}
		}, false, false, resizePopupOptions);

		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);

		this.ShowModal();

	}

	Destroy() {
		if(this._modal){
			this._modal.Destroy();
		}
	}


	Back() {
		this.Deactivate(this.activeEntity);
		this.activeEntity = null;
		this.GetEntityInfo(this.history.pop());
	}

	AppendHistory() {
		if (this.history()[this.history().length - 1] != this.activeEntity) {
			this.history.push(this.activeEntity);
		}
	}

	GetEntityInfo(entity: EntityModel) {
		BlockUI.Block();
		this.CleanHelpBook();


		if (this.activeEntity != null && this.activeEntity != entity) {
			this.AppendHistory();
		}

		
		this.Deactivate(this.activeEntity);
		this.activeEntity = entity;

		if (this.activeEntityGroup != null && this.activeEntityGroup.subjectAreaId != entity.SubjectAreaId) {
			this.Deactivate(this.activeEntityGroup);
			this.activeEntityGroup = null;
			const entityGroup = this.entityList.find((e: any) => e.subjectAreaId == entity.SubjectAreaId);
			this.activeEntityGroup = entityGroup;
			this.activeEntityGroup.isActive(true);
		}

		const helpEntity = new HelpEntity();
		helpEntity.FetchData(entity).then(() => {
			if (entity.directScreen) {
				this.Show(entity.Id);
			}
		}).then(() => {
			this.HelpEntity(helpEntity);
			this.dataIsRendered(true);
			entity.isActive(true);
		}).then(() => {
			this.GetEntityTypes();
		});
	}

	GetEntityTypes() {
		this.HelpEntity().selectedEntityTypesList(this.HelpEntity().selectedEntityTypes().slice(1));
		this.GetScreens(this.HelpEntity().selectedEntityTypes()[0], true);

		if (this.HelpEntity().selectedEntityTypes()[0].Id === 0) {
			this.HelpEntity().selectedEntityTypes()[0].isActive(true);
			return;
		}

		this.HelpEntity().selectedEntityTypes()[0].HelpDescription.ActivateDescription();
	}

	GetScreens(type, skipBlock: boolean = false) {
		if (type.isActive && type.isActive()) {
			type.isActive(!type.isActive());
			return
		}
		type.isActive && this.HelpEntity().selectedEntityTypes().map((type: any) => {
			type.isActive(false);
		});
		type.isActive && type.isActive(!type.isActive());

		if (type.Id !== 0 && type.StepsCount > 0) {
			this.BuildLifecicleDesignerFlow(type.Id);
		}

		const screenIsLoaded = this.HelpScreens().find((el) => {
			if (el.id == type.Id) return true;
		});

		if (!screenIsLoaded) {
			const screen = new HelpScreen(type, skipBlock);
			screen.id = type.Id;
			this.HelpScreens().push(screen);
		}

		this.ActiveHelpScreen(this.HelpScreens().find(el => el.id == type.Id));
	}

	ToggleMenu() {
		this.isClosed(!this.isClosed());
	}

	GetScreenInfo(screen, skipBlock: boolean = false) {
		const selectedScreenId = screen.Id;
		if (screen.isActive && screen.isActive()) {
			screen.isActive(!screen.isActive());
			screen.isActive && this.screenIsActive(screen.isActive());
			this.dataIsRendered(true);
			return;
		}

		screen.isActive && this.ActiveHelpScreen() && this.ActiveHelpScreen().selectedEntityScreens().map((screen: any) => {
			screen.isActive(false);
			if (selectedScreenId !== screen.Id) screen.dataFetched = false;
		});
		screen.isActive && screen.isActive(!screen.isActive());
		screen.isActive && this.screenIsActive(screen.isActive());
		if (screen.dataFetched) {
			return;
		}
		const form = new HelpForm();
		form.FetchData(screen, skipBlock).then(() => {
			this.screenIsActive(true);
			if (screen.directScreen) {
				this.Show(form.selectedEntityId);
			}
		}).then(() => {
			this.HelpForm(form);
			this.dataIsRendered(true);
		});
	};

	GetControlInfo(control) {
		this.HelpControl(new HelpControl(control));
	}

	GetTypeInfo(type) {
		this.HelpType(new HelpType(type));
	}

	AfterRender() {

	}

	ShowModal() {
		if (this._modal) {
			this._modal.Show();
		}
	}

	static get Instance(): HelpBook {

		if (HelpBook._instance == null) {
			HelpBook._allowInstantiation = true;
			HelpBook._instance = new HelpBook();
			HelpBook._allowInstantiation = false;
		}

		return HelpBook._instance;
	}

	ShowControlHelp(control: BaseControl) {
		if (this.IsHelpButtonPressed()) {
			this.helpButtonPressed = false;
			this.Show(control.GetForm().GetScreen().GetEntityId());
			this.GetControlInfo(({ Id: control.GetControlId() }));
			return false;
		}

		return true;
	}


	ShowScreenHelp(screenId: number) {
		if (this.IsHelpButtonPressed()) {
			this.helpButtonPressed = false;
			this.GetScreenInfo({ Id: screenId, directScreen: true } as any);
		}
		return true;
	}

	ShowEntityHelp(entityId: number) {
		if (this.IsHelpButtonPressed()) {
			this.helpButtonPressed = false;
			this.GetEntityInfo({ Id: entityId, directScreen: true, isActive: ko.observable(false), isOpen: ko.observable(false) } as EntityModel);
			return false;
		}
		return true;
	}

	ShowTypeHelp(typeModel) {
		if (this.IsHelpButtonPressed()) {
			this.helpButtonPressed = false;
			this.Show(typeModel._entityId);
			this.GetTypeInfo({ Id: typeModel._typeId, EntityId: typeModel._entityId});
			return false;
		}
		return true;
	}

	IsHelpButtonPressed(): boolean {
		if (this.helpButtonPressed) {
			var timeSpan = (new Date().getTime() - this.helpButtonPressedTime.getTime()) / 6000;
			return timeSpan < 1;
		}
		this.ExitHelpCursorMode();
		return false;
	}

	EnterHelpCursorMode() {
		$('*').css('cursor', 'help');
		this._timeSpan = setTimeout(() => { this.ExitHelpCursorMode() }, 6000);

	}

	ExitHelpCursorMode(forceExit?: boolean) {
		if (this.helpButtonPressed || forceExit) {
			this.helpButtonPressed = false;
			clearTimeout(this._timeSpan);
			$('*').css('cursor', '');
		}
	}

	ClickStatusFlow(data) {
		data.isStatusFlowActive(!data.isStatusFlowActive());
	}

	BuildLifecicleDesignerFlow(typeId) {
		const flow = new LifecycleDesignerFlow({
			Container: '.lifeStatusDiagramContainer',
			TableTypes: []
		});
		flow.LoadDataType(typeId).then(data => {
			flow.InitJsPoint(data.LifecycleObject);
			this.lifestatuses(data.LifecycleObject.Steps.slice(1).map(status => {
				const selectedTranslation = _.find(status.Translations, (translation: TranslationModel) => translation.Selected === true);
				const name = (selectedTranslation && selectedTranslation.Selected && selectedTranslation.Translation !== null) ? selectedTranslation.Translation : status.Label;
				const isTriggersSet = status.Triggers.length && status.Triggers[0].DwPackages.length;
				let deadlineElapsed = '';
				if (status.DeadlineElapsed) {
					let day = moment(status.DeadlineElapsed).subtract(1, 'd').day(),
						hours = moment(status.DeadlineElapsed).hours(),
						minutes = moment(status.DeadlineElapsed).minutes();
					if (day === 0) {
						deadlineElapsed = `${hours}h ${minutes}m`;
					} else {
						deadlineElapsed = `${day}d ${hours}h ${minutes}m`;
					}
				}

				let dateElapsed = '';
				if (status.DateElapsed) {
					let day = moment(status.DateElapsed).subtract(1, 'd').day(),
						hours = moment(status.DateElapsed).hours(),
						minutes = moment(status.DateElapsed).minutes();
					if (day === 0) {
						dateElapsed = `${hours}h ${minutes}m`;
					} else {
						dateElapsed = `${day}d ${hours}h ${minutes}m`;
					}
				}
				let dwPackages = [];
				let dwPackagesNames = [];
				if (isTriggersSet) {
					dwPackages = status.Triggers[0].DwPackages;
					dwPackagesNames = status.Triggers[0].DwPackages.map(dwPackage => dwPackage.Name).join(', ');
				}

				let businessRoles = data.BusinessRoles.find(el => el.LifestatusId === status.Id) && data.BusinessRoles.find(el => el.LifestatusId === status.Id).Roles;
				const isSettigsDisplays = isTriggersSet || status.DeadlineElapsed || status.DateElapsed || status.NoActionNode;
				const isAnythingAttached = isSettigsDisplays || businessRoles;
				return _.extend({}, status, {
					Name: ko.observable(name),
					isActive: ko.observable(false),
					Click: (status) => {
						if (isAnythingAttached) {
							status.isActive(!status.isActive());
						} else {
							status.isActive(false);
						}
					},
					isTriggersSet: isTriggersSet,
					dwPackages: dwPackages,
					dwPackagesNames: dwPackagesNames,
					deadlineElapsed: deadlineElapsed,
					dateElapsed: dateElapsed,
					isSettigsDisplays: ko.observable(isSettigsDisplays),
					isAnythingAttached: ko.observable(isAnythingAttached),
					businessRoles: businessRoles
				});
			}));
		});
	}
}