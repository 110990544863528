export class Convert {
	static ToBase64Image(binaryData: string): string {
		if (binaryData != null) {
			const base64 = this.ToBase64(binaryData);
			return `data:image/jpeg;base64,${base64}`;
		}

		return ``;
	}

	static ToBase64(binaryData: string): string {
		const data = binaryData.replace("0x", "");

		return btoa(
			String.fromCharCode.apply(null,
				data
					.replace(/[\r\n]/g, "")
					.replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
					.replace(/ +$/, "")
					.split(" "))
		);
	}
}