import { deserialize } from 'libs/cerialize';

export class DestinationMailModel {
    @deserialize
    Name: string;

    @deserialize
    RecordId: number;

    @deserialize
    TableId: number;

    @deserialize
    Mail: string;
}