import * as ko from "knockout";
import * as $ from 'jquery';

import {SubGroupEditor} from "./SubGroupEditor";
import {ColumnEditor} from "./ColumnEditor";

export class GroupEditor {
    _$collapse: JQuery;
    _subGroupEditors: SubGroupEditor[];
    _columnsEditors: ColumnEditor[];
    _name: string;
    _isCollapsed: KnockoutObservable<boolean>;
    _sortOrder: KnockoutObservable<number>;
    _isSelected: KnockoutObservable<boolean>;
    private _oldSortOrder: number;
    _selectedCount: KnockoutObservable<number>;

    constructor(name: string, order: number, isSelected: boolean) {
        this._name = name;
        this._sortOrder = ko.observable(order);
        this._subGroupEditors = [];
        this._columnsEditors = [];
        this._isCollapsed = ko.observable(true);
        this._oldSortOrder = order;
        this._isSelected = ko.observable(isSelected);
        this._selectedCount = ko.observable(this.GetSelectedCount());
    }

    ToggleSelection() {
        _.forEach(this._subGroupEditors, (subGroup) => {
            subGroup._isSelected(this._isSelected());
        });
        this.UpdateSelectedCount();
        return true;
    }

    UpdateSelectedCount() {
        this._selectedCount(this.GetSelectedCount());
    }

    GetSelectedCount(): number {
        let selected = 0;
        _.forEach(this._subGroupEditors, (subGroup) => {
            if (subGroup._isSelected()) {
                selected++;
            }
        });
        return selected;
    }

    OnOpenGroup() {
        this._isCollapsed(false);
        this._$collapse.addClass('opened');
    }

    OnCloseGroup() {
        this._isCollapsed(true);
        this._$collapse.removeClass('opened');
    }

    OnCollapseReady($collapse) {
        this._$collapse = $collapse;
    }

    OpenGroup() {
        this._$collapse && this._$collapse.find('a').click();
    }
}