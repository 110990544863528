import * as ko from "knockout";
import {IControlParam, ISearchScreenParam} from "Core/Screens/IScreen";
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel';
import {RenderModes, CONTROL_TYPES} from "Core/Constant";

import {FunctionDesigner as FunctionDesignerControl} from "Core/Controls/FunctionDesigner/FunctionDesigner";
import FunctionDesignerTemplate from "Pages/Templates/FunctionDesigner.html";
ko.templates["Pages/Templates/FunctionDesigner"] = FunctionDesignerTemplate;

export class FunctionDesignerPage {
	private _FunctionDesignerControl: FunctionDesignerControl;
	private _isReady: KnockoutObservable<boolean>;

	constructor() {
		this._isReady = ko.observable(false);
		var controlModel = new ControlModel();
		controlModel.TypeName = CONTROL_TYPES.FunctionDesigner;
		var controlsParam: IControlParam = {
			Model: controlModel,
			Form: null,
			RenderMode: RenderModes.View
		}
		this._FunctionDesignerControl = new FunctionDesignerControl(controlsParam);
		this._FunctionDesignerControl.SetValue(1);
	}

	Render(target): void {
		ko.cleanNode(document.getElementById(target));
		ko.applyBindings(this, document.getElementById(target));
	}

	GetTemplateName(): string {
		return "Pages/Templates/FunctionDesigner";
	}

	get FunctionDesignerControl(): FunctionDesignerControl {
		return this._FunctionDesignerControl;
	}

	AfterRender() {
		this._isReady(true);
	}

	RemoveControl() {
	}
}