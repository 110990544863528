import {deserialize, deserializeAs} from 'libs/cerialize';
import {LifeStatusesModel} from "Core/Controls/ButtonFollowUp/Models/LifeStatusesModel";

export class FollowUpOptionModel {
	@deserialize
	EntityId: number;

	@deserialize
	EntityName: string;

	@deserialize
	RecordId: number;

	@deserialize
	TypeId: number;

	@deserializeAs(LifeStatusesModel)
	LifeStatuses: LifeStatusesModel;
}