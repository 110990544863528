import { deserialize } from 'libs/cerialize';
import { deserializeAs } from 'libs/cerialize';

export class TriggerParamModel {

	@deserialize
	Id: number;

	@deserialize
	Name: string;
}