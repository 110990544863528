import * as ko from 'knockout';
import * as _ from 'underscore';

import {RenderModes} from 'Core/Constant';

import {IControlParam} from 'Core/Screens/IScreen';
import {BaseControl} from 'Core/Controls/BaseControl/BaseControl';

import {GeneralProperties} from '../../GeneralProperties/GeneralProperties';
import SpecialScreenButtonConfig
    from 'Core/Controls/ButtonDashboardScreen/Configs/dashboard-screen-button-config.json';
import {CHECKBOX_PROPERTIES} from "Core/GeneralProperties/Managers/CheckboxProperty/Constants";

import ViewTemplate from './Templates/View.html';
import DesignTemplate from './Templates/Design.html';
import HelpViewTemplate from './Templates/HelpView.html';
import ToolBarTemplate from './Templates/Toolbar.html';

ko.templates["Core/Controls/ButtonDashboardScreen/Templates/Design"] = DesignTemplate;
ko.templates["Core/Controls/ButtonDashboardScreen/Templates/Edit"] = ViewTemplate;
ko.templates["Core/Controls/ButtonDashboardScreen/Templates/ToolBar"] = ToolBarTemplate;
ko.templates["Core/Controls/ButtonDashboardScreen/Templates/HelpView"] = HelpViewTemplate;
ko.templates["Core/Controls/ButtonDashboardScreen/Templates/View"] = ViewTemplate;

export class ButtonDashboardScreen extends BaseControl {
    private _config: any;

    constructor(params: IControlParam) {
        super(params, SpecialScreenButtonConfig);

        this._enabled = ko.observable(this.IsDashboardScreenExist);
        this._style = ko.computed(() => {
            return {
                backgroundColor: this._backgroundColor(),
                color: this._color(),
                borderColor: this._borderColor(),
                borderWidth: this._border() ? '1px' : '0',
                padding: this._border() ? '6px 14px' : '7px 14px'
            };
        });

        this.Init();
    }

    OnClick() {
        if (this._help.IsHelpButtonPressed()) {
            this._help.ShowControlHelp(this);
        } else if(this._enabled()){
            const screen = this._form.GetScreen();
            screen.Trigger("SHOW_DASHBOARD_SCREEN");
        }
    }

    private Init() {
        this.ApplyProperties();
    }

    SetHideCaption(data: boolean) {
        this.HideCaption(this.HideCaptionFromProperty() ? this.HideCaptionFromProperty() : data);
    }

    GetHideCaption(): boolean {
        return this.HideCaption();
    }

    ApplyProperties() {
        if (this.Properties) {
            this.AssignProperty('Styling', 'BackgroundColor', this._backgroundColor);
            this.AssignProperty('Styling', 'TextColor', this._color);
            this.AssignProperty('Styling', 'Border', this._border);
            this.AssignProperty('Styling', 'BorderColor', this._borderColor);
            this.AssignProperty('Styling', CHECKBOX_PROPERTIES.HIDE_CAPTION, this.HideCaptionFromProperty);
            this.HideCaption(this.HideCaptionFromProperty());
        }
    }

    private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
        if (this.Properties[groupedBy]) {
            _.each(this.Properties[groupedBy].Properties,
                (property: any) => {
                    if (property.hasOwnProperty(propertyName)) {
                        propertyHolder(property[propertyName]);
                    }
                });
        }
    }
}