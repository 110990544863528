import * as ko from 'knockout';
import { ConfigurationLevel } from './ConfigurationLEvel';

export class ConfigurationRole extends ConfigurationLevel{
    Active: KnockoutObservable<boolean>;

    constructor(
        public Id: number,
        public Name: string,
        public TranslatedName: string,
        level: string) {
        super(level);

        this.Active = ko.observable(false);
    }

    get DisplayName() {
        return this.TranslatedName || this.Name;
    }
}