define(['FormatEditor/FormatConverter', 'jquery' ,'touchSpin'], function (formatConverterModule, $) {
	var FormatConverter = formatConverterModule.FormatConverter;
	(function ($) {
		'use strict';

		var TouchSpinExtension = function (options) {
			this.init('touchSpin', options, TouchSpinExtension.defaults);
		};

		$.fn.editableutils.inherit(TouchSpinExtension, $.fn.editabletypes.abstractinput);
		$.extend(TouchSpinExtension.prototype, {
			render: function () {
				this.$input = this.$tpl;
				this.$input.TouchSpin({
					min: -Infinity,
					max: Infinity,
					stepinterval: 50,
					maxboostedstep: 10000000,
					callback_before_calculation: function (value) {
						return value.replace(new RegExp('[' + FormatConverter.GetGroupSeparator() + ']', 'g'), '');
					}
				});
				this.$input.inputmask('integer', {
					integerDigits: 10,
					autoGroup: true,
					groupSeparator: FormatConverter.GetGroupSeparator(),
					autoUnmask: true,
					rightAlign: false
				});
			},
			value2html: function (value, element) {
				if (!value) {
					$(element).empty();
					return;
				}

				var textValue = '';

				if (value.current != undefined && value.current != null) {
					textValue = value.current;
				}

				if (value.default != undefined && value.default != null) {
					textValue = value.default;
				}

				$(element).html(FormatConverter.LocalizeDecimalOrInteger(textValue.toString()));
			},
			value2input: function (value) {
				if (!value) {
					return;
				}

				if (value.current != undefined && value.current != null) {
					this.$input.val(value.current);
					return;
				}

				if (value.default != undefined && value.default != null) {
					this.$input.val(value.default);
					return;
				}
			},
			input2value: function () {
				return {
					current: this.$input.val()
				}
			},
			activate: function () {
				this.$input.focus();
			}
		});

		TouchSpinExtension.defaults = $.extend({}, $.fn.editabletypes.abstractinput.defaults, {
			tpl: '<input type="text"/>'
		});

		$.fn.editabletypes.touchSpin = TouchSpinExtension;

	}($));
});