import {ProductPartDto} from "./ProductPartDto";
import {CustomFieldValueDto} from './CustomFieldValueDto';

export class AlteredProductDto {
    constructor(public Id: number,
                public EntityId: number,
                public NewParts: ProductPartDto[],
                public RemovedParts: ProductPartDto[],
                public AlteredParts: ProductPartDto[],
                public CustomFieldValues: CustomFieldValueDto[]) {
    }
}