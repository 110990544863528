import * as ko from 'knockout';
import * as $ from 'jquery';

export class EscKeyExtension {
    static Init() {
        const ESC_KEY_CODE = 27;

        ko.bindingHandlers.escKey = {
            init: (element, valueAccessor, allBindings, viewModel) => {
                const callback = valueAccessor();
                $(element).keyup(event => {
                    const keyCode = event.which || event.keyCode;
                    if (keyCode === ESC_KEY_CODE) {
                        callback.call(viewModel);
                        return false;
                    }

                    return true;
                });
            }
        };
    }
}