import {deserialize} from 'libs/cerialize';

export class LanguageInfoModel {

	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	ShortName: string;
}