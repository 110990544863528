import { deserialize, deserializeAs } from "libs/cerialize";

export class NodeTypeDto{
    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    TranslatedName: string;
}