import * as ko from 'knockout';
import * as $ from 'jquery';

import {GenericDeserialize} from 'libs/cerialize'

import {Modal} from 'Core/Common/Modal';
import {BlockUI} from 'Core/Common/BlockUi';
import {EVENTS, UISettings} from 'Core/Common/Themes/UISettings';
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';

import {Demo} from "metronicDemo";
import {Notifier} from 'Core/Common/Notifier';
import {LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";

import {UserManager, UserRoles} from 'User/UserManager';
import {GlobalManager, GLOBALS} from 'Core/GlobalManager/GlobalManager';

import {HexColorModel} from "Core/Components/ColorSelector/ColorModels/Hex/HexColorModel";
import {ColorModel} from "Core/Components/ColorSelector/ColorModels/ColorModel";

import {ThemeBuilderStore} from './Stores/ThemeBuilderStore';

import ThemeBuilderPage from 'Core/ProfilePage/ThemeBuilder/Templates/ThemeBuilder.html';
import {CustomThemeDescriptor} from "../../Common/Themes/CustomThemeDescriptor";
import {CustomThemeSetting} from "../../Common/Themes/CustomThemeSetting";

ko.templates['Core/ProfilePage/ThemeBuilder/Templates/ThemeBuilder'] = ThemeBuilderPage;

export interface IColorSelectorInfo {
    Id: string;
    Selector: any;
    Rule: any;
    Value: string;
    Transparent: string;
    ColorSelector: KnockoutObservable<ColorModel>;
}

const COLOR_SELECTOR_ID = {
    RootBackground: 'root_background',
    BodyHeaderBackground: 'body_header_background',
}

export class ThemeBuilder {
    private _uiSettings: UISettings;
    private _modal: any;
    private value: KnockoutObservable<string>;
    private _$themes: any;
    private _isInitialized: boolean;
    private _isActive: KnockoutObservable<boolean>;

    private _$themesContainer: any;
    private _$saveProfileBtn: any;
    private _isColorSelectorsInit: boolean;
    private _themeContent: any;
    private _colorSelectors: any;
    private _defaultThemeName: string;
    private _defaultThemeColor: string;
    private _defaultThemes: any;
    private _previewTemplate: any;
    private _colorThemes: any;
    private _layoutModeDropDown: any;
    private _sidebarBorders: any;
    private _sidebarPos: any;
    private _sidebarStyle: any;
    private _sidebarOption: any;
    private _selectedCustomThemeSettings: any;
    private _haveChanges: KnockoutObservable<boolean>;
    private _labels = LABELS;

    private _colorPickersObject: { [Id: string]: IColorSelectorInfo };
    private _userIsAllowedToChange: boolean;
    private _toggleThemeSwitcher: KnockoutObservable<boolean>

    _el: HTMLElement;

    constructor() {
        this._$themes = null;
        this._modal = new Modal();
        this._$themesContainer = null;
        this._$saveProfileBtn = null;
        this._themeContent = null;
        this._isColorSelectorsInit = false;
        this._colorPickersObject = {};
        this._isInitialized = false;
        this._colorSelectors = null;
        this._previewTemplate = null;
        this._colorThemes = null;
        this._layoutModeDropDown = null;
        this._sidebarBorders = null;
        this._defaultThemes = [];
        this._defaultThemeName = '';
        this._defaultThemeColor = '';
        this._selectedCustomThemeSettings = [];
        this._el = null;
        this._isActive = ko.observable(false);
        this._haveChanges = ko.observable(false);
        this._userIsAllowedToChange = !GlobalManager.Instance.GetGlobalDescriptor(GLOBALS.THEME).LockToDefault || UserManager.Instance.IsUserInRole(UserRoles.SuperUser);

        this._uiSettings = Demo.GetUISettings();

        this._toggleThemeSwitcher = ko.observable(null);

        this.Init();

    }

    Init() {

        this.value = ko.observable('');
        this.InitColorSelectors();

        if (this._userIsAllowedToChange) {
            this._isActive.subscribe(isActive => {
                this._toggleThemeSwitcher(isActive);
                const changeSelectorsEnabling = (selector: IColorSelectorInfo) => selector.ColorSelector().Enable(isActive);

                Object.keys(this._colorPickersObject).forEach(colorSelector => {
                    changeSelectorsEnabling(this._colorPickersObject[colorSelector]);
                });
            });
        }
    }

    CacheElements() {
        this._colorThemes = $(this._themeContent).find('#color-themes');
        this._layoutModeDropDown = $(this._themeContent).find('.layout-style-option');
        this._sidebarBorders = $(this._themeContent).find('.borders-option');
        this._sidebarPos = $(this._themeContent).find('.sidebar-pos-option');
        this._sidebarStyle = $(this._themeContent).find('.sidebar-style-option');
        this._sidebarOption = $(this._themeContent).find('.sidebar-option');

        this._colorSelectors = $(this._themeContent).find('.minicolors-input');
        this._$themes = $(this._themeContent).find('#custom-color-themes li');
        this._$themesContainer = $(this._themeContent).find('#custom-color-themes');
        this._$saveProfileBtn = $(this._themeContent).find("#profileSaveBtn");
        this._previewTemplate = $(this._themeContent).find(".theme-builder");
    }

    BindEvents() {
        this._uiSettings.Off(EVENTS.SETTINGS_CHANGED);
        this._uiSettings.On(EVENTS.SETTINGS_CHANGED, this, () => {
            this._haveChanges(true);
        });
    }

    SetThemeSquares() {
        this._defaultThemes = [
            {Custom1: '#ffffff'},
            {Custom2: '#ffffff'},
            {Custom3: '#ffffff'},
            {Custom4: '#ffffff'},
            {Custom5: '#ffffff'}
        ];
    }

    ApplyCurrentTheme() {
        this.SetThemeSquares();

        const customThemeName = this._uiSettings.Settings.CustomThemeName;
        if (customThemeName) {
            let settingsList: Array<CustomThemeSetting> = [];
            const customThemeSettings: CustomThemeDescriptor = this._uiSettings.GetCustomTheme(customThemeName);

            if (customThemeSettings) {
                settingsList = customThemeSettings.Settings;
                this._selectedCustomThemeSettings = settingsList;
                _.each(settingsList,
                    (setting: CustomThemeSetting) => {
                        let colorValue = setting.Val;
                        if (setting.Transparent) {
                            colorValue = this.RgbToHex(setting.Val);
                        }

                        if (colorValue) {
                            this._colorPickersObject[setting.Id].Value = colorValue;
                            this._colorPickersObject[setting.Id].ColorSelector().SetColor(colorValue);
                        }
                    }
                );
            } else {
                _.each(this._colorPickersObject, picker => {
                    picker.ColorSelector().SetColor('');
                });
            }

            if (customThemeName != 'Custom0') {
                this._isActive(true);
            }
        }
    }

    RgbToHex(rgb) {
        if (!rgb) {
            return null;
        }
        rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return (rgb && rgb.length === 4) ? "#" +
            ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
    }

    Discard() {
        Demo.SetUISettings(this.GetUISettings());
        this._uiSettings = Demo.GetUISettings();

        this.InitDropdowns();
        this.BindEvents();

        this._haveChanges(false);
    }

    InitColorSelectors() {
        let self = this;

        let pickerApplying = function (data, eventargs, id, isHex?) {
            let colorValue = '';
            let hexValue = '';
            let settingsList = [];

            if (!self._haveChanges()) {

                settingsList = self._selectedCustomThemeSettings;
                _.each(settingsList,
                    setting => {
                    let test = setting.Selector;
                        if (setting && setting.Selector && setting.Rule) {
                            if (typeof (setting.Selector) === 'string') {
                                $(setting.Selector).css(setting.Rule, setting.Val);
                            } else if (setting.Selector instanceof Array) {
                                const zipped = _.zip(setting.Selector, setting.Rule, setting.Val);
                                _.each(zipped, pair => {
                                    if (pair[0] && pair[1]) {
                                        $(pair[0]).css(pair[1], pair[2]);
                                    }
                                });
                            }
                        }
                    }
                );
            }

            colorValue = eventargs.data.Color || '';
            data.Value = eventargs.data.Color || '';

            var zippedStyles = _.zip(data.Selector, data.Rule);

            _.each(zippedStyles, (pair: any) => {
                if (pair[0] && pair[1]) {
                    $(pair[0]).css(pair[1], (hexValue ? hexValue : colorValue) || '');
                }
            });


            let name = '';
            _.each(self._$themes, theme => {
                if ($(theme).hasClass('active')) {
                    name = $(theme).attr('title')
                }
            })
            var arr = self.BuildCustomThemeArray();

            self._uiSettings.SetCustomTheme(name, arr)
            self._haveChanges(true);
        }


        let bodyHeader = {
            Id: COLOR_SELECTOR_ID.BodyHeaderBackground,
            Rule: ['background-color'],
            Value: '',
            Transparent: '',
            Selector: ['.page-header.navbar'],
            ColorSelector: ko.observable(new HexColorModel({
                Label: LABELS.HEADER_BACKGROUND_COLOR,
                Disabled: !this._isActive()
            }))
        };
        this._colorPickersObject[bodyHeader.Id] = bodyHeader;
        bodyHeader.ColorSelector().On("COLOR_SET", this, eventArgs => {
            pickerApplying(bodyHeader, eventArgs, bodyHeader.Id);
        });
        bodyHeader.ColorSelector().On("COLOR_RESET", this, eventArgs => {
            pickerApplying(bodyHeader, eventArgs, bodyHeader.Id);
        });

        let themeSquare = {
            Id: COLOR_SELECTOR_ID.RootBackground,
            Rule: ['--root-background-color'],
            Value: '',
            Transparent: '',
            Selector: ['body'],
            ColorSelector: ko.observable(new HexColorModel({
                Label: 'Background Color',
                Disabled: !this._isActive()
            }))
        };
        // let themeSquare = {
        //     Id: 'theme_sqare',
        //     Rule: ['background-color', '--root-background-color'],
        //     Value: '',
        //     Transparent: '',
        //     Selector: ['#custom-color-themes .active','body'],
        //     ColorSelector: ko.observable(new HexColorModel({
        //         Label: LABELS.BACKGROUND_COLOR_FOR_THEME_SQUARE,
        //         Disabled: !this._isActive()
        //     }))
        // };
        this._colorPickersObject[themeSquare.Id] = themeSquare;
        themeSquare.ColorSelector().On("COLOR_SET", this, eventArgs => {

            let defaultThemeName = $('#custom-color-themes li.active').attr('title');
            let defaultThemeColor = eventArgs.data.ColorValue;
            this._defaultThemes[defaultThemeName] = eventArgs.data.ColorValue;

            pickerApplying(themeSquare, eventArgs, themeSquare.Id);
        });
        themeSquare.ColorSelector().On("COLOR_RESET", this, eventArgs => {

            let defaultThemeName = $('#custom-color-themes li.active').attr('title');
            let defaultThemeColor = eventArgs.data.ColorValue;
            this._defaultThemes[defaultThemeName] = eventArgs.data.ColorValue;

            pickerApplying(themeSquare, eventArgs, themeSquare.Id);
        });


        // let sidebarBackground: IColorSelectorInfo = {
        //     Id: 'sidebar_background',
        //     Rule: ['background-color'],
        //     Value: '',
        //     Transparent: '',
        //     Selector: ['body, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover, .page-sidebar, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.active > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a'],
        //     ColorSelector: ko.observable(new HexColorModel({
        //         Label: LABELS.BACKGROUND_COLOR_FOR_SIDEBAR,
        //         InitialColor: null,
        //         Disabled: !this._isActive()
        //     }))
        // };
        // // this._colorPickersObject[sidebarBackground.Id] = sidebarBackground;
        // this._colorPickersObject[sidebarBackground.Id] = sidebarBackground;
        // sidebarBackground.ColorSelector().On("COLOR_SET", this, eventArgs => {
        //     pickerApplying(sidebarBackground, eventArgs, sidebarBackground.Id);
        // });
        // sidebarBackground.ColorSelector().On("COLOR_RESET", this, eventArgs => {
        //     pickerApplying(sidebarBackground, eventArgs, sidebarBackground.Id);
        // });

        // let sidebarLinkBackgroundHovered = {
        //     Id: 'sidebar_link_hovered',
        //     Rule: ['background-color', 'border-left-color'],
        //     Value: '',
        //     Transparent: '',
        //     Selector: ['.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li:hover > a, .page-sidebar .page-sidebar-menu:not(.page-sidebar-menu-light) > li:hover > a, .page-sidebar .page-sidebar-menu:not(.page-sidebar-menu-light) > li.active > a:hover, .page-sidebar .page-sidebar-menu:not(.page-sidebar-menu-light) > li.active.open > a:hover', '.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a:hover'],
        //     ColorSelector: ko.observable(new HexColorModel({
        //         Label: LABELS.BACKGROUND_COLOR_FOR_HOVERED_MENU_ITEM,
        //         Disabled: !this._isActive()
        //     }))
        // };
        // this._colorPickersObject[sidebarLinkBackgroundHovered.Id] = sidebarLinkBackgroundHovered;
        // sidebarLinkBackgroundHovered.ColorSelector().On("COLOR_SET", this, eventArgs => {
        //     pickerApplying(sidebarLinkBackgroundHovered, eventArgs, sidebarLinkBackgroundHovered.Id);
        // });
        // sidebarLinkBackgroundHovered.ColorSelector().On("COLOR_RESET", this, eventArgs => {
        //     pickerApplying(sidebarLinkBackgroundHovered, eventArgs, sidebarLinkBackgroundHovered.Id);
        // });

        // let sidebarSecondLinkBackgroundHovered = {
        //     Id: 'sidebar_secondlink_hovered',
        //     Rule: ['background-color', 'color'],
        //     Value: '',
        //     Transparent: '',
        //     Selector: ['.page-sidebar .page-sidebar-menu:not(.page-sidebar-menu-light) > li .sub-menu > li:hover > a', '.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li:hover > a'],
        //     ColorSelector: ko.observable(new HexColorModel({
        //         Label: LABELS.BACKGROUND_COLOR_FOR_HOVERED_MENU_ITEM_SECOND_LEVEL_ITEM,
        //         Disabled: !this._isActive()
        //     }))
        // };
        // this._colorPickersObject[sidebarSecondLinkBackgroundHovered.Id] = sidebarSecondLinkBackgroundHovered;
        // sidebarSecondLinkBackgroundHovered.ColorSelector().On("COLOR_SET", this, eventArgs => {
        //     pickerApplying(sidebarSecondLinkBackgroundHovered, eventArgs, sidebarSecondLinkBackgroundHovered.Id);
        // });
        // sidebarSecondLinkBackgroundHovered.ColorSelector().On("COLOR_RESET", this, eventArgs => {
        //     pickerApplying(sidebarSecondLinkBackgroundHovered, eventArgs, sidebarSecondLinkBackgroundHovered.Id);
        // });

        // let sidebarTextcolor = {
        //     Id: 'sidebar_textcolor',
        //     Rule: ['color'],
        //     Value: '',
        //     Transparent: '',
        //     Selector: ['.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a, .page-sidebar .page-sidebar-menu > li > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i[class^="icon-"], .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i[class*="icon-"], .page-sidebar .page-sidebar-menu > li > a > i[class^="icon-"], .page-sidebar .page-sidebar-menu > li > a > i[class*="icon-"], .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i, .page-sidebar .page-sidebar-menu > li > a > i'],
        //     ColorSelector: ko.observable(new HexColorModel({
        //         Label: LABELS.TEXT_COLOR_FOR_SIDEBAR_MENU,
        //         Disabled: !this._isActive()
        //     }))
        // };
        // this._colorPickersObject[sidebarTextcolor.Id] = sidebarTextcolor;
        // sidebarTextcolor.ColorSelector().On("COLOR_SET", this, eventArgs => {
        //     pickerApplying(sidebarTextcolor, eventArgs, sidebarTextcolor.Id);
        // });
        // sidebarTextcolor.ColorSelector().On("COLOR_RESET", this, eventArgs => {
        //     pickerApplying(sidebarTextcolor, eventArgs, sidebarTextcolor.Id);
        // });

        // let sidebarBorders = {
        //     Id: 'sidebar_borders',
        //     Rule: ['border-top-color'],
        //     Value: '',
        //     Transparent: '',
        //     Selector: ['.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a, .page-sidebar .page-sidebar-menu > li > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open + li > a, .page-sidebar .page-sidebar-menu > li.active.open + li > a'],
        //     ColorSelector: ko.observable(new HexColorModel({
        //         Label: LABELS.COLOR_FOR_BORDERS_BETWEEN_MENU_LINKS,
        //         Disabled: !this._isActive()
        //     }))
        // };
        // this._colorPickersObject[sidebarBorders.Id] = sidebarBorders;
        // sidebarBorders.ColorSelector().On("COLOR_SET", this, eventArgs => {
        //     pickerApplying(sidebarBorders, eventArgs, sidebarBorders.Id);
        // });
        // sidebarBorders.ColorSelector().On("COLOR_RESET", this, eventArgs => {
        //     pickerApplying(sidebarBorders, eventArgs, sidebarBorders.Id);
        // });

        // let sidebarActiveLink = {
        //     Id: 'sidebar_activelink',
        //     Rule: ['background-color', 'border-left-color'],
        //     Value: '',
        //     Transparent: '',
        //     Selector: ['.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu:not(.page-sidebar-menu-light) > li.active > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu:not(.page-sidebar-menu-light) > li.active.open > a, .page-sidebar .page-sidebar-menu:not(.page-sidebar-menu-light) > li.active > a, .page-sidebar .page-sidebar-menu:not(.page-sidebar-menu-light) > li.active.open > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu:not(.page-sidebar-menu-light) > li:hover > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu:not(.page-sidebar-menu-light) > li.open > a, .page-sidebar .page-sidebar-menu:not(.page-sidebar-menu-light) > li:hover > a, .page-sidebar .page-sidebar-menu:not(.page-sidebar-menu-light) > li.open > a', '.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a, page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a:hover, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a:hover'],
        //     ColorSelector: ko.observable(new HexColorModel({
        //         Label: LABELS.ACTIVE_LINK_COLOR,
        //         Disabled: !this._isActive()
        //     }))
        // };
        // this._colorPickersObject[sidebarActiveLink.Id] = sidebarActiveLink;
        // sidebarActiveLink.ColorSelector().On("COLOR_SET", this, eventArgs => {
        //     pickerApplying(sidebarActiveLink, eventArgs, sidebarActiveLink.Id);
        // });
        // sidebarActiveLink.ColorSelector().On("COLOR_RESET", this, eventArgs => {
        //     pickerApplying(sidebarActiveLink, eventArgs, sidebarActiveLink.Id);
        // });

        // let sidebarSecondLinkBg = {
        //     Id: 'sidebar_secondlink',
        //     Rule: ['background-color', 'border-left-color'],
        //     Value: '',
        //     Transparent: '',
        //     Selector: ['.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a, .page-sidebar .page-sidebar-menu .sub-menu > li:hover > a, .page-sidebar .page-sidebar-menu .sub-menu > li.open > a, .page-sidebar .page-sidebar-menu .sub-menu > li.active > a', '.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.active > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light .sub-menu > li > a'],
        //     ColorSelector: ko.observable(new HexColorModel({
        //         Label: LABELS.MENU_2_LEVEL_ACTIVE_LINK_COLOR,
        //         Disabled: !this._isActive()
        //     }))
        // };
        // this._colorPickersObject[sidebarSecondLinkBg.Id] = sidebarSecondLinkBg;
        // sidebarSecondLinkBg.ColorSelector().On("COLOR_SET", this, eventArgs => {
        //     pickerApplying(sidebarSecondLinkBg, eventArgs, sidebarSecondLinkBg.Id);
        // });
        // sidebarSecondLinkBg.ColorSelector().On("COLOR_RESET", this, eventArgs => {
        //     pickerApplying(sidebarSecondLinkBg, eventArgs, sidebarSecondLinkBg.Id);
        // });

        // let controlsBg = {
        //     Id: 'controls_bg',
        //     Rule: ['background-color'],
        //     Value: '',
        //     Transparent: '0.2',
        //     Selector: [`.consult-screen .mainSubForm .form-control,
		// 				.consult-screen .group-elements input,
		// 				.form-standart-control input,
		// 				.form-standart-control select,
		// 				.form-standart-control .textarea-control,
		// 				.form-standart-control textarea,
		// 				.form-standart-control .dropdown-wraper .form-control .items,
		// 				.form-standart-control .dropdown-wraper .form-control .items .item::before,
		// 				.FormDesignerView.form-standart-control .image-background,
		// 				.form-designer .form-standard-control .form-control[readonly]`],
        //     ColorSelector: ko.observable(new HexColorModel({
        //         Label: LABELS.CONTROLS_BACKGROUND_COLOR,
        //         Disabled: !this._isActive()
        //     }))
        // };
        // this._colorPickersObject[controlsBg.Id] = controlsBg;
        // controlsBg.ColorSelector().On("COLOR_SET", this, eventArgs => {
        //     var isHex = true;
        //     pickerApplying(controlsBg, eventArgs, controlsBg.Id, isHex);
        // });
        // controlsBg.ColorSelector().On("COLOR_RESET", this, eventArgs => {
        //     var isHex = true;
        //     pickerApplying(controlsBg, eventArgs, controlsBg.Id, isHex);
        // });

        // let controlsBorder = {
        //     Id: 'controls_border',
        //     Rule: ['border-color'],
        //     Value: '',
        //     Transparent: '',
        //     Selector: ['.form-standart-control, .jBox-container .edit-screen .group-control.consult-subform-control, .image-control-result img, .ConsultScreenView.EditScreenView .group-control, .form-designer .form-standard-control fieldset, .EditScreenView.linklist-control'],
        //     ColorSelector: ko.observable(new HexColorModel({
        //         Label: LABELS.CONTROLS_BACKGROUND_BORDER_COLOR,
        //         Disabled: !this._isActive()
        //     }))
        // };
        // this._colorPickersObject[controlsBorder.Id] = controlsBorder;
        // controlsBorder.ColorSelector().On("COLOR_SET", this, eventArgs => {
        //     pickerApplying(controlsBorder, eventArgs, controlsBorder.Id);
        // });
        // controlsBorder.ColorSelector().On("COLOR_RESET", this, eventArgs => {
        //     pickerApplying(controlsBorder, eventArgs, controlsBorder.Id);
        // });

        // let controlsColor = {
        //     Id: 'controls_color',
        //     Rule: ['color'],
        //     Value: '',
        //     Transparent: '',
        //     Selector: ['.label-input label, .label-input input, .edit-screen-block label, .select-wrapper, .select-wrapper select, .form-control, .form-standart-control .textarea-control, .textarea-control:empty:not(:focus):before, .form-designer .form-standard-control .form-control, .form-standart-control textarea, .form-standart-control select, .form-standart-control .additional-icon'],
        //     ColorSelector: ko.observable(new HexColorModel({
        //         Label: LABELS.CONTROLS_TEXT_COLOR,
        //         Disabled: !this._isActive()
        //     }))
        // };
        // this._colorPickersObject[controlsColor.Id] = controlsColor;
        // controlsColor.ColorSelector().On("COLOR_SET", this, eventArgs => {
        //     pickerApplying(controlsColor, eventArgs, controlsColor.Id);
        // });
        // controlsColor.ColorSelector().On("COLOR_RESET", this, eventArgs => {
        //     pickerApplying(controlsColor, eventArgs, controlsColor.Id);
        // });

        // let tabBgActive = {
        //     Id: 'tab_bgactive',
        //     Rule: ['background-color'],
        //     Value: '',
        //     Transparent: '',
        //     Selector: ['.nav.nav-tabs .tab.active a, .nav.nav-tabs .tab.active a:hover'],
        //     ColorSelector: ko.observable(new HexColorModel({
        //         Label: LABELS.ACTIVE_TAB_LEVEL_BACKGROUND_COLOR,
        //         Disabled: !this._isActive()
        //     }))
        // };
        // this._colorPickersObject[tabBgActive.Id] = tabBgActive;
        // tabBgActive.ColorSelector().On("COLOR_SET", this, eventArgs => {
        //     pickerApplying(tabBgActive, eventArgs, tabBgActive.Id);
        // });
        // tabBgActive.ColorSelector().On("COLOR_RESET", this, eventArgs => {
        //     pickerApplying(tabBgActive, eventArgs, tabBgActive.Id);
        // });

        // let tabColorActive = {
        //     Id: 'tab_coloractive',
        //     Rule: ['background-color'],
        //     Value: '',
        //     Transparent: '',
        //     Selector: ['.nav.nav-tabs .tab.active a, .nav.nav-tabs .tab.active a:hover'],
        //     ColorSelector: ko.observable(new HexColorModel({
        //         Label: LABELS.ACTIVE_TAB_LEVEL_TEXT_COLOR,
        //         Disabled: !this._isActive()
        //     }))
        // };
        // this._colorPickersObject[tabColorActive.Id] = tabColorActive;
        // tabColorActive.ColorSelector().On("COLOR_SET", this, eventArgs => {
        //     pickerApplying(tabColorActive, eventArgs, tabColorActive.Id);
        // });
        // tabColorActive.ColorSelector().On("COLOR_RESET", this, eventArgs => {
        //     pickerApplying(tabColorActive, eventArgs, tabColorActive.Id);
        // });

        // let tabBorderColor = {
        //     Id: 'tab_bordercolor',
        //     Rule: ['border-color'],
        //     Value: '',
        //     Transparent: '',
        //     Selector: ['.tab-page-result, .portlet-body.tab-page-result'],
        //     ColorSelector: ko.observable(new HexColorModel({
        //         Label: LABELS.TAB_BORDER_COLOR,
        //         Disabled: !this._isActive()
        //     }))
        // };
        // this._colorPickersObject[tabBorderColor.Id] = tabBorderColor;
        // tabBorderColor.ColorSelector().On("COLOR_SET", this, eventArgs => {
        //     pickerApplying(tabBorderColor, eventArgs, tabBorderColor.Id);
        // });
        // tabBorderColor.ColorSelector().On("COLOR_RESET", this, eventArgs => {
        //     pickerApplying(tabBorderColor, eventArgs, tabBorderColor.Id);
        // });

        // let tableHeaderBg = {
        //     Id: 'table_headerbg',
        //     Rule: ['background-color'],
        //     Value: '',
        //     Transparent: '',
        //     Selector: ['.table-striped thead tr th'],
        //     ColorSelector: ko.observable(new HexColorModel({
        //         Label: LABELS.TABLE_HEADER_BACKGROUND_COLOR,
        //         Disabled: !this._isActive()
        //     }))
        // };
        // this._colorPickersObject[tableHeaderBg.Id] = tableHeaderBg;
        // tableHeaderBg.ColorSelector().On("COLOR_SET", this, eventArgs => {
        //     pickerApplying(tableHeaderBg, eventArgs, tableHeaderBg.Id);
        // });
        // tableHeaderBg.ColorSelector().On("COLOR_RESET", this, eventArgs => {
        //     pickerApplying(tableHeaderBg, eventArgs, tableHeaderBg.Id);
        // });

        // let tableHeaderColor = {
        //     Id: 'table_headercolor',
        //     Rule: ['color'],
        //     Value: '',
        //     Transparent: '',
        //     Selector: ['.table-striped thead tr th'],
        //     ColorSelector: ko.observable(new HexColorModel({
        //         Label: LABELS.TABLE_HEADER_TEXT_COLOR,
        //         Disabled: !this._isActive()
        //     }))
        // };
        // this._colorPickersObject[tableHeaderColor.Id] = tableHeaderColor;
        // tableHeaderColor.ColorSelector().On("COLOR_SET", this, eventArgs => {
        //     pickerApplying(tableHeaderColor, eventArgs, tableHeaderColor.Id);
        // });
        // tableHeaderColor.ColorSelector().On("COLOR_RESET", this, eventArgs => {
        //     pickerApplying(tableHeaderColor, eventArgs, tableHeaderColor.Id);
        // });

        // let headerText = {
        //     Id: 'header_text',
        //     Rule: ['color'],
        //     Value: '',
        //     Transparent: '',
        //     Selector: ['.page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-toggle > .username'],
        //     ColorSelector: ko.observable(new HexColorModel({
        //         Label: LABELS.COLOR_FOR_HEADER_TEXT,
        //         Disabled: !this._isActive()
        //     }))
        // };
        // this._colorPickersObject[headerText.Id] = headerText;
        // headerText.ColorSelector().On("COLOR_SET", this, eventArgs => {
        //     pickerApplying(headerText, eventArgs, headerText.Id);
        // });
        // headerText.ColorSelector().On("COLOR_RESET", this, eventArgs => {
        //     pickerApplying(headerText, eventArgs, headerText.Id);
        // });

        // let headerBgText = {
        //     Id: 'header_bgtext',
        //     Rule: ['background-color'],
        //     Value: '',
        //     Transparent: '',
        //     Selector: ['.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle:hover'],
        //     ColorSelector: ko.observable(new HexColorModel({
        //         Label: LABELS.BACKGROUND_COLOR_FOR_HEADER_TEXT,
        //         Disabled: !this._isActive()
        //     }))
        // };
        // this._colorPickersObject[headerBgText.Id] = headerBgText;
        // headerBgText.ColorSelector().On("COLOR_SET", this, eventArgs => {
        //     pickerApplying(headerBgText, eventArgs, headerBgText.Id);
        // });
        // headerBgText.ColorSelector().On("COLOR_RESET", this, eventArgs => {
        //     pickerApplying(headerBgText, eventArgs, headerBgText.Id);
        // });

    }

    InitMetronic() {
        Demo.init();
        Demo.handleTheme();

        this.InitDropdowns();
    }

    InitDropdowns() {
        // let colorSetting = UserVarsManager.Instance.GetSettingsColor();
        // let layoutSetting = UserVarsManager.Instance.GetSettingsLayoutMode();
        // let layoutBorders = UserVarsManager.Instance.GetSettingsSidebarBorders();

        let colorSetting = this._uiSettings && this._uiSettings.Settings.Color;
        let layoutSetting = this._uiSettings && this._uiSettings.Settings.Style;
        let layoutBorders = this._uiSettings && this._uiSettings.Settings.SidebarBorders;

        let sidebarPos = this._uiSettings && this._uiSettings.Settings.SidebarPosOption;
        let sidebarStyle = this._uiSettings && this._uiSettings.Settings.SidebarStyleOption;
        let sidebarOption = this._uiSettings && this._uiSettings.Settings.SidebarOption;

        this._colorThemes.find('li').removeClass('current');

        if (colorSetting) {
            this._colorThemes.find('li[data-style="' + colorSetting + '"]').addClass('current');
        } else {
            this._colorThemes.find('li[data-style="darkblue"]').addClass('current');
        }

        if (layoutSetting) {
            this._layoutModeDropDown.val(layoutSetting);
        }

        if (layoutBorders) {
            this._sidebarBorders.val(layoutBorders);
        }

        if (sidebarPos) {
            this._sidebarPos.val(sidebarPos);
        }

        if (sidebarStyle) {
            this._sidebarStyle.val(sidebarStyle);
        }

        if (sidebarOption) {
            this._sidebarOption.val(sidebarOption);
        }
    }

    //add styles to preview elements
    AddStyles(val, el) {
        let self = this;
        let rule = "";
        let propertyName = "";

        rule = el.data("classes-info");
        propertyName = el.data("property-info");
        let propertyValue = val;
        if (el.data("transparent") && propertyValue.length <= 7) {
            let transparentValue = 0;
            let propertyValueHex = '';
            transparentValue = el.data("transparent");
            let propertyValueToHex: any; //??
            propertyValueToHex = self.HexToRgb(propertyValue);
            propertyValueHex = "rgba(" + propertyValueToHex.r + ',' + propertyValueToHex.g + ',' + propertyValueToHex.b + ',' + transparentValue + ")";

            $(rule).css(propertyName, propertyValueHex);
        } else {
            $(rule).css(propertyName, propertyValue);
        }
    }

    HexToRgb(hex) {
        if (!hex) {
            return null;
        }

        let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
            return r + r + g + g + b + b;
        });
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    ThemeSwitcher(el, themeName) {
        $(el).siblings().removeClass('active');
        $(el).toggleClass('active');

        this._haveChanges(true);

        let self = this;

        if ($(el).hasClass('active')) {
            this._isActive(true);

            const customTheme: CustomThemeDescriptor = this._uiSettings.GetCustomTheme(themeName);
            if (customTheme) {
                _.each(self._colorPickersObject, colorSelector => {
                    colorSelector.ColorSelector().SetColor('');
                });

                let settingsList: CustomThemeSetting[] = customTheme.Settings;

                this._selectedCustomThemeSettings = settingsList;
                _.each(settingsList,
                    (setting: CustomThemeSetting) => {

                        let colorValue = setting.Val;
                        if (setting.Transparent) {
                            colorValue = self.RgbToHex(setting.Val);
                        }
                        self._colorPickersObject[setting.Id].Value = colorValue;
                        self._colorPickersObject[setting.Id].ColorSelector().SetColor(colorValue);
                    });

                this._uiSettings.SetCustomTheme(customTheme.Name, customTheme.Settings);
                this.SetThemeSquares();
                // this.ApplyCurrentTheme();
            } else {
                _.each(this._colorPickersObject, picker => {
                    picker.ColorSelector().SetColor('');
                });
                this._uiSettings.SetCustomTheme(themeName, []);
            }
        } else {
            this._isActive(false);
            this.UncheckTheme();
        }
    }

    ThemeStatus(el, themeName) {
        // this.ThemeSwitcher(el, themeName);
    }

    UncheckTheme() {
        this._uiSettings.SetCustomTheme('Custom0', []);

        _.each(this._colorPickersObject, picker => {
            picker.ColorSelector().SetColor('');
        });
        Demo.SetUISettings(this._uiSettings);
    }

    Save() {
        GlobalManager.Instance.GetGlobalDescriptor(GLOBALS.THEME).Value = this._uiSettings.ToJSON();
        Demo.SetUISettings(this._uiSettings);

        BlockUI.Block();
        ThemeBuilderStore.SetUISettings(this._uiSettings.Serialize())
            .then(() => new Notifier().Success(NOTIFICATIONS.CHANGES_APPLIED))
            .fail(err => new Notifier().Failed(err.message))
            .always(() => BlockUI.Unblock());
    }

    ShowPreview() {
        this._modal = new Modal({}, true);
        let template = this._previewTemplate.clone();
        this._modal.SetContent(template);
        let modalPreview = $(this._modal.Wrapper).find(template);
        $(modalPreview).show();

        this._modal.Show();
    }

    RenderByTargetId(target: string): void {
        ko.cleanNode(document.getElementById(target));
        ko.applyBindings(this, document.getElementById(target));
    }

    GetTemplateName() {
        return 'Core/ProfilePage/ThemeBuilder/Templates/ThemeBuilder';
    }

    AfterRender(el: Array<HTMLElement>) {
        this._el = el[0];
        this._themeContent = el[0];

        this.CacheElements();
        this.InitMetronic();

        // if (this._uiSettings) {
        //     const customThemeName = this._uiSettings.Settings.CustomThemeName;
        //
        //     if (customThemeName) {
        //         $(this._themeContent).find('.' + customThemeName).addClass('active');
        //     }
        // }
        // this.ApplyCurrentTheme()

        this.BindEvents();

        if (!this._uiSettings.Equals(this.GetUISettings())) {
            this._haveChanges(true);
        }

        if (!this._userIsAllowedToChange) {
            BlockUI.TransparentBlock(this._el, {
                ZIndex: 200,
                PaddingRootElement: 5,
                TextMessage: this._labels.LOCKED_BY_SUPER_USER,
                OpacityMessage: true,
                BackgroundColor: 'rgb(0 0 0 / 15%)',
                Cursor: 'not-allowed'
            });
        }
    }

    private GetUISettings() {
        const uiSettingsJson = GlobalManager.Instance.GetGlobal(GLOBALS.THEME);
        return uiSettingsJson ? GenericDeserialize(JSON.parse(uiSettingsJson), UISettings) : new UISettings();
    }

    private BuildCustomThemeArray() {
        let arr = [];

        _.each(this._colorPickersObject, (selector: IColorSelectorInfo) => {

            let className = '';
            let rule = '';
            let val = '';
            let id = '';
            let transparent = '';

            var hexModel = selector.ColorSelector() as HexColorModel;

            id = selector.Id;
            className = selector.Selector;
            rule = selector.Rule;
            val = hexModel.ColorValue;
            transparent = selector.Transparent;

            arr.push({
                Id: id,
                Selector: className,
                Rule: rule,
                Val: val,
                Transparent: transparent
            });
        });

        return arr;
    }

}