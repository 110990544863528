import * as ko from 'knockout';

import {deserialize} from 'libs/cerialize';

export class ViewGridModel {
    constructor(id: number, name: string) {
        this.Id = id;
        this.Name = name;

        this.LockedTableView = ko.observable(false);
    }

    Init() {
        this.LockedTableView(this.Locked);
    }

    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    IsEditingAllowed: boolean;

    @deserialize
    IsDeletingAllowed: boolean;

    @deserialize
    Locked: boolean;

    LockedTableView: KnockoutObservable<boolean>;

    Lock() {
        this.Locked = true;
        this.LockedTableView(true);
    }

    Unlock() {
        this.Locked = false;
        this.LockedTableView(false);
    }
}