import {FieldCollectionMappings} from "Core/Controls/FieldSecurity/Mappings/FieldCollectionMappings";

import {DataRoleDTOModel} from "Core/Controls/FieldSecurity/Shared/Models/DTO/Get/DataRoleDTOModel";
import {DataRoleModel} from "Core/Controls/FieldSecurity/Shared/Models/Business/DataRoleModel";

import {NewDataRoleDTOModel} from "Core/Controls/FieldSecurity/Shared/Models/DTO/Post/NewDataRoleDTOModel";
import {AssigneeMappings} from "Core/Controls/FieldSecurity/Mappings/AssigneeMappings";


export class DataRoleMappings {
	static MapToDataRole(dataRoleDto: DataRoleDTOModel): DataRoleModel {
		const fieldCollection = FieldCollectionMappings.MapToFieldCollection(dataRoleDto.FieldCollection);
		return new DataRoleModel(dataRoleDto.Id, dataRoleDto.Name, fieldCollection);
	}

	static MapToNewDataRole(newRole: DataRoleModel) {
		const assignee = AssigneeMappings.MapToNewAssignee(newRole.FieldCollection.Assignee);
		return new NewDataRoleDTOModel(newRole.Name, newRole.FieldCollection.Name, assignee);
	}
}