import {FastFilter} from 'Core/Controls/Grid/BaseGrid/FastFilter/FastFilter';
import {GridColumnModel} from 'Core/Controls/Grid/Models/GridDataModel/GridColumnModel';
import {EVENTS} from 'Core/Controls/Grid/BaseGrid/Events';
import {IGetGridDataRequestModel} from 'Core/Controls/Grid/Stores/GridStore';

// Templates
import TextFilterTemplate from 'Core/Controls/Grid/BaseGrid/FastFilter/Filters/Text/Templates/TextFilter.html';
ko.templates['Core/Controls/Grid/BaseGrid/FastFilter/Filters/Text/Templates/TextFilter'] = TextFilterTemplate;

import Template from 'Core/Controls/Grid/BaseGrid/FastFilter/Filters/Text/Templates/TextFilterPopUp.html';
ko.templates['Core/Controls/Grid/BaseGrid/FastFilter/Filters/Text/Templates/TextFilterPopUp'] = Template;

export class TextFilter extends FastFilter {
	constructor(model: GridColumnModel,
				recordId: number,
				tableViewId: number,
				getGridDataModel: IGetGridDataRequestModel,
				existedColumnAliases: string[]) {
		super(model, recordId, tableViewId, getGridDataModel, existedColumnAliases);

		this.Preselect();
	}

	protected Filter() {
		this.MapToSaveModel();

		this.Trigger(EVENTS.FAST_FILTER);
	}

	GetTemplate() {
		return TextFilterTemplate;
	}

	GetPopUpTemplate() {
		return Template;
	}
}