import {P} from "Core/Common/Promise";
import {Request} from "Core/Common/Request";
import {TypeSecurityModel} from "Core/Controls/TypeSecurity/Models/TypeSecurityModel";

export class TypeSecurityStore {
    static GetTypeSecurityData(userId: number): P.Promise<TypeSecurityModel> {
        let deferredResult = P.defer<TypeSecurityModel>();

        Request.Send({
            proxy: {
                url: '/api/TypeSecurityApi/GetTypeSecurityScreen',
                type: "GET"
            },
            params: { userId: userId }
        })
            .then(result => deferredResult.resolve(result))
            .fail(error => deferredResult.reject(this.GetResponseError(error, 'Error getting type security')));

        return deferredResult.promise();
    }

    static GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}