import {Event} from "Core/Common/Event";

import {
	ConfirmationDialog,
	EVENTS as CONFIRMATION_DIALOG_EVENTS, Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

import {CONFIRMATIONS, LABELS} from "Core/Components/Translation/Locales";

export class ContinueLockConfirmation extends Event {
	private _dialog: ConfirmationDialog;

	constructor(duration: number) {
		super();

		this._dialog = new ConfirmationDialog({
			Text: CONFIRMATIONS.CONTINUE,
			Type: ConfirmationTypes.Question,
			TextConfirm: LABELS.YES,
			TextDecline: LABELS.NO,
			Timeout: duration
		});

		this._dialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, this.OnConfirm);
		this._dialog.On(CONFIRMATION_DIALOG_EVENTS.DISCARD_SELECTED, this, this.OnDiscard);
		this._dialog.On(CONFIRMATION_DIALOG_EVENTS.TIMER_EXPIRED, this, this.OnTimerExpired);
	}

	Show() {
		this._dialog.Show();
	}

	private OnConfirm() {
		this.Trigger(CONTINUE_LOCK_CONFIRMATION_EVENTS.CONTINUE);
	}

	private OnDiscard() {
		this.Trigger(CONTINUE_LOCK_CONFIRMATION_EVENTS.STOP);
	}

	private OnTimerExpired() {
		this._dialog.Close();
		this.Trigger(CONTINUE_LOCK_CONFIRMATION_EVENTS.STOP);
	}
}

export const CONTINUE_LOCK_CONFIRMATION_EVENTS = {
	CONTINUE: "Continue",
	STOP: "Stop"
};