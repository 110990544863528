import 'jBox';

export class Tooltip {
	private _el: HTMLElement;
	private _jbox: jBox;

	constructor(_el, content?: string, options?: any) {
		const defaultOptions = {
			content: content,
			attach: $(_el),
			zIndex: 50000,
			closeOnClick: true,
			closeOnMouseleave: true,
			// Workaround for tooltips appearing close to vertical scrollbar
			adjustDistance:
				document.documentElement.scrollHeight > document.documentElement.clientHeight
					? { right: 15 }
					: undefined
		};
		if (options) {
			$.extend(defaultOptions, options);
		}

		if($(_el).attr('title') || content) {
            this._jbox = new jBox('Tooltip', defaultOptions);
		}
    }

	Close() {
		this._jbox.close();
	}

    AddClass(className: string) {
		if(this._jbox) {
			this._jbox.options.addClass = className;
		}
	}

	Show(targetElem?: any, targetId?: any) {
		if (targetId){
			this._jbox.open({ target: $(`#${targetId}`) });
		} else if (targetElem) {
			this._jbox.open({target: $(targetElem)});
		}
	}

	Attach(el: JQuery) {
		this._jbox.close();
	}

	SetContent(content) {
        this._jbox && this._jbox.setContent(content);
	}

	Destroy() {
		this._jbox && this._jbox.destroy();
	}
}