import * as ko from "knockout"
import 'jquery';

interface IScrollSynchOptions {
    topScroll: string;
    bottomScroll: string;
}

export class ScrollSynchronizingExtention {
    static Init() {
        ko.bindingHandlers.syncScroll = {
            init: (element, valueAccessor: any) => {

                let options: IScrollSynchOptions = {
                    topScroll: valueAccessor().topScroll,
                    bottomScroll: valueAccessor().bottomScroll
                };

                if (options.topScroll && options.bottomScroll) {
                    let $topEl = $(element).find(options.topScroll);
                    let $bottomEl = $(element).find(options.bottomScroll);

                    $topEl.scroll(function() {
                        let length = $(this).scrollLeft();
                        $bottomEl.scrollLeft(length);
                    });

                    $bottomEl.scroll(function() {
                        let length = $(this).scrollLeft();
                        $topEl.scrollLeft(length);
                    });
                }
            }
        }

    }
}