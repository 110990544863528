import * as ko from 'knockout';
import * as $ from 'jquery';
import * as _ from 'underscore';
import {CONTROL_TYPES, LIFE_STATUS_GROUPS} from "Core/Constant";
import {IUpdateStatusRequestModel, KanbanStore} from 'Core/Controls/Kanban/Stores/KanbanStore';
import {
    ActionModel,
    LinkModel,
    PriorityModel,
    RecordModel,
    StatusModel,
    StepModel,
    ThumbnailModel
} from 'Core/Controls/Kanban/Models/StatusModel';
import {DragulaExtention} from 'Core/KnockoutExtentions/DragulaExtention';
import {BlockUI} from 'Core/Common/BlockUi';
import {KanbanStep} from 'Core/Controls/Kanban/Step';
import {Notifier} from 'Core/Common/Notifier';
import {LOCK_EVENTS, LockManager} from "Core/Components/Locker/LockManager";
import {ButtonFollowUp} from "Core/Controls/ButtonFollowUp/ButtonFollowUp";
import {FollowUpRecordModel} from "Core/Controls/ButtonFollowUp/Models/FollowUpRecordModel";
import {DataModes} from "Core/Enums/DataModes";
import type {EditScreen} from 'Core/Screens/EditScreen/EditScreen';
import {IScreen} from "Core/Screens/IScreen";

import {CONFIRMATIONS, LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";

import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";
import {TypeScreen} from "Core/Screens/TypeScreen/TypeScreen";

import {FollowUpStore} from "Core/Controls/ButtonFollowUp/Stores/FollowUpStore";
import {PromptDialog, Types} from 'Core/Components/Dialogs/PromptDialog/PromptDialog';
import {LifeStatusesModel} from 'Core/Controls/ButtonFollowUp/Models/LifeStatusesModel';
import {FollowupModes} from 'Core/Constants/FollowupModes';

import {ConfigModel} from 'Core/GeneralProperties/Models/ConfigModel';
import {PROPERTIES} from "Core/Controls/Kanban/Constants";

import {ScreenTypes} from "Core/Common/Enums/ScreenTypes";
import {ActionTypes} from "Core/Common/Enums/ActionTypes";

import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS,
    Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog'
import {ActionSubjectRecordModel} from "Core/ScreenManager/Models/ActionSubjectRecordModel";
import {LinkList} from "Core/Controls/LinkList/LinkList";
import {Tab} from "Core/Controls/Tab/Tab";
import {TabPage} from "Core/Controls/TabPage/TabPage";
import {NewRelationModel} from "Core/Controls/LinkList/Models/NewRelationModel";
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';

import "Core/Controls/Kanban/KanbanCard";
import {MobileChecker} from 'Core/Common/MobileChecker';
import {ResizeObserver} from 'Core/Common/ResizeObserver';
import {PathRunner} from "Core/Components/PathRunner/PathRunner";

import {LifeStatusesGeneralModel} from "Core/Controls/ButtonFollowUp/Models/LifeStatusesGeneralModel";

import {FollowUpLifeStatuses} from "Core/Controls/ButtonFollowUp/FollowUpLifeStatuses";
import {ScreenParamsModel} from "Core/Controls/ButtonFollowUp/Models/ScreenParamsModel";
import {SubjectActionModel} from "Core/Controls/ButtonFollowUp/Models/SubjectActionModel";

import {ActionCheckListViewModel} from "Core/Components/ActionCheckList/Models/View/ActionCheckListViewModel";
import {FlowFolderStore} from "Core/Components/ProgressBar/Stores/FlowFolderStore";
import {ProgressBarInfoModel} from "Core/Components/ProgressBar/Models/Response/ProgressBarInfoModel";
import {FlowFolderModel} from "Core/Components/ProgressBar/Models/Response/FlowFolderModel";
import {ActionCheckList} from "Core/Components/ActionCheckList/ActionCheckList";
import {ICheckItemSerializedValue} from "Core/Components/ActionCheckList/CheckItems/ICheckItemSerializedValue";
import {LifeStatusSimpleModel} from "Core/Controls/ButtonFollowUp/Models/LifestatusSimpleModel";
import {KanbanTypeViewModel} from "Core/Controls/Kanban/Models/KanbanTypeViewModel";

const ResizeService = new ResizeObserver();

var CLASSES = {
    FORBID_DRAG: 'forbid-drag',
    ALLOW_DRAG: 'allow-drag'
}

function getPopoverTemplate(classNames: string) {
    return _.escape(`
        <div class="popover ${classNames}" role="tooltip">
            <div class="arrow"></div>
            <h3 class="popover-title"></h3>
            <div class="popover-content"></div>
        </div>
    `);
}

interface DragulaState {
    item: any,
    sourceIndex: any,
    sourceItems: any,
    sourceContext: any,
    targetIndex: any,
    targetItems: any
}

//Templates
import Template from 'Core/Controls/Kanban/Templates/Default/DefaultKanban.html';
import TodoActionItemsTemplate from 'Core/Controls/Kanban/Templates/Todo/TodoActionItemsTemplate.html';
import {Event} from "../../Common/Event";

ko.templates['Core/Controls/Kanban/Templates/Default/DefaultKanban'] = Template;

class DefaultKanban {
    private _screen: KnockoutObservable<IScreen>;
    private _selectedTypeObservable: KnockoutObservable<KanbanTypeViewModel>;
    private _originSteps: KanbanStep[];
    private _steps: KnockoutObservableArray<KanbanStep>;
    private _hasData: KnockoutObservable<boolean>;
    private _searchString: KnockoutObservable<string>;
    private _data: StatusModel;
    private _height: KnockoutObservable<number>;
    private _selectedUsers: KnockoutObservableArray<number>;
    private _showPlanned: KnockoutObservable<boolean>;
    private _showTypeError: KnockoutObservable<boolean>;
    private _lifeStatuses: LifeStatusesModel;
    private _dragulaState: DragulaState;
    private _generalProperties: ConfigModel;
    private _isTodoToggleShown: KnockoutObservable<boolean>;
    _avatarThumbnails: KnockoutObservableArray<ThumbnailModel>;
    _priorities: KnockoutObservableArray<PriorityModel>;
    private _viewId: KnockoutObservable<number>;
    private _elObservable: KnockoutObservable<HTMLElement>;
    private _selectedTypeSubscription: KnockoutSubscription;
    private _selectedUsersSubscription: KnockoutSubscription;
    private _showPlannedSubscription: KnockoutSubscription;
    private _viewIdSubscription: KnockoutSubscription;
    private _showTypeErrorSubscription: KnockoutSubscription;
    _titleFieldId: number;
    private _kanbanSubjectId: KnockoutObservable<number>;
    private _kanbanSubjectName: KnockoutObservable<string>;
    _withDateBadges: boolean;
    _progressBar: boolean;
    _withMemo: boolean;
    _readOnly: boolean;
    private _heightImage: any;
    private _calculateHeightContainerStyle: string;
    private _colorBar: boolean;
    private _alwaysShowDeadline: boolean;
    private _hideOwner: boolean;
    private _backgroundColor: string;
    private _showTooltip: boolean;
    private _showAlias: boolean;
    private _followUpRecord: FollowUpRecordModel;
    private _followUpLifeStatuses: FollowUpLifeStatuses;
    private _isFlowFolder: boolean;
    private _checkList: ActionCheckList;

    Css: KnockoutComputed<any>;

    constructor(params: {
        screen: KnockoutObservable<IScreen>;
        selectedTypeObservable: KnockoutObservable<KanbanTypeViewModel>;
        generalProperties: ConfigModel;
        searchString: KnockoutObservable<string>;
        selectedUsers: KnockoutObservableArray<number>;
        showPlanned: KnockoutObservable<boolean>;
        showTypeError: KnockoutObservable<boolean>;
        isTodoToggleShown: KnockoutObservable<boolean>;
        titleFieldId: number;
        kanbanSubjectId: KnockoutObservable<number>;
        kanbanSubjectName: KnockoutObservable<string>;
        viewId: KnockoutObservable<number>;
    }) {
        this._screen = params.screen;
        this._selectedTypeObservable = params.selectedTypeObservable;
        this._generalProperties = params.generalProperties;
        this._searchString = params.searchString;
        this._selectedUsers = params.selectedUsers;
        this._showPlanned = params.showPlanned;
        this._showTypeError = params.showTypeError;
        this._titleFieldId = params.titleFieldId;
        this._kanbanSubjectId = params.kanbanSubjectId;
        this._kanbanSubjectName = params.kanbanSubjectName;
        this._viewId = params.viewId;
        this._isTodoToggleShown = params.isTodoToggleShown;
        this._elObservable = ko.observable(null);
        this._originSteps = [];
        this._steps = ko.observableArray([]);
        this._hasData = ko.observable(true);
        this._avatarThumbnails = ko.observableArray();
        this._priorities = ko.observableArray();
        this._lifeStatuses = null;
        this._dragulaState = null;
        this._withDateBadges = this._generalProperties.GetPropertyValue(PROPERTIES.WITH_DATE_BADGES);
        this._progressBar = this._generalProperties.GetPropertyValue(PROPERTIES.PROGRESS_BAR_TYPE);
        this._withMemo = this._generalProperties.GetPropertyValue(PROPERTIES.WITH_MEMO);
        this._readOnly = this._generalProperties.GetPropertyValue(PROPERTIES.READ_ONLY);
        this._heightImage = this._generalProperties.GetPropertyValue(PROPERTIES.HEIGHT_IMAGE);
        this._colorBar = this._generalProperties.GetPropertyValue(PROPERTIES.COLOR_BAR);
        this._alwaysShowDeadline = this._generalProperties.GetPropertyValue(PROPERTIES.ALWAYS_SHOW_DEADLINE);
        this._hideOwner = this._generalProperties.GetPropertyValue(PROPERTIES.HIDE_OWNER);
        this._backgroundColor = this._generalProperties.GetPropertyValue(PROPERTIES.BACKGROUND_COLOR);
        this._showTooltip = this._generalProperties.GetPropertyValue(PROPERTIES.SHOW_TOOLTIP);
        this._showAlias = this._generalProperties.GetPropertyValue(PROPERTIES.SHOW_ALIAS);
        this._height = ko.observable(null);
        this.Css = ko.computed(() => {
            return {
                'min-height': this._height() + 'px',
                'background-color': !!this._backgroundColor && this._backgroundColor
            }
        });
        this._calculateHeightContainerStyle = null;

        this._searchString.subscribe(() => {
            this.Search();
        });
        this._followUpLifeStatuses = null;
        this._isFlowFolder = null;
        this._checkList = null;

        this.BindEvents();
    }

    private BindEvents() {
        PubSub.subscribe('KANBAN_FULLSCREEN', (message: any, data: boolean)=> {
            this.SetContainerHeight(data);
        });
    }

    private InitDragula() {
        _.each(DragulaExtention.FindGroups('KanbanBoard'), (item) => DragulaExtention.DestroyGroup(item));

        var kanbanBoardDrake = DragulaExtention.AddGroupWithOptions('KanbanBoard',
            {
                accepts: (el, target, source) => {
                    var record = ko.dataFor(el) as RecordModel;
                    var targetStep = ko.dataFor(target) as KanbanStep;
                    return targetStep.HasNextStatus(record.LifestatusId);
                },

                moves: (el, target, source) => {
                    var record = ko.dataFor(el) as RecordModel;
                    if (!record.Actions || record.IsDisabled) {
                        return false;
                    }

                    const actions = _.filter(record.Actions, action => !action.IsDisabled);
                    let step = _.find(this.Steps(), item => item.Id === record.LifestatusId)

                    if (actions.length > 1 && !step.MultipleActions) {
                        return false;
                    }

                    var result = true;
                    _.each(record.Actions, action => {
                        if (!action.ActionRecordId || action.IsDisabled) {
                            result = false;
                            return;
                        }
                    });

                    if (result) {
                        let isActionTypeAction = _.find(record.Actions, (action) => {
                            return (action.ActionTypeName === 'Action' && !action.IsDisabled) || (action.ActionTypeId == 0 && !action.IsDisabled);
                        });
                        return !isActionTypeAction ? result = false : result;

                    } else {
                        return result;
                    }
                }

            });

        kanbanBoardDrake.drake.on('drag',
            (el) => {

                var record = ko.dataFor(el) as RecordModel;

                _.each(kanbanBoardDrake.drake.containers,
                    (container) => {
                        var kanbanStep = ko.dataFor(container) as KanbanStep;

                        if (kanbanStep.HasNextStatus(record.LifestatusId)) {
                            $(container).addClass(CLASSES.ALLOW_DRAG);
                        } else {
                            $(container).addClass(CLASSES.FORBID_DRAG);
                        }
                    });

            });

        kanbanBoardDrake.drake.on('dragend',
            (el) => {

                _.each(kanbanBoardDrake.drake.containers,
                    (container) => {
                        $(container).removeClass(CLASSES.ALLOW_DRAG);
                        $(container).removeClass(CLASSES.FORBID_DRAG);
                    });
            });

        if (this._readOnly) {
            kanbanBoardDrake.drake.destroy();
        }
    }

    ListenForStatusesLoad() {
        this._selectedTypeSubscription = this._selectedTypeObservable.subscribe(() => {
            this.LoadStatuses();
        });
        this._selectedUsersSubscription = this._selectedUsers.subscribe(() => {
            this.LoadStatuses();
        });
        this._showPlannedSubscription = this._showPlanned.subscribe(() => {
            this.LoadStatuses();
        });
        this._viewIdSubscription = this._viewId.subscribe(() => {
            this.LoadStatuses();
        });
        this._showTypeErrorSubscription = this._showTypeError.subscribe(() => {
            this.ShowTypeError();
        });
        this.LoadStatuses();
    }

    ShowTypeError(){
        if(this._showTypeError()) {
            new Notifier().Warning(NOTIFICATIONS.ERROR_GETTING_LIFESTATUSES
                .replace('{EntityName}', this.SubjectEntityName));
            this._showTypeError(false);
        }
    }

    LoadStatuses() {
        const selectedType = this._selectedTypeObservable();
        const selectedUsers = this._selectedUsers();
        const showPlanned = this._showPlanned();
        const viewId = this._screen().IsConsultScreen? 0 : this._viewId();
        this._isFlowFolder = this._selectedTypeObservable() && this._selectedTypeObservable().HasFlowFolder;

        if (selectedType === null || selectedUsers.length === 0 || showPlanned === null || viewId === null) {
            return;
        }

        const el = this._elObservable() ? {Target: this._elObservable()} : {};
        BlockUI.Block(el);
        KanbanStore.GetStatuses({
            EntityId: this.SubjectEntity,
            FieldId: this._titleFieldId,
            TypeId: selectedType ? selectedType.K_Type : null,
            SubjectTypeId: this._screen().GetTableTypeId(),
            FilterByTypeHierarchy: this._selectedTypeObservable() && this._selectedTypeObservable().ShowTypeHierarchy,
            FilterByOwners: true,
            UseSubjectEntity: this.UseKanbanSubject,
            FilterByEntityId: this.FilterByEntityId,
            FilterByRecordId: this.FilterByRecordId,
            RecordOwners: selectedUsers,
            ShowPlanned: showPlanned,
            WithTags: !this._generalProperties.GetPropertyValue(PROPERTIES.WITH_TAGS),
            WithSubjectImage: this._generalProperties.GetPropertyValue(PROPERTIES.WITH_SUBJECT_IMAGE),
            WithPriorities: this._generalProperties.GetPropertyValue(PROPERTIES.WITH_PRIORITIES).Value === 'Show',
            WithCalculatedPriority: this._generalProperties.GetPropertyValue(PROPERTIES.WITH_PRIORITIES).Value === 'Calculated',
            WithCalculatedViaCalсPrioPriority: this.IsCalculatedViaCalcPriority,
            WithMails: this._generalProperties.GetPropertyValue(PROPERTIES.WITH_MAILS),
            WithColor: this._colorBar,
            ViewId: viewId,
            IsToDo: false,
            WithMemo: this._withMemo,
            WithToDoProgressBar: this._progressBar
        }).always(() => {
            BlockUI.Unblock(this._elObservable());
        })
            .then((status: StatusModel) => {
                this._steps([]);
                this.InitDragula();
                this.SetData(status);
                this._data = status;
                this._hasData(status.Steps.length > 0);
                this._isTodoToggleShown(status.HasTodoType);
                this._avatarThumbnails(status.Thumbnails);
                this._priorities(status.Priorities);
                this.HeaderHeightResize();
            })
            .fail(error => {
                new Notifier().Failed(error);
            });
    }

    SetData(status: StatusModel) {
        var steps = [];
        _.each(status.Records,
            (record: RecordModel) => {
                let isActionTypeAction = _.find(record.Actions, (action) => {
                    return (action.ActionTypeName === 'Action' && !action.IsDisabled) || (action.ActionTypeId == 0 && !action.IsDisabled);
                });
                record.IsDisabledCard = !isActionTypeAction;

                _.each(record.Actions,
                    (action) => {
                        if ((action.ActionTypeName === 'Action' && !action.IsDisabled) ||
                            (action.ActionTypeId == 0 && !action.IsDisabled)) {
                            action.HighlightedAction = true;
                        } else {
                            action.HighlightedAction = false;
                        }
                    });
            });

        _.each(status.Steps,
            (step: StepModel) => {
                if (step.Enabled) {
                    if (!step.IsRetired) {
                        step.Records = _.filter(status.Records, (record: RecordModel) => {
                            return record.LifestatusId === step.Id;
                        });
                    }

                    var links = _.filter(status.Links, (link: LinkModel) => {
                        return link.IdStepTo === step.Id;
                    });
                    var kanbanStep = new KanbanStep(step, links);
                    steps.push(kanbanStep);
                }
            });

        this._originSteps = steps;

        if (this._searchString()) {
            this.Search()
        } else {
            this._steps(steps);
        }
    }

    TopHeight() {
        let allPadding = 91,
            pageHeaderHeight = $("body").find('.page-header.navbar').height(),
            actionBarSubFormHeight = $("body").find('.actionBarSubForm').outerHeight(),
            kanbanBoardRootHeight = $(this._elObservable()).find('.kanbard-board-root').outerHeight(),
            panelTitleHeight = $(this._elObservable()).find('.kanban-column').find('.panel-title').outerHeight();

        return allPadding + pageHeaderHeight + actionBarSubFormHeight + kanbanBoardRootHeight + panelTitleHeight;
    }

    AfterRenderSteps(list) {
        this.SetContainerStyle();
    }

    SetContainerStyle(isFullscreen?: boolean) {
        const $container = $(this._elObservable()).find('.kanban-container').first();
        let calculate = $container.offset().top + 35;

        if (!MobileChecker.IsMobile() && calculate != this.TopHeight()) {
            let pageHeaderHeight = $("body").find('.page-header.navbar').height(),
                kanbanBoardRootHeight = $(this._elObservable()).find('.kanbard-board-root').outerHeight(),
                panelTitleHeight = $(this._elObservable()).find('.kanban-column').find('.panel-title').outerHeight();

            calculate = pageHeaderHeight + kanbanBoardRootHeight + panelTitleHeight + 50;

            if (isFullscreen) {
                calculate = kanbanBoardRootHeight;
            } else {
                $container.css('min-height', `auto`);
                return;
            }
        }

        $container.css('min-height',`calc(100vh - ${calculate}px)`);
    }

    SetContainerHeight(isFullscreen: boolean): void {
        const $container = $(this._elObservable()).find('.js-default-kanban__component');
        const toolbarHeight = $(this._elObservable()).find('.table-toolbar-line').outerHeight();

        let calculate: number = 45;

        if (isFullscreen) {
            calculate = toolbarHeight
        } else {
            $container.find('.kanban-container').first().css('min-height', `auto`);
        }
        $container.css('height', isFullscreen ? `calc(100vh - ${calculate}px)`: 'auto');
    }

    HeaderHeightResize() {
        const defaultKanbanHtml = $(this._elObservable()).find('.js-default-kanban');
        const unbindResize = ResizeService.SubscribeWidth(this.OnResize, defaultKanbanHtml[0]); // subscribe on resize

        ko.utils.domNodeDisposal.addDisposeCallback(defaultKanbanHtml[0], () => {
            unbindResize();
            window.removeEventListener("scroll", this.RepositionDefaultKanbanHeaderOnScroll, false)
        });
        window.addEventListener("scroll", this.RepositionDefaultKanbanHeaderOnScroll, false );
    }

    RepositionDefaultKanbanHeaderOnScroll = () => {
        const kanbanContainer = $(this._elObservable()).find('.js-default-kanban').find('.kanban-container');
        if (!kanbanContainer.hasClass('board-root')) {
            window.removeEventListener("scroll", this.RepositionDefaultKanbanHeaderOnScroll, false)
            return false;
        }
        const scrollTop = $(window).scrollTop();
        const allTitleElement = this._elObservable().querySelectorAll(".kanban-column:not(.retired-column):not(.collapsed) > .panel-title");
        const titleElementHeight = $(this._elObservable().querySelector(".kanban-column:not(.retired-column):not(.collapsed) > .panel-title")).outerHeight();
        const boundingRect = $(this._elObservable()).find('.board-root')[0].getBoundingClientRect();
        const positionKanbanBoardRoot = $(this._elObservable()).find('.board-root').offset().top + 6;
        let fixedHeaderHeight: number = 0;
        let calculate: number = 0;

        if (window.innerWidth > 991) {
            const headerElement: HTMLDivElement = document.querySelector("body > .page-header");
            fixedHeaderHeight = headerElement ? headerElement.offsetHeight : 0;
        }

        if ((boundingRect.top + 5) < fixedHeaderHeight) {
            calculate = Math.abs((positionKanbanBoardRoot - (scrollTop + fixedHeaderHeight)));
        }

        if (((boundingRect.bottom - 15) - fixedHeaderHeight - titleElementHeight) <= 0) {
            return false;
        }

        _.each(allTitleElement, (title: HTMLDivElement) => {
            title.style.top = calculate + "px";
        });
    }

    OnResize = () => {
        this.RepositionDefaultKanbanHeaderOnScroll();
    };

    Collapsing(item: KanbanStep, element) {
        item.IsCollapsed = !item.IsCollapsed;
        element.currentTarget.style.top = 0 + 'px';
    }

    get Steps(): KnockoutObservableArray<KanbanStep> {
        return this._steps;
    }

    get IsCalculatedViaCalcPriority(): boolean {
        return this._generalProperties.GetPropertyValue(PROPERTIES.WITH_PRIORITIES).Value === 'WithCalculatedViaCalсPrio';
    }

    GetActionItemsTemplate(data){
        return $((ko as any).renderTemplateX(TodoActionItemsTemplate, {$_data: data}));
    }

    ShowCheckListKanban(record: RecordModel, step: KanbanStep){
        FlowFolderStore.GetProgressBarInfo({ EntityId: record.EntityId, RecordId: record.RecordId })
            .then((result: ProgressBarInfoModel) => {
                const nextLifeStatus = _.find(result.NextLifeStatuses.Child.LifeStatuses, (lifeStatus)=> lifeStatus.Id === step.Model.Id);
                result.NextLifeStatuses.Child.CheckLists = _.filter(result.NextLifeStatuses.Child.CheckLists, (checkList) => checkList.LifeStatusId === step.Model.Id);

                this.CheckListKanban(result.NextLifeStatuses, result.FlowFolder, record, step, nextLifeStatus);
            });
    }

    CheckListKanban(result: LifeStatusesGeneralModel,
                        flowFolderModel: FlowFolderModel,
                        kanbanRecordModel: RecordModel,
                        kanbanStep: KanbanStep,
                        nextLifeStatus: LifeStatusSimpleModel) {
        if (result == null || !result.Child) {
            return;
        }

        if (!this._followUpRecord) {
            this._followUpRecord = new FollowUpRecordModel();
        }

        if (!this._followUpLifeStatuses){
            this._followUpLifeStatuses = new FollowUpLifeStatuses();
        }

        const screenParams: ScreenParamsModel = { EntityId: kanbanRecordModel.EntityId, RecordId: kanbanRecordModel.RecordId };

        this._followUpRecord.CurrentLifeStatus = result.Child.CurrentLifeStatus;

        this._lifeStatuses = result.Child;

        const showCheckLists = _.any(this._lifeStatuses.CheckLists, checkList => checkList.CheckItems.length > 0);

        if (showCheckLists) {
            this.ShowCheckLists(result,
                this._isFlowFolder && !this._screen().IsEditScreen,
                screenParams,
                flowFolderModel,
                kanbanRecordModel,
                nextLifeStatus);
            return;
        } else {
            this.TryToApproveLifeStatus(result, kanbanRecordModel, kanbanStep);
        }

    }

    private ShowCheckLists(result: LifeStatusesGeneralModel,
                           isFlowFolder: boolean,
                           screenParams,
                           flowFolderModel: FlowFolderModel,
                           kanbanRecordModel: RecordModel,
                           nextLifeStatus: LifeStatusSimpleModel) {

        const actionSubject = result.Child.ActionSubjectRecord;
        const checkLists = result.Child.CheckLists;
        const currentLifeStatus = result.Child.CurrentLifeStatus;

        this._checkList = new ActionCheckList({
            EntityId: actionSubject.EntityId,
            RecordId: actionSubject.RecordId,
            FollowUpMode: true,
            CurrentLifestatus: result.Child.CurrentLifeStatus,
            KanbanMod: true
        });
        this._checkList.On('KANBAN_CHECKLIST_CANCEL',
            this,
            eventArgs => {
                this.CancelUpdateProcess();
            });
        this._checkList.On('KANBAN_CHECKLIST_SAVE',
            this,
            eventArgs => {
                const lifeStatusesSerializedData = eventArgs.data.serializedData;

                if (nextLifeStatus.ApprovalName === 'Password' || nextLifeStatus.ApprovalName === 'Yes/No'){
                    this.AproveLifeStatus({
                        currentLifeStatus: currentLifeStatus,
                        nextLifeStatus: nextLifeStatus
                    }, kanbanRecordModel, nextLifeStatus.Id, lifeStatusesSerializedData[0].CheckItems);

                } else {
                    this.UpdateStatus(kanbanRecordModel,
                                        lifeStatusesSerializedData[0].LifeStatusId,
                                        lifeStatusesSerializedData[0].CheckItems,
                                null);
                }
            });

        this._checkList.ProvideData(checkLists);
        this._checkList.InitFlowFolder(isFlowFolder, screenParams.EntityId, flowFolderModel);
        this._checkList.Show();
    }

    AfterDropCase(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext) {
        var record = item as RecordModel;
        var step = targetContext as KanbanStep;
        this._dragulaState = {
            item: item,
            sourceIndex: sourceIndex,
            sourceItems: sourceItems,
            sourceContext: sourceContext,
            targetIndex: targetIndex,
            targetItems: targetItems
        };
        if (record) {
            let action = _.first(record.Actions),
                isActionItems = !!(step.ActionItems && step.ActionItems.length),
                sourceMultipleActions = sourceContext.Model.MultipleActions,
                oneAction = item.Actions.length == 1;

            if (isActionItems && (oneAction ? oneAction : !sourceMultipleActions) ) {
                this.ShowCheckListKanban(record, step);

            } else if (action.ActionsEntityId) {

                FollowUpStore.GetStatuses(
                    {
                        ActionEntityId: action.ActionsEntityId,
                        EntityId: record.EntityId,
                        RecordId: record.RecordId
                    }).then(result => {
                        this.TryToApproveLifeStatus(result, record, step);
                });
            }
        }

    }

    CancelUpdateProcess() {
        this._dragulaState.sourceItems.splice(this._dragulaState.sourceIndex, 1);
        this._dragulaState.sourceItems.splice(this._dragulaState.sourceIndex, 0, this._dragulaState.item);

        BlockUI.Unblock();
    }

    TryToApproveLifeStatus(lifeStatusesModel: LifeStatusesGeneralModel, record: RecordModel, step: KanbanStep) {
        this._lifeStatuses = lifeStatusesModel.Child;
        const nextLifeStatus = _.find(lifeStatusesModel.Child.LifeStatuses, (lifeStatus)=> lifeStatus.Id === step.Model.Id);

        if (this._lifeStatuses.LifeStatuses.length === 0) {
            new Notifier().Warning(NOTIFICATIONS.NEXT_STATUS_NOT_AVAILABLE);
        } else {
            this.AproveLifeStatus({
                currentLifeStatus: lifeStatusesModel.Child.CurrentLifeStatus,
                nextLifeStatus: nextLifeStatus
            }, record, step.Id);
        }
    }

    AproveLifeStatus(aprovalRelatedData, record, stepId, checkItems?: ICheckItemSerializedValue[]) {
        const confirmationText = CONFIRMATIONS.ARE_SURE_TO_CHANGE_LIFESTATUS
            .replace('{fromstatus}', aprovalRelatedData.currentLifeStatus.Name)
            .replace('{tostatus}', aprovalRelatedData.nextLifeStatus.Name);

        const checkItemsValue:ICheckItemSerializedValue[] = (checkItems && checkItems.length ? checkItems : null);

        const confirmationDialog = new ConfirmationDialog({
            Text: confirmationText,
            Type: ConfirmationTypes.Question
        });

        confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED,
            this,
            () => {
                this.UpdateStatus(record, stepId, checkItemsValue);
            });

        confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.DISCARD_SELECTED,
            this,
            () => {
                this.SaveCheckList();
                this.CancelUpdateProcess();
            });
        const approvalName = aprovalRelatedData.nextLifeStatus.ApprovalName || 'Off';
        switch (approvalName) {
            case 'Off':
                this.UpdateStatus(record, stepId, checkItemsValue);
                break;
            case 'Password':
                const passwordPrompt = new PromptDialog(
                    {
                        Type: Types.Password,
                        Label: CONFIRMATIONS.PLEASE_TYPE_YOUR_PASSWORD_TO_CONFIRM,
                        Value: '',
                        MinHeight: 200,
                        Required: true,
                        RequiredErrorMessage: LABELS.PASSWORD + ' ' + NOTIFICATIONS.IS_REQUIRED,
                        ShowNotification: false,
                        CloseOnSave: false
                    });
                passwordPrompt.Show();

                passwordPrompt.On('Save', this,
                    (eventargs) => {
                        const password = eventargs.data.value;
                        if (password) {
                            BlockUI.Block();

                            FollowUpStore.ConfirmPassword({password: password})
                                .always(() => {
                                    BlockUI.Unblock();
                                })
                                .then((response) => {
                                    if (response) {
                                        passwordPrompt.Hide(false);
                                        this.UpdateStatus(record, stepId, checkItemsValue, password);
                                    } else {
                                        passwordPrompt.ExternalInvalidActionHandling(NOTIFICATIONS.INCORRECT_PASSWORD);
                                        this.SaveCheckList();
                                        this.CancelUpdateProcess();
                                    }
                                })
                                .fail(error => {
                                    new Notifier().Failed(error);
                                });
                        }
                    }
                );

                passwordPrompt.On('Cancel', this, () => {
                    this.SaveCheckList();
                    this.CancelUpdateProcess();
                });
                break;

            case 'Yes/No':
                confirmationDialog.Show();
                break;
        }
    }

    UpdateStatus(record: RecordModel, stepId, checkItems?: ICheckItemSerializedValue[], password?) {
        const requestModel: IUpdateStatusRequestModel = {
            SubjectEntityId: record.EntityId,
            SubjectRecordId: record.RecordId,
            NewStatusId: stepId,
            Actions: record.Actions,
            PreviousLifeStatusId: record.LifestatusId
        };

        if (checkItems && checkItems.length) {
            requestModel.CheckItems = checkItems;
        }

        if (password) requestModel.ConfirmationPassword = password;

        const el = this._elObservable();
        BlockUI.Block({Target: el});
        KanbanStore.UpdateStatus(requestModel)
            .always(() => {
                BlockUI.Unblock(el);
            })
            .then(() => {
                this.LoadStatuses();
                new Notifier().Success(NOTIFICATIONS.STATUS_UPDATED);
                record.LifestatusId = stepId;
                this._dragulaState.sourceItems.splice(this._dragulaState.sourceIndex, 1);
                this._dragulaState.targetItems.splice(this._dragulaState.targetIndex, 0, this._dragulaState.item);
            }).fail((err) => {
            new Notifier().Failed(err.message);
        });

    }

    SaveCheckList(){
        if (this._checkList){
            this._checkList.SaveCheckList();
        }
    }

    set Height(height: number) {
        this._height(height);
    }

    private GetConsultScreenType(): ScreenTypes {
        return ScreenTypes.ConsultScreen;
    }

    private GetEditScreenType(): ScreenTypes {
        return ScreenTypes.EditScreen;
    }

    private GetScreenTypeFromProperty(propertyName: string): ScreenTypes {
        const screenTypeName = this._generalProperties.GetPropertyValue(propertyName);
        if (screenTypeName) {
            if (screenTypeName.Value == 'ConsultScreen') {
                return ScreenTypes.ConsultScreen
            }

            if (screenTypeName.Value == 'EditScreen') {
                return ScreenTypes.EditScreen
            }
        }

        return ScreenTypes.EditScreen;
    }

    OpenConsultScreen(record: RecordModel) {
        this.OpenScreen(record.EntityId,
            record.TypeId,
            record.RecordId,
            null,
            DataModes.Default,
            record,
            this.GetConsultScreenType());
    }

    OpenEditScreen(record: RecordModel) {
        this.OpenScreen(record.EntityId,
            record.TypeId,
            record.RecordId,
            null,
            DataModes.Default,
            record,
            this.GetEditScreenType());
    }

    OpenActionRecordScreen(record: RecordModel, action: ActionModel) {
        let followUpRecord = new FollowUpRecordModel();
        followUpRecord.Actions = record.Actions;
        this.OpenScreen(action.ActionsEntityId,
            action.ActionTypeId,
            action.ActionRecordId,
            followUpRecord,
            DataModes.Default,
            record,
            this.GetScreenTypeFromProperty(PROPERTIES.ACTION_SCREEN_TYPE));
    }

    async OpenScreen(
        entityId: number,
        recordTypeId: number,
        recordId: number,
        record: FollowUpRecordModel,
        mode: DataModes,
        activeCard: RecordModel,
        screenType: ScreenTypes,
        openMailTab?: boolean
    ) {
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
        BlockUI.Block();

        if (screenType == ScreenTypes.EditScreen) {
            LockManager.Instance.TryLock(entityId, recordId)
                .then(() => {
                    screenManager.IsTypeTransformationRequired(entityId, recordId)
                        .then((result: any) => {
                            if (result) {
                                const typeScreen = new TypeScreen(entityId, recordTypeId, true, false);

                                typeScreen.On("TYPE_SELECTED",
                                    this,
                                    (eventArgs) => {
                                        UserVarsManager.Instance.RemoveFromRecent(recordId, entityId, recordTypeId);

                                        const typeId = eventArgs.data.TypeId;
                                        const typeName = eventArgs.data.TypeName;

                                        UserVarsManager.Instance.AddRecent(entityId, recordId, typeId);

                                        this.ShowEditScreen(entityId, typeId, recordId, record, mode, activeCard, typeName, openMailTab)
                                    });

                                typeScreen.Show();
                            } else {
                                this.ShowEditScreen(entityId, recordTypeId, recordId, record, mode, activeCard, null, openMailTab)
                            }
                        });
                })
                .fail(() => BlockUI.Unblock());
        } else if (screenType == ScreenTypes.ConsultScreen) {
            screenManager.GetScreenByScreenType(
                entityId,
                screenType,
                recordId
            )
                .always(() => {
                    BlockUI.Unblock();
                })
                .then(screen => {
                    screen.ShowInModal();

                    if (openMailTab) {
                        this.OpenMailTab(screen);
                    }

                    if (screen.GetType() === ScreenTypes[ScreenTypes.ConsultScreen]) {
                        PathRunner.Instance.GetUnit(entityId, screen.IsSpecialScreenExist).LoadNewData(recordId);
                    }
                })
                .fail(err => new Notifier().Warning(err.message));
        }
    }

    private async ShowEditScreen(
        entityId: number,
        recordTypeId: number,
        recordId: number,
        record: FollowUpRecordModel,
        mode: DataModes,
        activeCard: RecordModel,
        typeName: string = null,
        openMailTab?: boolean
    ) {
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

        BlockUI.Block();
        screenManager.GetEditScreen({
            EntityId: entityId,
            TableTypeId: recordTypeId,
            TableTypeName: typeName,
            RecordId: recordId,
            DataMode: mode
        }).always(() => {
            BlockUI.Unblock();
        })
            .then(screen => {
                LockManager.Instance.On(LOCK_EVENTS.RELEASED, this, (eventArgs) => {
                    if (eventArgs.data.TableId === entityId && eventArgs.data.RecordId === recordId) {
                        screen.Close();
                    }
                });

                screen.On('MODAL_CLOSE',
                    this,
                    () => {
                        LockManager.Instance.ReleaseLock(entityId, recordId);
                    });

                screen.On('RECORD_SAVED',
                    this,
                    () => {
                        const notifier = new Notifier($(this._elObservable()));
                        notifier.Success(NOTIFICATIONS.RECORD_SAVED);
                        this.LoadStatuses();
                        LockManager.Instance.ReleaseLock(entityId, recordId);
                    });

                screen.On("RECORD_REMOVED",
                    this,
                    (eventArgs) => {
                        const notifier = new Notifier($(this._elObservable()));
                        notifier.Success(NOTIFICATIONS.RECORD_REMOVED);

                        let editScreen = screen as EditScreen;
                        editScreen.Close();

                        LockManager.Instance.ReleaseLock(entityId, recordId);
                        this.LoadStatuses();
                    });

                screen.On('COPY', this, async (eventArgs) => {
                    const copyTableType = eventArgs.data.copyToType || recordTypeId;
                    await this.NewRecord(activeCard, {
                        EntityId: entityId,
                        TypeId: copyTableType,
                        ExampleRecordId: eventArgs.data.recordId
                    }, eventArgs.data.dataMode, eventArgs.data.linkToSource, eventArgs.data.aliasSuffix);
                });


                var followUpControl = screen.GetControlByType(CONTROL_TYPES.ButtonFollowUp) as ButtonFollowUp;

                if (followUpControl) {
                    followUpControl.SetRecord(record);

                    screen.On("FOLLOWUP_RECORD",
                        this,
                        (eventArgs) => {
                            screen.Close();

                            const followUpRecordModel = followUpControl.GetRecord();
                            const currentLifestatus = followUpRecordModel ? followUpRecordModel.CurrentLifeStatus : null;

                            BlockUI.Block();
                            if (followUpRecordModel && (followUpRecordModel.LifeStatusSort === LIFE_STATUS_GROUPS.RETIRED
                                || followUpRecordModel.LifeStatusNoActionNode
                                || (currentLifestatus
                                    && (currentLifestatus.FollowupModeName === FollowupModes.DIRECT_STATUS_UPDATE
                                        || currentLifestatus.FollowupModeName === FollowupModes.EDIT_CURRENT)))) {
                                BlockUI.Unblock();
                                return;
                            }

                            screenManager.GetEditScreen({
                                EntityId: entityId,
                                TableTypeId: recordTypeId,
                                RecordId: recordId,
                                LoadAsExample: recordId > 0,
                                DataMode: DataModes.FollowUp,
                                SubjectLifestatusId: followUpRecordModel ? followUpRecordModel.LifeStatusId : null
                            }).always(() => {
                                BlockUI.Unblock();
                            })
                                .then((screen: EditScreen) => {
                                    this.EditScreenInFollowUpMode(screen);
                                }).fail(error => {
                                new Notifier($(this._elObservable())).Warning(error.message);
                            });
                        });
                }

                screen.ShowInModal();

                if (openMailTab) {
                    this.OpenMailTab(screen);
                }
            }).fail(err => new Notifier().Warning(err.message));
    }

    Search() {
        this._steps([]);

        this.InitDragula();

        const searchDelegate = (record: RecordModel) => this.RecordMatchesByCard(record) || this.RecordMatchesByAction(record) || this.RecordMatchesByLink(record);
        this.SearchRecordsBySearchTerm(searchDelegate);

        this._hasData(this._steps().length > 0);
    }

    private SearchRecordsBySearchTerm(filterDelegate: (record: RecordModel) => boolean) {
        if (!this._searchString()) {
            this._steps(this._originSteps);
            return;
        }

        const newSteps = [];

        for (const step of this._originSteps) {
            const newStep = step.Copy();
            newStep.ClearRecords();

            for (const record of step.Records()) {
                const match = filterDelegate(record);
                if (match) {
                    newStep.AddRecord(record);
                }
            }

            newSteps.push(newStep);
        }

        this._steps(newSteps);
    }

    private RecordMatchesByCard(record: RecordModel) {
        return record.Name && record.Name.toUpperCase().indexOf(this._searchString().toUpperCase()) > -1;
    }

    private RecordMatchesByAction(record: RecordModel) {
        return record.Actions
            .filter(a => !!a.ActionName)
            .filter(a => a.ActionName.toUpperCase().indexOf(this._searchString().toUpperCase()) > -1).length > 0;
    }

    private RecordMatchesByLink(record: RecordModel) {
        for (const link of record.MainLinks) {
            const match = link.RecordName && link.RecordName.toUpperCase().indexOf(this._searchString().toUpperCase()) > -1;
            if (match) {
                return true;
            }
        }

        for (const user of record.Users) {
            const match = user.UserName && user.UserName.toUpperCase().indexOf(this._searchString().toUpperCase()) > -1;
            if (match) {
                return true;
            }
        }

        return false;
    }

    private EditScreenInFollowUpMode(editScreen: EditScreen) {
        LockManager.Instance.ReleaseAllLocks();

        editScreen.IsDataFromExample = true;
        editScreen.UseLinking = true;
        editScreen.ParentRecordId = editScreen.GetRecordId();

        editScreen.On('FOLLOWUP',
            this,
            (eventArgs) => {
                LockManager.Instance.ReleaseAllLocks();
                this.LoadStatuses();
            });

        editScreen.On("RECORD_SAVED",
            this,
            (eventArgs) => {
                const notifier = new Notifier($(this._elObservable()));
                notifier.Success(NOTIFICATIONS.RECORD_CREATED);
                this.LoadStatuses();
                LockManager.Instance.ReleaseAllLocks();
            });

        editScreen.On("RECORD_REMOVED",
            this,
            (eventArgs) => {
                const notifier = new Notifier($(this._elObservable()));
                notifier.Success(NOTIFICATIONS.RECORD_REMOVED);

                LockManager.Instance.ReleaseAllLocks();
                this.LoadStatuses();
            });

        editScreen.ShowInModal();
    }

    SelectAction(card: RecordModel) {
        const globalManager = GlobalManager.Instance;
        const actionGlobal = globalManager.GetGlobal(GLOBALS.ACTION_TABLE);
        const parentTypeId = this.SubjectEntityName === actionGlobal ? card.TypeId : 0;
        const typeScreen = new TypeScreen(null, parentTypeId, false, false, actionGlobal, [ActionTypes.ACTION]);
        typeScreen
            .On("TYPE_SELECTED", this, async eventArgs => { await this.NewRecord(card, eventArgs.data); })
            .On("TYPES_NOT_FOUND", this, eventArgs => new Notifier($(this._elObservable())).Warning(eventArgs.data.Message || NOTIFICATIONS.SUB_TYPE_NOT_FOUND));
        typeScreen.Show();
    }

    private async NewRecord(activeCard: RecordModel, newRecordSettings: any, dataMode: DataModes = DataModes.Default, linkToSource: boolean = false, aliasSuffix: string = '') {
        const actionEntityId = newRecordSettings.EntityId;
        const typeId = newRecordSettings.TypeId;
        const kindId = newRecordSettings.KindId;
        const exampleRecordId = newRecordSettings.ExampleRecordId;

        const actionSubjectRecord = new ActionSubjectRecordModel(activeCard.EntityId, activeCard.RecordId);

        if (actionEntityId) {
            BlockUI.Block();
            const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
            screenManager.GetEditScreen({
                EntityId: actionEntityId,
                TableTypeId: typeId,
                KindId: kindId,
                RecordId: exampleRecordId,
                LoadAsExample: exampleRecordId > 0,
                SubjectLifestatusId: activeCard.LifestatusId,
                ActionSubjectRecord: actionSubjectRecord,
                DataMode: dataMode,
                AliasSuffix: aliasSuffix
            }).always(() => {
                BlockUI.Unblock();
            }).fail(error => {
                let notifier = new Notifier($(this._elObservable()));
                notifier.Warning(error.message);
            }).then((screen) => {
                const editScreen = screen as EditScreen;

                screen.On('LINK_LIST_DATA_LOADED', this, eventArgs => {
                    if (dataMode === DataModes.Default) {
                        let linkListControl = eventArgs.data.Control as LinkList;
                        const relationModel = _.find(linkListControl.DataModel().Entities(), relatedEntity => {
                            return relatedEntity.EntityId === activeCard.EntityId;
                        });

                        if (relationModel) {
                            let newRelation = new NewRelationModel();
                            newRelation.Name = activeCard.LinkName;
                            newRelation.Id = activeCard.RecordId;
                            newRelation.IsMain = true;
                            newRelation.TypeName = this._selectedTypeObservable().Name;
                            linkListControl.AddRecord(activeCard.RecordId, relationModel, newRelation);
                        }
                    }

                });
                editScreen.IsDataFromExample = exampleRecordId > 0;
                editScreen.SaveImmediately = true;
                editScreen.LinkToSource = linkToSource;

                screen.On('RECORD_SAVED', this, eventArgs => this.LoadStatuses());
                screen.On('COPY', this, async (eventArgs) => {
                    const copyTableType = eventArgs.data.copyToType || typeId;
                    await this.NewRecord(activeCard, {
                        EntityId: actionEntityId,
                        TypeId: copyTableType,
                        ExampleRecordId: eventArgs.data.recordId
                    }, eventArgs.data.dataMode, eventArgs.data.linkToSource, eventArgs.data.aliasSuffix);
                });
                screen.ShowInModal();
            });
        } else {
            var notifier = new Notifier($(this._elObservable()));
            notifier.Failed(NOTIFICATIONS.GRID_SUBJECT_NOT_FOUND);
        }
    }

    private OpenMailTab(screen: IScreen) {
        const tab = screen.GetControl<Tab>(CONTROL_TYPES.Tab);

        if (tab) {
            for (const tabPage of tab.GetSubControls() as TabPage[]) {
                const grid = tabPage.GetSubControls().find(control => control.GetType() === CONTROL_TYPES.Grid);
                if (grid && grid.GetModel().EntityId === this._data.MailEntityId) {
                    tab.SetActiveTabPage(tabPage);
                    break;
                }
            }
        }
    }

    private get UseKanbanSubject() {
        return !!this._kanbanSubjectId();
    }

    private get UseFilterByEntityRecord() {
        return this._screen().IsConsultScreen;
    }

    private get SubjectEntity() {
        return this.UseKanbanSubject
            ? this._kanbanSubjectId()
            : this._screen().GetEntityId();
    }

    private get SubjectEntityName() {
        return this.UseKanbanSubject
            ? this._kanbanSubjectName()
            : this._screen().GetEntityName();
    }

    private get FilterByEntityId() {
        return this.UseFilterByEntityRecord
            ? this._screen().GetEntityId()
            : null;
    }

    private get FilterByRecordId() {
        return this.UseFilterByEntityRecord
            ? this._screen().GetRecordId()
            : null;
    }

    OnPriorityUpdateError() {
        this.LoadStatuses();
    }

    OnMailClick(record: RecordModel) {
        this.OpenScreen(
            record.EntityId,
            record.TypeId,
            record.RecordId,
            null,
            DataModes.Default,
            record,
            this.GetEditScreenType(),
            true
        );
    }

    AfterRender(el: Array<HTMLElement>){
        this._elObservable(el[0].parentElement.parentElement.parentElement);
        this.ListenForStatusesLoad();

        ko.utils.domNodeDisposal.addDisposeCallback(el[0], () => {
            PubSub.unsubscribe('KANBAN_FULLSCREEN');
        });
    }

    GetRootDefaultTemplateName(): string {
        return 'Core/Controls/Kanban/Templates/Default/DefaultKanban';
    }

    dispose() {
        this._selectedTypeSubscription.dispose();
        this._selectedUsersSubscription.dispose();
        this._showPlannedSubscription.dispose();
        this._viewIdSubscription.dispose();
        this._showTypeErrorSubscription.dispose();
    }
}

ko.components.register('default-kanban', {
    viewModel: DefaultKanban,
    template: `<div class="js-default-kanban__component" data-bind="template: { name: GetRootDefaultTemplateName.bind($data), afterRender: $data.AfterRender.bind($data) }"></div>`
});