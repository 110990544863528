import * as ko from 'knockout';

import { BaseControl, IControlValue} from 'Core/Controls/BaseControl/BaseControl';
import {IControlParam} from 'Core/Screens/IScreen';
import {RenderModes} from "Core/Constant";

import { RssFeedStore } from "Core/Controls/RssFeed/Stores/RssFeedStore";
import { Posts } from "Core/Controls/RssFeed/Models/Post";
import { IRssFeedRequestModel } from "Core/Controls/RssFeed/Models/IRssFeedRequestModel";

import RssFeedConfigJson from 'Core/Controls/RssFeed/Configs/rssfeed-config.json';
import {GeneralProperties} from 'Core/GeneralProperties/GeneralProperties';

import ViewTemplate from 'Core/Controls/RssFeed/Templates/View.html';
import ToolBarTemplate from 'Core/Controls/RssFeed/Templates/ToolBar.html';
import DesignTemplate from 'Core/Controls/RssFeed/Templates/Design.html';

ko.templates['Core/Controls/RssFeed/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/RssFeed/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/RssFeed/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/RssFeed/Templates/Design'] = DesignTemplate;

export class RssFeed extends BaseControl {
	private _url: KnockoutObservable<string>;
	private _rssPosts: KnockoutObservable<any>;
	private _labelStyle: KnockoutObservable<any>;
	private _textInputStyle: KnockoutObservable<any>;

	constructor(params: IControlParam) {
		super(params, RssFeedConfigJson);

		this._labelStyle = ko.observable(null);
		this._textInputStyle = ko.observable(null);
		this._url = ko.observable('');
		this.Init();
		this.BindEvents();
		this._rssPosts = ko.observable(null);
		this._model.subscribe(() => {
			if (this.Properties) {
				this.ApplyProperties();
			}
		});
	}

	Init(): void {
		this._url = ko.observable('');
		this.ApplyProperties();
		if (this._renderMode() !== RenderModes.Design && this._renderMode() !== RenderModes.ToolBar) {
			this.LoadData();
		}
	}

	SetValue(value: IControlValue): void {
	}

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}

	private BindEvents() {
	
	}

	private SetUrlProperty() {
		if (this.Properties) {
			if (this.Properties.Url) {
				_.each(this.Properties.Url.Properties, (property: any) => {
					if (property.Url) {
						this._url(property.Url);
					}
				});
			}
		}
	}

	ApplyProperties() {
		if (this.Properties) {
			//Label
			if (this.Properties.Label) {
				var labelStyle = { backgroundColor: null, color: null };
				_.each(this.Properties.Label.Properties, (property: any) => {
					if (property.BackgroundColor) {
						labelStyle.backgroundColor = property.BackgroundColor;
					}
					if (property.Color) {
						labelStyle.color = property.Color;
					}
				});

				this._labelStyle(labelStyle);
			}

			//TextInput
			if (this.Properties.TextInput) {
				var textInputStyle = { backgroundColor: null };
				_.each(this.Properties.TextInput.Properties, (property: any) => {
					if (property.BackgroundColor) {
						textInputStyle.backgroundColor = property.BackgroundColor;
					}
				});

				this._textInputStyle(textInputStyle);
			}

			//Url
			this.SetUrlProperty();
		}
	}

	private LoadData() {
		RssFeedStore.GetRssFeed({ Url: this._url()})
			.then((data: Posts) => {
				this._rssPosts(data.Posts);
				let linkArray = $(this._el).find('a');
				_.each(linkArray, link => {
					$(link).attr('tabindex', '-1');
				});
			});
	}
}