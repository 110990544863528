import { deserialize, deserializeAs } from "libs/cerialize";
export class Posts {
	@deserialize
	Posts: Array<Post>;

	@deserialize
	Title: string;
}
export class Post {
	@deserialize
	Title: string;

	@deserialize
	Body: string;

	@deserialize
	Url: string;

	@deserialize
	DateTimePosted: string;
}