import * as ko from "knockout";

import {
    ConfigStructModel,
    GroupStructModel,
    PropertyStructModel
} from "Core/GeneralProperties/Models/ConfigStructModel";
import {PropertyManagersFactory} from "Core/GeneralProperties/Utils/PropertyManagersFactory";
import {BaseProperty} from "Core/GeneralProperties/Managers/BaseProperty";
import {SelectProperty} from "Core/GeneralProperties/Managers/SelectProperty/SelectProperty";
import {IControl} from 'Core/Controls/IControl';
import {ScreenTypes} from "Core/Common/Enums/ScreenTypes";
import enumerable from '../../Common/Decorators/EnumerableDecorator';
import { Guid } from "Core/Common/Guid";

export class ConfigModel {
    Groups: Array<GroupModel>;

    constructor(configStruct: ConfigStructModel, properties: any, control: IControl) {
        if (properties) {
            this.Groups = this.FilterGroups(configStruct.Groups, control).map(groupStruct => {
                let group = properties[groupStruct.Name];
                return new GroupModel(groupStruct, group, control);
            });
        } else {
            this.Groups = this.FilterGroups(configStruct.Groups, control).map(groupStruct => new GroupModel(groupStruct, null, control));
        }
    }

    SetPropertyEnabled(typeName: string, enabled: boolean) {
        let property = this.GetProperty(typeName);
        if (property) {
            property.PropertyManager.SetEnabled(enabled);
        }
    }

    ResetProperty(typeName: string) {
        let property = this.GetProperty(typeName);
        if (property) {
            property.PropertyManager.Reset();
        }
    }

    GetPropertyDefaultValue(typeName: string) {
        let property = this.GetProperty(typeName);
        if (property) {
            return property.PropertyManager.GetDefaultValue();
        }
    }

    GetPropertyValue(typeName: string, groupName: string = null) {
        let property = this.GetProperty(typeName, groupName);
        return property ? property.Value : undefined;
    }

    GetPropertyManager(typeName: string, groupName: string = null): BaseProperty {
        let property = this.GetProperty(typeName, groupName);
        return property ? property.PropertyManager : undefined;
    }

    GetPropertyName(typeName: string) {
        let property = this.GetProperty(typeName);
        return property ? property.Name : undefined;
    }

    SetPropertyValue(type: string, value: any) {
        let property = this.GetProperty(type);

        if (property) {
            property.Value = value;
        }
    }

    HasProperty(typeName: string) {
        let property = this.GetProperty(typeName);

        return !!property;
    }

    SetPropertyOptions(type: string, options: any) {
	    let property = this.GetProperty(type);

	    if (property) {
			property.Options = options;
	    }
    }

    Serialize(): string {
        let serializedConfig = {};

        this.Groups.forEach(group => {
            let serializedGroup = serializedConfig[group.Name] = {Properties: []};

            let serializedProperties = group.Properties.map(property => {
                let serializedProperty = {
                    PropertyManager: property.PropertyManager.Type,
                    [property.Type]: property.Value || property.PropertyManager.GetDefaultValue()
                };

                return serializedProperty;
            });

            serializedGroup.Properties = serializedProperties;
        });

        return JSON.stringify(serializedConfig);
    }

    IsValid(): boolean {
        return this.Groups.every(group => group.IsValid());
    }

    IsValidToSave(): boolean {
        return this.Groups.every(group => group.IsValidToSave());
    }

    GetPropertiesErrors(): string[] {
        let propertiesErrors = [];
        _.each(this.Groups, group => propertiesErrors = propertiesErrors.concat(group.GetPropertiesErrors()));

        const uniqueErrors = _.uniq(propertiesErrors);
        return uniqueErrors;
    }

    private FilterGroups(groups: GroupStructModel[], control: IControl) {
        if (control && control.GetForm()) {
            const screenType = control.GetForm().GetScreen().GetType();
            const parentControlType = control.GetParentControl();
            return groups
                .filter(group => !group.Screens || group.Screens.length === 0 || group.Screens.indexOf(screenType) > -1)
                .filter(group => !group.ParentControls || group.ParentControls.length === 0 || parentControlType && group.ParentControls.indexOf(parentControlType.GetType()) > -1);
        }

        return groups;
    }

    private GetProperty(typeName: string, groupName: string = null): PropertyModel {
        let propertyGroup =
            _.find(this.Groups,
                (group: GroupModel) => (group.Name === groupName || groupName == null) && _.any(_.filter(group.Properties, (property: PropertyModel) => property.Type === typeName)));
        if (!propertyGroup) {
            return null;
        }

        let groupProperties = _.map(propertyGroup.Properties, (property: PropertyModel) => property);

        let property = _.find(groupProperties, (property: PropertyModel) => property.Type === typeName) || null;

        return property;
    }
}

export class GroupModel {
    Id: string;
    Name: string;
    Properties: Array<PropertyModel>;
    Expanded: KnockoutObservable<boolean>;

    constructor(groupStruct: GroupStructModel, groupData: any, control: IControl) {
        this.Id = Guid.NewGuid();
        this.Name = groupStruct.Name;
        this.Properties = this.MergePropertyStructWithData(groupStruct, groupData, control);
        this.Expanded = ko.observable(false);
    }

    private MergePropertyStructWithData(groupStruct: GroupStructModel, groupData: any, control: IControl) {
        let properties = groupStruct.Properties.map(propertyStruct => {
            if (groupData) {
                let property = this.GetProperty(groupData.Properties, propertyStruct.Type);

                if (property) {
                    let propertyValue = property[propertyStruct.Type];
                    return new PropertyModel(propertyStruct, control, propertyValue);
                }
            }

            return new PropertyModel(propertyStruct, control, null);
        });

        return properties;
    }

    private GetProperty(groupProperties: any, propertyName: string) {
        return _.find(groupProperties, property => property.hasOwnProperty(propertyName));
    }

    public IsValid(): boolean {
        var result = this.Properties.every(property => property.IsValid);
        this.Expanded(!result);
        return result;
    }

    public IsValidToSave(): boolean {
        var result = this.Properties.every(property => property.IsValidToSave);
        this.Expanded(!result);
        return result;
    }

    public GetPropertiesErrors(): string[] {
        return _.map(this.Properties, property => property.ErrorMessage);
    }

    Toggle() {
        this.Expanded(!this.Expanded());
    }
}

export class PropertyModel {
    Name: string;
    Type: string;
    ValueRequired: boolean;

    private _propertyManager: KnockoutObservable<BaseProperty>;
    private _control: IControl;
    private _property: PropertyStructModel;

    private _value: KnockoutComputed<any>;
    private _propertyValue: any;

    constructor(property: PropertyStructModel, control: IControl, propertyValue?: any) {
        this.Name = property.Name;
        this.Type = property.Type;
        this.ValueRequired = property.ValueRequired;

        this._control = control;
        this._property = property;
        this._propertyManager = ko.observable(null);


        this._propertyValue = propertyValue == null ? property.DefaultValue : propertyValue;

        this._value = ko.computed(() => {
                if (this._propertyManager()) {
                    return this.PropertyManager.Value();
                }
                return this._propertyValue;

            }, this
        );
    }

    CheckForPropertyManager(getData: boolean = true) {
        if (!this._propertyManager()) {
            const manager = PropertyManagersFactory.GetPropertyManager(this._property, this._propertyValue, this._control, getData);
            manager.OnInit();

            this._propertyManager(manager);
        }
    }

    @enumerable get PropertyManager(): BaseProperty {
        this.CheckForPropertyManager();

        return this._propertyManager();
    }

    @enumerable get PropertyManagerForSave(): BaseProperty {
        this.CheckForPropertyManager(false);

        return this._propertyManager();
    }

    @enumerable   get IsValid(): boolean {
        return this.PropertyManager.IsValid();
    }

    @enumerable   get IsValidToSave(): boolean {
        return this.PropertyManagerForSave.IsValid();
    }

    @enumerable get ErrorMessage(): string {
        return this.PropertyManager.ErrorMessage();
    }

    @enumerable get Value(): any {
        return this._value();
    }

    set Value(value: any) {
        this._propertyValue = value;
	}

    @enumerable get Options(): any {
		return this._property.Options;
    }

    set Options(options: any) {
		const property = this.PropertyManager as SelectProperty;
		if (property) {
			property.RewriteOptions(options);
		}
    }
}