import {ChartDataModel} from "Core/Controls/Chart/Models/ChartDataModel";

import {FIELD_TYPES} from "Core/Constant";

import Highcharts from "highcharts";
import Highcharts3d from "highcharts/highcharts-3d";
import HighchartsCylinder from "highcharts/modules/cylinder";
import HighchartsFunnel3d from "highcharts/modules/funnel3d";

Highcharts3d(Highcharts);
HighchartsCylinder(Highcharts);
HighchartsFunnel3d(Highcharts);

import {ChartBuilder} from "./ChartBuilder"
import {IChartBuilderParams} from "../IChartBuilderParams";
import {ChartAxisInfoModel} from "Core/Controls/Chart/Models/ChartAxisInfoModel";
import {ChartTypeEnum} from "../Enums/ChartTypeEnum";

export class Funnel3DBuilder extends ChartBuilder {
    private  _mainAxis: ChartAxisInfoModel;

    constructor(params: IChartBuilderParams) {
        super(params);
    }

    RenderChart(options: ChartDataModel) {
        const self = this;
        this._mainAxis = this.GetMainAxis(options.XAxisInfoModel, options.YAxisInfoModel);

        const series = this.GetSeries(options);

        Highcharts.chart(
            this._wrapperId,
            {
                chart: {
                    type: ChartTypeEnum.Funnel3D,
                    options3d: {
                        enabled: true,
                        alpha: 10,
                        depth: 50,
                        viewDistance: 50
                    }
                },
                title: {
                    text: this._chartTitle
                },
                tooltip: {
                    formatter() {
                        return self.GetToolTip(options, this.point);
                    }
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true,
                            formatter: function() {
                                return self.GetDataLabel(options, this.point);
                            },
                            allowOverlap: true,
                            y: 10
                        }
                    }
                },
                series: series
            },
    () => {}
        );
    }

    GetSeries(options: ChartDataModel) {
        let series = [];
        const points = options.Points;

        if (!points || !_.any(points)) {
            return series;
        }

        if (this._mainAxis === options.XAxisInfoModel) {
            let data = [];
            _.forEach(_.groupBy(points, point => {
                    return point.Line;
                }),
                groupedPoints => {
                    const line = _.first(groupedPoints).Line ? _.first(groupedPoints).Line : options.XAxisInfoModel.AxisDefaultName;

                    let categoryMax = Math.max(...groupedPoints.map(item => this.GetFormattedValue(item.XAxis, options.XAxisInfoModel.FieldType)));
                    data.push([line, categoryMax]);
                });
            series.push({ type: ChartTypeEnum.Funnel3D, data: data });
        }
        else {
            let data = [];
            _.forEach(_.groupBy(points, point => {
                    return point.Line;
                }),
                groupedPoints => {
                    const line = _.first(groupedPoints).Line ? _.first(groupedPoints).Line : options.YAxisInfoModel.AxisDefaultName;

                    let categoryMax = Math.max(...groupedPoints.map(item => this.GetFormattedValue(item.YAxis, options.YAxisInfoModel.FieldType)));
                    data.push([line, categoryMax]);
                });
            series.push({ type: ChartTypeEnum.Funnel3D, data: data });
        }

        return series;
    }

    private GetMainAxis(xAxisModel: ChartAxisInfoModel, yAxisModel: ChartAxisInfoModel){
        return xAxisModel.AxisExist && xAxisModel.FieldType !== FIELD_TYPES.Text
            ? xAxisModel : yAxisModel;
    }

    private GetToolTip(options: ChartDataModel, point) {
        return `<span style="color:${point.color}"></span>
                ${point.name}: ${this.ApplyLabelFormatter(point.y, this._mainAxis.FieldType, this._mainAxis.FormatName)}`;
    }

    private GetDataLabel(options: ChartDataModel, point) {
        return `<b>${point.name}</b>
            (${this.ApplyLabelFormatter(point.y, this._mainAxis.FieldType, this._mainAxis.FormatName)})`;
    }
}