import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';

export interface IStartTransactionDto {
	Id: string;
}

export interface ICommitTransactionDto {
	Id: string;
}

export interface IRollbackTransactionDto {
	Id: string;
}

export class TransactionApi {

	static  Start(params: IStartTransactionDto): P.Promise<number>{
		let deferredResult = P.defer<number>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/TransactionApi/Start',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
			})
			.fail(error => deferredResult.reject(error));

		return deferredResult.promise();
	}

	static  Commit(params: ICommitTransactionDto): P.Promise<number>{
		let deferredResult = P.defer<number>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/TransactionApi/Commit',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
			})
			.fail(error => deferredResult.reject(error));

		return deferredResult.promise();
	}

	static  Continue(params: IRollbackTransactionDto): P.Promise<number>{
		let deferredResult = P.defer<number>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/TransactionApi/Continue',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
			})
			.fail(error => deferredResult.reject(error));

		return deferredResult.promise();
	}

	static  Rollback(params: IRollbackTransactionDto): P.Promise<number>{
		let deferredResult = P.defer<number>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/TransactionApi/Rollback',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(data);
			})
			.fail(error => deferredResult.reject(error));

		return deferredResult.promise();
	}
}