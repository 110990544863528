import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize} from 'libs/cerialize';
import {EntityRelationshipsMetadataModel} from 'Core/Controls/Grid/Models/GridDataModel/Metadata/EntityRelationshipsMetadataModel';

export interface IGetEntityMetadataRequestModel {
	EntityId: number;
};

export interface IGetEntitiesMetadataRequestModel {
	EntityIds: Array<number>;
};

export class EntityMetadataStore {
	
	static GetEntityMetadata(params: IGetEntityMetadataRequestModel): P.Promise<EntityRelationshipsMetadataModel> {

		var deferredResult = P.defer<EntityRelationshipsMetadataModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/VisualQueryBuilderApi/GetEntityMetadata',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				var result = <EntityRelationshipsMetadataModel>Deserialize(data, EntityRelationshipsMetadataModel);
				deferredResult.resolve(result);
			});

		return deferredResult.promise();
	}

	static GetEntitiesMetadata(params: IGetEntitiesMetadataRequestModel): P.Promise<Array<EntityRelationshipsMetadataModel>> {

		var deferredResult = P.defer<Array<EntityRelationshipsMetadataModel>>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/VisualQueryBuilderApi/GetEntitiesMetadata',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				var result = Deserialize(data, EntityRelationshipsMetadataModel);
				deferredResult.resolve(result);
			});

		return deferredResult.promise();
	}

}