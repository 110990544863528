import * as ko from "knockout"
import * as $ from 'jquery';
import * as _ from 'underscore';
import {Tooltip} from 'Core/Common/Tooltip';

class TooltipCollection {

    private _tooltips: Array<any>;

    constructor() {
        this._tooltips = [];
    }

    HasElement(element) {
        return !!this.Get(element);
    }

    Add(element, value) {
        const tooltip = new Tooltip(element, ko.unwrap(value.content), {
            position: value.position,
            outside: value.outside,
            pointer: value.pointer,
            closeOnMouseleave: value.closeOnMouseleave
        });
        tooltip.AddClass(value.addClass);
        this._tooltips.push({
            element: element,
            tooltip: tooltip
        });
    }

    Remove(element) {
        const tooltip = this.Get(element);
        tooltip.tooltip.Destroy();
        const index = this._tooltips.indexOf(tooltip);
        this._tooltips.splice(index, 1);
    }

    SetContent(element, options) {
        const tooltip = this.Get(element).tooltip;
        tooltip.SetContent(ko.unwrap(options.content));
        tooltip.AddClass(options.addClass);
    }

    private Get(element) {
        return _.find(this._tooltips, (tooltip) => tooltip.element === element);
    }
}

export class TooltipExtention {

    private static _collection: TooltipCollection = new TooltipCollection();

    static Init() {
        ko.bindingHandlers.tooltip = {
            init: (element, valueAccessor, allBindings, viewModel, bindingContext) => {
                ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                    this.DestroyTooltip(element);
                });
            },
            update: (element, valueAccessor) => {
                const options = ko.unwrap(valueAccessor()) as ITooltipConfig;
				if (!options || !options.content) {
					this.DestroyTooltip(element);
                    return;
                }

                this.ExtendOptions(options);

                if (options.onlyOnOverflow) {
                    this.RenderOnOverflow(element, options);
                } else {
                    this.Render(element, options);
                }
            }
        };
    }

    private static ExtendOptions(options: ITooltipConfig) {
        if (!options.hasOwnProperty('onlyOnOverflow')) {
            options.onlyOnOverflow = false;
        }
    }

    private static RenderOnOverflow(element: HTMLInputElement, options: ITooltipConfig) {
        const $element = $(element);
        const elementText = $element.text() && $element.text().toLowerCase();
        const tooltipContent = options.content && options.content.toLowerCase();

        if (element.scrollWidth > element.offsetWidth || elementText !== tooltipContent) {
            this.Render(element, options);
        } else {
            this.DestroyTooltip(element);
        }
    }

    private static Render(element, options: ITooltipConfig) {
        if (!this._collection.HasElement(element)) {
            this._collection.Add(element, options);
        } else {
            this._collection.SetContent(element, options);
        }
    }

    private static DestroyTooltip(element) {
        if (this._collection.HasElement(element)) {
            this._collection.Remove(element);
        }
    }
}

export interface ITooltipConfig {
    content: string;
    onlyOnOverflow?: boolean;
    position?: { x: string, y: string },
    outside?: string,
    pointer?: string,
    closeOnMouseleave?: boolean,
    addClass?: string,
}