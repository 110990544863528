import * as ko from 'knockout';
import * as _ from 'underscore';

import {PropertyControl} from "./Controls/PropertyControl";
import {PropertyValue} from "../PropertyValue";

import Template from './Template.html';

export class ProductPropertyGroup {
    HasContent: KnockoutComputed<boolean>;
    AllContentLoaded: KnockoutComputed<boolean>;
    readOnly: KnockoutObservable<boolean>;

    constructor(public Name: string, public PropertyControls: PropertyControl[]) {
        this.HasContent = ko.computed(() => _.any(this.PropertyControls, control => control.HasContent()));
        this.AllContentLoaded = ko.computed(() => _.every(this.PropertyControls, control => control.HasContent()));
        this.readOnly = ko.observable(false);
    }

    GetTemplate() {
        return Template;
    }

    GetPropertyControl(propertyId: number) {
        return _.find(this.PropertyControls, control => control.Id === propertyId);
    }

    GetPropertyValues(): PropertyValue[] {
        return this.PropertyControls.map(control => new PropertyValue(control.Id, control.GetValueForSave(), control.IsValid()));
    }

    HasPropertyValues(): boolean {
        return this.PropertyControls.find(control => control.GetValueForSave() && !control.IsHiddenByAction) != null;
    }

    GetPropertyValuesForPrice(): PropertyValue[] {
        return this.PropertyControls.filter(control => control.PriceInfluence).map(control => new PropertyValue(control.ValueHolder, control.GetValueForPrice(), control.IsValid()));
    }
    GetPropertyValuesForPreview(): PropertyValue[] {
        return this.PropertyControls.filter(control => !control.IsHiddenByAction)
            .map(control => new PropertyValue(control.Id, control.GetValueForPreview(), control.IsValid()));
    }

    ClearPropertyValues() {
        this.PropertyControls.forEach(propertyControl => propertyControl.ClearValue());
    }

    HasPropertyControl(propertyControl: PropertyControl) {
        return this.PropertyControls.indexOf(propertyControl) > -1;
    }

    get IsHiddenByAction(): boolean {
        return _.every(this.PropertyControls, control => control.IsHiddenByAction);
    }

    OnInit() {
        this.PropertyControls.forEach(control => control.OnInit());
    }

    Dispose() {
        this.PropertyControls.forEach(control => control.Dispose());
    }
}