export class NewDataRoleDTOModel {
	DataRole: string;
	FieldCollectionName: string;
	AssigneeId: number;

	constructor(dataRole: string, fieldCollectionName: string, assigneeId: number) {
		this.DataRole = dataRole;
		this.FieldCollectionName = fieldCollectionName;
		this.AssigneeId = assigneeId;
	}
}