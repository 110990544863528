import * as ko from "knockout";
import {Modal} from "Core/Common/Modal";
import {Event} from "Core/Common/Event";
import {LABELS} from "Core/Components/Translation/Locales";
import {InexItem, InexProduct} from "../../Models/InexItem";
import {ProductPart} from "../../Models/ProductPart";

import InexConfirmationPopupTemplate from './Templates/View.html';
import {
    EVENTS, Types
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { PriceList } from "../../Models/PriceList";
import { AlternativePriceModel } from 'Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/AlternativePriceModel';
import { PriceGroup } from "../../Models/PriceGroup";
import { ProductImageViewer } from '../../../../Components/ProductImageViewer/ProductImageViewer';
import { Notifier } from 'Core/Common/Notifier';

ko.templates["./Templates/View"] = InexConfirmationPopupTemplate;

const TEMPLATES = {
    VIEW: "./Templates/View"
};

export interface IInexConfirmationPopupOptions {
    Text: string;
    Type: Types;
    ProductId: number;
    Inclusives: InexItem[];
	SelectedProducts: ProductPart[];
	ProductImageViewer: ProductImageViewer;
    Width?: string;
    Height?: string;
    MinHeight?: number;
    TextConfirm?: string;
    TextDecline?: string;
    Timeout?: number;
    ModalClass?: string;
    ImageSize: number;
    AlternativePriceModel: AlternativePriceModel;
}

export class InexConfirmationPopup extends Event {
    private _labels = LABELS;
    private _modal: Modal;
    private _text: KnockoutObservable<string>;
    Inclusives: KnockoutObservableArray<InexItem>;
    Exclusives: KnockoutObservableArray<InexProduct>;
    SelectedProducts: ProductPart[];
    PriceList: KnockoutObservable<PriceList>;
    private ProductId: number;
    private _textConfirm: KnockoutObservable<string>;
    private _textCancel: KnockoutObservable<string>;
    private _options: IInexConfirmationPopupOptions;
    private _modalClass: string;
    private _imageSizeClassName: string;

    private productImageViewer: ProductImageViewer;
    private MainProduct: InexItem;
    private incompatibleDefaultComponents: InexItem[];
    private InclusivesHiddenOnInit: number[];
    private InclusivesCheckedOnInit: number[];
    private _timeout: number;

    constructor(options: IInexConfirmationPopupOptions) {
        super();
        this._options = options;
        this.Inclusives = ko.observableArray(options.Inclusives);
        this.Exclusives = ko.observableArray([]);
        this.SelectedProducts = options.SelectedProducts;
		this.ProductId = options.ProductId;
		this.productImageViewer = options.ProductImageViewer;
        this.MainProduct = this.Inclusives().find(p => p.IsMain);
        this._timeout = 500;

        this._imageSizeClassName = null;

        const productIds = this.Inclusives().map(p => p.Id);
        const mainProduct = this.MainProduct;

        if (mainProduct) {
            mainProduct.Checked(true);
        }

        this.PriceList = ko.observable(new PriceList());
        this.PriceList().AddAlternativePriceModel(options.AlternativePriceModel);
        this.PriceList().AddMainProductPriceGroup(this.MainProduct.InexInclusiveItem.Id, this.MainProduct.InexInclusiveItem.Name, this.MainProduct.InexInclusiveItem.NameTranslated, this.MainProduct.InexInclusiveItem.Price, 1);
        this.PriceList().Raw(true);

        this.incompatibleDefaultComponents = [];

        const defaultInclusives = this.Inclusives().filter(inclusive => inclusive.Default());

        defaultInclusives.forEach(p => {
            const notRelatedToMain = !mainProduct ||
                (p.DependsOn != mainProduct.Id && p.Id != mainProduct.Id && p.InexParentIds.indexOf(mainProduct.Id) == -1);
            if (notRelatedToMain && p.InexExclusiveItems().filter(ex => !ex.Exchange()).length > 0) {
                const incompatibles = defaultInclusives.filter(inclusive => {

                    const inclusiveNotRelatedToMain = !mainProduct ||
                        (inclusive.DependsOn != mainProduct.Id && inclusive.Id != mainProduct.Id && inclusive.InexParentIds.indexOf(mainProduct.Id) == -1);
                    return inclusiveNotRelatedToMain && inclusive.Default() &&
                        p.InexExclusiveItems().find(exclusive => exclusive.Id == inclusive.Id && exclusive.ParentId == inclusive.DependsOn) != null
                });
                if (incompatibles.length > 0) {
                    incompatibles.forEach(incompatible => {
                        if (this.incompatibleDefaultComponents.find(i => i.Id == incompatible.Id) == null) {
                            this.incompatibleDefaultComponents.push(incompatible);
                        }
                    })
                    incompatibles.push(p);
                }
            }
        });

        if (this.incompatibleDefaultComponents.length > 0) {
            const incompatibleDefaultComponentsids = this.incompatibleDefaultComponents.map(i => i.Id);
            this.Inclusives(this.Inclusives().filter(inclusive => !(incompatibleDefaultComponentsids.indexOf(inclusive.Id) != -1 && inclusive.Default())));
        }


        this.Inclusives().filter(inclusive => inclusive.Default()).forEach(p => {

            const siblings = this.Inclusives().filter(inclusive => inclusive.Id != p.Id &&
                inclusive.GroupId == p.GroupId &&
                inclusive.GroupName == p.GroupName &&
                inclusive.DependsOn == p.DependsOn &&
                !inclusive.Default());

            if (siblings.length > 0) {
                this.RecursiveFilterForDefault(siblings);
            }
        });

        this.Inclusives().forEach(p => {

            if (p.Default() && !p.Checked()) {
                this.HandleDefaultItem(p);
            }
        })

        this.HideNonMainInclusives();
		this.HideNonParentInclusives();

        this.HandleExclusives();

        this.HandlePriceList();

        this.InclusivesHiddenOnInit = _.map(_.filter(this.Inclusives(), inclusive => inclusive.Hidden()), item => item.Id);
        this.InclusivesCheckedOnInit = _.map(_.filter(this.Inclusives(), inclusive => inclusive.Checked()), item => item.Id);

        this.Init();

    }

    ToggleAnimate(item, check: boolean){
        setTimeout(() => {
            item.DelayItem(check);
        }, this._timeout);
    }

    HandleDefaultItem(p: InexItem) {
        const defaultSiblings = this.Inclusives().filter(inclusive => inclusive.Id != p.Id &&
            inclusive.GroupId == p.GroupId &&
            inclusive.GroupName == p.GroupName &&
            inclusive.DependsOn == p.DependsOn && inclusive.Default());

        const parent = this.Inclusives().find(inclusive => inclusive.Id == p.DependsOn);

        const incompatibles = p.InexExclusiveItems().filter(ex => !ex.Exchange() &&
            (this.SelectedProducts.find(s => s.Id == ex.Id) ||
            this.Inclusives().find(inclusive => inclusive.InexInclusiveItem.Id == ex.Id && inclusive.Default())));

        if (defaultSiblings.length == 0 && (parent != null? parent.Checked(): true) && incompatibles.length == 0) {
            this.ToggleCheck(p, this);
            p.Hidden(true);
            this.ToggleAnimate(p, true);
        }
    }

    RecursiveFilterForDefault(itemsToFilter: InexItem[]) {
        itemsToFilter.forEach(item => {

            this.Inclusives(this.Inclusives().filter(i => i.IsMain || !(i.Id == item.Id && i.DependsOn == item.DependsOn)));

            if (this.Inclusives().filter(i => i.Id == item.Id).length == 0){
                this.Inclusives().forEach(i => {
                    if (i.InexParentIds.indexOf(item.Id) != -1) {
                        i.InexParentIds = i.InexParentIds.filter(id => id != item.Id);
                    }
                });

                this.Inclusives(this.Inclusives().filter(i => i.IsMain || i.InexParentIds.length != 0));

            }

            const linkedItems = this.Inclusives().filter(inclusive =>
                inclusive.DependsOn == item.Id && item.Path.every(pathItem => inclusive.Path.indexOf(pathItem) != -1));

            if (linkedItems.length > 0) {
                this.RecursiveFilterForDefault(linkedItems);
            }
        });
    }

    get ImageSizeInExTextClassName(): string{
        return this.GetImageSizeClassName();
    }

    GetImageSizeClassName(){
        switch (this._options.ImageSize) {
            case 60:
                return this._imageSizeClassName = 'imageSize-60';
            case 70:
                return this._imageSizeClassName = 'imageSize-70';
            case 80:
                return this._imageSizeClassName = 'imageSize-80';
            case 90:
                return this._imageSizeClassName = 'imageSize-90';
            default:
                return this._imageSizeClassName = 'imageSize-100';
        }
    }

    get IsQuestion() {
        return this._options.Type === Types.Question;
    }

    get IsWarning() {
        return this._options.Type === Types.Warning;
    }

    get IsError() {
        return this._options.Type === Types.Error;
    }

    private Init() {
        this._text = ko.observable(this._options.Text);
        this._textConfirm = ko.observable(this._options.TextConfirm || LABELS.CONFIRM);
        this._textCancel = ko.observable(this._options.TextDecline || LABELS.CANCEL);
        this._modalClass = '';
    }

    private InitModal() {
        this._modal = new Modal({
            width: this._options.Width || '405',
            minHeight: this._options.Height || '200',
            closeButton: false,
            closeOnEsc: false,
            addClass: this._options.ModalClass === undefined ? '' : this._options.ModalClass + "",
            appendTo: 'body:first'
        }, false);
    }

    Show() {
        if (this.Exclusives().length == 0 &&
            this.Inclusives().filter(inclusive =>
                !inclusive.Checked() &&
                !inclusive.Hidden() &&
                !inclusive.IsMain).length == 0) {
            this.TriggerConfirmEvent();
        } else {
            this.InitModal();
            ko.cleanNode(this._modal.Wrapper);
            ko.applyBindings(this, this._modal.Wrapper);
        }

        if (this.incompatibleDefaultComponents.length > 0) {
            this.incompatibleDefaultComponents.sort(function (a, b) {
                return a.Path.length - b.Path.length;
            });
            new Notifier().Warning(LABELS.CANT_RESOLVE_CONFLICTS_FOR_NESTED_DEFAULT_PRODUCTS +
                this.incompatibleDefaultComponents.map(incompatible =>
                    incompatible.InexInclusiveItem.Name).join(', '));
        }
    }

    GetTemplateName() {
        return TEMPLATES.VIEW;
    }

    OnOkClick() {
        this.Close();
        this.TriggerConfirmEvent();
    }

    TriggerConfirmEvent() {
        this.Trigger(EVENTS.CONFIRM_SELECTED, this.Inclusives().filter(p => p.IsMain || p.Checked()));
    }

    ToggleCheck(item: InexItem, popup: InexConfirmationPopup) {
        item.Checked(!item.Checked());
        //const relatedSelected = popup.SelectedProducts.find(s => s.Id == item.DependsOn || s.ParentGroup.Name == item.Group);

        const checkedProducts = _.union(popup.Inclusives().filter(p => p.Checked()), [item]);

        let sameGroupItems = [];
        //if (relatedSelected) {
        //    sameGroupItems = popup.Inclusives().filter(p =>
        //        (relatedSelected.ParentGroup.ParentProduct.Id == p.DependsOn ||
        //            relatedSelected.Id == p.DependsOn) &&
        //        p.Id != item.Id &&
        //        !p.IsMain
        //    );
        //} else {
            sameGroupItems = popup.Inclusives().filter(p => p.GroupId == item.GroupId &&
                p.GroupName == item.GroupName &&
                p.Id != item.Id &&
                item.DependsOn == p.DependsOn &&
                !p.IsMain
            );
        //}

        const relatedInclusives = popup.Inclusives().filter(p => {

            const inclusiveCheckedProduct = p.InexParentIds.indexOf(item.Id) != -1 ||
                (p.DependsOn == item.Id && checkedProducts.find(c =>
                    p.InexParentIds.indexOf(c.Id) != -1 && c.Checked() != null));

                const relatedToCheckedOrEndProduct = checkedProducts.find(c => c.Id == p.DependsOn && c.Checked()) != null || p.DependsOn == popup.ProductId;

                const isChecked = checkedProducts.find(c =>
                    c.Id != p.Id &&
                    c.GroupId == p.GroupId &&
                    c.GroupName == p.GroupName &&
                    c.Path == p.Path &&
                    c.DependsOn == p.DependsOn) != null;

                return !p.IsMain && !isChecked && inclusiveCheckedProduct && relatedToCheckedOrEndProduct;
            }
        );
        const dependencies = popup.Inclusives().filter(p => {
            return p.DependsOn == item.Id
        });


        if (!item.Checked()) {
            item.InexExclusiveItems().filter(ex => !ex.Exchange()).forEach(p => {
                const exclusives = popup.Inclusives().filter(product => product.Id == p.Id);
                if (exclusives.length > 0) {
                    exclusives.forEach(exclusive => {

                        const inclusiveCheckedProduct = exclusive.InexParentIds.indexOf(item.Id) != -1 || checkedProducts.find(c => exclusive.InexParentIds.indexOf(c.Id) != -1 && c.Checked() != null);

                        const relatedToCheckedOrEndProduct = checkedProducts.find(c => c.Id == exclusive.DependsOn && c.Checked()) != null || exclusive.DependsOn == popup.ProductId;

                        const sameGroupItemChecked = popup.Inclusives().find(i => i.GroupId == exclusive.GroupId &&
                            i.GroupName == exclusive.GroupName &&
                            i.Id != exclusive.Id &&
                            exclusive.DependsOn == i.DependsOn &&
                            !i.IsMain && i.Checked()
                        ) != null;

                        if (!popup.IsIncompatible(exclusive) &&
                            (!exclusive.Checked() || exclusive.Hidden) &&
                            inclusiveCheckedProduct &&
                            relatedToCheckedOrEndProduct &&
                            !sameGroupItemChecked) {
                            exclusive.Hidden(false);
                            this.ToggleAnimate(exclusive, false);
                        }
                    });
                }
            });

            dependencies.forEach(d => {
                d.Hidden(true);
                d.Checked(false);
                popup.ToggleAnimate(d, true);
            });

            relatedInclusives.forEach(p => {
                p.Hidden(true);
                p.Checked(false);
                popup.ToggleAnimate(p, true);
                const relatedInclusiveHiddenSiblings = popup.Inclusives().filter(product => {
                    const inclusiveCheckedProduct = checkedProducts.find(c => product.InexParentIds.indexOf(c.Id) != -1 && c.Checked());
                    const relatedCheckedProduct = checkedProducts.find(c => c.Id == product.DependsOn && c.Checked());

                    const sameGroupItemChecked = popup.Inclusives().find(i => i.GroupId == product.GroupId &&
                        i.GroupName == product.GroupName &&
                        i.Id != product.Id &&
                        product.DependsOn == i.DependsOn &&
                        !i.IsMain && i.Checked()
                    ) != null;

                    return product.Hidden() && product.GroupId == p.GroupId &&
                        product.GroupName == p.GroupName &&
                        product.DependsOn == p.DependsOn &&
                        inclusiveCheckedProduct != null &&
                        relatedCheckedProduct != null && !sameGroupItemChecked;
                });
                relatedInclusiveHiddenSiblings.forEach(product => {
                    if (!popup.IsIncompatible(p)) {
                        product.Hidden(false);
                        product.Checked(false);
                        popup.ToggleAnimate(product, false);
                    }
                });
            });

            sameGroupItems.forEach(p => {
                const inclusiveCheckedParent = checkedProducts.find(c =>
                    p.InexParentIds.indexOf(c.Id) != -1 && c.Checked())

                if ((inclusiveCheckedParent != null ||
                    p.InexParentIds.indexOf(popup.ProductId) != -1) && !popup.IsIncompatible(p)) {

                    setTimeout(() => {
                        p.Hidden(false);
                    }, popup._timeout);

                    p.DelayItem(false);
                } else {
                    p.Hidden(true);
                    popup.ToggleAnimate(p, true);
                }
                p.Checked(false);
            });

        } else {
            dependencies.forEach(d => {
                if (!popup.IsIncompatible(d) && (d.InexParentIds.length == 0 || checkedProducts.find(c => d.InexParentIds.indexOf(c.Id) != -1 && c.Checked()) != null)) {
                    d.Hidden(false);
                    popup.ToggleAnimate(d, false);
                    if (d.Default() && !d.Checked()) {
                        popup.HandleDefaultItem(d);
                        if (d.Checked()) {
                            checkedProducts.push(d);
                        }
                    }
                }
            });

            relatedInclusives.forEach(r => {
                if (checkedProducts.find(c => c.GroupId == r.GroupId && c.GroupName == r.GroupName && r.DependsOn == item.Id) == null && !popup.IsIncompatible(r)) {
                    r.Checked(false);

                    setTimeout(() => {
                        r.Hidden(false);
                    }, this._timeout);

                    r.DelayItem(false);
                }
            });

            sameGroupItems.forEach(p => {
                p.Hidden(true);
                p.Checked(false);
                popup.ToggleAnimate(p, true);
            });
            item.InexExclusiveItems().filter(ex => !ex.Exchange()).forEach(p => {
                const exclusives = popup.Inclusives().filter(product => product.Id == p.Id && p.ParentId == product.DependsOn);
                if (exclusives.length > 0) {
                    exclusives.forEach(exclusive => {
                            if (exclusive.Checked()) {
                                popup.ToggleCheck(exclusive, popup);
                            }
                            exclusive.Hidden(true);
                            popup.ToggleAnimate(exclusive, true);
                        }
                    );
                }
            });
        }

        popup.HandleExclusives();

        popup.HandlePriceList();

        const nonHiddenInclusives = _.filter(popup.Inclusives(), inclusive => !inclusive.IsMain && !inclusive.Hidden());
        if (_.all(nonHiddenInclusives, inclusive => !inclusive.Checked())){
            popup.SetInclusivesOnInit();
        }
    }

    private HideNonMainInclusives() {
        if (!this.MainProduct){
            return;
        }

        this.Inclusives().filter(inclusive => !inclusive.IsMain && inclusive.InexParentIds && inclusive.InexParentIds.indexOf(this.MainProduct.Id) == -1)
            .forEach(inclusive => {
                inclusive.Hidden(true);
                inclusive.DelayItem(true);
            });
    }

	private HideNonParentInclusives() {
		this.Inclusives().filter(inclusive => !inclusive.IsMain && inclusive.InexInclusiveItem && inclusive.InexInclusiveItem.ParentId !== this.ProductId)
			.forEach(inclusive => {
                inclusive.Hidden(true);
                inclusive.DelayItem(true);
            });
	}

	private SetInclusivesOnInit() {
        _.each(this.Inclusives(), inclusive => {
            const isHidden = _.any(this.InclusivesHiddenOnInit, hiddenInclusiveId => hiddenInclusiveId === inclusive.Id);
            const isChecked = _.any(this.InclusivesCheckedOnInit, checkedInclusiveId => checkedInclusiveId === inclusive.Id)

            inclusive.Hidden(isHidden);
            inclusive.Checked(isChecked);
            this.ToggleAnimate(inclusive, isHidden);
        })
    }

    HandleExclusives() {
        this.Inclusives().forEach(inclusive =>
            inclusive.InexExclusiveItems().forEach(exclusive => {
                if (this.SelectedProducts.find(selected => selected.Id == exclusive.Id)) {
                    const alreadyHandledExclusive = this.Exclusives().find(ex => exclusive.Id == ex.Id);
                    if (alreadyHandledExclusive) {
                        if (alreadyHandledExclusive.IncompatibleIds.indexOf(inclusive.Id) == -1) {
                            alreadyHandledExclusive.AddIncompatibleId(inclusive.Id);
                        }
                    } else {
                        exclusive.AddIncompatibleId(inclusive.Id);
                        if (this.Exclusives().indexOf(exclusive) == -1) {
                            this.Exclusives.push(exclusive);
                        }
                    }
                }
            })
        );

        _.forEach(this.Exclusives(), exclusive => {
            if (exclusive.Exchange()) {
                exclusive.Hidden(false);
                this.ToggleAnimate(exclusive, false);
                exclusive.Reason(LABELS.INCOMPATIBLE_WITH + " " + this.MainProduct.InexInclusiveItem.Name);
            } else {
                let checkedIncompatibles = [];
                exclusive.IncompatibleIds.forEach(id => {
                    const incompatible = this.Inclusives().find(inclusive => inclusive.Id == id && inclusive.Checked());
                    if (incompatible) {
                        checkedIncompatibles.push(incompatible.InexInclusiveItem.NameTranslated || incompatible.InexInclusiveItem.Name);
                    }
                });
                if (checkedIncompatibles.length > 0) {
                    exclusive.Hidden(false);
                    this.ToggleAnimate(exclusive, false);
                    exclusive.Reason(LABELS.INCOMPATIBLE_WITH + " " + checkedIncompatibles.join(', '));
                } else {
                    exclusive.Hidden(true);
                    this.ToggleAnimate(exclusive, true);
                    exclusive.Reason("");
                }

            }
        });
    }

    HandlePriceList() {
        this.PriceList().Groups([]);

        const totalPrice = this.Inclusives().find(inclusive => inclusive.IsMain).InexInclusiveItem.Price;
        this.PriceList().Total(totalPrice);

        const alternativeTotalPrice = this.PriceList().CalculateAlternativePrice(totalPrice);
        this.PriceList().AlternativeTotal(alternativeTotalPrice);

        this.Inclusives().filter(inclusive => inclusive.Checked() && !inclusive.IsMain).forEach(inclusive => {

            const product = inclusive.InexInclusiveItem;

            const group = new PriceGroup(product.Id, product.Name, product.Price, product.NameTranslated);

            const alternativeGroupPrice = this.PriceList().CalculateAlternativePrice(group.Price());
            group.AlternativePrice(alternativeGroupPrice);

            this.PriceList().Groups.push(group);
        });

        this.Exclusives().filter(exclusive => !exclusive.Hidden() || !exclusive.Exchange()).forEach(exclusive => {

            const group = new PriceGroup(exclusive.Id, exclusive.Name, -exclusive.Price, exclusive.NameTranslated);

            const alternativeGroupPrice = this.PriceList().CalculateAlternativePrice(group.Price());
            group.AlternativePrice(alternativeGroupPrice);

            this.PriceList().Groups.push(group);
        })

        this.PriceList().Groups().forEach(g => {
            this.PriceList().Total(this.PriceList().Total() + g.Price());
            this.PriceList().AlternativeTotal(this.PriceList().AlternativeTotal() + g.AlternativePrice());
        });
    }

    IsIncompatible(product: InexItem) {
        return this.Inclusives().find(p => {
            return p.InexExclusiveItems().filter(ex => !ex.Exchange()).find(exclusive => exclusive.Id == product.Id && product.DependsOn == exclusive.ParentId) != null && p.Checked()
        }) != null
    }

    OnCancelClick() {
        this.Close();
        this.Trigger(EVENTS.DISCARD_SELECTED);
    }

    Close() {
        this._modal.Close();
    }

    private AfterRender() {
        this._modal.Show();
    }
}