import * as ko from 'knockout';

import { BlockUI } from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';

import {EventBus, IEventArgs} from "Core/Common/EventBus/EventBus";

import {Paginator} from 'Core/Components/Paginator/Paginator';
import {EVENTS as PAGINATOR_EVENTS} from 'Core/Components/Paginator/Constants';

import {IProductConfigurationPageParams, ProductConfiguratorPage} from "../ProductConfiguratorPage";

import {NavigationStack} from '../../Stores/NavigationStack/NavigationStack';
import {NavigationItemTypes} from '../../Stores/NavigationStack/NavigationItem';

import {RootProductsPageStore} from "./Stores/RootProductsPageStore";
import {RootProductsPageMappings} from "./Mappings/RootProductsPageMappings";

import {ProductResponse} from "./Stores/Models/ProductResponse";

import {ViewModel} from "./Models/ViewModel";
import {Product} from "./Models/Product";

import {ProductDto} from "Core/Components/Controls/ProductConfigurator/Models/ProductDto";

import { RootProductsPageEvents } from "./Events/RootProductsPageEvents";
import {StartPageEvents} from "../StartPage/Events/StartPageEvents";

import { LABELS } from "Core/Components/Translation/Locales";

import Template from './Templates/Template.html';

export interface IRootProductsPageParams extends IProductConfigurationPageParams {
    ImageSizes: IRootProductsPageImageSizes;
    RootProductsViewId?: number;
}

export interface IRootProductsPageImageSizes {
    ProductSelection: number
}

export class RootProductsPage extends ProductConfiguratorPage<IRootProductsPageParams> {
    private _paginator: Paginator;
    private _product: Product;

    private _store: RootProductsPageStore;
    private _mappings: RootProductsPageMappings;

    private _viewModel: ViewModel;
    private _header:KnockoutObservable<string>;
    _labels = LABELS;

    constructor(params: IRootProductsPageParams, navigationStack: NavigationStack, eventBus: EventBus) {
        super(params, navigationStack, eventBus);

        this._paginator = new Paginator();
        this._paginator.PageNumber = 1;
        this._paginator.RecordsPerPage = 50;

        this._store = new RootProductsPageStore(params.ProductsEntityId, params.RootProductsViewId);
        this._mappings = new RootProductsPageMappings();

        this._viewModel = new ViewModel(eventBus);
        this.BindEvents();

        this._header = ko.observable(LABELS.SELECT_PRODUCT);
    }

    GetTemplate() {
        return Template;
    }

    Refresh(product?: Product, pageNumber?: number) {
        if (product) {
            return this.LoadNestedProductsOf(product);
        }

        if (pageNumber != null) {
            this._paginator.PageNumber = pageNumber;
        }

        return this.LoadRootProducts()
            .then(() => {
                this.navigationStack.Upsert(LABELS.NEW_PRODUCT + (this._paginator.PageNumber ? ` (${LABELS.PAGE} ${this._paginator.PageNumber})` : ""), NavigationItemTypes.RootProducts, {
                    PageNumber: this._paginator.PageNumber,
                    RecordsPerPage: this._paginator.RecordsPerPage
                });
            });
    }

    Dispose() {
        this._viewModel.Dispose();
        super.Dispose();
    }

    private BindEvents() {
        this.HandleEvent(RootProductsPageEvents.Selected)
            .Using((eventArgs: IEventArgs<Product, Product>) => this.SelectProduct(eventArgs.Data))
            .Always();

        this._paginator.On(PAGINATOR_EVENTS.CHANGE, this, () => this.SwitchPage());
    }

    private SwitchPage() {
        if (!this._product) {
            return this.LoadRootProducts()
                .then(() => {
                    this.navigationStack.Upsert(LABELS.NEW_PRODUCT + (this._paginator.PageNumber?` (${ LABELS.PAGE } ${ this._paginator.PageNumber })`: ``), NavigationItemTypes.RootProducts, {
                        PageNumber: this._paginator.PageNumber,
                        RecordsPerPage: this._paginator.RecordsPerPage
                    });
                });
        }

        return this.LoadNestedProductsOf(this._product)
            .then(() => {
                this.navigationStack.Upsert(`${LABELS.NEW_PRODUCT} (${this._product.NameTranslated || this._product.Name}` + (this._paginator.PageNumber ? ` | ${LABELS.PAGE} ${this._paginator.PageNumber})` : ``), NavigationItemTypes.NestedProducts, {
                    Id: this._product.Id,
                    PageNumber: this._paginator.PageNumber,
                });
            });
    }

    private FillViewModel(rootProducts: ProductResponse[]) {
        const products = this._mappings.MapToProducts(rootProducts,
            (rootProduct, product) => product.AssignEventBus(this.EventBus));

        products.forEach(product => product.ImageSize = this.params.ImageSizes.ProductSelection);
        this._viewModel.Products(products);
    }

    public CloseModal() {
        this.DispatchEvent(StartPageEvents.ClosePopup);
    }

    private SelectProduct(product: Product) {
        if (product.GroupId || product.GroupName) {
            const productDto = this._mappings.MapToProductDto(product);
            this.DispatchEvent<ProductDto>(RootProductsPageEvents.ConfigurableProductSelected, productDto);
            return;
        }

        this._product = product;

        return this.LoadNestedProductsOf(product)
            .then(() => {
                this._paginator.PageNumber = 1;
                this.navigationStack.Push(`${LABELS.NEW_PRODUCT} (${this._product.NameTranslated || this._product.Name}` + (this._paginator.PageNumber?` | ${LABELS.PAGE} ${this._paginator.PageNumber})`: ``), NavigationItemTypes.NestedProducts, {
                    Product: product,
                    PageNumber: this._paginator.PageNumber
                });
            });
    }

    private LoadRootProducts() {
        BlockUI.Block();

        return this._store.GetRootProducts(this._paginator.PageNumber, this._paginator.RecordsPerPage)
            .then(productsSlice => {
                this.FillViewModel(productsSlice.Items);
                this._paginator.TotalRecords = productsSlice.TotalCount;
                BlockUI.Unblock();
            })
            .fail(err => {
                BlockUI.Unblock();
                new Notifier().Failed(err.message);
            });
    }

    private LoadNestedProductsOf(product: Product) {
        this._header(this._header() + ": " + (product.NameTranslated || product.Name));
        BlockUI.Block({Target: this.container});

        return this._store.GetNestedProductsOf(product.Id)
            .then(nestedProductsSlice => {
                this.FillViewModel(nestedProductsSlice.Items);
                this._paginator.TotalRecords = nestedProductsSlice.TotalCount;

                BlockUI.Unblock(this.container);
            })
            .fail(err => {
                BlockUI.Unblock(this.container);
                new Notifier().Failed(err.message);
            });
    }
}