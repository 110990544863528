import { P } from 'Core/Common/Promise';
import { Request, IRequestOptions } from 'Core/Common/Request';

export interface IParamModel {
	Name: string;
	Value: string | number;
}

export interface IExecuteTriggerRequestModel {
	Id: number;
	Name: string;
	Params: Array<IParamModel>;
	ObjectId: string;
}

export interface IExecuteLinkEditorGridSaveTriggerDto {
	Name: string;
	Params: Array<IParamModel>;
	ObjectId: string;
}

export interface IExecuteLinkEditorUnlinkTriggerDto {
	Name: string;
	Params: Array<IParamModel>;
	ObjectId: string;
}

export interface IExecuteClusteredPivotFinishedTriggerDto {
	Id: number;
	Params: Array<IParamModel>;
	ObjectId: string;
}

export class TriggerStore {
	public static ExecuteClickTrigger(params: IExecuteTriggerRequestModel): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/TriggerApi/ExecuteClickTrigger',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(() => {
					deferredResult.resolve(null);
			}).fail(data => { 
				let jsonResult = JSON.parse(data.message);
				deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			 });

		return deferredResult.promise();
	}

	public static ExecuteLinkEditorGridSaveTrigger(params: IExecuteLinkEditorGridSaveTriggerDto): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/TriggerApi/ExecuteLinkEditorGridSaveTrigger',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(() => {
					deferredResult.resolve(null);
			}).fail(data => { 
				let jsonResult = JSON.parse(data.message);
				deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			 });

		return deferredResult.promise();
	}

	public static ExecuteLinkEditorUnlinkTrigger(params: IExecuteLinkEditorUnlinkTriggerDto): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/TriggerApi/ExecuteLinkEditorUnlinkTrigger',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(() => {
					deferredResult.resolve(null);
			}).fail(data => { 
				let jsonResult = JSON.parse(data.message);
				deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			 });

		return deferredResult.promise();
	}


	public static ExecuteClusteredPivotFinishedTrigger(params: IExecuteClusteredPivotFinishedTriggerDto): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/TriggerApi/ExecuteClusteredPivotFinishedTrigger',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(() => {
					deferredResult.resolve(null);
			}).fail(data => { 
				let jsonResult = JSON.parse(data.message);
				deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			 });

		return deferredResult.promise();
	}
}