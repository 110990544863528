import * as ko from 'knockout';
import * as $ from 'jquery';

import {Modal} from 'Core/Common/Modal';
import {Notifier} from 'Core/Common/Notifier';
import {BlockUI} from 'Core/Common/BlockUi';
import {LABELS, NOTIFICATIONS} from 'Core/Components/Translation/Locales';
import {SupportStore} from 'Core/Components/ReportIssueModal/Stores/SupportStore';
import {SupportInfoModel} from 'Core/Components/ReportIssueModal/Models/SupportInfoModel';
import {UserManager} from 'User/UserManager';
import {MenuManager} from 'MenuManager/MenuManager';
import {ErrorHolder} from 'Core/ErrorHolder/ErrorHolder';

import ReportIssueModalTemplate from 'Core/Components/ReportIssueModal/Templates/ReportIssueModal.html';
import {SupportTicketModel} from './Models/SupportTicketModel';

ko.templates['Core/Components/ReportIssueModal/Templates/ReportIssueModal'] = ReportIssueModalTemplate;

declare global {
    interface MediaDevices {
        getDisplayMedia(constraints?: MediaStreamConstraints): Promise<MediaStream>;
    }
}

async function getScreenshot() {
    if (!navigator.mediaDevices) {
        throw new Error(NOTIFICATIONS.SCREENSHOT_CAN_BE_MADE_ONLY_USING_HTTPS);
    }

    const video = document.createElement('video');

    try {
        video.srcObject = await navigator.mediaDevices.getDisplayMedia();
    } catch {
        throw new Error(NOTIFICATIONS.PERMISSION_IS_DENIED)
    }

    // Some time for source choosing popup to close
    await new Promise(resolve => {
        setTimeout(resolve, 250);
    });

    await video.play();

    const canvas = document.createElement('canvas');

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0);

    const [track] = video.srcObject.getVideoTracks();
    track.stop();

    return canvas.toDataURL('image/jpeg');
}

export class ReportIssueModal {
    private _image: string;
    private _supportInfo: SupportInfoModel;
    private _modal = new Modal({closeOnEsc: true, isolateScroll: false}, true);

    _summary = ko.observable<string>();
    _selectedCategory = ko.observable<number>();
    _selectedUrgency = ko.observable<number>();
    _description = ko.observable<string>();

    //false means there are no errors
    _validation = {
        summary: ko.observable(false),
        summaryMaxLength: ko.observable(false),
        category: ko.observable(false),
        urgency: ko.observable(false),
        description: ko.observable(false),
        descriptionMinLength: ko.observable(false),
        descriptionMaxLength: ko.observable(false),
        isValid: function () {
            return (
                !this.summary() &&
                !this.summaryMaxLength() &&
                !this.category() &&
                !this.urgency() &&
                !this.description() &&
                !this.descriptionMinLength() &&
                !this.descriptionMaxLength()
            );
        }
    };

    _placeholder = LABELS.REPORT_ISSUE_DESCRIPTION_PLACEHOLDER.replace(/\\n/g, '\n');;
    _labels = LABELS;

    constructor({image, supportInfo}: { image: string; supportInfo: SupportInfoModel }) {
        this._image = image;
        this._supportInfo = supportInfo;
    }

    static async Open() {
        const getSupportInfoPromise = SupportStore.GetSupportInfo({
            DataBaseName: ReportIssueModal.GetDatabaseName()
        });

        let image: string | undefined;
        let supportInfo: SupportInfoModel | undefined;

        try {
            image = await getScreenshot();

            BlockUI.Block();

            supportInfo = await new Promise<SupportInfoModel>((resolve, reject) => {
                getSupportInfoPromise.then(resolve).fail(reject);
            });
        } catch (error) {
            new Notifier().Failed(error.message);
        } finally {
            BlockUI.Unblock();
        }

        if (image && supportInfo) {
            const modal = new ReportIssueModal({image, supportInfo});
            modal.Show();
        }
    }

    Show() {
        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
        this._modal.Show();
    }

    Close() {
        this._modal.Close();
    }

    GetTemplateName() {
        return 'Core/Components/ReportIssueModal/Templates/ReportIssueModal';
    }

    AfterRender() {
    }

    SendTicket() {
        const ticket = this.ConstructTicket();
        this.ValidateTicket(ticket);

        if (!this._validation.isValid()) {
            return;
        }

        BlockUI.Block();

        SupportStore.CreateSupportTicket(ticket)
            .then(() => {
                this._modal.Close();
                new Notifier().Success(NOTIFICATIONS.ISSUE_HAS_BEEN_SENT_TO_SUPPORT_TEAM);
            })
            .fail(error => new Notifier().Failed(error.message))
            .always(() => BlockUI.Unblock());
    }

    private ConstructTicket(): SupportTicketModel {
        let entityId, recordId, screenTypeId;

        const openedScreen = MenuManager.Instance.GetCurrentScreen();
        if (openedScreen) {
            entityId = openedScreen.GetEntityId();
            recordId = openedScreen.GetRecordId();
            screenTypeId = openedScreen.GetTypeId()
        }

        return {
            CategoryId: this._selectedCategory(),
            UrgencyId: this._selectedUrgency(),
            EntityId: entityId,
            RecordId: recordId,
            ScreenTypeId: screenTypeId,
            Description: this._description(),
            Browser: window.navigator.userAgent,
            ConsoleOutput: ErrorHolder.GetErrors(),
            Screenshot: this._image,
            Summary: this._summary(),
            DataBaseName: ReportIssueModal.GetDatabaseName()
        };
    }

    private ValidateTicket(ticket: SupportTicketModel) {
        this._validation.summary(!ticket.Summary);
        this._validation.summaryMaxLength(ticket.Summary && ticket.Summary.length > 80);
        this._validation.category(!ticket.CategoryId);
        this._validation.urgency(!ticket.UrgencyId);
        this._validation.description(!ticket.Description);
        this._validation.descriptionMinLength(ticket.Description && ticket.Description.length < 50);
        this._validation.descriptionMaxLength(ticket.Description && ticket.Description.length > 2000);
    }

    private static GetDatabaseName() {
        return this.GetDbFromSession() || this.GetDbFromUrl() || this.GetDbFromLoginForm();
    }

    private static GetDbFromSession() {
        return UserManager.Instance.CurrentUser && UserManager.Instance.CurrentUser.DbName;
    }

    private static GetDbFromUrl() {
        return new URL(window.location.href).searchParams.get('db');
    }

    private static GetDbFromLoginForm() {
        return $('#login-form__dbname').val() as string;
    }
}
