import { deserializeAs } from 'libs/cerialize';
import { SyntaxErrorModel } from 'Core/Controls/FunctionDesigner/Models/SyntaxErrorModel';
import { CompileErrorModel } from 'Core/Controls/FunctionDesigner/Models/CompileErrorModel';

export class FunctionValidationModel {
	constructor() {
		this.SyntaxErrors = [];
		this.CompileErrors = [];
	}

	@deserializeAs(SyntaxErrorModel)
	SyntaxErrors: Array<SyntaxErrorModel>;

	@deserializeAs(CompileErrorModel)
	CompileErrors: Array<CompileErrorModel>;
}