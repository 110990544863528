import {P} from 'Core/Common/Promise';
import {Request} from 'Core/Common/Request';

import {SpreadsheetDataDto} from '../Models/Dto/SpreadsheetDataDto';
import {SpreadsheetDescriptorDto} from '../Models/Dto/SpreadsheetDescriptorDto';

export class SpreadsheetStore {
    GetSpreadsheetDescriptor(params: {
        TableId: number,
        HorizontalDimensionFields: string[],
        VerticalDimensionFields: string[],
        ValueField: string,
        PageField: string
    }): P.Promise<SpreadsheetDescriptorDto> {

        const deferred = P.defer<SpreadsheetDescriptorDto>();

        Request.Send({
            proxy: {
                url: '/api/SpreadsheetApi/GetSpreadsheetDescriptor',
                type: 'POST',
                dataType: 'json'
            },
            params: params
        }).then(jsonObj => {
            deferred.resolve(SpreadsheetDescriptorDto.Deserialize(jsonObj))
        }).fail(err => {
            deferred.reject(this.GetResponseError(err, 'Error getting spreadsheet'));
        });

        return deferred.promise();
    }

    GetSpreadsheet(params: {
        TableId: number,
        EntityRecordId: number,
        HorizontalDimensionFields: string[],
        VerticalDimensionFields: string[],
        ValueField: string,
        PageField: string
    }): P.Promise<SpreadsheetDataDto> {

        const deferred = P.defer<SpreadsheetDataDto>();

        Request.Send({
            proxy: {
                url: '/api/SpreadsheetApi/GetSpreadsheet',
                type: 'POST',
                dataType: 'json'
            },
            params: params
        }).then(jsonObj => {
            deferred.resolve(SpreadsheetDataDto.Deserialize(jsonObj))
        }).fail(err => {
            deferred.reject(this.GetResponseError(err, 'Error getting spreadsheet'));
        });

        return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}