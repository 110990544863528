import * as ko from 'knockout';
import * as _ from "underscore";

import {BlockUI} from "Core/Common/BlockUi";
import {IControl} from "Core/Controls/IControl";
import {EntitiesStore} from "../Stores/EntitiesStore";
import {LabelPositions} from "../Enums/LabelPositions";
import {TableWithImagesModel} from "../Models/TableWithImagesModel";
import {BaseControlEditor, EVENTS as BASE_CONTROL_EDITOR_EVENTS} from "./BaseControlEditor";
import {ILabelPosition} from "./ControlEditor";

import { Notifier } from 'Core/Common/Notifier';
import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";
import {ImageFieldModel} from "../Models/ImageFieldModel";

import Template from 'Core/Screens/DesignScreen/ControlEditor/Templates/SignatureControlEditor.html';
ko.templates['Core/Screens/DesignScreen/ControlEditor/Templates/SignatureControlEditor'] = Template;

export const EVENTS = {
    ENTITY_SELECTED: 'ENTITY_SELECTED',
    FIELD_SELECTED: 'FIELD_SELECTED'
};

export class SignatureControlEditor extends BaseControlEditor {
    private _labelPositions: KnockoutObservableArray<ILabelPosition>;
    private _selectedLabelPosition: KnockoutObservable<ILabelPosition>;

    private _fields: KnockoutObservableArray<ImageFieldModel>;
    private _selectedField: KnockoutObservable<ImageFieldModel>;

    private _entities: KnockoutObservableArray<TableWithImagesModel>;
    private _selectedEntity: KnockoutObservable<TableWithImagesModel>;

    constructor(control: IControl){
        super(control);

        this.InitEditControlData(control);
        this.BindEvents();
    }

    GetTemplateName(): string {
        return 'Core/Screens/DesignScreen/ControlEditor/Templates/SignatureControlEditor';
    }

    AfterRender(el: HTMLElement) {
        const container = el[0];
        super.AfterRender(container);

        BlockUI.Block();

        this.LoadData()
            .then(entities => {
                this.PopulateEntities(entities)
            })
            .always(() => BlockUI.Unblock());
    }

    LoadData(){
        return EntitiesStore.GetSignatureEntities({
            // EntityId: this.GetSubjectEntityId()
            SubjectTableId: this.GetSubjectEntityId()
        });
    }

    Save(): void {
        const validationError = this.Validate();
        if (validationError) {
            new Notifier().Warning(validationError);
            return;
        }

        const controlEditorModel = this.GetControlEditorModel();

        this.Trigger(BASE_CONTROL_EDITOR_EVENTS.CONTROL_SAVED, {UpdateControlModel: controlEditorModel});

        this.Close();
    }

    Validate() {
        const validationError = super.Validate();
        if (validationError) {
            return validationError;
        }

        if (!this._selectedField()) {
            this.IsValid(false);
            return 'Please, select field';
        }
    }

    GetControlEditorModel() {
        const desktopLanguage = GlobalManager.Instance.GetGlobal(GLOBALS.DESKTOP_LANGUAGE);
        const controlEditorModel = super.GetControlEditorModel();
        controlEditorModel.LabelPosName = this._selectedLabelPosition().Title;
        controlEditorModel.LabelPos = this._selectedLabelPosition().Value;

        const selectedEntity = this._selectedEntity();
        controlEditorModel.EntityId = selectedEntity.Id;
        controlEditorModel.EntityName = selectedEntity.Name;

        const selectedField = this._selectedField();
        controlEditorModel.FieldId = selectedField.Id;
        controlEditorModel.FieldName = selectedField.Name;
        // controlEditorModel.FieldNameTranslation = selectedField.NameTranslated;
        // controlEditorModel.ValTableId = selectedField.ValTableId;
        // controlEditorModel.ValFieldId = selectedField.ValFieldId;

        controlEditorModel.UseFieldName = this.UseFieldName();
        if (controlEditorModel.UseFieldName) {
            controlEditorModel.Name = selectedField.Name;
        } else {
            _.each(controlEditorModel.NameTranslations, (translation) => {
                if (translation.Selected || (desktopLanguage == translation.Language.ShortName)) {
                    translation.Selected = true;
                }
            });
        }
        return controlEditorModel;
    }

    FieldSelectorAfterRender(option, item) {
        ko.applyBindingsToNode(option, {
            // has removed the functionality of the "disabled/enabled of Fields"
            enableAttr: true,
            css: {
                disabled: false
            }
        }, item);
    }

    protected InitEditControlData(control: IControl) {
        super.InitEditControlData(control);

        this.InitLabelPositions();
        this.InitEntities();
    }

    protected BindEvents() {
        this._selectedEntity.subscribe(selectedEntity => {
            if (this._selectedEntity()) {
                this.Trigger(EVENTS.ENTITY_SELECTED, selectedEntity);
            }
        });

        this.On(EVENTS.ENTITY_SELECTED, this, eventArgs => this.OnEntityChange());
        this.On(EVENTS.FIELD_SELECTED, this, () => this.OnFieldChange());

        if (this.UseFieldName()) {
            this.UseFieldNameAsControlName();
        }

        this.UseFieldName.subscribe((newValue) => {
            if (newValue) {
                this.UseFieldNameAsControlName();
            }
        });
    }

    OnSelectField(selectedField) {
        this.Trigger(EVENTS.FIELD_SELECTED, selectedField);
    }

    InitLabelPositions(){
        this._labelPositions = ko.observableArray([
            {Value: LabelPositions.UpperLeft, Title: LabelPositions[LabelPositions.UpperLeft]},
            {Value: LabelPositions.NoLabel, Title: LabelPositions[LabelPositions.NoLabel]}

        ]);

        const selectedLabelPosition = _.find(this._labelPositions(), item => item.Value === LabelPositions[this.EditControlModel.LabelPosition]);
        this._selectedLabelPosition = ko.observable(selectedLabelPosition);
    }

    private InitEntities() {
        this._entities = ko.observableArray([]);
        this._selectedEntity = ko.observable(null);

        this._fields = ko.observableArray([]);
        this._selectedField = ko.observable(null);
    }

    PopulateEntities(entities: TableWithImagesModel[]){
        entities = _.uniq(entities, false, entity => entity.Id);

        this._entities(entities);
        // this.MarkAcceptableFields(entities);

        const attachedField = _.first(this.EditControlModel.Fields);

        const attachedEntity = attachedField && _.find(entities, entity => entity.Id === attachedField.EntityId);

        if (attachedEntity) {
            this._selectedEntity(attachedEntity);
        } else {
            const subjectEntity = _.find(entities, entity => entity.Id === this.SubjectEntityId);
            this._selectedEntity(subjectEntity);
        }
    }

    // private MarkAcceptableFields(entities: TableWithModel[]) {
    //     const usedFields = this.FindUsedFields();
    //     const attachedField = _.first(this.EditControlModel.Fields);
    //     //Disable used fields
    //     entities.forEach(entity => {
    //         entity.ImageFields
    //             .filter(field => (!attachedField || field.Id !== attachedField.Id) && _.contains(usedFields, field.Id))
    //             .forEach(field => field.IsEnabled = false);
    //     });
    // }

    // private FindUsedFields() {
    //     return this.GetDesignScreen().Controls
    //         .filter(control => control.HasOneField())
    //         .map(control => control.GetFieldModel().Id);
    // }

    private OnEntityChange() {
        const selectedEntity = this._selectedEntity();

        this._fields(selectedEntity.ImageFields);

        const attachedField = _.first(this.EditControlModel.Fields);
        if (attachedField) {
            const selectedField = _.find(selectedEntity.ImageFields, field => field.Id === attachedField.Id);
            this._selectedField(selectedField);
            if (this.UseFieldName()) {
                this.UseFieldNameAsControlName();
            }
        }
    }

    private OnFieldChange() {
        if (this.UseFieldName()) {
            this.UseFieldNameAsControlName();
        } else {
            const translations = this.NameTranslations();
            const defTrans = this.NameTranslations()[0];
            defTrans.Translation = this._selectedField().Name;
            this.SelectedName(defTrans);
            this.NameTranslations([]);
            this.NameTranslations(translations);
        }


    }

    ToggleUseFieldName() {
        this.UseFieldName(!this.UseFieldName());
    }

    UseFieldNameAsControlName() {
        let field = this._selectedField();
        if (field) {
            _.each(this.NameTranslations(), (translation) => {

                if (translation.Language.K_Language === -1) {
                    translation.Translation = field.Name;
                }

                let fieldTranslation = _.find(field.NameTranslations, (fieldTranslation) => {
                    return fieldTranslation.Language.K_Language === translation.Language.K_Language;
                });
                if (fieldTranslation) {

                    if (this.SelectedName() && this.SelectedName().Language.K_Language == fieldTranslation.Language.K_Language) {
                        this.SelectedName().Translation = fieldTranslation.Translation;
                        this.SelectedName.valueHasMutated();
                    }

                    translation.Translation = fieldTranslation.Translation;
                }
            });
            let translations = this.NameTranslations();
            this.NameTranslations([]);
            this.NameTranslations(translations);

            const defTrans = this.NameTranslations()[0];
            defTrans.Translation = this._selectedField().Name;
            this.SelectedName(defTrans);
        }
    }

}