import * as ko from "knockout";
import * as $ from 'jquery';

export class IframeContentExtention {
    static Init() {
        ko.bindingHandlers.iframeContent = {
            update: function (element, valueAccessor) {
                let value = ko.unwrap(valueAccessor());

                setTimeout(function () {
                    const doc = ($(element)[0]  as any).contentWindow.document;
                    const $body = $('body', doc);
                    $body.html(value);
                }, 0);
            }
        };
    }
}