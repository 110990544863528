import * as ko from "knockout";
import {BaseProperty, IPropertyDescription} from "Core/GeneralProperties/Managers/BaseProperty";
import {Guid} from "Core/Common/Guid";

import {LABELS} from "Core/Components/Translation/Locales";

import InputUrlPropertyTemplate from "Core/GeneralProperties/Managers/InputUrlProperty/Templates/InputUrlProperty.html";
ko.templates["Core/GeneralProperties/Managers/InputUrlProperty/Templates/InputUrlProperty"] = InputUrlPropertyTemplate;

export class InputUrlProperty extends BaseProperty {
	private _isValid: KnockoutObservable<boolean>;
	private _labels = LABELS;

	constructor(property: IPropertyDescription, propertyValue: string) {
		super(property);
		this.Value = ko.observable(propertyValue || '');
		this._isValid = ko.observable(true);
	}

	GetTemplateName(): string {
		return "Core/GeneralProperties/Managers/InputUrlProperty/Templates/InputUrlProperty";
	}

	SetValue(propertyValue: number): void {
		if (propertyValue) {
			this.Value(propertyValue);
		}
	}

	IsValid(): boolean {
		return this.CheckValidUrl();
	}

	private CheckValidUrl(): boolean {
		var isValid = this.Value().toString() !== '';
		this._isValid(isValid);
		return isValid;

		//todo create correct regexp for url
		//var url = this.Value().toString();
		//var urlEtalon = "(http|ftp|https)://[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?";
		//var pattern = new RegExp(urlEtalon); // fragment locater

		//return pattern.test(url);
	}
}