import { BasicCanvasItemViewModel } from 'Core/Controls/CanvasDesigner/Models/BasicCanvasItemViewModel';
import { CanvasItemModel, CanvasLinkModel } from 'Core/Controls/CanvasDesigner/Models/CanvasModel';
import { CanvasShapeWidth } from 'Core/Controls/CanvasDesigner/Enums/CanvasShapeWidth';
import { ICanvasItemPositionObject } from 'Core/Controls/CanvasDesigner/Interfaces/ICanvasItemPositionObject';
import { Utils } from 'Core/Controls/CanvasDesigner/Utils';
import { CANVAS_TYPES } from 'Core/Controls/CanvasDesigner/Constants/CanvasTypes';

export class DestinationViewModel extends BasicCanvasItemViewModel {

	parent: number | string = 0;
	embeddingInvalid: boolean = false;
	embedded: boolean = true;
	embeds: Array<number | string> = [];
	parentedNode: boolean = true;
	childSocketsIds: Array<number> = [];
	attrs: any;
	constructor(model: CanvasItemModel) {
		super(model, { width: CanvasShapeWidth[CANVAS_TYPES.DESTINATION], height: 420 });
		this.z = Utils.GetRandomArbitrary(30, 60);
		this.InitProperties(model.Properties);
	}

	private InitProperties(properties: string){
		try {
			let jsonProperties = JSON.parse(properties);

			if(jsonProperties && jsonProperties.EndPointUrl){
				this.attrs.EndPointUrl = jsonProperties.EndPointUrl;
			}

			if(jsonProperties && jsonProperties.CallbackUrl){
				this.attrs.CallbackUrl = jsonProperties.CallbackUrl;
			}

			if(jsonProperties && jsonProperties.ClientId){
				this.attrs.ClientId = jsonProperties.ClientId;
			}

			if(jsonProperties && jsonProperties.ClientSecret){
				this.attrs.ClientSecret = jsonProperties.ClientSecret;
			}

			if(jsonProperties && jsonProperties.SecretKey){
				this.attrs.SecretKey = jsonProperties.SecretKey;
			}

			if(jsonProperties && jsonProperties.Login){
				this.attrs.Login = jsonProperties.Login;
			}

			if(jsonProperties && jsonProperties.Password){
				this.attrs.Password = jsonProperties.Password;
			}

			if(jsonProperties && jsonProperties.Division){
				this.attrs.Division = jsonProperties.Division;
			}

		}catch (e) {}
	}

	SetEmbedds(link: CanvasLinkModel): void {
		this.embeds.push(link.CanvasItem2Id || link.CanvasItem2Guid);
	}

	SetSize(size): void {
		this.size = { 'width': size.width, 'height': size.height };
	}

	SetPosition(position: ICanvasItemPositionObject): void {
		this.position = position;
	}


	SetParentId(link: CanvasLinkModel): void {
		this.parent = link.CanvasItem1Id || link.CanvasItem1Guid;
	}
}