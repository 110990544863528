

import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {ConcreteEntityModel} from 'QueryBuilder/Models/ConcreteEntityModel';
import {P} from 'Core/Common/Promise';
import {Serialize, Deserialize, GenericDeserialize} from 'libs/cerialize';

import {UpdateScreenModel} from 'Core/Screens/DesignScreen/Models/UpdateScreenModel'

export class UpdateScreenStore {
	static UpdateScreen(params: UpdateScreenModel): P.Promise<UpdateScreenModel> {
		var deferredResult = P.defer<UpdateScreenModel>();
		var requestParams: IRequestOptions = {
			proxy: {
				url: 'api/FormDesignerApi/UpdateScreenForm',
				dataType: 'json',
				type: 'POST'
			}, params: Serialize(params)
		};

		Request.Send(requestParams)
			.then(data => {
					if (data.IsSuccessfull) {
						deferredResult.resolve(Deserialize(data.ResultObject));
					} else {
						deferredResult.reject({ message: data.ErrorMessage });
					}

				}
			);

		return deferredResult.promise();
	}

	private static Serialize(model: any) {
		return Serialize(model);
	}

	private static Deserialize(jsonObj: any): UpdateScreenModel {
		return GenericDeserialize(jsonObj, UpdateScreenModel);
	}
}