import { Request, IRequestOptions } from 'Core/Common/Request';
import { P } from 'Core/Common/Promise';
import { DeletionModeModel } from "Core/Screens/Models/DeletionModeModel";

export interface IGetRecordRequestModel {
	TableId: number;
	TableTypeId: number;
	RecordId: number;
	ReadLookups: boolean;
}

export interface IGetDeletionModeRequestModel {
	EntityId: number;
	RecordId: number;
}

export interface IDeleteRecordRequestModel {
	EntityId: number;
	RecordId: number;
	Disable?: boolean;
};

export class RecordStore {
	static GetRecord(params: IGetRecordRequestModel): P.Promise<any> {
		return Request.Send({
			proxy: {
				url: 'api/RecordsApi/Get',
				dataType: 'json',
				type: 'GET'
			}, params: params
		});
	}

	static GetDeletionMode(params: IGetDeletionModeRequestModel): P.Promise<any> {
		var deferredResult = P.defer<DeletionModeModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: 'api/ScreenFormApi/GetDeletionMode',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(result => {
				deferredResult.resolve(result);
			})
			.fail(error => {
				deferredResult.reject(error);
			});
		return deferredResult.promise();
	}

	static DeleteRecord(params: IDeleteRecordRequestModel): P.Promise<any> {
		return Request.Send({
			proxy: {
				url: 'api/ScreenFormApi/DeleteRecord',
				dataType: 'json',
				type: 'POST'
			}, params: params
		});
	}
}