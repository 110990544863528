import {RecipeResponseModel} from "../Response/RecipeResponseModel";
import {RecipeViewModel} from "../Models/View/RecipeViewModel";
import {RecipeEditorTreeNodeViewModelMapping} from "./RecipeEditorTreeNodeViewModelMapping";
import {ITypePropertyModel} from "Core/GeneralProperties/Managers/TypesProperty/TypesProperty";

export class RecipeEditorTreeViewModelMapping {
    static MapFromResponse(responseModel: RecipeResponseModel, typesProperty: ITypePropertyModel[]) {
        let viewModel = new RecipeViewModel();

        let roots = responseModel.Roots.map(root => RecipeEditorTreeNodeViewModelMapping.MapFromResponse(root, typesProperty));
        viewModel.AddRange(roots);
        return viewModel;
    }
}