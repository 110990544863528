import * as ko from 'knockout'
import * as _ from 'underscore';
import * as moment from "moment";

import {Modal} from 'Core/Common/Modal';
import {PlannerLevelRowModel, TimeCellModel} from "../Models/PlannerLevelRowModel";
import {Event} from 'Core/Common/Event'
import {EVENTS} from 'Core/Controls/PlannerLevel/HigherResolutionPlanning/Events';

import Template from 'Core/Controls/PlannerLevel/HigherResolutionPlanning/Templates/HigherResolutionPlanning.html';
import {RightBarCell} from "../RightBarCell/RightBarCell";
import {FormatConverter} from "../../../../FormatEditor/FormatConverter";
ko.templates['Core/Controls/PlannerLevel/HigherResolutionPlanning/Templates/HigherResolutionPlanning'] = Template;

export class HigherResolutionPlanning extends Event{
    private _modal: Modal;
    private _higherResolutionsPlanning: PlannerLevelRowModel;
    private _parentPlan: TimeCellModel;
    private _barName: string;
    Cells: Array<RightBarCell>;

    constructor(
        higherResolutionPlanning: PlannerLevelRowModel,
        parentPlan: TimeCellModel,
        barName: string
        ){
        super();
        this._higherResolutionsPlanning = higherResolutionPlanning;
        this._parentPlan = parentPlan;
        this._barName = barName;
        this.Cells = [];

        if(!this.HasAnyPlanning()){
            this.CalculatePlanning();
        }

        _.each(this._higherResolutionsPlanning.TimeLine, (timeLine) =>{
            let cell = new RightBarCell(timeLine,  this._higherResolutionsPlanning, false);
            this.Cells.push(cell);
        });

        this.AddEvent(EVENTS.SAVE)
    }

    CalculatePlanning(){
        let value = Math.floor(parseFloat(this._parentPlan.TimeValue.replace(',','.'))/this._higherResolutionsPlanning.TimeLine.length);

        if(value > 0){
            _.each(this._higherResolutionsPlanning.TimeLine, (item) =>{
                item.TimeValue = this.FormatValue(value.toString());

                if(this._higherResolutionsPlanning.TimeLine.indexOf(item) === this._higherResolutionsPlanning.TimeLine.length -1){
                    item.TimeValue = this.FormatValue((parseFloat(item.TimeValue.replace(',', '.'))
                        + parseFloat(this._parentPlan.TimeValue.replace(',', '.'))%this._higherResolutionsPlanning.TimeLine.length).toString());
                }
            });
        }else{
            let firstDate = _.first(this._higherResolutionsPlanning.TimeLine);
            if(firstDate){
                firstDate.TimeValue = this._parentPlan.TimeValue;
            }
        }
    }

    FormatValue(value: string){
        return FormatConverter.ConvertDecimal(value)
    }

    GetTemplateName(){
        return 'Core/Controls/PlannerLevel/HigherResolutionPlanning/Templates/HigherResolutionPlanning';
    }

    FormatTitle(cell: RightBarCell){
        let startWeekDate = moment(cell.TimeAxis);
        let endWeekDate =  moment(cell.TimeAxis).add(6, 'day');
        return `Week ${startWeekDate.isoWeek()}: ${startWeekDate.format('DD')}/${startWeekDate.format('MM')} - ${endWeekDate.format('DD')}/${endWeekDate.format('MM')}`;
    }

    get Title(){
        return `${this._barName}: ${moment(this._parentPlan.TimeAxis).format('MMMM')}, ${moment(this._parentPlan.TimeAxis).year()}`;
    }

    AfterRender(){
        let first =_.first(this.Cells);
        if(first){
            first.SelectCell();
        }
    }

    Show(){
        this._modal = new Modal({ addClass: 'higher-resolution-planner', height: 300, width: 350 }, false);
        this._modal.Show();
        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
    }

    Close(){
        if(this._modal){
            this._modal.Close();
        }
    }

    Save(){
        this.Trigger(EVENTS.SAVE);
    }

    private HasAnyPlanning(){
        return _.filter(this._higherResolutionsPlanning.TimeLine, item => parseFloat(item.TimeValue) !== 0).length > 0;
    }
}