import * as ko from 'knockout';

import {Event} from 'Core/Common/Event';

import {LABELS} from "Core/Components/Translation/Locales";

import {IReadyForProcessingParams} from './Params/IReadyForProcessingParams';

import {ISendToFPEventData} from './Events/ISendToFPEventData';

import {JournalsState} from "Core/Components/Dashboards/FinancialDashboard/Enums/JournalsState";
import {AccountsState} from "Core/Components/Dashboards/FinancialDashboard/Enums/AccountsState";

import {FinancialProcessorRunnerViewModel} from 'Core/Components/Dashboards/FinancialDashboard/Models/View/FinancialProcessorRunnerViewModel';

import {FinancialDashboardMappingProfile} from 'Core/Components/Dashboards/FinancialDashboard/Mappings/FinancialDashboardMappings';

import Template
    from 'Core/Components/Dashboards/FinancialDashboard/Components/ReadyForProcessing/Templates/Template.html';

export class ReadyForProcessing extends Event {
    private _labels = LABELS;

    Expanded: KnockoutObservable<boolean>;

    JournalsState: JournalsState;
    AccountsState: AccountsState;
    FinancialProcessorRunners: FinancialProcessorRunnerViewModel[];

    constructor(params: IReadyForProcessingParams) {
        super();

        this.Expanded = ko.observable(true);

        this.Init(params);
    }

    get IsAvailable() {
        return this.JournalsState === JournalsState.Ok && this.AccountsState === AccountsState.Ok;
    }

    get NotAvailableReason() {
        switch (this.JournalsState) {
            case JournalsState.Deleted:
                return 'JOURNALS table is deleted';

            case JournalsState.Disabled:
                return 'JOURNALS table is disabled';

            case JournalsState.NotExist:
                return 'JOURNALS table does not exist';
        }

        switch (this.AccountsState) {
            case AccountsState.Deleted:
                return 'ACCOUNTS table is deleted';

            case AccountsState.Disabled:
                return 'ACCOUNTS table is disabled';

            case AccountsState.NotExist:
                return 'ACCOUNTS table does not exist';

            case AccountsState.NotLinkedToJournals:
                return 'ACCOUNTS not linked to JOURNALS';
        }

        return null;
    }

    get TotalAmount() {
        return this.FinancialProcessorRunners.map(runner => runner.RecordsAmount).reduce((sum, current) => sum + current, 0);
    }

    GetTemplate() {
        return Template;
    }

    ToggleExpansion() {
        this.Expanded(!this.Expanded());
    }

    private Init(params: IReadyForProcessingParams) {
        this.FinancialProcessorRunners = params.FinancialProcessorRunners.map(runner => FinancialDashboardMappingProfile.MapToFinancialProcessorRunner(runner));
        this.JournalsState = params.JournalsState;
        this.AccountsState = params.AccountsState;
    }

    OnSendToFPBtnClick(runner: FinancialProcessorRunnerViewModel) {
        const eventData: ISendToFPEventData = {QueryResultId: runner.Id, PageNumber: 1};
        this.Trigger('SEND_TO_FP_REQUESTED', eventData);
    }
}