import * as ko from 'knockout';

import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';

import {KanbanBoard} from 'Core/Controls/Kanban/KanbanBoard';

import {BaseControlEditor, EVENTS} from '../BaseControlEditor';
import {
    KanbanControlEditorStore,
    IKanbanSubjectEntity,
    IKanbanTodoEntity,
    IKanbanField,
    IKanbanTodoType
} from './Stores/KanbanControlEditorStore';

import Template from 'Core/Screens/DesignScreen/ControlEditor/Templates/KanbanControlEditor.html';

ko.templates['Core/Screens/DesignScreen/ControlEditor/Templates/KanbanControlEditor'] = Template;

export class KanbanControlEditor extends BaseControlEditor {
    protected EditControl: KanbanBoard;

    private _store: KanbanControlEditorStore;

    private _kanbanSubjectEntities: KnockoutObservableArray<IKanbanSubjectEntity>;
    private _selectedKanbanSubjectEntity: KnockoutObservable<IKanbanSubjectEntity>;

    private _kanbanSubjectFields: KnockoutComputed<IKanbanField[]>;
    private _selectedKanbanSubjectField: KnockoutObservable<IKanbanField>;

    private _todoEntities: KnockoutObservableArray<IKanbanTodoEntity>;
    private _selectedTodoEntity: KnockoutObservable<IKanbanTodoEntity>;

    private _todoTypes: KnockoutComputed<IKanbanTodoType[]>;
    private _selectedTodoType: KnockoutObservable<IKanbanTodoType>;

    private _todoFields: KnockoutComputed<IKanbanField[]>;
    private _selectedTodoField: KnockoutObservable<IKanbanField>;

    constructor(control: KanbanBoard) {
        super(control);

        this._store = new KanbanControlEditorStore();

        this._kanbanSubjectEntities = ko.observableArray([]);
        this._selectedKanbanSubjectEntity = ko.observable();

        this._kanbanSubjectFields = ko.pureComputed(() => {
            const selectedKanbanSubjectEntity = this._selectedKanbanSubjectEntity();
            return selectedKanbanSubjectEntity ? selectedKanbanSubjectEntity.Fields : [];
        });
        this._selectedKanbanSubjectField = ko.observable();

        this._todoEntities = ko.observableArray([]);
        this._selectedTodoEntity = ko.observable();

        this._todoTypes = ko.pureComputed(() => {
            const selectedTodoEntity = this._selectedTodoEntity();
            return selectedTodoEntity ? selectedTodoEntity.Types : [];
        });
        this._selectedTodoType = ko.observable();

        this._todoFields = ko.pureComputed(() => {
            const selectedTodoEntity = this._selectedTodoEntity();
            return selectedTodoEntity ? selectedTodoEntity.Fields : [];
        });
        this._selectedTodoField = ko.observable();
    }

    GetTemplateName() {
        return 'Core/Screens/DesignScreen/ControlEditor/Templates/KanbanControlEditor';
    }

    AfterRender(el: HTMLElement) {
        const container = el[0];
        super.AfterRender(container);

        this.LoadData();
    }

    Save(): void {
        const validationError = this.Validate();

        if (validationError) {
            new Notifier().Warning(validationError);
            return;
        }
        const controlEditorModel = this.GetControlEditorModel();

        this.Trigger(EVENTS.CONTROL_SAVED, {UpdateControlModel: controlEditorModel});

        this.Close();
    }

    Validate() {
        let validationError = super.Validate();

        if (validationError) {
            return validationError;
        }

        if (!this._selectedKanbanSubjectField()) {
            return 'Please, select Kanban title field';
        }

        if (!this._selectedTodoEntity()) {
            return 'Please, select Todo entity';
        }

        if (!this._selectedTodoType()) {
            return 'Please, select Todo type';
        }

        if (!this._selectedTodoField()) {
            return 'Please, select Todo field';
        }

        return null;
    }

    GetControlEditorModel() {
        const controlEditorModel = super.GetControlEditorModel();

        const selectedKanbanSubjectEntity = this._selectedKanbanSubjectEntity();
        if (selectedKanbanSubjectEntity) {
            controlEditorModel.KanbanSubjectId = selectedKanbanSubjectEntity.Id;
        }

        const selectedTitleField = this._selectedKanbanSubjectField();
        if (selectedTitleField) {
            controlEditorModel.TitleFieldId = selectedTitleField.Id;
        }

        const selectedTodoEntity = this._selectedTodoEntity();
        if (selectedTodoEntity) {
            controlEditorModel.TodoEntityId = selectedTodoEntity.Id;
        }

        const selectedTodoType = this._selectedTodoType();
        if (selectedTodoType) {
            controlEditorModel.TodoTypeId = selectedTodoType.Id;
        }

        const selectedTodoField = this._selectedTodoField();
        if (selectedTodoField) {
            controlEditorModel.TodoFieldId = selectedTodoField.Id;
        }

        return controlEditorModel;
    }

    private LoadData() {
        BlockUI.Block();

        this._store.GetDesignOptions(this.GetSubjectEntityId())
            .then(options => this.PopulateData(options.ScreenSubjectFields, options.KanbanSubjectEntities, options.TodoEntities))
            .fail(error => new Notifier().Failed(error.message))
            .always(() => BlockUI.Unblock());
    }

    private PopulateData(screenSubjectFields: IKanbanField[], kanbanSubjectEntities: IKanbanSubjectEntity[], todoEntities: IKanbanTodoEntity[]) {
        const defaultKanbanSubject: IKanbanSubjectEntity = {
            Id: null,
            Name: this.Labels.SCREEN_SUBJECT,
            Fields: screenSubjectFields
        };
        kanbanSubjectEntities.unshift(defaultKanbanSubject);
        this._kanbanSubjectEntities(kanbanSubjectEntities);

        const selectedKanbanSubjectEntity = _.any(kanbanSubjectEntities, entity => entity.Id === this.EditControl.Model.KanbanSubjectId)
            ? kanbanSubjectEntities.find(entity => entity.Id === this.EditControl.Model.KanbanSubjectId)
            : defaultKanbanSubject;
        this._selectedKanbanSubjectEntity(selectedKanbanSubjectEntity);

        const selectedKanbanSubjectField = selectedKanbanSubjectEntity.Fields.find(field => field.Id === this.EditControl.Model.TitleFieldId);
        this._selectedKanbanSubjectField(selectedKanbanSubjectField);

        const selectedTodoEntity = todoEntities.find(entity => entity.Id === this.EditControl.Model.TodoEntityId);
        this._todoEntities(todoEntities);
        this._selectedTodoEntity(selectedTodoEntity);

        if (this._selectedTodoEntity()) {
            const todoTypes = this._selectedTodoEntity().Types;

            const selectedTodoType = todoTypes.find(type => type.Id === this.EditControl.Model.TodoTypeId);
            this._selectedTodoType(selectedTodoType);

            const todoFields = this._selectedTodoEntity().Fields;

            const selectedTodoField = todoFields.find(field => field.Id === this.EditControl.Model.TodoFieldId);
            this._selectedTodoField(selectedTodoField);
        }
    }
}