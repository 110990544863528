import {ActionCheckListDto} from "../Models/Dto/ActionCheckListDto";
import {ActionCheckListViewModel} from "../Models/View/ActionCheckListViewModel";
import {CheckItemFactory} from "../CheckItems/CheckItemFactory";

export class ActionCheckListMappingProfile {
    static MapToViewModel(dto: ActionCheckListDto) {
        const viewModel = new ActionCheckListViewModel();

        viewModel.LifeStatusId = dto.LifeStatusId;
        viewModel.LifeStatusName = dto.LifeStatusName;
        viewModel.LifeStatusMemo = dto.LifeStatusMemo;
        viewModel.LifeStatusMemoTranslation = dto.LifeStatusMemoTranslation;
        viewModel.LifeStatusTranslatedName = dto.LifeStatusTranslatedName;
        viewModel.LifeStatusApprovalName = dto.LifeStatusApprovalName;
        viewModel.LifeStatusNoActionNode = dto.LifeStatusNoActionNode;
        viewModel.LifeStatusSort = dto.LifeStatusSort;
        viewModel.IsFollowUpDisabled = dto.IsFollowUpDisabled;
        viewModel.DisabledReason = dto.DisabledReason;
        viewModel.CheckItems(dto.CheckItems.map(checkItemDto => CheckItemFactory.CreateCheckItem({
            Id: checkItemDto.Id,
            Name: checkItemDto.Name,
            NameTranslation: checkItemDto.NameTranslation,
            TypeName: checkItemDto.TypeName,
            Value: checkItemDto.Value,
            Required: checkItemDto.Required,
            ValField: checkItemDto.ValField
        })));

        return viewModel;
    }
}