import {P} from "Core/Common/Promise";
import {Request, IRequestOptions} from "Core/Common/Request";

export interface IHistoryRequestModel {
	EntityId: number;
	RecordId: number;
}

export class HistoryLogsStore {

	static GetHistory(model: IHistoryRequestModel): P.Promise<any> {
		let deferredResult = P.defer<any>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: 'api/HistoryApi/GetHistory',
				dataType: 'json',
				type: 'GET'
			}, params: model
		};

		Request.Send(requestParams)
			.then(data => deferredResult.resolve(data));

		return deferredResult.promise();
	}

	static HasHistory(model: IHistoryRequestModel): P.Promise<boolean> {
		let deferredResult = P.defer<any>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: 'api/HistoryApi/HasHistory',
				dataType: 'json',
				type: 'GET'
			}, params: model
		};

		Request.Send(requestParams)
			.then(data => {
					deferredResult.resolve(data)
				}
			);

		return deferredResult.promise();
	}
}