import { Notifier } from 'Core/Common/Notifier';
import { P } from 'Core/Common/Promise';
import { CONTROL_TYPES } from 'Core/Constant';
import { IDictionary } from 'Core/Screens/BaseScreen';
import { BlockUI } from '../../Common/BlockUi';
import { TableStore } from '../../Common/Stores/TableStore';
import {LOCK_EVENTS, LockManager} from "Core/Components/Locker/LockManager";
import { screenFacade } from './Facades/ScreenFacade';
import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';

export interface INavigateToScreenOptions {
    EntityId: number;
    RecordId: number;
}

export class FunctionBuilder {
    private static P = P;
    private static CONTROL_TYPES = CONTROL_TYPES;

    public static async Execute(code: string, param: IDictionary<string>, context: any = null){
        try {
            await this.BuildFunction(code).call(this, param, context);
        }catch(e){
            new Notifier().Failed(e.message);
        }
    }

    public static BuildFunction(body: string){
        return new Function(`return async function(param, $context) {
            const CONTROL_TYPES = this.CONTROL_TYPES;
            const P = this.P;
            
            var deferredResult = this.P.defer();
                
            ${body}

            return deferredResult.promise();            

        }`)();
    }

    public static async GetTableByName(tableName: string){
        return await TableStore.Get({ TableName: tableName });
    }

    public static async GetEditScreen(options){

        if(options.RecordId > 0){
            await LockManager.Instance.TryLock(options.EntityId, options.RecordId)
            .fail(err=>{                
                Notifier.Failed(err.message);
                return;
            });
        }

        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
        let screen = await screenManager.GetEditScreen({ EntityId: options.EntityId, TableTypeId: options.TableTypeId, RecordId: options.RecordId });

        if(options.RecordId > 0){
            screen.On('CLOSE', this, () => LockManager.Instance.ReleaseLock(options.EntityId, options.RecordId));
            screen.On('RECORD_SAVED', this, () => LockManager.Instance.ReleaseLock(options.EntityId, options.RecordId));
        }

        return screenFacade(screen);
    }

    public static BlockUI(){
        BlockUI.Block();
    }

    public static UnblockUI(){
        BlockUI.Unblock();
    }

    public static NavigateToRecordScreen(options: INavigateToScreenOptions){
        PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, {
            EntityId: options.EntityId,
            RecordId: options.RecordId
        });
    }
}